import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { AuthService } from '../../services/auth.service';
import { ModalProperties } from 'src/app/constants/set-modal-properties';

@Component({
  selector: 'app-login-button-modal',
  templateUrl: './login-button-modal.component.html',
  styleUrls: ['./login-button-modal.component.scss']
})
export class LoginButtonModalComponent implements OnInit {

  @Input() fontSmall = true;
  @Input() width100 = true;
  @Input() positionAbsolute = true;
  @Input() button = true;

  constructor(
    private dialog: MatDialog,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    ModalProperties.setBaseParams();
  }

  onLogIn() {
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '600px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    }
    ModalProperties.properties.panelClass = ['modal-background'];

    this.dialog.open(LoginComponent, ModalProperties.properties);
    ModalProperties.resetProperties();

  }

}
