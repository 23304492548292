import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Web3Service } from 'src/app/services/web3.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-asset-info',
  templateUrl: './asset-info.component.html',
  styleUrls: ['./asset-info.component.scss'],
})
export class AssetInfoComponent implements OnInit {

  copied = false;

  constructor(
    private dialogRef: MatDialogRef<AssetInfoComponent>,
    public web3Service: Web3Service,
    private clipboard: Clipboard
  ) { }

  ngOnInit() {}

  closeModal() {
    this.dialogRef.close();
  }

  copyAddress() {
    this.clipboard.copy(this.web3Service.usdtContractAddress);
    this.copied = true;
  }

}
