/* eslint-disable max-len */
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss']
})
export class CustomToastComponent implements OnInit {

  @Input() top: string;
  @Input() width: string;
  @Input() firstHoverString = 'address';
  @Input() customText: string;
  @Input() triangleFlip = false;
  @Input() background = 'black';
  @Input() preventClick = false;
  @Input() padding = '0 0';

  @ViewChild('container') container: ElementRef;
  @ViewChild('firstHover') firstHover: ElementRef;
  @ViewChild('secondHover') secondHover: ElementRef;

  clicked = false;

  constructor(
    private toast: ToastService
  ) { }

  ngOnInit(): void {
  }

  getPosition() {
    this.firstHover.nativeElement.style.left = (this.container.nativeElement.clientWidth / 2 - this.firstHover.nativeElement.clientWidth/2) + 'px';
    this.secondHover.nativeElement.style.left = (this.container.nativeElement.clientWidth / 2 - this.secondHover.nativeElement.clientWidth/2) + 'px';
  }
}
