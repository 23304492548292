import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-announcement-modal',
  templateUrl: './announcement-modal.component.html',
  styleUrls: ['./announcement-modal.component.scss'],
})
export class AnnouncementModalComponent implements OnInit {
  content: string;
  title: string;
  time: string;

  constructor(
    public dialogRef: MatDialogRef<AnnouncementModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
    ) { }

  ngOnInit() {
    this.content = this.data.content;
    this.title = this.data.title;
    this.time = this.data.time;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
