export class NavItem {

  constructor(
    public name: string,
    private route: string
  ) {
    this.name = name;
    this.route = route;
  }

  getRouteName() {
    return this.name;
  }
  getRoute() {
    return this.route;
  }
}
