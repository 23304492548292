import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ModalProperties } from 'src/app/constants/set-modal-properties';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { WalletService } from 'src/app/services/wallet.service';
import { ConvertCreidtsComponent } from '../convert-creidts/convert-creidts.component';

@Component({
  selector: 'app-confirm-withdrawal',
  templateUrl: './confirm-withdrawal.component.html',
  styleUrls: ['./confirm-withdrawal.component.scss'],
})
export class ConfirmWithdrawalComponent implements OnInit {
  error = false;
  success = false;
  loading = false;

  subscription: Subscription;
  subscription1: Subscription;

  withdrawalData = [
    {
      // text: 'your-total-invite-earnings',
      text: 'Your Total Invite Earnings',
      amount: this.subscriptionService.subscriptionWallet.bonusBalance,
      src: 'assets/images/invite-earnings.png',
      alt: 'Invite Earnings icon'
    },
    {
      // text: 'invite-earnings-to-deduct-confirm-withdrawal',
      text: 'Invite Earnings to Deduct',
      amount: 0,
      src: 'assets/images/invite-earnings.png',
      alt: 'Invite Earnings icon'
    },
    {
      // text: 'usdt-to-receive-confirm-withdrawal',
      text: 'USDT to Receive',
      amount: 0,
      src: 'assets/images/coin-logo/USDT.png',
      alt: 'USDT icon',
      hide: false
    },
    {
      text: 'ten-percent-tx-fee-confirm-withdrawal',
      amount: 0,
      src: 'assets/images/coin-logo/USDT.png',
      alt: 'USDT icon',
      hide: false
    },
    {
      text: 'total-usdt-to-receive-confirm-withdrawal',
      amount: 0,
      src: 'assets/images/coin-logo/USDT.png',
      alt: 'USDT icon',
      total: true
    },
    {
      // text: 'total-invite-earnings-after-deduction',
      text: 'Total Invite Earnings After Deduction',
      amount: 0,
      src: 'assets/images/invite-earnings.png',
      alt: 'Invite Earnings icon',
    },
  ];

  constructor(
    private dialogRef: MatDialogRef<ConfirmWithdrawalComponent>,
    private dialog: MatDialog,
    private walletService: WalletService,
    private subscriptionService: SubscriptionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    const totalInvEarnings = this.withdrawalData[0];
    const invEarningsToDeduct = this.withdrawalData[1];
    const usdxToReceive = this.withdrawalData[2];
    const txFee = this.withdrawalData[3];
    const totalUsdxToReceive = this.withdrawalData[4];
    const totalInvEarningsAfterDeduction = this.withdrawalData[5];

    if (this.data.operation === 'convert') {
      txFee.hide = true;
      usdxToReceive.hide = true;
      // totalUsdxToReceive.text = 'total-empass-to-receive-confirm-withdrawal';
      totalUsdxToReceive.text = 'Total ExPass to Receive';
      totalUsdxToReceive.src = 'assets/images/credits-empass-v2-250px-min.png';
    } else {
      txFee.amount = this.data.inviteCredits * .1;
    }
    invEarningsToDeduct.amount = 0 - this.data.inviteCredits;
    usdxToReceive.amount = this.data.inviteCredits;
    totalUsdxToReceive.amount = usdxToReceive.amount - txFee.amount;
    totalInvEarningsAfterDeduction.amount = totalInvEarnings.amount + invEarningsToDeduct.amount;
  }

  close(): void {
    this.dialogRef.close();
  }

  backToWithdraw(operation: string): void {
    this.close();
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    } //end ELSE

    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    ModalProperties.properties.data = {
      data: {
        withdraw: (operation === 'withdraw' ? true : false),
        inviteCredits: this.data.inviteCredits
      }
    };

    this.dialog.open(ConvertCreidtsComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  convertCredits() {
    this.error = false;
    this.success = false;
    this.loading = true;
    // this.subscription = this.walletService.convertInviteEarnings(this.data.inviteCredits).subscribe((res: any) => {
    //   this.subscription1 = this.subscriptionService.getSubscriptionInfo().subscribe();
    //   this.loading = false;
    //   this.success = true;
    //   setTimeout(() => {
    //     this.success = false;
    //   }, 10000);
    // },
    //   (err: any) => {
    //     this.error = true;
    //     this.loading = false;
    //     setTimeout(() => {
    //       this.error = false;
    //     }, 10000);
    //   });
  }
}
