import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppStoreService } from 'src/app/services/app-store.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() placeholderText;
  @Output() newSearchEvent = new EventEmitter<any>();
  searchInput;
  constructor(
    public appStore: AppStoreService
  ) {}

  ngOnInit() {}

  searchEmit(event) {
    this.newSearchEvent.emit(event);
  }

}
