import { Component, OnInit, OnDestroy } from '@angular/core';
import { coinLibrary } from '../../models/common/coinLibrary';
import { trigger, transition, style, animate } from '@angular/animations';
import { FakeLiveDataService } from '../../services/fake-live-data.service';
import { Subscription } from 'rxjs';
import { numberGoUp } from '../../animations/animations';
import { AppStoreService } from '../../services/app-store.service';

@Component({
  selector: 'app-order-bots-list',
  templateUrl: './order-bots-list.component.html',
  styleUrls: ['./order-bots-list.component.scss'],
  animations: [
    numberGoUp
  ]
})
export class OrderBotsListComponent implements OnInit, OnDestroy {

  bots;
  subscription: Subscription;

  constructor(
    private fakeData: FakeLiveDataService,
    private appStore: AppStoreService
  ) { }

  ngOnInit(): void {
   this.fakeData.init();
   this.bots = this.appStore.bots;
   this.subscription = this.fakeData.botReward.subscribe((res: any) => {
    this.bots[0].unclaimed += res;
   });
  }

  getImage(bot: any) {
    return coinLibrary[bot.name.toLowerCase()].image;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
