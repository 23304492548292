import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-night-day-toggle',
  templateUrl: './night-day-toggle.component.html',
  styleUrls: ['./night-day-toggle.component.scss'],
})
export class NightDayToggleComponent implements OnInit {
  check = true;
  constructor() { }

  ngOnInit() {}

  onClick() {
    this.check = !this.check;
  }

}
