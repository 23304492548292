import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateCenter'
})
export class TruncateCenterPipe implements PipeTransform {

  transform(value: string, startChar = 6, endChar = 6, numPeriods = 6): unknown {
    if (!value || value.length < startChar + endChar) {
      return value;
    }
    else {
      let periods = '';
      for (let i = 0; i < numPeriods; i++) {
        periods += '.';
      }
      return value.slice(0, startChar) + periods + value.slice(value.length - endChar);
    }
  }

}
