import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-general-description',
  templateUrl: './general-description.component.html',
  styleUrls: ['./general-description.component.scss'],
})
export class GeneralDescriptionComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<GeneralDescriptionComponent>) {}

  ngOnInit() {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
