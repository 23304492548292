import { Component, EventEmitter, OnInit, Output, OnDestroy, Input } from '@angular/core';
import { WalletService } from '../../services/wallet.service';
import { AppStoreService } from '../../services/app-store.service';
import { coinLibrary } from 'src/app/models/common/coinLibrary';
import { MatDialog } from '@angular/material/dialog';
import { CirculationConfirmationComponent } from '../circulation-confirmation/circulation-confirmation.component';
import { AuthService } from '../../services/auth.service';
import { LoginComponent } from '../login/login.component';
import { QrContractComponent } from '../qr-contract/qr-contract.component';
import { Web3Service } from '../../services/web3.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CoinAsset } from 'src/app/models/common/coin-asset';
import { ModalProperties } from 'src/app/constants/set-modal-properties';
import { SubscriptionService } from '../../services/subscription.service';
import { SubscriptionModalComponent } from '../../header/subscription-modal/subscription-modal.component';

@Component({
  selector: 'app-circulations-options',
  templateUrl: './circulations-options.component.html',
  styleUrls: ['./circulations-options.component.scss'],
})
export class CirculationsOptionsComponent implements OnInit, OnDestroy {

  @Input() showQR = true;

  subscription: Subscription;
  subscription1: Subscription;
  coinAmount;
  metamaskInterval;
  bscInterval;
  bscNetwork = false;
  coin;

  constructor(
    public walletService: WalletService,
    public appStoreService: AppStoreService,
    private dialog: MatDialog,
    public authService: AuthService,
    public web3Service: Web3Service,
    public router: Router,
    public appStore: AppStoreService,
    public subscriptionService: SubscriptionService
  ) {}

  ngOnInit() {
    this.assetChangeSubscription();
    this.web3TxSubscription();
  }

  assetChangeSubscription() {
    this.subscription = this.appStore.selectedAssetInfo.subscribe(
      (res: CoinAsset) => {
        this.coin = res.id;
      }
    );
  }

  web3TxSubscription() {
    this.subscription1 = this.web3Service.$txConfirmed.subscribe((res: any) => {
      this.coinAmount = null;
    });
  }

  getCoinAmount() {
    return this.walletService.bep20Wallet.usdx;
  }

  switchNetwork() {
    this.web3Service.networkRequest('bscNetwork');
  }

  onClick() {
    if (!this.authService.authObj.walletConnected) {
      return this.router.navigate(['/wallet']);
    }
    if (this.subscriptionService.subscriptionWallet.activeSubscriptionStatus !== 'active') {
      this.openSubscriptionModal();
    }
    this.userLoggedIn();

    this.max();
  }

  preventKeys(event: any) {
    if ([189, 187, 69].includes(event.keyCode)) {
      event.returnValue = false;
      event.preventDefault();
    }
  }
  userLoggedIn() {
    if (!this.authService.authObj.isLoggedIn) {
      ModalProperties.setBaseParams();
      if (ModalProperties.properties.screenWidth > 600) {
        ModalProperties.properties.width = '600px';
        ModalProperties.properties.maxWidth = '90vw';
      }
      ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
      this.dialog.open(LoginComponent, ModalProperties.properties);
      ModalProperties.resetProperties();
    }
  }

  openSubscriptionModal() {
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else {
      //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    }
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    this.dialog.open(SubscriptionModalComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  max() {
    const maxSubscriptionAmount = this.subscriptionService.subscriptionWallet.activeSubscriptionTradeAmtMax;
    const availableAmount = this.walletService.bep20Wallet.usdx;
    if (maxSubscriptionAmount < availableAmount) {
      this.coinAmount = maxSubscriptionAmount;
    } else {
      this.coinAmount = availableAmount;
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription1?.unsubscribe();
  }
}
