/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { from, of, Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  currentConnection: any;
  establishingConnection = false;
  _messages = new Subject();

  constructor(
    // private socket: Socket
  ) {
    // this.initiateConnection()
    //   .pipe(take(1), switchMap(() => this.socket.fromEvent('get new data')))
    //   .subscribe((response) => {
    //     if (!response) {
    //       return;
    //     }

    //     const result = typeof response === 'string' ? JSON.parse(response) : response;
    //     this._messages.next(result);
    //   });
  }

  initiateConnection() {
    // if (!this.currentConnection || !this.currentConnection.connected) {
    //   const promise = new Promise((resolve) => {
    //     this.socket.on('connect', () => {
    //       this.establishingConnection = false;
    //       this.socket.removeListener('connect');
    //       resolve(true);
    //     });
    //     if (!this.establishingConnection) {
    //       this.establishingConnection = true;
    //       this.currentConnection = this.socket.connect();
    //     }
    //   });

    //   return from(promise);
    // } else {
    //   return of(true);
    // }
  }

  sendMessage(event: string, msg: string) {
    // const sub = this.initiateConnection()
    //   .subscribe((result) => {
    //     this.socket.emit(event, msg);
    //     // sub.unsubscribe();
    //   });
  }

  getMessage(event?: string) {
    // if (event) {
    //   this.initiateConnection()
    //     .pipe(take(1), switchMap(() => this.socket.fromEvent(event)));
    // }

    return this._messages.asObservable();
  }

  removeEvent(eventName: string) {
    // this.socket.removeListener(eventName);
  }
}
