import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-switch-network',
  templateUrl: './switch-network.component.html',
  styleUrls: ['./switch-network.component.scss'],
})
export class SwitchNetworkComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
