import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {

  constructor(
    public translate: TranslateService,
  ){

  }

  transform(key: string, args?: string[]): string {
    if (!key) {
      return '';
    }
    const processedKey = key.toLowerCase().replace(/ /g, '-');
    const translation = this.translate.instant(key);
    return this.processString(translation, args);
  }

  processString(str: string, args: string[]) {
    if(!str || !args) {
      return str || '';
    }
    str = str.replace('(t)', '');
    args.forEach((fill, i) => {
      str = str.replace('{' + i + '}', fill);
    });

    return str;
  }

}
