import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CirculationHistoryItem } from 'src/app/models/common/circulation-history-models/circulation-history-item';
import { CoinAsset } from 'src/app/models/common/coin-asset';
import { AppStoreService } from 'src/app/services/app-store.service';
import { CirculationService } from 'src/app/services/circulation.service';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-buy-sell-switcher',
  templateUrl: './buy-sell-switcher.component.html',
  styleUrls: ['./buy-sell-switcher.component.scss'],
})
export class BuySellSwitcherComponent implements OnInit, OnDestroy {
  @Output() emitFocus = new EventEmitter<boolean>();
  links = ['Buy', 'Sell'];
  activeLink = this.links[0];

  options = ['Market', 'Limit', 'Stop'];
  activeOption = this.options[0];
  presets = [
    {
      name: '25%',
      value: 0.25,
      active: false
    },
    {
      name: '50%',
      value: 0.5,
      active: false
    },
    {
      name: '75%',
      value: 0.75,
      active: false
    },
    {
      name: 'MAX',
      value: 1,
      active: false
    }
  ];

  amount: any;
  userInputAmount: any;

  userCoinTotal: number;
  userUSDTTotal: number;
  availableCoinTotal: number;
  availableAssetTotal: number;
  coin: CoinAsset;
  currentPercentage: number;
  lastLink = this.activeLink;
  lastOption = this.activeOption;

  errorNotEnoughUSD: boolean;
  errorNotEnoughAssets: boolean;
  transactionBtnDisabled: boolean;

  price: number;

  limitPrice: number;

  destroy$ = new Subject<boolean>();

  constructor(
    public appStore: AppStoreService,
    public walletService: WalletService,
    private toastr: ToastrService,
    private circulationService: CirculationService
  ) { }

  ngOnInit() {
    this.availableCoinTotal = this.appStore.selectedAssetValue?.allTimeAsset;
    this.userCoinTotal = this.walletService.wallet[this.appStore.selectedAssetValue?.symbol.toLowerCase()] || 0;
    this.userUSDTTotal = this.walletService.bep20Wallet.usdt;
    this.availableAssetTotal = this.walletService.wallet[this.appStore.selectedSymbol.toLowerCase()];

    this.appStore.selectedAssetInfo.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (res: CoinAsset) => {
        this.coin = res;
        this.limitPrice = this.coin.price;
      }
    );
  }

  canBuy() {
    return this.amount;
  }

  canSell() {
    return this.amount;
  }

  setPreset(percent: number) {
    this.userInputAmount = null;
    this.currentPercentage = percent;

    this.presets.forEach(preset => preset.active = (preset.value === percent));

    if (this.activeOption === 'Market' && this.activeLink === 'Buy') {
      this.amount = this.userUSDTTotal * percent;
    } else if (this.activeLink === 'Buy') {
      this.amount = (this.userUSDTTotal * percent) / this.coin?.price;
    } else if (this.activeLink === 'Sell') {
      this.amount = this.availableAssetTotal * percent;
    }
  }

  detectIfMarketTransaction(type) {
    this.lastLink = this.activeLink;
    this.lastOption = this.activeOption;
    if (type === 'Buy' || type === 'Sell') {
      this.activeLink = type;
    } else {
      this.activeOption = type;
    }

    if (this.activeLink === 'Buy' && this.activeOption === 'Market') {
      this.amount = this.userInputAmount || (this.amount * this.coin?.price);
    }
    else if (this.lastLink === 'Buy' && this.lastOption === 'Market') {
      this.amount /= this.coin?.price;
    }
  }

  calculatePrice() {
    this.price = this.amount * this.appStore.selectedAssetValue?.usdxConversion;
  }

  detectUserInput(e) {
    if (e.key === '+' || e.key === '-') {
      e.preventDefault();
      return false;
    }
    this.userInputAmount = this.amount;
    this.presets.forEach(preset => preset.active = false);
  }

  transact() {
    this.errorNotEnoughUSD = false;
    this.errorNotEnoughAssets = false;
    let totalAmt;

    if (this.activeOption === 'Market' && this.activeLink === 'Buy') {
      totalAmt = this.amount;
      if (totalAmt > this.userUSDTTotal) {
        return this.errorNotEnoughUSD = true;
      }
    } else if (this.activeOption === 'Market' && this.activeLink === 'Sell') {
      if (this.amount > this.availableAssetTotal) {
        return this.errorNotEnoughAssets = true;
      }
      totalAmt = this.amount * this.coin?.price;
    }
    else if (this.activeOption !== 'Market' && this.activeLink === 'Buy') {
      totalAmt = this.amount * this.limitPrice;
      if (totalAmt > this.userUSDTTotal) {
        return this.errorNotEnoughUSD = true;
      }
    } else if (this.activeOption !== 'Market' && this.activeLink === 'Sell') {
      if (this.amount > this.availableAssetTotal) {
        return this.errorNotEnoughAssets = true;
      }
      totalAmt = this.amount * this.limitPrice;
    }

    this.transactionBtnDisabled = true;
    this.toastr.warning(
      '<span class="text-bold">Preparing your transaction</span></br></br> <i class="icon icon-watch10 warning-yellow"></i> <span>Waiting for confirmation...</span>',
      '', {
      timeOut: 2000,
      closeButton: true,
      easeTime: 150,
      progressBar: true,
    }).onHidden.subscribe(() => {
      this.transactionBtnDisabled = false;
      this.toastr.success(
        '<span class="text-bold">Transaction confirmed!</span></br></br> <i class="icon icon-checkmark-circle success"></i> <span>Transaction added to your history</span>',
        '', {
        timeOut: 3000,
        closeButton: true,
        easeTime: 150,
        progressBar: true,
      });


      const circulationItem: CirculationHistoryItem = {
        amt: totalAmt,
        bonus_amt: totalAmt,
        bonus_tx_id: '0x329154bce4d20693bb6dc2d075b4c1114f6240616152516e5776879b4b4d3aed',
        complete_dt: Date.now().toString(),
        currency: 'USDT',
        status: this.activeLink,
        tran_type: null,
        tx_dt: Date.now().toString(),
        tx_id: '0x329154bce4d20693bb6dc2d075b4c1114f6240616152516e5776879b4b4d3aed',
        issue: 0,
        type: 'GS'
      };

      this.circulationService.personalHistory.push(circulationItem);

      if (this.activeLink === 'Buy') {
        this.userUSDTTotal -= totalAmt;
        this.availableAssetTotal += totalAmt / this.coin?.price;
      } else {
        this.userUSDTTotal += totalAmt;
        this.availableAssetTotal -= totalAmt / this.coin?.price;
      }


      this.amount = null;
      this.presets.forEach(preset => preset.active = false);
      // this.limitPrice = null;
    });


  }

  focus() {
    this.emitFocus.emit(true);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
