import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  navigatedFromSubscriptionModal: boolean;

  scrollToCirculationHistory = new Subject<any>();

  constructor() { }

}
