import { Component, OnInit } from '@angular/core';
import { NavItem } from 'src/app/models/navigation-models/nav-item';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from 'src/app/common/login/login.component';
import { ModalProperties } from 'src/app/constants/set-modal-properties';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  navigation: NavItem[] = [
    new NavItem('dashboard', '/dashboard'),
    // new NavItem('get-crypto','/get-crypto'),
    // new NavItem('services', '/dashboard/services'),
    // new NavItem('wallet', '/wallet')
  ];

  constructor(
    public router: Router,
    private auth: AuthService,
    private openLogin: MatDialog
  ) { }

  ngOnInit(): void {
  }

  onNavigate(route: NavItem) {
    switch(route.getRouteName()) {

      case 'dashboard':
        this.getDashboardURL();
        break;

      case 'get-crypto':
        if (!this.auth.authObj.isLoggedIn) {
          if (ModalProperties.properties.screenWidth > 750) {
            ModalProperties.properties.width = '750px';
            ModalProperties.properties.maxWidth = '90vw';
          } else { //Modal Max width for full width pop ups on mobile thingy
            ModalProperties.properties.width = '100%';
            ModalProperties.properties.maxWidth = '100vw';
          } //end ELSE

          ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
          this.openLogin.open(LoginComponent, ModalProperties.properties);
          ModalProperties.resetProperties();
        } else {
          this.router.navigate([route.getRoute()]);
        }
        break;

      default:
        this.router.navigate([route.getRoute()]);
        break;
    };
  }

  checkRoute(route: NavItem) {
    let value = this.router.url.includes(route.getRoute());
    if(this.router.url.includes('services') && route.getRoute().includes('services')) {
      value = true;
    } else if (this.router.url.includes('services') &&  !route.getRoute().includes('services')) {
      value = false;
    }
    return value;
  }

  getDashboardURL() {
    const lastVisited = localStorage.getItem('empowerLastVisited');
    if(lastVisited && lastVisited !== 'BTC') {
      this.router.navigate(['/dashboard/', lastVisited]);
    } else {
      this.router.navigate(['/dashboard/BTC']);
    }
  }
}
