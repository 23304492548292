import { AfterViewInit, Component, OnInit } from '@angular/core';
import gsap from 'gsap/all';

@Component({
  selector: 'app-space-elevator',
  templateUrl: './space-elevator.component.html',
  styleUrls: ['./space-elevator.component.scss'],
})
export class SpaceElevatorComponent implements OnInit, AfterViewInit {
  itemsDown = [
    '.light4',
    '.light5',
    '.light6',
    '.light7',
    '.light8',
    '.light11',
    '.light12',
    '.light13',
    '.light14',
    '.light15',
    '.light16'
  ];

  itemsUp = [
    '.light1',
    '.light2',
    '.light3',
    '.light9',
    '.light10',
    '.light17'
  ];

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.itemsDown.forEach(light => this.animateWithRandomNumber(light, -1080, 1080));
    this.itemsUp.forEach(light => this.animateWithRandomNumber(light, 1080, -1080));
  }

  animateWithRandomNumber(myClass: string, from: number, to: number) {
    gsap.fromTo(myClass, { y: from }, {
      y: to,
      onComplete: this.animateWithRandomNumber,
      onCompleteParams: [myClass, from, to],
      ease: 'none',
      duration: Math.floor((Math.random() * 20) + 1),
      repeat: -1
    });
  }
}
