import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StayOnDeprecatedTokenService } from 'src/app/services/stay-on-deprecated-token.service';

@Component({
  selector: 'app-usdx-warning-modal',
  templateUrl: './usdx-warning-modal.component.html',
  styleUrls: ['./usdx-warning-modal.component.scss'],
})
export class UsdxWarningModalComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<UsdxWarningModalComponent>,
    private router: Router,
    private sodt: StayOnDeprecatedTokenService
  ) { }

  ngOnInit() { }

  goToUSDx01(): void {
    this.sodt.stayOnDeprecatedToken = false;
    localStorage.removeItem('empowerLastVisited');
    this.router.navigate(['/dashboard/BTC']);
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  cancel(): void {
    this.close();
    this.sodt.stayOnDeprecatedToken = true;
  }
}
