/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CirculationPSItem } from '../models/common/circulation-history-models/circulation-ps';
import { CirculationGSItem } from '../models/common/circulation-history-models/circulation-gs';
import { environment } from 'src/environments/environment.prod';
import { map } from 'rxjs/operators';
import { CirculationHistoryItem } from '../models/common/circulation-history-models/circulation-history-item';
import { AuthService } from './auth.service';
import { WebsocketService } from './websocket.service';
import { Web3Service } from './web3.service';
import { ToastrService } from 'ngx-toastr';
import { WalletService } from './wallet.service';

@Injectable({
  providedIn: 'root'
})
export class CirculationService {

  last: any;
  personalHistory;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private websocketService: WebsocketService,
    private walletService: WalletService
  ) {
    // this.websocketService.getMessage()
    //   .subscribe((result: any) => {
    //     if (result.recordType === 'gs' ||
    //       (result) &&
    //       (result.recordType === 'PS_SENT' ||
    //       result.recordType === 'PS' || result.recordType === 'pymt' ||
    //       result.recordType === 'circ') && result.data.userAddress === this.walletService.wallet.ethAddress) {
    //       this.getUserCirculation();
    //     }
    //   });
  }

  getCirculationPS(currency: string, issue: number): Observable<any> {
    const data: CirculationPSItem[] = [];
    return this.http.post(`${environment.apiUrl}/circulationhistory`, {
      type: 'ps',
      statusList: 'Pending,Payout,WaitPayout',
      currency,
      issue
    }).pipe(map((res: { result: CirculationHistoryItem[] }) => {
      res.result.forEach((item) => {
        item.type = 'PS';
      });
      return res.result;
    }));
  }

  getCirculationGS(currency: string, issue: number): Observable<any> {
    const data: CirculationGSItem[] = [];
    return this.http.post(`${environment.apiUrl}/circulationhistory`, {
      type: 'gs',
      statusList: 'Complete',
      currency,
      issue
    }).pipe(map((res: { result: CirculationHistoryItem[] }) => {
      res.result.forEach((item) => {
        item.type = 'GS';
      });
      return res.result;
    }));
  }

  getUserCirculation() {
    // return this.http.post(`${environment.apiUrl}/circulationhistory`, {
    //   sessIDstr: this.authService.authObj.sessIDstr,
    //   type: 'personal'
    // }).subscribe((data: any) => {
      const result: CirculationHistoryItem[] = [
        {
          amt: Math.floor(Math.random() * 1001),
          bonus_amt: Math.floor(Math.random() * 1001),
          bonus_tx_id: '0x329154bce4d20693bb6dc2d075b4c1114f6240616152516e5776879b4b4d3aed',
          complete_dt: Date.now().toString(),
          currency: 'USDT',
          status: 'Sell',
          tran_type: null,
          tx_dt: Date.now().toString(),
          tx_id: '0x329154bce4d20693bb6dc2d075b4c1114f6240616152516e5776879b4b4d3aed',
          issue: 0,
          type: 'PS'
        },
        {
          amt: Math.floor(Math.random() * 1001),
          bonus_amt: Math.floor(Math.random() * 1001),
          bonus_tx_id: '0x329154bce4d20693bb6dc2d075b4c1114f6240616152516e5776879b4b4d3aed',
          complete_dt: Date.now().toString(),
          currency: 'USDT',
          status: 'Sell',
          tran_type: null,
          tx_dt: Date.now().toString(),
          tx_id: '0x329154bce4d20693bb6dc2d075b4c1114f6240616152516e5776879b4b4d3aed',
          issue: 0,
          type: 'PS'
        },
        {
          amt: Math.floor(Math.random() * 1001),
          bonus_amt: Math.floor(Math.random() * 1001),
          bonus_tx_id: '0x329154bce4d20693bb6dc2d075b4c1114f6240616152516e5776879b4b4d3aed',
          complete_dt: Date.now().toString(),
          currency: 'USDT',
          status: 'Buy',
          tran_type: null,
          tx_dt: Date.now().toString(),
          tx_id: '0x329154bce4d20693bb6dc2d075b4c1114f6240616152516e5776879b4b4d3aed',
          issue: 0,
          type: 'GS'
        },
        {
          amt: Math.floor(Math.random() * 1001),
          bonus_amt: Math.floor(Math.random() * 1001),
          bonus_tx_id: '0x329154bce4d20693bb6dc2d075b4c1114f6240616152516e5776879b4b4d3aed',
          complete_dt: Date.now().toString(),
          currency: 'USDT',
          status: 'Buy',
          tran_type: null,
          tx_dt: Date.now().toString(),
          tx_id: '0x329154bce4d20693bb6dc2d075b4c1114f6240616152516e5776879b4b4d3aed',
          issue: 0,
          type: 'GS'
        }
      ];
      // result.forEach((result) => {
      //   result.type = result.status?.toLowerCase() === 'complete' ? 'GS' : 'PS';
      // });
      this.personalHistory = result;
    // });
  }
}
