import { Component, Input, OnInit } from '@angular/core';
import { AppStoreService } from '../../services/app-store.service';

@Component({
  selector: 'app-bond-card',
  templateUrl: './bond-card.component.html',
  styleUrls: ['./bond-card.component.scss']
})
export class BondCardComponent implements OnInit {

  @Input() bond;

  width: number;

  constructor(
    public appStore: AppStoreService
    ) { }

  ngOnInit(): void {
    if(this.bond.status) {
      this.getWidth();
    }
  }

  getWidth() {
    // eslint-disable-next-line max-len
    const max = Math.floor(this.bond.bondMaturationDeadline - this.bond.started)/1000;
    const passed = max - Math.floor((Number(this.bond.bondMaturationDeadline) - Math.floor(new Date().getTime()))/1000);
    this.width = passed/max;
  }

  onClick() {
    this.appStore.selectedBond = this.bond;
  }

}
