import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circulation-confirmation',
  templateUrl: './circulation-confirmation.component.html',
  styleUrls: ['./circulation-confirmation.component.scss'],
})
export class CirculationConfirmationComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
