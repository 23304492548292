export const coinLibrary = {
  btc: {
    id: 'btc',
    name: 'BTC',
    symbol: 'BTC',
    image: 'assets/images/coin-logo/BTC.png',
    activity24h: 1241256,
    availableAmount: 1328.232323,
    usdxConversion: 1,
    allTimeValue: 51512390,
    allTimeAsset: 765421,
    candleData:  [{
      close: "35407",
      high: "35430",
      low: "33906",
      open: "34620",
      time: "2021-07-25T00:00:00.000Z",
      volume: "2779"
    }, {
      close: "37296",
      high: "40515.6",
      low: "35276",
      open: "35422",
      time: "2021-07-26T00:00:00.000Z",
      volume: "16313"
    }, {
      close: "39563",
      high: "39563",
      low: "36436",
      open: "37296",
      time: "2021-07-27T00:00:00.000Z",
      volume: "6041"
    }, {
      close: "40004",
      high: "40899",
      low: "38822",
      open: "39563",
      time: "2021-07-28T00:00:00.000Z",
      volume: "7978"
    }, {
      close: "40041",
      high: "40623",
      low: "39282",
      open: "40004",
      time: "2021-07-29T00:00:00.000Z",
      volume: "4068"
    }, {
      close: "42169",
      high: "42244",
      low: "38373",
      open: "40034.4",
      time: "2021-07-30T00:00:00.000Z",
      volume: "7765"
    }, {
      close: "41409",
      high: "42317",
      low: "41051",
      open: "42169",
      time: "2021-07-31T00:00:00.000Z",
      volume: "5073"
    }, {
      close: "39922",
      high: "42532",
      low: "39596",
      open: "41409",
      time: "2021-08-01T00:00:00.000Z",
      volume: "9113"
    }, {
      close: "39156",
      high: "40441",
      low: "38716",
      open: "39922",
      time: "2021-08-02T00:00:00.000Z",
      volume: "4618"
    }, {
      close: "38207",
      high: "39774",
      low: "37700",
      open: "39156",
      time: "2021-08-03T00:00:00.000Z",
      volume: "5076"
    }, {
      close: "39754",
      high: "39965",
      low: "37564",
      open: "38219",
      time: "2021-08-04T00:00:00.000Z",
      volume: "3668"
    }, {
      close: "40887",
      high: "41352",
      low: "37361",
      open: "39741",
      time: "2021-08-05T00:00:00.000Z",
      volume: "6434"
    }, {
      close: "42830",
      high: "43294",
      low: "39933",
      open: "40887",
      time: "2021-08-06T00:00:00.000Z",
      volume: "6460"
    }, {
      close: "44589",
      high: "44689.2",
      low: "42464",
      open: "42830",
      time: "2021-08-07T00:00:00.000Z",
      volume: "9321"
    }, {
      close: "43770",
      high: "45249",
      low: "43300",
      open: "44589",
      time: "2021-08-08T00:00:00.000Z",
      volume: "8162"
    }, {
      close: "46293",
      high: "46432.2",
      low: "42792",
      open: "43770",
      time: "2021-08-09T00:00:00.000Z",
      volume: "10721"
    }, {
      close: "45612",
      high: "46674",
      low: "44644",
      open: "46291",
      time: "2021-08-10T00:00:00.000Z",
      volume: "6365"
    }, {
      close: "45546",
      high: "46725",
      low: "45358",
      open: "45612",
      time: "2021-08-11T00:00:00.000Z",
      volume: "4321"
    }, {
      close: "44423",
      high: "46185",
      low: "43789",
      open: "45546",
      time: "2021-08-12T00:00:00.000Z",
      volume: "5644"
    }, {
      close: "47842",
      high: "47866",
      low: "44276",
      open: "44423",
      time: "2021-08-13T00:00:00.000Z",
      volume: "7388"
    }, {
      close: "47084",
      high: "48120",
      low: "46036",
      open: "47842",
      time: "2021-08-14T00:00:00.000Z",
      volume: "4351"
    }, {
      close: "47031",
      high: "47355",
      low: "45556",
      open: "47084",
      time: "2021-08-15T00:00:00.000Z",
      volume: "3831"
    }, {
      close: "45940",
      high: "48059",
      low: "45689",
      open: "47031",
      time: "2021-08-16T00:00:00.000Z",
      volume: "6058"
    }, {
      close: "44698",
      high: "47157",
      low: "44467",
      open: "45940",
      time: "2021-08-17T00:00:00.000Z",
      volume: "6933"
    }, {
      close: "44744",
      high: "46007.8",
      low: "44290",
      open: "44698",
      time: "2021-08-18T00:00:00.000Z",
      volume: "5816"
    }, {
      close: "46765",
      high: "47060",
      low: "43992",
      open: "44744",
      time: "2021-08-19T00:00:00.000Z",
      volume: "5965"
    }, {
      close: "49332",
      high: "49362",
      low: "46660",
      open: "46765",
      time: "2021-08-20T00:00:00.000Z",
      volume: "6044"
    }, {
      close: "48868",
      high: "49784",
      low: "48290",
      open: "49332",
      time: "2021-08-21T00:00:00.000Z",
      volume: "3919"
    }, {
      close: "49264",
      high: "49487",
      low: "48121",
      open: "48868",
      time: "2021-08-22T00:00:00.000Z",
      volume: "2746"
    }, {
      close: "49539",
      high: "50506.3",
      low: "49055",
      open: "49264",
      time: "2021-08-23T00:00:00.000Z",
      volume: "5420"
    }, {
      close: "47714",
      high: "49857",
      low: "47604",
      open: "49539",
      time: "2021-08-24T00:00:00.000Z",
      volume: "4771"
    }, {
      close: "48989",
      high: "49244",
      low: "47158",
      open: "47714",
      time: "2021-08-25T00:00:00.000Z",
      volume: "4118"
    }, {
      close: "46832",
      high: "49385",
      low: "46370.7",
      open: "48989",
      time: "2021-08-26T00:00:00.000Z",
      volume: "6478"
    }, {
      close: "49076.1",
      high: "49159",
      low: "46364.9",
      open: "46832",
      time: "2021-08-27T00:00:00.000Z",
      volume: "5795"
    }, {
      close: "48914",
      high: "49310",
      low: "48400",
      open: "49076.1",
      time: "2021-08-28T00:00:00.000Z",
      volume: "2300"
    }, {
      close: "48800",
      high: "49650",
      low: "47844",
      open: "48914",
      time: "2021-08-29T00:00:00.000Z",
      volume: "4045"
    }, {
      close: "47008",
      high: "48915.7",
      low: "46904",
      open: "48800",
      time: "2021-08-30T00:00:00.000Z",
      volume: "4122"
    }, {
      close: "47157",
      high: "48241",
      low: "46725",
      open: "47008",
      time: "2021-08-31T00:00:00.000Z",
      volume: "4300"
    }, {
      close: "48844",
      high: "49102",
      low: "46600",
      open: "47157",
      time: "2021-09-01T00:00:00.000Z",
      volume: "5707"
    }, {
      close: "49291",
      high: "50365",
      low: "48633",
      open: "48844",
      time: "2021-09-02T00:00:00.000Z",
      volume: "4952"
    }, {
      close: "50000",
      high: "51032",
      low: "48362",
      open: "49291",
      time: "2021-09-03T00:00:00.000Z",
      volume: "7334"
    }, {
      close: "49899",
      high: "50529",
      low: "49418",
      open: "50000",
      time: "2021-09-04T00:00:00.000Z",
      volume: "2368"
    }, {
      close: "51762",
      high: "51900",
      low: "49500",
      open: "49899",
      time: "2021-09-05T00:00:00.000Z",
      volume: "5538"
    }, {
      close: "52676",
      high: "52709",
      low: "51062",
      open: "51762",
      time: "2021-09-06T00:00:00.000Z",
      volume: "4843"
    }, {
      close: "46778",
      high: "52888",
      low: "43347",
      open: "52676",
      time: "2021-09-07T00:00:00.000Z",
      volume: "16703"
    }, {
      close: "46088",
      high: "47342.4",
      low: "44509.9",
      open: "46778",
      time: "2021-09-08T00:00:00.000Z",
      volume: "7552"
    }, {
      close: "46389",
      high: "47397",
      low: "45591",
      open: "46088",
      time: "2021-09-09T00:00:00.000Z",
      volume: "3853"
    }, {
      close: "44852",
      high: "47036",
      low: "44265.1",
      open: "46389",
      time: "2021-09-10T00:00:00.000Z",
      volume: "5180"
    }, {
      close: "45169",
      high: "45990",
      low: "44750",
      open: "44852",
      time: "2021-09-11T00:00:00.000Z",
      volume: "2544"
    }, {
      close: "46069",
      high: "46391",
      low: "44780.1",
      open: "45169",
      time: "2021-09-12T00:00:00.000Z",
      volume: "3386"
    }, {
      close: "44960",
      high: "46823",
      low: "43514",
      open: "46069",
      time: "2021-09-13T00:00:00.000Z",
      volume: "8766"
    }, {
      close: "47078",
      high: "47235",
      low: "44726",
      open: "44960",
      time: "2021-09-14T00:00:00.000Z",
      volume: "3521"
    }, {
      close: "48136",
      high: "48440",
      low: "46738",
      open: "47078",
      time: "2021-09-15T00:00:00.000Z",
      volume: "3984"
    }, {
      close: "47786",
      high: "48518",
      low: "47080",
      open: "48136",
      time: "2021-09-16T00:00:00.000Z",
      volume: "3606"
    }, {
      close: "47292",
      high: "48183",
      low: "46782",
      open: "47786",
      time: "2021-09-17T00:00:00.000Z",
      volume: "2492"
    }, {
      close: "48309",
      high: "48794",
      low: "47070",
      open: "47296",
      time: "2021-09-18T00:00:00.000Z",
      volume: "2046"
    }, {
      close: "47239",
      high: "48370",
      low: "46837",
      open: "48309",
      time: "2021-09-19T00:00:00.000Z",
      volume: "2277"
    }, {
      close: "43002",
      high: "47323",
      low: "42577",
      open: "47239",
      time: "2021-09-20T00:00:00.000Z",
      volume: "12625"
    }, {
      close: "40664",
      high: "43622.3",
      low: "39678",
      open: "43002",
      time: "2021-09-21T00:00:00.000Z",
      volume: "14955"
    }, {
      close: "43582.6",
      high: "43998",
      low: "40607",
      open: "40632.5",
      time: "2021-09-22T00:00:00.000Z",
      volume: "8433"
    }, {
      close: "44882",
      high: "44986",
      low: "43121",
      open: "43582.6",
      time: "2021-09-23T00:00:00.000Z",
      volume: "5286"
    }, {
      close: "42878",
      high: "45140",
      low: "40779",
      open: "44882",
      time: "2021-09-24T00:00:00.000Z",
      volume: "11762"
    }, {
      close: "42711",
      high: "42977",
      low: "41731",
      open: "42854",
      time: "2021-09-25T00:00:00.000Z",
      volume: "2960"
    }, {
      close: "43180",
      high: "43931",
      low: "40830",
      open: "42711",
      time: "2021-09-26T00:00:00.000Z",
      volume: "5465"
    }, {
      close: "42191",
      high: "44354",
      low: "42144",
      open: "43220.5",
      time: "2021-09-27T00:00:00.000Z",
      volume: "4264"
    }, {
      close: "41064",
      high: "42777",
      low: "40928",
      open: "42191",
      time: "2021-09-28T00:00:00.000Z",
      volume: "6159"
    }, {
      close: "41551",
      high: "42591",
      low: "40811",
      open: "41064",
      time: "2021-09-29T00:00:00.000Z",
      volume: "4388"
    }, {
      close: "43830",
      high: "44107",
      low: "41432",
      open: "41551",
      time: "2021-09-30T00:00:00.000Z",
      volume: "5107"
    }, {
      close: "48157",
      high: "48454",
      low: "43311",
      open: "43830",
      time: "2021-10-01T00:00:00.000Z",
      volume: "8320"
    }, {
      close: "47675",
      high: "48330",
      low: "47459",
      open: "48151",
      time: "2021-10-02T00:00:00.000Z",
      volume: "2062"
    }, {
      close: "48243",
      high: "49187",
      low: "47124",
      open: "47675",
      time: "2021-10-03T00:00:00.000Z",
      volume: "3603"
    }, {
      close: "49252",
      high: "49503",
      low: "46951",
      open: "48243",
      time: "2021-10-04T00:00:00.000Z",
      volume: "6189"
    }, {
      close: "51508",
      high: "51891.1",
      low: "49065",
      open: "49232",
      time: "2021-10-05T00:00:00.000Z",
      volume: "6681"
    }, {
      close: "55331",
      high: "55724",
      low: "50448",
      open: "51508",
      time: "2021-10-06T00:00:00.000Z",
      volume: "10522"
    }, {
      close: "53793",
      high: "55339",
      low: "53444",
      open: "55331",
      time: "2021-10-07T00:00:00.000Z",
      volume: "4945"
    }, {
      close: "53925",
      high: "56000",
      low: "53649",
      open: "53793",
      time: "2021-10-08T00:00:00.000Z",
      volume: "5482"
    }, {
      close: "54978",
      high: "55443.7",
      low: "53711",
      open: "53925",
      time: "2021-10-09T00:00:00.000Z",
      volume: "2441"
    }, {
      close: "54701",
      high: "56414",
      low: "54173",
      open: "54978",
      time: "2021-10-10T00:00:00.000Z",
      volume: "5837"
    }, {
      close: "57468",
      high: "57802.5",
      low: "54430",
      open: "54701",
      time: "2021-10-11T00:00:00.000Z",
      volume: "7511"
    }, {
      close: "55967",
      high: "57633",
      low: "54000",
      open: "57468",
      time: "2021-10-12T00:00:00.000Z",
      volume: "5938"
    }, {
      close: "57381",
      high: "57758",
      low: "54310",
      open: "55967",
      time: "2021-10-13T00:00:00.000Z",
      volume: "3091"
    }, {
      close: "57354",
      high: "58473",
      low: "56880",
      open: "57381",
      time: "2021-10-14T00:00:00.000Z",
      volume: "4594"
    }, {
      close: "61709",
      high: "62897",
      low: "56889",
      open: "57354",
      time: "2021-10-15T00:00:00.000Z",
      volume: "11882"
    }, {
      close: "60867.5",
      high: "62325",
      low: "60175",
      open: "61680",
      time: "2021-10-16T00:00:00.000Z",
      volume: "4282"
    }, {
      close: "61511",
      high: "61652",
      low: "59073",
      open: "60867.5",
      time: "2021-10-17T00:00:00.000Z",
      volume: "5142"
    }, {
      close: "62034",
      high: "62661",
      low: "59948",
      open: "61504",
      time: "2021-10-18T00:00:00.000Z",
      volume: "8559"
    }, {
      close: "64237",
      high: "64418",
      low: "61441.1",
      open: "62034",
      time: "2021-10-19T00:00:00.000Z",
      volume: "9146"
    }, {
      close: "65988",
      high: "66927",
      low: "63536",
      open: "64263",
      time: "2021-10-20T00:00:00.000Z",
      volume: "9260"
    }, {
      close: "62200",
      high: "66643",
      low: "62100",
      open: "65988",
      time: "2021-10-21T00:00:00.000Z",
      volume: "13369"
    }, {
      close: "60708",
      high: "63708",
      low: "60045",
      open: "62200",
      time: "2021-10-22T00:00:00.000Z",
      volume: "6352"
    }, {
      close: "61302",
      high: "61731",
      low: "59762",
      open: "60708",
      time: "2021-10-23T00:00:00.000Z",
      volume: "2710"
    }, {
      close: "60847",
      high: "61471",
      low: "59537",
      open: "61302",
      time: "2021-10-24T00:00:00.000Z",
      volume: "2876"
    }, {
      close: "63100",
      high: "63666",
      low: "60633",
      open: "60847",
      time: "2021-10-25T00:00:00.000Z",
      volume: "3714"
    }, {
      close: "60290",
      high: "63306",
      low: "59852",
      open: "63100",
      time: "2021-10-26T00:00:00.000Z",
      volume: "4748"
    }, {
      close: "58453",
      high: "61447.7",
      low: "58096.4",
      open: "60289",
      time: "2021-10-27T00:00:00.000Z",
      volume: "7135"
    }, {
      close: "60598",
      high: "62395",
      low: "57612",
      open: "58453",
      time: "2021-10-28T00:00:00.000Z",
      volume: "8065"
    }, {
      close: "62235",
      high: "62980",
      low: "60189.5",
      open: "60598",
      time: "2021-10-29T00:00:00.000Z",
      volume: "3923"
    }, {
      close: "61838.8",
      high: "62353",
      low: "60756",
      open: "62235",
      time: "2021-10-30T00:00:00.000Z",
      volume: "2239"
    }, {
      close: "61330",
      high: "62414",
      low: "60021",
      open: "61838.8",
      time: "2021-10-31T00:00:00.000Z",
      volume: "2418"
    }, {
      close: "60927",
      high: "62485",
      low: "59595.6",
      open: "61330",
      time: "2021-11-01T00:00:00.000Z",
      volume: "4812"
    }, {
      close: "63249",
      high: "64263.4",
      low: "60661",
      open: "60927",
      time: "2021-11-02T00:00:00.000Z",
      volume: "5201"
    }, {
      close: "62904",
      high: "63556",
      low: "60624",
      open: "63249",
      time: "2021-11-03T00:00:00.000Z",
      volume: "4764"
    }, {
      close: "61422.8",
      high: "63060.2",
      low: "60733",
      open: "62904",
      time: "2021-11-04T00:00:00.000Z",
      volume: "2931"
    }, {
      close: "61016.4",
      high: "62598",
      low: "60746.3",
      open: "61422.8",
      time: "2021-11-05T00:00:00.000Z",
      volume: "2255"
    }, {
      close: "61496",
      high: "61583",
      low: "60100",
      open: "61016.4",
      time: "2021-11-06T00:00:00.000Z",
      volume: "2931"
    }, {
      close: "63259.1",
      high: "63279",
      low: "61400",
      open: "61495",
      time: "2021-11-07T00:00:00.000Z",
      volume: "3233"
    }, {
      close: "67526",
      high: "67665",
      low: "63259.1",
      open: "63259.1",
      time: "2021-11-08T00:00:00.000Z",
      volume: "8981"
    }, {
      close: "66935",
      high: "68429",
      low: "66343",
      open: "67526",
      time: "2021-11-09T00:00:00.000Z",
      volume: "6463"
    }, {
      close: "64954",
      high: "68925",
      low: "63123",
      open: "66935",
      time: "2021-11-10T00:00:00.000Z",
      volume: "12230"
    }, {
      close: "64801",
      high: "65541",
      low: "64089",
      open: "64954",
      time: "2021-11-11T00:00:00.000Z",
      volume: "2864"
    }, {
      close: "64156",
      high: "65412",
      low: "62310",
      open: "64801",
      time: "2021-11-12T00:00:00.000Z",
      volume: "5118"
    }, {
      close: "64406",
      high: "64968.2",
      low: "63405",
      open: "64137",
      time: "2021-11-13T00:00:00.000Z",
      volume: "1868"
    }, {
      close: "65471",
      high: "65471",
      low: "63589",
      open: "64406",
      time: "2021-11-14T00:00:00.000Z",
      volume: "2192"
    }, {
      close: "63562",
      high: "66320",
      low: "63383.3",
      open: "65471",
      time: "2021-11-15T00:00:00.000Z",
      volume: "5271"
    }, {
      close: "60124",
      high: "63562",
      low: "58708",
      open: "63562",
      time: "2021-11-16T00:00:00.000Z",
      volume: "13067"
    }, {
      close: "60386.1",
      high: "60847",
      low: "58427",
      open: "60124",
      time: "2021-11-17T00:00:00.000Z",
      volume: "6722"
    }, {
      close: "57014",
      high: "60945",
      low: "56559",
      open: "60386.1",
      time: "2021-11-18T00:00:00.000Z",
      volume: "11539"
    }, {
      close: "58130",
      high: "58379",
      low: "55670",
      open: "56932",
      time: "2021-11-19T00:00:00.000Z",
      volume: "5178"
    }, {
      close: "59739",
      high: "59886",
      low: "57494.4",
      open: "58130",
      time: "2021-11-20T00:00:00.000Z",
      volume: "3132"
    }, {
      close: "58790",
      high: "60047",
      low: "58624",
      open: "59739",
      time: "2021-11-21T00:00:00.000Z",
      volume: "2440"
    }, {
      close: "56299",
      high: "59407",
      low: "55714",
      open: "58790",
      time: "2021-11-22T00:00:00.000Z",
      volume: "8830"
    }, {
      close: "57602",
      high: "57866.2",
      low: "55460",
      open: "56299",
      time: "2021-11-23T00:00:00.000Z",
      volume: "8556"
    }, {
      close: "57209",
      high: "57762",
      low: "55916.9",
      open: "57602",
      time: "2021-11-24T00:00:00.000Z",
      volume: "4893"
    }, {
      close: "58995",
      high: "59423.4",
      low: "57070",
      open: "57209",
      time: "2021-11-25T00:00:00.000Z",
      volume: "3980"
    }, {
      close: "53738",
      high: "59229",
      low: "53565",
      open: "58995",
      time: "2021-11-26T00:00:00.000Z",
      volume: "12190"
    }, {
      close: "54836",
      high: "55329",
      low: "53711",
      open: "53738",
      time: "2021-11-27T00:00:00.000Z",
      volume: "3094"
    }, {
      close: "57325",
      high: "57469",
      low: "53482",
      open: "54836",
      time: "2021-11-28T00:00:00.000Z",
      volume: "4935"
    }, {
      close: "57845",
      high: "58886",
      low: "56772",
      open: "57325",
      time: "2021-11-29T00:00:00.000Z",
      volume: "5067"
    }, {
      close: "56938",
      high: "59174",
      low: "55940",
      open: "57845",
      time: "2021-11-30T00:00:00.000Z",
      volume: "6473"
    }, {
      close: "57250",
      high: "59055",
      low: "56505",
      open: "56938",
      time: "2021-12-01T00:00:00.000Z",
      volume: "5181"
    }, {
      close: "56517",
      high: "57389",
      low: "55842",
      open: "57250",
      time: "2021-12-02T00:00:00.000Z",
      volume: "4407"
    }, {
      close: "53709",
      high: "57599",
      low: "52062",
      open: "56517",
      time: "2021-12-03T00:00:00.000Z",
      volume: "10729"
    }, {
      close: "49272",
      high: "53879",
      low: "42101",
      open: "53709",
      time: "2021-12-04T00:00:00.000Z",
      volume: "22576"
    }, {
      close: "49469",
      high: "49752",
      low: "47860",
      open: "49272",
      time: "2021-12-05T00:00:00.000Z",
      volume: "4781"
    }, {
      close: "50566.6",
      high: "50974",
      low: "47295",
      open: "49469",
      time: "2021-12-06T00:00:00.000Z",
      volume: "8045"
    }, {
      close: "50634",
      high: "51969",
      low: "50102",
      open: "50566.6",
      time: "2021-12-07T00:00:00.000Z",
      volume: "3906"
    }, {
      close: "50503.4",
      high: "51239",
      low: "48760",
      open: "50634",
      time: "2021-12-08T00:00:00.000Z",
      volume: "4034"
    }, {
      close: "47611",
      high: "50824",
      low: "47408",
      open: "50503.4",
      time: "2021-12-09T00:00:00.000Z",
      volume: "5275"
    }, {
      close: "47170",
      high: "50105",
      low: "47060",
      open: "47611",
      time: "2021-12-10T00:00:00.000Z",
      volume: "8169"
    }, {
      close: "49363.1",
      high: "49510",
      low: "46897",
      open: "47170",
      time: "2021-12-11T00:00:00.000Z",
      volume: "5289"
    }, {
      close: "50123",
      high: "50800",
      low: "48692",
      open: "49363.1",
      time: "2021-12-12T00:00:00.000Z",
      volume: "3870"
    }, {
      close: "46777",
      high: "50222.7",
      low: "45790",
      open: "50123",
      time: "2021-12-13T00:00:00.000Z",
      volume: "8712"
    }, {
      close: "48408",
      high: "48656",
      low: "46356.2",
      open: "46777",
      time: "2021-12-14T00:00:00.000Z",
      volume: "5475"
    }, {
      close: "48901",
      high: "49520",
      low: "46602",
      open: "48408",
      time: "2021-12-15T00:00:00.000Z",
      volume: "7035"
    }, {
      close: "47675",
      high: "49462.1",
      low: "47561",
      open: "48901",
      time: "2021-12-16T00:00:00.000Z",
      volume: "3526"
    }, {
      close: "46193",
      high: "48028.8",
      low: "45539",
      open: "47675",
      time: "2021-12-17T00:00:00.000Z",
      volume: "5855"
    }, {
      close: "46879",
      high: "47364",
      low: "45543.5",
      open: "46193",
      time: "2021-12-18T00:00:00.000Z",
      volume: "3740"
    }, {
      close: "46716.5",
      high: "48283",
      low: "46474.9",
      open: "46879",
      time: "2021-12-19T00:00:00.000Z",
      volume: "5235"
    }, {
      close: "46898",
      high: "47541",
      low: "45606",
      open: "46716.5",
      time: "2021-12-20T00:00:00.000Z",
      volume: "5653"
    }, {
      close: "48923",
      high: "49352",
      low: "46683",
      open: "46898",
      time: "2021-12-21T00:00:00.000Z",
      volume: "4712"
    }, {
      close: "48626",
      high: "49575.1",
      low: "48459.8",
      open: "48923",
      time: "2021-12-22T00:00:00.000Z",
      volume: "3142"
    }, {
      close: "50812",
      high: "51376",
      low: "48078",
      open: "48626",
      time: "2021-12-23T00:00:00.000Z",
      volume: "4431"
    }, {
      close: "50815",
      high: "51834",
      low: "50439",
      open: "50833",
      time: "2021-12-24T00:00:00.000Z",
      volume: "2595"
    }, {
      close: "50437",
      high: "51169",
      low: "50209",
      open: "50815",
      time: "2021-12-25T00:00:00.000Z",
      volume: "1989"
    }, {
      close: "50797",
      high: "51281",
      low: "49473",
      open: "50435.2",
      time: "2021-12-26T00:00:00.000Z",
      volume: "2904"
    }, {
      close: "50684",
      high: "52027",
      low: "50483",
      open: "50797",
      time: "2021-12-27T00:00:00.000Z",
      volume: "3518"
    }, {
      close: "47554",
      high: "50710",
      low: "47339",
      open: "50684",
      time: "2021-12-28T00:00:00.000Z",
      volume: "5431"
    }, {
      close: "46465",
      high: "48166",
      low: "46129",
      open: "47554",
      time: "2021-12-29T00:00:00.000Z",
      volume: "4123"
    }, {
      close: "47156",
      high: "47911",
      low: "45996",
      open: "46465",
      time: "2021-12-30T00:00:00.000Z",
      volume: "3197"
    }, {
      close: "46218",
      high: "48589",
      low: "45697",
      open: "47156",
      time: "2021-12-31T00:00:00.000Z",
      volume: "4252"
    }, {
      close: "47755",
      high: "47954",
      low: "46215",
      open: "46218",
      time: "2022-01-01T00:00:00.000Z",
      volume: "2177"
    }, {
      close: "47314",
      high: "47980",
      low: "46698",
      open: "47755",
      time: "2022-01-02T00:00:00.000Z",
      volume: "1841"
    }, {
      close: "46445",
      high: "47578",
      low: "45701",
      open: "47314",
      time: "2022-01-03T00:00:00.000Z",
      volume: "2577"
    }, {
      close: "45830",
      high: "47546",
      low: "45583",
      open: "46445",
      time: "2022-01-04T00:00:00.000Z",
      volume: "3788"
    }, {
      close: "43418",
      high: "47056.7",
      low: "42519",
      open: "45830",
      time: "2022-01-05T00:00:00.000Z",
      volume: "6930"
    }, {
      close: "43111",
      high: "43777",
      low: "42487",
      open: "43418",
      time: "2022-01-06T00:00:00.000Z",
      volume: "3405"
    }, {
      close: "41518",
      high: "43132",
      low: "40755",
      open: "43111",
      time: "2022-01-07T00:00:00.000Z",
      volume: "7404"
    }, {
      close: "41679",
      high: "42319",
      low: "40570",
      open: "41518",
      time: "2022-01-08T00:00:00.000Z",
      volume: "4145"
    }, {
      close: "41850",
      high: "42790",
      low: "41275",
      open: "41679",
      time: "2022-01-09T00:00:00.000Z",
      volume: "3715"
    }, {
      close: "41839",
      high: "42270",
      low: "39677",
      open: "41850",
      time: "2022-01-10T00:00:00.000Z",
      volume: "9553"
    }, {
      close: "42743",
      high: "43113",
      low: "41287",
      open: "41839",
      time: "2022-01-11T00:00:00.000Z",
      volume: "4787"
    }, {
      close: "43909",
      high: "44305",
      low: "42475",
      open: "42743",
      time: "2022-01-12T00:00:00.000Z",
      volume: "3769"
    }, {
      close: "42562",
      high: "44426.7",
      low: "42334",
      open: "43909",
      time: "2022-01-13T00:00:00.000Z",
      volume: "3740"
    }, {
      close: "43091",
      high: "43463",
      low: "41839",
      open: "42562",
      time: "2022-01-14T00:00:00.000Z",
      volume: "4314"
    }, {
      close: "43069",
      high: "43806",
      low: "42600",
      open: "43091",
      time: "2022-01-15T00:00:00.000Z",
      volume: "3195"
    }, {
      close: "43084",
      high: "43487",
      low: "42611.8",
      open: "43069",
      time: "2022-01-16T00:00:00.000Z",
      volume: "1581"
    }, {
      close: "42242",
      high: "43191",
      low: "41568",
      open: "43084",
      time: "2022-01-17T00:00:00.000Z",
      volume: "2920"
    }, {
      close: "42390",
      high: "42682",
      low: "41304",
      open: "42223",
      time: "2022-01-18T00:00:00.000Z",
      volume: "4340"
    }, {
      close: "41676",
      high: "42581.2",
      low: "41180",
      open: "42390",
      time: "2022-01-19T00:00:00.000Z",
      volume: "6452"
    }, {
      close: "40740",
      high: "43540.6",
      low: "40572",
      open: "41676",
      time: "2022-01-20T00:00:00.000Z",
      volume: "5111"
    }, {
      close: "36501",
      high: "41132",
      low: "35524",
      open: "40740",
      time: "2022-01-21T00:00:00.000Z",
      volume: "16222"
    }, {
      close: "35103",
      high: "36816",
      low: "34079",
      open: "36467",
      time: "2022-01-22T00:00:00.000Z",
      volume: "17582"
    }, {
      close: "36308",
      high: "36550",
      low: "34687.3",
      open: "35103",
      time: "2022-01-23T00:00:00.000Z",
      volume: "8715"
    }, {
      close: "35809",
      high: "36308",
      low: "35692",
      open: "36308",
      time: "2022-01-24T00:00:00.000Z",
      volume: "8743"
    }]
  },
  bnb: {
    id: 'bnb',
    name: 'BNB',
    symbol: 'BNB',
    image: 'assets/images/coin-logo/BNB.png',
    activity24h: 9974589345,
    availableAmount: 508.232323,
    usdxConversion: .7,
    allTimeValue: 943812390,
    allTimeAsset: 3421,
    candleData: [{
      close: "302.58",
      high: "303.09",
      low: "292.87",
      open: "302.55",
      time: "2021-07-25T00:00:00.000Z",
      volume: "1083435"
    }, {
      close: "304.24",
      high: "327.93",
      low: "301.2",
      open: "302.88",
      time: "2021-07-26T00:00:00.000Z",
      volume: "2575570"
    }, {
      close: "313.7",
      high: "318.24",
      low: "298.54",
      open: "303.84",
      time: "2021-07-27T00:00:00.000Z",
      volume: "1677216"
    }, {
      close: "313.79",
      high: "318.65",
      low: "308.53",
      open: "313.24",
      time: "2021-07-28T00:00:00.000Z",
      volume: "1309345"
    }, {
      close: "316.72",
      high: "319.99",
      low: "309",
      open: "313.63",
      time: "2021-07-29T00:00:00.000Z",
      volume: "999159"
    }, {
      close: "322.66",
      high: "325.27",
      low: "306.72",
      open: "317.06",
      time: "2021-07-30T00:00:00.000Z",
      volume: "1297446"
    }, {
      close: "332.44",
      high: "337.98",
      low: "317.6",
      open: "323.34",
      time: "2021-07-31T00:00:00.000Z",
      volume: "1390329"
    }, {
      close: "332.77",
      high: "347.47",
      low: "329.71",
      open: "332.38",
      time: "2021-08-01T00:00:00.000Z",
      volume: "1514849"
    }, {
      close: "330.86",
      high: "338.11",
      low: "326.38",
      open: "332.55",
      time: "2021-08-02T00:00:00.000Z",
      volume: "1018782"
    }, {
      close: "323.2",
      high: "334.64",
      low: "317.66",
      open: "330.4",
      time: "2021-08-03T00:00:00.000Z",
      volume: "1060730"
    }, {
      close: "334.9",
      high: "337.05",
      low: "319.97",
      open: "323.42",
      time: "2021-08-04T00:00:00.000Z",
      volume: "969678"
    }, {
      close: "337.91",
      high: "339.98",
      low: "323.71",
      open: "334.99",
      time: "2021-08-05T00:00:00.000Z",
      volume: "1263655"
    }, {
      close: "342.08",
      high: "347.01",
      low: "331.42",
      open: "337.94",
      time: "2021-08-06T00:00:00.000Z",
      volume: "1267308"
    }, {
      close: "356.18",
      high: "359.6",
      low: "340.65",
      open: "342.32",
      time: "2021-08-07T00:00:00.000Z",
      volume: "1545809"
    }, {
      close: "341.83",
      high: "359.79",
      low: "337.08",
      open: "356.41",
      time: "2021-08-08T00:00:00.000Z",
      volume: "1275662"
    }, {
      close: "354.49",
      high: "357.35",
      low: "333.52",
      open: "342.1",
      time: "2021-08-09T00:00:00.000Z",
      volume: "1285113"
    }, {
      close: "371.91",
      high: "376.22",
      low: "348.95",
      open: "354.31",
      time: "2021-08-10T00:00:00.000Z",
      volume: "1660852"
    }, {
      close: "391.48",
      high: "408.19",
      low: "371.11",
      open: "371.81",
      time: "2021-08-11T00:00:00.000Z",
      volume: "1911488"
    }, {
      close: "385.51",
      high: "406.11",
      low: "371.8",
      open: "390.8",
      time: "2021-08-12T00:00:00.000Z",
      volume: "1835463"
    }, {
      close: "410.9",
      high: "411.04",
      low: "383",
      open: "385.94",
      time: "2021-08-13T00:00:00.000Z",
      volume: "1261882"
    }, {
      close: "408.91",
      high: "418.79",
      low: "400.44",
      open: "410.82",
      time: "2021-08-14T00:00:00.000Z",
      volume: "1439361"
    }, {
      close: "414.1",
      high: "414.9",
      low: "396.63",
      open: "409.13",
      time: "2021-08-15T00:00:00.000Z",
      volume: "1238127"
    }, {
      close: "416.08",
      high: "436.65",
      low: "411.04",
      open: "414.67",
      time: "2021-08-16T00:00:00.000Z",
      volume: "1850372"
    }, {
      close: "401.96",
      high: "435.65",
      low: "399.37",
      open: "415.57",
      time: "2021-08-17T00:00:00.000Z",
      volume: "1984017"
    }, {
      close: "396.44",
      high: "412.08",
      low: "386.62",
      open: "401.58",
      time: "2021-08-18T00:00:00.000Z",
      volume: "1749883"
    }, {
      close: "431.41",
      high: "431.44",
      low: "391.06",
      open: "397.21",
      time: "2021-08-19T00:00:00.000Z",
      volume: "1853847"
    }, {
      close: "454.88",
      high: "457.55",
      low: "421.97",
      open: "431.11",
      time: "2021-08-20T00:00:00.000Z",
      volume: "1691374"
    }, {
      close: "448.63",
      high: "459.99",
      low: "444.8",
      open: "454.9",
      time: "2021-08-21T00:00:00.000Z",
      volume: "1426782"
    }, {
      close: "448.67",
      high: "458.25",
      low: "438.01",
      open: "448.62",
      time: "2021-08-22T00:00:00.000Z",
      volume: "1135556"
    }, {
      close: "499.8",
      high: "508.85",
      low: "447",
      open: "448.43",
      time: "2021-08-23T00:00:00.000Z",
      volume: "2660781"
    }, {
      close: "472.1",
      high: "504.06",
      low: "461.27",
      open: "498.63",
      time: "2021-08-24T00:00:00.000Z",
      volume: "2080221"
    }, {
      close: "503.46",
      high: "506.85",
      low: "470.1",
      open: "471.42",
      time: "2021-08-25T00:00:00.000Z",
      volume: "2243091"
    }, {
      close: "477.8",
      high: "518.6",
      low: "472.7",
      open: "503.11",
      time: "2021-08-26T00:00:00.000Z",
      volume: "2229193"
    }, {
      close: "494.5",
      high: "494.9",
      low: "467.5",
      open: "478.2",
      time: "2021-08-27T00:00:00.000Z",
      volume: "1550195"
    }, {
      close: "486",
      high: "496.8",
      low: "482.7",
      open: "494.6",
      time: "2021-08-28T00:00:00.000Z",
      volume: "891984"
    }, {
      close: "479.8",
      high: "495.5",
      low: "476.8",
      open: "486.3",
      time: "2021-08-29T00:00:00.000Z",
      volume: "910775"
    }, {
      close: "460.1",
      high: "480.9",
      low: "457.4",
      open: "479.4",
      time: "2021-08-30T00:00:00.000Z",
      volume: "1125345"
    }, {
      close: "464.3",
      high: "477.1",
      low: "452.5",
      open: "459.3",
      time: "2021-08-31T00:00:00.000Z",
      volume: "1477687"
    }, {
      close: "489.4",
      high: "490",
      low: "455.5",
      open: "463.1",
      time: "2021-09-01T00:00:00.000Z",
      volume: "1320640"
    }, {
      close: "483.7",
      high: "504.6",
      low: "478.1",
      open: "489",
      time: "2021-09-02T00:00:00.000Z",
      volume: "1245658"
    }, {
      close: "488.5",
      high: "494.5",
      low: "473.4",
      open: "483.6",
      time: "2021-09-03T00:00:00.000Z",
      volume: "1094115"
    }, {
      close: "497.4",
      high: "509.8",
      low: "485.6",
      open: "487.9",
      time: "2021-09-04T00:00:00.000Z",
      volume: "1248324"
    }, {
      close: "504.5",
      high: "505.2",
      low: "488.9",
      open: "497.2",
      time: "2021-09-05T00:00:00.000Z",
      volume: "877957"
    }, {
      close: "496.1",
      high: "508.9",
      low: "487.8",
      open: "505.2",
      time: "2021-09-06T00:00:00.000Z",
      volume: "1121786"
    }, {
      close: "417.3",
      high: "504.6",
      low: "373.9",
      open: "496.1",
      time: "2021-09-07T00:00:00.000Z",
      volume: "3358462"
    }, {
      close: "414.6",
      high: "425.5",
      low: "383.7",
      open: "418.7",
      time: "2021-09-08T00:00:00.000Z",
      volume: "2058596"
    }, {
      close: "421.2",
      high: "435.6",
      low: "404.1",
      open: "415.2",
      time: "2021-09-09T00:00:00.000Z",
      volume: "1543339"
    }, {
      close: "401.1",
      high: "438.9",
      low: "393.9",
      open: "421.3",
      time: "2021-09-10T00:00:00.000Z",
      volume: "1528887"
    }, {
      close: "405.1",
      high: "415.1",
      low: "399.5",
      open: "401.6",
      time: "2021-09-11T00:00:00.000Z",
      volume: "922645"
    }, {
      close: "417",
      high: "421.3",
      low: "398",
      open: "404.5",
      time: "2021-09-12T00:00:00.000Z",
      volume: "981362"
    }, {
      close: "399",
      high: "419.4",
      low: "385.3",
      open: "416.3",
      time: "2021-09-13T00:00:00.000Z",
      volume: "1531982"
    }, {
      close: "414.3",
      high: "414.3",
      low: "396.1",
      open: "399.1",
      time: "2021-09-14T00:00:00.000Z",
      volume: "930333"
    }, {
      close: "431.2",
      high: "436.4",
      low: "410.1",
      open: "414.4",
      time: "2021-09-15T00:00:00.000Z",
      volume: "1182055"
    }, {
      close: "424.2",
      high: "434",
      low: "416",
      open: "431.4",
      time: "2021-09-16T00:00:00.000Z",
      volume: "1037063"
    }, {
      close: "406.2",
      high: "425.8",
      low: "401.4",
      open: "424.7",
      time: "2021-09-17T00:00:00.000Z",
      volume: "844042"
    }, {
      close: "410.7",
      high: "419.9",
      low: "401.6",
      open: "407",
      time: "2021-09-18T00:00:00.000Z",
      volume: "733269"
    }, {
      close: "408.4",
      high: "419",
      low: "403.4",
      open: "410.5",
      time: "2021-09-19T00:00:00.000Z",
      volume: "743181"
    }, {
      close: "363.6",
      high: "409.6",
      low: "355.3",
      open: "408.9",
      time: "2021-09-20T00:00:00.000Z",
      volume: "1865275"
    }, {
      close: "343.3",
      high: "375.7",
      low: "337.3",
      open: "364.2",
      time: "2021-09-21T00:00:00.000Z",
      volume: "1575591"
    }, {
      close: "378.8",
      high: "379.9",
      low: "342.4",
      open: "344.7",
      time: "2021-09-22T00:00:00.000Z",
      volume: "1086200"
    }, {
      close: "383.6",
      high: "384.5",
      low: "369.2",
      open: "378.8",
      time: "2021-09-23T00:00:00.000Z",
      volume: "993852"
    }, {
      close: "355.1",
      high: "385",
      low: "334.6",
      open: "383.5",
      time: "2021-09-24T00:00:00.000Z",
      volume: "1719014"
    }, {
      close: "349.5",
      high: "360.5",
      low: "343.7",
      open: "355",
      time: "2021-09-25T00:00:00.000Z",
      volume: "916866"
    }, {
      close: "344.6",
      high: "352.6",
      low: "320.1",
      open: "349.5",
      time: "2021-09-26T00:00:00.000Z",
      volume: "1538635"
    }, {
      close: "335.3",
      high: "357.5",
      low: "335.3",
      open: "343.8",
      time: "2021-09-27T00:00:00.000Z",
      volume: "956607"
    }, {
      close: "332.9",
      high: "344.4",
      low: "330",
      open: "335.2",
      time: "2021-09-28T00:00:00.000Z",
      volume: "922413"
    }, {
      close: "367.5",
      high: "375",
      low: "331.6",
      open: "332.9",
      time: "2021-09-29T00:00:00.000Z",
      volume: "1696612"
    }, {
      close: "387.6",
      high: "388.5",
      low: "366.5",
      open: "367.6",
      time: "2021-09-30T00:00:00.000Z",
      volume: "1163467"
    }, {
      close: "421.3",
      high: "423.3",
      low: "381.8",
      open: "387.9",
      time: "2021-10-01T00:00:00.000Z",
      volume: "1440354"
    }, {
      close: "427.2",
      high: "438",
      low: "410.8",
      open: "420.6",
      time: "2021-10-02T00:00:00.000Z",
      volume: "1039069"
    }, {
      close: "430.3",
      high: "436.9",
      low: "422.4",
      open: "427",
      time: "2021-10-03T00:00:00.000Z",
      volume: "821007"
    }, {
      close: "426.5",
      high: "430.9",
      low: "411.6",
      open: "430.5",
      time: "2021-10-04T00:00:00.000Z",
      volume: "974416"
    }, {
      close: "442.2",
      high: "443.9",
      low: "424.2",
      open: "426.4",
      time: "2021-10-05T00:00:00.000Z",
      volume: "883633"
    }, {
      close: "434.8",
      high: "442.8",
      low: "414.9",
      open: "442.1",
      time: "2021-10-06T00:00:00.000Z",
      volume: "1245148"
    }, {
      close: "438.2",
      high: "449.9",
      low: "424.1",
      open: "435",
      time: "2021-10-07T00:00:00.000Z",
      volume: "1158859"
    }, {
      close: "418.7",
      high: "441.6",
      low: "416",
      open: "438.2",
      time: "2021-10-08T00:00:00.000Z",
      volume: "1506029"
    }, {
      close: "421.7",
      high: "425.9",
      low: "416.5",
      open: "419.7",
      time: "2021-10-09T00:00:00.000Z",
      volume: "632349"
    }, {
      close: "404.2",
      high: "422.2",
      low: "402.4",
      open: "422.2",
      time: "2021-10-10T00:00:00.000Z",
      volume: "729363"
    }, {
      close: "413.4",
      high: "423.9",
      low: "400.4",
      open: "403.7",
      time: "2021-10-11T00:00:00.000Z",
      volume: "759886"
    }, {
      close: "444.4",
      high: "444.4",
      low: "392.5",
      open: "413",
      time: "2021-10-12T00:00:00.000Z",
      volume: "1966537"
    }, {
      close: "470.4",
      high: "471.6",
      low: "434.1",
      open: "443.9",
      time: "2021-10-13T00:00:00.000Z",
      volume: "3384595"
    }, {
      close: "472.4",
      high: "482.6",
      low: "461.8",
      open: "471",
      time: "2021-10-14T00:00:00.000Z",
      volume: "1431619"
    }, {
      close: "474.3",
      high: "477.4",
      low: "452.9",
      open: "472.7",
      time: "2021-10-15T00:00:00.000Z",
      volume: "1508268"
    }, {
      close: "465.1",
      high: "484.4",
      low: "462.5",
      open: "474.8",
      time: "2021-10-16T00:00:00.000Z",
      volume: "1040453"
    }, {
      close: "470.7",
      high: "472.4",
      low: "455.9",
      open: "465.8",
      time: "2021-10-17T00:00:00.000Z",
      volume: "889877"
    }, {
      close: "485",
      high: "487.2",
      low: "467.2",
      open: "470.9",
      time: "2021-10-18T00:00:00.000Z",
      volume: "1091022"
    }, {
      close: "488.1",
      high: "497.4",
      low: "481.1",
      open: "484.9",
      time: "2021-10-19T00:00:00.000Z",
      volume: "912953"
    }, {
      close: "501.1",
      high: "505.6",
      low: "482.4",
      open: "488.1",
      time: "2021-10-20T00:00:00.000Z",
      volume: "946054"
    }, {
      close: "471.1",
      high: "504.8",
      low: "467.3",
      open: "501.3",
      time: "2021-10-21T00:00:00.000Z",
      volume: "1851399"
    }, {
      close: "478.8",
      high: "497.9",
      low: "470.9",
      open: "470.9",
      time: "2021-10-22T00:00:00.000Z",
      volume: "1118447"
    }, {
      close: "485.4",
      high: "485.4",
      low: "472.9",
      open: "479.2",
      time: "2021-10-23T00:00:00.000Z",
      volume: "522392"
    }, {
      close: "475.8",
      high: "486.4",
      low: "468",
      open: "485.6",
      time: "2021-10-24T00:00:00.000Z",
      volume: "591037"
    }, {
      close: "484.9",
      high: "488.6",
      low: "474.8",
      open: "475.7",
      time: "2021-10-25T00:00:00.000Z",
      volume: "632112"
    }, {
      close: "478",
      high: "489.6",
      low: "474.2",
      open: "484.9",
      time: "2021-10-26T00:00:00.000Z",
      volume: "702619"
    }, {
      close: "448.7",
      high: "486.3",
      low: "438.5",
      open: "478.2",
      time: "2021-10-27T00:00:00.000Z",
      volume: "1370827"
    }, {
      close: "492.1",
      high: "494.7",
      low: "446.6",
      open: "448.9",
      time: "2021-10-28T00:00:00.000Z",
      volume: "1523871"
    }, {
      close: "529.5",
      high: "533.6",
      low: "489",
      open: "492.1",
      time: "2021-10-29T00:00:00.000Z",
      volume: "1411390"
    }, {
      close: "528.2",
      high: "540.3",
      low: "512.2",
      open: "530.4",
      time: "2021-10-30T00:00:00.000Z",
      volume: "1095028"
    }, {
      close: "524.6",
      high: "534.9",
      low: "509.3",
      open: "529.3",
      time: "2021-10-31T00:00:00.000Z",
      volume: "1018970"
    }, {
      close: "551.5",
      high: "551.9",
      low: "511.8",
      open: "525.3",
      time: "2021-11-01T00:00:00.000Z",
      volume: "1274337"
    }, {
      close: "554.3",
      high: "563.7",
      low: "537.6",
      open: "551.2",
      time: "2021-11-02T00:00:00.000Z",
      volume: "1153773"
    }, {
      close: "568.4",
      high: "569.9",
      low: "537.6",
      open: "554.3",
      time: "2021-11-03T00:00:00.000Z",
      volume: "1262699"
    }, {
      close: "558.7",
      high: "568.7",
      low: "543.8",
      open: "568.5",
      time: "2021-11-04T00:00:00.000Z",
      volume: "1052931"
    }, {
      close: "607.3",
      high: "614.6",
      low: "554.4",
      open: "559.6",
      time: "2021-11-05T00:00:00.000Z",
      volume: "1989811"
    }, {
      close: "636.3",
      high: "636.3",
      low: "596",
      open: "607.1",
      time: "2021-11-06T00:00:00.000Z",
      volume: "1364788"
    }, {
      close: "649.5",
      high: "668.8",
      low: "630.9",
      open: "637.7",
      time: "2021-11-07T00:00:00.000Z",
      volume: "1476664"
    }, {
      close: "653.8",
      high: "655.5",
      low: "631.7",
      open: "649.5",
      time: "2021-11-08T00:00:00.000Z",
      volume: "1290047"
    }, {
      close: "634.9",
      high: "656.7",
      low: "632.1",
      open: "653.6",
      time: "2021-11-09T00:00:00.000Z",
      volume: "879127"
    }, {
      close: "614.8",
      high: "662.6",
      low: "575.7",
      open: "634.4",
      time: "2021-11-10T00:00:00.000Z",
      volume: "1814197"
    }, {
      close: "628.8",
      high: "634.8",
      low: "606",
      open: "614.1",
      time: "2021-11-11T00:00:00.000Z",
      volume: "1020203"
    }, {
      close: "626.9",
      high: "634.5",
      low: "603.9",
      open: "627.9",
      time: "2021-11-12T00:00:00.000Z",
      volume: "1013017"
    }, {
      close: "649.5",
      high: "654.7",
      low: "620.8",
      open: "627.8",
      time: "2021-11-13T00:00:00.000Z",
      volume: "900092"
    }, {
      close: "650.8",
      high: "660.2",
      low: "634.8",
      open: "649.9",
      time: "2021-11-14T00:00:00.000Z",
      volume: "933707"
    }, {
      close: "633.5",
      high: "659.2",
      low: "631.3",
      open: "651.5",
      time: "2021-11-15T00:00:00.000Z",
      volume: "954069"
    }, {
      close: "588.9",
      high: "634.5",
      low: "574",
      open: "633.8",
      time: "2021-11-16T00:00:00.000Z",
      volume: "2200880"
    }, {
      close: "578.3",
      high: "592.5",
      low: "554.6",
      open: "589.2",
      time: "2021-11-17T00:00:00.000Z",
      volume: "1453365"
    }, {
      close: "531.9",
      high: "587.9",
      low: "517.1",
      open: "578.2",
      time: "2021-11-18T00:00:00.000Z",
      volume: "1750339"
    }, {
      close: "581",
      high: "585.4",
      low: "524.7",
      open: "532.6",
      time: "2021-11-19T00:00:00.000Z",
      volume: "1300809"
    }, {
      close: "604.3",
      high: "604.7",
      low: "573.6",
      open: "580.7",
      time: "2021-11-20T00:00:00.000Z",
      volume: "1119502"
    }, {
      close: "585.2",
      high: "604.9",
      low: "580.1",
      open: "604.6",
      time: "2021-11-21T00:00:00.000Z",
      volume: "970169"
    }, {
      close: "559.7",
      high: "584.7",
      low: "554.3",
      open: "584.3",
      time: "2021-11-22T00:00:00.000Z",
      volume: "1141146"
    }, {
      close: "591.3",
      high: "604.8",
      low: "558.3",
      open: "559.3",
      time: "2021-11-23T00:00:00.000Z",
      volume: "1202416"
    }, {
      close: "590.6",
      high: "593.9",
      low: "577.4",
      open: "591.1",
      time: "2021-11-24T00:00:00.000Z",
      volume: "1079709"
    }, {
      close: "638.8",
      high: "646.5",
      low: "587.7",
      open: "590.9",
      time: "2021-11-25T00:00:00.000Z",
      volume: "1536313"
    }, {
      close: "587.7",
      high: "657.7",
      low: "564.6",
      open: "639.2",
      time: "2021-11-26T00:00:00.000Z",
      volume: "2413501"
    }, {
      close: "600.3",
      high: "621.2",
      low: "584.3",
      open: "587.5",
      time: "2021-11-27T00:00:00.000Z",
      volume: "1183853"
    }, {
      close: "611.6",
      high: "612.6",
      low: "574",
      open: "598.9",
      time: "2021-11-28T00:00:00.000Z",
      volume: "1280973"
    }, {
      close: "623.6",
      high: "628.6",
      low: "602",
      open: "611.9",
      time: "2021-11-29T00:00:00.000Z",
      volume: "1090773"
    }, {
      close: "621.5",
      high: "634.5",
      low: "603.7",
      open: "624.5",
      time: "2021-11-30T00:00:00.000Z",
      volume: "1380560"
    }, {
      close: "627.3",
      high: "649.6",
      low: "618.7",
      open: "622.7",
      time: "2021-12-01T00:00:00.000Z",
      volume: "1384234"
    }, {
      close: "620.2",
      high: "629.7",
      low: "605.6",
      open: "626.7",
      time: "2021-12-02T00:00:00.000Z",
      volume: "1104625"
    }, {
      close: "594.6",
      high: "627.8",
      low: "576.9",
      open: "620.1",
      time: "2021-12-03T00:00:00.000Z",
      volume: "1250409"
    }, {
      close: "568.6",
      high: "595.9",
      low: "495.2",
      open: "594.9",
      time: "2021-12-04T00:00:00.000Z",
      volume: "2611902"
    }, {
      close: "557.6",
      high: "581.8",
      low: "542.7",
      open: "568.8",
      time: "2021-12-05T00:00:00.000Z",
      volume: "1258583"
    }, {
      close: "588.6",
      high: "592.6",
      low: "533.3",
      open: "557.9",
      time: "2021-12-06T00:00:00.000Z",
      volume: "2005277"
    }, {
      close: "578.7",
      high: "593.4",
      low: "572.3",
      open: "587.8",
      time: "2021-12-07T00:00:00.000Z",
      volume: "1017772"
    }, {
      close: "606.9",
      high: "607.5",
      low: "567.5",
      open: "578.7",
      time: "2021-12-08T00:00:00.000Z",
      volume: "1112354"
    }, {
      close: "570.8",
      high: "616.2",
      low: "567.9",
      open: "607.7",
      time: "2021-12-09T00:00:00.000Z",
      volume: "1097412"
    }, {
      close: "552.6",
      high: "594.9",
      low: "551.5",
      open: "570.3",
      time: "2021-12-10T00:00:00.000Z",
      volume: "1082758"
    }, {
      close: "563.2",
      high: "572.1",
      low: "542.3",
      open: "551.8",
      time: "2021-12-11T00:00:00.000Z",
      volume: "951110"
    }, {
      close: "570.5",
      high: "574",
      low: "559.5",
      open: "564.9",
      time: "2021-12-12T00:00:00.000Z",
      volume: "544947"
    }, {
      close: "520.9",
      high: "572.1",
      low: "512.1",
      open: "570.1",
      time: "2021-12-13T00:00:00.000Z",
      volume: "1037170"
    }, {
      close: "528.3",
      high: "535.7",
      low: "510.2",
      open: "521",
      time: "2021-12-14T00:00:00.000Z",
      volume: "1263623"
    }, {
      close: "541.1",
      high: "546.2",
      low: "502.1",
      open: "528.2",
      time: "2021-12-15T00:00:00.000Z",
      volume: "1147658"
    }, {
      close: "526",
      high: "545",
      low: "525.3",
      open: "541.7",
      time: "2021-12-16T00:00:00.000Z",
      volume: "735441"
    }, {
      close: "525.9",
      high: "535.4",
      low: "511.3",
      open: "526",
      time: "2021-12-17T00:00:00.000Z",
      volume: "921846"
    }, {
      close: "534.2",
      high: "537.7",
      low: "516.7",
      open: "525.9",
      time: "2021-12-18T00:00:00.000Z",
      volume: "580371"
    }, {
      close: "529.9",
      high: "544.2",
      low: "527.5",
      open: "533.8",
      time: "2021-12-19T00:00:00.000Z",
      volume: "540346"
    }, {
      close: "523.3",
      high: "534.6",
      low: "506.3",
      open: "529.6",
      time: "2021-12-20T00:00:00.000Z",
      volume: "727321"
    }, {
      close: "528.5",
      high: "535.2",
      low: "517.5",
      open: "524.9",
      time: "2021-12-21T00:00:00.000Z",
      volume: "686760"
    }, {
      close: "533.4",
      high: "544.5",
      low: "527.4",
      open: "528.3",
      time: "2021-12-22T00:00:00.000Z",
      volume: "721671"
    }, {
      close: "548.7",
      high: "552.3",
      low: "524.7",
      open: "533.5",
      time: "2021-12-23T00:00:00.000Z",
      volume: "844255"
    }, {
      close: "541.8",
      high: "554.1",
      low: "538",
      open: "549",
      time: "2021-12-24T00:00:00.000Z",
      volume: "665677"
    }, {
      close: "547.3",
      high: "550.4",
      low: "539.7",
      open: "542.1",
      time: "2021-12-25T00:00:00.000Z",
      volume: "455662"
    }, {
      close: "546.5",
      high: "549.2",
      low: "536.4",
      open: "547.4",
      time: "2021-12-26T00:00:00.000Z",
      volume: "536332"
    }, {
      close: "563.6",
      high: "571.6",
      low: "543.3",
      open: "546.5",
      time: "2021-12-27T00:00:00.000Z",
      volume: "803488"
    }, {
      close: "534.2",
      high: "563.3",
      low: "529.3",
      open: "563",
      time: "2021-12-28T00:00:00.000Z",
      volume: "1002602"
    }, {
      close: "514.5",
      high: "540.4",
      low: "511.2",
      open: "535",
      time: "2021-12-29T00:00:00.000Z",
      volume: "740117"
    }, {
      close: "518.5",
      high: "527.6",
      low: "508.8",
      open: "514.8",
      time: "2021-12-30T00:00:00.000Z",
      volume: "639344"
    }, {
      close: "511.7",
      high: "528.1",
      low: "507.1",
      open: "517.5",
      time: "2021-12-31T00:00:00.000Z",
      volume: "677855"
    }, {
      close: "527.4",
      high: "527.5",
      low: "511.6",
      open: "511.6",
      time: "2022-01-01T00:00:00.000Z",
      volume: "446161"
    }, {
      close: "531.3",
      high: "533.6",
      low: "518.4",
      open: "527.4",
      time: "2022-01-02T00:00:00.000Z",
      volume: "404559"
    }, {
      close: "511.7",
      high: "532.6",
      low: "510.2",
      open: "531",
      time: "2022-01-03T00:00:00.000Z",
      volume: "663264"
    }, {
      close: "507",
      high: "520",
      low: "502.4",
      open: "512.2",
      time: "2022-01-04T00:00:00.000Z",
      volume: "687429"
    }, {
      close: "473.6",
      high: "515.8",
      low: "459.1",
      open: "507.7",
      time: "2022-01-05T00:00:00.000Z",
      volume: "984694"
    }, {
      close: "472.9",
      high: "481.3",
      low: "459.4",
      open: "473.8",
      time: "2022-01-06T00:00:00.000Z",
      volume: "986956"
    }, {
      close: "447.9",
      high: "473.7",
      low: "438.2",
      open: "473.1",
      time: "2022-01-07T00:00:00.000Z",
      volume: "1331122"
    }, {
      close: "429.3",
      high: "459.3",
      low: "417.1",
      open: "448.5",
      time: "2022-01-08T00:00:00.000Z",
      volume: "1062831"
    }, {
      close: "438.6",
      high: "444.4",
      low: "427.3",
      open: "429.8",
      time: "2022-01-09T00:00:00.000Z",
      volume: "688887"
    }, {
      close: "424.8",
      high: "445.4",
      low: "407.7",
      open: "438.5",
      time: "2022-01-10T00:00:00.000Z",
      volume: "1221605"
    }, {
      close: "463.5",
      high: "467.5",
      low: "421.5",
      open: "424.8",
      time: "2022-01-11T00:00:00.000Z",
      volume: "1266117"
    }, {
      close: "487",
      high: "489.6",
      low: "456.7",
      open: "462.8",
      time: "2022-01-12T00:00:00.000Z",
      volume: "834703"
    }, {
      close: "475.1",
      high: "488.9",
      low: "467.1",
      open: "487.7",
      time: "2022-01-13T00:00:00.000Z",
      volume: "908236"
    }, {
      close: "490",
      high: "492.1",
      low: "468.3",
      open: "475.2",
      time: "2022-01-14T00:00:00.000Z",
      volume: "797146"
    }, {
      close: "494.5",
      high: "500.8",
      low: "485.3",
      open: "490.1",
      time: "2022-01-15T00:00:00.000Z",
      volume: "548626"
    }, {
      close: "498.5",
      high: "505.6",
      low: "488.9",
      open: "494.1",
      time: "2022-01-16T00:00:00.000Z",
      volume: "507152"
    }, {
      close: "475",
      high: "499",
      low: "467.4",
      open: "498.5",
      time: "2022-01-17T00:00:00.000Z",
      volume: "757732"
    }, {
      close: "471.2",
      high: "479.3",
      low: "457.5",
      open: "475.5",
      time: "2022-01-18T00:00:00.000Z",
      volume: "707388"
    }, {
      close: "462.3",
      high: "472.8",
      low: "454.1",
      open: "472",
      time: "2022-01-19T00:00:00.000Z",
      volume: "585542"
    }, {
      close: "440.4",
      high: "478.3",
      low: "438.5",
      open: "462",
      time: "2022-01-20T00:00:00.000Z",
      volume: "1137855"
    }, {
      close: "384.3",
      high: "444.3",
      low: "375",
      open: "440.2",
      time: "2022-01-21T00:00:00.000Z",
      volume: "1889698"
    }, {
      close: "358.3",
      high: "389",
      low: "337.6",
      open: "383.2",
      time: "2022-01-22T00:00:00.000Z",
      volume: "2635609"
    }, {
      close: "384.1",
      high: "388.9",
      low: "356.6",
      open: "358.2",
      time: "2022-01-23T00:00:00.000Z",
      volume: "1297012"
    }, {
      close: "375",
      high: "383.8",
      low: "374.8",
      open: "383.8",
      time: "2022-01-24T00:00:00.000Z",
      volume: "1261797"
    }]
  },
  eth: {
    id: 'eth',
    name: 'ETH',
    symbol: 'ETH',
    image: 'assets/images/coin-logo/ETH.png',
    activity24h: 545323456,
    availableAmount: 508.232323,
    usdxConversion: 7,
    allTimeValue: 3812390,
    allTimeAsset: 90678678421,
    candleData: [{
      close: "2190.59",
      high: "2195.25",
      low: "2108.72",
      open: "2176.17",
      time: "2021-07-25T00:00:00.000Z",
      volume: "27202"
    }, {
      close: "2227.96",
      high: "2430.64",
      low: "2172.88",
      open: "2189.61",
      time: "2021-07-26T00:00:00.000Z",
      volume: "55995"
    }, {
      close: "2301.45",
      high: "2318",
      low: "2152.76",
      open: "2228.33",
      time: "2021-07-27T00:00:00.000Z",
      volume: "44821"
    }, {
      close: "2299.52",
      high: "2344.7",
      low: "2244.23",
      open: "2300.35",
      time: "2021-07-28T00:00:00.000Z",
      volume: "40422"
    }, {
      close: "2382.29",
      high: "2399.15",
      low: "2266.72",
      open: "2299.52",
      time: "2021-07-29T00:00:00.000Z",
      volume: "28512"
    }, {
      close: "2461.33",
      high: "2469.43",
      low: "2316.75",
      open: "2382.51",
      time: "2021-07-30T00:00:00.000Z",
      volume: "35783"
    }, {
      close: "2528.85",
      high: "2552.09",
      low: "2421.11",
      open: "2459.61",
      time: "2021-07-31T00:00:00.000Z",
      volume: "22779"
    }, {
      close: "2554.78",
      high: "2696.68",
      low: "2512.2",
      open: "2531.41",
      time: "2021-08-01T00:00:00.000Z",
      volume: "41796"
    }, {
      close: "2606.99",
      high: "2664.82",
      low: "2508.7",
      open: "2555.13",
      time: "2021-08-02T00:00:00.000Z",
      volume: "35567"
    }, {
      close: "2508.37",
      high: "2631.4",
      low: "2445.57",
      open: "2606.99",
      time: "2021-08-03T00:00:00.000Z",
      volume: "35305"
    }, {
      close: "2725.33",
      high: "2768.77",
      low: "2459.27",
      open: "2508.6",
      time: "2021-08-04T00:00:00.000Z",
      volume: "29032"
    }, {
      close: "2827.52",
      high: "2843.32",
      low: "2531.58",
      open: "2724.86",
      time: "2021-08-05T00:00:00.000Z",
      volume: "39569"
    }, {
      close: "2890.62",
      high: "2946.43",
      low: "2721.55",
      open: "2827.52",
      time: "2021-08-06T00:00:00.000Z",
      volume: "28593"
    }, {
      close: "3159.39",
      high: "3169.51",
      low: "2865.78",
      open: "2891.41",
      time: "2021-08-07T00:00:00.000Z",
      volume: "30257"
    }, {
      close: "3013.69",
      high: "3188.54",
      low: "2950.07",
      open: "3159.32",
      time: "2021-08-08T00:00:00.000Z",
      volume: "31461"
    }, {
      close: "3162.05",
      high: "3185.37",
      low: "2895.64",
      open: "3011.23",
      time: "2021-08-09T00:00:00.000Z",
      volume: "34538"
    }, {
      close: "3139.33",
      high: "3230.45",
      low: "3053.51",
      open: "3161.37",
      time: "2021-08-10T00:00:00.000Z",
      volume: "29073"
    }, {
      close: "3162.71",
      high: "3273.26",
      low: "3121.62",
      open: "3139.69",
      time: "2021-08-11T00:00:00.000Z",
      volume: "25959"
    }, {
      close: "3047.01",
      high: "3237.89",
      low: "2980.41",
      open: "3161.85",
      time: "2021-08-12T00:00:00.000Z",
      volume: "36055"
    }, {
      close: "3321.78",
      high: "3325.25",
      low: "3034.71",
      open: "3047.01",
      time: "2021-08-13T00:00:00.000Z",
      volume: "26724"
    }, {
      close: "3264.44",
      high: "3329.95",
      low: "3206.12",
      open: "3321.78",
      time: "2021-08-14T00:00:00.000Z",
      volume: "24136"
    }, {
      close: "3308.41",
      high: "3319.27",
      low: "3112.92",
      open: "3264.15",
      time: "2021-08-15T00:00:00.000Z",
      volume: "27732"
    }, {
      close: "3146.57",
      high: "3334.21",
      low: "3134.38",
      open: "3308.16",
      time: "2021-08-16T00:00:00.000Z",
      volume: "29117"
    }, {
      close: "3012.34",
      high: "3290.36",
      low: "2993.1",
      open: "3146.81",
      time: "2021-08-17T00:00:00.000Z",
      volume: "44717"
    }, {
      close: "3015.62",
      high: "3123.48",
      low: "2951.66",
      open: "3011.54",
      time: "2021-08-18T00:00:00.000Z",
      volume: "42911"
    }, {
      close: "3182.59",
      high: "3187.21",
      low: "2960.64",
      open: "3016.58",
      time: "2021-08-19T00:00:00.000Z",
      volume: "36293"
    }, {
      close: "3283.51",
      high: "3299.87",
      low: "3176.85",
      open: "3182.6",
      time: "2021-08-20T00:00:00.000Z",
      volume: "19539"
    }, {
      close: "3223.91",
      high: "3307.33",
      low: "3200.19",
      open: "3285.12",
      time: "2021-08-21T00:00:00.000Z",
      volume: "421170"
    }, {
      close: "3238.08",
      high: "3271.96",
      low: "3128.01",
      open: "3224.07",
      time: "2021-08-22T00:00:00.000Z",
      volume: "406493"
    }, {
      close: "3323.05",
      high: "3375.02",
      low: "3231.63",
      open: "3232.53",
      time: "2021-08-23T00:00:00.000Z",
      volume: "602085"
    }, {
      close: "3173.35",
      high: "3357.83",
      low: "3150.4",
      open: "3324.58",
      time: "2021-08-24T00:00:00.000Z",
      volume: "540541"
    }, {
      close: "3228.4",
      high: "3247.63",
      low: "3080.42",
      open: "3170.02",
      time: "2021-08-25T00:00:00.000Z",
      volume: "483748"
    }, {
      close: "3092.48",
      high: "3249.5",
      low: "3055.01",
      open: "3227.26",
      time: "2021-08-26T00:00:00.000Z",
      volume: "441856"
    }, {
      close: "3273.57",
      high: "3279.99",
      low: "3061.79",
      open: "3095.81",
      time: "2021-08-27T00:00:00.000Z",
      volume: "451375"
    }, {
      close: "3244.01",
      high: "3284.73",
      low: "3212.53",
      open: "3273.88",
      time: "2021-08-28T00:00:00.000Z",
      volume: "262348"
    }, {
      close: "3221.81",
      high: "3281.27",
      low: "3156.24",
      open: "3244.64",
      time: "2021-08-29T00:00:00.000Z",
      volume: "335826"
    }, {
      close: "3228.54",
      high: "3346.21",
      low: "3145.69",
      open: "3223.36",
      time: "2021-08-30T00:00:00.000Z",
      volume: "625341"
    }, {
      close: "3429.45",
      high: "3468.6",
      low: "3191.48",
      open: "3229.05",
      time: "2021-08-31T00:00:00.000Z",
      volume: "863097"
    }, {
      close: "3835.96",
      high: "3840",
      low: "3384.89",
      open: "3424.29",
      time: "2021-09-01T00:00:00.000Z",
      volume: "845431"
    }, {
      close: "3786.14",
      high: "3833.42",
      low: "3722.78",
      open: "3826.43",
      time: "2021-09-02T00:00:00.000Z",
      volume: "525423"
    }, {
      close: "3934.83",
      high: "4025.06",
      low: "3716.75",
      open: "3785.63",
      time: "2021-09-03T00:00:00.000Z",
      volume: "655821"
    }, {
      close: "3885",
      high: "3968.68",
      low: "3832.52",
      open: "3938.54",
      time: "2021-09-04T00:00:00.000Z",
      volume: "364580"
    }, {
      close: "3950.91",
      high: "3979",
      low: "3835.01",
      open: "3886.77",
      time: "2021-09-05T00:00:00.000Z",
      volume: "365749"
    }, {
      close: "3927.43",
      high: "3968",
      low: "3866.73",
      open: "3950.29",
      time: "2021-09-06T00:00:00.000Z",
      volume: "397777"
    }, {
      close: "3422.38",
      high: "3945.67",
      low: "3029.27",
      open: "3926.66",
      time: "2021-09-07T00:00:00.000Z",
      volume: "1203414"
    }, {
      close: "3499.27",
      high: "3558.99",
      low: "3215.32",
      open: "3436.28",
      time: "2021-09-08T00:00:00.000Z",
      volume: "780716"
    }, {
      close: "3422.99",
      high: "3564.31",
      low: "3395.09",
      open: "3497.93",
      time: "2021-09-09T00:00:00.000Z",
      volume: "628536"
    }, {
      close: "3210.63",
      high: "3512.49",
      low: "3155.6",
      open: "3421.42",
      time: "2021-09-10T00:00:00.000Z",
      volume: "633009"
    }, {
      close: "3267.27",
      high: "3345",
      low: "3202.83",
      open: "3211.33",
      time: "2021-09-11T00:00:00.000Z",
      volume: "413626"
    }, {
      close: "3407.9",
      high: "3471.71",
      low: "3231.4",
      open: "3261.32",
      time: "2021-09-12T00:00:00.000Z",
      volume: "408862"
    }, {
      close: "3285.09",
      high: "3428.87",
      low: "3117.75",
      open: "3401.16",
      time: "2021-09-13T00:00:00.000Z",
      volume: "647870"
    }, {
      close: "3430.91",
      high: "3430.91",
      low: "3270.69",
      open: "3285.83",
      time: "2021-09-14T00:00:00.000Z",
      volume: "373340"
    }, {
      close: "3611.32",
      high: "3611.32",
      low: "3360.48",
      open: "3433.88",
      time: "2021-09-15T00:00:00.000Z",
      volume: "428771"
    }, {
      close: "3567.68",
      high: "3672.93",
      low: "3488.03",
      open: "3614.51",
      time: "2021-09-16T00:00:00.000Z",
      volume: "468161"
    }, {
      close: "3398.38",
      high: "3593.31",
      low: "3352.78",
      open: "3567.89",
      time: "2021-09-17T00:00:00.000Z",
      volume: "398876"
    }, {
      close: "3436.22",
      high: "3539.57",
      low: "3371.1",
      open: "3401.18",
      time: "2021-09-18T00:00:00.000Z",
      volume: "315122"
    }, {
      close: "3328.23",
      high: "3455.36",
      low: "3281.91",
      open: "3435.6",
      time: "2021-09-19T00:00:00.000Z",
      volume: "312949"
    }, {
      close: "2975.9",
      high: "3343.83",
      low: "2933.47",
      open: "3334.06",
      time: "2021-09-20T00:00:00.000Z",
      volume: "917822"
    }, {
      close: "2765.14",
      high: "3100.93",
      low: "2662.5",
      open: "2978.16",
      time: "2021-09-21T00:00:00.000Z",
      volume: "971491"
    }, {
      close: "3077.76",
      high: "3087.92",
      low: "2740.58",
      open: "2769.4",
      time: "2021-09-22T00:00:00.000Z",
      volume: "677624"
    }, {
      close: "3152.67",
      high: "3173.14",
      low: "3035.15",
      open: "3076.23",
      time: "2021-09-23T00:00:00.000Z",
      volume: "459459"
    }, {
      close: "2928.24",
      high: "3157.89",
      low: "2740.03",
      open: "3152.02",
      time: "2021-09-24T00:00:00.000Z",
      volume: "932089"
    }, {
      close: "2921.91",
      high: "2965.49",
      low: "2809.42",
      open: "2928.56",
      time: "2021-09-25T00:00:00.000Z",
      volume: "491979"
    }, {
      close: "3063.32",
      high: "3113.37",
      low: "2739.59",
      open: "2923.34",
      time: "2021-09-26T00:00:00.000Z",
      volume: "703588"
    }, {
      close: "2926.02",
      high: "3164.2",
      low: "2926.02",
      open: "3057.5",
      time: "2021-09-27T00:00:00.000Z",
      volume: "510065"
    }, {
      close: "2807.67",
      high: "2969.01",
      low: "2791.1",
      open: "2920.35",
      time: "2021-09-28T00:00:00.000Z",
      volume: "511606"
    }, {
      close: "2851.72",
      high: "2949.06",
      low: "2785.64",
      open: "2803.84",
      time: "2021-09-29T00:00:00.000Z",
      volume: "411248"
    }, {
      close: "2999.86",
      high: "3047",
      low: "2837.55",
      open: "2849.34",
      time: "2021-09-30T00:00:00.000Z",
      volume: "506988"
    }, {
      close: "3309.58",
      high: "3328.74",
      low: "2972.91",
      open: "2998.46",
      time: "2021-10-01T00:00:00.000Z",
      volume: "648726"
    }, {
      close: "3388.26",
      high: "3468.3",
      low: "3258.28",
      open: "3310.94",
      time: "2021-10-02T00:00:00.000Z",
      volume: "422513"
    }, {
      close: "3416.18",
      high: "3485",
      low: "3347.32",
      open: "3390.74",
      time: "2021-10-03T00:00:00.000Z",
      volume: "360636"
    }, {
      close: "3383.17",
      high: "3436.13",
      low: "3272.33",
      open: "3417.06",
      time: "2021-10-04T00:00:00.000Z",
      volume: "485180"
    }, {
      close: "3517.82",
      high: "3540.83",
      low: "3364.83",
      open: "3386.15",
      time: "2021-10-05T00:00:00.000Z",
      volume: "393900"
    }, {
      close: "3575.58",
      high: "3627.08",
      low: "3345.56",
      open: "3514.94",
      time: "2021-10-06T00:00:00.000Z",
      volume: "618226"
    }, {
      close: "3586.93",
      high: "3649.64",
      low: "3473.54",
      open: "3575.39",
      time: "2021-10-07T00:00:00.000Z",
      volume: "486105"
    }, {
      close: "3559.24",
      high: "3668.14",
      low: "3537.94",
      open: "3586.69",
      time: "2021-10-08T00:00:00.000Z",
      volume: "403541"
    }, {
      close: "3574.23",
      high: "3630.33",
      low: "3542",
      open: "3564.99",
      time: "2021-10-09T00:00:00.000Z",
      volume: "361871"
    }, {
      close: "3414.72",
      high: "3599.23",
      low: "3409.25",
      open: "3573.89",
      time: "2021-10-10T00:00:00.000Z",
      volume: "580260"
    }, {
      close: "3545.08",
      high: "3622.89",
      low: "3382.58",
      open: "3413.29",
      time: "2021-10-11T00:00:00.000Z",
      volume: "493488"
    }, {
      close: "3489",
      high: "3544.88",
      low: "3404.64",
      open: "3541.35",
      time: "2021-10-12T00:00:00.000Z",
      volume: "409436"
    }, {
      close: "3607.21",
      high: "3611.83",
      low: "3415.45",
      open: "3490.76",
      time: "2021-10-13T00:00:00.000Z",
      volume: "393395"
    }, {
      close: "3790.18",
      high: "3819.81",
      low: "3588.2",
      open: "3609",
      time: "2021-10-14T00:00:00.000Z",
      volume: "481878"
    }, {
      close: "3868.99",
      high: "3906.54",
      low: "3735.1",
      open: "3790.95",
      time: "2021-10-15T00:00:00.000Z",
      volume: "504325"
    }, {
      close: "3828.25",
      high: "3967.73",
      low: "3804.46",
      open: "3872.68",
      time: "2021-10-16T00:00:00.000Z",
      volume: "373758"
    }, {
      close: "3844.63",
      high: "3917.62",
      low: "3666.11",
      open: "3829.47",
      time: "2021-10-17T00:00:00.000Z",
      volume: "368796"
    }, {
      close: "3747.62",
      high: "3892.36",
      low: "3685.35",
      open: "3849.34",
      time: "2021-10-18T00:00:00.000Z",
      volume: "401929"
    }, {
      close: "3876.71",
      high: "3884.23",
      low: "3733.81",
      open: "3745.28",
      time: "2021-10-19T00:00:00.000Z",
      volume: "341278"
    }, {
      close: "4160.21",
      high: "4168.4",
      low: "3830.31",
      open: "3882.65",
      time: "2021-10-20T00:00:00.000Z",
      volume: "537348"
    }, {
      close: "4054.15",
      high: "4365.7",
      low: "4028.35",
      open: "4161.79",
      time: "2021-10-21T00:00:00.000Z",
      volume: "798628"
    }, {
      close: "3970.9",
      high: "4163.97",
      low: "3896.17",
      open: "4053.34",
      time: "2021-10-22T00:00:00.000Z",
      volume: "463253"
    }, {
      close: "4168.78",
      high: "4168.78",
      low: "3938.36",
      open: "3971.49",
      time: "2021-10-23T00:00:00.000Z",
      volume: "326588"
    }, {
      close: "4083.18",
      high: "4183.98",
      low: "3965.55",
      open: "4171.99",
      time: "2021-10-24T00:00:00.000Z",
      volume: "350133"
    }, {
      close: "4220.35",
      high: "4235.25",
      low: "4069.92",
      open: "4084.1",
      time: "2021-10-25T00:00:00.000Z",
      volume: "347434"
    }, {
      close: "4129.69",
      high: "4292.35",
      low: "4098.35",
      open: "4225.63",
      time: "2021-10-26T00:00:00.000Z",
      volume: "414487"
    }, {
      close: "3921.72",
      high: "4300.31",
      low: "3914.22",
      open: "4130.52",
      time: "2021-10-27T00:00:00.000Z",
      volume: "704442"
    }, {
      close: "4284.51",
      high: "4289.44",
      low: "3895.91",
      open: "3923.58",
      time: "2021-10-28T00:00:00.000Z",
      volume: "626561"
    }, {
      close: "4413.79",
      high: "4459.04",
      low: "4268.25",
      open: "4286.51",
      time: "2021-10-29T00:00:00.000Z",
      volume: "559057"
    }, {
      close: "4321.56",
      high: "4427.46",
      low: "4250.84",
      open: "4420.46",
      time: "2021-10-30T00:00:00.000Z",
      volume: "340237"
    }, {
      close: "4287.6",
      high: "4393.48",
      low: "4170.48",
      open: "4327.99",
      time: "2021-10-31T00:00:00.000Z",
      volume: "413746"
    }, {
      close: "4320.91",
      high: "4377.37",
      low: "4158.07",
      open: "4288.93",
      time: "2021-11-01T00:00:00.000Z",
      volume: "417619"
    }, {
      close: "4585.74",
      high: "4598.12",
      low: "4287.86",
      open: "4318.8",
      time: "2021-11-02T00:00:00.000Z",
      volume: "468156"
    }, {
      close: "4602.42",
      high: "4661.14",
      low: "4459.5",
      open: "4589.26",
      time: "2021-11-03T00:00:00.000Z",
      volume: "448682"
    }, {
      close: "4535.53",
      high: "4604.98",
      low: "4421.72",
      open: "4603.78",
      time: "2021-11-04T00:00:00.000Z",
      volume: "368261"
    }, {
      close: "4474.98",
      high: "4569.43",
      low: "4439",
      open: "4533.16",
      time: "2021-11-05T00:00:00.000Z",
      volume: "305637"
    }, {
      close: "4517.27",
      high: "4526.66",
      low: "4330.83",
      open: "4473.03",
      time: "2021-11-06T00:00:00.000Z",
      volume: "322088"
    }, {
      close: "4611.73",
      high: "4634.35",
      low: "4502.38",
      open: "4516.46",
      time: "2021-11-07T00:00:00.000Z",
      volume: "243689"
    }, {
      close: "4808.09",
      high: "4822.96",
      low: "4613.2",
      open: "4613.2",
      time: "2021-11-08T00:00:00.000Z",
      volume: "391093"
    }, {
      close: "4731.68",
      high: "4836.54",
      low: "4715.36",
      open: "4804.5",
      time: "2021-11-09T00:00:00.000Z",
      volume: "311313"
    }, {
      close: "4633.95",
      high: "4864.13",
      low: "4507.15",
      open: "4732.74",
      time: "2021-11-10T00:00:00.000Z",
      volume: "553545"
    }, {
      close: "4720.86",
      high: "4778.09",
      low: "4578.99",
      open: "4625.11",
      time: "2021-11-11T00:00:00.000Z",
      volume: "303774"
    }, {
      close: "4665.46",
      high: "4807.78",
      low: "4512.66",
      open: "4718.28",
      time: "2021-11-12T00:00:00.000Z",
      volume: "461583"
    }, {
      close: "4644.58",
      high: "4704.89",
      low: "4585.21",
      open: "4669.97",
      time: "2021-11-13T00:00:00.000Z",
      volume: "220725"
    }, {
      close: "4625.76",
      high: "4692.28",
      low: "4514.81",
      open: "4644.94",
      time: "2021-11-14T00:00:00.000Z",
      volume: "245307"
    }, {
      close: "4563.04",
      high: "4770.01",
      low: "4544.21",
      open: "4634.28",
      time: "2021-11-15T00:00:00.000Z",
      volume: "375482"
    }, {
      close: "4208.42",
      high: "4562.12",
      low: "4111.94",
      open: "4562.12",
      time: "2021-11-16T00:00:00.000Z",
      volume: "735797"
    }, {
      close: "4290.99",
      high: "4298.72",
      low: "4070.81",
      open: "4208.75",
      time: "2021-11-17T00:00:00.000Z",
      volume: "472873"
    }, {
      close: "4003.13",
      high: "4342.67",
      low: "3958.35",
      open: "4291.23",
      time: "2021-11-18T00:00:00.000Z",
      volume: "576619"
    }, {
      close: "4295.39",
      high: "4305.18",
      low: "3978.34",
      open: "3996.26",
      time: "2021-11-19T00:00:00.000Z",
      volume: "420394"
    }, {
      close: "4408",
      high: "4434.09",
      low: "4206.02",
      open: "4294.75",
      time: "2021-11-20T00:00:00.000Z",
      volume: "365226"
    }, {
      close: "4268.16",
      high: "4422.54",
      low: "4246.31",
      open: "4412.18",
      time: "2021-11-21T00:00:00.000Z",
      volume: "340074"
    }, {
      close: "4086.52",
      high: "4311.17",
      low: "4026.64",
      open: "4263.24",
      time: "2021-11-22T00:00:00.000Z",
      volume: "518626"
    }, {
      close: "4340.45",
      high: "4384.72",
      low: "4062.19",
      open: "4088.03",
      time: "2021-11-23T00:00:00.000Z",
      volume: "502791"
    }, {
      close: "4270",
      high: "4372.8",
      low: "4171.89",
      open: "4338.75",
      time: "2021-11-24T00:00:00.000Z",
      volume: "448725"
    }, {
      close: "4519.71",
      high: "4549.18",
      low: "4248.98",
      open: "4272.28",
      time: "2021-11-25T00:00:00.000Z",
      volume: "445826"
    }, {
      close: "4034.41",
      high: "4547.7",
      low: "3916.62",
      open: "4525.51",
      time: "2021-11-26T00:00:00.000Z",
      volume: "720214"
    }, {
      close: "4093.56",
      high: "4182.66",
      low: "4028.52",
      open: "4041.65",
      time: "2021-11-27T00:00:00.000Z",
      volume: "275441"
    }, {
      close: "4297.52",
      high: "4297.52",
      low: "3980",
      open: "4094.97",
      time: "2021-11-28T00:00:00.000Z",
      volume: "346500"
    }, {
      close: "4442.82",
      high: "4457",
      low: "4279.54",
      open: "4300",
      time: "2021-11-29T00:00:00.000Z",
      volume: "406973"
    }, {
      close: "4631.22",
      high: "4748",
      low: "4348.96",
      open: "4442.62",
      time: "2021-11-30T00:00:00.000Z",
      volume: "742650"
    }, {
      close: "4582.96",
      high: "4778",
      low: "4524.92",
      open: "4630.52",
      time: "2021-12-01T00:00:00.000Z",
      volume: "508218"
    }, {
      close: "4512.71",
      high: "4630.11",
      low: "4437.49",
      open: "4583.02",
      time: "2021-12-02T00:00:00.000Z",
      volume: "451878"
    }, {
      close: "4217.78",
      high: "4646.83",
      low: "4045.17",
      open: "4512.71",
      time: "2021-12-03T00:00:00.000Z",
      volume: "677861"
    }, {
      close: "4118.06",
      high: "4237.33",
      low: "3561.06",
      open: "4213.57",
      time: "2021-12-04T00:00:00.000Z",
      volume: "1111781"
    }, {
      close: "4194.6",
      high: "4247.57",
      low: "4035.8",
      open: "4123.56",
      time: "2021-12-05T00:00:00.000Z",
      volume: "536333"
    }, {
      close: "4352.66",
      high: "4374.22",
      low: "3922.04",
      open: "4197.06",
      time: "2021-12-06T00:00:00.000Z",
      volume: "726725"
    }, {
      close: "4307.11",
      high: "4424.22",
      low: "4259.06",
      open: "4350.79",
      time: "2021-12-07T00:00:00.000Z",
      volume: "420889"
    }, {
      close: "4435.24",
      high: "4449.85",
      low: "4229.16",
      open: "4308.26",
      time: "2021-12-08T00:00:00.000Z",
      volume: "429377"
    }, {
      close: "4107.82",
      high: "4482.76",
      low: "4077.94",
      open: "4437.94",
      time: "2021-12-09T00:00:00.000Z",
      volume: "462005"
    }, {
      close: "3903.58",
      high: "4227.84",
      low: "3894.26",
      open: "4107.7",
      time: "2021-12-10T00:00:00.000Z",
      volume: "563198"
    }, {
      close: "4075.23",
      high: "4094.73",
      low: "3837.15",
      open: "3896.72",
      time: "2021-12-11T00:00:00.000Z",
      volume: "427794"
    }, {
      close: "4131.84",
      high: "4173.15",
      low: "3988.22",
      open: "4089.38",
      time: "2021-12-12T00:00:00.000Z",
      volume: "260676"
    }, {
      close: "3780.13",
      high: "4140.73",
      low: "3672.3",
      open: "4126.81",
      time: "2021-12-13T00:00:00.000Z",
      volume: "533472"
    }, {
      close: "3860.41",
      high: "3879.48",
      low: "3690.04",
      open: "3783.53",
      time: "2021-12-14T00:00:00.000Z",
      volume: "395143"
    }, {
      close: "4018.94",
      high: "4089.65",
      low: "3648.77",
      open: "3859.06",
      time: "2021-12-15T00:00:00.000Z",
      volume: "542027"
    }, {
      close: "3956.35",
      high: "4107.85",
      low: "3955.35",
      open: "4023.92",
      time: "2021-12-16T00:00:00.000Z",
      volume: "368814"
    }, {
      close: "3878.43",
      high: "3994",
      low: "3702.29",
      open: "3959.05",
      time: "2021-12-17T00:00:00.000Z",
      volume: "463286"
    }, {
      close: "3959.77",
      high: "3992.51",
      low: "3770.62",
      open: "3881.81",
      time: "2021-12-18T00:00:00.000Z",
      volume: "339773"
    }, {
      close: "3925.8",
      high: "4024.8",
      low: "3890.82",
      open: "3959",
      time: "2021-12-19T00:00:00.000Z",
      volume: "305997"
    }, {
      close: "3944.49",
      high: "3978.42",
      low: "3757.35",
      open: "3923.62",
      time: "2021-12-20T00:00:00.000Z",
      volume: "366911"
    }, {
      close: "4016.27",
      high: "4058.42",
      low: "3915.49",
      open: "3956.37",
      time: "2021-12-21T00:00:00.000Z",
      volume: "340871"
    }, {
      close: "3979.47",
      high: "4072.1",
      low: "3943.62",
      open: "4014",
      time: "2021-12-22T00:00:00.000Z",
      volume: "303221"
    }, {
      close: "4109.7",
      high: "4148.85",
      low: "3895.82",
      open: "3980.37",
      time: "2021-12-23T00:00:00.000Z",
      volume: "380175"
    }, {
      close: "4043.19",
      high: "4133.72",
      low: "4025.43",
      open: "4112.3",
      time: "2021-12-24T00:00:00.000Z",
      volume: "230846"
    }, {
      close: "4092.69",
      high: "4135.67",
      low: "4025.33",
      open: "4046.98",
      time: "2021-12-25T00:00:00.000Z",
      volume: "208143"
    }, {
      close: "4061.17",
      high: "4103.98",
      low: "4005.01",
      open: "4095.72",
      time: "2021-12-26T00:00:00.000Z",
      volume: "189160"
    }, {
      close: "4036.24",
      high: "4125.11",
      low: "4033.26",
      open: "4064.58",
      time: "2021-12-27T00:00:00.000Z",
      volume: "222306"
    }, {
      close: "3793.17",
      high: "4037.04",
      low: "3761",
      open: "4037.04",
      time: "2021-12-28T00:00:00.000Z",
      volume: "358064"
    }, {
      close: "3627.17",
      high: "3826.1",
      low: "3607.76",
      open: "3798.51",
      time: "2021-12-29T00:00:00.000Z",
      volume: "302745"
    }, {
      close: "3709.42",
      high: "3767.96",
      low: "3588.14",
      open: "3629.32",
      time: "2021-12-30T00:00:00.000Z",
      volume: "249316"
    }, {
      close: "3677.7",
      high: "3812.06",
      low: "3623.69",
      open: "3709.19",
      time: "2021-12-31T00:00:00.000Z",
      volume: "268023"
    }, {
      close: "3765.36",
      high: "3774.77",
      low: "3675.68",
      open: "3679.41",
      time: "2022-01-01T00:00:00.000Z",
      volume: "154148"
    }, {
      close: "3831.24",
      high: "3850",
      low: "3718.31",
      open: "3765.75",
      time: "2022-01-02T00:00:00.000Z",
      volume: "154754"
    }, {
      close: "3764.48",
      high: "3849.03",
      low: "3682.57",
      open: "3828.2",
      time: "2022-01-03T00:00:00.000Z",
      volume: "236283"
    }, {
      close: "3790.83",
      high: "3888.86",
      low: "3714.78",
      open: "3772.46",
      time: "2022-01-04T00:00:00.000Z",
      volume: "288099"
    }, {
      close: "3535.07",
      high: "3846.31",
      low: "3419.2",
      open: "3784.1",
      time: "2022-01-05T00:00:00.000Z",
      volume: "397437"
    }, {
      close: "3407.21",
      high: "3550.42",
      low: "3306.91",
      open: "3533.73",
      time: "2022-01-06T00:00:00.000Z",
      volume: "497144"
    }, {
      close: "3195.35",
      high: "3412.66",
      low: "3080.3",
      open: "3412.43",
      time: "2022-01-07T00:00:00.000Z",
      volume: "669758"
    }, {
      close: "3079.66",
      high: "3246.4",
      low: "3001.63",
      open: "3198.5",
      time: "2022-01-08T00:00:00.000Z",
      volume: "400679"
    }, {
      close: "3150.42",
      high: "3210.49",
      low: "3060.36",
      open: "3080.01",
      time: "2022-01-09T00:00:00.000Z",
      volume: "275884"
    }, {
      close: "3085.46",
      high: "3179.99",
      low: "2932.51",
      open: "3146.03",
      time: "2022-01-10T00:00:00.000Z",
      volume: "529755"
    }, {
      close: "3237.93",
      high: "3263.07",
      low: "3053.61",
      open: "3083.14",
      time: "2022-01-11T00:00:00.000Z",
      volume: "362470"
    }, {
      close: "3370.48",
      high: "3406.5",
      low: "3208.47",
      open: "3239.73",
      time: "2022-01-12T00:00:00.000Z",
      volume: "370470"
    }, {
      close: "3242.44",
      high: "3407.33",
      low: "3234.85",
      open: "3368.76",
      time: "2022-01-13T00:00:00.000Z",
      volume: "336223"
    }, {
      close: "3307.74",
      high: "3340.98",
      low: "3196.46",
      open: "3242.82",
      time: "2022-01-14T00:00:00.000Z",
      volume: "285396"
    }, {
      close: "3328.08",
      high: "3369.45",
      low: "3265.36",
      open: "3310.73",
      time: "2022-01-15T00:00:00.000Z",
      volume: "203069"
    }, {
      close: "3346.47",
      high: "3387.99",
      low: "3276.74",
      open: "3326.25",
      time: "2022-01-16T00:00:00.000Z",
      volume: "210783"
    }, {
      close: "3209.25",
      high: "3356.4",
      low: "3142.02",
      open: "3348.4",
      time: "2022-01-17T00:00:00.000Z",
      volume: "284012"
    }, {
      close: "3159.96",
      high: "3242.13",
      low: "3084.78",
      open: "3212.42",
      time: "2022-01-18T00:00:00.000Z",
      volume: "299193"
    }, {
      close: "3084.2",
      high: "3173.14",
      low: "3049.3",
      open: "3165.57",
      time: "2022-01-19T00:00:00.000Z",
      volume: "332961"
    }, {
      close: "3004.19",
      high: "3270.58",
      low: "2989.72",
      open: "3088.04",
      time: "2022-01-20T00:00:00.000Z",
      volume: "357361"
    }, {
      close: "2571.12",
      high: "3032.34",
      low: "2473.5",
      open: "3000.15",
      time: "2022-01-21T00:00:00.000Z",
      volume: "1022035"
    }, {
      close: "2412.48",
      high: "2621.41",
      low: "2314.3",
      open: "2562.66",
      time: "2022-01-22T00:00:00.000Z",
      volume: "1081640"
    }, {
      close: "2540.45",
      high: "2547.01",
      low: "2373.75",
      open: "2412.75",
      time: "2022-01-23T00:00:00.000Z",
      volume: "580477"
    }, {
      close: "2496.12",
      high: "2538.36",
      low: "2484.42",
      open: "2538.36",
      time: "2022-01-24T00:00:00.000Z",
      volume: "588105"
    }]
  },
  aave: {
    id: 'aave',
    name: 'AAVE',
    symbol: 'AAVE',
    image: 'assets/images/coin-logo/AAVE.png',
    activity24h: 421323456,
    availableAmount: 208.232323,
    usdxConversion: 2,
    allTimeValue: 55943812390,
    allTimeAsset: 542321,
    candleData: [
      {
      open: '0.5355000000000000',
      high: '0.5355000000000000',
      low: '0.4668000000000000',
      close: '0.4895000000000000',
      volume: '1343660.3100000000000000',
      quoteVolume: 665013.644449,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '0.4895000000000000',
      high: '0.4953000000000000',
      low: '0.4639000000000000',
      close: '0.4756000000000000',
      volume: '1411725.3500000000000000',
      quoteVolume: 673447.208597,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '0.4762000000000000',
      high: '0.5112000000000000',
      low: '0.4601000000000000',
      close: '0.4894000000000000',
      volume: '984417.5900000000000000',
      quoteVolume: 474999.286242,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '0.4908000000000000',
      high: '0.5000000000000000',
      low: '0.4673000000000000',
      close: '0.4831000000000000',
      volume: '735443.2000000000000000',
      quoteVolume: 354090.041046,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '0.4803000000000000',
      high: '0.4920000000000000',
      low: '0.4590000000000000',
      close: '0.4834000000000000',
      volume: '677499.5600000000000000',
      quoteVolume: 323333.553838,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '0.4839000000000000',
      high: '0.5198000000000000',
      low: '0.4650000000000000',
      close: '0.4972000000000000',
      volume: '897787.8400000000000000',
      quoteVolume: 446086.78762,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '0.4960000000000000',
      high: '0.5656000000000000',
      low: '0.4956000000000000',
      close: '0.5344000000000000',
      volume: '1784953.5000000000000000',
      quoteVolume: 955513.881021,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '0.5342000000000000',
      high: '0.5475000000000000',
      low: '0.4901000000000000',
      close: '0.5046000000000000',
      volume: '1827069.3900000000000000',
      quoteVolume: 940352.297759,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '0.5046000000000000',
      high: '0.5065000000000000',
      low: '0.4518000000000000',
      close: '0.5017000000000000',
      volume: '1529219.5300000000000000',
      quoteVolume: 745639.12774,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '0.5031000000000000',
      high: '0.5157000000000000',
      low: '0.4712000000000000',
      close: '0.4739000000000000',
      volume: '1112869.0800000000000000',
      quoteVolume: 544844.844073,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '0.4736000000000000',
      high: '0.4884000000000000',
      low: '0.4624000000000000',
      close: '0.4661000000000000',
      volume: '865653.8900000000000000',
      quoteVolume: 410185.821637,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '0.4661000000000000',
      high: '0.4666000000000000',
      low: '0.4229000000000000',
      close: '0.4303000000000000',
      volume: '2278919.6500000000000000',
      quoteVolume: 1004304.902092,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '0.4310000000000000',
      high: '0.4313000000000000',
      low: '0.3504000000000000',
      close: '0.4116000000000000',
      volume: '4006211.8500000000000000',
      quoteVolume: 1540777.018846,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '0.4104000000000000',
      high: '0.4638000000000000',
      low: '0.4061000000000000',
      close: '0.4505000000000000',
      volume: '5190825.3700000000000000',
      quoteVolume: 2252399.736119,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '0.4505000000000000',
      high: '0.4514000000000000',
      low: '0.4130000000000000',
      close: '0.4249000000000000',
      volume: '2092369.5900000000000000',
      quoteVolume: 897815.126911,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '0.4250000000000000',
      high: '0.4410000000000000',
      low: '0.3811000000000000',
      close: '0.3832000000000000',
      volume: '2029033.6100000000000000',
      quoteVolume: 826552.030246,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '0.3850000000000000',
      high: '0.4300000000000000',
      low: '0.3800000000000000',
      close: '0.4147000000000000',
      volume: '1899232.5600000000000000',
      quoteVolume: 768342.467271,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '0.4163000000000000',
      high: '0.4330000000000000',
      low: '0.3985000000000000',
      close: '0.4136000000000000',
      volume: '821033.4800000000000000',
      quoteVolume: 340840.331218,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '0.4170000000000000',
      high: '0.4170000000000000',
      low: '0.3860000000000000',
      close: '0.3963000000000000',
      volume: '1065170.5600000000000000',
      quoteVolume: 424303.971749,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '0.3959000000000000',
      high: '0.4125000000000000',
      low: '0.3867000000000000',
      close: '0.3943000000000000',
      volume: '1449384.9600000000000000',
      quoteVolume: 579009.583139,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '0.3926000000000000',
      high: '0.4327000000000000',
      low: '0.3902000000000000',
      close: '0.4224000000000000',
      volume: '1299107.0300000000000000',
      quoteVolume: 532341.103934,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '0.4224000000000000',
      high: '0.4236000000000000',
      low: '0.3915000000000000',
      close: '0.4000000000000000',
      volume: '1368546.2100000000000000',
      quoteVolume: 552819.621243,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '0.4000000000000000',
      high: '0.4065000000000000',
      low: '0.3913000000000000',
      close: '0.4021000000000000',
      volume: '498842.3600000000000000',
      quoteVolume: 199193.78253,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '0.4021000000000000',
      high: '0.4500000000000000',
      low: '0.3985000000000000',
      close: '0.4383000000000000',
      volume: '2015969.5800000000000000',
      quoteVolume: 859693.298603,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '0.4371000000000000',
      high: '0.4844000000000000',
      low: '0.4310000000000000',
      close: '0.4612000000000000',
      volume: '1384815.9500000000000000',
      quoteVolume: 637171.045365,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '0.4648000000000000',
      high: '0.4800000000000000',
      low: '0.4325000000000000',
      close: '0.4499000000000000',
      volume: '2000850.5500000000000000',
      quoteVolume: 913391.61962,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '0.4495000000000000',
      high: '0.4515000000000000',
      low: '0.4200000000000000',
      close: '0.4289000000000000',
      volume: '1580197.3900000000000000',
      quoteVolume: 685555.872631,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '0.4277000000000000',
      high: '0.4374000000000000',
      low: '0.4176000000000000',
      close: '0.4277000000000000',
      volume: '941303.5800000000000000',
      quoteVolume: 400668.760404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '0.4277000000000000',
      high: '0.4454000000000000',
      low: '0.4183000000000000',
      close: '0.4411000000000000',
      volume: '935377.3800000000000000',
      quoteVolume: 400576.99087,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '0.4422000000000000',
      high: '0.4442000000000000',
      low: '0.4203000000000000',
      close: '0.4259000000000000',
      volume: '942053.4400000000000000',
      quoteVolume: 407685.941353,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '0.4235000000000000',
      high: '0.4500000000000000',
      low: '0.4222000000000000',
      close: '0.4342000000000000',
      volume: '612251.8000000000000000',
      quoteVolume: 264659.849465,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '0.4366000000000000',
      high: '0.4464000000000000',
      low: '0.4251000000000000',
      close: '0.4398000000000000',
      volume: '465491.5800000000000000',
      quoteVolume: 203941.272106,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '0.4386000000000000',
      high: '0.4797000000000000',
      low: '0.4317000000000000',
      close: '0.4604000000000000',
      volume: '1616509.7200000000000000',
      quoteVolume: 740396.471002,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '0.4615000000000000',
      high: '0.4823000000000000',
      low: '0.4534000000000000',
      close: '0.4710000000000000',
      volume: '1430254.7800000000000000',
      quoteVolume: 671573.658591,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '0.4710000000000000',
      high: '0.4789000000000000',
      low: '0.4520000000000000',
      close: '0.4598000000000000',
      volume: '1548002.4400000000000000',
      quoteVolume: 724054.883013,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '0.4548000000000000',
      high: '0.4748000000000000',
      low: '0.4432000000000000',
      close: '0.4618000000000000',
      volume: '1358786.2800000000000000',
      quoteVolume: 625130.767047,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '0.4617000000000000',
      high: '0.4750000000000000',
      low: '0.4380000000000000',
      close: '0.4438000000000000',
      volume: '1443214.9800000000000000',
      quoteVolume: 655875.790716,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '0.4438000000000000',
      high: '0.4650000000000000',
      low: '0.4400000000000000',
      close: '0.4479000000000000',
      volume: '700523.7700000000000000',
      quoteVolume: 313850.863647,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '0.4480000000000000',
      high: '0.4640000000000000',
      low: '0.4437000000000000',
      close: '0.4585000000000000',
      volume: '925529.6400000000000000',
      quoteVolume: 419693.727298,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '0.4592000000000000',
      high: '0.4644000000000000',
      low: '0.3975000000000000',
      close: '0.4050000000000000',
      volume: '2154193.2500000000000000',
      quoteVolume: 917345.275562,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '0.4047000000000000',
      high: '0.4749000000000000',
      low: '0.4007000000000000',
      close: '0.4723000000000000',
      volume: '1785356.2100000000000000',
      quoteVolume: 783690.750459,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '0.4722000000000000',
      high: '0.4740000000000000',
      low: '0.4300000000000000',
      close: '0.4341000000000000',
      volume: '1775526.6700000000000000',
      quoteVolume: 796546.586808,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '0.4335000000000000',
      high: '0.4412000000000000',
      low: '0.4145000000000000',
      close: '0.4274000000000000',
      volume: '854212.4500000000000000',
      quoteVolume: 365787.418355,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '0.4274000000000000',
      high: '0.4500000000000000',
      low: '0.4166000000000000',
      close: '0.4244000000000000',
      volume: '1059968.1300000000000000',
      quoteVolume: 455311.559954,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '0.4236000000000000',
      high: '0.4386000000000000',
      low: '0.4203000000000000',
      close: '0.4285000000000000',
      volume: '560690.8100000000000000',
      quoteVolume: 239706.048076,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '0.4286000000000000',
      high: '0.4372000000000000',
      low: '0.4173000000000000',
      close: '0.4221000000000000',
      volume: '331570.0000000000000000',
      quoteVolume: 140599.974044,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '0.4232000000000000',
      high: '0.4292000000000000',
      low: '0.4177000000000000',
      close: '0.4205000000000000',
      volume: '225429.9500000000000000',
      quoteVolume: 95660.216465,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '0.4203000000000000',
      high: '0.4255000000000000',
      low: '0.3929000000000000',
      close: '0.4036000000000000',
      volume: '946382.1900000000000000',
      quoteVolume: 387502.948292,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '0.4029000000000000',
      high: '0.4343000000000000',
      low: '0.4023000000000000',
      close: '0.4291000000000000',
      volume: '1686179.9000000000000000',
      quoteVolume: 703630.897735,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '0.4291000000000000',
      high: '0.4385000000000000',
      low: '0.4186000000000000',
      close: '0.4296000000000000',
      volume: '716328.1500000000000000',
      quoteVolume: 305884.809425,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '0.4296000000000000',
      high: '0.4766000000000000',
      low: '0.4256000000000000',
      close: '0.4521000000000000',
      volume: '1498764.0300000000000000',
      quoteVolume: 677393.543142,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '0.4521000000000000',
      high: '0.5107000000000000',
      low: '0.4519000000000000',
      close: '0.4611000000000000',
      volume: '3248280.8300000000000000',
      quoteVolume: 1570069.711841,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '0.4611000000000000',
      high: '0.4824000000000000',
      low: '0.4524000000000000',
      close: '0.4746000000000000',
      volume: '1381686.4300000000000000',
      quoteVolume: 643096.002798,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '0.4742000000000000',
      high: '0.5460000000000000',
      low: '0.4681000000000000',
      close: '0.4981000000000000',
      volume: '2887991.8600000000000000',
      quoteVolume: 1456511.395647,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '0.4982000000000000',
      high: '0.5181000000000000',
      low: '0.4382000000000000',
      close: '0.4629000000000000',
      volume: '2633054.2100000000000000',
      quoteVolume: 1262800.852306,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '0.4641000000000000',
      high: '0.5079000000000000',
      low: '0.4503000000000000',
      close: '0.4876000000000000',
      volume: '2105182.6700000000000000',
      quoteVolume: 1016416.428628,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '0.4872000000000000',
      high: '0.4880000000000000',
      low: '0.4671000000000000',
      close: '0.4805000000000000',
      volume: '1263356.6800000000000000',
      quoteVolume: 602730.177399,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '0.4814000000000000',
      high: '0.4994000000000000',
      low: '0.4722000000000000',
      close: '0.4871000000000000',
      volume: '1720792.4000000000000000',
      quoteVolume: 835254.863559,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '0.4858000000000000',
      high: '0.4960000000000000',
      low: '0.4831000000000000',
      close: '0.4858000000000000',
      volume: '577893.0600000000000000',
      quoteVolume: 281897.916414,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '0.4866000000000000',
      high: '0.4960000000000000',
      low: '0.4681000000000000',
      close: '0.4819000000000000',
      volume: '1768514.5100000000000000',
      quoteVolume: 850597.475821,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '0.4805000000000000',
      high: '0.4887000000000000',
      low: '0.4702000000000000',
      close: '0.4785000000000000',
      volume: '1352126.7300000000000000',
      quoteVolume: 645227.394195,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '0.4789000000000000',
      high: '0.4833000000000000',
      low: '0.4670000000000000',
      close: '0.4757000000000000',
      volume: '891826.0900000000000000',
      quoteVolume: 422057.338849,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '0.4760000000000000',
      high: '0.4800000000000000',
      low: '0.4648000000000000',
      close: '0.4770000000000000',
      volume: '530734.5900000000000000',
      quoteVolume: 250536.102196,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '0.4796000000000000',
      high: '0.4899000000000000',
      low: '0.4737000000000000',
      close: '0.4807000000000000',
      volume: '696146.2800000000000000',
      quoteVolume: 335078.549549,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '0.4818000000000000',
      high: '0.4842000000000000',
      low: '0.4342000000000000',
      close: '0.4548000000000000',
      volume: '1043991.8000000000000000',
      quoteVolume: 484257.113374,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '0.4551000000000000',
      high: '0.4655000000000000',
      low: '0.4470000000000000',
      close: '0.4646000000000000',
      volume: '863577.3300000000000000',
      quoteVolume: 392384.916353,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '0.4627000000000000',
      high: '0.4644000000000000',
      low: '0.4545000000000000',
      close: '0.4608000000000000',
      volume: '406749.6600000000000000',
      quoteVolume: 186648.714066,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '0.4606000000000000',
      high: '0.4621000000000000',
      low: '0.4485000000000000',
      close: '0.4531000000000000',
      volume: '634173.7500000000000000',
      quoteVolume: 287618.895525,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '0.4539000000000000',
      high: '0.4949000000000000',
      low: '0.4410000000000000',
      close: '0.4734000000000000',
      volume: '2441517.9900000000000000',
      quoteVolume: 1142857.563225,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '0.4729000000000000',
      high: '0.4850000000000000',
      low: '0.4480000000000000',
      close: '0.4605000000000000',
      volume: '1129722.2000000000000000',
      quoteVolume: 526180.143477,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '0.4603000000000000',
      high: '0.4997000000000000',
      low: '0.4556000000000000',
      close: '0.4946000000000000',
      volume: '1352736.3400000000000000',
      quoteVolume: 651471.143333,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '0.4967000000000000',
      high: '0.5196000000000000',
      low: '0.4846000000000000',
      close: '0.4992000000000000',
      volume: '1443876.8300000000000000',
      quoteVolume: 719069.643363,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '0.4984000000000000',
      high: '0.5042000000000000',
      low: '0.4887000000000000',
      close: '0.5008000000000000',
      volume: '877803.9200000000000000',
      quoteVolume: 434977.122532,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '0.5008000000000000',
      high: '0.5034000000000000',
      low: '0.4858000000000000',
      close: '0.4940000000000000',
      volume: '675007.1300000000000000',
      quoteVolume: 332877.149186,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '0.4938000000000000',
      high: '0.5410000000000000',
      low: '0.4868000000000000',
      close: '0.5084000000000000',
      volume: '2047013.3300000000000000',
      quoteVolume: 1057434.916151,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '0.5084000000000000',
      high: '0.5630000000000000',
      low: '0.5007000000000000',
      close: '0.5540000000000000',
      volume: '2288623.9600000000000000',
      quoteVolume: 1226137.818617,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '0.5533000000000000',
      high: '0.6000000000000000',
      low: '0.5326000000000000',
      close: '0.5811000000000000',
      volume: '4081948.8400000000000000',
      quoteVolume: 2328532.773552,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '0.5811000000000000',
      high: '0.6073000000000000',
      low: '0.5170000000000000',
      close: '0.5620000000000000',
      volume: '4435492.2100000000000000',
      quoteVolume: 2584274.57288,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '0.5588000000000000',
      high: '0.5839000000000000',
      low: '0.5195000000000000',
      close: '0.5396000000000000',
      volume: '2088035.7200000000000000',
      quoteVolume: 1155026.496367,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '0.5409000000000000',
      high: '0.5799000000000000',
      low: '0.5390000000000000',
      close: '0.5729000000000000',
      volume: '1039348.6800000000000000',
      quoteVolume: 579093.364827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '0.5735000000000000',
      high: '0.5900000000000000',
      low: '0.5557000000000000',
      close: '0.5691000000000000',
      volume: '1176149.9800000000000000',
      quoteVolume: 672544.531992,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '0.5677000000000000',
      high: '0.5918000000000000',
      low: '0.5655000000000000',
      close: '0.5773000000000000',
      volume: '956649.3600000000000000',
      quoteVolume: 552493.434727,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '0.5776000000000000',
      high: '0.5912000000000000',
      low: '0.5370000000000000',
      close: '0.5634000000000000',
      volume: '1559203.7500000000000000',
      quoteVolume: 875383.308889,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '0.5617000000000000',
      high: '0.5637000000000000',
      low: '0.5170000000000000',
      close: '0.5300000000000000',
      volume: '1284451.2500000000000000',
      quoteVolume: 682600.691592,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '0.5300000000000000',
      high: '0.5452000000000000',
      low: '0.5100000000000000',
      close: '0.5197000000000000',
      volume: '1259717.4200000000000000',
      quoteVolume: 662636.077743,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '0.5198000000000000',
      high: '0.5210000000000000',
      low: '0.4530000000000000',
      close: '0.4821000000000000',
      volume: '1891750.2700000000000000',
      quoteVolume: 920572.945172,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '0.4821000000000000',
      high: '0.5134000000000000',
      low: '0.4531000000000000',
      close: '0.4920000000000000',
      volume: '1247794.8300000000000000',
      quoteVolume: 609014.276187,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '0.4932000000000000',
      high: '0.5399000000000000',
      low: '0.4913000000000000',
      close: '0.5358000000000000',
      volume: '984745.9300000000000000',
      quoteVolume: 514382.609618,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '0.5366000000000000',
      high: '0.5449000000000000',
      low: '0.5073000000000000',
      close: '0.5225000000000000',
      volume: '825009.0900000000000000',
      quoteVolume: 431556.816813,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '0.5259000000000000',
      high: '0.5268000000000000',
      low: '0.4800000000000000',
      close: '0.4883000000000000',
      volume: '1617780.6900000000000000',
      quoteVolume: 812827.262155,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '0.4884000000000000',
      high: '0.5176000000000000',
      low: '0.4883000000000000',
      close: '0.4985000000000000',
      volume: '752244.2900000000000000',
      quoteVolume: 377882.383651,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '0.4998000000000000',
      high: '0.5046000000000000',
      low: '0.4880000000000000',
      close: '0.4998000000000000',
      volume: '625939.6200000000000000',
      quoteVolume: 310958.450695,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '0.4992000000000000',
      high: '0.5226000000000000',
      low: '0.4974000000000000',
      close: '0.5193000000000000',
      volume: '747995.9900000000000000',
      quoteVolume: 382966.982357,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '0.5187000000000000',
      high: '0.5390000000000000',
      low: '0.5083000000000000',
      close: '0.5095000000000000',
      volume: '857084.8000000000000000',
      quoteVolume: 447910.430574,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '0.5105000000000000',
      high: '0.5287000000000000',
      low: '0.5050000000000000',
      close: '0.5118000000000000',
      volume: '547954.6800000000000000',
      quoteVolume: 281962.973871,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '0.5117000000000000',
      high: '0.5139000000000000',
      low: '0.4752000000000000',
      close: '0.4963000000000000',
      volume: '755636.9500000000000000',
      quoteVolume: 371111.531726,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '0.4963000000000000',
      high: '0.5170000000000000',
      low: '0.4837000000000000',
      close: '0.4957000000000000',
      volume: '669329.7000000000000000',
      quoteVolume: 335305.551779,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '0.4920000000000000',
      high: '0.5040000000000000',
      low: '0.4756000000000000',
      close: '0.4756000000000000',
      volume: '798688.9900000000000000',
      quoteVolume: 391603.526803,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '0.4756000000000000',
      high: '0.4800000000000000',
      low: '0.4400000000000000',
      close: '0.4750000000000000',
      volume: '1341167.6500000000000000',
      quoteVolume: 614034.70833,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '0.4745000000000000',
      high: '0.4997000000000000',
      low: '0.4350000000000000',
      close: '0.4503000000000000',
      volume: '1289948.5700000000000000',
      quoteVolume: 607159.886379,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '0.4499000000000000',
      high: '0.4699000000000000',
      low: '0.4264000000000000',
      close: '0.4416000000000000',
      volume: '881943.9300000000000000',
      quoteVolume: 389402.998679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '0.4437000000000000',
      high: '0.4698000000000000',
      low: '0.4357000000000000',
      close: '0.4430000000000000',
      volume: '330467.6700000000000000',
      quoteVolume: 149494.592163,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '0.4448000000000000',
      high: '0.4600000000000000',
      low: '0.4403000000000000',
      close: '0.4510000000000000',
      volume: '429353.9200000000000000',
      quoteVolume: 194108.9041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '0.4510000000000000',
      high: '0.4546000000000000',
      low: '0.4240000000000000',
      close: '0.4338000000000000',
      volume: '394944.3300000000000000',
      quoteVolume: 173429.731609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '0.4334000000000000',
      high: '0.4771000000000000',
      low: '0.4320000000000000',
      close: '0.4727000000000000',
      volume: '610148.8000000000000000',
      quoteVolume: 281321.216962,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '0.4750000000000000',
      high: '0.4876000000000000',
      low: '0.4638000000000000',
      close: '0.4730000000000000',
      volume: '397476.9300000000000000',
      quoteVolume: 188524.095397,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '0.4745000000000000',
      high: '0.4757000000000000',
      low: '0.4600000000000000',
      close: '0.4630000000000000',
      volume: '228270.4000000000000000',
      quoteVolume: 106411.499404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '0.4643000000000000',
      high: '0.5223000000000000',
      low: '0.4607000000000000',
      close: '0.5110000000000000',
      volume: '1017233.7800000000000000',
      quoteVolume: 502255.676203,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '0.5118000000000000',
      high: '0.5151000000000000',
      low: '0.4783000000000000',
      close: '0.4964000000000000',
      volume: '640984.0600000000000000',
      quoteVolume: 318811.507836,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '0.4964000000000000',
      high: '0.5238000000000000',
      low: '0.4902000000000000',
      close: '0.5058000000000000',
      volume: '641774.3200000000000000',
      quoteVolume: 328895.388404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '0.5058000000000000',
      high: '0.5882000000000000',
      low: '0.4901000000000000',
      close: '0.5745000000000000',
      volume: '1593771.0200000000000000',
      quoteVolume: 868680.989601,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '0.5798000000000000',
      high: '0.6186000000000000',
      low: '0.5409000000000000',
      close: '0.5435000000000000',
      volume: '2267674.3000000000000000',
      quoteVolume: 1314846.891036,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '0.5433000000000000',
      high: '0.6157000000000000',
      low: '0.5212000000000000',
      close: '0.5940000000000000',
      volume: '1255624.9100000000000000',
      quoteVolume: 730122.02836,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '0.5940000000000000',
      high: '0.7040000000000000',
      low: '0.5873000000000000',
      close: '0.6403000000000000',
      volume: '3548028.1300000000000000',
      quoteVolume: 2284998.871122,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '0.6402000000000000',
      high: '0.7233000000000000',
      low: '0.6374000000000000',
      close: '0.6703000000000000',
      volume: '2987660.6900000000000000',
      quoteVolume: 2051444.51188,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '0.6731000000000000',
      high: '0.7484000000000000',
      low: '0.6517000000000000',
      close: '0.6752000000000000',
      volume: '3014329.8900000000000000',
      quoteVolume: 2076341.525905,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '0.6790000000000000',
      high: '0.6999000000000000',
      low: '0.6285000000000000',
      close: '0.6586000000000000',
      volume: '1760800.0800000000000000',
      quoteVolume: 1165285.921864,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '0.6607000000000000',
      high: '0.6872000000000000',
      low: '0.6336000000000000',
      close: '0.6474000000000000',
      volume: '1281864.7000000000000000',
      quoteVolume: 853179.428728,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '0.6474000000000000',
      high: '0.9565000000000000',
      low: '0.6332000000000000',
      close: '0.8280000000000000',
      volume: '7911014.0700000000000000',
      quoteVolume: 6371210.43235,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '0.8251000000000000',
      high: '0.8890000000000000',
      low: '0.7772000000000000',
      close: '0.8427000000000000',
      volume: '3798325.6400000000000000',
      quoteVolume: 3148626.361888,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '0.8427000000000000',
      high: '0.9389000000000000',
      low: '0.7551000000000000',
      close: '0.9280000000000000',
      volume: '4555532.1900000000000000',
      quoteVolume: 3883945.234255,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '0.9264000000000000',
      high: '0.9869000000000000',
      low: '0.8100000000000000',
      close: '0.8911000000000000',
      volume: '5725645.6900000000000000',
      quoteVolume: 5097003.16005,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '0.8950000000000000',
      high: '0.9592000000000000',
      low: '0.8457000000000000',
      close: '0.9359000000000000',
      volume: '2389369.0400000000000000',
      quoteVolume: 2179041.174403,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '0.9360000000000000',
      high: '1.0500000000000000',
      low: '0.9279000000000000',
      close: '0.9934000000000000',
      volume: '4785605.6200000000000000',
      quoteVolume: 4702849.494509,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '0.9927000000000000',
      high: '1.1430000000000000',
      low: '0.9460000000000000',
      close: '1.1170000000000000',
      volume: '2936467.3000000000000000',
      quoteVolume: 2982285.87569,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '1.1146000000000000',
      high: '1.2500000000000000',
      low: '1.0600000000000000',
      close: '1.1798000000000000',
      volume: '5046994.2000000000000000',
      quoteVolume: 5745623.487639,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '1.1855000000000000',
      high: '1.4740000000000000',
      low: '1.1301000000000000',
      close: '1.2205000000000000',
      volume: '14821357.0900000000000000',
      quoteVolume: 19619790.33193,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '1.2205000000000000',
      high: '1.3655000000000000',
      low: '1.1418000000000000',
      close: '1.3392000000000000',
      volume: '6874618.3900000000000000',
      quoteVolume: 8592874.224192,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '1.3393000000000000',
      high: '1.4347000000000000',
      low: '1.2216000000000000',
      close: '1.3314000000000000',
      volume: '5097940.0500000000000000',
      quoteVolume: 6795296.790503,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '1.3257000000000000',
      high: '1.3376000000000000',
      low: '1.1005000000000000',
      close: '1.1139000000000000',
      volume: '3990172.6100000000000000',
      quoteVolume: 4655614.840081993,
      btcVolume: 581.9913178767249,
      usdVolume: 4655614.840081993,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '1.1181000000000000',
      high: '1.1824000000000000',
      low: '1.0340000000000000',
      close: '1.0756000000000000',
      volume: '2787144.3800000000000000',
      quoteVolume: 3053065.8027050006,
      btcVolume: 377.16663921841183,
      usdVolume: 3053065.8027050006,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '1.0806000000000000',
      high: '1.2750000000000000',
      low: '1.0806000000000000',
      close: '1.2119000000000000',
      volume: '3879947.9900000000000000',
      quoteVolume: 4585345.262517948,
      btcVolume: 526.2433979570677,
      usdVolume: 4585345.262517948,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '1.2199000000000000',
      high: '1.3500000000000000',
      low: '1.1444000000000000',
      close: '1.2921000000000000',
      volume: '2591508.8200000000000000',
      quoteVolume: 3228837.802578005,
      btcVolume: 371.1273709190563,
      usdVolume: 3228837.802578005,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '1.2782000000000000',
      high: '1.2844000000000000',
      low: '1.1497000000000000',
      close: '1.1665000000000000',
      volume: '2450800.8100000000000000',
      quoteVolume: 2938928.7943600034,
      btcVolume: 340.2041830509092,
      usdVolume: 2938928.7943600034,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '1.1780000000000000',
      high: '1.2250000000000000',
      low: '0.9044000000000000',
      close: '1.0057000000000000',
      volume: '6305578.1300000000000000',
      quoteVolume: 6787576.3371680295,
      btcVolume: 791.9128840898691,
      usdVolume: 6787576.3371680295,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '1.0063000000000000',
      high: '1.0444000000000000',
      low: '0.9330000000000000',
      close: '1.0195000000000000',
      volume: '4936161.7800000000000000',
      quoteVolume: 4857535.299134998,
      btcVolume: 581.7438750463537,
      usdVolume: 4857535.299134998,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '1.0252000000000000',
      high: '1.0597000000000000',
      low: '0.9511000000000000',
      close: '0.9560000000000000',
      volume: '2558921.2100000000000000',
      quoteVolume: 2554370.774409004,
      btcVolume: 299.04123365519683,
      usdVolume: 2554370.774409004,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '0.9536000000000000',
      high: '1.0554000000000000',
      low: '0.9323000000000000',
      close: '1.0155000000000000',
      volume: '3448639.2900000000000000',
      quoteVolume: 3433793.2090730225,
      btcVolume: 396.5090133601953,
      usdVolume: 3433793.2090730225,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '1.0155000000000000',
      high: '1.0200000000000000',
      low: '0.8853000000000000',
      close: '0.9000000000000000',
      volume: '3218003.4000000000000000',
      quoteVolume: 3096993.8518149965,
      btcVolume: 365.97881939525035,
      usdVolume: 3096993.8518149965,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '0.8985000000000000',
      high: '0.9299000000000000',
      low: '0.8368000000000000',
      close: '0.8850000000000000',
      volume: '4563258.6100000000000000',
      quoteVolume: 4005371.5748359957,
      btcVolume: 509.9416978186965,
      usdVolume: 4005371.5748359957,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '0.8850000000000000',
      high: '1.0991000000000000',
      low: '0.8838000000000000',
      close: '1.0959000000000000',
      volume: '10134365.7300000000000000',
      quoteVolume: 10259929.50928907,
      btcVolume: 1323.3373278111412,
      usdVolume: 10259929.50928907,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '1.0960000000000000',
      high: '1.2700000000000000',
      low: '1.0556000000000000',
      close: '1.1098000000000000',
      volume: '11104062.4700000000000000',
      quoteVolume: 12707991.386383945,
      btcVolume: 1649.824982729021,
      usdVolume: 12707991.386383945,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '1.1099000000000000',
      high: '1.2589000000000000',
      low: '1.0710000000000000',
      close: '1.1753000000000000',
      volume: '6318630.4200000000000000',
      quoteVolume: 7452520.230448068,
      btcVolume: 944.3447342802139,
      usdVolume: 7452520.230448068,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '1.1747000000000000',
      high: '1.1991000000000000',
      low: '1.1165000000000000',
      close: '1.1326000000000000',
      volume: '2532914.5100000000000000',
      quoteVolume: 2922870.3576520067,
      btcVolume: 369.3243148377816,
      usdVolume: 2922870.3576520067,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '1.1376000000000000',
      high: '1.1486000000000000',
      low: '1.0085000000000000',
      close: '1.0595000000000000',
      volume: '3542850.8800000000000000',
      quoteVolume: 3804204.519311013,
      btcVolume: 491.5768447574139,
      usdVolume: 3804204.519311013,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '1.0595000000000000',
      high: '1.2104000000000000',
      low: '1.0301000000000000',
      close: '1.2060000000000000',
      volume: '3560912.7500000000000000',
      quoteVolume: 4027483.4204219743,
      btcVolume: 514.0312403954563,
      usdVolume: 4027483.4204219743,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '1.2043000000000000',
      high: '1.2070000000000000',
      low: '1.0876000000000000',
      close: '1.1243000000000000',
      volume: '3064771.3900000000000000',
      quoteVolume: 3504537.899613992,
      btcVolume: 445.75194109413064,
      usdVolume: 3504537.899613992,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '1.1241000000000000',
      high: '1.1643000000000000',
      low: '1.0875000000000000',
      close: '1.1448000000000000',
      volume: '2552167.9900000000000000',
      quoteVolume: 2871559.2307969993,
      btcVolume: 359.4040334628175,
      usdVolume: 2871559.2307969993,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '1.1448000000000000',
      high: '1.9700000000000000',
      low: '1.1126000000000000',
      close: '1.8680000000000000',
      volume: '34026255.5700000000000000',
      quoteVolume: 58291200.9484317,
      btcVolume: 7099.103367062914,
      usdVolume: 58291200.9484317,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '1.8680000000000000',
      high: '1.8936000000000000',
      low: '1.4900000000000000',
      close: '1.6395000000000000',
      volume: '33206570.6700000000000000',
      quoteVolume: 54533735.08588836,
      btcVolume: 6570.521290055457,
      usdVolume: 54533735.08588836,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '1.6364000000000000',
      high: '1.8065000000000000',
      low: '1.6022000000000000',
      close: '1.7179000000000000',
      volume: '10700181.1400000000000000',
      quoteVolume: 18387957.79867493,
      btcVolume: 2121.574515120722,
      usdVolume: 18387957.79867493,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '1.7180000000000000',
      high: '1.7924000000000000',
      low: '1.6000000000000000',
      close: '1.6621000000000000',
      volume: '8261300.1500000000000000',
      quoteVolume: 13964950.109855045,
      btcVolume: 1544.6562665703477,
      usdVolume: 13964950.109855045,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '1.6651000000000000',
      high: '2.0776000000000000',
      low: '1.6525000000000000',
      close: '1.9632000000000000',
      volume: '17062882.5500000000000000',
      quoteVolume: 32436293.282424346,
      btcVolume: 3512.1607543888294,
      usdVolume: 32436293.282424346,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '1.9631000000000000',
      high: '2.0185000000000000',
      low: '1.7600000000000000',
      close: '1.8194000000000000',
      volume: '13903321.3000000000000000',
      quoteVolume: 25882989.567272145,
      btcVolume: 2819.6184477064003,
      usdVolume: 25816336.76297314,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '1.8158000000000000',
      high: '1.9016000000000000',
      low: '1.7869000000000000',
      close: '1.8353000000000000',
      volume: '4857233.6000000000000000',
      quoteVolume: 8953905.75995399,
      btcVolume: 980.2564405252405,
      usdVolume: 8953905.75995399,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '1.8352000000000000',
      high: '1.8865000000000000',
      low: '1.6862000000000000',
      close: '1.7656000000000000',
      volume: '5058765.9500000000000000',
      quoteVolume: 8995915.657141935,
      btcVolume: 964.4591378098079,
      usdVolume: 8995915.657141935,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '1.7671000000000000',
      high: '1.7900000000000000',
      low: '1.6100000000000000',
      close: '1.6883000000000000',
      volume: '6929829.1600000000000000',
      quoteVolume: 11630088.984655974,
      btcVolume: 1192.1055771385234,
      usdVolume: 11630088.984655974,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '1.6895000000000000',
      high: '1.9470000000000000',
      low: '1.6827000000000000',
      close: '1.8428000000000000',
      volume: '8390022.4600000000000000',
      quoteVolume: 15123936.817318201,
      btcVolume: 1410.6256719829264,
      usdVolume: 15123936.817318201,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '1.8429000000000000',
      high: '1.9100000000000000',
      low: '1.7702000000000000',
      close: '1.8169000000000000',
      volume: '4593412.3100000000000000',
      quoteVolume: 8418104.00219802,
      btcVolume: 775.4769301470001,
      usdVolume: 8418104.00219802,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '1.8169000000000000',
      high: '1.8884000000000000',
      low: '1.7900000000000000',
      close: '1.8689000000000000',
      volume: '2384487.8200000000000000',
      quoteVolume: 4400730.711527992,
      btcVolume: 403.4557862186641,
      usdVolume: 4400730.711527992,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '1.8689000000000000',
      high: '2.4273000000000000',
      low: '1.8613000000000000',
      close: '2.1928000000000000',
      volume: '21998410.4800000000000000',
      quoteVolume: 47761526.31202764,
      btcVolume: 4201.741421999988,
      usdVolume: 47761526.31202764,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '2.1931000000000000',
      high: '2.5000000000000000',
      low: '2.0231000000000000',
      close: '2.2800000000000000',
      volume: '15800691.2200000000000000',
      quoteVolume: 35017061.17698764,
      btcVolume: 2716.258242985611,
      usdVolume: 35017061.17698764,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '2.2821000000000000',
      high: '2.5550000000000000',
      low: '2.1123000000000000',
      close: '2.2879000000000000',
      volume: '13867785.5900000000000000',
      quoteVolume: 32591424.308536824,
      btcVolume: 2692.462710930972,
      usdVolume: 32591424.308536824,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '2.2912000000000000',
      high: '3.0981000000000000',
      low: '2.2150000000000000',
      close: '3.0392000000000000',
      volume: '16190974.7700000000000000',
      quoteVolume: 43067890.31031614,
      btcVolume: 3571.5324626501774,
      usdVolume: 43067890.31031614,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '3.0391000000000000',
      high: '4.5826000000000000',
      low: '2.9499000000000000',
      close: '3.7125000000000000',
      volume: '55501686.3100000000000000',
      quoteVolume: 212256136.05785748,
      btcVolume: 17792.420778535194,
      usdVolume: 212256136.05785748,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '3.7051000000000000',
      high: '4.2200000000000000',
      low: '3.2300000000000000',
      close: '3.3869000000000000',
      volume: '25190926.7600000000000000',
      quoteVolume: 97105333.71238178,
      btcVolume: 8374.845437346688,
      usdVolume: 97105333.71238178,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '3.3884000000000000',
      high: '3.8620000000000000',
      low: '2.9266000000000000',
      close: '3.6369000000000000',
      volume: '25321151.3600000000000000',
      quoteVolume: 85723441.75518183,
      btcVolume: 8111.5203882492,
      usdVolume: 85723441.75518183,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '3.6476000000000000',
      high: '3.9914000000000000',
      low: '3.2010000000000000',
      close: '3.7453000000000000',
      volume: '26688227.6900000000000000',
      quoteVolume: 97119111.08506477,
      btcVolume: 9423.268210581768,
      usdVolume: 97119111.08506477,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '3.7441000000000000',
      high: '3.8857000000000000',
      low: '3.4769000000000000',
      close: '3.4900000000000000',
      volume: '11091453.7900000000000000',
      quoteVolume: 40850492.97302097,
      btcVolume: 3600.00749644261,
      usdVolume: 40850492.97302097,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '3.4854000000000000',
      high: '3.6100000000000000',
      low: '3.2040000000000000',
      close: '3.4530000000000000',
      volume: '9871917.6900000000000000',
      quoteVolume: 33779334.70123714,
      btcVolume: 2886.5694636866006,
      usdVolume: 33779334.70123714,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '3.4555000000000000',
      high: '3.7500000000000000',
      low: '3.4410000000000000',
      close: '3.6400000000000000',
      volume: '10497021.6500000000000000',
      quoteVolume: 37787417.88724579,
      btcVolume: 3395.0022936467367,
      usdVolume: 37787417.88724579,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '3.6400000000000000',
      high: '3.6611000000000000',
      low: '3.4384000000000000',
      close: '3.4659000000000000',
      volume: '4978418.4100000000000000',
      quoteVolume: 17630030.877459843,
      btcVolume: 1551.1898933985874,
      usdVolume: 17630030.877459843,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '3.4650000000000000',
      high: '3.5038000000000000',
      low: '3.2500000000000000',
      close: '3.2750000000000000',
      volume: '5553651.6400000000000000',
      quoteVolume: 18694548.744352836,
      btcVolume: 1655.1485012561434,
      usdVolume: 18694548.744352836,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '3.2750000000000000',
      high: '3.5731000000000000',
      low: '3.2500000000000000',
      close: '3.3362000000000000',
      volume: '5268983.9800000000000000',
      quoteVolume: 17808772.169726066,
      btcVolume: 1520.284319482537,
      usdVolume: 17808772.169726066,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '3.3334000000000000',
      high: '3.3741000000000000',
      low: '2.9401000000000000',
      close: '3.0670000000000000',
      volume: '8673443.7700000000000000',
      quoteVolume: 27298953.85568921,
      btcVolume: 2188.425469158119,
      usdVolume: 27298953.85568921,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '3.0670000000000000',
      high: '3.2568000000000000',
      low: '2.8578000000000000',
      close: '3.1166000000000000',
      volume: '9527421.0200000000000000',
      quoteVolume: 29323317.465814188,
      btcVolume: 2364.92290456046,
      usdVolume: 29323317.465814188,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '3.1166000000000000',
      high: '3.1555000000000000',
      low: '2.7100000000000000',
      close: '2.7853000000000000',
      volume: '7262068.2500000000000000',
      quoteVolume: 21179286.916986044,
      btcVolume: 1836.1996771189365,
      usdVolume: 21179286.916986044,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '2.7870000000000000',
      high: '3.3282000000000000',
      low: '2.6000000000000000',
      close: '3.1660000000000000',
      volume: '11731863.1100000000000000',
      quoteVolume: 35185953.587122776,
      btcVolume: 3037.647143684832,
      usdVolume: 35185953.587122776,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '3.1660000000000000',
      high: '3.2953000000000000',
      low: '3.0400000000000000',
      close: '3.1652000000000000',
      volume: '9065258.2000000000000000',
      quoteVolume: 28811293.98759913,
      btcVolume: 2530.9180949987526,
      usdVolume: 28811293.98759913,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '3.1689000000000000',
      high: '3.2413000000000000',
      low: '2.7611000000000000',
      close: '2.7856000000000000',
      volume: '8164892.0900000000000000',
      quoteVolume: 24328975.206776988,
      btcVolume: 2259.0976435334483,
      usdVolume: 24328975.206776988,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '2.7792000000000000',
      high: '2.8498000000000000',
      low: '2.5801000000000000',
      close: '2.6913000000000000',
      volume: '7318353.9000000000000000',
      quoteVolume: 19951566.39118311,
      btcVolume: 1928.7548326502576,
      usdVolume: 19951566.39118311,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '2.6911000000000000',
      high: '2.7749000000000000',
      low: '2.1521000000000000',
      close: '2.1948000000000000',
      volume: '7934695.5900000000000000',
      quoteVolume: 19458898.407283843,
      btcVolume: 1935.2094152580992,
      usdVolume: 19458898.407283843,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '2.1947000000000000',
      high: '2.6000000000000000',
      low: '2.0531000000000000',
      close: '2.3980000000000000',
      volume: '13183927.9300000000000000',
      quoteVolume: 30803035.246100836,
      btcVolume: 3208.952057742877,
      usdVolume: 30803035.246100836,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '2.3951000000000000',
      high: '2.8094000000000000',
      low: '2.3542000000000000',
      close: '2.6700000000000000',
      volume: '13345016.8600000000000000',
      quoteVolume: 34825584.843023226,
      btcVolume: 3500.7237591055887,
      usdVolume: 34764284.05553622,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '2.6700000000000000',
      high: '2.7490000000000000',
      low: '2.4910000000000000',
      close: '2.6046000000000000',
      volume: '5510181.5700000000000000',
      quoteVolume: 14293523.817000896,
      btcVolume: 1368.4110094593677,
      usdVolume: 14293523.817000896,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '2.6020000000000000',
      high: '2.7314000000000000',
      low: '2.5751000000000000',
      close: '2.6370000000000000',
      volume: '4596378.0700000000000000',
      quoteVolume: 12202999.48393705,
      btcVolume: 1147.4765675933133,
      usdVolume: 12202999.48393705,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '2.6369000000000000',
      high: '2.6650000000000000',
      low: '2.4600000000000000',
      close: '2.5319000000000000',
      volume: '3349432.2400000000000000',
      quoteVolume: 8531199.090973042,
      btcVolume: 809.0792883766617,
      usdVolume: 8531199.090973042,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '2.5319000000000000',
      high: '2.5857000000000000',
      low: '2.3732000000000000',
      close: '2.4078000000000000',
      volume: '3415186.7600000000000000',
      quoteVolume: 8425751.27914594,
      btcVolume: 810.0485394775847,
      usdVolume: 8425751.27914594,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '2.4073000000000000',
      high: '2.5200000000000000',
      low: '2.2489000000000000',
      close: '2.3535000000000000',
      volume: '5670086.3900000000000000',
      quoteVolume: 13434498.579576982,
      btcVolume: 1334.7270621160683,
      usdVolume: 13434498.579576982,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '2.3559000000000000',
      high: '2.5131000000000000',
      low: '2.2700000000000000',
      close: '2.3999000000000000',
      volume: '5308279.8100000000000000',
      quoteVolume: 12686743.212995907,
      btcVolume: 1303.671357536717,
      usdVolume: 12686743.212995907,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '2.3971000000000000',
      high: '2.5149000000000000',
      low: '2.3595000000000000',
      close: '2.3615000000000000',
      volume: '4016580.8300000000000000',
      quoteVolume: 9840046.642877948,
      btcVolume: 982.9918828701473,
      usdVolume: 9840046.642877948,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '2.3615000000000000',
      high: '2.4300000000000000',
      low: '2.3201000000000000',
      close: '2.3989000000000000',
      volume: '1971791.7400000000000000',
      quoteVolume: 4688945.92508803,
      btcVolume: 480.4188696425642,
      usdVolume: 4688945.92508803,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '2.3954000000000000',
      high: '2.4600000000000000',
      low: '2.1525000000000000',
      close: '2.2453000000000000',
      volume: '4245834.2700000000000000',
      quoteVolume: 9865099.724368896,
      btcVolume: 1019.8334976316504,
      usdVolume: 9865099.724368896,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '2.2372000000000000',
      high: '2.2733000000000000',
      low: '2.1000000000000000',
      close: '2.2156000000000000',
      volume: '1902749.3100000000000000',
      quoteVolume: 4200464.91369699,
      btcVolume: 443.62670497298694,
      usdVolume: 4200464.91369699,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '2.2178000000000000',
      high: '2.2482000000000000',
      low: '2.1100000000000000',
      close: '2.1251000000000000',
      volume: '2215943.5300000000000000',
      quoteVolume: 4805773.902062991,
      btcVolume: 503.4159560790306,
      usdVolume: 4805773.902062991,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '2.1250000000000000',
      high: '2.1854000000000000',
      low: '1.9694000000000000',
      close: '2.0682000000000000',
      volume: '4210835.6200000000000000',
      quoteVolume: 8720296.91253309,
      btcVolume: 916.5223854094169,
      usdVolume: 8720296.91253309,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '2.0672000000000000',
      high: '2.2500000000000000',
      low: '2.0589000000000000',
      close: '2.2102000000000000',
      volume: '4558064.6100000000000000',
      quoteVolume: 9920046.062618986,
      btcVolume: 1007.3882816663967,
      usdVolume: 9920046.062618986,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '2.2169000000000000',
      high: '2.2310000000000000',
      low: '2.1054000000000000',
      close: '2.1610000000000000',
      volume: '2890541.3500000000000000',
      quoteVolume: 6235734.283716941,
      btcVolume: 618.792071520022,
      usdVolume: 6235734.283716941,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '2.1612000000000000',
      high: '2.4900000000000000',
      low: '2.1275000000000000',
      close: '2.4271000000000000',
      volume: '6319480.9600000000000000',
      quoteVolume: 14629814.693532972,
      btcVolume: 1400.4593413301136,
      usdVolume: 14629814.693532972,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '2.4269000000000000',
      high: '2.7342000000000000',
      low: '2.3569000000000000',
      close: '2.4211000000000000',
      volume: '11323687.1300000000000000',
      quoteVolume: 29135005.95525144,
      btcVolume: 2701.80633921746,
      usdVolume: 29135005.95525144,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '2.4186000000000000',
      high: '2.6044000000000000',
      low: '2.3811000000000000',
      close: '2.5099000000000000',
      volume: '6257429.6800000000000000',
      quoteVolume: 15626611.666627988,
      btcVolume: 1446.5228705557931,
      usdVolume: 15626611.666627988,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '2.5097000000000000',
      high: '2.6495000000000000',
      low: '2.4562000000000000',
      close: '2.4562000000000000',
      volume: '5389254.3200000000000000',
      quoteVolume: 13710085.081441978,
      btcVolume: 1176.551959779662,
      usdVolume: 13710085.081441978,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '2.4599000000000000',
      high: '2.5181000000000000',
      low: '2.3100000000000000',
      close: '2.4785000000000000',
      volume: '6024056.7500000000000000',
      quoteVolume: 14593117.820943044,
      btcVolume: 1242.3745698550665,
      usdVolume: 14593117.820943044,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '2.4822000000000000',
      high: '2.5000000000000000',
      low: '2.3502000000000000',
      close: '2.3826000000000000',
      volume: '3376864.0700000000000000',
      quoteVolume: 8167003.771067984,
      btcVolume: 696.2613814905944,
      usdVolume: 8167003.771067984,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '2.3883000000000000',
      high: '2.3998000000000000',
      low: '2.2713000000000000',
      close: '2.2755000000000000',
      volume: '2863707.1900000000000000',
      quoteVolume: 6666989.981878993,
      btcVolume: 564.8964889382114,
      usdVolume: 6666989.981878993,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '2.2784000000000000',
      high: '2.2919000000000000',
      low: '2.1510000000000000',
      close: '2.1669000000000000',
      volume: '2368470.3900000000000000',
      quoteVolume: 5268428.711106007,
      btcVolume: 444.782865591571,
      usdVolume: 5268428.711106007,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '2.1690000000000000',
      high: '2.4091000000000000',
      low: '2.1306000000000000',
      close: '2.3336000000000000',
      volume: '5233238.7500000000000000',
      quoteVolume: 12050390.512594998,
      btcVolume: 1035.7246734427822,
      usdVolume: 12050390.512594998,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '2.3336000000000000',
      high: '2.4900000000000000',
      low: '2.2901000000000000',
      close: '2.4157000000000000',
      volume: '3701976.9800000000000000',
      quoteVolume: 8866596.82357804,
      btcVolume: 777.1699540463694,
      usdVolume: 8866596.82357804,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '2.4157000000000000',
      high: '2.4790000000000000',
      low: '2.3350000000000000',
      close: '2.3900000000000000',
      volume: '2579648.7400000000000000',
      quoteVolume: 6209796.363283018,
      btcVolume: 544.0263252504219,
      usdVolume: 6209796.363283018,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '2.3846000000000000',
      high: '2.4679000000000000',
      low: '2.3060000000000000',
      close: '2.4229000000000000',
      volume: '3150417.3700000000000000',
      quoteVolume: 7506682.66399902,
      btcVolume: 678.7973027516069,
      usdVolume: 7506682.66399902,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '2.4190000000000000',
      high: '2.6031000000000000',
      low: '2.1630000000000000',
      close: '2.2842000000000000',
      volume: '6699207.3700000000000000',
      quoteVolume: 16231062.046769118,
      btcVolume: 1549.2558632873051,
      usdVolume: 16231062.046769118,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '2.2840000000000000',
      high: '2.4506000000000000',
      low: '2.2565000000000000',
      close: '2.4232000000000000',
      volume: '2806801.4000000000000000',
      quoteVolume: 6611413.672988072,
      btcVolume: 652.6110331933832,
      usdVolume: 6611413.672988072,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '2.4225000000000000',
      high: '2.4590000000000000',
      low: '2.2270000000000000',
      close: '2.3000000000000000',
      volume: '3572174.4000000000000000',
      quoteVolume: 8302828.475309981,
      btcVolume: 815.1156611012854,
      usdVolume: 8302828.475309981,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '2.3046000000000000',
      high: '2.4190000000000000',
      low: '2.2820000000000000',
      close: '2.4036000000000000',
      volume: '2035414.3900000000000000',
      quoteVolume: 4797115.851594993,
      btcVolume: 466.47840144058904,
      usdVolume: 4797115.851594993,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '2.4050000000000000',
      high: '2.5657000000000000',
      low: '2.3729000000000000',
      close: '2.5051000000000000',
      volume: '3610375.3000000000000000',
      quoteVolume: 8909798.939858058,
      btcVolume: 863.7326634348484,
      usdVolume: 8909798.939858058,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '2.5055000000000000',
      high: '2.6000000000000000',
      low: '2.4508000000000000',
      close: '2.4958000000000000',
      volume: '4280607.5800000000000000',
      quoteVolume: 10853323.38349604,
      btcVolume: 1017.6371082460578,
      usdVolume: 10853323.38349604,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '2.4943000000000000',
      high: '2.5401000000000000',
      low: '2.3354000000000000',
      close: '2.3624000000000000',
      volume: '3474086.6000000000000000',
      quoteVolume: 8391667.38601199,
      btcVolume: 780.6798427463843,
      usdVolume: 8391667.38601199,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '2.3622000000000000',
      high: '2.3710000000000000',
      low: '2.1500000000000000',
      close: '2.2054000000000000',
      volume: '4915776.4000000000000000',
      quoteVolume: 11029113.309874035,
      btcVolume: 1081.9973137236552,
      usdVolume: 11029113.309874035,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '2.2058000000000000',
      high: '2.2896000000000000',
      low: '2.1350000000000000',
      close: '2.2227000000000000',
      volume: '2980708.5400000000000000',
      quoteVolume: 6618775.966529956,
      btcVolume: 660.0412390163551,
      usdVolume: 6618775.966529956,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '2.2231000000000000',
      high: '2.2950000000000000',
      low: '2.1723000000000000',
      close: '2.2343000000000000',
      volume: '3274284.1600000000000000',
      quoteVolume: 7324880.720449999,
      btcVolume: 715.1730454658111,
      usdVolume: 7324880.720449999,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '2.2343000000000000',
      high: '2.2545000000000000',
      low: '2.1537000000000000',
      close: '2.1681000000000000',
      volume: '1629473.9800000000000000',
      quoteVolume: 3574006.0448640008,
      btcVolume: 352.9833991449766,
      usdVolume: 3574006.0448640008,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '2.1704000000000000',
      high: '2.1804000000000000',
      low: '2.0255000000000000',
      close: '2.0780000000000000',
      volume: '2603393.1400000000000000',
      quoteVolume: 5492656.678655035,
      btcVolume: 545.2530944238928,
      usdVolume: 5492656.678655035,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '2.0783000000000000',
      high: '2.1546000000000000',
      low: '2.0450000000000000',
      close: '2.0739000000000000',
      volume: '2094166.6100000000000000',
      quoteVolume: 4389987.473204011,
      btcVolume: 423.4809452125515,
      usdVolume: 4389987.473204011,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '2.0737000000000000',
      high: '2.1788000000000000',
      low: '2.0050000000000000',
      close: '2.1128000000000000',
      volume: '2811534.7300000000000000',
      quoteVolume: 5891385.969969013,
      btcVolume: 579.2737813324595,
      usdVolume: 5891385.969969013,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '2.1098000000000000',
      high: '2.1134000000000000',
      low: '1.8750000000000000',
      close: '1.8957000000000000',
      volume: '2925102.1700000000000000',
      quoteVolume: 5826671.213469991,
      btcVolume: 585.9165069079237,
      usdVolume: 5826671.213469991,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '1.8956000000000000',
      high: '1.8956000000000000',
      low: '1.7253000000000000',
      close: '1.7744000000000000',
      volume: '4172574.3900000000000000',
      quoteVolume: 7534972.244916026,
      btcVolume: 791.9607358540669,
      usdVolume: 7534972.244916026,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '1.7763000000000000',
      high: '1.8913000000000000',
      low: '1.7365000000000000',
      close: '1.7960000000000000',
      volume: '2660522.1000000000000000',
      quoteVolume: 4851727.818894994,
      btcVolume: 508.2563024568634,
      usdVolume: 4851727.818894994,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '1.7958000000000000',
      high: '1.8535000000000000',
      low: '1.7400000000000000',
      close: '1.7791000000000000',
      volume: '1818973.5000000000000000',
      quoteVolume: 3255608.6478299913,
      btcVolume: 339.9346500517163,
      usdVolume: 3255608.6478299913,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '1.7788000000000000',
      high: '1.8000000000000000',
      low: '1.7170000000000000',
      close: '1.7686000000000000',
      volume: '1329827.2100000000000000',
      quoteVolume: 2338722.617214002,
      btcVolume: 242.99787843430724,
      usdVolume: 2338722.617214002,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '1.7696000000000000',
      high: '1.8900000000000000',
      low: '1.7272000000000000',
      close: '1.8287000000000000',
      volume: '3042460.9700000000000000',
      quoteVolume: 5523573.6413650215,
      btcVolume: 553.7959499063911,
      usdVolume: 5523573.6413650215,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '1.8307000000000000',
      high: '1.9424000000000000',
      low: '1.8147000000000000',
      close: '1.8490000000000000',
      volume: '3198661.0900000000000000',
      quoteVolume: 5978206.5396679975,
      btcVolume: 570.2827065368367,
      usdVolume: 5978206.5396679975,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '1.8486000000000000',
      high: '1.8700000000000000',
      low: '1.7167000000000000',
      close: '1.7793000000000000',
      volume: '3545459.8000000000000000',
      quoteVolume: 6323373.737698996,
      btcVolume: 599.008568072105,
      usdVolume: 6323373.737698996,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '1.7784000000000000',
      high: '1.8624000000000000',
      low: '1.7680000000000000',
      close: '1.7921000000000000',
      volume: '2705662.3600000000000000',
      quoteVolume: 4910186.863704015,
      btcVolume: 465.71608792980777,
      usdVolume: 4910186.863704015,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '1.7919000000000000',
      high: '1.8600000000000000',
      low: '1.6767000000000000',
      close: '1.7226000000000000',
      volume: '3028097.8200000000000000',
      quoteVolume: 5355618.6108840015,
      btcVolume: 506.31943933656817,
      usdVolume: 5355618.6108840015,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '1.7222000000000000',
      high: '1.8060000000000000',
      low: '1.7085000000000000',
      close: '1.7672000000000000',
      volume: '2401823.2500000000000000',
      quoteVolume: 4215075.491124999,
      btcVolume: 404.7663920454655,
      usdVolume: 4215075.491124999,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '1.7672000000000000',
      high: '1.9054000000000000',
      low: '1.7610000000000000',
      close: '1.8275000000000000',
      volume: '3500287.6000000000000000',
      quoteVolume: 6422308.280667982,
      btcVolume: 617.3872473153666,
      usdVolume: 6422308.280667982,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '1.8290000000000000',
      high: '1.8731000000000000',
      low: '1.7610000000000000',
      close: '1.7872000000000000',
      volume: '3473067.9800000000000000',
      quoteVolume: 6305180.84346195,
      btcVolume: 613.2646136601918,
      usdVolume: 6305180.84346195,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '1.7883000000000000',
      high: '1.8326000000000000',
      low: '1.7730000000000000',
      close: '1.7998000000000000',
      volume: '2711918.0700000000000000',
      quoteVolume: 4888778.085218002,
      btcVolume: 478.77419074309563,
      usdVolume: 4888778.085218002,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '1.7988000000000000',
      high: '1.8258000000000000',
      low: '1.7136000000000000',
      close: '1.7301000000000000',
      volume: '2214016.1100000000000000',
      quoteVolume: 3890643.726558997,
      btcVolume: 386.83840067774776,
      usdVolume: 3890643.726558997,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '1.7301000000000000',
      high: '1.7458000000000000',
      low: '1.6918000000000000',
      close: '1.7073000000000000',
      volume: '2228942.3400000000000000',
      quoteVolume: 3823755.5435819686,
      btcVolume: 374.2899559647845,
      usdVolume: 3823755.5435819686,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '1.7073000000000000',
      high: '1.7099000000000000',
      low: '1.5100000000000000',
      close: '1.5759000000000000',
      volume: '6637630.0700000000000000',
      quoteVolume: 10595656.219862076,
      btcVolume: 1032.4407207665965,
      usdVolume: 10595656.219862076,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '1.5761000000000000',
      high: '1.6375000000000000',
      low: '1.5239000000000000',
      close: '1.5634000000000000',
      volume: '5678360.6100000000000000',
      quoteVolume: 9008346.159965044,
      btcVolume: 872.4429504635938,
      usdVolume: 9008346.159965044,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '1.5634000000000000',
      high: '1.6458000000000000',
      low: '1.5600000000000000',
      close: '1.6104000000000000',
      volume: '4049102.7700000000000000',
      quoteVolume: 6482781.439092984,
      btcVolume: 629.5046579926835,
      usdVolume: 6482781.439092984,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '1.6110000000000000',
      high: '1.6355000000000000',
      low: '1.5450000000000000',
      close: '1.5806000000000000',
      volume: '3545712.0300000000000000',
      quoteVolume: 5618393.775373046,
      btcVolume: 548.8843112989407,
      usdVolume: 5618393.775373046,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '1.5806000000000000',
      high: '1.6765000000000000',
      low: '1.5532000000000000',
      close: '1.6240000000000000',
      volume: '5390335.8700000000000000',
      quoteVolume: 8708882.286866039,
      btcVolume: 852.1328908855065,
      usdVolume: 8708882.286866039,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '1.6240000000000000',
      high: '1.8462000000000000',
      low: '1.6240000000000000',
      close: '1.8010000000000000',
      volume: '8267989.9400000000000000',
      quoteVolume: 14346677.761906926,
      btcVolume: 1407.7978038474118,
      usdVolume: 14346677.761906926,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '1.7994000000000000',
      high: '1.9075000000000000',
      low: '1.7079000000000000',
      close: '1.8744000000000000',
      volume: '8485812.8300000000000000',
      quoteVolume: 15522274.024547847,
      btcVolume: 1554.190947436255,
      usdVolume: 15522274.024547847,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '1.8743000000000000',
      high: '1.8772000000000000',
      low: '1.7510000000000000',
      close: '1.8245000000000000',
      volume: '3468435.2500000000000000',
      quoteVolume: 6287930.976908962,
      btcVolume: 618.0830858277567,
      usdVolume: 6287930.976908962,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '1.8215000000000000',
      high: '1.8888000000000000',
      low: '1.7881000000000000',
      close: '1.8855000000000000',
      volume: '2771763.9300000000000000',
      quoteVolume: 5102178.238018988,
      btcVolume: 508.0689834466635,
      usdVolume: 5102178.238018988,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '1.8848000000000000',
      high: '1.8868000000000000',
      low: '1.7711000000000000',
      close: '1.8157000000000000',
      volume: '2311848.6500000000000000',
      quoteVolume: 4207056.9643990025,
      btcVolume: 422.0307052601196,
      usdVolume: 4207056.9643990025,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '1.8149000000000000',
      high: '1.8800000000000000',
      low: '1.7799000000000000',
      close: '1.8031000000000000',
      volume: '2667120.7500000000000000',
      quoteVolume: 4892761.515483015,
      btcVolume: 497.2694919497504,
      usdVolume: 4892761.515483015,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '1.8048000000000000',
      high: '2.0657000000000000',
      low: '1.4612000000000000',
      close: '1.7160000000000000',
      volume: '15096447.2100000000000000',
      quoteVolume: 28169393.552325387,
      btcVolume: 3042.0172749110857,
      usdVolume: 28169393.552325387,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '1.7135000000000000',
      high: '1.9286000000000000',
      low: '1.6249000000000000',
      close: '1.7443000000000000',
      volume: '8910281.8800000000000000',
      quoteVolume: 15541684.445626967,
      btcVolume: 1835.8125331240349,
      usdVolume: 15541684.445626967,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '1.7476000000000000',
      high: '1.7584000000000000',
      low: '1.5276000000000000',
      close: '1.6512000000000000',
      volume: '6786874.8600000000000000',
      quoteVolume: 11209636.83260096,
      btcVolume: 1367.9713551044558,
      usdVolume: 11209636.83260096,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '1.6512000000000000',
      high: '1.6786000000000000',
      low: '1.5573000000000000',
      close: '1.6590000000000000',
      volume: '4333552.0600000000000000',
      quoteVolume: 6991710.784840995,
      btcVolume: 870.5039437524816,
      usdVolume: 6991710.784840995,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '1.6593000000000000',
      high: '1.7017000000000000',
      low: '1.6284000000000000',
      close: '1.6742000000000000',
      volume: '2326952.1300000000000000',
      quoteVolume: 3880224.7487799893,
      btcVolume: 476.88231743920215,
      usdVolume: 3880224.7487799893,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '1.6748000000000000',
      high: '1.7492000000000000',
      low: '1.6441000000000000',
      close: '1.7002000000000000',
      volume: '3304534.4900000000000000',
      quoteVolume: 5625241.514617002,
      btcVolume: 697.5333272708833,
      usdVolume: 5625241.514617002,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '1.7004000000000000',
      high: '1.7690000000000000',
      low: '1.6404000000000000',
      close: '1.7677000000000000',
      volume: '3564898.9100000000000000',
      quoteVolume: 6077384.966384979,
      btcVolume: 753.0627398007749,
      usdVolume: 6077384.966384979,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '1.7677000000000000',
      high: '1.8820000000000000',
      low: '1.7522000000000000',
      close: '1.8286000000000000',
      volume: '4077978.5200000000000000',
      quoteVolume: 7412545.756683013,
      btcVolume: 888.0318959948171,
      usdVolume: 7412545.756683013,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '1.8285000000000000',
      high: '2.0233000000000000',
      low: '1.8121000000000000',
      close: '2.0110000000000000',
      volume: '5789143.1000000000000000',
      quoteVolume: 11109499.143447043,
      btcVolume: 1347.6344778608,
      usdVolume: 11109499.143447043,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '2.0134000000000000',
      high: '2.0338000000000000',
      low: '1.8800000000000000',
      close: '1.9442000000000000',
      volume: '6996841.1000000000000000',
      quoteVolume: 13705258.538260838,
      btcVolume: 1670.6787632836763,
      usdVolume: 13705258.538260838,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '1.9443000000000000',
      high: '1.9949000000000000',
      low: '1.8880000000000000',
      close: '1.9663000000000000',
      volume: '3419972.1800000000000000',
      quoteVolume: 6612123.5559109915,
      btcVolume: 813.0573588319999,
      usdVolume: 6612123.5559109915,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '1.9663000000000000',
      high: '1.9775000000000000',
      low: '1.9113000000000000',
      close: '1.9624000000000000',
      volume: '2231607.1100000000000000',
      quoteVolume: 4348757.9730950035,
      btcVolume: 537.2042324566693,
      usdVolume: 4348757.9730950035,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '1.9624000000000000',
      high: '2.2659000000000000',
      low: '1.9531000000000000',
      close: '2.1669000000000000',
      volume: '9724532.2300000000000000',
      quoteVolume: 20653440.90051679,
      btcVolume: 2600.3765280923726,
      usdVolume: 20653440.90051679,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '2.1669000000000000',
      high: '2.4899000000000000',
      low: '2.0971000000000000',
      close: '2.3963000000000000',
      volume: '12793025.3500000000000000',
      quoteVolume: 29795334.186469693,
      btcVolume: 3681.5038062533695,
      usdVolume: 29795334.186469693,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '2.3963000000000000',
      high: '2.6500000000000000',
      low: '2.2680000000000000',
      close: '2.5766000000000000',
      volume: '16095226.0400000000000000',
      quoteVolume: 39937936.664554514,
      btcVolume: 4873.697508425804,
      usdVolume: 39937936.664554514,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '2.5765000000000000',
      high: '2.9500000000000000',
      low: '2.5010000000000000',
      close: '2.8011000000000000',
      volume: '21716501.4900000000000000',
      quoteVolume: 59715252.16293435,
      btcVolume: 7171.176638549378,
      usdVolume: 59715252.16293435,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '2.8011000000000000',
      high: '2.8058000000000000',
      low: '2.4650000000000000',
      close: '2.6302000000000000',
      volume: '15341662.5100000000000000',
      quoteVolume: 40233982.30970988,
      btcVolume: 4713.439912937979,
      usdVolume: 40233982.30970988,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '2.6280000000000000',
      high: '2.7936000000000000',
      low: '2.5619000000000000',
      close: '2.7481000000000000',
      volume: '11370701.0600000000000000',
      quoteVolume: 30661560.51973289,
      btcVolume: 3657.570932964459,
      usdVolume: 30661560.51973289,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '2.7443000000000000',
      high: '2.7572000000000000',
      low: '2.5519000000000000',
      close: '2.5975000000000000',
      volume: '6172891.7200000000000000',
      quoteVolume: 16310219.63231884,
      btcVolume: 1958.0872004698058,
      usdVolume: 16310219.63231884,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '2.5975000000000000',
      high: '2.6443000000000000',
      low: '2.4351000000000000',
      close: '2.4860000000000000',
      volume: '5530185.4400000000000000',
      quoteVolume: 14018335.45872208,
      btcVolume: 1683.0569562732167,
      usdVolume: 14018335.45872208,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '2.4850000000000000',
      high: '2.6741000000000000',
      low: '2.4527000000000000',
      close: '2.5324000000000000',
      volume: '5826032.3200000000000000',
      quoteVolume: 14927603.979734935,
      btcVolume: 1798.6897727563057,
      usdVolume: 14927603.979734935,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '2.5315000000000000',
      high: '2.5970000000000000',
      low: '2.3320000000000000',
      close: '2.3972000000000000',
      volume: '6184169.7700000000000000',
      quoteVolume: 15237510.027046982,
      btcVolume: 1851.108592609114,
      usdVolume: 15237510.027046982,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '2.3972000000000000',
      high: '2.4143000000000000',
      low: '2.1820000000000000',
      close: '2.3717000000000000',
      volume: '11839469.0800000000000000',
      quoteVolume: 27151346.82973899,
      btcVolume: 3372.7497076069235,
      usdVolume: 27151346.82973899,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '2.3736000000000000',
      high: '2.4900000000000000',
      low: '2.2740000000000000',
      close: '2.4124000000000000',
      volume: '10413491.6200000000000000',
      quoteVolume: 24976988.911125984,
      btcVolume: 3108.003201846181,
      usdVolume: 24976988.911125984,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '2.4190000000000000',
      high: '2.4999000000000000',
      low: '2.3164000000000000',
      close: '2.3546000000000000',
      volume: '7255303.2100000000000000',
      quoteVolume: 17379294.0806661,
      btcVolume: 2186.768617430999,
      usdVolume: 17379294.0806661,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '2.3521000000000000',
      high: '2.3910000000000000',
      low: '2.2914000000000000',
      close: '2.3521000000000000',
      volume: '4513432.3200000000000000',
      quoteVolume: 10575592.141082024,
      btcVolume: 1328.617937628987,
      usdVolume: 10575592.141082024,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '2.3513000000000000',
      high: '2.4510000000000000',
      low: '2.2780000000000000',
      close: '2.4444000000000000',
      volume: '4491279.5700000000000000',
      quoteVolume: 10590230.618070923,
      btcVolume: 1316.2113024406156,
      usdVolume: 10590230.618070923,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '2.4443000000000000',
      high: '2.6900000000000000',
      low: '2.4347000000000000',
      close: '2.6223000000000000',
      volume: '11336763.6800000000000000',
      quoteVolume: 29227623.981732704,
      btcVolume: 3558.2909764104465,
      usdVolume: 29227623.981732704,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '2.6216000000000000',
      high: '2.8178000000000000',
      low: '2.5619000000000000',
      close: '2.6074000000000000',
      volume: '11193488.7900000000000000',
      quoteVolume: 30334954.89091577,
      btcVolume: 3713.298114804292,
      usdVolume: 30334954.89091577,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '2.6073000000000000',
      high: '2.6840000000000000',
      low: '2.3717000000000000',
      close: '2.6055000000000000',
      volume: '13350403.7600000000000000',
      quoteVolume: 34058909.05652626,
      btcVolume: 4438.740203459202,
      usdVolume: 34058909.05652626,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '2.6071000000000000',
      high: '2.7748000000000000',
      low: '2.6020000000000000',
      close: '2.7200000000000000',
      volume: '9744122.3600000000000000',
      quoteVolume: 26188969.916833855,
      btcVolume: 3521.547093016349,
      usdVolume: 26188969.916833855,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '2.7201000000000000',
      high: '3.0000000000000000',
      low: '2.6165000000000000',
      close: '2.8360000000000000',
      volume: '12714070.6800000000000000',
      quoteVolume: 35489759.83948473,
      btcVolume: 4402.325745022558,
      usdVolume: 35489759.83948473,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '2.8370000000000000',
      high: '2.9977000000000000',
      low: '2.6640000000000000',
      close: '2.7348000000000000',
      volume: '12993334.8100000000000000',
      quoteVolume: 36586743.2039599,
      btcVolume: 3897.7642510202168,
      usdVolume: 36586743.2039599,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '2.7323000000000000',
      high: '2.7620000000000000',
      low: '2.6511000000000000',
      close: '2.6646000000000000',
      volume: '5966423.4400000000000000',
      quoteVolume: 16086672.751381027,
      btcVolume: 1713.5391674385778,
      usdVolume: 16086672.751381027,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '2.6707000000000000',
      high: '2.8859000000000000',
      low: '2.6600000000000000',
      close: '2.6611000000000000',
      volume: '7822708.6800000000000000',
      quoteVolume: 21672797.56783017,
      btcVolume: 2284.2347637633156,
      usdVolume: 21672797.56783017,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '2.6601000000000000',
      high: '2.7690000000000000',
      low: '2.6321000000000000',
      close: '2.6620000000000000',
      volume: '3504202.7100000000000000',
      quoteVolume: 9449017.04016303,
      btcVolume: 1008.1912647024507,
      usdVolume: 9449017.04016303,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '2.6610000000000000',
      high: '2.6768000000000000',
      low: '2.5107000000000000',
      close: '2.6093000000000000',
      volume: '3975481.9600000000000000',
      quoteVolume: 10291062.332419943,
      btcVolume: 1120.2450669570424,
      usdVolume: 10291062.332419943,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '2.6044000000000000',
      high: '2.7573000000000000',
      low: '2.5365000000000000',
      close: '2.7219000000000000',
      volume: '4135810.6300000000000000',
      quoteVolume: 10988316.188437952,
      btcVolume: 1197.8135631055834,
      usdVolume: 10988316.188437952,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '2.7233000000000000',
      high: '2.7952000000000000',
      low: '2.6428000000000000',
      close: '2.7091000000000000',
      volume: '3786813.4200000000000000',
      quoteVolume: 10249112.15861604,
      btcVolume: 1122.302010791027,
      usdVolume: 10249112.15861604,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '2.7092000000000000',
      high: '2.7399000000000000',
      low: '2.6828000000000000',
      close: '2.6973000000000000',
      volume: '1945361.3400000000000000',
      quoteVolume: 5267072.043627042,
      btcVolume: 568.6068601325862,
      usdVolume: 5267072.043627042,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '2.7015000000000000',
      high: '2.7104000000000000',
      low: '2.6202000000000000',
      close: '2.6605000000000000',
      volume: '1676684.8800000000000000',
      quoteVolume: 4468927.620704968,
      btcVolume: 485.58249494067894,
      usdVolume: 4468927.620704968,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '2.6594000000000000',
      high: '2.7300000000000000',
      low: '2.6267000000000000',
      close: '2.6746000000000000',
      volume: '2308453.4600000000000000',
      quoteVolume: 6191639.232816044,
      btcVolume: 667.6063736733863,
      usdVolume: 6191639.232816044,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '2.6746000000000000',
      high: '2.6947000000000000',
      low: '2.6322000000000000',
      close: '2.6746000000000000',
      volume: '1985221.7300000000000000',
      quoteVolume: 5293153.906610983,
      btcVolume: 566.6809866952036,
      usdVolume: 5293153.906610983,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '2.6743000000000000',
      high: '2.8200000000000000',
      low: '2.6479000000000000',
      close: '2.7448000000000000',
      volume: '3174248.6300000000000000',
      quoteVolume: 8633537.382419948,
      btcVolume: 926.8771591924037,
      usdVolume: 8633537.382419948,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '2.7446000000000000',
      high: '2.7549000000000000',
      low: '2.6022000000000000',
      close: '2.6757000000000000',
      volume: '3498719.3300000000000000',
      quoteVolume: 9378528.926173998,
      btcVolume: 1015.9056048703025,
      usdVolume: 9378528.926173998,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '2.6758000000000000',
      high: '2.7333000000000000',
      low: '2.6210000000000000',
      close: '2.7100000000000000',
      volume: '2921454.2700000000000000',
      quoteVolume: 7841818.426597041,
      btcVolume: 875.5145773375328,
      usdVolume: 7841818.426597041,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '2.7091000000000000',
      high: '2.8497000000000000',
      low: '2.7031000000000000',
      close: '2.8009000000000000',
      volume: '4656993.4400000000000000',
      quoteVolume: 12964192.966649953,
      btcVolume: 1471.6148137529362,
      usdVolume: 12964192.966649953,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '2.8008000000000000',
      high: '2.8370000000000000',
      low: '2.7362000000000000',
      close: '2.7770000000000000',
      volume: '2591145.5900000000000000',
      quoteVolume: 7226235.751716039,
      btcVolume: 811.2847092382342,
      usdVolume: 7226235.751716039,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '2.7773000000000000',
      high: '2.7777000000000000',
      low: '2.6655000000000000',
      close: '2.7440000000000000',
      volume: '2140491.0400000000000000',
      quoteVolume: 5824727.782756004,
      btcVolume: 663.0973900459279,
      usdVolume: 5824727.782756004,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '2.7438000000000000',
      high: '2.8733000000000000',
      low: '2.7311000000000000',
      close: '2.8152000000000000',
      volume: '3207309.4900000000000000',
      quoteVolume: 8980218.046382047,
      btcVolume: 1028.891814520804,
      usdVolume: 8980218.046382047,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '2.8177000000000000',
      high: '3.0894000000000000',
      low: '2.7851000000000000',
      close: '3.0200000000000000',
      volume: '6541016.7600000000000000',
      quoteVolume: 19407023.30134102,
      btcVolume: 2214.351922566352,
      usdVolume: 19407023.30134102,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '3.0189000000000000',
      high: '3.1600000000000000',
      low: '2.9666000000000000',
      close: '3.0391000000000000',
      volume: '6593839.9000000000000000',
      quoteVolume: 20356219.364202138,
      btcVolume: 2349.5884763957965,
      usdVolume: 20356219.364202138,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '3.0390000000000000',
      high: '3.0700000000000000',
      low: '2.7970000000000000',
      close: '3.0389000000000000',
      volume: '5616867.4900000000000000',
      quoteVolume: 16587870.154289966,
      btcVolume: 1939.8783909842598,
      usdVolume: 16587870.154289966,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '3.0372000000000000',
      high: '3.0435000000000000',
      low: '2.9115000000000000',
      close: '2.9894000000000000',
      volume: '1973143.8200000000000000',
      quoteVolume: 5875410.748616997,
      btcVolume: 693.1568795868579,
      usdVolume: 5875410.748616997,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '2.9893000000000000',
      high: '2.9894000000000000',
      low: '2.8644000000000000',
      close: '2.9100000000000000',
      volume: '1638152.2100000000000000',
      quoteVolume: 4776273.310892999,
      btcVolume: 560.9160031958835,
      usdVolume: 4776273.310892999,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '2.9100000000000000',
      high: '2.9610000000000000',
      low: '2.6977000000000000',
      close: '2.7885000000000000',
      volume: '3479282.4300000000000000',
      quoteVolume: 9852607.662228048,
      btcVolume: 1181.9947586571366,
      usdVolume: 9852607.662228048,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '2.7886000000000000',
      high: '2.8069000000000000',
      low: '2.5800000000000000',
      close: '2.7255000000000000',
      volume: '4538639.6800000000000000',
      quoteVolume: 12210133.545297062,
      btcVolume: 1503.1001831593057,
      usdVolume: 12210133.545297062,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '2.7279000000000000',
      high: '2.7957000000000000',
      low: '2.6300000000000000',
      close: '2.6982000000000000',
      volume: '3588766.3500000000000000',
      quoteVolume: 9788974.060772017,
      btcVolume: 1207.4464054339237,
      usdVolume: 9788974.060772017,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '2.6983000000000000',
      high: '2.7153000000000000',
      low: '2.4710000000000000',
      close: '2.5456000000000000',
      volume: '4528968.6200000000000000',
      quoteVolume: 11725649.253482027,
      btcVolume: 1506.7638418172246,
      usdVolume: 11725649.253482027,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '2.5456000000000000',
      high: '2.5952000000000000',
      low: '2.1000000000000000',
      close: '2.3466000000000000',
      volume: '10778947.2500000000000000',
      quoteVolume: 25122066.728304863,
      btcVolume: 3458.21602950047,
      usdVolume: 25122066.728304863,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '2.3434000000000000',
      high: '2.4621000000000000',
      low: '2.2604000000000000',
      close: '2.4266000000000000',
      volume: '4841617.1200000000000000',
      quoteVolume: 11394019.118919024,
      btcVolume: 1578.269819528385,
      usdVolume: 11394019.118919024,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '2.4272000000000000',
      high: '2.4533000000000000',
      low: '2.2000000000000000',
      close: '2.2216000000000000',
      volume: '4491218.3300000000000000',
      quoteVolume: 10364009.312878031,
      btcVolume: 1456.1232933078102,
      usdVolume: 10364009.312878031,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '2.2218000000000000',
      high: '2.3976000000000000',
      low: '2.0200000000000000',
      close: '2.2523000000000000',
      volume: '8151200.1200000000000000',
      quoteVolume: 18025202.51352003,
      btcVolume: 2602.121395130407,
      usdVolume: 18025202.51352003,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '2.2554000000000000',
      high: '2.3578000000000000',
      low: '2.1459000000000000',
      close: '2.2424000000000000',
      volume: '7541215.9200000000000000',
      quoteVolume: 16895075.120843165,
      btcVolume: 2370.226442420348,
      usdVolume: 16895075.120843165,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '2.2437000000000000',
      high: '2.2882000000000000',
      low: '2.1002000000000000',
      close: '2.2242000000000000',
      volume: '7867101.6900000000000000',
      quoteVolume: 17458921.123055957,
      btcVolume: 2407.2933436753665,
      usdVolume: 17458921.123055957,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '2.2216000000000000',
      high: '2.3248000000000000',
      low: '2.1711000000000000',
      close: '2.2702000000000000',
      volume: '4770270.0100000000000000',
      quoteVolume: 10676600.041403953,
      btcVolume: 1420.5539937358105,
      usdVolume: 10676600.041403953,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '2.2716000000000000',
      high: '2.3763000000000000',
      low: '2.2611000000000000',
      close: '2.3403000000000000',
      volume: '4132886.9400000000000000',
      quoteVolume: 9586717.671007054,
      btcVolume: 1262.8765759121409,
      usdVolume: 9586717.671007054,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '2.3403000000000000',
      high: '2.3437000000000000',
      low: '2.1943000000000000',
      close: '2.2373000000000000',
      volume: '2920487.5800000000000000',
      quoteVolume: 6606597.773422014,
      btcVolume: 864.7793427026156,
      usdVolume: 6606597.773422014,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '2.2360000000000000',
      high: '2.2385000000000000',
      low: '2.1274000000000000',
      close: '2.1638000000000000',
      volume: '3517483.4300000000000000',
      quoteVolume: 7624553.0653519705,
      btcVolume: 1036.6018203822432,
      usdVolume: 7624553.0653519705,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '2.1634000000000000',
      high: '2.1660000000000000',
      low: '2.0524000000000000',
      close: '2.0911000000000000',
      volume: '3648117.9700000000000000',
      quoteVolume: 7673977.60459799,
      btcVolume: 1052.6747095580927,
      usdVolume: 7673977.60459799,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '2.0913000000000000',
      high: '2.1891000000000000',
      low: '2.0580000000000000',
      close: '2.1222000000000000',
      volume: '3779094.5300000000000000',
      quoteVolume: 8035102.426474956,
      btcVolume: 1099.7458034935873,
      usdVolume: 8035102.426474956,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '2.1222000000000000',
      high: '2.1551000000000000',
      low: '2.0000000000000000',
      close: '2.0290000000000000',
      volume: '6418298.4400000000000000',
      quoteVolume: 13241702.282410966,
      btcVolume: 1821.4389081573547,
      usdVolume: 13241702.282410966,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '2.0282000000000000',
      high: '2.0740000000000000',
      low: '1.9583000000000000',
      close: '2.0068000000000000',
      volume: '5364919.9900000000000000',
      quoteVolume: 10777462.949475031,
      btcVolume: 1473.092445555551,
      usdVolume: 10777462.949475031,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '2.0059000000000000',
      high: '2.1042000000000000',
      low: '1.9685000000000000',
      close: '2.0943000000000000',
      volume: '3855061.0400000000000000',
      quoteVolume: 7874379.4179719705,
      btcVolume: 1063.7224341290423,
      usdVolume: 7874379.4179719705,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '2.0918000000000000',
      high: '2.1114000000000000',
      low: '2.0346000000000000',
      close: '2.0440000000000000',
      volume: '2405401.8200000000000000',
      quoteVolume: 4979102.437877952,
      btcVolume: 661.935259884129,
      usdVolume: 4979102.437877952,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '2.0402000000000000',
      high: '2.0850000000000000',
      low: '2.0012000000000000',
      close: '2.0528000000000000',
      volume: '2017582.9900000000000000',
      quoteVolume: 4126872.5763140195,
      btcVolume: 551.4016518898085,
      usdVolume: 4126872.5763140195,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '2.0520000000000000',
      high: '2.0985000000000000',
      low: '2.0151000000000000',
      close: '2.0984000000000000',
      volume: '2749805.9600000000000000',
      quoteVolume: 5635545.581415003,
      btcVolume: 756.3154499408689,
      usdVolume: 5635545.581415003,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '2.0983000000000000',
      high: '2.2862000000000000',
      low: '2.0611000000000000',
      close: '2.2657000000000000',
      volume: '10810913.6300000000000000',
      quoteVolume: 23748657.368107967,
      btcVolume: 3247.6560107514047,
      usdVolume: 23748657.368107967,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '2.2655000000000000',
      high: '2.3042000000000000',
      low: '2.1470000000000000',
      close: '2.1786000000000000',
      volume: '4814219.6800000000000000',
      quoteVolume: 10700085.694164913,
      btcVolume: 1482.272457471991,
      usdVolume: 10700085.694164913,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '2.1784000000000000',
      high: '2.1951000000000000',
      low: '2.0197000000000000',
      close: '2.1056000000000000',
      volume: '4784990.2800000000000000',
      quoteVolume: 10011804.878543014,
      btcVolume: 1397.2028682925654,
      usdVolume: 10011804.878543014,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '2.1037000000000000',
      high: '2.1210000000000000',
      low: '2.0575000000000000',
      close: '2.0709000000000000',
      volume: '1938856.4000000000000000',
      quoteVolume: 4050597.004999004,
      btcVolume: 560.2010274614569,
      usdVolume: 4050597.004999004,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '2.0713000000000000',
      high: '2.0933000000000000',
      low: '1.9700000000000000',
      close: '1.9946000000000000',
      volume: '1750170.3500000000000000',
      quoteVolume: 3553231.517961994,
      btcVolume: 497.52783996859756,
      usdVolume: 3553231.517961994,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '1.9947000000000000',
      high: '2.1168000000000000',
      low: '1.9606000000000000',
      close: '2.0708000000000000',
      volume: '2572245.4900000000000000',
      quoteVolume: 5290463.029340993,
      btcVolume: 744.7645762589991,
      usdVolume: 5290463.029340993,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '2.0722000000000000',
      high: '2.0873000000000000',
      low: '1.9670000000000000',
      close: '1.9751000000000000',
      volume: '2426967.3300000000000000',
      quoteVolume: 4880535.555653993,
      btcVolume: 697.783270194055,
      usdVolume: 4880535.555653993,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '1.9705000000000000',
      high: '2.0025000000000000',
      low: '1.7000000000000000',
      close: '1.7598000000000000',
      volume: '7314955.8200000000000000',
      quoteVolume: 13273928.880977929,
      btcVolume: 1967.0240939966445,
      usdVolume: 13273928.880977929,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '1.7594000000000000',
      high: '1.8995000000000000',
      low: '1.5996000000000000',
      close: '1.8703000000000000',
      volume: '8586632.6500000000000000',
      quoteVolume: 15238050.812313028,
      btcVolume: 2248.4745148935344,
      usdVolume: 15238050.812313028,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '1.8703000000000000',
      high: '1.8901000000000000',
      low: '1.7490000000000000',
      close: '1.8050000000000000',
      volume: '5044150.8900000000000000',
      quoteVolume: 9131480.463140937,
      btcVolume: 1276.1833159780792,
      usdVolume: 9131480.463140937,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '1.8050000000000000',
      high: '1.9238000000000000',
      low: '1.7849000000000000',
      close: '1.9182000000000000',
      volume: '3285479.3800000000000000',
      quoteVolume: 6091824.338370992,
      btcVolume: 851.739498901493,
      usdVolume: 6091824.338370992,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '1.9182000000000000',
      high: '1.9204000000000000',
      low: '1.8544000000000000',
      close: '1.8575000000000000',
      volume: '2246207.9200000000000000',
      quoteVolume: 4254205.469336003,
      btcVolume: 595.372491489374,
      usdVolume: 4254205.469336003,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '1.8563000000000000',
      high: '1.9400000000000000',
      low: '1.8530000000000000',
      close: '1.9181000000000000',
      volume: '2765867.8000000000000000',
      quoteVolume: 5280663.966873023,
      btcVolume: 724.5881359505457,
      usdVolume: 5280663.966873023,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '1.9167000000000000',
      high: '1.9532000000000000',
      low: '1.8460000000000000',
      close: '1.8629000000000000',
      volume: '2832932.7600000000000000',
      quoteVolume: 5427329.285269981,
      btcVolume: 724.3302026053892,
      usdVolume: 5427329.285269981,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '1.8638000000000000',
      high: '1.8854000000000000',
      low: '1.8154000000000000',
      close: '1.8318000000000000',
      volume: '2276138.1100000000000000',
      quoteVolume: 4206713.595653003,
      btcVolume: 575.7089237245987,
      usdVolume: 4206713.595653003,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '1.8434000000000000',
      high: '1.8462000000000000',
      low: '1.7600000000000000',
      close: '1.7826000000000000',
      volume: '1576134.1400000000000000',
      quoteVolume: 2842886.6440100074,
      btcVolume: 394.49536602127625,
      usdVolume: 2842886.6440100074,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '1.7836000000000000',
      high: '1.9604000000000000',
      low: '1.7702000000000000',
      close: '1.9460000000000000',
      volume: '2645538.2200000000000000',
      quoteVolume: 4914305.251431999,
      btcVolume: 675.865579877377,
      usdVolume: 4914305.251431999,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '1.8538000000000000',
      high: '1.9299000000000000',
      low: '1.8435000000000000',
      close: '1.8751000000000000',
      volume: '2413976.6800000000000000',
      quoteVolume: 4547744.520783,
      btcVolume: 630.9067581741584,
      usdVolume: 4547744.520783,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '1.8777000000000000',
      high: '1.8994000000000000',
      low: '1.8555000000000000',
      close: '1.8746000000000000',
      volume: '1269364.7000000000000000',
      quoteVolume: 2384012.458777003,
      btcVolume: 326.02528374878295,
      usdVolume: 2384012.458777003,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '1.8716000000000000',
      high: '1.9200000000000000',
      low: '1.8444000000000000',
      close: '1.8976000000000000',
      volume: '1411381.0500000000000000',
      quoteVolume: 2639536.610925001,
      btcVolume: 357.41364249988766,
      usdVolume: 2639536.610925001,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '1.8980000000000000',
      high: '1.8980000000000000',
      low: '1.8072000000000000',
      close: '1.8243000000000000',
      volume: '1099037.9900000000000000',
      quoteVolume: 2036388.7939540006,
      btcVolume: 278.66897959056115,
      usdVolume: 2036388.7939540006,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '1.8215000000000000',
      high: '1.8292000000000000',
      low: '1.7540000000000000',
      close: '1.7673000000000000',
      volume: '1637635.2800000000000000',
      quoteVolume: 2932476.277038998,
      btcVolume: 406.1371342093213,
      usdVolume: 2932476.277038998,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '1.7678000000000000',
      high: '1.8548000000000000',
      low: '1.7606000000000000',
      close: '1.8111000000000000',
      volume: '1158740.3200000000000000',
      quoteVolume: 2106240.113113006,
      btcVolume: 291.7435681061432,
      usdVolume: 2106240.113113006,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '1.8109000000000000',
      high: '1.8164000000000000',
      low: '1.7065000000000000',
      close: '1.7366000000000000',
      volume: '1346757.5400000000000000',
      quoteVolume: 2370258.0493380134,
      btcVolume: 336.50922976459,
      usdVolume: 2370258.0493380134,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '1.7366000000000000',
      high: '1.8292000000000000',
      low: '1.7013000000000000',
      close: '1.8040000000000000',
      volume: '1782059.3300000000000000',
      quoteVolume: 3173528.806004989,
      btcVolume: 440.5557916213236,
      usdVolume: 3173528.806004989,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '1.8030000000000000',
      high: '1.8446000000000000',
      low: '1.7840000000000000',
      close: '1.8242000000000000',
      volume: '1241322.8100000000000000',
      quoteVolume: 2252857.6365430057,
      btcVolume: 307.3651298252609,
      usdVolume: 2252857.6365430057,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '1.8243000000000000',
      high: '1.8413000000000000',
      low: '1.7850000000000000',
      close: '1.7957000000000000',
      volume: '1524603.4000000000000000',
      quoteVolume: 2771904.605593008,
      btcVolume: 372.70606476580207,
      usdVolume: 2771904.605593008,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '1.7954000000000000',
      high: '1.9386000000000000',
      low: '1.7925000000000000',
      close: '1.9374000000000000',
      volume: '3301230.5100000000000000',
      quoteVolume: 6219983.016896969,
      btcVolume: 822.3795323204727,
      usdVolume: 6219983.016896969,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '1.9373000000000000',
      high: '2.1398000000000000',
      low: '1.8993000000000000',
      close: '2.1033000000000000',
      volume: '6873503.3900000000000000',
      quoteVolume: 14001167.440691043,
      btcVolume: 1765.0859844694687,
      usdVolume: 14001167.440691043,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '2.1006000000000000',
      high: '2.3495000000000000',
      low: '2.1006000000000000',
      close: '2.1910000000000000',
      volume: '10645081.3000000000000000',
      quoteVolume: 23671432.343011014,
      btcVolume: 2869.5091074200345,
      usdVolume: 23671432.343011014,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '2.1842000000000000',
      high: '2.2344000000000000',
      low: '2.0977000000000000',
      close: '2.1886000000000000',
      volume: '4880978.6700000000000000',
      quoteVolume: 10564559.54597592,
      btcVolume: 1336.7758650978221,
      usdVolume: 10564559.54597592,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '2.1867000000000000',
      high: '2.2489000000000000',
      low: '2.1455000000000000',
      close: '2.2257000000000000',
      volume: '4235446.6200000000000000',
      quoteVolume: 9348313.457428945,
      btcVolume: 1187.4445809136419,
      usdVolume: 9348313.457428945,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '2.2255000000000000',
      high: '2.3450000000000000',
      low: '2.2180000000000000',
      close: '2.2734000000000000',
      volume: '4346405.0600000000000000',
      quoteVolume: 9936058.411543818,
      btcVolume: 1222.4125100446236,
      usdVolume: 9936058.411543818,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '2.2730000000000000',
      high: '2.3212000000000000',
      low: '2.2120000000000000',
      close: '2.2383000000000000',
      volume: '2499405.7900000000000000',
      quoteVolume: 5647655.962262982,
      btcVolume: 695.833466287728,
      usdVolume: 5647655.962262982,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '2.2382000000000000',
      high: '2.2382000000000000',
      low: '2.1561000000000000',
      close: '2.1901000000000000',
      volume: '1952305.6900000000000000',
      quoteVolume: 4261648.208497995,
      btcVolume: 525.0222154958136,
      usdVolume: 4261648.208497995,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '2.1904000000000000',
      high: '2.4701000000000000',
      low: '2.1792000000000000',
      close: '2.3751000000000000',
      volume: '7058803.5000000000000000',
      quoteVolume: 16487477.721062977,
      btcVolume: 1910.6120911212884,
      usdVolume: 16487477.721062977,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '2.3751000000000000',
      high: '2.5604000000000000',
      low: '2.3332000000000000',
      close: '2.3953000000000000',
      volume: '6388121.6800000000000000',
      quoteVolume: 15661576.690633962,
      btcVolume: 1788.326833421823,
      usdVolume: 15661576.690633962,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '2.3991000000000000',
      high: '2.5100000000000000',
      low: '2.2932000000000000',
      close: '2.4925000000000000',
      volume: '4937240.5300000000000000',
      quoteVolume: 11942842.296321075,
      btcVolume: 1374.9026425662325,
      usdVolume: 11942842.296321075,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '2.4924000000000000',
      high: '2.8600000000000000',
      low: '2.4589000000000000',
      close: '2.7006000000000000',
      volume: '12107831.2900000000000000',
      quoteVolume: 32609325.00067248,
      btcVolume: 3674.588483290462,
      usdVolume: 32609325.00067248,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '2.7006000000000000',
      high: '2.7946000000000000',
      low: '2.5800000000000000',
      close: '2.7709000000000000',
      volume: '4454822.8800000000000000',
      quoteVolume: 12057457.05895922,
      btcVolume: 1354.3595299323777,
      usdVolume: 12057457.05895922,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '2.7749000000000000',
      high: '2.8475000000000000',
      low: '2.5385000000000000',
      close: '2.6212000000000000',
      volume: '6634722.1700000000000000',
      quoteVolume: 17678886.450535074,
      btcVolume: 2012.0756253195111,
      usdVolume: 17678886.450535074,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '2.6207000000000000',
      high: '2.7443000000000000',
      low: '2.5425000000000000',
      close: '2.6880000000000000',
      volume: '4142689.5300000000000000',
      quoteVolume: 10966736.422782997,
      btcVolume: 1266.964503736621,
      usdVolume: 10966736.422782997,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '2.6881000000000000',
      high: '2.7440000000000000',
      low: '2.5700000000000000',
      close: '2.6716000000000000',
      volume: '3244654.0900000000000000',
      quoteVolume: 8677468.39766201,
      btcVolume: 1002.1855751352247,
      usdVolume: 8677468.39766201,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '2.6724000000000000',
      high: '2.6978000000000000',
      low: '2.6187000000000000',
      close: '2.6426000000000000',
      volume: '1637205.0200000000000000',
      quoteVolume: 4351550.913769996,
      btcVolume: 500.3133383092309,
      usdVolume: 4351550.913769996,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '2.6425000000000000',
      high: '2.6470000000000000',
      low: '2.4256000000000000',
      close: '2.4698000000000000',
      volume: '4806803.2100000000000000',
      quoteVolume: 11989939.300899168,
      btcVolume: 1413.7383885194763,
      usdVolume: 11989939.300899168,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '2.4695000000000000',
      high: '2.5330000000000000',
      low: '2.3638000000000000',
      close: '2.5024000000000000',
      volume: '4161651.9700000000000000',
      quoteVolume: 10232694.793375008,
      btcVolume: 1220.4855118581247,
      usdVolume: 10232694.793375008,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '2.5024000000000000',
      high: '2.5100000000000000',
      low: '2.4210000000000000',
      close: '2.4468000000000000',
      volume: '1881630.1800000000000000',
      quoteVolume: 4630337.163306008,
      btcVolume: 555.0830281021886,
      usdVolume: 4630337.163306008,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '2.4425000000000000',
      high: '2.5764000000000000',
      low: '2.4266000000000000',
      close: '2.5764000000000000',
      volume: '1936836.4700000000000000',
      quoteVolume: 4876802.3327080235,
      btcVolume: 574.1477799412621,
      usdVolume: 4876802.3327080235,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '2.5764000000000000',
      high: '2.6764000000000000',
      low: '2.5656000000000000',
      close: '2.6055000000000000',
      volume: '3426087.6500000000000000',
      quoteVolume: 8983048.130742965,
      btcVolume: 1025.861592856912,
      usdVolume: 8983048.130742965,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '2.6051000000000000',
      high: '2.6700000000000000',
      low: '2.5868000000000000',
      close: '2.6558000000000000',
      volume: '3113588.1700000000000000',
      quoteVolume: 8190994.708914968,
      btcVolume: 901.4969362742667,
      usdVolume: 8190994.708914968,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '2.6544000000000000',
      high: '2.8348000000000000',
      low: '2.6316000000000000',
      close: '2.7773000000000000',
      volume: '5191102.2600000000000000',
      quoteVolume: 14210741.277650112,
      btcVolume: 1519.9493554481853,
      usdVolume: 14210741.277650112,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '2.7785000000000000',
      high: '2.9482000000000000',
      low: '2.7167000000000000',
      close: '2.9156000000000000',
      volume: '5780750.0200000000000000',
      quoteVolume: 16529966.221006121,
      btcVolume: 1760.8623301714779,
      usdVolume: 16529966.221006121,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '2.9120000000000000',
      high: '2.9153000000000000',
      low: '2.7628000000000000',
      close: '2.8259000000000000',
      volume: '3910431.2100000000000000',
      quoteVolume: 11114615.65423999,
      btcVolume: 1189.571698179413,
      usdVolume: 11114615.65423999,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '2.8258000000000000',
      high: '2.8800000000000000',
      low: '2.7438000000000000',
      close: '2.8378000000000000',
      volume: '2737541.7700000000000000',
      quoteVolume: 7681755.743857064,
      btcVolume: 819.739572372717,
      usdVolume: 7681755.743857064,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '2.8372000000000000',
      high: '2.8869000000000000',
      low: '2.7314000000000000',
      close: '2.8267000000000000',
      volume: '3442889.5900000000000000',
      quoteVolume: 9768511.051874014,
      btcVolume: 1040.2867368472987,
      usdVolume: 9768511.051874014,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '2.8225000000000000',
      high: '2.8790000000000000',
      low: '2.7451000000000000',
      close: '2.7710000000000000',
      volume: '3106758.8600000000000000',
      quoteVolume: 8691569.65454806,
      btcVolume: 929.8882901515204,
      usdVolume: 8691569.65454806,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '2.7713000000000000',
      high: '2.8038000000000000',
      low: '2.6751000000000000',
      close: '2.7395000000000000',
      volume: '4491247.5800000000000000',
      quoteVolume: 12261241.924308985,
      btcVolume: 1328.2200602977618,
      usdVolume: 12261241.924308985,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '2.7397000000000000',
      high: '2.8845000000000000',
      low: '2.7261000000000000',
      close: '2.8390000000000000',
      volume: '4267689.2200000000000000',
      quoteVolume: 12053160.781550005,
      btcVolume: 1276.385904166067,
      usdVolume: 12053160.781550005,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '2.8360000000000000',
      high: '2.9436000000000000',
      low: '2.7900000000000000',
      close: '2.8900000000000000',
      volume: '4131243.5200000000000000',
      quoteVolume: 11910422.487068068,
      btcVolume: 1227.3627511910402,
      usdVolume: 11910422.487068068,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '2.8900000000000000',
      high: '3.3682000000000000',
      low: '2.8882000000000000',
      close: '3.3072000000000000',
      volume: '14813365.3600000000000000',
      quoteVolume: 47449282.522496335,
      btcVolume: 4846.042169836303,
      usdVolume: 47449282.522496335,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '3.3071000000000000',
      high: '3.4865000000000000',
      low: '3.1310000000000000',
      close: '3.4171000000000000',
      volume: '10059760.5500000000000000',
      quoteVolume: 33442719.414118227,
      btcVolume: 3409.4308561389403,
      usdVolume: 33442719.414118227,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '3.4179000000000000',
      high: '3.4984000000000000',
      low: '3.2330000000000000',
      close: '3.4945000000000000',
      volume: '6056067.2700000000000000',
      quoteVolume: 20336735.107043743,
      btcVolume: 2016.569351190022,
      usdVolume: 20336735.107043743,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '3.4927000000000000',
      high: '3.4972000000000000',
      low: '3.2806000000000000',
      close: '3.3862000000000000',
      volume: '6758374.3500000000000000',
      quoteVolume: 22873780.175180133,
      btcVolume: 2303.5567499558065,
      usdVolume: 22873780.175180133,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '3.3874000000000000',
      high: '3.9964000000000000',
      low: '3.3500000000000000',
      close: '3.9931000000000000',
      volume: '11780106.0700000000000000',
      quoteVolume: 43237892.69208104,
      btcVolume: 4336.995754427936,
      usdVolume: 43237892.69208104,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '3.9937000000000000',
      high: '4.2000000000000000',
      low: '3.8472000000000000',
      close: '3.9953000000000000',
      volume: '13673147.3100000000000000',
      quoteVolume: 55003441.64790517,
      btcVolume: 5332.8082573628735,
      usdVolume: 55003441.64790517,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '3.9930000000000000',
      high: '4.1147000000000000',
      low: '3.7123000000000000',
      close: '3.8765000000000000',
      volume: '8191206.1300000000000000',
      quoteVolume: 32257042.692259546,
      btcVolume: 3140.1527250738345,
      usdVolume: 32257042.692259546,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '3.8753000000000000',
      high: '4.4870000000000000',
      low: '3.7873000000000000',
      close: '4.4260000000000000',
      volume: '10369344.9500000000000000',
      quoteVolume: 43468717.18347415,
      btcVolume: 4231.988614585208,
      usdVolume: 43468717.18347415,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '4.4262000000000000',
      high: '4.6210000000000000',
      low: '4.0650000000000000',
      close: '4.3241000000000000',
      volume: '15573115.1200000000000000',
      quoteVolume: 68355374.03477696,
      btcVolume: 6799.603705176355,
      usdVolume: 68355374.03477696,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '4.3215000000000000',
      high: '4.8671000000000000',
      low: '4.0384000000000000',
      close: '4.4566000000000000',
      volume: '22171359.6900000000000000',
      quoteVolume: 99672983.28316268,
      btcVolume: 10084.607252387823,
      usdVolume: 99672983.28316268,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '4.4563000000000000',
      high: '4.5522000000000000',
      low: '3.8449000000000000',
      close: '4.3108000000000000',
      volume: '19343142.2500000000000000',
      quoteVolume: 80859272.12602058,
      btcVolume: 8343.69697549989,
      usdVolume: 80859272.12602058,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '4.3059000000000000',
      high: '4.6360000000000000',
      low: '4.1416000000000000',
      close: '4.5523000000000000',
      volume: '11931206.5700000000000000',
      quoteVolume: 52870430.99099803,
      btcVolume: 5385.401313218787,
      usdVolume: 52870430.99099803,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '4.5539000000000000',
      high: '4.8355000000000000',
      low: '4.1930000000000000',
      close: '4.3873000000000000',
      volume: '10055681.7000000000000000',
      quoteVolume: 46162309.631008886,
      btcVolume: 4610.077023153315,
      usdVolume: 46162309.631008886,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '4.3840000000000000',
      high: '4.4865000000000000',
      low: '4.0673000000000000',
      close: '4.2644000000000000',
      volume: '11463128.1100000000000000',
      quoteVolume: 48921964.38781903,
      btcVolume: 5107.005216621635,
      usdVolume: 48921964.38781903,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '4.2666000000000000',
      high: '4.5127000000000000',
      low: '4.1840000000000000',
      close: '4.2778000000000000',
      volume: '7706651.0700000000000000',
      quoteVolume: 33506855.339449476,
      btcVolume: 3462.076686554174,
      usdVolume: 33506855.339449476,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '4.2789000000000000',
      high: '4.3500000000000000',
      low: '4.0332000000000000',
      close: '4.1066000000000000',
      volume: '6716454.0200000000000000',
      quoteVolume: 27976312.64533768,
      btcVolume: 2902.199389115639,
      usdVolume: 27976312.64533768,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '4.1006000000000000',
      high: '4.3768000000000000',
      low: '4.0693000000000000',
      close: '4.2365000000000000',
      volume: '5642974.2100000000000000',
      quoteVolume: 23992021.76366039,
      btcVolume: 2434.114086850125,
      usdVolume: 23992021.76366039,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '4.2366000000000000',
      high: '4.2871000000000000',
      low: '3.7916000000000000',
      close: '3.9148000000000000',
      volume: '8591286.0700000000000000',
      quoteVolume: 34393400.24605992,
      btcVolume: 3548.267172458539,
      usdVolume: 34393400.24605992,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '3.9179000000000000',
      high: '3.9600000000000000',
      low: '3.4864000000000000',
      close: '3.4905000000000000',
      volume: '12394531.5200000000000000',
      quoteVolume: 45560015.71143371,
      btcVolume: 4810.153110343996,
      usdVolume: 45560015.71143371,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '3.4874000000000000',
      high: '3.8587000000000000',
      low: '3.3113000000000000',
      close: '3.5648000000000000',
      volume: '22177330.0200000000000000',
      quoteVolume: 79010843.38455322,
      btcVolume: 8784.642820650577,
      usdVolume: 79010843.38455322,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '3.5648000000000000',
      high: '4.1320000000000000',
      low: '3.4197000000000000',
      close: '3.9579000000000000',
      volume: '19080853.8700000000000000',
      quoteVolume: 73041636.71234092,
      btcVolume: 8290.177225043033,
      usdVolume: 73041636.71234092,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '3.9568000000000000',
      high: '4.2395000000000000',
      low: '3.7500000000000000',
      close: '4.1609000000000000',
      volume: '18251889.1100000000000000',
      quoteVolume: 72768936.34054644,
      btcVolume: 8381.737217549813,
      usdVolume: 72768936.34054644,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '4.1614000000000000',
      high: '4.3535000000000000',
      low: '4.0572000000000000',
      close: '4.0579000000000000',
      volume: '12433586.3300000000000000',
      quoteVolume: 52447455.00483184,
      btcVolume: 6050.453008344912,
      usdVolume: 52447455.00483184,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '4.0600000000000000',
      high: '4.2385000000000000',
      low: '3.7607000000000000',
      close: '3.8590000000000000',
      volume: '14748523.0700000000000000',
      quoteVolume: 58869388.27488089,
      btcVolume: 6880.090250435741,
      usdVolume: 58869388.27488089,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '3.8622000000000000',
      high: '4.3435000000000000',
      low: '3.8173000000000000',
      close: '4.2423000000000000',
      volume: '12656156.0500000000000000',
      quoteVolume: 52493228.92320553,
      btcVolume: 5994.207586648094,
      usdVolume: 52493228.92320553,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '4.2421000000000000',
      high: '4.6800000000000000',
      low: '4.1249000000000000',
      close: '4.5846000000000000',
      volume: '17189323.0000000000000000',
      quoteVolume: 75145836.87198897,
      btcVolume: 8560.826818400581,
      usdVolume: 75145836.87198897,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '4.5873000000000000',
      high: '4.9762000000000000',
      low: '4.5333000000000000',
      close: '4.6406000000000000',
      volume: '18623299.5100000000000000',
      quoteVolume: 88271977.43112102,
      btcVolume: 10087.310040122788,
      usdVolume: 88271977.43112102,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '4.6388000000000000',
      high: '4.8990000000000000',
      low: '4.6239000000000000',
      close: '4.7151000000000000',
      volume: '11845630.6300000000000000',
      quoteVolume: 56401786.5002544,
      btcVolume: 6263.948735289506,
      usdVolume: 56401786.5002544,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '4.7144000000000000',
      high: '4.7800000000000000',
      low: '4.5429000000000000',
      close: '4.6814000000000000',
      volume: '7645180.7400000000000000',
      quoteVolume: 35490114.51378227,
      btcVolume: 3908.2548527927784,
      usdVolume: 35490114.51378227,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '4.6813000000000000',
      high: '4.6863000000000000',
      low: '4.3143000000000000',
      close: '4.3412000000000000',
      volume: '8209506.8500000000000000',
      quoteVolume: 37077348.43982905,
      btcVolume: 4113.020563862099,
      usdVolume: 37077348.43982905,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '4.3468000000000000',
      high: '4.4646000000000000',
      low: '3.8500000000000000',
      close: '4.0537000000000000',
      volume: '18324667.2600000000000000',
      quoteVolume: 76704000.26084687,
      btcVolume: 9085.343101448982,
      usdVolume: 76704000.26084687,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '4.0537000000000000',
      high: '4.3690000000000000',
      low: '3.6012000000000000',
      close: '4.1231000000000000',
      volume: '29042929.6300000000000000',
      quoteVolume: 116996235.58194844,
      btcVolume: 14910.78468436395,
      usdVolume: 116996235.58194844,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '4.1237000000000000',
      high: '4.2119000000000000',
      low: '3.9132000000000000',
      close: '4.0839000000000000',
      volume: '18071323.5600000000000000',
      quoteVolume: 73423955.05481032,
      btcVolume: 9232.548450091543,
      usdVolume: 73423955.05481032,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '4.0842000000000000',
      high: '4.1100000000000000',
      low: '3.5514000000000000',
      close: '3.8169000000000000',
      volume: '19407327.4000000000000000',
      quoteVolume: 74033713.87227552,
      btcVolume: 9483.288672404144,
      usdVolume: 74033713.87227552,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '3.8170000000000000',
      high: '3.8519000000000000',
      low: '0.0001000000000000',
      close: '1.9931000000000000',
      volume: '67738097.2700000000000000',
      quoteVolume: 177673762.49713,
      btcVolume: 27866.237147761774,
      usdVolume: 177673762.49713,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '1.9990000000000000',
      high: '2.7520000000000000',
      low: '1.3600000000000000',
      close: '2.4333000000000000',
      volume: '69599335.5100000000000000',
      quoteVolume: 150523398.38686204,
      btcVolume: 29105.657242605583,
      usdVolume: 150523398.38686204,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '2.4328000000000000',
      high: '2.5141000000000000',
      low: '2.0607000000000000',
      close: '2.1379000000000000',
      volume: '21569351.2700000000000000',
      quoteVolume: 49487604.75409475,
      btcVolume: 9228.82980590662,
      usdVolume: 49487604.75409475,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '2.1401000000000000',
      high: '2.4000000000000000',
      low: '2.0150000000000000',
      close: '2.0884000000000000',
      volume: '24056556.5000000000000000',
      quoteVolume: 51979784.30551739,
      btcVolume: 9676.317809668104,
      usdVolume: 51979784.30551739,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '2.0868000000000000',
      high: '2.0898000000000000',
      low: '1.5571000000000000',
      close: '1.7832000000000000',
      volume: '47175196.2700000000000000',
      quoteVolume: 83730824.5977933,
      btcVolume: 16985.606488035726,
      usdVolume: 83730824.5977933,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '1.7832000000000000',
      high: '2.0690000000000000',
      low: '1.7300000000000000',
      close: '1.8641000000000000',
      volume: '32839078.6800000000000000',
      quoteVolume: 63607268.71138073,
      btcVolume: 12035.406899066662,
      usdVolume: 63607268.71138073,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '1.8660000000000000',
      high: '1.9543000000000000',
      low: '1.7620000000000000',
      close: '1.9483000000000000',
      volume: '22298409.7900000000000000',
      quoteVolume: 41588758.50010696,
      btcVolume: 7903.516934502976,
      usdVolume: 41588758.50010696,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '1.9497000000000000',
      high: '2.3700000000000000',
      low: '1.8841000000000000',
      close: '2.2398000000000000',
      volume: '33319846.3700000000000000',
      quoteVolume: 71956024.81635506,
      btcVolume: 12300.451552067118,
      usdVolume: 71956024.81635506,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '2.2409000000000000',
      high: '2.6249000000000000',
      low: '2.0143000000000000',
      close: '2.2600000000000000',
      volume: '39390658.8700000000000000',
      quoteVolume: 92950604.2035976,
      btcVolume: 14562.188219917904,
      usdVolume: 92950604.2035976,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '2.2631000000000000',
      high: '2.3750000000000000',
      low: '2.1050000000000000',
      close: '2.2787000000000000',
      volume: '19471583.8400000000000000',
      quoteVolume: 43519402.05460915,
      btcVolume: 7045.261058540346,
      usdVolume: 43519402.05460915,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '2.2754000000000000',
      high: '2.3300000000000000',
      low: '1.9334000000000000',
      close: '1.9898000000000000',
      volume: '21634031.6900000000000000',
      quoteVolume: 45651954.71691129,
      btcVolume: 7551.212589732256,
      usdVolume: 45651954.71691129,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '1.9889000000000000',
      high: '2.3000000000000000',
      low: '1.9352000000000000',
      close: '2.2468000000000000',
      volume: '23672246.4900000000000000',
      quoteVolume: 50076329.17784201,
      btcVolume: 8112.687771095752,
      usdVolume: 50076329.17784201,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '2.2470000000000000',
      high: '2.3709000000000000',
      low: '2.1950000000000000',
      close: '2.3104000000000000',
      volume: '18400021.3200000000000000',
      quoteVolume: 42125779.98805816,
      btcVolume: 6347.98486825512,
      usdVolume: 42125779.98805816,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '2.3109000000000000',
      high: '2.3500000000000000',
      low: '2.1804000000000000',
      close: '2.2570000000000000',
      volume: '11321064.3000000000000000',
      quoteVolume: 25603393.54787736,
      btcVolume: 3847.290256854321,
      usdVolume: 25603393.54787736,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '2.2569000000000000',
      high: '2.3132000000000000',
      low: '2.1884000000000000',
      close: '2.3132000000000000',
      volume: '6803251.3800000000000000',
      quoteVolume: 15346687.775324736,
      btcVolume: 2303.7934993235885,
      usdVolume: 15346687.775324736,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '2.3125000000000000',
      high: '2.3599000000000000',
      low: '2.1055000000000000',
      close: '2.1373000000000000',
      volume: '8151561.2000000000000000',
      quoteVolume: 18458235.87850203,
      btcVolume: 2776.0706097775064,
      usdVolume: 18458235.87850203,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '2.1372000000000000',
      high: '2.1544000000000000',
      low: '2.0372000000000000',
      close: '2.1364000000000000',
      volume: '11036739.0800000000000000',
      quoteVolume: 23162230.482438084,
      btcVolume: 3726.428487730779,
      usdVolume: 23162230.482438084,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '2.1356000000000000',
      high: '2.1520000000000000',
      low: '2.0070000000000000',
      close: '2.0083000000000000',
      volume: '6879303.8400000000000000',
      quoteVolume: 14309647.633855054,
      btcVolume: 2356.8649141943242,
      usdVolume: 14309647.633855054,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '2.0083000000000000',
      high: '2.2000000000000000',
      low: '2.0006000000000000',
      close: '2.1350000000000000',
      volume: '7970226.4900000000000000',
      quoteVolume: 16914779.735236812,
      btcVolume: 2698.6373186550286,
      usdVolume: 16914779.735236812,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '2.1348000000000000',
      high: '2.3020000000000000',
      low: '2.1102000000000000',
      close: '2.2658000000000000',
      volume: '8585088.2100000000000000',
      quoteVolume: 18841402.98135908,
      btcVolume: 2922.5256630907675,
      usdVolume: 18841402.98135908,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '2.2657000000000000',
      high: '2.2999000000000000',
      low: '2.1400000000000000',
      close: '2.2753000000000000',
      volume: '13239732.4400000000000000',
      quoteVolume: 29469066.54972005,
      btcVolume: 4669.983786159829,
      usdVolume: 29469066.54972005,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '2.2764000000000000',
      high: '2.4850000000000000',
      low: '2.2566000000000000',
      close: '2.2941000000000000',
      volume: '13266669.3900000000000000',
      quoteVolume: 30798141.032473035,
      btcVolume: 4540.6089277905485,
      usdVolume: 30798141.032473035,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '2.2940000000000000',
      high: '2.3837000000000000',
      low: '2.2652000000000000',
      close: '2.3013000000000000',
      volume: '8452189.4500000000000000',
      quoteVolume: 19628894.276646227,
      btcVolume: 2880.3710594063255,
      usdVolume: 19628894.276646227,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '2.2995000000000000',
      high: '2.3423000000000000',
      low: '2.2545000000000000',
      close: '2.3155000000000000',
      volume: '6117486.0100000000000000',
      quoteVolume: 14001543.18994617,
      btcVolume: 2067.6146389867217,
      usdVolume: 14001543.18994617,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '2.3155000000000000',
      high: '2.3219000000000000',
      low: '2.2437000000000000',
      close: '2.2635000000000000',
      volume: '3228659.2300000000000000',
      quoteVolume: 7361336.519118009,
      btcVolume: 1085.637636025333,
      usdVolume: 7361336.519118009,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '2.2638000000000000',
      high: '2.5677000000000000',
      low: '2.2621000000000000',
      close: '2.5555000000000000',
      volume: '12222925.8100000000000000',
      quoteVolume: 29594871.538146637,
      btcVolume: 4150.693258507703,
      usdVolume: 29594871.538146637,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '2.5556000000000000',
      high: '2.9080000000000000',
      low: '2.5550000000000000',
      close: '2.7303000000000000',
      volume: '30354976.5100000000000000',
      quoteVolume: 83149479.68281008,
      btcVolume: 11365.108305697015,
      usdVolume: 83149479.68281008,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '2.7295000000000000',
      high: '3.2748000000000000',
      low: '2.7124000000000000',
      close: '3.1693000000000000',
      volume: '27092993.2400000000000000',
      quoteVolume: 80619866.26421164,
      btcVolume: 11021.04111756064,
      usdVolume: 80619866.26421164,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '3.1695000000000000',
      high: '3.4741000000000000',
      low: '3.1224000000000000',
      close: '3.3099000000000000',
      volume: '36999763.5100000000000000',
      quoteVolume: 122751896.42489386,
      btcVolume: 16839.37173441571,
      usdVolume: 122751896.42489386,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '3.3099000000000000',
      high: '3.3199000000000000',
      low: '2.9450000000000000',
      close: '3.2200000000000000',
      volume: '43621490.6000000000000000',
      quoteVolume: 136026639.49936587,
      btcVolume: 19554.197753884662,
      usdVolume: 136026639.49936587,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '3.2207000000000000',
      high: '3.4364000000000000',
      low: '3.0629000000000000',
      close: '3.2900000000000000',
      volume: '30471997.5000000000000000',
      quoteVolume: 99435616.49901348,
      btcVolume: 14491.434066489905,
      usdVolume: 99435616.49901348,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '3.2912000000000000',
      high: '3.6412000000000000',
      low: '3.1670000000000000',
      close: '3.4189000000000000',
      volume: '32589266.7900000000000000',
      quoteVolume: 111420529.95980085,
      btcVolume: 15946.446334706492,
      usdVolume: 111420529.95980085,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '3.4117000000000000',
      high: '3.4602000000000000',
      low: '3.2000000000000000',
      close: '3.3702000000000000',
      volume: '29488744.4800000000000000',
      quoteVolume: 99093977.11353326,
      btcVolume: 14706.279591907658,
      usdVolume: 99093977.11353326,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '3.3719000000000000',
      high: '3.4437000000000000',
      low: '3.2100000000000000',
      close: '3.2520000000000000',
      volume: '17078827.0100000000000000',
      quoteVolume: 57101788.37653568,
      btcVolume: 8313.477053450417,
      usdVolume: 57101788.37653568,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '3.2517000000000000',
      high: '3.3061000000000000',
      low: '3.1085000000000000',
      close: '3.1212000000000000',
      volume: '15635873.1100000000000000',
      quoteVolume: 50161624.541744925,
      btcVolume: 7410.73145926265,
      usdVolume: 50161624.541744925,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '3.1212000000000000',
      high: '3.5555000000000000',
      low: '2.9761000000000000',
      close: '3.4851000000000000',
      volume: '24817961.6500000000000000',
      quoteVolume: 81880780.97622721,
      btcVolume: 11818.013830683783,
      usdVolume: 81880780.97622721,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '3.4831000000000000',
      high: '3.5250000000000000',
      low: '3.3778000000000000',
      close: '3.4523000000000000',
      volume: '12669277.2500000000000000',
      quoteVolume: 43776483.22340796,
      btcVolume: 6193.887862135089,
      usdVolume: 43776483.22340796,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '3.4525000000000000',
      high: '3.8300000000000000',
      low: '3.4470000000000000',
      close: '3.7805000000000000',
      volume: '16363948.9700000000000000',
      quoteVolume: 59614221.39959253,
      btcVolume: 8310.66795040822,
      usdVolume: 59614221.39959253,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '3.7814000000000000',
      high: '3.8102000000000000',
      low: '3.5131000000000000',
      close: '3.5902000000000000',
      volume: '16350496.0200000000000000',
      quoteVolume: 59872603.95570334,
      btcVolume: 8367.38354342493,
      usdVolume: 59872603.95570334,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '3.5885000000000000',
      high: '3.7920000000000000',
      low: '3.3720000000000000',
      close: '3.4322000000000000',
      volume: '20550987.6400000000000000',
      quoteVolume: 73812197.67135006,
      btcVolume: 10555.803904890008,
      usdVolume: 73812197.67135006,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '3.4317000000000000',
      high: '3.5719000000000000',
      low: '3.3903000000000000',
      close: '3.4577000000000000',
      volume: '13684724.9900000000000000',
      quoteVolume: 47685948.13373961,
      btcVolume: 6958.109221813893,
      usdVolume: 47685948.13373961,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '3.4593000000000000',
      high: '3.6904000000000000',
      low: '3.4327000000000000',
      close: '3.6786000000000000',
      volume: '11762633.8300000000000000',
      quoteVolume: 42349673.69567189,
      btcVolume: 6054.788459746665,
      usdVolume: 42349673.69567189,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '3.6787000000000000',
      high: '3.9101000000000000',
      low: '3.5968000000000000',
      close: '3.7533000000000000',
      volume: '14126638.7900000000000000',
      quoteVolume: 52991673.114868954,
      btcVolume: 7220.341851272833,
      usdVolume: 52991673.114868954,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '3.7551000000000000',
      high: '3.8874000000000000',
      low: '3.7364000000000000',
      close: '3.7735000000000000',
      volume: '7933872.6600000000000000',
      quoteVolume: 30294109.77665413,
      btcVolume: 4032.0422494074355,
      usdVolume: 30294109.77665413,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '3.7735000000000000',
      high: '3.9062000000000000',
      low: '3.7420000000000000',
      close: '3.7865000000000000',
      volume: '6095069.3800000000000000',
      quoteVolume: 23261065.041254092,
      btcVolume: 3081.0533832217207,
      usdVolume: 23261065.041254092,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '3.7865000000000000',
      high: '3.8160000000000000',
      low: '3.6432000000000000',
      close: '3.7210000000000000',
      volume: '8246658.1300000000000000',
      quoteVolume: 30808734.632196084,
      btcVolume: 4057.4729534536946,
      usdVolume: 30808734.632196084,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '3.7212000000000000',
      high: '3.7385000000000000',
      low: '3.4621000000000000',
      close: '3.6383000000000000',
      volume: '11371929.8600000000000000',
      quoteVolume: 40891564.50235291,
      btcVolume: 5313.060933220702,
      usdVolume: 40891564.50235291,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '3.6373000000000000',
      high: '3.7354000000000000',
      low: '3.5554000000000000',
      close: '3.6413000000000000',
      volume: '7379317.2300000000000000',
      quoteVolume: 26928714.47205683,
      btcVolume: 3488.09599283931,
      usdVolume: 26928714.47205683,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '3.6422000000000000',
      high: '3.9918000000000000',
      low: '3.6300000000000000',
      close: '3.8879000000000000',
      volume: '15125283.5400000000000000',
      quoteVolume: 57370181.28630435,
      btcVolume: 6832.774464426143,
      usdVolume: 57370181.28630435,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '3.8881000000000000',
      high: '3.9741000000000000',
      low: '3.6200000000000000',
      close: '3.7025000000000000',
      volume: '13545136.0200000000000000',
      quoteVolume: 51376800.29429678,
      btcVolume: 5766.507587671276,
      usdVolume: 51376800.29429678,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '3.7014000000000000',
      high: '3.9162000000000000',
      low: '3.7003000000000000',
      close: '3.7571000000000000',
      volume: '8084328.3600000000000000',
      quoteVolume: 30928293.222303115,
      btcVolume: 3519.057180538519,
      usdVolume: 30928293.222303115,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '3.7571000000000000',
      high: '3.8600000000000000',
      low: '3.7300000000000000',
      close: '3.8182000000000000',
      volume: '4467208.0100000000000000',
      quoteVolume: 16996396.923116993,
      btcVolume: 1915.6064803541883,
      usdVolume: 16996396.923116993,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '3.8186000000000000',
      high: '3.8600000000000000',
      low: '3.6379000000000000',
      close: '3.7092000000000000',
      volume: '5554731.5200000000000000',
      quoteVolume: 20831570.33686512,
      btcVolume: 2325.320369499448,
      usdVolume: 20831570.33686512,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '3.7098000000000000',
      high: '3.8272000000000000',
      low: '3.5033000000000000',
      close: '3.7499000000000000',
      volume: '10973722.2700000000000000',
      quoteVolume: 40395738.452246346,
      btcVolume: 4611.440780167115,
      usdVolume: 40395738.452246346,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '3.7496000000000000',
      high: '3.8140000000000000',
      low: '3.6540000000000000',
      close: '3.6833000000000000',
      volume: '4730504.5800000000000000',
      quoteVolume: 17621530.537070103,
      btcVolume: 1975.2075137716056,
      usdVolume: 17621530.537070103,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '3.6820000000000000',
      high: '3.7654000000000000',
      low: '3.5700000000000000',
      close: '3.5872000000000000',
      volume: '5299802.5900000000000000',
      quoteVolume: 19570134.054998033,
      btcVolume: 2138.6881860809644,
      usdVolume: 19570134.054998033,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '3.5874000000000000',
      high: '3.8500000000000000',
      low: '3.5633000000000000',
      close: '3.7560000000000000',
      volume: '8481271.5900000000000000',
      quoteVolume: 31361055.942226164,
      btcVolume: 3257.382561578943,
      usdVolume: 31361055.942226164,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '3.7580000000000000',
      high: '3.8590000000000000',
      low: '3.6874000000000000',
      close: '3.8010000000000000',
      volume: '7383724.4800000000000000',
      quoteVolume: 27986549.846176166,
      btcVolume: 2821.090111412898,
      usdVolume: 27986549.846176166,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '3.8001000000000000',
      high: '4.2129000000000000',
      low: '3.7601000000000000',
      close: '4.0150000000000000',
      volume: '15240721.6300000000000000',
      quoteVolume: 61673011.98541812,
      btcVolume: 6356.310945841886,
      usdVolume: 61673011.98541812,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '4.0149000000000000',
      high: '4.0197000000000000',
      low: '3.4602000000000000',
      close: '3.7582000000000000',
      volume: '19818388.7300000000000000',
      quoteVolume: 73819362.15594393,
      btcVolume: 8521.796803966217,
      usdVolume: 73819362.15594393,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '3.7574000000000000',
      high: '3.8320000000000000',
      low: '3.2865000000000000',
      close: '3.5837000000000000',
      volume: '13273873.6200000000000000',
      quoteVolume: 47852980.089774795,
      btcVolume: 5547.514411645623,
      usdVolume: 47852980.089774795,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '3.5838000000000000',
      high: '3.7932000000000000',
      low: '3.5400000000000000',
      close: '3.6943000000000000',
      volume: '7797486.4300000000000000',
      quoteVolume: 28881156.065596662,
      btcVolume: 3297.963204756944,
      usdVolume: 28881156.065596662,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '3.6938000000000000',
      high: '3.8150000000000000',
      low: '3.6366000000000000',
      close: '3.7655000000000000',
      volume: '5949203.5300000000000000',
      quoteVolume: 22154224.568957023,
      btcVolume: 2444.9070553685347,
      usdVolume: 22154224.568957023,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '3.7654000000000000',
      high: '3.8583000000000000',
      low: '3.6947000000000000',
      close: '3.8096000000000000',
      volume: '7333655.8100000000000000',
      quoteVolume: 27791822.366877846,
      btcVolume: 2891.52904194716,
      usdVolume: 27791822.366877846,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '3.8093000000000000',
      high: '3.8337000000000000',
      low: '3.6144000000000000',
      close: '3.6615000000000000',
      volume: '5571782.8800000000000000',
      quoteVolume: 20831712.993012883,
      btcVolume: 2197.771365437242,
      usdVolume: 20831712.993012883,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '3.6626000000000000',
      high: '3.7900000000000000',
      low: '3.6168000000000000',
      close: '3.7614000000000000',
      volume: '4045380.2000000000000000',
      quoteVolume: 15074632.829739979,
      btcVolume: 1604.6960921741713,
      usdVolume: 15074632.829739979,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '3.7621000000000000',
      high: '3.8524000000000000',
      low: '3.7526000000000000',
      close: '3.7815000000000000',
      volume: '3630692.0900000000000000',
      quoteVolume: 13812656.339239048,
      btcVolume: 1432.957547933766,
      usdVolume: 13812656.339239048,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '3.7815000000000000',
      high: '3.9500000000000000',
      low: '3.7786000000000000',
      close: '3.8895000000000000',
      volume: '5739644.8600000000000000',
      quoteVolume: 22305115.00685797,
      btcVolume: 2287.439967086519,
      usdVolume: 22305115.00685797,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '3.8895000000000000',
      high: '3.9488000000000000',
      low: '3.7734000000000000',
      close: '3.9470000000000000',
      volume: '3765966.9300000000000000',
      quoteVolume: 14542942.326336175,
      btcVolume: 1503.244995161628,
      usdVolume: 14542942.326336175,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '3.9466000000000000',
      high: '4.1388000000000000',
      low: '3.8955000000000000',
      close: '4.0866000000000000',
      volume: '9805842.1600000000000000',
      quoteVolume: 39677537.53178006,
      btcVolume: 4109.400460965454,
      usdVolume: 39677537.53178006,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '4.0880000000000000',
      high: '4.1740000000000000',
      low: '3.7292000000000000',
      close: '3.8298000000000000',
      volume: '10446021.8600000000000000',
      quoteVolume: 41318047.346202776,
      btcVolume: 4472.548344593976,
      usdVolume: 41318047.346202776,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '3.8307000000000000',
      high: '4.1447000000000000',
      low: '3.7932000000000000',
      close: '4.0611000000000000',
      volume: '7194944.8900000000000000',
      quoteVolume: 28640710.818571955,
      btcVolume: 3133.076928130972,
      usdVolume: 28640710.818571955,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '4.0620000000000000',
      high: '4.1072000000000000',
      low: '3.9100000000000000',
      close: '3.9250000000000000',
      volume: '5865674.1500000000000000',
      quoteVolume: 23402699.08869481,
      btcVolume: 2542.731357186535,
      usdVolume: 23402699.08869481,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '3.9242000000000000',
      high: '4.0262000000000000',
      low: '3.7844000000000000',
      close: '3.7917000000000000',
      volume: '6229925.9300000000000000',
      quoteVolume: 24355874.593129028,
      btcVolume: 2685.7880060204666,
      usdVolume: 24355874.593129028,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '3.7878000000000000',
      high: '3.9073000000000000',
      low: '3.7700000000000000',
      close: '3.8614000000000000',
      volume: '4443996.5100000000000000',
      quoteVolume: 17123412.802925922,
      btcVolume: 1942.855709479334,
      usdVolume: 17123412.802925922,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '3.8634000000000000',
      high: '3.8816000000000000',
      low: '3.7689000000000000',
      close: '3.8440000000000000',
      volume: '3774200.8300000000000000',
      quoteVolume: 14457491.492909947,
      btcVolume: 1633.177692310847,
      usdVolume: 14457491.492909947,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '3.8436000000000000',
      high: '3.9143000000000000',
      low: '3.8065000000000000',
      close: '3.8526000000000000',
      volume: '3793553.8500000000000000',
      quoteVolume: 14669023.347105095,
      btcVolume: 1621.3588858213739,
      usdVolume: 14669023.347105095,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '3.8542000000000000',
      high: '4.0601000000000000',
      low: '3.8270000000000000',
      close: '4.0461000000000000',
      volume: '4242354.5900000000000000',
      quoteVolume: 16636880.490730118,
      btcVolume: 1771.4352825657559,
      usdVolume: 16636880.490730118,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '4.0462000000000000',
      high: '4.1300000000000000',
      low: '3.9488000000000000',
      close: '3.9800000000000000',
      volume: '5242842.6700000000000000',
      quoteVolume: 21161407.756916173,
      btcVolume: 2231.9952818631095,
      usdVolume: 21161407.756916173,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '3.9820000000000000',
      high: '4.1800000000000000',
      low: '3.9430000000000000',
      close: '4.1763000000000000',
      volume: '4734058.3400000000000000',
      quoteVolume: 19356855.90212316,
      btcVolume: 2030.7654975998275,
      usdVolume: 19356855.90212316,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '4.1780000000000000',
      high: '4.2838000000000000',
      low: '4.0410000000000000',
      close: '4.1310000000000000',
      volume: '6223457.9900000000000000',
      quoteVolume: 25986422.58422777,
      btcVolume: 2723.684714084976,
      usdVolume: 25986422.58422777,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '4.1278000000000000',
      high: '4.4800000000000000',
      low: '4.1175000000000000',
      close: '4.3785000000000000',
      volume: '8703425.4700000000000000',
      quoteVolume: 38003418.52632179,
      btcVolume: 3946.2706304533644,
      usdVolume: 38003418.52632179,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '4.3795000000000000',
      high: '4.6185000000000000',
      low: '4.1075000000000000',
      close: '4.3976000000000000',
      volume: '8147770.0700000000000000',
      quoteVolume: 35881970.22012894,
      btcVolume: 3668.4636094846724,
      usdVolume: 35881970.22012894,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '4.3987000000000000',
      high: '4.5300000000000000',
      low: '4.2898000000000000',
      close: '4.4892000000000000',
      volume: '4168765.8100000000000000',
      quoteVolume: 18467942.894892167,
      btcVolume: 1933.344668741359,
      usdVolume: 18467942.894892167,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '4.4881000000000000',
      high: '4.5081000000000000',
      low: '4.2864000000000000',
      close: '4.4243000000000000',
      volume: '3231902.9900000000000000',
      quoteVolume: 14276426.128533928,
      btcVolume: 1476.4285848581262,
      usdVolume: 14276426.128533928,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '4.4263000000000000',
      high: '4.4860000000000000',
      low: '4.3405000000000000',
      close: '4.3580000000000000',
      volume: '2314891.8700000000000000',
      quoteVolume: 10216880.887104046,
      btcVolume: 1049.6536854309381,
      usdVolume: 10216880.887104046,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '4.3569000000000000',
      high: '4.3979000000000000',
      low: '4.3034000000000000',
      close: '4.3512000000000000',
      volume: '1153869.9600000000000000',
      quoteVolume: 5020633.317667975,
      btcVolume: 521.0659599515068,
      usdVolume: 5020633.317667975,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '4.3513000000000000',
      high: '4.4100000000000000',
      low: '4.2068000000000000',
      close: '4.3465000000000000',
      volume: '2137522.0500000000000000',
      quoteVolume: 9211491.186056014,
      btcVolume: 960.8098153273933,
      usdVolume: 9211491.186056014,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '4.3487000000000000',
      high: '4.4759000000000000',
      low: '4.3250000000000000',
      close: '4.4730000000000000',
      volume: '2037163.2100000000000000',
      quoteVolume: 8929343.506046021,
      btcVolume: 918.8751048356538,
      usdVolume: 8929343.506046021,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '4.4753000000000000',
      high: '4.5062000000000000',
      low: '4.3605000000000000',
      close: '4.4350000000000000',
      volume: '2250839.6700000000000000',
      quoteVolume: 9985584.222381042,
      btcVolume: 1027.6679495159528,
      usdVolume: 9985584.222381042,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '4.4374000000000000',
      high: '4.4404000000000000',
      low: '4.3426000000000000',
      close: '4.4225000000000000',
      volume: '2229011.4100000000000000',
      quoteVolume: 9773177.039550047,
      btcVolume: 997.9263921227159,
      usdVolume: 9773177.039550047,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '4.4207000000000000',
      high: '4.4503000000000000',
      low: '3.9100000000000000',
      close: '3.9565000000000000',
      volume: '6568688.8700000000000000',
      quoteVolume: 27115697.81298312,
      btcVolume: 2861.0421903797023,
      usdVolume: 27115697.81298312,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '3.9530000000000000',
      high: '4.1874000000000000',
      low: '3.8573000000000000',
      close: '4.0819000000000000',
      volume: '4751891.2300000000000000',
      quoteVolume: 19339118.373514097,
      btcVolume: 2057.689856681987,
      usdVolume: 19339118.373514097,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '4.0807000000000000',
      high: '4.1346000000000000',
      low: '4.0054000000000000',
      close: '4.1100000000000000',
      volume: '1604660.7400000000000000',
      quoteVolume: 6524511.677829004,
      btcVolume: 692.1491678856813,
      usdVolume: 6524511.677829004,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '4.1100000000000000',
      high: '4.1134000000000000',
      low: '3.9143000000000000',
      close: '3.9371000000000000',
      volume: '1710213.3600000000000000',
      quoteVolume: 6841578.031834006,
      btcVolume: 729.0393181477964,
      usdVolume: 6841578.031834006,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '3.9395000000000000',
      high: '3.9889000000000000',
      low: '3.6551000000000000',
      close: '3.9274000000000000',
      volume: '6850306.1100000000000000',
      quoteVolume: 26068942.977982927,
      btcVolume: 2826.528626557364,
      usdVolume: 26068942.977982927,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '3.9254000000000000',
      high: '4.0918000000000000',
      low: '3.8935000000000000',
      close: '4.0502000000000000',
      volume: '3614037.3300000000000000',
      quoteVolume: 14515402.500366056,
      btcVolume: 1528.8235581573408,
      usdVolume: 14515402.500366056,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '4.0502000000000000',
      high: '4.2000000000000000',
      low: '3.9570000000000000',
      close: '4.1659000000000000',
      volume: '4301280.9800000000000000',
      quoteVolume: 17558113.834275063,
      btcVolume: 1862.55704310758,
      usdVolume: 17558113.834275063,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '4.1663000000000000',
      high: '4.2491000000000000',
      low: '4.0957000000000000',
      close: '4.1415000000000000',
      volume: '3348384.0800000000000000',
      quoteVolume: 13969700.00507494,
      btcVolume: 1483.9403280382335,
      usdVolume: 13969700.00507494,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '4.1417000000000000',
      high: '4.1839000000000000',
      low: '4.0521000000000000',
      close: '4.0980000000000000',
      volume: '2424386.7000000000000000',
      quoteVolume: 9970938.73032103,
      btcVolume: 1068.115770950031,
      usdVolume: 9970938.73032103,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '4.0978000000000000',
      high: '4.2072000000000000',
      low: '4.0654000000000000',
      close: '4.1765000000000000',
      volume: '2414698.6200000000000000',
      quoteVolume: 10014907.932097934,
      btcVolume: 1076.6564266730459,
      usdVolume: 10014907.932097934,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '4.1762000000000000',
      high: '4.2058000000000000',
      low: '4.1395000000000000',
      close: '4.1812000000000000',
      volume: '1336859.5000000000000000',
      quoteVolume: 5582676.14346401,
      btcVolume: 596.8254803691216,
      usdVolume: 5582676.14346401,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '4.1819000000000000',
      high: '4.4859000000000000',
      low: '4.1760000000000000',
      close: '4.4841000000000000',
      volume: '4636790.2800000000000000',
      quoteVolume: 20238493.440281935,
      btcVolume: 2131.3300159503665,
      usdVolume: 20238493.440281935,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '4.4840000000000000',
      high: '4.9422000000000000',
      low: '4.4254000000000000',
      close: '4.8051000000000000',
      volume: '10884153.7200000000000000',
      quoteVolume: 51489688.58105523,
      btcVolume: 5341.591224643085,
      usdVolume: 51489688.58105523,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '4.8061000000000000',
      high: '4.9411000000000000',
      low: '4.4986000000000000',
      close: '4.7075000000000000',
      volume: '10253258.2100000000000000',
      quoteVolume: 48438749.91706304,
      btcVolume: 5137.643671618544,
      usdVolume: 48438749.91706304,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '4.7075000000000000',
      high: '4.8407000000000000',
      low: '4.5033000000000000',
      close: '4.8134000000000000',
      volume: '7023055.3700000000000000',
      quoteVolume: 33164952.91921413,
      btcVolume: 3591.85419221288,
      usdVolume: 33164952.91921413,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '4.8129000000000000',
      high: '4.8587000000000000',
      low: '4.6424000000000000',
      close: '4.6813000000000000',
      volume: '5531129.1300000000000000',
      quoteVolume: 26116409.53864893,
      btcVolume: 2843.866240257118,
      usdVolume: 26116409.53864893,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '4.6830000000000000',
      high: '4.6957000000000000',
      low: '4.3000000000000000',
      close: '4.4058000000000000',
      volume: '6165533.0500000000000000',
      quoteVolume: 27809003.537887093,
      btcVolume: 3060.9526617884444,
      usdVolume: 27809003.537887093,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '4.4051000000000000',
      high: '4.6200000000000000',
      low: '4.3647000000000000',
      close: '4.5668000000000000',
      volume: '4751931.7400000000000000',
      quoteVolume: 21438525.93253806,
      btcVolume: 2359.635570929396,
      usdVolume: 21438525.93253806,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '4.5658000000000000',
      high: '4.6500000000000000',
      low: '4.4232000000000000',
      close: '4.6187000000000000',
      volume: '4789663.3200000000000000',
      quoteVolume: 21719855.120023914,
      btcVolume: 2382.4340020200825,
      usdVolume: 21719855.120023914,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '4.6192000000000000',
      high: '4.6622000000000000',
      low: '4.5337000000000000',
      close: '4.5626000000000000',
      volume: '3001636.3100000000000000',
      quoteVolume: 13817102.521447966,
      btcVolume: 1509.948465482142,
      usdVolume: 13817102.521447966,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '4.5625000000000000',
      high: '4.7300000000000000',
      low: '4.5217000000000000',
      close: '4.7067000000000000',
      volume: '4059427.1700000000000000',
      quoteVolume: 18803296.868452046,
      btcVolume: 2044.3470835901742,
      usdVolume: 18803296.868452046,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '4.7068000000000000',
      high: '4.9181000000000000',
      low: '4.6025000000000000',
      close: '4.7996000000000000',
      volume: '7638387.3500000000000000',
      quoteVolume: 36415952.50593107,
      btcVolume: 3981.9894097602037,
      usdVolume: 36415952.50593107,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '4.7998000000000000',
      high: '4.8964000000000000',
      low: '4.7031000000000000',
      close: '4.7309000000000000',
      volume: '4456413.0000000000000000',
      quoteVolume: 21519728.981386155,
      btcVolume: 2366.7125707992577,
      usdVolume: 21519728.981386155,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '4.7300000000000000',
      high: '4.8539000000000000',
      low: '4.7186000000000000',
      close: '4.8200000000000000',
      volume: '2535114.5100000000000000',
      quoteVolume: 12133983.302445995,
      btcVolume: 1333.81578228134,
      usdVolume: 12133983.302445995,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '4.8198000000000000',
      high: '4.8325000000000000',
      low: '4.6118000000000000',
      close: '4.7439000000000000',
      volume: '3420895.3700000000000000',
      quoteVolume: 16145214.992928915,
      btcVolume: 1782.6229066425183,
      usdVolume: 16145214.992928915,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '4.7426000000000000',
      high: '5.5000000000000000',
      low: '4.7362000000000000',
      close: '5.3617000000000000',
      volume: '12032948.9700000000000000',
      quoteVolume: 61715137.636092246,
      btcVolume: 6663.492972858207,
      usdVolume: 61715137.636092246,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '5.3613000000000000',
      high: '5.8890000000000000',
      low: '5.3300000000000000',
      close: '5.7248000000000000',
      volume: '13783142.2200000000000000',
      quoteVolume: 77491639.85352772,
      btcVolume: 8361.43667342537,
      usdVolume: 77491639.85352772,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '5.7245000000000000',
      high: '6.5607000000000000',
      low: '5.5920000000000000',
      close: '6.4987000000000000',
      volume: '15825025.1900000000000000',
      quoteVolume: 97337955.14051247,
      btcVolume: 10432.903935303775,
      usdVolume: 97337955.14051247,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '6.5001000000000000',
      high: '6.5006000000000000',
      low: '5.7029000000000000',
      close: '6.0820000000000000',
      volume: '12942475.7400000000000000',
      quoteVolume: 78848343.82277149,
      btcVolume: 8463.213815528254,
      usdVolume: 78848343.82277149,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '6.0820000000000000',
      high: '6.2009000000000000',
      low: '5.7096000000000000',
      close: '6.1488000000000000',
      volume: '9932051.9600000000000000',
      quoteVolume: 59118518.95393981,
      btcVolume: 6423.877893463667,
      usdVolume: 59118518.95393981,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '6.1500000000000000',
      high: '6.2661000000000000',
      low: '5.9610000000000000',
      close: '6.0994000000000000',
      volume: '5180049.0300000000000000',
      quoteVolume: 31685675.96767919,
      btcVolume: 3429.120643940731,
      usdVolume: 31685675.96767919,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '6.1043000000000000',
      high: '7.3898000000000000',
      low: '6.0975000000000000',
      close: '7.3345000000000000',
      volume: '18949318.6200000000000000',
      quoteVolume: 128119147.50220707,
      btcVolume: 13858.753628207221,
      usdVolume: 128119147.50220707,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '7.3447000000000000',
      high: '8.4800000000000000',
      low: '6.8525000000000000',
      close: '7.1630000000000000',
      volume: '30737550.9600000000000000',
      quoteVolume: 235671215.53400916,
      btcVolume: 25398.30633539493,
      usdVolume: 235671215.53400916,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '7.1616000000000000',
      high: '8.3446000000000000',
      low: '6.9650000000000000',
      close: '8.1321000000000000',
      volume: '25153380.4100000000000000',
      quoteVolume: 192793205.38648593,
      btcVolume: 20888.78682809465,
      usdVolume: 192793205.38648593,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '8.1315000000000000',
      high: '8.9080000000000000',
      low: '7.8309000000000000',
      close: '8.6494000000000000',
      volume: '20518883.2200000000000000',
      quoteVolume: 172454027.970733,
      btcVolume: 18690.633753627804,
      usdVolume: 172454027.970733,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '8.6486000000000000',
      high: '8.8822000000000000',
      low: '7.9427000000000000',
      close: '8.3540000000000000',
      volume: '20705851.7900000000000000',
      quoteVolume: 173628619.95218885,
      btcVolume: 19005.124013284785,
      usdVolume: 173628619.95218885,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '8.3545000000000000',
      high: '8.6444000000000000',
      low: '8.2000000000000000',
      close: '8.2605000000000000',
      volume: '8580583.8800000000000000',
      quoteVolume: 71891358.28923588,
      btcVolume: 7867.034349896577,
      usdVolume: 71891358.28923588,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '8.2589000000000000',
      high: '8.2648000000000000',
      low: '7.6570000000000000',
      close: '7.9734000000000000',
      volume: '11416632.8500000000000000',
      quoteVolume: 90986110.87730221,
      btcVolume: 9936.17044525862,
      usdVolume: 90986110.87730221,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '7.9731000000000000',
      high: '8.2100000000000000',
      low: '7.8006000000000000',
      close: '8.0784000000000000',
      volume: '5325842.7900000000000000',
      quoteVolume: 42588167.82897199,
      btcVolume: 4648.695039778068,
      usdVolume: 42588167.82897199,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '8.0848000000000000',
      high: '8.1388000000000000',
      low: '7.1210000000000000',
      close: '7.2301000000000000',
      volume: '11024362.8200000000000000',
      quoteVolume: 84110745.40996003,
      btcVolume: 9169.302612989131,
      usdVolume: 84110745.40996003,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '7.2301000000000000',
      high: '7.6788000000000000',
      low: '6.9232000000000000',
      close: '7.3101000000000000',
      volume: '10932081.1000000000000000',
      quoteVolume: 80003692.44480799,
      btcVolume: 8576.725504872806,
      usdVolume: 80003692.44480799,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '7.3086000000000000',
      high: '7.6365000000000000',
      low: '7.0832000000000000',
      close: '7.4689000000000000',
      volume: '9596016.7400000000000000',
      quoteVolume: 70863239.4579672,
      btcVolume: 7572.310124975652,
      usdVolume: 70863239.4579672,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '7.4690000000000000',
      high: '8.0990000000000000',
      low: '7.4029000000000000',
      close: '7.8983000000000000',
      volume: '10915322.8800000000000000',
      quoteVolume: 85588641.40242985,
      btcVolume: 8986.708733063164,
      usdVolume: 85588641.40242985,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '7.8982000000000000',
      high: '7.9304000000000000',
      low: '7.3840000000000000',
      close: '7.4284000000000000',
      volume: '7313843.7500000000000000',
      quoteVolume: 55866119.15418281,
      btcVolume: 5854.581041359676,
      usdVolume: 55866119.15418281,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '7.4284000000000000',
      high: '7.8009000000000000',
      low: '7.3744000000000000',
      close: '7.6720000000000000',
      volume: '7502502.4600000000000000',
      quoteVolume: 57265634.06685721,
      btcVolume: 5964.891413603993,
      usdVolume: 57265634.06685721,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '7.6737000000000000',
      high: '7.9450000000000000',
      low: '7.1885000000000000',
      close: '7.5022000000000000',
      volume: '10637660.3200000000000000',
      quoteVolume: 80431491.35325707,
      btcVolume: 8167.326551727266,
      usdVolume: 80431491.35325707,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '7.5009000000000000',
      high: '7.5045000000000000',
      low: '6.5101000000000000',
      close: '7.1000000000000000',
      volume: '16680558.0400000000000000',
      quoteVolume: 117110382.73523542,
      btcVolume: 11213.359323082714,
      usdVolume: 117110382.73523542,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '7.0990000000000000',
      high: '7.4243000000000000',
      low: '6.9106000000000000',
      close: '7.2333000000000000',
      volume: '8500726.8300000000000000',
      quoteVolume: 60812597.213252656,
      btcVolume: 5549.91287596061,
      usdVolume: 60812597.213252656,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '7.2332000000000000',
      high: '7.4871000000000000',
      low: '6.9867000000000000',
      close: '7.0876000000000000',
      volume: '7058913.7900000000000000',
      quoteVolume: 50881041.657357685,
      btcVolume: 4599.08400376134,
      usdVolume: 50881041.657357685,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '7.0876000000000000',
      high: '7.5757000000000000',
      low: '7.0253000000000000',
      close: '7.4093000000000000',
      volume: '7667819.8300000000000000',
      quoteVolume: 56146316.3170551,
      btcVolume: 5092.87952074015,
      usdVolume: 56146316.3170551,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '7.4093000000000000',
      high: '7.8649000000000000',
      low: '7.4085000000000000',
      close: '7.8033000000000000',
      volume: '7419454.2600000000000000',
      quoteVolume: 57010133.853312016,
      btcVolume: 5107.431794695363,
      usdVolume: 57010133.853312016,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '7.8025000000000000',
      high: '8.4720000000000000',
      low: '7.6373000000000000',
      close: '8.3248000000000000',
      volume: '9345076.4000000000000000',
      quoteVolume: 74293026.60700549,
      btcVolume: 6369.51346066485,
      usdVolume: 74293026.60700549,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '8.3216000000000000',
      high: '8.7972000000000000',
      low: '6.8700000000000000',
      close: '8.2814000000000000',
      volume: '22647614.3400000000000000',
      quoteVolume: 187308467.6520541,
      btcVolume: 16618.54057221337,
      usdVolume: 187308467.6520541,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '8.2851000000000000',
      high: '9.2707000000000000',
      low: '8.1408000000000000',
      close: '9.2502000000000000',
      volume: '12805982.5400000000000000',
      quoteVolume: 110080414.61075139,
      btcVolume: 9792.377399999354,
      usdVolume: 110080414.61075139,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '9.2502000000000000',
      high: '9.9645000000000000',
      low: '9.0807000000000000',
      close: '9.8687000000000000',
      volume: '18460200.9900000000000000',
      quoteVolume: 175203350.75301245,
      btcVolume: 15588.926012531812,
      usdVolume: 175203350.75301245,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '9.8686000000000000',
      high: '9.8686000000000000',
      low: '9.3207000000000000',
      close: '9.5253000000000000',
      volume: '9682088.7700000000000000',
      quoteVolume: 92516426.18121561,
      btcVolume: 8087.5667816474515,
      usdVolume: 92516426.18121561,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '9.5265000000000000',
      high: '10.2917000000000000',
      low: '9.4515000000000000',
      close: '10.2343000000000000',
      volume: '9041552.6000000000000000',
      quoteVolume: 88144051.28129894,
      btcVolume: 7505.910950203218,
      usdVolume: 88144051.28129894,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '10.2284000000000000',
      high: '10.2615000000000000',
      low: '9.0500000000000000',
      close: '10.1286000000000000',
      volume: '11733225.3400000000000000',
      quoteVolume: 115568472.9989462,
      btcVolume: 9912.375472640897,
      usdVolume: 115568472.9989462,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '10.1286000000000000',
      high: '13.8799000000000000',
      low: '9.9287000000000000',
      close: '12.7093000000000000',
      volume: '28542792.8400000000000000',
      quoteVolume: 342944887.19930977,
      btcVolume: 29343.8183945227,
      usdVolume: 342944887.19930977,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '12.7095000000000000',
      high: '14.4586000000000000',
      low: '12.0000000000000000',
      close: '13.8104000000000000',
      volume: '28201686.3100000000000000',
      quoteVolume: 376769344.70101273,
      btcVolume: 32361.99931890226,
      usdVolume: 376769344.70101273,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '13.8105000000000000',
      high: '14.0551000000000000',
      low: '12.5200000000000000',
      close: '13.4722000000000000',
      volume: '17910767.8200000000000000',
      quoteVolume: 240411392.94454074,
      btcVolume: 20238.132402655556,
      usdVolume: 240411392.94454074,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '13.4722000000000000',
      high: '13.5403000000000000',
      low: '12.0905000000000000',
      close: '12.9971000000000000',
      volume: '17514622.4200000000000000',
      quoteVolume: 225363799.55548632,
      btcVolume: 19493.751301956687,
      usdVolume: 225363799.55548632,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '12.9999000000000000',
      high: '16.8000000000000000',
      low: '12.5936000000000000',
      close: '16.4635000000000000',
      volume: '23113249.4000000000000000',
      quoteVolume: 342110085.2548909,
      btcVolume: 29740.856429109834,
      usdVolume: 342110085.2548909,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '16.4634000000000000',
      high: '18.3488000000000000',
      low: '15.9175000000000000',
      close: '17.1883000000000000',
      volume: '30934675.5500000000000000',
      quoteVolume: 527151580.26604337,
      btcVolume: 45706.95238937064,
      usdVolume: 527151580.26604337,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '17.1728000000000000',
      high: '17.5998000000000000',
      low: '16.3000000000000000',
      close: '16.9574000000000000',
      volume: '11109883.8400000000000000',
      quoteVolume: 188010113.5259645,
      btcVolume: 16024.502767051485,
      usdVolume: 188010113.5259645,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '16.9447000000000000',
      high: '19.8303000000000000',
      low: '16.5554000000000000',
      close: '19.2285000000000000',
      volume: '17941112.0100000000000000',
      quoteVolume: 330894146.757813,
      btcVolume: 27921.28371563732,
      usdVolume: 330894146.757813,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '19.2108000000000000',
      high: '20.1111000000000000',
      low: '18.5144000000000000',
      close: '18.8061000000000000',
      volume: '10757840.9900000000000000',
      quoteVolume: 206509366.21838295,
      btcVolume: 17444.9116283756,
      usdVolume: 206509366.21838295,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '18.8096000000000000',
      high: '19.7111000000000000',
      low: '15.5000000000000000',
      close: '16.8196000000000000',
      volume: '13542369.7900000000000000',
      quoteVolume: 248379200.46697778,
      btcVolume: 20504.962867936094,
      usdVolume: 248379200.46697778,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '16.8064000000000000',
      high: '17.6738000000000000',
      low: '14.6900000000000000',
      close: '16.1927000000000000',
      volume: '27088057.7600000000000000',
      quoteVolume: 445332598.9067832,
      btcVolume: 36628.32089159984,
      usdVolume: 445332598.9067832,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '16.1846000000000000',
      high: '17.2554000000000000',
      low: '14.8926000000000000',
      close: '16.1863000000000000',
      volume: '18358817.9100000000000000',
      quoteVolume: 297260255.6285172,
      btcVolume: 25207.258992200754,
      usdVolume: 297260255.6285172,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '16.1884000000000000',
      high: '16.7862000000000000',
      low: '15.7232000000000000',
      close: '16.0483000000000000',
      volume: '11011575.0900000000000000',
      quoteVolume: 179321055.27822992,
      btcVolume: 15196.345359291849,
      usdVolume: 179321055.27822992,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '16.0476000000000000',
      high: '16.1421000000000000',
      low: '12.8900000000000000',
      close: '13.8325000000000000',
      volume: '26139289.1300000000000000',
      quoteVolume: 376332110.8896587,
      btcVolume: 32120.329513780653,
      usdVolume: 376332110.8896587,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '13.8342000000000000',
      high: '16.3300000000000000',
      low: '13.6600000000000000',
      close: '15.8078000000000000',
      volume: '16709823.0900000000000000',
      quoteVolume: 252784283.01039815,
      btcVolume: 21872.182565912786,
      usdVolume: 252784283.01039815,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '15.8085000000000000',
      high: '16.0042000000000000',
      low: '14.6536000000000000',
      close: '15.1428000000000000',
      volume: '10765015.8300000000000000',
      quoteVolume: 163456993.92262277,
      btcVolume: 14082.112398712841,
      usdVolume: 163456993.92262277,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '15.1572000000000000',
      high: '15.8797000000000000',
      low: '14.7000000000000000',
      close: '15.1419000000000000',
      volume: '8454928.0700000000000000',
      quoteVolume: 129187194.20472585,
      btcVolume: 11012.764821572282,
      usdVolume: 129187194.20472585,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '15.1363000000000000',
      high: '15.3743000000000000',
      low: '13.4578000000000000',
      close: '14.1164000000000000',
      volume: '11988432.8700000000000000',
      quoteVolume: 173378967.9258536,
      btcVolume: 15132.48523268232,
      usdVolume: 173378967.9258536,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '14.1171000000000000',
      high: '15.6664000000000000',
      low: '14.0537000000000000',
      close: '15.2000000000000000',
      volume: '11415333.4100000000000000',
      quoteVolume: 169326699.33486208,
      btcVolume: 14838.375991579402,
      usdVolume: 169326699.33486208,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '15.2088000000000000',
      high: '15.5215000000000000',
      low: '13.9679000000000000',
      close: '14.5234000000000000',
      volume: '11685135.1900000000000000',
      quoteVolume: 173265514.72794762,
      btcVolume: 15276.438417623027,
      usdVolume: 173265514.72794762,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '14.5234000000000000',
      high: '15.4989000000000000',
      low: '14.2360000000000000',
      close: '15.1207000000000000',
      volume: '7108908.2300000000000000',
      quoteVolume: 106217471.48299323,
      btcVolume: 9283.514516201361,
      usdVolume: 106215608.60329323,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '15.1136000000000000',
      high: '17.0305000000000000',
      low: '14.9565000000000000',
      close: '16.4273000000000000',
      volume: '11734386.8700000000000000',
      quoteVolume: 189641391.5824856,
      btcVolume: 16492.39931867633,
      usdVolume: 189641391.5824856,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '16.4537000000000000',
      high: '17.7777000000000000',
      low: '16.4037000000000000',
      close: '16.4788000000000000',
      volume: '9731994.3200000000000000',
      quoteVolume: 166124406.2667331,
      btcVolume: 14311.349188271188,
      usdVolume: 166124406.2667331,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '16.4782000000000000',
      high: '16.9495000000000000',
      low: '15.4451000000000000',
      close: '15.5772000000000000',
      volume: '9615963.2700000000000000',
      quoteVolume: 157331308.63249928,
      btcVolume: 13475.644648985366,
      usdVolume: 157331308.63249928,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '15.5746000000000000',
      high: '16.5053000000000000',
      low: '15.0629000000000000',
      close: '16.0545000000000000',
      volume: '7793462.3200000000000000',
      quoteVolume: 123969155.38132185,
      btcVolume: 10492.616965270177,
      usdVolume: 123958598.20392784,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '16.2072000000000000',
      high: '16.3587000000000000',
      low: '14.0000000000000000',
      close: '14.7982000000000000',
      volume: '10301348.0000000000000000',
      quoteVolume: 154640593.96041393,
      btcVolume: 13465.979576934247,
      usdVolume: 154640593.96041393,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '14.7922000000000000',
      high: '14.9989000000000000',
      low: '10.8261000000000000',
      close: '11.8895000000000000',
      volume: '16949856.4800000000000000',
      quoteVolume: 227014586.31002295,
      btcVolume: 20804.680530088768,
      usdVolume: 227014586.31002295,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '11.8802000000000000',
      high: '12.9040000000000000',
      low: '11.0000000000000000',
      close: '12.3708000000000000',
      volume: '18612095.2100000000000000',
      quoteVolume: 225971603.75573367,
      btcVolume: 21814.154412869015,
      usdVolume: 225971603.75573367,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '12.3804000000000000',
      high: '12.7655000000000000',
      low: '9.1000000000000000',
      close: '10.6017000000000000',
      volume: '25511608.4800000000000000',
      quoteVolume: 278344840.137839,
      btcVolume: 27443.663519385616,
      usdVolume: 278344840.137839,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '10.6087000000000000',
      high: '13.2400000000000000',
      low: '9.7155000000000000',
      close: '12.8044000000000000',
      volume: '21811584.7900000000000000',
      quoteVolume: 254940172.73275283,
      btcVolume: 24977.6005690715,
      usdVolume: 254940172.73275283,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '12.8060000000000000',
      high: '13.1747000000000000',
      low: '11.1700000000000000',
      close: '12.4700000000000000',
      volume: '20515580.9600000000000000',
      quoteVolume: 250103840.5173619,
      btcVolume: 24710.405343979466,
      usdVolume: 250103840.5173619,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '12.4744000000000000',
      high: '12.7362000000000000',
      low: '11.0284000000000000',
      close: '11.7460000000000000',
      volume: '14832129.8100000000000000',
      quoteVolume: 176444797.96903428,
      btcVolume: 17428.664020195858,
      usdVolume: 176444797.96903428,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '11.7359000000000000',
      high: '12.6566000000000000',
      low: '11.4356000000000000',
      close: '12.3444000000000000',
      volume: '10649791.4300000000000000',
      quoteVolume: 129646130.9405381,
      btcVolume: 12725.251017309938,
      usdVolume: 129646130.9405381,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '12.3452000000000000',
      high: '13.2800000000000000',
      low: '12.1800000000000000',
      close: '12.4957000000000000',
      volume: '14928167.2000000000000000',
      quoteVolume: 190985359.03175014,
      btcVolume: 18462.71704639154,
      usdVolume: 190985359.03175014,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '12.4948000000000000',
      high: '12.6960000000000000',
      low: '12.0190000000000000',
      close: '12.4406000000000000',
      volume: '7498865.1600000000000000',
      quoteVolume: 92935019.6125966,
      btcVolume: 9026.605245547558,
      usdVolume: 92935019.6125966,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '12.4406000000000000',
      high: '12.7470000000000000',
      low: '12.0934000000000000',
      close: '12.7187000000000000',
      volume: '6269794.6100000000000000',
      quoteVolume: 77882381.38087636,
      btcVolume: 7509.491501095776,
      usdVolume: 77882381.38087636,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '12.7194000000000000',
      high: '13.2300000000000000',
      low: '11.7658000000000000',
      close: '12.0670000000000000',
      volume: '11107099.4800000000000000',
      quoteVolume: 138433490.7795642,
      btcVolume: 13317.710404478297,
      usdVolume: 138433490.7795642,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '12.0757000000000000',
      high: '12.3863000000000000',
      low: '11.6349000000000000',
      close: '12.1374000000000000',
      volume: '8830940.1600000000000000',
      quoteVolume: 106057143.09535801,
      btcVolume: 10093.04992993571,
      usdVolume: 106057143.09535801,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '12.1300000000000000',
      high: '12.2400000000000000',
      low: '10.7500000000000000',
      close: '10.9314000000000000',
      volume: '11413633.6300000000000000',
      quoteVolume: 132790893.20361203,
      btcVolume: 12320.461229483713,
      usdVolume: 132790893.20361203,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '10.9301000000000000',
      high: '11.3002000000000000',
      low: '10.4771000000000000',
      close: '10.7367000000000000',
      volume: '10362445.0800000000000000',
      quoteVolume: 112764173.2894766,
      btcVolume: 10353.892720724712,
      usdVolume: 112764173.2894766,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '10.7367000000000000',
      high: '11.6759000000000000',
      low: '10.4720000000000000',
      close: '11.0139000000000000',
      volume: '10163046.4600000000000000',
      quoteVolume: 111006019.31385103,
      btcVolume: 10179.558912072785,
      usdVolume: 111006019.31385103,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '11.0139000000000000',
      high: '11.1315000000000000',
      low: '9.7863000000000000',
      close: '10.0940000000000000',
      volume: '8890206.0700000000000000',
      quoteVolume: 93387663.390679,
      btcVolume: 8561.061779425265,
      usdVolume: 93387663.390679,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '10.0938000000000000',
      high: '10.4969000000000000',
      low: '9.8756000000000000',
      close: '10.1209000000000000',
      volume: '5596280.8000000000000000',
      quoteVolume: 56998511.47489919,
      btcVolume: 5177.969389632715,
      usdVolume: 56998511.47489919,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '10.1192000000000000',
      high: '10.6199000000000000',
      low: '9.5000000000000000',
      close: '9.7357000000000000',
      volume: '7410739.4400000000000000',
      quoteVolume: 74829506.00962031,
      btcVolume: 6862.181652542345,
      usdVolume: 74829506.00962031,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '9.7394000000000000',
      high: '9.9235000000000000',
      low: '8.5012000000000000',
      close: '8.7114000000000000',
      volume: '15761415.8500000000000000',
      quoteVolume: 141463825.6035975,
      btcVolume: 13312.026406212963,
      usdVolume: 141463825.6035975,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '8.7162000000000000',
      high: '9.0800000000000000',
      low: '8.5400000000000000',
      close: '8.7401000000000000',
      volume: '8074342.9400000000000000',
      quoteVolume: 71284015.95208645,
      btcVolume: 6811.9002965444815,
      usdVolume: 71284015.95208645,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '8.7440000000000000',
      high: '8.8016000000000000',
      low: '7.2869000000000000',
      close: '7.6364000000000000',
      volume: '18221454.9700000000000000',
      quoteVolume: 146298886.40303153,
      btcVolume: 14105.940252148712,
      usdVolume: 146298886.40303153,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '7.6404000000000000',
      high: '10.5300000000000000',
      low: '7.4761000000000000',
      close: '9.8700000000000000',
      volume: '26877668.8100000000000000',
      quoteVolume: 243924037.01423475,
      btcVolume: 23200.606802237573,
      usdVolume: 243924037.01423475,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '9.8701000000000000',
      high: '11.1990000000000000',
      low: '9.3771000000000000',
      close: '10.7279000000000000',
      volume: '20220142.0700000000000000',
      quoteVolume: 206454816.90600815,
      btcVolume: 19347.190816472226,
      usdVolume: 206454816.90600815,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '10.7218000000000000',
      high: '11.1000000000000000',
      low: '9.9757000000000000',
      close: '10.3169000000000000',
      volume: '12019763.1700000000000000',
      quoteVolume: 125899152.91074796,
      btcVolume: 11759.471660591937,
      usdVolume: 125899152.91074796,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '10.3079000000000000',
      high: '10.9910000000000000',
      low: '9.9122000000000000',
      close: '10.7939000000000000',
      volume: '10005871.9800000000000000',
      quoteVolume: 104553700.74577622,
      btcVolume: 9756.633696493238,
      usdVolume: 104553700.74577622,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '10.8000000000000000',
      high: '11.0700000000000000',
      low: '10.1500000000000000',
      close: '10.2830000000000000',
      volume: '8720119.6800000000000000',
      quoteVolume: 92733132.97270194,
      btcVolume: 8531.552193163896,
      usdVolume: 92733132.97270194,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '10.2809000000000000',
      high: '10.3882000000000000',
      low: '9.4502000000000000',
      close: '10.1350000000000000',
      volume: '13371311.3100000000000000',
      quoteVolume: 133299972.25986037,
      btcVolume: 12416.99662418838,
      usdVolume: 133299972.25986037,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '10.1356000000000000',
      high: '10.2314000000000000',
      low: '9.5768000000000000',
      close: '9.8694000000000000',
      volume: '6624754.2600000000000000',
      quoteVolume: 65302473.930610836,
      btcVolume: 6075.231257322131,
      usdVolume: 65302473.930610836,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '9.8727000000000000',
      high: '10.4119000000000000',
      low: '9.2457000000000000',
      close: '9.6394000000000000',
      volume: '9437903.4200000000000000',
      quoteVolume: 93289422.56827107,
      btcVolume: 8711.5733196697,
      usdVolume: 93289422.56827107,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '9.6394000000000000',
      high: '9.8449000000000000',
      low: '8.7975000000000000',
      close: '9.2167000000000000',
      volume: '11050283.7900000000000000',
      quoteVolume: 101613627.94135539,
      btcVolume: 9676.160636390727,
      usdVolume: 101613627.94135539,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '9.2132000000000000',
      high: '9.5555000000000000',
      low: '9.1040000000000000',
      close: '9.2690000000000000',
      volume: '4803593.0900000000000000',
      quoteVolume: 44581852.41136064,
      btcVolume: 4225.164872066804,
      usdVolume: 44581852.41136064,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '9.2686000000000000',
      high: '9.4660000000000000',
      low: '9.1141000000000000',
      close: '9.3922000000000000',
      volume: '4665626.6900000000000000',
      quoteVolume: 43259192.53149099,
      btcVolume: 4079.3745493298234,
      usdVolume: 43259192.53149099,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '9.3906000000000000',
      high: '9.7200000000000000',
      low: '9.2629000000000000',
      close: '9.6176000000000000',
      volume: '6205749.7700000000000000',
      quoteVolume: 58892347.278092586,
      btcVolume: 5503.330432382905,
      usdVolume: 58892347.278092586,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '9.6134000000000000',
      high: '9.6580000000000000',
      low: '8.5630000000000000',
      close: '8.7689000000000000',
      volume: '7422770.6500000000000000',
      quoteVolume: 67706985.53549488,
      btcVolume: 6342.859632868126,
      usdVolume: 67706985.53549488,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '8.7690000000000000',
      high: '9.0100000000000000',
      low: '8.3817000000000000',
      close: '8.9799000000000000',
      volume: '7536611.8700000000000000',
      quoteVolume: 65993355.663363345,
      btcVolume: 6218.463186844811,
      usdVolume: 65993355.663363345,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '8.9801000000000000',
      high: '9.6829000000000000',
      low: '8.4016000000000000',
      close: '9.5594000000000000',
      volume: '10110727.7500000000000000',
      quoteVolume: 91351206.53641662,
      btcVolume: 8488.116029639972,
      usdVolume: 91351206.53641662,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '9.5519000000000000',
      high: '10.5254000000000000',
      low: '9.3701000000000000',
      close: '10.3959000000000000',
      volume: '11276147.4500000000000000',
      quoteVolume: 112848361.62231602,
      btcVolume: 10272.349870485246,
      usdVolume: 112848361.62231602,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '10.3923000000000000',
      high: '10.9200000000000000',
      low: '10.3700000000000000',
      close: '10.5198000000000000',
      volume: '9504046.9700000000000000',
      quoteVolume: 100981242.65024652,
      btcVolume: 8918.134330290197,
      usdVolume: 100981242.65024652,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '10.5168000000000000',
      high: '11.0258000000000000',
      low: '10.1500000000000000',
      close: '10.8440000000000000',
      volume: '8011810.1500000000000000',
      quoteVolume: 85068557.21714121,
      btcVolume: 7485.698997274424,
      usdVolume: 85068557.21714121,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '10.8441000000000000',
      high: '11.8028000000000000',
      low: '10.6807000000000000',
      close: '11.4242000000000000',
      volume: '9262541.8700000000000000',
      quoteVolume: 104346545.63303679,
      btcVolume: 9109.599515130829,
      usdVolume: 104346545.63303679,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '11.4599000000000000',
      high: '11.5520000000000000',
      low: '10.6592000000000000',
      close: '11.0016000000000000',
      volume: '7617422.8600000000000000',
      quoteVolume: 85252142.00798237,
      btcVolume: 7456.401573669332,
      usdVolume: 85252142.00798237,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '11.0016000000000000',
      high: '11.3536000000000000',
      low: '10.6201000000000000',
      close: '10.8896000000000000',
      volume: '6061485.9500000000000000',
      quoteVolume: 66687126.90002584,
      btcVolume: 5850.816052446876,
      usdVolume: 66687126.90002584,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '10.8896000000000000',
      high: '10.9317000000000000',
      low: '10.4550000000000000',
      close: '10.7483000000000000',
      volume: '6908169.7600000000000000',
      quoteVolume: 73900491.87788318,
      btcVolume: 6486.307989052753,
      usdVolume: 73900491.87788318,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '10.7472000000000000',
      high: '10.8935000000000000',
      low: '10.2694000000000000',
      close: '10.5877000000000000',
      volume: '6662666.8600000000000000',
      quoteVolume: 70509800.96815944,
      btcVolume: 6215.660703350463,
      usdVolume: 70509800.96815944,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '10.5867000000000000',
      high: '10.7149000000000000',
      low: '10.4082000000000000',
      close: '10.6267000000000000',
      volume: '3320266.0600000000000000',
      quoteVolume: 35074078.772476934,
      btcVolume: 3094.4010233917893,
      usdVolume: 35074078.772476934,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '10.6254000000000000',
      high: '11.0000000000000000',
      low: '10.6000000000000000',
      close: '10.9409000000000000',
      volume: '3679623.9400000000000000',
      quoteVolume: 39786628.52001377,
      btcVolume: 3482.0203876379023,
      usdVolume: 39786628.52001377,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '10.9435000000000000',
      high: '11.2226000000000000',
      low: '10.6510000000000000',
      close: '10.9147000000000000',
      volume: '4474849.3100000000000000',
      quoteVolume: 48718810.825249076,
      btcVolume: 4198.544817946333,
      usdVolume: 48718810.825249076,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '10.9116000000000000',
      high: '10.9349000000000000',
      low: '9.7665000000000000',
      close: '9.8589000000000000',
      volume: '7095267.1100000000000000',
      quoteVolume: 73344149.05086827,
      btcVolume: 6182.229702645229,
      usdVolume: 73344149.05086827,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '9.8589000000000000',
      high: '11.1372000000000000',
      low: '9.7864000000000000',
      close: '10.7863000000000000',
      volume: '8276637.4200000000000000',
      quoteVolume: 87959927.25448503,
      btcVolume: 7031.471299457609,
      usdVolume: 87959927.25448503,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '10.7805000000000000',
      high: '12.4173000000000000',
      low: '10.7142000000000000',
      close: '11.9539000000000000',
      volume: '10301801.8300000000000000',
      quoteVolume: 120278047.81922631,
      btcVolume: 9287.505769123953,
      usdVolume: 120278047.81922631,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '11.9551000000000000',
      high: '12.2640000000000000',
      low: '11.5784000000000000',
      close: '11.7869000000000000',
      volume: '6599326.7100000000000000',
      quoteVolume: 78921676.86675517,
      btcVolume: 6109.358731500419,
      usdVolume: 78921676.86675517,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '12.1592000000000000',
      high: '12.9500000000000000',
      low: '11.9839000000000000',
      close: '12.7001000000000000',
      volume: '7531584.3600000000000000',
      quoteVolume: 94612367.21991429,
      btcVolume: 7266.641304869805,
      usdVolume: 94612367.21991429,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '12.7002000000000000',
      high: '12.8574000000000000',
      low: '11.9080000000000000',
      close: '12.1481000000000000',
      volume: '6996448.0600000000000000',
      quoteVolume: 86499461.98964313,
      btcVolume: 6638.643976139153,
      usdVolume: 86499461.98964313,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '12.1480000000000000',
      high: '12.4472000000000000',
      low: '11.2594000000000000',
      close: '11.7096000000000000',
      volume: '8831828.5200000000000000',
      quoteVolume: 104763458.12184793,
      btcVolume: 8038.602388420682,
      usdVolume: 104763458.12184793,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '11.7086000000000000',
      high: '12.4774000000000000',
      low: '11.4900000000000000',
      close: '11.9224000000000000',
      volume: '7598237.9300000000000000',
      quoteVolume: 91297276.70568031,
      btcVolume: 6804.543512604595,
      usdVolume: 91297276.70568031,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '11.9210000000000000',
      high: '12.1509000000000000',
      low: '11.1500000000000000',
      close: '11.4403000000000000',
      volume: '6787355.3400000000000000',
      quoteVolume: 78579989.27577649,
      btcVolume: 5868.232165162447,
      usdVolume: 78579989.27577649,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '11.4448000000000000',
      high: '11.8100000000000000',
      low: '11.0816000000000000',
      close: '11.2772000000000000',
      volume: '8541237.8300000000000000',
      quoteVolume: 97758547.62588826,
      btcVolume: 7336.005858513019,
      usdVolume: 97758547.62588826,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '11.2743000000000000',
      high: '11.4811000000000000',
      low: '10.7200000000000000',
      close: '11.0900000000000000',
      volume: '7958701.4600000000000000',
      quoteVolume: 87936295.50341521,
      btcVolume: 6567.679161107949,
      usdVolume: 87936295.50341521,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '11.0947000000000000',
      high: '11.3981000000000000',
      low: '10.9053000000000000',
      close: '11.2277000000000000',
      volume: '4822720.4700000000000000',
      quoteVolume: 53963475.40082074,
      btcVolume: 3926.032199920614,
      usdVolume: 53963475.40082074,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '11.2259000000000000',
      high: '11.5885000000000000',
      low: '11.0181000000000000',
      close: '11.5722000000000000',
      volume: '3872679.9900000000000000',
      quoteVolume: 43600203.22140587,
      btcVolume: 3168.731592931039,
      usdVolume: 43600203.22140587,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '11.5681000000000000',
      high: '11.6850000000000000',
      low: '10.7049000000000000',
      close: '10.7814000000000000',
      volume: '6919050.6800000000000000',
      quoteVolume: 77130468.23187579,
      btcVolume: 5694.891968008859,
      usdVolume: 77130468.23187579,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '10.7814000000000000',
      high: '10.7814000000000000',
      low: '9.9454000000000000',
      close: '10.3911000000000000',
      volume: '9925376.6400000000000000',
      quoteVolume: 102949946.71757828,
      btcVolume: 7589.417998761801,
      usdVolume: 102949946.71757828,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '10.3982000000000000',
      high: '10.6750000000000000',
      low: '9.7500000000000000',
      close: '10.4713000000000000',
      volume: '8586603.9100000000000000',
      quoteVolume: 87954953.98638126,
      btcVolume: 6320.607986664108,
      usdVolume: 87954953.98638126,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '10.4730000000000000',
      high: '11.2749000000000000',
      low: '10.0764000000000000',
      close: '10.9794000000000000',
      volume: '10616943.9300000000000000',
      quoteVolume: 113756787.2898385,
      btcVolume: 7651.513480243185,
      usdVolume: 113756787.2898385,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '10.9822000000000000',
      high: '12.2813000000000000',
      low: '10.9286000000000000',
      close: '12.1687000000000000',
      volume: '10613986.0500000000000000',
      quoteVolume: 123261012.0719206,
      btcVolume: 7916.228371545654,
      usdVolume: 123261012.0719206,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '12.1686000000000000',
      high: '13.5956000000000000',
      low: '11.3123000000000000',
      close: '11.7638000000000000',
      volume: '24836053.4300000000000000',
      quoteVolume: 312825764.5672563,
      btcVolume: 20466.466375561587,
      usdVolume: 312825764.5672563,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '11.7668000000000000',
      high: '13.1000000000000000',
      low: '11.4770000000000000',
      close: '12.7100000000000000',
      volume: '12895124.5400000000000000',
      quoteVolume: 159621589.8883934,
      btcVolume: 10501.569151809028,
      usdVolume: 159621589.8883934,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '12.7083000000000000',
      high: '13.0026000000000000',
      low: '12.0400000000000000',
      close: '12.5107000000000000',
      volume: '12634927.8300000000000000',
      quoteVolume: 157868780.3591942,
      btcVolume: 10278.693917702634,
      usdVolume: 157868780.3591942,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '12.5182000000000000',
      high: '13.2780000000000000',
      low: '12.3156000000000000',
      close: '13.0017000000000000',
      volume: '12313102.0800000000000000',
      quoteVolume: 158472628.9918838,
      btcVolume: 10350.422830750087,
      usdVolume: 158472628.9918838,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '13.0017000000000000',
      high: '13.4293000000000000',
      low: '12.7500000000000000',
      close: '12.7530000000000000',
      volume: '9402996.4400000000000000',
      quoteVolume: 123227278.88061701,
      btcVolume: 7903.41235731508,
      usdVolume: 123227278.88061701,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '12.7530000000000000',
      high: '13.0956000000000000',
      low: '12.2700000000000000',
      close: '12.4038000000000000',
      volume: '8887319.0400000000000000',
      quoteVolume: 112105873.89800362,
      btcVolume: 7056.930684473489,
      usdVolume: 112105873.89800362,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '12.4038000000000000',
      high: '13.0000000000000000',
      low: '12.3079000000000000',
      close: '12.8687000000000000',
      volume: '7183843.8700000000000000',
      quoteVolume: 91486357.29356198,
      btcVolume: 5621.798860970854,
      usdVolume: 91486357.29356198,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '12.8679000000000000',
      high: '12.9335000000000000',
      low: '12.3000000000000000',
      close: '12.5574000000000000',
      volume: '4779543.0800000000000000',
      quoteVolume: 60108858.82407817,
      btcVolume: 3755.1233582281616,
      usdVolume: 60108858.82407817,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '12.5570000000000000',
      high: '12.6484000000000000',
      low: '11.8156000000000000',
      close: '12.1192000000000000',
      volume: '5705889.4900000000000000',
      quoteVolume: 70141538.75487132,
      btcVolume: 4393.466812059062,
      usdVolume: 70141538.75487132,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '12.1199000000000000',
      high: '12.8132000000000000',
      low: '11.9971000000000000',
      close: '12.6223000000000000',
      volume: '5966554.6100000000000000',
      quoteVolume: 74312723.17503132,
      btcVolume: 4538.090941158825,
      usdVolume: 74312723.17503132,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '12.6204000000000000',
      high: '13.4228000000000000',
      low: '12.5414000000000000',
      close: '13.3944000000000000',
      volume: '9943527.4100000000000000',
      quoteVolume: 128513966.31700706,
      btcVolume: 7475.957078646857,
      usdVolume: 128513966.31700706,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '13.3943000000000000',
      high: '14.2669000000000000',
      low: '12.5850000000000000',
      close: '13.5598000000000000',
      volume: '17994859.8100000000000000',
      quoteVolume: 243378004.2585356,
      btcVolume: 13652.72139475029,
      usdVolume: 243378004.2585356,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '13.5602000000000000',
      high: '14.0000000000000000',
      low: '13.0111000000000000',
      close: '13.6187000000000000',
      volume: '9295294.8300000000000000',
      quoteVolume: 125766975.70460743,
      btcVolume: 7051.256067524977,
      usdVolume: 125766975.70460743,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '13.6117000000000000',
      high: '14.4770000000000000',
      low: '13.4618000000000000',
      close: '14.0933000000000000',
      volume: '10955855.4700000000000000',
      quoteVolume: 153945485.5929507,
      btcVolume: 8393.83606216672,
      usdVolume: 153945485.5929507,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '14.0913000000000000',
      high: '15.5000000000000000',
      low: '13.6876000000000000',
      close: '15.4511000000000000',
      volume: '13999538.8800000000000000',
      quoteVolume: 204973635.11923143,
      btcVolume: 10955.623567420895,
      usdVolume: 204973635.11923143,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '15.4408000000000000',
      high: '15.7179000000000000',
      low: '13.8402000000000000',
      close: '14.5098000000000000',
      volume: '14244002.3200000000000000',
      quoteVolume: 209484344.0724672,
      btcVolume: 11387.241340723021,
      usdVolume: 209484344.0724672,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '14.5098000000000000',
      high: '15.4250000000000000',
      low: '14.2520000000000000',
      close: '15.2746000000000000',
      volume: '12165056.4100000000000000',
      quoteVolume: 181293925.92475033,
      btcVolume: 9837.750688600954,
      usdVolume: 181293925.92475033,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '15.2648000000000000',
      high: '16.3900000000000000',
      low: '14.9430000000000000',
      close: '15.6703000000000000',
      volume: '15636471.1700000000000000',
      quoteVolume: 244854909.96831247,
      btcVolume: 13082.91825525146,
      usdVolume: 244854909.96831247,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '15.6637000000000000',
      high: '15.8600000000000000',
      low: '13.7900000000000000',
      close: '14.2016000000000000',
      volume: '9463367.4500000000000000',
      quoteVolume: 142275307.68800414,
      btcVolume: 7489.979662764517,
      usdVolume: 142275307.68800414,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '14.2180000000000000',
      high: '14.4743000000000000',
      low: '11.2939000000000000',
      close: '12.6030000000000000',
      volume: '24581331.8400000000000000',
      quoteVolume: 307097131.8146662,
      btcVolume: 17664.03164046134,
      usdVolume: 307097131.8146662,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '12.6036000000000000',
      high: '12.9530000000000000',
      low: '11.9454000000000000',
      close: '12.4989000000000000',
      volume: '9126132.6500000000000000',
      quoteVolume: 113170817.60460344,
      btcVolume: 6660.581797963793,
      usdVolume: 113170817.60460344,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '12.5038000000000000',
      high: '13.4300000000000000',
      low: '12.3489000000000000',
      close: '13.0853000000000000',
      volume: '7281492.8400000000000000',
      quoteVolume: 94217840.3930073,
      btcVolume: 5414.366931727638,
      usdVolume: 94217840.3930073,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '13.0848000000000000',
      high: '13.4765000000000000',
      low: '12.8408000000000000',
      close: '13.3800000000000000',
      volume: '6537927.5000000000000000',
      quoteVolume: 85931374.67872472,
      btcVolume: 4773.352624805251,
      usdVolume: 85931374.67872472,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '13.3855000000000000',
      high: '14.4370000000000000',
      low: '13.3855000000000000',
      close: '14.2677000000000000',
      volume: '10253978.8400000000000000',
      quoteVolume: 143593067.98141998,
      btcVolume: 7589.521928283262,
      usdVolume: 143593067.98141998,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '14.2626000000000000',
      high: '14.8328000000000000',
      low: '13.0456000000000000',
      close: '13.3461000000000000',
      volume: '10704338.7400000000000000',
      quoteVolume: 149610565.32318997,
      btcVolume: 7801.160851270977,
      usdVolume: 149610565.32318997,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '13.3544000000000000',
      high: '14.1482000000000000',
      low: '13.0500000000000000',
      close: '13.9799000000000000',
      volume: '6137932.7300000000000000',
      quoteVolume: 83779802.26252918,
      btcVolume: 4424.74642432009,
      usdVolume: 83779802.26252918,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '13.9785000000000000',
      high: '14.2200000000000000',
      low: '13.6446000000000000',
      close: '13.9749000000000000',
      volume: '4275317.8800000000000000',
      quoteVolume: 59535321.2775434,
      btcVolume: 3090.5416607922175,
      usdVolume: 59535321.2775434,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '13.9749000000000000',
      high: '14.0332000000000000',
      low: '12.5101000000000000',
      close: '12.6800000000000000',
      volume: '5794937.4500000000000000',
      quoteVolume: 77275788.62363255,
      btcVolume: 4061.1546698955426,
      usdVolume: 77275788.62363255,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '12.6800000000000000',
      high: '13.3478000000000000',
      low: '12.5265000000000000',
      close: '13.1748000000000000',
      volume: '3411635.3200000000000000',
      quoteVolume: 44668221.07546615,
      btcVolume: 2355.8773509120115,
      usdVolume: 44668221.07546615,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '13.1743000000000000',
      high: '13.5282000000000000',
      low: '12.7671000000000000',
      close: '13.5057000000000000',
      volume: '3866618.8500000000000000',
      quoteVolume: 51005078.82048807,
      btcVolume: 2664.431067672835,
      usdVolume: 51005078.82048807,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '13.5090000000000000',
      high: '13.5536000000000000',
      low: '12.8671000000000000',
      close: '13.0704000000000000',
      volume: '3223051.6500000000000000',
      quoteVolume: 42439345.8764611,
      btcVolume: 2211.4995680383877,
      usdVolume: 42439345.8764611,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '13.0737000000000000',
      high: '13.1929000000000000',
      low: '12.0000000000000000',
      close: '12.1356000000000000',
      volume: '5175641.2100000000000000',
      quoteVolume: 65172913.683855735,
      btcVolume: 3458.1321327537844,
      usdVolume: 65172913.683855735,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '12.1280000000000000',
      high: '12.8290000000000000',
      low: '11.5726000000000000',
      close: '12.6212000000000000',
      volume: '6754613.8300000000000000',
      quoteVolume: 81954160.37834212,
      btcVolume: 4495.429433765595,
      usdVolume: 81954160.37834212,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '12.6200000000000000',
      high: '12.6730000000000000',
      low: '11.8549000000000000',
      close: '11.9172000000000000',
      volume: '3519174.2400000000000000',
      quoteVolume: 42896656.04262703,
      btcVolume: 2346.0300410065424,
      usdVolume: 42896656.04262703,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '11.9172000000000000',
      high: '11.9899000000000000',
      low: '11.4300000000000000',
      close: '11.6335000000000000',
      volume: '4337254.6200000000000000',
      quoteVolume: 50652575.468863204,
      btcVolume: 2820.6350744098336,
      usdVolume: 50647631.718757205,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '11.6393000000000000',
      high: '12.3529000000000000',
      low: '11.6201000000000000',
      close: '12.2259000000000000',
      volume: '3453043.1200000000000000',
      quoteVolume: 41720520.772683,
      btcVolume: 2259.4574947618594,
      usdVolume: 41720520.772683,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '12.2280000000000000',
      high: '13.2697000000000000',
      low: '12.0210000000000000',
      close: '12.9392000000000000',
      volume: '6036848.9700000000000000',
      quoteVolume: 76518629.71278152,
      btcVolume: 3990.4218542597705,
      usdVolume: 76518629.71278152,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '12.9389000000000000',
      high: '12.9800000000000000',
      low: '12.3238000000000000',
      close: '12.8502000000000000',
      volume: '4526653.6900000000000000',
      quoteVolume: 57446821.99876489,
      btcVolume: 2998.157797062246,
      usdVolume: 57446821.99876489,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '12.8513000000000000',
      high: '13.0220000000000000',
      low: '12.4659000000000000',
      close: '12.6818000000000000',
      volume: '2635221.9300000000000000',
      quoteVolume: 33514452.026845157,
      btcVolume: 1732.722900418818,
      usdVolume: 33514452.026845157,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '12.6786000000000000',
      high: '13.5000000000000000',
      low: '12.3119000000000000',
      close: '13.4852000000000000',
      volume: '5644198.7100000000000000',
      quoteVolume: 73189908.58627348,
      btcVolume: 3595.924463613392,
      usdVolume: 73189908.58627348,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '13.4840000000000000',
      high: '14.5038000000000000',
      low: '13.0575000000000000',
      close: '13.4440000000000000',
      volume: '11577270.1700000000000000',
      quoteVolume: 159653805.28869012,
      btcVolume: 7026.0614219511735,
      usdVolume: 159653805.28869012,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '13.4440000000000000',
      high: '14.0235000000000000',
      low: '13.1725000000000000',
      close: '13.5001000000000000',
      volume: '5513708.3800000000000000',
      quoteVolume: 74792637.47296071,
      btcVolume: 3271.2867842547794,
      usdVolume: 74792637.47296071,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '13.5000000000000000',
      high: '13.9697000000000000',
      low: '13.2981000000000000',
      close: '13.4747000000000000',
      volume: '4596156.0500000000000000',
      quoteVolume: 62945458.56621082,
      btcVolume: 2692.9200733659177,
      usdVolume: 62945458.56621082,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '13.4748000000000000',
      high: '13.8000000000000000',
      low: '12.6752000000000000',
      close: '13.0604000000000000',
      volume: '5227497.0200000000000000',
      quoteVolume: 69925342.0503345,
      btcVolume: 2966.1250407952425,
      usdVolume: 69925342.0503345,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '13.0603000000000000',
      high: '13.2700000000000000',
      low: '12.1354000000000000',
      close: '12.3049000000000000',
      volume: '5659600.6800000000000000',
      quoteVolume: 71716093.56996408,
      btcVolume: 3090.609511831206,
      usdVolume: 71716093.56996408,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '12.3028000000000000',
      high: '12.8400000000000000',
      low: '11.8117000000000000',
      close: '12.7842000000000000',
      volume: '6172266.6600000000000000',
      quoteVolume: 76364461.06396206,
      btcVolume: 3308.2084013040967,
      usdVolume: 76364461.06396206,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '12.7811000000000000',
      high: '12.8634000000000000',
      low: '8.0500000000000000',
      close: '10.9736000000000000',
      volume: '15154276.0300000000000000',
      quoteVolume: 168815393.817345,
      btcVolume: 7227.637473629581,
      usdVolume: 168815393.817345,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '10.9730000000000000',
      high: '11.6877000000000000',
      low: '9.8564000000000000',
      close: '11.5724000000000000',
      volume: '14771438.4000000000000000',
      quoteVolume: 157801028.12701663,
      btcVolume: 6807.5861925391055,
      usdVolume: 157801028.12701663,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '11.5705000000000000',
      high: '11.9234000000000000',
      low: '11.0601000000000000',
      close: '11.5543000000000000',
      volume: '7348236.1100000000000000',
      quoteVolume: 84426543.59749623,
      btcVolume: 3504.7630743045383,
      usdVolume: 84426543.59749623,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '11.5640000000000000',
      high: '11.8151000000000000',
      low: '10.6238000000000000',
      close: '10.9809000000000000',
      volume: '9093279.7400000000000000',
      quoteVolume: 101048617.75868994,
      btcVolume: 3982.7085174247964,
      usdVolume: 101048617.75868994,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '10.9840000000000000',
      high: '13.2448000000000000',
      low: '10.4763000000000000',
      close: '12.1541000000000000',
      volume: '18943413.3800000000000000',
      quoteVolume: 226941348.13784474,
      btcVolume: 8381.434369307135,
      usdVolume: 226941348.13784474,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '12.1542000000000000',
      high: '12.9781000000000000',
      low: '11.9710000000000000',
      close: '12.6081000000000000',
      volume: '11270755.2200000000000000',
      quoteVolume: 140279053.6851551,
      btcVolume: 5203.613393033124,
      usdVolume: 140279053.6851551,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '12.6080000000000000',
      high: '13.0351000000000000',
      low: '10.8161000000000000',
      close: '11.7786000000000000',
      volume: '12890847.2600000000000000',
      quoteVolume: 151849176.99178037,
      btcVolume: 5695.149438822997,
      usdVolume: 151849176.99178037,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '11.7786000000000000',
      high: '11.9900000000000000',
      low: '11.0671000000000000',
      close: '11.2435000000000000',
      volume: '7687754.9000000000000000',
      quoteVolume: 88083075.6322653,
      btcVolume: 3132.5608072986693,
      usdVolume: 88083075.6322653,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '11.2437000000000000',
      high: '11.7530000000000000',
      low: '10.9039000000000000',
      close: '11.2364000000000000',
      volume: '6639477.6200000000000000',
      quoteVolume: 75178212.43988732,
      btcVolume: 2609.4447854849886,
      usdVolume: 75178212.43988732,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '11.2364000000000000',
      high: '12.4600000000000000',
      low: '11.0638000000000000',
      close: '11.8477000000000000',
      volume: '8245184.6000000000000000',
      quoteVolume: 97963056.43351899,
      btcVolume: 3350.468743703054,
      usdVolume: 97963056.43351899,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '11.8453000000000000',
      high: '12.4188000000000000',
      low: '11.4303000000000000',
      close: '12.2215000000000000',
      volume: '9617243.6900000000000000',
      quoteVolume: 114480499.1565445,
      btcVolume: 3665.1539501073544,
      usdVolume: 114480499.1565445,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '12.2206000000000000',
      high: '13.9999000000000000',
      low: '11.8355000000000000',
      close: '13.6814000000000000',
      volume: '15927066.3100000000000000',
      quoteVolume: 205085520.50347137,
      btcVolume: 6124.344091873578,
      usdVolume: 205085520.50347137,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '13.6815000000000000',
      high: '15.6440000000000000',
      low: '12.3713000000000000',
      close: '13.5935000000000000',
      volume: '23196077.6800000000000000',
      quoteVolume: 321727867.96833014,
      btcVolume: 10107.698949256002,
      usdVolume: 321727867.96833014,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '13.5962000000000000',
      high: '14.8900000000000000',
      low: '13.0928000000000000',
      close: '14.5162000000000000',
      volume: '14809606.1500000000000000',
      quoteVolume: 208905104.45490494,
      btcVolume: 6485.977306193403,
      usdVolume: 208905104.45490494,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '14.5141000000000000',
      high: '17.2997000000000000',
      low: '14.1427000000000000',
      close: '17.2322000000000000',
      volume: '21280047.1800000000000000',
      quoteVolume: 338322543.10816723,
      btcVolume: 9721.843587977792,
      usdVolume: 338322543.10816723,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '17.2342000000000000',
      high: '17.4100000000000000',
      low: '15.3542000000000000',
      close: '15.9628000000000000',
      volume: '16135019.3000000000000000',
      quoteVolume: 266063447.9945453,
      btcVolume: 6971.427792671456,
      usdVolume: 266063447.9945453,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '15.9659000000000000',
      high: '16.1917000000000000',
      low: '14.1200000000000000',
      close: '15.2381000000000000',
      volume: '14730622.4700000000000000',
      quoteVolume: 225837560.70656046,
      btcVolume: 5699.007296482832,
      usdVolume: 225837560.70656046,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '15.2395000000000000',
      high: '17.8888000000000000',
      low: '15.0029000000000000',
      close: '17.5501000000000000',
      volume: '12807954.5600000000000000',
      quoteVolume: 209112616.22126392,
      btcVolume: 5179.822048220309,
      usdVolume: 209112616.22126392,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '17.5501000000000000',
      high: '18.4386000000000000',
      low: '15.4418000000000000',
      close: '16.2060000000000000',
      volume: '16459987.7000000000000000',
      quoteVolume: 280219098.9046208,
      btcVolume: 7124.706550510726,
      usdVolume: 280219098.9046208,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '16.1998000000000000',
      high: '16.2596000000000000',
      low: '12.5000000000000000',
      close: '14.6393000000000000',
      volume: '21046546.5800000000000000',
      quoteVolume: 299155444.76900715,
      btcVolume: 8793.50832897109,
      usdVolume: 299155444.76900715,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '14.6312000000000000',
      high: '15.4760000000000000',
      low: '13.6701000000000000',
      close: '13.9655000000000000',
      volume: '8959437.3100000000000000',
      quoteVolume: 130225909.90950419,
      btcVolume: 3745.877009935573,
      usdVolume: 130225909.90950419,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '13.9609000000000000',
      high: '16.2356000000000000',
      low: '13.3701000000000000',
      close: '15.9356000000000000',
      volume: '10911746.9200000000000000',
      quoteVolume: 163912112.80508533,
      btcVolume: 4699.829494560408,
      usdVolume: 163912112.80508533,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '15.9396000000000000',
      high: '17.9900000000000000',
      low: '15.3300000000000000',
      close: '17.9804000000000000',
      volume: '13478182.9400000000000000',
      quoteVolume: 222149143.90415877,
      btcVolume: 5733.229551150123,
      usdVolume: 222149143.90415877,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '17.9888000000000000',
      high: '21.4221000000000000',
      low: '17.5355000000000000',
      close: '20.7375000000000000',
      volume: '31054166.3600000000000000',
      quoteVolume: 598915855.4361597,
      btcVolume: 16128.175112869507,
      usdVolume: 598915855.4361597,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '20.7405000000000000',
      high: '22.9600000000000000',
      low: '19.6280000000000000',
      close: '20.1054000000000000',
      volume: '22583400.8600000000000000',
      quoteVolume: 488477166.4998309,
      btcVolume: 13263.862594524382,
      usdVolume: 488477166.4998309,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '20.1003000000000000',
      high: '23.7670000000000000',
      low: '19.3729000000000000',
      close: '23.2819000000000000',
      volume: '21794612.1800000000000000',
      quoteVolume: 467638469.6446607,
      btcVolume: 13093.00975534152,
      usdVolume: 467638469.6446607,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '23.2676000000000000',
      high: '23.6862000000000000',
      low: '21.0600000000000000',
      close: '22.0336000000000000',
      volume: '13776439.8500000000000000',
      quoteVolume: 305061086.4015469,
      btcVolume: 8477.02470472144,
      usdVolume: 305061086.4015469,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '22.0325000000000000',
      high: '22.8248000000000000',
      low: '20.3774000000000000',
      close: '20.5557000000000000',
      volume: '10237213.4000000000000000',
      quoteVolume: 221370711.89643016,
      btcVolume: 6011.617268406959,
      usdVolume: 221370711.89643016,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '20.5437000000000000',
      high: '21.9788000000000000',
      low: '19.0645000000000000',
      close: '21.8482000000000000',
      volume: '12445387.5700000000000000',
      quoteVolume: 253587783.00309002,
      btcVolume: 7255.599211354413,
      usdVolume: 253587783.00309002,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '21.8421000000000000',
      high: '22.0307000000000000',
      low: '18.0611000000000000',
      close: '18.4119000000000000',
      volume: '14800253.5200000000000000',
      quoteVolume: 295655182.9197108,
      btcVolume: 9063.9657818205,
      usdVolume: 295655182.9197108,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '18.4109000000000000',
      high: '22.4725000000000000',
      low: '17.1269000000000000',
      close: '21.5519000000000000',
      volume: '16210103.8000000000000000',
      quoteVolume: 326743324.4039971,
      btcVolume: 10379.828662817843,
      usdVolume: 326743324.4039971,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '21.5467000000000000',
      high: '25.5000000000000000',
      low: '21.1400000000000000',
      close: '24.8046000000000000',
      volume: '14892659.5600000000000000',
      quoteVolume: 357496388.1897744,
      btcVolume: 11065.14109872835,
      usdVolume: 357496388.1897744,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '24.8168000000000000',
      high: '24.9530000000000000',
      low: '23.1612000000000000',
      close: '24.7935000000000000',
      volume: '11903411.3600000000000000',
      quoteVolume: 288283267.4123565,
      btcVolume: 8969.474384440253,
      usdVolume: 288283267.4123565,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '24.7912000000000000',
      high: '25.7824000000000000',
      low: '22.6000000000000000',
      close: '23.3631000000000000',
      volume: '11247196.2700000000000000',
      quoteVolume: 275657899.55470353,
      btcVolume: 8265.386510770037,
      usdVolume: 275657899.55470353,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '22.4042000000000000',
      high: '23.3000000000000000',
      low: '22.3137000000000000',
      close: '23.0981000000000000',
      volume: '2366823.8100000000000000',
      quoteVolume: 54138852.50846183,
      btcVolume: 1682.3671327730394,
      usdVolume: 54138852.50846183,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '23.0914000000000000',
      high: '23.2295000000000000',
      low: '20.2767000000000000',
      close: '20.9586000000000000',
      volume: '8535198.1600000000000000',
      quoteVolume: 182731435.30837923,
      btcVolume: 5920.435835584469,
      usdVolume: 182731435.30837923,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '20.9586000000000000',
      high: '24.4716000000000000',
      low: '20.5734000000000000',
      close: '23.0145000000000000',
      volume: '8468868.5600000000000000',
      quoteVolume: 197396206.1119182,
      btcVolume: 6236.369329316392,
      usdVolume: 197396206.1119182,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '22.9869000000000000',
      high: '24.2400000000000000',
      low: '22.0450000000000000',
      close: '22.7480000000000000',
      volume: '9016409.4900000000000000',
      quoteVolume: 208328902.77130583,
      btcVolume: 5907.088801742898,
      usdVolume: 208328902.77130583,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '22.7480000000000000',
      high: '23.9800000000000000',
      low: '22.1594000000000000',
      close: '23.6399000000000000',
      volume: '6773423.4600000000000000',
      quoteVolume: 156017856.8708368,
      btcVolume: 4581.931097812988,
      usdVolume: 156017856.8708368,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '23.6491000000000000',
      high: '24.6780000000000000',
      low: '22.0603000000000000',
      close: '22.5613000000000000',
      volume: '5361442.2200000000000000',
      quoteVolume: 124076481.29119983,
      btcVolume: 3697.017425316733,
      usdVolume: 124076481.29119983,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '22.5643000000000000',
      high: '23.2000000000000000',
      low: '21.6526000000000000',
      close: '22.8757000000000000',
      volume: '5564509.6300000000000000',
      quoteVolume: 124203143.57187262,
      btcVolume: 3687.457488368662,
      usdVolume: 124203143.57187262,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '22.8752000000000000',
      high: '24.1693000000000000',
      low: '22.7354000000000000',
      close: '23.6582000000000000',
      volume: '6470015.7600000000000000',
      quoteVolume: 150444221.2962143,
      btcVolume: 4337.8794213047395,
      usdVolume: 150444221.2962143,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '23.6578000000000000',
      high: '25.5674000000000000',
      low: '23.5318000000000000',
      close: '25.0870000000000000',
      volume: '8459477.4100000000000000',
      quoteVolume: 208200036.68349785,
      btcVolume: 5709.561538472078,
      usdVolume: 208200036.68349785,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '25.0814000000000000',
      high: '26.1500000000000000',
      low: '22.6174000000000000',
      close: '24.4920000000000000',
      volume: '8629885.3200000000000000',
      quoteVolume: 211063847.95773497,
      btcVolume: 5630.340958663389,
      usdVolume: 211063847.95773497,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '24.4841000000000000',
      high: '27.0000000000000000',
      low: '24.4081000000000000',
      close: '26.3034000000000000',
      volume: '9640790.8100000000000000',
      quoteVolume: 251859619.30684873,
      btcVolume: 6697.035136045025,
      usdVolume: 251859619.30684873,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '26.3023000000000000',
      high: '26.8000000000000000',
      low: '24.2081000000000000',
      close: '25.0231000000000000',
      volume: '7043800.3900000000000000',
      quoteVolume: 178656384.6660932,
      btcVolume: 4511.137870600865,
      usdVolume: 178656384.6660932,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '25.0336000000000000',
      high: '25.2836000000000000',
      low: '22.8100000000000000',
      close: '24.7254000000000000',
      volume: '6499629.3000000000000000',
      quoteVolume: 157729890.3566084,
      btcVolume: 4097.192310639251,
      usdVolume: 157729890.3566084,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '24.7335000000000000',
      high: '25.9800000000000000',
      low: '23.9587000000000000',
      close: '25.4115000000000000',
      volume: '6837186.9500000000000000',
      quoteVolume: 171815141.03402072,
      btcVolume: 4122.278957205255,
      usdVolume: 171815141.03402072,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '25.4070000000000000',
      high: '28.0000000000000000',
      low: '25.0000000000000000',
      close: '27.6034000000000000',
      volume: '9472870.3200000000000000',
      quoteVolume: 246830741.98450413,
      btcVolume: 5292.997466768552,
      usdVolume: 246830741.98450413,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '27.6150000000000000',
      high: '28.6412000000000000',
      low: '25.1227000000000000',
      close: '26.8121000000000000',
      volume: '11717346.8200000000000000',
      quoteVolume: 318420727.69046074,
      btcVolume: 6960.680395575012,
      usdVolume: 318411629.02087176,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '26.8000000000000000',
      high: '28.5705000000000000',
      low: '26.2172000000000000',
      close: '27.8475000000000000',
      volume: '6650588.7300000000000000',
      quoteVolume: 183913909.31249094,
      btcVolume: 3961.896278975156,
      usdVolume: 183913909.31249094,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '27.8645000000000000',
      high: '31.4485000000000000',
      low: '26.6746000000000000',
      close: '30.6265000000000000',
      volume: '9749785.0200000000000000',
      quoteVolume: 284077020.56706005,
      btcVolume: 5975.622685196747,
      usdVolume: 284045968.225476,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '30.6404000000000000',
      high: '34.7573000000000000',
      low: '29.0700000000000000',
      close: '34.1941000000000000',
      volume: '11011345.3200000000000000',
      quoteVolume: 346030518.51178366,
      btcVolume: 7340.703684708217,
      usdVolume: 345996921.8331248,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '34.1969000000000000',
      high: '35.6945000000000000',
      low: '32.3088000000000000',
      close: '33.1565000000000000',
      volume: '8337078.5800000000000000',
      quoteVolume: 283546794.60109466,
      btcVolume: 5868.713681268827,
      usdVolume: 283546794.60109466,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '33.1555000000000000',
      high: '34.9252000000000000',
      low: '27.5000000000000000',
      close: '32.5899000000000000',
      volume: '10469894.2400000000000000',
      quoteVolume: 335112228.9163464,
      btcVolume: 7001.374521254515,
      usdVolume: 333251261.0761585,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '32.5853000000000000',
      high: '33.4269000000000000',
      low: '30.7840000000000000',
      close: '31.9632000000000000',
      volume: '6985113.3100000000000000',
      quoteVolume: 224245398.9060409,
      btcVolume: 4510.950361788866,
      usdVolume: 220012561.30996123,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '31.9630000000000000',
      high: '32.6705000000000000',
      low: '30.0000000000000000',
      close: '32.2324000000000000',
      volume: '5259067.4900000000000000',
      quoteVolume: 166231266.71195933,
      btcVolume: 3258.0237581539295,
      usdVolume: 166231266.71195933,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '32.2256000000000000',
      high: '33.5961000000000000',
      low: '31.7785000000000000',
      close: '32.4801000000000000',
      volume: '5451118.6700000000000000',
      quoteVolume: 177745245.9786863,
      btcVolume: 3426.75446906022,
      usdVolume: 177745245.9786863,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '32.4727000000000000',
      high: '35.5072000000000000',
      low: '31.3799000000000000',
      close: '34.6846000000000000',
      volume: '8360898.6500000000000000',
      quoteVolume: 281294280.39428127,
      btcVolume: 5294.475712342659,
      usdVolume: 281294280.39428127,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '34.6846000000000000',
      high: '36.9307000000000000',
      low: '32.0000000000000000',
      close: '33.9387000000000000',
      volume: '10054962.6900000000000000',
      quoteVolume: 352536639.04229677,
      btcVolume: 6272.494218045403,
      usdVolume: 352532151.53390276,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '33.9293000000000000',
      high: '35.2918000000000000',
      low: '33.2712000000000000',
      close: '34.0915000000000000',
      volume: '5179283.2400000000000000',
      quoteVolume: 177022601.01385555,
      btcVolume: 3109.2651388863383,
      usdVolume: 177022601.01385555,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '34.0916000000000000',
      high: '34.2185000000000000',
      low: '25.2312000000000000',
      close: '31.4542000000000000',
      volume: '10543122.8600000000000000',
      quoteVolume: 319683313.051021,
      btcVolume: 5634.770333904902,
      usdVolume: 301180710.3156824,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '31.4482000000000000',
      high: '31.5822000000000000',
      low: '21.6261000000000000',
      close: '25.9249000000000000',
      volume: '21560485.0600000000000000',
      quoteVolume: 554568596.2290249,
      btcVolume: 11275.518366580607,
      usdVolume: 545229229.464444,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '25.9573000000000000',
      high: '28.8292000000000000',
      low: '24.5050000000000000',
      close: '28.0851000000000000',
      volume: '6709381.7900000000000000',
      quoteVolume: 183384362.28603324,
      btcVolume: 3687.2224811202454,
      usdVolume: 183355744.81425026,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '28.0737000000000000',
      high: '28.6802000000000000',
      low: '24.2509000000000000',
      close: '24.7144000000000000',
      volume: '7893277.6100000000000000',
      quoteVolume: 214328348.89058003,
      btcVolume: 4308.799154273922,
      usdVolume: 214328348.89058003,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '24.7144000000000000',
      high: '26.9906000000000000',
      low: '23.6574000000000000',
      close: '25.1862000000000000',
      volume: '9016978.7100000000000000',
      quoteVolume: 226299006.61160633,
      btcVolume: 4860.302348071939,
      usdVolume: 226070729.98285314,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '25.1650000000000000',
      high: '27.4472000000000000',
      low: '24.8545000000000000',
      close: '26.1896000000000000',
      volume: '7622190.2300000000000000',
      quoteVolume: 197306897.2042366,
      btcVolume: 4194.093858773107,
      usdVolume: 197306897.2042366,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '26.1941000000000000',
      high: '26.7624000000000000',
      low: '22.8578000000000000',
      close: '24.7016000000000000',
      volume: '7513099.0000000000000000',
      quoteVolume: 183181771.37759796,
      btcVolume: 4101.6732584746605,
      usdVolume: 183181771.37759796,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '24.6954000000000000',
      high: '27.7326000000000000',
      low: '24.5758000000000000',
      close: '27.7116000000000000',
      volume: '11662106.9800000000000000',
      quoteVolume: 309292384.27149624,
      btcVolume: 6431.989232060969,
      usdVolume: 309292384.27149624,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '27.7187000000000000',
      high: '30.5251000000000000',
      low: '27.1757000000000000',
      close: '28.4769000000000000',
      volume: '9706793.4600000000000000',
      quoteVolume: 280186454.4698888,
      btcVolume: 5764.268832849585,
      usdVolume: 280186454.4698888,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '28.4640000000000000',
      high: '31.4300000000000000',
      low: '28.1272000000000000',
      close: '29.8500000000000000',
      volume: '6412989.4500000000000000',
      quoteVolume: 193702490.28628224,
      btcVolume: 3844.793474650542,
      usdVolume: 193702490.28628224,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '29.8334000000000000',
      high: '30.5731000000000000',
      low: '27.1891000000000000',
      close: '27.6943000000000000',
      volume: '6789296.5900000000000000',
      quoteVolume: 196271455.16863889,
      btcVolume: 3979.194700761396,
      usdVolume: 196249322.53349993,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '27.6856000000000000',
      high: '28.2338000000000000',
      low: '25.6483000000000000',
      close: '27.8343000000000000',
      volume: '5832853.2100000000000000',
      quoteVolume: 156056362.04028454,
      btcVolume: 3286.3488640127302,
      usdVolume: 156056362.04028454,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '27.8195000000000000',
      high: '28.3985000000000000',
      low: '26.2889000000000000',
      close: '28.1479000000000000',
      volume: '3381150.1600000000000000',
      quoteVolume: 92804331.2379402,
      btcVolume: 1896.9852933650902,
      usdVolume: 91553822.80733496,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '28.1608000000000000',
      high: '29.1258000000000000',
      low: '27.3859000000000000',
      close: '28.5932000000000000',
      volume: '4599258.0200000000000000',
      quoteVolume: 130068525.6266061,
      btcVolume: 2589.6634533421866,
      usdVolume: 130068525.6266061,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '28.5918000000000000',
      high: '31.8469000000000000',
      low: '28.2652000000000000',
      close: '31.8469000000000000',
      volume: '6681348.4500000000000000',
      quoteVolume: 199010644.3594535,
      btcVolume: 3922.101818010387,
      usdVolume: 199010644.3594535,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '31.8227000000000000',
      high: '32.0000000000000000',
      low: '30.3639000000000000',
      close: '31.4914000000000000',
      volume: '4049655.7000000000000000',
      quoteVolume: 126227587.21440111,
      btcVolume: 2342.082973005306,
      usdVolume: 126227587.21440111,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '31.4914000000000000',
      high: '31.7934000000000000',
      low: '29.2545000000000000',
      close: '29.9036000000000000',
      volume: '4027599.8200000000000000',
      quoteVolume: 122397560.04919197,
      btcVolume: 2207.964000897798,
      usdVolume: 122396451.37618196,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '29.8743000000000000',
      high: '30.5285000000000000',
      low: '28.5516000000000000',
      close: '30.1166000000000000',
      volume: '5024365.7100000000000000',
      quoteVolume: 148147003.90674606,
      btcVolume: 2628.6223761839137,
      usdVolume: 148147003.90674606,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '30.1227000000000000',
      high: '30.4110000000000000',
      low: '27.3717000000000000',
      close: '28.1489000000000000',
      volume: '4477144.4100000000000000',
      quoteVolume: 128936166.34822313,
      btcVolume: 1583.1266319722276,
      usdVolume: 89974102.5483421,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '27.7838000000000000',
      high: '30.5700000000000000',
      low: '26.9326000000000000',
      close: '29.7384000000000000',
      volume: '5584451.5200000000000000',
      quoteVolume: 162487581.38361448,
      btcVolume: 2765.323829329903,
      usdVolume: 162487581.38361448,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '29.7506000000000000',
      high: '30.2000000000000000',
      low: '28.5108000000000000',
      close: '28.5469000000000000',
      volume: '3121480.3400000000000000',
      quoteVolume: 91263041.59569518,
      btcVolume: 1507.0857507872481,
      usdVolume: 91064805.56460914,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '28.5310000000000000',
      high: '29.4217000000000000',
      low: '26.8000000000000000',
      close: '27.5091000000000000',
      volume: '5236280.7600000000000000',
      quoteVolume: 145357316.8911458,
      btcVolume: 2536.539090783008,
      usdVolume: 145357316.8911458,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '27.5006000000000000',
      high: '28.1883000000000000',
      low: '26.3975000000000000',
      close: '27.9099000000000000',
      volume: '3202429.1200000000000000',
      quoteVolume: 87907886.1985268,
      btcVolume: 1592.4117076930133,
      usdVolume: 87865565.54260874,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '27.9100000000000000',
      high: '31.2000000000000000',
      low: '27.3027000000000000',
      close: '31.0579000000000000',
      volume: '8119714.6200000000000000',
      quoteVolume: 240849861.95782134,
      btcVolume: 4319.501158108978,
      usdVolume: 240849861.95782134,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '31.0579000000000000',
      high: '31.3588000000000000',
      low: '29.0000000000000000',
      close: '29.3949000000000000',
      volume: '4982847.5300000000000000',
      quoteVolume: 149502922.10217696,
      btcVolume: 2552.5916810317053,
      usdVolume: 149502922.10217696,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '29.3929000000000000',
      high: '30.5510000000000000',
      low: '28.6802000000000000',
      close: '29.7770000000000000',
      volume: '3239965.5200000000000000',
      quoteVolume: 96988651.02827796,
      btcVolume: 1661.0995932340902,
      usdVolume: 96861045.95362605,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '29.7866000000000000',
      high: '31.3809000000000000',
      low: '29.5800000000000000',
      close: '29.6465000000000000',
      volume: '2540903.5400000000000000',
      quoteVolume: 77772991.47447608,
      btcVolume: 1323.149915780175,
      usdVolume: 77770316.54868808,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '29.6564000000000000',
      high: '30.0800000000000000',
      low: '28.5817000000000000',
      close: '29.2097000000000000',
      volume: '2532521.5100000000000000',
      quoteVolume: 74287913.29808004,
      btcVolume: 1297.2908602936614,
      usdVolume: 74260278.37190706,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '29.2117000000000000',
      high: '29.4444000000000000',
      low: '27.1362000000000000',
      close: '27.2431000000000000',
      volume: '3564715.9100000000000000',
      quoteVolume: 101808090.24197517,
      btcVolume: 1809.4616360427897,
      usdVolume: 101741677.36866613,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '27.2551000000000000',
      high: '27.9351000000000000',
      low: '26.6283000000000000',
      close: '26.7813000000000000',
      volume: '3659416.4600000000000000',
      quoteVolume: 99812413.47572565,
      btcVolume: 1215.8619127352338,
      usdVolume: 66191594.700597785,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '26.7831000000000000',
      high: '27.8814000000000000',
      low: '24.0404000000000000',
      close: '24.8684000000000000',
      volume: '4061588.6900000000000000',
      quoteVolume: 106692601.87539235,
      btcVolume: 1959.2264580328283,
      usdVolume: 106692601.87539235,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '24.8679000000000000',
      high: '26.6401000000000000',
      low: '24.3665000000000000',
      close: '25.5350000000000000',
      volume: '4189607.3400000000000000',
      quoteVolume: 106697254.9266262,
      btcVolume: 1689.745893902917,
      usdVolume: 87698677.2009626,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '25.5300000000000000',
      high: '27.4000000000000000',
      low: '25.3993000000000000',
      close: '27.3927000000000000',
      volume: '3356222.6100000000000000',
      quoteVolume: 88633668.99963559,
      btcVolume: 1599.8484710312273,
      usdVolume: 85435262.31795225,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '27.3988000000000000',
      high: '27.4982000000000000',
      low: '26.0030000000000000',
      close: '26.8766000000000000',
      volume: '2032627.2400000000000000',
      quoteVolume: 54412158.06017271,
      btcVolume: 986.9278333127505,
      usdVolume: 54406973.49173972,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '26.8765000000000000',
      high: '27.6624000000000000',
      low: '26.0889000000000000',
      close: '26.6588000000000000',
      volume: '2205293.2300000000000000',
      quoteVolume: 59418251.07729596,
      btcVolume: 1064.179617444524,
      usdVolume: 59406433.69876095,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '26.6576000000000000',
      high: '28.6203000000000000',
      low: '26.3886000000000000',
      close: '28.0357000000000000',
      volume: '3528308.7300000000000000',
      quoteVolume: 97966057.5120884,
      btcVolume: 1709.0159853740718,
      usdVolume: 97916578.44213544,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '28.0356000000000000',
      high: '28.5735000000000000',
      low: '27.4460000000000000',
      close: '27.8301000000000000',
      volume: '2521474.6200000000000000',
      quoteVolume: 70923243.6872522,
      btcVolume: 1213.622206853287,
      usdVolume: 70920571.39197218,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '27.8300000000000000',
      high: '29.4401000000000000',
      low: '26.1800000000000000',
      close: '29.4167000000000000',
      volume: '4353005.8100000000000000',
      quoteVolume: 120857861.76495478,
      btcVolume: 2051.9849029876646,
      usdVolume: 120365548.36152953,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '29.4168000000000000',
      high: '31.1250000000000000',
      low: '29.3143000000000000',
      close: '30.0684000000000000',
      volume: '5308598.1000000000000000',
      quoteVolume: 159884612.16588667,
      btcVolume: 2714.508183126401,
      usdVolume: 159764276.83964464,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '30.0808000000000000',
      high: '32.5555000000000000',
      low: '29.5463000000000000',
      close: '32.4864000000000000',
      volume: '4245194.7400000000000000',
      quoteVolume: 131458201.24949433,
      btcVolume: 2219.951784391892,
      usdVolume: 131448573.62730134,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '32.4867000000000000',
      high: '33.1707000000000000',
      low: '29.1256000000000000',
      close: '29.2930000000000000',
      volume: '5326728.5500000000000000',
      quoteVolume: 167961809.66658044,
      btcVolume: 1795.4395891216325,
      usdVolume: 105857363.34651382,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '29.2930000000000000',
      high: '31.4643000000000000',
      low: '28.6801000000000000',
      close: '30.7471000000000000',
      volume: '2738370.5800000000000000',
      quoteVolume: 83118846.51577824,
      btcVolume: 1249.3258134334203,
      usdVolume: 72051166.9085144,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '30.7473000000000000',
      high: '32.6399000000000000',
      low: '29.6179000000000000',
      close: '32.3791000000000000',
      volume: '4976271.3600000000000000',
      quoteVolume: 155152448.59764713,
      btcVolume: 1991.659027501243,
      usdVolume: 115877633.66876267,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '32.3791000000000000',
      high: '34.9000000000000000',
      low: '31.3074000000000000',
      close: '34.7250000000000000',
      volume: '7244775.1200000000000000',
      quoteVolume: 240433366.91596004,
      btcVolume: 3797.508343023463,
      usdVolume: 221680600.84789953,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '34.7295000000000000',
      high: '35.1200000000000000',
      low: '30.2286000000000000',
      close: '31.1090000000000000',
      volume: '7241883.5600000000000000',
      quoteVolume: 235343305.11428273,
      btcVolume: 3884.6148457101895,
      usdVolume: 221471220.7998364,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '31.1008000000000000',
      high: '32.8999000000000000',
      low: '30.9466000000000000',
      close: '32.7884000000000000',
      volume: '3371094.7200000000000000',
      quoteVolume: 108183402.49988818,
      btcVolume: 1171.9067624824738,
      usdVolume: 66948808.547933206,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '32.7870000000000000',
      high: '33.2876000000000000',
      low: '31.2348000000000000',
      close: '31.5893000000000000',
      volume: '2728512.6100000000000000',
      quoteVolume: 87716711.54413132,
      btcVolume: 1274.446364485902,
      usdVolume: 74249858.40623835,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '31.5961000000000000',
      high: '33.0000000000000000',
      low: '31.0000000000000000',
      close: '31.8944000000000000',
      volume: '3545699.4300000000000000',
      quoteVolume: 113928069.33940087,
      btcVolume: 1828.0827861744679,
      usdVolume: 109414242.01156527,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '31.8980000000000000',
      high: '34.4397000000000000',
      low: '31.7283000000000000',
      close: '33.8811000000000000',
      volume: '4949345.2000000000000000',
      quoteVolume: 164105171.51139703,
      btcVolume: 2677.3634365869484,
      usdVolume: 160031551.35507315,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '33.8811000000000000',
      high: '34.1468000000000000',
      low: '31.8601000000000000',
      close: '33.1080000000000000',
      volume: '3732041.9700000000000000',
      quoteVolume: 123252667.28419851,
      btcVolume: 1636.5603716794567,
      usdVolume: 98349366.92374945,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '33.1023000000000000',
      high: '35.8645000000000000',
      low: '32.3842000000000000',
      close: '35.5217000000000000',
      volume: '5624044.8000000000000000',
      quoteVolume: 192994936.86974585,
      btcVolume: 2998.5339331543933,
      usdVolume: 188012549.6792243,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '35.5416000000000000',
      high: '41.9901000000000000',
      low: '35.0000000000000000',
      close: '41.3641000000000000',
      volume: '13418401.1400000000000000',
      quoteVolume: 506888645.13665795,
      btcVolume: 6514.2283925472175,
      usdVolume: 412737241.69925255,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '41.3371000000000000',
      high: '44.3300000000000000',
      low: '39.6638000000000000',
      close: '42.5601000000000000',
      volume: '12581718.5700000000000000',
      quoteVolume: 528214652.25350446,
      btcVolume: 7445.637896817438,
      usdVolume: 467700360.8493485,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '42.5654000000000000',
      high: '43.2902000000000000',
      low: '38.5273000000000000',
      close: '41.9861000000000000',
      volume: '9648189.8700000000000000',
      quoteVolume: 396462710.2078966,
      btcVolume: 5702.375812210699,
      usdVolume: 350891882.7305064,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '41.9797000000000000',
      high: '44.1116000000000000',
      low: '39.8304000000000000',
      close: '39.9137000000000000',
      volume: '6757853.5600000000000000',
      quoteVolume: 284759254.2188944,
      btcVolume: 2680.560705167462,
      usdVolume: 164429323.88150203,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '39.9135000000000000',
      high: '40.3436000000000000',
      low: '29.0000000000000000',
      close: '39.0799000000000000',
      volume: '14640165.5300000000000000',
      quoteVolume: 530375955.0032846,
      btcVolume: 9097.744625141062,
      usdVolume: 507371033.8404291,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '39.0858000000000000',
      high: '41.9900000000000000',
      low: '34.8144000000000000',
      close: '35.7359000000000000',
      volume: '12978321.7600000000000000',
      quoteVolume: 497670097.2335755,
      btcVolume: 6174.098440246101,
      usdVolume: 347089895.7808836,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '35.7504000000000000',
      high: '39.3901000000000000',
      low: '33.1933000000000000',
      close: '38.9954000000000000',
      volume: '11956908.1000000000000000',
      quoteVolume: 437511825.9164905,
      btcVolume: 7196.936350920713,
      usdVolume: 398982378.77708787,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '38.9983000000000000',
      high: '39.4872000000000000',
      low: '35.8933000000000000',
      close: '36.2274000000000000',
      volume: '6732313.3700000000000000',
      quoteVolume: 253633014.1501259,
      btcVolume: 3719.7137046466846,
      usdVolume: 206621089.2032104,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '36.2364000000000000',
      high: '40.8200000000000000',
      low: '33.7859000000000000',
      close: '35.1306000000000000',
      volume: '11519039.9400000000000000',
      quoteVolume: 432182126.09576267,
      btcVolume: 2025.7054502568894,
      usdVolume: 107167666.04858308,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '35.1256000000000000',
      high: '35.9643000000000000',
      low: '30.2771000000000000',
      close: '35.2121000000000000',
      volume: '14134304.6300000000000000',
      quoteVolume: 465142368.4095787,
      btcVolume: 4763.5180098543515,
      usdVolume: 237164782.14820454,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '35.2121000000000000',
      high: '35.3033000000000000',
      low: '31.0000000000000000',
      close: '31.2443000000000000',
      volume: '6295773.8600000000000000',
      quoteVolume: 206376774.11149526,
      btcVolume: 3817.3264985910882,
      usdVolume: 190343281.2332728,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '31.2370000000000000',
      high: '33.4320000000000000',
      low: '29.8010000000000000',
      close: '31.6920000000000000',
      volume: '4274733.3210000000000000',
      quoteVolume: 135910873.76321998,
      btcVolume: 1405.9735040050004,
      usdVolume: 69698982.35040447,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '31.6900000000000000',
      high: '35.2750000000000000',
      low: '31.3630000000000000',
      close: '35.1300000000000000',
      volume: '5764275.4540000000000000',
      quoteVolume: 196917985.91491917,
      btcVolume: 1940.686409307913,
      usdVolume: 102715801.07328665,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '35.1120000000000000',
      high: '37.6650000000000000',
      low: '34.4830000000000000',
      close: '36.7880000000000000',
      volume: '5453945.1760000000000000',
      quoteVolume: 197434451.9676538,
      btcVolume: 3508.307389527573,
      usdVolume: 191567073.98651674,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '36.7860000000000000',
      high: '37.8080000000000000',
      low: '34.9010000000000000',
      close: '36.2330000000000000',
      volume: '5218990.7380000000000000',
      quoteVolume: 188499275.87359715,
      btcVolume: 3443.115782242929,
      usdVolume: 188499275.87359715,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '36.2530000000000000',
      high: '37.4500000000000000',
      low: '35.0000000000000000',
      close: '36.4670000000000000',
      volume: '4960983.1160000000000000',
      quoteVolume: 178985701.04202038,
      btcVolume: 3326.1200982145674,
      usdVolume: 178985701.04202038,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '36.4650000000000000',
      high: '38.7900000000000000',
      low: '35.8270000000000000',
      close: '38.1000000000000000',
      volume: '5036107.6020000000000000',
      quoteVolume: 189862115.18069306,
      btcVolume: 2169.133779042782,
      usdVolume: 119700732.92635407,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '38.1010000000000000',
      high: '41.2700000000000000',
      low: '37.4280000000000000',
      close: '41.2570000000000000',
      volume: '5223059.2340000000000000',
      quoteVolume: 205464629.83196887,
      btcVolume: 1552.158704895812,
      usdVolume: 89600807.00053962,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '41.2490000000000000',
      high: '41.4600000000000000',
      low: '38.6900000000000000',
      close: '39.4610000000000000',
      volume: '5198773.9980000000000000',
      quoteVolume: 206970460.92982715,
      btcVolume: 2174.314081160398,
      usdVolume: 123461594.93853049,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '39.4610000000000000',
      high: '42.7280000000000000',
      low: '39.1660000000000000',
      close: '42.0520000000000000',
      volume: '6010571.0640000000000000',
      quoteVolume: 246579333.6021091,
      btcVolume: 4045.734938153716,
      usdVolume: 233712903.15058511,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '42.0600000000000000',
      high: '48.5400000000000000',
      low: '38.6340000000000000',
      close: '45.5270000000000000',
      volume: '19237312.3900000000000000',
      quoteVolume: 845809208.6302068,
      btcVolume: 10465.55258596805,
      usdVolume: 575221269.0704849,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '45.5080000000000000',
      high: '51.2000000000000000',
      low: '44.8650000000000000',
      close: '49.5750000000000000',
      volume: '13527073.0750000000000000',
      quoteVolume: 657942604.5017196,
      btcVolume: 10857.379037079556,
      usdVolume: 601168078.6758198,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '49.5760000000000000',
      high: '49.9000000000000000',
      low: '45.3430000000000000',
      close: '47.1540000000000000',
      volume: '9482621.1830000000000000',
      quoteVolume: 450955107.1350383,
      btcVolume: 7582.913753017384,
      usdVolume: 430590815.2935991,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '47.1480000000000000',
      high: '51.9690000000000000',
      low: '45.6660000000000000',
      close: '49.1290000000000000',
      volume: '10577050.1670000000000000',
      quoteVolume: 515234804.58814085,
      btcVolume: 8494.174715359122,
      usdVolume: 484259627.57465845,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '49.1340000000000000',
      high: '50.2650000000000000',
      low: '46.5000000000000000',
      close: '48.5970000000000000',
      volume: '5883822.5840000000000000',
      quoteVolume: 283989339.17529905,
      btcVolume: 4686.917318376704,
      usdVolume: 273537936.3731609,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '48.6070000000000000',
      high: '52.4260000000000000',
      low: '45.8300000000000000',
      close: '52.2690000000000000',
      volume: '8588355.4620000000000000',
      quoteVolume: 426164154.7903979,
      btcVolume: 7030.02275298761,
      usdVolume: 405961264.1488087,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '52.2770000000000000',
      high: '53.0000000000000000',
      low: '41.1600000000000000',
      close: '46.3340000000000000',
      volume: '9923820.9180000000000000',
      quoteVolume: 486677346.87598133,
      btcVolume: 6276.730764781867,
      usdVolume: 357244261.1626152,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '46.3260000000000000',
      high: '49.0000000000000000',
      low: '44.8000000000000000',
      close: '48.8260000000000000',
      volume: '6709350.2410000000000000',
      quoteVolume: 312517087.9033863,
      btcVolume: 4159.683255202934,
      usdVolume: 232124988.1499912,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '48.8050000000000000',
      high: '49.8360000000000000',
      low: '40.9140000000000000',
      close: '41.0070000000000000',
      volume: '8272510.7590000000000000',
      quoteVolume: 383764473.964816,
      btcVolume: 6696.055930825579,
      usdVolume: 367665436.85888445,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '41.5410000000000000',
      high: '44.9580000000000000',
      low: '40.0660000000000000',
      close: '43.2870000000000000',
      volume: '9769701.1020000000000000',
      quoteVolume: 415729412.96724325,
      btcVolume: 7858.229520494778,
      usdVolume: 389116685.6106139,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '43.2730000000000000',
      high: '47.8750000000000000',
      low: '42.9350000000000000',
      close: '46.5670000000000000',
      volume: '6297019.0800000000000000',
      quoteVolume: 286301712.8153783,
      btcVolume: 5462.904943375931,
      usdVolume: 274286488.952303,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '46.5580000000000000',
      high: '47.8000000000000000',
      low: '40.9750000000000000',
      close: '41.4920000000000000',
      volume: '9851823.9350000000000000',
      quoteVolume: 432218709.2421017,
      btcVolume: 8544.101354516299,
      usdVolume: 415295386.7222744,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '41.5120000000000000',
      high: '44.9010000000000000',
      low: '37.9440000000000000',
      close: '40.6700000000000000',
      volume: '9745811.4610000000000000',
      quoteVolume: 402376827.20252913,
      btcVolume: 7873.992844695695,
      usdVolume: 370599721.498782,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '40.6620000000000000',
      high: '40.6740000000000000',
      low: '35.0680000000000000',
      close: '37.1770000000000000',
      volume: '10362229.7890000000000000',
      quoteVolume: 390099667.8940053,
      btcVolume: 8288.179682427983,
      usdVolume: 364544533.9681069,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '37.1760000000000000',
      high: '44.6900000000000000',
      low: '36.6550000000000000',
      close: '42.5660000000000000',
      volume: '11232354.3090000000000000',
      quoteVolume: 458226371.3004289,
      btcVolume: 9965.215932435603,
      usdVolume: 436152928.96179956,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '42.5550000000000000',
      high: '43.5000000000000000',
      low: '21.0000000000000000',
      close: '26.2270000000000000',
      volume: '20210187.3060000000000000',
      quoteVolume: 664059850.4303083,
      btcVolume: 13824.814824954283,
      usdVolume: 538586189.5562819,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '26.2270000000000000',
      high: '33.0500000000000000',
      low: '22.3500000000000000',
      close: '30.6060000000000000',
      volume: '20323256.5380000000000000',
      quoteVolume: 577592046.0905249,
      btcVolume: 10784.808827983394,
      usdVolume: 422558516.6124092,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '30.6330000000000000',
      high: '31.9450000000000000',
      low: '22.0190000000000000',
      close: '25.5460000000000000',
      volume: '13068081.0750000000000000',
      quoteVolume: 353993914.03694314,
      btcVolume: 6932.648993527497,
      usdVolume: 266425708.4751106,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '25.5480000000000000',
      high: '26.2000000000000000',
      low: '22.2250000000000000',
      close: '23.2960000000000000',
      volume: '13032278.1370000000000000',
      quoteVolume: 314829379.610612,
      btcVolume: 5695.951410606267,
      usdVolume: 211218954.28534862,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '23.2930000000000000',
      high: '24.3680000000000000',
      low: '15.0000000000000000',
      close: '19.8440000000000000',
      volume: '28816332.7880000000000000',
      quoteVolume: 543985798.5938362,
      btcVolume: 15126.42507829283,
      usdVolume: 517948800.9596924,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '19.8490000000000000',
      high: '26.4310000000000000',
      low: '19.6770000000000000',
      close: '26.4120000000000000',
      volume: '18217984.6480000000000000',
      quoteVolume: 425942553.28623766,
      btcVolume: 10844.812716329057,
      usdVolume: 401451530.6456157,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '26.4000000000000000',
      high: '27.6440000000000000',
      low: '23.6730000000000000',
      close: '27.5030000000000000',
      volume: '13594283.8180000000000000',
      quoteVolume: 349644756.5528623,
      btcVolume: 8716.278447151888,
      usdVolume: 331359167.24137896,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '27.5050000000000000',
      high: '34.2850000000000000',
      low: '26.8470000000000000',
      close: '34.0180000000000000',
      volume: '14862009.4600000000000000',
      quoteVolume: 456256699.2802189,
      btcVolume: 11201.464045901757,
      usdVolume: 439295034.0456097,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '34.0300000000000000',
      high: '35.3340000000000000',
      low: '30.0330000000000000',
      close: '31.7320000000000000',
      volume: '14928881.5110000000000000',
      quoteVolume: 489547108.0042761,
      btcVolume: 12329.95986455298,
      usdVolume: 477724155.1958763,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '31.7230000000000000',
      high: '32.1850000000000000',
      low: '26.8210000000000000',
      close: '27.8780000000000000',
      volume: '18297258.3920000000000000',
      quoteVolume: 533840739.506926,
      btcVolume: 13937.929959889794,
      usdVolume: 508564072.9063156,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '27.8720000000000000',
      high: '29.7340000000000000',
      low: '24.1200000000000000',
      close: '25.3650000000000000',
      volume: '11813482.7710000000000000',
      quoteVolume: 314260807.1404808,
      btcVolume: 8707.412591102062,
      usdVolume: 305391847.5639692,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '25.3770000000000000',
      high: '29.1680000000000000',
      low: '23.7000000000000000',
      close: '26.8180000000000000',
      volume: '11542374.4850000000000000',
      quoteVolume: 311943247.68354595,
      btcVolume: 8749.034315009025,
      usdVolume: 309860777.6783972,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '26.8130000000000000',
      high: '32.1590000000000000',
      low: '25.5550000000000000',
      close: '32.0980000000000000',
      volume: '11473684.1530000000000000',
      quoteVolume: 330074023.8033171,
      btcVolume: 9049.198752953676,
      usdVolume: 328513729.3170985,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '32.0980000000000000',
      high: '32.6500000000000000',
      low: '29.0350000000000000',
      close: '30.7190000000000000',
      volume: '7595288.2510000000000000',
      quoteVolume: 231579652.6416101,
      btcVolume: 6228.039389492023,
      usdVolume: 228605999.2276237,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '30.7290000000000000',
      high: '32.2180000000000000',
      low: '29.7000000000000000',
      close: '30.6700000000000000',
      volume: '5992710.5570000000000000',
      quoteVolume: 184925689.9538908,
      btcVolume: 4910.509911672765,
      usdVolume: 183636440.3796502,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '30.6690000000000000',
      high: '32.8560000000000000',
      low: '30.0000000000000000',
      close: '32.1980000000000000',
      volume: '6926586.1530000000000000',
      quoteVolume: 220195095.12678242,
      btcVolume: 5686.208264256343,
      usdVolume: 219529228.41781366,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '32.1990000000000000',
      high: '32.2670000000000000',
      low: '27.1720000000000000',
      close: '28.8260000000000000',
      volume: '10756129.4560000000000000',
      quoteVolume: 310574364.8097001,
      btcVolume: 8079.12819316762,
      usdVolume: 299137467.401279,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '28.8290000000000000',
      high: '30.1860000000000000',
      low: '26.0440000000000000',
      close: '27.1290000000000000',
      volume: '8021172.9850000000000000',
      quoteVolume: 224556115.0710708,
      btcVolume: 6072.610138454598,
      usdVolume: 220742793.7734021,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '27.1270000000000000',
      high: '28.1900000000000000',
      low: '26.8180000000000000',
      close: '27.5320000000000000',
      volume: '4071729.1110000000000000',
      quoteVolume: 111593094.49793832,
      btcVolume: 3094.66184741806,
      usdVolume: 111191327.61157948,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '27.5300000000000000',
      high: '28.6930000000000000',
      low: '24.2340000000000000',
      close: '24.5840000000000000',
      volume: '7593474.6300000000000000',
      quoteVolume: 203546009.9958056,
      btcVolume: 5664.404814150535,
      usdVolume: 201030726.639649,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '24.5740000000000000',
      high: '25.0980000000000000',
      low: '21.3340000000000000',
      close: '24.0760000000000000',
      volume: '12533550.4740000000000000',
      quoteVolume: 295383608.8363167,
      btcVolume: 8748.60414936731,
      usdVolume: 286178492.67321956,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '24.0790000000000000',
      high: '25.6260000000000000',
      low: '22.4410000000000000',
      close: '25.5850000000000000',
      volume: '10755352.9180000000000000',
      quoteVolume: 259836017.6864675,
      btcVolume: 7275.701651707724,
      usdVolume: 253653794.22880164,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '25.5890000000000000',
      high: '25.7800000000000000',
      low: '22.7210000000000000',
      close: '23.1140000000000000',
      volume: '8385905.0280000000000000',
      quoteVolume: 201718092.68922827,
      btcVolume: 5398.213856768574,
      usdVolume: 199614065.45911995,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '23.1170000000000000',
      high: '23.3740000000000000',
      low: '21.0000000000000000',
      close: '21.4780000000000000',
      volume: '7674813.3900000000000000',
      quoteVolume: 172255303.31648567,
      btcVolume: 4653.500277683774,
      usdVolume: 171879481.40592545,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '21.4750000000000000',
      high: '22.2870000000000000',
      low: '20.0000000000000000',
      close: '21.6700000000000000',
      volume: '8849284.6130000000000000',
      quoteVolume: 186854318.51986963,
      btcVolume: 5161.93685251144,
      usdVolume: 184384310.62285846,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '21.6710000000000000',
      high: '23.8860000000000000',
      low: '20.3960000000000000',
      close: '23.3120000000000000',
      volume: '8516193.5180000000000000',
      quoteVolume: 184993026.3649717,
      btcVolume: 5018.546755318045,
      usdVolume: 183990625.90337497,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '23.3140000000000000',
      high: '25.2800000000000000',
      low: '22.8370000000000000',
      close: '24.9730000000000000',
      volume: '8598594.0080000000000000',
      quoteVolume: 205633987.74170867,
      btcVolume: 5124.726546014837,
      usdVolume: 204447032.09525958,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '24.9670000000000000',
      high: '26.4580000000000000',
      low: '24.3760000000000000',
      close: '24.4820000000000000',
      volume: '7585322.2020000000000000',
      quoteVolume: 193173661.50221747,
      btcVolume: 4783.978518048711,
      usdVolume: 192339881.37543938,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '24.4800000000000000',
      high: '24.6400000000000000',
      low: '22.9180000000000000',
      close: '23.0440000000000000',
      volume: '7495769.8280000000000000',
      quoteVolume: 178312029.61373052,
      btcVolume: 4478.7932855389,
      usdVolume: 176264709.6132166,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '23.0420000000000000',
      high: '24.4450000000000000',
      low: '22.8100000000000000',
      close: '23.3960000000000000',
      volume: '5230276.4320000000000000',
      quoteVolume: 123641681.8274587,
      btcVolume: 3158.8471853964566,
      usdVolume: 121967618.36204572,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '23.4010000000000000',
      high: '23.4250000000000000',
      low: '20.5260000000000000',
      close: '21.2330000000000000',
      volume: '6979309.1900000000000000',
      quoteVolume: 152221963.2704614,
      btcVolume: 4077.4579812512793,
      usdVolume: 150001420.01759854,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '21.2360000000000000',
      high: '21.9500000000000000',
      low: '20.0000000000000000',
      close: '20.2460000000000000',
      volume: '5015956.9490000000000000',
      quoteVolume: 105884657.21403985,
      btcVolume: 2957.13775712415,
      usdVolume: 105574108.67201178,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '20.2630000000000000',
      high: '22.0710000000000000',
      low: '19.0770000000000000',
      close: '21.6460000000000000',
      volume: '6709724.1760000000000000',
      quoteVolume: 136982147.47085837,
      btcVolume: 3882.7672656123696,
      usdVolume: 135510283.7439176,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '21.6410000000000000',
      high: '21.8810000000000000',
      low: '16.9810000000000000',
      close: '17.3650000000000000',
      volume: '16151190.0200000000000000',
      quoteVolume: 304924588.43656045,
      btcVolume: 9030.919682300091,
      usdVolume: 296698047.37943095,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '17.3570000000000000',
      high: '18.7970000000000000',
      low: '15.0000000000000000',
      close: '16.8500000000000000',
      volume: '19773357.5780000000000000',
      quoteVolume: 331030753.695022,
      btcVolume: 10016.698622465823,
      usdVolume: 314934189.5838592,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '16.8400000000000000',
      high: '19.1990000000000000',
      low: '16.1490000000000000',
      close: '18.3950000000000000',
      volume: '10930842.9690000000000000',
      quoteVolume: 198104336.2413875,
      btcVolume: 5677.69271694415,
      usdVolume: 191351833.36313608,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '18.3930000000000000',
      high: '19.5250000000000000',
      low: '17.3520000000000000',
      close: '19.0600000000000000',
      volume: '6708926.7880000000000000',
      quoteVolume: 124897019.4366688,
      btcVolume: 3676.6447137809605,
      usdVolume: 124160107.18648963,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '19.0600000000000000',
      high: '19.5000000000000000',
      low: '16.8130000000000000',
      close: '16.8700000000000000',
      volume: '10048473.2290000000000000',
      quoteVolume: 180145249.5753493,
      btcVolume: 5424.613837041398,
      usdVolume: 179815215.34201533,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '16.8720000000000000',
      high: '17.4950000000000000',
      low: '15.9200000000000000',
      close: '16.9520000000000000',
      volume: '8343823.1670000000000000',
      quoteVolume: 138685945.58320868,
      btcVolume: 4317.21304493639,
      usdVolume: 135592125.3028405,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '16.9510000000000000',
      high: '18.4120000000000000',
      low: '16.5910000000000000',
      close: '18.4060000000000000',
      volume: '6649981.0660000000000000',
      quoteVolume: 114152516.37204297,
      btcVolume: 3403.722727537482,
      usdVolume: 112739292.89915286,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '18.4070000000000000',
      high: '19.4340000000000000',
      low: '17.9020000000000000',
      close: '18.8300000000000000',
      volume: '7282776.6190000000000000',
      quoteVolume: 135071888.30002892,
      btcVolume: 3906.1907284798945,
      usdVolume: 134704190.30824476,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '18.8300000000000000',
      high: '20.5460000000000000',
      low: '18.7520000000000000',
      close: '19.5350000000000000',
      volume: '6574919.5160000000000000',
      quoteVolume: 129862268.00902891,
      btcVolume: 3645.3920298803437,
      usdVolume: 129659684.4622509,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '19.5310000000000000',
      high: '19.7730000000000000',
      low: '18.1490000000000000',
      close: '19.5160000000000000',
      volume: '6554785.3410000000000000',
      quoteVolume: 124177607.62473075,
      btcVolume: 3561.7088830145212,
      usdVolume: 124094990.07156971,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '19.5170000000000000',
      high: '19.5330000000000000',
      low: '17.6030000000000000',
      close: '18.1860000000000000',
      volume: '6074285.8190000000000000',
      quoteVolume: 110566739.74832156,
      btcVolume: 3272.3128181398106,
      usdVolume: 110064065.08651572,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '18.1840000000000000',
      high: '18.5230000000000000',
      low: '17.2560000000000000',
      close: '18.2740000000000000',
      volume: '5394371.4130000000000000',
      quoteVolume: 95752765.26831836,
      btcVolume: 2873.9523665643746,
      usdVolume: 95533297.25328133,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '18.2770000000000000',
      high: '18.8940000000000000',
      low: '17.8640000000000000',
      close: '18.5430000000000000',
      volume: '3247443.1790000000000000',
      quoteVolume: 59971889.21668191,
      btcVolume: 1743.3323281938071,
      usdVolume: 59881835.060770914,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '18.5430000000000000',
      high: '19.9250000000000000',
      low: '18.0020000000000000',
      close: '19.2420000000000000',
      volume: '4486291.0750000000000000',
      quoteVolume: 85193944.29341578,
      btcVolume: 2409.86380003794,
      usdVolume: 85114681.7393157,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '19.2380000000000000',
      high: '19.2420000000000000',
      low: '17.7670000000000000',
      close: '18.3620000000000000',
      volume: '5244315.0980000000000000',
      quoteVolume: 96893912.50225921,
      btcVolume: 2815.4797708645337,
      usdVolume: 95800634.92579712,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '18.3640000000000000',
      high: '20.2710000000000000',
      low: '18.3170000000000000',
      close: '20.0730000000000000',
      volume: '7942424.0840000000000000',
      quoteVolume: 155645652.46806923,
      btcVolume: 4515.283695510405,
      usdVolume: 154167558.60065004,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '20.0730000000000000',
      high: '21.2740000000000000',
      low: '19.6200000000000000',
      close: '19.7880000000000000',
      volume: '7220562.4210000000000000',
      quoteVolume: 148395155.42902967,
      btcVolume: 4299.919566919362,
      usdVolume: 148395155.42902967,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '19.7860000000000000',
      high: '19.8730000000000000',
      low: '18.1380000000000000',
      close: '18.3530000000000000',
      volume: '7787413.9360000000000000',
      quoteVolume: 146836983.45400667,
      btcVolume: 4442.217674403576,
      usdVolume: 145991527.75807318,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '18.3650000000000000',
      high: '18.8770000000000000',
      low: '17.6170000000000000',
      close: '18.6560000000000000',
      volume: '5272793.7130000000000000',
      quoteVolume: 96043496.92060412,
      btcVolume: 2903.464250314309,
      usdVolume: 96030029.07034913,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '18.6560000000000000',
      high: '18.9880000000000000',
      low: '17.7340000000000000',
      close: '18.2040000000000000',
      volume: '4078447.0630000000000000',
      quoteVolume: 74240182.6654956,
      btcVolume: 2201.704167208646,
      usdVolume: 74224861.40051863,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '18.2090000000000000',
      high: '18.5810000000000000',
      low: '17.8610000000000000',
      close: '18.3830000000000000',
      volume: '2730333.2010000000000000',
      quoteVolume: 49800226.79839872,
      btcVolume: 1472.494351442755,
      usdVolume: 49793240.52506473,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '18.3820000000000000',
      high: '18.6090000000000000',
      low: '17.0000000000000000',
      close: '17.5180000000000000',
      volume: '3783915.1710000000000000',
      quoteVolume: 67410348.47561233,
      btcVolume: 2009.4146255562289,
      usdVolume: 67298959.85275029,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '17.5140000000000000',
      high: '17.7660000000000000',
      low: '16.6630000000000000',
      close: '16.8700000000000000',
      volume: '3488186.4250000000000000',
      quoteVolume: 60230003.646684654,
      btcVolume: 1836.6843254841956,
      usdVolume: 60221186.657093674,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '16.8730000000000000',
      high: '17.4190000000000000',
      low: '16.0140000000000000',
      close: '16.9360000000000000',
      volume: '5441562.0200000000000000',
      quoteVolume: 90914973.0594299,
      btcVolume: 2788.0780014295497,
      usdVolume: 90500474.54788788,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '16.9360000000000000',
      high: '17.2380000000000000',
      low: '15.5170000000000000',
      close: '15.7640000000000000',
      volume: '6198386.0130000000000000',
      quoteVolume: 99985406.05859742,
      btcVolume: 3118.566762095075,
      usdVolume: 99653864.88515042,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '15.7640000000000000',
      high: '16.3300000000000000',
      low: '15.1250000000000000',
      close: '15.2340000000000000',
      volume: '4106915.0750000000000000',
      quoteVolume: 64218657.385411024,
      btcVolume: 2026.355929873666,
      usdVolume: 64218546.10895903,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '15.2340000000000000',
      high: '15.6780000000000000',
      low: '15.0200000000000000',
      close: '15.3520000000000000',
      volume: '4957872.3330000000000000',
      quoteVolume: 76011680.64757319,
      btcVolume: 2401.8918472972196,
      usdVolume: 75811199.49437419,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '15.3520000000000000',
      high: '16.0780000000000000',
      low: '15.1550000000000000',
      close: '15.4700000000000000',
      volume: '3946657.1550000000000000',
      quoteVolume: 61781931.20700029,
      btcVolume: 1945.5860240070042,
      usdVolume: 61763430.72707828,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '15.4750000000000000',
      high: '15.6410000000000000',
      low: '14.4040000000000000',
      close: '14.5280000000000000',
      volume: '5828975.8470000000000000',
      quoteVolume: 86676083.7316871,
      btcVolume: 2787.3535740700127,
      usdVolume: 86630306.81259608,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '14.5290000000000000',
      high: '14.7830000000000000',
      low: '13.3840000000000000',
      close: '13.7420000000000000',
      volume: '7649757.9780000000000000',
      quoteVolume: 105449843.69424452,
      btcVolume: 3529.459900354445,
      usdVolume: 105367660.82367453,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '13.7440000000000000',
      high: '15.7970000000000000',
      low: '13.4600000000000000',
      close: '15.2970000000000000',
      volume: '8493449.3690000000000000',
      quoteVolume: 125644680.88342938,
      btcVolume: 3997.9525201581914,
      usdVolume: 124994012.93451238,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '15.3000000000000000',
      high: '16.1760000000000000',
      low: '14.9610000000000000',
      close: '16.0710000000000000',
      volume: '5857032.9790000000000000',
      quoteVolume: 90738306.1379759,
      btcVolume: 2820.8161649730764,
      usdVolume: 90645833.05113186,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '16.0730000000000000',
      high: '16.4860000000000000',
      low: '15.2300000000000000',
      close: '15.5970000000000000',
      volume: '4154495.5190000000000000',
      quoteVolume: 66029359.02171643,
      btcVolume: 2038.14247445674,
      usdVolume: 66029359.02171643,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
  ada: {
    id: 'ada',
    name: 'ADA',
    symbol: 'ADA',
    image: 'assets/images/coin-logo/ADA.png',
    activity24h: 1241256,
    availableAmount: 1328.232323,
    usdxConversion: 1,
    allTimeValue: 51512390,
    allTimeAsset: 765421,
    candleData: [
      {
      open: '9.7285000000000000',
      high: '9.7638000000000000',
      low: '9.6022000000000000',
      close: '9.7207000000000000',
      volume: '838913.9200000000000000',
      quoteVolume: 8136588.44204,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-28T00:00:00.000Z'
      },
      {
      open: '9.7207000000000000',
      high: '9.7317000000000000',
      low: '9.3748000000000000',
      close: '9.4450000000000000',
      volume: '788866.5700000000000000',
      quoteVolume: 7525106.643176,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-29T00:00:00.000Z'
      },
      {
      open: '9.4516000000000000',
      high: '9.5386000000000000',
      low: '9.4003000000000000',
      close: '9.4521000000000000',
      volume: '719748.6600000000000000',
      quoteVolume: 6803212.351281,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-30T00:00:00.000Z'
      },
      {
      open: '9.4513000000000000',
      high: '9.5546000000000000',
      low: '9.2811000000000000',
      close: '9.4807000000000000',
      volume: '707061.5900000000000000',
      quoteVolume: 6671089.908958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-31T00:00:00.000Z'
      },
      {
      open: '9.4837000000000000',
      high: '9.6600000000000000',
      low: '9.4303000000000000',
      close: '9.6192000000000000',
      volume: '791111.4800000000000000',
      quoteVolume: 7528202.69593,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-01T00:00:00.000Z'
      },
      {
      open: '9.6106000000000000',
      high: '9.7192000000000000',
      low: '9.5467000000000000',
      close: '9.6421000000000000',
      volume: '850927.5000000000000000',
      quoteVolume: 8194490.466364,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-02T00:00:00.000Z'
      },
      {
      open: '9.6607000000000000',
      high: '9.7440000000000000',
      low: '9.4888000000000000',
      close: '9.5889000000000000',
      volume: '812589.6100000000000000',
      quoteVolume: 7781309.311534,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-03T00:00:00.000Z'
      },
      {
      open: '9.5880000000000000',
      high: '9.8974000000000000',
      low: '9.5000000000000000',
      close: '9.8190000000000000',
      volume: '934579.3000000000000000',
      quoteVolume: 9056755.741708,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-04T00:00:00.000Z'
      },
      {
      open: '9.8084000000000000',
      high: '9.8294000000000000',
      low: '9.6603000000000000',
      close: '9.7333000000000000',
      volume: '836650.9100000000000000',
      quoteVolume: 8131267.116155,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-05T00:00:00.000Z'
      },
      {
      open: '9.7509000000000000',
      high: '9.8891000000000000',
      low: '9.6703000000000000',
      close: '9.8810000000000000',
      volume: '887223.8200000000000000',
      quoteVolume: 8677224.600936,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-06T00:00:00.000Z'
      },
      {
      open: '9.8718000000000000',
      high: '9.9776000000000000',
      low: '9.8100000000000000',
      close: '9.8970000000000000',
      volume: '865071.5600000000000000',
      quoteVolume: 8556329.625219,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-07T00:00:00.000Z'
      },
      {
      open: '9.8970000000000000',
      high: '9.9268000000000000',
      low: '9.6503000000000000',
      close: '9.6860000000000000',
      volume: '798983.4400000000000000',
      quoteVolume: 7826431.10844,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-08T00:00:00.000Z'
      },
      {
      open: '9.6868000000000000',
      high: '9.7497000000000000',
      low: '9.5169000000000000',
      close: '9.6141000000000000',
      volume: '735975.4500000000000000',
      quoteVolume: 7091790.464076,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-09T00:00:00.000Z'
      },
      {
      open: '9.6132000000000000',
      high: '9.6896000000000000',
      low: '9.5672000000000000',
      close: '9.5898000000000000',
      volume: '782308.0400000000000000',
      quoteVolume: 7533998.386684,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-10T00:00:00.000Z'
      },
      {
      open: '9.5898000000000000',
      high: '9.6210000000000000',
      low: '9.3500000000000000',
      close: '9.5611000000000000',
      volume: '755404.2100000000000000',
      quoteVolume: 7180788.710182,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-11T00:00:00.000Z'
      },
      {
      open: '9.5586000000000000',
      high: '9.5891000000000000',
      low: '9.3310000000000000',
      close: '9.4862000000000000',
      volume: '723363.6300000000000000',
      quoteVolume: 6843645.615956,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-12T00:00:00.000Z'
      },
      {
      open: '9.4862000000000000',
      high: '9.5891000000000000',
      low: '9.3602000000000000',
      close: '9.3936000000000000',
      volume: '658735.4600000000000000',
      quoteVolume: 6227393.415766,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-13T00:00:00.000Z'
      },
      {
      open: '9.3870000000000000',
      high: '9.4453000000000000',
      low: '8.3359000000000000',
      close: '8.6811000000000000',
      volume: '672611.0600000000000000',
      quoteVolume: 5964449.985309,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-14T00:00:00.000Z'
      },
      {
      open: '8.6896000000000000',
      high: '8.7342000000000000',
      low: '7.8000000000000000',
      close: '8.3793000000000000',
      volume: '822045.1000000000000000',
      quoteVolume: 6802948.925313,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-15T00:00:00.000Z'
      },
      {
      open: '8.3793000000000000',
      high: '8.4049000000000000',
      low: '7.8000000000000000',
      close: '8.0100000000000000',
      volume: '901842.2500000000000000',
      quoteVolume: 7292373.45122,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-16T00:00:00.000Z'
      },
      {
      open: '8.0097000000000000',
      high: '8.0192000000000000',
      low: '7.6846000000000000',
      close: '7.8494000000000000',
      volume: '752867.4700000000000000',
      quoteVolume: 5865731.485472,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-17T00:00:00.000Z'
      },
      {
      open: '7.8493000000000000',
      high: '8.1660000000000000',
      low: '7.8301000000000000',
      close: '8.0832000000000000',
      volume: '604839.7500000000000000',
      quoteVolume: 4838054.114957,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-18T00:00:00.000Z'
      },
      {
      open: '8.0833000000000000',
      high: '8.0918000000000000',
      low: '6.6453000000000000',
      close: '6.6844000000000000',
      volume: '1052222.0800000000000000',
      quoteVolume: 7512579.853245,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-19T00:00:00.000Z'
      },
      {
      open: '6.7040000000000000',
      high: '6.9050000000000000',
      low: '5.6120000000000000',
      close: '5.9200000000000000',
      volume: '1624846.6400000000000000',
      quoteVolume: 9912905.068409,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-20T00:00:00.000Z'
      },
      {
      open: '5.9419000000000000',
      high: '6.3230000000000000',
      low: '5.6502000000000000',
      close: '6.2894000000000000',
      volume: '842731.4100000000000000',
      quoteVolume: 5084237.945527,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-21T00:00:00.000Z'
      },
      {
      open: '6.2896000000000000',
      high: '6.3727000000000000',
      low: '5.7172000000000000',
      close: '5.7700000000000000',
      volume: '630372.0700000000000000',
      quoteVolume: 3840547.082434,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-22T00:00:00.000Z'
      },
      {
      open: '5.7755000000000000',
      high: '5.8396000000000000',
      low: '5.4500000000000000',
      close: '5.6611000000000000',
      volume: '598556.9000000000000000',
      quoteVolume: 3357369.283447,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-23T00:00:00.000Z'
      },
      {
      open: '5.6525000000000000',
      high: '5.8055000000000000',
      low: '4.7632000000000000',
      close: '5.1930000000000000',
      volume: '917700.2100000000000000',
      quoteVolume: 4844925.914768,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-24T00:00:00.000Z'
      },
      {
      open: '5.1990000000000000',
      high: '5.3800000000000000',
      low: '4.5320000000000000',
      close: '5.2873000000000000',
      volume: '1003892.6700000000000000',
      quoteVolume: 4998073.759825,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-25T00:00:00.000Z'
      },
      {
      open: '5.2800000000000000',
      high: '5.4000000000000000',
      low: '4.7568000000000000',
      close: '5.0631000000000000',
      volume: '852519.3700000000000000',
      quoteVolume: 4333954.593448,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-26T00:00:00.000Z'
      },
      {
      open: '5.0630000000000000',
      high: '5.0630000000000000',
      low: '4.7045000000000000',
      close: '4.9840000000000000',
      volume: '701759.1100000000000000',
      quoteVolume: 3434036.230959,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-27T00:00:00.000Z'
      },
      {
      open: '4.9840000000000000',
      high: '5.4603000000000000',
      low: '4.9840000000000000',
      close: '5.3499000000000000',
      volume: '1144816.8000000000000000',
      quoteVolume: 6061490.260849,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-28T00:00:00.000Z'
      },
      {
      open: '5.3498000000000000',
      high: '5.6344000000000000',
      low: '5.1243000000000000',
      close: '5.3805000000000000',
      volume: '1018322.9900000000000000',
      quoteVolume: 5461031.121114,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-29T00:00:00.000Z'
      },
      {
      open: '5.3902000000000000',
      high: '5.4480000000000000',
      low: '4.9534000000000000',
      close: '5.1150000000000000',
      volume: '709284.0200000000000000',
      quoteVolume: 3662427.823626,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-30T00:00:00.000Z'
      },
      {
      open: '5.1122000000000000',
      high: '5.4006000000000000',
      low: '5.0072000000000000',
      close: '5.2899000000000000',
      volume: '925388.4200000000000000',
      quoteVolume: 4874052.81019,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-01T00:00:00.000Z'
      },
      {
      open: '5.2787000000000000',
      high: '5.4357000000000000',
      low: '5.2050000000000000',
      close: '5.2888000000000000',
      volume: '853331.6100000000000000',
      quoteVolume: 4534872.243938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-02T00:00:00.000Z'
      },
      {
      open: '5.2831000000000000',
      high: '5.2956000000000000',
      low: '5.0100000000000000',
      close: '5.1266000000000000',
      volume: '673983.3800000000000000',
      quoteVolume: 3451158.980001,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-03T00:00:00.000Z'
      },
      {
      open: '5.1294000000000000',
      high: '6.3649000000000000',
      low: '5.0856000000000000',
      close: '5.9020000000000000',
      volume: '2238490.1600000000000000',
      quoteVolume: 12882498.1221741,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-04T00:00:00.000Z'
      },
      {
      open: '5.9020000000000000',
      high: '6.6200000000000000',
      low: '5.7225000000000000',
      close: '6.0727000000000000',
      volume: '4208247.7800000000000000',
      quoteVolume: 25915024.891642,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-05T00:00:00.000Z'
      },
      {
      open: '6.0689000000000000',
      high: '6.1090000000000000',
      low: '4.8500000000000000',
      close: '4.9023000000000000',
      volume: '3033034.7700000000000000',
      quoteVolume: 16605405.771891,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-06T00:00:00.000Z'
      },
      {
      open: '4.8977000000000000',
      high: '4.9997000000000000',
      low: '4.1200000000000000',
      close: '4.5160000000000000',
      volume: '2250208.5300000000000000',
      quoteVolume: 10282558.541603,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-07T00:00:00.000Z'
      },
      {
      open: '4.5118000000000000',
      high: '4.8009000000000000',
      low: '4.3001000000000000',
      close: '4.6361000000000000',
      volume: '1260790.7000000000000000',
      quoteVolume: 5751093.241387,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-08T00:00:00.000Z'
      },
      {
      open: '4.6280000000000000',
      high: '5.0003000000000000',
      low: '4.5167000000000000',
      close: '4.7693000000000000',
      volume: '1505537.6400000000000000',
      quoteVolume: 7177505.612086,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-09T00:00:00.000Z'
      },
      {
      open: '4.7693000000000000',
      high: '4.9398000000000000',
      low: '4.4698000000000000',
      close: '4.6276000000000000',
      volume: '928495.8500000000000000',
      quoteVolume: 4361795.739574,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-10T00:00:00.000Z'
      },
      {
      open: '4.6297000000000000',
      high: '4.9295000000000000',
      low: '4.5000000000000000',
      close: '4.8973000000000000',
      volume: '1232098.4300000000000000',
      quoteVolume: 5766708.758249,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-11T00:00:00.000Z'
      },
      {
      open: '4.8936000000000000',
      high: '5.1500000000000000',
      low: '4.7390000000000000',
      close: '4.9815000000000000',
      volume: '1196552.2900000000000000',
      quoteVolume: 5925304.975092,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-12T00:00:00.000Z'
      },
      {
      open: '4.9820000000000000',
      high: '4.9933000000000000',
      low: '4.5563000000000000',
      close: '4.6384000000000000',
      volume: '1003196.1800000000000000',
      quoteVolume: 4804678.850471,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-13T00:00:00.000Z'
      },
      {
      open: '4.6399000000000000',
      high: '4.7500000000000000',
      low: '4.4300000000000000',
      close: '4.5211000000000000',
      volume: '946936.1900000000000000',
      quoteVolume: 4370044.405834,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-14T00:00:00.000Z'
      },
      {
      open: '4.5148000000000000',
      high: '4.5990000000000000',
      low: '4.4436000000000000',
      close: '4.5531000000000000',
      volume: '969833.4400000000000000',
      quoteVolume: 4387901.297965,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-15T00:00:00.000Z'
      },
      {
      open: '4.5479000000000000',
      high: '4.7064000000000000',
      low: '4.5089000000000000',
      close: '4.6160000000000000',
      volume: '1069563.7700000000000000',
      quoteVolume: 4963613.541897,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-16T00:00:00.000Z'
      },
      {
      open: '4.6193000000000000',
      high: '5.1271000000000000',
      low: '4.6010000000000000',
      close: '5.0650000000000000',
      volume: '1507154.3700000000000000',
      quoteVolume: 7344673.590916,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-17T00:00:00.000Z'
      },
      {
      open: '5.0650000000000000',
      high: '5.5362000000000000',
      low: '4.9000000000000000',
      close: '5.5308000000000000',
      volume: '1906857.2400000000000000',
      quoteVolume: 9864772.820849,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-18T00:00:00.000Z'
      },
      {
      open: '5.5349000000000000',
      high: '5.8331000000000000',
      low: '5.2847000000000000',
      close: '5.3633000000000000',
      volume: '1805892.7600000000000000',
      quoteVolume: 9964231.850427,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-19T00:00:00.000Z'
      },
      {
      open: '5.3627000000000000',
      high: '5.7900000000000000',
      low: '5.2369000000000000',
      close: '5.6766000000000000',
      volume: '2160302.6100000000000000',
      quoteVolume: 12060645.667534,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-20T00:00:00.000Z'
      },
      {
      open: '5.6761000000000000',
      high: '5.7897000000000000',
      low: '5.3166000000000000',
      close: '5.4766000000000000',
      volume: '1592262.7400000000000000',
      quoteVolume: 8888728.773336,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-21T00:00:00.000Z'
      },
      {
      open: '5.4766000000000000',
      high: '5.8802000000000000',
      low: '5.3950000000000000',
      close: '5.8398000000000000',
      volume: '1775214.5900000000000000',
      quoteVolume: 10028050.954402,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-22T00:00:00.000Z'
      },
      {
      open: '5.8399000000000000',
      high: '6.1318000000000000',
      low: '5.7589000000000000',
      close: '5.9590000000000000',
      volume: '1878151.1300000000000000',
      quoteVolume: 11203811.278916,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-23T00:00:00.000Z'
      },
      {
      open: '5.9588000000000000',
      high: '6.4735000000000000',
      low: '5.9300000000000000',
      close: '6.0979000000000000',
      volume: '2058385.4600000000000000',
      quoteVolume: 12800612.446236,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-24T00:00:00.000Z'
      },
      {
      open: '6.0975000000000000',
      high: '6.0994000000000000',
      low: '5.3127000000000000',
      close: '5.4500000000000000',
      volume: '1443517.2200000000000000',
      quoteVolume: 7946748.599596,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-25T00:00:00.000Z'
      },
      {
      open: '5.4432000000000000',
      high: '5.6840000000000000',
      low: '5.3700000000000000',
      close: '5.6029000000000000',
      volume: '1351072.4800000000000000',
      quoteVolume: 7510239.890038,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-26T00:00:00.000Z'
      },
      {
      open: '5.6062000000000000',
      high: '5.6559000000000000',
      low: '5.1000000000000000',
      close: '5.1791000000000000',
      volume: '1148939.3700000000000000',
      quoteVolume: 6200387.822491,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-27T00:00:00.000Z'
      },
      {
      open: '5.1818000000000000',
      high: '5.8995000000000000',
      low: '5.1003000000000000',
      close: '5.8811000000000000',
      volume: '1505031.2400000000000000',
      quoteVolume: 8318661.357344,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-28T00:00:00.000Z'
      },
      {
      open: '5.8803000000000000',
      high: '6.0000000000000000',
      low: '5.6100000000000000',
      close: '5.6600000000000000',
      volume: '1232874.4500000000000000',
      quoteVolume: 7206376.833483,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-29T00:00:00.000Z'
      },
      {
      open: '5.6700000000000000',
      high: '5.9186000000000000',
      low: '5.5580000000000000',
      close: '5.8939000000000000',
      volume: '1394241.0900000000000000',
      quoteVolume: 8065674.631955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-30T00:00:00.000Z'
      },
      {
      open: '5.9000000000000000',
      high: '6.2433000000000000',
      low: '5.8304000000000000',
      close: '6.1111000000000000',
      volume: '2388899.2300000000000000',
      quoteVolume: 14438039.454585,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-31T00:00:00.000Z'
      },
      {
      open: '6.1139000000000000',
      high: '6.1228000000000000',
      low: '5.7735000000000000',
      close: '5.9910000000000000',
      volume: '1412914.1800000000000000',
      quoteVolume: 8379611.862422,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-01T00:00:00.000Z'
      },
      {
      open: '5.9910000000000000',
      high: '6.0939000000000000',
      low: '5.8371000000000000',
      close: '6.0510000000000000',
      volume: '2130127.0000000000000000',
      quoteVolume: 12667352.240985,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-02T00:00:00.000Z'
      },
      {
      open: '6.0445000000000000',
      high: '6.0620000000000000',
      low: '5.7739000000000000',
      close: '5.7979000000000000',
      volume: '1524726.3700000000000000',
      quoteVolume: 8984033.715786,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-03T00:00:00.000Z'
      },
      {
      open: '5.7947000000000000',
      high: '5.9900000000000000',
      low: '5.7402000000000000',
      close: '5.9692000000000000',
      volume: '1657441.7400000000000000',
      quoteVolume: 9772290.645118,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-04T00:00:00.000Z'
      },
      {
      open: '5.9688000000000000',
      high: '6.1043000000000000',
      low: '5.9188000000000000',
      close: '5.9625000000000000',
      volume: '1636284.9900000000000000',
      quoteVolume: 9826475.877965,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-05T00:00:00.000Z'
      },
      {
      open: '5.9626000000000000',
      high: '6.3888000000000000',
      low: '5.9250000000000000',
      close: '6.2688000000000000',
      volume: '2511301.2700000000000000',
      quoteVolume: 15355552.564777,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-06T00:00:00.000Z'
      },
      {
      open: '6.2681000000000000',
      high: '6.3094000000000000',
      low: '6.0061000000000000',
      close: '6.2009000000000000',
      volume: '1683858.6800000000000000',
      quoteVolume: 10428760.417152,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-07T00:00:00.000Z'
      },
      {
      open: '6.1979000000000000',
      high: '6.7984000000000000',
      low: '6.1381000000000000',
      close: '6.5128000000000000',
      volume: '3946905.6100000000000000',
      quoteVolume: 25730037.887084,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-08T00:00:00.000Z'
      },
      {
      open: '6.5197000000000000',
      high: '6.8540000000000000',
      low: '6.3386000000000000',
      close: '6.5511000000000000',
      volume: '4167653.6900000000000000',
      quoteVolume: 27575766.440919,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-09T00:00:00.000Z'
      },
      {
      open: '6.5511000000000000',
      high: '6.5764000000000000',
      low: '5.6500000000000000',
      close: '5.8449000000000000',
      volume: '3348790.0300000000000000',
      quoteVolume: 20367712.107099,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-10T00:00:00.000Z'
      },
      {
      open: '5.8440000000000000',
      high: '6.1198000000000000',
      low: '5.6853000000000000',
      close: '5.9595000000000000',
      volume: '2559930.8100000000000000',
      quoteVolume: 15157433.652937,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-11T00:00:00.000Z'
      },
      {
      open: '5.9552000000000000',
      high: '5.9657000000000000',
      low: '5.8000000000000000',
      close: '5.8594000000000000',
      volume: '1743319.3100000000000000',
      quoteVolume: 10235285.058373,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-12T00:00:00.000Z'
      },
      {
      open: '5.8629000000000000',
      high: '5.8884000000000000',
      low: '5.3733000000000000',
      close: '5.4688000000000000',
      volume: '1420692.0500000000000000',
      quoteVolume: 8108312.422364,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-13T00:00:00.000Z'
      },
      {
      open: '5.4688000000000000',
      high: '6.0620000000000000',
      low: '5.4586000000000000',
      close: '6.0583000000000000',
      volume: '2330172.4800000000000000',
      quoteVolume: 13521000.249423,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-14T00:00:00.000Z'
      },
      {
      open: '6.0565000000000000',
      high: '6.0649000000000000',
      low: '5.6601000000000000',
      close: '5.8111000000000000',
      volume: '1366715.2500000000000000',
      quoteVolume: 8031293.154063,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-15T00:00:00.000Z'
      },
      {
      open: '5.8007000000000000',
      high: '6.1817000000000000',
      low: '5.7741000000000000',
      close: '6.0911000000000000',
      volume: '2164639.9000000000000000',
      quoteVolume: 12960434.729842,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '6.0941000000000000',
      high: '6.3300000000000000',
      low: '5.9604000000000000',
      close: '6.3266000000000000',
      volume: '2312287.7400000000000000',
      quoteVolume: 14193314.652338,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '6.3152000000000000',
      high: '6.5879000000000000',
      low: '6.2132000000000000',
      close: '6.4867000000000000',
      volume: '2495447.2200000000000000',
      quoteVolume: 15985247.059954,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '6.4887000000000000',
      high: '6.6400000000000000',
      low: '6.2435000000000000',
      close: '6.5199000000000000',
      volume: '2269437.9800000000000000',
      quoteVolume: 14687340.306653,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '6.5221000000000000',
      high: '6.6295000000000000',
      low: '6.2401000000000000',
      close: '6.4008000000000000',
      volume: '1778376.9300000000000000',
      quoteVolume: 11481466.262429,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '6.4069000000000000',
      high: '6.5150000000000000',
      low: '6.2788000000000000',
      close: '6.4724000000000000',
      volume: '1851048.4900000000000000',
      quoteVolume: 11908754.858074,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '6.4681000000000000',
      high: '6.5088000000000000',
      low: '6.2500000000000000',
      close: '6.4736000000000000',
      volume: '1854629.7500000000000000',
      quoteVolume: 11938849.639233,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '6.4780000000000000',
      high: '6.5300000000000000',
      low: '6.3499000000000000',
      close: '6.4969000000000000',
      volume: '1928704.7200000000000000',
      quoteVolume: 12474449.059306,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '6.4963000000000000',
      high: '6.4963000000000000',
      low: '6.3915000000000000',
      close: '6.4588000000000000',
      volume: '1514319.9100000000000000',
      quoteVolume: 9750589.965848,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '6.4555000000000000',
      high: '6.7502000000000000',
      low: '6.4408000000000000',
      close: '6.6899000000000000',
      volume: '2583153.3800000000000000',
      quoteVolume: 17021668.779197,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '6.6890000000000000',
      high: '6.9786000000000000',
      low: '6.6196000000000000',
      close: '6.9463000000000000',
      volume: '2734629.1100000000000000',
      quoteVolume: 18598393.817596,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '6.9453000000000000',
      high: '7.2157000000000000',
      low: '6.7919000000000000',
      close: '7.0396000000000000',
      volume: '2776887.6800000000000000',
      quoteVolume: 19399583.767419,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '7.0399000000000000',
      high: '7.1398000000000000',
      low: '5.9400000000000000',
      close: '6.1648000000000000',
      volume: '4950985.6900000000000000',
      quoteVolume: 32288204.501957,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '6.1642000000000000',
      high: '6.2599000000000000',
      low: '5.9680000000000000',
      close: '6.1661000000000000',
      volume: '2967157.3600000000000000',
      quoteVolume: 18169111.04114,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '6.1335000000000000',
      high: '6.1999000000000000',
      low: '6.0010000000000000',
      close: '6.1388000000000000',
      volume: '2238811.5900000000000000',
      quoteVolume: 13687184.380913,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '6.1384000000000000',
      high: '6.2663000000000000',
      low: '5.9468000000000000',
      close: '6.2200000000000000',
      volume: '2264988.8600000000000000',
      quoteVolume: 13878555.849304,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '6.2250000000000000',
      high: '6.6500000000000000',
      low: '6.1172000000000000',
      close: '6.5229000000000000',
      volume: '2942865.6800000000000000',
      quoteVolume: 19039053.024294,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '6.5185000000000000',
      high: '6.9000000000000000',
      low: '6.4823000000000000',
      close: '6.8655000000000000',
      volume: '3251363.1600000000000000',
      quoteVolume: 21783128.783937,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '6.8688000000000000',
      high: '6.9000000000000000',
      low: '6.6148000000000000',
      close: '6.7887000000000000',
      volume: '3095048.1600000000000000',
      quoteVolume: 21095885.790271,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '6.7888000000000000',
      high: '7.1850000000000000',
      low: '6.6800000000000000',
      close: '7.1069000000000000',
      volume: '3306867.3100000000000000',
      quoteVolume: 23064420.680175,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '7.1028000000000000',
      high: '7.9715000000000000',
      low: '6.9501000000000000',
      close: '7.8665000000000000',
      volume: '4154631.4300000000000000',
      quoteVolume: 30555459.709009,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '7.8569000000000000',
      high: '8.4800000000000000',
      low: '7.3200000000000000',
      close: '8.1303000000000000',
      volume: '5817391.7600000000000000',
      quoteVolume: 46487023.094392,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '8.1347000000000000',
      high: '8.4666000000000000',
      low: '7.6733000000000000',
      close: '7.8100000000000000',
      volume: '4354792.8000000000000000',
      quoteVolume: 35149002.628069,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '7.8070000000000000',
      high: '8.8387000000000000',
      low: '7.6100000000000000',
      close: '8.5972000000000000',
      volume: '4960284.8400000000000000',
      quoteVolume: 40860967.438254,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '8.5972000000000000',
      high: '8.9210000000000000',
      low: '8.4052000000000000',
      close: '8.9051000000000000',
      volume: '5440051.1100000000000000',
      quoteVolume: 47410868.663698,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '8.9001000000000000',
      high: '9.2999000000000000',
      low: '8.8264000000000000',
      close: '9.1697000000000000',
      volume: '5750442.0600000000000000',
      quoteVolume: 52514889.989487,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '9.1695000000000000',
      high: '9.7780000000000000',
      low: '9.0100000000000000',
      close: '9.5288000000000000',
      volume: '6041136.0700000000000000',
      quoteVolume: 57201203.615301,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '9.5250000000000000',
      high: '9.9000000000000000',
      low: '8.9210000000000000',
      close: '9.2765000000000000',
      volume: '4945231.4600000000000000',
      quoteVolume: 46161674.265266,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '9.2763000000000000',
      high: '9.2763000000000000',
      low: '8.7100000000000000',
      close: '8.9103000000000000',
      volume: '4005598.1000000000000000',
      quoteVolume: 36165479.576568,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '8.9165000000000000',
      high: '9.0500000000000000',
      low: '8.4051000000000000',
      close: '8.7655000000000000',
      volume: '2803390.0100000000000000',
      quoteVolume: 24547147.485664,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '8.7636000000000000',
      high: '9.4997000000000000',
      low: '8.7293000000000000',
      close: '9.2911000000000000',
      volume: '3326003.0400000000000000',
      quoteVolume: 30588883.272504,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '9.2854000000000000',
      high: '9.3399000000000000',
      low: '9.0480000000000000',
      close: '9.1087000000000000',
      volume: '2499086.4000000000000000',
      quoteVolume: 22869550.901954,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '9.0972000000000000',
      high: '9.2200000000000000',
      low: '8.8903000000000000',
      close: '9.2200000000000000',
      volume: '2460188.8100000000000000',
      quoteVolume: 22259140.520036,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '9.2167000000000000',
      high: '9.6999000000000000',
      low: '9.1777000000000000',
      close: '9.6127000000000000',
      volume: '2809984.6500000000000000',
      quoteVolume: 26459587.703255,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '9.6127000000000000',
      high: '11.1307000000000000',
      low: '9.4810000000000000',
      close: '10.4728000000000000',
      volume: '4894965.3800000000000000',
      quoteVolume: 49969717.014679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '10.4687000000000000',
      high: '11.2951000000000000',
      low: '10.3928000000000000',
      close: '10.9211000000000000',
      volume: '4346711.0700000000000000',
      quoteVolume: 47273284.241928,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '10.9199000000000000',
      high: '10.9360000000000000',
      low: '10.1300000000000000',
      close: '10.4661000000000000',
      volume: '3077022.9900000000000000',
      quoteVolume: 31977282.892985,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '10.4695000000000000',
      high: '10.9611000000000000',
      low: '10.3799000000000000',
      close: '10.7576000000000000',
      volume: '3038507.7600000000000000',
      quoteVolume: 32510212.911946,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '10.7572000000000000',
      high: '10.8500000000000000',
      low: '10.4000000000000000',
      close: '10.7265000000000000',
      volume: '2877713.1500000000000000',
      quoteVolume: 30567202.596206,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '10.7267000000000000',
      high: '11.0900000000000000',
      low: '9.3901000000000000',
      close: '9.9476000000000000',
      volume: '4800152.2500000000000000',
      quoteVolume: 49351275.843466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '9.9442000000000000',
      high: '10.2361000000000000',
      low: '9.2685000000000000',
      close: '9.6967000000000000',
      volume: '5348579.5700000000000000',
      quoteVolume: 52442588.585831,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '9.6967000000000000',
      high: '9.7557000000000000',
      low: '9.3551000000000000',
      close: '9.4168000000000000',
      volume: '3118340.9500000000000000',
      quoteVolume: 29619413.056984,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '9.4238000000000000',
      high: '9.9998000000000000',
      low: '9.3500000000000000',
      close: '9.8467000000000000',
      volume: '3524897.3500000000000000',
      quoteVolume: 34336397.563894,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '9.8502000000000000',
      high: '10.4914000000000000',
      low: '9.7899000000000000',
      close: '10.2779000000000000',
      volume: '3693794.5400000000000000',
      quoteVolume: 37396909.411865,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '10.2777000000000000',
      high: '11.5510000000000000',
      low: '10.2067000000000000',
      close: '11.3111000000000000',
      volume: '4025695.9700000000000000',
      quoteVolume: 44152799.747361,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '11.3353000000000000',
      high: '11.8000000000000000',
      low: '10.8000000000000000',
      close: '11.7566000000000000',
      volume: '3893847.1600000000000000',
      quoteVolume: 43818057.90284,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '11.7564000000000000',
      high: '11.8701000000000000',
      low: '11.1902000000000000',
      close: '11.4511000000000000',
      volume: '2853200.1500000000000000',
      quoteVolume: 32727760.247279,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '11.4511000000000000',
      high: '11.5080000000000000',
      low: '10.8500000000000000',
      close: '11.4006000000000000',
      volume: '2739049.0800000000000000',
      quoteVolume: 30560700.450351,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '11.4050000000000000',
      high: '13.7500000000000000',
      low: '11.3950000000000000',
      close: '13.6229000000000000',
      volume: '8340655.3200000000000000',
      quoteVolume: 107482964.630679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '13.6230000000000000',
      high: '14.6630000000000000',
      low: '12.9000000000000000',
      close: '14.5294000000000000',
      volume: '6158611.9700000000000000',
      quoteVolume: 84008255.221381,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '14.5290000000000000',
      high: '15.5600000000000000',
      low: '14.3270000000000000',
      close: '15.1000000000000000',
      volume: '6757497.3300000000000000',
      quoteVolume: 101414485.34027,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '15.0999000000000000',
      high: '15.2194000000000000',
      low: '13.5578000000000000',
      close: '14.2931000000000000',
      volume: '6343194.0300000000000000',
      quoteVolume: 90848655.629742,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '14.2848000000000000',
      high: '14.9200000000000000',
      low: '13.9609000000000000',
      close: '14.4651000000000000',
      volume: '3996324.5500000000000000',
      quoteVolume: 58062140.975948,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '14.4651000000000000',
      high: '14.5075000000000000',
      low: '13.8600000000000000',
      close: '14.3711000000000000',
      volume: '3531708.7000000000000000',
      quoteVolume: 50093088.214938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '14.3741000000000000',
      high: '14.9000000000000000',
      low: '14.0110000000000000',
      close: '14.4143000000000000',
      volume: '3757653.7700000000000000',
      quoteVolume: 54303593.05517,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '14.4133000000000000',
      high: '15.4288000000000000',
      low: '14.1809000000000000',
      close: '15.3333000000000000',
      volume: '4049068.3900000000000000',
      quoteVolume: 60747159.402645,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '15.3459000000000000',
      high: '15.3800000000000000',
      low: '14.9333000000000000',
      close: '15.0244000000000000',
      volume: '3282883.1800000000000000',
      quoteVolume: 49667294.365356,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '15.0311000000000000',
      high: '15.0970000000000000',
      low: '14.5630000000000000',
      close: '14.8901000000000000',
      volume: '3079956.9300000000000000',
      quoteVolume: 45702098.682484,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '14.8993000000000000',
      high: '15.1990000000000000',
      low: '14.8400000000000000',
      close: '14.9432000000000000',
      volume: '2986987.6900000000000000',
      quoteVolume: 44970630.556925,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '14.9429000000000000',
      high: '16.0000000000000000',
      low: '14.8800000000000000',
      close: '15.9589000000000000',
      volume: '3293876.1300000000000000',
      quoteVolume: 51027787.313053,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '15.9589000000000000',
      high: '15.9589000000000000',
      low: '15.4400000000000000',
      close: '15.5693000000000000',
      volume: '3106901.4800000000000000',
      quoteVolume: 48737968.350018,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '15.5836000000000000',
      high: '15.7888000000000000',
      low: '15.2040000000000000',
      close: '15.5388000000000000',
      volume: '2472584.8900000000000000',
      quoteVolume: 38403832.139068,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '15.5361000000000000',
      high: '15.8000000000000000',
      low: '15.0000000000000000',
      close: '15.4953000000000000',
      volume: '4271050.4000000000000000',
      quoteVolume: 65955552.676115,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '15.5098000000000000',
      high: '15.5098000000000000',
      low: '15.0220000000000000',
      close: '15.1288000000000000',
      volume: '2369648.3800000000000000',
      quoteVolume: 35958975.368582,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '15.1288000000000000',
      high: '15.2987000000000000',
      low: '13.8607000000000000',
      close: '14.3511000000000000',
      volume: '3015066.5300000000000000',
      quoteVolume: 44410580.384387,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '14.3567000000000000',
      high: '15.2260000000000000',
      low: '14.2310000000000000',
      close: '15.1502000000000000',
      volume: '2695850.8700000000000000',
      quoteVolume: 40069206.590268,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '15.1524000000000000',
      high: '15.4400000000000000',
      low: '14.9469000000000000',
      close: '15.1397000000000000',
      volume: '2632489.7000000000000000',
      quoteVolume: 39846679.02577,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '15.1397000000000000',
      high: '17.5000000000000000',
      low: '14.6600000000000000',
      close: '17.1096000000000000',
      volume: '5285367.1000000000000000',
      quoteVolume: 86478446.288151,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '17.1112000000000000',
      high: '17.3380000000000000',
      low: '16.1000000000000000',
      close: '16.7631000000000000',
      volume: '2775613.8600000000000000',
      quoteVolume: 46472992.15214,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '16.7761000000000000',
      high: '16.8000000000000000',
      low: '15.5301000000000000',
      close: '16.1998000000000000',
      volume: '2725145.6800000000000000',
      quoteVolume: 44086712.960827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '16.1928000000000000',
      high: '16.8297000000000000',
      low: '15.8697000000000000',
      close: '16.6000000000000000',
      volume: '3240914.2500000000000000',
      quoteVolume: 53588345.695308,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '16.6000000000000000',
      high: '16.7188000000000000',
      low: '16.4010000000000000',
      close: '16.6665000000000000',
      volume: '2988300.9700000000000000',
      quoteVolume: 49575583.531128,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '16.6538000000000000',
      high: '16.8067000000000000',
      low: '16.3770000000000000',
      close: '16.4753000000000000',
      volume: '2737173.2000000000000000',
      quoteVolume: 45408014.592112,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '16.4949000000000000',
      high: '16.9850000000000000',
      low: '15.9612000000000000',
      close: '16.9666000000000000',
      volume: '2790050.9300000000000000',
      quoteVolume: 46116769.387966,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '16.9666000000000000',
      high: '17.7386000000000000',
      low: '16.7314000000000000',
      close: '17.5376000000000000',
      volume: '3182964.8500000000000000',
      quoteVolume: 55170337.210131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '17.5201000000000000',
      high: '18.2576000000000000',
      low: '17.3319000000000000',
      close: '17.8888000000000000',
      volume: '2966204.7100000000000000',
      quoteVolume: 52669428.49396,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '17.8888000000000000',
      high: '19.8800000000000000',
      low: '17.5303000000000000',
      close: '19.7155000000000000',
      volume: '3356886.8900000000000000',
      quoteVolume: 62702520.871521,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '19.7155000000000000',
      high: '20.0000000000000000',
      low: '18.0000000000000000',
      close: '18.5489000000000000',
      volume: '3532965.9700000000000000',
      quoteVolume: 67883042.73247,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '18.5388000000000000',
      high: '19.4100000000000000',
      low: '18.1600000000000000',
      close: '18.9920000000000000',
      volume: '2939082.4200000000000000',
      quoteVolume: 55680460.143077,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '18.9710000000000000',
      high: '19.4593000000000000',
      low: '18.8445000000000000',
      close: '19.3657000000000000',
      volume: '2703355.9100000000000000',
      quoteVolume: 51825140.403764,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '19.3658000000000000',
      high: '19.3866000000000000',
      low: '18.8200000000000000',
      close: '19.0745000000000000',
      volume: '2519027.6300000000000000',
      quoteVolume: 48185036.221402,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '19.0423000000000000',
      high: '19.2137000000000000',
      low: '18.8000000000000000',
      close: '18.9523000000000000',
      volume: '2329702.3400000000000000',
      quoteVolume: 44296486.486205,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '18.9507000000000000',
      high: '19.0420000000000000',
      low: '17.7881000000000000',
      close: '17.9777000000000000',
      volume: '2142481.9200000000000000',
      quoteVolume: 39222061.198727,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '17.9728000000000000',
      high: '18.5400000000000000',
      low: '17.0100000000000000',
      close: '18.2984000000000000',
      volume: '2776440.4800000000000000',
      quoteVolume: 49902259.792581,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '18.2984000000000000',
      high: '18.6095000000000000',
      low: '17.8927000000000000',
      close: '18.2400000000000000',
      volume: '2553039.1200000000000000',
      quoteVolume: 46664596.972989,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '18.2400000000000000',
      high: '18.2400000000000000',
      low: '16.2501000000000000',
      close: '17.5555000000000000',
      volume: '1815271.0500000000000000',
      quoteVolume: 31411384.587709,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '17.5555000000000000',
      high: '18.5520000000000000',
      low: '16.6120000000000000',
      close: '18.3333000000000000',
      volume: '2325593.3600000000000000',
      quoteVolume: 41384885.795214,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '18.2867000000000000',
      high: '18.6398000000000000',
      low: '17.9143000000000000',
      close: '18.6111000000000000',
      volume: '2205194.6100000000000000',
      quoteVolume: 40484642.365226,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '18.6111000000000000',
      high: '19.9000000000000000',
      low: '18.4259000000000000',
      close: '19.3866000000000000',
      volume: '2438051.2100000000000000',
      quoteVolume: 46779479.130832,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '19.3734000000000000',
      high: '19.7800000000000000',
      low: '18.6601000000000000',
      close: '19.2666000000000000',
      volume: '2218308.0300000000000000',
      quoteVolume: 42952596.269131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '19.2668000000000000',
      high: '19.7482000000000000',
      low: '18.7064000000000000',
      close: '19.6408000000000000',
      volume: '2237334.6000000000000000',
      quoteVolume: 43185852.935218,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '19.6399000000000000',
      high: '19.7908000000000000',
      low: '19.2600000000000000',
      close: '19.4888000000000000',
      volume: '2005582.6000000000000000',
      quoteVolume: 39131247.103883,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '19.4757000000000000',
      high: '21.9980000000000000',
      low: '19.4333000000000000',
      close: '21.8888000000000000',
      volume: '3686092.5700000000000000',
      quoteVolume: 76859035.465239,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '21.8888000000000000',
      high: '24.7459000000000000',
      low: '21.1200000000000000',
      close: '24.3188000000000000',
      volume: '4247944.3800000000000000',
      quoteVolume: 97541476.101794,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '24.3188000000000000',
      high: '25.4973000000000000',
      low: '23.7600000000000000',
      close: '24.8888000000000000',
      volume: '3545157.4900000000000000',
      quoteVolume: 87320188.459109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '24.8693000000000000',
      high: '24.8693000000000000',
      low: '22.4500000000000000',
      close: '23.9999000000000000',
      volume: '3307426.8400000000000000',
      quoteVolume: 77726576.261717,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '23.9999000000000000',
      high: '24.6368000000000000',
      low: '23.0200000000000000',
      close: '23.8000000000000000',
      volume: '3005735.9300000000000000',
      quoteVolume: 72180137.003328,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '23.7812000000000000',
      high: '24.6131000000000000',
      low: '22.4700000000000000',
      close: '22.5460000000000000',
      volume: '2877995.4900000000000000',
      quoteVolume: 67622172.827015,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '22.5207000000000000',
      high: '23.1209000000000000',
      low: '20.8800000000000000',
      close: '22.9703000000000000',
      volume: '2961681.7400000000000000',
      quoteVolume: 65293459.483933,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '22.9877000000000000',
      high: '23.4698000000000000',
      low: '21.5500000000000000',
      close: '22.4863000000000000',
      volume: '3114460.9000000000000000',
      quoteVolume: 70932653.981115,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '22.4869000000000000',
      high: '23.7860000000000000',
      low: '21.6503000000000000',
      close: '23.0555000000000000',
      volume: '3273950.4700000000000000',
      quoteVolume: 75393015.340524,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '23.0555000000000000',
      high: '23.1209000000000000',
      low: '22.3876000000000000',
      close: '22.4324000000000000',
      volume: '2989261.0700000000000000',
      quoteVolume: 67575656.858512,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '22.4326000000000000',
      high: '23.2092000000000000',
      low: '22.4200000000000000',
      close: '23.0226000000000000',
      volume: '3015774.2900000000000000',
      quoteVolume: 69067258.092041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '23.0166000000000000',
      high: '23.0828000000000000',
      low: '21.2797000000000000',
      close: '21.6788000000000000',
      volume: '2388965.0700000000000000',
      quoteVolume: 52801462.600293,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '21.6847000000000000',
      high: '22.3000000000000000',
      low: '21.3292000000000000',
      close: '22.0391000000000000',
      volume: '2197565.5300000000000000',
      quoteVolume: 47987092.830938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '22.0300000000000000',
      high: '22.0990000000000000',
      low: '21.4700000000000000',
      close: '21.8788000000000000',
      volume: '2064791.5400000000000000',
      quoteVolume: 45032827.826184,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '21.8810000000000000',
      high: '24.1270000000000000',
      low: '21.7497000000000000',
      close: '23.6166000000000000',
      volume: '2446015.2000000000000000',
      quoteVolume: 56078160.970131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '23.6168000000000000',
      high: '23.9720000000000000',
      low: '23.0101000000000000',
      close: '23.6999000000000000',
      volume: '2230465.5800000000000000',
      quoteVolume: 52406113.705314,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '23.6999000000000000',
      high: '23.7968000000000000',
      low: '22.3000000000000000',
      close: '22.8888000000000000',
      volume: '1898934.6200000000000000',
      quoteVolume: 43768914.432769,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '22.8887000000000000',
      high: '23.1772000000000000',
      low: '22.6408000000000000',
      close: '22.9777000000000000',
      volume: '1787108.3900000000000000',
      quoteVolume: 40919944.41562,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '22.9777000000000000',
      high: '22.9973000000000000',
      low: '21.4500000000000000',
      close: '22.1895000000000000',
      volume: '1764747.5400000000000000',
      quoteVolume: 39058125.114883,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '22.2018000000000000',
      high: '22.4500000000000000',
      low: '20.2050000000000000',
      close: '20.6602000000000000',
      volume: '1813897.1200000000000000',
      quoteVolume: 39553390.823722,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '20.6602000000000000',
      high: '21.4135000000000000',
      low: '19.5100000000000000',
      close: '20.6673000000000000',
      volume: '2742356.4200000000000000',
      quoteVolume: 56630740.961407,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '20.6671000000000000',
      high: '20.9670000000000000',
      low: '18.5402000000000000',
      close: '18.8388000000000000',
      volume: '2334477.6000000000000000',
      quoteVolume: 46078618.722513,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '18.8377000000000000',
      high: '19.9888000000000000',
      low: '18.3000000000000000',
      close: '19.6166000000000000',
      volume: '2429624.3200000000000000',
      quoteVolume: 46735449.139546,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '19.6294000000000000',
      high: '22.0000000000000000',
      low: '18.8800000000000000',
      close: '20.8059000000000000',
      volume: '3746402.5300000000000000',
      quoteVolume: 76700019.096599,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '20.7871000000000000',
      high: '21.5000000000000000',
      low: '20.1000000000000000',
      close: '20.8934000000000000',
      volume: '3228794.3800000000000000',
      quoteVolume: 67290647.825955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '20.9026000000000000',
      high: '24.0000000000000000',
      low: '20.7349000000000000',
      close: '23.5210000000000000',
      volume: '4566012.5800000000000000',
      quoteVolume: 103765929.624047,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '23.5210000000000000',
      high: '24.5000000000000000',
      low: '22.5748000000000000',
      close: '23.6553000000000000',
      volume: '3821993.1600000000000000',
      quoteVolume: 90350011.235486,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '23.6553000000000000',
      high: '27.6556000000000000',
      low: '23.4600000000000000',
      close: '26.8116000000000000',
      volume: '3433770.3400000000000000',
      quoteVolume: 89988096.508272,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '26.8348000000000000',
      high: '27.7900000000000000',
      low: '25.0000000000000000',
      close: '26.2888000000000000',
      volume: '4200071.2400000000000000',
      quoteVolume: 111245092.085173,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '26.2888000000000000',
      high: '26.6783000000000000',
      low: '23.1424000000000000',
      close: '25.7795000000000000',
      volume: '3429086.5600000000000000',
      quoteVolume: 85241258.720158,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '25.7986000000000000',
      high: '29.0989000000000000',
      low: '25.1124000000000000',
      close: '28.8171000000000000',
      volume: '4319102.3500000000000000',
      quoteVolume: 117061502.700204,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '28.8165000000000000',
      high: '29.8368000000000000',
      low: '27.5430000000000000',
      close: '29.1122000000000000',
      volume: '4437585.5400000000000000',
      quoteVolume: 128407708.555722,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '29.1122000000000000',
      high: '29.3399000000000000',
      low: '27.5306000000000000',
      close: '29.2666000000000000',
      volume: '3980328.4500000000000000',
      quoteVolume: 113614310.06848,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '29.2666000000000000',
      high: '32.8000000000000000',
      low: '28.0500000000000000',
      close: '32.3907000000000000',
      volume: '4704491.9800000000000000',
      quoteVolume: 142363584.104499,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '32.3911000000000000',
      high: '32.4374000000000000',
      low: '30.6100000000000000',
      close: '30.8067000000000000',
      volume: '3807962.5800000000000000',
      quoteVolume: 120261451.277312,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '30.8333000000000000',
      high: '32.2500000000000000',
      low: '29.6699000000000000',
      close: '31.3934000000000000',
      volume: '3793076.8500000000000000',
      quoteVolume: 118471724.038178,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '31.3986000000000000',
      high: '34.7067000000000000',
      low: '31.1200000000000000',
      close: '33.9435000000000000',
      volume: '4534224.5600000000000000',
      quoteVolume: 151952803.938095,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '34.1004000000000000',
      high: '35.4868000000000000',
      low: '33.6231000000000000',
      close: '35.1464000000000000',
      volume: '2287998.0600000000000000',
      quoteVolume: 79609705.50209479,
      btcVolume: 9949.002147255906,
      usdVolume: 79609705.50209479,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '35.1128000000000000',
      high: '35.1128000000000000',
      low: '32.3200000000000000',
      close: '33.0989000000000000',
      volume: '2391390.5700000000000000',
      quoteVolume: 80142171.05356416,
      btcVolume: 9883.743936491968,
      usdVolume: 80142171.05356416,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '33.0220000000000000',
      high: '35.1001000000000000',
      low: '32.5000000000000000',
      close: '33.4619000000000000',
      volume: '2295348.1100000000000000',
      quoteVolume: 77908157.85849185,
      btcVolume: 8929.21104959771,
      usdVolume: 77908157.85849185,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '33.4618000000000000',
      high: '34.5000000000000000',
      low: '32.5000000000000000',
      close: '33.1336000000000000',
      volume: '1895937.3300000000000000',
      quoteVolume: 63128977.16986113,
      btcVolume: 7252.390997849462,
      usdVolume: 63128977.16986113,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '33.0923000000000000',
      high: '33.8790000000000000',
      low: '30.8500000000000000',
      close: '33.6083000000000000',
      volume: '2212524.3300000000000000',
      quoteVolume: 72870434.08302279,
      btcVolume: 8454.326316547385,
      usdVolume: 72870434.08302279,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '33.6085000000000000',
      high: '34.9600000000000000',
      low: '30.2500000000000000',
      close: '31.6330000000000000',
      volume: '2432021.9400000000000000',
      quoteVolume: 80763627.05465643,
      btcVolume: 9414.799798958127,
      usdVolume: 80763627.05465643,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '31.6473000000000000',
      high: '32.9724000000000000',
      low: '30.6100000000000000',
      close: '32.9321000000000000',
      volume: '1923224.7500000000000000',
      quoteVolume: 61104329.90333608,
      btcVolume: 7303.226615523445,
      usdVolume: 61104329.90333608,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '32.9165000000000000',
      high: '34.3530000000000000',
      low: '32.5024000000000000',
      close: '33.3348000000000000',
      volume: '2519040.2400000000000000',
      quoteVolume: 83650473.63800699,
      btcVolume: 9797.183411874852,
      usdVolume: 83650473.63800699,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '33.3521000000000000',
      high: '33.7647000000000000',
      low: '32.6800000000000000',
      close: '33.1527000000000000',
      volume: '3125349.5700000000000000',
      quoteVolume: 103747789.15420249,
      btcVolume: 11980.301630342452,
      usdVolume: 103747789.15420249,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '33.1343000000000000',
      high: '33.1753000000000000',
      low: '30.5100000000000000',
      close: '30.8093000000000000',
      volume: '2793947.5900000000000000',
      quoteVolume: 89255709.65780656,
      btcVolume: 10474.381377841331,
      usdVolume: 89255709.65780656,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '30.8093000000000000',
      high: '31.4673000000000000',
      low: '28.7168000000000000',
      close: '29.2451000000000000',
      volume: '2872894.7300000000000000',
      quoteVolume: 86509446.32841438,
      btcVolume: 10980.6600454644,
      usdVolume: 86509446.32841438,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '29.2451000000000000',
      high: '31.1843000000000000',
      low: '28.9440000000000000',
      close: '30.8755000000000000',
      volume: '3345930.6000000000000000',
      quoteVolume: 100255371.95814995,
      btcVolume: 12913.379731084178,
      usdVolume: 100255371.95814995,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '30.8887000000000000',
      high: '31.9906000000000000',
      low: '29.6300000000000000',
      close: '31.0888000000000000',
      volume: '2987983.0800000000000000',
      quoteVolume: 93095763.1398957,
      btcVolume: 12017.344452736703,
      usdVolume: 93095763.1398957,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '31.0888000000000000',
      high: '31.9911000000000000',
      low: '30.9001000000000000',
      close: '31.3098000000000000',
      volume: '2758670.5000000000000000',
      quoteVolume: 86875753.11986831,
      btcVolume: 10951.248485073238,
      usdVolume: 86875753.11986831,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '31.3478000000000000',
      high: '32.2926000000000000',
      low: '31.1071000000000000',
      close: '31.7045000000000000',
      volume: '3228596.5900000000000000',
      quoteVolume: 102524672.99267428,
      btcVolume: 12945.174416648542,
      usdVolume: 102524672.99267428,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '31.7293000000000000',
      high: '31.8500000000000000',
      low: '29.7611000000000000',
      close: '30.0950000000000000',
      volume: '2533570.1800000000000000',
      quoteVolume: 78429078.60609235,
      btcVolume: 10087.18462271012,
      usdVolume: 78429078.60609235,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '30.1174000000000000',
      high: '32.0438000000000000',
      low: '29.7286000000000000',
      close: '32.0271000000000000',
      volume: '3550510.6300000000000000',
      quoteVolume: 110950707.9152291,
      btcVolume: 14185.907492697892,
      usdVolume: 110950707.9152291,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '32.0261000000000000',
      high: '32.0821000000000000',
      low: '30.9312000000000000',
      close: '31.8889000000000000',
      volume: '2693421.5300000000000000',
      quoteVolume: 84894700.01127566,
      btcVolume: 10799.407953421249,
      usdVolume: 84894700.01127566,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '31.8889000000000000',
      high: '34.9800000000000000',
      low: '31.8008000000000000',
      close: '34.8310000000000000',
      volume: '3941279.3400000000000000',
      quoteVolume: 132789273.60234739,
      btcVolume: 16598.78224827631,
      usdVolume: 132789273.60234739,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '34.8284000000000000',
      high: '36.3689000000000000',
      low: '34.1200000000000000',
      close: '35.2510000000000000',
      volume: '3537185.5100000000000000',
      quoteVolume: 124552938.20500359,
      btcVolume: 15300.412184978906,
      usdVolume: 124552938.20500359,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '35.2577000000000000',
      high: '35.3000000000000000',
      low: '29.8146000000000000',
      close: '32.3803000000000000',
      volume: '4820144.3200000000000000',
      quoteVolume: 155335662.97399047,
      btcVolume: 18662.671237915034,
      usdVolume: 155335662.97399047,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '32.3887000000000000',
      high: '33.9420000000000000',
      low: '31.6865000000000000',
      close: '32.7500000000000000',
      volume: '3389863.2300000000000000',
      quoteVolume: 111183078.4554273,
      btcVolume: 12804.521200155277,
      usdVolume: 111183078.4554273,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '32.7303000000000000',
      high: '33.2943000000000000',
      low: '31.7316000000000000',
      close: '32.1888000000000000',
      volume: '2826534.9700000000000000',
      quoteVolume: 92109821.4809208,
      btcVolume: 10183.184045346163,
      usdVolume: 92109821.4809208,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '32.1956000000000000',
      high: '34.4500000000000000',
      low: '32.1862000000000000',
      close: '33.8286000000000000',
      volume: '3325355.0300000000000000',
      quoteVolume: 111126704.95099491,
      btcVolume: 12078.924988720071,
      usdVolume: 111126704.95099491,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '33.8542000000000000',
      high: '35.0749000000000000',
      low: '33.7386000000000000',
      close: '34.4333000000000000',
      volume: '3404156.4000000000000000',
      quoteVolume: 117184660.8514287,
      btcVolume: 12820.477601301915,
      usdVolume: 117142616.23937275,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '34.4261000000000000',
      high: '35.6000000000000000',
      low: '34.3939000000000000',
      close: '35.1888000000000000',
      volume: '3662780.3500000000000000',
      quoteVolume: 128944461.18892004,
      btcVolume: 14098.563163206594,
      usdVolume: 128944461.18892004,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '35.1764000000000000',
      high: '36.9146000000000000',
      low: '34.3979000000000000',
      close: '36.8200000000000000',
      volume: '4059479.0200000000000000',
      quoteVolume: 145554685.05118558,
      btcVolume: 15614.242998911897,
      usdVolume: 145554685.05118558,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '36.8377000000000000',
      high: '38.8500000000000000',
      low: '36.3364000000000000',
      close: '38.6666000000000000',
      volume: '3532992.4300000000000000',
      quoteVolume: 132425793.40442118,
      btcVolume: 13534.113990105783,
      usdVolume: 132425793.40442118,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '38.6666000000000000',
      high: '39.5941000000000000',
      low: '37.1100000000000000',
      close: '38.0000000000000000',
      volume: '3287617.1200000000000000',
      quoteVolume: 126851635.12920612,
      btcVolume: 11861.268781544859,
      usdVolume: 126851635.12920612,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '38.0001000000000000',
      high: '38.5320000000000000',
      low: '37.2600000000000000',
      close: '37.2836000000000000',
      volume: '2788591.8500000000000000',
      quoteVolume: 105493792.36592467,
      btcVolume: 9731.003920100708,
      usdVolume: 105493792.36592467,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '37.2665000000000000',
      high: '37.8210000000000000',
      low: '36.0001000000000000',
      close: '37.4300000000000000',
      volume: '2631717.1900000000000000',
      quoteVolume: 97880605.03243802,
      btcVolume: 8975.410919955108,
      usdVolume: 97880605.03243802,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '37.4300000000000000',
      high: '37.5510000000000000',
      low: '35.7100000000000000',
      close: '36.0947000000000000',
      volume: '2000864.9200000000000000',
      quoteVolume: 73473294.34677196,
      btcVolume: 6467.382207788116,
      usdVolume: 73473294.34677196,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '36.0941000000000000',
      high: '38.0000000000000000',
      low: '34.5000000000000000',
      close: '36.0999000000000000',
      volume: '2758448.8600000000000000',
      quoteVolume: 100936180.80838415,
      btcVolume: 7924.993856109126,
      usdVolume: 100936180.80838415,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '36.0999000000000000',
      high: '36.3500000000000000',
      low: '33.1584000000000000',
      close: '34.3999000000000000',
      volume: '2591182.3600000000000000',
      quoteVolume: 89690344.30455235,
      btcVolume: 7533.165332525957,
      usdVolume: 89690344.30455235,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '34.3420000000000000',
      high: '35.2809000000000000',
      low: '33.8200000000000000',
      close: '34.6289000000000000',
      volume: '2104962.2500000000000000',
      quoteVolume: 72480203.20366873,
      btcVolume: 6101.8047057837575,
      usdVolume: 72480203.20366873,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '34.5951000000000000',
      high: '35.8000000000000000',
      low: '33.2447000000000000',
      close: '35.5000000000000000',
      volume: '2585753.6300000000000000',
      quoteVolume: 89769494.98459294,
      btcVolume: 7508.666820715312,
      usdVolume: 89769494.98459294,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '35.5111000000000000',
      high: '35.5111000000000000',
      low: '32.0200000000000000',
      close: '32.1314000000000000',
      volume: '2022991.5500000000000000',
      quoteVolume: 68980161.28362553,
      btcVolume: 5906.05441303598,
      usdVolume: 68980161.28362553,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '32.1140000000000000',
      high: '34.0500000000000000',
      low: '31.3682000000000000',
      close: '33.2777000000000000',
      volume: '1834460.9000000000000000',
      quoteVolume: 59929948.94285722,
      btcVolume: 5568.470684819062,
      usdVolume: 59929948.94285722,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '33.2777000000000000',
      high: '34.0000000000000000',
      low: '30.5600000000000000',
      close: '31.9570000000000000',
      volume: '2016746.5900000000000000',
      quoteVolume: 64596527.83502798,
      btcVolume: 6368.043385041789,
      usdVolume: 64596527.83502798,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '31.9772000000000000',
      high: '33.0500000000000000',
      low: '31.6230000000000000',
      close: '32.5414000000000000',
      volume: '1930269.8700000000000000',
      quoteVolume: 62412176.663419046,
      btcVolume: 5508.986915116347,
      usdVolume: 62412176.663419046,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '32.5237000000000000',
      high: '34.3839000000000000',
      low: '32.1503000000000000',
      close: '33.0888000000000000',
      volume: '2193452.4800000000000000',
      quoteVolume: 73032732.02201295,
      btcVolume: 6260.154521227522,
      usdVolume: 73032732.02201295,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '33.0399000000000000',
      high: '33.6497000000000000',
      low: '32.4000000000000000',
      close: '32.5566000000000000',
      volume: '1501816.7600000000000000',
      quoteVolume: 49493032.56383061,
      btcVolume: 4446.897333852955,
      usdVolume: 49493032.56383061,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '32.5507000000000000',
      high: '33.1815000000000000',
      low: '32.5000000000000000',
      close: '32.5888000000000000',
      volume: '1122287.4400000000000000',
      quoteVolume: 36787236.23351705,
      btcVolume: 3237.2866952944937,
      usdVolume: 36787236.23351705,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '32.5471000000000000',
      high: '33.3997000000000000',
      low: '32.4000000000000000',
      close: '32.9344000000000000',
      volume: '1219900.0500000000000000',
      quoteVolume: 40138364.745880224,
      btcVolume: 3553.1949115873035,
      usdVolume: 40138364.745880224,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '32.9326000000000000',
      high: '34.2918000000000000',
      low: '32.7500000000000000',
      close: '33.3888000000000000',
      volume: '1483935.3600000000000000',
      quoteVolume: 49789526.97420341,
      btcVolume: 4261.852971883836,
      usdVolume: 49789526.97420341,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '33.3888000000000000',
      high: '33.9000000000000000',
      low: '32.2195000000000000',
      close: '32.5075000000000000',
      volume: '1212484.7200000000000000',
      quoteVolume: 40118253.17297037,
      btcVolume: 3221.2079585364836,
      usdVolume: 40118253.17297037,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '32.4825000000000000',
      high: '32.8900000000000000',
      low: '30.5100000000000000',
      close: '31.2220000000000000',
      volume: '1575508.6000000000000000',
      quoteVolume: 50081252.967029475,
      btcVolume: 3955.920822486626,
      usdVolume: 50081252.967029475,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '31.2220000000000000',
      high: '31.8900000000000000',
      low: '28.2666000000000000',
      close: '29.4181000000000000',
      volume: '1727744.5200000000000000',
      quoteVolume: 51340643.34346419,
      btcVolume: 4462.463255903058,
      usdVolume: 51340643.34346419,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '29.4213000000000000',
      high: '32.6219000000000000',
      low: '29.0000000000000000',
      close: '31.8894000000000000',
      volume: '2941848.2500000000000000',
      quoteVolume: 92635627.07000853,
      btcVolume: 8103.016132792709,
      usdVolume: 92635627.07000853,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '31.8874000000000000',
      high: '32.4894000000000000',
      low: '30.2075000000000000',
      close: '31.4888000000000000',
      volume: '1746699.7900000000000000',
      quoteVolume: 54982624.17098898,
      btcVolume: 4813.480581114553,
      usdVolume: 54982624.17098898,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '31.4701000000000000',
      high: '31.5259000000000000',
      low: '28.3111000000000000',
      close: '28.5589000000000000',
      volume: '2103525.3200000000000000',
      quoteVolume: 62793063.28152121,
      btcVolume: 5821.32081882098,
      usdVolume: 62793063.28152121,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '28.5584000000000000',
      high: '28.7498000000000000',
      low: '26.3190000000000000',
      close: '28.6489000000000000',
      volume: '2954452.0200000000000000',
      quoteVolume: 81881912.98124091,
      btcVolume: 7918.900039057427,
      usdVolume: 81881912.98124091,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '28.6348000000000000',
      high: '29.5800000000000000',
      low: '24.8626000000000000',
      close: '25.0601000000000000',
      volume: '3691855.4300000000000000',
      quoteVolume: 100236711.8868326,
      btcVolume: 9803.524590355422,
      usdVolume: 100236711.8868326,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '25.0552000000000000',
      high: '28.2898000000000000',
      low: '24.1709000000000000',
      close: '27.6258000000000000',
      volume: '5619617.8800000000000000',
      quoteVolume: 147693999.79602024,
      btcVolume: 15463.868456792456,
      usdVolume: 147693999.79602024,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '27.6264000000000000',
      high: '29.5000000000000000',
      low: '26.8000000000000000',
      close: '28.9381000000000000',
      volume: '6265532.8900000000000000',
      quoteVolume: 177710944.23860273,
      btcVolume: 17945.243760879956,
      usdVolume: 177608163.10294086,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '28.9521000000000000',
      high: '29.9600000000000000',
      low: '28.3158000000000000',
      close: '29.0096000000000000',
      volume: '3925662.8400000000000000',
      quoteVolume: 113855677.67222823,
      btcVolume: 10894.61710090789,
      usdVolume: 113855677.67222823,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '29.0015000000000000',
      high: '31.4600000000000000',
      low: '28.9000000000000000',
      close: '31.0932000000000000',
      volume: '2868452.7700000000000000',
      quoteVolume: 87962899.98284975,
      btcVolume: 8266.346344725387,
      usdVolume: 87962899.98284975,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '31.1222000000000000',
      high: '31.5281000000000000',
      low: '29.2801000000000000',
      close: '30.4388000000000000',
      volume: '2165383.6300000000000000',
      quoteVolume: 65800415.77082615,
      btcVolume: 6225.919909520394,
      usdVolume: 65800415.77082615,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '30.4389000000000000',
      high: '32.6000000000000000',
      low: '29.6203000000000000',
      close: '30.8850000000000000',
      volume: '3465285.3200000000000000',
      quoteVolume: 107521586.41408998,
      btcVolume: 10273.673397545184,
      usdVolume: 107521586.41408998,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '30.9226000000000000',
      high: '30.9400000000000000',
      low: '28.9471000000000000',
      close: '29.4894000000000000',
      volume: '2638959.1800000000000000',
      quoteVolume: 78461044.85644288,
      btcVolume: 7795.804246913197,
      usdVolume: 78461044.85644288,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '29.4649000000000000',
      high: '29.5057000000000000',
      low: '27.7426000000000000',
      close: '28.8303000000000000',
      volume: '2335697.9600000000000000',
      quoteVolume: 66771505.28094476,
      btcVolume: 6868.82631676914,
      usdVolume: 66771505.28094476,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '28.8304000000000000',
      high: '29.6596000000000000',
      low: '28.4444000000000000',
      close: '28.9395000000000000',
      volume: '2189105.6200000000000000',
      quoteVolume: 63998748.52433625,
      btcVolume: 6402.849558443817,
      usdVolume: 63998748.52433625,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '28.9227000000000000',
      high: '29.0500000000000000',
      low: '28.1641000000000000',
      close: '28.8983000000000000',
      volume: '1926812.0300000000000000',
      quoteVolume: 55269615.02700419,
      btcVolume: 5653.511709576811,
      usdVolume: 55269615.02700419,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '28.8983000000000000',
      high: '29.4502000000000000',
      low: '27.2061000000000000',
      close: '27.7498000000000000',
      volume: '1482247.8300000000000000',
      quoteVolume: 41938098.57057801,
      btcVolume: 4304.517936922523,
      usdVolume: 41938098.57057801,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '27.7384000000000000',
      high: '27.8459000000000000',
      low: '26.4200000000000000',
      close: '27.7627000000000000',
      volume: '1320975.1600000000000000',
      quoteVolume: 36374310.99186316,
      btcVolume: 3832.5016194283835,
      usdVolume: 36374310.99186316,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '27.7581000000000000',
      high: '28.0419000000000000',
      low: '26.8202000000000000',
      close: '26.9525000000000000',
      volume: '1140682.0500000000000000',
      quoteVolume: 31110138.426472325,
      btcVolume: 3256.314799181455,
      usdVolume: 31110138.426472325,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '26.9540000000000000',
      high: '27.1198000000000000',
      low: '26.2020000000000000',
      close: '26.9867000000000000',
      volume: '820023.9000000000000000',
      quoteVolume: 21902889.9898799,
      btcVolume: 2296.8262791212037,
      usdVolume: 21902889.9898799,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '26.9879000000000000',
      high: '27.8300000000000000',
      low: '26.7800000000000000',
      close: '27.7348000000000000',
      volume: '1934428.9000000000000000',
      quoteVolume: 53152588.64401577,
      btcVolume: 5403.686098362969,
      usdVolume: 53152588.64401577,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '27.7500000000000000',
      high: '28.7664000000000000',
      low: '27.4500000000000000',
      close: '28.7088000000000000',
      volume: '2252485.2300000000000000',
      quoteVolume: 63463951.041090965,
      btcVolume: 6318.884088129215,
      usdVolume: 63463951.041090965,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '28.7100000000000000',
      high: '28.7186000000000000',
      low: '27.5643000000000000',
      close: '28.1400000000000000',
      volume: '1501539.5400000000000000',
      quoteVolume: 42179913.12631353,
      btcVolume: 4032.0149580734223,
      usdVolume: 42179913.12631353,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '28.1400000000000000',
      high: '28.3409000000000000',
      low: '27.5000000000000000',
      close: '27.7285000000000000',
      volume: '1209342.2000000000000000',
      quoteVolume: 33690305.912137166,
      btcVolume: 3130.3892108291648,
      usdVolume: 33690305.912137166,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '27.7333000000000000',
      high: '27.7719000000000000',
      low: '27.0852000000000000',
      close: '27.3834000000000000',
      volume: '967494.6400000000000000',
      quoteVolume: 26514204.222139858,
      btcVolume: 2463.628698032826,
      usdVolume: 26514204.222139858,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '27.3834000000000000',
      high: '28.3000000000000000',
      low: '27.3011000000000000',
      close: '28.0107000000000000',
      volume: '1170209.2600000000000000',
      quoteVolume: 32618569.941277068,
      btcVolume: 2806.4498779368155,
      usdVolume: 32618569.941277068,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '28.0117000000000000',
      high: '28.3125000000000000',
      low: '27.3333000000000000',
      close: '27.6523000000000000',
      volume: '1404874.7100000000000000',
      quoteVolume: 39075613.06976784,
      btcVolume: 3302.2858155088666,
      usdVolume: 39075613.06976784,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '27.6539000000000000',
      high: '29.9800000000000000',
      low: '27.3330000000000000',
      close: '29.6694000000000000',
      volume: '2398210.6200000000000000',
      quoteVolume: 68536315.25246768,
      btcVolume: 5832.360785093793,
      usdVolume: 68536315.25246768,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '29.6690000000000000',
      high: '31.8791000000000000',
      low: '29.1914000000000000',
      close: '31.1427000000000000',
      volume: '3071252.6500000000000000',
      quoteVolume: 94636423.46255855,
      btcVolume: 8004.065605940615,
      usdVolume: 94636423.46255855,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '31.1074000000000000',
      high: '31.5000000000000000',
      low: '29.6666000000000000',
      close: '29.8188000000000000',
      volume: '1467817.4400000000000000',
      quoteVolume: 45035359.27979547,
      btcVolume: 3801.756204313858,
      usdVolume: 45035359.27979547,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '29.7969000000000000',
      high: '30.0200000000000000',
      low: '29.0333000000000000',
      close: '29.6662000000000000',
      volume: '1302579.2400000000000000',
      quoteVolume: 38469949.58556509,
      btcVolume: 3320.6255793968776,
      usdVolume: 38469949.58556509,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '29.6660000000000000',
      high: '30.4458000000000000',
      low: '29.3000000000000000',
      close: '30.2109000000000000',
      volume: '1563528.9000000000000000',
      quoteVolume: 46830334.26929312,
      btcVolume: 4107.984996045789,
      usdVolume: 46830334.26929312,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '30.3192000000000000',
      high: '30.5936000000000000',
      low: '29.8230000000000000',
      close: '30.1856000000000000',
      volume: '1527254.3500000000000000',
      quoteVolume: 46134967.60864254,
      btcVolume: 4043.0634741807817,
      usdVolume: 46134967.60864254,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '30.1856000000000000',
      high: '30.4496000000000000',
      low: '29.2000000000000000',
      close: '29.4537000000000000',
      volume: '1164233.9000000000000000',
      quoteVolume: 34747155.42068626,
      btcVolume: 3108.3618369887367,
      usdVolume: 34747155.42068626,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '29.4512000000000000',
      high: '29.4693000000000000',
      low: '26.7500000000000000',
      close: '27.0106000000000000',
      volume: '1176934.2300000000000000',
      quoteVolume: 33298167.10513729,
      btcVolume: 3178.5215896041464,
      usdVolume: 33298167.10513729,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '27.0269000000000000',
      high: '28.2174000000000000',
      low: '26.7000000000000000',
      close: '28.1058000000000000',
      volume: '1242352.4600000000000000',
      quoteVolume: 34322334.57446039,
      btcVolume: 3395.545034113294,
      usdVolume: 34322334.57446039,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '28.1052000000000000',
      high: '28.1313000000000000',
      low: '26.7880000000000000',
      close: '27.5111000000000000',
      volume: '965369.6300000000000000',
      quoteVolume: 26500903.438648794,
      btcVolume: 2602.9765482710295,
      usdVolume: 26500903.438648794,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '27.4908000000000000',
      high: '27.9605000000000000',
      low: '26.9863000000000000',
      close: '27.5038000000000000',
      volume: '963986.7000000000000000',
      quoteVolume: 26558675.591700017,
      btcVolume: 2579.2725537309097,
      usdVolume: 26558675.591700017,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '27.5037000000000000',
      high: '28.1797000000000000',
      low: '27.0511000000000000',
      close: '27.8977000000000000',
      volume: '1210841.2500000000000000',
      quoteVolume: 33534708.06565196,
      btcVolume: 3250.687025349346,
      usdVolume: 33534708.06565196,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '27.9033000000000000',
      high: '29.1779000000000000',
      low: '27.6884000000000000',
      close: '28.7931000000000000',
      volume: '1802460.6600000000000000',
      quoteVolume: 51651237.54506001,
      btcVolume: 4855.6186625084,
      usdVolume: 51651237.54506001,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '28.7974000000000000',
      high: '28.8079000000000000',
      low: '27.5200000000000000',
      close: '28.0111000000000000',
      volume: '1230912.5200000000000000',
      quoteVolume: 34656879.16071867,
      btcVolume: 3223.4213999415306,
      usdVolume: 34656879.16071867,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '28.0110000000000000',
      high: '28.0510000000000000',
      low: '26.3000000000000000',
      close: '26.9250000000000000',
      volume: '1007955.5700000000000000',
      quoteVolume: 27333103.0550634,
      btcVolume: 2672.2406391647623,
      usdVolume: 27333103.0550634,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '26.9328000000000000',
      high: '27.3301000000000000',
      low: '26.2110000000000000',
      close: '26.9409000000000000',
      volume: '1087088.9100000000000000',
      quoteVolume: 29207188.464991644,
      btcVolume: 2909.9205678527996,
      usdVolume: 29207188.464991644,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '26.9652000000000000',
      high: '27.2864000000000000',
      low: '26.7400000000000000',
      close: '26.9400000000000000',
      volume: '880521.1800000000000000',
      quoteVolume: 23808910.937024746,
      btcVolume: 2322.2513891613335,
      usdVolume: 23808910.937024746,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '26.9399000000000000',
      high: '26.9586000000000000',
      low: '26.0100000000000000',
      close: '26.2814000000000000',
      volume: '917921.5400000000000000',
      quoteVolume: 24273248.70356496,
      btcVolume: 2390.3516691531204,
      usdVolume: 24273248.70356496,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '26.2982000000000000',
      high: '26.4000000000000000',
      low: '25.5700000000000000',
      close: '26.0888000000000000',
      volume: '933021.8300000000000000',
      quoteVolume: 24320148.965540506,
      btcVolume: 2410.161394681131,
      usdVolume: 24320148.965540506,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '26.0886000000000000',
      high: '27.1908000000000000',
      low: '25.6502000000000000',
      close: '26.1489000000000000',
      volume: '1811039.1700000000000000',
      quoteVolume: 47866074.05944471,
      btcVolume: 4619.226473501923,
      usdVolume: 47866074.05944471,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '26.1251000000000000',
      high: '26.1507000000000000',
      low: '24.6224000000000000',
      close: '25.6051000000000000',
      volume: '1196840.9100000000000000',
      quoteVolume: 30351679.124037657,
      btcVolume: 2975.526344844052,
      usdVolume: 30351679.124037657,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '25.6056000000000000',
      high: '26.1290000000000000',
      low: '23.2030000000000000',
      close: '23.3565000000000000',
      volume: '1398347.3100000000000000',
      quoteVolume: 34916854.38443927,
      btcVolume: 3480.137824867305,
      usdVolume: 34916854.38443927,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '23.3559000000000000',
      high: '23.3559000000000000',
      low: '21.2202000000000000',
      close: '21.7777000000000000',
      volume: '1617357.3700000000000000',
      quoteVolume: 35791217.56290334,
      btcVolume: 3750.9222576047623,
      usdVolume: 35791217.56290334,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '21.7894000000000000',
      high: '22.5678000000000000',
      low: '21.5000000000000000',
      close: '22.1448000000000000',
      volume: '1599602.4900000000000000',
      quoteVolume: 35445343.4393091,
      btcVolume: 3714.583705432022,
      usdVolume: 35445343.4393091,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '22.1449000000000000',
      high: '22.3700000000000000',
      low: '20.8857000000000000',
      close: '21.0604000000000000',
      volume: '1372689.9900000000000000',
      quoteVolume: 29884844.230699934,
      btcVolume: 3119.26041144571,
      usdVolume: 29884844.230699934,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '21.0602000000000000',
      high: '21.6828000000000000',
      low: '20.9800000000000000',
      close: '21.4248000000000000',
      volume: '1434196.5900000000000000',
      quoteVolume: 30594531.66041575,
      btcVolume: 3180.3090835419152,
      usdVolume: 30594531.66041575,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '21.4195000000000000',
      high: '22.8303000000000000',
      low: '21.0100000000000000',
      close: '22.7449000000000000',
      volume: '1743830.8100000000000000',
      quoteVolume: 38331837.52907952,
      btcVolume: 3871.494591953881,
      usdVolume: 38331837.52907952,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '22.7449000000000000',
      high: '22.8900000000000000',
      low: '22.1615000000000000',
      close: '22.3511000000000000',
      volume: '1355171.8400000000000000',
      quoteVolume: 30562302.513001986,
      btcVolume: 2912.5933500976334,
      usdVolume: 30562302.513001986,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '22.3521000000000000',
      high: '22.5998000000000000',
      low: '21.4699000000000000',
      close: '21.6764000000000000',
      volume: '1086116.6600000000000000',
      quoteVolume: 23806043.422344267,
      btcVolume: 2257.8192440245984,
      usdVolume: 23806043.422344267,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '21.6821000000000000',
      high: '23.5213000000000000',
      low: '21.3000000000000000',
      close: '22.6385000000000000',
      volume: '2235402.4400000000000000',
      quoteVolume: 50322754.76862728,
      btcVolume: 4775.140325449655,
      usdVolume: 50322754.76862728,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '22.6389000000000000',
      high: '23.4450000000000000',
      low: '21.6997000000000000',
      close: '21.9905000000000000',
      volume: '1990229.3000000000000000',
      quoteVolume: 44871162.02233011,
      btcVolume: 4221.877198386819,
      usdVolume: 44871162.02233011,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '21.9906000000000000',
      high: '22.6500000000000000',
      low: '21.9100000000000000',
      close: '22.4120000000000000',
      volume: '1681983.7800000000000000',
      quoteVolume: 37521579.67938833,
      btcVolume: 3608.3791504554933,
      usdVolume: 37521579.67938833,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '22.4120000000000000',
      high: '22.7321000000000000',
      low: '22.0000000000000000',
      close: '22.4111000000000000',
      volume: '1304840.1500000000000000',
      quoteVolume: 29335266.506811257,
      btcVolume: 2806.5841675990073,
      usdVolume: 29335266.506811257,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '22.3747000000000000',
      high: '22.4901000000000000',
      low: '21.7400000000000000',
      close: '22.1046000000000000',
      volume: '1006341.0100000000000000',
      quoteVolume: 22302503.142360844,
      btcVolume: 2165.136904136606,
      usdVolume: 22302503.142360844,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '22.0978000000000000',
      high: '22.3262000000000000',
      low: '21.5000000000000000',
      close: '21.7679000000000000',
      volume: '831886.4700000000000000',
      quoteVolume: 18339145.368692745,
      btcVolume: 1792.2664010096803,
      usdVolume: 18339145.368692745,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '21.7704000000000000',
      high: '22.0724000000000000',
      low: '20.5413000000000000',
      close: '20.7334000000000000',
      volume: '1069114.6200000000000000',
      quoteVolume: 22669645.665027037,
      btcVolume: 2253.2292197146694,
      usdVolume: 22669645.665027037,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '20.7315000000000000',
      high: '20.9572000000000000',
      low: '19.6500000000000000',
      close: '20.8197000000000000',
      volume: '1699362.5600000000000000',
      quoteVolume: 34777083.85403479,
      btcVolume: 3420.318759152667,
      usdVolume: 34777083.85403479,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '20.8197000000000000',
      high: '21.2475000000000000',
      low: '20.4088000000000000',
      close: '20.7880000000000000',
      volume: '1429270.9500000000000000',
      quoteVolume: 29787724.15361781,
      btcVolume: 2889.221782175787,
      usdVolume: 29787724.15361781,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '20.7889000000000000',
      high: '21.1500000000000000',
      low: '20.6714000000000000',
      close: '20.9555000000000000',
      volume: '1305740.9000000000000000',
      quoteVolume: 27255071.779076073,
      btcVolume: 2640.2704864370567,
      usdVolume: 27255071.779076073,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '20.9438000000000000',
      high: '21.0419000000000000',
      low: '20.2619000000000000',
      close: '20.2901000000000000',
      volume: '1149167.0800000000000000',
      quoteVolume: 23790454.124340992,
      btcVolume: 2309.5205840680474,
      usdVolume: 23790454.124340992,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '20.2901000000000000',
      high: '20.8000000000000000',
      low: '19.8775000000000000',
      close: '20.2422000000000000',
      volume: '1032883.1400000000000000',
      quoteVolume: 21027752.240456037,
      btcVolume: 2049.967916517665,
      usdVolume: 21027752.240456037,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '20.2460000000000000',
      high: '21.4390000000000000',
      low: '20.0300000000000000',
      close: '21.0473000000000000',
      volume: '1845592.2700000000000000',
      quoteVolume: 38220802.17507817,
      btcVolume: 3744.021260306312,
      usdVolume: 38220802.17507817,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '21.0302000000000000',
      high: '23.1700000000000000',
      low: '20.9442000000000000',
      close: '22.1986000000000000',
      volume: '3302750.2400000000000000',
      quoteVolume: 73063135.11076553,
      btcVolume: 7173.648061693396,
      usdVolume: 73063135.11076553,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '22.1988000000000000',
      high: '22.4383000000000000',
      low: '20.0022000000000000',
      close: '21.5376000000000000',
      volume: '1560199.9000000000000000',
      quoteVolume: 33005910.961343434,
      btcVolume: 3309.6496506288595,
      usdVolume: 33005910.961343434,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '21.5281000000000000',
      high: '21.5764000000000000',
      low: '20.9005000000000000',
      close: '21.2695000000000000',
      volume: '1389940.6700000000000000',
      quoteVolume: 29611689.325407807,
      btcVolume: 2910.330413478063,
      usdVolume: 29611689.325407807,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '21.2741000000000000',
      high: '21.4494000000000000',
      low: '20.8096000000000000',
      close: '20.9666000000000000',
      volume: '1127512.7000000000000000',
      quoteVolume: 23814050.32814106,
      btcVolume: 2369.7781077006107,
      usdVolume: 23814050.32814106,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '20.9732000000000000',
      high: '20.9912000000000000',
      low: '20.0000000000000000',
      close: '20.3602000000000000',
      volume: '972362.6300000000000000',
      quoteVolume: 19879295.326771013,
      btcVolume: 1991.1559276366263,
      usdVolume: 19879295.326771013,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '20.3640000000000000',
      high: '20.3640000000000000',
      low: '19.1614000000000000',
      close: '19.3228000000000000',
      volume: '1094197.9900000000000000',
      quoteVolume: 21719659.651494753,
      btcVolume: 2196.348015116035,
      usdVolume: 21719659.651494753,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '19.3127000000000000',
      high: '19.5000000000000000',
      low: '14.6400000000000000',
      close: '15.8249000000000000',
      volume: '2280940.4000000000000000',
      quoteVolume: 40128590.36126655,
      btcVolume: 4420.050188018196,
      usdVolume: 40128590.36126655,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '15.8240000000000000',
      high: '16.4882000000000000',
      low: '14.8945000000000000',
      close: '16.0321000000000000',
      volume: '1826023.8900000000000000',
      quoteVolume: 28771070.64009279,
      btcVolume: 3404.4134048413493,
      usdVolume: 28771070.64009279,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '16.0138000000000000',
      high: '16.4000000000000000',
      low: '14.2555000000000000',
      close: '15.2212000000000000',
      volume: '1777727.2900000000000000',
      quoteVolume: 27673009.60844759,
      btcVolume: 3368.7647208454014,
      usdVolume: 27673009.60844759,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '15.2113000000000000',
      high: '15.7770000000000000',
      low: '14.7000000000000000',
      close: '15.6666000000000000',
      volume: '1435874.0300000000000000',
      quoteVolume: 21891944.29132878,
      btcVolume: 2721.037106291271,
      usdVolume: 21891944.29132878,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '15.6666000000000000',
      high: '15.8729000000000000',
      low: '15.2911000000000000',
      close: '15.7346000000000000',
      volume: '1097731.6800000000000000',
      quoteVolume: 17158364.84970291,
      btcVolume: 2104.8652182188,
      usdVolume: 17158364.84970291,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '15.7335000000000000',
      high: '15.7953000000000000',
      low: '14.8018000000000000',
      close: '15.1999000000000000',
      volume: '963970.0200000000000000',
      quoteVolume: 14796006.704381201,
      btcVolume: 1833.4019387024855,
      usdVolume: 14796006.704381201,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '15.1870000000000000',
      high: '15.8506000000000000',
      low: '14.6200000000000000',
      close: '15.8349000000000000',
      volume: '1129249.1300000000000000',
      quoteVolume: 17240225.00851707,
      btcVolume: 2144.011528382059,
      usdVolume: 17240225.00851707,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '15.8493000000000000',
      high: '16.2800000000000000',
      low: '15.6295000000000000',
      close: '15.8563000000000000',
      volume: '1180076.1200000000000000',
      quoteVolume: 18822946.423811994,
      btcVolume: 2253.1708840608026,
      usdVolume: 18822946.423811994,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '15.8689000000000000',
      high: '15.8892000000000000',
      low: '15.5000000000000000',
      close: '15.8742000000000000',
      volume: '950281.1200000000000000',
      quoteVolume: 14907087.290238062,
      btcVolume: 1806.9759980656027,
      usdVolume: 14907087.290238062,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '15.8900000000000000',
      high: '15.9290000000000000',
      low: '15.2500000000000000',
      close: '15.6377000000000000',
      volume: '904723.7000000000000000',
      quoteVolume: 14166132.276806083,
      btcVolume: 1718.482479568365,
      usdVolume: 14166132.276806083,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '15.6489000000000000',
      high: '15.8200000000000000',
      low: '15.2100000000000000',
      close: '15.6133000000000000',
      volume: '857614.2600000000000000',
      quoteVolume: 13323519.193896951,
      btcVolume: 1637.9909754976863,
      usdVolume: 13323519.193896951,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '15.6028000000000000',
      high: '15.7794000000000000',
      low: '15.3644000000000000',
      close: '15.6869000000000000',
      volume: '815215.7400000000000000',
      quoteVolume: 12687937.458294004,
      btcVolume: 1565.997866828115,
      usdVolume: 12687937.458294004,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '15.6866000000000000',
      high: '15.7299000000000000',
      low: '14.9100000000000000',
      close: '15.0688000000000000',
      volume: '814844.4500000000000000',
      quoteVolume: 12466458.898545044,
      btcVolume: 1563.9266798482508,
      usdVolume: 12466458.898545044,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '15.0655000000000000',
      high: '16.1280000000000000',
      low: '14.8555000000000000',
      close: '16.0030000000000000',
      volume: '1137667.6700000000000000',
      quoteVolume: 17614353.686007068,
      btcVolume: 2197.8583870903244,
      usdVolume: 17614353.686007068,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '16.0198000000000000',
      high: '16.4007000000000000',
      low: '15.7959000000000000',
      close: '16.0213000000000000',
      volume: '992780.4300000000000000',
      quoteVolume: 15889938.956474038,
      btcVolume: 1934.7114076545115,
      usdVolume: 15889938.956474038,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '16.0206000000000000',
      high: '17.9999000000000000',
      low: '15.8829000000000000',
      close: '17.7100000000000000',
      volume: '2809202.1000000000000000',
      quoteVolume: 47844345.02730628,
      btcVolume: 5781.710685310098,
      usdVolume: 47844345.02730628,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '17.7172000000000000',
      high: '17.9180000000000000',
      low: '17.0310000000000000',
      close: '17.5300000000000000',
      volume: '1785749.3400000000000000',
      quoteVolume: 31222666.861801084,
      btcVolume: 3655.173159325509,
      usdVolume: 31222666.861801084,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '17.5288000000000000',
      high: '17.7000000000000000',
      low: '16.5100000000000000',
      close: '16.5656000000000000',
      volume: '1476429.8400000000000000',
      quoteVolume: 25136224.21603257,
      btcVolume: 2982.0542530942034,
      usdVolume: 25136224.21603257,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '16.5665000000000000',
      high: '17.4868000000000000',
      low: '16.5059000000000000',
      close: '17.1700000000000000',
      volume: '1405829.2300000000000000',
      quoteVolume: 23921395.579711266,
      btcVolume: 2872.116767496237,
      usdVolume: 23921395.579711266,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '17.1666000000000000',
      high: '18.7699000000000000',
      low: '17.1110000000000000',
      close: '18.2560000000000000',
      volume: '2233894.4300000000000000',
      quoteVolume: 40107522.71327606,
      btcVolume: 4802.447899917144,
      usdVolume: 40107522.71327606,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '18.2560000000000000',
      high: '18.6275000000000000',
      low: '17.8511000000000000',
      close: '18.4376000000000000',
      volume: '1643019.3600000000000000',
      quoteVolume: 29971983.59315997,
      btcVolume: 3613.0751251258484,
      usdVolume: 29971983.59315997,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '18.4389000000000000',
      high: '19.1700000000000000',
      low: '18.1000000000000000',
      close: '18.5697000000000000',
      volume: '2913060.4200000000000000',
      quoteVolume: 54382456.61385796,
      btcVolume: 6569.711201030303,
      usdVolume: 54382456.61385796,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '18.5697000000000000',
      high: '18.6470000000000000',
      low: '17.3627000000000000',
      close: '17.7401000000000000',
      volume: '1815021.0600000000000000',
      quoteVolume: 32739450.98535026,
      btcVolume: 4055.212668955555,
      usdVolume: 32739450.98535026,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '17.7504000000000000',
      high: '18.8346000000000000',
      low: '17.5620000000000000',
      close: '18.5188000000000000',
      volume: '1889272.6600000000000000',
      quoteVolume: 34608013.92635818,
      btcVolume: 4311.318414716609,
      usdVolume: 34608013.92635818,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '18.5205000000000000',
      high: '18.7501000000000000',
      low: '17.6733000000000000',
      close: '18.2188000000000000',
      volume: '1427724.2100000000000000',
      quoteVolume: 26053677.954359047,
      btcVolume: 3266.035239146559,
      usdVolume: 26053677.954359047,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '18.2188000000000000',
      high: '18.4444000000000000',
      low: '17.8575000000000000',
      close: '18.2229000000000000',
      volume: '1457872.5200000000000000',
      quoteVolume: 26457012.36290216,
      btcVolume: 3326.33246552125,
      usdVolume: 26457012.36290216,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '18.2189000000000000',
      high: '18.5800000000000000',
      low: '17.7152000000000000',
      close: '18.4997000000000000',
      volume: '1696662.0200000000000000',
      quoteVolume: 30829354.6997274,
      btcVolume: 3832.375444981121,
      usdVolume: 30829354.6997274,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '18.4897000000000000',
      high: '18.5171000000000000',
      low: '17.9100000000000000',
      close: '18.2560000000000000',
      volume: '1497274.8700000000000000',
      quoteVolume: 27204985.86214098,
      btcVolume: 3313.6879489931994,
      usdVolume: 27204985.86214098,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '18.2551000000000000',
      high: '18.8000000000000000',
      low: '18.0692000000000000',
      close: '18.2286000000000000',
      volume: '1819185.3800000000000000',
      quoteVolume: 33532586.548880003,
      btcVolume: 4093.9879290171616,
      usdVolume: 33532586.548880003,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '18.2581000000000000',
      high: '18.2700000000000000',
      low: '16.2111000000000000',
      close: '16.6796000000000000',
      volume: '1735472.3000000000000000',
      quoteVolume: 30019967.217108868,
      btcVolume: 3879.4704562633056,
      usdVolume: 30019967.217108868,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '16.6798000000000000',
      high: '17.0987000000000000',
      low: '16.3100000000000000',
      close: '16.9194000000000000',
      volume: '1244966.9900000000000000',
      quoteVolume: 20790631.76627608,
      btcVolume: 2794.787971493176,
      usdVolume: 20790631.76627608,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '16.9194000000000000',
      high: '19.1110000000000000',
      low: '16.7516000000000000',
      close: '18.6188000000000000',
      volume: '2770630.2600000000000000',
      quoteVolume: 49737182.561813205,
      btcVolume: 6263.872839719397,
      usdVolume: 49737182.561813205,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '18.6189000000000000',
      high: '19.7653000000000000',
      low: '18.0308000000000000',
      close: '18.9501000000000000',
      volume: '3038020.4400000000000000',
      quoteVolume: 57490280.69272188,
      btcVolume: 6119.3718702883,
      usdVolume: 57490280.69272188,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '18.9311000000000000',
      high: '19.6300000000000000',
      low: '18.4744000000000000',
      close: '19.2888000000000000',
      volume: '2292790.2600000000000000',
      quoteVolume: 43689050.22110826,
      btcVolume: 4656.735220807045,
      usdVolume: 43689050.22110826,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '19.2938000000000000',
      high: '21.1500000000000000',
      low: '19.2600000000000000',
      close: '19.9390000000000000',
      volume: '2712590.2200000000000000',
      quoteVolume: 54705192.09512571,
      btcVolume: 5746.423873513421,
      usdVolume: 54705192.09512571,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '19.9379000000000000',
      high: '21.2378000000000000',
      low: '19.8100000000000000',
      close: '20.7452000000000000',
      volume: '2287705.3800000000000000',
      quoteVolume: 47070804.28249447,
      btcVolume: 5028.784777580716,
      usdVolume: 47070804.28249447,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '20.7300000000000000',
      high: '20.7975000000000000',
      low: '19.1076000000000000',
      close: '20.0520000000000000',
      volume: '1822916.4300000000000000',
      quoteVolume: 36219179.39048555,
      btcVolume: 3946.264713227161,
      usdVolume: 36219179.39048555,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '20.0500000000000000',
      high: '20.3700000000000000',
      low: '19.3529000000000000',
      close: '19.9099000000000000',
      volume: '1677653.1800000000000000',
      quoteVolume: 33444789.194522977,
      btcVolume: 3654.9820249353547,
      usdVolume: 33444789.194522977,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '19.9087000000000000',
      high: '20.0399000000000000',
      low: '19.6424000000000000',
      close: '19.9811000000000000',
      volume: '1304915.2800000000000000',
      quoteVolume: 25929970.432292167,
      btcVolume: 2837.8645933661815,
      usdVolume: 25929970.432292167,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '19.9988000000000000',
      high: '20.3000000000000000',
      low: '19.9600000000000000',
      close: '20.1745000000000000',
      volume: '1604349.3200000000000000',
      quoteVolume: 32341714.859536927,
      btcVolume: 3491.3146563665596,
      usdVolume: 32341714.859536927,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '20.1847000000000000',
      high: '20.3900000000000000',
      low: '19.8053000000000000',
      close: '20.1874000000000000',
      volume: '1331174.6000000000000000',
      quoteVolume: 26791642.74283898,
      btcVolume: 2906.420761934206,
      usdVolume: 26791642.74283898,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '20.1725000000000000',
      high: '20.8800000000000000',
      low: '20.0285000000000000',
      close: '20.6139000000000000',
      volume: '1638295.2000000000000000',
      quoteVolume: 33568729.35200188,
      btcVolume: 3633.9058084230064,
      usdVolume: 33568729.35200188,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '20.6139000000000000',
      high: '20.8900000000000000',
      low: '20.3200000000000000',
      close: '20.6588000000000000',
      volume: '1435673.9200000000000000',
      quoteVolume: 29643945.41316726,
      btcVolume: 3174.3640255474643,
      usdVolume: 29643945.41316726,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '20.6621000000000000',
      high: '20.9399000000000000',
      low: '20.4408000000000000',
      close: '20.7000000000000000',
      volume: '1304183.9900000000000000',
      quoteVolume: 26943504.690265935,
      btcVolume: 2886.089792710133,
      usdVolume: 26943504.690265935,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '20.6993000000000000',
      high: '20.8490000000000000',
      low: '19.9100000000000000',
      close: '20.4208000000000000',
      volume: '1034081.1100000000000000',
      quoteVolume: 21112048.776744843,
      btcVolume: 2279.6955633595517,
      usdVolume: 21112048.776744843,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '20.4342000000000000',
      high: '20.5164000000000000',
      low: '19.2609000000000000',
      close: '19.6460000000000000',
      volume: '1081524.1000000000000000',
      quoteVolume: 21620174.59220783,
      btcVolume: 2392.7004682323113,
      usdVolume: 21620174.59220783,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '19.6460000000000000',
      high: '19.9632000000000000',
      low: '19.4626000000000000',
      close: '19.6999000000000000',
      volume: '886139.1000000000000000',
      quoteVolume: 17507369.511074036,
      btcVolume: 1985.1423284212408,
      usdVolume: 17507369.511074036,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '19.7042000000000000',
      high: '20.6000000000000000',
      low: '19.6694000000000000',
      close: '20.4333000000000000',
      volume: '1278095.9300000000000000',
      quoteVolume: 25754570.998597786,
      btcVolume: 2901.93943637929,
      usdVolume: 25754570.998597786,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '20.4323000000000000',
      high: '20.6277000000000000',
      low: '19.7497000000000000',
      close: '20.0888000000000000',
      volume: '1002364.6600000000000000',
      quoteVolume: 20292034.237837035,
      btcVolume: 2289.625297028486,
      usdVolume: 20292034.237837035,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '20.0977000000000000',
      high: '20.9798000000000000',
      low: '19.9443000000000000',
      close: '20.9798000000000000',
      volume: '1235499.6000000000000000',
      quoteVolume: 25207219.032343943,
      btcVolume: 2881.138559153625,
      usdVolume: 25207219.032343943,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '20.9797000000000000',
      high: '21.8000000000000000',
      low: '20.6800000000000000',
      close: '21.3846000000000000',
      volume: '1369703.6200000000000000',
      quoteVolume: 29120752.930271562,
      btcVolume: 3322.2398970219933,
      usdVolume: 29120752.930271562,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '21.3883000000000000',
      high: '21.6492000000000000',
      low: '20.7111000000000000',
      close: '21.3166000000000000',
      volume: '1177353.8200000000000000',
      quoteVolume: 24991032.071327083,
      btcVolume: 2875.553521588978,
      usdVolume: 24991032.071327083,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '21.3165000000000000',
      high: '21.3353000000000000',
      low: '19.9614000000000000',
      close: '20.2735000000000000',
      volume: '1358774.2500000000000000',
      quoteVolume: 27979389.54651814,
      btcVolume: 3269.53297184289,
      usdVolume: 27979389.54651814,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '20.2871000000000000',
      high: '20.5999000000000000',
      low: '19.9000000000000000',
      close: '20.1631000000000000',
      volume: '1001272.5400000000000000',
      quoteVolume: 20291968.77348383,
      btcVolume: 2394.1821660160967,
      usdVolume: 20291968.77348383,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '20.1641000000000000',
      high: '20.4359000000000000',
      low: '19.7500000000000000',
      close: '20.1888000000000000',
      volume: '1153032.2600000000000000',
      quoteVolume: 23183744.303397186,
      btcVolume: 2722.9127437014527,
      usdVolume: 23183744.303397186,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '20.1894000000000000',
      high: '20.1940000000000000',
      low: '18.6000000000000000',
      close: '19.0817000000000000',
      volume: '1368453.1000000000000000',
      quoteVolume: 26728303.31546702,
      btcVolume: 3188.792085551649,
      usdVolume: 26728303.31546702,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '19.0817000000000000',
      high: '19.2800000000000000',
      low: '18.0800000000000000',
      close: '18.4997000000000000',
      volume: '1218553.0600000000000000',
      quoteVolume: 22711534.818970643,
      btcVolume: 2795.312906394973,
      usdVolume: 22711534.818970643,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '18.4999000000000000',
      high: '18.7494000000000000',
      low: '17.7738000000000000',
      close: '18.0826000000000000',
      volume: '1209633.0100000000000000',
      quoteVolume: 22221973.60810997,
      btcVolume: 2739.6584331838912,
      usdVolume: 22221973.60810997,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '18.0827000000000000',
      high: '18.2399000000000000',
      low: '16.3400000000000000',
      close: '16.7985000000000000',
      volume: '1715376.7100000000000000',
      quoteVolume: 29495384.97432084,
      btcVolume: 3764.2748558580925,
      usdVolume: 29495384.97432084,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '16.7986000000000000',
      high: '17.2798000000000000',
      low: '14.2846000000000000',
      close: '15.4875000000000000',
      volume: '3355659.6400000000000000',
      quoteVolume: 52944056.290057965,
      btcVolume: 7292.508377878102,
      usdVolume: 52944056.290057965,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '15.4767000000000000',
      high: '16.3483000000000000',
      low: '14.9570000000000000',
      close: '16.2320000000000000',
      volume: '1941896.4600000000000000',
      quoteVolume: 30375854.714530647,
      btcVolume: 4197.788544615611,
      usdVolume: 30375854.714530647,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '16.2321000000000000',
      high: '16.5060000000000000',
      low: '14.9000000000000000',
      close: '14.9964000000000000',
      volume: '2155053.8800000000000000',
      quoteVolume: 34080052.57061797,
      btcVolume: 4773.880226329648,
      usdVolume: 34080052.57061797,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '15.0152000000000000',
      high: '15.7600000000000000',
      low: '13.8800000000000000',
      close: '15.1457000000000000',
      volume: '3063415.2800000000000000',
      quoteVolume: 45713597.39123177,
      btcVolume: 6615.167109750736,
      usdVolume: 45713597.39123177,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '15.1466000000000000',
      high: '15.8900000000000000',
      low: '14.9382000000000000',
      close: '15.3564000000000000',
      volume: '1658330.7900000000000000',
      quoteVolume: 25501834.969267953,
      btcVolume: 3567.6079268195067,
      usdVolume: 25501834.969267953,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '15.3565000000000000',
      high: '16.2255000000000000',
      low: '14.5811000000000000',
      close: '16.0888000000000000',
      volume: '2405778.6200000000000000',
      quoteVolume: 37268429.1905796,
      btcVolume: 5141.099123684343,
      usdVolume: 37268429.1905796,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '16.0888000000000000',
      high: '16.1899000000000000',
      low: '15.4404000000000000',
      close: '15.5333000000000000',
      volume: '1619845.5800000000000000',
      quoteVolume: 25668886.932569165,
      btcVolume: 3417.447648852904,
      usdVolume: 25668886.932569165,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '15.5255000000000000',
      high: '16.2998000000000000',
      low: '15.4692000000000000',
      close: '16.2281000000000000',
      volume: '2034007.5200000000000000',
      quoteVolume: 32409441.241127864,
      btcVolume: 4248.276128127099,
      usdVolume: 32409441.241127864,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '16.2281000000000000',
      high: '16.2765000000000000',
      low: '15.4501000000000000',
      close: '15.7118000000000000',
      volume: '1435716.2100000000000000',
      quoteVolume: 22780843.578936126,
      btcVolume: 2979.5426085168438,
      usdVolume: 22780843.578936126,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '15.7030000000000000',
      high: '15.7139000000000000',
      low: '14.8000000000000000',
      close: '15.4297000000000000',
      volume: '1232843.2000000000000000',
      quoteVolume: 18823975.185732864,
      btcVolume: 2559.4840004958196,
      usdVolume: 18823975.185732864,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '15.4287000000000000',
      high: '15.6200000000000000',
      low: '15.0190000000000000',
      close: '15.1288000000000000',
      volume: '1205451.5000000000000000',
      quoteVolume: 18412700.208588973,
      btcVolume: 2519.5182703419514,
      usdVolume: 18412700.208588973,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '15.1394000000000000',
      high: '15.4255000000000000',
      low: '15.0475000000000000',
      close: '15.2542000000000000',
      volume: '1260422.8200000000000000',
      quoteVolume: 19241970.49272903,
      btcVolume: 2629.721760674202,
      usdVolume: 19241970.49272903,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '15.2545000000000000',
      high: '15.7511000000000000',
      low: '14.8275000000000000',
      close: '15.1000000000000000',
      volume: '1448353.5900000000000000',
      quoteVolume: 22125483.77934104,
      btcVolume: 3029.126283546053,
      usdVolume: 22125483.77934104,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '15.1254000000000000',
      high: '15.7200000000000000',
      low: '14.8060000000000000',
      close: '15.6594000000000000',
      volume: '1702989.3100000000000000',
      quoteVolume: 26185398.260484762,
      btcVolume: 3580.017154853113,
      usdVolume: 26185398.260484762,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '15.6554000000000000',
      high: '15.7498000000000000',
      low: '15.3000000000000000',
      close: '15.6666000000000000',
      volume: '1425210.7900000000000000',
      quoteVolume: 22138436.21785305,
      btcVolume: 2987.805804791008,
      usdVolume: 22138436.21785305,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '15.6570000000000000',
      high: '15.8199000000000000',
      low: '15.5190000000000000',
      close: '15.6067000000000000',
      volume: '1249702.2900000000000000',
      quoteVolume: 19570141.810453042,
      btcVolume: 2601.8723228509753,
      usdVolume: 19570141.810453042,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '15.6066000000000000',
      high: '15.7200000000000000',
      low: '15.3496000000000000',
      close: '15.6121000000000000',
      volume: '1097588.2900000000000000',
      quoteVolume: 17074933.996354897,
      btcVolume: 2281.4706372298097,
      usdVolume: 17074933.996354897,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '15.5947000000000000',
      high: '15.7990000000000000',
      low: '15.1838000000000000',
      close: '15.3284000000000000',
      volume: '952736.1300000000000000',
      quoteVolume: 14820153.790239785,
      btcVolume: 1984.031553870555,
      usdVolume: 14820153.790239785,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '15.3284000000000000',
      high: '15.3454000000000000',
      low: '14.6000000000000000',
      close: '14.8096000000000000',
      volume: '1001509.5100000000000000',
      quoteVolume: 15034745.741738938,
      btcVolume: 2055.8667526296367,
      usdVolume: 15034745.741738938,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '14.8085000000000000',
      high: '14.9984000000000000',
      low: '14.6000000000000000',
      close: '14.7800000000000000',
      volume: '803776.6500000000000000',
      quoteVolume: 11916428.093796976,
      btcVolume: 1650.5866045607136,
      usdVolume: 11916428.093796976,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '14.7800000000000000',
      high: '14.8495000000000000',
      low: '14.3500000000000000',
      close: '14.7238000000000000',
      volume: '740738.6700000000000000',
      quoteVolume: 10828113.391639946,
      btcVolume: 1508.9196509223818,
      usdVolume: 10828113.391639946,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '14.7165000000000000',
      high: '14.9775000000000000',
      low: '14.6600000000000000',
      close: '14.8555000000000000',
      volume: '883103.0900000000000000',
      quoteVolume: 13061294.686844045,
      btcVolume: 1805.6559930105461,
      usdVolume: 13061294.686844045,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '14.8555000000000000',
      high: '15.0000000000000000',
      low: '14.1629000000000000',
      close: '14.3888000000000000',
      volume: '841038.8200000000000000',
      quoteVolume: 12317989.087613093,
      btcVolume: 1717.5495142247462,
      usdVolume: 12317989.087613093,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '14.3887000000000000',
      high: '14.4930000000000000',
      low: '14.1361000000000000',
      close: '14.3949000000000000',
      volume: '786465.1500000000000000',
      quoteVolume: 11267335.87640899,
      btcVolume: 1588.1571392617413,
      usdVolume: 11267335.87640899,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '14.3946000000000000',
      high: '14.4356000000000000',
      low: '13.2580000000000000',
      close: '13.4544000000000000',
      volume: '1091047.0100000000000000',
      quoteVolume: 15235222.031920152,
      btcVolume: 2170.523777622476,
      usdVolume: 15235222.031920152,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '13.4721000000000000',
      high: '13.4750000000000000',
      low: '12.1111000000000000',
      close: '12.3810000000000000',
      volume: '1681588.6700000000000000',
      quoteVolume: 21579880.298549183,
      btcVolume: 3181.966603781795,
      usdVolume: 21579880.298549183,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '12.3837000000000000',
      high: '13.7348000000000000',
      low: '12.2100000000000000',
      close: '13.6158000000000000',
      volume: '2321743.1800000000000000',
      quoteVolume: 29939618.62193826,
      btcVolume: 4400.7711891586205,
      usdVolume: 29939618.62193826,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '13.6247000000000000',
      high: '13.7631000000000000',
      low: '12.9624000000000000',
      close: '13.3441000000000000',
      volume: '1378005.7000000000000000',
      quoteVolume: 18297729.627122004,
      btcVolume: 2555.9462004711754,
      usdVolume: 18297729.627122004,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '13.3519000000000000',
      high: '13.5291000000000000',
      low: '13.0800000000000000',
      close: '13.5000000000000000',
      volume: '1397599.9900000000000000',
      quoteVolume: 18629001.250787843,
      btcVolume: 2608.2124018163277,
      usdVolume: 18629001.250787843,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '13.4932000000000000',
      high: '13.5100000000000000',
      low: '13.2600000000000000',
      close: '13.2933000000000000',
      volume: '1260784.8500000000000000',
      quoteVolume: 16846058.069898106,
      btcVolume: 2357.3199262431112,
      usdVolume: 16846058.069898106,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '13.2874000000000000',
      high: '13.8309000000000000',
      low: '13.2689000000000000',
      close: '13.7798000000000000',
      volume: '1436306.2900000000000000',
      quoteVolume: 19378426.182353877,
      btcVolume: 2671.594975672879,
      usdVolume: 19378426.182353877,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '13.7768000000000000',
      high: '13.9447000000000000',
      low: '13.2000000000000000',
      close: '13.2998000000000000',
      volume: '1226252.0100000000000000',
      quoteVolume: 16842774.154331747,
      btcVolume: 2236.6478855031783,
      usdVolume: 16842774.154331747,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '13.2987000000000000',
      high: '13.5916000000000000',
      low: '13.2299000000000000',
      close: '13.3261000000000000',
      volume: '1306914.4800000000000000',
      quoteVolume: 17482280.272196006,
      btcVolume: 2396.4134118510615,
      usdVolume: 17482280.272196006,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '13.3340000000000000',
      high: '13.3522000000000000',
      low: '12.8500000000000000',
      close: '13.0666000000000000',
      volume: '923319.5400000000000000',
      quoteVolume: 12154298.537538983,
      btcVolume: 1682.9049427550863,
      usdVolume: 12154298.537538983,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '13.0665000000000000',
      high: '13.5466000000000000',
      low: '13.0230000000000000',
      close: '13.4952000000000000',
      volume: '1056537.7800000000000000',
      quoteVolume: 14002514.230783973,
      btcVolume: 1930.7821358732667,
      usdVolume: 14002514.230783973,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '13.1349000000000000',
      high: '13.2981000000000000',
      low: '12.8927000000000000',
      close: '13.2888000000000000',
      volume: '1109904.6900000000000000',
      quoteVolume: 14633137.803384082,
      btcVolume: 2029.5574032953941,
      usdVolume: 14633137.803384082,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '13.2888000000000000',
      high: '13.7200000000000000',
      low: '13.2569000000000000',
      close: '13.6779000000000000',
      volume: '1358080.6500000000000000',
      quoteVolume: 18404654.469618995,
      btcVolume: 2515.9847764068354,
      usdVolume: 18404654.469618995,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '13.6788000000000000',
      high: '14.2186000000000000',
      low: '13.5750000000000000',
      close: '14.1040000000000000',
      volume: '1608471.4600000000000000',
      quoteVolume: 22338596.36302602,
      btcVolume: 3032.599269002952,
      usdVolume: 22338596.36302602,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '14.0984000000000000',
      high: '14.3796000000000000',
      low: '13.7800000000000000',
      close: '13.8597000000000000',
      volume: '1255186.2700000000000000',
      quoteVolume: 17736371.93092798,
      btcVolume: 2418.5750608115254,
      usdVolume: 17736371.93092798,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '13.8597000000000000',
      high: '14.0564000000000000',
      low: '13.6400000000000000',
      close: '13.7161000000000000',
      volume: '970000.0200000000000000',
      quoteVolume: 13410409.01565197,
      btcVolume: 1853.0878040366188,
      usdVolume: 13410409.01565197,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '13.7159000000000000',
      high: '13.8692000000000000',
      low: '13.6500000000000000',
      close: '13.7184000000000000',
      volume: '940273.7700000000000000',
      quoteVolume: 12945796.344635054,
      btcVolume: 1793.7739920087274,
      usdVolume: 12945796.344635054,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '13.7072000000000000',
      high: '13.7302000000000000',
      low: '12.9800000000000000',
      close: '13.0105000000000000',
      volume: '960515.6000000000000000',
      quoteVolume: 12855590.844871968,
      btcVolume: 1810.6692341853307,
      usdVolume: 12855590.844871968,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '13.0104000000000000',
      high: '13.7500000000000000',
      low: '12.9801000000000000',
      close: '13.6474000000000000',
      volume: '1458213.2600000000000000',
      quoteVolume: 19697994.93713138,
      btcVolume: 2725.85101080842,
      usdVolume: 19697994.93713138,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '13.6481000000000000',
      high: '13.8710000000000000',
      low: '13.5102000000000000',
      close: '13.8092000000000000',
      volume: '1231268.3700000000000000',
      quoteVolume: 16885513.379274156,
      btcVolume: 2302.0692057035767,
      usdVolume: 16885513.379274156,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '13.7978000000000000',
      high: '14.3133000000000000',
      low: '13.7910000000000000',
      close: '14.0532000000000000',
      volume: '1458003.0200000000000000',
      quoteVolume: 20531817.041770168,
      btcVolume: 2760.741171838204,
      usdVolume: 20531817.041770168,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '14.0488000000000000',
      high: '14.9873000000000000',
      low: '14.0168000000000000',
      close: '14.9471000000000000',
      volume: '1633712.0400000000000000',
      quoteVolume: 23743495.229042225,
      btcVolume: 3144.9834827722107,
      usdVolume: 23743495.229042225,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '14.9427000000000000',
      high: '15.1870000000000000',
      low: '14.4560000000000000',
      close: '15.0226000000000000',
      volume: '1458769.7000000000000000',
      quoteVolume: 21730397.146320045,
      btcVolume: 2741.5005004584696,
      usdVolume: 21730397.146320045,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '15.0212000000000000',
      high: '15.3200000000000000',
      low: '14.2000000000000000',
      close: '14.5254000000000000',
      volume: '1444209.0300000000000000',
      quoteVolume: 21394481.601980902,
      btcVolume: 2593.189647193552,
      usdVolume: 21394481.601980902,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '14.5308000000000000',
      high: '14.5823000000000000',
      low: '14.2010000000000000',
      close: '14.3978000000000000',
      volume: '1052935.6900000000000000',
      quoteVolume: 15196486.221940989,
      btcVolume: 1918.9735468286913,
      usdVolume: 15196486.221940989,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '14.3806000000000000',
      high: '15.0973000000000000',
      low: '14.1100000000000000',
      close: '15.0700000000000000',
      volume: '1572079.7800000000000000',
      quoteVolume: 23078267.45961109,
      btcVolume: 2897.5210314858323,
      usdVolume: 23078267.45961109,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '15.0643000000000000',
      high: '15.3200000000000000',
      low: '14.7111000000000000',
      close: '14.9177000000000000',
      volume: '1397398.3000000000000000',
      quoteVolume: 20959042.697335042,
      btcVolume: 2577.9974693735912,
      usdVolume: 20959042.697335042,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '14.9137000000000000',
      high: '15.4707000000000000',
      low: '14.8001000000000000',
      close: '15.3366000000000000',
      volume: '2042556.1700000000000000',
      quoteVolume: 31106160.735202186,
      btcVolume: 3830.745845979114,
      usdVolume: 31106160.735202186,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '15.3370000000000000',
      high: '15.3572000000000000',
      low: '14.9295000000000000',
      close: '15.1736000000000000',
      volume: '1520954.5900000000000000',
      quoteVolume: 22965408.692243036,
      btcVolume: 2830.7449498311053,
      usdVolume: 22965408.692243036,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '15.1735000000000000',
      high: '16.9100000000000000',
      low: '15.1659000000000000',
      close: '16.6371000000000000',
      volume: '2711583.2200000000000000',
      quoteVolume: 43167140.88381576,
      btcVolume: 5029.673767491132,
      usdVolume: 43167140.88381576,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '16.6301000000000000',
      high: '18.1900000000000000',
      low: '16.2700000000000000',
      close: '17.6655000000000000',
      volume: '3040382.6900000000000000',
      quoteVolume: 52587124.739194795,
      btcVolume: 5996.84247707859,
      usdVolume: 52587124.739194795,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '17.6655000000000000',
      high: '17.6923000000000000',
      low: '16.3100000000000000',
      close: '17.0333000000000000',
      volume: '1842017.2900000000000000',
      quoteVolume: 31029984.91111983,
      btcVolume: 3570.093648897622,
      usdVolume: 31029984.91111983,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '17.0400000000000000',
      high: '18.4849000000000000',
      low: '17.0400000000000000',
      close: '18.0724000000000000',
      volume: '2734425.7700000000000000',
      quoteVolume: 48859738.857078,
      btcVolume: 5520.430319295519,
      usdVolume: 48859738.857078,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '18.0763000000000000',
      high: '18.3602000000000000',
      low: '17.4500000000000000',
      close: '17.8088000000000000',
      volume: '1903073.6300000000000000',
      quoteVolume: 34104864.132670745,
      btcVolume: 3831.157825228501,
      usdVolume: 34104864.132670745,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '17.7954000000000000',
      high: '18.4950000000000000',
      low: '16.6666000000000000',
      close: '17.3880000000000000',
      volume: '2127422.8500000000000000',
      quoteVolume: 37440202.05314928,
      btcVolume: 4223.113876587941,
      usdVolume: 37440202.05314928,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '17.3706000000000000',
      high: '17.5516000000000000',
      low: '16.8043000000000000',
      close: '17.3235000000000000',
      volume: '1576499.0800000000000000',
      quoteVolume: 27136151.86145567,
      btcVolume: 3135.1916814614,
      usdVolume: 27136151.86145567,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '17.3265000000000000',
      high: '18.2000000000000000',
      low: '17.2228000000000000',
      close: '18.1166000000000000',
      volume: '1923914.5000000000000000',
      quoteVolume: 33973360.84587211,
      btcVolume: 3922.624684198108,
      usdVolume: 33973360.84587211,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '18.1107000000000000',
      high: '18.4000000000000000',
      low: '17.5500000000000000',
      close: '17.8344000000000000',
      volume: '1607749.7800000000000000',
      quoteVolume: 28997476.09421225,
      btcVolume: 3333.354464634478,
      usdVolume: 28997476.09421225,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '17.8344000000000000',
      high: '17.8344000000000000',
      low: '16.7441000000000000',
      close: '17.0524000000000000',
      volume: '1363632.7900000000000000',
      quoteVolume: 23479710.346752085,
      btcVolume: 2763.2312364747722,
      usdVolume: 23479710.346752085,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '17.0813000000000000',
      high: '17.4429000000000000',
      low: '16.4288000000000000',
      close: '17.1148000000000000',
      volume: '1411501.9800000000000000',
      quoteVolume: 23870035.10373168,
      btcVolume: 2846.8695971312045,
      usdVolume: 23870035.10373168,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '17.1133000000000000',
      high: '17.1215000000000000',
      low: '16.5685000000000000',
      close: '16.9555000000000000',
      volume: '1211416.3200000000000000',
      quoteVolume: 20396383.54308985,
      btcVolume: 2446.212569813146,
      usdVolume: 20396383.54308985,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '16.9419000000000000',
      high: '17.4990000000000000',
      low: '16.8315000000000000',
      close: '17.4990000000000000',
      volume: '1619344.7500000000000000',
      quoteVolume: 27866656.71784081,
      btcVolume: 3293.769502538616,
      usdVolume: 27866656.71784081,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '17.4919000000000000',
      high: '17.9500000000000000',
      low: '17.2198000000000000',
      close: '17.6700000000000000',
      volume: '1562998.8400000000000000',
      quoteVolume: 27448591.22210781,
      btcVolume: 3140.819387866112,
      usdVolume: 27448591.22210781,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '17.6700000000000000',
      high: '18.1600000000000000',
      low: '17.4466000000000000',
      close: '18.0662000000000000',
      volume: '1916526.0700000000000000',
      quoteVolume: 34097546.051095806,
      btcVolume: 3754.0928695235057,
      usdVolume: 34097546.051095806,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '18.0614000000000000',
      high: '18.4437000000000000',
      low: '17.8090000000000000',
      close: '17.9134000000000000',
      volume: '1447265.2000000000000000',
      quoteVolume: 26231426.254242063,
      btcVolume: 2807.684884866969,
      usdVolume: 26231426.254242063,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '17.9296000000000000',
      high: '18.7027000000000000',
      low: '17.7000000000000000',
      close: '18.5555000000000000',
      volume: '1787521.2200000000000000',
      quoteVolume: 32487717.892014977,
      btcVolume: 3454.6241320377576,
      usdVolume: 32487717.892014977,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '18.5557000000000000',
      high: '18.8800000000000000',
      low: '17.8000000000000000',
      close: '18.2644000000000000',
      volume: '1647399.0800000000000000',
      quoteVolume: 30233383.52456968,
      btcVolume: 3224.3985404614446,
      usdVolume: 30233383.52456968,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '18.2644000000000000',
      high: '18.5956000000000000',
      low: '18.0300000000000000',
      close: '18.2752000000000000',
      volume: '1511588.8600000000000000',
      quoteVolume: 27611547.000338193,
      btcVolume: 2942.137716907503,
      usdVolume: 27611547.000338193,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '18.2753000000000000',
      high: '18.7714000000000000',
      low: '17.8277000000000000',
      close: '18.4333000000000000',
      volume: '1558716.5200000000000000',
      quoteVolume: 28652209.626673575,
      btcVolume: 3053.4044339388865,
      usdVolume: 28652209.626673575,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '18.4333000000000000',
      high: '18.8763000000000000',
      low: '18.1959000000000000',
      close: '18.4600000000000000',
      volume: '1314693.1200000000000000',
      quoteVolume: 24235415.42380266,
      btcVolume: 2588.7478758068564,
      usdVolume: 24235415.42380266,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '18.4610000000000000',
      high: '18.6158000000000000',
      low: '17.8700000000000000',
      close: '18.1778000000000000',
      volume: '1215556.3700000000000000',
      quoteVolume: 22148996.309246045,
      btcVolume: 2399.6608902337066,
      usdVolume: 22148996.309246045,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '18.1778000000000000',
      high: '19.4583000000000000',
      low: '18.1635000000000000',
      close: '19.2620000000000000',
      volume: '2068915.0400000000000000',
      quoteVolume: 39153896.76314988,
      btcVolume: 4174.003818806791,
      usdVolume: 39153896.76314988,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '19.2686000000000000',
      high: '20.8400000000000000',
      low: '19.0300000000000000',
      close: '20.6675000000000000',
      volume: '2770774.3100000000000000',
      quoteVolume: 55407991.63401992,
      btcVolume: 5699.561189798247,
      usdVolume: 55407991.63401992,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '20.6665000000000000',
      high: '22.5221000000000000',
      low: '20.5199000000000000',
      close: '22.0555000000000000',
      volume: '3182382.8900000000000000',
      quoteVolume: 68795504.22441779,
      btcVolume: 7022.0759772449155,
      usdVolume: 68795504.22441779,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '22.0360000000000000',
      high: '22.1900000000000000',
      low: '20.8943000000000000',
      close: '21.7888000000000000',
      volume: '2208642.4000000000000000',
      quoteVolume: 47849193.68056081,
      btcVolume: 4879.389335821138,
      usdVolume: 47849193.68056081,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '21.7871000000000000',
      high: '24.3814000000000000',
      low: '21.7746000000000000',
      close: '24.3800000000000000',
      volume: '2846806.8900000000000000',
      quoteVolume: 66056350.15274652,
      btcVolume: 6555.827857878055,
      usdVolume: 66056350.15274652,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '24.3517000000000000',
      high: '25.7935000000000000',
      low: '23.3000000000000000',
      close: '25.1465000000000000',
      volume: '4794544.7200000000000000',
      quoteVolume: 118356199.03064102,
      btcVolume: 11937.05365463192,
      usdVolume: 118356199.03064102,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '25.1450000000000000',
      high: '25.7400000000000000',
      low: '24.1202000000000000',
      close: '25.6963000000000000',
      volume: '3512538.3800000000000000',
      quoteVolume: 87532758.12909538,
      btcVolume: 8788.633029031116,
      usdVolume: 87532758.12909538,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '25.6728000000000000',
      high: '26.9196000000000000',
      low: '25.3402000000000000',
      close: '26.4243000000000000',
      volume: '2954235.4800000000000000',
      quoteVolume: 76168100.46527852,
      btcVolume: 7380.550113864897,
      usdVolume: 76168100.46527852,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '26.4136000000000000',
      high: '27.1905000000000000',
      low: '24.5995000000000000',
      close: '25.4579000000000000',
      volume: '3591601.3200000000000000',
      quoteVolume: 92634936.37990527,
      btcVolume: 9002.249103389786,
      usdVolume: 92634936.37990527,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '25.4621000000000000',
      high: '26.5130000000000000',
      low: '25.0304000000000000',
      close: '26.3547000000000000',
      volume: '3252594.3200000000000000',
      quoteVolume: 84109977.24348529,
      btcVolume: 8191.832934805558,
      usdVolume: 84109977.24348529,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '26.3662000000000000',
      high: '26.4931000000000000',
      low: '23.5288000000000000',
      close: '24.2576000000000000',
      volume: '3355108.1400000000000000',
      quoteVolume: 85265826.4624216,
      btcVolume: 8426.730139384954,
      usdVolume: 85265826.4624216,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '24.2388000000000000',
      high: '25.2485000000000000',
      low: '21.8000000000000000',
      close: '23.3193000000000000',
      volume: '3752723.6900000000000000',
      quoteVolume: 89187179.91969427,
      btcVolume: 9019.615804441175,
      usdVolume: 89187179.91969427,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '23.3193000000000000',
      high: '23.6000000000000000',
      low: '21.5510000000000000',
      close: '23.4555000000000000',
      volume: '3208991.9600000000000000',
      quoteVolume: 72372207.9082924,
      btcVolume: 7461.240037311607,
      usdVolume: 72372207.9082924,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '23.4558000000000000',
      high: '24.4588000000000000',
      low: '22.8013000000000000',
      close: '24.0821000000000000',
      volume: '3010749.1700000000000000',
      quoteVolume: 71169442.58374034,
      btcVolume: 7232.1388082425665,
      usdVolume: 71169442.58374034,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '24.0611000000000000',
      high: '24.3264000000000000',
      low: '21.8000000000000000',
      close: '22.3709000000000000',
      volume: '2140879.9700000000000000',
      quoteVolume: 50475552.67366864,
      btcVolume: 5035.254917997106,
      usdVolume: 50475552.67366864,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '22.3702000000000000',
      high: '22.6741000000000000',
      low: '21.0000000000000000',
      close: '22.1626000000000000',
      volume: '2366725.1400000000000000',
      quoteVolume: 51918299.17105383,
      btcVolume: 5418.649900876407,
      usdVolume: 51918299.17105383,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '22.1617000000000000',
      high: '22.8639000000000000',
      low: '21.7677000000000000',
      close: '22.1888000000000000',
      volume: '2987444.2400000000000000',
      quoteVolume: 66404440.860843465,
      btcVolume: 6861.125072216571,
      usdVolume: 66404440.860843465,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '22.1997000000000000',
      high: '22.3500000000000000',
      low: '21.5000000000000000',
      close: '21.9689000000000000',
      volume: '2139282.2200000000000000',
      quoteVolume: 46985151.28201848,
      btcVolume: 4870.228412924825,
      usdVolume: 46985151.28201848,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '21.9458000000000000',
      high: '22.9800000000000000',
      low: '21.9275000000000000',
      close: '22.8452000000000000',
      volume: '3052036.9600000000000000',
      quoteVolume: 69055523.55500332,
      btcVolume: 7015.549581370111,
      usdVolume: 69055523.55500332,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '22.8267000000000000',
      high: '23.2550000000000000',
      low: '21.4844000000000000',
      close: '22.0965000000000000',
      volume: '2294898.3500000000000000',
      quoteVolume: 51480520.2332999,
      btcVolume: 5282.9140573604745,
      usdVolume: 51480520.2332999,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '22.0965000000000000',
      high: '22.0967000000000000',
      low: '20.0711000000000000',
      close: '20.3193000000000000',
      volume: '2335580.1100000000000000',
      quoteVolume: 49059568.007921465,
      btcVolume: 5169.869833706369,
      usdVolume: 49059568.007921465,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '20.3316000000000000',
      high: '20.5191000000000000',
      low: '17.9000000000000000',
      close: '18.7441000000000000',
      volume: '3753737.4600000000000000',
      quoteVolume: 71546982.18924734,
      btcVolume: 7905.138057480794,
      usdVolume: 71546982.18924734,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '18.7338000000000000',
      high: '19.6499000000000000',
      low: '17.7051000000000000',
      close: '19.2000000000000000',
      volume: '3016651.2500000000000000',
      quoteVolume: 56860249.75728752,
      btcVolume: 6470.8292928242745,
      usdVolume: 56860249.75728752,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '19.1889000000000000',
      high: '19.7551000000000000',
      low: '17.9053000000000000',
      close: '18.9838000000000000',
      volume: '3099999.9300000000000000',
      quoteVolume: 58487714.17780671,
      btcVolume: 6714.188632991106,
      usdVolume: 58487714.17780671,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '18.9838000000000000',
      high: '20.4000000000000000',
      low: '18.8000000000000000',
      close: '19.2300000000000000',
      volume: '3567334.5900000000000000',
      quoteVolume: 69257747.27603036,
      btcVolume: 7987.256453347228,
      usdVolume: 69257747.27603036,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '19.2481000000000000',
      high: '19.8994000000000000',
      low: '18.4880000000000000',
      close: '18.9020000000000000',
      volume: '2382184.7800000000000000',
      quoteVolume: 45970226.909694515,
      btcVolume: 5363.65102030691,
      usdVolume: 45970226.909694515,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '18.9183000000000000',
      high: '20.0722000000000000',
      low: '18.7583000000000000',
      close: '19.9289000000000000',
      volume: '3267729.0000000000000000',
      quoteVolume: 64060497.85221986,
      btcVolume: 7310.647723090957,
      usdVolume: 64060497.85221986,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '19.9297000000000000',
      high: '20.3865000000000000',
      low: '19.3156000000000000',
      close: '19.6882000000000000',
      volume: '2545825.0000000000000000',
      quoteVolume: 50338561.07374935,
      btcVolume: 5718.875108291753,
      usdVolume: 50338561.07374935,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '19.6707000000000000',
      high: '20.2847000000000000',
      low: '19.5131000000000000',
      close: '19.9500000000000000',
      volume: '2408511.9400000000000000',
      quoteVolume: 47942820.95931419,
      btcVolume: 5467.07577696825,
      usdVolume: 47942820.95931419,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '19.9500000000000000',
      high: '21.4585000000000000',
      low: '19.9401000000000000',
      close: '20.7462000000000000',
      volume: '3088988.6500000000000000',
      quoteVolume: 64384864.18373439,
      btcVolume: 7154.825193230421,
      usdVolume: 64384864.18373439,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '20.7463000000000000',
      high: '21.7628000000000000',
      low: '20.6303000000000000',
      close: '21.3389000000000000',
      volume: '3228771.5100000000000000',
      quoteVolume: 68446184.90637933,
      btcVolume: 7534.967221418779,
      usdVolume: 68446184.90637933,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '21.3389000000000000',
      high: '21.4900000000000000',
      low: '19.7183000000000000',
      close: '20.2500000000000000',
      volume: '2378944.8500000000000000',
      quoteVolume: 49387277.98027311,
      btcVolume: 5463.183437439565,
      usdVolume: 49387277.98027311,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '20.2500000000000000',
      high: '20.2612000000000000',
      low: '16.7315000000000000',
      close: '16.8940000000000000',
      volume: '3465550.2500000000000000',
      quoteVolume: 64439955.25070982,
      btcVolume: 7591.149351999756,
      usdVolume: 64439955.25070982,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '16.8939000000000000',
      high: '17.4775000000000000',
      low: '15.6168000000000000',
      close: '16.5888000000000000',
      volume: '4046754.1400000000000000',
      quoteVolume: 67231659.84751555,
      btcVolume: 8549.570747626509,
      usdVolume: 67231659.84751555,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '16.5934000000000000',
      high: '17.4475000000000000',
      low: '16.3107000000000000',
      close: '16.8671000000000000',
      volume: '3449744.5700000000000000',
      quoteVolume: 58330699.05467022,
      btcVolume: 7320.410900960158,
      usdVolume: 58330699.05467022,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '16.8530000000000000',
      high: '17.1388000000000000',
      low: '15.4100000000000000',
      close: '16.5705000000000000',
      volume: '2581538.7700000000000000',
      quoteVolume: 42412598.42866471,
      btcVolume: 5417.646463351474,
      usdVolume: 42412598.42866471,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '16.5698000000000000',
      high: '16.6000000000000000',
      low: '8.8900000000000000',
      close: '9.2566000000000000',
      volume: '7775320.7400000000000000',
      quoteVolume: 96587818.94082893,
      btcVolume: 15229.295961519643,
      usdVolume: 96587818.94082893,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '9.2565000000000000',
      high: '11.5572000000000000',
      low: '6.3800000000000000',
      close: '10.9204000000000000',
      volume: '10695887.9800000000000000',
      quoteVolume: 101790362.66277254,
      btcVolume: 19963.23178862742,
      usdVolume: 101790362.66277254,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '10.9224000000000000',
      high: '11.2886000000000000',
      low: '9.8652000000000000',
      close: '10.1487000000000000',
      volume: '5343507.5700000000000000',
      quoteVolume: 56907072.065318905,
      btcVolume: 10586.292565466663,
      usdVolume: 56907072.065318905,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '10.1475000000000000',
      high: '11.2644000000000000',
      low: '9.9768000000000000',
      close: '10.3898000000000000',
      volume: '6424065.3600000000000000',
      quoteVolume: 67251583.01660968,
      btcVolume: 12552.17335408042,
      usdVolume: 67251583.01660968,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '10.3808000000000000',
      high: '10.3952000000000000',
      low: '8.4422000000000000',
      close: '9.4285000000000000',
      volume: '5814301.1700000000000000',
      quoteVolume: 54897967.26809575,
      btcVolume: 11073.417029929627,
      usdVolume: 54897967.26809575,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '9.4290000000000000',
      high: '10.4900000000000000',
      low: '9.3265000000000000',
      close: '10.2576000000000000',
      volume: '5102840.4500000000000000',
      quoteVolume: 51631527.65429831,
      btcVolume: 9759.11417103753,
      usdVolume: 51631527.65429831,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '10.2498000000000000',
      high: '10.4301000000000000',
      low: '9.6539000000000000',
      close: '10.3866000000000000',
      volume: '4450666.1500000000000000',
      quoteVolume: 45276532.730975404,
      btcVolume: 8574.510856799883,
      usdVolume: 45276532.730975404,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '10.3917000000000000',
      high: '12.6891000000000000',
      low: '10.2260000000000000',
      close: '12.2597000000000000',
      volume: '5848299.4600000000000000',
      quoteVolume: 66264533.82355488,
      btcVolume: 11416.165972116107,
      usdVolume: 66264533.82355488,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '12.2597000000000000',
      high: '13.6500000000000000',
      low: '10.4014000000000000',
      close: '11.9147000000000000',
      volume: '6965457.9200000000000000',
      quoteVolume: 86260327.52522887,
      btcVolume: 13530.08677890309,
      usdVolume: 86260327.52522887,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '11.8967000000000000',
      high: '12.4199000000000000',
      low: '11.2500000000000000',
      close: '12.1000000000000000',
      volume: '5810720.8800000000000000',
      quoteVolume: 69365406.12530024,
      btcVolume: 11212.692133104454,
      usdVolume: 69365406.12530024,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '12.1050000000000000',
      high: '12.4754000000000000',
      low: '11.0145000000000000',
      close: '11.1670000000000000',
      volume: '4562736.8600000000000000',
      quoteVolume: 54219558.920412235,
      btcVolume: 8829.651908483296,
      usdVolume: 54219558.920412235,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '11.1494000000000000',
      high: '12.4900000000000000',
      low: '10.8427000000000000',
      close: '12.1887000000000000',
      volume: '5299899.4500000000000000',
      quoteVolume: 61800844.96620327,
      btcVolume: 10040.54539065147,
      usdVolume: 61800844.96620327,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '12.1887000000000000',
      high: '12.5834000000000000',
      low: '11.9100000000000000',
      close: '12.4260000000000000',
      volume: '4627182.2900000000000000',
      quoteVolume: 56790493.548444174,
      btcVolume: 8555.766772115445,
      usdVolume: 56790493.548444174,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '12.4261000000000000',
      high: '12.7108000000000000',
      low: '12.0561000000000000',
      close: '12.3251000000000000',
      volume: '3607793.8900000000000000',
      quoteVolume: 44491441.70805428,
      btcVolume: 6675.4778502505305,
      usdVolume: 44491441.70805428,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '12.3253000000000000',
      high: '13.2565000000000000',
      low: '12.1900000000000000',
      close: '13.1990000000000000',
      volume: '3433997.0900000000000000',
      quoteVolume: 42838269.466780595,
      btcVolume: 6422.748334392865,
      usdVolume: 42838269.466780595,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '13.1989000000000000',
      high: '13.3655000000000000',
      low: '12.1600000000000000',
      close: '12.3354000000000000',
      volume: '2778050.9100000000000000',
      quoteVolume: 36084938.85881204,
      btcVolume: 5400.701483352602,
      usdVolume: 36084938.85881204,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '12.3375000000000000',
      high: '12.3588000000000000',
      low: '11.6414000000000000',
      close: '12.1888000000000000',
      volume: '2478685.1100000000000000',
      quoteVolume: 30031382.50817986,
      btcVolume: 4830.592276464181,
      usdVolume: 30031382.50817986,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '12.1888000000000000',
      high: '12.2425000000000000',
      low: '11.2750000000000000',
      close: '11.3008000000000000',
      volume: '2305768.5200000000000000',
      quoteVolume: 27252838.148580186,
      btcVolume: 4463.8054330588775,
      usdVolume: 27252838.148580186,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '11.3007000000000000',
      high: '12.4277000000000000',
      low: '11.2552000000000000',
      close: '12.1365000000000000',
      volume: '4183148.7200000000000000',
      quoteVolume: 50273122.37520111,
      btcVolume: 7996.7455340660035,
      usdVolume: 50273122.37520111,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '12.1368000000000000',
      high: '12.7322000000000000',
      low: '12.1180000000000000',
      close: '12.5451000000000000',
      volume: '5119093.1500000000000000',
      quoteVolume: 64105779.65224831,
      btcVolume: 9947.257238601136,
      usdVolume: 64105779.65224831,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '12.5497000000000000',
      high: '12.7386000000000000',
      low: '11.9600000000000000',
      close: '12.7200000000000000',
      volume: '4355655.4600000000000000',
      quoteVolume: 53721353.74091241,
      btcVolume: 8489.017350493368,
      usdVolume: 53721353.74091241,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '12.7190000000000000',
      high: '13.7362000000000000',
      low: '12.5349000000000000',
      close: '13.0166000000000000',
      volume: '5332604.5300000000000000',
      quoteVolume: 69276074.10863224,
      btcVolume: 10253.55586604115,
      usdVolume: 69276074.10863224,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '13.0185000000000000',
      high: '13.5700000000000000',
      low: '12.9106000000000000',
      close: '13.3615000000000000',
      volume: '5030322.0300000000000000',
      quoteVolume: 66661802.96690086,
      btcVolume: 9801.523808937845,
      usdVolume: 66661802.96690086,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '13.3682000000000000',
      high: '14.0561000000000000',
      low: '13.1724000000000000',
      close: '13.7606000000000000',
      volume: '4840528.5300000000000000',
      quoteVolume: 66003739.59200456,
      btcVolume: 9745.152195828143,
      usdVolume: 66003739.59200456,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '13.7608000000000000',
      high: '13.9638000000000000',
      low: '13.4002000000000000',
      close: '13.6888000000000000',
      volume: '4275639.2500000000000000',
      quoteVolume: 58555104.63058687,
      btcVolume: 8621.568347482043,
      usdVolume: 58555104.63058687,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '13.6888000000000000',
      high: '15.2149000000000000',
      low: '13.6371000000000000',
      close: '15.1989000000000000',
      volume: '5583296.7600000000000000',
      quoteVolume: 80214973.59908952,
      btcVolume: 11301.364287830236,
      usdVolume: 80214973.59908952,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '15.1930000000000000',
      high: '15.4900000000000000',
      low: '14.2503000000000000',
      close: '14.7266000000000000',
      volume: '4715229.2800000000000000',
      quoteVolume: 70591252.82052863,
      btcVolume: 9647.541530712178,
      usdVolume: 70591252.82052863,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '14.7267000000000000',
      high: '15.3486000000000000',
      low: '14.5700000000000000',
      close: '15.0888000000000000',
      volume: '4614905.6300000000000000',
      quoteVolume: 69355343.21978821,
      btcVolume: 9493.717090075794,
      usdVolume: 69355343.21978821,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '15.0899000000000000',
      high: '15.2011000000000000',
      low: '14.6279000000000000',
      close: '15.0096000000000000',
      volume: '4638418.3900000000000000',
      quoteVolume: 69662383.47849114,
      btcVolume: 9554.251740198943,
      usdVolume: 69662383.47849114,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '15.0096000000000000',
      high: '15.0797000000000000',
      low: '13.4700000000000000',
      close: '13.7200000000000000',
      volume: '3999879.1400000000000000',
      quoteVolume: 56437561.49783074,
      btcVolume: 8088.757467476043,
      usdVolume: 56437561.49783074,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '13.7171000000000000',
      high: '14.0808000000000000',
      low: '13.5234000000000000',
      close: '13.8070000000000000',
      volume: '3692817.9200000000000000',
      quoteVolume: 51151554.74794237,
      btcVolume: 7454.864937758831,
      usdVolume: 51151554.74794237,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '13.8089000000000000',
      high: '14.8399000000000000',
      low: '13.6439000000000000',
      close: '14.2808000000000000',
      volume: '4070517.8200000000000000',
      quoteVolume: 57950623.216416314,
      btcVolume: 8337.283577200431,
      usdVolume: 57950623.216416314,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '14.2808000000000000',
      high: '15.0799000000000000',
      low: '13.6289000000000000',
      close: '15.0277000000000000',
      volume: '4313190.9300000000000000',
      quoteVolume: 62029135.8484717,
      btcVolume: 9209.291923149041,
      usdVolume: 62029135.8484717,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '15.0257000000000000',
      high: '15.9555000000000000',
      low: '14.7835000000000000',
      close: '15.6148000000000000',
      volume: '4523071.2000000000000000',
      quoteVolume: 70092382.14295062,
      btcVolume: 10207.861589084292,
      usdVolume: 70092382.14295062,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '15.6148000000000000',
      high: '15.8888000000000000',
      low: '14.4913000000000000',
      close: '14.5755000000000000',
      volume: '3795966.9000000000000000',
      quoteVolume: 58221065.7500413,
      btcVolume: 8555.74483779464,
      usdVolume: 58221065.7500413,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '14.5755000000000000',
      high: '15.7669000000000000',
      low: '14.1160000000000000',
      close: '15.7394000000000000',
      volume: '4068049.4800000000000000',
      quoteVolume: 61814683.27456321,
      btcVolume: 8977.839996691559,
      usdVolume: 61814683.27456321,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '15.7394000000000000',
      high: '15.9200000000000000',
      low: '15.2501000000000000',
      close: '15.6400000000000000',
      volume: '3150559.1700000000000000',
      quoteVolume: 49188415.57936379,
      btcVolume: 6962.024816102106,
      usdVolume: 49188415.57936379,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '15.6447000000000000',
      high: '16.8183000000000000',
      low: '15.6242000000000000',
      close: '16.6137000000000000',
      volume: '3968019.0700000000000000',
      quoteVolume: 64502814.66373515,
      btcVolume: 8996.773890457556,
      usdVolume: 64502814.66373515,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '16.6198000000000000',
      high: '16.6933000000000000',
      low: '15.8509000000000000',
      close: '16.0683000000000000',
      volume: '2962072.6000000000000000',
      quoteVolume: 48174317.52943487,
      btcVolume: 6722.896522589055,
      usdVolume: 48174317.52943487,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '16.0677000000000000',
      high: '16.4499000000000000',
      low: '14.8219000000000000',
      close: '14.9892000000000000',
      volume: '3219905.9000000000000000',
      quoteVolume: 50757347.771237776,
      btcVolume: 7228.98020818563,
      usdVolume: 50757347.771237776,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '14.9840000000000000',
      high: '15.4416000000000000',
      low: '14.6945000000000000',
      close: '15.1283000000000000',
      volume: '3134797.6600000000000000',
      quoteVolume: 47551099.75450032,
      btcVolume: 6932.414273166686,
      usdVolume: 47551099.75450032,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '15.1381000000000000',
      high: '15.8030000000000000',
      low: '14.9112000000000000',
      close: '15.7104000000000000',
      volume: '3293822.4400000000000000',
      quoteVolume: 50953432.53308494,
      btcVolume: 7270.805302216778,
      usdVolume: 50953432.53308494,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '15.7103000000000000',
      high: '16.4666000000000000',
      low: '15.4102000000000000',
      close: '16.0340000000000000',
      volume: '3451154.7600000000000000',
      quoteVolume: 54969135.67182449,
      btcVolume: 7506.714246229673,
      usdVolume: 54969135.67182449,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '16.0502000000000000',
      high: '16.2568000000000000',
      low: '15.6800000000000000',
      close: '15.9319000000000000',
      volume: '3008320.2600000000000000',
      quoteVolume: 48259042.136493415,
      btcVolume: 6423.189422170581,
      usdVolume: 48259042.136493415,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '15.9319000000000000',
      high: '16.4305000000000000',
      low: '15.7761000000000000',
      close: '16.2283000000000000',
      volume: '2946839.7900000000000000',
      quoteVolume: 47639855.83746818,
      btcVolume: 6314.8054320104275,
      usdVolume: 47639855.83746818,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '16.2235000000000000',
      high: '16.4435000000000000',
      low: '16.0400000000000000',
      close: '16.3588000000000000',
      volume: '3314818.9100000000000000',
      quoteVolume: 53903371.49447087,
      btcVolume: 7094.218778997715,
      usdVolume: 53903371.49447087,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '16.3645000000000000',
      high: '16.5600000000000000',
      low: '16.0630000000000000',
      close: '16.4229000000000000',
      volume: '2937287.3600000000000000',
      quoteVolume: 47859272.721880525,
      btcVolume: 6212.335727211785,
      usdVolume: 47859272.721880525,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '16.4199000000000000',
      high: '16.4900000000000000',
      low: '16.1125000000000000',
      close: '16.3400000000000000',
      volume: '2838511.4300000000000000',
      quoteVolume: 46309248.7211524,
      btcVolume: 5999.145090290307,
      usdVolume: 46309248.7211524,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '16.3354000000000000',
      high: '17.5171000000000000',
      low: '16.3000000000000000',
      close: '17.3667000000000000',
      volume: '3817656.7000000000000000',
      quoteVolume: 64434759.7688937,
      btcVolume: 7799.88414199083,
      usdVolume: 64434759.7688937,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '17.3754000000000000',
      high: '18.1377000000000000',
      low: '16.5000000000000000',
      close: '16.9542000000000000',
      volume: '4127322.5100000000000000',
      quoteVolume: 71629569.6960837,
      btcVolume: 8039.289696160252,
      usdVolume: 71629569.6960837,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '16.9510000000000000',
      high: '17.8551000000000000',
      low: '16.9381000000000000',
      close: '17.4660000000000000',
      volume: '3805293.6700000000000000',
      quoteVolume: 66271797.65506503,
      btcVolume: 7557.207038844103,
      usdVolume: 66271797.65506503,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '17.4665000000000000',
      high: '17.5711000000000000',
      low: '17.2599000000000000',
      close: '17.5333000000000000',
      volume: '3009939.6700000000000000',
      quoteVolume: 52504866.732339546,
      btcVolume: 5915.815664352295,
      usdVolume: 52504866.732339546,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '17.5337000000000000',
      high: '17.8100000000000000',
      low: '16.8711000000000000',
      close: '17.1071000000000000',
      volume: '2616299.5300000000000000',
      quoteVolume: 45271776.505038545,
      btcVolume: 5050.819962883537,
      usdVolume: 45271776.505038545,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '17.1027000000000000',
      high: '17.3267000000000000',
      low: '16.2556000000000000',
      close: '17.0074000000000000',
      volume: '2755883.6300000000000000',
      quoteVolume: 46419270.57261121,
      btcVolume: 5284.661382519424,
      usdVolume: 46419270.57261121,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '17.0120000000000000',
      high: '17.2993000000000000',
      low: '16.6060000000000000',
      close: '16.8886000000000000',
      volume: '2613361.6500000000000000',
      quoteVolume: 44261093.04238469,
      btcVolume: 4957.354183210489,
      usdVolume: 44261093.04238469,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '16.8814000000000000',
      high: '17.1450000000000000',
      low: '16.3018000000000000',
      close: '16.3743000000000000',
      volume: '2716280.3700000000000000',
      quoteVolume: 45806861.8974411,
      btcVolume: 5004.187193369502,
      usdVolume: 45806861.8974411,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '16.3672000000000000',
      high: '17.2400000000000000',
      low: '16.1218000000000000',
      close: '17.0003000000000000',
      volume: '3030451.5100000000000000',
      quoteVolume: 50623360.84297903,
      btcVolume: 5321.812894633818,
      usdVolume: 50623360.84297903,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '17.0003000000000000',
      high: '17.3977000000000000',
      low: '16.8230000000000000',
      close: '17.1371000000000000',
      volume: '2621559.8700000000000000',
      quoteVolume: 44879553.905084215,
      btcVolume: 4525.608471864123,
      usdVolume: 44879553.905084215,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '17.1371000000000000',
      high: '17.4926000000000000',
      low: '16.7330000000000000',
      close: '16.7751000000000000',
      volume: '2243643.8300000000000000',
      quoteVolume: 38582692.96829227,
      btcVolume: 3962.9396596703737,
      usdVolume: 38582692.96829227,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '16.7753000000000000',
      high: '16.7900000000000000',
      low: '13.9129000000000000',
      close: '15.3506000000000000',
      volume: '2900497.8000000000000000',
      quoteVolume: 43979315.8858527,
      btcVolume: 5066.467433139654,
      usdVolume: 43979315.8858527,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '15.3506000000000000',
      high: '15.5468000000000000',
      low: '14.0100000000000000',
      close: '15.0811000000000000',
      volume: '2726253.2700000000000000',
      quoteVolume: 40893423.03739202,
      btcVolume: 4712.194211346884,
      usdVolume: 40893423.03739202,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '15.0902000000000000',
      high: '16.2400000000000000',
      low: '14.9586000000000000',
      close: '15.7892000000000000',
      volume: '3026813.0900000000000000',
      quoteVolume: 47332783.09776167,
      btcVolume: 5395.76311152628,
      usdVolume: 47332783.09776167,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '15.7941000000000000',
      high: '16.3500000000000000',
      low: '15.6785000000000000',
      close: '16.2164000000000000',
      volume: '2812457.1200000000000000',
      quoteVolume: 45141960.7019688,
      btcVolume: 4989.884585136048,
      usdVolume: 45141960.7019688,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '16.2187000000000000',
      high: '16.6982000000000000',
      low: '16.0123000000000000',
      close: '16.3777000000000000',
      volume: '2801822.6000000000000000',
      quoteVolume: 45666418.70995536,
      btcVolume: 4771.624602245077,
      usdVolume: 45666418.70995536,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '16.3722000000000000',
      high: '16.4719000000000000',
      low: '15.4606000000000000',
      close: '15.7222000000000000',
      volume: '2314656.9000000000000000',
      quoteVolume: 37214374.23302308,
      btcVolume: 3912.922381413597,
      usdVolume: 37214374.23302308,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '15.7121000000000000',
      high: '16.2367000000000000',
      low: '15.5800000000000000',
      close: '16.0580000000000000',
      volume: '2730367.3500000000000000',
      quoteVolume: 43746498.66421267,
      btcVolume: 4654.9166519593045,
      usdVolume: 43746498.66421267,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '16.0580000000000000',
      high: '16.5100000000000000',
      low: '16.0014000000000000',
      close: '16.2959000000000000',
      volume: '2893701.8400000000000000',
      quoteVolume: 47142111.5064022,
      btcVolume: 4887.223961466,
      usdVolume: 47142111.5064022,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '16.2999000000000000',
      high: '16.8620000000000000',
      low: '16.2511000000000000',
      close: '16.6299000000000000',
      volume: '3116090.7500000000000000',
      quoteVolume: 51603793.31526883,
      btcVolume: 5303.9246355544265,
      usdVolume: 51603793.31526883,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '16.6298000000000000',
      high: '17.2488000000000000',
      low: '16.3000000000000000',
      close: '17.2098000000000000',
      volume: '3245275.7500000000000000',
      quoteVolume: 54590767.63110695,
      btcVolume: 5634.9082075498,
      usdVolume: 54590767.63110695,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '17.2096000000000000',
      high: '17.3396000000000000',
      low: '16.4851000000000000',
      close: '16.8998000000000000',
      volume: '2395316.0900000000000000',
      quoteVolume: 40821394.31884481,
      btcVolume: 4224.383755576833,
      usdVolume: 40821394.31884481,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '16.8994000000000000',
      high: '17.0675000000000000',
      low: '15.7218000000000000',
      close: '16.0888000000000000',
      volume: '2163570.9800000000000000',
      quoteVolume: 35589912.05392658,
      btcVolume: 3843.773844858664,
      usdVolume: 35589912.05392658,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '16.0888000000000000',
      high: '16.9240000000000000',
      low: '15.9145000000000000',
      close: '16.5864000000000000',
      volume: '2520655.4700000000000000',
      quoteVolume: 41584466.617784135,
      btcVolume: 4547.953831523734,
      usdVolume: 41584466.617784135,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '16.5884000000000000',
      high: '16.8500000000000000',
      low: '16.3202000000000000',
      close: '16.4800000000000000',
      volume: '2302712.1700000000000000',
      quoteVolume: 38173594.65329609,
      btcVolume: 4145.125970293084,
      usdVolume: 38173594.65329609,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '16.4789000000000000',
      high: '16.7900000000000000',
      low: '15.9074000000000000',
      close: '15.9624000000000000',
      volume: '2280604.7400000000000000',
      quoteVolume: 37593388.9279645,
      btcVolume: 4127.7763329936115,
      usdVolume: 37593388.9279645,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '15.9457000000000000',
      high: '16.4161000000000000',
      low: '15.8057000000000000',
      close: '16.3807000000000000',
      volume: '2348512.9100000000000000',
      quoteVolume: 38152773.256409764,
      btcVolume: 4321.228700886494,
      usdVolume: 38152773.256409764,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '16.3807000000000000',
      high: '16.4979000000000000',
      low: '15.9537000000000000',
      close: '16.2159000000000000',
      volume: '2007343.8200000000000000',
      quoteVolume: 32624803.88715872,
      btcVolume: 3682.6289500059283,
      usdVolume: 32624803.88715872,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '16.2097000000000000',
      high: '16.5100000000000000',
      low: '16.1554000000000000',
      close: '16.4700000000000000',
      volume: '2613812.0500000000000000',
      quoteVolume: 42792287.77669632,
      btcVolume: 4725.005187970007,
      usdVolume: 42792287.77669632,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '16.4700000000000000',
      high: '17.1405000000000000',
      low: '16.3890000000000000',
      close: '17.0772000000000000',
      volume: '2866468.3900000000000000',
      quoteVolume: 47917801.298296325,
      btcVolume: 5119.878787996562,
      usdVolume: 47917801.298296325,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '17.0769000000000000',
      high: '17.1980000000000000',
      low: '16.6333000000000000',
      close: '17.0031000000000000',
      volume: '2598906.5200000000000000',
      quoteVolume: 44169314.055208385,
      btcVolume: 4668.689724077699,
      usdVolume: 44169314.055208385,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '17.0030000000000000',
      high: '17.7857000000000000',
      low: '16.9291000000000000',
      close: '17.7277000000000000',
      volume: '2969818.2300000000000000',
      quoteVolume: 51559065.97362402,
      btcVolume: 5420.853255843491,
      usdVolume: 51559065.97362402,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '17.7277000000000000',
      high: '18.1954000000000000',
      low: '16.8419000000000000',
      close: '17.0916000000000000',
      volume: '2612942.1800000000000000',
      quoteVolume: 45947854.11468179,
      btcVolume: 4807.9438395434145,
      usdVolume: 45947854.11468179,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '17.0926000000000000',
      high: '18.1224000000000000',
      low: '16.9836000000000000',
      close: '17.9477000000000000',
      volume: '2989853.0600000000000000',
      quoteVolume: 52189313.03744129,
      btcVolume: 5419.06119775764,
      usdVolume: 52189313.03744129,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '17.9469000000000000',
      high: '18.2000000000000000',
      low: '16.6800000000000000',
      close: '17.2669000000000000',
      volume: '2536257.1500000000000000',
      quoteVolume: 44844446.996629834,
      btcVolume: 4522.870886309356,
      usdVolume: 44844446.996629834,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '17.2761000000000000',
      high: '17.6275000000000000',
      low: '16.9645000000000000',
      close: '17.5779000000000000',
      volume: '2650311.1400000000000000',
      quoteVolume: 45972871.326653786,
      btcVolume: 4811.90967908672,
      usdVolume: 45972871.326653786,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '17.5780000000000000',
      high: '17.6527000000000000',
      low: '17.0424000000000000',
      close: '17.5170000000000000',
      volume: '2320525.8900000000000000',
      quoteVolume: 40612423.08444422,
      btcVolume: 4186.441359549021,
      usdVolume: 40612423.08444422,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '17.5180000000000000',
      high: '17.8999000000000000',
      low: '17.4396000000000000',
      close: '17.6914000000000000',
      volume: '2454801.7700000000000000',
      quoteVolume: 43342829.55218497,
      btcVolume: 4448.532900411398,
      usdVolume: 43342829.55218497,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '17.6915000000000000',
      high: '17.7951000000000000',
      low: '17.4900000000000000',
      close: '17.5511000000000000',
      volume: '1963897.2200000000000000',
      quoteVolume: 34612966.357234664,
      btcVolume: 3593.211679800369,
      usdVolume: 34612966.357234664,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '17.5510000000000000',
      high: '17.6371000000000000',
      low: '16.8526000000000000',
      close: '17.4135000000000000',
      volume: '1816342.7400000000000000',
      quoteVolume: 31593373.320084013,
      btcVolume: 3279.991408861491,
      usdVolume: 31593373.320084013,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '17.4134000000000000',
      high: '17.5454000000000000',
      low: '17.2594000000000000',
      close: '17.4489000000000000',
      volume: '1657535.1100000000000000',
      quoteVolume: 28825851.386570796,
      btcVolume: 2964.926669627316,
      usdVolume: 28825851.386570796,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '17.4489000000000000',
      high: '17.5098000000000000',
      low: '17.0374000000000000',
      close: '17.3733000000000000',
      volume: '1630641.2900000000000000',
      quoteVolume: 28236106.363147046,
      btcVolume: 2904.68453638977,
      usdVolume: 28236106.363147046,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '17.3734000000000000',
      high: '17.5833000000000000',
      low: '17.1300000000000000',
      close: '17.4060000000000000',
      volume: '1807563.8600000000000000',
      quoteVolume: 31389856.381225992,
      btcVolume: 3201.181062165144,
      usdVolume: 31389856.381225992,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '17.4084000000000000',
      high: '17.5191000000000000',
      low: '15.9300000000000000',
      close: '16.2298000000000000',
      volume: '1799102.4900000000000000',
      quoteVolume: 30529455.476210415,
      btcVolume: 3172.6583143612193,
      usdVolume: 30529455.476210415,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '16.2376000000000000',
      high: '16.7999000000000000',
      low: '16.0869000000000000',
      close: '16.6377000000000000',
      volume: '1930125.9500000000000000',
      quoteVolume: 31938857.307844184,
      btcVolume: 3394.540608530982,
      usdVolume: 31938857.307844184,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '16.6420000000000000',
      high: '16.8179000000000000',
      low: '16.4900000000000000',
      close: '16.8101000000000000',
      volume: '2023311.8300000000000000',
      quoteVolume: 33704354.30536094,
      btcVolume: 3573.99175725781,
      usdVolume: 33704354.30536094,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '16.8111000000000000',
      high: '16.8398000000000000',
      low: '16.4023000000000000',
      close: '16.5729000000000000',
      volume: '1697344.5500000000000000',
      quoteVolume: 28336046.766627934,
      btcVolume: 3010.4223953476185,
      usdVolume: 28336046.766627934,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '16.5728000000000000',
      high: '16.5982000000000000',
      low: '15.4000000000000000',
      close: '16.3770000000000000',
      volume: '1521685.2100000000000000',
      quoteVolume: 24473919.621151537,
      btcVolume: 2658.7457305257617,
      usdVolume: 24473919.621151537,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '16.3758000000000000',
      high: '16.6481000000000000',
      low: '16.1562000000000000',
      close: '16.4329000000000000',
      volume: '1614905.6400000000000000',
      quoteVolume: 26479345.22874293,
      btcVolume: 2788.7732205533584,
      usdVolume: 26479345.22874293,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '16.4331000000000000',
      high: '16.6320000000000000',
      low: '15.9709000000000000',
      close: '16.3983000000000000',
      volume: '1502697.3100000000000000',
      quoteVolume: 24591062.00260309,
      btcVolume: 2604.547568064439,
      usdVolume: 24591062.00260309,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '16.3983000000000000',
      high: '16.5423000000000000',
      low: '15.8783000000000000',
      close: '16.1608000000000000',
      volume: '1382820.8700000000000000',
      quoteVolume: 22540156.403535735,
      btcVolume: 2394.7606782552566,
      usdVolume: 22540156.403535735,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '16.1665000000000000',
      high: '16.3106000000000000',
      low: '15.8966000000000000',
      close: '15.9512000000000000',
      volume: '1324135.6000000000000000',
      quoteVolume: 21271385.818114948,
      btcVolume: 2279.89302677804,
      usdVolume: 21271385.818114948,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '15.9611000000000000',
      high: '16.0898000000000000',
      low: '15.7000000000000000',
      close: '16.0590000000000000',
      volume: '1427210.2200000000000000',
      quoteVolume: 22776460.30155413,
      btcVolume: 2445.1087498630836,
      usdVolume: 22776460.30155413,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '16.0590000000000000',
      high: '16.3358000000000000',
      low: '15.9210000000000000',
      close: '15.9775000000000000',
      volume: '1317075.8400000000000000',
      quoteVolume: 21166507.63965404,
      btcVolume: 2262.4663175872406,
      usdVolume: 21166507.63965404,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '15.9792000000000000',
      high: '16.5500000000000000',
      low: '15.9499000000000000',
      close: '16.5051000000000000',
      volume: '1703957.1800000000000000',
      quoteVolume: 27730249.14524187,
      btcVolume: 2922.9736930384024,
      usdVolume: 27730249.14524187,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '16.5051000000000000',
      high: '16.5472000000000000',
      low: '16.2602000000000000',
      close: '16.3898000000000000',
      volume: '1450721.0800000000000000',
      quoteVolume: 23797667.00323723,
      btcVolume: 2468.0003601359663,
      usdVolume: 23797667.00323723,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '16.3898000000000000',
      high: '16.6481000000000000',
      low: '15.9500000000000000',
      close: '15.9968000000000000',
      volume: '1529800.3000000000000000',
      quoteVolume: 24889909.655295953,
      btcVolume: 2628.3948271845443,
      usdVolume: 24889909.655295953,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '16.0084000000000000',
      high: '16.0816000000000000',
      low: '15.5000000000000000',
      close: '15.9060000000000000',
      volume: '1201354.7500000000000000',
      quoteVolume: 19105061.019308876,
      btcVolume: 2069.4495041309574,
      usdVolume: 19105061.019308876,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '15.9056000000000000',
      high: '15.9996000000000000',
      low: '15.5715000000000000',
      close: '15.7694000000000000',
      volume: '1041399.0000000000000000',
      quoteVolume: 16428293.389013952,
      btcVolume: 1787.4554301521573,
      usdVolume: 16428293.389013952,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '15.7696000000000000',
      high: '15.8714000000000000',
      low: '14.8000000000000000',
      close: '15.1242000000000000',
      volume: '1309335.6600000000000000',
      quoteVolume: 20331918.057953976,
      btcVolume: 2234.822337873103,
      usdVolume: 20331918.057953976,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '15.1230000000000000',
      high: '15.4944000000000000',
      low: '14.9249000000000000',
      close: '15.3782000000000000',
      volume: '1402858.9600000000000000',
      quoteVolume: 21418398.753466826,
      btcVolume: 2360.2436605355056,
      usdVolume: 21418398.753466826,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '15.3714000000000000',
      high: '15.5901000000000000',
      low: '15.2040000000000000',
      close: '15.4656000000000000',
      volume: '1511856.3800000000000000',
      quoteVolume: 23294081.307785984,
      btcVolume: 2551.092853713331,
      usdVolume: 23294081.307785984,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '15.4674000000000000',
      high: '15.5476000000000000',
      low: '15.2519000000000000',
      close: '15.3838000000000000',
      volume: '1315772.4200000000000000',
      quoteVolume: 20305967.15507204,
      btcVolume: 2218.39382184501,
      usdVolume: 20305967.15507204,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '15.3959000000000000',
      high: '15.8888000000000000',
      low: '15.3363000000000000',
      close: '15.8144000000000000',
      volume: '1609244.5800000000000000',
      quoteVolume: 25167285.05407897,
      btcVolume: 2737.6419244650206,
      usdVolume: 25167285.05407897,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '15.8123000000000000',
      high: '15.9796000000000000',
      low: '15.0743000000000000',
      close: '15.3634000000000000',
      volume: '1399345.3900000000000000',
      quoteVolume: 21887439.935343955,
      btcVolume: 2389.049039204999,
      usdVolume: 21887439.935343955,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '15.3640000000000000',
      high: '15.4880000000000000',
      low: '15.2484000000000000',
      close: '15.2863000000000000',
      volume: '1204044.4900000000000000',
      quoteVolume: 18526262.42016398,
      btcVolume: 2036.9658883771122,
      usdVolume: 18526262.42016398,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '15.2856000000000000',
      high: '15.6399000000000000',
      low: '15.2239000000000000',
      close: '15.5548000000000000',
      volume: '1226300.0400000000000000',
      quoteVolume: 18922658.450647,
      btcVolume: 2079.9805745186986,
      usdVolume: 18922658.450647,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '15.5579000000000000',
      high: '15.6135000000000000',
      low: '15.1000000000000000',
      close: '15.4885000000000000',
      volume: '1064265.3200000000000000',
      quoteVolume: 16414898.226830075,
      btcVolume: 1810.9834840684625,
      usdVolume: 16414898.226830075,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '15.4865000000000000',
      high: '16.3496000000000000',
      low: '15.4401000000000000',
      close: '16.3110000000000000',
      volume: '1606449.3100000000000000',
      quoteVolume: 25364592.417103197,
      btcVolume: 2750.178905626979,
      usdVolume: 25364592.417103197,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '16.3120000000000000',
      high: '16.8757000000000000',
      low: '16.0301000000000000',
      close: '16.8048000000000000',
      volume: '1880630.3400000000000000',
      quoteVolume: 31044612.46472845,
      btcVolume: 3348.0111025538104,
      usdVolume: 31044612.46472845,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '16.7995000000000000',
      high: '17.4550000000000000',
      low: '16.5683000000000000',
      close: '17.2819000000000000',
      volume: '2246484.1800000000000000',
      quoteVolume: 38372913.62190868,
      btcVolume: 4118.227717083268,
      usdVolume: 38372913.62190868,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '17.2819000000000000',
      high: '17.5000000000000000',
      low: '16.4088000000000000',
      close: '16.8274000000000000',
      volume: '1869273.6700000000000000',
      quoteVolume: 31602020.87875102,
      btcVolume: 3389.70673054376,
      usdVolume: 31602020.87875102,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '16.8278000000000000',
      high: '17.3310000000000000',
      low: '16.4438000000000000',
      close: '17.3123000000000000',
      volume: '2099193.5700000000000000',
      quoteVolume: 35493337.16801673,
      btcVolume: 3853.180488607405,
      usdVolume: 35493337.16801673,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '17.3133000000000000',
      high: '17.6888000000000000',
      low: '17.0180000000000000',
      close: '17.6205000000000000',
      volume: '2276165.3000000000000000',
      quoteVolume: 39710164.3496227,
      btcVolume: 4298.963618020441,
      usdVolume: 39710164.3496227,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '17.6205000000000000',
      high: '18.5900000000000000',
      low: '17.5928000000000000',
      close: '18.2994000000000000',
      volume: '2995977.4100000000000000',
      quoteVolume: 54487455.92163716,
      btcVolume: 5888.09404421164,
      usdVolume: 54487455.92163716,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '18.3036000000000000',
      high: '18.9999000000000000',
      low: '17.7282000000000000',
      close: '18.4046000000000000',
      volume: '3228966.6100000000000000',
      quoteVolume: 59615751.20299803,
      btcVolume: 6420.203822258735,
      usdVolume: 59615751.20299803,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '18.4007000000000000',
      high: '18.7700000000000000',
      low: '17.8266000000000000',
      close: '18.0790000000000000',
      volume: '2385481.9700000000000000',
      quoteVolume: 43474784.56725936,
      btcVolume: 4714.97312281895,
      usdVolume: 43474784.56725936,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '18.0790000000000000',
      high: '18.1538000000000000',
      low: '17.4194000000000000',
      close: '17.6351000000000000',
      volume: '1959582.7300000000000000',
      quoteVolume: 34842425.382078946,
      btcVolume: 3776.4969692526847,
      usdVolume: 34842425.382078946,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '17.6351000000000000',
      high: '17.7618000000000000',
      low: '16.5473000000000000',
      close: '17.1647000000000000',
      volume: '2053439.4400000000000000',
      quoteVolume: 35131243.13980965,
      btcVolume: 3840.4754390289468,
      usdVolume: 35131243.13980965,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '17.1647000000000000',
      high: '17.4825000000000000',
      low: '16.9354000000000000',
      close: '17.0676000000000000',
      volume: '1852340.6900000000000000',
      quoteVolume: 32019335.19000079,
      btcVolume: 3504.743471468927,
      usdVolume: 32019335.19000079,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '17.0675000000000000',
      high: '17.3247000000000000',
      low: '16.8122000000000000',
      close: '17.1507000000000000',
      volume: '1687884.7600000000000000',
      quoteVolume: 28863376.478104953,
      btcVolume: 3151.7268006262602,
      usdVolume: 28863376.478104953,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '17.1542000000000000',
      high: '18.0980000000000000',
      low: '16.8900000000000000',
      close: '18.0154000000000000',
      volume: '2040900.4400000000000000',
      quoteVolume: 35931733.34284701,
      btcVolume: 3925.100741605019,
      usdVolume: 35931733.34284701,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '18.0166000000000000',
      high: '18.3400000000000000',
      low: '17.2350000000000000',
      close: '17.4645000000000000',
      volume: '2487505.4900000000000000',
      quoteVolume: 44544637.59956494,
      btcVolume: 4853.4342390229085,
      usdVolume: 44544637.59956494,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '17.4645000000000000',
      high: '17.9799000000000000',
      low: '17.4000000000000000',
      close: '17.7285000000000000',
      volume: '2110733.7600000000000000',
      quoteVolume: 37350560.43213578,
      btcVolume: 4021.207302975722,
      usdVolume: 37350560.43213578,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '17.7268000000000000',
      high: '18.1151000000000000',
      low: '17.4500000000000000',
      close: '18.0900000000000000',
      volume: '2172957.4900000000000000',
      quoteVolume: 38516127.55802692,
      btcVolume: 4103.451167804567,
      usdVolume: 38516127.55802692,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '18.0950000000000000',
      high: '18.7700000000000000',
      low: '18.0430000000000000',
      close: '18.7500000000000000',
      volume: '2393871.4500000000000000',
      quoteVolume: 43866390.168993205,
      btcVolume: 4602.916363249338,
      usdVolume: 43866390.168993205,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '18.7508000000000000',
      high: '19.5500000000000000',
      low: '18.2350000000000000',
      close: '19.1853000000000000',
      volume: '2790281.4600000000000000',
      quoteVolume: 52627925.923414364,
      btcVolume: 5510.371737165114,
      usdVolume: 52627925.923414364,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '19.1878000000000000',
      high: '20.0434000000000000',
      low: '19.1451000000000000',
      close: '19.8242000000000000',
      volume: '2956369.7600000000000000',
      quoteVolume: 58227674.3389005,
      btcVolume: 6053.932571636272,
      usdVolume: 58227674.3389005,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '19.8196000000000000',
      high: '20.4900000000000000',
      low: '19.2639000000000000',
      close: '19.5557000000000000',
      volume: '2857739.1600000000000000',
      quoteVolume: 56378423.676656574,
      btcVolume: 5746.873011607245,
      usdVolume: 56378423.676656574,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '19.5524000000000000',
      high: '20.1999000000000000',
      low: '18.4000000000000000',
      close: '19.7474000000000000',
      volume: '3841364.9100000000000000',
      quoteVolume: 74130208.23661059,
      btcVolume: 7086.863775270473,
      usdVolume: 74130208.23661059,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '19.7489000000000000',
      high: '20.6834000000000000',
      low: '19.4281000000000000',
      close: '20.1732000000000000',
      volume: '3516712.3500000000000000',
      quoteVolume: 70792618.98395808,
      btcVolume: 6451.110876885711,
      usdVolume: 70792618.98395808,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '20.1692000000000000',
      high: '20.4091000000000000',
      low: '19.7404000000000000',
      close: '19.8990000000000000',
      volume: '2219760.2000000000000000',
      quoteVolume: 44493255.13050928,
      btcVolume: 4013.2992143742485,
      usdVolume: 44493255.13050928,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '19.8926000000000000',
      high: '20.0995000000000000',
      low: '19.5111000000000000',
      close: '19.9700000000000000',
      volume: '2052242.5900000000000000',
      quoteVolume: 40761604.204639345,
      btcVolume: 3688.6879848192216,
      usdVolume: 40761604.204639345,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '19.9700000000000000',
      high: '20.8550000000000000',
      low: '19.9102000000000000',
      close: '20.6615000000000000',
      volume: '2251101.2100000000000000',
      quoteVolume: 46058826.375720695,
      btcVolume: 4123.40021955276,
      usdVolume: 46058826.375720695,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '20.6637000000000000',
      high: '21.7400000000000000',
      low: '20.5100000000000000',
      close: '21.6168000000000000',
      volume: '2657942.8500000000000000',
      quoteVolume: 55887870.71842739,
      btcVolume: 4818.782175909525,
      usdVolume: 55887870.71842739,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '21.6204000000000000',
      high: '22.1832000000000000',
      low: '19.1000000000000000',
      close: '20.9898000000000000',
      volume: '3352897.4600000000000000',
      quoteVolume: 69934920.73105527,
      btcVolume: 6141.195512343305,
      usdVolume: 69934920.73105527,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '20.9899000000000000',
      high: '22.5000000000000000',
      low: '20.7000000000000000',
      close: '22.0504000000000000',
      volume: '2668345.0200000000000000',
      quoteVolume: 58090829.071236245,
      btcVolume: 5171.676116333851,
      usdVolume: 58090829.071236245,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '22.0622000000000000',
      high: '22.4433000000000000',
      low: '21.4100000000000000',
      close: '22.2818000000000000',
      volume: '2388746.3300000000000000',
      quoteVolume: 52666694.152770996,
      btcVolume: 4685.128631957818,
      usdVolume: 52666694.152770996,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '22.2967000000000000',
      high: '23.5400000000000000',
      low: '22.0566000000000000',
      close: '23.2728000000000000',
      volume: '3698317.5100000000000000',
      quoteVolume: 85353655.3331338,
      btcVolume: 7486.721568389888,
      usdVolume: 85353655.3331338,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '23.2685000000000000',
      high: '23.3150000000000000',
      low: '22.5000000000000000',
      close: '22.7487000000000000',
      volume: '2442941.2600000000000000',
      quoteVolume: 56050066.31906953,
      btcVolume: 4770.055541701376,
      usdVolume: 56050066.31906953,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '22.7488000000000000',
      high: '22.9999000000000000',
      low: '21.4515000000000000',
      close: '22.3323000000000000',
      volume: '2770757.2300000000000000',
      quoteVolume: 62149286.781891696,
      btcVolume: 5325.432326997081,
      usdVolume: 62149286.781891696,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '22.3323000000000000',
      high: '22.9241000000000000',
      low: '21.8571000000000000',
      close: '22.9234000000000000',
      volume: '2408649.6800000000000000',
      quoteVolume: 53989515.896717064,
      btcVolume: 4622.635302886836,
      usdVolume: 53989515.896717064,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '22.9230000000000000',
      high: '22.9233000000000000',
      low: '22.0462000000000000',
      close: '22.4220000000000000',
      volume: '2107633.5500000000000000',
      quoteVolume: 47448309.30630981,
      btcVolume: 4067.839134883987,
      usdVolume: 47448309.30630981,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '22.4224000000000000',
      high: '22.7700000000000000',
      low: '22.0462000000000000',
      close: '22.6369000000000000',
      volume: '2108928.4200000000000000',
      quoteVolume: 47454718.99539966,
      btcVolume: 3996.4516752424197,
      usdVolume: 47454718.99539966,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '22.6369000000000000',
      high: '22.7306000000000000',
      low: '20.6600000000000000',
      close: '21.2902000000000000',
      volume: '3211543.4400000000000000',
      quoteVolume: 69760318.54425076,
      btcVolume: 6019.612592108588,
      usdVolume: 69760318.54425076,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '21.2993000000000000',
      high: '21.9820000000000000',
      low: '20.5710000000000000',
      close: '21.4919000000000000',
      volume: '2455644.0200000000000000',
      quoteVolume: 52586016.37716104,
      btcVolume: 4598.830579180555,
      usdVolume: 52586016.37716104,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '21.4919000000000000',
      high: '21.8560000000000000',
      low: '20.6400000000000000',
      close: '21.7667000000000000',
      volume: '3079188.7800000000000000',
      quoteVolume: 65454119.38361212,
      btcVolume: 5675.420522437151,
      usdVolume: 65454119.38361212,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '21.7751000000000000',
      high: '23.3019000000000000',
      low: '21.2600000000000000',
      close: '23.1047000000000000',
      volume: '3817320.2200000000000000',
      quoteVolume: 85895020.77906209,
      btcVolume: 7324.882591857548,
      usdVolume: 85895020.77906209,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '23.0966000000000000',
      high: '23.7884000000000000',
      low: '22.7000000000000000',
      close: '23.0881000000000000',
      volume: '2752150.0600000000000000',
      quoteVolume: 64002725.1804066,
      btcVolume: 5394.658484324495,
      usdVolume: 64002725.1804066,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '23.1045000000000000',
      high: '23.7790000000000000',
      low: '22.7000000000000000',
      close: '23.5290000000000000',
      volume: '2705813.5400000000000000',
      quoteVolume: 62902668.60949415,
      btcVolume: 5311.255677616959,
      usdVolume: 62902668.60949415,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '23.5291000000000000',
      high: '23.9100000000000000',
      low: '22.9584000000000000',
      close: '23.4961000000000000',
      volume: '2423482.1500000000000000',
      quoteVolume: 56563755.666076384,
      btcVolume: 4691.623480656751,
      usdVolume: 56563755.666076384,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '23.4868000000000000',
      high: '23.7800000000000000',
      low: '22.3330000000000000',
      close: '22.9705000000000000',
      volume: '2531759.2800000000000000',
      quoteVolume: 58722035.45027393,
      btcVolume: 4835.174004514033,
      usdVolume: 58722035.45027393,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '22.9802000000000000',
      high: '23.2500000000000000',
      low: '21.8219000000000000',
      close: '22.3761000000000000',
      volume: '2128074.0300000000000000',
      quoteVolume: 47918443.635081224,
      btcVolume: 4064.0451093136103,
      usdVolume: 47918443.635081224,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '22.3817000000000000',
      high: '23.3000000000000000',
      low: '22.0265000000000000',
      close: '23.0888000000000000',
      volume: '2236274.1500000000000000',
      quoteVolume: 50958756.88971377,
      btcVolume: 4320.849344252172,
      usdVolume: 50958756.88971377,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '23.0909000000000000',
      high: '23.3200000000000000',
      low: '21.5000000000000000',
      close: '22.0988000000000000',
      volume: '2489462.0200000000000000',
      quoteVolume: 56223221.93286248,
      btcVolume: 4795.974426477643,
      usdVolume: 56223221.93286248,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '22.0994000000000000',
      high: '22.2464000000000000',
      low: '21.2814000000000000',
      close: '22.2044000000000000',
      volume: '1891753.4200000000000000',
      quoteVolume: 41537070.73814768,
      btcVolume: 3592.3703459321446,
      usdVolume: 41537070.73814768,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '22.2010000000000000',
      high: '22.2348000000000000',
      low: '21.2841000000000000',
      close: '21.8555000000000000',
      volume: '1618939.3500000000000000',
      quoteVolume: 35415367.6439598,
      btcVolume: 3050.172713172957,
      usdVolume: 35415367.6439598,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '21.8575000000000000',
      high: '22.9746000000000000',
      low: '21.6655000000000000',
      close: '22.5953000000000000',
      volume: '1877683.4400000000000000',
      quoteVolume: 42093704.95900781,
      btcVolume: 3588.708992922525,
      usdVolume: 42093704.95900781,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '22.6016000000000000',
      high: '22.6415000000000000',
      low: '20.9805000000000000',
      close: '21.3882000000000000',
      volume: '2214962.9300000000000000',
      quoteVolume: 48368662.81298032,
      btcVolume: 4213.210966957328,
      usdVolume: 48368662.81298032,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '21.3881000000000000',
      high: '22.4743000000000000',
      low: '21.2651000000000000',
      close: '22.1999000000000000',
      volume: '2499792.2900000000000000',
      quoteVolume: 55285830.68548643,
      btcVolume: 4852.707891075785,
      usdVolume: 55285830.68548643,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '22.2005000000000000',
      high: '23.1507000000000000',
      low: '21.8064000000000000',
      close: '23.1127000000000000',
      volume: '2925157.1700000000000000',
      quoteVolume: 65680254.026992634,
      btcVolume: 5788.970095035588,
      usdVolume: 65680254.026992634,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '23.1127000000000000',
      high: '23.5846000000000000',
      low: '22.6158000000000000',
      close: '23.0811000000000000',
      volume: '2526396.8800000000000000',
      quoteVolume: 58274233.91624748,
      btcVolume: 5107.116104849058,
      usdVolume: 58274233.91624748,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '23.0783000000000000',
      high: '23.5000000000000000',
      low: '22.7416000000000000',
      close: '22.9286000000000000',
      volume: '1825207.1700000000000000',
      quoteVolume: 42300003.84051298,
      btcVolume: 3677.51541413538,
      usdVolume: 42300003.84051298,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '22.9441000000000000',
      high: '23.8300000000000000',
      low: '22.9000000000000000',
      close: '23.6208000000000000',
      volume: '1777138.8500000000000000',
      quoteVolume: 41436875.3779341,
      btcVolume: 3568.130657260122,
      usdVolume: 41436875.3779341,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '23.6250000000000000',
      high: '23.9000000000000000',
      low: '22.9670000000000000',
      close: '23.1477000000000000',
      volume: '1767387.7800000000000000',
      quoteVolume: 41283857.21209864,
      btcVolume: 3534.6531834593225,
      usdVolume: 41283857.21209864,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '23.1481000000000000',
      high: '25.4100000000000000',
      low: '22.8400000000000000',
      close: '24.7028000000000000',
      volume: '3998518.4600000000000000',
      quoteVolume: 95349979.86598961,
      btcVolume: 8016.152928036265,
      usdVolume: 95348715.97884162,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '24.7101000000000000',
      high: '25.8262000000000000',
      low: '23.1215000000000000',
      close: '24.7652000000000000',
      volume: '5843722.5300000000000000',
      quoteVolume: 144376866.58587617,
      btcVolume: 12476.71910511825,
      usdVolume: 144376866.58587617,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '24.7653000000000000',
      high: '25.5400000000000000',
      low: '19.8765000000000000',
      close: '20.3592000000000000',
      volume: '7208188.4900000000000000',
      quoteVolume: 168388157.16915026,
      btcVolume: 15323.190970084937,
      usdVolume: 168388157.16915026,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '20.4027000000000000',
      high: '21.8300000000000000',
      low: '19.2000000000000000',
      close: '20.9712000000000000',
      volume: '5304836.8000000000000000',
      quoteVolume: 110005537.1003693,
      btcVolume: 10648.840229664847,
      usdVolume: 110005537.1003693,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '20.9995000000000000',
      high: '21.5539000000000000',
      low: '18.0110000000000000',
      close: '19.4698000000000000',
      volume: '4587657.7700000000000000',
      quoteVolume: 89925282.01269266,
      btcVolume: 8795.872253723111,
      usdVolume: 89925282.01269266,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '19.4737000000000000',
      high: '23.4418000000000000',
      low: '18.5283000000000000',
      close: '23.1420000000000000',
      volume: '8069211.7600000000000000',
      quoteVolume: 174283155.6269918,
      btcVolume: 17068.88087509094,
      usdVolume: 174283155.6269918,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '23.1612000000000000',
      high: '23.5545000000000000',
      low: '20.0000000000000000',
      close: '22.4426000000000000',
      volume: '5402317.0400000000000000',
      quoteVolume: 117739085.20631854,
      btcVolume: 11629.8606720547,
      usdVolume: 117739085.20631854,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '22.4428000000000000',
      high: '23.4200000000000000',
      low: '21.2739000000000000',
      close: '23.2837000000000000',
      volume: '5158563.9800000000000000',
      quoteVolume: 115741375.12774241,
      btcVolume: 11408.651574079575,
      usdVolume: 115741375.12774241,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '23.2745000000000000',
      high: '24.5201000000000000',
      low: '22.4139000000000000',
      close: '24.4716000000000000',
      volume: '4016452.5100000000000000',
      quoteVolume: 94841678.35523182,
      btcVolume: 9328.731014737568,
      usdVolume: 94841678.35523182,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '24.4847000000000000',
      high: '25.6700000000000000',
      low: '24.0568000000000000',
      close: '24.5489000000000000',
      volume: '4197768.0800000000000000',
      quoteVolume: 103939762.77621293,
      btcVolume: 10055.05164439836,
      usdVolume: 103939762.77621293,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '24.5579000000000000',
      high: '25.6969000000000000',
      low: '23.0677000000000000',
      close: '25.3990000000000000',
      volume: '3622605.0700000000000000',
      quoteVolume: 88276634.60726066,
      btcVolume: 8560.638049834533,
      usdVolume: 88276634.60726066,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '25.4000000000000000',
      high: '28.5305000000000000',
      low: '25.3575000000000000',
      close: '28.5050000000000000',
      volume: '6538510.1800000000000000',
      quoteVolume: 175802729.4278746,
      btcVolume: 16975.401468484088,
      usdVolume: 175802729.4278746,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '28.5044000000000000',
      high: '32.4401000000000000',
      low: '27.6100000000000000',
      close: '31.1656000000000000',
      volume: '10646215.6600000000000000',
      quoteVolume: 324162248.7407463,
      btcVolume: 31172.01029736164,
      usdVolume: 324162248.7407463,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '31.1656000000000000',
      high: '33.3888000000000000',
      low: '29.0000000000000000',
      close: '31.2523000000000000',
      volume: '9039644.3200000000000000',
      quoteVolume: 282868031.2655408,
      btcVolume: 26883.24594624267,
      usdVolume: 282868031.2655408,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '31.2546000000000000',
      high: '31.2780000000000000',
      low: '26.7123000000000000',
      close: '27.1181000000000000',
      volume: '7931354.6460000000000000',
      quoteVolume: 228427870.13850227,
      btcVolume: 21238.609622009128,
      usdVolume: 228427870.13850227,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '27.1148000000000000',
      high: '28.4724000000000000',
      low: '25.8200000000000000',
      close: '27.8695000000000000',
      volume: '5777419.7520000000000000',
      quoteVolume: 157849897.40358767,
      btcVolume: 14525.119593023768,
      usdVolume: 157849897.40358767,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '27.8539000000000000',
      high: '28.8300000000000000',
      low: '26.0500000000000000',
      close: '27.0950000000000000',
      volume: '4620518.8740000000000000',
      quoteVolume: 125923630.01926374,
      btcVolume: 11551.914313428693,
      usdVolume: 125923630.01926374,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '27.1106000000000000',
      high: '28.7500000000000000',
      low: '26.5708000000000000',
      close: '27.6290000000000000',
      volume: '4243135.9990000000000000',
      quoteVolume: 117188169.08344378,
      btcVolume: 10721.855220981095,
      usdVolume: 117188169.08344378,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '27.6414000000000000',
      high: '27.9770000000000000',
      low: '26.6591000000000000',
      close: '27.2035000000000000',
      volume: '2431517.1500000000000000',
      quoteVolume: 66209405.92737906,
      btcVolume: 6017.836049060832,
      usdVolume: 66209405.92737906,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '27.2035000000000000',
      high: '27.3200000000000000',
      low: '25.1247000000000000',
      close: '26.3160000000000000',
      volume: '2843429.9720000000000000',
      quoteVolume: 74991076.55056357,
      btcVolume: 6881.997502694567,
      usdVolume: 74991076.55056357,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '26.3167000000000000',
      high: '26.6800000000000000',
      low: '22.1000000000000000',
      close: '23.1232000000000000',
      volume: '5251422.2430000000000000',
      quoteVolume: 127147745.41469589,
      btcVolume: 11950.186281642047,
      usdVolume: 127147745.41469589,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '23.1273000000000000',
      high: '24.5650000000000000',
      low: '22.6666000000000000',
      close: '24.0468000000000000',
      volume: '3281775.4620000000000000',
      quoteVolume: 77971128.39187472,
      btcVolume: 7453.267500200181,
      usdVolume: 77971128.39187472,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '24.0468000000000000',
      high: '24.3437000000000000',
      low: '22.4514000000000000',
      close: '22.8331000000000000',
      volume: '3134141.7740000000000000',
      quoteVolume: 73949538.77212654,
      btcVolume: 7097.075147605696,
      usdVolume: 73949538.77212654,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '22.8448000000000000',
      high: '24.6999000000000000',
      low: '22.5661000000000000',
      close: '24.5745000000000000',
      volume: '3280582.2240000000000000',
      quoteVolume: 78057236.24715658,
      btcVolume: 7468.991503938146,
      usdVolume: 78057236.24715658,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '24.5749000000000000',
      high: '25.0799000000000000',
      low: '23.7111000000000000',
      close: '24.6924000000000000',
      volume: '2826869.5890000000000000',
      quoteVolume: 69263025.79303573,
      btcVolume: 6491.558933280871,
      usdVolume: 69263025.79303573,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '24.6925000000000000',
      high: '26.4628000000000000',
      low: '24.4750000000000000',
      close: '26.1998000000000000',
      volume: '3109951.9450000000000000',
      quoteVolume: 79026166.8893397,
      btcVolume: 7375.746915791385,
      usdVolume: 79026166.8893397,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '26.1885000000000000',
      high: '26.9000000000000000',
      low: '25.5012000000000000',
      close: '26.2510000000000000',
      volume: '2549943.8930000000000000',
      quoteVolume: 66948796.67374076,
      btcVolume: 6245.902387721704,
      usdVolume: 66948796.67374076,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '26.2435000000000000',
      high: '27.7200000000000000',
      low: '25.8313000000000000',
      close: '26.9855000000000000',
      volume: '3215302.9540000000000000',
      quoteVolume: 85939137.96800753,
      btcVolume: 7904.878434360903,
      usdVolume: 85939137.96800753,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '26.9969000000000000',
      high: '29.0000000000000000',
      low: '26.8600000000000000',
      close: '28.8946000000000000',
      volume: '3642652.3360000000000000',
      quoteVolume: 102012505.98227179,
      btcVolume: 9504.594750278005,
      usdVolume: 102012505.98227179,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '28.8814000000000000',
      high: '29.5646000000000000',
      low: '27.8813000000000000',
      close: '29.2656000000000000',
      volume: '3102332.1810000000000000',
      quoteVolume: 89000015.31908017,
      btcVolume: 8286.090073062971,
      usdVolume: 89000015.31908017,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '29.2696000000000000',
      high: '29.3927000000000000',
      low: '26.2111000000000000',
      close: '27.4720000000000000',
      volume: '4000390.3250000000000000',
      quoteVolume: 111543168.04777229,
      btcVolume: 10423.810924564852,
      usdVolume: 111543168.04777229,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '27.3367000000000000',
      high: '28.3633000000000000',
      low: '25.6652000000000000',
      close: '27.3369000000000000',
      volume: '4117221.0050000000000000',
      quoteVolume: 111198198.15562725,
      btcVolume: 10572.72520169783,
      usdVolume: 111198198.15562725,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '27.3338000000000000',
      high: '28.3997000000000000',
      low: '27.0701000000000000',
      close: '28.1867000000000000',
      volume: '2946071.9920000000000000',
      quoteVolume: 81824336.491502,
      btcVolume: 7759.17949681676,
      usdVolume: 81824336.491502,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '28.1872000000000000',
      high: '29.1500000000000000',
      low: '27.9658000000000000',
      close: '28.9668000000000000',
      volume: '3102597.6600000000000000',
      quoteVolume: 89029435.36271603,
      btcVolume: 8394.366201707255,
      usdVolume: 89029435.36271603,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '28.9832000000000000',
      high: '29.2665000000000000',
      low: '28.0687000000000000',
      close: '28.6158000000000000',
      volume: '2268257.1710000000000000',
      quoteVolume: 65226739.00450134,
      btcVolume: 6100.3480015059995,
      usdVolume: 65226739.00450134,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '28.6330000000000000',
      high: '28.9900000000000000',
      low: '27.2500000000000000',
      close: '27.6812000000000000',
      volume: '2810020.1650000000000000',
      quoteVolume: 79203442.53717537,
      btcVolume: 7402.756056350025,
      usdVolume: 79203442.53717537,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '27.6907000000000000',
      high: '27.8965000000000000',
      low: '26.8401000000000000',
      close: '27.6898000000000000',
      volume: '2059912.1820000000000000',
      quoteVolume: 56521007.48227771,
      btcVolume: 5326.109268024313,
      usdVolume: 56521007.48227771,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '27.6931000000000000',
      high: '27.8153000000000000',
      low: '26.4999000000000000',
      close: '27.7424000000000000',
      volume: '2423081.9940000000000000',
      quoteVolume: 66102858.40443837,
      btcVolume: 6161.524987847587,
      usdVolume: 66102858.40443837,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '27.7342000000000000',
      high: '28.5368000000000000',
      low: '27.4548000000000000',
      close: '28.4076000000000000',
      volume: '2590468.0830000000000000',
      quoteVolume: 72830100.20340902,
      btcVolume: 6638.910063675278,
      usdVolume: 72830100.20340902,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '28.3980000000000000',
      high: '29.2814000000000000',
      low: '28.0666000000000000',
      close: '28.4414000000000000',
      volume: '2405371.4570000000000000',
      quoteVolume: 68983739.73955177,
      btcVolume: 6094.207111159801,
      usdVolume: 68983739.73955177,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '28.4100000000000000',
      high: '28.7200000000000000',
      low: '27.8760000000000000',
      close: '28.5271000000000000',
      volume: '2287361.9230000000000000',
      quoteVolume: 64862236.42497369,
      btcVolume: 5711.44629460792,
      usdVolume: 64862236.42497369,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '28.5200000000000000',
      high: '31.1299000000000000',
      low: '28.4338000000000000',
      close: '30.6503000000000000',
      volume: '4002611.1740000000000000',
      quoteVolume: 120352867.44459017,
      btcVolume: 10544.280084904543,
      usdVolume: 120352867.44459017,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '30.6503000000000000',
      high: '31.7999000000000000',
      low: '30.1870000000000000',
      close: '30.7256000000000000',
      volume: '3052275.4880000000000000',
      quoteVolume: 94547125.33016007,
      btcVolume: 8257.112105221257,
      usdVolume: 94547125.33016007,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '30.7256000000000000',
      high: '31.3300000000000000',
      low: '30.3733000000000000',
      close: '30.8380000000000000',
      volume: '2235892.8320000000000000',
      quoteVolume: 68852635.2776885,
      btcVolume: 6038.336550994363,
      usdVolume: 68852635.2776885,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '30.8380000000000000',
      high: '31.6663000000000000',
      low: '30.3990000000000000',
      close: '31.3256000000000000',
      volume: '2664333.4610000000000000',
      quoteVolume: 82806329.4986107,
      btcVolume: 7257.335240346204,
      usdVolume: 82806329.4986107,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '31.3256000000000000',
      high: '31.9798000000000000',
      low: '29.5705000000000000',
      close: '30.0516000000000000',
      volume: '2971307.3670000000000000',
      quoteVolume: 90779001.72612782,
      btcVolume: 7990.956897298697,
      usdVolume: 90779001.72612782,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '30.0510000000000000',
      high: '30.5640000000000000',
      low: '29.6521000000000000',
      close: '30.2414000000000000',
      volume: '1845150.5060000000000000',
      quoteVolume: 55749675.69959583,
      btcVolume: 4918.479991791265,
      usdVolume: 55749675.69959583,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '30.2423000000000000',
      high: '31.0354000000000000',
      low: '30.1650000000000000',
      close: '30.6593000000000000',
      volume: '2267377.3170000000000000',
      quoteVolume: 69506327.30622022,
      btcVolume: 6086.495770877648,
      usdVolume: 69506327.30622022,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '30.6568000000000000',
      high: '30.6766000000000000',
      low: '29.7002000000000000',
      close: '29.9107000000000000',
      volume: '1752644.9990000000000000',
      quoteVolume: 52880539.760139465,
      btcVolume: 4575.7130204564255,
      usdVolume: 52880539.760139465,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '29.9043000000000000',
      high: '30.0892000000000000',
      low: '28.5000000000000000',
      close: '28.8227000000000000',
      volume: '2583242.4400000000000000',
      quoteVolume: 75672316.51136452,
      btcVolume: 6399.37021187418,
      usdVolume: 75672316.51136452,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '28.8131000000000000',
      high: '30.4074000000000000',
      low: '28.6406000000000000',
      close: '29.7329000000000000',
      volume: '2663615.8890000000000000',
      quoteVolume: 78865122.07201448,
      btcVolume: 6329.800794199335,
      usdVolume: 78865122.07201448,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '29.7355000000000000',
      high: '31.3000000000000000',
      low: '29.6655000000000000',
      close: '30.6396000000000000',
      volume: '2830772.7050000000000000',
      quoteVolume: 86718736.3816204,
      btcVolume: 6732.909811327086,
      usdVolume: 86718736.3816204,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '30.6334000000000000',
      high: '31.2000000000000000',
      low: '30.0652000000000000',
      close: '30.3066000000000000',
      volume: '1751841.2190000000000000',
      quoteVolume: 53822247.52955811,
      btcVolume: 4166.146373136381,
      usdVolume: 53822247.52955811,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '30.6461000000000000',
      high: '30.8781000000000000',
      low: '30.4243000000000000',
      close: '30.6639000000000000',
      volume: '1625502.5240000000000000',
      quoteVolume: 49794483.44654,
      btcVolume: 3828.381409664332,
      usdVolume: 49794483.44654,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '30.6522000000000000',
      high: '30.9726000000000000',
      low: '29.8805000000000000',
      close: '30.1280000000000000',
      volume: '1486018.1520000000000000',
      quoteVolume: 45184192.15742622,
      btcVolume: 3461.2183046942464,
      usdVolume: 45184192.15742622,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '30.1342000000000000',
      high: '31.4300000000000000',
      low: '29.9600000000000000',
      close: '31.0467000000000000',
      volume: '2647734.6210000000000000',
      quoteVolume: 81431799.40994392,
      btcVolume: 6237.364724196646,
      usdVolume: 81431799.40994392,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '31.0436000000000000',
      high: '32.3788000000000000',
      low: '30.9000000000000000',
      close: '31.4826000000000000',
      volume: '2881430.2220000000000000',
      quoteVolume: 91088211.45629856,
      btcVolume: 6829.721068843635,
      usdVolume: 91088211.45629856,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '31.4730000000000000',
      high: '31.9375000000000000',
      low: '29.8000000000000000',
      close: '30.3338000000000000',
      volume: '2052118.9290000000000000',
      quoteVolume: 63425566.100130275,
      btcVolume: 4717.743672827569,
      usdVolume: 63425566.100130275,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '30.3295000000000000',
      high: '30.4272000000000000',
      low: '29.4700000000000000',
      close: '29.8702000000000000',
      volume: '1977911.0400000000000000',
      quoteVolume: 59517443.808163345,
      btcVolume: 4471.350986754041,
      usdVolume: 59517443.808163345,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '29.8696000000000000',
      high: '30.2596000000000000',
      low: '27.5111000000000000',
      close: '28.4909000000000000',
      volume: '3211305.3580000000000000',
      quoteVolume: 91773152.80737089,
      btcVolume: 6865.029260469122,
      usdVolume: 91773152.80737089,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '28.4969000000000000',
      high: '28.8880000000000000',
      low: '28.1132000000000000',
      close: '28.5058000000000000',
      volume: '1975871.5750000000000000',
      quoteVolume: 56382966.93345828,
      btcVolume: 4104.696666980671,
      usdVolume: 56382966.93345828,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '28.5058000000000000',
      high: '28.6790000000000000',
      low: '28.0000000000000000',
      close: '28.5318000000000000',
      volume: '1916131.6810000000000000',
      quoteVolume: 54186354.18994433,
      btcVolume: 3937.006778622533,
      usdVolume: 54186354.18994433,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '28.5485000000000000',
      high: '29.3079000000000000',
      low: '27.7782000000000000',
      close: '27.7983000000000000',
      volume: '2123599.0520000000000000',
      quoteVolume: 60737427.18014957,
      btcVolume: 4463.645604676236,
      usdVolume: 60737427.18014957,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '27.7984000000000000',
      high: '27.7984000000000000',
      low: '25.7499000000000000',
      close: '26.8638000000000000',
      volume: '2591560.8550000000000000',
      quoteVolume: 69103680.17142187,
      btcVolume: 5107.912094364955,
      usdVolume: 69103680.17142187,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '26.8722000000000000',
      high: '26.9700000000000000',
      low: '25.9000000000000000',
      close: '26.9600000000000000',
      volume: '2039985.3400000000000000',
      quoteVolume: 54040159.97019821,
      btcVolume: 3890.820934023125,
      usdVolume: 54040159.97019821,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '26.9607000000000000',
      high: '27.8947000000000000',
      low: '26.6601000000000000',
      close: '27.6875000000000000',
      volume: '2396900.8220000000000000',
      quoteVolume: 65625192.2818929,
      btcVolume: 4465.977407660962,
      usdVolume: 65625192.2818929,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '27.6860000000000000',
      high: '29.4692000000000000',
      low: '27.6668000000000000',
      close: '29.0435000000000000',
      volume: '2705358.2900000000000000',
      quoteVolume: 77745649.67222922,
      btcVolume: 4990.296761515876,
      usdVolume: 77745649.67222922,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '29.0391000000000000',
      high: '29.8990000000000000',
      low: '26.8500000000000000',
      close: '27.7651000000000000',
      volume: '3088858.1960000000000000',
      quoteVolume: 88661320.14317802,
      btcVolume: 5796.6328340812925,
      usdVolume: 88661320.14317802,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '27.7628000000000000',
      high: '28.6943000000000000',
      low: '27.4490000000000000',
      close: '28.4101000000000000',
      volume: '2296984.2220000000000000',
      quoteVolume: 64827072.49203944,
      btcVolume: 4258.489159084323,
      usdVolume: 64827072.49203944,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '28.4101000000000000',
      high: '28.9691000000000000',
      low: '27.3461000000000000',
      close: '28.0941000000000000',
      volume: '2147857.4210000000000000',
      quoteVolume: 60633554.16222215,
      btcVolume: 3946.070465446345,
      usdVolume: 60633554.16222215,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '28.0836000000000000',
      high: '28.4880000000000000',
      low: '27.6114000000000000',
      close: '28.2188000000000000',
      volume: '2172589.6330000000000000',
      quoteVolume: 61108455.63378035,
      btcVolume: 3990.1571339070315,
      usdVolume: 61108455.63378035,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '28.2183000000000000',
      high: '28.5999000000000000',
      low: '27.7966000000000000',
      close: '28.0160000000000000',
      volume: '1974074.3100000000000000',
      quoteVolume: 55629780.26008157,
      btcVolume: 3569.799698588421,
      usdVolume: 55629780.26008157,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '28.0057000000000000',
      high: '28.1599000000000000',
      low: '27.3028000000000000',
      close: '27.6874000000000000',
      volume: '1661489.2860000000000000',
      quoteVolume: 46197453.38885523,
      btcVolume: 2911.5701341389563,
      usdVolume: 46197453.38885523,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '27.7019000000000000',
      high: '28.5198000000000000',
      low: '27.5284000000000000',
      close: '28.1974000000000000',
      volume: '1870570.7210000000000000',
      quoteVolume: 52380431.54643035,
      btcVolume: 3219.0015810697378,
      usdVolume: 52380431.54643035,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '28.1974000000000000',
      high: '28.1974000000000000',
      low: '27.3684000000000000',
      close: '27.9311000000000000',
      volume: '1491728.0070000000000000',
      quoteVolume: 41393342.505827054,
      btcVolume: 2581.1714629579965,
      usdVolume: 41393342.505827054,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '27.9359000000000000',
      high: '28.2332000000000000',
      low: '27.0700000000000000',
      close: '27.6786000000000000',
      volume: '1441384.5610000000000000',
      quoteVolume: 40098284.74564967,
      btcVolume: 2509.6270634778307,
      usdVolume: 40098284.74564967,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '27.6822000000000000',
      high: '28.2202000000000000',
      low: '27.4461000000000000',
      close: '27.9451000000000000',
      volume: '1684972.1740000000000000',
      quoteVolume: 46951053.47256122,
      btcVolume: 2873.554556801199,
      usdVolume: 46951053.47256122,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '27.9588000000000000',
      high: '28.8000000000000000',
      low: '27.9262000000000000',
      close: '28.5552000000000000',
      volume: '2165992.6190000000000000',
      quoteVolume: 61647606.24655668,
      btcVolume: 3620.0821814901874,
      usdVolume: 61647606.24655668,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '28.5552000000000000',
      high: '28.8380000000000000',
      low: '27.5000000000000000',
      close: '28.0931000000000000',
      volume: '2208116.0360000000000000',
      quoteVolume: 62179324.88033911,
      btcVolume: 3481.8660558290812,
      usdVolume: 62179324.88033911,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '28.1049000000000000',
      high: '28.3666000000000000',
      low: '27.6183000000000000',
      close: '28.1387000000000000',
      volume: '1978784.7070000000000000',
      quoteVolume: 55532045.828950375,
      btcVolume: 3111.2806511064537,
      usdVolume: 55532045.828950375,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '28.1266000000000000',
      high: '29.3516000000000000',
      low: '28.0598000000000000',
      close: '28.9715000000000000',
      volume: '2462083.4030000000000000',
      quoteVolume: 70582295.03196461,
      btcVolume: 3855.968113643725,
      usdVolume: 70582295.03196461,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '28.9722000000000000',
      high: '30.8000000000000000',
      low: '28.2955000000000000',
      close: '30.5161000000000000',
      volume: '3160335.4720000000000000',
      quoteVolume: 93653968.66348147,
      btcVolume: 5004.286317743498,
      usdVolume: 93653968.66348147,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '30.5168000000000000',
      high: '31.1200000000000000',
      low: '29.0000000000000000',
      close: '30.0226000000000000',
      volume: '2842370.7830000000000000',
      quoteVolume: 85423529.80392088,
      btcVolume: 4642.671182761763,
      usdVolume: 85423529.80392088,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '30.0291000000000000',
      high: '31.1000000000000000',
      low: '29.7860000000000000',
      close: '30.9950000000000000',
      volume: '2854700.3560000000000000',
      quoteVolume: 86945280.82502285,
      btcVolume: 4722.192647593682,
      usdVolume: 86945280.82502285,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '30.9994000000000000',
      high: '35.1400000000000000',
      low: '30.7934000000000000',
      close: '33.9407000000000000',
      volume: '7296367.3690000000000000',
      quoteVolume: 240609850.8251657,
      btcVolume: 12879.90776771163,
      usdVolume: 240609850.8251657,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '33.9451000000000000',
      high: '35.4338000000000000',
      low: '30.4390000000000000',
      close: '31.2953000000000000',
      volume: '4579889.4800000000000000',
      quoteVolume: 152154802.22398162,
      btcVolume: 7996.9340517331775,
      usdVolume: 152154802.22398162,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '31.2950000000000000',
      high: '31.7708000000000000',
      low: '26.3500000000000000',
      close: '28.2356000000000000',
      volume: '6241853.0130000000000000',
      quoteVolume: 179684915.43192223,
      btcVolume: 10317.064916775644,
      usdVolume: 179684915.43192223,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '28.2354000000000000',
      high: '28.7175000000000000',
      low: '27.2042000000000000',
      close: '28.4606000000000000',
      volume: '2317359.7950000000000000',
      quoteVolume: 65177587.384269916,
      btcVolume: 3831.3766146767975,
      usdVolume: 65177587.384269916,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '28.4606000000000000',
      high: '29.6175000000000000',
      low: '27.9000000000000000',
      close: '29.1766000000000000',
      volume: '2636604.6020000000000000',
      quoteVolume: 76332488.51250012,
      btcVolume: 4378.493625971485,
      usdVolume: 76332488.51250012,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '29.1552000000000000',
      high: '30.4500000000000000',
      low: '28.8544000000000000',
      close: '30.1045000000000000',
      volume: '2856408.5640000000000000',
      quoteVolume: 84710614.21822041,
      btcVolume: 4707.608681471879,
      usdVolume: 84710614.21822041,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '30.1003000000000000',
      high: '31.5999000000000000',
      low: '29.7230000000000000',
      close: '31.4935000000000000',
      volume: '2977355.3390000000000000',
      quoteVolume: 91514232.68682995,
      btcVolume: 4817.517391122217,
      usdVolume: 91514232.68682995,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '31.4935000000000000',
      high: '31.8875000000000000',
      low: '29.1700000000000000',
      close: '30.1996000000000000',
      volume: '3066237.6600000000000000',
      quoteVolume: 94399139.74031925,
      btcVolume: 4914.238089053225,
      usdVolume: 94399139.74031925,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '30.2003000000000000',
      high: '30.9993000000000000',
      low: '29.5306000000000000',
      close: '30.2875000000000000',
      volume: '2360296.3080000000000000',
      quoteVolume: 71525356.8507045,
      btcVolume: 3776.942602662899,
      usdVolume: 71525356.8507045,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '30.2724000000000000',
      high: '30.7930000000000000',
      low: '29.8568000000000000',
      close: '30.7065000000000000',
      volume: '1924462.4660000000000000',
      quoteVolume: 58447530.68081103,
      btcVolume: 3036.9154023239544,
      usdVolume: 58447530.68081103,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '30.7099000000000000',
      high: '31.1371000000000000',
      low: '28.7051000000000000',
      close: '29.0231000000000000',
      volume: '2064562.0540000000000000',
      quoteVolume: 61840304.01373671,
      btcVolume: 3237.434025284598,
      usdVolume: 61840304.01373671,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '29.0315000000000000',
      high: '30.0588000000000000',
      low: '28.6588000000000000',
      close: '29.7280000000000000',
      volume: '1836006.3170000000000000',
      quoteVolume: 53868352.508677736,
      btcVolume: 2845.6790819435187,
      usdVolume: 53868352.508677736,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '29.7296000000000000',
      high: '30.1819000000000000',
      low: '29.0124000000000000',
      close: '29.5026000000000000',
      volume: '1330813.8990000000000000',
      quoteVolume: 39419545.068958886,
      btcVolume: 2055.588312615177,
      usdVolume: 39419545.068958886,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '29.4912000000000000',
      high: '29.6685000000000000',
      low: '29.0600000000000000',
      close: '29.3700000000000000',
      volume: '1219543.1660000000000000',
      quoteVolume: 35795857.65738368,
      btcVolume: 1864.6156139549516,
      usdVolume: 35795857.65738368,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '29.3705000000000000',
      high: '29.4998000000000000',
      low: '27.4500000000000000',
      close: '27.6705000000000000',
      volume: '1844102.0560000000000000',
      quoteVolume: 52562094.302194595,
      btcVolume: 2782.158122499107,
      usdVolume: 52562094.302194595,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '27.6705000000000000',
      high: '28.3853000000000000',
      low: '26.9244000000000000',
      close: '28.1452000000000000',
      volume: '1635344.1810000000000000',
      quoteVolume: 45454697.5386892,
      btcVolume: 2491.802163982006,
      usdVolume: 45454697.5386892,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '28.1440000000000000',
      high: '28.2997000000000000',
      low: '27.3900000000000000',
      close: '27.6926000000000000',
      volume: '1338821.6330000000000000',
      quoteVolume: 37239316.75626387,
      btcVolume: 2036.0163366628099,
      usdVolume: 37239316.75626387,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '27.6950000000000000',
      high: '27.7700000000000000',
      low: '26.7273000000000000',
      close: '27.3419000000000000',
      volume: '1185571.0640000000000000',
      quoteVolume: 32228789.764525007,
      btcVolume: 1796.9005359462299,
      usdVolume: 32222415.98784421,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '27.3490000000000000',
      high: '28.2300000000000000',
      low: '27.2542000000000000',
      close: '28.1603000000000000',
      volume: '1209119.9800000000000000',
      quoteVolume: 33698868.29087121,
      btcVolume: 1826.7844744499994,
      usdVolume: 33698868.29087121,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '28.1603000000000000',
      high: '29.2628000000000000',
      low: '28.0000000000000000',
      close: '29.1571000000000000',
      volume: '1639495.8160000000000000',
      quoteVolume: 47146012.390196115,
      btcVolume: 2467.453865154321,
      usdVolume: 47146012.390196115,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '29.1599000000000000',
      high: '30.1884000000000000',
      low: '28.6669000000000000',
      close: '30.0338000000000000',
      volume: '2025868.5440000000000000',
      quoteVolume: 59594740.44459001,
      btcVolume: 3107.6269302330743,
      usdVolume: 59594740.44459001,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '30.0338000000000000',
      high: '30.3790000000000000',
      low: '29.3069000000000000',
      close: '29.5053000000000000',
      volume: '1536633.4260000000000000',
      quoteVolume: 45782401.5646542,
      btcVolume: 2367.206132560912,
      usdVolume: 45782401.5646542,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '29.5059000000000000',
      high: '30.6140000000000000',
      low: '29.0265000000000000',
      close: '30.5577000000000000',
      volume: '2034306.1120000000000000',
      quoteVolume: 60707183.55854131,
      btcVolume: 2993.371881466091,
      usdVolume: 60707183.55854131,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '30.5524000000000000',
      high: '31.5489000000000000',
      low: '29.6891000000000000',
      close: '30.2255000000000000',
      volume: '3274786.1310000000000000',
      quoteVolume: 100846680.86741851,
      btcVolume: 4478.236654440235,
      usdVolume: 100846680.86741851,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '30.2254000000000000',
      high: '31.2172000000000000',
      low: '29.6100000000000000',
      close: '30.9882000000000000',
      volume: '2045132.3370000000000000',
      quoteVolume: 62437403.31907742,
      btcVolume: 2727.6635487497656,
      usdVolume: 62437403.31907742,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '30.9858000000000000',
      high: '34.2307000000000000',
      low: '30.8812000000000000',
      close: '33.3167000000000000',
      volume: '4117460.2930000000000000',
      quoteVolume: 134985674.6489003,
      btcVolume: 5801.552675700171,
      usdVolume: 134985674.6489003,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '33.3018000000000000',
      high: '35.6900000000000000',
      low: '32.5899000000000000',
      close: '34.3148000000000000',
      volume: '4477498.8180000000000000',
      quoteVolume: 153515421.81364313,
      btcVolume: 6509.728499602096,
      usdVolume: 153515421.81364313,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '34.3072000000000000',
      high: '35.6000000000000000',
      low: '31.4871000000000000',
      close: '32.0500000000000000',
      volume: '4525705.7910000000000000',
      quoteVolume: 151775639.78318813,
      btcVolume: 6527.479468708382,
      usdVolume: 151775639.78318813,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '32.0500000000000000',
      high: '33.8000000000000000',
      low: '30.4719000000000000',
      close: '33.5151000000000000',
      volume: '3563635.6730000000000000',
      quoteVolume: 115167307.80793788,
      btcVolume: 5006.055644717137,
      usdVolume: 115167307.80793788,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '33.5112000000000000',
      high: '34.5050000000000000',
      low: '30.0000000000000000',
      close: '31.1843000000000000',
      volume: '3940926.4420000000000000',
      quoteVolume: 128877133.69173539,
      btcVolume: 5483.664774174263,
      usdVolume: 128877133.69173539,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '31.1698000000000000',
      high: '32.7589000000000000',
      low: '30.0000000000000000',
      close: '32.4895000000000000',
      volume: '2999570.8530000000000000',
      quoteVolume: 94739220.62729084,
      btcVolume: 4084.295268120996,
      usdVolume: 94739220.62729084,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '32.4947000000000000',
      high: '33.4954000000000000',
      low: '31.7313000000000000',
      close: '33.1996000000000000',
      volume: '2595633.3640000000000000',
      quoteVolume: 84823958.91861957,
      btcVolume: 3526.3258018350043,
      usdVolume: 84823958.91861957,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '33.1996000000000000',
      high: '34.0495000000000000',
      low: '32.5100000000000000',
      close: '33.6361000000000000',
      volume: '2418941.5440000000000000',
      quoteVolume: 80768632.43812405,
      btcVolume: 3199.458679420725,
      usdVolume: 80768632.43812405,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '33.6510000000000000',
      high: '34.9900000000000000',
      low: '31.8601000000000000',
      close: '33.6740000000000000',
      volume: '3747872.7180000000000000',
      quoteVolume: 126569474.2263926,
      btcVolume: 4663.94819780864,
      usdVolume: 126569474.2263926,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '33.6741000000000000',
      high: '36.1500000000000000',
      low: '33.3105000000000000',
      close: '35.9510000000000000',
      volume: '4121783.1030000000000000',
      quoteVolume: 145163638.0339237,
      btcVolume: 5381.500496375316,
      usdVolume: 145163638.0339237,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '35.9507000000000000',
      high: '39.9999000000000000',
      low: '35.3015000000000000',
      close: '39.0667000000000000',
      volume: '7022548.4870000000000000',
      quoteVolume: 266651443.80075303,
      btcVolume: 10020.386760643316,
      usdVolume: 266651443.80075303,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '39.0746000000000000',
      high: '39.2700000000000000',
      low: '36.8131000000000000',
      close: '38.1558000000000000',
      volume: '2424623.4670000000000000',
      quoteVolume: 91497596.04163969,
      btcVolume: 3260.3633178562527,
      usdVolume: 91497596.04163969,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '38.1558000000000000',
      high: '38.2133000000000000',
      low: '36.5157000000000000',
      close: '37.3588000000000000',
      volume: '1887715.8350000000000000',
      quoteVolume: 70486125.96259664,
      btcVolume: 2446.931071296564,
      usdVolume: 70486125.96259664,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '37.3596000000000000',
      high: '38.9590000000000000',
      low: '36.9555000000000000',
      close: '37.7762000000000000',
      volume: '2433482.2970000000000000',
      quoteVolume: 92264806.14913891,
      btcVolume: 3158.8112751452077,
      usdVolume: 92264806.14913891,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '37.7637000000000000',
      high: '38.8798000000000000',
      low: '36.8000000000000000',
      close: '38.2331000000000000',
      volume: '3018062.2060000000000000',
      quoteVolume: 114060050.8496273,
      btcVolume: 3673.549570252766,
      usdVolume: 114060050.8496273,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '38.2331000000000000',
      high: '41.7017000000000000',
      low: '37.7630000000000000',
      close: '41.2575000000000000',
      volume: '4903626.6970000000000000',
      quoteVolume: 195224916.82053122,
      btcVolume: 5801.770817073169,
      usdVolume: 195224916.82053122,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '41.2560000000000000',
      high: '43.2039000000000000',
      low: '38.0200000000000000',
      close: '41.1333000000000000',
      volume: '5306248.1660000000000000',
      quoteVolume: 214835112.0872529,
      btcVolume: 6762.2209205324225,
      usdVolume: 214835112.0872529,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '41.1300000000000000',
      high: '41.8375000000000000',
      low: '38.8014000000000000',
      close: '41.8219000000000000',
      volume: '3513013.9910000000000000',
      quoteVolume: 141826591.3171311,
      btcVolume: 4386.074240907546,
      usdVolume: 141826591.3171311,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '41.8271000000000000',
      high: '42.2670000000000000',
      low: '40.4531000000000000',
      close: '42.2417000000000000',
      volume: '3784356.4310000000000000',
      quoteVolume: 156400986.59603542,
      btcVolume: 4502.298574139992,
      usdVolume: 156400986.59603542,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '42.2440000000000000',
      high: '44.9000000000000000',
      low: '41.3660000000000000',
      close: '43.5728000000000000',
      volume: '5010228.3040000000000000',
      quoteVolume: 216002626.83736652,
      btcVolume: 5709.586809164074,
      usdVolume: 216002626.83736652,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '43.5728000000000000',
      high: '43.7220000000000000',
      low: '40.2313000000000000',
      close: '42.3560000000000000',
      volume: '3545616.4530000000000000',
      quoteVolume: 149813003.67615464,
      btcVolume: 3783.9907502632377,
      usdVolume: 149813003.67615464,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '42.3450000000000000',
      high: '44.0552000000000000',
      low: '41.5000000000000000',
      close: '43.8479000000000000',
      volume: '2720363.6360000000000000',
      quoteVolume: 116290473.53861824,
      btcVolume: 2885.4307702783954,
      usdVolume: 116290473.53861824,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '43.8479000000000000',
      high: '45.1620000000000000',
      low: '40.0000000000000000',
      close: '42.4031000000000000',
      volume: '4276146.4430000000000000',
      quoteVolume: 185111910.32453433,
      btcVolume: 4685.988559488082,
      usdVolume: 185111910.32453433,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '42.4033000000000000',
      high: '42.5094000000000000',
      low: '35.0374000000000000',
      close: '38.1674000000000000',
      volume: '6282218.7140000000000000',
      quoteVolume: 241222935.06371692,
      btcVolume: 7090.358330981841,
      usdVolume: 241222935.06371692,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '38.1623000000000000',
      high: '40.1989000000000000',
      low: '37.0000000000000000',
      close: '38.2541000000000000',
      volume: '2967129.8990000000000000',
      quoteVolume: 114541059.98171715,
      btcVolume: 3279.5902550381034,
      usdVolume: 114541059.98171715,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '38.2703000000000000',
      high: '40.4499000000000000',
      low: '37.0200000000000000',
      close: '40.1114000000000000',
      volume: '3456796.7670000000000000',
      quoteVolume: 133704300.47653846,
      btcVolume: 3841.1434871509555,
      usdVolume: 133704300.47653846,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '40.1114000000000000',
      high: '42.0000000000000000',
      low: '39.5739000000000000',
      close: '41.8723000000000000',
      volume: '3306689.9330000000000000',
      quoteVolume: 135085613.30355036,
      btcVolume: 3524.8729501905395,
      usdVolume: 135085613.30355036,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '41.8721000000000000',
      high: '42.5622000000000000',
      low: '38.6616000000000000',
      close: '40.8668000000000000',
      volume: '3239226.4660000000000000',
      quoteVolume: 132807501.32071899,
      btcVolume: 3551.40548928815,
      usdVolume: 132807501.32071899,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '40.8951000000000000',
      high: '44.1200000000000000',
      low: '40.6250000000000000',
      close: '43.1420000000000000',
      volume: '3033653.3580000000000000',
      quoteVolume: 129106934.07833531,
      btcVolume: 3495.5013833004346,
      usdVolume: 129106934.07833531,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '43.1413000000000000',
      high: '46.7228000000000000',
      low: '40.9192000000000000',
      close: '45.8675000000000000',
      volume: '3878914.2990000000000000',
      quoteVolume: 169754085.7227777,
      btcVolume: 4749.843457538563,
      usdVolume: 169754085.7227777,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '45.8645000000000000',
      high: '46.8888000000000000',
      low: '44.0278000000000000',
      close: '45.3412000000000000',
      volume: '3069027.8490000000000000',
      quoteVolume: 139380249.91174093,
      btcVolume: 3868.6820587698926,
      usdVolume: 139380249.91174093,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '45.3592000000000000',
      high: '47.2187000000000000',
      low: '41.7000000000000000',
      close: '42.5241000000000000',
      volume: '5142469.4010000000000000',
      quoteVolume: 229041236.3572534,
      btcVolume: 6215.5026986066505,
      usdVolume: 229041236.3572534,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '42.5241000000000000',
      high: '43.0992000000000000',
      low: '40.2000000000000000',
      close: '42.6069000000000000',
      volume: '3707024.5780000000000000',
      quoteVolume: 154379904.5654336,
      btcVolume: 4404.916420772621,
      usdVolume: 154379904.5654336,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '42.6056000000000000',
      high: '42.7176000000000000',
      low: '38.2000000000000000',
      close: '38.6343000000000000',
      volume: '3460575.2420000000000000',
      quoteVolume: 140161330.8816147,
      btcVolume: 4278.077907472615,
      usdVolume: 140161330.8816147,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '38.6596000000000000',
      high: '41.5280000000000000',
      low: '36.8518000000000000',
      close: '40.8936000000000000',
      volume: '3045177.7070000000000000',
      quoteVolume: 120441457.89852692,
      btcVolume: 3837.1422348553915,
      usdVolume: 120441457.89852692,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '40.8938000000000000',
      high: '41.8800000000000000',
      low: '39.4555000000000000',
      close: '40.9109000000000000',
      volume: '2278214.3590000000000000',
      quoteVolume: 92739441.75976337,
      btcVolume: 2865.448972787932,
      usdVolume: 92739441.75976337,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '40.9113000000000000',
      high: '42.2000000000000000',
      low: '40.3144000000000000',
      close: '41.8263000000000000',
      volume: '2340747.6230000000000000',
      quoteVolume: 97017133.59785114,
      btcVolume: 3005.7392112630923,
      usdVolume: 97017133.59785114,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '41.8270000000000000',
      high: '43.4241000000000000',
      low: '40.5364000000000000',
      close: '41.1687000000000000',
      volume: '2518182.3520000000000000',
      quoteVolume: 106660129.78203705,
      btcVolume: 3193.7435879782656,
      usdVolume: 106660129.78203705,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '40.6902000000000000',
      high: '41.9990000000000000',
      low: '40.6495000000000000',
      close: '41.8396000000000000',
      volume: '569370.8780000000000000',
      quoteVolume: 23434513.457918085,
      btcVolume: 727.6668268505232,
      usdVolume: 23434513.457918085,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '41.8397000000000000',
      high: '42.0984000000000000',
      low: '39.6102000000000000',
      close: '40.8826000000000000',
      volume: '2436860.8560000000000000',
      quoteVolume: 99920228.95624547,
      btcVolume: 3215.2310783315247,
      usdVolume: 99920228.95624547,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '40.8957000000000000',
      high: '43.0850000000000000',
      low: '40.3170000000000000',
      close: '42.5254000000000000',
      volume: '2696452.7010000000000000',
      quoteVolume: 113671095.32156354,
      btcVolume: 3581.5448326085157,
      usdVolume: 113671095.32156354,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '42.5277000000000000',
      high: '44.0000000000000000',
      low: '41.8017000000000000',
      close: '42.8618000000000000',
      volume: '3128425.9590000000000000',
      quoteVolume: 134263033.31381023,
      btcVolume: 3835.6490017488704,
      usdVolume: 134263033.31381023,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '42.8653000000000000',
      high: '45.1071000000000000',
      low: '42.5911000000000000',
      close: '44.7849000000000000',
      volume: '2684982.5910000000000000',
      quoteVolume: 118195712.16575812,
      btcVolume: 3475.2585019044272,
      usdVolume: 118195712.16575812,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '44.8022000000000000',
      high: '45.8269000000000000',
      low: '43.3000000000000000',
      close: '44.3292000000000000',
      volume: '2275614.1730000000000000',
      quoteVolume: 101023763.14275552,
      btcVolume: 3017.434459535125,
      usdVolume: 101023763.14275552,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '44.3291000000000000',
      high: '52.3736000000000000',
      low: '43.4331000000000000',
      close: '51.4781000000000000',
      volume: '6050100.3010000000000000',
      quoteVolume: 292440579.7585255,
      btcVolume: 8664.193157549942,
      usdVolume: 292440579.7585255,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '51.4762000000000000',
      high: '53.0000000000000000',
      low: '49.1572000000000000',
      close: '51.0071000000000000',
      volume: '4671921.8650000000000000',
      quoteVolume: 237462302.3806651,
      btcVolume: 6853.326195790986,
      usdVolume: 237462302.3806651,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '51.0290000000000000',
      high: '52.7000000000000000',
      low: '49.7823000000000000',
      close: '52.1200000000000000',
      volume: '2949735.0360000000000000',
      quoteVolume: 150776117.80316824,
      btcVolume: 4132.7211784957935,
      usdVolume: 150776117.80316824,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '52.1200000000000000',
      high: '57.0547000000000000',
      low: '51.2500000000000000',
      close: '55.9599000000000000',
      volume: '4004728.2130000000000000',
      quoteVolume: 215001574.4533509,
      btcVolume: 5736.324770655654,
      usdVolume: 215001574.4533509,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '55.9593000000000000',
      high: '68.7300000000000000',
      low: '55.9202000000000000',
      close: '67.7005000000000000',
      volume: '5033214.7830000000000000',
      quoteVolume: 306083346.7708751,
      btcVolume: 8127.166722306979,
      usdVolume: 306083346.7708751,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '67.6217000000000000',
      high: '75.5000000000000000',
      low: '61.7000000000000000',
      close: '72.8356000000000000',
      volume: '8481923.5090000000000000',
      quoteVolume: 595939416.8906882,
      btcVolume: 15020.336224524533,
      usdVolume: 595939416.8906882,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '72.8693000000000000',
      high: '74.8199000000000000',
      low: '64.1803000000000000',
      close: '68.3542000000000000',
      volume: '5969908.4420000000000000',
      quoteVolume: 413359634.3580266,
      btcVolume: 10704.071358881154,
      usdVolume: 413359634.3580266,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '68.3550000000000000',
      high: '79.8823000000000000',
      low: '67.6152000000000000',
      close: '79.8374000000000000',
      volume: '7849448.2670000000000000',
      quoteVolume: 573011026.0739279,
      btcVolume: 14013.22524313177,
      usdVolume: 573011026.0739279,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '79.8734000000000000',
      high: '116.2400000000000000',
      low: '78.0381000000000000',
      close: '107.3469000000000000',
      volume: '12487305.8680000000000000',
      quoteVolume: 1222317019.460528,
      btcVolume: 26192.431070371436,
      usdVolume: 1222317019.460528,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '107.3496000000000000',
      high: '148.4000000000000000',
      low: '107.2811000000000000',
      close: '128.9711000000000000',
      volume: '16613534.2740000000000000',
      quoteVolume: 2139218096.5095875,
      btcVolume: 46624.18677740852,
      usdVolume: 2138674058.9751093,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '128.9463000000000000',
      high: '132.8000000000000000',
      low: '117.7289000000000000',
      close: '124.0850000000000000',
      volume: '5912684.9520000000000000',
      quoteVolume: 736389166.0586556,
      btcVolume: 15887.557250176676,
      usdVolume: 736389166.0586556,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '124.1129000000000000',
      high: '141.3200000000000000',
      low: '120.8900000000000000',
      close: '136.6139000000000000',
      volume: '5624105.4490000000000000',
      quoteVolume: 739818687.5567763,
      btcVolume: 15573.408733004304,
      usdVolume: 739734387.6189897,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '136.7321000000000000',
      high: '138.5067000000000000',
      low: '125.0000000000000000',
      close: '133.3496000000000000',
      volume: '3785723.4020000000000000',
      quoteVolume: 497454132.3754859,
      btcVolume: 10550.521716952037,
      usdVolume: 497442916.39831746,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '133.3498000000000000',
      high: '139.0000000000000000',
      low: '128.4615000000000000',
      close: '135.8623000000000000',
      volume: '4149634.1350000000000000',
      quoteVolume: 553817439.5471048,
      btcVolume: 11381.352118436944,
      usdVolume: 553817439.5471048,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '135.8623000000000000',
      high: '139.3690000000000000',
      low: '118.0000000000000000',
      close: '130.3030000000000000',
      volume: '4726200.1690000000000000',
      quoteVolume: 610939861.0625807,
      btcVolume: 12798.537422520802,
      usdVolume: 608891488.1794219,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '129.9493000000000000',
      high: '133.4925000000000000',
      low: '126.0000000000000000',
      close: '130.1830000000000000',
      volume: '3253115.9180000000000000',
      quoteVolume: 422971528.8945765,
      btcVolume: 8473.804158435485,
      usdVolume: 413215114.4048722,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '130.1830000000000000',
      high: '175.9997000000000000',
      low: '126.7546000000000000',
      close: '165.3233000000000000',
      volume: '10095433.2290000000000000',
      quoteVolume: 1529265397.2071304,
      btcVolume: 29733.66977060965,
      usdVolume: 1529265397.2071304,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '165.3176000000000000',
      high: '199.8800000000000000',
      low: '162.7969000000000000',
      close: '195.6000000000000000',
      volume: '11226893.3730000000000000',
      quoteVolume: 2093727364.8791077,
      btcVolume: 40384.559838438676,
      usdVolume: 2093727364.8791077,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '195.5549000000000000',
      high: '348.6969000000000000',
      low: '194.9279000000000000',
      close: '333.1110000000000000',
      volume: '20177086.7240000000000000',
      quoteVolume: 5524988273.532031,
      btcVolume: 104550.88500921716,
      usdVolume: 5524988273.532031,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '333.2212000000000000',
      high: '335.0000000000000000',
      low: '223.0000000000000000',
      close: '253.0000000000000000',
      volume: '13493080.4310000000000000',
      quoteVolume: 3802302297.1180034,
      btcVolume: 67696.97645332622,
      usdVolume: 3802255523.1218314,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '253.0100000000000000',
      high: '309.4995000000000000',
      low: '249.7044000000000000',
      close: '295.2597000000000000',
      volume: '10646422.3070000000000000',
      quoteVolume: 3016515097.588496,
      btcVolume: 53004.9096656135,
      usdVolume: 3016515097.588496,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '294.9167000000000000',
      high: '296.0000000000000000',
      low: '220.5000000000000000',
      close: '267.5147000000000000',
      volume: '11090199.9140000000000000',
      quoteVolume: 2901115898.821675,
      btcVolume: 51481.76655921517,
      usdVolume: 2776214823.2922387,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '267.1721000000000000',
      high: '268.8000000000000000',
      low: '188.8195000000000000',
      close: '231.3646000000000000',
      volume: '14894899.8590000000000000',
      quoteVolume: 3323972236.903524,
      btcVolume: 67971.97939281193,
      usdVolume: 3306278943.257356,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '231.3646000000000000',
      high: '281.0000000000000000',
      low: '213.0257000000000000',
      close: '254.5056000000000000',
      volume: '9115851.7520000000000000',
      quoteVolume: 2299513536.8925033,
      btcVolume: 45958.25770110001,
      usdVolume: 2299401919.088233,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '254.3826000000000000',
      high: '265.4014000000000000',
      low: '231.5000000000000000',
      close: '234.9146000000000000',
      volume: '6871992.7940000000000000',
      quoteVolume: 1718504824.021201,
      btcVolume: 34352.22898526557,
      usdVolume: 1718504824.021201,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '234.7605000000000000',
      high: '239.4000000000000000',
      low: '209.1050000000000000',
      close: '221.7170000000000000',
      volume: '7410706.3850000000000000',
      quoteVolume: 1673740236.886314,
      btcVolume: 35915.41505900414,
      usdVolume: 1672629199.376214,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '221.9592000000000000',
      high: '237.7700000000000000',
      low: '219.0110000000000000',
      close: '225.3853000000000000',
      volume: '4192697.1520000000000000',
      quoteVolume: 956073854.7960905,
      btcVolume: 20310.25188922906,
      usdVolume: 956073854.7960905,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '225.4567000000000000',
      high: '229.4000000000000000',
      low: '195.0000000000000000',
      close: '210.2138000000000000',
      volume: '5582387.4930000000000000',
      quoteVolume: 1169488220.755494,
      btcVolume: 26195.665345367866,
      usdVolume: 1169488220.755494,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '210.1177000000000000',
      high: '260.0000000000000000',
      low: '209.1204000000000000',
      close: '254.9637000000000000',
      volume: '7696473.1020000000000000',
      quoteVolume: 1861423426.5445766,
      btcVolume: 38996.01528997843,
      usdVolume: 1861423426.5445766,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '254.9486000000000000',
      high: '264.9000000000000000',
      low: '227.0000000000000000',
      close: '239.6841000000000000',
      volume: '5386013.7940000000000000',
      quoteVolume: 1322863990.4305365,
      btcVolume: 27229.610061705047,
      usdVolume: 1322863990.4305365,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '239.7204000000000000',
      high: '254.8481000000000000',
      low: '236.0020000000000000',
      close: '240.5701000000000000',
      volume: '4213922.8000000000000000',
      quoteVolume: 1038796903.290087,
      btcVolume: 20561.198898104394,
      usdVolume: 1038796903.290087,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '240.4801000000000000',
      high: '249.9885000000000000',
      low: '225.5308000000000000',
      close: '229.6369000000000000',
      volume: '3999983.8360000000000000',
      quoteVolume: 951344257.2707027,
      btcVolume: 19254.739130732472,
      usdVolume: 950963632.5327258,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '229.6369000000000000',
      high: '232.2000000000000000',
      low: '215.6439000000000000',
      close: '225.4714000000000000',
      volume: '3654842.8050000000000000',
      quoteVolume: 816218663.6047677,
      btcVolume: 17130.43983808357,
      usdVolume: 816218663.6047677,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '225.5391000000000000',
      high: '232.4996000000000000',
      low: '219.1514000000000000',
      close: '226.3814000000000000',
      volume: '2540451.2780000000000000',
      quoteVolume: 572783201.0439407,
      btcVolume: 11728.787027795814,
      usdVolume: 566811264.0986007,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '226.3812000000000000',
      high: '243.9500000000000000',
      low: '224.9542000000000000',
      close: '240.1100000000000000',
      volume: '3519962.9290000000000000',
      quoteVolume: 825683976.9668484,
      btcVolume: 16392.968709891094,
      usdVolume: 825683976.9668484,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '240.1039000000000000',
      high: '243.0986000000000000',
      low: '228.7029000000000000',
      close: '240.5509000000000000',
      volume: '2974749.0600000000000000',
      quoteVolume: 700594381.5475962,
      btcVolume: 13790.190438705486,
      usdVolume: 700594381.5475962,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '240.7852000000000000',
      high: '297.2962000000000000',
      low: '235.6001000000000000',
      close: '293.2052000000000000',
      volume: '7349183.8380000000000000',
      quoteVolume: 1967801660.5323133,
      btcVolume: 36388.01138608123,
      usdVolume: 1967801660.5323133,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '293.1305000000000000',
      high: '295.5000000000000000',
      low: '263.8184000000000000',
      close: '278.4499000000000000',
      volume: '5653640.2100000000000000',
      quoteVolume: 1590670836.0511584,
      btcVolume: 28815.964844856844,
      usdVolume: 1590533270.0117154,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '278.3522000000000000',
      high: '315.2855000000000000',
      low: '272.1200000000000000',
      close: '290.0301000000000000',
      volume: '6597122.9060000000000000',
      quoteVolume: 1920751069.112857,
      btcVolume: 34050.75363516682,
      usdVolume: 1920751069.112857,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '289.7860000000000000',
      high: '290.5000000000000000',
      low: '247.5547000000000000',
      close: '264.2515000000000000',
      volume: '5733513.3280000000000000',
      quoteVolume: 1549981975.0642865,
      btcVolume: 18321.480693181016,
      usdVolume: 1039794507.6320997,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '260.1943000000000000',
      high: '284.2857000000000000',
      low: '255.4206000000000000',
      close: '276.2822000000000000',
      volume: '3870240.0390000000000000',
      quoteVolume: 1055956835.0145825,
      btcVolume: 17918.280534933638,
      usdVolume: 1055956835.0145825,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '276.2892000000000000',
      high: '276.8847000000000000',
      low: '263.0003000000000000',
      close: '263.2380000000000000',
      volume: '2426314.6890000000000000',
      quoteVolume: 655148936.6244737,
      btcVolume: 10820.477528826821,
      usdVolume: 654442187.9202651,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '263.3500000000000000',
      high: '267.7453000000000000',
      low: '249.5327000000000000',
      close: '254.5144000000000000',
      volume: '3362729.4940000000000000',
      quoteVolume: 865777537.7956617,
      btcVolume: 15099.63548641119,
      usdVolume: 865777537.7956617,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '254.3894000000000000',
      high: '259.7847000000000000',
      low: '240.1990000000000000',
      close: '258.5352000000000000',
      volume: '2873578.7750000000000000',
      quoteVolume: 724203640.2983155,
      btcVolume: 13111.127043243541,
      usdVolume: 723730256.9268228,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '258.5611000000000000',
      high: '272.1787000000000000',
      low: '248.2001000000000000',
      close: '269.7896000000000000',
      volume: '2840597.4750000000000000',
      quoteVolume: 730345547.8462642,
      btcVolume: 12972.881070043122,
      usdVolume: 730345547.8462642,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '269.8578000000000000',
      high: '277.8000000000000000',
      low: '257.0100000000000000',
      close: '261.4816000000000000',
      volume: '3151815.5090000000000000',
      quoteVolume: 849421790.8761196,
      btcVolume: 14510.926635933718,
      usdVolume: 849416699.5951898,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '261.5157000000000000',
      high: '269.0000000000000000',
      low: '255.0000000000000000',
      close: '262.9350000000000000',
      volume: '2162339.2940000000000000',
      quoteVolume: 571656223.3764387,
      btcVolume: 9795.650695015467,
      usdVolume: 570353586.1848109,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '263.1083000000000000',
      high: '274.6805000000000000',
      low: '261.4000000000000000',
      close: '264.3023000000000000',
      volume: '2167591.3950000000000000',
      quoteVolume: 581172465.4111629,
      btcVolume: 9881.756043643418,
      usdVolume: 581150401.0430868,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '264.2213000000000000',
      high: '268.0305000000000000',
      low: '257.0001000000000000',
      close: '264.6513000000000000',
      volume: '1969870.0700000000000000',
      quoteVolume: 519208676.9906088,
      btcVolume: 9066.830743937264,
      usdVolume: 518831620.6721869,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '264.7152000000000000',
      high: '277.0000000000000000',
      low: '252.9164000000000000',
      close: '254.7976000000000000',
      volume: '3264577.5660000000000000',
      quoteVolume: 867106025.9929574,
      btcVolume: 15341.116908069982,
      usdVolume: 866971561.9590058,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '254.9689000000000000',
      high: '262.9700000000000000',
      low: '251.8380000000000000',
      close: '255.4646000000000000',
      volume: '2572459.8170000000000000',
      quoteVolume: 663206251.7188904,
      btcVolume: 8225.60529805851,
      usdVolume: 447969721.01421326,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '255.5191000000000000',
      high: '268.7062000000000000',
      low: '240.3333000000000000',
      close: '248.5236000000000000',
      volume: '3077671.5470000000000000',
      quoteVolume: 793614390.5674702,
      btcVolume: 14422.352218746142,
      usdVolume: 793614390.5674702,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '248.5345000000000000',
      high: '250.0912000000000000',
      low: '224.9686000000000000',
      close: '233.9466000000000000',
      volume: '3206750.4610000000000000',
      quoteVolume: 761130676.4045202,
      btcVolume: 13047.060000011228,
      usdVolume: 676406281.7791395,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '234.0024000000000000',
      high: '255.2113000000000000',
      low: '233.8114000000000000',
      close: '255.2105000000000000',
      volume: '2245802.5640000000000000',
      quoteVolume: 556008625.4021944,
      btcVolume: 10101.506782457785,
      usdVolume: 538894875.840913,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '255.1201000000000000',
      high: '276.0000000000000000',
      low: '249.5001000000000000',
      close: '269.2234000000000000',
      volume: '3089181.8480000000000000',
      quoteVolume: 804732179.3560896,
      btcVolume: 14561.461272689408,
      usdVolume: 804579787.2442447,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '269.2864000000000000',
      high: '281.3939000000000000',
      low: '261.0000000000000000',
      close: '268.4263000000000000',
      volume: '3272613.7550000000000000',
      quoteVolume: 888837689.7422438,
      btcVolume: 15905.983283637139,
      usdVolume: 888769684.6338859,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '268.6234000000000000',
      high: '277.9813000000000000',
      low: '264.4585000000000000',
      close: '275.2991000000000000',
      volume: '2718538.8020000000000000',
      quoteVolume: 741093981.6902637,
      btcVolume: 13018.42468453984,
      usdVolume: 741031453.2366232,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '275.2986000000000000',
      high: '311.5325000000000000',
      low: '271.2857000000000000',
      close: '311.2962000000000000',
      volume: '5532768.0410000000000000',
      quoteVolume: 1633393475.0102596,
      btcVolume: 27920.656657466523,
      usdVolume: 1633357160.421257,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '311.2962000000000000',
      high: '316.6997000000000000',
      low: '284.0000000000000000',
      close: '302.3724000000000000',
      volume: '4719335.5060000000000000',
      quoteVolume: 1432519744.2660277,
      btcVolume: 24381.244738004352,
      usdVolume: 1428700884.4968865,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '302.4591000000000000',
      high: '339.0000000000000000',
      low: '300.7000000000000000',
      close: '334.7991000000000000',
      volume: '4332111.9370000000000000',
      quoteVolume: 1384341137.684506,
      btcVolume: 23482.89919447801,
      usdVolume: 1382271214.156847,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '334.6706000000000000',
      high: '356.9882000000000000',
      low: '324.6162000000000000',
      close: '338.2932000000000000',
      volume: '4071170.6560000000000000',
      quoteVolume: 1403027066.2804952,
      btcVolume: 23692.93398872393,
      usdVolume: 1402782243.379352,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '338.2932000000000000',
      high: '349.1347000000000000',
      low: '315.9100000000000000',
      close: '321.9497000000000000',
      volume: '3098374.7850000000000000',
      quoteVolume: 1044133678.7217119,
      btcVolume: 10038.83808275111,
      usdVolume: 591404806.9357758,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '321.9597000000000000',
      high: '352.3275000000000000',
      low: '316.0000000000000000',
      close: '348.8617000000000000',
      volume: '2648776.3950000000000000',
      quoteVolume: 902098257.0088232,
      btcVolume: 13702.133872871518,
      usdVolume: 791916397.8945813,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '348.8705000000000000',
      high: '380.0000000000000000',
      low: '335.8442000000000000',
      close: '368.0263000000000000',
      volume: '3431868.4070000000000000',
      quoteVolume: 1236127810.4175525,
      btcVolume: 17901.65290795174,
      usdVolume: 1037725357.78694,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '368.1506000000000000',
      high: '411.7550000000000000',
      low: '363.8333000000000000',
      close: '403.3648000000000000',
      volume: '4239882.7000000000000000',
      quoteVolume: 1648279064.5724006,
      btcVolume: 26531.19803700832,
      usdVolume: 1548692540.941828,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '403.2607000000000000',
      high: '414.3803000000000000',
      low: '356.0000000000000000',
      close: '375.5538000000000000',
      volume: '6153246.9340000000000000',
      quoteVolume: 2372758767.1935678,
      btcVolume: 40716.89375916033,
      usdVolume: 2320206435.050474,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '375.5538000000000000',
      high: '422.6323000000000000',
      low: '371.7800000000000000',
      close: '418.7048000000000000',
      volume: '4010180.6390000000000000',
      quoteVolume: 1625423876.1601589,
      btcVolume: 18000.290159044805,
      usdVolume: 1028345774.5132892,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '418.7047000000000000',
      high: '453.9937000000000000',
      low: '403.1900000000000000',
      close: '453.9937000000000000',
      volume: '3392014.2090000000000000',
      quoteVolume: 1464479256.1346753,
      btcVolume: 22466.754948146387,
      usdVolume: 1309044489.8936975,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '453.9898000000000000',
      high: '487.4062000000000000',
      low: '450.0000000000000000',
      close: '471.2975000000000000',
      volume: '4659107.2120000000000000',
      quoteVolume: 2200226600.979961,
      btcVolume: 36213.41070222386,
      usdVolume: 2165238340.1851015,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '471.2974000000000000',
      high: '529.2110000000000000',
      low: '458.0165000000000000',
      close: '525.9275000000000000',
      volume: '3644396.1100000000000000',
      quoteVolume: 1776698304.8045623,
      btcVolume: 29506.82991366972,
      usdVolume: 1764632035.1456206,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '525.9275000000000000',
      high: '638.5673000000000000',
      low: '515.1000000000000000',
      close: '596.7104000000000000',
      volume: '9111902.9720000000000000',
      quoteVolume: 5245816431.400061,
      btcVolume: 63257.127669028734,
      usdVolume: 3806149366.686823,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '596.7878000000000000',
      high: '602.7871000000000000',
      low: '530.2288000000000000',
      close: '551.6442000000000000',
      volume: '5211856.8490000000000000',
      quoteVolume: 2928039699.6231527,
      btcVolume: 46855.84817376075,
      usdVolume: 2904056644.2781954,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '551.3956000000000000',
      high: '590.5369000000000000',
      low: '521.1313000000000000',
      close: '550.0182000000000000',
      volume: '4141516.3380000000000000',
      quoteVolume: 2316332649.32106,
      btcVolume: 32494.32817460017,
      usdVolume: 2063377293.09785,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '550.0182000000000000',
      high: '556.9000000000000000',
      low: '533.7166000000000000',
      close: '541.8824000000000000',
      volume: '2306847.3860000000000000',
      quoteVolume: 1261788213.6561053,
      btcVolume: 18450.056652820283,
      usdVolume: 1159651151.0654097,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '541.8351000000000000',
      high: '547.6074000000000000',
      low: '489.7356000000000000',
      close: '508.2743000000000000',
      volume: '4192215.1750000000000000',
      quoteVolume: 2179385039.089873,
      btcVolume: 33322.010808070954,
      usdVolume: 2049560218.6198566,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '508.2614000000000000',
      high: '547.9500000000000000',
      low: '506.0000000000000000',
      close: '514.6861000000000000',
      volume: '2949039.8180000000000000',
      quoteVolume: 1548814241.88043,
      btcVolume: 16718.141931258793,
      usdVolume: 1024252858.6623896,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '514.8178000000000000',
      high: '520.4237000000000000',
      low: '428.0000000000000000',
      close: '481.4367000000000000',
      volume: '4049186.4060000000000000',
      quoteVolume: 1904645753.8034728,
      btcVolume: 33628.949170106855,
      usdVolume: 1876226556.6125178,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '481.5112000000000000',
      high: '540.0000000000000000',
      low: '467.8846000000000000',
      close: '504.0322000000000000',
      volume: '5031304.0290000000000000',
      quoteVolume: 2572847032.948682,
      btcVolume: 37508.47886493883,
      usdVolume: 2114776432.2070198,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '504.0322000000000000',
      high: '589.5000000000000000',
      low: '470.2070000000000000',
      close: '586.3635000000000000',
      volume: '5715927.6350000000000000',
      quoteVolume: 3058354760.9721446,
      btcVolume: 53121.18738860772,
      usdVolume: 2963040031.251067,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '586.5201000000000000',
      high: '613.8975000000000000',
      low: '535.9186000000000000',
      close: '543.6327000000000000',
      volume: '5415834.4300000000000000',
      quoteVolume: 3148647997.53806,
      btcVolume: 49070.86389573589,
      usdVolume: 2723704942.1352196,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '543.6827000000000000',
      high: '582.7051000000000000',
      low: '480.0000000000000000',
      close: '505.2411000000000000',
      volume: '5711241.6150000000000000',
      quoteVolume: 3087134504.9510384,
      btcVolume: 16336.707809710622,
      usdVolume: 865615640.8242787,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '505.2282000000000000',
      high: '530.0000000000000000',
      low: '453.1000000000000000',
      close: '526.5357000000000000',
      volume: '6415439.4970000000000000',
      quoteVolume: 3176588289.098144,
      btcVolume: 33856.082810714746,
      usdVolume: 1686441255.1318297,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '526.4319000000000000',
      high: '528.8088000000000000',
      low: '482.3052000000000000',
      close: '496.9920000000000000',
      volume: '2969978.4340000000000000',
      quoteVolume: 1502798926.917138,
      btcVolume: 28745.678183221087,
      usdVolume: 1432476977.467932,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '496.9920000000000000',
      high: '517.7400000000000000',
      low: '478.0100000000000000',
      close: '505.0000000000000000',
      volume: '2352878.9486000000000000',
      quoteVolume: 1177965823.3087528,
      btcVolume: 12577.589728470612,
      usdVolume: 623023504.0575972,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '505.0000000000000000',
      high: '550.0000000000000000',
      low: '501.5000000000000000',
      close: '535.3300000000000000',
      volume: '2655262.1521000000000000',
      quoteVolume: 1411293600.6102023,
      btcVolume: 15480.36818125127,
      usdVolume: 815757282.7136099,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '535.3300000000000000',
      high: '579.5000000000000000',
      low: '525.0000000000000000',
      close: '568.7800000000000000',
      volume: '2807308.6198000000000000',
      quoteVolume: 1565140057.7051094,
      btcVolume: 28300.124067514644,
      usdVolume: 1546267212.3260415,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '568.7800000000000000',
      high: '578.8800000000000000',
      low: '538.0000000000000000',
      close: '562.7800000000000000',
      volume: '2384511.0120000000000000',
      quoteVolume: 1333085756.448968,
      btcVolume: 24357.758243790056,
      usdVolume: 1333085756.448968,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '562.7800000000000000',
      high: '615.2900000000000000',
      low: '554.0000000000000000',
      close: '599.7000000000000000',
      volume: '4242963.4049000000000000',
      quoteVolume: 2481862298.554591,
      btcVolume: 46006.51699559538,
      usdVolume: 2481862298.554591,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '599.6600000000000000',
      high: '628.0000000000000000',
      low: '585.7200000000000000',
      close: '622.6500000000000000',
      volume: '3379163.2196000000000000',
      quoteVolume: 2063664584.472258,
      btcVolume: 25172.717745086713,
      usdVolume: 1386220097.2369685,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '622.6400000000000000',
      high: '645.5700000000000000',
      low: '609.2100000000000000',
      close: '620.0000000000000000',
      volume: '2427568.3909000000000000',
      quoteVolume: 1516905856.244385,
      btcVolume: 12446.095949705987,
      usdVolume: 719848206.3838052,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '620.0000000000000000',
      high: '629.0000000000000000',
      low: '595.3000000000000000',
      close: '621.7800000000000000',
      volume: '2066188.5104000000000000',
      quoteVolume: 1273393608.5696151,
      btcVolume: 14374.383604305296,
      usdVolume: 815986629.5360534,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '621.7700000000000000',
      high: '680.0000000000000000',
      low: '621.2200000000000000',
      close: '676.1500000000000000',
      volume: '2873379.6170000000000000',
      quoteVolume: 1872406454.7114303,
      btcVolume: 31523.29798707986,
      usdVolume: 1827102859.2419474,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '676.1500000000000000',
      high: '677.7100000000000000',
      low: '604.1400000000000000',
      close: '609.3000000000000000',
      volume: '3760446.5392000000000000',
      quoteVolume: 2397311746.8970165,
      btcVolume: 34681.93696052413,
      usdVolume: 1922027889.6555834,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '609.4100000000000000',
      high: '656.6700000000000000',
      low: '605.0000000000000000',
      close: '651.1000000000000000',
      volume: '2108343.7681000000000000',
      quoteVolume: 1350489029.914887,
      btcVolume: 22376.029864647935,
      usdVolume: 1245585308.8986058,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '651.1000000000000000',
      high: '653.7700000000000000',
      low: '620.0000000000000000',
      close: '633.9900000000000000',
      volume: '2027391.4345000000000000',
      quoteVolume: 1294526643.8860967,
      btcVolume: 22104.33571757016,
      usdVolume: 1257147022.1981807,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '634.0300000000000000',
      high: '644.0200000000000000',
      low: '611.4300000000000000',
      close: '624.3000000000000000',
      volume: '1979433.7807000000000000',
      quoteVolume: 1238888054.2225192,
      btcVolume: 21423.691802310528,
      usdVolume: 1214310669.4553754,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '624.3000000000000000',
      high: '657.9100000000000000',
      low: '618.3600000000000000',
      close: '646.1000000000000000',
      volume: '2185770.9717000000000000',
      quoteVolume: 1395759313.3025906,
      btcVolume: 23506.98439331287,
      usdVolume: 1375162536.6888602,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '646.0700000000000000',
      high: '679.0000000000000000',
      low: '630.5400000000000000',
      close: '661.7400000000000000',
      volume: '2343400.2966000000000000',
      quoteVolume: 1543793211.4605246,
      btcVolume: 25883.63462490665,
      usdVolume: 1497580524.9378126,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '661.7400000000000000',
      high: '691.7700000000000000',
      low: '588.0000000000000000',
      close: '631.1000000000000000',
      volume: '3000221.4265000000000000',
      quoteVolume: 1974492506.488098,
      btcVolume: 23776.338284261044,
      usdVolume: 1357118070.6740103,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '631.1600000000000000',
      high: '673.0000000000000000',
      low: '621.1100000000000000',
      close: '671.9100000000000000',
      volume: '2559194.2717000000000000',
      quoteVolume: 1669252689.2629402,
      btcVolume: 23284.48219498061,
      usdVolume: 1299085092.4906397,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '671.9000000000000000',
      high: '684.9100000000000000',
      low: '581.4000000000000000',
      close: '590.0000000000000000',
      volume: '2807361.4119000000000000',
      quoteVolume: 1818252882.3973007,
      btcVolume: 32752.382692644092,
      usdVolume: 1796910641.5199373,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '594.2000000000000000',
      high: '633.5000000000000000',
      low: '526.6000000000000000',
      close: '571.1000000000000000',
      volume: '5067197.8665000000000000',
      quoteVolume: 2965262611.7093215,
      btcVolume: 57817.7131586063,
      usdVolume: 2860884730.229648,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '571.1000000000000000',
      high: '615.0000000000000000',
      low: '566.0000000000000000',
      close: '602.6100000000000000',
      volume: '2185834.5291000000000000',
      quoteVolume: 1300235538.911767,
      btcVolume: 25346.497714855974,
      usdVolume: 1270717338.95908,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '602.6100000000000000',
      high: '611.3100000000000000',
      low: '560.0000000000000000',
      close: '563.4100000000000000',
      volume: '2259793.5825000000000000',
      quoteVolume: 1322248759.3872828,
      btcVolume: 26733.85650391243,
      usdVolume: 1302373374.1391342,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '563.4200000000000000',
      high: '606.7800000000000000',
      low: '534.8800000000000000',
      close: '564.3200000000000000',
      volume: '2935802.4611000000000000',
      quoteVolume: 1676575346.1177924,
      btcVolume: 34768.195828266646,
      usdVolume: 1641174937.546295,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '564.6600000000000000',
      high: '564.6600000000000000',
      low: '483.0000000000000000',
      close: '512.0700000000000000',
      volume: '4002372.8778000000000000',
      quoteVolume: 2073223636.3413508,
      btcVolume: 45783.614024130424,
      usdVolume: 2011961037.140912,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '512.0600000000000000',
      high: '534.0000000000000000',
      low: '490.0000000000000000',
      close: '507.8800000000000000',
      volume: '2720458.6730000000000000',
      quoteVolume: 1404074857.8504837,
      btcVolume: 31414.796893148603,
      usdVolume: 1382388016.6856706,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '507.9000000000000000',
      high: '516.5000000000000000',
      low: '280.0000000000000000',
      close: '334.8000000000000000',
      volume: '8239380.7045000000000000',
      quoteVolume: 3344112007.75396,
      btcVolume: 72337.91025415438,
      usdVolume: 2827850240.61419,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '334.8000000000000000',
      high: '423.8300000000000000',
      low: '284.2700000000000000',
      close: '391.1700000000000000',
      volume: '8115885.0027000000000000',
      quoteVolume: 2937254802.220806,
      btcVolume: 60130.470531550855,
      usdVolume: 2366728450.1871305,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '391.3700000000000000',
      high: '415.7100000000000000',
      low: '274.0000000000000000',
      close: '326.0100000000000000',
      volume: '7320106.3957000000000000',
      quoteVolume: 2518202198.2935557,
      btcVolume: 49722.66139396216,
      usdVolume: 1903529428.7297347,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '326.2300000000000000',
      high: '335.8200000000000000',
      low: '284.5500000000000000',
      close: '299.7600000000000000',
      volume: '5296110.3904000000000000',
      quoteVolume: 1644509563.62935,
      btcVolume: 28543.884802395307,
      usdVolume: 1058397270.464872,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '299.7500000000000000',
      high: '313.4500000000000000',
      low: '211.7000000000000000',
      close: '260.7200000000000000',
      volume: '8801148.1832000000000000',
      quoteVolume: 2229226334.873108,
      btcVolume: 63281.297938914686,
      usdVolume: 2178774483.491753,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '260.7200000000000000',
      high: '350.0000000000000000',
      low: '257.4000000000000000',
      close: '345.6400000000000000',
      volume: '7781959.4786000000000000',
      quoteVolume: 2368109550.148683,
      btcVolume: 62642.28704122964,
      usdVolume: 2327105512.6887536,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '345.6400000000000000',
      high: '378.1200000000000000',
      low: '306.6100000000000000',
      close: '343.1800000000000000',
      volume: '7638986.0132000000000000',
      quoteVolume: 2568468246.8690605,
      btcVolume: 66152.31977044823,
      usdVolume: 2514295964.407211,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '343.1800000000000000',
      high: '388.2800000000000000',
      low: '336.2100000000000000',
      close: '379.1600000000000000',
      volume: '5258751.3486000000000000',
      quoteVolume: 1933572793.550551,
      btcVolume: 48379.41805278539,
      usdVolume: 1900748427.6240995,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '379.1600000000000000',
      high: '387.5600000000000000',
      low: '340.0000000000000000',
      close: '369.6700000000000000',
      volume: '4275801.5014000000000000',
      quoteVolume: 1564221587.8316987,
      btcVolume: 40027.51492212167,
      usdVolume: 1551370014.1647067,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '369.8300000000000000',
      high: '373.6800000000000000',
      low: '312.3000000000000000',
      close: '328.8200000000000000',
      volume: '5253443.3619000000000000',
      quoteVolume: 1767981944.1573837,
      btcVolume: 47664.24020697601,
      usdVolume: 1739093359.1059759,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '328.8600000000000000',
      high: '347.0000000000000000',
      low: '295.2200000000000000',
      close: '305.0400000000000000',
      volume: '3763011.1068000000000000',
      quoteVolume: 1207079531.3800404,
      btcVolume: 33934.25604475658,
      usdVolume: 1191835011.423594,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '305.1800000000000000',
      high: '338.3200000000000000',
      low: '291.0600000000000000',
      close: '325.5700000000000000',
      volume: '3737180.6229000000000000',
      quoteVolume: 1203296649.3056147,
      btcVolume: 33941.50714115873,
      usdVolume: 1201181411.5601633,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '325.5500000000000000',
      high: '356.0000000000000000',
      low: '307.5500000000000000',
      close: '353.3300000000000000',
      volume: '4100337.3521000000000000',
      quoteVolume: 1373992157.7018433,
      btcVolume: 37865.87542025713,
      usdVolume: 1369885540.0723953,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '353.3700000000000000',
      high: '364.4000000000000000',
      low: '339.4800000000000000',
      close: '362.3900000000000000',
      volume: '3509428.1657000000000000',
      quoteVolume: 1234613133.2643874,
      btcVolume: 33299.87059281471,
      usdVolume: 1220996431.8403883,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '362.3600000000000000',
      high: '419.4300000000000000',
      low: '350.7100000000000000',
      close: '401.5400000000000000',
      volume: '4195383.3566000000000000',
      quoteVolume: 1645840910.622663,
      btcVolume: 43851.300647727745,
      usdVolume: 1640011801.5316956,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '401.5200000000000000',
      high: '433.0000000000000000',
      low: '391.3200000000000000',
      close: '428.4800000000000000',
      volume: '4180143.8076000000000000',
      quoteVolume: 1737768750.2461805,
      btcVolume: 45007.68133463864,
      usdVolume: 1735956965.104566,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '428.4600000000000000',
      high: '429.4400000000000000',
      low: '365.0000000000000000',
      close: '390.7000000000000000',
      volume: '4787078.4675000000000000',
      quoteVolume: 1860279166.5224113,
      btcVolume: 49180.94965001132,
      usdVolume: 1819826565.68764,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '390.6800000000000000',
      high: '426.0000000000000000',
      low: '373.8000000000000000',
      close: '390.0800000000000000',
      volume: '3977579.3470000000000000',
      quoteVolume: 1595813798.7458856,
      btcVolume: 43299.21514760122,
      usdVolume: 1582242242.5882614,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '390.0500000000000000',
      high: '400.2900000000000000',
      low: '381.5000000000000000',
      close: '393.7300000000000000',
      volume: '1861227.0254000000000000',
      quoteVolume: 728760657.4104598,
      btcVolume: 20250.859347582682,
      usdVolume: 727603105.8069081,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '393.8200000000000000',
      high: '407.4200000000000000',
      low: '353.5300000000000000',
      close: '357.9600000000000000',
      volume: '3028938.8445000000000000',
      quoteVolume: 1170669919.3293319,
      btcVolume: 32773.58817400823,
      usdVolume: 1165121515.317816,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '357.9400000000000000',
      high: '366.3300000000000000',
      low: '319.1800000000000000',
      close: '352.5000000000000000',
      volume: '4986849.8512000000000000',
      quoteVolume: 1719924713.8838046,
      btcVolume: 52178.07647016997,
      usdVolume: 1704623920.306418,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '352.5000000000000000',
      high: '378.7700000000000000',
      low: '333.5600000000000000',
      close: '375.5400000000000000',
      volume: '3494285.6690000000000000',
      quoteVolume: 1249840132.937433,
      btcVolume: 35442.0885217172,
      usdVolume: 1236317469.7043624,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '375.5600000000000000',
      high: '377.5500000000000000',
      low: '344.1300000000000000',
      close: '351.9400000000000000',
      volume: '2416033.9503000000000000',
      quoteVolume: 872167769.3608466,
      btcVolume: 23457.678201352148,
      usdVolume: 868637983.1314929,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '351.9600000000000000',
      high: '366.2800000000000000',
      low: '340.8400000000000000',
      close: '345.7500000000000000',
      volume: '2215352.7230000000000000',
      quoteVolume: 785929588.8141638,
      btcVolume: 21185.154784415125,
      usdVolume: 783490988.6749337,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '345.7600000000000000',
      high: '348.8800000000000000',
      low: '325.3000000000000000',
      close: '343.7000000000000000',
      volume: '2074837.0012000000000000',
      quoteVolume: 699907058.519631,
      btcVolume: 19499.705610749803,
      usdVolume: 696409381.2621572,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '343.6800000000000000',
      high: '369.0000000000000000',
      low: '330.4800000000000000',
      close: '365.8000000000000000',
      volume: '1958071.3656000000000000',
      quoteVolume: 679668875.2465632,
      btcVolume: 18401.26447166104,
      usdVolume: 677962892.4327875,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '365.8100000000000000',
      high: '377.3300000000000000',
      low: '355.0700000000000000',
      close: '370.1900000000000000',
      volume: '1978613.2384000000000000',
      quoteVolume: 723637001.3751506,
      btcVolume: 18165.93816349188,
      usdVolume: 723148705.02761,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '370.2000000000000000',
      high: '379.5800000000000000',
      low: '363.2300000000000000',
      close: '366.4800000000000000',
      volume: '1545190.4369000000000000',
      quoteVolume: 573156968.6381595,
      btcVolume: 14239.62588138577,
      usdVolume: 572741192.8239167,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '366.4800000000000000',
      high: '366.7700000000000000',
      low: '343.3500000000000000',
      close: '347.2200000000000000',
      volume: '1531522.2062000000000000',
      quoteVolume: 543418325.492728,
      btcVolume: 13807.544901929507,
      usdVolume: 542608162.7021075,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '347.2200000000000000',
      high: '362.4700000000000000',
      low: '345.8500000000000000',
      close: '352.8500000000000000',
      volume: '1239146.5273000000000000',
      quoteVolume: 439111935.5714126,
      btcVolume: 11360.551963526297,
      usdVolume: 438379161.15634346,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '352.8400000000000000',
      high: '357.1200000000000000',
      low: '327.2200000000000000',
      close: '337.1600000000000000',
      volume: '1583897.2791000000000000',
      quoteVolume: 543765122.8516467,
      btcVolume: 14644.68316373736,
      usdVolume: 540156402.3887985,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '337.2100000000000000',
      high: '345.0000000000000000',
      low: '331.1100000000000000',
      close: '334.0100000000000000',
      volume: '1066914.4291000000000000',
      quoteVolume: 361009064.1546483,
      btcVolume: 10093.106647631466,
      usdVolume: 360603082.2604604,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '334.0300000000000000',
      high: '340.5700000000000000',
      low: '310.1300000000000000',
      close: '339.0800000000000000',
      volume: '1618135.6528000000000000',
      quoteVolume: 529042411.3983932,
      btcVolume: 15094.299251402948,
      usdVolume: 523909240.19277626,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '339.0900000000000000',
      high: '342.0000000000000000',
      low: '268.5700000000000000',
      close: '270.7600000000000000',
      volume: '3728933.7663000000000000',
      quoteVolume: 1133986634.6228907,
      btcVolume: 34042.98997440145,
      usdVolume: 1114042737.0850716,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '270.7600000000000000',
      high: '291.5000000000000000',
      low: '225.4200000000000000',
      close: '262.1000000000000000',
      volume: '5569480.0596000000000000',
      quoteVolume: 1444878723.2121854,
      btcVolume: 45224.72868315482,
      usdVolume: 1421659323.222384,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '262.1000000000000000',
      high: '309.7500000000000000',
      low: '251.4100000000000000',
      close: '296.2500000000000000',
      volume: '3317195.3211000000000000',
      quoteVolume: 955844232.5110707,
      btcVolume: 28063.34606238212,
      usdVolume: 947394250.7374245,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '296.1700000000000000',
      high: '316.4900000000000000',
      low: '281.5000000000000000',
      close: '308.6200000000000000',
      volume: '2610131.7883000000000000',
      quoteVolume: 786154895.0311371,
      btcVolume: 23317.078611131026,
      usdVolume: 783721733.0198007,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '308.6000000000000000',
      high: '314.8400000000000000',
      low: '275.2400000000000000',
      close: '280.8100000000000000',
      volume: '2711888.8709000000000000',
      quoteVolume: 791189610.0092999,
      btcVolume: 23725.1496532656,
      usdVolume: 789609971.7930796,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '280.8900000000000000',
      high: '290.3300000000000000',
      low: '264.2600000000000000',
      close: '279.6500000000000000',
      volume: '2255714.8070000000000000',
      quoteVolume: 620289844.1352688,
      btcVolume: 19714.79679267372,
      usdVolume: 617533521.9969708,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '279.5800000000000000',
      high: '291.7000000000000000',
      low: '269.7200000000000000',
      close: '290.8700000000000000',
      volume: '1539903.0268000000000000',
      quoteVolume: 431044973.3830572,
      btcVolume: 12975.955639558339,
      usdVolume: 430025822.8561487,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '290.8900000000000000',
      high: '299.9600000000000000',
      low: '283.5500000000000000',
      close: '290.0000000000000000',
      volume: '1791427.4545000000000000',
      quoteVolume: 521315922.3130429,
      btcVolume: 15087.577540242723,
      usdVolume: 520602774.96711284,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '290.0000000000000000',
      high: '315.6000000000000000',
      low: '289.1900000000000000',
      close: '300.7900000000000000',
      volume: '1996075.2527000000000000',
      quoteVolume: 606800912.6351702,
      btcVolume: 17004.53387746386,
      usdVolume: 606106183.2717582,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '300.7900000000000000',
      high: '304.8800000000000000',
      low: '281.5300000000000000',
      close: '303.7100000000000000',
      volume: '2003771.1501000000000000',
      quoteVolume: 589286208.902001,
      btcVolume: 16881.53168393942,
      usdVolume: 588691995.1413999,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '303.7500000000000000',
      high: '304.0000000000000000',
      low: '281.0000000000000000',
      close: '287.4300000000000000',
      volume: '1388151.0265000000000000',
      quoteVolume: 401830865.47626346,
      btcVolume: 11897.911415033517,
      usdVolume: 400902690.8213621,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '287.4900000000000000',
      high: '290.7400000000000000',
      low: '276.4000000000000000',
      close: '287.1700000000000000',
      volume: '999531.0988000000000000',
      quoteVolume: 281885530.70657486,
      btcVolume: 8474.226394092162,
      usdVolume: 281776937.5769988,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '287.1700000000000000',
      high: '303.2100000000000000',
      low: '282.7700000000000000',
      close: '298.1300000000000000',
      volume: '970447.5647000000000000',
      quoteVolume: 285959914.3739259,
      btcVolume: 8295.090216560875,
      usdVolume: 285468108.13785046,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '298.1800000000000000',
      high: '314.9100000000000000',
      low: '292.5800000000000000',
      close: '307.6200000000000000',
      volume: '1289644.5294000000000000',
      quoteVolume: 395101122.4499773,
      btcVolume: 11166.440864142483,
      usdVolume: 394799633.44753224,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '307.6300000000000000',
      high: '307.6800000000000000',
      low: '291.0000000000000000',
      close: '302.0200000000000000',
      volume: '1401707.3090000000000000',
      quoteVolume: 420794880.4929045,
      btcVolume: 12250.65881746282,
      usdVolume: 417383535.8960341,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '302.0400000000000000',
      high: '322.5000000000000000',
      low: '301.8000000000000000',
      close: '319.9200000000000000',
      volume: '2017579.2475000000000000',
      quoteVolume: 634877944.4661232,
      btcVolume: 18213.7054914752,
      usdVolume: 624628084.0134494,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '319.9100000000000000',
      high: '339.2000000000000000',
      low: '318.2300000000000000',
      close: '327.5100000000000000',
      volume: '1752283.5071000000000000',
      quoteVolume: 581264198.4175483,
      btcVolume: 16826.903381226817,
      usdVolume: 581264198.4175483,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '327.5100000000000000',
      high: '328.2100000000000000',
      low: '304.4000000000000000',
      close: '310.1400000000000000',
      volume: '1644090.8501000000000000',
      quoteVolume: 519961296.56923467,
      btcVolume: 15753.624652227563,
      usdVolume: 518366397.8303491,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '310.0800000000000000',
      high: '319.6900000000000000',
      low: '301.4300000000000000',
      close: '317.0900000000000000',
      volume: '1106411.7860000000000000',
      quoteVolume: 343735624.2635021,
      btcVolume: 10375.187952189024,
      usdVolume: 343590533.1811929,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '317.0900000000000000',
      high: '321.4400000000000000',
      low: '310.5700000000000000',
      close: '316.1200000000000000',
      volume: '818970.9225000000000000',
      quoteVolume: 258075549.81921732,
      btcVolume: 7649.980650066824,
      usdVolume: 258069158.45030236,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '316.1200000000000000',
      high: '326.8200000000000000',
      low: '312.2100000000000000',
      close: '322.1600000000000000',
      volume: '798248.5445000000000000',
      quoteVolume: 255757579.33083242,
      btcVolume: 7559.742869184711,
      usdVolume: 255696265.09578243,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '322.1700000000000000',
      high: '334.3700000000000000',
      low: '308.5700000000000000',
      close: '316.1200000000000000',
      volume: '1322836.6575000000000000',
      quoteVolume: 426623454.93747866,
      btcVolume: 12659.101822822975,
      usdVolume: 426389699.78516084,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '316.1100000000000000',
      high: '318.3300000000000000',
      low: '305.2000000000000000',
      close: '308.9400000000000000',
      volume: '920029.5386000000000000',
      quoteVolume: 286332861.64762217,
      btcVolume: 8716.39219531748,
      usdVolume: 286196536.8978347,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '308.9800000000000000',
      high: '313.4600000000000000',
      low: '290.2400000000000000',
      close: '309.4000000000000000',
      volume: '1429632.5382000000000000',
      quoteVolume: 432703278.02084684,
      btcVolume: 13340.15495367681,
      usdVolume: 432565896.3701419,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '309.3300000000000000',
      high: '324.3300000000000000',
      low: '301.3200000000000000',
      close: '316.1100000000000000',
      volume: '2182717.6094000000000000',
      quoteVolume: 682797057.890845,
      btcVolume: 21238.91718069471,
      usdVolume: 681918652.5679137,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '316.1200000000000000',
      high: '323.5000000000000000',
      low: '301.5000000000000000',
      close: '302.8800000000000000',
      volume: '1404493.8001000000000000',
      quoteVolume: 436177727.3631599,
      btcVolume: 13765.70767375639,
      usdVolume: 436177363.4888899,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '302.9400000000000000',
      high: '306.7100000000000000',
      low: '295.0000000000000000',
      close: '300.5200000000000000',
      volume: '1000972.6192000000000000',
      quoteVolume: 300875643.0396566,
      btcVolume: 9534.88714728326,
      usdVolume: 300540577.2338914,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '300.5600000000000000',
      high: '309.1800000000000000',
      low: '298.7000000000000000',
      close: '302.1000000000000000',
      volume: '815497.3527000000000000',
      quoteVolume: 248310442.2807995,
      btcVolume: 7822.5474578018375,
      usdVolume: 248294306.8784705,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '302.0400000000000000',
      high: '303.9400000000000000',
      low: '278.8500000000000000',
      close: '280.5400000000000000',
      volume: '1318041.7074000000000000',
      quoteVolume: 380236625.4640259,
      btcVolume: 12254.211166206616,
      usdVolume: 380166078.83319795,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '280.5400000000000000',
      high: '283.6000000000000000',
      low: '254.5200000000000000',
      close: '264.4400000000000000',
      volume: '1796247.8290000000000000',
      quoteVolume: 477237090.34517443,
      btcVolume: 15976.537565143271,
      usdVolume: 477091911.90441567,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '264.4300000000000000',
      high: '298.9200000000000000',
      low: '261.0200000000000000',
      close: '293.2600000000000000',
      volume: '1740736.0226000000000000',
      quoteVolume: 493675666.91880316,
      btcVolume: 15781.106267115589,
      usdVolume: 493348159.1449845,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '293.2700000000000000',
      high: '298.4400000000000000',
      low: '287.3000000000000000',
      close: '293.3600000000000000',
      volume: '1173507.9432000000000000',
      quoteVolume: 344239131.7439699,
      btcVolume: 10689.223886290574,
      usdVolume: 343305158.22704864,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '293.3900000000000000',
      high: '301.5000000000000000',
      low: '281.0100000000000000',
      close: '285.8000000000000000',
      volume: '1349255.6384000000000000',
      quoteVolume: 393613769.09714,
      btcVolume: 12142.021329993779,
      usdVolume: 393613769.09714,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
  amp: {
    id: 'amp',
    name: 'AMP',
    symbol: 'AMP',
    image: 'assets/images/coin-logo/AMP.png',
    activity24h: 9974589345,
    availableAmount: 508.232323,
    usdxConversion: .7,
    allTimeValue: 943812390,
    allTimeAsset: 3421,
    candleData: [
      {
      open: '204.4500000000000000',
      high: '205.9400000000000000',
      low: '203.6200000000000000',
      close: '204.9900000000000000',
      volume: '44394.8335700000000000',
      quoteVolume: 9084565.9157548,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-28T00:00:00.000Z'
      },
      {
      open: '204.9900000000000000',
      high: '205.4900000000000000',
      low: '195.2800000000000000',
      close: '196.9600000000000000',
      volume: '118281.8227200000000000',
      quoteVolume: 23671711.3937594,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-29T00:00:00.000Z'
      },
      {
      open: '196.9500000000000000',
      high: '198.6000000000000000',
      low: '195.9100000000000000',
      close: '197.2700000000000000',
      volume: '83633.8146300000000000',
      quoteVolume: 16501372.8633176,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-30T00:00:00.000Z'
      },
      {
      open: '197.2400000000000000',
      high: '201.4500000000000000',
      low: '193.0100000000000000',
      close: '198.7300000000000000',
      volume: '114739.7042400000000000',
      quoteVolume: 22632315.2602598,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-31T00:00:00.000Z'
      },
      {
      open: '198.7000000000000000',
      high: '200.9500000000000000',
      low: '197.8800000000000000',
      close: '199.9600000000000000',
      volume: '86097.9862900000000000',
      quoteVolume: 17116621.5351845,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-01T00:00:00.000Z'
      },
      {
      open: '199.9300000000000000',
      high: '203.7400000000000000',
      low: '199.3400000000000000',
      close: '202.3500000000000000',
      volume: '97123.2281300000000000',
      quoteVolume: 19559214.4192249,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-02T00:00:00.000Z'
      },
      {
      open: '202.3100000000000000',
      high: '202.6900000000000000',
      low: '198.5900000000000000',
      close: '200.3100000000000000',
      volume: '72084.9293200000000000',
      quoteVolume: 14456528.3775608,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-03T00:00:00.000Z'
      },
      {
      open: '200.2700000000000000',
      high: '218.7600000000000000',
      low: '199.0000000000000000',
      close: '212.1100000000000000',
      volume: '182534.6216000000000000',
      quoteVolume: 37959621.568273,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-04T00:00:00.000Z'
      },
      {
      open: '212.1300000000000000',
      high: '213.3900000000000000',
      low: '207.6200000000000000',
      close: '210.6600000000000000',
      volume: '141432.1406000000000000',
      quoteVolume: 29714567.4106342,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-05T00:00:00.000Z'
      },
      {
      open: '210.6400000000000000',
      high: '221.2500000000000000',
      low: '209.1100000000000000',
      close: '220.7400000000000000',
      volume: '197797.4952700000000000',
      quoteVolume: 42534535.8639938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-06T00:00:00.000Z'
      },
      {
      open: '220.7700000000000000',
      high: '224.8000000000000000',
      low: '217.0100000000000000',
      close: '219.0700000000000000',
      volume: '204931.0342000000000000',
      quoteVolume: 45252424.0852285,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-07T00:00:00.000Z'
      },
      {
      open: '219.0600000000000000',
      high: '220.7800000000000000',
      low: '212.4900000000000000',
      close: '212.7900000000000000',
      volume: '189828.4945000000000000',
      quoteVolume: 40931506.148422,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-08T00:00:00.000Z'
      },
      {
      open: '212.7900000000000000',
      high: '215.5100000000000000',
      low: '209.1000000000000000',
      close: '210.7700000000000000',
      volume: '152215.9468600000000000',
      quoteVolume: 32309082.1975887,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-09T00:00:00.000Z'
      },
      {
      open: '210.7800000000000000',
      high: '215.5500000000000000',
      low: '209.0200000000000000',
      close: '213.2500000000000000',
      volume: '131384.4789300000000000',
      quoteVolume: 28019225.0100827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-10T00:00:00.000Z'
      },
      {
      open: '213.2700000000000000',
      high: '213.9900000000000000',
      low: '209.0000000000000000',
      close: '212.5400000000000000',
      volume: '136268.8115600000000000',
      quoteVolume: 28875079.8849474,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-11T00:00:00.000Z'
      },
      {
      open: '212.6000000000000000',
      high: '214.2200000000000000',
      low: '210.9000000000000000',
      close: '212.8900000000000000',
      volume: '99963.9720300000000000',
      quoteVolume: 21271725.1845819,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-12T00:00:00.000Z'
      },
      {
      open: '212.9100000000000000',
      high: '213.8000000000000000',
      low: '209.2000000000000000',
      close: '209.8400000000000000',
      volume: '114466.3670800000000000',
      quoteVolume: 24176049.6062305,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-13T00:00:00.000Z'
      },
      {
      open: '209.8400000000000000',
      high: '210.3000000000000000',
      low: '176.1600000000000000',
      close: '187.4300000000000000',
      volume: '434676.1683200000000000',
      quoteVolume: 82531353.9766622,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-14T00:00:00.000Z'
      },
      {
      open: '187.4300000000000000',
      high: '190.0000000000000000',
      low: '170.9900000000000000',
      close: '184.3500000000000000',
      volume: '452416.1078400000000000',
      quoteVolume: 81674273.8857771,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-15T00:00:00.000Z'
      },
      {
      open: '184.3500000000000000',
      high: '185.0000000000000000',
      low: '173.0000000000000000',
      close: '176.8900000000000000',
      volume: '241710.3476300000000000',
      quoteVolume: 43450649.948378,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-16T00:00:00.000Z'
      },
      {
      open: '176.9300000000000000',
      high: '177.9100000000000000',
      low: '173.9400000000000000',
      close: '176.2000000000000000',
      volume: '144804.7098600000000000',
      quoteVolume: 25452515.6364988,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-17T00:00:00.000Z'
      },
      {
      open: '176.2000000000000000',
      high: '182.9400000000000000',
      low: '176.1000000000000000',
      close: '178.9700000000000000',
      volume: '141736.3226300000000000',
      quoteVolume: 25261184.0928033,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-18T00:00:00.000Z'
      },
      {
      open: '179.0400000000000000',
      high: '179.3300000000000000',
      low: '146.4700000000000000',
      close: '150.8100000000000000',
      volume: '596789.8391600000000000',
      quoteVolume: 94639227.0313713,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-19T00:00:00.000Z'
      },
      {
      open: '150.7600000000000000',
      high: '154.7000000000000000',
      low: '126.9500000000000000',
      close: '133.8200000000000000',
      volume: '996552.2872100000000000',
      quoteVolume: 139191208.455093,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-20T00:00:00.000Z'
      },
      {
      open: '133.6800000000000000',
      high: '142.0100000000000000',
      low: '127.1500000000000000',
      close: '138.2300000000000000',
      volume: '718957.7855200000000000',
      quoteVolume: 97819681.4905397,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-21T00:00:00.000Z'
      },
      {
      open: '138.3600000000000000',
      high: '140.4000000000000000',
      low: '125.6400000000000000',
      close: '126.6300000000000000',
      volume: '334674.0718500000000000',
      quoteVolume: 44837388.4512714,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-22T00:00:00.000Z'
      },
      {
      open: '126.5800000000000000',
      high: '128.9000000000000000',
      low: '118.5600000000000000',
      close: '125.0200000000000000',
      volume: '504352.2438600000000000',
      quoteVolume: 62657024.0486937,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-23T00:00:00.000Z'
      },
      {
      open: '125.0000000000000000',
      high: '130.8100000000000000',
      low: '112.3200000000000000',
      close: '114.8900000000000000',
      volume: '471321.5676400000000000',
      quoteVolume: 57264800.0665808,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-24T00:00:00.000Z'
      },
      {
      open: '114.8300000000000000',
      high: '122.3500000000000000',
      low: '102.9500000000000000',
      close: '118.6700000000000000',
      volume: '836233.3125200000000000',
      quoteVolume: 93723314.1234466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-25T00:00:00.000Z'
      },
      {
      open: '118.6500000000000000',
      high: '121.5000000000000000',
      low: '106.4100000000000000',
      close: '110.6800000000000000',
      volume: '679477.3623100000000000',
      quoteVolume: 77209761.8275687,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-26T00:00:00.000Z'
      },
      {
      open: '110.6600000000000000',
      high: '114.3100000000000000',
      low: '102.3600000000000000',
      close: '111.4300000000000000',
      volume: '524024.4061600000000000',
      quoteVolume: 56638056.0091172,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-27T00:00:00.000Z'
      },
      {
      open: '111.3800000000000000',
      high: '126.8000000000000000',
      low: '111.3700000000000000',
      close: '122.9000000000000000',
      volume: '578552.7412400000000000',
      quoteVolume: 68485222.4434163,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-28T00:00:00.000Z'
      },
      {
      open: '122.8800000000000000',
      high: '124.0000000000000000',
      low: '114.7800000000000000',
      close: '117.9800000000000000',
      volume: '478777.3961400000000000',
      quoteVolume: 57140533.5732648,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-29T00:00:00.000Z'
      },
      {
      open: '117.9900000000000000',
      high: '120.2600000000000000',
      low: '111.0900000000000000',
      close: '113.7300000000000000',
      volume: '418593.6352900000000000',
      quoteVolume: 48362085.7818422,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-30T00:00:00.000Z'
      },
      {
      open: '113.7100000000000000',
      high: '120.8000000000000000',
      low: '111.3500000000000000',
      close: '118.1000000000000000',
      volume: '330159.0674200000000000',
      quoteVolume: 38614493.6264599,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-01T00:00:00.000Z'
      },
      {
      open: '118.0900000000000000',
      high: '120.7300000000000000',
      low: '116.0000000000000000',
      close: '116.5700000000000000',
      volume: '273657.6215000000000000',
      quoteVolume: 32184013.7601348,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-02T00:00:00.000Z'
      },
      {
      open: '116.6300000000000000',
      high: '116.9600000000000000',
      low: '106.7000000000000000',
      close: '108.7600000000000000',
      volume: '324446.0332400000000000',
      quoteVolume: 36185196.1948889,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-03T00:00:00.000Z'
      },
      {
      open: '108.7600000000000000',
      high: '113.2000000000000000',
      low: '106.0000000000000000',
      close: '109.9400000000000000',
      volume: '275321.0063000000000000',
      quoteVolume: 30365546.7521367,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-04T00:00:00.000Z'
      },
      {
      open: '109.9200000000000000',
      high: '110.5800000000000000',
      low: '102.3800000000000000',
      close: '102.7100000000000000',
      volume: '354071.5324100000000000',
      quoteVolume: 37519336.0718317,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-05T00:00:00.000Z'
      },
      {
      open: '102.7300000000000000',
      high: '104.6800000000000000',
      low: '90.2000000000000000',
      close: '91.0000000000000000',
      volume: '586138.5904300000000000',
      quoteVolume: 58114721.7510634,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-06T00:00:00.000Z'
      },
      {
      open: '91.1800000000000000',
      high: '96.9000000000000000',
      low: '82.0700000000000000',
      close: '93.0500000000000000',
      volume: '1030073.5846700000000000',
      quoteVolume: 90499447.337842,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-07T00:00:00.000Z'
      },
      {
      open: '93.0600000000000000',
      high: '98.0000000000000000',
      low: '84.5500000000000000',
      close: '90.3800000000000000',
      volume: '698217.1252900000000000',
      quoteVolume: 63224518.5473893,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-08T00:00:00.000Z'
      },
      {
      open: '90.3900000000000000',
      high: '98.7000000000000000',
      low: '88.6300000000000000',
      close: '93.2600000000000000',
      volume: '520519.3602300000000000',
      quoteVolume: 48533111.8520108,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-09T00:00:00.000Z'
      },
      {
      open: '93.2600000000000000',
      high: '95.2100000000000000',
      low: '88.1100000000000000',
      close: '89.9100000000000000',
      volume: '408573.0292900000000000',
      quoteVolume: 37180525.4573109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-10T00:00:00.000Z'
      },
      {
      open: '89.8800000000000000',
      high: '91.6500000000000000',
      low: '85.8300000000000000',
      close: '87.7600000000000000',
      volume: '339991.8424600000000000',
      quoteVolume: 30055007.219609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-11T00:00:00.000Z'
      },
      {
      open: '87.7700000000000000',
      high: '91.3100000000000000',
      low: '86.6300000000000000',
      close: '89.4100000000000000',
      volume: '285436.0402900000000000',
      quoteVolume: 25500398.9286536,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-12T00:00:00.000Z'
      },
      {
      open: '89.3900000000000000',
      high: '90.7100000000000000',
      low: '84.7100000000000000',
      close: '86.3400000000000000',
      volume: '331218.3008300000000000',
      quoteVolume: 29089359.8313012,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-13T00:00:00.000Z'
      },
      {
      open: '86.3500000000000000',
      high: '87.2500000000000000',
      low: '82.2000000000000000',
      close: '83.8200000000000000',
      volume: '318003.7342700000000000',
      quoteVolume: 27041687.8001072,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-14T00:00:00.000Z'
      },
      {
      open: '83.8200000000000000',
      high: '85.0000000000000000',
      low: '81.7900000000000000',
      close: '83.7600000000000000',
      volume: '217360.6577500000000000',
      quoteVolume: 18169124.0002302,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-15T00:00:00.000Z'
      },
      {
      open: '83.7500000000000000',
      high: '87.6400000000000000',
      low: '83.7300000000000000',
      close: '84.6700000000000000',
      volume: '241366.7630500000000000',
      quoteVolume: 20783723.6930707,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-16T00:00:00.000Z'
      },
      {
      open: '84.6800000000000000',
      high: '97.0000000000000000',
      low: '84.4000000000000000',
      close: '94.0200000000000000',
      volume: '472252.2338800000000000',
      quoteVolume: 42965553.4158694,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-17T00:00:00.000Z'
      },
      {
      open: '94.0300000000000000',
      high: '101.3000000000000000',
      low: '91.3400000000000000',
      close: '100.3900000000000000',
      volume: '529946.7917500000000000',
      quoteVolume: 50202945.7917017,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-18T00:00:00.000Z'
      },
      {
      open: '100.3900000000000000',
      high: '107.5500000000000000',
      low: '97.7500000000000000',
      close: '98.7400000000000000',
      volume: '827748.5909600000000000',
      quoteVolume: 84862730.2333679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-19T00:00:00.000Z'
      },
      {
      open: '98.7300000000000000',
      high: '116.0000000000000000',
      low: '97.8800000000000000',
      close: '114.0500000000000000',
      volume: '859612.5720500000000000',
      quoteVolume: 93128082.5480073,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-20T00:00:00.000Z'
      },
      {
      open: '113.7100000000000000',
      high: '118.0000000000000000',
      low: '104.7600000000000000',
      close: '107.6900000000000000',
      volume: '666048.7178600000000000',
      quoteVolume: 74510414.5879752,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-21T00:00:00.000Z'
      },
      {
      open: '107.6700000000000000',
      high: '115.5000000000000000',
      low: '105.3800000000000000',
      close: '115.2000000000000000',
      volume: '405672.8311700000000000',
      quoteVolume: 44814571.6096925,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-22T00:00:00.000Z'
      },
      {
      open: '115.1800000000000000',
      high: '131.9400000000000000',
      low: '114.9100000000000000',
      close: '129.0300000000000000',
      volume: '936094.1049000000000000',
      quoteVolume: 118250392.555097,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-23T00:00:00.000Z'
      },
      {
      open: '129.0300000000000000',
      high: '157.6700000000000000',
      low: '128.8000000000000000',
      close: '138.0300000000000000',
      volume: '1319609.8165000000000000',
      quoteVolume: 190137361.065877,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-24T00:00:00.000Z'
      },
      {
      open: '138.0100000000000000',
      high: '139.2600000000000000',
      low: '119.6800000000000000',
      close: '127.6000000000000000',
      volume: '1076164.1280900000000000',
      quoteVolume: 136600862.042676,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-25T00:00:00.000Z'
      },
      {
      open: '127.6100000000000000',
      high: '135.3200000000000000',
      low: '122.5100000000000000',
      close: '129.3500000000000000',
      volume: '794834.0560100000000000',
      quoteVolume: 102196717.395056,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-26T00:00:00.000Z'
      },
      {
      open: '129.3500000000000000',
      high: '131.0900000000000000',
      low: '111.2200000000000000',
      close: '113.8000000000000000',
      volume: '752177.9011100000000000',
      quoteVolume: 90456912.8883521,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-27T00:00:00.000Z'
      },
      {
      open: '113.7500000000000000',
      high: '136.6600000000000000',
      low: '111.8700000000000000',
      close: '135.3000000000000000',
      volume: '894454.1905100000000000',
      quoteVolume: 111865576.497758,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-28T00:00:00.000Z'
      },
      {
      open: '135.3000000000000000',
      high: '145.4200000000000000',
      low: '128.5000000000000000',
      close: '132.8900000000000000',
      volume: '1006654.4984400000000000',
      quoteVolume: 136479145.942347,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-29T00:00:00.000Z'
      },
      {
      open: '133.0000000000000000',
      high: '139.0000000000000000',
      low: '127.0300000000000000',
      close: '137.7700000000000000',
      volume: '704259.4161400000000000',
      quoteVolume: 94804050.4933616,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-30T00:00:00.000Z'
      },
      {
      open: '137.8100000000000000',
      high: '138.6600000000000000',
      low: '128.7000000000000000',
      close: '131.4500000000000000',
      volume: '484604.8396200000000000',
      quoteVolume: 64763118.1166383,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-31T00:00:00.000Z'
      },
      {
      open: '131.4500000000000000',
      high: '140.6700000000000000',
      low: '130.0000000000000000',
      close: '139.1000000000000000',
      volume: '401776.8879700000000000',
      quoteVolume: 54280385.598127,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-01T00:00:00.000Z'
      },
      {
      open: '139.1000000000000000',
      high: '155.3700000000000000',
      low: '137.7600000000000000',
      close: '152.0100000000000000',
      volume: '941486.3152400000000000',
      quoteVolume: 138801471.118652,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-02T00:00:00.000Z'
      },
      {
      open: '152.0100000000000000',
      high: '153.6400000000000000',
      low: '143.1100000000000000',
      close: '146.3000000000000000',
      volume: '708091.3171400000000000',
      quoteVolume: 104953535.148513,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-03T00:00:00.000Z'
      },
      {
      open: '146.3000000000000000',
      high: '154.8000000000000000',
      low: '143.5400000000000000',
      close: '151.9700000000000000',
      volume: '805248.9382100000000000',
      quoteVolume: 120827141.544228,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-04T00:00:00.000Z'
      },
      {
      open: '152.0800000000000000',
      high: '159.2600000000000000',
      low: '151.2000000000000000',
      close: '152.8300000000000000',
      volume: '773169.1373600000000000',
      quoteVolume: 120238339.782219,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-05T00:00:00.000Z'
      },
      {
      open: '152.8300000000000000',
      high: '157.4000000000000000',
      low: '147.5100000000000000',
      close: '153.8600000000000000',
      volume: '627908.4736600000000000',
      quoteVolume: 95681339.4600648,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-06T00:00:00.000Z'
      },
      {
      open: '153.8900000000000000',
      high: '155.4800000000000000',
      low: '147.8600000000000000',
      close: '149.3400000000000000',
      volume: '424765.3025800000000000',
      quoteVolume: 64310962.9190328,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-07T00:00:00.000Z'
      },
      {
      open: '149.2900000000000000',
      high: '151.6500000000000000',
      low: '145.2000000000000000',
      close: '147.6000000000000000',
      volume: '441661.0677800000000000',
      quoteVolume: 65535529.7557393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-08T00:00:00.000Z'
      },
      {
      open: '147.6200000000000000',
      high: '151.5000000000000000',
      low: '146.7700000000000000',
      close: '148.1000000000000000',
      volume: '299443.5630000000000000',
      quoteVolume: 44673066.391582,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-09T00:00:00.000Z'
      },
      {
      open: '148.1100000000000000',
      high: '149.4500000000000000',
      low: '121.0000000000000000',
      close: '124.9500000000000000',
      volume: '871751.7503800000000000',
      quoteVolume: 114437025.282728,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-10T00:00:00.000Z'
      },
      {
      open: '124.9600000000000000',
      high: '127.3600000000000000',
      low: '120.0800000000000000',
      close: '124.6500000000000000',
      volume: '545293.9836900000000000',
      quoteVolume: 67782816.3375987,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-11T00:00:00.000Z'
      },
      {
      open: '124.6700000000000000',
      high: '126.1900000000000000',
      low: '122.0000000000000000',
      close: '123.3500000000000000',
      volume: '300262.1340400000000000',
      quoteVolume: 37295866.3538343,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-12T00:00:00.000Z'
      },
      {
      open: '123.3500000000000000',
      high: '124.2500000000000000',
      low: '111.8700000000000000',
      close: '114.1600000000000000',
      volume: '422561.9677700000000000',
      quoteVolume: 49521201.2764662,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-13T00:00:00.000Z'
      },
      {
      open: '114.1700000000000000',
      high: '130.0500000000000000',
      low: '113.9400000000000000',
      close: '126.9500000000000000',
      volume: '546420.4085400000000000',
      quoteVolume: 66801543.3050366,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-14T00:00:00.000Z'
      },
      {
      open: '126.9500000000000000',
      high: '129.4000000000000000',
      low: '116.0000000000000000',
      close: '118.9500000000000000',
      volume: '649020.5297000000000000',
      quoteVolume: 79985529.5587708,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-15T00:00:00.000Z'
      },
      {
      open: '118.9300000000000000',
      high: '126.8800000000000000',
      low: '118.5200000000000000',
      close: '121.4800000000000000',
      volume: '513486.6547200000000000',
      quoteVolume: 62314225.9960432,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '121.4900000000000000',
      high: '122.9900000000000000',
      low: '116.8000000000000000',
      close: '121.5400000000000000',
      volume: '447535.2271600000000000',
      quoteVolume: 53854069.2105117,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '121.5600000000000000',
      high: '121.9800000000000000',
      low: '117.6600000000000000',
      close: '119.1700000000000000',
      volume: '235646.6840000000000000',
      quoteVolume: 28305971.6688854,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '119.1600000000000000',
      high: '125.2900000000000000',
      low: '119.1400000000000000',
      close: '122.6000000000000000',
      volume: '323303.7929600000000000',
      quoteVolume: 39715159.1649606,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '122.6100000000000000',
      high: '123.3200000000000000',
      low: '115.6800000000000000',
      close: '117.4700000000000000',
      volume: '451792.8535200000000000',
      quoteVolume: 53833668.9066349,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '117.5600000000000000',
      high: '118.5000000000000000',
      low: '113.6100000000000000',
      close: '115.6300000000000000',
      volume: '303632.9346300000000000',
      quoteVolume: 35258073.4282649,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '115.6200000000000000',
      high: '119.4900000000000000',
      low: '111.3900000000000000',
      close: '117.8100000000000000',
      volume: '359542.6387300000000000',
      quoteVolume: 41777737.4704705,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '117.8000000000000000',
      high: '118.9500000000000000',
      low: '114.8000000000000000',
      close: '116.4400000000000000',
      volume: '230247.6157800000000000',
      quoteVolume: 26975386.3924584,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '116.4000000000000000',
      high: '117.1500000000000000',
      low: '113.6100000000000000',
      close: '116.3900000000000000',
      volume: '216242.2982900000000000',
      quoteVolume: 25044265.7542041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '116.4000000000000000',
      high: '117.5000000000000000',
      low: '114.3000000000000000',
      close: '115.1100000000000000',
      volume: '198617.0810800000000000',
      quoteVolume: 22977175.9541193,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '115.1300000000000000',
      high: '118.0000000000000000',
      low: '115.0000000000000000',
      close: '115.3400000000000000',
      volume: '146216.8281900000000000',
      quoteVolume: 16978386.1972377,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '115.3200000000000000',
      high: '116.1500000000000000',
      low: '111.0400000000000000',
      close: '112.0500000000000000',
      volume: '247369.6055500000000000',
      quoteVolume: 28158012.7304768,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '112.0400000000000000',
      high: '112.8700000000000000',
      low: '100.9100000000000000',
      close: '105.5000000000000000',
      volume: '566205.7828800000000000',
      quoteVolume: 60050129.3863579,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '105.4900000000000000',
      high: '106.6100000000000000',
      low: '102.2200000000000000',
      close: '104.4500000000000000',
      volume: '319768.9134800000000000',
      quoteVolume: 33427469.3450618,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '104.4100000000000000',
      high: '109.8000000000000000',
      low: '103.4500000000000000',
      close: '108.2900000000000000',
      volume: '330968.1926500000000000',
      quoteVolume: 35368520.135571,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '108.2900000000000000',
      high: '110.6100000000000000',
      low: '105.4600000000000000',
      close: '106.4400000000000000',
      volume: '290679.6193500000000000',
      quoteVolume: 31234353.515393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '106.4700000000000000',
      high: '108.3500000000000000',
      low: '103.7700000000000000',
      close: '106.7300000000000000',
      volume: '243149.8843500000000000',
      quoteVolume: 25791535.1460238,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '106.7100000000000000',
      high: '111.1500000000000000',
      low: '106.0700000000000000',
      close: '110.4200000000000000',
      volume: '186908.7573000000000000',
      quoteVolume: 20153872.302919,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '110.4100000000000000',
      high: '111.1100000000000000',
      low: '105.8200000000000000',
      close: '107.3600000000000000',
      volume: '227211.5406400000000000',
      quoteVolume: 24605892.2887283,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '107.3300000000000000',
      high: '109.3000000000000000',
      low: '106.5700000000000000',
      close: '107.6800000000000000',
      volume: '160850.1029300000000000',
      quoteVolume: 17312736.6001502,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '107.6500000000000000',
      high: '108.1800000000000000',
      low: '106.4700000000000000',
      close: '107.2700000000000000',
      volume: '159730.1886000000000000',
      quoteVolume: 17137008.2478395,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '107.2800000000000000',
      high: '107.8300000000000000',
      low: '101.2100000000000000',
      close: '104.5000000000000000',
      volume: '337204.3025400000000000',
      quoteVolume: 34984364.2970212,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '104.4900000000000000',
      high: '106.1800000000000000',
      low: '103.8200000000000000',
      close: '104.4800000000000000',
      volume: '147942.0768300000000000',
      quoteVolume: 15530160.6471066,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '104.4800000000000000',
      high: '123.0000000000000000',
      low: '103.4100000000000000',
      close: '119.2500000000000000',
      volume: '589950.6492500000000000',
      quoteVolume: 67027886.9398639,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '119.3200000000000000',
      high: '121.9900000000000000',
      low: '117.6400000000000000',
      close: '119.1900000000000000',
      volume: '328067.9574000000000000',
      quoteVolume: 39061582.3914826,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '119.1900000000000000',
      high: '126.4900000000000000',
      low: '115.8100000000000000',
      close: '125.1200000000000000',
      volume: '385819.0961900000000000',
      quoteVolume: 45929459.1290131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '125.1200000000000000',
      high: '125.2800000000000000',
      low: '119.2400000000000000',
      close: '120.8900000000000000',
      volume: '389405.3040400000000000',
      quoteVolume: 47197595.845191,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '120.8800000000000000',
      high: '123.8900000000000000',
      low: '118.1800000000000000',
      close: '122.4100000000000000',
      volume: '343765.6698700000000000',
      quoteVolume: 41603632.811699,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '122.4000000000000000',
      high: '126.4700000000000000',
      low: '120.7000000000000000',
      close: '121.8600000000000000',
      volume: '317827.3195600000000000',
      quoteVolume: 39073077.8391656,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '121.8600000000000000',
      high: '124.5000000000000000',
      low: '120.1000000000000000',
      close: '120.4200000000000000',
      volume: '297205.7873800000000000',
      quoteVolume: 36278326.0774455,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '120.4700000000000000',
      high: '124.2500000000000000',
      low: '120.1900000000000000',
      close: '121.6700000000000000',
      volume: '255296.0814000000000000',
      quoteVolume: 31111280.2093057,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '121.6600000000000000',
      high: '124.9900000000000000',
      low: '121.5400000000000000',
      close: '122.6700000000000000',
      volume: '215320.7013400000000000',
      quoteVolume: 26462034.6125396,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '122.6700000000000000',
      high: '135.6100000000000000',
      low: '122.4300000000000000',
      close: '133.7500000000000000',
      volume: '614788.6007800000000000',
      quoteVolume: 78958054.9589805,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '133.7700000000000000',
      high: '148.3000000000000000',
      low: '132.4200000000000000',
      close: '145.6900000000000000',
      volume: '1079830.1502000000000000',
      quoteVolume: 152756654.645478,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '145.6900000000000000',
      high: '150.0000000000000000',
      low: '142.3600000000000000',
      close: '143.4400000000000000',
      volume: '747968.9044500000000000',
      quoteVolume: 109645050.466097,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '143.4500000000000000',
      high: '149.5200000000000000',
      low: '139.6000000000000000',
      close: '148.4400000000000000',
      volume: '614303.6100700000000000',
      quoteVolume: 89136482.3716519,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '148.4200000000000000',
      high: '149.6100000000000000',
      low: '142.7000000000000000',
      close: '145.6900000000000000',
      volume: '447364.4087300000000000',
      quoteVolume: 65199779.5124385,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '145.6800000000000000',
      high: '148.8500000000000000',
      low: '143.8500000000000000',
      close: '147.3600000000000000',
      volume: '366251.1854200000000000',
      quoteVolume: 53771600.8025665,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '147.4000000000000000',
      high: '159.6000000000000000',
      low: '145.7800000000000000',
      close: '157.7900000000000000',
      volume: '588183.7289600000000000',
      quoteVolume: 89997516.9234365,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '157.7500000000000000',
      high: '166.0000000000000000',
      low: '131.7000000000000000',
      close: '132.1000000000000000',
      volume: '1260506.8067200000000000',
      quoteVolume: 186688063.414179,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '132.1000000000000000',
      high: '141.5500000000000000',
      low: '131.8900000000000000',
      close: '137.8700000000000000',
      volume: '801147.0954300000000000',
      quoteVolume: 110348892.970024,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '137.8800000000000000',
      high: '138.4100000000000000',
      low: '133.7200000000000000',
      close: '136.2200000000000000',
      volume: '432944.1703300000000000',
      quoteVolume: 58979934.7160016,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '136.2200000000000000',
      high: '140.2900000000000000',
      low: '126.8400000000000000',
      close: '135.0200000000000000',
      volume: '567202.6103500000000000',
      quoteVolume: 76519497.9080148,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '135.0300000000000000',
      high: '139.0000000000000000',
      low: '132.1000000000000000',
      close: '135.3400000000000000',
      volume: '579327.3174900000000000',
      quoteVolume: 78861797.5593921,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '135.3200000000000000',
      high: '137.9500000000000000',
      low: '134.9000000000000000',
      close: '135.3200000000000000',
      volume: '302632.0283100000000000',
      quoteVolume: 41198722.5798789,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '135.3200000000000000',
      high: '136.1200000000000000',
      low: '130.0300000000000000',
      close: '132.5600000000000000',
      volume: '297005.2809300000000000',
      quoteVolume: 39467507.6857296,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '132.5400000000000000',
      high: '134.6000000000000000',
      low: '128.0100000000000000',
      close: '130.6000000000000000',
      volume: '222453.2859000000000000',
      quoteVolume: 29447917.2382588,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '130.6000000000000000',
      high: '131.1100000000000000',
      low: '122.6000000000000000',
      close: '125.6500000000000000',
      volume: '403947.7944500000000000',
      quoteVolume: 51060349.7665833,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '125.6800000000000000',
      high: '137.8400000000000000',
      low: '125.1900000000000000',
      close: '136.7200000000000000',
      volume: '512631.2317800000000000',
      quoteVolume: 67740644.3311417,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '136.7100000000000000',
      high: '140.2900000000000000',
      low: '133.3300000000000000',
      close: '137.4000000000000000',
      volume: '428826.8741500000000000',
      quoteVolume: 58701328.649431,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '137.3700000000000000',
      high: '140.2000000000000000',
      low: '135.2000000000000000',
      close: '136.8100000000000000',
      volume: '307336.4931000000000000',
      quoteVolume: 42271691.2428617,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '136.8200000000000000',
      high: '139.3400000000000000',
      low: '130.6200000000000000',
      close: '133.5200000000000000',
      volume: '330283.5823300000000000',
      quoteVolume: 44879637.3870354,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '133.5100000000000000',
      high: '138.6500000000000000',
      low: '132.9300000000000000',
      close: '137.2700000000000000',
      volume: '279861.1204900000000000',
      quoteVolume: 38220614.005662,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '137.2700000000000000',
      high: '137.5000000000000000',
      low: '134.0000000000000000',
      close: '135.3700000000000000',
      volume: '203965.1681500000000000',
      quoteVolume: 27650702.3793393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '135.3700000000000000',
      high: '136.1500000000000000',
      low: '131.0000000000000000',
      close: '132.6700000000000000',
      volume: '264587.0068300000000000',
      quoteVolume: 35285847.7482702,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '132.6700000000000000',
      high: '135.3000000000000000',
      low: '130.7700000000000000',
      close: '133.5700000000000000',
      volume: '194905.9684300000000000',
      quoteVolume: 25981446.1033466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '133.6200000000000000',
      high: '133.6500000000000000',
      low: '130.5800000000000000',
      close: '131.6500000000000000',
      volume: '209456.0984800000000000',
      quoteVolume: 27670734.4309227,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '131.6900000000000000',
      high: '135.1100000000000000',
      low: '128.5200000000000000',
      close: '132.2000000000000000',
      volume: '258413.8186800000000000',
      quoteVolume: 34099414.8055834,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '132.2400000000000000',
      high: '137.6200000000000000',
      low: '132.1800000000000000',
      close: '136.5600000000000000',
      volume: '295832.2656200000000000',
      quoteVolume: 39928522.3372778,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '136.6000000000000000',
      high: '144.4700000000000000',
      low: '136.5100000000000000',
      close: '140.9200000000000000',
      volume: '474664.1019700000000000',
      quoteVolume: 66878173.4518186,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '140.9400000000000000',
      high: '141.0700000000000000',
      low: '137.2000000000000000',
      close: '138.6700000000000000',
      volume: '252697.0814800000000000',
      quoteVolume: 35051209.9303395,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '138.6700000000000000',
      high: '142.0100000000000000',
      low: '136.1000000000000000',
      close: '137.5900000000000000',
      volume: '281718.9619500000000000',
      quoteVolume: 39017864.9224565,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '137.6200000000000000',
      high: '139.3800000000000000',
      low: '136.9700000000000000',
      close: '138.5500000000000000',
      volume: '218577.4520800000000000',
      quoteVolume: 30175322.0784989,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '138.5600000000000000',
      high: '139.6600000000000000',
      low: '136.0100000000000000',
      close: '139.0100000000000000',
      volume: '222883.5175400000000000',
      quoteVolume: 30757997.8115518,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '139.0400000000000000',
      high: '139.5500000000000000',
      low: '131.2100000000000000',
      close: '134.8500000000000000',
      volume: '312531.3936800000000000',
      quoteVolume: 42635937.4891712,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '134.8500000000000000',
      high: '137.2000000000000000',
      low: '134.2900000000000000',
      close: '135.9800000000000000',
      volume: '186119.7474500000000000',
      quoteVolume: 25309122.7264498,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '135.9200000000000000',
      high: '138.2100000000000000',
      low: '135.6000000000000000',
      close: '137.2800000000000000',
      volume: '174710.4305900000000000',
      quoteVolume: 23922572.3588934,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '137.2800000000000000',
      high: '137.3700000000000000',
      low: '134.7500000000000000',
      close: '136.0900000000000000',
      volume: '198490.4512400000000000',
      quoteVolume: 27042322.6952009,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '136.1200000000000000',
      high: '136.8300000000000000',
      low: '131.6000000000000000',
      close: '133.9800000000000000',
      volume: '230880.2850000000000000',
      quoteVolume: 31082445.5932449,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '133.9700000000000000',
      high: '134.7400000000000000',
      low: '132.2700000000000000',
      close: '134.1800000000000000',
      volume: '183139.1142600000000000',
      quoteVolume: 24490323.0607397,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '134.1400000000000000',
      high: '140.1400000000000000',
      low: '133.8400000000000000',
      close: '139.4500000000000000',
      volume: '317167.4532500000000000',
      quoteVolume: 43635238.4289265,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '139.4600000000000000',
      high: '139.5700000000000000',
      low: '137.3000000000000000',
      close: '137.8600000000000000',
      volume: '163185.6991000000000000',
      quoteVolume: 22543474.6122913,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '137.8500000000000000',
      high: '143.6000000000000000',
      low: '137.6200000000000000',
      close: '143.2500000000000000',
      volume: '235853.0454000000000000',
      quoteVolume: 33098245.9556135,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '143.2500000000000000',
      high: '145.8900000000000000',
      low: '140.0600000000000000',
      close: '142.4600000000000000',
      volume: '254798.7006700000000000',
      quoteVolume: 36382647.2268952,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '142.4400000000000000',
      high: '142.5000000000000000',
      low: '140.4200000000000000',
      close: '141.3800000000000000',
      volume: '142469.2117100000000000',
      quoteVolume: 20131931.6990724,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '141.4100000000000000',
      high: '143.0800000000000000',
      low: '139.5700000000000000',
      close: '141.2400000000000000',
      volume: '234310.1916100000000000',
      quoteVolume: 33234998.6438733,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '141.2400000000000000',
      high: '165.6000000000000000',
      low: '141.0000000000000000',
      close: '163.3100000000000000',
      volume: '906868.8586000000000000',
      quoteVolume: 138094679.302043,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '163.2600000000000000',
      high: '177.8100000000000000',
      low: '149.2300000000000000',
      close: '159.9600000000000000',
      volume: '1345344.5384500000000000',
      quoteVolume: 225523896.201466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '159.9600000000000000',
      high: '164.0000000000000000',
      low: '152.8000000000000000',
      close: '157.3600000000000000',
      volume: '686080.9524800000000000',
      quoteVolume: 109082183.587735,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '157.3300000000000000',
      high: '167.8300000000000000',
      low: '155.9400000000000000',
      close: '164.7700000000000000',
      volume: '516070.3455300000000000',
      quoteVolume: 84123101.326904,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '164.8400000000000000',
      high: '172.7700000000000000',
      low: '159.2000000000000000',
      close: '165.5000000000000000',
      volume: '528168.3329700000000000',
      quoteVolume: 87657058.1149817,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '165.5600000000000000',
      high: '175.4300000000000000',
      low: '164.0600000000000000',
      close: '174.2100000000000000',
      volume: '465507.5249700000000000',
      quoteVolume: 78876773.2967177,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '174.2900000000000000',
      high: '185.8700000000000000',
      low: '170.9000000000000000',
      close: '178.7200000000000000',
      volume: '873410.0728500000000000',
      quoteVolume: 156417981.302118,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '178.7100000000000000',
      high: '179.1700000000000000',
      low: '171.0500000000000000',
      close: '174.6000000000000000',
      volume: '431986.3610800000000000',
      quoteVolume: 75745619.5720787,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '174.5800000000000000',
      high: '183.7700000000000000',
      low: '168.0000000000000000',
      close: '177.1200000000000000',
      volume: '523943.2049800000000000',
      quoteVolume: 93283836.587114,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '177.1200000000000000',
      high: '177.4800000000000000',
      low: '158.2700000000000000',
      close: '164.0700000000000000',
      volume: '661305.3032100000000000',
      quoteVolume: 110201659.947876,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '164.0600000000000000',
      high: '166.5000000000000000',
      low: '158.1100000000000000',
      close: '163.1900000000000000',
      volume: '316337.8782300000000000',
      quoteVolume: 51556919.1461367,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '163.2000000000000000',
      high: '165.3700000000000000',
      low: '160.7400000000000000',
      close: '162.9900000000000000',
      volume: '179237.7457400000000000',
      quoteVolume: 29247030.1526373,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '162.9900000000000000',
      high: '168.1100000000000000',
      low: '161.2000000000000000',
      close: '167.2300000000000000',
      volume: '186977.6621100000000000',
      quoteVolume: 30640985.0609499,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '167.2100000000000000',
      high: '168.8100000000000000',
      low: '155.7000000000000000',
      close: '159.9500000000000000',
      volume: '351548.9079000000000000',
      quoteVolume: 57215098.1117043,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '159.9100000000000000',
      high: '166.7300000000000000',
      low: '159.4100000000000000',
      close: '165.3700000000000000',
      volume: '240370.5044100000000000',
      quoteVolume: 39245823.494595,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '165.4100000000000000',
      high: '167.2800000000000000',
      low: '163.7800000000000000',
      close: '165.7500000000000000',
      volume: '227841.5498100000000000',
      quoteVolume: 37678036.5964673,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '165.7500000000000000',
      high: '175.2000000000000000',
      low: '165.0400000000000000',
      close: '173.3300000000000000',
      volume: '396118.9440700000000000',
      quoteVolume: 68031293.2325779,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '173.3400000000000000',
      high: '173.5500000000000000',
      low: '168.5900000000000000',
      close: '172.6400000000000000',
      volume: '320541.7216600000000000',
      quoteVolume: 54991300.1097207,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '172.6600000000000000',
      high: '176.9700000000000000',
      low: '169.8700000000000000',
      close: '172.8800000000000000',
      volume: '253596.4840700000000000',
      quoteVolume: 43939979.0160537,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '172.8900000000000000',
      high: '173.4800000000000000',
      low: '164.8200000000000000',
      close: '168.5900000000000000',
      volume: '283450.4394300000000000',
      quoteVolume: 47781349.4020958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '168.5900000000000000',
      high: '172.3000000000000000',
      low: '167.0000000000000000',
      close: '170.5200000000000000',
      volume: '269868.3987600000000000',
      quoteVolume: 45832751.057015,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '170.5200000000000000',
      high: '175.8300000000000000',
      low: '167.7800000000000000',
      close: '169.1600000000000000',
      volume: '363523.3453100000000000',
      quoteVolume: 62784241.170476,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '169.1500000000000000',
      high: '169.8700000000000000',
      low: '159.2600000000000000',
      close: '164.7800000000000000',
      volume: '426497.0415300000000000',
      quoteVolume: 70076178.2845027,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '164.7800000000000000',
      high: '165.4900000000000000',
      low: '150.3500000000000000',
      close: '154.7200000000000000',
      volume: '422657.0323900000000000',
      quoteVolume: 67326464.6484704,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '154.7000000000000000',
      high: '162.5600000000000000',
      low: '151.4000000000000000',
      close: '157.9500000000000000',
      volume: '500355.8628300000000000',
      quoteVolume: 78334214.8744062,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '157.9300000000000000',
      high: '166.2400000000000000',
      low: '156.3500000000000000',
      close: '159.3100000000000000',
      volume: '215918.6384200000000000',
      quoteVolume: 34294277.9954465,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '159.3200000000000000',
      high: '160.2400000000000000',
      low: '156.9200000000000000',
      close: '157.8500000000000000',
      volume: '152976.3018100000000000',
      quoteVolume: 24304182.1410825,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '157.8600000000000000',
      high: '159.0500000000000000',
      low: '152.0000000000000000',
      close: '155.1100000000000000',
      volume: '243591.9489800000000000',
      quoteVolume: 37892463.2904875,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '155.1400000000000000',
      high: '162.8900000000000000',
      low: '155.1100000000000000',
      close: '161.8300000000000000',
      volume: '305056.2465300000000000',
      quoteVolume: 48352308.3471109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '161.8500000000000000',
      high: '163.8800000000000000',
      low: '158.3600000000000000',
      close: '160.1900000000000000',
      volume: '192517.6276300000000000',
      quoteVolume: 30965351.6968463,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '160.2000000000000000',
      high: '163.4800000000000000',
      low: '158.5000000000000000',
      close: '161.8100000000000000',
      volume: '289519.0202500000000000',
      quoteVolume: 46716417.0127823,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '161.8200000000000000',
      high: '170.8600000000000000',
      low: '160.9900000000000000',
      close: '168.0600000000000000',
      volume: '416357.5212300000000000',
      quoteVolume: 69185162.5971677,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '168.1000000000000000',
      high: '171.3000000000000000',
      low: '159.7600000000000000',
      close: '163.7000000000000000',
      volume: '312928.5834900000000000',
      quoteVolume: 51596174.4008955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '163.7100000000000000',
      high: '165.3600000000000000',
      low: '159.1800000000000000',
      close: '163.0500000000000000',
      volume: '176029.1044500000000000',
      quoteVolume: 28659516.7862457,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '163.0500000000000000',
      high: '178.0500000000000000',
      low: '157.5500000000000000',
      close: '173.0000000000000000',
      volume: '506528.1427800000000000',
      quoteVolume: 86112232.475863,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '172.9800000000000000',
      high: '182.7600000000000000',
      low: '167.8900000000000000',
      close: '169.1800000000000000',
      volume: '732496.8786100000000000',
      quoteVolume: 129206700.127684,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '169.0700000000000000',
      high: '173.1700000000000000',
      low: '164.8000000000000000',
      close: '170.8500000000000000',
      volume: '296410.7610300000000000',
      quoteVolume: 50307994.5847109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '170.8800000000000000',
      high: '173.8800000000000000',
      low: '166.5700000000000000',
      close: '170.9000000000000000',
      volume: '284663.9945600000000000',
      quoteVolume: 48482365.5414184,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '170.9600000000000000',
      high: '176.7000000000000000',
      low: '168.4600000000000000',
      close: '172.9100000000000000',
      volume: '425543.7402100000000000',
      quoteVolume: 73717438.6539584,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '172.9200000000000000',
      high: '205.2300000000000000',
      low: '172.9200000000000000',
      close: '190.2000000000000000',
      volume: '885292.3838900000000000',
      quoteVolume: 167180496.76233,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '190.2000000000000000',
      high: '204.3200000000000000',
      low: '181.0000000000000000',
      close: '188.1100000000000000',
      volume: '823372.2814800000000000',
      quoteVolume: 157611011.725251,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '188.0700000000000000',
      high: '206.8700000000000000',
      low: '185.3200000000000000',
      close: '195.8400000000000000',
      volume: '669265.4652400000000000',
      quoteVolume: 131102303.13857,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '195.8300000000000000',
      high: '219.8100000000000000',
      low: '194.1900000000000000',
      close: '216.4000000000000000',
      volume: '864211.0060300000000000',
      quoteVolume: 179717430.310727,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '216.4000000000000000',
      high: '250.2700000000000000',
      low: '216.2100000000000000',
      close: '248.1000000000000000',
      volume: '574697.2992700000000000',
      quoteVolume: 135054369.61757,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '248.0600000000000000',
      high: '279.7600000000000000',
      low: '241.7600000000000000',
      close: '264.2500000000000000',
      volume: '1733360.8936000000000000',
      quoteVolume: 452410350.051738,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '264.3500000000000000',
      high: '268.7400000000000000',
      low: '223.4300000000000000',
      close: '244.3000000000000000',
      volume: '1529892.5689800000000000',
      quoteVolume: 366429299.202803,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '244.3100000000000000',
      high: '248.0000000000000000',
      low: '230.6200000000000000',
      close: '233.6000000000000000',
      volume: '673218.0998200000000000',
      quoteVolume: 160450040.806919,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '233.5700000000000000',
      high: '264.0000000000000000',
      low: '231.7700000000000000',
      close: '259.5100000000000000',
      volume: '823859.0634200000000000',
      quoteVolume: 207383027.826484,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '259.3700000000000000',
      high: '259.4900000000000000',
      low: '238.0000000000000000',
      close: '250.3700000000000000',
      volume: '666889.8224100000000000',
      quoteVolume: 165783106.07703,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '250.4800000000000000',
      high: '262.0000000000000000',
      low: '246.0600000000000000',
      close: '253.4300000000000000',
      volume: '580249.6975300000000000',
      quoteVolume: 146749988.273737,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '253.3500000000000000',
      high: '260.1800000000000000',
      low: '239.6300000000000000',
      close: '243.6400000000000000',
      volume: '592848.9560500000000000',
      quoteVolume: 149006735.642177,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '243.6800000000000000',
      high: '247.3400000000000000',
      low: '231.1000000000000000',
      close: '244.5600000000000000',
      volume: '600193.8936800000000000',
      quoteVolume: 144051488.877393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '244.6300000000000000',
      high: '254.3500000000000000',
      low: '241.5400000000000000',
      close: '248.8700000000000000',
      volume: '447066.9259600000000000',
      quoteVolume: 111294049.366715,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '248.7500000000000000',
      high: '257.3300000000000000',
      low: '247.2600000000000000',
      close: '250.2900000000000000',
      volume: '210086.6689900000000000',
      quoteVolume: 52883558.98214837,
      btcVolume: 6601.861190292515,
      usdVolume: 52883558.98214837,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '250.5800000000000000',
      high: '269.3300000000000000',
      low: '245.1100000000000000',
      close: '264.4300000000000000',
      volume: '328906.1700300000000000',
      quoteVolume: 84389359.21664856,
      btcVolume: 10168.644937039242,
      usdVolume: 84389359.21664856,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '264.4400000000000000',
      high: '279.5900000000000000',
      low: '262.0000000000000000',
      close: '270.5700000000000000',
      volume: '430881.9927300000000000',
      quoteVolume: 115553890.30784754,
      btcVolume: 13224.875481256204,
      usdVolume: 115553890.30784754,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '270.6500000000000000',
      high: '275.9500000000000000',
      low: '264.7100000000000000',
      close: '271.5400000000000000',
      volume: '244142.8490000000000000',
      quoteVolume: 66001001.032268964,
      btcVolume: 7580.161845035036,
      usdVolume: 66001001.032268964,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '270.7600000000000000',
      high: '274.0400000000000000',
      low: '260.0500000000000000',
      close: '268.9400000000000000',
      volume: '250176.3188600000000000',
      quoteVolume: 67085217.72845518,
      btcVolume: 7783.531911611134,
      usdVolume: 67085217.72845518,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '268.8900000000000000',
      high: '288.6200000000000000',
      low: '240.1400000000000000',
      close: '254.3900000000000000',
      volume: '874566.2467900000000000',
      quoteVolume: 237214565.53410167,
      btcVolume: 27663.736785432655,
      usdVolume: 237214565.53410167,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '254.5600000000000000',
      high: '268.7200000000000000',
      low: '245.2100000000000000',
      close: '267.5000000000000000',
      volume: '435596.2624900000000000',
      quoteVolume: 111862948.81367387,
      btcVolume: 13416.233814032905,
      usdVolume: 111862948.81367387,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '268.5500000000000000',
      high: '275.5000000000000000',
      low: '260.6800000000000000',
      close: '264.3300000000000000',
      volume: '340251.6517200000000000',
      quoteVolume: 90953282.63449085,
      btcVolume: 10654.39476621369,
      usdVolume: 90953282.63449085,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '264.3300000000000000',
      high: '273.2000000000000000',
      low: '263.2000000000000000',
      close: '268.8800000000000000',
      volume: '301536.1506200000000000',
      quoteVolume: 80960737.4054651,
      btcVolume: 9351.28557475043,
      usdVolume: 80960737.4054651,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '268.8700000000000000',
      high: '270.0000000000000000',
      low: '248.0000000000000000',
      close: '249.9100000000000000',
      volume: '390829.8328400000000000',
      quoteVolume: 101905937.03778602,
      btcVolume: 12020.341131421099,
      usdVolume: 101905937.03778602,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '250.0200000000000000',
      high: '251.6700000000000000',
      low: '234.3900000000000000',
      close: '241.3900000000000000',
      volume: '633054.5403700000000000',
      quoteVolume: 154522639.97359976,
      btcVolume: 19697.30421417344,
      usdVolume: 154522639.97359976,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '241.4600000000000000',
      high: '250.4100000000000000',
      low: '237.3700000000000000',
      close: '245.6700000000000000',
      volume: '335951.0818300000000000',
      quoteVolume: 81838722.29639693,
      btcVolume: 10541.8570319359,
      usdVolume: 81838722.29639693,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '245.6300000000000000',
      high: '250.6600000000000000',
      low: '235.0100000000000000',
      close: '248.7300000000000000',
      volume: '297333.9071700000000000',
      quoteVolume: 72359467.8907992,
      btcVolume: 9375.200502885184,
      usdVolume: 72359467.8907992,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '248.7500000000000000',
      high: '253.2800000000000000',
      low: '243.4600000000000000',
      close: '248.7700000000000000',
      volume: '353382.9471000000000000',
      quoteVolume: 87645350.54723677,
      btcVolume: 11105.098897639275,
      usdVolume: 87645350.54723677,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '248.7800000000000000',
      high: '250.5300000000000000',
      low: '240.3700000000000000',
      close: '243.2500000000000000',
      volume: '188448.2774800000000000',
      quoteVolume: 46293916.7909611,
      btcVolume: 5858.450696551191,
      usdVolume: 46293916.7909611,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '243.3300000000000000',
      high: '244.5300000000000000',
      low: '226.5600000000000000',
      close: '230.9800000000000000',
      volume: '316894.1012500000000000',
      quoteVolume: 74585371.36728549,
      btcVolume: 9656.729097772812,
      usdVolume: 74585371.36728549,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '230.9800000000000000',
      high: '247.4500000000000000',
      low: '227.0300000000000000',
      close: '246.5800000000000000',
      volume: '314636.9740500000000000',
      quoteVolume: 75084193.16334817,
      btcVolume: 9642.047156408467,
      usdVolume: 75084193.16334817,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '246.6400000000000000',
      high: '247.8000000000000000',
      low: '236.4900000000000000',
      close: '244.2800000000000000',
      volume: '236917.8619100000000000',
      quoteVolume: 57405428.93464183,
      btcVolume: 7322.677723536042,
      usdVolume: 57405428.93464183,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '244.2900000000000000',
      high: '261.8700000000000000',
      low: '242.2000000000000000',
      close: '261.0400000000000000',
      volume: '334914.1948300000000000',
      quoteVolume: 84260792.21337387,
      btcVolume: 10493.689905645679,
      usdVolume: 84260792.21337387,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '261.0400000000000000',
      high: '262.9800000000000000',
      low: '253.6900000000000000',
      close: '254.7000000000000000',
      volume: '291757.4165600000000000',
      quoteVolume: 75447458.94669941,
      btcVolume: 9258.053283515925,
      usdVolume: 75447458.94669941,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '254.6700000000000000',
      high: '264.2600000000000000',
      low: '251.7500000000000000',
      close: '262.4700000000000000',
      volume: '327384.9350300000000000',
      quoteVolume: 84046270.42804858,
      btcVolume: 10052.089943759258,
      usdVolume: 84046270.42804858,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '262.4800000000000000',
      high: '272.2700000000000000',
      low: '260.6100000000000000',
      close: '267.8100000000000000',
      volume: '286562.0009300000000000',
      quoteVolume: 76044367.43084204,
      btcVolume: 8744.231544379601,
      usdVolume: 76044367.43084204,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '267.8200000000000000',
      high: '277.0600000000000000',
      low: '263.3100000000000000',
      close: '267.8200000000000000',
      volume: '457043.8053700000000000',
      quoteVolume: 123543186.81485234,
      btcVolume: 13633.549352858961,
      usdVolume: 123543186.81485234,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '267.7100000000000000',
      high: '276.0000000000000000',
      low: '266.8100000000000000',
      close: '273.0900000000000000',
      volume: '339245.3390700000000000',
      quoteVolume: 91798515.34806772,
      btcVolume: 9973.204806382242,
      usdVolume: 91798515.34806772,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '273.1200000000000000',
      high: '273.5800000000000000',
      low: '261.4500000000000000',
      close: '264.6600000000000000',
      volume: '319694.8046200000000000',
      quoteVolume: 85270720.28219834,
      btcVolume: 9341.837857544495,
      usdVolume: 85191137.66587001,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '264.6800000000000000',
      high: '270.0000000000000000',
      low: '263.5900000000000000',
      close: '268.1400000000000000',
      volume: '208374.2487300000000000',
      quoteVolume: 55665534.352045216,
      btcVolume: 6091.847271949922,
      usdVolume: 55665534.352045216,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '268.1400000000000000',
      high: '273.6300000000000000',
      low: '265.7100000000000000',
      close: '271.2200000000000000',
      volume: '197206.8955100000000000',
      quoteVolume: 53051977.61480555,
      btcVolume: 5666.18245046985,
      usdVolume: 53051977.61480555,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '271.2700000000000000',
      high: '295.9400000000000000',
      low: '271.2200000000000000',
      close: '294.3000000000000000',
      volume: '519753.0589000000000000',
      quoteVolume: 148622967.47444713,
      btcVolume: 15205.227567841224,
      usdVolume: 148622967.47444713,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '294.3200000000000000',
      high: '314.3900000000000000',
      low: '289.6900000000000000',
      close: '310.6100000000000000',
      volume: '716598.8706100000000000',
      quoteVolume: 219105237.54071936,
      btcVolume: 20528.78896259923,
      usdVolume: 219105237.54071936,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '310.7000000000000000',
      high: '321.7000000000000000',
      low: '305.5800000000000000',
      close: '308.5200000000000000',
      volume: '418569.8935300000000000',
      quoteVolume: 131176119.43065393,
      btcVolume: 12082.996427040667,
      usdVolume: 131176119.43065393,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '308.4200000000000000',
      high: '314.4100000000000000',
      low: '298.0000000000000000',
      close: '311.6800000000000000',
      volume: '352738.3189300000000000',
      quoteVolume: 108423026.96658173,
      btcVolume: 9981.964869792726,
      usdVolume: 108423026.96658173,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '311.6400000000000000',
      high: '318.9900000000000000',
      low: '307.0000000000000000',
      close: '318.2600000000000000',
      volume: '345551.7547400000000000',
      quoteVolume: 108140713.48472323,
      btcVolume: 9505.539339201336,
      usdVolume: 108140713.48472323,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '318.2900000000000000',
      high: '366.8000000000000000',
      low: '315.5700000000000000',
      close: '339.3900000000000000',
      volume: '977599.1145100000000000',
      quoteVolume: 331095769.33132416,
      btcVolume: 25950.15573069012,
      usdVolume: 331095769.33132416,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '339.5300000000000000',
      high: '347.0000000000000000',
      low: '280.0100000000000000',
      close: '298.3500000000000000',
      volume: '1008017.1146500000000000',
      quoteVolume: 312540982.32200617,
      btcVolume: 26554.17730475792,
      usdVolume: 312540982.32200617,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '298.3500000000000000',
      high: '313.5800000000000000',
      low: '295.2200000000000000',
      close: '310.9400000000000000',
      volume: '529156.4880900000000000',
      quoteVolume: 161833162.7906752,
      btcVolume: 13747.592392081055,
      usdVolume: 161833162.7906752,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '311.0400000000000000',
      high: '324.7400000000000000',
      low: '295.1800000000000000',
      close: '317.6200000000000000',
      volume: '424971.8825400000000000',
      quoteVolume: 130248119.6772739,
      btcVolume: 10938.351707879097,
      usdVolume: 130248119.6772739,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '317.6200000000000000',
      high: '323.5000000000000000',
      low: '290.1200000000000000',
      close: '292.6500000000000000',
      volume: '489053.0179000000000000',
      quoteVolume: 149535574.11292773,
      btcVolume: 12975.985612883589,
      usdVolume: 149535574.11292773,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '292.7900000000000000',
      high: '303.4900000000000000',
      low: '280.1300000000000000',
      close: '295.0700000000000000',
      volume: '489783.9887600000000000',
      quoteVolume: 142876245.52713487,
      btcVolume: 13339.488752079711,
      usdVolume: 142876245.52713487,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '295.0300000000000000',
      high: '297.5700000000000000',
      low: '272.6500000000000000',
      close: '291.4800000000000000',
      volume: '493473.2749400000000000',
      quoteVolume: 139935800.5385573,
      btcVolume: 13679.520911516493,
      usdVolume: 139935800.5385573,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '291.4700000000000000',
      high: '302.8900000000000000',
      low: '285.0000000000000000',
      close: '301.1400000000000000',
      volume: '369294.5282900000000000',
      quoteVolume: 108898870.22858743,
      btcVolume: 9594.832986006992,
      usdVolume: 108898870.22858743,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '301.1100000000000000',
      high: '302.7400000000000000',
      low: '280.5300000000000000',
      close: '282.8200000000000000',
      volume: '269955.9546000000000000',
      quoteVolume: 79265333.87642588,
      btcVolume: 6797.331954964179,
      usdVolume: 79265333.87642588,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '282.8300000000000000',
      high: '294.8900000000000000',
      low: '280.7800000000000000',
      close: '287.4700000000000000',
      volume: '261133.4233100000000000',
      quoteVolume: 75163436.31124392,
      btcVolume: 6755.597179253407,
      usdVolume: 75163436.31124392,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '287.3200000000000000',
      high: '296.7100000000000000',
      low: '285.2800000000000000',
      close: '288.2300000000000000',
      volume: '196064.5701800000000000',
      quoteVolume: 57137760.438672334,
      btcVolume: 5022.881732165552,
      usdVolume: 57137760.438672334,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '288.2900000000000000',
      high: '309.6900000000000000',
      low: '284.2500000000000000',
      close: '304.7200000000000000',
      volume: '246110.6519400000000000',
      quoteVolume: 73170593.82215981,
      btcVolume: 6461.019883114503,
      usdVolume: 73170593.82215981,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '304.7200000000000000',
      high: '313.7400000000000000',
      low: '300.7800000000000000',
      close: '312.0300000000000000',
      volume: '286873.6683400000000000',
      quoteVolume: 88048229.33933607,
      btcVolume: 7475.430726388852,
      usdVolume: 88048229.33933607,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '311.9700000000000000',
      high: '318.1900000000000000',
      low: '302.6900000000000000',
      close: '307.4700000000000000',
      volume: '305980.4263800000000000',
      quoteVolume: 95131881.31853455,
      btcVolume: 7644.49484918057,
      usdVolume: 95131881.31853455,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '307.4600000000000000',
      high: '314.1800000000000000',
      low: '281.0400000000000000',
      close: '289.0700000000000000',
      volume: '453318.0444900000000000',
      quoteVolume: 134696704.5792062,
      btcVolume: 10731.201391195193,
      usdVolume: 134696704.5792062,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '289.2300000000000000',
      high: '289.2300000000000000',
      low: '262.1200000000000000',
      close: '268.6300000000000000',
      volume: '499749.9402200000000000',
      quoteVolume: 135963256.5679943,
      btcVolume: 11808.225077232584,
      usdVolume: 135963256.5679943,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '268.6700000000000000',
      high: '278.9900000000000000',
      low: '266.2100000000000000',
      close: '274.6200000000000000',
      volume: '266703.2448100000000000',
      quoteVolume: 72734970.85533215,
      btcVolume: 6307.693377250677,
      usdVolume: 72734970.85533215,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '274.5300000000000000',
      high: '275.0400000000000000',
      low: '262.2000000000000000',
      close: '268.7900000000000000',
      volume: '205956.2684900000000000',
      quoteVolume: 55322770.19371056,
      btcVolume: 4873.915839593925,
      usdVolume: 55322770.19371056,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '268.8000000000000000',
      high: '269.7400000000000000',
      low: '222.8100000000000000',
      close: '225.8700000000000000',
      volume: '530763.9314500000000000',
      quoteVolume: 128508198.8008858,
      btcVolume: 12029.780617969636,
      usdVolume: 128508198.8008858,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '225.8700000000000000',
      high: '235.5800000000000000',
      low: '203.0800000000000000',
      close: '228.2000000000000000',
      volume: '713930.7343200000000000',
      quoteVolume: 159360393.30564103,
      btcVolume: 15433.265597050142,
      usdVolume: 159360393.30564103,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '228.2000000000000000',
      high: '234.5000000000000000',
      low: '192.2500000000000000',
      close: '198.8800000000000000',
      volume: '703463.4360500000000000',
      quoteVolume: 149069538.53840336,
      btcVolume: 14722.108901822574,
      usdVolume: 149069538.53840336,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '198.8900000000000000',
      high: '219.8600000000000000',
      low: '191.8400000000000000',
      close: '210.8100000000000000',
      volume: '694454.2491200000000000',
      quoteVolume: 142940279.97858965,
      btcVolume: 14966.311310757123,
      usdVolume: 142940279.97858965,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '210.8100000000000000',
      high: '229.6500000000000000',
      low: '205.7100000000000000',
      close: '225.7600000000000000',
      volume: '607720.5507500000000000',
      quoteVolume: 132341223.14210978,
      btcVolume: 13229.997952454805,
      usdVolume: 132225049.702838,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '225.7300000000000000',
      high: '226.2300000000000000',
      low: '213.4000000000000000',
      close: '220.7300000000000000',
      volume: '367728.2543200000000000',
      quoteVolume: 80763332.4244571,
      btcVolume: 7739.2579698659,
      usdVolume: 80763332.4244571,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '220.8400000000000000',
      high: '235.0900000000000000',
      low: '219.7800000000000000',
      close: '228.2000000000000000',
      volume: '324481.0616300000000000',
      quoteVolume: 73975253.97204855,
      btcVolume: 6947.4776892394175,
      usdVolume: 73975253.97204855,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '228.2500000000000000',
      high: '229.6600000000000000',
      low: '216.9900000000000000',
      close: '225.4200000000000000',
      volume: '269941.8271000000000000',
      quoteVolume: 60398168.970782824,
      btcVolume: 5727.828146485641,
      usdVolume: 60398168.970782824,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '225.3800000000000000',
      high: '228.3400000000000000',
      low: '212.2500000000000000',
      close: '217.5100000000000000',
      volume: '270400.5234700000000000',
      quoteVolume: 59405061.677015305,
      btcVolume: 5704.734783903516,
      usdVolume: 59405061.677015305,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '217.5900000000000000',
      high: '219.5500000000000000',
      low: '208.3600000000000000',
      close: '212.6500000000000000',
      volume: '317846.4293800000000000',
      quoteVolume: 67923781.93982494,
      btcVolume: 6750.32948411748,
      usdVolume: 67923781.93982494,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '212.4500000000000000',
      high: '218.2800000000000000',
      low: '202.0000000000000000',
      close: '216.2400000000000000',
      volume: '330735.0082700000000000',
      quoteVolume: 69192671.19910969,
      btcVolume: 7118.715377791444,
      usdVolume: 69192671.19910969,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '216.3500000000000000',
      high: '225.1200000000000000',
      low: '215.2300000000000000',
      close: '219.1400000000000000',
      volume: '280365.4146300000000000',
      quoteVolume: 62016363.6238403,
      btcVolume: 6193.944875951812,
      usdVolume: 62016363.6238403,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '219.1400000000000000',
      high: '220.0000000000000000',
      low: '212.7100000000000000',
      close: '218.8100000000000000',
      volume: '197602.4657900000000000',
      quoteVolume: 42800909.35558814,
      btcVolume: 4382.145350153194,
      usdVolume: 42800909.35558814,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '218.8100000000000000',
      high: '223.3000000000000000',
      low: '203.0000000000000000',
      close: '207.2300000000000000',
      volume: '296667.0630100000000000',
      quoteVolume: 62964379.504462086,
      btcVolume: 6496.223467135922,
      usdVolume: 62964379.504462086,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '207.3300000000000000',
      high: '213.5200000000000000',
      low: '198.2400000000000000',
      close: '211.6200000000000000',
      volume: '214577.6842300000000000',
      quoteVolume: 44564653.222721346,
      btcVolume: 4706.346521404506,
      usdVolume: 44564653.222721346,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '211.5800000000000000',
      high: '215.8300000000000000',
      low: '206.5900000000000000',
      close: '210.8900000000000000',
      volume: '225967.7936000000000000',
      quoteVolume: 47645918.66213094,
      btcVolume: 4986.044256069211,
      usdVolume: 47645918.66213094,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '210.8400000000000000',
      high: '214.3600000000000000',
      low: '204.4000000000000000',
      close: '209.5800000000000000',
      volume: '175533.8397300000000000',
      quoteVolume: 36699059.76343268,
      btcVolume: 3850.9828761000917,
      usdVolume: 36698995.585413486,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '209.5700000000000000',
      high: '218.7900000000000000',
      low: '209.2000000000000000',
      close: '218.4200000000000000',
      volume: '207213.5565800000000000',
      quoteVolume: 44416634.90026903,
      btcVolume: 4513.526614405959,
      usdVolume: 44416634.90026903,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '218.4200000000000000',
      high: '219.3900000000000000',
      low: '210.5400000000000000',
      close: '216.8400000000000000',
      volume: '186806.1884400000000000',
      quoteVolume: 40054998.682404175,
      btcVolume: 3972.6309014935255,
      usdVolume: 40054998.682404175,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '216.8000000000000000',
      high: '222.1800000000000000',
      low: '214.3100000000000000',
      close: '217.6100000000000000',
      volume: '204158.9276400000000000',
      quoteVolume: 44530976.07793969,
      btcVolume: 4253.794355309355,
      usdVolume: 44530976.07793969,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '217.6900000000000000',
      high: '224.5100000000000000',
      low: '216.6200000000000000',
      close: '222.1400000000000000',
      volume: '181591.9529600000000000',
      quoteVolume: 40285734.53192182,
      btcVolume: 3743.4532071145845,
      usdVolume: 40285734.53192182,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '222.1400000000000000',
      high: '223.3400000000000000',
      low: '216.9000000000000000',
      close: '221.7900000000000000',
      volume: '134883.5495500000000000',
      quoteVolume: 29674383.960012592,
      btcVolume: 2749.4027954145995,
      usdVolume: 29674383.960012592,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '221.7900000000000000',
      high: '236.2500000000000000',
      low: '221.7900000000000000',
      close: '233.5400000000000000',
      volume: '307911.0830700000000000',
      quoteVolume: 70951607.64964774,
      btcVolume: 6104.385556472976,
      usdVolume: 70951607.64964774,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '233.5300000000000000',
      high: '239.1500000000000000',
      low: '223.0300000000000000',
      close: '226.2800000000000000',
      volume: '332632.0690500000000000',
      quoteVolume: 76612125.24778406,
      btcVolume: 6481.8262212509835,
      usdVolume: 76612125.24778406,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '226.3100000000000000',
      high: '231.2500000000000000',
      low: '220.9500000000000000',
      close: '226.1000000000000000',
      volume: '276075.3803400000000000',
      quoteVolume: 62453877.71585768,
      btcVolume: 5316.932199587181,
      usdVolume: 62453877.71585768,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '226.1100000000000000',
      high: '228.5000000000000000',
      low: '215.5100000000000000',
      close: '221.3800000000000000',
      volume: '235285.6142500000000000',
      quoteVolume: 52379480.13759483,
      btcVolume: 4440.469334714355,
      usdVolume: 52379480.13759483,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '221.3800000000000000',
      high: '221.7900000000000000',
      low: '207.3000000000000000',
      close: '210.5900000000000000',
      volume: '230958.0568000000000000',
      quoteVolume: 49180479.38156705,
      btcVolume: 4160.003984113228,
      usdVolume: 49180479.38156705,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '210.5600000000000000',
      high: '215.0000000000000000',
      low: '202.6000000000000000',
      close: '206.4800000000000000',
      volume: '248474.4130400000000000',
      quoteVolume: 51678057.27487786,
      btcVolume: 4460.255650817106,
      usdVolume: 51678057.27487786,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '206.4800000000000000',
      high: '216.9400000000000000',
      low: '206.1400000000000000',
      close: '216.5700000000000000',
      volume: '187625.9175000000000000',
      quoteVolume: 39691954.109307386,
      btcVolume: 3486.7880886797293,
      usdVolume: 39691954.109307386,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '216.5700000000000000',
      high: '216.8400000000000000',
      low: '209.7500000000000000',
      close: '211.5600000000000000',
      volume: '123598.5398700000000000',
      quoteVolume: 26272463.184408363,
      btcVolume: 2303.3073218777877,
      usdVolume: 26272463.184408363,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '211.4100000000000000',
      high: '214.3000000000000000',
      low: '204.0000000000000000',
      close: '209.3300000000000000',
      volume: '166922.4822300000000000',
      quoteVolume: 34861802.56011053,
      btcVolume: 3125.4337664048094,
      usdVolume: 34861802.56011053,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '209.3000000000000000',
      high: '209.9000000000000000',
      low: '183.5600000000000000',
      close: '187.0900000000000000',
      volume: '310158.0672200000000000',
      quoteVolume: 60710593.62070666,
      btcVolume: 5862.423952661254,
      usdVolume: 60710593.62070666,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '187.1000000000000000',
      high: '189.9500000000000000',
      low: '181.2300000000000000',
      close: '188.0300000000000000',
      volume: '237166.2993100000000000',
      quoteVolume: 44039710.624289505,
      btcVolume: 4342.288061699282,
      usdVolume: 44039710.624289505,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '187.9800000000000000',
      high: '188.3900000000000000',
      low: '178.0400000000000000',
      close: '184.8800000000000000',
      volume: '282176.9227900000000000',
      quoteVolume: 51945165.33384251,
      btcVolume: 5121.74136567943,
      usdVolume: 51945165.33384251,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '184.8300000000000000',
      high: '187.0000000000000000',
      low: '181.8300000000000000',
      close: '185.5900000000000000',
      volume: '138799.6150800000000000',
      quoteVolume: 25599796.904651288,
      btcVolume: 2487.8626142405346,
      usdVolume: 25599796.904651288,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '185.6700000000000000',
      high: '197.9100000000000000',
      low: '183.3500000000000000',
      close: '194.3300000000000000',
      volume: '175216.9309700000000000',
      quoteVolume: 33627585.449778885,
      btcVolume: 3252.016245410699,
      usdVolume: 33627585.449778885,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '194.3200000000000000',
      high: '203.5900000000000000',
      low: '192.7000000000000000',
      close: '202.2800000000000000',
      volume: '239158.0927100000000000',
      quoteVolume: 47599954.978613,
      btcVolume: 4479.389668521723,
      usdVolume: 47599954.978613,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '202.2400000000000000',
      high: '202.7500000000000000',
      low: '194.4500000000000000',
      close: '196.6000000000000000',
      volume: '189101.1123700000000000',
      quoteVolume: 37373990.242117286,
      btcVolume: 3483.295716474263,
      usdVolume: 37373990.242117286,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '196.5500000000000000',
      high: '197.2000000000000000',
      low: '179.5300000000000000',
      close: '187.4500000000000000',
      volume: '284348.0028800000000000',
      quoteVolume: 53317203.7731113,
      btcVolume: 5220.638258204045,
      usdVolume: 53317203.7731113,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '187.4500000000000000',
      high: '195.1400000000000000',
      low: '182.8000000000000000',
      close: '190.4000000000000000',
      volume: '245448.2435900000000000',
      quoteVolume: 46402513.17893997,
      btcVolume: 4613.9219820769085,
      usdVolume: 46402513.17893997,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '190.3500000000000000',
      high: '196.1900000000000000',
      low: '188.1600000000000000',
      close: '194.0200000000000000',
      volume: '171898.6596700000000000',
      quoteVolume: 33061516.57383751,
      btcVolume: 3230.660408468503,
      usdVolume: 33061516.57383751,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '194.0200000000000000',
      high: '194.0900000000000000',
      low: '185.6300000000000000',
      close: '190.6000000000000000',
      volume: '167806.3429400000000000',
      quoteVolume: 31786227.862663552,
      btcVolume: 3148.57127433382,
      usdVolume: 31786227.862663552,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '190.6000000000000000',
      high: '192.4000000000000000',
      low: '182.8000000000000000',
      close: '186.5400000000000000',
      volume: '168041.6810300000000000',
      quoteVolume: 31596795.659395352,
      btcVolume: 3133.1655657182055,
      usdVolume: 31596795.659395352,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '186.5400000000000000',
      high: '193.7000000000000000',
      low: '186.0000000000000000',
      close: '188.5900000000000000',
      volume: '248347.1284900000000000',
      quoteVolume: 47147744.7486281,
      btcVolume: 4546.553792072835,
      usdVolume: 47147744.7486281,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '188.6400000000000000',
      high: '189.4900000000000000',
      low: '184.7500000000000000',
      close: '187.2400000000000000',
      volume: '157901.7820000000000000',
      quoteVolume: 29513850.7927091,
      btcVolume: 2896.2658559704814,
      usdVolume: 29513850.7927091,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '187.3000000000000000',
      high: '188.2200000000000000',
      low: '166.4800000000000000',
      close: '173.0300000000000000',
      volume: '305449.4437100000000000',
      quoteVolume: 54488502.82976617,
      btcVolume: 5471.412145390252,
      usdVolume: 54488502.82976617,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '173.0300000000000000',
      high: '173.5000000000000000',
      low: '163.6100000000000000',
      close: '169.0100000000000000',
      volume: '295241.2160000000000000',
      quoteVolume: 49838097.166188926,
      btcVolume: 5238.717079717483,
      usdVolume: 49838097.166188926,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '169.0300000000000000',
      high: '170.7700000000000000',
      low: '165.5500000000000000',
      close: '168.5000000000000000',
      volume: '238511.3647300000000000',
      quoteVolume: 40199011.83567741,
      btcVolume: 4214.966705259767,
      usdVolume: 40199011.83567741,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '168.4800000000000000',
      high: '174.9800000000000000',
      low: '165.6300000000000000',
      close: '171.5700000000000000',
      volume: '194999.1958300000000000',
      quoteVolume: 32983642.84325837,
      btcVolume: 3439.4432364087634,
      usdVolume: 32983642.84325837,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '171.5200000000000000',
      high: '173.4200000000000000',
      low: '167.6100000000000000',
      close: '170.7400000000000000',
      volume: '191088.8305400000000000',
      quoteVolume: 32618691.233790867,
      btcVolume: 3391.76802121759,
      usdVolume: 32618691.233790867,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '170.7300000000000000',
      high: '181.0000000000000000',
      low: '170.0200000000000000',
      close: '178.0500000000000000',
      volume: '294320.5420600000000000',
      quoteVolume: 51392254.91867205,
      btcVolume: 5145.478077032096,
      usdVolume: 51392254.91867205,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '178.0000000000000000',
      high: '183.0000000000000000',
      low: '174.2800000000000000',
      close: '178.7500000000000000',
      volume: '325198.5117100000000000',
      quoteVolume: 58155903.15998513,
      btcVolume: 5528.606852780161,
      usdVolume: 58155903.15998513,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '178.7900000000000000',
      high: '180.1400000000000000',
      low: '173.0000000000000000',
      close: '174.7200000000000000',
      volume: '286198.2752900000000000',
      quoteVolume: 50624411.02937586,
      btcVolume: 4799.552094657055,
      usdVolume: 50624411.02937586,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '174.7000000000000000',
      high: '176.1900000000000000',
      low: '168.1000000000000000',
      close: '173.7500000000000000',
      volume: '231857.0212900000000000',
      quoteVolume: 40092220.48523467,
      btcVolume: 3798.553502855217,
      usdVolume: 40092220.48523467,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '173.7400000000000000',
      high: '177.8700000000000000',
      low: '165.0000000000000000',
      close: '169.0800000000000000',
      volume: '310365.3140900000000000',
      quoteVolume: 53620745.30355337,
      btcVolume: 5073.360788024353,
      usdVolume: 53620745.30355337,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '169.1100000000000000',
      high: '180.8000000000000000',
      low: '168.3000000000000000',
      close: '177.6200000000000000',
      volume: '251633.8069800000000000',
      quoteVolume: 43892369.55699689,
      btcVolume: 4209.284863585666,
      usdVolume: 43892369.55699689,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '177.5800000000000000',
      high: '184.1800000000000000',
      low: '176.3000000000000000',
      close: '181.1900000000000000',
      volume: '288720.1333900000000000',
      quoteVolume: 52178342.21652669,
      btcVolume: 4999.145556813438,
      usdVolume: 52178342.21652669,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '181.1800000000000000',
      high: '185.3800000000000000',
      low: '176.0100000000000000',
      close: '180.5400000000000000',
      volume: '270831.2111100000000000',
      quoteVolume: 48805236.52920722,
      btcVolume: 4741.73754696686,
      usdVolume: 48805236.52920722,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '180.5200000000000000',
      high: '184.3600000000000000',
      low: '177.0000000000000000',
      close: '179.8800000000000000',
      volume: '238217.8448800000000000',
      quoteVolume: 43037704.07242178,
      btcVolume: 4213.994957892011,
      usdVolume: 43037704.07242178,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '179.8700000000000000',
      high: '182.8000000000000000',
      low: '173.0000000000000000',
      close: '178.2800000000000000',
      volume: '278560.0964800000000000',
      quoteVolume: 49658310.75524305,
      btcVolume: 4937.86830579354,
      usdVolume: 49658310.75524305,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '178.3000000000000000',
      high: '182.3800000000000000',
      low: '176.6200000000000000',
      close: '180.7200000000000000',
      volume: '203335.8256500000000000',
      quoteVolume: 36383120.69543437,
      btcVolume: 3557.784215657219,
      usdVolume: 36383120.69543437,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '180.7100000000000000',
      high: '181.3800000000000000',
      low: '177.5400000000000000',
      close: '180.9500000000000000',
      volume: '264421.1936600000000000',
      quoteVolume: 47382185.41032426,
      btcVolume: 4605.8993130550225,
      usdVolume: 47382185.41032426,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '180.9600000000000000',
      high: '188.7900000000000000',
      low: '179.7500000000000000',
      close: '188.1300000000000000',
      volume: '279315.8876300000000000',
      quoteVolume: 51414430.17027494,
      btcVolume: 4974.16648427331,
      usdVolume: 51414430.17027494,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '188.1400000000000000',
      high: '190.4500000000000000',
      low: '185.7600000000000000',
      close: '189.0300000000000000',
      volume: '288928.6082700000000000',
      quoteVolume: 54373471.38522485,
      btcVolume: 5276.705425791319,
      usdVolume: 54373471.38522485,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '189.0500000000000000',
      high: '199.4400000000000000',
      low: '188.3000000000000000',
      close: '197.2300000000000000',
      volume: '549687.6874800000000000',
      quoteVolume: 106195854.54559186,
      btcVolume: 10379.571268151718,
      usdVolume: 106195854.54559186,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '197.2200000000000000',
      high: '215.1300000000000000',
      low: '195.7400000000000000',
      close: '207.8400000000000000',
      volume: '715858.9165100000000000',
      quoteVolume: 145944198.49593797,
      btcVolume: 14286.041939823335,
      usdVolume: 145944198.49593797,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '207.8500000000000000',
      high: '217.2700000000000000',
      low: '207.6600000000000000',
      close: '210.2100000000000000',
      volume: '539028.1435900000000000',
      quoteVolume: 114703029.02276355,
      btcVolume: 11256.168265878949,
      usdVolume: 114703029.02276355,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '210.2700000000000000',
      high: '223.9400000000000000',
      low: '202.3000000000000000',
      close: '220.2400000000000000',
      volume: '831524.9647500000000000',
      quoteVolume: 176952550.75094664,
      btcVolume: 17699.54227982766,
      usdVolume: 176952550.75094664,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '220.2600000000000000',
      high: '221.5400000000000000',
      low: '212.0500000000000000',
      close: '218.0300000000000000',
      volume: '437246.7696400000000000',
      quoteVolume: 94949393.91495149,
      btcVolume: 9332.199000692983,
      usdVolume: 94949393.91495149,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '218.0100000000000000',
      high: '221.5000000000000000',
      low: '213.2000000000000000',
      close: '215.0500000000000000',
      volume: '417891.5242000000000000',
      quoteVolume: 90638548.23529331,
      btcVolume: 9023.05159525662,
      usdVolume: 90638548.23529331,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '215.0400000000000000',
      high: '215.6100000000000000',
      low: '206.1000000000000000',
      close: '211.2000000000000000',
      volume: '445348.5123300000000000',
      quoteVolume: 93653967.98538981,
      btcVolume: 9389.012165487718,
      usdVolume: 93653967.98538981,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '211.2000000000000000',
      high: '211.6800000000000000',
      low: '198.6500000000000000',
      close: '201.2900000000000000',
      volume: '392320.7899200000000000',
      quoteVolume: 80899036.09704779,
      btcVolume: 8221.249859609254,
      usdVolume: 80899036.09704779,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '201.2500000000000000',
      high: '202.9800000000000000',
      low: '150.9700000000000000',
      close: '165.6900000000000000',
      volume: '1370149.8274300000000000',
      quoteVolume: 245718654.8582988,
      btcVolume: 27141.120316206176,
      usdVolume: 245718654.8582988,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '165.7400000000000000',
      high: '174.8500000000000000',
      low: '161.8800000000000000',
      close: '169.9700000000000000',
      volume: '878956.3378100000000000',
      quoteVolume: 147611978.20665354,
      btcVolume: 17503.743620916684,
      usdVolume: 147611978.20665354,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '169.9600000000000000',
      high: '171.0100000000000000',
      low: '152.1100000000000000',
      close: '165.9200000000000000',
      volume: '770883.6988700000000000',
      quoteVolume: 126485225.43295789,
      btcVolume: 15552.32826268824,
      usdVolume: 126485225.43295789,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '165.9200000000000000',
      high: '176.7200000000000000',
      low: '161.0300000000000000',
      close: '173.7900000000000000',
      volume: '634893.1348000000000000',
      quoteVolume: 106454562.85455583,
      btcVolume: 13228.604400340973,
      usdVolume: 106454522.86135663,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '173.8300000000000000',
      high: '175.4900000000000000',
      low: '168.0000000000000000',
      close: '173.4900000000000000',
      volume: '517943.1928800000000000',
      quoteVolume: 89460465.58471853,
      btcVolume: 11001.489635218039,
      usdVolume: 89460465.58471853,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '173.4900000000000000',
      high: '174.5000000000000000',
      low: '164.1200000000000000',
      close: '169.2400000000000000',
      volume: '410856.2773000000000000',
      quoteVolume: 69327426.95232227,
      btcVolume: 8623.060832254734,
      usdVolume: 69327426.95232227,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '169.2600000000000000',
      high: '181.2400000000000000',
      low: '165.0100000000000000',
      close: '180.8500000000000000',
      volume: '580295.3997000000000000',
      quoteVolume: 100443187.77168407,
      btcVolume: 12452.06118869834,
      usdVolume: 100443187.77168407,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '180.8900000000000000',
      high: '185.5300000000000000',
      low: '173.1900000000000000',
      close: '175.6600000000000000',
      volume: '609952.9387900000000000',
      quoteVolume: 109361456.4661927,
      btcVolume: 13111.359686678557,
      usdVolume: 109361456.4661927,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '175.6500000000000000',
      high: '181.2900000000000000',
      low: '173.6500000000000000',
      close: '180.2400000000000000',
      volume: '348268.1162000000000000',
      quoteVolume: 61530973.80423232,
      btcVolume: 7459.867727760892,
      usdVolume: 61530973.80423232,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '180.2400000000000000',
      high: '180.7200000000000000',
      low: '169.5500000000000000',
      close: '174.6900000000000000',
      volume: '354756.7847800000000000',
      quoteVolume: 62001237.48178279,
      btcVolume: 7563.155490555289,
      usdVolume: 62001237.48178279,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '174.7100000000000000',
      high: '178.9800000000000000',
      low: '170.7400000000000000',
      close: '175.5500000000000000',
      volume: '333817.1892000000000000',
      quoteVolume: 58456089.07824363,
      btcVolume: 7179.203250367646,
      usdVolume: 58456089.07824363,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '175.5500000000000000',
      high: '176.7100000000000000',
      low: '172.0200000000000000',
      close: '176.2500000000000000',
      volume: '278096.7557300000000000',
      quoteVolume: 48579546.419203795,
      btcVolume: 6005.859799986798,
      usdVolume: 48579546.419203795,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '176.2300000000000000',
      high: '177.0400000000000000',
      low: '167.6800000000000000',
      close: '170.1200000000000000',
      volume: '313627.4917500000000000',
      quoteVolume: 54020278.79604051,
      btcVolume: 6804.035467902695,
      usdVolume: 54020278.79604051,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '170.1000000000000000',
      high: '182.3200000000000000',
      low: '168.6800000000000000',
      close: '179.8800000000000000',
      volume: '496335.0680100000000000',
      quoteVolume: 87584450.44061345,
      btcVolume: 10846.12187727844,
      usdVolume: 87584450.44061345,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '179.8900000000000000',
      high: '184.8700000000000000',
      low: '177.0000000000000000',
      close: '180.6000000000000000',
      volume: '400833.1661700000000000',
      quoteVolume: 72432871.7087306,
      btcVolume: 8826.82276500747,
      usdVolume: 72432871.7087306,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '180.6100000000000000',
      high: '195.5300000000000000',
      low: '178.9600000000000000',
      close: '192.6200000000000000',
      volume: '557835.1119300000000000',
      quoteVolume: 104652037.76379311,
      btcVolume: 12510.317498902197,
      usdVolume: 104652037.76379311,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '192.6100000000000000',
      high: '194.2000000000000000',
      low: '186.8800000000000000',
      close: '191.1400000000000000',
      volume: '436576.1546100000000000',
      quoteVolume: 83365576.54034896,
      btcVolume: 9773.782550866496,
      usdVolume: 83365576.54034896,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '191.1400000000000000',
      high: '196.6500000000000000',
      low: '179.4100000000000000',
      close: '180.6500000000000000',
      volume: '621046.7695900000000000',
      quoteVolume: 115752733.32752092,
      btcVolume: 13750.640098528595,
      usdVolume: 115752733.32752092,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '180.6500000000000000',
      high: '184.6400000000000000',
      low: '177.5900000000000000',
      close: '179.6800000000000000',
      volume: '290372.9091400000000000',
      quoteVolume: 52887563.36652836,
      btcVolume: 6354.686685667993,
      usdVolume: 52887563.36652836,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '179.6500000000000000',
      high: '184.9500000000000000',
      low: '178.5200000000000000',
      close: '180.9900000000000000',
      volume: '247588.8460700000000000',
      quoteVolume: 45067270.51547066,
      btcVolume: 5399.218077546016,
      usdVolume: 45067270.51547066,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '181.0000000000000000',
      high: '187.5400000000000000',
      low: '180.4300000000000000',
      close: '186.7200000000000000',
      volume: '279731.4719000000000000',
      quoteVolume: 51494611.47577687,
      btcVolume: 6199.09079783479,
      usdVolume: 51494611.47577687,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '186.7100000000000000',
      high: '188.3700000000000000',
      low: '175.9600000000000000',
      close: '180.4900000000000000',
      volume: '400889.5286500000000000',
      quoteVolume: 73178688.57269453,
      btcVolume: 8861.147026864002,
      usdVolume: 73178688.57269453,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '180.4900000000000000',
      high: '181.4400000000000000',
      low: '171.8100000000000000',
      close: '174.4700000000000000',
      volume: '347333.7878200000000000',
      quoteVolume: 61105309.00180442,
      btcVolume: 7601.657333953499,
      usdVolume: 61105309.00180442,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '174.5200000000000000',
      high: '178.9600000000000000',
      low: '172.6100000000000000',
      close: '177.1600000000000000',
      volume: '298795.8198000000000000',
      quoteVolume: 52578052.94403298,
      btcVolume: 6542.154565971178,
      usdVolume: 52578052.94403298,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '177.1700000000000000',
      high: '177.4400000000000000',
      low: '168.6600000000000000',
      close: '172.7400000000000000',
      volume: '318571.4343500000000000',
      quoteVolume: 55106462.09562457,
      btcVolume: 6943.9535656951975,
      usdVolume: 55106462.09562457,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '172.7800000000000000',
      high: '174.9800000000000000',
      low: '169.4400000000000000',
      close: '171.8900000000000000',
      volume: '296902.3488500000000000',
      quoteVolume: 51266629.241353355,
      btcVolume: 6441.994366966404,
      usdVolume: 51266629.241353355,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '171.8600000000000000',
      high: '176.8800000000000000',
      low: '169.2100000000000000',
      close: '175.2200000000000000',
      volume: '299146.0715200000000000',
      quoteVolume: 51655109.15151142,
      btcVolume: 6436.934282521773,
      usdVolume: 51655109.15151142,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '175.1800000000000000',
      high: '177.9000000000000000',
      low: '171.5900000000000000',
      close: '173.9800000000000000',
      volume: '270573.1392900000000000',
      quoteVolume: 47198240.85169531,
      btcVolume: 5741.980152076821,
      usdVolume: 47198240.85169531,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '174.0000000000000000',
      high: '175.0400000000000000',
      low: '170.3000000000000000',
      close: '171.2400000000000000',
      volume: '255425.1915800000000000',
      quoteVolume: 44175399.86161367,
      btcVolume: 5395.723198048019,
      usdVolume: 44175399.86161367,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '171.2000000000000000',
      high: '171.4900000000000000',
      low: '153.4500000000000000',
      close: '162.3500000000000000',
      volume: '707323.9820400000000000',
      quoteVolume: 114779124.2515252,
      btcVolume: 14987.397322367471,
      usdVolume: 114779124.2515252,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '162.3500000000000000',
      high: '163.7200000000000000',
      low: '158.7200000000000000',
      close: '160.3800000000000000',
      volume: '386873.3559300000000000',
      quoteVolume: 62349254.66595083,
      btcVolume: 8383.711086675577,
      usdVolume: 62349254.66595083,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '160.3900000000000000',
      high: '187.7500000000000000',
      low: '160.2500000000000000',
      close: '181.5000000000000000',
      volume: '868260.5296600000000000',
      quoteVolume: 151040465.53858292,
      btcVolume: 18615.31944889506,
      usdVolume: 151040465.53858292,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '181.5300000000000000',
      high: '197.7400000000000000',
      low: '173.8000000000000000',
      close: '179.4900000000000000',
      volume: '1189930.8960700000000000',
      quoteVolume: 218719059.8162375,
      btcVolume: 23386.331259630017,
      usdVolume: 218719059.8162375,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '179.4200000000000000',
      high: '188.7000000000000000',
      low: '176.2200000000000000',
      close: '183.7500000000000000',
      volume: '723948.0442200000000000',
      quoteVolume: 132240902.2748802,
      btcVolume: 13994.80514249749,
      usdVolume: 132240902.2748802,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '183.8400000000000000',
      high: '189.4800000000000000',
      low: '180.3500000000000000',
      close: '181.7200000000000000',
      volume: '581705.0428700000000000',
      quoteVolume: 106847690.6990435,
      btcVolume: 11272.596969686028,
      usdVolume: 106847690.6990435,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '181.6700000000000000',
      high: '192.7400000000000000',
      low: '181.2600000000000000',
      close: '190.4600000000000000',
      volume: '529266.3083100000000000',
      quoteVolume: 98895301.78917634,
      btcVolume: 10554.02278596135,
      usdVolume: 98895301.78917634,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '190.4500000000000000',
      high: '191.7100000000000000',
      low: '179.2800000000000000',
      close: '183.1300000000000000',
      volume: '537770.4305600000000000',
      quoteVolume: 99508269.89031763,
      btcVolume: 10838.56631729719,
      usdVolume: 99508269.89031763,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '183.1400000000000000',
      high: '185.2700000000000000',
      low: '177.6600000000000000',
      close: '182.1800000000000000',
      volume: '410609.1639300000000000',
      quoteVolume: 74631751.23578401,
      btcVolume: 8159.802334886984,
      usdVolume: 74631751.23578401,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '182.1900000000000000',
      high: '184.5000000000000000',
      low: '177.0200000000000000',
      close: '182.8600000000000000',
      volume: '331518.2372300000000000',
      quoteVolume: 60092446.93983594,
      btcVolume: 6574.424243967809,
      usdVolume: 60092446.93983594,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '182.8500000000000000',
      high: '186.0000000000000000',
      low: '181.5300000000000000',
      close: '182.9100000000000000',
      volume: '179852.0178300000000000',
      quoteVolume: 32934405.78327263,
      btcVolume: 3551.462296933882,
      usdVolume: 32934405.78327263,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '182.9000000000000000',
      high: '184.7000000000000000',
      low: '178.9500000000000000',
      close: '181.5400000000000000',
      volume: '232349.8479600000000000',
      quoteVolume: 42211230.11712696,
      btcVolume: 4590.906257325065,
      usdVolume: 42211230.11712696,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '181.5300000000000000',
      high: '188.6400000000000000',
      low: '180.3600000000000000',
      close: '185.7200000000000000',
      volume: '318731.1727400000000000',
      quoteVolume: 58897915.884484924,
      btcVolume: 6352.353989298192,
      usdVolume: 58897915.884484924,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '185.7100000000000000',
      high: '192.5100000000000000',
      low: '182.2200000000000000',
      close: '188.6800000000000000',
      volume: '389421.1059000000000000',
      quoteVolume: 72988899.54730941,
      btcVolume: 7828.37934264765,
      usdVolume: 72988899.54730941,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '188.6500000000000000',
      high: '195.0900000000000000',
      low: '187.7200000000000000',
      close: '191.1600000000000000',
      volume: '343219.9224000000000000',
      quoteVolume: 65471595.20700819,
      btcVolume: 7012.585366600161,
      usdVolume: 65471595.20700819,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '191.1600000000000000',
      high: '192.2700000000000000',
      low: '184.5900000000000000',
      close: '186.6800000000000000',
      volume: '309882.0820600000000000',
      quoteVolume: 58150631.2768292,
      btcVolume: 6291.1791580124855,
      usdVolume: 58150631.2768292,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '186.6700000000000000',
      high: '188.2600000000000000',
      low: '181.4100000000000000',
      close: '183.7400000000000000',
      volume: '364493.9337200000000000',
      quoteVolume: 67207601.06199925,
      btcVolume: 7488.491062509954,
      usdVolume: 67207601.06199925,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '183.7100000000000000',
      high: '185.7900000000000000',
      low: '182.6300000000000000',
      close: '184.8900000000000000',
      volume: '192073.3804400000000000',
      quoteVolume: 35441967.76570041,
      btcVolume: 4019.998146716231,
      usdVolume: 35441967.76570041,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '184.8600000000000000',
      high: '191.5800000000000000',
      low: '183.3000000000000000',
      close: '188.9500000000000000',
      volume: '269941.5343600000000000',
      quoteVolume: 50699742.773780935,
      btcVolume: 5698.653252114131,
      usdVolume: 50699742.773780935,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '188.9600000000000000',
      high: '190.1900000000000000',
      low: '184.0600000000000000',
      close: '184.9800000000000000',
      volume: '254362.4843600000000000',
      quoteVolume: 47520753.42440565,
      btcVolume: 5398.5502773478665,
      usdVolume: 47520753.42440565,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '184.9800000000000000',
      high: '187.6500000000000000',
      low: '182.4100000000000000',
      close: '187.0900000000000000',
      volume: '256637.8790500000000000',
      quoteVolume: 47608963.337230064,
      btcVolume: 5449.8930699344155,
      usdVolume: 47608963.337230064,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '187.0900000000000000',
      high: '189.6600000000000000',
      low: '185.3000000000000000',
      close: '188.1100000000000000',
      volume: '197273.8400100000000000',
      quoteVolume: 36936429.935943484,
      btcVolume: 4212.216828428857,
      usdVolume: 36936429.935943484,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '188.0700000000000000',
      high: '188.7200000000000000',
      low: '183.3400000000000000',
      close: '184.9300000000000000',
      volume: '245478.1899700000000000',
      quoteVolume: 45518688.567261755,
      btcVolume: 5250.084494910773,
      usdVolume: 45518688.567261755,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '184.9200000000000000',
      high: '186.7000000000000000',
      low: '177.6700000000000000',
      close: '180.0000000000000000',
      volume: '404828.6103000000000000',
      quoteVolume: 73739540.13606241,
      btcVolume: 8600.798508976508,
      usdVolume: 73739540.13606241,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '179.9900000000000000',
      high: '183.4600000000000000',
      low: '179.3000000000000000',
      close: '182.3700000000000000',
      volume: '172801.5257600000000000',
      quoteVolume: 31378510.015389085,
      btcVolume: 3700.092412038125,
      usdVolume: 31378510.015389085,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '182.3700000000000000',
      high: '186.0900000000000000',
      low: '180.0600000000000000',
      close: '183.8200000000000000',
      volume: '198323.1213100000000000',
      quoteVolume: 36407476.06173793,
      btcVolume: 4273.182664311965,
      usdVolume: 36407476.06173793,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '183.8200000000000000',
      high: '184.0600000000000000',
      low: '175.0100000000000000',
      close: '178.2000000000000000',
      volume: '292916.4108000000000000',
      quoteVolume: 52873504.41217531,
      btcVolume: 6334.069797912126,
      usdVolume: 52873504.41217531,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '178.2000000000000000',
      high: '178.5200000000000000',
      low: '172.6500000000000000',
      close: '175.9400000000000000',
      volume: '275886.6411000000000000',
      quoteVolume: 48414106.956933685,
      btcVolume: 5968.5359977672715,
      usdVolume: 48414106.956933685,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '175.9300000000000000',
      high: '177.4100000000000000',
      low: '173.5000000000000000',
      close: '174.7200000000000000',
      volume: '216315.9330900000000000',
      quoteVolume: 38001672.19309863,
      btcVolume: 4686.270009046089,
      usdVolume: 38001672.19309863,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '174.7500000000000000',
      high: '175.8500000000000000',
      low: '157.2600000000000000',
      close: '161.0100000000000000',
      volume: '470224.9285800000000000',
      quoteVolume: 78033991.15986177,
      btcVolume: 10011.92260591679,
      usdVolume: 78033991.15986177,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '161.0200000000000000',
      high: '162.7900000000000000',
      low: '138.0000000000000000',
      close: '149.5600000000000000',
      volume: '975937.0667600000000000',
      quoteVolume: 147306766.45684162,
      btcVolume: 20315.079048812546,
      usdVolume: 147306766.45684162,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '149.5500000000000000',
      high: '154.3300000000000000',
      low: '146.1100000000000000',
      close: '151.8600000000000000',
      volume: '369720.2906000000000000',
      quoteVolume: 55595034.725483,
      btcVolume: 7691.525331812389,
      usdVolume: 55595034.725483,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '151.8400000000000000',
      high: '152.8600000000000000',
      low: '138.6200000000000000',
      close: '139.9900000000000000',
      volume: '352318.5460300000000000',
      quoteVolume: 51503621.078441106,
      btcVolume: 7270.627960676268,
      usdVolume: 51503621.078441106,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '139.9600000000000000',
      high: '151.5000000000000000',
      low: '131.4500000000000000',
      close: '145.6900000000000000',
      volume: '749381.0582700000000000',
      quoteVolume: 106143909.57189968,
      btcVolume: 15339.430749701922,
      usdVolume: 106143909.57189968,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '145.8100000000000000',
      high: '149.9700000000000000',
      low: '143.3700000000000000',
      close: '147.4700000000000000',
      volume: '354023.0429800000000000',
      quoteVolume: 51873405.03929025,
      btcVolume: 7258.985728654345,
      usdVolume: 51873405.03929025,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '147.4700000000000000',
      high: '155.5400000000000000',
      low: '140.8400000000000000',
      close: '152.6200000000000000',
      volume: '563679.1596700000000000',
      quoteVolume: 83338641.04602854,
      btcVolume: 11568.893523095534,
      usdVolume: 83338641.04602854,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '152.6100000000000000',
      high: '154.6300000000000000',
      low: '149.0900000000000000',
      close: '150.7200000000000000',
      volume: '317714.1197600000000000',
      quoteVolume: 48324153.77624194,
      btcVolume: 6441.974596553162,
      usdVolume: 48324153.77624194,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '150.7200000000000000',
      high: '157.6000000000000000',
      low: '150.2300000000000000',
      close: '154.5000000000000000',
      volume: '328513.9167000000000000',
      quoteVolume: 50618447.52032263,
      btcVolume: 6641.291265743082,
      usdVolume: 50618447.52032263,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '154.5500000000000000',
      high: '155.2500000000000000',
      low: '149.7000000000000000',
      close: '151.4000000000000000',
      volume: '226862.4671000000000000',
      quoteVolume: 34617789.5827378,
      btcVolume: 4534.817039770797,
      usdVolume: 34617789.5827378,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '151.4300000000000000',
      high: '152.4900000000000000',
      low: '145.7900000000000000',
      close: '150.7300000000000000',
      volume: '344185.5408400000000000',
      quoteVolume: 51149912.23894076,
      btcVolume: 6959.7035482148,
      usdVolume: 51149912.23894076,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '150.7200000000000000',
      high: '151.4200000000000000',
      low: '146.7100000000000000',
      close: '148.6500000000000000',
      volume: '233774.3063000000000000',
      quoteVolume: 34759015.80900913,
      btcVolume: 4765.617361975778,
      usdVolume: 34759015.80900913,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '148.6600000000000000',
      high: '149.9300000000000000',
      low: '145.7700000000000000',
      close: '147.1700000000000000',
      volume: '196329.2250300000000000',
      quoteVolume: 29061161.101775736,
      btcVolume: 3976.947714942431,
      usdVolume: 29061161.101775736,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '147.1900000000000000',
      high: '151.0000000000000000',
      low: '143.1500000000000000',
      close: '145.3800000000000000',
      volume: '418523.8529900000000000',
      quoteVolume: 61465918.00020749,
      btcVolume: 8421.53839803797,
      usdVolume: 61465918.00020749,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '145.4500000000000000',
      high: '149.0200000000000000',
      low: '143.6400000000000000',
      close: '148.1000000000000000',
      volume: '298773.7016400000000000',
      quoteVolume: 43733249.10134732,
      btcVolume: 5975.972289154188,
      usdVolume: 43733249.10134732,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '148.1100000000000000',
      high: '149.7700000000000000',
      low: '145.7400000000000000',
      close: '148.4500000000000000',
      volume: '219804.5782100000000000',
      quoteVolume: 32420380.085808042,
      btcVolume: 4379.421398292797,
      usdVolume: 32420380.085808042,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '148.4600000000000000',
      high: '149.4900000000000000',
      low: '146.8500000000000000',
      close: '147.1400000000000000',
      volume: '140471.6858800000000000',
      quoteVolume: 20799551.345695384,
      btcVolume: 2765.513206109344,
      usdVolume: 20799551.345695384,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '147.1600000000000000',
      high: '151.6200000000000000',
      low: '146.1100000000000000',
      close: '150.4300000000000000',
      volume: '205292.3260400000000000',
      quoteVolume: 30604893.80851998,
      btcVolume: 4083.993478963329,
      usdVolume: 30604893.80851998,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '150.4300000000000000',
      high: '151.1800000000000000',
      low: '146.5600000000000000',
      close: '147.4100000000000000',
      volume: '241824.4918400000000000',
      quoteVolume: 36054914.410974964,
      btcVolume: 4830.038571314736,
      usdVolume: 36054914.410974964,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '147.3900000000000000',
      high: '148.5700000000000000',
      low: '143.8100000000000000',
      close: '145.5600000000000000',
      volume: '202345.9339500000000000',
      quoteVolume: 29619185.42152988,
      btcVolume: 4056.038127495942,
      usdVolume: 29619185.42152988,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '145.5300000000000000',
      high: '146.3400000000000000',
      low: '142.1200000000000000',
      close: '143.3900000000000000',
      volume: '157843.1048400000000000',
      quoteVolume: 22772651.168129046,
      btcVolume: 3157.805594325894,
      usdVolume: 22772651.168129046,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '143.4100000000000000',
      high: '145.8500000000000000',
      low: '139.2400000000000000',
      close: '144.8700000000000000',
      volume: '261614.3454600000000000',
      quoteVolume: 37425141.739041,
      btcVolume: 5217.446754533626,
      usdVolume: 37425141.739041,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '144.8700000000000000',
      high: '146.0000000000000000',
      low: '142.8000000000000000',
      close: '144.8000000000000000',
      volume: '160597.0306700000000000',
      quoteVolume: 23189253.309127137,
      btcVolume: 3205.056989013503,
      usdVolume: 23189253.309127137,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '144.8000000000000000',
      high: '145.0700000000000000',
      low: '141.1800000000000000',
      close: '141.7900000000000000',
      volume: '126232.5920100000000000',
      quoteVolume: 18027581.359546103,
      btcVolume: 2521.1961065175824,
      usdVolume: 18027581.359546103,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '141.7900000000000000',
      high: '144.1200000000000000',
      low: '139.9200000000000000',
      close: '142.4600000000000000',
      volume: '151189.6587700000000000',
      quoteVolume: 21495196.81888501,
      btcVolume: 3029.229605400445,
      usdVolume: 21495196.81888501,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '142.4600000000000000',
      high: '142.7200000000000000',
      low: '127.9300000000000000',
      close: '132.7300000000000000',
      volume: '450883.4902900000000000',
      quoteVolume: 60935923.41354128,
      btcVolume: 8739.56219158376,
      usdVolume: 60935923.41354128,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '132.7200000000000000',
      high: '132.9800000000000000',
      low: '119.1100000000000000',
      close: '121.8800000000000000',
      volume: '554842.5818300000000000',
      quoteVolume: 70638378.5959894,
      btcVolume: 10478.106986160494,
      usdVolume: 70638378.5959894,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '121.8800000000000000',
      high: '134.8700000000000000',
      low: '116.2600000000000000',
      close: '132.7800000000000000',
      volume: '884910.2066800000000000',
      quoteVolume: 110560097.03269246,
      btcVolume: 16307.41864801072,
      usdVolume: 110560097.03269246,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '132.8000000000000000',
      high: '134.0000000000000000',
      low: '125.6900000000000000',
      close: '128.1000000000000000',
      volume: '420674.8172000000000000',
      quoteVolume: 53766864.18518815,
      btcVolume: 7512.382660233176,
      usdVolume: 53766864.18518815,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '128.1000000000000000',
      high: '129.3900000000000000',
      low: '125.8400000000000000',
      close: '128.1900000000000000',
      volume: '213897.4673000000000000',
      quoteVolume: 27266605.48486431,
      btcVolume: 3812.8979207455554,
      usdVolume: 27266605.48486431,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '128.1900000000000000',
      high: '128.4000000000000000',
      low: '126.5000000000000000',
      close: '126.9900000000000000',
      volume: '135196.1164100000000000',
      quoteVolume: 17199278.79468431,
      btcVolume: 2407.517101337839,
      usdVolume: 17199278.79468431,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '127.0000000000000000',
      high: '133.0700000000000000',
      low: '126.8200000000000000',
      close: '132.0900000000000000',
      volume: '252349.1950800000000000',
      quoteVolume: 32837658.598232217,
      btcVolume: 4517.366310910823,
      usdVolume: 32837658.598232217,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '132.1200000000000000',
      high: '135.1000000000000000',
      low: '126.0000000000000000',
      close: '127.8000000000000000',
      volume: '421600.7565500000000000',
      quoteVolume: 55501305.92012195,
      btcVolume: 7393.0923029749465,
      usdVolume: 55501305.92012195,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '127.8000000000000000',
      high: '129.6900000000000000',
      low: '126.6100000000000000',
      close: '127.7100000000000000',
      volume: '200423.4802800000000000',
      quoteVolume: 25640395.796429265,
      btcVolume: 3511.8402799722517,
      usdVolume: 25640395.796429265,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '127.7000000000000000',
      high: '127.8400000000000000',
      low: '123.0700000000000000',
      close: '125.0900000000000000',
      volume: '224856.9553900000000000',
      quoteVolume: 28126021.072964624,
      btcVolume: 3897.9462094468076,
      usdVolume: 28126021.072964624,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '125.0900000000000000',
      high: '132.2600000000000000',
      low: '124.3200000000000000',
      close: '131.1800000000000000',
      volume: '271991.3960900000000000',
      quoteVolume: 34556187.22649918,
      btcVolume: 4757.447100368064,
      usdVolume: 34556187.22649918,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '125.5800000000000000',
      high: '127.1000000000000000',
      low: '121.9100000000000000',
      close: '126.2900000000000000',
      volume: '239584.0186300000000000',
      quoteVolume: 29967207.36584079,
      btcVolume: 4162.919074511555,
      usdVolume: 29967207.36584079,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '126.2800000000000000',
      high: '129.6800000000000000',
      low: '125.8400000000000000',
      close: '128.1100000000000000',
      volume: '196893.5227700000000000',
      quoteVolume: 25177121.147064537,
      btcVolume: 3441.3052732034603,
      usdVolume: 25177121.147064537,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '128.1100000000000000',
      high: '138.0700000000000000',
      low: '127.5200000000000000',
      close: '134.3600000000000000',
      volume: '316304.0786500000000000',
      quoteVolume: 41887251.75431606,
      btcVolume: 5666.2955116504645,
      usdVolume: 41887251.75431606,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '134.3600000000000000',
      high: '136.2400000000000000',
      low: '130.3000000000000000',
      close: '131.5900000000000000',
      volume: '320328.9778500000000000',
      quoteVolume: 42634148.31351888,
      btcVolume: 5822.900412418773,
      usdVolume: 42634148.31351888,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '131.6100000000000000',
      high: '133.6800000000000000',
      low: '128.1700000000000000',
      close: '129.1600000000000000',
      volume: '264902.2166100000000000',
      quoteVolume: 34674502.08294672,
      btcVolume: 4798.822256382279,
      usdVolume: 34674502.08294672,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '129.1600000000000000',
      high: '133.0500000000000000',
      low: '128.6800000000000000',
      close: '130.7700000000000000',
      volume: '144766.0171600000000000',
      quoteVolume: 18951727.853203688,
      btcVolume: 2626.058687621114,
      usdVolume: 18951727.853203688,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '130.7200000000000000',
      high: '130.7800000000000000',
      low: '126.3800000000000000',
      close: '127.1900000000000000',
      volume: '206486.6936400000000000',
      quoteVolume: 26562586.64793831,
      btcVolume: 3748.1292562505037,
      usdVolume: 26562586.64793831,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '127.1900000000000000',
      high: '135.1400000000000000',
      low: '125.8800000000000000',
      close: '134.3500000000000000',
      volume: '412941.3612600000000000',
      quoteVolume: 54124562.00994656,
      btcVolume: 7477.56431390865,
      usdVolume: 54124562.00994656,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '134.3700000000000000',
      high: '135.8500000000000000',
      low: '132.5000000000000000',
      close: '134.2000000000000000',
      volume: '184276.1710200000000000',
      quoteVolume: 24641135.568952836,
      btcVolume: 3361.8386052854335,
      usdVolume: 24641135.568952836,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '134.2000000000000000',
      high: '138.1900000000000000',
      low: '134.1900000000000000',
      close: '135.3700000000000000',
      volume: '254120.4534300000000000',
      quoteVolume: 34593687.60327518,
      btcVolume: 4653.251278572446,
      usdVolume: 34593687.60327518,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '135.3700000000000000',
      high: '144.4100000000000000',
      low: '134.8600000000000000',
      close: '144.1500000000000000',
      volume: '408020.2737500000000000',
      quoteVolume: 57400544.487255365,
      btcVolume: 7600.492701267486,
      usdVolume: 57400544.487255365,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '144.1400000000000000',
      high: '145.3100000000000000',
      low: '138.7600000000000000',
      close: '142.8000000000000000',
      volume: '447762.1728100000000000',
      quoteVolume: 63861666.4047595,
      btcVolume: 8049.865532289283,
      usdVolume: 63861666.4047595,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '142.8000000000000000',
      high: '147.7700000000000000',
      low: '137.0300000000000000',
      close: '140.7200000000000000',
      volume: '561419.2059200000000000',
      quoteVolume: 79913004.38781014,
      btcVolume: 9694.962625535327,
      usdVolume: 79913004.38781014,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '140.7600000000000000',
      high: '141.5000000000000000',
      low: '135.3000000000000000',
      close: '137.7400000000000000',
      volume: '363987.6637700000000000',
      quoteVolume: 50319731.13866686,
      btcVolume: 6374.095494904485,
      usdVolume: 50319731.13866686,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '137.7300000000000000',
      high: '145.1700000000000000',
      low: '135.3200000000000000',
      close: '144.8400000000000000',
      volume: '409400.9174100000000000',
      quoteVolume: 57377160.97009285,
      btcVolume: 7232.247618277078,
      usdVolume: 57377160.97009285,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '144.8300000000000000',
      high: '148.0500000000000000',
      low: '142.0900000000000000',
      close: '142.3800000000000000',
      volume: '368350.5793900000000000',
      quoteVolume: 53149360.56174742,
      btcVolume: 6540.115802381793,
      usdVolume: 53149360.56174742,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '142.4000000000000000',
      high: '146.6000000000000000',
      low: '141.7600000000000000',
      close: '146.5400000000000000',
      volume: '229541.8613700000000000',
      quoteVolume: 33112850.822234996,
      btcVolume: 4081.84868926777,
      usdVolume: 33112850.822234996,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '146.5600000000000000',
      high: '147.0000000000000000',
      low: '142.2700000000000000',
      close: '143.5800000000000000',
      volume: '207971.5884600000000000',
      quoteVolume: 29884467.67111847,
      btcVolume: 3683.879733305947,
      usdVolume: 29884467.67111847,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '143.5800000000000000',
      high: '171.7000000000000000',
      low: '143.5100000000000000',
      close: '165.6400000000000000',
      volume: '1108476.3118600000000000',
      quoteVolume: 173492006.24820682,
      btcVolume: 20115.096219225106,
      usdVolume: 173492006.24820682,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '165.6000000000000000',
      high: '171.9800000000000000',
      low: '159.2000000000000000',
      close: '166.4000000000000000',
      volume: '721560.7995600000000000',
      quoteVolume: 119219193.04167666,
      btcVolume: 13597.93692389315,
      usdVolume: 119219193.04167666,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '166.4000000000000000',
      high: '167.4000000000000000',
      low: '157.8000000000000000',
      close: '164.2100000000000000',
      volume: '456170.8671900000000000',
      quoteVolume: 74038650.82913166,
      btcVolume: 8518.447924957838,
      usdVolume: 74038650.82913166,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '164.2400000000000000',
      high: '174.8100000000000000',
      low: '162.1400000000000000',
      close: '169.8500000000000000',
      volume: '767180.6785300000000000',
      quoteVolume: 129909265.25615731,
      btcVolume: 14632.362690520036,
      usdVolume: 129909265.25615731,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '169.9200000000000000',
      high: '179.5000000000000000',
      low: '164.9200000000000000',
      close: '174.1400000000000000',
      volume: '688748.6367600000000000',
      quoteVolume: 119382479.69063364,
      btcVolume: 13404.09413255703,
      usdVolume: 119382479.69063364,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '174.1000000000000000',
      high: '178.0500000000000000',
      low: '161.6600000000000000',
      close: '166.7900000000000000',
      volume: '624670.6336700000000000',
      quoteVolume: 105689492.0033365,
      btcVolume: 12002.351244357744,
      usdVolume: 105689492.0033365,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '166.7900000000000000',
      high: '169.3300000000000000',
      low: '161.2400000000000000',
      close: '166.8700000000000000',
      volume: '358092.8841000000000000',
      quoteVolume: 59292534.71746608,
      btcVolume: 6852.199304969695,
      usdVolume: 59292534.71746608,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '166.8600000000000000',
      high: '170.3200000000000000',
      low: '164.8000000000000000',
      close: '169.4900000000000000',
      volume: '308007.6353000000000000',
      quoteVolume: 51615232.56698999,
      btcVolume: 5961.4179039502005,
      usdVolume: 51615232.56698999,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '169.4800000000000000',
      high: '171.4700000000000000',
      low: '166.0300000000000000',
      close: '168.0700000000000000',
      volume: '272240.9028600000000000',
      quoteVolume: 45903134.16291278,
      btcVolume: 5277.281438195617,
      usdVolume: 45903134.16291278,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '168.0700000000000000',
      high: '168.2000000000000000',
      low: '159.2100000000000000',
      close: '162.8100000000000000',
      volume: '373414.3498500000000000',
      quoteVolume: 60971659.77458279,
      btcVolume: 7198.282444848286,
      usdVolume: 60971659.77458279,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '162.8500000000000000',
      high: '164.4500000000000000',
      low: '155.5500000000000000',
      close: '162.5400000000000000',
      volume: '430013.1990200000000000',
      quoteVolume: 68980724.06049524,
      btcVolume: 8218.743302588302,
      usdVolume: 68980724.06049524,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '162.5100000000000000',
      high: '162.7900000000000000',
      low: '157.6100000000000000',
      close: '160.3500000000000000',
      volume: '219921.6519700000000000',
      quoteVolume: 35196608.30633701,
      btcVolume: 4216.84914612778,
      usdVolume: 35196608.30633701,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '160.3600000000000000',
      high: '168.0800000000000000',
      low: '159.4100000000000000',
      close: '167.8600000000000000',
      volume: '251582.5575800000000000',
      quoteVolume: 41239031.33790531,
      btcVolume: 4864.643562453211,
      usdVolume: 41239031.33790531,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '167.9100000000000000',
      high: '172.5600000000000000',
      low: '165.2200000000000000',
      close: '170.0800000000000000',
      volume: '365894.8191700000000000',
      quoteVolume: 61846624.61453907,
      btcVolume: 7067.9493571795865,
      usdVolume: 61846624.61453907,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '170.0400000000000000',
      high: '176.2000000000000000',
      low: '170.0300000000000000',
      close: '175.6400000000000000',
      volume: '473433.8960900000000000',
      quoteVolume: 81649497.49294704,
      btcVolume: 9000.09542777262,
      usdVolume: 81649497.49294704,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '175.5800000000000000',
      high: '178.4500000000000000',
      low: '173.3300000000000000',
      close: '173.7200000000000000',
      volume: '317366.2058000000000000',
      quoteVolume: 55985259.09291722,
      btcVolume: 5993.288480446213,
      usdVolume: 55985259.09291722,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '173.6800000000000000',
      high: '187.0000000000000000',
      low: '170.9300000000000000',
      close: '184.6900000000000000',
      volume: '477721.6609000000000000',
      quoteVolume: 85164074.08231418,
      btcVolume: 9048.137874415446,
      usdVolume: 85164074.08231418,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '184.7100000000000000',
      high: '185.8200000000000000',
      low: '175.2200000000000000',
      close: '179.9900000000000000',
      volume: '385596.5336500000000000',
      quoteVolume: 69633052.28263958,
      btcVolume: 7449.645096830591,
      usdVolume: 69633052.28263958,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '179.9400000000000000',
      high: '184.2800000000000000',
      low: '179.2300000000000000',
      close: '183.6000000000000000',
      volume: '259370.1290200000000000',
      quoteVolume: 47263178.06678158,
      btcVolume: 5035.1164574204295,
      usdVolume: 47263178.06678158,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '183.6300000000000000',
      high: '193.4300000000000000',
      low: '179.1000000000000000',
      close: '188.4400000000000000',
      volume: '552621.1361900000000000',
      quoteVolume: 104105613.55878504,
      btcVolume: 11088.57195048023,
      usdVolume: 104105613.55878504,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '188.4800000000000000',
      high: '195.1900000000000000',
      low: '186.6200000000000000',
      close: '189.6900000000000000',
      volume: '417175.9578100000000000',
      quoteVolume: 79336312.5246926,
      btcVolume: 8471.442558236018,
      usdVolume: 79336312.5246926,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '189.7400000000000000',
      high: '191.6000000000000000',
      low: '184.6900000000000000',
      close: '188.9100000000000000',
      volume: '366267.8454800000000000',
      quoteVolume: 68697714.9065747,
      btcVolume: 7449.885768860561,
      usdVolume: 68697714.9065747,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '188.9100000000000000',
      high: '207.6100000000000000',
      low: '188.1900000000000000',
      close: '203.7800000000000000',
      volume: '550942.1141700000000000',
      quoteVolume: 108659358.53591804,
      btcVolume: 11498.225576271325,
      usdVolume: 108659358.53591804,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '203.7800000000000000',
      high: '216.3300000000000000',
      low: '201.0200000000000000',
      close: '213.1900000000000000',
      volume: '602040.0810800000000000',
      quoteVolume: 126577112.92357521,
      btcVolume: 13029.657093734239,
      usdVolume: 126577112.92357521,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '213.1600000000000000',
      high: '225.0000000000000000',
      low: '213.1400000000000000',
      close: '223.3300000000000000',
      volume: '628858.0077800000000000',
      quoteVolume: 138414055.77483606,
      btcVolume: 14123.858774287371,
      usdVolume: 138414055.77483606,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '223.3600000000000000',
      high: '227.7500000000000000',
      low: '213.2200000000000000',
      close: '223.0500000000000000',
      volume: '548551.8746500000000000',
      quoteVolume: 122069507.65510991,
      btcVolume: 12459.442096719877,
      usdVolume: 122069507.65510991,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '223.0100000000000000',
      high: '230.6500000000000000',
      low: '222.8600000000000000',
      close: '228.4900000000000000',
      volume: '350221.2019800000000000',
      quoteVolume: 79648275.7579095,
      btcVolume: 7906.719601131233,
      usdVolume: 79648275.7579095,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '228.5300000000000000',
      high: '229.4000000000000000',
      low: '216.3700000000000000',
      close: '222.8900000000000000',
      volume: '510415.4994900000000000',
      quoteVolume: 113385288.95029798,
      btcVolume: 11438.000262952832,
      usdVolume: 113385288.95029798,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '222.8900000000000000',
      high: '239.1500000000000000',
      low: '218.1700000000000000',
      close: '236.6900000000000000',
      volume: '595576.9027600000000000',
      quoteVolume: 135863928.62430608,
      btcVolume: 13545.116057370578,
      usdVolume: 135863928.62430608,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '236.6900000000000000',
      high: '275.3400000000000000',
      low: '236.6900000000000000',
      close: '265.7400000000000000',
      volume: '1038073.7478200000000000',
      quoteVolume: 266734296.55029422,
      btcVolume: 25827.16221092207,
      usdVolume: 266734296.55029422,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '265.7400000000000000',
      high: '277.6900000000000000',
      low: '256.0800000000000000',
      close: '268.3200000000000000',
      volume: '1083763.8066500000000000',
      quoteVolume: 289993412.75714827,
      btcVolume: 28204.52124006715,
      usdVolume: 289993412.75714827,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '268.3400000000000000',
      high: '287.1500000000000000',
      low: '260.2800000000000000',
      close: '285.1500000000000000',
      volume: '734944.3226600000000000',
      quoteVolume: 201120424.58321652,
      btcVolume: 19585.77364231225,
      usdVolume: 201120424.58321652,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '285.1100000000000000',
      high: '288.4100000000000000',
      low: '261.8600000000000000',
      close: '264.8800000000000000',
      volume: '860767.3711600000000000',
      quoteVolume: 236274810.63895243,
      btcVolume: 23452.950884857593,
      usdVolume: 236274810.63895243,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '264.9100000000000000',
      high: '274.0000000000000000',
      low: '237.4100000000000000',
      close: '258.8500000000000000',
      volume: '1110118.4639500000000000',
      quoteVolume: 286269166.28143066,
      btcVolume: 29048.30557248655,
      usdVolume: 286269166.28143066,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '258.8900000000000000',
      high: '268.7700000000000000',
      low: '242.0000000000000000',
      close: '267.8500000000000000',
      volume: '1110371.3909400000000000',
      quoteVolume: 280371301.7549912,
      btcVolume: 28929.875955319196,
      usdVolume: 280371301.7549912,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '267.9000000000000000',
      high: '285.8800000000000000',
      low: '258.0000000000000000',
      close: '282.6100000000000000',
      volume: '1115523.4399200000000000',
      quoteVolume: 304162263.3061434,
      btcVolume: 30935.19354943302,
      usdVolume: 304162263.3061434,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '282.6400000000000000',
      high: '285.0000000000000000',
      low: '251.5600000000000000',
      close: '258.4500000000000000',
      volume: '702700.0948200000000000',
      quoteVolume: 190968563.2271233,
      btcVolume: 19191.632547085086,
      usdVolume: 190968563.2271233,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '258.4400000000000000',
      high: '264.3300000000000000',
      low: '245.3400000000000000',
      close: '256.9600000000000000',
      volume: '972629.9034800000000000',
      quoteVolume: 249295948.92492777,
      btcVolume: 26026.85890519547,
      usdVolume: 249295948.92492777,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '256.9700000000000000',
      high: '268.2400000000000000',
      low: '253.6100000000000000',
      close: '265.2700000000000000',
      volume: '524737.1325800000000000',
      quoteVolume: 137561519.8080567,
      btcVolume: 14215.99915940384,
      usdVolume: 137561519.8080567,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '265.3200000000000000',
      high: '266.8100000000000000',
      low: '256.0000000000000000',
      close: '261.5700000000000000',
      volume: '313062.5213300000000000',
      quoteVolume: 81781388.31935476,
      btcVolume: 8483.868403005552,
      usdVolume: 81781388.31935476,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '261.5500000000000000',
      high: '275.6800000000000000',
      low: '261.0200000000000000',
      close: '274.4800000000000000',
      volume: '444740.8288300000000000',
      quoteVolume: 120024058.97196795,
      btcVolume: 12171.335680224975,
      usdVolume: 120024058.97196795,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '274.5000000000000000',
      high: '277.2000000000000000',
      low: '257.0900000000000000',
      close: '265.5200000000000000',
      volume: '696591.7298300000000000',
      quoteVolume: 185949182.70858055,
      btcVolume: 19137.450555293988,
      usdVolume: 185949182.70858055,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '265.4700000000000000',
      high: '266.2200000000000000',
      low: '244.4400000000000000',
      close: '246.6700000000000000',
      volume: '774791.0102700000000000',
      quoteVolume: 197442911.5447298,
      btcVolume: 20862.28626803828,
      usdVolume: 197442911.5447298,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '246.6700000000000000',
      high: '250.3200000000000000',
      low: '215.6600000000000000',
      close: '223.9300000000000000',
      volume: '1390938.8858200000000000',
      quoteVolume: 323053459.4097742,
      btcVolume: 35807.633154278985,
      usdVolume: 323053459.4097742,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '223.9800000000000000',
      high: '238.3000000000000000',
      low: '210.0000000000000000',
      close: '227.7900000000000000',
      volume: '1273621.5787700000000000',
      quoteVolume: 287283849.70437545,
      btcVolume: 32695.90481354741,
      usdVolume: 287283849.70437545,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '227.7300000000000000',
      high: '234.6700000000000000',
      low: '214.0100000000000000',
      close: '226.7600000000000000',
      volume: '1054994.9239700000000000',
      quoteVolume: 236064804.33459535,
      btcVolume: 27236.336241981913,
      usdVolume: 236064804.33459535,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '226.7600000000000000',
      high: '233.0000000000000000',
      low: '217.0000000000000000',
      close: '217.2100000000000000',
      volume: '546866.6851000000000000',
      quoteVolume: 123635801.01782297,
      btcVolume: 14249.24078788142,
      usdVolume: 123635801.01782297,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '217.2900000000000000',
      high: '227.8900000000000000',
      low: '212.3600000000000000',
      close: '217.8100000000000000',
      volume: '715016.0194100000000000',
      quoteVolume: 157064953.84227648,
      btcVolume: 18346.386497742988,
      usdVolume: 157064953.84227648,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '217.8100000000000000',
      high: '234.4000000000000000',
      low: '216.0700000000000000',
      close: '231.9700000000000000',
      volume: '810051.4833000000000000',
      quoteVolume: 182948651.67645854,
      btcVolume: 20891.507673847358,
      usdVolume: 182948651.67645854,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '232.1000000000000000',
      high: '232.4600000000000000',
      low: '219.5700000000000000',
      close: '223.9100000000000000',
      volume: '741498.5482500000000000',
      quoteVolume: 168090325.5387952,
      btcVolume: 19140.228688685915,
      usdVolume: 168090325.5387952,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '223.8400000000000000',
      high: '228.8500000000000000',
      low: '220.2300000000000000',
      close: '224.2600000000000000',
      volume: '443780.3377200000000000',
      quoteVolume: 99650220.54162118,
      btcVolume: 11371.931756177451,
      usdVolume: 99650220.54162118,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '224.2600000000000000',
      high: '234.0900000000000000',
      low: '224.2300000000000000',
      close: '228.3800000000000000',
      volume: '601434.2612800000000000',
      quoteVolume: 138071496.98683217,
      btcVolume: 15332.125743401275,
      usdVolume: 138071496.98683217,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '228.3800000000000000',
      high: '245.1600000000000000',
      low: '227.3300000000000000',
      close: '244.8800000000000000',
      volume: '628147.3257000000000000',
      quoteVolume: 148563741.83299482,
      btcVolume: 16336.054719609947,
      usdVolume: 148563741.83299482,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '244.9300000000000000',
      high: '251.9300000000000000',
      low: '236.0000000000000000',
      close: '237.2300000000000000',
      volume: '633748.8966200000000000',
      quoteVolume: 154037325.61130953,
      btcVolume: 17062.989655657668,
      usdVolume: 154037325.61130953,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '237.2300000000000000',
      high: '237.2300000000000000',
      low: '195.5000000000000000',
      close: '199.4300000000000000',
      volume: '1278973.5374100000000000',
      quoteVolume: 277970187.86825454,
      btcVolume: 32927.24759153153,
      usdVolume: 277970187.86825454,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '199.4300000000000000',
      high: '208.6200000000000000',
      low: '190.0000000000000000',
      close: '202.8100000000000000',
      volume: '1661331.8355300000000000',
      quoteVolume: 332570408.53516084,
      btcVolume: 42278.225193090606,
      usdVolume: 332570408.53516084,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '202.7900000000000000',
      high: '206.2000000000000000',
      low: '195.5400000000000000',
      close: '200.7000000000000000',
      volume: '1020260.1070000000000000',
      quoteVolume: 205536190.19393793,
      btcVolume: 25870.47287880342,
      usdVolume: 205536190.19393793,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '200.7400000000000000',
      high: '203.1800000000000000',
      low: '181.7300000000000000',
      close: '194.6100000000000000',
      volume: '1079824.9016700000000000',
      quoteVolume: 208232375.1861964,
      btcVolume: 26676.44911775151,
      usdVolume: 208232375.1861964,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '194.6100000000000000',
      high: '195.5500000000000000',
      low: '101.2000000000000000',
      close: '107.8200000000000000',
      volume: '3487974.4724600000000000',
      quoteVolume: 502735483.19587886,
      btcVolume: 79582.69602720514,
      usdVolume: 502735483.19587886,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '107.6700000000000000',
      high: '139.6800000000000000',
      low: '86.0000000000000000',
      close: '134.0600000000000000',
      volume: '4352096.1180700000000000',
      quoteVolume: 524165020.32676804,
      btcVolume: 102228.2183345659,
      usdVolume: 524165020.32676804,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '134.0600000000000000',
      high: '134.6800000000000000',
      low: '120.0000000000000000',
      close: '122.5400000000000000',
      volume: '1237626.0682200000000000',
      quoteVolume: 159186518.38922155,
      btcVolume: 29588.5952380688,
      usdVolume: 159186518.38922155,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '122.5400000000000000',
      high: '133.5000000000000000',
      low: '120.1600000000000000',
      close: '123.7800000000000000',
      volume: '1312950.9013700000000000',
      quoteVolume: 163436235.26908636,
      btcVolume: 30443.399792973287,
      usdVolume: 163436235.26908636,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '123.8200000000000000',
      high: '124.3300000000000000',
      low: '101.1000000000000000',
      close: '111.0200000000000000',
      volume: '2442064.1444800000000000',
      quoteVolume: 270353891.80499524,
      btcVolume: 55152.042843550655,
      usdVolume: 270353891.80499524,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '111.0200000000000000',
      high: '120.8000000000000000',
      low: '109.3800000000000000',
      close: '115.6700000000000000',
      volume: '1295108.4848000000000000',
      quoteVolume: 150800664.07195225,
      btcVolume: 28587.190421365343,
      usdVolume: 150800664.07195225,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '115.6700000000000000',
      high: '118.5000000000000000',
      low: '109.8600000000000000',
      close: '118.1100000000000000',
      volume: '1201850.3127500000000000',
      quoteVolume: 137550912.08551753,
      btcVolume: 26224.964726877704,
      usdVolume: 137550912.08551753,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '118.1100000000000000',
      high: '143.1300000000000000',
      low: '115.4600000000000000',
      close: '136.3000000000000000',
      volume: '1801071.1880800000000000',
      quoteVolume: 231626928.1512321,
      btcVolume: 39606.47361637073,
      usdVolume: 231626928.1512321,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '136.3000000000000000',
      high: '152.5500000000000000',
      low: '116.7400000000000000',
      close: '133.4800000000000000',
      volume: '2504057.6379900000000000',
      quoteVolume: 345856006.74780256,
      btcVolume: 54225.66525238545,
      usdVolume: 345856006.74780256,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '133.4900000000000000',
      high: '137.4900000000000000',
      low: '125.6900000000000000',
      close: '132.6600000000000000',
      volume: '1231842.1112100000000000',
      quoteVolume: 162468451.77231285,
      btcVolume: 26347.20683720244,
      usdVolume: 162468451.77231285,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '132.6300000000000000',
      high: '137.1300000000000000',
      low: '121.1000000000000000',
      close: '122.3200000000000000',
      volume: '1055699.8653200000000000',
      quoteVolume: 135927941.07952216,
      btcVolume: 22332.10691388855,
      usdVolume: 135927941.07952216,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '122.3100000000000000',
      high: '137.5000000000000000',
      low: '119.5000000000000000',
      close: '135.9200000000000000',
      volume: '1436796.1244900000000000',
      quoteVolume: 184851609.12426114,
      btcVolume: 30054.31381726867,
      usdVolume: 184851609.12426114,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '135.8900000000000000',
      high: '143.3600000000000000',
      low: '132.2000000000000000',
      close: '138.4200000000000000',
      volume: '1244377.1633400000000000',
      quoteVolume: 171156363.69229996,
      btcVolume: 25793.645485705532,
      usdVolume: 171156363.69229996,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '138.4200000000000000',
      high: '142.3300000000000000',
      low: '132.5400000000000000',
      close: '136.1000000000000000',
      volume: '1034248.7436900000000000',
      quoteVolume: 141795323.0268692,
      btcVolume: 21290.647624377056,
      usdVolume: 141795323.0268692,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '136.1100000000000000',
      high: '139.7400000000000000',
      low: '133.2600000000000000',
      close: '138.4000000000000000',
      volume: '750975.5504600000000000',
      quoteVolume: 101907167.70116763,
      btcVolume: 15297.871335099853,
      usdVolume: 101907167.70116763,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '138.4000000000000000',
      high: '141.2700000000000000',
      low: '129.6800000000000000',
      close: '131.3300000000000000',
      volume: '706948.5455900000000000',
      quoteVolume: 96581886.87894869,
      btcVolume: 14496.8359010212,
      usdVolume: 96581886.87894869,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '131.3300000000000000',
      high: '132.9700000000000000',
      low: '124.8800000000000000',
      close: '130.9800000000000000',
      volume: '896032.1059400000000000',
      quoteVolume: 115166651.97007346,
      btcVolume: 18545.39550175307,
      usdVolume: 115166651.97007346,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '130.9400000000000000',
      high: '131.8400000000000000',
      low: '123.8100000000000000',
      close: '124.5000000000000000',
      volume: '543997.0000000000000000',
      quoteVolume: 69434202.20547193,
      btcVolume: 11449.693329595028,
      usdVolume: 69434202.20547193,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '124.4700000000000000',
      high: '135.4200000000000000',
      low: '124.0600000000000000',
      close: '132.0600000000000000',
      volume: '760403.4785400000000000',
      quoteVolume: 99677889.71604659,
      btcVolume: 15885.98378555245,
      usdVolume: 99677889.71604659,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '132.0900000000000000',
      high: '135.0000000000000000',
      low: '130.3800000000000000',
      close: '132.7200000000000000',
      volume: '547529.2537800000000000',
      quoteVolume: 72599600.32278414,
      btcVolume: 11270.645004277229,
      usdVolume: 72599600.32278414,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '132.7200000000000000',
      high: '136.9900000000000000',
      low: '128.6200000000000000',
      close: '135.6900000000000000',
      volume: '680643.4807700000000000',
      quoteVolume: 90027178.09661904,
      btcVolume: 14172.192723006221,
      usdVolume: 90027178.09661904,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '135.6500000000000000',
      high: '149.7600000000000000',
      low: '135.0100000000000000',
      close: '141.2800000000000000',
      volume: '1138977.6611400000000000',
      quoteVolume: 159989506.60494053,
      btcVolume: 23529.02931639409,
      usdVolume: 159989506.60494053,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '141.2700000000000000',
      high: '146.7200000000000000',
      low: '137.2600000000000000',
      close: '141.3100000000000000',
      volume: '797546.1144600000000000',
      quoteVolume: 113731706.71500106,
      btcVolume: 16666.063640760167,
      usdVolume: 113731706.71500106,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '141.3000000000000000',
      high: '146.2800000000000000',
      low: '138.8800000000000000',
      close: '144.1000000000000000',
      volume: '573508.1408600000000000',
      quoteVolume: 81640622.82843257,
      btcVolume: 12045.428630419647,
      usdVolume: 81640622.82843257,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '144.1000000000000000',
      high: '145.5800000000000000',
      low: '140.6800000000000000',
      close: '142.6800000000000000',
      volume: '414987.4524600000000000',
      quoteVolume: 59383946.127442546,
      btcVolume: 8751.783787499042,
      usdVolume: 59383946.127442546,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '142.6900000000000000',
      high: '172.0000000000000000',
      low: '142.6000000000000000',
      close: '171.3200000000000000',
      volume: '1636338.7311000000000000',
      quoteVolume: 257097597.7838751,
      btcVolume: 36146.37040615942,
      usdVolume: 257097597.7838751,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '171.3200000000000000',
      high: '176.2200000000000000',
      low: '162.2200000000000000',
      close: '164.6300000000000000',
      volume: '1717496.7435600000000000',
      quoteVolume: 292630399.0504515,
      btcVolume: 40022.062364601465,
      usdVolume: 292630399.0504515,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '164.6200000000000000',
      high: '174.4800000000000000',
      low: '163.5900000000000000',
      close: '173.1100000000000000',
      volume: '1023575.9207600000000000',
      quoteVolume: 173916484.78615347,
      btcVolume: 23819.17844363877,
      usdVolume: 173916484.78615347,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '173.1500000000000000',
      high: '173.3800000000000000',
      low: '165.1900000000000000',
      close: '169.5200000000000000',
      volume: '719837.2442300000000000',
      quoteVolume: 122531131.95595776,
      btcVolume: 16818.265725807498,
      usdVolume: 122531131.95595776,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '169.5600000000000000',
      high: '170.1000000000000000',
      low: '152.1100000000000000',
      close: '157.8100000000000000',
      volume: '1314841.3620000000000000',
      quoteVolume: 209535613.32368094,
      btcVolume: 30105.99545258993,
      usdVolume: 209535613.32368094,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '157.8100000000000000',
      high: '161.3700000000000000',
      low: '153.9100000000000000',
      close: '158.3300000000000000',
      volume: '621599.6128900000000000',
      quoteVolume: 98275533.06263469,
      btcVolume: 14322.52534224828,
      usdVolume: 98275533.06263469,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '158.3300000000000000',
      high: '164.7800000000000000',
      low: '155.0000000000000000',
      close: '158.5900000000000000',
      volume: '809097.1317400000000000',
      quoteVolume: 129919434.5212399,
      btcVolume: 18622.065967622548,
      usdVolume: 129919434.5212399,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '158.5600000000000000',
      high: '158.9700000000000000',
      low: '150.0000000000000000',
      close: '156.2900000000000000',
      volume: '1042091.0362200000000000',
      quoteVolume: 160423270.30371547,
      btcVolume: 23810.704478929125,
      usdVolume: 160423270.30371547,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '156.2800000000000000',
      high: '161.8500000000000000',
      low: '155.2300000000000000',
      close: '158.4500000000000000',
      volume: '698030.6508900000000000',
      quoteVolume: 110710086.10400799,
      btcVolume: 16092.175363956325,
      usdVolume: 110710086.10400799,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '158.4600000000000000',
      high: '161.2900000000000000',
      low: '152.0000000000000000',
      close: '152.7300000000000000',
      volume: '729119.6650500000000000',
      quoteVolume: 114640840.11551383,
      btcVolume: 16907.325021412682,
      usdVolume: 114640840.11551383,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '152.7400000000000000',
      high: '174.7900000000000000',
      low: '148.3300000000000000',
      close: '172.2900000000000000',
      volume: '1647207.5999100000000000',
      quoteVolume: 273639160.06938905,
      btcVolume: 39469.80302466689,
      usdVolume: 273639160.06938905,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '172.3100000000000000',
      high: '174.9600000000000000',
      low: '168.3100000000000000',
      close: '170.6900000000000000',
      volume: '723537.7559100000000000',
      quoteVolume: 123553456.31967713,
      btcVolume: 17477.09337208831,
      usdVolume: 123553456.31967713,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '170.6100000000000000',
      high: '189.5400000000000000',
      low: '170.4800000000000000',
      close: '187.4000000000000000',
      volume: '1127296.3697900000000000',
      quoteVolume: 203132211.95640972,
      btcVolume: 28322.990109666654,
      usdVolume: 203132211.95640972,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '187.4000000000000000',
      high: '188.3500000000000000',
      low: '175.7500000000000000',
      close: '180.0300000000000000',
      volume: '995759.5775000000000000',
      quoteVolume: 181415813.50269327,
      btcVolume: 25353.375195221084,
      usdVolume: 181415813.50269327,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '180.0200000000000000',
      high: '186.4600000000000000',
      low: '166.7000000000000000',
      close: '170.2000000000000000',
      volume: '1547821.5273700000000000',
      quoteVolume: 273854539.8037984,
      btcVolume: 39135.19214462897,
      usdVolume: 273854539.8037984,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '170.2100000000000000',
      high: '174.7000000000000000',
      low: '168.0000000000000000',
      close: '170.7400000000000000',
      volume: '906251.0337800000000000',
      quoteVolume: 155269608.04007408,
      btcVolume: 22667.419297755732,
      usdVolume: 155269608.04007408,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '170.7300000000000000',
      high: '184.0000000000000000',
      low: '169.7800000000000000',
      close: '182.7000000000000000',
      volume: '988227.0746000000000000',
      quoteVolume: 175998292.09734583,
      btcVolume: 25096.17505566981,
      usdVolume: 175998292.09734583,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '182.7000000000000000',
      high: '193.6300000000000000',
      low: '178.1600000000000000',
      close: '185.5100000000000000',
      volume: '1457546.9509400000000000',
      quoteVolume: 270803059.6644047,
      btcVolume: 36995.15878859557,
      usdVolume: 270803059.6644047,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '185.5700000000000000',
      high: '189.8000000000000000',
      low: '184.7200000000000000',
      close: '187.5900000000000000',
      volume: '780904.2125700000000000',
      quoteVolume: 146419748.08164915,
      btcVolume: 19487.960270951102,
      usdVolume: 146419748.08164915,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '187.6000000000000000',
      high: '198.3600000000000000',
      low: '185.7600000000000000',
      close: '194.2100000000000000',
      volume: '866478.0492800000000000',
      quoteVolume: 167703202.42505187,
      btcVolume: 22213.786528375527,
      usdVolume: 167703202.42505187,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '194.2100000000000000',
      high: '200.0000000000000000',
      low: '192.3000000000000000',
      close: '197.3800000000000000',
      volume: '758525.8153300000000000',
      quoteVolume: 148433709.777186,
      btcVolume: 19517.662353427906,
      usdVolume: 148433709.777186,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '197.3900000000000000',
      high: '199.0500000000000000',
      low: '189.2900000000000000',
      close: '196.3400000000000000',
      volume: '896627.3727100000000000',
      quoteVolume: 174775637.92727718,
      btcVolume: 22703.242326359323,
      usdVolume: 174775637.92727718,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '196.3300000000000000',
      high: '198.5000000000000000',
      low: '192.3600000000000000',
      close: '196.2400000000000000',
      volume: '636829.8939700000000000',
      quoteVolume: 124476041.88938437,
      btcVolume: 16122.852361506426,
      usdVolume: 124476041.88938437,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '196.2400000000000000',
      high: '218.7700000000000000',
      low: '196.2200000000000000',
      close: '215.3600000000000000',
      volume: '1620422.7243100000000000',
      quoteVolume: 338324593.63727975,
      btcVolume: 40923.435434255334,
      usdVolume: 338324593.63727975,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '215.4000000000000000',
      high: '227.3000000000000000',
      low: '202.0000000000000000',
      close: '206.0800000000000000',
      volume: '1749436.3115300000000000',
      quoteVolume: 374836060.0037704,
      btcVolume: 42031.33369272022,
      usdVolume: 374836060.0037704,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '206.0700000000000000',
      high: '217.1400000000000000',
      low: '205.9100000000000000',
      close: '212.0200000000000000',
      volume: '794913.9582800000000000',
      quoteVolume: 168339123.3579177,
      btcVolume: 19142.43801029207,
      usdVolume: 168339123.3579177,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '212.0100000000000000',
      high: '215.3700000000000000',
      low: '210.3600000000000000',
      close: '213.9000000000000000',
      volume: '443494.6724800000000000',
      quoteVolume: 94389501.51781647,
      btcVolume: 10636.541506688323,
      usdVolume: 94389501.51781647,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '213.8900000000000000',
      high: '219.3000000000000000',
      low: '203.9200000000000000',
      close: '209.8600000000000000',
      volume: '748383.9131100000000000',
      quoteVolume: 158874887.0783394,
      btcVolume: 17742.09175499337,
      usdVolume: 158874887.0783394,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '209.8600000000000000',
      high: '210.6500000000000000',
      low: '194.8800000000000000',
      close: '206.4800000000000000',
      volume: '1114249.3265800000000000',
      quoteVolume: 225451167.65536752,
      btcVolume: 25813.68579734503,
      usdVolume: 225451167.65536752,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '206.4700000000000000',
      high: '211.6800000000000000',
      low: '201.0000000000000000',
      close: '205.2900000000000000',
      volume: '640644.6626800000000000',
      quoteVolume: 131906204.90781972,
      btcVolume: 14781.073403266748,
      usdVolume: 131906204.90781972,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '205.3000000000000000',
      high: '210.7700000000000000',
      low: '197.7100000000000000',
      close: '198.9700000000000000',
      volume: '862700.6784900000000000',
      quoteVolume: 178157452.60084617,
      btcVolume: 19446.63985121468,
      usdVolume: 178157452.60084617,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '198.9200000000000000',
      high: '215.1000000000000000',
      low: '196.4300000000000000',
      close: '212.0000000000000000',
      volume: '1305778.7376400000000000',
      quoteVolume: 271197927.6650283,
      btcVolume: 28416.436328368578,
      usdVolume: 271197927.6650283,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '212.0100000000000000',
      high: '216.6100000000000000',
      low: '206.9000000000000000',
      close: '211.3100000000000000',
      volume: '917908.1944000000000000',
      quoteVolume: 194579529.42776698,
      btcVolume: 19629.460850922827,
      usdVolume: 194579529.42776698,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '211.3200000000000000',
      high: '214.6000000000000000',
      low: '208.4000000000000000',
      close: '210.0700000000000000',
      volume: '587871.9807300000000000',
      quoteVolume: 124626471.42703621,
      btcVolume: 12822.779287709614,
      usdVolume: 124626471.42703621,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '210.0900000000000000',
      high: '210.1800000000000000',
      low: '179.5900000000000000',
      close: '187.5400000000000000',
      volume: '1473226.0547500000000000',
      quoteVolume: 278394290.7597449,
      btcVolume: 32142.471378080594,
      usdVolume: 278394290.7597449,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '187.6200000000000000',
      high: '193.5000000000000000',
      low: '176.0000000000000000',
      close: '185.7300000000000000',
      volume: '1093299.8192700000000000',
      quoteVolume: 203391530.206183,
      btcVolume: 23422.170849671944,
      usdVolume: 203391530.206183,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '185.8000000000000000',
      high: '192.2400000000000000',
      low: '185.4800000000000000',
      close: '189.7600000000000000',
      volume: '669755.7956700000000000',
      quoteVolume: 126810812.16817188,
      btcVolume: 14463.158714611134,
      usdVolume: 126810812.16817188,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '189.7700000000000000',
      high: '201.0000000000000000',
      low: '188.3500000000000000',
      close: '199.5800000000000000',
      volume: '805809.8298700000000000',
      quoteVolume: 156573908.52186683,
      btcVolume: 17267.02579964432,
      usdVolume: 156573908.52186683,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '199.6100000000000000',
      high: '206.0200000000000000',
      low: '195.6700000000000000',
      close: '203.2900000000000000',
      volume: '1114700.2493700000000000',
      quoteVolume: 224233786.12671238,
      btcVolume: 23321.873078992805,
      usdVolume: 224233786.12671238,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '203.2900000000000000',
      high: '204.0800000000000000',
      low: '191.6600000000000000',
      close: '194.8600000000000000',
      volume: '904749.6357000000000000',
      quoteVolume: 179634085.6227652,
      btcVolume: 18891.585288550705,
      usdVolume: 179634085.6227652,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '194.8700000000000000',
      high: '203.5000000000000000',
      low: '193.2100000000000000',
      close: '200.4500000000000000',
      volume: '669900.0375400000000000',
      quoteVolume: 134060788.49418302,
      btcVolume: 14238.268494091166,
      usdVolume: 134060788.49418302,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '200.4400000000000000',
      high: '209.8500000000000000',
      low: '199.6800000000000000',
      close: '207.0000000000000000',
      volume: '708656.1209600000000000',
      quoteVolume: 145330053.95795897,
      btcVolume: 15038.595482450506,
      usdVolume: 145330053.95795897,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '207.0200000000000000',
      high: '217.0700000000000000',
      low: '207.0000000000000000',
      close: '214.9100000000000000',
      volume: '1064118.4969700000000000',
      quoteVolume: 226784577.1006466,
      btcVolume: 23254.32875270356,
      usdVolume: 226784577.1006466,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '214.9200000000000000',
      high: '215.9500000000000000',
      low: '209.4500000000000000',
      close: '214.5800000000000000',
      volume: '643702.9884900000000000',
      quoteVolume: 136846948.55680534,
      btcVolume: 14141.16094939455,
      usdVolume: 136846948.55680534,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '214.6000000000000000',
      high: '215.7500000000000000',
      low: '204.2700000000000000',
      close: '209.9800000000000000',
      volume: '679532.6103700000000000',
      quoteVolume: 143704806.17524174,
      btcVolume: 14897.556828334138,
      usdVolume: 143704806.17524174,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '209.9900000000000000',
      high: '211.6400000000000000',
      low: '191.7100000000000000',
      close: '198.7400000000000000',
      volume: '1003923.4342000000000000',
      quoteVolume: 203445710.96284306,
      btcVolume: 22082.32268408724,
      usdVolume: 203445710.96284306,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '198.7200000000000000',
      high: '209.1900000000000000',
      low: '196.2400000000000000',
      close: '207.3600000000000000',
      volume: '787672.3608100000000000',
      quoteVolume: 159972608.5341703,
      btcVolume: 17518.762923166818,
      usdVolume: 159972608.5341703,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '207.3600000000000000',
      high: '211.2000000000000000',
      low: '204.5500000000000000',
      close: '206.6000000000000000',
      volume: '478543.5112000000000000',
      quoteVolume: 99457681.15441823,
      btcVolume: 10806.153666981252,
      usdVolume: 99457681.15441823,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '206.6000000000000000',
      high: '210.5500000000000000',
      low: '199.5000000000000000',
      close: '199.9000000000000000',
      volume: '673364.5911000000000000',
      quoteVolume: 138882881.96251076,
      btcVolume: 15299.402726933584,
      usdVolume: 138882881.96251076,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '199.9000000000000000',
      high: '205.6700000000000000',
      low: '198.0100000000000000',
      close: '204.1500000000000000',
      volume: '469931.6249200000000000',
      quoteVolume: 95411610.79763587,
      btcVolume: 10833.988909859245,
      usdVolume: 95411610.79763587,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '204.2200000000000000',
      high: '205.0000000000000000',
      low: '196.9000000000000000',
      close: '200.8900000000000000',
      volume: '477176.7353000000000000',
      quoteVolume: 96116808.85809049,
      btcVolume: 10865.809286287838,
      usdVolume: 96116808.85809049,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '200.9200000000000000',
      high: '208.4400000000000000',
      low: '200.7600000000000000',
      close: '208.2900000000000000',
      volume: '494816.7272700000000000',
      quoteVolume: 101445351.02566375,
      btcVolume: 11223.077448084685,
      usdVolume: 101445351.02566375,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '208.2900000000000000',
      high: '220.5000000000000000',
      low: '204.6700000000000000',
      close: '220.2100000000000000',
      volume: '643945.9776900000000000',
      quoteVolume: 136361627.34641564,
      btcVolume: 14529.09885208273,
      usdVolume: 136361627.34641564,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '220.2400000000000000',
      high: '224.7900000000000000',
      low: '217.5700000000000000',
      close: '220.6700000000000000',
      volume: '602955.9267100000000000',
      quoteVolume: 133197594.260007,
      btcVolume: 14054.00894376058,
      usdVolume: 133197594.260007,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '220.6300000000000000',
      high: '247.0000000000000000',
      low: '218.8000000000000000',
      close: '243.6600000000000000',
      volume: '1019550.7514600000000000',
      quoteVolume: 238678839.809296,
      btcVolume: 25044.601085406517,
      usdVolume: 238678839.809296,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '243.7100000000000000',
      high: '245.3900000000000000',
      low: '229.5800000000000000',
      close: '231.5700000000000000',
      volume: '821608.6888400000000000',
      quoteVolume: 194867426.2941861,
      btcVolume: 20410.587835015256,
      usdVolume: 194867426.2941861,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '231.5600000000000000',
      high: '250.6800000000000000',
      low: '230.6600000000000000',
      close: '248.2300000000000000',
      volume: '823816.6993600000000000',
      quoteVolume: 198791911.84704918,
      btcVolume: 20496.02670912183,
      usdVolume: 198791911.84704918,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '248.2300000000000000',
      high: '253.8000000000000000',
      low: '225.0000000000000000',
      close: '237.7100000000000000',
      volume: '955520.9186300000000000',
      quoteVolume: 230680327.30929545,
      btcVolume: 23537.399570541293,
      usdVolume: 230680327.30929545,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '237.7100000000000000',
      high: '245.0000000000000000',
      low: '232.7100000000000000',
      close: '244.5200000000000000',
      volume: '502928.1337100000000000',
      quoteVolume: 120179301.17898554,
      btcVolume: 12578.74569388963,
      usdVolume: 120179301.17898554,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '244.5400000000000000',
      high: '246.6000000000000000',
      low: '236.0000000000000000',
      close: '243.2100000000000000',
      volume: '669369.3392000000000000',
      quoteVolume: 162460397.62767565,
      btcVolume: 16776.170878664434,
      usdVolume: 162460397.62767565,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '243.2200000000000000',
      high: '247.8700000000000000',
      low: '239.1400000000000000',
      close: '240.0300000000000000',
      volume: '502468.8571600000000000',
      quoteVolume: 122065135.39008076,
      btcVolume: 12523.278719135755,
      usdVolume: 122065135.39008076,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '240.0400000000000000',
      high: '244.7000000000000000',
      low: '238.1600000000000000',
      close: '241.9400000000000000',
      volume: '366776.8953700000000000',
      quoteVolume: 88523230.03653955,
      btcVolume: 9183.854988193434,
      usdVolume: 88523230.03653955,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '241.9400000000000000',
      high: '245.3700000000000000',
      low: '234.6000000000000000',
      close: '244.5600000000000000',
      volume: '571102.5551500000000000',
      quoteVolume: 137165732.64761737,
      btcVolume: 14264.71365491363,
      usdVolume: 137165732.64761737,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '244.5700000000000000',
      high: '247.7000000000000000',
      low: '240.5900000000000000',
      close: '246.4000000000000000',
      volume: '383116.5471900000000000',
      quoteVolume: 93355224.96830915,
      btcVolume: 9598.855468043803,
      usdVolume: 93355224.96830915,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '246.3700000000000000',
      high: '249.8200000000000000',
      low: '238.0000000000000000',
      close: '243.8000000000000000',
      volume: '419967.8054400000000000',
      quoteVolume: 102287184.99320976,
      btcVolume: 10528.431478674393,
      usdVolume: 102287184.99320976,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '243.7900000000000000',
      high: '250.2800000000000000',
      low: '242.0000000000000000',
      close: '247.7800000000000000',
      volume: '431285.6171500000000000',
      quoteVolume: 105797248.24742587,
      btcVolume: 10771.848889298295,
      usdVolume: 105797248.24742587,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '247.7800000000000000',
      high: '250.0900000000000000',
      low: '226.2000000000000000',
      close: '230.5100000000000000',
      volume: '815431.7796500000000000',
      quoteVolume: 194825983.3024181,
      btcVolume: 20371.297467425575,
      usdVolume: 194825983.3024181,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '230.4600000000000000',
      high: '239.3800000000000000',
      low: '228.1900000000000000',
      close: '237.5500000000000000',
      volume: '444488.9407200000000000',
      quoteVolume: 104599402.02950011,
      btcVolume: 11109.617756002966,
      usdVolume: 104599402.02950011,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '237.5500000000000000',
      high: '238.7300000000000000',
      low: '234.6600000000000000',
      close: '238.1100000000000000',
      volume: '253797.7809000000000000',
      quoteVolume: 60087772.65246506,
      btcVolume: 6372.817903545626,
      usdVolume: 60087772.65246506,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '238.1300000000000000',
      high: '238.5900000000000000',
      low: '229.8300000000000000',
      close: '231.7700000000000000',
      volume: '304943.2118800000000000',
      quoteVolume: 71575514.08027163,
      btcVolume: 7622.81941591543,
      usdVolume: 71575514.08027163,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '231.7400000000000000',
      high: '233.5500000000000000',
      low: '218.2700000000000000',
      close: '230.8600000000000000',
      volume: '875026.9205700000000000',
      quoteVolume: 197907504.77877796,
      btcVolume: 21476.01503478616,
      usdVolume: 197907504.77877796,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '230.8600000000000000',
      high: '236.0000000000000000',
      low: '228.7000000000000000',
      close: '235.3000000000000000',
      volume: '499194.6675800000000000',
      quoteVolume: 116456364.2312674,
      btcVolume: 12259.734211678307,
      usdVolume: 116456364.2312674,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '235.3100000000000000',
      high: '237.3000000000000000',
      low: '227.6500000000000000',
      close: '233.8200000000000000',
      volume: '478187.2917000000000000',
      quoteVolume: 111415059.05626224,
      btcVolume: 11798.305623152255,
      usdVolume: 111415059.05626224,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '233.8200000000000000',
      high: '234.6500000000000000',
      low: '227.9300000000000000',
      close: '231.2700000000000000',
      volume: '334347.7991700000000000',
      quoteVolume: 77542070.33327071,
      btcVolume: 8244.094026212786,
      usdVolume: 77542070.33327071,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '231.2600000000000000',
      high: '231.9500000000000000',
      low: '226.7400000000000000',
      close: '229.0000000000000000',
      volume: '397284.5980800000000000',
      quoteVolume: 91117706.15513879,
      btcVolume: 9760.152539929302,
      usdVolume: 91117706.15513879,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '229.0000000000000000',
      high: '230.8500000000000000',
      low: '225.6200000000000000',
      close: '228.8900000000000000',
      volume: '303704.9882600000000000',
      quoteVolume: 69343699.90954724,
      btcVolume: 7455.57113620382,
      usdVolume: 69343699.90954724,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '228.8800000000000000',
      high: '231.4900000000000000',
      low: '227.1100000000000000',
      close: '228.0400000000000000',
      volume: '256305.2373400000000000',
      quoteVolume: 58868166.506103784,
      btcVolume: 6293.000295933788,
      usdVolume: 58868166.506103784,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '228.0300000000000000',
      high: '246.8900000000000000',
      low: '227.5100000000000000',
      close: '243.1300000000000000',
      volume: '748759.3895600000000000',
      quoteVolume: 178643659.67329305,
      btcVolume: 18825.751197784553,
      usdVolume: 178643659.67329305,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '243.1100000000000000',
      high: '244.8700000000000000',
      low: '240.7500000000000000',
      close: '243.1400000000000000',
      volume: '442226.8735200000000000',
      quoteVolume: 107440173.5377359,
      btcVolume: 11144.411233446323,
      usdVolume: 107440173.5377359,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '243.1500000000000000',
      high: '249.2400000000000000',
      low: '231.0700000000000000',
      close: '234.5800000000000000',
      volume: '921634.0534000000000000',
      quoteVolume: 220763040.43374506,
      btcVolume: 23383.216137878488,
      usdVolume: 220763040.43374506,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '234.5700000000000000',
      high: '235.3900000000000000',
      low: '227.5600000000000000',
      close: '232.5000000000000000',
      volume: '613748.5934800000000000',
      quoteVolume: 142697463.3801034,
      btcVolume: 15489.816263370116,
      usdVolume: 142697463.3801034,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '232.4900000000000000',
      high: '233.6100000000000000',
      low: '227.7500000000000000',
      close: '229.6400000000000000',
      volume: '628340.0700700000000000',
      quoteVolume: 144805505.1915821,
      btcVolume: 15783.082372432726,
      usdVolume: 144805505.1915821,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '229.6600000000000000',
      high: '231.4200000000000000',
      low: '216.0000000000000000',
      close: '220.9400000000000000',
      volume: '645950.6143800000000000',
      quoteVolume: 145264632.26381838,
      btcVolume: 16009.140095513289,
      usdVolume: 145264632.26381838,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '220.9400000000000000',
      high: '227.9600000000000000',
      low: '218.3200000000000000',
      close: '224.9300000000000000',
      volume: '444766.5688100000000000',
      quoteVolume: 99423540.13931867,
      btcVolume: 10949.038056790398,
      usdVolume: 99423540.13931867,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '224.8900000000000000',
      high: '229.9600000000000000',
      low: '221.2600000000000000',
      close: '227.9300000000000000',
      volume: '597915.5224200000000000',
      quoteVolume: 134510531.19766667,
      btcVolume: 14743.622729629511,
      usdVolume: 134510531.19766667,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '227.9800000000000000',
      high: '228.7000000000000000',
      low: '222.9100000000000000',
      close: '225.6000000000000000',
      volume: '415036.0297700000000000',
      quoteVolume: 93802207.27357335,
      btcVolume: 10255.185172332418,
      usdVolume: 93802207.27357335,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '225.5900000000000000',
      high: '232.7100000000000000',
      low: '224.1600000000000000',
      close: '230.9900000000000000',
      volume: '515968.3174700000000000',
      quoteVolume: 118009144.95166142,
      btcVolume: 12823.681622799983,
      usdVolume: 118009144.95166142,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '230.9900000000000000',
      high: '232.0100000000000000',
      low: '222.8700000000000000',
      close: '226.4600000000000000',
      volume: '592485.9111500000000000',
      quoteVolume: 134976975.12319767,
      btcVolume: 14752.25945808237,
      usdVolume: 134976975.12319767,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '226.4600000000000000',
      high: '228.1300000000000000',
      low: '223.9700000000000000',
      close: '224.8700000000000000',
      volume: '312108.6060700000000000',
      quoteVolume: 70729994.77950838,
      btcVolume: 7780.293748705394,
      usdVolume: 70729994.77950838,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '224.8600000000000000',
      high: '230.7100000000000000',
      low: '224.4300000000000000',
      close: '229.1700000000000000',
      volume: '298119.6003300000000000',
      quoteVolume: 67726803.92678858,
      btcVolume: 7439.9741424165295,
      usdVolume: 67726803.92678858,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '229.2400000000000000',
      high: '229.8500000000000000',
      low: '223.0500000000000000',
      close: '227.5600000000000000',
      volume: '341994.9329000000000000',
      quoteVolume: 77425409.00913155,
      btcVolume: 8548.264489930476,
      usdVolume: 77425409.00913155,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '227.5400000000000000',
      high: '242.0000000000000000',
      low: '226.9300000000000000',
      close: '241.6000000000000000',
      volume: '864817.5070000000000000',
      quoteVolume: 203334727.850139,
      btcVolume: 22028.523967262292,
      usdVolume: 203334727.850139,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '241.6000000000000000',
      high: '243.7700000000000000',
      low: '234.4000000000000000',
      close: '239.3900000000000000',
      volume: '600378.4220900000000000',
      quoteVolume: 143111734.11362022,
      btcVolume: 15434.282792689764,
      usdVolume: 143111734.11362022,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '239.3900000000000000',
      high: '248.8800000000000000',
      low: '237.7800000000000000',
      close: '246.9500000000000000',
      volume: '942434.0782300000000000',
      quoteVolume: 229569778.54287842,
      btcVolume: 24561.674657966098,
      usdVolume: 229569778.54287842,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '246.9400000000000000',
      high: '247.9000000000000000',
      low: '237.8800000000000000',
      close: '241.9800000000000000',
      volume: '647641.5829100000000000',
      quoteVolume: 157541370.0048171,
      btcVolume: 16903.849735123644,
      usdVolume: 157541370.0048171,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '241.9700000000000000',
      high: '242.1600000000000000',
      low: '235.7200000000000000',
      close: '241.2600000000000000',
      volume: '455025.1238500000000000',
      quoteVolume: 108806462.4650961,
      btcVolume: 11835.149454014792,
      usdVolume: 108806462.4650961,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '241.2700000000000000',
      high: '241.6700000000000000',
      low: '237.5400000000000000',
      close: '239.1900000000000000',
      volume: '290546.6408300000000000',
      quoteVolume: 69603089.08903724,
      btcVolume: 7535.268541585202,
      usdVolume: 69603089.08903724,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '239.1900000000000000',
      high: '244.0000000000000000',
      low: '236.5200000000000000',
      close: '242.7900000000000000',
      volume: '469315.1172000000000000',
      quoteVolume: 112487552.43401246,
      btcVolume: 12159.372376119501,
      usdVolume: 112487552.43401246,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '242.7600000000000000',
      high: '245.3300000000000000',
      low: '237.3300000000000000',
      close: '239.5000000000000000',
      volume: '604093.0424500000000000',
      quoteVolume: 146193291.91067657,
      btcVolume: 15758.109658099816,
      usdVolume: 146193291.91067657,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '239.5000000000000000',
      high: '242.0900000000000000',
      low: '237.0000000000000000',
      close: '240.4400000000000000',
      volume: '426320.8918800000000000',
      quoteVolume: 102105027.13192993,
      btcVolume: 11077.23641948493,
      usdVolume: 102105027.13192993,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '240.4500000000000000',
      high: '241.4500000000000000',
      low: '236.8300000000000000',
      close: '238.6100000000000000',
      volume: '368350.6651800000000000',
      quoteVolume: 88059682.48343667,
      btcVolume: 9547.404137094758,
      usdVolume: 88059682.48343667,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '238.5900000000000000',
      high: '239.3900000000000000',
      low: '230.0200000000000000',
      close: '233.5700000000000000',
      volume: '587161.7389400000000000',
      quoteVolume: 137208323.20157194,
      btcVolume: 15019.41735536055,
      usdVolume: 137208323.20157194,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '233.5800000000000000',
      high: '234.8400000000000000',
      low: '231.6500000000000000',
      close: '232.6700000000000000',
      volume: '325579.4645700000000000',
      quoteVolume: 75926217.2310008,
      btcVolume: 8310.273922367218,
      usdVolume: 75926217.2310008,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '232.6600000000000000',
      high: '236.8000000000000000',
      low: '232.3200000000000000',
      close: '235.6700000000000000',
      volume: '341356.6182000000000000',
      quoteVolume: 80046747.8322333,
      btcVolume: 8738.120886553355,
      usdVolume: 80046747.8322333,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '235.6700000000000000',
      high: '239.9200000000000000',
      low: '233.2800000000000000',
      close: '239.1100000000000000',
      volume: '265225.5624400000000000',
      quoteVolume: 62547101.402666144,
      btcVolume: 6826.036997576792,
      usdVolume: 62547101.402666144,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '239.1200000000000000',
      high: '239.7200000000000000',
      low: '234.0500000000000000',
      close: '236.0100000000000000',
      volume: '396657.9193800000000000',
      quoteVolume: 94314378.93819806,
      btcVolume: 10278.861999376948,
      usdVolume: 94314378.93819806,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '236.0000000000000000',
      high: '246.6600000000000000',
      low: '235.5700000000000000',
      close: '245.5900000000000000',
      volume: '637462.0807400000000000',
      quoteVolume: 154502240.94120297,
      btcVolume: 16568.240820505314,
      usdVolume: 154502240.94120297,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '245.5300000000000000',
      high: '269.6100000000000000',
      low: '241.5100000000000000',
      close: '263.7400000000000000',
      volume: '718412.8694500000000000',
      quoteVolume: 180691409.64491135,
      btcVolume: 19223.102848907532,
      usdVolume: 180691409.64491135,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '263.7500000000000000',
      high: '279.9800000000000000',
      low: '259.7000000000000000',
      close: '275.3000000000000000',
      volume: '1068774.5787900000000000',
      quoteVolume: 287217202.72011954,
      btcVolume: 30130.661822095655,
      usdVolume: 287217202.72011954,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '275.3100000000000000',
      high: '287.3400000000000000',
      low: '266.0000000000000000',
      close: '279.1500000000000000',
      volume: '926033.0669200000000000',
      quoteVolume: 256347245.54814723,
      btcVolume: 26844.91878187144,
      usdVolume: 256347245.54814723,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '279.1500000000000000',
      high: '308.9400000000000000',
      low: '0.0000102300000000',
      close: '305.2600000000000000',
      volume: '1060891.1375400000000000',
      quoteVolume: 308799629.3812334,
      btcVolume: 32052.82286372341,
      usdVolume: 308799821.8866399,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '305.3100000000000000',
      high: '318.7500000000000000',
      low: '299.0000000000000000',
      close: '311.0000000000000000',
      volume: '1270368.7530000000000000',
      quoteVolume: 392346895.32266885,
      btcVolume: 39870.04525589428,
      usdVolume: 392346895.32266885,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '311.0200000000000000',
      high: '333.3100000000000000',
      low: '310.7800000000000000',
      close: '321.9300000000000000',
      volume: '1636122.3472700000000000',
      quoteVolume: 527292799.2879079,
      btcVolume: 50537.47976051564,
      usdVolume: 527292799.2879079,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '321.9500000000000000',
      high: '326.8000000000000000',
      low: '305.8600000000000000',
      close: '316.7700000000000000',
      volume: '1093231.5557800000000000',
      quoteVolume: 346187853.2759322,
      btcVolume: 31616.78758360037,
      usdVolume: 346187853.2759322,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '316.7700000000000000',
      high: '325.0000000000000000',
      low: '312.3800000000000000',
      close: '317.7100000000000000',
      volume: '792802.3214800000000000',
      quoteVolume: 254201662.13340423,
      btcVolume: 22934.294000855425,
      usdVolume: 254201662.13340423,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '317.7600000000000000',
      high: '342.0000000000000000',
      low: '314.0500000000000000',
      close: '334.8700000000000000',
      volume: '1008827.2143400000000000',
      quoteVolume: 328286340.23770994,
      btcVolume: 29714.76886977982,
      usdVolume: 328286340.23770994,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '334.8200000000000000',
      high: '349.4500000000000000',
      low: '328.4000000000000000',
      close: '346.3300000000000000',
      volume: '948088.4498200000000000',
      quoteVolume: 323282981.0159161,
      btcVolume: 28866.335039593676,
      usdVolume: 323282981.0159161,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '346.3200000000000000',
      high: '392.6700000000000000',
      low: '343.0700000000000000',
      close: '387.0000000000000000',
      volume: '1440843.3420300000000000',
      quoteVolume: 527543444.8628902,
      btcVolume: 45339.60061159785,
      usdVolume: 527543444.8628902,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '387.0100000000000000',
      high: '415.8500000000000000',
      low: '325.0000000000000000',
      close: '372.4200000000000000',
      volume: '2324909.0961600000000000',
      quoteVolume: 878397036.2975318,
      btcVolume: 77495.83814159164,
      usdVolume: 878397036.2975318,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '372.4500000000000000',
      high: '398.0000000000000000',
      low: '366.5000000000000000',
      close: '385.9400000000000000',
      volume: '1191565.0938900000000000',
      quoteVolume: 462170587.4784976,
      btcVolume: 41057.234921476615,
      usdVolume: 462170587.4784976,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '385.9100000000000000',
      high: '402.9800000000000000',
      low: '380.7700000000000000',
      close: '389.6100000000000000',
      volume: '1175763.9381400000000000',
      quoteVolume: 459835053.5956598,
      btcVolume: 40921.10256553506,
      usdVolume: 459835053.5956598,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '389.5900000000000000',
      high: '407.0000000000000000',
      low: '384.1100000000000000',
      close: '400.7200000000000000',
      volume: '1124119.0672000000000000',
      quoteVolume: 444335097.1493966,
      btcVolume: 38666.919386438094,
      usdVolume: 444335097.1493966,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '400.7200000000000000',
      high: '403.6600000000000000',
      low: '390.5100000000000000',
      close: '394.7700000000000000',
      volume: '821379.0839700000000000',
      quoteVolume: 325718417.71600866,
      btcVolume: 27726.769672481845,
      usdVolume: 325718417.71600866,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '394.7700000000000000',
      high: '398.2600000000000000',
      low: '362.8500000000000000',
      close: '379.3400000000000000',
      volume: '1041207.3594600000000000',
      quoteVolume: 398625567.06390804,
      btcVolume: 34262.12382888949,
      usdVolume: 398625567.06390804,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '379.3400000000000000',
      high: '397.0500000000000000',
      low: '375.7900000000000000',
      close: '397.0300000000000000',
      volume: '623013.8133600000000000',
      quoteVolume: 240992993.07174557,
      btcVolume: 20611.4249741265,
      usdVolume: 240992993.07174557,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '397.0500000000000000',
      high: '400.0000000000000000',
      low: '383.3600000000000000',
      close: '390.1400000000000000',
      volume: '594729.4803400000000000',
      quoteVolume: 232989976.98507214,
      btcVolume: 19961.97802262133,
      usdVolume: 232989976.98507214,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '390.1400000000000000',
      high: '399.1800000000000000',
      low: '385.1300000000000000',
      close: '395.4400000000000000',
      volume: '826969.9063300000000000',
      quoteVolume: 326372434.89705575,
      btcVolume: 27443.329738405846,
      usdVolume: 326372434.89705575,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '395.4300000000000000',
      high: '398.2100000000000000',
      low: '367.0000000000000000',
      close: '379.0000000000000000',
      volume: '992412.6023000000000000',
      quoteVolume: 381981614.62167376,
      btcVolume: 33030.351758669196,
      usdVolume: 381981614.62167376,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '378.9900000000000000',
      high: '389.2300000000000000',
      low: '365.8500000000000000',
      close: '387.1800000000000000',
      volume: '751848.7983000000000000',
      quoteVolume: 285872467.64409924,
      btcVolume: 24976.58134909489,
      usdVolume: 285872467.64409924,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '387.1800000000000000',
      high: '431.4900000000000000',
      low: '376.5900000000000000',
      close: '424.5400000000000000',
      volume: '1511330.7732000000000000',
      quoteVolume: 604810975.1318486,
      btcVolume: 52246.13470100553,
      usdVolume: 604810975.1318486,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '424.5400000000000000',
      high: '444.7100000000000000',
      low: '417.0500000000000000',
      close: '438.1100000000000000',
      volume: '1108919.1175900000000000',
      quoteVolume: 476850417.99412924,
      btcVolume: 40596.08524064946,
      usdVolume: 476850417.99412924,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '438.1200000000000000',
      high: '442.9400000000000000',
      low: '427.0200000000000000',
      close: '432.3200000000000000',
      volume: '681696.2610000000000000',
      quoteVolume: 296651951.35869735,
      btcVolume: 25006.734408894456,
      usdVolume: 296651951.35869735,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '432.3000000000000000',
      high: '436.9900000000000000',
      low: '412.4700000000000000',
      close: '433.6200000000000000',
      volume: '716666.2525800000000000',
      quoteVolume: 305445885.8166684,
      btcVolume: 25821.942518111297,
      usdVolume: 305445885.8166684,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '433.6100000000000000',
      high: '446.0000000000000000',
      low: '420.3700000000000000',
      close: '430.8900000000000000',
      volume: '868870.6499600000000000',
      quoteVolume: 375038999.50752,
      btcVolume: 31000.024384319247,
      usdVolume: 375038999.50752,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '430.8900000000000000',
      high: '433.8100000000000000',
      low: '415.0000000000000000',
      close: '421.9500000000000000',
      volume: '743254.5701800000000000',
      quoteVolume: 316635286.99291563,
      btcVolume: 26116.737005652198,
      usdVolume: 316635286.99291563,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '421.9200000000000000',
      high: '426.7500000000000000',
      low: '393.2500000000000000',
      close: '407.4600000000000000',
      volume: '978339.6922100000000000',
      quoteVolume: 400208683.34672534,
      btcVolume: 34018.89009102192,
      usdVolume: 400208683.34672534,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '407.4600000000000000',
      high: '418.9800000000000000',
      low: '402.0700000000000000',
      close: '415.9400000000000000',
      volume: '660964.6963900000000000',
      quoteVolume: 272240068.376272,
      btcVolume: 23069.824029488518,
      usdVolume: 272240068.376272,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '415.9400000000000000',
      high: '418.6800000000000000',
      low: '385.1000000000000000',
      close: '387.9700000000000000',
      volume: '860092.2392900000000000',
      quoteVolume: 347090880.07555234,
      btcVolume: 29626.18363494332,
      usdVolume: 347090880.07555234,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '387.9300000000000000',
      high: '397.0400000000000000',
      low: '379.9200000000000000',
      close: '395.2400000000000000',
      volume: '643112.7567000000000000',
      quoteVolume: 250021920.7245382,
      btcVolume: 21657.375885407164,
      usdVolume: 250021920.7245382,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '395.2300000000000000',
      high: '396.9500000000000000',
      low: '383.1500000000000000',
      close: '390.7100000000000000',
      volume: '445307.5880200000000000',
      quoteVolume: 173795952.99000394,
      btcVolume: 14968.181091353075,
      usdVolume: 173795952.99000394,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '390.7200000000000000',
      high: '410.4200000000000000',
      low: '387.4400000000000000',
      close: '407.6800000000000000',
      volume: '717395.9721000000000000',
      quoteVolume: 287988643.53621656,
      btcVolume: 24526.709155711666,
      usdVolume: 287988643.53621656,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '407.6800000000000000',
      high: '409.0600000000000000',
      low: '370.0000000000000000',
      close: '382.9300000000000000',
      volume: '951557.2886700000000000',
      quoteVolume: 368309918.2113772,
      btcVolume: 32162.02308140377,
      usdVolume: 368309918.2113772,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '382.9100000000000000',
      high: '392.9100000000000000',
      low: '377.3700000000000000',
      close: '385.8100000000000000',
      volume: '738590.5295000000000000',
      quoteVolume: 284465412.4634799,
      btcVolume: 24953.561629989617,
      usdVolume: 284465412.4634799,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '385.8100000000000000',
      high: '397.1000000000000000',
      low: '371.4800000000000000',
      close: '383.2700000000000000',
      volume: '876931.1946000000000000',
      quoteVolume: 336328075.9025886,
      btcVolume: 29644.310136701326,
      usdVolume: 336328075.9025886,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '383.3100000000000000',
      high: '398.0000000000000000',
      low: '379.7500000000000000',
      close: '395.1100000000000000',
      volume: '721730.6497200000000000',
      quoteVolume: 282252408.9354685,
      btcVolume: 24674.28459629944,
      usdVolume: 282252408.9354685,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '395.1000000000000000',
      high: '405.6900000000000000',
      low: '391.8800000000000000',
      close: '398.6500000000000000',
      volume: '654320.0035500000000000',
      quoteVolume: 261582840.97993016,
      btcVolume: 22739.15544880467,
      usdVolume: 261582840.97993016,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '398.6500000000000000',
      high: '429.8900000000000000',
      low: '398.3000000000000000',
      close: '428.9400000000000000',
      volume: '851160.6742400000000000',
      quoteVolume: 352311003.28142095,
      btcVolume: 30325.394571282835,
      usdVolume: 352311003.28142095,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '428.9400000000000000',
      high: '439.0000000000000000',
      low: '418.0400000000000000',
      close: '433.7900000000000000',
      volume: '1040748.6888100000000000',
      quoteVolume: 447866996.185111,
      btcVolume: 38337.44332463666,
      usdVolume: 447866996.185111,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '433.8000000000000000',
      high: '488.8400000000000000',
      low: '429.0300000000000000',
      close: '475.4000000000000000',
      volume: '1656066.8494900000000000',
      quoteVolume: 769006775.5385326,
      btcVolume: 64803.214479942595,
      usdVolume: 768959484.5333103,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '475.4000000000000000',
      high: '481.3900000000000000',
      low: '420.0000000000000000',
      close: '439.5000000000000000',
      volume: '1876373.4429600000000000',
      quoteVolume: 842455901.235392,
      btcVolume: 73163.14575846263,
      usdVolume: 842455901.235392,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '439.5000000000000000',
      high: '450.7000000000000000',
      low: '372.0000000000000000',
      close: '381.4000000000000000',
      volume: '2402627.0353300000000000',
      quoteVolume: 993081852.551086,
      btcVolume: 90931.01288632085,
      usdVolume: 993081852.551086,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '381.3500000000000000',
      high: '400.2100000000000000',
      low: '357.0000000000000000',
      close: '385.2600000000000000',
      volume: '1806487.9639800000000000',
      quoteVolume: 696658830.8205959,
      btcVolume: 67364.86665822042,
      usdVolume: 696658830.8205959,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '385.3000000000000000',
      high: '395.0000000000000000',
      low: '308.4200000000000000',
      close: '335.0100000000000000',
      volume: '2776774.5031000000000000',
      quoteVolume: 967581076.4469733,
      btcVolume: 94990.08545339752,
      usdVolume: 967581076.4469733,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '335.0400000000000000',
      high: '360.0000000000000000',
      low: '316.1000000000000000',
      close: '352.8000000000000000',
      volume: '2017811.1472100000000000',
      quoteVolume: 691925087.3968136,
      btcVolume: 67832.96092299436,
      usdVolume: 691925087.3968136,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '352.8500000000000000',
      high: '358.5100000000000000',
      low: '323.0000000000000000',
      close: '353.5900000000000000',
      volume: '1721408.5783500000000000',
      quoteVolume: 590110812.7562342,
      btcVolume: 58285.20441211165,
      usdVolume: 590110812.7562342,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '353.5900000000000000',
      high: '356.5800000000000000',
      low: '325.3800000000000000',
      close: '337.3900000000000000',
      volume: '1400246.8296200000000000',
      quoteVolume: 476444912.54462713,
      btcVolume: 47124.00188261758,
      usdVolume: 476444912.54462713,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '337.4100000000000000',
      high: '359.0000000000000000',
      low: '330.5100000000000000',
      close: '350.8500000000000000',
      volume: '879760.5625500000000000',
      quoteVolume: 304910633.80089056,
      btcVolume: 29909.92428361022,
      usdVolume: 304910633.80089056,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '350.8500000000000000',
      high: '377.2500000000000000',
      low: '349.9100000000000000',
      close: '367.9300000000000000',
      volume: '1134065.2960700000000000',
      quoteVolume: 416517016.27456033,
      btcVolume: 40287.65628581293,
      usdVolume: 416517016.27456033,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '367.9300000000000000',
      high: '375.2700000000000000',
      low: '355.4900000000000000',
      close: '373.6300000000000000',
      volume: '844017.5996600000000000',
      quoteVolume: 308822715.13365656,
      btcVolume: 29997.26435090535,
      usdVolume: 308822715.13365656,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '373.6200000000000000',
      high: '388.5700000000000000',
      low: '364.7300000000000000',
      close: '387.7800000000000000',
      volume: '766542.3163500000000000',
      quoteVolume: 287133871.2472935,
      btcVolume: 27668.664342038548,
      usdVolume: 287133871.2472935,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '387.7800000000000000',
      high: '390.4300000000000000',
      low: '350.0000000000000000',
      close: '366.4200000000000000',
      volume: '989195.6904700000000000',
      quoteVolume: 367969518.5134864,
      btcVolume: 35461.65474002583,
      usdVolume: 367969518.5134864,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '366.4300000000000000',
      high: '384.6400000000000000',
      low: '355.7300000000000000',
      close: '377.1200000000000000',
      volume: '1042059.6226500000000000',
      quoteVolume: 387238794.68096745,
      btcVolume: 36789.98529463114,
      usdVolume: 387238794.68096745,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '377.1200000000000000',
      high: '382.4700000000000000',
      low: '362.3400000000000000',
      close: '364.0600000000000000',
      volume: '883856.3300500000000000',
      quoteVolume: 328317009.5683414,
      btcVolume: 30470.16739104851,
      usdVolume: 328317009.5683414,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '364.0500000000000000',
      high: '373.4500000000000000',
      low: '355.0000000000000000',
      close: '365.1100000000000000',
      volume: '841709.9893900000000000',
      quoteVolume: 306622238.0715463,
      btcVolume: 28177.270136204377,
      usdVolume: 306622238.0715463,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '365.0400000000000000',
      high: '394.1400000000000000',
      low: '363.3000000000000000',
      close: '389.3600000000000000',
      volume: '1256300.4110000000000000',
      quoteVolume: 479026710.896617,
      btcVolume: 43950.597949227995,
      usdVolume: 479026710.896617,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '389.3500000000000000',
      high: '392.5800000000000000',
      low: '375.3300000000000000',
      close: '384.3900000000000000',
      volume: '804194.4721900000000000',
      quoteVolume: 308748579.81603116,
      btcVolume: 28281.960169687176,
      usdVolume: 308748579.81603116,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '384.4000000000000000',
      high: '388.7200000000000000',
      low: '377.0000000000000000',
      close: '385.2500000000000000',
      volume: '570908.3355300000000000',
      quoteVolume: 218785730.14017949,
      btcVolume: 19860.826040587017,
      usdVolume: 218785730.14017949,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '385.2500000000000000',
      high: '385.4500000000000000',
      low: '365.0000000000000000',
      close: '371.0400000000000000',
      volume: '692270.5369000000000000',
      quoteVolume: 259365469.57772288,
      btcVolume: 23796.352412135322,
      usdVolume: 259365469.57772288,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '371.0400000000000000',
      high: '376.8000000000000000',
      low: '331.0000000000000000',
      close: '340.3600000000000000',
      volume: '1283946.0309800000000000',
      quoteVolume: 452134893.6340549,
      btcVolume: 42542.986905510355,
      usdVolume: 452134893.6340549,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '340.3100000000000000',
      high: '346.9100000000000000',
      low: '335.3400000000000000',
      close: '344.2100000000000000',
      volume: '842519.6630700000000000',
      quoteVolume: 288094132.61557794,
      btcVolume: 27542.53930759665,
      usdVolume: 288094132.61557794,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '344.1800000000000000',
      high: '344.6500000000000000',
      low: '313.2900000000000000',
      close: '320.7200000000000000',
      volume: '940904.4975600000000000',
      quoteVolume: 313808761.09044343,
      btcVolume: 30190.07605426562,
      usdVolume: 313808761.09044343,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '320.7200000000000000',
      high: '353.1000000000000000',
      low: '316.6700000000000000',
      close: '348.9700000000000000',
      volume: '1070458.7500100000000000',
      quoteVolume: 360295904.75400215,
      btcVolume: 34447.673829712236,
      usdVolume: 360295904.75400215,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '348.9700000000000000',
      high: '358.0000000000000000',
      low: '337.5800000000000000',
      close: '351.9200000000000000',
      volume: '860643.7792400000000000',
      quoteVolume: 298266918.80064315,
      btcVolume: 27960.07827767787,
      usdVolume: 298266918.80064315,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '351.9300000000000000',
      high: '356.4000000000000000',
      low: '345.8800000000000000',
      close: '353.9200000000000000',
      volume: '530698.1258100000000000',
      quoteVolume: 186748081.4048322,
      btcVolume: 17435.278426956505,
      usdVolume: 186748081.4048322,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '353.9500000000000000',
      high: '362.1400000000000000',
      low: '347.2400000000000000',
      close: '357.4900000000000000',
      volume: '569255.6546900000000000',
      quoteVolume: 202300944.46097547,
      btcVolume: 18873.55464049207,
      usdVolume: 202300944.46097547,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '357.5800000000000000',
      high: '368.2000000000000000',
      low: '351.7000000000000000',
      close: '353.9600000000000000',
      volume: '757758.4304000000000000',
      quoteVolume: 273063157.7899871,
      btcVolume: 25118.54484016467,
      usdVolume: 273063157.7899871,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '353.9100000000000000',
      high: '360.6300000000000000',
      low: '350.4500000000000000',
      close: '359.7900000000000000',
      volume: '585138.1015300000000000',
      quoteVolume: 208027010.0546198,
      btcVolume: 19389.23399823974,
      usdVolume: 208027010.0546198,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '359.7800000000000000',
      high: '361.5000000000000000',
      low: '351.5600000000000000',
      close: '359.8300000000000000',
      volume: '498791.2080200000000000',
      quoteVolume: 177730785.31600055,
      btcVolume: 16544.49139067945,
      usdVolume: 177730785.31600055,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '359.8300000000000000',
      high: '370.4500000000000000',
      low: '345.5100000000000000',
      close: '353.0300000000000000',
      volume: '749944.4218500000000000',
      quoteVolume: 268507606.4936562,
      btcVolume: 25081.67090101762,
      usdVolume: 268507606.4936562,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '351.5000000000000000',
      high: '354.5500000000000000',
      low: '334.2400000000000000',
      close: '345.6000000000000000',
      volume: '728971.7125400000000000',
      quoteVolume: 250210659.13679448,
      btcVolume: 23823.341024494177,
      usdVolume: 250210659.13679448,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '345.6000000000000000',
      high: '351.0000000000000000',
      low: '343.7000000000000000',
      close: '345.9500000000000000',
      volume: '303595.7190100000000000',
      quoteVolume: 105401893.35734256,
      btcVolume: 9994.847525254354,
      usdVolume: 105401893.35734256,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '345.9400000000000000',
      high: '354.4500000000000000',
      low: '343.8100000000000000',
      close: '352.3700000000000000',
      volume: '343932.9171200000000000',
      quoteVolume: 119961274.70065778,
      btcVolume: 11308.936300639205,
      usdVolume: 119961274.70065778,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '352.3400000000000000',
      high: '356.1000000000000000',
      low: '348.5400000000000000',
      close: '353.6200000000000000',
      volume: '376740.3069200000000000',
      quoteVolume: 132656713.04409541,
      btcVolume: 12401.031763501092,
      usdVolume: 132656713.04409541,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '353.6300000000000000',
      high: '355.1000000000000000',
      low: '336.8000000000000000',
      close: '340.7500000000000000',
      volume: '640027.0228500000000000',
      quoteVolume: 221752725.91989398,
      btcVolume: 20777.07296176719,
      usdVolume: 221752725.91989398,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '340.8000000000000000',
      high: '342.8500000000000000',
      low: '332.1800000000000000',
      close: '341.5800000000000000',
      volume: '541928.8637600000000000',
      quoteVolume: 183873686.39043358,
      btcVolume: 17327.5506872979,
      usdVolume: 183873686.39043358,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '341.5800000000000000',
      high: '353.3200000000000000',
      low: '334.2100000000000000',
      close: '350.9500000000000000',
      volume: '622582.1733200000000000',
      quoteVolume: 214085732.44744968,
      btcVolume: 19925.932109701625,
      usdVolume: 214085732.44744968,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '350.9400000000000000',
      high: '368.3300000000000000',
      low: '347.3200000000000000',
      close: '365.0200000000000000',
      volume: '650478.1933800000000000',
      quoteVolume: 232878561.88521227,
      btcVolume: 21172.01801686735,
      usdVolume: 232878561.88521227,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '365.0100000000000000',
      high: '378.4000000000000000',
      low: '364.7100000000000000',
      close: '370.5200000000000000',
      volume: '591005.3212900000000000',
      quoteVolume: 220607973.01227474,
      btcVolume: 19473.834142001007,
      usdVolume: 220607973.01227474,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '370.5100000000000000',
      high: '377.6000000000000000',
      low: '368.8700000000000000',
      close: '374.1600000000000000',
      volume: '371050.1549000000000000',
      quoteVolume: 138482829.08381432,
      btcVolume: 12189.491907556405,
      usdVolume: 138482829.08381432,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '374.1700000000000000',
      high: '394.8000000000000000',
      low: '365.5800000000000000',
      close: '386.5100000000000000',
      volume: '777805.9870200000000000',
      quoteVolume: 295379758.31860316,
      btcVolume: 25799.813917940977,
      usdVolume: 295379758.31860316,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '386.5100000000000000',
      high: '387.7500000000000000',
      low: '374.5100000000000000',
      close: '381.0400000000000000',
      volume: '614228.9526600000000000',
      quoteVolume: 234463380.94917893,
      btcVolume: 20497.322200014103,
      usdVolume: 234463380.94917893,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '381.0500000000000000',
      high: '387.4100000000000000',
      low: '373.1300000000000000',
      close: '378.6900000000000000',
      volume: '531769.6736300000000000',
      quoteVolume: 201932522.19194937,
      btcVolume: 17718.0848554347,
      usdVolume: 201932522.19194937,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '378.6900000000000000',
      high: '381.7900000000000000',
      low: '369.8500000000000000',
      close: '377.6400000000000000',
      volume: '632253.2404100000000000',
      quoteVolume: 237722026.66919282,
      btcVolume: 20862.537140127723,
      usdVolume: 237722026.66919282,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '377.6300000000000000',
      high: '379.8300000000000000',
      low: '361.1800000000000000',
      close: '365.5000000000000000',
      volume: '654029.3640400000000000',
      quoteVolume: 241127894.70244646,
      btcVolume: 21270.53628135259,
      usdVolume: 241127894.70244646,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '365.5000000000000000',
      high: '370.4600000000000000',
      low: '363.3600000000000000',
      close: '368.3200000000000000',
      volume: '285578.2453700000000000',
      quoteVolume: 104838880.45610258,
      btcVolume: 9248.343343944713,
      usdVolume: 104838880.45610258,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '368.3100000000000000',
      high: '378.6400000000000000',
      low: '367.1000000000000000',
      close: '378.1100000000000000',
      volume: '323468.4709500000000000',
      quoteVolume: 120818450.62118112,
      btcVolume: 10571.651931836788,
      usdVolume: 120818450.62118112,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '378.1200000000000000',
      high: '384.3200000000000000',
      low: '372.5000000000000000',
      close: '379.3000000000000000',
      volume: '577252.9026500000000000',
      quoteVolume: 218372639.95702484,
      btcVolume: 18818.921615016232,
      usdVolume: 218372639.95702484,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '379.3000000000000000',
      high: '380.7300000000000000',
      low: '365.2700000000000000',
      close: '368.5200000000000000',
      volume: '617309.8167700000000000',
      quoteVolume: 229928443.79690987,
      btcVolume: 19409.513615432777,
      usdVolume: 229928443.79690987,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '368.5300000000000000',
      high: '401.4900000000000000',
      low: '367.7400000000000000',
      close: '390.3800000000000000',
      volume: '1221663.6378400000000000',
      quoteVolume: 472488832.71627617,
      btcVolume: 37631.22329892552,
      usdVolume: 472488832.71627617,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '390.3100000000000000',
      high: '420.7300000000000000',
      low: '390.2100000000000000',
      close: '413.9800000000000000',
      volume: '1095886.2298700000000000',
      quoteVolume: 447206682.58595973,
      btcVolume: 34562.69697720812,
      usdVolume: 447206682.58595973,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '413.9400000000000000',
      high: '419.3300000000000000',
      low: '401.3000000000000000',
      close: '405.9200000000000000',
      volume: '642369.4293200000000000',
      quoteVolume: 265063811.5230488,
      btcVolume: 20523.233149956377,
      usdVolume: 265063811.5230488,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '409.1900000000000000',
      high: '416.6600000000000000',
      low: '407.0000000000000000',
      close: '412.1400000000000000',
      volume: '419236.8420100000000000',
      quoteVolume: 172799812.65625998,
      btcVolume: 13272.573133752301,
      usdVolume: 172799812.65625998,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '412.1400000000000000',
      high: '417.4000000000000000',
      low: '402.5000000000000000',
      close: '405.8600000000000000',
      volume: '415431.1155000000000000',
      quoteVolume: 169990300.58460298,
      btcVolume: 13026.150189403857,
      usdVolume: 169990300.58460298,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '405.8500000000000000',
      high: '411.1100000000000000',
      low: '382.3000000000000000',
      close: '392.5200000000000000',
      volume: '894757.9162500000000000',
      quoteVolume: 356060346.50108105,
      btcVolume: 27339.184264126954,
      usdVolume: 356060346.50108105,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '392.5200000000000000',
      high: '410.2500000000000000',
      low: '389.9000000000000000',
      close: '403.4500000000000000',
      volume: '753617.4010100000000000',
      quoteVolume: 302269548.1476697,
      btcVolume: 22546.706790982997,
      usdVolume: 302269548.1476697,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '403.4100000000000000',
      high: '409.2000000000000000',
      low: '380.5200000000000000',
      close: '388.2300000000000000',
      volume: '816953.5301800000000000',
      quoteVolume: 320590416.22950745,
      btcVolume: 23922.336631484806,
      usdVolume: 320590416.22950745,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '388.2200000000000000',
      high: '393.7200000000000000',
      low: '380.5200000000000000',
      close: '387.1300000000000000',
      volume: '573096.0141600000000000',
      quoteVolume: 222669504.8753169,
      btcVolume: 16722.98084116485,
      usdVolume: 222669504.8753169,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '387.1200000000000000',
      high: '391.9700000000000000',
      low: '373.2900000000000000',
      close: '382.4900000000000000',
      volume: '771750.7474600000000000',
      quoteVolume: 294342545.9808737,
      btcVolume: 22024.58912338817,
      usdVolume: 294342545.9808737,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '382.5900000000000000',
      high: '394.8800000000000000',
      low: '379.9000000000000000',
      close: '386.4600000000000000',
      volume: '466870.1136200000000000',
      quoteVolume: 180911022.7121731,
      btcVolume: 13143.343560424059,
      usdVolume: 180911022.7121731,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '386.4400000000000000',
      high: '398.2900000000000000',
      low: '384.6100000000000000',
      close: '396.6700000000000000',
      volume: '438952.9556500000000000',
      quoteVolume: 171238317.7003242,
      btcVolume: 12438.544674604154,
      usdVolume: 171238317.7003242,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '396.6800000000000000',
      high: '404.5000000000000000',
      low: '377.9200000000000000',
      close: '383.0200000000000000',
      volume: '779968.5795700000000000',
      quoteVolume: 304223545.89203674,
      btcVolume: 22406.862744217426,
      usdVolume: 304223545.89203674,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '383.0100000000000000',
      high: '390.0000000000000000',
      low: '370.2300000000000000',
      close: '387.8800000000000000',
      volume: '752954.3711400000000000',
      quoteVolume: 286291773.9684873,
      btcVolume: 21076.429697023013,
      usdVolume: 286291773.9684873,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '387.8800000000000000',
      high: '408.3900000000000000',
      low: '376.4700000000000000',
      close: '402.4900000000000000',
      volume: '1157064.3507600000000000',
      quoteVolume: 454481208.4020135,
      btcVolume: 32586.363296352156,
      usdVolume: 454481208.4020135,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '402.5000000000000000',
      high: '420.4000000000000000',
      low: '396.1400000000000000',
      close: '416.6900000000000000',
      volume: '1475139.7734200000000000',
      quoteVolume: 601591250.4012383,
      btcVolume: 40712.080001064045,
      usdVolume: 601591250.4012383,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '416.7300000000000000',
      high: '458.2700000000000000',
      low: '414.7600000000000000',
      close: '455.9100000000000000',
      volume: '1679301.1392700000000000',
      quoteVolume: 734615875.017384,
      btcVolume: 47133.026973207314,
      usdVolume: 734615875.017384,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '455.9100000000000000',
      high: '468.2800000000000000',
      low: '424.1400000000000000',
      close: '435.2100000000000000',
      volume: '1653416.5961000000000000',
      quoteVolume: 743871175.7821237,
      btcVolume: 48717.33214579415,
      usdVolume: 743871175.7821237,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '435.2000000000000000',
      high: '460.1000000000000000',
      low: '431.9900000000000000',
      close: '454.3000000000000000',
      volume: '840722.7949000000000000',
      quoteVolume: 375595944.18961173,
      btcVolume: 24675.810368249833,
      usdVolume: 375595944.18961173,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '454.2900000000000000',
      high: '459.1000000000000000',
      low: '433.0900000000000000',
      close: '444.3200000000000000',
      volume: '1098601.4997800000000000',
      quoteVolume: 491653842.88129467,
      btcVolume: 32077.913407959655,
      usdVolume: 491653842.88129467,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '444.3200000000000000',
      high: '455.0000000000000000',
      low: '438.7000000000000000',
      close: '450.3400000000000000',
      volume: '866686.4285100000000000',
      quoteVolume: 388106243.05318296,
      btcVolume: 25360.781987038194,
      usdVolume: 388106243.05318296,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '450.3400000000000000',
      high: '476.2500000000000000',
      low: '449.2800000000000000',
      close: '463.0900000000000000',
      volume: '1205782.8426200000000000',
      quoteVolume: 559228791.8903673,
      btcVolume: 35902.59522880581,
      usdVolume: 559228791.8903673,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '463.0900000000000000',
      high: '470.0000000000000000',
      low: '451.2000000000000000',
      close: '462.3900000000000000',
      volume: '990776.6291200000000000',
      quoteVolume: 455971309.2168636,
      btcVolume: 28686.52115959985,
      usdVolume: 455971309.2168636,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '462.4800000000000000',
      high: '478.0100000000000000',
      low: '457.1200000000000000',
      close: '476.4300000000000000',
      volume: '951211.0953600000000000',
      quoteVolume: 444338362.5908921,
      btcVolume: 27326.06235354085,
      usdVolume: 444338362.5908921,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '476.4200000000000000',
      high: '477.4700000000000000',
      low: '452.0000000000000000',
      close: '460.8900000000000000',
      volume: '726969.1507100000000000',
      quoteVolume: 336364969.86737686,
      btcVolume: 21033.888011294177,
      usdVolume: 336364969.86737686,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '460.9000000000000000',
      high: '462.8900000000000000',
      low: '440.1900000000000000',
      close: '448.0800000000000000',
      volume: '665184.8098900000000000',
      quoteVolume: 301391078.6921614,
      btcVolume: 18876.13024282066,
      usdVolume: 301391078.6921614,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '448.0700000000000000',
      high: '466.0000000000000000',
      low: '445.4500000000000000',
      close: '460.5500000000000000',
      volume: '811687.1632900000000000',
      quoteVolume: 370720232.1125867,
      btcVolume: 22611.477082211626,
      usdVolume: 370720232.1125867,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '460.5100000000000000',
      high: '484.8800000000000000',
      low: '459.8000000000000000',
      close: '482.1500000000000000',
      volume: '1120652.5363800000000000',
      quoteVolume: 529585259.9541648,
      btcVolume: 30825.11456878513,
      usdVolume: 529585259.9541648,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '482.1100000000000000',
      high: '495.3100000000000000',
      low: '456.6000000000000000',
      close: '478.4100000000000000',
      volume: '1474767.0885100000000000',
      quoteVolume: 705288664.0718446,
      btcVolume: 39474.871305535446,
      usdVolume: 705288664.0718446,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '478.4300000000000000',
      high: '480.7500000000000000',
      low: '464.8600000000000000',
      close: '471.2500000000000000',
      volume: '813313.3043500000000000',
      quoteVolume: 385259724.0413454,
      btcVolume: 21647.693501398127,
      usdVolume: 385259724.0413454,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '471.2600000000000000',
      high: '514.4000000000000000',
      low: '470.6500000000000000',
      close: '510.1200000000000000',
      volume: '1504164.8389300000000000',
      quoteVolume: 750785717.8739455,
      btcVolume: 40955.1380609167,
      usdVolume: 750785717.8739455,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '510.1200000000000000',
      high: '553.3200000000000000',
      low: '502.8200000000000000',
      close: '552.3500000000000000',
      volume: '1548851.2906700000000000',
      quoteVolume: 815402228.5227968,
      btcVolume: 43572.12042857939,
      usdVolume: 815402228.5227968,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '552.4200000000000000',
      high: '580.8800000000000000',
      low: '511.2800000000000000',
      close: '560.0300000000000000',
      volume: '1850317.9358100000000000',
      quoteVolume: 1014064539.0881541,
      btcVolume: 55112.07754619641,
      usdVolume: 1014064539.0881541,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '560.0300000000000000',
      high: '610.8500000000000000',
      low: '550.1200000000000000',
      close: '608.5800000000000000',
      volume: '2059928.2932900000000000',
      quoteVolume: 1208598659.5999002,
      btcVolume: 65547.02278720128,
      usdVolume: 1208598659.5999002,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '608.5800000000000000',
      high: '623.0000000000000000',
      low: '585.0000000000000000',
      close: '604.9700000000000000',
      volume: '1606450.4919300000000000',
      quoteVolume: 976376501.3114522,
      btcVolume: 52075.479021025865,
      usdVolume: 976376501.3114522,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '604.9800000000000000',
      high: '606.0000000000000000',
      low: '553.8300000000000000',
      close: '569.5000000000000000',
      volume: '1215573.9318600000000000',
      quoteVolume: 714664659.3653877,
      btcVolume: 37634.657613884825,
      usdVolume: 714664659.3653877,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '569.3800000000000000',
      high: '577.1400000000000000',
      low: '481.0000000000000000',
      close: '519.8300000000000000',
      volume: '2683460.7417100000000000',
      quoteVolume: 1386030255.1680546,
      btcVolume: 79991.49178543665,
      usdVolume: 1386030255.1680546,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '519.8300000000000000',
      high: '530.6200000000000000',
      low: '493.7200000000000000',
      close: '518.6800000000000000',
      volume: '1088063.4322200000000000',
      quoteVolume: 558984876.9379048,
      btcVolume: 32921.56765547952,
      usdVolume: 558984876.9379048,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '518.5900000000000000',
      high: '547.9400000000000000',
      low: '506.5900000000000000',
      close: '537.5200000000000000',
      volume: '900508.6143100000000000',
      quoteVolume: 475169074.6903964,
      btcVolume: 27355.45626379676,
      usdVolume: 475169074.6903964,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '537.5000000000000000',
      high: '576.9000000000000000',
      low: '530.3200000000000000',
      close: '576.3000000000000000',
      volume: '855184.5340300000000000',
      quoteVolume: 471578566.1167156,
      btcVolume: 26185.011636034185,
      usdVolume: 471578566.1167156,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '576.3200000000000000',
      high: '616.8500000000000000',
      low: '570.0700000000000000',
      close: '616.6600000000000000',
      volume: '1501989.4540800000000000',
      quoteVolume: 895373784.8300341,
      btcVolume: 47102.56560570271,
      usdVolume: 895373784.8300341,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '616.6500000000000000',
      high: '635.7000000000000000',
      low: '560.0000000000000000',
      close: '585.2600000000000000',
      volume: '2127926.8705500000000000',
      quoteVolume: 1278451640.1510434,
      btcVolume: 66678.68357305365,
      usdVolume: 1278451640.1510434,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '585.2600000000000000',
      high: '604.9300000000000000',
      low: '575.0000000000000000',
      close: '596.8900000000000000',
      volume: '1069538.6561900000000000',
      quoteVolume: 632858653.2050295,
      btcVolume: 33458.77431583617,
      usdVolume: 632858653.2050295,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '596.8800000000000000',
      high: '623.4400000000000000',
      low: '585.8200000000000000',
      close: '615.8500000000000000',
      volume: '935687.9172300000000000',
      quoteVolume: 568024618.9410435,
      btcVolume: 29433.265740340947,
      usdVolume: 568024618.9410435,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '615.8400000000000000',
      high: '620.0000000000000000',
      low: '565.8200000000000000',
      close: '567.2400000000000000',
      volume: '1052452.7866600000000000',
      quoteVolume: 624543865.9008446,
      btcVolume: 32794.329183490656,
      usdVolume: 624543865.9008446,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '567.2400000000000000',
      high: '597.1100000000000000',
      low: '560.8800000000000000',
      close: '596.8000000000000000',
      volume: '701073.3926200000000000',
      quoteVolume: 410088998.40737647,
      btcVolume: 21637.275162148107,
      usdVolume: 410088998.40737647,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '596.8200000000000000',
      high: '607.5000000000000000',
      low: '583.0000000000000000',
      close: '601.5400000000000000',
      volume: '602496.9633900000000000',
      quoteVolume: 359236400.29498786,
      btcVolume: 18762.06787882863,
      usdVolume: 359236400.29498786,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '601.5900000000000000',
      high: '603.1500000000000000',
      low: '584.2200000000000000',
      close: '591.2800000000000000',
      volume: '498329.3758900000000000',
      quoteVolume: 295598087.0279998,
      btcVolume: 15413.761647877274,
      usdVolume: 295598087.0279998,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '591.2700000000000000',
      high: '595.0000000000000000',
      low: '549.2200000000000000',
      close: '554.9400000000000000',
      volume: '804443.0071700000000000',
      quoteVolume: 461781203.16172385,
      btcVolume: 24513.068401678625,
      usdVolume: 461781203.16172385,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '554.9200000000000000',
      high: '578.0800000000000000',
      low: '530.4300000000000000',
      close: '573.0900000000000000',
      volume: '997191.6484000000000000',
      quoteVolume: 555141876.6276906,
      btcVolume: 30469.950793735137,
      usdVolume: 555141876.6276906,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '573.2000000000000000',
      high: '575.7000000000000000',
      low: '548.1100000000000000',
      close: '558.9900000000000000',
      volume: '607169.0047400000000000',
      quoteVolume: 340648675.9975368,
      btcVolume: 18641.974867746296,
      usdVolume: 340648675.9975368,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '558.9900000000000000',
      high: '560.8000000000000000',
      low: '535.5000000000000000',
      close: '544.4300000000000000',
      volume: '747763.1198900000000000',
      quoteVolume: 408047285.092406,
      btcVolume: 22758.283163314973,
      usdVolume: 407979347.94980955,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '544.4600000000000000',
      high: '574.0000000000000000',
      low: '543.1000000000000000',
      close: '568.0400000000000000',
      volume: '539225.5604100000000000',
      quoteVolume: 301525321.23763144,
      btcVolume: 16307.87218266341,
      usdVolume: 301525321.23763144,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '568.0900000000000000',
      high: '595.0000000000000000',
      low: '563.2200000000000000',
      close: '590.7000000000000000',
      volume: '657794.4615800000000000',
      quoteVolume: 383072560.34673756,
      btcVolume: 19983.985401772963,
      usdVolume: 383072560.34673756,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '590.8100000000000000',
      high: '591.4800000000000000',
      low: '575.6000000000000000',
      close: '586.2900000000000000',
      volume: '492918.9449700000000000',
      quoteVolume: 288056061.22263926,
      btcVolume: 15035.10093618048,
      usdVolume: 288056061.22263926,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '586.2900000000000000',
      high: '597.2000000000000000',
      low: '578.5800000000000000',
      close: '588.6400000000000000',
      volume: '551569.0579400000000000',
      quoteVolume: 324168681.67163837,
      btcVolume: 16753.55977616931,
      usdVolume: 324168681.67163837,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '588.7800000000000000',
      high: '640.0000000000000000',
      low: '580.7100000000000000',
      close: '637.1800000000000000',
      volume: '1144619.8239400000000000',
      quoteVolume: 700180315.3934683,
      btcVolume: 34363.64123742975,
      usdVolume: 700180315.3934683,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '637.1800000000000000',
      high: '676.2500000000000000',
      low: '625.7600000000000000',
      close: '642.7000000000000000',
      volume: '2006130.0197500000000000',
      quoteVolume: 1307445033.1218147,
      btcVolume: 57721.48977556455,
      usdVolume: 1307445033.1218147,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '642.7100000000000000',
      high: '664.6100000000000000',
      low: '629.4000000000000000',
      close: '653.8500000000000000',
      volume: '1000012.9801800000000000',
      quoteVolume: 646217791.229689,
      btcVolume: 28267.30155209629,
      usdVolume: 646217791.229689,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '653.7600000000000000',
      high: '669.9500000000000000',
      low: '645.4200000000000000',
      close: '658.0300000000000000',
      volume: '858803.8845900000000000',
      quoteVolume: 564029933.2588553,
      btcVolume: 24101.66087667955,
      usdVolume: 564029933.2588553,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '658.0500000000000000',
      high: '661.3900000000000000',
      low: '621.0100000000000000',
      close: '638.1800000000000000',
      volume: '823958.2284300000000000',
      quoteVolume: 533109285.6519513,
      btcVolume: 22604.403561175943,
      usdVolume: 533109285.6519513,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '638.1000000000000000',
      high: '648.0000000000000000',
      low: '595.9000000000000000',
      close: '607.5800000000000000',
      volume: '834470.3823100000000000',
      quoteVolume: 518855748.82504433,
      btcVolume: 22402.25118531381,
      usdVolume: 518855748.82504433,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '607.6800000000000000',
      high: '637.7300000000000000',
      low: '588.0000000000000000',
      close: '636.4500000000000000',
      volume: '918851.2565400000000000',
      quoteVolume: 563872004.6334975,
      btcVolume: 24418.03638657798,
      usdVolume: 563872004.6334975,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '636.4100000000000000',
      high: '638.4400000000000000',
      low: '551.2800000000000000',
      close: '585.5600000000000000',
      volume: '1208807.3651800000000000',
      quoteVolume: 733443630.3656094,
      btcVolume: 31260.2759405341,
      usdVolume: 733443630.3656094,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '585.4800000000000000',
      high: '615.0200000000000000',
      low: '565.4300000000000000',
      close: '612.3100000000000000',
      volume: '886335.8633300000000000',
      quoteVolume: 519843556.98166037,
      btcVolume: 22398.590221377046,
      usdVolume: 519843556.98166037,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '612.3100000000000000',
      high: '634.2400000000000000',
      low: '604.2800000000000000',
      close: '626.8200000000000000',
      volume: '797946.9485900000000000',
      quoteVolume: 494050300.1567907,
      btcVolume: 20522.31724411632,
      usdVolume: 494050300.1567907,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '626.7800000000000000',
      high: '652.9100000000000000',
      low: '615.2600000000000000',
      close: '637.4400000000000000',
      volume: '958585.5064700000000000',
      quoteVolume: 607664782.0109019,
      btcVolume: 23891.58404704324,
      usdVolume: 607664782.0109019,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '637.4400000000000000',
      high: '717.1300000000000000',
      low: '625.0000000000000000',
      close: '685.1100000000000000',
      volume: '1859968.1502900000000000',
      quoteVolume: 1247547956.8239481,
      btcVolume: 46008.51410433634,
      usdVolume: 1247547956.8239481,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '685.1000000000000000',
      high: '748.0900000000000000',
      low: '681.0400000000000000',
      close: '730.4100000000000000',
      volume: '1627123.9175900000000000',
      quoteVolume: 1180415994.5896938,
      btcVolume: 43771.67271543014,
      usdVolume: 1180415994.5896938,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '730.4000000000000000',
      high: '740.7800000000000000',
      low: '689.2000000000000000',
      close: '732.0000000000000000',
      volume: '1106876.3585500000000000',
      quoteVolume: 794643540.4901432,
      btcVolume: 29803.913314660324,
      usdVolume: 794643540.4901432,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '732.0000000000000000',
      high: '758.7400000000000000',
      low: '716.6200000000000000',
      close: '752.1700000000000000',
      volume: '1008573.9629400000000000',
      quoteVolume: 743546576.743025,
      btcVolume: 26409.000752216456,
      usdVolume: 743546576.743025,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '752.1700000000000000',
      high: '755.7400000000000000',
      low: '722.0000000000000000',
      close: '736.4200000000000000',
      volume: '863375.5798100000000000',
      quoteVolume: 638503822.3940647,
      btcVolume: 22178.91912646232,
      usdVolume: 638503822.3940647,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '736.4200000000000000',
      high: '749.0000000000000000',
      low: '714.2900000000000000',
      close: '728.9100000000000000',
      volume: '674648.6773000000000000',
      quoteVolume: 495736382.4405749,
      btcVolume: 16984.616127141537,
      usdVolume: 495736382.4405749,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '728.9100000000000000',
      high: '787.6900000000000000',
      low: '714.9100000000000000',
      close: '774.5600000000000000',
      volume: '1352618.5766800000000000',
      quoteVolume: 1024472182.6120937,
      btcVolume: 32889.04989161244,
      usdVolume: 1024472182.6120937,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '774.4400000000000000',
      high: '1011.0700000000000000',
      low: '768.7100000000000000',
      close: '978.2800000000000000',
      volume: '2813603.8861500000000000',
      quoteVolume: 2522411859.731612,
      btcVolume: 75351.3954511743,
      usdVolume: 2522411859.731612,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '978.3300000000000000',
      high: '1162.9700000000000000',
      low: '890.0000000000000000',
      close: '1041.4300000000000000',
      volume: '4245009.5910400000000000',
      quoteVolume: 4303632891.8297615,
      btcVolume: 135522.0321379406,
      usdVolume: 4303632891.8297615,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '1041.4500000000000000',
      high: '1134.6000000000000000',
      low: '974.4500000000000000',
      close: '1099.5600000000000000',
      volume: '2706995.6752500000000000',
      quoteVolume: 2857050280.008042,
      btcVolume: 88801.0479132276,
      usdVolume: 2857050280.008042,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '1100.1600000000000000',
      high: '1213.0000000000000000',
      low: '1057.2600000000000000',
      close: '1208.4200000000000000',
      volume: '2390134.2561700000000000',
      quoteVolume: 2725259321.0546975,
      btcVolume: 78230.68111796751,
      usdVolume: 2725259321.0546975,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '1208.7500000000000000',
      high: '1289.0000000000000000',
      low: '1131.0000000000000000',
      close: '1224.8700000000000000',
      volume: '1972917.8883400000000000',
      quoteVolume: 2406213991.6994786,
      btcVolume: 63090.38541215102,
      usdVolume: 2406213991.6994786,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '1224.8600000000000000',
      high: '1273.7500000000000000',
      low: '1065.0000000000000000',
      close: '1216.9300000000000000',
      volume: '2130161.3642600000000000',
      quoteVolume: 2542192047.8181143,
      btcVolume: 64132.46310834457,
      usdVolume: 2542192047.8181143,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '1216.7200000000000000',
      high: '1304.3700000000000000',
      low: '1171.3600000000000000',
      close: '1276.0000000000000000',
      volume: '1448871.1151400000000000',
      quoteVolume: 1780137376.416452,
      btcVolume: 44126.55053631966,
      usdVolume: 1780137376.416452,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '1276.0000000000000000',
      high: '1348.3300000000000000',
      low: '1170.0000000000000000',
      close: '1254.2500000000000000',
      volume: '1951179.3500300000000000',
      quoteVolume: 2517791852.4078064,
      btcVolume: 64117.4902690094,
      usdVolume: 2517791852.4078064,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '1254.2400000000000000',
      high: '1260.0000000000000000',
      low: '915.0000000000000000',
      close: '1087.0100000000000000',
      volume: '4054496.9340000000000000',
      quoteVolume: 4277939199.1682744,
      btcVolume: 126094.41321785371,
      usdVolume: 4277939199.1682744,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '1087.0000000000000000',
      high: '1150.0200000000000000',
      low: '1006.3300000000000000',
      close: '1050.1600000000000000',
      volume: '2173756.2488300000000000',
      quoteVolume: 2351659986.680197,
      btcVolume: 67607.46412545761,
      usdVolume: 2351659986.680197,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '1050.3600000000000000',
      high: '1137.6500000000000000',
      low: '987.4800000000000000',
      close: '1129.8900000000000000',
      volume: '1546028.4733400000000000',
      quoteVolume: 1645548180.1396663,
      btcVolume: 47601.494850955794,
      usdVolume: 1645548180.1396663,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '1129.7900000000000000',
      high: '1247.5500000000000000',
      low: '1086.7000000000000000',
      close: '1231.6200000000000000',
      volume: '1555737.3855600000000000',
      quoteVolume: 1825860914.1737947,
      btcVolume: 47277.3001514479,
      usdVolume: 1825860914.1737947,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '1231.8900000000000000',
      high: '1256.9800000000000000',
      low: '1069.4200000000000000',
      close: '1168.5300000000000000',
      volume: '1854933.6970800000000000',
      quoteVolume: 2199247860.325725,
      btcVolume: 59240.27896315071,
      usdVolume: 2199247860.325725,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '1168.4800000000000000',
      high: '1292.6600000000000000',
      low: '1151.7700000000000000',
      close: '1227.8300000000000000',
      volume: '1515434.5202100000000000',
      quoteVolume: 1854150543.260001,
      btcVolume: 50229.12509310558,
      usdVolume: 1854150543.260001,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '1227.8300000000000000',
      high: '1269.0200000000000000',
      low: '1165.0000000000000000',
      close: '1232.4500000000000000',
      volume: '1317285.0057900000000000',
      quoteVolume: 1606424364.4970233,
      btcVolume: 45243.78505901359,
      usdVolume: 1606424364.4970233,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '1232.5000000000000000',
      high: '1260.7500000000000000',
      low: '1182.5800000000000000',
      close: '1258.4500000000000000',
      volume: '998004.1263100000000000',
      quoteVolume: 1223572330.470765,
      btcVolume: 33926.362340998414,
      usdVolume: 1223572330.470765,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '1258.5200000000000000',
      high: '1438.3000000000000000',
      low: '1251.4900000000000000',
      close: '1365.3600000000000000',
      volume: '2277189.7942800000000000',
      quoteVolume: 3118645648.601239,
      btcVolume: 84576.84836448962,
      usdVolume: 3118645648.601239,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '1365.3600000000000000',
      high: '1407.9300000000000000',
      low: '1235.4200000000000000',
      close: '1376.9900000000000000',
      volume: '2217969.4846900000000000',
      quoteVolume: 2930015788.0453196,
      btcVolume: 83805.54654902022,
      usdVolume: 2930015788.0453196,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '1376.7200000000000000',
      high: '1390.0000000000000000',
      low: '1086.0000000000000000',
      close: '1110.9600000000000000',
      volume: '2310208.5729800000000000',
      quoteVolume: 2857288905.5771556,
      btcVolume: 87560.1065502035,
      usdVolume: 2857288905.5771556,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '1111.9800000000000000',
      high: '1273.7900000000000000',
      low: '1042.3100000000000000',
      close: '1232.6200000000000000',
      volume: '2005642.5528900000000000',
      quoteVolume: 2341609368.9179225,
      btcVolume: 74708.67997123068,
      usdVolume: 2341609368.9179225,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '1232.6100000000000000',
      high: '1272.4400000000000000',
      low: '1195.9000000000000000',
      close: '1233.6000000000000000',
      volume: '915290.0468900000000000',
      quoteVolume: 1134112575.401647,
      btcVolume: 35109.53478031294,
      usdVolume: 1134112575.401647,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '1233.4600000000000000',
      high: '1398.8300000000000000',
      low: '1220.0000000000000000',
      close: '1392.5100000000000000',
      volume: '1510879.5381700000000000',
      quoteVolume: 1996765627.5405061,
      btcVolume: 61983.71959418937,
      usdVolume: 1996765627.5405061,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '1392.1900000000000000',
      high: '1475.0000000000000000',
      low: '1311.0200000000000000',
      close: '1347.8800000000000000',
      volume: '1715392.6717800000000000',
      quoteVolume: 2419347040.465905,
      btcVolume: 72644.26574669621,
      usdVolume: 2419347040.465905,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '1289.8900000000000000',
      high: '1374.2200000000000000',
      low: '1288.0000000000000000',
      close: '1365.9700000000000000',
      volume: '474907.7533300000000000',
      quoteVolume: 634395015.3148867,
      btcVolume: 19710.204623958827,
      usdVolume: 634395015.3148867,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '1366.5400000000000000',
      high: '1373.9000000000000000',
      low: '1207.0000000000000000',
      close: '1239.0300000000000000',
      volume: '1913340.1664600000000000',
      quoteVolume: 2432623351.210675,
      btcVolume: 78753.05627142359,
      usdVolume: 2432623351.210675,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '1238.9300000000000000',
      high: '1360.0000000000000000',
      low: '1217.2300000000000000',
      close: '1329.6900000000000000',
      volume: '1285582.1498600000000000',
      quoteVolume: 1685996087.100964,
      btcVolume: 53145.032890713526,
      usdVolume: 1685996087.100964,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '1329.4400000000000000',
      high: '1436.9000000000000000',
      low: '1285.3500000000000000',
      close: '1379.5000000000000000',
      volume: '2111692.2313000000000000',
      quoteVolume: 2883767792.77202,
      btcVolume: 81514.055974949,
      usdVolume: 2883767792.77202,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '1379.5600000000000000',
      high: '1406.0000000000000000',
      low: '1326.4100000000000000',
      close: '1378.0500000000000000',
      volume: '1017334.1650900000000000',
      quoteVolume: 1383293203.399458,
      btcVolume: 40669.810913327645,
      usdVolume: 1383293203.399458,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '1378.0400000000000000',
      high: '1380.7400000000000000',
      low: '1281.1200000000000000',
      close: '1312.5500000000000000',
      volume: '913951.9568300000000000',
      quoteVolume: 1217085727.1929095,
      btcVolume: 36537.581286708024,
      usdVolume: 1217085727.1929095,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '1312.4500000000000000',
      high: '1377.9900000000000000',
      low: '1269.9900000000000000',
      close: '1374.1700000000000000',
      volume: '1080698.2129100000000000',
      quoteVolume: 1429023779.126755,
      btcVolume: 42479.33622274833,
      usdVolume: 1429023779.126755,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '1374.0900000000000000',
      high: '1547.9000000000000000',
      low: '1360.2800000000000000',
      close: '1511.8700000000000000',
      volume: '1943744.1966000000000000',
      quoteVolume: 2820899589.0203815,
      btcVolume: 81013.11891040324,
      usdVolume: 2820899589.0203815,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '1511.9300000000000000',
      high: '1669.3500000000000000',
      low: '1508.3900000000000000',
      close: '1664.4900000000000000',
      volume: '1535869.8880100000000000',
      quoteVolume: 2418887041.5875654,
      btcVolume: 66156.27367384409,
      usdVolume: 2418887041.5875654,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '1664.6700000000000000',
      high: '1695.2000000000000000',
      low: '1554.4400000000000000',
      close: '1595.6700000000000000',
      volume: '1646502.0866300000000000',
      quoteVolume: 2693099202.271006,
      btcVolume: 72046.17314645574,
      usdVolume: 2693099202.271006,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '1595.5300000000000000',
      high: '1760.4900000000000000',
      low: '1591.0000000000000000',
      close: '1719.1900000000000000',
      volume: '1193485.8324200000000000',
      quoteVolume: 2010622894.1443644,
      btcVolume: 53367.070155870046,
      usdVolume: 2010622894.1443644,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '1718.9500000000000000',
      high: '1743.6700000000000000',
      low: '1642.9400000000000000',
      close: '1676.3500000000000000',
      volume: '1149726.0674300000000000',
      quoteVolume: 1939917120.7056394,
      btcVolume: 48912.4046267318,
      usdVolume: 1939917120.7056394,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '1676.3500000000000000',
      high: '1693.0000000000000000',
      low: '1490.4600000000000000',
      close: '1611.6200000000000000',
      volume: '1281734.8681400000000000',
      quoteVolume: 2044371589.0232632,
      btcVolume: 53081.3239508303,
      usdVolume: 2044371589.0232632,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '1611.4600000000000000',
      high: '1775.5700000000000000',
      low: '1563.8900000000000000',
      close: '1750.1500000000000000',
      volume: '1339777.0953600000000000',
      quoteVolume: 2255426926.0200105,
      btcVolume: 53848.27559053579,
      usdVolume: 2255426926.0200105,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '1749.6800000000000000',
      high: '1821.8800000000000000',
      low: '1708.4900000000000000',
      close: '1769.1300000000000000',
      volume: '1185071.8959300000000000',
      quoteVolume: 2080818891.6989098,
      btcVolume: 44595.047468861056,
      usdVolume: 2080818891.6989098,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '1769.1400000000000000',
      high: '1837.8600000000000000',
      low: '1680.0000000000000000',
      close: '1740.7800000000000000',
      volume: '1219932.2547400000000000',
      quoteVolume: 2149076144.4077,
      btcVolume: 47044.18686396017,
      usdVolume: 2148911012.185441,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '1740.7600000000000000',
      high: '1819.0000000000000000',
      low: '0.0000163270000000',
      close: '1785.6200000000000000',
      volume: '734621.0468700000000000',
      quoteVolume: 1300810400.6745741,
      btcVolume: 27978.80513237656,
      usdVolume: 1300810421.239668,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '1785.8300000000000000',
      high: '1864.8800000000000000',
      low: '1739.8800000000000000',
      close: '1840.2600000000000000',
      volume: '909354.4636700000000000',
      quoteVolume: 1634996984.4396915,
      btcVolume: 34411.479778793124,
      usdVolume: 1634776311.9280682,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '1840.2400000000000000',
      high: '1871.7200000000000000',
      low: '1765.0000000000000000',
      close: '1814.9600000000000000',
      volume: '767588.7814400000000000',
      quoteVolume: 1395593560.9624188,
      btcVolume: 29584.45599424286,
      usdVolume: 1395437080.521789,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '1815.2500000000000000',
      high: '1850.0000000000000000',
      low: '1784.1100000000000000',
      close: '1800.4200000000000000',
      volume: '653541.2701500000000000',
      quoteVolume: 1190593797.9381444,
      btcVolume: 24535.71966972843,
      usdVolume: 1190593797.9381444,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '1800.0300000000000000',
      high: '1835.7200000000000000',
      low: '1655.6700000000000000',
      close: '1779.2500000000000000',
      volume: '1049314.2533700000000000',
      quoteVolume: 1852907004.6686351,
      btcVolume: 38765.91600274143,
      usdVolume: 1846625373.4324832,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '1779.3300000000000000',
      high: '1827.2800000000000000',
      low: '1724.0000000000000000',
      close: '1781.9900000000000000',
      volume: '816519.6687500000000000',
      quoteVolume: 1454153395.7932453,
      btcVolume: 29364.208274199304,
      usdVolume: 1434518186.7829216,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '1781.9900000000000000',
      high: '1855.0000000000000000',
      low: '1733.0100000000000000',
      close: '1849.8800000000000000',
      volume: '784619.1244400000000000',
      quoteVolume: 1417932396.1485229,
      btcVolume: 27781.649794336336,
      usdVolume: 1417932396.1485229,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '1849.7000000000000000',
      high: '1950.5000000000000000',
      low: '1849.6900000000000000',
      close: '1939.6100000000000000',
      volume: '937227.1019100000000000',
      quoteVolume: 1787383532.1023593,
      btcVolume: 34457.66137137417,
      usdVolume: 1787383532.1023593,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '1938.9100000000000000',
      high: '1972.8200000000000000',
      low: '1891.0000000000000000',
      close: '1955.0000000000000000',
      volume: '793166.6203700000000000',
      quoteVolume: 1533528111.7198534,
      btcVolume: 28954.450074244185,
      usdVolume: 1533528111.7198534,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '1955.4600000000000000',
      high: '2042.3400000000000000',
      low: '1817.4000000000000000',
      close: '1913.0000000000000000',
      volume: '1231380.2653200000000000',
      quoteVolume: 2438742761.044716,
      btcVolume: 43461.22491246883,
      usdVolume: 2438737210.8991766,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '1912.9000000000000000',
      high: '1976.3100000000000000',
      low: '1883.0100000000000000',
      close: '1933.5300000000000000',
      volume: '756568.1974300000000000',
      quoteVolume: 1467970937.285071,
      btcVolume: 25788.46682354838,
      usdVolume: 1467970937.285071,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '1933.5400000000000000',
      high: '1936.2900000000000000',
      low: '1555.0000000000000000',
      close: '1777.5800000000000000',
      volume: '1544855.9559000000000000',
      quoteVolume: 2744964206.143231,
      btcVolume: 48368.6255478767,
      usdVolume: 2601427551.846227,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '1777.4200000000000000',
      high: '1781.9900000000000000',
      low: '1371.6000000000000000',
      close: '1577.8900000000000000',
      volume: '2349675.3107900000000000',
      quoteVolume: 3640290942.4959636,
      btcVolume: 74416.81196595635,
      usdVolume: 3622396228.338403,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '1577.7900000000000000',
      high: '1712.7000000000000000',
      low: '1500.2000000000000000',
      close: '1623.3900000000000000',
      volume: '998796.1130000000000000',
      quoteVolume: 1631162134.0796974,
      btcVolume: 32698.532812710335,
      usdVolume: 1631047215.6908228,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '1623.1600000000000000',
      high: '1671.5900000000000000',
      low: '1459.1600000000000000',
      close: '1481.7500000000000000',
      volume: '1032670.7940900000000000',
      quoteVolume: 1650582276.8718207,
      btcVolume: 33117.338134786136,
      usdVolume: 1650582276.8718207,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '1482.0800000000000000',
      high: '1562.2500000000000000',
      low: '1400.0000000000000000',
      close: '1444.5900000000000000',
      volume: '1355973.2937800000000000',
      quoteVolume: 2004016547.1112273,
      btcVolume: 43035.34655306483,
      usdVolume: 2003245444.1310456,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '1444.5600000000000000',
      high: '1529.0000000000000000',
      low: '1425.0000000000000000',
      close: '1458.1300000000000000',
      volume: '927423.0475000000000000',
      quoteVolume: 1375946816.3325465,
      btcVolume: 29233.007119435944,
      usdVolume: 1375946816.3325465,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '1458.2700000000000000',
      high: '1468.7500000000000000',
      low: '1293.1800000000000000',
      close: '1419.1800000000000000',
      volume: '1394039.1589300000000000',
      quoteVolume: 1906652845.303296,
      btcVolume: 42805.29027654558,
      usdVolume: 1906652845.303296,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '1418.6700000000000000',
      high: '1572.0000000000000000',
      low: '1409.9100000000000000',
      close: '1569.8600000000000000',
      volume: '1071988.8959200000000000',
      quoteVolume: 1609708358.4012525,
      btcVolume: 33760.292992242015,
      usdVolume: 1609708358.4012525,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '1570.0000000000000000',
      high: '1602.9000000000000000',
      low: '1455.5200000000000000',
      close: '1486.7800000000000000',
      volume: '1061142.3874300000000000',
      quoteVolume: 1627312282.6235447,
      btcVolume: 33587.44590463653,
      usdVolume: 1627312282.6235447,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '1486.7900000000000000',
      high: '1656.9400000000000000',
      low: '1475.4700000000000000',
      close: '1567.6900000000000000',
      volume: '1054045.4787500000000000',
      quoteVolume: 1674981197.4400375,
      btcVolume: 33038.92359702907,
      usdVolume: 1674981197.4400375,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '1567.6800000000000000',
      high: '1624.8700000000000000',
      low: '1506.3600000000000000',
      close: '1539.2300000000000000',
      volume: '946365.6708800000000000',
      quoteVolume: 1476463517.9864302,
      btcVolume: 29907.939970665844,
      usdVolume: 1476144246.8024573,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '1539.2200000000000000',
      high: '1548.9600000000000000',
      low: '1441.6600000000000000',
      close: '1528.3100000000000000',
      volume: '910786.2897600000000000',
      quoteVolume: 1355798089.2726953,
      btcVolume: 28468.307956482684,
      usdVolume: 1355798089.2726953,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '1528.0800000000000000',
      high: '1671.5800000000000000',
      low: '1513.0300000000000000',
      close: '1650.3500000000000000',
      volume: '990313.5575500000000000',
      quoteVolume: 1575968246.2858746,
      btcVolume: 32484.06849446943,
      usdVolume: 1569880633.498245,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '1650.6800000000000000',
      high: '1734.1600000000000000',
      low: '1630.3500000000000000',
      close: '1726.1600000000000000',
      volume: '848470.7813600000000000',
      quoteVolume: 1414988218.19356,
      btcVolume: 28184.765635712247,
      usdVolume: 1414988218.19356,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '1726.4600000000000000',
      high: '1843.5900000000000000',
      low: '1664.9600000000000000',
      close: '1833.1800000000000000',
      volume: '1134793.9935500000000000',
      quoteVolume: 1968559430.9578898,
      btcVolume: 38787.24460770227,
      usdVolume: 1968559430.9578898,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '1833.2200000000000000',
      high: '1870.7700000000000000',
      low: '1798.2000000000000000',
      close: '1870.5100000000000000',
      volume: '813029.3448600000000000',
      quoteVolume: 1489269737.9822018,
      btcVolume: 27646.61354923279,
      usdVolume: 1489269737.9822018,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '1870.5000000000000000',
      high: '1877.6900000000000000',
      low: '1757.0000000000000000',
      close: '1795.1200000000000000',
      volume: '901824.9905900000000000',
      quoteVolume: 1641659203.449609,
      btcVolume: 29670.607882495333,
      usdVolume: 1641510306.9788375,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '1795.2800000000000000',
      high: '1846.7000000000000000',
      low: '1725.0000000000000000',
      close: '1825.7800000000000000',
      volume: '909011.3136100000000000',
      quoteVolume: 1627027140.3418167,
      btcVolume: 28930.654359520144,
      usdVolume: 1627027140.3418167,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '1825.7800000000000000',
      high: '1841.8800000000000000',
      low: '1719.7200000000000000',
      close: '1771.4800000000000000',
      volume: '840060.3990300000000000',
      quoteVolume: 1490608510.5170076,
      btcVolume: 19454.286038851555,
      usdVolume: 1105911678.621963,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '1759.7200000000000000',
      high: '1944.3000000000000000',
      low: '1727.7500000000000000',
      close: '1922.3500000000000000',
      volume: '921478.4547800000000000',
      quoteVolume: 1710098953.5524776,
      btcVolume: 28839.313150162354,
      usdVolume: 1710098953.5524776,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '1922.3500000000000000',
      high: '1933.1000000000000000',
      low: '1836.0000000000000000',
      close: '1848.1000000000000000',
      volume: '637140.4717400000000000',
      quoteVolume: 1198818913.9901521,
      btcVolume: 19816.886952228757,
      usdVolume: 1197320013.2192478,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '1848.1000000000000000',
      high: '1891.7600000000000000',
      low: '1730.0000000000000000',
      close: '1793.4800000000000000',
      volume: '1025326.7965800000000000',
      quoteVolume: 1842608872.7630134,
      btcVolume: 32173.17842836311,
      usdVolume: 1842608872.7630134,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '1793.9900000000000000',
      high: '1819.5000000000000000',
      low: '1711.6800000000000000',
      close: '1805.1200000000000000',
      volume: '745047.8840800000000000',
      quoteVolume: 1324386049.5584662,
      btcVolume: 23990.615019311717,
      usdVolume: 1323585091.7132356,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '1805.0100000000000000',
      high: '1838.8800000000000000',
      low: '1741.1800000000000000',
      close: '1823.5800000000000000',
      volume: '654844.3962200000000000',
      quoteVolume: 1171393579.333251,
      btcVolume: 20836.313439670008,
      usdVolume: 1171393579.333251,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '1823.5800000000000000',
      high: '1850.0000000000000000',
      low: '1759.0400000000000000',
      close: '1776.0800000000000000',
      volume: '603721.6105600000000000',
      quoteVolume: 1092346826.5537026,
      btcVolume: 18665.018047687696,
      usdVolume: 1092344736.4815433,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '1775.9200000000000000',
      high: '1840.6100000000000000',
      low: '1733.4700000000000000',
      close: '1809.1000000000000000',
      volume: '485394.8323500000000000',
      quoteVolume: 875146682.91855,
      btcVolume: 14962.1323122463,
      usdVolume: 871822690.956532,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '1809.0100000000000000',
      high: '1868.8300000000000000',
      low: '1800.8600000000000000',
      close: '1806.1000000000000000',
      volume: '423872.9248500000000000',
      quoteVolume: 777603559.92763,
      btcVolume: 13225.478131358275,
      usdVolume: 777585802.0887206,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '1806.5700000000000000',
      high: '1817.1600000000000000',
      low: '1747.0000000000000000',
      close: '1782.9800000000000000',
      volume: '465300.7587400000000000',
      quoteVolume: 829784481.8129778,
      btcVolume: 14483.824726332654,
      usdVolume: 828756040.3627473,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '1783.2300000000000000',
      high: '1807.5100000000000000',
      low: '1655.1500000000000000',
      close: '1680.9700000000000000',
      volume: '582614.8017100000000000',
      quoteVolume: 1019579063.1709024,
      btcVolume: 18102.374296305938,
      usdVolume: 1019512873.3647406,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '1681.1700000000000000',
      high: '1721.1700000000000000',
      low: '1650.0000000000000000',
      close: '1668.0800000000000000',
      volume: '574333.9664000000000000',
      quoteVolume: 970709518.4563361,
      btcVolume: 12089.460579743516,
      usdVolume: 658329851.7034984,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '1668.2000000000000000',
      high: '1740.8600000000000000',
      low: '1536.9200000000000000',
      close: '1581.8400000000000000',
      volume: '712606.6818800000000000',
      quoteVolume: 1184354983.115622,
      btcVolume: 21604.55412650087,
      usdVolume: 1184354983.115622,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '1581.8800000000000000',
      high: '1622.0000000000000000',
      low: '1549.4000000000000000',
      close: '1585.7400000000000000',
      volume: '637200.0501600000000000',
      quoteVolume: 1012804696.3799217,
      btcVolume: 17272.44309048303,
      usdVolume: 897465027.6504521,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '1585.8300000000000000',
      high: '1699.9300000000000000',
      low: '1585.7900000000000000',
      close: '1698.8400000000000000',
      volume: '442973.6271000000000000',
      quoteVolume: 727250279.6639122,
      btcVolume: 13128.883505516924,
      usdVolume: 700660186.6217037,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '1698.8400000000000000',
      high: '1732.6700000000000000',
      low: '1666.4100000000000000',
      close: '1712.6300000000000000',
      volume: '443845.4995700000000000',
      quoteVolume: 753555521.6446587,
      btcVolume: 13655.180403119155,
      usdVolume: 753442291.0866222,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '1712.5200000000000000',
      high: '1725.6600000000000000',
      low: '1661.4000000000000000',
      close: '1687.0100000000000000',
      volume: '348895.5131400000000000',
      quoteVolume: 592836644.5993483,
      btcVolume: 10623.566952815665,
      usdVolume: 592744065.8884122,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '1687.1600000000000000',
      high: '1841.0000000000000000',
      low: '1677.8500000000000000',
      close: '1816.7400000000000000',
      volume: '645153.9473800000000000',
      quoteVolume: 1143708814.718205,
      btcVolume: 19952.416250138947,
      usdVolume: 1143593696.951361,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '1816.7600000000000000',
      high: '1859.1300000000000000',
      low: '1786.3000000000000000',
      close: '1840.4600000000000000',
      volume: '568842.0052600000000000',
      quoteVolume: 1039262033.3867666,
      btcVolume: 17794.857969149907,
      usdVolume: 1039257506.3019704,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '1840.4100000000000000',
      high: '1947.2900000000000000',
      low: '1768.1500000000000000',
      close: '1919.3700000000000000',
      volume: '746995.8591000000000000',
      quoteVolume: 1387448329.8618498,
      btcVolume: 23597.31023417654,
      usdVolume: 1385699357.8138206,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '1919.3700000000000000',
      high: '1984.7700000000000000',
      low: '1885.0200000000000000',
      close: '1968.0500000000000000',
      volume: '735243.4803000000000000',
      quoteVolume: 1426255182.7403693,
      btcVolume: 24205.415566070777,
      usdVolume: 1424192936.6523898,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '1968.0500000000000000',
      high: '2146.0000000000000000',
      low: '1948.4800000000000000',
      close: '2133.6900000000000000',
      volume: '836004.3995600000000000',
      quoteVolume: 1699509331.9186137,
      btcVolume: 28682.7732154343,
      usdVolume: 1699416511.4975188,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '2133.6900000000000000',
      high: '2138.1200000000000000',
      low: '1996.3000000000000000',
      close: '2008.5200000000000000',
      volume: '770009.0373200000000000',
      quoteVolume: 1601162397.6543598,
      btcVolume: 14889.771267495946,
      usdVolume: 877202202.2152315,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '2008.5900000000000000',
      high: '2091.8300000000000000',
      low: '1975.2400000000000000',
      close: '2075.6900000000000000',
      volume: '504446.3045400000000000',
      quoteVolume: 1030198911.5271093,
      btcVolume: 14552.052881946049,
      usdVolume: 839965464.8217052,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '2075.7000000000000000',
      high: '2130.6400000000000000',
      low: '2002.1500000000000000',
      close: '2107.3800000000000000',
      volume: '672715.8405800000000000',
      quoteVolume: 1393443640.6387079,
      btcVolume: 19837.173273893703,
      usdVolume: 1151438176.7157285,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '2107.4300000000000000',
      high: '2151.0000000000000000',
      low: '2044.6500000000000000',
      close: '2112.2100000000000000',
      volume: '646617.8868200000000000',
      quoteVolume: 1364425311.2743363,
      btcVolume: 21462.268704397782,
      usdVolume: 1253287568.3909323,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '2112.2200000000000000',
      high: '2129.0100000000000000',
      low: '1930.0100000000000000',
      close: '1963.4700000000000000',
      volume: '890590.5480600000000000',
      quoteVolume: 1793821185.7585125,
      btcVolume: 31085.880944942655,
      usdVolume: 1765504353.4110937,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '1963.4600000000000000',
      high: '2084.6100000000000000',
      low: '1947.3500000000000000',
      close: '2080.4600000000000000',
      volume: '492449.6518300000000000',
      quoteVolume: 998031729.5983349,
      btcVolume: 11760.316400533313,
      usdVolume: 671261695.401949,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '2080.3900000000000000',
      high: '2100.0000000000000000',
      low: '2047.5700000000000000',
      close: '2066.8700000000000000',
      volume: '410697.4165100000000000',
      quoteVolume: 852604897.8719946,
      btcVolume: 12983.009883018114,
      usdVolume: 756441273.5357431,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '2067.1900000000000000',
      high: '2200.0000000000000000',
      low: '2058.0000000000000000',
      close: '2133.4900000000000000',
      volume: '655458.2089400000000000',
      quoteVolume: 1405364881.858304,
      btcVolume: 23111.04520438008,
      usdVolume: 1384757109.84192,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '2133.4900000000000000',
      high: '2165.0200000000000000',
      low: '2113.4000000000000000',
      close: '2151.3600000000000000',
      volume: '392265.5077300000000000',
      quoteVolume: 839653140.9406079,
      btcVolume: 13957.221461420964,
      usdVolume: 834685291.3442482,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '2151.4200000000000000',
      high: '2203.4600000000000000',
      low: '2103.0500000000000000',
      close: '2137.6900000000000000',
      volume: '569303.1026900000000000',
      quoteVolume: 1224143619.588617,
      btcVolume: 16124.45725574653,
      usdVolume: 970101865.6152618,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '2137.8600000000000000',
      high: '2318.0000000000000000',
      low: '2135.5300000000000000',
      close: '2299.1900000000000000',
      volume: '753630.4481400000000000',
      quoteVolume: 1682124954.2545862,
      btcVolume: 26651.164261948637,
      usdVolume: 1663537493.65112,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '2299.1900000000000000',
      high: '2447.2900000000000000',
      low: '2281.3300000000000000',
      close: '2432.6000000000000000',
      volume: '962367.1999900000000000',
      quoteVolume: 2270110171.976935,
      btcVolume: 31771.533630725116,
      usdVolume: 2017446256.9322753,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '2432.5900000000000000',
      high: '2543.4700000000000000',
      low: '2400.0000000000000000',
      close: '2514.0400000000000000',
      volume: '681946.7520600000000000',
      quoteVolume: 1680052982.425424,
      btcVolume: 24649.296881737133,
      usdVolume: 1551057612.4665124,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '2514.0400000000000000',
      high: '2548.2900000000000000',
      low: '2300.0000000000000000',
      close: '2422.6700000000000000',
      volume: '999163.1245600000000000',
      quoteVolume: 2418565017.4451094,
      btcVolume: 37191.04906644725,
      usdVolume: 2288259291.5148406,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '2422.3800000000000000',
      high: '2495.0000000000000000',
      low: '2310.0000000000000000',
      close: '2317.6000000000000000',
      volume: '624232.2556800000000000',
      quoteVolume: 1504895144.9122174,
      btcVolume: 16884.468899235042,
      usdVolume: 1031965089.1388259,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '2317.6500000000000000',
      high: '2340.0000000000000000',
      low: '1950.0000000000000000',
      close: '2235.6400000000000000',
      volume: '1322276.0831600000000000',
      quoteVolume: 2866812093.0214953,
      btcVolume: 50861.605911477796,
      usdVolume: 2839032150.7239733,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '2235.6500000000000000',
      high: '2300.0000000000000000',
      low: '2080.0000000000000000',
      close: '2161.1200000000000000',
      volume: '820592.2655500000000000',
      quoteVolume: 1800575928.1074977,
      btcVolume: 26365.040134011742,
      usdVolume: 1481688446.6256678,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '2161.1200000000000000',
      high: '2346.6200000000000000',
      low: '2055.0000000000000000',
      close: '2330.0300000000000000',
      volume: '990682.9157700000000000',
      quoteVolume: 2177949495.5727634,
      btcVolume: 37918.97019892185,
      usdVolume: 2107334113.285447,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '2330.2600000000000000',
      high: '2468.0000000000000000',
      low: '2235.1600000000000000',
      close: '2357.0600000000000000',
      volume: '1113465.3921000000000000',
      quoteVolume: 2619975710.2322025,
      btcVolume: 40857.67918389536,
      usdVolume: 2269210586.199889,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '2357.0600000000000000',
      high: '2644.4700000000000000',
      low: '2303.4400000000000000',
      close: '2397.9400000000000000',
      volume: '1882176.3625200000000000',
      quoteVolume: 4670246227.172266,
      btcVolume: 25414.11693159991,
      usdVolume: 1354009041.8840244,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '2397.9400000000000000',
      high: '2442.8900000000000000',
      low: '2107.0000000000000000',
      close: '2367.3900000000000000',
      volume: '2392663.5084700000000000',
      quoteVolume: 5410016346.711445,
      btcVolume: 57263.218105779786,
      usdVolume: 2852064612.713971,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '2367.3900000000000000',
      high: '2368.7400000000000000',
      low: '2154.8000000000000000',
      close: '2213.3500000000000000',
      volume: '990513.3389000000000000',
      quoteVolume: 2230697502.0572195,
      btcVolume: 42345.682650564064,
      usdVolume: 2111057044.5906014,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '2214.0800000000000000',
      high: '2356.6400000000000000',
      low: '2168.0000000000000000',
      close: '2320.4900000000000000',
      volume: '817845.6182200000000000',
      quoteVolume: 1860725124.3291864,
      btcVolume: 20853.64183965011,
      usdVolume: 1033254138.8731536,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '2320.4400000000000000',
      high: '2541.8700000000000000',
      low: '2303.6400000000000000',
      close: '2532.6000000000000000',
      volume: '940704.4956200000000000',
      quoteVolume: 2314456522.3843803,
      btcVolume: 26103.489465891733,
      usdVolume: 1376208037.313604,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '2532.0600000000000000',
      high: '2680.0000000000000000',
      low: '2480.2900000000000000',
      close: '2666.1300000000000000',
      volume: '771728.4587600000000000',
      quoteVolume: 1995139009.247419,
      btcVolume: 36127.94470152542,
      usdVolume: 1973577408.7839944,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '2666.1300000000000000',
      high: '2760.0000000000000000',
      low: '2559.4400000000000000',
      close: '2748.2400000000000000',
      volume: '999383.1852000000000000',
      quoteVolume: 2673547154.2009354,
      btcVolume: 48837.864468014675,
      usdVolume: 2673547154.2009354,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '2748.2300000000000000',
      high: '2798.0000000000000000',
      low: '2668.0000000000000000',
      close: '2757.0400000000000000',
      volume: '859315.6791200000000000',
      quoteVolume: 2353486577.8108225,
      btcVolume: 43742.4160025124,
      usdVolume: 2353486577.8108225,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '2757.0400000000000000',
      high: '2798.3000000000000000',
      low: '2723.0900000000000000',
      close: '2772.4200000000000000',
      volume: '683253.7843800000000000',
      quoteVolume: 1883721206.1899638,
      btcVolume: 22122.659703772588,
      usdVolume: 1221913689.666882,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '2772.4200000000000000',
      high: '2954.2800000000000000',
      low: '2743.4100000000000000',
      close: '2944.7700000000000000',
      volume: '690260.0774100000000000',
      quoteVolume: 1979833188.065232,
      btcVolume: 16327.850363768606,
      usdVolume: 944162910.1777735,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '2944.8300000000000000',
      high: '2985.0000000000000000',
      low: '2852.2600000000000000',
      close: '2949.5600000000000000',
      volume: '571953.7712900000000000',
      quoteVolume: 1672227924.3766143,
      btcVolume: 19320.10153700959,
      usdVolume: 1096231381.3174038,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '2949.3300000000000000',
      high: '3454.0000000000000000',
      low: '2949.3200000000000000',
      close: '3431.0400000000000000',
      volume: '1329083.1120400000000000',
      quoteVolume: 4243771371.0916457,
      btcVolume: 71145.21913947008,
      usdVolume: 4116214448.7382607,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '3431.0400000000000000',
      high: '3527.0000000000000000',
      low: '3160.0000000000000000',
      close: '3239.0100000000000000',
      volume: '1797928.4935400000000000',
      quoteVolume: 6010359311.950286,
      btcVolume: 85671.24707619315,
      usdVolume: 4746803613.875628,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '3239.0100000000000000',
      high: '3549.3400000000000000',
      low: '3200.2300000000000000',
      close: '3524.2600000000000000',
      volume: '1094521.4393500000000000',
      quoteVolume: 3683966384.3414955,
      btcVolume: 60862.394706312116,
      usdVolume: 3399403885.2271895,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '3524.3300000000000000',
      high: '3605.4000000000000000',
      low: '3376.4700000000000000',
      close: '3489.7300000000000000',
      volume: '907396.3895700000000000',
      quoteVolume: 3167529015.808255,
      btcVolume: 54859.42355950186,
      usdVolume: 3118998835.779845,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '3489.7400000000000000',
      high: '3587.0000000000000000',
      low: '3353.2300000000000000',
      close: '3479.5300000000000000',
      volume: '760544.3177800000000000',
      quoteVolume: 2641739520.816758,
      btcVolume: 45671.46922983503,
      usdVolume: 2590518418.0204196,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '3479.7900000000000000',
      high: '3958.3300000000000000',
      low: '3449.2700000000000000',
      close: '3910.2600000000000000',
      volume: '1130467.5485200000000000',
      quoteVolume: 4170897763.922241,
      btcVolume: 70835.00620283352,
      usdVolume: 4147360439.8575544,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '3910.2600000000000000',
      high: '3983.6500000000000000',
      low: '3726.0800000000000000',
      close: '3923.9500000000000000',
      volume: '1000399.7612200000000000',
      quoteVolume: 3885034129.3254085,
      btcVolume: 66054.86727804251,
      usdVolume: 3823738678.629524,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '3923.9500000000000000',
      high: '4208.0000000000000000',
      low: '3693.4700000000000000',
      close: '3945.8800000000000000',
      volume: '1330445.0332100000000000',
      quoteVolume: 5397868145.894449,
      btcVolume: 66445.5379158468,
      usdVolume: 3801853644.3229055,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '3946.6300000000000000',
      high: '4180.2200000000000000',
      low: '3771.6400000000000000',
      close: '4173.4500000000000000',
      volume: '1071659.7833600000000000',
      quoteVolume: 4255907552.0657372,
      btcVolume: 59163.452670007915,
      usdVolume: 3302134688.3033047,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '4173.4600000000000000',
      high: '4372.7200000000000000',
      low: '3701.5800000000000000',
      close: '3887.7100000000000000',
      volume: '1589824.9292600000000000',
      quoteVolume: 6626411118.25824,
      btcVolume: 118376.00765287268,
      usdVolume: 6523763033.013671,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '3810.1500000000000000',
      high: '4034.7700000000000000',
      low: '3541.5100000000000000',
      close: '3717.9100000000000000',
      volume: '1849973.9261200000000000',
      quoteVolume: 7017701529.470531,
      btcVolume: 137733.97130057015,
      usdVolume: 6840474825.010459,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '3717.9200000000000000',
      high: '4173.2800000000000000',
      low: '3691.3500000000000000',
      close: '4075.0100000000000000',
      volume: '1152899.9027000000000000',
      quoteVolume: 4567552216.6355095,
      btcVolume: 88199.46408681228,
      usdVolume: 4430311894.504898,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '4075.0200000000000000',
      high: '4134.0500000000000000',
      low: '3632.0000000000000000',
      close: '3646.6800000000000000',
      volume: '1039174.3467800000000000',
      quoteVolume: 4030584420.9662867,
      btcVolume: 81888.4612645759,
      usdVolume: 3986942174.04559,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '3646.2400000000000000',
      high: '3879.0000000000000000',
      low: '3343.0600000000000000',
      close: '3582.1000000000000000',
      volume: '1322422.7023000000000000',
      quoteVolume: 4783072987.0008545,
      btcVolume: 99450.38052256861,
      usdVolume: 4678658584.545799,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '3581.8100000000000000',
      high: '3587.0600000000000000',
      low: '3102.0000000000000000',
      close: '3282.5100000000000000',
      volume: '1896852.2045000000000000',
      quoteVolume: 6376399013.426107,
      btcVolume: 141476.57089151486,
      usdVolume: 6236699464.627672,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '3282.2500000000000000',
      high: '3565.7100000000000000',
      low: '3240.0000000000000000',
      close: '3375.0700000000000000',
      volume: '1287105.1300500000000000',
      quoteVolume: 4392855117.159339,
      btcVolume: 98470.05761413471,
      usdVolume: 4341814936.084908,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '3375.0800000000000000',
      high: '3441.7300000000000000',
      low: '1900.0000000000000000',
      close: '2438.9200000000000000',
      volume: '2822906.7017300000000000',
      quoteVolume: 7943579809.10751,
      btcVolume: 169657.88253061575,
      usdVolume: 6636093341.830318,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '2438.9200000000000000',
      high: '2990.0500000000000000',
      low: '2155.0000000000000000',
      close: '2768.6200000000000000',
      volume: '2937686.1189100000000000',
      quoteVolume: 7738734836.44043,
      btcVolume: 162815.48666917285,
      usdVolume: 6411033639.910326,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '2767.8700000000000000',
      high: '2938.5400000000000000',
      low: '2101.0000000000000000',
      close: '2430.9800000000000000',
      volume: '2224854.1226900000000000',
      quoteVolume: 5663309143.626692,
      btcVolume: 119218.8275934102,
      usdVolume: 4583070212.396227,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '2430.9800000000000000',
      high: '2485.0000000000000000',
      low: '2145.0000000000000000',
      close: '2294.8700000000000000',
      volume: '1682579.1813100000000000',
      quoteVolume: 3930453187.1191373,
      btcVolume: 71381.46907218514,
      usdVolume: 2649594194.693011,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '2295.4500000000000000',
      high: '2381.5700000000000000',
      low: '1728.7400000000000000',
      close: '2096.3900000000000000',
      volume: '2914548.7312500000000000',
      quoteVolume: 5907260139.545083,
      btcVolume: 169607.22742003587,
      usdVolume: 5818960197.408998,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '2096.6300000000000000',
      high: '2675.0000000000000000',
      low: '2079.9400000000000000',
      close: '2648.4300000000000000',
      volume: '2184824.1622800000000000',
      quoteVolume: 5176143519.684482,
      btcVolume: 136880.6376374799,
      usdVolume: 5087206518.533662,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '2647.9200000000000000',
      high: '2750.0000000000000000',
      low: '2378.5400000000000000',
      close: '2705.1100000000000000',
      volume: '2115349.5044500000000000',
      quoteVolume: 5442737549.401353,
      btcVolume: 141033.426253863,
      usdVolume: 5351032818.276227,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '2705.1000000000000000',
      high: '2910.0000000000000000',
      low: '2643.0000000000000000',
      close: '2884.9400000000000000',
      volume: '1562515.8153800000000000',
      quoteVolume: 4356014953.228169,
      btcVolume: 109277.35501313985,
      usdVolume: 4286403436.2894278,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '2884.8200000000000000',
      high: '2889.7400000000000000',
      low: '2633.6800000000000000',
      close: '2742.2300000000000000',
      volume: '1099029.9120600000000000',
      quoteVolume: 3033719144.3402476,
      btcVolume: 77944.20663975782,
      usdVolume: 3015457564.549178,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '2742.2300000000000000',
      high: '2762.4500000000000000',
      low: '2327.3800000000000000',
      close: '2412.1000000000000000',
      volume: '1554584.6136200000000000',
      quoteVolume: 3934662309.0444574,
      btcVolume: 106412.09487045105,
      usdVolume: 3873392067.229942,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '2412.3400000000000000',
      high: '2574.2700000000000000',
      low: '2204.1000000000000000',
      close: '2278.7400000000000000',
      volume: '1379427.1885100000000000',
      quoteVolume: 3288804154.8317804,
      btcVolume: 92759.48942970896,
      usdVolume: 3255889564.014722,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '2278.7400000000000000',
      high: '2476.4200000000000000',
      low: '2180.5300000000000000',
      close: '2385.9800000000000000',
      volume: '1151113.1988300000000000',
      quoteVolume: 2726606138.6317267,
      btcVolume: 76968.91849705382,
      usdVolume: 2721338216.8446603,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '2385.8200000000000000',
      high: '2720.0000000000000000',
      low: '2271.4400000000000000',
      close: '2706.1500000000000000',
      volume: '1307559.4516500000000000',
      quoteVolume: 3276105181.236761,
      btcVolume: 90321.1015277825,
      usdVolume: 3271922711.64009,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '2706.1500000000000000',
      high: '2740.0000000000000000',
      low: '2523.7400000000000000',
      close: '2634.5700000000000000',
      volume: '1128194.7242100000000000',
      quoteVolume: 2948103721.112332,
      btcVolume: 79478.59488431703,
      usdVolume: 2909896222.812539,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '2634.3100000000000000',
      high: '2802.9900000000000000',
      low: '2550.0000000000000000',
      close: '2706.2200000000000000',
      volume: '942515.8424900000000000',
      quoteVolume: 2543367680.0125494,
      btcVolume: 67979.08133458497,
      usdVolume: 2536877829.1898575,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '2706.2100000000000000',
      high: '2891.9500000000000000',
      low: '2662.4000000000000000',
      close: '2857.2500000000000000',
      volume: '1020865.2499900000000000',
      quoteVolume: 2865136924.0086355,
      btcVolume: 74011.12317239847,
      usdVolume: 2859489401.1131005,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '2857.2500000000000000',
      high: '2860.0000000000000000',
      low: '2552.0000000000000000',
      close: '2689.5100000000000000',
      volume: '1128891.3342400000000000',
      quoteVolume: 3013427232.3647776,
      btcVolume: 79691.24087877397,
      usdVolume: 2948492978.646687,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '2689.5400000000000000',
      high: '2816.8700000000000000',
      low: '2552.2300000000000000',
      close: '2627.7300000000000000',
      volume: '961824.1241300000000000',
      quoteVolume: 2575726370.4308653,
      btcVolume: 69952.15513260124,
      usdVolume: 2547190306.6139827,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '2627.6300000000000000',
      high: '2745.0000000000000000',
      low: '2614.0000000000000000',
      close: '2711.9200000000000000',
      volume: '673616.2318600000000000',
      quoteVolume: 1813640710.7122996,
      btcVolume: 50332.5808706832,
      usdVolume: 1810527213.9748535,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '2711.9200000000000000',
      high: '2847.5500000000000000',
      low: '2570.0000000000000000',
      close: '2592.0500000000000000',
      volume: '1152128.0785600000000000',
      quoteVolume: 3149245176.9112477,
      btcVolume: 87844.73708419563,
      usdVolume: 3133289457.721581,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '2592.0500000000000000',
      high: '2620.9900000000000000',
      low: '2304.9100000000000000',
      close: '2507.2300000000000000',
      volume: '1693747.4275900000000000',
      quoteVolume: 4201646662.8817635,
      btcVolume: 127564.26680324697,
      usdVolume: 4173747237.8152375,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '2507.4300000000000000',
      high: '2626.6300000000000000',
      low: '2405.2800000000000000',
      close: '2610.1800000000000000',
      volume: '1276718.2401100000000000',
      quoteVolume: 3223323188.07037,
      btcVolume: 91671.38084778743,
      usdVolume: 3194267768.5913177,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '2610.2000000000000000',
      high: '2624.4600000000000000',
      low: '2425.1100000000000000',
      close: '2471.0900000000000000',
      volume: '740997.1712300000000000',
      quoteVolume: 1873951840.3917582,
      btcVolume: 50420.55129947163,
      usdVolume: 1868275591.2025664,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '2471.0100000000000000',
      high: '2497.9900000000000000',
      low: '2321.3700000000000000',
      close: '2354.0100000000000000',
      volume: '587364.2735800000000000',
      quoteVolume: 1424975261.80769,
      btcVolume: 38486.31716446658,
      usdVolume: 1422146092.2599802,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '2354.0900000000000000',
      high: '2454.5300000000000000',
      low: '2256.2200000000000000',
      close: '2370.7400000000000000',
      volume: '767439.4984000000000000',
      quoteVolume: 1807036943.9986227,
      btcVolume: 50420.12200546101,
      usdVolume: 1799225782.2437441,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '2370.6400000000000000',
      high: '2548.4100000000000000',
      low: '2308.4100000000000000',
      close: '2509.3300000000000000',
      volume: '715948.7598600000000000',
      quoteVolume: 1724097564.3342984,
      btcVolume: 46788.12807221204,
      usdVolume: 1720049201.122317,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '2509.3300000000000000',
      high: '2609.1700000000000000',
      low: '2461.0000000000000000',
      close: '2580.7700000000000000',
      volume: '684183.5562200000000000',
      quoteVolume: 1730260219.1337855,
      btcVolume: 43429.67097729521,
      usdVolume: 1729368351.3638728,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '2580.5800000000000000',
      high: '2640.0000000000000000',
      low: '2507.7000000000000000',
      close: '2543.5400000000000000',
      volume: '599826.6493300000000000',
      quoteVolume: 1548303436.0628068,
      btcVolume: 38413.865031627225,
      usdVolume: 1546074562.469748,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '2543.5500000000000000',
      high: '2556.1900000000000000',
      low: '2351.1800000000000000',
      close: '2367.8900000000000000',
      volume: '624741.7835800000000000',
      quoteVolume: 1533734359.4129868,
      btcVolume: 39025.025528815226,
      usdVolume: 1532223313.0262673,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '2368.0200000000000000',
      high: '2460.5000000000000000',
      low: '2306.0000000000000000',
      close: '2373.2800000000000000',
      volume: '560667.5856600000000000',
      quoteVolume: 1338389545.0014875,
      btcVolume: 34687.277518367926,
      usdVolume: 1336853440.9920366,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '2373.2800000000000000',
      high: '2378.0000000000000000',
      low: '2139.0000000000000000',
      close: '2234.0900000000000000',
      volume: '778905.2019700000000000',
      quoteVolume: 1758827532.8833337,
      btcVolume: 47651.515366817985,
      usdVolume: 1752866245.1499925,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '2234.0900000000000000',
      high: '2278.8200000000000000',
      low: '2161.6700000000000000',
      close: '2164.6700000000000000',
      volume: '496795.2001700000000000',
      quoteVolume: 1104751776.509073,
      btcVolume: 30908.617217601786,
      usdVolume: 1104192848.8738875,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '2165.3400000000000000',
      high: '2280.0100000000000000',
      low: '2040.6800000000000000',
      close: '2243.4700000000000000',
      volume: '743883.6474100000000000',
      quoteVolume: 1599009750.52694,
      btcVolume: 45441.06693970805,
      usdVolume: 1581146882.9629188,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '2243.4900000000000000',
      high: '2260.1600000000000000',
      low: '1865.5400000000000000',
      close: '1885.4900000000000000',
      volume: '1612068.6092700000000000',
      quoteVolume: 3216482751.6444073,
      btcVolume: 96836.53868378879,
      usdVolume: 3184376853.0560994,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '1885.2700000000000000',
      high: '1998.0000000000000000',
      low: '1700.4800000000000000',
      close: '1879.2800000000000000',
      volume: '1695015.0570000000000000',
      quoteVolume: 3158320609.65545,
      btcVolume: 98947.73538033027,
      usdVolume: 3106137818.9013505,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '1879.2900000000000000',
      high: '2045.0000000000000000',
      low: '1821.9500000000000000',
      close: '1968.1400000000000000',
      volume: '918428.2598300000000000',
      quoteVolume: 1817150090.2132,
      btcVolume: 53355.95568641275,
      usdVolume: 1800911052.56896,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '1967.9500000000000000',
      high: '2036.6100000000000000',
      low: '1884.7900000000000000',
      close: '1989.5200000000000000',
      volume: '696819.0814600000000000',
      quoteVolume: 1369338921.1143198,
      btcVolume: 40275.19926124708,
      usdVolume: 1360915669.485624,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '1989.5300000000000000',
      high: '2019.9900000000000000',
      low: '1791.2200000000000000',
      close: '1808.8200000000000000',
      volume: '858808.5476700000000000',
      quoteVolume: 1612556879.10726,
      btcVolume: 48562.74287689089,
      usdVolume: 1610678376.9593577,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '1808.9700000000000000',
      high: '1852.8000000000000000',
      low: '1717.3200000000000000',
      close: '1829.6400000000000000',
      volume: '832315.1490400000000000',
      quoteVolume: 1478550576.5646644,
      btcVolume: 47044.29221027522,
      usdVolume: 1472306570.7656367,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '1829.8400000000000000',
      high: '1984.5000000000000000',
      low: '1804.2600000000000000',
      close: '1982.2800000000000000',
      volume: '696055.7930700000000000',
      quoteVolume: 1299521992.0718284,
      btcVolume: 39054.314536943915,
      usdVolume: 1296909651.9815106,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '1982.2700000000000000',
      high: '2145.0000000000000000',
      low: '1960.5200000000000000',
      close: '2084.4100000000000000',
      volume: '1041685.6177500000000000',
      quoteVolume: 2133750196.9705138,
      btcVolume: 61642.91631656625,
      usdVolume: 2128309767.164647,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '2084.4200000000000000',
      high: '2245.0000000000000000',
      low: '2073.5700000000000000',
      close: '2165.3300000000000000',
      volume: '810280.8201900000000000',
      quoteVolume: 1758516830.5676162,
      btcVolume: 49297.042069009694,
      usdVolume: 1752182286.5171914,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '2165.4600000000000000',
      high: '2287.7800000000000000',
      low: '2087.9900000000000000',
      close: '2275.6800000000000000',
      volume: '898104.5594900000000000',
      quoteVolume: 1947010071.716728,
      btcVolume: 55804.56318164029,
      usdVolume: 1945352686.7102058,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '2275.6800000000000000',
      high: '2275.9300000000000000',
      low: '2073.1200000000000000',
      close: '2106.4100000000000000',
      volume: '787865.2513700000000000',
      quoteVolume: 1688043308.1272736,
      btcVolume: 50052.84631659016,
      usdVolume: 1684503864.007349,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '2106.4100000000000000',
      high: '2160.0000000000000000',
      low: '2018.5000000000000000',
      close: '2154.1300000000000000',
      volume: '667688.1982700000000000',
      quoteVolume: 1387639105.207638,
      btcVolume: 41678.18078815616,
      usdVolume: 1387357481.4871128,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '2153.9500000000000000',
      high: '2240.0000000000000000',
      low: '2112.0100000000000000',
      close: '2226.9900000000000000',
      volume: '567852.4913700000000000',
      quoteVolume: 1245755929.802624,
      btcVolume: 36259.684210643376,
      usdVolume: 1245045174.0268378,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '2226.9900000000000000',
      high: '2389.9300000000000000',
      low: '2189.5800000000000000',
      close: '2322.6900000000000000',
      volume: '586011.9181400000000000',
      quoteVolume: 1354921782.9253561,
      btcVolume: 38334.608601607164,
      usdVolume: 1354169661.0324094,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '2322.6900000000000000',
      high: '2324.6400000000000000',
      low: '2157.3200000000000000',
      close: '2196.5300000000000000',
      volume: '781397.7756700000000000',
      quoteVolume: 1752928567.61669,
      btcVolume: 50930.09033861102,
      usdVolume: 1733327510.3003647,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '2196.8500000000000000',
      high: '2350.0000000000000000',
      low: '2193.0000000000000000',
      close: '2322.5000000000000000',
      volume: '773628.7066000000000000',
      quoteVolume: 1776303270.1513493,
      btcVolume: 50945.131886658244,
      usdVolume: 1742938566.1977441,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '2322.5100000000000000',
      high: '2409.0000000000000000',
      low: '2291.9000000000000000',
      close: '2316.5700000000000000',
      volume: '663034.0864800000000000',
      quoteVolume: 1562325076.9449115,
      btcVolume: 45243.083431928804,
      usdVolume: 1562325076.9449115,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '2316.2500000000000000',
      high: '2325.6000000000000000',
      low: '2084.7900000000000000',
      close: '2116.3300000000000000',
      volume: '880265.0412400000000000',
      quoteVolume: 1926184139.3098714,
      btcVolume: 58517.61155780528,
      usdVolume: 1923539240.4032106,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '2116.3200000000000000',
      high: '2189.4000000000000000',
      low: '2046.5100000000000000',
      close: '2146.9900000000000000',
      volume: '738611.3630400000000000',
      quoteVolume: 1568646237.310992,
      btcVolume: 47365.37692337437,
      usdVolume: 1568180213.2470188,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '2146.9900000000000000',
      high: '2194.8200000000000000',
      low: '2074.1600000000000000',
      close: '2110.6900000000000000',
      volume: '499071.8590400000000000',
      quoteVolume: 1058023794.5114112,
      btcVolume: 31367.83342121098,
      usdVolume: 1058004268.9712181,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '2110.6800000000000000',
      high: '2174.0000000000000000',
      low: '2081.0200000000000000',
      close: '2140.4500000000000000',
      volume: '341861.9107800000000000',
      quoteVolume: 726983500.1953408,
      btcVolume: 21483.282017250633,
      usdVolume: 726871259.6481026,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '2140.5400000000000000',
      high: '2167.8300000000000000',
      low: '2006.6800000000000000',
      close: '2031.5800000000000000',
      volume: '540630.1074200000000000',
      quoteVolume: 1126108847.3713632,
      btcVolume: 33473.528532740485,
      usdVolume: 1122137454.5873184,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '2031.5700000000000000',
      high: '2047.3500000000000000',
      low: '1918.0000000000000000',
      close: '1940.1500000000000000',
      volume: '646200.5714500000000000',
      quoteVolume: 1285151955.408697,
      btcVolume: 39172.03527550333,
      usdVolume: 1284955208.4702895,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '1940.4400000000000000',
      high: '2019.1800000000000000',
      low: '1865.0000000000000000',
      close: '1994.5800000000000000',
      volume: '690308.3220300000000000',
      quoteVolume: 1342549043.7056658,
      btcVolume: 41334.87560952568,
      usdVolume: 1341142947.6783092,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '1994.4100000000000000',
      high: '2042.8800000000000000',
      low: '1881.0000000000000000',
      close: '1919.5100000000000000',
      volume: '645206.5048600000000000',
      quoteVolume: 1251707253.3075902,
      btcVolume: 38931.359341004165,
      usdVolume: 1249677161.8594604,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '1919.5400000000000000',
      high: '1965.0000000000000000',
      low: '1850.0000000000000000',
      close: '1876.2600000000000000',
      volume: '564174.3896200000000000',
      quoteVolume: 1073278193.9976848,
      btcVolume: 33853.35022909676,
      usdVolume: 1073277728.2380823,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '1876.4700000000000000',
      high: '1919.1900000000000000',
      low: '1844.4500000000000000',
      close: '1899.5900000000000000',
      volume: '425518.6836600000000000',
      quoteVolume: 801669257.5310873,
      btcVolume: 25349.466334220528,
      usdVolume: 799730281.5427622,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '1899.5000000000000000',
      high: '1993.7900000000000000',
      low: '1877.1000000000000000',
      close: '1891.6400000000000000',
      volume: '534837.6365400000000000',
      quoteVolume: 1038104997.5220226,
      btcVolume: 32671.78700030974,
      usdVolume: 1038010885.0892934,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '1891.6500000000000000',
      high: '1919.0000000000000000',
      low: '1805.0000000000000000',
      close: '1818.7300000000000000',
      volume: '547419.9377100000000000',
      quoteVolume: 1011627398.9238861,
      btcVolume: 32502.636019487036,
      usdVolume: 1011432953.4100147,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '1818.7300000000000000',
      high: '1841.3400000000000000',
      low: '1706.0000000000000000',
      close: '1786.0300000000000000',
      volume: '786035.8219700000000000',
      quoteVolume: 1386111795.633173,
      btcVolume: 46342.08150771999,
      usdVolume: 1385239379.852115,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '1786.0300000000000000',
      high: '2035.2300000000000000',
      low: '1747.5500000000000000',
      close: '1995.5000000000000000',
      volume: '1115949.8203300000000000',
      quoteVolume: 2138345813.7854047,
      btcVolume: 68045.4703475941,
      usdVolume: 2137544618.252568,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '1995.5000000000000000',
      high: '2046.0000000000000000',
      low: '1947.6200000000000000',
      close: '2024.5600000000000000',
      volume: '768854.3659000000000000',
      quoteVolume: 1536669128.520453,
      btcVolume: 47687.592340090785,
      usdVolume: 1532204051.4068804,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '2024.2700000000000000',
      high: '2094.2800000000000000',
      low: '1993.2200000000000000',
      close: '2034.1400000000000000',
      volume: '561649.8608800000000000',
      quoteVolume: 1150771289.6299481,
      btcVolume: 35513.3079063386,
      usdVolume: 1150771289.6299481,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
  ankr: {
    id: 'ankr',
    name: 'ANKR',
    symbol: 'ANKR',
    image: 'assets/images/coin-logo/ANKR.png',
    activity24h: 545323456,
    availableAmount: 508.232323,
    usdxConversion: 7,
    allTimeValue: 3812390,
    allTimeAsset: 90678678421,
    candleData: [
      {
      open: '52.2500000000000000',
      high: '52.6600000000000000',
      low: '51.7000000000000000',
      close: '52.0700000000000000',
      volume: '35288.3970200000000000',
      quoteVolume: 1838724.3862475,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-28T00:00:00.000Z'
      },
      {
      open: '52.1000000000000000',
      high: '52.2600000000000000',
      low: '48.1700000000000000',
      close: '49.2100000000000000',
      volume: '93056.5563300000000000',
      quoteVolume: 4660386.8668885,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-29T00:00:00.000Z'
      },
      {
      open: '49.1600000000000000',
      high: '49.9600000000000000',
      low: '49.0200000000000000',
      close: '49.3400000000000000',
      volume: '41869.7843300000000000',
      quoteVolume: 2069910.6907136,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-30T00:00:00.000Z'
      },
      {
      open: '49.3500000000000000',
      high: '50.7100000000000000',
      low: '47.7100000000000000',
      close: '49.9300000000000000',
      volume: '59069.9364000000000000',
      quoteVolume: 2926931.182231,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-31T00:00:00.000Z'
      },
      {
      open: '49.9400000000000000',
      high: '50.8500000000000000',
      low: '49.7800000000000000',
      close: '50.6100000000000000',
      volume: '40743.5490400000000000',
      quoteVolume: 2044481.6283927,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-01T00:00:00.000Z'
      },
      {
      open: '50.6300000000000000',
      high: '52.2000000000000000',
      low: '50.5600000000000000',
      close: '51.7800000000000000',
      volume: '55439.5422700000000000',
      quoteVolume: 2853112.5898938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-02T00:00:00.000Z'
      },
      {
      open: '51.7800000000000000',
      high: '51.8100000000000000',
      low: '50.6000000000000000',
      close: '51.0400000000000000',
      volume: '44526.4412600000000000',
      quoteVolume: 2280110.5089847,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-03T00:00:00.000Z'
      },
      {
      open: '51.0900000000000000',
      high: '56.3300000000000000',
      low: '50.7700000000000000',
      close: '54.6000000000000000',
      volume: '131563.2191000000000000',
      quoteVolume: 7106100.759797,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-04T00:00:00.000Z'
      },
      {
      open: '54.6000000000000000',
      high: '55.1500000000000000',
      low: '53.0100000000000000',
      close: '53.9100000000000000',
      volume: '71425.6818700000000000',
      quoteVolume: 3841003.0724431,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-05T00:00:00.000Z'
      },
      {
      open: '53.9300000000000000',
      high: '56.8800000000000000',
      low: '53.8700000000000000',
      close: '56.4800000000000000',
      volume: '82733.1937500000000000',
      quoteVolume: 4543573.2471019,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-06T00:00:00.000Z'
      },
      {
      open: '56.4400000000000000',
      high: '56.9200000000000000',
      low: '53.5200000000000000',
      close: '54.4700000000000000',
      volume: '96697.1057100000000000',
      quoteVolume: 5343582.6991728,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-07T00:00:00.000Z'
      },
      {
      open: '54.4900000000000000',
      high: '55.1200000000000000',
      low: '52.4200000000000000',
      close: '52.7200000000000000',
      volume: '71785.2511700000000000',
      quoteVolume: 3845880.2935037,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-08T00:00:00.000Z'
      },
      {
      open: '52.7000000000000000',
      high: '53.1500000000000000',
      low: '51.6200000000000000',
      close: '52.2500000000000000',
      volume: '54807.0000800000000000',
      quoteVolume: 2873957.5317537,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-09T00:00:00.000Z'
      },
      {
      open: '52.2800000000000000',
      high: '53.0800000000000000',
      low: '51.9700000000000000',
      close: '52.4800000000000000',
      volume: '37947.9906200000000000',
      quoteVolume: 1994759.2672652,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-10T00:00:00.000Z'
      },
      {
      open: '52.4700000000000000',
      high: '52.5700000000000000',
      low: '49.7900000000000000',
      close: '51.4600000000000000',
      volume: '66550.4946300000000000',
      quoteVolume: 3407022.15279,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-11T00:00:00.000Z'
      },
      {
      open: '51.5000000000000000',
      high: '52.0400000000000000',
      low: '50.5900000000000000',
      close: '51.2600000000000000',
      volume: '60038.9645800000000000',
      quoteVolume: 3075503.9229793,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-12T00:00:00.000Z'
      },
      {
      open: '51.2600000000000000',
      high: '51.6600000000000000',
      low: '49.6000000000000000',
      close: '50.4300000000000000',
      volume: '63966.8834300000000000',
      quoteVolume: 3255168.879664,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-13T00:00:00.000Z'
      },
      {
      open: '50.4100000000000000',
      high: '51.0800000000000000',
      low: '42.0000000000000000',
      close: '45.0500000000000000',
      volume: '307943.6722800000000000',
      quoteVolume: 13948978.8933711,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-14T00:00:00.000Z'
      },
      {
      open: '45.0500000000000000',
      high: '45.8000000000000000',
      low: '40.4000000000000000',
      close: '44.7800000000000000',
      volume: '239147.1436300000000000',
      quoteVolume: 10442471.6269976,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-15T00:00:00.000Z'
      },
      {
      open: '44.7800000000000000',
      high: '44.9500000000000000',
      low: '42.3800000000000000',
      close: '42.8900000000000000',
      volume: '126959.9343000000000000',
      quoteVolume: 5526205.6703331,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-16T00:00:00.000Z'
      },
      {
      open: '42.8900000000000000',
      high: '42.9400000000000000',
      low: '41.9200000000000000',
      close: '42.4300000000000000',
      volume: '64923.2697500000000000',
      quoteVolume: 2748488.2839843,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-17T00:00:00.000Z'
      },
      {
      open: '42.4600000000000000',
      high: '43.8400000000000000',
      low: '42.1100000000000000',
      close: '42.6100000000000000',
      volume: '69727.5181800000000000',
      quoteVolume: 2991350.2245596,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-18T00:00:00.000Z'
      },
      {
      open: '42.6300000000000000',
      high: '42.6500000000000000',
      low: '35.9400000000000000',
      close: '37.0700000000000000',
      volume: '266627.8181000000000000',
      quoteVolume: 10193547.6518018,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-19T00:00:00.000Z'
      },
      {
      open: '37.1100000000000000',
      high: '38.8000000000000000',
      low: '31.4700000000000000',
      close: '33.8000000000000000',
      volume: '481972.2055200000000000',
      quoteVolume: 16734732.4018969,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-20T00:00:00.000Z'
      },
      {
      open: '33.7100000000000000',
      high: '35.3800000000000000',
      low: '32.1700000000000000',
      close: '35.1500000000000000',
      volume: '307689.2861400000000000',
      quoteVolume: 10447986.7251629,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-21T00:00:00.000Z'
      },
      {
      open: '35.1700000000000000',
      high: '35.6800000000000000',
      low: '31.8400000000000000',
      close: '32.1100000000000000',
      volume: '140470.9296900000000000',
      quoteVolume: 4791952.8493514,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-22T00:00:00.000Z'
      },
      {
      open: '32.0900000000000000',
      high: '33.8100000000000000',
      low: '31.1000000000000000',
      close: '32.7400000000000000',
      volume: '178263.3759900000000000',
      quoteVolume: 5763152.7094353,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-23T00:00:00.000Z'
      },
      {
      open: '32.7400000000000000',
      high: '34.3000000000000000',
      low: '29.0700000000000000',
      close: '29.7300000000000000',
      volume: '175080.6502100000000000',
      quoteVolume: 5486899.3643201,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-24T00:00:00.000Z'
      },
      {
      open: '29.7200000000000000',
      high: '32.9300000000000000',
      low: '27.7000000000000000',
      close: '31.4900000000000000',
      volume: '330712.4127800000000000',
      quoteVolume: 9875455.0267542,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-25T00:00:00.000Z'
      },
      {
      open: '31.5100000000000000',
      high: '32.8800000000000000',
      low: '28.6600000000000000',
      close: '30.1800000000000000',
      volume: '346086.7723900000000000',
      quoteVolume: 10650119.32109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-26T00:00:00.000Z'
      },
      {
      open: '30.1800000000000000',
      high: '32.1600000000000000',
      low: '29.0500000000000000',
      close: '31.6100000000000000',
      volume: '194024.5309000000000000',
      quoteVolume: 5911546.9844789,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-27T00:00:00.000Z'
      },
      {
      open: '31.6200000000000000',
      high: '36.5100000000000000',
      low: '31.3900000000000000',
      close: '34.7400000000000000',
      volume: '261988.5621100000000000',
      quoteVolume: 8934592.4563842,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-28T00:00:00.000Z'
      },
      {
      open: '34.7400000000000000',
      high: '36.0000000000000000',
      low: '33.0800000000000000',
      close: '33.8800000000000000',
      volume: '206424.0394100000000000',
      quoteVolume: 7091338.8885229,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-29T00:00:00.000Z'
      },
      {
      open: '33.9000000000000000',
      high: '34.5300000000000000',
      low: '31.0700000000000000',
      close: '32.2100000000000000',
      volume: '182324.5556100000000000',
      quoteVolume: 5934596.3999476,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-30T00:00:00.000Z'
      },
      {
      open: '32.1900000000000000',
      high: '35.1000000000000000',
      low: '31.3800000000000000',
      close: '34.1700000000000000',
      volume: '130601.1752700000000000',
      quoteVolume: 4373049.6516537,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-01T00:00:00.000Z'
      },
      {
      open: '34.1100000000000000',
      high: '35.2400000000000000',
      low: '33.2100000000000000',
      close: '33.8200000000000000',
      volume: '126562.5130800000000000',
      quoteVolume: 4314174.8406725,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-02T00:00:00.000Z'
      },
      {
      open: '33.8100000000000000',
      high: '33.9700000000000000',
      low: '30.0800000000000000',
      close: '30.9000000000000000',
      volume: '160953.4761900000000000',
      quoteVolume: 5107201.1187242,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-03T00:00:00.000Z'
      },
      {
      open: '30.9200000000000000',
      high: '32.2500000000000000',
      low: '29.8700000000000000',
      close: '31.1300000000000000',
      volume: '125624.1006500000000000',
      quoteVolume: 3938334.4457955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-04T00:00:00.000Z'
      },
      {
      open: '31.1400000000000000',
      high: '31.4000000000000000',
      low: '29.1500000000000000',
      close: '29.2700000000000000',
      volume: '122536.9591900000000000',
      quoteVolume: 3686632.3658405,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-05T00:00:00.000Z'
      },
      {
      open: '29.2800000000000000',
      high: '30.4900000000000000',
      low: '26.2500000000000000',
      close: '26.7800000000000000',
      volume: '186020.5287000000000000',
      quoteVolume: 5318278.7153886,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-06T00:00:00.000Z'
      },
      {
      open: '26.7400000000000000',
      high: '26.9400000000000000',
      low: '22.3200000000000000',
      close: '24.9100000000000000',
      volume: '344334.7713200000000000',
      quoteVolume: 8550497.2274868,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-07T00:00:00.000Z'
      },
      {
      open: '24.8800000000000000',
      high: '26.6300000000000000',
      low: '22.8900000000000000',
      close: '24.2200000000000000',
      volume: '272026.2401200000000000',
      quoteVolume: 6669827.4592307,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-08T00:00:00.000Z'
      },
      {
      open: '24.2300000000000000',
      high: '26.7500000000000000',
      low: '24.2000000000000000',
      close: '25.3700000000000000',
      volume: '185392.2777400000000000',
      quoteVolume: 4728009.3049824,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-09T00:00:00.000Z'
      },
      {
      open: '25.3700000000000000',
      high: '25.8200000000000000',
      low: '23.6700000000000000',
      close: '24.1400000000000000',
      volume: '159523.3339000000000000',
      quoteVolume: 3913245.9562269,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-10T00:00:00.000Z'
      },
      {
      open: '24.1500000000000000',
      high: '24.6300000000000000',
      low: '22.9400000000000000',
      close: '23.4100000000000000',
      volume: '116912.3106800000000000',
      quoteVolume: 2774102.4887662,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-11T00:00:00.000Z'
      },
      {
      open: '23.4000000000000000',
      high: '24.9800000000000000',
      low: '23.2000000000000000',
      close: '24.1300000000000000',
      volume: '116697.0162100000000000',
      quoteVolume: 2839522.3254574,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-12T00:00:00.000Z'
      },
      {
      open: '24.1300000000000000',
      high: '24.2900000000000000',
      low: '22.9100000000000000',
      close: '23.0800000000000000',
      volume: '101343.2455300000000000',
      quoteVolume: 2391795.1552609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-13T00:00:00.000Z'
      },
      {
      open: '23.0600000000000000',
      high: '23.7600000000000000',
      low: '22.5000000000000000',
      close: '23.3400000000000000',
      volume: '115451.1935700000000000',
      quoteVolume: 2669993.897385,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-14T00:00:00.000Z'
      },
      {
      open: '23.3300000000000000',
      high: '24.0600000000000000',
      low: '23.0900000000000000',
      close: '23.6000000000000000',
      volume: '114346.7365200000000000',
      quoteVolume: 2696112.2014415,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-15T00:00:00.000Z'
      },
      {
      open: '23.5900000000000000',
      high: '26.9600000000000000',
      low: '23.4800000000000000',
      close: '25.5200000000000000',
      volume: '284638.3664300000000000',
      quoteVolume: 7367653.6178438,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-16T00:00:00.000Z'
      },
      {
      open: '25.5100000000000000',
      high: '30.3000000000000000',
      low: '25.4300000000000000',
      close: '28.9500000000000000',
      volume: '415677.5500900000000000',
      quoteVolume: 11734862.3593704,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-17T00:00:00.000Z'
      },
      {
      open: '28.9700000000000000',
      high: '30.1500000000000000',
      low: '27.3900000000000000',
      close: '29.8900000000000000',
      volume: '241540.2199400000000000',
      quoteVolume: 6940548.103116,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-18T00:00:00.000Z'
      },
      {
      open: '29.8900000000000000',
      high: '31.5500000000000000',
      low: '28.5100000000000000',
      close: '28.8300000000000000',
      volume: '464104.8813400000000000',
      quoteVolume: 13967868.6904562,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-19T00:00:00.000Z'
      },
      {
      open: '28.8300000000000000',
      high: '32.7600000000000000',
      low: '28.5200000000000000',
      close: '32.1800000000000000',
      volume: '347428.5018700000000000',
      quoteVolume: 10702281.6625123,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-20T00:00:00.000Z'
      },
      {
      open: '32.1800000000000000',
      high: '32.8700000000000000',
      low: '29.5500000000000000',
      close: '30.2900000000000000',
      volume: '222927.6634500000000000',
      quoteVolume: 6941800.3395846,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-21T00:00:00.000Z'
      },
      {
      open: '30.2900000000000000',
      high: '31.4900000000000000',
      low: '29.7000000000000000',
      close: '31.3300000000000000',
      volume: '106033.3357700000000000',
      quoteVolume: 3234572.7694072,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-22T00:00:00.000Z'
      },
      {
      open: '31.2900000000000000',
      high: '33.8400000000000000',
      low: '31.0900000000000000',
      close: '32.9700000000000000',
      volume: '255561.7289100000000000',
      quoteVolume: 8317118.2252661,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-23T00:00:00.000Z'
      },
      {
      open: '32.9800000000000000',
      high: '36.1200000000000000',
      low: '32.1700000000000000',
      close: '32.6800000000000000',
      volume: '305504.7449800000000000',
      quoteVolume: 10561724.7698791,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-24T00:00:00.000Z'
      },
      {
      open: '32.6900000000000000',
      high: '32.9300000000000000',
      low: '29.1900000000000000',
      close: '30.5000000000000000',
      volume: '248993.5555600000000000',
      quoteVolume: 7586691.2768408,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-25T00:00:00.000Z'
      },
      {
      open: '30.5000000000000000',
      high: '31.6800000000000000',
      low: '28.7900000000000000',
      close: '30.3000000000000000',
      volume: '174048.0413600000000000',
      quoteVolume: 5242464.4829548,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-26T00:00:00.000Z'
      },
      {
      open: '30.2700000000000000',
      high: '30.8900000000000000',
      low: '27.1700000000000000',
      close: '27.5500000000000000',
      volume: '157817.2261100000000000',
      quoteVolume: 4528463.300989,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-27T00:00:00.000Z'
      },
      {
      open: '27.5500000000000000',
      high: '33.0800000000000000',
      low: '27.3900000000000000',
      close: '31.8000000000000000',
      volume: '242123.8975000000000000',
      quoteVolume: 7428412.8216719,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-28T00:00:00.000Z'
      },
      {
      open: '31.7800000000000000',
      high: '32.8100000000000000',
      low: '29.9100000000000000',
      close: '30.2800000000000000',
      volume: '222196.8569800000000000',
      quoteVolume: 6994333.2082403,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-29T00:00:00.000Z'
      },
      {
      open: '30.2800000000000000',
      high: '32.0000000000000000',
      low: '29.6800000000000000',
      close: '31.3800000000000000',
      volume: '151572.9304500000000000',
      quoteVolume: 4696122.0322328,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-30T00:00:00.000Z'
      },
      {
      open: '31.3800000000000000',
      high: '31.4300000000000000',
      low: '29.4200000000000000',
      close: '29.9000000000000000',
      volume: '97446.1124100000000000',
      quoteVolume: 2959940.3443041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-31T00:00:00.000Z'
      },
      {
      open: '29.9200000000000000',
      high: '31.8000000000000000',
      low: '29.5600000000000000',
      close: '31.4800000000000000',
      volume: '103182.7376500000000000',
      quoteVolume: 3162741.4467664,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-01T00:00:00.000Z'
      },
      {
      open: '31.4700000000000000',
      high: '33.2900000000000000',
      low: '31.0700000000000000',
      close: '32.6700000000000000',
      volume: '118306.2331700000000000',
      quoteVolume: 3805773.1198366,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-02T00:00:00.000Z'
      },
      {
      open: '32.6800000000000000',
      high: '32.8000000000000000',
      low: '30.6200000000000000',
      close: '31.3900000000000000',
      volume: '94129.8511400000000000',
      quoteVolume: 2982980.2248225,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-03T00:00:00.000Z'
      },
      {
      open: '31.4100000000000000',
      high: '32.5300000000000000',
      low: '30.7800000000000000',
      close: '31.7600000000000000',
      volume: '108636.8219300000000000',
      quoteVolume: 3437486.3851984,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-04T00:00:00.000Z'
      },
      {
      open: '31.7600000000000000',
      high: '35.9500000000000000',
      low: '31.7600000000000000',
      close: '34.3300000000000000',
      volume: '335581.5584800000000000',
      quoteVolume: 11471296.9699548,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-05T00:00:00.000Z'
      },
      {
      open: '34.3300000000000000',
      high: '39.7100000000000000',
      low: '33.8900000000000000',
      close: '38.3000000000000000',
      volume: '761396.1067600000000000',
      quoteVolume: 28298586.9009635,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-06T00:00:00.000Z'
      },
      {
      open: '38.2900000000000000',
      high: '39.0400000000000000',
      low: '36.5300000000000000',
      close: '37.3500000000000000',
      volume: '444975.9243100000000000',
      quoteVolume: 16797767.6556064,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-07T00:00:00.000Z'
      },
      {
      open: '37.3500000000000000',
      high: '40.8000000000000000',
      low: '36.9100000000000000',
      close: '38.7000000000000000',
      volume: '522510.1714200000000000',
      quoteVolume: 20322945.3312497,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-08T00:00:00.000Z'
      },
      {
      open: '38.7000000000000000',
      high: '39.7200000000000000',
      low: '38.0000000000000000',
      close: '38.1700000000000000',
      volume: '312030.1302100000000000',
      quoteVolume: 12104357.4442312,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-09T00:00:00.000Z'
      },
      {
      open: '38.1700000000000000',
      high: '38.7700000000000000',
      low: '31.0000000000000000',
      close: '32.8100000000000000',
      volume: '584622.4539000000000000',
      quoteVolume: 19980421.46609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-10T00:00:00.000Z'
      },
      {
      open: '32.8100000000000000',
      high: '33.2200000000000000',
      low: '29.6800000000000000',
      close: '31.4900000000000000',
      volume: '351182.3816500000000000',
      quoteVolume: 11268415.5174647,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-11T00:00:00.000Z'
      },
      {
      open: '31.4800000000000000',
      high: '32.1300000000000000',
      low: '31.0700000000000000',
      close: '31.6500000000000000',
      volume: '178476.2871800000000000',
      quoteVolume: 5640569.2458151,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-12T00:00:00.000Z'
      },
      {
      open: '31.6500000000000000',
      high: '32.6300000000000000',
      low: '29.1200000000000000',
      close: '29.5900000000000000',
      volume: '229264.1192700000000000',
      quoteVolume: 7075842.0712984,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-13T00:00:00.000Z'
      },
      {
      open: '29.6000000000000000',
      high: '32.5000000000000000',
      low: '29.3800000000000000',
      close: '31.7100000000000000',
      volume: '255282.3580100000000000',
      quoteVolume: 7925720.2735823,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-14T00:00:00.000Z'
      },
      {
      open: '31.7200000000000000',
      high: '32.2700000000000000',
      low: '29.9500000000000000',
      close: '30.7500000000000000',
      volume: '216841.8608500000000000',
      quoteVolume: 6784015.4244266,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-15T00:00:00.000Z'
      },
      {
      open: '30.7400000000000000',
      high: '31.9300000000000000',
      low: '30.4700000000000000',
      close: '31.2900000000000000',
      volume: '153939.5962200000000000',
      quoteVolume: 4789334.1418824,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '31.2900000000000000',
      high: '31.5500000000000000',
      low: '29.8000000000000000',
      close: '31.4000000000000000',
      volume: '203218.0028600000000000',
      quoteVolume: 6238519.8934698,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '31.3800000000000000',
      high: '31.5300000000000000',
      low: '30.3100000000000000',
      close: '30.7700000000000000',
      volume: '150711.3444400000000000',
      quoteVolume: 4659547.3864997,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '30.7800000000000000',
      high: '32.9200000000000000',
      low: '30.7700000000000000',
      close: '32.0000000000000000',
      volume: '164989.6366800000000000',
      quoteVolume: 5273505.545043,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '31.9400000000000000',
      high: '33.2000000000000000',
      low: '28.4500000000000000',
      close: '30.6400000000000000',
      volume: '249450.0040800000000000',
      quoteVolume: 7815412.7300217,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '30.6300000000000000',
      high: '31.2200000000000000',
      low: '30.3400000000000000',
      close: '30.8200000000000000',
      volume: '110305.7586600000000000',
      quoteVolume: 3392716.0542585,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '30.8200000000000000',
      high: '32.0000000000000000',
      low: '29.4800000000000000',
      close: '31.4100000000000000',
      volume: '199887.1625700000000000',
      quoteVolume: 6183272.8098907,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '31.4000000000000000',
      high: '32.1800000000000000',
      low: '31.2400000000000000',
      close: '31.6900000000000000',
      volume: '123577.2723200000000000',
      quoteVolume: 3920165.8017895,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '31.6900000000000000',
      high: '33.1000000000000000',
      low: '30.8900000000000000',
      close: '32.5100000000000000',
      volume: '138812.1621600000000000',
      quoteVolume: 4453091.2945143,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '32.5200000000000000',
      high: '33.0000000000000000',
      low: '31.8700000000000000',
      close: '32.6900000000000000',
      volume: '169242.5338200000000000',
      quoteVolume: 5485967.9294638,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '32.6800000000000000',
      high: '33.9500000000000000',
      low: '32.5700000000000000',
      close: '32.8000000000000000',
      volume: '166677.4280800000000000',
      quoteVolume: 5537195.8890435,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '32.7900000000000000',
      high: '33.2000000000000000',
      low: '31.0500000000000000',
      close: '32.2100000000000000',
      volume: '173697.8199800000000000',
      quoteVolume: 5628213.3201342,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '32.2200000000000000',
      high: '32.5300000000000000',
      low: '30.0000000000000000',
      close: '30.9400000000000000',
      volume: '267887.6294200000000000',
      quoteVolume: 8275485.9723247,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '30.9400000000000000',
      high: '31.2600000000000000',
      low: '29.9100000000000000',
      close: '30.8100000000000000',
      volume: '135879.8588000000000000',
      quoteVolume: 4168438.6557358,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '30.8000000000000000',
      high: '31.9700000000000000',
      low: '30.4700000000000000',
      close: '31.6800000000000000',
      volume: '129114.9970500000000000',
      quoteVolume: 4046024.3723204,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '31.6800000000000000',
      high: '32.3300000000000000',
      low: '31.0800000000000000',
      close: '31.4100000000000000',
      volume: '154708.1742700000000000',
      quoteVolume: 4881672.9566493,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '31.4300000000000000',
      high: '33.6400000000000000',
      low: '30.7000000000000000',
      close: '32.6500000000000000',
      volume: '335544.9644500000000000',
      quoteVolume: 10864529.5873272,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '32.6100000000000000',
      high: '35.0100000000000000',
      low: '32.4400000000000000',
      close: '34.5900000000000000',
      volume: '268052.5235400000000000',
      quoteVolume: 8939273.973636,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '34.5900000000000000',
      high: '34.8900000000000000',
      low: '32.8600000000000000',
      close: '33.4400000000000000',
      volume: '348406.7322400000000000',
      quoteVolume: 11802330.6146259,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '33.4300000000000000',
      high: '34.5000000000000000',
      low: '33.3600000000000000',
      close: '34.0900000000000000',
      volume: '206649.6690200000000000',
      quoteVolume: 7014206.2681502,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '34.0800000000000000',
      high: '34.5400000000000000',
      low: '33.6300000000000000',
      close: '34.3400000000000000',
      volume: '214319.3423500000000000',
      quoteVolume: 7321284.8808597,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '34.3500000000000000',
      high: '34.5000000000000000',
      low: '32.2500000000000000',
      close: '32.7900000000000000',
      volume: '312456.5420100000000000',
      quoteVolume: 10273847.9600226,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '32.7800000000000000',
      high: '33.6100000000000000',
      low: '32.7300000000000000',
      close: '33.2400000000000000',
      volume: '144528.0808400000000000',
      quoteVolume: 4798854.8184626,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '33.2300000000000000',
      high: '44.8500000000000000',
      low: '32.7500000000000000',
      close: '42.9900000000000000',
      volume: '1237384.0701200000000000',
      quoteVolume: 48819159.693489,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '42.9900000000000000',
      high: '46.9500000000000000',
      low: '42.0500000000000000',
      close: '44.6800000000000000',
      volume: '993529.3585100000000000',
      quoteVolume: 43845377.3617417,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '44.6800000000000000',
      high: '47.5900000000000000',
      low: '42.0000000000000000',
      close: '46.7900000000000000',
      volume: '667594.4622900000000000',
      quoteVolume: 30084786.2181325,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '46.8000000000000000',
      high: '46.8700000000000000',
      low: '41.8100000000000000',
      close: '42.9000000000000000',
      volume: '570398.4899100000000000',
      quoteVolume: 25000654.7153878,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '42.8800000000000000',
      high: '44.5700000000000000',
      low: '41.8400000000000000',
      close: '43.8900000000000000',
      volume: '326731.5580100000000000',
      quoteVolume: 14181167.7324087,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '43.8900000000000000',
      high: '44.6600000000000000',
      low: '40.6400000000000000',
      close: '41.6100000000000000',
      volume: '333914.8432600000000000',
      quoteVolume: 14169773.6990322,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '41.6100000000000000',
      high: '42.4500000000000000',
      low: '40.3100000000000000',
      close: '41.2600000000000000',
      volume: '285144.9373900000000000',
      quoteVolume: 11825040.1861124,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '41.2600000000000000',
      high: '44.6000000000000000',
      low: '41.0600000000000000',
      close: '42.5800000000000000',
      volume: '389950.9521300000000000',
      quoteVolume: 16519445.9920431,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '42.6000000000000000',
      high: '44.3700000000000000',
      low: '42.2800000000000000',
      close: '43.3400000000000000',
      volume: '326691.7079300000000000',
      quoteVolume: 14225774.1208697,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '43.3400000000000000',
      high: '45.1300000000000000',
      low: '42.6000000000000000',
      close: '43.9100000000000000',
      volume: '331698.7960300000000000',
      quoteVolume: 14459368.8381966,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '43.8900000000000000',
      high: '49.0300000000000000',
      low: '43.6200000000000000',
      close: '47.8400000000000000',
      volume: '744029.8712100000000000',
      quoteVolume: 34349598.9758269,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '47.8100000000000000',
      high: '49.1000000000000000',
      low: '46.5600000000000000',
      close: '47.1400000000000000',
      volume: '404786.7578400000000000',
      quoteVolume: 19350985.317177,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '47.1600000000000000',
      high: '52.2100000000000000',
      low: '46.2500000000000000',
      close: '51.5000000000000000',
      volume: '566874.1207300000000000',
      quoteVolume: 28402239.4220076,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '51.4700000000000000',
      high: '51.6500000000000000',
      low: '47.3500000000000000',
      close: '48.9400000000000000',
      volume: '383781.9003200000000000',
      quoteVolume: 18972966.8701831,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '48.9300000000000000',
      high: '49.9700000000000000',
      low: '48.0800000000000000',
      close: '49.1900000000000000',
      volume: '265979.4348900000000000',
      quoteVolume: 13079846.878828,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '49.2200000000000000',
      high: '51.8800000000000000',
      low: '48.0000000000000000',
      close: '51.4000000000000000',
      volume: '411115.3383000000000000',
      quoteVolume: 20597303.0431109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '51.3900000000000000',
      high: '53.5300000000000000',
      low: '42.3700000000000000',
      close: '43.7500000000000000',
      volume: '685277.0160800000000000',
      quoteVolume: 32440229.6963062,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '43.7400000000000000',
      high: '46.8500000000000000',
      low: '43.0000000000000000',
      close: '45.4000000000000000',
      volume: '377273.1727500000000000',
      quoteVolume: 16992487.3052862,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '45.4000000000000000',
      high: '45.8900000000000000',
      low: '44.0600000000000000',
      close: '44.7600000000000000',
      volume: '220101.5549400000000000',
      quoteVolume: 9886505.2314402,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '44.7700000000000000',
      high: '45.5300000000000000',
      low: '42.5100000000000000',
      close: '45.1600000000000000',
      volume: '223940.9450500000000000',
      quoteVolume: 9964616.9874317,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '45.1500000000000000',
      high: '46.7000000000000000',
      low: '44.4400000000000000',
      close: '45.6100000000000000',
      volume: '258562.9677900000000000',
      quoteVolume: 11834468.51228,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '45.6200000000000000',
      high: '48.9500000000000000',
      low: '45.5300000000000000',
      close: '47.0500000000000000',
      volume: '336011.9229400000000000',
      quoteVolume: 15914635.4786478,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '47.0200000000000000',
      high: '49.4000000000000000',
      low: '46.3800000000000000',
      close: '48.4900000000000000',
      volume: '325733.9473500000000000',
      quoteVolume: 15618945.7813467,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '48.5100000000000000',
      high: '49.1000000000000000',
      low: '47.1800000000000000',
      close: '47.8600000000000000',
      volume: '218511.9939600000000000',
      quoteVolume: 10525909.3067072,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '47.8700000000000000',
      high: '48.2200000000000000',
      low: '44.5700000000000000',
      close: '45.7800000000000000',
      volume: '306985.2797700000000000',
      quoteVolume: 14131761.4970669,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '45.7800000000000000',
      high: '54.2500000000000000',
      low: '45.3800000000000000',
      close: '52.5200000000000000',
      volume: '798938.1036800000000000',
      quoteVolume: 40660412.2179829,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '52.5200000000000000',
      high: '56.8100000000000000',
      low: '51.1100000000000000',
      close: '55.3700000000000000',
      volume: '670227.4940300000000000',
      quoteVolume: 36366973.2904414,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '55.3700000000000000',
      high: '59.1200000000000000',
      low: '53.7700000000000000',
      close: '56.7300000000000000',
      volume: '816612.7704900000000000',
      quoteVolume: 46227699.9424252,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '56.7300000000000000',
      high: '57.6000000000000000',
      low: '54.3000000000000000',
      close: '55.6100000000000000',
      volume: '440480.6590400000000000',
      quoteVolume: 24793142.8918252,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '55.5800000000000000',
      high: '59.8300000000000000',
      low: '55.3800000000000000',
      close: '57.7800000000000000',
      volume: '413625.1043600000000000',
      quoteVolume: 23841397.7942103,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '57.7800000000000000',
      high: '58.0400000000000000',
      low: '55.8400000000000000',
      close: '56.5900000000000000',
      volume: '198932.2372700000000000',
      quoteVolume: 11276533.6754336,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '56.6300000000000000',
      high: '57.3700000000000000',
      low: '54.0000000000000000',
      close: '54.8200000000000000',
      volume: '319640.8436300000000000',
      quoteVolume: 17619148.0806475,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '54.8200000000000000',
      high: '56.9600000000000000',
      low: '53.7200000000000000',
      close: '56.5800000000000000',
      volume: '186798.9733400000000000',
      quoteVolume: 10473627.53209,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '56.6000000000000000',
      high: '56.8400000000000000',
      low: '54.3000000000000000',
      close: '55.2900000000000000',
      volume: '234206.3592300000000000',
      quoteVolume: 13044765.6089822,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '55.2900000000000000',
      high: '58.2000000000000000',
      low: '51.6600000000000000',
      close: '55.9400000000000000',
      volume: '333686.3963100000000000',
      quoteVolume: 18559101.3738751,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '55.9400000000000000',
      high: '59.1500000000000000',
      low: '55.9400000000000000',
      close: '58.5200000000000000',
      volume: '295307.7767700000000000',
      quoteVolume: 17013433.6384185,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '58.5200000000000000',
      high: '61.9700000000000000',
      low: '58.4100000000000000',
      close: '61.2100000000000000',
      volume: '375314.7926600000000000',
      quoteVolume: 22846386.0282299,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '61.2200000000000000',
      high: '61.3200000000000000',
      low: '59.5700000000000000',
      close: '60.4700000000000000',
      volume: '174833.3191300000000000',
      quoteVolume: 10590199.9608341,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '60.4700000000000000',
      high: '62.6300000000000000',
      low: '58.2000000000000000',
      close: '59.1700000000000000',
      volume: '301146.2139800000000000',
      quoteVolume: 18071146.4905442,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '59.2000000000000000',
      high: '59.9600000000000000',
      low: '58.3300000000000000',
      close: '59.5200000000000000',
      volume: '221995.5983300000000000',
      quoteVolume: 13162394.4379992,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '59.5000000000000000',
      high: '60.7400000000000000',
      low: '56.6400000000000000',
      close: '60.2700000000000000',
      volume: '285413.7934000000000000',
      quoteVolume: 16953291.8433374,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '60.2900000000000000',
      high: '60.6400000000000000',
      low: '56.9800000000000000',
      close: '58.5400000000000000',
      volume: '164946.6053500000000000',
      quoteVolume: 9725257.740012,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '58.5400000000000000',
      high: '59.6000000000000000',
      low: '58.0000000000000000',
      close: '59.2600000000000000',
      volume: '184427.3358700000000000',
      quoteVolume: 10869615.6738316,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '59.2300000000000000',
      high: '61.7600000000000000',
      low: '58.9300000000000000',
      close: '60.8900000000000000',
      volume: '372961.5478100000000000',
      quoteVolume: 22578010.5670285,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '60.8800000000000000',
      high: '60.9000000000000000',
      low: '58.8400000000000000',
      close: '59.8200000000000000',
      volume: '360926.1570600000000000',
      quoteVolume: 21566309.6375968,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '59.8100000000000000',
      high: '60.7000000000000000',
      low: '58.2000000000000000',
      close: '59.0400000000000000',
      volume: '216703.6696800000000000',
      quoteVolume: 12846830.3232689,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '59.0600000000000000',
      high: '59.3500000000000000',
      low: '57.2300000000000000',
      close: '58.7900000000000000',
      volume: '175752.0092500000000000',
      quoteVolume: 10247903.100617,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '58.8100000000000000',
      high: '61.8700000000000000',
      low: '58.5800000000000000',
      close: '61.4400000000000000',
      volume: '260971.7306100000000000',
      quoteVolume: 15803472.9452996,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '61.4300000000000000',
      high: '61.4300000000000000',
      low: '60.1500000000000000',
      close: '60.5000000000000000',
      volume: '160785.5461800000000000',
      quoteVolume: 9744151.8473039,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '60.4800000000000000',
      high: '61.8000000000000000',
      low: '60.1500000000000000',
      close: '61.4500000000000000',
      volume: '200422.3453500000000000',
      quoteVolume: 12200871.3372611,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '61.4100000000000000',
      high: '61.5600000000000000',
      low: '59.5900000000000000',
      close: '60.4500000000000000',
      volume: '161304.2612800000000000',
      quoteVolume: 9780439.5002168,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '60.4500000000000000',
      high: '60.7200000000000000',
      low: '59.8300000000000000',
      close: '60.4800000000000000',
      volume: '120015.8600500000000000',
      quoteVolume: 7234304.2504601,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '60.4700000000000000',
      high: '60.9000000000000000',
      low: '59.6200000000000000',
      close: '60.4500000000000000',
      volume: '129238.6612100000000000',
      quoteVolume: 7810478.4448446,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '60.4500000000000000',
      high: '78.4000000000000000',
      low: '59.9500000000000000',
      close: '76.7800000000000000',
      volume: '805505.1657500000000000',
      quoteVolume: 55327330.1915808,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '76.7600000000000000',
      high: '98.5000000000000000',
      low: '76.2400000000000000',
      close: '85.1900000000000000',
      volume: '1514710.2931300000000000',
      quoteVolume: 130062156.107703,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '85.0200000000000000',
      high: '89.8500000000000000',
      low: '79.8500000000000000',
      close: '84.7000000000000000',
      volume: '775702.6721700000000000',
      quoteVolume: 66127061.9191573,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '84.7100000000000000',
      high: '89.8500000000000000',
      low: '83.6500000000000000',
      close: '88.2400000000000000',
      volume: '366668.9956300000000000',
      quoteVolume: 31984491.3758827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '88.2400000000000000',
      high: '97.5400000000000000',
      low: '85.0000000000000000',
      close: '92.2100000000000000',
      volume: '706789.2345900000000000',
      quoteVolume: 65322880.0905888,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '92.2300000000000000',
      high: '95.4400000000000000',
      low: '88.7400000000000000',
      close: '91.5500000000000000',
      volume: '404878.1342900000000000',
      quoteVolume: 37409101.1554274,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '91.5500000000000000',
      high: '94.0000000000000000',
      low: '85.2000000000000000',
      close: '88.5700000000000000',
      volume: '439909.3384300000000000',
      quoteVolume: 39092277.7488787,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '88.5700000000000000',
      high: '88.7100000000000000',
      low: '82.6100000000000000',
      close: '86.1700000000000000',
      volume: '317748.7851900000000000',
      quoteVolume: 27340412.18294,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '86.1900000000000000',
      high: '91.1000000000000000',
      low: '85.9100000000000000',
      close: '88.1600000000000000',
      volume: '315727.2367600000000000',
      quoteVolume: 27802373.2737036,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '88.1600000000000000',
      high: '88.4500000000000000',
      low: '74.6400000000000000',
      close: '78.8800000000000000',
      volume: '663804.9849800000000000',
      quoteVolume: 52734752.5700692,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '78.9000000000000000',
      high: '80.4300000000000000',
      low: '75.0000000000000000',
      close: '78.4700000000000000',
      volume: '351092.1192400000000000',
      quoteVolume: 27388660.7258404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '78.4300000000000000',
      high: '81.0200000000000000',
      low: '75.5100000000000000',
      close: '77.6200000000000000',
      volume: '276710.7109000000000000',
      quoteVolume: 21674683.2126471,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '77.5800000000000000',
      high: '83.6000000000000000',
      low: '76.0900000000000000',
      close: '83.2800000000000000',
      volume: '242418.4130300000000000',
      quoteVolume: 19179352.734823,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '83.3100000000000000',
      high: '84.4100000000000000',
      low: '75.0000000000000000',
      close: '78.0700000000000000',
      volume: '437925.6544900000000000',
      quoteVolume: 35039724.5357955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '78.0600000000000000',
      high: '81.4900000000000000',
      low: '76.8800000000000000',
      close: '80.2700000000000000',
      volume: '295134.1772800000000000',
      quoteVolume: 23370026.8609958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '80.2700000000000000',
      high: '81.5000000000000000',
      low: '78.0000000000000000',
      close: '78.7700000000000000',
      volume: '232336.8106200000000000',
      quoteVolume: 18452843.5923519,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '78.8200000000000000',
      high: '83.6600000000000000',
      low: '78.7400000000000000',
      close: '81.6800000000000000',
      volume: '328509.7703700000000000',
      quoteVolume: 26614059.4859404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '81.6700000000000000',
      high: '83.1700000000000000',
      low: '79.4800000000000000',
      close: '82.0500000000000000',
      volume: '265365.4938300000000000',
      quoteVolume: 21606124.7509276,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '82.0500000000000000',
      high: '82.6900000000000000',
      low: '79.3300000000000000',
      close: '81.1500000000000000',
      volume: '209481.5475900000000000',
      quoteVolume: 17009020.0094766,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '81.1600000000000000',
      high: '81.8500000000000000',
      low: '72.8600000000000000',
      close: '76.6500000000000000',
      volume: '405449.3565200000000000',
      quoteVolume: 30882393.8202287,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '76.6100000000000000',
      high: '77.6300000000000000',
      low: '74.9000000000000000',
      close: '76.5100000000000000',
      volume: '285737.8388600000000000',
      quoteVolume: 21814764.7461896,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '76.4700000000000000',
      high: '79.2900000000000000',
      low: '73.3300000000000000',
      close: '73.7000000000000000',
      volume: '408341.2265500000000000',
      quoteVolume: 31534100.588167,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '73.6700000000000000',
      high: '74.6900000000000000',
      low: '69.9000000000000000',
      close: '72.8500000000000000',
      volume: '466422.9092200000000000',
      quoteVolume: 33586009.2516322,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '72.8500000000000000',
      high: '74.4300000000000000',
      low: '70.6000000000000000',
      close: '71.7800000000000000',
      volume: '307408.7038600000000000',
      quoteVolume: 22353656.7217245,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '71.7900000000000000',
      high: '76.5000000000000000',
      low: '70.3100000000000000',
      close: '73.8200000000000000',
      volume: '345014.8767600000000000',
      quoteVolume: 25320328.5480487,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '73.8300000000000000',
      high: '74.0100000000000000',
      low: '71.8300000000000000',
      close: '72.2300000000000000',
      volume: '102336.9821700000000000',
      quoteVolume: 7467848.3762795,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '72.2400000000000000',
      high: '72.7200000000000000',
      low: '68.9500000000000000',
      close: '69.8900000000000000',
      volume: '218635.5895900000000000',
      quoteVolume: 15458571.1783797,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '69.8900000000000000',
      high: '70.3700000000000000',
      low: '66.5000000000000000',
      close: '67.8700000000000000',
      volume: '203603.1860100000000000',
      quoteVolume: 13896456.9662964,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '67.8600000000000000',
      high: '74.7400000000000000',
      low: '67.8600000000000000',
      close: '74.3200000000000000',
      volume: '354469.1353800000000000',
      quoteVolume: 25357609.0003327,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '74.3100000000000000',
      high: '75.3600000000000000',
      low: '71.7800000000000000',
      close: '73.3600000000000000',
      volume: '273831.0476500000000000',
      quoteVolume: 20098972.4966065,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '73.3500000000000000',
      high: '74.4200000000000000',
      low: '72.7300000000000000',
      close: '73.6800000000000000',
      volume: '289626.4536600000000000',
      quoteVolume: 21306943.7993429,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '73.6500000000000000',
      high: '80.5800000000000000',
      low: '73.3100000000000000',
      close: '79.2200000000000000',
      volume: '538448.4052300000000000',
      quoteVolume: 41683556.4117655,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '79.2600000000000000',
      high: '82.7600000000000000',
      low: '74.5400000000000000',
      close: '77.7700000000000000',
      volume: '457004.0148400000000000',
      quoteVolume: 35750061.7116449,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '77.7300000000000000',
      high: '78.1600000000000000',
      low: '75.4000000000000000',
      close: '75.7000000000000000',
      volume: '236821.3124500000000000',
      quoteVolume: 18139200.7639219,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '75.7000000000000000',
      high: '76.9100000000000000',
      low: '71.6400000000000000',
      close: '74.9400000000000000',
      volume: '389445.4803700000000000',
      quoteVolume: 28863797.8869894,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '74.9500000000000000',
      high: '78.7500000000000000',
      low: '73.4000000000000000',
      close: '74.6100000000000000',
      volume: '357677.9279500000000000',
      quoteVolume: 27275279.8649669,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '74.5900000000000000',
      high: '75.5000000000000000',
      low: '72.4000000000000000',
      close: '74.4400000000000000',
      volume: '206081.7854800000000000',
      quoteVolume: 15267753.6578615,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '74.4400000000000000',
      high: '75.9000000000000000',
      low: '72.7700000000000000',
      close: '74.1900000000000000',
      volume: '240477.4366700000000000',
      quoteVolume: 17829294.3569958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '74.1800000000000000',
      high: '79.6100000000000000',
      low: '72.6600000000000000',
      close: '77.1100000000000000',
      volume: '422915.9252000000000000',
      quoteVolume: 32199715.1352361,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '77.1200000000000000',
      high: '95.0000000000000000',
      low: '76.8500000000000000',
      close: '86.5900000000000000',
      volume: '909602.1269400000000000',
      quoteVolume: 78091222.1082642,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '86.5900000000000000',
      high: '94.0100000000000000',
      low: '83.0000000000000000',
      close: '84.7300000000000000',
      volume: '797262.8597300000000000',
      quoteVolume: 69998434.01864,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '84.7100000000000000',
      high: '92.4900000000000000',
      low: '84.1000000000000000',
      close: '87.1600000000000000',
      volume: '627311.3557300000000000',
      quoteVolume: 55391993.134908,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '87.3400000000000000',
      high: '93.6100000000000000',
      low: '86.5000000000000000',
      close: '91.1700000000000000',
      volume: '644139.0646700000000000',
      quoteVolume: 58186818.859561,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '91.1500000000000000',
      high: '104.2500000000000000',
      low: '89.6900000000000000',
      close: '102.0200000000000000',
      volume: '673429.9261200000000000',
      quoteVolume: 66429508.1788531,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '102.0300000000000000',
      high: '107.1400000000000000',
      low: '90.7300000000000000',
      close: '95.4900000000000000',
      volume: '1179885.1078400000000000',
      quoteVolume: 116020063.777305,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '95.4900000000000000',
      high: '97.4800000000000000',
      low: '84.2700000000000000',
      close: '89.7600000000000000',
      volume: '914179.2254800000000000',
      quoteVolume: 80856683.9611846,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '89.7600000000000000',
      high: '90.5100000000000000',
      low: '85.1100000000000000',
      close: '86.5100000000000000',
      volume: '380542.1429700000000000',
      quoteVolume: 33352370.4500154,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '86.5500000000000000',
      high: '96.8100000000000000',
      low: '86.0700000000000000',
      close: '94.5800000000000000',
      volume: '584649.5175900000000000',
      quoteVolume: 54045051.6228448,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '94.5500000000000000',
      high: '94.5800000000000000',
      low: '87.1100000000000000',
      close: '91.1000000000000000',
      volume: '447735.7161500000000000',
      quoteVolume: 40656121.4410844,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '91.1000000000000000',
      high: '93.0200000000000000',
      low: '88.8300000000000000',
      close: '90.7900000000000000',
      volume: '335538.2330900000000000',
      quoteVolume: 30483197.1255505,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '90.7700000000000000',
      high: '92.0400000000000000',
      low: '85.8300000000000000',
      close: '87.7400000000000000',
      volume: '308520.2910900000000000',
      quoteVolume: 27641752.0456785,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '87.7400000000000000',
      high: '89.5300000000000000',
      low: '84.6200000000000000',
      close: '88.6100000000000000',
      volume: '208332.2952500000000000',
      quoteVolume: 18146600.071508,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '88.6700000000000000',
      high: '103.7700000000000000',
      low: '88.0100000000000000',
      close: '100.5000000000000000',
      volume: '832985.1612500000000000',
      quoteVolume: 81794059.9838869,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '100.5000000000000000',
      high: '105.0700000000000000',
      low: '98.8700000000000000',
      close: '102.6600000000000000',
      volume: '337657.9218500000000000',
      quoteVolume: 34485049.017298095,
      btcVolume: 4312.521556865614,
      usdVolume: 34485049.017298095,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '102.6600000000000000',
      high: '114.0000000000000000',
      low: '97.5000000000000000',
      close: '111.0000000000000000',
      volume: '447812.7599800000000000',
      quoteVolume: 46913902.85878797,
      btcVolume: 5689.1831608019,
      usdVolume: 46913902.85878797,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '111.0300000000000000',
      high: '121.8900000000000000',
      low: '109.9400000000000000',
      close: '116.2700000000000000',
      volume: '595659.2299900000000000',
      quoteVolume: 68097250.03244157,
      btcVolume: 7799.762188822423,
      usdVolume: 68097250.03244157,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '116.3000000000000000',
      high: '118.0000000000000000',
      low: '111.1100000000000000',
      close: '114.8100000000000000',
      volume: '230852.9642500000000000',
      quoteVolume: 26424422.174400397,
      btcVolume: 3034.2155133217652,
      usdVolume: 26424422.174400397,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '114.8700000000000000',
      high: '118.3300000000000000',
      low: '108.0000000000000000',
      close: '114.5300000000000000',
      volume: '220738.2597100000000000',
      quoteVolume: 25195330.963828564,
      btcVolume: 2920.4957713299823,
      usdVolume: 25195330.963828564,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '115.0500000000000000',
      high: '120.3500000000000000',
      low: '102.4000000000000000',
      close: '108.2100000000000000',
      volume: '507093.9330100000000000',
      quoteVolume: 57087056.14430182,
      btcVolume: 6672.278677065912,
      usdVolume: 57087056.14430182,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '107.4100000000000000',
      high: '114.9300000000000000',
      low: '104.5600000000000000',
      close: '114.3500000000000000',
      volume: '312792.4647700000000000',
      quoteVolume: 34046399.04506248,
      btcVolume: 4077.7128007930464,
      usdVolume: 34046399.04506248,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '114.8000000000000000',
      high: '116.1000000000000000',
      low: '110.5100000000000000',
      close: '112.3500000000000000',
      volume: '223549.1279200000000000',
      quoteVolume: 25341433.203971107,
      btcVolume: 2969.5187987480676,
      usdVolume: 25341433.203971107,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '112.3400000000000000',
      high: '116.4800000000000000',
      low: '111.2500000000000000',
      close: '114.3900000000000000',
      volume: '233743.1953000000000000',
      quoteVolume: 26688762.017144043,
      btcVolume: 3077.4531043299494,
      usdVolume: 26688762.017144043,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '114.4400000000000000',
      high: '116.2000000000000000',
      low: '105.0100000000000000',
      close: '105.9900000000000000',
      volume: '426880.8260100000000000',
      quoteVolume: 47600822.28424786,
      btcVolume: 5611.544120917245,
      usdVolume: 47600822.28424786,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '105.9900000000000000',
      high: '106.8000000000000000',
      low: '98.1300000000000000',
      close: '102.2000000000000000',
      volume: '673205.5704500000000000',
      quoteVolume: 69225543.10550998,
      btcVolume: 8824.314357109533,
      usdVolume: 69225543.10550998,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '102.2000000000000000',
      high: '105.2100000000000000',
      low: '100.1100000000000000',
      close: '103.6600000000000000',
      volume: '379894.4448300000000000',
      quoteVolume: 39022877.25634307,
      btcVolume: 5026.87552023653,
      usdVolume: 39022877.25634307,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '103.6100000000000000',
      high: '112.0700000000000000',
      low: '101.0000000000000000',
      close: '111.1900000000000000',
      volume: '488706.9407100000000000',
      quoteVolume: 51465078.74484297,
      btcVolume: 6669.936853023607,
      usdVolume: 51465078.74484297,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '111.2500000000000000',
      high: '121.0000000000000000',
      low: '109.5300000000000000',
      close: '116.7100000000000000',
      volume: '619673.8448900000000000',
      quoteVolume: 71546455.96240085,
      btcVolume: 9005.230709084188,
      usdVolume: 71546455.96240085,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '116.7400000000000000',
      high: '119.7000000000000000',
      low: '113.5000000000000000',
      close: '118.0100000000000000',
      volume: '454619.1137200000000000',
      quoteVolume: 52877073.47281,
      btcVolume: 6681.652583502133,
      usdVolume: 52877073.47281,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '118.0100000000000000',
      high: '118.9400000000000000',
      low: '111.0200000000000000',
      close: '114.6600000000000000',
      volume: '425242.5817000000000000',
      quoteVolume: 48979562.40265552,
      btcVolume: 6336.33411727269,
      usdVolume: 48979562.40265552,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '114.6600000000000000',
      high: '130.1200000000000000',
      low: '112.5400000000000000',
      close: '129.1600000000000000',
      volume: '793131.5001000000000000',
      quoteVolume: 97858566.95458615,
      btcVolume: 12435.906067485123,
      usdVolume: 97858566.95458615,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '129.1500000000000000',
      high: '138.5600000000000000',
      low: '124.8500000000000000',
      close: '135.6900000000000000',
      volume: '832736.8101700000000000',
      quoteVolume: 108840543.08616316,
      btcVolume: 13831.644364595131,
      usdVolume: 108840543.08616316,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '135.7900000000000000',
      high: '143.1900000000000000',
      low: '129.8600000000000000',
      close: '135.3200000000000000',
      volume: '1013528.9184000000000000',
      quoteVolume: 139315210.82220733,
      btcVolume: 17393.1824521332,
      usdVolume: 139315210.82220733,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '135.3200000000000000',
      high: '136.5200000000000000',
      low: '127.9200000000000000',
      close: '130.5500000000000000',
      volume: '477007.3052300000000000',
      quoteVolume: 63425735.66279704,
      btcVolume: 7773.751516352979,
      usdVolume: 63425735.66279704,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '130.5500000000000000',
      high: '133.0300000000000000',
      low: '125.2200000000000000',
      close: '132.0000000000000000',
      volume: '460272.4765100000000000',
      quoteVolume: 59616339.318167776,
      btcVolume: 7149.36358649792,
      usdVolume: 59616339.318167776,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '132.0300000000000000',
      high: '139.5000000000000000',
      low: '132.0100000000000000',
      close: '137.5400000000000000',
      volume: '389609.6416600000000000',
      quoteVolume: 52876679.67863012,
      btcVolume: 6089.583260704667,
      usdVolume: 52876679.67863012,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '137.5100000000000000',
      high: '138.2000000000000000',
      low: '133.1500000000000000',
      close: '136.4600000000000000',
      volume: '392160.2389100000000000',
      quoteVolume: 53217106.12389209,
      btcVolume: 5876.901296371626,
      usdVolume: 53217106.12389209,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '136.4500000000000000',
      high: '136.8200000000000000',
      low: '131.5300000000000000',
      close: '133.8000000000000000',
      volume: '302482.8519500000000000',
      quoteVolume: 40631956.395158365,
      btcVolume: 4412.679894293252,
      usdVolume: 40631956.395158365,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '133.8000000000000000',
      high: '136.8400000000000000',
      low: '128.0000000000000000',
      close: '135.2200000000000000',
      volume: '400793.1621100000000000',
      quoteVolume: 53271407.49490323,
      btcVolume: 5834.779980955947,
      usdVolume: 53232164.38223043,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '135.2500000000000000',
      high: '139.5000000000000000',
      low: '133.5000000000000000',
      close: '136.4300000000000000',
      volume: '373848.4380200000000000',
      quoteVolume: 51215339.67678405,
      btcVolume: 5598.390589121898,
      usdVolume: 51215339.67678405,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '136.4100000000000000',
      high: '136.7500000000000000',
      low: '133.0000000000000000',
      close: '135.4600000000000000',
      volume: '226900.6498000000000000',
      quoteVolume: 30613327.430043694,
      btcVolume: 3276.5296546410664,
      usdVolume: 30613327.430043694,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '135.4500000000000000',
      high: '141.2900000000000000',
      low: '131.0000000000000000',
      close: '138.2400000000000000',
      volume: '392322.7834100000000000',
      quoteVolume: 53942344.98886258,
      btcVolume: 5520.032818796717,
      usdVolume: 53942344.98886258,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '138.2400000000000000',
      high: '145.9000000000000000',
      low: '136.0100000000000000',
      close: '142.2600000000000000',
      volume: '663584.3836600000000000',
      quoteVolume: 93771724.38599907,
      btcVolume: 8742.486600283723,
      usdVolume: 93771724.38599907,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '142.2000000000000000',
      high: '143.3400000000000000',
      low: '136.1500000000000000',
      close: '137.5000000000000000',
      volume: '272572.6124300000000000',
      quoteVolume: 38252959.48642319,
      btcVolume: 3516.3861683369537,
      usdVolume: 38252959.48642319,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '137.5000000000000000',
      high: '137.5200000000000000',
      low: '132.6500000000000000',
      close: '135.7700000000000000',
      volume: '269139.9196400000000000',
      quoteVolume: 36393766.969366886,
      btcVolume: 3342.376735778028,
      usdVolume: 36393766.969366886,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '135.7800000000000000',
      high: '138.6300000000000000',
      low: '129.7500000000000000',
      close: '136.2900000000000000',
      volume: '342770.9536500000000000',
      quoteVolume: 46148912.96185091,
      btcVolume: 4050.110257770818,
      usdVolume: 46148912.96185091,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '136.3300000000000000',
      high: '138.5000000000000000',
      low: '127.5000000000000000',
      close: '132.2500000000000000',
      volume: '636720.2570400000000000',
      quoteVolume: 86027810.12377585,
      btcVolume: 6747.88318704931,
      usdVolume: 86027810.12377585,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '132.2400000000000000',
      high: '133.5000000000000000',
      low: '111.8200000000000000',
      close: '116.0100000000000000',
      volume: '925760.4631400000000000',
      quoteVolume: 110853611.31913063,
      btcVolume: 9337.051184083171,
      usdVolume: 110853611.31913063,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '116.0200000000000000',
      high: '122.1900000000000000',
      low: '114.4900000000000000',
      close: '120.0100000000000000',
      volume: '455533.6026900000000000',
      quoteVolume: 53918279.744928494,
      btcVolume: 4582.2478046452115,
      usdVolume: 53918279.744928494,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '119.9900000000000000',
      high: '137.0000000000000000',
      low: '116.0000000000000000',
      close: '133.8600000000000000',
      volume: '650765.6611200000000000',
      quoteVolume: 82923808.74529916,
      btcVolume: 6965.579657418369,
      usdVolume: 82923808.74529916,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '133.8800000000000000',
      high: '137.4400000000000000',
      low: '120.5400000000000000',
      close: '123.2000000000000000',
      volume: '808624.4477500000000000',
      quoteVolume: 105465556.33002208,
      btcVolume: 9174.964797036842,
      usdVolume: 105465556.33002208,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '123.2100000000000000',
      high: '127.0300000000000000',
      low: '113.8200000000000000',
      close: '123.1700000000000000',
      volume: '612082.5504700000000000',
      quoteVolume: 74031245.77021483,
      btcVolume: 6937.17995542507,
      usdVolume: 74031245.77021483,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '123.1600000000000000',
      high: '124.0000000000000000',
      low: '112.6300000000000000',
      close: '118.7700000000000000',
      volume: '468821.0334100000000000',
      quoteVolume: 54961125.00467627,
      btcVolume: 5357.843844817624,
      usdVolume: 54961125.00467627,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '118.7400000000000000',
      high: '123.1500000000000000',
      low: '116.7800000000000000',
      close: '121.3600000000000000',
      volume: '365004.8130000000000000',
      quoteVolume: 43802919.31616289,
      btcVolume: 3864.830877205342,
      usdVolume: 43802919.31616289,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '121.3400000000000000',
      high: '127.0300000000000000',
      low: '118.5100000000000000',
      close: '119.6100000000000000',
      volume: '374342.5657700000000000',
      quoteVolume: 45835115.28303999,
      btcVolume: 3922.4531882102947,
      usdVolume: 45835115.28303999,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '119.6400000000000000',
      high: '120.9900000000000000',
      low: '116.2200000000000000',
      close: '118.3500000000000000',
      volume: '223506.3921400000000000',
      quoteVolume: 26561837.46533212,
      btcVolume: 2393.4466400120245,
      usdVolume: 26561837.46533212,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '118.2900000000000000',
      high: '121.8000000000000000',
      low: '117.0600000000000000',
      close: '118.3100000000000000',
      volume: '153807.7581500000000000',
      quoteVolume: 18322698.17243,
      btcVolume: 1610.505545271505,
      usdVolume: 18322698.17243,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '118.2800000000000000',
      high: '120.3500000000000000',
      low: '117.0000000000000000',
      close: '119.4800000000000000',
      volume: '142378.6142900000000000',
      quoteVolume: 16885095.364536773,
      btcVolume: 1493.6997705844158,
      usdVolume: 16885095.364536773,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '119.4800000000000000',
      high: '125.0700000000000000',
      low: '117.7700000000000000',
      close: '122.9200000000000000',
      volume: '217712.8518000000000000',
      quoteVolume: 26152584.18533981,
      btcVolume: 2213.2029853237336,
      usdVolume: 26152584.18533981,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '122.9200000000000000',
      high: '124.0800000000000000',
      low: '117.6400000000000000',
      close: '119.2400000000000000',
      volume: '229937.7747500000000000',
      quoteVolume: 27739458.330567557,
      btcVolume: 2229.1310227514605,
      usdVolume: 27739458.330567557,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '119.2400000000000000',
      high: '120.1300000000000000',
      low: '102.5000000000000000',
      close: '108.3100000000000000',
      volume: '505822.3816400000000000',
      quoteVolume: 55612005.274497144,
      btcVolume: 4459.300542159586,
      usdVolume: 55612005.274497144,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '108.2900000000000000',
      high: '108.3300000000000000',
      low: '95.2100000000000000',
      close: '102.9800000000000000',
      volume: '559700.8997700000000000',
      quoteVolume: 56859127.19466299,
      btcVolume: 4940.505976488649,
      usdVolume: 56859127.19466299,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '103.0000000000000000',
      high: '107.0200000000000000',
      low: '100.6500000000000000',
      close: '104.8300000000000000',
      volume: '204844.2164100000000000',
      quoteVolume: 21371324.036817204,
      btcVolume: 1850.9778044022698,
      usdVolume: 21371324.036817204,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '104.8400000000000000',
      high: '105.0300000000000000',
      low: '97.6400000000000000',
      close: '101.0300000000000000',
      volume: '229334.5614600000000000',
      quoteVolume: 23264553.92123755,
      btcVolume: 2051.3447663181432,
      usdVolume: 23264553.92123755,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '101.0600000000000000',
      high: '101.9000000000000000',
      low: '87.5800000000000000',
      close: '89.7300000000000000',
      volume: '423313.0351200000000000',
      quoteVolume: 39325264.58610615,
      btcVolume: 3681.02471090942,
      usdVolume: 39325264.58610615,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '89.6800000000000000',
      high: '92.9900000000000000',
      low: '84.6000000000000000',
      close: '90.4300000000000000',
      volume: '382007.6629800000000000',
      quoteVolume: 34014962.47825661,
      btcVolume: 3290.421882815431,
      usdVolume: 34014962.47825661,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '90.4300000000000000',
      high: '91.9100000000000000',
      low: '76.6000000000000000',
      close: '79.5600000000000000',
      volume: '476050.7487700000000000',
      quoteVolume: 39592816.252067514,
      btcVolume: 3948.301710139775,
      usdVolume: 39592816.252067514,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '79.5700000000000000',
      high: '94.6500000000000000',
      low: '71.0000000000000000',
      close: '89.9500000000000000',
      volume: '611087.7265300000000000',
      quoteVolume: 53122108.969450906,
      btcVolume: 5523.905454198043,
      usdVolume: 53122108.969450906,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '89.9800000000000000',
      high: '104.5000000000000000',
      low: '86.8700000000000000',
      close: '101.1600000000000000',
      volume: '608935.5958700000000000',
      quoteVolume: 58255818.192272015,
      btcVolume: 5773.175422048092,
      usdVolume: 58215321.1838005,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '101.1500000000000000',
      high: '101.2600000000000000',
      low: '94.7300000000000000',
      close: '98.2300000000000000',
      volume: '364731.5720400000000000',
      quoteVolume: 35601483.447144754,
      btcVolume: 3411.5750330938563,
      usdVolume: 35601483.447144754,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '98.2500000000000000',
      high: '105.8800000000000000',
      low: '97.4200000000000000',
      close: '100.2500000000000000',
      volume: '355101.6718200000000000',
      quoteVolume: 35894490.36556177,
      btcVolume: 3363.2675353843997,
      usdVolume: 35894490.36556177,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '100.2600000000000000',
      high: '101.4100000000000000',
      low: '96.4000000000000000',
      close: '99.7000000000000000',
      volume: '247040.7271900000000000',
      quoteVolume: 24369602.513854142,
      btcVolume: 2307.538644188406,
      usdVolume: 24369602.513854142,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '99.7400000000000000',
      high: '100.9100000000000000',
      low: '91.1400000000000000',
      close: '95.3100000000000000',
      volume: '316815.0448700000000000',
      quoteVolume: 30213787.40266795,
      btcVolume: 2901.236765354251,
      usdVolume: 30213787.40266795,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '95.3100000000000000',
      high: '95.3500000000000000',
      low: '88.6200000000000000',
      close: '90.1500000000000000',
      volume: '264516.2157400000000000',
      quoteVolume: 24185689.349569995,
      btcVolume: 2407.8084257111295,
      usdVolume: 24185689.349569995,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '90.1000000000000000',
      high: '97.4500000000000000',
      low: '86.8000000000000000',
      close: '94.5000000000000000',
      volume: '354766.4814900000000000',
      quoteVolume: 32545800.829587497,
      btcVolume: 3344.4513821679425,
      usdVolume: 32545800.829587497,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '94.4300000000000000',
      high: '96.6700000000000000',
      low: '91.0600000000000000',
      close: '93.2200000000000000',
      volume: '228174.7700300000000000',
      quoteVolume: 21502628.636138897,
      btcVolume: 2150.016682373243,
      usdVolume: 21502628.636138897,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '93.2300000000000000',
      high: '94.5200000000000000',
      low: '89.8300000000000000',
      close: '94.0600000000000000',
      volume: '169001.4772100000000000',
      quoteVolume: 15639015.230352713,
      btcVolume: 1602.9055728539722,
      usdVolume: 15639015.230352713,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '94.0100000000000000',
      high: '96.0900000000000000',
      low: '86.6700000000000000',
      close: '88.7900000000000000',
      volume: '246284.6023800000000000',
      quoteVolume: 22269324.203205597,
      btcVolume: 2305.886333888476,
      usdVolume: 22269324.203205597,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '88.8300000000000000',
      high: '90.9000000000000000',
      low: '83.1300000000000000',
      close: '89.9300000000000000',
      volume: '196819.9021300000000000',
      quoteVolume: 17358318.803394448,
      btcVolume: 1837.2083760848138,
      usdVolume: 17358318.803394448,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '89.9700000000000000',
      high: '92.2600000000000000',
      low: '87.4800000000000000',
      close: '90.3300000000000000',
      volume: '232648.9633600000000000',
      quoteVolume: 20980770.098834805,
      btcVolume: 2194.949487131651,
      usdVolume: 20980770.098834805,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '90.3300000000000000',
      high: '92.7100000000000000',
      low: '88.5000000000000000',
      close: '90.1500000000000000',
      volume: '132728.7110700000000000',
      quoteVolume: 11999589.695907073,
      btcVolume: 1256.76640132148,
      usdVolume: 11999589.695907073,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '90.1200000000000000',
      high: '99.9500000000000000',
      low: '89.8100000000000000',
      close: '98.7600000000000000',
      volume: '280442.0190700000000000',
      quoteVolume: 26802062.262311406,
      btcVolume: 2708.4298976866316,
      usdVolume: 26802062.262311406,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '98.7700000000000000',
      high: '100.5900000000000000',
      low: '95.2300000000000000',
      close: '98.6400000000000000',
      volume: '256621.8002700000000000',
      quoteVolume: 25131509.021462236,
      btcVolume: 2491.621683425381,
      usdVolume: 25131509.021462236,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '98.6200000000000000',
      high: '98.7700000000000000',
      low: '92.5200000000000000',
      close: '94.7600000000000000',
      volume: '284185.5684900000000000',
      quoteVolume: 27263314.77004936,
      btcVolume: 2606.5500325395974,
      usdVolume: 27263314.77004936,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '94.8000000000000000',
      high: '96.7000000000000000',
      low: '93.5100000000000000',
      close: '94.1700000000000000',
      volume: '187442.2663600000000000',
      quoteVolume: 17836682.37128238,
      btcVolume: 1658.7354555809563,
      usdVolume: 17836682.37128238,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '94.1500000000000000',
      high: '94.8000000000000000',
      low: '90.1100000000000000',
      close: '92.6400000000000000',
      volume: '209831.4188500000000000',
      quoteVolume: 19390235.069303315,
      btcVolume: 1799.4440000625016,
      usdVolume: 19390235.069303315,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '92.6800000000000000',
      high: '106.7700000000000000',
      low: '92.6800000000000000',
      close: '96.6100000000000000',
      volume: '707982.8941800000000000',
      quoteVolume: 70027160.96589696,
      btcVolume: 5999.5126965229465,
      usdVolume: 70027160.96589696,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '96.5600000000000000',
      high: '99.3000000000000000',
      low: '91.0000000000000000',
      close: '92.9900000000000000',
      volume: '363691.9904700000000000',
      quoteVolume: 34611183.819958195,
      btcVolume: 2931.27156672291,
      usdVolume: 34611183.819958195,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '93.0500000000000000',
      high: '94.4500000000000000',
      low: '87.3600000000000000',
      close: '90.5500000000000000',
      volume: '295995.6655100000000000',
      quoteVolume: 26997231.240942493,
      btcVolume: 2299.5786668850474,
      usdVolume: 26997231.240942493,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '90.5900000000000000',
      high: '92.7500000000000000',
      low: '87.7200000000000000',
      close: '90.1400000000000000',
      volume: '226973.5770400000000000',
      quoteVolume: 20399520.9403848,
      btcVolume: 1729.0445142042847,
      usdVolume: 20399520.9403848,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '90.1400000000000000',
      high: '90.4500000000000000',
      low: '82.6400000000000000',
      close: '84.3300000000000000',
      volume: '244679.6808500000000000',
      quoteVolume: 21052903.37981216,
      btcVolume: 1782.1537264855651,
      usdVolume: 21052903.37981216,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '84.2500000000000000',
      high: '87.5000000000000000',
      low: '82.2700000000000000',
      close: '85.4600000000000000',
      volume: '240013.8068600000000000',
      quoteVolume: 20434411.59965533,
      btcVolume: 1765.6610402914791,
      usdVolume: 20434411.59965533,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '85.4600000000000000',
      high: '91.5700000000000000',
      low: '85.1500000000000000',
      close: '89.9400000000000000',
      volume: '196224.6058500000000000',
      quoteVolume: 17374539.532690413,
      btcVolume: 1524.9630326426839,
      usdVolume: 17374539.532690413,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '89.9200000000000000',
      high: '90.0400000000000000',
      low: '85.4500000000000000',
      close: '85.7900000000000000',
      volume: '148132.4291500000000000',
      quoteVolume: 12892549.668831954,
      btcVolume: 1130.0638225098155,
      usdVolume: 12892549.668831954,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '85.7900000000000000',
      high: '86.7900000000000000',
      low: '83.2600000000000000',
      close: '84.8800000000000000',
      volume: '166783.8095700000000000',
      quoteVolume: 14166418.159683961,
      btcVolume: 1274.1904971222834,
      usdVolume: 14166418.159683961,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '84.8300000000000000',
      high: '86.3200000000000000',
      low: '75.0000000000000000',
      close: '75.9000000000000000',
      volume: '301247.4537300000000000',
      quoteVolume: 24226106.115131296,
      btcVolume: 2325.4823677043105,
      usdVolume: 24226106.115131296,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '75.9100000000000000',
      high: '77.7000000000000000',
      low: '74.3600000000000000',
      close: '76.2500000000000000',
      volume: '155744.5086700000000000',
      quoteVolume: 11881666.830015346,
      btcVolume: 1170.7221866608465,
      usdVolume: 11881666.830015346,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '76.2400000000000000',
      high: '76.4200000000000000',
      low: '72.3400000000000000',
      close: '74.6700000000000000',
      volume: '186709.0272800000000000',
      quoteVolume: 13900659.419270849,
      btcVolume: 1370.0754829605883,
      usdVolume: 13900659.419270849,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '74.6300000000000000',
      high: '75.3500000000000000',
      low: '71.5000000000000000',
      close: '72.6500000000000000',
      volume: '131037.3773100000000000',
      quoteVolume: 9606480.326331407,
      btcVolume: 935.0616632613101,
      usdVolume: 9606480.326331407,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '72.6400000000000000',
      high: '78.0000000000000000',
      low: '71.4000000000000000',
      close: '76.2500000000000000',
      volume: '171758.4529300000000000',
      quoteVolume: 12942681.697281959,
      btcVolume: 1254.3177248522557,
      usdVolume: 12942681.697281959,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '76.3100000000000000',
      high: '78.9500000000000000',
      low: '75.3600000000000000',
      close: '77.1000000000000000',
      volume: '176320.8779000000000000',
      quoteVolume: 13602703.9572976,
      btcVolume: 1278.3583112223776,
      usdVolume: 13602703.9572976,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '77.1000000000000000',
      high: '77.3000000000000000',
      low: '73.3100000000000000',
      close: '75.1400000000000000',
      volume: '130272.1040200000000000',
      quoteVolume: 9809533.243689496,
      btcVolume: 914.3887166901854,
      usdVolume: 9809533.243689496,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '75.1300000000000000',
      high: '75.3300000000000000',
      low: '70.1400000000000000',
      close: '73.2700000000000000',
      volume: '188369.4364000000000000',
      quoteVolume: 13677422.750244448,
      btcVolume: 1345.3220954490534,
      usdVolume: 13677422.750244448,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '73.1800000000000000',
      high: '74.6400000000000000',
      low: '71.0000000000000000',
      close: '73.3100000000000000',
      volume: '159574.3573900000000000',
      quoteVolume: 11682660.11134095,
      btcVolume: 1163.5406280504114,
      usdVolume: 11682660.11134095,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '73.3800000000000000',
      high: '75.9200000000000000',
      low: '72.1000000000000000',
      close: '75.3800000000000000',
      volume: '126538.7728700000000000',
      quoteVolume: 9392367.036347631,
      btcVolume: 914.8262129553217,
      usdVolume: 9392367.036347631,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '75.3700000000000000',
      high: '75.6500000000000000',
      low: '71.3900000000000000',
      close: '73.3200000000000000',
      volume: '119835.6546400000000000',
      quoteVolume: 8766693.140755767,
      btcVolume: 866.621719030702,
      usdVolume: 8766693.140755767,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '73.3200000000000000',
      high: '74.9300000000000000',
      low: '70.5600000000000000',
      close: '72.1000000000000000',
      volume: '141416.0203900000000000',
      quoteVolume: 10265920.916051323,
      btcVolume: 1018.3454425401993,
      usdVolume: 10265920.916051323,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '72.1200000000000000',
      high: '75.4600000000000000',
      low: '72.0900000000000000',
      close: '73.5400000000000000',
      volume: '183788.5068600000000000',
      quoteVolume: 13607022.423831994,
      btcVolume: 1313.6613691337795,
      usdVolume: 13607022.423831994,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '73.5900000000000000',
      high: '73.7500000000000000',
      low: '72.1300000000000000',
      close: '72.9000000000000000',
      volume: '92746.0175100000000000',
      quoteVolume: 6757231.102277973,
      btcVolume: 662.8619923714382,
      usdVolume: 6757231.102277973,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '72.8800000000000000',
      high: '73.1400000000000000',
      low: '65.0000000000000000',
      close: '66.7300000000000000',
      volume: '279997.3057300000000000',
      quoteVolume: 19218269.137130044,
      btcVolume: 1926.7857817998536,
      usdVolume: 19218269.137130044,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '66.7300000000000000',
      high: '66.7700000000000000',
      low: '62.0000000000000000',
      close: '63.5900000000000000',
      volume: '206125.3285400000000000',
      quoteVolume: 13327397.63727353,
      btcVolume: 1401.9829055159644,
      usdVolume: 13327397.63727353,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '63.5500000000000000',
      high: '65.6000000000000000',
      low: '62.1600000000000000',
      close: '64.1700000000000000',
      volume: '188502.6473100000000000',
      quoteVolume: 12025107.367332652,
      btcVolume: 1260.478351604105,
      usdVolume: 12025107.367332652,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '64.1800000000000000',
      high: '65.3900000000000000',
      low: '62.2500000000000000',
      close: '64.2400000000000000',
      volume: '148167.1587700000000000',
      quoteVolume: 9443772.20454113,
      btcVolume: 985.5488740690859,
      usdVolume: 9443772.20454113,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '64.2300000000000000',
      high: '67.2100000000000000',
      low: '63.7400000000000000',
      close: '65.8900000000000000',
      volume: '160360.7877400000000000',
      quoteVolume: 10473417.107566353,
      btcVolume: 1088.2357260615822,
      usdVolume: 10473417.107566353,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '65.8500000000000000',
      high: '68.0300000000000000',
      low: '64.8000000000000000',
      close: '66.9200000000000000',
      volume: '210394.3789600000000000',
      quoteVolume: 13944978.885087235,
      btcVolume: 1390.3786949813993,
      usdVolume: 13944978.885087235,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '66.9200000000000000',
      high: '71.0000000000000000',
      low: '66.4700000000000000',
      close: '69.0600000000000000',
      volume: '285573.8842600000000000',
      quoteVolume: 19591536.12131445,
      btcVolume: 1861.1902617597352,
      usdVolume: 19591536.12131445,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '69.0000000000000000',
      high: '69.2400000000000000',
      low: '66.4000000000000000',
      close: '67.0100000000000000',
      volume: '219414.7318900000000000',
      quoteVolume: 14835718.502787702,
      btcVolume: 1407.2822863278584,
      usdVolume: 14835718.502787702,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '67.0200000000000000',
      high: '67.3900000000000000',
      low: '64.1400000000000000',
      close: '65.2400000000000000',
      volume: '165280.2625800000000000',
      quoteVolume: 10833905.336375384,
      btcVolume: 1026.921360854257,
      usdVolume: 10833905.336375384,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '65.2300000000000000',
      high: '68.0000000000000000',
      low: '63.5400000000000000',
      close: '64.8700000000000000',
      volume: '269355.4199300000000000',
      quoteVolume: 17769105.790459905,
      btcVolume: 1683.5510553061292,
      usdVolume: 17769105.790459905,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '64.8800000000000000',
      high: '70.5700000000000000',
      low: '64.5300000000000000',
      close: '68.7800000000000000',
      volume: '305074.1416000000000000',
      quoteVolume: 20786479.847835682,
      btcVolume: 1993.4143229328859,
      usdVolume: 20786479.847835682,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '68.7700000000000000',
      high: '71.4600000000000000',
      low: '68.0000000000000000',
      close: '70.3600000000000000',
      volume: '313588.1220800000000000',
      quoteVolume: 21958484.40247299,
      btcVolume: 2102.4332066868055,
      usdVolume: 21958484.40247299,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '70.3600000000000000',
      high: '71.3800000000000000',
      low: '67.7300000000000000',
      close: '69.8900000000000000',
      volume: '227135.8567900000000000',
      quoteVolume: 15787764.06820893,
      btcVolume: 1535.4445818677495,
      usdVolume: 15787764.06820893,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '69.8600000000000000',
      high: '73.1000000000000000',
      low: '69.7700000000000000',
      close: '70.8500000000000000',
      volume: '315418.1644100000000000',
      quoteVolume: 22463397.73757165,
      btcVolume: 2195.2854664792503,
      usdVolume: 22463397.73757165,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '70.8500000000000000',
      high: '71.8100000000000000',
      low: '68.2500000000000000',
      close: '69.8000000000000000',
      volume: '208661.7044600000000000',
      quoteVolume: 14616150.160225926,
      btcVolume: 1453.8574985722253,
      usdVolume: 14616150.160225926,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '69.8000000000000000',
      high: '70.0000000000000000',
      low: '68.3000000000000000',
      close: '69.1500000000000000',
      volume: '135127.9931800000000000',
      quoteVolume: 9339546.336077025,
      btcVolume: 912.766277649649,
      usdVolume: 9339546.336077025,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '69.1800000000000000',
      high: '69.5800000000000000',
      low: '67.3600000000000000',
      close: '68.9300000000000000',
      volume: '156416.2924400000000000',
      quoteVolume: 10722817.409428386,
      btcVolume: 1041.7990264891057,
      usdVolume: 10722817.409428386,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '68.9300000000000000',
      high: '71.5000000000000000',
      low: '68.2000000000000000',
      close: '70.5000000000000000',
      volume: '178914.2774100000000000',
      quoteVolume: 12510402.533513518,
      btcVolume: 1210.141755485563,
      usdVolume: 12510402.533513518,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '70.4900000000000000',
      high: '71.2800000000000000',
      low: '69.5000000000000000',
      close: '70.0400000000000000',
      volume: '123157.5877600000000000',
      quoteVolume: 8653254.98748152,
      btcVolume: 839.9682939747071,
      usdVolume: 8653254.98748152,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '70.0400000000000000',
      high: '73.6700000000000000',
      low: '69.3600000000000000',
      close: '72.6300000000000000',
      volume: '266145.5193300000000000',
      quoteVolume: 18990638.744624417,
      btcVolume: 1850.305593630325,
      usdVolume: 18990638.744624417,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '72.6700000000000000',
      high: '77.5900000000000000',
      low: '72.1500000000000000',
      close: '74.0400000000000000',
      volume: '331768.0453000000000000',
      quoteVolume: 24570461.734894328,
      btcVolume: 2405.01019871254,
      usdVolume: 24570461.734894328,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '74.0600000000000000',
      high: '80.3000000000000000',
      low: '73.9800000000000000',
      close: '77.8100000000000000',
      volume: '463500.2471200000000000',
      quoteVolume: 35715978.31337182,
      btcVolume: 3505.5073405779085,
      usdVolume: 35715978.31337182,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '77.7700000000000000',
      high: '80.9000000000000000',
      low: '72.6200000000000000',
      close: '76.5500000000000000',
      volume: '501398.1546800000000000',
      quoteVolume: 37733017.621873595,
      btcVolume: 3774.092404497032,
      usdVolume: 37733017.621873595,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '76.5600000000000000',
      high: '77.0800000000000000',
      low: '72.7800000000000000',
      close: '75.0500000000000000',
      volume: '285843.0479300000000000',
      quoteVolume: 21452170.229147196,
      btcVolume: 2108.9030586427175,
      usdVolume: 21452170.229147196,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '75.0600000000000000',
      high: '75.5800000000000000',
      low: '72.0600000000000000',
      close: '73.2200000000000000',
      volume: '211641.7919700000000000',
      quoteVolume: 15603395.96512544,
      btcVolume: 1554.647856825871,
      usdVolume: 15603395.96512544,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '73.2000000000000000',
      high: '73.2200000000000000',
      low: '71.0300000000000000',
      close: '72.2300000000000000',
      volume: '219379.8472300000000000',
      quoteVolume: 15822626.780132813,
      btcVolume: 1585.411547795001,
      usdVolume: 15822626.780132813,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '72.2600000000000000',
      high: '74.2400000000000000',
      low: '61.8800000000000000',
      close: '66.5400000000000000',
      volume: '526339.5301500000000000',
      quoteVolume: 36437108.07404466,
      btcVolume: 3714.5060783639537,
      usdVolume: 36437108.07404466,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '66.5400000000000000',
      high: '67.4200000000000000',
      low: '54.3400000000000000',
      close: '54.5700000000000000',
      volume: '896194.8912400000000000',
      quoteVolume: 54840616.36898153,
      btcVolume: 6077.979287792019,
      usdVolume: 54840616.36898153,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '54.5700000000000000',
      high: '58.3400000000000000',
      low: '52.8400000000000000',
      close: '57.4300000000000000',
      volume: '780933.6156200000000000',
      quoteVolume: 43514734.915106036,
      btcVolume: 5172.0466578427,
      usdVolume: 43514734.915106036,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '57.4800000000000000',
      high: '57.5600000000000000',
      low: '50.3000000000000000',
      close: '55.1900000000000000',
      volume: '620655.1100700000000000',
      quoteVolume: 33894831.566069685,
      btcVolume: 4162.554218117035,
      usdVolume: 33894831.566069685,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '55.1700000000000000',
      high: '56.6100000000000000',
      low: '52.6100000000000000',
      close: '55.8600000000000000',
      volume: '390319.7644900000000000',
      quoteVolume: 21346773.06550875,
      btcVolume: 2660.2624788155385,
      usdVolume: 21346773.06550875,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '55.8600000000000000',
      high: '56.1600000000000000',
      low: '53.6700000000000000',
      close: '55.3700000000000000',
      volume: '256916.9284600000000000',
      quoteVolume: 14172326.366005592,
      btcVolume: 1741.149072225589,
      usdVolume: 14172326.366005592,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '55.3700000000000000',
      high: '55.5800000000000000',
      low: '52.1300000000000000',
      close: '53.8200000000000000',
      volume: '227396.1860800000000000',
      quoteVolume: 12189476.348992856,
      btcVolume: 1518.3799061716697,
      usdVolume: 12189476.348992856,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '53.8400000000000000',
      high: '56.2500000000000000',
      low: '52.4600000000000000',
      close: '55.9900000000000000',
      volume: '369529.6878700000000000',
      quoteVolume: 20182788.05999346,
      btcVolume: 2510.805443251447,
      usdVolume: 20182788.05999346,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '56.0100000000000000',
      high: '57.6000000000000000',
      low: '54.8200000000000000',
      close: '55.6300000000000000',
      volume: '333516.6371400000000000',
      quoteVolume: 18701415.035006244,
      btcVolume: 2241.634536922254,
      usdVolume: 18701415.035006244,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '55.5900000000000000',
      high: '56.6600000000000000',
      low: '55.1300000000000000',
      close: '56.2300000000000000',
      volume: '215239.2591000000000000',
      quoteVolume: 12024564.531152936,
      btcVolume: 1459.108573099224,
      usdVolume: 12024564.531152936,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '56.2800000000000000',
      high: '57.0700000000000000',
      low: '53.6200000000000000',
      close: '56.4400000000000000',
      volume: '253120.5364200000000000',
      quoteVolume: 14050677.75934256,
      btcVolume: 1717.4520781431509,
      usdVolume: 14050677.75934256,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '56.4500000000000000',
      high: '57.5000000000000000',
      low: '55.2200000000000000',
      close: '56.3000000000000000',
      volume: '247079.7704500000000000',
      quoteVolume: 13984268.782087287,
      btcVolume: 1718.1473442856827,
      usdVolume: 13984268.782087287,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '56.3000000000000000',
      high: '57.4000000000000000',
      low: '55.5700000000000000',
      close: '56.6900000000000000',
      volume: '177602.1005800000000000',
      quoteVolume: 10003772.607079951,
      btcVolume: 1237.007987145328,
      usdVolume: 10003772.607079951,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '56.6900000000000000',
      high: '56.8000000000000000',
      low: '52.9500000000000000',
      close: '54.4100000000000000',
      volume: '255560.2861700000000000',
      quoteVolume: 14075211.354071734,
      btcVolume: 1771.6354364948543,
      usdVolume: 14075211.354071734,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '54.4200000000000000',
      high: '58.6500000000000000',
      low: '53.2500000000000000',
      close: '57.4600000000000000',
      volume: '314651.2758500000000000',
      quoteVolume: 17736478.819784664,
      btcVolume: 2198.803572764758,
      usdVolume: 17736478.819784664,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '57.4600000000000000',
      high: '58.4500000000000000',
      low: '56.4700000000000000',
      close: '56.9500000000000000',
      volume: '184191.0427500000000000',
      quoteVolume: 10558792.262372462,
      btcVolume: 1286.8688325201551,
      usdVolume: 10558792.262372462,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '56.9500000000000000',
      high: '60.0600000000000000',
      low: '56.6000000000000000',
      close: '59.1200000000000000',
      volume: '337686.6303300000000000',
      quoteVolume: 19730452.316288013,
      btcVolume: 2355.7027861823267,
      usdVolume: 19730452.316288013,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '59.1000000000000000',
      high: '59.3300000000000000',
      low: '57.0000000000000000',
      close: '57.4700000000000000',
      volume: '215031.0358800000000000',
      quoteVolume: 12487431.424961781,
      btcVolume: 1463.474709248515,
      usdVolume: 12487431.424961781,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '57.4800000000000000',
      high: '58.4200000000000000',
      low: '55.3300000000000000',
      close: '55.8500000000000000',
      volume: '265707.6043700000000000',
      quoteVolume: 15003032.363513099,
      btcVolume: 1779.3269982097474,
      usdVolume: 15003032.363513099,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '55.8500000000000000',
      high: '56.5000000000000000',
      low: '55.4100000000000000',
      close: '55.7000000000000000',
      volume: '101055.5079000000000000',
      quoteVolume: 5657808.268919437,
      btcVolume: 679.6634476450376,
      usdVolume: 5657808.268919437,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '55.7100000000000000',
      high: '57.6900000000000000',
      low: '55.4700000000000000',
      close: '56.4600000000000000',
      volume: '157772.3878500000000000',
      quoteVolume: 8939980.18918927,
      btcVolume: 1071.554187571745,
      usdVolume: 8939980.18918927,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '56.4400000000000000',
      high: '57.1000000000000000',
      low: '55.9400000000000000',
      close: '56.8300000000000000',
      volume: '133356.2528900000000000',
      quoteVolume: 7554339.147984878,
      btcVolume: 910.4939564933641,
      usdVolume: 7554339.147984878,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '56.8300000000000000',
      high: '57.3900000000000000',
      low: '53.4000000000000000',
      close: '54.5800000000000000',
      volume: '329395.4878400000000000',
      quoteVolume: 18207494.81235211,
      btcVolume: 2200.181196563216,
      usdVolume: 18207494.81235211,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '54.5700000000000000',
      high: '55.3900000000000000',
      low: '51.0000000000000000',
      close: '52.4500000000000000',
      volume: '297730.1499600000000000',
      quoteVolume: 15783948.682937246,
      btcVolume: 1965.4916381767648,
      usdVolume: 15783948.682937246,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '52.4500000000000000',
      high: '55.2200000000000000',
      low: '51.5700000000000000',
      close: '54.9400000000000000',
      volume: '238776.4036000000000000',
      quoteVolume: 12771309.090288963,
      btcVolume: 1589.7400371088647,
      usdVolume: 12771309.090288963,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '54.9500000000000000',
      high: '55.0100000000000000',
      low: '52.1900000000000000',
      close: '53.2100000000000000',
      volume: '212503.0760200000000000',
      quoteVolume: 11342598.709450595,
      btcVolume: 1428.7754993429342,
      usdVolume: 11342598.709450595,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '53.2100000000000000',
      high: '54.1600000000000000',
      low: '52.5400000000000000',
      close: '53.7200000000000000',
      volume: '152608.9214200000000000',
      quoteVolume: 8152589.987414735,
      btcVolume: 1023.959008729595,
      usdVolume: 8152589.987414735,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '53.7200000000000000',
      high: '55.4600000000000000',
      low: '52.5800000000000000',
      close: '54.8500000000000000',
      volume: '208172.7019100000000000',
      quoteVolume: 11213347.343628593,
      btcVolume: 1399.9846240450204,
      usdVolume: 11213347.343628593,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '54.8500000000000000',
      high: '55.7400000000000000',
      low: '53.6700000000000000',
      close: '54.5700000000000000',
      volume: '208443.7668600000000000',
      quoteVolume: 11370329.079330875,
      btcVolume: 1383.3455861262153,
      usdVolume: 11370329.079330875,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '54.5700000000000000',
      high: '55.0900000000000000',
      low: '52.9700000000000000',
      close: '53.3900000000000000',
      volume: '159505.0758900000000000',
      quoteVolume: 8659267.226605335,
      btcVolume: 1058.7525600589156,
      usdVolume: 8659267.226605335,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '53.3800000000000000',
      high: '53.4400000000000000',
      low: '47.5300000000000000',
      close: '49.5000000000000000',
      volume: '470048.8542900000000000',
      quoteVolume: 23488078.022841353,
      btcVolume: 3076.301841104244,
      usdVolume: 23488078.022841353,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '49.4800000000000000',
      high: '50.3200000000000000',
      low: '48.3000000000000000',
      close: '49.5400000000000000',
      volume: '308330.5219600000000000',
      quoteVolume: 15231954.366521599,
      btcVolume: 2047.6661467292997,
      usdVolume: 15231954.366521599,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '49.5500000000000000',
      high: '58.7000000000000000',
      low: '49.0100000000000000',
      close: '56.9300000000000000',
      volume: '736498.2495500000000000',
      quoteVolume: 40028251.61986273,
      btcVolume: 4942.253267416375,
      usdVolume: 40028251.61986273,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '56.9300000000000000',
      high: '63.3700000000000000',
      low: '54.1400000000000000',
      close: '56.7100000000000000',
      volume: '1011134.5544100000000000',
      quoteVolume: 58374697.06996285,
      btcVolume: 6236.904792516147,
      usdVolume: 58374697.06996285,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '56.6900000000000000',
      high: '62.8500000000000000',
      low: '55.5900000000000000',
      close: '59.7800000000000000',
      volume: '659707.7341100000000000',
      quoteVolume: 39098216.43731671,
      btcVolume: 4118.114476739268,
      usdVolume: 39098216.43731671,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '59.7900000000000000',
      high: '62.4700000000000000',
      low: '57.4000000000000000',
      close: '57.7000000000000000',
      volume: '539486.7688600000000000',
      quoteVolume: 31869821.10145664,
      btcVolume: 3367.080583556967,
      usdVolume: 31869821.10145664,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '57.7000000000000000',
      high: '60.7500000000000000',
      low: '57.6500000000000000',
      close: '59.7500000000000000',
      volume: '399523.8960500000000000',
      quoteVolume: 23770607.904536624,
      btcVolume: 2536.351568802833,
      usdVolume: 23770607.904536624,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '59.7600000000000000',
      high: '61.0500000000000000',
      low: '56.8800000000000000',
      close: '57.8900000000000000',
      volume: '341531.2851900000000000',
      quoteVolume: 20067287.16194507,
      btcVolume: 2180.7999647255724,
      usdVolume: 20067287.16194507,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '57.9000000000000000',
      high: '59.5600000000000000',
      low: '57.0200000000000000',
      close: '58.2900000000000000',
      volume: '268866.1439300000000000',
      quoteVolume: 15690777.836845942,
      btcVolume: 1711.8755410860422,
      usdVolume: 15690777.836845942,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '58.2900000000000000',
      high: '58.8000000000000000',
      low: '56.6800000000000000',
      close: '58.2300000000000000',
      volume: '221222.3294000000000000',
      quoteVolume: 12800948.223556288,
      btcVolume: 1400.6610473174119,
      usdVolume: 12800948.223556288,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '58.2400000000000000',
      high: '59.1900000000000000',
      low: '57.7300000000000000',
      close: '58.2500000000000000',
      volume: '150629.3718500000000000',
      quoteVolume: 8789951.618953658,
      btcVolume: 947.7305790128487,
      usdVolume: 8789951.618953658,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '58.2400000000000000',
      high: '58.7500000000000000',
      low: '57.1800000000000000',
      close: '58.2900000000000000',
      volume: '141294.2567900000000000',
      quoteVolume: 8186539.4970356515,
      btcVolume: 888.9066093561216,
      usdVolume: 8186539.4970356515,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '58.2900000000000000',
      high: '62.4000000000000000',
      low: '57.8700000000000000',
      close: '61.2900000000000000',
      volume: '407402.9069300000000000',
      quoteVolume: 24699682.594704557,
      btcVolume: 2665.317399877282,
      usdVolume: 24699682.594704557,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '61.2600000000000000',
      high: '64.1500000000000000',
      low: '60.8900000000000000',
      close: '63.1200000000000000',
      volume: '527467.4519600000000000',
      quoteVolume: 33010265.398333196,
      btcVolume: 3542.273311220224,
      usdVolume: 33010265.398333196,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '63.1300000000000000',
      high: '64.3300000000000000',
      low: '62.5600000000000000',
      close: '64.1100000000000000',
      volume: '334742.1432800000000000',
      quoteVolume: 21184873.260657944,
      btcVolume: 2268.6466351593385,
      usdVolume: 21184873.260657944,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '64.1100000000000000',
      high: '64.3300000000000000',
      low: '60.2700000000000000',
      close: '61.3900000000000000',
      volume: '327504.1707300000000000',
      quoteVolume: 20341773.350589715,
      btcVolume: 2200.2788075310846,
      usdVolume: 20341773.350589715,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '61.4000000000000000',
      high: '62.4900000000000000',
      low: '58.9300000000000000',
      close: '60.3800000000000000',
      volume: '345551.4396900000000000',
      quoteVolume: 20960125.60154757,
      btcVolume: 2332.7802751493164,
      usdVolume: 20960125.60154757,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '60.3600000000000000',
      high: '62.3700000000000000',
      low: '60.1700000000000000',
      close: '62.0400000000000000',
      volume: '196430.9663500000000000',
      quoteVolume: 12033723.069156943,
      btcVolume: 1365.127796378876,
      usdVolume: 12033723.069156943,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '62.0400000000000000',
      high: '66.1900000000000000',
      low: '60.8000000000000000',
      close: '63.6500000000000000',
      volume: '428980.9520600000000000',
      quoteVolume: 27218648.713102452,
      btcVolume: 3057.9731993427763,
      usdVolume: 27218648.713102452,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '63.6500000000000000',
      high: '64.3300000000000000',
      low: '60.4700000000000000',
      close: '61.8400000000000000',
      volume: '408664.6897800000000000',
      quoteVolume: 25451946.05196973,
      btcVolume: 2882.6678622354775,
      usdVolume: 25451946.05196973,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '61.8400000000000000',
      high: '62.2500000000000000',
      low: '59.7500000000000000',
      close: '61.4200000000000000',
      volume: '299554.9198200000000000',
      quoteVolume: 18351569.172878858,
      btcVolume: 2100.756847368965,
      usdVolume: 18351569.172878858,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '61.4500000000000000',
      high: '62.0500000000000000',
      low: '60.2700000000000000',
      close: '60.9600000000000000',
      volume: '175581.9554600000000000',
      quoteVolume: 10721665.62536904,
      btcVolume: 1223.0289182322622,
      usdVolume: 10721665.62536904,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '60.9600000000000000',
      high: '61.1000000000000000',
      low: '58.2500000000000000',
      close: '59.0000000000000000',
      volume: '289023.5733800000000000',
      quoteVolume: 17173463.110559292,
      btcVolume: 1981.3152223352774,
      usdVolume: 17173463.110559292,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '59.0000000000000000',
      high: '60.7500000000000000',
      low: '56.2000000000000000',
      close: '57.6000000000000000',
      volume: '331442.8743400000000000',
      quoteVolume: 19338540.966621064,
      btcVolume: 2257.9348903748273,
      usdVolume: 19338540.966621064,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '57.6100000000000000',
      high: '58.9800000000000000',
      low: '57.1400000000000000',
      close: '58.3000000000000000',
      volume: '135649.0746000000000000',
      quoteVolume: 7869798.748988588,
      btcVolume: 927.7112832694024,
      usdVolume: 7869798.748988588,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '58.2900000000000000',
      high: '59.9700000000000000',
      low: '57.5500000000000000',
      close: '59.2200000000000000',
      volume: '189990.6350700000000000',
      quoteVolume: 11229720.96215726,
      btcVolume: 1318.1179952916186,
      usdVolume: 11229720.96215726,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '59.2100000000000000',
      high: '59.3000000000000000',
      low: '54.0000000000000000',
      close: '55.8100000000000000',
      volume: '295818.0605500000000000',
      quoteVolume: 16800996.48111148,
      btcVolume: 2019.3958745827504,
      usdVolume: 16800996.48111148,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '55.8000000000000000',
      high: '56.2300000000000000',
      low: '54.2000000000000000',
      close: '55.1300000000000000',
      volume: '245040.3911600000000000',
      quoteVolume: 13537640.598777153,
      btcVolume: 1669.4864925290328,
      usdVolume: 13537640.598777153,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '55.1300000000000000',
      high: '56.1600000000000000',
      low: '54.6100000000000000',
      close: '54.9800000000000000',
      volume: '224543.3721000000000000',
      quoteVolume: 12409649.698862612,
      btcVolume: 1529.485427593074,
      usdVolume: 12409649.698862612,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '54.9900000000000000',
      high: '55.2900000000000000',
      low: '48.5100000000000000',
      close: '50.6100000000000000',
      volume: '439158.0191300000000000',
      quoteVolume: 22774364.172612227,
      btcVolume: 2908.36978884641,
      usdVolume: 22774364.172612227,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '50.6100000000000000',
      high: '51.5500000000000000',
      low: '43.5800000000000000',
      close: '47.3200000000000000',
      volume: '922303.8905500000000000',
      quoteVolume: 43869831.870156966,
      btcVolume: 6058.957447311882,
      usdVolume: 43869831.870156966,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '47.3200000000000000',
      high: '48.5300000000000000',
      low: '46.1300000000000000',
      close: '48.1000000000000000',
      volume: '301729.2088500000000000',
      quoteVolume: 14284224.628105655,
      btcVolume: 1978.2053346800967,
      usdVolume: 14284224.628105655,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '48.1100000000000000',
      high: '48.2400000000000000',
      low: '43.4500000000000000',
      close: '43.8600000000000000',
      volume: '419515.9268500000000000',
      quoteVolume: 19189897.614486806,
      btcVolume: 2708.0365194507826,
      usdVolume: 19189897.614486806,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '43.8700000000000000',
      high: '47.4200000000000000',
      low: '42.0400000000000000',
      close: '45.4700000000000000',
      volume: '568354.8930000000000000',
      quoteVolume: 25558493.400256857,
      btcVolume: 3690.2398898192073,
      usdVolume: 25558493.400256857,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '45.4800000000000000',
      high: '47.5700000000000000',
      low: '45.2200000000000000',
      close: '46.9100000000000000',
      volume: '310692.2824100000000000',
      quoteVolume: 14415831.257585848,
      btcVolume: 2017.545020006212,
      usdVolume: 14415831.257585848,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '46.9100000000000000',
      high: '48.6900000000000000',
      low: '44.5100000000000000',
      close: '47.7000000000000000',
      volume: '455208.2126200000000000',
      quoteVolume: 21261996.524687037,
      btcVolume: 2940.3101183783738,
      usdVolume: 21261996.524687037,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '47.6900000000000000',
      high: '48.0000000000000000',
      low: '46.0100000000000000',
      close: '46.7900000000000000',
      volume: '267853.2410700000000000',
      quoteVolume: 12673681.758905394,
      btcVolume: 1689.725092052278,
      usdVolume: 12673681.758905394,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '46.7800000000000000',
      high: '49.3300000000000000',
      low: '46.7300000000000000',
      close: '48.5400000000000000',
      volume: '300683.0915400000000000',
      quoteVolume: 14490088.801178327,
      btcVolume: 1907.3941093084677,
      usdVolume: 14490088.801178327,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '48.5500000000000000',
      high: '48.7600000000000000',
      low: '46.5300000000000000',
      close: '47.2100000000000000',
      volume: '270873.2850800000000000',
      quoteVolume: 12935690.65358488,
      btcVolume: 1697.6778943997815,
      usdVolume: 12935690.65358488,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '47.2100000000000000',
      high: '48.4500000000000000',
      low: '45.5000000000000000',
      close: '47.4500000000000000',
      volume: '313487.6048300000000000',
      quoteVolume: 14677077.515154386,
      btcVolume: 1995.4070593380043,
      usdVolume: 14677077.515154386,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '47.4500000000000000',
      high: '47.5700000000000000',
      low: '44.7100000000000000',
      close: '45.4500000000000000',
      volume: '302395.0200900000000000',
      quoteVolume: 13878732.659008326,
      btcVolume: 1902.976734313874,
      usdVolume: 13878732.659008326,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '45.4400000000000000',
      high: '46.2200000000000000',
      low: '44.2600000000000000',
      close: '44.7500000000000000',
      volume: '323943.4612000000000000',
      quoteVolume: 14690325.25946848,
      btcVolume: 2010.2978077888893,
      usdVolume: 14690325.25946848,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '44.7600000000000000',
      high: '46.7000000000000000',
      low: '43.6900000000000000',
      close: '44.6700000000000000',
      volume: '539077.4009800000000000',
      quoteVolume: 24320896.92843568,
      btcVolume: 3326.541904574674,
      usdVolume: 24320896.92843568,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '44.6400000000000000',
      high: '45.2100000000000000',
      low: '43.7000000000000000',
      close: '44.8100000000000000',
      volume: '444681.4243600000000000',
      quoteVolume: 19790933.976291418,
      btcVolume: 2706.2151928563303,
      usdVolume: 19790933.976291418,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '44.7800000000000000',
      high: '45.5000000000000000',
      low: '44.0500000000000000',
      close: '45.2800000000000000',
      volume: '271289.1369300000000000',
      quoteVolume: 12150894.310600491,
      btcVolume: 1640.903702691806,
      usdVolume: 12150894.310600491,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '45.2600000000000000',
      high: '46.5000000000000000',
      low: '44.9400000000000000',
      close: '45.1500000000000000',
      volume: '195130.2029600000000000',
      quoteVolume: 8895004.035972556,
      btcVolume: 1181.7389955788406,
      usdVolume: 8895004.035972556,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '45.1400000000000000',
      high: '46.2000000000000000',
      low: '44.8100000000000000',
      close: '45.5600000000000000',
      volume: '131662.0929900000000000',
      quoteVolume: 5984698.629427009,
      btcVolume: 800.0403835402077,
      usdVolume: 5984698.629427009,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '45.5500000000000000',
      high: '45.8700000000000000',
      low: '44.0400000000000000',
      close: '44.4500000000000000',
      volume: '217927.8825200000000000',
      quoteVolume: 9810024.721531982,
      btcVolume: 1315.4281615543798,
      usdVolume: 9810024.721531982,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '44.4500000000000000',
      high: '44.6700000000000000',
      low: '43.6000000000000000',
      close: '44.0900000000000000',
      volume: '150376.5875200000000000',
      quoteVolume: 6644775.029064859,
      btcVolume: 910.4725316157349,
      usdVolume: 6644775.029064859,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '44.0700000000000000',
      high: '44.4600000000000000',
      low: '43.2700000000000000',
      close: '43.7000000000000000',
      volume: '134280.0069800000000000',
      quoteVolume: 5891144.238883986,
      btcVolume: 816.6022311473681,
      usdVolume: 5891144.238883986,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '43.7000000000000000',
      high: '44.1100000000000000',
      low: '42.8400000000000000',
      close: '43.6500000000000000',
      volume: '169652.1601800000000000',
      quoteVolume: 7380545.620045383,
      btcVolume: 1028.175549454187,
      usdVolume: 7380545.620045383,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '43.6500000000000000',
      high: '44.5500000000000000',
      low: '43.4300000000000000',
      close: '44.4400000000000000',
      volume: '127640.0717900000000000',
      quoteVolume: 5608098.184686444,
      btcVolume: 775.0595310858355,
      usdVolume: 5608098.184686444,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '44.4400000000000000',
      high: '44.6700000000000000',
      low: '43.1900000000000000',
      close: '43.2500000000000000',
      volume: '121534.2323000000000000',
      quoteVolume: 5321086.2473528925,
      btcVolume: 744.3880130799254,
      usdVolume: 5321086.2473528925,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '43.2800000000000000',
      high: '44.0400000000000000',
      low: '42.9000000000000000',
      close: '43.4400000000000000',
      volume: '105006.6976000000000000',
      quoteVolume: 4563268.2074588975,
      btcVolume: 643.0807131329308,
      usdVolume: 4563268.2074588975,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '43.4600000000000000',
      high: '43.5600000000000000',
      low: '39.0200000000000000',
      close: '39.9900000000000000',
      volume: '373430.2658200000000000',
      quoteVolume: 15295271.543124389,
      btcVolume: 2193.410071747004,
      usdVolume: 15295271.543124389,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '39.9800000000000000',
      high: '40.1300000000000000',
      low: '36.1000000000000000',
      close: '36.9200000000000000',
      volume: '645072.3422400000000000',
      quoteVolume: 24673081.77184135,
      btcVolume: 3668.629711227305,
      usdVolume: 24673081.77184135,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '36.9400000000000000',
      high: '41.7100000000000000',
      low: '35.7800000000000000',
      close: '40.7900000000000000',
      volume: '667785.3073500000000000',
      quoteVolume: 25701967.270766664,
      btcVolume: 3786.72926837403,
      usdVolume: 25701967.270766664,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '40.7900000000000000',
      high: '41.1000000000000000',
      low: '39.0600000000000000',
      close: '39.6500000000000000',
      volume: '240386.3147900000000000',
      quoteVolume: 9577045.423953377,
      btcVolume: 1337.9051700822683,
      usdVolume: 9577045.423953377,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '39.6600000000000000',
      high: '40.4100000000000000',
      low: '39.2500000000000000',
      close: '40.1700000000000000',
      volume: '151508.4764800000000000',
      quoteVolume: 6050598.731822773,
      btcVolume: 845.4937670166742,
      usdVolume: 6050598.731822773,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '40.1500000000000000',
      high: '40.2200000000000000',
      low: '39.4100000000000000',
      close: '39.6700000000000000',
      volume: '91777.0964000000000000',
      quoteVolume: 3651414.4911812823,
      btcVolume: 511.12426012410907,
      usdVolume: 3651414.4911812823,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '39.6700000000000000',
      high: '42.1600000000000000',
      low: '39.6200000000000000',
      close: '41.8200000000000000',
      volume: '178219.7773000000000000',
      quoteVolume: 7343575.467333499,
      btcVolume: 1010.9593601908497,
      usdVolume: 7343575.467333499,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '41.8400000000000000',
      high: '42.6700000000000000',
      low: '40.2100000000000000',
      close: '40.5600000000000000',
      volume: '288871.4672100000000000',
      quoteVolume: 12041679.124624727,
      btcVolume: 1606.172428630869,
      usdVolume: 12041679.124624727,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '40.5600000000000000',
      high: '41.1300000000000000',
      low: '39.6800000000000000',
      close: '40.0600000000000000',
      volume: '211464.7632900000000000',
      quoteVolume: 8551583.328070415,
      btcVolume: 1173.559105839882,
      usdVolume: 8551583.328070415,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '40.3300000000000000',
      high: '40.4800000000000000',
      low: '39.3800000000000000',
      close: '40.0800000000000000',
      volume: '136222.9401800000000000',
      quoteVolume: 5447546.31914613,
      btcVolume: 755.370742894901,
      usdVolume: 5447546.31914613,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '40.1100000000000000',
      high: '41.7300000000000000',
      low: '39.5800000000000000',
      close: '41.4400000000000000',
      volume: '194299.1207600000000000',
      quoteVolume: 7858546.46046011,
      btcVolume: 1081.0147742755657,
      usdVolume: 7858546.46046011,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '39.9900000000000000',
      high: '41.5000000000000000',
      low: '39.6700000000000000',
      close: '40.9500000000000000',
      volume: '219194.0915600000000000',
      quoteVolume: 8901146.687390905,
      btcVolume: 1233.9824964047334,
      usdVolume: 8901146.687390905,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '40.9600000000000000',
      high: '43.7600000000000000',
      low: '40.9100000000000000',
      close: '42.8300000000000000',
      volume: '284295.3329600000000000',
      quoteVolume: 12068166.856607929,
      btcVolume: 1649.5543814055918,
      usdVolume: 12068166.856607929,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '42.8400000000000000',
      high: '43.7600000000000000',
      low: '42.1400000000000000',
      close: '43.0800000000000000',
      volume: '337077.7113200000000000',
      quoteVolume: 14472707.652529165,
      btcVolume: 1964.1795286823033,
      usdVolume: 14472707.652529165,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '43.0700000000000000',
      high: '44.1800000000000000',
      low: '41.8200000000000000',
      close: '42.3800000000000000',
      volume: '298581.8729900000000000',
      quoteVolume: 12800174.560334394,
      btcVolume: 1748.879815023186,
      usdVolume: 12800174.560334394,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '42.3600000000000000',
      high: '43.0500000000000000',
      low: '41.0000000000000000',
      close: '41.3000000000000000',
      volume: '201446.8929200000000000',
      quoteVolume: 8445983.18938396,
      btcVolume: 1169.7012363488755,
      usdVolume: 8445983.18938396,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '41.2900000000000000',
      high: '42.0800000000000000',
      low: '41.1600000000000000',
      close: '41.6200000000000000',
      volume: '110044.8744500000000000',
      quoteVolume: 4588192.884942515,
      btcVolume: 635.4364428671792,
      usdVolume: 4588192.884942515,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '41.5800000000000000',
      high: '41.7400000000000000',
      low: '39.1700000000000000',
      close: '39.4300000000000000',
      volume: '185573.9423700000000000',
      quoteVolume: 7483717.243429469,
      btcVolume: 1061.4403351410635,
      usdVolume: 7483717.243429469,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '39.4400000000000000',
      high: '42.3900000000000000',
      low: '38.7700000000000000',
      close: '42.2300000000000000',
      volume: '426686.1784300000000000',
      quoteVolume: 17553430.816173177,
      btcVolume: 2423.2412705017687,
      usdVolume: 17553430.816173177,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '42.2300000000000000',
      high: '42.9000000000000000',
      low: '41.7700000000000000',
      close: '42.7800000000000000',
      volume: '248765.3690300000000000',
      quoteVolume: 10534042.087356858,
      btcVolume: 1436.827480722452,
      usdVolume: 10534042.087356858,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '42.7900000000000000',
      high: '44.6100000000000000',
      low: '42.6000000000000000',
      close: '43.3000000000000000',
      volume: '291946.5645000000000000',
      quoteVolume: 12683510.13119241,
      btcVolume: 1705.9415015020584,
      usdVolume: 12683510.13119241,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '43.2900000000000000',
      high: '46.0800000000000000',
      low: '43.1600000000000000',
      close: '45.8000000000000000',
      volume: '384930.9909000000000000',
      quoteVolume: 17201996.630419996,
      btcVolume: 2279.19673036352,
      usdVolume: 17201996.630419996,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '45.8000000000000000',
      high: '47.0300000000000000',
      low: '44.4800000000000000',
      close: '46.3000000000000000',
      volume: '493847.1053200000000000',
      quoteVolume: 22589565.78223805,
      btcVolume: 2841.5038279351834,
      usdVolume: 22589565.78223805,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '46.3000000000000000',
      high: '48.5400000000000000',
      low: '44.0000000000000000',
      close: '45.3500000000000000',
      volume: '584339.7458700000000000',
      quoteVolume: 27180426.110374276,
      btcVolume: 3303.5440248504137,
      usdVolume: 27180426.110374276,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '45.3100000000000000',
      high: '45.7900000000000000',
      low: '43.9000000000000000',
      close: '44.7400000000000000',
      volume: '291473.3014800000000000',
      quoteVolume: 13068587.786202814,
      btcVolume: 1655.8036544951558,
      usdVolume: 13068587.786202814,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '44.7500000000000000',
      high: '49.7200000000000000',
      low: '43.7100000000000000',
      close: '49.0600000000000000',
      volume: '729802.1629100000000000',
      quoteVolume: 34640157.160860166,
      btcVolume: 4336.05850079116,
      usdVolume: 34640157.160860166,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '49.0600000000000000',
      high: '51.9500000000000000',
      low: '47.7700000000000000',
      close: '49.3000000000000000',
      volume: '681984.4665200000000000',
      quoteVolume: 33981679.44025984,
      btcVolume: 4182.297745203546,
      usdVolume: 33981679.44025984,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '49.3200000000000000',
      high: '51.7500000000000000',
      low: '48.7800000000000000',
      close: '51.4300000000000000',
      volume: '532886.7588500000000000',
      quoteVolume: 26955144.342092644,
      btcVolume: 3323.759557503418,
      usdVolume: 26955144.342092644,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '51.4400000000000000',
      high: '51.6000000000000000',
      low: '48.9100000000000000',
      close: '49.6000000000000000',
      volume: '381202.3532900000000000',
      quoteVolume: 19042757.26225366,
      btcVolume: 2347.839281568202,
      usdVolume: 19042757.26225366,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '49.5900000000000000',
      high: '60.8000000000000000',
      low: '49.5800000000000000',
      close: '58.3900000000000000',
      volume: '1232536.3536400000000000',
      quoteVolume: 68826521.75910157,
      btcVolume: 7986.234559202855,
      usdVolume: 68826521.75910157,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '58.3700000000000000',
      high: '60.3300000000000000',
      low: '55.2800000000000000',
      close: '58.1600000000000000',
      volume: '719639.6444600000000000',
      quoteVolume: 41814710.281005435,
      btcVolume: 4771.257558434868,
      usdVolume: 41814710.281005435,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '58.1700000000000000',
      high: '58.5000000000000000',
      low: '54.5000000000000000',
      close: '57.5600000000000000',
      volume: '477991.2016200000000000',
      quoteVolume: 27095155.674876053,
      btcVolume: 3117.7992797943502,
      usdVolume: 27095155.674876053,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '57.5600000000000000',
      high: '63.2600000000000000',
      low: '56.7200000000000000',
      close: '61.0200000000000000',
      volume: '767262.9013300000000000',
      quoteVolume: 46240460.37712067,
      btcVolume: 5206.750862665781,
      usdVolume: 46240460.37712067,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '61.0500000000000000',
      high: '62.5000000000000000',
      low: '57.9000000000000000',
      close: '59.3200000000000000',
      volume: '498224.6925600000000000',
      quoteVolume: 29957779.817371603,
      btcVolume: 3366.710070150065,
      usdVolume: 29957779.817371603,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '59.2900000000000000',
      high: '62.5500000000000000',
      low: '55.3400000000000000',
      close: '57.6000000000000000',
      volume: '530895.3948100000000000',
      quoteVolume: 31172505.611098293,
      btcVolume: 3524.9826085852515,
      usdVolume: 31172505.611098293,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '57.5900000000000000',
      high: '58.2900000000000000',
      low: '55.8400000000000000',
      close: '57.1900000000000000',
      volume: '262316.6725800000000000',
      quoteVolume: 14992782.875085551,
      btcVolume: 1733.2682720575585,
      usdVolume: 14992782.875085551,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '57.1900000000000000',
      high: '58.0900000000000000',
      low: '55.9100000000000000',
      close: '57.5700000000000000',
      volume: '216543.7237600000000000',
      quoteVolume: 12411702.385828936,
      btcVolume: 1433.8494862629868,
      usdVolume: 12411702.385828936,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '57.6000000000000000',
      high: '59.3300000000000000',
      low: '57.1800000000000000',
      close: '58.3300000000000000',
      volume: '218658.9547700000000000',
      quoteVolume: 12717189.464885991,
      btcVolume: 1461.8622331104564,
      usdVolume: 12717189.464885991,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '58.3300000000000000',
      high: '58.4000000000000000',
      low: '52.4200000000000000',
      close: '54.5200000000000000',
      volume: '464982.4302600000000000',
      quoteVolume: 25617022.981270354,
      btcVolume: 3040.6698235798467,
      usdVolume: 25617022.981270354,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '54.5200000000000000',
      high: '55.4100000000000000',
      low: '50.6400000000000000',
      close: '54.3800000000000000',
      volume: '550746.2142300000000000',
      quoteVolume: 29358272.10197633,
      btcVolume: 3502.858976790822,
      usdVolume: 29358272.10197633,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '54.4100000000000000',
      high: '54.4100000000000000',
      low: '51.9000000000000000',
      close: '53.2500000000000000',
      volume: '282004.9267600000000000',
      quoteVolume: 15034806.238122009,
      btcVolume: 1801.659164508155,
      usdVolume: 15034806.238122009,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '53.2600000000000000',
      high: '56.6800000000000000',
      low: '52.8000000000000000',
      close: '56.2400000000000000',
      volume: '348355.2649400000000000',
      quoteVolume: 19066690.28468506,
      btcVolume: 2251.529364018872,
      usdVolume: 19066690.28468506,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '56.2900000000000000',
      high: '59.6500000000000000',
      low: '55.8700000000000000',
      close: '58.5500000000000000',
      volume: '504725.6770700000000000',
      quoteVolume: 29245084.44984709,
      btcVolume: 3342.2689126799664,
      usdVolume: 29245084.44984709,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '58.5500000000000000',
      high: '61.6100000000000000',
      low: '57.2800000000000000',
      close: '60.5200000000000000',
      volume: '720540.7454800000000000',
      quoteVolume: 42958185.64174903,
      btcVolume: 4739.804305857356,
      usdVolume: 42958185.64174903,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '60.5400000000000000',
      high: '62.5600000000000000',
      low: '59.4000000000000000',
      close: '59.8000000000000000',
      volume: '566997.9273700000000000',
      quoteVolume: 34512745.46518843,
      btcVolume: 3694.810881665897,
      usdVolume: 34512745.46518843,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '59.8100000000000000',
      high: '70.2200000000000000',
      low: '58.2400000000000000',
      close: '67.9800000000000000',
      volume: '1286322.9967600000000000',
      quoteVolume: 83696612.6659622,
      btcVolume: 8901.350446887207,
      usdVolume: 83696612.6659622,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '68.0000000000000000',
      high: '70.1000000000000000',
      low: '65.3700000000000000',
      close: '68.0000000000000000',
      volume: '938256.7834600000000000',
      quoteVolume: 63513691.45788283,
      btcVolume: 6794.098635568326,
      usdVolume: 63513691.45788283,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '68.0200000000000000',
      high: '73.0000000000000000',
      low: '67.6200000000000000',
      close: '70.7200000000000000',
      volume: '952836.8964000000000000',
      quoteVolume: 67544318.68868984,
      btcVolume: 7193.168790333061,
      usdVolume: 67544318.68868984,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '70.7200000000000000',
      high: '73.5000000000000000',
      low: '67.6600000000000000',
      close: '69.9300000000000000',
      volume: '818385.8921200000000000',
      quoteVolume: 57937576.88566312,
      btcVolume: 6178.672563598776,
      usdVolume: 57937576.88566312,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '69.9300000000000000',
      high: '73.0000000000000000',
      low: '68.4500000000000000',
      close: '69.6500000000000000',
      volume: '689182.2485500000000000',
      quoteVolume: 48493966.03873978,
      btcVolume: 5178.755191366324,
      usdVolume: 48493966.03873978,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '69.6500000000000000',
      high: '70.5000000000000000',
      low: '66.6200000000000000',
      close: '68.0700000000000000',
      volume: '486977.7672700000000000',
      quoteVolume: 33194308.20363878,
      btcVolume: 3598.386589011568,
      usdVolume: 33194308.20363878,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '68.0400000000000000',
      high: '73.4700000000000000',
      low: '67.6200000000000000',
      close: '72.5900000000000000',
      volume: '646729.4887700000000000',
      quoteVolume: 46118158.32426789,
      btcVolume: 4877.923830710961,
      usdVolume: 46118158.32426789,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '72.5900000000000000',
      high: '75.0800000000000000',
      low: '70.9100000000000000',
      close: '73.6000000000000000',
      volume: '698855.4245600000000000',
      quoteVolume: 51231501.11111841,
      btcVolume: 5277.399278078971,
      usdVolume: 51231501.11111841,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '73.5900000000000000',
      high: '75.4800000000000000',
      low: '72.8500000000000000',
      close: '74.3000000000000000',
      volume: '541747.2637200000000000',
      quoteVolume: 40077414.45603498,
      btcVolume: 4090.357266366724,
      usdVolume: 40077414.45603498,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '74.3100000000000000',
      high: '77.9400000000000000',
      low: '71.5300000000000000',
      close: '76.5600000000000000',
      volume: '647821.6039800000000000',
      quoteVolume: 48757951.45692755,
      btcVolume: 4970.738929853706,
      usdVolume: 48757951.45692755,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '76.5600000000000000',
      high: '78.4300000000000000',
      low: '75.0000000000000000',
      close: '77.0000000000000000',
      volume: '472262.1156700000000000',
      quoteVolume: 36317074.30775854,
      btcVolume: 3602.7858292027363,
      usdVolume: 36317074.30775854,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '76.9900000000000000',
      high: '77.4800000000000000',
      low: '72.2100000000000000',
      close: '74.0200000000000000',
      volume: '709830.6287100000000000',
      quoteVolume: 52756980.53293135,
      btcVolume: 5317.311038857939,
      usdVolume: 52756980.53293135,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '73.9800000000000000',
      high: '77.4000000000000000',
      low: '72.6400000000000000',
      close: '76.4600000000000000',
      volume: '557017.2686200000000000',
      quoteVolume: 41776583.98157975,
      btcVolume: 4186.95219451079,
      usdVolume: 41776583.98157975,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '76.4500000000000000',
      high: '82.8100000000000000',
      low: '76.4400000000000000',
      close: '81.1700000000000000',
      volume: '771544.8829200000000000',
      quoteVolume: 61428938.033711724,
      btcVolume: 5950.1181314721225,
      usdVolume: 61428938.033711724,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '81.2000000000000000',
      high: '84.3200000000000000',
      low: '77.1500000000000000',
      close: '80.5500000000000000',
      volume: '1044135.4611700000000000',
      quoteVolume: 84499591.43981327,
      btcVolume: 8207.278543411736,
      usdVolume: 84499591.43981327,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '80.5500000000000000',
      high: '83.7900000000000000',
      low: '78.6100000000000000',
      close: '83.1400000000000000',
      volume: '523491.6950600000000000',
      quoteVolume: 42705491.43694748,
      btcVolume: 4162.817891228647,
      usdVolume: 42705491.43694748,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '83.1400000000000000',
      high: '83.5000000000000000',
      low: '75.0400000000000000',
      close: '76.7000000000000000',
      volume: '704649.8592300000000000',
      quoteVolume: 55784535.30447557,
      btcVolume: 5528.033565597649,
      usdVolume: 55784535.30447557,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '76.7400000000000000',
      high: '80.7700000000000000',
      low: '67.8500000000000000',
      close: '74.6700000000000000',
      volume: '998173.2565800000000000',
      quoteVolume: 74384870.94958961,
      btcVolume: 7540.054081915949,
      usdVolume: 74384870.94958961,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '74.6600000000000000',
      high: '75.5000000000000000',
      low: '68.5100000000000000',
      close: '73.4100000000000000',
      volume: '738054.4697500000000000',
      quoteVolume: 52679051.66613214,
      btcVolume: 5429.018811635842,
      usdVolume: 52679051.66613214,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '73.4400000000000000',
      high: '79.0900000000000000',
      low: '70.0000000000000000',
      close: '77.2500000000000000',
      volume: '713680.2316200000000000',
      quoteVolume: 53060847.638592295,
      btcVolume: 5393.830722757955,
      usdVolume: 53060847.638592295,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '77.2500000000000000',
      high: '79.4500000000000000',
      low: '67.0000000000000000',
      close: '70.6100000000000000',
      volume: '542175.5195700000000000',
      quoteVolume: 40353067.756060414,
      btcVolume: 4058.4679125064927,
      usdVolume: 40353067.756060414,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '70.6100000000000000',
      high: '72.4300000000000000',
      low: '66.1000000000000000',
      close: '69.4000000000000000',
      volume: '730443.1978900000000000',
      quoteVolume: 50737958.514938615,
      btcVolume: 5298.48056977998,
      usdVolume: 50737958.514938615,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '69.3800000000000000',
      high: '75.2500000000000000',
      low: '68.5600000000000000',
      close: '73.2500000000000000',
      volume: '624557.8448400000000000',
      quoteVolume: 45340746.56160606,
      btcVolume: 4683.2319382880305,
      usdVolume: 45340746.56160606,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '73.2500000000000000',
      high: '76.7900000000000000',
      low: '72.3200000000000000',
      close: '74.7900000000000000',
      volume: '651449.7864800000000000',
      quoteVolume: 48795358.48709348,
      btcVolume: 5060.245308587067,
      usdVolume: 48795358.48709348,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '74.8000000000000000',
      high: '80.0000000000000000',
      low: '74.6600000000000000',
      close: '79.5200000000000000',
      volume: '665878.9020700000000000',
      quoteVolume: 52072540.692516245,
      btcVolume: 5281.779201690238,
      usdVolume: 52072540.692516245,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '79.5200000000000000',
      high: '79.7600000000000000',
      low: '71.7100000000000000',
      close: '75.5900000000000000',
      volume: '936157.2591900000000000',
      quoteVolume: 70787716.5914514,
      btcVolume: 7272.425954963807,
      usdVolume: 70787716.5914514,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '75.5700000000000000',
      high: '76.0800000000000000',
      low: '69.5400000000000000',
      close: '70.9800000000000000',
      volume: '778051.0902300000000000',
      quoteVolume: 56572719.08831394,
      btcVolume: 5971.138131273642,
      usdVolume: 56572719.08831394,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '70.9800000000000000',
      high: '72.1500000000000000',
      low: '57.4100000000000000',
      close: '60.5300000000000000',
      volume: '1518769.7348500000000000',
      quoteVolume: 97983345.80007514,
      btcVolume: 10860.043177575628,
      usdVolume: 97983345.80007514,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '60.5300000000000000',
      high: '65.1000000000000000',
      low: '57.1500000000000000',
      close: '61.7700000000000000',
      volume: '1110152.5418600000000000',
      quoteVolume: 68205004.9433444,
      btcVolume: 7760.49735099012,
      usdVolume: 68205004.9433444,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '61.7600000000000000',
      high: '63.3900000000000000',
      low: '56.5600000000000000',
      close: '59.8900000000000000',
      volume: '1070641.2770800000000000',
      quoteVolume: 64057582.61899067,
      btcVolume: 7378.595868383946,
      usdVolume: 64057582.61899067,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '59.8800000000000000',
      high: '61.6500000000000000',
      low: '57.8400000000000000',
      close: '58.0500000000000000',
      volume: '506232.3970400000000000',
      quoteVolume: 30182934.208716422,
      btcVolume: 3477.3583610745964,
      usdVolume: 30182934.208716422,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '58.0500000000000000',
      high: '60.2800000000000000',
      low: '56.2100000000000000',
      close: '57.7000000000000000',
      volume: '633924.9116200000000000',
      quoteVolume: 37024496.384328924,
      btcVolume: 4325.436819033869,
      usdVolume: 37024496.384328924,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '57.7200000000000000',
      high: '62.3200000000000000',
      low: '57.2500000000000000',
      close: '61.4200000000000000',
      volume: '660927.2617600000000000',
      quoteVolume: 39608823.902695134,
      btcVolume: 4524.26294574055,
      usdVolume: 39608823.902695134,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '61.4200000000000000',
      high: '62.4000000000000000',
      low: '59.4000000000000000',
      close: '61.0100000000000000',
      volume: '640940.7745700000000000',
      quoteVolume: 39002038.56290318,
      btcVolume: 4441.375840912394,
      usdVolume: 39002038.56290318,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '61.0200000000000000',
      high: '61.7800000000000000',
      low: '58.7100000000000000',
      close: '60.3800000000000000',
      volume: '424299.8621100000000000',
      quoteVolume: 25604221.91697132,
      btcVolume: 2924.6084427488145,
      usdVolume: 25604221.91697132,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '60.3800000000000000',
      high: '63.2900000000000000',
      low: '60.3300000000000000',
      close: '62.0600000000000000',
      volume: '556284.7403100000000000',
      quoteVolume: 34448965.31724282,
      btcVolume: 3820.9976165896405,
      usdVolume: 34448965.31724282,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '62.0600000000000000',
      high: '63.8400000000000000',
      low: '61.1800000000000000',
      close: '63.2200000000000000',
      volume: '468431.8505300000000000',
      quoteVolume: 29256812.809018675,
      btcVolume: 3219.3959694013993,
      usdVolume: 29256812.809018675,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '63.2100000000000000',
      high: '63.8900000000000000',
      low: '59.8600000000000000',
      close: '60.4600000000000000',
      volume: '444270.5350800000000000',
      quoteVolume: 27655735.411491226,
      btcVolume: 3061.59890192781,
      usdVolume: 27655735.411491226,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '60.4600000000000000',
      high: '60.4700000000000000',
      low: '50.4100000000000000',
      close: '51.0000000000000000',
      volume: '945026.2712800000000000',
      quoteVolume: 51958845.61631424,
      btcVolume: 6146.981784105797,
      usdVolume: 51958845.61631424,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '51.0200000000000000',
      high: '52.2700000000000000',
      low: '46.5700000000000000',
      close: '50.3900000000000000',
      volume: '1426145.5016400000000000',
      quoteVolume: 70420337.2832661,
      btcVolume: 8969.579053763204,
      usdVolume: 70420337.2832661,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '50.3700000000000000',
      high: '51.3900000000000000',
      low: '48.8600000000000000',
      close: '50.2600000000000000',
      volume: '727732.9091100000000000',
      quoteVolume: 36535744.60946244,
      btcVolume: 4600.383086081476,
      usdVolume: 36535744.60946244,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '50.2400000000000000',
      high: '50.7400000000000000',
      low: '45.4700000000000000',
      close: '48.5300000000000000',
      volume: '798310.6481000000000000',
      quoteVolume: 38456344.38179883,
      btcVolume: 4924.684407095869,
      usdVolume: 38456344.38179883,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '48.5200000000000000',
      high: '48.7600000000000000',
      low: '28.6500000000000000',
      close: '29.8300000000000000',
      volume: '2086322.4159000000000000',
      quoteVolume: 75460005.32619856,
      btcVolume: 12025.230888564047,
      usdVolume: 75460005.32619856,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '29.8300000000000000',
      high: '39.9200000000000000',
      low: '24.0000000000000000',
      close: '37.5200000000000000',
      volume: '3233170.0085600000000000',
      quoteVolume: 106737958.21170305,
      btcVolume: 20811.33498176254,
      usdVolume: 106737958.21170305,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '37.5100000000000000',
      high: '37.6500000000000000',
      low: '33.8000000000000000',
      close: '34.4000000000000000',
      volume: '1177751.7307200000000000',
      quoteVolume: 42192079.856290706,
      btcVolume: 7824.353304327343,
      usdVolume: 42192079.856290706,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '34.4000000000000000',
      high: '38.7300000000000000',
      low: '34.0400000000000000',
      close: '36.3800000000000000',
      volume: '1040322.3028200000000000',
      quoteVolume: 37333342.64250416,
      btcVolume: 6968.352015588107,
      usdVolume: 37333342.64250416,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '36.3700000000000000',
      high: '36.4100000000000000',
      low: '29.2900000000000000',
      close: '33.2000000000000000',
      volume: '1624714.3758400000000000',
      quoteVolume: 52644643.37862483,
      btcVolume: 10729.651950772735,
      usdVolume: 52644643.37862483,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '33.2300000000000000',
      high: '35.8500000000000000',
      low: '32.4200000000000000',
      close: '34.0700000000000000',
      volume: '945437.9313600000000000',
      quoteVolume: 32633674.457892723,
      btcVolume: 6180.955240181797,
      usdVolume: 32633674.457892723,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '34.0600000000000000',
      high: '34.8300000000000000',
      low: '32.2800000000000000',
      close: '34.7100000000000000',
      volume: '1044418.0699700000000000',
      quoteVolume: 35185450.617933325,
      btcVolume: 6701.831333709307,
      usdVolume: 35185450.617933325,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '34.7200000000000000',
      high: '40.8000000000000000',
      low: '34.0000000000000000',
      close: '38.9000000000000000',
      volume: '1343522.4596300000000000',
      quoteVolume: 50290985.84804543,
      btcVolume: 8550.756313543709,
      usdVolume: 50290985.84804543,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '38.9000000000000000',
      high: '43.6900000000000000',
      low: '34.0500000000000000',
      close: '38.0800000000000000',
      volume: '1571300.6085700000000000',
      quoteVolume: 62380455.892511785,
      btcVolume: 9798.316569328548,
      usdVolume: 62380455.892511785,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '38.0700000000000000',
      high: '39.8400000000000000',
      low: '36.2100000000000000',
      close: '38.3200000000000000',
      volume: '667425.6322200000000000',
      quoteVolume: 25413717.28592991,
      btcVolume: 4123.580369137249,
      usdVolume: 25413717.28592991,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '38.3400000000000000',
      high: '39.6900000000000000',
      low: '34.8200000000000000',
      close: '35.4000000000000000',
      volume: '714342.7273000000000000',
      quoteVolume: 26556648.090772223,
      btcVolume: 4362.9065967115375,
      usdVolume: 26556648.090772223,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '35.4200000000000000',
      high: '39.9900000000000000',
      low: '34.5700000000000000',
      close: '38.9400000000000000',
      volume: '859942.5261100000000000',
      quoteVolume: 32311045.429742124,
      btcVolume: 5270.1140483688005,
      usdVolume: 32311045.429742124,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '38.9300000000000000',
      high: '40.9500000000000000',
      low: '38.2100000000000000',
      close: '40.5700000000000000',
      volume: '593656.7733500000000000',
      quoteVolume: 23511452.09394711,
      btcVolume: 3536.1717797574593,
      usdVolume: 23511452.09394711,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '40.5600000000000000',
      high: '41.0400000000000000',
      low: '38.0900000000000000',
      close: '39.1700000000000000',
      volume: '577448.8885600000000000',
      quoteVolume: 22783918.764491983,
      btcVolume: 3426.4774081346554,
      usdVolume: 22783918.764491983,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '39.1700000000000000',
      high: '40.4800000000000000',
      low: '38.4000000000000000',
      close: '40.4100000000000000',
      volume: '415288.7595000000000000',
      quoteVolume: 16295287.489501858,
      btcVolume: 2447.192594622033,
      usdVolume: 16295287.489501858,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '40.4100000000000000',
      high: '41.2100000000000000',
      low: '37.7300000000000000',
      close: '38.0400000000000000',
      volume: '481643.2817300000000000',
      quoteVolume: 19173843.62566706,
      btcVolume: 2881.7355055505905,
      usdVolume: 19173843.62566706,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '38.0400000000000000',
      high: '39.4300000000000000',
      low: '36.6100000000000000',
      close: '38.8700000000000000',
      volume: '624312.1869900000000000',
      quoteVolume: 23604375.60363385,
      btcVolume: 3798.947942790218,
      usdVolume: 23604375.60363385,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '38.8500000000000000',
      high: '39.1100000000000000',
      low: '36.9700000000000000',
      close: '37.1100000000000000',
      volume: '402076.3126400000000000',
      quoteVolume: 15295566.907294536,
      btcVolume: 2513.0753250709718,
      usdVolume: 15295566.907294536,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '37.1100000000000000',
      high: '39.7000000000000000',
      low: '37.1000000000000000',
      close: '38.9100000000000000',
      volume: '428079.8682600000000000',
      quoteVolume: 16531614.31945865,
      btcVolume: 2634.7438065394886,
      usdVolume: 16531614.31945865,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '38.9300000000000000',
      high: '39.3200000000000000',
      low: '38.3300000000000000',
      close: '39.0800000000000000',
      volume: '378250.3606500000000000',
      quoteVolume: 14716269.660095483,
      btcVolume: 2283.619623452454,
      usdVolume: 14716269.660095483,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '39.0700000000000000',
      high: '39.5000000000000000',
      low: '37.1400000000000000',
      close: '39.2300000000000000',
      volume: '488799.1143600000000000',
      quoteVolume: 18738315.769651353,
      btcVolume: 2961.325340905673,
      usdVolume: 18738315.769651353,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '39.2300000000000000',
      high: '42.9200000000000000',
      low: '39.0000000000000000',
      close: '39.8600000000000000',
      volume: '701573.7692100000000000',
      quoteVolume: 28237488.266804706,
      btcVolume: 4154.26325616401,
      usdVolume: 28237488.266804706,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '39.8800000000000000',
      high: '41.2600000000000000',
      low: '39.6400000000000000',
      close: '40.4600000000000000',
      volume: '463368.1163000000000000',
      quoteVolume: 18748635.166638713,
      btcVolume: 2747.9145418248345,
      usdVolume: 18748635.166638713,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '40.4900000000000000',
      high: '41.1600000000000000',
      low: '39.8600000000000000',
      close: '40.7100000000000000',
      volume: '362553.4451000000000000',
      quoteVolume: 14663339.769387648,
      btcVolume: 2163.870381431818,
      usdVolume: 14663339.769387648,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '40.7400000000000000',
      high: '41.0000000000000000',
      low: '39.8600000000000000',
      close: '40.3100000000000000',
      volume: '231112.9573000000000000',
      quoteVolume: 9362413.054505432,
      btcVolume: 1379.8091072782984,
      usdVolume: 9362413.054505432,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '40.3100000000000000',
      high: '45.0900000000000000',
      low: '40.2800000000000000',
      close: '45.0400000000000000',
      volume: '721562.2858000000000000',
      quoteVolume: 30935223.803984463,
      btcVolume: 4356.628030072247,
      usdVolume: 30935223.803984463,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '45.0300000000000000',
      high: '47.6400000000000000',
      low: '43.6400000000000000',
      close: '44.7700000000000000',
      volume: '807492.3694800000000000',
      quoteVolume: 36986803.916505545,
      btcVolume: 5058.8892341354185,
      usdVolume: 36986803.916505545,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '44.7900000000000000',
      high: '47.2000000000000000',
      low: '44.2700000000000000',
      close: '46.5500000000000000',
      volume: '500059.4649500000000000',
      quoteVolume: 23044062.553474456,
      btcVolume: 3156.204573659692,
      usdVolume: 23044062.553474456,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '46.5200000000000000',
      high: '46.8600000000000000',
      low: '44.6900000000000000',
      close: '46.2100000000000000',
      volume: '401085.4795800000000000',
      quoteVolume: 18464427.839610986,
      btcVolume: 2533.6102006732344,
      usdVolume: 18464427.839610986,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '46.1900000000000000',
      high: '46.3900000000000000',
      low: '40.8600000000000000',
      close: '42.2500000000000000',
      volume: '785172.3313100000000000',
      quoteVolume: 33771322.35967942,
      btcVolume: 4856.52724305218,
      usdVolume: 33771322.35967942,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '42.2800000000000000',
      high: '43.1600000000000000',
      low: '41.4300000000000000',
      close: '42.5400000000000000',
      volume: '382360.3213200000000000',
      quoteVolume: 16212942.580929037,
      btcVolume: 2364.227913329805,
      usdVolume: 16212942.580929037,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '42.5400000000000000',
      high: '43.5800000000000000',
      low: '41.8300000000000000',
      close: '42.0000000000000000',
      volume: '499257.0325700000000000',
      quoteVolume: 21342178.133174907,
      btcVolume: 3067.773815331192,
      usdVolume: 21342178.133174907,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '41.9900000000000000',
      high: '41.9900000000000000',
      low: '39.5200000000000000',
      close: '41.2200000000000000',
      volume: '744114.5130600000000000',
      quoteVolume: 30090872.086141095,
      btcVolume: 4470.071976428221,
      usdVolume: 30090872.086141095,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '41.2000000000000000',
      high: '41.8500000000000000',
      low: '40.4700000000000000',
      close: '41.2200000000000000',
      volume: '420837.6883200000000000',
      quoteVolume: 17361900.52885351,
      btcVolume: 2527.268685546851,
      usdVolume: 17361900.52885351,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '41.1900000000000000',
      high: '41.7500000000000000',
      low: '39.0800000000000000',
      close: '39.2200000000000000',
      volume: '460328.1631000000000000',
      quoteVolume: 18685153.46120497,
      btcVolume: 2758.6937261226535,
      usdVolume: 18685153.46120497,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '39.2400000000000000',
      high: '43.7800000000000000',
      low: '37.9100000000000000',
      close: '42.5300000000000000',
      volume: '913086.9777100000000000',
      quoteVolume: 37743900.83684656,
      btcVolume: 5472.020626825356,
      usdVolume: 37743900.83684656,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '42.5200000000000000',
      high: '43.0100000000000000',
      low: '41.6100000000000000',
      close: '42.1900000000000000',
      volume: '407623.0458700000000000',
      quoteVolume: 17197450.73896654,
      btcVolume: 2433.5847695278885,
      usdVolume: 17197450.73896654,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '42.2000000000000000',
      high: '44.4300000000000000',
      low: '41.9800000000000000',
      close: '44.1400000000000000',
      volume: '497104.9427100000000000',
      quoteVolume: 21438290.761193395,
      btcVolume: 2994.1891368990136,
      usdVolume: 21438290.761193395,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '44.1600000000000000',
      high: '44.3000000000000000',
      low: '41.5400000000000000',
      close: '42.2300000000000000',
      volume: '456205.8125200000000000',
      quoteVolume: 19598464.2516532,
      btcVolume: 2739.1972282400675,
      usdVolume: 19598464.2516532,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '42.2300000000000000',
      high: '43.2400000000000000',
      low: '39.5500000000000000',
      close: '40.2100000000000000',
      volume: '543840.0568300000000000',
      quoteVolume: 22495305.856098518,
      btcVolume: 3219.760771868381,
      usdVolume: 22495305.856098518,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '40.2100000000000000',
      high: '41.3400000000000000',
      low: '40.0100000000000000',
      close: '40.5900000000000000',
      volume: '345238.8723200000000000',
      quoteVolume: 14009507.177860197,
      btcVolume: 2044.2849891545281,
      usdVolume: 14009507.177860197,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '40.5900000000000000',
      high: '42.3200000000000000',
      low: '40.2700000000000000',
      close: '41.8000000000000000',
      volume: '462963.2967500000000000',
      quoteVolume: 19150251.779417757,
      btcVolume: 2733.259301365301,
      usdVolume: 19150251.779417757,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '41.8100000000000000',
      high: '43.9100000000000000',
      low: '40.9400000000000000',
      close: '42.9800000000000000',
      volume: '638808.1586600000000000',
      quoteVolume: 27205984.39496531,
      btcVolume: 3698.639315507722,
      usdVolume: 27205984.39496531,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '42.9800000000000000',
      high: '44.9900000000000000',
      low: '42.8300000000000000',
      close: '44.5300000000000000',
      volume: '623497.4206400000000000',
      quoteVolume: 27494508.18459142,
      btcVolume: 3656.2769200491334,
      usdVolume: 27494508.18459142,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '44.5200000000000000',
      high: '45.5800000000000000',
      low: '43.8400000000000000',
      close: '44.3800000000000000',
      volume: '555367.1872400000000000',
      quoteVolume: 24833710.353949737,
      btcVolume: 3290.9485922233407,
      usdVolume: 24833710.353949737,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '44.3800000000000000',
      high: '45.3900000000000000',
      low: '43.7500000000000000',
      close: '44.5200000000000000',
      volume: '485605.1559300000000000',
      quoteVolume: 21633052.172737543,
      btcVolume: 2845.7674049944853,
      usdVolume: 21633052.172737543,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '44.5400000000000000',
      high: '45.2300000000000000',
      low: '43.1600000000000000',
      close: '44.4600000000000000',
      volume: '443570.6602900000000000',
      quoteVolume: 19611892.418601938,
      btcVolume: 2546.7952458477666,
      usdVolume: 19611892.418601938,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '44.4700000000000000',
      high: '46.1300000000000000',
      low: '43.4200000000000000',
      close: '45.6900000000000000',
      volume: '590012.5230400000000000',
      quoteVolume: 26316522.275929503,
      btcVolume: 3404.4738459937435,
      usdVolume: 26316522.275929503,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '45.6900000000000000',
      high: '49.9100000000000000',
      low: '45.6400000000000000',
      close: '48.8100000000000000',
      volume: '1198086.9005900000000000',
      quoteVolume: 56983117.16653303,
      btcVolume: 6840.247516585622,
      usdVolume: 56983117.16653303,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '48.8100000000000000',
      high: '50.8000000000000000',
      low: '45.5900000000000000',
      close: '46.3300000000000000',
      volume: '1404189.5332000000000000',
      quoteVolume: 67399084.98858932,
      btcVolume: 7558.333091499474,
      usdVolume: 67399084.98858932,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '46.3300000000000000',
      high: '48.2700000000000000',
      low: '46.3000000000000000',
      close: '47.0700000000000000',
      volume: '499757.9662500000000000',
      quoteVolume: 23637798.368433,
      btcVolume: 2688.212536886058,
      usdVolume: 23637798.368433,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '47.0800000000000000',
      high: '49.6000000000000000',
      low: '46.7300000000000000',
      close: '49.2000000000000000',
      volume: '408979.1499600000000000',
      quoteVolume: 19681383.60385065,
      btcVolume: 2212.021453381788,
      usdVolume: 19681383.60385065,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '49.2000000000000000',
      high: '50.0200000000000000',
      low: '46.6100000000000000',
      close: '48.0500000000000000',
      volume: '566317.3808100000000000',
      quoteVolume: 27433863.01719666,
      btcVolume: 3063.5466670768265,
      usdVolume: 27433863.01719666,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '48.0700000000000000',
      high: '48.2000000000000000',
      low: '45.1100000000000000',
      close: '46.9600000000000000',
      volume: '693367.0109200000000000',
      quoteVolume: 32216489.944114767,
      btcVolume: 3687.084305406875,
      usdVolume: 32216489.944114767,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '46.9700000000000000',
      high: '47.7600000000000000',
      low: '45.7100000000000000',
      close: '46.5400000000000000',
      volume: '415057.4376200000000000',
      quoteVolume: 19397806.104704224,
      btcVolume: 2173.7880200232094,
      usdVolume: 19397806.104704224,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '46.5600000000000000',
      high: '47.5500000000000000',
      low: '44.5500000000000000',
      close: '44.9400000000000000',
      volume: '466241.7278500000000000',
      quoteVolume: 21723146.905489907,
      btcVolume: 2368.579458205406,
      usdVolume: 21723146.905489907,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '44.9200000000000000',
      high: '48.2200000000000000',
      low: '44.3700000000000000',
      close: '47.3700000000000000',
      volume: '796061.5993300000000000',
      quoteVolume: 37063053.8901233,
      btcVolume: 3872.7360538266807,
      usdVolume: 37063053.8901233,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '47.3700000000000000',
      high: '48.4300000000000000',
      low: '46.3400000000000000',
      close: '47.6900000000000000',
      volume: '631882.8103600000000000',
      quoteVolume: 30005956.286906376,
      btcVolume: 3025.61774026537,
      usdVolume: 30005956.286906376,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '47.7000000000000000',
      high: '49.1000000000000000',
      low: '46.7500000000000000',
      close: '46.9500000000000000',
      volume: '656199.9985000000000000',
      quoteVolume: 31531059.60198355,
      btcVolume: 3242.069854321196,
      usdVolume: 31531059.60198355,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '46.9700000000000000',
      high: '47.0400000000000000',
      low: '39.4000000000000000',
      close: '42.0600000000000000',
      volume: '1404081.1754800000000000',
      quoteVolume: 58997967.78654685,
      btcVolume: 6799.973073584511,
      usdVolume: 58997967.78654685,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '42.0500000000000000',
      high: '42.9000000000000000',
      low: '39.3700000000000000',
      close: '41.5100000000000000',
      volume: '955643.7080500000000000',
      quoteVolume: 39459230.38420516,
      btcVolume: 4550.364445794178,
      usdVolume: 39459230.38420516,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '41.4900000000000000',
      high: '42.8800000000000000',
      low: '41.2600000000000000',
      close: '42.1800000000000000',
      volume: '598291.6399500000000000',
      quoteVolume: 25210189.417792954,
      btcVolume: 2873.5800124011444,
      usdVolume: 25210189.417792954,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '42.1800000000000000',
      high: '43.7400000000000000',
      low: '41.9400000000000000',
      close: '43.1800000000000000',
      volume: '401563.4178500000000000',
      quoteVolume: 17187427.88953854,
      btcVolume: 1892.7048671079617,
      usdVolume: 17187427.88953854,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '43.1800000000000000',
      high: '44.3800000000000000',
      low: '42.2100000000000000',
      close: '43.9800000000000000',
      volume: '507436.3497400000000000',
      quoteVolume: 22075904.008925483,
      btcVolume: 2303.152931180216,
      usdVolume: 22075904.008925483,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '43.9900000000000000',
      high: '44.1400000000000000',
      low: '41.8200000000000000',
      close: '42.7400000000000000',
      volume: '468825.9372000000000000',
      quoteVolume: 20205683.375076648,
      btcVolume: 2131.6645117325697,
      usdVolume: 20205683.375076648,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '42.7300000000000000',
      high: '43.8900000000000000',
      low: '42.5000000000000000',
      close: '43.2500000000000000',
      volume: '435940.4069700000000000',
      quoteVolume: 18877872.465006832,
      btcVolume: 2006.2006217660262,
      usdVolume: 18877872.465006832,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '43.2500000000000000',
      high: '44.4900000000000000',
      low: '43.1000000000000000',
      close: '43.7100000000000000',
      volume: '344451.6233800000000000',
      quoteVolume: 15152133.939513858,
      btcVolume: 1573.049518404476,
      usdVolume: 15152133.939513858,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '43.7000000000000000',
      high: '46.5900000000000000',
      low: '43.6400000000000000',
      close: '45.3200000000000000',
      volume: '694268.3291300000000000',
      quoteVolume: 31428313.748912312,
      btcVolume: 3215.1275437118916,
      usdVolume: 31428313.748912312,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '45.3300000000000000',
      high: '45.8000000000000000',
      low: '44.0700000000000000',
      close: '45.7300000000000000',
      volume: '375917.6522500000000000',
      quoteVolume: 16910574.08702791,
      btcVolume: 1747.0177395296405,
      usdVolume: 16910574.08702791,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '45.7300000000000000',
      high: '46.1400000000000000',
      low: '43.1700000000000000',
      close: '44.2500000000000000',
      volume: '546255.0060000000000000',
      quoteVolume: 24521814.476579513,
      btcVolume: 2542.608320507337,
      usdVolume: 24521814.476579513,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '44.2400000000000000',
      high: '44.6200000000000000',
      low: '41.6600000000000000',
      close: '42.7300000000000000',
      volume: '504821.8913700000000000',
      quoteVolume: 21790870.5584972,
      btcVolume: 2362.9361362732657,
      usdVolume: 21790870.5584972,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '42.7200000000000000',
      high: '44.6800000000000000',
      low: '42.3500000000000000',
      close: '44.2300000000000000',
      volume: '398405.5385600000000000',
      quoteVolume: 17376646.5729942,
      btcVolume: 1903.3033669829076,
      usdVolume: 17376646.5729942,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '44.2200000000000000',
      high: '44.7400000000000000',
      low: '43.6700000000000000',
      close: '43.8500000000000000',
      volume: '237463.4446900000000000',
      quoteVolume: 10482358.645321624,
      btcVolume: 1139.0076516333727,
      usdVolume: 10482358.645321624,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '43.8400000000000000',
      high: '44.5500000000000000',
      low: '42.0400000000000000',
      close: '42.0800000000000000',
      volume: '360033.5515400000000000',
      quoteVolume: 15679863.525856707,
      btcVolume: 1726.0693649842788,
      usdVolume: 15679863.525856707,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '42.0800000000000000',
      high: '43.3200000000000000',
      low: '41.7200000000000000',
      close: '42.9700000000000000',
      volume: '250914.5274100000000000',
      quoteVolume: 10724204.717699511,
      btcVolume: 1217.8718797560175,
      usdVolume: 10724204.717699511,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '42.9900000000000000',
      high: '43.0700000000000000',
      low: '41.7600000000000000',
      close: '42.2700000000000000',
      volume: '218690.0446300000000000',
      quoteVolume: 9289007.752625285,
      btcVolume: 1049.1031437450883,
      usdVolume: 9289007.752625285,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '42.2600000000000000',
      high: '44.0500000000000000',
      low: '42.2200000000000000',
      close: '43.8500000000000000',
      volume: '282807.4623400000000000',
      quoteVolume: 12241409.404598255,
      btcVolume: 1350.5834483933272,
      usdVolume: 12241409.404598255,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '43.8600000000000000',
      high: '44.8800000000000000',
      low: '43.1500000000000000',
      close: '44.7700000000000000',
      volume: '291358.4733600000000000',
      quoteVolume: 12814294.25871001,
      btcVolume: 1372.8438156303184,
      usdVolume: 12814294.25871001,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '44.7700000000000000',
      high: '45.2900000000000000',
      low: '44.1700000000000000',
      close: '44.4700000000000000',
      volume: '298461.3751300000000000',
      quoteVolume: 13345930.015912458,
      btcVolume: 1409.140378403909,
      usdVolume: 13345930.015912458,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '44.4900000000000000',
      high: '48.0800000000000000',
      low: '44.1800000000000000',
      close: '47.7200000000000000',
      volume: '590070.5535000000000000',
      quoteVolume: 27463513.679888483,
      btcVolume: 2881.444155580501,
      usdVolume: 27463513.679888483,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '47.7200000000000000',
      high: '47.8400000000000000',
      low: '45.0700000000000000',
      close: '45.6100000000000000',
      volume: '494076.1867300000000000',
      quoteVolume: 23046362.233182173,
      btcVolume: 2414.446392609635,
      usdVolume: 23046362.233182173,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '45.6000000000000000',
      high: '48.8100000000000000',
      low: '45.3600000000000000',
      close: '48.2500000000000000',
      volume: '599583.6772200000000000',
      quoteVolume: 28169093.711731534,
      btcVolume: 2895.4119659902226,
      usdVolume: 28169093.711731534,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '48.2600000000000000',
      high: '49.9600000000000000',
      low: '44.0000000000000000',
      close: '45.9900000000000000',
      volume: '920374.0312200000000000',
      quoteVolume: 43606491.94942139,
      btcVolume: 4436.165811723226,
      usdVolume: 43606491.94942139,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '47.5000000000000000',
      low: '45.2500000000000000',
      close: '47.1600000000000000',
      volume: '428190.8216400000000000',
      quoteVolume: 19934098.49867122,
      btcVolume: 2086.467816317527,
      usdVolume: 19934098.49867122,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '47.1500000000000000',
      high: '48.1000000000000000',
      low: '46.1500000000000000',
      close: '47.4300000000000000',
      volume: '471692.4156600000000000',
      quoteVolume: 22364295.281365484,
      btcVolume: 2310.0219355528084,
      usdVolume: 22364295.281365484,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '47.4300000000000000',
      high: '47.9800000000000000',
      low: '46.4200000000000000',
      close: '46.8300000000000000',
      volume: '400835.1846600000000000',
      quoteVolume: 18969882.083338924,
      btcVolume: 1945.0400952427456,
      usdVolume: 18969882.083338924,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '46.8000000000000000',
      high: '47.2400000000000000',
      low: '46.2000000000000000',
      close: '46.7900000000000000',
      volume: '195688.0406400000000000',
      quoteVolume: 9141881.677323475,
      btcVolume: 948.8992424450283,
      usdVolume: 9141881.677323475,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '46.7900000000000000',
      high: '46.9800000000000000',
      low: '45.0600000000000000',
      close: '46.6300000000000000',
      volume: '306262.5414800000000000',
      quoteVolume: 14151417.896596055,
      btcVolume: 1473.8708394166745,
      usdVolume: 14151417.896596055,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '46.6000000000000000',
      high: '46.7900000000000000',
      low: '45.9500000000000000',
      close: '46.4300000000000000',
      volume: '220499.0699300000000000',
      quoteVolume: 10227376.443103297,
      btcVolume: 1051.937083531138,
      usdVolume: 10227376.443103297,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '46.4500000000000000',
      high: '46.8400000000000000',
      low: '45.3100000000000000',
      close: '45.8700000000000000',
      volume: '219582.2168000000000000',
      quoteVolume: 10105379.596295863,
      btcVolume: 1040.202431965599,
      usdVolume: 10105379.596295863,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '45.8900000000000000',
      high: '47.1900000000000000',
      low: '45.6500000000000000',
      close: '46.6300000000000000',
      volume: '319562.9294800000000000',
      quoteVolume: 14778011.431747977,
      btcVolume: 1507.8102529037167,
      usdVolume: 14778011.431747977,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '46.6300000000000000',
      high: '46.9600000000000000',
      low: '42.0000000000000000',
      close: '43.2200000000000000',
      volume: '538312.5504400000000000',
      quoteVolume: 24117129.0557672,
      btcVolume: 2526.1064285493794,
      usdVolume: 24117129.0557672,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '43.2200000000000000',
      high: '45.3600000000000000',
      low: '42.8100000000000000',
      close: '44.7200000000000000',
      volume: '236637.2390700000000000',
      quoteVolume: 10478472.454529103,
      btcVolume: 1112.938106242603,
      usdVolume: 10478472.454529103,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '44.7200000000000000',
      high: '45.2200000000000000',
      low: '44.2700000000000000',
      close: '45.0800000000000000',
      volume: '151582.8051800000000000',
      quoteVolume: 6775955.931185166,
      btcVolume: 718.694564235063,
      usdVolume: 6775955.931185166,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '45.0900000000000000',
      high: '45.1100000000000000',
      low: '43.5300000000000000',
      close: '44.0500000000000000',
      volume: '170082.7402900000000000',
      quoteVolume: 7542598.776623745,
      btcVolume: 802.8177727597499,
      usdVolume: 7542598.776623745,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '44.0400000000000000',
      high: '44.0900000000000000',
      low: '41.7500000000000000',
      close: '43.7300000000000000',
      volume: '355287.5401600000000000',
      quoteVolume: 15285580.752425862,
      btcVolume: 1659.6268802012912,
      usdVolume: 15285580.752425862,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '43.7300000000000000',
      high: '44.2200000000000000',
      low: '43.3300000000000000',
      close: '43.8800000000000000',
      volume: '215557.5907300000000000',
      quoteVolume: 9436824.369403064,
      btcVolume: 993.2645058025514,
      usdVolume: 9436824.369403064,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '43.9000000000000000',
      high: '45.0400000000000000',
      low: '43.0000000000000000',
      close: '44.0700000000000000',
      volume: '324893.8614200000000000',
      quoteVolume: 14346683.24048972,
      btcVolume: 1519.1996398986237,
      usdVolume: 14346683.24048972,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '44.0700000000000000',
      high: '44.2300000000000000',
      low: '42.8800000000000000',
      close: '43.4300000000000000',
      volume: '193534.9893800000000000',
      quoteVolume: 8454598.742349433,
      btcVolume: 898.8378626695845,
      usdVolume: 8454598.742349433,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '43.4300000000000000',
      high: '43.7300000000000000',
      low: '42.5700000000000000',
      close: '42.8900000000000000',
      volume: '304810.9753600000000000',
      quoteVolume: 13177549.12879669,
      btcVolume: 1412.1592884165075,
      usdVolume: 13177549.12879669,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '42.8900000000000000',
      high: '43.8500000000000000',
      low: '42.2100000000000000',
      close: '43.5700000000000000',
      volume: '202428.9463000000000000',
      quoteVolume: 8709765.120769074,
      btcVolume: 936.1793726946369,
      usdVolume: 8709765.120769074,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '43.5700000000000000',
      high: '43.8300000000000000',
      low: '42.8800000000000000',
      close: '43.0000000000000000',
      volume: '159632.6785000000000000',
      quoteVolume: 6913195.771169413,
      btcVolume: 739.0315965348125,
      usdVolume: 6913195.771169413,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '43.0000000000000000',
      high: '44.6700000000000000',
      low: '42.9400000000000000',
      close: '44.3000000000000000',
      volume: '281062.1117900000000000',
      quoteVolume: 12346345.856977155,
      btcVolume: 1299.505700098188,
      usdVolume: 12346345.856977155,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '44.3000000000000000',
      high: '44.4900000000000000',
      low: '43.8900000000000000',
      close: '44.1800000000000000',
      volume: '219363.9022300000000000',
      quoteVolume: 9684310.145484513,
      btcVolume: 1004.7333264570984,
      usdVolume: 9684310.145484513,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '44.1800000000000000',
      high: '44.6900000000000000',
      low: '42.1000000000000000',
      close: '42.6500000000000000',
      volume: '357937.9722300000000000',
      quoteVolume: 15461096.439797288,
      btcVolume: 1637.8654486722658,
      usdVolume: 15461096.439797288,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '42.6500000000000000',
      high: '43.0000000000000000',
      low: '41.6800000000000000',
      close: '42.3500000000000000',
      volume: '241972.0063200000000000',
      quoteVolume: 10274173.075952465,
      btcVolume: 1114.9908980390967,
      usdVolume: 10274173.075952465,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '42.3400000000000000',
      high: '43.6000000000000000',
      low: '41.3700000000000000',
      close: '43.0900000000000000',
      volume: '348379.3200800000000000',
      quoteVolume: 14852775.950750457,
      btcVolume: 1618.6751008852393,
      usdVolume: 14852775.950750457,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '43.1000000000000000',
      high: '43.1200000000000000',
      low: '39.1100000000000000',
      close: '41.0600000000000000',
      volume: '301504.6343000000000000',
      quoteVolume: 12500262.768287318,
      btcVolume: 1381.4322759722381,
      usdVolume: 12500262.768287318,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '41.0400000000000000',
      high: '42.0500000000000000',
      low: '40.4800000000000000',
      close: '41.3700000000000000',
      volume: '169011.9868500000000000',
      quoteVolume: 6984592.714551371,
      btcVolume: 769.65230482301,
      usdVolume: 6984592.714551371,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '41.3700000000000000',
      high: '42.2900000000000000',
      low: '40.5900000000000000',
      close: '41.8700000000000000',
      volume: '323346.1720000000000000',
      quoteVolume: 13400088.961560369,
      btcVolume: 1466.6645153508061,
      usdVolume: 13400088.961560369,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '41.8700000000000000',
      high: '42.0700000000000000',
      low: '40.7300000000000000',
      close: '41.1700000000000000',
      volume: '170712.3676100000000000',
      quoteVolume: 7047775.850108025,
      btcVolume: 770.4331270254231,
      usdVolume: 7047775.850108025,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '41.1600000000000000',
      high: '42.0100000000000000',
      low: '40.9600000000000000',
      close: '41.7700000000000000',
      volume: '189395.4022900000000000',
      quoteVolume: 7873933.718510014,
      btcVolume: 856.8015905668915,
      usdVolume: 7873933.718510014,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '41.7600000000000000',
      high: '41.9000000000000000',
      low: '40.2800000000000000',
      close: '41.0500000000000000',
      volume: '274520.0541700000000000',
      quoteVolume: 11294890.195721142,
      btcVolume: 1236.9147850457389,
      usdVolume: 11294890.195721142,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '41.0300000000000000',
      high: '41.7000000000000000',
      low: '40.8900000000000000',
      close: '41.1400000000000000',
      volume: '188681.9363500000000000',
      quoteVolume: 7794144.723730499,
      btcVolume: 857.0715613494609,
      usdVolume: 7794144.723730499,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '41.1300000000000000',
      high: '42.2300000000000000',
      low: '41.0600000000000000',
      close: '42.1100000000000000',
      volume: '224248.6520000000000000',
      quoteVolume: 9360266.498081032,
      btcVolume: 1028.5227163525683,
      usdVolume: 9360266.498081032,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '42.1100000000000000',
      high: '42.2000000000000000',
      low: '40.7500000000000000',
      close: '41.5800000000000000',
      volume: '226360.8260500000000000',
      quoteVolume: 9392173.28080973,
      btcVolume: 1037.076979817458,
      usdVolume: 9392173.28080973,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '41.5800000000000000',
      high: '44.3100000000000000',
      low: '41.5000000000000000',
      close: '44.0600000000000000',
      volume: '368489.7151600000000000',
      quoteVolume: 15857759.209187848,
      btcVolume: 1716.882729781821,
      usdVolume: 15857759.209187848,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '44.0700000000000000',
      high: '44.4900000000000000',
      low: '43.0600000000000000',
      close: '43.3900000000000000',
      volume: '307753.4437600000000000',
      quoteVolume: 13417182.820945997,
      btcVolume: 1447.5272221888722,
      usdVolume: 13417182.820945997,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '43.3900000000000000',
      high: '45.6200000000000000',
      low: '43.1000000000000000',
      close: '45.3500000000000000',
      volume: '527238.1869100000000000',
      quoteVolume: 23640875.868721846,
      btcVolume: 2531.3426117904874,
      usdVolume: 23640875.868721846,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '45.3500000000000000',
      high: '45.8700000000000000',
      low: '43.5700000000000000',
      close: '44.3300000000000000',
      volume: '413262.0522600000000000',
      quoteVolume: 18508468.305630103,
      btcVolume: 1984.9220768982761,
      usdVolume: 18508468.305630103,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '44.3400000000000000',
      high: '44.6400000000000000',
      low: '43.4400000000000000',
      close: '44.3000000000000000',
      volume: '256685.4454300000000000',
      quoteVolume: 11325710.672338482,
      btcVolume: 1231.7303880489342,
      usdVolume: 11325710.672338482,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '44.3200000000000000',
      high: '44.7700000000000000',
      low: '43.8500000000000000',
      close: '44.6900000000000000',
      volume: '175099.4520400000000000',
      quoteVolume: 7760961.70704716,
      btcVolume: 840.2449316980167,
      usdVolume: 7760961.70704716,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '44.6800000000000000',
      high: '45.3000000000000000',
      low: '43.7000000000000000',
      close: '44.7000000000000000',
      volume: '245471.1214700000000000',
      quoteVolume: 10954049.958728487,
      btcVolume: 1184.221349967855,
      usdVolume: 10954049.958728487,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '44.7200000000000000',
      high: '45.5900000000000000',
      low: '42.3100000000000000',
      close: '43.9200000000000000',
      volume: '445103.8423000000000000',
      quoteVolume: 19783707.239249174,
      btcVolume: 2134.0023954961785,
      usdVolume: 19783707.239249174,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '43.9100000000000000',
      high: '44.0900000000000000',
      low: '42.8600000000000000',
      close: '43.8800000000000000',
      volume: '246958.9888000000000000',
      quoteVolume: 10753439.352485811,
      btcVolume: 1167.1898197526466,
      usdVolume: 10753439.352485811,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '43.8800000000000000',
      high: '44.0600000000000000',
      low: '42.8100000000000000',
      close: '43.3300000000000000',
      volume: '216931.7592000000000000',
      quoteVolume: 9414665.121901916,
      btcVolume: 1020.4055870355884,
      usdVolume: 9414665.121901916,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '43.3400000000000000',
      high: '43.4900000000000000',
      low: '40.8500000000000000',
      close: '42.0900000000000000',
      volume: '389747.3708100000000000',
      quoteVolume: 16388849.494132191,
      btcVolume: 1793.8588392303009,
      usdVolume: 16388849.494132191,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '42.0900000000000000',
      high: '42.4900000000000000',
      low: '41.7200000000000000',
      close: '41.9000000000000000',
      volume: '201168.4514700000000000',
      quoteVolume: 8451330.732305363,
      btcVolume: 925.0899826232296,
      usdVolume: 8451330.732305363,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '41.8800000000000000',
      high: '42.9000000000000000',
      low: '41.7300000000000000',
      close: '42.4500000000000000',
      volume: '171429.4827800000000000',
      quoteVolume: 7261317.07974402,
      btcVolume: 792.6440309584311,
      usdVolume: 7261317.07974402,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '42.4500000000000000',
      high: '42.8900000000000000',
      low: '41.9400000000000000',
      close: '42.6400000000000000',
      volume: '131250.1648000000000000',
      quoteVolume: 5562354.392689597,
      btcVolume: 607.4853703600584,
      usdVolume: 5562354.392689597,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '42.6500000000000000',
      high: '42.7900000000000000',
      low: '41.3700000000000000',
      close: '41.9400000000000000',
      volume: '180313.8811200000000000',
      quoteVolume: 7608229.1871632915,
      btcVolume: 829.548965830741,
      usdVolume: 7608229.1871632915,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '41.9400000000000000',
      high: '44.1100000000000000',
      low: '41.8700000000000000',
      close: '43.7700000000000000',
      volume: '314409.7580700000000000',
      quoteVolume: 13652466.385358201,
      btcVolume: 1464.4779697490103,
      usdVolume: 13652466.385358201,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '43.7700000000000000',
      high: '45.1200000000000000',
      low: '43.2000000000000000',
      close: '44.9600000000000000',
      volume: '335284.7529800000000000',
      quoteVolume: 14745570.92134318,
      btcVolume: 1567.9594825294864,
      usdVolume: 14745570.92134318,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '44.9600000000000000',
      high: '45.6300000000000000',
      low: '44.5200000000000000',
      close: '44.9400000000000000',
      volume: '432494.1135100000000000',
      quoteVolume: 19488260.358961288,
      btcVolume: 2044.7602331347512,
      usdVolume: 19488260.358961288,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '44.9300000000000000',
      high: '44.9600000000000000',
      low: '43.9300000000000000',
      close: '44.1100000000000000',
      volume: '246802.5687100000000000',
      quoteVolume: 10968239.44478951,
      btcVolume: 1148.885394620396,
      usdVolume: 10968239.44478951,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '44.1200000000000000',
      high: '49.5200000000000000',
      low: '44.0300000000000000',
      close: '49.1600000000000000',
      volume: '908436.8540300000000000',
      quoteVolume: 43121417.14661318,
      btcVolume: 4487.739810331088,
      usdVolume: 43121417.14661318,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '49.1600000000000000',
      high: '50.7900000000000000',
      low: '47.4900000000000000',
      close: '48.1000000000000000',
      volume: '895757.1956100000000000',
      quoteVolume: 43736445.02248403,
      btcVolume: 4445.709420722064,
      usdVolume: 43736445.02248403,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '48.0700000000000000',
      high: '55.8300000000000000',
      low: '46.8500000000000000',
      close: '53.2800000000000000',
      volume: '1501022.7139000000000000',
      quoteVolume: 76319950.40804677,
      btcVolume: 7196.4237752346735,
      usdVolume: 76319950.40804677,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '53.2900000000000000',
      high: '57.9200000000000000',
      low: '51.6500000000000000',
      close: '55.7500000000000000',
      volume: '1524796.9333900000000000',
      quoteVolume: 83657329.52223343,
      btcVolume: 7615.786009539288,
      usdVolume: 83657329.52223343,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '55.7600000000000000',
      high: '56.9700000000000000',
      low: '54.6500000000000000',
      close: '55.1600000000000000',
      volume: '827762.0175900000000000',
      quoteVolume: 46195373.44725716,
      btcVolume: 4170.565054592356,
      usdVolume: 46195373.44725716,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '55.1700000000000000',
      high: '58.6100000000000000',
      low: '53.9100000000000000',
      close: '56.7500000000000000',
      volume: '1023616.3229500000000000',
      quoteVolume: 57619092.418167934,
      btcVolume: 5228.698800494788,
      usdVolume: 57619092.418167934,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '56.7300000000000000',
      high: '59.2600000000000000',
      low: '55.7800000000000000',
      close: '58.1300000000000000',
      volume: '656214.7910400000000000',
      quoteVolume: 37799292.05627845,
      btcVolume: 3370.0313097064472,
      usdVolume: 37799292.05627845,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '58.1300000000000000',
      high: '62.2300000000000000',
      low: '57.5500000000000000',
      close: '61.6500000000000000',
      volume: '1209772.3068500000000000',
      quoteVolume: 72750836.99199294,
      btcVolume: 6265.125426344265,
      usdVolume: 72750836.99199294,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '61.6500000000000000',
      high: '65.1700000000000000',
      low: '51.7900000000000000',
      close: '56.7700000000000000',
      volume: '1811190.5919400000000000',
      quoteVolume: 106128081.26600313,
      btcVolume: 9335.06038904262,
      usdVolume: 106128081.26600313,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '56.7700000000000000',
      high: '60.0300000000000000',
      low: '55.7100000000000000',
      close: '58.4800000000000000',
      volume: '825040.8628300000000000',
      quoteVolume: 48042233.88053324,
      btcVolume: 4268.170005799202,
      usdVolume: 48042233.88053324,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '58.5100000000000000',
      high: '59.6200000000000000',
      low: '56.2600000000000000',
      close: '57.6100000000000000',
      volume: '621412.6135200000000000',
      quoteVolume: 35953617.97974933,
      btcVolume: 3203.314765910491,
      usdVolume: 35953617.97974933,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '57.6200000000000000',
      high: '59.7700000000000000',
      low: '56.7300000000000000',
      close: '58.8800000000000000',
      volume: '716365.4747300000000000',
      quoteVolume: 41978222.97824183,
      btcVolume: 3649.591519738918,
      usdVolume: 41978222.97824183,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '58.8800000000000000',
      high: '60.6800000000000000',
      low: '57.6900000000000000',
      close: '59.0300000000000000',
      volume: '822577.3453400000000000',
      quoteVolume: 48695999.688867584,
      btcVolume: 4138.214894335373,
      usdVolume: 48695999.688867584,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '59.0300000000000000',
      high: '61.0800000000000000',
      low: '54.5100000000000000',
      close: '57.1700000000000000',
      volume: '897778.4995300000000000',
      quoteVolume: 52381526.540909186,
      btcVolume: 4494.798690246006,
      usdVolume: 52381526.540909186,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '57.1900000000000000',
      high: '58.5500000000000000',
      low: '56.4600000000000000',
      close: '58.4600000000000000',
      volume: '428068.0562900000000000',
      quoteVolume: 24639956.16664136,
      btcVolume: 2111.277753614711,
      usdVolume: 24639956.16664136,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '58.4600000000000000',
      high: '58.6700000000000000',
      low: '56.0000000000000000',
      close: '56.9600000000000000',
      volume: '368126.2206100000000000',
      quoteVolume: 21112826.33196615,
      btcVolume: 1810.1238543621755,
      usdVolume: 21112826.33196615,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '56.9600000000000000',
      high: '59.4000000000000000',
      low: '56.3800000000000000',
      close: '58.2700000000000000',
      volume: '651148.7946500000000000',
      quoteVolume: 37767773.986994274,
      btcVolume: 3179.571790810527,
      usdVolume: 37767773.986994274,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '58.2700000000000000',
      high: '59.6700000000000000',
      low: '52.3700000000000000',
      close: '54.1000000000000000',
      volume: '887598.7192800000000000',
      quoteVolume: 49937919.23003544,
      btcVolume: 4305.809808019921,
      usdVolume: 49937919.23003544,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '54.1100000000000000',
      high: '55.0500000000000000',
      low: '51.5500000000000000',
      close: '54.5200000000000000',
      volume: '645092.4992200000000000',
      quoteVolume: 34861226.1993341,
      btcVolume: 3047.8872072032136,
      usdVolume: 34861226.1993341,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '54.5400000000000000',
      high: '57.3000000000000000',
      low: '51.8600000000000000',
      close: '57.1400000000000000',
      volume: '837108.8358500000000000',
      quoteVolume: 45688848.09087257,
      btcVolume: 3954.229249874195,
      usdVolume: 45688848.09087257,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '57.1400000000000000',
      high: '57.8500000000000000',
      low: '55.8200000000000000',
      close: '56.7800000000000000',
      volume: '724000.2630800000000000',
      quoteVolume: 41120118.16273497,
      btcVolume: 3499.1980018550985,
      usdVolume: 41120118.16273497,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '56.7800000000000000',
      high: '60.8700000000000000',
      low: '56.4500000000000000',
      close: '59.8500000000000000',
      volume: '1137795.1226200000000000',
      quoteVolume: 66736568.52031225,
      btcVolume: 5623.638209743656,
      usdVolume: 66736568.52031225,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '59.8500000000000000',
      high: '64.6000000000000000',
      low: '57.8000000000000000',
      close: '63.8700000000000000',
      volume: '1576897.5925500000000000',
      quoteVolume: 97000374.93973804,
      btcVolume: 8195.032534326576,
      usdVolume: 97000374.93973804,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '63.8900000000000000',
      high: '68.8800000000000000',
      low: '61.3400000000000000',
      close: '67.2200000000000000',
      volume: '1790813.8027600000000000',
      quoteVolume: 116245941.78525142,
      btcVolume: 9624.636426175008,
      usdVolume: 116245941.78525142,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '67.2200000000000000',
      high: '68.3700000000000000',
      low: '63.3700000000000000',
      close: '65.4000000000000000',
      volume: '1562910.4138300000000000',
      quoteVolume: 103317811.94231711,
      btcVolume: 8525.308883804713,
      usdVolume: 103317811.94231711,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '65.4200000000000000',
      high: '67.1500000000000000',
      low: '58.7300000000000000',
      close: '62.1200000000000000',
      volume: '1699324.3872900000000000',
      quoteVolume: 105697900.02547406,
      btcVolume: 8977.615215296333,
      usdVolume: 105697900.02547406,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '62.0800000000000000',
      high: '63.6800000000000000',
      low: '61.0000000000000000',
      close: '62.8000000000000000',
      volume: '863943.4751600000000000',
      quoteVolume: 54002617.98938122,
      btcVolume: 4582.333629079296,
      usdVolume: 54002617.98938122,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '62.8000000000000000',
      high: '63.9000000000000000',
      low: '58.7700000000000000',
      close: '59.2000000000000000',
      volume: '1052514.0798200000000000',
      quoteVolume: 64605524.54157742,
      btcVolume: 5505.111606281471,
      usdVolume: 64605524.54157742,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '59.1800000000000000',
      high: '60.5400000000000000',
      low: '57.7600000000000000',
      close: '60.2100000000000000',
      volume: '735565.0401700000000000',
      quoteVolume: 43554757.475364566,
      btcVolume: 3776.8163310064206,
      usdVolume: 43554757.475364566,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '60.2100000000000000',
      high: '61.6300000000000000',
      low: '58.0100000000000000',
      close: '60.5500000000000000',
      volume: '629684.8634600000000000',
      quoteVolume: 37576440.64409044,
      btcVolume: 3233.0834064375513,
      usdVolume: 37576440.64409044,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '60.5600000000000000',
      high: '63.0100000000000000',
      low: '59.9700000000000000',
      close: '62.0200000000000000',
      volume: '687059.2379900000000000',
      quoteVolume: 42496265.57367974,
      btcVolume: 3621.93782544044,
      usdVolume: 42496265.57367974,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '62.0300000000000000',
      high: '62.2900000000000000',
      low: '56.6000000000000000',
      close: '58.3400000000000000',
      volume: '1124054.9217200000000000',
      quoteVolume: 66441420.47979737,
      btcVolume: 5809.195150196892,
      usdVolume: 66441420.47979737,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '58.3300000000000000',
      high: '59.1000000000000000',
      low: '57.0200000000000000',
      close: '58.1000000000000000',
      volume: '663363.6882900000000000',
      quoteVolume: 38514355.9755069,
      btcVolume: 3381.1134622002087,
      usdVolume: 38514355.9755069,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '58.1100000000000000',
      high: '59.2400000000000000',
      low: '53.9100000000000000',
      close: '56.1000000000000000',
      volume: '973405.5399700000000000',
      quoteVolume: 55038359.140136294,
      btcVolume: 4861.832698425037,
      usdVolume: 55038359.140136294,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '56.1200000000000000',
      high: '58.2800000000000000',
      low: '55.3300000000000000',
      close: '57.3800000000000000',
      volume: '637283.3690700000000000',
      quoteVolume: 36330743.38773648,
      btcVolume: 3176.5984724538266,
      usdVolume: 36330743.38773648,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '57.3600000000000000',
      high: '58.1800000000000000',
      low: '56.9300000000000000',
      close: '56.9600000000000000',
      volume: '401337.7699700000000000',
      quoteVolume: 23096156.99058844,
      btcVolume: 2008.145085599693,
      usdVolume: 23096156.99058844,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '56.9400000000000000',
      high: '62.9500000000000000',
      low: '56.9400000000000000',
      close: '62.8900000000000000',
      volume: '748060.0688700000000000',
      quoteVolume: 45090938.55684995,
      btcVolume: 3882.7301382338846,
      usdVolume: 45090938.55684995,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '62.8900000000000000',
      high: '63.7000000000000000',
      low: '60.5100000000000000',
      close: '60.9200000000000000',
      volume: '680146.7862100000000000',
      quoteVolume: 42096579.01839404,
      btcVolume: 3604.5811924799323,
      usdVolume: 42096579.01839404,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '60.9400000000000000',
      high: '64.3100000000000000',
      low: '59.6900000000000000',
      close: '62.6900000000000000',
      volume: '858839.7179200000000000',
      quoteVolume: 53770235.135081835,
      btcVolume: 4530.7995896947705,
      usdVolume: 53764694.36727703,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '62.6800000000000000',
      high: '63.1900000000000000',
      low: '56.3200000000000000',
      close: '58.0600000000000000',
      volume: '931097.5278800000000000',
      quoteVolume: 55440952.94712479,
      btcVolume: 4813.422832983661,
      usdVolume: 55440952.94712479,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '58.0700000000000000',
      high: '58.6200000000000000',
      low: '45.3300000000000000',
      close: '47.2800000000000000',
      volume: '1314623.3469700000000000',
      quoteVolume: 70288090.06395356,
      btcVolume: 6485.293399090235,
      usdVolume: 70288090.06395356,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '47.2800000000000000',
      high: '52.3800000000000000',
      low: '46.2400000000000000',
      close: '50.3200000000000000',
      volume: '1475370.2928000000000000',
      quoteVolume: 72616532.67811747,
      btcVolume: 7023.38061709872,
      usdVolume: 72616532.67811747,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '50.3300000000000000',
      high: '51.3200000000000000',
      low: '45.2100000000000000',
      close: '47.8300000000000000',
      volume: '1350136.6749200000000000',
      quoteVolume: 64913830.12016042,
      btcVolume: 6374.162126272181,
      usdVolume: 64913830.12016042,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '47.8200000000000000',
      high: '49.2500000000000000',
      low: '46.2800000000000000',
      close: '47.9200000000000000',
      volume: '652628.8199300000000000',
      quoteVolume: 31304000.690226022,
      btcVolume: 3068.8890835351963,
      usdVolume: 31304000.690226022,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '47.9300000000000000',
      high: '49.1800000000000000',
      low: '45.2200000000000000',
      close: '48.8400000000000000',
      volume: '811120.8419300000000000',
      quoteVolume: 38431332.983129896,
      btcVolume: 3795.0340089993906,
      usdVolume: 38431332.983129896,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '48.8500000000000000',
      high: '49.3500000000000000',
      low: '45.9800000000000000',
      close: '47.4700000000000000',
      volume: '760182.5056400000000000',
      quoteVolume: 36217916.465697624,
      btcVolume: 3579.288705742158,
      usdVolume: 36217916.465697624,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '47.4700000000000000',
      high: '48.8100000000000000',
      low: '46.5200000000000000',
      close: '47.8200000000000000',
      volume: '378772.5075900000000000',
      quoteVolume: 18086146.485945318,
      btcVolume: 1776.629629881756,
      usdVolume: 18086146.485945318,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '47.8200000000000000',
      high: '49.4500000000000000',
      low: '47.7700000000000000',
      close: '48.6700000000000000',
      volume: '472784.4530600000000000',
      quoteVolume: 23042794.85121679,
      btcVolume: 2228.667738928336,
      usdVolume: 23042794.85121679,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '48.6500000000000000',
      high: '49.5900000000000000',
      low: '47.3700000000000000',
      close: '48.9600000000000000',
      volume: '367092.2208900000000000',
      quoteVolume: 17795692.856570706,
      btcVolume: 1728.2423967171549,
      usdVolume: 17795692.856570706,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '48.9500000000000000',
      high: '51.3100000000000000',
      low: '48.3800000000000000',
      close: '50.8000000000000000',
      volume: '451940.7079000000000000',
      quoteVolume: 22526331.35838648,
      btcVolume: 2165.612033112267,
      usdVolume: 22526331.35838648,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '50.8200000000000000',
      high: '51.0400000000000000',
      low: '47.2600000000000000',
      close: '48.1500000000000000',
      volume: '458828.4182100000000000',
      quoteVolume: 22491968.402588304,
      btcVolume: 2169.5843476754585,
      usdVolume: 22491968.402588304,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '48.1700000000000000',
      high: '49.6500000000000000',
      low: '47.1100000000000000',
      close: '49.0800000000000000',
      volume: '493683.7537500000000000',
      quoteVolume: 23889800.051954094,
      btcVolume: 2275.1908831815435,
      usdVolume: 23889800.051954094,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '49.0800000000000000',
      high: '50.3700000000000000',
      low: '48.1000000000000000',
      close: '48.3100000000000000',
      volume: '615374.4693200000000000',
      quoteVolume: 30324133.006873846,
      btcVolume: 2816.2117686878037,
      usdVolume: 30324133.006873846,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '48.2900000000000000',
      high: '48.7500000000000000',
      low: '46.9100000000000000',
      close: '47.7600000000000000',
      volume: '409293.5778300000000000',
      quoteVolume: 19641762.865069613,
      btcVolume: 1806.5374075469983,
      usdVolume: 19641762.865069613,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '47.7600000000000000',
      high: '49.6700000000000000',
      low: '47.6400000000000000',
      close: '48.8500000000000000',
      volume: '412392.2905700000000000',
      quoteVolume: 20027421.966672275,
      btcVolume: 1836.6245811757067,
      usdVolume: 20027421.966672275,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '48.8500000000000000',
      high: '49.0700000000000000',
      low: '47.4400000000000000',
      close: '48.5400000000000000',
      volume: '322351.6253900000000000',
      quoteVolume: 15606108.2084333,
      btcVolume: 1428.8963360394227,
      usdVolume: 15606108.2084333,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '48.5300000000000000',
      high: '49.0300000000000000',
      low: '47.9400000000000000',
      close: '48.5200000000000000',
      volume: '225043.6954300000000000',
      quoteVolume: 10911627.63864736,
      btcVolume: 989.4437357340863,
      usdVolume: 10911627.63864736,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '48.5100000000000000',
      high: '48.5100000000000000',
      low: '46.1500000000000000',
      close: '47.0900000000000000',
      volume: '307000.2131400000000000',
      quoteVolume: 14530136.545319801,
      btcVolume: 1333.6149737245325,
      usdVolume: 14530136.545319801,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '47.1000000000000000',
      high: '47.6400000000000000',
      low: '41.5900000000000000',
      close: '43.1600000000000000',
      volume: '724381.6674200000000000',
      quoteVolume: 32251827.730186,
      btcVolume: 3041.5168729433744,
      usdVolume: 32251827.730186,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '43.1700000000000000',
      high: '44.7900000000000000',
      low: '42.7500000000000000',
      close: '44.4700000000000000',
      volume: '333108.9347300000000000',
      quoteVolume: 14589016.845933013,
      btcVolume: 1393.625461476955,
      usdVolume: 14589016.845933013,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '44.4700000000000000',
      high: '45.1400000000000000',
      low: '42.2500000000000000',
      close: '42.9600000000000000',
      volume: '416831.7164900000000000',
      quoteVolume: 18334844.49414594,
      btcVolume: 1763.5199781287474,
      usdVolume: 18334844.49414594,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '42.9900000000000000',
      high: '45.2900000000000000',
      low: '42.5600000000000000',
      close: '44.9200000000000000',
      volume: '384786.2813000000000000',
      quoteVolume: 16990572.907504234,
      btcVolume: 1626.0734649737904,
      usdVolume: 16990572.907504234,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '44.9200000000000000',
      high: '46.5800000000000000',
      low: '43.9700000000000000',
      close: '46.0200000000000000',
      volume: '417026.3309900000000000',
      quoteVolume: 18981897.443485588,
      btcVolume: 1782.2563567218074,
      usdVolume: 18981897.443485588,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '46.5600000000000000',
      low: '45.1200000000000000',
      close: '46.0300000000000000',
      volume: '239753.2724600000000000',
      quoteVolume: 11008649.377640275,
      btcVolume: 1027.554331921975,
      usdVolume: 11008649.377640275,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '46.6200000000000000',
      low: '44.5600000000000000',
      close: '46.0500000000000000',
      volume: '299418.6488900000000000',
      quoteVolume: 13705245.681490649,
      btcVolume: 1279.5801104718228,
      usdVolume: 13705245.681490649,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '46.0500000000000000',
      high: '47.1400000000000000',
      low: '44.8000000000000000',
      close: '45.2300000000000000',
      volume: '500207.2502000000000000',
      quoteVolume: 23194876.99856291,
      btcVolume: 2133.7172755091074,
      usdVolume: 23194876.99856291,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '45.2300000000000000',
      high: '45.8500000000000000',
      low: '44.9500000000000000',
      close: '45.6900000000000000',
      volume: '314647.6859400000000000',
      quoteVolume: 14294098.56854367,
      btcVolume: 1332.3540477383756,
      usdVolume: 14294098.56854367,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '45.6800000000000000',
      high: '46.7000000000000000',
      low: '45.4400000000000000',
      close: '46.2400000000000000',
      volume: '293684.9697200000000000',
      quoteVolume: 13501441.41659697,
      btcVolume: 1256.481594643728,
      usdVolume: 13501441.41659697,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '46.2600000000000000',
      high: '48.4600000000000000',
      low: '45.2300000000000000',
      close: '46.2500000000000000',
      volume: '678479.7253200000000000',
      quoteVolume: 31764172.418082513,
      btcVolume: 2962.4967676505066,
      usdVolume: 31764172.418082513,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '46.2000000000000000',
      high: '46.9700000000000000',
      low: '43.1900000000000000',
      close: '44.8800000000000000',
      volume: '514023.5243600000000000',
      quoteVolume: 23159362.416366585,
      btcVolume: 2205.834513704922,
      usdVolume: 23159362.416366585,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '44.8800000000000000',
      high: '45.9700000000000000',
      low: '44.7200000000000000',
      close: '45.5100000000000000',
      volume: '225825.7437200000000000',
      quoteVolume: 10251368.171526043,
      btcVolume: 972.2082293671139,
      usdVolume: 10251368.171526043,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '45.5000000000000000',
      high: '47.0700000000000000',
      low: '45.2800000000000000',
      close: '46.6900000000000000',
      volume: '257423.6618800000000000',
      quoteVolume: 11876813.22842714,
      btcVolume: 1119.7951342568836,
      usdVolume: 11876813.22842714,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '46.6800000000000000',
      high: '47.0800000000000000',
      low: '45.5300000000000000',
      close: '46.3400000000000000',
      volume: '287599.9852800000000000',
      quoteVolume: 13304869.255124534,
      btcVolume: 1243.5815826726844,
      usdVolume: 13304869.255124534,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '46.3500000000000000',
      high: '48.2500000000000000',
      low: '45.5200000000000000',
      close: '45.9900000000000000',
      volume: '650695.8909300000000000',
      quoteVolume: 30393271.881367873,
      btcVolume: 2846.7976903218064,
      usdVolume: 30393271.881367873,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '46.9600000000000000',
      low: '45.0200000000000000',
      close: '46.5500000000000000',
      volume: '384592.7193700000000000',
      quoteVolume: 17704976.198836096,
      btcVolume: 1667.6503606006302,
      usdVolume: 17704976.198836096,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '46.5600000000000000',
      high: '47.5000000000000000',
      low: '45.4500000000000000',
      close: '47.2100000000000000',
      volume: '401592.8257000000000000',
      quoteVolume: 18706424.61098511,
      btcVolume: 1743.2242091557664,
      usdVolume: 18706424.61098511,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '47.2100000000000000',
      high: '48.2000000000000000',
      low: '46.5900000000000000',
      close: '47.7800000000000000',
      volume: '402514.8916800000000000',
      quoteVolume: 19072193.160766963,
      btcVolume: 1736.864381218933,
      usdVolume: 19072193.160766963,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '47.7800000000000000',
      high: '50.4600000000000000',
      low: '47.7600000000000000',
      close: '48.8800000000000000',
      volume: '426163.7387700000000000',
      quoteVolume: 21004622.662101585,
      btcVolume: 1853.0414493767137,
      usdVolume: 21004622.662101585,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '48.8900000000000000',
      high: '51.2700000000000000',
      low: '48.6600000000000000',
      close: '50.4800000000000000',
      volume: '389959.9119800000000000',
      quoteVolume: 19591405.274990585,
      btcVolume: 1723.361127572673,
      usdVolume: 19591405.274990585,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '50.4800000000000000',
      high: '51.5000000000000000',
      low: '49.2400000000000000',
      close: '50.5800000000000000',
      volume: '547647.7248700000000000',
      quoteVolume: 27687729.169129193,
      btcVolume: 2420.2362706464387,
      usdVolume: 27687729.169129193,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '50.5800000000000000',
      high: '50.7100000000000000',
      low: '48.8000000000000000',
      close: '49.8900000000000000',
      volume: '415337.0570800000000000',
      quoteVolume: 20736639.884147868,
      btcVolume: 1812.348882829239,
      usdVolume: 20736639.884147868,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '49.9000000000000000',
      high: '51.0400000000000000',
      low: '49.2700000000000000',
      close: '49.7900000000000000',
      volume: '331002.8019200000000000',
      quoteVolume: 16584880.285257336,
      btcVolume: 1454.7096946875001,
      usdVolume: 16584880.285257336,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '49.7900000000000000',
      high: '50.2400000000000000',
      low: '48.8000000000000000',
      close: '49.5300000000000000',
      volume: '348137.2278700000000000',
      quoteVolume: 17261154.582352445,
      btcVolume: 1514.5854746239897,
      usdVolume: 17261154.582352445,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '49.5200000000000000',
      high: '49.8400000000000000',
      low: '45.6000000000000000',
      close: '47.6000000000000000',
      volume: '603775.0778600000000000',
      quoteVolume: 28593842.01842037,
      btcVolume: 2521.7754293189723,
      usdVolume: 28593842.01842037,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '47.6100000000000000',
      high: '47.6400000000000000',
      low: '46.2800000000000000',
      close: '46.9300000000000000',
      volume: '222889.3661200000000000',
      quoteVolume: 10493526.272867309,
      btcVolume: 925.8634715761125,
      usdVolume: 10493526.272867309,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '46.9200000000000000',
      high: '47.9800000000000000',
      low: '46.7800000000000000',
      close: '47.5400000000000000',
      volume: '164733.2730500000000000',
      quoteVolume: 7805904.884538982,
      btcVolume: 683.1095831981876,
      usdVolume: 7805904.884538982,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '47.5400000000000000',
      high: '48.4400000000000000',
      low: '46.8000000000000000',
      close: '47.9900000000000000',
      volume: '316808.8316300000000000',
      quoteVolume: 15130105.410249878,
      btcVolume: 1303.2892230318398,
      usdVolume: 15130105.410249878,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '47.9900000000000000',
      high: '48.4600000000000000',
      low: '46.3600000000000000',
      close: '46.8800000000000000',
      volume: '399229.1667700000000000',
      quoteVolume: 18895656.11728302,
      btcVolume: 1594.7065459227063,
      usdVolume: 18895656.11728302,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '46.8800000000000000',
      high: '55.0000000000000000',
      low: '46.8500000000000000',
      close: '52.9800000000000000',
      volume: '1425655.0175000000000000',
      quoteVolume: 73309576.45552866,
      btcVolume: 5835.867276617416,
      usdVolume: 73309576.45552866,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '52.9800000000000000',
      high: '56.3100000000000000',
      low: '52.9700000000000000',
      close: '54.3500000000000000',
      volume: '1405462.7678200000000000',
      quoteVolume: 77304160.5002028,
      btcVolume: 5998.580339830083,
      usdVolume: 77304160.5002028,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '54.3500000000000000',
      high: '57.0000000000000000',
      low: '53.0200000000000000',
      close: '55.4200000000000000',
      volume: '955931.4407600000000000',
      quoteVolume: 52751563.52434198,
      btcVolume: 4083.2913725414433,
      usdVolume: 52751563.52434198,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '55.4100000000000000',
      high: '59.6400000000000000',
      low: '54.8300000000000000',
      close: '59.2300000000000000',
      volume: '945743.9995400000000000',
      quoteVolume: 54668377.69328972,
      btcVolume: 4200.363593327066,
      usdVolume: 54668377.69328972,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '59.2200000000000000',
      high: '60.0000000000000000',
      low: '56.5300000000000000',
      close: '58.8400000000000000',
      volume: '906306.0174900000000000',
      quoteVolume: 52834489.40751223,
      btcVolume: 4053.9574657856656,
      usdVolume: 52834489.40751223,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '58.8300000000000000',
      high: '59.3100000000000000',
      low: '54.9400000000000000',
      close: '56.8200000000000000',
      volume: '937112.5894400000000000',
      quoteVolume: 53652363.95019294,
      btcVolume: 4114.583402151287,
      usdVolume: 53652363.95019294,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '56.8000000000000000',
      high: '59.0600000000000000',
      low: '56.4600000000000000',
      close: '57.9100000000000000',
      volume: '841336.6319200000000000',
      quoteVolume: 48609139.49673568,
      btcVolume: 3630.1006218151615,
      usdVolume: 48609139.49673568,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '57.9000000000000000',
      high: '60.5500000000000000',
      low: '54.7100000000000000',
      close: '55.7700000000000000',
      volume: '1108813.1369600000000000',
      quoteVolume: 63758393.118976705,
      btcVolume: 4749.631359213374,
      usdVolume: 63758393.118976705,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '55.7600000000000000',
      high: '56.5700000000000000',
      low: '53.3700000000000000',
      close: '54.8700000000000000',
      volume: '798253.7344200000000000',
      quoteVolume: 44138399.06422936,
      btcVolume: 3326.956263048808,
      usdVolume: 44138399.06422936,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '54.8800000000000000',
      high: '55.7800000000000000',
      low: '52.2000000000000000',
      close: '54.0100000000000000',
      volume: '706074.8976900000000000',
      quoteVolume: 38012823.48380605,
      btcVolume: 2842.8179458148893,
      usdVolume: 38012823.48380605,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '54.0200000000000000',
      high: '56.5000000000000000',
      low: '53.6000000000000000',
      close: '55.7200000000000000',
      volume: '568503.1681100000000000',
      quoteVolume: 31438490.218596324,
      btcVolume: 2284.8493085352666,
      usdVolume: 31438490.218596324,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '55.6900000000000000',
      high: '56.1900000000000000',
      low: '54.0700000000000000',
      close: '55.6000000000000000',
      volume: '369373.8115100000000000',
      quoteVolume: 20377654.865856357,
      btcVolume: 1481.5753298312095,
      usdVolume: 20377654.865856357,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '55.6000000000000000',
      high: '56.8400000000000000',
      low: '53.0100000000000000',
      close: '53.8000000000000000',
      volume: '700498.4928000000000000',
      quoteVolume: 38304588.60460887,
      btcVolume: 2824.797492464806,
      usdVolume: 38304588.60460887,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '53.8200000000000000',
      high: '54.3700000000000000',
      low: '51.1300000000000000',
      close: '53.9600000000000000',
      volume: '671178.3424500000000000',
      quoteVolume: 35476946.64793883,
      btcVolume: 2616.935741127481,
      usdVolume: 35476946.64793883,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '53.9600000000000000',
      high: '55.2400000000000000',
      low: '51.5900000000000000',
      close: '54.6700000000000000',
      volume: '801009.7913300000000000',
      quoteVolume: 42873857.89305916,
      btcVolume: 3085.610113492847,
      usdVolume: 42873857.89305916,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '54.7000000000000000',
      high: '59.7300000000000000',
      low: '54.6200000000000000',
      close: '58.9500000000000000',
      volume: '1366335.6293900000000000',
      quoteVolume: 78053912.16519552,
      btcVolume: 5264.593238274786,
      usdVolume: 78053912.16519552,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '58.9400000000000000',
      high: '63.6800000000000000',
      low: '58.6100000000000000',
      close: '63.2000000000000000',
      volume: '1688091.0752800000000000',
      quoteVolume: 103709490.48633637,
      btcVolume: 6656.930874335542,
      usdVolume: 103709490.48633637,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '63.2200000000000000',
      high: '64.7700000000000000',
      low: '57.0000000000000000',
      close: '58.9000000000000000',
      volume: '1654668.9549600000000000',
      quoteVolume: 101602887.2770486,
      btcVolume: 6659.106944962225,
      usdVolume: 101602887.2770486,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '58.9000000000000000',
      high: '62.0300000000000000',
      low: '58.5200000000000000',
      close: '61.0200000000000000',
      volume: '794156.2220100000000000',
      quoteVolume: 47870580.79491664,
      btcVolume: 3150.0612302343625,
      usdVolume: 47870580.79491664,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '61.0000000000000000',
      high: '61.8600000000000000',
      low: '57.3700000000000000',
      close: '59.2300000000000000',
      volume: '997389.4548800000000000',
      quoteVolume: 59476114.155003875,
      btcVolume: 3879.134836223116,
      usdVolume: 59476114.155003875,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '59.2200000000000000',
      high: '59.6000000000000000',
      low: '57.0400000000000000',
      close: '57.9300000000000000',
      volume: '639971.9687300000000000',
      quoteVolume: 37332965.35725458,
      btcVolume: 2441.6074414528925,
      usdVolume: 37332965.35725458,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '57.9400000000000000',
      high: '60.5300000000000000',
      low: '57.8000000000000000',
      close: '59.2700000000000000',
      volume: '824536.8814700000000000',
      quoteVolume: 49135913.09168669,
      btcVolume: 3157.3304871935893,
      usdVolume: 49135913.09168669,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '59.2800000000000000',
      high: '60.8200000000000000',
      low: '57.6000000000000000',
      close: '60.7200000000000000',
      volume: '830578.2622800000000000',
      quoteVolume: 48949867.85248493,
      btcVolume: 3069.4575024684545,
      usdVolume: 48949867.85248493,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '60.7200000000000000',
      high: '66.6600000000000000',
      low: '60.1400000000000000',
      close: '65.9600000000000000',
      volume: '1890699.0773000000000000',
      quoteVolume: 120417135.62659656,
      btcVolume: 7402.366563715269,
      usdVolume: 120416609.86659656,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '65.9600000000000000',
      high: '66.3200000000000000',
      low: '61.6300000000000000',
      close: '63.8500000000000000',
      volume: '1004682.3311900000000000',
      quoteVolume: 63840748.70659265,
      btcVolume: 3982.6190328362654,
      usdVolume: 63840748.70659265,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '63.8300000000000000',
      high: '64.4300000000000000',
      low: '61.0800000000000000',
      close: '62.2800000000000000',
      volume: '685198.3981500000000000',
      quoteVolume: 42934465.16887135,
      btcVolume: 2687.015722325898,
      usdVolume: 42934465.16887135,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '62.2900000000000000',
      high: '74.6200000000000000',
      low: '62.0800000000000000',
      close: '73.8400000000000000',
      volume: '2523297.8789300000000000',
      quoteVolume: 174212415.64149517,
      btcVolume: 10641.431291066707,
      usdVolume: 174212415.64149517,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '73.8300000000000000',
      high: '77.0000000000000000',
      low: '71.3600000000000000',
      close: '76.3200000000000000',
      volume: '2672837.8382000000000000',
      quoteVolume: 197658068.17998445,
      btcVolume: 11577.927976041276,
      usdVolume: 197658068.17998445,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '76.3200000000000000',
      high: '77.1300000000000000',
      low: '67.7700000000000000',
      close: '73.4200000000000000',
      volume: '2633618.3237700000000000',
      quoteVolume: 192305528.25402403,
      btcVolume: 10784.179482659336,
      usdVolume: 192305528.25402403,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '73.4100000000000000',
      high: '83.4000000000000000',
      low: '71.4700000000000000',
      close: '81.5300000000000000',
      volume: '2733332.0071700000000000',
      quoteVolume: 212853407.31297737,
      btcVolume: 11944.761485430221,
      usdVolume: 212853407.31297737,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '81.5400000000000000',
      high: '85.6300000000000000',
      low: '79.9900000000000000',
      close: '82.5300000000000000',
      volume: '2343217.5381100000000000',
      quoteVolume: 192700587.20860234,
      btcVolume: 10522.118130650888,
      usdVolume: 192700587.20860234,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '82.5500000000000000',
      high: '88.5000000000000000',
      low: '80.1000000000000000',
      close: '87.1400000000000000',
      volume: '2521719.4082800000000000',
      quoteVolume: 212594343.03374535,
      btcVolume: 11359.43501812571,
      usdVolume: 212594343.03374535,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '87.0900000000000000',
      high: '87.9200000000000000',
      low: '77.3000000000000000',
      close: '82.8700000000000000',
      volume: '2048304.5808200000000000',
      quoteVolume: 171408076.02709004,
      btcVolume: 9333.179458237288,
      usdVolume: 171408076.02709004,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '82.8700000000000000',
      high: '90.9300000000000000',
      low: '80.8000000000000000',
      close: '89.0300000000000000',
      volume: '2486393.8341900000000000',
      quoteVolume: 215727635.64653075,
      btcVolume: 11699.840364269925,
      usdVolume: 215727635.64653075,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '89.0400000000000000',
      high: '94.0200000000000000',
      low: '84.6000000000000000',
      close: '89.2500000000000000',
      volume: '2621715.6430300000000000',
      quoteVolume: 235080123.5942224,
      btcVolume: 12570.326121700413,
      usdVolume: 235080123.5942224,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '89.2700000000000000',
      high: '90.7600000000000000',
      low: '78.0500000000000000',
      close: '81.7500000000000000',
      volume: '1623767.0887800000000000',
      quoteVolume: 140421215.4986958,
      btcVolume: 7390.470493802939,
      usdVolume: 140421215.4986958,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '81.7500000000000000',
      high: '83.2900000000000000',
      low: '64.4500000000000000',
      close: '71.0000000000000000',
      volume: '3435959.2951800000000000',
      quoteVolume: 251435782.40591905,
      btcVolume: 14535.673824882117,
      usdVolume: 251435782.40591905,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '70.9900000000000000',
      high: '73.5000000000000000',
      low: '65.3000000000000000',
      close: '69.1500000000000000',
      volume: '1707521.5040200000000000',
      quoteVolume: 118203288.9302594,
      btcVolume: 6968.137207364826,
      usdVolume: 118203288.9302594,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '69.1800000000000000',
      high: '74.9700000000000000',
      low: '68.2600000000000000',
      close: '72.7000000000000000',
      volume: '1482675.1561800000000000',
      quoteVolume: 106143884.81866534,
      btcVolume: 6107.917822619824,
      usdVolume: 106143884.81866534,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '72.6800000000000000',
      high: '81.0700000000000000',
      low: '71.5100000000000000',
      close: '78.9100000000000000',
      volume: '1514393.2172200000000000',
      quoteVolume: 115521557.95323898,
      btcVolume: 6412.697834411257,
      usdVolume: 115521557.95323898,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '78.8900000000000000',
      high: '88.4600000000000000',
      low: '76.6300000000000000',
      close: '87.6500000000000000',
      volume: '2118061.6227200000000000',
      quoteVolume: 176367822.45216078,
      btcVolume: 9216.718286347665,
      usdVolume: 176367822.45216078,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '87.6600000000000000',
      high: '93.0100000000000000',
      low: '79.5600000000000000',
      close: '85.0800000000000000',
      volume: '3495457.6565900000000000',
      quoteVolume: 307020765.6007774,
      btcVolume: 16015.001816947644,
      usdVolume: 307020765.6007774,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '85.0700000000000000',
      high: '90.6300000000000000',
      low: '82.6100000000000000',
      close: '88.9000000000000000',
      volume: '2135078.4793700000000000',
      quoteVolume: 185587960.28621328,
      btcVolume: 9814.274674754284,
      usdVolume: 185587960.28621328,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '88.8900000000000000',
      high: '91.5000000000000000',
      low: '85.4600000000000000',
      close: '89.0000000000000000',
      volume: '1690248.4588500000000000',
      quoteVolume: 149648537.47962323,
      btcVolume: 7763.714935990381,
      usdVolume: 149648537.47962323,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '89.0100000000000000',
      high: '89.8400000000000000',
      low: '77.8000000000000000',
      close: '79.5200000000000000',
      volume: '1701697.8531700000000000',
      quoteVolume: 144315548.66815314,
      btcVolume: 7578.867302398465,
      usdVolume: 144315548.66815314,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '79.5200000000000000',
      high: '84.2800000000000000',
      low: '78.6100000000000000',
      close: '83.1400000000000000',
      volume: '1275970.0537600000000000',
      quoteVolume: 104423917.99398686,
      btcVolume: 5517.332671389263,
      usdVolume: 104423917.99398686,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '83.1100000000000000',
      high: '85.1900000000000000',
      low: '80.3100000000000000',
      close: '83.5500000000000000',
      volume: '921046.7599600000000000',
      quoteVolume: 76405873.32399245,
      btcVolume: 3991.063481423035,
      usdVolume: 76405873.32399245,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '83.5400000000000000',
      high: '86.8700000000000000',
      low: '81.7300000000000000',
      close: '83.3700000000000000',
      volume: '1145446.4740700000000000',
      quoteVolume: 96166838.18654118,
      btcVolume: 5011.269364217304,
      usdVolume: 96166838.18654118,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '83.3700000000000000',
      high: '84.8300000000000000',
      low: '75.4100000000000000',
      close: '76.7600000000000000',
      volume: '1230953.8697500000000000',
      quoteVolume: 98587741.41994153,
      btcVolume: 5229.632704580527,
      usdVolume: 98587741.41994153,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '76.7500000000000000',
      high: '78.1400000000000000',
      low: '72.1300000000000000',
      close: '77.4100000000000000',
      volume: '1378297.5836100000000000',
      quoteVolume: 104644648.13944061,
      btcVolume: 5748.061365408652,
      usdVolume: 104644648.13944061,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '77.4100000000000000',
      high: '77.6600000000000000',
      low: '72.5100000000000000',
      close: '74.7000000000000000',
      volume: '897534.5890900000000000',
      quoteVolume: 67578374.0447259,
      btcVolume: 3697.8440886872677,
      usdVolume: 67578374.0447259,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '74.7700000000000000',
      high: '75.0900000000000000',
      low: '70.1200000000000000',
      close: '72.2000000000000000',
      volume: '1385260.5103000000000000',
      quoteVolume: 99443406.27715112,
      btcVolume: 5548.574129400644,
      usdVolume: 99438023.14967503,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '72.2000000000000000',
      high: '77.5800000000000000',
      low: '71.9700000000000000',
      close: '76.6700000000000000',
      volume: '778073.6457200000000000',
      quoteVolume: 58653684.98446523,
      btcVolume: 3179.4830099512424,
      usdVolume: 58653684.98446523,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '76.6400000000000000',
      high: '84.3800000000000000',
      low: '75.5200000000000000',
      close: '82.2800000000000000',
      volume: '1278882.9832800000000000',
      quoteVolume: 102688853.34340486,
      btcVolume: 5349.804122942844,
      usdVolume: 102688853.34340486,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '82.2700000000000000',
      high: '82.9800000000000000',
      low: '79.1100000000000000',
      close: '82.3500000000000000',
      volume: '1101678.0078500000000000',
      quoteVolume: 89408089.55373186,
      btcVolume: 4664.897724867536,
      usdVolume: 89408089.55373186,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '82.3800000000000000',
      high: '84.9300000000000000',
      low: '80.3900000000000000',
      close: '81.3200000000000000',
      volume: '1261474.3270300000000000',
      quoteVolume: 103669039.39603397,
      btcVolume: 5363.974475859031,
      usdVolume: 103669039.39603397,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '81.3600000000000000',
      high: '93.4400000000000000',
      low: '78.3100000000000000',
      close: '92.9400000000000000',
      volume: '2150469.3674300000000000',
      quoteVolume: 185140134.72739515,
      btcVolume: 9105.005162094782,
      usdVolume: 185140134.72739515,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '92.9400000000000000',
      high: '109.8000000000000000',
      low: '90.0000000000000000',
      close: '101.0100000000000000',
      volume: '4161454.8925300000000000',
      quoteVolume: 414585850.6995076,
      btcVolume: 18207.769039019673,
      usdVolume: 414585850.6995076,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '100.9900000000000000',
      high: '112.8800000000000000',
      low: '99.2400000000000000',
      close: '109.3400000000000000',
      volume: '3140130.3420400000000000',
      quoteVolume: 337503795.3740959,
      btcVolume: 14761.494258487553,
      usdVolume: 337503795.3740959,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '109.3400000000000000',
      high: '124.2400000000000000',
      low: '105.3100000000000000',
      close: '119.8500000000000000',
      volume: '2753360.1997100000000000',
      quoteVolume: 316850965.36465776,
      btcVolume: 13481.095408817218,
      usdVolume: 316850965.36465776,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '119.8500000000000000',
      high: '123.2100000000000000',
      low: '109.7600000000000000',
      close: '114.5800000000000000',
      volume: '2671305.3283900000000000',
      quoteVolume: 313367367.83264947,
      btcVolume: 13298.314869032618,
      usdVolume: 313367367.83264947,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '114.5900000000000000',
      high: '118.6500000000000000',
      low: '100.3100000000000000',
      close: '104.4500000000000000',
      volume: '2580555.1076800000000000',
      quoteVolume: 281116978.1689041,
      btcVolume: 12139.994599410998,
      usdVolume: 281116978.1689041,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '104.4800000000000000',
      high: '114.6300000000000000',
      low: '98.3500000000000000',
      close: '114.3200000000000000',
      volume: '3210319.3252800000000000',
      quoteVolume: 342738420.0010703,
      btcVolume: 14838.635043893324,
      usdVolume: 342738420.0010703,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '114.3100000000000000',
      high: '117.2900000000000000',
      low: '95.2300000000000000',
      close: '102.4500000000000000',
      volume: '3923591.0743400000000000',
      quoteVolume: 424020893.5197978,
      btcVolume: 18035.881882760194,
      usdVolume: 424020893.5197978,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '102.4600000000000000',
      high: '112.3200000000000000',
      low: '98.5500000000000000',
      close: '111.1300000000000000',
      volume: '2696980.5169700000000000',
      quoteVolume: 282469396.37838954,
      btcVolume: 12178.014726858833,
      usdVolume: 282469396.37838954,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '111.1300000000000000',
      high: '128.6300000000000000',
      low: '108.1800000000000000',
      close: '127.7800000000000000',
      volume: '3108695.5070400000000000',
      quoteVolume: 369487915.87514305,
      btcVolume: 15293.283381962749,
      usdVolume: 369487915.87514305,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '127.7400000000000000',
      high: '135.1900000000000000',
      low: '124.9400000000000000',
      close: '129.6100000000000000',
      volume: '3461075.6473000000000000',
      quoteVolume: 449830180.98402226,
      btcVolume: 17798.908427176073,
      usdVolume: 449830180.98402226,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '129.6100000000000000',
      high: '139.4700000000000000',
      low: '121.8800000000000000',
      close: '127.8800000000000000',
      volume: '3743173.1300100000000000',
      quoteVolume: 494840941.2477139,
      btcVolume: 18223.64596921554,
      usdVolume: 494840941.2477139,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '127.8300000000000000',
      high: '137.4100000000000000',
      low: '126.3700000000000000',
      close: '129.8300000000000000',
      volume: '2349842.9550200000000000',
      quoteVolume: 309678082.06321186,
      btcVolume: 11483.214550343224,
      usdVolume: 309678082.06321186,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '129.8400000000000000',
      high: '130.9000000000000000',
      low: '120.0000000000000000',
      close: '128.8000000000000000',
      volume: '1732248.3840900000000000',
      quoteVolume: 218608845.40846404,
      btcVolume: 8200.6564058765,
      usdVolume: 218608845.40846404,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '128.8000000000000000',
      high: '133.1100000000000000',
      low: '122.6300000000000000',
      close: '130.0500000000000000',
      volume: '1727676.6410000000000000',
      quoteVolume: 220908109.4346938,
      btcVolume: 7872.871817315565,
      usdVolume: 220908109.4346938,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '130.0600000000000000',
      high: '130.3500000000000000',
      low: '121.9800000000000000',
      close: '124.2100000000000000',
      volume: '1248723.0342400000000000',
      quoteVolume: 156991311.03482178,
      btcVolume: 5449.115523218407,
      usdVolume: 156991311.03482178,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '124.2200000000000000',
      high: '133.9700000000000000',
      low: '122.8900000000000000',
      close: '126.2400000000000000',
      volume: '1536326.1071500000000000',
      quoteVolume: 197120659.81115305,
      btcVolume: 6747.775990258352,
      usdVolume: 197120659.81115305,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '126.2000000000000000',
      high: '142.0000000000000000',
      low: '123.1500000000000000',
      close: '136.9600000000000000',
      volume: '2442266.7650000000000000',
      quoteVolume: 323461384.15540344,
      btcVolume: 10287.35677451215,
      usdVolume: 323461384.15540344,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '136.9400000000000000',
      high: '164.7000000000000000',
      low: '135.3000000000000000',
      close: '161.2400000000000000',
      volume: '3813834.0766900000000000',
      quoteVolume: 568621276.8387065,
      btcVolume: 16926.853812273916,
      usdVolume: 568621276.8387065,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '161.2300000000000000',
      high: '173.6600000000000000',
      low: '139.3100000000000000',
      close: '155.3400000000000000',
      volume: '3530422.2039200000000000',
      quoteVolume: 551040498.8754859,
      btcVolume: 17344.92344775779,
      usdVolume: 551040498.8754859,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '155.3300000000000000',
      high: '162.8000000000000000',
      low: '146.5200000000000000',
      close: '158.4300000000000000',
      volume: '1998175.7975400000000000',
      quoteVolume: 308975694.1353862,
      btcVolume: 9579.752665689979,
      usdVolume: 308975694.1353862,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '158.4600000000000000',
      high: '169.7200000000000000',
      low: '154.9500000000000000',
      close: '168.9200000000000000',
      volume: '1857437.6845700000000000',
      quoteVolume: 302142832.62634295,
      btcVolume: 8680.956043080312,
      usdVolume: 302142832.62634295,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '168.9600000000000000',
      high: '181.2200000000000000',
      low: '161.1800000000000000',
      close: '169.3500000000000000',
      volume: '2479926.5663800000000000',
      quoteVolume: 422479799.42550886,
      btcVolume: 11058.616576435745,
      usdVolume: 422479799.42550886,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '169.3500000000000000',
      high: '181.9200000000000000',
      low: '150.0000000000000000',
      close: '172.1900000000000000',
      volume: '2833341.6404500000000000',
      quoteVolume: 478225816.520269,
      btcVolume: 11964.035801892664,
      usdVolume: 478225816.520269,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '172.2800000000000000',
      high: '178.8000000000000000',
      low: '163.3000000000000000',
      close: '177.2600000000000000',
      volume: '1749781.8497300000000000',
      quoteVolume: 299170389.286915,
      btcVolume: 7425.313037764587,
      usdVolume: 299170389.286915,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '177.2700000000000000',
      high: '185.8300000000000000',
      low: '161.8600000000000000',
      close: '170.0700000000000000',
      volume: '2269089.8322300000000000',
      quoteVolume: 397596277.8818141,
      btcVolume: 10055.323763923585,
      usdVolume: 397596277.8818141,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '170.0600000000000000',
      high: '170.7400000000000000',
      low: '112.7000000000000000',
      close: '138.8700000000000000',
      volume: '4877205.3953600000000000',
      quoteVolume: 662514925.4818044,
      btcVolume: 19588.40860151175,
      usdVolume: 662514925.4818044,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '138.8800000000000000',
      high: '146.8600000000000000',
      low: '128.0000000000000000',
      close: '133.4200000000000000',
      volume: '2477243.9833200000000000',
      quoteVolume: 340391038.4888703,
      btcVolume: 9797.131310819967,
      usdVolume: 340391038.4888703,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '133.4400000000000000',
      high: '148.4600000000000000',
      low: '125.9000000000000000',
      close: '147.3800000000000000',
      volume: '2088185.1472100000000000',
      quoteVolume: 286151973.30492896,
      btcVolume: 8269.541344650439,
      usdVolume: 286151973.30492896,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '147.3900000000000000',
      high: '156.8900000000000000',
      low: '143.5500000000000000',
      close: '152.5000000000000000',
      volume: '2033762.6002700000000000',
      quoteVolume: 304472230.40606844,
      btcVolume: 7883.6115631974335,
      usdVolume: 304472230.40606844,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '152.4600000000000000',
      high: '155.7400000000000000',
      low: '130.0000000000000000',
      close: '143.6500000000000000',
      volume: '1929869.1502000000000000',
      quoteVolume: 279867480.1701219,
      btcVolume: 7532.0663673781455,
      usdVolume: 279867480.1701219,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '143.6600000000000000',
      high: '152.5900000000000000',
      low: '139.2800000000000000',
      close: '143.5200000000000000',
      volume: '1619686.2842200000000000',
      quoteVolume: 236445434.4062732,
      btcVolume: 6406.112755540417,
      usdVolume: 236445434.4062732,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '143.5200000000000000',
      high: '147.0000000000000000',
      low: '136.2200000000000000',
      close: '142.8000000000000000',
      volume: '1379082.3792000000000000',
      quoteVolume: 195777669.99104866,
      btcVolume: 5518.389442894679,
      usdVolume: 195777669.99104866,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '142.7800000000000000',
      high: '154.9900000000000000',
      low: '138.4400000000000000',
      close: '151.9600000000000000',
      volume: '1644918.1028000000000000',
      quoteVolume: 242251163.15367344,
      btcVolume: 6662.692550152778,
      usdVolume: 242251163.15367344,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '151.9700000000000000',
      high: '166.0200000000000000',
      low: '150.1800000000000000',
      close: '151.8600000000000000',
      volume: '2250266.1240300000000000',
      quoteVolume: 358045416.38105613,
      btcVolume: 9715.528233417612,
      usdVolume: 358045416.38105613,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '151.8500000000000000',
      high: '156.1500000000000000',
      low: '138.4000000000000000',
      close: '149.6900000000000000',
      volume: '2037065.0855500000000000',
      quoteVolume: 300099295.2165031,
      btcVolume: 8570.773651988773,
      usdVolume: 300099295.2165031,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '149.6700000000000000',
      high: '150.2500000000000000',
      low: '125.6400000000000000',
      close: '129.7200000000000000',
      volume: '2176119.4788900000000000',
      quoteVolume: 296428620.1166068,
      btcVolume: 9078.008984863776,
      usdVolume: 296428620.1166068,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '129.7000000000000000',
      high: '141.8500000000000000',
      low: '122.7000000000000000',
      close: '137.6600000000000000',
      volume: '1951005.0663900000000000',
      quoteVolume: 263524938.22357655,
      btcVolume: 8423.197856584778,
      usdVolume: 263524938.22357655,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '137.6500000000000000',
      high: '143.5200000000000000',
      low: '135.0500000000000000',
      close: '137.6700000000000000',
      volume: '819478.3417900000000000',
      quoteVolume: 113831601.15240765,
      btcVolume: 3514.611539877437,
      usdVolume: 113831601.15240765,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '137.6600000000000000',
      high: '142.6500000000000000',
      low: '133.7300000000000000',
      close: '141.0100000000000000',
      volume: '917459.8505800000000000',
      quoteVolume: 127591959.12300138,
      btcVolume: 3964.1660200345823,
      usdVolume: 127591959.12300138,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '141.0100000000000000',
      high: '147.8700000000000000',
      low: '136.8300000000000000',
      close: '138.5600000000000000',
      volume: '1297445.3763800000000000',
      quoteVolume: 186110798.1019838,
      btcVolume: 5571.664082584082,
      usdVolume: 186110798.1019838,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '132.7000000000000000',
      high: '136.3000000000000000',
      low: '132.5100000000000000',
      close: '134.6700000000000000',
      volume: '239830.9402400000000000',
      quoteVolume: 32177113.892162576,
      btcVolume: 999.7459445541468,
      usdVolume: 32177113.892162576,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '134.6900000000000000',
      high: '134.9600000000000000',
      low: '118.1300000000000000',
      close: '122.4400000000000000',
      volume: '1112624.2676100000000000',
      quoteVolume: 139464919.21385488,
      btcVolume: 4524.577313776934,
      usdVolume: 139464919.21385488,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '122.4500000000000000',
      high: '135.8400000000000000',
      low: '120.7400000000000000',
      close: '133.2700000000000000',
      volume: '937794.6301600000000000',
      quoteVolume: 121424463.23872843,
      btcVolume: 3823.7182988262484,
      usdVolume: 121424463.23872843,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '133.2500000000000000',
      high: '145.5000000000000000',
      low: '131.4400000000000000',
      close: '134.6500000000000000',
      volume: '1953065.9401100000000000',
      quoteVolume: 269778147.58305985,
      btcVolume: 7637.60669728038,
      usdVolume: 269778147.58305985,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '134.6700000000000000',
      high: '136.2300000000000000',
      low: '128.0200000000000000',
      close: '133.3600000000000000',
      volume: '1171505.9664700000000000',
      quoteVolume: 154495780.49306908,
      btcVolume: 4551.220051793771,
      usdVolume: 154495780.49306908,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '133.3500000000000000',
      high: '135.0600000000000000',
      low: '125.6700000000000000',
      close: '129.3600000000000000',
      volume: '806399.7757100000000000',
      quoteVolume: 104722485.35395245,
      btcVolume: 3138.156642547338,
      usdVolume: 104722485.35395245,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '129.3700000000000000',
      high: '136.6300000000000000',
      low: '125.9500000000000000',
      close: '132.0000000000000000',
      volume: '1135286.5070700000000000',
      quoteVolume: 149340674.82508767,
      btcVolume: 4416.238424240979,
      usdVolume: 149340674.82508767,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '131.9900000000000000',
      high: '145.8200000000000000',
      low: '131.8700000000000000',
      close: '141.9800000000000000',
      volume: '1883149.5372600000000000',
      quoteVolume: 265391340.62986633,
      btcVolume: 7621.335552581734,
      usdVolume: 265391340.62986633,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '141.9600000000000000',
      high: '156.7000000000000000',
      low: '141.9200000000000000',
      close: '155.9300000000000000',
      volume: '1820821.5903600000000000',
      quoteVolume: 276355678.3739926,
      btcVolume: 7583.191926757077,
      usdVolume: 276355678.3739926,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '155.9400000000000000',
      high: '158.6800000000000000',
      low: '141.0300000000000000',
      close: '145.0700000000000000',
      volume: '1762820.3654400000000000',
      quoteVolume: 263148795.56052074,
      btcVolume: 7024.899854343085,
      usdVolume: 263148795.56052074,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '145.0500000000000000',
      high: '156.7800000000000000',
      low: '143.4500000000000000',
      close: '155.0500000000000000',
      volume: '1557577.8368500000000000',
      quoteVolume: 237025412.1601315,
      btcVolume: 6297.118687933591,
      usdVolume: 237025412.1601315,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '155.0500000000000000',
      high: '164.2700000000000000',
      low: '149.8200000000000000',
      close: '155.5700000000000000',
      volume: '1878793.7270300000000000',
      quoteVolume: 298604965.4190308,
      btcVolume: 7537.248027732104,
      usdVolume: 298604965.4190308,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '155.5700000000000000',
      high: '157.2900000000000000',
      low: '145.2300000000000000',
      close: '150.7600000000000000',
      volume: '1259975.9289400000000000',
      quoteVolume: 190631760.74434358,
      btcVolume: 4938.417851396465,
      usdVolume: 190631760.74434358,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '150.7600000000000000',
      high: '169.3800000000000000',
      low: '147.7600000000000000',
      close: '167.2800000000000000',
      volume: '1869837.9437200000000000',
      quoteVolume: 297709749.822012,
      btcVolume: 7033.137189014122,
      usdVolume: 297709749.822012,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '167.2800000000000000',
      high: '184.4900000000000000',
      low: '164.2200000000000000',
      close: '181.4900000000000000',
      volume: '1981149.1236600000000000',
      quoteVolume: 339741920.4317491,
      btcVolume: 7286.474296530587,
      usdVolume: 339741920.4317491,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '181.5200000000000000',
      high: '195.1700000000000000',
      low: '169.5700000000000000',
      close: '181.5600000000000000',
      volume: '2781973.9625400000000000',
      quoteVolume: 511568659.8745071,
      btcVolume: 11171.189003450432,
      usdVolume: 511396513.1418937,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '181.5900000000000000',
      high: '193.5900000000000000',
      low: '175.7200000000000000',
      close: '183.6200000000000000',
      volume: '1662458.2148900000000000',
      quoteVolume: 308980082.707041,
      btcVolume: 6638.340260622761,
      usdVolume: 308980082.707041,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '183.6200000000000000',
      high: '199.8400000000000000',
      low: '176.7500000000000000',
      close: '196.6900000000000000',
      volume: '1944278.4281900000000000',
      quoteVolume: 363675328.6517229,
      btcVolume: 7651.174521048404,
      usdVolume: 363646656.3346562,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '196.7100000000000000',
      high: '229.0700000000000000',
      low: '191.4500000000000000',
      close: '225.7600000000000000',
      volume: '2843476.1869100000000000',
      quoteVolume: 594213638.5294985,
      btcVolume: 12603.247974606615,
      usdVolume: 594155739.24837,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '225.7700000000000000',
      high: '230.7700000000000000',
      low: '208.1100000000000000',
      close: '213.4900000000000000',
      volume: '1654862.3886300000000000',
      quoteVolume: 363531568.69063455,
      btcVolume: 7495.86825872764,
      usdVolume: 363531568.69063455,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '213.4700000000000000',
      high: '219.9900000000000000',
      low: '185.3100000000000000',
      close: '207.8300000000000000',
      volume: '2145096.2026600000000000',
      quoteVolume: 441287107.2575872,
      btcVolume: 9249.958178220597,
      usdVolume: 440751484.0592311,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '208.0300000000000000',
      high: '222.4800000000000000',
      low: '200.1000000000000000',
      close: '210.2600000000000000',
      volume: '1655771.0432500000000000',
      quoteVolume: 350218158.5830828,
      btcVolume: 7007.571698477013,
      usdVolume: 342424004.4173157,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '210.2300000000000000',
      high: '237.7500000000000000',
      low: '203.3700000000000000',
      close: '237.4900000000000000',
      volume: '1781373.1518300000000000',
      quoteVolume: 397616883.22465694,
      btcVolume: 7787.136345561708,
      usdVolume: 397616883.22465694,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '237.3900000000000000',
      high: '239.1800000000000000',
      low: '221.0000000000000000',
      close: '226.5900000000000000',
      volume: '1580820.7100000000000000',
      quoteVolume: 362511271.6078358,
      btcVolume: 6993.752084518185,
      usdVolume: 362511271.6078358,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '226.5800000000000000',
      high: '241.9200000000000000',
      low: '220.0600000000000000',
      close: '236.5000000000000000',
      volume: '1398560.5533800000000000',
      quoteVolume: 324183188.11299986,
      btcVolume: 6114.868427314256,
      usdVolume: 324183188.11299986,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '236.4500000000000000',
      high: '247.0400000000000000',
      low: '214.0200000000000000',
      close: '226.6500000000000000',
      volume: '1699874.2734800000000000',
      quoteVolume: 401212940.2932522,
      btcVolume: 7143.652988554469,
      usdVolume: 401212460.39169174,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '226.7700000000000000',
      high: '233.7800000000000000',
      low: '220.0600000000000000',
      close: '227.1300000000000000',
      volume: '1268407.0304600000000000',
      quoteVolume: 288545534.88444746,
      btcVolume: 5070.698499732074,
      usdVolume: 288545534.88444746,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '227.1700000000000000',
      high: '227.3900000000000000',
      low: '180.0000000000000000',
      close: '208.3200000000000000',
      volume: '2222077.3503300000000000',
      quoteVolume: 460086677.0755931,
      btcVolume: 8037.5535313587925,
      usdVolume: 432162455.0772618,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '208.1000000000000000',
      high: '208.2300000000000000',
      low: '158.8300000000000000',
      close: '177.3400000000000000',
      volume: '3390922.1286900000000000',
      quoteVolume: 606664750.1952728,
      btcVolume: 12391.424200432175,
      usdVolume: 602656639.5134262,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '177.3500000000000000',
      high: '189.4600000000000000',
      low: '169.0800000000000000',
      close: '181.4300000000000000',
      volume: '1385993.1585900000000000',
      quoteVolume: 251534269.16248846,
      btcVolume: 5045.167835396706,
      usdVolume: 251505675.69378206,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '181.4300000000000000',
      high: '205.2100000000000000',
      low: '175.0000000000000000',
      close: '179.3600000000000000',
      volume: '1961972.0422000000000000',
      quoteVolume: 374933085.95019436,
      btcVolume: 7503.165956785665,
      usdVolume: 374933085.95019436,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '179.3600000000000000',
      high: '182.8600000000000000',
      low: '162.7400000000000000',
      close: '170.2500000000000000',
      volume: '2179517.4955500000000000',
      quoteVolume: 376772370.9497997,
      btcVolume: 8092.3230294664,
      usdVolume: 376485294.8561675,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '170.2000000000000000',
      high: '180.4800000000000000',
      low: '167.1300000000000000',
      close: '171.7600000000000000',
      volume: '1113606.4401000000000000',
      quoteVolume: 194721552.36965466,
      btcVolume: 4134.973488951998,
      usdVolume: 194721552.36965466,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '171.7500000000000000',
      high: '173.0000000000000000',
      low: '152.8800000000000000',
      close: '165.2200000000000000',
      volume: '1630049.9177200000000000',
      quoteVolume: 262199243.42310986,
      btcVolume: 5874.032431282306,
      usdVolume: 262199243.42310986,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '165.2700000000000000',
      high: '176.4600000000000000',
      low: '163.3000000000000000',
      close: '175.8900000000000000',
      volume: '1157182.4178100000000000',
      quoteVolume: 196920601.48332697,
      btcVolume: 4133.868226475361,
      usdVolume: 196920601.48332697,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '175.9000000000000000',
      high: '185.3200000000000000',
      low: '170.6900000000000000',
      close: '179.0800000000000000',
      volume: '1441741.0023500000000000',
      quoteVolume: 255346162.55105585,
      btcVolume: 5250.61792323071,
      usdVolume: 255346162.55105585,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '179.0600000000000000',
      high: '196.4900000000000000',
      low: '176.7900000000000000',
      close: '187.6400000000000000',
      volume: '1788998.4592800000000000',
      quoteVolume: 340485879.58694166,
      btcVolume: 6762.4933075880845,
      usdVolume: 340485879.58694166,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '187.7000000000000000',
      high: '194.4000000000000000',
      low: '176.8300000000000000',
      close: '180.6700000000000000',
      volume: '1484950.5713600000000000',
      quoteVolume: 275283690.3275003,
      btcVolume: 5567.250076468444,
      usdVolume: 275230426.05999345,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '180.6600000000000000',
      high: '183.5700000000000000',
      low: '168.5000000000000000',
      close: '180.2300000000000000',
      volume: '1182501.6988200000000000',
      quoteVolume: 206736683.42664367,
      btcVolume: 4343.41978808486,
      usdVolume: 206736683.42664367,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '180.2100000000000000',
      high: '184.6700000000000000',
      low: '174.6800000000000000',
      close: '182.8500000000000000',
      volume: '890375.3310000000000000',
      quoteVolume: 160843481.4558911,
      btcVolume: 3295.7412318699794,
      usdVolume: 159221856.4260747,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '182.8600000000000000',
      high: '192.2400000000000000',
      low: '182.2500000000000000',
      close: '190.7500000000000000',
      volume: '820418.2390100000000000',
      quoteVolume: 153066685.711372,
      btcVolume: 3041.8416759099878,
      usdVolume: 153066685.711372,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '190.7500000000000000',
      high: '195.2800000000000000',
      low: '179.5800000000000000',
      close: '191.8500000000000000',
      volume: '1145514.1533900000000000',
      quoteVolume: 213310567.88827464,
      btcVolume: 4215.520210397939,
      usdVolume: 213310567.88827464,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '191.8600000000000000',
      high: '205.0000000000000000',
      low: '189.2500000000000000',
      close: '203.8100000000000000',
      volume: '1149941.2715400000000000',
      quoteVolume: 228024787.08482048,
      btcVolume: 4230.227265341441,
      usdVolume: 228024787.08482048,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '203.8000000000000000',
      high: '208.0000000000000000',
      low: '191.4700000000000000',
      close: '200.2900000000000000',
      volume: '1292995.9055100000000000',
      quoteVolume: 259453140.60054418,
      btcVolume: 4683.568407179226,
      usdVolume: 259436705.04717085,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '200.2900000000000000',
      high: '203.5400000000000000',
      low: '191.0300000000000000',
      close: '201.3600000000000000',
      volume: '980924.4222700000000000',
      quoteVolume: 194360542.3998627,
      btcVolume: 3462.342245940359,
      usdVolume: 194360542.3998627,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '201.3700000000000000',
      high: '224.2300000000000000',
      low: '198.4100000000000000',
      close: '221.4200000000000000',
      volume: '1977431.3681000000000000',
      quoteVolume: 416264469.25011075,
      btcVolume: 5921.218381160836,
      usdVolume: 336886590.78517324,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '218.6500000000000000',
      high: '230.2300000000000000',
      low: '212.7000000000000000',
      close: '226.0600000000000000',
      volume: '1310331.5687300000000000',
      quoteVolume: 292374674.933538,
      btcVolume: 4956.360993659334,
      usdVolume: 292374674.933538,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '226.0800000000000000',
      high: '228.1200000000000000',
      low: '213.8400000000000000',
      close: '213.9400000000000000',
      volume: '798435.6040500000000000',
      quoteVolume: 175877600.8876904,
      btcVolume: 2901.7605953284483,
      usdVolume: 175390710.21983862,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '214.0000000000000000',
      high: '222.5800000000000000',
      low: '197.5600000000000000',
      close: '200.5900000000000000',
      volume: '1413381.5745600000000000',
      quoteVolume: 291674131.5058429,
      btcVolume: 5106.393558040645,
      usdVolume: 291674131.5058429,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '200.5900000000000000',
      high: '205.5300000000000000',
      low: '191.1500000000000000',
      close: '201.6000000000000000',
      volume: '992587.9162400000000000',
      quoteVolume: 197656651.7759283,
      btcVolume: 3582.8634646412274,
      usdVolume: 197591598.5747801,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '201.6100000000000000',
      high: '206.9400000000000000',
      low: '194.1300000000000000',
      close: '205.9800000000000000',
      volume: '881712.4524000000000000',
      quoteVolume: 176884881.60728255,
      btcVolume: 3142.8106849439105,
      usdVolume: 176884881.60728255,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '205.9600000000000000',
      high: '208.2500000000000000',
      low: '199.1300000000000000',
      close: '199.7000000000000000',
      volume: '774116.3689700000000000',
      quoteVolume: 158124202.7894712,
      btcVolume: 2696.520577593762,
      usdVolume: 158123481.68116263,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '199.7200000000000000',
      high: '205.0000000000000000',
      low: '195.8100000000000000',
      close: '199.9600000000000000',
      volume: '515574.3893500000000000',
      quoteVolume: 103843591.3837549,
      btcVolume: 1779.0182398838715,
      usdVolume: 103678690.17642716,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '199.9600000000000000',
      high: '206.1000000000000000',
      low: '198.8200000000000000',
      close: '200.1700000000000000',
      volume: '408707.5852000000000000',
      quoteVolume: 82836648.74984606,
      btcVolume: 1409.1616201750144,
      usdVolume: 82836585.73164566,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '200.1600000000000000',
      high: '202.0000000000000000',
      low: '192.0000000000000000',
      close: '195.3100000000000000',
      volume: '579091.9878400000000000',
      quoteVolume: 113737671.3688079,
      btcVolume: 1986.3030989694942,
      usdVolume: 113644945.73073904,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '195.3300000000000000',
      high: '199.0000000000000000',
      low: '184.4200000000000000',
      close: '185.6700000000000000',
      volume: '725077.5781400000000000',
      quoteVolume: 139899897.6498326,
      btcVolume: 2476.105185480285,
      usdVolume: 139844519.7670538,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '185.6900000000000000',
      high: '189.4400000000000000',
      low: '181.1200000000000000',
      close: '185.8300000000000000',
      volume: '632291.1116900000000000',
      quoteVolume: 117638252.58571476,
      btcVolume: 1495.9951187040629,
      usdVolume: 81470228.03850105,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '185.8200000000000000',
      high: '197.1700000000000000',
      low: '172.0000000000000000',
      close: '176.0500000000000000',
      volume: '1081496.2327600000000000',
      quoteVolume: 204651010.18833292,
      btcVolume: 3732.791518881732,
      usdVolume: 204651010.18833292,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '176.0500000000000000',
      high: '179.0300000000000000',
      low: '167.8600000000000000',
      close: '171.5300000000000000',
      volume: '840201.4116500000000000',
      quoteVolume: 145853345.58006474,
      btcVolume: 2520.2457069925285,
      usdVolume: 130917909.539687,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '171.5300000000000000',
      high: '183.8200000000000000',
      low: '171.4400000000000000',
      close: '183.4500000000000000',
      volume: '602677.7101500000000000',
      quoteVolume: 107824053.70199299,
      btcVolume: 1947.79094311182,
      usdVolume: 103830246.82252973,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '183.4400000000000000',
      high: '186.1200000000000000',
      low: '177.7500000000000000',
      close: '184.0600000000000000',
      volume: '475886.8387300000000000',
      quoteVolume: 86757743.80726413,
      btcVolume: 1572.6367137829343,
      usdVolume: 86752376.32223454,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '184.0600000000000000',
      high: '187.2900000000000000',
      low: '180.0000000000000000',
      close: '184.2400000000000000',
      volume: '380545.1621100000000000',
      quoteVolume: 70082688.39183253,
      btcVolume: 1254.4888606538248,
      usdVolume: 70065167.58697064,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '184.2200000000000000',
      high: '197.6000000000000000',
      low: '182.4500000000000000',
      close: '193.7300000000000000',
      volume: '891104.9208000000000000',
      quoteVolume: 172040612.47646165,
      btcVolume: 3033.1693284032813,
      usdVolume: 171975567.3003832,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '193.7300000000000000',
      high: '200.0900000000000000',
      low: '190.3400000000000000',
      close: '195.7400000000000000',
      volume: '932290.6498000000000000',
      quoteVolume: 181705740.05520037,
      btcVolume: 3111.562505675036,
      usdVolume: 181705701.26920035,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '195.7100000000000000',
      high: '198.9900000000000000',
      low: '187.0000000000000000',
      close: '196.7000000000000000',
      volume: '698229.6378600000000000',
      quoteVolume: 135030259.1244356,
      btcVolume: 2299.6428350422234,
      usdVolume: 134758219.97780785,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '196.6900000000000000',
      high: '205.0000000000000000',
      low: '194.0000000000000000',
      close: '202.8600000000000000',
      volume: '860883.6398300000000000',
      quoteVolume: 171796660.07412636,
      btcVolume: 2915.077930636623,
      usdVolume: 171627655.0033485,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '202.8700000000000000',
      high: '213.7700000000000000',
      low: '200.7800000000000000',
      close: '211.4600000000000000',
      volume: '911504.7463400000000000',
      quoteVolume: 188097396.48105705,
      btcVolume: 3171.97268148674,
      usdVolume: 188075782.95719445,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '211.4400000000000000',
      high: '217.9700000000000000',
      low: '194.8500000000000000',
      close: '195.2300000000000000',
      volume: '955157.6424300000000000',
      quoteVolume: 199272205.1538647,
      btcVolume: 1923.0081233352637,
      usdVolume: 113285748.669347,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '195.1700000000000000',
      high: '204.9200000000000000',
      low: '192.2200000000000000',
      close: '202.2100000000000000',
      volume: '537808.5370400000000000',
      quoteVolume: 107874767.17797312,
      btcVolume: 1616.1763098667245,
      usdVolume: 93333101.29026881,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '202.2400000000000000',
      high: '225.4700000000000000',
      low: '198.0700000000000000',
      close: '221.4800000000000000',
      volume: '1320185.3316300000000000',
      quoteVolume: 279356016.0500422,
      btcVolume: 3604.645404045187,
      usdVolume: 210101068.50432605,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '221.4800000000000000',
      high: '244.5900000000000000',
      low: '212.9700000000000000',
      close: '237.0400000000000000',
      volume: '2258610.1931100000000000',
      quoteVolume: 520462770.85170555,
      btcVolume: 8308.54901413599,
      usdVolume: 484407399.03375864,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '236.9600000000000000',
      high: '242.9900000000000000',
      low: '210.7700000000000000',
      close: '219.0500000000000000',
      volume: '2252949.3544600000000000',
      quoteVolume: 507979738.3688623,
      btcVolume: 8713.75947843975,
      usdVolume: 496605124.34581065,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '219.2200000000000000',
      high: '228.2100000000000000',
      low: '216.8900000000000000',
      close: '226.1100000000000000',
      volume: '1040059.1698900000000000',
      quoteVolume: 232196028.07719645,
      btcVolume: 2623.6393819989507,
      usdVolume: 149526942.68774295,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '226.1700000000000000',
      high: '228.9900000000000000',
      low: '218.7200000000000000',
      close: '221.1200000000000000',
      volume: '629961.8332600000000000',
      quoteVolume: 141610934.81979573,
      btcVolume: 2106.0786755977633,
      usdVolume: 122650861.38468109,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '221.1300000000000000',
      high: '257.0000000000000000',
      low: '219.1600000000000000',
      close: '256.3400000000000000',
      volume: '1859281.8830400000000000',
      quoteVolume: 441762890.57776946,
      btcVolume: 7247.337651456451,
      usdVolume: 434426894.35602313,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '256.5500000000000000',
      high: '263.6200000000000000',
      low: '0.0113660000000000',
      close: '252.3100000000000000',
      volume: '1890921.5803900000000000',
      quoteVolume: 480319113.2081568,
      btcVolume: 7907.7185822236115,
      usdVolume: 472938780.5553337,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '252.3500000000000000',
      high: '259.9400000000000000',
      low: '240.0100000000000000',
      close: '244.4200000000000000',
      volume: '1328144.9269900000000000',
      quoteVolume: 331420362.6224557,
      btcVolume: 4408.987173128506,
      usdVolume: 264961310.1003388,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '244.4400000000000000',
      high: '273.5400000000000000',
      low: '244.3600000000000000',
      close: '267.5900000000000000',
      volume: '1815822.6911300000000000',
      quoteVolume: 476947654.2477242,
      btcVolume: 7578.822561176737,
      usdVolume: 472033209.7211003,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '267.6100000000000000',
      high: '283.0000000000000000',
      low: '255.0000000000000000',
      close: '278.8600000000000000',
      volume: '1934889.3058500000000000',
      quoteVolume: 524702625.81534594,
      btcVolume: 7474.81355593654,
      usdVolume: 474608362.09590185,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '278.8000000000000000',
      high: '290.9000000000000000',
      low: '266.0000000000000000',
      close: '285.6300000000000000',
      volume: '1409512.9217200000000000',
      quoteVolume: 392049527.74386656,
      btcVolume: 5819.337916249173,
      usdVolume: 366017514.8535886,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '285.6400000000000000',
      high: '317.0900000000000000',
      low: '265.5000000000000000',
      close: '308.4300000000000000',
      volume: '3150447.9992800000000000',
      quoteVolume: 924239206.0551368,
      btcVolume: 13754.14904721424,
      usdVolume: 847181090.2771821,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '308.3900000000000000',
      high: '335.6900000000000000',
      low: '296.7500000000000000',
      close: '300.8600000000000000',
      volume: '2574519.6355000000000000',
      quoteVolume: 817941072.7363646,
      btcVolume: 8650.440913958724,
      usdVolume: 529440111.53025746,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '300.8700000000000000',
      high: '304.8300000000000000',
      low: '232.0000000000000000',
      close: '273.3600000000000000',
      volume: '3046826.0682100000000000',
      quoteVolume: 814978326.9479513,
      btcVolume: 14251.274999648283,
      usdVolume: 799689184.4717911,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '273.3500000000000000',
      high: '284.3900000000000000',
      low: '250.2100000000000000',
      close: '261.3800000000000000',
      volume: '1973502.3718900000000000',
      quoteVolume: 526831697.8987827,
      btcVolume: 7545.76975452113,
      usdVolume: 424171196.8055375,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '261.4700000000000000',
      high: '272.8000000000000000',
      low: '234.3100000000000000',
      close: '260.6800000000000000',
      volume: '2100827.2657200000000000',
      quoteVolume: 536954207.2394092,
      btcVolume: 9272.855998053765,
      usdVolume: 514533908.33471185,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '260.7300000000000000',
      high: '277.0000000000000000',
      low: '250.7500000000000000',
      close: '257.6900000000000000',
      volume: '1690547.6833500000000000',
      quoteVolume: 446244126.3759943,
      btcVolume: 6848.168090990059,
      usdVolume: 380430906.4184981,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '257.6300000000000000',
      high: '289.6100000000000000',
      low: '239.0000000000000000',
      close: '252.5000000000000000',
      volume: '2441035.4976300000000000',
      quoteVolume: 653807393.5680175,
      btcVolume: 3138.9783548631967,
      usdVolume: 166658633.73239762,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '252.6000000000000000',
      high: '257.1500000000000000',
      low: '214.2900000000000000',
      close: '241.3100000000000000',
      volume: '2633187.8420800000000000',
      quoteVolume: 613120720.0451101,
      btcVolume: 6726.945644391381,
      usdVolume: 334739258.42326885,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '241.3100000000000000',
      high: '241.4100000000000000',
      low: '220.3400000000000000',
      close: '224.7500000000000000',
      volume: '1127242.4756500000000000',
      quoteVolume: 258970433.8586322,
      btcVolume: 4959.794572907675,
      usdVolume: 247114101.5572867,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '224.7600000000000000',
      high: '235.8000000000000000',
      low: '210.7300000000000000',
      close: '223.8800000000000000',
      volume: '872669.4430500000000000',
      quoteVolume: 197094883.39333206,
      btcVolume: 2116.3775832422107,
      usdVolume: 104947659.02477793,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '223.9000000000000000',
      high: '248.2000000000000000',
      low: '221.2400000000000000',
      close: '247.4200000000000000',
      volume: '1090110.9487400000000000',
      quoteVolume: 262056371.5676198,
      btcVolume: 2935.0307772177202,
      usdVolume: 155435733.32541108,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '247.4000000000000000',
      high: '263.1500000000000000',
      low: '243.6300000000000000',
      close: '259.7700000000000000',
      volume: '1113898.0502300000000000',
      quoteVolume: 283129397.5147066,
      btcVolume: 5071.1060750962415,
      usdVolume: 276778818.2280468,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '259.7700000000000000',
      high: '266.9400000000000000',
      low: '247.5800000000000000',
      close: '258.5100000000000000',
      volume: '1014038.4373400000000000',
      quoteVolume: 259424641.2548764,
      btcVolume: 4737.856416827141,
      usdVolume: 259424641.2548764,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '258.5000000000000000',
      high: '262.8900000000000000',
      low: '248.1600000000000000',
      close: '255.3100000000000000',
      volume: '856981.6494100000000000',
      quoteVolume: 218587991.57567295,
      btcVolume: 4061.048623525768,
      usdVolume: 218587991.57567295,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '255.3100000000000000',
      high: '273.5800000000000000',
      low: '252.6100000000000000',
      close: '271.1300000000000000',
      volume: '1019109.2282500000000000',
      quoteVolume: 269497860.7586776,
      btcVolume: 3226.855828115803,
      usdVolume: 177988659.73005295,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '271.1400000000000000',
      high: '278.5800000000000000',
      low: '265.9000000000000000',
      close: '276.7800000000000000',
      volume: '759685.2319600000000000',
      quoteVolume: 206313142.37866887,
      btcVolume: 1707.670992326543,
      usdVolume: 98677446.61978804,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '276.7700000000000000',
      high: '277.4700000000000000',
      low: '264.7000000000000000',
      close: '268.8800000000000000',
      volume: '656401.3022700000000000',
      quoteVolume: 177319409.03695217,
      btcVolume: 1903.0205383441184,
      usdVolume: 108037070.23640811,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '268.8300000000000000',
      high: '299.7200000000000000',
      low: '268.4400000000000000',
      close: '294.8700000000000000',
      volume: '1427253.5714300000000000',
      quoteVolume: 406345333.7440447,
      btcVolume: 6780.1405280927665,
      usdVolume: 392154426.84161633,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '294.7300000000000000',
      high: '327.6800000000000000',
      low: '270.0000000000000000',
      close: '305.2300000000000000',
      volume: '3928402.9769700000000000',
      quoteVolume: 1192658666.056111,
      btcVolume: 17129.631266402546,
      usdVolume: 949745423.8557243,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '305.2400000000000000',
      high: '360.0000000000000000',
      low: '300.5200000000000000',
      close: '356.0400000000000000',
      volume: '3390309.8850700000000000',
      quoteVolume: 1149588604.2960927,
      btcVolume: 19086.40330757933,
      usdVolume: 1061797763.2331136,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '356.0300000000000000',
      high: '364.9300000000000000',
      low: '318.0000000000000000',
      close: '354.6700000000000000',
      volume: '2657432.8323600000000000',
      quoteVolume: 908738665.4650412,
      btcVolume: 15602.374312501974,
      usdVolume: 887300260.0806935,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '354.5200000000000000',
      high: '374.5000000000000000',
      low: '333.3300000000000000',
      close: '344.8900000000000000',
      volume: '2822658.7155800000000000',
      quoteVolume: 1005641007.73384,
      btcVolume: 17158.120265906025,
      usdVolume: 969197721.0813937,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '344.8900000000000000',
      high: '356.6800000000000000',
      low: '334.0000000000000000',
      close: '347.1000000000000000',
      volume: '1326815.3766000000000000',
      quoteVolume: 458272514.1193381,
      btcVolume: 7737.5166810146,
      usdVolume: 452069650.3441574,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '347.1700000000000000',
      high: '389.0000000000000000',
      low: '333.5100000000000000',
      close: '387.8000000000000000',
      volume: '2292100.0585200000000000',
      quoteVolume: 824990298.5702738,
      btcVolume: 14024.145540287725,
      usdVolume: 811254222.256117,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '387.7700000000000000',
      high: '413.4900000000000000',
      low: '333.6800000000000000',
      close: '358.8900000000000000',
      volume: '3692639.9933200000000000',
      quoteVolume: 1422149685.4017851,
      btcVolume: 16986.416600752273,
      usdVolume: 971531402.2277263,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '358.9300000000000000',
      high: '379.7200000000000000',
      low: '345.0000000000000000',
      close: '377.6000000000000000',
      volume: '1933301.8959500000000000',
      quoteVolume: 696994582.879416,
      btcVolume: 9658.930490487104,
      usdVolume: 538638876.0129843,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '377.8000000000000000',
      high: '394.3700000000000000',
      low: '301.0000000000000000',
      close: '301.1600000000000000',
      volume: '2535240.4789300000000000',
      quoteVolume: 908891543.0191455,
      btcVolume: 16147.06658592599,
      usdVolume: 886854180.1405613,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '310.4000000000000000',
      high: '332.7300000000000000',
      low: '291.0000000000000000',
      close: '317.9500000000000000',
      volume: '2633768.7978000000000000',
      quoteVolume: 831763903.622356,
      btcVolume: 16126.135514947295,
      usdVolume: 800855571.6677618,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '317.9500000000000000',
      high: '338.8500000000000000',
      low: '307.9600000000000000',
      close: '326.2300000000000000',
      volume: '1480983.4927200000000000',
      quoteVolume: 478735235.15381885,
      btcVolume: 9290.562772,
      usdVolume: 466090574.20245117,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '326.2400000000000000',
      high: '332.0000000000000000',
      low: '296.1700000000000000',
      close: '300.3000000000000000',
      volume: '1486174.6400000000000000',
      quoteVolume: 464488652.98064655,
      btcVolume: 9354.642033526949,
      usdVolume: 455189162.857259,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '300.2200000000000000',
      high: '323.9500000000000000',
      low: '279.6600000000000000',
      close: '295.8900000000000000',
      volume: '1689775.5445200000000000',
      quoteVolume: 509125100.09983116,
      btcVolume: 10506.571843399115,
      usdVolume: 493364301.71537817,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '295.8900000000000000',
      high: '296.3400000000000000',
      low: '261.2700000000000000',
      close: '281.5300000000000000',
      volume: '2058950.0621700000000000',
      quoteVolume: 576011740.6532687,
      btcVolume: 12647.507440176665,
      usdVolume: 556665988.3596952,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '281.3800000000000000',
      high: '317.9700000000000000',
      low: '278.3200000000000000',
      close: '293.9800000000000000',
      volume: '2558745.8496900000000000',
      quoteVolume: 770908333.9244474,
      btcVolume: 17076.297568639577,
      usdVolume: 752161422.2082568,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '293.9800000000000000',
      high: '300.3100000000000000',
      low: '147.0000000000000000',
      close: '185.9300000000000000',
      volume: '4429987.0992500000000000',
      quoteVolume: 1024051125.9764041,
      btcVolume: 21425.247480595644,
      usdVolume: 837275028.9459155,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '186.3100000000000000',
      high: '225.0000000000000000',
      low: '166.4500000000000000',
      close: '210.7400000000000000',
      volume: '4267102.3515400000000000',
      quoteVolume: 857136804.6603408,
      btcVolume: 17609.38450938026,
      usdVolume: 690358545.9245719,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '210.6600000000000000',
      high: '219.1200000000000000',
      low: '156.5600000000000000',
      close: '179.9700000000000000',
      volume: '2499829.1189300000000000',
      quoteVolume: 477038651.68571556,
      btcVolume: 9570.554815255731,
      usdVolume: 369647877.63828075,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '179.9700000000000000',
      high: '184.4400000000000000',
      low: '161.6100000000000000',
      close: '169.3200000000000000',
      volume: '1975608.6877700000000000',
      quoteVolume: 344670437.0064659,
      btcVolume: 6063.268649807608,
      usdVolume: 224771211.51457024,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '169.3000000000000000',
      high: '176.2600000000000000',
      low: '118.0300000000000000',
      close: '142.3900000000000000',
      volume: '3854006.1240800000000000',
      quoteVolume: 548343321.08721,
      btcVolume: 15597.115173980857,
      usdVolume: 534078487.19652426,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '142.5200000000000000',
      high: '185.3700000000000000',
      low: '139.5400000000000000',
      close: '185.3200000000000000',
      volume: '2471798.9177700000000000',
      quoteVolume: 401352119.8284848,
      btcVolume: 10534.620490455787,
      usdVolume: 390721604.9641617,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '185.3200000000000000',
      high: '194.6100000000000000',
      low: '167.1500000000000000',
      close: '183.7600000000000000',
      volume: '1974384.4346300000000000',
      quoteVolume: 352461818.3773789,
      btcVolume: 9048.857573695626,
      usdVolume: 343883497.5524442,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '183.8900000000000000',
      high: '204.1300000000000000',
      low: '179.4200000000000000',
      close: '198.9100000000000000',
      volume: '1522242.9800100000000000',
      quoteVolume: 295744722.487022,
      btcVolume: 7397.801239257871,
      usdVolume: 290508656.1029971,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '198.9200000000000000',
      high: '209.0000000000000000',
      low: '180.3900000000000000',
      close: '194.3200000000000000',
      volume: '1457775.3844800000000000',
      quoteVolume: 283796099.3329705,
      btcVolume: 7184.054871626712,
      usdVolume: 278865293.7616737,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '194.3100000000000000',
      high: '198.8800000000000000',
      low: '165.5000000000000000',
      close: '177.3300000000000000',
      volume: '2118247.1385100000000000',
      quoteVolume: 378817824.8082162,
      btcVolume: 10181.549955274471,
      usdVolume: 371492579.2034223,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '177.3000000000000000',
      high: '188.6900000000000000',
      low: '157.6500000000000000',
      close: '164.0100000000000000',
      volume: '1553245.0097100000000000',
      quoteVolume: 267294263.0746701,
      btcVolume: 7489.570968288885,
      usdVolume: 263585916.44740584,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '164.0400000000000000',
      high: '181.1000000000000000',
      low: '155.1100000000000000',
      close: '170.5100000000000000',
      volume: '1118503.2655100000000000',
      quoteVolume: 190582769.66552165,
      btcVolume: 5376.479791676544,
      usdVolume: 189717014.9300997,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '170.4800000000000000',
      high: '188.5000000000000000',
      low: '163.5000000000000000',
      close: '187.9700000000000000',
      volume: '1239310.5303800000000000',
      quoteVolume: 219656209.67978868,
      btcVolume: 6050.641649360358,
      usdVolume: 218836203.75448558,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '188.0100000000000000',
      high: '193.1600000000000000',
      low: '175.8200000000000000',
      close: '182.9900000000000000',
      volume: '1017682.1460000000000000',
      quoteVolume: 185830461.32079786,
      btcVolume: 4992.905208779654,
      usdVolume: 183136314.50446558,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '183.0500000000000000',
      high: '191.3400000000000000',
      low: '178.6300000000000000',
      close: '187.1400000000000000',
      volume: '919350.2437500000000000',
      quoteVolume: 170450027.89501953,
      btcVolume: 4545.1303175238545,
      usdVolume: 169822883.12995762,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '187.1800000000000000',
      high: '197.7800000000000000',
      low: '181.9500000000000000',
      close: '193.5000000000000000',
      volume: '1068163.8122400000000000',
      quoteVolume: 203871823.75115305,
      btcVolume: 5274.935447853314,
      usdVolume: 203567782.85967907,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '193.5200000000000000',
      high: '193.8100000000000000',
      low: '168.9500000000000000',
      close: '178.4400000000000000',
      volume: '1083395.0124300000000000',
      quoteVolume: 192472231.32231694,
      btcVolume: 5072.536802753547,
      usdVolume: 187806213.84734955,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '178.4600000000000000',
      high: '185.4900000000000000',
      low: '167.3200000000000000',
      close: '172.9400000000000000',
      volume: '845338.3479100000000000',
      quoteVolume: 148913591.34313303,
      btcVolume: 4047.721342287558,
      usdVolume: 147372091.39759547,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '172.9500000000000000',
      high: '179.9600000000000000',
      low: '172.0200000000000000',
      close: '176.4100000000000000',
      volume: '676410.3521800000000000',
      quoteVolume: 119363989.89739811,
      btcVolume: 3313.0261015985343,
      usdVolume: 119122275.72225323,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '176.4200000000000000',
      high: '182.2200000000000000',
      low: '160.2500000000000000',
      close: '161.9500000000000000',
      volume: '1035822.8250900000000000',
      quoteVolume: 180434186.55291733,
      btcVolume: 5056.415096494469,
      usdVolume: 179870212.29867515,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '161.9200000000000000',
      high: '164.4800000000000000',
      low: '144.8900000000000000',
      close: '160.6300000000000000',
      volume: '1417289.8764700000000000',
      quoteVolume: 222062589.14860615,
      btcVolume: 6696.45943399783,
      usdVolume: 219130900.61702827,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '160.6400000000000000',
      high: '173.3000000000000000',
      low: '152.3100000000000000',
      close: '172.5600000000000000',
      volume: '1139739.3605400000000000',
      quoteVolume: 186552484.183113,
      btcVolume: 5247.567629145548,
      usdVolume: 184026648.27611592,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '172.5400000000000000',
      high: '174.6100000000000000',
      low: '162.1200000000000000',
      close: '168.1600000000000000',
      volume: '877869.9250200000000000',
      quoteVolume: 147862248.68551347,
      btcVolume: 3977.149088401281,
      usdVolume: 147278462.99204102,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '168.1800000000000000',
      high: '173.7800000000000000',
      low: '160.5500000000000000',
      close: '162.8300000000000000',
      volume: '928611.5444600000000000',
      quoteVolume: 156280950.68599966,
      btcVolume: 4223.884717970092,
      usdVolume: 156041583.2841295,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '162.8400000000000000',
      high: '164.3000000000000000',
      low: '154.1400000000000000',
      close: '161.8000000000000000',
      volume: '653092.9881500000000000',
      quoteVolume: 103889826.80616859,
      btcVolume: 2894.8574142745842,
      usdVolume: 103359782.14313756,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '161.8100000000000000',
      high: '171.5900000000000000',
      low: '156.4200000000000000',
      close: '170.8500000000000000',
      volume: '573493.9078300000000000',
      quoteVolume: 93618007.16348189,
      btcVolume: 2549.4662560893084,
      usdVolume: 93402814.7181441,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '170.8500000000000000',
      high: '179.9900000000000000',
      low: '166.3400000000000000',
      close: '179.8900000000000000',
      volume: '802051.9564500000000000',
      quoteVolume: 138132150.6621468,
      btcVolume: 3462.3042173691624,
      usdVolume: 137992367.33837593,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '179.8800000000000000',
      high: '181.2000000000000000',
      low: '172.1500000000000000',
      close: '175.1500000000000000',
      volume: '654352.0530400000000000',
      quoteVolume: 115569055.5994115,
      btcVolume: 2868.206424184333,
      usdVolume: 115393253.99403785,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '175.1600000000000000',
      high: '177.3100000000000000',
      low: '164.2300000000000000',
      close: '166.5700000000000000',
      volume: '761479.4526300000000000',
      quoteVolume: 129526004.72512966,
      btcVolume: 3269.23856819484,
      usdVolume: 128729009.70918988,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '166.5600000000000000',
      high: '173.4000000000000000',
      low: '162.8100000000000000',
      close: '167.0800000000000000',
      volume: '559434.6236700000000000',
      quoteVolume: 94143650.72569731,
      btcVolume: 2440.0515935312997,
      usdVolume: 93977575.46471305,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '167.1300000000000000',
      high: '167.4300000000000000',
      low: '151.2000000000000000',
      close: '156.3400000000000000',
      volume: '800699.3038400000000000',
      quoteVolume: 127526013.18198103,
      btcVolume: 3451.3788942966594,
      usdVolume: 126795373.6731447,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '156.3300000000000000',
      high: '160.8800000000000000',
      low: '152.3100000000000000',
      close: '152.4900000000000000',
      volume: '522793.6393800000000000',
      quoteVolume: 82002075.66598175,
      btcVolume: 2293.7654961253556,
      usdVolume: 81905459.6444485,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '152.5100000000000000',
      high: '156.1800000000000000',
      low: '143.7500000000000000',
      close: '154.8800000000000000',
      volume: '611126.9176600000000000',
      quoteVolume: 91873187.05818173,
      btcVolume: 2616.173092821781,
      usdVolume: 91098177.96242698,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '154.8700000000000000',
      high: '155.5000000000000000',
      low: '123.9100000000000000',
      close: '124.4600000000000000',
      volume: '1491214.5233800000000000',
      quoteVolume: 203016501.85429946,
      btcVolume: 6075.534275582749,
      usdVolume: 199064002.10991165,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '124.3800000000000000',
      high: '133.0300000000000000',
      low: '104.9200000000000000',
      close: '119.6400000000000000',
      volume: '2255194.7519200000000000',
      quoteVolume: 266658628.66409847,
      btcVolume: 8351.340529707115,
      usdVolume: 262285946.07146153,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '119.6900000000000000',
      high: '133.7500000000000000',
      low: '115.4900000000000000',
      close: '129.1000000000000000',
      volume: '1018662.2307000000000000',
      quoteVolume: 129893644.46478507,
      btcVolume: 3806.186447654474,
      usdVolume: 128367468.01437268,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '129.1300000000000000',
      high: '137.3600000000000000',
      low: '123.7100000000000000',
      close: '134.5800000000000000',
      volume: '650968.5008600000000000',
      quoteVolume: 86002587.93949006,
      btcVolume: 2540.35834770411,
      usdVolume: 85601845.83265461,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '134.5900000000000000',
      high: '138.4500000000000000',
      low: '124.3100000000000000',
      close: '125.0000000000000000',
      volume: '764515.3389200000000000',
      quoteVolume: 99682735.3520955,
      btcVolume: 2989.7157760446407,
      usdVolume: 99437255.44342713,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '125.0300000000000000',
      high: '129.4400000000000000',
      low: '118.6700000000000000',
      close: '126.8900000000000000',
      volume: '868428.5919600000000000',
      quoteVolume: 107074782.7018947,
      btcVolume: 3405.7791541849497,
      usdVolume: 106608690.08379035,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '126.8100000000000000',
      high: '132.6700000000000000',
      low: '124.6600000000000000',
      close: '132.6000000000000000',
      volume: '573388.2468800000000000',
      quoteVolume: 73420914.55001809,
      btcVolume: 2206.7295809874763,
      usdVolume: 73052077.51809245,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '132.5700000000000000',
      high: '139.5500000000000000',
      low: '129.8900000000000000',
      close: '137.6900000000000000',
      volume: '657962.2346700000000000',
      quoteVolume: 88324620.00472222,
      btcVolume: 2556.119863531481,
      usdVolume: 88194403.87278609,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '137.6800000000000000',
      high: '149.1800000000000000',
      low: '137.2400000000000000',
      close: '144.2000000000000000',
      volume: '840281.7752200000000000',
      quoteVolume: 120878119.45537868,
      btcVolume: 3398.725464122438,
      usdVolume: 120738719.24876784,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '144.2300000000000000',
      high: '146.9400000000000000',
      low: '136.0100000000000000',
      close: '144.2600000000000000',
      volume: '601927.1802600000000000',
      quoteVolume: 84998636.27030478,
      btcVolume: 2433.7350642691163,
      usdVolume: 84897826.1464357,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '144.3100000000000000',
      high: '144.3100000000000000',
      low: '133.9900000000000000',
      close: '137.0200000000000000',
      volume: '539535.2997000000000000',
      quoteVolume: 74099823.13621905,
      btcVolume: 2199.154275297908,
      usdVolume: 73990525.94229446,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '137.0100000000000000',
      high: '138.9700000000000000',
      low: '130.6000000000000000',
      close: '136.8500000000000000',
      volume: '464289.9855400000000000',
      quoteVolume: 62070122.90920927,
      btcVolume: 1866.2283049451146,
      usdVolume: 62049635.439033374,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '136.8700000000000000',
      high: '141.3700000000000000',
      low: '134.5700000000000000',
      close: '140.1700000000000000',
      volume: '342781.9026900000000000',
      quoteVolume: 47569780.320621595,
      btcVolume: 1377.1630726778033,
      usdVolume: 47351874.70020899,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '140.1900000000000000',
      high: '148.1000000000000000',
      low: '136.8800000000000000',
      close: '144.9500000000000000',
      volume: '435307.8254500000000000',
      quoteVolume: 62602668.35071889,
      btcVolume: 1770.4592503135034,
      usdVolume: 62526340.18334017,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '144.9600000000000000',
      high: '145.0300000000000000',
      low: '133.8400000000000000',
      close: '137.7100000000000000',
      volume: '561316.3421300000000000',
      quoteVolume: 78108514.57286538,
      btcVolume: 2273.043772946485,
      usdVolume: 77425208.18744867,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '137.7600000000000000',
      high: '142.7300000000000000',
      low: '135.6800000000000000',
      close: '138.8700000000000000',
      volume: '513385.4598500000000000',
      quoteVolume: 71206203.53100015,
      btcVolume: 2058.938771197183,
      usdVolume: 70372583.83128636,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '138.8600000000000000',
      high: '142.9300000000000000',
      low: '136.8700000000000000',
      close: '137.4100000000000000',
      volume: '515462.6606800000000000',
      quoteVolume: 72372106.19902879,
      btcVolume: 2095.11390021604,
      usdVolume: 72372106.19902879,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '137.3800000000000000',
      high: '137.7400000000000000',
      low: '127.7700000000000000',
      close: '132.1500000000000000',
      volume: '645442.5711200000000000',
      quoteVolume: 85207418.88870086,
      btcVolume: 2580.982544909361,
      usdVolume: 84861412.34308095,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '132.1100000000000000',
      high: '135.8500000000000000',
      low: '126.0000000000000000',
      close: '134.5800000000000000',
      volume: '527547.8216300000000000',
      quoteVolume: 69418988.6298179,
      btcVolume: 2095.969894767974,
      usdVolume: 69393787.63974923,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '134.5400000000000000',
      high: '136.8200000000000000',
      low: '130.3200000000000000',
      close: '133.9900000000000000',
      volume: '365844.8683200000000000',
      quoteVolume: 48672574.565501355,
      btcVolume: 1443.797183822238,
      usdVolume: 48670934.54957684,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '133.9900000000000000',
      high: '136.4800000000000000',
      low: '132.0800000000000000',
      close: '134.2800000000000000',
      volume: '324569.4102200000000000',
      quoteVolume: 43649181.09015877,
      btcVolume: 1290.2481974206867,
      usdVolume: 43648244.99860397,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '134.2800000000000000',
      high: '139.7500000000000000',
      low: '131.8200000000000000',
      close: '133.3800000000000000',
      volume: '670917.6235500000000000',
      quoteVolume: 91393565.62359004,
      btcVolume: 2712.82869674453,
      usdVolume: 91352372.01710989,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '133.3200000000000000',
      high: '134.6100000000000000',
      low: '129.3500000000000000',
      close: '131.4400000000000000',
      volume: '446421.4796000000000000',
      quoteVolume: 58969790.60242994,
      btcVolume: 1796.7553077819084,
      usdVolume: 58951683.42339532,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '131.4500000000000000',
      high: '132.4200000000000000',
      low: '123.5900000000000000',
      close: '131.0700000000000000',
      volume: '538549.4072300000000000',
      quoteVolume: 69104634.35945345,
      btcVolume: 2129.938412921626,
      usdVolume: 69047331.59467553,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '131.0600000000000000',
      high: '133.1300000000000000',
      low: '121.9300000000000000',
      close: '125.8600000000000000',
      volume: '494276.3957800000000000',
      quoteVolume: 62599065.68099787,
      btcVolume: 1949.375687069558,
      usdVolume: 62495906.468084864,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '125.8800000000000000',
      high: '127.8700000000000000',
      low: '120.3800000000000000',
      close: '120.6500000000000000',
      volume: '396793.6475500000000000',
      quoteVolume: 49187467.51789337,
      btcVolume: 1551.73880256109,
      usdVolume: 49187456.52809857,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '120.6600000000000000',
      high: '122.9200000000000000',
      low: '117.6800000000000000',
      close: '119.9500000000000000',
      volume: '412797.9346200000000000',
      quoteVolume: 49618380.247838296,
      btcVolume: 1572.2543394713864,
      usdVolume: 49564013.24779822,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '120.0000000000000000',
      high: '124.0500000000000000',
      low: '116.2300000000000000',
      close: '119.1700000000000000',
      volume: '430985.2145700000000000',
      quoteVolume: 51789227.20684465,
      btcVolume: 1633.9578324717402,
      usdVolume: 51787309.337946646,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '119.1600000000000000',
      high: '120.7500000000000000',
      low: '111.8200000000000000',
      close: '113.1300000000000000',
      volume: '493082.9626600000000000',
      quoteVolume: 56960133.80445162,
      btcVolume: 1829.8567510212017,
      usdVolume: 56942282.4436929,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '113.1400000000000000',
      high: '115.0900000000000000',
      low: '103.8300000000000000',
      close: '107.2900000000000000',
      volume: '765414.9566800000000000',
      quoteVolume: 82409699.71007985,
      btcVolume: 2758.298975959001,
      usdVolume: 82377481.65606456,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '107.3100000000000000',
      high: '119.2400000000000000',
      low: '105.0500000000000000',
      close: '117.7400000000000000',
      volume: '682039.4728300000000000',
      quoteVolume: 77598308.8381928,
      btcVolume: 2473.82772359749,
      usdVolume: 77368480.01030874,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '117.7700000000000000',
      high: '122.0000000000000000',
      low: '115.6100000000000000',
      close: '120.6700000000000000',
      volume: '464759.4714600000000000',
      quoteVolume: 55142102.74740585,
      btcVolume: 1710.182490463156,
      usdVolume: 54964505.0582159,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '120.6900000000000000',
      high: '122.1300000000000000',
      low: '117.0200000000000000',
      close: '119.4200000000000000',
      volume: '318529.7456500000000000',
      quoteVolume: 38201094.05437847,
      btcVolume: 1177.7807010659724,
      usdVolume: 38201094.05437847,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
  atom: {
    id: 'atom',
    name: 'ATOM',
    symbol: 'ATOM',
    image: 'assets/images/coin-logo/ATOM.png',
    activity24h: 421323456,
    availableAmount: 208.232323,
    usdxConversion: 2,
    allTimeValue: 55943812390,
    allTimeAsset: 542321,
    candleData: [
      {
      open: '0.5355000000000000',
      high: '0.5355000000000000',
      low: '0.4668000000000000',
      close: '0.4895000000000000',
      volume: '1343660.3100000000000000',
      quoteVolume: 665013.644449,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '0.4895000000000000',
      high: '0.4953000000000000',
      low: '0.4639000000000000',
      close: '0.4756000000000000',
      volume: '1411725.3500000000000000',
      quoteVolume: 673447.208597,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '0.4762000000000000',
      high: '0.5112000000000000',
      low: '0.4601000000000000',
      close: '0.4894000000000000',
      volume: '984417.5900000000000000',
      quoteVolume: 474999.286242,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '0.4908000000000000',
      high: '0.5000000000000000',
      low: '0.4673000000000000',
      close: '0.4831000000000000',
      volume: '735443.2000000000000000',
      quoteVolume: 354090.041046,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '0.4803000000000000',
      high: '0.4920000000000000',
      low: '0.4590000000000000',
      close: '0.4834000000000000',
      volume: '677499.5600000000000000',
      quoteVolume: 323333.553838,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '0.4839000000000000',
      high: '0.5198000000000000',
      low: '0.4650000000000000',
      close: '0.4972000000000000',
      volume: '897787.8400000000000000',
      quoteVolume: 446086.78762,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '0.4960000000000000',
      high: '0.5656000000000000',
      low: '0.4956000000000000',
      close: '0.5344000000000000',
      volume: '1784953.5000000000000000',
      quoteVolume: 955513.881021,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '0.5342000000000000',
      high: '0.5475000000000000',
      low: '0.4901000000000000',
      close: '0.5046000000000000',
      volume: '1827069.3900000000000000',
      quoteVolume: 940352.297759,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '0.5046000000000000',
      high: '0.5065000000000000',
      low: '0.4518000000000000',
      close: '0.5017000000000000',
      volume: '1529219.5300000000000000',
      quoteVolume: 745639.12774,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '0.5031000000000000',
      high: '0.5157000000000000',
      low: '0.4712000000000000',
      close: '0.4739000000000000',
      volume: '1112869.0800000000000000',
      quoteVolume: 544844.844073,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '0.4736000000000000',
      high: '0.4884000000000000',
      low: '0.4624000000000000',
      close: '0.4661000000000000',
      volume: '865653.8900000000000000',
      quoteVolume: 410185.821637,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '0.4661000000000000',
      high: '0.4666000000000000',
      low: '0.4229000000000000',
      close: '0.4303000000000000',
      volume: '2278919.6500000000000000',
      quoteVolume: 1004304.902092,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '0.4310000000000000',
      high: '0.4313000000000000',
      low: '0.3504000000000000',
      close: '0.4116000000000000',
      volume: '4006211.8500000000000000',
      quoteVolume: 1540777.018846,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '0.4104000000000000',
      high: '0.4638000000000000',
      low: '0.4061000000000000',
      close: '0.4505000000000000',
      volume: '5190825.3700000000000000',
      quoteVolume: 2252399.736119,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '0.4505000000000000',
      high: '0.4514000000000000',
      low: '0.4130000000000000',
      close: '0.4249000000000000',
      volume: '2092369.5900000000000000',
      quoteVolume: 897815.126911,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '0.4250000000000000',
      high: '0.4410000000000000',
      low: '0.3811000000000000',
      close: '0.3832000000000000',
      volume: '2029033.6100000000000000',
      quoteVolume: 826552.030246,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '0.3850000000000000',
      high: '0.4300000000000000',
      low: '0.3800000000000000',
      close: '0.4147000000000000',
      volume: '1899232.5600000000000000',
      quoteVolume: 768342.467271,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '0.4163000000000000',
      high: '0.4330000000000000',
      low: '0.3985000000000000',
      close: '0.4136000000000000',
      volume: '821033.4800000000000000',
      quoteVolume: 340840.331218,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '0.4170000000000000',
      high: '0.4170000000000000',
      low: '0.3860000000000000',
      close: '0.3963000000000000',
      volume: '1065170.5600000000000000',
      quoteVolume: 424303.971749,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '0.3959000000000000',
      high: '0.4125000000000000',
      low: '0.3867000000000000',
      close: '0.3943000000000000',
      volume: '1449384.9600000000000000',
      quoteVolume: 579009.583139,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '0.3926000000000000',
      high: '0.4327000000000000',
      low: '0.3902000000000000',
      close: '0.4224000000000000',
      volume: '1299107.0300000000000000',
      quoteVolume: 532341.103934,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '0.4224000000000000',
      high: '0.4236000000000000',
      low: '0.3915000000000000',
      close: '0.4000000000000000',
      volume: '1368546.2100000000000000',
      quoteVolume: 552819.621243,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '0.4000000000000000',
      high: '0.4065000000000000',
      low: '0.3913000000000000',
      close: '0.4021000000000000',
      volume: '498842.3600000000000000',
      quoteVolume: 199193.78253,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '0.4021000000000000',
      high: '0.4500000000000000',
      low: '0.3985000000000000',
      close: '0.4383000000000000',
      volume: '2015969.5800000000000000',
      quoteVolume: 859693.298603,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '0.4371000000000000',
      high: '0.4844000000000000',
      low: '0.4310000000000000',
      close: '0.4612000000000000',
      volume: '1384815.9500000000000000',
      quoteVolume: 637171.045365,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '0.4648000000000000',
      high: '0.4800000000000000',
      low: '0.4325000000000000',
      close: '0.4499000000000000',
      volume: '2000850.5500000000000000',
      quoteVolume: 913391.61962,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '0.4495000000000000',
      high: '0.4515000000000000',
      low: '0.4200000000000000',
      close: '0.4289000000000000',
      volume: '1580197.3900000000000000',
      quoteVolume: 685555.872631,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '0.4277000000000000',
      high: '0.4374000000000000',
      low: '0.4176000000000000',
      close: '0.4277000000000000',
      volume: '941303.5800000000000000',
      quoteVolume: 400668.760404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '0.4277000000000000',
      high: '0.4454000000000000',
      low: '0.4183000000000000',
      close: '0.4411000000000000',
      volume: '935377.3800000000000000',
      quoteVolume: 400576.99087,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '0.4422000000000000',
      high: '0.4442000000000000',
      low: '0.4203000000000000',
      close: '0.4259000000000000',
      volume: '942053.4400000000000000',
      quoteVolume: 407685.941353,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '0.4235000000000000',
      high: '0.4500000000000000',
      low: '0.4222000000000000',
      close: '0.4342000000000000',
      volume: '612251.8000000000000000',
      quoteVolume: 264659.849465,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '0.4366000000000000',
      high: '0.4464000000000000',
      low: '0.4251000000000000',
      close: '0.4398000000000000',
      volume: '465491.5800000000000000',
      quoteVolume: 203941.272106,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '0.4386000000000000',
      high: '0.4797000000000000',
      low: '0.4317000000000000',
      close: '0.4604000000000000',
      volume: '1616509.7200000000000000',
      quoteVolume: 740396.471002,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '0.4615000000000000',
      high: '0.4823000000000000',
      low: '0.4534000000000000',
      close: '0.4710000000000000',
      volume: '1430254.7800000000000000',
      quoteVolume: 671573.658591,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '0.4710000000000000',
      high: '0.4789000000000000',
      low: '0.4520000000000000',
      close: '0.4598000000000000',
      volume: '1548002.4400000000000000',
      quoteVolume: 724054.883013,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '0.4548000000000000',
      high: '0.4748000000000000',
      low: '0.4432000000000000',
      close: '0.4618000000000000',
      volume: '1358786.2800000000000000',
      quoteVolume: 625130.767047,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '0.4617000000000000',
      high: '0.4750000000000000',
      low: '0.4380000000000000',
      close: '0.4438000000000000',
      volume: '1443214.9800000000000000',
      quoteVolume: 655875.790716,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '0.4438000000000000',
      high: '0.4650000000000000',
      low: '0.4400000000000000',
      close: '0.4479000000000000',
      volume: '700523.7700000000000000',
      quoteVolume: 313850.863647,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '0.4480000000000000',
      high: '0.4640000000000000',
      low: '0.4437000000000000',
      close: '0.4585000000000000',
      volume: '925529.6400000000000000',
      quoteVolume: 419693.727298,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '0.4592000000000000',
      high: '0.4644000000000000',
      low: '0.3975000000000000',
      close: '0.4050000000000000',
      volume: '2154193.2500000000000000',
      quoteVolume: 917345.275562,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '0.4047000000000000',
      high: '0.4749000000000000',
      low: '0.4007000000000000',
      close: '0.4723000000000000',
      volume: '1785356.2100000000000000',
      quoteVolume: 783690.750459,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '0.4722000000000000',
      high: '0.4740000000000000',
      low: '0.4300000000000000',
      close: '0.4341000000000000',
      volume: '1775526.6700000000000000',
      quoteVolume: 796546.586808,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '0.4335000000000000',
      high: '0.4412000000000000',
      low: '0.4145000000000000',
      close: '0.4274000000000000',
      volume: '854212.4500000000000000',
      quoteVolume: 365787.418355,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '0.4274000000000000',
      high: '0.4500000000000000',
      low: '0.4166000000000000',
      close: '0.4244000000000000',
      volume: '1059968.1300000000000000',
      quoteVolume: 455311.559954,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '0.4236000000000000',
      high: '0.4386000000000000',
      low: '0.4203000000000000',
      close: '0.4285000000000000',
      volume: '560690.8100000000000000',
      quoteVolume: 239706.048076,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '0.4286000000000000',
      high: '0.4372000000000000',
      low: '0.4173000000000000',
      close: '0.4221000000000000',
      volume: '331570.0000000000000000',
      quoteVolume: 140599.974044,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '0.4232000000000000',
      high: '0.4292000000000000',
      low: '0.4177000000000000',
      close: '0.4205000000000000',
      volume: '225429.9500000000000000',
      quoteVolume: 95660.216465,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '0.4203000000000000',
      high: '0.4255000000000000',
      low: '0.3929000000000000',
      close: '0.4036000000000000',
      volume: '946382.1900000000000000',
      quoteVolume: 387502.948292,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '0.4029000000000000',
      high: '0.4343000000000000',
      low: '0.4023000000000000',
      close: '0.4291000000000000',
      volume: '1686179.9000000000000000',
      quoteVolume: 703630.897735,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '0.4291000000000000',
      high: '0.4385000000000000',
      low: '0.4186000000000000',
      close: '0.4296000000000000',
      volume: '716328.1500000000000000',
      quoteVolume: 305884.809425,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '0.4296000000000000',
      high: '0.4766000000000000',
      low: '0.4256000000000000',
      close: '0.4521000000000000',
      volume: '1498764.0300000000000000',
      quoteVolume: 677393.543142,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '0.4521000000000000',
      high: '0.5107000000000000',
      low: '0.4519000000000000',
      close: '0.4611000000000000',
      volume: '3248280.8300000000000000',
      quoteVolume: 1570069.711841,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '0.4611000000000000',
      high: '0.4824000000000000',
      low: '0.4524000000000000',
      close: '0.4746000000000000',
      volume: '1381686.4300000000000000',
      quoteVolume: 643096.002798,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '0.4742000000000000',
      high: '0.5460000000000000',
      low: '0.4681000000000000',
      close: '0.4981000000000000',
      volume: '2887991.8600000000000000',
      quoteVolume: 1456511.395647,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '0.4982000000000000',
      high: '0.5181000000000000',
      low: '0.4382000000000000',
      close: '0.4629000000000000',
      volume: '2633054.2100000000000000',
      quoteVolume: 1262800.852306,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '0.4641000000000000',
      high: '0.5079000000000000',
      low: '0.4503000000000000',
      close: '0.4876000000000000',
      volume: '2105182.6700000000000000',
      quoteVolume: 1016416.428628,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '0.4872000000000000',
      high: '0.4880000000000000',
      low: '0.4671000000000000',
      close: '0.4805000000000000',
      volume: '1263356.6800000000000000',
      quoteVolume: 602730.177399,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '0.4814000000000000',
      high: '0.4994000000000000',
      low: '0.4722000000000000',
      close: '0.4871000000000000',
      volume: '1720792.4000000000000000',
      quoteVolume: 835254.863559,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '0.4858000000000000',
      high: '0.4960000000000000',
      low: '0.4831000000000000',
      close: '0.4858000000000000',
      volume: '577893.0600000000000000',
      quoteVolume: 281897.916414,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '0.4866000000000000',
      high: '0.4960000000000000',
      low: '0.4681000000000000',
      close: '0.4819000000000000',
      volume: '1768514.5100000000000000',
      quoteVolume: 850597.475821,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '0.4805000000000000',
      high: '0.4887000000000000',
      low: '0.4702000000000000',
      close: '0.4785000000000000',
      volume: '1352126.7300000000000000',
      quoteVolume: 645227.394195,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '0.4789000000000000',
      high: '0.4833000000000000',
      low: '0.4670000000000000',
      close: '0.4757000000000000',
      volume: '891826.0900000000000000',
      quoteVolume: 422057.338849,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '0.4760000000000000',
      high: '0.4800000000000000',
      low: '0.4648000000000000',
      close: '0.4770000000000000',
      volume: '530734.5900000000000000',
      quoteVolume: 250536.102196,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '0.4796000000000000',
      high: '0.4899000000000000',
      low: '0.4737000000000000',
      close: '0.4807000000000000',
      volume: '696146.2800000000000000',
      quoteVolume: 335078.549549,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '0.4818000000000000',
      high: '0.4842000000000000',
      low: '0.4342000000000000',
      close: '0.4548000000000000',
      volume: '1043991.8000000000000000',
      quoteVolume: 484257.113374,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '0.4551000000000000',
      high: '0.4655000000000000',
      low: '0.4470000000000000',
      close: '0.4646000000000000',
      volume: '863577.3300000000000000',
      quoteVolume: 392384.916353,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '0.4627000000000000',
      high: '0.4644000000000000',
      low: '0.4545000000000000',
      close: '0.4608000000000000',
      volume: '406749.6600000000000000',
      quoteVolume: 186648.714066,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '0.4606000000000000',
      high: '0.4621000000000000',
      low: '0.4485000000000000',
      close: '0.4531000000000000',
      volume: '634173.7500000000000000',
      quoteVolume: 287618.895525,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '0.4539000000000000',
      high: '0.4949000000000000',
      low: '0.4410000000000000',
      close: '0.4734000000000000',
      volume: '2441517.9900000000000000',
      quoteVolume: 1142857.563225,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '0.4729000000000000',
      high: '0.4850000000000000',
      low: '0.4480000000000000',
      close: '0.4605000000000000',
      volume: '1129722.2000000000000000',
      quoteVolume: 526180.143477,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '0.4603000000000000',
      high: '0.4997000000000000',
      low: '0.4556000000000000',
      close: '0.4946000000000000',
      volume: '1352736.3400000000000000',
      quoteVolume: 651471.143333,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '0.4967000000000000',
      high: '0.5196000000000000',
      low: '0.4846000000000000',
      close: '0.4992000000000000',
      volume: '1443876.8300000000000000',
      quoteVolume: 719069.643363,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '0.4984000000000000',
      high: '0.5042000000000000',
      low: '0.4887000000000000',
      close: '0.5008000000000000',
      volume: '877803.9200000000000000',
      quoteVolume: 434977.122532,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '0.5008000000000000',
      high: '0.5034000000000000',
      low: '0.4858000000000000',
      close: '0.4940000000000000',
      volume: '675007.1300000000000000',
      quoteVolume: 332877.149186,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '0.4938000000000000',
      high: '0.5410000000000000',
      low: '0.4868000000000000',
      close: '0.5084000000000000',
      volume: '2047013.3300000000000000',
      quoteVolume: 1057434.916151,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '0.5084000000000000',
      high: '0.5630000000000000',
      low: '0.5007000000000000',
      close: '0.5540000000000000',
      volume: '2288623.9600000000000000',
      quoteVolume: 1226137.818617,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '0.5533000000000000',
      high: '0.6000000000000000',
      low: '0.5326000000000000',
      close: '0.5811000000000000',
      volume: '4081948.8400000000000000',
      quoteVolume: 2328532.773552,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '0.5811000000000000',
      high: '0.6073000000000000',
      low: '0.5170000000000000',
      close: '0.5620000000000000',
      volume: '4435492.2100000000000000',
      quoteVolume: 2584274.57288,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '0.5588000000000000',
      high: '0.5839000000000000',
      low: '0.5195000000000000',
      close: '0.5396000000000000',
      volume: '2088035.7200000000000000',
      quoteVolume: 1155026.496367,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '0.5409000000000000',
      high: '0.5799000000000000',
      low: '0.5390000000000000',
      close: '0.5729000000000000',
      volume: '1039348.6800000000000000',
      quoteVolume: 579093.364827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '0.5735000000000000',
      high: '0.5900000000000000',
      low: '0.5557000000000000',
      close: '0.5691000000000000',
      volume: '1176149.9800000000000000',
      quoteVolume: 672544.531992,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '0.5677000000000000',
      high: '0.5918000000000000',
      low: '0.5655000000000000',
      close: '0.5773000000000000',
      volume: '956649.3600000000000000',
      quoteVolume: 552493.434727,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '0.5776000000000000',
      high: '0.5912000000000000',
      low: '0.5370000000000000',
      close: '0.5634000000000000',
      volume: '1559203.7500000000000000',
      quoteVolume: 875383.308889,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '0.5617000000000000',
      high: '0.5637000000000000',
      low: '0.5170000000000000',
      close: '0.5300000000000000',
      volume: '1284451.2500000000000000',
      quoteVolume: 682600.691592,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '0.5300000000000000',
      high: '0.5452000000000000',
      low: '0.5100000000000000',
      close: '0.5197000000000000',
      volume: '1259717.4200000000000000',
      quoteVolume: 662636.077743,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '0.5198000000000000',
      high: '0.5210000000000000',
      low: '0.4530000000000000',
      close: '0.4821000000000000',
      volume: '1891750.2700000000000000',
      quoteVolume: 920572.945172,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '0.4821000000000000',
      high: '0.5134000000000000',
      low: '0.4531000000000000',
      close: '0.4920000000000000',
      volume: '1247794.8300000000000000',
      quoteVolume: 609014.276187,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '0.4932000000000000',
      high: '0.5399000000000000',
      low: '0.4913000000000000',
      close: '0.5358000000000000',
      volume: '984745.9300000000000000',
      quoteVolume: 514382.609618,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '0.5366000000000000',
      high: '0.5449000000000000',
      low: '0.5073000000000000',
      close: '0.5225000000000000',
      volume: '825009.0900000000000000',
      quoteVolume: 431556.816813,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '0.5259000000000000',
      high: '0.5268000000000000',
      low: '0.4800000000000000',
      close: '0.4883000000000000',
      volume: '1617780.6900000000000000',
      quoteVolume: 812827.262155,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '0.4884000000000000',
      high: '0.5176000000000000',
      low: '0.4883000000000000',
      close: '0.4985000000000000',
      volume: '752244.2900000000000000',
      quoteVolume: 377882.383651,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '0.4998000000000000',
      high: '0.5046000000000000',
      low: '0.4880000000000000',
      close: '0.4998000000000000',
      volume: '625939.6200000000000000',
      quoteVolume: 310958.450695,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '0.4992000000000000',
      high: '0.5226000000000000',
      low: '0.4974000000000000',
      close: '0.5193000000000000',
      volume: '747995.9900000000000000',
      quoteVolume: 382966.982357,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '0.5187000000000000',
      high: '0.5390000000000000',
      low: '0.5083000000000000',
      close: '0.5095000000000000',
      volume: '857084.8000000000000000',
      quoteVolume: 447910.430574,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '0.5105000000000000',
      high: '0.5287000000000000',
      low: '0.5050000000000000',
      close: '0.5118000000000000',
      volume: '547954.6800000000000000',
      quoteVolume: 281962.973871,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '0.5117000000000000',
      high: '0.5139000000000000',
      low: '0.4752000000000000',
      close: '0.4963000000000000',
      volume: '755636.9500000000000000',
      quoteVolume: 371111.531726,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '0.4963000000000000',
      high: '0.5170000000000000',
      low: '0.4837000000000000',
      close: '0.4957000000000000',
      volume: '669329.7000000000000000',
      quoteVolume: 335305.551779,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '0.4920000000000000',
      high: '0.5040000000000000',
      low: '0.4756000000000000',
      close: '0.4756000000000000',
      volume: '798688.9900000000000000',
      quoteVolume: 391603.526803,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '0.4756000000000000',
      high: '0.4800000000000000',
      low: '0.4400000000000000',
      close: '0.4750000000000000',
      volume: '1341167.6500000000000000',
      quoteVolume: 614034.70833,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '0.4745000000000000',
      high: '0.4997000000000000',
      low: '0.4350000000000000',
      close: '0.4503000000000000',
      volume: '1289948.5700000000000000',
      quoteVolume: 607159.886379,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '0.4499000000000000',
      high: '0.4699000000000000',
      low: '0.4264000000000000',
      close: '0.4416000000000000',
      volume: '881943.9300000000000000',
      quoteVolume: 389402.998679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '0.4437000000000000',
      high: '0.4698000000000000',
      low: '0.4357000000000000',
      close: '0.4430000000000000',
      volume: '330467.6700000000000000',
      quoteVolume: 149494.592163,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '0.4448000000000000',
      high: '0.4600000000000000',
      low: '0.4403000000000000',
      close: '0.4510000000000000',
      volume: '429353.9200000000000000',
      quoteVolume: 194108.9041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '0.4510000000000000',
      high: '0.4546000000000000',
      low: '0.4240000000000000',
      close: '0.4338000000000000',
      volume: '394944.3300000000000000',
      quoteVolume: 173429.731609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '0.4334000000000000',
      high: '0.4771000000000000',
      low: '0.4320000000000000',
      close: '0.4727000000000000',
      volume: '610148.8000000000000000',
      quoteVolume: 281321.216962,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '0.4750000000000000',
      high: '0.4876000000000000',
      low: '0.4638000000000000',
      close: '0.4730000000000000',
      volume: '397476.9300000000000000',
      quoteVolume: 188524.095397,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '0.4745000000000000',
      high: '0.4757000000000000',
      low: '0.4600000000000000',
      close: '0.4630000000000000',
      volume: '228270.4000000000000000',
      quoteVolume: 106411.499404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '0.4643000000000000',
      high: '0.5223000000000000',
      low: '0.4607000000000000',
      close: '0.5110000000000000',
      volume: '1017233.7800000000000000',
      quoteVolume: 502255.676203,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '0.5118000000000000',
      high: '0.5151000000000000',
      low: '0.4783000000000000',
      close: '0.4964000000000000',
      volume: '640984.0600000000000000',
      quoteVolume: 318811.507836,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '0.4964000000000000',
      high: '0.5238000000000000',
      low: '0.4902000000000000',
      close: '0.5058000000000000',
      volume: '641774.3200000000000000',
      quoteVolume: 328895.388404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '0.5058000000000000',
      high: '0.5882000000000000',
      low: '0.4901000000000000',
      close: '0.5745000000000000',
      volume: '1593771.0200000000000000',
      quoteVolume: 868680.989601,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '0.5798000000000000',
      high: '0.6186000000000000',
      low: '0.5409000000000000',
      close: '0.5435000000000000',
      volume: '2267674.3000000000000000',
      quoteVolume: 1314846.891036,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '0.5433000000000000',
      high: '0.6157000000000000',
      low: '0.5212000000000000',
      close: '0.5940000000000000',
      volume: '1255624.9100000000000000',
      quoteVolume: 730122.02836,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '0.5940000000000000',
      high: '0.7040000000000000',
      low: '0.5873000000000000',
      close: '0.6403000000000000',
      volume: '3548028.1300000000000000',
      quoteVolume: 2284998.871122,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '0.6402000000000000',
      high: '0.7233000000000000',
      low: '0.6374000000000000',
      close: '0.6703000000000000',
      volume: '2987660.6900000000000000',
      quoteVolume: 2051444.51188,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '0.6731000000000000',
      high: '0.7484000000000000',
      low: '0.6517000000000000',
      close: '0.6752000000000000',
      volume: '3014329.8900000000000000',
      quoteVolume: 2076341.525905,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '0.6790000000000000',
      high: '0.6999000000000000',
      low: '0.6285000000000000',
      close: '0.6586000000000000',
      volume: '1760800.0800000000000000',
      quoteVolume: 1165285.921864,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '0.6607000000000000',
      high: '0.6872000000000000',
      low: '0.6336000000000000',
      close: '0.6474000000000000',
      volume: '1281864.7000000000000000',
      quoteVolume: 853179.428728,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '0.6474000000000000',
      high: '0.9565000000000000',
      low: '0.6332000000000000',
      close: '0.8280000000000000',
      volume: '7911014.0700000000000000',
      quoteVolume: 6371210.43235,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '0.8251000000000000',
      high: '0.8890000000000000',
      low: '0.7772000000000000',
      close: '0.8427000000000000',
      volume: '3798325.6400000000000000',
      quoteVolume: 3148626.361888,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '0.8427000000000000',
      high: '0.9389000000000000',
      low: '0.7551000000000000',
      close: '0.9280000000000000',
      volume: '4555532.1900000000000000',
      quoteVolume: 3883945.234255,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '0.9264000000000000',
      high: '0.9869000000000000',
      low: '0.8100000000000000',
      close: '0.8911000000000000',
      volume: '5725645.6900000000000000',
      quoteVolume: 5097003.16005,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '0.8950000000000000',
      high: '0.9592000000000000',
      low: '0.8457000000000000',
      close: '0.9359000000000000',
      volume: '2389369.0400000000000000',
      quoteVolume: 2179041.174403,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '0.9360000000000000',
      high: '1.0500000000000000',
      low: '0.9279000000000000',
      close: '0.9934000000000000',
      volume: '4785605.6200000000000000',
      quoteVolume: 4702849.494509,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '0.9927000000000000',
      high: '1.1430000000000000',
      low: '0.9460000000000000',
      close: '1.1170000000000000',
      volume: '2936467.3000000000000000',
      quoteVolume: 2982285.87569,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '1.1146000000000000',
      high: '1.2500000000000000',
      low: '1.0600000000000000',
      close: '1.1798000000000000',
      volume: '5046994.2000000000000000',
      quoteVolume: 5745623.487639,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '1.1855000000000000',
      high: '1.4740000000000000',
      low: '1.1301000000000000',
      close: '1.2205000000000000',
      volume: '14821357.0900000000000000',
      quoteVolume: 19619790.33193,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '1.2205000000000000',
      high: '1.3655000000000000',
      low: '1.1418000000000000',
      close: '1.3392000000000000',
      volume: '6874618.3900000000000000',
      quoteVolume: 8592874.224192,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '1.3393000000000000',
      high: '1.4347000000000000',
      low: '1.2216000000000000',
      close: '1.3314000000000000',
      volume: '5097940.0500000000000000',
      quoteVolume: 6795296.790503,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '1.3257000000000000',
      high: '1.3376000000000000',
      low: '1.1005000000000000',
      close: '1.1139000000000000',
      volume: '3990172.6100000000000000',
      quoteVolume: 4655614.840081993,
      btcVolume: 581.9913178767249,
      usdVolume: 4655614.840081993,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '1.1181000000000000',
      high: '1.1824000000000000',
      low: '1.0340000000000000',
      close: '1.0756000000000000',
      volume: '2787144.3800000000000000',
      quoteVolume: 3053065.8027050006,
      btcVolume: 377.16663921841183,
      usdVolume: 3053065.8027050006,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '1.0806000000000000',
      high: '1.2750000000000000',
      low: '1.0806000000000000',
      close: '1.2119000000000000',
      volume: '3879947.9900000000000000',
      quoteVolume: 4585345.262517948,
      btcVolume: 526.2433979570677,
      usdVolume: 4585345.262517948,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '1.2199000000000000',
      high: '1.3500000000000000',
      low: '1.1444000000000000',
      close: '1.2921000000000000',
      volume: '2591508.8200000000000000',
      quoteVolume: 3228837.802578005,
      btcVolume: 371.1273709190563,
      usdVolume: 3228837.802578005,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '1.2782000000000000',
      high: '1.2844000000000000',
      low: '1.1497000000000000',
      close: '1.1665000000000000',
      volume: '2450800.8100000000000000',
      quoteVolume: 2938928.7943600034,
      btcVolume: 340.2041830509092,
      usdVolume: 2938928.7943600034,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '1.1780000000000000',
      high: '1.2250000000000000',
      low: '0.9044000000000000',
      close: '1.0057000000000000',
      volume: '6305578.1300000000000000',
      quoteVolume: 6787576.3371680295,
      btcVolume: 791.9128840898691,
      usdVolume: 6787576.3371680295,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '1.0063000000000000',
      high: '1.0444000000000000',
      low: '0.9330000000000000',
      close: '1.0195000000000000',
      volume: '4936161.7800000000000000',
      quoteVolume: 4857535.299134998,
      btcVolume: 581.7438750463537,
      usdVolume: 4857535.299134998,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '1.0252000000000000',
      high: '1.0597000000000000',
      low: '0.9511000000000000',
      close: '0.9560000000000000',
      volume: '2558921.2100000000000000',
      quoteVolume: 2554370.774409004,
      btcVolume: 299.04123365519683,
      usdVolume: 2554370.774409004,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '0.9536000000000000',
      high: '1.0554000000000000',
      low: '0.9323000000000000',
      close: '1.0155000000000000',
      volume: '3448639.2900000000000000',
      quoteVolume: 3433793.2090730225,
      btcVolume: 396.5090133601953,
      usdVolume: 3433793.2090730225,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '1.0155000000000000',
      high: '1.0200000000000000',
      low: '0.8853000000000000',
      close: '0.9000000000000000',
      volume: '3218003.4000000000000000',
      quoteVolume: 3096993.8518149965,
      btcVolume: 365.97881939525035,
      usdVolume: 3096993.8518149965,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '0.8985000000000000',
      high: '0.9299000000000000',
      low: '0.8368000000000000',
      close: '0.8850000000000000',
      volume: '4563258.6100000000000000',
      quoteVolume: 4005371.5748359957,
      btcVolume: 509.9416978186965,
      usdVolume: 4005371.5748359957,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '0.8850000000000000',
      high: '1.0991000000000000',
      low: '0.8838000000000000',
      close: '1.0959000000000000',
      volume: '10134365.7300000000000000',
      quoteVolume: 10259929.50928907,
      btcVolume: 1323.3373278111412,
      usdVolume: 10259929.50928907,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '1.0960000000000000',
      high: '1.2700000000000000',
      low: '1.0556000000000000',
      close: '1.1098000000000000',
      volume: '11104062.4700000000000000',
      quoteVolume: 12707991.386383945,
      btcVolume: 1649.824982729021,
      usdVolume: 12707991.386383945,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '1.1099000000000000',
      high: '1.2589000000000000',
      low: '1.0710000000000000',
      close: '1.1753000000000000',
      volume: '6318630.4200000000000000',
      quoteVolume: 7452520.230448068,
      btcVolume: 944.3447342802139,
      usdVolume: 7452520.230448068,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '1.1747000000000000',
      high: '1.1991000000000000',
      low: '1.1165000000000000',
      close: '1.1326000000000000',
      volume: '2532914.5100000000000000',
      quoteVolume: 2922870.3576520067,
      btcVolume: 369.3243148377816,
      usdVolume: 2922870.3576520067,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '1.1376000000000000',
      high: '1.1486000000000000',
      low: '1.0085000000000000',
      close: '1.0595000000000000',
      volume: '3542850.8800000000000000',
      quoteVolume: 3804204.519311013,
      btcVolume: 491.5768447574139,
      usdVolume: 3804204.519311013,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '1.0595000000000000',
      high: '1.2104000000000000',
      low: '1.0301000000000000',
      close: '1.2060000000000000',
      volume: '3560912.7500000000000000',
      quoteVolume: 4027483.4204219743,
      btcVolume: 514.0312403954563,
      usdVolume: 4027483.4204219743,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '1.2043000000000000',
      high: '1.2070000000000000',
      low: '1.0876000000000000',
      close: '1.1243000000000000',
      volume: '3064771.3900000000000000',
      quoteVolume: 3504537.899613992,
      btcVolume: 445.75194109413064,
      usdVolume: 3504537.899613992,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '1.1241000000000000',
      high: '1.1643000000000000',
      low: '1.0875000000000000',
      close: '1.1448000000000000',
      volume: '2552167.9900000000000000',
      quoteVolume: 2871559.2307969993,
      btcVolume: 359.4040334628175,
      usdVolume: 2871559.2307969993,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '1.1448000000000000',
      high: '1.9700000000000000',
      low: '1.1126000000000000',
      close: '1.8680000000000000',
      volume: '34026255.5700000000000000',
      quoteVolume: 58291200.9484317,
      btcVolume: 7099.103367062914,
      usdVolume: 58291200.9484317,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '1.8680000000000000',
      high: '1.8936000000000000',
      low: '1.4900000000000000',
      close: '1.6395000000000000',
      volume: '33206570.6700000000000000',
      quoteVolume: 54533735.08588836,
      btcVolume: 6570.521290055457,
      usdVolume: 54533735.08588836,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '1.6364000000000000',
      high: '1.8065000000000000',
      low: '1.6022000000000000',
      close: '1.7179000000000000',
      volume: '10700181.1400000000000000',
      quoteVolume: 18387957.79867493,
      btcVolume: 2121.574515120722,
      usdVolume: 18387957.79867493,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '1.7180000000000000',
      high: '1.7924000000000000',
      low: '1.6000000000000000',
      close: '1.6621000000000000',
      volume: '8261300.1500000000000000',
      quoteVolume: 13964950.109855045,
      btcVolume: 1544.6562665703477,
      usdVolume: 13964950.109855045,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '1.6651000000000000',
      high: '2.0776000000000000',
      low: '1.6525000000000000',
      close: '1.9632000000000000',
      volume: '17062882.5500000000000000',
      quoteVolume: 32436293.282424346,
      btcVolume: 3512.1607543888294,
      usdVolume: 32436293.282424346,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '1.9631000000000000',
      high: '2.0185000000000000',
      low: '1.7600000000000000',
      close: '1.8194000000000000',
      volume: '13903321.3000000000000000',
      quoteVolume: 25882989.567272145,
      btcVolume: 2819.6184477064003,
      usdVolume: 25816336.76297314,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '1.8158000000000000',
      high: '1.9016000000000000',
      low: '1.7869000000000000',
      close: '1.8353000000000000',
      volume: '4857233.6000000000000000',
      quoteVolume: 8953905.75995399,
      btcVolume: 980.2564405252405,
      usdVolume: 8953905.75995399,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '1.8352000000000000',
      high: '1.8865000000000000',
      low: '1.6862000000000000',
      close: '1.7656000000000000',
      volume: '5058765.9500000000000000',
      quoteVolume: 8995915.657141935,
      btcVolume: 964.4591378098079,
      usdVolume: 8995915.657141935,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '1.7671000000000000',
      high: '1.7900000000000000',
      low: '1.6100000000000000',
      close: '1.6883000000000000',
      volume: '6929829.1600000000000000',
      quoteVolume: 11630088.984655974,
      btcVolume: 1192.1055771385234,
      usdVolume: 11630088.984655974,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '1.6895000000000000',
      high: '1.9470000000000000',
      low: '1.6827000000000000',
      close: '1.8428000000000000',
      volume: '8390022.4600000000000000',
      quoteVolume: 15123936.817318201,
      btcVolume: 1410.6256719829264,
      usdVolume: 15123936.817318201,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '1.8429000000000000',
      high: '1.9100000000000000',
      low: '1.7702000000000000',
      close: '1.8169000000000000',
      volume: '4593412.3100000000000000',
      quoteVolume: 8418104.00219802,
      btcVolume: 775.4769301470001,
      usdVolume: 8418104.00219802,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '1.8169000000000000',
      high: '1.8884000000000000',
      low: '1.7900000000000000',
      close: '1.8689000000000000',
      volume: '2384487.8200000000000000',
      quoteVolume: 4400730.711527992,
      btcVolume: 403.4557862186641,
      usdVolume: 4400730.711527992,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '1.8689000000000000',
      high: '2.4273000000000000',
      low: '1.8613000000000000',
      close: '2.1928000000000000',
      volume: '21998410.4800000000000000',
      quoteVolume: 47761526.31202764,
      btcVolume: 4201.741421999988,
      usdVolume: 47761526.31202764,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '2.1931000000000000',
      high: '2.5000000000000000',
      low: '2.0231000000000000',
      close: '2.2800000000000000',
      volume: '15800691.2200000000000000',
      quoteVolume: 35017061.17698764,
      btcVolume: 2716.258242985611,
      usdVolume: 35017061.17698764,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '2.2821000000000000',
      high: '2.5550000000000000',
      low: '2.1123000000000000',
      close: '2.2879000000000000',
      volume: '13867785.5900000000000000',
      quoteVolume: 32591424.308536824,
      btcVolume: 2692.462710930972,
      usdVolume: 32591424.308536824,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '2.2912000000000000',
      high: '3.0981000000000000',
      low: '2.2150000000000000',
      close: '3.0392000000000000',
      volume: '16190974.7700000000000000',
      quoteVolume: 43067890.31031614,
      btcVolume: 3571.5324626501774,
      usdVolume: 43067890.31031614,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '3.0391000000000000',
      high: '4.5826000000000000',
      low: '2.9499000000000000',
      close: '3.7125000000000000',
      volume: '55501686.3100000000000000',
      quoteVolume: 212256136.05785748,
      btcVolume: 17792.420778535194,
      usdVolume: 212256136.05785748,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '3.7051000000000000',
      high: '4.2200000000000000',
      low: '3.2300000000000000',
      close: '3.3869000000000000',
      volume: '25190926.7600000000000000',
      quoteVolume: 97105333.71238178,
      btcVolume: 8374.845437346688,
      usdVolume: 97105333.71238178,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '3.3884000000000000',
      high: '3.8620000000000000',
      low: '2.9266000000000000',
      close: '3.6369000000000000',
      volume: '25321151.3600000000000000',
      quoteVolume: 85723441.75518183,
      btcVolume: 8111.5203882492,
      usdVolume: 85723441.75518183,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '3.6476000000000000',
      high: '3.9914000000000000',
      low: '3.2010000000000000',
      close: '3.7453000000000000',
      volume: '26688227.6900000000000000',
      quoteVolume: 97119111.08506477,
      btcVolume: 9423.268210581768,
      usdVolume: 97119111.08506477,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '3.7441000000000000',
      high: '3.8857000000000000',
      low: '3.4769000000000000',
      close: '3.4900000000000000',
      volume: '11091453.7900000000000000',
      quoteVolume: 40850492.97302097,
      btcVolume: 3600.00749644261,
      usdVolume: 40850492.97302097,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '3.4854000000000000',
      high: '3.6100000000000000',
      low: '3.2040000000000000',
      close: '3.4530000000000000',
      volume: '9871917.6900000000000000',
      quoteVolume: 33779334.70123714,
      btcVolume: 2886.5694636866006,
      usdVolume: 33779334.70123714,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '3.4555000000000000',
      high: '3.7500000000000000',
      low: '3.4410000000000000',
      close: '3.6400000000000000',
      volume: '10497021.6500000000000000',
      quoteVolume: 37787417.88724579,
      btcVolume: 3395.0022936467367,
      usdVolume: 37787417.88724579,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '3.6400000000000000',
      high: '3.6611000000000000',
      low: '3.4384000000000000',
      close: '3.4659000000000000',
      volume: '4978418.4100000000000000',
      quoteVolume: 17630030.877459843,
      btcVolume: 1551.1898933985874,
      usdVolume: 17630030.877459843,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '3.4650000000000000',
      high: '3.5038000000000000',
      low: '3.2500000000000000',
      close: '3.2750000000000000',
      volume: '5553651.6400000000000000',
      quoteVolume: 18694548.744352836,
      btcVolume: 1655.1485012561434,
      usdVolume: 18694548.744352836,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '3.2750000000000000',
      high: '3.5731000000000000',
      low: '3.2500000000000000',
      close: '3.3362000000000000',
      volume: '5268983.9800000000000000',
      quoteVolume: 17808772.169726066,
      btcVolume: 1520.284319482537,
      usdVolume: 17808772.169726066,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '3.3334000000000000',
      high: '3.3741000000000000',
      low: '2.9401000000000000',
      close: '3.0670000000000000',
      volume: '8673443.7700000000000000',
      quoteVolume: 27298953.85568921,
      btcVolume: 2188.425469158119,
      usdVolume: 27298953.85568921,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '3.0670000000000000',
      high: '3.2568000000000000',
      low: '2.8578000000000000',
      close: '3.1166000000000000',
      volume: '9527421.0200000000000000',
      quoteVolume: 29323317.465814188,
      btcVolume: 2364.92290456046,
      usdVolume: 29323317.465814188,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '3.1166000000000000',
      high: '3.1555000000000000',
      low: '2.7100000000000000',
      close: '2.7853000000000000',
      volume: '7262068.2500000000000000',
      quoteVolume: 21179286.916986044,
      btcVolume: 1836.1996771189365,
      usdVolume: 21179286.916986044,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '2.7870000000000000',
      high: '3.3282000000000000',
      low: '2.6000000000000000',
      close: '3.1660000000000000',
      volume: '11731863.1100000000000000',
      quoteVolume: 35185953.587122776,
      btcVolume: 3037.647143684832,
      usdVolume: 35185953.587122776,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '3.1660000000000000',
      high: '3.2953000000000000',
      low: '3.0400000000000000',
      close: '3.1652000000000000',
      volume: '9065258.2000000000000000',
      quoteVolume: 28811293.98759913,
      btcVolume: 2530.9180949987526,
      usdVolume: 28811293.98759913,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '3.1689000000000000',
      high: '3.2413000000000000',
      low: '2.7611000000000000',
      close: '2.7856000000000000',
      volume: '8164892.0900000000000000',
      quoteVolume: 24328975.206776988,
      btcVolume: 2259.0976435334483,
      usdVolume: 24328975.206776988,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '2.7792000000000000',
      high: '2.8498000000000000',
      low: '2.5801000000000000',
      close: '2.6913000000000000',
      volume: '7318353.9000000000000000',
      quoteVolume: 19951566.39118311,
      btcVolume: 1928.7548326502576,
      usdVolume: 19951566.39118311,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '2.6911000000000000',
      high: '2.7749000000000000',
      low: '2.1521000000000000',
      close: '2.1948000000000000',
      volume: '7934695.5900000000000000',
      quoteVolume: 19458898.407283843,
      btcVolume: 1935.2094152580992,
      usdVolume: 19458898.407283843,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '2.1947000000000000',
      high: '2.6000000000000000',
      low: '2.0531000000000000',
      close: '2.3980000000000000',
      volume: '13183927.9300000000000000',
      quoteVolume: 30803035.246100836,
      btcVolume: 3208.952057742877,
      usdVolume: 30803035.246100836,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '2.3951000000000000',
      high: '2.8094000000000000',
      low: '2.3542000000000000',
      close: '2.6700000000000000',
      volume: '13345016.8600000000000000',
      quoteVolume: 34825584.843023226,
      btcVolume: 3500.7237591055887,
      usdVolume: 34764284.05553622,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '2.6700000000000000',
      high: '2.7490000000000000',
      low: '2.4910000000000000',
      close: '2.6046000000000000',
      volume: '5510181.5700000000000000',
      quoteVolume: 14293523.817000896,
      btcVolume: 1368.4110094593677,
      usdVolume: 14293523.817000896,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '2.6020000000000000',
      high: '2.7314000000000000',
      low: '2.5751000000000000',
      close: '2.6370000000000000',
      volume: '4596378.0700000000000000',
      quoteVolume: 12202999.48393705,
      btcVolume: 1147.4765675933133,
      usdVolume: 12202999.48393705,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '2.6369000000000000',
      high: '2.6650000000000000',
      low: '2.4600000000000000',
      close: '2.5319000000000000',
      volume: '3349432.2400000000000000',
      quoteVolume: 8531199.090973042,
      btcVolume: 809.0792883766617,
      usdVolume: 8531199.090973042,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '2.5319000000000000',
      high: '2.5857000000000000',
      low: '2.3732000000000000',
      close: '2.4078000000000000',
      volume: '3415186.7600000000000000',
      quoteVolume: 8425751.27914594,
      btcVolume: 810.0485394775847,
      usdVolume: 8425751.27914594,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '2.4073000000000000',
      high: '2.5200000000000000',
      low: '2.2489000000000000',
      close: '2.3535000000000000',
      volume: '5670086.3900000000000000',
      quoteVolume: 13434498.579576982,
      btcVolume: 1334.7270621160683,
      usdVolume: 13434498.579576982,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '2.3559000000000000',
      high: '2.5131000000000000',
      low: '2.2700000000000000',
      close: '2.3999000000000000',
      volume: '5308279.8100000000000000',
      quoteVolume: 12686743.212995907,
      btcVolume: 1303.671357536717,
      usdVolume: 12686743.212995907,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '2.3971000000000000',
      high: '2.5149000000000000',
      low: '2.3595000000000000',
      close: '2.3615000000000000',
      volume: '4016580.8300000000000000',
      quoteVolume: 9840046.642877948,
      btcVolume: 982.9918828701473,
      usdVolume: 9840046.642877948,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '2.3615000000000000',
      high: '2.4300000000000000',
      low: '2.3201000000000000',
      close: '2.3989000000000000',
      volume: '1971791.7400000000000000',
      quoteVolume: 4688945.92508803,
      btcVolume: 480.4188696425642,
      usdVolume: 4688945.92508803,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '2.3954000000000000',
      high: '2.4600000000000000',
      low: '2.1525000000000000',
      close: '2.2453000000000000',
      volume: '4245834.2700000000000000',
      quoteVolume: 9865099.724368896,
      btcVolume: 1019.8334976316504,
      usdVolume: 9865099.724368896,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '2.2372000000000000',
      high: '2.2733000000000000',
      low: '2.1000000000000000',
      close: '2.2156000000000000',
      volume: '1902749.3100000000000000',
      quoteVolume: 4200464.91369699,
      btcVolume: 443.62670497298694,
      usdVolume: 4200464.91369699,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '2.2178000000000000',
      high: '2.2482000000000000',
      low: '2.1100000000000000',
      close: '2.1251000000000000',
      volume: '2215943.5300000000000000',
      quoteVolume: 4805773.902062991,
      btcVolume: 503.4159560790306,
      usdVolume: 4805773.902062991,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '2.1250000000000000',
      high: '2.1854000000000000',
      low: '1.9694000000000000',
      close: '2.0682000000000000',
      volume: '4210835.6200000000000000',
      quoteVolume: 8720296.91253309,
      btcVolume: 916.5223854094169,
      usdVolume: 8720296.91253309,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '2.0672000000000000',
      high: '2.2500000000000000',
      low: '2.0589000000000000',
      close: '2.2102000000000000',
      volume: '4558064.6100000000000000',
      quoteVolume: 9920046.062618986,
      btcVolume: 1007.3882816663967,
      usdVolume: 9920046.062618986,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '2.2169000000000000',
      high: '2.2310000000000000',
      low: '2.1054000000000000',
      close: '2.1610000000000000',
      volume: '2890541.3500000000000000',
      quoteVolume: 6235734.283716941,
      btcVolume: 618.792071520022,
      usdVolume: 6235734.283716941,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '2.1612000000000000',
      high: '2.4900000000000000',
      low: '2.1275000000000000',
      close: '2.4271000000000000',
      volume: '6319480.9600000000000000',
      quoteVolume: 14629814.693532972,
      btcVolume: 1400.4593413301136,
      usdVolume: 14629814.693532972,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '2.4269000000000000',
      high: '2.7342000000000000',
      low: '2.3569000000000000',
      close: '2.4211000000000000',
      volume: '11323687.1300000000000000',
      quoteVolume: 29135005.95525144,
      btcVolume: 2701.80633921746,
      usdVolume: 29135005.95525144,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '2.4186000000000000',
      high: '2.6044000000000000',
      low: '2.3811000000000000',
      close: '2.5099000000000000',
      volume: '6257429.6800000000000000',
      quoteVolume: 15626611.666627988,
      btcVolume: 1446.5228705557931,
      usdVolume: 15626611.666627988,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '2.5097000000000000',
      high: '2.6495000000000000',
      low: '2.4562000000000000',
      close: '2.4562000000000000',
      volume: '5389254.3200000000000000',
      quoteVolume: 13710085.081441978,
      btcVolume: 1176.551959779662,
      usdVolume: 13710085.081441978,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '2.4599000000000000',
      high: '2.5181000000000000',
      low: '2.3100000000000000',
      close: '2.4785000000000000',
      volume: '6024056.7500000000000000',
      quoteVolume: 14593117.820943044,
      btcVolume: 1242.3745698550665,
      usdVolume: 14593117.820943044,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '2.4822000000000000',
      high: '2.5000000000000000',
      low: '2.3502000000000000',
      close: '2.3826000000000000',
      volume: '3376864.0700000000000000',
      quoteVolume: 8167003.771067984,
      btcVolume: 696.2613814905944,
      usdVolume: 8167003.771067984,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '2.3883000000000000',
      high: '2.3998000000000000',
      low: '2.2713000000000000',
      close: '2.2755000000000000',
      volume: '2863707.1900000000000000',
      quoteVolume: 6666989.981878993,
      btcVolume: 564.8964889382114,
      usdVolume: 6666989.981878993,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '2.2784000000000000',
      high: '2.2919000000000000',
      low: '2.1510000000000000',
      close: '2.1669000000000000',
      volume: '2368470.3900000000000000',
      quoteVolume: 5268428.711106007,
      btcVolume: 444.782865591571,
      usdVolume: 5268428.711106007,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '2.1690000000000000',
      high: '2.4091000000000000',
      low: '2.1306000000000000',
      close: '2.3336000000000000',
      volume: '5233238.7500000000000000',
      quoteVolume: 12050390.512594998,
      btcVolume: 1035.7246734427822,
      usdVolume: 12050390.512594998,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '2.3336000000000000',
      high: '2.4900000000000000',
      low: '2.2901000000000000',
      close: '2.4157000000000000',
      volume: '3701976.9800000000000000',
      quoteVolume: 8866596.82357804,
      btcVolume: 777.1699540463694,
      usdVolume: 8866596.82357804,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '2.4157000000000000',
      high: '2.4790000000000000',
      low: '2.3350000000000000',
      close: '2.3900000000000000',
      volume: '2579648.7400000000000000',
      quoteVolume: 6209796.363283018,
      btcVolume: 544.0263252504219,
      usdVolume: 6209796.363283018,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '2.3846000000000000',
      high: '2.4679000000000000',
      low: '2.3060000000000000',
      close: '2.4229000000000000',
      volume: '3150417.3700000000000000',
      quoteVolume: 7506682.66399902,
      btcVolume: 678.7973027516069,
      usdVolume: 7506682.66399902,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '2.4190000000000000',
      high: '2.6031000000000000',
      low: '2.1630000000000000',
      close: '2.2842000000000000',
      volume: '6699207.3700000000000000',
      quoteVolume: 16231062.046769118,
      btcVolume: 1549.2558632873051,
      usdVolume: 16231062.046769118,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '2.2840000000000000',
      high: '2.4506000000000000',
      low: '2.2565000000000000',
      close: '2.4232000000000000',
      volume: '2806801.4000000000000000',
      quoteVolume: 6611413.672988072,
      btcVolume: 652.6110331933832,
      usdVolume: 6611413.672988072,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '2.4225000000000000',
      high: '2.4590000000000000',
      low: '2.2270000000000000',
      close: '2.3000000000000000',
      volume: '3572174.4000000000000000',
      quoteVolume: 8302828.475309981,
      btcVolume: 815.1156611012854,
      usdVolume: 8302828.475309981,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '2.3046000000000000',
      high: '2.4190000000000000',
      low: '2.2820000000000000',
      close: '2.4036000000000000',
      volume: '2035414.3900000000000000',
      quoteVolume: 4797115.851594993,
      btcVolume: 466.47840144058904,
      usdVolume: 4797115.851594993,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '2.4050000000000000',
      high: '2.5657000000000000',
      low: '2.3729000000000000',
      close: '2.5051000000000000',
      volume: '3610375.3000000000000000',
      quoteVolume: 8909798.939858058,
      btcVolume: 863.7326634348484,
      usdVolume: 8909798.939858058,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '2.5055000000000000',
      high: '2.6000000000000000',
      low: '2.4508000000000000',
      close: '2.4958000000000000',
      volume: '4280607.5800000000000000',
      quoteVolume: 10853323.38349604,
      btcVolume: 1017.6371082460578,
      usdVolume: 10853323.38349604,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '2.4943000000000000',
      high: '2.5401000000000000',
      low: '2.3354000000000000',
      close: '2.3624000000000000',
      volume: '3474086.6000000000000000',
      quoteVolume: 8391667.38601199,
      btcVolume: 780.6798427463843,
      usdVolume: 8391667.38601199,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '2.3622000000000000',
      high: '2.3710000000000000',
      low: '2.1500000000000000',
      close: '2.2054000000000000',
      volume: '4915776.4000000000000000',
      quoteVolume: 11029113.309874035,
      btcVolume: 1081.9973137236552,
      usdVolume: 11029113.309874035,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '2.2058000000000000',
      high: '2.2896000000000000',
      low: '2.1350000000000000',
      close: '2.2227000000000000',
      volume: '2980708.5400000000000000',
      quoteVolume: 6618775.966529956,
      btcVolume: 660.0412390163551,
      usdVolume: 6618775.966529956,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '2.2231000000000000',
      high: '2.2950000000000000',
      low: '2.1723000000000000',
      close: '2.2343000000000000',
      volume: '3274284.1600000000000000',
      quoteVolume: 7324880.720449999,
      btcVolume: 715.1730454658111,
      usdVolume: 7324880.720449999,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '2.2343000000000000',
      high: '2.2545000000000000',
      low: '2.1537000000000000',
      close: '2.1681000000000000',
      volume: '1629473.9800000000000000',
      quoteVolume: 3574006.0448640008,
      btcVolume: 352.9833991449766,
      usdVolume: 3574006.0448640008,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '2.1704000000000000',
      high: '2.1804000000000000',
      low: '2.0255000000000000',
      close: '2.0780000000000000',
      volume: '2603393.1400000000000000',
      quoteVolume: 5492656.678655035,
      btcVolume: 545.2530944238928,
      usdVolume: 5492656.678655035,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '2.0783000000000000',
      high: '2.1546000000000000',
      low: '2.0450000000000000',
      close: '2.0739000000000000',
      volume: '2094166.6100000000000000',
      quoteVolume: 4389987.473204011,
      btcVolume: 423.4809452125515,
      usdVolume: 4389987.473204011,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '2.0737000000000000',
      high: '2.1788000000000000',
      low: '2.0050000000000000',
      close: '2.1128000000000000',
      volume: '2811534.7300000000000000',
      quoteVolume: 5891385.969969013,
      btcVolume: 579.2737813324595,
      usdVolume: 5891385.969969013,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '2.1098000000000000',
      high: '2.1134000000000000',
      low: '1.8750000000000000',
      close: '1.8957000000000000',
      volume: '2925102.1700000000000000',
      quoteVolume: 5826671.213469991,
      btcVolume: 585.9165069079237,
      usdVolume: 5826671.213469991,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '1.8956000000000000',
      high: '1.8956000000000000',
      low: '1.7253000000000000',
      close: '1.7744000000000000',
      volume: '4172574.3900000000000000',
      quoteVolume: 7534972.244916026,
      btcVolume: 791.9607358540669,
      usdVolume: 7534972.244916026,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '1.7763000000000000',
      high: '1.8913000000000000',
      low: '1.7365000000000000',
      close: '1.7960000000000000',
      volume: '2660522.1000000000000000',
      quoteVolume: 4851727.818894994,
      btcVolume: 508.2563024568634,
      usdVolume: 4851727.818894994,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '1.7958000000000000',
      high: '1.8535000000000000',
      low: '1.7400000000000000',
      close: '1.7791000000000000',
      volume: '1818973.5000000000000000',
      quoteVolume: 3255608.6478299913,
      btcVolume: 339.9346500517163,
      usdVolume: 3255608.6478299913,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '1.7788000000000000',
      high: '1.8000000000000000',
      low: '1.7170000000000000',
      close: '1.7686000000000000',
      volume: '1329827.2100000000000000',
      quoteVolume: 2338722.617214002,
      btcVolume: 242.99787843430724,
      usdVolume: 2338722.617214002,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '1.7696000000000000',
      high: '1.8900000000000000',
      low: '1.7272000000000000',
      close: '1.8287000000000000',
      volume: '3042460.9700000000000000',
      quoteVolume: 5523573.6413650215,
      btcVolume: 553.7959499063911,
      usdVolume: 5523573.6413650215,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '1.8307000000000000',
      high: '1.9424000000000000',
      low: '1.8147000000000000',
      close: '1.8490000000000000',
      volume: '3198661.0900000000000000',
      quoteVolume: 5978206.5396679975,
      btcVolume: 570.2827065368367,
      usdVolume: 5978206.5396679975,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '1.8486000000000000',
      high: '1.8700000000000000',
      low: '1.7167000000000000',
      close: '1.7793000000000000',
      volume: '3545459.8000000000000000',
      quoteVolume: 6323373.737698996,
      btcVolume: 599.008568072105,
      usdVolume: 6323373.737698996,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '1.7784000000000000',
      high: '1.8624000000000000',
      low: '1.7680000000000000',
      close: '1.7921000000000000',
      volume: '2705662.3600000000000000',
      quoteVolume: 4910186.863704015,
      btcVolume: 465.71608792980777,
      usdVolume: 4910186.863704015,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '1.7919000000000000',
      high: '1.8600000000000000',
      low: '1.6767000000000000',
      close: '1.7226000000000000',
      volume: '3028097.8200000000000000',
      quoteVolume: 5355618.6108840015,
      btcVolume: 506.31943933656817,
      usdVolume: 5355618.6108840015,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '1.7222000000000000',
      high: '1.8060000000000000',
      low: '1.7085000000000000',
      close: '1.7672000000000000',
      volume: '2401823.2500000000000000',
      quoteVolume: 4215075.491124999,
      btcVolume: 404.7663920454655,
      usdVolume: 4215075.491124999,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '1.7672000000000000',
      high: '1.9054000000000000',
      low: '1.7610000000000000',
      close: '1.8275000000000000',
      volume: '3500287.6000000000000000',
      quoteVolume: 6422308.280667982,
      btcVolume: 617.3872473153666,
      usdVolume: 6422308.280667982,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '1.8290000000000000',
      high: '1.8731000000000000',
      low: '1.7610000000000000',
      close: '1.7872000000000000',
      volume: '3473067.9800000000000000',
      quoteVolume: 6305180.84346195,
      btcVolume: 613.2646136601918,
      usdVolume: 6305180.84346195,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '1.7883000000000000',
      high: '1.8326000000000000',
      low: '1.7730000000000000',
      close: '1.7998000000000000',
      volume: '2711918.0700000000000000',
      quoteVolume: 4888778.085218002,
      btcVolume: 478.77419074309563,
      usdVolume: 4888778.085218002,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '1.7988000000000000',
      high: '1.8258000000000000',
      low: '1.7136000000000000',
      close: '1.7301000000000000',
      volume: '2214016.1100000000000000',
      quoteVolume: 3890643.726558997,
      btcVolume: 386.83840067774776,
      usdVolume: 3890643.726558997,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '1.7301000000000000',
      high: '1.7458000000000000',
      low: '1.6918000000000000',
      close: '1.7073000000000000',
      volume: '2228942.3400000000000000',
      quoteVolume: 3823755.5435819686,
      btcVolume: 374.2899559647845,
      usdVolume: 3823755.5435819686,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '1.7073000000000000',
      high: '1.7099000000000000',
      low: '1.5100000000000000',
      close: '1.5759000000000000',
      volume: '6637630.0700000000000000',
      quoteVolume: 10595656.219862076,
      btcVolume: 1032.4407207665965,
      usdVolume: 10595656.219862076,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '1.5761000000000000',
      high: '1.6375000000000000',
      low: '1.5239000000000000',
      close: '1.5634000000000000',
      volume: '5678360.6100000000000000',
      quoteVolume: 9008346.159965044,
      btcVolume: 872.4429504635938,
      usdVolume: 9008346.159965044,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '1.5634000000000000',
      high: '1.6458000000000000',
      low: '1.5600000000000000',
      close: '1.6104000000000000',
      volume: '4049102.7700000000000000',
      quoteVolume: 6482781.439092984,
      btcVolume: 629.5046579926835,
      usdVolume: 6482781.439092984,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '1.6110000000000000',
      high: '1.6355000000000000',
      low: '1.5450000000000000',
      close: '1.5806000000000000',
      volume: '3545712.0300000000000000',
      quoteVolume: 5618393.775373046,
      btcVolume: 548.8843112989407,
      usdVolume: 5618393.775373046,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '1.5806000000000000',
      high: '1.6765000000000000',
      low: '1.5532000000000000',
      close: '1.6240000000000000',
      volume: '5390335.8700000000000000',
      quoteVolume: 8708882.286866039,
      btcVolume: 852.1328908855065,
      usdVolume: 8708882.286866039,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '1.6240000000000000',
      high: '1.8462000000000000',
      low: '1.6240000000000000',
      close: '1.8010000000000000',
      volume: '8267989.9400000000000000',
      quoteVolume: 14346677.761906926,
      btcVolume: 1407.7978038474118,
      usdVolume: 14346677.761906926,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '1.7994000000000000',
      high: '1.9075000000000000',
      low: '1.7079000000000000',
      close: '1.8744000000000000',
      volume: '8485812.8300000000000000',
      quoteVolume: 15522274.024547847,
      btcVolume: 1554.190947436255,
      usdVolume: 15522274.024547847,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '1.8743000000000000',
      high: '1.8772000000000000',
      low: '1.7510000000000000',
      close: '1.8245000000000000',
      volume: '3468435.2500000000000000',
      quoteVolume: 6287930.976908962,
      btcVolume: 618.0830858277567,
      usdVolume: 6287930.976908962,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '1.8215000000000000',
      high: '1.8888000000000000',
      low: '1.7881000000000000',
      close: '1.8855000000000000',
      volume: '2771763.9300000000000000',
      quoteVolume: 5102178.238018988,
      btcVolume: 508.0689834466635,
      usdVolume: 5102178.238018988,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '1.8848000000000000',
      high: '1.8868000000000000',
      low: '1.7711000000000000',
      close: '1.8157000000000000',
      volume: '2311848.6500000000000000',
      quoteVolume: 4207056.9643990025,
      btcVolume: 422.0307052601196,
      usdVolume: 4207056.9643990025,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '1.8149000000000000',
      high: '1.8800000000000000',
      low: '1.7799000000000000',
      close: '1.8031000000000000',
      volume: '2667120.7500000000000000',
      quoteVolume: 4892761.515483015,
      btcVolume: 497.2694919497504,
      usdVolume: 4892761.515483015,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '1.8048000000000000',
      high: '2.0657000000000000',
      low: '1.4612000000000000',
      close: '1.7160000000000000',
      volume: '15096447.2100000000000000',
      quoteVolume: 28169393.552325387,
      btcVolume: 3042.0172749110857,
      usdVolume: 28169393.552325387,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '1.7135000000000000',
      high: '1.9286000000000000',
      low: '1.6249000000000000',
      close: '1.7443000000000000',
      volume: '8910281.8800000000000000',
      quoteVolume: 15541684.445626967,
      btcVolume: 1835.8125331240349,
      usdVolume: 15541684.445626967,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '1.7476000000000000',
      high: '1.7584000000000000',
      low: '1.5276000000000000',
      close: '1.6512000000000000',
      volume: '6786874.8600000000000000',
      quoteVolume: 11209636.83260096,
      btcVolume: 1367.9713551044558,
      usdVolume: 11209636.83260096,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '1.6512000000000000',
      high: '1.6786000000000000',
      low: '1.5573000000000000',
      close: '1.6590000000000000',
      volume: '4333552.0600000000000000',
      quoteVolume: 6991710.784840995,
      btcVolume: 870.5039437524816,
      usdVolume: 6991710.784840995,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '1.6593000000000000',
      high: '1.7017000000000000',
      low: '1.6284000000000000',
      close: '1.6742000000000000',
      volume: '2326952.1300000000000000',
      quoteVolume: 3880224.7487799893,
      btcVolume: 476.88231743920215,
      usdVolume: 3880224.7487799893,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '1.6748000000000000',
      high: '1.7492000000000000',
      low: '1.6441000000000000',
      close: '1.7002000000000000',
      volume: '3304534.4900000000000000',
      quoteVolume: 5625241.514617002,
      btcVolume: 697.5333272708833,
      usdVolume: 5625241.514617002,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '1.7004000000000000',
      high: '1.7690000000000000',
      low: '1.6404000000000000',
      close: '1.7677000000000000',
      volume: '3564898.9100000000000000',
      quoteVolume: 6077384.966384979,
      btcVolume: 753.0627398007749,
      usdVolume: 6077384.966384979,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '1.7677000000000000',
      high: '1.8820000000000000',
      low: '1.7522000000000000',
      close: '1.8286000000000000',
      volume: '4077978.5200000000000000',
      quoteVolume: 7412545.756683013,
      btcVolume: 888.0318959948171,
      usdVolume: 7412545.756683013,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '1.8285000000000000',
      high: '2.0233000000000000',
      low: '1.8121000000000000',
      close: '2.0110000000000000',
      volume: '5789143.1000000000000000',
      quoteVolume: 11109499.143447043,
      btcVolume: 1347.6344778608,
      usdVolume: 11109499.143447043,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '2.0134000000000000',
      high: '2.0338000000000000',
      low: '1.8800000000000000',
      close: '1.9442000000000000',
      volume: '6996841.1000000000000000',
      quoteVolume: 13705258.538260838,
      btcVolume: 1670.6787632836763,
      usdVolume: 13705258.538260838,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '1.9443000000000000',
      high: '1.9949000000000000',
      low: '1.8880000000000000',
      close: '1.9663000000000000',
      volume: '3419972.1800000000000000',
      quoteVolume: 6612123.5559109915,
      btcVolume: 813.0573588319999,
      usdVolume: 6612123.5559109915,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '1.9663000000000000',
      high: '1.9775000000000000',
      low: '1.9113000000000000',
      close: '1.9624000000000000',
      volume: '2231607.1100000000000000',
      quoteVolume: 4348757.9730950035,
      btcVolume: 537.2042324566693,
      usdVolume: 4348757.9730950035,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '1.9624000000000000',
      high: '2.2659000000000000',
      low: '1.9531000000000000',
      close: '2.1669000000000000',
      volume: '9724532.2300000000000000',
      quoteVolume: 20653440.90051679,
      btcVolume: 2600.3765280923726,
      usdVolume: 20653440.90051679,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '2.1669000000000000',
      high: '2.4899000000000000',
      low: '2.0971000000000000',
      close: '2.3963000000000000',
      volume: '12793025.3500000000000000',
      quoteVolume: 29795334.186469693,
      btcVolume: 3681.5038062533695,
      usdVolume: 29795334.186469693,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '2.3963000000000000',
      high: '2.6500000000000000',
      low: '2.2680000000000000',
      close: '2.5766000000000000',
      volume: '16095226.0400000000000000',
      quoteVolume: 39937936.664554514,
      btcVolume: 4873.697508425804,
      usdVolume: 39937936.664554514,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '2.5765000000000000',
      high: '2.9500000000000000',
      low: '2.5010000000000000',
      close: '2.8011000000000000',
      volume: '21716501.4900000000000000',
      quoteVolume: 59715252.16293435,
      btcVolume: 7171.176638549378,
      usdVolume: 59715252.16293435,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '2.8011000000000000',
      high: '2.8058000000000000',
      low: '2.4650000000000000',
      close: '2.6302000000000000',
      volume: '15341662.5100000000000000',
      quoteVolume: 40233982.30970988,
      btcVolume: 4713.439912937979,
      usdVolume: 40233982.30970988,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '2.6280000000000000',
      high: '2.7936000000000000',
      low: '2.5619000000000000',
      close: '2.7481000000000000',
      volume: '11370701.0600000000000000',
      quoteVolume: 30661560.51973289,
      btcVolume: 3657.570932964459,
      usdVolume: 30661560.51973289,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '2.7443000000000000',
      high: '2.7572000000000000',
      low: '2.5519000000000000',
      close: '2.5975000000000000',
      volume: '6172891.7200000000000000',
      quoteVolume: 16310219.63231884,
      btcVolume: 1958.0872004698058,
      usdVolume: 16310219.63231884,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '2.5975000000000000',
      high: '2.6443000000000000',
      low: '2.4351000000000000',
      close: '2.4860000000000000',
      volume: '5530185.4400000000000000',
      quoteVolume: 14018335.45872208,
      btcVolume: 1683.0569562732167,
      usdVolume: 14018335.45872208,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '2.4850000000000000',
      high: '2.6741000000000000',
      low: '2.4527000000000000',
      close: '2.5324000000000000',
      volume: '5826032.3200000000000000',
      quoteVolume: 14927603.979734935,
      btcVolume: 1798.6897727563057,
      usdVolume: 14927603.979734935,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '2.5315000000000000',
      high: '2.5970000000000000',
      low: '2.3320000000000000',
      close: '2.3972000000000000',
      volume: '6184169.7700000000000000',
      quoteVolume: 15237510.027046982,
      btcVolume: 1851.108592609114,
      usdVolume: 15237510.027046982,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '2.3972000000000000',
      high: '2.4143000000000000',
      low: '2.1820000000000000',
      close: '2.3717000000000000',
      volume: '11839469.0800000000000000',
      quoteVolume: 27151346.82973899,
      btcVolume: 3372.7497076069235,
      usdVolume: 27151346.82973899,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '2.3736000000000000',
      high: '2.4900000000000000',
      low: '2.2740000000000000',
      close: '2.4124000000000000',
      volume: '10413491.6200000000000000',
      quoteVolume: 24976988.911125984,
      btcVolume: 3108.003201846181,
      usdVolume: 24976988.911125984,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '2.4190000000000000',
      high: '2.4999000000000000',
      low: '2.3164000000000000',
      close: '2.3546000000000000',
      volume: '7255303.2100000000000000',
      quoteVolume: 17379294.0806661,
      btcVolume: 2186.768617430999,
      usdVolume: 17379294.0806661,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '2.3521000000000000',
      high: '2.3910000000000000',
      low: '2.2914000000000000',
      close: '2.3521000000000000',
      volume: '4513432.3200000000000000',
      quoteVolume: 10575592.141082024,
      btcVolume: 1328.617937628987,
      usdVolume: 10575592.141082024,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '2.3513000000000000',
      high: '2.4510000000000000',
      low: '2.2780000000000000',
      close: '2.4444000000000000',
      volume: '4491279.5700000000000000',
      quoteVolume: 10590230.618070923,
      btcVolume: 1316.2113024406156,
      usdVolume: 10590230.618070923,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '2.4443000000000000',
      high: '2.6900000000000000',
      low: '2.4347000000000000',
      close: '2.6223000000000000',
      volume: '11336763.6800000000000000',
      quoteVolume: 29227623.981732704,
      btcVolume: 3558.2909764104465,
      usdVolume: 29227623.981732704,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '2.6216000000000000',
      high: '2.8178000000000000',
      low: '2.5619000000000000',
      close: '2.6074000000000000',
      volume: '11193488.7900000000000000',
      quoteVolume: 30334954.89091577,
      btcVolume: 3713.298114804292,
      usdVolume: 30334954.89091577,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '2.6073000000000000',
      high: '2.6840000000000000',
      low: '2.3717000000000000',
      close: '2.6055000000000000',
      volume: '13350403.7600000000000000',
      quoteVolume: 34058909.05652626,
      btcVolume: 4438.740203459202,
      usdVolume: 34058909.05652626,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '2.6071000000000000',
      high: '2.7748000000000000',
      low: '2.6020000000000000',
      close: '2.7200000000000000',
      volume: '9744122.3600000000000000',
      quoteVolume: 26188969.916833855,
      btcVolume: 3521.547093016349,
      usdVolume: 26188969.916833855,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '2.7201000000000000',
      high: '3.0000000000000000',
      low: '2.6165000000000000',
      close: '2.8360000000000000',
      volume: '12714070.6800000000000000',
      quoteVolume: 35489759.83948473,
      btcVolume: 4402.325745022558,
      usdVolume: 35489759.83948473,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '2.8370000000000000',
      high: '2.9977000000000000',
      low: '2.6640000000000000',
      close: '2.7348000000000000',
      volume: '12993334.8100000000000000',
      quoteVolume: 36586743.2039599,
      btcVolume: 3897.7642510202168,
      usdVolume: 36586743.2039599,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '2.7323000000000000',
      high: '2.7620000000000000',
      low: '2.6511000000000000',
      close: '2.6646000000000000',
      volume: '5966423.4400000000000000',
      quoteVolume: 16086672.751381027,
      btcVolume: 1713.5391674385778,
      usdVolume: 16086672.751381027,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '2.6707000000000000',
      high: '2.8859000000000000',
      low: '2.6600000000000000',
      close: '2.6611000000000000',
      volume: '7822708.6800000000000000',
      quoteVolume: 21672797.56783017,
      btcVolume: 2284.2347637633156,
      usdVolume: 21672797.56783017,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '2.6601000000000000',
      high: '2.7690000000000000',
      low: '2.6321000000000000',
      close: '2.6620000000000000',
      volume: '3504202.7100000000000000',
      quoteVolume: 9449017.04016303,
      btcVolume: 1008.1912647024507,
      usdVolume: 9449017.04016303,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '2.6610000000000000',
      high: '2.6768000000000000',
      low: '2.5107000000000000',
      close: '2.6093000000000000',
      volume: '3975481.9600000000000000',
      quoteVolume: 10291062.332419943,
      btcVolume: 1120.2450669570424,
      usdVolume: 10291062.332419943,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '2.6044000000000000',
      high: '2.7573000000000000',
      low: '2.5365000000000000',
      close: '2.7219000000000000',
      volume: '4135810.6300000000000000',
      quoteVolume: 10988316.188437952,
      btcVolume: 1197.8135631055834,
      usdVolume: 10988316.188437952,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '2.7233000000000000',
      high: '2.7952000000000000',
      low: '2.6428000000000000',
      close: '2.7091000000000000',
      volume: '3786813.4200000000000000',
      quoteVolume: 10249112.15861604,
      btcVolume: 1122.302010791027,
      usdVolume: 10249112.15861604,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '2.7092000000000000',
      high: '2.7399000000000000',
      low: '2.6828000000000000',
      close: '2.6973000000000000',
      volume: '1945361.3400000000000000',
      quoteVolume: 5267072.043627042,
      btcVolume: 568.6068601325862,
      usdVolume: 5267072.043627042,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '2.7015000000000000',
      high: '2.7104000000000000',
      low: '2.6202000000000000',
      close: '2.6605000000000000',
      volume: '1676684.8800000000000000',
      quoteVolume: 4468927.620704968,
      btcVolume: 485.58249494067894,
      usdVolume: 4468927.620704968,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '2.6594000000000000',
      high: '2.7300000000000000',
      low: '2.6267000000000000',
      close: '2.6746000000000000',
      volume: '2308453.4600000000000000',
      quoteVolume: 6191639.232816044,
      btcVolume: 667.6063736733863,
      usdVolume: 6191639.232816044,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '2.6746000000000000',
      high: '2.6947000000000000',
      low: '2.6322000000000000',
      close: '2.6746000000000000',
      volume: '1985221.7300000000000000',
      quoteVolume: 5293153.906610983,
      btcVolume: 566.6809866952036,
      usdVolume: 5293153.906610983,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '2.6743000000000000',
      high: '2.8200000000000000',
      low: '2.6479000000000000',
      close: '2.7448000000000000',
      volume: '3174248.6300000000000000',
      quoteVolume: 8633537.382419948,
      btcVolume: 926.8771591924037,
      usdVolume: 8633537.382419948,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '2.7446000000000000',
      high: '2.7549000000000000',
      low: '2.6022000000000000',
      close: '2.6757000000000000',
      volume: '3498719.3300000000000000',
      quoteVolume: 9378528.926173998,
      btcVolume: 1015.9056048703025,
      usdVolume: 9378528.926173998,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '2.6758000000000000',
      high: '2.7333000000000000',
      low: '2.6210000000000000',
      close: '2.7100000000000000',
      volume: '2921454.2700000000000000',
      quoteVolume: 7841818.426597041,
      btcVolume: 875.5145773375328,
      usdVolume: 7841818.426597041,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '2.7091000000000000',
      high: '2.8497000000000000',
      low: '2.7031000000000000',
      close: '2.8009000000000000',
      volume: '4656993.4400000000000000',
      quoteVolume: 12964192.966649953,
      btcVolume: 1471.6148137529362,
      usdVolume: 12964192.966649953,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '2.8008000000000000',
      high: '2.8370000000000000',
      low: '2.7362000000000000',
      close: '2.7770000000000000',
      volume: '2591145.5900000000000000',
      quoteVolume: 7226235.751716039,
      btcVolume: 811.2847092382342,
      usdVolume: 7226235.751716039,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '2.7773000000000000',
      high: '2.7777000000000000',
      low: '2.6655000000000000',
      close: '2.7440000000000000',
      volume: '2140491.0400000000000000',
      quoteVolume: 5824727.782756004,
      btcVolume: 663.0973900459279,
      usdVolume: 5824727.782756004,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '2.7438000000000000',
      high: '2.8733000000000000',
      low: '2.7311000000000000',
      close: '2.8152000000000000',
      volume: '3207309.4900000000000000',
      quoteVolume: 8980218.046382047,
      btcVolume: 1028.891814520804,
      usdVolume: 8980218.046382047,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '2.8177000000000000',
      high: '3.0894000000000000',
      low: '2.7851000000000000',
      close: '3.0200000000000000',
      volume: '6541016.7600000000000000',
      quoteVolume: 19407023.30134102,
      btcVolume: 2214.351922566352,
      usdVolume: 19407023.30134102,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '3.0189000000000000',
      high: '3.1600000000000000',
      low: '2.9666000000000000',
      close: '3.0391000000000000',
      volume: '6593839.9000000000000000',
      quoteVolume: 20356219.364202138,
      btcVolume: 2349.5884763957965,
      usdVolume: 20356219.364202138,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '3.0390000000000000',
      high: '3.0700000000000000',
      low: '2.7970000000000000',
      close: '3.0389000000000000',
      volume: '5616867.4900000000000000',
      quoteVolume: 16587870.154289966,
      btcVolume: 1939.8783909842598,
      usdVolume: 16587870.154289966,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '3.0372000000000000',
      high: '3.0435000000000000',
      low: '2.9115000000000000',
      close: '2.9894000000000000',
      volume: '1973143.8200000000000000',
      quoteVolume: 5875410.748616997,
      btcVolume: 693.1568795868579,
      usdVolume: 5875410.748616997,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '2.9893000000000000',
      high: '2.9894000000000000',
      low: '2.8644000000000000',
      close: '2.9100000000000000',
      volume: '1638152.2100000000000000',
      quoteVolume: 4776273.310892999,
      btcVolume: 560.9160031958835,
      usdVolume: 4776273.310892999,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '2.9100000000000000',
      high: '2.9610000000000000',
      low: '2.6977000000000000',
      close: '2.7885000000000000',
      volume: '3479282.4300000000000000',
      quoteVolume: 9852607.662228048,
      btcVolume: 1181.9947586571366,
      usdVolume: 9852607.662228048,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '2.7886000000000000',
      high: '2.8069000000000000',
      low: '2.5800000000000000',
      close: '2.7255000000000000',
      volume: '4538639.6800000000000000',
      quoteVolume: 12210133.545297062,
      btcVolume: 1503.1001831593057,
      usdVolume: 12210133.545297062,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '2.7279000000000000',
      high: '2.7957000000000000',
      low: '2.6300000000000000',
      close: '2.6982000000000000',
      volume: '3588766.3500000000000000',
      quoteVolume: 9788974.060772017,
      btcVolume: 1207.4464054339237,
      usdVolume: 9788974.060772017,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '2.6983000000000000',
      high: '2.7153000000000000',
      low: '2.4710000000000000',
      close: '2.5456000000000000',
      volume: '4528968.6200000000000000',
      quoteVolume: 11725649.253482027,
      btcVolume: 1506.7638418172246,
      usdVolume: 11725649.253482027,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '2.5456000000000000',
      high: '2.5952000000000000',
      low: '2.1000000000000000',
      close: '2.3466000000000000',
      volume: '10778947.2500000000000000',
      quoteVolume: 25122066.728304863,
      btcVolume: 3458.21602950047,
      usdVolume: 25122066.728304863,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '2.3434000000000000',
      high: '2.4621000000000000',
      low: '2.2604000000000000',
      close: '2.4266000000000000',
      volume: '4841617.1200000000000000',
      quoteVolume: 11394019.118919024,
      btcVolume: 1578.269819528385,
      usdVolume: 11394019.118919024,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '2.4272000000000000',
      high: '2.4533000000000000',
      low: '2.2000000000000000',
      close: '2.2216000000000000',
      volume: '4491218.3300000000000000',
      quoteVolume: 10364009.312878031,
      btcVolume: 1456.1232933078102,
      usdVolume: 10364009.312878031,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '2.2218000000000000',
      high: '2.3976000000000000',
      low: '2.0200000000000000',
      close: '2.2523000000000000',
      volume: '8151200.1200000000000000',
      quoteVolume: 18025202.51352003,
      btcVolume: 2602.121395130407,
      usdVolume: 18025202.51352003,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '2.2554000000000000',
      high: '2.3578000000000000',
      low: '2.1459000000000000',
      close: '2.2424000000000000',
      volume: '7541215.9200000000000000',
      quoteVolume: 16895075.120843165,
      btcVolume: 2370.226442420348,
      usdVolume: 16895075.120843165,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '2.2437000000000000',
      high: '2.2882000000000000',
      low: '2.1002000000000000',
      close: '2.2242000000000000',
      volume: '7867101.6900000000000000',
      quoteVolume: 17458921.123055957,
      btcVolume: 2407.2933436753665,
      usdVolume: 17458921.123055957,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '2.2216000000000000',
      high: '2.3248000000000000',
      low: '2.1711000000000000',
      close: '2.2702000000000000',
      volume: '4770270.0100000000000000',
      quoteVolume: 10676600.041403953,
      btcVolume: 1420.5539937358105,
      usdVolume: 10676600.041403953,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '2.2716000000000000',
      high: '2.3763000000000000',
      low: '2.2611000000000000',
      close: '2.3403000000000000',
      volume: '4132886.9400000000000000',
      quoteVolume: 9586717.671007054,
      btcVolume: 1262.8765759121409,
      usdVolume: 9586717.671007054,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '2.3403000000000000',
      high: '2.3437000000000000',
      low: '2.1943000000000000',
      close: '2.2373000000000000',
      volume: '2920487.5800000000000000',
      quoteVolume: 6606597.773422014,
      btcVolume: 864.7793427026156,
      usdVolume: 6606597.773422014,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '2.2360000000000000',
      high: '2.2385000000000000',
      low: '2.1274000000000000',
      close: '2.1638000000000000',
      volume: '3517483.4300000000000000',
      quoteVolume: 7624553.0653519705,
      btcVolume: 1036.6018203822432,
      usdVolume: 7624553.0653519705,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '2.1634000000000000',
      high: '2.1660000000000000',
      low: '2.0524000000000000',
      close: '2.0911000000000000',
      volume: '3648117.9700000000000000',
      quoteVolume: 7673977.60459799,
      btcVolume: 1052.6747095580927,
      usdVolume: 7673977.60459799,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '2.0913000000000000',
      high: '2.1891000000000000',
      low: '2.0580000000000000',
      close: '2.1222000000000000',
      volume: '3779094.5300000000000000',
      quoteVolume: 8035102.426474956,
      btcVolume: 1099.7458034935873,
      usdVolume: 8035102.426474956,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '2.1222000000000000',
      high: '2.1551000000000000',
      low: '2.0000000000000000',
      close: '2.0290000000000000',
      volume: '6418298.4400000000000000',
      quoteVolume: 13241702.282410966,
      btcVolume: 1821.4389081573547,
      usdVolume: 13241702.282410966,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '2.0282000000000000',
      high: '2.0740000000000000',
      low: '1.9583000000000000',
      close: '2.0068000000000000',
      volume: '5364919.9900000000000000',
      quoteVolume: 10777462.949475031,
      btcVolume: 1473.092445555551,
      usdVolume: 10777462.949475031,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '2.0059000000000000',
      high: '2.1042000000000000',
      low: '1.9685000000000000',
      close: '2.0943000000000000',
      volume: '3855061.0400000000000000',
      quoteVolume: 7874379.4179719705,
      btcVolume: 1063.7224341290423,
      usdVolume: 7874379.4179719705,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '2.0918000000000000',
      high: '2.1114000000000000',
      low: '2.0346000000000000',
      close: '2.0440000000000000',
      volume: '2405401.8200000000000000',
      quoteVolume: 4979102.437877952,
      btcVolume: 661.935259884129,
      usdVolume: 4979102.437877952,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '2.0402000000000000',
      high: '2.0850000000000000',
      low: '2.0012000000000000',
      close: '2.0528000000000000',
      volume: '2017582.9900000000000000',
      quoteVolume: 4126872.5763140195,
      btcVolume: 551.4016518898085,
      usdVolume: 4126872.5763140195,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '2.0520000000000000',
      high: '2.0985000000000000',
      low: '2.0151000000000000',
      close: '2.0984000000000000',
      volume: '2749805.9600000000000000',
      quoteVolume: 5635545.581415003,
      btcVolume: 756.3154499408689,
      usdVolume: 5635545.581415003,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '2.0983000000000000',
      high: '2.2862000000000000',
      low: '2.0611000000000000',
      close: '2.2657000000000000',
      volume: '10810913.6300000000000000',
      quoteVolume: 23748657.368107967,
      btcVolume: 3247.6560107514047,
      usdVolume: 23748657.368107967,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '2.2655000000000000',
      high: '2.3042000000000000',
      low: '2.1470000000000000',
      close: '2.1786000000000000',
      volume: '4814219.6800000000000000',
      quoteVolume: 10700085.694164913,
      btcVolume: 1482.272457471991,
      usdVolume: 10700085.694164913,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '2.1784000000000000',
      high: '2.1951000000000000',
      low: '2.0197000000000000',
      close: '2.1056000000000000',
      volume: '4784990.2800000000000000',
      quoteVolume: 10011804.878543014,
      btcVolume: 1397.2028682925654,
      usdVolume: 10011804.878543014,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '2.1037000000000000',
      high: '2.1210000000000000',
      low: '2.0575000000000000',
      close: '2.0709000000000000',
      volume: '1938856.4000000000000000',
      quoteVolume: 4050597.004999004,
      btcVolume: 560.2010274614569,
      usdVolume: 4050597.004999004,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '2.0713000000000000',
      high: '2.0933000000000000',
      low: '1.9700000000000000',
      close: '1.9946000000000000',
      volume: '1750170.3500000000000000',
      quoteVolume: 3553231.517961994,
      btcVolume: 497.52783996859756,
      usdVolume: 3553231.517961994,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '1.9947000000000000',
      high: '2.1168000000000000',
      low: '1.9606000000000000',
      close: '2.0708000000000000',
      volume: '2572245.4900000000000000',
      quoteVolume: 5290463.029340993,
      btcVolume: 744.7645762589991,
      usdVolume: 5290463.029340993,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '2.0722000000000000',
      high: '2.0873000000000000',
      low: '1.9670000000000000',
      close: '1.9751000000000000',
      volume: '2426967.3300000000000000',
      quoteVolume: 4880535.555653993,
      btcVolume: 697.783270194055,
      usdVolume: 4880535.555653993,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '1.9705000000000000',
      high: '2.0025000000000000',
      low: '1.7000000000000000',
      close: '1.7598000000000000',
      volume: '7314955.8200000000000000',
      quoteVolume: 13273928.880977929,
      btcVolume: 1967.0240939966445,
      usdVolume: 13273928.880977929,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '1.7594000000000000',
      high: '1.8995000000000000',
      low: '1.5996000000000000',
      close: '1.8703000000000000',
      volume: '8586632.6500000000000000',
      quoteVolume: 15238050.812313028,
      btcVolume: 2248.4745148935344,
      usdVolume: 15238050.812313028,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '1.8703000000000000',
      high: '1.8901000000000000',
      low: '1.7490000000000000',
      close: '1.8050000000000000',
      volume: '5044150.8900000000000000',
      quoteVolume: 9131480.463140937,
      btcVolume: 1276.1833159780792,
      usdVolume: 9131480.463140937,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '1.8050000000000000',
      high: '1.9238000000000000',
      low: '1.7849000000000000',
      close: '1.9182000000000000',
      volume: '3285479.3800000000000000',
      quoteVolume: 6091824.338370992,
      btcVolume: 851.739498901493,
      usdVolume: 6091824.338370992,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '1.9182000000000000',
      high: '1.9204000000000000',
      low: '1.8544000000000000',
      close: '1.8575000000000000',
      volume: '2246207.9200000000000000',
      quoteVolume: 4254205.469336003,
      btcVolume: 595.372491489374,
      usdVolume: 4254205.469336003,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '1.8563000000000000',
      high: '1.9400000000000000',
      low: '1.8530000000000000',
      close: '1.9181000000000000',
      volume: '2765867.8000000000000000',
      quoteVolume: 5280663.966873023,
      btcVolume: 724.5881359505457,
      usdVolume: 5280663.966873023,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '1.9167000000000000',
      high: '1.9532000000000000',
      low: '1.8460000000000000',
      close: '1.8629000000000000',
      volume: '2832932.7600000000000000',
      quoteVolume: 5427329.285269981,
      btcVolume: 724.3302026053892,
      usdVolume: 5427329.285269981,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '1.8638000000000000',
      high: '1.8854000000000000',
      low: '1.8154000000000000',
      close: '1.8318000000000000',
      volume: '2276138.1100000000000000',
      quoteVolume: 4206713.595653003,
      btcVolume: 575.7089237245987,
      usdVolume: 4206713.595653003,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '1.8434000000000000',
      high: '1.8462000000000000',
      low: '1.7600000000000000',
      close: '1.7826000000000000',
      volume: '1576134.1400000000000000',
      quoteVolume: 2842886.6440100074,
      btcVolume: 394.49536602127625,
      usdVolume: 2842886.6440100074,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '1.7836000000000000',
      high: '1.9604000000000000',
      low: '1.7702000000000000',
      close: '1.9460000000000000',
      volume: '2645538.2200000000000000',
      quoteVolume: 4914305.251431999,
      btcVolume: 675.865579877377,
      usdVolume: 4914305.251431999,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '1.8538000000000000',
      high: '1.9299000000000000',
      low: '1.8435000000000000',
      close: '1.8751000000000000',
      volume: '2413976.6800000000000000',
      quoteVolume: 4547744.520783,
      btcVolume: 630.9067581741584,
      usdVolume: 4547744.520783,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '1.8777000000000000',
      high: '1.8994000000000000',
      low: '1.8555000000000000',
      close: '1.8746000000000000',
      volume: '1269364.7000000000000000',
      quoteVolume: 2384012.458777003,
      btcVolume: 326.02528374878295,
      usdVolume: 2384012.458777003,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '1.8716000000000000',
      high: '1.9200000000000000',
      low: '1.8444000000000000',
      close: '1.8976000000000000',
      volume: '1411381.0500000000000000',
      quoteVolume: 2639536.610925001,
      btcVolume: 357.41364249988766,
      usdVolume: 2639536.610925001,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '1.8980000000000000',
      high: '1.8980000000000000',
      low: '1.8072000000000000',
      close: '1.8243000000000000',
      volume: '1099037.9900000000000000',
      quoteVolume: 2036388.7939540006,
      btcVolume: 278.66897959056115,
      usdVolume: 2036388.7939540006,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '1.8215000000000000',
      high: '1.8292000000000000',
      low: '1.7540000000000000',
      close: '1.7673000000000000',
      volume: '1637635.2800000000000000',
      quoteVolume: 2932476.277038998,
      btcVolume: 406.1371342093213,
      usdVolume: 2932476.277038998,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '1.7678000000000000',
      high: '1.8548000000000000',
      low: '1.7606000000000000',
      close: '1.8111000000000000',
      volume: '1158740.3200000000000000',
      quoteVolume: 2106240.113113006,
      btcVolume: 291.7435681061432,
      usdVolume: 2106240.113113006,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '1.8109000000000000',
      high: '1.8164000000000000',
      low: '1.7065000000000000',
      close: '1.7366000000000000',
      volume: '1346757.5400000000000000',
      quoteVolume: 2370258.0493380134,
      btcVolume: 336.50922976459,
      usdVolume: 2370258.0493380134,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '1.7366000000000000',
      high: '1.8292000000000000',
      low: '1.7013000000000000',
      close: '1.8040000000000000',
      volume: '1782059.3300000000000000',
      quoteVolume: 3173528.806004989,
      btcVolume: 440.5557916213236,
      usdVolume: 3173528.806004989,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '1.8030000000000000',
      high: '1.8446000000000000',
      low: '1.7840000000000000',
      close: '1.8242000000000000',
      volume: '1241322.8100000000000000',
      quoteVolume: 2252857.6365430057,
      btcVolume: 307.3651298252609,
      usdVolume: 2252857.6365430057,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '1.8243000000000000',
      high: '1.8413000000000000',
      low: '1.7850000000000000',
      close: '1.7957000000000000',
      volume: '1524603.4000000000000000',
      quoteVolume: 2771904.605593008,
      btcVolume: 372.70606476580207,
      usdVolume: 2771904.605593008,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '1.7954000000000000',
      high: '1.9386000000000000',
      low: '1.7925000000000000',
      close: '1.9374000000000000',
      volume: '3301230.5100000000000000',
      quoteVolume: 6219983.016896969,
      btcVolume: 822.3795323204727,
      usdVolume: 6219983.016896969,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '1.9373000000000000',
      high: '2.1398000000000000',
      low: '1.8993000000000000',
      close: '2.1033000000000000',
      volume: '6873503.3900000000000000',
      quoteVolume: 14001167.440691043,
      btcVolume: 1765.0859844694687,
      usdVolume: 14001167.440691043,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '2.1006000000000000',
      high: '2.3495000000000000',
      low: '2.1006000000000000',
      close: '2.1910000000000000',
      volume: '10645081.3000000000000000',
      quoteVolume: 23671432.343011014,
      btcVolume: 2869.5091074200345,
      usdVolume: 23671432.343011014,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '2.1842000000000000',
      high: '2.2344000000000000',
      low: '2.0977000000000000',
      close: '2.1886000000000000',
      volume: '4880978.6700000000000000',
      quoteVolume: 10564559.54597592,
      btcVolume: 1336.7758650978221,
      usdVolume: 10564559.54597592,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '2.1867000000000000',
      high: '2.2489000000000000',
      low: '2.1455000000000000',
      close: '2.2257000000000000',
      volume: '4235446.6200000000000000',
      quoteVolume: 9348313.457428945,
      btcVolume: 1187.4445809136419,
      usdVolume: 9348313.457428945,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '2.2255000000000000',
      high: '2.3450000000000000',
      low: '2.2180000000000000',
      close: '2.2734000000000000',
      volume: '4346405.0600000000000000',
      quoteVolume: 9936058.411543818,
      btcVolume: 1222.4125100446236,
      usdVolume: 9936058.411543818,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '2.2730000000000000',
      high: '2.3212000000000000',
      low: '2.2120000000000000',
      close: '2.2383000000000000',
      volume: '2499405.7900000000000000',
      quoteVolume: 5647655.962262982,
      btcVolume: 695.833466287728,
      usdVolume: 5647655.962262982,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '2.2382000000000000',
      high: '2.2382000000000000',
      low: '2.1561000000000000',
      close: '2.1901000000000000',
      volume: '1952305.6900000000000000',
      quoteVolume: 4261648.208497995,
      btcVolume: 525.0222154958136,
      usdVolume: 4261648.208497995,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '2.1904000000000000',
      high: '2.4701000000000000',
      low: '2.1792000000000000',
      close: '2.3751000000000000',
      volume: '7058803.5000000000000000',
      quoteVolume: 16487477.721062977,
      btcVolume: 1910.6120911212884,
      usdVolume: 16487477.721062977,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '2.3751000000000000',
      high: '2.5604000000000000',
      low: '2.3332000000000000',
      close: '2.3953000000000000',
      volume: '6388121.6800000000000000',
      quoteVolume: 15661576.690633962,
      btcVolume: 1788.326833421823,
      usdVolume: 15661576.690633962,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '2.3991000000000000',
      high: '2.5100000000000000',
      low: '2.2932000000000000',
      close: '2.4925000000000000',
      volume: '4937240.5300000000000000',
      quoteVolume: 11942842.296321075,
      btcVolume: 1374.9026425662325,
      usdVolume: 11942842.296321075,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '2.4924000000000000',
      high: '2.8600000000000000',
      low: '2.4589000000000000',
      close: '2.7006000000000000',
      volume: '12107831.2900000000000000',
      quoteVolume: 32609325.00067248,
      btcVolume: 3674.588483290462,
      usdVolume: 32609325.00067248,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '2.7006000000000000',
      high: '2.7946000000000000',
      low: '2.5800000000000000',
      close: '2.7709000000000000',
      volume: '4454822.8800000000000000',
      quoteVolume: 12057457.05895922,
      btcVolume: 1354.3595299323777,
      usdVolume: 12057457.05895922,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '2.7749000000000000',
      high: '2.8475000000000000',
      low: '2.5385000000000000',
      close: '2.6212000000000000',
      volume: '6634722.1700000000000000',
      quoteVolume: 17678886.450535074,
      btcVolume: 2012.0756253195111,
      usdVolume: 17678886.450535074,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '2.6207000000000000',
      high: '2.7443000000000000',
      low: '2.5425000000000000',
      close: '2.6880000000000000',
      volume: '4142689.5300000000000000',
      quoteVolume: 10966736.422782997,
      btcVolume: 1266.964503736621,
      usdVolume: 10966736.422782997,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '2.6881000000000000',
      high: '2.7440000000000000',
      low: '2.5700000000000000',
      close: '2.6716000000000000',
      volume: '3244654.0900000000000000',
      quoteVolume: 8677468.39766201,
      btcVolume: 1002.1855751352247,
      usdVolume: 8677468.39766201,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '2.6724000000000000',
      high: '2.6978000000000000',
      low: '2.6187000000000000',
      close: '2.6426000000000000',
      volume: '1637205.0200000000000000',
      quoteVolume: 4351550.913769996,
      btcVolume: 500.3133383092309,
      usdVolume: 4351550.913769996,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '2.6425000000000000',
      high: '2.6470000000000000',
      low: '2.4256000000000000',
      close: '2.4698000000000000',
      volume: '4806803.2100000000000000',
      quoteVolume: 11989939.300899168,
      btcVolume: 1413.7383885194763,
      usdVolume: 11989939.300899168,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '2.4695000000000000',
      high: '2.5330000000000000',
      low: '2.3638000000000000',
      close: '2.5024000000000000',
      volume: '4161651.9700000000000000',
      quoteVolume: 10232694.793375008,
      btcVolume: 1220.4855118581247,
      usdVolume: 10232694.793375008,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '2.5024000000000000',
      high: '2.5100000000000000',
      low: '2.4210000000000000',
      close: '2.4468000000000000',
      volume: '1881630.1800000000000000',
      quoteVolume: 4630337.163306008,
      btcVolume: 555.0830281021886,
      usdVolume: 4630337.163306008,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '2.4425000000000000',
      high: '2.5764000000000000',
      low: '2.4266000000000000',
      close: '2.5764000000000000',
      volume: '1936836.4700000000000000',
      quoteVolume: 4876802.3327080235,
      btcVolume: 574.1477799412621,
      usdVolume: 4876802.3327080235,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '2.5764000000000000',
      high: '2.6764000000000000',
      low: '2.5656000000000000',
      close: '2.6055000000000000',
      volume: '3426087.6500000000000000',
      quoteVolume: 8983048.130742965,
      btcVolume: 1025.861592856912,
      usdVolume: 8983048.130742965,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '2.6051000000000000',
      high: '2.6700000000000000',
      low: '2.5868000000000000',
      close: '2.6558000000000000',
      volume: '3113588.1700000000000000',
      quoteVolume: 8190994.708914968,
      btcVolume: 901.4969362742667,
      usdVolume: 8190994.708914968,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '2.6544000000000000',
      high: '2.8348000000000000',
      low: '2.6316000000000000',
      close: '2.7773000000000000',
      volume: '5191102.2600000000000000',
      quoteVolume: 14210741.277650112,
      btcVolume: 1519.9493554481853,
      usdVolume: 14210741.277650112,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '2.7785000000000000',
      high: '2.9482000000000000',
      low: '2.7167000000000000',
      close: '2.9156000000000000',
      volume: '5780750.0200000000000000',
      quoteVolume: 16529966.221006121,
      btcVolume: 1760.8623301714779,
      usdVolume: 16529966.221006121,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '2.9120000000000000',
      high: '2.9153000000000000',
      low: '2.7628000000000000',
      close: '2.8259000000000000',
      volume: '3910431.2100000000000000',
      quoteVolume: 11114615.65423999,
      btcVolume: 1189.571698179413,
      usdVolume: 11114615.65423999,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '2.8258000000000000',
      high: '2.8800000000000000',
      low: '2.7438000000000000',
      close: '2.8378000000000000',
      volume: '2737541.7700000000000000',
      quoteVolume: 7681755.743857064,
      btcVolume: 819.739572372717,
      usdVolume: 7681755.743857064,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '2.8372000000000000',
      high: '2.8869000000000000',
      low: '2.7314000000000000',
      close: '2.8267000000000000',
      volume: '3442889.5900000000000000',
      quoteVolume: 9768511.051874014,
      btcVolume: 1040.2867368472987,
      usdVolume: 9768511.051874014,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '2.8225000000000000',
      high: '2.8790000000000000',
      low: '2.7451000000000000',
      close: '2.7710000000000000',
      volume: '3106758.8600000000000000',
      quoteVolume: 8691569.65454806,
      btcVolume: 929.8882901515204,
      usdVolume: 8691569.65454806,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '2.7713000000000000',
      high: '2.8038000000000000',
      low: '2.6751000000000000',
      close: '2.7395000000000000',
      volume: '4491247.5800000000000000',
      quoteVolume: 12261241.924308985,
      btcVolume: 1328.2200602977618,
      usdVolume: 12261241.924308985,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '2.7397000000000000',
      high: '2.8845000000000000',
      low: '2.7261000000000000',
      close: '2.8390000000000000',
      volume: '4267689.2200000000000000',
      quoteVolume: 12053160.781550005,
      btcVolume: 1276.385904166067,
      usdVolume: 12053160.781550005,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '2.8360000000000000',
      high: '2.9436000000000000',
      low: '2.7900000000000000',
      close: '2.8900000000000000',
      volume: '4131243.5200000000000000',
      quoteVolume: 11910422.487068068,
      btcVolume: 1227.3627511910402,
      usdVolume: 11910422.487068068,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '2.8900000000000000',
      high: '3.3682000000000000',
      low: '2.8882000000000000',
      close: '3.3072000000000000',
      volume: '14813365.3600000000000000',
      quoteVolume: 47449282.522496335,
      btcVolume: 4846.042169836303,
      usdVolume: 47449282.522496335,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '3.3071000000000000',
      high: '3.4865000000000000',
      low: '3.1310000000000000',
      close: '3.4171000000000000',
      volume: '10059760.5500000000000000',
      quoteVolume: 33442719.414118227,
      btcVolume: 3409.4308561389403,
      usdVolume: 33442719.414118227,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '3.4179000000000000',
      high: '3.4984000000000000',
      low: '3.2330000000000000',
      close: '3.4945000000000000',
      volume: '6056067.2700000000000000',
      quoteVolume: 20336735.107043743,
      btcVolume: 2016.569351190022,
      usdVolume: 20336735.107043743,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '3.4927000000000000',
      high: '3.4972000000000000',
      low: '3.2806000000000000',
      close: '3.3862000000000000',
      volume: '6758374.3500000000000000',
      quoteVolume: 22873780.175180133,
      btcVolume: 2303.5567499558065,
      usdVolume: 22873780.175180133,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '3.3874000000000000',
      high: '3.9964000000000000',
      low: '3.3500000000000000',
      close: '3.9931000000000000',
      volume: '11780106.0700000000000000',
      quoteVolume: 43237892.69208104,
      btcVolume: 4336.995754427936,
      usdVolume: 43237892.69208104,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '3.9937000000000000',
      high: '4.2000000000000000',
      low: '3.8472000000000000',
      close: '3.9953000000000000',
      volume: '13673147.3100000000000000',
      quoteVolume: 55003441.64790517,
      btcVolume: 5332.8082573628735,
      usdVolume: 55003441.64790517,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '3.9930000000000000',
      high: '4.1147000000000000',
      low: '3.7123000000000000',
      close: '3.8765000000000000',
      volume: '8191206.1300000000000000',
      quoteVolume: 32257042.692259546,
      btcVolume: 3140.1527250738345,
      usdVolume: 32257042.692259546,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '3.8753000000000000',
      high: '4.4870000000000000',
      low: '3.7873000000000000',
      close: '4.4260000000000000',
      volume: '10369344.9500000000000000',
      quoteVolume: 43468717.18347415,
      btcVolume: 4231.988614585208,
      usdVolume: 43468717.18347415,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '4.4262000000000000',
      high: '4.6210000000000000',
      low: '4.0650000000000000',
      close: '4.3241000000000000',
      volume: '15573115.1200000000000000',
      quoteVolume: 68355374.03477696,
      btcVolume: 6799.603705176355,
      usdVolume: 68355374.03477696,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '4.3215000000000000',
      high: '4.8671000000000000',
      low: '4.0384000000000000',
      close: '4.4566000000000000',
      volume: '22171359.6900000000000000',
      quoteVolume: 99672983.28316268,
      btcVolume: 10084.607252387823,
      usdVolume: 99672983.28316268,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '4.4563000000000000',
      high: '4.5522000000000000',
      low: '3.8449000000000000',
      close: '4.3108000000000000',
      volume: '19343142.2500000000000000',
      quoteVolume: 80859272.12602058,
      btcVolume: 8343.69697549989,
      usdVolume: 80859272.12602058,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '4.3059000000000000',
      high: '4.6360000000000000',
      low: '4.1416000000000000',
      close: '4.5523000000000000',
      volume: '11931206.5700000000000000',
      quoteVolume: 52870430.99099803,
      btcVolume: 5385.401313218787,
      usdVolume: 52870430.99099803,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '4.5539000000000000',
      high: '4.8355000000000000',
      low: '4.1930000000000000',
      close: '4.3873000000000000',
      volume: '10055681.7000000000000000',
      quoteVolume: 46162309.631008886,
      btcVolume: 4610.077023153315,
      usdVolume: 46162309.631008886,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '4.3840000000000000',
      high: '4.4865000000000000',
      low: '4.0673000000000000',
      close: '4.2644000000000000',
      volume: '11463128.1100000000000000',
      quoteVolume: 48921964.38781903,
      btcVolume: 5107.005216621635,
      usdVolume: 48921964.38781903,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '4.2666000000000000',
      high: '4.5127000000000000',
      low: '4.1840000000000000',
      close: '4.2778000000000000',
      volume: '7706651.0700000000000000',
      quoteVolume: 33506855.339449476,
      btcVolume: 3462.076686554174,
      usdVolume: 33506855.339449476,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '4.2789000000000000',
      high: '4.3500000000000000',
      low: '4.0332000000000000',
      close: '4.1066000000000000',
      volume: '6716454.0200000000000000',
      quoteVolume: 27976312.64533768,
      btcVolume: 2902.199389115639,
      usdVolume: 27976312.64533768,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '4.1006000000000000',
      high: '4.3768000000000000',
      low: '4.0693000000000000',
      close: '4.2365000000000000',
      volume: '5642974.2100000000000000',
      quoteVolume: 23992021.76366039,
      btcVolume: 2434.114086850125,
      usdVolume: 23992021.76366039,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '4.2366000000000000',
      high: '4.2871000000000000',
      low: '3.7916000000000000',
      close: '3.9148000000000000',
      volume: '8591286.0700000000000000',
      quoteVolume: 34393400.24605992,
      btcVolume: 3548.267172458539,
      usdVolume: 34393400.24605992,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '3.9179000000000000',
      high: '3.9600000000000000',
      low: '3.4864000000000000',
      close: '3.4905000000000000',
      volume: '12394531.5200000000000000',
      quoteVolume: 45560015.71143371,
      btcVolume: 4810.153110343996,
      usdVolume: 45560015.71143371,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '3.4874000000000000',
      high: '3.8587000000000000',
      low: '3.3113000000000000',
      close: '3.5648000000000000',
      volume: '22177330.0200000000000000',
      quoteVolume: 79010843.38455322,
      btcVolume: 8784.642820650577,
      usdVolume: 79010843.38455322,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '3.5648000000000000',
      high: '4.1320000000000000',
      low: '3.4197000000000000',
      close: '3.9579000000000000',
      volume: '19080853.8700000000000000',
      quoteVolume: 73041636.71234092,
      btcVolume: 8290.177225043033,
      usdVolume: 73041636.71234092,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '3.9568000000000000',
      high: '4.2395000000000000',
      low: '3.7500000000000000',
      close: '4.1609000000000000',
      volume: '18251889.1100000000000000',
      quoteVolume: 72768936.34054644,
      btcVolume: 8381.737217549813,
      usdVolume: 72768936.34054644,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '4.1614000000000000',
      high: '4.3535000000000000',
      low: '4.0572000000000000',
      close: '4.0579000000000000',
      volume: '12433586.3300000000000000',
      quoteVolume: 52447455.00483184,
      btcVolume: 6050.453008344912,
      usdVolume: 52447455.00483184,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '4.0600000000000000',
      high: '4.2385000000000000',
      low: '3.7607000000000000',
      close: '3.8590000000000000',
      volume: '14748523.0700000000000000',
      quoteVolume: 58869388.27488089,
      btcVolume: 6880.090250435741,
      usdVolume: 58869388.27488089,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '3.8622000000000000',
      high: '4.3435000000000000',
      low: '3.8173000000000000',
      close: '4.2423000000000000',
      volume: '12656156.0500000000000000',
      quoteVolume: 52493228.92320553,
      btcVolume: 5994.207586648094,
      usdVolume: 52493228.92320553,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '4.2421000000000000',
      high: '4.6800000000000000',
      low: '4.1249000000000000',
      close: '4.5846000000000000',
      volume: '17189323.0000000000000000',
      quoteVolume: 75145836.87198897,
      btcVolume: 8560.826818400581,
      usdVolume: 75145836.87198897,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '4.5873000000000000',
      high: '4.9762000000000000',
      low: '4.5333000000000000',
      close: '4.6406000000000000',
      volume: '18623299.5100000000000000',
      quoteVolume: 88271977.43112102,
      btcVolume: 10087.310040122788,
      usdVolume: 88271977.43112102,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '4.6388000000000000',
      high: '4.8990000000000000',
      low: '4.6239000000000000',
      close: '4.7151000000000000',
      volume: '11845630.6300000000000000',
      quoteVolume: 56401786.5002544,
      btcVolume: 6263.948735289506,
      usdVolume: 56401786.5002544,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '4.7144000000000000',
      high: '4.7800000000000000',
      low: '4.5429000000000000',
      close: '4.6814000000000000',
      volume: '7645180.7400000000000000',
      quoteVolume: 35490114.51378227,
      btcVolume: 3908.2548527927784,
      usdVolume: 35490114.51378227,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '4.6813000000000000',
      high: '4.6863000000000000',
      low: '4.3143000000000000',
      close: '4.3412000000000000',
      volume: '8209506.8500000000000000',
      quoteVolume: 37077348.43982905,
      btcVolume: 4113.020563862099,
      usdVolume: 37077348.43982905,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '4.3468000000000000',
      high: '4.4646000000000000',
      low: '3.8500000000000000',
      close: '4.0537000000000000',
      volume: '18324667.2600000000000000',
      quoteVolume: 76704000.26084687,
      btcVolume: 9085.343101448982,
      usdVolume: 76704000.26084687,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '4.0537000000000000',
      high: '4.3690000000000000',
      low: '3.6012000000000000',
      close: '4.1231000000000000',
      volume: '29042929.6300000000000000',
      quoteVolume: 116996235.58194844,
      btcVolume: 14910.78468436395,
      usdVolume: 116996235.58194844,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '4.1237000000000000',
      high: '4.2119000000000000',
      low: '3.9132000000000000',
      close: '4.0839000000000000',
      volume: '18071323.5600000000000000',
      quoteVolume: 73423955.05481032,
      btcVolume: 9232.548450091543,
      usdVolume: 73423955.05481032,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '4.0842000000000000',
      high: '4.1100000000000000',
      low: '3.5514000000000000',
      close: '3.8169000000000000',
      volume: '19407327.4000000000000000',
      quoteVolume: 74033713.87227552,
      btcVolume: 9483.288672404144,
      usdVolume: 74033713.87227552,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '3.8170000000000000',
      high: '3.8519000000000000',
      low: '0.0001000000000000',
      close: '1.9931000000000000',
      volume: '67738097.2700000000000000',
      quoteVolume: 177673762.49713,
      btcVolume: 27866.237147761774,
      usdVolume: 177673762.49713,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '1.9990000000000000',
      high: '2.7520000000000000',
      low: '1.3600000000000000',
      close: '2.4333000000000000',
      volume: '69599335.5100000000000000',
      quoteVolume: 150523398.38686204,
      btcVolume: 29105.657242605583,
      usdVolume: 150523398.38686204,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '2.4328000000000000',
      high: '2.5141000000000000',
      low: '2.0607000000000000',
      close: '2.1379000000000000',
      volume: '21569351.2700000000000000',
      quoteVolume: 49487604.75409475,
      btcVolume: 9228.82980590662,
      usdVolume: 49487604.75409475,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '2.1401000000000000',
      high: '2.4000000000000000',
      low: '2.0150000000000000',
      close: '2.0884000000000000',
      volume: '24056556.5000000000000000',
      quoteVolume: 51979784.30551739,
      btcVolume: 9676.317809668104,
      usdVolume: 51979784.30551739,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '2.0868000000000000',
      high: '2.0898000000000000',
      low: '1.5571000000000000',
      close: '1.7832000000000000',
      volume: '47175196.2700000000000000',
      quoteVolume: 83730824.5977933,
      btcVolume: 16985.606488035726,
      usdVolume: 83730824.5977933,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '1.7832000000000000',
      high: '2.0690000000000000',
      low: '1.7300000000000000',
      close: '1.8641000000000000',
      volume: '32839078.6800000000000000',
      quoteVolume: 63607268.71138073,
      btcVolume: 12035.406899066662,
      usdVolume: 63607268.71138073,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '1.8660000000000000',
      high: '1.9543000000000000',
      low: '1.7620000000000000',
      close: '1.9483000000000000',
      volume: '22298409.7900000000000000',
      quoteVolume: 41588758.50010696,
      btcVolume: 7903.516934502976,
      usdVolume: 41588758.50010696,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '1.9497000000000000',
      high: '2.3700000000000000',
      low: '1.8841000000000000',
      close: '2.2398000000000000',
      volume: '33319846.3700000000000000',
      quoteVolume: 71956024.81635506,
      btcVolume: 12300.451552067118,
      usdVolume: 71956024.81635506,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '2.2409000000000000',
      high: '2.6249000000000000',
      low: '2.0143000000000000',
      close: '2.2600000000000000',
      volume: '39390658.8700000000000000',
      quoteVolume: 92950604.2035976,
      btcVolume: 14562.188219917904,
      usdVolume: 92950604.2035976,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '2.2631000000000000',
      high: '2.3750000000000000',
      low: '2.1050000000000000',
      close: '2.2787000000000000',
      volume: '19471583.8400000000000000',
      quoteVolume: 43519402.05460915,
      btcVolume: 7045.261058540346,
      usdVolume: 43519402.05460915,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '2.2754000000000000',
      high: '2.3300000000000000',
      low: '1.9334000000000000',
      close: '1.9898000000000000',
      volume: '21634031.6900000000000000',
      quoteVolume: 45651954.71691129,
      btcVolume: 7551.212589732256,
      usdVolume: 45651954.71691129,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '1.9889000000000000',
      high: '2.3000000000000000',
      low: '1.9352000000000000',
      close: '2.2468000000000000',
      volume: '23672246.4900000000000000',
      quoteVolume: 50076329.17784201,
      btcVolume: 8112.687771095752,
      usdVolume: 50076329.17784201,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '2.2470000000000000',
      high: '2.3709000000000000',
      low: '2.1950000000000000',
      close: '2.3104000000000000',
      volume: '18400021.3200000000000000',
      quoteVolume: 42125779.98805816,
      btcVolume: 6347.98486825512,
      usdVolume: 42125779.98805816,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '2.3109000000000000',
      high: '2.3500000000000000',
      low: '2.1804000000000000',
      close: '2.2570000000000000',
      volume: '11321064.3000000000000000',
      quoteVolume: 25603393.54787736,
      btcVolume: 3847.290256854321,
      usdVolume: 25603393.54787736,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '2.2569000000000000',
      high: '2.3132000000000000',
      low: '2.1884000000000000',
      close: '2.3132000000000000',
      volume: '6803251.3800000000000000',
      quoteVolume: 15346687.775324736,
      btcVolume: 2303.7934993235885,
      usdVolume: 15346687.775324736,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '2.3125000000000000',
      high: '2.3599000000000000',
      low: '2.1055000000000000',
      close: '2.1373000000000000',
      volume: '8151561.2000000000000000',
      quoteVolume: 18458235.87850203,
      btcVolume: 2776.0706097775064,
      usdVolume: 18458235.87850203,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '2.1372000000000000',
      high: '2.1544000000000000',
      low: '2.0372000000000000',
      close: '2.1364000000000000',
      volume: '11036739.0800000000000000',
      quoteVolume: 23162230.482438084,
      btcVolume: 3726.428487730779,
      usdVolume: 23162230.482438084,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '2.1356000000000000',
      high: '2.1520000000000000',
      low: '2.0070000000000000',
      close: '2.0083000000000000',
      volume: '6879303.8400000000000000',
      quoteVolume: 14309647.633855054,
      btcVolume: 2356.8649141943242,
      usdVolume: 14309647.633855054,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '2.0083000000000000',
      high: '2.2000000000000000',
      low: '2.0006000000000000',
      close: '2.1350000000000000',
      volume: '7970226.4900000000000000',
      quoteVolume: 16914779.735236812,
      btcVolume: 2698.6373186550286,
      usdVolume: 16914779.735236812,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '2.1348000000000000',
      high: '2.3020000000000000',
      low: '2.1102000000000000',
      close: '2.2658000000000000',
      volume: '8585088.2100000000000000',
      quoteVolume: 18841402.98135908,
      btcVolume: 2922.5256630907675,
      usdVolume: 18841402.98135908,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '2.2657000000000000',
      high: '2.2999000000000000',
      low: '2.1400000000000000',
      close: '2.2753000000000000',
      volume: '13239732.4400000000000000',
      quoteVolume: 29469066.54972005,
      btcVolume: 4669.983786159829,
      usdVolume: 29469066.54972005,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '2.2764000000000000',
      high: '2.4850000000000000',
      low: '2.2566000000000000',
      close: '2.2941000000000000',
      volume: '13266669.3900000000000000',
      quoteVolume: 30798141.032473035,
      btcVolume: 4540.6089277905485,
      usdVolume: 30798141.032473035,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '2.2940000000000000',
      high: '2.3837000000000000',
      low: '2.2652000000000000',
      close: '2.3013000000000000',
      volume: '8452189.4500000000000000',
      quoteVolume: 19628894.276646227,
      btcVolume: 2880.3710594063255,
      usdVolume: 19628894.276646227,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '2.2995000000000000',
      high: '2.3423000000000000',
      low: '2.2545000000000000',
      close: '2.3155000000000000',
      volume: '6117486.0100000000000000',
      quoteVolume: 14001543.18994617,
      btcVolume: 2067.6146389867217,
      usdVolume: 14001543.18994617,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '2.3155000000000000',
      high: '2.3219000000000000',
      low: '2.2437000000000000',
      close: '2.2635000000000000',
      volume: '3228659.2300000000000000',
      quoteVolume: 7361336.519118009,
      btcVolume: 1085.637636025333,
      usdVolume: 7361336.519118009,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '2.2638000000000000',
      high: '2.5677000000000000',
      low: '2.2621000000000000',
      close: '2.5555000000000000',
      volume: '12222925.8100000000000000',
      quoteVolume: 29594871.538146637,
      btcVolume: 4150.693258507703,
      usdVolume: 29594871.538146637,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '2.5556000000000000',
      high: '2.9080000000000000',
      low: '2.5550000000000000',
      close: '2.7303000000000000',
      volume: '30354976.5100000000000000',
      quoteVolume: 83149479.68281008,
      btcVolume: 11365.108305697015,
      usdVolume: 83149479.68281008,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '2.7295000000000000',
      high: '3.2748000000000000',
      low: '2.7124000000000000',
      close: '3.1693000000000000',
      volume: '27092993.2400000000000000',
      quoteVolume: 80619866.26421164,
      btcVolume: 11021.04111756064,
      usdVolume: 80619866.26421164,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '3.1695000000000000',
      high: '3.4741000000000000',
      low: '3.1224000000000000',
      close: '3.3099000000000000',
      volume: '36999763.5100000000000000',
      quoteVolume: 122751896.42489386,
      btcVolume: 16839.37173441571,
      usdVolume: 122751896.42489386,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '3.3099000000000000',
      high: '3.3199000000000000',
      low: '2.9450000000000000',
      close: '3.2200000000000000',
      volume: '43621490.6000000000000000',
      quoteVolume: 136026639.49936587,
      btcVolume: 19554.197753884662,
      usdVolume: 136026639.49936587,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '3.2207000000000000',
      high: '3.4364000000000000',
      low: '3.0629000000000000',
      close: '3.2900000000000000',
      volume: '30471997.5000000000000000',
      quoteVolume: 99435616.49901348,
      btcVolume: 14491.434066489905,
      usdVolume: 99435616.49901348,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '3.2912000000000000',
      high: '3.6412000000000000',
      low: '3.1670000000000000',
      close: '3.4189000000000000',
      volume: '32589266.7900000000000000',
      quoteVolume: 111420529.95980085,
      btcVolume: 15946.446334706492,
      usdVolume: 111420529.95980085,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '3.4117000000000000',
      high: '3.4602000000000000',
      low: '3.2000000000000000',
      close: '3.3702000000000000',
      volume: '29488744.4800000000000000',
      quoteVolume: 99093977.11353326,
      btcVolume: 14706.279591907658,
      usdVolume: 99093977.11353326,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '3.3719000000000000',
      high: '3.4437000000000000',
      low: '3.2100000000000000',
      close: '3.2520000000000000',
      volume: '17078827.0100000000000000',
      quoteVolume: 57101788.37653568,
      btcVolume: 8313.477053450417,
      usdVolume: 57101788.37653568,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '3.2517000000000000',
      high: '3.3061000000000000',
      low: '3.1085000000000000',
      close: '3.1212000000000000',
      volume: '15635873.1100000000000000',
      quoteVolume: 50161624.541744925,
      btcVolume: 7410.73145926265,
      usdVolume: 50161624.541744925,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '3.1212000000000000',
      high: '3.5555000000000000',
      low: '2.9761000000000000',
      close: '3.4851000000000000',
      volume: '24817961.6500000000000000',
      quoteVolume: 81880780.97622721,
      btcVolume: 11818.013830683783,
      usdVolume: 81880780.97622721,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '3.4831000000000000',
      high: '3.5250000000000000',
      low: '3.3778000000000000',
      close: '3.4523000000000000',
      volume: '12669277.2500000000000000',
      quoteVolume: 43776483.22340796,
      btcVolume: 6193.887862135089,
      usdVolume: 43776483.22340796,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '3.4525000000000000',
      high: '3.8300000000000000',
      low: '3.4470000000000000',
      close: '3.7805000000000000',
      volume: '16363948.9700000000000000',
      quoteVolume: 59614221.39959253,
      btcVolume: 8310.66795040822,
      usdVolume: 59614221.39959253,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '3.7814000000000000',
      high: '3.8102000000000000',
      low: '3.5131000000000000',
      close: '3.5902000000000000',
      volume: '16350496.0200000000000000',
      quoteVolume: 59872603.95570334,
      btcVolume: 8367.38354342493,
      usdVolume: 59872603.95570334,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '3.5885000000000000',
      high: '3.7920000000000000',
      low: '3.3720000000000000',
      close: '3.4322000000000000',
      volume: '20550987.6400000000000000',
      quoteVolume: 73812197.67135006,
      btcVolume: 10555.803904890008,
      usdVolume: 73812197.67135006,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '3.4317000000000000',
      high: '3.5719000000000000',
      low: '3.3903000000000000',
      close: '3.4577000000000000',
      volume: '13684724.9900000000000000',
      quoteVolume: 47685948.13373961,
      btcVolume: 6958.109221813893,
      usdVolume: 47685948.13373961,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '3.4593000000000000',
      high: '3.6904000000000000',
      low: '3.4327000000000000',
      close: '3.6786000000000000',
      volume: '11762633.8300000000000000',
      quoteVolume: 42349673.69567189,
      btcVolume: 6054.788459746665,
      usdVolume: 42349673.69567189,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '3.6787000000000000',
      high: '3.9101000000000000',
      low: '3.5968000000000000',
      close: '3.7533000000000000',
      volume: '14126638.7900000000000000',
      quoteVolume: 52991673.114868954,
      btcVolume: 7220.341851272833,
      usdVolume: 52991673.114868954,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '3.7551000000000000',
      high: '3.8874000000000000',
      low: '3.7364000000000000',
      close: '3.7735000000000000',
      volume: '7933872.6600000000000000',
      quoteVolume: 30294109.77665413,
      btcVolume: 4032.0422494074355,
      usdVolume: 30294109.77665413,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '3.7735000000000000',
      high: '3.9062000000000000',
      low: '3.7420000000000000',
      close: '3.7865000000000000',
      volume: '6095069.3800000000000000',
      quoteVolume: 23261065.041254092,
      btcVolume: 3081.0533832217207,
      usdVolume: 23261065.041254092,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '3.7865000000000000',
      high: '3.8160000000000000',
      low: '3.6432000000000000',
      close: '3.7210000000000000',
      volume: '8246658.1300000000000000',
      quoteVolume: 30808734.632196084,
      btcVolume: 4057.4729534536946,
      usdVolume: 30808734.632196084,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '3.7212000000000000',
      high: '3.7385000000000000',
      low: '3.4621000000000000',
      close: '3.6383000000000000',
      volume: '11371929.8600000000000000',
      quoteVolume: 40891564.50235291,
      btcVolume: 5313.060933220702,
      usdVolume: 40891564.50235291,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '3.6373000000000000',
      high: '3.7354000000000000',
      low: '3.5554000000000000',
      close: '3.6413000000000000',
      volume: '7379317.2300000000000000',
      quoteVolume: 26928714.47205683,
      btcVolume: 3488.09599283931,
      usdVolume: 26928714.47205683,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '3.6422000000000000',
      high: '3.9918000000000000',
      low: '3.6300000000000000',
      close: '3.8879000000000000',
      volume: '15125283.5400000000000000',
      quoteVolume: 57370181.28630435,
      btcVolume: 6832.774464426143,
      usdVolume: 57370181.28630435,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '3.8881000000000000',
      high: '3.9741000000000000',
      low: '3.6200000000000000',
      close: '3.7025000000000000',
      volume: '13545136.0200000000000000',
      quoteVolume: 51376800.29429678,
      btcVolume: 5766.507587671276,
      usdVolume: 51376800.29429678,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '3.7014000000000000',
      high: '3.9162000000000000',
      low: '3.7003000000000000',
      close: '3.7571000000000000',
      volume: '8084328.3600000000000000',
      quoteVolume: 30928293.222303115,
      btcVolume: 3519.057180538519,
      usdVolume: 30928293.222303115,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '3.7571000000000000',
      high: '3.8600000000000000',
      low: '3.7300000000000000',
      close: '3.8182000000000000',
      volume: '4467208.0100000000000000',
      quoteVolume: 16996396.923116993,
      btcVolume: 1915.6064803541883,
      usdVolume: 16996396.923116993,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '3.8186000000000000',
      high: '3.8600000000000000',
      low: '3.6379000000000000',
      close: '3.7092000000000000',
      volume: '5554731.5200000000000000',
      quoteVolume: 20831570.33686512,
      btcVolume: 2325.320369499448,
      usdVolume: 20831570.33686512,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '3.7098000000000000',
      high: '3.8272000000000000',
      low: '3.5033000000000000',
      close: '3.7499000000000000',
      volume: '10973722.2700000000000000',
      quoteVolume: 40395738.452246346,
      btcVolume: 4611.440780167115,
      usdVolume: 40395738.452246346,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '3.7496000000000000',
      high: '3.8140000000000000',
      low: '3.6540000000000000',
      close: '3.6833000000000000',
      volume: '4730504.5800000000000000',
      quoteVolume: 17621530.537070103,
      btcVolume: 1975.2075137716056,
      usdVolume: 17621530.537070103,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '3.6820000000000000',
      high: '3.7654000000000000',
      low: '3.5700000000000000',
      close: '3.5872000000000000',
      volume: '5299802.5900000000000000',
      quoteVolume: 19570134.054998033,
      btcVolume: 2138.6881860809644,
      usdVolume: 19570134.054998033,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '3.5874000000000000',
      high: '3.8500000000000000',
      low: '3.5633000000000000',
      close: '3.7560000000000000',
      volume: '8481271.5900000000000000',
      quoteVolume: 31361055.942226164,
      btcVolume: 3257.382561578943,
      usdVolume: 31361055.942226164,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '3.7580000000000000',
      high: '3.8590000000000000',
      low: '3.6874000000000000',
      close: '3.8010000000000000',
      volume: '7383724.4800000000000000',
      quoteVolume: 27986549.846176166,
      btcVolume: 2821.090111412898,
      usdVolume: 27986549.846176166,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '3.8001000000000000',
      high: '4.2129000000000000',
      low: '3.7601000000000000',
      close: '4.0150000000000000',
      volume: '15240721.6300000000000000',
      quoteVolume: 61673011.98541812,
      btcVolume: 6356.310945841886,
      usdVolume: 61673011.98541812,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '4.0149000000000000',
      high: '4.0197000000000000',
      low: '3.4602000000000000',
      close: '3.7582000000000000',
      volume: '19818388.7300000000000000',
      quoteVolume: 73819362.15594393,
      btcVolume: 8521.796803966217,
      usdVolume: 73819362.15594393,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '3.7574000000000000',
      high: '3.8320000000000000',
      low: '3.2865000000000000',
      close: '3.5837000000000000',
      volume: '13273873.6200000000000000',
      quoteVolume: 47852980.089774795,
      btcVolume: 5547.514411645623,
      usdVolume: 47852980.089774795,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '3.5838000000000000',
      high: '3.7932000000000000',
      low: '3.5400000000000000',
      close: '3.6943000000000000',
      volume: '7797486.4300000000000000',
      quoteVolume: 28881156.065596662,
      btcVolume: 3297.963204756944,
      usdVolume: 28881156.065596662,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '3.6938000000000000',
      high: '3.8150000000000000',
      low: '3.6366000000000000',
      close: '3.7655000000000000',
      volume: '5949203.5300000000000000',
      quoteVolume: 22154224.568957023,
      btcVolume: 2444.9070553685347,
      usdVolume: 22154224.568957023,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '3.7654000000000000',
      high: '3.8583000000000000',
      low: '3.6947000000000000',
      close: '3.8096000000000000',
      volume: '7333655.8100000000000000',
      quoteVolume: 27791822.366877846,
      btcVolume: 2891.52904194716,
      usdVolume: 27791822.366877846,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '3.8093000000000000',
      high: '3.8337000000000000',
      low: '3.6144000000000000',
      close: '3.6615000000000000',
      volume: '5571782.8800000000000000',
      quoteVolume: 20831712.993012883,
      btcVolume: 2197.771365437242,
      usdVolume: 20831712.993012883,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '3.6626000000000000',
      high: '3.7900000000000000',
      low: '3.6168000000000000',
      close: '3.7614000000000000',
      volume: '4045380.2000000000000000',
      quoteVolume: 15074632.829739979,
      btcVolume: 1604.6960921741713,
      usdVolume: 15074632.829739979,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '3.7621000000000000',
      high: '3.8524000000000000',
      low: '3.7526000000000000',
      close: '3.7815000000000000',
      volume: '3630692.0900000000000000',
      quoteVolume: 13812656.339239048,
      btcVolume: 1432.957547933766,
      usdVolume: 13812656.339239048,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '3.7815000000000000',
      high: '3.9500000000000000',
      low: '3.7786000000000000',
      close: '3.8895000000000000',
      volume: '5739644.8600000000000000',
      quoteVolume: 22305115.00685797,
      btcVolume: 2287.439967086519,
      usdVolume: 22305115.00685797,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '3.8895000000000000',
      high: '3.9488000000000000',
      low: '3.7734000000000000',
      close: '3.9470000000000000',
      volume: '3765966.9300000000000000',
      quoteVolume: 14542942.326336175,
      btcVolume: 1503.244995161628,
      usdVolume: 14542942.326336175,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '3.9466000000000000',
      high: '4.1388000000000000',
      low: '3.8955000000000000',
      close: '4.0866000000000000',
      volume: '9805842.1600000000000000',
      quoteVolume: 39677537.53178006,
      btcVolume: 4109.400460965454,
      usdVolume: 39677537.53178006,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '4.0880000000000000',
      high: '4.1740000000000000',
      low: '3.7292000000000000',
      close: '3.8298000000000000',
      volume: '10446021.8600000000000000',
      quoteVolume: 41318047.346202776,
      btcVolume: 4472.548344593976,
      usdVolume: 41318047.346202776,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '3.8307000000000000',
      high: '4.1447000000000000',
      low: '3.7932000000000000',
      close: '4.0611000000000000',
      volume: '7194944.8900000000000000',
      quoteVolume: 28640710.818571955,
      btcVolume: 3133.076928130972,
      usdVolume: 28640710.818571955,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '4.0620000000000000',
      high: '4.1072000000000000',
      low: '3.9100000000000000',
      close: '3.9250000000000000',
      volume: '5865674.1500000000000000',
      quoteVolume: 23402699.08869481,
      btcVolume: 2542.731357186535,
      usdVolume: 23402699.08869481,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '3.9242000000000000',
      high: '4.0262000000000000',
      low: '3.7844000000000000',
      close: '3.7917000000000000',
      volume: '6229925.9300000000000000',
      quoteVolume: 24355874.593129028,
      btcVolume: 2685.7880060204666,
      usdVolume: 24355874.593129028,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '3.7878000000000000',
      high: '3.9073000000000000',
      low: '3.7700000000000000',
      close: '3.8614000000000000',
      volume: '4443996.5100000000000000',
      quoteVolume: 17123412.802925922,
      btcVolume: 1942.855709479334,
      usdVolume: 17123412.802925922,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '3.8634000000000000',
      high: '3.8816000000000000',
      low: '3.7689000000000000',
      close: '3.8440000000000000',
      volume: '3774200.8300000000000000',
      quoteVolume: 14457491.492909947,
      btcVolume: 1633.177692310847,
      usdVolume: 14457491.492909947,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '3.8436000000000000',
      high: '3.9143000000000000',
      low: '3.8065000000000000',
      close: '3.8526000000000000',
      volume: '3793553.8500000000000000',
      quoteVolume: 14669023.347105095,
      btcVolume: 1621.3588858213739,
      usdVolume: 14669023.347105095,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '3.8542000000000000',
      high: '4.0601000000000000',
      low: '3.8270000000000000',
      close: '4.0461000000000000',
      volume: '4242354.5900000000000000',
      quoteVolume: 16636880.490730118,
      btcVolume: 1771.4352825657559,
      usdVolume: 16636880.490730118,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '4.0462000000000000',
      high: '4.1300000000000000',
      low: '3.9488000000000000',
      close: '3.9800000000000000',
      volume: '5242842.6700000000000000',
      quoteVolume: 21161407.756916173,
      btcVolume: 2231.9952818631095,
      usdVolume: 21161407.756916173,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '3.9820000000000000',
      high: '4.1800000000000000',
      low: '3.9430000000000000',
      close: '4.1763000000000000',
      volume: '4734058.3400000000000000',
      quoteVolume: 19356855.90212316,
      btcVolume: 2030.7654975998275,
      usdVolume: 19356855.90212316,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '4.1780000000000000',
      high: '4.2838000000000000',
      low: '4.0410000000000000',
      close: '4.1310000000000000',
      volume: '6223457.9900000000000000',
      quoteVolume: 25986422.58422777,
      btcVolume: 2723.684714084976,
      usdVolume: 25986422.58422777,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '4.1278000000000000',
      high: '4.4800000000000000',
      low: '4.1175000000000000',
      close: '4.3785000000000000',
      volume: '8703425.4700000000000000',
      quoteVolume: 38003418.52632179,
      btcVolume: 3946.2706304533644,
      usdVolume: 38003418.52632179,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '4.3795000000000000',
      high: '4.6185000000000000',
      low: '4.1075000000000000',
      close: '4.3976000000000000',
      volume: '8147770.0700000000000000',
      quoteVolume: 35881970.22012894,
      btcVolume: 3668.4636094846724,
      usdVolume: 35881970.22012894,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '4.3987000000000000',
      high: '4.5300000000000000',
      low: '4.2898000000000000',
      close: '4.4892000000000000',
      volume: '4168765.8100000000000000',
      quoteVolume: 18467942.894892167,
      btcVolume: 1933.344668741359,
      usdVolume: 18467942.894892167,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '4.4881000000000000',
      high: '4.5081000000000000',
      low: '4.2864000000000000',
      close: '4.4243000000000000',
      volume: '3231902.9900000000000000',
      quoteVolume: 14276426.128533928,
      btcVolume: 1476.4285848581262,
      usdVolume: 14276426.128533928,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '4.4263000000000000',
      high: '4.4860000000000000',
      low: '4.3405000000000000',
      close: '4.3580000000000000',
      volume: '2314891.8700000000000000',
      quoteVolume: 10216880.887104046,
      btcVolume: 1049.6536854309381,
      usdVolume: 10216880.887104046,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '4.3569000000000000',
      high: '4.3979000000000000',
      low: '4.3034000000000000',
      close: '4.3512000000000000',
      volume: '1153869.9600000000000000',
      quoteVolume: 5020633.317667975,
      btcVolume: 521.0659599515068,
      usdVolume: 5020633.317667975,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '4.3513000000000000',
      high: '4.4100000000000000',
      low: '4.2068000000000000',
      close: '4.3465000000000000',
      volume: '2137522.0500000000000000',
      quoteVolume: 9211491.186056014,
      btcVolume: 960.8098153273933,
      usdVolume: 9211491.186056014,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '4.3487000000000000',
      high: '4.4759000000000000',
      low: '4.3250000000000000',
      close: '4.4730000000000000',
      volume: '2037163.2100000000000000',
      quoteVolume: 8929343.506046021,
      btcVolume: 918.8751048356538,
      usdVolume: 8929343.506046021,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '4.4753000000000000',
      high: '4.5062000000000000',
      low: '4.3605000000000000',
      close: '4.4350000000000000',
      volume: '2250839.6700000000000000',
      quoteVolume: 9985584.222381042,
      btcVolume: 1027.6679495159528,
      usdVolume: 9985584.222381042,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '4.4374000000000000',
      high: '4.4404000000000000',
      low: '4.3426000000000000',
      close: '4.4225000000000000',
      volume: '2229011.4100000000000000',
      quoteVolume: 9773177.039550047,
      btcVolume: 997.9263921227159,
      usdVolume: 9773177.039550047,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '4.4207000000000000',
      high: '4.4503000000000000',
      low: '3.9100000000000000',
      close: '3.9565000000000000',
      volume: '6568688.8700000000000000',
      quoteVolume: 27115697.81298312,
      btcVolume: 2861.0421903797023,
      usdVolume: 27115697.81298312,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '3.9530000000000000',
      high: '4.1874000000000000',
      low: '3.8573000000000000',
      close: '4.0819000000000000',
      volume: '4751891.2300000000000000',
      quoteVolume: 19339118.373514097,
      btcVolume: 2057.689856681987,
      usdVolume: 19339118.373514097,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '4.0807000000000000',
      high: '4.1346000000000000',
      low: '4.0054000000000000',
      close: '4.1100000000000000',
      volume: '1604660.7400000000000000',
      quoteVolume: 6524511.677829004,
      btcVolume: 692.1491678856813,
      usdVolume: 6524511.677829004,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '4.1100000000000000',
      high: '4.1134000000000000',
      low: '3.9143000000000000',
      close: '3.9371000000000000',
      volume: '1710213.3600000000000000',
      quoteVolume: 6841578.031834006,
      btcVolume: 729.0393181477964,
      usdVolume: 6841578.031834006,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '3.9395000000000000',
      high: '3.9889000000000000',
      low: '3.6551000000000000',
      close: '3.9274000000000000',
      volume: '6850306.1100000000000000',
      quoteVolume: 26068942.977982927,
      btcVolume: 2826.528626557364,
      usdVolume: 26068942.977982927,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '3.9254000000000000',
      high: '4.0918000000000000',
      low: '3.8935000000000000',
      close: '4.0502000000000000',
      volume: '3614037.3300000000000000',
      quoteVolume: 14515402.500366056,
      btcVolume: 1528.8235581573408,
      usdVolume: 14515402.500366056,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '4.0502000000000000',
      high: '4.2000000000000000',
      low: '3.9570000000000000',
      close: '4.1659000000000000',
      volume: '4301280.9800000000000000',
      quoteVolume: 17558113.834275063,
      btcVolume: 1862.55704310758,
      usdVolume: 17558113.834275063,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '4.1663000000000000',
      high: '4.2491000000000000',
      low: '4.0957000000000000',
      close: '4.1415000000000000',
      volume: '3348384.0800000000000000',
      quoteVolume: 13969700.00507494,
      btcVolume: 1483.9403280382335,
      usdVolume: 13969700.00507494,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '4.1417000000000000',
      high: '4.1839000000000000',
      low: '4.0521000000000000',
      close: '4.0980000000000000',
      volume: '2424386.7000000000000000',
      quoteVolume: 9970938.73032103,
      btcVolume: 1068.115770950031,
      usdVolume: 9970938.73032103,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '4.0978000000000000',
      high: '4.2072000000000000',
      low: '4.0654000000000000',
      close: '4.1765000000000000',
      volume: '2414698.6200000000000000',
      quoteVolume: 10014907.932097934,
      btcVolume: 1076.6564266730459,
      usdVolume: 10014907.932097934,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '4.1762000000000000',
      high: '4.2058000000000000',
      low: '4.1395000000000000',
      close: '4.1812000000000000',
      volume: '1336859.5000000000000000',
      quoteVolume: 5582676.14346401,
      btcVolume: 596.8254803691216,
      usdVolume: 5582676.14346401,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '4.1819000000000000',
      high: '4.4859000000000000',
      low: '4.1760000000000000',
      close: '4.4841000000000000',
      volume: '4636790.2800000000000000',
      quoteVolume: 20238493.440281935,
      btcVolume: 2131.3300159503665,
      usdVolume: 20238493.440281935,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '4.4840000000000000',
      high: '4.9422000000000000',
      low: '4.4254000000000000',
      close: '4.8051000000000000',
      volume: '10884153.7200000000000000',
      quoteVolume: 51489688.58105523,
      btcVolume: 5341.591224643085,
      usdVolume: 51489688.58105523,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '4.8061000000000000',
      high: '4.9411000000000000',
      low: '4.4986000000000000',
      close: '4.7075000000000000',
      volume: '10253258.2100000000000000',
      quoteVolume: 48438749.91706304,
      btcVolume: 5137.643671618544,
      usdVolume: 48438749.91706304,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '4.7075000000000000',
      high: '4.8407000000000000',
      low: '4.5033000000000000',
      close: '4.8134000000000000',
      volume: '7023055.3700000000000000',
      quoteVolume: 33164952.91921413,
      btcVolume: 3591.85419221288,
      usdVolume: 33164952.91921413,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '4.8129000000000000',
      high: '4.8587000000000000',
      low: '4.6424000000000000',
      close: '4.6813000000000000',
      volume: '5531129.1300000000000000',
      quoteVolume: 26116409.53864893,
      btcVolume: 2843.866240257118,
      usdVolume: 26116409.53864893,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '4.6830000000000000',
      high: '4.6957000000000000',
      low: '4.3000000000000000',
      close: '4.4058000000000000',
      volume: '6165533.0500000000000000',
      quoteVolume: 27809003.537887093,
      btcVolume: 3060.9526617884444,
      usdVolume: 27809003.537887093,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '4.4051000000000000',
      high: '4.6200000000000000',
      low: '4.3647000000000000',
      close: '4.5668000000000000',
      volume: '4751931.7400000000000000',
      quoteVolume: 21438525.93253806,
      btcVolume: 2359.635570929396,
      usdVolume: 21438525.93253806,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '4.5658000000000000',
      high: '4.6500000000000000',
      low: '4.4232000000000000',
      close: '4.6187000000000000',
      volume: '4789663.3200000000000000',
      quoteVolume: 21719855.120023914,
      btcVolume: 2382.4340020200825,
      usdVolume: 21719855.120023914,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '4.6192000000000000',
      high: '4.6622000000000000',
      low: '4.5337000000000000',
      close: '4.5626000000000000',
      volume: '3001636.3100000000000000',
      quoteVolume: 13817102.521447966,
      btcVolume: 1509.948465482142,
      usdVolume: 13817102.521447966,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '4.5625000000000000',
      high: '4.7300000000000000',
      low: '4.5217000000000000',
      close: '4.7067000000000000',
      volume: '4059427.1700000000000000',
      quoteVolume: 18803296.868452046,
      btcVolume: 2044.3470835901742,
      usdVolume: 18803296.868452046,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '4.7068000000000000',
      high: '4.9181000000000000',
      low: '4.6025000000000000',
      close: '4.7996000000000000',
      volume: '7638387.3500000000000000',
      quoteVolume: 36415952.50593107,
      btcVolume: 3981.9894097602037,
      usdVolume: 36415952.50593107,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '4.7998000000000000',
      high: '4.8964000000000000',
      low: '4.7031000000000000',
      close: '4.7309000000000000',
      volume: '4456413.0000000000000000',
      quoteVolume: 21519728.981386155,
      btcVolume: 2366.7125707992577,
      usdVolume: 21519728.981386155,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '4.7300000000000000',
      high: '4.8539000000000000',
      low: '4.7186000000000000',
      close: '4.8200000000000000',
      volume: '2535114.5100000000000000',
      quoteVolume: 12133983.302445995,
      btcVolume: 1333.81578228134,
      usdVolume: 12133983.302445995,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '4.8198000000000000',
      high: '4.8325000000000000',
      low: '4.6118000000000000',
      close: '4.7439000000000000',
      volume: '3420895.3700000000000000',
      quoteVolume: 16145214.992928915,
      btcVolume: 1782.6229066425183,
      usdVolume: 16145214.992928915,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '4.7426000000000000',
      high: '5.5000000000000000',
      low: '4.7362000000000000',
      close: '5.3617000000000000',
      volume: '12032948.9700000000000000',
      quoteVolume: 61715137.636092246,
      btcVolume: 6663.492972858207,
      usdVolume: 61715137.636092246,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '5.3613000000000000',
      high: '5.8890000000000000',
      low: '5.3300000000000000',
      close: '5.7248000000000000',
      volume: '13783142.2200000000000000',
      quoteVolume: 77491639.85352772,
      btcVolume: 8361.43667342537,
      usdVolume: 77491639.85352772,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '5.7245000000000000',
      high: '6.5607000000000000',
      low: '5.5920000000000000',
      close: '6.4987000000000000',
      volume: '15825025.1900000000000000',
      quoteVolume: 97337955.14051247,
      btcVolume: 10432.903935303775,
      usdVolume: 97337955.14051247,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '6.5001000000000000',
      high: '6.5006000000000000',
      low: '5.7029000000000000',
      close: '6.0820000000000000',
      volume: '12942475.7400000000000000',
      quoteVolume: 78848343.82277149,
      btcVolume: 8463.213815528254,
      usdVolume: 78848343.82277149,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '6.0820000000000000',
      high: '6.2009000000000000',
      low: '5.7096000000000000',
      close: '6.1488000000000000',
      volume: '9932051.9600000000000000',
      quoteVolume: 59118518.95393981,
      btcVolume: 6423.877893463667,
      usdVolume: 59118518.95393981,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '6.1500000000000000',
      high: '6.2661000000000000',
      low: '5.9610000000000000',
      close: '6.0994000000000000',
      volume: '5180049.0300000000000000',
      quoteVolume: 31685675.96767919,
      btcVolume: 3429.120643940731,
      usdVolume: 31685675.96767919,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '6.1043000000000000',
      high: '7.3898000000000000',
      low: '6.0975000000000000',
      close: '7.3345000000000000',
      volume: '18949318.6200000000000000',
      quoteVolume: 128119147.50220707,
      btcVolume: 13858.753628207221,
      usdVolume: 128119147.50220707,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '7.3447000000000000',
      high: '8.4800000000000000',
      low: '6.8525000000000000',
      close: '7.1630000000000000',
      volume: '30737550.9600000000000000',
      quoteVolume: 235671215.53400916,
      btcVolume: 25398.30633539493,
      usdVolume: 235671215.53400916,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '7.1616000000000000',
      high: '8.3446000000000000',
      low: '6.9650000000000000',
      close: '8.1321000000000000',
      volume: '25153380.4100000000000000',
      quoteVolume: 192793205.38648593,
      btcVolume: 20888.78682809465,
      usdVolume: 192793205.38648593,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '8.1315000000000000',
      high: '8.9080000000000000',
      low: '7.8309000000000000',
      close: '8.6494000000000000',
      volume: '20518883.2200000000000000',
      quoteVolume: 172454027.970733,
      btcVolume: 18690.633753627804,
      usdVolume: 172454027.970733,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '8.6486000000000000',
      high: '8.8822000000000000',
      low: '7.9427000000000000',
      close: '8.3540000000000000',
      volume: '20705851.7900000000000000',
      quoteVolume: 173628619.95218885,
      btcVolume: 19005.124013284785,
      usdVolume: 173628619.95218885,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '8.3545000000000000',
      high: '8.6444000000000000',
      low: '8.2000000000000000',
      close: '8.2605000000000000',
      volume: '8580583.8800000000000000',
      quoteVolume: 71891358.28923588,
      btcVolume: 7867.034349896577,
      usdVolume: 71891358.28923588,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '8.2589000000000000',
      high: '8.2648000000000000',
      low: '7.6570000000000000',
      close: '7.9734000000000000',
      volume: '11416632.8500000000000000',
      quoteVolume: 90986110.87730221,
      btcVolume: 9936.17044525862,
      usdVolume: 90986110.87730221,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '7.9731000000000000',
      high: '8.2100000000000000',
      low: '7.8006000000000000',
      close: '8.0784000000000000',
      volume: '5325842.7900000000000000',
      quoteVolume: 42588167.82897199,
      btcVolume: 4648.695039778068,
      usdVolume: 42588167.82897199,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '8.0848000000000000',
      high: '8.1388000000000000',
      low: '7.1210000000000000',
      close: '7.2301000000000000',
      volume: '11024362.8200000000000000',
      quoteVolume: 84110745.40996003,
      btcVolume: 9169.302612989131,
      usdVolume: 84110745.40996003,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '7.2301000000000000',
      high: '7.6788000000000000',
      low: '6.9232000000000000',
      close: '7.3101000000000000',
      volume: '10932081.1000000000000000',
      quoteVolume: 80003692.44480799,
      btcVolume: 8576.725504872806,
      usdVolume: 80003692.44480799,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '7.3086000000000000',
      high: '7.6365000000000000',
      low: '7.0832000000000000',
      close: '7.4689000000000000',
      volume: '9596016.7400000000000000',
      quoteVolume: 70863239.4579672,
      btcVolume: 7572.310124975652,
      usdVolume: 70863239.4579672,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '7.4690000000000000',
      high: '8.0990000000000000',
      low: '7.4029000000000000',
      close: '7.8983000000000000',
      volume: '10915322.8800000000000000',
      quoteVolume: 85588641.40242985,
      btcVolume: 8986.708733063164,
      usdVolume: 85588641.40242985,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '7.8982000000000000',
      high: '7.9304000000000000',
      low: '7.3840000000000000',
      close: '7.4284000000000000',
      volume: '7313843.7500000000000000',
      quoteVolume: 55866119.15418281,
      btcVolume: 5854.581041359676,
      usdVolume: 55866119.15418281,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '7.4284000000000000',
      high: '7.8009000000000000',
      low: '7.3744000000000000',
      close: '7.6720000000000000',
      volume: '7502502.4600000000000000',
      quoteVolume: 57265634.06685721,
      btcVolume: 5964.891413603993,
      usdVolume: 57265634.06685721,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '7.6737000000000000',
      high: '7.9450000000000000',
      low: '7.1885000000000000',
      close: '7.5022000000000000',
      volume: '10637660.3200000000000000',
      quoteVolume: 80431491.35325707,
      btcVolume: 8167.326551727266,
      usdVolume: 80431491.35325707,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '7.5009000000000000',
      high: '7.5045000000000000',
      low: '6.5101000000000000',
      close: '7.1000000000000000',
      volume: '16680558.0400000000000000',
      quoteVolume: 117110382.73523542,
      btcVolume: 11213.359323082714,
      usdVolume: 117110382.73523542,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '7.0990000000000000',
      high: '7.4243000000000000',
      low: '6.9106000000000000',
      close: '7.2333000000000000',
      volume: '8500726.8300000000000000',
      quoteVolume: 60812597.213252656,
      btcVolume: 5549.91287596061,
      usdVolume: 60812597.213252656,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '7.2332000000000000',
      high: '7.4871000000000000',
      low: '6.9867000000000000',
      close: '7.0876000000000000',
      volume: '7058913.7900000000000000',
      quoteVolume: 50881041.657357685,
      btcVolume: 4599.08400376134,
      usdVolume: 50881041.657357685,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '7.0876000000000000',
      high: '7.5757000000000000',
      low: '7.0253000000000000',
      close: '7.4093000000000000',
      volume: '7667819.8300000000000000',
      quoteVolume: 56146316.3170551,
      btcVolume: 5092.87952074015,
      usdVolume: 56146316.3170551,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '7.4093000000000000',
      high: '7.8649000000000000',
      low: '7.4085000000000000',
      close: '7.8033000000000000',
      volume: '7419454.2600000000000000',
      quoteVolume: 57010133.853312016,
      btcVolume: 5107.431794695363,
      usdVolume: 57010133.853312016,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '7.8025000000000000',
      high: '8.4720000000000000',
      low: '7.6373000000000000',
      close: '8.3248000000000000',
      volume: '9345076.4000000000000000',
      quoteVolume: 74293026.60700549,
      btcVolume: 6369.51346066485,
      usdVolume: 74293026.60700549,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '8.3216000000000000',
      high: '8.7972000000000000',
      low: '6.8700000000000000',
      close: '8.2814000000000000',
      volume: '22647614.3400000000000000',
      quoteVolume: 187308467.6520541,
      btcVolume: 16618.54057221337,
      usdVolume: 187308467.6520541,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '8.2851000000000000',
      high: '9.2707000000000000',
      low: '8.1408000000000000',
      close: '9.2502000000000000',
      volume: '12805982.5400000000000000',
      quoteVolume: 110080414.61075139,
      btcVolume: 9792.377399999354,
      usdVolume: 110080414.61075139,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '9.2502000000000000',
      high: '9.9645000000000000',
      low: '9.0807000000000000',
      close: '9.8687000000000000',
      volume: '18460200.9900000000000000',
      quoteVolume: 175203350.75301245,
      btcVolume: 15588.926012531812,
      usdVolume: 175203350.75301245,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '9.8686000000000000',
      high: '9.8686000000000000',
      low: '9.3207000000000000',
      close: '9.5253000000000000',
      volume: '9682088.7700000000000000',
      quoteVolume: 92516426.18121561,
      btcVolume: 8087.5667816474515,
      usdVolume: 92516426.18121561,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '9.5265000000000000',
      high: '10.2917000000000000',
      low: '9.4515000000000000',
      close: '10.2343000000000000',
      volume: '9041552.6000000000000000',
      quoteVolume: 88144051.28129894,
      btcVolume: 7505.910950203218,
      usdVolume: 88144051.28129894,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '10.2284000000000000',
      high: '10.2615000000000000',
      low: '9.0500000000000000',
      close: '10.1286000000000000',
      volume: '11733225.3400000000000000',
      quoteVolume: 115568472.9989462,
      btcVolume: 9912.375472640897,
      usdVolume: 115568472.9989462,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '10.1286000000000000',
      high: '13.8799000000000000',
      low: '9.9287000000000000',
      close: '12.7093000000000000',
      volume: '28542792.8400000000000000',
      quoteVolume: 342944887.19930977,
      btcVolume: 29343.8183945227,
      usdVolume: 342944887.19930977,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '12.7095000000000000',
      high: '14.4586000000000000',
      low: '12.0000000000000000',
      close: '13.8104000000000000',
      volume: '28201686.3100000000000000',
      quoteVolume: 376769344.70101273,
      btcVolume: 32361.99931890226,
      usdVolume: 376769344.70101273,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '13.8105000000000000',
      high: '14.0551000000000000',
      low: '12.5200000000000000',
      close: '13.4722000000000000',
      volume: '17910767.8200000000000000',
      quoteVolume: 240411392.94454074,
      btcVolume: 20238.132402655556,
      usdVolume: 240411392.94454074,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '13.4722000000000000',
      high: '13.5403000000000000',
      low: '12.0905000000000000',
      close: '12.9971000000000000',
      volume: '17514622.4200000000000000',
      quoteVolume: 225363799.55548632,
      btcVolume: 19493.751301956687,
      usdVolume: 225363799.55548632,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '12.9999000000000000',
      high: '16.8000000000000000',
      low: '12.5936000000000000',
      close: '16.4635000000000000',
      volume: '23113249.4000000000000000',
      quoteVolume: 342110085.2548909,
      btcVolume: 29740.856429109834,
      usdVolume: 342110085.2548909,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '16.4634000000000000',
      high: '18.3488000000000000',
      low: '15.9175000000000000',
      close: '17.1883000000000000',
      volume: '30934675.5500000000000000',
      quoteVolume: 527151580.26604337,
      btcVolume: 45706.95238937064,
      usdVolume: 527151580.26604337,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '17.1728000000000000',
      high: '17.5998000000000000',
      low: '16.3000000000000000',
      close: '16.9574000000000000',
      volume: '11109883.8400000000000000',
      quoteVolume: 188010113.5259645,
      btcVolume: 16024.502767051485,
      usdVolume: 188010113.5259645,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '16.9447000000000000',
      high: '19.8303000000000000',
      low: '16.5554000000000000',
      close: '19.2285000000000000',
      volume: '17941112.0100000000000000',
      quoteVolume: 330894146.757813,
      btcVolume: 27921.28371563732,
      usdVolume: 330894146.757813,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '19.2108000000000000',
      high: '20.1111000000000000',
      low: '18.5144000000000000',
      close: '18.8061000000000000',
      volume: '10757840.9900000000000000',
      quoteVolume: 206509366.21838295,
      btcVolume: 17444.9116283756,
      usdVolume: 206509366.21838295,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '18.8096000000000000',
      high: '19.7111000000000000',
      low: '15.5000000000000000',
      close: '16.8196000000000000',
      volume: '13542369.7900000000000000',
      quoteVolume: 248379200.46697778,
      btcVolume: 20504.962867936094,
      usdVolume: 248379200.46697778,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '16.8064000000000000',
      high: '17.6738000000000000',
      low: '14.6900000000000000',
      close: '16.1927000000000000',
      volume: '27088057.7600000000000000',
      quoteVolume: 445332598.9067832,
      btcVolume: 36628.32089159984,
      usdVolume: 445332598.9067832,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '16.1846000000000000',
      high: '17.2554000000000000',
      low: '14.8926000000000000',
      close: '16.1863000000000000',
      volume: '18358817.9100000000000000',
      quoteVolume: 297260255.6285172,
      btcVolume: 25207.258992200754,
      usdVolume: 297260255.6285172,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '16.1884000000000000',
      high: '16.7862000000000000',
      low: '15.7232000000000000',
      close: '16.0483000000000000',
      volume: '11011575.0900000000000000',
      quoteVolume: 179321055.27822992,
      btcVolume: 15196.345359291849,
      usdVolume: 179321055.27822992,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '16.0476000000000000',
      high: '16.1421000000000000',
      low: '12.8900000000000000',
      close: '13.8325000000000000',
      volume: '26139289.1300000000000000',
      quoteVolume: 376332110.8896587,
      btcVolume: 32120.329513780653,
      usdVolume: 376332110.8896587,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '13.8342000000000000',
      high: '16.3300000000000000',
      low: '13.6600000000000000',
      close: '15.8078000000000000',
      volume: '16709823.0900000000000000',
      quoteVolume: 252784283.01039815,
      btcVolume: 21872.182565912786,
      usdVolume: 252784283.01039815,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '15.8085000000000000',
      high: '16.0042000000000000',
      low: '14.6536000000000000',
      close: '15.1428000000000000',
      volume: '10765015.8300000000000000',
      quoteVolume: 163456993.92262277,
      btcVolume: 14082.112398712841,
      usdVolume: 163456993.92262277,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '15.1572000000000000',
      high: '15.8797000000000000',
      low: '14.7000000000000000',
      close: '15.1419000000000000',
      volume: '8454928.0700000000000000',
      quoteVolume: 129187194.20472585,
      btcVolume: 11012.764821572282,
      usdVolume: 129187194.20472585,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '15.1363000000000000',
      high: '15.3743000000000000',
      low: '13.4578000000000000',
      close: '14.1164000000000000',
      volume: '11988432.8700000000000000',
      quoteVolume: 173378967.9258536,
      btcVolume: 15132.48523268232,
      usdVolume: 173378967.9258536,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '14.1171000000000000',
      high: '15.6664000000000000',
      low: '14.0537000000000000',
      close: '15.2000000000000000',
      volume: '11415333.4100000000000000',
      quoteVolume: 169326699.33486208,
      btcVolume: 14838.375991579402,
      usdVolume: 169326699.33486208,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '15.2088000000000000',
      high: '15.5215000000000000',
      low: '13.9679000000000000',
      close: '14.5234000000000000',
      volume: '11685135.1900000000000000',
      quoteVolume: 173265514.72794762,
      btcVolume: 15276.438417623027,
      usdVolume: 173265514.72794762,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '14.5234000000000000',
      high: '15.4989000000000000',
      low: '14.2360000000000000',
      close: '15.1207000000000000',
      volume: '7108908.2300000000000000',
      quoteVolume: 106217471.48299323,
      btcVolume: 9283.514516201361,
      usdVolume: 106215608.60329323,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '15.1136000000000000',
      high: '17.0305000000000000',
      low: '14.9565000000000000',
      close: '16.4273000000000000',
      volume: '11734386.8700000000000000',
      quoteVolume: 189641391.5824856,
      btcVolume: 16492.39931867633,
      usdVolume: 189641391.5824856,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '16.4537000000000000',
      high: '17.7777000000000000',
      low: '16.4037000000000000',
      close: '16.4788000000000000',
      volume: '9731994.3200000000000000',
      quoteVolume: 166124406.2667331,
      btcVolume: 14311.349188271188,
      usdVolume: 166124406.2667331,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '16.4782000000000000',
      high: '16.9495000000000000',
      low: '15.4451000000000000',
      close: '15.5772000000000000',
      volume: '9615963.2700000000000000',
      quoteVolume: 157331308.63249928,
      btcVolume: 13475.644648985366,
      usdVolume: 157331308.63249928,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '15.5746000000000000',
      high: '16.5053000000000000',
      low: '15.0629000000000000',
      close: '16.0545000000000000',
      volume: '7793462.3200000000000000',
      quoteVolume: 123969155.38132185,
      btcVolume: 10492.616965270177,
      usdVolume: 123958598.20392784,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '16.2072000000000000',
      high: '16.3587000000000000',
      low: '14.0000000000000000',
      close: '14.7982000000000000',
      volume: '10301348.0000000000000000',
      quoteVolume: 154640593.96041393,
      btcVolume: 13465.979576934247,
      usdVolume: 154640593.96041393,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '14.7922000000000000',
      high: '14.9989000000000000',
      low: '10.8261000000000000',
      close: '11.8895000000000000',
      volume: '16949856.4800000000000000',
      quoteVolume: 227014586.31002295,
      btcVolume: 20804.680530088768,
      usdVolume: 227014586.31002295,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '11.8802000000000000',
      high: '12.9040000000000000',
      low: '11.0000000000000000',
      close: '12.3708000000000000',
      volume: '18612095.2100000000000000',
      quoteVolume: 225971603.75573367,
      btcVolume: 21814.154412869015,
      usdVolume: 225971603.75573367,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '12.3804000000000000',
      high: '12.7655000000000000',
      low: '9.1000000000000000',
      close: '10.6017000000000000',
      volume: '25511608.4800000000000000',
      quoteVolume: 278344840.137839,
      btcVolume: 27443.663519385616,
      usdVolume: 278344840.137839,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '10.6087000000000000',
      high: '13.2400000000000000',
      low: '9.7155000000000000',
      close: '12.8044000000000000',
      volume: '21811584.7900000000000000',
      quoteVolume: 254940172.73275283,
      btcVolume: 24977.6005690715,
      usdVolume: 254940172.73275283,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '12.8060000000000000',
      high: '13.1747000000000000',
      low: '11.1700000000000000',
      close: '12.4700000000000000',
      volume: '20515580.9600000000000000',
      quoteVolume: 250103840.5173619,
      btcVolume: 24710.405343979466,
      usdVolume: 250103840.5173619,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '12.4744000000000000',
      high: '12.7362000000000000',
      low: '11.0284000000000000',
      close: '11.7460000000000000',
      volume: '14832129.8100000000000000',
      quoteVolume: 176444797.96903428,
      btcVolume: 17428.664020195858,
      usdVolume: 176444797.96903428,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '11.7359000000000000',
      high: '12.6566000000000000',
      low: '11.4356000000000000',
      close: '12.3444000000000000',
      volume: '10649791.4300000000000000',
      quoteVolume: 129646130.9405381,
      btcVolume: 12725.251017309938,
      usdVolume: 129646130.9405381,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '12.3452000000000000',
      high: '13.2800000000000000',
      low: '12.1800000000000000',
      close: '12.4957000000000000',
      volume: '14928167.2000000000000000',
      quoteVolume: 190985359.03175014,
      btcVolume: 18462.71704639154,
      usdVolume: 190985359.03175014,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '12.4948000000000000',
      high: '12.6960000000000000',
      low: '12.0190000000000000',
      close: '12.4406000000000000',
      volume: '7498865.1600000000000000',
      quoteVolume: 92935019.6125966,
      btcVolume: 9026.605245547558,
      usdVolume: 92935019.6125966,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '12.4406000000000000',
      high: '12.7470000000000000',
      low: '12.0934000000000000',
      close: '12.7187000000000000',
      volume: '6269794.6100000000000000',
      quoteVolume: 77882381.38087636,
      btcVolume: 7509.491501095776,
      usdVolume: 77882381.38087636,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '12.7194000000000000',
      high: '13.2300000000000000',
      low: '11.7658000000000000',
      close: '12.0670000000000000',
      volume: '11107099.4800000000000000',
      quoteVolume: 138433490.7795642,
      btcVolume: 13317.710404478297,
      usdVolume: 138433490.7795642,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '12.0757000000000000',
      high: '12.3863000000000000',
      low: '11.6349000000000000',
      close: '12.1374000000000000',
      volume: '8830940.1600000000000000',
      quoteVolume: 106057143.09535801,
      btcVolume: 10093.04992993571,
      usdVolume: 106057143.09535801,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '12.1300000000000000',
      high: '12.2400000000000000',
      low: '10.7500000000000000',
      close: '10.9314000000000000',
      volume: '11413633.6300000000000000',
      quoteVolume: 132790893.20361203,
      btcVolume: 12320.461229483713,
      usdVolume: 132790893.20361203,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '10.9301000000000000',
      high: '11.3002000000000000',
      low: '10.4771000000000000',
      close: '10.7367000000000000',
      volume: '10362445.0800000000000000',
      quoteVolume: 112764173.2894766,
      btcVolume: 10353.892720724712,
      usdVolume: 112764173.2894766,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '10.7367000000000000',
      high: '11.6759000000000000',
      low: '10.4720000000000000',
      close: '11.0139000000000000',
      volume: '10163046.4600000000000000',
      quoteVolume: 111006019.31385103,
      btcVolume: 10179.558912072785,
      usdVolume: 111006019.31385103,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '11.0139000000000000',
      high: '11.1315000000000000',
      low: '9.7863000000000000',
      close: '10.0940000000000000',
      volume: '8890206.0700000000000000',
      quoteVolume: 93387663.390679,
      btcVolume: 8561.061779425265,
      usdVolume: 93387663.390679,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '10.0938000000000000',
      high: '10.4969000000000000',
      low: '9.8756000000000000',
      close: '10.1209000000000000',
      volume: '5596280.8000000000000000',
      quoteVolume: 56998511.47489919,
      btcVolume: 5177.969389632715,
      usdVolume: 56998511.47489919,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '10.1192000000000000',
      high: '10.6199000000000000',
      low: '9.5000000000000000',
      close: '9.7357000000000000',
      volume: '7410739.4400000000000000',
      quoteVolume: 74829506.00962031,
      btcVolume: 6862.181652542345,
      usdVolume: 74829506.00962031,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '9.7394000000000000',
      high: '9.9235000000000000',
      low: '8.5012000000000000',
      close: '8.7114000000000000',
      volume: '15761415.8500000000000000',
      quoteVolume: 141463825.6035975,
      btcVolume: 13312.026406212963,
      usdVolume: 141463825.6035975,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '8.7162000000000000',
      high: '9.0800000000000000',
      low: '8.5400000000000000',
      close: '8.7401000000000000',
      volume: '8074342.9400000000000000',
      quoteVolume: 71284015.95208645,
      btcVolume: 6811.9002965444815,
      usdVolume: 71284015.95208645,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '8.7440000000000000',
      high: '8.8016000000000000',
      low: '7.2869000000000000',
      close: '7.6364000000000000',
      volume: '18221454.9700000000000000',
      quoteVolume: 146298886.40303153,
      btcVolume: 14105.940252148712,
      usdVolume: 146298886.40303153,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '7.6404000000000000',
      high: '10.5300000000000000',
      low: '7.4761000000000000',
      close: '9.8700000000000000',
      volume: '26877668.8100000000000000',
      quoteVolume: 243924037.01423475,
      btcVolume: 23200.606802237573,
      usdVolume: 243924037.01423475,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '9.8701000000000000',
      high: '11.1990000000000000',
      low: '9.3771000000000000',
      close: '10.7279000000000000',
      volume: '20220142.0700000000000000',
      quoteVolume: 206454816.90600815,
      btcVolume: 19347.190816472226,
      usdVolume: 206454816.90600815,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '10.7218000000000000',
      high: '11.1000000000000000',
      low: '9.9757000000000000',
      close: '10.3169000000000000',
      volume: '12019763.1700000000000000',
      quoteVolume: 125899152.91074796,
      btcVolume: 11759.471660591937,
      usdVolume: 125899152.91074796,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '10.3079000000000000',
      high: '10.9910000000000000',
      low: '9.9122000000000000',
      close: '10.7939000000000000',
      volume: '10005871.9800000000000000',
      quoteVolume: 104553700.74577622,
      btcVolume: 9756.633696493238,
      usdVolume: 104553700.74577622,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '10.8000000000000000',
      high: '11.0700000000000000',
      low: '10.1500000000000000',
      close: '10.2830000000000000',
      volume: '8720119.6800000000000000',
      quoteVolume: 92733132.97270194,
      btcVolume: 8531.552193163896,
      usdVolume: 92733132.97270194,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '10.2809000000000000',
      high: '10.3882000000000000',
      low: '9.4502000000000000',
      close: '10.1350000000000000',
      volume: '13371311.3100000000000000',
      quoteVolume: 133299972.25986037,
      btcVolume: 12416.99662418838,
      usdVolume: 133299972.25986037,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '10.1356000000000000',
      high: '10.2314000000000000',
      low: '9.5768000000000000',
      close: '9.8694000000000000',
      volume: '6624754.2600000000000000',
      quoteVolume: 65302473.930610836,
      btcVolume: 6075.231257322131,
      usdVolume: 65302473.930610836,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '9.8727000000000000',
      high: '10.4119000000000000',
      low: '9.2457000000000000',
      close: '9.6394000000000000',
      volume: '9437903.4200000000000000',
      quoteVolume: 93289422.56827107,
      btcVolume: 8711.5733196697,
      usdVolume: 93289422.56827107,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '9.6394000000000000',
      high: '9.8449000000000000',
      low: '8.7975000000000000',
      close: '9.2167000000000000',
      volume: '11050283.7900000000000000',
      quoteVolume: 101613627.94135539,
      btcVolume: 9676.160636390727,
      usdVolume: 101613627.94135539,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '9.2132000000000000',
      high: '9.5555000000000000',
      low: '9.1040000000000000',
      close: '9.2690000000000000',
      volume: '4803593.0900000000000000',
      quoteVolume: 44581852.41136064,
      btcVolume: 4225.164872066804,
      usdVolume: 44581852.41136064,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '9.2686000000000000',
      high: '9.4660000000000000',
      low: '9.1141000000000000',
      close: '9.3922000000000000',
      volume: '4665626.6900000000000000',
      quoteVolume: 43259192.53149099,
      btcVolume: 4079.3745493298234,
      usdVolume: 43259192.53149099,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '9.3906000000000000',
      high: '9.7200000000000000',
      low: '9.2629000000000000',
      close: '9.6176000000000000',
      volume: '6205749.7700000000000000',
      quoteVolume: 58892347.278092586,
      btcVolume: 5503.330432382905,
      usdVolume: 58892347.278092586,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '9.6134000000000000',
      high: '9.6580000000000000',
      low: '8.5630000000000000',
      close: '8.7689000000000000',
      volume: '7422770.6500000000000000',
      quoteVolume: 67706985.53549488,
      btcVolume: 6342.859632868126,
      usdVolume: 67706985.53549488,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '8.7690000000000000',
      high: '9.0100000000000000',
      low: '8.3817000000000000',
      close: '8.9799000000000000',
      volume: '7536611.8700000000000000',
      quoteVolume: 65993355.663363345,
      btcVolume: 6218.463186844811,
      usdVolume: 65993355.663363345,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '8.9801000000000000',
      high: '9.6829000000000000',
      low: '8.4016000000000000',
      close: '9.5594000000000000',
      volume: '10110727.7500000000000000',
      quoteVolume: 91351206.53641662,
      btcVolume: 8488.116029639972,
      usdVolume: 91351206.53641662,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '9.5519000000000000',
      high: '10.5254000000000000',
      low: '9.3701000000000000',
      close: '10.3959000000000000',
      volume: '11276147.4500000000000000',
      quoteVolume: 112848361.62231602,
      btcVolume: 10272.349870485246,
      usdVolume: 112848361.62231602,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '10.3923000000000000',
      high: '10.9200000000000000',
      low: '10.3700000000000000',
      close: '10.5198000000000000',
      volume: '9504046.9700000000000000',
      quoteVolume: 100981242.65024652,
      btcVolume: 8918.134330290197,
      usdVolume: 100981242.65024652,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '10.5168000000000000',
      high: '11.0258000000000000',
      low: '10.1500000000000000',
      close: '10.8440000000000000',
      volume: '8011810.1500000000000000',
      quoteVolume: 85068557.21714121,
      btcVolume: 7485.698997274424,
      usdVolume: 85068557.21714121,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '10.8441000000000000',
      high: '11.8028000000000000',
      low: '10.6807000000000000',
      close: '11.4242000000000000',
      volume: '9262541.8700000000000000',
      quoteVolume: 104346545.63303679,
      btcVolume: 9109.599515130829,
      usdVolume: 104346545.63303679,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '11.4599000000000000',
      high: '11.5520000000000000',
      low: '10.6592000000000000',
      close: '11.0016000000000000',
      volume: '7617422.8600000000000000',
      quoteVolume: 85252142.00798237,
      btcVolume: 7456.401573669332,
      usdVolume: 85252142.00798237,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '11.0016000000000000',
      high: '11.3536000000000000',
      low: '10.6201000000000000',
      close: '10.8896000000000000',
      volume: '6061485.9500000000000000',
      quoteVolume: 66687126.90002584,
      btcVolume: 5850.816052446876,
      usdVolume: 66687126.90002584,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '10.8896000000000000',
      high: '10.9317000000000000',
      low: '10.4550000000000000',
      close: '10.7483000000000000',
      volume: '6908169.7600000000000000',
      quoteVolume: 73900491.87788318,
      btcVolume: 6486.307989052753,
      usdVolume: 73900491.87788318,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '10.7472000000000000',
      high: '10.8935000000000000',
      low: '10.2694000000000000',
      close: '10.5877000000000000',
      volume: '6662666.8600000000000000',
      quoteVolume: 70509800.96815944,
      btcVolume: 6215.660703350463,
      usdVolume: 70509800.96815944,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '10.5867000000000000',
      high: '10.7149000000000000',
      low: '10.4082000000000000',
      close: '10.6267000000000000',
      volume: '3320266.0600000000000000',
      quoteVolume: 35074078.772476934,
      btcVolume: 3094.4010233917893,
      usdVolume: 35074078.772476934,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '10.6254000000000000',
      high: '11.0000000000000000',
      low: '10.6000000000000000',
      close: '10.9409000000000000',
      volume: '3679623.9400000000000000',
      quoteVolume: 39786628.52001377,
      btcVolume: 3482.0203876379023,
      usdVolume: 39786628.52001377,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '10.9435000000000000',
      high: '11.2226000000000000',
      low: '10.6510000000000000',
      close: '10.9147000000000000',
      volume: '4474849.3100000000000000',
      quoteVolume: 48718810.825249076,
      btcVolume: 4198.544817946333,
      usdVolume: 48718810.825249076,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '10.9116000000000000',
      high: '10.9349000000000000',
      low: '9.7665000000000000',
      close: '9.8589000000000000',
      volume: '7095267.1100000000000000',
      quoteVolume: 73344149.05086827,
      btcVolume: 6182.229702645229,
      usdVolume: 73344149.05086827,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '9.8589000000000000',
      high: '11.1372000000000000',
      low: '9.7864000000000000',
      close: '10.7863000000000000',
      volume: '8276637.4200000000000000',
      quoteVolume: 87959927.25448503,
      btcVolume: 7031.471299457609,
      usdVolume: 87959927.25448503,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '10.7805000000000000',
      high: '12.4173000000000000',
      low: '10.7142000000000000',
      close: '11.9539000000000000',
      volume: '10301801.8300000000000000',
      quoteVolume: 120278047.81922631,
      btcVolume: 9287.505769123953,
      usdVolume: 120278047.81922631,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '11.9551000000000000',
      high: '12.2640000000000000',
      low: '11.5784000000000000',
      close: '11.7869000000000000',
      volume: '6599326.7100000000000000',
      quoteVolume: 78921676.86675517,
      btcVolume: 6109.358731500419,
      usdVolume: 78921676.86675517,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '12.1592000000000000',
      high: '12.9500000000000000',
      low: '11.9839000000000000',
      close: '12.7001000000000000',
      volume: '7531584.3600000000000000',
      quoteVolume: 94612367.21991429,
      btcVolume: 7266.641304869805,
      usdVolume: 94612367.21991429,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '12.7002000000000000',
      high: '12.8574000000000000',
      low: '11.9080000000000000',
      close: '12.1481000000000000',
      volume: '6996448.0600000000000000',
      quoteVolume: 86499461.98964313,
      btcVolume: 6638.643976139153,
      usdVolume: 86499461.98964313,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '12.1480000000000000',
      high: '12.4472000000000000',
      low: '11.2594000000000000',
      close: '11.7096000000000000',
      volume: '8831828.5200000000000000',
      quoteVolume: 104763458.12184793,
      btcVolume: 8038.602388420682,
      usdVolume: 104763458.12184793,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '11.7086000000000000',
      high: '12.4774000000000000',
      low: '11.4900000000000000',
      close: '11.9224000000000000',
      volume: '7598237.9300000000000000',
      quoteVolume: 91297276.70568031,
      btcVolume: 6804.543512604595,
      usdVolume: 91297276.70568031,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '11.9210000000000000',
      high: '12.1509000000000000',
      low: '11.1500000000000000',
      close: '11.4403000000000000',
      volume: '6787355.3400000000000000',
      quoteVolume: 78579989.27577649,
      btcVolume: 5868.232165162447,
      usdVolume: 78579989.27577649,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '11.4448000000000000',
      high: '11.8100000000000000',
      low: '11.0816000000000000',
      close: '11.2772000000000000',
      volume: '8541237.8300000000000000',
      quoteVolume: 97758547.62588826,
      btcVolume: 7336.005858513019,
      usdVolume: 97758547.62588826,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '11.2743000000000000',
      high: '11.4811000000000000',
      low: '10.7200000000000000',
      close: '11.0900000000000000',
      volume: '7958701.4600000000000000',
      quoteVolume: 87936295.50341521,
      btcVolume: 6567.679161107949,
      usdVolume: 87936295.50341521,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '11.0947000000000000',
      high: '11.3981000000000000',
      low: '10.9053000000000000',
      close: '11.2277000000000000',
      volume: '4822720.4700000000000000',
      quoteVolume: 53963475.40082074,
      btcVolume: 3926.032199920614,
      usdVolume: 53963475.40082074,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '11.2259000000000000',
      high: '11.5885000000000000',
      low: '11.0181000000000000',
      close: '11.5722000000000000',
      volume: '3872679.9900000000000000',
      quoteVolume: 43600203.22140587,
      btcVolume: 3168.731592931039,
      usdVolume: 43600203.22140587,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '11.5681000000000000',
      high: '11.6850000000000000',
      low: '10.7049000000000000',
      close: '10.7814000000000000',
      volume: '6919050.6800000000000000',
      quoteVolume: 77130468.23187579,
      btcVolume: 5694.891968008859,
      usdVolume: 77130468.23187579,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '10.7814000000000000',
      high: '10.7814000000000000',
      low: '9.9454000000000000',
      close: '10.3911000000000000',
      volume: '9925376.6400000000000000',
      quoteVolume: 102949946.71757828,
      btcVolume: 7589.417998761801,
      usdVolume: 102949946.71757828,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '10.3982000000000000',
      high: '10.6750000000000000',
      low: '9.7500000000000000',
      close: '10.4713000000000000',
      volume: '8586603.9100000000000000',
      quoteVolume: 87954953.98638126,
      btcVolume: 6320.607986664108,
      usdVolume: 87954953.98638126,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '10.4730000000000000',
      high: '11.2749000000000000',
      low: '10.0764000000000000',
      close: '10.9794000000000000',
      volume: '10616943.9300000000000000',
      quoteVolume: 113756787.2898385,
      btcVolume: 7651.513480243185,
      usdVolume: 113756787.2898385,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '10.9822000000000000',
      high: '12.2813000000000000',
      low: '10.9286000000000000',
      close: '12.1687000000000000',
      volume: '10613986.0500000000000000',
      quoteVolume: 123261012.0719206,
      btcVolume: 7916.228371545654,
      usdVolume: 123261012.0719206,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '12.1686000000000000',
      high: '13.5956000000000000',
      low: '11.3123000000000000',
      close: '11.7638000000000000',
      volume: '24836053.4300000000000000',
      quoteVolume: 312825764.5672563,
      btcVolume: 20466.466375561587,
      usdVolume: 312825764.5672563,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '11.7668000000000000',
      high: '13.1000000000000000',
      low: '11.4770000000000000',
      close: '12.7100000000000000',
      volume: '12895124.5400000000000000',
      quoteVolume: 159621589.8883934,
      btcVolume: 10501.569151809028,
      usdVolume: 159621589.8883934,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '12.7083000000000000',
      high: '13.0026000000000000',
      low: '12.0400000000000000',
      close: '12.5107000000000000',
      volume: '12634927.8300000000000000',
      quoteVolume: 157868780.3591942,
      btcVolume: 10278.693917702634,
      usdVolume: 157868780.3591942,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '12.5182000000000000',
      high: '13.2780000000000000',
      low: '12.3156000000000000',
      close: '13.0017000000000000',
      volume: '12313102.0800000000000000',
      quoteVolume: 158472628.9918838,
      btcVolume: 10350.422830750087,
      usdVolume: 158472628.9918838,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '13.0017000000000000',
      high: '13.4293000000000000',
      low: '12.7500000000000000',
      close: '12.7530000000000000',
      volume: '9402996.4400000000000000',
      quoteVolume: 123227278.88061701,
      btcVolume: 7903.41235731508,
      usdVolume: 123227278.88061701,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '12.7530000000000000',
      high: '13.0956000000000000',
      low: '12.2700000000000000',
      close: '12.4038000000000000',
      volume: '8887319.0400000000000000',
      quoteVolume: 112105873.89800362,
      btcVolume: 7056.930684473489,
      usdVolume: 112105873.89800362,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '12.4038000000000000',
      high: '13.0000000000000000',
      low: '12.3079000000000000',
      close: '12.8687000000000000',
      volume: '7183843.8700000000000000',
      quoteVolume: 91486357.29356198,
      btcVolume: 5621.798860970854,
      usdVolume: 91486357.29356198,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '12.8679000000000000',
      high: '12.9335000000000000',
      low: '12.3000000000000000',
      close: '12.5574000000000000',
      volume: '4779543.0800000000000000',
      quoteVolume: 60108858.82407817,
      btcVolume: 3755.1233582281616,
      usdVolume: 60108858.82407817,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '12.5570000000000000',
      high: '12.6484000000000000',
      low: '11.8156000000000000',
      close: '12.1192000000000000',
      volume: '5705889.4900000000000000',
      quoteVolume: 70141538.75487132,
      btcVolume: 4393.466812059062,
      usdVolume: 70141538.75487132,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '12.1199000000000000',
      high: '12.8132000000000000',
      low: '11.9971000000000000',
      close: '12.6223000000000000',
      volume: '5966554.6100000000000000',
      quoteVolume: 74312723.17503132,
      btcVolume: 4538.090941158825,
      usdVolume: 74312723.17503132,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '12.6204000000000000',
      high: '13.4228000000000000',
      low: '12.5414000000000000',
      close: '13.3944000000000000',
      volume: '9943527.4100000000000000',
      quoteVolume: 128513966.31700706,
      btcVolume: 7475.957078646857,
      usdVolume: 128513966.31700706,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '13.3943000000000000',
      high: '14.2669000000000000',
      low: '12.5850000000000000',
      close: '13.5598000000000000',
      volume: '17994859.8100000000000000',
      quoteVolume: 243378004.2585356,
      btcVolume: 13652.72139475029,
      usdVolume: 243378004.2585356,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '13.5602000000000000',
      high: '14.0000000000000000',
      low: '13.0111000000000000',
      close: '13.6187000000000000',
      volume: '9295294.8300000000000000',
      quoteVolume: 125766975.70460743,
      btcVolume: 7051.256067524977,
      usdVolume: 125766975.70460743,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '13.6117000000000000',
      high: '14.4770000000000000',
      low: '13.4618000000000000',
      close: '14.0933000000000000',
      volume: '10955855.4700000000000000',
      quoteVolume: 153945485.5929507,
      btcVolume: 8393.83606216672,
      usdVolume: 153945485.5929507,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '14.0913000000000000',
      high: '15.5000000000000000',
      low: '13.6876000000000000',
      close: '15.4511000000000000',
      volume: '13999538.8800000000000000',
      quoteVolume: 204973635.11923143,
      btcVolume: 10955.623567420895,
      usdVolume: 204973635.11923143,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '15.4408000000000000',
      high: '15.7179000000000000',
      low: '13.8402000000000000',
      close: '14.5098000000000000',
      volume: '14244002.3200000000000000',
      quoteVolume: 209484344.0724672,
      btcVolume: 11387.241340723021,
      usdVolume: 209484344.0724672,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '14.5098000000000000',
      high: '15.4250000000000000',
      low: '14.2520000000000000',
      close: '15.2746000000000000',
      volume: '12165056.4100000000000000',
      quoteVolume: 181293925.92475033,
      btcVolume: 9837.750688600954,
      usdVolume: 181293925.92475033,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '15.2648000000000000',
      high: '16.3900000000000000',
      low: '14.9430000000000000',
      close: '15.6703000000000000',
      volume: '15636471.1700000000000000',
      quoteVolume: 244854909.96831247,
      btcVolume: 13082.91825525146,
      usdVolume: 244854909.96831247,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '15.6637000000000000',
      high: '15.8600000000000000',
      low: '13.7900000000000000',
      close: '14.2016000000000000',
      volume: '9463367.4500000000000000',
      quoteVolume: 142275307.68800414,
      btcVolume: 7489.979662764517,
      usdVolume: 142275307.68800414,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '14.2180000000000000',
      high: '14.4743000000000000',
      low: '11.2939000000000000',
      close: '12.6030000000000000',
      volume: '24581331.8400000000000000',
      quoteVolume: 307097131.8146662,
      btcVolume: 17664.03164046134,
      usdVolume: 307097131.8146662,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '12.6036000000000000',
      high: '12.9530000000000000',
      low: '11.9454000000000000',
      close: '12.4989000000000000',
      volume: '9126132.6500000000000000',
      quoteVolume: 113170817.60460344,
      btcVolume: 6660.581797963793,
      usdVolume: 113170817.60460344,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '12.5038000000000000',
      high: '13.4300000000000000',
      low: '12.3489000000000000',
      close: '13.0853000000000000',
      volume: '7281492.8400000000000000',
      quoteVolume: 94217840.3930073,
      btcVolume: 5414.366931727638,
      usdVolume: 94217840.3930073,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '13.0848000000000000',
      high: '13.4765000000000000',
      low: '12.8408000000000000',
      close: '13.3800000000000000',
      volume: '6537927.5000000000000000',
      quoteVolume: 85931374.67872472,
      btcVolume: 4773.352624805251,
      usdVolume: 85931374.67872472,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '13.3855000000000000',
      high: '14.4370000000000000',
      low: '13.3855000000000000',
      close: '14.2677000000000000',
      volume: '10253978.8400000000000000',
      quoteVolume: 143593067.98141998,
      btcVolume: 7589.521928283262,
      usdVolume: 143593067.98141998,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '14.2626000000000000',
      high: '14.8328000000000000',
      low: '13.0456000000000000',
      close: '13.3461000000000000',
      volume: '10704338.7400000000000000',
      quoteVolume: 149610565.32318997,
      btcVolume: 7801.160851270977,
      usdVolume: 149610565.32318997,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '13.3544000000000000',
      high: '14.1482000000000000',
      low: '13.0500000000000000',
      close: '13.9799000000000000',
      volume: '6137932.7300000000000000',
      quoteVolume: 83779802.26252918,
      btcVolume: 4424.74642432009,
      usdVolume: 83779802.26252918,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '13.9785000000000000',
      high: '14.2200000000000000',
      low: '13.6446000000000000',
      close: '13.9749000000000000',
      volume: '4275317.8800000000000000',
      quoteVolume: 59535321.2775434,
      btcVolume: 3090.5416607922175,
      usdVolume: 59535321.2775434,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '13.9749000000000000',
      high: '14.0332000000000000',
      low: '12.5101000000000000',
      close: '12.6800000000000000',
      volume: '5794937.4500000000000000',
      quoteVolume: 77275788.62363255,
      btcVolume: 4061.1546698955426,
      usdVolume: 77275788.62363255,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '12.6800000000000000',
      high: '13.3478000000000000',
      low: '12.5265000000000000',
      close: '13.1748000000000000',
      volume: '3411635.3200000000000000',
      quoteVolume: 44668221.07546615,
      btcVolume: 2355.8773509120115,
      usdVolume: 44668221.07546615,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '13.1743000000000000',
      high: '13.5282000000000000',
      low: '12.7671000000000000',
      close: '13.5057000000000000',
      volume: '3866618.8500000000000000',
      quoteVolume: 51005078.82048807,
      btcVolume: 2664.431067672835,
      usdVolume: 51005078.82048807,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '13.5090000000000000',
      high: '13.5536000000000000',
      low: '12.8671000000000000',
      close: '13.0704000000000000',
      volume: '3223051.6500000000000000',
      quoteVolume: 42439345.8764611,
      btcVolume: 2211.4995680383877,
      usdVolume: 42439345.8764611,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '13.0737000000000000',
      high: '13.1929000000000000',
      low: '12.0000000000000000',
      close: '12.1356000000000000',
      volume: '5175641.2100000000000000',
      quoteVolume: 65172913.683855735,
      btcVolume: 3458.1321327537844,
      usdVolume: 65172913.683855735,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '12.1280000000000000',
      high: '12.8290000000000000',
      low: '11.5726000000000000',
      close: '12.6212000000000000',
      volume: '6754613.8300000000000000',
      quoteVolume: 81954160.37834212,
      btcVolume: 4495.429433765595,
      usdVolume: 81954160.37834212,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '12.6200000000000000',
      high: '12.6730000000000000',
      low: '11.8549000000000000',
      close: '11.9172000000000000',
      volume: '3519174.2400000000000000',
      quoteVolume: 42896656.04262703,
      btcVolume: 2346.0300410065424,
      usdVolume: 42896656.04262703,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '11.9172000000000000',
      high: '11.9899000000000000',
      low: '11.4300000000000000',
      close: '11.6335000000000000',
      volume: '4337254.6200000000000000',
      quoteVolume: 50652575.468863204,
      btcVolume: 2820.6350744098336,
      usdVolume: 50647631.718757205,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '11.6393000000000000',
      high: '12.3529000000000000',
      low: '11.6201000000000000',
      close: '12.2259000000000000',
      volume: '3453043.1200000000000000',
      quoteVolume: 41720520.772683,
      btcVolume: 2259.4574947618594,
      usdVolume: 41720520.772683,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '12.2280000000000000',
      high: '13.2697000000000000',
      low: '12.0210000000000000',
      close: '12.9392000000000000',
      volume: '6036848.9700000000000000',
      quoteVolume: 76518629.71278152,
      btcVolume: 3990.4218542597705,
      usdVolume: 76518629.71278152,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '12.9389000000000000',
      high: '12.9800000000000000',
      low: '12.3238000000000000',
      close: '12.8502000000000000',
      volume: '4526653.6900000000000000',
      quoteVolume: 57446821.99876489,
      btcVolume: 2998.157797062246,
      usdVolume: 57446821.99876489,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '12.8513000000000000',
      high: '13.0220000000000000',
      low: '12.4659000000000000',
      close: '12.6818000000000000',
      volume: '2635221.9300000000000000',
      quoteVolume: 33514452.026845157,
      btcVolume: 1732.722900418818,
      usdVolume: 33514452.026845157,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '12.6786000000000000',
      high: '13.5000000000000000',
      low: '12.3119000000000000',
      close: '13.4852000000000000',
      volume: '5644198.7100000000000000',
      quoteVolume: 73189908.58627348,
      btcVolume: 3595.924463613392,
      usdVolume: 73189908.58627348,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '13.4840000000000000',
      high: '14.5038000000000000',
      low: '13.0575000000000000',
      close: '13.4440000000000000',
      volume: '11577270.1700000000000000',
      quoteVolume: 159653805.28869012,
      btcVolume: 7026.0614219511735,
      usdVolume: 159653805.28869012,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '13.4440000000000000',
      high: '14.0235000000000000',
      low: '13.1725000000000000',
      close: '13.5001000000000000',
      volume: '5513708.3800000000000000',
      quoteVolume: 74792637.47296071,
      btcVolume: 3271.2867842547794,
      usdVolume: 74792637.47296071,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '13.5000000000000000',
      high: '13.9697000000000000',
      low: '13.2981000000000000',
      close: '13.4747000000000000',
      volume: '4596156.0500000000000000',
      quoteVolume: 62945458.56621082,
      btcVolume: 2692.9200733659177,
      usdVolume: 62945458.56621082,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '13.4748000000000000',
      high: '13.8000000000000000',
      low: '12.6752000000000000',
      close: '13.0604000000000000',
      volume: '5227497.0200000000000000',
      quoteVolume: 69925342.0503345,
      btcVolume: 2966.1250407952425,
      usdVolume: 69925342.0503345,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '13.0603000000000000',
      high: '13.2700000000000000',
      low: '12.1354000000000000',
      close: '12.3049000000000000',
      volume: '5659600.6800000000000000',
      quoteVolume: 71716093.56996408,
      btcVolume: 3090.609511831206,
      usdVolume: 71716093.56996408,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '12.3028000000000000',
      high: '12.8400000000000000',
      low: '11.8117000000000000',
      close: '12.7842000000000000',
      volume: '6172266.6600000000000000',
      quoteVolume: 76364461.06396206,
      btcVolume: 3308.2084013040967,
      usdVolume: 76364461.06396206,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '12.7811000000000000',
      high: '12.8634000000000000',
      low: '8.0500000000000000',
      close: '10.9736000000000000',
      volume: '15154276.0300000000000000',
      quoteVolume: 168815393.817345,
      btcVolume: 7227.637473629581,
      usdVolume: 168815393.817345,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '10.9730000000000000',
      high: '11.6877000000000000',
      low: '9.8564000000000000',
      close: '11.5724000000000000',
      volume: '14771438.4000000000000000',
      quoteVolume: 157801028.12701663,
      btcVolume: 6807.5861925391055,
      usdVolume: 157801028.12701663,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '11.5705000000000000',
      high: '11.9234000000000000',
      low: '11.0601000000000000',
      close: '11.5543000000000000',
      volume: '7348236.1100000000000000',
      quoteVolume: 84426543.59749623,
      btcVolume: 3504.7630743045383,
      usdVolume: 84426543.59749623,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '11.5640000000000000',
      high: '11.8151000000000000',
      low: '10.6238000000000000',
      close: '10.9809000000000000',
      volume: '9093279.7400000000000000',
      quoteVolume: 101048617.75868994,
      btcVolume: 3982.7085174247964,
      usdVolume: 101048617.75868994,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '10.9840000000000000',
      high: '13.2448000000000000',
      low: '10.4763000000000000',
      close: '12.1541000000000000',
      volume: '18943413.3800000000000000',
      quoteVolume: 226941348.13784474,
      btcVolume: 8381.434369307135,
      usdVolume: 226941348.13784474,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '12.1542000000000000',
      high: '12.9781000000000000',
      low: '11.9710000000000000',
      close: '12.6081000000000000',
      volume: '11270755.2200000000000000',
      quoteVolume: 140279053.6851551,
      btcVolume: 5203.613393033124,
      usdVolume: 140279053.6851551,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '12.6080000000000000',
      high: '13.0351000000000000',
      low: '10.8161000000000000',
      close: '11.7786000000000000',
      volume: '12890847.2600000000000000',
      quoteVolume: 151849176.99178037,
      btcVolume: 5695.149438822997,
      usdVolume: 151849176.99178037,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '11.7786000000000000',
      high: '11.9900000000000000',
      low: '11.0671000000000000',
      close: '11.2435000000000000',
      volume: '7687754.9000000000000000',
      quoteVolume: 88083075.6322653,
      btcVolume: 3132.5608072986693,
      usdVolume: 88083075.6322653,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '11.2437000000000000',
      high: '11.7530000000000000',
      low: '10.9039000000000000',
      close: '11.2364000000000000',
      volume: '6639477.6200000000000000',
      quoteVolume: 75178212.43988732,
      btcVolume: 2609.4447854849886,
      usdVolume: 75178212.43988732,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '11.2364000000000000',
      high: '12.4600000000000000',
      low: '11.0638000000000000',
      close: '11.8477000000000000',
      volume: '8245184.6000000000000000',
      quoteVolume: 97963056.43351899,
      btcVolume: 3350.468743703054,
      usdVolume: 97963056.43351899,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '11.8453000000000000',
      high: '12.4188000000000000',
      low: '11.4303000000000000',
      close: '12.2215000000000000',
      volume: '9617243.6900000000000000',
      quoteVolume: 114480499.1565445,
      btcVolume: 3665.1539501073544,
      usdVolume: 114480499.1565445,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '12.2206000000000000',
      high: '13.9999000000000000',
      low: '11.8355000000000000',
      close: '13.6814000000000000',
      volume: '15927066.3100000000000000',
      quoteVolume: 205085520.50347137,
      btcVolume: 6124.344091873578,
      usdVolume: 205085520.50347137,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '13.6815000000000000',
      high: '15.6440000000000000',
      low: '12.3713000000000000',
      close: '13.5935000000000000',
      volume: '23196077.6800000000000000',
      quoteVolume: 321727867.96833014,
      btcVolume: 10107.698949256002,
      usdVolume: 321727867.96833014,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '13.5962000000000000',
      high: '14.8900000000000000',
      low: '13.0928000000000000',
      close: '14.5162000000000000',
      volume: '14809606.1500000000000000',
      quoteVolume: 208905104.45490494,
      btcVolume: 6485.977306193403,
      usdVolume: 208905104.45490494,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '14.5141000000000000',
      high: '17.2997000000000000',
      low: '14.1427000000000000',
      close: '17.2322000000000000',
      volume: '21280047.1800000000000000',
      quoteVolume: 338322543.10816723,
      btcVolume: 9721.843587977792,
      usdVolume: 338322543.10816723,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '17.2342000000000000',
      high: '17.4100000000000000',
      low: '15.3542000000000000',
      close: '15.9628000000000000',
      volume: '16135019.3000000000000000',
      quoteVolume: 266063447.9945453,
      btcVolume: 6971.427792671456,
      usdVolume: 266063447.9945453,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '15.9659000000000000',
      high: '16.1917000000000000',
      low: '14.1200000000000000',
      close: '15.2381000000000000',
      volume: '14730622.4700000000000000',
      quoteVolume: 225837560.70656046,
      btcVolume: 5699.007296482832,
      usdVolume: 225837560.70656046,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '15.2395000000000000',
      high: '17.8888000000000000',
      low: '15.0029000000000000',
      close: '17.5501000000000000',
      volume: '12807954.5600000000000000',
      quoteVolume: 209112616.22126392,
      btcVolume: 5179.822048220309,
      usdVolume: 209112616.22126392,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '17.5501000000000000',
      high: '18.4386000000000000',
      low: '15.4418000000000000',
      close: '16.2060000000000000',
      volume: '16459987.7000000000000000',
      quoteVolume: 280219098.9046208,
      btcVolume: 7124.706550510726,
      usdVolume: 280219098.9046208,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '16.1998000000000000',
      high: '16.2596000000000000',
      low: '12.5000000000000000',
      close: '14.6393000000000000',
      volume: '21046546.5800000000000000',
      quoteVolume: 299155444.76900715,
      btcVolume: 8793.50832897109,
      usdVolume: 299155444.76900715,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '14.6312000000000000',
      high: '15.4760000000000000',
      low: '13.6701000000000000',
      close: '13.9655000000000000',
      volume: '8959437.3100000000000000',
      quoteVolume: 130225909.90950419,
      btcVolume: 3745.877009935573,
      usdVolume: 130225909.90950419,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '13.9609000000000000',
      high: '16.2356000000000000',
      low: '13.3701000000000000',
      close: '15.9356000000000000',
      volume: '10911746.9200000000000000',
      quoteVolume: 163912112.80508533,
      btcVolume: 4699.829494560408,
      usdVolume: 163912112.80508533,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '15.9396000000000000',
      high: '17.9900000000000000',
      low: '15.3300000000000000',
      close: '17.9804000000000000',
      volume: '13478182.9400000000000000',
      quoteVolume: 222149143.90415877,
      btcVolume: 5733.229551150123,
      usdVolume: 222149143.90415877,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '17.9888000000000000',
      high: '21.4221000000000000',
      low: '17.5355000000000000',
      close: '20.7375000000000000',
      volume: '31054166.3600000000000000',
      quoteVolume: 598915855.4361597,
      btcVolume: 16128.175112869507,
      usdVolume: 598915855.4361597,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '20.7405000000000000',
      high: '22.9600000000000000',
      low: '19.6280000000000000',
      close: '20.1054000000000000',
      volume: '22583400.8600000000000000',
      quoteVolume: 488477166.4998309,
      btcVolume: 13263.862594524382,
      usdVolume: 488477166.4998309,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '20.1003000000000000',
      high: '23.7670000000000000',
      low: '19.3729000000000000',
      close: '23.2819000000000000',
      volume: '21794612.1800000000000000',
      quoteVolume: 467638469.6446607,
      btcVolume: 13093.00975534152,
      usdVolume: 467638469.6446607,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '23.2676000000000000',
      high: '23.6862000000000000',
      low: '21.0600000000000000',
      close: '22.0336000000000000',
      volume: '13776439.8500000000000000',
      quoteVolume: 305061086.4015469,
      btcVolume: 8477.02470472144,
      usdVolume: 305061086.4015469,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '22.0325000000000000',
      high: '22.8248000000000000',
      low: '20.3774000000000000',
      close: '20.5557000000000000',
      volume: '10237213.4000000000000000',
      quoteVolume: 221370711.89643016,
      btcVolume: 6011.617268406959,
      usdVolume: 221370711.89643016,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '20.5437000000000000',
      high: '21.9788000000000000',
      low: '19.0645000000000000',
      close: '21.8482000000000000',
      volume: '12445387.5700000000000000',
      quoteVolume: 253587783.00309002,
      btcVolume: 7255.599211354413,
      usdVolume: 253587783.00309002,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '21.8421000000000000',
      high: '22.0307000000000000',
      low: '18.0611000000000000',
      close: '18.4119000000000000',
      volume: '14800253.5200000000000000',
      quoteVolume: 295655182.9197108,
      btcVolume: 9063.9657818205,
      usdVolume: 295655182.9197108,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '18.4109000000000000',
      high: '22.4725000000000000',
      low: '17.1269000000000000',
      close: '21.5519000000000000',
      volume: '16210103.8000000000000000',
      quoteVolume: 326743324.4039971,
      btcVolume: 10379.828662817843,
      usdVolume: 326743324.4039971,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '21.5467000000000000',
      high: '25.5000000000000000',
      low: '21.1400000000000000',
      close: '24.8046000000000000',
      volume: '14892659.5600000000000000',
      quoteVolume: 357496388.1897744,
      btcVolume: 11065.14109872835,
      usdVolume: 357496388.1897744,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '24.8168000000000000',
      high: '24.9530000000000000',
      low: '23.1612000000000000',
      close: '24.7935000000000000',
      volume: '11903411.3600000000000000',
      quoteVolume: 288283267.4123565,
      btcVolume: 8969.474384440253,
      usdVolume: 288283267.4123565,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '24.7912000000000000',
      high: '25.7824000000000000',
      low: '22.6000000000000000',
      close: '23.3631000000000000',
      volume: '11247196.2700000000000000',
      quoteVolume: 275657899.55470353,
      btcVolume: 8265.386510770037,
      usdVolume: 275657899.55470353,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '22.4042000000000000',
      high: '23.3000000000000000',
      low: '22.3137000000000000',
      close: '23.0981000000000000',
      volume: '2366823.8100000000000000',
      quoteVolume: 54138852.50846183,
      btcVolume: 1682.3671327730394,
      usdVolume: 54138852.50846183,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '23.0914000000000000',
      high: '23.2295000000000000',
      low: '20.2767000000000000',
      close: '20.9586000000000000',
      volume: '8535198.1600000000000000',
      quoteVolume: 182731435.30837923,
      btcVolume: 5920.435835584469,
      usdVolume: 182731435.30837923,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '20.9586000000000000',
      high: '24.4716000000000000',
      low: '20.5734000000000000',
      close: '23.0145000000000000',
      volume: '8468868.5600000000000000',
      quoteVolume: 197396206.1119182,
      btcVolume: 6236.369329316392,
      usdVolume: 197396206.1119182,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '22.9869000000000000',
      high: '24.2400000000000000',
      low: '22.0450000000000000',
      close: '22.7480000000000000',
      volume: '9016409.4900000000000000',
      quoteVolume: 208328902.77130583,
      btcVolume: 5907.088801742898,
      usdVolume: 208328902.77130583,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '22.7480000000000000',
      high: '23.9800000000000000',
      low: '22.1594000000000000',
      close: '23.6399000000000000',
      volume: '6773423.4600000000000000',
      quoteVolume: 156017856.8708368,
      btcVolume: 4581.931097812988,
      usdVolume: 156017856.8708368,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '23.6491000000000000',
      high: '24.6780000000000000',
      low: '22.0603000000000000',
      close: '22.5613000000000000',
      volume: '5361442.2200000000000000',
      quoteVolume: 124076481.29119983,
      btcVolume: 3697.017425316733,
      usdVolume: 124076481.29119983,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '22.5643000000000000',
      high: '23.2000000000000000',
      low: '21.6526000000000000',
      close: '22.8757000000000000',
      volume: '5564509.6300000000000000',
      quoteVolume: 124203143.57187262,
      btcVolume: 3687.457488368662,
      usdVolume: 124203143.57187262,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '22.8752000000000000',
      high: '24.1693000000000000',
      low: '22.7354000000000000',
      close: '23.6582000000000000',
      volume: '6470015.7600000000000000',
      quoteVolume: 150444221.2962143,
      btcVolume: 4337.8794213047395,
      usdVolume: 150444221.2962143,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '23.6578000000000000',
      high: '25.5674000000000000',
      low: '23.5318000000000000',
      close: '25.0870000000000000',
      volume: '8459477.4100000000000000',
      quoteVolume: 208200036.68349785,
      btcVolume: 5709.561538472078,
      usdVolume: 208200036.68349785,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '25.0814000000000000',
      high: '26.1500000000000000',
      low: '22.6174000000000000',
      close: '24.4920000000000000',
      volume: '8629885.3200000000000000',
      quoteVolume: 211063847.95773497,
      btcVolume: 5630.340958663389,
      usdVolume: 211063847.95773497,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '24.4841000000000000',
      high: '27.0000000000000000',
      low: '24.4081000000000000',
      close: '26.3034000000000000',
      volume: '9640790.8100000000000000',
      quoteVolume: 251859619.30684873,
      btcVolume: 6697.035136045025,
      usdVolume: 251859619.30684873,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '26.3023000000000000',
      high: '26.8000000000000000',
      low: '24.2081000000000000',
      close: '25.0231000000000000',
      volume: '7043800.3900000000000000',
      quoteVolume: 178656384.6660932,
      btcVolume: 4511.137870600865,
      usdVolume: 178656384.6660932,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '25.0336000000000000',
      high: '25.2836000000000000',
      low: '22.8100000000000000',
      close: '24.7254000000000000',
      volume: '6499629.3000000000000000',
      quoteVolume: 157729890.3566084,
      btcVolume: 4097.192310639251,
      usdVolume: 157729890.3566084,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '24.7335000000000000',
      high: '25.9800000000000000',
      low: '23.9587000000000000',
      close: '25.4115000000000000',
      volume: '6837186.9500000000000000',
      quoteVolume: 171815141.03402072,
      btcVolume: 4122.278957205255,
      usdVolume: 171815141.03402072,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '25.4070000000000000',
      high: '28.0000000000000000',
      low: '25.0000000000000000',
      close: '27.6034000000000000',
      volume: '9472870.3200000000000000',
      quoteVolume: 246830741.98450413,
      btcVolume: 5292.997466768552,
      usdVolume: 246830741.98450413,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '27.6150000000000000',
      high: '28.6412000000000000',
      low: '25.1227000000000000',
      close: '26.8121000000000000',
      volume: '11717346.8200000000000000',
      quoteVolume: 318420727.69046074,
      btcVolume: 6960.680395575012,
      usdVolume: 318411629.02087176,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '26.8000000000000000',
      high: '28.5705000000000000',
      low: '26.2172000000000000',
      close: '27.8475000000000000',
      volume: '6650588.7300000000000000',
      quoteVolume: 183913909.31249094,
      btcVolume: 3961.896278975156,
      usdVolume: 183913909.31249094,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '27.8645000000000000',
      high: '31.4485000000000000',
      low: '26.6746000000000000',
      close: '30.6265000000000000',
      volume: '9749785.0200000000000000',
      quoteVolume: 284077020.56706005,
      btcVolume: 5975.622685196747,
      usdVolume: 284045968.225476,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '30.6404000000000000',
      high: '34.7573000000000000',
      low: '29.0700000000000000',
      close: '34.1941000000000000',
      volume: '11011345.3200000000000000',
      quoteVolume: 346030518.51178366,
      btcVolume: 7340.703684708217,
      usdVolume: 345996921.8331248,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '34.1969000000000000',
      high: '35.6945000000000000',
      low: '32.3088000000000000',
      close: '33.1565000000000000',
      volume: '8337078.5800000000000000',
      quoteVolume: 283546794.60109466,
      btcVolume: 5868.713681268827,
      usdVolume: 283546794.60109466,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '33.1555000000000000',
      high: '34.9252000000000000',
      low: '27.5000000000000000',
      close: '32.5899000000000000',
      volume: '10469894.2400000000000000',
      quoteVolume: 335112228.9163464,
      btcVolume: 7001.374521254515,
      usdVolume: 333251261.0761585,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '32.5853000000000000',
      high: '33.4269000000000000',
      low: '30.7840000000000000',
      close: '31.9632000000000000',
      volume: '6985113.3100000000000000',
      quoteVolume: 224245398.9060409,
      btcVolume: 4510.950361788866,
      usdVolume: 220012561.30996123,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '31.9630000000000000',
      high: '32.6705000000000000',
      low: '30.0000000000000000',
      close: '32.2324000000000000',
      volume: '5259067.4900000000000000',
      quoteVolume: 166231266.71195933,
      btcVolume: 3258.0237581539295,
      usdVolume: 166231266.71195933,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '32.2256000000000000',
      high: '33.5961000000000000',
      low: '31.7785000000000000',
      close: '32.4801000000000000',
      volume: '5451118.6700000000000000',
      quoteVolume: 177745245.9786863,
      btcVolume: 3426.75446906022,
      usdVolume: 177745245.9786863,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '32.4727000000000000',
      high: '35.5072000000000000',
      low: '31.3799000000000000',
      close: '34.6846000000000000',
      volume: '8360898.6500000000000000',
      quoteVolume: 281294280.39428127,
      btcVolume: 5294.475712342659,
      usdVolume: 281294280.39428127,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '34.6846000000000000',
      high: '36.9307000000000000',
      low: '32.0000000000000000',
      close: '33.9387000000000000',
      volume: '10054962.6900000000000000',
      quoteVolume: 352536639.04229677,
      btcVolume: 6272.494218045403,
      usdVolume: 352532151.53390276,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '33.9293000000000000',
      high: '35.2918000000000000',
      low: '33.2712000000000000',
      close: '34.0915000000000000',
      volume: '5179283.2400000000000000',
      quoteVolume: 177022601.01385555,
      btcVolume: 3109.2651388863383,
      usdVolume: 177022601.01385555,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '34.0916000000000000',
      high: '34.2185000000000000',
      low: '25.2312000000000000',
      close: '31.4542000000000000',
      volume: '10543122.8600000000000000',
      quoteVolume: 319683313.051021,
      btcVolume: 5634.770333904902,
      usdVolume: 301180710.3156824,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '31.4482000000000000',
      high: '31.5822000000000000',
      low: '21.6261000000000000',
      close: '25.9249000000000000',
      volume: '21560485.0600000000000000',
      quoteVolume: 554568596.2290249,
      btcVolume: 11275.518366580607,
      usdVolume: 545229229.464444,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '25.9573000000000000',
      high: '28.8292000000000000',
      low: '24.5050000000000000',
      close: '28.0851000000000000',
      volume: '6709381.7900000000000000',
      quoteVolume: 183384362.28603324,
      btcVolume: 3687.2224811202454,
      usdVolume: 183355744.81425026,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '28.0737000000000000',
      high: '28.6802000000000000',
      low: '24.2509000000000000',
      close: '24.7144000000000000',
      volume: '7893277.6100000000000000',
      quoteVolume: 214328348.89058003,
      btcVolume: 4308.799154273922,
      usdVolume: 214328348.89058003,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '24.7144000000000000',
      high: '26.9906000000000000',
      low: '23.6574000000000000',
      close: '25.1862000000000000',
      volume: '9016978.7100000000000000',
      quoteVolume: 226299006.61160633,
      btcVolume: 4860.302348071939,
      usdVolume: 226070729.98285314,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '25.1650000000000000',
      high: '27.4472000000000000',
      low: '24.8545000000000000',
      close: '26.1896000000000000',
      volume: '7622190.2300000000000000',
      quoteVolume: 197306897.2042366,
      btcVolume: 4194.093858773107,
      usdVolume: 197306897.2042366,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '26.1941000000000000',
      high: '26.7624000000000000',
      low: '22.8578000000000000',
      close: '24.7016000000000000',
      volume: '7513099.0000000000000000',
      quoteVolume: 183181771.37759796,
      btcVolume: 4101.6732584746605,
      usdVolume: 183181771.37759796,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '24.6954000000000000',
      high: '27.7326000000000000',
      low: '24.5758000000000000',
      close: '27.7116000000000000',
      volume: '11662106.9800000000000000',
      quoteVolume: 309292384.27149624,
      btcVolume: 6431.989232060969,
      usdVolume: 309292384.27149624,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '27.7187000000000000',
      high: '30.5251000000000000',
      low: '27.1757000000000000',
      close: '28.4769000000000000',
      volume: '9706793.4600000000000000',
      quoteVolume: 280186454.4698888,
      btcVolume: 5764.268832849585,
      usdVolume: 280186454.4698888,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '28.4640000000000000',
      high: '31.4300000000000000',
      low: '28.1272000000000000',
      close: '29.8500000000000000',
      volume: '6412989.4500000000000000',
      quoteVolume: 193702490.28628224,
      btcVolume: 3844.793474650542,
      usdVolume: 193702490.28628224,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '29.8334000000000000',
      high: '30.5731000000000000',
      low: '27.1891000000000000',
      close: '27.6943000000000000',
      volume: '6789296.5900000000000000',
      quoteVolume: 196271455.16863889,
      btcVolume: 3979.194700761396,
      usdVolume: 196249322.53349993,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '27.6856000000000000',
      high: '28.2338000000000000',
      low: '25.6483000000000000',
      close: '27.8343000000000000',
      volume: '5832853.2100000000000000',
      quoteVolume: 156056362.04028454,
      btcVolume: 3286.3488640127302,
      usdVolume: 156056362.04028454,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '27.8195000000000000',
      high: '28.3985000000000000',
      low: '26.2889000000000000',
      close: '28.1479000000000000',
      volume: '3381150.1600000000000000',
      quoteVolume: 92804331.2379402,
      btcVolume: 1896.9852933650902,
      usdVolume: 91553822.80733496,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '28.1608000000000000',
      high: '29.1258000000000000',
      low: '27.3859000000000000',
      close: '28.5932000000000000',
      volume: '4599258.0200000000000000',
      quoteVolume: 130068525.6266061,
      btcVolume: 2589.6634533421866,
      usdVolume: 130068525.6266061,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '28.5918000000000000',
      high: '31.8469000000000000',
      low: '28.2652000000000000',
      close: '31.8469000000000000',
      volume: '6681348.4500000000000000',
      quoteVolume: 199010644.3594535,
      btcVolume: 3922.101818010387,
      usdVolume: 199010644.3594535,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '31.8227000000000000',
      high: '32.0000000000000000',
      low: '30.3639000000000000',
      close: '31.4914000000000000',
      volume: '4049655.7000000000000000',
      quoteVolume: 126227587.21440111,
      btcVolume: 2342.082973005306,
      usdVolume: 126227587.21440111,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '31.4914000000000000',
      high: '31.7934000000000000',
      low: '29.2545000000000000',
      close: '29.9036000000000000',
      volume: '4027599.8200000000000000',
      quoteVolume: 122397560.04919197,
      btcVolume: 2207.964000897798,
      usdVolume: 122396451.37618196,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '29.8743000000000000',
      high: '30.5285000000000000',
      low: '28.5516000000000000',
      close: '30.1166000000000000',
      volume: '5024365.7100000000000000',
      quoteVolume: 148147003.90674606,
      btcVolume: 2628.6223761839137,
      usdVolume: 148147003.90674606,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '30.1227000000000000',
      high: '30.4110000000000000',
      low: '27.3717000000000000',
      close: '28.1489000000000000',
      volume: '4477144.4100000000000000',
      quoteVolume: 128936166.34822313,
      btcVolume: 1583.1266319722276,
      usdVolume: 89974102.5483421,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '27.7838000000000000',
      high: '30.5700000000000000',
      low: '26.9326000000000000',
      close: '29.7384000000000000',
      volume: '5584451.5200000000000000',
      quoteVolume: 162487581.38361448,
      btcVolume: 2765.323829329903,
      usdVolume: 162487581.38361448,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '29.7506000000000000',
      high: '30.2000000000000000',
      low: '28.5108000000000000',
      close: '28.5469000000000000',
      volume: '3121480.3400000000000000',
      quoteVolume: 91263041.59569518,
      btcVolume: 1507.0857507872481,
      usdVolume: 91064805.56460914,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '28.5310000000000000',
      high: '29.4217000000000000',
      low: '26.8000000000000000',
      close: '27.5091000000000000',
      volume: '5236280.7600000000000000',
      quoteVolume: 145357316.8911458,
      btcVolume: 2536.539090783008,
      usdVolume: 145357316.8911458,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '27.5006000000000000',
      high: '28.1883000000000000',
      low: '26.3975000000000000',
      close: '27.9099000000000000',
      volume: '3202429.1200000000000000',
      quoteVolume: 87907886.1985268,
      btcVolume: 1592.4117076930133,
      usdVolume: 87865565.54260874,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '27.9100000000000000',
      high: '31.2000000000000000',
      low: '27.3027000000000000',
      close: '31.0579000000000000',
      volume: '8119714.6200000000000000',
      quoteVolume: 240849861.95782134,
      btcVolume: 4319.501158108978,
      usdVolume: 240849861.95782134,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '31.0579000000000000',
      high: '31.3588000000000000',
      low: '29.0000000000000000',
      close: '29.3949000000000000',
      volume: '4982847.5300000000000000',
      quoteVolume: 149502922.10217696,
      btcVolume: 2552.5916810317053,
      usdVolume: 149502922.10217696,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '29.3929000000000000',
      high: '30.5510000000000000',
      low: '28.6802000000000000',
      close: '29.7770000000000000',
      volume: '3239965.5200000000000000',
      quoteVolume: 96988651.02827796,
      btcVolume: 1661.0995932340902,
      usdVolume: 96861045.95362605,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '29.7866000000000000',
      high: '31.3809000000000000',
      low: '29.5800000000000000',
      close: '29.6465000000000000',
      volume: '2540903.5400000000000000',
      quoteVolume: 77772991.47447608,
      btcVolume: 1323.149915780175,
      usdVolume: 77770316.54868808,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '29.6564000000000000',
      high: '30.0800000000000000',
      low: '28.5817000000000000',
      close: '29.2097000000000000',
      volume: '2532521.5100000000000000',
      quoteVolume: 74287913.29808004,
      btcVolume: 1297.2908602936614,
      usdVolume: 74260278.37190706,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '29.2117000000000000',
      high: '29.4444000000000000',
      low: '27.1362000000000000',
      close: '27.2431000000000000',
      volume: '3564715.9100000000000000',
      quoteVolume: 101808090.24197517,
      btcVolume: 1809.4616360427897,
      usdVolume: 101741677.36866613,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '27.2551000000000000',
      high: '27.9351000000000000',
      low: '26.6283000000000000',
      close: '26.7813000000000000',
      volume: '3659416.4600000000000000',
      quoteVolume: 99812413.47572565,
      btcVolume: 1215.8619127352338,
      usdVolume: 66191594.700597785,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '26.7831000000000000',
      high: '27.8814000000000000',
      low: '24.0404000000000000',
      close: '24.8684000000000000',
      volume: '4061588.6900000000000000',
      quoteVolume: 106692601.87539235,
      btcVolume: 1959.2264580328283,
      usdVolume: 106692601.87539235,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '24.8679000000000000',
      high: '26.6401000000000000',
      low: '24.3665000000000000',
      close: '25.5350000000000000',
      volume: '4189607.3400000000000000',
      quoteVolume: 106697254.9266262,
      btcVolume: 1689.745893902917,
      usdVolume: 87698677.2009626,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '25.5300000000000000',
      high: '27.4000000000000000',
      low: '25.3993000000000000',
      close: '27.3927000000000000',
      volume: '3356222.6100000000000000',
      quoteVolume: 88633668.99963559,
      btcVolume: 1599.8484710312273,
      usdVolume: 85435262.31795225,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '27.3988000000000000',
      high: '27.4982000000000000',
      low: '26.0030000000000000',
      close: '26.8766000000000000',
      volume: '2032627.2400000000000000',
      quoteVolume: 54412158.06017271,
      btcVolume: 986.9278333127505,
      usdVolume: 54406973.49173972,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '26.8765000000000000',
      high: '27.6624000000000000',
      low: '26.0889000000000000',
      close: '26.6588000000000000',
      volume: '2205293.2300000000000000',
      quoteVolume: 59418251.07729596,
      btcVolume: 1064.179617444524,
      usdVolume: 59406433.69876095,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '26.6576000000000000',
      high: '28.6203000000000000',
      low: '26.3886000000000000',
      close: '28.0357000000000000',
      volume: '3528308.7300000000000000',
      quoteVolume: 97966057.5120884,
      btcVolume: 1709.0159853740718,
      usdVolume: 97916578.44213544,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '28.0356000000000000',
      high: '28.5735000000000000',
      low: '27.4460000000000000',
      close: '27.8301000000000000',
      volume: '2521474.6200000000000000',
      quoteVolume: 70923243.6872522,
      btcVolume: 1213.622206853287,
      usdVolume: 70920571.39197218,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '27.8300000000000000',
      high: '29.4401000000000000',
      low: '26.1800000000000000',
      close: '29.4167000000000000',
      volume: '4353005.8100000000000000',
      quoteVolume: 120857861.76495478,
      btcVolume: 2051.9849029876646,
      usdVolume: 120365548.36152953,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '29.4168000000000000',
      high: '31.1250000000000000',
      low: '29.3143000000000000',
      close: '30.0684000000000000',
      volume: '5308598.1000000000000000',
      quoteVolume: 159884612.16588667,
      btcVolume: 2714.508183126401,
      usdVolume: 159764276.83964464,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '30.0808000000000000',
      high: '32.5555000000000000',
      low: '29.5463000000000000',
      close: '32.4864000000000000',
      volume: '4245194.7400000000000000',
      quoteVolume: 131458201.24949433,
      btcVolume: 2219.951784391892,
      usdVolume: 131448573.62730134,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '32.4867000000000000',
      high: '33.1707000000000000',
      low: '29.1256000000000000',
      close: '29.2930000000000000',
      volume: '5326728.5500000000000000',
      quoteVolume: 167961809.66658044,
      btcVolume: 1795.4395891216325,
      usdVolume: 105857363.34651382,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '29.2930000000000000',
      high: '31.4643000000000000',
      low: '28.6801000000000000',
      close: '30.7471000000000000',
      volume: '2738370.5800000000000000',
      quoteVolume: 83118846.51577824,
      btcVolume: 1249.3258134334203,
      usdVolume: 72051166.9085144,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '30.7473000000000000',
      high: '32.6399000000000000',
      low: '29.6179000000000000',
      close: '32.3791000000000000',
      volume: '4976271.3600000000000000',
      quoteVolume: 155152448.59764713,
      btcVolume: 1991.659027501243,
      usdVolume: 115877633.66876267,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '32.3791000000000000',
      high: '34.9000000000000000',
      low: '31.3074000000000000',
      close: '34.7250000000000000',
      volume: '7244775.1200000000000000',
      quoteVolume: 240433366.91596004,
      btcVolume: 3797.508343023463,
      usdVolume: 221680600.84789953,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '34.7295000000000000',
      high: '35.1200000000000000',
      low: '30.2286000000000000',
      close: '31.1090000000000000',
      volume: '7241883.5600000000000000',
      quoteVolume: 235343305.11428273,
      btcVolume: 3884.6148457101895,
      usdVolume: 221471220.7998364,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '31.1008000000000000',
      high: '32.8999000000000000',
      low: '30.9466000000000000',
      close: '32.7884000000000000',
      volume: '3371094.7200000000000000',
      quoteVolume: 108183402.49988818,
      btcVolume: 1171.9067624824738,
      usdVolume: 66948808.547933206,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '32.7870000000000000',
      high: '33.2876000000000000',
      low: '31.2348000000000000',
      close: '31.5893000000000000',
      volume: '2728512.6100000000000000',
      quoteVolume: 87716711.54413132,
      btcVolume: 1274.446364485902,
      usdVolume: 74249858.40623835,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '31.5961000000000000',
      high: '33.0000000000000000',
      low: '31.0000000000000000',
      close: '31.8944000000000000',
      volume: '3545699.4300000000000000',
      quoteVolume: 113928069.33940087,
      btcVolume: 1828.0827861744679,
      usdVolume: 109414242.01156527,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '31.8980000000000000',
      high: '34.4397000000000000',
      low: '31.7283000000000000',
      close: '33.8811000000000000',
      volume: '4949345.2000000000000000',
      quoteVolume: 164105171.51139703,
      btcVolume: 2677.3634365869484,
      usdVolume: 160031551.35507315,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '33.8811000000000000',
      high: '34.1468000000000000',
      low: '31.8601000000000000',
      close: '33.1080000000000000',
      volume: '3732041.9700000000000000',
      quoteVolume: 123252667.28419851,
      btcVolume: 1636.5603716794567,
      usdVolume: 98349366.92374945,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '33.1023000000000000',
      high: '35.8645000000000000',
      low: '32.3842000000000000',
      close: '35.5217000000000000',
      volume: '5624044.8000000000000000',
      quoteVolume: 192994936.86974585,
      btcVolume: 2998.5339331543933,
      usdVolume: 188012549.6792243,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '35.5416000000000000',
      high: '41.9901000000000000',
      low: '35.0000000000000000',
      close: '41.3641000000000000',
      volume: '13418401.1400000000000000',
      quoteVolume: 506888645.13665795,
      btcVolume: 6514.2283925472175,
      usdVolume: 412737241.69925255,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '41.3371000000000000',
      high: '44.3300000000000000',
      low: '39.6638000000000000',
      close: '42.5601000000000000',
      volume: '12581718.5700000000000000',
      quoteVolume: 528214652.25350446,
      btcVolume: 7445.637896817438,
      usdVolume: 467700360.8493485,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '42.5654000000000000',
      high: '43.2902000000000000',
      low: '38.5273000000000000',
      close: '41.9861000000000000',
      volume: '9648189.8700000000000000',
      quoteVolume: 396462710.2078966,
      btcVolume: 5702.375812210699,
      usdVolume: 350891882.7305064,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '41.9797000000000000',
      high: '44.1116000000000000',
      low: '39.8304000000000000',
      close: '39.9137000000000000',
      volume: '6757853.5600000000000000',
      quoteVolume: 284759254.2188944,
      btcVolume: 2680.560705167462,
      usdVolume: 164429323.88150203,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '39.9135000000000000',
      high: '40.3436000000000000',
      low: '29.0000000000000000',
      close: '39.0799000000000000',
      volume: '14640165.5300000000000000',
      quoteVolume: 530375955.0032846,
      btcVolume: 9097.744625141062,
      usdVolume: 507371033.8404291,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '39.0858000000000000',
      high: '41.9900000000000000',
      low: '34.8144000000000000',
      close: '35.7359000000000000',
      volume: '12978321.7600000000000000',
      quoteVolume: 497670097.2335755,
      btcVolume: 6174.098440246101,
      usdVolume: 347089895.7808836,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '35.7504000000000000',
      high: '39.3901000000000000',
      low: '33.1933000000000000',
      close: '38.9954000000000000',
      volume: '11956908.1000000000000000',
      quoteVolume: 437511825.9164905,
      btcVolume: 7196.936350920713,
      usdVolume: 398982378.77708787,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '38.9983000000000000',
      high: '39.4872000000000000',
      low: '35.8933000000000000',
      close: '36.2274000000000000',
      volume: '6732313.3700000000000000',
      quoteVolume: 253633014.1501259,
      btcVolume: 3719.7137046466846,
      usdVolume: 206621089.2032104,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '36.2364000000000000',
      high: '40.8200000000000000',
      low: '33.7859000000000000',
      close: '35.1306000000000000',
      volume: '11519039.9400000000000000',
      quoteVolume: 432182126.09576267,
      btcVolume: 2025.7054502568894,
      usdVolume: 107167666.04858308,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '35.1256000000000000',
      high: '35.9643000000000000',
      low: '30.2771000000000000',
      close: '35.2121000000000000',
      volume: '14134304.6300000000000000',
      quoteVolume: 465142368.4095787,
      btcVolume: 4763.5180098543515,
      usdVolume: 237164782.14820454,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '35.2121000000000000',
      high: '35.3033000000000000',
      low: '31.0000000000000000',
      close: '31.2443000000000000',
      volume: '6295773.8600000000000000',
      quoteVolume: 206376774.11149526,
      btcVolume: 3817.3264985910882,
      usdVolume: 190343281.2332728,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '31.2370000000000000',
      high: '33.4320000000000000',
      low: '29.8010000000000000',
      close: '31.6920000000000000',
      volume: '4274733.3210000000000000',
      quoteVolume: 135910873.76321998,
      btcVolume: 1405.9735040050004,
      usdVolume: 69698982.35040447,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '31.6900000000000000',
      high: '35.2750000000000000',
      low: '31.3630000000000000',
      close: '35.1300000000000000',
      volume: '5764275.4540000000000000',
      quoteVolume: 196917985.91491917,
      btcVolume: 1940.686409307913,
      usdVolume: 102715801.07328665,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '35.1120000000000000',
      high: '37.6650000000000000',
      low: '34.4830000000000000',
      close: '36.7880000000000000',
      volume: '5453945.1760000000000000',
      quoteVolume: 197434451.9676538,
      btcVolume: 3508.307389527573,
      usdVolume: 191567073.98651674,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '36.7860000000000000',
      high: '37.8080000000000000',
      low: '34.9010000000000000',
      close: '36.2330000000000000',
      volume: '5218990.7380000000000000',
      quoteVolume: 188499275.87359715,
      btcVolume: 3443.115782242929,
      usdVolume: 188499275.87359715,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '36.2530000000000000',
      high: '37.4500000000000000',
      low: '35.0000000000000000',
      close: '36.4670000000000000',
      volume: '4960983.1160000000000000',
      quoteVolume: 178985701.04202038,
      btcVolume: 3326.1200982145674,
      usdVolume: 178985701.04202038,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '36.4650000000000000',
      high: '38.7900000000000000',
      low: '35.8270000000000000',
      close: '38.1000000000000000',
      volume: '5036107.6020000000000000',
      quoteVolume: 189862115.18069306,
      btcVolume: 2169.133779042782,
      usdVolume: 119700732.92635407,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '38.1010000000000000',
      high: '41.2700000000000000',
      low: '37.4280000000000000',
      close: '41.2570000000000000',
      volume: '5223059.2340000000000000',
      quoteVolume: 205464629.83196887,
      btcVolume: 1552.158704895812,
      usdVolume: 89600807.00053962,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '41.2490000000000000',
      high: '41.4600000000000000',
      low: '38.6900000000000000',
      close: '39.4610000000000000',
      volume: '5198773.9980000000000000',
      quoteVolume: 206970460.92982715,
      btcVolume: 2174.314081160398,
      usdVolume: 123461594.93853049,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '39.4610000000000000',
      high: '42.7280000000000000',
      low: '39.1660000000000000',
      close: '42.0520000000000000',
      volume: '6010571.0640000000000000',
      quoteVolume: 246579333.6021091,
      btcVolume: 4045.734938153716,
      usdVolume: 233712903.15058511,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '42.0600000000000000',
      high: '48.5400000000000000',
      low: '38.6340000000000000',
      close: '45.5270000000000000',
      volume: '19237312.3900000000000000',
      quoteVolume: 845809208.6302068,
      btcVolume: 10465.55258596805,
      usdVolume: 575221269.0704849,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '45.5080000000000000',
      high: '51.2000000000000000',
      low: '44.8650000000000000',
      close: '49.5750000000000000',
      volume: '13527073.0750000000000000',
      quoteVolume: 657942604.5017196,
      btcVolume: 10857.379037079556,
      usdVolume: 601168078.6758198,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '49.5760000000000000',
      high: '49.9000000000000000',
      low: '45.3430000000000000',
      close: '47.1540000000000000',
      volume: '9482621.1830000000000000',
      quoteVolume: 450955107.1350383,
      btcVolume: 7582.913753017384,
      usdVolume: 430590815.2935991,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '47.1480000000000000',
      high: '51.9690000000000000',
      low: '45.6660000000000000',
      close: '49.1290000000000000',
      volume: '10577050.1670000000000000',
      quoteVolume: 515234804.58814085,
      btcVolume: 8494.174715359122,
      usdVolume: 484259627.57465845,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '49.1340000000000000',
      high: '50.2650000000000000',
      low: '46.5000000000000000',
      close: '48.5970000000000000',
      volume: '5883822.5840000000000000',
      quoteVolume: 283989339.17529905,
      btcVolume: 4686.917318376704,
      usdVolume: 273537936.3731609,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '48.6070000000000000',
      high: '52.4260000000000000',
      low: '45.8300000000000000',
      close: '52.2690000000000000',
      volume: '8588355.4620000000000000',
      quoteVolume: 426164154.7903979,
      btcVolume: 7030.02275298761,
      usdVolume: 405961264.1488087,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '52.2770000000000000',
      high: '53.0000000000000000',
      low: '41.1600000000000000',
      close: '46.3340000000000000',
      volume: '9923820.9180000000000000',
      quoteVolume: 486677346.87598133,
      btcVolume: 6276.730764781867,
      usdVolume: 357244261.1626152,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '46.3260000000000000',
      high: '49.0000000000000000',
      low: '44.8000000000000000',
      close: '48.8260000000000000',
      volume: '6709350.2410000000000000',
      quoteVolume: 312517087.9033863,
      btcVolume: 4159.683255202934,
      usdVolume: 232124988.1499912,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '48.8050000000000000',
      high: '49.8360000000000000',
      low: '40.9140000000000000',
      close: '41.0070000000000000',
      volume: '8272510.7590000000000000',
      quoteVolume: 383764473.964816,
      btcVolume: 6696.055930825579,
      usdVolume: 367665436.85888445,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '41.5410000000000000',
      high: '44.9580000000000000',
      low: '40.0660000000000000',
      close: '43.2870000000000000',
      volume: '9769701.1020000000000000',
      quoteVolume: 415729412.96724325,
      btcVolume: 7858.229520494778,
      usdVolume: 389116685.6106139,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '43.2730000000000000',
      high: '47.8750000000000000',
      low: '42.9350000000000000',
      close: '46.5670000000000000',
      volume: '6297019.0800000000000000',
      quoteVolume: 286301712.8153783,
      btcVolume: 5462.904943375931,
      usdVolume: 274286488.952303,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '46.5580000000000000',
      high: '47.8000000000000000',
      low: '40.9750000000000000',
      close: '41.4920000000000000',
      volume: '9851823.9350000000000000',
      quoteVolume: 432218709.2421017,
      btcVolume: 8544.101354516299,
      usdVolume: 415295386.7222744,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '41.5120000000000000',
      high: '44.9010000000000000',
      low: '37.9440000000000000',
      close: '40.6700000000000000',
      volume: '9745811.4610000000000000',
      quoteVolume: 402376827.20252913,
      btcVolume: 7873.992844695695,
      usdVolume: 370599721.498782,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '40.6620000000000000',
      high: '40.6740000000000000',
      low: '35.0680000000000000',
      close: '37.1770000000000000',
      volume: '10362229.7890000000000000',
      quoteVolume: 390099667.8940053,
      btcVolume: 8288.179682427983,
      usdVolume: 364544533.9681069,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '37.1760000000000000',
      high: '44.6900000000000000',
      low: '36.6550000000000000',
      close: '42.5660000000000000',
      volume: '11232354.3090000000000000',
      quoteVolume: 458226371.3004289,
      btcVolume: 9965.215932435603,
      usdVolume: 436152928.96179956,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '42.5550000000000000',
      high: '43.5000000000000000',
      low: '21.0000000000000000',
      close: '26.2270000000000000',
      volume: '20210187.3060000000000000',
      quoteVolume: 664059850.4303083,
      btcVolume: 13824.814824954283,
      usdVolume: 538586189.5562819,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '26.2270000000000000',
      high: '33.0500000000000000',
      low: '22.3500000000000000',
      close: '30.6060000000000000',
      volume: '20323256.5380000000000000',
      quoteVolume: 577592046.0905249,
      btcVolume: 10784.808827983394,
      usdVolume: 422558516.6124092,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '30.6330000000000000',
      high: '31.9450000000000000',
      low: '22.0190000000000000',
      close: '25.5460000000000000',
      volume: '13068081.0750000000000000',
      quoteVolume: 353993914.03694314,
      btcVolume: 6932.648993527497,
      usdVolume: 266425708.4751106,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '25.5480000000000000',
      high: '26.2000000000000000',
      low: '22.2250000000000000',
      close: '23.2960000000000000',
      volume: '13032278.1370000000000000',
      quoteVolume: 314829379.610612,
      btcVolume: 5695.951410606267,
      usdVolume: 211218954.28534862,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '23.2930000000000000',
      high: '24.3680000000000000',
      low: '15.0000000000000000',
      close: '19.8440000000000000',
      volume: '28816332.7880000000000000',
      quoteVolume: 543985798.5938362,
      btcVolume: 15126.42507829283,
      usdVolume: 517948800.9596924,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '19.8490000000000000',
      high: '26.4310000000000000',
      low: '19.6770000000000000',
      close: '26.4120000000000000',
      volume: '18217984.6480000000000000',
      quoteVolume: 425942553.28623766,
      btcVolume: 10844.812716329057,
      usdVolume: 401451530.6456157,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '26.4000000000000000',
      high: '27.6440000000000000',
      low: '23.6730000000000000',
      close: '27.5030000000000000',
      volume: '13594283.8180000000000000',
      quoteVolume: 349644756.5528623,
      btcVolume: 8716.278447151888,
      usdVolume: 331359167.24137896,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '27.5050000000000000',
      high: '34.2850000000000000',
      low: '26.8470000000000000',
      close: '34.0180000000000000',
      volume: '14862009.4600000000000000',
      quoteVolume: 456256699.2802189,
      btcVolume: 11201.464045901757,
      usdVolume: 439295034.0456097,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '34.0300000000000000',
      high: '35.3340000000000000',
      low: '30.0330000000000000',
      close: '31.7320000000000000',
      volume: '14928881.5110000000000000',
      quoteVolume: 489547108.0042761,
      btcVolume: 12329.95986455298,
      usdVolume: 477724155.1958763,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '31.7230000000000000',
      high: '32.1850000000000000',
      low: '26.8210000000000000',
      close: '27.8780000000000000',
      volume: '18297258.3920000000000000',
      quoteVolume: 533840739.506926,
      btcVolume: 13937.929959889794,
      usdVolume: 508564072.9063156,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '27.8720000000000000',
      high: '29.7340000000000000',
      low: '24.1200000000000000',
      close: '25.3650000000000000',
      volume: '11813482.7710000000000000',
      quoteVolume: 314260807.1404808,
      btcVolume: 8707.412591102062,
      usdVolume: 305391847.5639692,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '25.3770000000000000',
      high: '29.1680000000000000',
      low: '23.7000000000000000',
      close: '26.8180000000000000',
      volume: '11542374.4850000000000000',
      quoteVolume: 311943247.68354595,
      btcVolume: 8749.034315009025,
      usdVolume: 309860777.6783972,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '26.8130000000000000',
      high: '32.1590000000000000',
      low: '25.5550000000000000',
      close: '32.0980000000000000',
      volume: '11473684.1530000000000000',
      quoteVolume: 330074023.8033171,
      btcVolume: 9049.198752953676,
      usdVolume: 328513729.3170985,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '32.0980000000000000',
      high: '32.6500000000000000',
      low: '29.0350000000000000',
      close: '30.7190000000000000',
      volume: '7595288.2510000000000000',
      quoteVolume: 231579652.6416101,
      btcVolume: 6228.039389492023,
      usdVolume: 228605999.2276237,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '30.7290000000000000',
      high: '32.2180000000000000',
      low: '29.7000000000000000',
      close: '30.6700000000000000',
      volume: '5992710.5570000000000000',
      quoteVolume: 184925689.9538908,
      btcVolume: 4910.509911672765,
      usdVolume: 183636440.3796502,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '30.6690000000000000',
      high: '32.8560000000000000',
      low: '30.0000000000000000',
      close: '32.1980000000000000',
      volume: '6926586.1530000000000000',
      quoteVolume: 220195095.12678242,
      btcVolume: 5686.208264256343,
      usdVolume: 219529228.41781366,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '32.1990000000000000',
      high: '32.2670000000000000',
      low: '27.1720000000000000',
      close: '28.8260000000000000',
      volume: '10756129.4560000000000000',
      quoteVolume: 310574364.8097001,
      btcVolume: 8079.12819316762,
      usdVolume: 299137467.401279,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '28.8290000000000000',
      high: '30.1860000000000000',
      low: '26.0440000000000000',
      close: '27.1290000000000000',
      volume: '8021172.9850000000000000',
      quoteVolume: 224556115.0710708,
      btcVolume: 6072.610138454598,
      usdVolume: 220742793.7734021,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '27.1270000000000000',
      high: '28.1900000000000000',
      low: '26.8180000000000000',
      close: '27.5320000000000000',
      volume: '4071729.1110000000000000',
      quoteVolume: 111593094.49793832,
      btcVolume: 3094.66184741806,
      usdVolume: 111191327.61157948,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '27.5300000000000000',
      high: '28.6930000000000000',
      low: '24.2340000000000000',
      close: '24.5840000000000000',
      volume: '7593474.6300000000000000',
      quoteVolume: 203546009.9958056,
      btcVolume: 5664.404814150535,
      usdVolume: 201030726.639649,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '24.5740000000000000',
      high: '25.0980000000000000',
      low: '21.3340000000000000',
      close: '24.0760000000000000',
      volume: '12533550.4740000000000000',
      quoteVolume: 295383608.8363167,
      btcVolume: 8748.60414936731,
      usdVolume: 286178492.67321956,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '24.0790000000000000',
      high: '25.6260000000000000',
      low: '22.4410000000000000',
      close: '25.5850000000000000',
      volume: '10755352.9180000000000000',
      quoteVolume: 259836017.6864675,
      btcVolume: 7275.701651707724,
      usdVolume: 253653794.22880164,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '25.5890000000000000',
      high: '25.7800000000000000',
      low: '22.7210000000000000',
      close: '23.1140000000000000',
      volume: '8385905.0280000000000000',
      quoteVolume: 201718092.68922827,
      btcVolume: 5398.213856768574,
      usdVolume: 199614065.45911995,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '23.1170000000000000',
      high: '23.3740000000000000',
      low: '21.0000000000000000',
      close: '21.4780000000000000',
      volume: '7674813.3900000000000000',
      quoteVolume: 172255303.31648567,
      btcVolume: 4653.500277683774,
      usdVolume: 171879481.40592545,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '21.4750000000000000',
      high: '22.2870000000000000',
      low: '20.0000000000000000',
      close: '21.6700000000000000',
      volume: '8849284.6130000000000000',
      quoteVolume: 186854318.51986963,
      btcVolume: 5161.93685251144,
      usdVolume: 184384310.62285846,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '21.6710000000000000',
      high: '23.8860000000000000',
      low: '20.3960000000000000',
      close: '23.3120000000000000',
      volume: '8516193.5180000000000000',
      quoteVolume: 184993026.3649717,
      btcVolume: 5018.546755318045,
      usdVolume: 183990625.90337497,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '23.3140000000000000',
      high: '25.2800000000000000',
      low: '22.8370000000000000',
      close: '24.9730000000000000',
      volume: '8598594.0080000000000000',
      quoteVolume: 205633987.74170867,
      btcVolume: 5124.726546014837,
      usdVolume: 204447032.09525958,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '24.9670000000000000',
      high: '26.4580000000000000',
      low: '24.3760000000000000',
      close: '24.4820000000000000',
      volume: '7585322.2020000000000000',
      quoteVolume: 193173661.50221747,
      btcVolume: 4783.978518048711,
      usdVolume: 192339881.37543938,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '24.4800000000000000',
      high: '24.6400000000000000',
      low: '22.9180000000000000',
      close: '23.0440000000000000',
      volume: '7495769.8280000000000000',
      quoteVolume: 178312029.61373052,
      btcVolume: 4478.7932855389,
      usdVolume: 176264709.6132166,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '23.0420000000000000',
      high: '24.4450000000000000',
      low: '22.8100000000000000',
      close: '23.3960000000000000',
      volume: '5230276.4320000000000000',
      quoteVolume: 123641681.8274587,
      btcVolume: 3158.8471853964566,
      usdVolume: 121967618.36204572,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '23.4010000000000000',
      high: '23.4250000000000000',
      low: '20.5260000000000000',
      close: '21.2330000000000000',
      volume: '6979309.1900000000000000',
      quoteVolume: 152221963.2704614,
      btcVolume: 4077.4579812512793,
      usdVolume: 150001420.01759854,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '21.2360000000000000',
      high: '21.9500000000000000',
      low: '20.0000000000000000',
      close: '20.2460000000000000',
      volume: '5015956.9490000000000000',
      quoteVolume: 105884657.21403985,
      btcVolume: 2957.13775712415,
      usdVolume: 105574108.67201178,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '20.2630000000000000',
      high: '22.0710000000000000',
      low: '19.0770000000000000',
      close: '21.6460000000000000',
      volume: '6709724.1760000000000000',
      quoteVolume: 136982147.47085837,
      btcVolume: 3882.7672656123696,
      usdVolume: 135510283.7439176,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '21.6410000000000000',
      high: '21.8810000000000000',
      low: '16.9810000000000000',
      close: '17.3650000000000000',
      volume: '16151190.0200000000000000',
      quoteVolume: 304924588.43656045,
      btcVolume: 9030.919682300091,
      usdVolume: 296698047.37943095,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '17.3570000000000000',
      high: '18.7970000000000000',
      low: '15.0000000000000000',
      close: '16.8500000000000000',
      volume: '19773357.5780000000000000',
      quoteVolume: 331030753.695022,
      btcVolume: 10016.698622465823,
      usdVolume: 314934189.5838592,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '16.8400000000000000',
      high: '19.1990000000000000',
      low: '16.1490000000000000',
      close: '18.3950000000000000',
      volume: '10930842.9690000000000000',
      quoteVolume: 198104336.2413875,
      btcVolume: 5677.69271694415,
      usdVolume: 191351833.36313608,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '18.3930000000000000',
      high: '19.5250000000000000',
      low: '17.3520000000000000',
      close: '19.0600000000000000',
      volume: '6708926.7880000000000000',
      quoteVolume: 124897019.4366688,
      btcVolume: 3676.6447137809605,
      usdVolume: 124160107.18648963,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '19.0600000000000000',
      high: '19.5000000000000000',
      low: '16.8130000000000000',
      close: '16.8700000000000000',
      volume: '10048473.2290000000000000',
      quoteVolume: 180145249.5753493,
      btcVolume: 5424.613837041398,
      usdVolume: 179815215.34201533,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '16.8720000000000000',
      high: '17.4950000000000000',
      low: '15.9200000000000000',
      close: '16.9520000000000000',
      volume: '8343823.1670000000000000',
      quoteVolume: 138685945.58320868,
      btcVolume: 4317.21304493639,
      usdVolume: 135592125.3028405,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '16.9510000000000000',
      high: '18.4120000000000000',
      low: '16.5910000000000000',
      close: '18.4060000000000000',
      volume: '6649981.0660000000000000',
      quoteVolume: 114152516.37204297,
      btcVolume: 3403.722727537482,
      usdVolume: 112739292.89915286,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '18.4070000000000000',
      high: '19.4340000000000000',
      low: '17.9020000000000000',
      close: '18.8300000000000000',
      volume: '7282776.6190000000000000',
      quoteVolume: 135071888.30002892,
      btcVolume: 3906.1907284798945,
      usdVolume: 134704190.30824476,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '18.8300000000000000',
      high: '20.5460000000000000',
      low: '18.7520000000000000',
      close: '19.5350000000000000',
      volume: '6574919.5160000000000000',
      quoteVolume: 129862268.00902891,
      btcVolume: 3645.3920298803437,
      usdVolume: 129659684.4622509,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '19.5310000000000000',
      high: '19.7730000000000000',
      low: '18.1490000000000000',
      close: '19.5160000000000000',
      volume: '6554785.3410000000000000',
      quoteVolume: 124177607.62473075,
      btcVolume: 3561.7088830145212,
      usdVolume: 124094990.07156971,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '19.5170000000000000',
      high: '19.5330000000000000',
      low: '17.6030000000000000',
      close: '18.1860000000000000',
      volume: '6074285.8190000000000000',
      quoteVolume: 110566739.74832156,
      btcVolume: 3272.3128181398106,
      usdVolume: 110064065.08651572,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '18.1840000000000000',
      high: '18.5230000000000000',
      low: '17.2560000000000000',
      close: '18.2740000000000000',
      volume: '5394371.4130000000000000',
      quoteVolume: 95752765.26831836,
      btcVolume: 2873.9523665643746,
      usdVolume: 95533297.25328133,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '18.2770000000000000',
      high: '18.8940000000000000',
      low: '17.8640000000000000',
      close: '18.5430000000000000',
      volume: '3247443.1790000000000000',
      quoteVolume: 59971889.21668191,
      btcVolume: 1743.3323281938071,
      usdVolume: 59881835.060770914,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '18.5430000000000000',
      high: '19.9250000000000000',
      low: '18.0020000000000000',
      close: '19.2420000000000000',
      volume: '4486291.0750000000000000',
      quoteVolume: 85193944.29341578,
      btcVolume: 2409.86380003794,
      usdVolume: 85114681.7393157,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '19.2380000000000000',
      high: '19.2420000000000000',
      low: '17.7670000000000000',
      close: '18.3620000000000000',
      volume: '5244315.0980000000000000',
      quoteVolume: 96893912.50225921,
      btcVolume: 2815.4797708645337,
      usdVolume: 95800634.92579712,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '18.3640000000000000',
      high: '20.2710000000000000',
      low: '18.3170000000000000',
      close: '20.0730000000000000',
      volume: '7942424.0840000000000000',
      quoteVolume: 155645652.46806923,
      btcVolume: 4515.283695510405,
      usdVolume: 154167558.60065004,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '20.0730000000000000',
      high: '21.2740000000000000',
      low: '19.6200000000000000',
      close: '19.7880000000000000',
      volume: '7220562.4210000000000000',
      quoteVolume: 148395155.42902967,
      btcVolume: 4299.919566919362,
      usdVolume: 148395155.42902967,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '19.7860000000000000',
      high: '19.8730000000000000',
      low: '18.1380000000000000',
      close: '18.3530000000000000',
      volume: '7787413.9360000000000000',
      quoteVolume: 146836983.45400667,
      btcVolume: 4442.217674403576,
      usdVolume: 145991527.75807318,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '18.3650000000000000',
      high: '18.8770000000000000',
      low: '17.6170000000000000',
      close: '18.6560000000000000',
      volume: '5272793.7130000000000000',
      quoteVolume: 96043496.92060412,
      btcVolume: 2903.464250314309,
      usdVolume: 96030029.07034913,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '18.6560000000000000',
      high: '18.9880000000000000',
      low: '17.7340000000000000',
      close: '18.2040000000000000',
      volume: '4078447.0630000000000000',
      quoteVolume: 74240182.6654956,
      btcVolume: 2201.704167208646,
      usdVolume: 74224861.40051863,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '18.2090000000000000',
      high: '18.5810000000000000',
      low: '17.8610000000000000',
      close: '18.3830000000000000',
      volume: '2730333.2010000000000000',
      quoteVolume: 49800226.79839872,
      btcVolume: 1472.494351442755,
      usdVolume: 49793240.52506473,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '18.3820000000000000',
      high: '18.6090000000000000',
      low: '17.0000000000000000',
      close: '17.5180000000000000',
      volume: '3783915.1710000000000000',
      quoteVolume: 67410348.47561233,
      btcVolume: 2009.4146255562289,
      usdVolume: 67298959.85275029,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '17.5140000000000000',
      high: '17.7660000000000000',
      low: '16.6630000000000000',
      close: '16.8700000000000000',
      volume: '3488186.4250000000000000',
      quoteVolume: 60230003.646684654,
      btcVolume: 1836.6843254841956,
      usdVolume: 60221186.657093674,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '16.8730000000000000',
      high: '17.4190000000000000',
      low: '16.0140000000000000',
      close: '16.9360000000000000',
      volume: '5441562.0200000000000000',
      quoteVolume: 90914973.0594299,
      btcVolume: 2788.0780014295497,
      usdVolume: 90500474.54788788,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '16.9360000000000000',
      high: '17.2380000000000000',
      low: '15.5170000000000000',
      close: '15.7640000000000000',
      volume: '6198386.0130000000000000',
      quoteVolume: 99985406.05859742,
      btcVolume: 3118.566762095075,
      usdVolume: 99653864.88515042,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '15.7640000000000000',
      high: '16.3300000000000000',
      low: '15.1250000000000000',
      close: '15.2340000000000000',
      volume: '4106915.0750000000000000',
      quoteVolume: 64218657.385411024,
      btcVolume: 2026.355929873666,
      usdVolume: 64218546.10895903,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '15.2340000000000000',
      high: '15.6780000000000000',
      low: '15.0200000000000000',
      close: '15.3520000000000000',
      volume: '4957872.3330000000000000',
      quoteVolume: 76011680.64757319,
      btcVolume: 2401.8918472972196,
      usdVolume: 75811199.49437419,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '15.3520000000000000',
      high: '16.0780000000000000',
      low: '15.1550000000000000',
      close: '15.4700000000000000',
      volume: '3946657.1550000000000000',
      quoteVolume: 61781931.20700029,
      btcVolume: 1945.5860240070042,
      usdVolume: 61763430.72707828,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '15.4750000000000000',
      high: '15.6410000000000000',
      low: '14.4040000000000000',
      close: '14.5280000000000000',
      volume: '5828975.8470000000000000',
      quoteVolume: 86676083.7316871,
      btcVolume: 2787.3535740700127,
      usdVolume: 86630306.81259608,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '14.5290000000000000',
      high: '14.7830000000000000',
      low: '13.3840000000000000',
      close: '13.7420000000000000',
      volume: '7649757.9780000000000000',
      quoteVolume: 105449843.69424452,
      btcVolume: 3529.459900354445,
      usdVolume: 105367660.82367453,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '13.7440000000000000',
      high: '15.7970000000000000',
      low: '13.4600000000000000',
      close: '15.2970000000000000',
      volume: '8493449.3690000000000000',
      quoteVolume: 125644680.88342938,
      btcVolume: 3997.9525201581914,
      usdVolume: 124994012.93451238,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '15.3000000000000000',
      high: '16.1760000000000000',
      low: '14.9610000000000000',
      close: '16.0710000000000000',
      volume: '5857032.9790000000000000',
      quoteVolume: 90738306.1379759,
      btcVolume: 2820.8161649730764,
      usdVolume: 90645833.05113186,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '16.0730000000000000',
      high: '16.4860000000000000',
      low: '15.2300000000000000',
      close: '15.5970000000000000',
      volume: '4154495.5190000000000000',
      quoteVolume: 66029359.02171643,
      btcVolume: 2038.14247445674,
      usdVolume: 66029359.02171643,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
    avax: {
    id: 'avax',
    name: 'AVAX',
    symbol: 'AVAX',
    image: 'assets/images/coin-logo/BTC.png',
    activity24h: 1241256,
    availableAmount: 1328.232323,
    usdxConversion: 1,
    allTimeValue: 51512390,
    allTimeAsset: 765421,
    candleData: [
      {
      open: '9.7285000000000000',
      high: '9.7638000000000000',
      low: '9.6022000000000000',
      close: '9.7207000000000000',
      volume: '838913.9200000000000000',
      quoteVolume: 8136588.44204,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-28T00:00:00.000Z'
      },
      {
      open: '9.7207000000000000',
      high: '9.7317000000000000',
      low: '9.3748000000000000',
      close: '9.4450000000000000',
      volume: '788866.5700000000000000',
      quoteVolume: 7525106.643176,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-29T00:00:00.000Z'
      },
      {
      open: '9.4516000000000000',
      high: '9.5386000000000000',
      low: '9.4003000000000000',
      close: '9.4521000000000000',
      volume: '719748.6600000000000000',
      quoteVolume: 6803212.351281,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-30T00:00:00.000Z'
      },
      {
      open: '9.4513000000000000',
      high: '9.5546000000000000',
      low: '9.2811000000000000',
      close: '9.4807000000000000',
      volume: '707061.5900000000000000',
      quoteVolume: 6671089.908958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-31T00:00:00.000Z'
      },
      {
      open: '9.4837000000000000',
      high: '9.6600000000000000',
      low: '9.4303000000000000',
      close: '9.6192000000000000',
      volume: '791111.4800000000000000',
      quoteVolume: 7528202.69593,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-01T00:00:00.000Z'
      },
      {
      open: '9.6106000000000000',
      high: '9.7192000000000000',
      low: '9.5467000000000000',
      close: '9.6421000000000000',
      volume: '850927.5000000000000000',
      quoteVolume: 8194490.466364,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-02T00:00:00.000Z'
      },
      {
      open: '9.6607000000000000',
      high: '9.7440000000000000',
      low: '9.4888000000000000',
      close: '9.5889000000000000',
      volume: '812589.6100000000000000',
      quoteVolume: 7781309.311534,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-03T00:00:00.000Z'
      },
      {
      open: '9.5880000000000000',
      high: '9.8974000000000000',
      low: '9.5000000000000000',
      close: '9.8190000000000000',
      volume: '934579.3000000000000000',
      quoteVolume: 9056755.741708,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-04T00:00:00.000Z'
      },
      {
      open: '9.8084000000000000',
      high: '9.8294000000000000',
      low: '9.6603000000000000',
      close: '9.7333000000000000',
      volume: '836650.9100000000000000',
      quoteVolume: 8131267.116155,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-05T00:00:00.000Z'
      },
      {
      open: '9.7509000000000000',
      high: '9.8891000000000000',
      low: '9.6703000000000000',
      close: '9.8810000000000000',
      volume: '887223.8200000000000000',
      quoteVolume: 8677224.600936,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-06T00:00:00.000Z'
      },
      {
      open: '9.8718000000000000',
      high: '9.9776000000000000',
      low: '9.8100000000000000',
      close: '9.8970000000000000',
      volume: '865071.5600000000000000',
      quoteVolume: 8556329.625219,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-07T00:00:00.000Z'
      },
      {
      open: '9.8970000000000000',
      high: '9.9268000000000000',
      low: '9.6503000000000000',
      close: '9.6860000000000000',
      volume: '798983.4400000000000000',
      quoteVolume: 7826431.10844,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-08T00:00:00.000Z'
      },
      {
      open: '9.6868000000000000',
      high: '9.7497000000000000',
      low: '9.5169000000000000',
      close: '9.6141000000000000',
      volume: '735975.4500000000000000',
      quoteVolume: 7091790.464076,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-09T00:00:00.000Z'
      },
      {
      open: '9.6132000000000000',
      high: '9.6896000000000000',
      low: '9.5672000000000000',
      close: '9.5898000000000000',
      volume: '782308.0400000000000000',
      quoteVolume: 7533998.386684,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-10T00:00:00.000Z'
      },
      {
      open: '9.5898000000000000',
      high: '9.6210000000000000',
      low: '9.3500000000000000',
      close: '9.5611000000000000',
      volume: '755404.2100000000000000',
      quoteVolume: 7180788.710182,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-11T00:00:00.000Z'
      },
      {
      open: '9.5586000000000000',
      high: '9.5891000000000000',
      low: '9.3310000000000000',
      close: '9.4862000000000000',
      volume: '723363.6300000000000000',
      quoteVolume: 6843645.615956,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-12T00:00:00.000Z'
      },
      {
      open: '9.4862000000000000',
      high: '9.5891000000000000',
      low: '9.3602000000000000',
      close: '9.3936000000000000',
      volume: '658735.4600000000000000',
      quoteVolume: 6227393.415766,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-13T00:00:00.000Z'
      },
      {
      open: '9.3870000000000000',
      high: '9.4453000000000000',
      low: '8.3359000000000000',
      close: '8.6811000000000000',
      volume: '672611.0600000000000000',
      quoteVolume: 5964449.985309,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-14T00:00:00.000Z'
      },
      {
      open: '8.6896000000000000',
      high: '8.7342000000000000',
      low: '7.8000000000000000',
      close: '8.3793000000000000',
      volume: '822045.1000000000000000',
      quoteVolume: 6802948.925313,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-15T00:00:00.000Z'
      },
      {
      open: '8.3793000000000000',
      high: '8.4049000000000000',
      low: '7.8000000000000000',
      close: '8.0100000000000000',
      volume: '901842.2500000000000000',
      quoteVolume: 7292373.45122,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-16T00:00:00.000Z'
      },
      {
      open: '8.0097000000000000',
      high: '8.0192000000000000',
      low: '7.6846000000000000',
      close: '7.8494000000000000',
      volume: '752867.4700000000000000',
      quoteVolume: 5865731.485472,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-17T00:00:00.000Z'
      },
      {
      open: '7.8493000000000000',
      high: '8.1660000000000000',
      low: '7.8301000000000000',
      close: '8.0832000000000000',
      volume: '604839.7500000000000000',
      quoteVolume: 4838054.114957,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-18T00:00:00.000Z'
      },
      {
      open: '8.0833000000000000',
      high: '8.0918000000000000',
      low: '6.6453000000000000',
      close: '6.6844000000000000',
      volume: '1052222.0800000000000000',
      quoteVolume: 7512579.853245,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-19T00:00:00.000Z'
      },
      {
      open: '6.7040000000000000',
      high: '6.9050000000000000',
      low: '5.6120000000000000',
      close: '5.9200000000000000',
      volume: '1624846.6400000000000000',
      quoteVolume: 9912905.068409,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-20T00:00:00.000Z'
      },
      {
      open: '5.9419000000000000',
      high: '6.3230000000000000',
      low: '5.6502000000000000',
      close: '6.2894000000000000',
      volume: '842731.4100000000000000',
      quoteVolume: 5084237.945527,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-21T00:00:00.000Z'
      },
      {
      open: '6.2896000000000000',
      high: '6.3727000000000000',
      low: '5.7172000000000000',
      close: '5.7700000000000000',
      volume: '630372.0700000000000000',
      quoteVolume: 3840547.082434,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-22T00:00:00.000Z'
      },
      {
      open: '5.7755000000000000',
      high: '5.8396000000000000',
      low: '5.4500000000000000',
      close: '5.6611000000000000',
      volume: '598556.9000000000000000',
      quoteVolume: 3357369.283447,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-23T00:00:00.000Z'
      },
      {
      open: '5.6525000000000000',
      high: '5.8055000000000000',
      low: '4.7632000000000000',
      close: '5.1930000000000000',
      volume: '917700.2100000000000000',
      quoteVolume: 4844925.914768,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-24T00:00:00.000Z'
      },
      {
      open: '5.1990000000000000',
      high: '5.3800000000000000',
      low: '4.5320000000000000',
      close: '5.2873000000000000',
      volume: '1003892.6700000000000000',
      quoteVolume: 4998073.759825,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-25T00:00:00.000Z'
      },
      {
      open: '5.2800000000000000',
      high: '5.4000000000000000',
      low: '4.7568000000000000',
      close: '5.0631000000000000',
      volume: '852519.3700000000000000',
      quoteVolume: 4333954.593448,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-26T00:00:00.000Z'
      },
      {
      open: '5.0630000000000000',
      high: '5.0630000000000000',
      low: '4.7045000000000000',
      close: '4.9840000000000000',
      volume: '701759.1100000000000000',
      quoteVolume: 3434036.230959,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-27T00:00:00.000Z'
      },
      {
      open: '4.9840000000000000',
      high: '5.4603000000000000',
      low: '4.9840000000000000',
      close: '5.3499000000000000',
      volume: '1144816.8000000000000000',
      quoteVolume: 6061490.260849,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-28T00:00:00.000Z'
      },
      {
      open: '5.3498000000000000',
      high: '5.6344000000000000',
      low: '5.1243000000000000',
      close: '5.3805000000000000',
      volume: '1018322.9900000000000000',
      quoteVolume: 5461031.121114,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-29T00:00:00.000Z'
      },
      {
      open: '5.3902000000000000',
      high: '5.4480000000000000',
      low: '4.9534000000000000',
      close: '5.1150000000000000',
      volume: '709284.0200000000000000',
      quoteVolume: 3662427.823626,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-30T00:00:00.000Z'
      },
      {
      open: '5.1122000000000000',
      high: '5.4006000000000000',
      low: '5.0072000000000000',
      close: '5.2899000000000000',
      volume: '925388.4200000000000000',
      quoteVolume: 4874052.81019,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-01T00:00:00.000Z'
      },
      {
      open: '5.2787000000000000',
      high: '5.4357000000000000',
      low: '5.2050000000000000',
      close: '5.2888000000000000',
      volume: '853331.6100000000000000',
      quoteVolume: 4534872.243938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-02T00:00:00.000Z'
      },
      {
      open: '5.2831000000000000',
      high: '5.2956000000000000',
      low: '5.0100000000000000',
      close: '5.1266000000000000',
      volume: '673983.3800000000000000',
      quoteVolume: 3451158.980001,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-03T00:00:00.000Z'
      },
      {
      open: '5.1294000000000000',
      high: '6.3649000000000000',
      low: '5.0856000000000000',
      close: '5.9020000000000000',
      volume: '2238490.1600000000000000',
      quoteVolume: 12882498.1221741,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-04T00:00:00.000Z'
      },
      {
      open: '5.9020000000000000',
      high: '6.6200000000000000',
      low: '5.7225000000000000',
      close: '6.0727000000000000',
      volume: '4208247.7800000000000000',
      quoteVolume: 25915024.891642,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-05T00:00:00.000Z'
      },
      {
      open: '6.0689000000000000',
      high: '6.1090000000000000',
      low: '4.8500000000000000',
      close: '4.9023000000000000',
      volume: '3033034.7700000000000000',
      quoteVolume: 16605405.771891,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-06T00:00:00.000Z'
      },
      {
      open: '4.8977000000000000',
      high: '4.9997000000000000',
      low: '4.1200000000000000',
      close: '4.5160000000000000',
      volume: '2250208.5300000000000000',
      quoteVolume: 10282558.541603,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-07T00:00:00.000Z'
      },
      {
      open: '4.5118000000000000',
      high: '4.8009000000000000',
      low: '4.3001000000000000',
      close: '4.6361000000000000',
      volume: '1260790.7000000000000000',
      quoteVolume: 5751093.241387,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-08T00:00:00.000Z'
      },
      {
      open: '4.6280000000000000',
      high: '5.0003000000000000',
      low: '4.5167000000000000',
      close: '4.7693000000000000',
      volume: '1505537.6400000000000000',
      quoteVolume: 7177505.612086,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-09T00:00:00.000Z'
      },
      {
      open: '4.7693000000000000',
      high: '4.9398000000000000',
      low: '4.4698000000000000',
      close: '4.6276000000000000',
      volume: '928495.8500000000000000',
      quoteVolume: 4361795.739574,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-10T00:00:00.000Z'
      },
      {
      open: '4.6297000000000000',
      high: '4.9295000000000000',
      low: '4.5000000000000000',
      close: '4.8973000000000000',
      volume: '1232098.4300000000000000',
      quoteVolume: 5766708.758249,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-11T00:00:00.000Z'
      },
      {
      open: '4.8936000000000000',
      high: '5.1500000000000000',
      low: '4.7390000000000000',
      close: '4.9815000000000000',
      volume: '1196552.2900000000000000',
      quoteVolume: 5925304.975092,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-12T00:00:00.000Z'
      },
      {
      open: '4.9820000000000000',
      high: '4.9933000000000000',
      low: '4.5563000000000000',
      close: '4.6384000000000000',
      volume: '1003196.1800000000000000',
      quoteVolume: 4804678.850471,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-13T00:00:00.000Z'
      },
      {
      open: '4.6399000000000000',
      high: '4.7500000000000000',
      low: '4.4300000000000000',
      close: '4.5211000000000000',
      volume: '946936.1900000000000000',
      quoteVolume: 4370044.405834,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-14T00:00:00.000Z'
      },
      {
      open: '4.5148000000000000',
      high: '4.5990000000000000',
      low: '4.4436000000000000',
      close: '4.5531000000000000',
      volume: '969833.4400000000000000',
      quoteVolume: 4387901.297965,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-15T00:00:00.000Z'
      },
      {
      open: '4.5479000000000000',
      high: '4.7064000000000000',
      low: '4.5089000000000000',
      close: '4.6160000000000000',
      volume: '1069563.7700000000000000',
      quoteVolume: 4963613.541897,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-16T00:00:00.000Z'
      },
      {
      open: '4.6193000000000000',
      high: '5.1271000000000000',
      low: '4.6010000000000000',
      close: '5.0650000000000000',
      volume: '1507154.3700000000000000',
      quoteVolume: 7344673.590916,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-17T00:00:00.000Z'
      },
      {
      open: '5.0650000000000000',
      high: '5.5362000000000000',
      low: '4.9000000000000000',
      close: '5.5308000000000000',
      volume: '1906857.2400000000000000',
      quoteVolume: 9864772.820849,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-18T00:00:00.000Z'
      },
      {
      open: '5.5349000000000000',
      high: '5.8331000000000000',
      low: '5.2847000000000000',
      close: '5.3633000000000000',
      volume: '1805892.7600000000000000',
      quoteVolume: 9964231.850427,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-19T00:00:00.000Z'
      },
      {
      open: '5.3627000000000000',
      high: '5.7900000000000000',
      low: '5.2369000000000000',
      close: '5.6766000000000000',
      volume: '2160302.6100000000000000',
      quoteVolume: 12060645.667534,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-20T00:00:00.000Z'
      },
      {
      open: '5.6761000000000000',
      high: '5.7897000000000000',
      low: '5.3166000000000000',
      close: '5.4766000000000000',
      volume: '1592262.7400000000000000',
      quoteVolume: 8888728.773336,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-21T00:00:00.000Z'
      },
      {
      open: '5.4766000000000000',
      high: '5.8802000000000000',
      low: '5.3950000000000000',
      close: '5.8398000000000000',
      volume: '1775214.5900000000000000',
      quoteVolume: 10028050.954402,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-22T00:00:00.000Z'
      },
      {
      open: '5.8399000000000000',
      high: '6.1318000000000000',
      low: '5.7589000000000000',
      close: '5.9590000000000000',
      volume: '1878151.1300000000000000',
      quoteVolume: 11203811.278916,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-23T00:00:00.000Z'
      },
      {
      open: '5.9588000000000000',
      high: '6.4735000000000000',
      low: '5.9300000000000000',
      close: '6.0979000000000000',
      volume: '2058385.4600000000000000',
      quoteVolume: 12800612.446236,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-24T00:00:00.000Z'
      },
      {
      open: '6.0975000000000000',
      high: '6.0994000000000000',
      low: '5.3127000000000000',
      close: '5.4500000000000000',
      volume: '1443517.2200000000000000',
      quoteVolume: 7946748.599596,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-25T00:00:00.000Z'
      },
      {
      open: '5.4432000000000000',
      high: '5.6840000000000000',
      low: '5.3700000000000000',
      close: '5.6029000000000000',
      volume: '1351072.4800000000000000',
      quoteVolume: 7510239.890038,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-26T00:00:00.000Z'
      },
      {
      open: '5.6062000000000000',
      high: '5.6559000000000000',
      low: '5.1000000000000000',
      close: '5.1791000000000000',
      volume: '1148939.3700000000000000',
      quoteVolume: 6200387.822491,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-27T00:00:00.000Z'
      },
      {
      open: '5.1818000000000000',
      high: '5.8995000000000000',
      low: '5.1003000000000000',
      close: '5.8811000000000000',
      volume: '1505031.2400000000000000',
      quoteVolume: 8318661.357344,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-28T00:00:00.000Z'
      },
      {
      open: '5.8803000000000000',
      high: '6.0000000000000000',
      low: '5.6100000000000000',
      close: '5.6600000000000000',
      volume: '1232874.4500000000000000',
      quoteVolume: 7206376.833483,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-29T00:00:00.000Z'
      },
      {
      open: '5.6700000000000000',
      high: '5.9186000000000000',
      low: '5.5580000000000000',
      close: '5.8939000000000000',
      volume: '1394241.0900000000000000',
      quoteVolume: 8065674.631955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-30T00:00:00.000Z'
      },
      {
      open: '5.9000000000000000',
      high: '6.2433000000000000',
      low: '5.8304000000000000',
      close: '6.1111000000000000',
      volume: '2388899.2300000000000000',
      quoteVolume: 14438039.454585,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-31T00:00:00.000Z'
      },
      {
      open: '6.1139000000000000',
      high: '6.1228000000000000',
      low: '5.7735000000000000',
      close: '5.9910000000000000',
      volume: '1412914.1800000000000000',
      quoteVolume: 8379611.862422,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-01T00:00:00.000Z'
      },
      {
      open: '5.9910000000000000',
      high: '6.0939000000000000',
      low: '5.8371000000000000',
      close: '6.0510000000000000',
      volume: '2130127.0000000000000000',
      quoteVolume: 12667352.240985,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-02T00:00:00.000Z'
      },
      {
      open: '6.0445000000000000',
      high: '6.0620000000000000',
      low: '5.7739000000000000',
      close: '5.7979000000000000',
      volume: '1524726.3700000000000000',
      quoteVolume: 8984033.715786,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-03T00:00:00.000Z'
      },
      {
      open: '5.7947000000000000',
      high: '5.9900000000000000',
      low: '5.7402000000000000',
      close: '5.9692000000000000',
      volume: '1657441.7400000000000000',
      quoteVolume: 9772290.645118,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-04T00:00:00.000Z'
      },
      {
      open: '5.9688000000000000',
      high: '6.1043000000000000',
      low: '5.9188000000000000',
      close: '5.9625000000000000',
      volume: '1636284.9900000000000000',
      quoteVolume: 9826475.877965,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-05T00:00:00.000Z'
      },
      {
      open: '5.9626000000000000',
      high: '6.3888000000000000',
      low: '5.9250000000000000',
      close: '6.2688000000000000',
      volume: '2511301.2700000000000000',
      quoteVolume: 15355552.564777,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-06T00:00:00.000Z'
      },
      {
      open: '6.2681000000000000',
      high: '6.3094000000000000',
      low: '6.0061000000000000',
      close: '6.2009000000000000',
      volume: '1683858.6800000000000000',
      quoteVolume: 10428760.417152,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-07T00:00:00.000Z'
      },
      {
      open: '6.1979000000000000',
      high: '6.7984000000000000',
      low: '6.1381000000000000',
      close: '6.5128000000000000',
      volume: '3946905.6100000000000000',
      quoteVolume: 25730037.887084,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-08T00:00:00.000Z'
      },
      {
      open: '6.5197000000000000',
      high: '6.8540000000000000',
      low: '6.3386000000000000',
      close: '6.5511000000000000',
      volume: '4167653.6900000000000000',
      quoteVolume: 27575766.440919,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-09T00:00:00.000Z'
      },
      {
      open: '6.5511000000000000',
      high: '6.5764000000000000',
      low: '5.6500000000000000',
      close: '5.8449000000000000',
      volume: '3348790.0300000000000000',
      quoteVolume: 20367712.107099,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-10T00:00:00.000Z'
      },
      {
      open: '5.8440000000000000',
      high: '6.1198000000000000',
      low: '5.6853000000000000',
      close: '5.9595000000000000',
      volume: '2559930.8100000000000000',
      quoteVolume: 15157433.652937,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-11T00:00:00.000Z'
      },
      {
      open: '5.9552000000000000',
      high: '5.9657000000000000',
      low: '5.8000000000000000',
      close: '5.8594000000000000',
      volume: '1743319.3100000000000000',
      quoteVolume: 10235285.058373,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-12T00:00:00.000Z'
      },
      {
      open: '5.8629000000000000',
      high: '5.8884000000000000',
      low: '5.3733000000000000',
      close: '5.4688000000000000',
      volume: '1420692.0500000000000000',
      quoteVolume: 8108312.422364,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-13T00:00:00.000Z'
      },
      {
      open: '5.4688000000000000',
      high: '6.0620000000000000',
      low: '5.4586000000000000',
      close: '6.0583000000000000',
      volume: '2330172.4800000000000000',
      quoteVolume: 13521000.249423,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-14T00:00:00.000Z'
      },
      {
      open: '6.0565000000000000',
      high: '6.0649000000000000',
      low: '5.6601000000000000',
      close: '5.8111000000000000',
      volume: '1366715.2500000000000000',
      quoteVolume: 8031293.154063,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-15T00:00:00.000Z'
      },
      {
      open: '5.8007000000000000',
      high: '6.1817000000000000',
      low: '5.7741000000000000',
      close: '6.0911000000000000',
      volume: '2164639.9000000000000000',
      quoteVolume: 12960434.729842,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '6.0941000000000000',
      high: '6.3300000000000000',
      low: '5.9604000000000000',
      close: '6.3266000000000000',
      volume: '2312287.7400000000000000',
      quoteVolume: 14193314.652338,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '6.3152000000000000',
      high: '6.5879000000000000',
      low: '6.2132000000000000',
      close: '6.4867000000000000',
      volume: '2495447.2200000000000000',
      quoteVolume: 15985247.059954,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '6.4887000000000000',
      high: '6.6400000000000000',
      low: '6.2435000000000000',
      close: '6.5199000000000000',
      volume: '2269437.9800000000000000',
      quoteVolume: 14687340.306653,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '6.5221000000000000',
      high: '6.6295000000000000',
      low: '6.2401000000000000',
      close: '6.4008000000000000',
      volume: '1778376.9300000000000000',
      quoteVolume: 11481466.262429,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '6.4069000000000000',
      high: '6.5150000000000000',
      low: '6.2788000000000000',
      close: '6.4724000000000000',
      volume: '1851048.4900000000000000',
      quoteVolume: 11908754.858074,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '6.4681000000000000',
      high: '6.5088000000000000',
      low: '6.2500000000000000',
      close: '6.4736000000000000',
      volume: '1854629.7500000000000000',
      quoteVolume: 11938849.639233,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '6.4780000000000000',
      high: '6.5300000000000000',
      low: '6.3499000000000000',
      close: '6.4969000000000000',
      volume: '1928704.7200000000000000',
      quoteVolume: 12474449.059306,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '6.4963000000000000',
      high: '6.4963000000000000',
      low: '6.3915000000000000',
      close: '6.4588000000000000',
      volume: '1514319.9100000000000000',
      quoteVolume: 9750589.965848,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '6.4555000000000000',
      high: '6.7502000000000000',
      low: '6.4408000000000000',
      close: '6.6899000000000000',
      volume: '2583153.3800000000000000',
      quoteVolume: 17021668.779197,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '6.6890000000000000',
      high: '6.9786000000000000',
      low: '6.6196000000000000',
      close: '6.9463000000000000',
      volume: '2734629.1100000000000000',
      quoteVolume: 18598393.817596,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '6.9453000000000000',
      high: '7.2157000000000000',
      low: '6.7919000000000000',
      close: '7.0396000000000000',
      volume: '2776887.6800000000000000',
      quoteVolume: 19399583.767419,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '7.0399000000000000',
      high: '7.1398000000000000',
      low: '5.9400000000000000',
      close: '6.1648000000000000',
      volume: '4950985.6900000000000000',
      quoteVolume: 32288204.501957,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '6.1642000000000000',
      high: '6.2599000000000000',
      low: '5.9680000000000000',
      close: '6.1661000000000000',
      volume: '2967157.3600000000000000',
      quoteVolume: 18169111.04114,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '6.1335000000000000',
      high: '6.1999000000000000',
      low: '6.0010000000000000',
      close: '6.1388000000000000',
      volume: '2238811.5900000000000000',
      quoteVolume: 13687184.380913,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '6.1384000000000000',
      high: '6.2663000000000000',
      low: '5.9468000000000000',
      close: '6.2200000000000000',
      volume: '2264988.8600000000000000',
      quoteVolume: 13878555.849304,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '6.2250000000000000',
      high: '6.6500000000000000',
      low: '6.1172000000000000',
      close: '6.5229000000000000',
      volume: '2942865.6800000000000000',
      quoteVolume: 19039053.024294,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '6.5185000000000000',
      high: '6.9000000000000000',
      low: '6.4823000000000000',
      close: '6.8655000000000000',
      volume: '3251363.1600000000000000',
      quoteVolume: 21783128.783937,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '6.8688000000000000',
      high: '6.9000000000000000',
      low: '6.6148000000000000',
      close: '6.7887000000000000',
      volume: '3095048.1600000000000000',
      quoteVolume: 21095885.790271,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '6.7888000000000000',
      high: '7.1850000000000000',
      low: '6.6800000000000000',
      close: '7.1069000000000000',
      volume: '3306867.3100000000000000',
      quoteVolume: 23064420.680175,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '7.1028000000000000',
      high: '7.9715000000000000',
      low: '6.9501000000000000',
      close: '7.8665000000000000',
      volume: '4154631.4300000000000000',
      quoteVolume: 30555459.709009,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '7.8569000000000000',
      high: '8.4800000000000000',
      low: '7.3200000000000000',
      close: '8.1303000000000000',
      volume: '5817391.7600000000000000',
      quoteVolume: 46487023.094392,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '8.1347000000000000',
      high: '8.4666000000000000',
      low: '7.6733000000000000',
      close: '7.8100000000000000',
      volume: '4354792.8000000000000000',
      quoteVolume: 35149002.628069,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '7.8070000000000000',
      high: '8.8387000000000000',
      low: '7.6100000000000000',
      close: '8.5972000000000000',
      volume: '4960284.8400000000000000',
      quoteVolume: 40860967.438254,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '8.5972000000000000',
      high: '8.9210000000000000',
      low: '8.4052000000000000',
      close: '8.9051000000000000',
      volume: '5440051.1100000000000000',
      quoteVolume: 47410868.663698,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '8.9001000000000000',
      high: '9.2999000000000000',
      low: '8.8264000000000000',
      close: '9.1697000000000000',
      volume: '5750442.0600000000000000',
      quoteVolume: 52514889.989487,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '9.1695000000000000',
      high: '9.7780000000000000',
      low: '9.0100000000000000',
      close: '9.5288000000000000',
      volume: '6041136.0700000000000000',
      quoteVolume: 57201203.615301,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '9.5250000000000000',
      high: '9.9000000000000000',
      low: '8.9210000000000000',
      close: '9.2765000000000000',
      volume: '4945231.4600000000000000',
      quoteVolume: 46161674.265266,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '9.2763000000000000',
      high: '9.2763000000000000',
      low: '8.7100000000000000',
      close: '8.9103000000000000',
      volume: '4005598.1000000000000000',
      quoteVolume: 36165479.576568,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '8.9165000000000000',
      high: '9.0500000000000000',
      low: '8.4051000000000000',
      close: '8.7655000000000000',
      volume: '2803390.0100000000000000',
      quoteVolume: 24547147.485664,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '8.7636000000000000',
      high: '9.4997000000000000',
      low: '8.7293000000000000',
      close: '9.2911000000000000',
      volume: '3326003.0400000000000000',
      quoteVolume: 30588883.272504,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '9.2854000000000000',
      high: '9.3399000000000000',
      low: '9.0480000000000000',
      close: '9.1087000000000000',
      volume: '2499086.4000000000000000',
      quoteVolume: 22869550.901954,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '9.0972000000000000',
      high: '9.2200000000000000',
      low: '8.8903000000000000',
      close: '9.2200000000000000',
      volume: '2460188.8100000000000000',
      quoteVolume: 22259140.520036,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '9.2167000000000000',
      high: '9.6999000000000000',
      low: '9.1777000000000000',
      close: '9.6127000000000000',
      volume: '2809984.6500000000000000',
      quoteVolume: 26459587.703255,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '9.6127000000000000',
      high: '11.1307000000000000',
      low: '9.4810000000000000',
      close: '10.4728000000000000',
      volume: '4894965.3800000000000000',
      quoteVolume: 49969717.014679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '10.4687000000000000',
      high: '11.2951000000000000',
      low: '10.3928000000000000',
      close: '10.9211000000000000',
      volume: '4346711.0700000000000000',
      quoteVolume: 47273284.241928,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '10.9199000000000000',
      high: '10.9360000000000000',
      low: '10.1300000000000000',
      close: '10.4661000000000000',
      volume: '3077022.9900000000000000',
      quoteVolume: 31977282.892985,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '10.4695000000000000',
      high: '10.9611000000000000',
      low: '10.3799000000000000',
      close: '10.7576000000000000',
      volume: '3038507.7600000000000000',
      quoteVolume: 32510212.911946,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '10.7572000000000000',
      high: '10.8500000000000000',
      low: '10.4000000000000000',
      close: '10.7265000000000000',
      volume: '2877713.1500000000000000',
      quoteVolume: 30567202.596206,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '10.7267000000000000',
      high: '11.0900000000000000',
      low: '9.3901000000000000',
      close: '9.9476000000000000',
      volume: '4800152.2500000000000000',
      quoteVolume: 49351275.843466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '9.9442000000000000',
      high: '10.2361000000000000',
      low: '9.2685000000000000',
      close: '9.6967000000000000',
      volume: '5348579.5700000000000000',
      quoteVolume: 52442588.585831,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '9.6967000000000000',
      high: '9.7557000000000000',
      low: '9.3551000000000000',
      close: '9.4168000000000000',
      volume: '3118340.9500000000000000',
      quoteVolume: 29619413.056984,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '9.4238000000000000',
      high: '9.9998000000000000',
      low: '9.3500000000000000',
      close: '9.8467000000000000',
      volume: '3524897.3500000000000000',
      quoteVolume: 34336397.563894,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '9.8502000000000000',
      high: '10.4914000000000000',
      low: '9.7899000000000000',
      close: '10.2779000000000000',
      volume: '3693794.5400000000000000',
      quoteVolume: 37396909.411865,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '10.2777000000000000',
      high: '11.5510000000000000',
      low: '10.2067000000000000',
      close: '11.3111000000000000',
      volume: '4025695.9700000000000000',
      quoteVolume: 44152799.747361,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '11.3353000000000000',
      high: '11.8000000000000000',
      low: '10.8000000000000000',
      close: '11.7566000000000000',
      volume: '3893847.1600000000000000',
      quoteVolume: 43818057.90284,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '11.7564000000000000',
      high: '11.8701000000000000',
      low: '11.1902000000000000',
      close: '11.4511000000000000',
      volume: '2853200.1500000000000000',
      quoteVolume: 32727760.247279,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '11.4511000000000000',
      high: '11.5080000000000000',
      low: '10.8500000000000000',
      close: '11.4006000000000000',
      volume: '2739049.0800000000000000',
      quoteVolume: 30560700.450351,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '11.4050000000000000',
      high: '13.7500000000000000',
      low: '11.3950000000000000',
      close: '13.6229000000000000',
      volume: '8340655.3200000000000000',
      quoteVolume: 107482964.630679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '13.6230000000000000',
      high: '14.6630000000000000',
      low: '12.9000000000000000',
      close: '14.5294000000000000',
      volume: '6158611.9700000000000000',
      quoteVolume: 84008255.221381,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '14.5290000000000000',
      high: '15.5600000000000000',
      low: '14.3270000000000000',
      close: '15.1000000000000000',
      volume: '6757497.3300000000000000',
      quoteVolume: 101414485.34027,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '15.0999000000000000',
      high: '15.2194000000000000',
      low: '13.5578000000000000',
      close: '14.2931000000000000',
      volume: '6343194.0300000000000000',
      quoteVolume: 90848655.629742,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '14.2848000000000000',
      high: '14.9200000000000000',
      low: '13.9609000000000000',
      close: '14.4651000000000000',
      volume: '3996324.5500000000000000',
      quoteVolume: 58062140.975948,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '14.4651000000000000',
      high: '14.5075000000000000',
      low: '13.8600000000000000',
      close: '14.3711000000000000',
      volume: '3531708.7000000000000000',
      quoteVolume: 50093088.214938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '14.3741000000000000',
      high: '14.9000000000000000',
      low: '14.0110000000000000',
      close: '14.4143000000000000',
      volume: '3757653.7700000000000000',
      quoteVolume: 54303593.05517,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '14.4133000000000000',
      high: '15.4288000000000000',
      low: '14.1809000000000000',
      close: '15.3333000000000000',
      volume: '4049068.3900000000000000',
      quoteVolume: 60747159.402645,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '15.3459000000000000',
      high: '15.3800000000000000',
      low: '14.9333000000000000',
      close: '15.0244000000000000',
      volume: '3282883.1800000000000000',
      quoteVolume: 49667294.365356,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '15.0311000000000000',
      high: '15.0970000000000000',
      low: '14.5630000000000000',
      close: '14.8901000000000000',
      volume: '3079956.9300000000000000',
      quoteVolume: 45702098.682484,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '14.8993000000000000',
      high: '15.1990000000000000',
      low: '14.8400000000000000',
      close: '14.9432000000000000',
      volume: '2986987.6900000000000000',
      quoteVolume: 44970630.556925,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '14.9429000000000000',
      high: '16.0000000000000000',
      low: '14.8800000000000000',
      close: '15.9589000000000000',
      volume: '3293876.1300000000000000',
      quoteVolume: 51027787.313053,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '15.9589000000000000',
      high: '15.9589000000000000',
      low: '15.4400000000000000',
      close: '15.5693000000000000',
      volume: '3106901.4800000000000000',
      quoteVolume: 48737968.350018,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '15.5836000000000000',
      high: '15.7888000000000000',
      low: '15.2040000000000000',
      close: '15.5388000000000000',
      volume: '2472584.8900000000000000',
      quoteVolume: 38403832.139068,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '15.5361000000000000',
      high: '15.8000000000000000',
      low: '15.0000000000000000',
      close: '15.4953000000000000',
      volume: '4271050.4000000000000000',
      quoteVolume: 65955552.676115,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '15.5098000000000000',
      high: '15.5098000000000000',
      low: '15.0220000000000000',
      close: '15.1288000000000000',
      volume: '2369648.3800000000000000',
      quoteVolume: 35958975.368582,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '15.1288000000000000',
      high: '15.2987000000000000',
      low: '13.8607000000000000',
      close: '14.3511000000000000',
      volume: '3015066.5300000000000000',
      quoteVolume: 44410580.384387,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '14.3567000000000000',
      high: '15.2260000000000000',
      low: '14.2310000000000000',
      close: '15.1502000000000000',
      volume: '2695850.8700000000000000',
      quoteVolume: 40069206.590268,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '15.1524000000000000',
      high: '15.4400000000000000',
      low: '14.9469000000000000',
      close: '15.1397000000000000',
      volume: '2632489.7000000000000000',
      quoteVolume: 39846679.02577,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '15.1397000000000000',
      high: '17.5000000000000000',
      low: '14.6600000000000000',
      close: '17.1096000000000000',
      volume: '5285367.1000000000000000',
      quoteVolume: 86478446.288151,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '17.1112000000000000',
      high: '17.3380000000000000',
      low: '16.1000000000000000',
      close: '16.7631000000000000',
      volume: '2775613.8600000000000000',
      quoteVolume: 46472992.15214,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '16.7761000000000000',
      high: '16.8000000000000000',
      low: '15.5301000000000000',
      close: '16.1998000000000000',
      volume: '2725145.6800000000000000',
      quoteVolume: 44086712.960827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '16.1928000000000000',
      high: '16.8297000000000000',
      low: '15.8697000000000000',
      close: '16.6000000000000000',
      volume: '3240914.2500000000000000',
      quoteVolume: 53588345.695308,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '16.6000000000000000',
      high: '16.7188000000000000',
      low: '16.4010000000000000',
      close: '16.6665000000000000',
      volume: '2988300.9700000000000000',
      quoteVolume: 49575583.531128,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '16.6538000000000000',
      high: '16.8067000000000000',
      low: '16.3770000000000000',
      close: '16.4753000000000000',
      volume: '2737173.2000000000000000',
      quoteVolume: 45408014.592112,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '16.4949000000000000',
      high: '16.9850000000000000',
      low: '15.9612000000000000',
      close: '16.9666000000000000',
      volume: '2790050.9300000000000000',
      quoteVolume: 46116769.387966,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '16.9666000000000000',
      high: '17.7386000000000000',
      low: '16.7314000000000000',
      close: '17.5376000000000000',
      volume: '3182964.8500000000000000',
      quoteVolume: 55170337.210131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '17.5201000000000000',
      high: '18.2576000000000000',
      low: '17.3319000000000000',
      close: '17.8888000000000000',
      volume: '2966204.7100000000000000',
      quoteVolume: 52669428.49396,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '17.8888000000000000',
      high: '19.8800000000000000',
      low: '17.5303000000000000',
      close: '19.7155000000000000',
      volume: '3356886.8900000000000000',
      quoteVolume: 62702520.871521,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '19.7155000000000000',
      high: '20.0000000000000000',
      low: '18.0000000000000000',
      close: '18.5489000000000000',
      volume: '3532965.9700000000000000',
      quoteVolume: 67883042.73247,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '18.5388000000000000',
      high: '19.4100000000000000',
      low: '18.1600000000000000',
      close: '18.9920000000000000',
      volume: '2939082.4200000000000000',
      quoteVolume: 55680460.143077,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '18.9710000000000000',
      high: '19.4593000000000000',
      low: '18.8445000000000000',
      close: '19.3657000000000000',
      volume: '2703355.9100000000000000',
      quoteVolume: 51825140.403764,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '19.3658000000000000',
      high: '19.3866000000000000',
      low: '18.8200000000000000',
      close: '19.0745000000000000',
      volume: '2519027.6300000000000000',
      quoteVolume: 48185036.221402,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '19.0423000000000000',
      high: '19.2137000000000000',
      low: '18.8000000000000000',
      close: '18.9523000000000000',
      volume: '2329702.3400000000000000',
      quoteVolume: 44296486.486205,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '18.9507000000000000',
      high: '19.0420000000000000',
      low: '17.7881000000000000',
      close: '17.9777000000000000',
      volume: '2142481.9200000000000000',
      quoteVolume: 39222061.198727,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '17.9728000000000000',
      high: '18.5400000000000000',
      low: '17.0100000000000000',
      close: '18.2984000000000000',
      volume: '2776440.4800000000000000',
      quoteVolume: 49902259.792581,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '18.2984000000000000',
      high: '18.6095000000000000',
      low: '17.8927000000000000',
      close: '18.2400000000000000',
      volume: '2553039.1200000000000000',
      quoteVolume: 46664596.972989,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '18.2400000000000000',
      high: '18.2400000000000000',
      low: '16.2501000000000000',
      close: '17.5555000000000000',
      volume: '1815271.0500000000000000',
      quoteVolume: 31411384.587709,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '17.5555000000000000',
      high: '18.5520000000000000',
      low: '16.6120000000000000',
      close: '18.3333000000000000',
      volume: '2325593.3600000000000000',
      quoteVolume: 41384885.795214,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '18.2867000000000000',
      high: '18.6398000000000000',
      low: '17.9143000000000000',
      close: '18.6111000000000000',
      volume: '2205194.6100000000000000',
      quoteVolume: 40484642.365226,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '18.6111000000000000',
      high: '19.9000000000000000',
      low: '18.4259000000000000',
      close: '19.3866000000000000',
      volume: '2438051.2100000000000000',
      quoteVolume: 46779479.130832,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '19.3734000000000000',
      high: '19.7800000000000000',
      low: '18.6601000000000000',
      close: '19.2666000000000000',
      volume: '2218308.0300000000000000',
      quoteVolume: 42952596.269131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '19.2668000000000000',
      high: '19.7482000000000000',
      low: '18.7064000000000000',
      close: '19.6408000000000000',
      volume: '2237334.6000000000000000',
      quoteVolume: 43185852.935218,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '19.6399000000000000',
      high: '19.7908000000000000',
      low: '19.2600000000000000',
      close: '19.4888000000000000',
      volume: '2005582.6000000000000000',
      quoteVolume: 39131247.103883,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '19.4757000000000000',
      high: '21.9980000000000000',
      low: '19.4333000000000000',
      close: '21.8888000000000000',
      volume: '3686092.5700000000000000',
      quoteVolume: 76859035.465239,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '21.8888000000000000',
      high: '24.7459000000000000',
      low: '21.1200000000000000',
      close: '24.3188000000000000',
      volume: '4247944.3800000000000000',
      quoteVolume: 97541476.101794,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '24.3188000000000000',
      high: '25.4973000000000000',
      low: '23.7600000000000000',
      close: '24.8888000000000000',
      volume: '3545157.4900000000000000',
      quoteVolume: 87320188.459109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '24.8693000000000000',
      high: '24.8693000000000000',
      low: '22.4500000000000000',
      close: '23.9999000000000000',
      volume: '3307426.8400000000000000',
      quoteVolume: 77726576.261717,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '23.9999000000000000',
      high: '24.6368000000000000',
      low: '23.0200000000000000',
      close: '23.8000000000000000',
      volume: '3005735.9300000000000000',
      quoteVolume: 72180137.003328,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '23.7812000000000000',
      high: '24.6131000000000000',
      low: '22.4700000000000000',
      close: '22.5460000000000000',
      volume: '2877995.4900000000000000',
      quoteVolume: 67622172.827015,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '22.5207000000000000',
      high: '23.1209000000000000',
      low: '20.8800000000000000',
      close: '22.9703000000000000',
      volume: '2961681.7400000000000000',
      quoteVolume: 65293459.483933,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '22.9877000000000000',
      high: '23.4698000000000000',
      low: '21.5500000000000000',
      close: '22.4863000000000000',
      volume: '3114460.9000000000000000',
      quoteVolume: 70932653.981115,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '22.4869000000000000',
      high: '23.7860000000000000',
      low: '21.6503000000000000',
      close: '23.0555000000000000',
      volume: '3273950.4700000000000000',
      quoteVolume: 75393015.340524,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '23.0555000000000000',
      high: '23.1209000000000000',
      low: '22.3876000000000000',
      close: '22.4324000000000000',
      volume: '2989261.0700000000000000',
      quoteVolume: 67575656.858512,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '22.4326000000000000',
      high: '23.2092000000000000',
      low: '22.4200000000000000',
      close: '23.0226000000000000',
      volume: '3015774.2900000000000000',
      quoteVolume: 69067258.092041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '23.0166000000000000',
      high: '23.0828000000000000',
      low: '21.2797000000000000',
      close: '21.6788000000000000',
      volume: '2388965.0700000000000000',
      quoteVolume: 52801462.600293,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '21.6847000000000000',
      high: '22.3000000000000000',
      low: '21.3292000000000000',
      close: '22.0391000000000000',
      volume: '2197565.5300000000000000',
      quoteVolume: 47987092.830938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '22.0300000000000000',
      high: '22.0990000000000000',
      low: '21.4700000000000000',
      close: '21.8788000000000000',
      volume: '2064791.5400000000000000',
      quoteVolume: 45032827.826184,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '21.8810000000000000',
      high: '24.1270000000000000',
      low: '21.7497000000000000',
      close: '23.6166000000000000',
      volume: '2446015.2000000000000000',
      quoteVolume: 56078160.970131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '23.6168000000000000',
      high: '23.9720000000000000',
      low: '23.0101000000000000',
      close: '23.6999000000000000',
      volume: '2230465.5800000000000000',
      quoteVolume: 52406113.705314,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '23.6999000000000000',
      high: '23.7968000000000000',
      low: '22.3000000000000000',
      close: '22.8888000000000000',
      volume: '1898934.6200000000000000',
      quoteVolume: 43768914.432769,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '22.8887000000000000',
      high: '23.1772000000000000',
      low: '22.6408000000000000',
      close: '22.9777000000000000',
      volume: '1787108.3900000000000000',
      quoteVolume: 40919944.41562,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '22.9777000000000000',
      high: '22.9973000000000000',
      low: '21.4500000000000000',
      close: '22.1895000000000000',
      volume: '1764747.5400000000000000',
      quoteVolume: 39058125.114883,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '22.2018000000000000',
      high: '22.4500000000000000',
      low: '20.2050000000000000',
      close: '20.6602000000000000',
      volume: '1813897.1200000000000000',
      quoteVolume: 39553390.823722,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '20.6602000000000000',
      high: '21.4135000000000000',
      low: '19.5100000000000000',
      close: '20.6673000000000000',
      volume: '2742356.4200000000000000',
      quoteVolume: 56630740.961407,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '20.6671000000000000',
      high: '20.9670000000000000',
      low: '18.5402000000000000',
      close: '18.8388000000000000',
      volume: '2334477.6000000000000000',
      quoteVolume: 46078618.722513,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '18.8377000000000000',
      high: '19.9888000000000000',
      low: '18.3000000000000000',
      close: '19.6166000000000000',
      volume: '2429624.3200000000000000',
      quoteVolume: 46735449.139546,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '19.6294000000000000',
      high: '22.0000000000000000',
      low: '18.8800000000000000',
      close: '20.8059000000000000',
      volume: '3746402.5300000000000000',
      quoteVolume: 76700019.096599,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '20.7871000000000000',
      high: '21.5000000000000000',
      low: '20.1000000000000000',
      close: '20.8934000000000000',
      volume: '3228794.3800000000000000',
      quoteVolume: 67290647.825955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '20.9026000000000000',
      high: '24.0000000000000000',
      low: '20.7349000000000000',
      close: '23.5210000000000000',
      volume: '4566012.5800000000000000',
      quoteVolume: 103765929.624047,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '23.5210000000000000',
      high: '24.5000000000000000',
      low: '22.5748000000000000',
      close: '23.6553000000000000',
      volume: '3821993.1600000000000000',
      quoteVolume: 90350011.235486,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '23.6553000000000000',
      high: '27.6556000000000000',
      low: '23.4600000000000000',
      close: '26.8116000000000000',
      volume: '3433770.3400000000000000',
      quoteVolume: 89988096.508272,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '26.8348000000000000',
      high: '27.7900000000000000',
      low: '25.0000000000000000',
      close: '26.2888000000000000',
      volume: '4200071.2400000000000000',
      quoteVolume: 111245092.085173,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '26.2888000000000000',
      high: '26.6783000000000000',
      low: '23.1424000000000000',
      close: '25.7795000000000000',
      volume: '3429086.5600000000000000',
      quoteVolume: 85241258.720158,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '25.7986000000000000',
      high: '29.0989000000000000',
      low: '25.1124000000000000',
      close: '28.8171000000000000',
      volume: '4319102.3500000000000000',
      quoteVolume: 117061502.700204,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '28.8165000000000000',
      high: '29.8368000000000000',
      low: '27.5430000000000000',
      close: '29.1122000000000000',
      volume: '4437585.5400000000000000',
      quoteVolume: 128407708.555722,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '29.1122000000000000',
      high: '29.3399000000000000',
      low: '27.5306000000000000',
      close: '29.2666000000000000',
      volume: '3980328.4500000000000000',
      quoteVolume: 113614310.06848,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '29.2666000000000000',
      high: '32.8000000000000000',
      low: '28.0500000000000000',
      close: '32.3907000000000000',
      volume: '4704491.9800000000000000',
      quoteVolume: 142363584.104499,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '32.3911000000000000',
      high: '32.4374000000000000',
      low: '30.6100000000000000',
      close: '30.8067000000000000',
      volume: '3807962.5800000000000000',
      quoteVolume: 120261451.277312,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '30.8333000000000000',
      high: '32.2500000000000000',
      low: '29.6699000000000000',
      close: '31.3934000000000000',
      volume: '3793076.8500000000000000',
      quoteVolume: 118471724.038178,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '31.3986000000000000',
      high: '34.7067000000000000',
      low: '31.1200000000000000',
      close: '33.9435000000000000',
      volume: '4534224.5600000000000000',
      quoteVolume: 151952803.938095,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '34.1004000000000000',
      high: '35.4868000000000000',
      low: '33.6231000000000000',
      close: '35.1464000000000000',
      volume: '2287998.0600000000000000',
      quoteVolume: 79609705.50209479,
      btcVolume: 9949.002147255906,
      usdVolume: 79609705.50209479,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '35.1128000000000000',
      high: '35.1128000000000000',
      low: '32.3200000000000000',
      close: '33.0989000000000000',
      volume: '2391390.5700000000000000',
      quoteVolume: 80142171.05356416,
      btcVolume: 9883.743936491968,
      usdVolume: 80142171.05356416,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '33.0220000000000000',
      high: '35.1001000000000000',
      low: '32.5000000000000000',
      close: '33.4619000000000000',
      volume: '2295348.1100000000000000',
      quoteVolume: 77908157.85849185,
      btcVolume: 8929.21104959771,
      usdVolume: 77908157.85849185,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '33.4618000000000000',
      high: '34.5000000000000000',
      low: '32.5000000000000000',
      close: '33.1336000000000000',
      volume: '1895937.3300000000000000',
      quoteVolume: 63128977.16986113,
      btcVolume: 7252.390997849462,
      usdVolume: 63128977.16986113,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '33.0923000000000000',
      high: '33.8790000000000000',
      low: '30.8500000000000000',
      close: '33.6083000000000000',
      volume: '2212524.3300000000000000',
      quoteVolume: 72870434.08302279,
      btcVolume: 8454.326316547385,
      usdVolume: 72870434.08302279,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '33.6085000000000000',
      high: '34.9600000000000000',
      low: '30.2500000000000000',
      close: '31.6330000000000000',
      volume: '2432021.9400000000000000',
      quoteVolume: 80763627.05465643,
      btcVolume: 9414.799798958127,
      usdVolume: 80763627.05465643,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '31.6473000000000000',
      high: '32.9724000000000000',
      low: '30.6100000000000000',
      close: '32.9321000000000000',
      volume: '1923224.7500000000000000',
      quoteVolume: 61104329.90333608,
      btcVolume: 7303.226615523445,
      usdVolume: 61104329.90333608,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '32.9165000000000000',
      high: '34.3530000000000000',
      low: '32.5024000000000000',
      close: '33.3348000000000000',
      volume: '2519040.2400000000000000',
      quoteVolume: 83650473.63800699,
      btcVolume: 9797.183411874852,
      usdVolume: 83650473.63800699,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '33.3521000000000000',
      high: '33.7647000000000000',
      low: '32.6800000000000000',
      close: '33.1527000000000000',
      volume: '3125349.5700000000000000',
      quoteVolume: 103747789.15420249,
      btcVolume: 11980.301630342452,
      usdVolume: 103747789.15420249,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '33.1343000000000000',
      high: '33.1753000000000000',
      low: '30.5100000000000000',
      close: '30.8093000000000000',
      volume: '2793947.5900000000000000',
      quoteVolume: 89255709.65780656,
      btcVolume: 10474.381377841331,
      usdVolume: 89255709.65780656,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '30.8093000000000000',
      high: '31.4673000000000000',
      low: '28.7168000000000000',
      close: '29.2451000000000000',
      volume: '2872894.7300000000000000',
      quoteVolume: 86509446.32841438,
      btcVolume: 10980.6600454644,
      usdVolume: 86509446.32841438,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '29.2451000000000000',
      high: '31.1843000000000000',
      low: '28.9440000000000000',
      close: '30.8755000000000000',
      volume: '3345930.6000000000000000',
      quoteVolume: 100255371.95814995,
      btcVolume: 12913.379731084178,
      usdVolume: 100255371.95814995,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '30.8887000000000000',
      high: '31.9906000000000000',
      low: '29.6300000000000000',
      close: '31.0888000000000000',
      volume: '2987983.0800000000000000',
      quoteVolume: 93095763.1398957,
      btcVolume: 12017.344452736703,
      usdVolume: 93095763.1398957,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '31.0888000000000000',
      high: '31.9911000000000000',
      low: '30.9001000000000000',
      close: '31.3098000000000000',
      volume: '2758670.5000000000000000',
      quoteVolume: 86875753.11986831,
      btcVolume: 10951.248485073238,
      usdVolume: 86875753.11986831,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '31.3478000000000000',
      high: '32.2926000000000000',
      low: '31.1071000000000000',
      close: '31.7045000000000000',
      volume: '3228596.5900000000000000',
      quoteVolume: 102524672.99267428,
      btcVolume: 12945.174416648542,
      usdVolume: 102524672.99267428,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '31.7293000000000000',
      high: '31.8500000000000000',
      low: '29.7611000000000000',
      close: '30.0950000000000000',
      volume: '2533570.1800000000000000',
      quoteVolume: 78429078.60609235,
      btcVolume: 10087.18462271012,
      usdVolume: 78429078.60609235,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '30.1174000000000000',
      high: '32.0438000000000000',
      low: '29.7286000000000000',
      close: '32.0271000000000000',
      volume: '3550510.6300000000000000',
      quoteVolume: 110950707.9152291,
      btcVolume: 14185.907492697892,
      usdVolume: 110950707.9152291,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '32.0261000000000000',
      high: '32.0821000000000000',
      low: '30.9312000000000000',
      close: '31.8889000000000000',
      volume: '2693421.5300000000000000',
      quoteVolume: 84894700.01127566,
      btcVolume: 10799.407953421249,
      usdVolume: 84894700.01127566,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '31.8889000000000000',
      high: '34.9800000000000000',
      low: '31.8008000000000000',
      close: '34.8310000000000000',
      volume: '3941279.3400000000000000',
      quoteVolume: 132789273.60234739,
      btcVolume: 16598.78224827631,
      usdVolume: 132789273.60234739,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '34.8284000000000000',
      high: '36.3689000000000000',
      low: '34.1200000000000000',
      close: '35.2510000000000000',
      volume: '3537185.5100000000000000',
      quoteVolume: 124552938.20500359,
      btcVolume: 15300.412184978906,
      usdVolume: 124552938.20500359,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '35.2577000000000000',
      high: '35.3000000000000000',
      low: '29.8146000000000000',
      close: '32.3803000000000000',
      volume: '4820144.3200000000000000',
      quoteVolume: 155335662.97399047,
      btcVolume: 18662.671237915034,
      usdVolume: 155335662.97399047,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '32.3887000000000000',
      high: '33.9420000000000000',
      low: '31.6865000000000000',
      close: '32.7500000000000000',
      volume: '3389863.2300000000000000',
      quoteVolume: 111183078.4554273,
      btcVolume: 12804.521200155277,
      usdVolume: 111183078.4554273,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '32.7303000000000000',
      high: '33.2943000000000000',
      low: '31.7316000000000000',
      close: '32.1888000000000000',
      volume: '2826534.9700000000000000',
      quoteVolume: 92109821.4809208,
      btcVolume: 10183.184045346163,
      usdVolume: 92109821.4809208,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '32.1956000000000000',
      high: '34.4500000000000000',
      low: '32.1862000000000000',
      close: '33.8286000000000000',
      volume: '3325355.0300000000000000',
      quoteVolume: 111126704.95099491,
      btcVolume: 12078.924988720071,
      usdVolume: 111126704.95099491,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '33.8542000000000000',
      high: '35.0749000000000000',
      low: '33.7386000000000000',
      close: '34.4333000000000000',
      volume: '3404156.4000000000000000',
      quoteVolume: 117184660.8514287,
      btcVolume: 12820.477601301915,
      usdVolume: 117142616.23937275,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '34.4261000000000000',
      high: '35.6000000000000000',
      low: '34.3939000000000000',
      close: '35.1888000000000000',
      volume: '3662780.3500000000000000',
      quoteVolume: 128944461.18892004,
      btcVolume: 14098.563163206594,
      usdVolume: 128944461.18892004,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '35.1764000000000000',
      high: '36.9146000000000000',
      low: '34.3979000000000000',
      close: '36.8200000000000000',
      volume: '4059479.0200000000000000',
      quoteVolume: 145554685.05118558,
      btcVolume: 15614.242998911897,
      usdVolume: 145554685.05118558,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '36.8377000000000000',
      high: '38.8500000000000000',
      low: '36.3364000000000000',
      close: '38.6666000000000000',
      volume: '3532992.4300000000000000',
      quoteVolume: 132425793.40442118,
      btcVolume: 13534.113990105783,
      usdVolume: 132425793.40442118,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '38.6666000000000000',
      high: '39.5941000000000000',
      low: '37.1100000000000000',
      close: '38.0000000000000000',
      volume: '3287617.1200000000000000',
      quoteVolume: 126851635.12920612,
      btcVolume: 11861.268781544859,
      usdVolume: 126851635.12920612,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '38.0001000000000000',
      high: '38.5320000000000000',
      low: '37.2600000000000000',
      close: '37.2836000000000000',
      volume: '2788591.8500000000000000',
      quoteVolume: 105493792.36592467,
      btcVolume: 9731.003920100708,
      usdVolume: 105493792.36592467,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '37.2665000000000000',
      high: '37.8210000000000000',
      low: '36.0001000000000000',
      close: '37.4300000000000000',
      volume: '2631717.1900000000000000',
      quoteVolume: 97880605.03243802,
      btcVolume: 8975.410919955108,
      usdVolume: 97880605.03243802,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '37.4300000000000000',
      high: '37.5510000000000000',
      low: '35.7100000000000000',
      close: '36.0947000000000000',
      volume: '2000864.9200000000000000',
      quoteVolume: 73473294.34677196,
      btcVolume: 6467.382207788116,
      usdVolume: 73473294.34677196,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '36.0941000000000000',
      high: '38.0000000000000000',
      low: '34.5000000000000000',
      close: '36.0999000000000000',
      volume: '2758448.8600000000000000',
      quoteVolume: 100936180.80838415,
      btcVolume: 7924.993856109126,
      usdVolume: 100936180.80838415,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '36.0999000000000000',
      high: '36.3500000000000000',
      low: '33.1584000000000000',
      close: '34.3999000000000000',
      volume: '2591182.3600000000000000',
      quoteVolume: 89690344.30455235,
      btcVolume: 7533.165332525957,
      usdVolume: 89690344.30455235,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '34.3420000000000000',
      high: '35.2809000000000000',
      low: '33.8200000000000000',
      close: '34.6289000000000000',
      volume: '2104962.2500000000000000',
      quoteVolume: 72480203.20366873,
      btcVolume: 6101.8047057837575,
      usdVolume: 72480203.20366873,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '34.5951000000000000',
      high: '35.8000000000000000',
      low: '33.2447000000000000',
      close: '35.5000000000000000',
      volume: '2585753.6300000000000000',
      quoteVolume: 89769494.98459294,
      btcVolume: 7508.666820715312,
      usdVolume: 89769494.98459294,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '35.5111000000000000',
      high: '35.5111000000000000',
      low: '32.0200000000000000',
      close: '32.1314000000000000',
      volume: '2022991.5500000000000000',
      quoteVolume: 68980161.28362553,
      btcVolume: 5906.05441303598,
      usdVolume: 68980161.28362553,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '32.1140000000000000',
      high: '34.0500000000000000',
      low: '31.3682000000000000',
      close: '33.2777000000000000',
      volume: '1834460.9000000000000000',
      quoteVolume: 59929948.94285722,
      btcVolume: 5568.470684819062,
      usdVolume: 59929948.94285722,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '33.2777000000000000',
      high: '34.0000000000000000',
      low: '30.5600000000000000',
      close: '31.9570000000000000',
      volume: '2016746.5900000000000000',
      quoteVolume: 64596527.83502798,
      btcVolume: 6368.043385041789,
      usdVolume: 64596527.83502798,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '31.9772000000000000',
      high: '33.0500000000000000',
      low: '31.6230000000000000',
      close: '32.5414000000000000',
      volume: '1930269.8700000000000000',
      quoteVolume: 62412176.663419046,
      btcVolume: 5508.986915116347,
      usdVolume: 62412176.663419046,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '32.5237000000000000',
      high: '34.3839000000000000',
      low: '32.1503000000000000',
      close: '33.0888000000000000',
      volume: '2193452.4800000000000000',
      quoteVolume: 73032732.02201295,
      btcVolume: 6260.154521227522,
      usdVolume: 73032732.02201295,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '33.0399000000000000',
      high: '33.6497000000000000',
      low: '32.4000000000000000',
      close: '32.5566000000000000',
      volume: '1501816.7600000000000000',
      quoteVolume: 49493032.56383061,
      btcVolume: 4446.897333852955,
      usdVolume: 49493032.56383061,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '32.5507000000000000',
      high: '33.1815000000000000',
      low: '32.5000000000000000',
      close: '32.5888000000000000',
      volume: '1122287.4400000000000000',
      quoteVolume: 36787236.23351705,
      btcVolume: 3237.2866952944937,
      usdVolume: 36787236.23351705,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '32.5471000000000000',
      high: '33.3997000000000000',
      low: '32.4000000000000000',
      close: '32.9344000000000000',
      volume: '1219900.0500000000000000',
      quoteVolume: 40138364.745880224,
      btcVolume: 3553.1949115873035,
      usdVolume: 40138364.745880224,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '32.9326000000000000',
      high: '34.2918000000000000',
      low: '32.7500000000000000',
      close: '33.3888000000000000',
      volume: '1483935.3600000000000000',
      quoteVolume: 49789526.97420341,
      btcVolume: 4261.852971883836,
      usdVolume: 49789526.97420341,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '33.3888000000000000',
      high: '33.9000000000000000',
      low: '32.2195000000000000',
      close: '32.5075000000000000',
      volume: '1212484.7200000000000000',
      quoteVolume: 40118253.17297037,
      btcVolume: 3221.2079585364836,
      usdVolume: 40118253.17297037,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '32.4825000000000000',
      high: '32.8900000000000000',
      low: '30.5100000000000000',
      close: '31.2220000000000000',
      volume: '1575508.6000000000000000',
      quoteVolume: 50081252.967029475,
      btcVolume: 3955.920822486626,
      usdVolume: 50081252.967029475,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '31.2220000000000000',
      high: '31.8900000000000000',
      low: '28.2666000000000000',
      close: '29.4181000000000000',
      volume: '1727744.5200000000000000',
      quoteVolume: 51340643.34346419,
      btcVolume: 4462.463255903058,
      usdVolume: 51340643.34346419,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '29.4213000000000000',
      high: '32.6219000000000000',
      low: '29.0000000000000000',
      close: '31.8894000000000000',
      volume: '2941848.2500000000000000',
      quoteVolume: 92635627.07000853,
      btcVolume: 8103.016132792709,
      usdVolume: 92635627.07000853,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '31.8874000000000000',
      high: '32.4894000000000000',
      low: '30.2075000000000000',
      close: '31.4888000000000000',
      volume: '1746699.7900000000000000',
      quoteVolume: 54982624.17098898,
      btcVolume: 4813.480581114553,
      usdVolume: 54982624.17098898,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '31.4701000000000000',
      high: '31.5259000000000000',
      low: '28.3111000000000000',
      close: '28.5589000000000000',
      volume: '2103525.3200000000000000',
      quoteVolume: 62793063.28152121,
      btcVolume: 5821.32081882098,
      usdVolume: 62793063.28152121,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '28.5584000000000000',
      high: '28.7498000000000000',
      low: '26.3190000000000000',
      close: '28.6489000000000000',
      volume: '2954452.0200000000000000',
      quoteVolume: 81881912.98124091,
      btcVolume: 7918.900039057427,
      usdVolume: 81881912.98124091,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '28.6348000000000000',
      high: '29.5800000000000000',
      low: '24.8626000000000000',
      close: '25.0601000000000000',
      volume: '3691855.4300000000000000',
      quoteVolume: 100236711.8868326,
      btcVolume: 9803.524590355422,
      usdVolume: 100236711.8868326,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '25.0552000000000000',
      high: '28.2898000000000000',
      low: '24.1709000000000000',
      close: '27.6258000000000000',
      volume: '5619617.8800000000000000',
      quoteVolume: 147693999.79602024,
      btcVolume: 15463.868456792456,
      usdVolume: 147693999.79602024,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '27.6264000000000000',
      high: '29.5000000000000000',
      low: '26.8000000000000000',
      close: '28.9381000000000000',
      volume: '6265532.8900000000000000',
      quoteVolume: 177710944.23860273,
      btcVolume: 17945.243760879956,
      usdVolume: 177608163.10294086,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '28.9521000000000000',
      high: '29.9600000000000000',
      low: '28.3158000000000000',
      close: '29.0096000000000000',
      volume: '3925662.8400000000000000',
      quoteVolume: 113855677.67222823,
      btcVolume: 10894.61710090789,
      usdVolume: 113855677.67222823,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '29.0015000000000000',
      high: '31.4600000000000000',
      low: '28.9000000000000000',
      close: '31.0932000000000000',
      volume: '2868452.7700000000000000',
      quoteVolume: 87962899.98284975,
      btcVolume: 8266.346344725387,
      usdVolume: 87962899.98284975,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '31.1222000000000000',
      high: '31.5281000000000000',
      low: '29.2801000000000000',
      close: '30.4388000000000000',
      volume: '2165383.6300000000000000',
      quoteVolume: 65800415.77082615,
      btcVolume: 6225.919909520394,
      usdVolume: 65800415.77082615,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '30.4389000000000000',
      high: '32.6000000000000000',
      low: '29.6203000000000000',
      close: '30.8850000000000000',
      volume: '3465285.3200000000000000',
      quoteVolume: 107521586.41408998,
      btcVolume: 10273.673397545184,
      usdVolume: 107521586.41408998,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '30.9226000000000000',
      high: '30.9400000000000000',
      low: '28.9471000000000000',
      close: '29.4894000000000000',
      volume: '2638959.1800000000000000',
      quoteVolume: 78461044.85644288,
      btcVolume: 7795.804246913197,
      usdVolume: 78461044.85644288,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '29.4649000000000000',
      high: '29.5057000000000000',
      low: '27.7426000000000000',
      close: '28.8303000000000000',
      volume: '2335697.9600000000000000',
      quoteVolume: 66771505.28094476,
      btcVolume: 6868.82631676914,
      usdVolume: 66771505.28094476,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '28.8304000000000000',
      high: '29.6596000000000000',
      low: '28.4444000000000000',
      close: '28.9395000000000000',
      volume: '2189105.6200000000000000',
      quoteVolume: 63998748.52433625,
      btcVolume: 6402.849558443817,
      usdVolume: 63998748.52433625,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '28.9227000000000000',
      high: '29.0500000000000000',
      low: '28.1641000000000000',
      close: '28.8983000000000000',
      volume: '1926812.0300000000000000',
      quoteVolume: 55269615.02700419,
      btcVolume: 5653.511709576811,
      usdVolume: 55269615.02700419,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '28.8983000000000000',
      high: '29.4502000000000000',
      low: '27.2061000000000000',
      close: '27.7498000000000000',
      volume: '1482247.8300000000000000',
      quoteVolume: 41938098.57057801,
      btcVolume: 4304.517936922523,
      usdVolume: 41938098.57057801,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '27.7384000000000000',
      high: '27.8459000000000000',
      low: '26.4200000000000000',
      close: '27.7627000000000000',
      volume: '1320975.1600000000000000',
      quoteVolume: 36374310.99186316,
      btcVolume: 3832.5016194283835,
      usdVolume: 36374310.99186316,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '27.7581000000000000',
      high: '28.0419000000000000',
      low: '26.8202000000000000',
      close: '26.9525000000000000',
      volume: '1140682.0500000000000000',
      quoteVolume: 31110138.426472325,
      btcVolume: 3256.314799181455,
      usdVolume: 31110138.426472325,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '26.9540000000000000',
      high: '27.1198000000000000',
      low: '26.2020000000000000',
      close: '26.9867000000000000',
      volume: '820023.9000000000000000',
      quoteVolume: 21902889.9898799,
      btcVolume: 2296.8262791212037,
      usdVolume: 21902889.9898799,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '26.9879000000000000',
      high: '27.8300000000000000',
      low: '26.7800000000000000',
      close: '27.7348000000000000',
      volume: '1934428.9000000000000000',
      quoteVolume: 53152588.64401577,
      btcVolume: 5403.686098362969,
      usdVolume: 53152588.64401577,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '27.7500000000000000',
      high: '28.7664000000000000',
      low: '27.4500000000000000',
      close: '28.7088000000000000',
      volume: '2252485.2300000000000000',
      quoteVolume: 63463951.041090965,
      btcVolume: 6318.884088129215,
      usdVolume: 63463951.041090965,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '28.7100000000000000',
      high: '28.7186000000000000',
      low: '27.5643000000000000',
      close: '28.1400000000000000',
      volume: '1501539.5400000000000000',
      quoteVolume: 42179913.12631353,
      btcVolume: 4032.0149580734223,
      usdVolume: 42179913.12631353,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '28.1400000000000000',
      high: '28.3409000000000000',
      low: '27.5000000000000000',
      close: '27.7285000000000000',
      volume: '1209342.2000000000000000',
      quoteVolume: 33690305.912137166,
      btcVolume: 3130.3892108291648,
      usdVolume: 33690305.912137166,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '27.7333000000000000',
      high: '27.7719000000000000',
      low: '27.0852000000000000',
      close: '27.3834000000000000',
      volume: '967494.6400000000000000',
      quoteVolume: 26514204.222139858,
      btcVolume: 2463.628698032826,
      usdVolume: 26514204.222139858,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '27.3834000000000000',
      high: '28.3000000000000000',
      low: '27.3011000000000000',
      close: '28.0107000000000000',
      volume: '1170209.2600000000000000',
      quoteVolume: 32618569.941277068,
      btcVolume: 2806.4498779368155,
      usdVolume: 32618569.941277068,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '28.0117000000000000',
      high: '28.3125000000000000',
      low: '27.3333000000000000',
      close: '27.6523000000000000',
      volume: '1404874.7100000000000000',
      quoteVolume: 39075613.06976784,
      btcVolume: 3302.2858155088666,
      usdVolume: 39075613.06976784,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '27.6539000000000000',
      high: '29.9800000000000000',
      low: '27.3330000000000000',
      close: '29.6694000000000000',
      volume: '2398210.6200000000000000',
      quoteVolume: 68536315.25246768,
      btcVolume: 5832.360785093793,
      usdVolume: 68536315.25246768,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '29.6690000000000000',
      high: '31.8791000000000000',
      low: '29.1914000000000000',
      close: '31.1427000000000000',
      volume: '3071252.6500000000000000',
      quoteVolume: 94636423.46255855,
      btcVolume: 8004.065605940615,
      usdVolume: 94636423.46255855,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '31.1074000000000000',
      high: '31.5000000000000000',
      low: '29.6666000000000000',
      close: '29.8188000000000000',
      volume: '1467817.4400000000000000',
      quoteVolume: 45035359.27979547,
      btcVolume: 3801.756204313858,
      usdVolume: 45035359.27979547,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '29.7969000000000000',
      high: '30.0200000000000000',
      low: '29.0333000000000000',
      close: '29.6662000000000000',
      volume: '1302579.2400000000000000',
      quoteVolume: 38469949.58556509,
      btcVolume: 3320.6255793968776,
      usdVolume: 38469949.58556509,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '29.6660000000000000',
      high: '30.4458000000000000',
      low: '29.3000000000000000',
      close: '30.2109000000000000',
      volume: '1563528.9000000000000000',
      quoteVolume: 46830334.26929312,
      btcVolume: 4107.984996045789,
      usdVolume: 46830334.26929312,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '30.3192000000000000',
      high: '30.5936000000000000',
      low: '29.8230000000000000',
      close: '30.1856000000000000',
      volume: '1527254.3500000000000000',
      quoteVolume: 46134967.60864254,
      btcVolume: 4043.0634741807817,
      usdVolume: 46134967.60864254,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '30.1856000000000000',
      high: '30.4496000000000000',
      low: '29.2000000000000000',
      close: '29.4537000000000000',
      volume: '1164233.9000000000000000',
      quoteVolume: 34747155.42068626,
      btcVolume: 3108.3618369887367,
      usdVolume: 34747155.42068626,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '29.4512000000000000',
      high: '29.4693000000000000',
      low: '26.7500000000000000',
      close: '27.0106000000000000',
      volume: '1176934.2300000000000000',
      quoteVolume: 33298167.10513729,
      btcVolume: 3178.5215896041464,
      usdVolume: 33298167.10513729,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '27.0269000000000000',
      high: '28.2174000000000000',
      low: '26.7000000000000000',
      close: '28.1058000000000000',
      volume: '1242352.4600000000000000',
      quoteVolume: 34322334.57446039,
      btcVolume: 3395.545034113294,
      usdVolume: 34322334.57446039,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '28.1052000000000000',
      high: '28.1313000000000000',
      low: '26.7880000000000000',
      close: '27.5111000000000000',
      volume: '965369.6300000000000000',
      quoteVolume: 26500903.438648794,
      btcVolume: 2602.9765482710295,
      usdVolume: 26500903.438648794,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '27.4908000000000000',
      high: '27.9605000000000000',
      low: '26.9863000000000000',
      close: '27.5038000000000000',
      volume: '963986.7000000000000000',
      quoteVolume: 26558675.591700017,
      btcVolume: 2579.2725537309097,
      usdVolume: 26558675.591700017,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '27.5037000000000000',
      high: '28.1797000000000000',
      low: '27.0511000000000000',
      close: '27.8977000000000000',
      volume: '1210841.2500000000000000',
      quoteVolume: 33534708.06565196,
      btcVolume: 3250.687025349346,
      usdVolume: 33534708.06565196,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '27.9033000000000000',
      high: '29.1779000000000000',
      low: '27.6884000000000000',
      close: '28.7931000000000000',
      volume: '1802460.6600000000000000',
      quoteVolume: 51651237.54506001,
      btcVolume: 4855.6186625084,
      usdVolume: 51651237.54506001,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '28.7974000000000000',
      high: '28.8079000000000000',
      low: '27.5200000000000000',
      close: '28.0111000000000000',
      volume: '1230912.5200000000000000',
      quoteVolume: 34656879.16071867,
      btcVolume: 3223.4213999415306,
      usdVolume: 34656879.16071867,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '28.0110000000000000',
      high: '28.0510000000000000',
      low: '26.3000000000000000',
      close: '26.9250000000000000',
      volume: '1007955.5700000000000000',
      quoteVolume: 27333103.0550634,
      btcVolume: 2672.2406391647623,
      usdVolume: 27333103.0550634,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '26.9328000000000000',
      high: '27.3301000000000000',
      low: '26.2110000000000000',
      close: '26.9409000000000000',
      volume: '1087088.9100000000000000',
      quoteVolume: 29207188.464991644,
      btcVolume: 2909.9205678527996,
      usdVolume: 29207188.464991644,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '26.9652000000000000',
      high: '27.2864000000000000',
      low: '26.7400000000000000',
      close: '26.9400000000000000',
      volume: '880521.1800000000000000',
      quoteVolume: 23808910.937024746,
      btcVolume: 2322.2513891613335,
      usdVolume: 23808910.937024746,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '26.9399000000000000',
      high: '26.9586000000000000',
      low: '26.0100000000000000',
      close: '26.2814000000000000',
      volume: '917921.5400000000000000',
      quoteVolume: 24273248.70356496,
      btcVolume: 2390.3516691531204,
      usdVolume: 24273248.70356496,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '26.2982000000000000',
      high: '26.4000000000000000',
      low: '25.5700000000000000',
      close: '26.0888000000000000',
      volume: '933021.8300000000000000',
      quoteVolume: 24320148.965540506,
      btcVolume: 2410.161394681131,
      usdVolume: 24320148.965540506,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '26.0886000000000000',
      high: '27.1908000000000000',
      low: '25.6502000000000000',
      close: '26.1489000000000000',
      volume: '1811039.1700000000000000',
      quoteVolume: 47866074.05944471,
      btcVolume: 4619.226473501923,
      usdVolume: 47866074.05944471,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '26.1251000000000000',
      high: '26.1507000000000000',
      low: '24.6224000000000000',
      close: '25.6051000000000000',
      volume: '1196840.9100000000000000',
      quoteVolume: 30351679.124037657,
      btcVolume: 2975.526344844052,
      usdVolume: 30351679.124037657,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '25.6056000000000000',
      high: '26.1290000000000000',
      low: '23.2030000000000000',
      close: '23.3565000000000000',
      volume: '1398347.3100000000000000',
      quoteVolume: 34916854.38443927,
      btcVolume: 3480.137824867305,
      usdVolume: 34916854.38443927,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '23.3559000000000000',
      high: '23.3559000000000000',
      low: '21.2202000000000000',
      close: '21.7777000000000000',
      volume: '1617357.3700000000000000',
      quoteVolume: 35791217.56290334,
      btcVolume: 3750.9222576047623,
      usdVolume: 35791217.56290334,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '21.7894000000000000',
      high: '22.5678000000000000',
      low: '21.5000000000000000',
      close: '22.1448000000000000',
      volume: '1599602.4900000000000000',
      quoteVolume: 35445343.4393091,
      btcVolume: 3714.583705432022,
      usdVolume: 35445343.4393091,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '22.1449000000000000',
      high: '22.3700000000000000',
      low: '20.8857000000000000',
      close: '21.0604000000000000',
      volume: '1372689.9900000000000000',
      quoteVolume: 29884844.230699934,
      btcVolume: 3119.26041144571,
      usdVolume: 29884844.230699934,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '21.0602000000000000',
      high: '21.6828000000000000',
      low: '20.9800000000000000',
      close: '21.4248000000000000',
      volume: '1434196.5900000000000000',
      quoteVolume: 30594531.66041575,
      btcVolume: 3180.3090835419152,
      usdVolume: 30594531.66041575,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '21.4195000000000000',
      high: '22.8303000000000000',
      low: '21.0100000000000000',
      close: '22.7449000000000000',
      volume: '1743830.8100000000000000',
      quoteVolume: 38331837.52907952,
      btcVolume: 3871.494591953881,
      usdVolume: 38331837.52907952,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '22.7449000000000000',
      high: '22.8900000000000000',
      low: '22.1615000000000000',
      close: '22.3511000000000000',
      volume: '1355171.8400000000000000',
      quoteVolume: 30562302.513001986,
      btcVolume: 2912.5933500976334,
      usdVolume: 30562302.513001986,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '22.3521000000000000',
      high: '22.5998000000000000',
      low: '21.4699000000000000',
      close: '21.6764000000000000',
      volume: '1086116.6600000000000000',
      quoteVolume: 23806043.422344267,
      btcVolume: 2257.8192440245984,
      usdVolume: 23806043.422344267,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '21.6821000000000000',
      high: '23.5213000000000000',
      low: '21.3000000000000000',
      close: '22.6385000000000000',
      volume: '2235402.4400000000000000',
      quoteVolume: 50322754.76862728,
      btcVolume: 4775.140325449655,
      usdVolume: 50322754.76862728,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '22.6389000000000000',
      high: '23.4450000000000000',
      low: '21.6997000000000000',
      close: '21.9905000000000000',
      volume: '1990229.3000000000000000',
      quoteVolume: 44871162.02233011,
      btcVolume: 4221.877198386819,
      usdVolume: 44871162.02233011,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '21.9906000000000000',
      high: '22.6500000000000000',
      low: '21.9100000000000000',
      close: '22.4120000000000000',
      volume: '1681983.7800000000000000',
      quoteVolume: 37521579.67938833,
      btcVolume: 3608.3791504554933,
      usdVolume: 37521579.67938833,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '22.4120000000000000',
      high: '22.7321000000000000',
      low: '22.0000000000000000',
      close: '22.4111000000000000',
      volume: '1304840.1500000000000000',
      quoteVolume: 29335266.506811257,
      btcVolume: 2806.5841675990073,
      usdVolume: 29335266.506811257,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '22.3747000000000000',
      high: '22.4901000000000000',
      low: '21.7400000000000000',
      close: '22.1046000000000000',
      volume: '1006341.0100000000000000',
      quoteVolume: 22302503.142360844,
      btcVolume: 2165.136904136606,
      usdVolume: 22302503.142360844,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '22.0978000000000000',
      high: '22.3262000000000000',
      low: '21.5000000000000000',
      close: '21.7679000000000000',
      volume: '831886.4700000000000000',
      quoteVolume: 18339145.368692745,
      btcVolume: 1792.2664010096803,
      usdVolume: 18339145.368692745,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '21.7704000000000000',
      high: '22.0724000000000000',
      low: '20.5413000000000000',
      close: '20.7334000000000000',
      volume: '1069114.6200000000000000',
      quoteVolume: 22669645.665027037,
      btcVolume: 2253.2292197146694,
      usdVolume: 22669645.665027037,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '20.7315000000000000',
      high: '20.9572000000000000',
      low: '19.6500000000000000',
      close: '20.8197000000000000',
      volume: '1699362.5600000000000000',
      quoteVolume: 34777083.85403479,
      btcVolume: 3420.318759152667,
      usdVolume: 34777083.85403479,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '20.8197000000000000',
      high: '21.2475000000000000',
      low: '20.4088000000000000',
      close: '20.7880000000000000',
      volume: '1429270.9500000000000000',
      quoteVolume: 29787724.15361781,
      btcVolume: 2889.221782175787,
      usdVolume: 29787724.15361781,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '20.7889000000000000',
      high: '21.1500000000000000',
      low: '20.6714000000000000',
      close: '20.9555000000000000',
      volume: '1305740.9000000000000000',
      quoteVolume: 27255071.779076073,
      btcVolume: 2640.2704864370567,
      usdVolume: 27255071.779076073,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '20.9438000000000000',
      high: '21.0419000000000000',
      low: '20.2619000000000000',
      close: '20.2901000000000000',
      volume: '1149167.0800000000000000',
      quoteVolume: 23790454.124340992,
      btcVolume: 2309.5205840680474,
      usdVolume: 23790454.124340992,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '20.2901000000000000',
      high: '20.8000000000000000',
      low: '19.8775000000000000',
      close: '20.2422000000000000',
      volume: '1032883.1400000000000000',
      quoteVolume: 21027752.240456037,
      btcVolume: 2049.967916517665,
      usdVolume: 21027752.240456037,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '20.2460000000000000',
      high: '21.4390000000000000',
      low: '20.0300000000000000',
      close: '21.0473000000000000',
      volume: '1845592.2700000000000000',
      quoteVolume: 38220802.17507817,
      btcVolume: 3744.021260306312,
      usdVolume: 38220802.17507817,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '21.0302000000000000',
      high: '23.1700000000000000',
      low: '20.9442000000000000',
      close: '22.1986000000000000',
      volume: '3302750.2400000000000000',
      quoteVolume: 73063135.11076553,
      btcVolume: 7173.648061693396,
      usdVolume: 73063135.11076553,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '22.1988000000000000',
      high: '22.4383000000000000',
      low: '20.0022000000000000',
      close: '21.5376000000000000',
      volume: '1560199.9000000000000000',
      quoteVolume: 33005910.961343434,
      btcVolume: 3309.6496506288595,
      usdVolume: 33005910.961343434,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '21.5281000000000000',
      high: '21.5764000000000000',
      low: '20.9005000000000000',
      close: '21.2695000000000000',
      volume: '1389940.6700000000000000',
      quoteVolume: 29611689.325407807,
      btcVolume: 2910.330413478063,
      usdVolume: 29611689.325407807,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '21.2741000000000000',
      high: '21.4494000000000000',
      low: '20.8096000000000000',
      close: '20.9666000000000000',
      volume: '1127512.7000000000000000',
      quoteVolume: 23814050.32814106,
      btcVolume: 2369.7781077006107,
      usdVolume: 23814050.32814106,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '20.9732000000000000',
      high: '20.9912000000000000',
      low: '20.0000000000000000',
      close: '20.3602000000000000',
      volume: '972362.6300000000000000',
      quoteVolume: 19879295.326771013,
      btcVolume: 1991.1559276366263,
      usdVolume: 19879295.326771013,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '20.3640000000000000',
      high: '20.3640000000000000',
      low: '19.1614000000000000',
      close: '19.3228000000000000',
      volume: '1094197.9900000000000000',
      quoteVolume: 21719659.651494753,
      btcVolume: 2196.348015116035,
      usdVolume: 21719659.651494753,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '19.3127000000000000',
      high: '19.5000000000000000',
      low: '14.6400000000000000',
      close: '15.8249000000000000',
      volume: '2280940.4000000000000000',
      quoteVolume: 40128590.36126655,
      btcVolume: 4420.050188018196,
      usdVolume: 40128590.36126655,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '15.8240000000000000',
      high: '16.4882000000000000',
      low: '14.8945000000000000',
      close: '16.0321000000000000',
      volume: '1826023.8900000000000000',
      quoteVolume: 28771070.64009279,
      btcVolume: 3404.4134048413493,
      usdVolume: 28771070.64009279,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '16.0138000000000000',
      high: '16.4000000000000000',
      low: '14.2555000000000000',
      close: '15.2212000000000000',
      volume: '1777727.2900000000000000',
      quoteVolume: 27673009.60844759,
      btcVolume: 3368.7647208454014,
      usdVolume: 27673009.60844759,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '15.2113000000000000',
      high: '15.7770000000000000',
      low: '14.7000000000000000',
      close: '15.6666000000000000',
      volume: '1435874.0300000000000000',
      quoteVolume: 21891944.29132878,
      btcVolume: 2721.037106291271,
      usdVolume: 21891944.29132878,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '15.6666000000000000',
      high: '15.8729000000000000',
      low: '15.2911000000000000',
      close: '15.7346000000000000',
      volume: '1097731.6800000000000000',
      quoteVolume: 17158364.84970291,
      btcVolume: 2104.8652182188,
      usdVolume: 17158364.84970291,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '15.7335000000000000',
      high: '15.7953000000000000',
      low: '14.8018000000000000',
      close: '15.1999000000000000',
      volume: '963970.0200000000000000',
      quoteVolume: 14796006.704381201,
      btcVolume: 1833.4019387024855,
      usdVolume: 14796006.704381201,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '15.1870000000000000',
      high: '15.8506000000000000',
      low: '14.6200000000000000',
      close: '15.8349000000000000',
      volume: '1129249.1300000000000000',
      quoteVolume: 17240225.00851707,
      btcVolume: 2144.011528382059,
      usdVolume: 17240225.00851707,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '15.8493000000000000',
      high: '16.2800000000000000',
      low: '15.6295000000000000',
      close: '15.8563000000000000',
      volume: '1180076.1200000000000000',
      quoteVolume: 18822946.423811994,
      btcVolume: 2253.1708840608026,
      usdVolume: 18822946.423811994,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '15.8689000000000000',
      high: '15.8892000000000000',
      low: '15.5000000000000000',
      close: '15.8742000000000000',
      volume: '950281.1200000000000000',
      quoteVolume: 14907087.290238062,
      btcVolume: 1806.9759980656027,
      usdVolume: 14907087.290238062,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '15.8900000000000000',
      high: '15.9290000000000000',
      low: '15.2500000000000000',
      close: '15.6377000000000000',
      volume: '904723.7000000000000000',
      quoteVolume: 14166132.276806083,
      btcVolume: 1718.482479568365,
      usdVolume: 14166132.276806083,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '15.6489000000000000',
      high: '15.8200000000000000',
      low: '15.2100000000000000',
      close: '15.6133000000000000',
      volume: '857614.2600000000000000',
      quoteVolume: 13323519.193896951,
      btcVolume: 1637.9909754976863,
      usdVolume: 13323519.193896951,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '15.6028000000000000',
      high: '15.7794000000000000',
      low: '15.3644000000000000',
      close: '15.6869000000000000',
      volume: '815215.7400000000000000',
      quoteVolume: 12687937.458294004,
      btcVolume: 1565.997866828115,
      usdVolume: 12687937.458294004,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '15.6866000000000000',
      high: '15.7299000000000000',
      low: '14.9100000000000000',
      close: '15.0688000000000000',
      volume: '814844.4500000000000000',
      quoteVolume: 12466458.898545044,
      btcVolume: 1563.9266798482508,
      usdVolume: 12466458.898545044,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '15.0655000000000000',
      high: '16.1280000000000000',
      low: '14.8555000000000000',
      close: '16.0030000000000000',
      volume: '1137667.6700000000000000',
      quoteVolume: 17614353.686007068,
      btcVolume: 2197.8583870903244,
      usdVolume: 17614353.686007068,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '16.0198000000000000',
      high: '16.4007000000000000',
      low: '15.7959000000000000',
      close: '16.0213000000000000',
      volume: '992780.4300000000000000',
      quoteVolume: 15889938.956474038,
      btcVolume: 1934.7114076545115,
      usdVolume: 15889938.956474038,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '16.0206000000000000',
      high: '17.9999000000000000',
      low: '15.8829000000000000',
      close: '17.7100000000000000',
      volume: '2809202.1000000000000000',
      quoteVolume: 47844345.02730628,
      btcVolume: 5781.710685310098,
      usdVolume: 47844345.02730628,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '17.7172000000000000',
      high: '17.9180000000000000',
      low: '17.0310000000000000',
      close: '17.5300000000000000',
      volume: '1785749.3400000000000000',
      quoteVolume: 31222666.861801084,
      btcVolume: 3655.173159325509,
      usdVolume: 31222666.861801084,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '17.5288000000000000',
      high: '17.7000000000000000',
      low: '16.5100000000000000',
      close: '16.5656000000000000',
      volume: '1476429.8400000000000000',
      quoteVolume: 25136224.21603257,
      btcVolume: 2982.0542530942034,
      usdVolume: 25136224.21603257,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '16.5665000000000000',
      high: '17.4868000000000000',
      low: '16.5059000000000000',
      close: '17.1700000000000000',
      volume: '1405829.2300000000000000',
      quoteVolume: 23921395.579711266,
      btcVolume: 2872.116767496237,
      usdVolume: 23921395.579711266,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '17.1666000000000000',
      high: '18.7699000000000000',
      low: '17.1110000000000000',
      close: '18.2560000000000000',
      volume: '2233894.4300000000000000',
      quoteVolume: 40107522.71327606,
      btcVolume: 4802.447899917144,
      usdVolume: 40107522.71327606,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '18.2560000000000000',
      high: '18.6275000000000000',
      low: '17.8511000000000000',
      close: '18.4376000000000000',
      volume: '1643019.3600000000000000',
      quoteVolume: 29971983.59315997,
      btcVolume: 3613.0751251258484,
      usdVolume: 29971983.59315997,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '18.4389000000000000',
      high: '19.1700000000000000',
      low: '18.1000000000000000',
      close: '18.5697000000000000',
      volume: '2913060.4200000000000000',
      quoteVolume: 54382456.61385796,
      btcVolume: 6569.711201030303,
      usdVolume: 54382456.61385796,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '18.5697000000000000',
      high: '18.6470000000000000',
      low: '17.3627000000000000',
      close: '17.7401000000000000',
      volume: '1815021.0600000000000000',
      quoteVolume: 32739450.98535026,
      btcVolume: 4055.212668955555,
      usdVolume: 32739450.98535026,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '17.7504000000000000',
      high: '18.8346000000000000',
      low: '17.5620000000000000',
      close: '18.5188000000000000',
      volume: '1889272.6600000000000000',
      quoteVolume: 34608013.92635818,
      btcVolume: 4311.318414716609,
      usdVolume: 34608013.92635818,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '18.5205000000000000',
      high: '18.7501000000000000',
      low: '17.6733000000000000',
      close: '18.2188000000000000',
      volume: '1427724.2100000000000000',
      quoteVolume: 26053677.954359047,
      btcVolume: 3266.035239146559,
      usdVolume: 26053677.954359047,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '18.2188000000000000',
      high: '18.4444000000000000',
      low: '17.8575000000000000',
      close: '18.2229000000000000',
      volume: '1457872.5200000000000000',
      quoteVolume: 26457012.36290216,
      btcVolume: 3326.33246552125,
      usdVolume: 26457012.36290216,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '18.2189000000000000',
      high: '18.5800000000000000',
      low: '17.7152000000000000',
      close: '18.4997000000000000',
      volume: '1696662.0200000000000000',
      quoteVolume: 30829354.6997274,
      btcVolume: 3832.375444981121,
      usdVolume: 30829354.6997274,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '18.4897000000000000',
      high: '18.5171000000000000',
      low: '17.9100000000000000',
      close: '18.2560000000000000',
      volume: '1497274.8700000000000000',
      quoteVolume: 27204985.86214098,
      btcVolume: 3313.6879489931994,
      usdVolume: 27204985.86214098,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '18.2551000000000000',
      high: '18.8000000000000000',
      low: '18.0692000000000000',
      close: '18.2286000000000000',
      volume: '1819185.3800000000000000',
      quoteVolume: 33532586.548880003,
      btcVolume: 4093.9879290171616,
      usdVolume: 33532586.548880003,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '18.2581000000000000',
      high: '18.2700000000000000',
      low: '16.2111000000000000',
      close: '16.6796000000000000',
      volume: '1735472.3000000000000000',
      quoteVolume: 30019967.217108868,
      btcVolume: 3879.4704562633056,
      usdVolume: 30019967.217108868,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '16.6798000000000000',
      high: '17.0987000000000000',
      low: '16.3100000000000000',
      close: '16.9194000000000000',
      volume: '1244966.9900000000000000',
      quoteVolume: 20790631.76627608,
      btcVolume: 2794.787971493176,
      usdVolume: 20790631.76627608,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '16.9194000000000000',
      high: '19.1110000000000000',
      low: '16.7516000000000000',
      close: '18.6188000000000000',
      volume: '2770630.2600000000000000',
      quoteVolume: 49737182.561813205,
      btcVolume: 6263.872839719397,
      usdVolume: 49737182.561813205,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '18.6189000000000000',
      high: '19.7653000000000000',
      low: '18.0308000000000000',
      close: '18.9501000000000000',
      volume: '3038020.4400000000000000',
      quoteVolume: 57490280.69272188,
      btcVolume: 6119.3718702883,
      usdVolume: 57490280.69272188,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '18.9311000000000000',
      high: '19.6300000000000000',
      low: '18.4744000000000000',
      close: '19.2888000000000000',
      volume: '2292790.2600000000000000',
      quoteVolume: 43689050.22110826,
      btcVolume: 4656.735220807045,
      usdVolume: 43689050.22110826,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '19.2938000000000000',
      high: '21.1500000000000000',
      low: '19.2600000000000000',
      close: '19.9390000000000000',
      volume: '2712590.2200000000000000',
      quoteVolume: 54705192.09512571,
      btcVolume: 5746.423873513421,
      usdVolume: 54705192.09512571,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '19.9379000000000000',
      high: '21.2378000000000000',
      low: '19.8100000000000000',
      close: '20.7452000000000000',
      volume: '2287705.3800000000000000',
      quoteVolume: 47070804.28249447,
      btcVolume: 5028.784777580716,
      usdVolume: 47070804.28249447,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '20.7300000000000000',
      high: '20.7975000000000000',
      low: '19.1076000000000000',
      close: '20.0520000000000000',
      volume: '1822916.4300000000000000',
      quoteVolume: 36219179.39048555,
      btcVolume: 3946.264713227161,
      usdVolume: 36219179.39048555,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '20.0500000000000000',
      high: '20.3700000000000000',
      low: '19.3529000000000000',
      close: '19.9099000000000000',
      volume: '1677653.1800000000000000',
      quoteVolume: 33444789.194522977,
      btcVolume: 3654.9820249353547,
      usdVolume: 33444789.194522977,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '19.9087000000000000',
      high: '20.0399000000000000',
      low: '19.6424000000000000',
      close: '19.9811000000000000',
      volume: '1304915.2800000000000000',
      quoteVolume: 25929970.432292167,
      btcVolume: 2837.8645933661815,
      usdVolume: 25929970.432292167,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '19.9988000000000000',
      high: '20.3000000000000000',
      low: '19.9600000000000000',
      close: '20.1745000000000000',
      volume: '1604349.3200000000000000',
      quoteVolume: 32341714.859536927,
      btcVolume: 3491.3146563665596,
      usdVolume: 32341714.859536927,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '20.1847000000000000',
      high: '20.3900000000000000',
      low: '19.8053000000000000',
      close: '20.1874000000000000',
      volume: '1331174.6000000000000000',
      quoteVolume: 26791642.74283898,
      btcVolume: 2906.420761934206,
      usdVolume: 26791642.74283898,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '20.1725000000000000',
      high: '20.8800000000000000',
      low: '20.0285000000000000',
      close: '20.6139000000000000',
      volume: '1638295.2000000000000000',
      quoteVolume: 33568729.35200188,
      btcVolume: 3633.9058084230064,
      usdVolume: 33568729.35200188,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '20.6139000000000000',
      high: '20.8900000000000000',
      low: '20.3200000000000000',
      close: '20.6588000000000000',
      volume: '1435673.9200000000000000',
      quoteVolume: 29643945.41316726,
      btcVolume: 3174.3640255474643,
      usdVolume: 29643945.41316726,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '20.6621000000000000',
      high: '20.9399000000000000',
      low: '20.4408000000000000',
      close: '20.7000000000000000',
      volume: '1304183.9900000000000000',
      quoteVolume: 26943504.690265935,
      btcVolume: 2886.089792710133,
      usdVolume: 26943504.690265935,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '20.6993000000000000',
      high: '20.8490000000000000',
      low: '19.9100000000000000',
      close: '20.4208000000000000',
      volume: '1034081.1100000000000000',
      quoteVolume: 21112048.776744843,
      btcVolume: 2279.6955633595517,
      usdVolume: 21112048.776744843,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '20.4342000000000000',
      high: '20.5164000000000000',
      low: '19.2609000000000000',
      close: '19.6460000000000000',
      volume: '1081524.1000000000000000',
      quoteVolume: 21620174.59220783,
      btcVolume: 2392.7004682323113,
      usdVolume: 21620174.59220783,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '19.6460000000000000',
      high: '19.9632000000000000',
      low: '19.4626000000000000',
      close: '19.6999000000000000',
      volume: '886139.1000000000000000',
      quoteVolume: 17507369.511074036,
      btcVolume: 1985.1423284212408,
      usdVolume: 17507369.511074036,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '19.7042000000000000',
      high: '20.6000000000000000',
      low: '19.6694000000000000',
      close: '20.4333000000000000',
      volume: '1278095.9300000000000000',
      quoteVolume: 25754570.998597786,
      btcVolume: 2901.93943637929,
      usdVolume: 25754570.998597786,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '20.4323000000000000',
      high: '20.6277000000000000',
      low: '19.7497000000000000',
      close: '20.0888000000000000',
      volume: '1002364.6600000000000000',
      quoteVolume: 20292034.237837035,
      btcVolume: 2289.625297028486,
      usdVolume: 20292034.237837035,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '20.0977000000000000',
      high: '20.9798000000000000',
      low: '19.9443000000000000',
      close: '20.9798000000000000',
      volume: '1235499.6000000000000000',
      quoteVolume: 25207219.032343943,
      btcVolume: 2881.138559153625,
      usdVolume: 25207219.032343943,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '20.9797000000000000',
      high: '21.8000000000000000',
      low: '20.6800000000000000',
      close: '21.3846000000000000',
      volume: '1369703.6200000000000000',
      quoteVolume: 29120752.930271562,
      btcVolume: 3322.2398970219933,
      usdVolume: 29120752.930271562,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '21.3883000000000000',
      high: '21.6492000000000000',
      low: '20.7111000000000000',
      close: '21.3166000000000000',
      volume: '1177353.8200000000000000',
      quoteVolume: 24991032.071327083,
      btcVolume: 2875.553521588978,
      usdVolume: 24991032.071327083,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '21.3165000000000000',
      high: '21.3353000000000000',
      low: '19.9614000000000000',
      close: '20.2735000000000000',
      volume: '1358774.2500000000000000',
      quoteVolume: 27979389.54651814,
      btcVolume: 3269.53297184289,
      usdVolume: 27979389.54651814,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '20.2871000000000000',
      high: '20.5999000000000000',
      low: '19.9000000000000000',
      close: '20.1631000000000000',
      volume: '1001272.5400000000000000',
      quoteVolume: 20291968.77348383,
      btcVolume: 2394.1821660160967,
      usdVolume: 20291968.77348383,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '20.1641000000000000',
      high: '20.4359000000000000',
      low: '19.7500000000000000',
      close: '20.1888000000000000',
      volume: '1153032.2600000000000000',
      quoteVolume: 23183744.303397186,
      btcVolume: 2722.9127437014527,
      usdVolume: 23183744.303397186,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '20.1894000000000000',
      high: '20.1940000000000000',
      low: '18.6000000000000000',
      close: '19.0817000000000000',
      volume: '1368453.1000000000000000',
      quoteVolume: 26728303.31546702,
      btcVolume: 3188.792085551649,
      usdVolume: 26728303.31546702,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '19.0817000000000000',
      high: '19.2800000000000000',
      low: '18.0800000000000000',
      close: '18.4997000000000000',
      volume: '1218553.0600000000000000',
      quoteVolume: 22711534.818970643,
      btcVolume: 2795.312906394973,
      usdVolume: 22711534.818970643,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '18.4999000000000000',
      high: '18.7494000000000000',
      low: '17.7738000000000000',
      close: '18.0826000000000000',
      volume: '1209633.0100000000000000',
      quoteVolume: 22221973.60810997,
      btcVolume: 2739.6584331838912,
      usdVolume: 22221973.60810997,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '18.0827000000000000',
      high: '18.2399000000000000',
      low: '16.3400000000000000',
      close: '16.7985000000000000',
      volume: '1715376.7100000000000000',
      quoteVolume: 29495384.97432084,
      btcVolume: 3764.2748558580925,
      usdVolume: 29495384.97432084,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '16.7986000000000000',
      high: '17.2798000000000000',
      low: '14.2846000000000000',
      close: '15.4875000000000000',
      volume: '3355659.6400000000000000',
      quoteVolume: 52944056.290057965,
      btcVolume: 7292.508377878102,
      usdVolume: 52944056.290057965,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '15.4767000000000000',
      high: '16.3483000000000000',
      low: '14.9570000000000000',
      close: '16.2320000000000000',
      volume: '1941896.4600000000000000',
      quoteVolume: 30375854.714530647,
      btcVolume: 4197.788544615611,
      usdVolume: 30375854.714530647,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '16.2321000000000000',
      high: '16.5060000000000000',
      low: '14.9000000000000000',
      close: '14.9964000000000000',
      volume: '2155053.8800000000000000',
      quoteVolume: 34080052.57061797,
      btcVolume: 4773.880226329648,
      usdVolume: 34080052.57061797,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '15.0152000000000000',
      high: '15.7600000000000000',
      low: '13.8800000000000000',
      close: '15.1457000000000000',
      volume: '3063415.2800000000000000',
      quoteVolume: 45713597.39123177,
      btcVolume: 6615.167109750736,
      usdVolume: 45713597.39123177,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '15.1466000000000000',
      high: '15.8900000000000000',
      low: '14.9382000000000000',
      close: '15.3564000000000000',
      volume: '1658330.7900000000000000',
      quoteVolume: 25501834.969267953,
      btcVolume: 3567.6079268195067,
      usdVolume: 25501834.969267953,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '15.3565000000000000',
      high: '16.2255000000000000',
      low: '14.5811000000000000',
      close: '16.0888000000000000',
      volume: '2405778.6200000000000000',
      quoteVolume: 37268429.1905796,
      btcVolume: 5141.099123684343,
      usdVolume: 37268429.1905796,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '16.0888000000000000',
      high: '16.1899000000000000',
      low: '15.4404000000000000',
      close: '15.5333000000000000',
      volume: '1619845.5800000000000000',
      quoteVolume: 25668886.932569165,
      btcVolume: 3417.447648852904,
      usdVolume: 25668886.932569165,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '15.5255000000000000',
      high: '16.2998000000000000',
      low: '15.4692000000000000',
      close: '16.2281000000000000',
      volume: '2034007.5200000000000000',
      quoteVolume: 32409441.241127864,
      btcVolume: 4248.276128127099,
      usdVolume: 32409441.241127864,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '16.2281000000000000',
      high: '16.2765000000000000',
      low: '15.4501000000000000',
      close: '15.7118000000000000',
      volume: '1435716.2100000000000000',
      quoteVolume: 22780843.578936126,
      btcVolume: 2979.5426085168438,
      usdVolume: 22780843.578936126,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '15.7030000000000000',
      high: '15.7139000000000000',
      low: '14.8000000000000000',
      close: '15.4297000000000000',
      volume: '1232843.2000000000000000',
      quoteVolume: 18823975.185732864,
      btcVolume: 2559.4840004958196,
      usdVolume: 18823975.185732864,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '15.4287000000000000',
      high: '15.6200000000000000',
      low: '15.0190000000000000',
      close: '15.1288000000000000',
      volume: '1205451.5000000000000000',
      quoteVolume: 18412700.208588973,
      btcVolume: 2519.5182703419514,
      usdVolume: 18412700.208588973,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '15.1394000000000000',
      high: '15.4255000000000000',
      low: '15.0475000000000000',
      close: '15.2542000000000000',
      volume: '1260422.8200000000000000',
      quoteVolume: 19241970.49272903,
      btcVolume: 2629.721760674202,
      usdVolume: 19241970.49272903,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '15.2545000000000000',
      high: '15.7511000000000000',
      low: '14.8275000000000000',
      close: '15.1000000000000000',
      volume: '1448353.5900000000000000',
      quoteVolume: 22125483.77934104,
      btcVolume: 3029.126283546053,
      usdVolume: 22125483.77934104,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '15.1254000000000000',
      high: '15.7200000000000000',
      low: '14.8060000000000000',
      close: '15.6594000000000000',
      volume: '1702989.3100000000000000',
      quoteVolume: 26185398.260484762,
      btcVolume: 3580.017154853113,
      usdVolume: 26185398.260484762,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '15.6554000000000000',
      high: '15.7498000000000000',
      low: '15.3000000000000000',
      close: '15.6666000000000000',
      volume: '1425210.7900000000000000',
      quoteVolume: 22138436.21785305,
      btcVolume: 2987.805804791008,
      usdVolume: 22138436.21785305,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '15.6570000000000000',
      high: '15.8199000000000000',
      low: '15.5190000000000000',
      close: '15.6067000000000000',
      volume: '1249702.2900000000000000',
      quoteVolume: 19570141.810453042,
      btcVolume: 2601.8723228509753,
      usdVolume: 19570141.810453042,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '15.6066000000000000',
      high: '15.7200000000000000',
      low: '15.3496000000000000',
      close: '15.6121000000000000',
      volume: '1097588.2900000000000000',
      quoteVolume: 17074933.996354897,
      btcVolume: 2281.4706372298097,
      usdVolume: 17074933.996354897,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '15.5947000000000000',
      high: '15.7990000000000000',
      low: '15.1838000000000000',
      close: '15.3284000000000000',
      volume: '952736.1300000000000000',
      quoteVolume: 14820153.790239785,
      btcVolume: 1984.031553870555,
      usdVolume: 14820153.790239785,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '15.3284000000000000',
      high: '15.3454000000000000',
      low: '14.6000000000000000',
      close: '14.8096000000000000',
      volume: '1001509.5100000000000000',
      quoteVolume: 15034745.741738938,
      btcVolume: 2055.8667526296367,
      usdVolume: 15034745.741738938,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '14.8085000000000000',
      high: '14.9984000000000000',
      low: '14.6000000000000000',
      close: '14.7800000000000000',
      volume: '803776.6500000000000000',
      quoteVolume: 11916428.093796976,
      btcVolume: 1650.5866045607136,
      usdVolume: 11916428.093796976,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '14.7800000000000000',
      high: '14.8495000000000000',
      low: '14.3500000000000000',
      close: '14.7238000000000000',
      volume: '740738.6700000000000000',
      quoteVolume: 10828113.391639946,
      btcVolume: 1508.9196509223818,
      usdVolume: 10828113.391639946,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '14.7165000000000000',
      high: '14.9775000000000000',
      low: '14.6600000000000000',
      close: '14.8555000000000000',
      volume: '883103.0900000000000000',
      quoteVolume: 13061294.686844045,
      btcVolume: 1805.6559930105461,
      usdVolume: 13061294.686844045,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '14.8555000000000000',
      high: '15.0000000000000000',
      low: '14.1629000000000000',
      close: '14.3888000000000000',
      volume: '841038.8200000000000000',
      quoteVolume: 12317989.087613093,
      btcVolume: 1717.5495142247462,
      usdVolume: 12317989.087613093,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '14.3887000000000000',
      high: '14.4930000000000000',
      low: '14.1361000000000000',
      close: '14.3949000000000000',
      volume: '786465.1500000000000000',
      quoteVolume: 11267335.87640899,
      btcVolume: 1588.1571392617413,
      usdVolume: 11267335.87640899,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '14.3946000000000000',
      high: '14.4356000000000000',
      low: '13.2580000000000000',
      close: '13.4544000000000000',
      volume: '1091047.0100000000000000',
      quoteVolume: 15235222.031920152,
      btcVolume: 2170.523777622476,
      usdVolume: 15235222.031920152,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '13.4721000000000000',
      high: '13.4750000000000000',
      low: '12.1111000000000000',
      close: '12.3810000000000000',
      volume: '1681588.6700000000000000',
      quoteVolume: 21579880.298549183,
      btcVolume: 3181.966603781795,
      usdVolume: 21579880.298549183,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '12.3837000000000000',
      high: '13.7348000000000000',
      low: '12.2100000000000000',
      close: '13.6158000000000000',
      volume: '2321743.1800000000000000',
      quoteVolume: 29939618.62193826,
      btcVolume: 4400.7711891586205,
      usdVolume: 29939618.62193826,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '13.6247000000000000',
      high: '13.7631000000000000',
      low: '12.9624000000000000',
      close: '13.3441000000000000',
      volume: '1378005.7000000000000000',
      quoteVolume: 18297729.627122004,
      btcVolume: 2555.9462004711754,
      usdVolume: 18297729.627122004,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '13.3519000000000000',
      high: '13.5291000000000000',
      low: '13.0800000000000000',
      close: '13.5000000000000000',
      volume: '1397599.9900000000000000',
      quoteVolume: 18629001.250787843,
      btcVolume: 2608.2124018163277,
      usdVolume: 18629001.250787843,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '13.4932000000000000',
      high: '13.5100000000000000',
      low: '13.2600000000000000',
      close: '13.2933000000000000',
      volume: '1260784.8500000000000000',
      quoteVolume: 16846058.069898106,
      btcVolume: 2357.3199262431112,
      usdVolume: 16846058.069898106,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '13.2874000000000000',
      high: '13.8309000000000000',
      low: '13.2689000000000000',
      close: '13.7798000000000000',
      volume: '1436306.2900000000000000',
      quoteVolume: 19378426.182353877,
      btcVolume: 2671.594975672879,
      usdVolume: 19378426.182353877,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '13.7768000000000000',
      high: '13.9447000000000000',
      low: '13.2000000000000000',
      close: '13.2998000000000000',
      volume: '1226252.0100000000000000',
      quoteVolume: 16842774.154331747,
      btcVolume: 2236.6478855031783,
      usdVolume: 16842774.154331747,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '13.2987000000000000',
      high: '13.5916000000000000',
      low: '13.2299000000000000',
      close: '13.3261000000000000',
      volume: '1306914.4800000000000000',
      quoteVolume: 17482280.272196006,
      btcVolume: 2396.4134118510615,
      usdVolume: 17482280.272196006,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '13.3340000000000000',
      high: '13.3522000000000000',
      low: '12.8500000000000000',
      close: '13.0666000000000000',
      volume: '923319.5400000000000000',
      quoteVolume: 12154298.537538983,
      btcVolume: 1682.9049427550863,
      usdVolume: 12154298.537538983,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '13.0665000000000000',
      high: '13.5466000000000000',
      low: '13.0230000000000000',
      close: '13.4952000000000000',
      volume: '1056537.7800000000000000',
      quoteVolume: 14002514.230783973,
      btcVolume: 1930.7821358732667,
      usdVolume: 14002514.230783973,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '13.1349000000000000',
      high: '13.2981000000000000',
      low: '12.8927000000000000',
      close: '13.2888000000000000',
      volume: '1109904.6900000000000000',
      quoteVolume: 14633137.803384082,
      btcVolume: 2029.5574032953941,
      usdVolume: 14633137.803384082,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '13.2888000000000000',
      high: '13.7200000000000000',
      low: '13.2569000000000000',
      close: '13.6779000000000000',
      volume: '1358080.6500000000000000',
      quoteVolume: 18404654.469618995,
      btcVolume: 2515.9847764068354,
      usdVolume: 18404654.469618995,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '13.6788000000000000',
      high: '14.2186000000000000',
      low: '13.5750000000000000',
      close: '14.1040000000000000',
      volume: '1608471.4600000000000000',
      quoteVolume: 22338596.36302602,
      btcVolume: 3032.599269002952,
      usdVolume: 22338596.36302602,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '14.0984000000000000',
      high: '14.3796000000000000',
      low: '13.7800000000000000',
      close: '13.8597000000000000',
      volume: '1255186.2700000000000000',
      quoteVolume: 17736371.93092798,
      btcVolume: 2418.5750608115254,
      usdVolume: 17736371.93092798,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '13.8597000000000000',
      high: '14.0564000000000000',
      low: '13.6400000000000000',
      close: '13.7161000000000000',
      volume: '970000.0200000000000000',
      quoteVolume: 13410409.01565197,
      btcVolume: 1853.0878040366188,
      usdVolume: 13410409.01565197,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '13.7159000000000000',
      high: '13.8692000000000000',
      low: '13.6500000000000000',
      close: '13.7184000000000000',
      volume: '940273.7700000000000000',
      quoteVolume: 12945796.344635054,
      btcVolume: 1793.7739920087274,
      usdVolume: 12945796.344635054,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '13.7072000000000000',
      high: '13.7302000000000000',
      low: '12.9800000000000000',
      close: '13.0105000000000000',
      volume: '960515.6000000000000000',
      quoteVolume: 12855590.844871968,
      btcVolume: 1810.6692341853307,
      usdVolume: 12855590.844871968,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '13.0104000000000000',
      high: '13.7500000000000000',
      low: '12.9801000000000000',
      close: '13.6474000000000000',
      volume: '1458213.2600000000000000',
      quoteVolume: 19697994.93713138,
      btcVolume: 2725.85101080842,
      usdVolume: 19697994.93713138,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '13.6481000000000000',
      high: '13.8710000000000000',
      low: '13.5102000000000000',
      close: '13.8092000000000000',
      volume: '1231268.3700000000000000',
      quoteVolume: 16885513.379274156,
      btcVolume: 2302.0692057035767,
      usdVolume: 16885513.379274156,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '13.7978000000000000',
      high: '14.3133000000000000',
      low: '13.7910000000000000',
      close: '14.0532000000000000',
      volume: '1458003.0200000000000000',
      quoteVolume: 20531817.041770168,
      btcVolume: 2760.741171838204,
      usdVolume: 20531817.041770168,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '14.0488000000000000',
      high: '14.9873000000000000',
      low: '14.0168000000000000',
      close: '14.9471000000000000',
      volume: '1633712.0400000000000000',
      quoteVolume: 23743495.229042225,
      btcVolume: 3144.9834827722107,
      usdVolume: 23743495.229042225,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '14.9427000000000000',
      high: '15.1870000000000000',
      low: '14.4560000000000000',
      close: '15.0226000000000000',
      volume: '1458769.7000000000000000',
      quoteVolume: 21730397.146320045,
      btcVolume: 2741.5005004584696,
      usdVolume: 21730397.146320045,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '15.0212000000000000',
      high: '15.3200000000000000',
      low: '14.2000000000000000',
      close: '14.5254000000000000',
      volume: '1444209.0300000000000000',
      quoteVolume: 21394481.601980902,
      btcVolume: 2593.189647193552,
      usdVolume: 21394481.601980902,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '14.5308000000000000',
      high: '14.5823000000000000',
      low: '14.2010000000000000',
      close: '14.3978000000000000',
      volume: '1052935.6900000000000000',
      quoteVolume: 15196486.221940989,
      btcVolume: 1918.9735468286913,
      usdVolume: 15196486.221940989,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '14.3806000000000000',
      high: '15.0973000000000000',
      low: '14.1100000000000000',
      close: '15.0700000000000000',
      volume: '1572079.7800000000000000',
      quoteVolume: 23078267.45961109,
      btcVolume: 2897.5210314858323,
      usdVolume: 23078267.45961109,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '15.0643000000000000',
      high: '15.3200000000000000',
      low: '14.7111000000000000',
      close: '14.9177000000000000',
      volume: '1397398.3000000000000000',
      quoteVolume: 20959042.697335042,
      btcVolume: 2577.9974693735912,
      usdVolume: 20959042.697335042,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '14.9137000000000000',
      high: '15.4707000000000000',
      low: '14.8001000000000000',
      close: '15.3366000000000000',
      volume: '2042556.1700000000000000',
      quoteVolume: 31106160.735202186,
      btcVolume: 3830.745845979114,
      usdVolume: 31106160.735202186,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '15.3370000000000000',
      high: '15.3572000000000000',
      low: '14.9295000000000000',
      close: '15.1736000000000000',
      volume: '1520954.5900000000000000',
      quoteVolume: 22965408.692243036,
      btcVolume: 2830.7449498311053,
      usdVolume: 22965408.692243036,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '15.1735000000000000',
      high: '16.9100000000000000',
      low: '15.1659000000000000',
      close: '16.6371000000000000',
      volume: '2711583.2200000000000000',
      quoteVolume: 43167140.88381576,
      btcVolume: 5029.673767491132,
      usdVolume: 43167140.88381576,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '16.6301000000000000',
      high: '18.1900000000000000',
      low: '16.2700000000000000',
      close: '17.6655000000000000',
      volume: '3040382.6900000000000000',
      quoteVolume: 52587124.739194795,
      btcVolume: 5996.84247707859,
      usdVolume: 52587124.739194795,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '17.6655000000000000',
      high: '17.6923000000000000',
      low: '16.3100000000000000',
      close: '17.0333000000000000',
      volume: '1842017.2900000000000000',
      quoteVolume: 31029984.91111983,
      btcVolume: 3570.093648897622,
      usdVolume: 31029984.91111983,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '17.0400000000000000',
      high: '18.4849000000000000',
      low: '17.0400000000000000',
      close: '18.0724000000000000',
      volume: '2734425.7700000000000000',
      quoteVolume: 48859738.857078,
      btcVolume: 5520.430319295519,
      usdVolume: 48859738.857078,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '18.0763000000000000',
      high: '18.3602000000000000',
      low: '17.4500000000000000',
      close: '17.8088000000000000',
      volume: '1903073.6300000000000000',
      quoteVolume: 34104864.132670745,
      btcVolume: 3831.157825228501,
      usdVolume: 34104864.132670745,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '17.7954000000000000',
      high: '18.4950000000000000',
      low: '16.6666000000000000',
      close: '17.3880000000000000',
      volume: '2127422.8500000000000000',
      quoteVolume: 37440202.05314928,
      btcVolume: 4223.113876587941,
      usdVolume: 37440202.05314928,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '17.3706000000000000',
      high: '17.5516000000000000',
      low: '16.8043000000000000',
      close: '17.3235000000000000',
      volume: '1576499.0800000000000000',
      quoteVolume: 27136151.86145567,
      btcVolume: 3135.1916814614,
      usdVolume: 27136151.86145567,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '17.3265000000000000',
      high: '18.2000000000000000',
      low: '17.2228000000000000',
      close: '18.1166000000000000',
      volume: '1923914.5000000000000000',
      quoteVolume: 33973360.84587211,
      btcVolume: 3922.624684198108,
      usdVolume: 33973360.84587211,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '18.1107000000000000',
      high: '18.4000000000000000',
      low: '17.5500000000000000',
      close: '17.8344000000000000',
      volume: '1607749.7800000000000000',
      quoteVolume: 28997476.09421225,
      btcVolume: 3333.354464634478,
      usdVolume: 28997476.09421225,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '17.8344000000000000',
      high: '17.8344000000000000',
      low: '16.7441000000000000',
      close: '17.0524000000000000',
      volume: '1363632.7900000000000000',
      quoteVolume: 23479710.346752085,
      btcVolume: 2763.2312364747722,
      usdVolume: 23479710.346752085,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '17.0813000000000000',
      high: '17.4429000000000000',
      low: '16.4288000000000000',
      close: '17.1148000000000000',
      volume: '1411501.9800000000000000',
      quoteVolume: 23870035.10373168,
      btcVolume: 2846.8695971312045,
      usdVolume: 23870035.10373168,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '17.1133000000000000',
      high: '17.1215000000000000',
      low: '16.5685000000000000',
      close: '16.9555000000000000',
      volume: '1211416.3200000000000000',
      quoteVolume: 20396383.54308985,
      btcVolume: 2446.212569813146,
      usdVolume: 20396383.54308985,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '16.9419000000000000',
      high: '17.4990000000000000',
      low: '16.8315000000000000',
      close: '17.4990000000000000',
      volume: '1619344.7500000000000000',
      quoteVolume: 27866656.71784081,
      btcVolume: 3293.769502538616,
      usdVolume: 27866656.71784081,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '17.4919000000000000',
      high: '17.9500000000000000',
      low: '17.2198000000000000',
      close: '17.6700000000000000',
      volume: '1562998.8400000000000000',
      quoteVolume: 27448591.22210781,
      btcVolume: 3140.819387866112,
      usdVolume: 27448591.22210781,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '17.6700000000000000',
      high: '18.1600000000000000',
      low: '17.4466000000000000',
      close: '18.0662000000000000',
      volume: '1916526.0700000000000000',
      quoteVolume: 34097546.051095806,
      btcVolume: 3754.0928695235057,
      usdVolume: 34097546.051095806,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '18.0614000000000000',
      high: '18.4437000000000000',
      low: '17.8090000000000000',
      close: '17.9134000000000000',
      volume: '1447265.2000000000000000',
      quoteVolume: 26231426.254242063,
      btcVolume: 2807.684884866969,
      usdVolume: 26231426.254242063,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '17.9296000000000000',
      high: '18.7027000000000000',
      low: '17.7000000000000000',
      close: '18.5555000000000000',
      volume: '1787521.2200000000000000',
      quoteVolume: 32487717.892014977,
      btcVolume: 3454.6241320377576,
      usdVolume: 32487717.892014977,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '18.5557000000000000',
      high: '18.8800000000000000',
      low: '17.8000000000000000',
      close: '18.2644000000000000',
      volume: '1647399.0800000000000000',
      quoteVolume: 30233383.52456968,
      btcVolume: 3224.3985404614446,
      usdVolume: 30233383.52456968,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '18.2644000000000000',
      high: '18.5956000000000000',
      low: '18.0300000000000000',
      close: '18.2752000000000000',
      volume: '1511588.8600000000000000',
      quoteVolume: 27611547.000338193,
      btcVolume: 2942.137716907503,
      usdVolume: 27611547.000338193,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '18.2753000000000000',
      high: '18.7714000000000000',
      low: '17.8277000000000000',
      close: '18.4333000000000000',
      volume: '1558716.5200000000000000',
      quoteVolume: 28652209.626673575,
      btcVolume: 3053.4044339388865,
      usdVolume: 28652209.626673575,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '18.4333000000000000',
      high: '18.8763000000000000',
      low: '18.1959000000000000',
      close: '18.4600000000000000',
      volume: '1314693.1200000000000000',
      quoteVolume: 24235415.42380266,
      btcVolume: 2588.7478758068564,
      usdVolume: 24235415.42380266,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '18.4610000000000000',
      high: '18.6158000000000000',
      low: '17.8700000000000000',
      close: '18.1778000000000000',
      volume: '1215556.3700000000000000',
      quoteVolume: 22148996.309246045,
      btcVolume: 2399.6608902337066,
      usdVolume: 22148996.309246045,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '18.1778000000000000',
      high: '19.4583000000000000',
      low: '18.1635000000000000',
      close: '19.2620000000000000',
      volume: '2068915.0400000000000000',
      quoteVolume: 39153896.76314988,
      btcVolume: 4174.003818806791,
      usdVolume: 39153896.76314988,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '19.2686000000000000',
      high: '20.8400000000000000',
      low: '19.0300000000000000',
      close: '20.6675000000000000',
      volume: '2770774.3100000000000000',
      quoteVolume: 55407991.63401992,
      btcVolume: 5699.561189798247,
      usdVolume: 55407991.63401992,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '20.6665000000000000',
      high: '22.5221000000000000',
      low: '20.5199000000000000',
      close: '22.0555000000000000',
      volume: '3182382.8900000000000000',
      quoteVolume: 68795504.22441779,
      btcVolume: 7022.0759772449155,
      usdVolume: 68795504.22441779,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '22.0360000000000000',
      high: '22.1900000000000000',
      low: '20.8943000000000000',
      close: '21.7888000000000000',
      volume: '2208642.4000000000000000',
      quoteVolume: 47849193.68056081,
      btcVolume: 4879.389335821138,
      usdVolume: 47849193.68056081,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '21.7871000000000000',
      high: '24.3814000000000000',
      low: '21.7746000000000000',
      close: '24.3800000000000000',
      volume: '2846806.8900000000000000',
      quoteVolume: 66056350.15274652,
      btcVolume: 6555.827857878055,
      usdVolume: 66056350.15274652,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '24.3517000000000000',
      high: '25.7935000000000000',
      low: '23.3000000000000000',
      close: '25.1465000000000000',
      volume: '4794544.7200000000000000',
      quoteVolume: 118356199.03064102,
      btcVolume: 11937.05365463192,
      usdVolume: 118356199.03064102,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '25.1450000000000000',
      high: '25.7400000000000000',
      low: '24.1202000000000000',
      close: '25.6963000000000000',
      volume: '3512538.3800000000000000',
      quoteVolume: 87532758.12909538,
      btcVolume: 8788.633029031116,
      usdVolume: 87532758.12909538,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '25.6728000000000000',
      high: '26.9196000000000000',
      low: '25.3402000000000000',
      close: '26.4243000000000000',
      volume: '2954235.4800000000000000',
      quoteVolume: 76168100.46527852,
      btcVolume: 7380.550113864897,
      usdVolume: 76168100.46527852,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '26.4136000000000000',
      high: '27.1905000000000000',
      low: '24.5995000000000000',
      close: '25.4579000000000000',
      volume: '3591601.3200000000000000',
      quoteVolume: 92634936.37990527,
      btcVolume: 9002.249103389786,
      usdVolume: 92634936.37990527,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '25.4621000000000000',
      high: '26.5130000000000000',
      low: '25.0304000000000000',
      close: '26.3547000000000000',
      volume: '3252594.3200000000000000',
      quoteVolume: 84109977.24348529,
      btcVolume: 8191.832934805558,
      usdVolume: 84109977.24348529,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '26.3662000000000000',
      high: '26.4931000000000000',
      low: '23.5288000000000000',
      close: '24.2576000000000000',
      volume: '3355108.1400000000000000',
      quoteVolume: 85265826.4624216,
      btcVolume: 8426.730139384954,
      usdVolume: 85265826.4624216,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '24.2388000000000000',
      high: '25.2485000000000000',
      low: '21.8000000000000000',
      close: '23.3193000000000000',
      volume: '3752723.6900000000000000',
      quoteVolume: 89187179.91969427,
      btcVolume: 9019.615804441175,
      usdVolume: 89187179.91969427,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '23.3193000000000000',
      high: '23.6000000000000000',
      low: '21.5510000000000000',
      close: '23.4555000000000000',
      volume: '3208991.9600000000000000',
      quoteVolume: 72372207.9082924,
      btcVolume: 7461.240037311607,
      usdVolume: 72372207.9082924,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '23.4558000000000000',
      high: '24.4588000000000000',
      low: '22.8013000000000000',
      close: '24.0821000000000000',
      volume: '3010749.1700000000000000',
      quoteVolume: 71169442.58374034,
      btcVolume: 7232.1388082425665,
      usdVolume: 71169442.58374034,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '24.0611000000000000',
      high: '24.3264000000000000',
      low: '21.8000000000000000',
      close: '22.3709000000000000',
      volume: '2140879.9700000000000000',
      quoteVolume: 50475552.67366864,
      btcVolume: 5035.254917997106,
      usdVolume: 50475552.67366864,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '22.3702000000000000',
      high: '22.6741000000000000',
      low: '21.0000000000000000',
      close: '22.1626000000000000',
      volume: '2366725.1400000000000000',
      quoteVolume: 51918299.17105383,
      btcVolume: 5418.649900876407,
      usdVolume: 51918299.17105383,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '22.1617000000000000',
      high: '22.8639000000000000',
      low: '21.7677000000000000',
      close: '22.1888000000000000',
      volume: '2987444.2400000000000000',
      quoteVolume: 66404440.860843465,
      btcVolume: 6861.125072216571,
      usdVolume: 66404440.860843465,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '22.1997000000000000',
      high: '22.3500000000000000',
      low: '21.5000000000000000',
      close: '21.9689000000000000',
      volume: '2139282.2200000000000000',
      quoteVolume: 46985151.28201848,
      btcVolume: 4870.228412924825,
      usdVolume: 46985151.28201848,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '21.9458000000000000',
      high: '22.9800000000000000',
      low: '21.9275000000000000',
      close: '22.8452000000000000',
      volume: '3052036.9600000000000000',
      quoteVolume: 69055523.55500332,
      btcVolume: 7015.549581370111,
      usdVolume: 69055523.55500332,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '22.8267000000000000',
      high: '23.2550000000000000',
      low: '21.4844000000000000',
      close: '22.0965000000000000',
      volume: '2294898.3500000000000000',
      quoteVolume: 51480520.2332999,
      btcVolume: 5282.9140573604745,
      usdVolume: 51480520.2332999,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '22.0965000000000000',
      high: '22.0967000000000000',
      low: '20.0711000000000000',
      close: '20.3193000000000000',
      volume: '2335580.1100000000000000',
      quoteVolume: 49059568.007921465,
      btcVolume: 5169.869833706369,
      usdVolume: 49059568.007921465,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '20.3316000000000000',
      high: '20.5191000000000000',
      low: '17.9000000000000000',
      close: '18.7441000000000000',
      volume: '3753737.4600000000000000',
      quoteVolume: 71546982.18924734,
      btcVolume: 7905.138057480794,
      usdVolume: 71546982.18924734,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '18.7338000000000000',
      high: '19.6499000000000000',
      low: '17.7051000000000000',
      close: '19.2000000000000000',
      volume: '3016651.2500000000000000',
      quoteVolume: 56860249.75728752,
      btcVolume: 6470.8292928242745,
      usdVolume: 56860249.75728752,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '19.1889000000000000',
      high: '19.7551000000000000',
      low: '17.9053000000000000',
      close: '18.9838000000000000',
      volume: '3099999.9300000000000000',
      quoteVolume: 58487714.17780671,
      btcVolume: 6714.188632991106,
      usdVolume: 58487714.17780671,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '18.9838000000000000',
      high: '20.4000000000000000',
      low: '18.8000000000000000',
      close: '19.2300000000000000',
      volume: '3567334.5900000000000000',
      quoteVolume: 69257747.27603036,
      btcVolume: 7987.256453347228,
      usdVolume: 69257747.27603036,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '19.2481000000000000',
      high: '19.8994000000000000',
      low: '18.4880000000000000',
      close: '18.9020000000000000',
      volume: '2382184.7800000000000000',
      quoteVolume: 45970226.909694515,
      btcVolume: 5363.65102030691,
      usdVolume: 45970226.909694515,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '18.9183000000000000',
      high: '20.0722000000000000',
      low: '18.7583000000000000',
      close: '19.9289000000000000',
      volume: '3267729.0000000000000000',
      quoteVolume: 64060497.85221986,
      btcVolume: 7310.647723090957,
      usdVolume: 64060497.85221986,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '19.9297000000000000',
      high: '20.3865000000000000',
      low: '19.3156000000000000',
      close: '19.6882000000000000',
      volume: '2545825.0000000000000000',
      quoteVolume: 50338561.07374935,
      btcVolume: 5718.875108291753,
      usdVolume: 50338561.07374935,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '19.6707000000000000',
      high: '20.2847000000000000',
      low: '19.5131000000000000',
      close: '19.9500000000000000',
      volume: '2408511.9400000000000000',
      quoteVolume: 47942820.95931419,
      btcVolume: 5467.07577696825,
      usdVolume: 47942820.95931419,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '19.9500000000000000',
      high: '21.4585000000000000',
      low: '19.9401000000000000',
      close: '20.7462000000000000',
      volume: '3088988.6500000000000000',
      quoteVolume: 64384864.18373439,
      btcVolume: 7154.825193230421,
      usdVolume: 64384864.18373439,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '20.7463000000000000',
      high: '21.7628000000000000',
      low: '20.6303000000000000',
      close: '21.3389000000000000',
      volume: '3228771.5100000000000000',
      quoteVolume: 68446184.90637933,
      btcVolume: 7534.967221418779,
      usdVolume: 68446184.90637933,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '21.3389000000000000',
      high: '21.4900000000000000',
      low: '19.7183000000000000',
      close: '20.2500000000000000',
      volume: '2378944.8500000000000000',
      quoteVolume: 49387277.98027311,
      btcVolume: 5463.183437439565,
      usdVolume: 49387277.98027311,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '20.2500000000000000',
      high: '20.2612000000000000',
      low: '16.7315000000000000',
      close: '16.8940000000000000',
      volume: '3465550.2500000000000000',
      quoteVolume: 64439955.25070982,
      btcVolume: 7591.149351999756,
      usdVolume: 64439955.25070982,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '16.8939000000000000',
      high: '17.4775000000000000',
      low: '15.6168000000000000',
      close: '16.5888000000000000',
      volume: '4046754.1400000000000000',
      quoteVolume: 67231659.84751555,
      btcVolume: 8549.570747626509,
      usdVolume: 67231659.84751555,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '16.5934000000000000',
      high: '17.4475000000000000',
      low: '16.3107000000000000',
      close: '16.8671000000000000',
      volume: '3449744.5700000000000000',
      quoteVolume: 58330699.05467022,
      btcVolume: 7320.410900960158,
      usdVolume: 58330699.05467022,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '16.8530000000000000',
      high: '17.1388000000000000',
      low: '15.4100000000000000',
      close: '16.5705000000000000',
      volume: '2581538.7700000000000000',
      quoteVolume: 42412598.42866471,
      btcVolume: 5417.646463351474,
      usdVolume: 42412598.42866471,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '16.5698000000000000',
      high: '16.6000000000000000',
      low: '8.8900000000000000',
      close: '9.2566000000000000',
      volume: '7775320.7400000000000000',
      quoteVolume: 96587818.94082893,
      btcVolume: 15229.295961519643,
      usdVolume: 96587818.94082893,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '9.2565000000000000',
      high: '11.5572000000000000',
      low: '6.3800000000000000',
      close: '10.9204000000000000',
      volume: '10695887.9800000000000000',
      quoteVolume: 101790362.66277254,
      btcVolume: 19963.23178862742,
      usdVolume: 101790362.66277254,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '10.9224000000000000',
      high: '11.2886000000000000',
      low: '9.8652000000000000',
      close: '10.1487000000000000',
      volume: '5343507.5700000000000000',
      quoteVolume: 56907072.065318905,
      btcVolume: 10586.292565466663,
      usdVolume: 56907072.065318905,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '10.1475000000000000',
      high: '11.2644000000000000',
      low: '9.9768000000000000',
      close: '10.3898000000000000',
      volume: '6424065.3600000000000000',
      quoteVolume: 67251583.01660968,
      btcVolume: 12552.17335408042,
      usdVolume: 67251583.01660968,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '10.3808000000000000',
      high: '10.3952000000000000',
      low: '8.4422000000000000',
      close: '9.4285000000000000',
      volume: '5814301.1700000000000000',
      quoteVolume: 54897967.26809575,
      btcVolume: 11073.417029929627,
      usdVolume: 54897967.26809575,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '9.4290000000000000',
      high: '10.4900000000000000',
      low: '9.3265000000000000',
      close: '10.2576000000000000',
      volume: '5102840.4500000000000000',
      quoteVolume: 51631527.65429831,
      btcVolume: 9759.11417103753,
      usdVolume: 51631527.65429831,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '10.2498000000000000',
      high: '10.4301000000000000',
      low: '9.6539000000000000',
      close: '10.3866000000000000',
      volume: '4450666.1500000000000000',
      quoteVolume: 45276532.730975404,
      btcVolume: 8574.510856799883,
      usdVolume: 45276532.730975404,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '10.3917000000000000',
      high: '12.6891000000000000',
      low: '10.2260000000000000',
      close: '12.2597000000000000',
      volume: '5848299.4600000000000000',
      quoteVolume: 66264533.82355488,
      btcVolume: 11416.165972116107,
      usdVolume: 66264533.82355488,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '12.2597000000000000',
      high: '13.6500000000000000',
      low: '10.4014000000000000',
      close: '11.9147000000000000',
      volume: '6965457.9200000000000000',
      quoteVolume: 86260327.52522887,
      btcVolume: 13530.08677890309,
      usdVolume: 86260327.52522887,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '11.8967000000000000',
      high: '12.4199000000000000',
      low: '11.2500000000000000',
      close: '12.1000000000000000',
      volume: '5810720.8800000000000000',
      quoteVolume: 69365406.12530024,
      btcVolume: 11212.692133104454,
      usdVolume: 69365406.12530024,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '12.1050000000000000',
      high: '12.4754000000000000',
      low: '11.0145000000000000',
      close: '11.1670000000000000',
      volume: '4562736.8600000000000000',
      quoteVolume: 54219558.920412235,
      btcVolume: 8829.651908483296,
      usdVolume: 54219558.920412235,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '11.1494000000000000',
      high: '12.4900000000000000',
      low: '10.8427000000000000',
      close: '12.1887000000000000',
      volume: '5299899.4500000000000000',
      quoteVolume: 61800844.96620327,
      btcVolume: 10040.54539065147,
      usdVolume: 61800844.96620327,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '12.1887000000000000',
      high: '12.5834000000000000',
      low: '11.9100000000000000',
      close: '12.4260000000000000',
      volume: '4627182.2900000000000000',
      quoteVolume: 56790493.548444174,
      btcVolume: 8555.766772115445,
      usdVolume: 56790493.548444174,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '12.4261000000000000',
      high: '12.7108000000000000',
      low: '12.0561000000000000',
      close: '12.3251000000000000',
      volume: '3607793.8900000000000000',
      quoteVolume: 44491441.70805428,
      btcVolume: 6675.4778502505305,
      usdVolume: 44491441.70805428,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '12.3253000000000000',
      high: '13.2565000000000000',
      low: '12.1900000000000000',
      close: '13.1990000000000000',
      volume: '3433997.0900000000000000',
      quoteVolume: 42838269.466780595,
      btcVolume: 6422.748334392865,
      usdVolume: 42838269.466780595,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '13.1989000000000000',
      high: '13.3655000000000000',
      low: '12.1600000000000000',
      close: '12.3354000000000000',
      volume: '2778050.9100000000000000',
      quoteVolume: 36084938.85881204,
      btcVolume: 5400.701483352602,
      usdVolume: 36084938.85881204,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '12.3375000000000000',
      high: '12.3588000000000000',
      low: '11.6414000000000000',
      close: '12.1888000000000000',
      volume: '2478685.1100000000000000',
      quoteVolume: 30031382.50817986,
      btcVolume: 4830.592276464181,
      usdVolume: 30031382.50817986,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '12.1888000000000000',
      high: '12.2425000000000000',
      low: '11.2750000000000000',
      close: '11.3008000000000000',
      volume: '2305768.5200000000000000',
      quoteVolume: 27252838.148580186,
      btcVolume: 4463.8054330588775,
      usdVolume: 27252838.148580186,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '11.3007000000000000',
      high: '12.4277000000000000',
      low: '11.2552000000000000',
      close: '12.1365000000000000',
      volume: '4183148.7200000000000000',
      quoteVolume: 50273122.37520111,
      btcVolume: 7996.7455340660035,
      usdVolume: 50273122.37520111,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '12.1368000000000000',
      high: '12.7322000000000000',
      low: '12.1180000000000000',
      close: '12.5451000000000000',
      volume: '5119093.1500000000000000',
      quoteVolume: 64105779.65224831,
      btcVolume: 9947.257238601136,
      usdVolume: 64105779.65224831,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '12.5497000000000000',
      high: '12.7386000000000000',
      low: '11.9600000000000000',
      close: '12.7200000000000000',
      volume: '4355655.4600000000000000',
      quoteVolume: 53721353.74091241,
      btcVolume: 8489.017350493368,
      usdVolume: 53721353.74091241,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '12.7190000000000000',
      high: '13.7362000000000000',
      low: '12.5349000000000000',
      close: '13.0166000000000000',
      volume: '5332604.5300000000000000',
      quoteVolume: 69276074.10863224,
      btcVolume: 10253.55586604115,
      usdVolume: 69276074.10863224,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '13.0185000000000000',
      high: '13.5700000000000000',
      low: '12.9106000000000000',
      close: '13.3615000000000000',
      volume: '5030322.0300000000000000',
      quoteVolume: 66661802.96690086,
      btcVolume: 9801.523808937845,
      usdVolume: 66661802.96690086,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '13.3682000000000000',
      high: '14.0561000000000000',
      low: '13.1724000000000000',
      close: '13.7606000000000000',
      volume: '4840528.5300000000000000',
      quoteVolume: 66003739.59200456,
      btcVolume: 9745.152195828143,
      usdVolume: 66003739.59200456,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '13.7608000000000000',
      high: '13.9638000000000000',
      low: '13.4002000000000000',
      close: '13.6888000000000000',
      volume: '4275639.2500000000000000',
      quoteVolume: 58555104.63058687,
      btcVolume: 8621.568347482043,
      usdVolume: 58555104.63058687,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '13.6888000000000000',
      high: '15.2149000000000000',
      low: '13.6371000000000000',
      close: '15.1989000000000000',
      volume: '5583296.7600000000000000',
      quoteVolume: 80214973.59908952,
      btcVolume: 11301.364287830236,
      usdVolume: 80214973.59908952,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '15.1930000000000000',
      high: '15.4900000000000000',
      low: '14.2503000000000000',
      close: '14.7266000000000000',
      volume: '4715229.2800000000000000',
      quoteVolume: 70591252.82052863,
      btcVolume: 9647.541530712178,
      usdVolume: 70591252.82052863,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '14.7267000000000000',
      high: '15.3486000000000000',
      low: '14.5700000000000000',
      close: '15.0888000000000000',
      volume: '4614905.6300000000000000',
      quoteVolume: 69355343.21978821,
      btcVolume: 9493.717090075794,
      usdVolume: 69355343.21978821,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '15.0899000000000000',
      high: '15.2011000000000000',
      low: '14.6279000000000000',
      close: '15.0096000000000000',
      volume: '4638418.3900000000000000',
      quoteVolume: 69662383.47849114,
      btcVolume: 9554.251740198943,
      usdVolume: 69662383.47849114,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '15.0096000000000000',
      high: '15.0797000000000000',
      low: '13.4700000000000000',
      close: '13.7200000000000000',
      volume: '3999879.1400000000000000',
      quoteVolume: 56437561.49783074,
      btcVolume: 8088.757467476043,
      usdVolume: 56437561.49783074,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '13.7171000000000000',
      high: '14.0808000000000000',
      low: '13.5234000000000000',
      close: '13.8070000000000000',
      volume: '3692817.9200000000000000',
      quoteVolume: 51151554.74794237,
      btcVolume: 7454.864937758831,
      usdVolume: 51151554.74794237,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '13.8089000000000000',
      high: '14.8399000000000000',
      low: '13.6439000000000000',
      close: '14.2808000000000000',
      volume: '4070517.8200000000000000',
      quoteVolume: 57950623.216416314,
      btcVolume: 8337.283577200431,
      usdVolume: 57950623.216416314,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '14.2808000000000000',
      high: '15.0799000000000000',
      low: '13.6289000000000000',
      close: '15.0277000000000000',
      volume: '4313190.9300000000000000',
      quoteVolume: 62029135.8484717,
      btcVolume: 9209.291923149041,
      usdVolume: 62029135.8484717,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '15.0257000000000000',
      high: '15.9555000000000000',
      low: '14.7835000000000000',
      close: '15.6148000000000000',
      volume: '4523071.2000000000000000',
      quoteVolume: 70092382.14295062,
      btcVolume: 10207.861589084292,
      usdVolume: 70092382.14295062,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '15.6148000000000000',
      high: '15.8888000000000000',
      low: '14.4913000000000000',
      close: '14.5755000000000000',
      volume: '3795966.9000000000000000',
      quoteVolume: 58221065.7500413,
      btcVolume: 8555.74483779464,
      usdVolume: 58221065.7500413,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '14.5755000000000000',
      high: '15.7669000000000000',
      low: '14.1160000000000000',
      close: '15.7394000000000000',
      volume: '4068049.4800000000000000',
      quoteVolume: 61814683.27456321,
      btcVolume: 8977.839996691559,
      usdVolume: 61814683.27456321,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '15.7394000000000000',
      high: '15.9200000000000000',
      low: '15.2501000000000000',
      close: '15.6400000000000000',
      volume: '3150559.1700000000000000',
      quoteVolume: 49188415.57936379,
      btcVolume: 6962.024816102106,
      usdVolume: 49188415.57936379,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '15.6447000000000000',
      high: '16.8183000000000000',
      low: '15.6242000000000000',
      close: '16.6137000000000000',
      volume: '3968019.0700000000000000',
      quoteVolume: 64502814.66373515,
      btcVolume: 8996.773890457556,
      usdVolume: 64502814.66373515,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '16.6198000000000000',
      high: '16.6933000000000000',
      low: '15.8509000000000000',
      close: '16.0683000000000000',
      volume: '2962072.6000000000000000',
      quoteVolume: 48174317.52943487,
      btcVolume: 6722.896522589055,
      usdVolume: 48174317.52943487,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '16.0677000000000000',
      high: '16.4499000000000000',
      low: '14.8219000000000000',
      close: '14.9892000000000000',
      volume: '3219905.9000000000000000',
      quoteVolume: 50757347.771237776,
      btcVolume: 7228.98020818563,
      usdVolume: 50757347.771237776,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '14.9840000000000000',
      high: '15.4416000000000000',
      low: '14.6945000000000000',
      close: '15.1283000000000000',
      volume: '3134797.6600000000000000',
      quoteVolume: 47551099.75450032,
      btcVolume: 6932.414273166686,
      usdVolume: 47551099.75450032,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '15.1381000000000000',
      high: '15.8030000000000000',
      low: '14.9112000000000000',
      close: '15.7104000000000000',
      volume: '3293822.4400000000000000',
      quoteVolume: 50953432.53308494,
      btcVolume: 7270.805302216778,
      usdVolume: 50953432.53308494,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '15.7103000000000000',
      high: '16.4666000000000000',
      low: '15.4102000000000000',
      close: '16.0340000000000000',
      volume: '3451154.7600000000000000',
      quoteVolume: 54969135.67182449,
      btcVolume: 7506.714246229673,
      usdVolume: 54969135.67182449,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '16.0502000000000000',
      high: '16.2568000000000000',
      low: '15.6800000000000000',
      close: '15.9319000000000000',
      volume: '3008320.2600000000000000',
      quoteVolume: 48259042.136493415,
      btcVolume: 6423.189422170581,
      usdVolume: 48259042.136493415,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '15.9319000000000000',
      high: '16.4305000000000000',
      low: '15.7761000000000000',
      close: '16.2283000000000000',
      volume: '2946839.7900000000000000',
      quoteVolume: 47639855.83746818,
      btcVolume: 6314.8054320104275,
      usdVolume: 47639855.83746818,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '16.2235000000000000',
      high: '16.4435000000000000',
      low: '16.0400000000000000',
      close: '16.3588000000000000',
      volume: '3314818.9100000000000000',
      quoteVolume: 53903371.49447087,
      btcVolume: 7094.218778997715,
      usdVolume: 53903371.49447087,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '16.3645000000000000',
      high: '16.5600000000000000',
      low: '16.0630000000000000',
      close: '16.4229000000000000',
      volume: '2937287.3600000000000000',
      quoteVolume: 47859272.721880525,
      btcVolume: 6212.335727211785,
      usdVolume: 47859272.721880525,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '16.4199000000000000',
      high: '16.4900000000000000',
      low: '16.1125000000000000',
      close: '16.3400000000000000',
      volume: '2838511.4300000000000000',
      quoteVolume: 46309248.7211524,
      btcVolume: 5999.145090290307,
      usdVolume: 46309248.7211524,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '16.3354000000000000',
      high: '17.5171000000000000',
      low: '16.3000000000000000',
      close: '17.3667000000000000',
      volume: '3817656.7000000000000000',
      quoteVolume: 64434759.7688937,
      btcVolume: 7799.88414199083,
      usdVolume: 64434759.7688937,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '17.3754000000000000',
      high: '18.1377000000000000',
      low: '16.5000000000000000',
      close: '16.9542000000000000',
      volume: '4127322.5100000000000000',
      quoteVolume: 71629569.6960837,
      btcVolume: 8039.289696160252,
      usdVolume: 71629569.6960837,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '16.9510000000000000',
      high: '17.8551000000000000',
      low: '16.9381000000000000',
      close: '17.4660000000000000',
      volume: '3805293.6700000000000000',
      quoteVolume: 66271797.65506503,
      btcVolume: 7557.207038844103,
      usdVolume: 66271797.65506503,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '17.4665000000000000',
      high: '17.5711000000000000',
      low: '17.2599000000000000',
      close: '17.5333000000000000',
      volume: '3009939.6700000000000000',
      quoteVolume: 52504866.732339546,
      btcVolume: 5915.815664352295,
      usdVolume: 52504866.732339546,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '17.5337000000000000',
      high: '17.8100000000000000',
      low: '16.8711000000000000',
      close: '17.1071000000000000',
      volume: '2616299.5300000000000000',
      quoteVolume: 45271776.505038545,
      btcVolume: 5050.819962883537,
      usdVolume: 45271776.505038545,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '17.1027000000000000',
      high: '17.3267000000000000',
      low: '16.2556000000000000',
      close: '17.0074000000000000',
      volume: '2755883.6300000000000000',
      quoteVolume: 46419270.57261121,
      btcVolume: 5284.661382519424,
      usdVolume: 46419270.57261121,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '17.0120000000000000',
      high: '17.2993000000000000',
      low: '16.6060000000000000',
      close: '16.8886000000000000',
      volume: '2613361.6500000000000000',
      quoteVolume: 44261093.04238469,
      btcVolume: 4957.354183210489,
      usdVolume: 44261093.04238469,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '16.8814000000000000',
      high: '17.1450000000000000',
      low: '16.3018000000000000',
      close: '16.3743000000000000',
      volume: '2716280.3700000000000000',
      quoteVolume: 45806861.8974411,
      btcVolume: 5004.187193369502,
      usdVolume: 45806861.8974411,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '16.3672000000000000',
      high: '17.2400000000000000',
      low: '16.1218000000000000',
      close: '17.0003000000000000',
      volume: '3030451.5100000000000000',
      quoteVolume: 50623360.84297903,
      btcVolume: 5321.812894633818,
      usdVolume: 50623360.84297903,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '17.0003000000000000',
      high: '17.3977000000000000',
      low: '16.8230000000000000',
      close: '17.1371000000000000',
      volume: '2621559.8700000000000000',
      quoteVolume: 44879553.905084215,
      btcVolume: 4525.608471864123,
      usdVolume: 44879553.905084215,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '17.1371000000000000',
      high: '17.4926000000000000',
      low: '16.7330000000000000',
      close: '16.7751000000000000',
      volume: '2243643.8300000000000000',
      quoteVolume: 38582692.96829227,
      btcVolume: 3962.9396596703737,
      usdVolume: 38582692.96829227,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '16.7753000000000000',
      high: '16.7900000000000000',
      low: '13.9129000000000000',
      close: '15.3506000000000000',
      volume: '2900497.8000000000000000',
      quoteVolume: 43979315.8858527,
      btcVolume: 5066.467433139654,
      usdVolume: 43979315.8858527,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '15.3506000000000000',
      high: '15.5468000000000000',
      low: '14.0100000000000000',
      close: '15.0811000000000000',
      volume: '2726253.2700000000000000',
      quoteVolume: 40893423.03739202,
      btcVolume: 4712.194211346884,
      usdVolume: 40893423.03739202,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '15.0902000000000000',
      high: '16.2400000000000000',
      low: '14.9586000000000000',
      close: '15.7892000000000000',
      volume: '3026813.0900000000000000',
      quoteVolume: 47332783.09776167,
      btcVolume: 5395.76311152628,
      usdVolume: 47332783.09776167,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '15.7941000000000000',
      high: '16.3500000000000000',
      low: '15.6785000000000000',
      close: '16.2164000000000000',
      volume: '2812457.1200000000000000',
      quoteVolume: 45141960.7019688,
      btcVolume: 4989.884585136048,
      usdVolume: 45141960.7019688,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '16.2187000000000000',
      high: '16.6982000000000000',
      low: '16.0123000000000000',
      close: '16.3777000000000000',
      volume: '2801822.6000000000000000',
      quoteVolume: 45666418.70995536,
      btcVolume: 4771.624602245077,
      usdVolume: 45666418.70995536,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '16.3722000000000000',
      high: '16.4719000000000000',
      low: '15.4606000000000000',
      close: '15.7222000000000000',
      volume: '2314656.9000000000000000',
      quoteVolume: 37214374.23302308,
      btcVolume: 3912.922381413597,
      usdVolume: 37214374.23302308,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '15.7121000000000000',
      high: '16.2367000000000000',
      low: '15.5800000000000000',
      close: '16.0580000000000000',
      volume: '2730367.3500000000000000',
      quoteVolume: 43746498.66421267,
      btcVolume: 4654.9166519593045,
      usdVolume: 43746498.66421267,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '16.0580000000000000',
      high: '16.5100000000000000',
      low: '16.0014000000000000',
      close: '16.2959000000000000',
      volume: '2893701.8400000000000000',
      quoteVolume: 47142111.5064022,
      btcVolume: 4887.223961466,
      usdVolume: 47142111.5064022,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '16.2999000000000000',
      high: '16.8620000000000000',
      low: '16.2511000000000000',
      close: '16.6299000000000000',
      volume: '3116090.7500000000000000',
      quoteVolume: 51603793.31526883,
      btcVolume: 5303.9246355544265,
      usdVolume: 51603793.31526883,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '16.6298000000000000',
      high: '17.2488000000000000',
      low: '16.3000000000000000',
      close: '17.2098000000000000',
      volume: '3245275.7500000000000000',
      quoteVolume: 54590767.63110695,
      btcVolume: 5634.9082075498,
      usdVolume: 54590767.63110695,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '17.2096000000000000',
      high: '17.3396000000000000',
      low: '16.4851000000000000',
      close: '16.8998000000000000',
      volume: '2395316.0900000000000000',
      quoteVolume: 40821394.31884481,
      btcVolume: 4224.383755576833,
      usdVolume: 40821394.31884481,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '16.8994000000000000',
      high: '17.0675000000000000',
      low: '15.7218000000000000',
      close: '16.0888000000000000',
      volume: '2163570.9800000000000000',
      quoteVolume: 35589912.05392658,
      btcVolume: 3843.773844858664,
      usdVolume: 35589912.05392658,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '16.0888000000000000',
      high: '16.9240000000000000',
      low: '15.9145000000000000',
      close: '16.5864000000000000',
      volume: '2520655.4700000000000000',
      quoteVolume: 41584466.617784135,
      btcVolume: 4547.953831523734,
      usdVolume: 41584466.617784135,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '16.5884000000000000',
      high: '16.8500000000000000',
      low: '16.3202000000000000',
      close: '16.4800000000000000',
      volume: '2302712.1700000000000000',
      quoteVolume: 38173594.65329609,
      btcVolume: 4145.125970293084,
      usdVolume: 38173594.65329609,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '16.4789000000000000',
      high: '16.7900000000000000',
      low: '15.9074000000000000',
      close: '15.9624000000000000',
      volume: '2280604.7400000000000000',
      quoteVolume: 37593388.9279645,
      btcVolume: 4127.7763329936115,
      usdVolume: 37593388.9279645,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '15.9457000000000000',
      high: '16.4161000000000000',
      low: '15.8057000000000000',
      close: '16.3807000000000000',
      volume: '2348512.9100000000000000',
      quoteVolume: 38152773.256409764,
      btcVolume: 4321.228700886494,
      usdVolume: 38152773.256409764,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '16.3807000000000000',
      high: '16.4979000000000000',
      low: '15.9537000000000000',
      close: '16.2159000000000000',
      volume: '2007343.8200000000000000',
      quoteVolume: 32624803.88715872,
      btcVolume: 3682.6289500059283,
      usdVolume: 32624803.88715872,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '16.2097000000000000',
      high: '16.5100000000000000',
      low: '16.1554000000000000',
      close: '16.4700000000000000',
      volume: '2613812.0500000000000000',
      quoteVolume: 42792287.77669632,
      btcVolume: 4725.005187970007,
      usdVolume: 42792287.77669632,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '16.4700000000000000',
      high: '17.1405000000000000',
      low: '16.3890000000000000',
      close: '17.0772000000000000',
      volume: '2866468.3900000000000000',
      quoteVolume: 47917801.298296325,
      btcVolume: 5119.878787996562,
      usdVolume: 47917801.298296325,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '17.0769000000000000',
      high: '17.1980000000000000',
      low: '16.6333000000000000',
      close: '17.0031000000000000',
      volume: '2598906.5200000000000000',
      quoteVolume: 44169314.055208385,
      btcVolume: 4668.689724077699,
      usdVolume: 44169314.055208385,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '17.0030000000000000',
      high: '17.7857000000000000',
      low: '16.9291000000000000',
      close: '17.7277000000000000',
      volume: '2969818.2300000000000000',
      quoteVolume: 51559065.97362402,
      btcVolume: 5420.853255843491,
      usdVolume: 51559065.97362402,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '17.7277000000000000',
      high: '18.1954000000000000',
      low: '16.8419000000000000',
      close: '17.0916000000000000',
      volume: '2612942.1800000000000000',
      quoteVolume: 45947854.11468179,
      btcVolume: 4807.9438395434145,
      usdVolume: 45947854.11468179,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '17.0926000000000000',
      high: '18.1224000000000000',
      low: '16.9836000000000000',
      close: '17.9477000000000000',
      volume: '2989853.0600000000000000',
      quoteVolume: 52189313.03744129,
      btcVolume: 5419.06119775764,
      usdVolume: 52189313.03744129,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '17.9469000000000000',
      high: '18.2000000000000000',
      low: '16.6800000000000000',
      close: '17.2669000000000000',
      volume: '2536257.1500000000000000',
      quoteVolume: 44844446.996629834,
      btcVolume: 4522.870886309356,
      usdVolume: 44844446.996629834,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '17.2761000000000000',
      high: '17.6275000000000000',
      low: '16.9645000000000000',
      close: '17.5779000000000000',
      volume: '2650311.1400000000000000',
      quoteVolume: 45972871.326653786,
      btcVolume: 4811.90967908672,
      usdVolume: 45972871.326653786,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '17.5780000000000000',
      high: '17.6527000000000000',
      low: '17.0424000000000000',
      close: '17.5170000000000000',
      volume: '2320525.8900000000000000',
      quoteVolume: 40612423.08444422,
      btcVolume: 4186.441359549021,
      usdVolume: 40612423.08444422,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '17.5180000000000000',
      high: '17.8999000000000000',
      low: '17.4396000000000000',
      close: '17.6914000000000000',
      volume: '2454801.7700000000000000',
      quoteVolume: 43342829.55218497,
      btcVolume: 4448.532900411398,
      usdVolume: 43342829.55218497,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '17.6915000000000000',
      high: '17.7951000000000000',
      low: '17.4900000000000000',
      close: '17.5511000000000000',
      volume: '1963897.2200000000000000',
      quoteVolume: 34612966.357234664,
      btcVolume: 3593.211679800369,
      usdVolume: 34612966.357234664,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '17.5510000000000000',
      high: '17.6371000000000000',
      low: '16.8526000000000000',
      close: '17.4135000000000000',
      volume: '1816342.7400000000000000',
      quoteVolume: 31593373.320084013,
      btcVolume: 3279.991408861491,
      usdVolume: 31593373.320084013,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '17.4134000000000000',
      high: '17.5454000000000000',
      low: '17.2594000000000000',
      close: '17.4489000000000000',
      volume: '1657535.1100000000000000',
      quoteVolume: 28825851.386570796,
      btcVolume: 2964.926669627316,
      usdVolume: 28825851.386570796,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '17.4489000000000000',
      high: '17.5098000000000000',
      low: '17.0374000000000000',
      close: '17.3733000000000000',
      volume: '1630641.2900000000000000',
      quoteVolume: 28236106.363147046,
      btcVolume: 2904.68453638977,
      usdVolume: 28236106.363147046,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '17.3734000000000000',
      high: '17.5833000000000000',
      low: '17.1300000000000000',
      close: '17.4060000000000000',
      volume: '1807563.8600000000000000',
      quoteVolume: 31389856.381225992,
      btcVolume: 3201.181062165144,
      usdVolume: 31389856.381225992,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '17.4084000000000000',
      high: '17.5191000000000000',
      low: '15.9300000000000000',
      close: '16.2298000000000000',
      volume: '1799102.4900000000000000',
      quoteVolume: 30529455.476210415,
      btcVolume: 3172.6583143612193,
      usdVolume: 30529455.476210415,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '16.2376000000000000',
      high: '16.7999000000000000',
      low: '16.0869000000000000',
      close: '16.6377000000000000',
      volume: '1930125.9500000000000000',
      quoteVolume: 31938857.307844184,
      btcVolume: 3394.540608530982,
      usdVolume: 31938857.307844184,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '16.6420000000000000',
      high: '16.8179000000000000',
      low: '16.4900000000000000',
      close: '16.8101000000000000',
      volume: '2023311.8300000000000000',
      quoteVolume: 33704354.30536094,
      btcVolume: 3573.99175725781,
      usdVolume: 33704354.30536094,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '16.8111000000000000',
      high: '16.8398000000000000',
      low: '16.4023000000000000',
      close: '16.5729000000000000',
      volume: '1697344.5500000000000000',
      quoteVolume: 28336046.766627934,
      btcVolume: 3010.4223953476185,
      usdVolume: 28336046.766627934,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '16.5728000000000000',
      high: '16.5982000000000000',
      low: '15.4000000000000000',
      close: '16.3770000000000000',
      volume: '1521685.2100000000000000',
      quoteVolume: 24473919.621151537,
      btcVolume: 2658.7457305257617,
      usdVolume: 24473919.621151537,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '16.3758000000000000',
      high: '16.6481000000000000',
      low: '16.1562000000000000',
      close: '16.4329000000000000',
      volume: '1614905.6400000000000000',
      quoteVolume: 26479345.22874293,
      btcVolume: 2788.7732205533584,
      usdVolume: 26479345.22874293,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '16.4331000000000000',
      high: '16.6320000000000000',
      low: '15.9709000000000000',
      close: '16.3983000000000000',
      volume: '1502697.3100000000000000',
      quoteVolume: 24591062.00260309,
      btcVolume: 2604.547568064439,
      usdVolume: 24591062.00260309,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '16.3983000000000000',
      high: '16.5423000000000000',
      low: '15.8783000000000000',
      close: '16.1608000000000000',
      volume: '1382820.8700000000000000',
      quoteVolume: 22540156.403535735,
      btcVolume: 2394.7606782552566,
      usdVolume: 22540156.403535735,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '16.1665000000000000',
      high: '16.3106000000000000',
      low: '15.8966000000000000',
      close: '15.9512000000000000',
      volume: '1324135.6000000000000000',
      quoteVolume: 21271385.818114948,
      btcVolume: 2279.89302677804,
      usdVolume: 21271385.818114948,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '15.9611000000000000',
      high: '16.0898000000000000',
      low: '15.7000000000000000',
      close: '16.0590000000000000',
      volume: '1427210.2200000000000000',
      quoteVolume: 22776460.30155413,
      btcVolume: 2445.1087498630836,
      usdVolume: 22776460.30155413,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '16.0590000000000000',
      high: '16.3358000000000000',
      low: '15.9210000000000000',
      close: '15.9775000000000000',
      volume: '1317075.8400000000000000',
      quoteVolume: 21166507.63965404,
      btcVolume: 2262.4663175872406,
      usdVolume: 21166507.63965404,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '15.9792000000000000',
      high: '16.5500000000000000',
      low: '15.9499000000000000',
      close: '16.5051000000000000',
      volume: '1703957.1800000000000000',
      quoteVolume: 27730249.14524187,
      btcVolume: 2922.9736930384024,
      usdVolume: 27730249.14524187,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '16.5051000000000000',
      high: '16.5472000000000000',
      low: '16.2602000000000000',
      close: '16.3898000000000000',
      volume: '1450721.0800000000000000',
      quoteVolume: 23797667.00323723,
      btcVolume: 2468.0003601359663,
      usdVolume: 23797667.00323723,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '16.3898000000000000',
      high: '16.6481000000000000',
      low: '15.9500000000000000',
      close: '15.9968000000000000',
      volume: '1529800.3000000000000000',
      quoteVolume: 24889909.655295953,
      btcVolume: 2628.3948271845443,
      usdVolume: 24889909.655295953,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '16.0084000000000000',
      high: '16.0816000000000000',
      low: '15.5000000000000000',
      close: '15.9060000000000000',
      volume: '1201354.7500000000000000',
      quoteVolume: 19105061.019308876,
      btcVolume: 2069.4495041309574,
      usdVolume: 19105061.019308876,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '15.9056000000000000',
      high: '15.9996000000000000',
      low: '15.5715000000000000',
      close: '15.7694000000000000',
      volume: '1041399.0000000000000000',
      quoteVolume: 16428293.389013952,
      btcVolume: 1787.4554301521573,
      usdVolume: 16428293.389013952,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '15.7696000000000000',
      high: '15.8714000000000000',
      low: '14.8000000000000000',
      close: '15.1242000000000000',
      volume: '1309335.6600000000000000',
      quoteVolume: 20331918.057953976,
      btcVolume: 2234.822337873103,
      usdVolume: 20331918.057953976,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '15.1230000000000000',
      high: '15.4944000000000000',
      low: '14.9249000000000000',
      close: '15.3782000000000000',
      volume: '1402858.9600000000000000',
      quoteVolume: 21418398.753466826,
      btcVolume: 2360.2436605355056,
      usdVolume: 21418398.753466826,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '15.3714000000000000',
      high: '15.5901000000000000',
      low: '15.2040000000000000',
      close: '15.4656000000000000',
      volume: '1511856.3800000000000000',
      quoteVolume: 23294081.307785984,
      btcVolume: 2551.092853713331,
      usdVolume: 23294081.307785984,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '15.4674000000000000',
      high: '15.5476000000000000',
      low: '15.2519000000000000',
      close: '15.3838000000000000',
      volume: '1315772.4200000000000000',
      quoteVolume: 20305967.15507204,
      btcVolume: 2218.39382184501,
      usdVolume: 20305967.15507204,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '15.3959000000000000',
      high: '15.8888000000000000',
      low: '15.3363000000000000',
      close: '15.8144000000000000',
      volume: '1609244.5800000000000000',
      quoteVolume: 25167285.05407897,
      btcVolume: 2737.6419244650206,
      usdVolume: 25167285.05407897,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '15.8123000000000000',
      high: '15.9796000000000000',
      low: '15.0743000000000000',
      close: '15.3634000000000000',
      volume: '1399345.3900000000000000',
      quoteVolume: 21887439.935343955,
      btcVolume: 2389.049039204999,
      usdVolume: 21887439.935343955,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '15.3640000000000000',
      high: '15.4880000000000000',
      low: '15.2484000000000000',
      close: '15.2863000000000000',
      volume: '1204044.4900000000000000',
      quoteVolume: 18526262.42016398,
      btcVolume: 2036.9658883771122,
      usdVolume: 18526262.42016398,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '15.2856000000000000',
      high: '15.6399000000000000',
      low: '15.2239000000000000',
      close: '15.5548000000000000',
      volume: '1226300.0400000000000000',
      quoteVolume: 18922658.450647,
      btcVolume: 2079.9805745186986,
      usdVolume: 18922658.450647,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '15.5579000000000000',
      high: '15.6135000000000000',
      low: '15.1000000000000000',
      close: '15.4885000000000000',
      volume: '1064265.3200000000000000',
      quoteVolume: 16414898.226830075,
      btcVolume: 1810.9834840684625,
      usdVolume: 16414898.226830075,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '15.4865000000000000',
      high: '16.3496000000000000',
      low: '15.4401000000000000',
      close: '16.3110000000000000',
      volume: '1606449.3100000000000000',
      quoteVolume: 25364592.417103197,
      btcVolume: 2750.178905626979,
      usdVolume: 25364592.417103197,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '16.3120000000000000',
      high: '16.8757000000000000',
      low: '16.0301000000000000',
      close: '16.8048000000000000',
      volume: '1880630.3400000000000000',
      quoteVolume: 31044612.46472845,
      btcVolume: 3348.0111025538104,
      usdVolume: 31044612.46472845,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '16.7995000000000000',
      high: '17.4550000000000000',
      low: '16.5683000000000000',
      close: '17.2819000000000000',
      volume: '2246484.1800000000000000',
      quoteVolume: 38372913.62190868,
      btcVolume: 4118.227717083268,
      usdVolume: 38372913.62190868,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '17.2819000000000000',
      high: '17.5000000000000000',
      low: '16.4088000000000000',
      close: '16.8274000000000000',
      volume: '1869273.6700000000000000',
      quoteVolume: 31602020.87875102,
      btcVolume: 3389.70673054376,
      usdVolume: 31602020.87875102,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '16.8278000000000000',
      high: '17.3310000000000000',
      low: '16.4438000000000000',
      close: '17.3123000000000000',
      volume: '2099193.5700000000000000',
      quoteVolume: 35493337.16801673,
      btcVolume: 3853.180488607405,
      usdVolume: 35493337.16801673,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '17.3133000000000000',
      high: '17.6888000000000000',
      low: '17.0180000000000000',
      close: '17.6205000000000000',
      volume: '2276165.3000000000000000',
      quoteVolume: 39710164.3496227,
      btcVolume: 4298.963618020441,
      usdVolume: 39710164.3496227,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '17.6205000000000000',
      high: '18.5900000000000000',
      low: '17.5928000000000000',
      close: '18.2994000000000000',
      volume: '2995977.4100000000000000',
      quoteVolume: 54487455.92163716,
      btcVolume: 5888.09404421164,
      usdVolume: 54487455.92163716,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '18.3036000000000000',
      high: '18.9999000000000000',
      low: '17.7282000000000000',
      close: '18.4046000000000000',
      volume: '3228966.6100000000000000',
      quoteVolume: 59615751.20299803,
      btcVolume: 6420.203822258735,
      usdVolume: 59615751.20299803,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '18.4007000000000000',
      high: '18.7700000000000000',
      low: '17.8266000000000000',
      close: '18.0790000000000000',
      volume: '2385481.9700000000000000',
      quoteVolume: 43474784.56725936,
      btcVolume: 4714.97312281895,
      usdVolume: 43474784.56725936,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '18.0790000000000000',
      high: '18.1538000000000000',
      low: '17.4194000000000000',
      close: '17.6351000000000000',
      volume: '1959582.7300000000000000',
      quoteVolume: 34842425.382078946,
      btcVolume: 3776.4969692526847,
      usdVolume: 34842425.382078946,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '17.6351000000000000',
      high: '17.7618000000000000',
      low: '16.5473000000000000',
      close: '17.1647000000000000',
      volume: '2053439.4400000000000000',
      quoteVolume: 35131243.13980965,
      btcVolume: 3840.4754390289468,
      usdVolume: 35131243.13980965,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '17.1647000000000000',
      high: '17.4825000000000000',
      low: '16.9354000000000000',
      close: '17.0676000000000000',
      volume: '1852340.6900000000000000',
      quoteVolume: 32019335.19000079,
      btcVolume: 3504.743471468927,
      usdVolume: 32019335.19000079,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '17.0675000000000000',
      high: '17.3247000000000000',
      low: '16.8122000000000000',
      close: '17.1507000000000000',
      volume: '1687884.7600000000000000',
      quoteVolume: 28863376.478104953,
      btcVolume: 3151.7268006262602,
      usdVolume: 28863376.478104953,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '17.1542000000000000',
      high: '18.0980000000000000',
      low: '16.8900000000000000',
      close: '18.0154000000000000',
      volume: '2040900.4400000000000000',
      quoteVolume: 35931733.34284701,
      btcVolume: 3925.100741605019,
      usdVolume: 35931733.34284701,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '18.0166000000000000',
      high: '18.3400000000000000',
      low: '17.2350000000000000',
      close: '17.4645000000000000',
      volume: '2487505.4900000000000000',
      quoteVolume: 44544637.59956494,
      btcVolume: 4853.4342390229085,
      usdVolume: 44544637.59956494,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '17.4645000000000000',
      high: '17.9799000000000000',
      low: '17.4000000000000000',
      close: '17.7285000000000000',
      volume: '2110733.7600000000000000',
      quoteVolume: 37350560.43213578,
      btcVolume: 4021.207302975722,
      usdVolume: 37350560.43213578,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '17.7268000000000000',
      high: '18.1151000000000000',
      low: '17.4500000000000000',
      close: '18.0900000000000000',
      volume: '2172957.4900000000000000',
      quoteVolume: 38516127.55802692,
      btcVolume: 4103.451167804567,
      usdVolume: 38516127.55802692,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '18.0950000000000000',
      high: '18.7700000000000000',
      low: '18.0430000000000000',
      close: '18.7500000000000000',
      volume: '2393871.4500000000000000',
      quoteVolume: 43866390.168993205,
      btcVolume: 4602.916363249338,
      usdVolume: 43866390.168993205,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '18.7508000000000000',
      high: '19.5500000000000000',
      low: '18.2350000000000000',
      close: '19.1853000000000000',
      volume: '2790281.4600000000000000',
      quoteVolume: 52627925.923414364,
      btcVolume: 5510.371737165114,
      usdVolume: 52627925.923414364,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '19.1878000000000000',
      high: '20.0434000000000000',
      low: '19.1451000000000000',
      close: '19.8242000000000000',
      volume: '2956369.7600000000000000',
      quoteVolume: 58227674.3389005,
      btcVolume: 6053.932571636272,
      usdVolume: 58227674.3389005,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '19.8196000000000000',
      high: '20.4900000000000000',
      low: '19.2639000000000000',
      close: '19.5557000000000000',
      volume: '2857739.1600000000000000',
      quoteVolume: 56378423.676656574,
      btcVolume: 5746.873011607245,
      usdVolume: 56378423.676656574,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '19.5524000000000000',
      high: '20.1999000000000000',
      low: '18.4000000000000000',
      close: '19.7474000000000000',
      volume: '3841364.9100000000000000',
      quoteVolume: 74130208.23661059,
      btcVolume: 7086.863775270473,
      usdVolume: 74130208.23661059,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '19.7489000000000000',
      high: '20.6834000000000000',
      low: '19.4281000000000000',
      close: '20.1732000000000000',
      volume: '3516712.3500000000000000',
      quoteVolume: 70792618.98395808,
      btcVolume: 6451.110876885711,
      usdVolume: 70792618.98395808,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '20.1692000000000000',
      high: '20.4091000000000000',
      low: '19.7404000000000000',
      close: '19.8990000000000000',
      volume: '2219760.2000000000000000',
      quoteVolume: 44493255.13050928,
      btcVolume: 4013.2992143742485,
      usdVolume: 44493255.13050928,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '19.8926000000000000',
      high: '20.0995000000000000',
      low: '19.5111000000000000',
      close: '19.9700000000000000',
      volume: '2052242.5900000000000000',
      quoteVolume: 40761604.204639345,
      btcVolume: 3688.6879848192216,
      usdVolume: 40761604.204639345,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '19.9700000000000000',
      high: '20.8550000000000000',
      low: '19.9102000000000000',
      close: '20.6615000000000000',
      volume: '2251101.2100000000000000',
      quoteVolume: 46058826.375720695,
      btcVolume: 4123.40021955276,
      usdVolume: 46058826.375720695,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '20.6637000000000000',
      high: '21.7400000000000000',
      low: '20.5100000000000000',
      close: '21.6168000000000000',
      volume: '2657942.8500000000000000',
      quoteVolume: 55887870.71842739,
      btcVolume: 4818.782175909525,
      usdVolume: 55887870.71842739,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '21.6204000000000000',
      high: '22.1832000000000000',
      low: '19.1000000000000000',
      close: '20.9898000000000000',
      volume: '3352897.4600000000000000',
      quoteVolume: 69934920.73105527,
      btcVolume: 6141.195512343305,
      usdVolume: 69934920.73105527,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '20.9899000000000000',
      high: '22.5000000000000000',
      low: '20.7000000000000000',
      close: '22.0504000000000000',
      volume: '2668345.0200000000000000',
      quoteVolume: 58090829.071236245,
      btcVolume: 5171.676116333851,
      usdVolume: 58090829.071236245,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '22.0622000000000000',
      high: '22.4433000000000000',
      low: '21.4100000000000000',
      close: '22.2818000000000000',
      volume: '2388746.3300000000000000',
      quoteVolume: 52666694.152770996,
      btcVolume: 4685.128631957818,
      usdVolume: 52666694.152770996,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '22.2967000000000000',
      high: '23.5400000000000000',
      low: '22.0566000000000000',
      close: '23.2728000000000000',
      volume: '3698317.5100000000000000',
      quoteVolume: 85353655.3331338,
      btcVolume: 7486.721568389888,
      usdVolume: 85353655.3331338,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '23.2685000000000000',
      high: '23.3150000000000000',
      low: '22.5000000000000000',
      close: '22.7487000000000000',
      volume: '2442941.2600000000000000',
      quoteVolume: 56050066.31906953,
      btcVolume: 4770.055541701376,
      usdVolume: 56050066.31906953,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '22.7488000000000000',
      high: '22.9999000000000000',
      low: '21.4515000000000000',
      close: '22.3323000000000000',
      volume: '2770757.2300000000000000',
      quoteVolume: 62149286.781891696,
      btcVolume: 5325.432326997081,
      usdVolume: 62149286.781891696,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '22.3323000000000000',
      high: '22.9241000000000000',
      low: '21.8571000000000000',
      close: '22.9234000000000000',
      volume: '2408649.6800000000000000',
      quoteVolume: 53989515.896717064,
      btcVolume: 4622.635302886836,
      usdVolume: 53989515.896717064,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '22.9230000000000000',
      high: '22.9233000000000000',
      low: '22.0462000000000000',
      close: '22.4220000000000000',
      volume: '2107633.5500000000000000',
      quoteVolume: 47448309.30630981,
      btcVolume: 4067.839134883987,
      usdVolume: 47448309.30630981,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '22.4224000000000000',
      high: '22.7700000000000000',
      low: '22.0462000000000000',
      close: '22.6369000000000000',
      volume: '2108928.4200000000000000',
      quoteVolume: 47454718.99539966,
      btcVolume: 3996.4516752424197,
      usdVolume: 47454718.99539966,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '22.6369000000000000',
      high: '22.7306000000000000',
      low: '20.6600000000000000',
      close: '21.2902000000000000',
      volume: '3211543.4400000000000000',
      quoteVolume: 69760318.54425076,
      btcVolume: 6019.612592108588,
      usdVolume: 69760318.54425076,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '21.2993000000000000',
      high: '21.9820000000000000',
      low: '20.5710000000000000',
      close: '21.4919000000000000',
      volume: '2455644.0200000000000000',
      quoteVolume: 52586016.37716104,
      btcVolume: 4598.830579180555,
      usdVolume: 52586016.37716104,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '21.4919000000000000',
      high: '21.8560000000000000',
      low: '20.6400000000000000',
      close: '21.7667000000000000',
      volume: '3079188.7800000000000000',
      quoteVolume: 65454119.38361212,
      btcVolume: 5675.420522437151,
      usdVolume: 65454119.38361212,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '21.7751000000000000',
      high: '23.3019000000000000',
      low: '21.2600000000000000',
      close: '23.1047000000000000',
      volume: '3817320.2200000000000000',
      quoteVolume: 85895020.77906209,
      btcVolume: 7324.882591857548,
      usdVolume: 85895020.77906209,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '23.0966000000000000',
      high: '23.7884000000000000',
      low: '22.7000000000000000',
      close: '23.0881000000000000',
      volume: '2752150.0600000000000000',
      quoteVolume: 64002725.1804066,
      btcVolume: 5394.658484324495,
      usdVolume: 64002725.1804066,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '23.1045000000000000',
      high: '23.7790000000000000',
      low: '22.7000000000000000',
      close: '23.5290000000000000',
      volume: '2705813.5400000000000000',
      quoteVolume: 62902668.60949415,
      btcVolume: 5311.255677616959,
      usdVolume: 62902668.60949415,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '23.5291000000000000',
      high: '23.9100000000000000',
      low: '22.9584000000000000',
      close: '23.4961000000000000',
      volume: '2423482.1500000000000000',
      quoteVolume: 56563755.666076384,
      btcVolume: 4691.623480656751,
      usdVolume: 56563755.666076384,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '23.4868000000000000',
      high: '23.7800000000000000',
      low: '22.3330000000000000',
      close: '22.9705000000000000',
      volume: '2531759.2800000000000000',
      quoteVolume: 58722035.45027393,
      btcVolume: 4835.174004514033,
      usdVolume: 58722035.45027393,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '22.9802000000000000',
      high: '23.2500000000000000',
      low: '21.8219000000000000',
      close: '22.3761000000000000',
      volume: '2128074.0300000000000000',
      quoteVolume: 47918443.635081224,
      btcVolume: 4064.0451093136103,
      usdVolume: 47918443.635081224,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '22.3817000000000000',
      high: '23.3000000000000000',
      low: '22.0265000000000000',
      close: '23.0888000000000000',
      volume: '2236274.1500000000000000',
      quoteVolume: 50958756.88971377,
      btcVolume: 4320.849344252172,
      usdVolume: 50958756.88971377,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '23.0909000000000000',
      high: '23.3200000000000000',
      low: '21.5000000000000000',
      close: '22.0988000000000000',
      volume: '2489462.0200000000000000',
      quoteVolume: 56223221.93286248,
      btcVolume: 4795.974426477643,
      usdVolume: 56223221.93286248,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '22.0994000000000000',
      high: '22.2464000000000000',
      low: '21.2814000000000000',
      close: '22.2044000000000000',
      volume: '1891753.4200000000000000',
      quoteVolume: 41537070.73814768,
      btcVolume: 3592.3703459321446,
      usdVolume: 41537070.73814768,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '22.2010000000000000',
      high: '22.2348000000000000',
      low: '21.2841000000000000',
      close: '21.8555000000000000',
      volume: '1618939.3500000000000000',
      quoteVolume: 35415367.6439598,
      btcVolume: 3050.172713172957,
      usdVolume: 35415367.6439598,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '21.8575000000000000',
      high: '22.9746000000000000',
      low: '21.6655000000000000',
      close: '22.5953000000000000',
      volume: '1877683.4400000000000000',
      quoteVolume: 42093704.95900781,
      btcVolume: 3588.708992922525,
      usdVolume: 42093704.95900781,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '22.6016000000000000',
      high: '22.6415000000000000',
      low: '20.9805000000000000',
      close: '21.3882000000000000',
      volume: '2214962.9300000000000000',
      quoteVolume: 48368662.81298032,
      btcVolume: 4213.210966957328,
      usdVolume: 48368662.81298032,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '21.3881000000000000',
      high: '22.4743000000000000',
      low: '21.2651000000000000',
      close: '22.1999000000000000',
      volume: '2499792.2900000000000000',
      quoteVolume: 55285830.68548643,
      btcVolume: 4852.707891075785,
      usdVolume: 55285830.68548643,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '22.2005000000000000',
      high: '23.1507000000000000',
      low: '21.8064000000000000',
      close: '23.1127000000000000',
      volume: '2925157.1700000000000000',
      quoteVolume: 65680254.026992634,
      btcVolume: 5788.970095035588,
      usdVolume: 65680254.026992634,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '23.1127000000000000',
      high: '23.5846000000000000',
      low: '22.6158000000000000',
      close: '23.0811000000000000',
      volume: '2526396.8800000000000000',
      quoteVolume: 58274233.91624748,
      btcVolume: 5107.116104849058,
      usdVolume: 58274233.91624748,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '23.0783000000000000',
      high: '23.5000000000000000',
      low: '22.7416000000000000',
      close: '22.9286000000000000',
      volume: '1825207.1700000000000000',
      quoteVolume: 42300003.84051298,
      btcVolume: 3677.51541413538,
      usdVolume: 42300003.84051298,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '22.9441000000000000',
      high: '23.8300000000000000',
      low: '22.9000000000000000',
      close: '23.6208000000000000',
      volume: '1777138.8500000000000000',
      quoteVolume: 41436875.3779341,
      btcVolume: 3568.130657260122,
      usdVolume: 41436875.3779341,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '23.6250000000000000',
      high: '23.9000000000000000',
      low: '22.9670000000000000',
      close: '23.1477000000000000',
      volume: '1767387.7800000000000000',
      quoteVolume: 41283857.21209864,
      btcVolume: 3534.6531834593225,
      usdVolume: 41283857.21209864,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '23.1481000000000000',
      high: '25.4100000000000000',
      low: '22.8400000000000000',
      close: '24.7028000000000000',
      volume: '3998518.4600000000000000',
      quoteVolume: 95349979.86598961,
      btcVolume: 8016.152928036265,
      usdVolume: 95348715.97884162,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '24.7101000000000000',
      high: '25.8262000000000000',
      low: '23.1215000000000000',
      close: '24.7652000000000000',
      volume: '5843722.5300000000000000',
      quoteVolume: 144376866.58587617,
      btcVolume: 12476.71910511825,
      usdVolume: 144376866.58587617,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '24.7653000000000000',
      high: '25.5400000000000000',
      low: '19.8765000000000000',
      close: '20.3592000000000000',
      volume: '7208188.4900000000000000',
      quoteVolume: 168388157.16915026,
      btcVolume: 15323.190970084937,
      usdVolume: 168388157.16915026,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '20.4027000000000000',
      high: '21.8300000000000000',
      low: '19.2000000000000000',
      close: '20.9712000000000000',
      volume: '5304836.8000000000000000',
      quoteVolume: 110005537.1003693,
      btcVolume: 10648.840229664847,
      usdVolume: 110005537.1003693,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '20.9995000000000000',
      high: '21.5539000000000000',
      low: '18.0110000000000000',
      close: '19.4698000000000000',
      volume: '4587657.7700000000000000',
      quoteVolume: 89925282.01269266,
      btcVolume: 8795.872253723111,
      usdVolume: 89925282.01269266,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '19.4737000000000000',
      high: '23.4418000000000000',
      low: '18.5283000000000000',
      close: '23.1420000000000000',
      volume: '8069211.7600000000000000',
      quoteVolume: 174283155.6269918,
      btcVolume: 17068.88087509094,
      usdVolume: 174283155.6269918,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '23.1612000000000000',
      high: '23.5545000000000000',
      low: '20.0000000000000000',
      close: '22.4426000000000000',
      volume: '5402317.0400000000000000',
      quoteVolume: 117739085.20631854,
      btcVolume: 11629.8606720547,
      usdVolume: 117739085.20631854,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '22.4428000000000000',
      high: '23.4200000000000000',
      low: '21.2739000000000000',
      close: '23.2837000000000000',
      volume: '5158563.9800000000000000',
      quoteVolume: 115741375.12774241,
      btcVolume: 11408.651574079575,
      usdVolume: 115741375.12774241,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '23.2745000000000000',
      high: '24.5201000000000000',
      low: '22.4139000000000000',
      close: '24.4716000000000000',
      volume: '4016452.5100000000000000',
      quoteVolume: 94841678.35523182,
      btcVolume: 9328.731014737568,
      usdVolume: 94841678.35523182,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '24.4847000000000000',
      high: '25.6700000000000000',
      low: '24.0568000000000000',
      close: '24.5489000000000000',
      volume: '4197768.0800000000000000',
      quoteVolume: 103939762.77621293,
      btcVolume: 10055.05164439836,
      usdVolume: 103939762.77621293,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '24.5579000000000000',
      high: '25.6969000000000000',
      low: '23.0677000000000000',
      close: '25.3990000000000000',
      volume: '3622605.0700000000000000',
      quoteVolume: 88276634.60726066,
      btcVolume: 8560.638049834533,
      usdVolume: 88276634.60726066,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '25.4000000000000000',
      high: '28.5305000000000000',
      low: '25.3575000000000000',
      close: '28.5050000000000000',
      volume: '6538510.1800000000000000',
      quoteVolume: 175802729.4278746,
      btcVolume: 16975.401468484088,
      usdVolume: 175802729.4278746,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '28.5044000000000000',
      high: '32.4401000000000000',
      low: '27.6100000000000000',
      close: '31.1656000000000000',
      volume: '10646215.6600000000000000',
      quoteVolume: 324162248.7407463,
      btcVolume: 31172.01029736164,
      usdVolume: 324162248.7407463,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '31.1656000000000000',
      high: '33.3888000000000000',
      low: '29.0000000000000000',
      close: '31.2523000000000000',
      volume: '9039644.3200000000000000',
      quoteVolume: 282868031.2655408,
      btcVolume: 26883.24594624267,
      usdVolume: 282868031.2655408,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '31.2546000000000000',
      high: '31.2780000000000000',
      low: '26.7123000000000000',
      close: '27.1181000000000000',
      volume: '7931354.6460000000000000',
      quoteVolume: 228427870.13850227,
      btcVolume: 21238.609622009128,
      usdVolume: 228427870.13850227,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '27.1148000000000000',
      high: '28.4724000000000000',
      low: '25.8200000000000000',
      close: '27.8695000000000000',
      volume: '5777419.7520000000000000',
      quoteVolume: 157849897.40358767,
      btcVolume: 14525.119593023768,
      usdVolume: 157849897.40358767,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '27.8539000000000000',
      high: '28.8300000000000000',
      low: '26.0500000000000000',
      close: '27.0950000000000000',
      volume: '4620518.8740000000000000',
      quoteVolume: 125923630.01926374,
      btcVolume: 11551.914313428693,
      usdVolume: 125923630.01926374,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '27.1106000000000000',
      high: '28.7500000000000000',
      low: '26.5708000000000000',
      close: '27.6290000000000000',
      volume: '4243135.9990000000000000',
      quoteVolume: 117188169.08344378,
      btcVolume: 10721.855220981095,
      usdVolume: 117188169.08344378,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '27.6414000000000000',
      high: '27.9770000000000000',
      low: '26.6591000000000000',
      close: '27.2035000000000000',
      volume: '2431517.1500000000000000',
      quoteVolume: 66209405.92737906,
      btcVolume: 6017.836049060832,
      usdVolume: 66209405.92737906,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '27.2035000000000000',
      high: '27.3200000000000000',
      low: '25.1247000000000000',
      close: '26.3160000000000000',
      volume: '2843429.9720000000000000',
      quoteVolume: 74991076.55056357,
      btcVolume: 6881.997502694567,
      usdVolume: 74991076.55056357,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '26.3167000000000000',
      high: '26.6800000000000000',
      low: '22.1000000000000000',
      close: '23.1232000000000000',
      volume: '5251422.2430000000000000',
      quoteVolume: 127147745.41469589,
      btcVolume: 11950.186281642047,
      usdVolume: 127147745.41469589,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '23.1273000000000000',
      high: '24.5650000000000000',
      low: '22.6666000000000000',
      close: '24.0468000000000000',
      volume: '3281775.4620000000000000',
      quoteVolume: 77971128.39187472,
      btcVolume: 7453.267500200181,
      usdVolume: 77971128.39187472,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '24.0468000000000000',
      high: '24.3437000000000000',
      low: '22.4514000000000000',
      close: '22.8331000000000000',
      volume: '3134141.7740000000000000',
      quoteVolume: 73949538.77212654,
      btcVolume: 7097.075147605696,
      usdVolume: 73949538.77212654,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '22.8448000000000000',
      high: '24.6999000000000000',
      low: '22.5661000000000000',
      close: '24.5745000000000000',
      volume: '3280582.2240000000000000',
      quoteVolume: 78057236.24715658,
      btcVolume: 7468.991503938146,
      usdVolume: 78057236.24715658,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '24.5749000000000000',
      high: '25.0799000000000000',
      low: '23.7111000000000000',
      close: '24.6924000000000000',
      volume: '2826869.5890000000000000',
      quoteVolume: 69263025.79303573,
      btcVolume: 6491.558933280871,
      usdVolume: 69263025.79303573,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '24.6925000000000000',
      high: '26.4628000000000000',
      low: '24.4750000000000000',
      close: '26.1998000000000000',
      volume: '3109951.9450000000000000',
      quoteVolume: 79026166.8893397,
      btcVolume: 7375.746915791385,
      usdVolume: 79026166.8893397,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '26.1885000000000000',
      high: '26.9000000000000000',
      low: '25.5012000000000000',
      close: '26.2510000000000000',
      volume: '2549943.8930000000000000',
      quoteVolume: 66948796.67374076,
      btcVolume: 6245.902387721704,
      usdVolume: 66948796.67374076,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '26.2435000000000000',
      high: '27.7200000000000000',
      low: '25.8313000000000000',
      close: '26.9855000000000000',
      volume: '3215302.9540000000000000',
      quoteVolume: 85939137.96800753,
      btcVolume: 7904.878434360903,
      usdVolume: 85939137.96800753,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '26.9969000000000000',
      high: '29.0000000000000000',
      low: '26.8600000000000000',
      close: '28.8946000000000000',
      volume: '3642652.3360000000000000',
      quoteVolume: 102012505.98227179,
      btcVolume: 9504.594750278005,
      usdVolume: 102012505.98227179,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '28.8814000000000000',
      high: '29.5646000000000000',
      low: '27.8813000000000000',
      close: '29.2656000000000000',
      volume: '3102332.1810000000000000',
      quoteVolume: 89000015.31908017,
      btcVolume: 8286.090073062971,
      usdVolume: 89000015.31908017,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '29.2696000000000000',
      high: '29.3927000000000000',
      low: '26.2111000000000000',
      close: '27.4720000000000000',
      volume: '4000390.3250000000000000',
      quoteVolume: 111543168.04777229,
      btcVolume: 10423.810924564852,
      usdVolume: 111543168.04777229,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '27.3367000000000000',
      high: '28.3633000000000000',
      low: '25.6652000000000000',
      close: '27.3369000000000000',
      volume: '4117221.0050000000000000',
      quoteVolume: 111198198.15562725,
      btcVolume: 10572.72520169783,
      usdVolume: 111198198.15562725,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '27.3338000000000000',
      high: '28.3997000000000000',
      low: '27.0701000000000000',
      close: '28.1867000000000000',
      volume: '2946071.9920000000000000',
      quoteVolume: 81824336.491502,
      btcVolume: 7759.17949681676,
      usdVolume: 81824336.491502,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '28.1872000000000000',
      high: '29.1500000000000000',
      low: '27.9658000000000000',
      close: '28.9668000000000000',
      volume: '3102597.6600000000000000',
      quoteVolume: 89029435.36271603,
      btcVolume: 8394.366201707255,
      usdVolume: 89029435.36271603,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '28.9832000000000000',
      high: '29.2665000000000000',
      low: '28.0687000000000000',
      close: '28.6158000000000000',
      volume: '2268257.1710000000000000',
      quoteVolume: 65226739.00450134,
      btcVolume: 6100.3480015059995,
      usdVolume: 65226739.00450134,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '28.6330000000000000',
      high: '28.9900000000000000',
      low: '27.2500000000000000',
      close: '27.6812000000000000',
      volume: '2810020.1650000000000000',
      quoteVolume: 79203442.53717537,
      btcVolume: 7402.756056350025,
      usdVolume: 79203442.53717537,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '27.6907000000000000',
      high: '27.8965000000000000',
      low: '26.8401000000000000',
      close: '27.6898000000000000',
      volume: '2059912.1820000000000000',
      quoteVolume: 56521007.48227771,
      btcVolume: 5326.109268024313,
      usdVolume: 56521007.48227771,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '27.6931000000000000',
      high: '27.8153000000000000',
      low: '26.4999000000000000',
      close: '27.7424000000000000',
      volume: '2423081.9940000000000000',
      quoteVolume: 66102858.40443837,
      btcVolume: 6161.524987847587,
      usdVolume: 66102858.40443837,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '27.7342000000000000',
      high: '28.5368000000000000',
      low: '27.4548000000000000',
      close: '28.4076000000000000',
      volume: '2590468.0830000000000000',
      quoteVolume: 72830100.20340902,
      btcVolume: 6638.910063675278,
      usdVolume: 72830100.20340902,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '28.3980000000000000',
      high: '29.2814000000000000',
      low: '28.0666000000000000',
      close: '28.4414000000000000',
      volume: '2405371.4570000000000000',
      quoteVolume: 68983739.73955177,
      btcVolume: 6094.207111159801,
      usdVolume: 68983739.73955177,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '28.4100000000000000',
      high: '28.7200000000000000',
      low: '27.8760000000000000',
      close: '28.5271000000000000',
      volume: '2287361.9230000000000000',
      quoteVolume: 64862236.42497369,
      btcVolume: 5711.44629460792,
      usdVolume: 64862236.42497369,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '28.5200000000000000',
      high: '31.1299000000000000',
      low: '28.4338000000000000',
      close: '30.6503000000000000',
      volume: '4002611.1740000000000000',
      quoteVolume: 120352867.44459017,
      btcVolume: 10544.280084904543,
      usdVolume: 120352867.44459017,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '30.6503000000000000',
      high: '31.7999000000000000',
      low: '30.1870000000000000',
      close: '30.7256000000000000',
      volume: '3052275.4880000000000000',
      quoteVolume: 94547125.33016007,
      btcVolume: 8257.112105221257,
      usdVolume: 94547125.33016007,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '30.7256000000000000',
      high: '31.3300000000000000',
      low: '30.3733000000000000',
      close: '30.8380000000000000',
      volume: '2235892.8320000000000000',
      quoteVolume: 68852635.2776885,
      btcVolume: 6038.336550994363,
      usdVolume: 68852635.2776885,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '30.8380000000000000',
      high: '31.6663000000000000',
      low: '30.3990000000000000',
      close: '31.3256000000000000',
      volume: '2664333.4610000000000000',
      quoteVolume: 82806329.4986107,
      btcVolume: 7257.335240346204,
      usdVolume: 82806329.4986107,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '31.3256000000000000',
      high: '31.9798000000000000',
      low: '29.5705000000000000',
      close: '30.0516000000000000',
      volume: '2971307.3670000000000000',
      quoteVolume: 90779001.72612782,
      btcVolume: 7990.956897298697,
      usdVolume: 90779001.72612782,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '30.0510000000000000',
      high: '30.5640000000000000',
      low: '29.6521000000000000',
      close: '30.2414000000000000',
      volume: '1845150.5060000000000000',
      quoteVolume: 55749675.69959583,
      btcVolume: 4918.479991791265,
      usdVolume: 55749675.69959583,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '30.2423000000000000',
      high: '31.0354000000000000',
      low: '30.1650000000000000',
      close: '30.6593000000000000',
      volume: '2267377.3170000000000000',
      quoteVolume: 69506327.30622022,
      btcVolume: 6086.495770877648,
      usdVolume: 69506327.30622022,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '30.6568000000000000',
      high: '30.6766000000000000',
      low: '29.7002000000000000',
      close: '29.9107000000000000',
      volume: '1752644.9990000000000000',
      quoteVolume: 52880539.760139465,
      btcVolume: 4575.7130204564255,
      usdVolume: 52880539.760139465,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '29.9043000000000000',
      high: '30.0892000000000000',
      low: '28.5000000000000000',
      close: '28.8227000000000000',
      volume: '2583242.4400000000000000',
      quoteVolume: 75672316.51136452,
      btcVolume: 6399.37021187418,
      usdVolume: 75672316.51136452,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '28.8131000000000000',
      high: '30.4074000000000000',
      low: '28.6406000000000000',
      close: '29.7329000000000000',
      volume: '2663615.8890000000000000',
      quoteVolume: 78865122.07201448,
      btcVolume: 6329.800794199335,
      usdVolume: 78865122.07201448,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '29.7355000000000000',
      high: '31.3000000000000000',
      low: '29.6655000000000000',
      close: '30.6396000000000000',
      volume: '2830772.7050000000000000',
      quoteVolume: 86718736.3816204,
      btcVolume: 6732.909811327086,
      usdVolume: 86718736.3816204,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '30.6334000000000000',
      high: '31.2000000000000000',
      low: '30.0652000000000000',
      close: '30.3066000000000000',
      volume: '1751841.2190000000000000',
      quoteVolume: 53822247.52955811,
      btcVolume: 4166.146373136381,
      usdVolume: 53822247.52955811,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '30.6461000000000000',
      high: '30.8781000000000000',
      low: '30.4243000000000000',
      close: '30.6639000000000000',
      volume: '1625502.5240000000000000',
      quoteVolume: 49794483.44654,
      btcVolume: 3828.381409664332,
      usdVolume: 49794483.44654,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '30.6522000000000000',
      high: '30.9726000000000000',
      low: '29.8805000000000000',
      close: '30.1280000000000000',
      volume: '1486018.1520000000000000',
      quoteVolume: 45184192.15742622,
      btcVolume: 3461.2183046942464,
      usdVolume: 45184192.15742622,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '30.1342000000000000',
      high: '31.4300000000000000',
      low: '29.9600000000000000',
      close: '31.0467000000000000',
      volume: '2647734.6210000000000000',
      quoteVolume: 81431799.40994392,
      btcVolume: 6237.364724196646,
      usdVolume: 81431799.40994392,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '31.0436000000000000',
      high: '32.3788000000000000',
      low: '30.9000000000000000',
      close: '31.4826000000000000',
      volume: '2881430.2220000000000000',
      quoteVolume: 91088211.45629856,
      btcVolume: 6829.721068843635,
      usdVolume: 91088211.45629856,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '31.4730000000000000',
      high: '31.9375000000000000',
      low: '29.8000000000000000',
      close: '30.3338000000000000',
      volume: '2052118.9290000000000000',
      quoteVolume: 63425566.100130275,
      btcVolume: 4717.743672827569,
      usdVolume: 63425566.100130275,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '30.3295000000000000',
      high: '30.4272000000000000',
      low: '29.4700000000000000',
      close: '29.8702000000000000',
      volume: '1977911.0400000000000000',
      quoteVolume: 59517443.808163345,
      btcVolume: 4471.350986754041,
      usdVolume: 59517443.808163345,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '29.8696000000000000',
      high: '30.2596000000000000',
      low: '27.5111000000000000',
      close: '28.4909000000000000',
      volume: '3211305.3580000000000000',
      quoteVolume: 91773152.80737089,
      btcVolume: 6865.029260469122,
      usdVolume: 91773152.80737089,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '28.4969000000000000',
      high: '28.8880000000000000',
      low: '28.1132000000000000',
      close: '28.5058000000000000',
      volume: '1975871.5750000000000000',
      quoteVolume: 56382966.93345828,
      btcVolume: 4104.696666980671,
      usdVolume: 56382966.93345828,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '28.5058000000000000',
      high: '28.6790000000000000',
      low: '28.0000000000000000',
      close: '28.5318000000000000',
      volume: '1916131.6810000000000000',
      quoteVolume: 54186354.18994433,
      btcVolume: 3937.006778622533,
      usdVolume: 54186354.18994433,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '28.5485000000000000',
      high: '29.3079000000000000',
      low: '27.7782000000000000',
      close: '27.7983000000000000',
      volume: '2123599.0520000000000000',
      quoteVolume: 60737427.18014957,
      btcVolume: 4463.645604676236,
      usdVolume: 60737427.18014957,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '27.7984000000000000',
      high: '27.7984000000000000',
      low: '25.7499000000000000',
      close: '26.8638000000000000',
      volume: '2591560.8550000000000000',
      quoteVolume: 69103680.17142187,
      btcVolume: 5107.912094364955,
      usdVolume: 69103680.17142187,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '26.8722000000000000',
      high: '26.9700000000000000',
      low: '25.9000000000000000',
      close: '26.9600000000000000',
      volume: '2039985.3400000000000000',
      quoteVolume: 54040159.97019821,
      btcVolume: 3890.820934023125,
      usdVolume: 54040159.97019821,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '26.9607000000000000',
      high: '27.8947000000000000',
      low: '26.6601000000000000',
      close: '27.6875000000000000',
      volume: '2396900.8220000000000000',
      quoteVolume: 65625192.2818929,
      btcVolume: 4465.977407660962,
      usdVolume: 65625192.2818929,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '27.6860000000000000',
      high: '29.4692000000000000',
      low: '27.6668000000000000',
      close: '29.0435000000000000',
      volume: '2705358.2900000000000000',
      quoteVolume: 77745649.67222922,
      btcVolume: 4990.296761515876,
      usdVolume: 77745649.67222922,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '29.0391000000000000',
      high: '29.8990000000000000',
      low: '26.8500000000000000',
      close: '27.7651000000000000',
      volume: '3088858.1960000000000000',
      quoteVolume: 88661320.14317802,
      btcVolume: 5796.6328340812925,
      usdVolume: 88661320.14317802,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '27.7628000000000000',
      high: '28.6943000000000000',
      low: '27.4490000000000000',
      close: '28.4101000000000000',
      volume: '2296984.2220000000000000',
      quoteVolume: 64827072.49203944,
      btcVolume: 4258.489159084323,
      usdVolume: 64827072.49203944,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '28.4101000000000000',
      high: '28.9691000000000000',
      low: '27.3461000000000000',
      close: '28.0941000000000000',
      volume: '2147857.4210000000000000',
      quoteVolume: 60633554.16222215,
      btcVolume: 3946.070465446345,
      usdVolume: 60633554.16222215,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '28.0836000000000000',
      high: '28.4880000000000000',
      low: '27.6114000000000000',
      close: '28.2188000000000000',
      volume: '2172589.6330000000000000',
      quoteVolume: 61108455.63378035,
      btcVolume: 3990.1571339070315,
      usdVolume: 61108455.63378035,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '28.2183000000000000',
      high: '28.5999000000000000',
      low: '27.7966000000000000',
      close: '28.0160000000000000',
      volume: '1974074.3100000000000000',
      quoteVolume: 55629780.26008157,
      btcVolume: 3569.799698588421,
      usdVolume: 55629780.26008157,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '28.0057000000000000',
      high: '28.1599000000000000',
      low: '27.3028000000000000',
      close: '27.6874000000000000',
      volume: '1661489.2860000000000000',
      quoteVolume: 46197453.38885523,
      btcVolume: 2911.5701341389563,
      usdVolume: 46197453.38885523,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '27.7019000000000000',
      high: '28.5198000000000000',
      low: '27.5284000000000000',
      close: '28.1974000000000000',
      volume: '1870570.7210000000000000',
      quoteVolume: 52380431.54643035,
      btcVolume: 3219.0015810697378,
      usdVolume: 52380431.54643035,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '28.1974000000000000',
      high: '28.1974000000000000',
      low: '27.3684000000000000',
      close: '27.9311000000000000',
      volume: '1491728.0070000000000000',
      quoteVolume: 41393342.505827054,
      btcVolume: 2581.1714629579965,
      usdVolume: 41393342.505827054,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '27.9359000000000000',
      high: '28.2332000000000000',
      low: '27.0700000000000000',
      close: '27.6786000000000000',
      volume: '1441384.5610000000000000',
      quoteVolume: 40098284.74564967,
      btcVolume: 2509.6270634778307,
      usdVolume: 40098284.74564967,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '27.6822000000000000',
      high: '28.2202000000000000',
      low: '27.4461000000000000',
      close: '27.9451000000000000',
      volume: '1684972.1740000000000000',
      quoteVolume: 46951053.47256122,
      btcVolume: 2873.554556801199,
      usdVolume: 46951053.47256122,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '27.9588000000000000',
      high: '28.8000000000000000',
      low: '27.9262000000000000',
      close: '28.5552000000000000',
      volume: '2165992.6190000000000000',
      quoteVolume: 61647606.24655668,
      btcVolume: 3620.0821814901874,
      usdVolume: 61647606.24655668,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '28.5552000000000000',
      high: '28.8380000000000000',
      low: '27.5000000000000000',
      close: '28.0931000000000000',
      volume: '2208116.0360000000000000',
      quoteVolume: 62179324.88033911,
      btcVolume: 3481.8660558290812,
      usdVolume: 62179324.88033911,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '28.1049000000000000',
      high: '28.3666000000000000',
      low: '27.6183000000000000',
      close: '28.1387000000000000',
      volume: '1978784.7070000000000000',
      quoteVolume: 55532045.828950375,
      btcVolume: 3111.2806511064537,
      usdVolume: 55532045.828950375,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '28.1266000000000000',
      high: '29.3516000000000000',
      low: '28.0598000000000000',
      close: '28.9715000000000000',
      volume: '2462083.4030000000000000',
      quoteVolume: 70582295.03196461,
      btcVolume: 3855.968113643725,
      usdVolume: 70582295.03196461,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '28.9722000000000000',
      high: '30.8000000000000000',
      low: '28.2955000000000000',
      close: '30.5161000000000000',
      volume: '3160335.4720000000000000',
      quoteVolume: 93653968.66348147,
      btcVolume: 5004.286317743498,
      usdVolume: 93653968.66348147,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '30.5168000000000000',
      high: '31.1200000000000000',
      low: '29.0000000000000000',
      close: '30.0226000000000000',
      volume: '2842370.7830000000000000',
      quoteVolume: 85423529.80392088,
      btcVolume: 4642.671182761763,
      usdVolume: 85423529.80392088,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '30.0291000000000000',
      high: '31.1000000000000000',
      low: '29.7860000000000000',
      close: '30.9950000000000000',
      volume: '2854700.3560000000000000',
      quoteVolume: 86945280.82502285,
      btcVolume: 4722.192647593682,
      usdVolume: 86945280.82502285,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '30.9994000000000000',
      high: '35.1400000000000000',
      low: '30.7934000000000000',
      close: '33.9407000000000000',
      volume: '7296367.3690000000000000',
      quoteVolume: 240609850.8251657,
      btcVolume: 12879.90776771163,
      usdVolume: 240609850.8251657,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '33.9451000000000000',
      high: '35.4338000000000000',
      low: '30.4390000000000000',
      close: '31.2953000000000000',
      volume: '4579889.4800000000000000',
      quoteVolume: 152154802.22398162,
      btcVolume: 7996.9340517331775,
      usdVolume: 152154802.22398162,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '31.2950000000000000',
      high: '31.7708000000000000',
      low: '26.3500000000000000',
      close: '28.2356000000000000',
      volume: '6241853.0130000000000000',
      quoteVolume: 179684915.43192223,
      btcVolume: 10317.064916775644,
      usdVolume: 179684915.43192223,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '28.2354000000000000',
      high: '28.7175000000000000',
      low: '27.2042000000000000',
      close: '28.4606000000000000',
      volume: '2317359.7950000000000000',
      quoteVolume: 65177587.384269916,
      btcVolume: 3831.3766146767975,
      usdVolume: 65177587.384269916,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '28.4606000000000000',
      high: '29.6175000000000000',
      low: '27.9000000000000000',
      close: '29.1766000000000000',
      volume: '2636604.6020000000000000',
      quoteVolume: 76332488.51250012,
      btcVolume: 4378.493625971485,
      usdVolume: 76332488.51250012,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '29.1552000000000000',
      high: '30.4500000000000000',
      low: '28.8544000000000000',
      close: '30.1045000000000000',
      volume: '2856408.5640000000000000',
      quoteVolume: 84710614.21822041,
      btcVolume: 4707.608681471879,
      usdVolume: 84710614.21822041,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '30.1003000000000000',
      high: '31.5999000000000000',
      low: '29.7230000000000000',
      close: '31.4935000000000000',
      volume: '2977355.3390000000000000',
      quoteVolume: 91514232.68682995,
      btcVolume: 4817.517391122217,
      usdVolume: 91514232.68682995,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '31.4935000000000000',
      high: '31.8875000000000000',
      low: '29.1700000000000000',
      close: '30.1996000000000000',
      volume: '3066237.6600000000000000',
      quoteVolume: 94399139.74031925,
      btcVolume: 4914.238089053225,
      usdVolume: 94399139.74031925,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '30.2003000000000000',
      high: '30.9993000000000000',
      low: '29.5306000000000000',
      close: '30.2875000000000000',
      volume: '2360296.3080000000000000',
      quoteVolume: 71525356.8507045,
      btcVolume: 3776.942602662899,
      usdVolume: 71525356.8507045,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '30.2724000000000000',
      high: '30.7930000000000000',
      low: '29.8568000000000000',
      close: '30.7065000000000000',
      volume: '1924462.4660000000000000',
      quoteVolume: 58447530.68081103,
      btcVolume: 3036.9154023239544,
      usdVolume: 58447530.68081103,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '30.7099000000000000',
      high: '31.1371000000000000',
      low: '28.7051000000000000',
      close: '29.0231000000000000',
      volume: '2064562.0540000000000000',
      quoteVolume: 61840304.01373671,
      btcVolume: 3237.434025284598,
      usdVolume: 61840304.01373671,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '29.0315000000000000',
      high: '30.0588000000000000',
      low: '28.6588000000000000',
      close: '29.7280000000000000',
      volume: '1836006.3170000000000000',
      quoteVolume: 53868352.508677736,
      btcVolume: 2845.6790819435187,
      usdVolume: 53868352.508677736,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '29.7296000000000000',
      high: '30.1819000000000000',
      low: '29.0124000000000000',
      close: '29.5026000000000000',
      volume: '1330813.8990000000000000',
      quoteVolume: 39419545.068958886,
      btcVolume: 2055.588312615177,
      usdVolume: 39419545.068958886,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '29.4912000000000000',
      high: '29.6685000000000000',
      low: '29.0600000000000000',
      close: '29.3700000000000000',
      volume: '1219543.1660000000000000',
      quoteVolume: 35795857.65738368,
      btcVolume: 1864.6156139549516,
      usdVolume: 35795857.65738368,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '29.3705000000000000',
      high: '29.4998000000000000',
      low: '27.4500000000000000',
      close: '27.6705000000000000',
      volume: '1844102.0560000000000000',
      quoteVolume: 52562094.302194595,
      btcVolume: 2782.158122499107,
      usdVolume: 52562094.302194595,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '27.6705000000000000',
      high: '28.3853000000000000',
      low: '26.9244000000000000',
      close: '28.1452000000000000',
      volume: '1635344.1810000000000000',
      quoteVolume: 45454697.5386892,
      btcVolume: 2491.802163982006,
      usdVolume: 45454697.5386892,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '28.1440000000000000',
      high: '28.2997000000000000',
      low: '27.3900000000000000',
      close: '27.6926000000000000',
      volume: '1338821.6330000000000000',
      quoteVolume: 37239316.75626387,
      btcVolume: 2036.0163366628099,
      usdVolume: 37239316.75626387,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '27.6950000000000000',
      high: '27.7700000000000000',
      low: '26.7273000000000000',
      close: '27.3419000000000000',
      volume: '1185571.0640000000000000',
      quoteVolume: 32228789.764525007,
      btcVolume: 1796.9005359462299,
      usdVolume: 32222415.98784421,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '27.3490000000000000',
      high: '28.2300000000000000',
      low: '27.2542000000000000',
      close: '28.1603000000000000',
      volume: '1209119.9800000000000000',
      quoteVolume: 33698868.29087121,
      btcVolume: 1826.7844744499994,
      usdVolume: 33698868.29087121,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '28.1603000000000000',
      high: '29.2628000000000000',
      low: '28.0000000000000000',
      close: '29.1571000000000000',
      volume: '1639495.8160000000000000',
      quoteVolume: 47146012.390196115,
      btcVolume: 2467.453865154321,
      usdVolume: 47146012.390196115,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '29.1599000000000000',
      high: '30.1884000000000000',
      low: '28.6669000000000000',
      close: '30.0338000000000000',
      volume: '2025868.5440000000000000',
      quoteVolume: 59594740.44459001,
      btcVolume: 3107.6269302330743,
      usdVolume: 59594740.44459001,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '30.0338000000000000',
      high: '30.3790000000000000',
      low: '29.3069000000000000',
      close: '29.5053000000000000',
      volume: '1536633.4260000000000000',
      quoteVolume: 45782401.5646542,
      btcVolume: 2367.206132560912,
      usdVolume: 45782401.5646542,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '29.5059000000000000',
      high: '30.6140000000000000',
      low: '29.0265000000000000',
      close: '30.5577000000000000',
      volume: '2034306.1120000000000000',
      quoteVolume: 60707183.55854131,
      btcVolume: 2993.371881466091,
      usdVolume: 60707183.55854131,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '30.5524000000000000',
      high: '31.5489000000000000',
      low: '29.6891000000000000',
      close: '30.2255000000000000',
      volume: '3274786.1310000000000000',
      quoteVolume: 100846680.86741851,
      btcVolume: 4478.236654440235,
      usdVolume: 100846680.86741851,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '30.2254000000000000',
      high: '31.2172000000000000',
      low: '29.6100000000000000',
      close: '30.9882000000000000',
      volume: '2045132.3370000000000000',
      quoteVolume: 62437403.31907742,
      btcVolume: 2727.6635487497656,
      usdVolume: 62437403.31907742,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '30.9858000000000000',
      high: '34.2307000000000000',
      low: '30.8812000000000000',
      close: '33.3167000000000000',
      volume: '4117460.2930000000000000',
      quoteVolume: 134985674.6489003,
      btcVolume: 5801.552675700171,
      usdVolume: 134985674.6489003,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '33.3018000000000000',
      high: '35.6900000000000000',
      low: '32.5899000000000000',
      close: '34.3148000000000000',
      volume: '4477498.8180000000000000',
      quoteVolume: 153515421.81364313,
      btcVolume: 6509.728499602096,
      usdVolume: 153515421.81364313,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '34.3072000000000000',
      high: '35.6000000000000000',
      low: '31.4871000000000000',
      close: '32.0500000000000000',
      volume: '4525705.7910000000000000',
      quoteVolume: 151775639.78318813,
      btcVolume: 6527.479468708382,
      usdVolume: 151775639.78318813,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '32.0500000000000000',
      high: '33.8000000000000000',
      low: '30.4719000000000000',
      close: '33.5151000000000000',
      volume: '3563635.6730000000000000',
      quoteVolume: 115167307.80793788,
      btcVolume: 5006.055644717137,
      usdVolume: 115167307.80793788,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '33.5112000000000000',
      high: '34.5050000000000000',
      low: '30.0000000000000000',
      close: '31.1843000000000000',
      volume: '3940926.4420000000000000',
      quoteVolume: 128877133.69173539,
      btcVolume: 5483.664774174263,
      usdVolume: 128877133.69173539,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '31.1698000000000000',
      high: '32.7589000000000000',
      low: '30.0000000000000000',
      close: '32.4895000000000000',
      volume: '2999570.8530000000000000',
      quoteVolume: 94739220.62729084,
      btcVolume: 4084.295268120996,
      usdVolume: 94739220.62729084,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '32.4947000000000000',
      high: '33.4954000000000000',
      low: '31.7313000000000000',
      close: '33.1996000000000000',
      volume: '2595633.3640000000000000',
      quoteVolume: 84823958.91861957,
      btcVolume: 3526.3258018350043,
      usdVolume: 84823958.91861957,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '33.1996000000000000',
      high: '34.0495000000000000',
      low: '32.5100000000000000',
      close: '33.6361000000000000',
      volume: '2418941.5440000000000000',
      quoteVolume: 80768632.43812405,
      btcVolume: 3199.458679420725,
      usdVolume: 80768632.43812405,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '33.6510000000000000',
      high: '34.9900000000000000',
      low: '31.8601000000000000',
      close: '33.6740000000000000',
      volume: '3747872.7180000000000000',
      quoteVolume: 126569474.2263926,
      btcVolume: 4663.94819780864,
      usdVolume: 126569474.2263926,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '33.6741000000000000',
      high: '36.1500000000000000',
      low: '33.3105000000000000',
      close: '35.9510000000000000',
      volume: '4121783.1030000000000000',
      quoteVolume: 145163638.0339237,
      btcVolume: 5381.500496375316,
      usdVolume: 145163638.0339237,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '35.9507000000000000',
      high: '39.9999000000000000',
      low: '35.3015000000000000',
      close: '39.0667000000000000',
      volume: '7022548.4870000000000000',
      quoteVolume: 266651443.80075303,
      btcVolume: 10020.386760643316,
      usdVolume: 266651443.80075303,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '39.0746000000000000',
      high: '39.2700000000000000',
      low: '36.8131000000000000',
      close: '38.1558000000000000',
      volume: '2424623.4670000000000000',
      quoteVolume: 91497596.04163969,
      btcVolume: 3260.3633178562527,
      usdVolume: 91497596.04163969,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '38.1558000000000000',
      high: '38.2133000000000000',
      low: '36.5157000000000000',
      close: '37.3588000000000000',
      volume: '1887715.8350000000000000',
      quoteVolume: 70486125.96259664,
      btcVolume: 2446.931071296564,
      usdVolume: 70486125.96259664,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '37.3596000000000000',
      high: '38.9590000000000000',
      low: '36.9555000000000000',
      close: '37.7762000000000000',
      volume: '2433482.2970000000000000',
      quoteVolume: 92264806.14913891,
      btcVolume: 3158.8112751452077,
      usdVolume: 92264806.14913891,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '37.7637000000000000',
      high: '38.8798000000000000',
      low: '36.8000000000000000',
      close: '38.2331000000000000',
      volume: '3018062.2060000000000000',
      quoteVolume: 114060050.8496273,
      btcVolume: 3673.549570252766,
      usdVolume: 114060050.8496273,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '38.2331000000000000',
      high: '41.7017000000000000',
      low: '37.7630000000000000',
      close: '41.2575000000000000',
      volume: '4903626.6970000000000000',
      quoteVolume: 195224916.82053122,
      btcVolume: 5801.770817073169,
      usdVolume: 195224916.82053122,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '41.2560000000000000',
      high: '43.2039000000000000',
      low: '38.0200000000000000',
      close: '41.1333000000000000',
      volume: '5306248.1660000000000000',
      quoteVolume: 214835112.0872529,
      btcVolume: 6762.2209205324225,
      usdVolume: 214835112.0872529,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '41.1300000000000000',
      high: '41.8375000000000000',
      low: '38.8014000000000000',
      close: '41.8219000000000000',
      volume: '3513013.9910000000000000',
      quoteVolume: 141826591.3171311,
      btcVolume: 4386.074240907546,
      usdVolume: 141826591.3171311,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '41.8271000000000000',
      high: '42.2670000000000000',
      low: '40.4531000000000000',
      close: '42.2417000000000000',
      volume: '3784356.4310000000000000',
      quoteVolume: 156400986.59603542,
      btcVolume: 4502.298574139992,
      usdVolume: 156400986.59603542,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '42.2440000000000000',
      high: '44.9000000000000000',
      low: '41.3660000000000000',
      close: '43.5728000000000000',
      volume: '5010228.3040000000000000',
      quoteVolume: 216002626.83736652,
      btcVolume: 5709.586809164074,
      usdVolume: 216002626.83736652,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '43.5728000000000000',
      high: '43.7220000000000000',
      low: '40.2313000000000000',
      close: '42.3560000000000000',
      volume: '3545616.4530000000000000',
      quoteVolume: 149813003.67615464,
      btcVolume: 3783.9907502632377,
      usdVolume: 149813003.67615464,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '42.3450000000000000',
      high: '44.0552000000000000',
      low: '41.5000000000000000',
      close: '43.8479000000000000',
      volume: '2720363.6360000000000000',
      quoteVolume: 116290473.53861824,
      btcVolume: 2885.4307702783954,
      usdVolume: 116290473.53861824,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '43.8479000000000000',
      high: '45.1620000000000000',
      low: '40.0000000000000000',
      close: '42.4031000000000000',
      volume: '4276146.4430000000000000',
      quoteVolume: 185111910.32453433,
      btcVolume: 4685.988559488082,
      usdVolume: 185111910.32453433,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '42.4033000000000000',
      high: '42.5094000000000000',
      low: '35.0374000000000000',
      close: '38.1674000000000000',
      volume: '6282218.7140000000000000',
      quoteVolume: 241222935.06371692,
      btcVolume: 7090.358330981841,
      usdVolume: 241222935.06371692,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '38.1623000000000000',
      high: '40.1989000000000000',
      low: '37.0000000000000000',
      close: '38.2541000000000000',
      volume: '2967129.8990000000000000',
      quoteVolume: 114541059.98171715,
      btcVolume: 3279.5902550381034,
      usdVolume: 114541059.98171715,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '38.2703000000000000',
      high: '40.4499000000000000',
      low: '37.0200000000000000',
      close: '40.1114000000000000',
      volume: '3456796.7670000000000000',
      quoteVolume: 133704300.47653846,
      btcVolume: 3841.1434871509555,
      usdVolume: 133704300.47653846,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '40.1114000000000000',
      high: '42.0000000000000000',
      low: '39.5739000000000000',
      close: '41.8723000000000000',
      volume: '3306689.9330000000000000',
      quoteVolume: 135085613.30355036,
      btcVolume: 3524.8729501905395,
      usdVolume: 135085613.30355036,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '41.8721000000000000',
      high: '42.5622000000000000',
      low: '38.6616000000000000',
      close: '40.8668000000000000',
      volume: '3239226.4660000000000000',
      quoteVolume: 132807501.32071899,
      btcVolume: 3551.40548928815,
      usdVolume: 132807501.32071899,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '40.8951000000000000',
      high: '44.1200000000000000',
      low: '40.6250000000000000',
      close: '43.1420000000000000',
      volume: '3033653.3580000000000000',
      quoteVolume: 129106934.07833531,
      btcVolume: 3495.5013833004346,
      usdVolume: 129106934.07833531,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '43.1413000000000000',
      high: '46.7228000000000000',
      low: '40.9192000000000000',
      close: '45.8675000000000000',
      volume: '3878914.2990000000000000',
      quoteVolume: 169754085.7227777,
      btcVolume: 4749.843457538563,
      usdVolume: 169754085.7227777,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '45.8645000000000000',
      high: '46.8888000000000000',
      low: '44.0278000000000000',
      close: '45.3412000000000000',
      volume: '3069027.8490000000000000',
      quoteVolume: 139380249.91174093,
      btcVolume: 3868.6820587698926,
      usdVolume: 139380249.91174093,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '45.3592000000000000',
      high: '47.2187000000000000',
      low: '41.7000000000000000',
      close: '42.5241000000000000',
      volume: '5142469.4010000000000000',
      quoteVolume: 229041236.3572534,
      btcVolume: 6215.5026986066505,
      usdVolume: 229041236.3572534,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '42.5241000000000000',
      high: '43.0992000000000000',
      low: '40.2000000000000000',
      close: '42.6069000000000000',
      volume: '3707024.5780000000000000',
      quoteVolume: 154379904.5654336,
      btcVolume: 4404.916420772621,
      usdVolume: 154379904.5654336,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '42.6056000000000000',
      high: '42.7176000000000000',
      low: '38.2000000000000000',
      close: '38.6343000000000000',
      volume: '3460575.2420000000000000',
      quoteVolume: 140161330.8816147,
      btcVolume: 4278.077907472615,
      usdVolume: 140161330.8816147,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '38.6596000000000000',
      high: '41.5280000000000000',
      low: '36.8518000000000000',
      close: '40.8936000000000000',
      volume: '3045177.7070000000000000',
      quoteVolume: 120441457.89852692,
      btcVolume: 3837.1422348553915,
      usdVolume: 120441457.89852692,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '40.8938000000000000',
      high: '41.8800000000000000',
      low: '39.4555000000000000',
      close: '40.9109000000000000',
      volume: '2278214.3590000000000000',
      quoteVolume: 92739441.75976337,
      btcVolume: 2865.448972787932,
      usdVolume: 92739441.75976337,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '40.9113000000000000',
      high: '42.2000000000000000',
      low: '40.3144000000000000',
      close: '41.8263000000000000',
      volume: '2340747.6230000000000000',
      quoteVolume: 97017133.59785114,
      btcVolume: 3005.7392112630923,
      usdVolume: 97017133.59785114,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '41.8270000000000000',
      high: '43.4241000000000000',
      low: '40.5364000000000000',
      close: '41.1687000000000000',
      volume: '2518182.3520000000000000',
      quoteVolume: 106660129.78203705,
      btcVolume: 3193.7435879782656,
      usdVolume: 106660129.78203705,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '40.6902000000000000',
      high: '41.9990000000000000',
      low: '40.6495000000000000',
      close: '41.8396000000000000',
      volume: '569370.8780000000000000',
      quoteVolume: 23434513.457918085,
      btcVolume: 727.6668268505232,
      usdVolume: 23434513.457918085,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '41.8397000000000000',
      high: '42.0984000000000000',
      low: '39.6102000000000000',
      close: '40.8826000000000000',
      volume: '2436860.8560000000000000',
      quoteVolume: 99920228.95624547,
      btcVolume: 3215.2310783315247,
      usdVolume: 99920228.95624547,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '40.8957000000000000',
      high: '43.0850000000000000',
      low: '40.3170000000000000',
      close: '42.5254000000000000',
      volume: '2696452.7010000000000000',
      quoteVolume: 113671095.32156354,
      btcVolume: 3581.5448326085157,
      usdVolume: 113671095.32156354,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '42.5277000000000000',
      high: '44.0000000000000000',
      low: '41.8017000000000000',
      close: '42.8618000000000000',
      volume: '3128425.9590000000000000',
      quoteVolume: 134263033.31381023,
      btcVolume: 3835.6490017488704,
      usdVolume: 134263033.31381023,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '42.8653000000000000',
      high: '45.1071000000000000',
      low: '42.5911000000000000',
      close: '44.7849000000000000',
      volume: '2684982.5910000000000000',
      quoteVolume: 118195712.16575812,
      btcVolume: 3475.2585019044272,
      usdVolume: 118195712.16575812,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '44.8022000000000000',
      high: '45.8269000000000000',
      low: '43.3000000000000000',
      close: '44.3292000000000000',
      volume: '2275614.1730000000000000',
      quoteVolume: 101023763.14275552,
      btcVolume: 3017.434459535125,
      usdVolume: 101023763.14275552,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '44.3291000000000000',
      high: '52.3736000000000000',
      low: '43.4331000000000000',
      close: '51.4781000000000000',
      volume: '6050100.3010000000000000',
      quoteVolume: 292440579.7585255,
      btcVolume: 8664.193157549942,
      usdVolume: 292440579.7585255,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '51.4762000000000000',
      high: '53.0000000000000000',
      low: '49.1572000000000000',
      close: '51.0071000000000000',
      volume: '4671921.8650000000000000',
      quoteVolume: 237462302.3806651,
      btcVolume: 6853.326195790986,
      usdVolume: 237462302.3806651,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '51.0290000000000000',
      high: '52.7000000000000000',
      low: '49.7823000000000000',
      close: '52.1200000000000000',
      volume: '2949735.0360000000000000',
      quoteVolume: 150776117.80316824,
      btcVolume: 4132.7211784957935,
      usdVolume: 150776117.80316824,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '52.1200000000000000',
      high: '57.0547000000000000',
      low: '51.2500000000000000',
      close: '55.9599000000000000',
      volume: '4004728.2130000000000000',
      quoteVolume: 215001574.4533509,
      btcVolume: 5736.324770655654,
      usdVolume: 215001574.4533509,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '55.9593000000000000',
      high: '68.7300000000000000',
      low: '55.9202000000000000',
      close: '67.7005000000000000',
      volume: '5033214.7830000000000000',
      quoteVolume: 306083346.7708751,
      btcVolume: 8127.166722306979,
      usdVolume: 306083346.7708751,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '67.6217000000000000',
      high: '75.5000000000000000',
      low: '61.7000000000000000',
      close: '72.8356000000000000',
      volume: '8481923.5090000000000000',
      quoteVolume: 595939416.8906882,
      btcVolume: 15020.336224524533,
      usdVolume: 595939416.8906882,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '72.8693000000000000',
      high: '74.8199000000000000',
      low: '64.1803000000000000',
      close: '68.3542000000000000',
      volume: '5969908.4420000000000000',
      quoteVolume: 413359634.3580266,
      btcVolume: 10704.071358881154,
      usdVolume: 413359634.3580266,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '68.3550000000000000',
      high: '79.8823000000000000',
      low: '67.6152000000000000',
      close: '79.8374000000000000',
      volume: '7849448.2670000000000000',
      quoteVolume: 573011026.0739279,
      btcVolume: 14013.22524313177,
      usdVolume: 573011026.0739279,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '79.8734000000000000',
      high: '116.2400000000000000',
      low: '78.0381000000000000',
      close: '107.3469000000000000',
      volume: '12487305.8680000000000000',
      quoteVolume: 1222317019.460528,
      btcVolume: 26192.431070371436,
      usdVolume: 1222317019.460528,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '107.3496000000000000',
      high: '148.4000000000000000',
      low: '107.2811000000000000',
      close: '128.9711000000000000',
      volume: '16613534.2740000000000000',
      quoteVolume: 2139218096.5095875,
      btcVolume: 46624.18677740852,
      usdVolume: 2138674058.9751093,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '128.9463000000000000',
      high: '132.8000000000000000',
      low: '117.7289000000000000',
      close: '124.0850000000000000',
      volume: '5912684.9520000000000000',
      quoteVolume: 736389166.0586556,
      btcVolume: 15887.557250176676,
      usdVolume: 736389166.0586556,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '124.1129000000000000',
      high: '141.3200000000000000',
      low: '120.8900000000000000',
      close: '136.6139000000000000',
      volume: '5624105.4490000000000000',
      quoteVolume: 739818687.5567763,
      btcVolume: 15573.408733004304,
      usdVolume: 739734387.6189897,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '136.7321000000000000',
      high: '138.5067000000000000',
      low: '125.0000000000000000',
      close: '133.3496000000000000',
      volume: '3785723.4020000000000000',
      quoteVolume: 497454132.3754859,
      btcVolume: 10550.521716952037,
      usdVolume: 497442916.39831746,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '133.3498000000000000',
      high: '139.0000000000000000',
      low: '128.4615000000000000',
      close: '135.8623000000000000',
      volume: '4149634.1350000000000000',
      quoteVolume: 553817439.5471048,
      btcVolume: 11381.352118436944,
      usdVolume: 553817439.5471048,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '135.8623000000000000',
      high: '139.3690000000000000',
      low: '118.0000000000000000',
      close: '130.3030000000000000',
      volume: '4726200.1690000000000000',
      quoteVolume: 610939861.0625807,
      btcVolume: 12798.537422520802,
      usdVolume: 608891488.1794219,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '129.9493000000000000',
      high: '133.4925000000000000',
      low: '126.0000000000000000',
      close: '130.1830000000000000',
      volume: '3253115.9180000000000000',
      quoteVolume: 422971528.8945765,
      btcVolume: 8473.804158435485,
      usdVolume: 413215114.4048722,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '130.1830000000000000',
      high: '175.9997000000000000',
      low: '126.7546000000000000',
      close: '165.3233000000000000',
      volume: '10095433.2290000000000000',
      quoteVolume: 1529265397.2071304,
      btcVolume: 29733.66977060965,
      usdVolume: 1529265397.2071304,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '165.3176000000000000',
      high: '199.8800000000000000',
      low: '162.7969000000000000',
      close: '195.6000000000000000',
      volume: '11226893.3730000000000000',
      quoteVolume: 2093727364.8791077,
      btcVolume: 40384.559838438676,
      usdVolume: 2093727364.8791077,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '195.5549000000000000',
      high: '348.6969000000000000',
      low: '194.9279000000000000',
      close: '333.1110000000000000',
      volume: '20177086.7240000000000000',
      quoteVolume: 5524988273.532031,
      btcVolume: 104550.88500921716,
      usdVolume: 5524988273.532031,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '333.2212000000000000',
      high: '335.0000000000000000',
      low: '223.0000000000000000',
      close: '253.0000000000000000',
      volume: '13493080.4310000000000000',
      quoteVolume: 3802302297.1180034,
      btcVolume: 67696.97645332622,
      usdVolume: 3802255523.1218314,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '253.0100000000000000',
      high: '309.4995000000000000',
      low: '249.7044000000000000',
      close: '295.2597000000000000',
      volume: '10646422.3070000000000000',
      quoteVolume: 3016515097.588496,
      btcVolume: 53004.9096656135,
      usdVolume: 3016515097.588496,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '294.9167000000000000',
      high: '296.0000000000000000',
      low: '220.5000000000000000',
      close: '267.5147000000000000',
      volume: '11090199.9140000000000000',
      quoteVolume: 2901115898.821675,
      btcVolume: 51481.76655921517,
      usdVolume: 2776214823.2922387,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '267.1721000000000000',
      high: '268.8000000000000000',
      low: '188.8195000000000000',
      close: '231.3646000000000000',
      volume: '14894899.8590000000000000',
      quoteVolume: 3323972236.903524,
      btcVolume: 67971.97939281193,
      usdVolume: 3306278943.257356,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '231.3646000000000000',
      high: '281.0000000000000000',
      low: '213.0257000000000000',
      close: '254.5056000000000000',
      volume: '9115851.7520000000000000',
      quoteVolume: 2299513536.8925033,
      btcVolume: 45958.25770110001,
      usdVolume: 2299401919.088233,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '254.3826000000000000',
      high: '265.4014000000000000',
      low: '231.5000000000000000',
      close: '234.9146000000000000',
      volume: '6871992.7940000000000000',
      quoteVolume: 1718504824.021201,
      btcVolume: 34352.22898526557,
      usdVolume: 1718504824.021201,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '234.7605000000000000',
      high: '239.4000000000000000',
      low: '209.1050000000000000',
      close: '221.7170000000000000',
      volume: '7410706.3850000000000000',
      quoteVolume: 1673740236.886314,
      btcVolume: 35915.41505900414,
      usdVolume: 1672629199.376214,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '221.9592000000000000',
      high: '237.7700000000000000',
      low: '219.0110000000000000',
      close: '225.3853000000000000',
      volume: '4192697.1520000000000000',
      quoteVolume: 956073854.7960905,
      btcVolume: 20310.25188922906,
      usdVolume: 956073854.7960905,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '225.4567000000000000',
      high: '229.4000000000000000',
      low: '195.0000000000000000',
      close: '210.2138000000000000',
      volume: '5582387.4930000000000000',
      quoteVolume: 1169488220.755494,
      btcVolume: 26195.665345367866,
      usdVolume: 1169488220.755494,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '210.1177000000000000',
      high: '260.0000000000000000',
      low: '209.1204000000000000',
      close: '254.9637000000000000',
      volume: '7696473.1020000000000000',
      quoteVolume: 1861423426.5445766,
      btcVolume: 38996.01528997843,
      usdVolume: 1861423426.5445766,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '254.9486000000000000',
      high: '264.9000000000000000',
      low: '227.0000000000000000',
      close: '239.6841000000000000',
      volume: '5386013.7940000000000000',
      quoteVolume: 1322863990.4305365,
      btcVolume: 27229.610061705047,
      usdVolume: 1322863990.4305365,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '239.7204000000000000',
      high: '254.8481000000000000',
      low: '236.0020000000000000',
      close: '240.5701000000000000',
      volume: '4213922.8000000000000000',
      quoteVolume: 1038796903.290087,
      btcVolume: 20561.198898104394,
      usdVolume: 1038796903.290087,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '240.4801000000000000',
      high: '249.9885000000000000',
      low: '225.5308000000000000',
      close: '229.6369000000000000',
      volume: '3999983.8360000000000000',
      quoteVolume: 951344257.2707027,
      btcVolume: 19254.739130732472,
      usdVolume: 950963632.5327258,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '229.6369000000000000',
      high: '232.2000000000000000',
      low: '215.6439000000000000',
      close: '225.4714000000000000',
      volume: '3654842.8050000000000000',
      quoteVolume: 816218663.6047677,
      btcVolume: 17130.43983808357,
      usdVolume: 816218663.6047677,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '225.5391000000000000',
      high: '232.4996000000000000',
      low: '219.1514000000000000',
      close: '226.3814000000000000',
      volume: '2540451.2780000000000000',
      quoteVolume: 572783201.0439407,
      btcVolume: 11728.787027795814,
      usdVolume: 566811264.0986007,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '226.3812000000000000',
      high: '243.9500000000000000',
      low: '224.9542000000000000',
      close: '240.1100000000000000',
      volume: '3519962.9290000000000000',
      quoteVolume: 825683976.9668484,
      btcVolume: 16392.968709891094,
      usdVolume: 825683976.9668484,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '240.1039000000000000',
      high: '243.0986000000000000',
      low: '228.7029000000000000',
      close: '240.5509000000000000',
      volume: '2974749.0600000000000000',
      quoteVolume: 700594381.5475962,
      btcVolume: 13790.190438705486,
      usdVolume: 700594381.5475962,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '240.7852000000000000',
      high: '297.2962000000000000',
      low: '235.6001000000000000',
      close: '293.2052000000000000',
      volume: '7349183.8380000000000000',
      quoteVolume: 1967801660.5323133,
      btcVolume: 36388.01138608123,
      usdVolume: 1967801660.5323133,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '293.1305000000000000',
      high: '295.5000000000000000',
      low: '263.8184000000000000',
      close: '278.4499000000000000',
      volume: '5653640.2100000000000000',
      quoteVolume: 1590670836.0511584,
      btcVolume: 28815.964844856844,
      usdVolume: 1590533270.0117154,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '278.3522000000000000',
      high: '315.2855000000000000',
      low: '272.1200000000000000',
      close: '290.0301000000000000',
      volume: '6597122.9060000000000000',
      quoteVolume: 1920751069.112857,
      btcVolume: 34050.75363516682,
      usdVolume: 1920751069.112857,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '289.7860000000000000',
      high: '290.5000000000000000',
      low: '247.5547000000000000',
      close: '264.2515000000000000',
      volume: '5733513.3280000000000000',
      quoteVolume: 1549981975.0642865,
      btcVolume: 18321.480693181016,
      usdVolume: 1039794507.6320997,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '260.1943000000000000',
      high: '284.2857000000000000',
      low: '255.4206000000000000',
      close: '276.2822000000000000',
      volume: '3870240.0390000000000000',
      quoteVolume: 1055956835.0145825,
      btcVolume: 17918.280534933638,
      usdVolume: 1055956835.0145825,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '276.2892000000000000',
      high: '276.8847000000000000',
      low: '263.0003000000000000',
      close: '263.2380000000000000',
      volume: '2426314.6890000000000000',
      quoteVolume: 655148936.6244737,
      btcVolume: 10820.477528826821,
      usdVolume: 654442187.9202651,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '263.3500000000000000',
      high: '267.7453000000000000',
      low: '249.5327000000000000',
      close: '254.5144000000000000',
      volume: '3362729.4940000000000000',
      quoteVolume: 865777537.7956617,
      btcVolume: 15099.63548641119,
      usdVolume: 865777537.7956617,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '254.3894000000000000',
      high: '259.7847000000000000',
      low: '240.1990000000000000',
      close: '258.5352000000000000',
      volume: '2873578.7750000000000000',
      quoteVolume: 724203640.2983155,
      btcVolume: 13111.127043243541,
      usdVolume: 723730256.9268228,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '258.5611000000000000',
      high: '272.1787000000000000',
      low: '248.2001000000000000',
      close: '269.7896000000000000',
      volume: '2840597.4750000000000000',
      quoteVolume: 730345547.8462642,
      btcVolume: 12972.881070043122,
      usdVolume: 730345547.8462642,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '269.8578000000000000',
      high: '277.8000000000000000',
      low: '257.0100000000000000',
      close: '261.4816000000000000',
      volume: '3151815.5090000000000000',
      quoteVolume: 849421790.8761196,
      btcVolume: 14510.926635933718,
      usdVolume: 849416699.5951898,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '261.5157000000000000',
      high: '269.0000000000000000',
      low: '255.0000000000000000',
      close: '262.9350000000000000',
      volume: '2162339.2940000000000000',
      quoteVolume: 571656223.3764387,
      btcVolume: 9795.650695015467,
      usdVolume: 570353586.1848109,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '263.1083000000000000',
      high: '274.6805000000000000',
      low: '261.4000000000000000',
      close: '264.3023000000000000',
      volume: '2167591.3950000000000000',
      quoteVolume: 581172465.4111629,
      btcVolume: 9881.756043643418,
      usdVolume: 581150401.0430868,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '264.2213000000000000',
      high: '268.0305000000000000',
      low: '257.0001000000000000',
      close: '264.6513000000000000',
      volume: '1969870.0700000000000000',
      quoteVolume: 519208676.9906088,
      btcVolume: 9066.830743937264,
      usdVolume: 518831620.6721869,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '264.7152000000000000',
      high: '277.0000000000000000',
      low: '252.9164000000000000',
      close: '254.7976000000000000',
      volume: '3264577.5660000000000000',
      quoteVolume: 867106025.9929574,
      btcVolume: 15341.116908069982,
      usdVolume: 866971561.9590058,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '254.9689000000000000',
      high: '262.9700000000000000',
      low: '251.8380000000000000',
      close: '255.4646000000000000',
      volume: '2572459.8170000000000000',
      quoteVolume: 663206251.7188904,
      btcVolume: 8225.60529805851,
      usdVolume: 447969721.01421326,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '255.5191000000000000',
      high: '268.7062000000000000',
      low: '240.3333000000000000',
      close: '248.5236000000000000',
      volume: '3077671.5470000000000000',
      quoteVolume: 793614390.5674702,
      btcVolume: 14422.352218746142,
      usdVolume: 793614390.5674702,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '248.5345000000000000',
      high: '250.0912000000000000',
      low: '224.9686000000000000',
      close: '233.9466000000000000',
      volume: '3206750.4610000000000000',
      quoteVolume: 761130676.4045202,
      btcVolume: 13047.060000011228,
      usdVolume: 676406281.7791395,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '234.0024000000000000',
      high: '255.2113000000000000',
      low: '233.8114000000000000',
      close: '255.2105000000000000',
      volume: '2245802.5640000000000000',
      quoteVolume: 556008625.4021944,
      btcVolume: 10101.506782457785,
      usdVolume: 538894875.840913,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '255.1201000000000000',
      high: '276.0000000000000000',
      low: '249.5001000000000000',
      close: '269.2234000000000000',
      volume: '3089181.8480000000000000',
      quoteVolume: 804732179.3560896,
      btcVolume: 14561.461272689408,
      usdVolume: 804579787.2442447,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '269.2864000000000000',
      high: '281.3939000000000000',
      low: '261.0000000000000000',
      close: '268.4263000000000000',
      volume: '3272613.7550000000000000',
      quoteVolume: 888837689.7422438,
      btcVolume: 15905.983283637139,
      usdVolume: 888769684.6338859,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '268.6234000000000000',
      high: '277.9813000000000000',
      low: '264.4585000000000000',
      close: '275.2991000000000000',
      volume: '2718538.8020000000000000',
      quoteVolume: 741093981.6902637,
      btcVolume: 13018.42468453984,
      usdVolume: 741031453.2366232,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '275.2986000000000000',
      high: '311.5325000000000000',
      low: '271.2857000000000000',
      close: '311.2962000000000000',
      volume: '5532768.0410000000000000',
      quoteVolume: 1633393475.0102596,
      btcVolume: 27920.656657466523,
      usdVolume: 1633357160.421257,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '311.2962000000000000',
      high: '316.6997000000000000',
      low: '284.0000000000000000',
      close: '302.3724000000000000',
      volume: '4719335.5060000000000000',
      quoteVolume: 1432519744.2660277,
      btcVolume: 24381.244738004352,
      usdVolume: 1428700884.4968865,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '302.4591000000000000',
      high: '339.0000000000000000',
      low: '300.7000000000000000',
      close: '334.7991000000000000',
      volume: '4332111.9370000000000000',
      quoteVolume: 1384341137.684506,
      btcVolume: 23482.89919447801,
      usdVolume: 1382271214.156847,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '334.6706000000000000',
      high: '356.9882000000000000',
      low: '324.6162000000000000',
      close: '338.2932000000000000',
      volume: '4071170.6560000000000000',
      quoteVolume: 1403027066.2804952,
      btcVolume: 23692.93398872393,
      usdVolume: 1402782243.379352,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '338.2932000000000000',
      high: '349.1347000000000000',
      low: '315.9100000000000000',
      close: '321.9497000000000000',
      volume: '3098374.7850000000000000',
      quoteVolume: 1044133678.7217119,
      btcVolume: 10038.83808275111,
      usdVolume: 591404806.9357758,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '321.9597000000000000',
      high: '352.3275000000000000',
      low: '316.0000000000000000',
      close: '348.8617000000000000',
      volume: '2648776.3950000000000000',
      quoteVolume: 902098257.0088232,
      btcVolume: 13702.133872871518,
      usdVolume: 791916397.8945813,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '348.8705000000000000',
      high: '380.0000000000000000',
      low: '335.8442000000000000',
      close: '368.0263000000000000',
      volume: '3431868.4070000000000000',
      quoteVolume: 1236127810.4175525,
      btcVolume: 17901.65290795174,
      usdVolume: 1037725357.78694,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '368.1506000000000000',
      high: '411.7550000000000000',
      low: '363.8333000000000000',
      close: '403.3648000000000000',
      volume: '4239882.7000000000000000',
      quoteVolume: 1648279064.5724006,
      btcVolume: 26531.19803700832,
      usdVolume: 1548692540.941828,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '403.2607000000000000',
      high: '414.3803000000000000',
      low: '356.0000000000000000',
      close: '375.5538000000000000',
      volume: '6153246.9340000000000000',
      quoteVolume: 2372758767.1935678,
      btcVolume: 40716.89375916033,
      usdVolume: 2320206435.050474,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '375.5538000000000000',
      high: '422.6323000000000000',
      low: '371.7800000000000000',
      close: '418.7048000000000000',
      volume: '4010180.6390000000000000',
      quoteVolume: 1625423876.1601589,
      btcVolume: 18000.290159044805,
      usdVolume: 1028345774.5132892,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '418.7047000000000000',
      high: '453.9937000000000000',
      low: '403.1900000000000000',
      close: '453.9937000000000000',
      volume: '3392014.2090000000000000',
      quoteVolume: 1464479256.1346753,
      btcVolume: 22466.754948146387,
      usdVolume: 1309044489.8936975,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '453.9898000000000000',
      high: '487.4062000000000000',
      low: '450.0000000000000000',
      close: '471.2975000000000000',
      volume: '4659107.2120000000000000',
      quoteVolume: 2200226600.979961,
      btcVolume: 36213.41070222386,
      usdVolume: 2165238340.1851015,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '471.2974000000000000',
      high: '529.2110000000000000',
      low: '458.0165000000000000',
      close: '525.9275000000000000',
      volume: '3644396.1100000000000000',
      quoteVolume: 1776698304.8045623,
      btcVolume: 29506.82991366972,
      usdVolume: 1764632035.1456206,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '525.9275000000000000',
      high: '638.5673000000000000',
      low: '515.1000000000000000',
      close: '596.7104000000000000',
      volume: '9111902.9720000000000000',
      quoteVolume: 5245816431.400061,
      btcVolume: 63257.127669028734,
      usdVolume: 3806149366.686823,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '596.7878000000000000',
      high: '602.7871000000000000',
      low: '530.2288000000000000',
      close: '551.6442000000000000',
      volume: '5211856.8490000000000000',
      quoteVolume: 2928039699.6231527,
      btcVolume: 46855.84817376075,
      usdVolume: 2904056644.2781954,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '551.3956000000000000',
      high: '590.5369000000000000',
      low: '521.1313000000000000',
      close: '550.0182000000000000',
      volume: '4141516.3380000000000000',
      quoteVolume: 2316332649.32106,
      btcVolume: 32494.32817460017,
      usdVolume: 2063377293.09785,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '550.0182000000000000',
      high: '556.9000000000000000',
      low: '533.7166000000000000',
      close: '541.8824000000000000',
      volume: '2306847.3860000000000000',
      quoteVolume: 1261788213.6561053,
      btcVolume: 18450.056652820283,
      usdVolume: 1159651151.0654097,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '541.8351000000000000',
      high: '547.6074000000000000',
      low: '489.7356000000000000',
      close: '508.2743000000000000',
      volume: '4192215.1750000000000000',
      quoteVolume: 2179385039.089873,
      btcVolume: 33322.010808070954,
      usdVolume: 2049560218.6198566,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '508.2614000000000000',
      high: '547.9500000000000000',
      low: '506.0000000000000000',
      close: '514.6861000000000000',
      volume: '2949039.8180000000000000',
      quoteVolume: 1548814241.88043,
      btcVolume: 16718.141931258793,
      usdVolume: 1024252858.6623896,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '514.8178000000000000',
      high: '520.4237000000000000',
      low: '428.0000000000000000',
      close: '481.4367000000000000',
      volume: '4049186.4060000000000000',
      quoteVolume: 1904645753.8034728,
      btcVolume: 33628.949170106855,
      usdVolume: 1876226556.6125178,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '481.5112000000000000',
      high: '540.0000000000000000',
      low: '467.8846000000000000',
      close: '504.0322000000000000',
      volume: '5031304.0290000000000000',
      quoteVolume: 2572847032.948682,
      btcVolume: 37508.47886493883,
      usdVolume: 2114776432.2070198,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '504.0322000000000000',
      high: '589.5000000000000000',
      low: '470.2070000000000000',
      close: '586.3635000000000000',
      volume: '5715927.6350000000000000',
      quoteVolume: 3058354760.9721446,
      btcVolume: 53121.18738860772,
      usdVolume: 2963040031.251067,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '586.5201000000000000',
      high: '613.8975000000000000',
      low: '535.9186000000000000',
      close: '543.6327000000000000',
      volume: '5415834.4300000000000000',
      quoteVolume: 3148647997.53806,
      btcVolume: 49070.86389573589,
      usdVolume: 2723704942.1352196,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '543.6827000000000000',
      high: '582.7051000000000000',
      low: '480.0000000000000000',
      close: '505.2411000000000000',
      volume: '5711241.6150000000000000',
      quoteVolume: 3087134504.9510384,
      btcVolume: 16336.707809710622,
      usdVolume: 865615640.8242787,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '505.2282000000000000',
      high: '530.0000000000000000',
      low: '453.1000000000000000',
      close: '526.5357000000000000',
      volume: '6415439.4970000000000000',
      quoteVolume: 3176588289.098144,
      btcVolume: 33856.082810714746,
      usdVolume: 1686441255.1318297,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '526.4319000000000000',
      high: '528.8088000000000000',
      low: '482.3052000000000000',
      close: '496.9920000000000000',
      volume: '2969978.4340000000000000',
      quoteVolume: 1502798926.917138,
      btcVolume: 28745.678183221087,
      usdVolume: 1432476977.467932,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '496.9920000000000000',
      high: '517.7400000000000000',
      low: '478.0100000000000000',
      close: '505.0000000000000000',
      volume: '2352878.9486000000000000',
      quoteVolume: 1177965823.3087528,
      btcVolume: 12577.589728470612,
      usdVolume: 623023504.0575972,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '505.0000000000000000',
      high: '550.0000000000000000',
      low: '501.5000000000000000',
      close: '535.3300000000000000',
      volume: '2655262.1521000000000000',
      quoteVolume: 1411293600.6102023,
      btcVolume: 15480.36818125127,
      usdVolume: 815757282.7136099,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '535.3300000000000000',
      high: '579.5000000000000000',
      low: '525.0000000000000000',
      close: '568.7800000000000000',
      volume: '2807308.6198000000000000',
      quoteVolume: 1565140057.7051094,
      btcVolume: 28300.124067514644,
      usdVolume: 1546267212.3260415,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '568.7800000000000000',
      high: '578.8800000000000000',
      low: '538.0000000000000000',
      close: '562.7800000000000000',
      volume: '2384511.0120000000000000',
      quoteVolume: 1333085756.448968,
      btcVolume: 24357.758243790056,
      usdVolume: 1333085756.448968,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '562.7800000000000000',
      high: '615.2900000000000000',
      low: '554.0000000000000000',
      close: '599.7000000000000000',
      volume: '4242963.4049000000000000',
      quoteVolume: 2481862298.554591,
      btcVolume: 46006.51699559538,
      usdVolume: 2481862298.554591,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '599.6600000000000000',
      high: '628.0000000000000000',
      low: '585.7200000000000000',
      close: '622.6500000000000000',
      volume: '3379163.2196000000000000',
      quoteVolume: 2063664584.472258,
      btcVolume: 25172.717745086713,
      usdVolume: 1386220097.2369685,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '622.6400000000000000',
      high: '645.5700000000000000',
      low: '609.2100000000000000',
      close: '620.0000000000000000',
      volume: '2427568.3909000000000000',
      quoteVolume: 1516905856.244385,
      btcVolume: 12446.095949705987,
      usdVolume: 719848206.3838052,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '620.0000000000000000',
      high: '629.0000000000000000',
      low: '595.3000000000000000',
      close: '621.7800000000000000',
      volume: '2066188.5104000000000000',
      quoteVolume: 1273393608.5696151,
      btcVolume: 14374.383604305296,
      usdVolume: 815986629.5360534,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '621.7700000000000000',
      high: '680.0000000000000000',
      low: '621.2200000000000000',
      close: '676.1500000000000000',
      volume: '2873379.6170000000000000',
      quoteVolume: 1872406454.7114303,
      btcVolume: 31523.29798707986,
      usdVolume: 1827102859.2419474,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '676.1500000000000000',
      high: '677.7100000000000000',
      low: '604.1400000000000000',
      close: '609.3000000000000000',
      volume: '3760446.5392000000000000',
      quoteVolume: 2397311746.8970165,
      btcVolume: 34681.93696052413,
      usdVolume: 1922027889.6555834,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '609.4100000000000000',
      high: '656.6700000000000000',
      low: '605.0000000000000000',
      close: '651.1000000000000000',
      volume: '2108343.7681000000000000',
      quoteVolume: 1350489029.914887,
      btcVolume: 22376.029864647935,
      usdVolume: 1245585308.8986058,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '651.1000000000000000',
      high: '653.7700000000000000',
      low: '620.0000000000000000',
      close: '633.9900000000000000',
      volume: '2027391.4345000000000000',
      quoteVolume: 1294526643.8860967,
      btcVolume: 22104.33571757016,
      usdVolume: 1257147022.1981807,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '634.0300000000000000',
      high: '644.0200000000000000',
      low: '611.4300000000000000',
      close: '624.3000000000000000',
      volume: '1979433.7807000000000000',
      quoteVolume: 1238888054.2225192,
      btcVolume: 21423.691802310528,
      usdVolume: 1214310669.4553754,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '624.3000000000000000',
      high: '657.9100000000000000',
      low: '618.3600000000000000',
      close: '646.1000000000000000',
      volume: '2185770.9717000000000000',
      quoteVolume: 1395759313.3025906,
      btcVolume: 23506.98439331287,
      usdVolume: 1375162536.6888602,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '646.0700000000000000',
      high: '679.0000000000000000',
      low: '630.5400000000000000',
      close: '661.7400000000000000',
      volume: '2343400.2966000000000000',
      quoteVolume: 1543793211.4605246,
      btcVolume: 25883.63462490665,
      usdVolume: 1497580524.9378126,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '661.7400000000000000',
      high: '691.7700000000000000',
      low: '588.0000000000000000',
      close: '631.1000000000000000',
      volume: '3000221.4265000000000000',
      quoteVolume: 1974492506.488098,
      btcVolume: 23776.338284261044,
      usdVolume: 1357118070.6740103,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '631.1600000000000000',
      high: '673.0000000000000000',
      low: '621.1100000000000000',
      close: '671.9100000000000000',
      volume: '2559194.2717000000000000',
      quoteVolume: 1669252689.2629402,
      btcVolume: 23284.48219498061,
      usdVolume: 1299085092.4906397,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '671.9000000000000000',
      high: '684.9100000000000000',
      low: '581.4000000000000000',
      close: '590.0000000000000000',
      volume: '2807361.4119000000000000',
      quoteVolume: 1818252882.3973007,
      btcVolume: 32752.382692644092,
      usdVolume: 1796910641.5199373,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '594.2000000000000000',
      high: '633.5000000000000000',
      low: '526.6000000000000000',
      close: '571.1000000000000000',
      volume: '5067197.8665000000000000',
      quoteVolume: 2965262611.7093215,
      btcVolume: 57817.7131586063,
      usdVolume: 2860884730.229648,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '571.1000000000000000',
      high: '615.0000000000000000',
      low: '566.0000000000000000',
      close: '602.6100000000000000',
      volume: '2185834.5291000000000000',
      quoteVolume: 1300235538.911767,
      btcVolume: 25346.497714855974,
      usdVolume: 1270717338.95908,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '602.6100000000000000',
      high: '611.3100000000000000',
      low: '560.0000000000000000',
      close: '563.4100000000000000',
      volume: '2259793.5825000000000000',
      quoteVolume: 1322248759.3872828,
      btcVolume: 26733.85650391243,
      usdVolume: 1302373374.1391342,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '563.4200000000000000',
      high: '606.7800000000000000',
      low: '534.8800000000000000',
      close: '564.3200000000000000',
      volume: '2935802.4611000000000000',
      quoteVolume: 1676575346.1177924,
      btcVolume: 34768.195828266646,
      usdVolume: 1641174937.546295,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '564.6600000000000000',
      high: '564.6600000000000000',
      low: '483.0000000000000000',
      close: '512.0700000000000000',
      volume: '4002372.8778000000000000',
      quoteVolume: 2073223636.3413508,
      btcVolume: 45783.614024130424,
      usdVolume: 2011961037.140912,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '512.0600000000000000',
      high: '534.0000000000000000',
      low: '490.0000000000000000',
      close: '507.8800000000000000',
      volume: '2720458.6730000000000000',
      quoteVolume: 1404074857.8504837,
      btcVolume: 31414.796893148603,
      usdVolume: 1382388016.6856706,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '507.9000000000000000',
      high: '516.5000000000000000',
      low: '280.0000000000000000',
      close: '334.8000000000000000',
      volume: '8239380.7045000000000000',
      quoteVolume: 3344112007.75396,
      btcVolume: 72337.91025415438,
      usdVolume: 2827850240.61419,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '334.8000000000000000',
      high: '423.8300000000000000',
      low: '284.2700000000000000',
      close: '391.1700000000000000',
      volume: '8115885.0027000000000000',
      quoteVolume: 2937254802.220806,
      btcVolume: 60130.470531550855,
      usdVolume: 2366728450.1871305,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '391.3700000000000000',
      high: '415.7100000000000000',
      low: '274.0000000000000000',
      close: '326.0100000000000000',
      volume: '7320106.3957000000000000',
      quoteVolume: 2518202198.2935557,
      btcVolume: 49722.66139396216,
      usdVolume: 1903529428.7297347,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '326.2300000000000000',
      high: '335.8200000000000000',
      low: '284.5500000000000000',
      close: '299.7600000000000000',
      volume: '5296110.3904000000000000',
      quoteVolume: 1644509563.62935,
      btcVolume: 28543.884802395307,
      usdVolume: 1058397270.464872,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '299.7500000000000000',
      high: '313.4500000000000000',
      low: '211.7000000000000000',
      close: '260.7200000000000000',
      volume: '8801148.1832000000000000',
      quoteVolume: 2229226334.873108,
      btcVolume: 63281.297938914686,
      usdVolume: 2178774483.491753,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '260.7200000000000000',
      high: '350.0000000000000000',
      low: '257.4000000000000000',
      close: '345.6400000000000000',
      volume: '7781959.4786000000000000',
      quoteVolume: 2368109550.148683,
      btcVolume: 62642.28704122964,
      usdVolume: 2327105512.6887536,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '345.6400000000000000',
      high: '378.1200000000000000',
      low: '306.6100000000000000',
      close: '343.1800000000000000',
      volume: '7638986.0132000000000000',
      quoteVolume: 2568468246.8690605,
      btcVolume: 66152.31977044823,
      usdVolume: 2514295964.407211,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '343.1800000000000000',
      high: '388.2800000000000000',
      low: '336.2100000000000000',
      close: '379.1600000000000000',
      volume: '5258751.3486000000000000',
      quoteVolume: 1933572793.550551,
      btcVolume: 48379.41805278539,
      usdVolume: 1900748427.6240995,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '379.1600000000000000',
      high: '387.5600000000000000',
      low: '340.0000000000000000',
      close: '369.6700000000000000',
      volume: '4275801.5014000000000000',
      quoteVolume: 1564221587.8316987,
      btcVolume: 40027.51492212167,
      usdVolume: 1551370014.1647067,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '369.8300000000000000',
      high: '373.6800000000000000',
      low: '312.3000000000000000',
      close: '328.8200000000000000',
      volume: '5253443.3619000000000000',
      quoteVolume: 1767981944.1573837,
      btcVolume: 47664.24020697601,
      usdVolume: 1739093359.1059759,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '328.8600000000000000',
      high: '347.0000000000000000',
      low: '295.2200000000000000',
      close: '305.0400000000000000',
      volume: '3763011.1068000000000000',
      quoteVolume: 1207079531.3800404,
      btcVolume: 33934.25604475658,
      usdVolume: 1191835011.423594,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '305.1800000000000000',
      high: '338.3200000000000000',
      low: '291.0600000000000000',
      close: '325.5700000000000000',
      volume: '3737180.6229000000000000',
      quoteVolume: 1203296649.3056147,
      btcVolume: 33941.50714115873,
      usdVolume: 1201181411.5601633,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '325.5500000000000000',
      high: '356.0000000000000000',
      low: '307.5500000000000000',
      close: '353.3300000000000000',
      volume: '4100337.3521000000000000',
      quoteVolume: 1373992157.7018433,
      btcVolume: 37865.87542025713,
      usdVolume: 1369885540.0723953,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '353.3700000000000000',
      high: '364.4000000000000000',
      low: '339.4800000000000000',
      close: '362.3900000000000000',
      volume: '3509428.1657000000000000',
      quoteVolume: 1234613133.2643874,
      btcVolume: 33299.87059281471,
      usdVolume: 1220996431.8403883,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '362.3600000000000000',
      high: '419.4300000000000000',
      low: '350.7100000000000000',
      close: '401.5400000000000000',
      volume: '4195383.3566000000000000',
      quoteVolume: 1645840910.622663,
      btcVolume: 43851.300647727745,
      usdVolume: 1640011801.5316956,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '401.5200000000000000',
      high: '433.0000000000000000',
      low: '391.3200000000000000',
      close: '428.4800000000000000',
      volume: '4180143.8076000000000000',
      quoteVolume: 1737768750.2461805,
      btcVolume: 45007.68133463864,
      usdVolume: 1735956965.104566,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '428.4600000000000000',
      high: '429.4400000000000000',
      low: '365.0000000000000000',
      close: '390.7000000000000000',
      volume: '4787078.4675000000000000',
      quoteVolume: 1860279166.5224113,
      btcVolume: 49180.94965001132,
      usdVolume: 1819826565.68764,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '390.6800000000000000',
      high: '426.0000000000000000',
      low: '373.8000000000000000',
      close: '390.0800000000000000',
      volume: '3977579.3470000000000000',
      quoteVolume: 1595813798.7458856,
      btcVolume: 43299.21514760122,
      usdVolume: 1582242242.5882614,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '390.0500000000000000',
      high: '400.2900000000000000',
      low: '381.5000000000000000',
      close: '393.7300000000000000',
      volume: '1861227.0254000000000000',
      quoteVolume: 728760657.4104598,
      btcVolume: 20250.859347582682,
      usdVolume: 727603105.8069081,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '393.8200000000000000',
      high: '407.4200000000000000',
      low: '353.5300000000000000',
      close: '357.9600000000000000',
      volume: '3028938.8445000000000000',
      quoteVolume: 1170669919.3293319,
      btcVolume: 32773.58817400823,
      usdVolume: 1165121515.317816,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '357.9400000000000000',
      high: '366.3300000000000000',
      low: '319.1800000000000000',
      close: '352.5000000000000000',
      volume: '4986849.8512000000000000',
      quoteVolume: 1719924713.8838046,
      btcVolume: 52178.07647016997,
      usdVolume: 1704623920.306418,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '352.5000000000000000',
      high: '378.7700000000000000',
      low: '333.5600000000000000',
      close: '375.5400000000000000',
      volume: '3494285.6690000000000000',
      quoteVolume: 1249840132.937433,
      btcVolume: 35442.0885217172,
      usdVolume: 1236317469.7043624,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '375.5600000000000000',
      high: '377.5500000000000000',
      low: '344.1300000000000000',
      close: '351.9400000000000000',
      volume: '2416033.9503000000000000',
      quoteVolume: 872167769.3608466,
      btcVolume: 23457.678201352148,
      usdVolume: 868637983.1314929,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '351.9600000000000000',
      high: '366.2800000000000000',
      low: '340.8400000000000000',
      close: '345.7500000000000000',
      volume: '2215352.7230000000000000',
      quoteVolume: 785929588.8141638,
      btcVolume: 21185.154784415125,
      usdVolume: 783490988.6749337,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '345.7600000000000000',
      high: '348.8800000000000000',
      low: '325.3000000000000000',
      close: '343.7000000000000000',
      volume: '2074837.0012000000000000',
      quoteVolume: 699907058.519631,
      btcVolume: 19499.705610749803,
      usdVolume: 696409381.2621572,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '343.6800000000000000',
      high: '369.0000000000000000',
      low: '330.4800000000000000',
      close: '365.8000000000000000',
      volume: '1958071.3656000000000000',
      quoteVolume: 679668875.2465632,
      btcVolume: 18401.26447166104,
      usdVolume: 677962892.4327875,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '365.8100000000000000',
      high: '377.3300000000000000',
      low: '355.0700000000000000',
      close: '370.1900000000000000',
      volume: '1978613.2384000000000000',
      quoteVolume: 723637001.3751506,
      btcVolume: 18165.93816349188,
      usdVolume: 723148705.02761,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '370.2000000000000000',
      high: '379.5800000000000000',
      low: '363.2300000000000000',
      close: '366.4800000000000000',
      volume: '1545190.4369000000000000',
      quoteVolume: 573156968.6381595,
      btcVolume: 14239.62588138577,
      usdVolume: 572741192.8239167,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '366.4800000000000000',
      high: '366.7700000000000000',
      low: '343.3500000000000000',
      close: '347.2200000000000000',
      volume: '1531522.2062000000000000',
      quoteVolume: 543418325.492728,
      btcVolume: 13807.544901929507,
      usdVolume: 542608162.7021075,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '347.2200000000000000',
      high: '362.4700000000000000',
      low: '345.8500000000000000',
      close: '352.8500000000000000',
      volume: '1239146.5273000000000000',
      quoteVolume: 439111935.5714126,
      btcVolume: 11360.551963526297,
      usdVolume: 438379161.15634346,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '352.8400000000000000',
      high: '357.1200000000000000',
      low: '327.2200000000000000',
      close: '337.1600000000000000',
      volume: '1583897.2791000000000000',
      quoteVolume: 543765122.8516467,
      btcVolume: 14644.68316373736,
      usdVolume: 540156402.3887985,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '337.2100000000000000',
      high: '345.0000000000000000',
      low: '331.1100000000000000',
      close: '334.0100000000000000',
      volume: '1066914.4291000000000000',
      quoteVolume: 361009064.1546483,
      btcVolume: 10093.106647631466,
      usdVolume: 360603082.2604604,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '334.0300000000000000',
      high: '340.5700000000000000',
      low: '310.1300000000000000',
      close: '339.0800000000000000',
      volume: '1618135.6528000000000000',
      quoteVolume: 529042411.3983932,
      btcVolume: 15094.299251402948,
      usdVolume: 523909240.19277626,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '339.0900000000000000',
      high: '342.0000000000000000',
      low: '268.5700000000000000',
      close: '270.7600000000000000',
      volume: '3728933.7663000000000000',
      quoteVolume: 1133986634.6228907,
      btcVolume: 34042.98997440145,
      usdVolume: 1114042737.0850716,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '270.7600000000000000',
      high: '291.5000000000000000',
      low: '225.4200000000000000',
      close: '262.1000000000000000',
      volume: '5569480.0596000000000000',
      quoteVolume: 1444878723.2121854,
      btcVolume: 45224.72868315482,
      usdVolume: 1421659323.222384,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '262.1000000000000000',
      high: '309.7500000000000000',
      low: '251.4100000000000000',
      close: '296.2500000000000000',
      volume: '3317195.3211000000000000',
      quoteVolume: 955844232.5110707,
      btcVolume: 28063.34606238212,
      usdVolume: 947394250.7374245,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '296.1700000000000000',
      high: '316.4900000000000000',
      low: '281.5000000000000000',
      close: '308.6200000000000000',
      volume: '2610131.7883000000000000',
      quoteVolume: 786154895.0311371,
      btcVolume: 23317.078611131026,
      usdVolume: 783721733.0198007,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '308.6000000000000000',
      high: '314.8400000000000000',
      low: '275.2400000000000000',
      close: '280.8100000000000000',
      volume: '2711888.8709000000000000',
      quoteVolume: 791189610.0092999,
      btcVolume: 23725.1496532656,
      usdVolume: 789609971.7930796,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '280.8900000000000000',
      high: '290.3300000000000000',
      low: '264.2600000000000000',
      close: '279.6500000000000000',
      volume: '2255714.8070000000000000',
      quoteVolume: 620289844.1352688,
      btcVolume: 19714.79679267372,
      usdVolume: 617533521.9969708,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '279.5800000000000000',
      high: '291.7000000000000000',
      low: '269.7200000000000000',
      close: '290.8700000000000000',
      volume: '1539903.0268000000000000',
      quoteVolume: 431044973.3830572,
      btcVolume: 12975.955639558339,
      usdVolume: 430025822.8561487,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '290.8900000000000000',
      high: '299.9600000000000000',
      low: '283.5500000000000000',
      close: '290.0000000000000000',
      volume: '1791427.4545000000000000',
      quoteVolume: 521315922.3130429,
      btcVolume: 15087.577540242723,
      usdVolume: 520602774.96711284,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '290.0000000000000000',
      high: '315.6000000000000000',
      low: '289.1900000000000000',
      close: '300.7900000000000000',
      volume: '1996075.2527000000000000',
      quoteVolume: 606800912.6351702,
      btcVolume: 17004.53387746386,
      usdVolume: 606106183.2717582,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '300.7900000000000000',
      high: '304.8800000000000000',
      low: '281.5300000000000000',
      close: '303.7100000000000000',
      volume: '2003771.1501000000000000',
      quoteVolume: 589286208.902001,
      btcVolume: 16881.53168393942,
      usdVolume: 588691995.1413999,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '303.7500000000000000',
      high: '304.0000000000000000',
      low: '281.0000000000000000',
      close: '287.4300000000000000',
      volume: '1388151.0265000000000000',
      quoteVolume: 401830865.47626346,
      btcVolume: 11897.911415033517,
      usdVolume: 400902690.8213621,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '287.4900000000000000',
      high: '290.7400000000000000',
      low: '276.4000000000000000',
      close: '287.1700000000000000',
      volume: '999531.0988000000000000',
      quoteVolume: 281885530.70657486,
      btcVolume: 8474.226394092162,
      usdVolume: 281776937.5769988,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '287.1700000000000000',
      high: '303.2100000000000000',
      low: '282.7700000000000000',
      close: '298.1300000000000000',
      volume: '970447.5647000000000000',
      quoteVolume: 285959914.3739259,
      btcVolume: 8295.090216560875,
      usdVolume: 285468108.13785046,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '298.1800000000000000',
      high: '314.9100000000000000',
      low: '292.5800000000000000',
      close: '307.6200000000000000',
      volume: '1289644.5294000000000000',
      quoteVolume: 395101122.4499773,
      btcVolume: 11166.440864142483,
      usdVolume: 394799633.44753224,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '307.6300000000000000',
      high: '307.6800000000000000',
      low: '291.0000000000000000',
      close: '302.0200000000000000',
      volume: '1401707.3090000000000000',
      quoteVolume: 420794880.4929045,
      btcVolume: 12250.65881746282,
      usdVolume: 417383535.8960341,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '302.0400000000000000',
      high: '322.5000000000000000',
      low: '301.8000000000000000',
      close: '319.9200000000000000',
      volume: '2017579.2475000000000000',
      quoteVolume: 634877944.4661232,
      btcVolume: 18213.7054914752,
      usdVolume: 624628084.0134494,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '319.9100000000000000',
      high: '339.2000000000000000',
      low: '318.2300000000000000',
      close: '327.5100000000000000',
      volume: '1752283.5071000000000000',
      quoteVolume: 581264198.4175483,
      btcVolume: 16826.903381226817,
      usdVolume: 581264198.4175483,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '327.5100000000000000',
      high: '328.2100000000000000',
      low: '304.4000000000000000',
      close: '310.1400000000000000',
      volume: '1644090.8501000000000000',
      quoteVolume: 519961296.56923467,
      btcVolume: 15753.624652227563,
      usdVolume: 518366397.8303491,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '310.0800000000000000',
      high: '319.6900000000000000',
      low: '301.4300000000000000',
      close: '317.0900000000000000',
      volume: '1106411.7860000000000000',
      quoteVolume: 343735624.2635021,
      btcVolume: 10375.187952189024,
      usdVolume: 343590533.1811929,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '317.0900000000000000',
      high: '321.4400000000000000',
      low: '310.5700000000000000',
      close: '316.1200000000000000',
      volume: '818970.9225000000000000',
      quoteVolume: 258075549.81921732,
      btcVolume: 7649.980650066824,
      usdVolume: 258069158.45030236,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '316.1200000000000000',
      high: '326.8200000000000000',
      low: '312.2100000000000000',
      close: '322.1600000000000000',
      volume: '798248.5445000000000000',
      quoteVolume: 255757579.33083242,
      btcVolume: 7559.742869184711,
      usdVolume: 255696265.09578243,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '322.1700000000000000',
      high: '334.3700000000000000',
      low: '308.5700000000000000',
      close: '316.1200000000000000',
      volume: '1322836.6575000000000000',
      quoteVolume: 426623454.93747866,
      btcVolume: 12659.101822822975,
      usdVolume: 426389699.78516084,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '316.1100000000000000',
      high: '318.3300000000000000',
      low: '305.2000000000000000',
      close: '308.9400000000000000',
      volume: '920029.5386000000000000',
      quoteVolume: 286332861.64762217,
      btcVolume: 8716.39219531748,
      usdVolume: 286196536.8978347,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '308.9800000000000000',
      high: '313.4600000000000000',
      low: '290.2400000000000000',
      close: '309.4000000000000000',
      volume: '1429632.5382000000000000',
      quoteVolume: 432703278.02084684,
      btcVolume: 13340.15495367681,
      usdVolume: 432565896.3701419,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '309.3300000000000000',
      high: '324.3300000000000000',
      low: '301.3200000000000000',
      close: '316.1100000000000000',
      volume: '2182717.6094000000000000',
      quoteVolume: 682797057.890845,
      btcVolume: 21238.91718069471,
      usdVolume: 681918652.5679137,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '316.1200000000000000',
      high: '323.5000000000000000',
      low: '301.5000000000000000',
      close: '302.8800000000000000',
      volume: '1404493.8001000000000000',
      quoteVolume: 436177727.3631599,
      btcVolume: 13765.70767375639,
      usdVolume: 436177363.4888899,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '302.9400000000000000',
      high: '306.7100000000000000',
      low: '295.0000000000000000',
      close: '300.5200000000000000',
      volume: '1000972.6192000000000000',
      quoteVolume: 300875643.0396566,
      btcVolume: 9534.88714728326,
      usdVolume: 300540577.2338914,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '300.5600000000000000',
      high: '309.1800000000000000',
      low: '298.7000000000000000',
      close: '302.1000000000000000',
      volume: '815497.3527000000000000',
      quoteVolume: 248310442.2807995,
      btcVolume: 7822.5474578018375,
      usdVolume: 248294306.8784705,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '302.0400000000000000',
      high: '303.9400000000000000',
      low: '278.8500000000000000',
      close: '280.5400000000000000',
      volume: '1318041.7074000000000000',
      quoteVolume: 380236625.4640259,
      btcVolume: 12254.211166206616,
      usdVolume: 380166078.83319795,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '280.5400000000000000',
      high: '283.6000000000000000',
      low: '254.5200000000000000',
      close: '264.4400000000000000',
      volume: '1796247.8290000000000000',
      quoteVolume: 477237090.34517443,
      btcVolume: 15976.537565143271,
      usdVolume: 477091911.90441567,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '264.4300000000000000',
      high: '298.9200000000000000',
      low: '261.0200000000000000',
      close: '293.2600000000000000',
      volume: '1740736.0226000000000000',
      quoteVolume: 493675666.91880316,
      btcVolume: 15781.106267115589,
      usdVolume: 493348159.1449845,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '293.2700000000000000',
      high: '298.4400000000000000',
      low: '287.3000000000000000',
      close: '293.3600000000000000',
      volume: '1173507.9432000000000000',
      quoteVolume: 344239131.7439699,
      btcVolume: 10689.223886290574,
      usdVolume: 343305158.22704864,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '293.3900000000000000',
      high: '301.5000000000000000',
      low: '281.0100000000000000',
      close: '285.8000000000000000',
      volume: '1349255.6384000000000000',
      quoteVolume: 393613769.09714,
      btcVolume: 12142.021329993779,
      usdVolume: 393613769.09714,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
  bat: {
    id: 'bat',
    name: 'BAT',
    symbol: 'BAT',
    image: 'assets/images/coin-logo/BAT.png',
    activity24h: 9974589345,
    availableAmount: 508.232323,
    usdxConversion: .7,
    allTimeValue: 943812390,
    allTimeAsset: 3421,
    candleData: [
      {
      open: '204.4500000000000000',
      high: '205.9400000000000000',
      low: '203.6200000000000000',
      close: '204.9900000000000000',
      volume: '44394.8335700000000000',
      quoteVolume: 9084565.9157548,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-28T00:00:00.000Z'
      },
      {
      open: '204.9900000000000000',
      high: '205.4900000000000000',
      low: '195.2800000000000000',
      close: '196.9600000000000000',
      volume: '118281.8227200000000000',
      quoteVolume: 23671711.3937594,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-29T00:00:00.000Z'
      },
      {
      open: '196.9500000000000000',
      high: '198.6000000000000000',
      low: '195.9100000000000000',
      close: '197.2700000000000000',
      volume: '83633.8146300000000000',
      quoteVolume: 16501372.8633176,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-30T00:00:00.000Z'
      },
      {
      open: '197.2400000000000000',
      high: '201.4500000000000000',
      low: '193.0100000000000000',
      close: '198.7300000000000000',
      volume: '114739.7042400000000000',
      quoteVolume: 22632315.2602598,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-31T00:00:00.000Z'
      },
      {
      open: '198.7000000000000000',
      high: '200.9500000000000000',
      low: '197.8800000000000000',
      close: '199.9600000000000000',
      volume: '86097.9862900000000000',
      quoteVolume: 17116621.5351845,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-01T00:00:00.000Z'
      },
      {
      open: '199.9300000000000000',
      high: '203.7400000000000000',
      low: '199.3400000000000000',
      close: '202.3500000000000000',
      volume: '97123.2281300000000000',
      quoteVolume: 19559214.4192249,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-02T00:00:00.000Z'
      },
      {
      open: '202.3100000000000000',
      high: '202.6900000000000000',
      low: '198.5900000000000000',
      close: '200.3100000000000000',
      volume: '72084.9293200000000000',
      quoteVolume: 14456528.3775608,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-03T00:00:00.000Z'
      },
      {
      open: '200.2700000000000000',
      high: '218.7600000000000000',
      low: '199.0000000000000000',
      close: '212.1100000000000000',
      volume: '182534.6216000000000000',
      quoteVolume: 37959621.568273,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-04T00:00:00.000Z'
      },
      {
      open: '212.1300000000000000',
      high: '213.3900000000000000',
      low: '207.6200000000000000',
      close: '210.6600000000000000',
      volume: '141432.1406000000000000',
      quoteVolume: 29714567.4106342,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-05T00:00:00.000Z'
      },
      {
      open: '210.6400000000000000',
      high: '221.2500000000000000',
      low: '209.1100000000000000',
      close: '220.7400000000000000',
      volume: '197797.4952700000000000',
      quoteVolume: 42534535.8639938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-06T00:00:00.000Z'
      },
      {
      open: '220.7700000000000000',
      high: '224.8000000000000000',
      low: '217.0100000000000000',
      close: '219.0700000000000000',
      volume: '204931.0342000000000000',
      quoteVolume: 45252424.0852285,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-07T00:00:00.000Z'
      },
      {
      open: '219.0600000000000000',
      high: '220.7800000000000000',
      low: '212.4900000000000000',
      close: '212.7900000000000000',
      volume: '189828.4945000000000000',
      quoteVolume: 40931506.148422,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-08T00:00:00.000Z'
      },
      {
      open: '212.7900000000000000',
      high: '215.5100000000000000',
      low: '209.1000000000000000',
      close: '210.7700000000000000',
      volume: '152215.9468600000000000',
      quoteVolume: 32309082.1975887,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-09T00:00:00.000Z'
      },
      {
      open: '210.7800000000000000',
      high: '215.5500000000000000',
      low: '209.0200000000000000',
      close: '213.2500000000000000',
      volume: '131384.4789300000000000',
      quoteVolume: 28019225.0100827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-10T00:00:00.000Z'
      },
      {
      open: '213.2700000000000000',
      high: '213.9900000000000000',
      low: '209.0000000000000000',
      close: '212.5400000000000000',
      volume: '136268.8115600000000000',
      quoteVolume: 28875079.8849474,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-11T00:00:00.000Z'
      },
      {
      open: '212.6000000000000000',
      high: '214.2200000000000000',
      low: '210.9000000000000000',
      close: '212.8900000000000000',
      volume: '99963.9720300000000000',
      quoteVolume: 21271725.1845819,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-12T00:00:00.000Z'
      },
      {
      open: '212.9100000000000000',
      high: '213.8000000000000000',
      low: '209.2000000000000000',
      close: '209.8400000000000000',
      volume: '114466.3670800000000000',
      quoteVolume: 24176049.6062305,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-13T00:00:00.000Z'
      },
      {
      open: '209.8400000000000000',
      high: '210.3000000000000000',
      low: '176.1600000000000000',
      close: '187.4300000000000000',
      volume: '434676.1683200000000000',
      quoteVolume: 82531353.9766622,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-14T00:00:00.000Z'
      },
      {
      open: '187.4300000000000000',
      high: '190.0000000000000000',
      low: '170.9900000000000000',
      close: '184.3500000000000000',
      volume: '452416.1078400000000000',
      quoteVolume: 81674273.8857771,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-15T00:00:00.000Z'
      },
      {
      open: '184.3500000000000000',
      high: '185.0000000000000000',
      low: '173.0000000000000000',
      close: '176.8900000000000000',
      volume: '241710.3476300000000000',
      quoteVolume: 43450649.948378,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-16T00:00:00.000Z'
      },
      {
      open: '176.9300000000000000',
      high: '177.9100000000000000',
      low: '173.9400000000000000',
      close: '176.2000000000000000',
      volume: '144804.7098600000000000',
      quoteVolume: 25452515.6364988,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-17T00:00:00.000Z'
      },
      {
      open: '176.2000000000000000',
      high: '182.9400000000000000',
      low: '176.1000000000000000',
      close: '178.9700000000000000',
      volume: '141736.3226300000000000',
      quoteVolume: 25261184.0928033,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-18T00:00:00.000Z'
      },
      {
      open: '179.0400000000000000',
      high: '179.3300000000000000',
      low: '146.4700000000000000',
      close: '150.8100000000000000',
      volume: '596789.8391600000000000',
      quoteVolume: 94639227.0313713,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-19T00:00:00.000Z'
      },
      {
      open: '150.7600000000000000',
      high: '154.7000000000000000',
      low: '126.9500000000000000',
      close: '133.8200000000000000',
      volume: '996552.2872100000000000',
      quoteVolume: 139191208.455093,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-20T00:00:00.000Z'
      },
      {
      open: '133.6800000000000000',
      high: '142.0100000000000000',
      low: '127.1500000000000000',
      close: '138.2300000000000000',
      volume: '718957.7855200000000000',
      quoteVolume: 97819681.4905397,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-21T00:00:00.000Z'
      },
      {
      open: '138.3600000000000000',
      high: '140.4000000000000000',
      low: '125.6400000000000000',
      close: '126.6300000000000000',
      volume: '334674.0718500000000000',
      quoteVolume: 44837388.4512714,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-22T00:00:00.000Z'
      },
      {
      open: '126.5800000000000000',
      high: '128.9000000000000000',
      low: '118.5600000000000000',
      close: '125.0200000000000000',
      volume: '504352.2438600000000000',
      quoteVolume: 62657024.0486937,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-23T00:00:00.000Z'
      },
      {
      open: '125.0000000000000000',
      high: '130.8100000000000000',
      low: '112.3200000000000000',
      close: '114.8900000000000000',
      volume: '471321.5676400000000000',
      quoteVolume: 57264800.0665808,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-24T00:00:00.000Z'
      },
      {
      open: '114.8300000000000000',
      high: '122.3500000000000000',
      low: '102.9500000000000000',
      close: '118.6700000000000000',
      volume: '836233.3125200000000000',
      quoteVolume: 93723314.1234466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-25T00:00:00.000Z'
      },
      {
      open: '118.6500000000000000',
      high: '121.5000000000000000',
      low: '106.4100000000000000',
      close: '110.6800000000000000',
      volume: '679477.3623100000000000',
      quoteVolume: 77209761.8275687,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-26T00:00:00.000Z'
      },
      {
      open: '110.6600000000000000',
      high: '114.3100000000000000',
      low: '102.3600000000000000',
      close: '111.4300000000000000',
      volume: '524024.4061600000000000',
      quoteVolume: 56638056.0091172,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-27T00:00:00.000Z'
      },
      {
      open: '111.3800000000000000',
      high: '126.8000000000000000',
      low: '111.3700000000000000',
      close: '122.9000000000000000',
      volume: '578552.7412400000000000',
      quoteVolume: 68485222.4434163,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-28T00:00:00.000Z'
      },
      {
      open: '122.8800000000000000',
      high: '124.0000000000000000',
      low: '114.7800000000000000',
      close: '117.9800000000000000',
      volume: '478777.3961400000000000',
      quoteVolume: 57140533.5732648,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-29T00:00:00.000Z'
      },
      {
      open: '117.9900000000000000',
      high: '120.2600000000000000',
      low: '111.0900000000000000',
      close: '113.7300000000000000',
      volume: '418593.6352900000000000',
      quoteVolume: 48362085.7818422,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-30T00:00:00.000Z'
      },
      {
      open: '113.7100000000000000',
      high: '120.8000000000000000',
      low: '111.3500000000000000',
      close: '118.1000000000000000',
      volume: '330159.0674200000000000',
      quoteVolume: 38614493.6264599,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-01T00:00:00.000Z'
      },
      {
      open: '118.0900000000000000',
      high: '120.7300000000000000',
      low: '116.0000000000000000',
      close: '116.5700000000000000',
      volume: '273657.6215000000000000',
      quoteVolume: 32184013.7601348,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-02T00:00:00.000Z'
      },
      {
      open: '116.6300000000000000',
      high: '116.9600000000000000',
      low: '106.7000000000000000',
      close: '108.7600000000000000',
      volume: '324446.0332400000000000',
      quoteVolume: 36185196.1948889,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-03T00:00:00.000Z'
      },
      {
      open: '108.7600000000000000',
      high: '113.2000000000000000',
      low: '106.0000000000000000',
      close: '109.9400000000000000',
      volume: '275321.0063000000000000',
      quoteVolume: 30365546.7521367,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-04T00:00:00.000Z'
      },
      {
      open: '109.9200000000000000',
      high: '110.5800000000000000',
      low: '102.3800000000000000',
      close: '102.7100000000000000',
      volume: '354071.5324100000000000',
      quoteVolume: 37519336.0718317,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-05T00:00:00.000Z'
      },
      {
      open: '102.7300000000000000',
      high: '104.6800000000000000',
      low: '90.2000000000000000',
      close: '91.0000000000000000',
      volume: '586138.5904300000000000',
      quoteVolume: 58114721.7510634,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-06T00:00:00.000Z'
      },
      {
      open: '91.1800000000000000',
      high: '96.9000000000000000',
      low: '82.0700000000000000',
      close: '93.0500000000000000',
      volume: '1030073.5846700000000000',
      quoteVolume: 90499447.337842,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-07T00:00:00.000Z'
      },
      {
      open: '93.0600000000000000',
      high: '98.0000000000000000',
      low: '84.5500000000000000',
      close: '90.3800000000000000',
      volume: '698217.1252900000000000',
      quoteVolume: 63224518.5473893,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-08T00:00:00.000Z'
      },
      {
      open: '90.3900000000000000',
      high: '98.7000000000000000',
      low: '88.6300000000000000',
      close: '93.2600000000000000',
      volume: '520519.3602300000000000',
      quoteVolume: 48533111.8520108,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-09T00:00:00.000Z'
      },
      {
      open: '93.2600000000000000',
      high: '95.2100000000000000',
      low: '88.1100000000000000',
      close: '89.9100000000000000',
      volume: '408573.0292900000000000',
      quoteVolume: 37180525.4573109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-10T00:00:00.000Z'
      },
      {
      open: '89.8800000000000000',
      high: '91.6500000000000000',
      low: '85.8300000000000000',
      close: '87.7600000000000000',
      volume: '339991.8424600000000000',
      quoteVolume: 30055007.219609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-11T00:00:00.000Z'
      },
      {
      open: '87.7700000000000000',
      high: '91.3100000000000000',
      low: '86.6300000000000000',
      close: '89.4100000000000000',
      volume: '285436.0402900000000000',
      quoteVolume: 25500398.9286536,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-12T00:00:00.000Z'
      },
      {
      open: '89.3900000000000000',
      high: '90.7100000000000000',
      low: '84.7100000000000000',
      close: '86.3400000000000000',
      volume: '331218.3008300000000000',
      quoteVolume: 29089359.8313012,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-13T00:00:00.000Z'
      },
      {
      open: '86.3500000000000000',
      high: '87.2500000000000000',
      low: '82.2000000000000000',
      close: '83.8200000000000000',
      volume: '318003.7342700000000000',
      quoteVolume: 27041687.8001072,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-14T00:00:00.000Z'
      },
      {
      open: '83.8200000000000000',
      high: '85.0000000000000000',
      low: '81.7900000000000000',
      close: '83.7600000000000000',
      volume: '217360.6577500000000000',
      quoteVolume: 18169124.0002302,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-15T00:00:00.000Z'
      },
      {
      open: '83.7500000000000000',
      high: '87.6400000000000000',
      low: '83.7300000000000000',
      close: '84.6700000000000000',
      volume: '241366.7630500000000000',
      quoteVolume: 20783723.6930707,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-16T00:00:00.000Z'
      },
      {
      open: '84.6800000000000000',
      high: '97.0000000000000000',
      low: '84.4000000000000000',
      close: '94.0200000000000000',
      volume: '472252.2338800000000000',
      quoteVolume: 42965553.4158694,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-17T00:00:00.000Z'
      },
      {
      open: '94.0300000000000000',
      high: '101.3000000000000000',
      low: '91.3400000000000000',
      close: '100.3900000000000000',
      volume: '529946.7917500000000000',
      quoteVolume: 50202945.7917017,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-18T00:00:00.000Z'
      },
      {
      open: '100.3900000000000000',
      high: '107.5500000000000000',
      low: '97.7500000000000000',
      close: '98.7400000000000000',
      volume: '827748.5909600000000000',
      quoteVolume: 84862730.2333679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-19T00:00:00.000Z'
      },
      {
      open: '98.7300000000000000',
      high: '116.0000000000000000',
      low: '97.8800000000000000',
      close: '114.0500000000000000',
      volume: '859612.5720500000000000',
      quoteVolume: 93128082.5480073,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-20T00:00:00.000Z'
      },
      {
      open: '113.7100000000000000',
      high: '118.0000000000000000',
      low: '104.7600000000000000',
      close: '107.6900000000000000',
      volume: '666048.7178600000000000',
      quoteVolume: 74510414.5879752,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-21T00:00:00.000Z'
      },
      {
      open: '107.6700000000000000',
      high: '115.5000000000000000',
      low: '105.3800000000000000',
      close: '115.2000000000000000',
      volume: '405672.8311700000000000',
      quoteVolume: 44814571.6096925,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-22T00:00:00.000Z'
      },
      {
      open: '115.1800000000000000',
      high: '131.9400000000000000',
      low: '114.9100000000000000',
      close: '129.0300000000000000',
      volume: '936094.1049000000000000',
      quoteVolume: 118250392.555097,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-23T00:00:00.000Z'
      },
      {
      open: '129.0300000000000000',
      high: '157.6700000000000000',
      low: '128.8000000000000000',
      close: '138.0300000000000000',
      volume: '1319609.8165000000000000',
      quoteVolume: 190137361.065877,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-24T00:00:00.000Z'
      },
      {
      open: '138.0100000000000000',
      high: '139.2600000000000000',
      low: '119.6800000000000000',
      close: '127.6000000000000000',
      volume: '1076164.1280900000000000',
      quoteVolume: 136600862.042676,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-25T00:00:00.000Z'
      },
      {
      open: '127.6100000000000000',
      high: '135.3200000000000000',
      low: '122.5100000000000000',
      close: '129.3500000000000000',
      volume: '794834.0560100000000000',
      quoteVolume: 102196717.395056,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-26T00:00:00.000Z'
      },
      {
      open: '129.3500000000000000',
      high: '131.0900000000000000',
      low: '111.2200000000000000',
      close: '113.8000000000000000',
      volume: '752177.9011100000000000',
      quoteVolume: 90456912.8883521,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-27T00:00:00.000Z'
      },
      {
      open: '113.7500000000000000',
      high: '136.6600000000000000',
      low: '111.8700000000000000',
      close: '135.3000000000000000',
      volume: '894454.1905100000000000',
      quoteVolume: 111865576.497758,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-28T00:00:00.000Z'
      },
      {
      open: '135.3000000000000000',
      high: '145.4200000000000000',
      low: '128.5000000000000000',
      close: '132.8900000000000000',
      volume: '1006654.4984400000000000',
      quoteVolume: 136479145.942347,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-29T00:00:00.000Z'
      },
      {
      open: '133.0000000000000000',
      high: '139.0000000000000000',
      low: '127.0300000000000000',
      close: '137.7700000000000000',
      volume: '704259.4161400000000000',
      quoteVolume: 94804050.4933616,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-30T00:00:00.000Z'
      },
      {
      open: '137.8100000000000000',
      high: '138.6600000000000000',
      low: '128.7000000000000000',
      close: '131.4500000000000000',
      volume: '484604.8396200000000000',
      quoteVolume: 64763118.1166383,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-31T00:00:00.000Z'
      },
      {
      open: '131.4500000000000000',
      high: '140.6700000000000000',
      low: '130.0000000000000000',
      close: '139.1000000000000000',
      volume: '401776.8879700000000000',
      quoteVolume: 54280385.598127,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-01T00:00:00.000Z'
      },
      {
      open: '139.1000000000000000',
      high: '155.3700000000000000',
      low: '137.7600000000000000',
      close: '152.0100000000000000',
      volume: '941486.3152400000000000',
      quoteVolume: 138801471.118652,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-02T00:00:00.000Z'
      },
      {
      open: '152.0100000000000000',
      high: '153.6400000000000000',
      low: '143.1100000000000000',
      close: '146.3000000000000000',
      volume: '708091.3171400000000000',
      quoteVolume: 104953535.148513,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-03T00:00:00.000Z'
      },
      {
      open: '146.3000000000000000',
      high: '154.8000000000000000',
      low: '143.5400000000000000',
      close: '151.9700000000000000',
      volume: '805248.9382100000000000',
      quoteVolume: 120827141.544228,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-04T00:00:00.000Z'
      },
      {
      open: '152.0800000000000000',
      high: '159.2600000000000000',
      low: '151.2000000000000000',
      close: '152.8300000000000000',
      volume: '773169.1373600000000000',
      quoteVolume: 120238339.782219,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-05T00:00:00.000Z'
      },
      {
      open: '152.8300000000000000',
      high: '157.4000000000000000',
      low: '147.5100000000000000',
      close: '153.8600000000000000',
      volume: '627908.4736600000000000',
      quoteVolume: 95681339.4600648,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-06T00:00:00.000Z'
      },
      {
      open: '153.8900000000000000',
      high: '155.4800000000000000',
      low: '147.8600000000000000',
      close: '149.3400000000000000',
      volume: '424765.3025800000000000',
      quoteVolume: 64310962.9190328,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-07T00:00:00.000Z'
      },
      {
      open: '149.2900000000000000',
      high: '151.6500000000000000',
      low: '145.2000000000000000',
      close: '147.6000000000000000',
      volume: '441661.0677800000000000',
      quoteVolume: 65535529.7557393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-08T00:00:00.000Z'
      },
      {
      open: '147.6200000000000000',
      high: '151.5000000000000000',
      low: '146.7700000000000000',
      close: '148.1000000000000000',
      volume: '299443.5630000000000000',
      quoteVolume: 44673066.391582,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-09T00:00:00.000Z'
      },
      {
      open: '148.1100000000000000',
      high: '149.4500000000000000',
      low: '121.0000000000000000',
      close: '124.9500000000000000',
      volume: '871751.7503800000000000',
      quoteVolume: 114437025.282728,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-10T00:00:00.000Z'
      },
      {
      open: '124.9600000000000000',
      high: '127.3600000000000000',
      low: '120.0800000000000000',
      close: '124.6500000000000000',
      volume: '545293.9836900000000000',
      quoteVolume: 67782816.3375987,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-11T00:00:00.000Z'
      },
      {
      open: '124.6700000000000000',
      high: '126.1900000000000000',
      low: '122.0000000000000000',
      close: '123.3500000000000000',
      volume: '300262.1340400000000000',
      quoteVolume: 37295866.3538343,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-12T00:00:00.000Z'
      },
      {
      open: '123.3500000000000000',
      high: '124.2500000000000000',
      low: '111.8700000000000000',
      close: '114.1600000000000000',
      volume: '422561.9677700000000000',
      quoteVolume: 49521201.2764662,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-13T00:00:00.000Z'
      },
      {
      open: '114.1700000000000000',
      high: '130.0500000000000000',
      low: '113.9400000000000000',
      close: '126.9500000000000000',
      volume: '546420.4085400000000000',
      quoteVolume: 66801543.3050366,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-14T00:00:00.000Z'
      },
      {
      open: '126.9500000000000000',
      high: '129.4000000000000000',
      low: '116.0000000000000000',
      close: '118.9500000000000000',
      volume: '649020.5297000000000000',
      quoteVolume: 79985529.5587708,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-15T00:00:00.000Z'
      },
      {
      open: '118.9300000000000000',
      high: '126.8800000000000000',
      low: '118.5200000000000000',
      close: '121.4800000000000000',
      volume: '513486.6547200000000000',
      quoteVolume: 62314225.9960432,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '121.4900000000000000',
      high: '122.9900000000000000',
      low: '116.8000000000000000',
      close: '121.5400000000000000',
      volume: '447535.2271600000000000',
      quoteVolume: 53854069.2105117,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '121.5600000000000000',
      high: '121.9800000000000000',
      low: '117.6600000000000000',
      close: '119.1700000000000000',
      volume: '235646.6840000000000000',
      quoteVolume: 28305971.6688854,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '119.1600000000000000',
      high: '125.2900000000000000',
      low: '119.1400000000000000',
      close: '122.6000000000000000',
      volume: '323303.7929600000000000',
      quoteVolume: 39715159.1649606,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '122.6100000000000000',
      high: '123.3200000000000000',
      low: '115.6800000000000000',
      close: '117.4700000000000000',
      volume: '451792.8535200000000000',
      quoteVolume: 53833668.9066349,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '117.5600000000000000',
      high: '118.5000000000000000',
      low: '113.6100000000000000',
      close: '115.6300000000000000',
      volume: '303632.9346300000000000',
      quoteVolume: 35258073.4282649,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '115.6200000000000000',
      high: '119.4900000000000000',
      low: '111.3900000000000000',
      close: '117.8100000000000000',
      volume: '359542.6387300000000000',
      quoteVolume: 41777737.4704705,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '117.8000000000000000',
      high: '118.9500000000000000',
      low: '114.8000000000000000',
      close: '116.4400000000000000',
      volume: '230247.6157800000000000',
      quoteVolume: 26975386.3924584,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '116.4000000000000000',
      high: '117.1500000000000000',
      low: '113.6100000000000000',
      close: '116.3900000000000000',
      volume: '216242.2982900000000000',
      quoteVolume: 25044265.7542041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '116.4000000000000000',
      high: '117.5000000000000000',
      low: '114.3000000000000000',
      close: '115.1100000000000000',
      volume: '198617.0810800000000000',
      quoteVolume: 22977175.9541193,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '115.1300000000000000',
      high: '118.0000000000000000',
      low: '115.0000000000000000',
      close: '115.3400000000000000',
      volume: '146216.8281900000000000',
      quoteVolume: 16978386.1972377,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '115.3200000000000000',
      high: '116.1500000000000000',
      low: '111.0400000000000000',
      close: '112.0500000000000000',
      volume: '247369.6055500000000000',
      quoteVolume: 28158012.7304768,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '112.0400000000000000',
      high: '112.8700000000000000',
      low: '100.9100000000000000',
      close: '105.5000000000000000',
      volume: '566205.7828800000000000',
      quoteVolume: 60050129.3863579,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '105.4900000000000000',
      high: '106.6100000000000000',
      low: '102.2200000000000000',
      close: '104.4500000000000000',
      volume: '319768.9134800000000000',
      quoteVolume: 33427469.3450618,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '104.4100000000000000',
      high: '109.8000000000000000',
      low: '103.4500000000000000',
      close: '108.2900000000000000',
      volume: '330968.1926500000000000',
      quoteVolume: 35368520.135571,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '108.2900000000000000',
      high: '110.6100000000000000',
      low: '105.4600000000000000',
      close: '106.4400000000000000',
      volume: '290679.6193500000000000',
      quoteVolume: 31234353.515393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '106.4700000000000000',
      high: '108.3500000000000000',
      low: '103.7700000000000000',
      close: '106.7300000000000000',
      volume: '243149.8843500000000000',
      quoteVolume: 25791535.1460238,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '106.7100000000000000',
      high: '111.1500000000000000',
      low: '106.0700000000000000',
      close: '110.4200000000000000',
      volume: '186908.7573000000000000',
      quoteVolume: 20153872.302919,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '110.4100000000000000',
      high: '111.1100000000000000',
      low: '105.8200000000000000',
      close: '107.3600000000000000',
      volume: '227211.5406400000000000',
      quoteVolume: 24605892.2887283,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '107.3300000000000000',
      high: '109.3000000000000000',
      low: '106.5700000000000000',
      close: '107.6800000000000000',
      volume: '160850.1029300000000000',
      quoteVolume: 17312736.6001502,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '107.6500000000000000',
      high: '108.1800000000000000',
      low: '106.4700000000000000',
      close: '107.2700000000000000',
      volume: '159730.1886000000000000',
      quoteVolume: 17137008.2478395,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '107.2800000000000000',
      high: '107.8300000000000000',
      low: '101.2100000000000000',
      close: '104.5000000000000000',
      volume: '337204.3025400000000000',
      quoteVolume: 34984364.2970212,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '104.4900000000000000',
      high: '106.1800000000000000',
      low: '103.8200000000000000',
      close: '104.4800000000000000',
      volume: '147942.0768300000000000',
      quoteVolume: 15530160.6471066,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '104.4800000000000000',
      high: '123.0000000000000000',
      low: '103.4100000000000000',
      close: '119.2500000000000000',
      volume: '589950.6492500000000000',
      quoteVolume: 67027886.9398639,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '119.3200000000000000',
      high: '121.9900000000000000',
      low: '117.6400000000000000',
      close: '119.1900000000000000',
      volume: '328067.9574000000000000',
      quoteVolume: 39061582.3914826,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '119.1900000000000000',
      high: '126.4900000000000000',
      low: '115.8100000000000000',
      close: '125.1200000000000000',
      volume: '385819.0961900000000000',
      quoteVolume: 45929459.1290131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '125.1200000000000000',
      high: '125.2800000000000000',
      low: '119.2400000000000000',
      close: '120.8900000000000000',
      volume: '389405.3040400000000000',
      quoteVolume: 47197595.845191,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '120.8800000000000000',
      high: '123.8900000000000000',
      low: '118.1800000000000000',
      close: '122.4100000000000000',
      volume: '343765.6698700000000000',
      quoteVolume: 41603632.811699,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '122.4000000000000000',
      high: '126.4700000000000000',
      low: '120.7000000000000000',
      close: '121.8600000000000000',
      volume: '317827.3195600000000000',
      quoteVolume: 39073077.8391656,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '121.8600000000000000',
      high: '124.5000000000000000',
      low: '120.1000000000000000',
      close: '120.4200000000000000',
      volume: '297205.7873800000000000',
      quoteVolume: 36278326.0774455,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '120.4700000000000000',
      high: '124.2500000000000000',
      low: '120.1900000000000000',
      close: '121.6700000000000000',
      volume: '255296.0814000000000000',
      quoteVolume: 31111280.2093057,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '121.6600000000000000',
      high: '124.9900000000000000',
      low: '121.5400000000000000',
      close: '122.6700000000000000',
      volume: '215320.7013400000000000',
      quoteVolume: 26462034.6125396,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '122.6700000000000000',
      high: '135.6100000000000000',
      low: '122.4300000000000000',
      close: '133.7500000000000000',
      volume: '614788.6007800000000000',
      quoteVolume: 78958054.9589805,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '133.7700000000000000',
      high: '148.3000000000000000',
      low: '132.4200000000000000',
      close: '145.6900000000000000',
      volume: '1079830.1502000000000000',
      quoteVolume: 152756654.645478,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '145.6900000000000000',
      high: '150.0000000000000000',
      low: '142.3600000000000000',
      close: '143.4400000000000000',
      volume: '747968.9044500000000000',
      quoteVolume: 109645050.466097,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '143.4500000000000000',
      high: '149.5200000000000000',
      low: '139.6000000000000000',
      close: '148.4400000000000000',
      volume: '614303.6100700000000000',
      quoteVolume: 89136482.3716519,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '148.4200000000000000',
      high: '149.6100000000000000',
      low: '142.7000000000000000',
      close: '145.6900000000000000',
      volume: '447364.4087300000000000',
      quoteVolume: 65199779.5124385,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '145.6800000000000000',
      high: '148.8500000000000000',
      low: '143.8500000000000000',
      close: '147.3600000000000000',
      volume: '366251.1854200000000000',
      quoteVolume: 53771600.8025665,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '147.4000000000000000',
      high: '159.6000000000000000',
      low: '145.7800000000000000',
      close: '157.7900000000000000',
      volume: '588183.7289600000000000',
      quoteVolume: 89997516.9234365,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '157.7500000000000000',
      high: '166.0000000000000000',
      low: '131.7000000000000000',
      close: '132.1000000000000000',
      volume: '1260506.8067200000000000',
      quoteVolume: 186688063.414179,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '132.1000000000000000',
      high: '141.5500000000000000',
      low: '131.8900000000000000',
      close: '137.8700000000000000',
      volume: '801147.0954300000000000',
      quoteVolume: 110348892.970024,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '137.8800000000000000',
      high: '138.4100000000000000',
      low: '133.7200000000000000',
      close: '136.2200000000000000',
      volume: '432944.1703300000000000',
      quoteVolume: 58979934.7160016,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '136.2200000000000000',
      high: '140.2900000000000000',
      low: '126.8400000000000000',
      close: '135.0200000000000000',
      volume: '567202.6103500000000000',
      quoteVolume: 76519497.9080148,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '135.0300000000000000',
      high: '139.0000000000000000',
      low: '132.1000000000000000',
      close: '135.3400000000000000',
      volume: '579327.3174900000000000',
      quoteVolume: 78861797.5593921,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '135.3200000000000000',
      high: '137.9500000000000000',
      low: '134.9000000000000000',
      close: '135.3200000000000000',
      volume: '302632.0283100000000000',
      quoteVolume: 41198722.5798789,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '135.3200000000000000',
      high: '136.1200000000000000',
      low: '130.0300000000000000',
      close: '132.5600000000000000',
      volume: '297005.2809300000000000',
      quoteVolume: 39467507.6857296,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '132.5400000000000000',
      high: '134.6000000000000000',
      low: '128.0100000000000000',
      close: '130.6000000000000000',
      volume: '222453.2859000000000000',
      quoteVolume: 29447917.2382588,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '130.6000000000000000',
      high: '131.1100000000000000',
      low: '122.6000000000000000',
      close: '125.6500000000000000',
      volume: '403947.7944500000000000',
      quoteVolume: 51060349.7665833,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '125.6800000000000000',
      high: '137.8400000000000000',
      low: '125.1900000000000000',
      close: '136.7200000000000000',
      volume: '512631.2317800000000000',
      quoteVolume: 67740644.3311417,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '136.7100000000000000',
      high: '140.2900000000000000',
      low: '133.3300000000000000',
      close: '137.4000000000000000',
      volume: '428826.8741500000000000',
      quoteVolume: 58701328.649431,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '137.3700000000000000',
      high: '140.2000000000000000',
      low: '135.2000000000000000',
      close: '136.8100000000000000',
      volume: '307336.4931000000000000',
      quoteVolume: 42271691.2428617,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '136.8200000000000000',
      high: '139.3400000000000000',
      low: '130.6200000000000000',
      close: '133.5200000000000000',
      volume: '330283.5823300000000000',
      quoteVolume: 44879637.3870354,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '133.5100000000000000',
      high: '138.6500000000000000',
      low: '132.9300000000000000',
      close: '137.2700000000000000',
      volume: '279861.1204900000000000',
      quoteVolume: 38220614.005662,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '137.2700000000000000',
      high: '137.5000000000000000',
      low: '134.0000000000000000',
      close: '135.3700000000000000',
      volume: '203965.1681500000000000',
      quoteVolume: 27650702.3793393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '135.3700000000000000',
      high: '136.1500000000000000',
      low: '131.0000000000000000',
      close: '132.6700000000000000',
      volume: '264587.0068300000000000',
      quoteVolume: 35285847.7482702,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '132.6700000000000000',
      high: '135.3000000000000000',
      low: '130.7700000000000000',
      close: '133.5700000000000000',
      volume: '194905.9684300000000000',
      quoteVolume: 25981446.1033466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '133.6200000000000000',
      high: '133.6500000000000000',
      low: '130.5800000000000000',
      close: '131.6500000000000000',
      volume: '209456.0984800000000000',
      quoteVolume: 27670734.4309227,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '131.6900000000000000',
      high: '135.1100000000000000',
      low: '128.5200000000000000',
      close: '132.2000000000000000',
      volume: '258413.8186800000000000',
      quoteVolume: 34099414.8055834,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '132.2400000000000000',
      high: '137.6200000000000000',
      low: '132.1800000000000000',
      close: '136.5600000000000000',
      volume: '295832.2656200000000000',
      quoteVolume: 39928522.3372778,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '136.6000000000000000',
      high: '144.4700000000000000',
      low: '136.5100000000000000',
      close: '140.9200000000000000',
      volume: '474664.1019700000000000',
      quoteVolume: 66878173.4518186,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '140.9400000000000000',
      high: '141.0700000000000000',
      low: '137.2000000000000000',
      close: '138.6700000000000000',
      volume: '252697.0814800000000000',
      quoteVolume: 35051209.9303395,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '138.6700000000000000',
      high: '142.0100000000000000',
      low: '136.1000000000000000',
      close: '137.5900000000000000',
      volume: '281718.9619500000000000',
      quoteVolume: 39017864.9224565,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '137.6200000000000000',
      high: '139.3800000000000000',
      low: '136.9700000000000000',
      close: '138.5500000000000000',
      volume: '218577.4520800000000000',
      quoteVolume: 30175322.0784989,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '138.5600000000000000',
      high: '139.6600000000000000',
      low: '136.0100000000000000',
      close: '139.0100000000000000',
      volume: '222883.5175400000000000',
      quoteVolume: 30757997.8115518,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '139.0400000000000000',
      high: '139.5500000000000000',
      low: '131.2100000000000000',
      close: '134.8500000000000000',
      volume: '312531.3936800000000000',
      quoteVolume: 42635937.4891712,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '134.8500000000000000',
      high: '137.2000000000000000',
      low: '134.2900000000000000',
      close: '135.9800000000000000',
      volume: '186119.7474500000000000',
      quoteVolume: 25309122.7264498,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '135.9200000000000000',
      high: '138.2100000000000000',
      low: '135.6000000000000000',
      close: '137.2800000000000000',
      volume: '174710.4305900000000000',
      quoteVolume: 23922572.3588934,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '137.2800000000000000',
      high: '137.3700000000000000',
      low: '134.7500000000000000',
      close: '136.0900000000000000',
      volume: '198490.4512400000000000',
      quoteVolume: 27042322.6952009,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '136.1200000000000000',
      high: '136.8300000000000000',
      low: '131.6000000000000000',
      close: '133.9800000000000000',
      volume: '230880.2850000000000000',
      quoteVolume: 31082445.5932449,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '133.9700000000000000',
      high: '134.7400000000000000',
      low: '132.2700000000000000',
      close: '134.1800000000000000',
      volume: '183139.1142600000000000',
      quoteVolume: 24490323.0607397,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '134.1400000000000000',
      high: '140.1400000000000000',
      low: '133.8400000000000000',
      close: '139.4500000000000000',
      volume: '317167.4532500000000000',
      quoteVolume: 43635238.4289265,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '139.4600000000000000',
      high: '139.5700000000000000',
      low: '137.3000000000000000',
      close: '137.8600000000000000',
      volume: '163185.6991000000000000',
      quoteVolume: 22543474.6122913,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '137.8500000000000000',
      high: '143.6000000000000000',
      low: '137.6200000000000000',
      close: '143.2500000000000000',
      volume: '235853.0454000000000000',
      quoteVolume: 33098245.9556135,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '143.2500000000000000',
      high: '145.8900000000000000',
      low: '140.0600000000000000',
      close: '142.4600000000000000',
      volume: '254798.7006700000000000',
      quoteVolume: 36382647.2268952,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '142.4400000000000000',
      high: '142.5000000000000000',
      low: '140.4200000000000000',
      close: '141.3800000000000000',
      volume: '142469.2117100000000000',
      quoteVolume: 20131931.6990724,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '141.4100000000000000',
      high: '143.0800000000000000',
      low: '139.5700000000000000',
      close: '141.2400000000000000',
      volume: '234310.1916100000000000',
      quoteVolume: 33234998.6438733,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '141.2400000000000000',
      high: '165.6000000000000000',
      low: '141.0000000000000000',
      close: '163.3100000000000000',
      volume: '906868.8586000000000000',
      quoteVolume: 138094679.302043,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '163.2600000000000000',
      high: '177.8100000000000000',
      low: '149.2300000000000000',
      close: '159.9600000000000000',
      volume: '1345344.5384500000000000',
      quoteVolume: 225523896.201466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '159.9600000000000000',
      high: '164.0000000000000000',
      low: '152.8000000000000000',
      close: '157.3600000000000000',
      volume: '686080.9524800000000000',
      quoteVolume: 109082183.587735,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '157.3300000000000000',
      high: '167.8300000000000000',
      low: '155.9400000000000000',
      close: '164.7700000000000000',
      volume: '516070.3455300000000000',
      quoteVolume: 84123101.326904,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '164.8400000000000000',
      high: '172.7700000000000000',
      low: '159.2000000000000000',
      close: '165.5000000000000000',
      volume: '528168.3329700000000000',
      quoteVolume: 87657058.1149817,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '165.5600000000000000',
      high: '175.4300000000000000',
      low: '164.0600000000000000',
      close: '174.2100000000000000',
      volume: '465507.5249700000000000',
      quoteVolume: 78876773.2967177,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '174.2900000000000000',
      high: '185.8700000000000000',
      low: '170.9000000000000000',
      close: '178.7200000000000000',
      volume: '873410.0728500000000000',
      quoteVolume: 156417981.302118,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '178.7100000000000000',
      high: '179.1700000000000000',
      low: '171.0500000000000000',
      close: '174.6000000000000000',
      volume: '431986.3610800000000000',
      quoteVolume: 75745619.5720787,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '174.5800000000000000',
      high: '183.7700000000000000',
      low: '168.0000000000000000',
      close: '177.1200000000000000',
      volume: '523943.2049800000000000',
      quoteVolume: 93283836.587114,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '177.1200000000000000',
      high: '177.4800000000000000',
      low: '158.2700000000000000',
      close: '164.0700000000000000',
      volume: '661305.3032100000000000',
      quoteVolume: 110201659.947876,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '164.0600000000000000',
      high: '166.5000000000000000',
      low: '158.1100000000000000',
      close: '163.1900000000000000',
      volume: '316337.8782300000000000',
      quoteVolume: 51556919.1461367,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '163.2000000000000000',
      high: '165.3700000000000000',
      low: '160.7400000000000000',
      close: '162.9900000000000000',
      volume: '179237.7457400000000000',
      quoteVolume: 29247030.1526373,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '162.9900000000000000',
      high: '168.1100000000000000',
      low: '161.2000000000000000',
      close: '167.2300000000000000',
      volume: '186977.6621100000000000',
      quoteVolume: 30640985.0609499,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '167.2100000000000000',
      high: '168.8100000000000000',
      low: '155.7000000000000000',
      close: '159.9500000000000000',
      volume: '351548.9079000000000000',
      quoteVolume: 57215098.1117043,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '159.9100000000000000',
      high: '166.7300000000000000',
      low: '159.4100000000000000',
      close: '165.3700000000000000',
      volume: '240370.5044100000000000',
      quoteVolume: 39245823.494595,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '165.4100000000000000',
      high: '167.2800000000000000',
      low: '163.7800000000000000',
      close: '165.7500000000000000',
      volume: '227841.5498100000000000',
      quoteVolume: 37678036.5964673,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '165.7500000000000000',
      high: '175.2000000000000000',
      low: '165.0400000000000000',
      close: '173.3300000000000000',
      volume: '396118.9440700000000000',
      quoteVolume: 68031293.2325779,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '173.3400000000000000',
      high: '173.5500000000000000',
      low: '168.5900000000000000',
      close: '172.6400000000000000',
      volume: '320541.7216600000000000',
      quoteVolume: 54991300.1097207,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '172.6600000000000000',
      high: '176.9700000000000000',
      low: '169.8700000000000000',
      close: '172.8800000000000000',
      volume: '253596.4840700000000000',
      quoteVolume: 43939979.0160537,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '172.8900000000000000',
      high: '173.4800000000000000',
      low: '164.8200000000000000',
      close: '168.5900000000000000',
      volume: '283450.4394300000000000',
      quoteVolume: 47781349.4020958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '168.5900000000000000',
      high: '172.3000000000000000',
      low: '167.0000000000000000',
      close: '170.5200000000000000',
      volume: '269868.3987600000000000',
      quoteVolume: 45832751.057015,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '170.5200000000000000',
      high: '175.8300000000000000',
      low: '167.7800000000000000',
      close: '169.1600000000000000',
      volume: '363523.3453100000000000',
      quoteVolume: 62784241.170476,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '169.1500000000000000',
      high: '169.8700000000000000',
      low: '159.2600000000000000',
      close: '164.7800000000000000',
      volume: '426497.0415300000000000',
      quoteVolume: 70076178.2845027,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '164.7800000000000000',
      high: '165.4900000000000000',
      low: '150.3500000000000000',
      close: '154.7200000000000000',
      volume: '422657.0323900000000000',
      quoteVolume: 67326464.6484704,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '154.7000000000000000',
      high: '162.5600000000000000',
      low: '151.4000000000000000',
      close: '157.9500000000000000',
      volume: '500355.8628300000000000',
      quoteVolume: 78334214.8744062,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '157.9300000000000000',
      high: '166.2400000000000000',
      low: '156.3500000000000000',
      close: '159.3100000000000000',
      volume: '215918.6384200000000000',
      quoteVolume: 34294277.9954465,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '159.3200000000000000',
      high: '160.2400000000000000',
      low: '156.9200000000000000',
      close: '157.8500000000000000',
      volume: '152976.3018100000000000',
      quoteVolume: 24304182.1410825,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '157.8600000000000000',
      high: '159.0500000000000000',
      low: '152.0000000000000000',
      close: '155.1100000000000000',
      volume: '243591.9489800000000000',
      quoteVolume: 37892463.2904875,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '155.1400000000000000',
      high: '162.8900000000000000',
      low: '155.1100000000000000',
      close: '161.8300000000000000',
      volume: '305056.2465300000000000',
      quoteVolume: 48352308.3471109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '161.8500000000000000',
      high: '163.8800000000000000',
      low: '158.3600000000000000',
      close: '160.1900000000000000',
      volume: '192517.6276300000000000',
      quoteVolume: 30965351.6968463,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '160.2000000000000000',
      high: '163.4800000000000000',
      low: '158.5000000000000000',
      close: '161.8100000000000000',
      volume: '289519.0202500000000000',
      quoteVolume: 46716417.0127823,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '161.8200000000000000',
      high: '170.8600000000000000',
      low: '160.9900000000000000',
      close: '168.0600000000000000',
      volume: '416357.5212300000000000',
      quoteVolume: 69185162.5971677,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '168.1000000000000000',
      high: '171.3000000000000000',
      low: '159.7600000000000000',
      close: '163.7000000000000000',
      volume: '312928.5834900000000000',
      quoteVolume: 51596174.4008955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '163.7100000000000000',
      high: '165.3600000000000000',
      low: '159.1800000000000000',
      close: '163.0500000000000000',
      volume: '176029.1044500000000000',
      quoteVolume: 28659516.7862457,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '163.0500000000000000',
      high: '178.0500000000000000',
      low: '157.5500000000000000',
      close: '173.0000000000000000',
      volume: '506528.1427800000000000',
      quoteVolume: 86112232.475863,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '172.9800000000000000',
      high: '182.7600000000000000',
      low: '167.8900000000000000',
      close: '169.1800000000000000',
      volume: '732496.8786100000000000',
      quoteVolume: 129206700.127684,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '169.0700000000000000',
      high: '173.1700000000000000',
      low: '164.8000000000000000',
      close: '170.8500000000000000',
      volume: '296410.7610300000000000',
      quoteVolume: 50307994.5847109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '170.8800000000000000',
      high: '173.8800000000000000',
      low: '166.5700000000000000',
      close: '170.9000000000000000',
      volume: '284663.9945600000000000',
      quoteVolume: 48482365.5414184,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '170.9600000000000000',
      high: '176.7000000000000000',
      low: '168.4600000000000000',
      close: '172.9100000000000000',
      volume: '425543.7402100000000000',
      quoteVolume: 73717438.6539584,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '172.9200000000000000',
      high: '205.2300000000000000',
      low: '172.9200000000000000',
      close: '190.2000000000000000',
      volume: '885292.3838900000000000',
      quoteVolume: 167180496.76233,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '190.2000000000000000',
      high: '204.3200000000000000',
      low: '181.0000000000000000',
      close: '188.1100000000000000',
      volume: '823372.2814800000000000',
      quoteVolume: 157611011.725251,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '188.0700000000000000',
      high: '206.8700000000000000',
      low: '185.3200000000000000',
      close: '195.8400000000000000',
      volume: '669265.4652400000000000',
      quoteVolume: 131102303.13857,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '195.8300000000000000',
      high: '219.8100000000000000',
      low: '194.1900000000000000',
      close: '216.4000000000000000',
      volume: '864211.0060300000000000',
      quoteVolume: 179717430.310727,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '216.4000000000000000',
      high: '250.2700000000000000',
      low: '216.2100000000000000',
      close: '248.1000000000000000',
      volume: '574697.2992700000000000',
      quoteVolume: 135054369.61757,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '248.0600000000000000',
      high: '279.7600000000000000',
      low: '241.7600000000000000',
      close: '264.2500000000000000',
      volume: '1733360.8936000000000000',
      quoteVolume: 452410350.051738,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '264.3500000000000000',
      high: '268.7400000000000000',
      low: '223.4300000000000000',
      close: '244.3000000000000000',
      volume: '1529892.5689800000000000',
      quoteVolume: 366429299.202803,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '244.3100000000000000',
      high: '248.0000000000000000',
      low: '230.6200000000000000',
      close: '233.6000000000000000',
      volume: '673218.0998200000000000',
      quoteVolume: 160450040.806919,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '233.5700000000000000',
      high: '264.0000000000000000',
      low: '231.7700000000000000',
      close: '259.5100000000000000',
      volume: '823859.0634200000000000',
      quoteVolume: 207383027.826484,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '259.3700000000000000',
      high: '259.4900000000000000',
      low: '238.0000000000000000',
      close: '250.3700000000000000',
      volume: '666889.8224100000000000',
      quoteVolume: 165783106.07703,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '250.4800000000000000',
      high: '262.0000000000000000',
      low: '246.0600000000000000',
      close: '253.4300000000000000',
      volume: '580249.6975300000000000',
      quoteVolume: 146749988.273737,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '253.3500000000000000',
      high: '260.1800000000000000',
      low: '239.6300000000000000',
      close: '243.6400000000000000',
      volume: '592848.9560500000000000',
      quoteVolume: 149006735.642177,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '243.6800000000000000',
      high: '247.3400000000000000',
      low: '231.1000000000000000',
      close: '244.5600000000000000',
      volume: '600193.8936800000000000',
      quoteVolume: 144051488.877393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '244.6300000000000000',
      high: '254.3500000000000000',
      low: '241.5400000000000000',
      close: '248.8700000000000000',
      volume: '447066.9259600000000000',
      quoteVolume: 111294049.366715,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '248.7500000000000000',
      high: '257.3300000000000000',
      low: '247.2600000000000000',
      close: '250.2900000000000000',
      volume: '210086.6689900000000000',
      quoteVolume: 52883558.98214837,
      btcVolume: 6601.861190292515,
      usdVolume: 52883558.98214837,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '250.5800000000000000',
      high: '269.3300000000000000',
      low: '245.1100000000000000',
      close: '264.4300000000000000',
      volume: '328906.1700300000000000',
      quoteVolume: 84389359.21664856,
      btcVolume: 10168.644937039242,
      usdVolume: 84389359.21664856,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '264.4400000000000000',
      high: '279.5900000000000000',
      low: '262.0000000000000000',
      close: '270.5700000000000000',
      volume: '430881.9927300000000000',
      quoteVolume: 115553890.30784754,
      btcVolume: 13224.875481256204,
      usdVolume: 115553890.30784754,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '270.6500000000000000',
      high: '275.9500000000000000',
      low: '264.7100000000000000',
      close: '271.5400000000000000',
      volume: '244142.8490000000000000',
      quoteVolume: 66001001.032268964,
      btcVolume: 7580.161845035036,
      usdVolume: 66001001.032268964,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '270.7600000000000000',
      high: '274.0400000000000000',
      low: '260.0500000000000000',
      close: '268.9400000000000000',
      volume: '250176.3188600000000000',
      quoteVolume: 67085217.72845518,
      btcVolume: 7783.531911611134,
      usdVolume: 67085217.72845518,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '268.8900000000000000',
      high: '288.6200000000000000',
      low: '240.1400000000000000',
      close: '254.3900000000000000',
      volume: '874566.2467900000000000',
      quoteVolume: 237214565.53410167,
      btcVolume: 27663.736785432655,
      usdVolume: 237214565.53410167,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '254.5600000000000000',
      high: '268.7200000000000000',
      low: '245.2100000000000000',
      close: '267.5000000000000000',
      volume: '435596.2624900000000000',
      quoteVolume: 111862948.81367387,
      btcVolume: 13416.233814032905,
      usdVolume: 111862948.81367387,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '268.5500000000000000',
      high: '275.5000000000000000',
      low: '260.6800000000000000',
      close: '264.3300000000000000',
      volume: '340251.6517200000000000',
      quoteVolume: 90953282.63449085,
      btcVolume: 10654.39476621369,
      usdVolume: 90953282.63449085,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '264.3300000000000000',
      high: '273.2000000000000000',
      low: '263.2000000000000000',
      close: '268.8800000000000000',
      volume: '301536.1506200000000000',
      quoteVolume: 80960737.4054651,
      btcVolume: 9351.28557475043,
      usdVolume: 80960737.4054651,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '268.8700000000000000',
      high: '270.0000000000000000',
      low: '248.0000000000000000',
      close: '249.9100000000000000',
      volume: '390829.8328400000000000',
      quoteVolume: 101905937.03778602,
      btcVolume: 12020.341131421099,
      usdVolume: 101905937.03778602,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '250.0200000000000000',
      high: '251.6700000000000000',
      low: '234.3900000000000000',
      close: '241.3900000000000000',
      volume: '633054.5403700000000000',
      quoteVolume: 154522639.97359976,
      btcVolume: 19697.30421417344,
      usdVolume: 154522639.97359976,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '241.4600000000000000',
      high: '250.4100000000000000',
      low: '237.3700000000000000',
      close: '245.6700000000000000',
      volume: '335951.0818300000000000',
      quoteVolume: 81838722.29639693,
      btcVolume: 10541.8570319359,
      usdVolume: 81838722.29639693,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '245.6300000000000000',
      high: '250.6600000000000000',
      low: '235.0100000000000000',
      close: '248.7300000000000000',
      volume: '297333.9071700000000000',
      quoteVolume: 72359467.8907992,
      btcVolume: 9375.200502885184,
      usdVolume: 72359467.8907992,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '248.7500000000000000',
      high: '253.2800000000000000',
      low: '243.4600000000000000',
      close: '248.7700000000000000',
      volume: '353382.9471000000000000',
      quoteVolume: 87645350.54723677,
      btcVolume: 11105.098897639275,
      usdVolume: 87645350.54723677,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '248.7800000000000000',
      high: '250.5300000000000000',
      low: '240.3700000000000000',
      close: '243.2500000000000000',
      volume: '188448.2774800000000000',
      quoteVolume: 46293916.7909611,
      btcVolume: 5858.450696551191,
      usdVolume: 46293916.7909611,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '243.3300000000000000',
      high: '244.5300000000000000',
      low: '226.5600000000000000',
      close: '230.9800000000000000',
      volume: '316894.1012500000000000',
      quoteVolume: 74585371.36728549,
      btcVolume: 9656.729097772812,
      usdVolume: 74585371.36728549,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '230.9800000000000000',
      high: '247.4500000000000000',
      low: '227.0300000000000000',
      close: '246.5800000000000000',
      volume: '314636.9740500000000000',
      quoteVolume: 75084193.16334817,
      btcVolume: 9642.047156408467,
      usdVolume: 75084193.16334817,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '246.6400000000000000',
      high: '247.8000000000000000',
      low: '236.4900000000000000',
      close: '244.2800000000000000',
      volume: '236917.8619100000000000',
      quoteVolume: 57405428.93464183,
      btcVolume: 7322.677723536042,
      usdVolume: 57405428.93464183,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '244.2900000000000000',
      high: '261.8700000000000000',
      low: '242.2000000000000000',
      close: '261.0400000000000000',
      volume: '334914.1948300000000000',
      quoteVolume: 84260792.21337387,
      btcVolume: 10493.689905645679,
      usdVolume: 84260792.21337387,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '261.0400000000000000',
      high: '262.9800000000000000',
      low: '253.6900000000000000',
      close: '254.7000000000000000',
      volume: '291757.4165600000000000',
      quoteVolume: 75447458.94669941,
      btcVolume: 9258.053283515925,
      usdVolume: 75447458.94669941,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '254.6700000000000000',
      high: '264.2600000000000000',
      low: '251.7500000000000000',
      close: '262.4700000000000000',
      volume: '327384.9350300000000000',
      quoteVolume: 84046270.42804858,
      btcVolume: 10052.089943759258,
      usdVolume: 84046270.42804858,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '262.4800000000000000',
      high: '272.2700000000000000',
      low: '260.6100000000000000',
      close: '267.8100000000000000',
      volume: '286562.0009300000000000',
      quoteVolume: 76044367.43084204,
      btcVolume: 8744.231544379601,
      usdVolume: 76044367.43084204,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '267.8200000000000000',
      high: '277.0600000000000000',
      low: '263.3100000000000000',
      close: '267.8200000000000000',
      volume: '457043.8053700000000000',
      quoteVolume: 123543186.81485234,
      btcVolume: 13633.549352858961,
      usdVolume: 123543186.81485234,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '267.7100000000000000',
      high: '276.0000000000000000',
      low: '266.8100000000000000',
      close: '273.0900000000000000',
      volume: '339245.3390700000000000',
      quoteVolume: 91798515.34806772,
      btcVolume: 9973.204806382242,
      usdVolume: 91798515.34806772,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '273.1200000000000000',
      high: '273.5800000000000000',
      low: '261.4500000000000000',
      close: '264.6600000000000000',
      volume: '319694.8046200000000000',
      quoteVolume: 85270720.28219834,
      btcVolume: 9341.837857544495,
      usdVolume: 85191137.66587001,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '264.6800000000000000',
      high: '270.0000000000000000',
      low: '263.5900000000000000',
      close: '268.1400000000000000',
      volume: '208374.2487300000000000',
      quoteVolume: 55665534.352045216,
      btcVolume: 6091.847271949922,
      usdVolume: 55665534.352045216,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '268.1400000000000000',
      high: '273.6300000000000000',
      low: '265.7100000000000000',
      close: '271.2200000000000000',
      volume: '197206.8955100000000000',
      quoteVolume: 53051977.61480555,
      btcVolume: 5666.18245046985,
      usdVolume: 53051977.61480555,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '271.2700000000000000',
      high: '295.9400000000000000',
      low: '271.2200000000000000',
      close: '294.3000000000000000',
      volume: '519753.0589000000000000',
      quoteVolume: 148622967.47444713,
      btcVolume: 15205.227567841224,
      usdVolume: 148622967.47444713,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '294.3200000000000000',
      high: '314.3900000000000000',
      low: '289.6900000000000000',
      close: '310.6100000000000000',
      volume: '716598.8706100000000000',
      quoteVolume: 219105237.54071936,
      btcVolume: 20528.78896259923,
      usdVolume: 219105237.54071936,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '310.7000000000000000',
      high: '321.7000000000000000',
      low: '305.5800000000000000',
      close: '308.5200000000000000',
      volume: '418569.8935300000000000',
      quoteVolume: 131176119.43065393,
      btcVolume: 12082.996427040667,
      usdVolume: 131176119.43065393,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '308.4200000000000000',
      high: '314.4100000000000000',
      low: '298.0000000000000000',
      close: '311.6800000000000000',
      volume: '352738.3189300000000000',
      quoteVolume: 108423026.96658173,
      btcVolume: 9981.964869792726,
      usdVolume: 108423026.96658173,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '311.6400000000000000',
      high: '318.9900000000000000',
      low: '307.0000000000000000',
      close: '318.2600000000000000',
      volume: '345551.7547400000000000',
      quoteVolume: 108140713.48472323,
      btcVolume: 9505.539339201336,
      usdVolume: 108140713.48472323,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '318.2900000000000000',
      high: '366.8000000000000000',
      low: '315.5700000000000000',
      close: '339.3900000000000000',
      volume: '977599.1145100000000000',
      quoteVolume: 331095769.33132416,
      btcVolume: 25950.15573069012,
      usdVolume: 331095769.33132416,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '339.5300000000000000',
      high: '347.0000000000000000',
      low: '280.0100000000000000',
      close: '298.3500000000000000',
      volume: '1008017.1146500000000000',
      quoteVolume: 312540982.32200617,
      btcVolume: 26554.17730475792,
      usdVolume: 312540982.32200617,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '298.3500000000000000',
      high: '313.5800000000000000',
      low: '295.2200000000000000',
      close: '310.9400000000000000',
      volume: '529156.4880900000000000',
      quoteVolume: 161833162.7906752,
      btcVolume: 13747.592392081055,
      usdVolume: 161833162.7906752,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '311.0400000000000000',
      high: '324.7400000000000000',
      low: '295.1800000000000000',
      close: '317.6200000000000000',
      volume: '424971.8825400000000000',
      quoteVolume: 130248119.6772739,
      btcVolume: 10938.351707879097,
      usdVolume: 130248119.6772739,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '317.6200000000000000',
      high: '323.5000000000000000',
      low: '290.1200000000000000',
      close: '292.6500000000000000',
      volume: '489053.0179000000000000',
      quoteVolume: 149535574.11292773,
      btcVolume: 12975.985612883589,
      usdVolume: 149535574.11292773,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '292.7900000000000000',
      high: '303.4900000000000000',
      low: '280.1300000000000000',
      close: '295.0700000000000000',
      volume: '489783.9887600000000000',
      quoteVolume: 142876245.52713487,
      btcVolume: 13339.488752079711,
      usdVolume: 142876245.52713487,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '295.0300000000000000',
      high: '297.5700000000000000',
      low: '272.6500000000000000',
      close: '291.4800000000000000',
      volume: '493473.2749400000000000',
      quoteVolume: 139935800.5385573,
      btcVolume: 13679.520911516493,
      usdVolume: 139935800.5385573,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '291.4700000000000000',
      high: '302.8900000000000000',
      low: '285.0000000000000000',
      close: '301.1400000000000000',
      volume: '369294.5282900000000000',
      quoteVolume: 108898870.22858743,
      btcVolume: 9594.832986006992,
      usdVolume: 108898870.22858743,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '301.1100000000000000',
      high: '302.7400000000000000',
      low: '280.5300000000000000',
      close: '282.8200000000000000',
      volume: '269955.9546000000000000',
      quoteVolume: 79265333.87642588,
      btcVolume: 6797.331954964179,
      usdVolume: 79265333.87642588,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '282.8300000000000000',
      high: '294.8900000000000000',
      low: '280.7800000000000000',
      close: '287.4700000000000000',
      volume: '261133.4233100000000000',
      quoteVolume: 75163436.31124392,
      btcVolume: 6755.597179253407,
      usdVolume: 75163436.31124392,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '287.3200000000000000',
      high: '296.7100000000000000',
      low: '285.2800000000000000',
      close: '288.2300000000000000',
      volume: '196064.5701800000000000',
      quoteVolume: 57137760.438672334,
      btcVolume: 5022.881732165552,
      usdVolume: 57137760.438672334,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '288.2900000000000000',
      high: '309.6900000000000000',
      low: '284.2500000000000000',
      close: '304.7200000000000000',
      volume: '246110.6519400000000000',
      quoteVolume: 73170593.82215981,
      btcVolume: 6461.019883114503,
      usdVolume: 73170593.82215981,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '304.7200000000000000',
      high: '313.7400000000000000',
      low: '300.7800000000000000',
      close: '312.0300000000000000',
      volume: '286873.6683400000000000',
      quoteVolume: 88048229.33933607,
      btcVolume: 7475.430726388852,
      usdVolume: 88048229.33933607,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '311.9700000000000000',
      high: '318.1900000000000000',
      low: '302.6900000000000000',
      close: '307.4700000000000000',
      volume: '305980.4263800000000000',
      quoteVolume: 95131881.31853455,
      btcVolume: 7644.49484918057,
      usdVolume: 95131881.31853455,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '307.4600000000000000',
      high: '314.1800000000000000',
      low: '281.0400000000000000',
      close: '289.0700000000000000',
      volume: '453318.0444900000000000',
      quoteVolume: 134696704.5792062,
      btcVolume: 10731.201391195193,
      usdVolume: 134696704.5792062,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '289.2300000000000000',
      high: '289.2300000000000000',
      low: '262.1200000000000000',
      close: '268.6300000000000000',
      volume: '499749.9402200000000000',
      quoteVolume: 135963256.5679943,
      btcVolume: 11808.225077232584,
      usdVolume: 135963256.5679943,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '268.6700000000000000',
      high: '278.9900000000000000',
      low: '266.2100000000000000',
      close: '274.6200000000000000',
      volume: '266703.2448100000000000',
      quoteVolume: 72734970.85533215,
      btcVolume: 6307.693377250677,
      usdVolume: 72734970.85533215,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '274.5300000000000000',
      high: '275.0400000000000000',
      low: '262.2000000000000000',
      close: '268.7900000000000000',
      volume: '205956.2684900000000000',
      quoteVolume: 55322770.19371056,
      btcVolume: 4873.915839593925,
      usdVolume: 55322770.19371056,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '268.8000000000000000',
      high: '269.7400000000000000',
      low: '222.8100000000000000',
      close: '225.8700000000000000',
      volume: '530763.9314500000000000',
      quoteVolume: 128508198.8008858,
      btcVolume: 12029.780617969636,
      usdVolume: 128508198.8008858,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '225.8700000000000000',
      high: '235.5800000000000000',
      low: '203.0800000000000000',
      close: '228.2000000000000000',
      volume: '713930.7343200000000000',
      quoteVolume: 159360393.30564103,
      btcVolume: 15433.265597050142,
      usdVolume: 159360393.30564103,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '228.2000000000000000',
      high: '234.5000000000000000',
      low: '192.2500000000000000',
      close: '198.8800000000000000',
      volume: '703463.4360500000000000',
      quoteVolume: 149069538.53840336,
      btcVolume: 14722.108901822574,
      usdVolume: 149069538.53840336,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '198.8900000000000000',
      high: '219.8600000000000000',
      low: '191.8400000000000000',
      close: '210.8100000000000000',
      volume: '694454.2491200000000000',
      quoteVolume: 142940279.97858965,
      btcVolume: 14966.311310757123,
      usdVolume: 142940279.97858965,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '210.8100000000000000',
      high: '229.6500000000000000',
      low: '205.7100000000000000',
      close: '225.7600000000000000',
      volume: '607720.5507500000000000',
      quoteVolume: 132341223.14210978,
      btcVolume: 13229.997952454805,
      usdVolume: 132225049.702838,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '225.7300000000000000',
      high: '226.2300000000000000',
      low: '213.4000000000000000',
      close: '220.7300000000000000',
      volume: '367728.2543200000000000',
      quoteVolume: 80763332.4244571,
      btcVolume: 7739.2579698659,
      usdVolume: 80763332.4244571,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '220.8400000000000000',
      high: '235.0900000000000000',
      low: '219.7800000000000000',
      close: '228.2000000000000000',
      volume: '324481.0616300000000000',
      quoteVolume: 73975253.97204855,
      btcVolume: 6947.4776892394175,
      usdVolume: 73975253.97204855,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '228.2500000000000000',
      high: '229.6600000000000000',
      low: '216.9900000000000000',
      close: '225.4200000000000000',
      volume: '269941.8271000000000000',
      quoteVolume: 60398168.970782824,
      btcVolume: 5727.828146485641,
      usdVolume: 60398168.970782824,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '225.3800000000000000',
      high: '228.3400000000000000',
      low: '212.2500000000000000',
      close: '217.5100000000000000',
      volume: '270400.5234700000000000',
      quoteVolume: 59405061.677015305,
      btcVolume: 5704.734783903516,
      usdVolume: 59405061.677015305,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '217.5900000000000000',
      high: '219.5500000000000000',
      low: '208.3600000000000000',
      close: '212.6500000000000000',
      volume: '317846.4293800000000000',
      quoteVolume: 67923781.93982494,
      btcVolume: 6750.32948411748,
      usdVolume: 67923781.93982494,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '212.4500000000000000',
      high: '218.2800000000000000',
      low: '202.0000000000000000',
      close: '216.2400000000000000',
      volume: '330735.0082700000000000',
      quoteVolume: 69192671.19910969,
      btcVolume: 7118.715377791444,
      usdVolume: 69192671.19910969,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '216.3500000000000000',
      high: '225.1200000000000000',
      low: '215.2300000000000000',
      close: '219.1400000000000000',
      volume: '280365.4146300000000000',
      quoteVolume: 62016363.6238403,
      btcVolume: 6193.944875951812,
      usdVolume: 62016363.6238403,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '219.1400000000000000',
      high: '220.0000000000000000',
      low: '212.7100000000000000',
      close: '218.8100000000000000',
      volume: '197602.4657900000000000',
      quoteVolume: 42800909.35558814,
      btcVolume: 4382.145350153194,
      usdVolume: 42800909.35558814,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '218.8100000000000000',
      high: '223.3000000000000000',
      low: '203.0000000000000000',
      close: '207.2300000000000000',
      volume: '296667.0630100000000000',
      quoteVolume: 62964379.504462086,
      btcVolume: 6496.223467135922,
      usdVolume: 62964379.504462086,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '207.3300000000000000',
      high: '213.5200000000000000',
      low: '198.2400000000000000',
      close: '211.6200000000000000',
      volume: '214577.6842300000000000',
      quoteVolume: 44564653.222721346,
      btcVolume: 4706.346521404506,
      usdVolume: 44564653.222721346,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '211.5800000000000000',
      high: '215.8300000000000000',
      low: '206.5900000000000000',
      close: '210.8900000000000000',
      volume: '225967.7936000000000000',
      quoteVolume: 47645918.66213094,
      btcVolume: 4986.044256069211,
      usdVolume: 47645918.66213094,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '210.8400000000000000',
      high: '214.3600000000000000',
      low: '204.4000000000000000',
      close: '209.5800000000000000',
      volume: '175533.8397300000000000',
      quoteVolume: 36699059.76343268,
      btcVolume: 3850.9828761000917,
      usdVolume: 36698995.585413486,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '209.5700000000000000',
      high: '218.7900000000000000',
      low: '209.2000000000000000',
      close: '218.4200000000000000',
      volume: '207213.5565800000000000',
      quoteVolume: 44416634.90026903,
      btcVolume: 4513.526614405959,
      usdVolume: 44416634.90026903,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '218.4200000000000000',
      high: '219.3900000000000000',
      low: '210.5400000000000000',
      close: '216.8400000000000000',
      volume: '186806.1884400000000000',
      quoteVolume: 40054998.682404175,
      btcVolume: 3972.6309014935255,
      usdVolume: 40054998.682404175,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '216.8000000000000000',
      high: '222.1800000000000000',
      low: '214.3100000000000000',
      close: '217.6100000000000000',
      volume: '204158.9276400000000000',
      quoteVolume: 44530976.07793969,
      btcVolume: 4253.794355309355,
      usdVolume: 44530976.07793969,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '217.6900000000000000',
      high: '224.5100000000000000',
      low: '216.6200000000000000',
      close: '222.1400000000000000',
      volume: '181591.9529600000000000',
      quoteVolume: 40285734.53192182,
      btcVolume: 3743.4532071145845,
      usdVolume: 40285734.53192182,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '222.1400000000000000',
      high: '223.3400000000000000',
      low: '216.9000000000000000',
      close: '221.7900000000000000',
      volume: '134883.5495500000000000',
      quoteVolume: 29674383.960012592,
      btcVolume: 2749.4027954145995,
      usdVolume: 29674383.960012592,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '221.7900000000000000',
      high: '236.2500000000000000',
      low: '221.7900000000000000',
      close: '233.5400000000000000',
      volume: '307911.0830700000000000',
      quoteVolume: 70951607.64964774,
      btcVolume: 6104.385556472976,
      usdVolume: 70951607.64964774,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '233.5300000000000000',
      high: '239.1500000000000000',
      low: '223.0300000000000000',
      close: '226.2800000000000000',
      volume: '332632.0690500000000000',
      quoteVolume: 76612125.24778406,
      btcVolume: 6481.8262212509835,
      usdVolume: 76612125.24778406,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '226.3100000000000000',
      high: '231.2500000000000000',
      low: '220.9500000000000000',
      close: '226.1000000000000000',
      volume: '276075.3803400000000000',
      quoteVolume: 62453877.71585768,
      btcVolume: 5316.932199587181,
      usdVolume: 62453877.71585768,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '226.1100000000000000',
      high: '228.5000000000000000',
      low: '215.5100000000000000',
      close: '221.3800000000000000',
      volume: '235285.6142500000000000',
      quoteVolume: 52379480.13759483,
      btcVolume: 4440.469334714355,
      usdVolume: 52379480.13759483,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '221.3800000000000000',
      high: '221.7900000000000000',
      low: '207.3000000000000000',
      close: '210.5900000000000000',
      volume: '230958.0568000000000000',
      quoteVolume: 49180479.38156705,
      btcVolume: 4160.003984113228,
      usdVolume: 49180479.38156705,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '210.5600000000000000',
      high: '215.0000000000000000',
      low: '202.6000000000000000',
      close: '206.4800000000000000',
      volume: '248474.4130400000000000',
      quoteVolume: 51678057.27487786,
      btcVolume: 4460.255650817106,
      usdVolume: 51678057.27487786,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '206.4800000000000000',
      high: '216.9400000000000000',
      low: '206.1400000000000000',
      close: '216.5700000000000000',
      volume: '187625.9175000000000000',
      quoteVolume: 39691954.109307386,
      btcVolume: 3486.7880886797293,
      usdVolume: 39691954.109307386,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '216.5700000000000000',
      high: '216.8400000000000000',
      low: '209.7500000000000000',
      close: '211.5600000000000000',
      volume: '123598.5398700000000000',
      quoteVolume: 26272463.184408363,
      btcVolume: 2303.3073218777877,
      usdVolume: 26272463.184408363,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '211.4100000000000000',
      high: '214.3000000000000000',
      low: '204.0000000000000000',
      close: '209.3300000000000000',
      volume: '166922.4822300000000000',
      quoteVolume: 34861802.56011053,
      btcVolume: 3125.4337664048094,
      usdVolume: 34861802.56011053,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '209.3000000000000000',
      high: '209.9000000000000000',
      low: '183.5600000000000000',
      close: '187.0900000000000000',
      volume: '310158.0672200000000000',
      quoteVolume: 60710593.62070666,
      btcVolume: 5862.423952661254,
      usdVolume: 60710593.62070666,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '187.1000000000000000',
      high: '189.9500000000000000',
      low: '181.2300000000000000',
      close: '188.0300000000000000',
      volume: '237166.2993100000000000',
      quoteVolume: 44039710.624289505,
      btcVolume: 4342.288061699282,
      usdVolume: 44039710.624289505,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '187.9800000000000000',
      high: '188.3900000000000000',
      low: '178.0400000000000000',
      close: '184.8800000000000000',
      volume: '282176.9227900000000000',
      quoteVolume: 51945165.33384251,
      btcVolume: 5121.74136567943,
      usdVolume: 51945165.33384251,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '184.8300000000000000',
      high: '187.0000000000000000',
      low: '181.8300000000000000',
      close: '185.5900000000000000',
      volume: '138799.6150800000000000',
      quoteVolume: 25599796.904651288,
      btcVolume: 2487.8626142405346,
      usdVolume: 25599796.904651288,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '185.6700000000000000',
      high: '197.9100000000000000',
      low: '183.3500000000000000',
      close: '194.3300000000000000',
      volume: '175216.9309700000000000',
      quoteVolume: 33627585.449778885,
      btcVolume: 3252.016245410699,
      usdVolume: 33627585.449778885,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '194.3200000000000000',
      high: '203.5900000000000000',
      low: '192.7000000000000000',
      close: '202.2800000000000000',
      volume: '239158.0927100000000000',
      quoteVolume: 47599954.978613,
      btcVolume: 4479.389668521723,
      usdVolume: 47599954.978613,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '202.2400000000000000',
      high: '202.7500000000000000',
      low: '194.4500000000000000',
      close: '196.6000000000000000',
      volume: '189101.1123700000000000',
      quoteVolume: 37373990.242117286,
      btcVolume: 3483.295716474263,
      usdVolume: 37373990.242117286,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '196.5500000000000000',
      high: '197.2000000000000000',
      low: '179.5300000000000000',
      close: '187.4500000000000000',
      volume: '284348.0028800000000000',
      quoteVolume: 53317203.7731113,
      btcVolume: 5220.638258204045,
      usdVolume: 53317203.7731113,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '187.4500000000000000',
      high: '195.1400000000000000',
      low: '182.8000000000000000',
      close: '190.4000000000000000',
      volume: '245448.2435900000000000',
      quoteVolume: 46402513.17893997,
      btcVolume: 4613.9219820769085,
      usdVolume: 46402513.17893997,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '190.3500000000000000',
      high: '196.1900000000000000',
      low: '188.1600000000000000',
      close: '194.0200000000000000',
      volume: '171898.6596700000000000',
      quoteVolume: 33061516.57383751,
      btcVolume: 3230.660408468503,
      usdVolume: 33061516.57383751,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '194.0200000000000000',
      high: '194.0900000000000000',
      low: '185.6300000000000000',
      close: '190.6000000000000000',
      volume: '167806.3429400000000000',
      quoteVolume: 31786227.862663552,
      btcVolume: 3148.57127433382,
      usdVolume: 31786227.862663552,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '190.6000000000000000',
      high: '192.4000000000000000',
      low: '182.8000000000000000',
      close: '186.5400000000000000',
      volume: '168041.6810300000000000',
      quoteVolume: 31596795.659395352,
      btcVolume: 3133.1655657182055,
      usdVolume: 31596795.659395352,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '186.5400000000000000',
      high: '193.7000000000000000',
      low: '186.0000000000000000',
      close: '188.5900000000000000',
      volume: '248347.1284900000000000',
      quoteVolume: 47147744.7486281,
      btcVolume: 4546.553792072835,
      usdVolume: 47147744.7486281,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '188.6400000000000000',
      high: '189.4900000000000000',
      low: '184.7500000000000000',
      close: '187.2400000000000000',
      volume: '157901.7820000000000000',
      quoteVolume: 29513850.7927091,
      btcVolume: 2896.2658559704814,
      usdVolume: 29513850.7927091,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '187.3000000000000000',
      high: '188.2200000000000000',
      low: '166.4800000000000000',
      close: '173.0300000000000000',
      volume: '305449.4437100000000000',
      quoteVolume: 54488502.82976617,
      btcVolume: 5471.412145390252,
      usdVolume: 54488502.82976617,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '173.0300000000000000',
      high: '173.5000000000000000',
      low: '163.6100000000000000',
      close: '169.0100000000000000',
      volume: '295241.2160000000000000',
      quoteVolume: 49838097.166188926,
      btcVolume: 5238.717079717483,
      usdVolume: 49838097.166188926,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '169.0300000000000000',
      high: '170.7700000000000000',
      low: '165.5500000000000000',
      close: '168.5000000000000000',
      volume: '238511.3647300000000000',
      quoteVolume: 40199011.83567741,
      btcVolume: 4214.966705259767,
      usdVolume: 40199011.83567741,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '168.4800000000000000',
      high: '174.9800000000000000',
      low: '165.6300000000000000',
      close: '171.5700000000000000',
      volume: '194999.1958300000000000',
      quoteVolume: 32983642.84325837,
      btcVolume: 3439.4432364087634,
      usdVolume: 32983642.84325837,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '171.5200000000000000',
      high: '173.4200000000000000',
      low: '167.6100000000000000',
      close: '170.7400000000000000',
      volume: '191088.8305400000000000',
      quoteVolume: 32618691.233790867,
      btcVolume: 3391.76802121759,
      usdVolume: 32618691.233790867,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '170.7300000000000000',
      high: '181.0000000000000000',
      low: '170.0200000000000000',
      close: '178.0500000000000000',
      volume: '294320.5420600000000000',
      quoteVolume: 51392254.91867205,
      btcVolume: 5145.478077032096,
      usdVolume: 51392254.91867205,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '178.0000000000000000',
      high: '183.0000000000000000',
      low: '174.2800000000000000',
      close: '178.7500000000000000',
      volume: '325198.5117100000000000',
      quoteVolume: 58155903.15998513,
      btcVolume: 5528.606852780161,
      usdVolume: 58155903.15998513,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '178.7900000000000000',
      high: '180.1400000000000000',
      low: '173.0000000000000000',
      close: '174.7200000000000000',
      volume: '286198.2752900000000000',
      quoteVolume: 50624411.02937586,
      btcVolume: 4799.552094657055,
      usdVolume: 50624411.02937586,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '174.7000000000000000',
      high: '176.1900000000000000',
      low: '168.1000000000000000',
      close: '173.7500000000000000',
      volume: '231857.0212900000000000',
      quoteVolume: 40092220.48523467,
      btcVolume: 3798.553502855217,
      usdVolume: 40092220.48523467,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '173.7400000000000000',
      high: '177.8700000000000000',
      low: '165.0000000000000000',
      close: '169.0800000000000000',
      volume: '310365.3140900000000000',
      quoteVolume: 53620745.30355337,
      btcVolume: 5073.360788024353,
      usdVolume: 53620745.30355337,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '169.1100000000000000',
      high: '180.8000000000000000',
      low: '168.3000000000000000',
      close: '177.6200000000000000',
      volume: '251633.8069800000000000',
      quoteVolume: 43892369.55699689,
      btcVolume: 4209.284863585666,
      usdVolume: 43892369.55699689,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '177.5800000000000000',
      high: '184.1800000000000000',
      low: '176.3000000000000000',
      close: '181.1900000000000000',
      volume: '288720.1333900000000000',
      quoteVolume: 52178342.21652669,
      btcVolume: 4999.145556813438,
      usdVolume: 52178342.21652669,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '181.1800000000000000',
      high: '185.3800000000000000',
      low: '176.0100000000000000',
      close: '180.5400000000000000',
      volume: '270831.2111100000000000',
      quoteVolume: 48805236.52920722,
      btcVolume: 4741.73754696686,
      usdVolume: 48805236.52920722,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '180.5200000000000000',
      high: '184.3600000000000000',
      low: '177.0000000000000000',
      close: '179.8800000000000000',
      volume: '238217.8448800000000000',
      quoteVolume: 43037704.07242178,
      btcVolume: 4213.994957892011,
      usdVolume: 43037704.07242178,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '179.8700000000000000',
      high: '182.8000000000000000',
      low: '173.0000000000000000',
      close: '178.2800000000000000',
      volume: '278560.0964800000000000',
      quoteVolume: 49658310.75524305,
      btcVolume: 4937.86830579354,
      usdVolume: 49658310.75524305,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '178.3000000000000000',
      high: '182.3800000000000000',
      low: '176.6200000000000000',
      close: '180.7200000000000000',
      volume: '203335.8256500000000000',
      quoteVolume: 36383120.69543437,
      btcVolume: 3557.784215657219,
      usdVolume: 36383120.69543437,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '180.7100000000000000',
      high: '181.3800000000000000',
      low: '177.5400000000000000',
      close: '180.9500000000000000',
      volume: '264421.1936600000000000',
      quoteVolume: 47382185.41032426,
      btcVolume: 4605.8993130550225,
      usdVolume: 47382185.41032426,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '180.9600000000000000',
      high: '188.7900000000000000',
      low: '179.7500000000000000',
      close: '188.1300000000000000',
      volume: '279315.8876300000000000',
      quoteVolume: 51414430.17027494,
      btcVolume: 4974.16648427331,
      usdVolume: 51414430.17027494,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '188.1400000000000000',
      high: '190.4500000000000000',
      low: '185.7600000000000000',
      close: '189.0300000000000000',
      volume: '288928.6082700000000000',
      quoteVolume: 54373471.38522485,
      btcVolume: 5276.705425791319,
      usdVolume: 54373471.38522485,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '189.0500000000000000',
      high: '199.4400000000000000',
      low: '188.3000000000000000',
      close: '197.2300000000000000',
      volume: '549687.6874800000000000',
      quoteVolume: 106195854.54559186,
      btcVolume: 10379.571268151718,
      usdVolume: 106195854.54559186,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '197.2200000000000000',
      high: '215.1300000000000000',
      low: '195.7400000000000000',
      close: '207.8400000000000000',
      volume: '715858.9165100000000000',
      quoteVolume: 145944198.49593797,
      btcVolume: 14286.041939823335,
      usdVolume: 145944198.49593797,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '207.8500000000000000',
      high: '217.2700000000000000',
      low: '207.6600000000000000',
      close: '210.2100000000000000',
      volume: '539028.1435900000000000',
      quoteVolume: 114703029.02276355,
      btcVolume: 11256.168265878949,
      usdVolume: 114703029.02276355,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '210.2700000000000000',
      high: '223.9400000000000000',
      low: '202.3000000000000000',
      close: '220.2400000000000000',
      volume: '831524.9647500000000000',
      quoteVolume: 176952550.75094664,
      btcVolume: 17699.54227982766,
      usdVolume: 176952550.75094664,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '220.2600000000000000',
      high: '221.5400000000000000',
      low: '212.0500000000000000',
      close: '218.0300000000000000',
      volume: '437246.7696400000000000',
      quoteVolume: 94949393.91495149,
      btcVolume: 9332.199000692983,
      usdVolume: 94949393.91495149,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '218.0100000000000000',
      high: '221.5000000000000000',
      low: '213.2000000000000000',
      close: '215.0500000000000000',
      volume: '417891.5242000000000000',
      quoteVolume: 90638548.23529331,
      btcVolume: 9023.05159525662,
      usdVolume: 90638548.23529331,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '215.0400000000000000',
      high: '215.6100000000000000',
      low: '206.1000000000000000',
      close: '211.2000000000000000',
      volume: '445348.5123300000000000',
      quoteVolume: 93653967.98538981,
      btcVolume: 9389.012165487718,
      usdVolume: 93653967.98538981,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '211.2000000000000000',
      high: '211.6800000000000000',
      low: '198.6500000000000000',
      close: '201.2900000000000000',
      volume: '392320.7899200000000000',
      quoteVolume: 80899036.09704779,
      btcVolume: 8221.249859609254,
      usdVolume: 80899036.09704779,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '201.2500000000000000',
      high: '202.9800000000000000',
      low: '150.9700000000000000',
      close: '165.6900000000000000',
      volume: '1370149.8274300000000000',
      quoteVolume: 245718654.8582988,
      btcVolume: 27141.120316206176,
      usdVolume: 245718654.8582988,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '165.7400000000000000',
      high: '174.8500000000000000',
      low: '161.8800000000000000',
      close: '169.9700000000000000',
      volume: '878956.3378100000000000',
      quoteVolume: 147611978.20665354,
      btcVolume: 17503.743620916684,
      usdVolume: 147611978.20665354,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '169.9600000000000000',
      high: '171.0100000000000000',
      low: '152.1100000000000000',
      close: '165.9200000000000000',
      volume: '770883.6988700000000000',
      quoteVolume: 126485225.43295789,
      btcVolume: 15552.32826268824,
      usdVolume: 126485225.43295789,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '165.9200000000000000',
      high: '176.7200000000000000',
      low: '161.0300000000000000',
      close: '173.7900000000000000',
      volume: '634893.1348000000000000',
      quoteVolume: 106454562.85455583,
      btcVolume: 13228.604400340973,
      usdVolume: 106454522.86135663,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '173.8300000000000000',
      high: '175.4900000000000000',
      low: '168.0000000000000000',
      close: '173.4900000000000000',
      volume: '517943.1928800000000000',
      quoteVolume: 89460465.58471853,
      btcVolume: 11001.489635218039,
      usdVolume: 89460465.58471853,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '173.4900000000000000',
      high: '174.5000000000000000',
      low: '164.1200000000000000',
      close: '169.2400000000000000',
      volume: '410856.2773000000000000',
      quoteVolume: 69327426.95232227,
      btcVolume: 8623.060832254734,
      usdVolume: 69327426.95232227,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '169.2600000000000000',
      high: '181.2400000000000000',
      low: '165.0100000000000000',
      close: '180.8500000000000000',
      volume: '580295.3997000000000000',
      quoteVolume: 100443187.77168407,
      btcVolume: 12452.06118869834,
      usdVolume: 100443187.77168407,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '180.8900000000000000',
      high: '185.5300000000000000',
      low: '173.1900000000000000',
      close: '175.6600000000000000',
      volume: '609952.9387900000000000',
      quoteVolume: 109361456.4661927,
      btcVolume: 13111.359686678557,
      usdVolume: 109361456.4661927,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '175.6500000000000000',
      high: '181.2900000000000000',
      low: '173.6500000000000000',
      close: '180.2400000000000000',
      volume: '348268.1162000000000000',
      quoteVolume: 61530973.80423232,
      btcVolume: 7459.867727760892,
      usdVolume: 61530973.80423232,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '180.2400000000000000',
      high: '180.7200000000000000',
      low: '169.5500000000000000',
      close: '174.6900000000000000',
      volume: '354756.7847800000000000',
      quoteVolume: 62001237.48178279,
      btcVolume: 7563.155490555289,
      usdVolume: 62001237.48178279,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '174.7100000000000000',
      high: '178.9800000000000000',
      low: '170.7400000000000000',
      close: '175.5500000000000000',
      volume: '333817.1892000000000000',
      quoteVolume: 58456089.07824363,
      btcVolume: 7179.203250367646,
      usdVolume: 58456089.07824363,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '175.5500000000000000',
      high: '176.7100000000000000',
      low: '172.0200000000000000',
      close: '176.2500000000000000',
      volume: '278096.7557300000000000',
      quoteVolume: 48579546.419203795,
      btcVolume: 6005.859799986798,
      usdVolume: 48579546.419203795,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '176.2300000000000000',
      high: '177.0400000000000000',
      low: '167.6800000000000000',
      close: '170.1200000000000000',
      volume: '313627.4917500000000000',
      quoteVolume: 54020278.79604051,
      btcVolume: 6804.035467902695,
      usdVolume: 54020278.79604051,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '170.1000000000000000',
      high: '182.3200000000000000',
      low: '168.6800000000000000',
      close: '179.8800000000000000',
      volume: '496335.0680100000000000',
      quoteVolume: 87584450.44061345,
      btcVolume: 10846.12187727844,
      usdVolume: 87584450.44061345,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '179.8900000000000000',
      high: '184.8700000000000000',
      low: '177.0000000000000000',
      close: '180.6000000000000000',
      volume: '400833.1661700000000000',
      quoteVolume: 72432871.7087306,
      btcVolume: 8826.82276500747,
      usdVolume: 72432871.7087306,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '180.6100000000000000',
      high: '195.5300000000000000',
      low: '178.9600000000000000',
      close: '192.6200000000000000',
      volume: '557835.1119300000000000',
      quoteVolume: 104652037.76379311,
      btcVolume: 12510.317498902197,
      usdVolume: 104652037.76379311,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '192.6100000000000000',
      high: '194.2000000000000000',
      low: '186.8800000000000000',
      close: '191.1400000000000000',
      volume: '436576.1546100000000000',
      quoteVolume: 83365576.54034896,
      btcVolume: 9773.782550866496,
      usdVolume: 83365576.54034896,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '191.1400000000000000',
      high: '196.6500000000000000',
      low: '179.4100000000000000',
      close: '180.6500000000000000',
      volume: '621046.7695900000000000',
      quoteVolume: 115752733.32752092,
      btcVolume: 13750.640098528595,
      usdVolume: 115752733.32752092,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '180.6500000000000000',
      high: '184.6400000000000000',
      low: '177.5900000000000000',
      close: '179.6800000000000000',
      volume: '290372.9091400000000000',
      quoteVolume: 52887563.36652836,
      btcVolume: 6354.686685667993,
      usdVolume: 52887563.36652836,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '179.6500000000000000',
      high: '184.9500000000000000',
      low: '178.5200000000000000',
      close: '180.9900000000000000',
      volume: '247588.8460700000000000',
      quoteVolume: 45067270.51547066,
      btcVolume: 5399.218077546016,
      usdVolume: 45067270.51547066,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '181.0000000000000000',
      high: '187.5400000000000000',
      low: '180.4300000000000000',
      close: '186.7200000000000000',
      volume: '279731.4719000000000000',
      quoteVolume: 51494611.47577687,
      btcVolume: 6199.09079783479,
      usdVolume: 51494611.47577687,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '186.7100000000000000',
      high: '188.3700000000000000',
      low: '175.9600000000000000',
      close: '180.4900000000000000',
      volume: '400889.5286500000000000',
      quoteVolume: 73178688.57269453,
      btcVolume: 8861.147026864002,
      usdVolume: 73178688.57269453,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '180.4900000000000000',
      high: '181.4400000000000000',
      low: '171.8100000000000000',
      close: '174.4700000000000000',
      volume: '347333.7878200000000000',
      quoteVolume: 61105309.00180442,
      btcVolume: 7601.657333953499,
      usdVolume: 61105309.00180442,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '174.5200000000000000',
      high: '178.9600000000000000',
      low: '172.6100000000000000',
      close: '177.1600000000000000',
      volume: '298795.8198000000000000',
      quoteVolume: 52578052.94403298,
      btcVolume: 6542.154565971178,
      usdVolume: 52578052.94403298,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '177.1700000000000000',
      high: '177.4400000000000000',
      low: '168.6600000000000000',
      close: '172.7400000000000000',
      volume: '318571.4343500000000000',
      quoteVolume: 55106462.09562457,
      btcVolume: 6943.9535656951975,
      usdVolume: 55106462.09562457,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '172.7800000000000000',
      high: '174.9800000000000000',
      low: '169.4400000000000000',
      close: '171.8900000000000000',
      volume: '296902.3488500000000000',
      quoteVolume: 51266629.241353355,
      btcVolume: 6441.994366966404,
      usdVolume: 51266629.241353355,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '171.8600000000000000',
      high: '176.8800000000000000',
      low: '169.2100000000000000',
      close: '175.2200000000000000',
      volume: '299146.0715200000000000',
      quoteVolume: 51655109.15151142,
      btcVolume: 6436.934282521773,
      usdVolume: 51655109.15151142,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '175.1800000000000000',
      high: '177.9000000000000000',
      low: '171.5900000000000000',
      close: '173.9800000000000000',
      volume: '270573.1392900000000000',
      quoteVolume: 47198240.85169531,
      btcVolume: 5741.980152076821,
      usdVolume: 47198240.85169531,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '174.0000000000000000',
      high: '175.0400000000000000',
      low: '170.3000000000000000',
      close: '171.2400000000000000',
      volume: '255425.1915800000000000',
      quoteVolume: 44175399.86161367,
      btcVolume: 5395.723198048019,
      usdVolume: 44175399.86161367,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '171.2000000000000000',
      high: '171.4900000000000000',
      low: '153.4500000000000000',
      close: '162.3500000000000000',
      volume: '707323.9820400000000000',
      quoteVolume: 114779124.2515252,
      btcVolume: 14987.397322367471,
      usdVolume: 114779124.2515252,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '162.3500000000000000',
      high: '163.7200000000000000',
      low: '158.7200000000000000',
      close: '160.3800000000000000',
      volume: '386873.3559300000000000',
      quoteVolume: 62349254.66595083,
      btcVolume: 8383.711086675577,
      usdVolume: 62349254.66595083,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '160.3900000000000000',
      high: '187.7500000000000000',
      low: '160.2500000000000000',
      close: '181.5000000000000000',
      volume: '868260.5296600000000000',
      quoteVolume: 151040465.53858292,
      btcVolume: 18615.31944889506,
      usdVolume: 151040465.53858292,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '181.5300000000000000',
      high: '197.7400000000000000',
      low: '173.8000000000000000',
      close: '179.4900000000000000',
      volume: '1189930.8960700000000000',
      quoteVolume: 218719059.8162375,
      btcVolume: 23386.331259630017,
      usdVolume: 218719059.8162375,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '179.4200000000000000',
      high: '188.7000000000000000',
      low: '176.2200000000000000',
      close: '183.7500000000000000',
      volume: '723948.0442200000000000',
      quoteVolume: 132240902.2748802,
      btcVolume: 13994.80514249749,
      usdVolume: 132240902.2748802,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '183.8400000000000000',
      high: '189.4800000000000000',
      low: '180.3500000000000000',
      close: '181.7200000000000000',
      volume: '581705.0428700000000000',
      quoteVolume: 106847690.6990435,
      btcVolume: 11272.596969686028,
      usdVolume: 106847690.6990435,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '181.6700000000000000',
      high: '192.7400000000000000',
      low: '181.2600000000000000',
      close: '190.4600000000000000',
      volume: '529266.3083100000000000',
      quoteVolume: 98895301.78917634,
      btcVolume: 10554.02278596135,
      usdVolume: 98895301.78917634,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '190.4500000000000000',
      high: '191.7100000000000000',
      low: '179.2800000000000000',
      close: '183.1300000000000000',
      volume: '537770.4305600000000000',
      quoteVolume: 99508269.89031763,
      btcVolume: 10838.56631729719,
      usdVolume: 99508269.89031763,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '183.1400000000000000',
      high: '185.2700000000000000',
      low: '177.6600000000000000',
      close: '182.1800000000000000',
      volume: '410609.1639300000000000',
      quoteVolume: 74631751.23578401,
      btcVolume: 8159.802334886984,
      usdVolume: 74631751.23578401,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '182.1900000000000000',
      high: '184.5000000000000000',
      low: '177.0200000000000000',
      close: '182.8600000000000000',
      volume: '331518.2372300000000000',
      quoteVolume: 60092446.93983594,
      btcVolume: 6574.424243967809,
      usdVolume: 60092446.93983594,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '182.8500000000000000',
      high: '186.0000000000000000',
      low: '181.5300000000000000',
      close: '182.9100000000000000',
      volume: '179852.0178300000000000',
      quoteVolume: 32934405.78327263,
      btcVolume: 3551.462296933882,
      usdVolume: 32934405.78327263,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '182.9000000000000000',
      high: '184.7000000000000000',
      low: '178.9500000000000000',
      close: '181.5400000000000000',
      volume: '232349.8479600000000000',
      quoteVolume: 42211230.11712696,
      btcVolume: 4590.906257325065,
      usdVolume: 42211230.11712696,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '181.5300000000000000',
      high: '188.6400000000000000',
      low: '180.3600000000000000',
      close: '185.7200000000000000',
      volume: '318731.1727400000000000',
      quoteVolume: 58897915.884484924,
      btcVolume: 6352.353989298192,
      usdVolume: 58897915.884484924,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '185.7100000000000000',
      high: '192.5100000000000000',
      low: '182.2200000000000000',
      close: '188.6800000000000000',
      volume: '389421.1059000000000000',
      quoteVolume: 72988899.54730941,
      btcVolume: 7828.37934264765,
      usdVolume: 72988899.54730941,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '188.6500000000000000',
      high: '195.0900000000000000',
      low: '187.7200000000000000',
      close: '191.1600000000000000',
      volume: '343219.9224000000000000',
      quoteVolume: 65471595.20700819,
      btcVolume: 7012.585366600161,
      usdVolume: 65471595.20700819,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '191.1600000000000000',
      high: '192.2700000000000000',
      low: '184.5900000000000000',
      close: '186.6800000000000000',
      volume: '309882.0820600000000000',
      quoteVolume: 58150631.2768292,
      btcVolume: 6291.1791580124855,
      usdVolume: 58150631.2768292,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '186.6700000000000000',
      high: '188.2600000000000000',
      low: '181.4100000000000000',
      close: '183.7400000000000000',
      volume: '364493.9337200000000000',
      quoteVolume: 67207601.06199925,
      btcVolume: 7488.491062509954,
      usdVolume: 67207601.06199925,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '183.7100000000000000',
      high: '185.7900000000000000',
      low: '182.6300000000000000',
      close: '184.8900000000000000',
      volume: '192073.3804400000000000',
      quoteVolume: 35441967.76570041,
      btcVolume: 4019.998146716231,
      usdVolume: 35441967.76570041,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '184.8600000000000000',
      high: '191.5800000000000000',
      low: '183.3000000000000000',
      close: '188.9500000000000000',
      volume: '269941.5343600000000000',
      quoteVolume: 50699742.773780935,
      btcVolume: 5698.653252114131,
      usdVolume: 50699742.773780935,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '188.9600000000000000',
      high: '190.1900000000000000',
      low: '184.0600000000000000',
      close: '184.9800000000000000',
      volume: '254362.4843600000000000',
      quoteVolume: 47520753.42440565,
      btcVolume: 5398.5502773478665,
      usdVolume: 47520753.42440565,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '184.9800000000000000',
      high: '187.6500000000000000',
      low: '182.4100000000000000',
      close: '187.0900000000000000',
      volume: '256637.8790500000000000',
      quoteVolume: 47608963.337230064,
      btcVolume: 5449.8930699344155,
      usdVolume: 47608963.337230064,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '187.0900000000000000',
      high: '189.6600000000000000',
      low: '185.3000000000000000',
      close: '188.1100000000000000',
      volume: '197273.8400100000000000',
      quoteVolume: 36936429.935943484,
      btcVolume: 4212.216828428857,
      usdVolume: 36936429.935943484,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '188.0700000000000000',
      high: '188.7200000000000000',
      low: '183.3400000000000000',
      close: '184.9300000000000000',
      volume: '245478.1899700000000000',
      quoteVolume: 45518688.567261755,
      btcVolume: 5250.084494910773,
      usdVolume: 45518688.567261755,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '184.9200000000000000',
      high: '186.7000000000000000',
      low: '177.6700000000000000',
      close: '180.0000000000000000',
      volume: '404828.6103000000000000',
      quoteVolume: 73739540.13606241,
      btcVolume: 8600.798508976508,
      usdVolume: 73739540.13606241,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '179.9900000000000000',
      high: '183.4600000000000000',
      low: '179.3000000000000000',
      close: '182.3700000000000000',
      volume: '172801.5257600000000000',
      quoteVolume: 31378510.015389085,
      btcVolume: 3700.092412038125,
      usdVolume: 31378510.015389085,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '182.3700000000000000',
      high: '186.0900000000000000',
      low: '180.0600000000000000',
      close: '183.8200000000000000',
      volume: '198323.1213100000000000',
      quoteVolume: 36407476.06173793,
      btcVolume: 4273.182664311965,
      usdVolume: 36407476.06173793,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '183.8200000000000000',
      high: '184.0600000000000000',
      low: '175.0100000000000000',
      close: '178.2000000000000000',
      volume: '292916.4108000000000000',
      quoteVolume: 52873504.41217531,
      btcVolume: 6334.069797912126,
      usdVolume: 52873504.41217531,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '178.2000000000000000',
      high: '178.5200000000000000',
      low: '172.6500000000000000',
      close: '175.9400000000000000',
      volume: '275886.6411000000000000',
      quoteVolume: 48414106.956933685,
      btcVolume: 5968.5359977672715,
      usdVolume: 48414106.956933685,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '175.9300000000000000',
      high: '177.4100000000000000',
      low: '173.5000000000000000',
      close: '174.7200000000000000',
      volume: '216315.9330900000000000',
      quoteVolume: 38001672.19309863,
      btcVolume: 4686.270009046089,
      usdVolume: 38001672.19309863,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '174.7500000000000000',
      high: '175.8500000000000000',
      low: '157.2600000000000000',
      close: '161.0100000000000000',
      volume: '470224.9285800000000000',
      quoteVolume: 78033991.15986177,
      btcVolume: 10011.92260591679,
      usdVolume: 78033991.15986177,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '161.0200000000000000',
      high: '162.7900000000000000',
      low: '138.0000000000000000',
      close: '149.5600000000000000',
      volume: '975937.0667600000000000',
      quoteVolume: 147306766.45684162,
      btcVolume: 20315.079048812546,
      usdVolume: 147306766.45684162,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '149.5500000000000000',
      high: '154.3300000000000000',
      low: '146.1100000000000000',
      close: '151.8600000000000000',
      volume: '369720.2906000000000000',
      quoteVolume: 55595034.725483,
      btcVolume: 7691.525331812389,
      usdVolume: 55595034.725483,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '151.8400000000000000',
      high: '152.8600000000000000',
      low: '138.6200000000000000',
      close: '139.9900000000000000',
      volume: '352318.5460300000000000',
      quoteVolume: 51503621.078441106,
      btcVolume: 7270.627960676268,
      usdVolume: 51503621.078441106,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '139.9600000000000000',
      high: '151.5000000000000000',
      low: '131.4500000000000000',
      close: '145.6900000000000000',
      volume: '749381.0582700000000000',
      quoteVolume: 106143909.57189968,
      btcVolume: 15339.430749701922,
      usdVolume: 106143909.57189968,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '145.8100000000000000',
      high: '149.9700000000000000',
      low: '143.3700000000000000',
      close: '147.4700000000000000',
      volume: '354023.0429800000000000',
      quoteVolume: 51873405.03929025,
      btcVolume: 7258.985728654345,
      usdVolume: 51873405.03929025,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '147.4700000000000000',
      high: '155.5400000000000000',
      low: '140.8400000000000000',
      close: '152.6200000000000000',
      volume: '563679.1596700000000000',
      quoteVolume: 83338641.04602854,
      btcVolume: 11568.893523095534,
      usdVolume: 83338641.04602854,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '152.6100000000000000',
      high: '154.6300000000000000',
      low: '149.0900000000000000',
      close: '150.7200000000000000',
      volume: '317714.1197600000000000',
      quoteVolume: 48324153.77624194,
      btcVolume: 6441.974596553162,
      usdVolume: 48324153.77624194,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '150.7200000000000000',
      high: '157.6000000000000000',
      low: '150.2300000000000000',
      close: '154.5000000000000000',
      volume: '328513.9167000000000000',
      quoteVolume: 50618447.52032263,
      btcVolume: 6641.291265743082,
      usdVolume: 50618447.52032263,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '154.5500000000000000',
      high: '155.2500000000000000',
      low: '149.7000000000000000',
      close: '151.4000000000000000',
      volume: '226862.4671000000000000',
      quoteVolume: 34617789.5827378,
      btcVolume: 4534.817039770797,
      usdVolume: 34617789.5827378,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '151.4300000000000000',
      high: '152.4900000000000000',
      low: '145.7900000000000000',
      close: '150.7300000000000000',
      volume: '344185.5408400000000000',
      quoteVolume: 51149912.23894076,
      btcVolume: 6959.7035482148,
      usdVolume: 51149912.23894076,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '150.7200000000000000',
      high: '151.4200000000000000',
      low: '146.7100000000000000',
      close: '148.6500000000000000',
      volume: '233774.3063000000000000',
      quoteVolume: 34759015.80900913,
      btcVolume: 4765.617361975778,
      usdVolume: 34759015.80900913,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '148.6600000000000000',
      high: '149.9300000000000000',
      low: '145.7700000000000000',
      close: '147.1700000000000000',
      volume: '196329.2250300000000000',
      quoteVolume: 29061161.101775736,
      btcVolume: 3976.947714942431,
      usdVolume: 29061161.101775736,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '147.1900000000000000',
      high: '151.0000000000000000',
      low: '143.1500000000000000',
      close: '145.3800000000000000',
      volume: '418523.8529900000000000',
      quoteVolume: 61465918.00020749,
      btcVolume: 8421.53839803797,
      usdVolume: 61465918.00020749,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '145.4500000000000000',
      high: '149.0200000000000000',
      low: '143.6400000000000000',
      close: '148.1000000000000000',
      volume: '298773.7016400000000000',
      quoteVolume: 43733249.10134732,
      btcVolume: 5975.972289154188,
      usdVolume: 43733249.10134732,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '148.1100000000000000',
      high: '149.7700000000000000',
      low: '145.7400000000000000',
      close: '148.4500000000000000',
      volume: '219804.5782100000000000',
      quoteVolume: 32420380.085808042,
      btcVolume: 4379.421398292797,
      usdVolume: 32420380.085808042,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '148.4600000000000000',
      high: '149.4900000000000000',
      low: '146.8500000000000000',
      close: '147.1400000000000000',
      volume: '140471.6858800000000000',
      quoteVolume: 20799551.345695384,
      btcVolume: 2765.513206109344,
      usdVolume: 20799551.345695384,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '147.1600000000000000',
      high: '151.6200000000000000',
      low: '146.1100000000000000',
      close: '150.4300000000000000',
      volume: '205292.3260400000000000',
      quoteVolume: 30604893.80851998,
      btcVolume: 4083.993478963329,
      usdVolume: 30604893.80851998,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '150.4300000000000000',
      high: '151.1800000000000000',
      low: '146.5600000000000000',
      close: '147.4100000000000000',
      volume: '241824.4918400000000000',
      quoteVolume: 36054914.410974964,
      btcVolume: 4830.038571314736,
      usdVolume: 36054914.410974964,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '147.3900000000000000',
      high: '148.5700000000000000',
      low: '143.8100000000000000',
      close: '145.5600000000000000',
      volume: '202345.9339500000000000',
      quoteVolume: 29619185.42152988,
      btcVolume: 4056.038127495942,
      usdVolume: 29619185.42152988,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '145.5300000000000000',
      high: '146.3400000000000000',
      low: '142.1200000000000000',
      close: '143.3900000000000000',
      volume: '157843.1048400000000000',
      quoteVolume: 22772651.168129046,
      btcVolume: 3157.805594325894,
      usdVolume: 22772651.168129046,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '143.4100000000000000',
      high: '145.8500000000000000',
      low: '139.2400000000000000',
      close: '144.8700000000000000',
      volume: '261614.3454600000000000',
      quoteVolume: 37425141.739041,
      btcVolume: 5217.446754533626,
      usdVolume: 37425141.739041,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '144.8700000000000000',
      high: '146.0000000000000000',
      low: '142.8000000000000000',
      close: '144.8000000000000000',
      volume: '160597.0306700000000000',
      quoteVolume: 23189253.309127137,
      btcVolume: 3205.056989013503,
      usdVolume: 23189253.309127137,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '144.8000000000000000',
      high: '145.0700000000000000',
      low: '141.1800000000000000',
      close: '141.7900000000000000',
      volume: '126232.5920100000000000',
      quoteVolume: 18027581.359546103,
      btcVolume: 2521.1961065175824,
      usdVolume: 18027581.359546103,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '141.7900000000000000',
      high: '144.1200000000000000',
      low: '139.9200000000000000',
      close: '142.4600000000000000',
      volume: '151189.6587700000000000',
      quoteVolume: 21495196.81888501,
      btcVolume: 3029.229605400445,
      usdVolume: 21495196.81888501,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '142.4600000000000000',
      high: '142.7200000000000000',
      low: '127.9300000000000000',
      close: '132.7300000000000000',
      volume: '450883.4902900000000000',
      quoteVolume: 60935923.41354128,
      btcVolume: 8739.56219158376,
      usdVolume: 60935923.41354128,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '132.7200000000000000',
      high: '132.9800000000000000',
      low: '119.1100000000000000',
      close: '121.8800000000000000',
      volume: '554842.5818300000000000',
      quoteVolume: 70638378.5959894,
      btcVolume: 10478.106986160494,
      usdVolume: 70638378.5959894,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '121.8800000000000000',
      high: '134.8700000000000000',
      low: '116.2600000000000000',
      close: '132.7800000000000000',
      volume: '884910.2066800000000000',
      quoteVolume: 110560097.03269246,
      btcVolume: 16307.41864801072,
      usdVolume: 110560097.03269246,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '132.8000000000000000',
      high: '134.0000000000000000',
      low: '125.6900000000000000',
      close: '128.1000000000000000',
      volume: '420674.8172000000000000',
      quoteVolume: 53766864.18518815,
      btcVolume: 7512.382660233176,
      usdVolume: 53766864.18518815,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '128.1000000000000000',
      high: '129.3900000000000000',
      low: '125.8400000000000000',
      close: '128.1900000000000000',
      volume: '213897.4673000000000000',
      quoteVolume: 27266605.48486431,
      btcVolume: 3812.8979207455554,
      usdVolume: 27266605.48486431,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '128.1900000000000000',
      high: '128.4000000000000000',
      low: '126.5000000000000000',
      close: '126.9900000000000000',
      volume: '135196.1164100000000000',
      quoteVolume: 17199278.79468431,
      btcVolume: 2407.517101337839,
      usdVolume: 17199278.79468431,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '127.0000000000000000',
      high: '133.0700000000000000',
      low: '126.8200000000000000',
      close: '132.0900000000000000',
      volume: '252349.1950800000000000',
      quoteVolume: 32837658.598232217,
      btcVolume: 4517.366310910823,
      usdVolume: 32837658.598232217,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '132.1200000000000000',
      high: '135.1000000000000000',
      low: '126.0000000000000000',
      close: '127.8000000000000000',
      volume: '421600.7565500000000000',
      quoteVolume: 55501305.92012195,
      btcVolume: 7393.0923029749465,
      usdVolume: 55501305.92012195,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '127.8000000000000000',
      high: '129.6900000000000000',
      low: '126.6100000000000000',
      close: '127.7100000000000000',
      volume: '200423.4802800000000000',
      quoteVolume: 25640395.796429265,
      btcVolume: 3511.8402799722517,
      usdVolume: 25640395.796429265,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '127.7000000000000000',
      high: '127.8400000000000000',
      low: '123.0700000000000000',
      close: '125.0900000000000000',
      volume: '224856.9553900000000000',
      quoteVolume: 28126021.072964624,
      btcVolume: 3897.9462094468076,
      usdVolume: 28126021.072964624,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '125.0900000000000000',
      high: '132.2600000000000000',
      low: '124.3200000000000000',
      close: '131.1800000000000000',
      volume: '271991.3960900000000000',
      quoteVolume: 34556187.22649918,
      btcVolume: 4757.447100368064,
      usdVolume: 34556187.22649918,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '125.5800000000000000',
      high: '127.1000000000000000',
      low: '121.9100000000000000',
      close: '126.2900000000000000',
      volume: '239584.0186300000000000',
      quoteVolume: 29967207.36584079,
      btcVolume: 4162.919074511555,
      usdVolume: 29967207.36584079,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '126.2800000000000000',
      high: '129.6800000000000000',
      low: '125.8400000000000000',
      close: '128.1100000000000000',
      volume: '196893.5227700000000000',
      quoteVolume: 25177121.147064537,
      btcVolume: 3441.3052732034603,
      usdVolume: 25177121.147064537,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '128.1100000000000000',
      high: '138.0700000000000000',
      low: '127.5200000000000000',
      close: '134.3600000000000000',
      volume: '316304.0786500000000000',
      quoteVolume: 41887251.75431606,
      btcVolume: 5666.2955116504645,
      usdVolume: 41887251.75431606,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '134.3600000000000000',
      high: '136.2400000000000000',
      low: '130.3000000000000000',
      close: '131.5900000000000000',
      volume: '320328.9778500000000000',
      quoteVolume: 42634148.31351888,
      btcVolume: 5822.900412418773,
      usdVolume: 42634148.31351888,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '131.6100000000000000',
      high: '133.6800000000000000',
      low: '128.1700000000000000',
      close: '129.1600000000000000',
      volume: '264902.2166100000000000',
      quoteVolume: 34674502.08294672,
      btcVolume: 4798.822256382279,
      usdVolume: 34674502.08294672,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '129.1600000000000000',
      high: '133.0500000000000000',
      low: '128.6800000000000000',
      close: '130.7700000000000000',
      volume: '144766.0171600000000000',
      quoteVolume: 18951727.853203688,
      btcVolume: 2626.058687621114,
      usdVolume: 18951727.853203688,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '130.7200000000000000',
      high: '130.7800000000000000',
      low: '126.3800000000000000',
      close: '127.1900000000000000',
      volume: '206486.6936400000000000',
      quoteVolume: 26562586.64793831,
      btcVolume: 3748.1292562505037,
      usdVolume: 26562586.64793831,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '127.1900000000000000',
      high: '135.1400000000000000',
      low: '125.8800000000000000',
      close: '134.3500000000000000',
      volume: '412941.3612600000000000',
      quoteVolume: 54124562.00994656,
      btcVolume: 7477.56431390865,
      usdVolume: 54124562.00994656,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '134.3700000000000000',
      high: '135.8500000000000000',
      low: '132.5000000000000000',
      close: '134.2000000000000000',
      volume: '184276.1710200000000000',
      quoteVolume: 24641135.568952836,
      btcVolume: 3361.8386052854335,
      usdVolume: 24641135.568952836,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '134.2000000000000000',
      high: '138.1900000000000000',
      low: '134.1900000000000000',
      close: '135.3700000000000000',
      volume: '254120.4534300000000000',
      quoteVolume: 34593687.60327518,
      btcVolume: 4653.251278572446,
      usdVolume: 34593687.60327518,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '135.3700000000000000',
      high: '144.4100000000000000',
      low: '134.8600000000000000',
      close: '144.1500000000000000',
      volume: '408020.2737500000000000',
      quoteVolume: 57400544.487255365,
      btcVolume: 7600.492701267486,
      usdVolume: 57400544.487255365,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '144.1400000000000000',
      high: '145.3100000000000000',
      low: '138.7600000000000000',
      close: '142.8000000000000000',
      volume: '447762.1728100000000000',
      quoteVolume: 63861666.4047595,
      btcVolume: 8049.865532289283,
      usdVolume: 63861666.4047595,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '142.8000000000000000',
      high: '147.7700000000000000',
      low: '137.0300000000000000',
      close: '140.7200000000000000',
      volume: '561419.2059200000000000',
      quoteVolume: 79913004.38781014,
      btcVolume: 9694.962625535327,
      usdVolume: 79913004.38781014,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '140.7600000000000000',
      high: '141.5000000000000000',
      low: '135.3000000000000000',
      close: '137.7400000000000000',
      volume: '363987.6637700000000000',
      quoteVolume: 50319731.13866686,
      btcVolume: 6374.095494904485,
      usdVolume: 50319731.13866686,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '137.7300000000000000',
      high: '145.1700000000000000',
      low: '135.3200000000000000',
      close: '144.8400000000000000',
      volume: '409400.9174100000000000',
      quoteVolume: 57377160.97009285,
      btcVolume: 7232.247618277078,
      usdVolume: 57377160.97009285,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '144.8300000000000000',
      high: '148.0500000000000000',
      low: '142.0900000000000000',
      close: '142.3800000000000000',
      volume: '368350.5793900000000000',
      quoteVolume: 53149360.56174742,
      btcVolume: 6540.115802381793,
      usdVolume: 53149360.56174742,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '142.4000000000000000',
      high: '146.6000000000000000',
      low: '141.7600000000000000',
      close: '146.5400000000000000',
      volume: '229541.8613700000000000',
      quoteVolume: 33112850.822234996,
      btcVolume: 4081.84868926777,
      usdVolume: 33112850.822234996,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '146.5600000000000000',
      high: '147.0000000000000000',
      low: '142.2700000000000000',
      close: '143.5800000000000000',
      volume: '207971.5884600000000000',
      quoteVolume: 29884467.67111847,
      btcVolume: 3683.879733305947,
      usdVolume: 29884467.67111847,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '143.5800000000000000',
      high: '171.7000000000000000',
      low: '143.5100000000000000',
      close: '165.6400000000000000',
      volume: '1108476.3118600000000000',
      quoteVolume: 173492006.24820682,
      btcVolume: 20115.096219225106,
      usdVolume: 173492006.24820682,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '165.6000000000000000',
      high: '171.9800000000000000',
      low: '159.2000000000000000',
      close: '166.4000000000000000',
      volume: '721560.7995600000000000',
      quoteVolume: 119219193.04167666,
      btcVolume: 13597.93692389315,
      usdVolume: 119219193.04167666,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '166.4000000000000000',
      high: '167.4000000000000000',
      low: '157.8000000000000000',
      close: '164.2100000000000000',
      volume: '456170.8671900000000000',
      quoteVolume: 74038650.82913166,
      btcVolume: 8518.447924957838,
      usdVolume: 74038650.82913166,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '164.2400000000000000',
      high: '174.8100000000000000',
      low: '162.1400000000000000',
      close: '169.8500000000000000',
      volume: '767180.6785300000000000',
      quoteVolume: 129909265.25615731,
      btcVolume: 14632.362690520036,
      usdVolume: 129909265.25615731,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '169.9200000000000000',
      high: '179.5000000000000000',
      low: '164.9200000000000000',
      close: '174.1400000000000000',
      volume: '688748.6367600000000000',
      quoteVolume: 119382479.69063364,
      btcVolume: 13404.09413255703,
      usdVolume: 119382479.69063364,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '174.1000000000000000',
      high: '178.0500000000000000',
      low: '161.6600000000000000',
      close: '166.7900000000000000',
      volume: '624670.6336700000000000',
      quoteVolume: 105689492.0033365,
      btcVolume: 12002.351244357744,
      usdVolume: 105689492.0033365,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '166.7900000000000000',
      high: '169.3300000000000000',
      low: '161.2400000000000000',
      close: '166.8700000000000000',
      volume: '358092.8841000000000000',
      quoteVolume: 59292534.71746608,
      btcVolume: 6852.199304969695,
      usdVolume: 59292534.71746608,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '166.8600000000000000',
      high: '170.3200000000000000',
      low: '164.8000000000000000',
      close: '169.4900000000000000',
      volume: '308007.6353000000000000',
      quoteVolume: 51615232.56698999,
      btcVolume: 5961.4179039502005,
      usdVolume: 51615232.56698999,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '169.4800000000000000',
      high: '171.4700000000000000',
      low: '166.0300000000000000',
      close: '168.0700000000000000',
      volume: '272240.9028600000000000',
      quoteVolume: 45903134.16291278,
      btcVolume: 5277.281438195617,
      usdVolume: 45903134.16291278,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '168.0700000000000000',
      high: '168.2000000000000000',
      low: '159.2100000000000000',
      close: '162.8100000000000000',
      volume: '373414.3498500000000000',
      quoteVolume: 60971659.77458279,
      btcVolume: 7198.282444848286,
      usdVolume: 60971659.77458279,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '162.8500000000000000',
      high: '164.4500000000000000',
      low: '155.5500000000000000',
      close: '162.5400000000000000',
      volume: '430013.1990200000000000',
      quoteVolume: 68980724.06049524,
      btcVolume: 8218.743302588302,
      usdVolume: 68980724.06049524,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '162.5100000000000000',
      high: '162.7900000000000000',
      low: '157.6100000000000000',
      close: '160.3500000000000000',
      volume: '219921.6519700000000000',
      quoteVolume: 35196608.30633701,
      btcVolume: 4216.84914612778,
      usdVolume: 35196608.30633701,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '160.3600000000000000',
      high: '168.0800000000000000',
      low: '159.4100000000000000',
      close: '167.8600000000000000',
      volume: '251582.5575800000000000',
      quoteVolume: 41239031.33790531,
      btcVolume: 4864.643562453211,
      usdVolume: 41239031.33790531,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '167.9100000000000000',
      high: '172.5600000000000000',
      low: '165.2200000000000000',
      close: '170.0800000000000000',
      volume: '365894.8191700000000000',
      quoteVolume: 61846624.61453907,
      btcVolume: 7067.9493571795865,
      usdVolume: 61846624.61453907,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '170.0400000000000000',
      high: '176.2000000000000000',
      low: '170.0300000000000000',
      close: '175.6400000000000000',
      volume: '473433.8960900000000000',
      quoteVolume: 81649497.49294704,
      btcVolume: 9000.09542777262,
      usdVolume: 81649497.49294704,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '175.5800000000000000',
      high: '178.4500000000000000',
      low: '173.3300000000000000',
      close: '173.7200000000000000',
      volume: '317366.2058000000000000',
      quoteVolume: 55985259.09291722,
      btcVolume: 5993.288480446213,
      usdVolume: 55985259.09291722,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '173.6800000000000000',
      high: '187.0000000000000000',
      low: '170.9300000000000000',
      close: '184.6900000000000000',
      volume: '477721.6609000000000000',
      quoteVolume: 85164074.08231418,
      btcVolume: 9048.137874415446,
      usdVolume: 85164074.08231418,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '184.7100000000000000',
      high: '185.8200000000000000',
      low: '175.2200000000000000',
      close: '179.9900000000000000',
      volume: '385596.5336500000000000',
      quoteVolume: 69633052.28263958,
      btcVolume: 7449.645096830591,
      usdVolume: 69633052.28263958,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '179.9400000000000000',
      high: '184.2800000000000000',
      low: '179.2300000000000000',
      close: '183.6000000000000000',
      volume: '259370.1290200000000000',
      quoteVolume: 47263178.06678158,
      btcVolume: 5035.1164574204295,
      usdVolume: 47263178.06678158,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '183.6300000000000000',
      high: '193.4300000000000000',
      low: '179.1000000000000000',
      close: '188.4400000000000000',
      volume: '552621.1361900000000000',
      quoteVolume: 104105613.55878504,
      btcVolume: 11088.57195048023,
      usdVolume: 104105613.55878504,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '188.4800000000000000',
      high: '195.1900000000000000',
      low: '186.6200000000000000',
      close: '189.6900000000000000',
      volume: '417175.9578100000000000',
      quoteVolume: 79336312.5246926,
      btcVolume: 8471.442558236018,
      usdVolume: 79336312.5246926,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '189.7400000000000000',
      high: '191.6000000000000000',
      low: '184.6900000000000000',
      close: '188.9100000000000000',
      volume: '366267.8454800000000000',
      quoteVolume: 68697714.9065747,
      btcVolume: 7449.885768860561,
      usdVolume: 68697714.9065747,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '188.9100000000000000',
      high: '207.6100000000000000',
      low: '188.1900000000000000',
      close: '203.7800000000000000',
      volume: '550942.1141700000000000',
      quoteVolume: 108659358.53591804,
      btcVolume: 11498.225576271325,
      usdVolume: 108659358.53591804,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '203.7800000000000000',
      high: '216.3300000000000000',
      low: '201.0200000000000000',
      close: '213.1900000000000000',
      volume: '602040.0810800000000000',
      quoteVolume: 126577112.92357521,
      btcVolume: 13029.657093734239,
      usdVolume: 126577112.92357521,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '213.1600000000000000',
      high: '225.0000000000000000',
      low: '213.1400000000000000',
      close: '223.3300000000000000',
      volume: '628858.0077800000000000',
      quoteVolume: 138414055.77483606,
      btcVolume: 14123.858774287371,
      usdVolume: 138414055.77483606,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '223.3600000000000000',
      high: '227.7500000000000000',
      low: '213.2200000000000000',
      close: '223.0500000000000000',
      volume: '548551.8746500000000000',
      quoteVolume: 122069507.65510991,
      btcVolume: 12459.442096719877,
      usdVolume: 122069507.65510991,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '223.0100000000000000',
      high: '230.6500000000000000',
      low: '222.8600000000000000',
      close: '228.4900000000000000',
      volume: '350221.2019800000000000',
      quoteVolume: 79648275.7579095,
      btcVolume: 7906.719601131233,
      usdVolume: 79648275.7579095,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '228.5300000000000000',
      high: '229.4000000000000000',
      low: '216.3700000000000000',
      close: '222.8900000000000000',
      volume: '510415.4994900000000000',
      quoteVolume: 113385288.95029798,
      btcVolume: 11438.000262952832,
      usdVolume: 113385288.95029798,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '222.8900000000000000',
      high: '239.1500000000000000',
      low: '218.1700000000000000',
      close: '236.6900000000000000',
      volume: '595576.9027600000000000',
      quoteVolume: 135863928.62430608,
      btcVolume: 13545.116057370578,
      usdVolume: 135863928.62430608,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '236.6900000000000000',
      high: '275.3400000000000000',
      low: '236.6900000000000000',
      close: '265.7400000000000000',
      volume: '1038073.7478200000000000',
      quoteVolume: 266734296.55029422,
      btcVolume: 25827.16221092207,
      usdVolume: 266734296.55029422,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '265.7400000000000000',
      high: '277.6900000000000000',
      low: '256.0800000000000000',
      close: '268.3200000000000000',
      volume: '1083763.8066500000000000',
      quoteVolume: 289993412.75714827,
      btcVolume: 28204.52124006715,
      usdVolume: 289993412.75714827,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '268.3400000000000000',
      high: '287.1500000000000000',
      low: '260.2800000000000000',
      close: '285.1500000000000000',
      volume: '734944.3226600000000000',
      quoteVolume: 201120424.58321652,
      btcVolume: 19585.77364231225,
      usdVolume: 201120424.58321652,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '285.1100000000000000',
      high: '288.4100000000000000',
      low: '261.8600000000000000',
      close: '264.8800000000000000',
      volume: '860767.3711600000000000',
      quoteVolume: 236274810.63895243,
      btcVolume: 23452.950884857593,
      usdVolume: 236274810.63895243,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '264.9100000000000000',
      high: '274.0000000000000000',
      low: '237.4100000000000000',
      close: '258.8500000000000000',
      volume: '1110118.4639500000000000',
      quoteVolume: 286269166.28143066,
      btcVolume: 29048.30557248655,
      usdVolume: 286269166.28143066,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '258.8900000000000000',
      high: '268.7700000000000000',
      low: '242.0000000000000000',
      close: '267.8500000000000000',
      volume: '1110371.3909400000000000',
      quoteVolume: 280371301.7549912,
      btcVolume: 28929.875955319196,
      usdVolume: 280371301.7549912,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '267.9000000000000000',
      high: '285.8800000000000000',
      low: '258.0000000000000000',
      close: '282.6100000000000000',
      volume: '1115523.4399200000000000',
      quoteVolume: 304162263.3061434,
      btcVolume: 30935.19354943302,
      usdVolume: 304162263.3061434,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '282.6400000000000000',
      high: '285.0000000000000000',
      low: '251.5600000000000000',
      close: '258.4500000000000000',
      volume: '702700.0948200000000000',
      quoteVolume: 190968563.2271233,
      btcVolume: 19191.632547085086,
      usdVolume: 190968563.2271233,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '258.4400000000000000',
      high: '264.3300000000000000',
      low: '245.3400000000000000',
      close: '256.9600000000000000',
      volume: '972629.9034800000000000',
      quoteVolume: 249295948.92492777,
      btcVolume: 26026.85890519547,
      usdVolume: 249295948.92492777,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '256.9700000000000000',
      high: '268.2400000000000000',
      low: '253.6100000000000000',
      close: '265.2700000000000000',
      volume: '524737.1325800000000000',
      quoteVolume: 137561519.8080567,
      btcVolume: 14215.99915940384,
      usdVolume: 137561519.8080567,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '265.3200000000000000',
      high: '266.8100000000000000',
      low: '256.0000000000000000',
      close: '261.5700000000000000',
      volume: '313062.5213300000000000',
      quoteVolume: 81781388.31935476,
      btcVolume: 8483.868403005552,
      usdVolume: 81781388.31935476,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '261.5500000000000000',
      high: '275.6800000000000000',
      low: '261.0200000000000000',
      close: '274.4800000000000000',
      volume: '444740.8288300000000000',
      quoteVolume: 120024058.97196795,
      btcVolume: 12171.335680224975,
      usdVolume: 120024058.97196795,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '274.5000000000000000',
      high: '277.2000000000000000',
      low: '257.0900000000000000',
      close: '265.5200000000000000',
      volume: '696591.7298300000000000',
      quoteVolume: 185949182.70858055,
      btcVolume: 19137.450555293988,
      usdVolume: 185949182.70858055,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '265.4700000000000000',
      high: '266.2200000000000000',
      low: '244.4400000000000000',
      close: '246.6700000000000000',
      volume: '774791.0102700000000000',
      quoteVolume: 197442911.5447298,
      btcVolume: 20862.28626803828,
      usdVolume: 197442911.5447298,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '246.6700000000000000',
      high: '250.3200000000000000',
      low: '215.6600000000000000',
      close: '223.9300000000000000',
      volume: '1390938.8858200000000000',
      quoteVolume: 323053459.4097742,
      btcVolume: 35807.633154278985,
      usdVolume: 323053459.4097742,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '223.9800000000000000',
      high: '238.3000000000000000',
      low: '210.0000000000000000',
      close: '227.7900000000000000',
      volume: '1273621.5787700000000000',
      quoteVolume: 287283849.70437545,
      btcVolume: 32695.90481354741,
      usdVolume: 287283849.70437545,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '227.7300000000000000',
      high: '234.6700000000000000',
      low: '214.0100000000000000',
      close: '226.7600000000000000',
      volume: '1054994.9239700000000000',
      quoteVolume: 236064804.33459535,
      btcVolume: 27236.336241981913,
      usdVolume: 236064804.33459535,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '226.7600000000000000',
      high: '233.0000000000000000',
      low: '217.0000000000000000',
      close: '217.2100000000000000',
      volume: '546866.6851000000000000',
      quoteVolume: 123635801.01782297,
      btcVolume: 14249.24078788142,
      usdVolume: 123635801.01782297,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '217.2900000000000000',
      high: '227.8900000000000000',
      low: '212.3600000000000000',
      close: '217.8100000000000000',
      volume: '715016.0194100000000000',
      quoteVolume: 157064953.84227648,
      btcVolume: 18346.386497742988,
      usdVolume: 157064953.84227648,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '217.8100000000000000',
      high: '234.4000000000000000',
      low: '216.0700000000000000',
      close: '231.9700000000000000',
      volume: '810051.4833000000000000',
      quoteVolume: 182948651.67645854,
      btcVolume: 20891.507673847358,
      usdVolume: 182948651.67645854,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '232.1000000000000000',
      high: '232.4600000000000000',
      low: '219.5700000000000000',
      close: '223.9100000000000000',
      volume: '741498.5482500000000000',
      quoteVolume: 168090325.5387952,
      btcVolume: 19140.228688685915,
      usdVolume: 168090325.5387952,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '223.8400000000000000',
      high: '228.8500000000000000',
      low: '220.2300000000000000',
      close: '224.2600000000000000',
      volume: '443780.3377200000000000',
      quoteVolume: 99650220.54162118,
      btcVolume: 11371.931756177451,
      usdVolume: 99650220.54162118,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '224.2600000000000000',
      high: '234.0900000000000000',
      low: '224.2300000000000000',
      close: '228.3800000000000000',
      volume: '601434.2612800000000000',
      quoteVolume: 138071496.98683217,
      btcVolume: 15332.125743401275,
      usdVolume: 138071496.98683217,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '228.3800000000000000',
      high: '245.1600000000000000',
      low: '227.3300000000000000',
      close: '244.8800000000000000',
      volume: '628147.3257000000000000',
      quoteVolume: 148563741.83299482,
      btcVolume: 16336.054719609947,
      usdVolume: 148563741.83299482,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '244.9300000000000000',
      high: '251.9300000000000000',
      low: '236.0000000000000000',
      close: '237.2300000000000000',
      volume: '633748.8966200000000000',
      quoteVolume: 154037325.61130953,
      btcVolume: 17062.989655657668,
      usdVolume: 154037325.61130953,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '237.2300000000000000',
      high: '237.2300000000000000',
      low: '195.5000000000000000',
      close: '199.4300000000000000',
      volume: '1278973.5374100000000000',
      quoteVolume: 277970187.86825454,
      btcVolume: 32927.24759153153,
      usdVolume: 277970187.86825454,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '199.4300000000000000',
      high: '208.6200000000000000',
      low: '190.0000000000000000',
      close: '202.8100000000000000',
      volume: '1661331.8355300000000000',
      quoteVolume: 332570408.53516084,
      btcVolume: 42278.225193090606,
      usdVolume: 332570408.53516084,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '202.7900000000000000',
      high: '206.2000000000000000',
      low: '195.5400000000000000',
      close: '200.7000000000000000',
      volume: '1020260.1070000000000000',
      quoteVolume: 205536190.19393793,
      btcVolume: 25870.47287880342,
      usdVolume: 205536190.19393793,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '200.7400000000000000',
      high: '203.1800000000000000',
      low: '181.7300000000000000',
      close: '194.6100000000000000',
      volume: '1079824.9016700000000000',
      quoteVolume: 208232375.1861964,
      btcVolume: 26676.44911775151,
      usdVolume: 208232375.1861964,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '194.6100000000000000',
      high: '195.5500000000000000',
      low: '101.2000000000000000',
      close: '107.8200000000000000',
      volume: '3487974.4724600000000000',
      quoteVolume: 502735483.19587886,
      btcVolume: 79582.69602720514,
      usdVolume: 502735483.19587886,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '107.6700000000000000',
      high: '139.6800000000000000',
      low: '86.0000000000000000',
      close: '134.0600000000000000',
      volume: '4352096.1180700000000000',
      quoteVolume: 524165020.32676804,
      btcVolume: 102228.2183345659,
      usdVolume: 524165020.32676804,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '134.0600000000000000',
      high: '134.6800000000000000',
      low: '120.0000000000000000',
      close: '122.5400000000000000',
      volume: '1237626.0682200000000000',
      quoteVolume: 159186518.38922155,
      btcVolume: 29588.5952380688,
      usdVolume: 159186518.38922155,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '122.5400000000000000',
      high: '133.5000000000000000',
      low: '120.1600000000000000',
      close: '123.7800000000000000',
      volume: '1312950.9013700000000000',
      quoteVolume: 163436235.26908636,
      btcVolume: 30443.399792973287,
      usdVolume: 163436235.26908636,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '123.8200000000000000',
      high: '124.3300000000000000',
      low: '101.1000000000000000',
      close: '111.0200000000000000',
      volume: '2442064.1444800000000000',
      quoteVolume: 270353891.80499524,
      btcVolume: 55152.042843550655,
      usdVolume: 270353891.80499524,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '111.0200000000000000',
      high: '120.8000000000000000',
      low: '109.3800000000000000',
      close: '115.6700000000000000',
      volume: '1295108.4848000000000000',
      quoteVolume: 150800664.07195225,
      btcVolume: 28587.190421365343,
      usdVolume: 150800664.07195225,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '115.6700000000000000',
      high: '118.5000000000000000',
      low: '109.8600000000000000',
      close: '118.1100000000000000',
      volume: '1201850.3127500000000000',
      quoteVolume: 137550912.08551753,
      btcVolume: 26224.964726877704,
      usdVolume: 137550912.08551753,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '118.1100000000000000',
      high: '143.1300000000000000',
      low: '115.4600000000000000',
      close: '136.3000000000000000',
      volume: '1801071.1880800000000000',
      quoteVolume: 231626928.1512321,
      btcVolume: 39606.47361637073,
      usdVolume: 231626928.1512321,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '136.3000000000000000',
      high: '152.5500000000000000',
      low: '116.7400000000000000',
      close: '133.4800000000000000',
      volume: '2504057.6379900000000000',
      quoteVolume: 345856006.74780256,
      btcVolume: 54225.66525238545,
      usdVolume: 345856006.74780256,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '133.4900000000000000',
      high: '137.4900000000000000',
      low: '125.6900000000000000',
      close: '132.6600000000000000',
      volume: '1231842.1112100000000000',
      quoteVolume: 162468451.77231285,
      btcVolume: 26347.20683720244,
      usdVolume: 162468451.77231285,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '132.6300000000000000',
      high: '137.1300000000000000',
      low: '121.1000000000000000',
      close: '122.3200000000000000',
      volume: '1055699.8653200000000000',
      quoteVolume: 135927941.07952216,
      btcVolume: 22332.10691388855,
      usdVolume: 135927941.07952216,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '122.3100000000000000',
      high: '137.5000000000000000',
      low: '119.5000000000000000',
      close: '135.9200000000000000',
      volume: '1436796.1244900000000000',
      quoteVolume: 184851609.12426114,
      btcVolume: 30054.31381726867,
      usdVolume: 184851609.12426114,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '135.8900000000000000',
      high: '143.3600000000000000',
      low: '132.2000000000000000',
      close: '138.4200000000000000',
      volume: '1244377.1633400000000000',
      quoteVolume: 171156363.69229996,
      btcVolume: 25793.645485705532,
      usdVolume: 171156363.69229996,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '138.4200000000000000',
      high: '142.3300000000000000',
      low: '132.5400000000000000',
      close: '136.1000000000000000',
      volume: '1034248.7436900000000000',
      quoteVolume: 141795323.0268692,
      btcVolume: 21290.647624377056,
      usdVolume: 141795323.0268692,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '136.1100000000000000',
      high: '139.7400000000000000',
      low: '133.2600000000000000',
      close: '138.4000000000000000',
      volume: '750975.5504600000000000',
      quoteVolume: 101907167.70116763,
      btcVolume: 15297.871335099853,
      usdVolume: 101907167.70116763,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '138.4000000000000000',
      high: '141.2700000000000000',
      low: '129.6800000000000000',
      close: '131.3300000000000000',
      volume: '706948.5455900000000000',
      quoteVolume: 96581886.87894869,
      btcVolume: 14496.8359010212,
      usdVolume: 96581886.87894869,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '131.3300000000000000',
      high: '132.9700000000000000',
      low: '124.8800000000000000',
      close: '130.9800000000000000',
      volume: '896032.1059400000000000',
      quoteVolume: 115166651.97007346,
      btcVolume: 18545.39550175307,
      usdVolume: 115166651.97007346,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '130.9400000000000000',
      high: '131.8400000000000000',
      low: '123.8100000000000000',
      close: '124.5000000000000000',
      volume: '543997.0000000000000000',
      quoteVolume: 69434202.20547193,
      btcVolume: 11449.693329595028,
      usdVolume: 69434202.20547193,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '124.4700000000000000',
      high: '135.4200000000000000',
      low: '124.0600000000000000',
      close: '132.0600000000000000',
      volume: '760403.4785400000000000',
      quoteVolume: 99677889.71604659,
      btcVolume: 15885.98378555245,
      usdVolume: 99677889.71604659,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '132.0900000000000000',
      high: '135.0000000000000000',
      low: '130.3800000000000000',
      close: '132.7200000000000000',
      volume: '547529.2537800000000000',
      quoteVolume: 72599600.32278414,
      btcVolume: 11270.645004277229,
      usdVolume: 72599600.32278414,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '132.7200000000000000',
      high: '136.9900000000000000',
      low: '128.6200000000000000',
      close: '135.6900000000000000',
      volume: '680643.4807700000000000',
      quoteVolume: 90027178.09661904,
      btcVolume: 14172.192723006221,
      usdVolume: 90027178.09661904,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '135.6500000000000000',
      high: '149.7600000000000000',
      low: '135.0100000000000000',
      close: '141.2800000000000000',
      volume: '1138977.6611400000000000',
      quoteVolume: 159989506.60494053,
      btcVolume: 23529.02931639409,
      usdVolume: 159989506.60494053,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '141.2700000000000000',
      high: '146.7200000000000000',
      low: '137.2600000000000000',
      close: '141.3100000000000000',
      volume: '797546.1144600000000000',
      quoteVolume: 113731706.71500106,
      btcVolume: 16666.063640760167,
      usdVolume: 113731706.71500106,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '141.3000000000000000',
      high: '146.2800000000000000',
      low: '138.8800000000000000',
      close: '144.1000000000000000',
      volume: '573508.1408600000000000',
      quoteVolume: 81640622.82843257,
      btcVolume: 12045.428630419647,
      usdVolume: 81640622.82843257,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '144.1000000000000000',
      high: '145.5800000000000000',
      low: '140.6800000000000000',
      close: '142.6800000000000000',
      volume: '414987.4524600000000000',
      quoteVolume: 59383946.127442546,
      btcVolume: 8751.783787499042,
      usdVolume: 59383946.127442546,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '142.6900000000000000',
      high: '172.0000000000000000',
      low: '142.6000000000000000',
      close: '171.3200000000000000',
      volume: '1636338.7311000000000000',
      quoteVolume: 257097597.7838751,
      btcVolume: 36146.37040615942,
      usdVolume: 257097597.7838751,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '171.3200000000000000',
      high: '176.2200000000000000',
      low: '162.2200000000000000',
      close: '164.6300000000000000',
      volume: '1717496.7435600000000000',
      quoteVolume: 292630399.0504515,
      btcVolume: 40022.062364601465,
      usdVolume: 292630399.0504515,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '164.6200000000000000',
      high: '174.4800000000000000',
      low: '163.5900000000000000',
      close: '173.1100000000000000',
      volume: '1023575.9207600000000000',
      quoteVolume: 173916484.78615347,
      btcVolume: 23819.17844363877,
      usdVolume: 173916484.78615347,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '173.1500000000000000',
      high: '173.3800000000000000',
      low: '165.1900000000000000',
      close: '169.5200000000000000',
      volume: '719837.2442300000000000',
      quoteVolume: 122531131.95595776,
      btcVolume: 16818.265725807498,
      usdVolume: 122531131.95595776,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '169.5600000000000000',
      high: '170.1000000000000000',
      low: '152.1100000000000000',
      close: '157.8100000000000000',
      volume: '1314841.3620000000000000',
      quoteVolume: 209535613.32368094,
      btcVolume: 30105.99545258993,
      usdVolume: 209535613.32368094,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '157.8100000000000000',
      high: '161.3700000000000000',
      low: '153.9100000000000000',
      close: '158.3300000000000000',
      volume: '621599.6128900000000000',
      quoteVolume: 98275533.06263469,
      btcVolume: 14322.52534224828,
      usdVolume: 98275533.06263469,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '158.3300000000000000',
      high: '164.7800000000000000',
      low: '155.0000000000000000',
      close: '158.5900000000000000',
      volume: '809097.1317400000000000',
      quoteVolume: 129919434.5212399,
      btcVolume: 18622.065967622548,
      usdVolume: 129919434.5212399,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '158.5600000000000000',
      high: '158.9700000000000000',
      low: '150.0000000000000000',
      close: '156.2900000000000000',
      volume: '1042091.0362200000000000',
      quoteVolume: 160423270.30371547,
      btcVolume: 23810.704478929125,
      usdVolume: 160423270.30371547,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '156.2800000000000000',
      high: '161.8500000000000000',
      low: '155.2300000000000000',
      close: '158.4500000000000000',
      volume: '698030.6508900000000000',
      quoteVolume: 110710086.10400799,
      btcVolume: 16092.175363956325,
      usdVolume: 110710086.10400799,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '158.4600000000000000',
      high: '161.2900000000000000',
      low: '152.0000000000000000',
      close: '152.7300000000000000',
      volume: '729119.6650500000000000',
      quoteVolume: 114640840.11551383,
      btcVolume: 16907.325021412682,
      usdVolume: 114640840.11551383,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '152.7400000000000000',
      high: '174.7900000000000000',
      low: '148.3300000000000000',
      close: '172.2900000000000000',
      volume: '1647207.5999100000000000',
      quoteVolume: 273639160.06938905,
      btcVolume: 39469.80302466689,
      usdVolume: 273639160.06938905,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '172.3100000000000000',
      high: '174.9600000000000000',
      low: '168.3100000000000000',
      close: '170.6900000000000000',
      volume: '723537.7559100000000000',
      quoteVolume: 123553456.31967713,
      btcVolume: 17477.09337208831,
      usdVolume: 123553456.31967713,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '170.6100000000000000',
      high: '189.5400000000000000',
      low: '170.4800000000000000',
      close: '187.4000000000000000',
      volume: '1127296.3697900000000000',
      quoteVolume: 203132211.95640972,
      btcVolume: 28322.990109666654,
      usdVolume: 203132211.95640972,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '187.4000000000000000',
      high: '188.3500000000000000',
      low: '175.7500000000000000',
      close: '180.0300000000000000',
      volume: '995759.5775000000000000',
      quoteVolume: 181415813.50269327,
      btcVolume: 25353.375195221084,
      usdVolume: 181415813.50269327,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '180.0200000000000000',
      high: '186.4600000000000000',
      low: '166.7000000000000000',
      close: '170.2000000000000000',
      volume: '1547821.5273700000000000',
      quoteVolume: 273854539.8037984,
      btcVolume: 39135.19214462897,
      usdVolume: 273854539.8037984,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '170.2100000000000000',
      high: '174.7000000000000000',
      low: '168.0000000000000000',
      close: '170.7400000000000000',
      volume: '906251.0337800000000000',
      quoteVolume: 155269608.04007408,
      btcVolume: 22667.419297755732,
      usdVolume: 155269608.04007408,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '170.7300000000000000',
      high: '184.0000000000000000',
      low: '169.7800000000000000',
      close: '182.7000000000000000',
      volume: '988227.0746000000000000',
      quoteVolume: 175998292.09734583,
      btcVolume: 25096.17505566981,
      usdVolume: 175998292.09734583,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '182.7000000000000000',
      high: '193.6300000000000000',
      low: '178.1600000000000000',
      close: '185.5100000000000000',
      volume: '1457546.9509400000000000',
      quoteVolume: 270803059.6644047,
      btcVolume: 36995.15878859557,
      usdVolume: 270803059.6644047,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '185.5700000000000000',
      high: '189.8000000000000000',
      low: '184.7200000000000000',
      close: '187.5900000000000000',
      volume: '780904.2125700000000000',
      quoteVolume: 146419748.08164915,
      btcVolume: 19487.960270951102,
      usdVolume: 146419748.08164915,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '187.6000000000000000',
      high: '198.3600000000000000',
      low: '185.7600000000000000',
      close: '194.2100000000000000',
      volume: '866478.0492800000000000',
      quoteVolume: 167703202.42505187,
      btcVolume: 22213.786528375527,
      usdVolume: 167703202.42505187,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '194.2100000000000000',
      high: '200.0000000000000000',
      low: '192.3000000000000000',
      close: '197.3800000000000000',
      volume: '758525.8153300000000000',
      quoteVolume: 148433709.777186,
      btcVolume: 19517.662353427906,
      usdVolume: 148433709.777186,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '197.3900000000000000',
      high: '199.0500000000000000',
      low: '189.2900000000000000',
      close: '196.3400000000000000',
      volume: '896627.3727100000000000',
      quoteVolume: 174775637.92727718,
      btcVolume: 22703.242326359323,
      usdVolume: 174775637.92727718,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '196.3300000000000000',
      high: '198.5000000000000000',
      low: '192.3600000000000000',
      close: '196.2400000000000000',
      volume: '636829.8939700000000000',
      quoteVolume: 124476041.88938437,
      btcVolume: 16122.852361506426,
      usdVolume: 124476041.88938437,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '196.2400000000000000',
      high: '218.7700000000000000',
      low: '196.2200000000000000',
      close: '215.3600000000000000',
      volume: '1620422.7243100000000000',
      quoteVolume: 338324593.63727975,
      btcVolume: 40923.435434255334,
      usdVolume: 338324593.63727975,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '215.4000000000000000',
      high: '227.3000000000000000',
      low: '202.0000000000000000',
      close: '206.0800000000000000',
      volume: '1749436.3115300000000000',
      quoteVolume: 374836060.0037704,
      btcVolume: 42031.33369272022,
      usdVolume: 374836060.0037704,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '206.0700000000000000',
      high: '217.1400000000000000',
      low: '205.9100000000000000',
      close: '212.0200000000000000',
      volume: '794913.9582800000000000',
      quoteVolume: 168339123.3579177,
      btcVolume: 19142.43801029207,
      usdVolume: 168339123.3579177,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '212.0100000000000000',
      high: '215.3700000000000000',
      low: '210.3600000000000000',
      close: '213.9000000000000000',
      volume: '443494.6724800000000000',
      quoteVolume: 94389501.51781647,
      btcVolume: 10636.541506688323,
      usdVolume: 94389501.51781647,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '213.8900000000000000',
      high: '219.3000000000000000',
      low: '203.9200000000000000',
      close: '209.8600000000000000',
      volume: '748383.9131100000000000',
      quoteVolume: 158874887.0783394,
      btcVolume: 17742.09175499337,
      usdVolume: 158874887.0783394,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '209.8600000000000000',
      high: '210.6500000000000000',
      low: '194.8800000000000000',
      close: '206.4800000000000000',
      volume: '1114249.3265800000000000',
      quoteVolume: 225451167.65536752,
      btcVolume: 25813.68579734503,
      usdVolume: 225451167.65536752,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '206.4700000000000000',
      high: '211.6800000000000000',
      low: '201.0000000000000000',
      close: '205.2900000000000000',
      volume: '640644.6626800000000000',
      quoteVolume: 131906204.90781972,
      btcVolume: 14781.073403266748,
      usdVolume: 131906204.90781972,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '205.3000000000000000',
      high: '210.7700000000000000',
      low: '197.7100000000000000',
      close: '198.9700000000000000',
      volume: '862700.6784900000000000',
      quoteVolume: 178157452.60084617,
      btcVolume: 19446.63985121468,
      usdVolume: 178157452.60084617,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '198.9200000000000000',
      high: '215.1000000000000000',
      low: '196.4300000000000000',
      close: '212.0000000000000000',
      volume: '1305778.7376400000000000',
      quoteVolume: 271197927.6650283,
      btcVolume: 28416.436328368578,
      usdVolume: 271197927.6650283,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '212.0100000000000000',
      high: '216.6100000000000000',
      low: '206.9000000000000000',
      close: '211.3100000000000000',
      volume: '917908.1944000000000000',
      quoteVolume: 194579529.42776698,
      btcVolume: 19629.460850922827,
      usdVolume: 194579529.42776698,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '211.3200000000000000',
      high: '214.6000000000000000',
      low: '208.4000000000000000',
      close: '210.0700000000000000',
      volume: '587871.9807300000000000',
      quoteVolume: 124626471.42703621,
      btcVolume: 12822.779287709614,
      usdVolume: 124626471.42703621,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '210.0900000000000000',
      high: '210.1800000000000000',
      low: '179.5900000000000000',
      close: '187.5400000000000000',
      volume: '1473226.0547500000000000',
      quoteVolume: 278394290.7597449,
      btcVolume: 32142.471378080594,
      usdVolume: 278394290.7597449,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '187.6200000000000000',
      high: '193.5000000000000000',
      low: '176.0000000000000000',
      close: '185.7300000000000000',
      volume: '1093299.8192700000000000',
      quoteVolume: 203391530.206183,
      btcVolume: 23422.170849671944,
      usdVolume: 203391530.206183,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '185.8000000000000000',
      high: '192.2400000000000000',
      low: '185.4800000000000000',
      close: '189.7600000000000000',
      volume: '669755.7956700000000000',
      quoteVolume: 126810812.16817188,
      btcVolume: 14463.158714611134,
      usdVolume: 126810812.16817188,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '189.7700000000000000',
      high: '201.0000000000000000',
      low: '188.3500000000000000',
      close: '199.5800000000000000',
      volume: '805809.8298700000000000',
      quoteVolume: 156573908.52186683,
      btcVolume: 17267.02579964432,
      usdVolume: 156573908.52186683,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '199.6100000000000000',
      high: '206.0200000000000000',
      low: '195.6700000000000000',
      close: '203.2900000000000000',
      volume: '1114700.2493700000000000',
      quoteVolume: 224233786.12671238,
      btcVolume: 23321.873078992805,
      usdVolume: 224233786.12671238,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '203.2900000000000000',
      high: '204.0800000000000000',
      low: '191.6600000000000000',
      close: '194.8600000000000000',
      volume: '904749.6357000000000000',
      quoteVolume: 179634085.6227652,
      btcVolume: 18891.585288550705,
      usdVolume: 179634085.6227652,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '194.8700000000000000',
      high: '203.5000000000000000',
      low: '193.2100000000000000',
      close: '200.4500000000000000',
      volume: '669900.0375400000000000',
      quoteVolume: 134060788.49418302,
      btcVolume: 14238.268494091166,
      usdVolume: 134060788.49418302,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '200.4400000000000000',
      high: '209.8500000000000000',
      low: '199.6800000000000000',
      close: '207.0000000000000000',
      volume: '708656.1209600000000000',
      quoteVolume: 145330053.95795897,
      btcVolume: 15038.595482450506,
      usdVolume: 145330053.95795897,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '207.0200000000000000',
      high: '217.0700000000000000',
      low: '207.0000000000000000',
      close: '214.9100000000000000',
      volume: '1064118.4969700000000000',
      quoteVolume: 226784577.1006466,
      btcVolume: 23254.32875270356,
      usdVolume: 226784577.1006466,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '214.9200000000000000',
      high: '215.9500000000000000',
      low: '209.4500000000000000',
      close: '214.5800000000000000',
      volume: '643702.9884900000000000',
      quoteVolume: 136846948.55680534,
      btcVolume: 14141.16094939455,
      usdVolume: 136846948.55680534,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '214.6000000000000000',
      high: '215.7500000000000000',
      low: '204.2700000000000000',
      close: '209.9800000000000000',
      volume: '679532.6103700000000000',
      quoteVolume: 143704806.17524174,
      btcVolume: 14897.556828334138,
      usdVolume: 143704806.17524174,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '209.9900000000000000',
      high: '211.6400000000000000',
      low: '191.7100000000000000',
      close: '198.7400000000000000',
      volume: '1003923.4342000000000000',
      quoteVolume: 203445710.96284306,
      btcVolume: 22082.32268408724,
      usdVolume: 203445710.96284306,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '198.7200000000000000',
      high: '209.1900000000000000',
      low: '196.2400000000000000',
      close: '207.3600000000000000',
      volume: '787672.3608100000000000',
      quoteVolume: 159972608.5341703,
      btcVolume: 17518.762923166818,
      usdVolume: 159972608.5341703,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '207.3600000000000000',
      high: '211.2000000000000000',
      low: '204.5500000000000000',
      close: '206.6000000000000000',
      volume: '478543.5112000000000000',
      quoteVolume: 99457681.15441823,
      btcVolume: 10806.153666981252,
      usdVolume: 99457681.15441823,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '206.6000000000000000',
      high: '210.5500000000000000',
      low: '199.5000000000000000',
      close: '199.9000000000000000',
      volume: '673364.5911000000000000',
      quoteVolume: 138882881.96251076,
      btcVolume: 15299.402726933584,
      usdVolume: 138882881.96251076,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '199.9000000000000000',
      high: '205.6700000000000000',
      low: '198.0100000000000000',
      close: '204.1500000000000000',
      volume: '469931.6249200000000000',
      quoteVolume: 95411610.79763587,
      btcVolume: 10833.988909859245,
      usdVolume: 95411610.79763587,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '204.2200000000000000',
      high: '205.0000000000000000',
      low: '196.9000000000000000',
      close: '200.8900000000000000',
      volume: '477176.7353000000000000',
      quoteVolume: 96116808.85809049,
      btcVolume: 10865.809286287838,
      usdVolume: 96116808.85809049,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '200.9200000000000000',
      high: '208.4400000000000000',
      low: '200.7600000000000000',
      close: '208.2900000000000000',
      volume: '494816.7272700000000000',
      quoteVolume: 101445351.02566375,
      btcVolume: 11223.077448084685,
      usdVolume: 101445351.02566375,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '208.2900000000000000',
      high: '220.5000000000000000',
      low: '204.6700000000000000',
      close: '220.2100000000000000',
      volume: '643945.9776900000000000',
      quoteVolume: 136361627.34641564,
      btcVolume: 14529.09885208273,
      usdVolume: 136361627.34641564,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '220.2400000000000000',
      high: '224.7900000000000000',
      low: '217.5700000000000000',
      close: '220.6700000000000000',
      volume: '602955.9267100000000000',
      quoteVolume: 133197594.260007,
      btcVolume: 14054.00894376058,
      usdVolume: 133197594.260007,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '220.6300000000000000',
      high: '247.0000000000000000',
      low: '218.8000000000000000',
      close: '243.6600000000000000',
      volume: '1019550.7514600000000000',
      quoteVolume: 238678839.809296,
      btcVolume: 25044.601085406517,
      usdVolume: 238678839.809296,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '243.7100000000000000',
      high: '245.3900000000000000',
      low: '229.5800000000000000',
      close: '231.5700000000000000',
      volume: '821608.6888400000000000',
      quoteVolume: 194867426.2941861,
      btcVolume: 20410.587835015256,
      usdVolume: 194867426.2941861,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '231.5600000000000000',
      high: '250.6800000000000000',
      low: '230.6600000000000000',
      close: '248.2300000000000000',
      volume: '823816.6993600000000000',
      quoteVolume: 198791911.84704918,
      btcVolume: 20496.02670912183,
      usdVolume: 198791911.84704918,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '248.2300000000000000',
      high: '253.8000000000000000',
      low: '225.0000000000000000',
      close: '237.7100000000000000',
      volume: '955520.9186300000000000',
      quoteVolume: 230680327.30929545,
      btcVolume: 23537.399570541293,
      usdVolume: 230680327.30929545,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '237.7100000000000000',
      high: '245.0000000000000000',
      low: '232.7100000000000000',
      close: '244.5200000000000000',
      volume: '502928.1337100000000000',
      quoteVolume: 120179301.17898554,
      btcVolume: 12578.74569388963,
      usdVolume: 120179301.17898554,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '244.5400000000000000',
      high: '246.6000000000000000',
      low: '236.0000000000000000',
      close: '243.2100000000000000',
      volume: '669369.3392000000000000',
      quoteVolume: 162460397.62767565,
      btcVolume: 16776.170878664434,
      usdVolume: 162460397.62767565,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '243.2200000000000000',
      high: '247.8700000000000000',
      low: '239.1400000000000000',
      close: '240.0300000000000000',
      volume: '502468.8571600000000000',
      quoteVolume: 122065135.39008076,
      btcVolume: 12523.278719135755,
      usdVolume: 122065135.39008076,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '240.0400000000000000',
      high: '244.7000000000000000',
      low: '238.1600000000000000',
      close: '241.9400000000000000',
      volume: '366776.8953700000000000',
      quoteVolume: 88523230.03653955,
      btcVolume: 9183.854988193434,
      usdVolume: 88523230.03653955,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '241.9400000000000000',
      high: '245.3700000000000000',
      low: '234.6000000000000000',
      close: '244.5600000000000000',
      volume: '571102.5551500000000000',
      quoteVolume: 137165732.64761737,
      btcVolume: 14264.71365491363,
      usdVolume: 137165732.64761737,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '244.5700000000000000',
      high: '247.7000000000000000',
      low: '240.5900000000000000',
      close: '246.4000000000000000',
      volume: '383116.5471900000000000',
      quoteVolume: 93355224.96830915,
      btcVolume: 9598.855468043803,
      usdVolume: 93355224.96830915,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '246.3700000000000000',
      high: '249.8200000000000000',
      low: '238.0000000000000000',
      close: '243.8000000000000000',
      volume: '419967.8054400000000000',
      quoteVolume: 102287184.99320976,
      btcVolume: 10528.431478674393,
      usdVolume: 102287184.99320976,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '243.7900000000000000',
      high: '250.2800000000000000',
      low: '242.0000000000000000',
      close: '247.7800000000000000',
      volume: '431285.6171500000000000',
      quoteVolume: 105797248.24742587,
      btcVolume: 10771.848889298295,
      usdVolume: 105797248.24742587,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '247.7800000000000000',
      high: '250.0900000000000000',
      low: '226.2000000000000000',
      close: '230.5100000000000000',
      volume: '815431.7796500000000000',
      quoteVolume: 194825983.3024181,
      btcVolume: 20371.297467425575,
      usdVolume: 194825983.3024181,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '230.4600000000000000',
      high: '239.3800000000000000',
      low: '228.1900000000000000',
      close: '237.5500000000000000',
      volume: '444488.9407200000000000',
      quoteVolume: 104599402.02950011,
      btcVolume: 11109.617756002966,
      usdVolume: 104599402.02950011,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '237.5500000000000000',
      high: '238.7300000000000000',
      low: '234.6600000000000000',
      close: '238.1100000000000000',
      volume: '253797.7809000000000000',
      quoteVolume: 60087772.65246506,
      btcVolume: 6372.817903545626,
      usdVolume: 60087772.65246506,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '238.1300000000000000',
      high: '238.5900000000000000',
      low: '229.8300000000000000',
      close: '231.7700000000000000',
      volume: '304943.2118800000000000',
      quoteVolume: 71575514.08027163,
      btcVolume: 7622.81941591543,
      usdVolume: 71575514.08027163,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '231.7400000000000000',
      high: '233.5500000000000000',
      low: '218.2700000000000000',
      close: '230.8600000000000000',
      volume: '875026.9205700000000000',
      quoteVolume: 197907504.77877796,
      btcVolume: 21476.01503478616,
      usdVolume: 197907504.77877796,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '230.8600000000000000',
      high: '236.0000000000000000',
      low: '228.7000000000000000',
      close: '235.3000000000000000',
      volume: '499194.6675800000000000',
      quoteVolume: 116456364.2312674,
      btcVolume: 12259.734211678307,
      usdVolume: 116456364.2312674,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '235.3100000000000000',
      high: '237.3000000000000000',
      low: '227.6500000000000000',
      close: '233.8200000000000000',
      volume: '478187.2917000000000000',
      quoteVolume: 111415059.05626224,
      btcVolume: 11798.305623152255,
      usdVolume: 111415059.05626224,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '233.8200000000000000',
      high: '234.6500000000000000',
      low: '227.9300000000000000',
      close: '231.2700000000000000',
      volume: '334347.7991700000000000',
      quoteVolume: 77542070.33327071,
      btcVolume: 8244.094026212786,
      usdVolume: 77542070.33327071,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '231.2600000000000000',
      high: '231.9500000000000000',
      low: '226.7400000000000000',
      close: '229.0000000000000000',
      volume: '397284.5980800000000000',
      quoteVolume: 91117706.15513879,
      btcVolume: 9760.152539929302,
      usdVolume: 91117706.15513879,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '229.0000000000000000',
      high: '230.8500000000000000',
      low: '225.6200000000000000',
      close: '228.8900000000000000',
      volume: '303704.9882600000000000',
      quoteVolume: 69343699.90954724,
      btcVolume: 7455.57113620382,
      usdVolume: 69343699.90954724,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '228.8800000000000000',
      high: '231.4900000000000000',
      low: '227.1100000000000000',
      close: '228.0400000000000000',
      volume: '256305.2373400000000000',
      quoteVolume: 58868166.506103784,
      btcVolume: 6293.000295933788,
      usdVolume: 58868166.506103784,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '228.0300000000000000',
      high: '246.8900000000000000',
      low: '227.5100000000000000',
      close: '243.1300000000000000',
      volume: '748759.3895600000000000',
      quoteVolume: 178643659.67329305,
      btcVolume: 18825.751197784553,
      usdVolume: 178643659.67329305,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '243.1100000000000000',
      high: '244.8700000000000000',
      low: '240.7500000000000000',
      close: '243.1400000000000000',
      volume: '442226.8735200000000000',
      quoteVolume: 107440173.5377359,
      btcVolume: 11144.411233446323,
      usdVolume: 107440173.5377359,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '243.1500000000000000',
      high: '249.2400000000000000',
      low: '231.0700000000000000',
      close: '234.5800000000000000',
      volume: '921634.0534000000000000',
      quoteVolume: 220763040.43374506,
      btcVolume: 23383.216137878488,
      usdVolume: 220763040.43374506,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '234.5700000000000000',
      high: '235.3900000000000000',
      low: '227.5600000000000000',
      close: '232.5000000000000000',
      volume: '613748.5934800000000000',
      quoteVolume: 142697463.3801034,
      btcVolume: 15489.816263370116,
      usdVolume: 142697463.3801034,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '232.4900000000000000',
      high: '233.6100000000000000',
      low: '227.7500000000000000',
      close: '229.6400000000000000',
      volume: '628340.0700700000000000',
      quoteVolume: 144805505.1915821,
      btcVolume: 15783.082372432726,
      usdVolume: 144805505.1915821,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '229.6600000000000000',
      high: '231.4200000000000000',
      low: '216.0000000000000000',
      close: '220.9400000000000000',
      volume: '645950.6143800000000000',
      quoteVolume: 145264632.26381838,
      btcVolume: 16009.140095513289,
      usdVolume: 145264632.26381838,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '220.9400000000000000',
      high: '227.9600000000000000',
      low: '218.3200000000000000',
      close: '224.9300000000000000',
      volume: '444766.5688100000000000',
      quoteVolume: 99423540.13931867,
      btcVolume: 10949.038056790398,
      usdVolume: 99423540.13931867,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '224.8900000000000000',
      high: '229.9600000000000000',
      low: '221.2600000000000000',
      close: '227.9300000000000000',
      volume: '597915.5224200000000000',
      quoteVolume: 134510531.19766667,
      btcVolume: 14743.622729629511,
      usdVolume: 134510531.19766667,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '227.9800000000000000',
      high: '228.7000000000000000',
      low: '222.9100000000000000',
      close: '225.6000000000000000',
      volume: '415036.0297700000000000',
      quoteVolume: 93802207.27357335,
      btcVolume: 10255.185172332418,
      usdVolume: 93802207.27357335,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '225.5900000000000000',
      high: '232.7100000000000000',
      low: '224.1600000000000000',
      close: '230.9900000000000000',
      volume: '515968.3174700000000000',
      quoteVolume: 118009144.95166142,
      btcVolume: 12823.681622799983,
      usdVolume: 118009144.95166142,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '230.9900000000000000',
      high: '232.0100000000000000',
      low: '222.8700000000000000',
      close: '226.4600000000000000',
      volume: '592485.9111500000000000',
      quoteVolume: 134976975.12319767,
      btcVolume: 14752.25945808237,
      usdVolume: 134976975.12319767,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '226.4600000000000000',
      high: '228.1300000000000000',
      low: '223.9700000000000000',
      close: '224.8700000000000000',
      volume: '312108.6060700000000000',
      quoteVolume: 70729994.77950838,
      btcVolume: 7780.293748705394,
      usdVolume: 70729994.77950838,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '224.8600000000000000',
      high: '230.7100000000000000',
      low: '224.4300000000000000',
      close: '229.1700000000000000',
      volume: '298119.6003300000000000',
      quoteVolume: 67726803.92678858,
      btcVolume: 7439.9741424165295,
      usdVolume: 67726803.92678858,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '229.2400000000000000',
      high: '229.8500000000000000',
      low: '223.0500000000000000',
      close: '227.5600000000000000',
      volume: '341994.9329000000000000',
      quoteVolume: 77425409.00913155,
      btcVolume: 8548.264489930476,
      usdVolume: 77425409.00913155,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '227.5400000000000000',
      high: '242.0000000000000000',
      low: '226.9300000000000000',
      close: '241.6000000000000000',
      volume: '864817.5070000000000000',
      quoteVolume: 203334727.850139,
      btcVolume: 22028.523967262292,
      usdVolume: 203334727.850139,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '241.6000000000000000',
      high: '243.7700000000000000',
      low: '234.4000000000000000',
      close: '239.3900000000000000',
      volume: '600378.4220900000000000',
      quoteVolume: 143111734.11362022,
      btcVolume: 15434.282792689764,
      usdVolume: 143111734.11362022,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '239.3900000000000000',
      high: '248.8800000000000000',
      low: '237.7800000000000000',
      close: '246.9500000000000000',
      volume: '942434.0782300000000000',
      quoteVolume: 229569778.54287842,
      btcVolume: 24561.674657966098,
      usdVolume: 229569778.54287842,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '246.9400000000000000',
      high: '247.9000000000000000',
      low: '237.8800000000000000',
      close: '241.9800000000000000',
      volume: '647641.5829100000000000',
      quoteVolume: 157541370.0048171,
      btcVolume: 16903.849735123644,
      usdVolume: 157541370.0048171,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '241.9700000000000000',
      high: '242.1600000000000000',
      low: '235.7200000000000000',
      close: '241.2600000000000000',
      volume: '455025.1238500000000000',
      quoteVolume: 108806462.4650961,
      btcVolume: 11835.149454014792,
      usdVolume: 108806462.4650961,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '241.2700000000000000',
      high: '241.6700000000000000',
      low: '237.5400000000000000',
      close: '239.1900000000000000',
      volume: '290546.6408300000000000',
      quoteVolume: 69603089.08903724,
      btcVolume: 7535.268541585202,
      usdVolume: 69603089.08903724,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '239.1900000000000000',
      high: '244.0000000000000000',
      low: '236.5200000000000000',
      close: '242.7900000000000000',
      volume: '469315.1172000000000000',
      quoteVolume: 112487552.43401246,
      btcVolume: 12159.372376119501,
      usdVolume: 112487552.43401246,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '242.7600000000000000',
      high: '245.3300000000000000',
      low: '237.3300000000000000',
      close: '239.5000000000000000',
      volume: '604093.0424500000000000',
      quoteVolume: 146193291.91067657,
      btcVolume: 15758.109658099816,
      usdVolume: 146193291.91067657,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '239.5000000000000000',
      high: '242.0900000000000000',
      low: '237.0000000000000000',
      close: '240.4400000000000000',
      volume: '426320.8918800000000000',
      quoteVolume: 102105027.13192993,
      btcVolume: 11077.23641948493,
      usdVolume: 102105027.13192993,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '240.4500000000000000',
      high: '241.4500000000000000',
      low: '236.8300000000000000',
      close: '238.6100000000000000',
      volume: '368350.6651800000000000',
      quoteVolume: 88059682.48343667,
      btcVolume: 9547.404137094758,
      usdVolume: 88059682.48343667,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '238.5900000000000000',
      high: '239.3900000000000000',
      low: '230.0200000000000000',
      close: '233.5700000000000000',
      volume: '587161.7389400000000000',
      quoteVolume: 137208323.20157194,
      btcVolume: 15019.41735536055,
      usdVolume: 137208323.20157194,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '233.5800000000000000',
      high: '234.8400000000000000',
      low: '231.6500000000000000',
      close: '232.6700000000000000',
      volume: '325579.4645700000000000',
      quoteVolume: 75926217.2310008,
      btcVolume: 8310.273922367218,
      usdVolume: 75926217.2310008,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '232.6600000000000000',
      high: '236.8000000000000000',
      low: '232.3200000000000000',
      close: '235.6700000000000000',
      volume: '341356.6182000000000000',
      quoteVolume: 80046747.8322333,
      btcVolume: 8738.120886553355,
      usdVolume: 80046747.8322333,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '235.6700000000000000',
      high: '239.9200000000000000',
      low: '233.2800000000000000',
      close: '239.1100000000000000',
      volume: '265225.5624400000000000',
      quoteVolume: 62547101.402666144,
      btcVolume: 6826.036997576792,
      usdVolume: 62547101.402666144,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '239.1200000000000000',
      high: '239.7200000000000000',
      low: '234.0500000000000000',
      close: '236.0100000000000000',
      volume: '396657.9193800000000000',
      quoteVolume: 94314378.93819806,
      btcVolume: 10278.861999376948,
      usdVolume: 94314378.93819806,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '236.0000000000000000',
      high: '246.6600000000000000',
      low: '235.5700000000000000',
      close: '245.5900000000000000',
      volume: '637462.0807400000000000',
      quoteVolume: 154502240.94120297,
      btcVolume: 16568.240820505314,
      usdVolume: 154502240.94120297,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '245.5300000000000000',
      high: '269.6100000000000000',
      low: '241.5100000000000000',
      close: '263.7400000000000000',
      volume: '718412.8694500000000000',
      quoteVolume: 180691409.64491135,
      btcVolume: 19223.102848907532,
      usdVolume: 180691409.64491135,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '263.7500000000000000',
      high: '279.9800000000000000',
      low: '259.7000000000000000',
      close: '275.3000000000000000',
      volume: '1068774.5787900000000000',
      quoteVolume: 287217202.72011954,
      btcVolume: 30130.661822095655,
      usdVolume: 287217202.72011954,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '275.3100000000000000',
      high: '287.3400000000000000',
      low: '266.0000000000000000',
      close: '279.1500000000000000',
      volume: '926033.0669200000000000',
      quoteVolume: 256347245.54814723,
      btcVolume: 26844.91878187144,
      usdVolume: 256347245.54814723,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '279.1500000000000000',
      high: '308.9400000000000000',
      low: '0.0000102300000000',
      close: '305.2600000000000000',
      volume: '1060891.1375400000000000',
      quoteVolume: 308799629.3812334,
      btcVolume: 32052.82286372341,
      usdVolume: 308799821.8866399,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '305.3100000000000000',
      high: '318.7500000000000000',
      low: '299.0000000000000000',
      close: '311.0000000000000000',
      volume: '1270368.7530000000000000',
      quoteVolume: 392346895.32266885,
      btcVolume: 39870.04525589428,
      usdVolume: 392346895.32266885,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '311.0200000000000000',
      high: '333.3100000000000000',
      low: '310.7800000000000000',
      close: '321.9300000000000000',
      volume: '1636122.3472700000000000',
      quoteVolume: 527292799.2879079,
      btcVolume: 50537.47976051564,
      usdVolume: 527292799.2879079,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '321.9500000000000000',
      high: '326.8000000000000000',
      low: '305.8600000000000000',
      close: '316.7700000000000000',
      volume: '1093231.5557800000000000',
      quoteVolume: 346187853.2759322,
      btcVolume: 31616.78758360037,
      usdVolume: 346187853.2759322,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '316.7700000000000000',
      high: '325.0000000000000000',
      low: '312.3800000000000000',
      close: '317.7100000000000000',
      volume: '792802.3214800000000000',
      quoteVolume: 254201662.13340423,
      btcVolume: 22934.294000855425,
      usdVolume: 254201662.13340423,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '317.7600000000000000',
      high: '342.0000000000000000',
      low: '314.0500000000000000',
      close: '334.8700000000000000',
      volume: '1008827.2143400000000000',
      quoteVolume: 328286340.23770994,
      btcVolume: 29714.76886977982,
      usdVolume: 328286340.23770994,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '334.8200000000000000',
      high: '349.4500000000000000',
      low: '328.4000000000000000',
      close: '346.3300000000000000',
      volume: '948088.4498200000000000',
      quoteVolume: 323282981.0159161,
      btcVolume: 28866.335039593676,
      usdVolume: 323282981.0159161,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '346.3200000000000000',
      high: '392.6700000000000000',
      low: '343.0700000000000000',
      close: '387.0000000000000000',
      volume: '1440843.3420300000000000',
      quoteVolume: 527543444.8628902,
      btcVolume: 45339.60061159785,
      usdVolume: 527543444.8628902,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '387.0100000000000000',
      high: '415.8500000000000000',
      low: '325.0000000000000000',
      close: '372.4200000000000000',
      volume: '2324909.0961600000000000',
      quoteVolume: 878397036.2975318,
      btcVolume: 77495.83814159164,
      usdVolume: 878397036.2975318,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '372.4500000000000000',
      high: '398.0000000000000000',
      low: '366.5000000000000000',
      close: '385.9400000000000000',
      volume: '1191565.0938900000000000',
      quoteVolume: 462170587.4784976,
      btcVolume: 41057.234921476615,
      usdVolume: 462170587.4784976,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '385.9100000000000000',
      high: '402.9800000000000000',
      low: '380.7700000000000000',
      close: '389.6100000000000000',
      volume: '1175763.9381400000000000',
      quoteVolume: 459835053.5956598,
      btcVolume: 40921.10256553506,
      usdVolume: 459835053.5956598,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '389.5900000000000000',
      high: '407.0000000000000000',
      low: '384.1100000000000000',
      close: '400.7200000000000000',
      volume: '1124119.0672000000000000',
      quoteVolume: 444335097.1493966,
      btcVolume: 38666.919386438094,
      usdVolume: 444335097.1493966,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '400.7200000000000000',
      high: '403.6600000000000000',
      low: '390.5100000000000000',
      close: '394.7700000000000000',
      volume: '821379.0839700000000000',
      quoteVolume: 325718417.71600866,
      btcVolume: 27726.769672481845,
      usdVolume: 325718417.71600866,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '394.7700000000000000',
      high: '398.2600000000000000',
      low: '362.8500000000000000',
      close: '379.3400000000000000',
      volume: '1041207.3594600000000000',
      quoteVolume: 398625567.06390804,
      btcVolume: 34262.12382888949,
      usdVolume: 398625567.06390804,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '379.3400000000000000',
      high: '397.0500000000000000',
      low: '375.7900000000000000',
      close: '397.0300000000000000',
      volume: '623013.8133600000000000',
      quoteVolume: 240992993.07174557,
      btcVolume: 20611.4249741265,
      usdVolume: 240992993.07174557,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '397.0500000000000000',
      high: '400.0000000000000000',
      low: '383.3600000000000000',
      close: '390.1400000000000000',
      volume: '594729.4803400000000000',
      quoteVolume: 232989976.98507214,
      btcVolume: 19961.97802262133,
      usdVolume: 232989976.98507214,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '390.1400000000000000',
      high: '399.1800000000000000',
      low: '385.1300000000000000',
      close: '395.4400000000000000',
      volume: '826969.9063300000000000',
      quoteVolume: 326372434.89705575,
      btcVolume: 27443.329738405846,
      usdVolume: 326372434.89705575,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '395.4300000000000000',
      high: '398.2100000000000000',
      low: '367.0000000000000000',
      close: '379.0000000000000000',
      volume: '992412.6023000000000000',
      quoteVolume: 381981614.62167376,
      btcVolume: 33030.351758669196,
      usdVolume: 381981614.62167376,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '378.9900000000000000',
      high: '389.2300000000000000',
      low: '365.8500000000000000',
      close: '387.1800000000000000',
      volume: '751848.7983000000000000',
      quoteVolume: 285872467.64409924,
      btcVolume: 24976.58134909489,
      usdVolume: 285872467.64409924,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '387.1800000000000000',
      high: '431.4900000000000000',
      low: '376.5900000000000000',
      close: '424.5400000000000000',
      volume: '1511330.7732000000000000',
      quoteVolume: 604810975.1318486,
      btcVolume: 52246.13470100553,
      usdVolume: 604810975.1318486,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '424.5400000000000000',
      high: '444.7100000000000000',
      low: '417.0500000000000000',
      close: '438.1100000000000000',
      volume: '1108919.1175900000000000',
      quoteVolume: 476850417.99412924,
      btcVolume: 40596.08524064946,
      usdVolume: 476850417.99412924,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '438.1200000000000000',
      high: '442.9400000000000000',
      low: '427.0200000000000000',
      close: '432.3200000000000000',
      volume: '681696.2610000000000000',
      quoteVolume: 296651951.35869735,
      btcVolume: 25006.734408894456,
      usdVolume: 296651951.35869735,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '432.3000000000000000',
      high: '436.9900000000000000',
      low: '412.4700000000000000',
      close: '433.6200000000000000',
      volume: '716666.2525800000000000',
      quoteVolume: 305445885.8166684,
      btcVolume: 25821.942518111297,
      usdVolume: 305445885.8166684,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '433.6100000000000000',
      high: '446.0000000000000000',
      low: '420.3700000000000000',
      close: '430.8900000000000000',
      volume: '868870.6499600000000000',
      quoteVolume: 375038999.50752,
      btcVolume: 31000.024384319247,
      usdVolume: 375038999.50752,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '430.8900000000000000',
      high: '433.8100000000000000',
      low: '415.0000000000000000',
      close: '421.9500000000000000',
      volume: '743254.5701800000000000',
      quoteVolume: 316635286.99291563,
      btcVolume: 26116.737005652198,
      usdVolume: 316635286.99291563,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '421.9200000000000000',
      high: '426.7500000000000000',
      low: '393.2500000000000000',
      close: '407.4600000000000000',
      volume: '978339.6922100000000000',
      quoteVolume: 400208683.34672534,
      btcVolume: 34018.89009102192,
      usdVolume: 400208683.34672534,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '407.4600000000000000',
      high: '418.9800000000000000',
      low: '402.0700000000000000',
      close: '415.9400000000000000',
      volume: '660964.6963900000000000',
      quoteVolume: 272240068.376272,
      btcVolume: 23069.824029488518,
      usdVolume: 272240068.376272,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '415.9400000000000000',
      high: '418.6800000000000000',
      low: '385.1000000000000000',
      close: '387.9700000000000000',
      volume: '860092.2392900000000000',
      quoteVolume: 347090880.07555234,
      btcVolume: 29626.18363494332,
      usdVolume: 347090880.07555234,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '387.9300000000000000',
      high: '397.0400000000000000',
      low: '379.9200000000000000',
      close: '395.2400000000000000',
      volume: '643112.7567000000000000',
      quoteVolume: 250021920.7245382,
      btcVolume: 21657.375885407164,
      usdVolume: 250021920.7245382,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '395.2300000000000000',
      high: '396.9500000000000000',
      low: '383.1500000000000000',
      close: '390.7100000000000000',
      volume: '445307.5880200000000000',
      quoteVolume: 173795952.99000394,
      btcVolume: 14968.181091353075,
      usdVolume: 173795952.99000394,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '390.7200000000000000',
      high: '410.4200000000000000',
      low: '387.4400000000000000',
      close: '407.6800000000000000',
      volume: '717395.9721000000000000',
      quoteVolume: 287988643.53621656,
      btcVolume: 24526.709155711666,
      usdVolume: 287988643.53621656,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '407.6800000000000000',
      high: '409.0600000000000000',
      low: '370.0000000000000000',
      close: '382.9300000000000000',
      volume: '951557.2886700000000000',
      quoteVolume: 368309918.2113772,
      btcVolume: 32162.02308140377,
      usdVolume: 368309918.2113772,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '382.9100000000000000',
      high: '392.9100000000000000',
      low: '377.3700000000000000',
      close: '385.8100000000000000',
      volume: '738590.5295000000000000',
      quoteVolume: 284465412.4634799,
      btcVolume: 24953.561629989617,
      usdVolume: 284465412.4634799,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '385.8100000000000000',
      high: '397.1000000000000000',
      low: '371.4800000000000000',
      close: '383.2700000000000000',
      volume: '876931.1946000000000000',
      quoteVolume: 336328075.9025886,
      btcVolume: 29644.310136701326,
      usdVolume: 336328075.9025886,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '383.3100000000000000',
      high: '398.0000000000000000',
      low: '379.7500000000000000',
      close: '395.1100000000000000',
      volume: '721730.6497200000000000',
      quoteVolume: 282252408.9354685,
      btcVolume: 24674.28459629944,
      usdVolume: 282252408.9354685,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '395.1000000000000000',
      high: '405.6900000000000000',
      low: '391.8800000000000000',
      close: '398.6500000000000000',
      volume: '654320.0035500000000000',
      quoteVolume: 261582840.97993016,
      btcVolume: 22739.15544880467,
      usdVolume: 261582840.97993016,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '398.6500000000000000',
      high: '429.8900000000000000',
      low: '398.3000000000000000',
      close: '428.9400000000000000',
      volume: '851160.6742400000000000',
      quoteVolume: 352311003.28142095,
      btcVolume: 30325.394571282835,
      usdVolume: 352311003.28142095,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '428.9400000000000000',
      high: '439.0000000000000000',
      low: '418.0400000000000000',
      close: '433.7900000000000000',
      volume: '1040748.6888100000000000',
      quoteVolume: 447866996.185111,
      btcVolume: 38337.44332463666,
      usdVolume: 447866996.185111,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '433.8000000000000000',
      high: '488.8400000000000000',
      low: '429.0300000000000000',
      close: '475.4000000000000000',
      volume: '1656066.8494900000000000',
      quoteVolume: 769006775.5385326,
      btcVolume: 64803.214479942595,
      usdVolume: 768959484.5333103,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '475.4000000000000000',
      high: '481.3900000000000000',
      low: '420.0000000000000000',
      close: '439.5000000000000000',
      volume: '1876373.4429600000000000',
      quoteVolume: 842455901.235392,
      btcVolume: 73163.14575846263,
      usdVolume: 842455901.235392,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '439.5000000000000000',
      high: '450.7000000000000000',
      low: '372.0000000000000000',
      close: '381.4000000000000000',
      volume: '2402627.0353300000000000',
      quoteVolume: 993081852.551086,
      btcVolume: 90931.01288632085,
      usdVolume: 993081852.551086,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '381.3500000000000000',
      high: '400.2100000000000000',
      low: '357.0000000000000000',
      close: '385.2600000000000000',
      volume: '1806487.9639800000000000',
      quoteVolume: 696658830.8205959,
      btcVolume: 67364.86665822042,
      usdVolume: 696658830.8205959,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '385.3000000000000000',
      high: '395.0000000000000000',
      low: '308.4200000000000000',
      close: '335.0100000000000000',
      volume: '2776774.5031000000000000',
      quoteVolume: 967581076.4469733,
      btcVolume: 94990.08545339752,
      usdVolume: 967581076.4469733,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '335.0400000000000000',
      high: '360.0000000000000000',
      low: '316.1000000000000000',
      close: '352.8000000000000000',
      volume: '2017811.1472100000000000',
      quoteVolume: 691925087.3968136,
      btcVolume: 67832.96092299436,
      usdVolume: 691925087.3968136,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '352.8500000000000000',
      high: '358.5100000000000000',
      low: '323.0000000000000000',
      close: '353.5900000000000000',
      volume: '1721408.5783500000000000',
      quoteVolume: 590110812.7562342,
      btcVolume: 58285.20441211165,
      usdVolume: 590110812.7562342,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '353.5900000000000000',
      high: '356.5800000000000000',
      low: '325.3800000000000000',
      close: '337.3900000000000000',
      volume: '1400246.8296200000000000',
      quoteVolume: 476444912.54462713,
      btcVolume: 47124.00188261758,
      usdVolume: 476444912.54462713,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '337.4100000000000000',
      high: '359.0000000000000000',
      low: '330.5100000000000000',
      close: '350.8500000000000000',
      volume: '879760.5625500000000000',
      quoteVolume: 304910633.80089056,
      btcVolume: 29909.92428361022,
      usdVolume: 304910633.80089056,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '350.8500000000000000',
      high: '377.2500000000000000',
      low: '349.9100000000000000',
      close: '367.9300000000000000',
      volume: '1134065.2960700000000000',
      quoteVolume: 416517016.27456033,
      btcVolume: 40287.65628581293,
      usdVolume: 416517016.27456033,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '367.9300000000000000',
      high: '375.2700000000000000',
      low: '355.4900000000000000',
      close: '373.6300000000000000',
      volume: '844017.5996600000000000',
      quoteVolume: 308822715.13365656,
      btcVolume: 29997.26435090535,
      usdVolume: 308822715.13365656,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '373.6200000000000000',
      high: '388.5700000000000000',
      low: '364.7300000000000000',
      close: '387.7800000000000000',
      volume: '766542.3163500000000000',
      quoteVolume: 287133871.2472935,
      btcVolume: 27668.664342038548,
      usdVolume: 287133871.2472935,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '387.7800000000000000',
      high: '390.4300000000000000',
      low: '350.0000000000000000',
      close: '366.4200000000000000',
      volume: '989195.6904700000000000',
      quoteVolume: 367969518.5134864,
      btcVolume: 35461.65474002583,
      usdVolume: 367969518.5134864,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '366.4300000000000000',
      high: '384.6400000000000000',
      low: '355.7300000000000000',
      close: '377.1200000000000000',
      volume: '1042059.6226500000000000',
      quoteVolume: 387238794.68096745,
      btcVolume: 36789.98529463114,
      usdVolume: 387238794.68096745,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '377.1200000000000000',
      high: '382.4700000000000000',
      low: '362.3400000000000000',
      close: '364.0600000000000000',
      volume: '883856.3300500000000000',
      quoteVolume: 328317009.5683414,
      btcVolume: 30470.16739104851,
      usdVolume: 328317009.5683414,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '364.0500000000000000',
      high: '373.4500000000000000',
      low: '355.0000000000000000',
      close: '365.1100000000000000',
      volume: '841709.9893900000000000',
      quoteVolume: 306622238.0715463,
      btcVolume: 28177.270136204377,
      usdVolume: 306622238.0715463,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '365.0400000000000000',
      high: '394.1400000000000000',
      low: '363.3000000000000000',
      close: '389.3600000000000000',
      volume: '1256300.4110000000000000',
      quoteVolume: 479026710.896617,
      btcVolume: 43950.597949227995,
      usdVolume: 479026710.896617,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '389.3500000000000000',
      high: '392.5800000000000000',
      low: '375.3300000000000000',
      close: '384.3900000000000000',
      volume: '804194.4721900000000000',
      quoteVolume: 308748579.81603116,
      btcVolume: 28281.960169687176,
      usdVolume: 308748579.81603116,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '384.4000000000000000',
      high: '388.7200000000000000',
      low: '377.0000000000000000',
      close: '385.2500000000000000',
      volume: '570908.3355300000000000',
      quoteVolume: 218785730.14017949,
      btcVolume: 19860.826040587017,
      usdVolume: 218785730.14017949,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '385.2500000000000000',
      high: '385.4500000000000000',
      low: '365.0000000000000000',
      close: '371.0400000000000000',
      volume: '692270.5369000000000000',
      quoteVolume: 259365469.57772288,
      btcVolume: 23796.352412135322,
      usdVolume: 259365469.57772288,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '371.0400000000000000',
      high: '376.8000000000000000',
      low: '331.0000000000000000',
      close: '340.3600000000000000',
      volume: '1283946.0309800000000000',
      quoteVolume: 452134893.6340549,
      btcVolume: 42542.986905510355,
      usdVolume: 452134893.6340549,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '340.3100000000000000',
      high: '346.9100000000000000',
      low: '335.3400000000000000',
      close: '344.2100000000000000',
      volume: '842519.6630700000000000',
      quoteVolume: 288094132.61557794,
      btcVolume: 27542.53930759665,
      usdVolume: 288094132.61557794,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '344.1800000000000000',
      high: '344.6500000000000000',
      low: '313.2900000000000000',
      close: '320.7200000000000000',
      volume: '940904.4975600000000000',
      quoteVolume: 313808761.09044343,
      btcVolume: 30190.07605426562,
      usdVolume: 313808761.09044343,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '320.7200000000000000',
      high: '353.1000000000000000',
      low: '316.6700000000000000',
      close: '348.9700000000000000',
      volume: '1070458.7500100000000000',
      quoteVolume: 360295904.75400215,
      btcVolume: 34447.673829712236,
      usdVolume: 360295904.75400215,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '348.9700000000000000',
      high: '358.0000000000000000',
      low: '337.5800000000000000',
      close: '351.9200000000000000',
      volume: '860643.7792400000000000',
      quoteVolume: 298266918.80064315,
      btcVolume: 27960.07827767787,
      usdVolume: 298266918.80064315,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '351.9300000000000000',
      high: '356.4000000000000000',
      low: '345.8800000000000000',
      close: '353.9200000000000000',
      volume: '530698.1258100000000000',
      quoteVolume: 186748081.4048322,
      btcVolume: 17435.278426956505,
      usdVolume: 186748081.4048322,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '353.9500000000000000',
      high: '362.1400000000000000',
      low: '347.2400000000000000',
      close: '357.4900000000000000',
      volume: '569255.6546900000000000',
      quoteVolume: 202300944.46097547,
      btcVolume: 18873.55464049207,
      usdVolume: 202300944.46097547,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '357.5800000000000000',
      high: '368.2000000000000000',
      low: '351.7000000000000000',
      close: '353.9600000000000000',
      volume: '757758.4304000000000000',
      quoteVolume: 273063157.7899871,
      btcVolume: 25118.54484016467,
      usdVolume: 273063157.7899871,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '353.9100000000000000',
      high: '360.6300000000000000',
      low: '350.4500000000000000',
      close: '359.7900000000000000',
      volume: '585138.1015300000000000',
      quoteVolume: 208027010.0546198,
      btcVolume: 19389.23399823974,
      usdVolume: 208027010.0546198,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '359.7800000000000000',
      high: '361.5000000000000000',
      low: '351.5600000000000000',
      close: '359.8300000000000000',
      volume: '498791.2080200000000000',
      quoteVolume: 177730785.31600055,
      btcVolume: 16544.49139067945,
      usdVolume: 177730785.31600055,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '359.8300000000000000',
      high: '370.4500000000000000',
      low: '345.5100000000000000',
      close: '353.0300000000000000',
      volume: '749944.4218500000000000',
      quoteVolume: 268507606.4936562,
      btcVolume: 25081.67090101762,
      usdVolume: 268507606.4936562,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '351.5000000000000000',
      high: '354.5500000000000000',
      low: '334.2400000000000000',
      close: '345.6000000000000000',
      volume: '728971.7125400000000000',
      quoteVolume: 250210659.13679448,
      btcVolume: 23823.341024494177,
      usdVolume: 250210659.13679448,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '345.6000000000000000',
      high: '351.0000000000000000',
      low: '343.7000000000000000',
      close: '345.9500000000000000',
      volume: '303595.7190100000000000',
      quoteVolume: 105401893.35734256,
      btcVolume: 9994.847525254354,
      usdVolume: 105401893.35734256,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '345.9400000000000000',
      high: '354.4500000000000000',
      low: '343.8100000000000000',
      close: '352.3700000000000000',
      volume: '343932.9171200000000000',
      quoteVolume: 119961274.70065778,
      btcVolume: 11308.936300639205,
      usdVolume: 119961274.70065778,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '352.3400000000000000',
      high: '356.1000000000000000',
      low: '348.5400000000000000',
      close: '353.6200000000000000',
      volume: '376740.3069200000000000',
      quoteVolume: 132656713.04409541,
      btcVolume: 12401.031763501092,
      usdVolume: 132656713.04409541,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '353.6300000000000000',
      high: '355.1000000000000000',
      low: '336.8000000000000000',
      close: '340.7500000000000000',
      volume: '640027.0228500000000000',
      quoteVolume: 221752725.91989398,
      btcVolume: 20777.07296176719,
      usdVolume: 221752725.91989398,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '340.8000000000000000',
      high: '342.8500000000000000',
      low: '332.1800000000000000',
      close: '341.5800000000000000',
      volume: '541928.8637600000000000',
      quoteVolume: 183873686.39043358,
      btcVolume: 17327.5506872979,
      usdVolume: 183873686.39043358,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '341.5800000000000000',
      high: '353.3200000000000000',
      low: '334.2100000000000000',
      close: '350.9500000000000000',
      volume: '622582.1733200000000000',
      quoteVolume: 214085732.44744968,
      btcVolume: 19925.932109701625,
      usdVolume: 214085732.44744968,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '350.9400000000000000',
      high: '368.3300000000000000',
      low: '347.3200000000000000',
      close: '365.0200000000000000',
      volume: '650478.1933800000000000',
      quoteVolume: 232878561.88521227,
      btcVolume: 21172.01801686735,
      usdVolume: 232878561.88521227,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '365.0100000000000000',
      high: '378.4000000000000000',
      low: '364.7100000000000000',
      close: '370.5200000000000000',
      volume: '591005.3212900000000000',
      quoteVolume: 220607973.01227474,
      btcVolume: 19473.834142001007,
      usdVolume: 220607973.01227474,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '370.5100000000000000',
      high: '377.6000000000000000',
      low: '368.8700000000000000',
      close: '374.1600000000000000',
      volume: '371050.1549000000000000',
      quoteVolume: 138482829.08381432,
      btcVolume: 12189.491907556405,
      usdVolume: 138482829.08381432,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '374.1700000000000000',
      high: '394.8000000000000000',
      low: '365.5800000000000000',
      close: '386.5100000000000000',
      volume: '777805.9870200000000000',
      quoteVolume: 295379758.31860316,
      btcVolume: 25799.813917940977,
      usdVolume: 295379758.31860316,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '386.5100000000000000',
      high: '387.7500000000000000',
      low: '374.5100000000000000',
      close: '381.0400000000000000',
      volume: '614228.9526600000000000',
      quoteVolume: 234463380.94917893,
      btcVolume: 20497.322200014103,
      usdVolume: 234463380.94917893,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '381.0500000000000000',
      high: '387.4100000000000000',
      low: '373.1300000000000000',
      close: '378.6900000000000000',
      volume: '531769.6736300000000000',
      quoteVolume: 201932522.19194937,
      btcVolume: 17718.0848554347,
      usdVolume: 201932522.19194937,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '378.6900000000000000',
      high: '381.7900000000000000',
      low: '369.8500000000000000',
      close: '377.6400000000000000',
      volume: '632253.2404100000000000',
      quoteVolume: 237722026.66919282,
      btcVolume: 20862.537140127723,
      usdVolume: 237722026.66919282,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '377.6300000000000000',
      high: '379.8300000000000000',
      low: '361.1800000000000000',
      close: '365.5000000000000000',
      volume: '654029.3640400000000000',
      quoteVolume: 241127894.70244646,
      btcVolume: 21270.53628135259,
      usdVolume: 241127894.70244646,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '365.5000000000000000',
      high: '370.4600000000000000',
      low: '363.3600000000000000',
      close: '368.3200000000000000',
      volume: '285578.2453700000000000',
      quoteVolume: 104838880.45610258,
      btcVolume: 9248.343343944713,
      usdVolume: 104838880.45610258,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '368.3100000000000000',
      high: '378.6400000000000000',
      low: '367.1000000000000000',
      close: '378.1100000000000000',
      volume: '323468.4709500000000000',
      quoteVolume: 120818450.62118112,
      btcVolume: 10571.651931836788,
      usdVolume: 120818450.62118112,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '378.1200000000000000',
      high: '384.3200000000000000',
      low: '372.5000000000000000',
      close: '379.3000000000000000',
      volume: '577252.9026500000000000',
      quoteVolume: 218372639.95702484,
      btcVolume: 18818.921615016232,
      usdVolume: 218372639.95702484,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '379.3000000000000000',
      high: '380.7300000000000000',
      low: '365.2700000000000000',
      close: '368.5200000000000000',
      volume: '617309.8167700000000000',
      quoteVolume: 229928443.79690987,
      btcVolume: 19409.513615432777,
      usdVolume: 229928443.79690987,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '368.5300000000000000',
      high: '401.4900000000000000',
      low: '367.7400000000000000',
      close: '390.3800000000000000',
      volume: '1221663.6378400000000000',
      quoteVolume: 472488832.71627617,
      btcVolume: 37631.22329892552,
      usdVolume: 472488832.71627617,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '390.3100000000000000',
      high: '420.7300000000000000',
      low: '390.2100000000000000',
      close: '413.9800000000000000',
      volume: '1095886.2298700000000000',
      quoteVolume: 447206682.58595973,
      btcVolume: 34562.69697720812,
      usdVolume: 447206682.58595973,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '413.9400000000000000',
      high: '419.3300000000000000',
      low: '401.3000000000000000',
      close: '405.9200000000000000',
      volume: '642369.4293200000000000',
      quoteVolume: 265063811.5230488,
      btcVolume: 20523.233149956377,
      usdVolume: 265063811.5230488,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '409.1900000000000000',
      high: '416.6600000000000000',
      low: '407.0000000000000000',
      close: '412.1400000000000000',
      volume: '419236.8420100000000000',
      quoteVolume: 172799812.65625998,
      btcVolume: 13272.573133752301,
      usdVolume: 172799812.65625998,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '412.1400000000000000',
      high: '417.4000000000000000',
      low: '402.5000000000000000',
      close: '405.8600000000000000',
      volume: '415431.1155000000000000',
      quoteVolume: 169990300.58460298,
      btcVolume: 13026.150189403857,
      usdVolume: 169990300.58460298,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '405.8500000000000000',
      high: '411.1100000000000000',
      low: '382.3000000000000000',
      close: '392.5200000000000000',
      volume: '894757.9162500000000000',
      quoteVolume: 356060346.50108105,
      btcVolume: 27339.184264126954,
      usdVolume: 356060346.50108105,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '392.5200000000000000',
      high: '410.2500000000000000',
      low: '389.9000000000000000',
      close: '403.4500000000000000',
      volume: '753617.4010100000000000',
      quoteVolume: 302269548.1476697,
      btcVolume: 22546.706790982997,
      usdVolume: 302269548.1476697,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '403.4100000000000000',
      high: '409.2000000000000000',
      low: '380.5200000000000000',
      close: '388.2300000000000000',
      volume: '816953.5301800000000000',
      quoteVolume: 320590416.22950745,
      btcVolume: 23922.336631484806,
      usdVolume: 320590416.22950745,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '388.2200000000000000',
      high: '393.7200000000000000',
      low: '380.5200000000000000',
      close: '387.1300000000000000',
      volume: '573096.0141600000000000',
      quoteVolume: 222669504.8753169,
      btcVolume: 16722.98084116485,
      usdVolume: 222669504.8753169,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '387.1200000000000000',
      high: '391.9700000000000000',
      low: '373.2900000000000000',
      close: '382.4900000000000000',
      volume: '771750.7474600000000000',
      quoteVolume: 294342545.9808737,
      btcVolume: 22024.58912338817,
      usdVolume: 294342545.9808737,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '382.5900000000000000',
      high: '394.8800000000000000',
      low: '379.9000000000000000',
      close: '386.4600000000000000',
      volume: '466870.1136200000000000',
      quoteVolume: 180911022.7121731,
      btcVolume: 13143.343560424059,
      usdVolume: 180911022.7121731,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '386.4400000000000000',
      high: '398.2900000000000000',
      low: '384.6100000000000000',
      close: '396.6700000000000000',
      volume: '438952.9556500000000000',
      quoteVolume: 171238317.7003242,
      btcVolume: 12438.544674604154,
      usdVolume: 171238317.7003242,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '396.6800000000000000',
      high: '404.5000000000000000',
      low: '377.9200000000000000',
      close: '383.0200000000000000',
      volume: '779968.5795700000000000',
      quoteVolume: 304223545.89203674,
      btcVolume: 22406.862744217426,
      usdVolume: 304223545.89203674,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '383.0100000000000000',
      high: '390.0000000000000000',
      low: '370.2300000000000000',
      close: '387.8800000000000000',
      volume: '752954.3711400000000000',
      quoteVolume: 286291773.9684873,
      btcVolume: 21076.429697023013,
      usdVolume: 286291773.9684873,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '387.8800000000000000',
      high: '408.3900000000000000',
      low: '376.4700000000000000',
      close: '402.4900000000000000',
      volume: '1157064.3507600000000000',
      quoteVolume: 454481208.4020135,
      btcVolume: 32586.363296352156,
      usdVolume: 454481208.4020135,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '402.5000000000000000',
      high: '420.4000000000000000',
      low: '396.1400000000000000',
      close: '416.6900000000000000',
      volume: '1475139.7734200000000000',
      quoteVolume: 601591250.4012383,
      btcVolume: 40712.080001064045,
      usdVolume: 601591250.4012383,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '416.7300000000000000',
      high: '458.2700000000000000',
      low: '414.7600000000000000',
      close: '455.9100000000000000',
      volume: '1679301.1392700000000000',
      quoteVolume: 734615875.017384,
      btcVolume: 47133.026973207314,
      usdVolume: 734615875.017384,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '455.9100000000000000',
      high: '468.2800000000000000',
      low: '424.1400000000000000',
      close: '435.2100000000000000',
      volume: '1653416.5961000000000000',
      quoteVolume: 743871175.7821237,
      btcVolume: 48717.33214579415,
      usdVolume: 743871175.7821237,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '435.2000000000000000',
      high: '460.1000000000000000',
      low: '431.9900000000000000',
      close: '454.3000000000000000',
      volume: '840722.7949000000000000',
      quoteVolume: 375595944.18961173,
      btcVolume: 24675.810368249833,
      usdVolume: 375595944.18961173,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '454.2900000000000000',
      high: '459.1000000000000000',
      low: '433.0900000000000000',
      close: '444.3200000000000000',
      volume: '1098601.4997800000000000',
      quoteVolume: 491653842.88129467,
      btcVolume: 32077.913407959655,
      usdVolume: 491653842.88129467,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '444.3200000000000000',
      high: '455.0000000000000000',
      low: '438.7000000000000000',
      close: '450.3400000000000000',
      volume: '866686.4285100000000000',
      quoteVolume: 388106243.05318296,
      btcVolume: 25360.781987038194,
      usdVolume: 388106243.05318296,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '450.3400000000000000',
      high: '476.2500000000000000',
      low: '449.2800000000000000',
      close: '463.0900000000000000',
      volume: '1205782.8426200000000000',
      quoteVolume: 559228791.8903673,
      btcVolume: 35902.59522880581,
      usdVolume: 559228791.8903673,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '463.0900000000000000',
      high: '470.0000000000000000',
      low: '451.2000000000000000',
      close: '462.3900000000000000',
      volume: '990776.6291200000000000',
      quoteVolume: 455971309.2168636,
      btcVolume: 28686.52115959985,
      usdVolume: 455971309.2168636,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '462.4800000000000000',
      high: '478.0100000000000000',
      low: '457.1200000000000000',
      close: '476.4300000000000000',
      volume: '951211.0953600000000000',
      quoteVolume: 444338362.5908921,
      btcVolume: 27326.06235354085,
      usdVolume: 444338362.5908921,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '476.4200000000000000',
      high: '477.4700000000000000',
      low: '452.0000000000000000',
      close: '460.8900000000000000',
      volume: '726969.1507100000000000',
      quoteVolume: 336364969.86737686,
      btcVolume: 21033.888011294177,
      usdVolume: 336364969.86737686,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '460.9000000000000000',
      high: '462.8900000000000000',
      low: '440.1900000000000000',
      close: '448.0800000000000000',
      volume: '665184.8098900000000000',
      quoteVolume: 301391078.6921614,
      btcVolume: 18876.13024282066,
      usdVolume: 301391078.6921614,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '448.0700000000000000',
      high: '466.0000000000000000',
      low: '445.4500000000000000',
      close: '460.5500000000000000',
      volume: '811687.1632900000000000',
      quoteVolume: 370720232.1125867,
      btcVolume: 22611.477082211626,
      usdVolume: 370720232.1125867,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '460.5100000000000000',
      high: '484.8800000000000000',
      low: '459.8000000000000000',
      close: '482.1500000000000000',
      volume: '1120652.5363800000000000',
      quoteVolume: 529585259.9541648,
      btcVolume: 30825.11456878513,
      usdVolume: 529585259.9541648,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '482.1100000000000000',
      high: '495.3100000000000000',
      low: '456.6000000000000000',
      close: '478.4100000000000000',
      volume: '1474767.0885100000000000',
      quoteVolume: 705288664.0718446,
      btcVolume: 39474.871305535446,
      usdVolume: 705288664.0718446,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '478.4300000000000000',
      high: '480.7500000000000000',
      low: '464.8600000000000000',
      close: '471.2500000000000000',
      volume: '813313.3043500000000000',
      quoteVolume: 385259724.0413454,
      btcVolume: 21647.693501398127,
      usdVolume: 385259724.0413454,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '471.2600000000000000',
      high: '514.4000000000000000',
      low: '470.6500000000000000',
      close: '510.1200000000000000',
      volume: '1504164.8389300000000000',
      quoteVolume: 750785717.8739455,
      btcVolume: 40955.1380609167,
      usdVolume: 750785717.8739455,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '510.1200000000000000',
      high: '553.3200000000000000',
      low: '502.8200000000000000',
      close: '552.3500000000000000',
      volume: '1548851.2906700000000000',
      quoteVolume: 815402228.5227968,
      btcVolume: 43572.12042857939,
      usdVolume: 815402228.5227968,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '552.4200000000000000',
      high: '580.8800000000000000',
      low: '511.2800000000000000',
      close: '560.0300000000000000',
      volume: '1850317.9358100000000000',
      quoteVolume: 1014064539.0881541,
      btcVolume: 55112.07754619641,
      usdVolume: 1014064539.0881541,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '560.0300000000000000',
      high: '610.8500000000000000',
      low: '550.1200000000000000',
      close: '608.5800000000000000',
      volume: '2059928.2932900000000000',
      quoteVolume: 1208598659.5999002,
      btcVolume: 65547.02278720128,
      usdVolume: 1208598659.5999002,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '608.5800000000000000',
      high: '623.0000000000000000',
      low: '585.0000000000000000',
      close: '604.9700000000000000',
      volume: '1606450.4919300000000000',
      quoteVolume: 976376501.3114522,
      btcVolume: 52075.479021025865,
      usdVolume: 976376501.3114522,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '604.9800000000000000',
      high: '606.0000000000000000',
      low: '553.8300000000000000',
      close: '569.5000000000000000',
      volume: '1215573.9318600000000000',
      quoteVolume: 714664659.3653877,
      btcVolume: 37634.657613884825,
      usdVolume: 714664659.3653877,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '569.3800000000000000',
      high: '577.1400000000000000',
      low: '481.0000000000000000',
      close: '519.8300000000000000',
      volume: '2683460.7417100000000000',
      quoteVolume: 1386030255.1680546,
      btcVolume: 79991.49178543665,
      usdVolume: 1386030255.1680546,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '519.8300000000000000',
      high: '530.6200000000000000',
      low: '493.7200000000000000',
      close: '518.6800000000000000',
      volume: '1088063.4322200000000000',
      quoteVolume: 558984876.9379048,
      btcVolume: 32921.56765547952,
      usdVolume: 558984876.9379048,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '518.5900000000000000',
      high: '547.9400000000000000',
      low: '506.5900000000000000',
      close: '537.5200000000000000',
      volume: '900508.6143100000000000',
      quoteVolume: 475169074.6903964,
      btcVolume: 27355.45626379676,
      usdVolume: 475169074.6903964,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '537.5000000000000000',
      high: '576.9000000000000000',
      low: '530.3200000000000000',
      close: '576.3000000000000000',
      volume: '855184.5340300000000000',
      quoteVolume: 471578566.1167156,
      btcVolume: 26185.011636034185,
      usdVolume: 471578566.1167156,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '576.3200000000000000',
      high: '616.8500000000000000',
      low: '570.0700000000000000',
      close: '616.6600000000000000',
      volume: '1501989.4540800000000000',
      quoteVolume: 895373784.8300341,
      btcVolume: 47102.56560570271,
      usdVolume: 895373784.8300341,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '616.6500000000000000',
      high: '635.7000000000000000',
      low: '560.0000000000000000',
      close: '585.2600000000000000',
      volume: '2127926.8705500000000000',
      quoteVolume: 1278451640.1510434,
      btcVolume: 66678.68357305365,
      usdVolume: 1278451640.1510434,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '585.2600000000000000',
      high: '604.9300000000000000',
      low: '575.0000000000000000',
      close: '596.8900000000000000',
      volume: '1069538.6561900000000000',
      quoteVolume: 632858653.2050295,
      btcVolume: 33458.77431583617,
      usdVolume: 632858653.2050295,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '596.8800000000000000',
      high: '623.4400000000000000',
      low: '585.8200000000000000',
      close: '615.8500000000000000',
      volume: '935687.9172300000000000',
      quoteVolume: 568024618.9410435,
      btcVolume: 29433.265740340947,
      usdVolume: 568024618.9410435,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '615.8400000000000000',
      high: '620.0000000000000000',
      low: '565.8200000000000000',
      close: '567.2400000000000000',
      volume: '1052452.7866600000000000',
      quoteVolume: 624543865.9008446,
      btcVolume: 32794.329183490656,
      usdVolume: 624543865.9008446,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '567.2400000000000000',
      high: '597.1100000000000000',
      low: '560.8800000000000000',
      close: '596.8000000000000000',
      volume: '701073.3926200000000000',
      quoteVolume: 410088998.40737647,
      btcVolume: 21637.275162148107,
      usdVolume: 410088998.40737647,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '596.8200000000000000',
      high: '607.5000000000000000',
      low: '583.0000000000000000',
      close: '601.5400000000000000',
      volume: '602496.9633900000000000',
      quoteVolume: 359236400.29498786,
      btcVolume: 18762.06787882863,
      usdVolume: 359236400.29498786,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '601.5900000000000000',
      high: '603.1500000000000000',
      low: '584.2200000000000000',
      close: '591.2800000000000000',
      volume: '498329.3758900000000000',
      quoteVolume: 295598087.0279998,
      btcVolume: 15413.761647877274,
      usdVolume: 295598087.0279998,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '591.2700000000000000',
      high: '595.0000000000000000',
      low: '549.2200000000000000',
      close: '554.9400000000000000',
      volume: '804443.0071700000000000',
      quoteVolume: 461781203.16172385,
      btcVolume: 24513.068401678625,
      usdVolume: 461781203.16172385,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '554.9200000000000000',
      high: '578.0800000000000000',
      low: '530.4300000000000000',
      close: '573.0900000000000000',
      volume: '997191.6484000000000000',
      quoteVolume: 555141876.6276906,
      btcVolume: 30469.950793735137,
      usdVolume: 555141876.6276906,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '573.2000000000000000',
      high: '575.7000000000000000',
      low: '548.1100000000000000',
      close: '558.9900000000000000',
      volume: '607169.0047400000000000',
      quoteVolume: 340648675.9975368,
      btcVolume: 18641.974867746296,
      usdVolume: 340648675.9975368,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '558.9900000000000000',
      high: '560.8000000000000000',
      low: '535.5000000000000000',
      close: '544.4300000000000000',
      volume: '747763.1198900000000000',
      quoteVolume: 408047285.092406,
      btcVolume: 22758.283163314973,
      usdVolume: 407979347.94980955,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '544.4600000000000000',
      high: '574.0000000000000000',
      low: '543.1000000000000000',
      close: '568.0400000000000000',
      volume: '539225.5604100000000000',
      quoteVolume: 301525321.23763144,
      btcVolume: 16307.87218266341,
      usdVolume: 301525321.23763144,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '568.0900000000000000',
      high: '595.0000000000000000',
      low: '563.2200000000000000',
      close: '590.7000000000000000',
      volume: '657794.4615800000000000',
      quoteVolume: 383072560.34673756,
      btcVolume: 19983.985401772963,
      usdVolume: 383072560.34673756,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '590.8100000000000000',
      high: '591.4800000000000000',
      low: '575.6000000000000000',
      close: '586.2900000000000000',
      volume: '492918.9449700000000000',
      quoteVolume: 288056061.22263926,
      btcVolume: 15035.10093618048,
      usdVolume: 288056061.22263926,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '586.2900000000000000',
      high: '597.2000000000000000',
      low: '578.5800000000000000',
      close: '588.6400000000000000',
      volume: '551569.0579400000000000',
      quoteVolume: 324168681.67163837,
      btcVolume: 16753.55977616931,
      usdVolume: 324168681.67163837,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '588.7800000000000000',
      high: '640.0000000000000000',
      low: '580.7100000000000000',
      close: '637.1800000000000000',
      volume: '1144619.8239400000000000',
      quoteVolume: 700180315.3934683,
      btcVolume: 34363.64123742975,
      usdVolume: 700180315.3934683,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '637.1800000000000000',
      high: '676.2500000000000000',
      low: '625.7600000000000000',
      close: '642.7000000000000000',
      volume: '2006130.0197500000000000',
      quoteVolume: 1307445033.1218147,
      btcVolume: 57721.48977556455,
      usdVolume: 1307445033.1218147,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '642.7100000000000000',
      high: '664.6100000000000000',
      low: '629.4000000000000000',
      close: '653.8500000000000000',
      volume: '1000012.9801800000000000',
      quoteVolume: 646217791.229689,
      btcVolume: 28267.30155209629,
      usdVolume: 646217791.229689,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '653.7600000000000000',
      high: '669.9500000000000000',
      low: '645.4200000000000000',
      close: '658.0300000000000000',
      volume: '858803.8845900000000000',
      quoteVolume: 564029933.2588553,
      btcVolume: 24101.66087667955,
      usdVolume: 564029933.2588553,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '658.0500000000000000',
      high: '661.3900000000000000',
      low: '621.0100000000000000',
      close: '638.1800000000000000',
      volume: '823958.2284300000000000',
      quoteVolume: 533109285.6519513,
      btcVolume: 22604.403561175943,
      usdVolume: 533109285.6519513,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '638.1000000000000000',
      high: '648.0000000000000000',
      low: '595.9000000000000000',
      close: '607.5800000000000000',
      volume: '834470.3823100000000000',
      quoteVolume: 518855748.82504433,
      btcVolume: 22402.25118531381,
      usdVolume: 518855748.82504433,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '607.6800000000000000',
      high: '637.7300000000000000',
      low: '588.0000000000000000',
      close: '636.4500000000000000',
      volume: '918851.2565400000000000',
      quoteVolume: 563872004.6334975,
      btcVolume: 24418.03638657798,
      usdVolume: 563872004.6334975,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '636.4100000000000000',
      high: '638.4400000000000000',
      low: '551.2800000000000000',
      close: '585.5600000000000000',
      volume: '1208807.3651800000000000',
      quoteVolume: 733443630.3656094,
      btcVolume: 31260.2759405341,
      usdVolume: 733443630.3656094,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '585.4800000000000000',
      high: '615.0200000000000000',
      low: '565.4300000000000000',
      close: '612.3100000000000000',
      volume: '886335.8633300000000000',
      quoteVolume: 519843556.98166037,
      btcVolume: 22398.590221377046,
      usdVolume: 519843556.98166037,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '612.3100000000000000',
      high: '634.2400000000000000',
      low: '604.2800000000000000',
      close: '626.8200000000000000',
      volume: '797946.9485900000000000',
      quoteVolume: 494050300.1567907,
      btcVolume: 20522.31724411632,
      usdVolume: 494050300.1567907,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '626.7800000000000000',
      high: '652.9100000000000000',
      low: '615.2600000000000000',
      close: '637.4400000000000000',
      volume: '958585.5064700000000000',
      quoteVolume: 607664782.0109019,
      btcVolume: 23891.58404704324,
      usdVolume: 607664782.0109019,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '637.4400000000000000',
      high: '717.1300000000000000',
      low: '625.0000000000000000',
      close: '685.1100000000000000',
      volume: '1859968.1502900000000000',
      quoteVolume: 1247547956.8239481,
      btcVolume: 46008.51410433634,
      usdVolume: 1247547956.8239481,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '685.1000000000000000',
      high: '748.0900000000000000',
      low: '681.0400000000000000',
      close: '730.4100000000000000',
      volume: '1627123.9175900000000000',
      quoteVolume: 1180415994.5896938,
      btcVolume: 43771.67271543014,
      usdVolume: 1180415994.5896938,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '730.4000000000000000',
      high: '740.7800000000000000',
      low: '689.2000000000000000',
      close: '732.0000000000000000',
      volume: '1106876.3585500000000000',
      quoteVolume: 794643540.4901432,
      btcVolume: 29803.913314660324,
      usdVolume: 794643540.4901432,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '732.0000000000000000',
      high: '758.7400000000000000',
      low: '716.6200000000000000',
      close: '752.1700000000000000',
      volume: '1008573.9629400000000000',
      quoteVolume: 743546576.743025,
      btcVolume: 26409.000752216456,
      usdVolume: 743546576.743025,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '752.1700000000000000',
      high: '755.7400000000000000',
      low: '722.0000000000000000',
      close: '736.4200000000000000',
      volume: '863375.5798100000000000',
      quoteVolume: 638503822.3940647,
      btcVolume: 22178.91912646232,
      usdVolume: 638503822.3940647,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '736.4200000000000000',
      high: '749.0000000000000000',
      low: '714.2900000000000000',
      close: '728.9100000000000000',
      volume: '674648.6773000000000000',
      quoteVolume: 495736382.4405749,
      btcVolume: 16984.616127141537,
      usdVolume: 495736382.4405749,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '728.9100000000000000',
      high: '787.6900000000000000',
      low: '714.9100000000000000',
      close: '774.5600000000000000',
      volume: '1352618.5766800000000000',
      quoteVolume: 1024472182.6120937,
      btcVolume: 32889.04989161244,
      usdVolume: 1024472182.6120937,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '774.4400000000000000',
      high: '1011.0700000000000000',
      low: '768.7100000000000000',
      close: '978.2800000000000000',
      volume: '2813603.8861500000000000',
      quoteVolume: 2522411859.731612,
      btcVolume: 75351.3954511743,
      usdVolume: 2522411859.731612,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '978.3300000000000000',
      high: '1162.9700000000000000',
      low: '890.0000000000000000',
      close: '1041.4300000000000000',
      volume: '4245009.5910400000000000',
      quoteVolume: 4303632891.8297615,
      btcVolume: 135522.0321379406,
      usdVolume: 4303632891.8297615,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '1041.4500000000000000',
      high: '1134.6000000000000000',
      low: '974.4500000000000000',
      close: '1099.5600000000000000',
      volume: '2706995.6752500000000000',
      quoteVolume: 2857050280.008042,
      btcVolume: 88801.0479132276,
      usdVolume: 2857050280.008042,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '1100.1600000000000000',
      high: '1213.0000000000000000',
      low: '1057.2600000000000000',
      close: '1208.4200000000000000',
      volume: '2390134.2561700000000000',
      quoteVolume: 2725259321.0546975,
      btcVolume: 78230.68111796751,
      usdVolume: 2725259321.0546975,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '1208.7500000000000000',
      high: '1289.0000000000000000',
      low: '1131.0000000000000000',
      close: '1224.8700000000000000',
      volume: '1972917.8883400000000000',
      quoteVolume: 2406213991.6994786,
      btcVolume: 63090.38541215102,
      usdVolume: 2406213991.6994786,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '1224.8600000000000000',
      high: '1273.7500000000000000',
      low: '1065.0000000000000000',
      close: '1216.9300000000000000',
      volume: '2130161.3642600000000000',
      quoteVolume: 2542192047.8181143,
      btcVolume: 64132.46310834457,
      usdVolume: 2542192047.8181143,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '1216.7200000000000000',
      high: '1304.3700000000000000',
      low: '1171.3600000000000000',
      close: '1276.0000000000000000',
      volume: '1448871.1151400000000000',
      quoteVolume: 1780137376.416452,
      btcVolume: 44126.55053631966,
      usdVolume: 1780137376.416452,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '1276.0000000000000000',
      high: '1348.3300000000000000',
      low: '1170.0000000000000000',
      close: '1254.2500000000000000',
      volume: '1951179.3500300000000000',
      quoteVolume: 2517791852.4078064,
      btcVolume: 64117.4902690094,
      usdVolume: 2517791852.4078064,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '1254.2400000000000000',
      high: '1260.0000000000000000',
      low: '915.0000000000000000',
      close: '1087.0100000000000000',
      volume: '4054496.9340000000000000',
      quoteVolume: 4277939199.1682744,
      btcVolume: 126094.41321785371,
      usdVolume: 4277939199.1682744,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '1087.0000000000000000',
      high: '1150.0200000000000000',
      low: '1006.3300000000000000',
      close: '1050.1600000000000000',
      volume: '2173756.2488300000000000',
      quoteVolume: 2351659986.680197,
      btcVolume: 67607.46412545761,
      usdVolume: 2351659986.680197,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '1050.3600000000000000',
      high: '1137.6500000000000000',
      low: '987.4800000000000000',
      close: '1129.8900000000000000',
      volume: '1546028.4733400000000000',
      quoteVolume: 1645548180.1396663,
      btcVolume: 47601.494850955794,
      usdVolume: 1645548180.1396663,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '1129.7900000000000000',
      high: '1247.5500000000000000',
      low: '1086.7000000000000000',
      close: '1231.6200000000000000',
      volume: '1555737.3855600000000000',
      quoteVolume: 1825860914.1737947,
      btcVolume: 47277.3001514479,
      usdVolume: 1825860914.1737947,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '1231.8900000000000000',
      high: '1256.9800000000000000',
      low: '1069.4200000000000000',
      close: '1168.5300000000000000',
      volume: '1854933.6970800000000000',
      quoteVolume: 2199247860.325725,
      btcVolume: 59240.27896315071,
      usdVolume: 2199247860.325725,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '1168.4800000000000000',
      high: '1292.6600000000000000',
      low: '1151.7700000000000000',
      close: '1227.8300000000000000',
      volume: '1515434.5202100000000000',
      quoteVolume: 1854150543.260001,
      btcVolume: 50229.12509310558,
      usdVolume: 1854150543.260001,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '1227.8300000000000000',
      high: '1269.0200000000000000',
      low: '1165.0000000000000000',
      close: '1232.4500000000000000',
      volume: '1317285.0057900000000000',
      quoteVolume: 1606424364.4970233,
      btcVolume: 45243.78505901359,
      usdVolume: 1606424364.4970233,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '1232.5000000000000000',
      high: '1260.7500000000000000',
      low: '1182.5800000000000000',
      close: '1258.4500000000000000',
      volume: '998004.1263100000000000',
      quoteVolume: 1223572330.470765,
      btcVolume: 33926.362340998414,
      usdVolume: 1223572330.470765,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '1258.5200000000000000',
      high: '1438.3000000000000000',
      low: '1251.4900000000000000',
      close: '1365.3600000000000000',
      volume: '2277189.7942800000000000',
      quoteVolume: 3118645648.601239,
      btcVolume: 84576.84836448962,
      usdVolume: 3118645648.601239,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '1365.3600000000000000',
      high: '1407.9300000000000000',
      low: '1235.4200000000000000',
      close: '1376.9900000000000000',
      volume: '2217969.4846900000000000',
      quoteVolume: 2930015788.0453196,
      btcVolume: 83805.54654902022,
      usdVolume: 2930015788.0453196,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '1376.7200000000000000',
      high: '1390.0000000000000000',
      low: '1086.0000000000000000',
      close: '1110.9600000000000000',
      volume: '2310208.5729800000000000',
      quoteVolume: 2857288905.5771556,
      btcVolume: 87560.1065502035,
      usdVolume: 2857288905.5771556,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '1111.9800000000000000',
      high: '1273.7900000000000000',
      low: '1042.3100000000000000',
      close: '1232.6200000000000000',
      volume: '2005642.5528900000000000',
      quoteVolume: 2341609368.9179225,
      btcVolume: 74708.67997123068,
      usdVolume: 2341609368.9179225,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '1232.6100000000000000',
      high: '1272.4400000000000000',
      low: '1195.9000000000000000',
      close: '1233.6000000000000000',
      volume: '915290.0468900000000000',
      quoteVolume: 1134112575.401647,
      btcVolume: 35109.53478031294,
      usdVolume: 1134112575.401647,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '1233.4600000000000000',
      high: '1398.8300000000000000',
      low: '1220.0000000000000000',
      close: '1392.5100000000000000',
      volume: '1510879.5381700000000000',
      quoteVolume: 1996765627.5405061,
      btcVolume: 61983.71959418937,
      usdVolume: 1996765627.5405061,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '1392.1900000000000000',
      high: '1475.0000000000000000',
      low: '1311.0200000000000000',
      close: '1347.8800000000000000',
      volume: '1715392.6717800000000000',
      quoteVolume: 2419347040.465905,
      btcVolume: 72644.26574669621,
      usdVolume: 2419347040.465905,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '1289.8900000000000000',
      high: '1374.2200000000000000',
      low: '1288.0000000000000000',
      close: '1365.9700000000000000',
      volume: '474907.7533300000000000',
      quoteVolume: 634395015.3148867,
      btcVolume: 19710.204623958827,
      usdVolume: 634395015.3148867,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '1366.5400000000000000',
      high: '1373.9000000000000000',
      low: '1207.0000000000000000',
      close: '1239.0300000000000000',
      volume: '1913340.1664600000000000',
      quoteVolume: 2432623351.210675,
      btcVolume: 78753.05627142359,
      usdVolume: 2432623351.210675,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '1238.9300000000000000',
      high: '1360.0000000000000000',
      low: '1217.2300000000000000',
      close: '1329.6900000000000000',
      volume: '1285582.1498600000000000',
      quoteVolume: 1685996087.100964,
      btcVolume: 53145.032890713526,
      usdVolume: 1685996087.100964,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '1329.4400000000000000',
      high: '1436.9000000000000000',
      low: '1285.3500000000000000',
      close: '1379.5000000000000000',
      volume: '2111692.2313000000000000',
      quoteVolume: 2883767792.77202,
      btcVolume: 81514.055974949,
      usdVolume: 2883767792.77202,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '1379.5600000000000000',
      high: '1406.0000000000000000',
      low: '1326.4100000000000000',
      close: '1378.0500000000000000',
      volume: '1017334.1650900000000000',
      quoteVolume: 1383293203.399458,
      btcVolume: 40669.810913327645,
      usdVolume: 1383293203.399458,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '1378.0400000000000000',
      high: '1380.7400000000000000',
      low: '1281.1200000000000000',
      close: '1312.5500000000000000',
      volume: '913951.9568300000000000',
      quoteVolume: 1217085727.1929095,
      btcVolume: 36537.581286708024,
      usdVolume: 1217085727.1929095,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '1312.4500000000000000',
      high: '1377.9900000000000000',
      low: '1269.9900000000000000',
      close: '1374.1700000000000000',
      volume: '1080698.2129100000000000',
      quoteVolume: 1429023779.126755,
      btcVolume: 42479.33622274833,
      usdVolume: 1429023779.126755,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '1374.0900000000000000',
      high: '1547.9000000000000000',
      low: '1360.2800000000000000',
      close: '1511.8700000000000000',
      volume: '1943744.1966000000000000',
      quoteVolume: 2820899589.0203815,
      btcVolume: 81013.11891040324,
      usdVolume: 2820899589.0203815,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '1511.9300000000000000',
      high: '1669.3500000000000000',
      low: '1508.3900000000000000',
      close: '1664.4900000000000000',
      volume: '1535869.8880100000000000',
      quoteVolume: 2418887041.5875654,
      btcVolume: 66156.27367384409,
      usdVolume: 2418887041.5875654,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '1664.6700000000000000',
      high: '1695.2000000000000000',
      low: '1554.4400000000000000',
      close: '1595.6700000000000000',
      volume: '1646502.0866300000000000',
      quoteVolume: 2693099202.271006,
      btcVolume: 72046.17314645574,
      usdVolume: 2693099202.271006,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '1595.5300000000000000',
      high: '1760.4900000000000000',
      low: '1591.0000000000000000',
      close: '1719.1900000000000000',
      volume: '1193485.8324200000000000',
      quoteVolume: 2010622894.1443644,
      btcVolume: 53367.070155870046,
      usdVolume: 2010622894.1443644,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '1718.9500000000000000',
      high: '1743.6700000000000000',
      low: '1642.9400000000000000',
      close: '1676.3500000000000000',
      volume: '1149726.0674300000000000',
      quoteVolume: 1939917120.7056394,
      btcVolume: 48912.4046267318,
      usdVolume: 1939917120.7056394,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '1676.3500000000000000',
      high: '1693.0000000000000000',
      low: '1490.4600000000000000',
      close: '1611.6200000000000000',
      volume: '1281734.8681400000000000',
      quoteVolume: 2044371589.0232632,
      btcVolume: 53081.3239508303,
      usdVolume: 2044371589.0232632,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '1611.4600000000000000',
      high: '1775.5700000000000000',
      low: '1563.8900000000000000',
      close: '1750.1500000000000000',
      volume: '1339777.0953600000000000',
      quoteVolume: 2255426926.0200105,
      btcVolume: 53848.27559053579,
      usdVolume: 2255426926.0200105,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '1749.6800000000000000',
      high: '1821.8800000000000000',
      low: '1708.4900000000000000',
      close: '1769.1300000000000000',
      volume: '1185071.8959300000000000',
      quoteVolume: 2080818891.6989098,
      btcVolume: 44595.047468861056,
      usdVolume: 2080818891.6989098,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '1769.1400000000000000',
      high: '1837.8600000000000000',
      low: '1680.0000000000000000',
      close: '1740.7800000000000000',
      volume: '1219932.2547400000000000',
      quoteVolume: 2149076144.4077,
      btcVolume: 47044.18686396017,
      usdVolume: 2148911012.185441,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '1740.7600000000000000',
      high: '1819.0000000000000000',
      low: '0.0000163270000000',
      close: '1785.6200000000000000',
      volume: '734621.0468700000000000',
      quoteVolume: 1300810400.6745741,
      btcVolume: 27978.80513237656,
      usdVolume: 1300810421.239668,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '1785.8300000000000000',
      high: '1864.8800000000000000',
      low: '1739.8800000000000000',
      close: '1840.2600000000000000',
      volume: '909354.4636700000000000',
      quoteVolume: 1634996984.4396915,
      btcVolume: 34411.479778793124,
      usdVolume: 1634776311.9280682,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '1840.2400000000000000',
      high: '1871.7200000000000000',
      low: '1765.0000000000000000',
      close: '1814.9600000000000000',
      volume: '767588.7814400000000000',
      quoteVolume: 1395593560.9624188,
      btcVolume: 29584.45599424286,
      usdVolume: 1395437080.521789,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '1815.2500000000000000',
      high: '1850.0000000000000000',
      low: '1784.1100000000000000',
      close: '1800.4200000000000000',
      volume: '653541.2701500000000000',
      quoteVolume: 1190593797.9381444,
      btcVolume: 24535.71966972843,
      usdVolume: 1190593797.9381444,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '1800.0300000000000000',
      high: '1835.7200000000000000',
      low: '1655.6700000000000000',
      close: '1779.2500000000000000',
      volume: '1049314.2533700000000000',
      quoteVolume: 1852907004.6686351,
      btcVolume: 38765.91600274143,
      usdVolume: 1846625373.4324832,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '1779.3300000000000000',
      high: '1827.2800000000000000',
      low: '1724.0000000000000000',
      close: '1781.9900000000000000',
      volume: '816519.6687500000000000',
      quoteVolume: 1454153395.7932453,
      btcVolume: 29364.208274199304,
      usdVolume: 1434518186.7829216,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '1781.9900000000000000',
      high: '1855.0000000000000000',
      low: '1733.0100000000000000',
      close: '1849.8800000000000000',
      volume: '784619.1244400000000000',
      quoteVolume: 1417932396.1485229,
      btcVolume: 27781.649794336336,
      usdVolume: 1417932396.1485229,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '1849.7000000000000000',
      high: '1950.5000000000000000',
      low: '1849.6900000000000000',
      close: '1939.6100000000000000',
      volume: '937227.1019100000000000',
      quoteVolume: 1787383532.1023593,
      btcVolume: 34457.66137137417,
      usdVolume: 1787383532.1023593,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '1938.9100000000000000',
      high: '1972.8200000000000000',
      low: '1891.0000000000000000',
      close: '1955.0000000000000000',
      volume: '793166.6203700000000000',
      quoteVolume: 1533528111.7198534,
      btcVolume: 28954.450074244185,
      usdVolume: 1533528111.7198534,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '1955.4600000000000000',
      high: '2042.3400000000000000',
      low: '1817.4000000000000000',
      close: '1913.0000000000000000',
      volume: '1231380.2653200000000000',
      quoteVolume: 2438742761.044716,
      btcVolume: 43461.22491246883,
      usdVolume: 2438737210.8991766,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '1912.9000000000000000',
      high: '1976.3100000000000000',
      low: '1883.0100000000000000',
      close: '1933.5300000000000000',
      volume: '756568.1974300000000000',
      quoteVolume: 1467970937.285071,
      btcVolume: 25788.46682354838,
      usdVolume: 1467970937.285071,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '1933.5400000000000000',
      high: '1936.2900000000000000',
      low: '1555.0000000000000000',
      close: '1777.5800000000000000',
      volume: '1544855.9559000000000000',
      quoteVolume: 2744964206.143231,
      btcVolume: 48368.6255478767,
      usdVolume: 2601427551.846227,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '1777.4200000000000000',
      high: '1781.9900000000000000',
      low: '1371.6000000000000000',
      close: '1577.8900000000000000',
      volume: '2349675.3107900000000000',
      quoteVolume: 3640290942.4959636,
      btcVolume: 74416.81196595635,
      usdVolume: 3622396228.338403,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '1577.7900000000000000',
      high: '1712.7000000000000000',
      low: '1500.2000000000000000',
      close: '1623.3900000000000000',
      volume: '998796.1130000000000000',
      quoteVolume: 1631162134.0796974,
      btcVolume: 32698.532812710335,
      usdVolume: 1631047215.6908228,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '1623.1600000000000000',
      high: '1671.5900000000000000',
      low: '1459.1600000000000000',
      close: '1481.7500000000000000',
      volume: '1032670.7940900000000000',
      quoteVolume: 1650582276.8718207,
      btcVolume: 33117.338134786136,
      usdVolume: 1650582276.8718207,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '1482.0800000000000000',
      high: '1562.2500000000000000',
      low: '1400.0000000000000000',
      close: '1444.5900000000000000',
      volume: '1355973.2937800000000000',
      quoteVolume: 2004016547.1112273,
      btcVolume: 43035.34655306483,
      usdVolume: 2003245444.1310456,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '1444.5600000000000000',
      high: '1529.0000000000000000',
      low: '1425.0000000000000000',
      close: '1458.1300000000000000',
      volume: '927423.0475000000000000',
      quoteVolume: 1375946816.3325465,
      btcVolume: 29233.007119435944,
      usdVolume: 1375946816.3325465,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '1458.2700000000000000',
      high: '1468.7500000000000000',
      low: '1293.1800000000000000',
      close: '1419.1800000000000000',
      volume: '1394039.1589300000000000',
      quoteVolume: 1906652845.303296,
      btcVolume: 42805.29027654558,
      usdVolume: 1906652845.303296,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '1418.6700000000000000',
      high: '1572.0000000000000000',
      low: '1409.9100000000000000',
      close: '1569.8600000000000000',
      volume: '1071988.8959200000000000',
      quoteVolume: 1609708358.4012525,
      btcVolume: 33760.292992242015,
      usdVolume: 1609708358.4012525,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '1570.0000000000000000',
      high: '1602.9000000000000000',
      low: '1455.5200000000000000',
      close: '1486.7800000000000000',
      volume: '1061142.3874300000000000',
      quoteVolume: 1627312282.6235447,
      btcVolume: 33587.44590463653,
      usdVolume: 1627312282.6235447,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '1486.7900000000000000',
      high: '1656.9400000000000000',
      low: '1475.4700000000000000',
      close: '1567.6900000000000000',
      volume: '1054045.4787500000000000',
      quoteVolume: 1674981197.4400375,
      btcVolume: 33038.92359702907,
      usdVolume: 1674981197.4400375,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '1567.6800000000000000',
      high: '1624.8700000000000000',
      low: '1506.3600000000000000',
      close: '1539.2300000000000000',
      volume: '946365.6708800000000000',
      quoteVolume: 1476463517.9864302,
      btcVolume: 29907.939970665844,
      usdVolume: 1476144246.8024573,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '1539.2200000000000000',
      high: '1548.9600000000000000',
      low: '1441.6600000000000000',
      close: '1528.3100000000000000',
      volume: '910786.2897600000000000',
      quoteVolume: 1355798089.2726953,
      btcVolume: 28468.307956482684,
      usdVolume: 1355798089.2726953,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '1528.0800000000000000',
      high: '1671.5800000000000000',
      low: '1513.0300000000000000',
      close: '1650.3500000000000000',
      volume: '990313.5575500000000000',
      quoteVolume: 1575968246.2858746,
      btcVolume: 32484.06849446943,
      usdVolume: 1569880633.498245,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '1650.6800000000000000',
      high: '1734.1600000000000000',
      low: '1630.3500000000000000',
      close: '1726.1600000000000000',
      volume: '848470.7813600000000000',
      quoteVolume: 1414988218.19356,
      btcVolume: 28184.765635712247,
      usdVolume: 1414988218.19356,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '1726.4600000000000000',
      high: '1843.5900000000000000',
      low: '1664.9600000000000000',
      close: '1833.1800000000000000',
      volume: '1134793.9935500000000000',
      quoteVolume: 1968559430.9578898,
      btcVolume: 38787.24460770227,
      usdVolume: 1968559430.9578898,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '1833.2200000000000000',
      high: '1870.7700000000000000',
      low: '1798.2000000000000000',
      close: '1870.5100000000000000',
      volume: '813029.3448600000000000',
      quoteVolume: 1489269737.9822018,
      btcVolume: 27646.61354923279,
      usdVolume: 1489269737.9822018,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '1870.5000000000000000',
      high: '1877.6900000000000000',
      low: '1757.0000000000000000',
      close: '1795.1200000000000000',
      volume: '901824.9905900000000000',
      quoteVolume: 1641659203.449609,
      btcVolume: 29670.607882495333,
      usdVolume: 1641510306.9788375,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '1795.2800000000000000',
      high: '1846.7000000000000000',
      low: '1725.0000000000000000',
      close: '1825.7800000000000000',
      volume: '909011.3136100000000000',
      quoteVolume: 1627027140.3418167,
      btcVolume: 28930.654359520144,
      usdVolume: 1627027140.3418167,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '1825.7800000000000000',
      high: '1841.8800000000000000',
      low: '1719.7200000000000000',
      close: '1771.4800000000000000',
      volume: '840060.3990300000000000',
      quoteVolume: 1490608510.5170076,
      btcVolume: 19454.286038851555,
      usdVolume: 1105911678.621963,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '1759.7200000000000000',
      high: '1944.3000000000000000',
      low: '1727.7500000000000000',
      close: '1922.3500000000000000',
      volume: '921478.4547800000000000',
      quoteVolume: 1710098953.5524776,
      btcVolume: 28839.313150162354,
      usdVolume: 1710098953.5524776,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '1922.3500000000000000',
      high: '1933.1000000000000000',
      low: '1836.0000000000000000',
      close: '1848.1000000000000000',
      volume: '637140.4717400000000000',
      quoteVolume: 1198818913.9901521,
      btcVolume: 19816.886952228757,
      usdVolume: 1197320013.2192478,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '1848.1000000000000000',
      high: '1891.7600000000000000',
      low: '1730.0000000000000000',
      close: '1793.4800000000000000',
      volume: '1025326.7965800000000000',
      quoteVolume: 1842608872.7630134,
      btcVolume: 32173.17842836311,
      usdVolume: 1842608872.7630134,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '1793.9900000000000000',
      high: '1819.5000000000000000',
      low: '1711.6800000000000000',
      close: '1805.1200000000000000',
      volume: '745047.8840800000000000',
      quoteVolume: 1324386049.5584662,
      btcVolume: 23990.615019311717,
      usdVolume: 1323585091.7132356,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '1805.0100000000000000',
      high: '1838.8800000000000000',
      low: '1741.1800000000000000',
      close: '1823.5800000000000000',
      volume: '654844.3962200000000000',
      quoteVolume: 1171393579.333251,
      btcVolume: 20836.313439670008,
      usdVolume: 1171393579.333251,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '1823.5800000000000000',
      high: '1850.0000000000000000',
      low: '1759.0400000000000000',
      close: '1776.0800000000000000',
      volume: '603721.6105600000000000',
      quoteVolume: 1092346826.5537026,
      btcVolume: 18665.018047687696,
      usdVolume: 1092344736.4815433,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '1775.9200000000000000',
      high: '1840.6100000000000000',
      low: '1733.4700000000000000',
      close: '1809.1000000000000000',
      volume: '485394.8323500000000000',
      quoteVolume: 875146682.91855,
      btcVolume: 14962.1323122463,
      usdVolume: 871822690.956532,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '1809.0100000000000000',
      high: '1868.8300000000000000',
      low: '1800.8600000000000000',
      close: '1806.1000000000000000',
      volume: '423872.9248500000000000',
      quoteVolume: 777603559.92763,
      btcVolume: 13225.478131358275,
      usdVolume: 777585802.0887206,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '1806.5700000000000000',
      high: '1817.1600000000000000',
      low: '1747.0000000000000000',
      close: '1782.9800000000000000',
      volume: '465300.7587400000000000',
      quoteVolume: 829784481.8129778,
      btcVolume: 14483.824726332654,
      usdVolume: 828756040.3627473,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '1783.2300000000000000',
      high: '1807.5100000000000000',
      low: '1655.1500000000000000',
      close: '1680.9700000000000000',
      volume: '582614.8017100000000000',
      quoteVolume: 1019579063.1709024,
      btcVolume: 18102.374296305938,
      usdVolume: 1019512873.3647406,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '1681.1700000000000000',
      high: '1721.1700000000000000',
      low: '1650.0000000000000000',
      close: '1668.0800000000000000',
      volume: '574333.9664000000000000',
      quoteVolume: 970709518.4563361,
      btcVolume: 12089.460579743516,
      usdVolume: 658329851.7034984,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '1668.2000000000000000',
      high: '1740.8600000000000000',
      low: '1536.9200000000000000',
      close: '1581.8400000000000000',
      volume: '712606.6818800000000000',
      quoteVolume: 1184354983.115622,
      btcVolume: 21604.55412650087,
      usdVolume: 1184354983.115622,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '1581.8800000000000000',
      high: '1622.0000000000000000',
      low: '1549.4000000000000000',
      close: '1585.7400000000000000',
      volume: '637200.0501600000000000',
      quoteVolume: 1012804696.3799217,
      btcVolume: 17272.44309048303,
      usdVolume: 897465027.6504521,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '1585.8300000000000000',
      high: '1699.9300000000000000',
      low: '1585.7900000000000000',
      close: '1698.8400000000000000',
      volume: '442973.6271000000000000',
      quoteVolume: 727250279.6639122,
      btcVolume: 13128.883505516924,
      usdVolume: 700660186.6217037,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '1698.8400000000000000',
      high: '1732.6700000000000000',
      low: '1666.4100000000000000',
      close: '1712.6300000000000000',
      volume: '443845.4995700000000000',
      quoteVolume: 753555521.6446587,
      btcVolume: 13655.180403119155,
      usdVolume: 753442291.0866222,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '1712.5200000000000000',
      high: '1725.6600000000000000',
      low: '1661.4000000000000000',
      close: '1687.0100000000000000',
      volume: '348895.5131400000000000',
      quoteVolume: 592836644.5993483,
      btcVolume: 10623.566952815665,
      usdVolume: 592744065.8884122,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '1687.1600000000000000',
      high: '1841.0000000000000000',
      low: '1677.8500000000000000',
      close: '1816.7400000000000000',
      volume: '645153.9473800000000000',
      quoteVolume: 1143708814.718205,
      btcVolume: 19952.416250138947,
      usdVolume: 1143593696.951361,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '1816.7600000000000000',
      high: '1859.1300000000000000',
      low: '1786.3000000000000000',
      close: '1840.4600000000000000',
      volume: '568842.0052600000000000',
      quoteVolume: 1039262033.3867666,
      btcVolume: 17794.857969149907,
      usdVolume: 1039257506.3019704,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '1840.4100000000000000',
      high: '1947.2900000000000000',
      low: '1768.1500000000000000',
      close: '1919.3700000000000000',
      volume: '746995.8591000000000000',
      quoteVolume: 1387448329.8618498,
      btcVolume: 23597.31023417654,
      usdVolume: 1385699357.8138206,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '1919.3700000000000000',
      high: '1984.7700000000000000',
      low: '1885.0200000000000000',
      close: '1968.0500000000000000',
      volume: '735243.4803000000000000',
      quoteVolume: 1426255182.7403693,
      btcVolume: 24205.415566070777,
      usdVolume: 1424192936.6523898,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '1968.0500000000000000',
      high: '2146.0000000000000000',
      low: '1948.4800000000000000',
      close: '2133.6900000000000000',
      volume: '836004.3995600000000000',
      quoteVolume: 1699509331.9186137,
      btcVolume: 28682.7732154343,
      usdVolume: 1699416511.4975188,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '2133.6900000000000000',
      high: '2138.1200000000000000',
      low: '1996.3000000000000000',
      close: '2008.5200000000000000',
      volume: '770009.0373200000000000',
      quoteVolume: 1601162397.6543598,
      btcVolume: 14889.771267495946,
      usdVolume: 877202202.2152315,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '2008.5900000000000000',
      high: '2091.8300000000000000',
      low: '1975.2400000000000000',
      close: '2075.6900000000000000',
      volume: '504446.3045400000000000',
      quoteVolume: 1030198911.5271093,
      btcVolume: 14552.052881946049,
      usdVolume: 839965464.8217052,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '2075.7000000000000000',
      high: '2130.6400000000000000',
      low: '2002.1500000000000000',
      close: '2107.3800000000000000',
      volume: '672715.8405800000000000',
      quoteVolume: 1393443640.6387079,
      btcVolume: 19837.173273893703,
      usdVolume: 1151438176.7157285,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '2107.4300000000000000',
      high: '2151.0000000000000000',
      low: '2044.6500000000000000',
      close: '2112.2100000000000000',
      volume: '646617.8868200000000000',
      quoteVolume: 1364425311.2743363,
      btcVolume: 21462.268704397782,
      usdVolume: 1253287568.3909323,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '2112.2200000000000000',
      high: '2129.0100000000000000',
      low: '1930.0100000000000000',
      close: '1963.4700000000000000',
      volume: '890590.5480600000000000',
      quoteVolume: 1793821185.7585125,
      btcVolume: 31085.880944942655,
      usdVolume: 1765504353.4110937,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '1963.4600000000000000',
      high: '2084.6100000000000000',
      low: '1947.3500000000000000',
      close: '2080.4600000000000000',
      volume: '492449.6518300000000000',
      quoteVolume: 998031729.5983349,
      btcVolume: 11760.316400533313,
      usdVolume: 671261695.401949,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '2080.3900000000000000',
      high: '2100.0000000000000000',
      low: '2047.5700000000000000',
      close: '2066.8700000000000000',
      volume: '410697.4165100000000000',
      quoteVolume: 852604897.8719946,
      btcVolume: 12983.009883018114,
      usdVolume: 756441273.5357431,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '2067.1900000000000000',
      high: '2200.0000000000000000',
      low: '2058.0000000000000000',
      close: '2133.4900000000000000',
      volume: '655458.2089400000000000',
      quoteVolume: 1405364881.858304,
      btcVolume: 23111.04520438008,
      usdVolume: 1384757109.84192,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '2133.4900000000000000',
      high: '2165.0200000000000000',
      low: '2113.4000000000000000',
      close: '2151.3600000000000000',
      volume: '392265.5077300000000000',
      quoteVolume: 839653140.9406079,
      btcVolume: 13957.221461420964,
      usdVolume: 834685291.3442482,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '2151.4200000000000000',
      high: '2203.4600000000000000',
      low: '2103.0500000000000000',
      close: '2137.6900000000000000',
      volume: '569303.1026900000000000',
      quoteVolume: 1224143619.588617,
      btcVolume: 16124.45725574653,
      usdVolume: 970101865.6152618,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '2137.8600000000000000',
      high: '2318.0000000000000000',
      low: '2135.5300000000000000',
      close: '2299.1900000000000000',
      volume: '753630.4481400000000000',
      quoteVolume: 1682124954.2545862,
      btcVolume: 26651.164261948637,
      usdVolume: 1663537493.65112,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '2299.1900000000000000',
      high: '2447.2900000000000000',
      low: '2281.3300000000000000',
      close: '2432.6000000000000000',
      volume: '962367.1999900000000000',
      quoteVolume: 2270110171.976935,
      btcVolume: 31771.533630725116,
      usdVolume: 2017446256.9322753,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '2432.5900000000000000',
      high: '2543.4700000000000000',
      low: '2400.0000000000000000',
      close: '2514.0400000000000000',
      volume: '681946.7520600000000000',
      quoteVolume: 1680052982.425424,
      btcVolume: 24649.296881737133,
      usdVolume: 1551057612.4665124,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '2514.0400000000000000',
      high: '2548.2900000000000000',
      low: '2300.0000000000000000',
      close: '2422.6700000000000000',
      volume: '999163.1245600000000000',
      quoteVolume: 2418565017.4451094,
      btcVolume: 37191.04906644725,
      usdVolume: 2288259291.5148406,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '2422.3800000000000000',
      high: '2495.0000000000000000',
      low: '2310.0000000000000000',
      close: '2317.6000000000000000',
      volume: '624232.2556800000000000',
      quoteVolume: 1504895144.9122174,
      btcVolume: 16884.468899235042,
      usdVolume: 1031965089.1388259,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '2317.6500000000000000',
      high: '2340.0000000000000000',
      low: '1950.0000000000000000',
      close: '2235.6400000000000000',
      volume: '1322276.0831600000000000',
      quoteVolume: 2866812093.0214953,
      btcVolume: 50861.605911477796,
      usdVolume: 2839032150.7239733,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '2235.6500000000000000',
      high: '2300.0000000000000000',
      low: '2080.0000000000000000',
      close: '2161.1200000000000000',
      volume: '820592.2655500000000000',
      quoteVolume: 1800575928.1074977,
      btcVolume: 26365.040134011742,
      usdVolume: 1481688446.6256678,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '2161.1200000000000000',
      high: '2346.6200000000000000',
      low: '2055.0000000000000000',
      close: '2330.0300000000000000',
      volume: '990682.9157700000000000',
      quoteVolume: 2177949495.5727634,
      btcVolume: 37918.97019892185,
      usdVolume: 2107334113.285447,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '2330.2600000000000000',
      high: '2468.0000000000000000',
      low: '2235.1600000000000000',
      close: '2357.0600000000000000',
      volume: '1113465.3921000000000000',
      quoteVolume: 2619975710.2322025,
      btcVolume: 40857.67918389536,
      usdVolume: 2269210586.199889,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '2357.0600000000000000',
      high: '2644.4700000000000000',
      low: '2303.4400000000000000',
      close: '2397.9400000000000000',
      volume: '1882176.3625200000000000',
      quoteVolume: 4670246227.172266,
      btcVolume: 25414.11693159991,
      usdVolume: 1354009041.8840244,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '2397.9400000000000000',
      high: '2442.8900000000000000',
      low: '2107.0000000000000000',
      close: '2367.3900000000000000',
      volume: '2392663.5084700000000000',
      quoteVolume: 5410016346.711445,
      btcVolume: 57263.218105779786,
      usdVolume: 2852064612.713971,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '2367.3900000000000000',
      high: '2368.7400000000000000',
      low: '2154.8000000000000000',
      close: '2213.3500000000000000',
      volume: '990513.3389000000000000',
      quoteVolume: 2230697502.0572195,
      btcVolume: 42345.682650564064,
      usdVolume: 2111057044.5906014,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '2214.0800000000000000',
      high: '2356.6400000000000000',
      low: '2168.0000000000000000',
      close: '2320.4900000000000000',
      volume: '817845.6182200000000000',
      quoteVolume: 1860725124.3291864,
      btcVolume: 20853.64183965011,
      usdVolume: 1033254138.8731536,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '2320.4400000000000000',
      high: '2541.8700000000000000',
      low: '2303.6400000000000000',
      close: '2532.6000000000000000',
      volume: '940704.4956200000000000',
      quoteVolume: 2314456522.3843803,
      btcVolume: 26103.489465891733,
      usdVolume: 1376208037.313604,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '2532.0600000000000000',
      high: '2680.0000000000000000',
      low: '2480.2900000000000000',
      close: '2666.1300000000000000',
      volume: '771728.4587600000000000',
      quoteVolume: 1995139009.247419,
      btcVolume: 36127.94470152542,
      usdVolume: 1973577408.7839944,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '2666.1300000000000000',
      high: '2760.0000000000000000',
      low: '2559.4400000000000000',
      close: '2748.2400000000000000',
      volume: '999383.1852000000000000',
      quoteVolume: 2673547154.2009354,
      btcVolume: 48837.864468014675,
      usdVolume: 2673547154.2009354,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '2748.2300000000000000',
      high: '2798.0000000000000000',
      low: '2668.0000000000000000',
      close: '2757.0400000000000000',
      volume: '859315.6791200000000000',
      quoteVolume: 2353486577.8108225,
      btcVolume: 43742.4160025124,
      usdVolume: 2353486577.8108225,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '2757.0400000000000000',
      high: '2798.3000000000000000',
      low: '2723.0900000000000000',
      close: '2772.4200000000000000',
      volume: '683253.7843800000000000',
      quoteVolume: 1883721206.1899638,
      btcVolume: 22122.659703772588,
      usdVolume: 1221913689.666882,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '2772.4200000000000000',
      high: '2954.2800000000000000',
      low: '2743.4100000000000000',
      close: '2944.7700000000000000',
      volume: '690260.0774100000000000',
      quoteVolume: 1979833188.065232,
      btcVolume: 16327.850363768606,
      usdVolume: 944162910.1777735,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '2944.8300000000000000',
      high: '2985.0000000000000000',
      low: '2852.2600000000000000',
      close: '2949.5600000000000000',
      volume: '571953.7712900000000000',
      quoteVolume: 1672227924.3766143,
      btcVolume: 19320.10153700959,
      usdVolume: 1096231381.3174038,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '2949.3300000000000000',
      high: '3454.0000000000000000',
      low: '2949.3200000000000000',
      close: '3431.0400000000000000',
      volume: '1329083.1120400000000000',
      quoteVolume: 4243771371.0916457,
      btcVolume: 71145.21913947008,
      usdVolume: 4116214448.7382607,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '3431.0400000000000000',
      high: '3527.0000000000000000',
      low: '3160.0000000000000000',
      close: '3239.0100000000000000',
      volume: '1797928.4935400000000000',
      quoteVolume: 6010359311.950286,
      btcVolume: 85671.24707619315,
      usdVolume: 4746803613.875628,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '3239.0100000000000000',
      high: '3549.3400000000000000',
      low: '3200.2300000000000000',
      close: '3524.2600000000000000',
      volume: '1094521.4393500000000000',
      quoteVolume: 3683966384.3414955,
      btcVolume: 60862.394706312116,
      usdVolume: 3399403885.2271895,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '3524.3300000000000000',
      high: '3605.4000000000000000',
      low: '3376.4700000000000000',
      close: '3489.7300000000000000',
      volume: '907396.3895700000000000',
      quoteVolume: 3167529015.808255,
      btcVolume: 54859.42355950186,
      usdVolume: 3118998835.779845,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '3489.7400000000000000',
      high: '3587.0000000000000000',
      low: '3353.2300000000000000',
      close: '3479.5300000000000000',
      volume: '760544.3177800000000000',
      quoteVolume: 2641739520.816758,
      btcVolume: 45671.46922983503,
      usdVolume: 2590518418.0204196,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '3479.7900000000000000',
      high: '3958.3300000000000000',
      low: '3449.2700000000000000',
      close: '3910.2600000000000000',
      volume: '1130467.5485200000000000',
      quoteVolume: 4170897763.922241,
      btcVolume: 70835.00620283352,
      usdVolume: 4147360439.8575544,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '3910.2600000000000000',
      high: '3983.6500000000000000',
      low: '3726.0800000000000000',
      close: '3923.9500000000000000',
      volume: '1000399.7612200000000000',
      quoteVolume: 3885034129.3254085,
      btcVolume: 66054.86727804251,
      usdVolume: 3823738678.629524,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '3923.9500000000000000',
      high: '4208.0000000000000000',
      low: '3693.4700000000000000',
      close: '3945.8800000000000000',
      volume: '1330445.0332100000000000',
      quoteVolume: 5397868145.894449,
      btcVolume: 66445.5379158468,
      usdVolume: 3801853644.3229055,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '3946.6300000000000000',
      high: '4180.2200000000000000',
      low: '3771.6400000000000000',
      close: '4173.4500000000000000',
      volume: '1071659.7833600000000000',
      quoteVolume: 4255907552.0657372,
      btcVolume: 59163.452670007915,
      usdVolume: 3302134688.3033047,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '4173.4600000000000000',
      high: '4372.7200000000000000',
      low: '3701.5800000000000000',
      close: '3887.7100000000000000',
      volume: '1589824.9292600000000000',
      quoteVolume: 6626411118.25824,
      btcVolume: 118376.00765287268,
      usdVolume: 6523763033.013671,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '3810.1500000000000000',
      high: '4034.7700000000000000',
      low: '3541.5100000000000000',
      close: '3717.9100000000000000',
      volume: '1849973.9261200000000000',
      quoteVolume: 7017701529.470531,
      btcVolume: 137733.97130057015,
      usdVolume: 6840474825.010459,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '3717.9200000000000000',
      high: '4173.2800000000000000',
      low: '3691.3500000000000000',
      close: '4075.0100000000000000',
      volume: '1152899.9027000000000000',
      quoteVolume: 4567552216.6355095,
      btcVolume: 88199.46408681228,
      usdVolume: 4430311894.504898,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '4075.0200000000000000',
      high: '4134.0500000000000000',
      low: '3632.0000000000000000',
      close: '3646.6800000000000000',
      volume: '1039174.3467800000000000',
      quoteVolume: 4030584420.9662867,
      btcVolume: 81888.4612645759,
      usdVolume: 3986942174.04559,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '3646.2400000000000000',
      high: '3879.0000000000000000',
      low: '3343.0600000000000000',
      close: '3582.1000000000000000',
      volume: '1322422.7023000000000000',
      quoteVolume: 4783072987.0008545,
      btcVolume: 99450.38052256861,
      usdVolume: 4678658584.545799,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '3581.8100000000000000',
      high: '3587.0600000000000000',
      low: '3102.0000000000000000',
      close: '3282.5100000000000000',
      volume: '1896852.2045000000000000',
      quoteVolume: 6376399013.426107,
      btcVolume: 141476.57089151486,
      usdVolume: 6236699464.627672,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '3282.2500000000000000',
      high: '3565.7100000000000000',
      low: '3240.0000000000000000',
      close: '3375.0700000000000000',
      volume: '1287105.1300500000000000',
      quoteVolume: 4392855117.159339,
      btcVolume: 98470.05761413471,
      usdVolume: 4341814936.084908,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '3375.0800000000000000',
      high: '3441.7300000000000000',
      low: '1900.0000000000000000',
      close: '2438.9200000000000000',
      volume: '2822906.7017300000000000',
      quoteVolume: 7943579809.10751,
      btcVolume: 169657.88253061575,
      usdVolume: 6636093341.830318,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '2438.9200000000000000',
      high: '2990.0500000000000000',
      low: '2155.0000000000000000',
      close: '2768.6200000000000000',
      volume: '2937686.1189100000000000',
      quoteVolume: 7738734836.44043,
      btcVolume: 162815.48666917285,
      usdVolume: 6411033639.910326,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '2767.8700000000000000',
      high: '2938.5400000000000000',
      low: '2101.0000000000000000',
      close: '2430.9800000000000000',
      volume: '2224854.1226900000000000',
      quoteVolume: 5663309143.626692,
      btcVolume: 119218.8275934102,
      usdVolume: 4583070212.396227,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '2430.9800000000000000',
      high: '2485.0000000000000000',
      low: '2145.0000000000000000',
      close: '2294.8700000000000000',
      volume: '1682579.1813100000000000',
      quoteVolume: 3930453187.1191373,
      btcVolume: 71381.46907218514,
      usdVolume: 2649594194.693011,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '2295.4500000000000000',
      high: '2381.5700000000000000',
      low: '1728.7400000000000000',
      close: '2096.3900000000000000',
      volume: '2914548.7312500000000000',
      quoteVolume: 5907260139.545083,
      btcVolume: 169607.22742003587,
      usdVolume: 5818960197.408998,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '2096.6300000000000000',
      high: '2675.0000000000000000',
      low: '2079.9400000000000000',
      close: '2648.4300000000000000',
      volume: '2184824.1622800000000000',
      quoteVolume: 5176143519.684482,
      btcVolume: 136880.6376374799,
      usdVolume: 5087206518.533662,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '2647.9200000000000000',
      high: '2750.0000000000000000',
      low: '2378.5400000000000000',
      close: '2705.1100000000000000',
      volume: '2115349.5044500000000000',
      quoteVolume: 5442737549.401353,
      btcVolume: 141033.426253863,
      usdVolume: 5351032818.276227,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '2705.1000000000000000',
      high: '2910.0000000000000000',
      low: '2643.0000000000000000',
      close: '2884.9400000000000000',
      volume: '1562515.8153800000000000',
      quoteVolume: 4356014953.228169,
      btcVolume: 109277.35501313985,
      usdVolume: 4286403436.2894278,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '2884.8200000000000000',
      high: '2889.7400000000000000',
      low: '2633.6800000000000000',
      close: '2742.2300000000000000',
      volume: '1099029.9120600000000000',
      quoteVolume: 3033719144.3402476,
      btcVolume: 77944.20663975782,
      usdVolume: 3015457564.549178,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '2742.2300000000000000',
      high: '2762.4500000000000000',
      low: '2327.3800000000000000',
      close: '2412.1000000000000000',
      volume: '1554584.6136200000000000',
      quoteVolume: 3934662309.0444574,
      btcVolume: 106412.09487045105,
      usdVolume: 3873392067.229942,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '2412.3400000000000000',
      high: '2574.2700000000000000',
      low: '2204.1000000000000000',
      close: '2278.7400000000000000',
      volume: '1379427.1885100000000000',
      quoteVolume: 3288804154.8317804,
      btcVolume: 92759.48942970896,
      usdVolume: 3255889564.014722,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '2278.7400000000000000',
      high: '2476.4200000000000000',
      low: '2180.5300000000000000',
      close: '2385.9800000000000000',
      volume: '1151113.1988300000000000',
      quoteVolume: 2726606138.6317267,
      btcVolume: 76968.91849705382,
      usdVolume: 2721338216.8446603,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '2385.8200000000000000',
      high: '2720.0000000000000000',
      low: '2271.4400000000000000',
      close: '2706.1500000000000000',
      volume: '1307559.4516500000000000',
      quoteVolume: 3276105181.236761,
      btcVolume: 90321.1015277825,
      usdVolume: 3271922711.64009,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '2706.1500000000000000',
      high: '2740.0000000000000000',
      low: '2523.7400000000000000',
      close: '2634.5700000000000000',
      volume: '1128194.7242100000000000',
      quoteVolume: 2948103721.112332,
      btcVolume: 79478.59488431703,
      usdVolume: 2909896222.812539,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '2634.3100000000000000',
      high: '2802.9900000000000000',
      low: '2550.0000000000000000',
      close: '2706.2200000000000000',
      volume: '942515.8424900000000000',
      quoteVolume: 2543367680.0125494,
      btcVolume: 67979.08133458497,
      usdVolume: 2536877829.1898575,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '2706.2100000000000000',
      high: '2891.9500000000000000',
      low: '2662.4000000000000000',
      close: '2857.2500000000000000',
      volume: '1020865.2499900000000000',
      quoteVolume: 2865136924.0086355,
      btcVolume: 74011.12317239847,
      usdVolume: 2859489401.1131005,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '2857.2500000000000000',
      high: '2860.0000000000000000',
      low: '2552.0000000000000000',
      close: '2689.5100000000000000',
      volume: '1128891.3342400000000000',
      quoteVolume: 3013427232.3647776,
      btcVolume: 79691.24087877397,
      usdVolume: 2948492978.646687,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '2689.5400000000000000',
      high: '2816.8700000000000000',
      low: '2552.2300000000000000',
      close: '2627.7300000000000000',
      volume: '961824.1241300000000000',
      quoteVolume: 2575726370.4308653,
      btcVolume: 69952.15513260124,
      usdVolume: 2547190306.6139827,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '2627.6300000000000000',
      high: '2745.0000000000000000',
      low: '2614.0000000000000000',
      close: '2711.9200000000000000',
      volume: '673616.2318600000000000',
      quoteVolume: 1813640710.7122996,
      btcVolume: 50332.5808706832,
      usdVolume: 1810527213.9748535,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '2711.9200000000000000',
      high: '2847.5500000000000000',
      low: '2570.0000000000000000',
      close: '2592.0500000000000000',
      volume: '1152128.0785600000000000',
      quoteVolume: 3149245176.9112477,
      btcVolume: 87844.73708419563,
      usdVolume: 3133289457.721581,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '2592.0500000000000000',
      high: '2620.9900000000000000',
      low: '2304.9100000000000000',
      close: '2507.2300000000000000',
      volume: '1693747.4275900000000000',
      quoteVolume: 4201646662.8817635,
      btcVolume: 127564.26680324697,
      usdVolume: 4173747237.8152375,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '2507.4300000000000000',
      high: '2626.6300000000000000',
      low: '2405.2800000000000000',
      close: '2610.1800000000000000',
      volume: '1276718.2401100000000000',
      quoteVolume: 3223323188.07037,
      btcVolume: 91671.38084778743,
      usdVolume: 3194267768.5913177,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '2610.2000000000000000',
      high: '2624.4600000000000000',
      low: '2425.1100000000000000',
      close: '2471.0900000000000000',
      volume: '740997.1712300000000000',
      quoteVolume: 1873951840.3917582,
      btcVolume: 50420.55129947163,
      usdVolume: 1868275591.2025664,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '2471.0100000000000000',
      high: '2497.9900000000000000',
      low: '2321.3700000000000000',
      close: '2354.0100000000000000',
      volume: '587364.2735800000000000',
      quoteVolume: 1424975261.80769,
      btcVolume: 38486.31716446658,
      usdVolume: 1422146092.2599802,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '2354.0900000000000000',
      high: '2454.5300000000000000',
      low: '2256.2200000000000000',
      close: '2370.7400000000000000',
      volume: '767439.4984000000000000',
      quoteVolume: 1807036943.9986227,
      btcVolume: 50420.12200546101,
      usdVolume: 1799225782.2437441,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '2370.6400000000000000',
      high: '2548.4100000000000000',
      low: '2308.4100000000000000',
      close: '2509.3300000000000000',
      volume: '715948.7598600000000000',
      quoteVolume: 1724097564.3342984,
      btcVolume: 46788.12807221204,
      usdVolume: 1720049201.122317,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '2509.3300000000000000',
      high: '2609.1700000000000000',
      low: '2461.0000000000000000',
      close: '2580.7700000000000000',
      volume: '684183.5562200000000000',
      quoteVolume: 1730260219.1337855,
      btcVolume: 43429.67097729521,
      usdVolume: 1729368351.3638728,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '2580.5800000000000000',
      high: '2640.0000000000000000',
      low: '2507.7000000000000000',
      close: '2543.5400000000000000',
      volume: '599826.6493300000000000',
      quoteVolume: 1548303436.0628068,
      btcVolume: 38413.865031627225,
      usdVolume: 1546074562.469748,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '2543.5500000000000000',
      high: '2556.1900000000000000',
      low: '2351.1800000000000000',
      close: '2367.8900000000000000',
      volume: '624741.7835800000000000',
      quoteVolume: 1533734359.4129868,
      btcVolume: 39025.025528815226,
      usdVolume: 1532223313.0262673,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '2368.0200000000000000',
      high: '2460.5000000000000000',
      low: '2306.0000000000000000',
      close: '2373.2800000000000000',
      volume: '560667.5856600000000000',
      quoteVolume: 1338389545.0014875,
      btcVolume: 34687.277518367926,
      usdVolume: 1336853440.9920366,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '2373.2800000000000000',
      high: '2378.0000000000000000',
      low: '2139.0000000000000000',
      close: '2234.0900000000000000',
      volume: '778905.2019700000000000',
      quoteVolume: 1758827532.8833337,
      btcVolume: 47651.515366817985,
      usdVolume: 1752866245.1499925,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '2234.0900000000000000',
      high: '2278.8200000000000000',
      low: '2161.6700000000000000',
      close: '2164.6700000000000000',
      volume: '496795.2001700000000000',
      quoteVolume: 1104751776.509073,
      btcVolume: 30908.617217601786,
      usdVolume: 1104192848.8738875,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '2165.3400000000000000',
      high: '2280.0100000000000000',
      low: '2040.6800000000000000',
      close: '2243.4700000000000000',
      volume: '743883.6474100000000000',
      quoteVolume: 1599009750.52694,
      btcVolume: 45441.06693970805,
      usdVolume: 1581146882.9629188,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '2243.4900000000000000',
      high: '2260.1600000000000000',
      low: '1865.5400000000000000',
      close: '1885.4900000000000000',
      volume: '1612068.6092700000000000',
      quoteVolume: 3216482751.6444073,
      btcVolume: 96836.53868378879,
      usdVolume: 3184376853.0560994,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '1885.2700000000000000',
      high: '1998.0000000000000000',
      low: '1700.4800000000000000',
      close: '1879.2800000000000000',
      volume: '1695015.0570000000000000',
      quoteVolume: 3158320609.65545,
      btcVolume: 98947.73538033027,
      usdVolume: 3106137818.9013505,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '1879.2900000000000000',
      high: '2045.0000000000000000',
      low: '1821.9500000000000000',
      close: '1968.1400000000000000',
      volume: '918428.2598300000000000',
      quoteVolume: 1817150090.2132,
      btcVolume: 53355.95568641275,
      usdVolume: 1800911052.56896,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '1967.9500000000000000',
      high: '2036.6100000000000000',
      low: '1884.7900000000000000',
      close: '1989.5200000000000000',
      volume: '696819.0814600000000000',
      quoteVolume: 1369338921.1143198,
      btcVolume: 40275.19926124708,
      usdVolume: 1360915669.485624,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '1989.5300000000000000',
      high: '2019.9900000000000000',
      low: '1791.2200000000000000',
      close: '1808.8200000000000000',
      volume: '858808.5476700000000000',
      quoteVolume: 1612556879.10726,
      btcVolume: 48562.74287689089,
      usdVolume: 1610678376.9593577,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '1808.9700000000000000',
      high: '1852.8000000000000000',
      low: '1717.3200000000000000',
      close: '1829.6400000000000000',
      volume: '832315.1490400000000000',
      quoteVolume: 1478550576.5646644,
      btcVolume: 47044.29221027522,
      usdVolume: 1472306570.7656367,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '1829.8400000000000000',
      high: '1984.5000000000000000',
      low: '1804.2600000000000000',
      close: '1982.2800000000000000',
      volume: '696055.7930700000000000',
      quoteVolume: 1299521992.0718284,
      btcVolume: 39054.314536943915,
      usdVolume: 1296909651.9815106,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '1982.2700000000000000',
      high: '2145.0000000000000000',
      low: '1960.5200000000000000',
      close: '2084.4100000000000000',
      volume: '1041685.6177500000000000',
      quoteVolume: 2133750196.9705138,
      btcVolume: 61642.91631656625,
      usdVolume: 2128309767.164647,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '2084.4200000000000000',
      high: '2245.0000000000000000',
      low: '2073.5700000000000000',
      close: '2165.3300000000000000',
      volume: '810280.8201900000000000',
      quoteVolume: 1758516830.5676162,
      btcVolume: 49297.042069009694,
      usdVolume: 1752182286.5171914,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '2165.4600000000000000',
      high: '2287.7800000000000000',
      low: '2087.9900000000000000',
      close: '2275.6800000000000000',
      volume: '898104.5594900000000000',
      quoteVolume: 1947010071.716728,
      btcVolume: 55804.56318164029,
      usdVolume: 1945352686.7102058,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '2275.6800000000000000',
      high: '2275.9300000000000000',
      low: '2073.1200000000000000',
      close: '2106.4100000000000000',
      volume: '787865.2513700000000000',
      quoteVolume: 1688043308.1272736,
      btcVolume: 50052.84631659016,
      usdVolume: 1684503864.007349,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '2106.4100000000000000',
      high: '2160.0000000000000000',
      low: '2018.5000000000000000',
      close: '2154.1300000000000000',
      volume: '667688.1982700000000000',
      quoteVolume: 1387639105.207638,
      btcVolume: 41678.18078815616,
      usdVolume: 1387357481.4871128,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '2153.9500000000000000',
      high: '2240.0000000000000000',
      low: '2112.0100000000000000',
      close: '2226.9900000000000000',
      volume: '567852.4913700000000000',
      quoteVolume: 1245755929.802624,
      btcVolume: 36259.684210643376,
      usdVolume: 1245045174.0268378,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '2226.9900000000000000',
      high: '2389.9300000000000000',
      low: '2189.5800000000000000',
      close: '2322.6900000000000000',
      volume: '586011.9181400000000000',
      quoteVolume: 1354921782.9253561,
      btcVolume: 38334.608601607164,
      usdVolume: 1354169661.0324094,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '2322.6900000000000000',
      high: '2324.6400000000000000',
      low: '2157.3200000000000000',
      close: '2196.5300000000000000',
      volume: '781397.7756700000000000',
      quoteVolume: 1752928567.61669,
      btcVolume: 50930.09033861102,
      usdVolume: 1733327510.3003647,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '2196.8500000000000000',
      high: '2350.0000000000000000',
      low: '2193.0000000000000000',
      close: '2322.5000000000000000',
      volume: '773628.7066000000000000',
      quoteVolume: 1776303270.1513493,
      btcVolume: 50945.131886658244,
      usdVolume: 1742938566.1977441,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '2322.5100000000000000',
      high: '2409.0000000000000000',
      low: '2291.9000000000000000',
      close: '2316.5700000000000000',
      volume: '663034.0864800000000000',
      quoteVolume: 1562325076.9449115,
      btcVolume: 45243.083431928804,
      usdVolume: 1562325076.9449115,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '2316.2500000000000000',
      high: '2325.6000000000000000',
      low: '2084.7900000000000000',
      close: '2116.3300000000000000',
      volume: '880265.0412400000000000',
      quoteVolume: 1926184139.3098714,
      btcVolume: 58517.61155780528,
      usdVolume: 1923539240.4032106,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '2116.3200000000000000',
      high: '2189.4000000000000000',
      low: '2046.5100000000000000',
      close: '2146.9900000000000000',
      volume: '738611.3630400000000000',
      quoteVolume: 1568646237.310992,
      btcVolume: 47365.37692337437,
      usdVolume: 1568180213.2470188,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '2146.9900000000000000',
      high: '2194.8200000000000000',
      low: '2074.1600000000000000',
      close: '2110.6900000000000000',
      volume: '499071.8590400000000000',
      quoteVolume: 1058023794.5114112,
      btcVolume: 31367.83342121098,
      usdVolume: 1058004268.9712181,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '2110.6800000000000000',
      high: '2174.0000000000000000',
      low: '2081.0200000000000000',
      close: '2140.4500000000000000',
      volume: '341861.9107800000000000',
      quoteVolume: 726983500.1953408,
      btcVolume: 21483.282017250633,
      usdVolume: 726871259.6481026,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '2140.5400000000000000',
      high: '2167.8300000000000000',
      low: '2006.6800000000000000',
      close: '2031.5800000000000000',
      volume: '540630.1074200000000000',
      quoteVolume: 1126108847.3713632,
      btcVolume: 33473.528532740485,
      usdVolume: 1122137454.5873184,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '2031.5700000000000000',
      high: '2047.3500000000000000',
      low: '1918.0000000000000000',
      close: '1940.1500000000000000',
      volume: '646200.5714500000000000',
      quoteVolume: 1285151955.408697,
      btcVolume: 39172.03527550333,
      usdVolume: 1284955208.4702895,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '1940.4400000000000000',
      high: '2019.1800000000000000',
      low: '1865.0000000000000000',
      close: '1994.5800000000000000',
      volume: '690308.3220300000000000',
      quoteVolume: 1342549043.7056658,
      btcVolume: 41334.87560952568,
      usdVolume: 1341142947.6783092,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '1994.4100000000000000',
      high: '2042.8800000000000000',
      low: '1881.0000000000000000',
      close: '1919.5100000000000000',
      volume: '645206.5048600000000000',
      quoteVolume: 1251707253.3075902,
      btcVolume: 38931.359341004165,
      usdVolume: 1249677161.8594604,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '1919.5400000000000000',
      high: '1965.0000000000000000',
      low: '1850.0000000000000000',
      close: '1876.2600000000000000',
      volume: '564174.3896200000000000',
      quoteVolume: 1073278193.9976848,
      btcVolume: 33853.35022909676,
      usdVolume: 1073277728.2380823,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '1876.4700000000000000',
      high: '1919.1900000000000000',
      low: '1844.4500000000000000',
      close: '1899.5900000000000000',
      volume: '425518.6836600000000000',
      quoteVolume: 801669257.5310873,
      btcVolume: 25349.466334220528,
      usdVolume: 799730281.5427622,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '1899.5000000000000000',
      high: '1993.7900000000000000',
      low: '1877.1000000000000000',
      close: '1891.6400000000000000',
      volume: '534837.6365400000000000',
      quoteVolume: 1038104997.5220226,
      btcVolume: 32671.78700030974,
      usdVolume: 1038010885.0892934,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '1891.6500000000000000',
      high: '1919.0000000000000000',
      low: '1805.0000000000000000',
      close: '1818.7300000000000000',
      volume: '547419.9377100000000000',
      quoteVolume: 1011627398.9238861,
      btcVolume: 32502.636019487036,
      usdVolume: 1011432953.4100147,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '1818.7300000000000000',
      high: '1841.3400000000000000',
      low: '1706.0000000000000000',
      close: '1786.0300000000000000',
      volume: '786035.8219700000000000',
      quoteVolume: 1386111795.633173,
      btcVolume: 46342.08150771999,
      usdVolume: 1385239379.852115,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '1786.0300000000000000',
      high: '2035.2300000000000000',
      low: '1747.5500000000000000',
      close: '1995.5000000000000000',
      volume: '1115949.8203300000000000',
      quoteVolume: 2138345813.7854047,
      btcVolume: 68045.4703475941,
      usdVolume: 2137544618.252568,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '1995.5000000000000000',
      high: '2046.0000000000000000',
      low: '1947.6200000000000000',
      close: '2024.5600000000000000',
      volume: '768854.3659000000000000',
      quoteVolume: 1536669128.520453,
      btcVolume: 47687.592340090785,
      usdVolume: 1532204051.4068804,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '2024.2700000000000000',
      high: '2094.2800000000000000',
      low: '1993.2200000000000000',
      close: '2034.1400000000000000',
      volume: '561649.8608800000000000',
      quoteVolume: 1150771289.6299481,
      btcVolume: 35513.3079063386,
      usdVolume: 1150771289.6299481,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
  bch: {
    id: 'bch',
    name: 'BCH',
    symbol: 'BCH',
    image: 'assets/images/coin-logo/BCH.png',
    activity24h: 545323456,
    availableAmount: 508.232323,
    usdxConversion: 7,
    allTimeValue: 3812390,
    allTimeAsset: 90678678421,
    candleData: [
      {
      open: '52.2500000000000000',
      high: '52.6600000000000000',
      low: '51.7000000000000000',
      close: '52.0700000000000000',
      volume: '35288.3970200000000000',
      quoteVolume: 1838724.3862475,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-28T00:00:00.000Z'
      },
      {
      open: '52.1000000000000000',
      high: '52.2600000000000000',
      low: '48.1700000000000000',
      close: '49.2100000000000000',
      volume: '93056.5563300000000000',
      quoteVolume: 4660386.8668885,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-29T00:00:00.000Z'
      },
      {
      open: '49.1600000000000000',
      high: '49.9600000000000000',
      low: '49.0200000000000000',
      close: '49.3400000000000000',
      volume: '41869.7843300000000000',
      quoteVolume: 2069910.6907136,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-30T00:00:00.000Z'
      },
      {
      open: '49.3500000000000000',
      high: '50.7100000000000000',
      low: '47.7100000000000000',
      close: '49.9300000000000000',
      volume: '59069.9364000000000000',
      quoteVolume: 2926931.182231,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-31T00:00:00.000Z'
      },
      {
      open: '49.9400000000000000',
      high: '50.8500000000000000',
      low: '49.7800000000000000',
      close: '50.6100000000000000',
      volume: '40743.5490400000000000',
      quoteVolume: 2044481.6283927,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-01T00:00:00.000Z'
      },
      {
      open: '50.6300000000000000',
      high: '52.2000000000000000',
      low: '50.5600000000000000',
      close: '51.7800000000000000',
      volume: '55439.5422700000000000',
      quoteVolume: 2853112.5898938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-02T00:00:00.000Z'
      },
      {
      open: '51.7800000000000000',
      high: '51.8100000000000000',
      low: '50.6000000000000000',
      close: '51.0400000000000000',
      volume: '44526.4412600000000000',
      quoteVolume: 2280110.5089847,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-03T00:00:00.000Z'
      },
      {
      open: '51.0900000000000000',
      high: '56.3300000000000000',
      low: '50.7700000000000000',
      close: '54.6000000000000000',
      volume: '131563.2191000000000000',
      quoteVolume: 7106100.759797,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-04T00:00:00.000Z'
      },
      {
      open: '54.6000000000000000',
      high: '55.1500000000000000',
      low: '53.0100000000000000',
      close: '53.9100000000000000',
      volume: '71425.6818700000000000',
      quoteVolume: 3841003.0724431,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-05T00:00:00.000Z'
      },
      {
      open: '53.9300000000000000',
      high: '56.8800000000000000',
      low: '53.8700000000000000',
      close: '56.4800000000000000',
      volume: '82733.1937500000000000',
      quoteVolume: 4543573.2471019,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-06T00:00:00.000Z'
      },
      {
      open: '56.4400000000000000',
      high: '56.9200000000000000',
      low: '53.5200000000000000',
      close: '54.4700000000000000',
      volume: '96697.1057100000000000',
      quoteVolume: 5343582.6991728,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-07T00:00:00.000Z'
      },
      {
      open: '54.4900000000000000',
      high: '55.1200000000000000',
      low: '52.4200000000000000',
      close: '52.7200000000000000',
      volume: '71785.2511700000000000',
      quoteVolume: 3845880.2935037,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-08T00:00:00.000Z'
      },
      {
      open: '52.7000000000000000',
      high: '53.1500000000000000',
      low: '51.6200000000000000',
      close: '52.2500000000000000',
      volume: '54807.0000800000000000',
      quoteVolume: 2873957.5317537,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-09T00:00:00.000Z'
      },
      {
      open: '52.2800000000000000',
      high: '53.0800000000000000',
      low: '51.9700000000000000',
      close: '52.4800000000000000',
      volume: '37947.9906200000000000',
      quoteVolume: 1994759.2672652,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-10T00:00:00.000Z'
      },
      {
      open: '52.4700000000000000',
      high: '52.5700000000000000',
      low: '49.7900000000000000',
      close: '51.4600000000000000',
      volume: '66550.4946300000000000',
      quoteVolume: 3407022.15279,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-11T00:00:00.000Z'
      },
      {
      open: '51.5000000000000000',
      high: '52.0400000000000000',
      low: '50.5900000000000000',
      close: '51.2600000000000000',
      volume: '60038.9645800000000000',
      quoteVolume: 3075503.9229793,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-12T00:00:00.000Z'
      },
      {
      open: '51.2600000000000000',
      high: '51.6600000000000000',
      low: '49.6000000000000000',
      close: '50.4300000000000000',
      volume: '63966.8834300000000000',
      quoteVolume: 3255168.879664,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-13T00:00:00.000Z'
      },
      {
      open: '50.4100000000000000',
      high: '51.0800000000000000',
      low: '42.0000000000000000',
      close: '45.0500000000000000',
      volume: '307943.6722800000000000',
      quoteVolume: 13948978.8933711,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-14T00:00:00.000Z'
      },
      {
      open: '45.0500000000000000',
      high: '45.8000000000000000',
      low: '40.4000000000000000',
      close: '44.7800000000000000',
      volume: '239147.1436300000000000',
      quoteVolume: 10442471.6269976,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-15T00:00:00.000Z'
      },
      {
      open: '44.7800000000000000',
      high: '44.9500000000000000',
      low: '42.3800000000000000',
      close: '42.8900000000000000',
      volume: '126959.9343000000000000',
      quoteVolume: 5526205.6703331,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-16T00:00:00.000Z'
      },
      {
      open: '42.8900000000000000',
      high: '42.9400000000000000',
      low: '41.9200000000000000',
      close: '42.4300000000000000',
      volume: '64923.2697500000000000',
      quoteVolume: 2748488.2839843,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-17T00:00:00.000Z'
      },
      {
      open: '42.4600000000000000',
      high: '43.8400000000000000',
      low: '42.1100000000000000',
      close: '42.6100000000000000',
      volume: '69727.5181800000000000',
      quoteVolume: 2991350.2245596,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-18T00:00:00.000Z'
      },
      {
      open: '42.6300000000000000',
      high: '42.6500000000000000',
      low: '35.9400000000000000',
      close: '37.0700000000000000',
      volume: '266627.8181000000000000',
      quoteVolume: 10193547.6518018,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-19T00:00:00.000Z'
      },
      {
      open: '37.1100000000000000',
      high: '38.8000000000000000',
      low: '31.4700000000000000',
      close: '33.8000000000000000',
      volume: '481972.2055200000000000',
      quoteVolume: 16734732.4018969,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-20T00:00:00.000Z'
      },
      {
      open: '33.7100000000000000',
      high: '35.3800000000000000',
      low: '32.1700000000000000',
      close: '35.1500000000000000',
      volume: '307689.2861400000000000',
      quoteVolume: 10447986.7251629,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-21T00:00:00.000Z'
      },
      {
      open: '35.1700000000000000',
      high: '35.6800000000000000',
      low: '31.8400000000000000',
      close: '32.1100000000000000',
      volume: '140470.9296900000000000',
      quoteVolume: 4791952.8493514,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-22T00:00:00.000Z'
      },
      {
      open: '32.0900000000000000',
      high: '33.8100000000000000',
      low: '31.1000000000000000',
      close: '32.7400000000000000',
      volume: '178263.3759900000000000',
      quoteVolume: 5763152.7094353,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-23T00:00:00.000Z'
      },
      {
      open: '32.7400000000000000',
      high: '34.3000000000000000',
      low: '29.0700000000000000',
      close: '29.7300000000000000',
      volume: '175080.6502100000000000',
      quoteVolume: 5486899.3643201,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-24T00:00:00.000Z'
      },
      {
      open: '29.7200000000000000',
      high: '32.9300000000000000',
      low: '27.7000000000000000',
      close: '31.4900000000000000',
      volume: '330712.4127800000000000',
      quoteVolume: 9875455.0267542,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-25T00:00:00.000Z'
      },
      {
      open: '31.5100000000000000',
      high: '32.8800000000000000',
      low: '28.6600000000000000',
      close: '30.1800000000000000',
      volume: '346086.7723900000000000',
      quoteVolume: 10650119.32109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-26T00:00:00.000Z'
      },
      {
      open: '30.1800000000000000',
      high: '32.1600000000000000',
      low: '29.0500000000000000',
      close: '31.6100000000000000',
      volume: '194024.5309000000000000',
      quoteVolume: 5911546.9844789,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-27T00:00:00.000Z'
      },
      {
      open: '31.6200000000000000',
      high: '36.5100000000000000',
      low: '31.3900000000000000',
      close: '34.7400000000000000',
      volume: '261988.5621100000000000',
      quoteVolume: 8934592.4563842,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-28T00:00:00.000Z'
      },
      {
      open: '34.7400000000000000',
      high: '36.0000000000000000',
      low: '33.0800000000000000',
      close: '33.8800000000000000',
      volume: '206424.0394100000000000',
      quoteVolume: 7091338.8885229,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-29T00:00:00.000Z'
      },
      {
      open: '33.9000000000000000',
      high: '34.5300000000000000',
      low: '31.0700000000000000',
      close: '32.2100000000000000',
      volume: '182324.5556100000000000',
      quoteVolume: 5934596.3999476,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-30T00:00:00.000Z'
      },
      {
      open: '32.1900000000000000',
      high: '35.1000000000000000',
      low: '31.3800000000000000',
      close: '34.1700000000000000',
      volume: '130601.1752700000000000',
      quoteVolume: 4373049.6516537,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-01T00:00:00.000Z'
      },
      {
      open: '34.1100000000000000',
      high: '35.2400000000000000',
      low: '33.2100000000000000',
      close: '33.8200000000000000',
      volume: '126562.5130800000000000',
      quoteVolume: 4314174.8406725,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-02T00:00:00.000Z'
      },
      {
      open: '33.8100000000000000',
      high: '33.9700000000000000',
      low: '30.0800000000000000',
      close: '30.9000000000000000',
      volume: '160953.4761900000000000',
      quoteVolume: 5107201.1187242,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-03T00:00:00.000Z'
      },
      {
      open: '30.9200000000000000',
      high: '32.2500000000000000',
      low: '29.8700000000000000',
      close: '31.1300000000000000',
      volume: '125624.1006500000000000',
      quoteVolume: 3938334.4457955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-04T00:00:00.000Z'
      },
      {
      open: '31.1400000000000000',
      high: '31.4000000000000000',
      low: '29.1500000000000000',
      close: '29.2700000000000000',
      volume: '122536.9591900000000000',
      quoteVolume: 3686632.3658405,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-05T00:00:00.000Z'
      },
      {
      open: '29.2800000000000000',
      high: '30.4900000000000000',
      low: '26.2500000000000000',
      close: '26.7800000000000000',
      volume: '186020.5287000000000000',
      quoteVolume: 5318278.7153886,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-06T00:00:00.000Z'
      },
      {
      open: '26.7400000000000000',
      high: '26.9400000000000000',
      low: '22.3200000000000000',
      close: '24.9100000000000000',
      volume: '344334.7713200000000000',
      quoteVolume: 8550497.2274868,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-07T00:00:00.000Z'
      },
      {
      open: '24.8800000000000000',
      high: '26.6300000000000000',
      low: '22.8900000000000000',
      close: '24.2200000000000000',
      volume: '272026.2401200000000000',
      quoteVolume: 6669827.4592307,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-08T00:00:00.000Z'
      },
      {
      open: '24.2300000000000000',
      high: '26.7500000000000000',
      low: '24.2000000000000000',
      close: '25.3700000000000000',
      volume: '185392.2777400000000000',
      quoteVolume: 4728009.3049824,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-09T00:00:00.000Z'
      },
      {
      open: '25.3700000000000000',
      high: '25.8200000000000000',
      low: '23.6700000000000000',
      close: '24.1400000000000000',
      volume: '159523.3339000000000000',
      quoteVolume: 3913245.9562269,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-10T00:00:00.000Z'
      },
      {
      open: '24.1500000000000000',
      high: '24.6300000000000000',
      low: '22.9400000000000000',
      close: '23.4100000000000000',
      volume: '116912.3106800000000000',
      quoteVolume: 2774102.4887662,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-11T00:00:00.000Z'
      },
      {
      open: '23.4000000000000000',
      high: '24.9800000000000000',
      low: '23.2000000000000000',
      close: '24.1300000000000000',
      volume: '116697.0162100000000000',
      quoteVolume: 2839522.3254574,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-12T00:00:00.000Z'
      },
      {
      open: '24.1300000000000000',
      high: '24.2900000000000000',
      low: '22.9100000000000000',
      close: '23.0800000000000000',
      volume: '101343.2455300000000000',
      quoteVolume: 2391795.1552609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-13T00:00:00.000Z'
      },
      {
      open: '23.0600000000000000',
      high: '23.7600000000000000',
      low: '22.5000000000000000',
      close: '23.3400000000000000',
      volume: '115451.1935700000000000',
      quoteVolume: 2669993.897385,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-14T00:00:00.000Z'
      },
      {
      open: '23.3300000000000000',
      high: '24.0600000000000000',
      low: '23.0900000000000000',
      close: '23.6000000000000000',
      volume: '114346.7365200000000000',
      quoteVolume: 2696112.2014415,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-15T00:00:00.000Z'
      },
      {
      open: '23.5900000000000000',
      high: '26.9600000000000000',
      low: '23.4800000000000000',
      close: '25.5200000000000000',
      volume: '284638.3664300000000000',
      quoteVolume: 7367653.6178438,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-16T00:00:00.000Z'
      },
      {
      open: '25.5100000000000000',
      high: '30.3000000000000000',
      low: '25.4300000000000000',
      close: '28.9500000000000000',
      volume: '415677.5500900000000000',
      quoteVolume: 11734862.3593704,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-17T00:00:00.000Z'
      },
      {
      open: '28.9700000000000000',
      high: '30.1500000000000000',
      low: '27.3900000000000000',
      close: '29.8900000000000000',
      volume: '241540.2199400000000000',
      quoteVolume: 6940548.103116,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-18T00:00:00.000Z'
      },
      {
      open: '29.8900000000000000',
      high: '31.5500000000000000',
      low: '28.5100000000000000',
      close: '28.8300000000000000',
      volume: '464104.8813400000000000',
      quoteVolume: 13967868.6904562,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-19T00:00:00.000Z'
      },
      {
      open: '28.8300000000000000',
      high: '32.7600000000000000',
      low: '28.5200000000000000',
      close: '32.1800000000000000',
      volume: '347428.5018700000000000',
      quoteVolume: 10702281.6625123,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-20T00:00:00.000Z'
      },
      {
      open: '32.1800000000000000',
      high: '32.8700000000000000',
      low: '29.5500000000000000',
      close: '30.2900000000000000',
      volume: '222927.6634500000000000',
      quoteVolume: 6941800.3395846,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-21T00:00:00.000Z'
      },
      {
      open: '30.2900000000000000',
      high: '31.4900000000000000',
      low: '29.7000000000000000',
      close: '31.3300000000000000',
      volume: '106033.3357700000000000',
      quoteVolume: 3234572.7694072,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-22T00:00:00.000Z'
      },
      {
      open: '31.2900000000000000',
      high: '33.8400000000000000',
      low: '31.0900000000000000',
      close: '32.9700000000000000',
      volume: '255561.7289100000000000',
      quoteVolume: 8317118.2252661,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-23T00:00:00.000Z'
      },
      {
      open: '32.9800000000000000',
      high: '36.1200000000000000',
      low: '32.1700000000000000',
      close: '32.6800000000000000',
      volume: '305504.7449800000000000',
      quoteVolume: 10561724.7698791,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-24T00:00:00.000Z'
      },
      {
      open: '32.6900000000000000',
      high: '32.9300000000000000',
      low: '29.1900000000000000',
      close: '30.5000000000000000',
      volume: '248993.5555600000000000',
      quoteVolume: 7586691.2768408,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-25T00:00:00.000Z'
      },
      {
      open: '30.5000000000000000',
      high: '31.6800000000000000',
      low: '28.7900000000000000',
      close: '30.3000000000000000',
      volume: '174048.0413600000000000',
      quoteVolume: 5242464.4829548,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-26T00:00:00.000Z'
      },
      {
      open: '30.2700000000000000',
      high: '30.8900000000000000',
      low: '27.1700000000000000',
      close: '27.5500000000000000',
      volume: '157817.2261100000000000',
      quoteVolume: 4528463.300989,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-27T00:00:00.000Z'
      },
      {
      open: '27.5500000000000000',
      high: '33.0800000000000000',
      low: '27.3900000000000000',
      close: '31.8000000000000000',
      volume: '242123.8975000000000000',
      quoteVolume: 7428412.8216719,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-28T00:00:00.000Z'
      },
      {
      open: '31.7800000000000000',
      high: '32.8100000000000000',
      low: '29.9100000000000000',
      close: '30.2800000000000000',
      volume: '222196.8569800000000000',
      quoteVolume: 6994333.2082403,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-29T00:00:00.000Z'
      },
      {
      open: '30.2800000000000000',
      high: '32.0000000000000000',
      low: '29.6800000000000000',
      close: '31.3800000000000000',
      volume: '151572.9304500000000000',
      quoteVolume: 4696122.0322328,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-30T00:00:00.000Z'
      },
      {
      open: '31.3800000000000000',
      high: '31.4300000000000000',
      low: '29.4200000000000000',
      close: '29.9000000000000000',
      volume: '97446.1124100000000000',
      quoteVolume: 2959940.3443041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-31T00:00:00.000Z'
      },
      {
      open: '29.9200000000000000',
      high: '31.8000000000000000',
      low: '29.5600000000000000',
      close: '31.4800000000000000',
      volume: '103182.7376500000000000',
      quoteVolume: 3162741.4467664,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-01T00:00:00.000Z'
      },
      {
      open: '31.4700000000000000',
      high: '33.2900000000000000',
      low: '31.0700000000000000',
      close: '32.6700000000000000',
      volume: '118306.2331700000000000',
      quoteVolume: 3805773.1198366,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-02T00:00:00.000Z'
      },
      {
      open: '32.6800000000000000',
      high: '32.8000000000000000',
      low: '30.6200000000000000',
      close: '31.3900000000000000',
      volume: '94129.8511400000000000',
      quoteVolume: 2982980.2248225,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-03T00:00:00.000Z'
      },
      {
      open: '31.4100000000000000',
      high: '32.5300000000000000',
      low: '30.7800000000000000',
      close: '31.7600000000000000',
      volume: '108636.8219300000000000',
      quoteVolume: 3437486.3851984,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-04T00:00:00.000Z'
      },
      {
      open: '31.7600000000000000',
      high: '35.9500000000000000',
      low: '31.7600000000000000',
      close: '34.3300000000000000',
      volume: '335581.5584800000000000',
      quoteVolume: 11471296.9699548,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-05T00:00:00.000Z'
      },
      {
      open: '34.3300000000000000',
      high: '39.7100000000000000',
      low: '33.8900000000000000',
      close: '38.3000000000000000',
      volume: '761396.1067600000000000',
      quoteVolume: 28298586.9009635,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-06T00:00:00.000Z'
      },
      {
      open: '38.2900000000000000',
      high: '39.0400000000000000',
      low: '36.5300000000000000',
      close: '37.3500000000000000',
      volume: '444975.9243100000000000',
      quoteVolume: 16797767.6556064,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-07T00:00:00.000Z'
      },
      {
      open: '37.3500000000000000',
      high: '40.8000000000000000',
      low: '36.9100000000000000',
      close: '38.7000000000000000',
      volume: '522510.1714200000000000',
      quoteVolume: 20322945.3312497,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-08T00:00:00.000Z'
      },
      {
      open: '38.7000000000000000',
      high: '39.7200000000000000',
      low: '38.0000000000000000',
      close: '38.1700000000000000',
      volume: '312030.1302100000000000',
      quoteVolume: 12104357.4442312,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-09T00:00:00.000Z'
      },
      {
      open: '38.1700000000000000',
      high: '38.7700000000000000',
      low: '31.0000000000000000',
      close: '32.8100000000000000',
      volume: '584622.4539000000000000',
      quoteVolume: 19980421.46609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-10T00:00:00.000Z'
      },
      {
      open: '32.8100000000000000',
      high: '33.2200000000000000',
      low: '29.6800000000000000',
      close: '31.4900000000000000',
      volume: '351182.3816500000000000',
      quoteVolume: 11268415.5174647,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-11T00:00:00.000Z'
      },
      {
      open: '31.4800000000000000',
      high: '32.1300000000000000',
      low: '31.0700000000000000',
      close: '31.6500000000000000',
      volume: '178476.2871800000000000',
      quoteVolume: 5640569.2458151,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-12T00:00:00.000Z'
      },
      {
      open: '31.6500000000000000',
      high: '32.6300000000000000',
      low: '29.1200000000000000',
      close: '29.5900000000000000',
      volume: '229264.1192700000000000',
      quoteVolume: 7075842.0712984,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-13T00:00:00.000Z'
      },
      {
      open: '29.6000000000000000',
      high: '32.5000000000000000',
      low: '29.3800000000000000',
      close: '31.7100000000000000',
      volume: '255282.3580100000000000',
      quoteVolume: 7925720.2735823,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-14T00:00:00.000Z'
      },
      {
      open: '31.7200000000000000',
      high: '32.2700000000000000',
      low: '29.9500000000000000',
      close: '30.7500000000000000',
      volume: '216841.8608500000000000',
      quoteVolume: 6784015.4244266,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-15T00:00:00.000Z'
      },
      {
      open: '30.7400000000000000',
      high: '31.9300000000000000',
      low: '30.4700000000000000',
      close: '31.2900000000000000',
      volume: '153939.5962200000000000',
      quoteVolume: 4789334.1418824,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '31.2900000000000000',
      high: '31.5500000000000000',
      low: '29.8000000000000000',
      close: '31.4000000000000000',
      volume: '203218.0028600000000000',
      quoteVolume: 6238519.8934698,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '31.3800000000000000',
      high: '31.5300000000000000',
      low: '30.3100000000000000',
      close: '30.7700000000000000',
      volume: '150711.3444400000000000',
      quoteVolume: 4659547.3864997,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '30.7800000000000000',
      high: '32.9200000000000000',
      low: '30.7700000000000000',
      close: '32.0000000000000000',
      volume: '164989.6366800000000000',
      quoteVolume: 5273505.545043,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '31.9400000000000000',
      high: '33.2000000000000000',
      low: '28.4500000000000000',
      close: '30.6400000000000000',
      volume: '249450.0040800000000000',
      quoteVolume: 7815412.7300217,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '30.6300000000000000',
      high: '31.2200000000000000',
      low: '30.3400000000000000',
      close: '30.8200000000000000',
      volume: '110305.7586600000000000',
      quoteVolume: 3392716.0542585,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '30.8200000000000000',
      high: '32.0000000000000000',
      low: '29.4800000000000000',
      close: '31.4100000000000000',
      volume: '199887.1625700000000000',
      quoteVolume: 6183272.8098907,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '31.4000000000000000',
      high: '32.1800000000000000',
      low: '31.2400000000000000',
      close: '31.6900000000000000',
      volume: '123577.2723200000000000',
      quoteVolume: 3920165.8017895,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '31.6900000000000000',
      high: '33.1000000000000000',
      low: '30.8900000000000000',
      close: '32.5100000000000000',
      volume: '138812.1621600000000000',
      quoteVolume: 4453091.2945143,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '32.5200000000000000',
      high: '33.0000000000000000',
      low: '31.8700000000000000',
      close: '32.6900000000000000',
      volume: '169242.5338200000000000',
      quoteVolume: 5485967.9294638,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '32.6800000000000000',
      high: '33.9500000000000000',
      low: '32.5700000000000000',
      close: '32.8000000000000000',
      volume: '166677.4280800000000000',
      quoteVolume: 5537195.8890435,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '32.7900000000000000',
      high: '33.2000000000000000',
      low: '31.0500000000000000',
      close: '32.2100000000000000',
      volume: '173697.8199800000000000',
      quoteVolume: 5628213.3201342,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '32.2200000000000000',
      high: '32.5300000000000000',
      low: '30.0000000000000000',
      close: '30.9400000000000000',
      volume: '267887.6294200000000000',
      quoteVolume: 8275485.9723247,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '30.9400000000000000',
      high: '31.2600000000000000',
      low: '29.9100000000000000',
      close: '30.8100000000000000',
      volume: '135879.8588000000000000',
      quoteVolume: 4168438.6557358,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '30.8000000000000000',
      high: '31.9700000000000000',
      low: '30.4700000000000000',
      close: '31.6800000000000000',
      volume: '129114.9970500000000000',
      quoteVolume: 4046024.3723204,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '31.6800000000000000',
      high: '32.3300000000000000',
      low: '31.0800000000000000',
      close: '31.4100000000000000',
      volume: '154708.1742700000000000',
      quoteVolume: 4881672.9566493,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '31.4300000000000000',
      high: '33.6400000000000000',
      low: '30.7000000000000000',
      close: '32.6500000000000000',
      volume: '335544.9644500000000000',
      quoteVolume: 10864529.5873272,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '32.6100000000000000',
      high: '35.0100000000000000',
      low: '32.4400000000000000',
      close: '34.5900000000000000',
      volume: '268052.5235400000000000',
      quoteVolume: 8939273.973636,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '34.5900000000000000',
      high: '34.8900000000000000',
      low: '32.8600000000000000',
      close: '33.4400000000000000',
      volume: '348406.7322400000000000',
      quoteVolume: 11802330.6146259,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '33.4300000000000000',
      high: '34.5000000000000000',
      low: '33.3600000000000000',
      close: '34.0900000000000000',
      volume: '206649.6690200000000000',
      quoteVolume: 7014206.2681502,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '34.0800000000000000',
      high: '34.5400000000000000',
      low: '33.6300000000000000',
      close: '34.3400000000000000',
      volume: '214319.3423500000000000',
      quoteVolume: 7321284.8808597,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '34.3500000000000000',
      high: '34.5000000000000000',
      low: '32.2500000000000000',
      close: '32.7900000000000000',
      volume: '312456.5420100000000000',
      quoteVolume: 10273847.9600226,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '32.7800000000000000',
      high: '33.6100000000000000',
      low: '32.7300000000000000',
      close: '33.2400000000000000',
      volume: '144528.0808400000000000',
      quoteVolume: 4798854.8184626,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '33.2300000000000000',
      high: '44.8500000000000000',
      low: '32.7500000000000000',
      close: '42.9900000000000000',
      volume: '1237384.0701200000000000',
      quoteVolume: 48819159.693489,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '42.9900000000000000',
      high: '46.9500000000000000',
      low: '42.0500000000000000',
      close: '44.6800000000000000',
      volume: '993529.3585100000000000',
      quoteVolume: 43845377.3617417,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '44.6800000000000000',
      high: '47.5900000000000000',
      low: '42.0000000000000000',
      close: '46.7900000000000000',
      volume: '667594.4622900000000000',
      quoteVolume: 30084786.2181325,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '46.8000000000000000',
      high: '46.8700000000000000',
      low: '41.8100000000000000',
      close: '42.9000000000000000',
      volume: '570398.4899100000000000',
      quoteVolume: 25000654.7153878,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '42.8800000000000000',
      high: '44.5700000000000000',
      low: '41.8400000000000000',
      close: '43.8900000000000000',
      volume: '326731.5580100000000000',
      quoteVolume: 14181167.7324087,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '43.8900000000000000',
      high: '44.6600000000000000',
      low: '40.6400000000000000',
      close: '41.6100000000000000',
      volume: '333914.8432600000000000',
      quoteVolume: 14169773.6990322,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '41.6100000000000000',
      high: '42.4500000000000000',
      low: '40.3100000000000000',
      close: '41.2600000000000000',
      volume: '285144.9373900000000000',
      quoteVolume: 11825040.1861124,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '41.2600000000000000',
      high: '44.6000000000000000',
      low: '41.0600000000000000',
      close: '42.5800000000000000',
      volume: '389950.9521300000000000',
      quoteVolume: 16519445.9920431,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '42.6000000000000000',
      high: '44.3700000000000000',
      low: '42.2800000000000000',
      close: '43.3400000000000000',
      volume: '326691.7079300000000000',
      quoteVolume: 14225774.1208697,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '43.3400000000000000',
      high: '45.1300000000000000',
      low: '42.6000000000000000',
      close: '43.9100000000000000',
      volume: '331698.7960300000000000',
      quoteVolume: 14459368.8381966,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '43.8900000000000000',
      high: '49.0300000000000000',
      low: '43.6200000000000000',
      close: '47.8400000000000000',
      volume: '744029.8712100000000000',
      quoteVolume: 34349598.9758269,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '47.8100000000000000',
      high: '49.1000000000000000',
      low: '46.5600000000000000',
      close: '47.1400000000000000',
      volume: '404786.7578400000000000',
      quoteVolume: 19350985.317177,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '47.1600000000000000',
      high: '52.2100000000000000',
      low: '46.2500000000000000',
      close: '51.5000000000000000',
      volume: '566874.1207300000000000',
      quoteVolume: 28402239.4220076,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '51.4700000000000000',
      high: '51.6500000000000000',
      low: '47.3500000000000000',
      close: '48.9400000000000000',
      volume: '383781.9003200000000000',
      quoteVolume: 18972966.8701831,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '48.9300000000000000',
      high: '49.9700000000000000',
      low: '48.0800000000000000',
      close: '49.1900000000000000',
      volume: '265979.4348900000000000',
      quoteVolume: 13079846.878828,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '49.2200000000000000',
      high: '51.8800000000000000',
      low: '48.0000000000000000',
      close: '51.4000000000000000',
      volume: '411115.3383000000000000',
      quoteVolume: 20597303.0431109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '51.3900000000000000',
      high: '53.5300000000000000',
      low: '42.3700000000000000',
      close: '43.7500000000000000',
      volume: '685277.0160800000000000',
      quoteVolume: 32440229.6963062,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '43.7400000000000000',
      high: '46.8500000000000000',
      low: '43.0000000000000000',
      close: '45.4000000000000000',
      volume: '377273.1727500000000000',
      quoteVolume: 16992487.3052862,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '45.4000000000000000',
      high: '45.8900000000000000',
      low: '44.0600000000000000',
      close: '44.7600000000000000',
      volume: '220101.5549400000000000',
      quoteVolume: 9886505.2314402,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '44.7700000000000000',
      high: '45.5300000000000000',
      low: '42.5100000000000000',
      close: '45.1600000000000000',
      volume: '223940.9450500000000000',
      quoteVolume: 9964616.9874317,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '45.1500000000000000',
      high: '46.7000000000000000',
      low: '44.4400000000000000',
      close: '45.6100000000000000',
      volume: '258562.9677900000000000',
      quoteVolume: 11834468.51228,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '45.6200000000000000',
      high: '48.9500000000000000',
      low: '45.5300000000000000',
      close: '47.0500000000000000',
      volume: '336011.9229400000000000',
      quoteVolume: 15914635.4786478,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '47.0200000000000000',
      high: '49.4000000000000000',
      low: '46.3800000000000000',
      close: '48.4900000000000000',
      volume: '325733.9473500000000000',
      quoteVolume: 15618945.7813467,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '48.5100000000000000',
      high: '49.1000000000000000',
      low: '47.1800000000000000',
      close: '47.8600000000000000',
      volume: '218511.9939600000000000',
      quoteVolume: 10525909.3067072,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '47.8700000000000000',
      high: '48.2200000000000000',
      low: '44.5700000000000000',
      close: '45.7800000000000000',
      volume: '306985.2797700000000000',
      quoteVolume: 14131761.4970669,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '45.7800000000000000',
      high: '54.2500000000000000',
      low: '45.3800000000000000',
      close: '52.5200000000000000',
      volume: '798938.1036800000000000',
      quoteVolume: 40660412.2179829,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '52.5200000000000000',
      high: '56.8100000000000000',
      low: '51.1100000000000000',
      close: '55.3700000000000000',
      volume: '670227.4940300000000000',
      quoteVolume: 36366973.2904414,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '55.3700000000000000',
      high: '59.1200000000000000',
      low: '53.7700000000000000',
      close: '56.7300000000000000',
      volume: '816612.7704900000000000',
      quoteVolume: 46227699.9424252,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '56.7300000000000000',
      high: '57.6000000000000000',
      low: '54.3000000000000000',
      close: '55.6100000000000000',
      volume: '440480.6590400000000000',
      quoteVolume: 24793142.8918252,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '55.5800000000000000',
      high: '59.8300000000000000',
      low: '55.3800000000000000',
      close: '57.7800000000000000',
      volume: '413625.1043600000000000',
      quoteVolume: 23841397.7942103,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '57.7800000000000000',
      high: '58.0400000000000000',
      low: '55.8400000000000000',
      close: '56.5900000000000000',
      volume: '198932.2372700000000000',
      quoteVolume: 11276533.6754336,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '56.6300000000000000',
      high: '57.3700000000000000',
      low: '54.0000000000000000',
      close: '54.8200000000000000',
      volume: '319640.8436300000000000',
      quoteVolume: 17619148.0806475,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '54.8200000000000000',
      high: '56.9600000000000000',
      low: '53.7200000000000000',
      close: '56.5800000000000000',
      volume: '186798.9733400000000000',
      quoteVolume: 10473627.53209,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '56.6000000000000000',
      high: '56.8400000000000000',
      low: '54.3000000000000000',
      close: '55.2900000000000000',
      volume: '234206.3592300000000000',
      quoteVolume: 13044765.6089822,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '55.2900000000000000',
      high: '58.2000000000000000',
      low: '51.6600000000000000',
      close: '55.9400000000000000',
      volume: '333686.3963100000000000',
      quoteVolume: 18559101.3738751,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '55.9400000000000000',
      high: '59.1500000000000000',
      low: '55.9400000000000000',
      close: '58.5200000000000000',
      volume: '295307.7767700000000000',
      quoteVolume: 17013433.6384185,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '58.5200000000000000',
      high: '61.9700000000000000',
      low: '58.4100000000000000',
      close: '61.2100000000000000',
      volume: '375314.7926600000000000',
      quoteVolume: 22846386.0282299,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '61.2200000000000000',
      high: '61.3200000000000000',
      low: '59.5700000000000000',
      close: '60.4700000000000000',
      volume: '174833.3191300000000000',
      quoteVolume: 10590199.9608341,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '60.4700000000000000',
      high: '62.6300000000000000',
      low: '58.2000000000000000',
      close: '59.1700000000000000',
      volume: '301146.2139800000000000',
      quoteVolume: 18071146.4905442,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '59.2000000000000000',
      high: '59.9600000000000000',
      low: '58.3300000000000000',
      close: '59.5200000000000000',
      volume: '221995.5983300000000000',
      quoteVolume: 13162394.4379992,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '59.5000000000000000',
      high: '60.7400000000000000',
      low: '56.6400000000000000',
      close: '60.2700000000000000',
      volume: '285413.7934000000000000',
      quoteVolume: 16953291.8433374,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '60.2900000000000000',
      high: '60.6400000000000000',
      low: '56.9800000000000000',
      close: '58.5400000000000000',
      volume: '164946.6053500000000000',
      quoteVolume: 9725257.740012,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '58.5400000000000000',
      high: '59.6000000000000000',
      low: '58.0000000000000000',
      close: '59.2600000000000000',
      volume: '184427.3358700000000000',
      quoteVolume: 10869615.6738316,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '59.2300000000000000',
      high: '61.7600000000000000',
      low: '58.9300000000000000',
      close: '60.8900000000000000',
      volume: '372961.5478100000000000',
      quoteVolume: 22578010.5670285,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '60.8800000000000000',
      high: '60.9000000000000000',
      low: '58.8400000000000000',
      close: '59.8200000000000000',
      volume: '360926.1570600000000000',
      quoteVolume: 21566309.6375968,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '59.8100000000000000',
      high: '60.7000000000000000',
      low: '58.2000000000000000',
      close: '59.0400000000000000',
      volume: '216703.6696800000000000',
      quoteVolume: 12846830.3232689,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '59.0600000000000000',
      high: '59.3500000000000000',
      low: '57.2300000000000000',
      close: '58.7900000000000000',
      volume: '175752.0092500000000000',
      quoteVolume: 10247903.100617,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '58.8100000000000000',
      high: '61.8700000000000000',
      low: '58.5800000000000000',
      close: '61.4400000000000000',
      volume: '260971.7306100000000000',
      quoteVolume: 15803472.9452996,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '61.4300000000000000',
      high: '61.4300000000000000',
      low: '60.1500000000000000',
      close: '60.5000000000000000',
      volume: '160785.5461800000000000',
      quoteVolume: 9744151.8473039,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '60.4800000000000000',
      high: '61.8000000000000000',
      low: '60.1500000000000000',
      close: '61.4500000000000000',
      volume: '200422.3453500000000000',
      quoteVolume: 12200871.3372611,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '61.4100000000000000',
      high: '61.5600000000000000',
      low: '59.5900000000000000',
      close: '60.4500000000000000',
      volume: '161304.2612800000000000',
      quoteVolume: 9780439.5002168,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '60.4500000000000000',
      high: '60.7200000000000000',
      low: '59.8300000000000000',
      close: '60.4800000000000000',
      volume: '120015.8600500000000000',
      quoteVolume: 7234304.2504601,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '60.4700000000000000',
      high: '60.9000000000000000',
      low: '59.6200000000000000',
      close: '60.4500000000000000',
      volume: '129238.6612100000000000',
      quoteVolume: 7810478.4448446,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '60.4500000000000000',
      high: '78.4000000000000000',
      low: '59.9500000000000000',
      close: '76.7800000000000000',
      volume: '805505.1657500000000000',
      quoteVolume: 55327330.1915808,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '76.7600000000000000',
      high: '98.5000000000000000',
      low: '76.2400000000000000',
      close: '85.1900000000000000',
      volume: '1514710.2931300000000000',
      quoteVolume: 130062156.107703,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '85.0200000000000000',
      high: '89.8500000000000000',
      low: '79.8500000000000000',
      close: '84.7000000000000000',
      volume: '775702.6721700000000000',
      quoteVolume: 66127061.9191573,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '84.7100000000000000',
      high: '89.8500000000000000',
      low: '83.6500000000000000',
      close: '88.2400000000000000',
      volume: '366668.9956300000000000',
      quoteVolume: 31984491.3758827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '88.2400000000000000',
      high: '97.5400000000000000',
      low: '85.0000000000000000',
      close: '92.2100000000000000',
      volume: '706789.2345900000000000',
      quoteVolume: 65322880.0905888,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '92.2300000000000000',
      high: '95.4400000000000000',
      low: '88.7400000000000000',
      close: '91.5500000000000000',
      volume: '404878.1342900000000000',
      quoteVolume: 37409101.1554274,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '91.5500000000000000',
      high: '94.0000000000000000',
      low: '85.2000000000000000',
      close: '88.5700000000000000',
      volume: '439909.3384300000000000',
      quoteVolume: 39092277.7488787,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '88.5700000000000000',
      high: '88.7100000000000000',
      low: '82.6100000000000000',
      close: '86.1700000000000000',
      volume: '317748.7851900000000000',
      quoteVolume: 27340412.18294,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '86.1900000000000000',
      high: '91.1000000000000000',
      low: '85.9100000000000000',
      close: '88.1600000000000000',
      volume: '315727.2367600000000000',
      quoteVolume: 27802373.2737036,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '88.1600000000000000',
      high: '88.4500000000000000',
      low: '74.6400000000000000',
      close: '78.8800000000000000',
      volume: '663804.9849800000000000',
      quoteVolume: 52734752.5700692,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '78.9000000000000000',
      high: '80.4300000000000000',
      low: '75.0000000000000000',
      close: '78.4700000000000000',
      volume: '351092.1192400000000000',
      quoteVolume: 27388660.7258404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '78.4300000000000000',
      high: '81.0200000000000000',
      low: '75.5100000000000000',
      close: '77.6200000000000000',
      volume: '276710.7109000000000000',
      quoteVolume: 21674683.2126471,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '77.5800000000000000',
      high: '83.6000000000000000',
      low: '76.0900000000000000',
      close: '83.2800000000000000',
      volume: '242418.4130300000000000',
      quoteVolume: 19179352.734823,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '83.3100000000000000',
      high: '84.4100000000000000',
      low: '75.0000000000000000',
      close: '78.0700000000000000',
      volume: '437925.6544900000000000',
      quoteVolume: 35039724.5357955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '78.0600000000000000',
      high: '81.4900000000000000',
      low: '76.8800000000000000',
      close: '80.2700000000000000',
      volume: '295134.1772800000000000',
      quoteVolume: 23370026.8609958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '80.2700000000000000',
      high: '81.5000000000000000',
      low: '78.0000000000000000',
      close: '78.7700000000000000',
      volume: '232336.8106200000000000',
      quoteVolume: 18452843.5923519,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '78.8200000000000000',
      high: '83.6600000000000000',
      low: '78.7400000000000000',
      close: '81.6800000000000000',
      volume: '328509.7703700000000000',
      quoteVolume: 26614059.4859404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '81.6700000000000000',
      high: '83.1700000000000000',
      low: '79.4800000000000000',
      close: '82.0500000000000000',
      volume: '265365.4938300000000000',
      quoteVolume: 21606124.7509276,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '82.0500000000000000',
      high: '82.6900000000000000',
      low: '79.3300000000000000',
      close: '81.1500000000000000',
      volume: '209481.5475900000000000',
      quoteVolume: 17009020.0094766,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '81.1600000000000000',
      high: '81.8500000000000000',
      low: '72.8600000000000000',
      close: '76.6500000000000000',
      volume: '405449.3565200000000000',
      quoteVolume: 30882393.8202287,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '76.6100000000000000',
      high: '77.6300000000000000',
      low: '74.9000000000000000',
      close: '76.5100000000000000',
      volume: '285737.8388600000000000',
      quoteVolume: 21814764.7461896,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '76.4700000000000000',
      high: '79.2900000000000000',
      low: '73.3300000000000000',
      close: '73.7000000000000000',
      volume: '408341.2265500000000000',
      quoteVolume: 31534100.588167,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '73.6700000000000000',
      high: '74.6900000000000000',
      low: '69.9000000000000000',
      close: '72.8500000000000000',
      volume: '466422.9092200000000000',
      quoteVolume: 33586009.2516322,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '72.8500000000000000',
      high: '74.4300000000000000',
      low: '70.6000000000000000',
      close: '71.7800000000000000',
      volume: '307408.7038600000000000',
      quoteVolume: 22353656.7217245,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '71.7900000000000000',
      high: '76.5000000000000000',
      low: '70.3100000000000000',
      close: '73.8200000000000000',
      volume: '345014.8767600000000000',
      quoteVolume: 25320328.5480487,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '73.8300000000000000',
      high: '74.0100000000000000',
      low: '71.8300000000000000',
      close: '72.2300000000000000',
      volume: '102336.9821700000000000',
      quoteVolume: 7467848.3762795,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '72.2400000000000000',
      high: '72.7200000000000000',
      low: '68.9500000000000000',
      close: '69.8900000000000000',
      volume: '218635.5895900000000000',
      quoteVolume: 15458571.1783797,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '69.8900000000000000',
      high: '70.3700000000000000',
      low: '66.5000000000000000',
      close: '67.8700000000000000',
      volume: '203603.1860100000000000',
      quoteVolume: 13896456.9662964,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '67.8600000000000000',
      high: '74.7400000000000000',
      low: '67.8600000000000000',
      close: '74.3200000000000000',
      volume: '354469.1353800000000000',
      quoteVolume: 25357609.0003327,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '74.3100000000000000',
      high: '75.3600000000000000',
      low: '71.7800000000000000',
      close: '73.3600000000000000',
      volume: '273831.0476500000000000',
      quoteVolume: 20098972.4966065,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '73.3500000000000000',
      high: '74.4200000000000000',
      low: '72.7300000000000000',
      close: '73.6800000000000000',
      volume: '289626.4536600000000000',
      quoteVolume: 21306943.7993429,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '73.6500000000000000',
      high: '80.5800000000000000',
      low: '73.3100000000000000',
      close: '79.2200000000000000',
      volume: '538448.4052300000000000',
      quoteVolume: 41683556.4117655,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '79.2600000000000000',
      high: '82.7600000000000000',
      low: '74.5400000000000000',
      close: '77.7700000000000000',
      volume: '457004.0148400000000000',
      quoteVolume: 35750061.7116449,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '77.7300000000000000',
      high: '78.1600000000000000',
      low: '75.4000000000000000',
      close: '75.7000000000000000',
      volume: '236821.3124500000000000',
      quoteVolume: 18139200.7639219,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '75.7000000000000000',
      high: '76.9100000000000000',
      low: '71.6400000000000000',
      close: '74.9400000000000000',
      volume: '389445.4803700000000000',
      quoteVolume: 28863797.8869894,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '74.9500000000000000',
      high: '78.7500000000000000',
      low: '73.4000000000000000',
      close: '74.6100000000000000',
      volume: '357677.9279500000000000',
      quoteVolume: 27275279.8649669,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '74.5900000000000000',
      high: '75.5000000000000000',
      low: '72.4000000000000000',
      close: '74.4400000000000000',
      volume: '206081.7854800000000000',
      quoteVolume: 15267753.6578615,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '74.4400000000000000',
      high: '75.9000000000000000',
      low: '72.7700000000000000',
      close: '74.1900000000000000',
      volume: '240477.4366700000000000',
      quoteVolume: 17829294.3569958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '74.1800000000000000',
      high: '79.6100000000000000',
      low: '72.6600000000000000',
      close: '77.1100000000000000',
      volume: '422915.9252000000000000',
      quoteVolume: 32199715.1352361,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '77.1200000000000000',
      high: '95.0000000000000000',
      low: '76.8500000000000000',
      close: '86.5900000000000000',
      volume: '909602.1269400000000000',
      quoteVolume: 78091222.1082642,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '86.5900000000000000',
      high: '94.0100000000000000',
      low: '83.0000000000000000',
      close: '84.7300000000000000',
      volume: '797262.8597300000000000',
      quoteVolume: 69998434.01864,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '84.7100000000000000',
      high: '92.4900000000000000',
      low: '84.1000000000000000',
      close: '87.1600000000000000',
      volume: '627311.3557300000000000',
      quoteVolume: 55391993.134908,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '87.3400000000000000',
      high: '93.6100000000000000',
      low: '86.5000000000000000',
      close: '91.1700000000000000',
      volume: '644139.0646700000000000',
      quoteVolume: 58186818.859561,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '91.1500000000000000',
      high: '104.2500000000000000',
      low: '89.6900000000000000',
      close: '102.0200000000000000',
      volume: '673429.9261200000000000',
      quoteVolume: 66429508.1788531,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '102.0300000000000000',
      high: '107.1400000000000000',
      low: '90.7300000000000000',
      close: '95.4900000000000000',
      volume: '1179885.1078400000000000',
      quoteVolume: 116020063.777305,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '95.4900000000000000',
      high: '97.4800000000000000',
      low: '84.2700000000000000',
      close: '89.7600000000000000',
      volume: '914179.2254800000000000',
      quoteVolume: 80856683.9611846,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '89.7600000000000000',
      high: '90.5100000000000000',
      low: '85.1100000000000000',
      close: '86.5100000000000000',
      volume: '380542.1429700000000000',
      quoteVolume: 33352370.4500154,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '86.5500000000000000',
      high: '96.8100000000000000',
      low: '86.0700000000000000',
      close: '94.5800000000000000',
      volume: '584649.5175900000000000',
      quoteVolume: 54045051.6228448,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '94.5500000000000000',
      high: '94.5800000000000000',
      low: '87.1100000000000000',
      close: '91.1000000000000000',
      volume: '447735.7161500000000000',
      quoteVolume: 40656121.4410844,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '91.1000000000000000',
      high: '93.0200000000000000',
      low: '88.8300000000000000',
      close: '90.7900000000000000',
      volume: '335538.2330900000000000',
      quoteVolume: 30483197.1255505,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '90.7700000000000000',
      high: '92.0400000000000000',
      low: '85.8300000000000000',
      close: '87.7400000000000000',
      volume: '308520.2910900000000000',
      quoteVolume: 27641752.0456785,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '87.7400000000000000',
      high: '89.5300000000000000',
      low: '84.6200000000000000',
      close: '88.6100000000000000',
      volume: '208332.2952500000000000',
      quoteVolume: 18146600.071508,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '88.6700000000000000',
      high: '103.7700000000000000',
      low: '88.0100000000000000',
      close: '100.5000000000000000',
      volume: '832985.1612500000000000',
      quoteVolume: 81794059.9838869,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '100.5000000000000000',
      high: '105.0700000000000000',
      low: '98.8700000000000000',
      close: '102.6600000000000000',
      volume: '337657.9218500000000000',
      quoteVolume: 34485049.017298095,
      btcVolume: 4312.521556865614,
      usdVolume: 34485049.017298095,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '102.6600000000000000',
      high: '114.0000000000000000',
      low: '97.5000000000000000',
      close: '111.0000000000000000',
      volume: '447812.7599800000000000',
      quoteVolume: 46913902.85878797,
      btcVolume: 5689.1831608019,
      usdVolume: 46913902.85878797,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '111.0300000000000000',
      high: '121.8900000000000000',
      low: '109.9400000000000000',
      close: '116.2700000000000000',
      volume: '595659.2299900000000000',
      quoteVolume: 68097250.03244157,
      btcVolume: 7799.762188822423,
      usdVolume: 68097250.03244157,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '116.3000000000000000',
      high: '118.0000000000000000',
      low: '111.1100000000000000',
      close: '114.8100000000000000',
      volume: '230852.9642500000000000',
      quoteVolume: 26424422.174400397,
      btcVolume: 3034.2155133217652,
      usdVolume: 26424422.174400397,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '114.8700000000000000',
      high: '118.3300000000000000',
      low: '108.0000000000000000',
      close: '114.5300000000000000',
      volume: '220738.2597100000000000',
      quoteVolume: 25195330.963828564,
      btcVolume: 2920.4957713299823,
      usdVolume: 25195330.963828564,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '115.0500000000000000',
      high: '120.3500000000000000',
      low: '102.4000000000000000',
      close: '108.2100000000000000',
      volume: '507093.9330100000000000',
      quoteVolume: 57087056.14430182,
      btcVolume: 6672.278677065912,
      usdVolume: 57087056.14430182,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '107.4100000000000000',
      high: '114.9300000000000000',
      low: '104.5600000000000000',
      close: '114.3500000000000000',
      volume: '312792.4647700000000000',
      quoteVolume: 34046399.04506248,
      btcVolume: 4077.7128007930464,
      usdVolume: 34046399.04506248,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '114.8000000000000000',
      high: '116.1000000000000000',
      low: '110.5100000000000000',
      close: '112.3500000000000000',
      volume: '223549.1279200000000000',
      quoteVolume: 25341433.203971107,
      btcVolume: 2969.5187987480676,
      usdVolume: 25341433.203971107,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '112.3400000000000000',
      high: '116.4800000000000000',
      low: '111.2500000000000000',
      close: '114.3900000000000000',
      volume: '233743.1953000000000000',
      quoteVolume: 26688762.017144043,
      btcVolume: 3077.4531043299494,
      usdVolume: 26688762.017144043,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '114.4400000000000000',
      high: '116.2000000000000000',
      low: '105.0100000000000000',
      close: '105.9900000000000000',
      volume: '426880.8260100000000000',
      quoteVolume: 47600822.28424786,
      btcVolume: 5611.544120917245,
      usdVolume: 47600822.28424786,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '105.9900000000000000',
      high: '106.8000000000000000',
      low: '98.1300000000000000',
      close: '102.2000000000000000',
      volume: '673205.5704500000000000',
      quoteVolume: 69225543.10550998,
      btcVolume: 8824.314357109533,
      usdVolume: 69225543.10550998,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '102.2000000000000000',
      high: '105.2100000000000000',
      low: '100.1100000000000000',
      close: '103.6600000000000000',
      volume: '379894.4448300000000000',
      quoteVolume: 39022877.25634307,
      btcVolume: 5026.87552023653,
      usdVolume: 39022877.25634307,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '103.6100000000000000',
      high: '112.0700000000000000',
      low: '101.0000000000000000',
      close: '111.1900000000000000',
      volume: '488706.9407100000000000',
      quoteVolume: 51465078.74484297,
      btcVolume: 6669.936853023607,
      usdVolume: 51465078.74484297,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '111.2500000000000000',
      high: '121.0000000000000000',
      low: '109.5300000000000000',
      close: '116.7100000000000000',
      volume: '619673.8448900000000000',
      quoteVolume: 71546455.96240085,
      btcVolume: 9005.230709084188,
      usdVolume: 71546455.96240085,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '116.7400000000000000',
      high: '119.7000000000000000',
      low: '113.5000000000000000',
      close: '118.0100000000000000',
      volume: '454619.1137200000000000',
      quoteVolume: 52877073.47281,
      btcVolume: 6681.652583502133,
      usdVolume: 52877073.47281,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '118.0100000000000000',
      high: '118.9400000000000000',
      low: '111.0200000000000000',
      close: '114.6600000000000000',
      volume: '425242.5817000000000000',
      quoteVolume: 48979562.40265552,
      btcVolume: 6336.33411727269,
      usdVolume: 48979562.40265552,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '114.6600000000000000',
      high: '130.1200000000000000',
      low: '112.5400000000000000',
      close: '129.1600000000000000',
      volume: '793131.5001000000000000',
      quoteVolume: 97858566.95458615,
      btcVolume: 12435.906067485123,
      usdVolume: 97858566.95458615,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '129.1500000000000000',
      high: '138.5600000000000000',
      low: '124.8500000000000000',
      close: '135.6900000000000000',
      volume: '832736.8101700000000000',
      quoteVolume: 108840543.08616316,
      btcVolume: 13831.644364595131,
      usdVolume: 108840543.08616316,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '135.7900000000000000',
      high: '143.1900000000000000',
      low: '129.8600000000000000',
      close: '135.3200000000000000',
      volume: '1013528.9184000000000000',
      quoteVolume: 139315210.82220733,
      btcVolume: 17393.1824521332,
      usdVolume: 139315210.82220733,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '135.3200000000000000',
      high: '136.5200000000000000',
      low: '127.9200000000000000',
      close: '130.5500000000000000',
      volume: '477007.3052300000000000',
      quoteVolume: 63425735.66279704,
      btcVolume: 7773.751516352979,
      usdVolume: 63425735.66279704,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '130.5500000000000000',
      high: '133.0300000000000000',
      low: '125.2200000000000000',
      close: '132.0000000000000000',
      volume: '460272.4765100000000000',
      quoteVolume: 59616339.318167776,
      btcVolume: 7149.36358649792,
      usdVolume: 59616339.318167776,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '132.0300000000000000',
      high: '139.5000000000000000',
      low: '132.0100000000000000',
      close: '137.5400000000000000',
      volume: '389609.6416600000000000',
      quoteVolume: 52876679.67863012,
      btcVolume: 6089.583260704667,
      usdVolume: 52876679.67863012,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '137.5100000000000000',
      high: '138.2000000000000000',
      low: '133.1500000000000000',
      close: '136.4600000000000000',
      volume: '392160.2389100000000000',
      quoteVolume: 53217106.12389209,
      btcVolume: 5876.901296371626,
      usdVolume: 53217106.12389209,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '136.4500000000000000',
      high: '136.8200000000000000',
      low: '131.5300000000000000',
      close: '133.8000000000000000',
      volume: '302482.8519500000000000',
      quoteVolume: 40631956.395158365,
      btcVolume: 4412.679894293252,
      usdVolume: 40631956.395158365,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '133.8000000000000000',
      high: '136.8400000000000000',
      low: '128.0000000000000000',
      close: '135.2200000000000000',
      volume: '400793.1621100000000000',
      quoteVolume: 53271407.49490323,
      btcVolume: 5834.779980955947,
      usdVolume: 53232164.38223043,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '135.2500000000000000',
      high: '139.5000000000000000',
      low: '133.5000000000000000',
      close: '136.4300000000000000',
      volume: '373848.4380200000000000',
      quoteVolume: 51215339.67678405,
      btcVolume: 5598.390589121898,
      usdVolume: 51215339.67678405,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '136.4100000000000000',
      high: '136.7500000000000000',
      low: '133.0000000000000000',
      close: '135.4600000000000000',
      volume: '226900.6498000000000000',
      quoteVolume: 30613327.430043694,
      btcVolume: 3276.5296546410664,
      usdVolume: 30613327.430043694,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '135.4500000000000000',
      high: '141.2900000000000000',
      low: '131.0000000000000000',
      close: '138.2400000000000000',
      volume: '392322.7834100000000000',
      quoteVolume: 53942344.98886258,
      btcVolume: 5520.032818796717,
      usdVolume: 53942344.98886258,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '138.2400000000000000',
      high: '145.9000000000000000',
      low: '136.0100000000000000',
      close: '142.2600000000000000',
      volume: '663584.3836600000000000',
      quoteVolume: 93771724.38599907,
      btcVolume: 8742.486600283723,
      usdVolume: 93771724.38599907,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '142.2000000000000000',
      high: '143.3400000000000000',
      low: '136.1500000000000000',
      close: '137.5000000000000000',
      volume: '272572.6124300000000000',
      quoteVolume: 38252959.48642319,
      btcVolume: 3516.3861683369537,
      usdVolume: 38252959.48642319,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '137.5000000000000000',
      high: '137.5200000000000000',
      low: '132.6500000000000000',
      close: '135.7700000000000000',
      volume: '269139.9196400000000000',
      quoteVolume: 36393766.969366886,
      btcVolume: 3342.376735778028,
      usdVolume: 36393766.969366886,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '135.7800000000000000',
      high: '138.6300000000000000',
      low: '129.7500000000000000',
      close: '136.2900000000000000',
      volume: '342770.9536500000000000',
      quoteVolume: 46148912.96185091,
      btcVolume: 4050.110257770818,
      usdVolume: 46148912.96185091,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '136.3300000000000000',
      high: '138.5000000000000000',
      low: '127.5000000000000000',
      close: '132.2500000000000000',
      volume: '636720.2570400000000000',
      quoteVolume: 86027810.12377585,
      btcVolume: 6747.88318704931,
      usdVolume: 86027810.12377585,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '132.2400000000000000',
      high: '133.5000000000000000',
      low: '111.8200000000000000',
      close: '116.0100000000000000',
      volume: '925760.4631400000000000',
      quoteVolume: 110853611.31913063,
      btcVolume: 9337.051184083171,
      usdVolume: 110853611.31913063,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '116.0200000000000000',
      high: '122.1900000000000000',
      low: '114.4900000000000000',
      close: '120.0100000000000000',
      volume: '455533.6026900000000000',
      quoteVolume: 53918279.744928494,
      btcVolume: 4582.2478046452115,
      usdVolume: 53918279.744928494,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '119.9900000000000000',
      high: '137.0000000000000000',
      low: '116.0000000000000000',
      close: '133.8600000000000000',
      volume: '650765.6611200000000000',
      quoteVolume: 82923808.74529916,
      btcVolume: 6965.579657418369,
      usdVolume: 82923808.74529916,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '133.8800000000000000',
      high: '137.4400000000000000',
      low: '120.5400000000000000',
      close: '123.2000000000000000',
      volume: '808624.4477500000000000',
      quoteVolume: 105465556.33002208,
      btcVolume: 9174.964797036842,
      usdVolume: 105465556.33002208,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '123.2100000000000000',
      high: '127.0300000000000000',
      low: '113.8200000000000000',
      close: '123.1700000000000000',
      volume: '612082.5504700000000000',
      quoteVolume: 74031245.77021483,
      btcVolume: 6937.17995542507,
      usdVolume: 74031245.77021483,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '123.1600000000000000',
      high: '124.0000000000000000',
      low: '112.6300000000000000',
      close: '118.7700000000000000',
      volume: '468821.0334100000000000',
      quoteVolume: 54961125.00467627,
      btcVolume: 5357.843844817624,
      usdVolume: 54961125.00467627,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '118.7400000000000000',
      high: '123.1500000000000000',
      low: '116.7800000000000000',
      close: '121.3600000000000000',
      volume: '365004.8130000000000000',
      quoteVolume: 43802919.31616289,
      btcVolume: 3864.830877205342,
      usdVolume: 43802919.31616289,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '121.3400000000000000',
      high: '127.0300000000000000',
      low: '118.5100000000000000',
      close: '119.6100000000000000',
      volume: '374342.5657700000000000',
      quoteVolume: 45835115.28303999,
      btcVolume: 3922.4531882102947,
      usdVolume: 45835115.28303999,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '119.6400000000000000',
      high: '120.9900000000000000',
      low: '116.2200000000000000',
      close: '118.3500000000000000',
      volume: '223506.3921400000000000',
      quoteVolume: 26561837.46533212,
      btcVolume: 2393.4466400120245,
      usdVolume: 26561837.46533212,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '118.2900000000000000',
      high: '121.8000000000000000',
      low: '117.0600000000000000',
      close: '118.3100000000000000',
      volume: '153807.7581500000000000',
      quoteVolume: 18322698.17243,
      btcVolume: 1610.505545271505,
      usdVolume: 18322698.17243,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '118.2800000000000000',
      high: '120.3500000000000000',
      low: '117.0000000000000000',
      close: '119.4800000000000000',
      volume: '142378.6142900000000000',
      quoteVolume: 16885095.364536773,
      btcVolume: 1493.6997705844158,
      usdVolume: 16885095.364536773,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '119.4800000000000000',
      high: '125.0700000000000000',
      low: '117.7700000000000000',
      close: '122.9200000000000000',
      volume: '217712.8518000000000000',
      quoteVolume: 26152584.18533981,
      btcVolume: 2213.2029853237336,
      usdVolume: 26152584.18533981,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '122.9200000000000000',
      high: '124.0800000000000000',
      low: '117.6400000000000000',
      close: '119.2400000000000000',
      volume: '229937.7747500000000000',
      quoteVolume: 27739458.330567557,
      btcVolume: 2229.1310227514605,
      usdVolume: 27739458.330567557,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '119.2400000000000000',
      high: '120.1300000000000000',
      low: '102.5000000000000000',
      close: '108.3100000000000000',
      volume: '505822.3816400000000000',
      quoteVolume: 55612005.274497144,
      btcVolume: 4459.300542159586,
      usdVolume: 55612005.274497144,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '108.2900000000000000',
      high: '108.3300000000000000',
      low: '95.2100000000000000',
      close: '102.9800000000000000',
      volume: '559700.8997700000000000',
      quoteVolume: 56859127.19466299,
      btcVolume: 4940.505976488649,
      usdVolume: 56859127.19466299,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '103.0000000000000000',
      high: '107.0200000000000000',
      low: '100.6500000000000000',
      close: '104.8300000000000000',
      volume: '204844.2164100000000000',
      quoteVolume: 21371324.036817204,
      btcVolume: 1850.9778044022698,
      usdVolume: 21371324.036817204,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '104.8400000000000000',
      high: '105.0300000000000000',
      low: '97.6400000000000000',
      close: '101.0300000000000000',
      volume: '229334.5614600000000000',
      quoteVolume: 23264553.92123755,
      btcVolume: 2051.3447663181432,
      usdVolume: 23264553.92123755,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '101.0600000000000000',
      high: '101.9000000000000000',
      low: '87.5800000000000000',
      close: '89.7300000000000000',
      volume: '423313.0351200000000000',
      quoteVolume: 39325264.58610615,
      btcVolume: 3681.02471090942,
      usdVolume: 39325264.58610615,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '89.6800000000000000',
      high: '92.9900000000000000',
      low: '84.6000000000000000',
      close: '90.4300000000000000',
      volume: '382007.6629800000000000',
      quoteVolume: 34014962.47825661,
      btcVolume: 3290.421882815431,
      usdVolume: 34014962.47825661,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '90.4300000000000000',
      high: '91.9100000000000000',
      low: '76.6000000000000000',
      close: '79.5600000000000000',
      volume: '476050.7487700000000000',
      quoteVolume: 39592816.252067514,
      btcVolume: 3948.301710139775,
      usdVolume: 39592816.252067514,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '79.5700000000000000',
      high: '94.6500000000000000',
      low: '71.0000000000000000',
      close: '89.9500000000000000',
      volume: '611087.7265300000000000',
      quoteVolume: 53122108.969450906,
      btcVolume: 5523.905454198043,
      usdVolume: 53122108.969450906,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '89.9800000000000000',
      high: '104.5000000000000000',
      low: '86.8700000000000000',
      close: '101.1600000000000000',
      volume: '608935.5958700000000000',
      quoteVolume: 58255818.192272015,
      btcVolume: 5773.175422048092,
      usdVolume: 58215321.1838005,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '101.1500000000000000',
      high: '101.2600000000000000',
      low: '94.7300000000000000',
      close: '98.2300000000000000',
      volume: '364731.5720400000000000',
      quoteVolume: 35601483.447144754,
      btcVolume: 3411.5750330938563,
      usdVolume: 35601483.447144754,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '98.2500000000000000',
      high: '105.8800000000000000',
      low: '97.4200000000000000',
      close: '100.2500000000000000',
      volume: '355101.6718200000000000',
      quoteVolume: 35894490.36556177,
      btcVolume: 3363.2675353843997,
      usdVolume: 35894490.36556177,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '100.2600000000000000',
      high: '101.4100000000000000',
      low: '96.4000000000000000',
      close: '99.7000000000000000',
      volume: '247040.7271900000000000',
      quoteVolume: 24369602.513854142,
      btcVolume: 2307.538644188406,
      usdVolume: 24369602.513854142,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '99.7400000000000000',
      high: '100.9100000000000000',
      low: '91.1400000000000000',
      close: '95.3100000000000000',
      volume: '316815.0448700000000000',
      quoteVolume: 30213787.40266795,
      btcVolume: 2901.236765354251,
      usdVolume: 30213787.40266795,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '95.3100000000000000',
      high: '95.3500000000000000',
      low: '88.6200000000000000',
      close: '90.1500000000000000',
      volume: '264516.2157400000000000',
      quoteVolume: 24185689.349569995,
      btcVolume: 2407.8084257111295,
      usdVolume: 24185689.349569995,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '90.1000000000000000',
      high: '97.4500000000000000',
      low: '86.8000000000000000',
      close: '94.5000000000000000',
      volume: '354766.4814900000000000',
      quoteVolume: 32545800.829587497,
      btcVolume: 3344.4513821679425,
      usdVolume: 32545800.829587497,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '94.4300000000000000',
      high: '96.6700000000000000',
      low: '91.0600000000000000',
      close: '93.2200000000000000',
      volume: '228174.7700300000000000',
      quoteVolume: 21502628.636138897,
      btcVolume: 2150.016682373243,
      usdVolume: 21502628.636138897,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '93.2300000000000000',
      high: '94.5200000000000000',
      low: '89.8300000000000000',
      close: '94.0600000000000000',
      volume: '169001.4772100000000000',
      quoteVolume: 15639015.230352713,
      btcVolume: 1602.9055728539722,
      usdVolume: 15639015.230352713,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '94.0100000000000000',
      high: '96.0900000000000000',
      low: '86.6700000000000000',
      close: '88.7900000000000000',
      volume: '246284.6023800000000000',
      quoteVolume: 22269324.203205597,
      btcVolume: 2305.886333888476,
      usdVolume: 22269324.203205597,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '88.8300000000000000',
      high: '90.9000000000000000',
      low: '83.1300000000000000',
      close: '89.9300000000000000',
      volume: '196819.9021300000000000',
      quoteVolume: 17358318.803394448,
      btcVolume: 1837.2083760848138,
      usdVolume: 17358318.803394448,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '89.9700000000000000',
      high: '92.2600000000000000',
      low: '87.4800000000000000',
      close: '90.3300000000000000',
      volume: '232648.9633600000000000',
      quoteVolume: 20980770.098834805,
      btcVolume: 2194.949487131651,
      usdVolume: 20980770.098834805,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '90.3300000000000000',
      high: '92.7100000000000000',
      low: '88.5000000000000000',
      close: '90.1500000000000000',
      volume: '132728.7110700000000000',
      quoteVolume: 11999589.695907073,
      btcVolume: 1256.76640132148,
      usdVolume: 11999589.695907073,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '90.1200000000000000',
      high: '99.9500000000000000',
      low: '89.8100000000000000',
      close: '98.7600000000000000',
      volume: '280442.0190700000000000',
      quoteVolume: 26802062.262311406,
      btcVolume: 2708.4298976866316,
      usdVolume: 26802062.262311406,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '98.7700000000000000',
      high: '100.5900000000000000',
      low: '95.2300000000000000',
      close: '98.6400000000000000',
      volume: '256621.8002700000000000',
      quoteVolume: 25131509.021462236,
      btcVolume: 2491.621683425381,
      usdVolume: 25131509.021462236,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '98.6200000000000000',
      high: '98.7700000000000000',
      low: '92.5200000000000000',
      close: '94.7600000000000000',
      volume: '284185.5684900000000000',
      quoteVolume: 27263314.77004936,
      btcVolume: 2606.5500325395974,
      usdVolume: 27263314.77004936,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '94.8000000000000000',
      high: '96.7000000000000000',
      low: '93.5100000000000000',
      close: '94.1700000000000000',
      volume: '187442.2663600000000000',
      quoteVolume: 17836682.37128238,
      btcVolume: 1658.7354555809563,
      usdVolume: 17836682.37128238,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '94.1500000000000000',
      high: '94.8000000000000000',
      low: '90.1100000000000000',
      close: '92.6400000000000000',
      volume: '209831.4188500000000000',
      quoteVolume: 19390235.069303315,
      btcVolume: 1799.4440000625016,
      usdVolume: 19390235.069303315,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '92.6800000000000000',
      high: '106.7700000000000000',
      low: '92.6800000000000000',
      close: '96.6100000000000000',
      volume: '707982.8941800000000000',
      quoteVolume: 70027160.96589696,
      btcVolume: 5999.5126965229465,
      usdVolume: 70027160.96589696,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '96.5600000000000000',
      high: '99.3000000000000000',
      low: '91.0000000000000000',
      close: '92.9900000000000000',
      volume: '363691.9904700000000000',
      quoteVolume: 34611183.819958195,
      btcVolume: 2931.27156672291,
      usdVolume: 34611183.819958195,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '93.0500000000000000',
      high: '94.4500000000000000',
      low: '87.3600000000000000',
      close: '90.5500000000000000',
      volume: '295995.6655100000000000',
      quoteVolume: 26997231.240942493,
      btcVolume: 2299.5786668850474,
      usdVolume: 26997231.240942493,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '90.5900000000000000',
      high: '92.7500000000000000',
      low: '87.7200000000000000',
      close: '90.1400000000000000',
      volume: '226973.5770400000000000',
      quoteVolume: 20399520.9403848,
      btcVolume: 1729.0445142042847,
      usdVolume: 20399520.9403848,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '90.1400000000000000',
      high: '90.4500000000000000',
      low: '82.6400000000000000',
      close: '84.3300000000000000',
      volume: '244679.6808500000000000',
      quoteVolume: 21052903.37981216,
      btcVolume: 1782.1537264855651,
      usdVolume: 21052903.37981216,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '84.2500000000000000',
      high: '87.5000000000000000',
      low: '82.2700000000000000',
      close: '85.4600000000000000',
      volume: '240013.8068600000000000',
      quoteVolume: 20434411.59965533,
      btcVolume: 1765.6610402914791,
      usdVolume: 20434411.59965533,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '85.4600000000000000',
      high: '91.5700000000000000',
      low: '85.1500000000000000',
      close: '89.9400000000000000',
      volume: '196224.6058500000000000',
      quoteVolume: 17374539.532690413,
      btcVolume: 1524.9630326426839,
      usdVolume: 17374539.532690413,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '89.9200000000000000',
      high: '90.0400000000000000',
      low: '85.4500000000000000',
      close: '85.7900000000000000',
      volume: '148132.4291500000000000',
      quoteVolume: 12892549.668831954,
      btcVolume: 1130.0638225098155,
      usdVolume: 12892549.668831954,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '85.7900000000000000',
      high: '86.7900000000000000',
      low: '83.2600000000000000',
      close: '84.8800000000000000',
      volume: '166783.8095700000000000',
      quoteVolume: 14166418.159683961,
      btcVolume: 1274.1904971222834,
      usdVolume: 14166418.159683961,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '84.8300000000000000',
      high: '86.3200000000000000',
      low: '75.0000000000000000',
      close: '75.9000000000000000',
      volume: '301247.4537300000000000',
      quoteVolume: 24226106.115131296,
      btcVolume: 2325.4823677043105,
      usdVolume: 24226106.115131296,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '75.9100000000000000',
      high: '77.7000000000000000',
      low: '74.3600000000000000',
      close: '76.2500000000000000',
      volume: '155744.5086700000000000',
      quoteVolume: 11881666.830015346,
      btcVolume: 1170.7221866608465,
      usdVolume: 11881666.830015346,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '76.2400000000000000',
      high: '76.4200000000000000',
      low: '72.3400000000000000',
      close: '74.6700000000000000',
      volume: '186709.0272800000000000',
      quoteVolume: 13900659.419270849,
      btcVolume: 1370.0754829605883,
      usdVolume: 13900659.419270849,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '74.6300000000000000',
      high: '75.3500000000000000',
      low: '71.5000000000000000',
      close: '72.6500000000000000',
      volume: '131037.3773100000000000',
      quoteVolume: 9606480.326331407,
      btcVolume: 935.0616632613101,
      usdVolume: 9606480.326331407,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '72.6400000000000000',
      high: '78.0000000000000000',
      low: '71.4000000000000000',
      close: '76.2500000000000000',
      volume: '171758.4529300000000000',
      quoteVolume: 12942681.697281959,
      btcVolume: 1254.3177248522557,
      usdVolume: 12942681.697281959,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '76.3100000000000000',
      high: '78.9500000000000000',
      low: '75.3600000000000000',
      close: '77.1000000000000000',
      volume: '176320.8779000000000000',
      quoteVolume: 13602703.9572976,
      btcVolume: 1278.3583112223776,
      usdVolume: 13602703.9572976,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '77.1000000000000000',
      high: '77.3000000000000000',
      low: '73.3100000000000000',
      close: '75.1400000000000000',
      volume: '130272.1040200000000000',
      quoteVolume: 9809533.243689496,
      btcVolume: 914.3887166901854,
      usdVolume: 9809533.243689496,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '75.1300000000000000',
      high: '75.3300000000000000',
      low: '70.1400000000000000',
      close: '73.2700000000000000',
      volume: '188369.4364000000000000',
      quoteVolume: 13677422.750244448,
      btcVolume: 1345.3220954490534,
      usdVolume: 13677422.750244448,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '73.1800000000000000',
      high: '74.6400000000000000',
      low: '71.0000000000000000',
      close: '73.3100000000000000',
      volume: '159574.3573900000000000',
      quoteVolume: 11682660.11134095,
      btcVolume: 1163.5406280504114,
      usdVolume: 11682660.11134095,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '73.3800000000000000',
      high: '75.9200000000000000',
      low: '72.1000000000000000',
      close: '75.3800000000000000',
      volume: '126538.7728700000000000',
      quoteVolume: 9392367.036347631,
      btcVolume: 914.8262129553217,
      usdVolume: 9392367.036347631,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '75.3700000000000000',
      high: '75.6500000000000000',
      low: '71.3900000000000000',
      close: '73.3200000000000000',
      volume: '119835.6546400000000000',
      quoteVolume: 8766693.140755767,
      btcVolume: 866.621719030702,
      usdVolume: 8766693.140755767,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '73.3200000000000000',
      high: '74.9300000000000000',
      low: '70.5600000000000000',
      close: '72.1000000000000000',
      volume: '141416.0203900000000000',
      quoteVolume: 10265920.916051323,
      btcVolume: 1018.3454425401993,
      usdVolume: 10265920.916051323,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '72.1200000000000000',
      high: '75.4600000000000000',
      low: '72.0900000000000000',
      close: '73.5400000000000000',
      volume: '183788.5068600000000000',
      quoteVolume: 13607022.423831994,
      btcVolume: 1313.6613691337795,
      usdVolume: 13607022.423831994,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '73.5900000000000000',
      high: '73.7500000000000000',
      low: '72.1300000000000000',
      close: '72.9000000000000000',
      volume: '92746.0175100000000000',
      quoteVolume: 6757231.102277973,
      btcVolume: 662.8619923714382,
      usdVolume: 6757231.102277973,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '72.8800000000000000',
      high: '73.1400000000000000',
      low: '65.0000000000000000',
      close: '66.7300000000000000',
      volume: '279997.3057300000000000',
      quoteVolume: 19218269.137130044,
      btcVolume: 1926.7857817998536,
      usdVolume: 19218269.137130044,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '66.7300000000000000',
      high: '66.7700000000000000',
      low: '62.0000000000000000',
      close: '63.5900000000000000',
      volume: '206125.3285400000000000',
      quoteVolume: 13327397.63727353,
      btcVolume: 1401.9829055159644,
      usdVolume: 13327397.63727353,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '63.5500000000000000',
      high: '65.6000000000000000',
      low: '62.1600000000000000',
      close: '64.1700000000000000',
      volume: '188502.6473100000000000',
      quoteVolume: 12025107.367332652,
      btcVolume: 1260.478351604105,
      usdVolume: 12025107.367332652,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '64.1800000000000000',
      high: '65.3900000000000000',
      low: '62.2500000000000000',
      close: '64.2400000000000000',
      volume: '148167.1587700000000000',
      quoteVolume: 9443772.20454113,
      btcVolume: 985.5488740690859,
      usdVolume: 9443772.20454113,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '64.2300000000000000',
      high: '67.2100000000000000',
      low: '63.7400000000000000',
      close: '65.8900000000000000',
      volume: '160360.7877400000000000',
      quoteVolume: 10473417.107566353,
      btcVolume: 1088.2357260615822,
      usdVolume: 10473417.107566353,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '65.8500000000000000',
      high: '68.0300000000000000',
      low: '64.8000000000000000',
      close: '66.9200000000000000',
      volume: '210394.3789600000000000',
      quoteVolume: 13944978.885087235,
      btcVolume: 1390.3786949813993,
      usdVolume: 13944978.885087235,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '66.9200000000000000',
      high: '71.0000000000000000',
      low: '66.4700000000000000',
      close: '69.0600000000000000',
      volume: '285573.8842600000000000',
      quoteVolume: 19591536.12131445,
      btcVolume: 1861.1902617597352,
      usdVolume: 19591536.12131445,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '69.0000000000000000',
      high: '69.2400000000000000',
      low: '66.4000000000000000',
      close: '67.0100000000000000',
      volume: '219414.7318900000000000',
      quoteVolume: 14835718.502787702,
      btcVolume: 1407.2822863278584,
      usdVolume: 14835718.502787702,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '67.0200000000000000',
      high: '67.3900000000000000',
      low: '64.1400000000000000',
      close: '65.2400000000000000',
      volume: '165280.2625800000000000',
      quoteVolume: 10833905.336375384,
      btcVolume: 1026.921360854257,
      usdVolume: 10833905.336375384,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '65.2300000000000000',
      high: '68.0000000000000000',
      low: '63.5400000000000000',
      close: '64.8700000000000000',
      volume: '269355.4199300000000000',
      quoteVolume: 17769105.790459905,
      btcVolume: 1683.5510553061292,
      usdVolume: 17769105.790459905,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '64.8800000000000000',
      high: '70.5700000000000000',
      low: '64.5300000000000000',
      close: '68.7800000000000000',
      volume: '305074.1416000000000000',
      quoteVolume: 20786479.847835682,
      btcVolume: 1993.4143229328859,
      usdVolume: 20786479.847835682,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '68.7700000000000000',
      high: '71.4600000000000000',
      low: '68.0000000000000000',
      close: '70.3600000000000000',
      volume: '313588.1220800000000000',
      quoteVolume: 21958484.40247299,
      btcVolume: 2102.4332066868055,
      usdVolume: 21958484.40247299,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '70.3600000000000000',
      high: '71.3800000000000000',
      low: '67.7300000000000000',
      close: '69.8900000000000000',
      volume: '227135.8567900000000000',
      quoteVolume: 15787764.06820893,
      btcVolume: 1535.4445818677495,
      usdVolume: 15787764.06820893,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '69.8600000000000000',
      high: '73.1000000000000000',
      low: '69.7700000000000000',
      close: '70.8500000000000000',
      volume: '315418.1644100000000000',
      quoteVolume: 22463397.73757165,
      btcVolume: 2195.2854664792503,
      usdVolume: 22463397.73757165,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '70.8500000000000000',
      high: '71.8100000000000000',
      low: '68.2500000000000000',
      close: '69.8000000000000000',
      volume: '208661.7044600000000000',
      quoteVolume: 14616150.160225926,
      btcVolume: 1453.8574985722253,
      usdVolume: 14616150.160225926,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '69.8000000000000000',
      high: '70.0000000000000000',
      low: '68.3000000000000000',
      close: '69.1500000000000000',
      volume: '135127.9931800000000000',
      quoteVolume: 9339546.336077025,
      btcVolume: 912.766277649649,
      usdVolume: 9339546.336077025,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '69.1800000000000000',
      high: '69.5800000000000000',
      low: '67.3600000000000000',
      close: '68.9300000000000000',
      volume: '156416.2924400000000000',
      quoteVolume: 10722817.409428386,
      btcVolume: 1041.7990264891057,
      usdVolume: 10722817.409428386,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '68.9300000000000000',
      high: '71.5000000000000000',
      low: '68.2000000000000000',
      close: '70.5000000000000000',
      volume: '178914.2774100000000000',
      quoteVolume: 12510402.533513518,
      btcVolume: 1210.141755485563,
      usdVolume: 12510402.533513518,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '70.4900000000000000',
      high: '71.2800000000000000',
      low: '69.5000000000000000',
      close: '70.0400000000000000',
      volume: '123157.5877600000000000',
      quoteVolume: 8653254.98748152,
      btcVolume: 839.9682939747071,
      usdVolume: 8653254.98748152,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '70.0400000000000000',
      high: '73.6700000000000000',
      low: '69.3600000000000000',
      close: '72.6300000000000000',
      volume: '266145.5193300000000000',
      quoteVolume: 18990638.744624417,
      btcVolume: 1850.305593630325,
      usdVolume: 18990638.744624417,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '72.6700000000000000',
      high: '77.5900000000000000',
      low: '72.1500000000000000',
      close: '74.0400000000000000',
      volume: '331768.0453000000000000',
      quoteVolume: 24570461.734894328,
      btcVolume: 2405.01019871254,
      usdVolume: 24570461.734894328,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '74.0600000000000000',
      high: '80.3000000000000000',
      low: '73.9800000000000000',
      close: '77.8100000000000000',
      volume: '463500.2471200000000000',
      quoteVolume: 35715978.31337182,
      btcVolume: 3505.5073405779085,
      usdVolume: 35715978.31337182,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '77.7700000000000000',
      high: '80.9000000000000000',
      low: '72.6200000000000000',
      close: '76.5500000000000000',
      volume: '501398.1546800000000000',
      quoteVolume: 37733017.621873595,
      btcVolume: 3774.092404497032,
      usdVolume: 37733017.621873595,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '76.5600000000000000',
      high: '77.0800000000000000',
      low: '72.7800000000000000',
      close: '75.0500000000000000',
      volume: '285843.0479300000000000',
      quoteVolume: 21452170.229147196,
      btcVolume: 2108.9030586427175,
      usdVolume: 21452170.229147196,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '75.0600000000000000',
      high: '75.5800000000000000',
      low: '72.0600000000000000',
      close: '73.2200000000000000',
      volume: '211641.7919700000000000',
      quoteVolume: 15603395.96512544,
      btcVolume: 1554.647856825871,
      usdVolume: 15603395.96512544,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '73.2000000000000000',
      high: '73.2200000000000000',
      low: '71.0300000000000000',
      close: '72.2300000000000000',
      volume: '219379.8472300000000000',
      quoteVolume: 15822626.780132813,
      btcVolume: 1585.411547795001,
      usdVolume: 15822626.780132813,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '72.2600000000000000',
      high: '74.2400000000000000',
      low: '61.8800000000000000',
      close: '66.5400000000000000',
      volume: '526339.5301500000000000',
      quoteVolume: 36437108.07404466,
      btcVolume: 3714.5060783639537,
      usdVolume: 36437108.07404466,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '66.5400000000000000',
      high: '67.4200000000000000',
      low: '54.3400000000000000',
      close: '54.5700000000000000',
      volume: '896194.8912400000000000',
      quoteVolume: 54840616.36898153,
      btcVolume: 6077.979287792019,
      usdVolume: 54840616.36898153,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '54.5700000000000000',
      high: '58.3400000000000000',
      low: '52.8400000000000000',
      close: '57.4300000000000000',
      volume: '780933.6156200000000000',
      quoteVolume: 43514734.915106036,
      btcVolume: 5172.0466578427,
      usdVolume: 43514734.915106036,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '57.4800000000000000',
      high: '57.5600000000000000',
      low: '50.3000000000000000',
      close: '55.1900000000000000',
      volume: '620655.1100700000000000',
      quoteVolume: 33894831.566069685,
      btcVolume: 4162.554218117035,
      usdVolume: 33894831.566069685,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '55.1700000000000000',
      high: '56.6100000000000000',
      low: '52.6100000000000000',
      close: '55.8600000000000000',
      volume: '390319.7644900000000000',
      quoteVolume: 21346773.06550875,
      btcVolume: 2660.2624788155385,
      usdVolume: 21346773.06550875,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '55.8600000000000000',
      high: '56.1600000000000000',
      low: '53.6700000000000000',
      close: '55.3700000000000000',
      volume: '256916.9284600000000000',
      quoteVolume: 14172326.366005592,
      btcVolume: 1741.149072225589,
      usdVolume: 14172326.366005592,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '55.3700000000000000',
      high: '55.5800000000000000',
      low: '52.1300000000000000',
      close: '53.8200000000000000',
      volume: '227396.1860800000000000',
      quoteVolume: 12189476.348992856,
      btcVolume: 1518.3799061716697,
      usdVolume: 12189476.348992856,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '53.8400000000000000',
      high: '56.2500000000000000',
      low: '52.4600000000000000',
      close: '55.9900000000000000',
      volume: '369529.6878700000000000',
      quoteVolume: 20182788.05999346,
      btcVolume: 2510.805443251447,
      usdVolume: 20182788.05999346,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '56.0100000000000000',
      high: '57.6000000000000000',
      low: '54.8200000000000000',
      close: '55.6300000000000000',
      volume: '333516.6371400000000000',
      quoteVolume: 18701415.035006244,
      btcVolume: 2241.634536922254,
      usdVolume: 18701415.035006244,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '55.5900000000000000',
      high: '56.6600000000000000',
      low: '55.1300000000000000',
      close: '56.2300000000000000',
      volume: '215239.2591000000000000',
      quoteVolume: 12024564.531152936,
      btcVolume: 1459.108573099224,
      usdVolume: 12024564.531152936,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '56.2800000000000000',
      high: '57.0700000000000000',
      low: '53.6200000000000000',
      close: '56.4400000000000000',
      volume: '253120.5364200000000000',
      quoteVolume: 14050677.75934256,
      btcVolume: 1717.4520781431509,
      usdVolume: 14050677.75934256,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '56.4500000000000000',
      high: '57.5000000000000000',
      low: '55.2200000000000000',
      close: '56.3000000000000000',
      volume: '247079.7704500000000000',
      quoteVolume: 13984268.782087287,
      btcVolume: 1718.1473442856827,
      usdVolume: 13984268.782087287,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '56.3000000000000000',
      high: '57.4000000000000000',
      low: '55.5700000000000000',
      close: '56.6900000000000000',
      volume: '177602.1005800000000000',
      quoteVolume: 10003772.607079951,
      btcVolume: 1237.007987145328,
      usdVolume: 10003772.607079951,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '56.6900000000000000',
      high: '56.8000000000000000',
      low: '52.9500000000000000',
      close: '54.4100000000000000',
      volume: '255560.2861700000000000',
      quoteVolume: 14075211.354071734,
      btcVolume: 1771.6354364948543,
      usdVolume: 14075211.354071734,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '54.4200000000000000',
      high: '58.6500000000000000',
      low: '53.2500000000000000',
      close: '57.4600000000000000',
      volume: '314651.2758500000000000',
      quoteVolume: 17736478.819784664,
      btcVolume: 2198.803572764758,
      usdVolume: 17736478.819784664,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '57.4600000000000000',
      high: '58.4500000000000000',
      low: '56.4700000000000000',
      close: '56.9500000000000000',
      volume: '184191.0427500000000000',
      quoteVolume: 10558792.262372462,
      btcVolume: 1286.8688325201551,
      usdVolume: 10558792.262372462,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '56.9500000000000000',
      high: '60.0600000000000000',
      low: '56.6000000000000000',
      close: '59.1200000000000000',
      volume: '337686.6303300000000000',
      quoteVolume: 19730452.316288013,
      btcVolume: 2355.7027861823267,
      usdVolume: 19730452.316288013,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '59.1000000000000000',
      high: '59.3300000000000000',
      low: '57.0000000000000000',
      close: '57.4700000000000000',
      volume: '215031.0358800000000000',
      quoteVolume: 12487431.424961781,
      btcVolume: 1463.474709248515,
      usdVolume: 12487431.424961781,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '57.4800000000000000',
      high: '58.4200000000000000',
      low: '55.3300000000000000',
      close: '55.8500000000000000',
      volume: '265707.6043700000000000',
      quoteVolume: 15003032.363513099,
      btcVolume: 1779.3269982097474,
      usdVolume: 15003032.363513099,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '55.8500000000000000',
      high: '56.5000000000000000',
      low: '55.4100000000000000',
      close: '55.7000000000000000',
      volume: '101055.5079000000000000',
      quoteVolume: 5657808.268919437,
      btcVolume: 679.6634476450376,
      usdVolume: 5657808.268919437,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '55.7100000000000000',
      high: '57.6900000000000000',
      low: '55.4700000000000000',
      close: '56.4600000000000000',
      volume: '157772.3878500000000000',
      quoteVolume: 8939980.18918927,
      btcVolume: 1071.554187571745,
      usdVolume: 8939980.18918927,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '56.4400000000000000',
      high: '57.1000000000000000',
      low: '55.9400000000000000',
      close: '56.8300000000000000',
      volume: '133356.2528900000000000',
      quoteVolume: 7554339.147984878,
      btcVolume: 910.4939564933641,
      usdVolume: 7554339.147984878,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '56.8300000000000000',
      high: '57.3900000000000000',
      low: '53.4000000000000000',
      close: '54.5800000000000000',
      volume: '329395.4878400000000000',
      quoteVolume: 18207494.81235211,
      btcVolume: 2200.181196563216,
      usdVolume: 18207494.81235211,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '54.5700000000000000',
      high: '55.3900000000000000',
      low: '51.0000000000000000',
      close: '52.4500000000000000',
      volume: '297730.1499600000000000',
      quoteVolume: 15783948.682937246,
      btcVolume: 1965.4916381767648,
      usdVolume: 15783948.682937246,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '52.4500000000000000',
      high: '55.2200000000000000',
      low: '51.5700000000000000',
      close: '54.9400000000000000',
      volume: '238776.4036000000000000',
      quoteVolume: 12771309.090288963,
      btcVolume: 1589.7400371088647,
      usdVolume: 12771309.090288963,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '54.9500000000000000',
      high: '55.0100000000000000',
      low: '52.1900000000000000',
      close: '53.2100000000000000',
      volume: '212503.0760200000000000',
      quoteVolume: 11342598.709450595,
      btcVolume: 1428.7754993429342,
      usdVolume: 11342598.709450595,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '53.2100000000000000',
      high: '54.1600000000000000',
      low: '52.5400000000000000',
      close: '53.7200000000000000',
      volume: '152608.9214200000000000',
      quoteVolume: 8152589.987414735,
      btcVolume: 1023.959008729595,
      usdVolume: 8152589.987414735,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '53.7200000000000000',
      high: '55.4600000000000000',
      low: '52.5800000000000000',
      close: '54.8500000000000000',
      volume: '208172.7019100000000000',
      quoteVolume: 11213347.343628593,
      btcVolume: 1399.9846240450204,
      usdVolume: 11213347.343628593,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '54.8500000000000000',
      high: '55.7400000000000000',
      low: '53.6700000000000000',
      close: '54.5700000000000000',
      volume: '208443.7668600000000000',
      quoteVolume: 11370329.079330875,
      btcVolume: 1383.3455861262153,
      usdVolume: 11370329.079330875,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '54.5700000000000000',
      high: '55.0900000000000000',
      low: '52.9700000000000000',
      close: '53.3900000000000000',
      volume: '159505.0758900000000000',
      quoteVolume: 8659267.226605335,
      btcVolume: 1058.7525600589156,
      usdVolume: 8659267.226605335,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '53.3800000000000000',
      high: '53.4400000000000000',
      low: '47.5300000000000000',
      close: '49.5000000000000000',
      volume: '470048.8542900000000000',
      quoteVolume: 23488078.022841353,
      btcVolume: 3076.301841104244,
      usdVolume: 23488078.022841353,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '49.4800000000000000',
      high: '50.3200000000000000',
      low: '48.3000000000000000',
      close: '49.5400000000000000',
      volume: '308330.5219600000000000',
      quoteVolume: 15231954.366521599,
      btcVolume: 2047.6661467292997,
      usdVolume: 15231954.366521599,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '49.5500000000000000',
      high: '58.7000000000000000',
      low: '49.0100000000000000',
      close: '56.9300000000000000',
      volume: '736498.2495500000000000',
      quoteVolume: 40028251.61986273,
      btcVolume: 4942.253267416375,
      usdVolume: 40028251.61986273,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '56.9300000000000000',
      high: '63.3700000000000000',
      low: '54.1400000000000000',
      close: '56.7100000000000000',
      volume: '1011134.5544100000000000',
      quoteVolume: 58374697.06996285,
      btcVolume: 6236.904792516147,
      usdVolume: 58374697.06996285,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '56.6900000000000000',
      high: '62.8500000000000000',
      low: '55.5900000000000000',
      close: '59.7800000000000000',
      volume: '659707.7341100000000000',
      quoteVolume: 39098216.43731671,
      btcVolume: 4118.114476739268,
      usdVolume: 39098216.43731671,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '59.7900000000000000',
      high: '62.4700000000000000',
      low: '57.4000000000000000',
      close: '57.7000000000000000',
      volume: '539486.7688600000000000',
      quoteVolume: 31869821.10145664,
      btcVolume: 3367.080583556967,
      usdVolume: 31869821.10145664,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '57.7000000000000000',
      high: '60.7500000000000000',
      low: '57.6500000000000000',
      close: '59.7500000000000000',
      volume: '399523.8960500000000000',
      quoteVolume: 23770607.904536624,
      btcVolume: 2536.351568802833,
      usdVolume: 23770607.904536624,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '59.7600000000000000',
      high: '61.0500000000000000',
      low: '56.8800000000000000',
      close: '57.8900000000000000',
      volume: '341531.2851900000000000',
      quoteVolume: 20067287.16194507,
      btcVolume: 2180.7999647255724,
      usdVolume: 20067287.16194507,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '57.9000000000000000',
      high: '59.5600000000000000',
      low: '57.0200000000000000',
      close: '58.2900000000000000',
      volume: '268866.1439300000000000',
      quoteVolume: 15690777.836845942,
      btcVolume: 1711.8755410860422,
      usdVolume: 15690777.836845942,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '58.2900000000000000',
      high: '58.8000000000000000',
      low: '56.6800000000000000',
      close: '58.2300000000000000',
      volume: '221222.3294000000000000',
      quoteVolume: 12800948.223556288,
      btcVolume: 1400.6610473174119,
      usdVolume: 12800948.223556288,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '58.2400000000000000',
      high: '59.1900000000000000',
      low: '57.7300000000000000',
      close: '58.2500000000000000',
      volume: '150629.3718500000000000',
      quoteVolume: 8789951.618953658,
      btcVolume: 947.7305790128487,
      usdVolume: 8789951.618953658,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '58.2400000000000000',
      high: '58.7500000000000000',
      low: '57.1800000000000000',
      close: '58.2900000000000000',
      volume: '141294.2567900000000000',
      quoteVolume: 8186539.4970356515,
      btcVolume: 888.9066093561216,
      usdVolume: 8186539.4970356515,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '58.2900000000000000',
      high: '62.4000000000000000',
      low: '57.8700000000000000',
      close: '61.2900000000000000',
      volume: '407402.9069300000000000',
      quoteVolume: 24699682.594704557,
      btcVolume: 2665.317399877282,
      usdVolume: 24699682.594704557,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '61.2600000000000000',
      high: '64.1500000000000000',
      low: '60.8900000000000000',
      close: '63.1200000000000000',
      volume: '527467.4519600000000000',
      quoteVolume: 33010265.398333196,
      btcVolume: 3542.273311220224,
      usdVolume: 33010265.398333196,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '63.1300000000000000',
      high: '64.3300000000000000',
      low: '62.5600000000000000',
      close: '64.1100000000000000',
      volume: '334742.1432800000000000',
      quoteVolume: 21184873.260657944,
      btcVolume: 2268.6466351593385,
      usdVolume: 21184873.260657944,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '64.1100000000000000',
      high: '64.3300000000000000',
      low: '60.2700000000000000',
      close: '61.3900000000000000',
      volume: '327504.1707300000000000',
      quoteVolume: 20341773.350589715,
      btcVolume: 2200.2788075310846,
      usdVolume: 20341773.350589715,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '61.4000000000000000',
      high: '62.4900000000000000',
      low: '58.9300000000000000',
      close: '60.3800000000000000',
      volume: '345551.4396900000000000',
      quoteVolume: 20960125.60154757,
      btcVolume: 2332.7802751493164,
      usdVolume: 20960125.60154757,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '60.3600000000000000',
      high: '62.3700000000000000',
      low: '60.1700000000000000',
      close: '62.0400000000000000',
      volume: '196430.9663500000000000',
      quoteVolume: 12033723.069156943,
      btcVolume: 1365.127796378876,
      usdVolume: 12033723.069156943,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '62.0400000000000000',
      high: '66.1900000000000000',
      low: '60.8000000000000000',
      close: '63.6500000000000000',
      volume: '428980.9520600000000000',
      quoteVolume: 27218648.713102452,
      btcVolume: 3057.9731993427763,
      usdVolume: 27218648.713102452,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '63.6500000000000000',
      high: '64.3300000000000000',
      low: '60.4700000000000000',
      close: '61.8400000000000000',
      volume: '408664.6897800000000000',
      quoteVolume: 25451946.05196973,
      btcVolume: 2882.6678622354775,
      usdVolume: 25451946.05196973,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '61.8400000000000000',
      high: '62.2500000000000000',
      low: '59.7500000000000000',
      close: '61.4200000000000000',
      volume: '299554.9198200000000000',
      quoteVolume: 18351569.172878858,
      btcVolume: 2100.756847368965,
      usdVolume: 18351569.172878858,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '61.4500000000000000',
      high: '62.0500000000000000',
      low: '60.2700000000000000',
      close: '60.9600000000000000',
      volume: '175581.9554600000000000',
      quoteVolume: 10721665.62536904,
      btcVolume: 1223.0289182322622,
      usdVolume: 10721665.62536904,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '60.9600000000000000',
      high: '61.1000000000000000',
      low: '58.2500000000000000',
      close: '59.0000000000000000',
      volume: '289023.5733800000000000',
      quoteVolume: 17173463.110559292,
      btcVolume: 1981.3152223352774,
      usdVolume: 17173463.110559292,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '59.0000000000000000',
      high: '60.7500000000000000',
      low: '56.2000000000000000',
      close: '57.6000000000000000',
      volume: '331442.8743400000000000',
      quoteVolume: 19338540.966621064,
      btcVolume: 2257.9348903748273,
      usdVolume: 19338540.966621064,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '57.6100000000000000',
      high: '58.9800000000000000',
      low: '57.1400000000000000',
      close: '58.3000000000000000',
      volume: '135649.0746000000000000',
      quoteVolume: 7869798.748988588,
      btcVolume: 927.7112832694024,
      usdVolume: 7869798.748988588,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '58.2900000000000000',
      high: '59.9700000000000000',
      low: '57.5500000000000000',
      close: '59.2200000000000000',
      volume: '189990.6350700000000000',
      quoteVolume: 11229720.96215726,
      btcVolume: 1318.1179952916186,
      usdVolume: 11229720.96215726,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '59.2100000000000000',
      high: '59.3000000000000000',
      low: '54.0000000000000000',
      close: '55.8100000000000000',
      volume: '295818.0605500000000000',
      quoteVolume: 16800996.48111148,
      btcVolume: 2019.3958745827504,
      usdVolume: 16800996.48111148,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '55.8000000000000000',
      high: '56.2300000000000000',
      low: '54.2000000000000000',
      close: '55.1300000000000000',
      volume: '245040.3911600000000000',
      quoteVolume: 13537640.598777153,
      btcVolume: 1669.4864925290328,
      usdVolume: 13537640.598777153,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '55.1300000000000000',
      high: '56.1600000000000000',
      low: '54.6100000000000000',
      close: '54.9800000000000000',
      volume: '224543.3721000000000000',
      quoteVolume: 12409649.698862612,
      btcVolume: 1529.485427593074,
      usdVolume: 12409649.698862612,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '54.9900000000000000',
      high: '55.2900000000000000',
      low: '48.5100000000000000',
      close: '50.6100000000000000',
      volume: '439158.0191300000000000',
      quoteVolume: 22774364.172612227,
      btcVolume: 2908.36978884641,
      usdVolume: 22774364.172612227,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '50.6100000000000000',
      high: '51.5500000000000000',
      low: '43.5800000000000000',
      close: '47.3200000000000000',
      volume: '922303.8905500000000000',
      quoteVolume: 43869831.870156966,
      btcVolume: 6058.957447311882,
      usdVolume: 43869831.870156966,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '47.3200000000000000',
      high: '48.5300000000000000',
      low: '46.1300000000000000',
      close: '48.1000000000000000',
      volume: '301729.2088500000000000',
      quoteVolume: 14284224.628105655,
      btcVolume: 1978.2053346800967,
      usdVolume: 14284224.628105655,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '48.1100000000000000',
      high: '48.2400000000000000',
      low: '43.4500000000000000',
      close: '43.8600000000000000',
      volume: '419515.9268500000000000',
      quoteVolume: 19189897.614486806,
      btcVolume: 2708.0365194507826,
      usdVolume: 19189897.614486806,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '43.8700000000000000',
      high: '47.4200000000000000',
      low: '42.0400000000000000',
      close: '45.4700000000000000',
      volume: '568354.8930000000000000',
      quoteVolume: 25558493.400256857,
      btcVolume: 3690.2398898192073,
      usdVolume: 25558493.400256857,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '45.4800000000000000',
      high: '47.5700000000000000',
      low: '45.2200000000000000',
      close: '46.9100000000000000',
      volume: '310692.2824100000000000',
      quoteVolume: 14415831.257585848,
      btcVolume: 2017.545020006212,
      usdVolume: 14415831.257585848,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '46.9100000000000000',
      high: '48.6900000000000000',
      low: '44.5100000000000000',
      close: '47.7000000000000000',
      volume: '455208.2126200000000000',
      quoteVolume: 21261996.524687037,
      btcVolume: 2940.3101183783738,
      usdVolume: 21261996.524687037,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '47.6900000000000000',
      high: '48.0000000000000000',
      low: '46.0100000000000000',
      close: '46.7900000000000000',
      volume: '267853.2410700000000000',
      quoteVolume: 12673681.758905394,
      btcVolume: 1689.725092052278,
      usdVolume: 12673681.758905394,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '46.7800000000000000',
      high: '49.3300000000000000',
      low: '46.7300000000000000',
      close: '48.5400000000000000',
      volume: '300683.0915400000000000',
      quoteVolume: 14490088.801178327,
      btcVolume: 1907.3941093084677,
      usdVolume: 14490088.801178327,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '48.5500000000000000',
      high: '48.7600000000000000',
      low: '46.5300000000000000',
      close: '47.2100000000000000',
      volume: '270873.2850800000000000',
      quoteVolume: 12935690.65358488,
      btcVolume: 1697.6778943997815,
      usdVolume: 12935690.65358488,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '47.2100000000000000',
      high: '48.4500000000000000',
      low: '45.5000000000000000',
      close: '47.4500000000000000',
      volume: '313487.6048300000000000',
      quoteVolume: 14677077.515154386,
      btcVolume: 1995.4070593380043,
      usdVolume: 14677077.515154386,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '47.4500000000000000',
      high: '47.5700000000000000',
      low: '44.7100000000000000',
      close: '45.4500000000000000',
      volume: '302395.0200900000000000',
      quoteVolume: 13878732.659008326,
      btcVolume: 1902.976734313874,
      usdVolume: 13878732.659008326,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '45.4400000000000000',
      high: '46.2200000000000000',
      low: '44.2600000000000000',
      close: '44.7500000000000000',
      volume: '323943.4612000000000000',
      quoteVolume: 14690325.25946848,
      btcVolume: 2010.2978077888893,
      usdVolume: 14690325.25946848,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '44.7600000000000000',
      high: '46.7000000000000000',
      low: '43.6900000000000000',
      close: '44.6700000000000000',
      volume: '539077.4009800000000000',
      quoteVolume: 24320896.92843568,
      btcVolume: 3326.541904574674,
      usdVolume: 24320896.92843568,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '44.6400000000000000',
      high: '45.2100000000000000',
      low: '43.7000000000000000',
      close: '44.8100000000000000',
      volume: '444681.4243600000000000',
      quoteVolume: 19790933.976291418,
      btcVolume: 2706.2151928563303,
      usdVolume: 19790933.976291418,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '44.7800000000000000',
      high: '45.5000000000000000',
      low: '44.0500000000000000',
      close: '45.2800000000000000',
      volume: '271289.1369300000000000',
      quoteVolume: 12150894.310600491,
      btcVolume: 1640.903702691806,
      usdVolume: 12150894.310600491,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '45.2600000000000000',
      high: '46.5000000000000000',
      low: '44.9400000000000000',
      close: '45.1500000000000000',
      volume: '195130.2029600000000000',
      quoteVolume: 8895004.035972556,
      btcVolume: 1181.7389955788406,
      usdVolume: 8895004.035972556,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '45.1400000000000000',
      high: '46.2000000000000000',
      low: '44.8100000000000000',
      close: '45.5600000000000000',
      volume: '131662.0929900000000000',
      quoteVolume: 5984698.629427009,
      btcVolume: 800.0403835402077,
      usdVolume: 5984698.629427009,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '45.5500000000000000',
      high: '45.8700000000000000',
      low: '44.0400000000000000',
      close: '44.4500000000000000',
      volume: '217927.8825200000000000',
      quoteVolume: 9810024.721531982,
      btcVolume: 1315.4281615543798,
      usdVolume: 9810024.721531982,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '44.4500000000000000',
      high: '44.6700000000000000',
      low: '43.6000000000000000',
      close: '44.0900000000000000',
      volume: '150376.5875200000000000',
      quoteVolume: 6644775.029064859,
      btcVolume: 910.4725316157349,
      usdVolume: 6644775.029064859,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '44.0700000000000000',
      high: '44.4600000000000000',
      low: '43.2700000000000000',
      close: '43.7000000000000000',
      volume: '134280.0069800000000000',
      quoteVolume: 5891144.238883986,
      btcVolume: 816.6022311473681,
      usdVolume: 5891144.238883986,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '43.7000000000000000',
      high: '44.1100000000000000',
      low: '42.8400000000000000',
      close: '43.6500000000000000',
      volume: '169652.1601800000000000',
      quoteVolume: 7380545.620045383,
      btcVolume: 1028.175549454187,
      usdVolume: 7380545.620045383,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '43.6500000000000000',
      high: '44.5500000000000000',
      low: '43.4300000000000000',
      close: '44.4400000000000000',
      volume: '127640.0717900000000000',
      quoteVolume: 5608098.184686444,
      btcVolume: 775.0595310858355,
      usdVolume: 5608098.184686444,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '44.4400000000000000',
      high: '44.6700000000000000',
      low: '43.1900000000000000',
      close: '43.2500000000000000',
      volume: '121534.2323000000000000',
      quoteVolume: 5321086.2473528925,
      btcVolume: 744.3880130799254,
      usdVolume: 5321086.2473528925,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '43.2800000000000000',
      high: '44.0400000000000000',
      low: '42.9000000000000000',
      close: '43.4400000000000000',
      volume: '105006.6976000000000000',
      quoteVolume: 4563268.2074588975,
      btcVolume: 643.0807131329308,
      usdVolume: 4563268.2074588975,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '43.4600000000000000',
      high: '43.5600000000000000',
      low: '39.0200000000000000',
      close: '39.9900000000000000',
      volume: '373430.2658200000000000',
      quoteVolume: 15295271.543124389,
      btcVolume: 2193.410071747004,
      usdVolume: 15295271.543124389,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '39.9800000000000000',
      high: '40.1300000000000000',
      low: '36.1000000000000000',
      close: '36.9200000000000000',
      volume: '645072.3422400000000000',
      quoteVolume: 24673081.77184135,
      btcVolume: 3668.629711227305,
      usdVolume: 24673081.77184135,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '36.9400000000000000',
      high: '41.7100000000000000',
      low: '35.7800000000000000',
      close: '40.7900000000000000',
      volume: '667785.3073500000000000',
      quoteVolume: 25701967.270766664,
      btcVolume: 3786.72926837403,
      usdVolume: 25701967.270766664,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '40.7900000000000000',
      high: '41.1000000000000000',
      low: '39.0600000000000000',
      close: '39.6500000000000000',
      volume: '240386.3147900000000000',
      quoteVolume: 9577045.423953377,
      btcVolume: 1337.9051700822683,
      usdVolume: 9577045.423953377,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '39.6600000000000000',
      high: '40.4100000000000000',
      low: '39.2500000000000000',
      close: '40.1700000000000000',
      volume: '151508.4764800000000000',
      quoteVolume: 6050598.731822773,
      btcVolume: 845.4937670166742,
      usdVolume: 6050598.731822773,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '40.1500000000000000',
      high: '40.2200000000000000',
      low: '39.4100000000000000',
      close: '39.6700000000000000',
      volume: '91777.0964000000000000',
      quoteVolume: 3651414.4911812823,
      btcVolume: 511.12426012410907,
      usdVolume: 3651414.4911812823,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '39.6700000000000000',
      high: '42.1600000000000000',
      low: '39.6200000000000000',
      close: '41.8200000000000000',
      volume: '178219.7773000000000000',
      quoteVolume: 7343575.467333499,
      btcVolume: 1010.9593601908497,
      usdVolume: 7343575.467333499,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '41.8400000000000000',
      high: '42.6700000000000000',
      low: '40.2100000000000000',
      close: '40.5600000000000000',
      volume: '288871.4672100000000000',
      quoteVolume: 12041679.124624727,
      btcVolume: 1606.172428630869,
      usdVolume: 12041679.124624727,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '40.5600000000000000',
      high: '41.1300000000000000',
      low: '39.6800000000000000',
      close: '40.0600000000000000',
      volume: '211464.7632900000000000',
      quoteVolume: 8551583.328070415,
      btcVolume: 1173.559105839882,
      usdVolume: 8551583.328070415,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '40.3300000000000000',
      high: '40.4800000000000000',
      low: '39.3800000000000000',
      close: '40.0800000000000000',
      volume: '136222.9401800000000000',
      quoteVolume: 5447546.31914613,
      btcVolume: 755.370742894901,
      usdVolume: 5447546.31914613,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '40.1100000000000000',
      high: '41.7300000000000000',
      low: '39.5800000000000000',
      close: '41.4400000000000000',
      volume: '194299.1207600000000000',
      quoteVolume: 7858546.46046011,
      btcVolume: 1081.0147742755657,
      usdVolume: 7858546.46046011,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '39.9900000000000000',
      high: '41.5000000000000000',
      low: '39.6700000000000000',
      close: '40.9500000000000000',
      volume: '219194.0915600000000000',
      quoteVolume: 8901146.687390905,
      btcVolume: 1233.9824964047334,
      usdVolume: 8901146.687390905,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '40.9600000000000000',
      high: '43.7600000000000000',
      low: '40.9100000000000000',
      close: '42.8300000000000000',
      volume: '284295.3329600000000000',
      quoteVolume: 12068166.856607929,
      btcVolume: 1649.5543814055918,
      usdVolume: 12068166.856607929,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '42.8400000000000000',
      high: '43.7600000000000000',
      low: '42.1400000000000000',
      close: '43.0800000000000000',
      volume: '337077.7113200000000000',
      quoteVolume: 14472707.652529165,
      btcVolume: 1964.1795286823033,
      usdVolume: 14472707.652529165,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '43.0700000000000000',
      high: '44.1800000000000000',
      low: '41.8200000000000000',
      close: '42.3800000000000000',
      volume: '298581.8729900000000000',
      quoteVolume: 12800174.560334394,
      btcVolume: 1748.879815023186,
      usdVolume: 12800174.560334394,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '42.3600000000000000',
      high: '43.0500000000000000',
      low: '41.0000000000000000',
      close: '41.3000000000000000',
      volume: '201446.8929200000000000',
      quoteVolume: 8445983.18938396,
      btcVolume: 1169.7012363488755,
      usdVolume: 8445983.18938396,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '41.2900000000000000',
      high: '42.0800000000000000',
      low: '41.1600000000000000',
      close: '41.6200000000000000',
      volume: '110044.8744500000000000',
      quoteVolume: 4588192.884942515,
      btcVolume: 635.4364428671792,
      usdVolume: 4588192.884942515,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '41.5800000000000000',
      high: '41.7400000000000000',
      low: '39.1700000000000000',
      close: '39.4300000000000000',
      volume: '185573.9423700000000000',
      quoteVolume: 7483717.243429469,
      btcVolume: 1061.4403351410635,
      usdVolume: 7483717.243429469,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '39.4400000000000000',
      high: '42.3900000000000000',
      low: '38.7700000000000000',
      close: '42.2300000000000000',
      volume: '426686.1784300000000000',
      quoteVolume: 17553430.816173177,
      btcVolume: 2423.2412705017687,
      usdVolume: 17553430.816173177,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '42.2300000000000000',
      high: '42.9000000000000000',
      low: '41.7700000000000000',
      close: '42.7800000000000000',
      volume: '248765.3690300000000000',
      quoteVolume: 10534042.087356858,
      btcVolume: 1436.827480722452,
      usdVolume: 10534042.087356858,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '42.7900000000000000',
      high: '44.6100000000000000',
      low: '42.6000000000000000',
      close: '43.3000000000000000',
      volume: '291946.5645000000000000',
      quoteVolume: 12683510.13119241,
      btcVolume: 1705.9415015020584,
      usdVolume: 12683510.13119241,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '43.2900000000000000',
      high: '46.0800000000000000',
      low: '43.1600000000000000',
      close: '45.8000000000000000',
      volume: '384930.9909000000000000',
      quoteVolume: 17201996.630419996,
      btcVolume: 2279.19673036352,
      usdVolume: 17201996.630419996,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '45.8000000000000000',
      high: '47.0300000000000000',
      low: '44.4800000000000000',
      close: '46.3000000000000000',
      volume: '493847.1053200000000000',
      quoteVolume: 22589565.78223805,
      btcVolume: 2841.5038279351834,
      usdVolume: 22589565.78223805,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '46.3000000000000000',
      high: '48.5400000000000000',
      low: '44.0000000000000000',
      close: '45.3500000000000000',
      volume: '584339.7458700000000000',
      quoteVolume: 27180426.110374276,
      btcVolume: 3303.5440248504137,
      usdVolume: 27180426.110374276,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '45.3100000000000000',
      high: '45.7900000000000000',
      low: '43.9000000000000000',
      close: '44.7400000000000000',
      volume: '291473.3014800000000000',
      quoteVolume: 13068587.786202814,
      btcVolume: 1655.8036544951558,
      usdVolume: 13068587.786202814,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '44.7500000000000000',
      high: '49.7200000000000000',
      low: '43.7100000000000000',
      close: '49.0600000000000000',
      volume: '729802.1629100000000000',
      quoteVolume: 34640157.160860166,
      btcVolume: 4336.05850079116,
      usdVolume: 34640157.160860166,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '49.0600000000000000',
      high: '51.9500000000000000',
      low: '47.7700000000000000',
      close: '49.3000000000000000',
      volume: '681984.4665200000000000',
      quoteVolume: 33981679.44025984,
      btcVolume: 4182.297745203546,
      usdVolume: 33981679.44025984,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '49.3200000000000000',
      high: '51.7500000000000000',
      low: '48.7800000000000000',
      close: '51.4300000000000000',
      volume: '532886.7588500000000000',
      quoteVolume: 26955144.342092644,
      btcVolume: 3323.759557503418,
      usdVolume: 26955144.342092644,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '51.4400000000000000',
      high: '51.6000000000000000',
      low: '48.9100000000000000',
      close: '49.6000000000000000',
      volume: '381202.3532900000000000',
      quoteVolume: 19042757.26225366,
      btcVolume: 2347.839281568202,
      usdVolume: 19042757.26225366,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '49.5900000000000000',
      high: '60.8000000000000000',
      low: '49.5800000000000000',
      close: '58.3900000000000000',
      volume: '1232536.3536400000000000',
      quoteVolume: 68826521.75910157,
      btcVolume: 7986.234559202855,
      usdVolume: 68826521.75910157,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '58.3700000000000000',
      high: '60.3300000000000000',
      low: '55.2800000000000000',
      close: '58.1600000000000000',
      volume: '719639.6444600000000000',
      quoteVolume: 41814710.281005435,
      btcVolume: 4771.257558434868,
      usdVolume: 41814710.281005435,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '58.1700000000000000',
      high: '58.5000000000000000',
      low: '54.5000000000000000',
      close: '57.5600000000000000',
      volume: '477991.2016200000000000',
      quoteVolume: 27095155.674876053,
      btcVolume: 3117.7992797943502,
      usdVolume: 27095155.674876053,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '57.5600000000000000',
      high: '63.2600000000000000',
      low: '56.7200000000000000',
      close: '61.0200000000000000',
      volume: '767262.9013300000000000',
      quoteVolume: 46240460.37712067,
      btcVolume: 5206.750862665781,
      usdVolume: 46240460.37712067,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '61.0500000000000000',
      high: '62.5000000000000000',
      low: '57.9000000000000000',
      close: '59.3200000000000000',
      volume: '498224.6925600000000000',
      quoteVolume: 29957779.817371603,
      btcVolume: 3366.710070150065,
      usdVolume: 29957779.817371603,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '59.2900000000000000',
      high: '62.5500000000000000',
      low: '55.3400000000000000',
      close: '57.6000000000000000',
      volume: '530895.3948100000000000',
      quoteVolume: 31172505.611098293,
      btcVolume: 3524.9826085852515,
      usdVolume: 31172505.611098293,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '57.5900000000000000',
      high: '58.2900000000000000',
      low: '55.8400000000000000',
      close: '57.1900000000000000',
      volume: '262316.6725800000000000',
      quoteVolume: 14992782.875085551,
      btcVolume: 1733.2682720575585,
      usdVolume: 14992782.875085551,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '57.1900000000000000',
      high: '58.0900000000000000',
      low: '55.9100000000000000',
      close: '57.5700000000000000',
      volume: '216543.7237600000000000',
      quoteVolume: 12411702.385828936,
      btcVolume: 1433.8494862629868,
      usdVolume: 12411702.385828936,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '57.6000000000000000',
      high: '59.3300000000000000',
      low: '57.1800000000000000',
      close: '58.3300000000000000',
      volume: '218658.9547700000000000',
      quoteVolume: 12717189.464885991,
      btcVolume: 1461.8622331104564,
      usdVolume: 12717189.464885991,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '58.3300000000000000',
      high: '58.4000000000000000',
      low: '52.4200000000000000',
      close: '54.5200000000000000',
      volume: '464982.4302600000000000',
      quoteVolume: 25617022.981270354,
      btcVolume: 3040.6698235798467,
      usdVolume: 25617022.981270354,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '54.5200000000000000',
      high: '55.4100000000000000',
      low: '50.6400000000000000',
      close: '54.3800000000000000',
      volume: '550746.2142300000000000',
      quoteVolume: 29358272.10197633,
      btcVolume: 3502.858976790822,
      usdVolume: 29358272.10197633,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '54.4100000000000000',
      high: '54.4100000000000000',
      low: '51.9000000000000000',
      close: '53.2500000000000000',
      volume: '282004.9267600000000000',
      quoteVolume: 15034806.238122009,
      btcVolume: 1801.659164508155,
      usdVolume: 15034806.238122009,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '53.2600000000000000',
      high: '56.6800000000000000',
      low: '52.8000000000000000',
      close: '56.2400000000000000',
      volume: '348355.2649400000000000',
      quoteVolume: 19066690.28468506,
      btcVolume: 2251.529364018872,
      usdVolume: 19066690.28468506,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '56.2900000000000000',
      high: '59.6500000000000000',
      low: '55.8700000000000000',
      close: '58.5500000000000000',
      volume: '504725.6770700000000000',
      quoteVolume: 29245084.44984709,
      btcVolume: 3342.2689126799664,
      usdVolume: 29245084.44984709,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '58.5500000000000000',
      high: '61.6100000000000000',
      low: '57.2800000000000000',
      close: '60.5200000000000000',
      volume: '720540.7454800000000000',
      quoteVolume: 42958185.64174903,
      btcVolume: 4739.804305857356,
      usdVolume: 42958185.64174903,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '60.5400000000000000',
      high: '62.5600000000000000',
      low: '59.4000000000000000',
      close: '59.8000000000000000',
      volume: '566997.9273700000000000',
      quoteVolume: 34512745.46518843,
      btcVolume: 3694.810881665897,
      usdVolume: 34512745.46518843,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '59.8100000000000000',
      high: '70.2200000000000000',
      low: '58.2400000000000000',
      close: '67.9800000000000000',
      volume: '1286322.9967600000000000',
      quoteVolume: 83696612.6659622,
      btcVolume: 8901.350446887207,
      usdVolume: 83696612.6659622,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '68.0000000000000000',
      high: '70.1000000000000000',
      low: '65.3700000000000000',
      close: '68.0000000000000000',
      volume: '938256.7834600000000000',
      quoteVolume: 63513691.45788283,
      btcVolume: 6794.098635568326,
      usdVolume: 63513691.45788283,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '68.0200000000000000',
      high: '73.0000000000000000',
      low: '67.6200000000000000',
      close: '70.7200000000000000',
      volume: '952836.8964000000000000',
      quoteVolume: 67544318.68868984,
      btcVolume: 7193.168790333061,
      usdVolume: 67544318.68868984,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '70.7200000000000000',
      high: '73.5000000000000000',
      low: '67.6600000000000000',
      close: '69.9300000000000000',
      volume: '818385.8921200000000000',
      quoteVolume: 57937576.88566312,
      btcVolume: 6178.672563598776,
      usdVolume: 57937576.88566312,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '69.9300000000000000',
      high: '73.0000000000000000',
      low: '68.4500000000000000',
      close: '69.6500000000000000',
      volume: '689182.2485500000000000',
      quoteVolume: 48493966.03873978,
      btcVolume: 5178.755191366324,
      usdVolume: 48493966.03873978,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '69.6500000000000000',
      high: '70.5000000000000000',
      low: '66.6200000000000000',
      close: '68.0700000000000000',
      volume: '486977.7672700000000000',
      quoteVolume: 33194308.20363878,
      btcVolume: 3598.386589011568,
      usdVolume: 33194308.20363878,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '68.0400000000000000',
      high: '73.4700000000000000',
      low: '67.6200000000000000',
      close: '72.5900000000000000',
      volume: '646729.4887700000000000',
      quoteVolume: 46118158.32426789,
      btcVolume: 4877.923830710961,
      usdVolume: 46118158.32426789,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '72.5900000000000000',
      high: '75.0800000000000000',
      low: '70.9100000000000000',
      close: '73.6000000000000000',
      volume: '698855.4245600000000000',
      quoteVolume: 51231501.11111841,
      btcVolume: 5277.399278078971,
      usdVolume: 51231501.11111841,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '73.5900000000000000',
      high: '75.4800000000000000',
      low: '72.8500000000000000',
      close: '74.3000000000000000',
      volume: '541747.2637200000000000',
      quoteVolume: 40077414.45603498,
      btcVolume: 4090.357266366724,
      usdVolume: 40077414.45603498,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '74.3100000000000000',
      high: '77.9400000000000000',
      low: '71.5300000000000000',
      close: '76.5600000000000000',
      volume: '647821.6039800000000000',
      quoteVolume: 48757951.45692755,
      btcVolume: 4970.738929853706,
      usdVolume: 48757951.45692755,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '76.5600000000000000',
      high: '78.4300000000000000',
      low: '75.0000000000000000',
      close: '77.0000000000000000',
      volume: '472262.1156700000000000',
      quoteVolume: 36317074.30775854,
      btcVolume: 3602.7858292027363,
      usdVolume: 36317074.30775854,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '76.9900000000000000',
      high: '77.4800000000000000',
      low: '72.2100000000000000',
      close: '74.0200000000000000',
      volume: '709830.6287100000000000',
      quoteVolume: 52756980.53293135,
      btcVolume: 5317.311038857939,
      usdVolume: 52756980.53293135,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '73.9800000000000000',
      high: '77.4000000000000000',
      low: '72.6400000000000000',
      close: '76.4600000000000000',
      volume: '557017.2686200000000000',
      quoteVolume: 41776583.98157975,
      btcVolume: 4186.95219451079,
      usdVolume: 41776583.98157975,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '76.4500000000000000',
      high: '82.8100000000000000',
      low: '76.4400000000000000',
      close: '81.1700000000000000',
      volume: '771544.8829200000000000',
      quoteVolume: 61428938.033711724,
      btcVolume: 5950.1181314721225,
      usdVolume: 61428938.033711724,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '81.2000000000000000',
      high: '84.3200000000000000',
      low: '77.1500000000000000',
      close: '80.5500000000000000',
      volume: '1044135.4611700000000000',
      quoteVolume: 84499591.43981327,
      btcVolume: 8207.278543411736,
      usdVolume: 84499591.43981327,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '80.5500000000000000',
      high: '83.7900000000000000',
      low: '78.6100000000000000',
      close: '83.1400000000000000',
      volume: '523491.6950600000000000',
      quoteVolume: 42705491.43694748,
      btcVolume: 4162.817891228647,
      usdVolume: 42705491.43694748,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '83.1400000000000000',
      high: '83.5000000000000000',
      low: '75.0400000000000000',
      close: '76.7000000000000000',
      volume: '704649.8592300000000000',
      quoteVolume: 55784535.30447557,
      btcVolume: 5528.033565597649,
      usdVolume: 55784535.30447557,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '76.7400000000000000',
      high: '80.7700000000000000',
      low: '67.8500000000000000',
      close: '74.6700000000000000',
      volume: '998173.2565800000000000',
      quoteVolume: 74384870.94958961,
      btcVolume: 7540.054081915949,
      usdVolume: 74384870.94958961,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '74.6600000000000000',
      high: '75.5000000000000000',
      low: '68.5100000000000000',
      close: '73.4100000000000000',
      volume: '738054.4697500000000000',
      quoteVolume: 52679051.66613214,
      btcVolume: 5429.018811635842,
      usdVolume: 52679051.66613214,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '73.4400000000000000',
      high: '79.0900000000000000',
      low: '70.0000000000000000',
      close: '77.2500000000000000',
      volume: '713680.2316200000000000',
      quoteVolume: 53060847.638592295,
      btcVolume: 5393.830722757955,
      usdVolume: 53060847.638592295,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '77.2500000000000000',
      high: '79.4500000000000000',
      low: '67.0000000000000000',
      close: '70.6100000000000000',
      volume: '542175.5195700000000000',
      quoteVolume: 40353067.756060414,
      btcVolume: 4058.4679125064927,
      usdVolume: 40353067.756060414,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '70.6100000000000000',
      high: '72.4300000000000000',
      low: '66.1000000000000000',
      close: '69.4000000000000000',
      volume: '730443.1978900000000000',
      quoteVolume: 50737958.514938615,
      btcVolume: 5298.48056977998,
      usdVolume: 50737958.514938615,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '69.3800000000000000',
      high: '75.2500000000000000',
      low: '68.5600000000000000',
      close: '73.2500000000000000',
      volume: '624557.8448400000000000',
      quoteVolume: 45340746.56160606,
      btcVolume: 4683.2319382880305,
      usdVolume: 45340746.56160606,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '73.2500000000000000',
      high: '76.7900000000000000',
      low: '72.3200000000000000',
      close: '74.7900000000000000',
      volume: '651449.7864800000000000',
      quoteVolume: 48795358.48709348,
      btcVolume: 5060.245308587067,
      usdVolume: 48795358.48709348,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '74.8000000000000000',
      high: '80.0000000000000000',
      low: '74.6600000000000000',
      close: '79.5200000000000000',
      volume: '665878.9020700000000000',
      quoteVolume: 52072540.692516245,
      btcVolume: 5281.779201690238,
      usdVolume: 52072540.692516245,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '79.5200000000000000',
      high: '79.7600000000000000',
      low: '71.7100000000000000',
      close: '75.5900000000000000',
      volume: '936157.2591900000000000',
      quoteVolume: 70787716.5914514,
      btcVolume: 7272.425954963807,
      usdVolume: 70787716.5914514,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '75.5700000000000000',
      high: '76.0800000000000000',
      low: '69.5400000000000000',
      close: '70.9800000000000000',
      volume: '778051.0902300000000000',
      quoteVolume: 56572719.08831394,
      btcVolume: 5971.138131273642,
      usdVolume: 56572719.08831394,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '70.9800000000000000',
      high: '72.1500000000000000',
      low: '57.4100000000000000',
      close: '60.5300000000000000',
      volume: '1518769.7348500000000000',
      quoteVolume: 97983345.80007514,
      btcVolume: 10860.043177575628,
      usdVolume: 97983345.80007514,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '60.5300000000000000',
      high: '65.1000000000000000',
      low: '57.1500000000000000',
      close: '61.7700000000000000',
      volume: '1110152.5418600000000000',
      quoteVolume: 68205004.9433444,
      btcVolume: 7760.49735099012,
      usdVolume: 68205004.9433444,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '61.7600000000000000',
      high: '63.3900000000000000',
      low: '56.5600000000000000',
      close: '59.8900000000000000',
      volume: '1070641.2770800000000000',
      quoteVolume: 64057582.61899067,
      btcVolume: 7378.595868383946,
      usdVolume: 64057582.61899067,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '59.8800000000000000',
      high: '61.6500000000000000',
      low: '57.8400000000000000',
      close: '58.0500000000000000',
      volume: '506232.3970400000000000',
      quoteVolume: 30182934.208716422,
      btcVolume: 3477.3583610745964,
      usdVolume: 30182934.208716422,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '58.0500000000000000',
      high: '60.2800000000000000',
      low: '56.2100000000000000',
      close: '57.7000000000000000',
      volume: '633924.9116200000000000',
      quoteVolume: 37024496.384328924,
      btcVolume: 4325.436819033869,
      usdVolume: 37024496.384328924,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '57.7200000000000000',
      high: '62.3200000000000000',
      low: '57.2500000000000000',
      close: '61.4200000000000000',
      volume: '660927.2617600000000000',
      quoteVolume: 39608823.902695134,
      btcVolume: 4524.26294574055,
      usdVolume: 39608823.902695134,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '61.4200000000000000',
      high: '62.4000000000000000',
      low: '59.4000000000000000',
      close: '61.0100000000000000',
      volume: '640940.7745700000000000',
      quoteVolume: 39002038.56290318,
      btcVolume: 4441.375840912394,
      usdVolume: 39002038.56290318,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '61.0200000000000000',
      high: '61.7800000000000000',
      low: '58.7100000000000000',
      close: '60.3800000000000000',
      volume: '424299.8621100000000000',
      quoteVolume: 25604221.91697132,
      btcVolume: 2924.6084427488145,
      usdVolume: 25604221.91697132,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '60.3800000000000000',
      high: '63.2900000000000000',
      low: '60.3300000000000000',
      close: '62.0600000000000000',
      volume: '556284.7403100000000000',
      quoteVolume: 34448965.31724282,
      btcVolume: 3820.9976165896405,
      usdVolume: 34448965.31724282,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '62.0600000000000000',
      high: '63.8400000000000000',
      low: '61.1800000000000000',
      close: '63.2200000000000000',
      volume: '468431.8505300000000000',
      quoteVolume: 29256812.809018675,
      btcVolume: 3219.3959694013993,
      usdVolume: 29256812.809018675,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '63.2100000000000000',
      high: '63.8900000000000000',
      low: '59.8600000000000000',
      close: '60.4600000000000000',
      volume: '444270.5350800000000000',
      quoteVolume: 27655735.411491226,
      btcVolume: 3061.59890192781,
      usdVolume: 27655735.411491226,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '60.4600000000000000',
      high: '60.4700000000000000',
      low: '50.4100000000000000',
      close: '51.0000000000000000',
      volume: '945026.2712800000000000',
      quoteVolume: 51958845.61631424,
      btcVolume: 6146.981784105797,
      usdVolume: 51958845.61631424,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '51.0200000000000000',
      high: '52.2700000000000000',
      low: '46.5700000000000000',
      close: '50.3900000000000000',
      volume: '1426145.5016400000000000',
      quoteVolume: 70420337.2832661,
      btcVolume: 8969.579053763204,
      usdVolume: 70420337.2832661,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '50.3700000000000000',
      high: '51.3900000000000000',
      low: '48.8600000000000000',
      close: '50.2600000000000000',
      volume: '727732.9091100000000000',
      quoteVolume: 36535744.60946244,
      btcVolume: 4600.383086081476,
      usdVolume: 36535744.60946244,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '50.2400000000000000',
      high: '50.7400000000000000',
      low: '45.4700000000000000',
      close: '48.5300000000000000',
      volume: '798310.6481000000000000',
      quoteVolume: 38456344.38179883,
      btcVolume: 4924.684407095869,
      usdVolume: 38456344.38179883,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '48.5200000000000000',
      high: '48.7600000000000000',
      low: '28.6500000000000000',
      close: '29.8300000000000000',
      volume: '2086322.4159000000000000',
      quoteVolume: 75460005.32619856,
      btcVolume: 12025.230888564047,
      usdVolume: 75460005.32619856,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '29.8300000000000000',
      high: '39.9200000000000000',
      low: '24.0000000000000000',
      close: '37.5200000000000000',
      volume: '3233170.0085600000000000',
      quoteVolume: 106737958.21170305,
      btcVolume: 20811.33498176254,
      usdVolume: 106737958.21170305,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '37.5100000000000000',
      high: '37.6500000000000000',
      low: '33.8000000000000000',
      close: '34.4000000000000000',
      volume: '1177751.7307200000000000',
      quoteVolume: 42192079.856290706,
      btcVolume: 7824.353304327343,
      usdVolume: 42192079.856290706,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '34.4000000000000000',
      high: '38.7300000000000000',
      low: '34.0400000000000000',
      close: '36.3800000000000000',
      volume: '1040322.3028200000000000',
      quoteVolume: 37333342.64250416,
      btcVolume: 6968.352015588107,
      usdVolume: 37333342.64250416,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '36.3700000000000000',
      high: '36.4100000000000000',
      low: '29.2900000000000000',
      close: '33.2000000000000000',
      volume: '1624714.3758400000000000',
      quoteVolume: 52644643.37862483,
      btcVolume: 10729.651950772735,
      usdVolume: 52644643.37862483,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '33.2300000000000000',
      high: '35.8500000000000000',
      low: '32.4200000000000000',
      close: '34.0700000000000000',
      volume: '945437.9313600000000000',
      quoteVolume: 32633674.457892723,
      btcVolume: 6180.955240181797,
      usdVolume: 32633674.457892723,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '34.0600000000000000',
      high: '34.8300000000000000',
      low: '32.2800000000000000',
      close: '34.7100000000000000',
      volume: '1044418.0699700000000000',
      quoteVolume: 35185450.617933325,
      btcVolume: 6701.831333709307,
      usdVolume: 35185450.617933325,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '34.7200000000000000',
      high: '40.8000000000000000',
      low: '34.0000000000000000',
      close: '38.9000000000000000',
      volume: '1343522.4596300000000000',
      quoteVolume: 50290985.84804543,
      btcVolume: 8550.756313543709,
      usdVolume: 50290985.84804543,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '38.9000000000000000',
      high: '43.6900000000000000',
      low: '34.0500000000000000',
      close: '38.0800000000000000',
      volume: '1571300.6085700000000000',
      quoteVolume: 62380455.892511785,
      btcVolume: 9798.316569328548,
      usdVolume: 62380455.892511785,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '38.0700000000000000',
      high: '39.8400000000000000',
      low: '36.2100000000000000',
      close: '38.3200000000000000',
      volume: '667425.6322200000000000',
      quoteVolume: 25413717.28592991,
      btcVolume: 4123.580369137249,
      usdVolume: 25413717.28592991,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '38.3400000000000000',
      high: '39.6900000000000000',
      low: '34.8200000000000000',
      close: '35.4000000000000000',
      volume: '714342.7273000000000000',
      quoteVolume: 26556648.090772223,
      btcVolume: 4362.9065967115375,
      usdVolume: 26556648.090772223,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '35.4200000000000000',
      high: '39.9900000000000000',
      low: '34.5700000000000000',
      close: '38.9400000000000000',
      volume: '859942.5261100000000000',
      quoteVolume: 32311045.429742124,
      btcVolume: 5270.1140483688005,
      usdVolume: 32311045.429742124,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '38.9300000000000000',
      high: '40.9500000000000000',
      low: '38.2100000000000000',
      close: '40.5700000000000000',
      volume: '593656.7733500000000000',
      quoteVolume: 23511452.09394711,
      btcVolume: 3536.1717797574593,
      usdVolume: 23511452.09394711,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '40.5600000000000000',
      high: '41.0400000000000000',
      low: '38.0900000000000000',
      close: '39.1700000000000000',
      volume: '577448.8885600000000000',
      quoteVolume: 22783918.764491983,
      btcVolume: 3426.4774081346554,
      usdVolume: 22783918.764491983,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '39.1700000000000000',
      high: '40.4800000000000000',
      low: '38.4000000000000000',
      close: '40.4100000000000000',
      volume: '415288.7595000000000000',
      quoteVolume: 16295287.489501858,
      btcVolume: 2447.192594622033,
      usdVolume: 16295287.489501858,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '40.4100000000000000',
      high: '41.2100000000000000',
      low: '37.7300000000000000',
      close: '38.0400000000000000',
      volume: '481643.2817300000000000',
      quoteVolume: 19173843.62566706,
      btcVolume: 2881.7355055505905,
      usdVolume: 19173843.62566706,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '38.0400000000000000',
      high: '39.4300000000000000',
      low: '36.6100000000000000',
      close: '38.8700000000000000',
      volume: '624312.1869900000000000',
      quoteVolume: 23604375.60363385,
      btcVolume: 3798.947942790218,
      usdVolume: 23604375.60363385,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '38.8500000000000000',
      high: '39.1100000000000000',
      low: '36.9700000000000000',
      close: '37.1100000000000000',
      volume: '402076.3126400000000000',
      quoteVolume: 15295566.907294536,
      btcVolume: 2513.0753250709718,
      usdVolume: 15295566.907294536,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '37.1100000000000000',
      high: '39.7000000000000000',
      low: '37.1000000000000000',
      close: '38.9100000000000000',
      volume: '428079.8682600000000000',
      quoteVolume: 16531614.31945865,
      btcVolume: 2634.7438065394886,
      usdVolume: 16531614.31945865,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '38.9300000000000000',
      high: '39.3200000000000000',
      low: '38.3300000000000000',
      close: '39.0800000000000000',
      volume: '378250.3606500000000000',
      quoteVolume: 14716269.660095483,
      btcVolume: 2283.619623452454,
      usdVolume: 14716269.660095483,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '39.0700000000000000',
      high: '39.5000000000000000',
      low: '37.1400000000000000',
      close: '39.2300000000000000',
      volume: '488799.1143600000000000',
      quoteVolume: 18738315.769651353,
      btcVolume: 2961.325340905673,
      usdVolume: 18738315.769651353,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '39.2300000000000000',
      high: '42.9200000000000000',
      low: '39.0000000000000000',
      close: '39.8600000000000000',
      volume: '701573.7692100000000000',
      quoteVolume: 28237488.266804706,
      btcVolume: 4154.26325616401,
      usdVolume: 28237488.266804706,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '39.8800000000000000',
      high: '41.2600000000000000',
      low: '39.6400000000000000',
      close: '40.4600000000000000',
      volume: '463368.1163000000000000',
      quoteVolume: 18748635.166638713,
      btcVolume: 2747.9145418248345,
      usdVolume: 18748635.166638713,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '40.4900000000000000',
      high: '41.1600000000000000',
      low: '39.8600000000000000',
      close: '40.7100000000000000',
      volume: '362553.4451000000000000',
      quoteVolume: 14663339.769387648,
      btcVolume: 2163.870381431818,
      usdVolume: 14663339.769387648,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '40.7400000000000000',
      high: '41.0000000000000000',
      low: '39.8600000000000000',
      close: '40.3100000000000000',
      volume: '231112.9573000000000000',
      quoteVolume: 9362413.054505432,
      btcVolume: 1379.8091072782984,
      usdVolume: 9362413.054505432,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '40.3100000000000000',
      high: '45.0900000000000000',
      low: '40.2800000000000000',
      close: '45.0400000000000000',
      volume: '721562.2858000000000000',
      quoteVolume: 30935223.803984463,
      btcVolume: 4356.628030072247,
      usdVolume: 30935223.803984463,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '45.0300000000000000',
      high: '47.6400000000000000',
      low: '43.6400000000000000',
      close: '44.7700000000000000',
      volume: '807492.3694800000000000',
      quoteVolume: 36986803.916505545,
      btcVolume: 5058.8892341354185,
      usdVolume: 36986803.916505545,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '44.7900000000000000',
      high: '47.2000000000000000',
      low: '44.2700000000000000',
      close: '46.5500000000000000',
      volume: '500059.4649500000000000',
      quoteVolume: 23044062.553474456,
      btcVolume: 3156.204573659692,
      usdVolume: 23044062.553474456,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '46.5200000000000000',
      high: '46.8600000000000000',
      low: '44.6900000000000000',
      close: '46.2100000000000000',
      volume: '401085.4795800000000000',
      quoteVolume: 18464427.839610986,
      btcVolume: 2533.6102006732344,
      usdVolume: 18464427.839610986,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '46.1900000000000000',
      high: '46.3900000000000000',
      low: '40.8600000000000000',
      close: '42.2500000000000000',
      volume: '785172.3313100000000000',
      quoteVolume: 33771322.35967942,
      btcVolume: 4856.52724305218,
      usdVolume: 33771322.35967942,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '42.2800000000000000',
      high: '43.1600000000000000',
      low: '41.4300000000000000',
      close: '42.5400000000000000',
      volume: '382360.3213200000000000',
      quoteVolume: 16212942.580929037,
      btcVolume: 2364.227913329805,
      usdVolume: 16212942.580929037,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '42.5400000000000000',
      high: '43.5800000000000000',
      low: '41.8300000000000000',
      close: '42.0000000000000000',
      volume: '499257.0325700000000000',
      quoteVolume: 21342178.133174907,
      btcVolume: 3067.773815331192,
      usdVolume: 21342178.133174907,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '41.9900000000000000',
      high: '41.9900000000000000',
      low: '39.5200000000000000',
      close: '41.2200000000000000',
      volume: '744114.5130600000000000',
      quoteVolume: 30090872.086141095,
      btcVolume: 4470.071976428221,
      usdVolume: 30090872.086141095,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '41.2000000000000000',
      high: '41.8500000000000000',
      low: '40.4700000000000000',
      close: '41.2200000000000000',
      volume: '420837.6883200000000000',
      quoteVolume: 17361900.52885351,
      btcVolume: 2527.268685546851,
      usdVolume: 17361900.52885351,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '41.1900000000000000',
      high: '41.7500000000000000',
      low: '39.0800000000000000',
      close: '39.2200000000000000',
      volume: '460328.1631000000000000',
      quoteVolume: 18685153.46120497,
      btcVolume: 2758.6937261226535,
      usdVolume: 18685153.46120497,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '39.2400000000000000',
      high: '43.7800000000000000',
      low: '37.9100000000000000',
      close: '42.5300000000000000',
      volume: '913086.9777100000000000',
      quoteVolume: 37743900.83684656,
      btcVolume: 5472.020626825356,
      usdVolume: 37743900.83684656,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '42.5200000000000000',
      high: '43.0100000000000000',
      low: '41.6100000000000000',
      close: '42.1900000000000000',
      volume: '407623.0458700000000000',
      quoteVolume: 17197450.73896654,
      btcVolume: 2433.5847695278885,
      usdVolume: 17197450.73896654,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '42.2000000000000000',
      high: '44.4300000000000000',
      low: '41.9800000000000000',
      close: '44.1400000000000000',
      volume: '497104.9427100000000000',
      quoteVolume: 21438290.761193395,
      btcVolume: 2994.1891368990136,
      usdVolume: 21438290.761193395,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '44.1600000000000000',
      high: '44.3000000000000000',
      low: '41.5400000000000000',
      close: '42.2300000000000000',
      volume: '456205.8125200000000000',
      quoteVolume: 19598464.2516532,
      btcVolume: 2739.1972282400675,
      usdVolume: 19598464.2516532,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '42.2300000000000000',
      high: '43.2400000000000000',
      low: '39.5500000000000000',
      close: '40.2100000000000000',
      volume: '543840.0568300000000000',
      quoteVolume: 22495305.856098518,
      btcVolume: 3219.760771868381,
      usdVolume: 22495305.856098518,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '40.2100000000000000',
      high: '41.3400000000000000',
      low: '40.0100000000000000',
      close: '40.5900000000000000',
      volume: '345238.8723200000000000',
      quoteVolume: 14009507.177860197,
      btcVolume: 2044.2849891545281,
      usdVolume: 14009507.177860197,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '40.5900000000000000',
      high: '42.3200000000000000',
      low: '40.2700000000000000',
      close: '41.8000000000000000',
      volume: '462963.2967500000000000',
      quoteVolume: 19150251.779417757,
      btcVolume: 2733.259301365301,
      usdVolume: 19150251.779417757,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '41.8100000000000000',
      high: '43.9100000000000000',
      low: '40.9400000000000000',
      close: '42.9800000000000000',
      volume: '638808.1586600000000000',
      quoteVolume: 27205984.39496531,
      btcVolume: 3698.639315507722,
      usdVolume: 27205984.39496531,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '42.9800000000000000',
      high: '44.9900000000000000',
      low: '42.8300000000000000',
      close: '44.5300000000000000',
      volume: '623497.4206400000000000',
      quoteVolume: 27494508.18459142,
      btcVolume: 3656.2769200491334,
      usdVolume: 27494508.18459142,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '44.5200000000000000',
      high: '45.5800000000000000',
      low: '43.8400000000000000',
      close: '44.3800000000000000',
      volume: '555367.1872400000000000',
      quoteVolume: 24833710.353949737,
      btcVolume: 3290.9485922233407,
      usdVolume: 24833710.353949737,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '44.3800000000000000',
      high: '45.3900000000000000',
      low: '43.7500000000000000',
      close: '44.5200000000000000',
      volume: '485605.1559300000000000',
      quoteVolume: 21633052.172737543,
      btcVolume: 2845.7674049944853,
      usdVolume: 21633052.172737543,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '44.5400000000000000',
      high: '45.2300000000000000',
      low: '43.1600000000000000',
      close: '44.4600000000000000',
      volume: '443570.6602900000000000',
      quoteVolume: 19611892.418601938,
      btcVolume: 2546.7952458477666,
      usdVolume: 19611892.418601938,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '44.4700000000000000',
      high: '46.1300000000000000',
      low: '43.4200000000000000',
      close: '45.6900000000000000',
      volume: '590012.5230400000000000',
      quoteVolume: 26316522.275929503,
      btcVolume: 3404.4738459937435,
      usdVolume: 26316522.275929503,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '45.6900000000000000',
      high: '49.9100000000000000',
      low: '45.6400000000000000',
      close: '48.8100000000000000',
      volume: '1198086.9005900000000000',
      quoteVolume: 56983117.16653303,
      btcVolume: 6840.247516585622,
      usdVolume: 56983117.16653303,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '48.8100000000000000',
      high: '50.8000000000000000',
      low: '45.5900000000000000',
      close: '46.3300000000000000',
      volume: '1404189.5332000000000000',
      quoteVolume: 67399084.98858932,
      btcVolume: 7558.333091499474,
      usdVolume: 67399084.98858932,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '46.3300000000000000',
      high: '48.2700000000000000',
      low: '46.3000000000000000',
      close: '47.0700000000000000',
      volume: '499757.9662500000000000',
      quoteVolume: 23637798.368433,
      btcVolume: 2688.212536886058,
      usdVolume: 23637798.368433,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '47.0800000000000000',
      high: '49.6000000000000000',
      low: '46.7300000000000000',
      close: '49.2000000000000000',
      volume: '408979.1499600000000000',
      quoteVolume: 19681383.60385065,
      btcVolume: 2212.021453381788,
      usdVolume: 19681383.60385065,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '49.2000000000000000',
      high: '50.0200000000000000',
      low: '46.6100000000000000',
      close: '48.0500000000000000',
      volume: '566317.3808100000000000',
      quoteVolume: 27433863.01719666,
      btcVolume: 3063.5466670768265,
      usdVolume: 27433863.01719666,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '48.0700000000000000',
      high: '48.2000000000000000',
      low: '45.1100000000000000',
      close: '46.9600000000000000',
      volume: '693367.0109200000000000',
      quoteVolume: 32216489.944114767,
      btcVolume: 3687.084305406875,
      usdVolume: 32216489.944114767,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '46.9700000000000000',
      high: '47.7600000000000000',
      low: '45.7100000000000000',
      close: '46.5400000000000000',
      volume: '415057.4376200000000000',
      quoteVolume: 19397806.104704224,
      btcVolume: 2173.7880200232094,
      usdVolume: 19397806.104704224,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '46.5600000000000000',
      high: '47.5500000000000000',
      low: '44.5500000000000000',
      close: '44.9400000000000000',
      volume: '466241.7278500000000000',
      quoteVolume: 21723146.905489907,
      btcVolume: 2368.579458205406,
      usdVolume: 21723146.905489907,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '44.9200000000000000',
      high: '48.2200000000000000',
      low: '44.3700000000000000',
      close: '47.3700000000000000',
      volume: '796061.5993300000000000',
      quoteVolume: 37063053.8901233,
      btcVolume: 3872.7360538266807,
      usdVolume: 37063053.8901233,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '47.3700000000000000',
      high: '48.4300000000000000',
      low: '46.3400000000000000',
      close: '47.6900000000000000',
      volume: '631882.8103600000000000',
      quoteVolume: 30005956.286906376,
      btcVolume: 3025.61774026537,
      usdVolume: 30005956.286906376,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '47.7000000000000000',
      high: '49.1000000000000000',
      low: '46.7500000000000000',
      close: '46.9500000000000000',
      volume: '656199.9985000000000000',
      quoteVolume: 31531059.60198355,
      btcVolume: 3242.069854321196,
      usdVolume: 31531059.60198355,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '46.9700000000000000',
      high: '47.0400000000000000',
      low: '39.4000000000000000',
      close: '42.0600000000000000',
      volume: '1404081.1754800000000000',
      quoteVolume: 58997967.78654685,
      btcVolume: 6799.973073584511,
      usdVolume: 58997967.78654685,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '42.0500000000000000',
      high: '42.9000000000000000',
      low: '39.3700000000000000',
      close: '41.5100000000000000',
      volume: '955643.7080500000000000',
      quoteVolume: 39459230.38420516,
      btcVolume: 4550.364445794178,
      usdVolume: 39459230.38420516,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '41.4900000000000000',
      high: '42.8800000000000000',
      low: '41.2600000000000000',
      close: '42.1800000000000000',
      volume: '598291.6399500000000000',
      quoteVolume: 25210189.417792954,
      btcVolume: 2873.5800124011444,
      usdVolume: 25210189.417792954,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '42.1800000000000000',
      high: '43.7400000000000000',
      low: '41.9400000000000000',
      close: '43.1800000000000000',
      volume: '401563.4178500000000000',
      quoteVolume: 17187427.88953854,
      btcVolume: 1892.7048671079617,
      usdVolume: 17187427.88953854,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '43.1800000000000000',
      high: '44.3800000000000000',
      low: '42.2100000000000000',
      close: '43.9800000000000000',
      volume: '507436.3497400000000000',
      quoteVolume: 22075904.008925483,
      btcVolume: 2303.152931180216,
      usdVolume: 22075904.008925483,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '43.9900000000000000',
      high: '44.1400000000000000',
      low: '41.8200000000000000',
      close: '42.7400000000000000',
      volume: '468825.9372000000000000',
      quoteVolume: 20205683.375076648,
      btcVolume: 2131.6645117325697,
      usdVolume: 20205683.375076648,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '42.7300000000000000',
      high: '43.8900000000000000',
      low: '42.5000000000000000',
      close: '43.2500000000000000',
      volume: '435940.4069700000000000',
      quoteVolume: 18877872.465006832,
      btcVolume: 2006.2006217660262,
      usdVolume: 18877872.465006832,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '43.2500000000000000',
      high: '44.4900000000000000',
      low: '43.1000000000000000',
      close: '43.7100000000000000',
      volume: '344451.6233800000000000',
      quoteVolume: 15152133.939513858,
      btcVolume: 1573.049518404476,
      usdVolume: 15152133.939513858,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '43.7000000000000000',
      high: '46.5900000000000000',
      low: '43.6400000000000000',
      close: '45.3200000000000000',
      volume: '694268.3291300000000000',
      quoteVolume: 31428313.748912312,
      btcVolume: 3215.1275437118916,
      usdVolume: 31428313.748912312,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '45.3300000000000000',
      high: '45.8000000000000000',
      low: '44.0700000000000000',
      close: '45.7300000000000000',
      volume: '375917.6522500000000000',
      quoteVolume: 16910574.08702791,
      btcVolume: 1747.0177395296405,
      usdVolume: 16910574.08702791,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '45.7300000000000000',
      high: '46.1400000000000000',
      low: '43.1700000000000000',
      close: '44.2500000000000000',
      volume: '546255.0060000000000000',
      quoteVolume: 24521814.476579513,
      btcVolume: 2542.608320507337,
      usdVolume: 24521814.476579513,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '44.2400000000000000',
      high: '44.6200000000000000',
      low: '41.6600000000000000',
      close: '42.7300000000000000',
      volume: '504821.8913700000000000',
      quoteVolume: 21790870.5584972,
      btcVolume: 2362.9361362732657,
      usdVolume: 21790870.5584972,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '42.7200000000000000',
      high: '44.6800000000000000',
      low: '42.3500000000000000',
      close: '44.2300000000000000',
      volume: '398405.5385600000000000',
      quoteVolume: 17376646.5729942,
      btcVolume: 1903.3033669829076,
      usdVolume: 17376646.5729942,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '44.2200000000000000',
      high: '44.7400000000000000',
      low: '43.6700000000000000',
      close: '43.8500000000000000',
      volume: '237463.4446900000000000',
      quoteVolume: 10482358.645321624,
      btcVolume: 1139.0076516333727,
      usdVolume: 10482358.645321624,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '43.8400000000000000',
      high: '44.5500000000000000',
      low: '42.0400000000000000',
      close: '42.0800000000000000',
      volume: '360033.5515400000000000',
      quoteVolume: 15679863.525856707,
      btcVolume: 1726.0693649842788,
      usdVolume: 15679863.525856707,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '42.0800000000000000',
      high: '43.3200000000000000',
      low: '41.7200000000000000',
      close: '42.9700000000000000',
      volume: '250914.5274100000000000',
      quoteVolume: 10724204.717699511,
      btcVolume: 1217.8718797560175,
      usdVolume: 10724204.717699511,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '42.9900000000000000',
      high: '43.0700000000000000',
      low: '41.7600000000000000',
      close: '42.2700000000000000',
      volume: '218690.0446300000000000',
      quoteVolume: 9289007.752625285,
      btcVolume: 1049.1031437450883,
      usdVolume: 9289007.752625285,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '42.2600000000000000',
      high: '44.0500000000000000',
      low: '42.2200000000000000',
      close: '43.8500000000000000',
      volume: '282807.4623400000000000',
      quoteVolume: 12241409.404598255,
      btcVolume: 1350.5834483933272,
      usdVolume: 12241409.404598255,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '43.8600000000000000',
      high: '44.8800000000000000',
      low: '43.1500000000000000',
      close: '44.7700000000000000',
      volume: '291358.4733600000000000',
      quoteVolume: 12814294.25871001,
      btcVolume: 1372.8438156303184,
      usdVolume: 12814294.25871001,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '44.7700000000000000',
      high: '45.2900000000000000',
      low: '44.1700000000000000',
      close: '44.4700000000000000',
      volume: '298461.3751300000000000',
      quoteVolume: 13345930.015912458,
      btcVolume: 1409.140378403909,
      usdVolume: 13345930.015912458,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '44.4900000000000000',
      high: '48.0800000000000000',
      low: '44.1800000000000000',
      close: '47.7200000000000000',
      volume: '590070.5535000000000000',
      quoteVolume: 27463513.679888483,
      btcVolume: 2881.444155580501,
      usdVolume: 27463513.679888483,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '47.7200000000000000',
      high: '47.8400000000000000',
      low: '45.0700000000000000',
      close: '45.6100000000000000',
      volume: '494076.1867300000000000',
      quoteVolume: 23046362.233182173,
      btcVolume: 2414.446392609635,
      usdVolume: 23046362.233182173,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '45.6000000000000000',
      high: '48.8100000000000000',
      low: '45.3600000000000000',
      close: '48.2500000000000000',
      volume: '599583.6772200000000000',
      quoteVolume: 28169093.711731534,
      btcVolume: 2895.4119659902226,
      usdVolume: 28169093.711731534,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '48.2600000000000000',
      high: '49.9600000000000000',
      low: '44.0000000000000000',
      close: '45.9900000000000000',
      volume: '920374.0312200000000000',
      quoteVolume: 43606491.94942139,
      btcVolume: 4436.165811723226,
      usdVolume: 43606491.94942139,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '47.5000000000000000',
      low: '45.2500000000000000',
      close: '47.1600000000000000',
      volume: '428190.8216400000000000',
      quoteVolume: 19934098.49867122,
      btcVolume: 2086.467816317527,
      usdVolume: 19934098.49867122,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '47.1500000000000000',
      high: '48.1000000000000000',
      low: '46.1500000000000000',
      close: '47.4300000000000000',
      volume: '471692.4156600000000000',
      quoteVolume: 22364295.281365484,
      btcVolume: 2310.0219355528084,
      usdVolume: 22364295.281365484,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '47.4300000000000000',
      high: '47.9800000000000000',
      low: '46.4200000000000000',
      close: '46.8300000000000000',
      volume: '400835.1846600000000000',
      quoteVolume: 18969882.083338924,
      btcVolume: 1945.0400952427456,
      usdVolume: 18969882.083338924,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '46.8000000000000000',
      high: '47.2400000000000000',
      low: '46.2000000000000000',
      close: '46.7900000000000000',
      volume: '195688.0406400000000000',
      quoteVolume: 9141881.677323475,
      btcVolume: 948.8992424450283,
      usdVolume: 9141881.677323475,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '46.7900000000000000',
      high: '46.9800000000000000',
      low: '45.0600000000000000',
      close: '46.6300000000000000',
      volume: '306262.5414800000000000',
      quoteVolume: 14151417.896596055,
      btcVolume: 1473.8708394166745,
      usdVolume: 14151417.896596055,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '46.6000000000000000',
      high: '46.7900000000000000',
      low: '45.9500000000000000',
      close: '46.4300000000000000',
      volume: '220499.0699300000000000',
      quoteVolume: 10227376.443103297,
      btcVolume: 1051.937083531138,
      usdVolume: 10227376.443103297,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '46.4500000000000000',
      high: '46.8400000000000000',
      low: '45.3100000000000000',
      close: '45.8700000000000000',
      volume: '219582.2168000000000000',
      quoteVolume: 10105379.596295863,
      btcVolume: 1040.202431965599,
      usdVolume: 10105379.596295863,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '45.8900000000000000',
      high: '47.1900000000000000',
      low: '45.6500000000000000',
      close: '46.6300000000000000',
      volume: '319562.9294800000000000',
      quoteVolume: 14778011.431747977,
      btcVolume: 1507.8102529037167,
      usdVolume: 14778011.431747977,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '46.6300000000000000',
      high: '46.9600000000000000',
      low: '42.0000000000000000',
      close: '43.2200000000000000',
      volume: '538312.5504400000000000',
      quoteVolume: 24117129.0557672,
      btcVolume: 2526.1064285493794,
      usdVolume: 24117129.0557672,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '43.2200000000000000',
      high: '45.3600000000000000',
      low: '42.8100000000000000',
      close: '44.7200000000000000',
      volume: '236637.2390700000000000',
      quoteVolume: 10478472.454529103,
      btcVolume: 1112.938106242603,
      usdVolume: 10478472.454529103,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '44.7200000000000000',
      high: '45.2200000000000000',
      low: '44.2700000000000000',
      close: '45.0800000000000000',
      volume: '151582.8051800000000000',
      quoteVolume: 6775955.931185166,
      btcVolume: 718.694564235063,
      usdVolume: 6775955.931185166,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '45.0900000000000000',
      high: '45.1100000000000000',
      low: '43.5300000000000000',
      close: '44.0500000000000000',
      volume: '170082.7402900000000000',
      quoteVolume: 7542598.776623745,
      btcVolume: 802.8177727597499,
      usdVolume: 7542598.776623745,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '44.0400000000000000',
      high: '44.0900000000000000',
      low: '41.7500000000000000',
      close: '43.7300000000000000',
      volume: '355287.5401600000000000',
      quoteVolume: 15285580.752425862,
      btcVolume: 1659.6268802012912,
      usdVolume: 15285580.752425862,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '43.7300000000000000',
      high: '44.2200000000000000',
      low: '43.3300000000000000',
      close: '43.8800000000000000',
      volume: '215557.5907300000000000',
      quoteVolume: 9436824.369403064,
      btcVolume: 993.2645058025514,
      usdVolume: 9436824.369403064,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '43.9000000000000000',
      high: '45.0400000000000000',
      low: '43.0000000000000000',
      close: '44.0700000000000000',
      volume: '324893.8614200000000000',
      quoteVolume: 14346683.24048972,
      btcVolume: 1519.1996398986237,
      usdVolume: 14346683.24048972,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '44.0700000000000000',
      high: '44.2300000000000000',
      low: '42.8800000000000000',
      close: '43.4300000000000000',
      volume: '193534.9893800000000000',
      quoteVolume: 8454598.742349433,
      btcVolume: 898.8378626695845,
      usdVolume: 8454598.742349433,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '43.4300000000000000',
      high: '43.7300000000000000',
      low: '42.5700000000000000',
      close: '42.8900000000000000',
      volume: '304810.9753600000000000',
      quoteVolume: 13177549.12879669,
      btcVolume: 1412.1592884165075,
      usdVolume: 13177549.12879669,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '42.8900000000000000',
      high: '43.8500000000000000',
      low: '42.2100000000000000',
      close: '43.5700000000000000',
      volume: '202428.9463000000000000',
      quoteVolume: 8709765.120769074,
      btcVolume: 936.1793726946369,
      usdVolume: 8709765.120769074,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '43.5700000000000000',
      high: '43.8300000000000000',
      low: '42.8800000000000000',
      close: '43.0000000000000000',
      volume: '159632.6785000000000000',
      quoteVolume: 6913195.771169413,
      btcVolume: 739.0315965348125,
      usdVolume: 6913195.771169413,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '43.0000000000000000',
      high: '44.6700000000000000',
      low: '42.9400000000000000',
      close: '44.3000000000000000',
      volume: '281062.1117900000000000',
      quoteVolume: 12346345.856977155,
      btcVolume: 1299.505700098188,
      usdVolume: 12346345.856977155,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '44.3000000000000000',
      high: '44.4900000000000000',
      low: '43.8900000000000000',
      close: '44.1800000000000000',
      volume: '219363.9022300000000000',
      quoteVolume: 9684310.145484513,
      btcVolume: 1004.7333264570984,
      usdVolume: 9684310.145484513,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '44.1800000000000000',
      high: '44.6900000000000000',
      low: '42.1000000000000000',
      close: '42.6500000000000000',
      volume: '357937.9722300000000000',
      quoteVolume: 15461096.439797288,
      btcVolume: 1637.8654486722658,
      usdVolume: 15461096.439797288,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '42.6500000000000000',
      high: '43.0000000000000000',
      low: '41.6800000000000000',
      close: '42.3500000000000000',
      volume: '241972.0063200000000000',
      quoteVolume: 10274173.075952465,
      btcVolume: 1114.9908980390967,
      usdVolume: 10274173.075952465,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '42.3400000000000000',
      high: '43.6000000000000000',
      low: '41.3700000000000000',
      close: '43.0900000000000000',
      volume: '348379.3200800000000000',
      quoteVolume: 14852775.950750457,
      btcVolume: 1618.6751008852393,
      usdVolume: 14852775.950750457,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '43.1000000000000000',
      high: '43.1200000000000000',
      low: '39.1100000000000000',
      close: '41.0600000000000000',
      volume: '301504.6343000000000000',
      quoteVolume: 12500262.768287318,
      btcVolume: 1381.4322759722381,
      usdVolume: 12500262.768287318,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '41.0400000000000000',
      high: '42.0500000000000000',
      low: '40.4800000000000000',
      close: '41.3700000000000000',
      volume: '169011.9868500000000000',
      quoteVolume: 6984592.714551371,
      btcVolume: 769.65230482301,
      usdVolume: 6984592.714551371,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '41.3700000000000000',
      high: '42.2900000000000000',
      low: '40.5900000000000000',
      close: '41.8700000000000000',
      volume: '323346.1720000000000000',
      quoteVolume: 13400088.961560369,
      btcVolume: 1466.6645153508061,
      usdVolume: 13400088.961560369,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '41.8700000000000000',
      high: '42.0700000000000000',
      low: '40.7300000000000000',
      close: '41.1700000000000000',
      volume: '170712.3676100000000000',
      quoteVolume: 7047775.850108025,
      btcVolume: 770.4331270254231,
      usdVolume: 7047775.850108025,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '41.1600000000000000',
      high: '42.0100000000000000',
      low: '40.9600000000000000',
      close: '41.7700000000000000',
      volume: '189395.4022900000000000',
      quoteVolume: 7873933.718510014,
      btcVolume: 856.8015905668915,
      usdVolume: 7873933.718510014,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '41.7600000000000000',
      high: '41.9000000000000000',
      low: '40.2800000000000000',
      close: '41.0500000000000000',
      volume: '274520.0541700000000000',
      quoteVolume: 11294890.195721142,
      btcVolume: 1236.9147850457389,
      usdVolume: 11294890.195721142,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '41.0300000000000000',
      high: '41.7000000000000000',
      low: '40.8900000000000000',
      close: '41.1400000000000000',
      volume: '188681.9363500000000000',
      quoteVolume: 7794144.723730499,
      btcVolume: 857.0715613494609,
      usdVolume: 7794144.723730499,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '41.1300000000000000',
      high: '42.2300000000000000',
      low: '41.0600000000000000',
      close: '42.1100000000000000',
      volume: '224248.6520000000000000',
      quoteVolume: 9360266.498081032,
      btcVolume: 1028.5227163525683,
      usdVolume: 9360266.498081032,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '42.1100000000000000',
      high: '42.2000000000000000',
      low: '40.7500000000000000',
      close: '41.5800000000000000',
      volume: '226360.8260500000000000',
      quoteVolume: 9392173.28080973,
      btcVolume: 1037.076979817458,
      usdVolume: 9392173.28080973,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '41.5800000000000000',
      high: '44.3100000000000000',
      low: '41.5000000000000000',
      close: '44.0600000000000000',
      volume: '368489.7151600000000000',
      quoteVolume: 15857759.209187848,
      btcVolume: 1716.882729781821,
      usdVolume: 15857759.209187848,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '44.0700000000000000',
      high: '44.4900000000000000',
      low: '43.0600000000000000',
      close: '43.3900000000000000',
      volume: '307753.4437600000000000',
      quoteVolume: 13417182.820945997,
      btcVolume: 1447.5272221888722,
      usdVolume: 13417182.820945997,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '43.3900000000000000',
      high: '45.6200000000000000',
      low: '43.1000000000000000',
      close: '45.3500000000000000',
      volume: '527238.1869100000000000',
      quoteVolume: 23640875.868721846,
      btcVolume: 2531.3426117904874,
      usdVolume: 23640875.868721846,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '45.3500000000000000',
      high: '45.8700000000000000',
      low: '43.5700000000000000',
      close: '44.3300000000000000',
      volume: '413262.0522600000000000',
      quoteVolume: 18508468.305630103,
      btcVolume: 1984.9220768982761,
      usdVolume: 18508468.305630103,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '44.3400000000000000',
      high: '44.6400000000000000',
      low: '43.4400000000000000',
      close: '44.3000000000000000',
      volume: '256685.4454300000000000',
      quoteVolume: 11325710.672338482,
      btcVolume: 1231.7303880489342,
      usdVolume: 11325710.672338482,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '44.3200000000000000',
      high: '44.7700000000000000',
      low: '43.8500000000000000',
      close: '44.6900000000000000',
      volume: '175099.4520400000000000',
      quoteVolume: 7760961.70704716,
      btcVolume: 840.2449316980167,
      usdVolume: 7760961.70704716,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '44.6800000000000000',
      high: '45.3000000000000000',
      low: '43.7000000000000000',
      close: '44.7000000000000000',
      volume: '245471.1214700000000000',
      quoteVolume: 10954049.958728487,
      btcVolume: 1184.221349967855,
      usdVolume: 10954049.958728487,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '44.7200000000000000',
      high: '45.5900000000000000',
      low: '42.3100000000000000',
      close: '43.9200000000000000',
      volume: '445103.8423000000000000',
      quoteVolume: 19783707.239249174,
      btcVolume: 2134.0023954961785,
      usdVolume: 19783707.239249174,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '43.9100000000000000',
      high: '44.0900000000000000',
      low: '42.8600000000000000',
      close: '43.8800000000000000',
      volume: '246958.9888000000000000',
      quoteVolume: 10753439.352485811,
      btcVolume: 1167.1898197526466,
      usdVolume: 10753439.352485811,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '43.8800000000000000',
      high: '44.0600000000000000',
      low: '42.8100000000000000',
      close: '43.3300000000000000',
      volume: '216931.7592000000000000',
      quoteVolume: 9414665.121901916,
      btcVolume: 1020.4055870355884,
      usdVolume: 9414665.121901916,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '43.3400000000000000',
      high: '43.4900000000000000',
      low: '40.8500000000000000',
      close: '42.0900000000000000',
      volume: '389747.3708100000000000',
      quoteVolume: 16388849.494132191,
      btcVolume: 1793.8588392303009,
      usdVolume: 16388849.494132191,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '42.0900000000000000',
      high: '42.4900000000000000',
      low: '41.7200000000000000',
      close: '41.9000000000000000',
      volume: '201168.4514700000000000',
      quoteVolume: 8451330.732305363,
      btcVolume: 925.0899826232296,
      usdVolume: 8451330.732305363,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '41.8800000000000000',
      high: '42.9000000000000000',
      low: '41.7300000000000000',
      close: '42.4500000000000000',
      volume: '171429.4827800000000000',
      quoteVolume: 7261317.07974402,
      btcVolume: 792.6440309584311,
      usdVolume: 7261317.07974402,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '42.4500000000000000',
      high: '42.8900000000000000',
      low: '41.9400000000000000',
      close: '42.6400000000000000',
      volume: '131250.1648000000000000',
      quoteVolume: 5562354.392689597,
      btcVolume: 607.4853703600584,
      usdVolume: 5562354.392689597,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '42.6500000000000000',
      high: '42.7900000000000000',
      low: '41.3700000000000000',
      close: '41.9400000000000000',
      volume: '180313.8811200000000000',
      quoteVolume: 7608229.1871632915,
      btcVolume: 829.548965830741,
      usdVolume: 7608229.1871632915,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '41.9400000000000000',
      high: '44.1100000000000000',
      low: '41.8700000000000000',
      close: '43.7700000000000000',
      volume: '314409.7580700000000000',
      quoteVolume: 13652466.385358201,
      btcVolume: 1464.4779697490103,
      usdVolume: 13652466.385358201,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '43.7700000000000000',
      high: '45.1200000000000000',
      low: '43.2000000000000000',
      close: '44.9600000000000000',
      volume: '335284.7529800000000000',
      quoteVolume: 14745570.92134318,
      btcVolume: 1567.9594825294864,
      usdVolume: 14745570.92134318,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '44.9600000000000000',
      high: '45.6300000000000000',
      low: '44.5200000000000000',
      close: '44.9400000000000000',
      volume: '432494.1135100000000000',
      quoteVolume: 19488260.358961288,
      btcVolume: 2044.7602331347512,
      usdVolume: 19488260.358961288,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '44.9300000000000000',
      high: '44.9600000000000000',
      low: '43.9300000000000000',
      close: '44.1100000000000000',
      volume: '246802.5687100000000000',
      quoteVolume: 10968239.44478951,
      btcVolume: 1148.885394620396,
      usdVolume: 10968239.44478951,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '44.1200000000000000',
      high: '49.5200000000000000',
      low: '44.0300000000000000',
      close: '49.1600000000000000',
      volume: '908436.8540300000000000',
      quoteVolume: 43121417.14661318,
      btcVolume: 4487.739810331088,
      usdVolume: 43121417.14661318,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '49.1600000000000000',
      high: '50.7900000000000000',
      low: '47.4900000000000000',
      close: '48.1000000000000000',
      volume: '895757.1956100000000000',
      quoteVolume: 43736445.02248403,
      btcVolume: 4445.709420722064,
      usdVolume: 43736445.02248403,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '48.0700000000000000',
      high: '55.8300000000000000',
      low: '46.8500000000000000',
      close: '53.2800000000000000',
      volume: '1501022.7139000000000000',
      quoteVolume: 76319950.40804677,
      btcVolume: 7196.4237752346735,
      usdVolume: 76319950.40804677,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '53.2900000000000000',
      high: '57.9200000000000000',
      low: '51.6500000000000000',
      close: '55.7500000000000000',
      volume: '1524796.9333900000000000',
      quoteVolume: 83657329.52223343,
      btcVolume: 7615.786009539288,
      usdVolume: 83657329.52223343,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '55.7600000000000000',
      high: '56.9700000000000000',
      low: '54.6500000000000000',
      close: '55.1600000000000000',
      volume: '827762.0175900000000000',
      quoteVolume: 46195373.44725716,
      btcVolume: 4170.565054592356,
      usdVolume: 46195373.44725716,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '55.1700000000000000',
      high: '58.6100000000000000',
      low: '53.9100000000000000',
      close: '56.7500000000000000',
      volume: '1023616.3229500000000000',
      quoteVolume: 57619092.418167934,
      btcVolume: 5228.698800494788,
      usdVolume: 57619092.418167934,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '56.7300000000000000',
      high: '59.2600000000000000',
      low: '55.7800000000000000',
      close: '58.1300000000000000',
      volume: '656214.7910400000000000',
      quoteVolume: 37799292.05627845,
      btcVolume: 3370.0313097064472,
      usdVolume: 37799292.05627845,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '58.1300000000000000',
      high: '62.2300000000000000',
      low: '57.5500000000000000',
      close: '61.6500000000000000',
      volume: '1209772.3068500000000000',
      quoteVolume: 72750836.99199294,
      btcVolume: 6265.125426344265,
      usdVolume: 72750836.99199294,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '61.6500000000000000',
      high: '65.1700000000000000',
      low: '51.7900000000000000',
      close: '56.7700000000000000',
      volume: '1811190.5919400000000000',
      quoteVolume: 106128081.26600313,
      btcVolume: 9335.06038904262,
      usdVolume: 106128081.26600313,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '56.7700000000000000',
      high: '60.0300000000000000',
      low: '55.7100000000000000',
      close: '58.4800000000000000',
      volume: '825040.8628300000000000',
      quoteVolume: 48042233.88053324,
      btcVolume: 4268.170005799202,
      usdVolume: 48042233.88053324,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '58.5100000000000000',
      high: '59.6200000000000000',
      low: '56.2600000000000000',
      close: '57.6100000000000000',
      volume: '621412.6135200000000000',
      quoteVolume: 35953617.97974933,
      btcVolume: 3203.314765910491,
      usdVolume: 35953617.97974933,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '57.6200000000000000',
      high: '59.7700000000000000',
      low: '56.7300000000000000',
      close: '58.8800000000000000',
      volume: '716365.4747300000000000',
      quoteVolume: 41978222.97824183,
      btcVolume: 3649.591519738918,
      usdVolume: 41978222.97824183,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '58.8800000000000000',
      high: '60.6800000000000000',
      low: '57.6900000000000000',
      close: '59.0300000000000000',
      volume: '822577.3453400000000000',
      quoteVolume: 48695999.688867584,
      btcVolume: 4138.214894335373,
      usdVolume: 48695999.688867584,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '59.0300000000000000',
      high: '61.0800000000000000',
      low: '54.5100000000000000',
      close: '57.1700000000000000',
      volume: '897778.4995300000000000',
      quoteVolume: 52381526.540909186,
      btcVolume: 4494.798690246006,
      usdVolume: 52381526.540909186,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '57.1900000000000000',
      high: '58.5500000000000000',
      low: '56.4600000000000000',
      close: '58.4600000000000000',
      volume: '428068.0562900000000000',
      quoteVolume: 24639956.16664136,
      btcVolume: 2111.277753614711,
      usdVolume: 24639956.16664136,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '58.4600000000000000',
      high: '58.6700000000000000',
      low: '56.0000000000000000',
      close: '56.9600000000000000',
      volume: '368126.2206100000000000',
      quoteVolume: 21112826.33196615,
      btcVolume: 1810.1238543621755,
      usdVolume: 21112826.33196615,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '56.9600000000000000',
      high: '59.4000000000000000',
      low: '56.3800000000000000',
      close: '58.2700000000000000',
      volume: '651148.7946500000000000',
      quoteVolume: 37767773.986994274,
      btcVolume: 3179.571790810527,
      usdVolume: 37767773.986994274,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '58.2700000000000000',
      high: '59.6700000000000000',
      low: '52.3700000000000000',
      close: '54.1000000000000000',
      volume: '887598.7192800000000000',
      quoteVolume: 49937919.23003544,
      btcVolume: 4305.809808019921,
      usdVolume: 49937919.23003544,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '54.1100000000000000',
      high: '55.0500000000000000',
      low: '51.5500000000000000',
      close: '54.5200000000000000',
      volume: '645092.4992200000000000',
      quoteVolume: 34861226.1993341,
      btcVolume: 3047.8872072032136,
      usdVolume: 34861226.1993341,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '54.5400000000000000',
      high: '57.3000000000000000',
      low: '51.8600000000000000',
      close: '57.1400000000000000',
      volume: '837108.8358500000000000',
      quoteVolume: 45688848.09087257,
      btcVolume: 3954.229249874195,
      usdVolume: 45688848.09087257,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '57.1400000000000000',
      high: '57.8500000000000000',
      low: '55.8200000000000000',
      close: '56.7800000000000000',
      volume: '724000.2630800000000000',
      quoteVolume: 41120118.16273497,
      btcVolume: 3499.1980018550985,
      usdVolume: 41120118.16273497,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '56.7800000000000000',
      high: '60.8700000000000000',
      low: '56.4500000000000000',
      close: '59.8500000000000000',
      volume: '1137795.1226200000000000',
      quoteVolume: 66736568.52031225,
      btcVolume: 5623.638209743656,
      usdVolume: 66736568.52031225,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '59.8500000000000000',
      high: '64.6000000000000000',
      low: '57.8000000000000000',
      close: '63.8700000000000000',
      volume: '1576897.5925500000000000',
      quoteVolume: 97000374.93973804,
      btcVolume: 8195.032534326576,
      usdVolume: 97000374.93973804,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '63.8900000000000000',
      high: '68.8800000000000000',
      low: '61.3400000000000000',
      close: '67.2200000000000000',
      volume: '1790813.8027600000000000',
      quoteVolume: 116245941.78525142,
      btcVolume: 9624.636426175008,
      usdVolume: 116245941.78525142,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '67.2200000000000000',
      high: '68.3700000000000000',
      low: '63.3700000000000000',
      close: '65.4000000000000000',
      volume: '1562910.4138300000000000',
      quoteVolume: 103317811.94231711,
      btcVolume: 8525.308883804713,
      usdVolume: 103317811.94231711,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '65.4200000000000000',
      high: '67.1500000000000000',
      low: '58.7300000000000000',
      close: '62.1200000000000000',
      volume: '1699324.3872900000000000',
      quoteVolume: 105697900.02547406,
      btcVolume: 8977.615215296333,
      usdVolume: 105697900.02547406,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '62.0800000000000000',
      high: '63.6800000000000000',
      low: '61.0000000000000000',
      close: '62.8000000000000000',
      volume: '863943.4751600000000000',
      quoteVolume: 54002617.98938122,
      btcVolume: 4582.333629079296,
      usdVolume: 54002617.98938122,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '62.8000000000000000',
      high: '63.9000000000000000',
      low: '58.7700000000000000',
      close: '59.2000000000000000',
      volume: '1052514.0798200000000000',
      quoteVolume: 64605524.54157742,
      btcVolume: 5505.111606281471,
      usdVolume: 64605524.54157742,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '59.1800000000000000',
      high: '60.5400000000000000',
      low: '57.7600000000000000',
      close: '60.2100000000000000',
      volume: '735565.0401700000000000',
      quoteVolume: 43554757.475364566,
      btcVolume: 3776.8163310064206,
      usdVolume: 43554757.475364566,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '60.2100000000000000',
      high: '61.6300000000000000',
      low: '58.0100000000000000',
      close: '60.5500000000000000',
      volume: '629684.8634600000000000',
      quoteVolume: 37576440.64409044,
      btcVolume: 3233.0834064375513,
      usdVolume: 37576440.64409044,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '60.5600000000000000',
      high: '63.0100000000000000',
      low: '59.9700000000000000',
      close: '62.0200000000000000',
      volume: '687059.2379900000000000',
      quoteVolume: 42496265.57367974,
      btcVolume: 3621.93782544044,
      usdVolume: 42496265.57367974,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '62.0300000000000000',
      high: '62.2900000000000000',
      low: '56.6000000000000000',
      close: '58.3400000000000000',
      volume: '1124054.9217200000000000',
      quoteVolume: 66441420.47979737,
      btcVolume: 5809.195150196892,
      usdVolume: 66441420.47979737,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '58.3300000000000000',
      high: '59.1000000000000000',
      low: '57.0200000000000000',
      close: '58.1000000000000000',
      volume: '663363.6882900000000000',
      quoteVolume: 38514355.9755069,
      btcVolume: 3381.1134622002087,
      usdVolume: 38514355.9755069,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '58.1100000000000000',
      high: '59.2400000000000000',
      low: '53.9100000000000000',
      close: '56.1000000000000000',
      volume: '973405.5399700000000000',
      quoteVolume: 55038359.140136294,
      btcVolume: 4861.832698425037,
      usdVolume: 55038359.140136294,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '56.1200000000000000',
      high: '58.2800000000000000',
      low: '55.3300000000000000',
      close: '57.3800000000000000',
      volume: '637283.3690700000000000',
      quoteVolume: 36330743.38773648,
      btcVolume: 3176.5984724538266,
      usdVolume: 36330743.38773648,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '57.3600000000000000',
      high: '58.1800000000000000',
      low: '56.9300000000000000',
      close: '56.9600000000000000',
      volume: '401337.7699700000000000',
      quoteVolume: 23096156.99058844,
      btcVolume: 2008.145085599693,
      usdVolume: 23096156.99058844,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '56.9400000000000000',
      high: '62.9500000000000000',
      low: '56.9400000000000000',
      close: '62.8900000000000000',
      volume: '748060.0688700000000000',
      quoteVolume: 45090938.55684995,
      btcVolume: 3882.7301382338846,
      usdVolume: 45090938.55684995,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '62.8900000000000000',
      high: '63.7000000000000000',
      low: '60.5100000000000000',
      close: '60.9200000000000000',
      volume: '680146.7862100000000000',
      quoteVolume: 42096579.01839404,
      btcVolume: 3604.5811924799323,
      usdVolume: 42096579.01839404,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '60.9400000000000000',
      high: '64.3100000000000000',
      low: '59.6900000000000000',
      close: '62.6900000000000000',
      volume: '858839.7179200000000000',
      quoteVolume: 53770235.135081835,
      btcVolume: 4530.7995896947705,
      usdVolume: 53764694.36727703,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '62.6800000000000000',
      high: '63.1900000000000000',
      low: '56.3200000000000000',
      close: '58.0600000000000000',
      volume: '931097.5278800000000000',
      quoteVolume: 55440952.94712479,
      btcVolume: 4813.422832983661,
      usdVolume: 55440952.94712479,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '58.0700000000000000',
      high: '58.6200000000000000',
      low: '45.3300000000000000',
      close: '47.2800000000000000',
      volume: '1314623.3469700000000000',
      quoteVolume: 70288090.06395356,
      btcVolume: 6485.293399090235,
      usdVolume: 70288090.06395356,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '47.2800000000000000',
      high: '52.3800000000000000',
      low: '46.2400000000000000',
      close: '50.3200000000000000',
      volume: '1475370.2928000000000000',
      quoteVolume: 72616532.67811747,
      btcVolume: 7023.38061709872,
      usdVolume: 72616532.67811747,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '50.3300000000000000',
      high: '51.3200000000000000',
      low: '45.2100000000000000',
      close: '47.8300000000000000',
      volume: '1350136.6749200000000000',
      quoteVolume: 64913830.12016042,
      btcVolume: 6374.162126272181,
      usdVolume: 64913830.12016042,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '47.8200000000000000',
      high: '49.2500000000000000',
      low: '46.2800000000000000',
      close: '47.9200000000000000',
      volume: '652628.8199300000000000',
      quoteVolume: 31304000.690226022,
      btcVolume: 3068.8890835351963,
      usdVolume: 31304000.690226022,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '47.9300000000000000',
      high: '49.1800000000000000',
      low: '45.2200000000000000',
      close: '48.8400000000000000',
      volume: '811120.8419300000000000',
      quoteVolume: 38431332.983129896,
      btcVolume: 3795.0340089993906,
      usdVolume: 38431332.983129896,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '48.8500000000000000',
      high: '49.3500000000000000',
      low: '45.9800000000000000',
      close: '47.4700000000000000',
      volume: '760182.5056400000000000',
      quoteVolume: 36217916.465697624,
      btcVolume: 3579.288705742158,
      usdVolume: 36217916.465697624,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '47.4700000000000000',
      high: '48.8100000000000000',
      low: '46.5200000000000000',
      close: '47.8200000000000000',
      volume: '378772.5075900000000000',
      quoteVolume: 18086146.485945318,
      btcVolume: 1776.629629881756,
      usdVolume: 18086146.485945318,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '47.8200000000000000',
      high: '49.4500000000000000',
      low: '47.7700000000000000',
      close: '48.6700000000000000',
      volume: '472784.4530600000000000',
      quoteVolume: 23042794.85121679,
      btcVolume: 2228.667738928336,
      usdVolume: 23042794.85121679,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '48.6500000000000000',
      high: '49.5900000000000000',
      low: '47.3700000000000000',
      close: '48.9600000000000000',
      volume: '367092.2208900000000000',
      quoteVolume: 17795692.856570706,
      btcVolume: 1728.2423967171549,
      usdVolume: 17795692.856570706,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '48.9500000000000000',
      high: '51.3100000000000000',
      low: '48.3800000000000000',
      close: '50.8000000000000000',
      volume: '451940.7079000000000000',
      quoteVolume: 22526331.35838648,
      btcVolume: 2165.612033112267,
      usdVolume: 22526331.35838648,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '50.8200000000000000',
      high: '51.0400000000000000',
      low: '47.2600000000000000',
      close: '48.1500000000000000',
      volume: '458828.4182100000000000',
      quoteVolume: 22491968.402588304,
      btcVolume: 2169.5843476754585,
      usdVolume: 22491968.402588304,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '48.1700000000000000',
      high: '49.6500000000000000',
      low: '47.1100000000000000',
      close: '49.0800000000000000',
      volume: '493683.7537500000000000',
      quoteVolume: 23889800.051954094,
      btcVolume: 2275.1908831815435,
      usdVolume: 23889800.051954094,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '49.0800000000000000',
      high: '50.3700000000000000',
      low: '48.1000000000000000',
      close: '48.3100000000000000',
      volume: '615374.4693200000000000',
      quoteVolume: 30324133.006873846,
      btcVolume: 2816.2117686878037,
      usdVolume: 30324133.006873846,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '48.2900000000000000',
      high: '48.7500000000000000',
      low: '46.9100000000000000',
      close: '47.7600000000000000',
      volume: '409293.5778300000000000',
      quoteVolume: 19641762.865069613,
      btcVolume: 1806.5374075469983,
      usdVolume: 19641762.865069613,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '47.7600000000000000',
      high: '49.6700000000000000',
      low: '47.6400000000000000',
      close: '48.8500000000000000',
      volume: '412392.2905700000000000',
      quoteVolume: 20027421.966672275,
      btcVolume: 1836.6245811757067,
      usdVolume: 20027421.966672275,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '48.8500000000000000',
      high: '49.0700000000000000',
      low: '47.4400000000000000',
      close: '48.5400000000000000',
      volume: '322351.6253900000000000',
      quoteVolume: 15606108.2084333,
      btcVolume: 1428.8963360394227,
      usdVolume: 15606108.2084333,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '48.5300000000000000',
      high: '49.0300000000000000',
      low: '47.9400000000000000',
      close: '48.5200000000000000',
      volume: '225043.6954300000000000',
      quoteVolume: 10911627.63864736,
      btcVolume: 989.4437357340863,
      usdVolume: 10911627.63864736,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '48.5100000000000000',
      high: '48.5100000000000000',
      low: '46.1500000000000000',
      close: '47.0900000000000000',
      volume: '307000.2131400000000000',
      quoteVolume: 14530136.545319801,
      btcVolume: 1333.6149737245325,
      usdVolume: 14530136.545319801,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '47.1000000000000000',
      high: '47.6400000000000000',
      low: '41.5900000000000000',
      close: '43.1600000000000000',
      volume: '724381.6674200000000000',
      quoteVolume: 32251827.730186,
      btcVolume: 3041.5168729433744,
      usdVolume: 32251827.730186,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '43.1700000000000000',
      high: '44.7900000000000000',
      low: '42.7500000000000000',
      close: '44.4700000000000000',
      volume: '333108.9347300000000000',
      quoteVolume: 14589016.845933013,
      btcVolume: 1393.625461476955,
      usdVolume: 14589016.845933013,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '44.4700000000000000',
      high: '45.1400000000000000',
      low: '42.2500000000000000',
      close: '42.9600000000000000',
      volume: '416831.7164900000000000',
      quoteVolume: 18334844.49414594,
      btcVolume: 1763.5199781287474,
      usdVolume: 18334844.49414594,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '42.9900000000000000',
      high: '45.2900000000000000',
      low: '42.5600000000000000',
      close: '44.9200000000000000',
      volume: '384786.2813000000000000',
      quoteVolume: 16990572.907504234,
      btcVolume: 1626.0734649737904,
      usdVolume: 16990572.907504234,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '44.9200000000000000',
      high: '46.5800000000000000',
      low: '43.9700000000000000',
      close: '46.0200000000000000',
      volume: '417026.3309900000000000',
      quoteVolume: 18981897.443485588,
      btcVolume: 1782.2563567218074,
      usdVolume: 18981897.443485588,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '46.5600000000000000',
      low: '45.1200000000000000',
      close: '46.0300000000000000',
      volume: '239753.2724600000000000',
      quoteVolume: 11008649.377640275,
      btcVolume: 1027.554331921975,
      usdVolume: 11008649.377640275,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '46.6200000000000000',
      low: '44.5600000000000000',
      close: '46.0500000000000000',
      volume: '299418.6488900000000000',
      quoteVolume: 13705245.681490649,
      btcVolume: 1279.5801104718228,
      usdVolume: 13705245.681490649,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '46.0500000000000000',
      high: '47.1400000000000000',
      low: '44.8000000000000000',
      close: '45.2300000000000000',
      volume: '500207.2502000000000000',
      quoteVolume: 23194876.99856291,
      btcVolume: 2133.7172755091074,
      usdVolume: 23194876.99856291,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '45.2300000000000000',
      high: '45.8500000000000000',
      low: '44.9500000000000000',
      close: '45.6900000000000000',
      volume: '314647.6859400000000000',
      quoteVolume: 14294098.56854367,
      btcVolume: 1332.3540477383756,
      usdVolume: 14294098.56854367,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '45.6800000000000000',
      high: '46.7000000000000000',
      low: '45.4400000000000000',
      close: '46.2400000000000000',
      volume: '293684.9697200000000000',
      quoteVolume: 13501441.41659697,
      btcVolume: 1256.481594643728,
      usdVolume: 13501441.41659697,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '46.2600000000000000',
      high: '48.4600000000000000',
      low: '45.2300000000000000',
      close: '46.2500000000000000',
      volume: '678479.7253200000000000',
      quoteVolume: 31764172.418082513,
      btcVolume: 2962.4967676505066,
      usdVolume: 31764172.418082513,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '46.2000000000000000',
      high: '46.9700000000000000',
      low: '43.1900000000000000',
      close: '44.8800000000000000',
      volume: '514023.5243600000000000',
      quoteVolume: 23159362.416366585,
      btcVolume: 2205.834513704922,
      usdVolume: 23159362.416366585,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '44.8800000000000000',
      high: '45.9700000000000000',
      low: '44.7200000000000000',
      close: '45.5100000000000000',
      volume: '225825.7437200000000000',
      quoteVolume: 10251368.171526043,
      btcVolume: 972.2082293671139,
      usdVolume: 10251368.171526043,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '45.5000000000000000',
      high: '47.0700000000000000',
      low: '45.2800000000000000',
      close: '46.6900000000000000',
      volume: '257423.6618800000000000',
      quoteVolume: 11876813.22842714,
      btcVolume: 1119.7951342568836,
      usdVolume: 11876813.22842714,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '46.6800000000000000',
      high: '47.0800000000000000',
      low: '45.5300000000000000',
      close: '46.3400000000000000',
      volume: '287599.9852800000000000',
      quoteVolume: 13304869.255124534,
      btcVolume: 1243.5815826726844,
      usdVolume: 13304869.255124534,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '46.3500000000000000',
      high: '48.2500000000000000',
      low: '45.5200000000000000',
      close: '45.9900000000000000',
      volume: '650695.8909300000000000',
      quoteVolume: 30393271.881367873,
      btcVolume: 2846.7976903218064,
      usdVolume: 30393271.881367873,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '46.9600000000000000',
      low: '45.0200000000000000',
      close: '46.5500000000000000',
      volume: '384592.7193700000000000',
      quoteVolume: 17704976.198836096,
      btcVolume: 1667.6503606006302,
      usdVolume: 17704976.198836096,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '46.5600000000000000',
      high: '47.5000000000000000',
      low: '45.4500000000000000',
      close: '47.2100000000000000',
      volume: '401592.8257000000000000',
      quoteVolume: 18706424.61098511,
      btcVolume: 1743.2242091557664,
      usdVolume: 18706424.61098511,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '47.2100000000000000',
      high: '48.2000000000000000',
      low: '46.5900000000000000',
      close: '47.7800000000000000',
      volume: '402514.8916800000000000',
      quoteVolume: 19072193.160766963,
      btcVolume: 1736.864381218933,
      usdVolume: 19072193.160766963,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '47.7800000000000000',
      high: '50.4600000000000000',
      low: '47.7600000000000000',
      close: '48.8800000000000000',
      volume: '426163.7387700000000000',
      quoteVolume: 21004622.662101585,
      btcVolume: 1853.0414493767137,
      usdVolume: 21004622.662101585,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '48.8900000000000000',
      high: '51.2700000000000000',
      low: '48.6600000000000000',
      close: '50.4800000000000000',
      volume: '389959.9119800000000000',
      quoteVolume: 19591405.274990585,
      btcVolume: 1723.361127572673,
      usdVolume: 19591405.274990585,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '50.4800000000000000',
      high: '51.5000000000000000',
      low: '49.2400000000000000',
      close: '50.5800000000000000',
      volume: '547647.7248700000000000',
      quoteVolume: 27687729.169129193,
      btcVolume: 2420.2362706464387,
      usdVolume: 27687729.169129193,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '50.5800000000000000',
      high: '50.7100000000000000',
      low: '48.8000000000000000',
      close: '49.8900000000000000',
      volume: '415337.0570800000000000',
      quoteVolume: 20736639.884147868,
      btcVolume: 1812.348882829239,
      usdVolume: 20736639.884147868,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '49.9000000000000000',
      high: '51.0400000000000000',
      low: '49.2700000000000000',
      close: '49.7900000000000000',
      volume: '331002.8019200000000000',
      quoteVolume: 16584880.285257336,
      btcVolume: 1454.7096946875001,
      usdVolume: 16584880.285257336,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '49.7900000000000000',
      high: '50.2400000000000000',
      low: '48.8000000000000000',
      close: '49.5300000000000000',
      volume: '348137.2278700000000000',
      quoteVolume: 17261154.582352445,
      btcVolume: 1514.5854746239897,
      usdVolume: 17261154.582352445,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '49.5200000000000000',
      high: '49.8400000000000000',
      low: '45.6000000000000000',
      close: '47.6000000000000000',
      volume: '603775.0778600000000000',
      quoteVolume: 28593842.01842037,
      btcVolume: 2521.7754293189723,
      usdVolume: 28593842.01842037,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '47.6100000000000000',
      high: '47.6400000000000000',
      low: '46.2800000000000000',
      close: '46.9300000000000000',
      volume: '222889.3661200000000000',
      quoteVolume: 10493526.272867309,
      btcVolume: 925.8634715761125,
      usdVolume: 10493526.272867309,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '46.9200000000000000',
      high: '47.9800000000000000',
      low: '46.7800000000000000',
      close: '47.5400000000000000',
      volume: '164733.2730500000000000',
      quoteVolume: 7805904.884538982,
      btcVolume: 683.1095831981876,
      usdVolume: 7805904.884538982,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '47.5400000000000000',
      high: '48.4400000000000000',
      low: '46.8000000000000000',
      close: '47.9900000000000000',
      volume: '316808.8316300000000000',
      quoteVolume: 15130105.410249878,
      btcVolume: 1303.2892230318398,
      usdVolume: 15130105.410249878,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '47.9900000000000000',
      high: '48.4600000000000000',
      low: '46.3600000000000000',
      close: '46.8800000000000000',
      volume: '399229.1667700000000000',
      quoteVolume: 18895656.11728302,
      btcVolume: 1594.7065459227063,
      usdVolume: 18895656.11728302,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '46.8800000000000000',
      high: '55.0000000000000000',
      low: '46.8500000000000000',
      close: '52.9800000000000000',
      volume: '1425655.0175000000000000',
      quoteVolume: 73309576.45552866,
      btcVolume: 5835.867276617416,
      usdVolume: 73309576.45552866,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '52.9800000000000000',
      high: '56.3100000000000000',
      low: '52.9700000000000000',
      close: '54.3500000000000000',
      volume: '1405462.7678200000000000',
      quoteVolume: 77304160.5002028,
      btcVolume: 5998.580339830083,
      usdVolume: 77304160.5002028,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '54.3500000000000000',
      high: '57.0000000000000000',
      low: '53.0200000000000000',
      close: '55.4200000000000000',
      volume: '955931.4407600000000000',
      quoteVolume: 52751563.52434198,
      btcVolume: 4083.2913725414433,
      usdVolume: 52751563.52434198,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '55.4100000000000000',
      high: '59.6400000000000000',
      low: '54.8300000000000000',
      close: '59.2300000000000000',
      volume: '945743.9995400000000000',
      quoteVolume: 54668377.69328972,
      btcVolume: 4200.363593327066,
      usdVolume: 54668377.69328972,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '59.2200000000000000',
      high: '60.0000000000000000',
      low: '56.5300000000000000',
      close: '58.8400000000000000',
      volume: '906306.0174900000000000',
      quoteVolume: 52834489.40751223,
      btcVolume: 4053.9574657856656,
      usdVolume: 52834489.40751223,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '58.8300000000000000',
      high: '59.3100000000000000',
      low: '54.9400000000000000',
      close: '56.8200000000000000',
      volume: '937112.5894400000000000',
      quoteVolume: 53652363.95019294,
      btcVolume: 4114.583402151287,
      usdVolume: 53652363.95019294,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '56.8000000000000000',
      high: '59.0600000000000000',
      low: '56.4600000000000000',
      close: '57.9100000000000000',
      volume: '841336.6319200000000000',
      quoteVolume: 48609139.49673568,
      btcVolume: 3630.1006218151615,
      usdVolume: 48609139.49673568,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '57.9000000000000000',
      high: '60.5500000000000000',
      low: '54.7100000000000000',
      close: '55.7700000000000000',
      volume: '1108813.1369600000000000',
      quoteVolume: 63758393.118976705,
      btcVolume: 4749.631359213374,
      usdVolume: 63758393.118976705,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '55.7600000000000000',
      high: '56.5700000000000000',
      low: '53.3700000000000000',
      close: '54.8700000000000000',
      volume: '798253.7344200000000000',
      quoteVolume: 44138399.06422936,
      btcVolume: 3326.956263048808,
      usdVolume: 44138399.06422936,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '54.8800000000000000',
      high: '55.7800000000000000',
      low: '52.2000000000000000',
      close: '54.0100000000000000',
      volume: '706074.8976900000000000',
      quoteVolume: 38012823.48380605,
      btcVolume: 2842.8179458148893,
      usdVolume: 38012823.48380605,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '54.0200000000000000',
      high: '56.5000000000000000',
      low: '53.6000000000000000',
      close: '55.7200000000000000',
      volume: '568503.1681100000000000',
      quoteVolume: 31438490.218596324,
      btcVolume: 2284.8493085352666,
      usdVolume: 31438490.218596324,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '55.6900000000000000',
      high: '56.1900000000000000',
      low: '54.0700000000000000',
      close: '55.6000000000000000',
      volume: '369373.8115100000000000',
      quoteVolume: 20377654.865856357,
      btcVolume: 1481.5753298312095,
      usdVolume: 20377654.865856357,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '55.6000000000000000',
      high: '56.8400000000000000',
      low: '53.0100000000000000',
      close: '53.8000000000000000',
      volume: '700498.4928000000000000',
      quoteVolume: 38304588.60460887,
      btcVolume: 2824.797492464806,
      usdVolume: 38304588.60460887,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '53.8200000000000000',
      high: '54.3700000000000000',
      low: '51.1300000000000000',
      close: '53.9600000000000000',
      volume: '671178.3424500000000000',
      quoteVolume: 35476946.64793883,
      btcVolume: 2616.935741127481,
      usdVolume: 35476946.64793883,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '53.9600000000000000',
      high: '55.2400000000000000',
      low: '51.5900000000000000',
      close: '54.6700000000000000',
      volume: '801009.7913300000000000',
      quoteVolume: 42873857.89305916,
      btcVolume: 3085.610113492847,
      usdVolume: 42873857.89305916,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '54.7000000000000000',
      high: '59.7300000000000000',
      low: '54.6200000000000000',
      close: '58.9500000000000000',
      volume: '1366335.6293900000000000',
      quoteVolume: 78053912.16519552,
      btcVolume: 5264.593238274786,
      usdVolume: 78053912.16519552,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '58.9400000000000000',
      high: '63.6800000000000000',
      low: '58.6100000000000000',
      close: '63.2000000000000000',
      volume: '1688091.0752800000000000',
      quoteVolume: 103709490.48633637,
      btcVolume: 6656.930874335542,
      usdVolume: 103709490.48633637,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '63.2200000000000000',
      high: '64.7700000000000000',
      low: '57.0000000000000000',
      close: '58.9000000000000000',
      volume: '1654668.9549600000000000',
      quoteVolume: 101602887.2770486,
      btcVolume: 6659.106944962225,
      usdVolume: 101602887.2770486,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '58.9000000000000000',
      high: '62.0300000000000000',
      low: '58.5200000000000000',
      close: '61.0200000000000000',
      volume: '794156.2220100000000000',
      quoteVolume: 47870580.79491664,
      btcVolume: 3150.0612302343625,
      usdVolume: 47870580.79491664,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '61.0000000000000000',
      high: '61.8600000000000000',
      low: '57.3700000000000000',
      close: '59.2300000000000000',
      volume: '997389.4548800000000000',
      quoteVolume: 59476114.155003875,
      btcVolume: 3879.134836223116,
      usdVolume: 59476114.155003875,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '59.2200000000000000',
      high: '59.6000000000000000',
      low: '57.0400000000000000',
      close: '57.9300000000000000',
      volume: '639971.9687300000000000',
      quoteVolume: 37332965.35725458,
      btcVolume: 2441.6074414528925,
      usdVolume: 37332965.35725458,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '57.9400000000000000',
      high: '60.5300000000000000',
      low: '57.8000000000000000',
      close: '59.2700000000000000',
      volume: '824536.8814700000000000',
      quoteVolume: 49135913.09168669,
      btcVolume: 3157.3304871935893,
      usdVolume: 49135913.09168669,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '59.2800000000000000',
      high: '60.8200000000000000',
      low: '57.6000000000000000',
      close: '60.7200000000000000',
      volume: '830578.2622800000000000',
      quoteVolume: 48949867.85248493,
      btcVolume: 3069.4575024684545,
      usdVolume: 48949867.85248493,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '60.7200000000000000',
      high: '66.6600000000000000',
      low: '60.1400000000000000',
      close: '65.9600000000000000',
      volume: '1890699.0773000000000000',
      quoteVolume: 120417135.62659656,
      btcVolume: 7402.366563715269,
      usdVolume: 120416609.86659656,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '65.9600000000000000',
      high: '66.3200000000000000',
      low: '61.6300000000000000',
      close: '63.8500000000000000',
      volume: '1004682.3311900000000000',
      quoteVolume: 63840748.70659265,
      btcVolume: 3982.6190328362654,
      usdVolume: 63840748.70659265,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '63.8300000000000000',
      high: '64.4300000000000000',
      low: '61.0800000000000000',
      close: '62.2800000000000000',
      volume: '685198.3981500000000000',
      quoteVolume: 42934465.16887135,
      btcVolume: 2687.015722325898,
      usdVolume: 42934465.16887135,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '62.2900000000000000',
      high: '74.6200000000000000',
      low: '62.0800000000000000',
      close: '73.8400000000000000',
      volume: '2523297.8789300000000000',
      quoteVolume: 174212415.64149517,
      btcVolume: 10641.431291066707,
      usdVolume: 174212415.64149517,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '73.8300000000000000',
      high: '77.0000000000000000',
      low: '71.3600000000000000',
      close: '76.3200000000000000',
      volume: '2672837.8382000000000000',
      quoteVolume: 197658068.17998445,
      btcVolume: 11577.927976041276,
      usdVolume: 197658068.17998445,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '76.3200000000000000',
      high: '77.1300000000000000',
      low: '67.7700000000000000',
      close: '73.4200000000000000',
      volume: '2633618.3237700000000000',
      quoteVolume: 192305528.25402403,
      btcVolume: 10784.179482659336,
      usdVolume: 192305528.25402403,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '73.4100000000000000',
      high: '83.4000000000000000',
      low: '71.4700000000000000',
      close: '81.5300000000000000',
      volume: '2733332.0071700000000000',
      quoteVolume: 212853407.31297737,
      btcVolume: 11944.761485430221,
      usdVolume: 212853407.31297737,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '81.5400000000000000',
      high: '85.6300000000000000',
      low: '79.9900000000000000',
      close: '82.5300000000000000',
      volume: '2343217.5381100000000000',
      quoteVolume: 192700587.20860234,
      btcVolume: 10522.118130650888,
      usdVolume: 192700587.20860234,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '82.5500000000000000',
      high: '88.5000000000000000',
      low: '80.1000000000000000',
      close: '87.1400000000000000',
      volume: '2521719.4082800000000000',
      quoteVolume: 212594343.03374535,
      btcVolume: 11359.43501812571,
      usdVolume: 212594343.03374535,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '87.0900000000000000',
      high: '87.9200000000000000',
      low: '77.3000000000000000',
      close: '82.8700000000000000',
      volume: '2048304.5808200000000000',
      quoteVolume: 171408076.02709004,
      btcVolume: 9333.179458237288,
      usdVolume: 171408076.02709004,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '82.8700000000000000',
      high: '90.9300000000000000',
      low: '80.8000000000000000',
      close: '89.0300000000000000',
      volume: '2486393.8341900000000000',
      quoteVolume: 215727635.64653075,
      btcVolume: 11699.840364269925,
      usdVolume: 215727635.64653075,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '89.0400000000000000',
      high: '94.0200000000000000',
      low: '84.6000000000000000',
      close: '89.2500000000000000',
      volume: '2621715.6430300000000000',
      quoteVolume: 235080123.5942224,
      btcVolume: 12570.326121700413,
      usdVolume: 235080123.5942224,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '89.2700000000000000',
      high: '90.7600000000000000',
      low: '78.0500000000000000',
      close: '81.7500000000000000',
      volume: '1623767.0887800000000000',
      quoteVolume: 140421215.4986958,
      btcVolume: 7390.470493802939,
      usdVolume: 140421215.4986958,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '81.7500000000000000',
      high: '83.2900000000000000',
      low: '64.4500000000000000',
      close: '71.0000000000000000',
      volume: '3435959.2951800000000000',
      quoteVolume: 251435782.40591905,
      btcVolume: 14535.673824882117,
      usdVolume: 251435782.40591905,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '70.9900000000000000',
      high: '73.5000000000000000',
      low: '65.3000000000000000',
      close: '69.1500000000000000',
      volume: '1707521.5040200000000000',
      quoteVolume: 118203288.9302594,
      btcVolume: 6968.137207364826,
      usdVolume: 118203288.9302594,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '69.1800000000000000',
      high: '74.9700000000000000',
      low: '68.2600000000000000',
      close: '72.7000000000000000',
      volume: '1482675.1561800000000000',
      quoteVolume: 106143884.81866534,
      btcVolume: 6107.917822619824,
      usdVolume: 106143884.81866534,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '72.6800000000000000',
      high: '81.0700000000000000',
      low: '71.5100000000000000',
      close: '78.9100000000000000',
      volume: '1514393.2172200000000000',
      quoteVolume: 115521557.95323898,
      btcVolume: 6412.697834411257,
      usdVolume: 115521557.95323898,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '78.8900000000000000',
      high: '88.4600000000000000',
      low: '76.6300000000000000',
      close: '87.6500000000000000',
      volume: '2118061.6227200000000000',
      quoteVolume: 176367822.45216078,
      btcVolume: 9216.718286347665,
      usdVolume: 176367822.45216078,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '87.6600000000000000',
      high: '93.0100000000000000',
      low: '79.5600000000000000',
      close: '85.0800000000000000',
      volume: '3495457.6565900000000000',
      quoteVolume: 307020765.6007774,
      btcVolume: 16015.001816947644,
      usdVolume: 307020765.6007774,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '85.0700000000000000',
      high: '90.6300000000000000',
      low: '82.6100000000000000',
      close: '88.9000000000000000',
      volume: '2135078.4793700000000000',
      quoteVolume: 185587960.28621328,
      btcVolume: 9814.274674754284,
      usdVolume: 185587960.28621328,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '88.8900000000000000',
      high: '91.5000000000000000',
      low: '85.4600000000000000',
      close: '89.0000000000000000',
      volume: '1690248.4588500000000000',
      quoteVolume: 149648537.47962323,
      btcVolume: 7763.714935990381,
      usdVolume: 149648537.47962323,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '89.0100000000000000',
      high: '89.8400000000000000',
      low: '77.8000000000000000',
      close: '79.5200000000000000',
      volume: '1701697.8531700000000000',
      quoteVolume: 144315548.66815314,
      btcVolume: 7578.867302398465,
      usdVolume: 144315548.66815314,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '79.5200000000000000',
      high: '84.2800000000000000',
      low: '78.6100000000000000',
      close: '83.1400000000000000',
      volume: '1275970.0537600000000000',
      quoteVolume: 104423917.99398686,
      btcVolume: 5517.332671389263,
      usdVolume: 104423917.99398686,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '83.1100000000000000',
      high: '85.1900000000000000',
      low: '80.3100000000000000',
      close: '83.5500000000000000',
      volume: '921046.7599600000000000',
      quoteVolume: 76405873.32399245,
      btcVolume: 3991.063481423035,
      usdVolume: 76405873.32399245,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '83.5400000000000000',
      high: '86.8700000000000000',
      low: '81.7300000000000000',
      close: '83.3700000000000000',
      volume: '1145446.4740700000000000',
      quoteVolume: 96166838.18654118,
      btcVolume: 5011.269364217304,
      usdVolume: 96166838.18654118,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '83.3700000000000000',
      high: '84.8300000000000000',
      low: '75.4100000000000000',
      close: '76.7600000000000000',
      volume: '1230953.8697500000000000',
      quoteVolume: 98587741.41994153,
      btcVolume: 5229.632704580527,
      usdVolume: 98587741.41994153,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '76.7500000000000000',
      high: '78.1400000000000000',
      low: '72.1300000000000000',
      close: '77.4100000000000000',
      volume: '1378297.5836100000000000',
      quoteVolume: 104644648.13944061,
      btcVolume: 5748.061365408652,
      usdVolume: 104644648.13944061,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '77.4100000000000000',
      high: '77.6600000000000000',
      low: '72.5100000000000000',
      close: '74.7000000000000000',
      volume: '897534.5890900000000000',
      quoteVolume: 67578374.0447259,
      btcVolume: 3697.8440886872677,
      usdVolume: 67578374.0447259,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '74.7700000000000000',
      high: '75.0900000000000000',
      low: '70.1200000000000000',
      close: '72.2000000000000000',
      volume: '1385260.5103000000000000',
      quoteVolume: 99443406.27715112,
      btcVolume: 5548.574129400644,
      usdVolume: 99438023.14967503,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '72.2000000000000000',
      high: '77.5800000000000000',
      low: '71.9700000000000000',
      close: '76.6700000000000000',
      volume: '778073.6457200000000000',
      quoteVolume: 58653684.98446523,
      btcVolume: 3179.4830099512424,
      usdVolume: 58653684.98446523,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '76.6400000000000000',
      high: '84.3800000000000000',
      low: '75.5200000000000000',
      close: '82.2800000000000000',
      volume: '1278882.9832800000000000',
      quoteVolume: 102688853.34340486,
      btcVolume: 5349.804122942844,
      usdVolume: 102688853.34340486,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '82.2700000000000000',
      high: '82.9800000000000000',
      low: '79.1100000000000000',
      close: '82.3500000000000000',
      volume: '1101678.0078500000000000',
      quoteVolume: 89408089.55373186,
      btcVolume: 4664.897724867536,
      usdVolume: 89408089.55373186,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '82.3800000000000000',
      high: '84.9300000000000000',
      low: '80.3900000000000000',
      close: '81.3200000000000000',
      volume: '1261474.3270300000000000',
      quoteVolume: 103669039.39603397,
      btcVolume: 5363.974475859031,
      usdVolume: 103669039.39603397,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '81.3600000000000000',
      high: '93.4400000000000000',
      low: '78.3100000000000000',
      close: '92.9400000000000000',
      volume: '2150469.3674300000000000',
      quoteVolume: 185140134.72739515,
      btcVolume: 9105.005162094782,
      usdVolume: 185140134.72739515,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '92.9400000000000000',
      high: '109.8000000000000000',
      low: '90.0000000000000000',
      close: '101.0100000000000000',
      volume: '4161454.8925300000000000',
      quoteVolume: 414585850.6995076,
      btcVolume: 18207.769039019673,
      usdVolume: 414585850.6995076,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '100.9900000000000000',
      high: '112.8800000000000000',
      low: '99.2400000000000000',
      close: '109.3400000000000000',
      volume: '3140130.3420400000000000',
      quoteVolume: 337503795.3740959,
      btcVolume: 14761.494258487553,
      usdVolume: 337503795.3740959,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '109.3400000000000000',
      high: '124.2400000000000000',
      low: '105.3100000000000000',
      close: '119.8500000000000000',
      volume: '2753360.1997100000000000',
      quoteVolume: 316850965.36465776,
      btcVolume: 13481.095408817218,
      usdVolume: 316850965.36465776,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '119.8500000000000000',
      high: '123.2100000000000000',
      low: '109.7600000000000000',
      close: '114.5800000000000000',
      volume: '2671305.3283900000000000',
      quoteVolume: 313367367.83264947,
      btcVolume: 13298.314869032618,
      usdVolume: 313367367.83264947,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '114.5900000000000000',
      high: '118.6500000000000000',
      low: '100.3100000000000000',
      close: '104.4500000000000000',
      volume: '2580555.1076800000000000',
      quoteVolume: 281116978.1689041,
      btcVolume: 12139.994599410998,
      usdVolume: 281116978.1689041,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '104.4800000000000000',
      high: '114.6300000000000000',
      low: '98.3500000000000000',
      close: '114.3200000000000000',
      volume: '3210319.3252800000000000',
      quoteVolume: 342738420.0010703,
      btcVolume: 14838.635043893324,
      usdVolume: 342738420.0010703,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '114.3100000000000000',
      high: '117.2900000000000000',
      low: '95.2300000000000000',
      close: '102.4500000000000000',
      volume: '3923591.0743400000000000',
      quoteVolume: 424020893.5197978,
      btcVolume: 18035.881882760194,
      usdVolume: 424020893.5197978,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '102.4600000000000000',
      high: '112.3200000000000000',
      low: '98.5500000000000000',
      close: '111.1300000000000000',
      volume: '2696980.5169700000000000',
      quoteVolume: 282469396.37838954,
      btcVolume: 12178.014726858833,
      usdVolume: 282469396.37838954,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '111.1300000000000000',
      high: '128.6300000000000000',
      low: '108.1800000000000000',
      close: '127.7800000000000000',
      volume: '3108695.5070400000000000',
      quoteVolume: 369487915.87514305,
      btcVolume: 15293.283381962749,
      usdVolume: 369487915.87514305,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '127.7400000000000000',
      high: '135.1900000000000000',
      low: '124.9400000000000000',
      close: '129.6100000000000000',
      volume: '3461075.6473000000000000',
      quoteVolume: 449830180.98402226,
      btcVolume: 17798.908427176073,
      usdVolume: 449830180.98402226,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '129.6100000000000000',
      high: '139.4700000000000000',
      low: '121.8800000000000000',
      close: '127.8800000000000000',
      volume: '3743173.1300100000000000',
      quoteVolume: 494840941.2477139,
      btcVolume: 18223.64596921554,
      usdVolume: 494840941.2477139,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '127.8300000000000000',
      high: '137.4100000000000000',
      low: '126.3700000000000000',
      close: '129.8300000000000000',
      volume: '2349842.9550200000000000',
      quoteVolume: 309678082.06321186,
      btcVolume: 11483.214550343224,
      usdVolume: 309678082.06321186,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '129.8400000000000000',
      high: '130.9000000000000000',
      low: '120.0000000000000000',
      close: '128.8000000000000000',
      volume: '1732248.3840900000000000',
      quoteVolume: 218608845.40846404,
      btcVolume: 8200.6564058765,
      usdVolume: 218608845.40846404,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '128.8000000000000000',
      high: '133.1100000000000000',
      low: '122.6300000000000000',
      close: '130.0500000000000000',
      volume: '1727676.6410000000000000',
      quoteVolume: 220908109.4346938,
      btcVolume: 7872.871817315565,
      usdVolume: 220908109.4346938,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '130.0600000000000000',
      high: '130.3500000000000000',
      low: '121.9800000000000000',
      close: '124.2100000000000000',
      volume: '1248723.0342400000000000',
      quoteVolume: 156991311.03482178,
      btcVolume: 5449.115523218407,
      usdVolume: 156991311.03482178,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '124.2200000000000000',
      high: '133.9700000000000000',
      low: '122.8900000000000000',
      close: '126.2400000000000000',
      volume: '1536326.1071500000000000',
      quoteVolume: 197120659.81115305,
      btcVolume: 6747.775990258352,
      usdVolume: 197120659.81115305,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '126.2000000000000000',
      high: '142.0000000000000000',
      low: '123.1500000000000000',
      close: '136.9600000000000000',
      volume: '2442266.7650000000000000',
      quoteVolume: 323461384.15540344,
      btcVolume: 10287.35677451215,
      usdVolume: 323461384.15540344,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '136.9400000000000000',
      high: '164.7000000000000000',
      low: '135.3000000000000000',
      close: '161.2400000000000000',
      volume: '3813834.0766900000000000',
      quoteVolume: 568621276.8387065,
      btcVolume: 16926.853812273916,
      usdVolume: 568621276.8387065,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '161.2300000000000000',
      high: '173.6600000000000000',
      low: '139.3100000000000000',
      close: '155.3400000000000000',
      volume: '3530422.2039200000000000',
      quoteVolume: 551040498.8754859,
      btcVolume: 17344.92344775779,
      usdVolume: 551040498.8754859,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '155.3300000000000000',
      high: '162.8000000000000000',
      low: '146.5200000000000000',
      close: '158.4300000000000000',
      volume: '1998175.7975400000000000',
      quoteVolume: 308975694.1353862,
      btcVolume: 9579.752665689979,
      usdVolume: 308975694.1353862,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '158.4600000000000000',
      high: '169.7200000000000000',
      low: '154.9500000000000000',
      close: '168.9200000000000000',
      volume: '1857437.6845700000000000',
      quoteVolume: 302142832.62634295,
      btcVolume: 8680.956043080312,
      usdVolume: 302142832.62634295,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '168.9600000000000000',
      high: '181.2200000000000000',
      low: '161.1800000000000000',
      close: '169.3500000000000000',
      volume: '2479926.5663800000000000',
      quoteVolume: 422479799.42550886,
      btcVolume: 11058.616576435745,
      usdVolume: 422479799.42550886,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '169.3500000000000000',
      high: '181.9200000000000000',
      low: '150.0000000000000000',
      close: '172.1900000000000000',
      volume: '2833341.6404500000000000',
      quoteVolume: 478225816.520269,
      btcVolume: 11964.035801892664,
      usdVolume: 478225816.520269,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '172.2800000000000000',
      high: '178.8000000000000000',
      low: '163.3000000000000000',
      close: '177.2600000000000000',
      volume: '1749781.8497300000000000',
      quoteVolume: 299170389.286915,
      btcVolume: 7425.313037764587,
      usdVolume: 299170389.286915,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '177.2700000000000000',
      high: '185.8300000000000000',
      low: '161.8600000000000000',
      close: '170.0700000000000000',
      volume: '2269089.8322300000000000',
      quoteVolume: 397596277.8818141,
      btcVolume: 10055.323763923585,
      usdVolume: 397596277.8818141,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '170.0600000000000000',
      high: '170.7400000000000000',
      low: '112.7000000000000000',
      close: '138.8700000000000000',
      volume: '4877205.3953600000000000',
      quoteVolume: 662514925.4818044,
      btcVolume: 19588.40860151175,
      usdVolume: 662514925.4818044,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '138.8800000000000000',
      high: '146.8600000000000000',
      low: '128.0000000000000000',
      close: '133.4200000000000000',
      volume: '2477243.9833200000000000',
      quoteVolume: 340391038.4888703,
      btcVolume: 9797.131310819967,
      usdVolume: 340391038.4888703,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '133.4400000000000000',
      high: '148.4600000000000000',
      low: '125.9000000000000000',
      close: '147.3800000000000000',
      volume: '2088185.1472100000000000',
      quoteVolume: 286151973.30492896,
      btcVolume: 8269.541344650439,
      usdVolume: 286151973.30492896,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '147.3900000000000000',
      high: '156.8900000000000000',
      low: '143.5500000000000000',
      close: '152.5000000000000000',
      volume: '2033762.6002700000000000',
      quoteVolume: 304472230.40606844,
      btcVolume: 7883.6115631974335,
      usdVolume: 304472230.40606844,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '152.4600000000000000',
      high: '155.7400000000000000',
      low: '130.0000000000000000',
      close: '143.6500000000000000',
      volume: '1929869.1502000000000000',
      quoteVolume: 279867480.1701219,
      btcVolume: 7532.0663673781455,
      usdVolume: 279867480.1701219,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '143.6600000000000000',
      high: '152.5900000000000000',
      low: '139.2800000000000000',
      close: '143.5200000000000000',
      volume: '1619686.2842200000000000',
      quoteVolume: 236445434.4062732,
      btcVolume: 6406.112755540417,
      usdVolume: 236445434.4062732,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '143.5200000000000000',
      high: '147.0000000000000000',
      low: '136.2200000000000000',
      close: '142.8000000000000000',
      volume: '1379082.3792000000000000',
      quoteVolume: 195777669.99104866,
      btcVolume: 5518.389442894679,
      usdVolume: 195777669.99104866,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '142.7800000000000000',
      high: '154.9900000000000000',
      low: '138.4400000000000000',
      close: '151.9600000000000000',
      volume: '1644918.1028000000000000',
      quoteVolume: 242251163.15367344,
      btcVolume: 6662.692550152778,
      usdVolume: 242251163.15367344,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '151.9700000000000000',
      high: '166.0200000000000000',
      low: '150.1800000000000000',
      close: '151.8600000000000000',
      volume: '2250266.1240300000000000',
      quoteVolume: 358045416.38105613,
      btcVolume: 9715.528233417612,
      usdVolume: 358045416.38105613,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '151.8500000000000000',
      high: '156.1500000000000000',
      low: '138.4000000000000000',
      close: '149.6900000000000000',
      volume: '2037065.0855500000000000',
      quoteVolume: 300099295.2165031,
      btcVolume: 8570.773651988773,
      usdVolume: 300099295.2165031,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '149.6700000000000000',
      high: '150.2500000000000000',
      low: '125.6400000000000000',
      close: '129.7200000000000000',
      volume: '2176119.4788900000000000',
      quoteVolume: 296428620.1166068,
      btcVolume: 9078.008984863776,
      usdVolume: 296428620.1166068,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '129.7000000000000000',
      high: '141.8500000000000000',
      low: '122.7000000000000000',
      close: '137.6600000000000000',
      volume: '1951005.0663900000000000',
      quoteVolume: 263524938.22357655,
      btcVolume: 8423.197856584778,
      usdVolume: 263524938.22357655,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '137.6500000000000000',
      high: '143.5200000000000000',
      low: '135.0500000000000000',
      close: '137.6700000000000000',
      volume: '819478.3417900000000000',
      quoteVolume: 113831601.15240765,
      btcVolume: 3514.611539877437,
      usdVolume: 113831601.15240765,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '137.6600000000000000',
      high: '142.6500000000000000',
      low: '133.7300000000000000',
      close: '141.0100000000000000',
      volume: '917459.8505800000000000',
      quoteVolume: 127591959.12300138,
      btcVolume: 3964.1660200345823,
      usdVolume: 127591959.12300138,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '141.0100000000000000',
      high: '147.8700000000000000',
      low: '136.8300000000000000',
      close: '138.5600000000000000',
      volume: '1297445.3763800000000000',
      quoteVolume: 186110798.1019838,
      btcVolume: 5571.664082584082,
      usdVolume: 186110798.1019838,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '132.7000000000000000',
      high: '136.3000000000000000',
      low: '132.5100000000000000',
      close: '134.6700000000000000',
      volume: '239830.9402400000000000',
      quoteVolume: 32177113.892162576,
      btcVolume: 999.7459445541468,
      usdVolume: 32177113.892162576,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '134.6900000000000000',
      high: '134.9600000000000000',
      low: '118.1300000000000000',
      close: '122.4400000000000000',
      volume: '1112624.2676100000000000',
      quoteVolume: 139464919.21385488,
      btcVolume: 4524.577313776934,
      usdVolume: 139464919.21385488,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '122.4500000000000000',
      high: '135.8400000000000000',
      low: '120.7400000000000000',
      close: '133.2700000000000000',
      volume: '937794.6301600000000000',
      quoteVolume: 121424463.23872843,
      btcVolume: 3823.7182988262484,
      usdVolume: 121424463.23872843,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '133.2500000000000000',
      high: '145.5000000000000000',
      low: '131.4400000000000000',
      close: '134.6500000000000000',
      volume: '1953065.9401100000000000',
      quoteVolume: 269778147.58305985,
      btcVolume: 7637.60669728038,
      usdVolume: 269778147.58305985,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '134.6700000000000000',
      high: '136.2300000000000000',
      low: '128.0200000000000000',
      close: '133.3600000000000000',
      volume: '1171505.9664700000000000',
      quoteVolume: 154495780.49306908,
      btcVolume: 4551.220051793771,
      usdVolume: 154495780.49306908,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '133.3500000000000000',
      high: '135.0600000000000000',
      low: '125.6700000000000000',
      close: '129.3600000000000000',
      volume: '806399.7757100000000000',
      quoteVolume: 104722485.35395245,
      btcVolume: 3138.156642547338,
      usdVolume: 104722485.35395245,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '129.3700000000000000',
      high: '136.6300000000000000',
      low: '125.9500000000000000',
      close: '132.0000000000000000',
      volume: '1135286.5070700000000000',
      quoteVolume: 149340674.82508767,
      btcVolume: 4416.238424240979,
      usdVolume: 149340674.82508767,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '131.9900000000000000',
      high: '145.8200000000000000',
      low: '131.8700000000000000',
      close: '141.9800000000000000',
      volume: '1883149.5372600000000000',
      quoteVolume: 265391340.62986633,
      btcVolume: 7621.335552581734,
      usdVolume: 265391340.62986633,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '141.9600000000000000',
      high: '156.7000000000000000',
      low: '141.9200000000000000',
      close: '155.9300000000000000',
      volume: '1820821.5903600000000000',
      quoteVolume: 276355678.3739926,
      btcVolume: 7583.191926757077,
      usdVolume: 276355678.3739926,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '155.9400000000000000',
      high: '158.6800000000000000',
      low: '141.0300000000000000',
      close: '145.0700000000000000',
      volume: '1762820.3654400000000000',
      quoteVolume: 263148795.56052074,
      btcVolume: 7024.899854343085,
      usdVolume: 263148795.56052074,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '145.0500000000000000',
      high: '156.7800000000000000',
      low: '143.4500000000000000',
      close: '155.0500000000000000',
      volume: '1557577.8368500000000000',
      quoteVolume: 237025412.1601315,
      btcVolume: 6297.118687933591,
      usdVolume: 237025412.1601315,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '155.0500000000000000',
      high: '164.2700000000000000',
      low: '149.8200000000000000',
      close: '155.5700000000000000',
      volume: '1878793.7270300000000000',
      quoteVolume: 298604965.4190308,
      btcVolume: 7537.248027732104,
      usdVolume: 298604965.4190308,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '155.5700000000000000',
      high: '157.2900000000000000',
      low: '145.2300000000000000',
      close: '150.7600000000000000',
      volume: '1259975.9289400000000000',
      quoteVolume: 190631760.74434358,
      btcVolume: 4938.417851396465,
      usdVolume: 190631760.74434358,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '150.7600000000000000',
      high: '169.3800000000000000',
      low: '147.7600000000000000',
      close: '167.2800000000000000',
      volume: '1869837.9437200000000000',
      quoteVolume: 297709749.822012,
      btcVolume: 7033.137189014122,
      usdVolume: 297709749.822012,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '167.2800000000000000',
      high: '184.4900000000000000',
      low: '164.2200000000000000',
      close: '181.4900000000000000',
      volume: '1981149.1236600000000000',
      quoteVolume: 339741920.4317491,
      btcVolume: 7286.474296530587,
      usdVolume: 339741920.4317491,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '181.5200000000000000',
      high: '195.1700000000000000',
      low: '169.5700000000000000',
      close: '181.5600000000000000',
      volume: '2781973.9625400000000000',
      quoteVolume: 511568659.8745071,
      btcVolume: 11171.189003450432,
      usdVolume: 511396513.1418937,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '181.5900000000000000',
      high: '193.5900000000000000',
      low: '175.7200000000000000',
      close: '183.6200000000000000',
      volume: '1662458.2148900000000000',
      quoteVolume: 308980082.707041,
      btcVolume: 6638.340260622761,
      usdVolume: 308980082.707041,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '183.6200000000000000',
      high: '199.8400000000000000',
      low: '176.7500000000000000',
      close: '196.6900000000000000',
      volume: '1944278.4281900000000000',
      quoteVolume: 363675328.6517229,
      btcVolume: 7651.174521048404,
      usdVolume: 363646656.3346562,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '196.7100000000000000',
      high: '229.0700000000000000',
      low: '191.4500000000000000',
      close: '225.7600000000000000',
      volume: '2843476.1869100000000000',
      quoteVolume: 594213638.5294985,
      btcVolume: 12603.247974606615,
      usdVolume: 594155739.24837,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '225.7700000000000000',
      high: '230.7700000000000000',
      low: '208.1100000000000000',
      close: '213.4900000000000000',
      volume: '1654862.3886300000000000',
      quoteVolume: 363531568.69063455,
      btcVolume: 7495.86825872764,
      usdVolume: 363531568.69063455,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '213.4700000000000000',
      high: '219.9900000000000000',
      low: '185.3100000000000000',
      close: '207.8300000000000000',
      volume: '2145096.2026600000000000',
      quoteVolume: 441287107.2575872,
      btcVolume: 9249.958178220597,
      usdVolume: 440751484.0592311,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '208.0300000000000000',
      high: '222.4800000000000000',
      low: '200.1000000000000000',
      close: '210.2600000000000000',
      volume: '1655771.0432500000000000',
      quoteVolume: 350218158.5830828,
      btcVolume: 7007.571698477013,
      usdVolume: 342424004.4173157,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '210.2300000000000000',
      high: '237.7500000000000000',
      low: '203.3700000000000000',
      close: '237.4900000000000000',
      volume: '1781373.1518300000000000',
      quoteVolume: 397616883.22465694,
      btcVolume: 7787.136345561708,
      usdVolume: 397616883.22465694,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '237.3900000000000000',
      high: '239.1800000000000000',
      low: '221.0000000000000000',
      close: '226.5900000000000000',
      volume: '1580820.7100000000000000',
      quoteVolume: 362511271.6078358,
      btcVolume: 6993.752084518185,
      usdVolume: 362511271.6078358,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '226.5800000000000000',
      high: '241.9200000000000000',
      low: '220.0600000000000000',
      close: '236.5000000000000000',
      volume: '1398560.5533800000000000',
      quoteVolume: 324183188.11299986,
      btcVolume: 6114.868427314256,
      usdVolume: 324183188.11299986,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '236.4500000000000000',
      high: '247.0400000000000000',
      low: '214.0200000000000000',
      close: '226.6500000000000000',
      volume: '1699874.2734800000000000',
      quoteVolume: 401212940.2932522,
      btcVolume: 7143.652988554469,
      usdVolume: 401212460.39169174,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '226.7700000000000000',
      high: '233.7800000000000000',
      low: '220.0600000000000000',
      close: '227.1300000000000000',
      volume: '1268407.0304600000000000',
      quoteVolume: 288545534.88444746,
      btcVolume: 5070.698499732074,
      usdVolume: 288545534.88444746,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '227.1700000000000000',
      high: '227.3900000000000000',
      low: '180.0000000000000000',
      close: '208.3200000000000000',
      volume: '2222077.3503300000000000',
      quoteVolume: 460086677.0755931,
      btcVolume: 8037.5535313587925,
      usdVolume: 432162455.0772618,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '208.1000000000000000',
      high: '208.2300000000000000',
      low: '158.8300000000000000',
      close: '177.3400000000000000',
      volume: '3390922.1286900000000000',
      quoteVolume: 606664750.1952728,
      btcVolume: 12391.424200432175,
      usdVolume: 602656639.5134262,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '177.3500000000000000',
      high: '189.4600000000000000',
      low: '169.0800000000000000',
      close: '181.4300000000000000',
      volume: '1385993.1585900000000000',
      quoteVolume: 251534269.16248846,
      btcVolume: 5045.167835396706,
      usdVolume: 251505675.69378206,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '181.4300000000000000',
      high: '205.2100000000000000',
      low: '175.0000000000000000',
      close: '179.3600000000000000',
      volume: '1961972.0422000000000000',
      quoteVolume: 374933085.95019436,
      btcVolume: 7503.165956785665,
      usdVolume: 374933085.95019436,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '179.3600000000000000',
      high: '182.8600000000000000',
      low: '162.7400000000000000',
      close: '170.2500000000000000',
      volume: '2179517.4955500000000000',
      quoteVolume: 376772370.9497997,
      btcVolume: 8092.3230294664,
      usdVolume: 376485294.8561675,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '170.2000000000000000',
      high: '180.4800000000000000',
      low: '167.1300000000000000',
      close: '171.7600000000000000',
      volume: '1113606.4401000000000000',
      quoteVolume: 194721552.36965466,
      btcVolume: 4134.973488951998,
      usdVolume: 194721552.36965466,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '171.7500000000000000',
      high: '173.0000000000000000',
      low: '152.8800000000000000',
      close: '165.2200000000000000',
      volume: '1630049.9177200000000000',
      quoteVolume: 262199243.42310986,
      btcVolume: 5874.032431282306,
      usdVolume: 262199243.42310986,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '165.2700000000000000',
      high: '176.4600000000000000',
      low: '163.3000000000000000',
      close: '175.8900000000000000',
      volume: '1157182.4178100000000000',
      quoteVolume: 196920601.48332697,
      btcVolume: 4133.868226475361,
      usdVolume: 196920601.48332697,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '175.9000000000000000',
      high: '185.3200000000000000',
      low: '170.6900000000000000',
      close: '179.0800000000000000',
      volume: '1441741.0023500000000000',
      quoteVolume: 255346162.55105585,
      btcVolume: 5250.61792323071,
      usdVolume: 255346162.55105585,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '179.0600000000000000',
      high: '196.4900000000000000',
      low: '176.7900000000000000',
      close: '187.6400000000000000',
      volume: '1788998.4592800000000000',
      quoteVolume: 340485879.58694166,
      btcVolume: 6762.4933075880845,
      usdVolume: 340485879.58694166,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '187.7000000000000000',
      high: '194.4000000000000000',
      low: '176.8300000000000000',
      close: '180.6700000000000000',
      volume: '1484950.5713600000000000',
      quoteVolume: 275283690.3275003,
      btcVolume: 5567.250076468444,
      usdVolume: 275230426.05999345,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '180.6600000000000000',
      high: '183.5700000000000000',
      low: '168.5000000000000000',
      close: '180.2300000000000000',
      volume: '1182501.6988200000000000',
      quoteVolume: 206736683.42664367,
      btcVolume: 4343.41978808486,
      usdVolume: 206736683.42664367,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '180.2100000000000000',
      high: '184.6700000000000000',
      low: '174.6800000000000000',
      close: '182.8500000000000000',
      volume: '890375.3310000000000000',
      quoteVolume: 160843481.4558911,
      btcVolume: 3295.7412318699794,
      usdVolume: 159221856.4260747,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '182.8600000000000000',
      high: '192.2400000000000000',
      low: '182.2500000000000000',
      close: '190.7500000000000000',
      volume: '820418.2390100000000000',
      quoteVolume: 153066685.711372,
      btcVolume: 3041.8416759099878,
      usdVolume: 153066685.711372,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '190.7500000000000000',
      high: '195.2800000000000000',
      low: '179.5800000000000000',
      close: '191.8500000000000000',
      volume: '1145514.1533900000000000',
      quoteVolume: 213310567.88827464,
      btcVolume: 4215.520210397939,
      usdVolume: 213310567.88827464,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '191.8600000000000000',
      high: '205.0000000000000000',
      low: '189.2500000000000000',
      close: '203.8100000000000000',
      volume: '1149941.2715400000000000',
      quoteVolume: 228024787.08482048,
      btcVolume: 4230.227265341441,
      usdVolume: 228024787.08482048,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '203.8000000000000000',
      high: '208.0000000000000000',
      low: '191.4700000000000000',
      close: '200.2900000000000000',
      volume: '1292995.9055100000000000',
      quoteVolume: 259453140.60054418,
      btcVolume: 4683.568407179226,
      usdVolume: 259436705.04717085,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '200.2900000000000000',
      high: '203.5400000000000000',
      low: '191.0300000000000000',
      close: '201.3600000000000000',
      volume: '980924.4222700000000000',
      quoteVolume: 194360542.3998627,
      btcVolume: 3462.342245940359,
      usdVolume: 194360542.3998627,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '201.3700000000000000',
      high: '224.2300000000000000',
      low: '198.4100000000000000',
      close: '221.4200000000000000',
      volume: '1977431.3681000000000000',
      quoteVolume: 416264469.25011075,
      btcVolume: 5921.218381160836,
      usdVolume: 336886590.78517324,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '218.6500000000000000',
      high: '230.2300000000000000',
      low: '212.7000000000000000',
      close: '226.0600000000000000',
      volume: '1310331.5687300000000000',
      quoteVolume: 292374674.933538,
      btcVolume: 4956.360993659334,
      usdVolume: 292374674.933538,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '226.0800000000000000',
      high: '228.1200000000000000',
      low: '213.8400000000000000',
      close: '213.9400000000000000',
      volume: '798435.6040500000000000',
      quoteVolume: 175877600.8876904,
      btcVolume: 2901.7605953284483,
      usdVolume: 175390710.21983862,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '214.0000000000000000',
      high: '222.5800000000000000',
      low: '197.5600000000000000',
      close: '200.5900000000000000',
      volume: '1413381.5745600000000000',
      quoteVolume: 291674131.5058429,
      btcVolume: 5106.393558040645,
      usdVolume: 291674131.5058429,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '200.5900000000000000',
      high: '205.5300000000000000',
      low: '191.1500000000000000',
      close: '201.6000000000000000',
      volume: '992587.9162400000000000',
      quoteVolume: 197656651.7759283,
      btcVolume: 3582.8634646412274,
      usdVolume: 197591598.5747801,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '201.6100000000000000',
      high: '206.9400000000000000',
      low: '194.1300000000000000',
      close: '205.9800000000000000',
      volume: '881712.4524000000000000',
      quoteVolume: 176884881.60728255,
      btcVolume: 3142.8106849439105,
      usdVolume: 176884881.60728255,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '205.9600000000000000',
      high: '208.2500000000000000',
      low: '199.1300000000000000',
      close: '199.7000000000000000',
      volume: '774116.3689700000000000',
      quoteVolume: 158124202.7894712,
      btcVolume: 2696.520577593762,
      usdVolume: 158123481.68116263,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '199.7200000000000000',
      high: '205.0000000000000000',
      low: '195.8100000000000000',
      close: '199.9600000000000000',
      volume: '515574.3893500000000000',
      quoteVolume: 103843591.3837549,
      btcVolume: 1779.0182398838715,
      usdVolume: 103678690.17642716,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '199.9600000000000000',
      high: '206.1000000000000000',
      low: '198.8200000000000000',
      close: '200.1700000000000000',
      volume: '408707.5852000000000000',
      quoteVolume: 82836648.74984606,
      btcVolume: 1409.1616201750144,
      usdVolume: 82836585.73164566,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '200.1600000000000000',
      high: '202.0000000000000000',
      low: '192.0000000000000000',
      close: '195.3100000000000000',
      volume: '579091.9878400000000000',
      quoteVolume: 113737671.3688079,
      btcVolume: 1986.3030989694942,
      usdVolume: 113644945.73073904,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '195.3300000000000000',
      high: '199.0000000000000000',
      low: '184.4200000000000000',
      close: '185.6700000000000000',
      volume: '725077.5781400000000000',
      quoteVolume: 139899897.6498326,
      btcVolume: 2476.105185480285,
      usdVolume: 139844519.7670538,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '185.6900000000000000',
      high: '189.4400000000000000',
      low: '181.1200000000000000',
      close: '185.8300000000000000',
      volume: '632291.1116900000000000',
      quoteVolume: 117638252.58571476,
      btcVolume: 1495.9951187040629,
      usdVolume: 81470228.03850105,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '185.8200000000000000',
      high: '197.1700000000000000',
      low: '172.0000000000000000',
      close: '176.0500000000000000',
      volume: '1081496.2327600000000000',
      quoteVolume: 204651010.18833292,
      btcVolume: 3732.791518881732,
      usdVolume: 204651010.18833292,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '176.0500000000000000',
      high: '179.0300000000000000',
      low: '167.8600000000000000',
      close: '171.5300000000000000',
      volume: '840201.4116500000000000',
      quoteVolume: 145853345.58006474,
      btcVolume: 2520.2457069925285,
      usdVolume: 130917909.539687,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '171.5300000000000000',
      high: '183.8200000000000000',
      low: '171.4400000000000000',
      close: '183.4500000000000000',
      volume: '602677.7101500000000000',
      quoteVolume: 107824053.70199299,
      btcVolume: 1947.79094311182,
      usdVolume: 103830246.82252973,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '183.4400000000000000',
      high: '186.1200000000000000',
      low: '177.7500000000000000',
      close: '184.0600000000000000',
      volume: '475886.8387300000000000',
      quoteVolume: 86757743.80726413,
      btcVolume: 1572.6367137829343,
      usdVolume: 86752376.32223454,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '184.0600000000000000',
      high: '187.2900000000000000',
      low: '180.0000000000000000',
      close: '184.2400000000000000',
      volume: '380545.1621100000000000',
      quoteVolume: 70082688.39183253,
      btcVolume: 1254.4888606538248,
      usdVolume: 70065167.58697064,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '184.2200000000000000',
      high: '197.6000000000000000',
      low: '182.4500000000000000',
      close: '193.7300000000000000',
      volume: '891104.9208000000000000',
      quoteVolume: 172040612.47646165,
      btcVolume: 3033.1693284032813,
      usdVolume: 171975567.3003832,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '193.7300000000000000',
      high: '200.0900000000000000',
      low: '190.3400000000000000',
      close: '195.7400000000000000',
      volume: '932290.6498000000000000',
      quoteVolume: 181705740.05520037,
      btcVolume: 3111.562505675036,
      usdVolume: 181705701.26920035,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '195.7100000000000000',
      high: '198.9900000000000000',
      low: '187.0000000000000000',
      close: '196.7000000000000000',
      volume: '698229.6378600000000000',
      quoteVolume: 135030259.1244356,
      btcVolume: 2299.6428350422234,
      usdVolume: 134758219.97780785,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '196.6900000000000000',
      high: '205.0000000000000000',
      low: '194.0000000000000000',
      close: '202.8600000000000000',
      volume: '860883.6398300000000000',
      quoteVolume: 171796660.07412636,
      btcVolume: 2915.077930636623,
      usdVolume: 171627655.0033485,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '202.8700000000000000',
      high: '213.7700000000000000',
      low: '200.7800000000000000',
      close: '211.4600000000000000',
      volume: '911504.7463400000000000',
      quoteVolume: 188097396.48105705,
      btcVolume: 3171.97268148674,
      usdVolume: 188075782.95719445,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '211.4400000000000000',
      high: '217.9700000000000000',
      low: '194.8500000000000000',
      close: '195.2300000000000000',
      volume: '955157.6424300000000000',
      quoteVolume: 199272205.1538647,
      btcVolume: 1923.0081233352637,
      usdVolume: 113285748.669347,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '195.1700000000000000',
      high: '204.9200000000000000',
      low: '192.2200000000000000',
      close: '202.2100000000000000',
      volume: '537808.5370400000000000',
      quoteVolume: 107874767.17797312,
      btcVolume: 1616.1763098667245,
      usdVolume: 93333101.29026881,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '202.2400000000000000',
      high: '225.4700000000000000',
      low: '198.0700000000000000',
      close: '221.4800000000000000',
      volume: '1320185.3316300000000000',
      quoteVolume: 279356016.0500422,
      btcVolume: 3604.645404045187,
      usdVolume: 210101068.50432605,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '221.4800000000000000',
      high: '244.5900000000000000',
      low: '212.9700000000000000',
      close: '237.0400000000000000',
      volume: '2258610.1931100000000000',
      quoteVolume: 520462770.85170555,
      btcVolume: 8308.54901413599,
      usdVolume: 484407399.03375864,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '236.9600000000000000',
      high: '242.9900000000000000',
      low: '210.7700000000000000',
      close: '219.0500000000000000',
      volume: '2252949.3544600000000000',
      quoteVolume: 507979738.3688623,
      btcVolume: 8713.75947843975,
      usdVolume: 496605124.34581065,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '219.2200000000000000',
      high: '228.2100000000000000',
      low: '216.8900000000000000',
      close: '226.1100000000000000',
      volume: '1040059.1698900000000000',
      quoteVolume: 232196028.07719645,
      btcVolume: 2623.6393819989507,
      usdVolume: 149526942.68774295,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '226.1700000000000000',
      high: '228.9900000000000000',
      low: '218.7200000000000000',
      close: '221.1200000000000000',
      volume: '629961.8332600000000000',
      quoteVolume: 141610934.81979573,
      btcVolume: 2106.0786755977633,
      usdVolume: 122650861.38468109,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '221.1300000000000000',
      high: '257.0000000000000000',
      low: '219.1600000000000000',
      close: '256.3400000000000000',
      volume: '1859281.8830400000000000',
      quoteVolume: 441762890.57776946,
      btcVolume: 7247.337651456451,
      usdVolume: 434426894.35602313,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '256.5500000000000000',
      high: '263.6200000000000000',
      low: '0.0113660000000000',
      close: '252.3100000000000000',
      volume: '1890921.5803900000000000',
      quoteVolume: 480319113.2081568,
      btcVolume: 7907.7185822236115,
      usdVolume: 472938780.5553337,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '252.3500000000000000',
      high: '259.9400000000000000',
      low: '240.0100000000000000',
      close: '244.4200000000000000',
      volume: '1328144.9269900000000000',
      quoteVolume: 331420362.6224557,
      btcVolume: 4408.987173128506,
      usdVolume: 264961310.1003388,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '244.4400000000000000',
      high: '273.5400000000000000',
      low: '244.3600000000000000',
      close: '267.5900000000000000',
      volume: '1815822.6911300000000000',
      quoteVolume: 476947654.2477242,
      btcVolume: 7578.822561176737,
      usdVolume: 472033209.7211003,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '267.6100000000000000',
      high: '283.0000000000000000',
      low: '255.0000000000000000',
      close: '278.8600000000000000',
      volume: '1934889.3058500000000000',
      quoteVolume: 524702625.81534594,
      btcVolume: 7474.81355593654,
      usdVolume: 474608362.09590185,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '278.8000000000000000',
      high: '290.9000000000000000',
      low: '266.0000000000000000',
      close: '285.6300000000000000',
      volume: '1409512.9217200000000000',
      quoteVolume: 392049527.74386656,
      btcVolume: 5819.337916249173,
      usdVolume: 366017514.8535886,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '285.6400000000000000',
      high: '317.0900000000000000',
      low: '265.5000000000000000',
      close: '308.4300000000000000',
      volume: '3150447.9992800000000000',
      quoteVolume: 924239206.0551368,
      btcVolume: 13754.14904721424,
      usdVolume: 847181090.2771821,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '308.3900000000000000',
      high: '335.6900000000000000',
      low: '296.7500000000000000',
      close: '300.8600000000000000',
      volume: '2574519.6355000000000000',
      quoteVolume: 817941072.7363646,
      btcVolume: 8650.440913958724,
      usdVolume: 529440111.53025746,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '300.8700000000000000',
      high: '304.8300000000000000',
      low: '232.0000000000000000',
      close: '273.3600000000000000',
      volume: '3046826.0682100000000000',
      quoteVolume: 814978326.9479513,
      btcVolume: 14251.274999648283,
      usdVolume: 799689184.4717911,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '273.3500000000000000',
      high: '284.3900000000000000',
      low: '250.2100000000000000',
      close: '261.3800000000000000',
      volume: '1973502.3718900000000000',
      quoteVolume: 526831697.8987827,
      btcVolume: 7545.76975452113,
      usdVolume: 424171196.8055375,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '261.4700000000000000',
      high: '272.8000000000000000',
      low: '234.3100000000000000',
      close: '260.6800000000000000',
      volume: '2100827.2657200000000000',
      quoteVolume: 536954207.2394092,
      btcVolume: 9272.855998053765,
      usdVolume: 514533908.33471185,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '260.7300000000000000',
      high: '277.0000000000000000',
      low: '250.7500000000000000',
      close: '257.6900000000000000',
      volume: '1690547.6833500000000000',
      quoteVolume: 446244126.3759943,
      btcVolume: 6848.168090990059,
      usdVolume: 380430906.4184981,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '257.6300000000000000',
      high: '289.6100000000000000',
      low: '239.0000000000000000',
      close: '252.5000000000000000',
      volume: '2441035.4976300000000000',
      quoteVolume: 653807393.5680175,
      btcVolume: 3138.9783548631967,
      usdVolume: 166658633.73239762,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '252.6000000000000000',
      high: '257.1500000000000000',
      low: '214.2900000000000000',
      close: '241.3100000000000000',
      volume: '2633187.8420800000000000',
      quoteVolume: 613120720.0451101,
      btcVolume: 6726.945644391381,
      usdVolume: 334739258.42326885,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '241.3100000000000000',
      high: '241.4100000000000000',
      low: '220.3400000000000000',
      close: '224.7500000000000000',
      volume: '1127242.4756500000000000',
      quoteVolume: 258970433.8586322,
      btcVolume: 4959.794572907675,
      usdVolume: 247114101.5572867,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '224.7600000000000000',
      high: '235.8000000000000000',
      low: '210.7300000000000000',
      close: '223.8800000000000000',
      volume: '872669.4430500000000000',
      quoteVolume: 197094883.39333206,
      btcVolume: 2116.3775832422107,
      usdVolume: 104947659.02477793,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '223.9000000000000000',
      high: '248.2000000000000000',
      low: '221.2400000000000000',
      close: '247.4200000000000000',
      volume: '1090110.9487400000000000',
      quoteVolume: 262056371.5676198,
      btcVolume: 2935.0307772177202,
      usdVolume: 155435733.32541108,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '247.4000000000000000',
      high: '263.1500000000000000',
      low: '243.6300000000000000',
      close: '259.7700000000000000',
      volume: '1113898.0502300000000000',
      quoteVolume: 283129397.5147066,
      btcVolume: 5071.1060750962415,
      usdVolume: 276778818.2280468,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '259.7700000000000000',
      high: '266.9400000000000000',
      low: '247.5800000000000000',
      close: '258.5100000000000000',
      volume: '1014038.4373400000000000',
      quoteVolume: 259424641.2548764,
      btcVolume: 4737.856416827141,
      usdVolume: 259424641.2548764,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '258.5000000000000000',
      high: '262.8900000000000000',
      low: '248.1600000000000000',
      close: '255.3100000000000000',
      volume: '856981.6494100000000000',
      quoteVolume: 218587991.57567295,
      btcVolume: 4061.048623525768,
      usdVolume: 218587991.57567295,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '255.3100000000000000',
      high: '273.5800000000000000',
      low: '252.6100000000000000',
      close: '271.1300000000000000',
      volume: '1019109.2282500000000000',
      quoteVolume: 269497860.7586776,
      btcVolume: 3226.855828115803,
      usdVolume: 177988659.73005295,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '271.1400000000000000',
      high: '278.5800000000000000',
      low: '265.9000000000000000',
      close: '276.7800000000000000',
      volume: '759685.2319600000000000',
      quoteVolume: 206313142.37866887,
      btcVolume: 1707.670992326543,
      usdVolume: 98677446.61978804,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '276.7700000000000000',
      high: '277.4700000000000000',
      low: '264.7000000000000000',
      close: '268.8800000000000000',
      volume: '656401.3022700000000000',
      quoteVolume: 177319409.03695217,
      btcVolume: 1903.0205383441184,
      usdVolume: 108037070.23640811,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '268.8300000000000000',
      high: '299.7200000000000000',
      low: '268.4400000000000000',
      close: '294.8700000000000000',
      volume: '1427253.5714300000000000',
      quoteVolume: 406345333.7440447,
      btcVolume: 6780.1405280927665,
      usdVolume: 392154426.84161633,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '294.7300000000000000',
      high: '327.6800000000000000',
      low: '270.0000000000000000',
      close: '305.2300000000000000',
      volume: '3928402.9769700000000000',
      quoteVolume: 1192658666.056111,
      btcVolume: 17129.631266402546,
      usdVolume: 949745423.8557243,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '305.2400000000000000',
      high: '360.0000000000000000',
      low: '300.5200000000000000',
      close: '356.0400000000000000',
      volume: '3390309.8850700000000000',
      quoteVolume: 1149588604.2960927,
      btcVolume: 19086.40330757933,
      usdVolume: 1061797763.2331136,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '356.0300000000000000',
      high: '364.9300000000000000',
      low: '318.0000000000000000',
      close: '354.6700000000000000',
      volume: '2657432.8323600000000000',
      quoteVolume: 908738665.4650412,
      btcVolume: 15602.374312501974,
      usdVolume: 887300260.0806935,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '354.5200000000000000',
      high: '374.5000000000000000',
      low: '333.3300000000000000',
      close: '344.8900000000000000',
      volume: '2822658.7155800000000000',
      quoteVolume: 1005641007.73384,
      btcVolume: 17158.120265906025,
      usdVolume: 969197721.0813937,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '344.8900000000000000',
      high: '356.6800000000000000',
      low: '334.0000000000000000',
      close: '347.1000000000000000',
      volume: '1326815.3766000000000000',
      quoteVolume: 458272514.1193381,
      btcVolume: 7737.5166810146,
      usdVolume: 452069650.3441574,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '347.1700000000000000',
      high: '389.0000000000000000',
      low: '333.5100000000000000',
      close: '387.8000000000000000',
      volume: '2292100.0585200000000000',
      quoteVolume: 824990298.5702738,
      btcVolume: 14024.145540287725,
      usdVolume: 811254222.256117,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '387.7700000000000000',
      high: '413.4900000000000000',
      low: '333.6800000000000000',
      close: '358.8900000000000000',
      volume: '3692639.9933200000000000',
      quoteVolume: 1422149685.4017851,
      btcVolume: 16986.416600752273,
      usdVolume: 971531402.2277263,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '358.9300000000000000',
      high: '379.7200000000000000',
      low: '345.0000000000000000',
      close: '377.6000000000000000',
      volume: '1933301.8959500000000000',
      quoteVolume: 696994582.879416,
      btcVolume: 9658.930490487104,
      usdVolume: 538638876.0129843,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '377.8000000000000000',
      high: '394.3700000000000000',
      low: '301.0000000000000000',
      close: '301.1600000000000000',
      volume: '2535240.4789300000000000',
      quoteVolume: 908891543.0191455,
      btcVolume: 16147.06658592599,
      usdVolume: 886854180.1405613,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '310.4000000000000000',
      high: '332.7300000000000000',
      low: '291.0000000000000000',
      close: '317.9500000000000000',
      volume: '2633768.7978000000000000',
      quoteVolume: 831763903.622356,
      btcVolume: 16126.135514947295,
      usdVolume: 800855571.6677618,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '317.9500000000000000',
      high: '338.8500000000000000',
      low: '307.9600000000000000',
      close: '326.2300000000000000',
      volume: '1480983.4927200000000000',
      quoteVolume: 478735235.15381885,
      btcVolume: 9290.562772,
      usdVolume: 466090574.20245117,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '326.2400000000000000',
      high: '332.0000000000000000',
      low: '296.1700000000000000',
      close: '300.3000000000000000',
      volume: '1486174.6400000000000000',
      quoteVolume: 464488652.98064655,
      btcVolume: 9354.642033526949,
      usdVolume: 455189162.857259,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '300.2200000000000000',
      high: '323.9500000000000000',
      low: '279.6600000000000000',
      close: '295.8900000000000000',
      volume: '1689775.5445200000000000',
      quoteVolume: 509125100.09983116,
      btcVolume: 10506.571843399115,
      usdVolume: 493364301.71537817,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '295.8900000000000000',
      high: '296.3400000000000000',
      low: '261.2700000000000000',
      close: '281.5300000000000000',
      volume: '2058950.0621700000000000',
      quoteVolume: 576011740.6532687,
      btcVolume: 12647.507440176665,
      usdVolume: 556665988.3596952,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '281.3800000000000000',
      high: '317.9700000000000000',
      low: '278.3200000000000000',
      close: '293.9800000000000000',
      volume: '2558745.8496900000000000',
      quoteVolume: 770908333.9244474,
      btcVolume: 17076.297568639577,
      usdVolume: 752161422.2082568,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '293.9800000000000000',
      high: '300.3100000000000000',
      low: '147.0000000000000000',
      close: '185.9300000000000000',
      volume: '4429987.0992500000000000',
      quoteVolume: 1024051125.9764041,
      btcVolume: 21425.247480595644,
      usdVolume: 837275028.9459155,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '186.3100000000000000',
      high: '225.0000000000000000',
      low: '166.4500000000000000',
      close: '210.7400000000000000',
      volume: '4267102.3515400000000000',
      quoteVolume: 857136804.6603408,
      btcVolume: 17609.38450938026,
      usdVolume: 690358545.9245719,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '210.6600000000000000',
      high: '219.1200000000000000',
      low: '156.5600000000000000',
      close: '179.9700000000000000',
      volume: '2499829.1189300000000000',
      quoteVolume: 477038651.68571556,
      btcVolume: 9570.554815255731,
      usdVolume: 369647877.63828075,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '179.9700000000000000',
      high: '184.4400000000000000',
      low: '161.6100000000000000',
      close: '169.3200000000000000',
      volume: '1975608.6877700000000000',
      quoteVolume: 344670437.0064659,
      btcVolume: 6063.268649807608,
      usdVolume: 224771211.51457024,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '169.3000000000000000',
      high: '176.2600000000000000',
      low: '118.0300000000000000',
      close: '142.3900000000000000',
      volume: '3854006.1240800000000000',
      quoteVolume: 548343321.08721,
      btcVolume: 15597.115173980857,
      usdVolume: 534078487.19652426,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '142.5200000000000000',
      high: '185.3700000000000000',
      low: '139.5400000000000000',
      close: '185.3200000000000000',
      volume: '2471798.9177700000000000',
      quoteVolume: 401352119.8284848,
      btcVolume: 10534.620490455787,
      usdVolume: 390721604.9641617,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '185.3200000000000000',
      high: '194.6100000000000000',
      low: '167.1500000000000000',
      close: '183.7600000000000000',
      volume: '1974384.4346300000000000',
      quoteVolume: 352461818.3773789,
      btcVolume: 9048.857573695626,
      usdVolume: 343883497.5524442,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '183.8900000000000000',
      high: '204.1300000000000000',
      low: '179.4200000000000000',
      close: '198.9100000000000000',
      volume: '1522242.9800100000000000',
      quoteVolume: 295744722.487022,
      btcVolume: 7397.801239257871,
      usdVolume: 290508656.1029971,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '198.9200000000000000',
      high: '209.0000000000000000',
      low: '180.3900000000000000',
      close: '194.3200000000000000',
      volume: '1457775.3844800000000000',
      quoteVolume: 283796099.3329705,
      btcVolume: 7184.054871626712,
      usdVolume: 278865293.7616737,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '194.3100000000000000',
      high: '198.8800000000000000',
      low: '165.5000000000000000',
      close: '177.3300000000000000',
      volume: '2118247.1385100000000000',
      quoteVolume: 378817824.8082162,
      btcVolume: 10181.549955274471,
      usdVolume: 371492579.2034223,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '177.3000000000000000',
      high: '188.6900000000000000',
      low: '157.6500000000000000',
      close: '164.0100000000000000',
      volume: '1553245.0097100000000000',
      quoteVolume: 267294263.0746701,
      btcVolume: 7489.570968288885,
      usdVolume: 263585916.44740584,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '164.0400000000000000',
      high: '181.1000000000000000',
      low: '155.1100000000000000',
      close: '170.5100000000000000',
      volume: '1118503.2655100000000000',
      quoteVolume: 190582769.66552165,
      btcVolume: 5376.479791676544,
      usdVolume: 189717014.9300997,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '170.4800000000000000',
      high: '188.5000000000000000',
      low: '163.5000000000000000',
      close: '187.9700000000000000',
      volume: '1239310.5303800000000000',
      quoteVolume: 219656209.67978868,
      btcVolume: 6050.641649360358,
      usdVolume: 218836203.75448558,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '188.0100000000000000',
      high: '193.1600000000000000',
      low: '175.8200000000000000',
      close: '182.9900000000000000',
      volume: '1017682.1460000000000000',
      quoteVolume: 185830461.32079786,
      btcVolume: 4992.905208779654,
      usdVolume: 183136314.50446558,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '183.0500000000000000',
      high: '191.3400000000000000',
      low: '178.6300000000000000',
      close: '187.1400000000000000',
      volume: '919350.2437500000000000',
      quoteVolume: 170450027.89501953,
      btcVolume: 4545.1303175238545,
      usdVolume: 169822883.12995762,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '187.1800000000000000',
      high: '197.7800000000000000',
      low: '181.9500000000000000',
      close: '193.5000000000000000',
      volume: '1068163.8122400000000000',
      quoteVolume: 203871823.75115305,
      btcVolume: 5274.935447853314,
      usdVolume: 203567782.85967907,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '193.5200000000000000',
      high: '193.8100000000000000',
      low: '168.9500000000000000',
      close: '178.4400000000000000',
      volume: '1083395.0124300000000000',
      quoteVolume: 192472231.32231694,
      btcVolume: 5072.536802753547,
      usdVolume: 187806213.84734955,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '178.4600000000000000',
      high: '185.4900000000000000',
      low: '167.3200000000000000',
      close: '172.9400000000000000',
      volume: '845338.3479100000000000',
      quoteVolume: 148913591.34313303,
      btcVolume: 4047.721342287558,
      usdVolume: 147372091.39759547,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '172.9500000000000000',
      high: '179.9600000000000000',
      low: '172.0200000000000000',
      close: '176.4100000000000000',
      volume: '676410.3521800000000000',
      quoteVolume: 119363989.89739811,
      btcVolume: 3313.0261015985343,
      usdVolume: 119122275.72225323,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '176.4200000000000000',
      high: '182.2200000000000000',
      low: '160.2500000000000000',
      close: '161.9500000000000000',
      volume: '1035822.8250900000000000',
      quoteVolume: 180434186.55291733,
      btcVolume: 5056.415096494469,
      usdVolume: 179870212.29867515,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '161.9200000000000000',
      high: '164.4800000000000000',
      low: '144.8900000000000000',
      close: '160.6300000000000000',
      volume: '1417289.8764700000000000',
      quoteVolume: 222062589.14860615,
      btcVolume: 6696.45943399783,
      usdVolume: 219130900.61702827,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '160.6400000000000000',
      high: '173.3000000000000000',
      low: '152.3100000000000000',
      close: '172.5600000000000000',
      volume: '1139739.3605400000000000',
      quoteVolume: 186552484.183113,
      btcVolume: 5247.567629145548,
      usdVolume: 184026648.27611592,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '172.5400000000000000',
      high: '174.6100000000000000',
      low: '162.1200000000000000',
      close: '168.1600000000000000',
      volume: '877869.9250200000000000',
      quoteVolume: 147862248.68551347,
      btcVolume: 3977.149088401281,
      usdVolume: 147278462.99204102,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '168.1800000000000000',
      high: '173.7800000000000000',
      low: '160.5500000000000000',
      close: '162.8300000000000000',
      volume: '928611.5444600000000000',
      quoteVolume: 156280950.68599966,
      btcVolume: 4223.884717970092,
      usdVolume: 156041583.2841295,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '162.8400000000000000',
      high: '164.3000000000000000',
      low: '154.1400000000000000',
      close: '161.8000000000000000',
      volume: '653092.9881500000000000',
      quoteVolume: 103889826.80616859,
      btcVolume: 2894.8574142745842,
      usdVolume: 103359782.14313756,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '161.8100000000000000',
      high: '171.5900000000000000',
      low: '156.4200000000000000',
      close: '170.8500000000000000',
      volume: '573493.9078300000000000',
      quoteVolume: 93618007.16348189,
      btcVolume: 2549.4662560893084,
      usdVolume: 93402814.7181441,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '170.8500000000000000',
      high: '179.9900000000000000',
      low: '166.3400000000000000',
      close: '179.8900000000000000',
      volume: '802051.9564500000000000',
      quoteVolume: 138132150.6621468,
      btcVolume: 3462.3042173691624,
      usdVolume: 137992367.33837593,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '179.8800000000000000',
      high: '181.2000000000000000',
      low: '172.1500000000000000',
      close: '175.1500000000000000',
      volume: '654352.0530400000000000',
      quoteVolume: 115569055.5994115,
      btcVolume: 2868.206424184333,
      usdVolume: 115393253.99403785,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '175.1600000000000000',
      high: '177.3100000000000000',
      low: '164.2300000000000000',
      close: '166.5700000000000000',
      volume: '761479.4526300000000000',
      quoteVolume: 129526004.72512966,
      btcVolume: 3269.23856819484,
      usdVolume: 128729009.70918988,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '166.5600000000000000',
      high: '173.4000000000000000',
      low: '162.8100000000000000',
      close: '167.0800000000000000',
      volume: '559434.6236700000000000',
      quoteVolume: 94143650.72569731,
      btcVolume: 2440.0515935312997,
      usdVolume: 93977575.46471305,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '167.1300000000000000',
      high: '167.4300000000000000',
      low: '151.2000000000000000',
      close: '156.3400000000000000',
      volume: '800699.3038400000000000',
      quoteVolume: 127526013.18198103,
      btcVolume: 3451.3788942966594,
      usdVolume: 126795373.6731447,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '156.3300000000000000',
      high: '160.8800000000000000',
      low: '152.3100000000000000',
      close: '152.4900000000000000',
      volume: '522793.6393800000000000',
      quoteVolume: 82002075.66598175,
      btcVolume: 2293.7654961253556,
      usdVolume: 81905459.6444485,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '152.5100000000000000',
      high: '156.1800000000000000',
      low: '143.7500000000000000',
      close: '154.8800000000000000',
      volume: '611126.9176600000000000',
      quoteVolume: 91873187.05818173,
      btcVolume: 2616.173092821781,
      usdVolume: 91098177.96242698,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '154.8700000000000000',
      high: '155.5000000000000000',
      low: '123.9100000000000000',
      close: '124.4600000000000000',
      volume: '1491214.5233800000000000',
      quoteVolume: 203016501.85429946,
      btcVolume: 6075.534275582749,
      usdVolume: 199064002.10991165,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '124.3800000000000000',
      high: '133.0300000000000000',
      low: '104.9200000000000000',
      close: '119.6400000000000000',
      volume: '2255194.7519200000000000',
      quoteVolume: 266658628.66409847,
      btcVolume: 8351.340529707115,
      usdVolume: 262285946.07146153,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '119.6900000000000000',
      high: '133.7500000000000000',
      low: '115.4900000000000000',
      close: '129.1000000000000000',
      volume: '1018662.2307000000000000',
      quoteVolume: 129893644.46478507,
      btcVolume: 3806.186447654474,
      usdVolume: 128367468.01437268,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '129.1300000000000000',
      high: '137.3600000000000000',
      low: '123.7100000000000000',
      close: '134.5800000000000000',
      volume: '650968.5008600000000000',
      quoteVolume: 86002587.93949006,
      btcVolume: 2540.35834770411,
      usdVolume: 85601845.83265461,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '134.5900000000000000',
      high: '138.4500000000000000',
      low: '124.3100000000000000',
      close: '125.0000000000000000',
      volume: '764515.3389200000000000',
      quoteVolume: 99682735.3520955,
      btcVolume: 2989.7157760446407,
      usdVolume: 99437255.44342713,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '125.0300000000000000',
      high: '129.4400000000000000',
      low: '118.6700000000000000',
      close: '126.8900000000000000',
      volume: '868428.5919600000000000',
      quoteVolume: 107074782.7018947,
      btcVolume: 3405.7791541849497,
      usdVolume: 106608690.08379035,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '126.8100000000000000',
      high: '132.6700000000000000',
      low: '124.6600000000000000',
      close: '132.6000000000000000',
      volume: '573388.2468800000000000',
      quoteVolume: 73420914.55001809,
      btcVolume: 2206.7295809874763,
      usdVolume: 73052077.51809245,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '132.5700000000000000',
      high: '139.5500000000000000',
      low: '129.8900000000000000',
      close: '137.6900000000000000',
      volume: '657962.2346700000000000',
      quoteVolume: 88324620.00472222,
      btcVolume: 2556.119863531481,
      usdVolume: 88194403.87278609,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '137.6800000000000000',
      high: '149.1800000000000000',
      low: '137.2400000000000000',
      close: '144.2000000000000000',
      volume: '840281.7752200000000000',
      quoteVolume: 120878119.45537868,
      btcVolume: 3398.725464122438,
      usdVolume: 120738719.24876784,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '144.2300000000000000',
      high: '146.9400000000000000',
      low: '136.0100000000000000',
      close: '144.2600000000000000',
      volume: '601927.1802600000000000',
      quoteVolume: 84998636.27030478,
      btcVolume: 2433.7350642691163,
      usdVolume: 84897826.1464357,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '144.3100000000000000',
      high: '144.3100000000000000',
      low: '133.9900000000000000',
      close: '137.0200000000000000',
      volume: '539535.2997000000000000',
      quoteVolume: 74099823.13621905,
      btcVolume: 2199.154275297908,
      usdVolume: 73990525.94229446,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '137.0100000000000000',
      high: '138.9700000000000000',
      low: '130.6000000000000000',
      close: '136.8500000000000000',
      volume: '464289.9855400000000000',
      quoteVolume: 62070122.90920927,
      btcVolume: 1866.2283049451146,
      usdVolume: 62049635.439033374,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '136.8700000000000000',
      high: '141.3700000000000000',
      low: '134.5700000000000000',
      close: '140.1700000000000000',
      volume: '342781.9026900000000000',
      quoteVolume: 47569780.320621595,
      btcVolume: 1377.1630726778033,
      usdVolume: 47351874.70020899,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '140.1900000000000000',
      high: '148.1000000000000000',
      low: '136.8800000000000000',
      close: '144.9500000000000000',
      volume: '435307.8254500000000000',
      quoteVolume: 62602668.35071889,
      btcVolume: 1770.4592503135034,
      usdVolume: 62526340.18334017,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '144.9600000000000000',
      high: '145.0300000000000000',
      low: '133.8400000000000000',
      close: '137.7100000000000000',
      volume: '561316.3421300000000000',
      quoteVolume: 78108514.57286538,
      btcVolume: 2273.043772946485,
      usdVolume: 77425208.18744867,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '137.7600000000000000',
      high: '142.7300000000000000',
      low: '135.6800000000000000',
      close: '138.8700000000000000',
      volume: '513385.4598500000000000',
      quoteVolume: 71206203.53100015,
      btcVolume: 2058.938771197183,
      usdVolume: 70372583.83128636,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '138.8600000000000000',
      high: '142.9300000000000000',
      low: '136.8700000000000000',
      close: '137.4100000000000000',
      volume: '515462.6606800000000000',
      quoteVolume: 72372106.19902879,
      btcVolume: 2095.11390021604,
      usdVolume: 72372106.19902879,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '137.3800000000000000',
      high: '137.7400000000000000',
      low: '127.7700000000000000',
      close: '132.1500000000000000',
      volume: '645442.5711200000000000',
      quoteVolume: 85207418.88870086,
      btcVolume: 2580.982544909361,
      usdVolume: 84861412.34308095,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '132.1100000000000000',
      high: '135.8500000000000000',
      low: '126.0000000000000000',
      close: '134.5800000000000000',
      volume: '527547.8216300000000000',
      quoteVolume: 69418988.6298179,
      btcVolume: 2095.969894767974,
      usdVolume: 69393787.63974923,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '134.5400000000000000',
      high: '136.8200000000000000',
      low: '130.3200000000000000',
      close: '133.9900000000000000',
      volume: '365844.8683200000000000',
      quoteVolume: 48672574.565501355,
      btcVolume: 1443.797183822238,
      usdVolume: 48670934.54957684,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '133.9900000000000000',
      high: '136.4800000000000000',
      low: '132.0800000000000000',
      close: '134.2800000000000000',
      volume: '324569.4102200000000000',
      quoteVolume: 43649181.09015877,
      btcVolume: 1290.2481974206867,
      usdVolume: 43648244.99860397,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '134.2800000000000000',
      high: '139.7500000000000000',
      low: '131.8200000000000000',
      close: '133.3800000000000000',
      volume: '670917.6235500000000000',
      quoteVolume: 91393565.62359004,
      btcVolume: 2712.82869674453,
      usdVolume: 91352372.01710989,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '133.3200000000000000',
      high: '134.6100000000000000',
      low: '129.3500000000000000',
      close: '131.4400000000000000',
      volume: '446421.4796000000000000',
      quoteVolume: 58969790.60242994,
      btcVolume: 1796.7553077819084,
      usdVolume: 58951683.42339532,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '131.4500000000000000',
      high: '132.4200000000000000',
      low: '123.5900000000000000',
      close: '131.0700000000000000',
      volume: '538549.4072300000000000',
      quoteVolume: 69104634.35945345,
      btcVolume: 2129.938412921626,
      usdVolume: 69047331.59467553,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '131.0600000000000000',
      high: '133.1300000000000000',
      low: '121.9300000000000000',
      close: '125.8600000000000000',
      volume: '494276.3957800000000000',
      quoteVolume: 62599065.68099787,
      btcVolume: 1949.375687069558,
      usdVolume: 62495906.468084864,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '125.8800000000000000',
      high: '127.8700000000000000',
      low: '120.3800000000000000',
      close: '120.6500000000000000',
      volume: '396793.6475500000000000',
      quoteVolume: 49187467.51789337,
      btcVolume: 1551.73880256109,
      usdVolume: 49187456.52809857,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '120.6600000000000000',
      high: '122.9200000000000000',
      low: '117.6800000000000000',
      close: '119.9500000000000000',
      volume: '412797.9346200000000000',
      quoteVolume: 49618380.247838296,
      btcVolume: 1572.2543394713864,
      usdVolume: 49564013.24779822,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '120.0000000000000000',
      high: '124.0500000000000000',
      low: '116.2300000000000000',
      close: '119.1700000000000000',
      volume: '430985.2145700000000000',
      quoteVolume: 51789227.20684465,
      btcVolume: 1633.9578324717402,
      usdVolume: 51787309.337946646,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '119.1600000000000000',
      high: '120.7500000000000000',
      low: '111.8200000000000000',
      close: '113.1300000000000000',
      volume: '493082.9626600000000000',
      quoteVolume: 56960133.80445162,
      btcVolume: 1829.8567510212017,
      usdVolume: 56942282.4436929,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '113.1400000000000000',
      high: '115.0900000000000000',
      low: '103.8300000000000000',
      close: '107.2900000000000000',
      volume: '765414.9566800000000000',
      quoteVolume: 82409699.71007985,
      btcVolume: 2758.298975959001,
      usdVolume: 82377481.65606456,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '107.3100000000000000',
      high: '119.2400000000000000',
      low: '105.0500000000000000',
      close: '117.7400000000000000',
      volume: '682039.4728300000000000',
      quoteVolume: 77598308.8381928,
      btcVolume: 2473.82772359749,
      usdVolume: 77368480.01030874,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '117.7700000000000000',
      high: '122.0000000000000000',
      low: '115.6100000000000000',
      close: '120.6700000000000000',
      volume: '464759.4714600000000000',
      quoteVolume: 55142102.74740585,
      btcVolume: 1710.182490463156,
      usdVolume: 54964505.0582159,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '120.6900000000000000',
      high: '122.1300000000000000',
      low: '117.0200000000000000',
      close: '119.4200000000000000',
      volume: '318529.7456500000000000',
      quoteVolume: 38201094.05437847,
      btcVolume: 1177.7807010659724,
      usdVolume: 38201094.05437847,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
  bsv: {
    id: 'bsv',
    name: 'BSV',
    symbol: 'BSV',
    image: 'assets/images/coin-logo/BSV.png',
    activity24h: 421323456,
    availableAmount: 208.232323,
    usdxConversion: 2,
    allTimeValue: 55943812390,
    allTimeAsset: 542321,
    candleData: [
      {
      open: '0.5355000000000000',
      high: '0.5355000000000000',
      low: '0.4668000000000000',
      close: '0.4895000000000000',
      volume: '1343660.3100000000000000',
      quoteVolume: 665013.644449,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '0.4895000000000000',
      high: '0.4953000000000000',
      low: '0.4639000000000000',
      close: '0.4756000000000000',
      volume: '1411725.3500000000000000',
      quoteVolume: 673447.208597,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '0.4762000000000000',
      high: '0.5112000000000000',
      low: '0.4601000000000000',
      close: '0.4894000000000000',
      volume: '984417.5900000000000000',
      quoteVolume: 474999.286242,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '0.4908000000000000',
      high: '0.5000000000000000',
      low: '0.4673000000000000',
      close: '0.4831000000000000',
      volume: '735443.2000000000000000',
      quoteVolume: 354090.041046,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '0.4803000000000000',
      high: '0.4920000000000000',
      low: '0.4590000000000000',
      close: '0.4834000000000000',
      volume: '677499.5600000000000000',
      quoteVolume: 323333.553838,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '0.4839000000000000',
      high: '0.5198000000000000',
      low: '0.4650000000000000',
      close: '0.4972000000000000',
      volume: '897787.8400000000000000',
      quoteVolume: 446086.78762,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '0.4960000000000000',
      high: '0.5656000000000000',
      low: '0.4956000000000000',
      close: '0.5344000000000000',
      volume: '1784953.5000000000000000',
      quoteVolume: 955513.881021,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '0.5342000000000000',
      high: '0.5475000000000000',
      low: '0.4901000000000000',
      close: '0.5046000000000000',
      volume: '1827069.3900000000000000',
      quoteVolume: 940352.297759,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '0.5046000000000000',
      high: '0.5065000000000000',
      low: '0.4518000000000000',
      close: '0.5017000000000000',
      volume: '1529219.5300000000000000',
      quoteVolume: 745639.12774,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '0.5031000000000000',
      high: '0.5157000000000000',
      low: '0.4712000000000000',
      close: '0.4739000000000000',
      volume: '1112869.0800000000000000',
      quoteVolume: 544844.844073,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '0.4736000000000000',
      high: '0.4884000000000000',
      low: '0.4624000000000000',
      close: '0.4661000000000000',
      volume: '865653.8900000000000000',
      quoteVolume: 410185.821637,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '0.4661000000000000',
      high: '0.4666000000000000',
      low: '0.4229000000000000',
      close: '0.4303000000000000',
      volume: '2278919.6500000000000000',
      quoteVolume: 1004304.902092,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '0.4310000000000000',
      high: '0.4313000000000000',
      low: '0.3504000000000000',
      close: '0.4116000000000000',
      volume: '4006211.8500000000000000',
      quoteVolume: 1540777.018846,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '0.4104000000000000',
      high: '0.4638000000000000',
      low: '0.4061000000000000',
      close: '0.4505000000000000',
      volume: '5190825.3700000000000000',
      quoteVolume: 2252399.736119,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '0.4505000000000000',
      high: '0.4514000000000000',
      low: '0.4130000000000000',
      close: '0.4249000000000000',
      volume: '2092369.5900000000000000',
      quoteVolume: 897815.126911,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '0.4250000000000000',
      high: '0.4410000000000000',
      low: '0.3811000000000000',
      close: '0.3832000000000000',
      volume: '2029033.6100000000000000',
      quoteVolume: 826552.030246,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '0.3850000000000000',
      high: '0.4300000000000000',
      low: '0.3800000000000000',
      close: '0.4147000000000000',
      volume: '1899232.5600000000000000',
      quoteVolume: 768342.467271,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '0.4163000000000000',
      high: '0.4330000000000000',
      low: '0.3985000000000000',
      close: '0.4136000000000000',
      volume: '821033.4800000000000000',
      quoteVolume: 340840.331218,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '0.4170000000000000',
      high: '0.4170000000000000',
      low: '0.3860000000000000',
      close: '0.3963000000000000',
      volume: '1065170.5600000000000000',
      quoteVolume: 424303.971749,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '0.3959000000000000',
      high: '0.4125000000000000',
      low: '0.3867000000000000',
      close: '0.3943000000000000',
      volume: '1449384.9600000000000000',
      quoteVolume: 579009.583139,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '0.3926000000000000',
      high: '0.4327000000000000',
      low: '0.3902000000000000',
      close: '0.4224000000000000',
      volume: '1299107.0300000000000000',
      quoteVolume: 532341.103934,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '0.4224000000000000',
      high: '0.4236000000000000',
      low: '0.3915000000000000',
      close: '0.4000000000000000',
      volume: '1368546.2100000000000000',
      quoteVolume: 552819.621243,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '0.4000000000000000',
      high: '0.4065000000000000',
      low: '0.3913000000000000',
      close: '0.4021000000000000',
      volume: '498842.3600000000000000',
      quoteVolume: 199193.78253,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '0.4021000000000000',
      high: '0.4500000000000000',
      low: '0.3985000000000000',
      close: '0.4383000000000000',
      volume: '2015969.5800000000000000',
      quoteVolume: 859693.298603,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '0.4371000000000000',
      high: '0.4844000000000000',
      low: '0.4310000000000000',
      close: '0.4612000000000000',
      volume: '1384815.9500000000000000',
      quoteVolume: 637171.045365,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '0.4648000000000000',
      high: '0.4800000000000000',
      low: '0.4325000000000000',
      close: '0.4499000000000000',
      volume: '2000850.5500000000000000',
      quoteVolume: 913391.61962,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '0.4495000000000000',
      high: '0.4515000000000000',
      low: '0.4200000000000000',
      close: '0.4289000000000000',
      volume: '1580197.3900000000000000',
      quoteVolume: 685555.872631,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '0.4277000000000000',
      high: '0.4374000000000000',
      low: '0.4176000000000000',
      close: '0.4277000000000000',
      volume: '941303.5800000000000000',
      quoteVolume: 400668.760404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '0.4277000000000000',
      high: '0.4454000000000000',
      low: '0.4183000000000000',
      close: '0.4411000000000000',
      volume: '935377.3800000000000000',
      quoteVolume: 400576.99087,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '0.4422000000000000',
      high: '0.4442000000000000',
      low: '0.4203000000000000',
      close: '0.4259000000000000',
      volume: '942053.4400000000000000',
      quoteVolume: 407685.941353,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '0.4235000000000000',
      high: '0.4500000000000000',
      low: '0.4222000000000000',
      close: '0.4342000000000000',
      volume: '612251.8000000000000000',
      quoteVolume: 264659.849465,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '0.4366000000000000',
      high: '0.4464000000000000',
      low: '0.4251000000000000',
      close: '0.4398000000000000',
      volume: '465491.5800000000000000',
      quoteVolume: 203941.272106,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '0.4386000000000000',
      high: '0.4797000000000000',
      low: '0.4317000000000000',
      close: '0.4604000000000000',
      volume: '1616509.7200000000000000',
      quoteVolume: 740396.471002,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '0.4615000000000000',
      high: '0.4823000000000000',
      low: '0.4534000000000000',
      close: '0.4710000000000000',
      volume: '1430254.7800000000000000',
      quoteVolume: 671573.658591,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '0.4710000000000000',
      high: '0.4789000000000000',
      low: '0.4520000000000000',
      close: '0.4598000000000000',
      volume: '1548002.4400000000000000',
      quoteVolume: 724054.883013,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '0.4548000000000000',
      high: '0.4748000000000000',
      low: '0.4432000000000000',
      close: '0.4618000000000000',
      volume: '1358786.2800000000000000',
      quoteVolume: 625130.767047,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '0.4617000000000000',
      high: '0.4750000000000000',
      low: '0.4380000000000000',
      close: '0.4438000000000000',
      volume: '1443214.9800000000000000',
      quoteVolume: 655875.790716,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '0.4438000000000000',
      high: '0.4650000000000000',
      low: '0.4400000000000000',
      close: '0.4479000000000000',
      volume: '700523.7700000000000000',
      quoteVolume: 313850.863647,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '0.4480000000000000',
      high: '0.4640000000000000',
      low: '0.4437000000000000',
      close: '0.4585000000000000',
      volume: '925529.6400000000000000',
      quoteVolume: 419693.727298,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '0.4592000000000000',
      high: '0.4644000000000000',
      low: '0.3975000000000000',
      close: '0.4050000000000000',
      volume: '2154193.2500000000000000',
      quoteVolume: 917345.275562,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '0.4047000000000000',
      high: '0.4749000000000000',
      low: '0.4007000000000000',
      close: '0.4723000000000000',
      volume: '1785356.2100000000000000',
      quoteVolume: 783690.750459,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '0.4722000000000000',
      high: '0.4740000000000000',
      low: '0.4300000000000000',
      close: '0.4341000000000000',
      volume: '1775526.6700000000000000',
      quoteVolume: 796546.586808,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '0.4335000000000000',
      high: '0.4412000000000000',
      low: '0.4145000000000000',
      close: '0.4274000000000000',
      volume: '854212.4500000000000000',
      quoteVolume: 365787.418355,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '0.4274000000000000',
      high: '0.4500000000000000',
      low: '0.4166000000000000',
      close: '0.4244000000000000',
      volume: '1059968.1300000000000000',
      quoteVolume: 455311.559954,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '0.4236000000000000',
      high: '0.4386000000000000',
      low: '0.4203000000000000',
      close: '0.4285000000000000',
      volume: '560690.8100000000000000',
      quoteVolume: 239706.048076,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '0.4286000000000000',
      high: '0.4372000000000000',
      low: '0.4173000000000000',
      close: '0.4221000000000000',
      volume: '331570.0000000000000000',
      quoteVolume: 140599.974044,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '0.4232000000000000',
      high: '0.4292000000000000',
      low: '0.4177000000000000',
      close: '0.4205000000000000',
      volume: '225429.9500000000000000',
      quoteVolume: 95660.216465,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '0.4203000000000000',
      high: '0.4255000000000000',
      low: '0.3929000000000000',
      close: '0.4036000000000000',
      volume: '946382.1900000000000000',
      quoteVolume: 387502.948292,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '0.4029000000000000',
      high: '0.4343000000000000',
      low: '0.4023000000000000',
      close: '0.4291000000000000',
      volume: '1686179.9000000000000000',
      quoteVolume: 703630.897735,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '0.4291000000000000',
      high: '0.4385000000000000',
      low: '0.4186000000000000',
      close: '0.4296000000000000',
      volume: '716328.1500000000000000',
      quoteVolume: 305884.809425,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '0.4296000000000000',
      high: '0.4766000000000000',
      low: '0.4256000000000000',
      close: '0.4521000000000000',
      volume: '1498764.0300000000000000',
      quoteVolume: 677393.543142,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '0.4521000000000000',
      high: '0.5107000000000000',
      low: '0.4519000000000000',
      close: '0.4611000000000000',
      volume: '3248280.8300000000000000',
      quoteVolume: 1570069.711841,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '0.4611000000000000',
      high: '0.4824000000000000',
      low: '0.4524000000000000',
      close: '0.4746000000000000',
      volume: '1381686.4300000000000000',
      quoteVolume: 643096.002798,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '0.4742000000000000',
      high: '0.5460000000000000',
      low: '0.4681000000000000',
      close: '0.4981000000000000',
      volume: '2887991.8600000000000000',
      quoteVolume: 1456511.395647,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '0.4982000000000000',
      high: '0.5181000000000000',
      low: '0.4382000000000000',
      close: '0.4629000000000000',
      volume: '2633054.2100000000000000',
      quoteVolume: 1262800.852306,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '0.4641000000000000',
      high: '0.5079000000000000',
      low: '0.4503000000000000',
      close: '0.4876000000000000',
      volume: '2105182.6700000000000000',
      quoteVolume: 1016416.428628,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '0.4872000000000000',
      high: '0.4880000000000000',
      low: '0.4671000000000000',
      close: '0.4805000000000000',
      volume: '1263356.6800000000000000',
      quoteVolume: 602730.177399,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '0.4814000000000000',
      high: '0.4994000000000000',
      low: '0.4722000000000000',
      close: '0.4871000000000000',
      volume: '1720792.4000000000000000',
      quoteVolume: 835254.863559,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '0.4858000000000000',
      high: '0.4960000000000000',
      low: '0.4831000000000000',
      close: '0.4858000000000000',
      volume: '577893.0600000000000000',
      quoteVolume: 281897.916414,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '0.4866000000000000',
      high: '0.4960000000000000',
      low: '0.4681000000000000',
      close: '0.4819000000000000',
      volume: '1768514.5100000000000000',
      quoteVolume: 850597.475821,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '0.4805000000000000',
      high: '0.4887000000000000',
      low: '0.4702000000000000',
      close: '0.4785000000000000',
      volume: '1352126.7300000000000000',
      quoteVolume: 645227.394195,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '0.4789000000000000',
      high: '0.4833000000000000',
      low: '0.4670000000000000',
      close: '0.4757000000000000',
      volume: '891826.0900000000000000',
      quoteVolume: 422057.338849,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '0.4760000000000000',
      high: '0.4800000000000000',
      low: '0.4648000000000000',
      close: '0.4770000000000000',
      volume: '530734.5900000000000000',
      quoteVolume: 250536.102196,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '0.4796000000000000',
      high: '0.4899000000000000',
      low: '0.4737000000000000',
      close: '0.4807000000000000',
      volume: '696146.2800000000000000',
      quoteVolume: 335078.549549,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '0.4818000000000000',
      high: '0.4842000000000000',
      low: '0.4342000000000000',
      close: '0.4548000000000000',
      volume: '1043991.8000000000000000',
      quoteVolume: 484257.113374,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '0.4551000000000000',
      high: '0.4655000000000000',
      low: '0.4470000000000000',
      close: '0.4646000000000000',
      volume: '863577.3300000000000000',
      quoteVolume: 392384.916353,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '0.4627000000000000',
      high: '0.4644000000000000',
      low: '0.4545000000000000',
      close: '0.4608000000000000',
      volume: '406749.6600000000000000',
      quoteVolume: 186648.714066,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '0.4606000000000000',
      high: '0.4621000000000000',
      low: '0.4485000000000000',
      close: '0.4531000000000000',
      volume: '634173.7500000000000000',
      quoteVolume: 287618.895525,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '0.4539000000000000',
      high: '0.4949000000000000',
      low: '0.4410000000000000',
      close: '0.4734000000000000',
      volume: '2441517.9900000000000000',
      quoteVolume: 1142857.563225,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '0.4729000000000000',
      high: '0.4850000000000000',
      low: '0.4480000000000000',
      close: '0.4605000000000000',
      volume: '1129722.2000000000000000',
      quoteVolume: 526180.143477,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '0.4603000000000000',
      high: '0.4997000000000000',
      low: '0.4556000000000000',
      close: '0.4946000000000000',
      volume: '1352736.3400000000000000',
      quoteVolume: 651471.143333,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '0.4967000000000000',
      high: '0.5196000000000000',
      low: '0.4846000000000000',
      close: '0.4992000000000000',
      volume: '1443876.8300000000000000',
      quoteVolume: 719069.643363,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '0.4984000000000000',
      high: '0.5042000000000000',
      low: '0.4887000000000000',
      close: '0.5008000000000000',
      volume: '877803.9200000000000000',
      quoteVolume: 434977.122532,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '0.5008000000000000',
      high: '0.5034000000000000',
      low: '0.4858000000000000',
      close: '0.4940000000000000',
      volume: '675007.1300000000000000',
      quoteVolume: 332877.149186,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '0.4938000000000000',
      high: '0.5410000000000000',
      low: '0.4868000000000000',
      close: '0.5084000000000000',
      volume: '2047013.3300000000000000',
      quoteVolume: 1057434.916151,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '0.5084000000000000',
      high: '0.5630000000000000',
      low: '0.5007000000000000',
      close: '0.5540000000000000',
      volume: '2288623.9600000000000000',
      quoteVolume: 1226137.818617,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '0.5533000000000000',
      high: '0.6000000000000000',
      low: '0.5326000000000000',
      close: '0.5811000000000000',
      volume: '4081948.8400000000000000',
      quoteVolume: 2328532.773552,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '0.5811000000000000',
      high: '0.6073000000000000',
      low: '0.5170000000000000',
      close: '0.5620000000000000',
      volume: '4435492.2100000000000000',
      quoteVolume: 2584274.57288,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '0.5588000000000000',
      high: '0.5839000000000000',
      low: '0.5195000000000000',
      close: '0.5396000000000000',
      volume: '2088035.7200000000000000',
      quoteVolume: 1155026.496367,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '0.5409000000000000',
      high: '0.5799000000000000',
      low: '0.5390000000000000',
      close: '0.5729000000000000',
      volume: '1039348.6800000000000000',
      quoteVolume: 579093.364827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '0.5735000000000000',
      high: '0.5900000000000000',
      low: '0.5557000000000000',
      close: '0.5691000000000000',
      volume: '1176149.9800000000000000',
      quoteVolume: 672544.531992,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '0.5677000000000000',
      high: '0.5918000000000000',
      low: '0.5655000000000000',
      close: '0.5773000000000000',
      volume: '956649.3600000000000000',
      quoteVolume: 552493.434727,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '0.5776000000000000',
      high: '0.5912000000000000',
      low: '0.5370000000000000',
      close: '0.5634000000000000',
      volume: '1559203.7500000000000000',
      quoteVolume: 875383.308889,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '0.5617000000000000',
      high: '0.5637000000000000',
      low: '0.5170000000000000',
      close: '0.5300000000000000',
      volume: '1284451.2500000000000000',
      quoteVolume: 682600.691592,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '0.5300000000000000',
      high: '0.5452000000000000',
      low: '0.5100000000000000',
      close: '0.5197000000000000',
      volume: '1259717.4200000000000000',
      quoteVolume: 662636.077743,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '0.5198000000000000',
      high: '0.5210000000000000',
      low: '0.4530000000000000',
      close: '0.4821000000000000',
      volume: '1891750.2700000000000000',
      quoteVolume: 920572.945172,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '0.4821000000000000',
      high: '0.5134000000000000',
      low: '0.4531000000000000',
      close: '0.4920000000000000',
      volume: '1247794.8300000000000000',
      quoteVolume: 609014.276187,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '0.4932000000000000',
      high: '0.5399000000000000',
      low: '0.4913000000000000',
      close: '0.5358000000000000',
      volume: '984745.9300000000000000',
      quoteVolume: 514382.609618,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '0.5366000000000000',
      high: '0.5449000000000000',
      low: '0.5073000000000000',
      close: '0.5225000000000000',
      volume: '825009.0900000000000000',
      quoteVolume: 431556.816813,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '0.5259000000000000',
      high: '0.5268000000000000',
      low: '0.4800000000000000',
      close: '0.4883000000000000',
      volume: '1617780.6900000000000000',
      quoteVolume: 812827.262155,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '0.4884000000000000',
      high: '0.5176000000000000',
      low: '0.4883000000000000',
      close: '0.4985000000000000',
      volume: '752244.2900000000000000',
      quoteVolume: 377882.383651,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '0.4998000000000000',
      high: '0.5046000000000000',
      low: '0.4880000000000000',
      close: '0.4998000000000000',
      volume: '625939.6200000000000000',
      quoteVolume: 310958.450695,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '0.4992000000000000',
      high: '0.5226000000000000',
      low: '0.4974000000000000',
      close: '0.5193000000000000',
      volume: '747995.9900000000000000',
      quoteVolume: 382966.982357,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '0.5187000000000000',
      high: '0.5390000000000000',
      low: '0.5083000000000000',
      close: '0.5095000000000000',
      volume: '857084.8000000000000000',
      quoteVolume: 447910.430574,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '0.5105000000000000',
      high: '0.5287000000000000',
      low: '0.5050000000000000',
      close: '0.5118000000000000',
      volume: '547954.6800000000000000',
      quoteVolume: 281962.973871,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '0.5117000000000000',
      high: '0.5139000000000000',
      low: '0.4752000000000000',
      close: '0.4963000000000000',
      volume: '755636.9500000000000000',
      quoteVolume: 371111.531726,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '0.4963000000000000',
      high: '0.5170000000000000',
      low: '0.4837000000000000',
      close: '0.4957000000000000',
      volume: '669329.7000000000000000',
      quoteVolume: 335305.551779,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '0.4920000000000000',
      high: '0.5040000000000000',
      low: '0.4756000000000000',
      close: '0.4756000000000000',
      volume: '798688.9900000000000000',
      quoteVolume: 391603.526803,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '0.4756000000000000',
      high: '0.4800000000000000',
      low: '0.4400000000000000',
      close: '0.4750000000000000',
      volume: '1341167.6500000000000000',
      quoteVolume: 614034.70833,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '0.4745000000000000',
      high: '0.4997000000000000',
      low: '0.4350000000000000',
      close: '0.4503000000000000',
      volume: '1289948.5700000000000000',
      quoteVolume: 607159.886379,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '0.4499000000000000',
      high: '0.4699000000000000',
      low: '0.4264000000000000',
      close: '0.4416000000000000',
      volume: '881943.9300000000000000',
      quoteVolume: 389402.998679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '0.4437000000000000',
      high: '0.4698000000000000',
      low: '0.4357000000000000',
      close: '0.4430000000000000',
      volume: '330467.6700000000000000',
      quoteVolume: 149494.592163,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '0.4448000000000000',
      high: '0.4600000000000000',
      low: '0.4403000000000000',
      close: '0.4510000000000000',
      volume: '429353.9200000000000000',
      quoteVolume: 194108.9041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '0.4510000000000000',
      high: '0.4546000000000000',
      low: '0.4240000000000000',
      close: '0.4338000000000000',
      volume: '394944.3300000000000000',
      quoteVolume: 173429.731609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '0.4334000000000000',
      high: '0.4771000000000000',
      low: '0.4320000000000000',
      close: '0.4727000000000000',
      volume: '610148.8000000000000000',
      quoteVolume: 281321.216962,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '0.4750000000000000',
      high: '0.4876000000000000',
      low: '0.4638000000000000',
      close: '0.4730000000000000',
      volume: '397476.9300000000000000',
      quoteVolume: 188524.095397,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '0.4745000000000000',
      high: '0.4757000000000000',
      low: '0.4600000000000000',
      close: '0.4630000000000000',
      volume: '228270.4000000000000000',
      quoteVolume: 106411.499404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '0.4643000000000000',
      high: '0.5223000000000000',
      low: '0.4607000000000000',
      close: '0.5110000000000000',
      volume: '1017233.7800000000000000',
      quoteVolume: 502255.676203,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '0.5118000000000000',
      high: '0.5151000000000000',
      low: '0.4783000000000000',
      close: '0.4964000000000000',
      volume: '640984.0600000000000000',
      quoteVolume: 318811.507836,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '0.4964000000000000',
      high: '0.5238000000000000',
      low: '0.4902000000000000',
      close: '0.5058000000000000',
      volume: '641774.3200000000000000',
      quoteVolume: 328895.388404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '0.5058000000000000',
      high: '0.5882000000000000',
      low: '0.4901000000000000',
      close: '0.5745000000000000',
      volume: '1593771.0200000000000000',
      quoteVolume: 868680.989601,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '0.5798000000000000',
      high: '0.6186000000000000',
      low: '0.5409000000000000',
      close: '0.5435000000000000',
      volume: '2267674.3000000000000000',
      quoteVolume: 1314846.891036,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '0.5433000000000000',
      high: '0.6157000000000000',
      low: '0.5212000000000000',
      close: '0.5940000000000000',
      volume: '1255624.9100000000000000',
      quoteVolume: 730122.02836,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '0.5940000000000000',
      high: '0.7040000000000000',
      low: '0.5873000000000000',
      close: '0.6403000000000000',
      volume: '3548028.1300000000000000',
      quoteVolume: 2284998.871122,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '0.6402000000000000',
      high: '0.7233000000000000',
      low: '0.6374000000000000',
      close: '0.6703000000000000',
      volume: '2987660.6900000000000000',
      quoteVolume: 2051444.51188,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '0.6731000000000000',
      high: '0.7484000000000000',
      low: '0.6517000000000000',
      close: '0.6752000000000000',
      volume: '3014329.8900000000000000',
      quoteVolume: 2076341.525905,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '0.6790000000000000',
      high: '0.6999000000000000',
      low: '0.6285000000000000',
      close: '0.6586000000000000',
      volume: '1760800.0800000000000000',
      quoteVolume: 1165285.921864,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '0.6607000000000000',
      high: '0.6872000000000000',
      low: '0.6336000000000000',
      close: '0.6474000000000000',
      volume: '1281864.7000000000000000',
      quoteVolume: 853179.428728,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '0.6474000000000000',
      high: '0.9565000000000000',
      low: '0.6332000000000000',
      close: '0.8280000000000000',
      volume: '7911014.0700000000000000',
      quoteVolume: 6371210.43235,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '0.8251000000000000',
      high: '0.8890000000000000',
      low: '0.7772000000000000',
      close: '0.8427000000000000',
      volume: '3798325.6400000000000000',
      quoteVolume: 3148626.361888,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '0.8427000000000000',
      high: '0.9389000000000000',
      low: '0.7551000000000000',
      close: '0.9280000000000000',
      volume: '4555532.1900000000000000',
      quoteVolume: 3883945.234255,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '0.9264000000000000',
      high: '0.9869000000000000',
      low: '0.8100000000000000',
      close: '0.8911000000000000',
      volume: '5725645.6900000000000000',
      quoteVolume: 5097003.16005,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '0.8950000000000000',
      high: '0.9592000000000000',
      low: '0.8457000000000000',
      close: '0.9359000000000000',
      volume: '2389369.0400000000000000',
      quoteVolume: 2179041.174403,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '0.9360000000000000',
      high: '1.0500000000000000',
      low: '0.9279000000000000',
      close: '0.9934000000000000',
      volume: '4785605.6200000000000000',
      quoteVolume: 4702849.494509,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '0.9927000000000000',
      high: '1.1430000000000000',
      low: '0.9460000000000000',
      close: '1.1170000000000000',
      volume: '2936467.3000000000000000',
      quoteVolume: 2982285.87569,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '1.1146000000000000',
      high: '1.2500000000000000',
      low: '1.0600000000000000',
      close: '1.1798000000000000',
      volume: '5046994.2000000000000000',
      quoteVolume: 5745623.487639,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '1.1855000000000000',
      high: '1.4740000000000000',
      low: '1.1301000000000000',
      close: '1.2205000000000000',
      volume: '14821357.0900000000000000',
      quoteVolume: 19619790.33193,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '1.2205000000000000',
      high: '1.3655000000000000',
      low: '1.1418000000000000',
      close: '1.3392000000000000',
      volume: '6874618.3900000000000000',
      quoteVolume: 8592874.224192,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '1.3393000000000000',
      high: '1.4347000000000000',
      low: '1.2216000000000000',
      close: '1.3314000000000000',
      volume: '5097940.0500000000000000',
      quoteVolume: 6795296.790503,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '1.3257000000000000',
      high: '1.3376000000000000',
      low: '1.1005000000000000',
      close: '1.1139000000000000',
      volume: '3990172.6100000000000000',
      quoteVolume: 4655614.840081993,
      btcVolume: 581.9913178767249,
      usdVolume: 4655614.840081993,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '1.1181000000000000',
      high: '1.1824000000000000',
      low: '1.0340000000000000',
      close: '1.0756000000000000',
      volume: '2787144.3800000000000000',
      quoteVolume: 3053065.8027050006,
      btcVolume: 377.16663921841183,
      usdVolume: 3053065.8027050006,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '1.0806000000000000',
      high: '1.2750000000000000',
      low: '1.0806000000000000',
      close: '1.2119000000000000',
      volume: '3879947.9900000000000000',
      quoteVolume: 4585345.262517948,
      btcVolume: 526.2433979570677,
      usdVolume: 4585345.262517948,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '1.2199000000000000',
      high: '1.3500000000000000',
      low: '1.1444000000000000',
      close: '1.2921000000000000',
      volume: '2591508.8200000000000000',
      quoteVolume: 3228837.802578005,
      btcVolume: 371.1273709190563,
      usdVolume: 3228837.802578005,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '1.2782000000000000',
      high: '1.2844000000000000',
      low: '1.1497000000000000',
      close: '1.1665000000000000',
      volume: '2450800.8100000000000000',
      quoteVolume: 2938928.7943600034,
      btcVolume: 340.2041830509092,
      usdVolume: 2938928.7943600034,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '1.1780000000000000',
      high: '1.2250000000000000',
      low: '0.9044000000000000',
      close: '1.0057000000000000',
      volume: '6305578.1300000000000000',
      quoteVolume: 6787576.3371680295,
      btcVolume: 791.9128840898691,
      usdVolume: 6787576.3371680295,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '1.0063000000000000',
      high: '1.0444000000000000',
      low: '0.9330000000000000',
      close: '1.0195000000000000',
      volume: '4936161.7800000000000000',
      quoteVolume: 4857535.299134998,
      btcVolume: 581.7438750463537,
      usdVolume: 4857535.299134998,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '1.0252000000000000',
      high: '1.0597000000000000',
      low: '0.9511000000000000',
      close: '0.9560000000000000',
      volume: '2558921.2100000000000000',
      quoteVolume: 2554370.774409004,
      btcVolume: 299.04123365519683,
      usdVolume: 2554370.774409004,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '0.9536000000000000',
      high: '1.0554000000000000',
      low: '0.9323000000000000',
      close: '1.0155000000000000',
      volume: '3448639.2900000000000000',
      quoteVolume: 3433793.2090730225,
      btcVolume: 396.5090133601953,
      usdVolume: 3433793.2090730225,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '1.0155000000000000',
      high: '1.0200000000000000',
      low: '0.8853000000000000',
      close: '0.9000000000000000',
      volume: '3218003.4000000000000000',
      quoteVolume: 3096993.8518149965,
      btcVolume: 365.97881939525035,
      usdVolume: 3096993.8518149965,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '0.8985000000000000',
      high: '0.9299000000000000',
      low: '0.8368000000000000',
      close: '0.8850000000000000',
      volume: '4563258.6100000000000000',
      quoteVolume: 4005371.5748359957,
      btcVolume: 509.9416978186965,
      usdVolume: 4005371.5748359957,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '0.8850000000000000',
      high: '1.0991000000000000',
      low: '0.8838000000000000',
      close: '1.0959000000000000',
      volume: '10134365.7300000000000000',
      quoteVolume: 10259929.50928907,
      btcVolume: 1323.3373278111412,
      usdVolume: 10259929.50928907,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '1.0960000000000000',
      high: '1.2700000000000000',
      low: '1.0556000000000000',
      close: '1.1098000000000000',
      volume: '11104062.4700000000000000',
      quoteVolume: 12707991.386383945,
      btcVolume: 1649.824982729021,
      usdVolume: 12707991.386383945,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '1.1099000000000000',
      high: '1.2589000000000000',
      low: '1.0710000000000000',
      close: '1.1753000000000000',
      volume: '6318630.4200000000000000',
      quoteVolume: 7452520.230448068,
      btcVolume: 944.3447342802139,
      usdVolume: 7452520.230448068,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '1.1747000000000000',
      high: '1.1991000000000000',
      low: '1.1165000000000000',
      close: '1.1326000000000000',
      volume: '2532914.5100000000000000',
      quoteVolume: 2922870.3576520067,
      btcVolume: 369.3243148377816,
      usdVolume: 2922870.3576520067,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '1.1376000000000000',
      high: '1.1486000000000000',
      low: '1.0085000000000000',
      close: '1.0595000000000000',
      volume: '3542850.8800000000000000',
      quoteVolume: 3804204.519311013,
      btcVolume: 491.5768447574139,
      usdVolume: 3804204.519311013,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '1.0595000000000000',
      high: '1.2104000000000000',
      low: '1.0301000000000000',
      close: '1.2060000000000000',
      volume: '3560912.7500000000000000',
      quoteVolume: 4027483.4204219743,
      btcVolume: 514.0312403954563,
      usdVolume: 4027483.4204219743,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '1.2043000000000000',
      high: '1.2070000000000000',
      low: '1.0876000000000000',
      close: '1.1243000000000000',
      volume: '3064771.3900000000000000',
      quoteVolume: 3504537.899613992,
      btcVolume: 445.75194109413064,
      usdVolume: 3504537.899613992,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '1.1241000000000000',
      high: '1.1643000000000000',
      low: '1.0875000000000000',
      close: '1.1448000000000000',
      volume: '2552167.9900000000000000',
      quoteVolume: 2871559.2307969993,
      btcVolume: 359.4040334628175,
      usdVolume: 2871559.2307969993,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '1.1448000000000000',
      high: '1.9700000000000000',
      low: '1.1126000000000000',
      close: '1.8680000000000000',
      volume: '34026255.5700000000000000',
      quoteVolume: 58291200.9484317,
      btcVolume: 7099.103367062914,
      usdVolume: 58291200.9484317,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '1.8680000000000000',
      high: '1.8936000000000000',
      low: '1.4900000000000000',
      close: '1.6395000000000000',
      volume: '33206570.6700000000000000',
      quoteVolume: 54533735.08588836,
      btcVolume: 6570.521290055457,
      usdVolume: 54533735.08588836,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '1.6364000000000000',
      high: '1.8065000000000000',
      low: '1.6022000000000000',
      close: '1.7179000000000000',
      volume: '10700181.1400000000000000',
      quoteVolume: 18387957.79867493,
      btcVolume: 2121.574515120722,
      usdVolume: 18387957.79867493,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '1.7180000000000000',
      high: '1.7924000000000000',
      low: '1.6000000000000000',
      close: '1.6621000000000000',
      volume: '8261300.1500000000000000',
      quoteVolume: 13964950.109855045,
      btcVolume: 1544.6562665703477,
      usdVolume: 13964950.109855045,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '1.6651000000000000',
      high: '2.0776000000000000',
      low: '1.6525000000000000',
      close: '1.9632000000000000',
      volume: '17062882.5500000000000000',
      quoteVolume: 32436293.282424346,
      btcVolume: 3512.1607543888294,
      usdVolume: 32436293.282424346,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '1.9631000000000000',
      high: '2.0185000000000000',
      low: '1.7600000000000000',
      close: '1.8194000000000000',
      volume: '13903321.3000000000000000',
      quoteVolume: 25882989.567272145,
      btcVolume: 2819.6184477064003,
      usdVolume: 25816336.76297314,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '1.8158000000000000',
      high: '1.9016000000000000',
      low: '1.7869000000000000',
      close: '1.8353000000000000',
      volume: '4857233.6000000000000000',
      quoteVolume: 8953905.75995399,
      btcVolume: 980.2564405252405,
      usdVolume: 8953905.75995399,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '1.8352000000000000',
      high: '1.8865000000000000',
      low: '1.6862000000000000',
      close: '1.7656000000000000',
      volume: '5058765.9500000000000000',
      quoteVolume: 8995915.657141935,
      btcVolume: 964.4591378098079,
      usdVolume: 8995915.657141935,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '1.7671000000000000',
      high: '1.7900000000000000',
      low: '1.6100000000000000',
      close: '1.6883000000000000',
      volume: '6929829.1600000000000000',
      quoteVolume: 11630088.984655974,
      btcVolume: 1192.1055771385234,
      usdVolume: 11630088.984655974,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '1.6895000000000000',
      high: '1.9470000000000000',
      low: '1.6827000000000000',
      close: '1.8428000000000000',
      volume: '8390022.4600000000000000',
      quoteVolume: 15123936.817318201,
      btcVolume: 1410.6256719829264,
      usdVolume: 15123936.817318201,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '1.8429000000000000',
      high: '1.9100000000000000',
      low: '1.7702000000000000',
      close: '1.8169000000000000',
      volume: '4593412.3100000000000000',
      quoteVolume: 8418104.00219802,
      btcVolume: 775.4769301470001,
      usdVolume: 8418104.00219802,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '1.8169000000000000',
      high: '1.8884000000000000',
      low: '1.7900000000000000',
      close: '1.8689000000000000',
      volume: '2384487.8200000000000000',
      quoteVolume: 4400730.711527992,
      btcVolume: 403.4557862186641,
      usdVolume: 4400730.711527992,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '1.8689000000000000',
      high: '2.4273000000000000',
      low: '1.8613000000000000',
      close: '2.1928000000000000',
      volume: '21998410.4800000000000000',
      quoteVolume: 47761526.31202764,
      btcVolume: 4201.741421999988,
      usdVolume: 47761526.31202764,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '2.1931000000000000',
      high: '2.5000000000000000',
      low: '2.0231000000000000',
      close: '2.2800000000000000',
      volume: '15800691.2200000000000000',
      quoteVolume: 35017061.17698764,
      btcVolume: 2716.258242985611,
      usdVolume: 35017061.17698764,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '2.2821000000000000',
      high: '2.5550000000000000',
      low: '2.1123000000000000',
      close: '2.2879000000000000',
      volume: '13867785.5900000000000000',
      quoteVolume: 32591424.308536824,
      btcVolume: 2692.462710930972,
      usdVolume: 32591424.308536824,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '2.2912000000000000',
      high: '3.0981000000000000',
      low: '2.2150000000000000',
      close: '3.0392000000000000',
      volume: '16190974.7700000000000000',
      quoteVolume: 43067890.31031614,
      btcVolume: 3571.5324626501774,
      usdVolume: 43067890.31031614,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '3.0391000000000000',
      high: '4.5826000000000000',
      low: '2.9499000000000000',
      close: '3.7125000000000000',
      volume: '55501686.3100000000000000',
      quoteVolume: 212256136.05785748,
      btcVolume: 17792.420778535194,
      usdVolume: 212256136.05785748,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '3.7051000000000000',
      high: '4.2200000000000000',
      low: '3.2300000000000000',
      close: '3.3869000000000000',
      volume: '25190926.7600000000000000',
      quoteVolume: 97105333.71238178,
      btcVolume: 8374.845437346688,
      usdVolume: 97105333.71238178,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '3.3884000000000000',
      high: '3.8620000000000000',
      low: '2.9266000000000000',
      close: '3.6369000000000000',
      volume: '25321151.3600000000000000',
      quoteVolume: 85723441.75518183,
      btcVolume: 8111.5203882492,
      usdVolume: 85723441.75518183,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '3.6476000000000000',
      high: '3.9914000000000000',
      low: '3.2010000000000000',
      close: '3.7453000000000000',
      volume: '26688227.6900000000000000',
      quoteVolume: 97119111.08506477,
      btcVolume: 9423.268210581768,
      usdVolume: 97119111.08506477,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '3.7441000000000000',
      high: '3.8857000000000000',
      low: '3.4769000000000000',
      close: '3.4900000000000000',
      volume: '11091453.7900000000000000',
      quoteVolume: 40850492.97302097,
      btcVolume: 3600.00749644261,
      usdVolume: 40850492.97302097,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '3.4854000000000000',
      high: '3.6100000000000000',
      low: '3.2040000000000000',
      close: '3.4530000000000000',
      volume: '9871917.6900000000000000',
      quoteVolume: 33779334.70123714,
      btcVolume: 2886.5694636866006,
      usdVolume: 33779334.70123714,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '3.4555000000000000',
      high: '3.7500000000000000',
      low: '3.4410000000000000',
      close: '3.6400000000000000',
      volume: '10497021.6500000000000000',
      quoteVolume: 37787417.88724579,
      btcVolume: 3395.0022936467367,
      usdVolume: 37787417.88724579,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '3.6400000000000000',
      high: '3.6611000000000000',
      low: '3.4384000000000000',
      close: '3.4659000000000000',
      volume: '4978418.4100000000000000',
      quoteVolume: 17630030.877459843,
      btcVolume: 1551.1898933985874,
      usdVolume: 17630030.877459843,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '3.4650000000000000',
      high: '3.5038000000000000',
      low: '3.2500000000000000',
      close: '3.2750000000000000',
      volume: '5553651.6400000000000000',
      quoteVolume: 18694548.744352836,
      btcVolume: 1655.1485012561434,
      usdVolume: 18694548.744352836,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '3.2750000000000000',
      high: '3.5731000000000000',
      low: '3.2500000000000000',
      close: '3.3362000000000000',
      volume: '5268983.9800000000000000',
      quoteVolume: 17808772.169726066,
      btcVolume: 1520.284319482537,
      usdVolume: 17808772.169726066,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '3.3334000000000000',
      high: '3.3741000000000000',
      low: '2.9401000000000000',
      close: '3.0670000000000000',
      volume: '8673443.7700000000000000',
      quoteVolume: 27298953.85568921,
      btcVolume: 2188.425469158119,
      usdVolume: 27298953.85568921,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '3.0670000000000000',
      high: '3.2568000000000000',
      low: '2.8578000000000000',
      close: '3.1166000000000000',
      volume: '9527421.0200000000000000',
      quoteVolume: 29323317.465814188,
      btcVolume: 2364.92290456046,
      usdVolume: 29323317.465814188,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '3.1166000000000000',
      high: '3.1555000000000000',
      low: '2.7100000000000000',
      close: '2.7853000000000000',
      volume: '7262068.2500000000000000',
      quoteVolume: 21179286.916986044,
      btcVolume: 1836.1996771189365,
      usdVolume: 21179286.916986044,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '2.7870000000000000',
      high: '3.3282000000000000',
      low: '2.6000000000000000',
      close: '3.1660000000000000',
      volume: '11731863.1100000000000000',
      quoteVolume: 35185953.587122776,
      btcVolume: 3037.647143684832,
      usdVolume: 35185953.587122776,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '3.1660000000000000',
      high: '3.2953000000000000',
      low: '3.0400000000000000',
      close: '3.1652000000000000',
      volume: '9065258.2000000000000000',
      quoteVolume: 28811293.98759913,
      btcVolume: 2530.9180949987526,
      usdVolume: 28811293.98759913,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '3.1689000000000000',
      high: '3.2413000000000000',
      low: '2.7611000000000000',
      close: '2.7856000000000000',
      volume: '8164892.0900000000000000',
      quoteVolume: 24328975.206776988,
      btcVolume: 2259.0976435334483,
      usdVolume: 24328975.206776988,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '2.7792000000000000',
      high: '2.8498000000000000',
      low: '2.5801000000000000',
      close: '2.6913000000000000',
      volume: '7318353.9000000000000000',
      quoteVolume: 19951566.39118311,
      btcVolume: 1928.7548326502576,
      usdVolume: 19951566.39118311,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '2.6911000000000000',
      high: '2.7749000000000000',
      low: '2.1521000000000000',
      close: '2.1948000000000000',
      volume: '7934695.5900000000000000',
      quoteVolume: 19458898.407283843,
      btcVolume: 1935.2094152580992,
      usdVolume: 19458898.407283843,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '2.1947000000000000',
      high: '2.6000000000000000',
      low: '2.0531000000000000',
      close: '2.3980000000000000',
      volume: '13183927.9300000000000000',
      quoteVolume: 30803035.246100836,
      btcVolume: 3208.952057742877,
      usdVolume: 30803035.246100836,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '2.3951000000000000',
      high: '2.8094000000000000',
      low: '2.3542000000000000',
      close: '2.6700000000000000',
      volume: '13345016.8600000000000000',
      quoteVolume: 34825584.843023226,
      btcVolume: 3500.7237591055887,
      usdVolume: 34764284.05553622,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '2.6700000000000000',
      high: '2.7490000000000000',
      low: '2.4910000000000000',
      close: '2.6046000000000000',
      volume: '5510181.5700000000000000',
      quoteVolume: 14293523.817000896,
      btcVolume: 1368.4110094593677,
      usdVolume: 14293523.817000896,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '2.6020000000000000',
      high: '2.7314000000000000',
      low: '2.5751000000000000',
      close: '2.6370000000000000',
      volume: '4596378.0700000000000000',
      quoteVolume: 12202999.48393705,
      btcVolume: 1147.4765675933133,
      usdVolume: 12202999.48393705,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '2.6369000000000000',
      high: '2.6650000000000000',
      low: '2.4600000000000000',
      close: '2.5319000000000000',
      volume: '3349432.2400000000000000',
      quoteVolume: 8531199.090973042,
      btcVolume: 809.0792883766617,
      usdVolume: 8531199.090973042,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '2.5319000000000000',
      high: '2.5857000000000000',
      low: '2.3732000000000000',
      close: '2.4078000000000000',
      volume: '3415186.7600000000000000',
      quoteVolume: 8425751.27914594,
      btcVolume: 810.0485394775847,
      usdVolume: 8425751.27914594,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '2.4073000000000000',
      high: '2.5200000000000000',
      low: '2.2489000000000000',
      close: '2.3535000000000000',
      volume: '5670086.3900000000000000',
      quoteVolume: 13434498.579576982,
      btcVolume: 1334.7270621160683,
      usdVolume: 13434498.579576982,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '2.3559000000000000',
      high: '2.5131000000000000',
      low: '2.2700000000000000',
      close: '2.3999000000000000',
      volume: '5308279.8100000000000000',
      quoteVolume: 12686743.212995907,
      btcVolume: 1303.671357536717,
      usdVolume: 12686743.212995907,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '2.3971000000000000',
      high: '2.5149000000000000',
      low: '2.3595000000000000',
      close: '2.3615000000000000',
      volume: '4016580.8300000000000000',
      quoteVolume: 9840046.642877948,
      btcVolume: 982.9918828701473,
      usdVolume: 9840046.642877948,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '2.3615000000000000',
      high: '2.4300000000000000',
      low: '2.3201000000000000',
      close: '2.3989000000000000',
      volume: '1971791.7400000000000000',
      quoteVolume: 4688945.92508803,
      btcVolume: 480.4188696425642,
      usdVolume: 4688945.92508803,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '2.3954000000000000',
      high: '2.4600000000000000',
      low: '2.1525000000000000',
      close: '2.2453000000000000',
      volume: '4245834.2700000000000000',
      quoteVolume: 9865099.724368896,
      btcVolume: 1019.8334976316504,
      usdVolume: 9865099.724368896,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '2.2372000000000000',
      high: '2.2733000000000000',
      low: '2.1000000000000000',
      close: '2.2156000000000000',
      volume: '1902749.3100000000000000',
      quoteVolume: 4200464.91369699,
      btcVolume: 443.62670497298694,
      usdVolume: 4200464.91369699,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '2.2178000000000000',
      high: '2.2482000000000000',
      low: '2.1100000000000000',
      close: '2.1251000000000000',
      volume: '2215943.5300000000000000',
      quoteVolume: 4805773.902062991,
      btcVolume: 503.4159560790306,
      usdVolume: 4805773.902062991,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '2.1250000000000000',
      high: '2.1854000000000000',
      low: '1.9694000000000000',
      close: '2.0682000000000000',
      volume: '4210835.6200000000000000',
      quoteVolume: 8720296.91253309,
      btcVolume: 916.5223854094169,
      usdVolume: 8720296.91253309,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '2.0672000000000000',
      high: '2.2500000000000000',
      low: '2.0589000000000000',
      close: '2.2102000000000000',
      volume: '4558064.6100000000000000',
      quoteVolume: 9920046.062618986,
      btcVolume: 1007.3882816663967,
      usdVolume: 9920046.062618986,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '2.2169000000000000',
      high: '2.2310000000000000',
      low: '2.1054000000000000',
      close: '2.1610000000000000',
      volume: '2890541.3500000000000000',
      quoteVolume: 6235734.283716941,
      btcVolume: 618.792071520022,
      usdVolume: 6235734.283716941,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '2.1612000000000000',
      high: '2.4900000000000000',
      low: '2.1275000000000000',
      close: '2.4271000000000000',
      volume: '6319480.9600000000000000',
      quoteVolume: 14629814.693532972,
      btcVolume: 1400.4593413301136,
      usdVolume: 14629814.693532972,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '2.4269000000000000',
      high: '2.7342000000000000',
      low: '2.3569000000000000',
      close: '2.4211000000000000',
      volume: '11323687.1300000000000000',
      quoteVolume: 29135005.95525144,
      btcVolume: 2701.80633921746,
      usdVolume: 29135005.95525144,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '2.4186000000000000',
      high: '2.6044000000000000',
      low: '2.3811000000000000',
      close: '2.5099000000000000',
      volume: '6257429.6800000000000000',
      quoteVolume: 15626611.666627988,
      btcVolume: 1446.5228705557931,
      usdVolume: 15626611.666627988,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '2.5097000000000000',
      high: '2.6495000000000000',
      low: '2.4562000000000000',
      close: '2.4562000000000000',
      volume: '5389254.3200000000000000',
      quoteVolume: 13710085.081441978,
      btcVolume: 1176.551959779662,
      usdVolume: 13710085.081441978,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '2.4599000000000000',
      high: '2.5181000000000000',
      low: '2.3100000000000000',
      close: '2.4785000000000000',
      volume: '6024056.7500000000000000',
      quoteVolume: 14593117.820943044,
      btcVolume: 1242.3745698550665,
      usdVolume: 14593117.820943044,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '2.4822000000000000',
      high: '2.5000000000000000',
      low: '2.3502000000000000',
      close: '2.3826000000000000',
      volume: '3376864.0700000000000000',
      quoteVolume: 8167003.771067984,
      btcVolume: 696.2613814905944,
      usdVolume: 8167003.771067984,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '2.3883000000000000',
      high: '2.3998000000000000',
      low: '2.2713000000000000',
      close: '2.2755000000000000',
      volume: '2863707.1900000000000000',
      quoteVolume: 6666989.981878993,
      btcVolume: 564.8964889382114,
      usdVolume: 6666989.981878993,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '2.2784000000000000',
      high: '2.2919000000000000',
      low: '2.1510000000000000',
      close: '2.1669000000000000',
      volume: '2368470.3900000000000000',
      quoteVolume: 5268428.711106007,
      btcVolume: 444.782865591571,
      usdVolume: 5268428.711106007,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '2.1690000000000000',
      high: '2.4091000000000000',
      low: '2.1306000000000000',
      close: '2.3336000000000000',
      volume: '5233238.7500000000000000',
      quoteVolume: 12050390.512594998,
      btcVolume: 1035.7246734427822,
      usdVolume: 12050390.512594998,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '2.3336000000000000',
      high: '2.4900000000000000',
      low: '2.2901000000000000',
      close: '2.4157000000000000',
      volume: '3701976.9800000000000000',
      quoteVolume: 8866596.82357804,
      btcVolume: 777.1699540463694,
      usdVolume: 8866596.82357804,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '2.4157000000000000',
      high: '2.4790000000000000',
      low: '2.3350000000000000',
      close: '2.3900000000000000',
      volume: '2579648.7400000000000000',
      quoteVolume: 6209796.363283018,
      btcVolume: 544.0263252504219,
      usdVolume: 6209796.363283018,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '2.3846000000000000',
      high: '2.4679000000000000',
      low: '2.3060000000000000',
      close: '2.4229000000000000',
      volume: '3150417.3700000000000000',
      quoteVolume: 7506682.66399902,
      btcVolume: 678.7973027516069,
      usdVolume: 7506682.66399902,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '2.4190000000000000',
      high: '2.6031000000000000',
      low: '2.1630000000000000',
      close: '2.2842000000000000',
      volume: '6699207.3700000000000000',
      quoteVolume: 16231062.046769118,
      btcVolume: 1549.2558632873051,
      usdVolume: 16231062.046769118,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '2.2840000000000000',
      high: '2.4506000000000000',
      low: '2.2565000000000000',
      close: '2.4232000000000000',
      volume: '2806801.4000000000000000',
      quoteVolume: 6611413.672988072,
      btcVolume: 652.6110331933832,
      usdVolume: 6611413.672988072,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '2.4225000000000000',
      high: '2.4590000000000000',
      low: '2.2270000000000000',
      close: '2.3000000000000000',
      volume: '3572174.4000000000000000',
      quoteVolume: 8302828.475309981,
      btcVolume: 815.1156611012854,
      usdVolume: 8302828.475309981,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '2.3046000000000000',
      high: '2.4190000000000000',
      low: '2.2820000000000000',
      close: '2.4036000000000000',
      volume: '2035414.3900000000000000',
      quoteVolume: 4797115.851594993,
      btcVolume: 466.47840144058904,
      usdVolume: 4797115.851594993,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '2.4050000000000000',
      high: '2.5657000000000000',
      low: '2.3729000000000000',
      close: '2.5051000000000000',
      volume: '3610375.3000000000000000',
      quoteVolume: 8909798.939858058,
      btcVolume: 863.7326634348484,
      usdVolume: 8909798.939858058,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '2.5055000000000000',
      high: '2.6000000000000000',
      low: '2.4508000000000000',
      close: '2.4958000000000000',
      volume: '4280607.5800000000000000',
      quoteVolume: 10853323.38349604,
      btcVolume: 1017.6371082460578,
      usdVolume: 10853323.38349604,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '2.4943000000000000',
      high: '2.5401000000000000',
      low: '2.3354000000000000',
      close: '2.3624000000000000',
      volume: '3474086.6000000000000000',
      quoteVolume: 8391667.38601199,
      btcVolume: 780.6798427463843,
      usdVolume: 8391667.38601199,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '2.3622000000000000',
      high: '2.3710000000000000',
      low: '2.1500000000000000',
      close: '2.2054000000000000',
      volume: '4915776.4000000000000000',
      quoteVolume: 11029113.309874035,
      btcVolume: 1081.9973137236552,
      usdVolume: 11029113.309874035,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '2.2058000000000000',
      high: '2.2896000000000000',
      low: '2.1350000000000000',
      close: '2.2227000000000000',
      volume: '2980708.5400000000000000',
      quoteVolume: 6618775.966529956,
      btcVolume: 660.0412390163551,
      usdVolume: 6618775.966529956,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '2.2231000000000000',
      high: '2.2950000000000000',
      low: '2.1723000000000000',
      close: '2.2343000000000000',
      volume: '3274284.1600000000000000',
      quoteVolume: 7324880.720449999,
      btcVolume: 715.1730454658111,
      usdVolume: 7324880.720449999,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '2.2343000000000000',
      high: '2.2545000000000000',
      low: '2.1537000000000000',
      close: '2.1681000000000000',
      volume: '1629473.9800000000000000',
      quoteVolume: 3574006.0448640008,
      btcVolume: 352.9833991449766,
      usdVolume: 3574006.0448640008,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '2.1704000000000000',
      high: '2.1804000000000000',
      low: '2.0255000000000000',
      close: '2.0780000000000000',
      volume: '2603393.1400000000000000',
      quoteVolume: 5492656.678655035,
      btcVolume: 545.2530944238928,
      usdVolume: 5492656.678655035,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '2.0783000000000000',
      high: '2.1546000000000000',
      low: '2.0450000000000000',
      close: '2.0739000000000000',
      volume: '2094166.6100000000000000',
      quoteVolume: 4389987.473204011,
      btcVolume: 423.4809452125515,
      usdVolume: 4389987.473204011,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '2.0737000000000000',
      high: '2.1788000000000000',
      low: '2.0050000000000000',
      close: '2.1128000000000000',
      volume: '2811534.7300000000000000',
      quoteVolume: 5891385.969969013,
      btcVolume: 579.2737813324595,
      usdVolume: 5891385.969969013,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '2.1098000000000000',
      high: '2.1134000000000000',
      low: '1.8750000000000000',
      close: '1.8957000000000000',
      volume: '2925102.1700000000000000',
      quoteVolume: 5826671.213469991,
      btcVolume: 585.9165069079237,
      usdVolume: 5826671.213469991,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '1.8956000000000000',
      high: '1.8956000000000000',
      low: '1.7253000000000000',
      close: '1.7744000000000000',
      volume: '4172574.3900000000000000',
      quoteVolume: 7534972.244916026,
      btcVolume: 791.9607358540669,
      usdVolume: 7534972.244916026,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '1.7763000000000000',
      high: '1.8913000000000000',
      low: '1.7365000000000000',
      close: '1.7960000000000000',
      volume: '2660522.1000000000000000',
      quoteVolume: 4851727.818894994,
      btcVolume: 508.2563024568634,
      usdVolume: 4851727.818894994,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '1.7958000000000000',
      high: '1.8535000000000000',
      low: '1.7400000000000000',
      close: '1.7791000000000000',
      volume: '1818973.5000000000000000',
      quoteVolume: 3255608.6478299913,
      btcVolume: 339.9346500517163,
      usdVolume: 3255608.6478299913,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '1.7788000000000000',
      high: '1.8000000000000000',
      low: '1.7170000000000000',
      close: '1.7686000000000000',
      volume: '1329827.2100000000000000',
      quoteVolume: 2338722.617214002,
      btcVolume: 242.99787843430724,
      usdVolume: 2338722.617214002,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '1.7696000000000000',
      high: '1.8900000000000000',
      low: '1.7272000000000000',
      close: '1.8287000000000000',
      volume: '3042460.9700000000000000',
      quoteVolume: 5523573.6413650215,
      btcVolume: 553.7959499063911,
      usdVolume: 5523573.6413650215,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '1.8307000000000000',
      high: '1.9424000000000000',
      low: '1.8147000000000000',
      close: '1.8490000000000000',
      volume: '3198661.0900000000000000',
      quoteVolume: 5978206.5396679975,
      btcVolume: 570.2827065368367,
      usdVolume: 5978206.5396679975,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '1.8486000000000000',
      high: '1.8700000000000000',
      low: '1.7167000000000000',
      close: '1.7793000000000000',
      volume: '3545459.8000000000000000',
      quoteVolume: 6323373.737698996,
      btcVolume: 599.008568072105,
      usdVolume: 6323373.737698996,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '1.7784000000000000',
      high: '1.8624000000000000',
      low: '1.7680000000000000',
      close: '1.7921000000000000',
      volume: '2705662.3600000000000000',
      quoteVolume: 4910186.863704015,
      btcVolume: 465.71608792980777,
      usdVolume: 4910186.863704015,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '1.7919000000000000',
      high: '1.8600000000000000',
      low: '1.6767000000000000',
      close: '1.7226000000000000',
      volume: '3028097.8200000000000000',
      quoteVolume: 5355618.6108840015,
      btcVolume: 506.31943933656817,
      usdVolume: 5355618.6108840015,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '1.7222000000000000',
      high: '1.8060000000000000',
      low: '1.7085000000000000',
      close: '1.7672000000000000',
      volume: '2401823.2500000000000000',
      quoteVolume: 4215075.491124999,
      btcVolume: 404.7663920454655,
      usdVolume: 4215075.491124999,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '1.7672000000000000',
      high: '1.9054000000000000',
      low: '1.7610000000000000',
      close: '1.8275000000000000',
      volume: '3500287.6000000000000000',
      quoteVolume: 6422308.280667982,
      btcVolume: 617.3872473153666,
      usdVolume: 6422308.280667982,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '1.8290000000000000',
      high: '1.8731000000000000',
      low: '1.7610000000000000',
      close: '1.7872000000000000',
      volume: '3473067.9800000000000000',
      quoteVolume: 6305180.84346195,
      btcVolume: 613.2646136601918,
      usdVolume: 6305180.84346195,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '1.7883000000000000',
      high: '1.8326000000000000',
      low: '1.7730000000000000',
      close: '1.7998000000000000',
      volume: '2711918.0700000000000000',
      quoteVolume: 4888778.085218002,
      btcVolume: 478.77419074309563,
      usdVolume: 4888778.085218002,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '1.7988000000000000',
      high: '1.8258000000000000',
      low: '1.7136000000000000',
      close: '1.7301000000000000',
      volume: '2214016.1100000000000000',
      quoteVolume: 3890643.726558997,
      btcVolume: 386.83840067774776,
      usdVolume: 3890643.726558997,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '1.7301000000000000',
      high: '1.7458000000000000',
      low: '1.6918000000000000',
      close: '1.7073000000000000',
      volume: '2228942.3400000000000000',
      quoteVolume: 3823755.5435819686,
      btcVolume: 374.2899559647845,
      usdVolume: 3823755.5435819686,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '1.7073000000000000',
      high: '1.7099000000000000',
      low: '1.5100000000000000',
      close: '1.5759000000000000',
      volume: '6637630.0700000000000000',
      quoteVolume: 10595656.219862076,
      btcVolume: 1032.4407207665965,
      usdVolume: 10595656.219862076,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '1.5761000000000000',
      high: '1.6375000000000000',
      low: '1.5239000000000000',
      close: '1.5634000000000000',
      volume: '5678360.6100000000000000',
      quoteVolume: 9008346.159965044,
      btcVolume: 872.4429504635938,
      usdVolume: 9008346.159965044,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '1.5634000000000000',
      high: '1.6458000000000000',
      low: '1.5600000000000000',
      close: '1.6104000000000000',
      volume: '4049102.7700000000000000',
      quoteVolume: 6482781.439092984,
      btcVolume: 629.5046579926835,
      usdVolume: 6482781.439092984,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '1.6110000000000000',
      high: '1.6355000000000000',
      low: '1.5450000000000000',
      close: '1.5806000000000000',
      volume: '3545712.0300000000000000',
      quoteVolume: 5618393.775373046,
      btcVolume: 548.8843112989407,
      usdVolume: 5618393.775373046,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '1.5806000000000000',
      high: '1.6765000000000000',
      low: '1.5532000000000000',
      close: '1.6240000000000000',
      volume: '5390335.8700000000000000',
      quoteVolume: 8708882.286866039,
      btcVolume: 852.1328908855065,
      usdVolume: 8708882.286866039,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '1.6240000000000000',
      high: '1.8462000000000000',
      low: '1.6240000000000000',
      close: '1.8010000000000000',
      volume: '8267989.9400000000000000',
      quoteVolume: 14346677.761906926,
      btcVolume: 1407.7978038474118,
      usdVolume: 14346677.761906926,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '1.7994000000000000',
      high: '1.9075000000000000',
      low: '1.7079000000000000',
      close: '1.8744000000000000',
      volume: '8485812.8300000000000000',
      quoteVolume: 15522274.024547847,
      btcVolume: 1554.190947436255,
      usdVolume: 15522274.024547847,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '1.8743000000000000',
      high: '1.8772000000000000',
      low: '1.7510000000000000',
      close: '1.8245000000000000',
      volume: '3468435.2500000000000000',
      quoteVolume: 6287930.976908962,
      btcVolume: 618.0830858277567,
      usdVolume: 6287930.976908962,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '1.8215000000000000',
      high: '1.8888000000000000',
      low: '1.7881000000000000',
      close: '1.8855000000000000',
      volume: '2771763.9300000000000000',
      quoteVolume: 5102178.238018988,
      btcVolume: 508.0689834466635,
      usdVolume: 5102178.238018988,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '1.8848000000000000',
      high: '1.8868000000000000',
      low: '1.7711000000000000',
      close: '1.8157000000000000',
      volume: '2311848.6500000000000000',
      quoteVolume: 4207056.9643990025,
      btcVolume: 422.0307052601196,
      usdVolume: 4207056.9643990025,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '1.8149000000000000',
      high: '1.8800000000000000',
      low: '1.7799000000000000',
      close: '1.8031000000000000',
      volume: '2667120.7500000000000000',
      quoteVolume: 4892761.515483015,
      btcVolume: 497.2694919497504,
      usdVolume: 4892761.515483015,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '1.8048000000000000',
      high: '2.0657000000000000',
      low: '1.4612000000000000',
      close: '1.7160000000000000',
      volume: '15096447.2100000000000000',
      quoteVolume: 28169393.552325387,
      btcVolume: 3042.0172749110857,
      usdVolume: 28169393.552325387,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '1.7135000000000000',
      high: '1.9286000000000000',
      low: '1.6249000000000000',
      close: '1.7443000000000000',
      volume: '8910281.8800000000000000',
      quoteVolume: 15541684.445626967,
      btcVolume: 1835.8125331240349,
      usdVolume: 15541684.445626967,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '1.7476000000000000',
      high: '1.7584000000000000',
      low: '1.5276000000000000',
      close: '1.6512000000000000',
      volume: '6786874.8600000000000000',
      quoteVolume: 11209636.83260096,
      btcVolume: 1367.9713551044558,
      usdVolume: 11209636.83260096,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '1.6512000000000000',
      high: '1.6786000000000000',
      low: '1.5573000000000000',
      close: '1.6590000000000000',
      volume: '4333552.0600000000000000',
      quoteVolume: 6991710.784840995,
      btcVolume: 870.5039437524816,
      usdVolume: 6991710.784840995,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '1.6593000000000000',
      high: '1.7017000000000000',
      low: '1.6284000000000000',
      close: '1.6742000000000000',
      volume: '2326952.1300000000000000',
      quoteVolume: 3880224.7487799893,
      btcVolume: 476.88231743920215,
      usdVolume: 3880224.7487799893,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '1.6748000000000000',
      high: '1.7492000000000000',
      low: '1.6441000000000000',
      close: '1.7002000000000000',
      volume: '3304534.4900000000000000',
      quoteVolume: 5625241.514617002,
      btcVolume: 697.5333272708833,
      usdVolume: 5625241.514617002,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '1.7004000000000000',
      high: '1.7690000000000000',
      low: '1.6404000000000000',
      close: '1.7677000000000000',
      volume: '3564898.9100000000000000',
      quoteVolume: 6077384.966384979,
      btcVolume: 753.0627398007749,
      usdVolume: 6077384.966384979,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '1.7677000000000000',
      high: '1.8820000000000000',
      low: '1.7522000000000000',
      close: '1.8286000000000000',
      volume: '4077978.5200000000000000',
      quoteVolume: 7412545.756683013,
      btcVolume: 888.0318959948171,
      usdVolume: 7412545.756683013,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '1.8285000000000000',
      high: '2.0233000000000000',
      low: '1.8121000000000000',
      close: '2.0110000000000000',
      volume: '5789143.1000000000000000',
      quoteVolume: 11109499.143447043,
      btcVolume: 1347.6344778608,
      usdVolume: 11109499.143447043,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '2.0134000000000000',
      high: '2.0338000000000000',
      low: '1.8800000000000000',
      close: '1.9442000000000000',
      volume: '6996841.1000000000000000',
      quoteVolume: 13705258.538260838,
      btcVolume: 1670.6787632836763,
      usdVolume: 13705258.538260838,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '1.9443000000000000',
      high: '1.9949000000000000',
      low: '1.8880000000000000',
      close: '1.9663000000000000',
      volume: '3419972.1800000000000000',
      quoteVolume: 6612123.5559109915,
      btcVolume: 813.0573588319999,
      usdVolume: 6612123.5559109915,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '1.9663000000000000',
      high: '1.9775000000000000',
      low: '1.9113000000000000',
      close: '1.9624000000000000',
      volume: '2231607.1100000000000000',
      quoteVolume: 4348757.9730950035,
      btcVolume: 537.2042324566693,
      usdVolume: 4348757.9730950035,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '1.9624000000000000',
      high: '2.2659000000000000',
      low: '1.9531000000000000',
      close: '2.1669000000000000',
      volume: '9724532.2300000000000000',
      quoteVolume: 20653440.90051679,
      btcVolume: 2600.3765280923726,
      usdVolume: 20653440.90051679,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '2.1669000000000000',
      high: '2.4899000000000000',
      low: '2.0971000000000000',
      close: '2.3963000000000000',
      volume: '12793025.3500000000000000',
      quoteVolume: 29795334.186469693,
      btcVolume: 3681.5038062533695,
      usdVolume: 29795334.186469693,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '2.3963000000000000',
      high: '2.6500000000000000',
      low: '2.2680000000000000',
      close: '2.5766000000000000',
      volume: '16095226.0400000000000000',
      quoteVolume: 39937936.664554514,
      btcVolume: 4873.697508425804,
      usdVolume: 39937936.664554514,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '2.5765000000000000',
      high: '2.9500000000000000',
      low: '2.5010000000000000',
      close: '2.8011000000000000',
      volume: '21716501.4900000000000000',
      quoteVolume: 59715252.16293435,
      btcVolume: 7171.176638549378,
      usdVolume: 59715252.16293435,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '2.8011000000000000',
      high: '2.8058000000000000',
      low: '2.4650000000000000',
      close: '2.6302000000000000',
      volume: '15341662.5100000000000000',
      quoteVolume: 40233982.30970988,
      btcVolume: 4713.439912937979,
      usdVolume: 40233982.30970988,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '2.6280000000000000',
      high: '2.7936000000000000',
      low: '2.5619000000000000',
      close: '2.7481000000000000',
      volume: '11370701.0600000000000000',
      quoteVolume: 30661560.51973289,
      btcVolume: 3657.570932964459,
      usdVolume: 30661560.51973289,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '2.7443000000000000',
      high: '2.7572000000000000',
      low: '2.5519000000000000',
      close: '2.5975000000000000',
      volume: '6172891.7200000000000000',
      quoteVolume: 16310219.63231884,
      btcVolume: 1958.0872004698058,
      usdVolume: 16310219.63231884,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '2.5975000000000000',
      high: '2.6443000000000000',
      low: '2.4351000000000000',
      close: '2.4860000000000000',
      volume: '5530185.4400000000000000',
      quoteVolume: 14018335.45872208,
      btcVolume: 1683.0569562732167,
      usdVolume: 14018335.45872208,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '2.4850000000000000',
      high: '2.6741000000000000',
      low: '2.4527000000000000',
      close: '2.5324000000000000',
      volume: '5826032.3200000000000000',
      quoteVolume: 14927603.979734935,
      btcVolume: 1798.6897727563057,
      usdVolume: 14927603.979734935,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '2.5315000000000000',
      high: '2.5970000000000000',
      low: '2.3320000000000000',
      close: '2.3972000000000000',
      volume: '6184169.7700000000000000',
      quoteVolume: 15237510.027046982,
      btcVolume: 1851.108592609114,
      usdVolume: 15237510.027046982,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '2.3972000000000000',
      high: '2.4143000000000000',
      low: '2.1820000000000000',
      close: '2.3717000000000000',
      volume: '11839469.0800000000000000',
      quoteVolume: 27151346.82973899,
      btcVolume: 3372.7497076069235,
      usdVolume: 27151346.82973899,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '2.3736000000000000',
      high: '2.4900000000000000',
      low: '2.2740000000000000',
      close: '2.4124000000000000',
      volume: '10413491.6200000000000000',
      quoteVolume: 24976988.911125984,
      btcVolume: 3108.003201846181,
      usdVolume: 24976988.911125984,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '2.4190000000000000',
      high: '2.4999000000000000',
      low: '2.3164000000000000',
      close: '2.3546000000000000',
      volume: '7255303.2100000000000000',
      quoteVolume: 17379294.0806661,
      btcVolume: 2186.768617430999,
      usdVolume: 17379294.0806661,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '2.3521000000000000',
      high: '2.3910000000000000',
      low: '2.2914000000000000',
      close: '2.3521000000000000',
      volume: '4513432.3200000000000000',
      quoteVolume: 10575592.141082024,
      btcVolume: 1328.617937628987,
      usdVolume: 10575592.141082024,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '2.3513000000000000',
      high: '2.4510000000000000',
      low: '2.2780000000000000',
      close: '2.4444000000000000',
      volume: '4491279.5700000000000000',
      quoteVolume: 10590230.618070923,
      btcVolume: 1316.2113024406156,
      usdVolume: 10590230.618070923,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '2.4443000000000000',
      high: '2.6900000000000000',
      low: '2.4347000000000000',
      close: '2.6223000000000000',
      volume: '11336763.6800000000000000',
      quoteVolume: 29227623.981732704,
      btcVolume: 3558.2909764104465,
      usdVolume: 29227623.981732704,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '2.6216000000000000',
      high: '2.8178000000000000',
      low: '2.5619000000000000',
      close: '2.6074000000000000',
      volume: '11193488.7900000000000000',
      quoteVolume: 30334954.89091577,
      btcVolume: 3713.298114804292,
      usdVolume: 30334954.89091577,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '2.6073000000000000',
      high: '2.6840000000000000',
      low: '2.3717000000000000',
      close: '2.6055000000000000',
      volume: '13350403.7600000000000000',
      quoteVolume: 34058909.05652626,
      btcVolume: 4438.740203459202,
      usdVolume: 34058909.05652626,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '2.6071000000000000',
      high: '2.7748000000000000',
      low: '2.6020000000000000',
      close: '2.7200000000000000',
      volume: '9744122.3600000000000000',
      quoteVolume: 26188969.916833855,
      btcVolume: 3521.547093016349,
      usdVolume: 26188969.916833855,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '2.7201000000000000',
      high: '3.0000000000000000',
      low: '2.6165000000000000',
      close: '2.8360000000000000',
      volume: '12714070.6800000000000000',
      quoteVolume: 35489759.83948473,
      btcVolume: 4402.325745022558,
      usdVolume: 35489759.83948473,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '2.8370000000000000',
      high: '2.9977000000000000',
      low: '2.6640000000000000',
      close: '2.7348000000000000',
      volume: '12993334.8100000000000000',
      quoteVolume: 36586743.2039599,
      btcVolume: 3897.7642510202168,
      usdVolume: 36586743.2039599,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '2.7323000000000000',
      high: '2.7620000000000000',
      low: '2.6511000000000000',
      close: '2.6646000000000000',
      volume: '5966423.4400000000000000',
      quoteVolume: 16086672.751381027,
      btcVolume: 1713.5391674385778,
      usdVolume: 16086672.751381027,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '2.6707000000000000',
      high: '2.8859000000000000',
      low: '2.6600000000000000',
      close: '2.6611000000000000',
      volume: '7822708.6800000000000000',
      quoteVolume: 21672797.56783017,
      btcVolume: 2284.2347637633156,
      usdVolume: 21672797.56783017,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '2.6601000000000000',
      high: '2.7690000000000000',
      low: '2.6321000000000000',
      close: '2.6620000000000000',
      volume: '3504202.7100000000000000',
      quoteVolume: 9449017.04016303,
      btcVolume: 1008.1912647024507,
      usdVolume: 9449017.04016303,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '2.6610000000000000',
      high: '2.6768000000000000',
      low: '2.5107000000000000',
      close: '2.6093000000000000',
      volume: '3975481.9600000000000000',
      quoteVolume: 10291062.332419943,
      btcVolume: 1120.2450669570424,
      usdVolume: 10291062.332419943,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '2.6044000000000000',
      high: '2.7573000000000000',
      low: '2.5365000000000000',
      close: '2.7219000000000000',
      volume: '4135810.6300000000000000',
      quoteVolume: 10988316.188437952,
      btcVolume: 1197.8135631055834,
      usdVolume: 10988316.188437952,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '2.7233000000000000',
      high: '2.7952000000000000',
      low: '2.6428000000000000',
      close: '2.7091000000000000',
      volume: '3786813.4200000000000000',
      quoteVolume: 10249112.15861604,
      btcVolume: 1122.302010791027,
      usdVolume: 10249112.15861604,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '2.7092000000000000',
      high: '2.7399000000000000',
      low: '2.6828000000000000',
      close: '2.6973000000000000',
      volume: '1945361.3400000000000000',
      quoteVolume: 5267072.043627042,
      btcVolume: 568.6068601325862,
      usdVolume: 5267072.043627042,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '2.7015000000000000',
      high: '2.7104000000000000',
      low: '2.6202000000000000',
      close: '2.6605000000000000',
      volume: '1676684.8800000000000000',
      quoteVolume: 4468927.620704968,
      btcVolume: 485.58249494067894,
      usdVolume: 4468927.620704968,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '2.6594000000000000',
      high: '2.7300000000000000',
      low: '2.6267000000000000',
      close: '2.6746000000000000',
      volume: '2308453.4600000000000000',
      quoteVolume: 6191639.232816044,
      btcVolume: 667.6063736733863,
      usdVolume: 6191639.232816044,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '2.6746000000000000',
      high: '2.6947000000000000',
      low: '2.6322000000000000',
      close: '2.6746000000000000',
      volume: '1985221.7300000000000000',
      quoteVolume: 5293153.906610983,
      btcVolume: 566.6809866952036,
      usdVolume: 5293153.906610983,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '2.6743000000000000',
      high: '2.8200000000000000',
      low: '2.6479000000000000',
      close: '2.7448000000000000',
      volume: '3174248.6300000000000000',
      quoteVolume: 8633537.382419948,
      btcVolume: 926.8771591924037,
      usdVolume: 8633537.382419948,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '2.7446000000000000',
      high: '2.7549000000000000',
      low: '2.6022000000000000',
      close: '2.6757000000000000',
      volume: '3498719.3300000000000000',
      quoteVolume: 9378528.926173998,
      btcVolume: 1015.9056048703025,
      usdVolume: 9378528.926173998,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '2.6758000000000000',
      high: '2.7333000000000000',
      low: '2.6210000000000000',
      close: '2.7100000000000000',
      volume: '2921454.2700000000000000',
      quoteVolume: 7841818.426597041,
      btcVolume: 875.5145773375328,
      usdVolume: 7841818.426597041,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '2.7091000000000000',
      high: '2.8497000000000000',
      low: '2.7031000000000000',
      close: '2.8009000000000000',
      volume: '4656993.4400000000000000',
      quoteVolume: 12964192.966649953,
      btcVolume: 1471.6148137529362,
      usdVolume: 12964192.966649953,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '2.8008000000000000',
      high: '2.8370000000000000',
      low: '2.7362000000000000',
      close: '2.7770000000000000',
      volume: '2591145.5900000000000000',
      quoteVolume: 7226235.751716039,
      btcVolume: 811.2847092382342,
      usdVolume: 7226235.751716039,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '2.7773000000000000',
      high: '2.7777000000000000',
      low: '2.6655000000000000',
      close: '2.7440000000000000',
      volume: '2140491.0400000000000000',
      quoteVolume: 5824727.782756004,
      btcVolume: 663.0973900459279,
      usdVolume: 5824727.782756004,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '2.7438000000000000',
      high: '2.8733000000000000',
      low: '2.7311000000000000',
      close: '2.8152000000000000',
      volume: '3207309.4900000000000000',
      quoteVolume: 8980218.046382047,
      btcVolume: 1028.891814520804,
      usdVolume: 8980218.046382047,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '2.8177000000000000',
      high: '3.0894000000000000',
      low: '2.7851000000000000',
      close: '3.0200000000000000',
      volume: '6541016.7600000000000000',
      quoteVolume: 19407023.30134102,
      btcVolume: 2214.351922566352,
      usdVolume: 19407023.30134102,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '3.0189000000000000',
      high: '3.1600000000000000',
      low: '2.9666000000000000',
      close: '3.0391000000000000',
      volume: '6593839.9000000000000000',
      quoteVolume: 20356219.364202138,
      btcVolume: 2349.5884763957965,
      usdVolume: 20356219.364202138,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '3.0390000000000000',
      high: '3.0700000000000000',
      low: '2.7970000000000000',
      close: '3.0389000000000000',
      volume: '5616867.4900000000000000',
      quoteVolume: 16587870.154289966,
      btcVolume: 1939.8783909842598,
      usdVolume: 16587870.154289966,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '3.0372000000000000',
      high: '3.0435000000000000',
      low: '2.9115000000000000',
      close: '2.9894000000000000',
      volume: '1973143.8200000000000000',
      quoteVolume: 5875410.748616997,
      btcVolume: 693.1568795868579,
      usdVolume: 5875410.748616997,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '2.9893000000000000',
      high: '2.9894000000000000',
      low: '2.8644000000000000',
      close: '2.9100000000000000',
      volume: '1638152.2100000000000000',
      quoteVolume: 4776273.310892999,
      btcVolume: 560.9160031958835,
      usdVolume: 4776273.310892999,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '2.9100000000000000',
      high: '2.9610000000000000',
      low: '2.6977000000000000',
      close: '2.7885000000000000',
      volume: '3479282.4300000000000000',
      quoteVolume: 9852607.662228048,
      btcVolume: 1181.9947586571366,
      usdVolume: 9852607.662228048,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '2.7886000000000000',
      high: '2.8069000000000000',
      low: '2.5800000000000000',
      close: '2.7255000000000000',
      volume: '4538639.6800000000000000',
      quoteVolume: 12210133.545297062,
      btcVolume: 1503.1001831593057,
      usdVolume: 12210133.545297062,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '2.7279000000000000',
      high: '2.7957000000000000',
      low: '2.6300000000000000',
      close: '2.6982000000000000',
      volume: '3588766.3500000000000000',
      quoteVolume: 9788974.060772017,
      btcVolume: 1207.4464054339237,
      usdVolume: 9788974.060772017,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '2.6983000000000000',
      high: '2.7153000000000000',
      low: '2.4710000000000000',
      close: '2.5456000000000000',
      volume: '4528968.6200000000000000',
      quoteVolume: 11725649.253482027,
      btcVolume: 1506.7638418172246,
      usdVolume: 11725649.253482027,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '2.5456000000000000',
      high: '2.5952000000000000',
      low: '2.1000000000000000',
      close: '2.3466000000000000',
      volume: '10778947.2500000000000000',
      quoteVolume: 25122066.728304863,
      btcVolume: 3458.21602950047,
      usdVolume: 25122066.728304863,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '2.3434000000000000',
      high: '2.4621000000000000',
      low: '2.2604000000000000',
      close: '2.4266000000000000',
      volume: '4841617.1200000000000000',
      quoteVolume: 11394019.118919024,
      btcVolume: 1578.269819528385,
      usdVolume: 11394019.118919024,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '2.4272000000000000',
      high: '2.4533000000000000',
      low: '2.2000000000000000',
      close: '2.2216000000000000',
      volume: '4491218.3300000000000000',
      quoteVolume: 10364009.312878031,
      btcVolume: 1456.1232933078102,
      usdVolume: 10364009.312878031,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '2.2218000000000000',
      high: '2.3976000000000000',
      low: '2.0200000000000000',
      close: '2.2523000000000000',
      volume: '8151200.1200000000000000',
      quoteVolume: 18025202.51352003,
      btcVolume: 2602.121395130407,
      usdVolume: 18025202.51352003,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '2.2554000000000000',
      high: '2.3578000000000000',
      low: '2.1459000000000000',
      close: '2.2424000000000000',
      volume: '7541215.9200000000000000',
      quoteVolume: 16895075.120843165,
      btcVolume: 2370.226442420348,
      usdVolume: 16895075.120843165,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '2.2437000000000000',
      high: '2.2882000000000000',
      low: '2.1002000000000000',
      close: '2.2242000000000000',
      volume: '7867101.6900000000000000',
      quoteVolume: 17458921.123055957,
      btcVolume: 2407.2933436753665,
      usdVolume: 17458921.123055957,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '2.2216000000000000',
      high: '2.3248000000000000',
      low: '2.1711000000000000',
      close: '2.2702000000000000',
      volume: '4770270.0100000000000000',
      quoteVolume: 10676600.041403953,
      btcVolume: 1420.5539937358105,
      usdVolume: 10676600.041403953,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '2.2716000000000000',
      high: '2.3763000000000000',
      low: '2.2611000000000000',
      close: '2.3403000000000000',
      volume: '4132886.9400000000000000',
      quoteVolume: 9586717.671007054,
      btcVolume: 1262.8765759121409,
      usdVolume: 9586717.671007054,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '2.3403000000000000',
      high: '2.3437000000000000',
      low: '2.1943000000000000',
      close: '2.2373000000000000',
      volume: '2920487.5800000000000000',
      quoteVolume: 6606597.773422014,
      btcVolume: 864.7793427026156,
      usdVolume: 6606597.773422014,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '2.2360000000000000',
      high: '2.2385000000000000',
      low: '2.1274000000000000',
      close: '2.1638000000000000',
      volume: '3517483.4300000000000000',
      quoteVolume: 7624553.0653519705,
      btcVolume: 1036.6018203822432,
      usdVolume: 7624553.0653519705,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '2.1634000000000000',
      high: '2.1660000000000000',
      low: '2.0524000000000000',
      close: '2.0911000000000000',
      volume: '3648117.9700000000000000',
      quoteVolume: 7673977.60459799,
      btcVolume: 1052.6747095580927,
      usdVolume: 7673977.60459799,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '2.0913000000000000',
      high: '2.1891000000000000',
      low: '2.0580000000000000',
      close: '2.1222000000000000',
      volume: '3779094.5300000000000000',
      quoteVolume: 8035102.426474956,
      btcVolume: 1099.7458034935873,
      usdVolume: 8035102.426474956,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '2.1222000000000000',
      high: '2.1551000000000000',
      low: '2.0000000000000000',
      close: '2.0290000000000000',
      volume: '6418298.4400000000000000',
      quoteVolume: 13241702.282410966,
      btcVolume: 1821.4389081573547,
      usdVolume: 13241702.282410966,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '2.0282000000000000',
      high: '2.0740000000000000',
      low: '1.9583000000000000',
      close: '2.0068000000000000',
      volume: '5364919.9900000000000000',
      quoteVolume: 10777462.949475031,
      btcVolume: 1473.092445555551,
      usdVolume: 10777462.949475031,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '2.0059000000000000',
      high: '2.1042000000000000',
      low: '1.9685000000000000',
      close: '2.0943000000000000',
      volume: '3855061.0400000000000000',
      quoteVolume: 7874379.4179719705,
      btcVolume: 1063.7224341290423,
      usdVolume: 7874379.4179719705,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '2.0918000000000000',
      high: '2.1114000000000000',
      low: '2.0346000000000000',
      close: '2.0440000000000000',
      volume: '2405401.8200000000000000',
      quoteVolume: 4979102.437877952,
      btcVolume: 661.935259884129,
      usdVolume: 4979102.437877952,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '2.0402000000000000',
      high: '2.0850000000000000',
      low: '2.0012000000000000',
      close: '2.0528000000000000',
      volume: '2017582.9900000000000000',
      quoteVolume: 4126872.5763140195,
      btcVolume: 551.4016518898085,
      usdVolume: 4126872.5763140195,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '2.0520000000000000',
      high: '2.0985000000000000',
      low: '2.0151000000000000',
      close: '2.0984000000000000',
      volume: '2749805.9600000000000000',
      quoteVolume: 5635545.581415003,
      btcVolume: 756.3154499408689,
      usdVolume: 5635545.581415003,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '2.0983000000000000',
      high: '2.2862000000000000',
      low: '2.0611000000000000',
      close: '2.2657000000000000',
      volume: '10810913.6300000000000000',
      quoteVolume: 23748657.368107967,
      btcVolume: 3247.6560107514047,
      usdVolume: 23748657.368107967,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '2.2655000000000000',
      high: '2.3042000000000000',
      low: '2.1470000000000000',
      close: '2.1786000000000000',
      volume: '4814219.6800000000000000',
      quoteVolume: 10700085.694164913,
      btcVolume: 1482.272457471991,
      usdVolume: 10700085.694164913,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '2.1784000000000000',
      high: '2.1951000000000000',
      low: '2.0197000000000000',
      close: '2.1056000000000000',
      volume: '4784990.2800000000000000',
      quoteVolume: 10011804.878543014,
      btcVolume: 1397.2028682925654,
      usdVolume: 10011804.878543014,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '2.1037000000000000',
      high: '2.1210000000000000',
      low: '2.0575000000000000',
      close: '2.0709000000000000',
      volume: '1938856.4000000000000000',
      quoteVolume: 4050597.004999004,
      btcVolume: 560.2010274614569,
      usdVolume: 4050597.004999004,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '2.0713000000000000',
      high: '2.0933000000000000',
      low: '1.9700000000000000',
      close: '1.9946000000000000',
      volume: '1750170.3500000000000000',
      quoteVolume: 3553231.517961994,
      btcVolume: 497.52783996859756,
      usdVolume: 3553231.517961994,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '1.9947000000000000',
      high: '2.1168000000000000',
      low: '1.9606000000000000',
      close: '2.0708000000000000',
      volume: '2572245.4900000000000000',
      quoteVolume: 5290463.029340993,
      btcVolume: 744.7645762589991,
      usdVolume: 5290463.029340993,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '2.0722000000000000',
      high: '2.0873000000000000',
      low: '1.9670000000000000',
      close: '1.9751000000000000',
      volume: '2426967.3300000000000000',
      quoteVolume: 4880535.555653993,
      btcVolume: 697.783270194055,
      usdVolume: 4880535.555653993,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '1.9705000000000000',
      high: '2.0025000000000000',
      low: '1.7000000000000000',
      close: '1.7598000000000000',
      volume: '7314955.8200000000000000',
      quoteVolume: 13273928.880977929,
      btcVolume: 1967.0240939966445,
      usdVolume: 13273928.880977929,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '1.7594000000000000',
      high: '1.8995000000000000',
      low: '1.5996000000000000',
      close: '1.8703000000000000',
      volume: '8586632.6500000000000000',
      quoteVolume: 15238050.812313028,
      btcVolume: 2248.4745148935344,
      usdVolume: 15238050.812313028,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '1.8703000000000000',
      high: '1.8901000000000000',
      low: '1.7490000000000000',
      close: '1.8050000000000000',
      volume: '5044150.8900000000000000',
      quoteVolume: 9131480.463140937,
      btcVolume: 1276.1833159780792,
      usdVolume: 9131480.463140937,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '1.8050000000000000',
      high: '1.9238000000000000',
      low: '1.7849000000000000',
      close: '1.9182000000000000',
      volume: '3285479.3800000000000000',
      quoteVolume: 6091824.338370992,
      btcVolume: 851.739498901493,
      usdVolume: 6091824.338370992,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '1.9182000000000000',
      high: '1.9204000000000000',
      low: '1.8544000000000000',
      close: '1.8575000000000000',
      volume: '2246207.9200000000000000',
      quoteVolume: 4254205.469336003,
      btcVolume: 595.372491489374,
      usdVolume: 4254205.469336003,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '1.8563000000000000',
      high: '1.9400000000000000',
      low: '1.8530000000000000',
      close: '1.9181000000000000',
      volume: '2765867.8000000000000000',
      quoteVolume: 5280663.966873023,
      btcVolume: 724.5881359505457,
      usdVolume: 5280663.966873023,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '1.9167000000000000',
      high: '1.9532000000000000',
      low: '1.8460000000000000',
      close: '1.8629000000000000',
      volume: '2832932.7600000000000000',
      quoteVolume: 5427329.285269981,
      btcVolume: 724.3302026053892,
      usdVolume: 5427329.285269981,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '1.8638000000000000',
      high: '1.8854000000000000',
      low: '1.8154000000000000',
      close: '1.8318000000000000',
      volume: '2276138.1100000000000000',
      quoteVolume: 4206713.595653003,
      btcVolume: 575.7089237245987,
      usdVolume: 4206713.595653003,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '1.8434000000000000',
      high: '1.8462000000000000',
      low: '1.7600000000000000',
      close: '1.7826000000000000',
      volume: '1576134.1400000000000000',
      quoteVolume: 2842886.6440100074,
      btcVolume: 394.49536602127625,
      usdVolume: 2842886.6440100074,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '1.7836000000000000',
      high: '1.9604000000000000',
      low: '1.7702000000000000',
      close: '1.9460000000000000',
      volume: '2645538.2200000000000000',
      quoteVolume: 4914305.251431999,
      btcVolume: 675.865579877377,
      usdVolume: 4914305.251431999,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '1.8538000000000000',
      high: '1.9299000000000000',
      low: '1.8435000000000000',
      close: '1.8751000000000000',
      volume: '2413976.6800000000000000',
      quoteVolume: 4547744.520783,
      btcVolume: 630.9067581741584,
      usdVolume: 4547744.520783,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '1.8777000000000000',
      high: '1.8994000000000000',
      low: '1.8555000000000000',
      close: '1.8746000000000000',
      volume: '1269364.7000000000000000',
      quoteVolume: 2384012.458777003,
      btcVolume: 326.02528374878295,
      usdVolume: 2384012.458777003,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '1.8716000000000000',
      high: '1.9200000000000000',
      low: '1.8444000000000000',
      close: '1.8976000000000000',
      volume: '1411381.0500000000000000',
      quoteVolume: 2639536.610925001,
      btcVolume: 357.41364249988766,
      usdVolume: 2639536.610925001,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '1.8980000000000000',
      high: '1.8980000000000000',
      low: '1.8072000000000000',
      close: '1.8243000000000000',
      volume: '1099037.9900000000000000',
      quoteVolume: 2036388.7939540006,
      btcVolume: 278.66897959056115,
      usdVolume: 2036388.7939540006,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '1.8215000000000000',
      high: '1.8292000000000000',
      low: '1.7540000000000000',
      close: '1.7673000000000000',
      volume: '1637635.2800000000000000',
      quoteVolume: 2932476.277038998,
      btcVolume: 406.1371342093213,
      usdVolume: 2932476.277038998,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '1.7678000000000000',
      high: '1.8548000000000000',
      low: '1.7606000000000000',
      close: '1.8111000000000000',
      volume: '1158740.3200000000000000',
      quoteVolume: 2106240.113113006,
      btcVolume: 291.7435681061432,
      usdVolume: 2106240.113113006,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '1.8109000000000000',
      high: '1.8164000000000000',
      low: '1.7065000000000000',
      close: '1.7366000000000000',
      volume: '1346757.5400000000000000',
      quoteVolume: 2370258.0493380134,
      btcVolume: 336.50922976459,
      usdVolume: 2370258.0493380134,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '1.7366000000000000',
      high: '1.8292000000000000',
      low: '1.7013000000000000',
      close: '1.8040000000000000',
      volume: '1782059.3300000000000000',
      quoteVolume: 3173528.806004989,
      btcVolume: 440.5557916213236,
      usdVolume: 3173528.806004989,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '1.8030000000000000',
      high: '1.8446000000000000',
      low: '1.7840000000000000',
      close: '1.8242000000000000',
      volume: '1241322.8100000000000000',
      quoteVolume: 2252857.6365430057,
      btcVolume: 307.3651298252609,
      usdVolume: 2252857.6365430057,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '1.8243000000000000',
      high: '1.8413000000000000',
      low: '1.7850000000000000',
      close: '1.7957000000000000',
      volume: '1524603.4000000000000000',
      quoteVolume: 2771904.605593008,
      btcVolume: 372.70606476580207,
      usdVolume: 2771904.605593008,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '1.7954000000000000',
      high: '1.9386000000000000',
      low: '1.7925000000000000',
      close: '1.9374000000000000',
      volume: '3301230.5100000000000000',
      quoteVolume: 6219983.016896969,
      btcVolume: 822.3795323204727,
      usdVolume: 6219983.016896969,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '1.9373000000000000',
      high: '2.1398000000000000',
      low: '1.8993000000000000',
      close: '2.1033000000000000',
      volume: '6873503.3900000000000000',
      quoteVolume: 14001167.440691043,
      btcVolume: 1765.0859844694687,
      usdVolume: 14001167.440691043,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '2.1006000000000000',
      high: '2.3495000000000000',
      low: '2.1006000000000000',
      close: '2.1910000000000000',
      volume: '10645081.3000000000000000',
      quoteVolume: 23671432.343011014,
      btcVolume: 2869.5091074200345,
      usdVolume: 23671432.343011014,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '2.1842000000000000',
      high: '2.2344000000000000',
      low: '2.0977000000000000',
      close: '2.1886000000000000',
      volume: '4880978.6700000000000000',
      quoteVolume: 10564559.54597592,
      btcVolume: 1336.7758650978221,
      usdVolume: 10564559.54597592,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '2.1867000000000000',
      high: '2.2489000000000000',
      low: '2.1455000000000000',
      close: '2.2257000000000000',
      volume: '4235446.6200000000000000',
      quoteVolume: 9348313.457428945,
      btcVolume: 1187.4445809136419,
      usdVolume: 9348313.457428945,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '2.2255000000000000',
      high: '2.3450000000000000',
      low: '2.2180000000000000',
      close: '2.2734000000000000',
      volume: '4346405.0600000000000000',
      quoteVolume: 9936058.411543818,
      btcVolume: 1222.4125100446236,
      usdVolume: 9936058.411543818,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '2.2730000000000000',
      high: '2.3212000000000000',
      low: '2.2120000000000000',
      close: '2.2383000000000000',
      volume: '2499405.7900000000000000',
      quoteVolume: 5647655.962262982,
      btcVolume: 695.833466287728,
      usdVolume: 5647655.962262982,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '2.2382000000000000',
      high: '2.2382000000000000',
      low: '2.1561000000000000',
      close: '2.1901000000000000',
      volume: '1952305.6900000000000000',
      quoteVolume: 4261648.208497995,
      btcVolume: 525.0222154958136,
      usdVolume: 4261648.208497995,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '2.1904000000000000',
      high: '2.4701000000000000',
      low: '2.1792000000000000',
      close: '2.3751000000000000',
      volume: '7058803.5000000000000000',
      quoteVolume: 16487477.721062977,
      btcVolume: 1910.6120911212884,
      usdVolume: 16487477.721062977,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '2.3751000000000000',
      high: '2.5604000000000000',
      low: '2.3332000000000000',
      close: '2.3953000000000000',
      volume: '6388121.6800000000000000',
      quoteVolume: 15661576.690633962,
      btcVolume: 1788.326833421823,
      usdVolume: 15661576.690633962,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '2.3991000000000000',
      high: '2.5100000000000000',
      low: '2.2932000000000000',
      close: '2.4925000000000000',
      volume: '4937240.5300000000000000',
      quoteVolume: 11942842.296321075,
      btcVolume: 1374.9026425662325,
      usdVolume: 11942842.296321075,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '2.4924000000000000',
      high: '2.8600000000000000',
      low: '2.4589000000000000',
      close: '2.7006000000000000',
      volume: '12107831.2900000000000000',
      quoteVolume: 32609325.00067248,
      btcVolume: 3674.588483290462,
      usdVolume: 32609325.00067248,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '2.7006000000000000',
      high: '2.7946000000000000',
      low: '2.5800000000000000',
      close: '2.7709000000000000',
      volume: '4454822.8800000000000000',
      quoteVolume: 12057457.05895922,
      btcVolume: 1354.3595299323777,
      usdVolume: 12057457.05895922,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '2.7749000000000000',
      high: '2.8475000000000000',
      low: '2.5385000000000000',
      close: '2.6212000000000000',
      volume: '6634722.1700000000000000',
      quoteVolume: 17678886.450535074,
      btcVolume: 2012.0756253195111,
      usdVolume: 17678886.450535074,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '2.6207000000000000',
      high: '2.7443000000000000',
      low: '2.5425000000000000',
      close: '2.6880000000000000',
      volume: '4142689.5300000000000000',
      quoteVolume: 10966736.422782997,
      btcVolume: 1266.964503736621,
      usdVolume: 10966736.422782997,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '2.6881000000000000',
      high: '2.7440000000000000',
      low: '2.5700000000000000',
      close: '2.6716000000000000',
      volume: '3244654.0900000000000000',
      quoteVolume: 8677468.39766201,
      btcVolume: 1002.1855751352247,
      usdVolume: 8677468.39766201,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '2.6724000000000000',
      high: '2.6978000000000000',
      low: '2.6187000000000000',
      close: '2.6426000000000000',
      volume: '1637205.0200000000000000',
      quoteVolume: 4351550.913769996,
      btcVolume: 500.3133383092309,
      usdVolume: 4351550.913769996,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '2.6425000000000000',
      high: '2.6470000000000000',
      low: '2.4256000000000000',
      close: '2.4698000000000000',
      volume: '4806803.2100000000000000',
      quoteVolume: 11989939.300899168,
      btcVolume: 1413.7383885194763,
      usdVolume: 11989939.300899168,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '2.4695000000000000',
      high: '2.5330000000000000',
      low: '2.3638000000000000',
      close: '2.5024000000000000',
      volume: '4161651.9700000000000000',
      quoteVolume: 10232694.793375008,
      btcVolume: 1220.4855118581247,
      usdVolume: 10232694.793375008,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '2.5024000000000000',
      high: '2.5100000000000000',
      low: '2.4210000000000000',
      close: '2.4468000000000000',
      volume: '1881630.1800000000000000',
      quoteVolume: 4630337.163306008,
      btcVolume: 555.0830281021886,
      usdVolume: 4630337.163306008,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '2.4425000000000000',
      high: '2.5764000000000000',
      low: '2.4266000000000000',
      close: '2.5764000000000000',
      volume: '1936836.4700000000000000',
      quoteVolume: 4876802.3327080235,
      btcVolume: 574.1477799412621,
      usdVolume: 4876802.3327080235,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '2.5764000000000000',
      high: '2.6764000000000000',
      low: '2.5656000000000000',
      close: '2.6055000000000000',
      volume: '3426087.6500000000000000',
      quoteVolume: 8983048.130742965,
      btcVolume: 1025.861592856912,
      usdVolume: 8983048.130742965,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '2.6051000000000000',
      high: '2.6700000000000000',
      low: '2.5868000000000000',
      close: '2.6558000000000000',
      volume: '3113588.1700000000000000',
      quoteVolume: 8190994.708914968,
      btcVolume: 901.4969362742667,
      usdVolume: 8190994.708914968,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '2.6544000000000000',
      high: '2.8348000000000000',
      low: '2.6316000000000000',
      close: '2.7773000000000000',
      volume: '5191102.2600000000000000',
      quoteVolume: 14210741.277650112,
      btcVolume: 1519.9493554481853,
      usdVolume: 14210741.277650112,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '2.7785000000000000',
      high: '2.9482000000000000',
      low: '2.7167000000000000',
      close: '2.9156000000000000',
      volume: '5780750.0200000000000000',
      quoteVolume: 16529966.221006121,
      btcVolume: 1760.8623301714779,
      usdVolume: 16529966.221006121,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '2.9120000000000000',
      high: '2.9153000000000000',
      low: '2.7628000000000000',
      close: '2.8259000000000000',
      volume: '3910431.2100000000000000',
      quoteVolume: 11114615.65423999,
      btcVolume: 1189.571698179413,
      usdVolume: 11114615.65423999,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '2.8258000000000000',
      high: '2.8800000000000000',
      low: '2.7438000000000000',
      close: '2.8378000000000000',
      volume: '2737541.7700000000000000',
      quoteVolume: 7681755.743857064,
      btcVolume: 819.739572372717,
      usdVolume: 7681755.743857064,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '2.8372000000000000',
      high: '2.8869000000000000',
      low: '2.7314000000000000',
      close: '2.8267000000000000',
      volume: '3442889.5900000000000000',
      quoteVolume: 9768511.051874014,
      btcVolume: 1040.2867368472987,
      usdVolume: 9768511.051874014,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '2.8225000000000000',
      high: '2.8790000000000000',
      low: '2.7451000000000000',
      close: '2.7710000000000000',
      volume: '3106758.8600000000000000',
      quoteVolume: 8691569.65454806,
      btcVolume: 929.8882901515204,
      usdVolume: 8691569.65454806,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '2.7713000000000000',
      high: '2.8038000000000000',
      low: '2.6751000000000000',
      close: '2.7395000000000000',
      volume: '4491247.5800000000000000',
      quoteVolume: 12261241.924308985,
      btcVolume: 1328.2200602977618,
      usdVolume: 12261241.924308985,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '2.7397000000000000',
      high: '2.8845000000000000',
      low: '2.7261000000000000',
      close: '2.8390000000000000',
      volume: '4267689.2200000000000000',
      quoteVolume: 12053160.781550005,
      btcVolume: 1276.385904166067,
      usdVolume: 12053160.781550005,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '2.8360000000000000',
      high: '2.9436000000000000',
      low: '2.7900000000000000',
      close: '2.8900000000000000',
      volume: '4131243.5200000000000000',
      quoteVolume: 11910422.487068068,
      btcVolume: 1227.3627511910402,
      usdVolume: 11910422.487068068,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '2.8900000000000000',
      high: '3.3682000000000000',
      low: '2.8882000000000000',
      close: '3.3072000000000000',
      volume: '14813365.3600000000000000',
      quoteVolume: 47449282.522496335,
      btcVolume: 4846.042169836303,
      usdVolume: 47449282.522496335,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '3.3071000000000000',
      high: '3.4865000000000000',
      low: '3.1310000000000000',
      close: '3.4171000000000000',
      volume: '10059760.5500000000000000',
      quoteVolume: 33442719.414118227,
      btcVolume: 3409.4308561389403,
      usdVolume: 33442719.414118227,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '3.4179000000000000',
      high: '3.4984000000000000',
      low: '3.2330000000000000',
      close: '3.4945000000000000',
      volume: '6056067.2700000000000000',
      quoteVolume: 20336735.107043743,
      btcVolume: 2016.569351190022,
      usdVolume: 20336735.107043743,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '3.4927000000000000',
      high: '3.4972000000000000',
      low: '3.2806000000000000',
      close: '3.3862000000000000',
      volume: '6758374.3500000000000000',
      quoteVolume: 22873780.175180133,
      btcVolume: 2303.5567499558065,
      usdVolume: 22873780.175180133,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '3.3874000000000000',
      high: '3.9964000000000000',
      low: '3.3500000000000000',
      close: '3.9931000000000000',
      volume: '11780106.0700000000000000',
      quoteVolume: 43237892.69208104,
      btcVolume: 4336.995754427936,
      usdVolume: 43237892.69208104,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '3.9937000000000000',
      high: '4.2000000000000000',
      low: '3.8472000000000000',
      close: '3.9953000000000000',
      volume: '13673147.3100000000000000',
      quoteVolume: 55003441.64790517,
      btcVolume: 5332.8082573628735,
      usdVolume: 55003441.64790517,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '3.9930000000000000',
      high: '4.1147000000000000',
      low: '3.7123000000000000',
      close: '3.8765000000000000',
      volume: '8191206.1300000000000000',
      quoteVolume: 32257042.692259546,
      btcVolume: 3140.1527250738345,
      usdVolume: 32257042.692259546,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '3.8753000000000000',
      high: '4.4870000000000000',
      low: '3.7873000000000000',
      close: '4.4260000000000000',
      volume: '10369344.9500000000000000',
      quoteVolume: 43468717.18347415,
      btcVolume: 4231.988614585208,
      usdVolume: 43468717.18347415,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '4.4262000000000000',
      high: '4.6210000000000000',
      low: '4.0650000000000000',
      close: '4.3241000000000000',
      volume: '15573115.1200000000000000',
      quoteVolume: 68355374.03477696,
      btcVolume: 6799.603705176355,
      usdVolume: 68355374.03477696,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '4.3215000000000000',
      high: '4.8671000000000000',
      low: '4.0384000000000000',
      close: '4.4566000000000000',
      volume: '22171359.6900000000000000',
      quoteVolume: 99672983.28316268,
      btcVolume: 10084.607252387823,
      usdVolume: 99672983.28316268,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '4.4563000000000000',
      high: '4.5522000000000000',
      low: '3.8449000000000000',
      close: '4.3108000000000000',
      volume: '19343142.2500000000000000',
      quoteVolume: 80859272.12602058,
      btcVolume: 8343.69697549989,
      usdVolume: 80859272.12602058,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '4.3059000000000000',
      high: '4.6360000000000000',
      low: '4.1416000000000000',
      close: '4.5523000000000000',
      volume: '11931206.5700000000000000',
      quoteVolume: 52870430.99099803,
      btcVolume: 5385.401313218787,
      usdVolume: 52870430.99099803,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '4.5539000000000000',
      high: '4.8355000000000000',
      low: '4.1930000000000000',
      close: '4.3873000000000000',
      volume: '10055681.7000000000000000',
      quoteVolume: 46162309.631008886,
      btcVolume: 4610.077023153315,
      usdVolume: 46162309.631008886,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '4.3840000000000000',
      high: '4.4865000000000000',
      low: '4.0673000000000000',
      close: '4.2644000000000000',
      volume: '11463128.1100000000000000',
      quoteVolume: 48921964.38781903,
      btcVolume: 5107.005216621635,
      usdVolume: 48921964.38781903,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '4.2666000000000000',
      high: '4.5127000000000000',
      low: '4.1840000000000000',
      close: '4.2778000000000000',
      volume: '7706651.0700000000000000',
      quoteVolume: 33506855.339449476,
      btcVolume: 3462.076686554174,
      usdVolume: 33506855.339449476,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '4.2789000000000000',
      high: '4.3500000000000000',
      low: '4.0332000000000000',
      close: '4.1066000000000000',
      volume: '6716454.0200000000000000',
      quoteVolume: 27976312.64533768,
      btcVolume: 2902.199389115639,
      usdVolume: 27976312.64533768,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '4.1006000000000000',
      high: '4.3768000000000000',
      low: '4.0693000000000000',
      close: '4.2365000000000000',
      volume: '5642974.2100000000000000',
      quoteVolume: 23992021.76366039,
      btcVolume: 2434.114086850125,
      usdVolume: 23992021.76366039,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '4.2366000000000000',
      high: '4.2871000000000000',
      low: '3.7916000000000000',
      close: '3.9148000000000000',
      volume: '8591286.0700000000000000',
      quoteVolume: 34393400.24605992,
      btcVolume: 3548.267172458539,
      usdVolume: 34393400.24605992,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '3.9179000000000000',
      high: '3.9600000000000000',
      low: '3.4864000000000000',
      close: '3.4905000000000000',
      volume: '12394531.5200000000000000',
      quoteVolume: 45560015.71143371,
      btcVolume: 4810.153110343996,
      usdVolume: 45560015.71143371,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '3.4874000000000000',
      high: '3.8587000000000000',
      low: '3.3113000000000000',
      close: '3.5648000000000000',
      volume: '22177330.0200000000000000',
      quoteVolume: 79010843.38455322,
      btcVolume: 8784.642820650577,
      usdVolume: 79010843.38455322,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '3.5648000000000000',
      high: '4.1320000000000000',
      low: '3.4197000000000000',
      close: '3.9579000000000000',
      volume: '19080853.8700000000000000',
      quoteVolume: 73041636.71234092,
      btcVolume: 8290.177225043033,
      usdVolume: 73041636.71234092,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '3.9568000000000000',
      high: '4.2395000000000000',
      low: '3.7500000000000000',
      close: '4.1609000000000000',
      volume: '18251889.1100000000000000',
      quoteVolume: 72768936.34054644,
      btcVolume: 8381.737217549813,
      usdVolume: 72768936.34054644,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '4.1614000000000000',
      high: '4.3535000000000000',
      low: '4.0572000000000000',
      close: '4.0579000000000000',
      volume: '12433586.3300000000000000',
      quoteVolume: 52447455.00483184,
      btcVolume: 6050.453008344912,
      usdVolume: 52447455.00483184,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '4.0600000000000000',
      high: '4.2385000000000000',
      low: '3.7607000000000000',
      close: '3.8590000000000000',
      volume: '14748523.0700000000000000',
      quoteVolume: 58869388.27488089,
      btcVolume: 6880.090250435741,
      usdVolume: 58869388.27488089,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '3.8622000000000000',
      high: '4.3435000000000000',
      low: '3.8173000000000000',
      close: '4.2423000000000000',
      volume: '12656156.0500000000000000',
      quoteVolume: 52493228.92320553,
      btcVolume: 5994.207586648094,
      usdVolume: 52493228.92320553,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '4.2421000000000000',
      high: '4.6800000000000000',
      low: '4.1249000000000000',
      close: '4.5846000000000000',
      volume: '17189323.0000000000000000',
      quoteVolume: 75145836.87198897,
      btcVolume: 8560.826818400581,
      usdVolume: 75145836.87198897,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '4.5873000000000000',
      high: '4.9762000000000000',
      low: '4.5333000000000000',
      close: '4.6406000000000000',
      volume: '18623299.5100000000000000',
      quoteVolume: 88271977.43112102,
      btcVolume: 10087.310040122788,
      usdVolume: 88271977.43112102,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '4.6388000000000000',
      high: '4.8990000000000000',
      low: '4.6239000000000000',
      close: '4.7151000000000000',
      volume: '11845630.6300000000000000',
      quoteVolume: 56401786.5002544,
      btcVolume: 6263.948735289506,
      usdVolume: 56401786.5002544,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '4.7144000000000000',
      high: '4.7800000000000000',
      low: '4.5429000000000000',
      close: '4.6814000000000000',
      volume: '7645180.7400000000000000',
      quoteVolume: 35490114.51378227,
      btcVolume: 3908.2548527927784,
      usdVolume: 35490114.51378227,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '4.6813000000000000',
      high: '4.6863000000000000',
      low: '4.3143000000000000',
      close: '4.3412000000000000',
      volume: '8209506.8500000000000000',
      quoteVolume: 37077348.43982905,
      btcVolume: 4113.020563862099,
      usdVolume: 37077348.43982905,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '4.3468000000000000',
      high: '4.4646000000000000',
      low: '3.8500000000000000',
      close: '4.0537000000000000',
      volume: '18324667.2600000000000000',
      quoteVolume: 76704000.26084687,
      btcVolume: 9085.343101448982,
      usdVolume: 76704000.26084687,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '4.0537000000000000',
      high: '4.3690000000000000',
      low: '3.6012000000000000',
      close: '4.1231000000000000',
      volume: '29042929.6300000000000000',
      quoteVolume: 116996235.58194844,
      btcVolume: 14910.78468436395,
      usdVolume: 116996235.58194844,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '4.1237000000000000',
      high: '4.2119000000000000',
      low: '3.9132000000000000',
      close: '4.0839000000000000',
      volume: '18071323.5600000000000000',
      quoteVolume: 73423955.05481032,
      btcVolume: 9232.548450091543,
      usdVolume: 73423955.05481032,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '4.0842000000000000',
      high: '4.1100000000000000',
      low: '3.5514000000000000',
      close: '3.8169000000000000',
      volume: '19407327.4000000000000000',
      quoteVolume: 74033713.87227552,
      btcVolume: 9483.288672404144,
      usdVolume: 74033713.87227552,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '3.8170000000000000',
      high: '3.8519000000000000',
      low: '0.0001000000000000',
      close: '1.9931000000000000',
      volume: '67738097.2700000000000000',
      quoteVolume: 177673762.49713,
      btcVolume: 27866.237147761774,
      usdVolume: 177673762.49713,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '1.9990000000000000',
      high: '2.7520000000000000',
      low: '1.3600000000000000',
      close: '2.4333000000000000',
      volume: '69599335.5100000000000000',
      quoteVolume: 150523398.38686204,
      btcVolume: 29105.657242605583,
      usdVolume: 150523398.38686204,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '2.4328000000000000',
      high: '2.5141000000000000',
      low: '2.0607000000000000',
      close: '2.1379000000000000',
      volume: '21569351.2700000000000000',
      quoteVolume: 49487604.75409475,
      btcVolume: 9228.82980590662,
      usdVolume: 49487604.75409475,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '2.1401000000000000',
      high: '2.4000000000000000',
      low: '2.0150000000000000',
      close: '2.0884000000000000',
      volume: '24056556.5000000000000000',
      quoteVolume: 51979784.30551739,
      btcVolume: 9676.317809668104,
      usdVolume: 51979784.30551739,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '2.0868000000000000',
      high: '2.0898000000000000',
      low: '1.5571000000000000',
      close: '1.7832000000000000',
      volume: '47175196.2700000000000000',
      quoteVolume: 83730824.5977933,
      btcVolume: 16985.606488035726,
      usdVolume: 83730824.5977933,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '1.7832000000000000',
      high: '2.0690000000000000',
      low: '1.7300000000000000',
      close: '1.8641000000000000',
      volume: '32839078.6800000000000000',
      quoteVolume: 63607268.71138073,
      btcVolume: 12035.406899066662,
      usdVolume: 63607268.71138073,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '1.8660000000000000',
      high: '1.9543000000000000',
      low: '1.7620000000000000',
      close: '1.9483000000000000',
      volume: '22298409.7900000000000000',
      quoteVolume: 41588758.50010696,
      btcVolume: 7903.516934502976,
      usdVolume: 41588758.50010696,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '1.9497000000000000',
      high: '2.3700000000000000',
      low: '1.8841000000000000',
      close: '2.2398000000000000',
      volume: '33319846.3700000000000000',
      quoteVolume: 71956024.81635506,
      btcVolume: 12300.451552067118,
      usdVolume: 71956024.81635506,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '2.2409000000000000',
      high: '2.6249000000000000',
      low: '2.0143000000000000',
      close: '2.2600000000000000',
      volume: '39390658.8700000000000000',
      quoteVolume: 92950604.2035976,
      btcVolume: 14562.188219917904,
      usdVolume: 92950604.2035976,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '2.2631000000000000',
      high: '2.3750000000000000',
      low: '2.1050000000000000',
      close: '2.2787000000000000',
      volume: '19471583.8400000000000000',
      quoteVolume: 43519402.05460915,
      btcVolume: 7045.261058540346,
      usdVolume: 43519402.05460915,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '2.2754000000000000',
      high: '2.3300000000000000',
      low: '1.9334000000000000',
      close: '1.9898000000000000',
      volume: '21634031.6900000000000000',
      quoteVolume: 45651954.71691129,
      btcVolume: 7551.212589732256,
      usdVolume: 45651954.71691129,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '1.9889000000000000',
      high: '2.3000000000000000',
      low: '1.9352000000000000',
      close: '2.2468000000000000',
      volume: '23672246.4900000000000000',
      quoteVolume: 50076329.17784201,
      btcVolume: 8112.687771095752,
      usdVolume: 50076329.17784201,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '2.2470000000000000',
      high: '2.3709000000000000',
      low: '2.1950000000000000',
      close: '2.3104000000000000',
      volume: '18400021.3200000000000000',
      quoteVolume: 42125779.98805816,
      btcVolume: 6347.98486825512,
      usdVolume: 42125779.98805816,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '2.3109000000000000',
      high: '2.3500000000000000',
      low: '2.1804000000000000',
      close: '2.2570000000000000',
      volume: '11321064.3000000000000000',
      quoteVolume: 25603393.54787736,
      btcVolume: 3847.290256854321,
      usdVolume: 25603393.54787736,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '2.2569000000000000',
      high: '2.3132000000000000',
      low: '2.1884000000000000',
      close: '2.3132000000000000',
      volume: '6803251.3800000000000000',
      quoteVolume: 15346687.775324736,
      btcVolume: 2303.7934993235885,
      usdVolume: 15346687.775324736,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '2.3125000000000000',
      high: '2.3599000000000000',
      low: '2.1055000000000000',
      close: '2.1373000000000000',
      volume: '8151561.2000000000000000',
      quoteVolume: 18458235.87850203,
      btcVolume: 2776.0706097775064,
      usdVolume: 18458235.87850203,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '2.1372000000000000',
      high: '2.1544000000000000',
      low: '2.0372000000000000',
      close: '2.1364000000000000',
      volume: '11036739.0800000000000000',
      quoteVolume: 23162230.482438084,
      btcVolume: 3726.428487730779,
      usdVolume: 23162230.482438084,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '2.1356000000000000',
      high: '2.1520000000000000',
      low: '2.0070000000000000',
      close: '2.0083000000000000',
      volume: '6879303.8400000000000000',
      quoteVolume: 14309647.633855054,
      btcVolume: 2356.8649141943242,
      usdVolume: 14309647.633855054,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '2.0083000000000000',
      high: '2.2000000000000000',
      low: '2.0006000000000000',
      close: '2.1350000000000000',
      volume: '7970226.4900000000000000',
      quoteVolume: 16914779.735236812,
      btcVolume: 2698.6373186550286,
      usdVolume: 16914779.735236812,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '2.1348000000000000',
      high: '2.3020000000000000',
      low: '2.1102000000000000',
      close: '2.2658000000000000',
      volume: '8585088.2100000000000000',
      quoteVolume: 18841402.98135908,
      btcVolume: 2922.5256630907675,
      usdVolume: 18841402.98135908,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '2.2657000000000000',
      high: '2.2999000000000000',
      low: '2.1400000000000000',
      close: '2.2753000000000000',
      volume: '13239732.4400000000000000',
      quoteVolume: 29469066.54972005,
      btcVolume: 4669.983786159829,
      usdVolume: 29469066.54972005,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '2.2764000000000000',
      high: '2.4850000000000000',
      low: '2.2566000000000000',
      close: '2.2941000000000000',
      volume: '13266669.3900000000000000',
      quoteVolume: 30798141.032473035,
      btcVolume: 4540.6089277905485,
      usdVolume: 30798141.032473035,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '2.2940000000000000',
      high: '2.3837000000000000',
      low: '2.2652000000000000',
      close: '2.3013000000000000',
      volume: '8452189.4500000000000000',
      quoteVolume: 19628894.276646227,
      btcVolume: 2880.3710594063255,
      usdVolume: 19628894.276646227,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '2.2995000000000000',
      high: '2.3423000000000000',
      low: '2.2545000000000000',
      close: '2.3155000000000000',
      volume: '6117486.0100000000000000',
      quoteVolume: 14001543.18994617,
      btcVolume: 2067.6146389867217,
      usdVolume: 14001543.18994617,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '2.3155000000000000',
      high: '2.3219000000000000',
      low: '2.2437000000000000',
      close: '2.2635000000000000',
      volume: '3228659.2300000000000000',
      quoteVolume: 7361336.519118009,
      btcVolume: 1085.637636025333,
      usdVolume: 7361336.519118009,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '2.2638000000000000',
      high: '2.5677000000000000',
      low: '2.2621000000000000',
      close: '2.5555000000000000',
      volume: '12222925.8100000000000000',
      quoteVolume: 29594871.538146637,
      btcVolume: 4150.693258507703,
      usdVolume: 29594871.538146637,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '2.5556000000000000',
      high: '2.9080000000000000',
      low: '2.5550000000000000',
      close: '2.7303000000000000',
      volume: '30354976.5100000000000000',
      quoteVolume: 83149479.68281008,
      btcVolume: 11365.108305697015,
      usdVolume: 83149479.68281008,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '2.7295000000000000',
      high: '3.2748000000000000',
      low: '2.7124000000000000',
      close: '3.1693000000000000',
      volume: '27092993.2400000000000000',
      quoteVolume: 80619866.26421164,
      btcVolume: 11021.04111756064,
      usdVolume: 80619866.26421164,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '3.1695000000000000',
      high: '3.4741000000000000',
      low: '3.1224000000000000',
      close: '3.3099000000000000',
      volume: '36999763.5100000000000000',
      quoteVolume: 122751896.42489386,
      btcVolume: 16839.37173441571,
      usdVolume: 122751896.42489386,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '3.3099000000000000',
      high: '3.3199000000000000',
      low: '2.9450000000000000',
      close: '3.2200000000000000',
      volume: '43621490.6000000000000000',
      quoteVolume: 136026639.49936587,
      btcVolume: 19554.197753884662,
      usdVolume: 136026639.49936587,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '3.2207000000000000',
      high: '3.4364000000000000',
      low: '3.0629000000000000',
      close: '3.2900000000000000',
      volume: '30471997.5000000000000000',
      quoteVolume: 99435616.49901348,
      btcVolume: 14491.434066489905,
      usdVolume: 99435616.49901348,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '3.2912000000000000',
      high: '3.6412000000000000',
      low: '3.1670000000000000',
      close: '3.4189000000000000',
      volume: '32589266.7900000000000000',
      quoteVolume: 111420529.95980085,
      btcVolume: 15946.446334706492,
      usdVolume: 111420529.95980085,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '3.4117000000000000',
      high: '3.4602000000000000',
      low: '3.2000000000000000',
      close: '3.3702000000000000',
      volume: '29488744.4800000000000000',
      quoteVolume: 99093977.11353326,
      btcVolume: 14706.279591907658,
      usdVolume: 99093977.11353326,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '3.3719000000000000',
      high: '3.4437000000000000',
      low: '3.2100000000000000',
      close: '3.2520000000000000',
      volume: '17078827.0100000000000000',
      quoteVolume: 57101788.37653568,
      btcVolume: 8313.477053450417,
      usdVolume: 57101788.37653568,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '3.2517000000000000',
      high: '3.3061000000000000',
      low: '3.1085000000000000',
      close: '3.1212000000000000',
      volume: '15635873.1100000000000000',
      quoteVolume: 50161624.541744925,
      btcVolume: 7410.73145926265,
      usdVolume: 50161624.541744925,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '3.1212000000000000',
      high: '3.5555000000000000',
      low: '2.9761000000000000',
      close: '3.4851000000000000',
      volume: '24817961.6500000000000000',
      quoteVolume: 81880780.97622721,
      btcVolume: 11818.013830683783,
      usdVolume: 81880780.97622721,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '3.4831000000000000',
      high: '3.5250000000000000',
      low: '3.3778000000000000',
      close: '3.4523000000000000',
      volume: '12669277.2500000000000000',
      quoteVolume: 43776483.22340796,
      btcVolume: 6193.887862135089,
      usdVolume: 43776483.22340796,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '3.4525000000000000',
      high: '3.8300000000000000',
      low: '3.4470000000000000',
      close: '3.7805000000000000',
      volume: '16363948.9700000000000000',
      quoteVolume: 59614221.39959253,
      btcVolume: 8310.66795040822,
      usdVolume: 59614221.39959253,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '3.7814000000000000',
      high: '3.8102000000000000',
      low: '3.5131000000000000',
      close: '3.5902000000000000',
      volume: '16350496.0200000000000000',
      quoteVolume: 59872603.95570334,
      btcVolume: 8367.38354342493,
      usdVolume: 59872603.95570334,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '3.5885000000000000',
      high: '3.7920000000000000',
      low: '3.3720000000000000',
      close: '3.4322000000000000',
      volume: '20550987.6400000000000000',
      quoteVolume: 73812197.67135006,
      btcVolume: 10555.803904890008,
      usdVolume: 73812197.67135006,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '3.4317000000000000',
      high: '3.5719000000000000',
      low: '3.3903000000000000',
      close: '3.4577000000000000',
      volume: '13684724.9900000000000000',
      quoteVolume: 47685948.13373961,
      btcVolume: 6958.109221813893,
      usdVolume: 47685948.13373961,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '3.4593000000000000',
      high: '3.6904000000000000',
      low: '3.4327000000000000',
      close: '3.6786000000000000',
      volume: '11762633.8300000000000000',
      quoteVolume: 42349673.69567189,
      btcVolume: 6054.788459746665,
      usdVolume: 42349673.69567189,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '3.6787000000000000',
      high: '3.9101000000000000',
      low: '3.5968000000000000',
      close: '3.7533000000000000',
      volume: '14126638.7900000000000000',
      quoteVolume: 52991673.114868954,
      btcVolume: 7220.341851272833,
      usdVolume: 52991673.114868954,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '3.7551000000000000',
      high: '3.8874000000000000',
      low: '3.7364000000000000',
      close: '3.7735000000000000',
      volume: '7933872.6600000000000000',
      quoteVolume: 30294109.77665413,
      btcVolume: 4032.0422494074355,
      usdVolume: 30294109.77665413,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '3.7735000000000000',
      high: '3.9062000000000000',
      low: '3.7420000000000000',
      close: '3.7865000000000000',
      volume: '6095069.3800000000000000',
      quoteVolume: 23261065.041254092,
      btcVolume: 3081.0533832217207,
      usdVolume: 23261065.041254092,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '3.7865000000000000',
      high: '3.8160000000000000',
      low: '3.6432000000000000',
      close: '3.7210000000000000',
      volume: '8246658.1300000000000000',
      quoteVolume: 30808734.632196084,
      btcVolume: 4057.4729534536946,
      usdVolume: 30808734.632196084,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '3.7212000000000000',
      high: '3.7385000000000000',
      low: '3.4621000000000000',
      close: '3.6383000000000000',
      volume: '11371929.8600000000000000',
      quoteVolume: 40891564.50235291,
      btcVolume: 5313.060933220702,
      usdVolume: 40891564.50235291,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '3.6373000000000000',
      high: '3.7354000000000000',
      low: '3.5554000000000000',
      close: '3.6413000000000000',
      volume: '7379317.2300000000000000',
      quoteVolume: 26928714.47205683,
      btcVolume: 3488.09599283931,
      usdVolume: 26928714.47205683,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '3.6422000000000000',
      high: '3.9918000000000000',
      low: '3.6300000000000000',
      close: '3.8879000000000000',
      volume: '15125283.5400000000000000',
      quoteVolume: 57370181.28630435,
      btcVolume: 6832.774464426143,
      usdVolume: 57370181.28630435,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '3.8881000000000000',
      high: '3.9741000000000000',
      low: '3.6200000000000000',
      close: '3.7025000000000000',
      volume: '13545136.0200000000000000',
      quoteVolume: 51376800.29429678,
      btcVolume: 5766.507587671276,
      usdVolume: 51376800.29429678,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '3.7014000000000000',
      high: '3.9162000000000000',
      low: '3.7003000000000000',
      close: '3.7571000000000000',
      volume: '8084328.3600000000000000',
      quoteVolume: 30928293.222303115,
      btcVolume: 3519.057180538519,
      usdVolume: 30928293.222303115,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '3.7571000000000000',
      high: '3.8600000000000000',
      low: '3.7300000000000000',
      close: '3.8182000000000000',
      volume: '4467208.0100000000000000',
      quoteVolume: 16996396.923116993,
      btcVolume: 1915.6064803541883,
      usdVolume: 16996396.923116993,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '3.8186000000000000',
      high: '3.8600000000000000',
      low: '3.6379000000000000',
      close: '3.7092000000000000',
      volume: '5554731.5200000000000000',
      quoteVolume: 20831570.33686512,
      btcVolume: 2325.320369499448,
      usdVolume: 20831570.33686512,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '3.7098000000000000',
      high: '3.8272000000000000',
      low: '3.5033000000000000',
      close: '3.7499000000000000',
      volume: '10973722.2700000000000000',
      quoteVolume: 40395738.452246346,
      btcVolume: 4611.440780167115,
      usdVolume: 40395738.452246346,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '3.7496000000000000',
      high: '3.8140000000000000',
      low: '3.6540000000000000',
      close: '3.6833000000000000',
      volume: '4730504.5800000000000000',
      quoteVolume: 17621530.537070103,
      btcVolume: 1975.2075137716056,
      usdVolume: 17621530.537070103,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '3.6820000000000000',
      high: '3.7654000000000000',
      low: '3.5700000000000000',
      close: '3.5872000000000000',
      volume: '5299802.5900000000000000',
      quoteVolume: 19570134.054998033,
      btcVolume: 2138.6881860809644,
      usdVolume: 19570134.054998033,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '3.5874000000000000',
      high: '3.8500000000000000',
      low: '3.5633000000000000',
      close: '3.7560000000000000',
      volume: '8481271.5900000000000000',
      quoteVolume: 31361055.942226164,
      btcVolume: 3257.382561578943,
      usdVolume: 31361055.942226164,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '3.7580000000000000',
      high: '3.8590000000000000',
      low: '3.6874000000000000',
      close: '3.8010000000000000',
      volume: '7383724.4800000000000000',
      quoteVolume: 27986549.846176166,
      btcVolume: 2821.090111412898,
      usdVolume: 27986549.846176166,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '3.8001000000000000',
      high: '4.2129000000000000',
      low: '3.7601000000000000',
      close: '4.0150000000000000',
      volume: '15240721.6300000000000000',
      quoteVolume: 61673011.98541812,
      btcVolume: 6356.310945841886,
      usdVolume: 61673011.98541812,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '4.0149000000000000',
      high: '4.0197000000000000',
      low: '3.4602000000000000',
      close: '3.7582000000000000',
      volume: '19818388.7300000000000000',
      quoteVolume: 73819362.15594393,
      btcVolume: 8521.796803966217,
      usdVolume: 73819362.15594393,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '3.7574000000000000',
      high: '3.8320000000000000',
      low: '3.2865000000000000',
      close: '3.5837000000000000',
      volume: '13273873.6200000000000000',
      quoteVolume: 47852980.089774795,
      btcVolume: 5547.514411645623,
      usdVolume: 47852980.089774795,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '3.5838000000000000',
      high: '3.7932000000000000',
      low: '3.5400000000000000',
      close: '3.6943000000000000',
      volume: '7797486.4300000000000000',
      quoteVolume: 28881156.065596662,
      btcVolume: 3297.963204756944,
      usdVolume: 28881156.065596662,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '3.6938000000000000',
      high: '3.8150000000000000',
      low: '3.6366000000000000',
      close: '3.7655000000000000',
      volume: '5949203.5300000000000000',
      quoteVolume: 22154224.568957023,
      btcVolume: 2444.9070553685347,
      usdVolume: 22154224.568957023,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '3.7654000000000000',
      high: '3.8583000000000000',
      low: '3.6947000000000000',
      close: '3.8096000000000000',
      volume: '7333655.8100000000000000',
      quoteVolume: 27791822.366877846,
      btcVolume: 2891.52904194716,
      usdVolume: 27791822.366877846,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '3.8093000000000000',
      high: '3.8337000000000000',
      low: '3.6144000000000000',
      close: '3.6615000000000000',
      volume: '5571782.8800000000000000',
      quoteVolume: 20831712.993012883,
      btcVolume: 2197.771365437242,
      usdVolume: 20831712.993012883,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '3.6626000000000000',
      high: '3.7900000000000000',
      low: '3.6168000000000000',
      close: '3.7614000000000000',
      volume: '4045380.2000000000000000',
      quoteVolume: 15074632.829739979,
      btcVolume: 1604.6960921741713,
      usdVolume: 15074632.829739979,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '3.7621000000000000',
      high: '3.8524000000000000',
      low: '3.7526000000000000',
      close: '3.7815000000000000',
      volume: '3630692.0900000000000000',
      quoteVolume: 13812656.339239048,
      btcVolume: 1432.957547933766,
      usdVolume: 13812656.339239048,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '3.7815000000000000',
      high: '3.9500000000000000',
      low: '3.7786000000000000',
      close: '3.8895000000000000',
      volume: '5739644.8600000000000000',
      quoteVolume: 22305115.00685797,
      btcVolume: 2287.439967086519,
      usdVolume: 22305115.00685797,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '3.8895000000000000',
      high: '3.9488000000000000',
      low: '3.7734000000000000',
      close: '3.9470000000000000',
      volume: '3765966.9300000000000000',
      quoteVolume: 14542942.326336175,
      btcVolume: 1503.244995161628,
      usdVolume: 14542942.326336175,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '3.9466000000000000',
      high: '4.1388000000000000',
      low: '3.8955000000000000',
      close: '4.0866000000000000',
      volume: '9805842.1600000000000000',
      quoteVolume: 39677537.53178006,
      btcVolume: 4109.400460965454,
      usdVolume: 39677537.53178006,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '4.0880000000000000',
      high: '4.1740000000000000',
      low: '3.7292000000000000',
      close: '3.8298000000000000',
      volume: '10446021.8600000000000000',
      quoteVolume: 41318047.346202776,
      btcVolume: 4472.548344593976,
      usdVolume: 41318047.346202776,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '3.8307000000000000',
      high: '4.1447000000000000',
      low: '3.7932000000000000',
      close: '4.0611000000000000',
      volume: '7194944.8900000000000000',
      quoteVolume: 28640710.818571955,
      btcVolume: 3133.076928130972,
      usdVolume: 28640710.818571955,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '4.0620000000000000',
      high: '4.1072000000000000',
      low: '3.9100000000000000',
      close: '3.9250000000000000',
      volume: '5865674.1500000000000000',
      quoteVolume: 23402699.08869481,
      btcVolume: 2542.731357186535,
      usdVolume: 23402699.08869481,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '3.9242000000000000',
      high: '4.0262000000000000',
      low: '3.7844000000000000',
      close: '3.7917000000000000',
      volume: '6229925.9300000000000000',
      quoteVolume: 24355874.593129028,
      btcVolume: 2685.7880060204666,
      usdVolume: 24355874.593129028,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '3.7878000000000000',
      high: '3.9073000000000000',
      low: '3.7700000000000000',
      close: '3.8614000000000000',
      volume: '4443996.5100000000000000',
      quoteVolume: 17123412.802925922,
      btcVolume: 1942.855709479334,
      usdVolume: 17123412.802925922,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '3.8634000000000000',
      high: '3.8816000000000000',
      low: '3.7689000000000000',
      close: '3.8440000000000000',
      volume: '3774200.8300000000000000',
      quoteVolume: 14457491.492909947,
      btcVolume: 1633.177692310847,
      usdVolume: 14457491.492909947,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '3.8436000000000000',
      high: '3.9143000000000000',
      low: '3.8065000000000000',
      close: '3.8526000000000000',
      volume: '3793553.8500000000000000',
      quoteVolume: 14669023.347105095,
      btcVolume: 1621.3588858213739,
      usdVolume: 14669023.347105095,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '3.8542000000000000',
      high: '4.0601000000000000',
      low: '3.8270000000000000',
      close: '4.0461000000000000',
      volume: '4242354.5900000000000000',
      quoteVolume: 16636880.490730118,
      btcVolume: 1771.4352825657559,
      usdVolume: 16636880.490730118,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '4.0462000000000000',
      high: '4.1300000000000000',
      low: '3.9488000000000000',
      close: '3.9800000000000000',
      volume: '5242842.6700000000000000',
      quoteVolume: 21161407.756916173,
      btcVolume: 2231.9952818631095,
      usdVolume: 21161407.756916173,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '3.9820000000000000',
      high: '4.1800000000000000',
      low: '3.9430000000000000',
      close: '4.1763000000000000',
      volume: '4734058.3400000000000000',
      quoteVolume: 19356855.90212316,
      btcVolume: 2030.7654975998275,
      usdVolume: 19356855.90212316,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '4.1780000000000000',
      high: '4.2838000000000000',
      low: '4.0410000000000000',
      close: '4.1310000000000000',
      volume: '6223457.9900000000000000',
      quoteVolume: 25986422.58422777,
      btcVolume: 2723.684714084976,
      usdVolume: 25986422.58422777,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '4.1278000000000000',
      high: '4.4800000000000000',
      low: '4.1175000000000000',
      close: '4.3785000000000000',
      volume: '8703425.4700000000000000',
      quoteVolume: 38003418.52632179,
      btcVolume: 3946.2706304533644,
      usdVolume: 38003418.52632179,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '4.3795000000000000',
      high: '4.6185000000000000',
      low: '4.1075000000000000',
      close: '4.3976000000000000',
      volume: '8147770.0700000000000000',
      quoteVolume: 35881970.22012894,
      btcVolume: 3668.4636094846724,
      usdVolume: 35881970.22012894,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '4.3987000000000000',
      high: '4.5300000000000000',
      low: '4.2898000000000000',
      close: '4.4892000000000000',
      volume: '4168765.8100000000000000',
      quoteVolume: 18467942.894892167,
      btcVolume: 1933.344668741359,
      usdVolume: 18467942.894892167,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '4.4881000000000000',
      high: '4.5081000000000000',
      low: '4.2864000000000000',
      close: '4.4243000000000000',
      volume: '3231902.9900000000000000',
      quoteVolume: 14276426.128533928,
      btcVolume: 1476.4285848581262,
      usdVolume: 14276426.128533928,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '4.4263000000000000',
      high: '4.4860000000000000',
      low: '4.3405000000000000',
      close: '4.3580000000000000',
      volume: '2314891.8700000000000000',
      quoteVolume: 10216880.887104046,
      btcVolume: 1049.6536854309381,
      usdVolume: 10216880.887104046,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '4.3569000000000000',
      high: '4.3979000000000000',
      low: '4.3034000000000000',
      close: '4.3512000000000000',
      volume: '1153869.9600000000000000',
      quoteVolume: 5020633.317667975,
      btcVolume: 521.0659599515068,
      usdVolume: 5020633.317667975,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '4.3513000000000000',
      high: '4.4100000000000000',
      low: '4.2068000000000000',
      close: '4.3465000000000000',
      volume: '2137522.0500000000000000',
      quoteVolume: 9211491.186056014,
      btcVolume: 960.8098153273933,
      usdVolume: 9211491.186056014,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '4.3487000000000000',
      high: '4.4759000000000000',
      low: '4.3250000000000000',
      close: '4.4730000000000000',
      volume: '2037163.2100000000000000',
      quoteVolume: 8929343.506046021,
      btcVolume: 918.8751048356538,
      usdVolume: 8929343.506046021,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '4.4753000000000000',
      high: '4.5062000000000000',
      low: '4.3605000000000000',
      close: '4.4350000000000000',
      volume: '2250839.6700000000000000',
      quoteVolume: 9985584.222381042,
      btcVolume: 1027.6679495159528,
      usdVolume: 9985584.222381042,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '4.4374000000000000',
      high: '4.4404000000000000',
      low: '4.3426000000000000',
      close: '4.4225000000000000',
      volume: '2229011.4100000000000000',
      quoteVolume: 9773177.039550047,
      btcVolume: 997.9263921227159,
      usdVolume: 9773177.039550047,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '4.4207000000000000',
      high: '4.4503000000000000',
      low: '3.9100000000000000',
      close: '3.9565000000000000',
      volume: '6568688.8700000000000000',
      quoteVolume: 27115697.81298312,
      btcVolume: 2861.0421903797023,
      usdVolume: 27115697.81298312,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '3.9530000000000000',
      high: '4.1874000000000000',
      low: '3.8573000000000000',
      close: '4.0819000000000000',
      volume: '4751891.2300000000000000',
      quoteVolume: 19339118.373514097,
      btcVolume: 2057.689856681987,
      usdVolume: 19339118.373514097,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '4.0807000000000000',
      high: '4.1346000000000000',
      low: '4.0054000000000000',
      close: '4.1100000000000000',
      volume: '1604660.7400000000000000',
      quoteVolume: 6524511.677829004,
      btcVolume: 692.1491678856813,
      usdVolume: 6524511.677829004,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '4.1100000000000000',
      high: '4.1134000000000000',
      low: '3.9143000000000000',
      close: '3.9371000000000000',
      volume: '1710213.3600000000000000',
      quoteVolume: 6841578.031834006,
      btcVolume: 729.0393181477964,
      usdVolume: 6841578.031834006,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '3.9395000000000000',
      high: '3.9889000000000000',
      low: '3.6551000000000000',
      close: '3.9274000000000000',
      volume: '6850306.1100000000000000',
      quoteVolume: 26068942.977982927,
      btcVolume: 2826.528626557364,
      usdVolume: 26068942.977982927,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '3.9254000000000000',
      high: '4.0918000000000000',
      low: '3.8935000000000000',
      close: '4.0502000000000000',
      volume: '3614037.3300000000000000',
      quoteVolume: 14515402.500366056,
      btcVolume: 1528.8235581573408,
      usdVolume: 14515402.500366056,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '4.0502000000000000',
      high: '4.2000000000000000',
      low: '3.9570000000000000',
      close: '4.1659000000000000',
      volume: '4301280.9800000000000000',
      quoteVolume: 17558113.834275063,
      btcVolume: 1862.55704310758,
      usdVolume: 17558113.834275063,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '4.1663000000000000',
      high: '4.2491000000000000',
      low: '4.0957000000000000',
      close: '4.1415000000000000',
      volume: '3348384.0800000000000000',
      quoteVolume: 13969700.00507494,
      btcVolume: 1483.9403280382335,
      usdVolume: 13969700.00507494,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '4.1417000000000000',
      high: '4.1839000000000000',
      low: '4.0521000000000000',
      close: '4.0980000000000000',
      volume: '2424386.7000000000000000',
      quoteVolume: 9970938.73032103,
      btcVolume: 1068.115770950031,
      usdVolume: 9970938.73032103,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '4.0978000000000000',
      high: '4.2072000000000000',
      low: '4.0654000000000000',
      close: '4.1765000000000000',
      volume: '2414698.6200000000000000',
      quoteVolume: 10014907.932097934,
      btcVolume: 1076.6564266730459,
      usdVolume: 10014907.932097934,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '4.1762000000000000',
      high: '4.2058000000000000',
      low: '4.1395000000000000',
      close: '4.1812000000000000',
      volume: '1336859.5000000000000000',
      quoteVolume: 5582676.14346401,
      btcVolume: 596.8254803691216,
      usdVolume: 5582676.14346401,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '4.1819000000000000',
      high: '4.4859000000000000',
      low: '4.1760000000000000',
      close: '4.4841000000000000',
      volume: '4636790.2800000000000000',
      quoteVolume: 20238493.440281935,
      btcVolume: 2131.3300159503665,
      usdVolume: 20238493.440281935,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '4.4840000000000000',
      high: '4.9422000000000000',
      low: '4.4254000000000000',
      close: '4.8051000000000000',
      volume: '10884153.7200000000000000',
      quoteVolume: 51489688.58105523,
      btcVolume: 5341.591224643085,
      usdVolume: 51489688.58105523,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '4.8061000000000000',
      high: '4.9411000000000000',
      low: '4.4986000000000000',
      close: '4.7075000000000000',
      volume: '10253258.2100000000000000',
      quoteVolume: 48438749.91706304,
      btcVolume: 5137.643671618544,
      usdVolume: 48438749.91706304,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '4.7075000000000000',
      high: '4.8407000000000000',
      low: '4.5033000000000000',
      close: '4.8134000000000000',
      volume: '7023055.3700000000000000',
      quoteVolume: 33164952.91921413,
      btcVolume: 3591.85419221288,
      usdVolume: 33164952.91921413,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '4.8129000000000000',
      high: '4.8587000000000000',
      low: '4.6424000000000000',
      close: '4.6813000000000000',
      volume: '5531129.1300000000000000',
      quoteVolume: 26116409.53864893,
      btcVolume: 2843.866240257118,
      usdVolume: 26116409.53864893,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '4.6830000000000000',
      high: '4.6957000000000000',
      low: '4.3000000000000000',
      close: '4.4058000000000000',
      volume: '6165533.0500000000000000',
      quoteVolume: 27809003.537887093,
      btcVolume: 3060.9526617884444,
      usdVolume: 27809003.537887093,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '4.4051000000000000',
      high: '4.6200000000000000',
      low: '4.3647000000000000',
      close: '4.5668000000000000',
      volume: '4751931.7400000000000000',
      quoteVolume: 21438525.93253806,
      btcVolume: 2359.635570929396,
      usdVolume: 21438525.93253806,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '4.5658000000000000',
      high: '4.6500000000000000',
      low: '4.4232000000000000',
      close: '4.6187000000000000',
      volume: '4789663.3200000000000000',
      quoteVolume: 21719855.120023914,
      btcVolume: 2382.4340020200825,
      usdVolume: 21719855.120023914,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '4.6192000000000000',
      high: '4.6622000000000000',
      low: '4.5337000000000000',
      close: '4.5626000000000000',
      volume: '3001636.3100000000000000',
      quoteVolume: 13817102.521447966,
      btcVolume: 1509.948465482142,
      usdVolume: 13817102.521447966,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '4.5625000000000000',
      high: '4.7300000000000000',
      low: '4.5217000000000000',
      close: '4.7067000000000000',
      volume: '4059427.1700000000000000',
      quoteVolume: 18803296.868452046,
      btcVolume: 2044.3470835901742,
      usdVolume: 18803296.868452046,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '4.7068000000000000',
      high: '4.9181000000000000',
      low: '4.6025000000000000',
      close: '4.7996000000000000',
      volume: '7638387.3500000000000000',
      quoteVolume: 36415952.50593107,
      btcVolume: 3981.9894097602037,
      usdVolume: 36415952.50593107,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '4.7998000000000000',
      high: '4.8964000000000000',
      low: '4.7031000000000000',
      close: '4.7309000000000000',
      volume: '4456413.0000000000000000',
      quoteVolume: 21519728.981386155,
      btcVolume: 2366.7125707992577,
      usdVolume: 21519728.981386155,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '4.7300000000000000',
      high: '4.8539000000000000',
      low: '4.7186000000000000',
      close: '4.8200000000000000',
      volume: '2535114.5100000000000000',
      quoteVolume: 12133983.302445995,
      btcVolume: 1333.81578228134,
      usdVolume: 12133983.302445995,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '4.8198000000000000',
      high: '4.8325000000000000',
      low: '4.6118000000000000',
      close: '4.7439000000000000',
      volume: '3420895.3700000000000000',
      quoteVolume: 16145214.992928915,
      btcVolume: 1782.6229066425183,
      usdVolume: 16145214.992928915,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '4.7426000000000000',
      high: '5.5000000000000000',
      low: '4.7362000000000000',
      close: '5.3617000000000000',
      volume: '12032948.9700000000000000',
      quoteVolume: 61715137.636092246,
      btcVolume: 6663.492972858207,
      usdVolume: 61715137.636092246,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '5.3613000000000000',
      high: '5.8890000000000000',
      low: '5.3300000000000000',
      close: '5.7248000000000000',
      volume: '13783142.2200000000000000',
      quoteVolume: 77491639.85352772,
      btcVolume: 8361.43667342537,
      usdVolume: 77491639.85352772,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '5.7245000000000000',
      high: '6.5607000000000000',
      low: '5.5920000000000000',
      close: '6.4987000000000000',
      volume: '15825025.1900000000000000',
      quoteVolume: 97337955.14051247,
      btcVolume: 10432.903935303775,
      usdVolume: 97337955.14051247,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '6.5001000000000000',
      high: '6.5006000000000000',
      low: '5.7029000000000000',
      close: '6.0820000000000000',
      volume: '12942475.7400000000000000',
      quoteVolume: 78848343.82277149,
      btcVolume: 8463.213815528254,
      usdVolume: 78848343.82277149,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '6.0820000000000000',
      high: '6.2009000000000000',
      low: '5.7096000000000000',
      close: '6.1488000000000000',
      volume: '9932051.9600000000000000',
      quoteVolume: 59118518.95393981,
      btcVolume: 6423.877893463667,
      usdVolume: 59118518.95393981,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '6.1500000000000000',
      high: '6.2661000000000000',
      low: '5.9610000000000000',
      close: '6.0994000000000000',
      volume: '5180049.0300000000000000',
      quoteVolume: 31685675.96767919,
      btcVolume: 3429.120643940731,
      usdVolume: 31685675.96767919,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '6.1043000000000000',
      high: '7.3898000000000000',
      low: '6.0975000000000000',
      close: '7.3345000000000000',
      volume: '18949318.6200000000000000',
      quoteVolume: 128119147.50220707,
      btcVolume: 13858.753628207221,
      usdVolume: 128119147.50220707,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '7.3447000000000000',
      high: '8.4800000000000000',
      low: '6.8525000000000000',
      close: '7.1630000000000000',
      volume: '30737550.9600000000000000',
      quoteVolume: 235671215.53400916,
      btcVolume: 25398.30633539493,
      usdVolume: 235671215.53400916,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '7.1616000000000000',
      high: '8.3446000000000000',
      low: '6.9650000000000000',
      close: '8.1321000000000000',
      volume: '25153380.4100000000000000',
      quoteVolume: 192793205.38648593,
      btcVolume: 20888.78682809465,
      usdVolume: 192793205.38648593,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '8.1315000000000000',
      high: '8.9080000000000000',
      low: '7.8309000000000000',
      close: '8.6494000000000000',
      volume: '20518883.2200000000000000',
      quoteVolume: 172454027.970733,
      btcVolume: 18690.633753627804,
      usdVolume: 172454027.970733,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '8.6486000000000000',
      high: '8.8822000000000000',
      low: '7.9427000000000000',
      close: '8.3540000000000000',
      volume: '20705851.7900000000000000',
      quoteVolume: 173628619.95218885,
      btcVolume: 19005.124013284785,
      usdVolume: 173628619.95218885,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '8.3545000000000000',
      high: '8.6444000000000000',
      low: '8.2000000000000000',
      close: '8.2605000000000000',
      volume: '8580583.8800000000000000',
      quoteVolume: 71891358.28923588,
      btcVolume: 7867.034349896577,
      usdVolume: 71891358.28923588,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '8.2589000000000000',
      high: '8.2648000000000000',
      low: '7.6570000000000000',
      close: '7.9734000000000000',
      volume: '11416632.8500000000000000',
      quoteVolume: 90986110.87730221,
      btcVolume: 9936.17044525862,
      usdVolume: 90986110.87730221,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '7.9731000000000000',
      high: '8.2100000000000000',
      low: '7.8006000000000000',
      close: '8.0784000000000000',
      volume: '5325842.7900000000000000',
      quoteVolume: 42588167.82897199,
      btcVolume: 4648.695039778068,
      usdVolume: 42588167.82897199,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '8.0848000000000000',
      high: '8.1388000000000000',
      low: '7.1210000000000000',
      close: '7.2301000000000000',
      volume: '11024362.8200000000000000',
      quoteVolume: 84110745.40996003,
      btcVolume: 9169.302612989131,
      usdVolume: 84110745.40996003,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '7.2301000000000000',
      high: '7.6788000000000000',
      low: '6.9232000000000000',
      close: '7.3101000000000000',
      volume: '10932081.1000000000000000',
      quoteVolume: 80003692.44480799,
      btcVolume: 8576.725504872806,
      usdVolume: 80003692.44480799,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '7.3086000000000000',
      high: '7.6365000000000000',
      low: '7.0832000000000000',
      close: '7.4689000000000000',
      volume: '9596016.7400000000000000',
      quoteVolume: 70863239.4579672,
      btcVolume: 7572.310124975652,
      usdVolume: 70863239.4579672,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '7.4690000000000000',
      high: '8.0990000000000000',
      low: '7.4029000000000000',
      close: '7.8983000000000000',
      volume: '10915322.8800000000000000',
      quoteVolume: 85588641.40242985,
      btcVolume: 8986.708733063164,
      usdVolume: 85588641.40242985,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '7.8982000000000000',
      high: '7.9304000000000000',
      low: '7.3840000000000000',
      close: '7.4284000000000000',
      volume: '7313843.7500000000000000',
      quoteVolume: 55866119.15418281,
      btcVolume: 5854.581041359676,
      usdVolume: 55866119.15418281,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '7.4284000000000000',
      high: '7.8009000000000000',
      low: '7.3744000000000000',
      close: '7.6720000000000000',
      volume: '7502502.4600000000000000',
      quoteVolume: 57265634.06685721,
      btcVolume: 5964.891413603993,
      usdVolume: 57265634.06685721,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '7.6737000000000000',
      high: '7.9450000000000000',
      low: '7.1885000000000000',
      close: '7.5022000000000000',
      volume: '10637660.3200000000000000',
      quoteVolume: 80431491.35325707,
      btcVolume: 8167.326551727266,
      usdVolume: 80431491.35325707,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '7.5009000000000000',
      high: '7.5045000000000000',
      low: '6.5101000000000000',
      close: '7.1000000000000000',
      volume: '16680558.0400000000000000',
      quoteVolume: 117110382.73523542,
      btcVolume: 11213.359323082714,
      usdVolume: 117110382.73523542,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '7.0990000000000000',
      high: '7.4243000000000000',
      low: '6.9106000000000000',
      close: '7.2333000000000000',
      volume: '8500726.8300000000000000',
      quoteVolume: 60812597.213252656,
      btcVolume: 5549.91287596061,
      usdVolume: 60812597.213252656,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '7.2332000000000000',
      high: '7.4871000000000000',
      low: '6.9867000000000000',
      close: '7.0876000000000000',
      volume: '7058913.7900000000000000',
      quoteVolume: 50881041.657357685,
      btcVolume: 4599.08400376134,
      usdVolume: 50881041.657357685,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '7.0876000000000000',
      high: '7.5757000000000000',
      low: '7.0253000000000000',
      close: '7.4093000000000000',
      volume: '7667819.8300000000000000',
      quoteVolume: 56146316.3170551,
      btcVolume: 5092.87952074015,
      usdVolume: 56146316.3170551,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '7.4093000000000000',
      high: '7.8649000000000000',
      low: '7.4085000000000000',
      close: '7.8033000000000000',
      volume: '7419454.2600000000000000',
      quoteVolume: 57010133.853312016,
      btcVolume: 5107.431794695363,
      usdVolume: 57010133.853312016,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '7.8025000000000000',
      high: '8.4720000000000000',
      low: '7.6373000000000000',
      close: '8.3248000000000000',
      volume: '9345076.4000000000000000',
      quoteVolume: 74293026.60700549,
      btcVolume: 6369.51346066485,
      usdVolume: 74293026.60700549,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '8.3216000000000000',
      high: '8.7972000000000000',
      low: '6.8700000000000000',
      close: '8.2814000000000000',
      volume: '22647614.3400000000000000',
      quoteVolume: 187308467.6520541,
      btcVolume: 16618.54057221337,
      usdVolume: 187308467.6520541,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '8.2851000000000000',
      high: '9.2707000000000000',
      low: '8.1408000000000000',
      close: '9.2502000000000000',
      volume: '12805982.5400000000000000',
      quoteVolume: 110080414.61075139,
      btcVolume: 9792.377399999354,
      usdVolume: 110080414.61075139,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '9.2502000000000000',
      high: '9.9645000000000000',
      low: '9.0807000000000000',
      close: '9.8687000000000000',
      volume: '18460200.9900000000000000',
      quoteVolume: 175203350.75301245,
      btcVolume: 15588.926012531812,
      usdVolume: 175203350.75301245,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '9.8686000000000000',
      high: '9.8686000000000000',
      low: '9.3207000000000000',
      close: '9.5253000000000000',
      volume: '9682088.7700000000000000',
      quoteVolume: 92516426.18121561,
      btcVolume: 8087.5667816474515,
      usdVolume: 92516426.18121561,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '9.5265000000000000',
      high: '10.2917000000000000',
      low: '9.4515000000000000',
      close: '10.2343000000000000',
      volume: '9041552.6000000000000000',
      quoteVolume: 88144051.28129894,
      btcVolume: 7505.910950203218,
      usdVolume: 88144051.28129894,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '10.2284000000000000',
      high: '10.2615000000000000',
      low: '9.0500000000000000',
      close: '10.1286000000000000',
      volume: '11733225.3400000000000000',
      quoteVolume: 115568472.9989462,
      btcVolume: 9912.375472640897,
      usdVolume: 115568472.9989462,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '10.1286000000000000',
      high: '13.8799000000000000',
      low: '9.9287000000000000',
      close: '12.7093000000000000',
      volume: '28542792.8400000000000000',
      quoteVolume: 342944887.19930977,
      btcVolume: 29343.8183945227,
      usdVolume: 342944887.19930977,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '12.7095000000000000',
      high: '14.4586000000000000',
      low: '12.0000000000000000',
      close: '13.8104000000000000',
      volume: '28201686.3100000000000000',
      quoteVolume: 376769344.70101273,
      btcVolume: 32361.99931890226,
      usdVolume: 376769344.70101273,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '13.8105000000000000',
      high: '14.0551000000000000',
      low: '12.5200000000000000',
      close: '13.4722000000000000',
      volume: '17910767.8200000000000000',
      quoteVolume: 240411392.94454074,
      btcVolume: 20238.132402655556,
      usdVolume: 240411392.94454074,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '13.4722000000000000',
      high: '13.5403000000000000',
      low: '12.0905000000000000',
      close: '12.9971000000000000',
      volume: '17514622.4200000000000000',
      quoteVolume: 225363799.55548632,
      btcVolume: 19493.751301956687,
      usdVolume: 225363799.55548632,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '12.9999000000000000',
      high: '16.8000000000000000',
      low: '12.5936000000000000',
      close: '16.4635000000000000',
      volume: '23113249.4000000000000000',
      quoteVolume: 342110085.2548909,
      btcVolume: 29740.856429109834,
      usdVolume: 342110085.2548909,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '16.4634000000000000',
      high: '18.3488000000000000',
      low: '15.9175000000000000',
      close: '17.1883000000000000',
      volume: '30934675.5500000000000000',
      quoteVolume: 527151580.26604337,
      btcVolume: 45706.95238937064,
      usdVolume: 527151580.26604337,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '17.1728000000000000',
      high: '17.5998000000000000',
      low: '16.3000000000000000',
      close: '16.9574000000000000',
      volume: '11109883.8400000000000000',
      quoteVolume: 188010113.5259645,
      btcVolume: 16024.502767051485,
      usdVolume: 188010113.5259645,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '16.9447000000000000',
      high: '19.8303000000000000',
      low: '16.5554000000000000',
      close: '19.2285000000000000',
      volume: '17941112.0100000000000000',
      quoteVolume: 330894146.757813,
      btcVolume: 27921.28371563732,
      usdVolume: 330894146.757813,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '19.2108000000000000',
      high: '20.1111000000000000',
      low: '18.5144000000000000',
      close: '18.8061000000000000',
      volume: '10757840.9900000000000000',
      quoteVolume: 206509366.21838295,
      btcVolume: 17444.9116283756,
      usdVolume: 206509366.21838295,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '18.8096000000000000',
      high: '19.7111000000000000',
      low: '15.5000000000000000',
      close: '16.8196000000000000',
      volume: '13542369.7900000000000000',
      quoteVolume: 248379200.46697778,
      btcVolume: 20504.962867936094,
      usdVolume: 248379200.46697778,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '16.8064000000000000',
      high: '17.6738000000000000',
      low: '14.6900000000000000',
      close: '16.1927000000000000',
      volume: '27088057.7600000000000000',
      quoteVolume: 445332598.9067832,
      btcVolume: 36628.32089159984,
      usdVolume: 445332598.9067832,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '16.1846000000000000',
      high: '17.2554000000000000',
      low: '14.8926000000000000',
      close: '16.1863000000000000',
      volume: '18358817.9100000000000000',
      quoteVolume: 297260255.6285172,
      btcVolume: 25207.258992200754,
      usdVolume: 297260255.6285172,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '16.1884000000000000',
      high: '16.7862000000000000',
      low: '15.7232000000000000',
      close: '16.0483000000000000',
      volume: '11011575.0900000000000000',
      quoteVolume: 179321055.27822992,
      btcVolume: 15196.345359291849,
      usdVolume: 179321055.27822992,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '16.0476000000000000',
      high: '16.1421000000000000',
      low: '12.8900000000000000',
      close: '13.8325000000000000',
      volume: '26139289.1300000000000000',
      quoteVolume: 376332110.8896587,
      btcVolume: 32120.329513780653,
      usdVolume: 376332110.8896587,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '13.8342000000000000',
      high: '16.3300000000000000',
      low: '13.6600000000000000',
      close: '15.8078000000000000',
      volume: '16709823.0900000000000000',
      quoteVolume: 252784283.01039815,
      btcVolume: 21872.182565912786,
      usdVolume: 252784283.01039815,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '15.8085000000000000',
      high: '16.0042000000000000',
      low: '14.6536000000000000',
      close: '15.1428000000000000',
      volume: '10765015.8300000000000000',
      quoteVolume: 163456993.92262277,
      btcVolume: 14082.112398712841,
      usdVolume: 163456993.92262277,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '15.1572000000000000',
      high: '15.8797000000000000',
      low: '14.7000000000000000',
      close: '15.1419000000000000',
      volume: '8454928.0700000000000000',
      quoteVolume: 129187194.20472585,
      btcVolume: 11012.764821572282,
      usdVolume: 129187194.20472585,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '15.1363000000000000',
      high: '15.3743000000000000',
      low: '13.4578000000000000',
      close: '14.1164000000000000',
      volume: '11988432.8700000000000000',
      quoteVolume: 173378967.9258536,
      btcVolume: 15132.48523268232,
      usdVolume: 173378967.9258536,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '14.1171000000000000',
      high: '15.6664000000000000',
      low: '14.0537000000000000',
      close: '15.2000000000000000',
      volume: '11415333.4100000000000000',
      quoteVolume: 169326699.33486208,
      btcVolume: 14838.375991579402,
      usdVolume: 169326699.33486208,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '15.2088000000000000',
      high: '15.5215000000000000',
      low: '13.9679000000000000',
      close: '14.5234000000000000',
      volume: '11685135.1900000000000000',
      quoteVolume: 173265514.72794762,
      btcVolume: 15276.438417623027,
      usdVolume: 173265514.72794762,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '14.5234000000000000',
      high: '15.4989000000000000',
      low: '14.2360000000000000',
      close: '15.1207000000000000',
      volume: '7108908.2300000000000000',
      quoteVolume: 106217471.48299323,
      btcVolume: 9283.514516201361,
      usdVolume: 106215608.60329323,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '15.1136000000000000',
      high: '17.0305000000000000',
      low: '14.9565000000000000',
      close: '16.4273000000000000',
      volume: '11734386.8700000000000000',
      quoteVolume: 189641391.5824856,
      btcVolume: 16492.39931867633,
      usdVolume: 189641391.5824856,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '16.4537000000000000',
      high: '17.7777000000000000',
      low: '16.4037000000000000',
      close: '16.4788000000000000',
      volume: '9731994.3200000000000000',
      quoteVolume: 166124406.2667331,
      btcVolume: 14311.349188271188,
      usdVolume: 166124406.2667331,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '16.4782000000000000',
      high: '16.9495000000000000',
      low: '15.4451000000000000',
      close: '15.5772000000000000',
      volume: '9615963.2700000000000000',
      quoteVolume: 157331308.63249928,
      btcVolume: 13475.644648985366,
      usdVolume: 157331308.63249928,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '15.5746000000000000',
      high: '16.5053000000000000',
      low: '15.0629000000000000',
      close: '16.0545000000000000',
      volume: '7793462.3200000000000000',
      quoteVolume: 123969155.38132185,
      btcVolume: 10492.616965270177,
      usdVolume: 123958598.20392784,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '16.2072000000000000',
      high: '16.3587000000000000',
      low: '14.0000000000000000',
      close: '14.7982000000000000',
      volume: '10301348.0000000000000000',
      quoteVolume: 154640593.96041393,
      btcVolume: 13465.979576934247,
      usdVolume: 154640593.96041393,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '14.7922000000000000',
      high: '14.9989000000000000',
      low: '10.8261000000000000',
      close: '11.8895000000000000',
      volume: '16949856.4800000000000000',
      quoteVolume: 227014586.31002295,
      btcVolume: 20804.680530088768,
      usdVolume: 227014586.31002295,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '11.8802000000000000',
      high: '12.9040000000000000',
      low: '11.0000000000000000',
      close: '12.3708000000000000',
      volume: '18612095.2100000000000000',
      quoteVolume: 225971603.75573367,
      btcVolume: 21814.154412869015,
      usdVolume: 225971603.75573367,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '12.3804000000000000',
      high: '12.7655000000000000',
      low: '9.1000000000000000',
      close: '10.6017000000000000',
      volume: '25511608.4800000000000000',
      quoteVolume: 278344840.137839,
      btcVolume: 27443.663519385616,
      usdVolume: 278344840.137839,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '10.6087000000000000',
      high: '13.2400000000000000',
      low: '9.7155000000000000',
      close: '12.8044000000000000',
      volume: '21811584.7900000000000000',
      quoteVolume: 254940172.73275283,
      btcVolume: 24977.6005690715,
      usdVolume: 254940172.73275283,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '12.8060000000000000',
      high: '13.1747000000000000',
      low: '11.1700000000000000',
      close: '12.4700000000000000',
      volume: '20515580.9600000000000000',
      quoteVolume: 250103840.5173619,
      btcVolume: 24710.405343979466,
      usdVolume: 250103840.5173619,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '12.4744000000000000',
      high: '12.7362000000000000',
      low: '11.0284000000000000',
      close: '11.7460000000000000',
      volume: '14832129.8100000000000000',
      quoteVolume: 176444797.96903428,
      btcVolume: 17428.664020195858,
      usdVolume: 176444797.96903428,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '11.7359000000000000',
      high: '12.6566000000000000',
      low: '11.4356000000000000',
      close: '12.3444000000000000',
      volume: '10649791.4300000000000000',
      quoteVolume: 129646130.9405381,
      btcVolume: 12725.251017309938,
      usdVolume: 129646130.9405381,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '12.3452000000000000',
      high: '13.2800000000000000',
      low: '12.1800000000000000',
      close: '12.4957000000000000',
      volume: '14928167.2000000000000000',
      quoteVolume: 190985359.03175014,
      btcVolume: 18462.71704639154,
      usdVolume: 190985359.03175014,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '12.4948000000000000',
      high: '12.6960000000000000',
      low: '12.0190000000000000',
      close: '12.4406000000000000',
      volume: '7498865.1600000000000000',
      quoteVolume: 92935019.6125966,
      btcVolume: 9026.605245547558,
      usdVolume: 92935019.6125966,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '12.4406000000000000',
      high: '12.7470000000000000',
      low: '12.0934000000000000',
      close: '12.7187000000000000',
      volume: '6269794.6100000000000000',
      quoteVolume: 77882381.38087636,
      btcVolume: 7509.491501095776,
      usdVolume: 77882381.38087636,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '12.7194000000000000',
      high: '13.2300000000000000',
      low: '11.7658000000000000',
      close: '12.0670000000000000',
      volume: '11107099.4800000000000000',
      quoteVolume: 138433490.7795642,
      btcVolume: 13317.710404478297,
      usdVolume: 138433490.7795642,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '12.0757000000000000',
      high: '12.3863000000000000',
      low: '11.6349000000000000',
      close: '12.1374000000000000',
      volume: '8830940.1600000000000000',
      quoteVolume: 106057143.09535801,
      btcVolume: 10093.04992993571,
      usdVolume: 106057143.09535801,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '12.1300000000000000',
      high: '12.2400000000000000',
      low: '10.7500000000000000',
      close: '10.9314000000000000',
      volume: '11413633.6300000000000000',
      quoteVolume: 132790893.20361203,
      btcVolume: 12320.461229483713,
      usdVolume: 132790893.20361203,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '10.9301000000000000',
      high: '11.3002000000000000',
      low: '10.4771000000000000',
      close: '10.7367000000000000',
      volume: '10362445.0800000000000000',
      quoteVolume: 112764173.2894766,
      btcVolume: 10353.892720724712,
      usdVolume: 112764173.2894766,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '10.7367000000000000',
      high: '11.6759000000000000',
      low: '10.4720000000000000',
      close: '11.0139000000000000',
      volume: '10163046.4600000000000000',
      quoteVolume: 111006019.31385103,
      btcVolume: 10179.558912072785,
      usdVolume: 111006019.31385103,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '11.0139000000000000',
      high: '11.1315000000000000',
      low: '9.7863000000000000',
      close: '10.0940000000000000',
      volume: '8890206.0700000000000000',
      quoteVolume: 93387663.390679,
      btcVolume: 8561.061779425265,
      usdVolume: 93387663.390679,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '10.0938000000000000',
      high: '10.4969000000000000',
      low: '9.8756000000000000',
      close: '10.1209000000000000',
      volume: '5596280.8000000000000000',
      quoteVolume: 56998511.47489919,
      btcVolume: 5177.969389632715,
      usdVolume: 56998511.47489919,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '10.1192000000000000',
      high: '10.6199000000000000',
      low: '9.5000000000000000',
      close: '9.7357000000000000',
      volume: '7410739.4400000000000000',
      quoteVolume: 74829506.00962031,
      btcVolume: 6862.181652542345,
      usdVolume: 74829506.00962031,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '9.7394000000000000',
      high: '9.9235000000000000',
      low: '8.5012000000000000',
      close: '8.7114000000000000',
      volume: '15761415.8500000000000000',
      quoteVolume: 141463825.6035975,
      btcVolume: 13312.026406212963,
      usdVolume: 141463825.6035975,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '8.7162000000000000',
      high: '9.0800000000000000',
      low: '8.5400000000000000',
      close: '8.7401000000000000',
      volume: '8074342.9400000000000000',
      quoteVolume: 71284015.95208645,
      btcVolume: 6811.9002965444815,
      usdVolume: 71284015.95208645,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '8.7440000000000000',
      high: '8.8016000000000000',
      low: '7.2869000000000000',
      close: '7.6364000000000000',
      volume: '18221454.9700000000000000',
      quoteVolume: 146298886.40303153,
      btcVolume: 14105.940252148712,
      usdVolume: 146298886.40303153,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '7.6404000000000000',
      high: '10.5300000000000000',
      low: '7.4761000000000000',
      close: '9.8700000000000000',
      volume: '26877668.8100000000000000',
      quoteVolume: 243924037.01423475,
      btcVolume: 23200.606802237573,
      usdVolume: 243924037.01423475,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '9.8701000000000000',
      high: '11.1990000000000000',
      low: '9.3771000000000000',
      close: '10.7279000000000000',
      volume: '20220142.0700000000000000',
      quoteVolume: 206454816.90600815,
      btcVolume: 19347.190816472226,
      usdVolume: 206454816.90600815,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '10.7218000000000000',
      high: '11.1000000000000000',
      low: '9.9757000000000000',
      close: '10.3169000000000000',
      volume: '12019763.1700000000000000',
      quoteVolume: 125899152.91074796,
      btcVolume: 11759.471660591937,
      usdVolume: 125899152.91074796,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '10.3079000000000000',
      high: '10.9910000000000000',
      low: '9.9122000000000000',
      close: '10.7939000000000000',
      volume: '10005871.9800000000000000',
      quoteVolume: 104553700.74577622,
      btcVolume: 9756.633696493238,
      usdVolume: 104553700.74577622,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '10.8000000000000000',
      high: '11.0700000000000000',
      low: '10.1500000000000000',
      close: '10.2830000000000000',
      volume: '8720119.6800000000000000',
      quoteVolume: 92733132.97270194,
      btcVolume: 8531.552193163896,
      usdVolume: 92733132.97270194,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '10.2809000000000000',
      high: '10.3882000000000000',
      low: '9.4502000000000000',
      close: '10.1350000000000000',
      volume: '13371311.3100000000000000',
      quoteVolume: 133299972.25986037,
      btcVolume: 12416.99662418838,
      usdVolume: 133299972.25986037,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '10.1356000000000000',
      high: '10.2314000000000000',
      low: '9.5768000000000000',
      close: '9.8694000000000000',
      volume: '6624754.2600000000000000',
      quoteVolume: 65302473.930610836,
      btcVolume: 6075.231257322131,
      usdVolume: 65302473.930610836,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '9.8727000000000000',
      high: '10.4119000000000000',
      low: '9.2457000000000000',
      close: '9.6394000000000000',
      volume: '9437903.4200000000000000',
      quoteVolume: 93289422.56827107,
      btcVolume: 8711.5733196697,
      usdVolume: 93289422.56827107,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '9.6394000000000000',
      high: '9.8449000000000000',
      low: '8.7975000000000000',
      close: '9.2167000000000000',
      volume: '11050283.7900000000000000',
      quoteVolume: 101613627.94135539,
      btcVolume: 9676.160636390727,
      usdVolume: 101613627.94135539,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '9.2132000000000000',
      high: '9.5555000000000000',
      low: '9.1040000000000000',
      close: '9.2690000000000000',
      volume: '4803593.0900000000000000',
      quoteVolume: 44581852.41136064,
      btcVolume: 4225.164872066804,
      usdVolume: 44581852.41136064,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '9.2686000000000000',
      high: '9.4660000000000000',
      low: '9.1141000000000000',
      close: '9.3922000000000000',
      volume: '4665626.6900000000000000',
      quoteVolume: 43259192.53149099,
      btcVolume: 4079.3745493298234,
      usdVolume: 43259192.53149099,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '9.3906000000000000',
      high: '9.7200000000000000',
      low: '9.2629000000000000',
      close: '9.6176000000000000',
      volume: '6205749.7700000000000000',
      quoteVolume: 58892347.278092586,
      btcVolume: 5503.330432382905,
      usdVolume: 58892347.278092586,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '9.6134000000000000',
      high: '9.6580000000000000',
      low: '8.5630000000000000',
      close: '8.7689000000000000',
      volume: '7422770.6500000000000000',
      quoteVolume: 67706985.53549488,
      btcVolume: 6342.859632868126,
      usdVolume: 67706985.53549488,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '8.7690000000000000',
      high: '9.0100000000000000',
      low: '8.3817000000000000',
      close: '8.9799000000000000',
      volume: '7536611.8700000000000000',
      quoteVolume: 65993355.663363345,
      btcVolume: 6218.463186844811,
      usdVolume: 65993355.663363345,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '8.9801000000000000',
      high: '9.6829000000000000',
      low: '8.4016000000000000',
      close: '9.5594000000000000',
      volume: '10110727.7500000000000000',
      quoteVolume: 91351206.53641662,
      btcVolume: 8488.116029639972,
      usdVolume: 91351206.53641662,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '9.5519000000000000',
      high: '10.5254000000000000',
      low: '9.3701000000000000',
      close: '10.3959000000000000',
      volume: '11276147.4500000000000000',
      quoteVolume: 112848361.62231602,
      btcVolume: 10272.349870485246,
      usdVolume: 112848361.62231602,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '10.3923000000000000',
      high: '10.9200000000000000',
      low: '10.3700000000000000',
      close: '10.5198000000000000',
      volume: '9504046.9700000000000000',
      quoteVolume: 100981242.65024652,
      btcVolume: 8918.134330290197,
      usdVolume: 100981242.65024652,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '10.5168000000000000',
      high: '11.0258000000000000',
      low: '10.1500000000000000',
      close: '10.8440000000000000',
      volume: '8011810.1500000000000000',
      quoteVolume: 85068557.21714121,
      btcVolume: 7485.698997274424,
      usdVolume: 85068557.21714121,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '10.8441000000000000',
      high: '11.8028000000000000',
      low: '10.6807000000000000',
      close: '11.4242000000000000',
      volume: '9262541.8700000000000000',
      quoteVolume: 104346545.63303679,
      btcVolume: 9109.599515130829,
      usdVolume: 104346545.63303679,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '11.4599000000000000',
      high: '11.5520000000000000',
      low: '10.6592000000000000',
      close: '11.0016000000000000',
      volume: '7617422.8600000000000000',
      quoteVolume: 85252142.00798237,
      btcVolume: 7456.401573669332,
      usdVolume: 85252142.00798237,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '11.0016000000000000',
      high: '11.3536000000000000',
      low: '10.6201000000000000',
      close: '10.8896000000000000',
      volume: '6061485.9500000000000000',
      quoteVolume: 66687126.90002584,
      btcVolume: 5850.816052446876,
      usdVolume: 66687126.90002584,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '10.8896000000000000',
      high: '10.9317000000000000',
      low: '10.4550000000000000',
      close: '10.7483000000000000',
      volume: '6908169.7600000000000000',
      quoteVolume: 73900491.87788318,
      btcVolume: 6486.307989052753,
      usdVolume: 73900491.87788318,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '10.7472000000000000',
      high: '10.8935000000000000',
      low: '10.2694000000000000',
      close: '10.5877000000000000',
      volume: '6662666.8600000000000000',
      quoteVolume: 70509800.96815944,
      btcVolume: 6215.660703350463,
      usdVolume: 70509800.96815944,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '10.5867000000000000',
      high: '10.7149000000000000',
      low: '10.4082000000000000',
      close: '10.6267000000000000',
      volume: '3320266.0600000000000000',
      quoteVolume: 35074078.772476934,
      btcVolume: 3094.4010233917893,
      usdVolume: 35074078.772476934,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '10.6254000000000000',
      high: '11.0000000000000000',
      low: '10.6000000000000000',
      close: '10.9409000000000000',
      volume: '3679623.9400000000000000',
      quoteVolume: 39786628.52001377,
      btcVolume: 3482.0203876379023,
      usdVolume: 39786628.52001377,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '10.9435000000000000',
      high: '11.2226000000000000',
      low: '10.6510000000000000',
      close: '10.9147000000000000',
      volume: '4474849.3100000000000000',
      quoteVolume: 48718810.825249076,
      btcVolume: 4198.544817946333,
      usdVolume: 48718810.825249076,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '10.9116000000000000',
      high: '10.9349000000000000',
      low: '9.7665000000000000',
      close: '9.8589000000000000',
      volume: '7095267.1100000000000000',
      quoteVolume: 73344149.05086827,
      btcVolume: 6182.229702645229,
      usdVolume: 73344149.05086827,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '9.8589000000000000',
      high: '11.1372000000000000',
      low: '9.7864000000000000',
      close: '10.7863000000000000',
      volume: '8276637.4200000000000000',
      quoteVolume: 87959927.25448503,
      btcVolume: 7031.471299457609,
      usdVolume: 87959927.25448503,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '10.7805000000000000',
      high: '12.4173000000000000',
      low: '10.7142000000000000',
      close: '11.9539000000000000',
      volume: '10301801.8300000000000000',
      quoteVolume: 120278047.81922631,
      btcVolume: 9287.505769123953,
      usdVolume: 120278047.81922631,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '11.9551000000000000',
      high: '12.2640000000000000',
      low: '11.5784000000000000',
      close: '11.7869000000000000',
      volume: '6599326.7100000000000000',
      quoteVolume: 78921676.86675517,
      btcVolume: 6109.358731500419,
      usdVolume: 78921676.86675517,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '12.1592000000000000',
      high: '12.9500000000000000',
      low: '11.9839000000000000',
      close: '12.7001000000000000',
      volume: '7531584.3600000000000000',
      quoteVolume: 94612367.21991429,
      btcVolume: 7266.641304869805,
      usdVolume: 94612367.21991429,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '12.7002000000000000',
      high: '12.8574000000000000',
      low: '11.9080000000000000',
      close: '12.1481000000000000',
      volume: '6996448.0600000000000000',
      quoteVolume: 86499461.98964313,
      btcVolume: 6638.643976139153,
      usdVolume: 86499461.98964313,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '12.1480000000000000',
      high: '12.4472000000000000',
      low: '11.2594000000000000',
      close: '11.7096000000000000',
      volume: '8831828.5200000000000000',
      quoteVolume: 104763458.12184793,
      btcVolume: 8038.602388420682,
      usdVolume: 104763458.12184793,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '11.7086000000000000',
      high: '12.4774000000000000',
      low: '11.4900000000000000',
      close: '11.9224000000000000',
      volume: '7598237.9300000000000000',
      quoteVolume: 91297276.70568031,
      btcVolume: 6804.543512604595,
      usdVolume: 91297276.70568031,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '11.9210000000000000',
      high: '12.1509000000000000',
      low: '11.1500000000000000',
      close: '11.4403000000000000',
      volume: '6787355.3400000000000000',
      quoteVolume: 78579989.27577649,
      btcVolume: 5868.232165162447,
      usdVolume: 78579989.27577649,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '11.4448000000000000',
      high: '11.8100000000000000',
      low: '11.0816000000000000',
      close: '11.2772000000000000',
      volume: '8541237.8300000000000000',
      quoteVolume: 97758547.62588826,
      btcVolume: 7336.005858513019,
      usdVolume: 97758547.62588826,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '11.2743000000000000',
      high: '11.4811000000000000',
      low: '10.7200000000000000',
      close: '11.0900000000000000',
      volume: '7958701.4600000000000000',
      quoteVolume: 87936295.50341521,
      btcVolume: 6567.679161107949,
      usdVolume: 87936295.50341521,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '11.0947000000000000',
      high: '11.3981000000000000',
      low: '10.9053000000000000',
      close: '11.2277000000000000',
      volume: '4822720.4700000000000000',
      quoteVolume: 53963475.40082074,
      btcVolume: 3926.032199920614,
      usdVolume: 53963475.40082074,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '11.2259000000000000',
      high: '11.5885000000000000',
      low: '11.0181000000000000',
      close: '11.5722000000000000',
      volume: '3872679.9900000000000000',
      quoteVolume: 43600203.22140587,
      btcVolume: 3168.731592931039,
      usdVolume: 43600203.22140587,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '11.5681000000000000',
      high: '11.6850000000000000',
      low: '10.7049000000000000',
      close: '10.7814000000000000',
      volume: '6919050.6800000000000000',
      quoteVolume: 77130468.23187579,
      btcVolume: 5694.891968008859,
      usdVolume: 77130468.23187579,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '10.7814000000000000',
      high: '10.7814000000000000',
      low: '9.9454000000000000',
      close: '10.3911000000000000',
      volume: '9925376.6400000000000000',
      quoteVolume: 102949946.71757828,
      btcVolume: 7589.417998761801,
      usdVolume: 102949946.71757828,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '10.3982000000000000',
      high: '10.6750000000000000',
      low: '9.7500000000000000',
      close: '10.4713000000000000',
      volume: '8586603.9100000000000000',
      quoteVolume: 87954953.98638126,
      btcVolume: 6320.607986664108,
      usdVolume: 87954953.98638126,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '10.4730000000000000',
      high: '11.2749000000000000',
      low: '10.0764000000000000',
      close: '10.9794000000000000',
      volume: '10616943.9300000000000000',
      quoteVolume: 113756787.2898385,
      btcVolume: 7651.513480243185,
      usdVolume: 113756787.2898385,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '10.9822000000000000',
      high: '12.2813000000000000',
      low: '10.9286000000000000',
      close: '12.1687000000000000',
      volume: '10613986.0500000000000000',
      quoteVolume: 123261012.0719206,
      btcVolume: 7916.228371545654,
      usdVolume: 123261012.0719206,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '12.1686000000000000',
      high: '13.5956000000000000',
      low: '11.3123000000000000',
      close: '11.7638000000000000',
      volume: '24836053.4300000000000000',
      quoteVolume: 312825764.5672563,
      btcVolume: 20466.466375561587,
      usdVolume: 312825764.5672563,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '11.7668000000000000',
      high: '13.1000000000000000',
      low: '11.4770000000000000',
      close: '12.7100000000000000',
      volume: '12895124.5400000000000000',
      quoteVolume: 159621589.8883934,
      btcVolume: 10501.569151809028,
      usdVolume: 159621589.8883934,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '12.7083000000000000',
      high: '13.0026000000000000',
      low: '12.0400000000000000',
      close: '12.5107000000000000',
      volume: '12634927.8300000000000000',
      quoteVolume: 157868780.3591942,
      btcVolume: 10278.693917702634,
      usdVolume: 157868780.3591942,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '12.5182000000000000',
      high: '13.2780000000000000',
      low: '12.3156000000000000',
      close: '13.0017000000000000',
      volume: '12313102.0800000000000000',
      quoteVolume: 158472628.9918838,
      btcVolume: 10350.422830750087,
      usdVolume: 158472628.9918838,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '13.0017000000000000',
      high: '13.4293000000000000',
      low: '12.7500000000000000',
      close: '12.7530000000000000',
      volume: '9402996.4400000000000000',
      quoteVolume: 123227278.88061701,
      btcVolume: 7903.41235731508,
      usdVolume: 123227278.88061701,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '12.7530000000000000',
      high: '13.0956000000000000',
      low: '12.2700000000000000',
      close: '12.4038000000000000',
      volume: '8887319.0400000000000000',
      quoteVolume: 112105873.89800362,
      btcVolume: 7056.930684473489,
      usdVolume: 112105873.89800362,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '12.4038000000000000',
      high: '13.0000000000000000',
      low: '12.3079000000000000',
      close: '12.8687000000000000',
      volume: '7183843.8700000000000000',
      quoteVolume: 91486357.29356198,
      btcVolume: 5621.798860970854,
      usdVolume: 91486357.29356198,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '12.8679000000000000',
      high: '12.9335000000000000',
      low: '12.3000000000000000',
      close: '12.5574000000000000',
      volume: '4779543.0800000000000000',
      quoteVolume: 60108858.82407817,
      btcVolume: 3755.1233582281616,
      usdVolume: 60108858.82407817,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '12.5570000000000000',
      high: '12.6484000000000000',
      low: '11.8156000000000000',
      close: '12.1192000000000000',
      volume: '5705889.4900000000000000',
      quoteVolume: 70141538.75487132,
      btcVolume: 4393.466812059062,
      usdVolume: 70141538.75487132,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '12.1199000000000000',
      high: '12.8132000000000000',
      low: '11.9971000000000000',
      close: '12.6223000000000000',
      volume: '5966554.6100000000000000',
      quoteVolume: 74312723.17503132,
      btcVolume: 4538.090941158825,
      usdVolume: 74312723.17503132,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '12.6204000000000000',
      high: '13.4228000000000000',
      low: '12.5414000000000000',
      close: '13.3944000000000000',
      volume: '9943527.4100000000000000',
      quoteVolume: 128513966.31700706,
      btcVolume: 7475.957078646857,
      usdVolume: 128513966.31700706,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '13.3943000000000000',
      high: '14.2669000000000000',
      low: '12.5850000000000000',
      close: '13.5598000000000000',
      volume: '17994859.8100000000000000',
      quoteVolume: 243378004.2585356,
      btcVolume: 13652.72139475029,
      usdVolume: 243378004.2585356,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '13.5602000000000000',
      high: '14.0000000000000000',
      low: '13.0111000000000000',
      close: '13.6187000000000000',
      volume: '9295294.8300000000000000',
      quoteVolume: 125766975.70460743,
      btcVolume: 7051.256067524977,
      usdVolume: 125766975.70460743,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '13.6117000000000000',
      high: '14.4770000000000000',
      low: '13.4618000000000000',
      close: '14.0933000000000000',
      volume: '10955855.4700000000000000',
      quoteVolume: 153945485.5929507,
      btcVolume: 8393.83606216672,
      usdVolume: 153945485.5929507,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '14.0913000000000000',
      high: '15.5000000000000000',
      low: '13.6876000000000000',
      close: '15.4511000000000000',
      volume: '13999538.8800000000000000',
      quoteVolume: 204973635.11923143,
      btcVolume: 10955.623567420895,
      usdVolume: 204973635.11923143,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '15.4408000000000000',
      high: '15.7179000000000000',
      low: '13.8402000000000000',
      close: '14.5098000000000000',
      volume: '14244002.3200000000000000',
      quoteVolume: 209484344.0724672,
      btcVolume: 11387.241340723021,
      usdVolume: 209484344.0724672,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '14.5098000000000000',
      high: '15.4250000000000000',
      low: '14.2520000000000000',
      close: '15.2746000000000000',
      volume: '12165056.4100000000000000',
      quoteVolume: 181293925.92475033,
      btcVolume: 9837.750688600954,
      usdVolume: 181293925.92475033,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '15.2648000000000000',
      high: '16.3900000000000000',
      low: '14.9430000000000000',
      close: '15.6703000000000000',
      volume: '15636471.1700000000000000',
      quoteVolume: 244854909.96831247,
      btcVolume: 13082.91825525146,
      usdVolume: 244854909.96831247,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '15.6637000000000000',
      high: '15.8600000000000000',
      low: '13.7900000000000000',
      close: '14.2016000000000000',
      volume: '9463367.4500000000000000',
      quoteVolume: 142275307.68800414,
      btcVolume: 7489.979662764517,
      usdVolume: 142275307.68800414,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '14.2180000000000000',
      high: '14.4743000000000000',
      low: '11.2939000000000000',
      close: '12.6030000000000000',
      volume: '24581331.8400000000000000',
      quoteVolume: 307097131.8146662,
      btcVolume: 17664.03164046134,
      usdVolume: 307097131.8146662,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '12.6036000000000000',
      high: '12.9530000000000000',
      low: '11.9454000000000000',
      close: '12.4989000000000000',
      volume: '9126132.6500000000000000',
      quoteVolume: 113170817.60460344,
      btcVolume: 6660.581797963793,
      usdVolume: 113170817.60460344,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '12.5038000000000000',
      high: '13.4300000000000000',
      low: '12.3489000000000000',
      close: '13.0853000000000000',
      volume: '7281492.8400000000000000',
      quoteVolume: 94217840.3930073,
      btcVolume: 5414.366931727638,
      usdVolume: 94217840.3930073,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '13.0848000000000000',
      high: '13.4765000000000000',
      low: '12.8408000000000000',
      close: '13.3800000000000000',
      volume: '6537927.5000000000000000',
      quoteVolume: 85931374.67872472,
      btcVolume: 4773.352624805251,
      usdVolume: 85931374.67872472,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '13.3855000000000000',
      high: '14.4370000000000000',
      low: '13.3855000000000000',
      close: '14.2677000000000000',
      volume: '10253978.8400000000000000',
      quoteVolume: 143593067.98141998,
      btcVolume: 7589.521928283262,
      usdVolume: 143593067.98141998,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '14.2626000000000000',
      high: '14.8328000000000000',
      low: '13.0456000000000000',
      close: '13.3461000000000000',
      volume: '10704338.7400000000000000',
      quoteVolume: 149610565.32318997,
      btcVolume: 7801.160851270977,
      usdVolume: 149610565.32318997,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '13.3544000000000000',
      high: '14.1482000000000000',
      low: '13.0500000000000000',
      close: '13.9799000000000000',
      volume: '6137932.7300000000000000',
      quoteVolume: 83779802.26252918,
      btcVolume: 4424.74642432009,
      usdVolume: 83779802.26252918,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '13.9785000000000000',
      high: '14.2200000000000000',
      low: '13.6446000000000000',
      close: '13.9749000000000000',
      volume: '4275317.8800000000000000',
      quoteVolume: 59535321.2775434,
      btcVolume: 3090.5416607922175,
      usdVolume: 59535321.2775434,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '13.9749000000000000',
      high: '14.0332000000000000',
      low: '12.5101000000000000',
      close: '12.6800000000000000',
      volume: '5794937.4500000000000000',
      quoteVolume: 77275788.62363255,
      btcVolume: 4061.1546698955426,
      usdVolume: 77275788.62363255,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '12.6800000000000000',
      high: '13.3478000000000000',
      low: '12.5265000000000000',
      close: '13.1748000000000000',
      volume: '3411635.3200000000000000',
      quoteVolume: 44668221.07546615,
      btcVolume: 2355.8773509120115,
      usdVolume: 44668221.07546615,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '13.1743000000000000',
      high: '13.5282000000000000',
      low: '12.7671000000000000',
      close: '13.5057000000000000',
      volume: '3866618.8500000000000000',
      quoteVolume: 51005078.82048807,
      btcVolume: 2664.431067672835,
      usdVolume: 51005078.82048807,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '13.5090000000000000',
      high: '13.5536000000000000',
      low: '12.8671000000000000',
      close: '13.0704000000000000',
      volume: '3223051.6500000000000000',
      quoteVolume: 42439345.8764611,
      btcVolume: 2211.4995680383877,
      usdVolume: 42439345.8764611,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '13.0737000000000000',
      high: '13.1929000000000000',
      low: '12.0000000000000000',
      close: '12.1356000000000000',
      volume: '5175641.2100000000000000',
      quoteVolume: 65172913.683855735,
      btcVolume: 3458.1321327537844,
      usdVolume: 65172913.683855735,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '12.1280000000000000',
      high: '12.8290000000000000',
      low: '11.5726000000000000',
      close: '12.6212000000000000',
      volume: '6754613.8300000000000000',
      quoteVolume: 81954160.37834212,
      btcVolume: 4495.429433765595,
      usdVolume: 81954160.37834212,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '12.6200000000000000',
      high: '12.6730000000000000',
      low: '11.8549000000000000',
      close: '11.9172000000000000',
      volume: '3519174.2400000000000000',
      quoteVolume: 42896656.04262703,
      btcVolume: 2346.0300410065424,
      usdVolume: 42896656.04262703,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '11.9172000000000000',
      high: '11.9899000000000000',
      low: '11.4300000000000000',
      close: '11.6335000000000000',
      volume: '4337254.6200000000000000',
      quoteVolume: 50652575.468863204,
      btcVolume: 2820.6350744098336,
      usdVolume: 50647631.718757205,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '11.6393000000000000',
      high: '12.3529000000000000',
      low: '11.6201000000000000',
      close: '12.2259000000000000',
      volume: '3453043.1200000000000000',
      quoteVolume: 41720520.772683,
      btcVolume: 2259.4574947618594,
      usdVolume: 41720520.772683,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '12.2280000000000000',
      high: '13.2697000000000000',
      low: '12.0210000000000000',
      close: '12.9392000000000000',
      volume: '6036848.9700000000000000',
      quoteVolume: 76518629.71278152,
      btcVolume: 3990.4218542597705,
      usdVolume: 76518629.71278152,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '12.9389000000000000',
      high: '12.9800000000000000',
      low: '12.3238000000000000',
      close: '12.8502000000000000',
      volume: '4526653.6900000000000000',
      quoteVolume: 57446821.99876489,
      btcVolume: 2998.157797062246,
      usdVolume: 57446821.99876489,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '12.8513000000000000',
      high: '13.0220000000000000',
      low: '12.4659000000000000',
      close: '12.6818000000000000',
      volume: '2635221.9300000000000000',
      quoteVolume: 33514452.026845157,
      btcVolume: 1732.722900418818,
      usdVolume: 33514452.026845157,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '12.6786000000000000',
      high: '13.5000000000000000',
      low: '12.3119000000000000',
      close: '13.4852000000000000',
      volume: '5644198.7100000000000000',
      quoteVolume: 73189908.58627348,
      btcVolume: 3595.924463613392,
      usdVolume: 73189908.58627348,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '13.4840000000000000',
      high: '14.5038000000000000',
      low: '13.0575000000000000',
      close: '13.4440000000000000',
      volume: '11577270.1700000000000000',
      quoteVolume: 159653805.28869012,
      btcVolume: 7026.0614219511735,
      usdVolume: 159653805.28869012,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '13.4440000000000000',
      high: '14.0235000000000000',
      low: '13.1725000000000000',
      close: '13.5001000000000000',
      volume: '5513708.3800000000000000',
      quoteVolume: 74792637.47296071,
      btcVolume: 3271.2867842547794,
      usdVolume: 74792637.47296071,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '13.5000000000000000',
      high: '13.9697000000000000',
      low: '13.2981000000000000',
      close: '13.4747000000000000',
      volume: '4596156.0500000000000000',
      quoteVolume: 62945458.56621082,
      btcVolume: 2692.9200733659177,
      usdVolume: 62945458.56621082,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '13.4748000000000000',
      high: '13.8000000000000000',
      low: '12.6752000000000000',
      close: '13.0604000000000000',
      volume: '5227497.0200000000000000',
      quoteVolume: 69925342.0503345,
      btcVolume: 2966.1250407952425,
      usdVolume: 69925342.0503345,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '13.0603000000000000',
      high: '13.2700000000000000',
      low: '12.1354000000000000',
      close: '12.3049000000000000',
      volume: '5659600.6800000000000000',
      quoteVolume: 71716093.56996408,
      btcVolume: 3090.609511831206,
      usdVolume: 71716093.56996408,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '12.3028000000000000',
      high: '12.8400000000000000',
      low: '11.8117000000000000',
      close: '12.7842000000000000',
      volume: '6172266.6600000000000000',
      quoteVolume: 76364461.06396206,
      btcVolume: 3308.2084013040967,
      usdVolume: 76364461.06396206,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '12.7811000000000000',
      high: '12.8634000000000000',
      low: '8.0500000000000000',
      close: '10.9736000000000000',
      volume: '15154276.0300000000000000',
      quoteVolume: 168815393.817345,
      btcVolume: 7227.637473629581,
      usdVolume: 168815393.817345,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '10.9730000000000000',
      high: '11.6877000000000000',
      low: '9.8564000000000000',
      close: '11.5724000000000000',
      volume: '14771438.4000000000000000',
      quoteVolume: 157801028.12701663,
      btcVolume: 6807.5861925391055,
      usdVolume: 157801028.12701663,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '11.5705000000000000',
      high: '11.9234000000000000',
      low: '11.0601000000000000',
      close: '11.5543000000000000',
      volume: '7348236.1100000000000000',
      quoteVolume: 84426543.59749623,
      btcVolume: 3504.7630743045383,
      usdVolume: 84426543.59749623,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '11.5640000000000000',
      high: '11.8151000000000000',
      low: '10.6238000000000000',
      close: '10.9809000000000000',
      volume: '9093279.7400000000000000',
      quoteVolume: 101048617.75868994,
      btcVolume: 3982.7085174247964,
      usdVolume: 101048617.75868994,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '10.9840000000000000',
      high: '13.2448000000000000',
      low: '10.4763000000000000',
      close: '12.1541000000000000',
      volume: '18943413.3800000000000000',
      quoteVolume: 226941348.13784474,
      btcVolume: 8381.434369307135,
      usdVolume: 226941348.13784474,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '12.1542000000000000',
      high: '12.9781000000000000',
      low: '11.9710000000000000',
      close: '12.6081000000000000',
      volume: '11270755.2200000000000000',
      quoteVolume: 140279053.6851551,
      btcVolume: 5203.613393033124,
      usdVolume: 140279053.6851551,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '12.6080000000000000',
      high: '13.0351000000000000',
      low: '10.8161000000000000',
      close: '11.7786000000000000',
      volume: '12890847.2600000000000000',
      quoteVolume: 151849176.99178037,
      btcVolume: 5695.149438822997,
      usdVolume: 151849176.99178037,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '11.7786000000000000',
      high: '11.9900000000000000',
      low: '11.0671000000000000',
      close: '11.2435000000000000',
      volume: '7687754.9000000000000000',
      quoteVolume: 88083075.6322653,
      btcVolume: 3132.5608072986693,
      usdVolume: 88083075.6322653,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '11.2437000000000000',
      high: '11.7530000000000000',
      low: '10.9039000000000000',
      close: '11.2364000000000000',
      volume: '6639477.6200000000000000',
      quoteVolume: 75178212.43988732,
      btcVolume: 2609.4447854849886,
      usdVolume: 75178212.43988732,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '11.2364000000000000',
      high: '12.4600000000000000',
      low: '11.0638000000000000',
      close: '11.8477000000000000',
      volume: '8245184.6000000000000000',
      quoteVolume: 97963056.43351899,
      btcVolume: 3350.468743703054,
      usdVolume: 97963056.43351899,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '11.8453000000000000',
      high: '12.4188000000000000',
      low: '11.4303000000000000',
      close: '12.2215000000000000',
      volume: '9617243.6900000000000000',
      quoteVolume: 114480499.1565445,
      btcVolume: 3665.1539501073544,
      usdVolume: 114480499.1565445,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '12.2206000000000000',
      high: '13.9999000000000000',
      low: '11.8355000000000000',
      close: '13.6814000000000000',
      volume: '15927066.3100000000000000',
      quoteVolume: 205085520.50347137,
      btcVolume: 6124.344091873578,
      usdVolume: 205085520.50347137,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '13.6815000000000000',
      high: '15.6440000000000000',
      low: '12.3713000000000000',
      close: '13.5935000000000000',
      volume: '23196077.6800000000000000',
      quoteVolume: 321727867.96833014,
      btcVolume: 10107.698949256002,
      usdVolume: 321727867.96833014,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '13.5962000000000000',
      high: '14.8900000000000000',
      low: '13.0928000000000000',
      close: '14.5162000000000000',
      volume: '14809606.1500000000000000',
      quoteVolume: 208905104.45490494,
      btcVolume: 6485.977306193403,
      usdVolume: 208905104.45490494,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '14.5141000000000000',
      high: '17.2997000000000000',
      low: '14.1427000000000000',
      close: '17.2322000000000000',
      volume: '21280047.1800000000000000',
      quoteVolume: 338322543.10816723,
      btcVolume: 9721.843587977792,
      usdVolume: 338322543.10816723,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '17.2342000000000000',
      high: '17.4100000000000000',
      low: '15.3542000000000000',
      close: '15.9628000000000000',
      volume: '16135019.3000000000000000',
      quoteVolume: 266063447.9945453,
      btcVolume: 6971.427792671456,
      usdVolume: 266063447.9945453,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '15.9659000000000000',
      high: '16.1917000000000000',
      low: '14.1200000000000000',
      close: '15.2381000000000000',
      volume: '14730622.4700000000000000',
      quoteVolume: 225837560.70656046,
      btcVolume: 5699.007296482832,
      usdVolume: 225837560.70656046,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '15.2395000000000000',
      high: '17.8888000000000000',
      low: '15.0029000000000000',
      close: '17.5501000000000000',
      volume: '12807954.5600000000000000',
      quoteVolume: 209112616.22126392,
      btcVolume: 5179.822048220309,
      usdVolume: 209112616.22126392,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '17.5501000000000000',
      high: '18.4386000000000000',
      low: '15.4418000000000000',
      close: '16.2060000000000000',
      volume: '16459987.7000000000000000',
      quoteVolume: 280219098.9046208,
      btcVolume: 7124.706550510726,
      usdVolume: 280219098.9046208,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '16.1998000000000000',
      high: '16.2596000000000000',
      low: '12.5000000000000000',
      close: '14.6393000000000000',
      volume: '21046546.5800000000000000',
      quoteVolume: 299155444.76900715,
      btcVolume: 8793.50832897109,
      usdVolume: 299155444.76900715,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '14.6312000000000000',
      high: '15.4760000000000000',
      low: '13.6701000000000000',
      close: '13.9655000000000000',
      volume: '8959437.3100000000000000',
      quoteVolume: 130225909.90950419,
      btcVolume: 3745.877009935573,
      usdVolume: 130225909.90950419,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '13.9609000000000000',
      high: '16.2356000000000000',
      low: '13.3701000000000000',
      close: '15.9356000000000000',
      volume: '10911746.9200000000000000',
      quoteVolume: 163912112.80508533,
      btcVolume: 4699.829494560408,
      usdVolume: 163912112.80508533,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '15.9396000000000000',
      high: '17.9900000000000000',
      low: '15.3300000000000000',
      close: '17.9804000000000000',
      volume: '13478182.9400000000000000',
      quoteVolume: 222149143.90415877,
      btcVolume: 5733.229551150123,
      usdVolume: 222149143.90415877,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '17.9888000000000000',
      high: '21.4221000000000000',
      low: '17.5355000000000000',
      close: '20.7375000000000000',
      volume: '31054166.3600000000000000',
      quoteVolume: 598915855.4361597,
      btcVolume: 16128.175112869507,
      usdVolume: 598915855.4361597,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '20.7405000000000000',
      high: '22.9600000000000000',
      low: '19.6280000000000000',
      close: '20.1054000000000000',
      volume: '22583400.8600000000000000',
      quoteVolume: 488477166.4998309,
      btcVolume: 13263.862594524382,
      usdVolume: 488477166.4998309,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '20.1003000000000000',
      high: '23.7670000000000000',
      low: '19.3729000000000000',
      close: '23.2819000000000000',
      volume: '21794612.1800000000000000',
      quoteVolume: 467638469.6446607,
      btcVolume: 13093.00975534152,
      usdVolume: 467638469.6446607,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '23.2676000000000000',
      high: '23.6862000000000000',
      low: '21.0600000000000000',
      close: '22.0336000000000000',
      volume: '13776439.8500000000000000',
      quoteVolume: 305061086.4015469,
      btcVolume: 8477.02470472144,
      usdVolume: 305061086.4015469,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '22.0325000000000000',
      high: '22.8248000000000000',
      low: '20.3774000000000000',
      close: '20.5557000000000000',
      volume: '10237213.4000000000000000',
      quoteVolume: 221370711.89643016,
      btcVolume: 6011.617268406959,
      usdVolume: 221370711.89643016,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '20.5437000000000000',
      high: '21.9788000000000000',
      low: '19.0645000000000000',
      close: '21.8482000000000000',
      volume: '12445387.5700000000000000',
      quoteVolume: 253587783.00309002,
      btcVolume: 7255.599211354413,
      usdVolume: 253587783.00309002,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '21.8421000000000000',
      high: '22.0307000000000000',
      low: '18.0611000000000000',
      close: '18.4119000000000000',
      volume: '14800253.5200000000000000',
      quoteVolume: 295655182.9197108,
      btcVolume: 9063.9657818205,
      usdVolume: 295655182.9197108,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '18.4109000000000000',
      high: '22.4725000000000000',
      low: '17.1269000000000000',
      close: '21.5519000000000000',
      volume: '16210103.8000000000000000',
      quoteVolume: 326743324.4039971,
      btcVolume: 10379.828662817843,
      usdVolume: 326743324.4039971,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '21.5467000000000000',
      high: '25.5000000000000000',
      low: '21.1400000000000000',
      close: '24.8046000000000000',
      volume: '14892659.5600000000000000',
      quoteVolume: 357496388.1897744,
      btcVolume: 11065.14109872835,
      usdVolume: 357496388.1897744,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '24.8168000000000000',
      high: '24.9530000000000000',
      low: '23.1612000000000000',
      close: '24.7935000000000000',
      volume: '11903411.3600000000000000',
      quoteVolume: 288283267.4123565,
      btcVolume: 8969.474384440253,
      usdVolume: 288283267.4123565,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '24.7912000000000000',
      high: '25.7824000000000000',
      low: '22.6000000000000000',
      close: '23.3631000000000000',
      volume: '11247196.2700000000000000',
      quoteVolume: 275657899.55470353,
      btcVolume: 8265.386510770037,
      usdVolume: 275657899.55470353,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '22.4042000000000000',
      high: '23.3000000000000000',
      low: '22.3137000000000000',
      close: '23.0981000000000000',
      volume: '2366823.8100000000000000',
      quoteVolume: 54138852.50846183,
      btcVolume: 1682.3671327730394,
      usdVolume: 54138852.50846183,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '23.0914000000000000',
      high: '23.2295000000000000',
      low: '20.2767000000000000',
      close: '20.9586000000000000',
      volume: '8535198.1600000000000000',
      quoteVolume: 182731435.30837923,
      btcVolume: 5920.435835584469,
      usdVolume: 182731435.30837923,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '20.9586000000000000',
      high: '24.4716000000000000',
      low: '20.5734000000000000',
      close: '23.0145000000000000',
      volume: '8468868.5600000000000000',
      quoteVolume: 197396206.1119182,
      btcVolume: 6236.369329316392,
      usdVolume: 197396206.1119182,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '22.9869000000000000',
      high: '24.2400000000000000',
      low: '22.0450000000000000',
      close: '22.7480000000000000',
      volume: '9016409.4900000000000000',
      quoteVolume: 208328902.77130583,
      btcVolume: 5907.088801742898,
      usdVolume: 208328902.77130583,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '22.7480000000000000',
      high: '23.9800000000000000',
      low: '22.1594000000000000',
      close: '23.6399000000000000',
      volume: '6773423.4600000000000000',
      quoteVolume: 156017856.8708368,
      btcVolume: 4581.931097812988,
      usdVolume: 156017856.8708368,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '23.6491000000000000',
      high: '24.6780000000000000',
      low: '22.0603000000000000',
      close: '22.5613000000000000',
      volume: '5361442.2200000000000000',
      quoteVolume: 124076481.29119983,
      btcVolume: 3697.017425316733,
      usdVolume: 124076481.29119983,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '22.5643000000000000',
      high: '23.2000000000000000',
      low: '21.6526000000000000',
      close: '22.8757000000000000',
      volume: '5564509.6300000000000000',
      quoteVolume: 124203143.57187262,
      btcVolume: 3687.457488368662,
      usdVolume: 124203143.57187262,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '22.8752000000000000',
      high: '24.1693000000000000',
      low: '22.7354000000000000',
      close: '23.6582000000000000',
      volume: '6470015.7600000000000000',
      quoteVolume: 150444221.2962143,
      btcVolume: 4337.8794213047395,
      usdVolume: 150444221.2962143,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '23.6578000000000000',
      high: '25.5674000000000000',
      low: '23.5318000000000000',
      close: '25.0870000000000000',
      volume: '8459477.4100000000000000',
      quoteVolume: 208200036.68349785,
      btcVolume: 5709.561538472078,
      usdVolume: 208200036.68349785,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '25.0814000000000000',
      high: '26.1500000000000000',
      low: '22.6174000000000000',
      close: '24.4920000000000000',
      volume: '8629885.3200000000000000',
      quoteVolume: 211063847.95773497,
      btcVolume: 5630.340958663389,
      usdVolume: 211063847.95773497,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '24.4841000000000000',
      high: '27.0000000000000000',
      low: '24.4081000000000000',
      close: '26.3034000000000000',
      volume: '9640790.8100000000000000',
      quoteVolume: 251859619.30684873,
      btcVolume: 6697.035136045025,
      usdVolume: 251859619.30684873,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '26.3023000000000000',
      high: '26.8000000000000000',
      low: '24.2081000000000000',
      close: '25.0231000000000000',
      volume: '7043800.3900000000000000',
      quoteVolume: 178656384.6660932,
      btcVolume: 4511.137870600865,
      usdVolume: 178656384.6660932,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '25.0336000000000000',
      high: '25.2836000000000000',
      low: '22.8100000000000000',
      close: '24.7254000000000000',
      volume: '6499629.3000000000000000',
      quoteVolume: 157729890.3566084,
      btcVolume: 4097.192310639251,
      usdVolume: 157729890.3566084,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '24.7335000000000000',
      high: '25.9800000000000000',
      low: '23.9587000000000000',
      close: '25.4115000000000000',
      volume: '6837186.9500000000000000',
      quoteVolume: 171815141.03402072,
      btcVolume: 4122.278957205255,
      usdVolume: 171815141.03402072,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '25.4070000000000000',
      high: '28.0000000000000000',
      low: '25.0000000000000000',
      close: '27.6034000000000000',
      volume: '9472870.3200000000000000',
      quoteVolume: 246830741.98450413,
      btcVolume: 5292.997466768552,
      usdVolume: 246830741.98450413,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '27.6150000000000000',
      high: '28.6412000000000000',
      low: '25.1227000000000000',
      close: '26.8121000000000000',
      volume: '11717346.8200000000000000',
      quoteVolume: 318420727.69046074,
      btcVolume: 6960.680395575012,
      usdVolume: 318411629.02087176,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '26.8000000000000000',
      high: '28.5705000000000000',
      low: '26.2172000000000000',
      close: '27.8475000000000000',
      volume: '6650588.7300000000000000',
      quoteVolume: 183913909.31249094,
      btcVolume: 3961.896278975156,
      usdVolume: 183913909.31249094,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '27.8645000000000000',
      high: '31.4485000000000000',
      low: '26.6746000000000000',
      close: '30.6265000000000000',
      volume: '9749785.0200000000000000',
      quoteVolume: 284077020.56706005,
      btcVolume: 5975.622685196747,
      usdVolume: 284045968.225476,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '30.6404000000000000',
      high: '34.7573000000000000',
      low: '29.0700000000000000',
      close: '34.1941000000000000',
      volume: '11011345.3200000000000000',
      quoteVolume: 346030518.51178366,
      btcVolume: 7340.703684708217,
      usdVolume: 345996921.8331248,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '34.1969000000000000',
      high: '35.6945000000000000',
      low: '32.3088000000000000',
      close: '33.1565000000000000',
      volume: '8337078.5800000000000000',
      quoteVolume: 283546794.60109466,
      btcVolume: 5868.713681268827,
      usdVolume: 283546794.60109466,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '33.1555000000000000',
      high: '34.9252000000000000',
      low: '27.5000000000000000',
      close: '32.5899000000000000',
      volume: '10469894.2400000000000000',
      quoteVolume: 335112228.9163464,
      btcVolume: 7001.374521254515,
      usdVolume: 333251261.0761585,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '32.5853000000000000',
      high: '33.4269000000000000',
      low: '30.7840000000000000',
      close: '31.9632000000000000',
      volume: '6985113.3100000000000000',
      quoteVolume: 224245398.9060409,
      btcVolume: 4510.950361788866,
      usdVolume: 220012561.30996123,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '31.9630000000000000',
      high: '32.6705000000000000',
      low: '30.0000000000000000',
      close: '32.2324000000000000',
      volume: '5259067.4900000000000000',
      quoteVolume: 166231266.71195933,
      btcVolume: 3258.0237581539295,
      usdVolume: 166231266.71195933,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '32.2256000000000000',
      high: '33.5961000000000000',
      low: '31.7785000000000000',
      close: '32.4801000000000000',
      volume: '5451118.6700000000000000',
      quoteVolume: 177745245.9786863,
      btcVolume: 3426.75446906022,
      usdVolume: 177745245.9786863,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '32.4727000000000000',
      high: '35.5072000000000000',
      low: '31.3799000000000000',
      close: '34.6846000000000000',
      volume: '8360898.6500000000000000',
      quoteVolume: 281294280.39428127,
      btcVolume: 5294.475712342659,
      usdVolume: 281294280.39428127,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '34.6846000000000000',
      high: '36.9307000000000000',
      low: '32.0000000000000000',
      close: '33.9387000000000000',
      volume: '10054962.6900000000000000',
      quoteVolume: 352536639.04229677,
      btcVolume: 6272.494218045403,
      usdVolume: 352532151.53390276,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '33.9293000000000000',
      high: '35.2918000000000000',
      low: '33.2712000000000000',
      close: '34.0915000000000000',
      volume: '5179283.2400000000000000',
      quoteVolume: 177022601.01385555,
      btcVolume: 3109.2651388863383,
      usdVolume: 177022601.01385555,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '34.0916000000000000',
      high: '34.2185000000000000',
      low: '25.2312000000000000',
      close: '31.4542000000000000',
      volume: '10543122.8600000000000000',
      quoteVolume: 319683313.051021,
      btcVolume: 5634.770333904902,
      usdVolume: 301180710.3156824,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '31.4482000000000000',
      high: '31.5822000000000000',
      low: '21.6261000000000000',
      close: '25.9249000000000000',
      volume: '21560485.0600000000000000',
      quoteVolume: 554568596.2290249,
      btcVolume: 11275.518366580607,
      usdVolume: 545229229.464444,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '25.9573000000000000',
      high: '28.8292000000000000',
      low: '24.5050000000000000',
      close: '28.0851000000000000',
      volume: '6709381.7900000000000000',
      quoteVolume: 183384362.28603324,
      btcVolume: 3687.2224811202454,
      usdVolume: 183355744.81425026,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '28.0737000000000000',
      high: '28.6802000000000000',
      low: '24.2509000000000000',
      close: '24.7144000000000000',
      volume: '7893277.6100000000000000',
      quoteVolume: 214328348.89058003,
      btcVolume: 4308.799154273922,
      usdVolume: 214328348.89058003,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '24.7144000000000000',
      high: '26.9906000000000000',
      low: '23.6574000000000000',
      close: '25.1862000000000000',
      volume: '9016978.7100000000000000',
      quoteVolume: 226299006.61160633,
      btcVolume: 4860.302348071939,
      usdVolume: 226070729.98285314,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '25.1650000000000000',
      high: '27.4472000000000000',
      low: '24.8545000000000000',
      close: '26.1896000000000000',
      volume: '7622190.2300000000000000',
      quoteVolume: 197306897.2042366,
      btcVolume: 4194.093858773107,
      usdVolume: 197306897.2042366,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '26.1941000000000000',
      high: '26.7624000000000000',
      low: '22.8578000000000000',
      close: '24.7016000000000000',
      volume: '7513099.0000000000000000',
      quoteVolume: 183181771.37759796,
      btcVolume: 4101.6732584746605,
      usdVolume: 183181771.37759796,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '24.6954000000000000',
      high: '27.7326000000000000',
      low: '24.5758000000000000',
      close: '27.7116000000000000',
      volume: '11662106.9800000000000000',
      quoteVolume: 309292384.27149624,
      btcVolume: 6431.989232060969,
      usdVolume: 309292384.27149624,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '27.7187000000000000',
      high: '30.5251000000000000',
      low: '27.1757000000000000',
      close: '28.4769000000000000',
      volume: '9706793.4600000000000000',
      quoteVolume: 280186454.4698888,
      btcVolume: 5764.268832849585,
      usdVolume: 280186454.4698888,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '28.4640000000000000',
      high: '31.4300000000000000',
      low: '28.1272000000000000',
      close: '29.8500000000000000',
      volume: '6412989.4500000000000000',
      quoteVolume: 193702490.28628224,
      btcVolume: 3844.793474650542,
      usdVolume: 193702490.28628224,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '29.8334000000000000',
      high: '30.5731000000000000',
      low: '27.1891000000000000',
      close: '27.6943000000000000',
      volume: '6789296.5900000000000000',
      quoteVolume: 196271455.16863889,
      btcVolume: 3979.194700761396,
      usdVolume: 196249322.53349993,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '27.6856000000000000',
      high: '28.2338000000000000',
      low: '25.6483000000000000',
      close: '27.8343000000000000',
      volume: '5832853.2100000000000000',
      quoteVolume: 156056362.04028454,
      btcVolume: 3286.3488640127302,
      usdVolume: 156056362.04028454,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '27.8195000000000000',
      high: '28.3985000000000000',
      low: '26.2889000000000000',
      close: '28.1479000000000000',
      volume: '3381150.1600000000000000',
      quoteVolume: 92804331.2379402,
      btcVolume: 1896.9852933650902,
      usdVolume: 91553822.80733496,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '28.1608000000000000',
      high: '29.1258000000000000',
      low: '27.3859000000000000',
      close: '28.5932000000000000',
      volume: '4599258.0200000000000000',
      quoteVolume: 130068525.6266061,
      btcVolume: 2589.6634533421866,
      usdVolume: 130068525.6266061,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '28.5918000000000000',
      high: '31.8469000000000000',
      low: '28.2652000000000000',
      close: '31.8469000000000000',
      volume: '6681348.4500000000000000',
      quoteVolume: 199010644.3594535,
      btcVolume: 3922.101818010387,
      usdVolume: 199010644.3594535,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '31.8227000000000000',
      high: '32.0000000000000000',
      low: '30.3639000000000000',
      close: '31.4914000000000000',
      volume: '4049655.7000000000000000',
      quoteVolume: 126227587.21440111,
      btcVolume: 2342.082973005306,
      usdVolume: 126227587.21440111,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '31.4914000000000000',
      high: '31.7934000000000000',
      low: '29.2545000000000000',
      close: '29.9036000000000000',
      volume: '4027599.8200000000000000',
      quoteVolume: 122397560.04919197,
      btcVolume: 2207.964000897798,
      usdVolume: 122396451.37618196,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '29.8743000000000000',
      high: '30.5285000000000000',
      low: '28.5516000000000000',
      close: '30.1166000000000000',
      volume: '5024365.7100000000000000',
      quoteVolume: 148147003.90674606,
      btcVolume: 2628.6223761839137,
      usdVolume: 148147003.90674606,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '30.1227000000000000',
      high: '30.4110000000000000',
      low: '27.3717000000000000',
      close: '28.1489000000000000',
      volume: '4477144.4100000000000000',
      quoteVolume: 128936166.34822313,
      btcVolume: 1583.1266319722276,
      usdVolume: 89974102.5483421,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '27.7838000000000000',
      high: '30.5700000000000000',
      low: '26.9326000000000000',
      close: '29.7384000000000000',
      volume: '5584451.5200000000000000',
      quoteVolume: 162487581.38361448,
      btcVolume: 2765.323829329903,
      usdVolume: 162487581.38361448,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '29.7506000000000000',
      high: '30.2000000000000000',
      low: '28.5108000000000000',
      close: '28.5469000000000000',
      volume: '3121480.3400000000000000',
      quoteVolume: 91263041.59569518,
      btcVolume: 1507.0857507872481,
      usdVolume: 91064805.56460914,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '28.5310000000000000',
      high: '29.4217000000000000',
      low: '26.8000000000000000',
      close: '27.5091000000000000',
      volume: '5236280.7600000000000000',
      quoteVolume: 145357316.8911458,
      btcVolume: 2536.539090783008,
      usdVolume: 145357316.8911458,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '27.5006000000000000',
      high: '28.1883000000000000',
      low: '26.3975000000000000',
      close: '27.9099000000000000',
      volume: '3202429.1200000000000000',
      quoteVolume: 87907886.1985268,
      btcVolume: 1592.4117076930133,
      usdVolume: 87865565.54260874,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '27.9100000000000000',
      high: '31.2000000000000000',
      low: '27.3027000000000000',
      close: '31.0579000000000000',
      volume: '8119714.6200000000000000',
      quoteVolume: 240849861.95782134,
      btcVolume: 4319.501158108978,
      usdVolume: 240849861.95782134,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '31.0579000000000000',
      high: '31.3588000000000000',
      low: '29.0000000000000000',
      close: '29.3949000000000000',
      volume: '4982847.5300000000000000',
      quoteVolume: 149502922.10217696,
      btcVolume: 2552.5916810317053,
      usdVolume: 149502922.10217696,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '29.3929000000000000',
      high: '30.5510000000000000',
      low: '28.6802000000000000',
      close: '29.7770000000000000',
      volume: '3239965.5200000000000000',
      quoteVolume: 96988651.02827796,
      btcVolume: 1661.0995932340902,
      usdVolume: 96861045.95362605,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '29.7866000000000000',
      high: '31.3809000000000000',
      low: '29.5800000000000000',
      close: '29.6465000000000000',
      volume: '2540903.5400000000000000',
      quoteVolume: 77772991.47447608,
      btcVolume: 1323.149915780175,
      usdVolume: 77770316.54868808,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '29.6564000000000000',
      high: '30.0800000000000000',
      low: '28.5817000000000000',
      close: '29.2097000000000000',
      volume: '2532521.5100000000000000',
      quoteVolume: 74287913.29808004,
      btcVolume: 1297.2908602936614,
      usdVolume: 74260278.37190706,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '29.2117000000000000',
      high: '29.4444000000000000',
      low: '27.1362000000000000',
      close: '27.2431000000000000',
      volume: '3564715.9100000000000000',
      quoteVolume: 101808090.24197517,
      btcVolume: 1809.4616360427897,
      usdVolume: 101741677.36866613,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '27.2551000000000000',
      high: '27.9351000000000000',
      low: '26.6283000000000000',
      close: '26.7813000000000000',
      volume: '3659416.4600000000000000',
      quoteVolume: 99812413.47572565,
      btcVolume: 1215.8619127352338,
      usdVolume: 66191594.700597785,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '26.7831000000000000',
      high: '27.8814000000000000',
      low: '24.0404000000000000',
      close: '24.8684000000000000',
      volume: '4061588.6900000000000000',
      quoteVolume: 106692601.87539235,
      btcVolume: 1959.2264580328283,
      usdVolume: 106692601.87539235,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '24.8679000000000000',
      high: '26.6401000000000000',
      low: '24.3665000000000000',
      close: '25.5350000000000000',
      volume: '4189607.3400000000000000',
      quoteVolume: 106697254.9266262,
      btcVolume: 1689.745893902917,
      usdVolume: 87698677.2009626,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '25.5300000000000000',
      high: '27.4000000000000000',
      low: '25.3993000000000000',
      close: '27.3927000000000000',
      volume: '3356222.6100000000000000',
      quoteVolume: 88633668.99963559,
      btcVolume: 1599.8484710312273,
      usdVolume: 85435262.31795225,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '27.3988000000000000',
      high: '27.4982000000000000',
      low: '26.0030000000000000',
      close: '26.8766000000000000',
      volume: '2032627.2400000000000000',
      quoteVolume: 54412158.06017271,
      btcVolume: 986.9278333127505,
      usdVolume: 54406973.49173972,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '26.8765000000000000',
      high: '27.6624000000000000',
      low: '26.0889000000000000',
      close: '26.6588000000000000',
      volume: '2205293.2300000000000000',
      quoteVolume: 59418251.07729596,
      btcVolume: 1064.179617444524,
      usdVolume: 59406433.69876095,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '26.6576000000000000',
      high: '28.6203000000000000',
      low: '26.3886000000000000',
      close: '28.0357000000000000',
      volume: '3528308.7300000000000000',
      quoteVolume: 97966057.5120884,
      btcVolume: 1709.0159853740718,
      usdVolume: 97916578.44213544,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '28.0356000000000000',
      high: '28.5735000000000000',
      low: '27.4460000000000000',
      close: '27.8301000000000000',
      volume: '2521474.6200000000000000',
      quoteVolume: 70923243.6872522,
      btcVolume: 1213.622206853287,
      usdVolume: 70920571.39197218,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '27.8300000000000000',
      high: '29.4401000000000000',
      low: '26.1800000000000000',
      close: '29.4167000000000000',
      volume: '4353005.8100000000000000',
      quoteVolume: 120857861.76495478,
      btcVolume: 2051.9849029876646,
      usdVolume: 120365548.36152953,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '29.4168000000000000',
      high: '31.1250000000000000',
      low: '29.3143000000000000',
      close: '30.0684000000000000',
      volume: '5308598.1000000000000000',
      quoteVolume: 159884612.16588667,
      btcVolume: 2714.508183126401,
      usdVolume: 159764276.83964464,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '30.0808000000000000',
      high: '32.5555000000000000',
      low: '29.5463000000000000',
      close: '32.4864000000000000',
      volume: '4245194.7400000000000000',
      quoteVolume: 131458201.24949433,
      btcVolume: 2219.951784391892,
      usdVolume: 131448573.62730134,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '32.4867000000000000',
      high: '33.1707000000000000',
      low: '29.1256000000000000',
      close: '29.2930000000000000',
      volume: '5326728.5500000000000000',
      quoteVolume: 167961809.66658044,
      btcVolume: 1795.4395891216325,
      usdVolume: 105857363.34651382,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '29.2930000000000000',
      high: '31.4643000000000000',
      low: '28.6801000000000000',
      close: '30.7471000000000000',
      volume: '2738370.5800000000000000',
      quoteVolume: 83118846.51577824,
      btcVolume: 1249.3258134334203,
      usdVolume: 72051166.9085144,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '30.7473000000000000',
      high: '32.6399000000000000',
      low: '29.6179000000000000',
      close: '32.3791000000000000',
      volume: '4976271.3600000000000000',
      quoteVolume: 155152448.59764713,
      btcVolume: 1991.659027501243,
      usdVolume: 115877633.66876267,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '32.3791000000000000',
      high: '34.9000000000000000',
      low: '31.3074000000000000',
      close: '34.7250000000000000',
      volume: '7244775.1200000000000000',
      quoteVolume: 240433366.91596004,
      btcVolume: 3797.508343023463,
      usdVolume: 221680600.84789953,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '34.7295000000000000',
      high: '35.1200000000000000',
      low: '30.2286000000000000',
      close: '31.1090000000000000',
      volume: '7241883.5600000000000000',
      quoteVolume: 235343305.11428273,
      btcVolume: 3884.6148457101895,
      usdVolume: 221471220.7998364,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '31.1008000000000000',
      high: '32.8999000000000000',
      low: '30.9466000000000000',
      close: '32.7884000000000000',
      volume: '3371094.7200000000000000',
      quoteVolume: 108183402.49988818,
      btcVolume: 1171.9067624824738,
      usdVolume: 66948808.547933206,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '32.7870000000000000',
      high: '33.2876000000000000',
      low: '31.2348000000000000',
      close: '31.5893000000000000',
      volume: '2728512.6100000000000000',
      quoteVolume: 87716711.54413132,
      btcVolume: 1274.446364485902,
      usdVolume: 74249858.40623835,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '31.5961000000000000',
      high: '33.0000000000000000',
      low: '31.0000000000000000',
      close: '31.8944000000000000',
      volume: '3545699.4300000000000000',
      quoteVolume: 113928069.33940087,
      btcVolume: 1828.0827861744679,
      usdVolume: 109414242.01156527,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '31.8980000000000000',
      high: '34.4397000000000000',
      low: '31.7283000000000000',
      close: '33.8811000000000000',
      volume: '4949345.2000000000000000',
      quoteVolume: 164105171.51139703,
      btcVolume: 2677.3634365869484,
      usdVolume: 160031551.35507315,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '33.8811000000000000',
      high: '34.1468000000000000',
      low: '31.8601000000000000',
      close: '33.1080000000000000',
      volume: '3732041.9700000000000000',
      quoteVolume: 123252667.28419851,
      btcVolume: 1636.5603716794567,
      usdVolume: 98349366.92374945,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '33.1023000000000000',
      high: '35.8645000000000000',
      low: '32.3842000000000000',
      close: '35.5217000000000000',
      volume: '5624044.8000000000000000',
      quoteVolume: 192994936.86974585,
      btcVolume: 2998.5339331543933,
      usdVolume: 188012549.6792243,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '35.5416000000000000',
      high: '41.9901000000000000',
      low: '35.0000000000000000',
      close: '41.3641000000000000',
      volume: '13418401.1400000000000000',
      quoteVolume: 506888645.13665795,
      btcVolume: 6514.2283925472175,
      usdVolume: 412737241.69925255,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '41.3371000000000000',
      high: '44.3300000000000000',
      low: '39.6638000000000000',
      close: '42.5601000000000000',
      volume: '12581718.5700000000000000',
      quoteVolume: 528214652.25350446,
      btcVolume: 7445.637896817438,
      usdVolume: 467700360.8493485,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '42.5654000000000000',
      high: '43.2902000000000000',
      low: '38.5273000000000000',
      close: '41.9861000000000000',
      volume: '9648189.8700000000000000',
      quoteVolume: 396462710.2078966,
      btcVolume: 5702.375812210699,
      usdVolume: 350891882.7305064,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '41.9797000000000000',
      high: '44.1116000000000000',
      low: '39.8304000000000000',
      close: '39.9137000000000000',
      volume: '6757853.5600000000000000',
      quoteVolume: 284759254.2188944,
      btcVolume: 2680.560705167462,
      usdVolume: 164429323.88150203,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '39.9135000000000000',
      high: '40.3436000000000000',
      low: '29.0000000000000000',
      close: '39.0799000000000000',
      volume: '14640165.5300000000000000',
      quoteVolume: 530375955.0032846,
      btcVolume: 9097.744625141062,
      usdVolume: 507371033.8404291,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '39.0858000000000000',
      high: '41.9900000000000000',
      low: '34.8144000000000000',
      close: '35.7359000000000000',
      volume: '12978321.7600000000000000',
      quoteVolume: 497670097.2335755,
      btcVolume: 6174.098440246101,
      usdVolume: 347089895.7808836,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '35.7504000000000000',
      high: '39.3901000000000000',
      low: '33.1933000000000000',
      close: '38.9954000000000000',
      volume: '11956908.1000000000000000',
      quoteVolume: 437511825.9164905,
      btcVolume: 7196.936350920713,
      usdVolume: 398982378.77708787,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '38.9983000000000000',
      high: '39.4872000000000000',
      low: '35.8933000000000000',
      close: '36.2274000000000000',
      volume: '6732313.3700000000000000',
      quoteVolume: 253633014.1501259,
      btcVolume: 3719.7137046466846,
      usdVolume: 206621089.2032104,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '36.2364000000000000',
      high: '40.8200000000000000',
      low: '33.7859000000000000',
      close: '35.1306000000000000',
      volume: '11519039.9400000000000000',
      quoteVolume: 432182126.09576267,
      btcVolume: 2025.7054502568894,
      usdVolume: 107167666.04858308,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '35.1256000000000000',
      high: '35.9643000000000000',
      low: '30.2771000000000000',
      close: '35.2121000000000000',
      volume: '14134304.6300000000000000',
      quoteVolume: 465142368.4095787,
      btcVolume: 4763.5180098543515,
      usdVolume: 237164782.14820454,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '35.2121000000000000',
      high: '35.3033000000000000',
      low: '31.0000000000000000',
      close: '31.2443000000000000',
      volume: '6295773.8600000000000000',
      quoteVolume: 206376774.11149526,
      btcVolume: 3817.3264985910882,
      usdVolume: 190343281.2332728,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '31.2370000000000000',
      high: '33.4320000000000000',
      low: '29.8010000000000000',
      close: '31.6920000000000000',
      volume: '4274733.3210000000000000',
      quoteVolume: 135910873.76321998,
      btcVolume: 1405.9735040050004,
      usdVolume: 69698982.35040447,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '31.6900000000000000',
      high: '35.2750000000000000',
      low: '31.3630000000000000',
      close: '35.1300000000000000',
      volume: '5764275.4540000000000000',
      quoteVolume: 196917985.91491917,
      btcVolume: 1940.686409307913,
      usdVolume: 102715801.07328665,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '35.1120000000000000',
      high: '37.6650000000000000',
      low: '34.4830000000000000',
      close: '36.7880000000000000',
      volume: '5453945.1760000000000000',
      quoteVolume: 197434451.9676538,
      btcVolume: 3508.307389527573,
      usdVolume: 191567073.98651674,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '36.7860000000000000',
      high: '37.8080000000000000',
      low: '34.9010000000000000',
      close: '36.2330000000000000',
      volume: '5218990.7380000000000000',
      quoteVolume: 188499275.87359715,
      btcVolume: 3443.115782242929,
      usdVolume: 188499275.87359715,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '36.2530000000000000',
      high: '37.4500000000000000',
      low: '35.0000000000000000',
      close: '36.4670000000000000',
      volume: '4960983.1160000000000000',
      quoteVolume: 178985701.04202038,
      btcVolume: 3326.1200982145674,
      usdVolume: 178985701.04202038,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '36.4650000000000000',
      high: '38.7900000000000000',
      low: '35.8270000000000000',
      close: '38.1000000000000000',
      volume: '5036107.6020000000000000',
      quoteVolume: 189862115.18069306,
      btcVolume: 2169.133779042782,
      usdVolume: 119700732.92635407,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '38.1010000000000000',
      high: '41.2700000000000000',
      low: '37.4280000000000000',
      close: '41.2570000000000000',
      volume: '5223059.2340000000000000',
      quoteVolume: 205464629.83196887,
      btcVolume: 1552.158704895812,
      usdVolume: 89600807.00053962,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '41.2490000000000000',
      high: '41.4600000000000000',
      low: '38.6900000000000000',
      close: '39.4610000000000000',
      volume: '5198773.9980000000000000',
      quoteVolume: 206970460.92982715,
      btcVolume: 2174.314081160398,
      usdVolume: 123461594.93853049,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '39.4610000000000000',
      high: '42.7280000000000000',
      low: '39.1660000000000000',
      close: '42.0520000000000000',
      volume: '6010571.0640000000000000',
      quoteVolume: 246579333.6021091,
      btcVolume: 4045.734938153716,
      usdVolume: 233712903.15058511,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '42.0600000000000000',
      high: '48.5400000000000000',
      low: '38.6340000000000000',
      close: '45.5270000000000000',
      volume: '19237312.3900000000000000',
      quoteVolume: 845809208.6302068,
      btcVolume: 10465.55258596805,
      usdVolume: 575221269.0704849,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '45.5080000000000000',
      high: '51.2000000000000000',
      low: '44.8650000000000000',
      close: '49.5750000000000000',
      volume: '13527073.0750000000000000',
      quoteVolume: 657942604.5017196,
      btcVolume: 10857.379037079556,
      usdVolume: 601168078.6758198,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '49.5760000000000000',
      high: '49.9000000000000000',
      low: '45.3430000000000000',
      close: '47.1540000000000000',
      volume: '9482621.1830000000000000',
      quoteVolume: 450955107.1350383,
      btcVolume: 7582.913753017384,
      usdVolume: 430590815.2935991,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '47.1480000000000000',
      high: '51.9690000000000000',
      low: '45.6660000000000000',
      close: '49.1290000000000000',
      volume: '10577050.1670000000000000',
      quoteVolume: 515234804.58814085,
      btcVolume: 8494.174715359122,
      usdVolume: 484259627.57465845,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '49.1340000000000000',
      high: '50.2650000000000000',
      low: '46.5000000000000000',
      close: '48.5970000000000000',
      volume: '5883822.5840000000000000',
      quoteVolume: 283989339.17529905,
      btcVolume: 4686.917318376704,
      usdVolume: 273537936.3731609,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '48.6070000000000000',
      high: '52.4260000000000000',
      low: '45.8300000000000000',
      close: '52.2690000000000000',
      volume: '8588355.4620000000000000',
      quoteVolume: 426164154.7903979,
      btcVolume: 7030.02275298761,
      usdVolume: 405961264.1488087,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '52.2770000000000000',
      high: '53.0000000000000000',
      low: '41.1600000000000000',
      close: '46.3340000000000000',
      volume: '9923820.9180000000000000',
      quoteVolume: 486677346.87598133,
      btcVolume: 6276.730764781867,
      usdVolume: 357244261.1626152,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '46.3260000000000000',
      high: '49.0000000000000000',
      low: '44.8000000000000000',
      close: '48.8260000000000000',
      volume: '6709350.2410000000000000',
      quoteVolume: 312517087.9033863,
      btcVolume: 4159.683255202934,
      usdVolume: 232124988.1499912,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '48.8050000000000000',
      high: '49.8360000000000000',
      low: '40.9140000000000000',
      close: '41.0070000000000000',
      volume: '8272510.7590000000000000',
      quoteVolume: 383764473.964816,
      btcVolume: 6696.055930825579,
      usdVolume: 367665436.85888445,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '41.5410000000000000',
      high: '44.9580000000000000',
      low: '40.0660000000000000',
      close: '43.2870000000000000',
      volume: '9769701.1020000000000000',
      quoteVolume: 415729412.96724325,
      btcVolume: 7858.229520494778,
      usdVolume: 389116685.6106139,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '43.2730000000000000',
      high: '47.8750000000000000',
      low: '42.9350000000000000',
      close: '46.5670000000000000',
      volume: '6297019.0800000000000000',
      quoteVolume: 286301712.8153783,
      btcVolume: 5462.904943375931,
      usdVolume: 274286488.952303,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '46.5580000000000000',
      high: '47.8000000000000000',
      low: '40.9750000000000000',
      close: '41.4920000000000000',
      volume: '9851823.9350000000000000',
      quoteVolume: 432218709.2421017,
      btcVolume: 8544.101354516299,
      usdVolume: 415295386.7222744,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '41.5120000000000000',
      high: '44.9010000000000000',
      low: '37.9440000000000000',
      close: '40.6700000000000000',
      volume: '9745811.4610000000000000',
      quoteVolume: 402376827.20252913,
      btcVolume: 7873.992844695695,
      usdVolume: 370599721.498782,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '40.6620000000000000',
      high: '40.6740000000000000',
      low: '35.0680000000000000',
      close: '37.1770000000000000',
      volume: '10362229.7890000000000000',
      quoteVolume: 390099667.8940053,
      btcVolume: 8288.179682427983,
      usdVolume: 364544533.9681069,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '37.1760000000000000',
      high: '44.6900000000000000',
      low: '36.6550000000000000',
      close: '42.5660000000000000',
      volume: '11232354.3090000000000000',
      quoteVolume: 458226371.3004289,
      btcVolume: 9965.215932435603,
      usdVolume: 436152928.96179956,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '42.5550000000000000',
      high: '43.5000000000000000',
      low: '21.0000000000000000',
      close: '26.2270000000000000',
      volume: '20210187.3060000000000000',
      quoteVolume: 664059850.4303083,
      btcVolume: 13824.814824954283,
      usdVolume: 538586189.5562819,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '26.2270000000000000',
      high: '33.0500000000000000',
      low: '22.3500000000000000',
      close: '30.6060000000000000',
      volume: '20323256.5380000000000000',
      quoteVolume: 577592046.0905249,
      btcVolume: 10784.808827983394,
      usdVolume: 422558516.6124092,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '30.6330000000000000',
      high: '31.9450000000000000',
      low: '22.0190000000000000',
      close: '25.5460000000000000',
      volume: '13068081.0750000000000000',
      quoteVolume: 353993914.03694314,
      btcVolume: 6932.648993527497,
      usdVolume: 266425708.4751106,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '25.5480000000000000',
      high: '26.2000000000000000',
      low: '22.2250000000000000',
      close: '23.2960000000000000',
      volume: '13032278.1370000000000000',
      quoteVolume: 314829379.610612,
      btcVolume: 5695.951410606267,
      usdVolume: 211218954.28534862,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '23.2930000000000000',
      high: '24.3680000000000000',
      low: '15.0000000000000000',
      close: '19.8440000000000000',
      volume: '28816332.7880000000000000',
      quoteVolume: 543985798.5938362,
      btcVolume: 15126.42507829283,
      usdVolume: 517948800.9596924,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '19.8490000000000000',
      high: '26.4310000000000000',
      low: '19.6770000000000000',
      close: '26.4120000000000000',
      volume: '18217984.6480000000000000',
      quoteVolume: 425942553.28623766,
      btcVolume: 10844.812716329057,
      usdVolume: 401451530.6456157,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '26.4000000000000000',
      high: '27.6440000000000000',
      low: '23.6730000000000000',
      close: '27.5030000000000000',
      volume: '13594283.8180000000000000',
      quoteVolume: 349644756.5528623,
      btcVolume: 8716.278447151888,
      usdVolume: 331359167.24137896,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '27.5050000000000000',
      high: '34.2850000000000000',
      low: '26.8470000000000000',
      close: '34.0180000000000000',
      volume: '14862009.4600000000000000',
      quoteVolume: 456256699.2802189,
      btcVolume: 11201.464045901757,
      usdVolume: 439295034.0456097,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '34.0300000000000000',
      high: '35.3340000000000000',
      low: '30.0330000000000000',
      close: '31.7320000000000000',
      volume: '14928881.5110000000000000',
      quoteVolume: 489547108.0042761,
      btcVolume: 12329.95986455298,
      usdVolume: 477724155.1958763,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '31.7230000000000000',
      high: '32.1850000000000000',
      low: '26.8210000000000000',
      close: '27.8780000000000000',
      volume: '18297258.3920000000000000',
      quoteVolume: 533840739.506926,
      btcVolume: 13937.929959889794,
      usdVolume: 508564072.9063156,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '27.8720000000000000',
      high: '29.7340000000000000',
      low: '24.1200000000000000',
      close: '25.3650000000000000',
      volume: '11813482.7710000000000000',
      quoteVolume: 314260807.1404808,
      btcVolume: 8707.412591102062,
      usdVolume: 305391847.5639692,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '25.3770000000000000',
      high: '29.1680000000000000',
      low: '23.7000000000000000',
      close: '26.8180000000000000',
      volume: '11542374.4850000000000000',
      quoteVolume: 311943247.68354595,
      btcVolume: 8749.034315009025,
      usdVolume: 309860777.6783972,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '26.8130000000000000',
      high: '32.1590000000000000',
      low: '25.5550000000000000',
      close: '32.0980000000000000',
      volume: '11473684.1530000000000000',
      quoteVolume: 330074023.8033171,
      btcVolume: 9049.198752953676,
      usdVolume: 328513729.3170985,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '32.0980000000000000',
      high: '32.6500000000000000',
      low: '29.0350000000000000',
      close: '30.7190000000000000',
      volume: '7595288.2510000000000000',
      quoteVolume: 231579652.6416101,
      btcVolume: 6228.039389492023,
      usdVolume: 228605999.2276237,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '30.7290000000000000',
      high: '32.2180000000000000',
      low: '29.7000000000000000',
      close: '30.6700000000000000',
      volume: '5992710.5570000000000000',
      quoteVolume: 184925689.9538908,
      btcVolume: 4910.509911672765,
      usdVolume: 183636440.3796502,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '30.6690000000000000',
      high: '32.8560000000000000',
      low: '30.0000000000000000',
      close: '32.1980000000000000',
      volume: '6926586.1530000000000000',
      quoteVolume: 220195095.12678242,
      btcVolume: 5686.208264256343,
      usdVolume: 219529228.41781366,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '32.1990000000000000',
      high: '32.2670000000000000',
      low: '27.1720000000000000',
      close: '28.8260000000000000',
      volume: '10756129.4560000000000000',
      quoteVolume: 310574364.8097001,
      btcVolume: 8079.12819316762,
      usdVolume: 299137467.401279,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '28.8290000000000000',
      high: '30.1860000000000000',
      low: '26.0440000000000000',
      close: '27.1290000000000000',
      volume: '8021172.9850000000000000',
      quoteVolume: 224556115.0710708,
      btcVolume: 6072.610138454598,
      usdVolume: 220742793.7734021,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '27.1270000000000000',
      high: '28.1900000000000000',
      low: '26.8180000000000000',
      close: '27.5320000000000000',
      volume: '4071729.1110000000000000',
      quoteVolume: 111593094.49793832,
      btcVolume: 3094.66184741806,
      usdVolume: 111191327.61157948,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '27.5300000000000000',
      high: '28.6930000000000000',
      low: '24.2340000000000000',
      close: '24.5840000000000000',
      volume: '7593474.6300000000000000',
      quoteVolume: 203546009.9958056,
      btcVolume: 5664.404814150535,
      usdVolume: 201030726.639649,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '24.5740000000000000',
      high: '25.0980000000000000',
      low: '21.3340000000000000',
      close: '24.0760000000000000',
      volume: '12533550.4740000000000000',
      quoteVolume: 295383608.8363167,
      btcVolume: 8748.60414936731,
      usdVolume: 286178492.67321956,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '24.0790000000000000',
      high: '25.6260000000000000',
      low: '22.4410000000000000',
      close: '25.5850000000000000',
      volume: '10755352.9180000000000000',
      quoteVolume: 259836017.6864675,
      btcVolume: 7275.701651707724,
      usdVolume: 253653794.22880164,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '25.5890000000000000',
      high: '25.7800000000000000',
      low: '22.7210000000000000',
      close: '23.1140000000000000',
      volume: '8385905.0280000000000000',
      quoteVolume: 201718092.68922827,
      btcVolume: 5398.213856768574,
      usdVolume: 199614065.45911995,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '23.1170000000000000',
      high: '23.3740000000000000',
      low: '21.0000000000000000',
      close: '21.4780000000000000',
      volume: '7674813.3900000000000000',
      quoteVolume: 172255303.31648567,
      btcVolume: 4653.500277683774,
      usdVolume: 171879481.40592545,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '21.4750000000000000',
      high: '22.2870000000000000',
      low: '20.0000000000000000',
      close: '21.6700000000000000',
      volume: '8849284.6130000000000000',
      quoteVolume: 186854318.51986963,
      btcVolume: 5161.93685251144,
      usdVolume: 184384310.62285846,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '21.6710000000000000',
      high: '23.8860000000000000',
      low: '20.3960000000000000',
      close: '23.3120000000000000',
      volume: '8516193.5180000000000000',
      quoteVolume: 184993026.3649717,
      btcVolume: 5018.546755318045,
      usdVolume: 183990625.90337497,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '23.3140000000000000',
      high: '25.2800000000000000',
      low: '22.8370000000000000',
      close: '24.9730000000000000',
      volume: '8598594.0080000000000000',
      quoteVolume: 205633987.74170867,
      btcVolume: 5124.726546014837,
      usdVolume: 204447032.09525958,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '24.9670000000000000',
      high: '26.4580000000000000',
      low: '24.3760000000000000',
      close: '24.4820000000000000',
      volume: '7585322.2020000000000000',
      quoteVolume: 193173661.50221747,
      btcVolume: 4783.978518048711,
      usdVolume: 192339881.37543938,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '24.4800000000000000',
      high: '24.6400000000000000',
      low: '22.9180000000000000',
      close: '23.0440000000000000',
      volume: '7495769.8280000000000000',
      quoteVolume: 178312029.61373052,
      btcVolume: 4478.7932855389,
      usdVolume: 176264709.6132166,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '23.0420000000000000',
      high: '24.4450000000000000',
      low: '22.8100000000000000',
      close: '23.3960000000000000',
      volume: '5230276.4320000000000000',
      quoteVolume: 123641681.8274587,
      btcVolume: 3158.8471853964566,
      usdVolume: 121967618.36204572,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '23.4010000000000000',
      high: '23.4250000000000000',
      low: '20.5260000000000000',
      close: '21.2330000000000000',
      volume: '6979309.1900000000000000',
      quoteVolume: 152221963.2704614,
      btcVolume: 4077.4579812512793,
      usdVolume: 150001420.01759854,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '21.2360000000000000',
      high: '21.9500000000000000',
      low: '20.0000000000000000',
      close: '20.2460000000000000',
      volume: '5015956.9490000000000000',
      quoteVolume: 105884657.21403985,
      btcVolume: 2957.13775712415,
      usdVolume: 105574108.67201178,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '20.2630000000000000',
      high: '22.0710000000000000',
      low: '19.0770000000000000',
      close: '21.6460000000000000',
      volume: '6709724.1760000000000000',
      quoteVolume: 136982147.47085837,
      btcVolume: 3882.7672656123696,
      usdVolume: 135510283.7439176,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '21.6410000000000000',
      high: '21.8810000000000000',
      low: '16.9810000000000000',
      close: '17.3650000000000000',
      volume: '16151190.0200000000000000',
      quoteVolume: 304924588.43656045,
      btcVolume: 9030.919682300091,
      usdVolume: 296698047.37943095,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '17.3570000000000000',
      high: '18.7970000000000000',
      low: '15.0000000000000000',
      close: '16.8500000000000000',
      volume: '19773357.5780000000000000',
      quoteVolume: 331030753.695022,
      btcVolume: 10016.698622465823,
      usdVolume: 314934189.5838592,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '16.8400000000000000',
      high: '19.1990000000000000',
      low: '16.1490000000000000',
      close: '18.3950000000000000',
      volume: '10930842.9690000000000000',
      quoteVolume: 198104336.2413875,
      btcVolume: 5677.69271694415,
      usdVolume: 191351833.36313608,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '18.3930000000000000',
      high: '19.5250000000000000',
      low: '17.3520000000000000',
      close: '19.0600000000000000',
      volume: '6708926.7880000000000000',
      quoteVolume: 124897019.4366688,
      btcVolume: 3676.6447137809605,
      usdVolume: 124160107.18648963,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '19.0600000000000000',
      high: '19.5000000000000000',
      low: '16.8130000000000000',
      close: '16.8700000000000000',
      volume: '10048473.2290000000000000',
      quoteVolume: 180145249.5753493,
      btcVolume: 5424.613837041398,
      usdVolume: 179815215.34201533,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '16.8720000000000000',
      high: '17.4950000000000000',
      low: '15.9200000000000000',
      close: '16.9520000000000000',
      volume: '8343823.1670000000000000',
      quoteVolume: 138685945.58320868,
      btcVolume: 4317.21304493639,
      usdVolume: 135592125.3028405,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '16.9510000000000000',
      high: '18.4120000000000000',
      low: '16.5910000000000000',
      close: '18.4060000000000000',
      volume: '6649981.0660000000000000',
      quoteVolume: 114152516.37204297,
      btcVolume: 3403.722727537482,
      usdVolume: 112739292.89915286,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '18.4070000000000000',
      high: '19.4340000000000000',
      low: '17.9020000000000000',
      close: '18.8300000000000000',
      volume: '7282776.6190000000000000',
      quoteVolume: 135071888.30002892,
      btcVolume: 3906.1907284798945,
      usdVolume: 134704190.30824476,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '18.8300000000000000',
      high: '20.5460000000000000',
      low: '18.7520000000000000',
      close: '19.5350000000000000',
      volume: '6574919.5160000000000000',
      quoteVolume: 129862268.00902891,
      btcVolume: 3645.3920298803437,
      usdVolume: 129659684.4622509,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '19.5310000000000000',
      high: '19.7730000000000000',
      low: '18.1490000000000000',
      close: '19.5160000000000000',
      volume: '6554785.3410000000000000',
      quoteVolume: 124177607.62473075,
      btcVolume: 3561.7088830145212,
      usdVolume: 124094990.07156971,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '19.5170000000000000',
      high: '19.5330000000000000',
      low: '17.6030000000000000',
      close: '18.1860000000000000',
      volume: '6074285.8190000000000000',
      quoteVolume: 110566739.74832156,
      btcVolume: 3272.3128181398106,
      usdVolume: 110064065.08651572,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '18.1840000000000000',
      high: '18.5230000000000000',
      low: '17.2560000000000000',
      close: '18.2740000000000000',
      volume: '5394371.4130000000000000',
      quoteVolume: 95752765.26831836,
      btcVolume: 2873.9523665643746,
      usdVolume: 95533297.25328133,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '18.2770000000000000',
      high: '18.8940000000000000',
      low: '17.8640000000000000',
      close: '18.5430000000000000',
      volume: '3247443.1790000000000000',
      quoteVolume: 59971889.21668191,
      btcVolume: 1743.3323281938071,
      usdVolume: 59881835.060770914,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '18.5430000000000000',
      high: '19.9250000000000000',
      low: '18.0020000000000000',
      close: '19.2420000000000000',
      volume: '4486291.0750000000000000',
      quoteVolume: 85193944.29341578,
      btcVolume: 2409.86380003794,
      usdVolume: 85114681.7393157,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '19.2380000000000000',
      high: '19.2420000000000000',
      low: '17.7670000000000000',
      close: '18.3620000000000000',
      volume: '5244315.0980000000000000',
      quoteVolume: 96893912.50225921,
      btcVolume: 2815.4797708645337,
      usdVolume: 95800634.92579712,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '18.3640000000000000',
      high: '20.2710000000000000',
      low: '18.3170000000000000',
      close: '20.0730000000000000',
      volume: '7942424.0840000000000000',
      quoteVolume: 155645652.46806923,
      btcVolume: 4515.283695510405,
      usdVolume: 154167558.60065004,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '20.0730000000000000',
      high: '21.2740000000000000',
      low: '19.6200000000000000',
      close: '19.7880000000000000',
      volume: '7220562.4210000000000000',
      quoteVolume: 148395155.42902967,
      btcVolume: 4299.919566919362,
      usdVolume: 148395155.42902967,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '19.7860000000000000',
      high: '19.8730000000000000',
      low: '18.1380000000000000',
      close: '18.3530000000000000',
      volume: '7787413.9360000000000000',
      quoteVolume: 146836983.45400667,
      btcVolume: 4442.217674403576,
      usdVolume: 145991527.75807318,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '18.3650000000000000',
      high: '18.8770000000000000',
      low: '17.6170000000000000',
      close: '18.6560000000000000',
      volume: '5272793.7130000000000000',
      quoteVolume: 96043496.92060412,
      btcVolume: 2903.464250314309,
      usdVolume: 96030029.07034913,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '18.6560000000000000',
      high: '18.9880000000000000',
      low: '17.7340000000000000',
      close: '18.2040000000000000',
      volume: '4078447.0630000000000000',
      quoteVolume: 74240182.6654956,
      btcVolume: 2201.704167208646,
      usdVolume: 74224861.40051863,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '18.2090000000000000',
      high: '18.5810000000000000',
      low: '17.8610000000000000',
      close: '18.3830000000000000',
      volume: '2730333.2010000000000000',
      quoteVolume: 49800226.79839872,
      btcVolume: 1472.494351442755,
      usdVolume: 49793240.52506473,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '18.3820000000000000',
      high: '18.6090000000000000',
      low: '17.0000000000000000',
      close: '17.5180000000000000',
      volume: '3783915.1710000000000000',
      quoteVolume: 67410348.47561233,
      btcVolume: 2009.4146255562289,
      usdVolume: 67298959.85275029,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '17.5140000000000000',
      high: '17.7660000000000000',
      low: '16.6630000000000000',
      close: '16.8700000000000000',
      volume: '3488186.4250000000000000',
      quoteVolume: 60230003.646684654,
      btcVolume: 1836.6843254841956,
      usdVolume: 60221186.657093674,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '16.8730000000000000',
      high: '17.4190000000000000',
      low: '16.0140000000000000',
      close: '16.9360000000000000',
      volume: '5441562.0200000000000000',
      quoteVolume: 90914973.0594299,
      btcVolume: 2788.0780014295497,
      usdVolume: 90500474.54788788,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '16.9360000000000000',
      high: '17.2380000000000000',
      low: '15.5170000000000000',
      close: '15.7640000000000000',
      volume: '6198386.0130000000000000',
      quoteVolume: 99985406.05859742,
      btcVolume: 3118.566762095075,
      usdVolume: 99653864.88515042,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '15.7640000000000000',
      high: '16.3300000000000000',
      low: '15.1250000000000000',
      close: '15.2340000000000000',
      volume: '4106915.0750000000000000',
      quoteVolume: 64218657.385411024,
      btcVolume: 2026.355929873666,
      usdVolume: 64218546.10895903,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '15.2340000000000000',
      high: '15.6780000000000000',
      low: '15.0200000000000000',
      close: '15.3520000000000000',
      volume: '4957872.3330000000000000',
      quoteVolume: 76011680.64757319,
      btcVolume: 2401.8918472972196,
      usdVolume: 75811199.49437419,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '15.3520000000000000',
      high: '16.0780000000000000',
      low: '15.1550000000000000',
      close: '15.4700000000000000',
      volume: '3946657.1550000000000000',
      quoteVolume: 61781931.20700029,
      btcVolume: 1945.5860240070042,
      usdVolume: 61763430.72707828,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '15.4750000000000000',
      high: '15.6410000000000000',
      low: '14.4040000000000000',
      close: '14.5280000000000000',
      volume: '5828975.8470000000000000',
      quoteVolume: 86676083.7316871,
      btcVolume: 2787.3535740700127,
      usdVolume: 86630306.81259608,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '14.5290000000000000',
      high: '14.7830000000000000',
      low: '13.3840000000000000',
      close: '13.7420000000000000',
      volume: '7649757.9780000000000000',
      quoteVolume: 105449843.69424452,
      btcVolume: 3529.459900354445,
      usdVolume: 105367660.82367453,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '13.7440000000000000',
      high: '15.7970000000000000',
      low: '13.4600000000000000',
      close: '15.2970000000000000',
      volume: '8493449.3690000000000000',
      quoteVolume: 125644680.88342938,
      btcVolume: 3997.9525201581914,
      usdVolume: 124994012.93451238,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '15.3000000000000000',
      high: '16.1760000000000000',
      low: '14.9610000000000000',
      close: '16.0710000000000000',
      volume: '5857032.9790000000000000',
      quoteVolume: 90738306.1379759,
      btcVolume: 2820.8161649730764,
      usdVolume: 90645833.05113186,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '16.0730000000000000',
      high: '16.4860000000000000',
      low: '15.2300000000000000',
      close: '15.5970000000000000',
      volume: '4154495.5190000000000000',
      quoteVolume: 66029359.02171643,
      btcVolume: 2038.14247445674,
      usdVolume: 66029359.02171643,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
    btg: {
    id: 'btg',
    name: 'BTG',
    symbol: 'BTG',
    image: 'assets/images/coin-logo/BTG.png',
    activity24h: 1241256,
    availableAmount: 1328.232323,
    usdxConversion: 1,
    allTimeValue: 51512390,
    allTimeAsset: 765421,
    candleData: [
      {
      open: '9.7285000000000000',
      high: '9.7638000000000000',
      low: '9.6022000000000000',
      close: '9.7207000000000000',
      volume: '838913.9200000000000000',
      quoteVolume: 8136588.44204,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-28T00:00:00.000Z'
      },
      {
      open: '9.7207000000000000',
      high: '9.7317000000000000',
      low: '9.3748000000000000',
      close: '9.4450000000000000',
      volume: '788866.5700000000000000',
      quoteVolume: 7525106.643176,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-29T00:00:00.000Z'
      },
      {
      open: '9.4516000000000000',
      high: '9.5386000000000000',
      low: '9.4003000000000000',
      close: '9.4521000000000000',
      volume: '719748.6600000000000000',
      quoteVolume: 6803212.351281,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-30T00:00:00.000Z'
      },
      {
      open: '9.4513000000000000',
      high: '9.5546000000000000',
      low: '9.2811000000000000',
      close: '9.4807000000000000',
      volume: '707061.5900000000000000',
      quoteVolume: 6671089.908958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-31T00:00:00.000Z'
      },
      {
      open: '9.4837000000000000',
      high: '9.6600000000000000',
      low: '9.4303000000000000',
      close: '9.6192000000000000',
      volume: '791111.4800000000000000',
      quoteVolume: 7528202.69593,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-01T00:00:00.000Z'
      },
      {
      open: '9.6106000000000000',
      high: '9.7192000000000000',
      low: '9.5467000000000000',
      close: '9.6421000000000000',
      volume: '850927.5000000000000000',
      quoteVolume: 8194490.466364,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-02T00:00:00.000Z'
      },
      {
      open: '9.6607000000000000',
      high: '9.7440000000000000',
      low: '9.4888000000000000',
      close: '9.5889000000000000',
      volume: '812589.6100000000000000',
      quoteVolume: 7781309.311534,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-03T00:00:00.000Z'
      },
      {
      open: '9.5880000000000000',
      high: '9.8974000000000000',
      low: '9.5000000000000000',
      close: '9.8190000000000000',
      volume: '934579.3000000000000000',
      quoteVolume: 9056755.741708,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-04T00:00:00.000Z'
      },
      {
      open: '9.8084000000000000',
      high: '9.8294000000000000',
      low: '9.6603000000000000',
      close: '9.7333000000000000',
      volume: '836650.9100000000000000',
      quoteVolume: 8131267.116155,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-05T00:00:00.000Z'
      },
      {
      open: '9.7509000000000000',
      high: '9.8891000000000000',
      low: '9.6703000000000000',
      close: '9.8810000000000000',
      volume: '887223.8200000000000000',
      quoteVolume: 8677224.600936,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-06T00:00:00.000Z'
      },
      {
      open: '9.8718000000000000',
      high: '9.9776000000000000',
      low: '9.8100000000000000',
      close: '9.8970000000000000',
      volume: '865071.5600000000000000',
      quoteVolume: 8556329.625219,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-07T00:00:00.000Z'
      },
      {
      open: '9.8970000000000000',
      high: '9.9268000000000000',
      low: '9.6503000000000000',
      close: '9.6860000000000000',
      volume: '798983.4400000000000000',
      quoteVolume: 7826431.10844,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-08T00:00:00.000Z'
      },
      {
      open: '9.6868000000000000',
      high: '9.7497000000000000',
      low: '9.5169000000000000',
      close: '9.6141000000000000',
      volume: '735975.4500000000000000',
      quoteVolume: 7091790.464076,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-09T00:00:00.000Z'
      },
      {
      open: '9.6132000000000000',
      high: '9.6896000000000000',
      low: '9.5672000000000000',
      close: '9.5898000000000000',
      volume: '782308.0400000000000000',
      quoteVolume: 7533998.386684,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-10T00:00:00.000Z'
      },
      {
      open: '9.5898000000000000',
      high: '9.6210000000000000',
      low: '9.3500000000000000',
      close: '9.5611000000000000',
      volume: '755404.2100000000000000',
      quoteVolume: 7180788.710182,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-11T00:00:00.000Z'
      },
      {
      open: '9.5586000000000000',
      high: '9.5891000000000000',
      low: '9.3310000000000000',
      close: '9.4862000000000000',
      volume: '723363.6300000000000000',
      quoteVolume: 6843645.615956,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-12T00:00:00.000Z'
      },
      {
      open: '9.4862000000000000',
      high: '9.5891000000000000',
      low: '9.3602000000000000',
      close: '9.3936000000000000',
      volume: '658735.4600000000000000',
      quoteVolume: 6227393.415766,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-13T00:00:00.000Z'
      },
      {
      open: '9.3870000000000000',
      high: '9.4453000000000000',
      low: '8.3359000000000000',
      close: '8.6811000000000000',
      volume: '672611.0600000000000000',
      quoteVolume: 5964449.985309,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-14T00:00:00.000Z'
      },
      {
      open: '8.6896000000000000',
      high: '8.7342000000000000',
      low: '7.8000000000000000',
      close: '8.3793000000000000',
      volume: '822045.1000000000000000',
      quoteVolume: 6802948.925313,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-15T00:00:00.000Z'
      },
      {
      open: '8.3793000000000000',
      high: '8.4049000000000000',
      low: '7.8000000000000000',
      close: '8.0100000000000000',
      volume: '901842.2500000000000000',
      quoteVolume: 7292373.45122,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-16T00:00:00.000Z'
      },
      {
      open: '8.0097000000000000',
      high: '8.0192000000000000',
      low: '7.6846000000000000',
      close: '7.8494000000000000',
      volume: '752867.4700000000000000',
      quoteVolume: 5865731.485472,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-17T00:00:00.000Z'
      },
      {
      open: '7.8493000000000000',
      high: '8.1660000000000000',
      low: '7.8301000000000000',
      close: '8.0832000000000000',
      volume: '604839.7500000000000000',
      quoteVolume: 4838054.114957,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-18T00:00:00.000Z'
      },
      {
      open: '8.0833000000000000',
      high: '8.0918000000000000',
      low: '6.6453000000000000',
      close: '6.6844000000000000',
      volume: '1052222.0800000000000000',
      quoteVolume: 7512579.853245,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-19T00:00:00.000Z'
      },
      {
      open: '6.7040000000000000',
      high: '6.9050000000000000',
      low: '5.6120000000000000',
      close: '5.9200000000000000',
      volume: '1624846.6400000000000000',
      quoteVolume: 9912905.068409,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-20T00:00:00.000Z'
      },
      {
      open: '5.9419000000000000',
      high: '6.3230000000000000',
      low: '5.6502000000000000',
      close: '6.2894000000000000',
      volume: '842731.4100000000000000',
      quoteVolume: 5084237.945527,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-21T00:00:00.000Z'
      },
      {
      open: '6.2896000000000000',
      high: '6.3727000000000000',
      low: '5.7172000000000000',
      close: '5.7700000000000000',
      volume: '630372.0700000000000000',
      quoteVolume: 3840547.082434,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-22T00:00:00.000Z'
      },
      {
      open: '5.7755000000000000',
      high: '5.8396000000000000',
      low: '5.4500000000000000',
      close: '5.6611000000000000',
      volume: '598556.9000000000000000',
      quoteVolume: 3357369.283447,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-23T00:00:00.000Z'
      },
      {
      open: '5.6525000000000000',
      high: '5.8055000000000000',
      low: '4.7632000000000000',
      close: '5.1930000000000000',
      volume: '917700.2100000000000000',
      quoteVolume: 4844925.914768,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-24T00:00:00.000Z'
      },
      {
      open: '5.1990000000000000',
      high: '5.3800000000000000',
      low: '4.5320000000000000',
      close: '5.2873000000000000',
      volume: '1003892.6700000000000000',
      quoteVolume: 4998073.759825,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-25T00:00:00.000Z'
      },
      {
      open: '5.2800000000000000',
      high: '5.4000000000000000',
      low: '4.7568000000000000',
      close: '5.0631000000000000',
      volume: '852519.3700000000000000',
      quoteVolume: 4333954.593448,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-26T00:00:00.000Z'
      },
      {
      open: '5.0630000000000000',
      high: '5.0630000000000000',
      low: '4.7045000000000000',
      close: '4.9840000000000000',
      volume: '701759.1100000000000000',
      quoteVolume: 3434036.230959,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-27T00:00:00.000Z'
      },
      {
      open: '4.9840000000000000',
      high: '5.4603000000000000',
      low: '4.9840000000000000',
      close: '5.3499000000000000',
      volume: '1144816.8000000000000000',
      quoteVolume: 6061490.260849,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-28T00:00:00.000Z'
      },
      {
      open: '5.3498000000000000',
      high: '5.6344000000000000',
      low: '5.1243000000000000',
      close: '5.3805000000000000',
      volume: '1018322.9900000000000000',
      quoteVolume: 5461031.121114,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-29T00:00:00.000Z'
      },
      {
      open: '5.3902000000000000',
      high: '5.4480000000000000',
      low: '4.9534000000000000',
      close: '5.1150000000000000',
      volume: '709284.0200000000000000',
      quoteVolume: 3662427.823626,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-30T00:00:00.000Z'
      },
      {
      open: '5.1122000000000000',
      high: '5.4006000000000000',
      low: '5.0072000000000000',
      close: '5.2899000000000000',
      volume: '925388.4200000000000000',
      quoteVolume: 4874052.81019,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-01T00:00:00.000Z'
      },
      {
      open: '5.2787000000000000',
      high: '5.4357000000000000',
      low: '5.2050000000000000',
      close: '5.2888000000000000',
      volume: '853331.6100000000000000',
      quoteVolume: 4534872.243938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-02T00:00:00.000Z'
      },
      {
      open: '5.2831000000000000',
      high: '5.2956000000000000',
      low: '5.0100000000000000',
      close: '5.1266000000000000',
      volume: '673983.3800000000000000',
      quoteVolume: 3451158.980001,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-03T00:00:00.000Z'
      },
      {
      open: '5.1294000000000000',
      high: '6.3649000000000000',
      low: '5.0856000000000000',
      close: '5.9020000000000000',
      volume: '2238490.1600000000000000',
      quoteVolume: 12882498.1221741,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-04T00:00:00.000Z'
      },
      {
      open: '5.9020000000000000',
      high: '6.6200000000000000',
      low: '5.7225000000000000',
      close: '6.0727000000000000',
      volume: '4208247.7800000000000000',
      quoteVolume: 25915024.891642,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-05T00:00:00.000Z'
      },
      {
      open: '6.0689000000000000',
      high: '6.1090000000000000',
      low: '4.8500000000000000',
      close: '4.9023000000000000',
      volume: '3033034.7700000000000000',
      quoteVolume: 16605405.771891,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-06T00:00:00.000Z'
      },
      {
      open: '4.8977000000000000',
      high: '4.9997000000000000',
      low: '4.1200000000000000',
      close: '4.5160000000000000',
      volume: '2250208.5300000000000000',
      quoteVolume: 10282558.541603,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-07T00:00:00.000Z'
      },
      {
      open: '4.5118000000000000',
      high: '4.8009000000000000',
      low: '4.3001000000000000',
      close: '4.6361000000000000',
      volume: '1260790.7000000000000000',
      quoteVolume: 5751093.241387,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-08T00:00:00.000Z'
      },
      {
      open: '4.6280000000000000',
      high: '5.0003000000000000',
      low: '4.5167000000000000',
      close: '4.7693000000000000',
      volume: '1505537.6400000000000000',
      quoteVolume: 7177505.612086,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-09T00:00:00.000Z'
      },
      {
      open: '4.7693000000000000',
      high: '4.9398000000000000',
      low: '4.4698000000000000',
      close: '4.6276000000000000',
      volume: '928495.8500000000000000',
      quoteVolume: 4361795.739574,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-10T00:00:00.000Z'
      },
      {
      open: '4.6297000000000000',
      high: '4.9295000000000000',
      low: '4.5000000000000000',
      close: '4.8973000000000000',
      volume: '1232098.4300000000000000',
      quoteVolume: 5766708.758249,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-11T00:00:00.000Z'
      },
      {
      open: '4.8936000000000000',
      high: '5.1500000000000000',
      low: '4.7390000000000000',
      close: '4.9815000000000000',
      volume: '1196552.2900000000000000',
      quoteVolume: 5925304.975092,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-12T00:00:00.000Z'
      },
      {
      open: '4.9820000000000000',
      high: '4.9933000000000000',
      low: '4.5563000000000000',
      close: '4.6384000000000000',
      volume: '1003196.1800000000000000',
      quoteVolume: 4804678.850471,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-13T00:00:00.000Z'
      },
      {
      open: '4.6399000000000000',
      high: '4.7500000000000000',
      low: '4.4300000000000000',
      close: '4.5211000000000000',
      volume: '946936.1900000000000000',
      quoteVolume: 4370044.405834,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-14T00:00:00.000Z'
      },
      {
      open: '4.5148000000000000',
      high: '4.5990000000000000',
      low: '4.4436000000000000',
      close: '4.5531000000000000',
      volume: '969833.4400000000000000',
      quoteVolume: 4387901.297965,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-15T00:00:00.000Z'
      },
      {
      open: '4.5479000000000000',
      high: '4.7064000000000000',
      low: '4.5089000000000000',
      close: '4.6160000000000000',
      volume: '1069563.7700000000000000',
      quoteVolume: 4963613.541897,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-16T00:00:00.000Z'
      },
      {
      open: '4.6193000000000000',
      high: '5.1271000000000000',
      low: '4.6010000000000000',
      close: '5.0650000000000000',
      volume: '1507154.3700000000000000',
      quoteVolume: 7344673.590916,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-17T00:00:00.000Z'
      },
      {
      open: '5.0650000000000000',
      high: '5.5362000000000000',
      low: '4.9000000000000000',
      close: '5.5308000000000000',
      volume: '1906857.2400000000000000',
      quoteVolume: 9864772.820849,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-18T00:00:00.000Z'
      },
      {
      open: '5.5349000000000000',
      high: '5.8331000000000000',
      low: '5.2847000000000000',
      close: '5.3633000000000000',
      volume: '1805892.7600000000000000',
      quoteVolume: 9964231.850427,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-19T00:00:00.000Z'
      },
      {
      open: '5.3627000000000000',
      high: '5.7900000000000000',
      low: '5.2369000000000000',
      close: '5.6766000000000000',
      volume: '2160302.6100000000000000',
      quoteVolume: 12060645.667534,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-20T00:00:00.000Z'
      },
      {
      open: '5.6761000000000000',
      high: '5.7897000000000000',
      low: '5.3166000000000000',
      close: '5.4766000000000000',
      volume: '1592262.7400000000000000',
      quoteVolume: 8888728.773336,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-21T00:00:00.000Z'
      },
      {
      open: '5.4766000000000000',
      high: '5.8802000000000000',
      low: '5.3950000000000000',
      close: '5.8398000000000000',
      volume: '1775214.5900000000000000',
      quoteVolume: 10028050.954402,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-22T00:00:00.000Z'
      },
      {
      open: '5.8399000000000000',
      high: '6.1318000000000000',
      low: '5.7589000000000000',
      close: '5.9590000000000000',
      volume: '1878151.1300000000000000',
      quoteVolume: 11203811.278916,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-23T00:00:00.000Z'
      },
      {
      open: '5.9588000000000000',
      high: '6.4735000000000000',
      low: '5.9300000000000000',
      close: '6.0979000000000000',
      volume: '2058385.4600000000000000',
      quoteVolume: 12800612.446236,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-24T00:00:00.000Z'
      },
      {
      open: '6.0975000000000000',
      high: '6.0994000000000000',
      low: '5.3127000000000000',
      close: '5.4500000000000000',
      volume: '1443517.2200000000000000',
      quoteVolume: 7946748.599596,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-25T00:00:00.000Z'
      },
      {
      open: '5.4432000000000000',
      high: '5.6840000000000000',
      low: '5.3700000000000000',
      close: '5.6029000000000000',
      volume: '1351072.4800000000000000',
      quoteVolume: 7510239.890038,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-26T00:00:00.000Z'
      },
      {
      open: '5.6062000000000000',
      high: '5.6559000000000000',
      low: '5.1000000000000000',
      close: '5.1791000000000000',
      volume: '1148939.3700000000000000',
      quoteVolume: 6200387.822491,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-27T00:00:00.000Z'
      },
      {
      open: '5.1818000000000000',
      high: '5.8995000000000000',
      low: '5.1003000000000000',
      close: '5.8811000000000000',
      volume: '1505031.2400000000000000',
      quoteVolume: 8318661.357344,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-28T00:00:00.000Z'
      },
      {
      open: '5.8803000000000000',
      high: '6.0000000000000000',
      low: '5.6100000000000000',
      close: '5.6600000000000000',
      volume: '1232874.4500000000000000',
      quoteVolume: 7206376.833483,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-29T00:00:00.000Z'
      },
      {
      open: '5.6700000000000000',
      high: '5.9186000000000000',
      low: '5.5580000000000000',
      close: '5.8939000000000000',
      volume: '1394241.0900000000000000',
      quoteVolume: 8065674.631955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-30T00:00:00.000Z'
      },
      {
      open: '5.9000000000000000',
      high: '6.2433000000000000',
      low: '5.8304000000000000',
      close: '6.1111000000000000',
      volume: '2388899.2300000000000000',
      quoteVolume: 14438039.454585,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-31T00:00:00.000Z'
      },
      {
      open: '6.1139000000000000',
      high: '6.1228000000000000',
      low: '5.7735000000000000',
      close: '5.9910000000000000',
      volume: '1412914.1800000000000000',
      quoteVolume: 8379611.862422,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-01T00:00:00.000Z'
      },
      {
      open: '5.9910000000000000',
      high: '6.0939000000000000',
      low: '5.8371000000000000',
      close: '6.0510000000000000',
      volume: '2130127.0000000000000000',
      quoteVolume: 12667352.240985,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-02T00:00:00.000Z'
      },
      {
      open: '6.0445000000000000',
      high: '6.0620000000000000',
      low: '5.7739000000000000',
      close: '5.7979000000000000',
      volume: '1524726.3700000000000000',
      quoteVolume: 8984033.715786,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-03T00:00:00.000Z'
      },
      {
      open: '5.7947000000000000',
      high: '5.9900000000000000',
      low: '5.7402000000000000',
      close: '5.9692000000000000',
      volume: '1657441.7400000000000000',
      quoteVolume: 9772290.645118,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-04T00:00:00.000Z'
      },
      {
      open: '5.9688000000000000',
      high: '6.1043000000000000',
      low: '5.9188000000000000',
      close: '5.9625000000000000',
      volume: '1636284.9900000000000000',
      quoteVolume: 9826475.877965,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-05T00:00:00.000Z'
      },
      {
      open: '5.9626000000000000',
      high: '6.3888000000000000',
      low: '5.9250000000000000',
      close: '6.2688000000000000',
      volume: '2511301.2700000000000000',
      quoteVolume: 15355552.564777,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-06T00:00:00.000Z'
      },
      {
      open: '6.2681000000000000',
      high: '6.3094000000000000',
      low: '6.0061000000000000',
      close: '6.2009000000000000',
      volume: '1683858.6800000000000000',
      quoteVolume: 10428760.417152,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-07T00:00:00.000Z'
      },
      {
      open: '6.1979000000000000',
      high: '6.7984000000000000',
      low: '6.1381000000000000',
      close: '6.5128000000000000',
      volume: '3946905.6100000000000000',
      quoteVolume: 25730037.887084,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-08T00:00:00.000Z'
      },
      {
      open: '6.5197000000000000',
      high: '6.8540000000000000',
      low: '6.3386000000000000',
      close: '6.5511000000000000',
      volume: '4167653.6900000000000000',
      quoteVolume: 27575766.440919,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-09T00:00:00.000Z'
      },
      {
      open: '6.5511000000000000',
      high: '6.5764000000000000',
      low: '5.6500000000000000',
      close: '5.8449000000000000',
      volume: '3348790.0300000000000000',
      quoteVolume: 20367712.107099,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-10T00:00:00.000Z'
      },
      {
      open: '5.8440000000000000',
      high: '6.1198000000000000',
      low: '5.6853000000000000',
      close: '5.9595000000000000',
      volume: '2559930.8100000000000000',
      quoteVolume: 15157433.652937,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-11T00:00:00.000Z'
      },
      {
      open: '5.9552000000000000',
      high: '5.9657000000000000',
      low: '5.8000000000000000',
      close: '5.8594000000000000',
      volume: '1743319.3100000000000000',
      quoteVolume: 10235285.058373,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-12T00:00:00.000Z'
      },
      {
      open: '5.8629000000000000',
      high: '5.8884000000000000',
      low: '5.3733000000000000',
      close: '5.4688000000000000',
      volume: '1420692.0500000000000000',
      quoteVolume: 8108312.422364,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-13T00:00:00.000Z'
      },
      {
      open: '5.4688000000000000',
      high: '6.0620000000000000',
      low: '5.4586000000000000',
      close: '6.0583000000000000',
      volume: '2330172.4800000000000000',
      quoteVolume: 13521000.249423,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-14T00:00:00.000Z'
      },
      {
      open: '6.0565000000000000',
      high: '6.0649000000000000',
      low: '5.6601000000000000',
      close: '5.8111000000000000',
      volume: '1366715.2500000000000000',
      quoteVolume: 8031293.154063,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-15T00:00:00.000Z'
      },
      {
      open: '5.8007000000000000',
      high: '6.1817000000000000',
      low: '5.7741000000000000',
      close: '6.0911000000000000',
      volume: '2164639.9000000000000000',
      quoteVolume: 12960434.729842,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '6.0941000000000000',
      high: '6.3300000000000000',
      low: '5.9604000000000000',
      close: '6.3266000000000000',
      volume: '2312287.7400000000000000',
      quoteVolume: 14193314.652338,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '6.3152000000000000',
      high: '6.5879000000000000',
      low: '6.2132000000000000',
      close: '6.4867000000000000',
      volume: '2495447.2200000000000000',
      quoteVolume: 15985247.059954,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '6.4887000000000000',
      high: '6.6400000000000000',
      low: '6.2435000000000000',
      close: '6.5199000000000000',
      volume: '2269437.9800000000000000',
      quoteVolume: 14687340.306653,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '6.5221000000000000',
      high: '6.6295000000000000',
      low: '6.2401000000000000',
      close: '6.4008000000000000',
      volume: '1778376.9300000000000000',
      quoteVolume: 11481466.262429,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '6.4069000000000000',
      high: '6.5150000000000000',
      low: '6.2788000000000000',
      close: '6.4724000000000000',
      volume: '1851048.4900000000000000',
      quoteVolume: 11908754.858074,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '6.4681000000000000',
      high: '6.5088000000000000',
      low: '6.2500000000000000',
      close: '6.4736000000000000',
      volume: '1854629.7500000000000000',
      quoteVolume: 11938849.639233,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '6.4780000000000000',
      high: '6.5300000000000000',
      low: '6.3499000000000000',
      close: '6.4969000000000000',
      volume: '1928704.7200000000000000',
      quoteVolume: 12474449.059306,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '6.4963000000000000',
      high: '6.4963000000000000',
      low: '6.3915000000000000',
      close: '6.4588000000000000',
      volume: '1514319.9100000000000000',
      quoteVolume: 9750589.965848,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '6.4555000000000000',
      high: '6.7502000000000000',
      low: '6.4408000000000000',
      close: '6.6899000000000000',
      volume: '2583153.3800000000000000',
      quoteVolume: 17021668.779197,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '6.6890000000000000',
      high: '6.9786000000000000',
      low: '6.6196000000000000',
      close: '6.9463000000000000',
      volume: '2734629.1100000000000000',
      quoteVolume: 18598393.817596,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '6.9453000000000000',
      high: '7.2157000000000000',
      low: '6.7919000000000000',
      close: '7.0396000000000000',
      volume: '2776887.6800000000000000',
      quoteVolume: 19399583.767419,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '7.0399000000000000',
      high: '7.1398000000000000',
      low: '5.9400000000000000',
      close: '6.1648000000000000',
      volume: '4950985.6900000000000000',
      quoteVolume: 32288204.501957,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '6.1642000000000000',
      high: '6.2599000000000000',
      low: '5.9680000000000000',
      close: '6.1661000000000000',
      volume: '2967157.3600000000000000',
      quoteVolume: 18169111.04114,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '6.1335000000000000',
      high: '6.1999000000000000',
      low: '6.0010000000000000',
      close: '6.1388000000000000',
      volume: '2238811.5900000000000000',
      quoteVolume: 13687184.380913,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '6.1384000000000000',
      high: '6.2663000000000000',
      low: '5.9468000000000000',
      close: '6.2200000000000000',
      volume: '2264988.8600000000000000',
      quoteVolume: 13878555.849304,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '6.2250000000000000',
      high: '6.6500000000000000',
      low: '6.1172000000000000',
      close: '6.5229000000000000',
      volume: '2942865.6800000000000000',
      quoteVolume: 19039053.024294,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '6.5185000000000000',
      high: '6.9000000000000000',
      low: '6.4823000000000000',
      close: '6.8655000000000000',
      volume: '3251363.1600000000000000',
      quoteVolume: 21783128.783937,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '6.8688000000000000',
      high: '6.9000000000000000',
      low: '6.6148000000000000',
      close: '6.7887000000000000',
      volume: '3095048.1600000000000000',
      quoteVolume: 21095885.790271,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '6.7888000000000000',
      high: '7.1850000000000000',
      low: '6.6800000000000000',
      close: '7.1069000000000000',
      volume: '3306867.3100000000000000',
      quoteVolume: 23064420.680175,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '7.1028000000000000',
      high: '7.9715000000000000',
      low: '6.9501000000000000',
      close: '7.8665000000000000',
      volume: '4154631.4300000000000000',
      quoteVolume: 30555459.709009,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '7.8569000000000000',
      high: '8.4800000000000000',
      low: '7.3200000000000000',
      close: '8.1303000000000000',
      volume: '5817391.7600000000000000',
      quoteVolume: 46487023.094392,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '8.1347000000000000',
      high: '8.4666000000000000',
      low: '7.6733000000000000',
      close: '7.8100000000000000',
      volume: '4354792.8000000000000000',
      quoteVolume: 35149002.628069,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '7.8070000000000000',
      high: '8.8387000000000000',
      low: '7.6100000000000000',
      close: '8.5972000000000000',
      volume: '4960284.8400000000000000',
      quoteVolume: 40860967.438254,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '8.5972000000000000',
      high: '8.9210000000000000',
      low: '8.4052000000000000',
      close: '8.9051000000000000',
      volume: '5440051.1100000000000000',
      quoteVolume: 47410868.663698,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '8.9001000000000000',
      high: '9.2999000000000000',
      low: '8.8264000000000000',
      close: '9.1697000000000000',
      volume: '5750442.0600000000000000',
      quoteVolume: 52514889.989487,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '9.1695000000000000',
      high: '9.7780000000000000',
      low: '9.0100000000000000',
      close: '9.5288000000000000',
      volume: '6041136.0700000000000000',
      quoteVolume: 57201203.615301,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '9.5250000000000000',
      high: '9.9000000000000000',
      low: '8.9210000000000000',
      close: '9.2765000000000000',
      volume: '4945231.4600000000000000',
      quoteVolume: 46161674.265266,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '9.2763000000000000',
      high: '9.2763000000000000',
      low: '8.7100000000000000',
      close: '8.9103000000000000',
      volume: '4005598.1000000000000000',
      quoteVolume: 36165479.576568,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '8.9165000000000000',
      high: '9.0500000000000000',
      low: '8.4051000000000000',
      close: '8.7655000000000000',
      volume: '2803390.0100000000000000',
      quoteVolume: 24547147.485664,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '8.7636000000000000',
      high: '9.4997000000000000',
      low: '8.7293000000000000',
      close: '9.2911000000000000',
      volume: '3326003.0400000000000000',
      quoteVolume: 30588883.272504,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '9.2854000000000000',
      high: '9.3399000000000000',
      low: '9.0480000000000000',
      close: '9.1087000000000000',
      volume: '2499086.4000000000000000',
      quoteVolume: 22869550.901954,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '9.0972000000000000',
      high: '9.2200000000000000',
      low: '8.8903000000000000',
      close: '9.2200000000000000',
      volume: '2460188.8100000000000000',
      quoteVolume: 22259140.520036,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '9.2167000000000000',
      high: '9.6999000000000000',
      low: '9.1777000000000000',
      close: '9.6127000000000000',
      volume: '2809984.6500000000000000',
      quoteVolume: 26459587.703255,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '9.6127000000000000',
      high: '11.1307000000000000',
      low: '9.4810000000000000',
      close: '10.4728000000000000',
      volume: '4894965.3800000000000000',
      quoteVolume: 49969717.014679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '10.4687000000000000',
      high: '11.2951000000000000',
      low: '10.3928000000000000',
      close: '10.9211000000000000',
      volume: '4346711.0700000000000000',
      quoteVolume: 47273284.241928,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '10.9199000000000000',
      high: '10.9360000000000000',
      low: '10.1300000000000000',
      close: '10.4661000000000000',
      volume: '3077022.9900000000000000',
      quoteVolume: 31977282.892985,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '10.4695000000000000',
      high: '10.9611000000000000',
      low: '10.3799000000000000',
      close: '10.7576000000000000',
      volume: '3038507.7600000000000000',
      quoteVolume: 32510212.911946,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '10.7572000000000000',
      high: '10.8500000000000000',
      low: '10.4000000000000000',
      close: '10.7265000000000000',
      volume: '2877713.1500000000000000',
      quoteVolume: 30567202.596206,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '10.7267000000000000',
      high: '11.0900000000000000',
      low: '9.3901000000000000',
      close: '9.9476000000000000',
      volume: '4800152.2500000000000000',
      quoteVolume: 49351275.843466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '9.9442000000000000',
      high: '10.2361000000000000',
      low: '9.2685000000000000',
      close: '9.6967000000000000',
      volume: '5348579.5700000000000000',
      quoteVolume: 52442588.585831,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '9.6967000000000000',
      high: '9.7557000000000000',
      low: '9.3551000000000000',
      close: '9.4168000000000000',
      volume: '3118340.9500000000000000',
      quoteVolume: 29619413.056984,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '9.4238000000000000',
      high: '9.9998000000000000',
      low: '9.3500000000000000',
      close: '9.8467000000000000',
      volume: '3524897.3500000000000000',
      quoteVolume: 34336397.563894,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '9.8502000000000000',
      high: '10.4914000000000000',
      low: '9.7899000000000000',
      close: '10.2779000000000000',
      volume: '3693794.5400000000000000',
      quoteVolume: 37396909.411865,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '10.2777000000000000',
      high: '11.5510000000000000',
      low: '10.2067000000000000',
      close: '11.3111000000000000',
      volume: '4025695.9700000000000000',
      quoteVolume: 44152799.747361,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '11.3353000000000000',
      high: '11.8000000000000000',
      low: '10.8000000000000000',
      close: '11.7566000000000000',
      volume: '3893847.1600000000000000',
      quoteVolume: 43818057.90284,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '11.7564000000000000',
      high: '11.8701000000000000',
      low: '11.1902000000000000',
      close: '11.4511000000000000',
      volume: '2853200.1500000000000000',
      quoteVolume: 32727760.247279,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '11.4511000000000000',
      high: '11.5080000000000000',
      low: '10.8500000000000000',
      close: '11.4006000000000000',
      volume: '2739049.0800000000000000',
      quoteVolume: 30560700.450351,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '11.4050000000000000',
      high: '13.7500000000000000',
      low: '11.3950000000000000',
      close: '13.6229000000000000',
      volume: '8340655.3200000000000000',
      quoteVolume: 107482964.630679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '13.6230000000000000',
      high: '14.6630000000000000',
      low: '12.9000000000000000',
      close: '14.5294000000000000',
      volume: '6158611.9700000000000000',
      quoteVolume: 84008255.221381,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '14.5290000000000000',
      high: '15.5600000000000000',
      low: '14.3270000000000000',
      close: '15.1000000000000000',
      volume: '6757497.3300000000000000',
      quoteVolume: 101414485.34027,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '15.0999000000000000',
      high: '15.2194000000000000',
      low: '13.5578000000000000',
      close: '14.2931000000000000',
      volume: '6343194.0300000000000000',
      quoteVolume: 90848655.629742,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '14.2848000000000000',
      high: '14.9200000000000000',
      low: '13.9609000000000000',
      close: '14.4651000000000000',
      volume: '3996324.5500000000000000',
      quoteVolume: 58062140.975948,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '14.4651000000000000',
      high: '14.5075000000000000',
      low: '13.8600000000000000',
      close: '14.3711000000000000',
      volume: '3531708.7000000000000000',
      quoteVolume: 50093088.214938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '14.3741000000000000',
      high: '14.9000000000000000',
      low: '14.0110000000000000',
      close: '14.4143000000000000',
      volume: '3757653.7700000000000000',
      quoteVolume: 54303593.05517,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '14.4133000000000000',
      high: '15.4288000000000000',
      low: '14.1809000000000000',
      close: '15.3333000000000000',
      volume: '4049068.3900000000000000',
      quoteVolume: 60747159.402645,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '15.3459000000000000',
      high: '15.3800000000000000',
      low: '14.9333000000000000',
      close: '15.0244000000000000',
      volume: '3282883.1800000000000000',
      quoteVolume: 49667294.365356,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '15.0311000000000000',
      high: '15.0970000000000000',
      low: '14.5630000000000000',
      close: '14.8901000000000000',
      volume: '3079956.9300000000000000',
      quoteVolume: 45702098.682484,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '14.8993000000000000',
      high: '15.1990000000000000',
      low: '14.8400000000000000',
      close: '14.9432000000000000',
      volume: '2986987.6900000000000000',
      quoteVolume: 44970630.556925,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '14.9429000000000000',
      high: '16.0000000000000000',
      low: '14.8800000000000000',
      close: '15.9589000000000000',
      volume: '3293876.1300000000000000',
      quoteVolume: 51027787.313053,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '15.9589000000000000',
      high: '15.9589000000000000',
      low: '15.4400000000000000',
      close: '15.5693000000000000',
      volume: '3106901.4800000000000000',
      quoteVolume: 48737968.350018,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '15.5836000000000000',
      high: '15.7888000000000000',
      low: '15.2040000000000000',
      close: '15.5388000000000000',
      volume: '2472584.8900000000000000',
      quoteVolume: 38403832.139068,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '15.5361000000000000',
      high: '15.8000000000000000',
      low: '15.0000000000000000',
      close: '15.4953000000000000',
      volume: '4271050.4000000000000000',
      quoteVolume: 65955552.676115,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '15.5098000000000000',
      high: '15.5098000000000000',
      low: '15.0220000000000000',
      close: '15.1288000000000000',
      volume: '2369648.3800000000000000',
      quoteVolume: 35958975.368582,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '15.1288000000000000',
      high: '15.2987000000000000',
      low: '13.8607000000000000',
      close: '14.3511000000000000',
      volume: '3015066.5300000000000000',
      quoteVolume: 44410580.384387,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '14.3567000000000000',
      high: '15.2260000000000000',
      low: '14.2310000000000000',
      close: '15.1502000000000000',
      volume: '2695850.8700000000000000',
      quoteVolume: 40069206.590268,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '15.1524000000000000',
      high: '15.4400000000000000',
      low: '14.9469000000000000',
      close: '15.1397000000000000',
      volume: '2632489.7000000000000000',
      quoteVolume: 39846679.02577,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '15.1397000000000000',
      high: '17.5000000000000000',
      low: '14.6600000000000000',
      close: '17.1096000000000000',
      volume: '5285367.1000000000000000',
      quoteVolume: 86478446.288151,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '17.1112000000000000',
      high: '17.3380000000000000',
      low: '16.1000000000000000',
      close: '16.7631000000000000',
      volume: '2775613.8600000000000000',
      quoteVolume: 46472992.15214,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '16.7761000000000000',
      high: '16.8000000000000000',
      low: '15.5301000000000000',
      close: '16.1998000000000000',
      volume: '2725145.6800000000000000',
      quoteVolume: 44086712.960827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '16.1928000000000000',
      high: '16.8297000000000000',
      low: '15.8697000000000000',
      close: '16.6000000000000000',
      volume: '3240914.2500000000000000',
      quoteVolume: 53588345.695308,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '16.6000000000000000',
      high: '16.7188000000000000',
      low: '16.4010000000000000',
      close: '16.6665000000000000',
      volume: '2988300.9700000000000000',
      quoteVolume: 49575583.531128,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '16.6538000000000000',
      high: '16.8067000000000000',
      low: '16.3770000000000000',
      close: '16.4753000000000000',
      volume: '2737173.2000000000000000',
      quoteVolume: 45408014.592112,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '16.4949000000000000',
      high: '16.9850000000000000',
      low: '15.9612000000000000',
      close: '16.9666000000000000',
      volume: '2790050.9300000000000000',
      quoteVolume: 46116769.387966,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '16.9666000000000000',
      high: '17.7386000000000000',
      low: '16.7314000000000000',
      close: '17.5376000000000000',
      volume: '3182964.8500000000000000',
      quoteVolume: 55170337.210131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '17.5201000000000000',
      high: '18.2576000000000000',
      low: '17.3319000000000000',
      close: '17.8888000000000000',
      volume: '2966204.7100000000000000',
      quoteVolume: 52669428.49396,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '17.8888000000000000',
      high: '19.8800000000000000',
      low: '17.5303000000000000',
      close: '19.7155000000000000',
      volume: '3356886.8900000000000000',
      quoteVolume: 62702520.871521,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '19.7155000000000000',
      high: '20.0000000000000000',
      low: '18.0000000000000000',
      close: '18.5489000000000000',
      volume: '3532965.9700000000000000',
      quoteVolume: 67883042.73247,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '18.5388000000000000',
      high: '19.4100000000000000',
      low: '18.1600000000000000',
      close: '18.9920000000000000',
      volume: '2939082.4200000000000000',
      quoteVolume: 55680460.143077,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '18.9710000000000000',
      high: '19.4593000000000000',
      low: '18.8445000000000000',
      close: '19.3657000000000000',
      volume: '2703355.9100000000000000',
      quoteVolume: 51825140.403764,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '19.3658000000000000',
      high: '19.3866000000000000',
      low: '18.8200000000000000',
      close: '19.0745000000000000',
      volume: '2519027.6300000000000000',
      quoteVolume: 48185036.221402,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '19.0423000000000000',
      high: '19.2137000000000000',
      low: '18.8000000000000000',
      close: '18.9523000000000000',
      volume: '2329702.3400000000000000',
      quoteVolume: 44296486.486205,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '18.9507000000000000',
      high: '19.0420000000000000',
      low: '17.7881000000000000',
      close: '17.9777000000000000',
      volume: '2142481.9200000000000000',
      quoteVolume: 39222061.198727,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '17.9728000000000000',
      high: '18.5400000000000000',
      low: '17.0100000000000000',
      close: '18.2984000000000000',
      volume: '2776440.4800000000000000',
      quoteVolume: 49902259.792581,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '18.2984000000000000',
      high: '18.6095000000000000',
      low: '17.8927000000000000',
      close: '18.2400000000000000',
      volume: '2553039.1200000000000000',
      quoteVolume: 46664596.972989,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '18.2400000000000000',
      high: '18.2400000000000000',
      low: '16.2501000000000000',
      close: '17.5555000000000000',
      volume: '1815271.0500000000000000',
      quoteVolume: 31411384.587709,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '17.5555000000000000',
      high: '18.5520000000000000',
      low: '16.6120000000000000',
      close: '18.3333000000000000',
      volume: '2325593.3600000000000000',
      quoteVolume: 41384885.795214,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '18.2867000000000000',
      high: '18.6398000000000000',
      low: '17.9143000000000000',
      close: '18.6111000000000000',
      volume: '2205194.6100000000000000',
      quoteVolume: 40484642.365226,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '18.6111000000000000',
      high: '19.9000000000000000',
      low: '18.4259000000000000',
      close: '19.3866000000000000',
      volume: '2438051.2100000000000000',
      quoteVolume: 46779479.130832,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '19.3734000000000000',
      high: '19.7800000000000000',
      low: '18.6601000000000000',
      close: '19.2666000000000000',
      volume: '2218308.0300000000000000',
      quoteVolume: 42952596.269131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '19.2668000000000000',
      high: '19.7482000000000000',
      low: '18.7064000000000000',
      close: '19.6408000000000000',
      volume: '2237334.6000000000000000',
      quoteVolume: 43185852.935218,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '19.6399000000000000',
      high: '19.7908000000000000',
      low: '19.2600000000000000',
      close: '19.4888000000000000',
      volume: '2005582.6000000000000000',
      quoteVolume: 39131247.103883,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '19.4757000000000000',
      high: '21.9980000000000000',
      low: '19.4333000000000000',
      close: '21.8888000000000000',
      volume: '3686092.5700000000000000',
      quoteVolume: 76859035.465239,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '21.8888000000000000',
      high: '24.7459000000000000',
      low: '21.1200000000000000',
      close: '24.3188000000000000',
      volume: '4247944.3800000000000000',
      quoteVolume: 97541476.101794,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '24.3188000000000000',
      high: '25.4973000000000000',
      low: '23.7600000000000000',
      close: '24.8888000000000000',
      volume: '3545157.4900000000000000',
      quoteVolume: 87320188.459109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '24.8693000000000000',
      high: '24.8693000000000000',
      low: '22.4500000000000000',
      close: '23.9999000000000000',
      volume: '3307426.8400000000000000',
      quoteVolume: 77726576.261717,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '23.9999000000000000',
      high: '24.6368000000000000',
      low: '23.0200000000000000',
      close: '23.8000000000000000',
      volume: '3005735.9300000000000000',
      quoteVolume: 72180137.003328,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '23.7812000000000000',
      high: '24.6131000000000000',
      low: '22.4700000000000000',
      close: '22.5460000000000000',
      volume: '2877995.4900000000000000',
      quoteVolume: 67622172.827015,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '22.5207000000000000',
      high: '23.1209000000000000',
      low: '20.8800000000000000',
      close: '22.9703000000000000',
      volume: '2961681.7400000000000000',
      quoteVolume: 65293459.483933,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '22.9877000000000000',
      high: '23.4698000000000000',
      low: '21.5500000000000000',
      close: '22.4863000000000000',
      volume: '3114460.9000000000000000',
      quoteVolume: 70932653.981115,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '22.4869000000000000',
      high: '23.7860000000000000',
      low: '21.6503000000000000',
      close: '23.0555000000000000',
      volume: '3273950.4700000000000000',
      quoteVolume: 75393015.340524,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '23.0555000000000000',
      high: '23.1209000000000000',
      low: '22.3876000000000000',
      close: '22.4324000000000000',
      volume: '2989261.0700000000000000',
      quoteVolume: 67575656.858512,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '22.4326000000000000',
      high: '23.2092000000000000',
      low: '22.4200000000000000',
      close: '23.0226000000000000',
      volume: '3015774.2900000000000000',
      quoteVolume: 69067258.092041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '23.0166000000000000',
      high: '23.0828000000000000',
      low: '21.2797000000000000',
      close: '21.6788000000000000',
      volume: '2388965.0700000000000000',
      quoteVolume: 52801462.600293,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '21.6847000000000000',
      high: '22.3000000000000000',
      low: '21.3292000000000000',
      close: '22.0391000000000000',
      volume: '2197565.5300000000000000',
      quoteVolume: 47987092.830938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '22.0300000000000000',
      high: '22.0990000000000000',
      low: '21.4700000000000000',
      close: '21.8788000000000000',
      volume: '2064791.5400000000000000',
      quoteVolume: 45032827.826184,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '21.8810000000000000',
      high: '24.1270000000000000',
      low: '21.7497000000000000',
      close: '23.6166000000000000',
      volume: '2446015.2000000000000000',
      quoteVolume: 56078160.970131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '23.6168000000000000',
      high: '23.9720000000000000',
      low: '23.0101000000000000',
      close: '23.6999000000000000',
      volume: '2230465.5800000000000000',
      quoteVolume: 52406113.705314,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '23.6999000000000000',
      high: '23.7968000000000000',
      low: '22.3000000000000000',
      close: '22.8888000000000000',
      volume: '1898934.6200000000000000',
      quoteVolume: 43768914.432769,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '22.8887000000000000',
      high: '23.1772000000000000',
      low: '22.6408000000000000',
      close: '22.9777000000000000',
      volume: '1787108.3900000000000000',
      quoteVolume: 40919944.41562,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '22.9777000000000000',
      high: '22.9973000000000000',
      low: '21.4500000000000000',
      close: '22.1895000000000000',
      volume: '1764747.5400000000000000',
      quoteVolume: 39058125.114883,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '22.2018000000000000',
      high: '22.4500000000000000',
      low: '20.2050000000000000',
      close: '20.6602000000000000',
      volume: '1813897.1200000000000000',
      quoteVolume: 39553390.823722,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '20.6602000000000000',
      high: '21.4135000000000000',
      low: '19.5100000000000000',
      close: '20.6673000000000000',
      volume: '2742356.4200000000000000',
      quoteVolume: 56630740.961407,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '20.6671000000000000',
      high: '20.9670000000000000',
      low: '18.5402000000000000',
      close: '18.8388000000000000',
      volume: '2334477.6000000000000000',
      quoteVolume: 46078618.722513,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '18.8377000000000000',
      high: '19.9888000000000000',
      low: '18.3000000000000000',
      close: '19.6166000000000000',
      volume: '2429624.3200000000000000',
      quoteVolume: 46735449.139546,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '19.6294000000000000',
      high: '22.0000000000000000',
      low: '18.8800000000000000',
      close: '20.8059000000000000',
      volume: '3746402.5300000000000000',
      quoteVolume: 76700019.096599,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '20.7871000000000000',
      high: '21.5000000000000000',
      low: '20.1000000000000000',
      close: '20.8934000000000000',
      volume: '3228794.3800000000000000',
      quoteVolume: 67290647.825955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '20.9026000000000000',
      high: '24.0000000000000000',
      low: '20.7349000000000000',
      close: '23.5210000000000000',
      volume: '4566012.5800000000000000',
      quoteVolume: 103765929.624047,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '23.5210000000000000',
      high: '24.5000000000000000',
      low: '22.5748000000000000',
      close: '23.6553000000000000',
      volume: '3821993.1600000000000000',
      quoteVolume: 90350011.235486,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '23.6553000000000000',
      high: '27.6556000000000000',
      low: '23.4600000000000000',
      close: '26.8116000000000000',
      volume: '3433770.3400000000000000',
      quoteVolume: 89988096.508272,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '26.8348000000000000',
      high: '27.7900000000000000',
      low: '25.0000000000000000',
      close: '26.2888000000000000',
      volume: '4200071.2400000000000000',
      quoteVolume: 111245092.085173,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '26.2888000000000000',
      high: '26.6783000000000000',
      low: '23.1424000000000000',
      close: '25.7795000000000000',
      volume: '3429086.5600000000000000',
      quoteVolume: 85241258.720158,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '25.7986000000000000',
      high: '29.0989000000000000',
      low: '25.1124000000000000',
      close: '28.8171000000000000',
      volume: '4319102.3500000000000000',
      quoteVolume: 117061502.700204,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '28.8165000000000000',
      high: '29.8368000000000000',
      low: '27.5430000000000000',
      close: '29.1122000000000000',
      volume: '4437585.5400000000000000',
      quoteVolume: 128407708.555722,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '29.1122000000000000',
      high: '29.3399000000000000',
      low: '27.5306000000000000',
      close: '29.2666000000000000',
      volume: '3980328.4500000000000000',
      quoteVolume: 113614310.06848,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '29.2666000000000000',
      high: '32.8000000000000000',
      low: '28.0500000000000000',
      close: '32.3907000000000000',
      volume: '4704491.9800000000000000',
      quoteVolume: 142363584.104499,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '32.3911000000000000',
      high: '32.4374000000000000',
      low: '30.6100000000000000',
      close: '30.8067000000000000',
      volume: '3807962.5800000000000000',
      quoteVolume: 120261451.277312,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '30.8333000000000000',
      high: '32.2500000000000000',
      low: '29.6699000000000000',
      close: '31.3934000000000000',
      volume: '3793076.8500000000000000',
      quoteVolume: 118471724.038178,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '31.3986000000000000',
      high: '34.7067000000000000',
      low: '31.1200000000000000',
      close: '33.9435000000000000',
      volume: '4534224.5600000000000000',
      quoteVolume: 151952803.938095,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '34.1004000000000000',
      high: '35.4868000000000000',
      low: '33.6231000000000000',
      close: '35.1464000000000000',
      volume: '2287998.0600000000000000',
      quoteVolume: 79609705.50209479,
      btcVolume: 9949.002147255906,
      usdVolume: 79609705.50209479,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '35.1128000000000000',
      high: '35.1128000000000000',
      low: '32.3200000000000000',
      close: '33.0989000000000000',
      volume: '2391390.5700000000000000',
      quoteVolume: 80142171.05356416,
      btcVolume: 9883.743936491968,
      usdVolume: 80142171.05356416,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '33.0220000000000000',
      high: '35.1001000000000000',
      low: '32.5000000000000000',
      close: '33.4619000000000000',
      volume: '2295348.1100000000000000',
      quoteVolume: 77908157.85849185,
      btcVolume: 8929.21104959771,
      usdVolume: 77908157.85849185,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '33.4618000000000000',
      high: '34.5000000000000000',
      low: '32.5000000000000000',
      close: '33.1336000000000000',
      volume: '1895937.3300000000000000',
      quoteVolume: 63128977.16986113,
      btcVolume: 7252.390997849462,
      usdVolume: 63128977.16986113,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '33.0923000000000000',
      high: '33.8790000000000000',
      low: '30.8500000000000000',
      close: '33.6083000000000000',
      volume: '2212524.3300000000000000',
      quoteVolume: 72870434.08302279,
      btcVolume: 8454.326316547385,
      usdVolume: 72870434.08302279,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '33.6085000000000000',
      high: '34.9600000000000000',
      low: '30.2500000000000000',
      close: '31.6330000000000000',
      volume: '2432021.9400000000000000',
      quoteVolume: 80763627.05465643,
      btcVolume: 9414.799798958127,
      usdVolume: 80763627.05465643,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '31.6473000000000000',
      high: '32.9724000000000000',
      low: '30.6100000000000000',
      close: '32.9321000000000000',
      volume: '1923224.7500000000000000',
      quoteVolume: 61104329.90333608,
      btcVolume: 7303.226615523445,
      usdVolume: 61104329.90333608,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '32.9165000000000000',
      high: '34.3530000000000000',
      low: '32.5024000000000000',
      close: '33.3348000000000000',
      volume: '2519040.2400000000000000',
      quoteVolume: 83650473.63800699,
      btcVolume: 9797.183411874852,
      usdVolume: 83650473.63800699,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '33.3521000000000000',
      high: '33.7647000000000000',
      low: '32.6800000000000000',
      close: '33.1527000000000000',
      volume: '3125349.5700000000000000',
      quoteVolume: 103747789.15420249,
      btcVolume: 11980.301630342452,
      usdVolume: 103747789.15420249,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '33.1343000000000000',
      high: '33.1753000000000000',
      low: '30.5100000000000000',
      close: '30.8093000000000000',
      volume: '2793947.5900000000000000',
      quoteVolume: 89255709.65780656,
      btcVolume: 10474.381377841331,
      usdVolume: 89255709.65780656,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '30.8093000000000000',
      high: '31.4673000000000000',
      low: '28.7168000000000000',
      close: '29.2451000000000000',
      volume: '2872894.7300000000000000',
      quoteVolume: 86509446.32841438,
      btcVolume: 10980.6600454644,
      usdVolume: 86509446.32841438,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '29.2451000000000000',
      high: '31.1843000000000000',
      low: '28.9440000000000000',
      close: '30.8755000000000000',
      volume: '3345930.6000000000000000',
      quoteVolume: 100255371.95814995,
      btcVolume: 12913.379731084178,
      usdVolume: 100255371.95814995,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '30.8887000000000000',
      high: '31.9906000000000000',
      low: '29.6300000000000000',
      close: '31.0888000000000000',
      volume: '2987983.0800000000000000',
      quoteVolume: 93095763.1398957,
      btcVolume: 12017.344452736703,
      usdVolume: 93095763.1398957,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '31.0888000000000000',
      high: '31.9911000000000000',
      low: '30.9001000000000000',
      close: '31.3098000000000000',
      volume: '2758670.5000000000000000',
      quoteVolume: 86875753.11986831,
      btcVolume: 10951.248485073238,
      usdVolume: 86875753.11986831,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '31.3478000000000000',
      high: '32.2926000000000000',
      low: '31.1071000000000000',
      close: '31.7045000000000000',
      volume: '3228596.5900000000000000',
      quoteVolume: 102524672.99267428,
      btcVolume: 12945.174416648542,
      usdVolume: 102524672.99267428,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '31.7293000000000000',
      high: '31.8500000000000000',
      low: '29.7611000000000000',
      close: '30.0950000000000000',
      volume: '2533570.1800000000000000',
      quoteVolume: 78429078.60609235,
      btcVolume: 10087.18462271012,
      usdVolume: 78429078.60609235,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '30.1174000000000000',
      high: '32.0438000000000000',
      low: '29.7286000000000000',
      close: '32.0271000000000000',
      volume: '3550510.6300000000000000',
      quoteVolume: 110950707.9152291,
      btcVolume: 14185.907492697892,
      usdVolume: 110950707.9152291,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '32.0261000000000000',
      high: '32.0821000000000000',
      low: '30.9312000000000000',
      close: '31.8889000000000000',
      volume: '2693421.5300000000000000',
      quoteVolume: 84894700.01127566,
      btcVolume: 10799.407953421249,
      usdVolume: 84894700.01127566,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '31.8889000000000000',
      high: '34.9800000000000000',
      low: '31.8008000000000000',
      close: '34.8310000000000000',
      volume: '3941279.3400000000000000',
      quoteVolume: 132789273.60234739,
      btcVolume: 16598.78224827631,
      usdVolume: 132789273.60234739,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '34.8284000000000000',
      high: '36.3689000000000000',
      low: '34.1200000000000000',
      close: '35.2510000000000000',
      volume: '3537185.5100000000000000',
      quoteVolume: 124552938.20500359,
      btcVolume: 15300.412184978906,
      usdVolume: 124552938.20500359,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '35.2577000000000000',
      high: '35.3000000000000000',
      low: '29.8146000000000000',
      close: '32.3803000000000000',
      volume: '4820144.3200000000000000',
      quoteVolume: 155335662.97399047,
      btcVolume: 18662.671237915034,
      usdVolume: 155335662.97399047,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '32.3887000000000000',
      high: '33.9420000000000000',
      low: '31.6865000000000000',
      close: '32.7500000000000000',
      volume: '3389863.2300000000000000',
      quoteVolume: 111183078.4554273,
      btcVolume: 12804.521200155277,
      usdVolume: 111183078.4554273,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '32.7303000000000000',
      high: '33.2943000000000000',
      low: '31.7316000000000000',
      close: '32.1888000000000000',
      volume: '2826534.9700000000000000',
      quoteVolume: 92109821.4809208,
      btcVolume: 10183.184045346163,
      usdVolume: 92109821.4809208,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '32.1956000000000000',
      high: '34.4500000000000000',
      low: '32.1862000000000000',
      close: '33.8286000000000000',
      volume: '3325355.0300000000000000',
      quoteVolume: 111126704.95099491,
      btcVolume: 12078.924988720071,
      usdVolume: 111126704.95099491,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '33.8542000000000000',
      high: '35.0749000000000000',
      low: '33.7386000000000000',
      close: '34.4333000000000000',
      volume: '3404156.4000000000000000',
      quoteVolume: 117184660.8514287,
      btcVolume: 12820.477601301915,
      usdVolume: 117142616.23937275,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '34.4261000000000000',
      high: '35.6000000000000000',
      low: '34.3939000000000000',
      close: '35.1888000000000000',
      volume: '3662780.3500000000000000',
      quoteVolume: 128944461.18892004,
      btcVolume: 14098.563163206594,
      usdVolume: 128944461.18892004,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '35.1764000000000000',
      high: '36.9146000000000000',
      low: '34.3979000000000000',
      close: '36.8200000000000000',
      volume: '4059479.0200000000000000',
      quoteVolume: 145554685.05118558,
      btcVolume: 15614.242998911897,
      usdVolume: 145554685.05118558,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '36.8377000000000000',
      high: '38.8500000000000000',
      low: '36.3364000000000000',
      close: '38.6666000000000000',
      volume: '3532992.4300000000000000',
      quoteVolume: 132425793.40442118,
      btcVolume: 13534.113990105783,
      usdVolume: 132425793.40442118,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '38.6666000000000000',
      high: '39.5941000000000000',
      low: '37.1100000000000000',
      close: '38.0000000000000000',
      volume: '3287617.1200000000000000',
      quoteVolume: 126851635.12920612,
      btcVolume: 11861.268781544859,
      usdVolume: 126851635.12920612,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '38.0001000000000000',
      high: '38.5320000000000000',
      low: '37.2600000000000000',
      close: '37.2836000000000000',
      volume: '2788591.8500000000000000',
      quoteVolume: 105493792.36592467,
      btcVolume: 9731.003920100708,
      usdVolume: 105493792.36592467,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '37.2665000000000000',
      high: '37.8210000000000000',
      low: '36.0001000000000000',
      close: '37.4300000000000000',
      volume: '2631717.1900000000000000',
      quoteVolume: 97880605.03243802,
      btcVolume: 8975.410919955108,
      usdVolume: 97880605.03243802,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '37.4300000000000000',
      high: '37.5510000000000000',
      low: '35.7100000000000000',
      close: '36.0947000000000000',
      volume: '2000864.9200000000000000',
      quoteVolume: 73473294.34677196,
      btcVolume: 6467.382207788116,
      usdVolume: 73473294.34677196,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '36.0941000000000000',
      high: '38.0000000000000000',
      low: '34.5000000000000000',
      close: '36.0999000000000000',
      volume: '2758448.8600000000000000',
      quoteVolume: 100936180.80838415,
      btcVolume: 7924.993856109126,
      usdVolume: 100936180.80838415,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '36.0999000000000000',
      high: '36.3500000000000000',
      low: '33.1584000000000000',
      close: '34.3999000000000000',
      volume: '2591182.3600000000000000',
      quoteVolume: 89690344.30455235,
      btcVolume: 7533.165332525957,
      usdVolume: 89690344.30455235,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '34.3420000000000000',
      high: '35.2809000000000000',
      low: '33.8200000000000000',
      close: '34.6289000000000000',
      volume: '2104962.2500000000000000',
      quoteVolume: 72480203.20366873,
      btcVolume: 6101.8047057837575,
      usdVolume: 72480203.20366873,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '34.5951000000000000',
      high: '35.8000000000000000',
      low: '33.2447000000000000',
      close: '35.5000000000000000',
      volume: '2585753.6300000000000000',
      quoteVolume: 89769494.98459294,
      btcVolume: 7508.666820715312,
      usdVolume: 89769494.98459294,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '35.5111000000000000',
      high: '35.5111000000000000',
      low: '32.0200000000000000',
      close: '32.1314000000000000',
      volume: '2022991.5500000000000000',
      quoteVolume: 68980161.28362553,
      btcVolume: 5906.05441303598,
      usdVolume: 68980161.28362553,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '32.1140000000000000',
      high: '34.0500000000000000',
      low: '31.3682000000000000',
      close: '33.2777000000000000',
      volume: '1834460.9000000000000000',
      quoteVolume: 59929948.94285722,
      btcVolume: 5568.470684819062,
      usdVolume: 59929948.94285722,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '33.2777000000000000',
      high: '34.0000000000000000',
      low: '30.5600000000000000',
      close: '31.9570000000000000',
      volume: '2016746.5900000000000000',
      quoteVolume: 64596527.83502798,
      btcVolume: 6368.043385041789,
      usdVolume: 64596527.83502798,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '31.9772000000000000',
      high: '33.0500000000000000',
      low: '31.6230000000000000',
      close: '32.5414000000000000',
      volume: '1930269.8700000000000000',
      quoteVolume: 62412176.663419046,
      btcVolume: 5508.986915116347,
      usdVolume: 62412176.663419046,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '32.5237000000000000',
      high: '34.3839000000000000',
      low: '32.1503000000000000',
      close: '33.0888000000000000',
      volume: '2193452.4800000000000000',
      quoteVolume: 73032732.02201295,
      btcVolume: 6260.154521227522,
      usdVolume: 73032732.02201295,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '33.0399000000000000',
      high: '33.6497000000000000',
      low: '32.4000000000000000',
      close: '32.5566000000000000',
      volume: '1501816.7600000000000000',
      quoteVolume: 49493032.56383061,
      btcVolume: 4446.897333852955,
      usdVolume: 49493032.56383061,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '32.5507000000000000',
      high: '33.1815000000000000',
      low: '32.5000000000000000',
      close: '32.5888000000000000',
      volume: '1122287.4400000000000000',
      quoteVolume: 36787236.23351705,
      btcVolume: 3237.2866952944937,
      usdVolume: 36787236.23351705,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '32.5471000000000000',
      high: '33.3997000000000000',
      low: '32.4000000000000000',
      close: '32.9344000000000000',
      volume: '1219900.0500000000000000',
      quoteVolume: 40138364.745880224,
      btcVolume: 3553.1949115873035,
      usdVolume: 40138364.745880224,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '32.9326000000000000',
      high: '34.2918000000000000',
      low: '32.7500000000000000',
      close: '33.3888000000000000',
      volume: '1483935.3600000000000000',
      quoteVolume: 49789526.97420341,
      btcVolume: 4261.852971883836,
      usdVolume: 49789526.97420341,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '33.3888000000000000',
      high: '33.9000000000000000',
      low: '32.2195000000000000',
      close: '32.5075000000000000',
      volume: '1212484.7200000000000000',
      quoteVolume: 40118253.17297037,
      btcVolume: 3221.2079585364836,
      usdVolume: 40118253.17297037,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '32.4825000000000000',
      high: '32.8900000000000000',
      low: '30.5100000000000000',
      close: '31.2220000000000000',
      volume: '1575508.6000000000000000',
      quoteVolume: 50081252.967029475,
      btcVolume: 3955.920822486626,
      usdVolume: 50081252.967029475,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '31.2220000000000000',
      high: '31.8900000000000000',
      low: '28.2666000000000000',
      close: '29.4181000000000000',
      volume: '1727744.5200000000000000',
      quoteVolume: 51340643.34346419,
      btcVolume: 4462.463255903058,
      usdVolume: 51340643.34346419,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '29.4213000000000000',
      high: '32.6219000000000000',
      low: '29.0000000000000000',
      close: '31.8894000000000000',
      volume: '2941848.2500000000000000',
      quoteVolume: 92635627.07000853,
      btcVolume: 8103.016132792709,
      usdVolume: 92635627.07000853,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '31.8874000000000000',
      high: '32.4894000000000000',
      low: '30.2075000000000000',
      close: '31.4888000000000000',
      volume: '1746699.7900000000000000',
      quoteVolume: 54982624.17098898,
      btcVolume: 4813.480581114553,
      usdVolume: 54982624.17098898,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '31.4701000000000000',
      high: '31.5259000000000000',
      low: '28.3111000000000000',
      close: '28.5589000000000000',
      volume: '2103525.3200000000000000',
      quoteVolume: 62793063.28152121,
      btcVolume: 5821.32081882098,
      usdVolume: 62793063.28152121,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '28.5584000000000000',
      high: '28.7498000000000000',
      low: '26.3190000000000000',
      close: '28.6489000000000000',
      volume: '2954452.0200000000000000',
      quoteVolume: 81881912.98124091,
      btcVolume: 7918.900039057427,
      usdVolume: 81881912.98124091,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '28.6348000000000000',
      high: '29.5800000000000000',
      low: '24.8626000000000000',
      close: '25.0601000000000000',
      volume: '3691855.4300000000000000',
      quoteVolume: 100236711.8868326,
      btcVolume: 9803.524590355422,
      usdVolume: 100236711.8868326,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '25.0552000000000000',
      high: '28.2898000000000000',
      low: '24.1709000000000000',
      close: '27.6258000000000000',
      volume: '5619617.8800000000000000',
      quoteVolume: 147693999.79602024,
      btcVolume: 15463.868456792456,
      usdVolume: 147693999.79602024,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '27.6264000000000000',
      high: '29.5000000000000000',
      low: '26.8000000000000000',
      close: '28.9381000000000000',
      volume: '6265532.8900000000000000',
      quoteVolume: 177710944.23860273,
      btcVolume: 17945.243760879956,
      usdVolume: 177608163.10294086,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '28.9521000000000000',
      high: '29.9600000000000000',
      low: '28.3158000000000000',
      close: '29.0096000000000000',
      volume: '3925662.8400000000000000',
      quoteVolume: 113855677.67222823,
      btcVolume: 10894.61710090789,
      usdVolume: 113855677.67222823,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '29.0015000000000000',
      high: '31.4600000000000000',
      low: '28.9000000000000000',
      close: '31.0932000000000000',
      volume: '2868452.7700000000000000',
      quoteVolume: 87962899.98284975,
      btcVolume: 8266.346344725387,
      usdVolume: 87962899.98284975,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '31.1222000000000000',
      high: '31.5281000000000000',
      low: '29.2801000000000000',
      close: '30.4388000000000000',
      volume: '2165383.6300000000000000',
      quoteVolume: 65800415.77082615,
      btcVolume: 6225.919909520394,
      usdVolume: 65800415.77082615,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '30.4389000000000000',
      high: '32.6000000000000000',
      low: '29.6203000000000000',
      close: '30.8850000000000000',
      volume: '3465285.3200000000000000',
      quoteVolume: 107521586.41408998,
      btcVolume: 10273.673397545184,
      usdVolume: 107521586.41408998,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '30.9226000000000000',
      high: '30.9400000000000000',
      low: '28.9471000000000000',
      close: '29.4894000000000000',
      volume: '2638959.1800000000000000',
      quoteVolume: 78461044.85644288,
      btcVolume: 7795.804246913197,
      usdVolume: 78461044.85644288,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '29.4649000000000000',
      high: '29.5057000000000000',
      low: '27.7426000000000000',
      close: '28.8303000000000000',
      volume: '2335697.9600000000000000',
      quoteVolume: 66771505.28094476,
      btcVolume: 6868.82631676914,
      usdVolume: 66771505.28094476,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '28.8304000000000000',
      high: '29.6596000000000000',
      low: '28.4444000000000000',
      close: '28.9395000000000000',
      volume: '2189105.6200000000000000',
      quoteVolume: 63998748.52433625,
      btcVolume: 6402.849558443817,
      usdVolume: 63998748.52433625,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '28.9227000000000000',
      high: '29.0500000000000000',
      low: '28.1641000000000000',
      close: '28.8983000000000000',
      volume: '1926812.0300000000000000',
      quoteVolume: 55269615.02700419,
      btcVolume: 5653.511709576811,
      usdVolume: 55269615.02700419,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '28.8983000000000000',
      high: '29.4502000000000000',
      low: '27.2061000000000000',
      close: '27.7498000000000000',
      volume: '1482247.8300000000000000',
      quoteVolume: 41938098.57057801,
      btcVolume: 4304.517936922523,
      usdVolume: 41938098.57057801,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '27.7384000000000000',
      high: '27.8459000000000000',
      low: '26.4200000000000000',
      close: '27.7627000000000000',
      volume: '1320975.1600000000000000',
      quoteVolume: 36374310.99186316,
      btcVolume: 3832.5016194283835,
      usdVolume: 36374310.99186316,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '27.7581000000000000',
      high: '28.0419000000000000',
      low: '26.8202000000000000',
      close: '26.9525000000000000',
      volume: '1140682.0500000000000000',
      quoteVolume: 31110138.426472325,
      btcVolume: 3256.314799181455,
      usdVolume: 31110138.426472325,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '26.9540000000000000',
      high: '27.1198000000000000',
      low: '26.2020000000000000',
      close: '26.9867000000000000',
      volume: '820023.9000000000000000',
      quoteVolume: 21902889.9898799,
      btcVolume: 2296.8262791212037,
      usdVolume: 21902889.9898799,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '26.9879000000000000',
      high: '27.8300000000000000',
      low: '26.7800000000000000',
      close: '27.7348000000000000',
      volume: '1934428.9000000000000000',
      quoteVolume: 53152588.64401577,
      btcVolume: 5403.686098362969,
      usdVolume: 53152588.64401577,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '27.7500000000000000',
      high: '28.7664000000000000',
      low: '27.4500000000000000',
      close: '28.7088000000000000',
      volume: '2252485.2300000000000000',
      quoteVolume: 63463951.041090965,
      btcVolume: 6318.884088129215,
      usdVolume: 63463951.041090965,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '28.7100000000000000',
      high: '28.7186000000000000',
      low: '27.5643000000000000',
      close: '28.1400000000000000',
      volume: '1501539.5400000000000000',
      quoteVolume: 42179913.12631353,
      btcVolume: 4032.0149580734223,
      usdVolume: 42179913.12631353,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '28.1400000000000000',
      high: '28.3409000000000000',
      low: '27.5000000000000000',
      close: '27.7285000000000000',
      volume: '1209342.2000000000000000',
      quoteVolume: 33690305.912137166,
      btcVolume: 3130.3892108291648,
      usdVolume: 33690305.912137166,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '27.7333000000000000',
      high: '27.7719000000000000',
      low: '27.0852000000000000',
      close: '27.3834000000000000',
      volume: '967494.6400000000000000',
      quoteVolume: 26514204.222139858,
      btcVolume: 2463.628698032826,
      usdVolume: 26514204.222139858,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '27.3834000000000000',
      high: '28.3000000000000000',
      low: '27.3011000000000000',
      close: '28.0107000000000000',
      volume: '1170209.2600000000000000',
      quoteVolume: 32618569.941277068,
      btcVolume: 2806.4498779368155,
      usdVolume: 32618569.941277068,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '28.0117000000000000',
      high: '28.3125000000000000',
      low: '27.3333000000000000',
      close: '27.6523000000000000',
      volume: '1404874.7100000000000000',
      quoteVolume: 39075613.06976784,
      btcVolume: 3302.2858155088666,
      usdVolume: 39075613.06976784,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '27.6539000000000000',
      high: '29.9800000000000000',
      low: '27.3330000000000000',
      close: '29.6694000000000000',
      volume: '2398210.6200000000000000',
      quoteVolume: 68536315.25246768,
      btcVolume: 5832.360785093793,
      usdVolume: 68536315.25246768,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '29.6690000000000000',
      high: '31.8791000000000000',
      low: '29.1914000000000000',
      close: '31.1427000000000000',
      volume: '3071252.6500000000000000',
      quoteVolume: 94636423.46255855,
      btcVolume: 8004.065605940615,
      usdVolume: 94636423.46255855,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '31.1074000000000000',
      high: '31.5000000000000000',
      low: '29.6666000000000000',
      close: '29.8188000000000000',
      volume: '1467817.4400000000000000',
      quoteVolume: 45035359.27979547,
      btcVolume: 3801.756204313858,
      usdVolume: 45035359.27979547,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '29.7969000000000000',
      high: '30.0200000000000000',
      low: '29.0333000000000000',
      close: '29.6662000000000000',
      volume: '1302579.2400000000000000',
      quoteVolume: 38469949.58556509,
      btcVolume: 3320.6255793968776,
      usdVolume: 38469949.58556509,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '29.6660000000000000',
      high: '30.4458000000000000',
      low: '29.3000000000000000',
      close: '30.2109000000000000',
      volume: '1563528.9000000000000000',
      quoteVolume: 46830334.26929312,
      btcVolume: 4107.984996045789,
      usdVolume: 46830334.26929312,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '30.3192000000000000',
      high: '30.5936000000000000',
      low: '29.8230000000000000',
      close: '30.1856000000000000',
      volume: '1527254.3500000000000000',
      quoteVolume: 46134967.60864254,
      btcVolume: 4043.0634741807817,
      usdVolume: 46134967.60864254,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '30.1856000000000000',
      high: '30.4496000000000000',
      low: '29.2000000000000000',
      close: '29.4537000000000000',
      volume: '1164233.9000000000000000',
      quoteVolume: 34747155.42068626,
      btcVolume: 3108.3618369887367,
      usdVolume: 34747155.42068626,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '29.4512000000000000',
      high: '29.4693000000000000',
      low: '26.7500000000000000',
      close: '27.0106000000000000',
      volume: '1176934.2300000000000000',
      quoteVolume: 33298167.10513729,
      btcVolume: 3178.5215896041464,
      usdVolume: 33298167.10513729,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '27.0269000000000000',
      high: '28.2174000000000000',
      low: '26.7000000000000000',
      close: '28.1058000000000000',
      volume: '1242352.4600000000000000',
      quoteVolume: 34322334.57446039,
      btcVolume: 3395.545034113294,
      usdVolume: 34322334.57446039,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '28.1052000000000000',
      high: '28.1313000000000000',
      low: '26.7880000000000000',
      close: '27.5111000000000000',
      volume: '965369.6300000000000000',
      quoteVolume: 26500903.438648794,
      btcVolume: 2602.9765482710295,
      usdVolume: 26500903.438648794,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '27.4908000000000000',
      high: '27.9605000000000000',
      low: '26.9863000000000000',
      close: '27.5038000000000000',
      volume: '963986.7000000000000000',
      quoteVolume: 26558675.591700017,
      btcVolume: 2579.2725537309097,
      usdVolume: 26558675.591700017,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '27.5037000000000000',
      high: '28.1797000000000000',
      low: '27.0511000000000000',
      close: '27.8977000000000000',
      volume: '1210841.2500000000000000',
      quoteVolume: 33534708.06565196,
      btcVolume: 3250.687025349346,
      usdVolume: 33534708.06565196,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '27.9033000000000000',
      high: '29.1779000000000000',
      low: '27.6884000000000000',
      close: '28.7931000000000000',
      volume: '1802460.6600000000000000',
      quoteVolume: 51651237.54506001,
      btcVolume: 4855.6186625084,
      usdVolume: 51651237.54506001,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '28.7974000000000000',
      high: '28.8079000000000000',
      low: '27.5200000000000000',
      close: '28.0111000000000000',
      volume: '1230912.5200000000000000',
      quoteVolume: 34656879.16071867,
      btcVolume: 3223.4213999415306,
      usdVolume: 34656879.16071867,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '28.0110000000000000',
      high: '28.0510000000000000',
      low: '26.3000000000000000',
      close: '26.9250000000000000',
      volume: '1007955.5700000000000000',
      quoteVolume: 27333103.0550634,
      btcVolume: 2672.2406391647623,
      usdVolume: 27333103.0550634,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '26.9328000000000000',
      high: '27.3301000000000000',
      low: '26.2110000000000000',
      close: '26.9409000000000000',
      volume: '1087088.9100000000000000',
      quoteVolume: 29207188.464991644,
      btcVolume: 2909.9205678527996,
      usdVolume: 29207188.464991644,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '26.9652000000000000',
      high: '27.2864000000000000',
      low: '26.7400000000000000',
      close: '26.9400000000000000',
      volume: '880521.1800000000000000',
      quoteVolume: 23808910.937024746,
      btcVolume: 2322.2513891613335,
      usdVolume: 23808910.937024746,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '26.9399000000000000',
      high: '26.9586000000000000',
      low: '26.0100000000000000',
      close: '26.2814000000000000',
      volume: '917921.5400000000000000',
      quoteVolume: 24273248.70356496,
      btcVolume: 2390.3516691531204,
      usdVolume: 24273248.70356496,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '26.2982000000000000',
      high: '26.4000000000000000',
      low: '25.5700000000000000',
      close: '26.0888000000000000',
      volume: '933021.8300000000000000',
      quoteVolume: 24320148.965540506,
      btcVolume: 2410.161394681131,
      usdVolume: 24320148.965540506,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '26.0886000000000000',
      high: '27.1908000000000000',
      low: '25.6502000000000000',
      close: '26.1489000000000000',
      volume: '1811039.1700000000000000',
      quoteVolume: 47866074.05944471,
      btcVolume: 4619.226473501923,
      usdVolume: 47866074.05944471,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '26.1251000000000000',
      high: '26.1507000000000000',
      low: '24.6224000000000000',
      close: '25.6051000000000000',
      volume: '1196840.9100000000000000',
      quoteVolume: 30351679.124037657,
      btcVolume: 2975.526344844052,
      usdVolume: 30351679.124037657,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '25.6056000000000000',
      high: '26.1290000000000000',
      low: '23.2030000000000000',
      close: '23.3565000000000000',
      volume: '1398347.3100000000000000',
      quoteVolume: 34916854.38443927,
      btcVolume: 3480.137824867305,
      usdVolume: 34916854.38443927,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '23.3559000000000000',
      high: '23.3559000000000000',
      low: '21.2202000000000000',
      close: '21.7777000000000000',
      volume: '1617357.3700000000000000',
      quoteVolume: 35791217.56290334,
      btcVolume: 3750.9222576047623,
      usdVolume: 35791217.56290334,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '21.7894000000000000',
      high: '22.5678000000000000',
      low: '21.5000000000000000',
      close: '22.1448000000000000',
      volume: '1599602.4900000000000000',
      quoteVolume: 35445343.4393091,
      btcVolume: 3714.583705432022,
      usdVolume: 35445343.4393091,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '22.1449000000000000',
      high: '22.3700000000000000',
      low: '20.8857000000000000',
      close: '21.0604000000000000',
      volume: '1372689.9900000000000000',
      quoteVolume: 29884844.230699934,
      btcVolume: 3119.26041144571,
      usdVolume: 29884844.230699934,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '21.0602000000000000',
      high: '21.6828000000000000',
      low: '20.9800000000000000',
      close: '21.4248000000000000',
      volume: '1434196.5900000000000000',
      quoteVolume: 30594531.66041575,
      btcVolume: 3180.3090835419152,
      usdVolume: 30594531.66041575,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '21.4195000000000000',
      high: '22.8303000000000000',
      low: '21.0100000000000000',
      close: '22.7449000000000000',
      volume: '1743830.8100000000000000',
      quoteVolume: 38331837.52907952,
      btcVolume: 3871.494591953881,
      usdVolume: 38331837.52907952,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '22.7449000000000000',
      high: '22.8900000000000000',
      low: '22.1615000000000000',
      close: '22.3511000000000000',
      volume: '1355171.8400000000000000',
      quoteVolume: 30562302.513001986,
      btcVolume: 2912.5933500976334,
      usdVolume: 30562302.513001986,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '22.3521000000000000',
      high: '22.5998000000000000',
      low: '21.4699000000000000',
      close: '21.6764000000000000',
      volume: '1086116.6600000000000000',
      quoteVolume: 23806043.422344267,
      btcVolume: 2257.8192440245984,
      usdVolume: 23806043.422344267,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '21.6821000000000000',
      high: '23.5213000000000000',
      low: '21.3000000000000000',
      close: '22.6385000000000000',
      volume: '2235402.4400000000000000',
      quoteVolume: 50322754.76862728,
      btcVolume: 4775.140325449655,
      usdVolume: 50322754.76862728,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '22.6389000000000000',
      high: '23.4450000000000000',
      low: '21.6997000000000000',
      close: '21.9905000000000000',
      volume: '1990229.3000000000000000',
      quoteVolume: 44871162.02233011,
      btcVolume: 4221.877198386819,
      usdVolume: 44871162.02233011,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '21.9906000000000000',
      high: '22.6500000000000000',
      low: '21.9100000000000000',
      close: '22.4120000000000000',
      volume: '1681983.7800000000000000',
      quoteVolume: 37521579.67938833,
      btcVolume: 3608.3791504554933,
      usdVolume: 37521579.67938833,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '22.4120000000000000',
      high: '22.7321000000000000',
      low: '22.0000000000000000',
      close: '22.4111000000000000',
      volume: '1304840.1500000000000000',
      quoteVolume: 29335266.506811257,
      btcVolume: 2806.5841675990073,
      usdVolume: 29335266.506811257,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '22.3747000000000000',
      high: '22.4901000000000000',
      low: '21.7400000000000000',
      close: '22.1046000000000000',
      volume: '1006341.0100000000000000',
      quoteVolume: 22302503.142360844,
      btcVolume: 2165.136904136606,
      usdVolume: 22302503.142360844,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '22.0978000000000000',
      high: '22.3262000000000000',
      low: '21.5000000000000000',
      close: '21.7679000000000000',
      volume: '831886.4700000000000000',
      quoteVolume: 18339145.368692745,
      btcVolume: 1792.2664010096803,
      usdVolume: 18339145.368692745,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '21.7704000000000000',
      high: '22.0724000000000000',
      low: '20.5413000000000000',
      close: '20.7334000000000000',
      volume: '1069114.6200000000000000',
      quoteVolume: 22669645.665027037,
      btcVolume: 2253.2292197146694,
      usdVolume: 22669645.665027037,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '20.7315000000000000',
      high: '20.9572000000000000',
      low: '19.6500000000000000',
      close: '20.8197000000000000',
      volume: '1699362.5600000000000000',
      quoteVolume: 34777083.85403479,
      btcVolume: 3420.318759152667,
      usdVolume: 34777083.85403479,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '20.8197000000000000',
      high: '21.2475000000000000',
      low: '20.4088000000000000',
      close: '20.7880000000000000',
      volume: '1429270.9500000000000000',
      quoteVolume: 29787724.15361781,
      btcVolume: 2889.221782175787,
      usdVolume: 29787724.15361781,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '20.7889000000000000',
      high: '21.1500000000000000',
      low: '20.6714000000000000',
      close: '20.9555000000000000',
      volume: '1305740.9000000000000000',
      quoteVolume: 27255071.779076073,
      btcVolume: 2640.2704864370567,
      usdVolume: 27255071.779076073,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '20.9438000000000000',
      high: '21.0419000000000000',
      low: '20.2619000000000000',
      close: '20.2901000000000000',
      volume: '1149167.0800000000000000',
      quoteVolume: 23790454.124340992,
      btcVolume: 2309.5205840680474,
      usdVolume: 23790454.124340992,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '20.2901000000000000',
      high: '20.8000000000000000',
      low: '19.8775000000000000',
      close: '20.2422000000000000',
      volume: '1032883.1400000000000000',
      quoteVolume: 21027752.240456037,
      btcVolume: 2049.967916517665,
      usdVolume: 21027752.240456037,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '20.2460000000000000',
      high: '21.4390000000000000',
      low: '20.0300000000000000',
      close: '21.0473000000000000',
      volume: '1845592.2700000000000000',
      quoteVolume: 38220802.17507817,
      btcVolume: 3744.021260306312,
      usdVolume: 38220802.17507817,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '21.0302000000000000',
      high: '23.1700000000000000',
      low: '20.9442000000000000',
      close: '22.1986000000000000',
      volume: '3302750.2400000000000000',
      quoteVolume: 73063135.11076553,
      btcVolume: 7173.648061693396,
      usdVolume: 73063135.11076553,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '22.1988000000000000',
      high: '22.4383000000000000',
      low: '20.0022000000000000',
      close: '21.5376000000000000',
      volume: '1560199.9000000000000000',
      quoteVolume: 33005910.961343434,
      btcVolume: 3309.6496506288595,
      usdVolume: 33005910.961343434,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '21.5281000000000000',
      high: '21.5764000000000000',
      low: '20.9005000000000000',
      close: '21.2695000000000000',
      volume: '1389940.6700000000000000',
      quoteVolume: 29611689.325407807,
      btcVolume: 2910.330413478063,
      usdVolume: 29611689.325407807,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '21.2741000000000000',
      high: '21.4494000000000000',
      low: '20.8096000000000000',
      close: '20.9666000000000000',
      volume: '1127512.7000000000000000',
      quoteVolume: 23814050.32814106,
      btcVolume: 2369.7781077006107,
      usdVolume: 23814050.32814106,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '20.9732000000000000',
      high: '20.9912000000000000',
      low: '20.0000000000000000',
      close: '20.3602000000000000',
      volume: '972362.6300000000000000',
      quoteVolume: 19879295.326771013,
      btcVolume: 1991.1559276366263,
      usdVolume: 19879295.326771013,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '20.3640000000000000',
      high: '20.3640000000000000',
      low: '19.1614000000000000',
      close: '19.3228000000000000',
      volume: '1094197.9900000000000000',
      quoteVolume: 21719659.651494753,
      btcVolume: 2196.348015116035,
      usdVolume: 21719659.651494753,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '19.3127000000000000',
      high: '19.5000000000000000',
      low: '14.6400000000000000',
      close: '15.8249000000000000',
      volume: '2280940.4000000000000000',
      quoteVolume: 40128590.36126655,
      btcVolume: 4420.050188018196,
      usdVolume: 40128590.36126655,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '15.8240000000000000',
      high: '16.4882000000000000',
      low: '14.8945000000000000',
      close: '16.0321000000000000',
      volume: '1826023.8900000000000000',
      quoteVolume: 28771070.64009279,
      btcVolume: 3404.4134048413493,
      usdVolume: 28771070.64009279,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '16.0138000000000000',
      high: '16.4000000000000000',
      low: '14.2555000000000000',
      close: '15.2212000000000000',
      volume: '1777727.2900000000000000',
      quoteVolume: 27673009.60844759,
      btcVolume: 3368.7647208454014,
      usdVolume: 27673009.60844759,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '15.2113000000000000',
      high: '15.7770000000000000',
      low: '14.7000000000000000',
      close: '15.6666000000000000',
      volume: '1435874.0300000000000000',
      quoteVolume: 21891944.29132878,
      btcVolume: 2721.037106291271,
      usdVolume: 21891944.29132878,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '15.6666000000000000',
      high: '15.8729000000000000',
      low: '15.2911000000000000',
      close: '15.7346000000000000',
      volume: '1097731.6800000000000000',
      quoteVolume: 17158364.84970291,
      btcVolume: 2104.8652182188,
      usdVolume: 17158364.84970291,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '15.7335000000000000',
      high: '15.7953000000000000',
      low: '14.8018000000000000',
      close: '15.1999000000000000',
      volume: '963970.0200000000000000',
      quoteVolume: 14796006.704381201,
      btcVolume: 1833.4019387024855,
      usdVolume: 14796006.704381201,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '15.1870000000000000',
      high: '15.8506000000000000',
      low: '14.6200000000000000',
      close: '15.8349000000000000',
      volume: '1129249.1300000000000000',
      quoteVolume: 17240225.00851707,
      btcVolume: 2144.011528382059,
      usdVolume: 17240225.00851707,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '15.8493000000000000',
      high: '16.2800000000000000',
      low: '15.6295000000000000',
      close: '15.8563000000000000',
      volume: '1180076.1200000000000000',
      quoteVolume: 18822946.423811994,
      btcVolume: 2253.1708840608026,
      usdVolume: 18822946.423811994,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '15.8689000000000000',
      high: '15.8892000000000000',
      low: '15.5000000000000000',
      close: '15.8742000000000000',
      volume: '950281.1200000000000000',
      quoteVolume: 14907087.290238062,
      btcVolume: 1806.9759980656027,
      usdVolume: 14907087.290238062,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '15.8900000000000000',
      high: '15.9290000000000000',
      low: '15.2500000000000000',
      close: '15.6377000000000000',
      volume: '904723.7000000000000000',
      quoteVolume: 14166132.276806083,
      btcVolume: 1718.482479568365,
      usdVolume: 14166132.276806083,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '15.6489000000000000',
      high: '15.8200000000000000',
      low: '15.2100000000000000',
      close: '15.6133000000000000',
      volume: '857614.2600000000000000',
      quoteVolume: 13323519.193896951,
      btcVolume: 1637.9909754976863,
      usdVolume: 13323519.193896951,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '15.6028000000000000',
      high: '15.7794000000000000',
      low: '15.3644000000000000',
      close: '15.6869000000000000',
      volume: '815215.7400000000000000',
      quoteVolume: 12687937.458294004,
      btcVolume: 1565.997866828115,
      usdVolume: 12687937.458294004,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '15.6866000000000000',
      high: '15.7299000000000000',
      low: '14.9100000000000000',
      close: '15.0688000000000000',
      volume: '814844.4500000000000000',
      quoteVolume: 12466458.898545044,
      btcVolume: 1563.9266798482508,
      usdVolume: 12466458.898545044,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '15.0655000000000000',
      high: '16.1280000000000000',
      low: '14.8555000000000000',
      close: '16.0030000000000000',
      volume: '1137667.6700000000000000',
      quoteVolume: 17614353.686007068,
      btcVolume: 2197.8583870903244,
      usdVolume: 17614353.686007068,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '16.0198000000000000',
      high: '16.4007000000000000',
      low: '15.7959000000000000',
      close: '16.0213000000000000',
      volume: '992780.4300000000000000',
      quoteVolume: 15889938.956474038,
      btcVolume: 1934.7114076545115,
      usdVolume: 15889938.956474038,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '16.0206000000000000',
      high: '17.9999000000000000',
      low: '15.8829000000000000',
      close: '17.7100000000000000',
      volume: '2809202.1000000000000000',
      quoteVolume: 47844345.02730628,
      btcVolume: 5781.710685310098,
      usdVolume: 47844345.02730628,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '17.7172000000000000',
      high: '17.9180000000000000',
      low: '17.0310000000000000',
      close: '17.5300000000000000',
      volume: '1785749.3400000000000000',
      quoteVolume: 31222666.861801084,
      btcVolume: 3655.173159325509,
      usdVolume: 31222666.861801084,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '17.5288000000000000',
      high: '17.7000000000000000',
      low: '16.5100000000000000',
      close: '16.5656000000000000',
      volume: '1476429.8400000000000000',
      quoteVolume: 25136224.21603257,
      btcVolume: 2982.0542530942034,
      usdVolume: 25136224.21603257,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '16.5665000000000000',
      high: '17.4868000000000000',
      low: '16.5059000000000000',
      close: '17.1700000000000000',
      volume: '1405829.2300000000000000',
      quoteVolume: 23921395.579711266,
      btcVolume: 2872.116767496237,
      usdVolume: 23921395.579711266,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '17.1666000000000000',
      high: '18.7699000000000000',
      low: '17.1110000000000000',
      close: '18.2560000000000000',
      volume: '2233894.4300000000000000',
      quoteVolume: 40107522.71327606,
      btcVolume: 4802.447899917144,
      usdVolume: 40107522.71327606,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '18.2560000000000000',
      high: '18.6275000000000000',
      low: '17.8511000000000000',
      close: '18.4376000000000000',
      volume: '1643019.3600000000000000',
      quoteVolume: 29971983.59315997,
      btcVolume: 3613.0751251258484,
      usdVolume: 29971983.59315997,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '18.4389000000000000',
      high: '19.1700000000000000',
      low: '18.1000000000000000',
      close: '18.5697000000000000',
      volume: '2913060.4200000000000000',
      quoteVolume: 54382456.61385796,
      btcVolume: 6569.711201030303,
      usdVolume: 54382456.61385796,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '18.5697000000000000',
      high: '18.6470000000000000',
      low: '17.3627000000000000',
      close: '17.7401000000000000',
      volume: '1815021.0600000000000000',
      quoteVolume: 32739450.98535026,
      btcVolume: 4055.212668955555,
      usdVolume: 32739450.98535026,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '17.7504000000000000',
      high: '18.8346000000000000',
      low: '17.5620000000000000',
      close: '18.5188000000000000',
      volume: '1889272.6600000000000000',
      quoteVolume: 34608013.92635818,
      btcVolume: 4311.318414716609,
      usdVolume: 34608013.92635818,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '18.5205000000000000',
      high: '18.7501000000000000',
      low: '17.6733000000000000',
      close: '18.2188000000000000',
      volume: '1427724.2100000000000000',
      quoteVolume: 26053677.954359047,
      btcVolume: 3266.035239146559,
      usdVolume: 26053677.954359047,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '18.2188000000000000',
      high: '18.4444000000000000',
      low: '17.8575000000000000',
      close: '18.2229000000000000',
      volume: '1457872.5200000000000000',
      quoteVolume: 26457012.36290216,
      btcVolume: 3326.33246552125,
      usdVolume: 26457012.36290216,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '18.2189000000000000',
      high: '18.5800000000000000',
      low: '17.7152000000000000',
      close: '18.4997000000000000',
      volume: '1696662.0200000000000000',
      quoteVolume: 30829354.6997274,
      btcVolume: 3832.375444981121,
      usdVolume: 30829354.6997274,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '18.4897000000000000',
      high: '18.5171000000000000',
      low: '17.9100000000000000',
      close: '18.2560000000000000',
      volume: '1497274.8700000000000000',
      quoteVolume: 27204985.86214098,
      btcVolume: 3313.6879489931994,
      usdVolume: 27204985.86214098,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '18.2551000000000000',
      high: '18.8000000000000000',
      low: '18.0692000000000000',
      close: '18.2286000000000000',
      volume: '1819185.3800000000000000',
      quoteVolume: 33532586.548880003,
      btcVolume: 4093.9879290171616,
      usdVolume: 33532586.548880003,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '18.2581000000000000',
      high: '18.2700000000000000',
      low: '16.2111000000000000',
      close: '16.6796000000000000',
      volume: '1735472.3000000000000000',
      quoteVolume: 30019967.217108868,
      btcVolume: 3879.4704562633056,
      usdVolume: 30019967.217108868,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '16.6798000000000000',
      high: '17.0987000000000000',
      low: '16.3100000000000000',
      close: '16.9194000000000000',
      volume: '1244966.9900000000000000',
      quoteVolume: 20790631.76627608,
      btcVolume: 2794.787971493176,
      usdVolume: 20790631.76627608,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '16.9194000000000000',
      high: '19.1110000000000000',
      low: '16.7516000000000000',
      close: '18.6188000000000000',
      volume: '2770630.2600000000000000',
      quoteVolume: 49737182.561813205,
      btcVolume: 6263.872839719397,
      usdVolume: 49737182.561813205,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '18.6189000000000000',
      high: '19.7653000000000000',
      low: '18.0308000000000000',
      close: '18.9501000000000000',
      volume: '3038020.4400000000000000',
      quoteVolume: 57490280.69272188,
      btcVolume: 6119.3718702883,
      usdVolume: 57490280.69272188,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '18.9311000000000000',
      high: '19.6300000000000000',
      low: '18.4744000000000000',
      close: '19.2888000000000000',
      volume: '2292790.2600000000000000',
      quoteVolume: 43689050.22110826,
      btcVolume: 4656.735220807045,
      usdVolume: 43689050.22110826,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '19.2938000000000000',
      high: '21.1500000000000000',
      low: '19.2600000000000000',
      close: '19.9390000000000000',
      volume: '2712590.2200000000000000',
      quoteVolume: 54705192.09512571,
      btcVolume: 5746.423873513421,
      usdVolume: 54705192.09512571,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '19.9379000000000000',
      high: '21.2378000000000000',
      low: '19.8100000000000000',
      close: '20.7452000000000000',
      volume: '2287705.3800000000000000',
      quoteVolume: 47070804.28249447,
      btcVolume: 5028.784777580716,
      usdVolume: 47070804.28249447,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '20.7300000000000000',
      high: '20.7975000000000000',
      low: '19.1076000000000000',
      close: '20.0520000000000000',
      volume: '1822916.4300000000000000',
      quoteVolume: 36219179.39048555,
      btcVolume: 3946.264713227161,
      usdVolume: 36219179.39048555,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '20.0500000000000000',
      high: '20.3700000000000000',
      low: '19.3529000000000000',
      close: '19.9099000000000000',
      volume: '1677653.1800000000000000',
      quoteVolume: 33444789.194522977,
      btcVolume: 3654.9820249353547,
      usdVolume: 33444789.194522977,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '19.9087000000000000',
      high: '20.0399000000000000',
      low: '19.6424000000000000',
      close: '19.9811000000000000',
      volume: '1304915.2800000000000000',
      quoteVolume: 25929970.432292167,
      btcVolume: 2837.8645933661815,
      usdVolume: 25929970.432292167,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '19.9988000000000000',
      high: '20.3000000000000000',
      low: '19.9600000000000000',
      close: '20.1745000000000000',
      volume: '1604349.3200000000000000',
      quoteVolume: 32341714.859536927,
      btcVolume: 3491.3146563665596,
      usdVolume: 32341714.859536927,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '20.1847000000000000',
      high: '20.3900000000000000',
      low: '19.8053000000000000',
      close: '20.1874000000000000',
      volume: '1331174.6000000000000000',
      quoteVolume: 26791642.74283898,
      btcVolume: 2906.420761934206,
      usdVolume: 26791642.74283898,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '20.1725000000000000',
      high: '20.8800000000000000',
      low: '20.0285000000000000',
      close: '20.6139000000000000',
      volume: '1638295.2000000000000000',
      quoteVolume: 33568729.35200188,
      btcVolume: 3633.9058084230064,
      usdVolume: 33568729.35200188,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '20.6139000000000000',
      high: '20.8900000000000000',
      low: '20.3200000000000000',
      close: '20.6588000000000000',
      volume: '1435673.9200000000000000',
      quoteVolume: 29643945.41316726,
      btcVolume: 3174.3640255474643,
      usdVolume: 29643945.41316726,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '20.6621000000000000',
      high: '20.9399000000000000',
      low: '20.4408000000000000',
      close: '20.7000000000000000',
      volume: '1304183.9900000000000000',
      quoteVolume: 26943504.690265935,
      btcVolume: 2886.089792710133,
      usdVolume: 26943504.690265935,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '20.6993000000000000',
      high: '20.8490000000000000',
      low: '19.9100000000000000',
      close: '20.4208000000000000',
      volume: '1034081.1100000000000000',
      quoteVolume: 21112048.776744843,
      btcVolume: 2279.6955633595517,
      usdVolume: 21112048.776744843,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '20.4342000000000000',
      high: '20.5164000000000000',
      low: '19.2609000000000000',
      close: '19.6460000000000000',
      volume: '1081524.1000000000000000',
      quoteVolume: 21620174.59220783,
      btcVolume: 2392.7004682323113,
      usdVolume: 21620174.59220783,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '19.6460000000000000',
      high: '19.9632000000000000',
      low: '19.4626000000000000',
      close: '19.6999000000000000',
      volume: '886139.1000000000000000',
      quoteVolume: 17507369.511074036,
      btcVolume: 1985.1423284212408,
      usdVolume: 17507369.511074036,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '19.7042000000000000',
      high: '20.6000000000000000',
      low: '19.6694000000000000',
      close: '20.4333000000000000',
      volume: '1278095.9300000000000000',
      quoteVolume: 25754570.998597786,
      btcVolume: 2901.93943637929,
      usdVolume: 25754570.998597786,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '20.4323000000000000',
      high: '20.6277000000000000',
      low: '19.7497000000000000',
      close: '20.0888000000000000',
      volume: '1002364.6600000000000000',
      quoteVolume: 20292034.237837035,
      btcVolume: 2289.625297028486,
      usdVolume: 20292034.237837035,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '20.0977000000000000',
      high: '20.9798000000000000',
      low: '19.9443000000000000',
      close: '20.9798000000000000',
      volume: '1235499.6000000000000000',
      quoteVolume: 25207219.032343943,
      btcVolume: 2881.138559153625,
      usdVolume: 25207219.032343943,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '20.9797000000000000',
      high: '21.8000000000000000',
      low: '20.6800000000000000',
      close: '21.3846000000000000',
      volume: '1369703.6200000000000000',
      quoteVolume: 29120752.930271562,
      btcVolume: 3322.2398970219933,
      usdVolume: 29120752.930271562,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '21.3883000000000000',
      high: '21.6492000000000000',
      low: '20.7111000000000000',
      close: '21.3166000000000000',
      volume: '1177353.8200000000000000',
      quoteVolume: 24991032.071327083,
      btcVolume: 2875.553521588978,
      usdVolume: 24991032.071327083,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '21.3165000000000000',
      high: '21.3353000000000000',
      low: '19.9614000000000000',
      close: '20.2735000000000000',
      volume: '1358774.2500000000000000',
      quoteVolume: 27979389.54651814,
      btcVolume: 3269.53297184289,
      usdVolume: 27979389.54651814,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '20.2871000000000000',
      high: '20.5999000000000000',
      low: '19.9000000000000000',
      close: '20.1631000000000000',
      volume: '1001272.5400000000000000',
      quoteVolume: 20291968.77348383,
      btcVolume: 2394.1821660160967,
      usdVolume: 20291968.77348383,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '20.1641000000000000',
      high: '20.4359000000000000',
      low: '19.7500000000000000',
      close: '20.1888000000000000',
      volume: '1153032.2600000000000000',
      quoteVolume: 23183744.303397186,
      btcVolume: 2722.9127437014527,
      usdVolume: 23183744.303397186,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '20.1894000000000000',
      high: '20.1940000000000000',
      low: '18.6000000000000000',
      close: '19.0817000000000000',
      volume: '1368453.1000000000000000',
      quoteVolume: 26728303.31546702,
      btcVolume: 3188.792085551649,
      usdVolume: 26728303.31546702,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '19.0817000000000000',
      high: '19.2800000000000000',
      low: '18.0800000000000000',
      close: '18.4997000000000000',
      volume: '1218553.0600000000000000',
      quoteVolume: 22711534.818970643,
      btcVolume: 2795.312906394973,
      usdVolume: 22711534.818970643,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '18.4999000000000000',
      high: '18.7494000000000000',
      low: '17.7738000000000000',
      close: '18.0826000000000000',
      volume: '1209633.0100000000000000',
      quoteVolume: 22221973.60810997,
      btcVolume: 2739.6584331838912,
      usdVolume: 22221973.60810997,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '18.0827000000000000',
      high: '18.2399000000000000',
      low: '16.3400000000000000',
      close: '16.7985000000000000',
      volume: '1715376.7100000000000000',
      quoteVolume: 29495384.97432084,
      btcVolume: 3764.2748558580925,
      usdVolume: 29495384.97432084,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '16.7986000000000000',
      high: '17.2798000000000000',
      low: '14.2846000000000000',
      close: '15.4875000000000000',
      volume: '3355659.6400000000000000',
      quoteVolume: 52944056.290057965,
      btcVolume: 7292.508377878102,
      usdVolume: 52944056.290057965,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '15.4767000000000000',
      high: '16.3483000000000000',
      low: '14.9570000000000000',
      close: '16.2320000000000000',
      volume: '1941896.4600000000000000',
      quoteVolume: 30375854.714530647,
      btcVolume: 4197.788544615611,
      usdVolume: 30375854.714530647,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '16.2321000000000000',
      high: '16.5060000000000000',
      low: '14.9000000000000000',
      close: '14.9964000000000000',
      volume: '2155053.8800000000000000',
      quoteVolume: 34080052.57061797,
      btcVolume: 4773.880226329648,
      usdVolume: 34080052.57061797,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '15.0152000000000000',
      high: '15.7600000000000000',
      low: '13.8800000000000000',
      close: '15.1457000000000000',
      volume: '3063415.2800000000000000',
      quoteVolume: 45713597.39123177,
      btcVolume: 6615.167109750736,
      usdVolume: 45713597.39123177,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '15.1466000000000000',
      high: '15.8900000000000000',
      low: '14.9382000000000000',
      close: '15.3564000000000000',
      volume: '1658330.7900000000000000',
      quoteVolume: 25501834.969267953,
      btcVolume: 3567.6079268195067,
      usdVolume: 25501834.969267953,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '15.3565000000000000',
      high: '16.2255000000000000',
      low: '14.5811000000000000',
      close: '16.0888000000000000',
      volume: '2405778.6200000000000000',
      quoteVolume: 37268429.1905796,
      btcVolume: 5141.099123684343,
      usdVolume: 37268429.1905796,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '16.0888000000000000',
      high: '16.1899000000000000',
      low: '15.4404000000000000',
      close: '15.5333000000000000',
      volume: '1619845.5800000000000000',
      quoteVolume: 25668886.932569165,
      btcVolume: 3417.447648852904,
      usdVolume: 25668886.932569165,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '15.5255000000000000',
      high: '16.2998000000000000',
      low: '15.4692000000000000',
      close: '16.2281000000000000',
      volume: '2034007.5200000000000000',
      quoteVolume: 32409441.241127864,
      btcVolume: 4248.276128127099,
      usdVolume: 32409441.241127864,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '16.2281000000000000',
      high: '16.2765000000000000',
      low: '15.4501000000000000',
      close: '15.7118000000000000',
      volume: '1435716.2100000000000000',
      quoteVolume: 22780843.578936126,
      btcVolume: 2979.5426085168438,
      usdVolume: 22780843.578936126,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '15.7030000000000000',
      high: '15.7139000000000000',
      low: '14.8000000000000000',
      close: '15.4297000000000000',
      volume: '1232843.2000000000000000',
      quoteVolume: 18823975.185732864,
      btcVolume: 2559.4840004958196,
      usdVolume: 18823975.185732864,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '15.4287000000000000',
      high: '15.6200000000000000',
      low: '15.0190000000000000',
      close: '15.1288000000000000',
      volume: '1205451.5000000000000000',
      quoteVolume: 18412700.208588973,
      btcVolume: 2519.5182703419514,
      usdVolume: 18412700.208588973,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '15.1394000000000000',
      high: '15.4255000000000000',
      low: '15.0475000000000000',
      close: '15.2542000000000000',
      volume: '1260422.8200000000000000',
      quoteVolume: 19241970.49272903,
      btcVolume: 2629.721760674202,
      usdVolume: 19241970.49272903,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '15.2545000000000000',
      high: '15.7511000000000000',
      low: '14.8275000000000000',
      close: '15.1000000000000000',
      volume: '1448353.5900000000000000',
      quoteVolume: 22125483.77934104,
      btcVolume: 3029.126283546053,
      usdVolume: 22125483.77934104,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '15.1254000000000000',
      high: '15.7200000000000000',
      low: '14.8060000000000000',
      close: '15.6594000000000000',
      volume: '1702989.3100000000000000',
      quoteVolume: 26185398.260484762,
      btcVolume: 3580.017154853113,
      usdVolume: 26185398.260484762,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '15.6554000000000000',
      high: '15.7498000000000000',
      low: '15.3000000000000000',
      close: '15.6666000000000000',
      volume: '1425210.7900000000000000',
      quoteVolume: 22138436.21785305,
      btcVolume: 2987.805804791008,
      usdVolume: 22138436.21785305,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '15.6570000000000000',
      high: '15.8199000000000000',
      low: '15.5190000000000000',
      close: '15.6067000000000000',
      volume: '1249702.2900000000000000',
      quoteVolume: 19570141.810453042,
      btcVolume: 2601.8723228509753,
      usdVolume: 19570141.810453042,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '15.6066000000000000',
      high: '15.7200000000000000',
      low: '15.3496000000000000',
      close: '15.6121000000000000',
      volume: '1097588.2900000000000000',
      quoteVolume: 17074933.996354897,
      btcVolume: 2281.4706372298097,
      usdVolume: 17074933.996354897,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '15.5947000000000000',
      high: '15.7990000000000000',
      low: '15.1838000000000000',
      close: '15.3284000000000000',
      volume: '952736.1300000000000000',
      quoteVolume: 14820153.790239785,
      btcVolume: 1984.031553870555,
      usdVolume: 14820153.790239785,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '15.3284000000000000',
      high: '15.3454000000000000',
      low: '14.6000000000000000',
      close: '14.8096000000000000',
      volume: '1001509.5100000000000000',
      quoteVolume: 15034745.741738938,
      btcVolume: 2055.8667526296367,
      usdVolume: 15034745.741738938,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '14.8085000000000000',
      high: '14.9984000000000000',
      low: '14.6000000000000000',
      close: '14.7800000000000000',
      volume: '803776.6500000000000000',
      quoteVolume: 11916428.093796976,
      btcVolume: 1650.5866045607136,
      usdVolume: 11916428.093796976,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '14.7800000000000000',
      high: '14.8495000000000000',
      low: '14.3500000000000000',
      close: '14.7238000000000000',
      volume: '740738.6700000000000000',
      quoteVolume: 10828113.391639946,
      btcVolume: 1508.9196509223818,
      usdVolume: 10828113.391639946,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '14.7165000000000000',
      high: '14.9775000000000000',
      low: '14.6600000000000000',
      close: '14.8555000000000000',
      volume: '883103.0900000000000000',
      quoteVolume: 13061294.686844045,
      btcVolume: 1805.6559930105461,
      usdVolume: 13061294.686844045,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '14.8555000000000000',
      high: '15.0000000000000000',
      low: '14.1629000000000000',
      close: '14.3888000000000000',
      volume: '841038.8200000000000000',
      quoteVolume: 12317989.087613093,
      btcVolume: 1717.5495142247462,
      usdVolume: 12317989.087613093,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '14.3887000000000000',
      high: '14.4930000000000000',
      low: '14.1361000000000000',
      close: '14.3949000000000000',
      volume: '786465.1500000000000000',
      quoteVolume: 11267335.87640899,
      btcVolume: 1588.1571392617413,
      usdVolume: 11267335.87640899,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '14.3946000000000000',
      high: '14.4356000000000000',
      low: '13.2580000000000000',
      close: '13.4544000000000000',
      volume: '1091047.0100000000000000',
      quoteVolume: 15235222.031920152,
      btcVolume: 2170.523777622476,
      usdVolume: 15235222.031920152,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '13.4721000000000000',
      high: '13.4750000000000000',
      low: '12.1111000000000000',
      close: '12.3810000000000000',
      volume: '1681588.6700000000000000',
      quoteVolume: 21579880.298549183,
      btcVolume: 3181.966603781795,
      usdVolume: 21579880.298549183,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '12.3837000000000000',
      high: '13.7348000000000000',
      low: '12.2100000000000000',
      close: '13.6158000000000000',
      volume: '2321743.1800000000000000',
      quoteVolume: 29939618.62193826,
      btcVolume: 4400.7711891586205,
      usdVolume: 29939618.62193826,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '13.6247000000000000',
      high: '13.7631000000000000',
      low: '12.9624000000000000',
      close: '13.3441000000000000',
      volume: '1378005.7000000000000000',
      quoteVolume: 18297729.627122004,
      btcVolume: 2555.9462004711754,
      usdVolume: 18297729.627122004,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '13.3519000000000000',
      high: '13.5291000000000000',
      low: '13.0800000000000000',
      close: '13.5000000000000000',
      volume: '1397599.9900000000000000',
      quoteVolume: 18629001.250787843,
      btcVolume: 2608.2124018163277,
      usdVolume: 18629001.250787843,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '13.4932000000000000',
      high: '13.5100000000000000',
      low: '13.2600000000000000',
      close: '13.2933000000000000',
      volume: '1260784.8500000000000000',
      quoteVolume: 16846058.069898106,
      btcVolume: 2357.3199262431112,
      usdVolume: 16846058.069898106,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '13.2874000000000000',
      high: '13.8309000000000000',
      low: '13.2689000000000000',
      close: '13.7798000000000000',
      volume: '1436306.2900000000000000',
      quoteVolume: 19378426.182353877,
      btcVolume: 2671.594975672879,
      usdVolume: 19378426.182353877,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '13.7768000000000000',
      high: '13.9447000000000000',
      low: '13.2000000000000000',
      close: '13.2998000000000000',
      volume: '1226252.0100000000000000',
      quoteVolume: 16842774.154331747,
      btcVolume: 2236.6478855031783,
      usdVolume: 16842774.154331747,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '13.2987000000000000',
      high: '13.5916000000000000',
      low: '13.2299000000000000',
      close: '13.3261000000000000',
      volume: '1306914.4800000000000000',
      quoteVolume: 17482280.272196006,
      btcVolume: 2396.4134118510615,
      usdVolume: 17482280.272196006,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '13.3340000000000000',
      high: '13.3522000000000000',
      low: '12.8500000000000000',
      close: '13.0666000000000000',
      volume: '923319.5400000000000000',
      quoteVolume: 12154298.537538983,
      btcVolume: 1682.9049427550863,
      usdVolume: 12154298.537538983,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '13.0665000000000000',
      high: '13.5466000000000000',
      low: '13.0230000000000000',
      close: '13.4952000000000000',
      volume: '1056537.7800000000000000',
      quoteVolume: 14002514.230783973,
      btcVolume: 1930.7821358732667,
      usdVolume: 14002514.230783973,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '13.1349000000000000',
      high: '13.2981000000000000',
      low: '12.8927000000000000',
      close: '13.2888000000000000',
      volume: '1109904.6900000000000000',
      quoteVolume: 14633137.803384082,
      btcVolume: 2029.5574032953941,
      usdVolume: 14633137.803384082,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '13.2888000000000000',
      high: '13.7200000000000000',
      low: '13.2569000000000000',
      close: '13.6779000000000000',
      volume: '1358080.6500000000000000',
      quoteVolume: 18404654.469618995,
      btcVolume: 2515.9847764068354,
      usdVolume: 18404654.469618995,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '13.6788000000000000',
      high: '14.2186000000000000',
      low: '13.5750000000000000',
      close: '14.1040000000000000',
      volume: '1608471.4600000000000000',
      quoteVolume: 22338596.36302602,
      btcVolume: 3032.599269002952,
      usdVolume: 22338596.36302602,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '14.0984000000000000',
      high: '14.3796000000000000',
      low: '13.7800000000000000',
      close: '13.8597000000000000',
      volume: '1255186.2700000000000000',
      quoteVolume: 17736371.93092798,
      btcVolume: 2418.5750608115254,
      usdVolume: 17736371.93092798,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '13.8597000000000000',
      high: '14.0564000000000000',
      low: '13.6400000000000000',
      close: '13.7161000000000000',
      volume: '970000.0200000000000000',
      quoteVolume: 13410409.01565197,
      btcVolume: 1853.0878040366188,
      usdVolume: 13410409.01565197,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '13.7159000000000000',
      high: '13.8692000000000000',
      low: '13.6500000000000000',
      close: '13.7184000000000000',
      volume: '940273.7700000000000000',
      quoteVolume: 12945796.344635054,
      btcVolume: 1793.7739920087274,
      usdVolume: 12945796.344635054,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '13.7072000000000000',
      high: '13.7302000000000000',
      low: '12.9800000000000000',
      close: '13.0105000000000000',
      volume: '960515.6000000000000000',
      quoteVolume: 12855590.844871968,
      btcVolume: 1810.6692341853307,
      usdVolume: 12855590.844871968,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '13.0104000000000000',
      high: '13.7500000000000000',
      low: '12.9801000000000000',
      close: '13.6474000000000000',
      volume: '1458213.2600000000000000',
      quoteVolume: 19697994.93713138,
      btcVolume: 2725.85101080842,
      usdVolume: 19697994.93713138,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '13.6481000000000000',
      high: '13.8710000000000000',
      low: '13.5102000000000000',
      close: '13.8092000000000000',
      volume: '1231268.3700000000000000',
      quoteVolume: 16885513.379274156,
      btcVolume: 2302.0692057035767,
      usdVolume: 16885513.379274156,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '13.7978000000000000',
      high: '14.3133000000000000',
      low: '13.7910000000000000',
      close: '14.0532000000000000',
      volume: '1458003.0200000000000000',
      quoteVolume: 20531817.041770168,
      btcVolume: 2760.741171838204,
      usdVolume: 20531817.041770168,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '14.0488000000000000',
      high: '14.9873000000000000',
      low: '14.0168000000000000',
      close: '14.9471000000000000',
      volume: '1633712.0400000000000000',
      quoteVolume: 23743495.229042225,
      btcVolume: 3144.9834827722107,
      usdVolume: 23743495.229042225,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '14.9427000000000000',
      high: '15.1870000000000000',
      low: '14.4560000000000000',
      close: '15.0226000000000000',
      volume: '1458769.7000000000000000',
      quoteVolume: 21730397.146320045,
      btcVolume: 2741.5005004584696,
      usdVolume: 21730397.146320045,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '15.0212000000000000',
      high: '15.3200000000000000',
      low: '14.2000000000000000',
      close: '14.5254000000000000',
      volume: '1444209.0300000000000000',
      quoteVolume: 21394481.601980902,
      btcVolume: 2593.189647193552,
      usdVolume: 21394481.601980902,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '14.5308000000000000',
      high: '14.5823000000000000',
      low: '14.2010000000000000',
      close: '14.3978000000000000',
      volume: '1052935.6900000000000000',
      quoteVolume: 15196486.221940989,
      btcVolume: 1918.9735468286913,
      usdVolume: 15196486.221940989,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '14.3806000000000000',
      high: '15.0973000000000000',
      low: '14.1100000000000000',
      close: '15.0700000000000000',
      volume: '1572079.7800000000000000',
      quoteVolume: 23078267.45961109,
      btcVolume: 2897.5210314858323,
      usdVolume: 23078267.45961109,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '15.0643000000000000',
      high: '15.3200000000000000',
      low: '14.7111000000000000',
      close: '14.9177000000000000',
      volume: '1397398.3000000000000000',
      quoteVolume: 20959042.697335042,
      btcVolume: 2577.9974693735912,
      usdVolume: 20959042.697335042,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '14.9137000000000000',
      high: '15.4707000000000000',
      low: '14.8001000000000000',
      close: '15.3366000000000000',
      volume: '2042556.1700000000000000',
      quoteVolume: 31106160.735202186,
      btcVolume: 3830.745845979114,
      usdVolume: 31106160.735202186,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '15.3370000000000000',
      high: '15.3572000000000000',
      low: '14.9295000000000000',
      close: '15.1736000000000000',
      volume: '1520954.5900000000000000',
      quoteVolume: 22965408.692243036,
      btcVolume: 2830.7449498311053,
      usdVolume: 22965408.692243036,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '15.1735000000000000',
      high: '16.9100000000000000',
      low: '15.1659000000000000',
      close: '16.6371000000000000',
      volume: '2711583.2200000000000000',
      quoteVolume: 43167140.88381576,
      btcVolume: 5029.673767491132,
      usdVolume: 43167140.88381576,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '16.6301000000000000',
      high: '18.1900000000000000',
      low: '16.2700000000000000',
      close: '17.6655000000000000',
      volume: '3040382.6900000000000000',
      quoteVolume: 52587124.739194795,
      btcVolume: 5996.84247707859,
      usdVolume: 52587124.739194795,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '17.6655000000000000',
      high: '17.6923000000000000',
      low: '16.3100000000000000',
      close: '17.0333000000000000',
      volume: '1842017.2900000000000000',
      quoteVolume: 31029984.91111983,
      btcVolume: 3570.093648897622,
      usdVolume: 31029984.91111983,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '17.0400000000000000',
      high: '18.4849000000000000',
      low: '17.0400000000000000',
      close: '18.0724000000000000',
      volume: '2734425.7700000000000000',
      quoteVolume: 48859738.857078,
      btcVolume: 5520.430319295519,
      usdVolume: 48859738.857078,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '18.0763000000000000',
      high: '18.3602000000000000',
      low: '17.4500000000000000',
      close: '17.8088000000000000',
      volume: '1903073.6300000000000000',
      quoteVolume: 34104864.132670745,
      btcVolume: 3831.157825228501,
      usdVolume: 34104864.132670745,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '17.7954000000000000',
      high: '18.4950000000000000',
      low: '16.6666000000000000',
      close: '17.3880000000000000',
      volume: '2127422.8500000000000000',
      quoteVolume: 37440202.05314928,
      btcVolume: 4223.113876587941,
      usdVolume: 37440202.05314928,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '17.3706000000000000',
      high: '17.5516000000000000',
      low: '16.8043000000000000',
      close: '17.3235000000000000',
      volume: '1576499.0800000000000000',
      quoteVolume: 27136151.86145567,
      btcVolume: 3135.1916814614,
      usdVolume: 27136151.86145567,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '17.3265000000000000',
      high: '18.2000000000000000',
      low: '17.2228000000000000',
      close: '18.1166000000000000',
      volume: '1923914.5000000000000000',
      quoteVolume: 33973360.84587211,
      btcVolume: 3922.624684198108,
      usdVolume: 33973360.84587211,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '18.1107000000000000',
      high: '18.4000000000000000',
      low: '17.5500000000000000',
      close: '17.8344000000000000',
      volume: '1607749.7800000000000000',
      quoteVolume: 28997476.09421225,
      btcVolume: 3333.354464634478,
      usdVolume: 28997476.09421225,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '17.8344000000000000',
      high: '17.8344000000000000',
      low: '16.7441000000000000',
      close: '17.0524000000000000',
      volume: '1363632.7900000000000000',
      quoteVolume: 23479710.346752085,
      btcVolume: 2763.2312364747722,
      usdVolume: 23479710.346752085,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '17.0813000000000000',
      high: '17.4429000000000000',
      low: '16.4288000000000000',
      close: '17.1148000000000000',
      volume: '1411501.9800000000000000',
      quoteVolume: 23870035.10373168,
      btcVolume: 2846.8695971312045,
      usdVolume: 23870035.10373168,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '17.1133000000000000',
      high: '17.1215000000000000',
      low: '16.5685000000000000',
      close: '16.9555000000000000',
      volume: '1211416.3200000000000000',
      quoteVolume: 20396383.54308985,
      btcVolume: 2446.212569813146,
      usdVolume: 20396383.54308985,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '16.9419000000000000',
      high: '17.4990000000000000',
      low: '16.8315000000000000',
      close: '17.4990000000000000',
      volume: '1619344.7500000000000000',
      quoteVolume: 27866656.71784081,
      btcVolume: 3293.769502538616,
      usdVolume: 27866656.71784081,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '17.4919000000000000',
      high: '17.9500000000000000',
      low: '17.2198000000000000',
      close: '17.6700000000000000',
      volume: '1562998.8400000000000000',
      quoteVolume: 27448591.22210781,
      btcVolume: 3140.819387866112,
      usdVolume: 27448591.22210781,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '17.6700000000000000',
      high: '18.1600000000000000',
      low: '17.4466000000000000',
      close: '18.0662000000000000',
      volume: '1916526.0700000000000000',
      quoteVolume: 34097546.051095806,
      btcVolume: 3754.0928695235057,
      usdVolume: 34097546.051095806,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '18.0614000000000000',
      high: '18.4437000000000000',
      low: '17.8090000000000000',
      close: '17.9134000000000000',
      volume: '1447265.2000000000000000',
      quoteVolume: 26231426.254242063,
      btcVolume: 2807.684884866969,
      usdVolume: 26231426.254242063,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '17.9296000000000000',
      high: '18.7027000000000000',
      low: '17.7000000000000000',
      close: '18.5555000000000000',
      volume: '1787521.2200000000000000',
      quoteVolume: 32487717.892014977,
      btcVolume: 3454.6241320377576,
      usdVolume: 32487717.892014977,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '18.5557000000000000',
      high: '18.8800000000000000',
      low: '17.8000000000000000',
      close: '18.2644000000000000',
      volume: '1647399.0800000000000000',
      quoteVolume: 30233383.52456968,
      btcVolume: 3224.3985404614446,
      usdVolume: 30233383.52456968,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '18.2644000000000000',
      high: '18.5956000000000000',
      low: '18.0300000000000000',
      close: '18.2752000000000000',
      volume: '1511588.8600000000000000',
      quoteVolume: 27611547.000338193,
      btcVolume: 2942.137716907503,
      usdVolume: 27611547.000338193,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '18.2753000000000000',
      high: '18.7714000000000000',
      low: '17.8277000000000000',
      close: '18.4333000000000000',
      volume: '1558716.5200000000000000',
      quoteVolume: 28652209.626673575,
      btcVolume: 3053.4044339388865,
      usdVolume: 28652209.626673575,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '18.4333000000000000',
      high: '18.8763000000000000',
      low: '18.1959000000000000',
      close: '18.4600000000000000',
      volume: '1314693.1200000000000000',
      quoteVolume: 24235415.42380266,
      btcVolume: 2588.7478758068564,
      usdVolume: 24235415.42380266,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '18.4610000000000000',
      high: '18.6158000000000000',
      low: '17.8700000000000000',
      close: '18.1778000000000000',
      volume: '1215556.3700000000000000',
      quoteVolume: 22148996.309246045,
      btcVolume: 2399.6608902337066,
      usdVolume: 22148996.309246045,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '18.1778000000000000',
      high: '19.4583000000000000',
      low: '18.1635000000000000',
      close: '19.2620000000000000',
      volume: '2068915.0400000000000000',
      quoteVolume: 39153896.76314988,
      btcVolume: 4174.003818806791,
      usdVolume: 39153896.76314988,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '19.2686000000000000',
      high: '20.8400000000000000',
      low: '19.0300000000000000',
      close: '20.6675000000000000',
      volume: '2770774.3100000000000000',
      quoteVolume: 55407991.63401992,
      btcVolume: 5699.561189798247,
      usdVolume: 55407991.63401992,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '20.6665000000000000',
      high: '22.5221000000000000',
      low: '20.5199000000000000',
      close: '22.0555000000000000',
      volume: '3182382.8900000000000000',
      quoteVolume: 68795504.22441779,
      btcVolume: 7022.0759772449155,
      usdVolume: 68795504.22441779,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '22.0360000000000000',
      high: '22.1900000000000000',
      low: '20.8943000000000000',
      close: '21.7888000000000000',
      volume: '2208642.4000000000000000',
      quoteVolume: 47849193.68056081,
      btcVolume: 4879.389335821138,
      usdVolume: 47849193.68056081,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '21.7871000000000000',
      high: '24.3814000000000000',
      low: '21.7746000000000000',
      close: '24.3800000000000000',
      volume: '2846806.8900000000000000',
      quoteVolume: 66056350.15274652,
      btcVolume: 6555.827857878055,
      usdVolume: 66056350.15274652,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '24.3517000000000000',
      high: '25.7935000000000000',
      low: '23.3000000000000000',
      close: '25.1465000000000000',
      volume: '4794544.7200000000000000',
      quoteVolume: 118356199.03064102,
      btcVolume: 11937.05365463192,
      usdVolume: 118356199.03064102,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '25.1450000000000000',
      high: '25.7400000000000000',
      low: '24.1202000000000000',
      close: '25.6963000000000000',
      volume: '3512538.3800000000000000',
      quoteVolume: 87532758.12909538,
      btcVolume: 8788.633029031116,
      usdVolume: 87532758.12909538,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '25.6728000000000000',
      high: '26.9196000000000000',
      low: '25.3402000000000000',
      close: '26.4243000000000000',
      volume: '2954235.4800000000000000',
      quoteVolume: 76168100.46527852,
      btcVolume: 7380.550113864897,
      usdVolume: 76168100.46527852,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '26.4136000000000000',
      high: '27.1905000000000000',
      low: '24.5995000000000000',
      close: '25.4579000000000000',
      volume: '3591601.3200000000000000',
      quoteVolume: 92634936.37990527,
      btcVolume: 9002.249103389786,
      usdVolume: 92634936.37990527,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '25.4621000000000000',
      high: '26.5130000000000000',
      low: '25.0304000000000000',
      close: '26.3547000000000000',
      volume: '3252594.3200000000000000',
      quoteVolume: 84109977.24348529,
      btcVolume: 8191.832934805558,
      usdVolume: 84109977.24348529,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '26.3662000000000000',
      high: '26.4931000000000000',
      low: '23.5288000000000000',
      close: '24.2576000000000000',
      volume: '3355108.1400000000000000',
      quoteVolume: 85265826.4624216,
      btcVolume: 8426.730139384954,
      usdVolume: 85265826.4624216,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '24.2388000000000000',
      high: '25.2485000000000000',
      low: '21.8000000000000000',
      close: '23.3193000000000000',
      volume: '3752723.6900000000000000',
      quoteVolume: 89187179.91969427,
      btcVolume: 9019.615804441175,
      usdVolume: 89187179.91969427,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '23.3193000000000000',
      high: '23.6000000000000000',
      low: '21.5510000000000000',
      close: '23.4555000000000000',
      volume: '3208991.9600000000000000',
      quoteVolume: 72372207.9082924,
      btcVolume: 7461.240037311607,
      usdVolume: 72372207.9082924,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '23.4558000000000000',
      high: '24.4588000000000000',
      low: '22.8013000000000000',
      close: '24.0821000000000000',
      volume: '3010749.1700000000000000',
      quoteVolume: 71169442.58374034,
      btcVolume: 7232.1388082425665,
      usdVolume: 71169442.58374034,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '24.0611000000000000',
      high: '24.3264000000000000',
      low: '21.8000000000000000',
      close: '22.3709000000000000',
      volume: '2140879.9700000000000000',
      quoteVolume: 50475552.67366864,
      btcVolume: 5035.254917997106,
      usdVolume: 50475552.67366864,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '22.3702000000000000',
      high: '22.6741000000000000',
      low: '21.0000000000000000',
      close: '22.1626000000000000',
      volume: '2366725.1400000000000000',
      quoteVolume: 51918299.17105383,
      btcVolume: 5418.649900876407,
      usdVolume: 51918299.17105383,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '22.1617000000000000',
      high: '22.8639000000000000',
      low: '21.7677000000000000',
      close: '22.1888000000000000',
      volume: '2987444.2400000000000000',
      quoteVolume: 66404440.860843465,
      btcVolume: 6861.125072216571,
      usdVolume: 66404440.860843465,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '22.1997000000000000',
      high: '22.3500000000000000',
      low: '21.5000000000000000',
      close: '21.9689000000000000',
      volume: '2139282.2200000000000000',
      quoteVolume: 46985151.28201848,
      btcVolume: 4870.228412924825,
      usdVolume: 46985151.28201848,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '21.9458000000000000',
      high: '22.9800000000000000',
      low: '21.9275000000000000',
      close: '22.8452000000000000',
      volume: '3052036.9600000000000000',
      quoteVolume: 69055523.55500332,
      btcVolume: 7015.549581370111,
      usdVolume: 69055523.55500332,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '22.8267000000000000',
      high: '23.2550000000000000',
      low: '21.4844000000000000',
      close: '22.0965000000000000',
      volume: '2294898.3500000000000000',
      quoteVolume: 51480520.2332999,
      btcVolume: 5282.9140573604745,
      usdVolume: 51480520.2332999,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '22.0965000000000000',
      high: '22.0967000000000000',
      low: '20.0711000000000000',
      close: '20.3193000000000000',
      volume: '2335580.1100000000000000',
      quoteVolume: 49059568.007921465,
      btcVolume: 5169.869833706369,
      usdVolume: 49059568.007921465,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '20.3316000000000000',
      high: '20.5191000000000000',
      low: '17.9000000000000000',
      close: '18.7441000000000000',
      volume: '3753737.4600000000000000',
      quoteVolume: 71546982.18924734,
      btcVolume: 7905.138057480794,
      usdVolume: 71546982.18924734,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '18.7338000000000000',
      high: '19.6499000000000000',
      low: '17.7051000000000000',
      close: '19.2000000000000000',
      volume: '3016651.2500000000000000',
      quoteVolume: 56860249.75728752,
      btcVolume: 6470.8292928242745,
      usdVolume: 56860249.75728752,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '19.1889000000000000',
      high: '19.7551000000000000',
      low: '17.9053000000000000',
      close: '18.9838000000000000',
      volume: '3099999.9300000000000000',
      quoteVolume: 58487714.17780671,
      btcVolume: 6714.188632991106,
      usdVolume: 58487714.17780671,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '18.9838000000000000',
      high: '20.4000000000000000',
      low: '18.8000000000000000',
      close: '19.2300000000000000',
      volume: '3567334.5900000000000000',
      quoteVolume: 69257747.27603036,
      btcVolume: 7987.256453347228,
      usdVolume: 69257747.27603036,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '19.2481000000000000',
      high: '19.8994000000000000',
      low: '18.4880000000000000',
      close: '18.9020000000000000',
      volume: '2382184.7800000000000000',
      quoteVolume: 45970226.909694515,
      btcVolume: 5363.65102030691,
      usdVolume: 45970226.909694515,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '18.9183000000000000',
      high: '20.0722000000000000',
      low: '18.7583000000000000',
      close: '19.9289000000000000',
      volume: '3267729.0000000000000000',
      quoteVolume: 64060497.85221986,
      btcVolume: 7310.647723090957,
      usdVolume: 64060497.85221986,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '19.9297000000000000',
      high: '20.3865000000000000',
      low: '19.3156000000000000',
      close: '19.6882000000000000',
      volume: '2545825.0000000000000000',
      quoteVolume: 50338561.07374935,
      btcVolume: 5718.875108291753,
      usdVolume: 50338561.07374935,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '19.6707000000000000',
      high: '20.2847000000000000',
      low: '19.5131000000000000',
      close: '19.9500000000000000',
      volume: '2408511.9400000000000000',
      quoteVolume: 47942820.95931419,
      btcVolume: 5467.07577696825,
      usdVolume: 47942820.95931419,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '19.9500000000000000',
      high: '21.4585000000000000',
      low: '19.9401000000000000',
      close: '20.7462000000000000',
      volume: '3088988.6500000000000000',
      quoteVolume: 64384864.18373439,
      btcVolume: 7154.825193230421,
      usdVolume: 64384864.18373439,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '20.7463000000000000',
      high: '21.7628000000000000',
      low: '20.6303000000000000',
      close: '21.3389000000000000',
      volume: '3228771.5100000000000000',
      quoteVolume: 68446184.90637933,
      btcVolume: 7534.967221418779,
      usdVolume: 68446184.90637933,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '21.3389000000000000',
      high: '21.4900000000000000',
      low: '19.7183000000000000',
      close: '20.2500000000000000',
      volume: '2378944.8500000000000000',
      quoteVolume: 49387277.98027311,
      btcVolume: 5463.183437439565,
      usdVolume: 49387277.98027311,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '20.2500000000000000',
      high: '20.2612000000000000',
      low: '16.7315000000000000',
      close: '16.8940000000000000',
      volume: '3465550.2500000000000000',
      quoteVolume: 64439955.25070982,
      btcVolume: 7591.149351999756,
      usdVolume: 64439955.25070982,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '16.8939000000000000',
      high: '17.4775000000000000',
      low: '15.6168000000000000',
      close: '16.5888000000000000',
      volume: '4046754.1400000000000000',
      quoteVolume: 67231659.84751555,
      btcVolume: 8549.570747626509,
      usdVolume: 67231659.84751555,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '16.5934000000000000',
      high: '17.4475000000000000',
      low: '16.3107000000000000',
      close: '16.8671000000000000',
      volume: '3449744.5700000000000000',
      quoteVolume: 58330699.05467022,
      btcVolume: 7320.410900960158,
      usdVolume: 58330699.05467022,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '16.8530000000000000',
      high: '17.1388000000000000',
      low: '15.4100000000000000',
      close: '16.5705000000000000',
      volume: '2581538.7700000000000000',
      quoteVolume: 42412598.42866471,
      btcVolume: 5417.646463351474,
      usdVolume: 42412598.42866471,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '16.5698000000000000',
      high: '16.6000000000000000',
      low: '8.8900000000000000',
      close: '9.2566000000000000',
      volume: '7775320.7400000000000000',
      quoteVolume: 96587818.94082893,
      btcVolume: 15229.295961519643,
      usdVolume: 96587818.94082893,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '9.2565000000000000',
      high: '11.5572000000000000',
      low: '6.3800000000000000',
      close: '10.9204000000000000',
      volume: '10695887.9800000000000000',
      quoteVolume: 101790362.66277254,
      btcVolume: 19963.23178862742,
      usdVolume: 101790362.66277254,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '10.9224000000000000',
      high: '11.2886000000000000',
      low: '9.8652000000000000',
      close: '10.1487000000000000',
      volume: '5343507.5700000000000000',
      quoteVolume: 56907072.065318905,
      btcVolume: 10586.292565466663,
      usdVolume: 56907072.065318905,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '10.1475000000000000',
      high: '11.2644000000000000',
      low: '9.9768000000000000',
      close: '10.3898000000000000',
      volume: '6424065.3600000000000000',
      quoteVolume: 67251583.01660968,
      btcVolume: 12552.17335408042,
      usdVolume: 67251583.01660968,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '10.3808000000000000',
      high: '10.3952000000000000',
      low: '8.4422000000000000',
      close: '9.4285000000000000',
      volume: '5814301.1700000000000000',
      quoteVolume: 54897967.26809575,
      btcVolume: 11073.417029929627,
      usdVolume: 54897967.26809575,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '9.4290000000000000',
      high: '10.4900000000000000',
      low: '9.3265000000000000',
      close: '10.2576000000000000',
      volume: '5102840.4500000000000000',
      quoteVolume: 51631527.65429831,
      btcVolume: 9759.11417103753,
      usdVolume: 51631527.65429831,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '10.2498000000000000',
      high: '10.4301000000000000',
      low: '9.6539000000000000',
      close: '10.3866000000000000',
      volume: '4450666.1500000000000000',
      quoteVolume: 45276532.730975404,
      btcVolume: 8574.510856799883,
      usdVolume: 45276532.730975404,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '10.3917000000000000',
      high: '12.6891000000000000',
      low: '10.2260000000000000',
      close: '12.2597000000000000',
      volume: '5848299.4600000000000000',
      quoteVolume: 66264533.82355488,
      btcVolume: 11416.165972116107,
      usdVolume: 66264533.82355488,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '12.2597000000000000',
      high: '13.6500000000000000',
      low: '10.4014000000000000',
      close: '11.9147000000000000',
      volume: '6965457.9200000000000000',
      quoteVolume: 86260327.52522887,
      btcVolume: 13530.08677890309,
      usdVolume: 86260327.52522887,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '11.8967000000000000',
      high: '12.4199000000000000',
      low: '11.2500000000000000',
      close: '12.1000000000000000',
      volume: '5810720.8800000000000000',
      quoteVolume: 69365406.12530024,
      btcVolume: 11212.692133104454,
      usdVolume: 69365406.12530024,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '12.1050000000000000',
      high: '12.4754000000000000',
      low: '11.0145000000000000',
      close: '11.1670000000000000',
      volume: '4562736.8600000000000000',
      quoteVolume: 54219558.920412235,
      btcVolume: 8829.651908483296,
      usdVolume: 54219558.920412235,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '11.1494000000000000',
      high: '12.4900000000000000',
      low: '10.8427000000000000',
      close: '12.1887000000000000',
      volume: '5299899.4500000000000000',
      quoteVolume: 61800844.96620327,
      btcVolume: 10040.54539065147,
      usdVolume: 61800844.96620327,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '12.1887000000000000',
      high: '12.5834000000000000',
      low: '11.9100000000000000',
      close: '12.4260000000000000',
      volume: '4627182.2900000000000000',
      quoteVolume: 56790493.548444174,
      btcVolume: 8555.766772115445,
      usdVolume: 56790493.548444174,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '12.4261000000000000',
      high: '12.7108000000000000',
      low: '12.0561000000000000',
      close: '12.3251000000000000',
      volume: '3607793.8900000000000000',
      quoteVolume: 44491441.70805428,
      btcVolume: 6675.4778502505305,
      usdVolume: 44491441.70805428,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '12.3253000000000000',
      high: '13.2565000000000000',
      low: '12.1900000000000000',
      close: '13.1990000000000000',
      volume: '3433997.0900000000000000',
      quoteVolume: 42838269.466780595,
      btcVolume: 6422.748334392865,
      usdVolume: 42838269.466780595,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '13.1989000000000000',
      high: '13.3655000000000000',
      low: '12.1600000000000000',
      close: '12.3354000000000000',
      volume: '2778050.9100000000000000',
      quoteVolume: 36084938.85881204,
      btcVolume: 5400.701483352602,
      usdVolume: 36084938.85881204,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '12.3375000000000000',
      high: '12.3588000000000000',
      low: '11.6414000000000000',
      close: '12.1888000000000000',
      volume: '2478685.1100000000000000',
      quoteVolume: 30031382.50817986,
      btcVolume: 4830.592276464181,
      usdVolume: 30031382.50817986,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '12.1888000000000000',
      high: '12.2425000000000000',
      low: '11.2750000000000000',
      close: '11.3008000000000000',
      volume: '2305768.5200000000000000',
      quoteVolume: 27252838.148580186,
      btcVolume: 4463.8054330588775,
      usdVolume: 27252838.148580186,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '11.3007000000000000',
      high: '12.4277000000000000',
      low: '11.2552000000000000',
      close: '12.1365000000000000',
      volume: '4183148.7200000000000000',
      quoteVolume: 50273122.37520111,
      btcVolume: 7996.7455340660035,
      usdVolume: 50273122.37520111,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '12.1368000000000000',
      high: '12.7322000000000000',
      low: '12.1180000000000000',
      close: '12.5451000000000000',
      volume: '5119093.1500000000000000',
      quoteVolume: 64105779.65224831,
      btcVolume: 9947.257238601136,
      usdVolume: 64105779.65224831,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '12.5497000000000000',
      high: '12.7386000000000000',
      low: '11.9600000000000000',
      close: '12.7200000000000000',
      volume: '4355655.4600000000000000',
      quoteVolume: 53721353.74091241,
      btcVolume: 8489.017350493368,
      usdVolume: 53721353.74091241,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '12.7190000000000000',
      high: '13.7362000000000000',
      low: '12.5349000000000000',
      close: '13.0166000000000000',
      volume: '5332604.5300000000000000',
      quoteVolume: 69276074.10863224,
      btcVolume: 10253.55586604115,
      usdVolume: 69276074.10863224,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '13.0185000000000000',
      high: '13.5700000000000000',
      low: '12.9106000000000000',
      close: '13.3615000000000000',
      volume: '5030322.0300000000000000',
      quoteVolume: 66661802.96690086,
      btcVolume: 9801.523808937845,
      usdVolume: 66661802.96690086,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '13.3682000000000000',
      high: '14.0561000000000000',
      low: '13.1724000000000000',
      close: '13.7606000000000000',
      volume: '4840528.5300000000000000',
      quoteVolume: 66003739.59200456,
      btcVolume: 9745.152195828143,
      usdVolume: 66003739.59200456,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '13.7608000000000000',
      high: '13.9638000000000000',
      low: '13.4002000000000000',
      close: '13.6888000000000000',
      volume: '4275639.2500000000000000',
      quoteVolume: 58555104.63058687,
      btcVolume: 8621.568347482043,
      usdVolume: 58555104.63058687,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '13.6888000000000000',
      high: '15.2149000000000000',
      low: '13.6371000000000000',
      close: '15.1989000000000000',
      volume: '5583296.7600000000000000',
      quoteVolume: 80214973.59908952,
      btcVolume: 11301.364287830236,
      usdVolume: 80214973.59908952,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '15.1930000000000000',
      high: '15.4900000000000000',
      low: '14.2503000000000000',
      close: '14.7266000000000000',
      volume: '4715229.2800000000000000',
      quoteVolume: 70591252.82052863,
      btcVolume: 9647.541530712178,
      usdVolume: 70591252.82052863,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '14.7267000000000000',
      high: '15.3486000000000000',
      low: '14.5700000000000000',
      close: '15.0888000000000000',
      volume: '4614905.6300000000000000',
      quoteVolume: 69355343.21978821,
      btcVolume: 9493.717090075794,
      usdVolume: 69355343.21978821,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '15.0899000000000000',
      high: '15.2011000000000000',
      low: '14.6279000000000000',
      close: '15.0096000000000000',
      volume: '4638418.3900000000000000',
      quoteVolume: 69662383.47849114,
      btcVolume: 9554.251740198943,
      usdVolume: 69662383.47849114,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '15.0096000000000000',
      high: '15.0797000000000000',
      low: '13.4700000000000000',
      close: '13.7200000000000000',
      volume: '3999879.1400000000000000',
      quoteVolume: 56437561.49783074,
      btcVolume: 8088.757467476043,
      usdVolume: 56437561.49783074,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '13.7171000000000000',
      high: '14.0808000000000000',
      low: '13.5234000000000000',
      close: '13.8070000000000000',
      volume: '3692817.9200000000000000',
      quoteVolume: 51151554.74794237,
      btcVolume: 7454.864937758831,
      usdVolume: 51151554.74794237,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '13.8089000000000000',
      high: '14.8399000000000000',
      low: '13.6439000000000000',
      close: '14.2808000000000000',
      volume: '4070517.8200000000000000',
      quoteVolume: 57950623.216416314,
      btcVolume: 8337.283577200431,
      usdVolume: 57950623.216416314,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '14.2808000000000000',
      high: '15.0799000000000000',
      low: '13.6289000000000000',
      close: '15.0277000000000000',
      volume: '4313190.9300000000000000',
      quoteVolume: 62029135.8484717,
      btcVolume: 9209.291923149041,
      usdVolume: 62029135.8484717,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '15.0257000000000000',
      high: '15.9555000000000000',
      low: '14.7835000000000000',
      close: '15.6148000000000000',
      volume: '4523071.2000000000000000',
      quoteVolume: 70092382.14295062,
      btcVolume: 10207.861589084292,
      usdVolume: 70092382.14295062,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '15.6148000000000000',
      high: '15.8888000000000000',
      low: '14.4913000000000000',
      close: '14.5755000000000000',
      volume: '3795966.9000000000000000',
      quoteVolume: 58221065.7500413,
      btcVolume: 8555.74483779464,
      usdVolume: 58221065.7500413,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '14.5755000000000000',
      high: '15.7669000000000000',
      low: '14.1160000000000000',
      close: '15.7394000000000000',
      volume: '4068049.4800000000000000',
      quoteVolume: 61814683.27456321,
      btcVolume: 8977.839996691559,
      usdVolume: 61814683.27456321,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '15.7394000000000000',
      high: '15.9200000000000000',
      low: '15.2501000000000000',
      close: '15.6400000000000000',
      volume: '3150559.1700000000000000',
      quoteVolume: 49188415.57936379,
      btcVolume: 6962.024816102106,
      usdVolume: 49188415.57936379,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '15.6447000000000000',
      high: '16.8183000000000000',
      low: '15.6242000000000000',
      close: '16.6137000000000000',
      volume: '3968019.0700000000000000',
      quoteVolume: 64502814.66373515,
      btcVolume: 8996.773890457556,
      usdVolume: 64502814.66373515,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '16.6198000000000000',
      high: '16.6933000000000000',
      low: '15.8509000000000000',
      close: '16.0683000000000000',
      volume: '2962072.6000000000000000',
      quoteVolume: 48174317.52943487,
      btcVolume: 6722.896522589055,
      usdVolume: 48174317.52943487,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '16.0677000000000000',
      high: '16.4499000000000000',
      low: '14.8219000000000000',
      close: '14.9892000000000000',
      volume: '3219905.9000000000000000',
      quoteVolume: 50757347.771237776,
      btcVolume: 7228.98020818563,
      usdVolume: 50757347.771237776,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '14.9840000000000000',
      high: '15.4416000000000000',
      low: '14.6945000000000000',
      close: '15.1283000000000000',
      volume: '3134797.6600000000000000',
      quoteVolume: 47551099.75450032,
      btcVolume: 6932.414273166686,
      usdVolume: 47551099.75450032,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '15.1381000000000000',
      high: '15.8030000000000000',
      low: '14.9112000000000000',
      close: '15.7104000000000000',
      volume: '3293822.4400000000000000',
      quoteVolume: 50953432.53308494,
      btcVolume: 7270.805302216778,
      usdVolume: 50953432.53308494,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '15.7103000000000000',
      high: '16.4666000000000000',
      low: '15.4102000000000000',
      close: '16.0340000000000000',
      volume: '3451154.7600000000000000',
      quoteVolume: 54969135.67182449,
      btcVolume: 7506.714246229673,
      usdVolume: 54969135.67182449,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '16.0502000000000000',
      high: '16.2568000000000000',
      low: '15.6800000000000000',
      close: '15.9319000000000000',
      volume: '3008320.2600000000000000',
      quoteVolume: 48259042.136493415,
      btcVolume: 6423.189422170581,
      usdVolume: 48259042.136493415,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '15.9319000000000000',
      high: '16.4305000000000000',
      low: '15.7761000000000000',
      close: '16.2283000000000000',
      volume: '2946839.7900000000000000',
      quoteVolume: 47639855.83746818,
      btcVolume: 6314.8054320104275,
      usdVolume: 47639855.83746818,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '16.2235000000000000',
      high: '16.4435000000000000',
      low: '16.0400000000000000',
      close: '16.3588000000000000',
      volume: '3314818.9100000000000000',
      quoteVolume: 53903371.49447087,
      btcVolume: 7094.218778997715,
      usdVolume: 53903371.49447087,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '16.3645000000000000',
      high: '16.5600000000000000',
      low: '16.0630000000000000',
      close: '16.4229000000000000',
      volume: '2937287.3600000000000000',
      quoteVolume: 47859272.721880525,
      btcVolume: 6212.335727211785,
      usdVolume: 47859272.721880525,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '16.4199000000000000',
      high: '16.4900000000000000',
      low: '16.1125000000000000',
      close: '16.3400000000000000',
      volume: '2838511.4300000000000000',
      quoteVolume: 46309248.7211524,
      btcVolume: 5999.145090290307,
      usdVolume: 46309248.7211524,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '16.3354000000000000',
      high: '17.5171000000000000',
      low: '16.3000000000000000',
      close: '17.3667000000000000',
      volume: '3817656.7000000000000000',
      quoteVolume: 64434759.7688937,
      btcVolume: 7799.88414199083,
      usdVolume: 64434759.7688937,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '17.3754000000000000',
      high: '18.1377000000000000',
      low: '16.5000000000000000',
      close: '16.9542000000000000',
      volume: '4127322.5100000000000000',
      quoteVolume: 71629569.6960837,
      btcVolume: 8039.289696160252,
      usdVolume: 71629569.6960837,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '16.9510000000000000',
      high: '17.8551000000000000',
      low: '16.9381000000000000',
      close: '17.4660000000000000',
      volume: '3805293.6700000000000000',
      quoteVolume: 66271797.65506503,
      btcVolume: 7557.207038844103,
      usdVolume: 66271797.65506503,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '17.4665000000000000',
      high: '17.5711000000000000',
      low: '17.2599000000000000',
      close: '17.5333000000000000',
      volume: '3009939.6700000000000000',
      quoteVolume: 52504866.732339546,
      btcVolume: 5915.815664352295,
      usdVolume: 52504866.732339546,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '17.5337000000000000',
      high: '17.8100000000000000',
      low: '16.8711000000000000',
      close: '17.1071000000000000',
      volume: '2616299.5300000000000000',
      quoteVolume: 45271776.505038545,
      btcVolume: 5050.819962883537,
      usdVolume: 45271776.505038545,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '17.1027000000000000',
      high: '17.3267000000000000',
      low: '16.2556000000000000',
      close: '17.0074000000000000',
      volume: '2755883.6300000000000000',
      quoteVolume: 46419270.57261121,
      btcVolume: 5284.661382519424,
      usdVolume: 46419270.57261121,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '17.0120000000000000',
      high: '17.2993000000000000',
      low: '16.6060000000000000',
      close: '16.8886000000000000',
      volume: '2613361.6500000000000000',
      quoteVolume: 44261093.04238469,
      btcVolume: 4957.354183210489,
      usdVolume: 44261093.04238469,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '16.8814000000000000',
      high: '17.1450000000000000',
      low: '16.3018000000000000',
      close: '16.3743000000000000',
      volume: '2716280.3700000000000000',
      quoteVolume: 45806861.8974411,
      btcVolume: 5004.187193369502,
      usdVolume: 45806861.8974411,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '16.3672000000000000',
      high: '17.2400000000000000',
      low: '16.1218000000000000',
      close: '17.0003000000000000',
      volume: '3030451.5100000000000000',
      quoteVolume: 50623360.84297903,
      btcVolume: 5321.812894633818,
      usdVolume: 50623360.84297903,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '17.0003000000000000',
      high: '17.3977000000000000',
      low: '16.8230000000000000',
      close: '17.1371000000000000',
      volume: '2621559.8700000000000000',
      quoteVolume: 44879553.905084215,
      btcVolume: 4525.608471864123,
      usdVolume: 44879553.905084215,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '17.1371000000000000',
      high: '17.4926000000000000',
      low: '16.7330000000000000',
      close: '16.7751000000000000',
      volume: '2243643.8300000000000000',
      quoteVolume: 38582692.96829227,
      btcVolume: 3962.9396596703737,
      usdVolume: 38582692.96829227,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '16.7753000000000000',
      high: '16.7900000000000000',
      low: '13.9129000000000000',
      close: '15.3506000000000000',
      volume: '2900497.8000000000000000',
      quoteVolume: 43979315.8858527,
      btcVolume: 5066.467433139654,
      usdVolume: 43979315.8858527,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '15.3506000000000000',
      high: '15.5468000000000000',
      low: '14.0100000000000000',
      close: '15.0811000000000000',
      volume: '2726253.2700000000000000',
      quoteVolume: 40893423.03739202,
      btcVolume: 4712.194211346884,
      usdVolume: 40893423.03739202,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '15.0902000000000000',
      high: '16.2400000000000000',
      low: '14.9586000000000000',
      close: '15.7892000000000000',
      volume: '3026813.0900000000000000',
      quoteVolume: 47332783.09776167,
      btcVolume: 5395.76311152628,
      usdVolume: 47332783.09776167,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '15.7941000000000000',
      high: '16.3500000000000000',
      low: '15.6785000000000000',
      close: '16.2164000000000000',
      volume: '2812457.1200000000000000',
      quoteVolume: 45141960.7019688,
      btcVolume: 4989.884585136048,
      usdVolume: 45141960.7019688,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '16.2187000000000000',
      high: '16.6982000000000000',
      low: '16.0123000000000000',
      close: '16.3777000000000000',
      volume: '2801822.6000000000000000',
      quoteVolume: 45666418.70995536,
      btcVolume: 4771.624602245077,
      usdVolume: 45666418.70995536,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '16.3722000000000000',
      high: '16.4719000000000000',
      low: '15.4606000000000000',
      close: '15.7222000000000000',
      volume: '2314656.9000000000000000',
      quoteVolume: 37214374.23302308,
      btcVolume: 3912.922381413597,
      usdVolume: 37214374.23302308,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '15.7121000000000000',
      high: '16.2367000000000000',
      low: '15.5800000000000000',
      close: '16.0580000000000000',
      volume: '2730367.3500000000000000',
      quoteVolume: 43746498.66421267,
      btcVolume: 4654.9166519593045,
      usdVolume: 43746498.66421267,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '16.0580000000000000',
      high: '16.5100000000000000',
      low: '16.0014000000000000',
      close: '16.2959000000000000',
      volume: '2893701.8400000000000000',
      quoteVolume: 47142111.5064022,
      btcVolume: 4887.223961466,
      usdVolume: 47142111.5064022,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '16.2999000000000000',
      high: '16.8620000000000000',
      low: '16.2511000000000000',
      close: '16.6299000000000000',
      volume: '3116090.7500000000000000',
      quoteVolume: 51603793.31526883,
      btcVolume: 5303.9246355544265,
      usdVolume: 51603793.31526883,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '16.6298000000000000',
      high: '17.2488000000000000',
      low: '16.3000000000000000',
      close: '17.2098000000000000',
      volume: '3245275.7500000000000000',
      quoteVolume: 54590767.63110695,
      btcVolume: 5634.9082075498,
      usdVolume: 54590767.63110695,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '17.2096000000000000',
      high: '17.3396000000000000',
      low: '16.4851000000000000',
      close: '16.8998000000000000',
      volume: '2395316.0900000000000000',
      quoteVolume: 40821394.31884481,
      btcVolume: 4224.383755576833,
      usdVolume: 40821394.31884481,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '16.8994000000000000',
      high: '17.0675000000000000',
      low: '15.7218000000000000',
      close: '16.0888000000000000',
      volume: '2163570.9800000000000000',
      quoteVolume: 35589912.05392658,
      btcVolume: 3843.773844858664,
      usdVolume: 35589912.05392658,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '16.0888000000000000',
      high: '16.9240000000000000',
      low: '15.9145000000000000',
      close: '16.5864000000000000',
      volume: '2520655.4700000000000000',
      quoteVolume: 41584466.617784135,
      btcVolume: 4547.953831523734,
      usdVolume: 41584466.617784135,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '16.5884000000000000',
      high: '16.8500000000000000',
      low: '16.3202000000000000',
      close: '16.4800000000000000',
      volume: '2302712.1700000000000000',
      quoteVolume: 38173594.65329609,
      btcVolume: 4145.125970293084,
      usdVolume: 38173594.65329609,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '16.4789000000000000',
      high: '16.7900000000000000',
      low: '15.9074000000000000',
      close: '15.9624000000000000',
      volume: '2280604.7400000000000000',
      quoteVolume: 37593388.9279645,
      btcVolume: 4127.7763329936115,
      usdVolume: 37593388.9279645,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '15.9457000000000000',
      high: '16.4161000000000000',
      low: '15.8057000000000000',
      close: '16.3807000000000000',
      volume: '2348512.9100000000000000',
      quoteVolume: 38152773.256409764,
      btcVolume: 4321.228700886494,
      usdVolume: 38152773.256409764,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '16.3807000000000000',
      high: '16.4979000000000000',
      low: '15.9537000000000000',
      close: '16.2159000000000000',
      volume: '2007343.8200000000000000',
      quoteVolume: 32624803.88715872,
      btcVolume: 3682.6289500059283,
      usdVolume: 32624803.88715872,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '16.2097000000000000',
      high: '16.5100000000000000',
      low: '16.1554000000000000',
      close: '16.4700000000000000',
      volume: '2613812.0500000000000000',
      quoteVolume: 42792287.77669632,
      btcVolume: 4725.005187970007,
      usdVolume: 42792287.77669632,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '16.4700000000000000',
      high: '17.1405000000000000',
      low: '16.3890000000000000',
      close: '17.0772000000000000',
      volume: '2866468.3900000000000000',
      quoteVolume: 47917801.298296325,
      btcVolume: 5119.878787996562,
      usdVolume: 47917801.298296325,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '17.0769000000000000',
      high: '17.1980000000000000',
      low: '16.6333000000000000',
      close: '17.0031000000000000',
      volume: '2598906.5200000000000000',
      quoteVolume: 44169314.055208385,
      btcVolume: 4668.689724077699,
      usdVolume: 44169314.055208385,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '17.0030000000000000',
      high: '17.7857000000000000',
      low: '16.9291000000000000',
      close: '17.7277000000000000',
      volume: '2969818.2300000000000000',
      quoteVolume: 51559065.97362402,
      btcVolume: 5420.853255843491,
      usdVolume: 51559065.97362402,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '17.7277000000000000',
      high: '18.1954000000000000',
      low: '16.8419000000000000',
      close: '17.0916000000000000',
      volume: '2612942.1800000000000000',
      quoteVolume: 45947854.11468179,
      btcVolume: 4807.9438395434145,
      usdVolume: 45947854.11468179,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '17.0926000000000000',
      high: '18.1224000000000000',
      low: '16.9836000000000000',
      close: '17.9477000000000000',
      volume: '2989853.0600000000000000',
      quoteVolume: 52189313.03744129,
      btcVolume: 5419.06119775764,
      usdVolume: 52189313.03744129,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '17.9469000000000000',
      high: '18.2000000000000000',
      low: '16.6800000000000000',
      close: '17.2669000000000000',
      volume: '2536257.1500000000000000',
      quoteVolume: 44844446.996629834,
      btcVolume: 4522.870886309356,
      usdVolume: 44844446.996629834,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '17.2761000000000000',
      high: '17.6275000000000000',
      low: '16.9645000000000000',
      close: '17.5779000000000000',
      volume: '2650311.1400000000000000',
      quoteVolume: 45972871.326653786,
      btcVolume: 4811.90967908672,
      usdVolume: 45972871.326653786,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '17.5780000000000000',
      high: '17.6527000000000000',
      low: '17.0424000000000000',
      close: '17.5170000000000000',
      volume: '2320525.8900000000000000',
      quoteVolume: 40612423.08444422,
      btcVolume: 4186.441359549021,
      usdVolume: 40612423.08444422,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '17.5180000000000000',
      high: '17.8999000000000000',
      low: '17.4396000000000000',
      close: '17.6914000000000000',
      volume: '2454801.7700000000000000',
      quoteVolume: 43342829.55218497,
      btcVolume: 4448.532900411398,
      usdVolume: 43342829.55218497,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '17.6915000000000000',
      high: '17.7951000000000000',
      low: '17.4900000000000000',
      close: '17.5511000000000000',
      volume: '1963897.2200000000000000',
      quoteVolume: 34612966.357234664,
      btcVolume: 3593.211679800369,
      usdVolume: 34612966.357234664,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '17.5510000000000000',
      high: '17.6371000000000000',
      low: '16.8526000000000000',
      close: '17.4135000000000000',
      volume: '1816342.7400000000000000',
      quoteVolume: 31593373.320084013,
      btcVolume: 3279.991408861491,
      usdVolume: 31593373.320084013,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '17.4134000000000000',
      high: '17.5454000000000000',
      low: '17.2594000000000000',
      close: '17.4489000000000000',
      volume: '1657535.1100000000000000',
      quoteVolume: 28825851.386570796,
      btcVolume: 2964.926669627316,
      usdVolume: 28825851.386570796,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '17.4489000000000000',
      high: '17.5098000000000000',
      low: '17.0374000000000000',
      close: '17.3733000000000000',
      volume: '1630641.2900000000000000',
      quoteVolume: 28236106.363147046,
      btcVolume: 2904.68453638977,
      usdVolume: 28236106.363147046,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '17.3734000000000000',
      high: '17.5833000000000000',
      low: '17.1300000000000000',
      close: '17.4060000000000000',
      volume: '1807563.8600000000000000',
      quoteVolume: 31389856.381225992,
      btcVolume: 3201.181062165144,
      usdVolume: 31389856.381225992,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '17.4084000000000000',
      high: '17.5191000000000000',
      low: '15.9300000000000000',
      close: '16.2298000000000000',
      volume: '1799102.4900000000000000',
      quoteVolume: 30529455.476210415,
      btcVolume: 3172.6583143612193,
      usdVolume: 30529455.476210415,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '16.2376000000000000',
      high: '16.7999000000000000',
      low: '16.0869000000000000',
      close: '16.6377000000000000',
      volume: '1930125.9500000000000000',
      quoteVolume: 31938857.307844184,
      btcVolume: 3394.540608530982,
      usdVolume: 31938857.307844184,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '16.6420000000000000',
      high: '16.8179000000000000',
      low: '16.4900000000000000',
      close: '16.8101000000000000',
      volume: '2023311.8300000000000000',
      quoteVolume: 33704354.30536094,
      btcVolume: 3573.99175725781,
      usdVolume: 33704354.30536094,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '16.8111000000000000',
      high: '16.8398000000000000',
      low: '16.4023000000000000',
      close: '16.5729000000000000',
      volume: '1697344.5500000000000000',
      quoteVolume: 28336046.766627934,
      btcVolume: 3010.4223953476185,
      usdVolume: 28336046.766627934,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '16.5728000000000000',
      high: '16.5982000000000000',
      low: '15.4000000000000000',
      close: '16.3770000000000000',
      volume: '1521685.2100000000000000',
      quoteVolume: 24473919.621151537,
      btcVolume: 2658.7457305257617,
      usdVolume: 24473919.621151537,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '16.3758000000000000',
      high: '16.6481000000000000',
      low: '16.1562000000000000',
      close: '16.4329000000000000',
      volume: '1614905.6400000000000000',
      quoteVolume: 26479345.22874293,
      btcVolume: 2788.7732205533584,
      usdVolume: 26479345.22874293,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '16.4331000000000000',
      high: '16.6320000000000000',
      low: '15.9709000000000000',
      close: '16.3983000000000000',
      volume: '1502697.3100000000000000',
      quoteVolume: 24591062.00260309,
      btcVolume: 2604.547568064439,
      usdVolume: 24591062.00260309,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '16.3983000000000000',
      high: '16.5423000000000000',
      low: '15.8783000000000000',
      close: '16.1608000000000000',
      volume: '1382820.8700000000000000',
      quoteVolume: 22540156.403535735,
      btcVolume: 2394.7606782552566,
      usdVolume: 22540156.403535735,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '16.1665000000000000',
      high: '16.3106000000000000',
      low: '15.8966000000000000',
      close: '15.9512000000000000',
      volume: '1324135.6000000000000000',
      quoteVolume: 21271385.818114948,
      btcVolume: 2279.89302677804,
      usdVolume: 21271385.818114948,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '15.9611000000000000',
      high: '16.0898000000000000',
      low: '15.7000000000000000',
      close: '16.0590000000000000',
      volume: '1427210.2200000000000000',
      quoteVolume: 22776460.30155413,
      btcVolume: 2445.1087498630836,
      usdVolume: 22776460.30155413,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '16.0590000000000000',
      high: '16.3358000000000000',
      low: '15.9210000000000000',
      close: '15.9775000000000000',
      volume: '1317075.8400000000000000',
      quoteVolume: 21166507.63965404,
      btcVolume: 2262.4663175872406,
      usdVolume: 21166507.63965404,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '15.9792000000000000',
      high: '16.5500000000000000',
      low: '15.9499000000000000',
      close: '16.5051000000000000',
      volume: '1703957.1800000000000000',
      quoteVolume: 27730249.14524187,
      btcVolume: 2922.9736930384024,
      usdVolume: 27730249.14524187,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '16.5051000000000000',
      high: '16.5472000000000000',
      low: '16.2602000000000000',
      close: '16.3898000000000000',
      volume: '1450721.0800000000000000',
      quoteVolume: 23797667.00323723,
      btcVolume: 2468.0003601359663,
      usdVolume: 23797667.00323723,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '16.3898000000000000',
      high: '16.6481000000000000',
      low: '15.9500000000000000',
      close: '15.9968000000000000',
      volume: '1529800.3000000000000000',
      quoteVolume: 24889909.655295953,
      btcVolume: 2628.3948271845443,
      usdVolume: 24889909.655295953,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '16.0084000000000000',
      high: '16.0816000000000000',
      low: '15.5000000000000000',
      close: '15.9060000000000000',
      volume: '1201354.7500000000000000',
      quoteVolume: 19105061.019308876,
      btcVolume: 2069.4495041309574,
      usdVolume: 19105061.019308876,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '15.9056000000000000',
      high: '15.9996000000000000',
      low: '15.5715000000000000',
      close: '15.7694000000000000',
      volume: '1041399.0000000000000000',
      quoteVolume: 16428293.389013952,
      btcVolume: 1787.4554301521573,
      usdVolume: 16428293.389013952,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '15.7696000000000000',
      high: '15.8714000000000000',
      low: '14.8000000000000000',
      close: '15.1242000000000000',
      volume: '1309335.6600000000000000',
      quoteVolume: 20331918.057953976,
      btcVolume: 2234.822337873103,
      usdVolume: 20331918.057953976,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '15.1230000000000000',
      high: '15.4944000000000000',
      low: '14.9249000000000000',
      close: '15.3782000000000000',
      volume: '1402858.9600000000000000',
      quoteVolume: 21418398.753466826,
      btcVolume: 2360.2436605355056,
      usdVolume: 21418398.753466826,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '15.3714000000000000',
      high: '15.5901000000000000',
      low: '15.2040000000000000',
      close: '15.4656000000000000',
      volume: '1511856.3800000000000000',
      quoteVolume: 23294081.307785984,
      btcVolume: 2551.092853713331,
      usdVolume: 23294081.307785984,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '15.4674000000000000',
      high: '15.5476000000000000',
      low: '15.2519000000000000',
      close: '15.3838000000000000',
      volume: '1315772.4200000000000000',
      quoteVolume: 20305967.15507204,
      btcVolume: 2218.39382184501,
      usdVolume: 20305967.15507204,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '15.3959000000000000',
      high: '15.8888000000000000',
      low: '15.3363000000000000',
      close: '15.8144000000000000',
      volume: '1609244.5800000000000000',
      quoteVolume: 25167285.05407897,
      btcVolume: 2737.6419244650206,
      usdVolume: 25167285.05407897,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '15.8123000000000000',
      high: '15.9796000000000000',
      low: '15.0743000000000000',
      close: '15.3634000000000000',
      volume: '1399345.3900000000000000',
      quoteVolume: 21887439.935343955,
      btcVolume: 2389.049039204999,
      usdVolume: 21887439.935343955,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '15.3640000000000000',
      high: '15.4880000000000000',
      low: '15.2484000000000000',
      close: '15.2863000000000000',
      volume: '1204044.4900000000000000',
      quoteVolume: 18526262.42016398,
      btcVolume: 2036.9658883771122,
      usdVolume: 18526262.42016398,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '15.2856000000000000',
      high: '15.6399000000000000',
      low: '15.2239000000000000',
      close: '15.5548000000000000',
      volume: '1226300.0400000000000000',
      quoteVolume: 18922658.450647,
      btcVolume: 2079.9805745186986,
      usdVolume: 18922658.450647,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '15.5579000000000000',
      high: '15.6135000000000000',
      low: '15.1000000000000000',
      close: '15.4885000000000000',
      volume: '1064265.3200000000000000',
      quoteVolume: 16414898.226830075,
      btcVolume: 1810.9834840684625,
      usdVolume: 16414898.226830075,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '15.4865000000000000',
      high: '16.3496000000000000',
      low: '15.4401000000000000',
      close: '16.3110000000000000',
      volume: '1606449.3100000000000000',
      quoteVolume: 25364592.417103197,
      btcVolume: 2750.178905626979,
      usdVolume: 25364592.417103197,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '16.3120000000000000',
      high: '16.8757000000000000',
      low: '16.0301000000000000',
      close: '16.8048000000000000',
      volume: '1880630.3400000000000000',
      quoteVolume: 31044612.46472845,
      btcVolume: 3348.0111025538104,
      usdVolume: 31044612.46472845,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '16.7995000000000000',
      high: '17.4550000000000000',
      low: '16.5683000000000000',
      close: '17.2819000000000000',
      volume: '2246484.1800000000000000',
      quoteVolume: 38372913.62190868,
      btcVolume: 4118.227717083268,
      usdVolume: 38372913.62190868,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '17.2819000000000000',
      high: '17.5000000000000000',
      low: '16.4088000000000000',
      close: '16.8274000000000000',
      volume: '1869273.6700000000000000',
      quoteVolume: 31602020.87875102,
      btcVolume: 3389.70673054376,
      usdVolume: 31602020.87875102,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '16.8278000000000000',
      high: '17.3310000000000000',
      low: '16.4438000000000000',
      close: '17.3123000000000000',
      volume: '2099193.5700000000000000',
      quoteVolume: 35493337.16801673,
      btcVolume: 3853.180488607405,
      usdVolume: 35493337.16801673,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '17.3133000000000000',
      high: '17.6888000000000000',
      low: '17.0180000000000000',
      close: '17.6205000000000000',
      volume: '2276165.3000000000000000',
      quoteVolume: 39710164.3496227,
      btcVolume: 4298.963618020441,
      usdVolume: 39710164.3496227,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '17.6205000000000000',
      high: '18.5900000000000000',
      low: '17.5928000000000000',
      close: '18.2994000000000000',
      volume: '2995977.4100000000000000',
      quoteVolume: 54487455.92163716,
      btcVolume: 5888.09404421164,
      usdVolume: 54487455.92163716,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '18.3036000000000000',
      high: '18.9999000000000000',
      low: '17.7282000000000000',
      close: '18.4046000000000000',
      volume: '3228966.6100000000000000',
      quoteVolume: 59615751.20299803,
      btcVolume: 6420.203822258735,
      usdVolume: 59615751.20299803,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '18.4007000000000000',
      high: '18.7700000000000000',
      low: '17.8266000000000000',
      close: '18.0790000000000000',
      volume: '2385481.9700000000000000',
      quoteVolume: 43474784.56725936,
      btcVolume: 4714.97312281895,
      usdVolume: 43474784.56725936,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '18.0790000000000000',
      high: '18.1538000000000000',
      low: '17.4194000000000000',
      close: '17.6351000000000000',
      volume: '1959582.7300000000000000',
      quoteVolume: 34842425.382078946,
      btcVolume: 3776.4969692526847,
      usdVolume: 34842425.382078946,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '17.6351000000000000',
      high: '17.7618000000000000',
      low: '16.5473000000000000',
      close: '17.1647000000000000',
      volume: '2053439.4400000000000000',
      quoteVolume: 35131243.13980965,
      btcVolume: 3840.4754390289468,
      usdVolume: 35131243.13980965,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '17.1647000000000000',
      high: '17.4825000000000000',
      low: '16.9354000000000000',
      close: '17.0676000000000000',
      volume: '1852340.6900000000000000',
      quoteVolume: 32019335.19000079,
      btcVolume: 3504.743471468927,
      usdVolume: 32019335.19000079,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '17.0675000000000000',
      high: '17.3247000000000000',
      low: '16.8122000000000000',
      close: '17.1507000000000000',
      volume: '1687884.7600000000000000',
      quoteVolume: 28863376.478104953,
      btcVolume: 3151.7268006262602,
      usdVolume: 28863376.478104953,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '17.1542000000000000',
      high: '18.0980000000000000',
      low: '16.8900000000000000',
      close: '18.0154000000000000',
      volume: '2040900.4400000000000000',
      quoteVolume: 35931733.34284701,
      btcVolume: 3925.100741605019,
      usdVolume: 35931733.34284701,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '18.0166000000000000',
      high: '18.3400000000000000',
      low: '17.2350000000000000',
      close: '17.4645000000000000',
      volume: '2487505.4900000000000000',
      quoteVolume: 44544637.59956494,
      btcVolume: 4853.4342390229085,
      usdVolume: 44544637.59956494,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '17.4645000000000000',
      high: '17.9799000000000000',
      low: '17.4000000000000000',
      close: '17.7285000000000000',
      volume: '2110733.7600000000000000',
      quoteVolume: 37350560.43213578,
      btcVolume: 4021.207302975722,
      usdVolume: 37350560.43213578,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '17.7268000000000000',
      high: '18.1151000000000000',
      low: '17.4500000000000000',
      close: '18.0900000000000000',
      volume: '2172957.4900000000000000',
      quoteVolume: 38516127.55802692,
      btcVolume: 4103.451167804567,
      usdVolume: 38516127.55802692,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '18.0950000000000000',
      high: '18.7700000000000000',
      low: '18.0430000000000000',
      close: '18.7500000000000000',
      volume: '2393871.4500000000000000',
      quoteVolume: 43866390.168993205,
      btcVolume: 4602.916363249338,
      usdVolume: 43866390.168993205,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '18.7508000000000000',
      high: '19.5500000000000000',
      low: '18.2350000000000000',
      close: '19.1853000000000000',
      volume: '2790281.4600000000000000',
      quoteVolume: 52627925.923414364,
      btcVolume: 5510.371737165114,
      usdVolume: 52627925.923414364,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '19.1878000000000000',
      high: '20.0434000000000000',
      low: '19.1451000000000000',
      close: '19.8242000000000000',
      volume: '2956369.7600000000000000',
      quoteVolume: 58227674.3389005,
      btcVolume: 6053.932571636272,
      usdVolume: 58227674.3389005,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '19.8196000000000000',
      high: '20.4900000000000000',
      low: '19.2639000000000000',
      close: '19.5557000000000000',
      volume: '2857739.1600000000000000',
      quoteVolume: 56378423.676656574,
      btcVolume: 5746.873011607245,
      usdVolume: 56378423.676656574,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '19.5524000000000000',
      high: '20.1999000000000000',
      low: '18.4000000000000000',
      close: '19.7474000000000000',
      volume: '3841364.9100000000000000',
      quoteVolume: 74130208.23661059,
      btcVolume: 7086.863775270473,
      usdVolume: 74130208.23661059,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '19.7489000000000000',
      high: '20.6834000000000000',
      low: '19.4281000000000000',
      close: '20.1732000000000000',
      volume: '3516712.3500000000000000',
      quoteVolume: 70792618.98395808,
      btcVolume: 6451.110876885711,
      usdVolume: 70792618.98395808,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '20.1692000000000000',
      high: '20.4091000000000000',
      low: '19.7404000000000000',
      close: '19.8990000000000000',
      volume: '2219760.2000000000000000',
      quoteVolume: 44493255.13050928,
      btcVolume: 4013.2992143742485,
      usdVolume: 44493255.13050928,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '19.8926000000000000',
      high: '20.0995000000000000',
      low: '19.5111000000000000',
      close: '19.9700000000000000',
      volume: '2052242.5900000000000000',
      quoteVolume: 40761604.204639345,
      btcVolume: 3688.6879848192216,
      usdVolume: 40761604.204639345,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '19.9700000000000000',
      high: '20.8550000000000000',
      low: '19.9102000000000000',
      close: '20.6615000000000000',
      volume: '2251101.2100000000000000',
      quoteVolume: 46058826.375720695,
      btcVolume: 4123.40021955276,
      usdVolume: 46058826.375720695,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '20.6637000000000000',
      high: '21.7400000000000000',
      low: '20.5100000000000000',
      close: '21.6168000000000000',
      volume: '2657942.8500000000000000',
      quoteVolume: 55887870.71842739,
      btcVolume: 4818.782175909525,
      usdVolume: 55887870.71842739,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '21.6204000000000000',
      high: '22.1832000000000000',
      low: '19.1000000000000000',
      close: '20.9898000000000000',
      volume: '3352897.4600000000000000',
      quoteVolume: 69934920.73105527,
      btcVolume: 6141.195512343305,
      usdVolume: 69934920.73105527,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '20.9899000000000000',
      high: '22.5000000000000000',
      low: '20.7000000000000000',
      close: '22.0504000000000000',
      volume: '2668345.0200000000000000',
      quoteVolume: 58090829.071236245,
      btcVolume: 5171.676116333851,
      usdVolume: 58090829.071236245,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '22.0622000000000000',
      high: '22.4433000000000000',
      low: '21.4100000000000000',
      close: '22.2818000000000000',
      volume: '2388746.3300000000000000',
      quoteVolume: 52666694.152770996,
      btcVolume: 4685.128631957818,
      usdVolume: 52666694.152770996,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '22.2967000000000000',
      high: '23.5400000000000000',
      low: '22.0566000000000000',
      close: '23.2728000000000000',
      volume: '3698317.5100000000000000',
      quoteVolume: 85353655.3331338,
      btcVolume: 7486.721568389888,
      usdVolume: 85353655.3331338,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '23.2685000000000000',
      high: '23.3150000000000000',
      low: '22.5000000000000000',
      close: '22.7487000000000000',
      volume: '2442941.2600000000000000',
      quoteVolume: 56050066.31906953,
      btcVolume: 4770.055541701376,
      usdVolume: 56050066.31906953,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '22.7488000000000000',
      high: '22.9999000000000000',
      low: '21.4515000000000000',
      close: '22.3323000000000000',
      volume: '2770757.2300000000000000',
      quoteVolume: 62149286.781891696,
      btcVolume: 5325.432326997081,
      usdVolume: 62149286.781891696,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '22.3323000000000000',
      high: '22.9241000000000000',
      low: '21.8571000000000000',
      close: '22.9234000000000000',
      volume: '2408649.6800000000000000',
      quoteVolume: 53989515.896717064,
      btcVolume: 4622.635302886836,
      usdVolume: 53989515.896717064,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '22.9230000000000000',
      high: '22.9233000000000000',
      low: '22.0462000000000000',
      close: '22.4220000000000000',
      volume: '2107633.5500000000000000',
      quoteVolume: 47448309.30630981,
      btcVolume: 4067.839134883987,
      usdVolume: 47448309.30630981,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '22.4224000000000000',
      high: '22.7700000000000000',
      low: '22.0462000000000000',
      close: '22.6369000000000000',
      volume: '2108928.4200000000000000',
      quoteVolume: 47454718.99539966,
      btcVolume: 3996.4516752424197,
      usdVolume: 47454718.99539966,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '22.6369000000000000',
      high: '22.7306000000000000',
      low: '20.6600000000000000',
      close: '21.2902000000000000',
      volume: '3211543.4400000000000000',
      quoteVolume: 69760318.54425076,
      btcVolume: 6019.612592108588,
      usdVolume: 69760318.54425076,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '21.2993000000000000',
      high: '21.9820000000000000',
      low: '20.5710000000000000',
      close: '21.4919000000000000',
      volume: '2455644.0200000000000000',
      quoteVolume: 52586016.37716104,
      btcVolume: 4598.830579180555,
      usdVolume: 52586016.37716104,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '21.4919000000000000',
      high: '21.8560000000000000',
      low: '20.6400000000000000',
      close: '21.7667000000000000',
      volume: '3079188.7800000000000000',
      quoteVolume: 65454119.38361212,
      btcVolume: 5675.420522437151,
      usdVolume: 65454119.38361212,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '21.7751000000000000',
      high: '23.3019000000000000',
      low: '21.2600000000000000',
      close: '23.1047000000000000',
      volume: '3817320.2200000000000000',
      quoteVolume: 85895020.77906209,
      btcVolume: 7324.882591857548,
      usdVolume: 85895020.77906209,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '23.0966000000000000',
      high: '23.7884000000000000',
      low: '22.7000000000000000',
      close: '23.0881000000000000',
      volume: '2752150.0600000000000000',
      quoteVolume: 64002725.1804066,
      btcVolume: 5394.658484324495,
      usdVolume: 64002725.1804066,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '23.1045000000000000',
      high: '23.7790000000000000',
      low: '22.7000000000000000',
      close: '23.5290000000000000',
      volume: '2705813.5400000000000000',
      quoteVolume: 62902668.60949415,
      btcVolume: 5311.255677616959,
      usdVolume: 62902668.60949415,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '23.5291000000000000',
      high: '23.9100000000000000',
      low: '22.9584000000000000',
      close: '23.4961000000000000',
      volume: '2423482.1500000000000000',
      quoteVolume: 56563755.666076384,
      btcVolume: 4691.623480656751,
      usdVolume: 56563755.666076384,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '23.4868000000000000',
      high: '23.7800000000000000',
      low: '22.3330000000000000',
      close: '22.9705000000000000',
      volume: '2531759.2800000000000000',
      quoteVolume: 58722035.45027393,
      btcVolume: 4835.174004514033,
      usdVolume: 58722035.45027393,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '22.9802000000000000',
      high: '23.2500000000000000',
      low: '21.8219000000000000',
      close: '22.3761000000000000',
      volume: '2128074.0300000000000000',
      quoteVolume: 47918443.635081224,
      btcVolume: 4064.0451093136103,
      usdVolume: 47918443.635081224,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '22.3817000000000000',
      high: '23.3000000000000000',
      low: '22.0265000000000000',
      close: '23.0888000000000000',
      volume: '2236274.1500000000000000',
      quoteVolume: 50958756.88971377,
      btcVolume: 4320.849344252172,
      usdVolume: 50958756.88971377,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '23.0909000000000000',
      high: '23.3200000000000000',
      low: '21.5000000000000000',
      close: '22.0988000000000000',
      volume: '2489462.0200000000000000',
      quoteVolume: 56223221.93286248,
      btcVolume: 4795.974426477643,
      usdVolume: 56223221.93286248,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '22.0994000000000000',
      high: '22.2464000000000000',
      low: '21.2814000000000000',
      close: '22.2044000000000000',
      volume: '1891753.4200000000000000',
      quoteVolume: 41537070.73814768,
      btcVolume: 3592.3703459321446,
      usdVolume: 41537070.73814768,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '22.2010000000000000',
      high: '22.2348000000000000',
      low: '21.2841000000000000',
      close: '21.8555000000000000',
      volume: '1618939.3500000000000000',
      quoteVolume: 35415367.6439598,
      btcVolume: 3050.172713172957,
      usdVolume: 35415367.6439598,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '21.8575000000000000',
      high: '22.9746000000000000',
      low: '21.6655000000000000',
      close: '22.5953000000000000',
      volume: '1877683.4400000000000000',
      quoteVolume: 42093704.95900781,
      btcVolume: 3588.708992922525,
      usdVolume: 42093704.95900781,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '22.6016000000000000',
      high: '22.6415000000000000',
      low: '20.9805000000000000',
      close: '21.3882000000000000',
      volume: '2214962.9300000000000000',
      quoteVolume: 48368662.81298032,
      btcVolume: 4213.210966957328,
      usdVolume: 48368662.81298032,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '21.3881000000000000',
      high: '22.4743000000000000',
      low: '21.2651000000000000',
      close: '22.1999000000000000',
      volume: '2499792.2900000000000000',
      quoteVolume: 55285830.68548643,
      btcVolume: 4852.707891075785,
      usdVolume: 55285830.68548643,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '22.2005000000000000',
      high: '23.1507000000000000',
      low: '21.8064000000000000',
      close: '23.1127000000000000',
      volume: '2925157.1700000000000000',
      quoteVolume: 65680254.026992634,
      btcVolume: 5788.970095035588,
      usdVolume: 65680254.026992634,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '23.1127000000000000',
      high: '23.5846000000000000',
      low: '22.6158000000000000',
      close: '23.0811000000000000',
      volume: '2526396.8800000000000000',
      quoteVolume: 58274233.91624748,
      btcVolume: 5107.116104849058,
      usdVolume: 58274233.91624748,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '23.0783000000000000',
      high: '23.5000000000000000',
      low: '22.7416000000000000',
      close: '22.9286000000000000',
      volume: '1825207.1700000000000000',
      quoteVolume: 42300003.84051298,
      btcVolume: 3677.51541413538,
      usdVolume: 42300003.84051298,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '22.9441000000000000',
      high: '23.8300000000000000',
      low: '22.9000000000000000',
      close: '23.6208000000000000',
      volume: '1777138.8500000000000000',
      quoteVolume: 41436875.3779341,
      btcVolume: 3568.130657260122,
      usdVolume: 41436875.3779341,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '23.6250000000000000',
      high: '23.9000000000000000',
      low: '22.9670000000000000',
      close: '23.1477000000000000',
      volume: '1767387.7800000000000000',
      quoteVolume: 41283857.21209864,
      btcVolume: 3534.6531834593225,
      usdVolume: 41283857.21209864,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '23.1481000000000000',
      high: '25.4100000000000000',
      low: '22.8400000000000000',
      close: '24.7028000000000000',
      volume: '3998518.4600000000000000',
      quoteVolume: 95349979.86598961,
      btcVolume: 8016.152928036265,
      usdVolume: 95348715.97884162,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '24.7101000000000000',
      high: '25.8262000000000000',
      low: '23.1215000000000000',
      close: '24.7652000000000000',
      volume: '5843722.5300000000000000',
      quoteVolume: 144376866.58587617,
      btcVolume: 12476.71910511825,
      usdVolume: 144376866.58587617,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '24.7653000000000000',
      high: '25.5400000000000000',
      low: '19.8765000000000000',
      close: '20.3592000000000000',
      volume: '7208188.4900000000000000',
      quoteVolume: 168388157.16915026,
      btcVolume: 15323.190970084937,
      usdVolume: 168388157.16915026,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '20.4027000000000000',
      high: '21.8300000000000000',
      low: '19.2000000000000000',
      close: '20.9712000000000000',
      volume: '5304836.8000000000000000',
      quoteVolume: 110005537.1003693,
      btcVolume: 10648.840229664847,
      usdVolume: 110005537.1003693,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '20.9995000000000000',
      high: '21.5539000000000000',
      low: '18.0110000000000000',
      close: '19.4698000000000000',
      volume: '4587657.7700000000000000',
      quoteVolume: 89925282.01269266,
      btcVolume: 8795.872253723111,
      usdVolume: 89925282.01269266,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '19.4737000000000000',
      high: '23.4418000000000000',
      low: '18.5283000000000000',
      close: '23.1420000000000000',
      volume: '8069211.7600000000000000',
      quoteVolume: 174283155.6269918,
      btcVolume: 17068.88087509094,
      usdVolume: 174283155.6269918,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '23.1612000000000000',
      high: '23.5545000000000000',
      low: '20.0000000000000000',
      close: '22.4426000000000000',
      volume: '5402317.0400000000000000',
      quoteVolume: 117739085.20631854,
      btcVolume: 11629.8606720547,
      usdVolume: 117739085.20631854,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '22.4428000000000000',
      high: '23.4200000000000000',
      low: '21.2739000000000000',
      close: '23.2837000000000000',
      volume: '5158563.9800000000000000',
      quoteVolume: 115741375.12774241,
      btcVolume: 11408.651574079575,
      usdVolume: 115741375.12774241,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '23.2745000000000000',
      high: '24.5201000000000000',
      low: '22.4139000000000000',
      close: '24.4716000000000000',
      volume: '4016452.5100000000000000',
      quoteVolume: 94841678.35523182,
      btcVolume: 9328.731014737568,
      usdVolume: 94841678.35523182,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '24.4847000000000000',
      high: '25.6700000000000000',
      low: '24.0568000000000000',
      close: '24.5489000000000000',
      volume: '4197768.0800000000000000',
      quoteVolume: 103939762.77621293,
      btcVolume: 10055.05164439836,
      usdVolume: 103939762.77621293,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '24.5579000000000000',
      high: '25.6969000000000000',
      low: '23.0677000000000000',
      close: '25.3990000000000000',
      volume: '3622605.0700000000000000',
      quoteVolume: 88276634.60726066,
      btcVolume: 8560.638049834533,
      usdVolume: 88276634.60726066,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '25.4000000000000000',
      high: '28.5305000000000000',
      low: '25.3575000000000000',
      close: '28.5050000000000000',
      volume: '6538510.1800000000000000',
      quoteVolume: 175802729.4278746,
      btcVolume: 16975.401468484088,
      usdVolume: 175802729.4278746,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '28.5044000000000000',
      high: '32.4401000000000000',
      low: '27.6100000000000000',
      close: '31.1656000000000000',
      volume: '10646215.6600000000000000',
      quoteVolume: 324162248.7407463,
      btcVolume: 31172.01029736164,
      usdVolume: 324162248.7407463,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '31.1656000000000000',
      high: '33.3888000000000000',
      low: '29.0000000000000000',
      close: '31.2523000000000000',
      volume: '9039644.3200000000000000',
      quoteVolume: 282868031.2655408,
      btcVolume: 26883.24594624267,
      usdVolume: 282868031.2655408,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '31.2546000000000000',
      high: '31.2780000000000000',
      low: '26.7123000000000000',
      close: '27.1181000000000000',
      volume: '7931354.6460000000000000',
      quoteVolume: 228427870.13850227,
      btcVolume: 21238.609622009128,
      usdVolume: 228427870.13850227,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '27.1148000000000000',
      high: '28.4724000000000000',
      low: '25.8200000000000000',
      close: '27.8695000000000000',
      volume: '5777419.7520000000000000',
      quoteVolume: 157849897.40358767,
      btcVolume: 14525.119593023768,
      usdVolume: 157849897.40358767,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '27.8539000000000000',
      high: '28.8300000000000000',
      low: '26.0500000000000000',
      close: '27.0950000000000000',
      volume: '4620518.8740000000000000',
      quoteVolume: 125923630.01926374,
      btcVolume: 11551.914313428693,
      usdVolume: 125923630.01926374,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '27.1106000000000000',
      high: '28.7500000000000000',
      low: '26.5708000000000000',
      close: '27.6290000000000000',
      volume: '4243135.9990000000000000',
      quoteVolume: 117188169.08344378,
      btcVolume: 10721.855220981095,
      usdVolume: 117188169.08344378,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '27.6414000000000000',
      high: '27.9770000000000000',
      low: '26.6591000000000000',
      close: '27.2035000000000000',
      volume: '2431517.1500000000000000',
      quoteVolume: 66209405.92737906,
      btcVolume: 6017.836049060832,
      usdVolume: 66209405.92737906,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '27.2035000000000000',
      high: '27.3200000000000000',
      low: '25.1247000000000000',
      close: '26.3160000000000000',
      volume: '2843429.9720000000000000',
      quoteVolume: 74991076.55056357,
      btcVolume: 6881.997502694567,
      usdVolume: 74991076.55056357,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '26.3167000000000000',
      high: '26.6800000000000000',
      low: '22.1000000000000000',
      close: '23.1232000000000000',
      volume: '5251422.2430000000000000',
      quoteVolume: 127147745.41469589,
      btcVolume: 11950.186281642047,
      usdVolume: 127147745.41469589,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '23.1273000000000000',
      high: '24.5650000000000000',
      low: '22.6666000000000000',
      close: '24.0468000000000000',
      volume: '3281775.4620000000000000',
      quoteVolume: 77971128.39187472,
      btcVolume: 7453.267500200181,
      usdVolume: 77971128.39187472,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '24.0468000000000000',
      high: '24.3437000000000000',
      low: '22.4514000000000000',
      close: '22.8331000000000000',
      volume: '3134141.7740000000000000',
      quoteVolume: 73949538.77212654,
      btcVolume: 7097.075147605696,
      usdVolume: 73949538.77212654,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '22.8448000000000000',
      high: '24.6999000000000000',
      low: '22.5661000000000000',
      close: '24.5745000000000000',
      volume: '3280582.2240000000000000',
      quoteVolume: 78057236.24715658,
      btcVolume: 7468.991503938146,
      usdVolume: 78057236.24715658,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '24.5749000000000000',
      high: '25.0799000000000000',
      low: '23.7111000000000000',
      close: '24.6924000000000000',
      volume: '2826869.5890000000000000',
      quoteVolume: 69263025.79303573,
      btcVolume: 6491.558933280871,
      usdVolume: 69263025.79303573,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '24.6925000000000000',
      high: '26.4628000000000000',
      low: '24.4750000000000000',
      close: '26.1998000000000000',
      volume: '3109951.9450000000000000',
      quoteVolume: 79026166.8893397,
      btcVolume: 7375.746915791385,
      usdVolume: 79026166.8893397,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '26.1885000000000000',
      high: '26.9000000000000000',
      low: '25.5012000000000000',
      close: '26.2510000000000000',
      volume: '2549943.8930000000000000',
      quoteVolume: 66948796.67374076,
      btcVolume: 6245.902387721704,
      usdVolume: 66948796.67374076,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '26.2435000000000000',
      high: '27.7200000000000000',
      low: '25.8313000000000000',
      close: '26.9855000000000000',
      volume: '3215302.9540000000000000',
      quoteVolume: 85939137.96800753,
      btcVolume: 7904.878434360903,
      usdVolume: 85939137.96800753,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '26.9969000000000000',
      high: '29.0000000000000000',
      low: '26.8600000000000000',
      close: '28.8946000000000000',
      volume: '3642652.3360000000000000',
      quoteVolume: 102012505.98227179,
      btcVolume: 9504.594750278005,
      usdVolume: 102012505.98227179,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '28.8814000000000000',
      high: '29.5646000000000000',
      low: '27.8813000000000000',
      close: '29.2656000000000000',
      volume: '3102332.1810000000000000',
      quoteVolume: 89000015.31908017,
      btcVolume: 8286.090073062971,
      usdVolume: 89000015.31908017,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '29.2696000000000000',
      high: '29.3927000000000000',
      low: '26.2111000000000000',
      close: '27.4720000000000000',
      volume: '4000390.3250000000000000',
      quoteVolume: 111543168.04777229,
      btcVolume: 10423.810924564852,
      usdVolume: 111543168.04777229,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '27.3367000000000000',
      high: '28.3633000000000000',
      low: '25.6652000000000000',
      close: '27.3369000000000000',
      volume: '4117221.0050000000000000',
      quoteVolume: 111198198.15562725,
      btcVolume: 10572.72520169783,
      usdVolume: 111198198.15562725,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '27.3338000000000000',
      high: '28.3997000000000000',
      low: '27.0701000000000000',
      close: '28.1867000000000000',
      volume: '2946071.9920000000000000',
      quoteVolume: 81824336.491502,
      btcVolume: 7759.17949681676,
      usdVolume: 81824336.491502,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '28.1872000000000000',
      high: '29.1500000000000000',
      low: '27.9658000000000000',
      close: '28.9668000000000000',
      volume: '3102597.6600000000000000',
      quoteVolume: 89029435.36271603,
      btcVolume: 8394.366201707255,
      usdVolume: 89029435.36271603,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '28.9832000000000000',
      high: '29.2665000000000000',
      low: '28.0687000000000000',
      close: '28.6158000000000000',
      volume: '2268257.1710000000000000',
      quoteVolume: 65226739.00450134,
      btcVolume: 6100.3480015059995,
      usdVolume: 65226739.00450134,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '28.6330000000000000',
      high: '28.9900000000000000',
      low: '27.2500000000000000',
      close: '27.6812000000000000',
      volume: '2810020.1650000000000000',
      quoteVolume: 79203442.53717537,
      btcVolume: 7402.756056350025,
      usdVolume: 79203442.53717537,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '27.6907000000000000',
      high: '27.8965000000000000',
      low: '26.8401000000000000',
      close: '27.6898000000000000',
      volume: '2059912.1820000000000000',
      quoteVolume: 56521007.48227771,
      btcVolume: 5326.109268024313,
      usdVolume: 56521007.48227771,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '27.6931000000000000',
      high: '27.8153000000000000',
      low: '26.4999000000000000',
      close: '27.7424000000000000',
      volume: '2423081.9940000000000000',
      quoteVolume: 66102858.40443837,
      btcVolume: 6161.524987847587,
      usdVolume: 66102858.40443837,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '27.7342000000000000',
      high: '28.5368000000000000',
      low: '27.4548000000000000',
      close: '28.4076000000000000',
      volume: '2590468.0830000000000000',
      quoteVolume: 72830100.20340902,
      btcVolume: 6638.910063675278,
      usdVolume: 72830100.20340902,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '28.3980000000000000',
      high: '29.2814000000000000',
      low: '28.0666000000000000',
      close: '28.4414000000000000',
      volume: '2405371.4570000000000000',
      quoteVolume: 68983739.73955177,
      btcVolume: 6094.207111159801,
      usdVolume: 68983739.73955177,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '28.4100000000000000',
      high: '28.7200000000000000',
      low: '27.8760000000000000',
      close: '28.5271000000000000',
      volume: '2287361.9230000000000000',
      quoteVolume: 64862236.42497369,
      btcVolume: 5711.44629460792,
      usdVolume: 64862236.42497369,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '28.5200000000000000',
      high: '31.1299000000000000',
      low: '28.4338000000000000',
      close: '30.6503000000000000',
      volume: '4002611.1740000000000000',
      quoteVolume: 120352867.44459017,
      btcVolume: 10544.280084904543,
      usdVolume: 120352867.44459017,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '30.6503000000000000',
      high: '31.7999000000000000',
      low: '30.1870000000000000',
      close: '30.7256000000000000',
      volume: '3052275.4880000000000000',
      quoteVolume: 94547125.33016007,
      btcVolume: 8257.112105221257,
      usdVolume: 94547125.33016007,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '30.7256000000000000',
      high: '31.3300000000000000',
      low: '30.3733000000000000',
      close: '30.8380000000000000',
      volume: '2235892.8320000000000000',
      quoteVolume: 68852635.2776885,
      btcVolume: 6038.336550994363,
      usdVolume: 68852635.2776885,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '30.8380000000000000',
      high: '31.6663000000000000',
      low: '30.3990000000000000',
      close: '31.3256000000000000',
      volume: '2664333.4610000000000000',
      quoteVolume: 82806329.4986107,
      btcVolume: 7257.335240346204,
      usdVolume: 82806329.4986107,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '31.3256000000000000',
      high: '31.9798000000000000',
      low: '29.5705000000000000',
      close: '30.0516000000000000',
      volume: '2971307.3670000000000000',
      quoteVolume: 90779001.72612782,
      btcVolume: 7990.956897298697,
      usdVolume: 90779001.72612782,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '30.0510000000000000',
      high: '30.5640000000000000',
      low: '29.6521000000000000',
      close: '30.2414000000000000',
      volume: '1845150.5060000000000000',
      quoteVolume: 55749675.69959583,
      btcVolume: 4918.479991791265,
      usdVolume: 55749675.69959583,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '30.2423000000000000',
      high: '31.0354000000000000',
      low: '30.1650000000000000',
      close: '30.6593000000000000',
      volume: '2267377.3170000000000000',
      quoteVolume: 69506327.30622022,
      btcVolume: 6086.495770877648,
      usdVolume: 69506327.30622022,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '30.6568000000000000',
      high: '30.6766000000000000',
      low: '29.7002000000000000',
      close: '29.9107000000000000',
      volume: '1752644.9990000000000000',
      quoteVolume: 52880539.760139465,
      btcVolume: 4575.7130204564255,
      usdVolume: 52880539.760139465,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '29.9043000000000000',
      high: '30.0892000000000000',
      low: '28.5000000000000000',
      close: '28.8227000000000000',
      volume: '2583242.4400000000000000',
      quoteVolume: 75672316.51136452,
      btcVolume: 6399.37021187418,
      usdVolume: 75672316.51136452,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '28.8131000000000000',
      high: '30.4074000000000000',
      low: '28.6406000000000000',
      close: '29.7329000000000000',
      volume: '2663615.8890000000000000',
      quoteVolume: 78865122.07201448,
      btcVolume: 6329.800794199335,
      usdVolume: 78865122.07201448,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '29.7355000000000000',
      high: '31.3000000000000000',
      low: '29.6655000000000000',
      close: '30.6396000000000000',
      volume: '2830772.7050000000000000',
      quoteVolume: 86718736.3816204,
      btcVolume: 6732.909811327086,
      usdVolume: 86718736.3816204,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '30.6334000000000000',
      high: '31.2000000000000000',
      low: '30.0652000000000000',
      close: '30.3066000000000000',
      volume: '1751841.2190000000000000',
      quoteVolume: 53822247.52955811,
      btcVolume: 4166.146373136381,
      usdVolume: 53822247.52955811,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '30.6461000000000000',
      high: '30.8781000000000000',
      low: '30.4243000000000000',
      close: '30.6639000000000000',
      volume: '1625502.5240000000000000',
      quoteVolume: 49794483.44654,
      btcVolume: 3828.381409664332,
      usdVolume: 49794483.44654,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '30.6522000000000000',
      high: '30.9726000000000000',
      low: '29.8805000000000000',
      close: '30.1280000000000000',
      volume: '1486018.1520000000000000',
      quoteVolume: 45184192.15742622,
      btcVolume: 3461.2183046942464,
      usdVolume: 45184192.15742622,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '30.1342000000000000',
      high: '31.4300000000000000',
      low: '29.9600000000000000',
      close: '31.0467000000000000',
      volume: '2647734.6210000000000000',
      quoteVolume: 81431799.40994392,
      btcVolume: 6237.364724196646,
      usdVolume: 81431799.40994392,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '31.0436000000000000',
      high: '32.3788000000000000',
      low: '30.9000000000000000',
      close: '31.4826000000000000',
      volume: '2881430.2220000000000000',
      quoteVolume: 91088211.45629856,
      btcVolume: 6829.721068843635,
      usdVolume: 91088211.45629856,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '31.4730000000000000',
      high: '31.9375000000000000',
      low: '29.8000000000000000',
      close: '30.3338000000000000',
      volume: '2052118.9290000000000000',
      quoteVolume: 63425566.100130275,
      btcVolume: 4717.743672827569,
      usdVolume: 63425566.100130275,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '30.3295000000000000',
      high: '30.4272000000000000',
      low: '29.4700000000000000',
      close: '29.8702000000000000',
      volume: '1977911.0400000000000000',
      quoteVolume: 59517443.808163345,
      btcVolume: 4471.350986754041,
      usdVolume: 59517443.808163345,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '29.8696000000000000',
      high: '30.2596000000000000',
      low: '27.5111000000000000',
      close: '28.4909000000000000',
      volume: '3211305.3580000000000000',
      quoteVolume: 91773152.80737089,
      btcVolume: 6865.029260469122,
      usdVolume: 91773152.80737089,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '28.4969000000000000',
      high: '28.8880000000000000',
      low: '28.1132000000000000',
      close: '28.5058000000000000',
      volume: '1975871.5750000000000000',
      quoteVolume: 56382966.93345828,
      btcVolume: 4104.696666980671,
      usdVolume: 56382966.93345828,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '28.5058000000000000',
      high: '28.6790000000000000',
      low: '28.0000000000000000',
      close: '28.5318000000000000',
      volume: '1916131.6810000000000000',
      quoteVolume: 54186354.18994433,
      btcVolume: 3937.006778622533,
      usdVolume: 54186354.18994433,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '28.5485000000000000',
      high: '29.3079000000000000',
      low: '27.7782000000000000',
      close: '27.7983000000000000',
      volume: '2123599.0520000000000000',
      quoteVolume: 60737427.18014957,
      btcVolume: 4463.645604676236,
      usdVolume: 60737427.18014957,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '27.7984000000000000',
      high: '27.7984000000000000',
      low: '25.7499000000000000',
      close: '26.8638000000000000',
      volume: '2591560.8550000000000000',
      quoteVolume: 69103680.17142187,
      btcVolume: 5107.912094364955,
      usdVolume: 69103680.17142187,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '26.8722000000000000',
      high: '26.9700000000000000',
      low: '25.9000000000000000',
      close: '26.9600000000000000',
      volume: '2039985.3400000000000000',
      quoteVolume: 54040159.97019821,
      btcVolume: 3890.820934023125,
      usdVolume: 54040159.97019821,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '26.9607000000000000',
      high: '27.8947000000000000',
      low: '26.6601000000000000',
      close: '27.6875000000000000',
      volume: '2396900.8220000000000000',
      quoteVolume: 65625192.2818929,
      btcVolume: 4465.977407660962,
      usdVolume: 65625192.2818929,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '27.6860000000000000',
      high: '29.4692000000000000',
      low: '27.6668000000000000',
      close: '29.0435000000000000',
      volume: '2705358.2900000000000000',
      quoteVolume: 77745649.67222922,
      btcVolume: 4990.296761515876,
      usdVolume: 77745649.67222922,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '29.0391000000000000',
      high: '29.8990000000000000',
      low: '26.8500000000000000',
      close: '27.7651000000000000',
      volume: '3088858.1960000000000000',
      quoteVolume: 88661320.14317802,
      btcVolume: 5796.6328340812925,
      usdVolume: 88661320.14317802,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '27.7628000000000000',
      high: '28.6943000000000000',
      low: '27.4490000000000000',
      close: '28.4101000000000000',
      volume: '2296984.2220000000000000',
      quoteVolume: 64827072.49203944,
      btcVolume: 4258.489159084323,
      usdVolume: 64827072.49203944,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '28.4101000000000000',
      high: '28.9691000000000000',
      low: '27.3461000000000000',
      close: '28.0941000000000000',
      volume: '2147857.4210000000000000',
      quoteVolume: 60633554.16222215,
      btcVolume: 3946.070465446345,
      usdVolume: 60633554.16222215,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '28.0836000000000000',
      high: '28.4880000000000000',
      low: '27.6114000000000000',
      close: '28.2188000000000000',
      volume: '2172589.6330000000000000',
      quoteVolume: 61108455.63378035,
      btcVolume: 3990.1571339070315,
      usdVolume: 61108455.63378035,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '28.2183000000000000',
      high: '28.5999000000000000',
      low: '27.7966000000000000',
      close: '28.0160000000000000',
      volume: '1974074.3100000000000000',
      quoteVolume: 55629780.26008157,
      btcVolume: 3569.799698588421,
      usdVolume: 55629780.26008157,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '28.0057000000000000',
      high: '28.1599000000000000',
      low: '27.3028000000000000',
      close: '27.6874000000000000',
      volume: '1661489.2860000000000000',
      quoteVolume: 46197453.38885523,
      btcVolume: 2911.5701341389563,
      usdVolume: 46197453.38885523,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '27.7019000000000000',
      high: '28.5198000000000000',
      low: '27.5284000000000000',
      close: '28.1974000000000000',
      volume: '1870570.7210000000000000',
      quoteVolume: 52380431.54643035,
      btcVolume: 3219.0015810697378,
      usdVolume: 52380431.54643035,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '28.1974000000000000',
      high: '28.1974000000000000',
      low: '27.3684000000000000',
      close: '27.9311000000000000',
      volume: '1491728.0070000000000000',
      quoteVolume: 41393342.505827054,
      btcVolume: 2581.1714629579965,
      usdVolume: 41393342.505827054,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '27.9359000000000000',
      high: '28.2332000000000000',
      low: '27.0700000000000000',
      close: '27.6786000000000000',
      volume: '1441384.5610000000000000',
      quoteVolume: 40098284.74564967,
      btcVolume: 2509.6270634778307,
      usdVolume: 40098284.74564967,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '27.6822000000000000',
      high: '28.2202000000000000',
      low: '27.4461000000000000',
      close: '27.9451000000000000',
      volume: '1684972.1740000000000000',
      quoteVolume: 46951053.47256122,
      btcVolume: 2873.554556801199,
      usdVolume: 46951053.47256122,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '27.9588000000000000',
      high: '28.8000000000000000',
      low: '27.9262000000000000',
      close: '28.5552000000000000',
      volume: '2165992.6190000000000000',
      quoteVolume: 61647606.24655668,
      btcVolume: 3620.0821814901874,
      usdVolume: 61647606.24655668,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '28.5552000000000000',
      high: '28.8380000000000000',
      low: '27.5000000000000000',
      close: '28.0931000000000000',
      volume: '2208116.0360000000000000',
      quoteVolume: 62179324.88033911,
      btcVolume: 3481.8660558290812,
      usdVolume: 62179324.88033911,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '28.1049000000000000',
      high: '28.3666000000000000',
      low: '27.6183000000000000',
      close: '28.1387000000000000',
      volume: '1978784.7070000000000000',
      quoteVolume: 55532045.828950375,
      btcVolume: 3111.2806511064537,
      usdVolume: 55532045.828950375,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '28.1266000000000000',
      high: '29.3516000000000000',
      low: '28.0598000000000000',
      close: '28.9715000000000000',
      volume: '2462083.4030000000000000',
      quoteVolume: 70582295.03196461,
      btcVolume: 3855.968113643725,
      usdVolume: 70582295.03196461,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '28.9722000000000000',
      high: '30.8000000000000000',
      low: '28.2955000000000000',
      close: '30.5161000000000000',
      volume: '3160335.4720000000000000',
      quoteVolume: 93653968.66348147,
      btcVolume: 5004.286317743498,
      usdVolume: 93653968.66348147,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '30.5168000000000000',
      high: '31.1200000000000000',
      low: '29.0000000000000000',
      close: '30.0226000000000000',
      volume: '2842370.7830000000000000',
      quoteVolume: 85423529.80392088,
      btcVolume: 4642.671182761763,
      usdVolume: 85423529.80392088,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '30.0291000000000000',
      high: '31.1000000000000000',
      low: '29.7860000000000000',
      close: '30.9950000000000000',
      volume: '2854700.3560000000000000',
      quoteVolume: 86945280.82502285,
      btcVolume: 4722.192647593682,
      usdVolume: 86945280.82502285,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '30.9994000000000000',
      high: '35.1400000000000000',
      low: '30.7934000000000000',
      close: '33.9407000000000000',
      volume: '7296367.3690000000000000',
      quoteVolume: 240609850.8251657,
      btcVolume: 12879.90776771163,
      usdVolume: 240609850.8251657,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '33.9451000000000000',
      high: '35.4338000000000000',
      low: '30.4390000000000000',
      close: '31.2953000000000000',
      volume: '4579889.4800000000000000',
      quoteVolume: 152154802.22398162,
      btcVolume: 7996.9340517331775,
      usdVolume: 152154802.22398162,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '31.2950000000000000',
      high: '31.7708000000000000',
      low: '26.3500000000000000',
      close: '28.2356000000000000',
      volume: '6241853.0130000000000000',
      quoteVolume: 179684915.43192223,
      btcVolume: 10317.064916775644,
      usdVolume: 179684915.43192223,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '28.2354000000000000',
      high: '28.7175000000000000',
      low: '27.2042000000000000',
      close: '28.4606000000000000',
      volume: '2317359.7950000000000000',
      quoteVolume: 65177587.384269916,
      btcVolume: 3831.3766146767975,
      usdVolume: 65177587.384269916,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '28.4606000000000000',
      high: '29.6175000000000000',
      low: '27.9000000000000000',
      close: '29.1766000000000000',
      volume: '2636604.6020000000000000',
      quoteVolume: 76332488.51250012,
      btcVolume: 4378.493625971485,
      usdVolume: 76332488.51250012,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '29.1552000000000000',
      high: '30.4500000000000000',
      low: '28.8544000000000000',
      close: '30.1045000000000000',
      volume: '2856408.5640000000000000',
      quoteVolume: 84710614.21822041,
      btcVolume: 4707.608681471879,
      usdVolume: 84710614.21822041,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '30.1003000000000000',
      high: '31.5999000000000000',
      low: '29.7230000000000000',
      close: '31.4935000000000000',
      volume: '2977355.3390000000000000',
      quoteVolume: 91514232.68682995,
      btcVolume: 4817.517391122217,
      usdVolume: 91514232.68682995,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '31.4935000000000000',
      high: '31.8875000000000000',
      low: '29.1700000000000000',
      close: '30.1996000000000000',
      volume: '3066237.6600000000000000',
      quoteVolume: 94399139.74031925,
      btcVolume: 4914.238089053225,
      usdVolume: 94399139.74031925,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '30.2003000000000000',
      high: '30.9993000000000000',
      low: '29.5306000000000000',
      close: '30.2875000000000000',
      volume: '2360296.3080000000000000',
      quoteVolume: 71525356.8507045,
      btcVolume: 3776.942602662899,
      usdVolume: 71525356.8507045,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '30.2724000000000000',
      high: '30.7930000000000000',
      low: '29.8568000000000000',
      close: '30.7065000000000000',
      volume: '1924462.4660000000000000',
      quoteVolume: 58447530.68081103,
      btcVolume: 3036.9154023239544,
      usdVolume: 58447530.68081103,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '30.7099000000000000',
      high: '31.1371000000000000',
      low: '28.7051000000000000',
      close: '29.0231000000000000',
      volume: '2064562.0540000000000000',
      quoteVolume: 61840304.01373671,
      btcVolume: 3237.434025284598,
      usdVolume: 61840304.01373671,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '29.0315000000000000',
      high: '30.0588000000000000',
      low: '28.6588000000000000',
      close: '29.7280000000000000',
      volume: '1836006.3170000000000000',
      quoteVolume: 53868352.508677736,
      btcVolume: 2845.6790819435187,
      usdVolume: 53868352.508677736,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '29.7296000000000000',
      high: '30.1819000000000000',
      low: '29.0124000000000000',
      close: '29.5026000000000000',
      volume: '1330813.8990000000000000',
      quoteVolume: 39419545.068958886,
      btcVolume: 2055.588312615177,
      usdVolume: 39419545.068958886,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '29.4912000000000000',
      high: '29.6685000000000000',
      low: '29.0600000000000000',
      close: '29.3700000000000000',
      volume: '1219543.1660000000000000',
      quoteVolume: 35795857.65738368,
      btcVolume: 1864.6156139549516,
      usdVolume: 35795857.65738368,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '29.3705000000000000',
      high: '29.4998000000000000',
      low: '27.4500000000000000',
      close: '27.6705000000000000',
      volume: '1844102.0560000000000000',
      quoteVolume: 52562094.302194595,
      btcVolume: 2782.158122499107,
      usdVolume: 52562094.302194595,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '27.6705000000000000',
      high: '28.3853000000000000',
      low: '26.9244000000000000',
      close: '28.1452000000000000',
      volume: '1635344.1810000000000000',
      quoteVolume: 45454697.5386892,
      btcVolume: 2491.802163982006,
      usdVolume: 45454697.5386892,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '28.1440000000000000',
      high: '28.2997000000000000',
      low: '27.3900000000000000',
      close: '27.6926000000000000',
      volume: '1338821.6330000000000000',
      quoteVolume: 37239316.75626387,
      btcVolume: 2036.0163366628099,
      usdVolume: 37239316.75626387,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '27.6950000000000000',
      high: '27.7700000000000000',
      low: '26.7273000000000000',
      close: '27.3419000000000000',
      volume: '1185571.0640000000000000',
      quoteVolume: 32228789.764525007,
      btcVolume: 1796.9005359462299,
      usdVolume: 32222415.98784421,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '27.3490000000000000',
      high: '28.2300000000000000',
      low: '27.2542000000000000',
      close: '28.1603000000000000',
      volume: '1209119.9800000000000000',
      quoteVolume: 33698868.29087121,
      btcVolume: 1826.7844744499994,
      usdVolume: 33698868.29087121,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '28.1603000000000000',
      high: '29.2628000000000000',
      low: '28.0000000000000000',
      close: '29.1571000000000000',
      volume: '1639495.8160000000000000',
      quoteVolume: 47146012.390196115,
      btcVolume: 2467.453865154321,
      usdVolume: 47146012.390196115,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '29.1599000000000000',
      high: '30.1884000000000000',
      low: '28.6669000000000000',
      close: '30.0338000000000000',
      volume: '2025868.5440000000000000',
      quoteVolume: 59594740.44459001,
      btcVolume: 3107.6269302330743,
      usdVolume: 59594740.44459001,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '30.0338000000000000',
      high: '30.3790000000000000',
      low: '29.3069000000000000',
      close: '29.5053000000000000',
      volume: '1536633.4260000000000000',
      quoteVolume: 45782401.5646542,
      btcVolume: 2367.206132560912,
      usdVolume: 45782401.5646542,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '29.5059000000000000',
      high: '30.6140000000000000',
      low: '29.0265000000000000',
      close: '30.5577000000000000',
      volume: '2034306.1120000000000000',
      quoteVolume: 60707183.55854131,
      btcVolume: 2993.371881466091,
      usdVolume: 60707183.55854131,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '30.5524000000000000',
      high: '31.5489000000000000',
      low: '29.6891000000000000',
      close: '30.2255000000000000',
      volume: '3274786.1310000000000000',
      quoteVolume: 100846680.86741851,
      btcVolume: 4478.236654440235,
      usdVolume: 100846680.86741851,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '30.2254000000000000',
      high: '31.2172000000000000',
      low: '29.6100000000000000',
      close: '30.9882000000000000',
      volume: '2045132.3370000000000000',
      quoteVolume: 62437403.31907742,
      btcVolume: 2727.6635487497656,
      usdVolume: 62437403.31907742,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '30.9858000000000000',
      high: '34.2307000000000000',
      low: '30.8812000000000000',
      close: '33.3167000000000000',
      volume: '4117460.2930000000000000',
      quoteVolume: 134985674.6489003,
      btcVolume: 5801.552675700171,
      usdVolume: 134985674.6489003,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '33.3018000000000000',
      high: '35.6900000000000000',
      low: '32.5899000000000000',
      close: '34.3148000000000000',
      volume: '4477498.8180000000000000',
      quoteVolume: 153515421.81364313,
      btcVolume: 6509.728499602096,
      usdVolume: 153515421.81364313,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '34.3072000000000000',
      high: '35.6000000000000000',
      low: '31.4871000000000000',
      close: '32.0500000000000000',
      volume: '4525705.7910000000000000',
      quoteVolume: 151775639.78318813,
      btcVolume: 6527.479468708382,
      usdVolume: 151775639.78318813,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '32.0500000000000000',
      high: '33.8000000000000000',
      low: '30.4719000000000000',
      close: '33.5151000000000000',
      volume: '3563635.6730000000000000',
      quoteVolume: 115167307.80793788,
      btcVolume: 5006.055644717137,
      usdVolume: 115167307.80793788,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '33.5112000000000000',
      high: '34.5050000000000000',
      low: '30.0000000000000000',
      close: '31.1843000000000000',
      volume: '3940926.4420000000000000',
      quoteVolume: 128877133.69173539,
      btcVolume: 5483.664774174263,
      usdVolume: 128877133.69173539,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '31.1698000000000000',
      high: '32.7589000000000000',
      low: '30.0000000000000000',
      close: '32.4895000000000000',
      volume: '2999570.8530000000000000',
      quoteVolume: 94739220.62729084,
      btcVolume: 4084.295268120996,
      usdVolume: 94739220.62729084,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '32.4947000000000000',
      high: '33.4954000000000000',
      low: '31.7313000000000000',
      close: '33.1996000000000000',
      volume: '2595633.3640000000000000',
      quoteVolume: 84823958.91861957,
      btcVolume: 3526.3258018350043,
      usdVolume: 84823958.91861957,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '33.1996000000000000',
      high: '34.0495000000000000',
      low: '32.5100000000000000',
      close: '33.6361000000000000',
      volume: '2418941.5440000000000000',
      quoteVolume: 80768632.43812405,
      btcVolume: 3199.458679420725,
      usdVolume: 80768632.43812405,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '33.6510000000000000',
      high: '34.9900000000000000',
      low: '31.8601000000000000',
      close: '33.6740000000000000',
      volume: '3747872.7180000000000000',
      quoteVolume: 126569474.2263926,
      btcVolume: 4663.94819780864,
      usdVolume: 126569474.2263926,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '33.6741000000000000',
      high: '36.1500000000000000',
      low: '33.3105000000000000',
      close: '35.9510000000000000',
      volume: '4121783.1030000000000000',
      quoteVolume: 145163638.0339237,
      btcVolume: 5381.500496375316,
      usdVolume: 145163638.0339237,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '35.9507000000000000',
      high: '39.9999000000000000',
      low: '35.3015000000000000',
      close: '39.0667000000000000',
      volume: '7022548.4870000000000000',
      quoteVolume: 266651443.80075303,
      btcVolume: 10020.386760643316,
      usdVolume: 266651443.80075303,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '39.0746000000000000',
      high: '39.2700000000000000',
      low: '36.8131000000000000',
      close: '38.1558000000000000',
      volume: '2424623.4670000000000000',
      quoteVolume: 91497596.04163969,
      btcVolume: 3260.3633178562527,
      usdVolume: 91497596.04163969,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '38.1558000000000000',
      high: '38.2133000000000000',
      low: '36.5157000000000000',
      close: '37.3588000000000000',
      volume: '1887715.8350000000000000',
      quoteVolume: 70486125.96259664,
      btcVolume: 2446.931071296564,
      usdVolume: 70486125.96259664,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '37.3596000000000000',
      high: '38.9590000000000000',
      low: '36.9555000000000000',
      close: '37.7762000000000000',
      volume: '2433482.2970000000000000',
      quoteVolume: 92264806.14913891,
      btcVolume: 3158.8112751452077,
      usdVolume: 92264806.14913891,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '37.7637000000000000',
      high: '38.8798000000000000',
      low: '36.8000000000000000',
      close: '38.2331000000000000',
      volume: '3018062.2060000000000000',
      quoteVolume: 114060050.8496273,
      btcVolume: 3673.549570252766,
      usdVolume: 114060050.8496273,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '38.2331000000000000',
      high: '41.7017000000000000',
      low: '37.7630000000000000',
      close: '41.2575000000000000',
      volume: '4903626.6970000000000000',
      quoteVolume: 195224916.82053122,
      btcVolume: 5801.770817073169,
      usdVolume: 195224916.82053122,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '41.2560000000000000',
      high: '43.2039000000000000',
      low: '38.0200000000000000',
      close: '41.1333000000000000',
      volume: '5306248.1660000000000000',
      quoteVolume: 214835112.0872529,
      btcVolume: 6762.2209205324225,
      usdVolume: 214835112.0872529,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '41.1300000000000000',
      high: '41.8375000000000000',
      low: '38.8014000000000000',
      close: '41.8219000000000000',
      volume: '3513013.9910000000000000',
      quoteVolume: 141826591.3171311,
      btcVolume: 4386.074240907546,
      usdVolume: 141826591.3171311,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '41.8271000000000000',
      high: '42.2670000000000000',
      low: '40.4531000000000000',
      close: '42.2417000000000000',
      volume: '3784356.4310000000000000',
      quoteVolume: 156400986.59603542,
      btcVolume: 4502.298574139992,
      usdVolume: 156400986.59603542,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '42.2440000000000000',
      high: '44.9000000000000000',
      low: '41.3660000000000000',
      close: '43.5728000000000000',
      volume: '5010228.3040000000000000',
      quoteVolume: 216002626.83736652,
      btcVolume: 5709.586809164074,
      usdVolume: 216002626.83736652,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '43.5728000000000000',
      high: '43.7220000000000000',
      low: '40.2313000000000000',
      close: '42.3560000000000000',
      volume: '3545616.4530000000000000',
      quoteVolume: 149813003.67615464,
      btcVolume: 3783.9907502632377,
      usdVolume: 149813003.67615464,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '42.3450000000000000',
      high: '44.0552000000000000',
      low: '41.5000000000000000',
      close: '43.8479000000000000',
      volume: '2720363.6360000000000000',
      quoteVolume: 116290473.53861824,
      btcVolume: 2885.4307702783954,
      usdVolume: 116290473.53861824,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '43.8479000000000000',
      high: '45.1620000000000000',
      low: '40.0000000000000000',
      close: '42.4031000000000000',
      volume: '4276146.4430000000000000',
      quoteVolume: 185111910.32453433,
      btcVolume: 4685.988559488082,
      usdVolume: 185111910.32453433,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '42.4033000000000000',
      high: '42.5094000000000000',
      low: '35.0374000000000000',
      close: '38.1674000000000000',
      volume: '6282218.7140000000000000',
      quoteVolume: 241222935.06371692,
      btcVolume: 7090.358330981841,
      usdVolume: 241222935.06371692,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '38.1623000000000000',
      high: '40.1989000000000000',
      low: '37.0000000000000000',
      close: '38.2541000000000000',
      volume: '2967129.8990000000000000',
      quoteVolume: 114541059.98171715,
      btcVolume: 3279.5902550381034,
      usdVolume: 114541059.98171715,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '38.2703000000000000',
      high: '40.4499000000000000',
      low: '37.0200000000000000',
      close: '40.1114000000000000',
      volume: '3456796.7670000000000000',
      quoteVolume: 133704300.47653846,
      btcVolume: 3841.1434871509555,
      usdVolume: 133704300.47653846,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '40.1114000000000000',
      high: '42.0000000000000000',
      low: '39.5739000000000000',
      close: '41.8723000000000000',
      volume: '3306689.9330000000000000',
      quoteVolume: 135085613.30355036,
      btcVolume: 3524.8729501905395,
      usdVolume: 135085613.30355036,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '41.8721000000000000',
      high: '42.5622000000000000',
      low: '38.6616000000000000',
      close: '40.8668000000000000',
      volume: '3239226.4660000000000000',
      quoteVolume: 132807501.32071899,
      btcVolume: 3551.40548928815,
      usdVolume: 132807501.32071899,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '40.8951000000000000',
      high: '44.1200000000000000',
      low: '40.6250000000000000',
      close: '43.1420000000000000',
      volume: '3033653.3580000000000000',
      quoteVolume: 129106934.07833531,
      btcVolume: 3495.5013833004346,
      usdVolume: 129106934.07833531,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '43.1413000000000000',
      high: '46.7228000000000000',
      low: '40.9192000000000000',
      close: '45.8675000000000000',
      volume: '3878914.2990000000000000',
      quoteVolume: 169754085.7227777,
      btcVolume: 4749.843457538563,
      usdVolume: 169754085.7227777,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '45.8645000000000000',
      high: '46.8888000000000000',
      low: '44.0278000000000000',
      close: '45.3412000000000000',
      volume: '3069027.8490000000000000',
      quoteVolume: 139380249.91174093,
      btcVolume: 3868.6820587698926,
      usdVolume: 139380249.91174093,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '45.3592000000000000',
      high: '47.2187000000000000',
      low: '41.7000000000000000',
      close: '42.5241000000000000',
      volume: '5142469.4010000000000000',
      quoteVolume: 229041236.3572534,
      btcVolume: 6215.5026986066505,
      usdVolume: 229041236.3572534,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '42.5241000000000000',
      high: '43.0992000000000000',
      low: '40.2000000000000000',
      close: '42.6069000000000000',
      volume: '3707024.5780000000000000',
      quoteVolume: 154379904.5654336,
      btcVolume: 4404.916420772621,
      usdVolume: 154379904.5654336,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '42.6056000000000000',
      high: '42.7176000000000000',
      low: '38.2000000000000000',
      close: '38.6343000000000000',
      volume: '3460575.2420000000000000',
      quoteVolume: 140161330.8816147,
      btcVolume: 4278.077907472615,
      usdVolume: 140161330.8816147,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '38.6596000000000000',
      high: '41.5280000000000000',
      low: '36.8518000000000000',
      close: '40.8936000000000000',
      volume: '3045177.7070000000000000',
      quoteVolume: 120441457.89852692,
      btcVolume: 3837.1422348553915,
      usdVolume: 120441457.89852692,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '40.8938000000000000',
      high: '41.8800000000000000',
      low: '39.4555000000000000',
      close: '40.9109000000000000',
      volume: '2278214.3590000000000000',
      quoteVolume: 92739441.75976337,
      btcVolume: 2865.448972787932,
      usdVolume: 92739441.75976337,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '40.9113000000000000',
      high: '42.2000000000000000',
      low: '40.3144000000000000',
      close: '41.8263000000000000',
      volume: '2340747.6230000000000000',
      quoteVolume: 97017133.59785114,
      btcVolume: 3005.7392112630923,
      usdVolume: 97017133.59785114,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '41.8270000000000000',
      high: '43.4241000000000000',
      low: '40.5364000000000000',
      close: '41.1687000000000000',
      volume: '2518182.3520000000000000',
      quoteVolume: 106660129.78203705,
      btcVolume: 3193.7435879782656,
      usdVolume: 106660129.78203705,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '40.6902000000000000',
      high: '41.9990000000000000',
      low: '40.6495000000000000',
      close: '41.8396000000000000',
      volume: '569370.8780000000000000',
      quoteVolume: 23434513.457918085,
      btcVolume: 727.6668268505232,
      usdVolume: 23434513.457918085,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '41.8397000000000000',
      high: '42.0984000000000000',
      low: '39.6102000000000000',
      close: '40.8826000000000000',
      volume: '2436860.8560000000000000',
      quoteVolume: 99920228.95624547,
      btcVolume: 3215.2310783315247,
      usdVolume: 99920228.95624547,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '40.8957000000000000',
      high: '43.0850000000000000',
      low: '40.3170000000000000',
      close: '42.5254000000000000',
      volume: '2696452.7010000000000000',
      quoteVolume: 113671095.32156354,
      btcVolume: 3581.5448326085157,
      usdVolume: 113671095.32156354,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '42.5277000000000000',
      high: '44.0000000000000000',
      low: '41.8017000000000000',
      close: '42.8618000000000000',
      volume: '3128425.9590000000000000',
      quoteVolume: 134263033.31381023,
      btcVolume: 3835.6490017488704,
      usdVolume: 134263033.31381023,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '42.8653000000000000',
      high: '45.1071000000000000',
      low: '42.5911000000000000',
      close: '44.7849000000000000',
      volume: '2684982.5910000000000000',
      quoteVolume: 118195712.16575812,
      btcVolume: 3475.2585019044272,
      usdVolume: 118195712.16575812,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '44.8022000000000000',
      high: '45.8269000000000000',
      low: '43.3000000000000000',
      close: '44.3292000000000000',
      volume: '2275614.1730000000000000',
      quoteVolume: 101023763.14275552,
      btcVolume: 3017.434459535125,
      usdVolume: 101023763.14275552,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '44.3291000000000000',
      high: '52.3736000000000000',
      low: '43.4331000000000000',
      close: '51.4781000000000000',
      volume: '6050100.3010000000000000',
      quoteVolume: 292440579.7585255,
      btcVolume: 8664.193157549942,
      usdVolume: 292440579.7585255,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '51.4762000000000000',
      high: '53.0000000000000000',
      low: '49.1572000000000000',
      close: '51.0071000000000000',
      volume: '4671921.8650000000000000',
      quoteVolume: 237462302.3806651,
      btcVolume: 6853.326195790986,
      usdVolume: 237462302.3806651,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '51.0290000000000000',
      high: '52.7000000000000000',
      low: '49.7823000000000000',
      close: '52.1200000000000000',
      volume: '2949735.0360000000000000',
      quoteVolume: 150776117.80316824,
      btcVolume: 4132.7211784957935,
      usdVolume: 150776117.80316824,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '52.1200000000000000',
      high: '57.0547000000000000',
      low: '51.2500000000000000',
      close: '55.9599000000000000',
      volume: '4004728.2130000000000000',
      quoteVolume: 215001574.4533509,
      btcVolume: 5736.324770655654,
      usdVolume: 215001574.4533509,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '55.9593000000000000',
      high: '68.7300000000000000',
      low: '55.9202000000000000',
      close: '67.7005000000000000',
      volume: '5033214.7830000000000000',
      quoteVolume: 306083346.7708751,
      btcVolume: 8127.166722306979,
      usdVolume: 306083346.7708751,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '67.6217000000000000',
      high: '75.5000000000000000',
      low: '61.7000000000000000',
      close: '72.8356000000000000',
      volume: '8481923.5090000000000000',
      quoteVolume: 595939416.8906882,
      btcVolume: 15020.336224524533,
      usdVolume: 595939416.8906882,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '72.8693000000000000',
      high: '74.8199000000000000',
      low: '64.1803000000000000',
      close: '68.3542000000000000',
      volume: '5969908.4420000000000000',
      quoteVolume: 413359634.3580266,
      btcVolume: 10704.071358881154,
      usdVolume: 413359634.3580266,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '68.3550000000000000',
      high: '79.8823000000000000',
      low: '67.6152000000000000',
      close: '79.8374000000000000',
      volume: '7849448.2670000000000000',
      quoteVolume: 573011026.0739279,
      btcVolume: 14013.22524313177,
      usdVolume: 573011026.0739279,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '79.8734000000000000',
      high: '116.2400000000000000',
      low: '78.0381000000000000',
      close: '107.3469000000000000',
      volume: '12487305.8680000000000000',
      quoteVolume: 1222317019.460528,
      btcVolume: 26192.431070371436,
      usdVolume: 1222317019.460528,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '107.3496000000000000',
      high: '148.4000000000000000',
      low: '107.2811000000000000',
      close: '128.9711000000000000',
      volume: '16613534.2740000000000000',
      quoteVolume: 2139218096.5095875,
      btcVolume: 46624.18677740852,
      usdVolume: 2138674058.9751093,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '128.9463000000000000',
      high: '132.8000000000000000',
      low: '117.7289000000000000',
      close: '124.0850000000000000',
      volume: '5912684.9520000000000000',
      quoteVolume: 736389166.0586556,
      btcVolume: 15887.557250176676,
      usdVolume: 736389166.0586556,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '124.1129000000000000',
      high: '141.3200000000000000',
      low: '120.8900000000000000',
      close: '136.6139000000000000',
      volume: '5624105.4490000000000000',
      quoteVolume: 739818687.5567763,
      btcVolume: 15573.408733004304,
      usdVolume: 739734387.6189897,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '136.7321000000000000',
      high: '138.5067000000000000',
      low: '125.0000000000000000',
      close: '133.3496000000000000',
      volume: '3785723.4020000000000000',
      quoteVolume: 497454132.3754859,
      btcVolume: 10550.521716952037,
      usdVolume: 497442916.39831746,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '133.3498000000000000',
      high: '139.0000000000000000',
      low: '128.4615000000000000',
      close: '135.8623000000000000',
      volume: '4149634.1350000000000000',
      quoteVolume: 553817439.5471048,
      btcVolume: 11381.352118436944,
      usdVolume: 553817439.5471048,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '135.8623000000000000',
      high: '139.3690000000000000',
      low: '118.0000000000000000',
      close: '130.3030000000000000',
      volume: '4726200.1690000000000000',
      quoteVolume: 610939861.0625807,
      btcVolume: 12798.537422520802,
      usdVolume: 608891488.1794219,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '129.9493000000000000',
      high: '133.4925000000000000',
      low: '126.0000000000000000',
      close: '130.1830000000000000',
      volume: '3253115.9180000000000000',
      quoteVolume: 422971528.8945765,
      btcVolume: 8473.804158435485,
      usdVolume: 413215114.4048722,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '130.1830000000000000',
      high: '175.9997000000000000',
      low: '126.7546000000000000',
      close: '165.3233000000000000',
      volume: '10095433.2290000000000000',
      quoteVolume: 1529265397.2071304,
      btcVolume: 29733.66977060965,
      usdVolume: 1529265397.2071304,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '165.3176000000000000',
      high: '199.8800000000000000',
      low: '162.7969000000000000',
      close: '195.6000000000000000',
      volume: '11226893.3730000000000000',
      quoteVolume: 2093727364.8791077,
      btcVolume: 40384.559838438676,
      usdVolume: 2093727364.8791077,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '195.5549000000000000',
      high: '348.6969000000000000',
      low: '194.9279000000000000',
      close: '333.1110000000000000',
      volume: '20177086.7240000000000000',
      quoteVolume: 5524988273.532031,
      btcVolume: 104550.88500921716,
      usdVolume: 5524988273.532031,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '333.2212000000000000',
      high: '335.0000000000000000',
      low: '223.0000000000000000',
      close: '253.0000000000000000',
      volume: '13493080.4310000000000000',
      quoteVolume: 3802302297.1180034,
      btcVolume: 67696.97645332622,
      usdVolume: 3802255523.1218314,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '253.0100000000000000',
      high: '309.4995000000000000',
      low: '249.7044000000000000',
      close: '295.2597000000000000',
      volume: '10646422.3070000000000000',
      quoteVolume: 3016515097.588496,
      btcVolume: 53004.9096656135,
      usdVolume: 3016515097.588496,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '294.9167000000000000',
      high: '296.0000000000000000',
      low: '220.5000000000000000',
      close: '267.5147000000000000',
      volume: '11090199.9140000000000000',
      quoteVolume: 2901115898.821675,
      btcVolume: 51481.76655921517,
      usdVolume: 2776214823.2922387,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '267.1721000000000000',
      high: '268.8000000000000000',
      low: '188.8195000000000000',
      close: '231.3646000000000000',
      volume: '14894899.8590000000000000',
      quoteVolume: 3323972236.903524,
      btcVolume: 67971.97939281193,
      usdVolume: 3306278943.257356,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '231.3646000000000000',
      high: '281.0000000000000000',
      low: '213.0257000000000000',
      close: '254.5056000000000000',
      volume: '9115851.7520000000000000',
      quoteVolume: 2299513536.8925033,
      btcVolume: 45958.25770110001,
      usdVolume: 2299401919.088233,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '254.3826000000000000',
      high: '265.4014000000000000',
      low: '231.5000000000000000',
      close: '234.9146000000000000',
      volume: '6871992.7940000000000000',
      quoteVolume: 1718504824.021201,
      btcVolume: 34352.22898526557,
      usdVolume: 1718504824.021201,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '234.7605000000000000',
      high: '239.4000000000000000',
      low: '209.1050000000000000',
      close: '221.7170000000000000',
      volume: '7410706.3850000000000000',
      quoteVolume: 1673740236.886314,
      btcVolume: 35915.41505900414,
      usdVolume: 1672629199.376214,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '221.9592000000000000',
      high: '237.7700000000000000',
      low: '219.0110000000000000',
      close: '225.3853000000000000',
      volume: '4192697.1520000000000000',
      quoteVolume: 956073854.7960905,
      btcVolume: 20310.25188922906,
      usdVolume: 956073854.7960905,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '225.4567000000000000',
      high: '229.4000000000000000',
      low: '195.0000000000000000',
      close: '210.2138000000000000',
      volume: '5582387.4930000000000000',
      quoteVolume: 1169488220.755494,
      btcVolume: 26195.665345367866,
      usdVolume: 1169488220.755494,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '210.1177000000000000',
      high: '260.0000000000000000',
      low: '209.1204000000000000',
      close: '254.9637000000000000',
      volume: '7696473.1020000000000000',
      quoteVolume: 1861423426.5445766,
      btcVolume: 38996.01528997843,
      usdVolume: 1861423426.5445766,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '254.9486000000000000',
      high: '264.9000000000000000',
      low: '227.0000000000000000',
      close: '239.6841000000000000',
      volume: '5386013.7940000000000000',
      quoteVolume: 1322863990.4305365,
      btcVolume: 27229.610061705047,
      usdVolume: 1322863990.4305365,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '239.7204000000000000',
      high: '254.8481000000000000',
      low: '236.0020000000000000',
      close: '240.5701000000000000',
      volume: '4213922.8000000000000000',
      quoteVolume: 1038796903.290087,
      btcVolume: 20561.198898104394,
      usdVolume: 1038796903.290087,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '240.4801000000000000',
      high: '249.9885000000000000',
      low: '225.5308000000000000',
      close: '229.6369000000000000',
      volume: '3999983.8360000000000000',
      quoteVolume: 951344257.2707027,
      btcVolume: 19254.739130732472,
      usdVolume: 950963632.5327258,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '229.6369000000000000',
      high: '232.2000000000000000',
      low: '215.6439000000000000',
      close: '225.4714000000000000',
      volume: '3654842.8050000000000000',
      quoteVolume: 816218663.6047677,
      btcVolume: 17130.43983808357,
      usdVolume: 816218663.6047677,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '225.5391000000000000',
      high: '232.4996000000000000',
      low: '219.1514000000000000',
      close: '226.3814000000000000',
      volume: '2540451.2780000000000000',
      quoteVolume: 572783201.0439407,
      btcVolume: 11728.787027795814,
      usdVolume: 566811264.0986007,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '226.3812000000000000',
      high: '243.9500000000000000',
      low: '224.9542000000000000',
      close: '240.1100000000000000',
      volume: '3519962.9290000000000000',
      quoteVolume: 825683976.9668484,
      btcVolume: 16392.968709891094,
      usdVolume: 825683976.9668484,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '240.1039000000000000',
      high: '243.0986000000000000',
      low: '228.7029000000000000',
      close: '240.5509000000000000',
      volume: '2974749.0600000000000000',
      quoteVolume: 700594381.5475962,
      btcVolume: 13790.190438705486,
      usdVolume: 700594381.5475962,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '240.7852000000000000',
      high: '297.2962000000000000',
      low: '235.6001000000000000',
      close: '293.2052000000000000',
      volume: '7349183.8380000000000000',
      quoteVolume: 1967801660.5323133,
      btcVolume: 36388.01138608123,
      usdVolume: 1967801660.5323133,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '293.1305000000000000',
      high: '295.5000000000000000',
      low: '263.8184000000000000',
      close: '278.4499000000000000',
      volume: '5653640.2100000000000000',
      quoteVolume: 1590670836.0511584,
      btcVolume: 28815.964844856844,
      usdVolume: 1590533270.0117154,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '278.3522000000000000',
      high: '315.2855000000000000',
      low: '272.1200000000000000',
      close: '290.0301000000000000',
      volume: '6597122.9060000000000000',
      quoteVolume: 1920751069.112857,
      btcVolume: 34050.75363516682,
      usdVolume: 1920751069.112857,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '289.7860000000000000',
      high: '290.5000000000000000',
      low: '247.5547000000000000',
      close: '264.2515000000000000',
      volume: '5733513.3280000000000000',
      quoteVolume: 1549981975.0642865,
      btcVolume: 18321.480693181016,
      usdVolume: 1039794507.6320997,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '260.1943000000000000',
      high: '284.2857000000000000',
      low: '255.4206000000000000',
      close: '276.2822000000000000',
      volume: '3870240.0390000000000000',
      quoteVolume: 1055956835.0145825,
      btcVolume: 17918.280534933638,
      usdVolume: 1055956835.0145825,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '276.2892000000000000',
      high: '276.8847000000000000',
      low: '263.0003000000000000',
      close: '263.2380000000000000',
      volume: '2426314.6890000000000000',
      quoteVolume: 655148936.6244737,
      btcVolume: 10820.477528826821,
      usdVolume: 654442187.9202651,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '263.3500000000000000',
      high: '267.7453000000000000',
      low: '249.5327000000000000',
      close: '254.5144000000000000',
      volume: '3362729.4940000000000000',
      quoteVolume: 865777537.7956617,
      btcVolume: 15099.63548641119,
      usdVolume: 865777537.7956617,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '254.3894000000000000',
      high: '259.7847000000000000',
      low: '240.1990000000000000',
      close: '258.5352000000000000',
      volume: '2873578.7750000000000000',
      quoteVolume: 724203640.2983155,
      btcVolume: 13111.127043243541,
      usdVolume: 723730256.9268228,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '258.5611000000000000',
      high: '272.1787000000000000',
      low: '248.2001000000000000',
      close: '269.7896000000000000',
      volume: '2840597.4750000000000000',
      quoteVolume: 730345547.8462642,
      btcVolume: 12972.881070043122,
      usdVolume: 730345547.8462642,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '269.8578000000000000',
      high: '277.8000000000000000',
      low: '257.0100000000000000',
      close: '261.4816000000000000',
      volume: '3151815.5090000000000000',
      quoteVolume: 849421790.8761196,
      btcVolume: 14510.926635933718,
      usdVolume: 849416699.5951898,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '261.5157000000000000',
      high: '269.0000000000000000',
      low: '255.0000000000000000',
      close: '262.9350000000000000',
      volume: '2162339.2940000000000000',
      quoteVolume: 571656223.3764387,
      btcVolume: 9795.650695015467,
      usdVolume: 570353586.1848109,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '263.1083000000000000',
      high: '274.6805000000000000',
      low: '261.4000000000000000',
      close: '264.3023000000000000',
      volume: '2167591.3950000000000000',
      quoteVolume: 581172465.4111629,
      btcVolume: 9881.756043643418,
      usdVolume: 581150401.0430868,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '264.2213000000000000',
      high: '268.0305000000000000',
      low: '257.0001000000000000',
      close: '264.6513000000000000',
      volume: '1969870.0700000000000000',
      quoteVolume: 519208676.9906088,
      btcVolume: 9066.830743937264,
      usdVolume: 518831620.6721869,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '264.7152000000000000',
      high: '277.0000000000000000',
      low: '252.9164000000000000',
      close: '254.7976000000000000',
      volume: '3264577.5660000000000000',
      quoteVolume: 867106025.9929574,
      btcVolume: 15341.116908069982,
      usdVolume: 866971561.9590058,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '254.9689000000000000',
      high: '262.9700000000000000',
      low: '251.8380000000000000',
      close: '255.4646000000000000',
      volume: '2572459.8170000000000000',
      quoteVolume: 663206251.7188904,
      btcVolume: 8225.60529805851,
      usdVolume: 447969721.01421326,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '255.5191000000000000',
      high: '268.7062000000000000',
      low: '240.3333000000000000',
      close: '248.5236000000000000',
      volume: '3077671.5470000000000000',
      quoteVolume: 793614390.5674702,
      btcVolume: 14422.352218746142,
      usdVolume: 793614390.5674702,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '248.5345000000000000',
      high: '250.0912000000000000',
      low: '224.9686000000000000',
      close: '233.9466000000000000',
      volume: '3206750.4610000000000000',
      quoteVolume: 761130676.4045202,
      btcVolume: 13047.060000011228,
      usdVolume: 676406281.7791395,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '234.0024000000000000',
      high: '255.2113000000000000',
      low: '233.8114000000000000',
      close: '255.2105000000000000',
      volume: '2245802.5640000000000000',
      quoteVolume: 556008625.4021944,
      btcVolume: 10101.506782457785,
      usdVolume: 538894875.840913,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '255.1201000000000000',
      high: '276.0000000000000000',
      low: '249.5001000000000000',
      close: '269.2234000000000000',
      volume: '3089181.8480000000000000',
      quoteVolume: 804732179.3560896,
      btcVolume: 14561.461272689408,
      usdVolume: 804579787.2442447,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '269.2864000000000000',
      high: '281.3939000000000000',
      low: '261.0000000000000000',
      close: '268.4263000000000000',
      volume: '3272613.7550000000000000',
      quoteVolume: 888837689.7422438,
      btcVolume: 15905.983283637139,
      usdVolume: 888769684.6338859,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '268.6234000000000000',
      high: '277.9813000000000000',
      low: '264.4585000000000000',
      close: '275.2991000000000000',
      volume: '2718538.8020000000000000',
      quoteVolume: 741093981.6902637,
      btcVolume: 13018.42468453984,
      usdVolume: 741031453.2366232,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '275.2986000000000000',
      high: '311.5325000000000000',
      low: '271.2857000000000000',
      close: '311.2962000000000000',
      volume: '5532768.0410000000000000',
      quoteVolume: 1633393475.0102596,
      btcVolume: 27920.656657466523,
      usdVolume: 1633357160.421257,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '311.2962000000000000',
      high: '316.6997000000000000',
      low: '284.0000000000000000',
      close: '302.3724000000000000',
      volume: '4719335.5060000000000000',
      quoteVolume: 1432519744.2660277,
      btcVolume: 24381.244738004352,
      usdVolume: 1428700884.4968865,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '302.4591000000000000',
      high: '339.0000000000000000',
      low: '300.7000000000000000',
      close: '334.7991000000000000',
      volume: '4332111.9370000000000000',
      quoteVolume: 1384341137.684506,
      btcVolume: 23482.89919447801,
      usdVolume: 1382271214.156847,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '334.6706000000000000',
      high: '356.9882000000000000',
      low: '324.6162000000000000',
      close: '338.2932000000000000',
      volume: '4071170.6560000000000000',
      quoteVolume: 1403027066.2804952,
      btcVolume: 23692.93398872393,
      usdVolume: 1402782243.379352,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '338.2932000000000000',
      high: '349.1347000000000000',
      low: '315.9100000000000000',
      close: '321.9497000000000000',
      volume: '3098374.7850000000000000',
      quoteVolume: 1044133678.7217119,
      btcVolume: 10038.83808275111,
      usdVolume: 591404806.9357758,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '321.9597000000000000',
      high: '352.3275000000000000',
      low: '316.0000000000000000',
      close: '348.8617000000000000',
      volume: '2648776.3950000000000000',
      quoteVolume: 902098257.0088232,
      btcVolume: 13702.133872871518,
      usdVolume: 791916397.8945813,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '348.8705000000000000',
      high: '380.0000000000000000',
      low: '335.8442000000000000',
      close: '368.0263000000000000',
      volume: '3431868.4070000000000000',
      quoteVolume: 1236127810.4175525,
      btcVolume: 17901.65290795174,
      usdVolume: 1037725357.78694,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '368.1506000000000000',
      high: '411.7550000000000000',
      low: '363.8333000000000000',
      close: '403.3648000000000000',
      volume: '4239882.7000000000000000',
      quoteVolume: 1648279064.5724006,
      btcVolume: 26531.19803700832,
      usdVolume: 1548692540.941828,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '403.2607000000000000',
      high: '414.3803000000000000',
      low: '356.0000000000000000',
      close: '375.5538000000000000',
      volume: '6153246.9340000000000000',
      quoteVolume: 2372758767.1935678,
      btcVolume: 40716.89375916033,
      usdVolume: 2320206435.050474,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '375.5538000000000000',
      high: '422.6323000000000000',
      low: '371.7800000000000000',
      close: '418.7048000000000000',
      volume: '4010180.6390000000000000',
      quoteVolume: 1625423876.1601589,
      btcVolume: 18000.290159044805,
      usdVolume: 1028345774.5132892,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '418.7047000000000000',
      high: '453.9937000000000000',
      low: '403.1900000000000000',
      close: '453.9937000000000000',
      volume: '3392014.2090000000000000',
      quoteVolume: 1464479256.1346753,
      btcVolume: 22466.754948146387,
      usdVolume: 1309044489.8936975,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '453.9898000000000000',
      high: '487.4062000000000000',
      low: '450.0000000000000000',
      close: '471.2975000000000000',
      volume: '4659107.2120000000000000',
      quoteVolume: 2200226600.979961,
      btcVolume: 36213.41070222386,
      usdVolume: 2165238340.1851015,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '471.2974000000000000',
      high: '529.2110000000000000',
      low: '458.0165000000000000',
      close: '525.9275000000000000',
      volume: '3644396.1100000000000000',
      quoteVolume: 1776698304.8045623,
      btcVolume: 29506.82991366972,
      usdVolume: 1764632035.1456206,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '525.9275000000000000',
      high: '638.5673000000000000',
      low: '515.1000000000000000',
      close: '596.7104000000000000',
      volume: '9111902.9720000000000000',
      quoteVolume: 5245816431.400061,
      btcVolume: 63257.127669028734,
      usdVolume: 3806149366.686823,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '596.7878000000000000',
      high: '602.7871000000000000',
      low: '530.2288000000000000',
      close: '551.6442000000000000',
      volume: '5211856.8490000000000000',
      quoteVolume: 2928039699.6231527,
      btcVolume: 46855.84817376075,
      usdVolume: 2904056644.2781954,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '551.3956000000000000',
      high: '590.5369000000000000',
      low: '521.1313000000000000',
      close: '550.0182000000000000',
      volume: '4141516.3380000000000000',
      quoteVolume: 2316332649.32106,
      btcVolume: 32494.32817460017,
      usdVolume: 2063377293.09785,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '550.0182000000000000',
      high: '556.9000000000000000',
      low: '533.7166000000000000',
      close: '541.8824000000000000',
      volume: '2306847.3860000000000000',
      quoteVolume: 1261788213.6561053,
      btcVolume: 18450.056652820283,
      usdVolume: 1159651151.0654097,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '541.8351000000000000',
      high: '547.6074000000000000',
      low: '489.7356000000000000',
      close: '508.2743000000000000',
      volume: '4192215.1750000000000000',
      quoteVolume: 2179385039.089873,
      btcVolume: 33322.010808070954,
      usdVolume: 2049560218.6198566,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '508.2614000000000000',
      high: '547.9500000000000000',
      low: '506.0000000000000000',
      close: '514.6861000000000000',
      volume: '2949039.8180000000000000',
      quoteVolume: 1548814241.88043,
      btcVolume: 16718.141931258793,
      usdVolume: 1024252858.6623896,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '514.8178000000000000',
      high: '520.4237000000000000',
      low: '428.0000000000000000',
      close: '481.4367000000000000',
      volume: '4049186.4060000000000000',
      quoteVolume: 1904645753.8034728,
      btcVolume: 33628.949170106855,
      usdVolume: 1876226556.6125178,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '481.5112000000000000',
      high: '540.0000000000000000',
      low: '467.8846000000000000',
      close: '504.0322000000000000',
      volume: '5031304.0290000000000000',
      quoteVolume: 2572847032.948682,
      btcVolume: 37508.47886493883,
      usdVolume: 2114776432.2070198,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '504.0322000000000000',
      high: '589.5000000000000000',
      low: '470.2070000000000000',
      close: '586.3635000000000000',
      volume: '5715927.6350000000000000',
      quoteVolume: 3058354760.9721446,
      btcVolume: 53121.18738860772,
      usdVolume: 2963040031.251067,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '586.5201000000000000',
      high: '613.8975000000000000',
      low: '535.9186000000000000',
      close: '543.6327000000000000',
      volume: '5415834.4300000000000000',
      quoteVolume: 3148647997.53806,
      btcVolume: 49070.86389573589,
      usdVolume: 2723704942.1352196,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '543.6827000000000000',
      high: '582.7051000000000000',
      low: '480.0000000000000000',
      close: '505.2411000000000000',
      volume: '5711241.6150000000000000',
      quoteVolume: 3087134504.9510384,
      btcVolume: 16336.707809710622,
      usdVolume: 865615640.8242787,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '505.2282000000000000',
      high: '530.0000000000000000',
      low: '453.1000000000000000',
      close: '526.5357000000000000',
      volume: '6415439.4970000000000000',
      quoteVolume: 3176588289.098144,
      btcVolume: 33856.082810714746,
      usdVolume: 1686441255.1318297,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '526.4319000000000000',
      high: '528.8088000000000000',
      low: '482.3052000000000000',
      close: '496.9920000000000000',
      volume: '2969978.4340000000000000',
      quoteVolume: 1502798926.917138,
      btcVolume: 28745.678183221087,
      usdVolume: 1432476977.467932,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '496.9920000000000000',
      high: '517.7400000000000000',
      low: '478.0100000000000000',
      close: '505.0000000000000000',
      volume: '2352878.9486000000000000',
      quoteVolume: 1177965823.3087528,
      btcVolume: 12577.589728470612,
      usdVolume: 623023504.0575972,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '505.0000000000000000',
      high: '550.0000000000000000',
      low: '501.5000000000000000',
      close: '535.3300000000000000',
      volume: '2655262.1521000000000000',
      quoteVolume: 1411293600.6102023,
      btcVolume: 15480.36818125127,
      usdVolume: 815757282.7136099,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '535.3300000000000000',
      high: '579.5000000000000000',
      low: '525.0000000000000000',
      close: '568.7800000000000000',
      volume: '2807308.6198000000000000',
      quoteVolume: 1565140057.7051094,
      btcVolume: 28300.124067514644,
      usdVolume: 1546267212.3260415,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '568.7800000000000000',
      high: '578.8800000000000000',
      low: '538.0000000000000000',
      close: '562.7800000000000000',
      volume: '2384511.0120000000000000',
      quoteVolume: 1333085756.448968,
      btcVolume: 24357.758243790056,
      usdVolume: 1333085756.448968,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '562.7800000000000000',
      high: '615.2900000000000000',
      low: '554.0000000000000000',
      close: '599.7000000000000000',
      volume: '4242963.4049000000000000',
      quoteVolume: 2481862298.554591,
      btcVolume: 46006.51699559538,
      usdVolume: 2481862298.554591,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '599.6600000000000000',
      high: '628.0000000000000000',
      low: '585.7200000000000000',
      close: '622.6500000000000000',
      volume: '3379163.2196000000000000',
      quoteVolume: 2063664584.472258,
      btcVolume: 25172.717745086713,
      usdVolume: 1386220097.2369685,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '622.6400000000000000',
      high: '645.5700000000000000',
      low: '609.2100000000000000',
      close: '620.0000000000000000',
      volume: '2427568.3909000000000000',
      quoteVolume: 1516905856.244385,
      btcVolume: 12446.095949705987,
      usdVolume: 719848206.3838052,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '620.0000000000000000',
      high: '629.0000000000000000',
      low: '595.3000000000000000',
      close: '621.7800000000000000',
      volume: '2066188.5104000000000000',
      quoteVolume: 1273393608.5696151,
      btcVolume: 14374.383604305296,
      usdVolume: 815986629.5360534,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '621.7700000000000000',
      high: '680.0000000000000000',
      low: '621.2200000000000000',
      close: '676.1500000000000000',
      volume: '2873379.6170000000000000',
      quoteVolume: 1872406454.7114303,
      btcVolume: 31523.29798707986,
      usdVolume: 1827102859.2419474,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '676.1500000000000000',
      high: '677.7100000000000000',
      low: '604.1400000000000000',
      close: '609.3000000000000000',
      volume: '3760446.5392000000000000',
      quoteVolume: 2397311746.8970165,
      btcVolume: 34681.93696052413,
      usdVolume: 1922027889.6555834,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '609.4100000000000000',
      high: '656.6700000000000000',
      low: '605.0000000000000000',
      close: '651.1000000000000000',
      volume: '2108343.7681000000000000',
      quoteVolume: 1350489029.914887,
      btcVolume: 22376.029864647935,
      usdVolume: 1245585308.8986058,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '651.1000000000000000',
      high: '653.7700000000000000',
      low: '620.0000000000000000',
      close: '633.9900000000000000',
      volume: '2027391.4345000000000000',
      quoteVolume: 1294526643.8860967,
      btcVolume: 22104.33571757016,
      usdVolume: 1257147022.1981807,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '634.0300000000000000',
      high: '644.0200000000000000',
      low: '611.4300000000000000',
      close: '624.3000000000000000',
      volume: '1979433.7807000000000000',
      quoteVolume: 1238888054.2225192,
      btcVolume: 21423.691802310528,
      usdVolume: 1214310669.4553754,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '624.3000000000000000',
      high: '657.9100000000000000',
      low: '618.3600000000000000',
      close: '646.1000000000000000',
      volume: '2185770.9717000000000000',
      quoteVolume: 1395759313.3025906,
      btcVolume: 23506.98439331287,
      usdVolume: 1375162536.6888602,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '646.0700000000000000',
      high: '679.0000000000000000',
      low: '630.5400000000000000',
      close: '661.7400000000000000',
      volume: '2343400.2966000000000000',
      quoteVolume: 1543793211.4605246,
      btcVolume: 25883.63462490665,
      usdVolume: 1497580524.9378126,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '661.7400000000000000',
      high: '691.7700000000000000',
      low: '588.0000000000000000',
      close: '631.1000000000000000',
      volume: '3000221.4265000000000000',
      quoteVolume: 1974492506.488098,
      btcVolume: 23776.338284261044,
      usdVolume: 1357118070.6740103,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '631.1600000000000000',
      high: '673.0000000000000000',
      low: '621.1100000000000000',
      close: '671.9100000000000000',
      volume: '2559194.2717000000000000',
      quoteVolume: 1669252689.2629402,
      btcVolume: 23284.48219498061,
      usdVolume: 1299085092.4906397,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '671.9000000000000000',
      high: '684.9100000000000000',
      low: '581.4000000000000000',
      close: '590.0000000000000000',
      volume: '2807361.4119000000000000',
      quoteVolume: 1818252882.3973007,
      btcVolume: 32752.382692644092,
      usdVolume: 1796910641.5199373,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '594.2000000000000000',
      high: '633.5000000000000000',
      low: '526.6000000000000000',
      close: '571.1000000000000000',
      volume: '5067197.8665000000000000',
      quoteVolume: 2965262611.7093215,
      btcVolume: 57817.7131586063,
      usdVolume: 2860884730.229648,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '571.1000000000000000',
      high: '615.0000000000000000',
      low: '566.0000000000000000',
      close: '602.6100000000000000',
      volume: '2185834.5291000000000000',
      quoteVolume: 1300235538.911767,
      btcVolume: 25346.497714855974,
      usdVolume: 1270717338.95908,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '602.6100000000000000',
      high: '611.3100000000000000',
      low: '560.0000000000000000',
      close: '563.4100000000000000',
      volume: '2259793.5825000000000000',
      quoteVolume: 1322248759.3872828,
      btcVolume: 26733.85650391243,
      usdVolume: 1302373374.1391342,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '563.4200000000000000',
      high: '606.7800000000000000',
      low: '534.8800000000000000',
      close: '564.3200000000000000',
      volume: '2935802.4611000000000000',
      quoteVolume: 1676575346.1177924,
      btcVolume: 34768.195828266646,
      usdVolume: 1641174937.546295,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '564.6600000000000000',
      high: '564.6600000000000000',
      low: '483.0000000000000000',
      close: '512.0700000000000000',
      volume: '4002372.8778000000000000',
      quoteVolume: 2073223636.3413508,
      btcVolume: 45783.614024130424,
      usdVolume: 2011961037.140912,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '512.0600000000000000',
      high: '534.0000000000000000',
      low: '490.0000000000000000',
      close: '507.8800000000000000',
      volume: '2720458.6730000000000000',
      quoteVolume: 1404074857.8504837,
      btcVolume: 31414.796893148603,
      usdVolume: 1382388016.6856706,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '507.9000000000000000',
      high: '516.5000000000000000',
      low: '280.0000000000000000',
      close: '334.8000000000000000',
      volume: '8239380.7045000000000000',
      quoteVolume: 3344112007.75396,
      btcVolume: 72337.91025415438,
      usdVolume: 2827850240.61419,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '334.8000000000000000',
      high: '423.8300000000000000',
      low: '284.2700000000000000',
      close: '391.1700000000000000',
      volume: '8115885.0027000000000000',
      quoteVolume: 2937254802.220806,
      btcVolume: 60130.470531550855,
      usdVolume: 2366728450.1871305,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '391.3700000000000000',
      high: '415.7100000000000000',
      low: '274.0000000000000000',
      close: '326.0100000000000000',
      volume: '7320106.3957000000000000',
      quoteVolume: 2518202198.2935557,
      btcVolume: 49722.66139396216,
      usdVolume: 1903529428.7297347,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '326.2300000000000000',
      high: '335.8200000000000000',
      low: '284.5500000000000000',
      close: '299.7600000000000000',
      volume: '5296110.3904000000000000',
      quoteVolume: 1644509563.62935,
      btcVolume: 28543.884802395307,
      usdVolume: 1058397270.464872,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '299.7500000000000000',
      high: '313.4500000000000000',
      low: '211.7000000000000000',
      close: '260.7200000000000000',
      volume: '8801148.1832000000000000',
      quoteVolume: 2229226334.873108,
      btcVolume: 63281.297938914686,
      usdVolume: 2178774483.491753,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '260.7200000000000000',
      high: '350.0000000000000000',
      low: '257.4000000000000000',
      close: '345.6400000000000000',
      volume: '7781959.4786000000000000',
      quoteVolume: 2368109550.148683,
      btcVolume: 62642.28704122964,
      usdVolume: 2327105512.6887536,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '345.6400000000000000',
      high: '378.1200000000000000',
      low: '306.6100000000000000',
      close: '343.1800000000000000',
      volume: '7638986.0132000000000000',
      quoteVolume: 2568468246.8690605,
      btcVolume: 66152.31977044823,
      usdVolume: 2514295964.407211,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '343.1800000000000000',
      high: '388.2800000000000000',
      low: '336.2100000000000000',
      close: '379.1600000000000000',
      volume: '5258751.3486000000000000',
      quoteVolume: 1933572793.550551,
      btcVolume: 48379.41805278539,
      usdVolume: 1900748427.6240995,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '379.1600000000000000',
      high: '387.5600000000000000',
      low: '340.0000000000000000',
      close: '369.6700000000000000',
      volume: '4275801.5014000000000000',
      quoteVolume: 1564221587.8316987,
      btcVolume: 40027.51492212167,
      usdVolume: 1551370014.1647067,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '369.8300000000000000',
      high: '373.6800000000000000',
      low: '312.3000000000000000',
      close: '328.8200000000000000',
      volume: '5253443.3619000000000000',
      quoteVolume: 1767981944.1573837,
      btcVolume: 47664.24020697601,
      usdVolume: 1739093359.1059759,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '328.8600000000000000',
      high: '347.0000000000000000',
      low: '295.2200000000000000',
      close: '305.0400000000000000',
      volume: '3763011.1068000000000000',
      quoteVolume: 1207079531.3800404,
      btcVolume: 33934.25604475658,
      usdVolume: 1191835011.423594,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '305.1800000000000000',
      high: '338.3200000000000000',
      low: '291.0600000000000000',
      close: '325.5700000000000000',
      volume: '3737180.6229000000000000',
      quoteVolume: 1203296649.3056147,
      btcVolume: 33941.50714115873,
      usdVolume: 1201181411.5601633,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '325.5500000000000000',
      high: '356.0000000000000000',
      low: '307.5500000000000000',
      close: '353.3300000000000000',
      volume: '4100337.3521000000000000',
      quoteVolume: 1373992157.7018433,
      btcVolume: 37865.87542025713,
      usdVolume: 1369885540.0723953,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '353.3700000000000000',
      high: '364.4000000000000000',
      low: '339.4800000000000000',
      close: '362.3900000000000000',
      volume: '3509428.1657000000000000',
      quoteVolume: 1234613133.2643874,
      btcVolume: 33299.87059281471,
      usdVolume: 1220996431.8403883,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '362.3600000000000000',
      high: '419.4300000000000000',
      low: '350.7100000000000000',
      close: '401.5400000000000000',
      volume: '4195383.3566000000000000',
      quoteVolume: 1645840910.622663,
      btcVolume: 43851.300647727745,
      usdVolume: 1640011801.5316956,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '401.5200000000000000',
      high: '433.0000000000000000',
      low: '391.3200000000000000',
      close: '428.4800000000000000',
      volume: '4180143.8076000000000000',
      quoteVolume: 1737768750.2461805,
      btcVolume: 45007.68133463864,
      usdVolume: 1735956965.104566,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '428.4600000000000000',
      high: '429.4400000000000000',
      low: '365.0000000000000000',
      close: '390.7000000000000000',
      volume: '4787078.4675000000000000',
      quoteVolume: 1860279166.5224113,
      btcVolume: 49180.94965001132,
      usdVolume: 1819826565.68764,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '390.6800000000000000',
      high: '426.0000000000000000',
      low: '373.8000000000000000',
      close: '390.0800000000000000',
      volume: '3977579.3470000000000000',
      quoteVolume: 1595813798.7458856,
      btcVolume: 43299.21514760122,
      usdVolume: 1582242242.5882614,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '390.0500000000000000',
      high: '400.2900000000000000',
      low: '381.5000000000000000',
      close: '393.7300000000000000',
      volume: '1861227.0254000000000000',
      quoteVolume: 728760657.4104598,
      btcVolume: 20250.859347582682,
      usdVolume: 727603105.8069081,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '393.8200000000000000',
      high: '407.4200000000000000',
      low: '353.5300000000000000',
      close: '357.9600000000000000',
      volume: '3028938.8445000000000000',
      quoteVolume: 1170669919.3293319,
      btcVolume: 32773.58817400823,
      usdVolume: 1165121515.317816,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '357.9400000000000000',
      high: '366.3300000000000000',
      low: '319.1800000000000000',
      close: '352.5000000000000000',
      volume: '4986849.8512000000000000',
      quoteVolume: 1719924713.8838046,
      btcVolume: 52178.07647016997,
      usdVolume: 1704623920.306418,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '352.5000000000000000',
      high: '378.7700000000000000',
      low: '333.5600000000000000',
      close: '375.5400000000000000',
      volume: '3494285.6690000000000000',
      quoteVolume: 1249840132.937433,
      btcVolume: 35442.0885217172,
      usdVolume: 1236317469.7043624,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '375.5600000000000000',
      high: '377.5500000000000000',
      low: '344.1300000000000000',
      close: '351.9400000000000000',
      volume: '2416033.9503000000000000',
      quoteVolume: 872167769.3608466,
      btcVolume: 23457.678201352148,
      usdVolume: 868637983.1314929,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '351.9600000000000000',
      high: '366.2800000000000000',
      low: '340.8400000000000000',
      close: '345.7500000000000000',
      volume: '2215352.7230000000000000',
      quoteVolume: 785929588.8141638,
      btcVolume: 21185.154784415125,
      usdVolume: 783490988.6749337,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '345.7600000000000000',
      high: '348.8800000000000000',
      low: '325.3000000000000000',
      close: '343.7000000000000000',
      volume: '2074837.0012000000000000',
      quoteVolume: 699907058.519631,
      btcVolume: 19499.705610749803,
      usdVolume: 696409381.2621572,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '343.6800000000000000',
      high: '369.0000000000000000',
      low: '330.4800000000000000',
      close: '365.8000000000000000',
      volume: '1958071.3656000000000000',
      quoteVolume: 679668875.2465632,
      btcVolume: 18401.26447166104,
      usdVolume: 677962892.4327875,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '365.8100000000000000',
      high: '377.3300000000000000',
      low: '355.0700000000000000',
      close: '370.1900000000000000',
      volume: '1978613.2384000000000000',
      quoteVolume: 723637001.3751506,
      btcVolume: 18165.93816349188,
      usdVolume: 723148705.02761,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '370.2000000000000000',
      high: '379.5800000000000000',
      low: '363.2300000000000000',
      close: '366.4800000000000000',
      volume: '1545190.4369000000000000',
      quoteVolume: 573156968.6381595,
      btcVolume: 14239.62588138577,
      usdVolume: 572741192.8239167,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '366.4800000000000000',
      high: '366.7700000000000000',
      low: '343.3500000000000000',
      close: '347.2200000000000000',
      volume: '1531522.2062000000000000',
      quoteVolume: 543418325.492728,
      btcVolume: 13807.544901929507,
      usdVolume: 542608162.7021075,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '347.2200000000000000',
      high: '362.4700000000000000',
      low: '345.8500000000000000',
      close: '352.8500000000000000',
      volume: '1239146.5273000000000000',
      quoteVolume: 439111935.5714126,
      btcVolume: 11360.551963526297,
      usdVolume: 438379161.15634346,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '352.8400000000000000',
      high: '357.1200000000000000',
      low: '327.2200000000000000',
      close: '337.1600000000000000',
      volume: '1583897.2791000000000000',
      quoteVolume: 543765122.8516467,
      btcVolume: 14644.68316373736,
      usdVolume: 540156402.3887985,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '337.2100000000000000',
      high: '345.0000000000000000',
      low: '331.1100000000000000',
      close: '334.0100000000000000',
      volume: '1066914.4291000000000000',
      quoteVolume: 361009064.1546483,
      btcVolume: 10093.106647631466,
      usdVolume: 360603082.2604604,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '334.0300000000000000',
      high: '340.5700000000000000',
      low: '310.1300000000000000',
      close: '339.0800000000000000',
      volume: '1618135.6528000000000000',
      quoteVolume: 529042411.3983932,
      btcVolume: 15094.299251402948,
      usdVolume: 523909240.19277626,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '339.0900000000000000',
      high: '342.0000000000000000',
      low: '268.5700000000000000',
      close: '270.7600000000000000',
      volume: '3728933.7663000000000000',
      quoteVolume: 1133986634.6228907,
      btcVolume: 34042.98997440145,
      usdVolume: 1114042737.0850716,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '270.7600000000000000',
      high: '291.5000000000000000',
      low: '225.4200000000000000',
      close: '262.1000000000000000',
      volume: '5569480.0596000000000000',
      quoteVolume: 1444878723.2121854,
      btcVolume: 45224.72868315482,
      usdVolume: 1421659323.222384,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '262.1000000000000000',
      high: '309.7500000000000000',
      low: '251.4100000000000000',
      close: '296.2500000000000000',
      volume: '3317195.3211000000000000',
      quoteVolume: 955844232.5110707,
      btcVolume: 28063.34606238212,
      usdVolume: 947394250.7374245,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '296.1700000000000000',
      high: '316.4900000000000000',
      low: '281.5000000000000000',
      close: '308.6200000000000000',
      volume: '2610131.7883000000000000',
      quoteVolume: 786154895.0311371,
      btcVolume: 23317.078611131026,
      usdVolume: 783721733.0198007,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '308.6000000000000000',
      high: '314.8400000000000000',
      low: '275.2400000000000000',
      close: '280.8100000000000000',
      volume: '2711888.8709000000000000',
      quoteVolume: 791189610.0092999,
      btcVolume: 23725.1496532656,
      usdVolume: 789609971.7930796,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '280.8900000000000000',
      high: '290.3300000000000000',
      low: '264.2600000000000000',
      close: '279.6500000000000000',
      volume: '2255714.8070000000000000',
      quoteVolume: 620289844.1352688,
      btcVolume: 19714.79679267372,
      usdVolume: 617533521.9969708,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '279.5800000000000000',
      high: '291.7000000000000000',
      low: '269.7200000000000000',
      close: '290.8700000000000000',
      volume: '1539903.0268000000000000',
      quoteVolume: 431044973.3830572,
      btcVolume: 12975.955639558339,
      usdVolume: 430025822.8561487,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '290.8900000000000000',
      high: '299.9600000000000000',
      low: '283.5500000000000000',
      close: '290.0000000000000000',
      volume: '1791427.4545000000000000',
      quoteVolume: 521315922.3130429,
      btcVolume: 15087.577540242723,
      usdVolume: 520602774.96711284,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '290.0000000000000000',
      high: '315.6000000000000000',
      low: '289.1900000000000000',
      close: '300.7900000000000000',
      volume: '1996075.2527000000000000',
      quoteVolume: 606800912.6351702,
      btcVolume: 17004.53387746386,
      usdVolume: 606106183.2717582,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '300.7900000000000000',
      high: '304.8800000000000000',
      low: '281.5300000000000000',
      close: '303.7100000000000000',
      volume: '2003771.1501000000000000',
      quoteVolume: 589286208.902001,
      btcVolume: 16881.53168393942,
      usdVolume: 588691995.1413999,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '303.7500000000000000',
      high: '304.0000000000000000',
      low: '281.0000000000000000',
      close: '287.4300000000000000',
      volume: '1388151.0265000000000000',
      quoteVolume: 401830865.47626346,
      btcVolume: 11897.911415033517,
      usdVolume: 400902690.8213621,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '287.4900000000000000',
      high: '290.7400000000000000',
      low: '276.4000000000000000',
      close: '287.1700000000000000',
      volume: '999531.0988000000000000',
      quoteVolume: 281885530.70657486,
      btcVolume: 8474.226394092162,
      usdVolume: 281776937.5769988,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '287.1700000000000000',
      high: '303.2100000000000000',
      low: '282.7700000000000000',
      close: '298.1300000000000000',
      volume: '970447.5647000000000000',
      quoteVolume: 285959914.3739259,
      btcVolume: 8295.090216560875,
      usdVolume: 285468108.13785046,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '298.1800000000000000',
      high: '314.9100000000000000',
      low: '292.5800000000000000',
      close: '307.6200000000000000',
      volume: '1289644.5294000000000000',
      quoteVolume: 395101122.4499773,
      btcVolume: 11166.440864142483,
      usdVolume: 394799633.44753224,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '307.6300000000000000',
      high: '307.6800000000000000',
      low: '291.0000000000000000',
      close: '302.0200000000000000',
      volume: '1401707.3090000000000000',
      quoteVolume: 420794880.4929045,
      btcVolume: 12250.65881746282,
      usdVolume: 417383535.8960341,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '302.0400000000000000',
      high: '322.5000000000000000',
      low: '301.8000000000000000',
      close: '319.9200000000000000',
      volume: '2017579.2475000000000000',
      quoteVolume: 634877944.4661232,
      btcVolume: 18213.7054914752,
      usdVolume: 624628084.0134494,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '319.9100000000000000',
      high: '339.2000000000000000',
      low: '318.2300000000000000',
      close: '327.5100000000000000',
      volume: '1752283.5071000000000000',
      quoteVolume: 581264198.4175483,
      btcVolume: 16826.903381226817,
      usdVolume: 581264198.4175483,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '327.5100000000000000',
      high: '328.2100000000000000',
      low: '304.4000000000000000',
      close: '310.1400000000000000',
      volume: '1644090.8501000000000000',
      quoteVolume: 519961296.56923467,
      btcVolume: 15753.624652227563,
      usdVolume: 518366397.8303491,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '310.0800000000000000',
      high: '319.6900000000000000',
      low: '301.4300000000000000',
      close: '317.0900000000000000',
      volume: '1106411.7860000000000000',
      quoteVolume: 343735624.2635021,
      btcVolume: 10375.187952189024,
      usdVolume: 343590533.1811929,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '317.0900000000000000',
      high: '321.4400000000000000',
      low: '310.5700000000000000',
      close: '316.1200000000000000',
      volume: '818970.9225000000000000',
      quoteVolume: 258075549.81921732,
      btcVolume: 7649.980650066824,
      usdVolume: 258069158.45030236,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '316.1200000000000000',
      high: '326.8200000000000000',
      low: '312.2100000000000000',
      close: '322.1600000000000000',
      volume: '798248.5445000000000000',
      quoteVolume: 255757579.33083242,
      btcVolume: 7559.742869184711,
      usdVolume: 255696265.09578243,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '322.1700000000000000',
      high: '334.3700000000000000',
      low: '308.5700000000000000',
      close: '316.1200000000000000',
      volume: '1322836.6575000000000000',
      quoteVolume: 426623454.93747866,
      btcVolume: 12659.101822822975,
      usdVolume: 426389699.78516084,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '316.1100000000000000',
      high: '318.3300000000000000',
      low: '305.2000000000000000',
      close: '308.9400000000000000',
      volume: '920029.5386000000000000',
      quoteVolume: 286332861.64762217,
      btcVolume: 8716.39219531748,
      usdVolume: 286196536.8978347,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '308.9800000000000000',
      high: '313.4600000000000000',
      low: '290.2400000000000000',
      close: '309.4000000000000000',
      volume: '1429632.5382000000000000',
      quoteVolume: 432703278.02084684,
      btcVolume: 13340.15495367681,
      usdVolume: 432565896.3701419,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '309.3300000000000000',
      high: '324.3300000000000000',
      low: '301.3200000000000000',
      close: '316.1100000000000000',
      volume: '2182717.6094000000000000',
      quoteVolume: 682797057.890845,
      btcVolume: 21238.91718069471,
      usdVolume: 681918652.5679137,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '316.1200000000000000',
      high: '323.5000000000000000',
      low: '301.5000000000000000',
      close: '302.8800000000000000',
      volume: '1404493.8001000000000000',
      quoteVolume: 436177727.3631599,
      btcVolume: 13765.70767375639,
      usdVolume: 436177363.4888899,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '302.9400000000000000',
      high: '306.7100000000000000',
      low: '295.0000000000000000',
      close: '300.5200000000000000',
      volume: '1000972.6192000000000000',
      quoteVolume: 300875643.0396566,
      btcVolume: 9534.88714728326,
      usdVolume: 300540577.2338914,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '300.5600000000000000',
      high: '309.1800000000000000',
      low: '298.7000000000000000',
      close: '302.1000000000000000',
      volume: '815497.3527000000000000',
      quoteVolume: 248310442.2807995,
      btcVolume: 7822.5474578018375,
      usdVolume: 248294306.8784705,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '302.0400000000000000',
      high: '303.9400000000000000',
      low: '278.8500000000000000',
      close: '280.5400000000000000',
      volume: '1318041.7074000000000000',
      quoteVolume: 380236625.4640259,
      btcVolume: 12254.211166206616,
      usdVolume: 380166078.83319795,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '280.5400000000000000',
      high: '283.6000000000000000',
      low: '254.5200000000000000',
      close: '264.4400000000000000',
      volume: '1796247.8290000000000000',
      quoteVolume: 477237090.34517443,
      btcVolume: 15976.537565143271,
      usdVolume: 477091911.90441567,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '264.4300000000000000',
      high: '298.9200000000000000',
      low: '261.0200000000000000',
      close: '293.2600000000000000',
      volume: '1740736.0226000000000000',
      quoteVolume: 493675666.91880316,
      btcVolume: 15781.106267115589,
      usdVolume: 493348159.1449845,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '293.2700000000000000',
      high: '298.4400000000000000',
      low: '287.3000000000000000',
      close: '293.3600000000000000',
      volume: '1173507.9432000000000000',
      quoteVolume: 344239131.7439699,
      btcVolume: 10689.223886290574,
      usdVolume: 343305158.22704864,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '293.3900000000000000',
      high: '301.5000000000000000',
      low: '281.0100000000000000',
      close: '285.8000000000000000',
      volume: '1349255.6384000000000000',
      quoteVolume: 393613769.09714,
      btcVolume: 12142.021329993779,
      usdVolume: 393613769.09714,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
  btt: {
    id: 'btt',
    name: 'BTT',
    symbol: 'BTT',
    image: 'assets/images/coin-logo/BTT.png',
    activity24h: 9974589345,
    availableAmount: 508.232323,
    usdxConversion: .7,
    allTimeValue: 943812390,
    allTimeAsset: 3421,
    candleData: [
      {
      open: '204.4500000000000000',
      high: '205.9400000000000000',
      low: '203.6200000000000000',
      close: '204.9900000000000000',
      volume: '44394.8335700000000000',
      quoteVolume: 9084565.9157548,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-28T00:00:00.000Z'
      },
      {
      open: '204.9900000000000000',
      high: '205.4900000000000000',
      low: '195.2800000000000000',
      close: '196.9600000000000000',
      volume: '118281.8227200000000000',
      quoteVolume: 23671711.3937594,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-29T00:00:00.000Z'
      },
      {
      open: '196.9500000000000000',
      high: '198.6000000000000000',
      low: '195.9100000000000000',
      close: '197.2700000000000000',
      volume: '83633.8146300000000000',
      quoteVolume: 16501372.8633176,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-30T00:00:00.000Z'
      },
      {
      open: '197.2400000000000000',
      high: '201.4500000000000000',
      low: '193.0100000000000000',
      close: '198.7300000000000000',
      volume: '114739.7042400000000000',
      quoteVolume: 22632315.2602598,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-31T00:00:00.000Z'
      },
      {
      open: '198.7000000000000000',
      high: '200.9500000000000000',
      low: '197.8800000000000000',
      close: '199.9600000000000000',
      volume: '86097.9862900000000000',
      quoteVolume: 17116621.5351845,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-01T00:00:00.000Z'
      },
      {
      open: '199.9300000000000000',
      high: '203.7400000000000000',
      low: '199.3400000000000000',
      close: '202.3500000000000000',
      volume: '97123.2281300000000000',
      quoteVolume: 19559214.4192249,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-02T00:00:00.000Z'
      },
      {
      open: '202.3100000000000000',
      high: '202.6900000000000000',
      low: '198.5900000000000000',
      close: '200.3100000000000000',
      volume: '72084.9293200000000000',
      quoteVolume: 14456528.3775608,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-03T00:00:00.000Z'
      },
      {
      open: '200.2700000000000000',
      high: '218.7600000000000000',
      low: '199.0000000000000000',
      close: '212.1100000000000000',
      volume: '182534.6216000000000000',
      quoteVolume: 37959621.568273,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-04T00:00:00.000Z'
      },
      {
      open: '212.1300000000000000',
      high: '213.3900000000000000',
      low: '207.6200000000000000',
      close: '210.6600000000000000',
      volume: '141432.1406000000000000',
      quoteVolume: 29714567.4106342,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-05T00:00:00.000Z'
      },
      {
      open: '210.6400000000000000',
      high: '221.2500000000000000',
      low: '209.1100000000000000',
      close: '220.7400000000000000',
      volume: '197797.4952700000000000',
      quoteVolume: 42534535.8639938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-06T00:00:00.000Z'
      },
      {
      open: '220.7700000000000000',
      high: '224.8000000000000000',
      low: '217.0100000000000000',
      close: '219.0700000000000000',
      volume: '204931.0342000000000000',
      quoteVolume: 45252424.0852285,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-07T00:00:00.000Z'
      },
      {
      open: '219.0600000000000000',
      high: '220.7800000000000000',
      low: '212.4900000000000000',
      close: '212.7900000000000000',
      volume: '189828.4945000000000000',
      quoteVolume: 40931506.148422,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-08T00:00:00.000Z'
      },
      {
      open: '212.7900000000000000',
      high: '215.5100000000000000',
      low: '209.1000000000000000',
      close: '210.7700000000000000',
      volume: '152215.9468600000000000',
      quoteVolume: 32309082.1975887,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-09T00:00:00.000Z'
      },
      {
      open: '210.7800000000000000',
      high: '215.5500000000000000',
      low: '209.0200000000000000',
      close: '213.2500000000000000',
      volume: '131384.4789300000000000',
      quoteVolume: 28019225.0100827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-10T00:00:00.000Z'
      },
      {
      open: '213.2700000000000000',
      high: '213.9900000000000000',
      low: '209.0000000000000000',
      close: '212.5400000000000000',
      volume: '136268.8115600000000000',
      quoteVolume: 28875079.8849474,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-11T00:00:00.000Z'
      },
      {
      open: '212.6000000000000000',
      high: '214.2200000000000000',
      low: '210.9000000000000000',
      close: '212.8900000000000000',
      volume: '99963.9720300000000000',
      quoteVolume: 21271725.1845819,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-12T00:00:00.000Z'
      },
      {
      open: '212.9100000000000000',
      high: '213.8000000000000000',
      low: '209.2000000000000000',
      close: '209.8400000000000000',
      volume: '114466.3670800000000000',
      quoteVolume: 24176049.6062305,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-13T00:00:00.000Z'
      },
      {
      open: '209.8400000000000000',
      high: '210.3000000000000000',
      low: '176.1600000000000000',
      close: '187.4300000000000000',
      volume: '434676.1683200000000000',
      quoteVolume: 82531353.9766622,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-14T00:00:00.000Z'
      },
      {
      open: '187.4300000000000000',
      high: '190.0000000000000000',
      low: '170.9900000000000000',
      close: '184.3500000000000000',
      volume: '452416.1078400000000000',
      quoteVolume: 81674273.8857771,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-15T00:00:00.000Z'
      },
      {
      open: '184.3500000000000000',
      high: '185.0000000000000000',
      low: '173.0000000000000000',
      close: '176.8900000000000000',
      volume: '241710.3476300000000000',
      quoteVolume: 43450649.948378,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-16T00:00:00.000Z'
      },
      {
      open: '176.9300000000000000',
      high: '177.9100000000000000',
      low: '173.9400000000000000',
      close: '176.2000000000000000',
      volume: '144804.7098600000000000',
      quoteVolume: 25452515.6364988,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-17T00:00:00.000Z'
      },
      {
      open: '176.2000000000000000',
      high: '182.9400000000000000',
      low: '176.1000000000000000',
      close: '178.9700000000000000',
      volume: '141736.3226300000000000',
      quoteVolume: 25261184.0928033,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-18T00:00:00.000Z'
      },
      {
      open: '179.0400000000000000',
      high: '179.3300000000000000',
      low: '146.4700000000000000',
      close: '150.8100000000000000',
      volume: '596789.8391600000000000',
      quoteVolume: 94639227.0313713,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-19T00:00:00.000Z'
      },
      {
      open: '150.7600000000000000',
      high: '154.7000000000000000',
      low: '126.9500000000000000',
      close: '133.8200000000000000',
      volume: '996552.2872100000000000',
      quoteVolume: 139191208.455093,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-20T00:00:00.000Z'
      },
      {
      open: '133.6800000000000000',
      high: '142.0100000000000000',
      low: '127.1500000000000000',
      close: '138.2300000000000000',
      volume: '718957.7855200000000000',
      quoteVolume: 97819681.4905397,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-21T00:00:00.000Z'
      },
      {
      open: '138.3600000000000000',
      high: '140.4000000000000000',
      low: '125.6400000000000000',
      close: '126.6300000000000000',
      volume: '334674.0718500000000000',
      quoteVolume: 44837388.4512714,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-22T00:00:00.000Z'
      },
      {
      open: '126.5800000000000000',
      high: '128.9000000000000000',
      low: '118.5600000000000000',
      close: '125.0200000000000000',
      volume: '504352.2438600000000000',
      quoteVolume: 62657024.0486937,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-23T00:00:00.000Z'
      },
      {
      open: '125.0000000000000000',
      high: '130.8100000000000000',
      low: '112.3200000000000000',
      close: '114.8900000000000000',
      volume: '471321.5676400000000000',
      quoteVolume: 57264800.0665808,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-24T00:00:00.000Z'
      },
      {
      open: '114.8300000000000000',
      high: '122.3500000000000000',
      low: '102.9500000000000000',
      close: '118.6700000000000000',
      volume: '836233.3125200000000000',
      quoteVolume: 93723314.1234466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-25T00:00:00.000Z'
      },
      {
      open: '118.6500000000000000',
      high: '121.5000000000000000',
      low: '106.4100000000000000',
      close: '110.6800000000000000',
      volume: '679477.3623100000000000',
      quoteVolume: 77209761.8275687,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-26T00:00:00.000Z'
      },
      {
      open: '110.6600000000000000',
      high: '114.3100000000000000',
      low: '102.3600000000000000',
      close: '111.4300000000000000',
      volume: '524024.4061600000000000',
      quoteVolume: 56638056.0091172,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-27T00:00:00.000Z'
      },
      {
      open: '111.3800000000000000',
      high: '126.8000000000000000',
      low: '111.3700000000000000',
      close: '122.9000000000000000',
      volume: '578552.7412400000000000',
      quoteVolume: 68485222.4434163,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-28T00:00:00.000Z'
      },
      {
      open: '122.8800000000000000',
      high: '124.0000000000000000',
      low: '114.7800000000000000',
      close: '117.9800000000000000',
      volume: '478777.3961400000000000',
      quoteVolume: 57140533.5732648,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-29T00:00:00.000Z'
      },
      {
      open: '117.9900000000000000',
      high: '120.2600000000000000',
      low: '111.0900000000000000',
      close: '113.7300000000000000',
      volume: '418593.6352900000000000',
      quoteVolume: 48362085.7818422,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-30T00:00:00.000Z'
      },
      {
      open: '113.7100000000000000',
      high: '120.8000000000000000',
      low: '111.3500000000000000',
      close: '118.1000000000000000',
      volume: '330159.0674200000000000',
      quoteVolume: 38614493.6264599,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-01T00:00:00.000Z'
      },
      {
      open: '118.0900000000000000',
      high: '120.7300000000000000',
      low: '116.0000000000000000',
      close: '116.5700000000000000',
      volume: '273657.6215000000000000',
      quoteVolume: 32184013.7601348,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-02T00:00:00.000Z'
      },
      {
      open: '116.6300000000000000',
      high: '116.9600000000000000',
      low: '106.7000000000000000',
      close: '108.7600000000000000',
      volume: '324446.0332400000000000',
      quoteVolume: 36185196.1948889,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-03T00:00:00.000Z'
      },
      {
      open: '108.7600000000000000',
      high: '113.2000000000000000',
      low: '106.0000000000000000',
      close: '109.9400000000000000',
      volume: '275321.0063000000000000',
      quoteVolume: 30365546.7521367,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-04T00:00:00.000Z'
      },
      {
      open: '109.9200000000000000',
      high: '110.5800000000000000',
      low: '102.3800000000000000',
      close: '102.7100000000000000',
      volume: '354071.5324100000000000',
      quoteVolume: 37519336.0718317,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-05T00:00:00.000Z'
      },
      {
      open: '102.7300000000000000',
      high: '104.6800000000000000',
      low: '90.2000000000000000',
      close: '91.0000000000000000',
      volume: '586138.5904300000000000',
      quoteVolume: 58114721.7510634,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-06T00:00:00.000Z'
      },
      {
      open: '91.1800000000000000',
      high: '96.9000000000000000',
      low: '82.0700000000000000',
      close: '93.0500000000000000',
      volume: '1030073.5846700000000000',
      quoteVolume: 90499447.337842,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-07T00:00:00.000Z'
      },
      {
      open: '93.0600000000000000',
      high: '98.0000000000000000',
      low: '84.5500000000000000',
      close: '90.3800000000000000',
      volume: '698217.1252900000000000',
      quoteVolume: 63224518.5473893,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-08T00:00:00.000Z'
      },
      {
      open: '90.3900000000000000',
      high: '98.7000000000000000',
      low: '88.6300000000000000',
      close: '93.2600000000000000',
      volume: '520519.3602300000000000',
      quoteVolume: 48533111.8520108,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-09T00:00:00.000Z'
      },
      {
      open: '93.2600000000000000',
      high: '95.2100000000000000',
      low: '88.1100000000000000',
      close: '89.9100000000000000',
      volume: '408573.0292900000000000',
      quoteVolume: 37180525.4573109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-10T00:00:00.000Z'
      },
      {
      open: '89.8800000000000000',
      high: '91.6500000000000000',
      low: '85.8300000000000000',
      close: '87.7600000000000000',
      volume: '339991.8424600000000000',
      quoteVolume: 30055007.219609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-11T00:00:00.000Z'
      },
      {
      open: '87.7700000000000000',
      high: '91.3100000000000000',
      low: '86.6300000000000000',
      close: '89.4100000000000000',
      volume: '285436.0402900000000000',
      quoteVolume: 25500398.9286536,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-12T00:00:00.000Z'
      },
      {
      open: '89.3900000000000000',
      high: '90.7100000000000000',
      low: '84.7100000000000000',
      close: '86.3400000000000000',
      volume: '331218.3008300000000000',
      quoteVolume: 29089359.8313012,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-13T00:00:00.000Z'
      },
      {
      open: '86.3500000000000000',
      high: '87.2500000000000000',
      low: '82.2000000000000000',
      close: '83.8200000000000000',
      volume: '318003.7342700000000000',
      quoteVolume: 27041687.8001072,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-14T00:00:00.000Z'
      },
      {
      open: '83.8200000000000000',
      high: '85.0000000000000000',
      low: '81.7900000000000000',
      close: '83.7600000000000000',
      volume: '217360.6577500000000000',
      quoteVolume: 18169124.0002302,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-15T00:00:00.000Z'
      },
      {
      open: '83.7500000000000000',
      high: '87.6400000000000000',
      low: '83.7300000000000000',
      close: '84.6700000000000000',
      volume: '241366.7630500000000000',
      quoteVolume: 20783723.6930707,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-16T00:00:00.000Z'
      },
      {
      open: '84.6800000000000000',
      high: '97.0000000000000000',
      low: '84.4000000000000000',
      close: '94.0200000000000000',
      volume: '472252.2338800000000000',
      quoteVolume: 42965553.4158694,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-17T00:00:00.000Z'
      },
      {
      open: '94.0300000000000000',
      high: '101.3000000000000000',
      low: '91.3400000000000000',
      close: '100.3900000000000000',
      volume: '529946.7917500000000000',
      quoteVolume: 50202945.7917017,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-18T00:00:00.000Z'
      },
      {
      open: '100.3900000000000000',
      high: '107.5500000000000000',
      low: '97.7500000000000000',
      close: '98.7400000000000000',
      volume: '827748.5909600000000000',
      quoteVolume: 84862730.2333679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-19T00:00:00.000Z'
      },
      {
      open: '98.7300000000000000',
      high: '116.0000000000000000',
      low: '97.8800000000000000',
      close: '114.0500000000000000',
      volume: '859612.5720500000000000',
      quoteVolume: 93128082.5480073,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-20T00:00:00.000Z'
      },
      {
      open: '113.7100000000000000',
      high: '118.0000000000000000',
      low: '104.7600000000000000',
      close: '107.6900000000000000',
      volume: '666048.7178600000000000',
      quoteVolume: 74510414.5879752,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-21T00:00:00.000Z'
      },
      {
      open: '107.6700000000000000',
      high: '115.5000000000000000',
      low: '105.3800000000000000',
      close: '115.2000000000000000',
      volume: '405672.8311700000000000',
      quoteVolume: 44814571.6096925,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-22T00:00:00.000Z'
      },
      {
      open: '115.1800000000000000',
      high: '131.9400000000000000',
      low: '114.9100000000000000',
      close: '129.0300000000000000',
      volume: '936094.1049000000000000',
      quoteVolume: 118250392.555097,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-23T00:00:00.000Z'
      },
      {
      open: '129.0300000000000000',
      high: '157.6700000000000000',
      low: '128.8000000000000000',
      close: '138.0300000000000000',
      volume: '1319609.8165000000000000',
      quoteVolume: 190137361.065877,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-24T00:00:00.000Z'
      },
      {
      open: '138.0100000000000000',
      high: '139.2600000000000000',
      low: '119.6800000000000000',
      close: '127.6000000000000000',
      volume: '1076164.1280900000000000',
      quoteVolume: 136600862.042676,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-25T00:00:00.000Z'
      },
      {
      open: '127.6100000000000000',
      high: '135.3200000000000000',
      low: '122.5100000000000000',
      close: '129.3500000000000000',
      volume: '794834.0560100000000000',
      quoteVolume: 102196717.395056,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-26T00:00:00.000Z'
      },
      {
      open: '129.3500000000000000',
      high: '131.0900000000000000',
      low: '111.2200000000000000',
      close: '113.8000000000000000',
      volume: '752177.9011100000000000',
      quoteVolume: 90456912.8883521,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-27T00:00:00.000Z'
      },
      {
      open: '113.7500000000000000',
      high: '136.6600000000000000',
      low: '111.8700000000000000',
      close: '135.3000000000000000',
      volume: '894454.1905100000000000',
      quoteVolume: 111865576.497758,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-28T00:00:00.000Z'
      },
      {
      open: '135.3000000000000000',
      high: '145.4200000000000000',
      low: '128.5000000000000000',
      close: '132.8900000000000000',
      volume: '1006654.4984400000000000',
      quoteVolume: 136479145.942347,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-29T00:00:00.000Z'
      },
      {
      open: '133.0000000000000000',
      high: '139.0000000000000000',
      low: '127.0300000000000000',
      close: '137.7700000000000000',
      volume: '704259.4161400000000000',
      quoteVolume: 94804050.4933616,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-30T00:00:00.000Z'
      },
      {
      open: '137.8100000000000000',
      high: '138.6600000000000000',
      low: '128.7000000000000000',
      close: '131.4500000000000000',
      volume: '484604.8396200000000000',
      quoteVolume: 64763118.1166383,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-31T00:00:00.000Z'
      },
      {
      open: '131.4500000000000000',
      high: '140.6700000000000000',
      low: '130.0000000000000000',
      close: '139.1000000000000000',
      volume: '401776.8879700000000000',
      quoteVolume: 54280385.598127,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-01T00:00:00.000Z'
      },
      {
      open: '139.1000000000000000',
      high: '155.3700000000000000',
      low: '137.7600000000000000',
      close: '152.0100000000000000',
      volume: '941486.3152400000000000',
      quoteVolume: 138801471.118652,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-02T00:00:00.000Z'
      },
      {
      open: '152.0100000000000000',
      high: '153.6400000000000000',
      low: '143.1100000000000000',
      close: '146.3000000000000000',
      volume: '708091.3171400000000000',
      quoteVolume: 104953535.148513,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-03T00:00:00.000Z'
      },
      {
      open: '146.3000000000000000',
      high: '154.8000000000000000',
      low: '143.5400000000000000',
      close: '151.9700000000000000',
      volume: '805248.9382100000000000',
      quoteVolume: 120827141.544228,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-04T00:00:00.000Z'
      },
      {
      open: '152.0800000000000000',
      high: '159.2600000000000000',
      low: '151.2000000000000000',
      close: '152.8300000000000000',
      volume: '773169.1373600000000000',
      quoteVolume: 120238339.782219,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-05T00:00:00.000Z'
      },
      {
      open: '152.8300000000000000',
      high: '157.4000000000000000',
      low: '147.5100000000000000',
      close: '153.8600000000000000',
      volume: '627908.4736600000000000',
      quoteVolume: 95681339.4600648,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-06T00:00:00.000Z'
      },
      {
      open: '153.8900000000000000',
      high: '155.4800000000000000',
      low: '147.8600000000000000',
      close: '149.3400000000000000',
      volume: '424765.3025800000000000',
      quoteVolume: 64310962.9190328,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-07T00:00:00.000Z'
      },
      {
      open: '149.2900000000000000',
      high: '151.6500000000000000',
      low: '145.2000000000000000',
      close: '147.6000000000000000',
      volume: '441661.0677800000000000',
      quoteVolume: 65535529.7557393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-08T00:00:00.000Z'
      },
      {
      open: '147.6200000000000000',
      high: '151.5000000000000000',
      low: '146.7700000000000000',
      close: '148.1000000000000000',
      volume: '299443.5630000000000000',
      quoteVolume: 44673066.391582,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-09T00:00:00.000Z'
      },
      {
      open: '148.1100000000000000',
      high: '149.4500000000000000',
      low: '121.0000000000000000',
      close: '124.9500000000000000',
      volume: '871751.7503800000000000',
      quoteVolume: 114437025.282728,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-10T00:00:00.000Z'
      },
      {
      open: '124.9600000000000000',
      high: '127.3600000000000000',
      low: '120.0800000000000000',
      close: '124.6500000000000000',
      volume: '545293.9836900000000000',
      quoteVolume: 67782816.3375987,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-11T00:00:00.000Z'
      },
      {
      open: '124.6700000000000000',
      high: '126.1900000000000000',
      low: '122.0000000000000000',
      close: '123.3500000000000000',
      volume: '300262.1340400000000000',
      quoteVolume: 37295866.3538343,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-12T00:00:00.000Z'
      },
      {
      open: '123.3500000000000000',
      high: '124.2500000000000000',
      low: '111.8700000000000000',
      close: '114.1600000000000000',
      volume: '422561.9677700000000000',
      quoteVolume: 49521201.2764662,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-13T00:00:00.000Z'
      },
      {
      open: '114.1700000000000000',
      high: '130.0500000000000000',
      low: '113.9400000000000000',
      close: '126.9500000000000000',
      volume: '546420.4085400000000000',
      quoteVolume: 66801543.3050366,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-14T00:00:00.000Z'
      },
      {
      open: '126.9500000000000000',
      high: '129.4000000000000000',
      low: '116.0000000000000000',
      close: '118.9500000000000000',
      volume: '649020.5297000000000000',
      quoteVolume: 79985529.5587708,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-15T00:00:00.000Z'
      },
      {
      open: '118.9300000000000000',
      high: '126.8800000000000000',
      low: '118.5200000000000000',
      close: '121.4800000000000000',
      volume: '513486.6547200000000000',
      quoteVolume: 62314225.9960432,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '121.4900000000000000',
      high: '122.9900000000000000',
      low: '116.8000000000000000',
      close: '121.5400000000000000',
      volume: '447535.2271600000000000',
      quoteVolume: 53854069.2105117,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '121.5600000000000000',
      high: '121.9800000000000000',
      low: '117.6600000000000000',
      close: '119.1700000000000000',
      volume: '235646.6840000000000000',
      quoteVolume: 28305971.6688854,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '119.1600000000000000',
      high: '125.2900000000000000',
      low: '119.1400000000000000',
      close: '122.6000000000000000',
      volume: '323303.7929600000000000',
      quoteVolume: 39715159.1649606,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '122.6100000000000000',
      high: '123.3200000000000000',
      low: '115.6800000000000000',
      close: '117.4700000000000000',
      volume: '451792.8535200000000000',
      quoteVolume: 53833668.9066349,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '117.5600000000000000',
      high: '118.5000000000000000',
      low: '113.6100000000000000',
      close: '115.6300000000000000',
      volume: '303632.9346300000000000',
      quoteVolume: 35258073.4282649,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '115.6200000000000000',
      high: '119.4900000000000000',
      low: '111.3900000000000000',
      close: '117.8100000000000000',
      volume: '359542.6387300000000000',
      quoteVolume: 41777737.4704705,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '117.8000000000000000',
      high: '118.9500000000000000',
      low: '114.8000000000000000',
      close: '116.4400000000000000',
      volume: '230247.6157800000000000',
      quoteVolume: 26975386.3924584,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '116.4000000000000000',
      high: '117.1500000000000000',
      low: '113.6100000000000000',
      close: '116.3900000000000000',
      volume: '216242.2982900000000000',
      quoteVolume: 25044265.7542041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '116.4000000000000000',
      high: '117.5000000000000000',
      low: '114.3000000000000000',
      close: '115.1100000000000000',
      volume: '198617.0810800000000000',
      quoteVolume: 22977175.9541193,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '115.1300000000000000',
      high: '118.0000000000000000',
      low: '115.0000000000000000',
      close: '115.3400000000000000',
      volume: '146216.8281900000000000',
      quoteVolume: 16978386.1972377,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '115.3200000000000000',
      high: '116.1500000000000000',
      low: '111.0400000000000000',
      close: '112.0500000000000000',
      volume: '247369.6055500000000000',
      quoteVolume: 28158012.7304768,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '112.0400000000000000',
      high: '112.8700000000000000',
      low: '100.9100000000000000',
      close: '105.5000000000000000',
      volume: '566205.7828800000000000',
      quoteVolume: 60050129.3863579,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '105.4900000000000000',
      high: '106.6100000000000000',
      low: '102.2200000000000000',
      close: '104.4500000000000000',
      volume: '319768.9134800000000000',
      quoteVolume: 33427469.3450618,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '104.4100000000000000',
      high: '109.8000000000000000',
      low: '103.4500000000000000',
      close: '108.2900000000000000',
      volume: '330968.1926500000000000',
      quoteVolume: 35368520.135571,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '108.2900000000000000',
      high: '110.6100000000000000',
      low: '105.4600000000000000',
      close: '106.4400000000000000',
      volume: '290679.6193500000000000',
      quoteVolume: 31234353.515393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '106.4700000000000000',
      high: '108.3500000000000000',
      low: '103.7700000000000000',
      close: '106.7300000000000000',
      volume: '243149.8843500000000000',
      quoteVolume: 25791535.1460238,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '106.7100000000000000',
      high: '111.1500000000000000',
      low: '106.0700000000000000',
      close: '110.4200000000000000',
      volume: '186908.7573000000000000',
      quoteVolume: 20153872.302919,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '110.4100000000000000',
      high: '111.1100000000000000',
      low: '105.8200000000000000',
      close: '107.3600000000000000',
      volume: '227211.5406400000000000',
      quoteVolume: 24605892.2887283,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '107.3300000000000000',
      high: '109.3000000000000000',
      low: '106.5700000000000000',
      close: '107.6800000000000000',
      volume: '160850.1029300000000000',
      quoteVolume: 17312736.6001502,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '107.6500000000000000',
      high: '108.1800000000000000',
      low: '106.4700000000000000',
      close: '107.2700000000000000',
      volume: '159730.1886000000000000',
      quoteVolume: 17137008.2478395,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '107.2800000000000000',
      high: '107.8300000000000000',
      low: '101.2100000000000000',
      close: '104.5000000000000000',
      volume: '337204.3025400000000000',
      quoteVolume: 34984364.2970212,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '104.4900000000000000',
      high: '106.1800000000000000',
      low: '103.8200000000000000',
      close: '104.4800000000000000',
      volume: '147942.0768300000000000',
      quoteVolume: 15530160.6471066,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '104.4800000000000000',
      high: '123.0000000000000000',
      low: '103.4100000000000000',
      close: '119.2500000000000000',
      volume: '589950.6492500000000000',
      quoteVolume: 67027886.9398639,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '119.3200000000000000',
      high: '121.9900000000000000',
      low: '117.6400000000000000',
      close: '119.1900000000000000',
      volume: '328067.9574000000000000',
      quoteVolume: 39061582.3914826,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '119.1900000000000000',
      high: '126.4900000000000000',
      low: '115.8100000000000000',
      close: '125.1200000000000000',
      volume: '385819.0961900000000000',
      quoteVolume: 45929459.1290131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '125.1200000000000000',
      high: '125.2800000000000000',
      low: '119.2400000000000000',
      close: '120.8900000000000000',
      volume: '389405.3040400000000000',
      quoteVolume: 47197595.845191,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '120.8800000000000000',
      high: '123.8900000000000000',
      low: '118.1800000000000000',
      close: '122.4100000000000000',
      volume: '343765.6698700000000000',
      quoteVolume: 41603632.811699,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '122.4000000000000000',
      high: '126.4700000000000000',
      low: '120.7000000000000000',
      close: '121.8600000000000000',
      volume: '317827.3195600000000000',
      quoteVolume: 39073077.8391656,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '121.8600000000000000',
      high: '124.5000000000000000',
      low: '120.1000000000000000',
      close: '120.4200000000000000',
      volume: '297205.7873800000000000',
      quoteVolume: 36278326.0774455,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '120.4700000000000000',
      high: '124.2500000000000000',
      low: '120.1900000000000000',
      close: '121.6700000000000000',
      volume: '255296.0814000000000000',
      quoteVolume: 31111280.2093057,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '121.6600000000000000',
      high: '124.9900000000000000',
      low: '121.5400000000000000',
      close: '122.6700000000000000',
      volume: '215320.7013400000000000',
      quoteVolume: 26462034.6125396,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '122.6700000000000000',
      high: '135.6100000000000000',
      low: '122.4300000000000000',
      close: '133.7500000000000000',
      volume: '614788.6007800000000000',
      quoteVolume: 78958054.9589805,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '133.7700000000000000',
      high: '148.3000000000000000',
      low: '132.4200000000000000',
      close: '145.6900000000000000',
      volume: '1079830.1502000000000000',
      quoteVolume: 152756654.645478,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '145.6900000000000000',
      high: '150.0000000000000000',
      low: '142.3600000000000000',
      close: '143.4400000000000000',
      volume: '747968.9044500000000000',
      quoteVolume: 109645050.466097,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '143.4500000000000000',
      high: '149.5200000000000000',
      low: '139.6000000000000000',
      close: '148.4400000000000000',
      volume: '614303.6100700000000000',
      quoteVolume: 89136482.3716519,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '148.4200000000000000',
      high: '149.6100000000000000',
      low: '142.7000000000000000',
      close: '145.6900000000000000',
      volume: '447364.4087300000000000',
      quoteVolume: 65199779.5124385,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '145.6800000000000000',
      high: '148.8500000000000000',
      low: '143.8500000000000000',
      close: '147.3600000000000000',
      volume: '366251.1854200000000000',
      quoteVolume: 53771600.8025665,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '147.4000000000000000',
      high: '159.6000000000000000',
      low: '145.7800000000000000',
      close: '157.7900000000000000',
      volume: '588183.7289600000000000',
      quoteVolume: 89997516.9234365,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '157.7500000000000000',
      high: '166.0000000000000000',
      low: '131.7000000000000000',
      close: '132.1000000000000000',
      volume: '1260506.8067200000000000',
      quoteVolume: 186688063.414179,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '132.1000000000000000',
      high: '141.5500000000000000',
      low: '131.8900000000000000',
      close: '137.8700000000000000',
      volume: '801147.0954300000000000',
      quoteVolume: 110348892.970024,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '137.8800000000000000',
      high: '138.4100000000000000',
      low: '133.7200000000000000',
      close: '136.2200000000000000',
      volume: '432944.1703300000000000',
      quoteVolume: 58979934.7160016,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '136.2200000000000000',
      high: '140.2900000000000000',
      low: '126.8400000000000000',
      close: '135.0200000000000000',
      volume: '567202.6103500000000000',
      quoteVolume: 76519497.9080148,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '135.0300000000000000',
      high: '139.0000000000000000',
      low: '132.1000000000000000',
      close: '135.3400000000000000',
      volume: '579327.3174900000000000',
      quoteVolume: 78861797.5593921,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '135.3200000000000000',
      high: '137.9500000000000000',
      low: '134.9000000000000000',
      close: '135.3200000000000000',
      volume: '302632.0283100000000000',
      quoteVolume: 41198722.5798789,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '135.3200000000000000',
      high: '136.1200000000000000',
      low: '130.0300000000000000',
      close: '132.5600000000000000',
      volume: '297005.2809300000000000',
      quoteVolume: 39467507.6857296,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '132.5400000000000000',
      high: '134.6000000000000000',
      low: '128.0100000000000000',
      close: '130.6000000000000000',
      volume: '222453.2859000000000000',
      quoteVolume: 29447917.2382588,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '130.6000000000000000',
      high: '131.1100000000000000',
      low: '122.6000000000000000',
      close: '125.6500000000000000',
      volume: '403947.7944500000000000',
      quoteVolume: 51060349.7665833,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '125.6800000000000000',
      high: '137.8400000000000000',
      low: '125.1900000000000000',
      close: '136.7200000000000000',
      volume: '512631.2317800000000000',
      quoteVolume: 67740644.3311417,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '136.7100000000000000',
      high: '140.2900000000000000',
      low: '133.3300000000000000',
      close: '137.4000000000000000',
      volume: '428826.8741500000000000',
      quoteVolume: 58701328.649431,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '137.3700000000000000',
      high: '140.2000000000000000',
      low: '135.2000000000000000',
      close: '136.8100000000000000',
      volume: '307336.4931000000000000',
      quoteVolume: 42271691.2428617,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '136.8200000000000000',
      high: '139.3400000000000000',
      low: '130.6200000000000000',
      close: '133.5200000000000000',
      volume: '330283.5823300000000000',
      quoteVolume: 44879637.3870354,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '133.5100000000000000',
      high: '138.6500000000000000',
      low: '132.9300000000000000',
      close: '137.2700000000000000',
      volume: '279861.1204900000000000',
      quoteVolume: 38220614.005662,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '137.2700000000000000',
      high: '137.5000000000000000',
      low: '134.0000000000000000',
      close: '135.3700000000000000',
      volume: '203965.1681500000000000',
      quoteVolume: 27650702.3793393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '135.3700000000000000',
      high: '136.1500000000000000',
      low: '131.0000000000000000',
      close: '132.6700000000000000',
      volume: '264587.0068300000000000',
      quoteVolume: 35285847.7482702,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '132.6700000000000000',
      high: '135.3000000000000000',
      low: '130.7700000000000000',
      close: '133.5700000000000000',
      volume: '194905.9684300000000000',
      quoteVolume: 25981446.1033466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '133.6200000000000000',
      high: '133.6500000000000000',
      low: '130.5800000000000000',
      close: '131.6500000000000000',
      volume: '209456.0984800000000000',
      quoteVolume: 27670734.4309227,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '131.6900000000000000',
      high: '135.1100000000000000',
      low: '128.5200000000000000',
      close: '132.2000000000000000',
      volume: '258413.8186800000000000',
      quoteVolume: 34099414.8055834,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '132.2400000000000000',
      high: '137.6200000000000000',
      low: '132.1800000000000000',
      close: '136.5600000000000000',
      volume: '295832.2656200000000000',
      quoteVolume: 39928522.3372778,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '136.6000000000000000',
      high: '144.4700000000000000',
      low: '136.5100000000000000',
      close: '140.9200000000000000',
      volume: '474664.1019700000000000',
      quoteVolume: 66878173.4518186,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '140.9400000000000000',
      high: '141.0700000000000000',
      low: '137.2000000000000000',
      close: '138.6700000000000000',
      volume: '252697.0814800000000000',
      quoteVolume: 35051209.9303395,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '138.6700000000000000',
      high: '142.0100000000000000',
      low: '136.1000000000000000',
      close: '137.5900000000000000',
      volume: '281718.9619500000000000',
      quoteVolume: 39017864.9224565,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '137.6200000000000000',
      high: '139.3800000000000000',
      low: '136.9700000000000000',
      close: '138.5500000000000000',
      volume: '218577.4520800000000000',
      quoteVolume: 30175322.0784989,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '138.5600000000000000',
      high: '139.6600000000000000',
      low: '136.0100000000000000',
      close: '139.0100000000000000',
      volume: '222883.5175400000000000',
      quoteVolume: 30757997.8115518,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '139.0400000000000000',
      high: '139.5500000000000000',
      low: '131.2100000000000000',
      close: '134.8500000000000000',
      volume: '312531.3936800000000000',
      quoteVolume: 42635937.4891712,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '134.8500000000000000',
      high: '137.2000000000000000',
      low: '134.2900000000000000',
      close: '135.9800000000000000',
      volume: '186119.7474500000000000',
      quoteVolume: 25309122.7264498,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '135.9200000000000000',
      high: '138.2100000000000000',
      low: '135.6000000000000000',
      close: '137.2800000000000000',
      volume: '174710.4305900000000000',
      quoteVolume: 23922572.3588934,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '137.2800000000000000',
      high: '137.3700000000000000',
      low: '134.7500000000000000',
      close: '136.0900000000000000',
      volume: '198490.4512400000000000',
      quoteVolume: 27042322.6952009,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '136.1200000000000000',
      high: '136.8300000000000000',
      low: '131.6000000000000000',
      close: '133.9800000000000000',
      volume: '230880.2850000000000000',
      quoteVolume: 31082445.5932449,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '133.9700000000000000',
      high: '134.7400000000000000',
      low: '132.2700000000000000',
      close: '134.1800000000000000',
      volume: '183139.1142600000000000',
      quoteVolume: 24490323.0607397,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '134.1400000000000000',
      high: '140.1400000000000000',
      low: '133.8400000000000000',
      close: '139.4500000000000000',
      volume: '317167.4532500000000000',
      quoteVolume: 43635238.4289265,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '139.4600000000000000',
      high: '139.5700000000000000',
      low: '137.3000000000000000',
      close: '137.8600000000000000',
      volume: '163185.6991000000000000',
      quoteVolume: 22543474.6122913,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '137.8500000000000000',
      high: '143.6000000000000000',
      low: '137.6200000000000000',
      close: '143.2500000000000000',
      volume: '235853.0454000000000000',
      quoteVolume: 33098245.9556135,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '143.2500000000000000',
      high: '145.8900000000000000',
      low: '140.0600000000000000',
      close: '142.4600000000000000',
      volume: '254798.7006700000000000',
      quoteVolume: 36382647.2268952,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '142.4400000000000000',
      high: '142.5000000000000000',
      low: '140.4200000000000000',
      close: '141.3800000000000000',
      volume: '142469.2117100000000000',
      quoteVolume: 20131931.6990724,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '141.4100000000000000',
      high: '143.0800000000000000',
      low: '139.5700000000000000',
      close: '141.2400000000000000',
      volume: '234310.1916100000000000',
      quoteVolume: 33234998.6438733,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '141.2400000000000000',
      high: '165.6000000000000000',
      low: '141.0000000000000000',
      close: '163.3100000000000000',
      volume: '906868.8586000000000000',
      quoteVolume: 138094679.302043,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '163.2600000000000000',
      high: '177.8100000000000000',
      low: '149.2300000000000000',
      close: '159.9600000000000000',
      volume: '1345344.5384500000000000',
      quoteVolume: 225523896.201466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '159.9600000000000000',
      high: '164.0000000000000000',
      low: '152.8000000000000000',
      close: '157.3600000000000000',
      volume: '686080.9524800000000000',
      quoteVolume: 109082183.587735,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '157.3300000000000000',
      high: '167.8300000000000000',
      low: '155.9400000000000000',
      close: '164.7700000000000000',
      volume: '516070.3455300000000000',
      quoteVolume: 84123101.326904,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '164.8400000000000000',
      high: '172.7700000000000000',
      low: '159.2000000000000000',
      close: '165.5000000000000000',
      volume: '528168.3329700000000000',
      quoteVolume: 87657058.1149817,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '165.5600000000000000',
      high: '175.4300000000000000',
      low: '164.0600000000000000',
      close: '174.2100000000000000',
      volume: '465507.5249700000000000',
      quoteVolume: 78876773.2967177,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '174.2900000000000000',
      high: '185.8700000000000000',
      low: '170.9000000000000000',
      close: '178.7200000000000000',
      volume: '873410.0728500000000000',
      quoteVolume: 156417981.302118,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '178.7100000000000000',
      high: '179.1700000000000000',
      low: '171.0500000000000000',
      close: '174.6000000000000000',
      volume: '431986.3610800000000000',
      quoteVolume: 75745619.5720787,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '174.5800000000000000',
      high: '183.7700000000000000',
      low: '168.0000000000000000',
      close: '177.1200000000000000',
      volume: '523943.2049800000000000',
      quoteVolume: 93283836.587114,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '177.1200000000000000',
      high: '177.4800000000000000',
      low: '158.2700000000000000',
      close: '164.0700000000000000',
      volume: '661305.3032100000000000',
      quoteVolume: 110201659.947876,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '164.0600000000000000',
      high: '166.5000000000000000',
      low: '158.1100000000000000',
      close: '163.1900000000000000',
      volume: '316337.8782300000000000',
      quoteVolume: 51556919.1461367,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '163.2000000000000000',
      high: '165.3700000000000000',
      low: '160.7400000000000000',
      close: '162.9900000000000000',
      volume: '179237.7457400000000000',
      quoteVolume: 29247030.1526373,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '162.9900000000000000',
      high: '168.1100000000000000',
      low: '161.2000000000000000',
      close: '167.2300000000000000',
      volume: '186977.6621100000000000',
      quoteVolume: 30640985.0609499,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '167.2100000000000000',
      high: '168.8100000000000000',
      low: '155.7000000000000000',
      close: '159.9500000000000000',
      volume: '351548.9079000000000000',
      quoteVolume: 57215098.1117043,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '159.9100000000000000',
      high: '166.7300000000000000',
      low: '159.4100000000000000',
      close: '165.3700000000000000',
      volume: '240370.5044100000000000',
      quoteVolume: 39245823.494595,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '165.4100000000000000',
      high: '167.2800000000000000',
      low: '163.7800000000000000',
      close: '165.7500000000000000',
      volume: '227841.5498100000000000',
      quoteVolume: 37678036.5964673,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '165.7500000000000000',
      high: '175.2000000000000000',
      low: '165.0400000000000000',
      close: '173.3300000000000000',
      volume: '396118.9440700000000000',
      quoteVolume: 68031293.2325779,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '173.3400000000000000',
      high: '173.5500000000000000',
      low: '168.5900000000000000',
      close: '172.6400000000000000',
      volume: '320541.7216600000000000',
      quoteVolume: 54991300.1097207,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '172.6600000000000000',
      high: '176.9700000000000000',
      low: '169.8700000000000000',
      close: '172.8800000000000000',
      volume: '253596.4840700000000000',
      quoteVolume: 43939979.0160537,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '172.8900000000000000',
      high: '173.4800000000000000',
      low: '164.8200000000000000',
      close: '168.5900000000000000',
      volume: '283450.4394300000000000',
      quoteVolume: 47781349.4020958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '168.5900000000000000',
      high: '172.3000000000000000',
      low: '167.0000000000000000',
      close: '170.5200000000000000',
      volume: '269868.3987600000000000',
      quoteVolume: 45832751.057015,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '170.5200000000000000',
      high: '175.8300000000000000',
      low: '167.7800000000000000',
      close: '169.1600000000000000',
      volume: '363523.3453100000000000',
      quoteVolume: 62784241.170476,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '169.1500000000000000',
      high: '169.8700000000000000',
      low: '159.2600000000000000',
      close: '164.7800000000000000',
      volume: '426497.0415300000000000',
      quoteVolume: 70076178.2845027,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '164.7800000000000000',
      high: '165.4900000000000000',
      low: '150.3500000000000000',
      close: '154.7200000000000000',
      volume: '422657.0323900000000000',
      quoteVolume: 67326464.6484704,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '154.7000000000000000',
      high: '162.5600000000000000',
      low: '151.4000000000000000',
      close: '157.9500000000000000',
      volume: '500355.8628300000000000',
      quoteVolume: 78334214.8744062,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '157.9300000000000000',
      high: '166.2400000000000000',
      low: '156.3500000000000000',
      close: '159.3100000000000000',
      volume: '215918.6384200000000000',
      quoteVolume: 34294277.9954465,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '159.3200000000000000',
      high: '160.2400000000000000',
      low: '156.9200000000000000',
      close: '157.8500000000000000',
      volume: '152976.3018100000000000',
      quoteVolume: 24304182.1410825,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '157.8600000000000000',
      high: '159.0500000000000000',
      low: '152.0000000000000000',
      close: '155.1100000000000000',
      volume: '243591.9489800000000000',
      quoteVolume: 37892463.2904875,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '155.1400000000000000',
      high: '162.8900000000000000',
      low: '155.1100000000000000',
      close: '161.8300000000000000',
      volume: '305056.2465300000000000',
      quoteVolume: 48352308.3471109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '161.8500000000000000',
      high: '163.8800000000000000',
      low: '158.3600000000000000',
      close: '160.1900000000000000',
      volume: '192517.6276300000000000',
      quoteVolume: 30965351.6968463,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '160.2000000000000000',
      high: '163.4800000000000000',
      low: '158.5000000000000000',
      close: '161.8100000000000000',
      volume: '289519.0202500000000000',
      quoteVolume: 46716417.0127823,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '161.8200000000000000',
      high: '170.8600000000000000',
      low: '160.9900000000000000',
      close: '168.0600000000000000',
      volume: '416357.5212300000000000',
      quoteVolume: 69185162.5971677,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '168.1000000000000000',
      high: '171.3000000000000000',
      low: '159.7600000000000000',
      close: '163.7000000000000000',
      volume: '312928.5834900000000000',
      quoteVolume: 51596174.4008955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '163.7100000000000000',
      high: '165.3600000000000000',
      low: '159.1800000000000000',
      close: '163.0500000000000000',
      volume: '176029.1044500000000000',
      quoteVolume: 28659516.7862457,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '163.0500000000000000',
      high: '178.0500000000000000',
      low: '157.5500000000000000',
      close: '173.0000000000000000',
      volume: '506528.1427800000000000',
      quoteVolume: 86112232.475863,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '172.9800000000000000',
      high: '182.7600000000000000',
      low: '167.8900000000000000',
      close: '169.1800000000000000',
      volume: '732496.8786100000000000',
      quoteVolume: 129206700.127684,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '169.0700000000000000',
      high: '173.1700000000000000',
      low: '164.8000000000000000',
      close: '170.8500000000000000',
      volume: '296410.7610300000000000',
      quoteVolume: 50307994.5847109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '170.8800000000000000',
      high: '173.8800000000000000',
      low: '166.5700000000000000',
      close: '170.9000000000000000',
      volume: '284663.9945600000000000',
      quoteVolume: 48482365.5414184,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '170.9600000000000000',
      high: '176.7000000000000000',
      low: '168.4600000000000000',
      close: '172.9100000000000000',
      volume: '425543.7402100000000000',
      quoteVolume: 73717438.6539584,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '172.9200000000000000',
      high: '205.2300000000000000',
      low: '172.9200000000000000',
      close: '190.2000000000000000',
      volume: '885292.3838900000000000',
      quoteVolume: 167180496.76233,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '190.2000000000000000',
      high: '204.3200000000000000',
      low: '181.0000000000000000',
      close: '188.1100000000000000',
      volume: '823372.2814800000000000',
      quoteVolume: 157611011.725251,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '188.0700000000000000',
      high: '206.8700000000000000',
      low: '185.3200000000000000',
      close: '195.8400000000000000',
      volume: '669265.4652400000000000',
      quoteVolume: 131102303.13857,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '195.8300000000000000',
      high: '219.8100000000000000',
      low: '194.1900000000000000',
      close: '216.4000000000000000',
      volume: '864211.0060300000000000',
      quoteVolume: 179717430.310727,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '216.4000000000000000',
      high: '250.2700000000000000',
      low: '216.2100000000000000',
      close: '248.1000000000000000',
      volume: '574697.2992700000000000',
      quoteVolume: 135054369.61757,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '248.0600000000000000',
      high: '279.7600000000000000',
      low: '241.7600000000000000',
      close: '264.2500000000000000',
      volume: '1733360.8936000000000000',
      quoteVolume: 452410350.051738,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '264.3500000000000000',
      high: '268.7400000000000000',
      low: '223.4300000000000000',
      close: '244.3000000000000000',
      volume: '1529892.5689800000000000',
      quoteVolume: 366429299.202803,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '244.3100000000000000',
      high: '248.0000000000000000',
      low: '230.6200000000000000',
      close: '233.6000000000000000',
      volume: '673218.0998200000000000',
      quoteVolume: 160450040.806919,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '233.5700000000000000',
      high: '264.0000000000000000',
      low: '231.7700000000000000',
      close: '259.5100000000000000',
      volume: '823859.0634200000000000',
      quoteVolume: 207383027.826484,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '259.3700000000000000',
      high: '259.4900000000000000',
      low: '238.0000000000000000',
      close: '250.3700000000000000',
      volume: '666889.8224100000000000',
      quoteVolume: 165783106.07703,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '250.4800000000000000',
      high: '262.0000000000000000',
      low: '246.0600000000000000',
      close: '253.4300000000000000',
      volume: '580249.6975300000000000',
      quoteVolume: 146749988.273737,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '253.3500000000000000',
      high: '260.1800000000000000',
      low: '239.6300000000000000',
      close: '243.6400000000000000',
      volume: '592848.9560500000000000',
      quoteVolume: 149006735.642177,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '243.6800000000000000',
      high: '247.3400000000000000',
      low: '231.1000000000000000',
      close: '244.5600000000000000',
      volume: '600193.8936800000000000',
      quoteVolume: 144051488.877393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '244.6300000000000000',
      high: '254.3500000000000000',
      low: '241.5400000000000000',
      close: '248.8700000000000000',
      volume: '447066.9259600000000000',
      quoteVolume: 111294049.366715,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '248.7500000000000000',
      high: '257.3300000000000000',
      low: '247.2600000000000000',
      close: '250.2900000000000000',
      volume: '210086.6689900000000000',
      quoteVolume: 52883558.98214837,
      btcVolume: 6601.861190292515,
      usdVolume: 52883558.98214837,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '250.5800000000000000',
      high: '269.3300000000000000',
      low: '245.1100000000000000',
      close: '264.4300000000000000',
      volume: '328906.1700300000000000',
      quoteVolume: 84389359.21664856,
      btcVolume: 10168.644937039242,
      usdVolume: 84389359.21664856,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '264.4400000000000000',
      high: '279.5900000000000000',
      low: '262.0000000000000000',
      close: '270.5700000000000000',
      volume: '430881.9927300000000000',
      quoteVolume: 115553890.30784754,
      btcVolume: 13224.875481256204,
      usdVolume: 115553890.30784754,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '270.6500000000000000',
      high: '275.9500000000000000',
      low: '264.7100000000000000',
      close: '271.5400000000000000',
      volume: '244142.8490000000000000',
      quoteVolume: 66001001.032268964,
      btcVolume: 7580.161845035036,
      usdVolume: 66001001.032268964,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '270.7600000000000000',
      high: '274.0400000000000000',
      low: '260.0500000000000000',
      close: '268.9400000000000000',
      volume: '250176.3188600000000000',
      quoteVolume: 67085217.72845518,
      btcVolume: 7783.531911611134,
      usdVolume: 67085217.72845518,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '268.8900000000000000',
      high: '288.6200000000000000',
      low: '240.1400000000000000',
      close: '254.3900000000000000',
      volume: '874566.2467900000000000',
      quoteVolume: 237214565.53410167,
      btcVolume: 27663.736785432655,
      usdVolume: 237214565.53410167,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '254.5600000000000000',
      high: '268.7200000000000000',
      low: '245.2100000000000000',
      close: '267.5000000000000000',
      volume: '435596.2624900000000000',
      quoteVolume: 111862948.81367387,
      btcVolume: 13416.233814032905,
      usdVolume: 111862948.81367387,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '268.5500000000000000',
      high: '275.5000000000000000',
      low: '260.6800000000000000',
      close: '264.3300000000000000',
      volume: '340251.6517200000000000',
      quoteVolume: 90953282.63449085,
      btcVolume: 10654.39476621369,
      usdVolume: 90953282.63449085,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '264.3300000000000000',
      high: '273.2000000000000000',
      low: '263.2000000000000000',
      close: '268.8800000000000000',
      volume: '301536.1506200000000000',
      quoteVolume: 80960737.4054651,
      btcVolume: 9351.28557475043,
      usdVolume: 80960737.4054651,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '268.8700000000000000',
      high: '270.0000000000000000',
      low: '248.0000000000000000',
      close: '249.9100000000000000',
      volume: '390829.8328400000000000',
      quoteVolume: 101905937.03778602,
      btcVolume: 12020.341131421099,
      usdVolume: 101905937.03778602,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '250.0200000000000000',
      high: '251.6700000000000000',
      low: '234.3900000000000000',
      close: '241.3900000000000000',
      volume: '633054.5403700000000000',
      quoteVolume: 154522639.97359976,
      btcVolume: 19697.30421417344,
      usdVolume: 154522639.97359976,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '241.4600000000000000',
      high: '250.4100000000000000',
      low: '237.3700000000000000',
      close: '245.6700000000000000',
      volume: '335951.0818300000000000',
      quoteVolume: 81838722.29639693,
      btcVolume: 10541.8570319359,
      usdVolume: 81838722.29639693,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '245.6300000000000000',
      high: '250.6600000000000000',
      low: '235.0100000000000000',
      close: '248.7300000000000000',
      volume: '297333.9071700000000000',
      quoteVolume: 72359467.8907992,
      btcVolume: 9375.200502885184,
      usdVolume: 72359467.8907992,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '248.7500000000000000',
      high: '253.2800000000000000',
      low: '243.4600000000000000',
      close: '248.7700000000000000',
      volume: '353382.9471000000000000',
      quoteVolume: 87645350.54723677,
      btcVolume: 11105.098897639275,
      usdVolume: 87645350.54723677,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '248.7800000000000000',
      high: '250.5300000000000000',
      low: '240.3700000000000000',
      close: '243.2500000000000000',
      volume: '188448.2774800000000000',
      quoteVolume: 46293916.7909611,
      btcVolume: 5858.450696551191,
      usdVolume: 46293916.7909611,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '243.3300000000000000',
      high: '244.5300000000000000',
      low: '226.5600000000000000',
      close: '230.9800000000000000',
      volume: '316894.1012500000000000',
      quoteVolume: 74585371.36728549,
      btcVolume: 9656.729097772812,
      usdVolume: 74585371.36728549,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '230.9800000000000000',
      high: '247.4500000000000000',
      low: '227.0300000000000000',
      close: '246.5800000000000000',
      volume: '314636.9740500000000000',
      quoteVolume: 75084193.16334817,
      btcVolume: 9642.047156408467,
      usdVolume: 75084193.16334817,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '246.6400000000000000',
      high: '247.8000000000000000',
      low: '236.4900000000000000',
      close: '244.2800000000000000',
      volume: '236917.8619100000000000',
      quoteVolume: 57405428.93464183,
      btcVolume: 7322.677723536042,
      usdVolume: 57405428.93464183,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '244.2900000000000000',
      high: '261.8700000000000000',
      low: '242.2000000000000000',
      close: '261.0400000000000000',
      volume: '334914.1948300000000000',
      quoteVolume: 84260792.21337387,
      btcVolume: 10493.689905645679,
      usdVolume: 84260792.21337387,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '261.0400000000000000',
      high: '262.9800000000000000',
      low: '253.6900000000000000',
      close: '254.7000000000000000',
      volume: '291757.4165600000000000',
      quoteVolume: 75447458.94669941,
      btcVolume: 9258.053283515925,
      usdVolume: 75447458.94669941,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '254.6700000000000000',
      high: '264.2600000000000000',
      low: '251.7500000000000000',
      close: '262.4700000000000000',
      volume: '327384.9350300000000000',
      quoteVolume: 84046270.42804858,
      btcVolume: 10052.089943759258,
      usdVolume: 84046270.42804858,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '262.4800000000000000',
      high: '272.2700000000000000',
      low: '260.6100000000000000',
      close: '267.8100000000000000',
      volume: '286562.0009300000000000',
      quoteVolume: 76044367.43084204,
      btcVolume: 8744.231544379601,
      usdVolume: 76044367.43084204,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '267.8200000000000000',
      high: '277.0600000000000000',
      low: '263.3100000000000000',
      close: '267.8200000000000000',
      volume: '457043.8053700000000000',
      quoteVolume: 123543186.81485234,
      btcVolume: 13633.549352858961,
      usdVolume: 123543186.81485234,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '267.7100000000000000',
      high: '276.0000000000000000',
      low: '266.8100000000000000',
      close: '273.0900000000000000',
      volume: '339245.3390700000000000',
      quoteVolume: 91798515.34806772,
      btcVolume: 9973.204806382242,
      usdVolume: 91798515.34806772,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '273.1200000000000000',
      high: '273.5800000000000000',
      low: '261.4500000000000000',
      close: '264.6600000000000000',
      volume: '319694.8046200000000000',
      quoteVolume: 85270720.28219834,
      btcVolume: 9341.837857544495,
      usdVolume: 85191137.66587001,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '264.6800000000000000',
      high: '270.0000000000000000',
      low: '263.5900000000000000',
      close: '268.1400000000000000',
      volume: '208374.2487300000000000',
      quoteVolume: 55665534.352045216,
      btcVolume: 6091.847271949922,
      usdVolume: 55665534.352045216,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '268.1400000000000000',
      high: '273.6300000000000000',
      low: '265.7100000000000000',
      close: '271.2200000000000000',
      volume: '197206.8955100000000000',
      quoteVolume: 53051977.61480555,
      btcVolume: 5666.18245046985,
      usdVolume: 53051977.61480555,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '271.2700000000000000',
      high: '295.9400000000000000',
      low: '271.2200000000000000',
      close: '294.3000000000000000',
      volume: '519753.0589000000000000',
      quoteVolume: 148622967.47444713,
      btcVolume: 15205.227567841224,
      usdVolume: 148622967.47444713,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '294.3200000000000000',
      high: '314.3900000000000000',
      low: '289.6900000000000000',
      close: '310.6100000000000000',
      volume: '716598.8706100000000000',
      quoteVolume: 219105237.54071936,
      btcVolume: 20528.78896259923,
      usdVolume: 219105237.54071936,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '310.7000000000000000',
      high: '321.7000000000000000',
      low: '305.5800000000000000',
      close: '308.5200000000000000',
      volume: '418569.8935300000000000',
      quoteVolume: 131176119.43065393,
      btcVolume: 12082.996427040667,
      usdVolume: 131176119.43065393,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '308.4200000000000000',
      high: '314.4100000000000000',
      low: '298.0000000000000000',
      close: '311.6800000000000000',
      volume: '352738.3189300000000000',
      quoteVolume: 108423026.96658173,
      btcVolume: 9981.964869792726,
      usdVolume: 108423026.96658173,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '311.6400000000000000',
      high: '318.9900000000000000',
      low: '307.0000000000000000',
      close: '318.2600000000000000',
      volume: '345551.7547400000000000',
      quoteVolume: 108140713.48472323,
      btcVolume: 9505.539339201336,
      usdVolume: 108140713.48472323,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '318.2900000000000000',
      high: '366.8000000000000000',
      low: '315.5700000000000000',
      close: '339.3900000000000000',
      volume: '977599.1145100000000000',
      quoteVolume: 331095769.33132416,
      btcVolume: 25950.15573069012,
      usdVolume: 331095769.33132416,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '339.5300000000000000',
      high: '347.0000000000000000',
      low: '280.0100000000000000',
      close: '298.3500000000000000',
      volume: '1008017.1146500000000000',
      quoteVolume: 312540982.32200617,
      btcVolume: 26554.17730475792,
      usdVolume: 312540982.32200617,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '298.3500000000000000',
      high: '313.5800000000000000',
      low: '295.2200000000000000',
      close: '310.9400000000000000',
      volume: '529156.4880900000000000',
      quoteVolume: 161833162.7906752,
      btcVolume: 13747.592392081055,
      usdVolume: 161833162.7906752,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '311.0400000000000000',
      high: '324.7400000000000000',
      low: '295.1800000000000000',
      close: '317.6200000000000000',
      volume: '424971.8825400000000000',
      quoteVolume: 130248119.6772739,
      btcVolume: 10938.351707879097,
      usdVolume: 130248119.6772739,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '317.6200000000000000',
      high: '323.5000000000000000',
      low: '290.1200000000000000',
      close: '292.6500000000000000',
      volume: '489053.0179000000000000',
      quoteVolume: 149535574.11292773,
      btcVolume: 12975.985612883589,
      usdVolume: 149535574.11292773,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '292.7900000000000000',
      high: '303.4900000000000000',
      low: '280.1300000000000000',
      close: '295.0700000000000000',
      volume: '489783.9887600000000000',
      quoteVolume: 142876245.52713487,
      btcVolume: 13339.488752079711,
      usdVolume: 142876245.52713487,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '295.0300000000000000',
      high: '297.5700000000000000',
      low: '272.6500000000000000',
      close: '291.4800000000000000',
      volume: '493473.2749400000000000',
      quoteVolume: 139935800.5385573,
      btcVolume: 13679.520911516493,
      usdVolume: 139935800.5385573,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '291.4700000000000000',
      high: '302.8900000000000000',
      low: '285.0000000000000000',
      close: '301.1400000000000000',
      volume: '369294.5282900000000000',
      quoteVolume: 108898870.22858743,
      btcVolume: 9594.832986006992,
      usdVolume: 108898870.22858743,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '301.1100000000000000',
      high: '302.7400000000000000',
      low: '280.5300000000000000',
      close: '282.8200000000000000',
      volume: '269955.9546000000000000',
      quoteVolume: 79265333.87642588,
      btcVolume: 6797.331954964179,
      usdVolume: 79265333.87642588,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '282.8300000000000000',
      high: '294.8900000000000000',
      low: '280.7800000000000000',
      close: '287.4700000000000000',
      volume: '261133.4233100000000000',
      quoteVolume: 75163436.31124392,
      btcVolume: 6755.597179253407,
      usdVolume: 75163436.31124392,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '287.3200000000000000',
      high: '296.7100000000000000',
      low: '285.2800000000000000',
      close: '288.2300000000000000',
      volume: '196064.5701800000000000',
      quoteVolume: 57137760.438672334,
      btcVolume: 5022.881732165552,
      usdVolume: 57137760.438672334,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '288.2900000000000000',
      high: '309.6900000000000000',
      low: '284.2500000000000000',
      close: '304.7200000000000000',
      volume: '246110.6519400000000000',
      quoteVolume: 73170593.82215981,
      btcVolume: 6461.019883114503,
      usdVolume: 73170593.82215981,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '304.7200000000000000',
      high: '313.7400000000000000',
      low: '300.7800000000000000',
      close: '312.0300000000000000',
      volume: '286873.6683400000000000',
      quoteVolume: 88048229.33933607,
      btcVolume: 7475.430726388852,
      usdVolume: 88048229.33933607,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '311.9700000000000000',
      high: '318.1900000000000000',
      low: '302.6900000000000000',
      close: '307.4700000000000000',
      volume: '305980.4263800000000000',
      quoteVolume: 95131881.31853455,
      btcVolume: 7644.49484918057,
      usdVolume: 95131881.31853455,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '307.4600000000000000',
      high: '314.1800000000000000',
      low: '281.0400000000000000',
      close: '289.0700000000000000',
      volume: '453318.0444900000000000',
      quoteVolume: 134696704.5792062,
      btcVolume: 10731.201391195193,
      usdVolume: 134696704.5792062,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '289.2300000000000000',
      high: '289.2300000000000000',
      low: '262.1200000000000000',
      close: '268.6300000000000000',
      volume: '499749.9402200000000000',
      quoteVolume: 135963256.5679943,
      btcVolume: 11808.225077232584,
      usdVolume: 135963256.5679943,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '268.6700000000000000',
      high: '278.9900000000000000',
      low: '266.2100000000000000',
      close: '274.6200000000000000',
      volume: '266703.2448100000000000',
      quoteVolume: 72734970.85533215,
      btcVolume: 6307.693377250677,
      usdVolume: 72734970.85533215,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '274.5300000000000000',
      high: '275.0400000000000000',
      low: '262.2000000000000000',
      close: '268.7900000000000000',
      volume: '205956.2684900000000000',
      quoteVolume: 55322770.19371056,
      btcVolume: 4873.915839593925,
      usdVolume: 55322770.19371056,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '268.8000000000000000',
      high: '269.7400000000000000',
      low: '222.8100000000000000',
      close: '225.8700000000000000',
      volume: '530763.9314500000000000',
      quoteVolume: 128508198.8008858,
      btcVolume: 12029.780617969636,
      usdVolume: 128508198.8008858,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '225.8700000000000000',
      high: '235.5800000000000000',
      low: '203.0800000000000000',
      close: '228.2000000000000000',
      volume: '713930.7343200000000000',
      quoteVolume: 159360393.30564103,
      btcVolume: 15433.265597050142,
      usdVolume: 159360393.30564103,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '228.2000000000000000',
      high: '234.5000000000000000',
      low: '192.2500000000000000',
      close: '198.8800000000000000',
      volume: '703463.4360500000000000',
      quoteVolume: 149069538.53840336,
      btcVolume: 14722.108901822574,
      usdVolume: 149069538.53840336,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '198.8900000000000000',
      high: '219.8600000000000000',
      low: '191.8400000000000000',
      close: '210.8100000000000000',
      volume: '694454.2491200000000000',
      quoteVolume: 142940279.97858965,
      btcVolume: 14966.311310757123,
      usdVolume: 142940279.97858965,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '210.8100000000000000',
      high: '229.6500000000000000',
      low: '205.7100000000000000',
      close: '225.7600000000000000',
      volume: '607720.5507500000000000',
      quoteVolume: 132341223.14210978,
      btcVolume: 13229.997952454805,
      usdVolume: 132225049.702838,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '225.7300000000000000',
      high: '226.2300000000000000',
      low: '213.4000000000000000',
      close: '220.7300000000000000',
      volume: '367728.2543200000000000',
      quoteVolume: 80763332.4244571,
      btcVolume: 7739.2579698659,
      usdVolume: 80763332.4244571,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '220.8400000000000000',
      high: '235.0900000000000000',
      low: '219.7800000000000000',
      close: '228.2000000000000000',
      volume: '324481.0616300000000000',
      quoteVolume: 73975253.97204855,
      btcVolume: 6947.4776892394175,
      usdVolume: 73975253.97204855,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '228.2500000000000000',
      high: '229.6600000000000000',
      low: '216.9900000000000000',
      close: '225.4200000000000000',
      volume: '269941.8271000000000000',
      quoteVolume: 60398168.970782824,
      btcVolume: 5727.828146485641,
      usdVolume: 60398168.970782824,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '225.3800000000000000',
      high: '228.3400000000000000',
      low: '212.2500000000000000',
      close: '217.5100000000000000',
      volume: '270400.5234700000000000',
      quoteVolume: 59405061.677015305,
      btcVolume: 5704.734783903516,
      usdVolume: 59405061.677015305,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '217.5900000000000000',
      high: '219.5500000000000000',
      low: '208.3600000000000000',
      close: '212.6500000000000000',
      volume: '317846.4293800000000000',
      quoteVolume: 67923781.93982494,
      btcVolume: 6750.32948411748,
      usdVolume: 67923781.93982494,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '212.4500000000000000',
      high: '218.2800000000000000',
      low: '202.0000000000000000',
      close: '216.2400000000000000',
      volume: '330735.0082700000000000',
      quoteVolume: 69192671.19910969,
      btcVolume: 7118.715377791444,
      usdVolume: 69192671.19910969,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '216.3500000000000000',
      high: '225.1200000000000000',
      low: '215.2300000000000000',
      close: '219.1400000000000000',
      volume: '280365.4146300000000000',
      quoteVolume: 62016363.6238403,
      btcVolume: 6193.944875951812,
      usdVolume: 62016363.6238403,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '219.1400000000000000',
      high: '220.0000000000000000',
      low: '212.7100000000000000',
      close: '218.8100000000000000',
      volume: '197602.4657900000000000',
      quoteVolume: 42800909.35558814,
      btcVolume: 4382.145350153194,
      usdVolume: 42800909.35558814,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '218.8100000000000000',
      high: '223.3000000000000000',
      low: '203.0000000000000000',
      close: '207.2300000000000000',
      volume: '296667.0630100000000000',
      quoteVolume: 62964379.504462086,
      btcVolume: 6496.223467135922,
      usdVolume: 62964379.504462086,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '207.3300000000000000',
      high: '213.5200000000000000',
      low: '198.2400000000000000',
      close: '211.6200000000000000',
      volume: '214577.6842300000000000',
      quoteVolume: 44564653.222721346,
      btcVolume: 4706.346521404506,
      usdVolume: 44564653.222721346,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '211.5800000000000000',
      high: '215.8300000000000000',
      low: '206.5900000000000000',
      close: '210.8900000000000000',
      volume: '225967.7936000000000000',
      quoteVolume: 47645918.66213094,
      btcVolume: 4986.044256069211,
      usdVolume: 47645918.66213094,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '210.8400000000000000',
      high: '214.3600000000000000',
      low: '204.4000000000000000',
      close: '209.5800000000000000',
      volume: '175533.8397300000000000',
      quoteVolume: 36699059.76343268,
      btcVolume: 3850.9828761000917,
      usdVolume: 36698995.585413486,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '209.5700000000000000',
      high: '218.7900000000000000',
      low: '209.2000000000000000',
      close: '218.4200000000000000',
      volume: '207213.5565800000000000',
      quoteVolume: 44416634.90026903,
      btcVolume: 4513.526614405959,
      usdVolume: 44416634.90026903,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '218.4200000000000000',
      high: '219.3900000000000000',
      low: '210.5400000000000000',
      close: '216.8400000000000000',
      volume: '186806.1884400000000000',
      quoteVolume: 40054998.682404175,
      btcVolume: 3972.6309014935255,
      usdVolume: 40054998.682404175,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '216.8000000000000000',
      high: '222.1800000000000000',
      low: '214.3100000000000000',
      close: '217.6100000000000000',
      volume: '204158.9276400000000000',
      quoteVolume: 44530976.07793969,
      btcVolume: 4253.794355309355,
      usdVolume: 44530976.07793969,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '217.6900000000000000',
      high: '224.5100000000000000',
      low: '216.6200000000000000',
      close: '222.1400000000000000',
      volume: '181591.9529600000000000',
      quoteVolume: 40285734.53192182,
      btcVolume: 3743.4532071145845,
      usdVolume: 40285734.53192182,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '222.1400000000000000',
      high: '223.3400000000000000',
      low: '216.9000000000000000',
      close: '221.7900000000000000',
      volume: '134883.5495500000000000',
      quoteVolume: 29674383.960012592,
      btcVolume: 2749.4027954145995,
      usdVolume: 29674383.960012592,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '221.7900000000000000',
      high: '236.2500000000000000',
      low: '221.7900000000000000',
      close: '233.5400000000000000',
      volume: '307911.0830700000000000',
      quoteVolume: 70951607.64964774,
      btcVolume: 6104.385556472976,
      usdVolume: 70951607.64964774,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '233.5300000000000000',
      high: '239.1500000000000000',
      low: '223.0300000000000000',
      close: '226.2800000000000000',
      volume: '332632.0690500000000000',
      quoteVolume: 76612125.24778406,
      btcVolume: 6481.8262212509835,
      usdVolume: 76612125.24778406,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '226.3100000000000000',
      high: '231.2500000000000000',
      low: '220.9500000000000000',
      close: '226.1000000000000000',
      volume: '276075.3803400000000000',
      quoteVolume: 62453877.71585768,
      btcVolume: 5316.932199587181,
      usdVolume: 62453877.71585768,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '226.1100000000000000',
      high: '228.5000000000000000',
      low: '215.5100000000000000',
      close: '221.3800000000000000',
      volume: '235285.6142500000000000',
      quoteVolume: 52379480.13759483,
      btcVolume: 4440.469334714355,
      usdVolume: 52379480.13759483,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '221.3800000000000000',
      high: '221.7900000000000000',
      low: '207.3000000000000000',
      close: '210.5900000000000000',
      volume: '230958.0568000000000000',
      quoteVolume: 49180479.38156705,
      btcVolume: 4160.003984113228,
      usdVolume: 49180479.38156705,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '210.5600000000000000',
      high: '215.0000000000000000',
      low: '202.6000000000000000',
      close: '206.4800000000000000',
      volume: '248474.4130400000000000',
      quoteVolume: 51678057.27487786,
      btcVolume: 4460.255650817106,
      usdVolume: 51678057.27487786,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '206.4800000000000000',
      high: '216.9400000000000000',
      low: '206.1400000000000000',
      close: '216.5700000000000000',
      volume: '187625.9175000000000000',
      quoteVolume: 39691954.109307386,
      btcVolume: 3486.7880886797293,
      usdVolume: 39691954.109307386,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '216.5700000000000000',
      high: '216.8400000000000000',
      low: '209.7500000000000000',
      close: '211.5600000000000000',
      volume: '123598.5398700000000000',
      quoteVolume: 26272463.184408363,
      btcVolume: 2303.3073218777877,
      usdVolume: 26272463.184408363,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '211.4100000000000000',
      high: '214.3000000000000000',
      low: '204.0000000000000000',
      close: '209.3300000000000000',
      volume: '166922.4822300000000000',
      quoteVolume: 34861802.56011053,
      btcVolume: 3125.4337664048094,
      usdVolume: 34861802.56011053,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '209.3000000000000000',
      high: '209.9000000000000000',
      low: '183.5600000000000000',
      close: '187.0900000000000000',
      volume: '310158.0672200000000000',
      quoteVolume: 60710593.62070666,
      btcVolume: 5862.423952661254,
      usdVolume: 60710593.62070666,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '187.1000000000000000',
      high: '189.9500000000000000',
      low: '181.2300000000000000',
      close: '188.0300000000000000',
      volume: '237166.2993100000000000',
      quoteVolume: 44039710.624289505,
      btcVolume: 4342.288061699282,
      usdVolume: 44039710.624289505,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '187.9800000000000000',
      high: '188.3900000000000000',
      low: '178.0400000000000000',
      close: '184.8800000000000000',
      volume: '282176.9227900000000000',
      quoteVolume: 51945165.33384251,
      btcVolume: 5121.74136567943,
      usdVolume: 51945165.33384251,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '184.8300000000000000',
      high: '187.0000000000000000',
      low: '181.8300000000000000',
      close: '185.5900000000000000',
      volume: '138799.6150800000000000',
      quoteVolume: 25599796.904651288,
      btcVolume: 2487.8626142405346,
      usdVolume: 25599796.904651288,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '185.6700000000000000',
      high: '197.9100000000000000',
      low: '183.3500000000000000',
      close: '194.3300000000000000',
      volume: '175216.9309700000000000',
      quoteVolume: 33627585.449778885,
      btcVolume: 3252.016245410699,
      usdVolume: 33627585.449778885,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '194.3200000000000000',
      high: '203.5900000000000000',
      low: '192.7000000000000000',
      close: '202.2800000000000000',
      volume: '239158.0927100000000000',
      quoteVolume: 47599954.978613,
      btcVolume: 4479.389668521723,
      usdVolume: 47599954.978613,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '202.2400000000000000',
      high: '202.7500000000000000',
      low: '194.4500000000000000',
      close: '196.6000000000000000',
      volume: '189101.1123700000000000',
      quoteVolume: 37373990.242117286,
      btcVolume: 3483.295716474263,
      usdVolume: 37373990.242117286,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '196.5500000000000000',
      high: '197.2000000000000000',
      low: '179.5300000000000000',
      close: '187.4500000000000000',
      volume: '284348.0028800000000000',
      quoteVolume: 53317203.7731113,
      btcVolume: 5220.638258204045,
      usdVolume: 53317203.7731113,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '187.4500000000000000',
      high: '195.1400000000000000',
      low: '182.8000000000000000',
      close: '190.4000000000000000',
      volume: '245448.2435900000000000',
      quoteVolume: 46402513.17893997,
      btcVolume: 4613.9219820769085,
      usdVolume: 46402513.17893997,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '190.3500000000000000',
      high: '196.1900000000000000',
      low: '188.1600000000000000',
      close: '194.0200000000000000',
      volume: '171898.6596700000000000',
      quoteVolume: 33061516.57383751,
      btcVolume: 3230.660408468503,
      usdVolume: 33061516.57383751,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '194.0200000000000000',
      high: '194.0900000000000000',
      low: '185.6300000000000000',
      close: '190.6000000000000000',
      volume: '167806.3429400000000000',
      quoteVolume: 31786227.862663552,
      btcVolume: 3148.57127433382,
      usdVolume: 31786227.862663552,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '190.6000000000000000',
      high: '192.4000000000000000',
      low: '182.8000000000000000',
      close: '186.5400000000000000',
      volume: '168041.6810300000000000',
      quoteVolume: 31596795.659395352,
      btcVolume: 3133.1655657182055,
      usdVolume: 31596795.659395352,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '186.5400000000000000',
      high: '193.7000000000000000',
      low: '186.0000000000000000',
      close: '188.5900000000000000',
      volume: '248347.1284900000000000',
      quoteVolume: 47147744.7486281,
      btcVolume: 4546.553792072835,
      usdVolume: 47147744.7486281,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '188.6400000000000000',
      high: '189.4900000000000000',
      low: '184.7500000000000000',
      close: '187.2400000000000000',
      volume: '157901.7820000000000000',
      quoteVolume: 29513850.7927091,
      btcVolume: 2896.2658559704814,
      usdVolume: 29513850.7927091,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '187.3000000000000000',
      high: '188.2200000000000000',
      low: '166.4800000000000000',
      close: '173.0300000000000000',
      volume: '305449.4437100000000000',
      quoteVolume: 54488502.82976617,
      btcVolume: 5471.412145390252,
      usdVolume: 54488502.82976617,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '173.0300000000000000',
      high: '173.5000000000000000',
      low: '163.6100000000000000',
      close: '169.0100000000000000',
      volume: '295241.2160000000000000',
      quoteVolume: 49838097.166188926,
      btcVolume: 5238.717079717483,
      usdVolume: 49838097.166188926,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '169.0300000000000000',
      high: '170.7700000000000000',
      low: '165.5500000000000000',
      close: '168.5000000000000000',
      volume: '238511.3647300000000000',
      quoteVolume: 40199011.83567741,
      btcVolume: 4214.966705259767,
      usdVolume: 40199011.83567741,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '168.4800000000000000',
      high: '174.9800000000000000',
      low: '165.6300000000000000',
      close: '171.5700000000000000',
      volume: '194999.1958300000000000',
      quoteVolume: 32983642.84325837,
      btcVolume: 3439.4432364087634,
      usdVolume: 32983642.84325837,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '171.5200000000000000',
      high: '173.4200000000000000',
      low: '167.6100000000000000',
      close: '170.7400000000000000',
      volume: '191088.8305400000000000',
      quoteVolume: 32618691.233790867,
      btcVolume: 3391.76802121759,
      usdVolume: 32618691.233790867,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '170.7300000000000000',
      high: '181.0000000000000000',
      low: '170.0200000000000000',
      close: '178.0500000000000000',
      volume: '294320.5420600000000000',
      quoteVolume: 51392254.91867205,
      btcVolume: 5145.478077032096,
      usdVolume: 51392254.91867205,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '178.0000000000000000',
      high: '183.0000000000000000',
      low: '174.2800000000000000',
      close: '178.7500000000000000',
      volume: '325198.5117100000000000',
      quoteVolume: 58155903.15998513,
      btcVolume: 5528.606852780161,
      usdVolume: 58155903.15998513,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '178.7900000000000000',
      high: '180.1400000000000000',
      low: '173.0000000000000000',
      close: '174.7200000000000000',
      volume: '286198.2752900000000000',
      quoteVolume: 50624411.02937586,
      btcVolume: 4799.552094657055,
      usdVolume: 50624411.02937586,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '174.7000000000000000',
      high: '176.1900000000000000',
      low: '168.1000000000000000',
      close: '173.7500000000000000',
      volume: '231857.0212900000000000',
      quoteVolume: 40092220.48523467,
      btcVolume: 3798.553502855217,
      usdVolume: 40092220.48523467,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '173.7400000000000000',
      high: '177.8700000000000000',
      low: '165.0000000000000000',
      close: '169.0800000000000000',
      volume: '310365.3140900000000000',
      quoteVolume: 53620745.30355337,
      btcVolume: 5073.360788024353,
      usdVolume: 53620745.30355337,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '169.1100000000000000',
      high: '180.8000000000000000',
      low: '168.3000000000000000',
      close: '177.6200000000000000',
      volume: '251633.8069800000000000',
      quoteVolume: 43892369.55699689,
      btcVolume: 4209.284863585666,
      usdVolume: 43892369.55699689,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '177.5800000000000000',
      high: '184.1800000000000000',
      low: '176.3000000000000000',
      close: '181.1900000000000000',
      volume: '288720.1333900000000000',
      quoteVolume: 52178342.21652669,
      btcVolume: 4999.145556813438,
      usdVolume: 52178342.21652669,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '181.1800000000000000',
      high: '185.3800000000000000',
      low: '176.0100000000000000',
      close: '180.5400000000000000',
      volume: '270831.2111100000000000',
      quoteVolume: 48805236.52920722,
      btcVolume: 4741.73754696686,
      usdVolume: 48805236.52920722,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '180.5200000000000000',
      high: '184.3600000000000000',
      low: '177.0000000000000000',
      close: '179.8800000000000000',
      volume: '238217.8448800000000000',
      quoteVolume: 43037704.07242178,
      btcVolume: 4213.994957892011,
      usdVolume: 43037704.07242178,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '179.8700000000000000',
      high: '182.8000000000000000',
      low: '173.0000000000000000',
      close: '178.2800000000000000',
      volume: '278560.0964800000000000',
      quoteVolume: 49658310.75524305,
      btcVolume: 4937.86830579354,
      usdVolume: 49658310.75524305,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '178.3000000000000000',
      high: '182.3800000000000000',
      low: '176.6200000000000000',
      close: '180.7200000000000000',
      volume: '203335.8256500000000000',
      quoteVolume: 36383120.69543437,
      btcVolume: 3557.784215657219,
      usdVolume: 36383120.69543437,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '180.7100000000000000',
      high: '181.3800000000000000',
      low: '177.5400000000000000',
      close: '180.9500000000000000',
      volume: '264421.1936600000000000',
      quoteVolume: 47382185.41032426,
      btcVolume: 4605.8993130550225,
      usdVolume: 47382185.41032426,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '180.9600000000000000',
      high: '188.7900000000000000',
      low: '179.7500000000000000',
      close: '188.1300000000000000',
      volume: '279315.8876300000000000',
      quoteVolume: 51414430.17027494,
      btcVolume: 4974.16648427331,
      usdVolume: 51414430.17027494,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '188.1400000000000000',
      high: '190.4500000000000000',
      low: '185.7600000000000000',
      close: '189.0300000000000000',
      volume: '288928.6082700000000000',
      quoteVolume: 54373471.38522485,
      btcVolume: 5276.705425791319,
      usdVolume: 54373471.38522485,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '189.0500000000000000',
      high: '199.4400000000000000',
      low: '188.3000000000000000',
      close: '197.2300000000000000',
      volume: '549687.6874800000000000',
      quoteVolume: 106195854.54559186,
      btcVolume: 10379.571268151718,
      usdVolume: 106195854.54559186,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '197.2200000000000000',
      high: '215.1300000000000000',
      low: '195.7400000000000000',
      close: '207.8400000000000000',
      volume: '715858.9165100000000000',
      quoteVolume: 145944198.49593797,
      btcVolume: 14286.041939823335,
      usdVolume: 145944198.49593797,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '207.8500000000000000',
      high: '217.2700000000000000',
      low: '207.6600000000000000',
      close: '210.2100000000000000',
      volume: '539028.1435900000000000',
      quoteVolume: 114703029.02276355,
      btcVolume: 11256.168265878949,
      usdVolume: 114703029.02276355,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '210.2700000000000000',
      high: '223.9400000000000000',
      low: '202.3000000000000000',
      close: '220.2400000000000000',
      volume: '831524.9647500000000000',
      quoteVolume: 176952550.75094664,
      btcVolume: 17699.54227982766,
      usdVolume: 176952550.75094664,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '220.2600000000000000',
      high: '221.5400000000000000',
      low: '212.0500000000000000',
      close: '218.0300000000000000',
      volume: '437246.7696400000000000',
      quoteVolume: 94949393.91495149,
      btcVolume: 9332.199000692983,
      usdVolume: 94949393.91495149,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '218.0100000000000000',
      high: '221.5000000000000000',
      low: '213.2000000000000000',
      close: '215.0500000000000000',
      volume: '417891.5242000000000000',
      quoteVolume: 90638548.23529331,
      btcVolume: 9023.05159525662,
      usdVolume: 90638548.23529331,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '215.0400000000000000',
      high: '215.6100000000000000',
      low: '206.1000000000000000',
      close: '211.2000000000000000',
      volume: '445348.5123300000000000',
      quoteVolume: 93653967.98538981,
      btcVolume: 9389.012165487718,
      usdVolume: 93653967.98538981,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '211.2000000000000000',
      high: '211.6800000000000000',
      low: '198.6500000000000000',
      close: '201.2900000000000000',
      volume: '392320.7899200000000000',
      quoteVolume: 80899036.09704779,
      btcVolume: 8221.249859609254,
      usdVolume: 80899036.09704779,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '201.2500000000000000',
      high: '202.9800000000000000',
      low: '150.9700000000000000',
      close: '165.6900000000000000',
      volume: '1370149.8274300000000000',
      quoteVolume: 245718654.8582988,
      btcVolume: 27141.120316206176,
      usdVolume: 245718654.8582988,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '165.7400000000000000',
      high: '174.8500000000000000',
      low: '161.8800000000000000',
      close: '169.9700000000000000',
      volume: '878956.3378100000000000',
      quoteVolume: 147611978.20665354,
      btcVolume: 17503.743620916684,
      usdVolume: 147611978.20665354,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '169.9600000000000000',
      high: '171.0100000000000000',
      low: '152.1100000000000000',
      close: '165.9200000000000000',
      volume: '770883.6988700000000000',
      quoteVolume: 126485225.43295789,
      btcVolume: 15552.32826268824,
      usdVolume: 126485225.43295789,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '165.9200000000000000',
      high: '176.7200000000000000',
      low: '161.0300000000000000',
      close: '173.7900000000000000',
      volume: '634893.1348000000000000',
      quoteVolume: 106454562.85455583,
      btcVolume: 13228.604400340973,
      usdVolume: 106454522.86135663,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '173.8300000000000000',
      high: '175.4900000000000000',
      low: '168.0000000000000000',
      close: '173.4900000000000000',
      volume: '517943.1928800000000000',
      quoteVolume: 89460465.58471853,
      btcVolume: 11001.489635218039,
      usdVolume: 89460465.58471853,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '173.4900000000000000',
      high: '174.5000000000000000',
      low: '164.1200000000000000',
      close: '169.2400000000000000',
      volume: '410856.2773000000000000',
      quoteVolume: 69327426.95232227,
      btcVolume: 8623.060832254734,
      usdVolume: 69327426.95232227,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '169.2600000000000000',
      high: '181.2400000000000000',
      low: '165.0100000000000000',
      close: '180.8500000000000000',
      volume: '580295.3997000000000000',
      quoteVolume: 100443187.77168407,
      btcVolume: 12452.06118869834,
      usdVolume: 100443187.77168407,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '180.8900000000000000',
      high: '185.5300000000000000',
      low: '173.1900000000000000',
      close: '175.6600000000000000',
      volume: '609952.9387900000000000',
      quoteVolume: 109361456.4661927,
      btcVolume: 13111.359686678557,
      usdVolume: 109361456.4661927,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '175.6500000000000000',
      high: '181.2900000000000000',
      low: '173.6500000000000000',
      close: '180.2400000000000000',
      volume: '348268.1162000000000000',
      quoteVolume: 61530973.80423232,
      btcVolume: 7459.867727760892,
      usdVolume: 61530973.80423232,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '180.2400000000000000',
      high: '180.7200000000000000',
      low: '169.5500000000000000',
      close: '174.6900000000000000',
      volume: '354756.7847800000000000',
      quoteVolume: 62001237.48178279,
      btcVolume: 7563.155490555289,
      usdVolume: 62001237.48178279,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '174.7100000000000000',
      high: '178.9800000000000000',
      low: '170.7400000000000000',
      close: '175.5500000000000000',
      volume: '333817.1892000000000000',
      quoteVolume: 58456089.07824363,
      btcVolume: 7179.203250367646,
      usdVolume: 58456089.07824363,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '175.5500000000000000',
      high: '176.7100000000000000',
      low: '172.0200000000000000',
      close: '176.2500000000000000',
      volume: '278096.7557300000000000',
      quoteVolume: 48579546.419203795,
      btcVolume: 6005.859799986798,
      usdVolume: 48579546.419203795,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '176.2300000000000000',
      high: '177.0400000000000000',
      low: '167.6800000000000000',
      close: '170.1200000000000000',
      volume: '313627.4917500000000000',
      quoteVolume: 54020278.79604051,
      btcVolume: 6804.035467902695,
      usdVolume: 54020278.79604051,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '170.1000000000000000',
      high: '182.3200000000000000',
      low: '168.6800000000000000',
      close: '179.8800000000000000',
      volume: '496335.0680100000000000',
      quoteVolume: 87584450.44061345,
      btcVolume: 10846.12187727844,
      usdVolume: 87584450.44061345,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '179.8900000000000000',
      high: '184.8700000000000000',
      low: '177.0000000000000000',
      close: '180.6000000000000000',
      volume: '400833.1661700000000000',
      quoteVolume: 72432871.7087306,
      btcVolume: 8826.82276500747,
      usdVolume: 72432871.7087306,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '180.6100000000000000',
      high: '195.5300000000000000',
      low: '178.9600000000000000',
      close: '192.6200000000000000',
      volume: '557835.1119300000000000',
      quoteVolume: 104652037.76379311,
      btcVolume: 12510.317498902197,
      usdVolume: 104652037.76379311,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '192.6100000000000000',
      high: '194.2000000000000000',
      low: '186.8800000000000000',
      close: '191.1400000000000000',
      volume: '436576.1546100000000000',
      quoteVolume: 83365576.54034896,
      btcVolume: 9773.782550866496,
      usdVolume: 83365576.54034896,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '191.1400000000000000',
      high: '196.6500000000000000',
      low: '179.4100000000000000',
      close: '180.6500000000000000',
      volume: '621046.7695900000000000',
      quoteVolume: 115752733.32752092,
      btcVolume: 13750.640098528595,
      usdVolume: 115752733.32752092,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '180.6500000000000000',
      high: '184.6400000000000000',
      low: '177.5900000000000000',
      close: '179.6800000000000000',
      volume: '290372.9091400000000000',
      quoteVolume: 52887563.36652836,
      btcVolume: 6354.686685667993,
      usdVolume: 52887563.36652836,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '179.6500000000000000',
      high: '184.9500000000000000',
      low: '178.5200000000000000',
      close: '180.9900000000000000',
      volume: '247588.8460700000000000',
      quoteVolume: 45067270.51547066,
      btcVolume: 5399.218077546016,
      usdVolume: 45067270.51547066,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '181.0000000000000000',
      high: '187.5400000000000000',
      low: '180.4300000000000000',
      close: '186.7200000000000000',
      volume: '279731.4719000000000000',
      quoteVolume: 51494611.47577687,
      btcVolume: 6199.09079783479,
      usdVolume: 51494611.47577687,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '186.7100000000000000',
      high: '188.3700000000000000',
      low: '175.9600000000000000',
      close: '180.4900000000000000',
      volume: '400889.5286500000000000',
      quoteVolume: 73178688.57269453,
      btcVolume: 8861.147026864002,
      usdVolume: 73178688.57269453,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '180.4900000000000000',
      high: '181.4400000000000000',
      low: '171.8100000000000000',
      close: '174.4700000000000000',
      volume: '347333.7878200000000000',
      quoteVolume: 61105309.00180442,
      btcVolume: 7601.657333953499,
      usdVolume: 61105309.00180442,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '174.5200000000000000',
      high: '178.9600000000000000',
      low: '172.6100000000000000',
      close: '177.1600000000000000',
      volume: '298795.8198000000000000',
      quoteVolume: 52578052.94403298,
      btcVolume: 6542.154565971178,
      usdVolume: 52578052.94403298,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '177.1700000000000000',
      high: '177.4400000000000000',
      low: '168.6600000000000000',
      close: '172.7400000000000000',
      volume: '318571.4343500000000000',
      quoteVolume: 55106462.09562457,
      btcVolume: 6943.9535656951975,
      usdVolume: 55106462.09562457,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '172.7800000000000000',
      high: '174.9800000000000000',
      low: '169.4400000000000000',
      close: '171.8900000000000000',
      volume: '296902.3488500000000000',
      quoteVolume: 51266629.241353355,
      btcVolume: 6441.994366966404,
      usdVolume: 51266629.241353355,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '171.8600000000000000',
      high: '176.8800000000000000',
      low: '169.2100000000000000',
      close: '175.2200000000000000',
      volume: '299146.0715200000000000',
      quoteVolume: 51655109.15151142,
      btcVolume: 6436.934282521773,
      usdVolume: 51655109.15151142,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '175.1800000000000000',
      high: '177.9000000000000000',
      low: '171.5900000000000000',
      close: '173.9800000000000000',
      volume: '270573.1392900000000000',
      quoteVolume: 47198240.85169531,
      btcVolume: 5741.980152076821,
      usdVolume: 47198240.85169531,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '174.0000000000000000',
      high: '175.0400000000000000',
      low: '170.3000000000000000',
      close: '171.2400000000000000',
      volume: '255425.1915800000000000',
      quoteVolume: 44175399.86161367,
      btcVolume: 5395.723198048019,
      usdVolume: 44175399.86161367,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '171.2000000000000000',
      high: '171.4900000000000000',
      low: '153.4500000000000000',
      close: '162.3500000000000000',
      volume: '707323.9820400000000000',
      quoteVolume: 114779124.2515252,
      btcVolume: 14987.397322367471,
      usdVolume: 114779124.2515252,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '162.3500000000000000',
      high: '163.7200000000000000',
      low: '158.7200000000000000',
      close: '160.3800000000000000',
      volume: '386873.3559300000000000',
      quoteVolume: 62349254.66595083,
      btcVolume: 8383.711086675577,
      usdVolume: 62349254.66595083,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '160.3900000000000000',
      high: '187.7500000000000000',
      low: '160.2500000000000000',
      close: '181.5000000000000000',
      volume: '868260.5296600000000000',
      quoteVolume: 151040465.53858292,
      btcVolume: 18615.31944889506,
      usdVolume: 151040465.53858292,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '181.5300000000000000',
      high: '197.7400000000000000',
      low: '173.8000000000000000',
      close: '179.4900000000000000',
      volume: '1189930.8960700000000000',
      quoteVolume: 218719059.8162375,
      btcVolume: 23386.331259630017,
      usdVolume: 218719059.8162375,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '179.4200000000000000',
      high: '188.7000000000000000',
      low: '176.2200000000000000',
      close: '183.7500000000000000',
      volume: '723948.0442200000000000',
      quoteVolume: 132240902.2748802,
      btcVolume: 13994.80514249749,
      usdVolume: 132240902.2748802,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '183.8400000000000000',
      high: '189.4800000000000000',
      low: '180.3500000000000000',
      close: '181.7200000000000000',
      volume: '581705.0428700000000000',
      quoteVolume: 106847690.6990435,
      btcVolume: 11272.596969686028,
      usdVolume: 106847690.6990435,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '181.6700000000000000',
      high: '192.7400000000000000',
      low: '181.2600000000000000',
      close: '190.4600000000000000',
      volume: '529266.3083100000000000',
      quoteVolume: 98895301.78917634,
      btcVolume: 10554.02278596135,
      usdVolume: 98895301.78917634,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '190.4500000000000000',
      high: '191.7100000000000000',
      low: '179.2800000000000000',
      close: '183.1300000000000000',
      volume: '537770.4305600000000000',
      quoteVolume: 99508269.89031763,
      btcVolume: 10838.56631729719,
      usdVolume: 99508269.89031763,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '183.1400000000000000',
      high: '185.2700000000000000',
      low: '177.6600000000000000',
      close: '182.1800000000000000',
      volume: '410609.1639300000000000',
      quoteVolume: 74631751.23578401,
      btcVolume: 8159.802334886984,
      usdVolume: 74631751.23578401,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '182.1900000000000000',
      high: '184.5000000000000000',
      low: '177.0200000000000000',
      close: '182.8600000000000000',
      volume: '331518.2372300000000000',
      quoteVolume: 60092446.93983594,
      btcVolume: 6574.424243967809,
      usdVolume: 60092446.93983594,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '182.8500000000000000',
      high: '186.0000000000000000',
      low: '181.5300000000000000',
      close: '182.9100000000000000',
      volume: '179852.0178300000000000',
      quoteVolume: 32934405.78327263,
      btcVolume: 3551.462296933882,
      usdVolume: 32934405.78327263,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '182.9000000000000000',
      high: '184.7000000000000000',
      low: '178.9500000000000000',
      close: '181.5400000000000000',
      volume: '232349.8479600000000000',
      quoteVolume: 42211230.11712696,
      btcVolume: 4590.906257325065,
      usdVolume: 42211230.11712696,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '181.5300000000000000',
      high: '188.6400000000000000',
      low: '180.3600000000000000',
      close: '185.7200000000000000',
      volume: '318731.1727400000000000',
      quoteVolume: 58897915.884484924,
      btcVolume: 6352.353989298192,
      usdVolume: 58897915.884484924,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '185.7100000000000000',
      high: '192.5100000000000000',
      low: '182.2200000000000000',
      close: '188.6800000000000000',
      volume: '389421.1059000000000000',
      quoteVolume: 72988899.54730941,
      btcVolume: 7828.37934264765,
      usdVolume: 72988899.54730941,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '188.6500000000000000',
      high: '195.0900000000000000',
      low: '187.7200000000000000',
      close: '191.1600000000000000',
      volume: '343219.9224000000000000',
      quoteVolume: 65471595.20700819,
      btcVolume: 7012.585366600161,
      usdVolume: 65471595.20700819,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '191.1600000000000000',
      high: '192.2700000000000000',
      low: '184.5900000000000000',
      close: '186.6800000000000000',
      volume: '309882.0820600000000000',
      quoteVolume: 58150631.2768292,
      btcVolume: 6291.1791580124855,
      usdVolume: 58150631.2768292,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '186.6700000000000000',
      high: '188.2600000000000000',
      low: '181.4100000000000000',
      close: '183.7400000000000000',
      volume: '364493.9337200000000000',
      quoteVolume: 67207601.06199925,
      btcVolume: 7488.491062509954,
      usdVolume: 67207601.06199925,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '183.7100000000000000',
      high: '185.7900000000000000',
      low: '182.6300000000000000',
      close: '184.8900000000000000',
      volume: '192073.3804400000000000',
      quoteVolume: 35441967.76570041,
      btcVolume: 4019.998146716231,
      usdVolume: 35441967.76570041,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '184.8600000000000000',
      high: '191.5800000000000000',
      low: '183.3000000000000000',
      close: '188.9500000000000000',
      volume: '269941.5343600000000000',
      quoteVolume: 50699742.773780935,
      btcVolume: 5698.653252114131,
      usdVolume: 50699742.773780935,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '188.9600000000000000',
      high: '190.1900000000000000',
      low: '184.0600000000000000',
      close: '184.9800000000000000',
      volume: '254362.4843600000000000',
      quoteVolume: 47520753.42440565,
      btcVolume: 5398.5502773478665,
      usdVolume: 47520753.42440565,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '184.9800000000000000',
      high: '187.6500000000000000',
      low: '182.4100000000000000',
      close: '187.0900000000000000',
      volume: '256637.8790500000000000',
      quoteVolume: 47608963.337230064,
      btcVolume: 5449.8930699344155,
      usdVolume: 47608963.337230064,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '187.0900000000000000',
      high: '189.6600000000000000',
      low: '185.3000000000000000',
      close: '188.1100000000000000',
      volume: '197273.8400100000000000',
      quoteVolume: 36936429.935943484,
      btcVolume: 4212.216828428857,
      usdVolume: 36936429.935943484,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '188.0700000000000000',
      high: '188.7200000000000000',
      low: '183.3400000000000000',
      close: '184.9300000000000000',
      volume: '245478.1899700000000000',
      quoteVolume: 45518688.567261755,
      btcVolume: 5250.084494910773,
      usdVolume: 45518688.567261755,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '184.9200000000000000',
      high: '186.7000000000000000',
      low: '177.6700000000000000',
      close: '180.0000000000000000',
      volume: '404828.6103000000000000',
      quoteVolume: 73739540.13606241,
      btcVolume: 8600.798508976508,
      usdVolume: 73739540.13606241,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '179.9900000000000000',
      high: '183.4600000000000000',
      low: '179.3000000000000000',
      close: '182.3700000000000000',
      volume: '172801.5257600000000000',
      quoteVolume: 31378510.015389085,
      btcVolume: 3700.092412038125,
      usdVolume: 31378510.015389085,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '182.3700000000000000',
      high: '186.0900000000000000',
      low: '180.0600000000000000',
      close: '183.8200000000000000',
      volume: '198323.1213100000000000',
      quoteVolume: 36407476.06173793,
      btcVolume: 4273.182664311965,
      usdVolume: 36407476.06173793,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '183.8200000000000000',
      high: '184.0600000000000000',
      low: '175.0100000000000000',
      close: '178.2000000000000000',
      volume: '292916.4108000000000000',
      quoteVolume: 52873504.41217531,
      btcVolume: 6334.069797912126,
      usdVolume: 52873504.41217531,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '178.2000000000000000',
      high: '178.5200000000000000',
      low: '172.6500000000000000',
      close: '175.9400000000000000',
      volume: '275886.6411000000000000',
      quoteVolume: 48414106.956933685,
      btcVolume: 5968.5359977672715,
      usdVolume: 48414106.956933685,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '175.9300000000000000',
      high: '177.4100000000000000',
      low: '173.5000000000000000',
      close: '174.7200000000000000',
      volume: '216315.9330900000000000',
      quoteVolume: 38001672.19309863,
      btcVolume: 4686.270009046089,
      usdVolume: 38001672.19309863,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '174.7500000000000000',
      high: '175.8500000000000000',
      low: '157.2600000000000000',
      close: '161.0100000000000000',
      volume: '470224.9285800000000000',
      quoteVolume: 78033991.15986177,
      btcVolume: 10011.92260591679,
      usdVolume: 78033991.15986177,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '161.0200000000000000',
      high: '162.7900000000000000',
      low: '138.0000000000000000',
      close: '149.5600000000000000',
      volume: '975937.0667600000000000',
      quoteVolume: 147306766.45684162,
      btcVolume: 20315.079048812546,
      usdVolume: 147306766.45684162,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '149.5500000000000000',
      high: '154.3300000000000000',
      low: '146.1100000000000000',
      close: '151.8600000000000000',
      volume: '369720.2906000000000000',
      quoteVolume: 55595034.725483,
      btcVolume: 7691.525331812389,
      usdVolume: 55595034.725483,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '151.8400000000000000',
      high: '152.8600000000000000',
      low: '138.6200000000000000',
      close: '139.9900000000000000',
      volume: '352318.5460300000000000',
      quoteVolume: 51503621.078441106,
      btcVolume: 7270.627960676268,
      usdVolume: 51503621.078441106,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '139.9600000000000000',
      high: '151.5000000000000000',
      low: '131.4500000000000000',
      close: '145.6900000000000000',
      volume: '749381.0582700000000000',
      quoteVolume: 106143909.57189968,
      btcVolume: 15339.430749701922,
      usdVolume: 106143909.57189968,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '145.8100000000000000',
      high: '149.9700000000000000',
      low: '143.3700000000000000',
      close: '147.4700000000000000',
      volume: '354023.0429800000000000',
      quoteVolume: 51873405.03929025,
      btcVolume: 7258.985728654345,
      usdVolume: 51873405.03929025,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '147.4700000000000000',
      high: '155.5400000000000000',
      low: '140.8400000000000000',
      close: '152.6200000000000000',
      volume: '563679.1596700000000000',
      quoteVolume: 83338641.04602854,
      btcVolume: 11568.893523095534,
      usdVolume: 83338641.04602854,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '152.6100000000000000',
      high: '154.6300000000000000',
      low: '149.0900000000000000',
      close: '150.7200000000000000',
      volume: '317714.1197600000000000',
      quoteVolume: 48324153.77624194,
      btcVolume: 6441.974596553162,
      usdVolume: 48324153.77624194,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '150.7200000000000000',
      high: '157.6000000000000000',
      low: '150.2300000000000000',
      close: '154.5000000000000000',
      volume: '328513.9167000000000000',
      quoteVolume: 50618447.52032263,
      btcVolume: 6641.291265743082,
      usdVolume: 50618447.52032263,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '154.5500000000000000',
      high: '155.2500000000000000',
      low: '149.7000000000000000',
      close: '151.4000000000000000',
      volume: '226862.4671000000000000',
      quoteVolume: 34617789.5827378,
      btcVolume: 4534.817039770797,
      usdVolume: 34617789.5827378,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '151.4300000000000000',
      high: '152.4900000000000000',
      low: '145.7900000000000000',
      close: '150.7300000000000000',
      volume: '344185.5408400000000000',
      quoteVolume: 51149912.23894076,
      btcVolume: 6959.7035482148,
      usdVolume: 51149912.23894076,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '150.7200000000000000',
      high: '151.4200000000000000',
      low: '146.7100000000000000',
      close: '148.6500000000000000',
      volume: '233774.3063000000000000',
      quoteVolume: 34759015.80900913,
      btcVolume: 4765.617361975778,
      usdVolume: 34759015.80900913,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '148.6600000000000000',
      high: '149.9300000000000000',
      low: '145.7700000000000000',
      close: '147.1700000000000000',
      volume: '196329.2250300000000000',
      quoteVolume: 29061161.101775736,
      btcVolume: 3976.947714942431,
      usdVolume: 29061161.101775736,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '147.1900000000000000',
      high: '151.0000000000000000',
      low: '143.1500000000000000',
      close: '145.3800000000000000',
      volume: '418523.8529900000000000',
      quoteVolume: 61465918.00020749,
      btcVolume: 8421.53839803797,
      usdVolume: 61465918.00020749,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '145.4500000000000000',
      high: '149.0200000000000000',
      low: '143.6400000000000000',
      close: '148.1000000000000000',
      volume: '298773.7016400000000000',
      quoteVolume: 43733249.10134732,
      btcVolume: 5975.972289154188,
      usdVolume: 43733249.10134732,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '148.1100000000000000',
      high: '149.7700000000000000',
      low: '145.7400000000000000',
      close: '148.4500000000000000',
      volume: '219804.5782100000000000',
      quoteVolume: 32420380.085808042,
      btcVolume: 4379.421398292797,
      usdVolume: 32420380.085808042,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '148.4600000000000000',
      high: '149.4900000000000000',
      low: '146.8500000000000000',
      close: '147.1400000000000000',
      volume: '140471.6858800000000000',
      quoteVolume: 20799551.345695384,
      btcVolume: 2765.513206109344,
      usdVolume: 20799551.345695384,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '147.1600000000000000',
      high: '151.6200000000000000',
      low: '146.1100000000000000',
      close: '150.4300000000000000',
      volume: '205292.3260400000000000',
      quoteVolume: 30604893.80851998,
      btcVolume: 4083.993478963329,
      usdVolume: 30604893.80851998,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '150.4300000000000000',
      high: '151.1800000000000000',
      low: '146.5600000000000000',
      close: '147.4100000000000000',
      volume: '241824.4918400000000000',
      quoteVolume: 36054914.410974964,
      btcVolume: 4830.038571314736,
      usdVolume: 36054914.410974964,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '147.3900000000000000',
      high: '148.5700000000000000',
      low: '143.8100000000000000',
      close: '145.5600000000000000',
      volume: '202345.9339500000000000',
      quoteVolume: 29619185.42152988,
      btcVolume: 4056.038127495942,
      usdVolume: 29619185.42152988,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '145.5300000000000000',
      high: '146.3400000000000000',
      low: '142.1200000000000000',
      close: '143.3900000000000000',
      volume: '157843.1048400000000000',
      quoteVolume: 22772651.168129046,
      btcVolume: 3157.805594325894,
      usdVolume: 22772651.168129046,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '143.4100000000000000',
      high: '145.8500000000000000',
      low: '139.2400000000000000',
      close: '144.8700000000000000',
      volume: '261614.3454600000000000',
      quoteVolume: 37425141.739041,
      btcVolume: 5217.446754533626,
      usdVolume: 37425141.739041,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '144.8700000000000000',
      high: '146.0000000000000000',
      low: '142.8000000000000000',
      close: '144.8000000000000000',
      volume: '160597.0306700000000000',
      quoteVolume: 23189253.309127137,
      btcVolume: 3205.056989013503,
      usdVolume: 23189253.309127137,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '144.8000000000000000',
      high: '145.0700000000000000',
      low: '141.1800000000000000',
      close: '141.7900000000000000',
      volume: '126232.5920100000000000',
      quoteVolume: 18027581.359546103,
      btcVolume: 2521.1961065175824,
      usdVolume: 18027581.359546103,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '141.7900000000000000',
      high: '144.1200000000000000',
      low: '139.9200000000000000',
      close: '142.4600000000000000',
      volume: '151189.6587700000000000',
      quoteVolume: 21495196.81888501,
      btcVolume: 3029.229605400445,
      usdVolume: 21495196.81888501,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '142.4600000000000000',
      high: '142.7200000000000000',
      low: '127.9300000000000000',
      close: '132.7300000000000000',
      volume: '450883.4902900000000000',
      quoteVolume: 60935923.41354128,
      btcVolume: 8739.56219158376,
      usdVolume: 60935923.41354128,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '132.7200000000000000',
      high: '132.9800000000000000',
      low: '119.1100000000000000',
      close: '121.8800000000000000',
      volume: '554842.5818300000000000',
      quoteVolume: 70638378.5959894,
      btcVolume: 10478.106986160494,
      usdVolume: 70638378.5959894,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '121.8800000000000000',
      high: '134.8700000000000000',
      low: '116.2600000000000000',
      close: '132.7800000000000000',
      volume: '884910.2066800000000000',
      quoteVolume: 110560097.03269246,
      btcVolume: 16307.41864801072,
      usdVolume: 110560097.03269246,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '132.8000000000000000',
      high: '134.0000000000000000',
      low: '125.6900000000000000',
      close: '128.1000000000000000',
      volume: '420674.8172000000000000',
      quoteVolume: 53766864.18518815,
      btcVolume: 7512.382660233176,
      usdVolume: 53766864.18518815,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '128.1000000000000000',
      high: '129.3900000000000000',
      low: '125.8400000000000000',
      close: '128.1900000000000000',
      volume: '213897.4673000000000000',
      quoteVolume: 27266605.48486431,
      btcVolume: 3812.8979207455554,
      usdVolume: 27266605.48486431,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '128.1900000000000000',
      high: '128.4000000000000000',
      low: '126.5000000000000000',
      close: '126.9900000000000000',
      volume: '135196.1164100000000000',
      quoteVolume: 17199278.79468431,
      btcVolume: 2407.517101337839,
      usdVolume: 17199278.79468431,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '127.0000000000000000',
      high: '133.0700000000000000',
      low: '126.8200000000000000',
      close: '132.0900000000000000',
      volume: '252349.1950800000000000',
      quoteVolume: 32837658.598232217,
      btcVolume: 4517.366310910823,
      usdVolume: 32837658.598232217,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '132.1200000000000000',
      high: '135.1000000000000000',
      low: '126.0000000000000000',
      close: '127.8000000000000000',
      volume: '421600.7565500000000000',
      quoteVolume: 55501305.92012195,
      btcVolume: 7393.0923029749465,
      usdVolume: 55501305.92012195,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '127.8000000000000000',
      high: '129.6900000000000000',
      low: '126.6100000000000000',
      close: '127.7100000000000000',
      volume: '200423.4802800000000000',
      quoteVolume: 25640395.796429265,
      btcVolume: 3511.8402799722517,
      usdVolume: 25640395.796429265,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '127.7000000000000000',
      high: '127.8400000000000000',
      low: '123.0700000000000000',
      close: '125.0900000000000000',
      volume: '224856.9553900000000000',
      quoteVolume: 28126021.072964624,
      btcVolume: 3897.9462094468076,
      usdVolume: 28126021.072964624,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '125.0900000000000000',
      high: '132.2600000000000000',
      low: '124.3200000000000000',
      close: '131.1800000000000000',
      volume: '271991.3960900000000000',
      quoteVolume: 34556187.22649918,
      btcVolume: 4757.447100368064,
      usdVolume: 34556187.22649918,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '125.5800000000000000',
      high: '127.1000000000000000',
      low: '121.9100000000000000',
      close: '126.2900000000000000',
      volume: '239584.0186300000000000',
      quoteVolume: 29967207.36584079,
      btcVolume: 4162.919074511555,
      usdVolume: 29967207.36584079,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '126.2800000000000000',
      high: '129.6800000000000000',
      low: '125.8400000000000000',
      close: '128.1100000000000000',
      volume: '196893.5227700000000000',
      quoteVolume: 25177121.147064537,
      btcVolume: 3441.3052732034603,
      usdVolume: 25177121.147064537,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '128.1100000000000000',
      high: '138.0700000000000000',
      low: '127.5200000000000000',
      close: '134.3600000000000000',
      volume: '316304.0786500000000000',
      quoteVolume: 41887251.75431606,
      btcVolume: 5666.2955116504645,
      usdVolume: 41887251.75431606,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '134.3600000000000000',
      high: '136.2400000000000000',
      low: '130.3000000000000000',
      close: '131.5900000000000000',
      volume: '320328.9778500000000000',
      quoteVolume: 42634148.31351888,
      btcVolume: 5822.900412418773,
      usdVolume: 42634148.31351888,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '131.6100000000000000',
      high: '133.6800000000000000',
      low: '128.1700000000000000',
      close: '129.1600000000000000',
      volume: '264902.2166100000000000',
      quoteVolume: 34674502.08294672,
      btcVolume: 4798.822256382279,
      usdVolume: 34674502.08294672,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '129.1600000000000000',
      high: '133.0500000000000000',
      low: '128.6800000000000000',
      close: '130.7700000000000000',
      volume: '144766.0171600000000000',
      quoteVolume: 18951727.853203688,
      btcVolume: 2626.058687621114,
      usdVolume: 18951727.853203688,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '130.7200000000000000',
      high: '130.7800000000000000',
      low: '126.3800000000000000',
      close: '127.1900000000000000',
      volume: '206486.6936400000000000',
      quoteVolume: 26562586.64793831,
      btcVolume: 3748.1292562505037,
      usdVolume: 26562586.64793831,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '127.1900000000000000',
      high: '135.1400000000000000',
      low: '125.8800000000000000',
      close: '134.3500000000000000',
      volume: '412941.3612600000000000',
      quoteVolume: 54124562.00994656,
      btcVolume: 7477.56431390865,
      usdVolume: 54124562.00994656,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '134.3700000000000000',
      high: '135.8500000000000000',
      low: '132.5000000000000000',
      close: '134.2000000000000000',
      volume: '184276.1710200000000000',
      quoteVolume: 24641135.568952836,
      btcVolume: 3361.8386052854335,
      usdVolume: 24641135.568952836,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '134.2000000000000000',
      high: '138.1900000000000000',
      low: '134.1900000000000000',
      close: '135.3700000000000000',
      volume: '254120.4534300000000000',
      quoteVolume: 34593687.60327518,
      btcVolume: 4653.251278572446,
      usdVolume: 34593687.60327518,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '135.3700000000000000',
      high: '144.4100000000000000',
      low: '134.8600000000000000',
      close: '144.1500000000000000',
      volume: '408020.2737500000000000',
      quoteVolume: 57400544.487255365,
      btcVolume: 7600.492701267486,
      usdVolume: 57400544.487255365,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '144.1400000000000000',
      high: '145.3100000000000000',
      low: '138.7600000000000000',
      close: '142.8000000000000000',
      volume: '447762.1728100000000000',
      quoteVolume: 63861666.4047595,
      btcVolume: 8049.865532289283,
      usdVolume: 63861666.4047595,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '142.8000000000000000',
      high: '147.7700000000000000',
      low: '137.0300000000000000',
      close: '140.7200000000000000',
      volume: '561419.2059200000000000',
      quoteVolume: 79913004.38781014,
      btcVolume: 9694.962625535327,
      usdVolume: 79913004.38781014,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '140.7600000000000000',
      high: '141.5000000000000000',
      low: '135.3000000000000000',
      close: '137.7400000000000000',
      volume: '363987.6637700000000000',
      quoteVolume: 50319731.13866686,
      btcVolume: 6374.095494904485,
      usdVolume: 50319731.13866686,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '137.7300000000000000',
      high: '145.1700000000000000',
      low: '135.3200000000000000',
      close: '144.8400000000000000',
      volume: '409400.9174100000000000',
      quoteVolume: 57377160.97009285,
      btcVolume: 7232.247618277078,
      usdVolume: 57377160.97009285,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '144.8300000000000000',
      high: '148.0500000000000000',
      low: '142.0900000000000000',
      close: '142.3800000000000000',
      volume: '368350.5793900000000000',
      quoteVolume: 53149360.56174742,
      btcVolume: 6540.115802381793,
      usdVolume: 53149360.56174742,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '142.4000000000000000',
      high: '146.6000000000000000',
      low: '141.7600000000000000',
      close: '146.5400000000000000',
      volume: '229541.8613700000000000',
      quoteVolume: 33112850.822234996,
      btcVolume: 4081.84868926777,
      usdVolume: 33112850.822234996,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '146.5600000000000000',
      high: '147.0000000000000000',
      low: '142.2700000000000000',
      close: '143.5800000000000000',
      volume: '207971.5884600000000000',
      quoteVolume: 29884467.67111847,
      btcVolume: 3683.879733305947,
      usdVolume: 29884467.67111847,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '143.5800000000000000',
      high: '171.7000000000000000',
      low: '143.5100000000000000',
      close: '165.6400000000000000',
      volume: '1108476.3118600000000000',
      quoteVolume: 173492006.24820682,
      btcVolume: 20115.096219225106,
      usdVolume: 173492006.24820682,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '165.6000000000000000',
      high: '171.9800000000000000',
      low: '159.2000000000000000',
      close: '166.4000000000000000',
      volume: '721560.7995600000000000',
      quoteVolume: 119219193.04167666,
      btcVolume: 13597.93692389315,
      usdVolume: 119219193.04167666,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '166.4000000000000000',
      high: '167.4000000000000000',
      low: '157.8000000000000000',
      close: '164.2100000000000000',
      volume: '456170.8671900000000000',
      quoteVolume: 74038650.82913166,
      btcVolume: 8518.447924957838,
      usdVolume: 74038650.82913166,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '164.2400000000000000',
      high: '174.8100000000000000',
      low: '162.1400000000000000',
      close: '169.8500000000000000',
      volume: '767180.6785300000000000',
      quoteVolume: 129909265.25615731,
      btcVolume: 14632.362690520036,
      usdVolume: 129909265.25615731,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '169.9200000000000000',
      high: '179.5000000000000000',
      low: '164.9200000000000000',
      close: '174.1400000000000000',
      volume: '688748.6367600000000000',
      quoteVolume: 119382479.69063364,
      btcVolume: 13404.09413255703,
      usdVolume: 119382479.69063364,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '174.1000000000000000',
      high: '178.0500000000000000',
      low: '161.6600000000000000',
      close: '166.7900000000000000',
      volume: '624670.6336700000000000',
      quoteVolume: 105689492.0033365,
      btcVolume: 12002.351244357744,
      usdVolume: 105689492.0033365,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '166.7900000000000000',
      high: '169.3300000000000000',
      low: '161.2400000000000000',
      close: '166.8700000000000000',
      volume: '358092.8841000000000000',
      quoteVolume: 59292534.71746608,
      btcVolume: 6852.199304969695,
      usdVolume: 59292534.71746608,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '166.8600000000000000',
      high: '170.3200000000000000',
      low: '164.8000000000000000',
      close: '169.4900000000000000',
      volume: '308007.6353000000000000',
      quoteVolume: 51615232.56698999,
      btcVolume: 5961.4179039502005,
      usdVolume: 51615232.56698999,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '169.4800000000000000',
      high: '171.4700000000000000',
      low: '166.0300000000000000',
      close: '168.0700000000000000',
      volume: '272240.9028600000000000',
      quoteVolume: 45903134.16291278,
      btcVolume: 5277.281438195617,
      usdVolume: 45903134.16291278,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '168.0700000000000000',
      high: '168.2000000000000000',
      low: '159.2100000000000000',
      close: '162.8100000000000000',
      volume: '373414.3498500000000000',
      quoteVolume: 60971659.77458279,
      btcVolume: 7198.282444848286,
      usdVolume: 60971659.77458279,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '162.8500000000000000',
      high: '164.4500000000000000',
      low: '155.5500000000000000',
      close: '162.5400000000000000',
      volume: '430013.1990200000000000',
      quoteVolume: 68980724.06049524,
      btcVolume: 8218.743302588302,
      usdVolume: 68980724.06049524,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '162.5100000000000000',
      high: '162.7900000000000000',
      low: '157.6100000000000000',
      close: '160.3500000000000000',
      volume: '219921.6519700000000000',
      quoteVolume: 35196608.30633701,
      btcVolume: 4216.84914612778,
      usdVolume: 35196608.30633701,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '160.3600000000000000',
      high: '168.0800000000000000',
      low: '159.4100000000000000',
      close: '167.8600000000000000',
      volume: '251582.5575800000000000',
      quoteVolume: 41239031.33790531,
      btcVolume: 4864.643562453211,
      usdVolume: 41239031.33790531,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '167.9100000000000000',
      high: '172.5600000000000000',
      low: '165.2200000000000000',
      close: '170.0800000000000000',
      volume: '365894.8191700000000000',
      quoteVolume: 61846624.61453907,
      btcVolume: 7067.9493571795865,
      usdVolume: 61846624.61453907,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '170.0400000000000000',
      high: '176.2000000000000000',
      low: '170.0300000000000000',
      close: '175.6400000000000000',
      volume: '473433.8960900000000000',
      quoteVolume: 81649497.49294704,
      btcVolume: 9000.09542777262,
      usdVolume: 81649497.49294704,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '175.5800000000000000',
      high: '178.4500000000000000',
      low: '173.3300000000000000',
      close: '173.7200000000000000',
      volume: '317366.2058000000000000',
      quoteVolume: 55985259.09291722,
      btcVolume: 5993.288480446213,
      usdVolume: 55985259.09291722,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '173.6800000000000000',
      high: '187.0000000000000000',
      low: '170.9300000000000000',
      close: '184.6900000000000000',
      volume: '477721.6609000000000000',
      quoteVolume: 85164074.08231418,
      btcVolume: 9048.137874415446,
      usdVolume: 85164074.08231418,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '184.7100000000000000',
      high: '185.8200000000000000',
      low: '175.2200000000000000',
      close: '179.9900000000000000',
      volume: '385596.5336500000000000',
      quoteVolume: 69633052.28263958,
      btcVolume: 7449.645096830591,
      usdVolume: 69633052.28263958,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '179.9400000000000000',
      high: '184.2800000000000000',
      low: '179.2300000000000000',
      close: '183.6000000000000000',
      volume: '259370.1290200000000000',
      quoteVolume: 47263178.06678158,
      btcVolume: 5035.1164574204295,
      usdVolume: 47263178.06678158,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '183.6300000000000000',
      high: '193.4300000000000000',
      low: '179.1000000000000000',
      close: '188.4400000000000000',
      volume: '552621.1361900000000000',
      quoteVolume: 104105613.55878504,
      btcVolume: 11088.57195048023,
      usdVolume: 104105613.55878504,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '188.4800000000000000',
      high: '195.1900000000000000',
      low: '186.6200000000000000',
      close: '189.6900000000000000',
      volume: '417175.9578100000000000',
      quoteVolume: 79336312.5246926,
      btcVolume: 8471.442558236018,
      usdVolume: 79336312.5246926,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '189.7400000000000000',
      high: '191.6000000000000000',
      low: '184.6900000000000000',
      close: '188.9100000000000000',
      volume: '366267.8454800000000000',
      quoteVolume: 68697714.9065747,
      btcVolume: 7449.885768860561,
      usdVolume: 68697714.9065747,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '188.9100000000000000',
      high: '207.6100000000000000',
      low: '188.1900000000000000',
      close: '203.7800000000000000',
      volume: '550942.1141700000000000',
      quoteVolume: 108659358.53591804,
      btcVolume: 11498.225576271325,
      usdVolume: 108659358.53591804,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '203.7800000000000000',
      high: '216.3300000000000000',
      low: '201.0200000000000000',
      close: '213.1900000000000000',
      volume: '602040.0810800000000000',
      quoteVolume: 126577112.92357521,
      btcVolume: 13029.657093734239,
      usdVolume: 126577112.92357521,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '213.1600000000000000',
      high: '225.0000000000000000',
      low: '213.1400000000000000',
      close: '223.3300000000000000',
      volume: '628858.0077800000000000',
      quoteVolume: 138414055.77483606,
      btcVolume: 14123.858774287371,
      usdVolume: 138414055.77483606,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '223.3600000000000000',
      high: '227.7500000000000000',
      low: '213.2200000000000000',
      close: '223.0500000000000000',
      volume: '548551.8746500000000000',
      quoteVolume: 122069507.65510991,
      btcVolume: 12459.442096719877,
      usdVolume: 122069507.65510991,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '223.0100000000000000',
      high: '230.6500000000000000',
      low: '222.8600000000000000',
      close: '228.4900000000000000',
      volume: '350221.2019800000000000',
      quoteVolume: 79648275.7579095,
      btcVolume: 7906.719601131233,
      usdVolume: 79648275.7579095,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '228.5300000000000000',
      high: '229.4000000000000000',
      low: '216.3700000000000000',
      close: '222.8900000000000000',
      volume: '510415.4994900000000000',
      quoteVolume: 113385288.95029798,
      btcVolume: 11438.000262952832,
      usdVolume: 113385288.95029798,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '222.8900000000000000',
      high: '239.1500000000000000',
      low: '218.1700000000000000',
      close: '236.6900000000000000',
      volume: '595576.9027600000000000',
      quoteVolume: 135863928.62430608,
      btcVolume: 13545.116057370578,
      usdVolume: 135863928.62430608,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '236.6900000000000000',
      high: '275.3400000000000000',
      low: '236.6900000000000000',
      close: '265.7400000000000000',
      volume: '1038073.7478200000000000',
      quoteVolume: 266734296.55029422,
      btcVolume: 25827.16221092207,
      usdVolume: 266734296.55029422,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '265.7400000000000000',
      high: '277.6900000000000000',
      low: '256.0800000000000000',
      close: '268.3200000000000000',
      volume: '1083763.8066500000000000',
      quoteVolume: 289993412.75714827,
      btcVolume: 28204.52124006715,
      usdVolume: 289993412.75714827,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '268.3400000000000000',
      high: '287.1500000000000000',
      low: '260.2800000000000000',
      close: '285.1500000000000000',
      volume: '734944.3226600000000000',
      quoteVolume: 201120424.58321652,
      btcVolume: 19585.77364231225,
      usdVolume: 201120424.58321652,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '285.1100000000000000',
      high: '288.4100000000000000',
      low: '261.8600000000000000',
      close: '264.8800000000000000',
      volume: '860767.3711600000000000',
      quoteVolume: 236274810.63895243,
      btcVolume: 23452.950884857593,
      usdVolume: 236274810.63895243,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '264.9100000000000000',
      high: '274.0000000000000000',
      low: '237.4100000000000000',
      close: '258.8500000000000000',
      volume: '1110118.4639500000000000',
      quoteVolume: 286269166.28143066,
      btcVolume: 29048.30557248655,
      usdVolume: 286269166.28143066,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '258.8900000000000000',
      high: '268.7700000000000000',
      low: '242.0000000000000000',
      close: '267.8500000000000000',
      volume: '1110371.3909400000000000',
      quoteVolume: 280371301.7549912,
      btcVolume: 28929.875955319196,
      usdVolume: 280371301.7549912,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '267.9000000000000000',
      high: '285.8800000000000000',
      low: '258.0000000000000000',
      close: '282.6100000000000000',
      volume: '1115523.4399200000000000',
      quoteVolume: 304162263.3061434,
      btcVolume: 30935.19354943302,
      usdVolume: 304162263.3061434,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '282.6400000000000000',
      high: '285.0000000000000000',
      low: '251.5600000000000000',
      close: '258.4500000000000000',
      volume: '702700.0948200000000000',
      quoteVolume: 190968563.2271233,
      btcVolume: 19191.632547085086,
      usdVolume: 190968563.2271233,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '258.4400000000000000',
      high: '264.3300000000000000',
      low: '245.3400000000000000',
      close: '256.9600000000000000',
      volume: '972629.9034800000000000',
      quoteVolume: 249295948.92492777,
      btcVolume: 26026.85890519547,
      usdVolume: 249295948.92492777,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '256.9700000000000000',
      high: '268.2400000000000000',
      low: '253.6100000000000000',
      close: '265.2700000000000000',
      volume: '524737.1325800000000000',
      quoteVolume: 137561519.8080567,
      btcVolume: 14215.99915940384,
      usdVolume: 137561519.8080567,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '265.3200000000000000',
      high: '266.8100000000000000',
      low: '256.0000000000000000',
      close: '261.5700000000000000',
      volume: '313062.5213300000000000',
      quoteVolume: 81781388.31935476,
      btcVolume: 8483.868403005552,
      usdVolume: 81781388.31935476,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '261.5500000000000000',
      high: '275.6800000000000000',
      low: '261.0200000000000000',
      close: '274.4800000000000000',
      volume: '444740.8288300000000000',
      quoteVolume: 120024058.97196795,
      btcVolume: 12171.335680224975,
      usdVolume: 120024058.97196795,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '274.5000000000000000',
      high: '277.2000000000000000',
      low: '257.0900000000000000',
      close: '265.5200000000000000',
      volume: '696591.7298300000000000',
      quoteVolume: 185949182.70858055,
      btcVolume: 19137.450555293988,
      usdVolume: 185949182.70858055,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '265.4700000000000000',
      high: '266.2200000000000000',
      low: '244.4400000000000000',
      close: '246.6700000000000000',
      volume: '774791.0102700000000000',
      quoteVolume: 197442911.5447298,
      btcVolume: 20862.28626803828,
      usdVolume: 197442911.5447298,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '246.6700000000000000',
      high: '250.3200000000000000',
      low: '215.6600000000000000',
      close: '223.9300000000000000',
      volume: '1390938.8858200000000000',
      quoteVolume: 323053459.4097742,
      btcVolume: 35807.633154278985,
      usdVolume: 323053459.4097742,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '223.9800000000000000',
      high: '238.3000000000000000',
      low: '210.0000000000000000',
      close: '227.7900000000000000',
      volume: '1273621.5787700000000000',
      quoteVolume: 287283849.70437545,
      btcVolume: 32695.90481354741,
      usdVolume: 287283849.70437545,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '227.7300000000000000',
      high: '234.6700000000000000',
      low: '214.0100000000000000',
      close: '226.7600000000000000',
      volume: '1054994.9239700000000000',
      quoteVolume: 236064804.33459535,
      btcVolume: 27236.336241981913,
      usdVolume: 236064804.33459535,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '226.7600000000000000',
      high: '233.0000000000000000',
      low: '217.0000000000000000',
      close: '217.2100000000000000',
      volume: '546866.6851000000000000',
      quoteVolume: 123635801.01782297,
      btcVolume: 14249.24078788142,
      usdVolume: 123635801.01782297,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '217.2900000000000000',
      high: '227.8900000000000000',
      low: '212.3600000000000000',
      close: '217.8100000000000000',
      volume: '715016.0194100000000000',
      quoteVolume: 157064953.84227648,
      btcVolume: 18346.386497742988,
      usdVolume: 157064953.84227648,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '217.8100000000000000',
      high: '234.4000000000000000',
      low: '216.0700000000000000',
      close: '231.9700000000000000',
      volume: '810051.4833000000000000',
      quoteVolume: 182948651.67645854,
      btcVolume: 20891.507673847358,
      usdVolume: 182948651.67645854,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '232.1000000000000000',
      high: '232.4600000000000000',
      low: '219.5700000000000000',
      close: '223.9100000000000000',
      volume: '741498.5482500000000000',
      quoteVolume: 168090325.5387952,
      btcVolume: 19140.228688685915,
      usdVolume: 168090325.5387952,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '223.8400000000000000',
      high: '228.8500000000000000',
      low: '220.2300000000000000',
      close: '224.2600000000000000',
      volume: '443780.3377200000000000',
      quoteVolume: 99650220.54162118,
      btcVolume: 11371.931756177451,
      usdVolume: 99650220.54162118,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '224.2600000000000000',
      high: '234.0900000000000000',
      low: '224.2300000000000000',
      close: '228.3800000000000000',
      volume: '601434.2612800000000000',
      quoteVolume: 138071496.98683217,
      btcVolume: 15332.125743401275,
      usdVolume: 138071496.98683217,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '228.3800000000000000',
      high: '245.1600000000000000',
      low: '227.3300000000000000',
      close: '244.8800000000000000',
      volume: '628147.3257000000000000',
      quoteVolume: 148563741.83299482,
      btcVolume: 16336.054719609947,
      usdVolume: 148563741.83299482,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '244.9300000000000000',
      high: '251.9300000000000000',
      low: '236.0000000000000000',
      close: '237.2300000000000000',
      volume: '633748.8966200000000000',
      quoteVolume: 154037325.61130953,
      btcVolume: 17062.989655657668,
      usdVolume: 154037325.61130953,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '237.2300000000000000',
      high: '237.2300000000000000',
      low: '195.5000000000000000',
      close: '199.4300000000000000',
      volume: '1278973.5374100000000000',
      quoteVolume: 277970187.86825454,
      btcVolume: 32927.24759153153,
      usdVolume: 277970187.86825454,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '199.4300000000000000',
      high: '208.6200000000000000',
      low: '190.0000000000000000',
      close: '202.8100000000000000',
      volume: '1661331.8355300000000000',
      quoteVolume: 332570408.53516084,
      btcVolume: 42278.225193090606,
      usdVolume: 332570408.53516084,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '202.7900000000000000',
      high: '206.2000000000000000',
      low: '195.5400000000000000',
      close: '200.7000000000000000',
      volume: '1020260.1070000000000000',
      quoteVolume: 205536190.19393793,
      btcVolume: 25870.47287880342,
      usdVolume: 205536190.19393793,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '200.7400000000000000',
      high: '203.1800000000000000',
      low: '181.7300000000000000',
      close: '194.6100000000000000',
      volume: '1079824.9016700000000000',
      quoteVolume: 208232375.1861964,
      btcVolume: 26676.44911775151,
      usdVolume: 208232375.1861964,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '194.6100000000000000',
      high: '195.5500000000000000',
      low: '101.2000000000000000',
      close: '107.8200000000000000',
      volume: '3487974.4724600000000000',
      quoteVolume: 502735483.19587886,
      btcVolume: 79582.69602720514,
      usdVolume: 502735483.19587886,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '107.6700000000000000',
      high: '139.6800000000000000',
      low: '86.0000000000000000',
      close: '134.0600000000000000',
      volume: '4352096.1180700000000000',
      quoteVolume: 524165020.32676804,
      btcVolume: 102228.2183345659,
      usdVolume: 524165020.32676804,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '134.0600000000000000',
      high: '134.6800000000000000',
      low: '120.0000000000000000',
      close: '122.5400000000000000',
      volume: '1237626.0682200000000000',
      quoteVolume: 159186518.38922155,
      btcVolume: 29588.5952380688,
      usdVolume: 159186518.38922155,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '122.5400000000000000',
      high: '133.5000000000000000',
      low: '120.1600000000000000',
      close: '123.7800000000000000',
      volume: '1312950.9013700000000000',
      quoteVolume: 163436235.26908636,
      btcVolume: 30443.399792973287,
      usdVolume: 163436235.26908636,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '123.8200000000000000',
      high: '124.3300000000000000',
      low: '101.1000000000000000',
      close: '111.0200000000000000',
      volume: '2442064.1444800000000000',
      quoteVolume: 270353891.80499524,
      btcVolume: 55152.042843550655,
      usdVolume: 270353891.80499524,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '111.0200000000000000',
      high: '120.8000000000000000',
      low: '109.3800000000000000',
      close: '115.6700000000000000',
      volume: '1295108.4848000000000000',
      quoteVolume: 150800664.07195225,
      btcVolume: 28587.190421365343,
      usdVolume: 150800664.07195225,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '115.6700000000000000',
      high: '118.5000000000000000',
      low: '109.8600000000000000',
      close: '118.1100000000000000',
      volume: '1201850.3127500000000000',
      quoteVolume: 137550912.08551753,
      btcVolume: 26224.964726877704,
      usdVolume: 137550912.08551753,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '118.1100000000000000',
      high: '143.1300000000000000',
      low: '115.4600000000000000',
      close: '136.3000000000000000',
      volume: '1801071.1880800000000000',
      quoteVolume: 231626928.1512321,
      btcVolume: 39606.47361637073,
      usdVolume: 231626928.1512321,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '136.3000000000000000',
      high: '152.5500000000000000',
      low: '116.7400000000000000',
      close: '133.4800000000000000',
      volume: '2504057.6379900000000000',
      quoteVolume: 345856006.74780256,
      btcVolume: 54225.66525238545,
      usdVolume: 345856006.74780256,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '133.4900000000000000',
      high: '137.4900000000000000',
      low: '125.6900000000000000',
      close: '132.6600000000000000',
      volume: '1231842.1112100000000000',
      quoteVolume: 162468451.77231285,
      btcVolume: 26347.20683720244,
      usdVolume: 162468451.77231285,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '132.6300000000000000',
      high: '137.1300000000000000',
      low: '121.1000000000000000',
      close: '122.3200000000000000',
      volume: '1055699.8653200000000000',
      quoteVolume: 135927941.07952216,
      btcVolume: 22332.10691388855,
      usdVolume: 135927941.07952216,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '122.3100000000000000',
      high: '137.5000000000000000',
      low: '119.5000000000000000',
      close: '135.9200000000000000',
      volume: '1436796.1244900000000000',
      quoteVolume: 184851609.12426114,
      btcVolume: 30054.31381726867,
      usdVolume: 184851609.12426114,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '135.8900000000000000',
      high: '143.3600000000000000',
      low: '132.2000000000000000',
      close: '138.4200000000000000',
      volume: '1244377.1633400000000000',
      quoteVolume: 171156363.69229996,
      btcVolume: 25793.645485705532,
      usdVolume: 171156363.69229996,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '138.4200000000000000',
      high: '142.3300000000000000',
      low: '132.5400000000000000',
      close: '136.1000000000000000',
      volume: '1034248.7436900000000000',
      quoteVolume: 141795323.0268692,
      btcVolume: 21290.647624377056,
      usdVolume: 141795323.0268692,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '136.1100000000000000',
      high: '139.7400000000000000',
      low: '133.2600000000000000',
      close: '138.4000000000000000',
      volume: '750975.5504600000000000',
      quoteVolume: 101907167.70116763,
      btcVolume: 15297.871335099853,
      usdVolume: 101907167.70116763,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '138.4000000000000000',
      high: '141.2700000000000000',
      low: '129.6800000000000000',
      close: '131.3300000000000000',
      volume: '706948.5455900000000000',
      quoteVolume: 96581886.87894869,
      btcVolume: 14496.8359010212,
      usdVolume: 96581886.87894869,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '131.3300000000000000',
      high: '132.9700000000000000',
      low: '124.8800000000000000',
      close: '130.9800000000000000',
      volume: '896032.1059400000000000',
      quoteVolume: 115166651.97007346,
      btcVolume: 18545.39550175307,
      usdVolume: 115166651.97007346,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '130.9400000000000000',
      high: '131.8400000000000000',
      low: '123.8100000000000000',
      close: '124.5000000000000000',
      volume: '543997.0000000000000000',
      quoteVolume: 69434202.20547193,
      btcVolume: 11449.693329595028,
      usdVolume: 69434202.20547193,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '124.4700000000000000',
      high: '135.4200000000000000',
      low: '124.0600000000000000',
      close: '132.0600000000000000',
      volume: '760403.4785400000000000',
      quoteVolume: 99677889.71604659,
      btcVolume: 15885.98378555245,
      usdVolume: 99677889.71604659,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '132.0900000000000000',
      high: '135.0000000000000000',
      low: '130.3800000000000000',
      close: '132.7200000000000000',
      volume: '547529.2537800000000000',
      quoteVolume: 72599600.32278414,
      btcVolume: 11270.645004277229,
      usdVolume: 72599600.32278414,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '132.7200000000000000',
      high: '136.9900000000000000',
      low: '128.6200000000000000',
      close: '135.6900000000000000',
      volume: '680643.4807700000000000',
      quoteVolume: 90027178.09661904,
      btcVolume: 14172.192723006221,
      usdVolume: 90027178.09661904,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '135.6500000000000000',
      high: '149.7600000000000000',
      low: '135.0100000000000000',
      close: '141.2800000000000000',
      volume: '1138977.6611400000000000',
      quoteVolume: 159989506.60494053,
      btcVolume: 23529.02931639409,
      usdVolume: 159989506.60494053,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '141.2700000000000000',
      high: '146.7200000000000000',
      low: '137.2600000000000000',
      close: '141.3100000000000000',
      volume: '797546.1144600000000000',
      quoteVolume: 113731706.71500106,
      btcVolume: 16666.063640760167,
      usdVolume: 113731706.71500106,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '141.3000000000000000',
      high: '146.2800000000000000',
      low: '138.8800000000000000',
      close: '144.1000000000000000',
      volume: '573508.1408600000000000',
      quoteVolume: 81640622.82843257,
      btcVolume: 12045.428630419647,
      usdVolume: 81640622.82843257,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '144.1000000000000000',
      high: '145.5800000000000000',
      low: '140.6800000000000000',
      close: '142.6800000000000000',
      volume: '414987.4524600000000000',
      quoteVolume: 59383946.127442546,
      btcVolume: 8751.783787499042,
      usdVolume: 59383946.127442546,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '142.6900000000000000',
      high: '172.0000000000000000',
      low: '142.6000000000000000',
      close: '171.3200000000000000',
      volume: '1636338.7311000000000000',
      quoteVolume: 257097597.7838751,
      btcVolume: 36146.37040615942,
      usdVolume: 257097597.7838751,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '171.3200000000000000',
      high: '176.2200000000000000',
      low: '162.2200000000000000',
      close: '164.6300000000000000',
      volume: '1717496.7435600000000000',
      quoteVolume: 292630399.0504515,
      btcVolume: 40022.062364601465,
      usdVolume: 292630399.0504515,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '164.6200000000000000',
      high: '174.4800000000000000',
      low: '163.5900000000000000',
      close: '173.1100000000000000',
      volume: '1023575.9207600000000000',
      quoteVolume: 173916484.78615347,
      btcVolume: 23819.17844363877,
      usdVolume: 173916484.78615347,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '173.1500000000000000',
      high: '173.3800000000000000',
      low: '165.1900000000000000',
      close: '169.5200000000000000',
      volume: '719837.2442300000000000',
      quoteVolume: 122531131.95595776,
      btcVolume: 16818.265725807498,
      usdVolume: 122531131.95595776,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '169.5600000000000000',
      high: '170.1000000000000000',
      low: '152.1100000000000000',
      close: '157.8100000000000000',
      volume: '1314841.3620000000000000',
      quoteVolume: 209535613.32368094,
      btcVolume: 30105.99545258993,
      usdVolume: 209535613.32368094,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '157.8100000000000000',
      high: '161.3700000000000000',
      low: '153.9100000000000000',
      close: '158.3300000000000000',
      volume: '621599.6128900000000000',
      quoteVolume: 98275533.06263469,
      btcVolume: 14322.52534224828,
      usdVolume: 98275533.06263469,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '158.3300000000000000',
      high: '164.7800000000000000',
      low: '155.0000000000000000',
      close: '158.5900000000000000',
      volume: '809097.1317400000000000',
      quoteVolume: 129919434.5212399,
      btcVolume: 18622.065967622548,
      usdVolume: 129919434.5212399,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '158.5600000000000000',
      high: '158.9700000000000000',
      low: '150.0000000000000000',
      close: '156.2900000000000000',
      volume: '1042091.0362200000000000',
      quoteVolume: 160423270.30371547,
      btcVolume: 23810.704478929125,
      usdVolume: 160423270.30371547,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '156.2800000000000000',
      high: '161.8500000000000000',
      low: '155.2300000000000000',
      close: '158.4500000000000000',
      volume: '698030.6508900000000000',
      quoteVolume: 110710086.10400799,
      btcVolume: 16092.175363956325,
      usdVolume: 110710086.10400799,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '158.4600000000000000',
      high: '161.2900000000000000',
      low: '152.0000000000000000',
      close: '152.7300000000000000',
      volume: '729119.6650500000000000',
      quoteVolume: 114640840.11551383,
      btcVolume: 16907.325021412682,
      usdVolume: 114640840.11551383,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '152.7400000000000000',
      high: '174.7900000000000000',
      low: '148.3300000000000000',
      close: '172.2900000000000000',
      volume: '1647207.5999100000000000',
      quoteVolume: 273639160.06938905,
      btcVolume: 39469.80302466689,
      usdVolume: 273639160.06938905,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '172.3100000000000000',
      high: '174.9600000000000000',
      low: '168.3100000000000000',
      close: '170.6900000000000000',
      volume: '723537.7559100000000000',
      quoteVolume: 123553456.31967713,
      btcVolume: 17477.09337208831,
      usdVolume: 123553456.31967713,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '170.6100000000000000',
      high: '189.5400000000000000',
      low: '170.4800000000000000',
      close: '187.4000000000000000',
      volume: '1127296.3697900000000000',
      quoteVolume: 203132211.95640972,
      btcVolume: 28322.990109666654,
      usdVolume: 203132211.95640972,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '187.4000000000000000',
      high: '188.3500000000000000',
      low: '175.7500000000000000',
      close: '180.0300000000000000',
      volume: '995759.5775000000000000',
      quoteVolume: 181415813.50269327,
      btcVolume: 25353.375195221084,
      usdVolume: 181415813.50269327,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '180.0200000000000000',
      high: '186.4600000000000000',
      low: '166.7000000000000000',
      close: '170.2000000000000000',
      volume: '1547821.5273700000000000',
      quoteVolume: 273854539.8037984,
      btcVolume: 39135.19214462897,
      usdVolume: 273854539.8037984,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '170.2100000000000000',
      high: '174.7000000000000000',
      low: '168.0000000000000000',
      close: '170.7400000000000000',
      volume: '906251.0337800000000000',
      quoteVolume: 155269608.04007408,
      btcVolume: 22667.419297755732,
      usdVolume: 155269608.04007408,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '170.7300000000000000',
      high: '184.0000000000000000',
      low: '169.7800000000000000',
      close: '182.7000000000000000',
      volume: '988227.0746000000000000',
      quoteVolume: 175998292.09734583,
      btcVolume: 25096.17505566981,
      usdVolume: 175998292.09734583,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '182.7000000000000000',
      high: '193.6300000000000000',
      low: '178.1600000000000000',
      close: '185.5100000000000000',
      volume: '1457546.9509400000000000',
      quoteVolume: 270803059.6644047,
      btcVolume: 36995.15878859557,
      usdVolume: 270803059.6644047,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '185.5700000000000000',
      high: '189.8000000000000000',
      low: '184.7200000000000000',
      close: '187.5900000000000000',
      volume: '780904.2125700000000000',
      quoteVolume: 146419748.08164915,
      btcVolume: 19487.960270951102,
      usdVolume: 146419748.08164915,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '187.6000000000000000',
      high: '198.3600000000000000',
      low: '185.7600000000000000',
      close: '194.2100000000000000',
      volume: '866478.0492800000000000',
      quoteVolume: 167703202.42505187,
      btcVolume: 22213.786528375527,
      usdVolume: 167703202.42505187,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '194.2100000000000000',
      high: '200.0000000000000000',
      low: '192.3000000000000000',
      close: '197.3800000000000000',
      volume: '758525.8153300000000000',
      quoteVolume: 148433709.777186,
      btcVolume: 19517.662353427906,
      usdVolume: 148433709.777186,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '197.3900000000000000',
      high: '199.0500000000000000',
      low: '189.2900000000000000',
      close: '196.3400000000000000',
      volume: '896627.3727100000000000',
      quoteVolume: 174775637.92727718,
      btcVolume: 22703.242326359323,
      usdVolume: 174775637.92727718,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '196.3300000000000000',
      high: '198.5000000000000000',
      low: '192.3600000000000000',
      close: '196.2400000000000000',
      volume: '636829.8939700000000000',
      quoteVolume: 124476041.88938437,
      btcVolume: 16122.852361506426,
      usdVolume: 124476041.88938437,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '196.2400000000000000',
      high: '218.7700000000000000',
      low: '196.2200000000000000',
      close: '215.3600000000000000',
      volume: '1620422.7243100000000000',
      quoteVolume: 338324593.63727975,
      btcVolume: 40923.435434255334,
      usdVolume: 338324593.63727975,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '215.4000000000000000',
      high: '227.3000000000000000',
      low: '202.0000000000000000',
      close: '206.0800000000000000',
      volume: '1749436.3115300000000000',
      quoteVolume: 374836060.0037704,
      btcVolume: 42031.33369272022,
      usdVolume: 374836060.0037704,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '206.0700000000000000',
      high: '217.1400000000000000',
      low: '205.9100000000000000',
      close: '212.0200000000000000',
      volume: '794913.9582800000000000',
      quoteVolume: 168339123.3579177,
      btcVolume: 19142.43801029207,
      usdVolume: 168339123.3579177,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '212.0100000000000000',
      high: '215.3700000000000000',
      low: '210.3600000000000000',
      close: '213.9000000000000000',
      volume: '443494.6724800000000000',
      quoteVolume: 94389501.51781647,
      btcVolume: 10636.541506688323,
      usdVolume: 94389501.51781647,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '213.8900000000000000',
      high: '219.3000000000000000',
      low: '203.9200000000000000',
      close: '209.8600000000000000',
      volume: '748383.9131100000000000',
      quoteVolume: 158874887.0783394,
      btcVolume: 17742.09175499337,
      usdVolume: 158874887.0783394,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '209.8600000000000000',
      high: '210.6500000000000000',
      low: '194.8800000000000000',
      close: '206.4800000000000000',
      volume: '1114249.3265800000000000',
      quoteVolume: 225451167.65536752,
      btcVolume: 25813.68579734503,
      usdVolume: 225451167.65536752,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '206.4700000000000000',
      high: '211.6800000000000000',
      low: '201.0000000000000000',
      close: '205.2900000000000000',
      volume: '640644.6626800000000000',
      quoteVolume: 131906204.90781972,
      btcVolume: 14781.073403266748,
      usdVolume: 131906204.90781972,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '205.3000000000000000',
      high: '210.7700000000000000',
      low: '197.7100000000000000',
      close: '198.9700000000000000',
      volume: '862700.6784900000000000',
      quoteVolume: 178157452.60084617,
      btcVolume: 19446.63985121468,
      usdVolume: 178157452.60084617,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '198.9200000000000000',
      high: '215.1000000000000000',
      low: '196.4300000000000000',
      close: '212.0000000000000000',
      volume: '1305778.7376400000000000',
      quoteVolume: 271197927.6650283,
      btcVolume: 28416.436328368578,
      usdVolume: 271197927.6650283,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '212.0100000000000000',
      high: '216.6100000000000000',
      low: '206.9000000000000000',
      close: '211.3100000000000000',
      volume: '917908.1944000000000000',
      quoteVolume: 194579529.42776698,
      btcVolume: 19629.460850922827,
      usdVolume: 194579529.42776698,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '211.3200000000000000',
      high: '214.6000000000000000',
      low: '208.4000000000000000',
      close: '210.0700000000000000',
      volume: '587871.9807300000000000',
      quoteVolume: 124626471.42703621,
      btcVolume: 12822.779287709614,
      usdVolume: 124626471.42703621,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '210.0900000000000000',
      high: '210.1800000000000000',
      low: '179.5900000000000000',
      close: '187.5400000000000000',
      volume: '1473226.0547500000000000',
      quoteVolume: 278394290.7597449,
      btcVolume: 32142.471378080594,
      usdVolume: 278394290.7597449,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '187.6200000000000000',
      high: '193.5000000000000000',
      low: '176.0000000000000000',
      close: '185.7300000000000000',
      volume: '1093299.8192700000000000',
      quoteVolume: 203391530.206183,
      btcVolume: 23422.170849671944,
      usdVolume: 203391530.206183,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '185.8000000000000000',
      high: '192.2400000000000000',
      low: '185.4800000000000000',
      close: '189.7600000000000000',
      volume: '669755.7956700000000000',
      quoteVolume: 126810812.16817188,
      btcVolume: 14463.158714611134,
      usdVolume: 126810812.16817188,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '189.7700000000000000',
      high: '201.0000000000000000',
      low: '188.3500000000000000',
      close: '199.5800000000000000',
      volume: '805809.8298700000000000',
      quoteVolume: 156573908.52186683,
      btcVolume: 17267.02579964432,
      usdVolume: 156573908.52186683,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '199.6100000000000000',
      high: '206.0200000000000000',
      low: '195.6700000000000000',
      close: '203.2900000000000000',
      volume: '1114700.2493700000000000',
      quoteVolume: 224233786.12671238,
      btcVolume: 23321.873078992805,
      usdVolume: 224233786.12671238,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '203.2900000000000000',
      high: '204.0800000000000000',
      low: '191.6600000000000000',
      close: '194.8600000000000000',
      volume: '904749.6357000000000000',
      quoteVolume: 179634085.6227652,
      btcVolume: 18891.585288550705,
      usdVolume: 179634085.6227652,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '194.8700000000000000',
      high: '203.5000000000000000',
      low: '193.2100000000000000',
      close: '200.4500000000000000',
      volume: '669900.0375400000000000',
      quoteVolume: 134060788.49418302,
      btcVolume: 14238.268494091166,
      usdVolume: 134060788.49418302,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '200.4400000000000000',
      high: '209.8500000000000000',
      low: '199.6800000000000000',
      close: '207.0000000000000000',
      volume: '708656.1209600000000000',
      quoteVolume: 145330053.95795897,
      btcVolume: 15038.595482450506,
      usdVolume: 145330053.95795897,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '207.0200000000000000',
      high: '217.0700000000000000',
      low: '207.0000000000000000',
      close: '214.9100000000000000',
      volume: '1064118.4969700000000000',
      quoteVolume: 226784577.1006466,
      btcVolume: 23254.32875270356,
      usdVolume: 226784577.1006466,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '214.9200000000000000',
      high: '215.9500000000000000',
      low: '209.4500000000000000',
      close: '214.5800000000000000',
      volume: '643702.9884900000000000',
      quoteVolume: 136846948.55680534,
      btcVolume: 14141.16094939455,
      usdVolume: 136846948.55680534,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '214.6000000000000000',
      high: '215.7500000000000000',
      low: '204.2700000000000000',
      close: '209.9800000000000000',
      volume: '679532.6103700000000000',
      quoteVolume: 143704806.17524174,
      btcVolume: 14897.556828334138,
      usdVolume: 143704806.17524174,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '209.9900000000000000',
      high: '211.6400000000000000',
      low: '191.7100000000000000',
      close: '198.7400000000000000',
      volume: '1003923.4342000000000000',
      quoteVolume: 203445710.96284306,
      btcVolume: 22082.32268408724,
      usdVolume: 203445710.96284306,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '198.7200000000000000',
      high: '209.1900000000000000',
      low: '196.2400000000000000',
      close: '207.3600000000000000',
      volume: '787672.3608100000000000',
      quoteVolume: 159972608.5341703,
      btcVolume: 17518.762923166818,
      usdVolume: 159972608.5341703,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '207.3600000000000000',
      high: '211.2000000000000000',
      low: '204.5500000000000000',
      close: '206.6000000000000000',
      volume: '478543.5112000000000000',
      quoteVolume: 99457681.15441823,
      btcVolume: 10806.153666981252,
      usdVolume: 99457681.15441823,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '206.6000000000000000',
      high: '210.5500000000000000',
      low: '199.5000000000000000',
      close: '199.9000000000000000',
      volume: '673364.5911000000000000',
      quoteVolume: 138882881.96251076,
      btcVolume: 15299.402726933584,
      usdVolume: 138882881.96251076,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '199.9000000000000000',
      high: '205.6700000000000000',
      low: '198.0100000000000000',
      close: '204.1500000000000000',
      volume: '469931.6249200000000000',
      quoteVolume: 95411610.79763587,
      btcVolume: 10833.988909859245,
      usdVolume: 95411610.79763587,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '204.2200000000000000',
      high: '205.0000000000000000',
      low: '196.9000000000000000',
      close: '200.8900000000000000',
      volume: '477176.7353000000000000',
      quoteVolume: 96116808.85809049,
      btcVolume: 10865.809286287838,
      usdVolume: 96116808.85809049,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '200.9200000000000000',
      high: '208.4400000000000000',
      low: '200.7600000000000000',
      close: '208.2900000000000000',
      volume: '494816.7272700000000000',
      quoteVolume: 101445351.02566375,
      btcVolume: 11223.077448084685,
      usdVolume: 101445351.02566375,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '208.2900000000000000',
      high: '220.5000000000000000',
      low: '204.6700000000000000',
      close: '220.2100000000000000',
      volume: '643945.9776900000000000',
      quoteVolume: 136361627.34641564,
      btcVolume: 14529.09885208273,
      usdVolume: 136361627.34641564,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '220.2400000000000000',
      high: '224.7900000000000000',
      low: '217.5700000000000000',
      close: '220.6700000000000000',
      volume: '602955.9267100000000000',
      quoteVolume: 133197594.260007,
      btcVolume: 14054.00894376058,
      usdVolume: 133197594.260007,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '220.6300000000000000',
      high: '247.0000000000000000',
      low: '218.8000000000000000',
      close: '243.6600000000000000',
      volume: '1019550.7514600000000000',
      quoteVolume: 238678839.809296,
      btcVolume: 25044.601085406517,
      usdVolume: 238678839.809296,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '243.7100000000000000',
      high: '245.3900000000000000',
      low: '229.5800000000000000',
      close: '231.5700000000000000',
      volume: '821608.6888400000000000',
      quoteVolume: 194867426.2941861,
      btcVolume: 20410.587835015256,
      usdVolume: 194867426.2941861,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '231.5600000000000000',
      high: '250.6800000000000000',
      low: '230.6600000000000000',
      close: '248.2300000000000000',
      volume: '823816.6993600000000000',
      quoteVolume: 198791911.84704918,
      btcVolume: 20496.02670912183,
      usdVolume: 198791911.84704918,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '248.2300000000000000',
      high: '253.8000000000000000',
      low: '225.0000000000000000',
      close: '237.7100000000000000',
      volume: '955520.9186300000000000',
      quoteVolume: 230680327.30929545,
      btcVolume: 23537.399570541293,
      usdVolume: 230680327.30929545,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '237.7100000000000000',
      high: '245.0000000000000000',
      low: '232.7100000000000000',
      close: '244.5200000000000000',
      volume: '502928.1337100000000000',
      quoteVolume: 120179301.17898554,
      btcVolume: 12578.74569388963,
      usdVolume: 120179301.17898554,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '244.5400000000000000',
      high: '246.6000000000000000',
      low: '236.0000000000000000',
      close: '243.2100000000000000',
      volume: '669369.3392000000000000',
      quoteVolume: 162460397.62767565,
      btcVolume: 16776.170878664434,
      usdVolume: 162460397.62767565,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '243.2200000000000000',
      high: '247.8700000000000000',
      low: '239.1400000000000000',
      close: '240.0300000000000000',
      volume: '502468.8571600000000000',
      quoteVolume: 122065135.39008076,
      btcVolume: 12523.278719135755,
      usdVolume: 122065135.39008076,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '240.0400000000000000',
      high: '244.7000000000000000',
      low: '238.1600000000000000',
      close: '241.9400000000000000',
      volume: '366776.8953700000000000',
      quoteVolume: 88523230.03653955,
      btcVolume: 9183.854988193434,
      usdVolume: 88523230.03653955,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '241.9400000000000000',
      high: '245.3700000000000000',
      low: '234.6000000000000000',
      close: '244.5600000000000000',
      volume: '571102.5551500000000000',
      quoteVolume: 137165732.64761737,
      btcVolume: 14264.71365491363,
      usdVolume: 137165732.64761737,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '244.5700000000000000',
      high: '247.7000000000000000',
      low: '240.5900000000000000',
      close: '246.4000000000000000',
      volume: '383116.5471900000000000',
      quoteVolume: 93355224.96830915,
      btcVolume: 9598.855468043803,
      usdVolume: 93355224.96830915,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '246.3700000000000000',
      high: '249.8200000000000000',
      low: '238.0000000000000000',
      close: '243.8000000000000000',
      volume: '419967.8054400000000000',
      quoteVolume: 102287184.99320976,
      btcVolume: 10528.431478674393,
      usdVolume: 102287184.99320976,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '243.7900000000000000',
      high: '250.2800000000000000',
      low: '242.0000000000000000',
      close: '247.7800000000000000',
      volume: '431285.6171500000000000',
      quoteVolume: 105797248.24742587,
      btcVolume: 10771.848889298295,
      usdVolume: 105797248.24742587,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '247.7800000000000000',
      high: '250.0900000000000000',
      low: '226.2000000000000000',
      close: '230.5100000000000000',
      volume: '815431.7796500000000000',
      quoteVolume: 194825983.3024181,
      btcVolume: 20371.297467425575,
      usdVolume: 194825983.3024181,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '230.4600000000000000',
      high: '239.3800000000000000',
      low: '228.1900000000000000',
      close: '237.5500000000000000',
      volume: '444488.9407200000000000',
      quoteVolume: 104599402.02950011,
      btcVolume: 11109.617756002966,
      usdVolume: 104599402.02950011,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '237.5500000000000000',
      high: '238.7300000000000000',
      low: '234.6600000000000000',
      close: '238.1100000000000000',
      volume: '253797.7809000000000000',
      quoteVolume: 60087772.65246506,
      btcVolume: 6372.817903545626,
      usdVolume: 60087772.65246506,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '238.1300000000000000',
      high: '238.5900000000000000',
      low: '229.8300000000000000',
      close: '231.7700000000000000',
      volume: '304943.2118800000000000',
      quoteVolume: 71575514.08027163,
      btcVolume: 7622.81941591543,
      usdVolume: 71575514.08027163,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '231.7400000000000000',
      high: '233.5500000000000000',
      low: '218.2700000000000000',
      close: '230.8600000000000000',
      volume: '875026.9205700000000000',
      quoteVolume: 197907504.77877796,
      btcVolume: 21476.01503478616,
      usdVolume: 197907504.77877796,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '230.8600000000000000',
      high: '236.0000000000000000',
      low: '228.7000000000000000',
      close: '235.3000000000000000',
      volume: '499194.6675800000000000',
      quoteVolume: 116456364.2312674,
      btcVolume: 12259.734211678307,
      usdVolume: 116456364.2312674,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '235.3100000000000000',
      high: '237.3000000000000000',
      low: '227.6500000000000000',
      close: '233.8200000000000000',
      volume: '478187.2917000000000000',
      quoteVolume: 111415059.05626224,
      btcVolume: 11798.305623152255,
      usdVolume: 111415059.05626224,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '233.8200000000000000',
      high: '234.6500000000000000',
      low: '227.9300000000000000',
      close: '231.2700000000000000',
      volume: '334347.7991700000000000',
      quoteVolume: 77542070.33327071,
      btcVolume: 8244.094026212786,
      usdVolume: 77542070.33327071,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '231.2600000000000000',
      high: '231.9500000000000000',
      low: '226.7400000000000000',
      close: '229.0000000000000000',
      volume: '397284.5980800000000000',
      quoteVolume: 91117706.15513879,
      btcVolume: 9760.152539929302,
      usdVolume: 91117706.15513879,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '229.0000000000000000',
      high: '230.8500000000000000',
      low: '225.6200000000000000',
      close: '228.8900000000000000',
      volume: '303704.9882600000000000',
      quoteVolume: 69343699.90954724,
      btcVolume: 7455.57113620382,
      usdVolume: 69343699.90954724,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '228.8800000000000000',
      high: '231.4900000000000000',
      low: '227.1100000000000000',
      close: '228.0400000000000000',
      volume: '256305.2373400000000000',
      quoteVolume: 58868166.506103784,
      btcVolume: 6293.000295933788,
      usdVolume: 58868166.506103784,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '228.0300000000000000',
      high: '246.8900000000000000',
      low: '227.5100000000000000',
      close: '243.1300000000000000',
      volume: '748759.3895600000000000',
      quoteVolume: 178643659.67329305,
      btcVolume: 18825.751197784553,
      usdVolume: 178643659.67329305,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '243.1100000000000000',
      high: '244.8700000000000000',
      low: '240.7500000000000000',
      close: '243.1400000000000000',
      volume: '442226.8735200000000000',
      quoteVolume: 107440173.5377359,
      btcVolume: 11144.411233446323,
      usdVolume: 107440173.5377359,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '243.1500000000000000',
      high: '249.2400000000000000',
      low: '231.0700000000000000',
      close: '234.5800000000000000',
      volume: '921634.0534000000000000',
      quoteVolume: 220763040.43374506,
      btcVolume: 23383.216137878488,
      usdVolume: 220763040.43374506,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '234.5700000000000000',
      high: '235.3900000000000000',
      low: '227.5600000000000000',
      close: '232.5000000000000000',
      volume: '613748.5934800000000000',
      quoteVolume: 142697463.3801034,
      btcVolume: 15489.816263370116,
      usdVolume: 142697463.3801034,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '232.4900000000000000',
      high: '233.6100000000000000',
      low: '227.7500000000000000',
      close: '229.6400000000000000',
      volume: '628340.0700700000000000',
      quoteVolume: 144805505.1915821,
      btcVolume: 15783.082372432726,
      usdVolume: 144805505.1915821,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '229.6600000000000000',
      high: '231.4200000000000000',
      low: '216.0000000000000000',
      close: '220.9400000000000000',
      volume: '645950.6143800000000000',
      quoteVolume: 145264632.26381838,
      btcVolume: 16009.140095513289,
      usdVolume: 145264632.26381838,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '220.9400000000000000',
      high: '227.9600000000000000',
      low: '218.3200000000000000',
      close: '224.9300000000000000',
      volume: '444766.5688100000000000',
      quoteVolume: 99423540.13931867,
      btcVolume: 10949.038056790398,
      usdVolume: 99423540.13931867,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '224.8900000000000000',
      high: '229.9600000000000000',
      low: '221.2600000000000000',
      close: '227.9300000000000000',
      volume: '597915.5224200000000000',
      quoteVolume: 134510531.19766667,
      btcVolume: 14743.622729629511,
      usdVolume: 134510531.19766667,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '227.9800000000000000',
      high: '228.7000000000000000',
      low: '222.9100000000000000',
      close: '225.6000000000000000',
      volume: '415036.0297700000000000',
      quoteVolume: 93802207.27357335,
      btcVolume: 10255.185172332418,
      usdVolume: 93802207.27357335,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '225.5900000000000000',
      high: '232.7100000000000000',
      low: '224.1600000000000000',
      close: '230.9900000000000000',
      volume: '515968.3174700000000000',
      quoteVolume: 118009144.95166142,
      btcVolume: 12823.681622799983,
      usdVolume: 118009144.95166142,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '230.9900000000000000',
      high: '232.0100000000000000',
      low: '222.8700000000000000',
      close: '226.4600000000000000',
      volume: '592485.9111500000000000',
      quoteVolume: 134976975.12319767,
      btcVolume: 14752.25945808237,
      usdVolume: 134976975.12319767,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '226.4600000000000000',
      high: '228.1300000000000000',
      low: '223.9700000000000000',
      close: '224.8700000000000000',
      volume: '312108.6060700000000000',
      quoteVolume: 70729994.77950838,
      btcVolume: 7780.293748705394,
      usdVolume: 70729994.77950838,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '224.8600000000000000',
      high: '230.7100000000000000',
      low: '224.4300000000000000',
      close: '229.1700000000000000',
      volume: '298119.6003300000000000',
      quoteVolume: 67726803.92678858,
      btcVolume: 7439.9741424165295,
      usdVolume: 67726803.92678858,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '229.2400000000000000',
      high: '229.8500000000000000',
      low: '223.0500000000000000',
      close: '227.5600000000000000',
      volume: '341994.9329000000000000',
      quoteVolume: 77425409.00913155,
      btcVolume: 8548.264489930476,
      usdVolume: 77425409.00913155,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '227.5400000000000000',
      high: '242.0000000000000000',
      low: '226.9300000000000000',
      close: '241.6000000000000000',
      volume: '864817.5070000000000000',
      quoteVolume: 203334727.850139,
      btcVolume: 22028.523967262292,
      usdVolume: 203334727.850139,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '241.6000000000000000',
      high: '243.7700000000000000',
      low: '234.4000000000000000',
      close: '239.3900000000000000',
      volume: '600378.4220900000000000',
      quoteVolume: 143111734.11362022,
      btcVolume: 15434.282792689764,
      usdVolume: 143111734.11362022,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '239.3900000000000000',
      high: '248.8800000000000000',
      low: '237.7800000000000000',
      close: '246.9500000000000000',
      volume: '942434.0782300000000000',
      quoteVolume: 229569778.54287842,
      btcVolume: 24561.674657966098,
      usdVolume: 229569778.54287842,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '246.9400000000000000',
      high: '247.9000000000000000',
      low: '237.8800000000000000',
      close: '241.9800000000000000',
      volume: '647641.5829100000000000',
      quoteVolume: 157541370.0048171,
      btcVolume: 16903.849735123644,
      usdVolume: 157541370.0048171,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '241.9700000000000000',
      high: '242.1600000000000000',
      low: '235.7200000000000000',
      close: '241.2600000000000000',
      volume: '455025.1238500000000000',
      quoteVolume: 108806462.4650961,
      btcVolume: 11835.149454014792,
      usdVolume: 108806462.4650961,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '241.2700000000000000',
      high: '241.6700000000000000',
      low: '237.5400000000000000',
      close: '239.1900000000000000',
      volume: '290546.6408300000000000',
      quoteVolume: 69603089.08903724,
      btcVolume: 7535.268541585202,
      usdVolume: 69603089.08903724,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '239.1900000000000000',
      high: '244.0000000000000000',
      low: '236.5200000000000000',
      close: '242.7900000000000000',
      volume: '469315.1172000000000000',
      quoteVolume: 112487552.43401246,
      btcVolume: 12159.372376119501,
      usdVolume: 112487552.43401246,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '242.7600000000000000',
      high: '245.3300000000000000',
      low: '237.3300000000000000',
      close: '239.5000000000000000',
      volume: '604093.0424500000000000',
      quoteVolume: 146193291.91067657,
      btcVolume: 15758.109658099816,
      usdVolume: 146193291.91067657,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '239.5000000000000000',
      high: '242.0900000000000000',
      low: '237.0000000000000000',
      close: '240.4400000000000000',
      volume: '426320.8918800000000000',
      quoteVolume: 102105027.13192993,
      btcVolume: 11077.23641948493,
      usdVolume: 102105027.13192993,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '240.4500000000000000',
      high: '241.4500000000000000',
      low: '236.8300000000000000',
      close: '238.6100000000000000',
      volume: '368350.6651800000000000',
      quoteVolume: 88059682.48343667,
      btcVolume: 9547.404137094758,
      usdVolume: 88059682.48343667,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '238.5900000000000000',
      high: '239.3900000000000000',
      low: '230.0200000000000000',
      close: '233.5700000000000000',
      volume: '587161.7389400000000000',
      quoteVolume: 137208323.20157194,
      btcVolume: 15019.41735536055,
      usdVolume: 137208323.20157194,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '233.5800000000000000',
      high: '234.8400000000000000',
      low: '231.6500000000000000',
      close: '232.6700000000000000',
      volume: '325579.4645700000000000',
      quoteVolume: 75926217.2310008,
      btcVolume: 8310.273922367218,
      usdVolume: 75926217.2310008,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '232.6600000000000000',
      high: '236.8000000000000000',
      low: '232.3200000000000000',
      close: '235.6700000000000000',
      volume: '341356.6182000000000000',
      quoteVolume: 80046747.8322333,
      btcVolume: 8738.120886553355,
      usdVolume: 80046747.8322333,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '235.6700000000000000',
      high: '239.9200000000000000',
      low: '233.2800000000000000',
      close: '239.1100000000000000',
      volume: '265225.5624400000000000',
      quoteVolume: 62547101.402666144,
      btcVolume: 6826.036997576792,
      usdVolume: 62547101.402666144,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '239.1200000000000000',
      high: '239.7200000000000000',
      low: '234.0500000000000000',
      close: '236.0100000000000000',
      volume: '396657.9193800000000000',
      quoteVolume: 94314378.93819806,
      btcVolume: 10278.861999376948,
      usdVolume: 94314378.93819806,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '236.0000000000000000',
      high: '246.6600000000000000',
      low: '235.5700000000000000',
      close: '245.5900000000000000',
      volume: '637462.0807400000000000',
      quoteVolume: 154502240.94120297,
      btcVolume: 16568.240820505314,
      usdVolume: 154502240.94120297,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '245.5300000000000000',
      high: '269.6100000000000000',
      low: '241.5100000000000000',
      close: '263.7400000000000000',
      volume: '718412.8694500000000000',
      quoteVolume: 180691409.64491135,
      btcVolume: 19223.102848907532,
      usdVolume: 180691409.64491135,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '263.7500000000000000',
      high: '279.9800000000000000',
      low: '259.7000000000000000',
      close: '275.3000000000000000',
      volume: '1068774.5787900000000000',
      quoteVolume: 287217202.72011954,
      btcVolume: 30130.661822095655,
      usdVolume: 287217202.72011954,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '275.3100000000000000',
      high: '287.3400000000000000',
      low: '266.0000000000000000',
      close: '279.1500000000000000',
      volume: '926033.0669200000000000',
      quoteVolume: 256347245.54814723,
      btcVolume: 26844.91878187144,
      usdVolume: 256347245.54814723,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '279.1500000000000000',
      high: '308.9400000000000000',
      low: '0.0000102300000000',
      close: '305.2600000000000000',
      volume: '1060891.1375400000000000',
      quoteVolume: 308799629.3812334,
      btcVolume: 32052.82286372341,
      usdVolume: 308799821.8866399,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '305.3100000000000000',
      high: '318.7500000000000000',
      low: '299.0000000000000000',
      close: '311.0000000000000000',
      volume: '1270368.7530000000000000',
      quoteVolume: 392346895.32266885,
      btcVolume: 39870.04525589428,
      usdVolume: 392346895.32266885,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '311.0200000000000000',
      high: '333.3100000000000000',
      low: '310.7800000000000000',
      close: '321.9300000000000000',
      volume: '1636122.3472700000000000',
      quoteVolume: 527292799.2879079,
      btcVolume: 50537.47976051564,
      usdVolume: 527292799.2879079,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '321.9500000000000000',
      high: '326.8000000000000000',
      low: '305.8600000000000000',
      close: '316.7700000000000000',
      volume: '1093231.5557800000000000',
      quoteVolume: 346187853.2759322,
      btcVolume: 31616.78758360037,
      usdVolume: 346187853.2759322,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '316.7700000000000000',
      high: '325.0000000000000000',
      low: '312.3800000000000000',
      close: '317.7100000000000000',
      volume: '792802.3214800000000000',
      quoteVolume: 254201662.13340423,
      btcVolume: 22934.294000855425,
      usdVolume: 254201662.13340423,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '317.7600000000000000',
      high: '342.0000000000000000',
      low: '314.0500000000000000',
      close: '334.8700000000000000',
      volume: '1008827.2143400000000000',
      quoteVolume: 328286340.23770994,
      btcVolume: 29714.76886977982,
      usdVolume: 328286340.23770994,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '334.8200000000000000',
      high: '349.4500000000000000',
      low: '328.4000000000000000',
      close: '346.3300000000000000',
      volume: '948088.4498200000000000',
      quoteVolume: 323282981.0159161,
      btcVolume: 28866.335039593676,
      usdVolume: 323282981.0159161,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '346.3200000000000000',
      high: '392.6700000000000000',
      low: '343.0700000000000000',
      close: '387.0000000000000000',
      volume: '1440843.3420300000000000',
      quoteVolume: 527543444.8628902,
      btcVolume: 45339.60061159785,
      usdVolume: 527543444.8628902,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '387.0100000000000000',
      high: '415.8500000000000000',
      low: '325.0000000000000000',
      close: '372.4200000000000000',
      volume: '2324909.0961600000000000',
      quoteVolume: 878397036.2975318,
      btcVolume: 77495.83814159164,
      usdVolume: 878397036.2975318,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '372.4500000000000000',
      high: '398.0000000000000000',
      low: '366.5000000000000000',
      close: '385.9400000000000000',
      volume: '1191565.0938900000000000',
      quoteVolume: 462170587.4784976,
      btcVolume: 41057.234921476615,
      usdVolume: 462170587.4784976,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '385.9100000000000000',
      high: '402.9800000000000000',
      low: '380.7700000000000000',
      close: '389.6100000000000000',
      volume: '1175763.9381400000000000',
      quoteVolume: 459835053.5956598,
      btcVolume: 40921.10256553506,
      usdVolume: 459835053.5956598,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '389.5900000000000000',
      high: '407.0000000000000000',
      low: '384.1100000000000000',
      close: '400.7200000000000000',
      volume: '1124119.0672000000000000',
      quoteVolume: 444335097.1493966,
      btcVolume: 38666.919386438094,
      usdVolume: 444335097.1493966,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '400.7200000000000000',
      high: '403.6600000000000000',
      low: '390.5100000000000000',
      close: '394.7700000000000000',
      volume: '821379.0839700000000000',
      quoteVolume: 325718417.71600866,
      btcVolume: 27726.769672481845,
      usdVolume: 325718417.71600866,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '394.7700000000000000',
      high: '398.2600000000000000',
      low: '362.8500000000000000',
      close: '379.3400000000000000',
      volume: '1041207.3594600000000000',
      quoteVolume: 398625567.06390804,
      btcVolume: 34262.12382888949,
      usdVolume: 398625567.06390804,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '379.3400000000000000',
      high: '397.0500000000000000',
      low: '375.7900000000000000',
      close: '397.0300000000000000',
      volume: '623013.8133600000000000',
      quoteVolume: 240992993.07174557,
      btcVolume: 20611.4249741265,
      usdVolume: 240992993.07174557,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '397.0500000000000000',
      high: '400.0000000000000000',
      low: '383.3600000000000000',
      close: '390.1400000000000000',
      volume: '594729.4803400000000000',
      quoteVolume: 232989976.98507214,
      btcVolume: 19961.97802262133,
      usdVolume: 232989976.98507214,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '390.1400000000000000',
      high: '399.1800000000000000',
      low: '385.1300000000000000',
      close: '395.4400000000000000',
      volume: '826969.9063300000000000',
      quoteVolume: 326372434.89705575,
      btcVolume: 27443.329738405846,
      usdVolume: 326372434.89705575,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '395.4300000000000000',
      high: '398.2100000000000000',
      low: '367.0000000000000000',
      close: '379.0000000000000000',
      volume: '992412.6023000000000000',
      quoteVolume: 381981614.62167376,
      btcVolume: 33030.351758669196,
      usdVolume: 381981614.62167376,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '378.9900000000000000',
      high: '389.2300000000000000',
      low: '365.8500000000000000',
      close: '387.1800000000000000',
      volume: '751848.7983000000000000',
      quoteVolume: 285872467.64409924,
      btcVolume: 24976.58134909489,
      usdVolume: 285872467.64409924,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '387.1800000000000000',
      high: '431.4900000000000000',
      low: '376.5900000000000000',
      close: '424.5400000000000000',
      volume: '1511330.7732000000000000',
      quoteVolume: 604810975.1318486,
      btcVolume: 52246.13470100553,
      usdVolume: 604810975.1318486,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '424.5400000000000000',
      high: '444.7100000000000000',
      low: '417.0500000000000000',
      close: '438.1100000000000000',
      volume: '1108919.1175900000000000',
      quoteVolume: 476850417.99412924,
      btcVolume: 40596.08524064946,
      usdVolume: 476850417.99412924,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '438.1200000000000000',
      high: '442.9400000000000000',
      low: '427.0200000000000000',
      close: '432.3200000000000000',
      volume: '681696.2610000000000000',
      quoteVolume: 296651951.35869735,
      btcVolume: 25006.734408894456,
      usdVolume: 296651951.35869735,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '432.3000000000000000',
      high: '436.9900000000000000',
      low: '412.4700000000000000',
      close: '433.6200000000000000',
      volume: '716666.2525800000000000',
      quoteVolume: 305445885.8166684,
      btcVolume: 25821.942518111297,
      usdVolume: 305445885.8166684,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '433.6100000000000000',
      high: '446.0000000000000000',
      low: '420.3700000000000000',
      close: '430.8900000000000000',
      volume: '868870.6499600000000000',
      quoteVolume: 375038999.50752,
      btcVolume: 31000.024384319247,
      usdVolume: 375038999.50752,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '430.8900000000000000',
      high: '433.8100000000000000',
      low: '415.0000000000000000',
      close: '421.9500000000000000',
      volume: '743254.5701800000000000',
      quoteVolume: 316635286.99291563,
      btcVolume: 26116.737005652198,
      usdVolume: 316635286.99291563,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '421.9200000000000000',
      high: '426.7500000000000000',
      low: '393.2500000000000000',
      close: '407.4600000000000000',
      volume: '978339.6922100000000000',
      quoteVolume: 400208683.34672534,
      btcVolume: 34018.89009102192,
      usdVolume: 400208683.34672534,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '407.4600000000000000',
      high: '418.9800000000000000',
      low: '402.0700000000000000',
      close: '415.9400000000000000',
      volume: '660964.6963900000000000',
      quoteVolume: 272240068.376272,
      btcVolume: 23069.824029488518,
      usdVolume: 272240068.376272,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '415.9400000000000000',
      high: '418.6800000000000000',
      low: '385.1000000000000000',
      close: '387.9700000000000000',
      volume: '860092.2392900000000000',
      quoteVolume: 347090880.07555234,
      btcVolume: 29626.18363494332,
      usdVolume: 347090880.07555234,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '387.9300000000000000',
      high: '397.0400000000000000',
      low: '379.9200000000000000',
      close: '395.2400000000000000',
      volume: '643112.7567000000000000',
      quoteVolume: 250021920.7245382,
      btcVolume: 21657.375885407164,
      usdVolume: 250021920.7245382,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '395.2300000000000000',
      high: '396.9500000000000000',
      low: '383.1500000000000000',
      close: '390.7100000000000000',
      volume: '445307.5880200000000000',
      quoteVolume: 173795952.99000394,
      btcVolume: 14968.181091353075,
      usdVolume: 173795952.99000394,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '390.7200000000000000',
      high: '410.4200000000000000',
      low: '387.4400000000000000',
      close: '407.6800000000000000',
      volume: '717395.9721000000000000',
      quoteVolume: 287988643.53621656,
      btcVolume: 24526.709155711666,
      usdVolume: 287988643.53621656,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '407.6800000000000000',
      high: '409.0600000000000000',
      low: '370.0000000000000000',
      close: '382.9300000000000000',
      volume: '951557.2886700000000000',
      quoteVolume: 368309918.2113772,
      btcVolume: 32162.02308140377,
      usdVolume: 368309918.2113772,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '382.9100000000000000',
      high: '392.9100000000000000',
      low: '377.3700000000000000',
      close: '385.8100000000000000',
      volume: '738590.5295000000000000',
      quoteVolume: 284465412.4634799,
      btcVolume: 24953.561629989617,
      usdVolume: 284465412.4634799,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '385.8100000000000000',
      high: '397.1000000000000000',
      low: '371.4800000000000000',
      close: '383.2700000000000000',
      volume: '876931.1946000000000000',
      quoteVolume: 336328075.9025886,
      btcVolume: 29644.310136701326,
      usdVolume: 336328075.9025886,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '383.3100000000000000',
      high: '398.0000000000000000',
      low: '379.7500000000000000',
      close: '395.1100000000000000',
      volume: '721730.6497200000000000',
      quoteVolume: 282252408.9354685,
      btcVolume: 24674.28459629944,
      usdVolume: 282252408.9354685,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '395.1000000000000000',
      high: '405.6900000000000000',
      low: '391.8800000000000000',
      close: '398.6500000000000000',
      volume: '654320.0035500000000000',
      quoteVolume: 261582840.97993016,
      btcVolume: 22739.15544880467,
      usdVolume: 261582840.97993016,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '398.6500000000000000',
      high: '429.8900000000000000',
      low: '398.3000000000000000',
      close: '428.9400000000000000',
      volume: '851160.6742400000000000',
      quoteVolume: 352311003.28142095,
      btcVolume: 30325.394571282835,
      usdVolume: 352311003.28142095,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '428.9400000000000000',
      high: '439.0000000000000000',
      low: '418.0400000000000000',
      close: '433.7900000000000000',
      volume: '1040748.6888100000000000',
      quoteVolume: 447866996.185111,
      btcVolume: 38337.44332463666,
      usdVolume: 447866996.185111,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '433.8000000000000000',
      high: '488.8400000000000000',
      low: '429.0300000000000000',
      close: '475.4000000000000000',
      volume: '1656066.8494900000000000',
      quoteVolume: 769006775.5385326,
      btcVolume: 64803.214479942595,
      usdVolume: 768959484.5333103,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '475.4000000000000000',
      high: '481.3900000000000000',
      low: '420.0000000000000000',
      close: '439.5000000000000000',
      volume: '1876373.4429600000000000',
      quoteVolume: 842455901.235392,
      btcVolume: 73163.14575846263,
      usdVolume: 842455901.235392,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '439.5000000000000000',
      high: '450.7000000000000000',
      low: '372.0000000000000000',
      close: '381.4000000000000000',
      volume: '2402627.0353300000000000',
      quoteVolume: 993081852.551086,
      btcVolume: 90931.01288632085,
      usdVolume: 993081852.551086,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '381.3500000000000000',
      high: '400.2100000000000000',
      low: '357.0000000000000000',
      close: '385.2600000000000000',
      volume: '1806487.9639800000000000',
      quoteVolume: 696658830.8205959,
      btcVolume: 67364.86665822042,
      usdVolume: 696658830.8205959,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '385.3000000000000000',
      high: '395.0000000000000000',
      low: '308.4200000000000000',
      close: '335.0100000000000000',
      volume: '2776774.5031000000000000',
      quoteVolume: 967581076.4469733,
      btcVolume: 94990.08545339752,
      usdVolume: 967581076.4469733,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '335.0400000000000000',
      high: '360.0000000000000000',
      low: '316.1000000000000000',
      close: '352.8000000000000000',
      volume: '2017811.1472100000000000',
      quoteVolume: 691925087.3968136,
      btcVolume: 67832.96092299436,
      usdVolume: 691925087.3968136,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '352.8500000000000000',
      high: '358.5100000000000000',
      low: '323.0000000000000000',
      close: '353.5900000000000000',
      volume: '1721408.5783500000000000',
      quoteVolume: 590110812.7562342,
      btcVolume: 58285.20441211165,
      usdVolume: 590110812.7562342,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '353.5900000000000000',
      high: '356.5800000000000000',
      low: '325.3800000000000000',
      close: '337.3900000000000000',
      volume: '1400246.8296200000000000',
      quoteVolume: 476444912.54462713,
      btcVolume: 47124.00188261758,
      usdVolume: 476444912.54462713,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '337.4100000000000000',
      high: '359.0000000000000000',
      low: '330.5100000000000000',
      close: '350.8500000000000000',
      volume: '879760.5625500000000000',
      quoteVolume: 304910633.80089056,
      btcVolume: 29909.92428361022,
      usdVolume: 304910633.80089056,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '350.8500000000000000',
      high: '377.2500000000000000',
      low: '349.9100000000000000',
      close: '367.9300000000000000',
      volume: '1134065.2960700000000000',
      quoteVolume: 416517016.27456033,
      btcVolume: 40287.65628581293,
      usdVolume: 416517016.27456033,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '367.9300000000000000',
      high: '375.2700000000000000',
      low: '355.4900000000000000',
      close: '373.6300000000000000',
      volume: '844017.5996600000000000',
      quoteVolume: 308822715.13365656,
      btcVolume: 29997.26435090535,
      usdVolume: 308822715.13365656,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '373.6200000000000000',
      high: '388.5700000000000000',
      low: '364.7300000000000000',
      close: '387.7800000000000000',
      volume: '766542.3163500000000000',
      quoteVolume: 287133871.2472935,
      btcVolume: 27668.664342038548,
      usdVolume: 287133871.2472935,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '387.7800000000000000',
      high: '390.4300000000000000',
      low: '350.0000000000000000',
      close: '366.4200000000000000',
      volume: '989195.6904700000000000',
      quoteVolume: 367969518.5134864,
      btcVolume: 35461.65474002583,
      usdVolume: 367969518.5134864,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '366.4300000000000000',
      high: '384.6400000000000000',
      low: '355.7300000000000000',
      close: '377.1200000000000000',
      volume: '1042059.6226500000000000',
      quoteVolume: 387238794.68096745,
      btcVolume: 36789.98529463114,
      usdVolume: 387238794.68096745,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '377.1200000000000000',
      high: '382.4700000000000000',
      low: '362.3400000000000000',
      close: '364.0600000000000000',
      volume: '883856.3300500000000000',
      quoteVolume: 328317009.5683414,
      btcVolume: 30470.16739104851,
      usdVolume: 328317009.5683414,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '364.0500000000000000',
      high: '373.4500000000000000',
      low: '355.0000000000000000',
      close: '365.1100000000000000',
      volume: '841709.9893900000000000',
      quoteVolume: 306622238.0715463,
      btcVolume: 28177.270136204377,
      usdVolume: 306622238.0715463,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '365.0400000000000000',
      high: '394.1400000000000000',
      low: '363.3000000000000000',
      close: '389.3600000000000000',
      volume: '1256300.4110000000000000',
      quoteVolume: 479026710.896617,
      btcVolume: 43950.597949227995,
      usdVolume: 479026710.896617,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '389.3500000000000000',
      high: '392.5800000000000000',
      low: '375.3300000000000000',
      close: '384.3900000000000000',
      volume: '804194.4721900000000000',
      quoteVolume: 308748579.81603116,
      btcVolume: 28281.960169687176,
      usdVolume: 308748579.81603116,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '384.4000000000000000',
      high: '388.7200000000000000',
      low: '377.0000000000000000',
      close: '385.2500000000000000',
      volume: '570908.3355300000000000',
      quoteVolume: 218785730.14017949,
      btcVolume: 19860.826040587017,
      usdVolume: 218785730.14017949,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '385.2500000000000000',
      high: '385.4500000000000000',
      low: '365.0000000000000000',
      close: '371.0400000000000000',
      volume: '692270.5369000000000000',
      quoteVolume: 259365469.57772288,
      btcVolume: 23796.352412135322,
      usdVolume: 259365469.57772288,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '371.0400000000000000',
      high: '376.8000000000000000',
      low: '331.0000000000000000',
      close: '340.3600000000000000',
      volume: '1283946.0309800000000000',
      quoteVolume: 452134893.6340549,
      btcVolume: 42542.986905510355,
      usdVolume: 452134893.6340549,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '340.3100000000000000',
      high: '346.9100000000000000',
      low: '335.3400000000000000',
      close: '344.2100000000000000',
      volume: '842519.6630700000000000',
      quoteVolume: 288094132.61557794,
      btcVolume: 27542.53930759665,
      usdVolume: 288094132.61557794,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '344.1800000000000000',
      high: '344.6500000000000000',
      low: '313.2900000000000000',
      close: '320.7200000000000000',
      volume: '940904.4975600000000000',
      quoteVolume: 313808761.09044343,
      btcVolume: 30190.07605426562,
      usdVolume: 313808761.09044343,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '320.7200000000000000',
      high: '353.1000000000000000',
      low: '316.6700000000000000',
      close: '348.9700000000000000',
      volume: '1070458.7500100000000000',
      quoteVolume: 360295904.75400215,
      btcVolume: 34447.673829712236,
      usdVolume: 360295904.75400215,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '348.9700000000000000',
      high: '358.0000000000000000',
      low: '337.5800000000000000',
      close: '351.9200000000000000',
      volume: '860643.7792400000000000',
      quoteVolume: 298266918.80064315,
      btcVolume: 27960.07827767787,
      usdVolume: 298266918.80064315,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '351.9300000000000000',
      high: '356.4000000000000000',
      low: '345.8800000000000000',
      close: '353.9200000000000000',
      volume: '530698.1258100000000000',
      quoteVolume: 186748081.4048322,
      btcVolume: 17435.278426956505,
      usdVolume: 186748081.4048322,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '353.9500000000000000',
      high: '362.1400000000000000',
      low: '347.2400000000000000',
      close: '357.4900000000000000',
      volume: '569255.6546900000000000',
      quoteVolume: 202300944.46097547,
      btcVolume: 18873.55464049207,
      usdVolume: 202300944.46097547,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '357.5800000000000000',
      high: '368.2000000000000000',
      low: '351.7000000000000000',
      close: '353.9600000000000000',
      volume: '757758.4304000000000000',
      quoteVolume: 273063157.7899871,
      btcVolume: 25118.54484016467,
      usdVolume: 273063157.7899871,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '353.9100000000000000',
      high: '360.6300000000000000',
      low: '350.4500000000000000',
      close: '359.7900000000000000',
      volume: '585138.1015300000000000',
      quoteVolume: 208027010.0546198,
      btcVolume: 19389.23399823974,
      usdVolume: 208027010.0546198,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '359.7800000000000000',
      high: '361.5000000000000000',
      low: '351.5600000000000000',
      close: '359.8300000000000000',
      volume: '498791.2080200000000000',
      quoteVolume: 177730785.31600055,
      btcVolume: 16544.49139067945,
      usdVolume: 177730785.31600055,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '359.8300000000000000',
      high: '370.4500000000000000',
      low: '345.5100000000000000',
      close: '353.0300000000000000',
      volume: '749944.4218500000000000',
      quoteVolume: 268507606.4936562,
      btcVolume: 25081.67090101762,
      usdVolume: 268507606.4936562,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '351.5000000000000000',
      high: '354.5500000000000000',
      low: '334.2400000000000000',
      close: '345.6000000000000000',
      volume: '728971.7125400000000000',
      quoteVolume: 250210659.13679448,
      btcVolume: 23823.341024494177,
      usdVolume: 250210659.13679448,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '345.6000000000000000',
      high: '351.0000000000000000',
      low: '343.7000000000000000',
      close: '345.9500000000000000',
      volume: '303595.7190100000000000',
      quoteVolume: 105401893.35734256,
      btcVolume: 9994.847525254354,
      usdVolume: 105401893.35734256,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '345.9400000000000000',
      high: '354.4500000000000000',
      low: '343.8100000000000000',
      close: '352.3700000000000000',
      volume: '343932.9171200000000000',
      quoteVolume: 119961274.70065778,
      btcVolume: 11308.936300639205,
      usdVolume: 119961274.70065778,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '352.3400000000000000',
      high: '356.1000000000000000',
      low: '348.5400000000000000',
      close: '353.6200000000000000',
      volume: '376740.3069200000000000',
      quoteVolume: 132656713.04409541,
      btcVolume: 12401.031763501092,
      usdVolume: 132656713.04409541,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '353.6300000000000000',
      high: '355.1000000000000000',
      low: '336.8000000000000000',
      close: '340.7500000000000000',
      volume: '640027.0228500000000000',
      quoteVolume: 221752725.91989398,
      btcVolume: 20777.07296176719,
      usdVolume: 221752725.91989398,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '340.8000000000000000',
      high: '342.8500000000000000',
      low: '332.1800000000000000',
      close: '341.5800000000000000',
      volume: '541928.8637600000000000',
      quoteVolume: 183873686.39043358,
      btcVolume: 17327.5506872979,
      usdVolume: 183873686.39043358,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '341.5800000000000000',
      high: '353.3200000000000000',
      low: '334.2100000000000000',
      close: '350.9500000000000000',
      volume: '622582.1733200000000000',
      quoteVolume: 214085732.44744968,
      btcVolume: 19925.932109701625,
      usdVolume: 214085732.44744968,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '350.9400000000000000',
      high: '368.3300000000000000',
      low: '347.3200000000000000',
      close: '365.0200000000000000',
      volume: '650478.1933800000000000',
      quoteVolume: 232878561.88521227,
      btcVolume: 21172.01801686735,
      usdVolume: 232878561.88521227,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '365.0100000000000000',
      high: '378.4000000000000000',
      low: '364.7100000000000000',
      close: '370.5200000000000000',
      volume: '591005.3212900000000000',
      quoteVolume: 220607973.01227474,
      btcVolume: 19473.834142001007,
      usdVolume: 220607973.01227474,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '370.5100000000000000',
      high: '377.6000000000000000',
      low: '368.8700000000000000',
      close: '374.1600000000000000',
      volume: '371050.1549000000000000',
      quoteVolume: 138482829.08381432,
      btcVolume: 12189.491907556405,
      usdVolume: 138482829.08381432,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '374.1700000000000000',
      high: '394.8000000000000000',
      low: '365.5800000000000000',
      close: '386.5100000000000000',
      volume: '777805.9870200000000000',
      quoteVolume: 295379758.31860316,
      btcVolume: 25799.813917940977,
      usdVolume: 295379758.31860316,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '386.5100000000000000',
      high: '387.7500000000000000',
      low: '374.5100000000000000',
      close: '381.0400000000000000',
      volume: '614228.9526600000000000',
      quoteVolume: 234463380.94917893,
      btcVolume: 20497.322200014103,
      usdVolume: 234463380.94917893,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '381.0500000000000000',
      high: '387.4100000000000000',
      low: '373.1300000000000000',
      close: '378.6900000000000000',
      volume: '531769.6736300000000000',
      quoteVolume: 201932522.19194937,
      btcVolume: 17718.0848554347,
      usdVolume: 201932522.19194937,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '378.6900000000000000',
      high: '381.7900000000000000',
      low: '369.8500000000000000',
      close: '377.6400000000000000',
      volume: '632253.2404100000000000',
      quoteVolume: 237722026.66919282,
      btcVolume: 20862.537140127723,
      usdVolume: 237722026.66919282,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '377.6300000000000000',
      high: '379.8300000000000000',
      low: '361.1800000000000000',
      close: '365.5000000000000000',
      volume: '654029.3640400000000000',
      quoteVolume: 241127894.70244646,
      btcVolume: 21270.53628135259,
      usdVolume: 241127894.70244646,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '365.5000000000000000',
      high: '370.4600000000000000',
      low: '363.3600000000000000',
      close: '368.3200000000000000',
      volume: '285578.2453700000000000',
      quoteVolume: 104838880.45610258,
      btcVolume: 9248.343343944713,
      usdVolume: 104838880.45610258,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '368.3100000000000000',
      high: '378.6400000000000000',
      low: '367.1000000000000000',
      close: '378.1100000000000000',
      volume: '323468.4709500000000000',
      quoteVolume: 120818450.62118112,
      btcVolume: 10571.651931836788,
      usdVolume: 120818450.62118112,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '378.1200000000000000',
      high: '384.3200000000000000',
      low: '372.5000000000000000',
      close: '379.3000000000000000',
      volume: '577252.9026500000000000',
      quoteVolume: 218372639.95702484,
      btcVolume: 18818.921615016232,
      usdVolume: 218372639.95702484,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '379.3000000000000000',
      high: '380.7300000000000000',
      low: '365.2700000000000000',
      close: '368.5200000000000000',
      volume: '617309.8167700000000000',
      quoteVolume: 229928443.79690987,
      btcVolume: 19409.513615432777,
      usdVolume: 229928443.79690987,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '368.5300000000000000',
      high: '401.4900000000000000',
      low: '367.7400000000000000',
      close: '390.3800000000000000',
      volume: '1221663.6378400000000000',
      quoteVolume: 472488832.71627617,
      btcVolume: 37631.22329892552,
      usdVolume: 472488832.71627617,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '390.3100000000000000',
      high: '420.7300000000000000',
      low: '390.2100000000000000',
      close: '413.9800000000000000',
      volume: '1095886.2298700000000000',
      quoteVolume: 447206682.58595973,
      btcVolume: 34562.69697720812,
      usdVolume: 447206682.58595973,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '413.9400000000000000',
      high: '419.3300000000000000',
      low: '401.3000000000000000',
      close: '405.9200000000000000',
      volume: '642369.4293200000000000',
      quoteVolume: 265063811.5230488,
      btcVolume: 20523.233149956377,
      usdVolume: 265063811.5230488,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '409.1900000000000000',
      high: '416.6600000000000000',
      low: '407.0000000000000000',
      close: '412.1400000000000000',
      volume: '419236.8420100000000000',
      quoteVolume: 172799812.65625998,
      btcVolume: 13272.573133752301,
      usdVolume: 172799812.65625998,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '412.1400000000000000',
      high: '417.4000000000000000',
      low: '402.5000000000000000',
      close: '405.8600000000000000',
      volume: '415431.1155000000000000',
      quoteVolume: 169990300.58460298,
      btcVolume: 13026.150189403857,
      usdVolume: 169990300.58460298,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '405.8500000000000000',
      high: '411.1100000000000000',
      low: '382.3000000000000000',
      close: '392.5200000000000000',
      volume: '894757.9162500000000000',
      quoteVolume: 356060346.50108105,
      btcVolume: 27339.184264126954,
      usdVolume: 356060346.50108105,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '392.5200000000000000',
      high: '410.2500000000000000',
      low: '389.9000000000000000',
      close: '403.4500000000000000',
      volume: '753617.4010100000000000',
      quoteVolume: 302269548.1476697,
      btcVolume: 22546.706790982997,
      usdVolume: 302269548.1476697,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '403.4100000000000000',
      high: '409.2000000000000000',
      low: '380.5200000000000000',
      close: '388.2300000000000000',
      volume: '816953.5301800000000000',
      quoteVolume: 320590416.22950745,
      btcVolume: 23922.336631484806,
      usdVolume: 320590416.22950745,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '388.2200000000000000',
      high: '393.7200000000000000',
      low: '380.5200000000000000',
      close: '387.1300000000000000',
      volume: '573096.0141600000000000',
      quoteVolume: 222669504.8753169,
      btcVolume: 16722.98084116485,
      usdVolume: 222669504.8753169,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '387.1200000000000000',
      high: '391.9700000000000000',
      low: '373.2900000000000000',
      close: '382.4900000000000000',
      volume: '771750.7474600000000000',
      quoteVolume: 294342545.9808737,
      btcVolume: 22024.58912338817,
      usdVolume: 294342545.9808737,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '382.5900000000000000',
      high: '394.8800000000000000',
      low: '379.9000000000000000',
      close: '386.4600000000000000',
      volume: '466870.1136200000000000',
      quoteVolume: 180911022.7121731,
      btcVolume: 13143.343560424059,
      usdVolume: 180911022.7121731,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '386.4400000000000000',
      high: '398.2900000000000000',
      low: '384.6100000000000000',
      close: '396.6700000000000000',
      volume: '438952.9556500000000000',
      quoteVolume: 171238317.7003242,
      btcVolume: 12438.544674604154,
      usdVolume: 171238317.7003242,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '396.6800000000000000',
      high: '404.5000000000000000',
      low: '377.9200000000000000',
      close: '383.0200000000000000',
      volume: '779968.5795700000000000',
      quoteVolume: 304223545.89203674,
      btcVolume: 22406.862744217426,
      usdVolume: 304223545.89203674,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '383.0100000000000000',
      high: '390.0000000000000000',
      low: '370.2300000000000000',
      close: '387.8800000000000000',
      volume: '752954.3711400000000000',
      quoteVolume: 286291773.9684873,
      btcVolume: 21076.429697023013,
      usdVolume: 286291773.9684873,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '387.8800000000000000',
      high: '408.3900000000000000',
      low: '376.4700000000000000',
      close: '402.4900000000000000',
      volume: '1157064.3507600000000000',
      quoteVolume: 454481208.4020135,
      btcVolume: 32586.363296352156,
      usdVolume: 454481208.4020135,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '402.5000000000000000',
      high: '420.4000000000000000',
      low: '396.1400000000000000',
      close: '416.6900000000000000',
      volume: '1475139.7734200000000000',
      quoteVolume: 601591250.4012383,
      btcVolume: 40712.080001064045,
      usdVolume: 601591250.4012383,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '416.7300000000000000',
      high: '458.2700000000000000',
      low: '414.7600000000000000',
      close: '455.9100000000000000',
      volume: '1679301.1392700000000000',
      quoteVolume: 734615875.017384,
      btcVolume: 47133.026973207314,
      usdVolume: 734615875.017384,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '455.9100000000000000',
      high: '468.2800000000000000',
      low: '424.1400000000000000',
      close: '435.2100000000000000',
      volume: '1653416.5961000000000000',
      quoteVolume: 743871175.7821237,
      btcVolume: 48717.33214579415,
      usdVolume: 743871175.7821237,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '435.2000000000000000',
      high: '460.1000000000000000',
      low: '431.9900000000000000',
      close: '454.3000000000000000',
      volume: '840722.7949000000000000',
      quoteVolume: 375595944.18961173,
      btcVolume: 24675.810368249833,
      usdVolume: 375595944.18961173,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '454.2900000000000000',
      high: '459.1000000000000000',
      low: '433.0900000000000000',
      close: '444.3200000000000000',
      volume: '1098601.4997800000000000',
      quoteVolume: 491653842.88129467,
      btcVolume: 32077.913407959655,
      usdVolume: 491653842.88129467,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '444.3200000000000000',
      high: '455.0000000000000000',
      low: '438.7000000000000000',
      close: '450.3400000000000000',
      volume: '866686.4285100000000000',
      quoteVolume: 388106243.05318296,
      btcVolume: 25360.781987038194,
      usdVolume: 388106243.05318296,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '450.3400000000000000',
      high: '476.2500000000000000',
      low: '449.2800000000000000',
      close: '463.0900000000000000',
      volume: '1205782.8426200000000000',
      quoteVolume: 559228791.8903673,
      btcVolume: 35902.59522880581,
      usdVolume: 559228791.8903673,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '463.0900000000000000',
      high: '470.0000000000000000',
      low: '451.2000000000000000',
      close: '462.3900000000000000',
      volume: '990776.6291200000000000',
      quoteVolume: 455971309.2168636,
      btcVolume: 28686.52115959985,
      usdVolume: 455971309.2168636,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '462.4800000000000000',
      high: '478.0100000000000000',
      low: '457.1200000000000000',
      close: '476.4300000000000000',
      volume: '951211.0953600000000000',
      quoteVolume: 444338362.5908921,
      btcVolume: 27326.06235354085,
      usdVolume: 444338362.5908921,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '476.4200000000000000',
      high: '477.4700000000000000',
      low: '452.0000000000000000',
      close: '460.8900000000000000',
      volume: '726969.1507100000000000',
      quoteVolume: 336364969.86737686,
      btcVolume: 21033.888011294177,
      usdVolume: 336364969.86737686,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '460.9000000000000000',
      high: '462.8900000000000000',
      low: '440.1900000000000000',
      close: '448.0800000000000000',
      volume: '665184.8098900000000000',
      quoteVolume: 301391078.6921614,
      btcVolume: 18876.13024282066,
      usdVolume: 301391078.6921614,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '448.0700000000000000',
      high: '466.0000000000000000',
      low: '445.4500000000000000',
      close: '460.5500000000000000',
      volume: '811687.1632900000000000',
      quoteVolume: 370720232.1125867,
      btcVolume: 22611.477082211626,
      usdVolume: 370720232.1125867,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '460.5100000000000000',
      high: '484.8800000000000000',
      low: '459.8000000000000000',
      close: '482.1500000000000000',
      volume: '1120652.5363800000000000',
      quoteVolume: 529585259.9541648,
      btcVolume: 30825.11456878513,
      usdVolume: 529585259.9541648,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '482.1100000000000000',
      high: '495.3100000000000000',
      low: '456.6000000000000000',
      close: '478.4100000000000000',
      volume: '1474767.0885100000000000',
      quoteVolume: 705288664.0718446,
      btcVolume: 39474.871305535446,
      usdVolume: 705288664.0718446,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '478.4300000000000000',
      high: '480.7500000000000000',
      low: '464.8600000000000000',
      close: '471.2500000000000000',
      volume: '813313.3043500000000000',
      quoteVolume: 385259724.0413454,
      btcVolume: 21647.693501398127,
      usdVolume: 385259724.0413454,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '471.2600000000000000',
      high: '514.4000000000000000',
      low: '470.6500000000000000',
      close: '510.1200000000000000',
      volume: '1504164.8389300000000000',
      quoteVolume: 750785717.8739455,
      btcVolume: 40955.1380609167,
      usdVolume: 750785717.8739455,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '510.1200000000000000',
      high: '553.3200000000000000',
      low: '502.8200000000000000',
      close: '552.3500000000000000',
      volume: '1548851.2906700000000000',
      quoteVolume: 815402228.5227968,
      btcVolume: 43572.12042857939,
      usdVolume: 815402228.5227968,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '552.4200000000000000',
      high: '580.8800000000000000',
      low: '511.2800000000000000',
      close: '560.0300000000000000',
      volume: '1850317.9358100000000000',
      quoteVolume: 1014064539.0881541,
      btcVolume: 55112.07754619641,
      usdVolume: 1014064539.0881541,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '560.0300000000000000',
      high: '610.8500000000000000',
      low: '550.1200000000000000',
      close: '608.5800000000000000',
      volume: '2059928.2932900000000000',
      quoteVolume: 1208598659.5999002,
      btcVolume: 65547.02278720128,
      usdVolume: 1208598659.5999002,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '608.5800000000000000',
      high: '623.0000000000000000',
      low: '585.0000000000000000',
      close: '604.9700000000000000',
      volume: '1606450.4919300000000000',
      quoteVolume: 976376501.3114522,
      btcVolume: 52075.479021025865,
      usdVolume: 976376501.3114522,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '604.9800000000000000',
      high: '606.0000000000000000',
      low: '553.8300000000000000',
      close: '569.5000000000000000',
      volume: '1215573.9318600000000000',
      quoteVolume: 714664659.3653877,
      btcVolume: 37634.657613884825,
      usdVolume: 714664659.3653877,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '569.3800000000000000',
      high: '577.1400000000000000',
      low: '481.0000000000000000',
      close: '519.8300000000000000',
      volume: '2683460.7417100000000000',
      quoteVolume: 1386030255.1680546,
      btcVolume: 79991.49178543665,
      usdVolume: 1386030255.1680546,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '519.8300000000000000',
      high: '530.6200000000000000',
      low: '493.7200000000000000',
      close: '518.6800000000000000',
      volume: '1088063.4322200000000000',
      quoteVolume: 558984876.9379048,
      btcVolume: 32921.56765547952,
      usdVolume: 558984876.9379048,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '518.5900000000000000',
      high: '547.9400000000000000',
      low: '506.5900000000000000',
      close: '537.5200000000000000',
      volume: '900508.6143100000000000',
      quoteVolume: 475169074.6903964,
      btcVolume: 27355.45626379676,
      usdVolume: 475169074.6903964,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '537.5000000000000000',
      high: '576.9000000000000000',
      low: '530.3200000000000000',
      close: '576.3000000000000000',
      volume: '855184.5340300000000000',
      quoteVolume: 471578566.1167156,
      btcVolume: 26185.011636034185,
      usdVolume: 471578566.1167156,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '576.3200000000000000',
      high: '616.8500000000000000',
      low: '570.0700000000000000',
      close: '616.6600000000000000',
      volume: '1501989.4540800000000000',
      quoteVolume: 895373784.8300341,
      btcVolume: 47102.56560570271,
      usdVolume: 895373784.8300341,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '616.6500000000000000',
      high: '635.7000000000000000',
      low: '560.0000000000000000',
      close: '585.2600000000000000',
      volume: '2127926.8705500000000000',
      quoteVolume: 1278451640.1510434,
      btcVolume: 66678.68357305365,
      usdVolume: 1278451640.1510434,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '585.2600000000000000',
      high: '604.9300000000000000',
      low: '575.0000000000000000',
      close: '596.8900000000000000',
      volume: '1069538.6561900000000000',
      quoteVolume: 632858653.2050295,
      btcVolume: 33458.77431583617,
      usdVolume: 632858653.2050295,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '596.8800000000000000',
      high: '623.4400000000000000',
      low: '585.8200000000000000',
      close: '615.8500000000000000',
      volume: '935687.9172300000000000',
      quoteVolume: 568024618.9410435,
      btcVolume: 29433.265740340947,
      usdVolume: 568024618.9410435,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '615.8400000000000000',
      high: '620.0000000000000000',
      low: '565.8200000000000000',
      close: '567.2400000000000000',
      volume: '1052452.7866600000000000',
      quoteVolume: 624543865.9008446,
      btcVolume: 32794.329183490656,
      usdVolume: 624543865.9008446,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '567.2400000000000000',
      high: '597.1100000000000000',
      low: '560.8800000000000000',
      close: '596.8000000000000000',
      volume: '701073.3926200000000000',
      quoteVolume: 410088998.40737647,
      btcVolume: 21637.275162148107,
      usdVolume: 410088998.40737647,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '596.8200000000000000',
      high: '607.5000000000000000',
      low: '583.0000000000000000',
      close: '601.5400000000000000',
      volume: '602496.9633900000000000',
      quoteVolume: 359236400.29498786,
      btcVolume: 18762.06787882863,
      usdVolume: 359236400.29498786,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '601.5900000000000000',
      high: '603.1500000000000000',
      low: '584.2200000000000000',
      close: '591.2800000000000000',
      volume: '498329.3758900000000000',
      quoteVolume: 295598087.0279998,
      btcVolume: 15413.761647877274,
      usdVolume: 295598087.0279998,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '591.2700000000000000',
      high: '595.0000000000000000',
      low: '549.2200000000000000',
      close: '554.9400000000000000',
      volume: '804443.0071700000000000',
      quoteVolume: 461781203.16172385,
      btcVolume: 24513.068401678625,
      usdVolume: 461781203.16172385,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '554.9200000000000000',
      high: '578.0800000000000000',
      low: '530.4300000000000000',
      close: '573.0900000000000000',
      volume: '997191.6484000000000000',
      quoteVolume: 555141876.6276906,
      btcVolume: 30469.950793735137,
      usdVolume: 555141876.6276906,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '573.2000000000000000',
      high: '575.7000000000000000',
      low: '548.1100000000000000',
      close: '558.9900000000000000',
      volume: '607169.0047400000000000',
      quoteVolume: 340648675.9975368,
      btcVolume: 18641.974867746296,
      usdVolume: 340648675.9975368,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '558.9900000000000000',
      high: '560.8000000000000000',
      low: '535.5000000000000000',
      close: '544.4300000000000000',
      volume: '747763.1198900000000000',
      quoteVolume: 408047285.092406,
      btcVolume: 22758.283163314973,
      usdVolume: 407979347.94980955,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '544.4600000000000000',
      high: '574.0000000000000000',
      low: '543.1000000000000000',
      close: '568.0400000000000000',
      volume: '539225.5604100000000000',
      quoteVolume: 301525321.23763144,
      btcVolume: 16307.87218266341,
      usdVolume: 301525321.23763144,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '568.0900000000000000',
      high: '595.0000000000000000',
      low: '563.2200000000000000',
      close: '590.7000000000000000',
      volume: '657794.4615800000000000',
      quoteVolume: 383072560.34673756,
      btcVolume: 19983.985401772963,
      usdVolume: 383072560.34673756,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '590.8100000000000000',
      high: '591.4800000000000000',
      low: '575.6000000000000000',
      close: '586.2900000000000000',
      volume: '492918.9449700000000000',
      quoteVolume: 288056061.22263926,
      btcVolume: 15035.10093618048,
      usdVolume: 288056061.22263926,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '586.2900000000000000',
      high: '597.2000000000000000',
      low: '578.5800000000000000',
      close: '588.6400000000000000',
      volume: '551569.0579400000000000',
      quoteVolume: 324168681.67163837,
      btcVolume: 16753.55977616931,
      usdVolume: 324168681.67163837,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '588.7800000000000000',
      high: '640.0000000000000000',
      low: '580.7100000000000000',
      close: '637.1800000000000000',
      volume: '1144619.8239400000000000',
      quoteVolume: 700180315.3934683,
      btcVolume: 34363.64123742975,
      usdVolume: 700180315.3934683,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '637.1800000000000000',
      high: '676.2500000000000000',
      low: '625.7600000000000000',
      close: '642.7000000000000000',
      volume: '2006130.0197500000000000',
      quoteVolume: 1307445033.1218147,
      btcVolume: 57721.48977556455,
      usdVolume: 1307445033.1218147,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '642.7100000000000000',
      high: '664.6100000000000000',
      low: '629.4000000000000000',
      close: '653.8500000000000000',
      volume: '1000012.9801800000000000',
      quoteVolume: 646217791.229689,
      btcVolume: 28267.30155209629,
      usdVolume: 646217791.229689,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '653.7600000000000000',
      high: '669.9500000000000000',
      low: '645.4200000000000000',
      close: '658.0300000000000000',
      volume: '858803.8845900000000000',
      quoteVolume: 564029933.2588553,
      btcVolume: 24101.66087667955,
      usdVolume: 564029933.2588553,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '658.0500000000000000',
      high: '661.3900000000000000',
      low: '621.0100000000000000',
      close: '638.1800000000000000',
      volume: '823958.2284300000000000',
      quoteVolume: 533109285.6519513,
      btcVolume: 22604.403561175943,
      usdVolume: 533109285.6519513,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '638.1000000000000000',
      high: '648.0000000000000000',
      low: '595.9000000000000000',
      close: '607.5800000000000000',
      volume: '834470.3823100000000000',
      quoteVolume: 518855748.82504433,
      btcVolume: 22402.25118531381,
      usdVolume: 518855748.82504433,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '607.6800000000000000',
      high: '637.7300000000000000',
      low: '588.0000000000000000',
      close: '636.4500000000000000',
      volume: '918851.2565400000000000',
      quoteVolume: 563872004.6334975,
      btcVolume: 24418.03638657798,
      usdVolume: 563872004.6334975,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '636.4100000000000000',
      high: '638.4400000000000000',
      low: '551.2800000000000000',
      close: '585.5600000000000000',
      volume: '1208807.3651800000000000',
      quoteVolume: 733443630.3656094,
      btcVolume: 31260.2759405341,
      usdVolume: 733443630.3656094,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '585.4800000000000000',
      high: '615.0200000000000000',
      low: '565.4300000000000000',
      close: '612.3100000000000000',
      volume: '886335.8633300000000000',
      quoteVolume: 519843556.98166037,
      btcVolume: 22398.590221377046,
      usdVolume: 519843556.98166037,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '612.3100000000000000',
      high: '634.2400000000000000',
      low: '604.2800000000000000',
      close: '626.8200000000000000',
      volume: '797946.9485900000000000',
      quoteVolume: 494050300.1567907,
      btcVolume: 20522.31724411632,
      usdVolume: 494050300.1567907,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '626.7800000000000000',
      high: '652.9100000000000000',
      low: '615.2600000000000000',
      close: '637.4400000000000000',
      volume: '958585.5064700000000000',
      quoteVolume: 607664782.0109019,
      btcVolume: 23891.58404704324,
      usdVolume: 607664782.0109019,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '637.4400000000000000',
      high: '717.1300000000000000',
      low: '625.0000000000000000',
      close: '685.1100000000000000',
      volume: '1859968.1502900000000000',
      quoteVolume: 1247547956.8239481,
      btcVolume: 46008.51410433634,
      usdVolume: 1247547956.8239481,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '685.1000000000000000',
      high: '748.0900000000000000',
      low: '681.0400000000000000',
      close: '730.4100000000000000',
      volume: '1627123.9175900000000000',
      quoteVolume: 1180415994.5896938,
      btcVolume: 43771.67271543014,
      usdVolume: 1180415994.5896938,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '730.4000000000000000',
      high: '740.7800000000000000',
      low: '689.2000000000000000',
      close: '732.0000000000000000',
      volume: '1106876.3585500000000000',
      quoteVolume: 794643540.4901432,
      btcVolume: 29803.913314660324,
      usdVolume: 794643540.4901432,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '732.0000000000000000',
      high: '758.7400000000000000',
      low: '716.6200000000000000',
      close: '752.1700000000000000',
      volume: '1008573.9629400000000000',
      quoteVolume: 743546576.743025,
      btcVolume: 26409.000752216456,
      usdVolume: 743546576.743025,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '752.1700000000000000',
      high: '755.7400000000000000',
      low: '722.0000000000000000',
      close: '736.4200000000000000',
      volume: '863375.5798100000000000',
      quoteVolume: 638503822.3940647,
      btcVolume: 22178.91912646232,
      usdVolume: 638503822.3940647,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '736.4200000000000000',
      high: '749.0000000000000000',
      low: '714.2900000000000000',
      close: '728.9100000000000000',
      volume: '674648.6773000000000000',
      quoteVolume: 495736382.4405749,
      btcVolume: 16984.616127141537,
      usdVolume: 495736382.4405749,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '728.9100000000000000',
      high: '787.6900000000000000',
      low: '714.9100000000000000',
      close: '774.5600000000000000',
      volume: '1352618.5766800000000000',
      quoteVolume: 1024472182.6120937,
      btcVolume: 32889.04989161244,
      usdVolume: 1024472182.6120937,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '774.4400000000000000',
      high: '1011.0700000000000000',
      low: '768.7100000000000000',
      close: '978.2800000000000000',
      volume: '2813603.8861500000000000',
      quoteVolume: 2522411859.731612,
      btcVolume: 75351.3954511743,
      usdVolume: 2522411859.731612,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '978.3300000000000000',
      high: '1162.9700000000000000',
      low: '890.0000000000000000',
      close: '1041.4300000000000000',
      volume: '4245009.5910400000000000',
      quoteVolume: 4303632891.8297615,
      btcVolume: 135522.0321379406,
      usdVolume: 4303632891.8297615,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '1041.4500000000000000',
      high: '1134.6000000000000000',
      low: '974.4500000000000000',
      close: '1099.5600000000000000',
      volume: '2706995.6752500000000000',
      quoteVolume: 2857050280.008042,
      btcVolume: 88801.0479132276,
      usdVolume: 2857050280.008042,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '1100.1600000000000000',
      high: '1213.0000000000000000',
      low: '1057.2600000000000000',
      close: '1208.4200000000000000',
      volume: '2390134.2561700000000000',
      quoteVolume: 2725259321.0546975,
      btcVolume: 78230.68111796751,
      usdVolume: 2725259321.0546975,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '1208.7500000000000000',
      high: '1289.0000000000000000',
      low: '1131.0000000000000000',
      close: '1224.8700000000000000',
      volume: '1972917.8883400000000000',
      quoteVolume: 2406213991.6994786,
      btcVolume: 63090.38541215102,
      usdVolume: 2406213991.6994786,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '1224.8600000000000000',
      high: '1273.7500000000000000',
      low: '1065.0000000000000000',
      close: '1216.9300000000000000',
      volume: '2130161.3642600000000000',
      quoteVolume: 2542192047.8181143,
      btcVolume: 64132.46310834457,
      usdVolume: 2542192047.8181143,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '1216.7200000000000000',
      high: '1304.3700000000000000',
      low: '1171.3600000000000000',
      close: '1276.0000000000000000',
      volume: '1448871.1151400000000000',
      quoteVolume: 1780137376.416452,
      btcVolume: 44126.55053631966,
      usdVolume: 1780137376.416452,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '1276.0000000000000000',
      high: '1348.3300000000000000',
      low: '1170.0000000000000000',
      close: '1254.2500000000000000',
      volume: '1951179.3500300000000000',
      quoteVolume: 2517791852.4078064,
      btcVolume: 64117.4902690094,
      usdVolume: 2517791852.4078064,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '1254.2400000000000000',
      high: '1260.0000000000000000',
      low: '915.0000000000000000',
      close: '1087.0100000000000000',
      volume: '4054496.9340000000000000',
      quoteVolume: 4277939199.1682744,
      btcVolume: 126094.41321785371,
      usdVolume: 4277939199.1682744,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '1087.0000000000000000',
      high: '1150.0200000000000000',
      low: '1006.3300000000000000',
      close: '1050.1600000000000000',
      volume: '2173756.2488300000000000',
      quoteVolume: 2351659986.680197,
      btcVolume: 67607.46412545761,
      usdVolume: 2351659986.680197,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '1050.3600000000000000',
      high: '1137.6500000000000000',
      low: '987.4800000000000000',
      close: '1129.8900000000000000',
      volume: '1546028.4733400000000000',
      quoteVolume: 1645548180.1396663,
      btcVolume: 47601.494850955794,
      usdVolume: 1645548180.1396663,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '1129.7900000000000000',
      high: '1247.5500000000000000',
      low: '1086.7000000000000000',
      close: '1231.6200000000000000',
      volume: '1555737.3855600000000000',
      quoteVolume: 1825860914.1737947,
      btcVolume: 47277.3001514479,
      usdVolume: 1825860914.1737947,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '1231.8900000000000000',
      high: '1256.9800000000000000',
      low: '1069.4200000000000000',
      close: '1168.5300000000000000',
      volume: '1854933.6970800000000000',
      quoteVolume: 2199247860.325725,
      btcVolume: 59240.27896315071,
      usdVolume: 2199247860.325725,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '1168.4800000000000000',
      high: '1292.6600000000000000',
      low: '1151.7700000000000000',
      close: '1227.8300000000000000',
      volume: '1515434.5202100000000000',
      quoteVolume: 1854150543.260001,
      btcVolume: 50229.12509310558,
      usdVolume: 1854150543.260001,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '1227.8300000000000000',
      high: '1269.0200000000000000',
      low: '1165.0000000000000000',
      close: '1232.4500000000000000',
      volume: '1317285.0057900000000000',
      quoteVolume: 1606424364.4970233,
      btcVolume: 45243.78505901359,
      usdVolume: 1606424364.4970233,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '1232.5000000000000000',
      high: '1260.7500000000000000',
      low: '1182.5800000000000000',
      close: '1258.4500000000000000',
      volume: '998004.1263100000000000',
      quoteVolume: 1223572330.470765,
      btcVolume: 33926.362340998414,
      usdVolume: 1223572330.470765,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '1258.5200000000000000',
      high: '1438.3000000000000000',
      low: '1251.4900000000000000',
      close: '1365.3600000000000000',
      volume: '2277189.7942800000000000',
      quoteVolume: 3118645648.601239,
      btcVolume: 84576.84836448962,
      usdVolume: 3118645648.601239,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '1365.3600000000000000',
      high: '1407.9300000000000000',
      low: '1235.4200000000000000',
      close: '1376.9900000000000000',
      volume: '2217969.4846900000000000',
      quoteVolume: 2930015788.0453196,
      btcVolume: 83805.54654902022,
      usdVolume: 2930015788.0453196,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '1376.7200000000000000',
      high: '1390.0000000000000000',
      low: '1086.0000000000000000',
      close: '1110.9600000000000000',
      volume: '2310208.5729800000000000',
      quoteVolume: 2857288905.5771556,
      btcVolume: 87560.1065502035,
      usdVolume: 2857288905.5771556,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '1111.9800000000000000',
      high: '1273.7900000000000000',
      low: '1042.3100000000000000',
      close: '1232.6200000000000000',
      volume: '2005642.5528900000000000',
      quoteVolume: 2341609368.9179225,
      btcVolume: 74708.67997123068,
      usdVolume: 2341609368.9179225,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '1232.6100000000000000',
      high: '1272.4400000000000000',
      low: '1195.9000000000000000',
      close: '1233.6000000000000000',
      volume: '915290.0468900000000000',
      quoteVolume: 1134112575.401647,
      btcVolume: 35109.53478031294,
      usdVolume: 1134112575.401647,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '1233.4600000000000000',
      high: '1398.8300000000000000',
      low: '1220.0000000000000000',
      close: '1392.5100000000000000',
      volume: '1510879.5381700000000000',
      quoteVolume: 1996765627.5405061,
      btcVolume: 61983.71959418937,
      usdVolume: 1996765627.5405061,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '1392.1900000000000000',
      high: '1475.0000000000000000',
      low: '1311.0200000000000000',
      close: '1347.8800000000000000',
      volume: '1715392.6717800000000000',
      quoteVolume: 2419347040.465905,
      btcVolume: 72644.26574669621,
      usdVolume: 2419347040.465905,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '1289.8900000000000000',
      high: '1374.2200000000000000',
      low: '1288.0000000000000000',
      close: '1365.9700000000000000',
      volume: '474907.7533300000000000',
      quoteVolume: 634395015.3148867,
      btcVolume: 19710.204623958827,
      usdVolume: 634395015.3148867,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '1366.5400000000000000',
      high: '1373.9000000000000000',
      low: '1207.0000000000000000',
      close: '1239.0300000000000000',
      volume: '1913340.1664600000000000',
      quoteVolume: 2432623351.210675,
      btcVolume: 78753.05627142359,
      usdVolume: 2432623351.210675,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '1238.9300000000000000',
      high: '1360.0000000000000000',
      low: '1217.2300000000000000',
      close: '1329.6900000000000000',
      volume: '1285582.1498600000000000',
      quoteVolume: 1685996087.100964,
      btcVolume: 53145.032890713526,
      usdVolume: 1685996087.100964,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '1329.4400000000000000',
      high: '1436.9000000000000000',
      low: '1285.3500000000000000',
      close: '1379.5000000000000000',
      volume: '2111692.2313000000000000',
      quoteVolume: 2883767792.77202,
      btcVolume: 81514.055974949,
      usdVolume: 2883767792.77202,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '1379.5600000000000000',
      high: '1406.0000000000000000',
      low: '1326.4100000000000000',
      close: '1378.0500000000000000',
      volume: '1017334.1650900000000000',
      quoteVolume: 1383293203.399458,
      btcVolume: 40669.810913327645,
      usdVolume: 1383293203.399458,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '1378.0400000000000000',
      high: '1380.7400000000000000',
      low: '1281.1200000000000000',
      close: '1312.5500000000000000',
      volume: '913951.9568300000000000',
      quoteVolume: 1217085727.1929095,
      btcVolume: 36537.581286708024,
      usdVolume: 1217085727.1929095,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '1312.4500000000000000',
      high: '1377.9900000000000000',
      low: '1269.9900000000000000',
      close: '1374.1700000000000000',
      volume: '1080698.2129100000000000',
      quoteVolume: 1429023779.126755,
      btcVolume: 42479.33622274833,
      usdVolume: 1429023779.126755,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '1374.0900000000000000',
      high: '1547.9000000000000000',
      low: '1360.2800000000000000',
      close: '1511.8700000000000000',
      volume: '1943744.1966000000000000',
      quoteVolume: 2820899589.0203815,
      btcVolume: 81013.11891040324,
      usdVolume: 2820899589.0203815,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '1511.9300000000000000',
      high: '1669.3500000000000000',
      low: '1508.3900000000000000',
      close: '1664.4900000000000000',
      volume: '1535869.8880100000000000',
      quoteVolume: 2418887041.5875654,
      btcVolume: 66156.27367384409,
      usdVolume: 2418887041.5875654,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '1664.6700000000000000',
      high: '1695.2000000000000000',
      low: '1554.4400000000000000',
      close: '1595.6700000000000000',
      volume: '1646502.0866300000000000',
      quoteVolume: 2693099202.271006,
      btcVolume: 72046.17314645574,
      usdVolume: 2693099202.271006,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '1595.5300000000000000',
      high: '1760.4900000000000000',
      low: '1591.0000000000000000',
      close: '1719.1900000000000000',
      volume: '1193485.8324200000000000',
      quoteVolume: 2010622894.1443644,
      btcVolume: 53367.070155870046,
      usdVolume: 2010622894.1443644,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '1718.9500000000000000',
      high: '1743.6700000000000000',
      low: '1642.9400000000000000',
      close: '1676.3500000000000000',
      volume: '1149726.0674300000000000',
      quoteVolume: 1939917120.7056394,
      btcVolume: 48912.4046267318,
      usdVolume: 1939917120.7056394,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '1676.3500000000000000',
      high: '1693.0000000000000000',
      low: '1490.4600000000000000',
      close: '1611.6200000000000000',
      volume: '1281734.8681400000000000',
      quoteVolume: 2044371589.0232632,
      btcVolume: 53081.3239508303,
      usdVolume: 2044371589.0232632,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '1611.4600000000000000',
      high: '1775.5700000000000000',
      low: '1563.8900000000000000',
      close: '1750.1500000000000000',
      volume: '1339777.0953600000000000',
      quoteVolume: 2255426926.0200105,
      btcVolume: 53848.27559053579,
      usdVolume: 2255426926.0200105,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '1749.6800000000000000',
      high: '1821.8800000000000000',
      low: '1708.4900000000000000',
      close: '1769.1300000000000000',
      volume: '1185071.8959300000000000',
      quoteVolume: 2080818891.6989098,
      btcVolume: 44595.047468861056,
      usdVolume: 2080818891.6989098,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '1769.1400000000000000',
      high: '1837.8600000000000000',
      low: '1680.0000000000000000',
      close: '1740.7800000000000000',
      volume: '1219932.2547400000000000',
      quoteVolume: 2149076144.4077,
      btcVolume: 47044.18686396017,
      usdVolume: 2148911012.185441,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '1740.7600000000000000',
      high: '1819.0000000000000000',
      low: '0.0000163270000000',
      close: '1785.6200000000000000',
      volume: '734621.0468700000000000',
      quoteVolume: 1300810400.6745741,
      btcVolume: 27978.80513237656,
      usdVolume: 1300810421.239668,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '1785.8300000000000000',
      high: '1864.8800000000000000',
      low: '1739.8800000000000000',
      close: '1840.2600000000000000',
      volume: '909354.4636700000000000',
      quoteVolume: 1634996984.4396915,
      btcVolume: 34411.479778793124,
      usdVolume: 1634776311.9280682,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '1840.2400000000000000',
      high: '1871.7200000000000000',
      low: '1765.0000000000000000',
      close: '1814.9600000000000000',
      volume: '767588.7814400000000000',
      quoteVolume: 1395593560.9624188,
      btcVolume: 29584.45599424286,
      usdVolume: 1395437080.521789,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '1815.2500000000000000',
      high: '1850.0000000000000000',
      low: '1784.1100000000000000',
      close: '1800.4200000000000000',
      volume: '653541.2701500000000000',
      quoteVolume: 1190593797.9381444,
      btcVolume: 24535.71966972843,
      usdVolume: 1190593797.9381444,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '1800.0300000000000000',
      high: '1835.7200000000000000',
      low: '1655.6700000000000000',
      close: '1779.2500000000000000',
      volume: '1049314.2533700000000000',
      quoteVolume: 1852907004.6686351,
      btcVolume: 38765.91600274143,
      usdVolume: 1846625373.4324832,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '1779.3300000000000000',
      high: '1827.2800000000000000',
      low: '1724.0000000000000000',
      close: '1781.9900000000000000',
      volume: '816519.6687500000000000',
      quoteVolume: 1454153395.7932453,
      btcVolume: 29364.208274199304,
      usdVolume: 1434518186.7829216,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '1781.9900000000000000',
      high: '1855.0000000000000000',
      low: '1733.0100000000000000',
      close: '1849.8800000000000000',
      volume: '784619.1244400000000000',
      quoteVolume: 1417932396.1485229,
      btcVolume: 27781.649794336336,
      usdVolume: 1417932396.1485229,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '1849.7000000000000000',
      high: '1950.5000000000000000',
      low: '1849.6900000000000000',
      close: '1939.6100000000000000',
      volume: '937227.1019100000000000',
      quoteVolume: 1787383532.1023593,
      btcVolume: 34457.66137137417,
      usdVolume: 1787383532.1023593,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '1938.9100000000000000',
      high: '1972.8200000000000000',
      low: '1891.0000000000000000',
      close: '1955.0000000000000000',
      volume: '793166.6203700000000000',
      quoteVolume: 1533528111.7198534,
      btcVolume: 28954.450074244185,
      usdVolume: 1533528111.7198534,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '1955.4600000000000000',
      high: '2042.3400000000000000',
      low: '1817.4000000000000000',
      close: '1913.0000000000000000',
      volume: '1231380.2653200000000000',
      quoteVolume: 2438742761.044716,
      btcVolume: 43461.22491246883,
      usdVolume: 2438737210.8991766,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '1912.9000000000000000',
      high: '1976.3100000000000000',
      low: '1883.0100000000000000',
      close: '1933.5300000000000000',
      volume: '756568.1974300000000000',
      quoteVolume: 1467970937.285071,
      btcVolume: 25788.46682354838,
      usdVolume: 1467970937.285071,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '1933.5400000000000000',
      high: '1936.2900000000000000',
      low: '1555.0000000000000000',
      close: '1777.5800000000000000',
      volume: '1544855.9559000000000000',
      quoteVolume: 2744964206.143231,
      btcVolume: 48368.6255478767,
      usdVolume: 2601427551.846227,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '1777.4200000000000000',
      high: '1781.9900000000000000',
      low: '1371.6000000000000000',
      close: '1577.8900000000000000',
      volume: '2349675.3107900000000000',
      quoteVolume: 3640290942.4959636,
      btcVolume: 74416.81196595635,
      usdVolume: 3622396228.338403,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '1577.7900000000000000',
      high: '1712.7000000000000000',
      low: '1500.2000000000000000',
      close: '1623.3900000000000000',
      volume: '998796.1130000000000000',
      quoteVolume: 1631162134.0796974,
      btcVolume: 32698.532812710335,
      usdVolume: 1631047215.6908228,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '1623.1600000000000000',
      high: '1671.5900000000000000',
      low: '1459.1600000000000000',
      close: '1481.7500000000000000',
      volume: '1032670.7940900000000000',
      quoteVolume: 1650582276.8718207,
      btcVolume: 33117.338134786136,
      usdVolume: 1650582276.8718207,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '1482.0800000000000000',
      high: '1562.2500000000000000',
      low: '1400.0000000000000000',
      close: '1444.5900000000000000',
      volume: '1355973.2937800000000000',
      quoteVolume: 2004016547.1112273,
      btcVolume: 43035.34655306483,
      usdVolume: 2003245444.1310456,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '1444.5600000000000000',
      high: '1529.0000000000000000',
      low: '1425.0000000000000000',
      close: '1458.1300000000000000',
      volume: '927423.0475000000000000',
      quoteVolume: 1375946816.3325465,
      btcVolume: 29233.007119435944,
      usdVolume: 1375946816.3325465,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '1458.2700000000000000',
      high: '1468.7500000000000000',
      low: '1293.1800000000000000',
      close: '1419.1800000000000000',
      volume: '1394039.1589300000000000',
      quoteVolume: 1906652845.303296,
      btcVolume: 42805.29027654558,
      usdVolume: 1906652845.303296,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '1418.6700000000000000',
      high: '1572.0000000000000000',
      low: '1409.9100000000000000',
      close: '1569.8600000000000000',
      volume: '1071988.8959200000000000',
      quoteVolume: 1609708358.4012525,
      btcVolume: 33760.292992242015,
      usdVolume: 1609708358.4012525,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '1570.0000000000000000',
      high: '1602.9000000000000000',
      low: '1455.5200000000000000',
      close: '1486.7800000000000000',
      volume: '1061142.3874300000000000',
      quoteVolume: 1627312282.6235447,
      btcVolume: 33587.44590463653,
      usdVolume: 1627312282.6235447,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '1486.7900000000000000',
      high: '1656.9400000000000000',
      low: '1475.4700000000000000',
      close: '1567.6900000000000000',
      volume: '1054045.4787500000000000',
      quoteVolume: 1674981197.4400375,
      btcVolume: 33038.92359702907,
      usdVolume: 1674981197.4400375,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '1567.6800000000000000',
      high: '1624.8700000000000000',
      low: '1506.3600000000000000',
      close: '1539.2300000000000000',
      volume: '946365.6708800000000000',
      quoteVolume: 1476463517.9864302,
      btcVolume: 29907.939970665844,
      usdVolume: 1476144246.8024573,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '1539.2200000000000000',
      high: '1548.9600000000000000',
      low: '1441.6600000000000000',
      close: '1528.3100000000000000',
      volume: '910786.2897600000000000',
      quoteVolume: 1355798089.2726953,
      btcVolume: 28468.307956482684,
      usdVolume: 1355798089.2726953,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '1528.0800000000000000',
      high: '1671.5800000000000000',
      low: '1513.0300000000000000',
      close: '1650.3500000000000000',
      volume: '990313.5575500000000000',
      quoteVolume: 1575968246.2858746,
      btcVolume: 32484.06849446943,
      usdVolume: 1569880633.498245,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '1650.6800000000000000',
      high: '1734.1600000000000000',
      low: '1630.3500000000000000',
      close: '1726.1600000000000000',
      volume: '848470.7813600000000000',
      quoteVolume: 1414988218.19356,
      btcVolume: 28184.765635712247,
      usdVolume: 1414988218.19356,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '1726.4600000000000000',
      high: '1843.5900000000000000',
      low: '1664.9600000000000000',
      close: '1833.1800000000000000',
      volume: '1134793.9935500000000000',
      quoteVolume: 1968559430.9578898,
      btcVolume: 38787.24460770227,
      usdVolume: 1968559430.9578898,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '1833.2200000000000000',
      high: '1870.7700000000000000',
      low: '1798.2000000000000000',
      close: '1870.5100000000000000',
      volume: '813029.3448600000000000',
      quoteVolume: 1489269737.9822018,
      btcVolume: 27646.61354923279,
      usdVolume: 1489269737.9822018,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '1870.5000000000000000',
      high: '1877.6900000000000000',
      low: '1757.0000000000000000',
      close: '1795.1200000000000000',
      volume: '901824.9905900000000000',
      quoteVolume: 1641659203.449609,
      btcVolume: 29670.607882495333,
      usdVolume: 1641510306.9788375,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '1795.2800000000000000',
      high: '1846.7000000000000000',
      low: '1725.0000000000000000',
      close: '1825.7800000000000000',
      volume: '909011.3136100000000000',
      quoteVolume: 1627027140.3418167,
      btcVolume: 28930.654359520144,
      usdVolume: 1627027140.3418167,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '1825.7800000000000000',
      high: '1841.8800000000000000',
      low: '1719.7200000000000000',
      close: '1771.4800000000000000',
      volume: '840060.3990300000000000',
      quoteVolume: 1490608510.5170076,
      btcVolume: 19454.286038851555,
      usdVolume: 1105911678.621963,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '1759.7200000000000000',
      high: '1944.3000000000000000',
      low: '1727.7500000000000000',
      close: '1922.3500000000000000',
      volume: '921478.4547800000000000',
      quoteVolume: 1710098953.5524776,
      btcVolume: 28839.313150162354,
      usdVolume: 1710098953.5524776,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '1922.3500000000000000',
      high: '1933.1000000000000000',
      low: '1836.0000000000000000',
      close: '1848.1000000000000000',
      volume: '637140.4717400000000000',
      quoteVolume: 1198818913.9901521,
      btcVolume: 19816.886952228757,
      usdVolume: 1197320013.2192478,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '1848.1000000000000000',
      high: '1891.7600000000000000',
      low: '1730.0000000000000000',
      close: '1793.4800000000000000',
      volume: '1025326.7965800000000000',
      quoteVolume: 1842608872.7630134,
      btcVolume: 32173.17842836311,
      usdVolume: 1842608872.7630134,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '1793.9900000000000000',
      high: '1819.5000000000000000',
      low: '1711.6800000000000000',
      close: '1805.1200000000000000',
      volume: '745047.8840800000000000',
      quoteVolume: 1324386049.5584662,
      btcVolume: 23990.615019311717,
      usdVolume: 1323585091.7132356,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '1805.0100000000000000',
      high: '1838.8800000000000000',
      low: '1741.1800000000000000',
      close: '1823.5800000000000000',
      volume: '654844.3962200000000000',
      quoteVolume: 1171393579.333251,
      btcVolume: 20836.313439670008,
      usdVolume: 1171393579.333251,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '1823.5800000000000000',
      high: '1850.0000000000000000',
      low: '1759.0400000000000000',
      close: '1776.0800000000000000',
      volume: '603721.6105600000000000',
      quoteVolume: 1092346826.5537026,
      btcVolume: 18665.018047687696,
      usdVolume: 1092344736.4815433,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '1775.9200000000000000',
      high: '1840.6100000000000000',
      low: '1733.4700000000000000',
      close: '1809.1000000000000000',
      volume: '485394.8323500000000000',
      quoteVolume: 875146682.91855,
      btcVolume: 14962.1323122463,
      usdVolume: 871822690.956532,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '1809.0100000000000000',
      high: '1868.8300000000000000',
      low: '1800.8600000000000000',
      close: '1806.1000000000000000',
      volume: '423872.9248500000000000',
      quoteVolume: 777603559.92763,
      btcVolume: 13225.478131358275,
      usdVolume: 777585802.0887206,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '1806.5700000000000000',
      high: '1817.1600000000000000',
      low: '1747.0000000000000000',
      close: '1782.9800000000000000',
      volume: '465300.7587400000000000',
      quoteVolume: 829784481.8129778,
      btcVolume: 14483.824726332654,
      usdVolume: 828756040.3627473,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '1783.2300000000000000',
      high: '1807.5100000000000000',
      low: '1655.1500000000000000',
      close: '1680.9700000000000000',
      volume: '582614.8017100000000000',
      quoteVolume: 1019579063.1709024,
      btcVolume: 18102.374296305938,
      usdVolume: 1019512873.3647406,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '1681.1700000000000000',
      high: '1721.1700000000000000',
      low: '1650.0000000000000000',
      close: '1668.0800000000000000',
      volume: '574333.9664000000000000',
      quoteVolume: 970709518.4563361,
      btcVolume: 12089.460579743516,
      usdVolume: 658329851.7034984,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '1668.2000000000000000',
      high: '1740.8600000000000000',
      low: '1536.9200000000000000',
      close: '1581.8400000000000000',
      volume: '712606.6818800000000000',
      quoteVolume: 1184354983.115622,
      btcVolume: 21604.55412650087,
      usdVolume: 1184354983.115622,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '1581.8800000000000000',
      high: '1622.0000000000000000',
      low: '1549.4000000000000000',
      close: '1585.7400000000000000',
      volume: '637200.0501600000000000',
      quoteVolume: 1012804696.3799217,
      btcVolume: 17272.44309048303,
      usdVolume: 897465027.6504521,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '1585.8300000000000000',
      high: '1699.9300000000000000',
      low: '1585.7900000000000000',
      close: '1698.8400000000000000',
      volume: '442973.6271000000000000',
      quoteVolume: 727250279.6639122,
      btcVolume: 13128.883505516924,
      usdVolume: 700660186.6217037,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '1698.8400000000000000',
      high: '1732.6700000000000000',
      low: '1666.4100000000000000',
      close: '1712.6300000000000000',
      volume: '443845.4995700000000000',
      quoteVolume: 753555521.6446587,
      btcVolume: 13655.180403119155,
      usdVolume: 753442291.0866222,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '1712.5200000000000000',
      high: '1725.6600000000000000',
      low: '1661.4000000000000000',
      close: '1687.0100000000000000',
      volume: '348895.5131400000000000',
      quoteVolume: 592836644.5993483,
      btcVolume: 10623.566952815665,
      usdVolume: 592744065.8884122,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '1687.1600000000000000',
      high: '1841.0000000000000000',
      low: '1677.8500000000000000',
      close: '1816.7400000000000000',
      volume: '645153.9473800000000000',
      quoteVolume: 1143708814.718205,
      btcVolume: 19952.416250138947,
      usdVolume: 1143593696.951361,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '1816.7600000000000000',
      high: '1859.1300000000000000',
      low: '1786.3000000000000000',
      close: '1840.4600000000000000',
      volume: '568842.0052600000000000',
      quoteVolume: 1039262033.3867666,
      btcVolume: 17794.857969149907,
      usdVolume: 1039257506.3019704,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '1840.4100000000000000',
      high: '1947.2900000000000000',
      low: '1768.1500000000000000',
      close: '1919.3700000000000000',
      volume: '746995.8591000000000000',
      quoteVolume: 1387448329.8618498,
      btcVolume: 23597.31023417654,
      usdVolume: 1385699357.8138206,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '1919.3700000000000000',
      high: '1984.7700000000000000',
      low: '1885.0200000000000000',
      close: '1968.0500000000000000',
      volume: '735243.4803000000000000',
      quoteVolume: 1426255182.7403693,
      btcVolume: 24205.415566070777,
      usdVolume: 1424192936.6523898,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '1968.0500000000000000',
      high: '2146.0000000000000000',
      low: '1948.4800000000000000',
      close: '2133.6900000000000000',
      volume: '836004.3995600000000000',
      quoteVolume: 1699509331.9186137,
      btcVolume: 28682.7732154343,
      usdVolume: 1699416511.4975188,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '2133.6900000000000000',
      high: '2138.1200000000000000',
      low: '1996.3000000000000000',
      close: '2008.5200000000000000',
      volume: '770009.0373200000000000',
      quoteVolume: 1601162397.6543598,
      btcVolume: 14889.771267495946,
      usdVolume: 877202202.2152315,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '2008.5900000000000000',
      high: '2091.8300000000000000',
      low: '1975.2400000000000000',
      close: '2075.6900000000000000',
      volume: '504446.3045400000000000',
      quoteVolume: 1030198911.5271093,
      btcVolume: 14552.052881946049,
      usdVolume: 839965464.8217052,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '2075.7000000000000000',
      high: '2130.6400000000000000',
      low: '2002.1500000000000000',
      close: '2107.3800000000000000',
      volume: '672715.8405800000000000',
      quoteVolume: 1393443640.6387079,
      btcVolume: 19837.173273893703,
      usdVolume: 1151438176.7157285,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '2107.4300000000000000',
      high: '2151.0000000000000000',
      low: '2044.6500000000000000',
      close: '2112.2100000000000000',
      volume: '646617.8868200000000000',
      quoteVolume: 1364425311.2743363,
      btcVolume: 21462.268704397782,
      usdVolume: 1253287568.3909323,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '2112.2200000000000000',
      high: '2129.0100000000000000',
      low: '1930.0100000000000000',
      close: '1963.4700000000000000',
      volume: '890590.5480600000000000',
      quoteVolume: 1793821185.7585125,
      btcVolume: 31085.880944942655,
      usdVolume: 1765504353.4110937,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '1963.4600000000000000',
      high: '2084.6100000000000000',
      low: '1947.3500000000000000',
      close: '2080.4600000000000000',
      volume: '492449.6518300000000000',
      quoteVolume: 998031729.5983349,
      btcVolume: 11760.316400533313,
      usdVolume: 671261695.401949,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '2080.3900000000000000',
      high: '2100.0000000000000000',
      low: '2047.5700000000000000',
      close: '2066.8700000000000000',
      volume: '410697.4165100000000000',
      quoteVolume: 852604897.8719946,
      btcVolume: 12983.009883018114,
      usdVolume: 756441273.5357431,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '2067.1900000000000000',
      high: '2200.0000000000000000',
      low: '2058.0000000000000000',
      close: '2133.4900000000000000',
      volume: '655458.2089400000000000',
      quoteVolume: 1405364881.858304,
      btcVolume: 23111.04520438008,
      usdVolume: 1384757109.84192,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '2133.4900000000000000',
      high: '2165.0200000000000000',
      low: '2113.4000000000000000',
      close: '2151.3600000000000000',
      volume: '392265.5077300000000000',
      quoteVolume: 839653140.9406079,
      btcVolume: 13957.221461420964,
      usdVolume: 834685291.3442482,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '2151.4200000000000000',
      high: '2203.4600000000000000',
      low: '2103.0500000000000000',
      close: '2137.6900000000000000',
      volume: '569303.1026900000000000',
      quoteVolume: 1224143619.588617,
      btcVolume: 16124.45725574653,
      usdVolume: 970101865.6152618,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '2137.8600000000000000',
      high: '2318.0000000000000000',
      low: '2135.5300000000000000',
      close: '2299.1900000000000000',
      volume: '753630.4481400000000000',
      quoteVolume: 1682124954.2545862,
      btcVolume: 26651.164261948637,
      usdVolume: 1663537493.65112,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '2299.1900000000000000',
      high: '2447.2900000000000000',
      low: '2281.3300000000000000',
      close: '2432.6000000000000000',
      volume: '962367.1999900000000000',
      quoteVolume: 2270110171.976935,
      btcVolume: 31771.533630725116,
      usdVolume: 2017446256.9322753,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '2432.5900000000000000',
      high: '2543.4700000000000000',
      low: '2400.0000000000000000',
      close: '2514.0400000000000000',
      volume: '681946.7520600000000000',
      quoteVolume: 1680052982.425424,
      btcVolume: 24649.296881737133,
      usdVolume: 1551057612.4665124,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '2514.0400000000000000',
      high: '2548.2900000000000000',
      low: '2300.0000000000000000',
      close: '2422.6700000000000000',
      volume: '999163.1245600000000000',
      quoteVolume: 2418565017.4451094,
      btcVolume: 37191.04906644725,
      usdVolume: 2288259291.5148406,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '2422.3800000000000000',
      high: '2495.0000000000000000',
      low: '2310.0000000000000000',
      close: '2317.6000000000000000',
      volume: '624232.2556800000000000',
      quoteVolume: 1504895144.9122174,
      btcVolume: 16884.468899235042,
      usdVolume: 1031965089.1388259,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '2317.6500000000000000',
      high: '2340.0000000000000000',
      low: '1950.0000000000000000',
      close: '2235.6400000000000000',
      volume: '1322276.0831600000000000',
      quoteVolume: 2866812093.0214953,
      btcVolume: 50861.605911477796,
      usdVolume: 2839032150.7239733,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '2235.6500000000000000',
      high: '2300.0000000000000000',
      low: '2080.0000000000000000',
      close: '2161.1200000000000000',
      volume: '820592.2655500000000000',
      quoteVolume: 1800575928.1074977,
      btcVolume: 26365.040134011742,
      usdVolume: 1481688446.6256678,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '2161.1200000000000000',
      high: '2346.6200000000000000',
      low: '2055.0000000000000000',
      close: '2330.0300000000000000',
      volume: '990682.9157700000000000',
      quoteVolume: 2177949495.5727634,
      btcVolume: 37918.97019892185,
      usdVolume: 2107334113.285447,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '2330.2600000000000000',
      high: '2468.0000000000000000',
      low: '2235.1600000000000000',
      close: '2357.0600000000000000',
      volume: '1113465.3921000000000000',
      quoteVolume: 2619975710.2322025,
      btcVolume: 40857.67918389536,
      usdVolume: 2269210586.199889,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '2357.0600000000000000',
      high: '2644.4700000000000000',
      low: '2303.4400000000000000',
      close: '2397.9400000000000000',
      volume: '1882176.3625200000000000',
      quoteVolume: 4670246227.172266,
      btcVolume: 25414.11693159991,
      usdVolume: 1354009041.8840244,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '2397.9400000000000000',
      high: '2442.8900000000000000',
      low: '2107.0000000000000000',
      close: '2367.3900000000000000',
      volume: '2392663.5084700000000000',
      quoteVolume: 5410016346.711445,
      btcVolume: 57263.218105779786,
      usdVolume: 2852064612.713971,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '2367.3900000000000000',
      high: '2368.7400000000000000',
      low: '2154.8000000000000000',
      close: '2213.3500000000000000',
      volume: '990513.3389000000000000',
      quoteVolume: 2230697502.0572195,
      btcVolume: 42345.682650564064,
      usdVolume: 2111057044.5906014,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '2214.0800000000000000',
      high: '2356.6400000000000000',
      low: '2168.0000000000000000',
      close: '2320.4900000000000000',
      volume: '817845.6182200000000000',
      quoteVolume: 1860725124.3291864,
      btcVolume: 20853.64183965011,
      usdVolume: 1033254138.8731536,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '2320.4400000000000000',
      high: '2541.8700000000000000',
      low: '2303.6400000000000000',
      close: '2532.6000000000000000',
      volume: '940704.4956200000000000',
      quoteVolume: 2314456522.3843803,
      btcVolume: 26103.489465891733,
      usdVolume: 1376208037.313604,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '2532.0600000000000000',
      high: '2680.0000000000000000',
      low: '2480.2900000000000000',
      close: '2666.1300000000000000',
      volume: '771728.4587600000000000',
      quoteVolume: 1995139009.247419,
      btcVolume: 36127.94470152542,
      usdVolume: 1973577408.7839944,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '2666.1300000000000000',
      high: '2760.0000000000000000',
      low: '2559.4400000000000000',
      close: '2748.2400000000000000',
      volume: '999383.1852000000000000',
      quoteVolume: 2673547154.2009354,
      btcVolume: 48837.864468014675,
      usdVolume: 2673547154.2009354,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '2748.2300000000000000',
      high: '2798.0000000000000000',
      low: '2668.0000000000000000',
      close: '2757.0400000000000000',
      volume: '859315.6791200000000000',
      quoteVolume: 2353486577.8108225,
      btcVolume: 43742.4160025124,
      usdVolume: 2353486577.8108225,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '2757.0400000000000000',
      high: '2798.3000000000000000',
      low: '2723.0900000000000000',
      close: '2772.4200000000000000',
      volume: '683253.7843800000000000',
      quoteVolume: 1883721206.1899638,
      btcVolume: 22122.659703772588,
      usdVolume: 1221913689.666882,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '2772.4200000000000000',
      high: '2954.2800000000000000',
      low: '2743.4100000000000000',
      close: '2944.7700000000000000',
      volume: '690260.0774100000000000',
      quoteVolume: 1979833188.065232,
      btcVolume: 16327.850363768606,
      usdVolume: 944162910.1777735,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '2944.8300000000000000',
      high: '2985.0000000000000000',
      low: '2852.2600000000000000',
      close: '2949.5600000000000000',
      volume: '571953.7712900000000000',
      quoteVolume: 1672227924.3766143,
      btcVolume: 19320.10153700959,
      usdVolume: 1096231381.3174038,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '2949.3300000000000000',
      high: '3454.0000000000000000',
      low: '2949.3200000000000000',
      close: '3431.0400000000000000',
      volume: '1329083.1120400000000000',
      quoteVolume: 4243771371.0916457,
      btcVolume: 71145.21913947008,
      usdVolume: 4116214448.7382607,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '3431.0400000000000000',
      high: '3527.0000000000000000',
      low: '3160.0000000000000000',
      close: '3239.0100000000000000',
      volume: '1797928.4935400000000000',
      quoteVolume: 6010359311.950286,
      btcVolume: 85671.24707619315,
      usdVolume: 4746803613.875628,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '3239.0100000000000000',
      high: '3549.3400000000000000',
      low: '3200.2300000000000000',
      close: '3524.2600000000000000',
      volume: '1094521.4393500000000000',
      quoteVolume: 3683966384.3414955,
      btcVolume: 60862.394706312116,
      usdVolume: 3399403885.2271895,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '3524.3300000000000000',
      high: '3605.4000000000000000',
      low: '3376.4700000000000000',
      close: '3489.7300000000000000',
      volume: '907396.3895700000000000',
      quoteVolume: 3167529015.808255,
      btcVolume: 54859.42355950186,
      usdVolume: 3118998835.779845,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '3489.7400000000000000',
      high: '3587.0000000000000000',
      low: '3353.2300000000000000',
      close: '3479.5300000000000000',
      volume: '760544.3177800000000000',
      quoteVolume: 2641739520.816758,
      btcVolume: 45671.46922983503,
      usdVolume: 2590518418.0204196,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '3479.7900000000000000',
      high: '3958.3300000000000000',
      low: '3449.2700000000000000',
      close: '3910.2600000000000000',
      volume: '1130467.5485200000000000',
      quoteVolume: 4170897763.922241,
      btcVolume: 70835.00620283352,
      usdVolume: 4147360439.8575544,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '3910.2600000000000000',
      high: '3983.6500000000000000',
      low: '3726.0800000000000000',
      close: '3923.9500000000000000',
      volume: '1000399.7612200000000000',
      quoteVolume: 3885034129.3254085,
      btcVolume: 66054.86727804251,
      usdVolume: 3823738678.629524,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '3923.9500000000000000',
      high: '4208.0000000000000000',
      low: '3693.4700000000000000',
      close: '3945.8800000000000000',
      volume: '1330445.0332100000000000',
      quoteVolume: 5397868145.894449,
      btcVolume: 66445.5379158468,
      usdVolume: 3801853644.3229055,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '3946.6300000000000000',
      high: '4180.2200000000000000',
      low: '3771.6400000000000000',
      close: '4173.4500000000000000',
      volume: '1071659.7833600000000000',
      quoteVolume: 4255907552.0657372,
      btcVolume: 59163.452670007915,
      usdVolume: 3302134688.3033047,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '4173.4600000000000000',
      high: '4372.7200000000000000',
      low: '3701.5800000000000000',
      close: '3887.7100000000000000',
      volume: '1589824.9292600000000000',
      quoteVolume: 6626411118.25824,
      btcVolume: 118376.00765287268,
      usdVolume: 6523763033.013671,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '3810.1500000000000000',
      high: '4034.7700000000000000',
      low: '3541.5100000000000000',
      close: '3717.9100000000000000',
      volume: '1849973.9261200000000000',
      quoteVolume: 7017701529.470531,
      btcVolume: 137733.97130057015,
      usdVolume: 6840474825.010459,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '3717.9200000000000000',
      high: '4173.2800000000000000',
      low: '3691.3500000000000000',
      close: '4075.0100000000000000',
      volume: '1152899.9027000000000000',
      quoteVolume: 4567552216.6355095,
      btcVolume: 88199.46408681228,
      usdVolume: 4430311894.504898,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '4075.0200000000000000',
      high: '4134.0500000000000000',
      low: '3632.0000000000000000',
      close: '3646.6800000000000000',
      volume: '1039174.3467800000000000',
      quoteVolume: 4030584420.9662867,
      btcVolume: 81888.4612645759,
      usdVolume: 3986942174.04559,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '3646.2400000000000000',
      high: '3879.0000000000000000',
      low: '3343.0600000000000000',
      close: '3582.1000000000000000',
      volume: '1322422.7023000000000000',
      quoteVolume: 4783072987.0008545,
      btcVolume: 99450.38052256861,
      usdVolume: 4678658584.545799,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '3581.8100000000000000',
      high: '3587.0600000000000000',
      low: '3102.0000000000000000',
      close: '3282.5100000000000000',
      volume: '1896852.2045000000000000',
      quoteVolume: 6376399013.426107,
      btcVolume: 141476.57089151486,
      usdVolume: 6236699464.627672,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '3282.2500000000000000',
      high: '3565.7100000000000000',
      low: '3240.0000000000000000',
      close: '3375.0700000000000000',
      volume: '1287105.1300500000000000',
      quoteVolume: 4392855117.159339,
      btcVolume: 98470.05761413471,
      usdVolume: 4341814936.084908,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '3375.0800000000000000',
      high: '3441.7300000000000000',
      low: '1900.0000000000000000',
      close: '2438.9200000000000000',
      volume: '2822906.7017300000000000',
      quoteVolume: 7943579809.10751,
      btcVolume: 169657.88253061575,
      usdVolume: 6636093341.830318,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '2438.9200000000000000',
      high: '2990.0500000000000000',
      low: '2155.0000000000000000',
      close: '2768.6200000000000000',
      volume: '2937686.1189100000000000',
      quoteVolume: 7738734836.44043,
      btcVolume: 162815.48666917285,
      usdVolume: 6411033639.910326,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '2767.8700000000000000',
      high: '2938.5400000000000000',
      low: '2101.0000000000000000',
      close: '2430.9800000000000000',
      volume: '2224854.1226900000000000',
      quoteVolume: 5663309143.626692,
      btcVolume: 119218.8275934102,
      usdVolume: 4583070212.396227,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '2430.9800000000000000',
      high: '2485.0000000000000000',
      low: '2145.0000000000000000',
      close: '2294.8700000000000000',
      volume: '1682579.1813100000000000',
      quoteVolume: 3930453187.1191373,
      btcVolume: 71381.46907218514,
      usdVolume: 2649594194.693011,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '2295.4500000000000000',
      high: '2381.5700000000000000',
      low: '1728.7400000000000000',
      close: '2096.3900000000000000',
      volume: '2914548.7312500000000000',
      quoteVolume: 5907260139.545083,
      btcVolume: 169607.22742003587,
      usdVolume: 5818960197.408998,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '2096.6300000000000000',
      high: '2675.0000000000000000',
      low: '2079.9400000000000000',
      close: '2648.4300000000000000',
      volume: '2184824.1622800000000000',
      quoteVolume: 5176143519.684482,
      btcVolume: 136880.6376374799,
      usdVolume: 5087206518.533662,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '2647.9200000000000000',
      high: '2750.0000000000000000',
      low: '2378.5400000000000000',
      close: '2705.1100000000000000',
      volume: '2115349.5044500000000000',
      quoteVolume: 5442737549.401353,
      btcVolume: 141033.426253863,
      usdVolume: 5351032818.276227,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '2705.1000000000000000',
      high: '2910.0000000000000000',
      low: '2643.0000000000000000',
      close: '2884.9400000000000000',
      volume: '1562515.8153800000000000',
      quoteVolume: 4356014953.228169,
      btcVolume: 109277.35501313985,
      usdVolume: 4286403436.2894278,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '2884.8200000000000000',
      high: '2889.7400000000000000',
      low: '2633.6800000000000000',
      close: '2742.2300000000000000',
      volume: '1099029.9120600000000000',
      quoteVolume: 3033719144.3402476,
      btcVolume: 77944.20663975782,
      usdVolume: 3015457564.549178,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '2742.2300000000000000',
      high: '2762.4500000000000000',
      low: '2327.3800000000000000',
      close: '2412.1000000000000000',
      volume: '1554584.6136200000000000',
      quoteVolume: 3934662309.0444574,
      btcVolume: 106412.09487045105,
      usdVolume: 3873392067.229942,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '2412.3400000000000000',
      high: '2574.2700000000000000',
      low: '2204.1000000000000000',
      close: '2278.7400000000000000',
      volume: '1379427.1885100000000000',
      quoteVolume: 3288804154.8317804,
      btcVolume: 92759.48942970896,
      usdVolume: 3255889564.014722,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '2278.7400000000000000',
      high: '2476.4200000000000000',
      low: '2180.5300000000000000',
      close: '2385.9800000000000000',
      volume: '1151113.1988300000000000',
      quoteVolume: 2726606138.6317267,
      btcVolume: 76968.91849705382,
      usdVolume: 2721338216.8446603,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '2385.8200000000000000',
      high: '2720.0000000000000000',
      low: '2271.4400000000000000',
      close: '2706.1500000000000000',
      volume: '1307559.4516500000000000',
      quoteVolume: 3276105181.236761,
      btcVolume: 90321.1015277825,
      usdVolume: 3271922711.64009,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '2706.1500000000000000',
      high: '2740.0000000000000000',
      low: '2523.7400000000000000',
      close: '2634.5700000000000000',
      volume: '1128194.7242100000000000',
      quoteVolume: 2948103721.112332,
      btcVolume: 79478.59488431703,
      usdVolume: 2909896222.812539,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '2634.3100000000000000',
      high: '2802.9900000000000000',
      low: '2550.0000000000000000',
      close: '2706.2200000000000000',
      volume: '942515.8424900000000000',
      quoteVolume: 2543367680.0125494,
      btcVolume: 67979.08133458497,
      usdVolume: 2536877829.1898575,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '2706.2100000000000000',
      high: '2891.9500000000000000',
      low: '2662.4000000000000000',
      close: '2857.2500000000000000',
      volume: '1020865.2499900000000000',
      quoteVolume: 2865136924.0086355,
      btcVolume: 74011.12317239847,
      usdVolume: 2859489401.1131005,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '2857.2500000000000000',
      high: '2860.0000000000000000',
      low: '2552.0000000000000000',
      close: '2689.5100000000000000',
      volume: '1128891.3342400000000000',
      quoteVolume: 3013427232.3647776,
      btcVolume: 79691.24087877397,
      usdVolume: 2948492978.646687,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '2689.5400000000000000',
      high: '2816.8700000000000000',
      low: '2552.2300000000000000',
      close: '2627.7300000000000000',
      volume: '961824.1241300000000000',
      quoteVolume: 2575726370.4308653,
      btcVolume: 69952.15513260124,
      usdVolume: 2547190306.6139827,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '2627.6300000000000000',
      high: '2745.0000000000000000',
      low: '2614.0000000000000000',
      close: '2711.9200000000000000',
      volume: '673616.2318600000000000',
      quoteVolume: 1813640710.7122996,
      btcVolume: 50332.5808706832,
      usdVolume: 1810527213.9748535,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '2711.9200000000000000',
      high: '2847.5500000000000000',
      low: '2570.0000000000000000',
      close: '2592.0500000000000000',
      volume: '1152128.0785600000000000',
      quoteVolume: 3149245176.9112477,
      btcVolume: 87844.73708419563,
      usdVolume: 3133289457.721581,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '2592.0500000000000000',
      high: '2620.9900000000000000',
      low: '2304.9100000000000000',
      close: '2507.2300000000000000',
      volume: '1693747.4275900000000000',
      quoteVolume: 4201646662.8817635,
      btcVolume: 127564.26680324697,
      usdVolume: 4173747237.8152375,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '2507.4300000000000000',
      high: '2626.6300000000000000',
      low: '2405.2800000000000000',
      close: '2610.1800000000000000',
      volume: '1276718.2401100000000000',
      quoteVolume: 3223323188.07037,
      btcVolume: 91671.38084778743,
      usdVolume: 3194267768.5913177,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '2610.2000000000000000',
      high: '2624.4600000000000000',
      low: '2425.1100000000000000',
      close: '2471.0900000000000000',
      volume: '740997.1712300000000000',
      quoteVolume: 1873951840.3917582,
      btcVolume: 50420.55129947163,
      usdVolume: 1868275591.2025664,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '2471.0100000000000000',
      high: '2497.9900000000000000',
      low: '2321.3700000000000000',
      close: '2354.0100000000000000',
      volume: '587364.2735800000000000',
      quoteVolume: 1424975261.80769,
      btcVolume: 38486.31716446658,
      usdVolume: 1422146092.2599802,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '2354.0900000000000000',
      high: '2454.5300000000000000',
      low: '2256.2200000000000000',
      close: '2370.7400000000000000',
      volume: '767439.4984000000000000',
      quoteVolume: 1807036943.9986227,
      btcVolume: 50420.12200546101,
      usdVolume: 1799225782.2437441,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '2370.6400000000000000',
      high: '2548.4100000000000000',
      low: '2308.4100000000000000',
      close: '2509.3300000000000000',
      volume: '715948.7598600000000000',
      quoteVolume: 1724097564.3342984,
      btcVolume: 46788.12807221204,
      usdVolume: 1720049201.122317,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '2509.3300000000000000',
      high: '2609.1700000000000000',
      low: '2461.0000000000000000',
      close: '2580.7700000000000000',
      volume: '684183.5562200000000000',
      quoteVolume: 1730260219.1337855,
      btcVolume: 43429.67097729521,
      usdVolume: 1729368351.3638728,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '2580.5800000000000000',
      high: '2640.0000000000000000',
      low: '2507.7000000000000000',
      close: '2543.5400000000000000',
      volume: '599826.6493300000000000',
      quoteVolume: 1548303436.0628068,
      btcVolume: 38413.865031627225,
      usdVolume: 1546074562.469748,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '2543.5500000000000000',
      high: '2556.1900000000000000',
      low: '2351.1800000000000000',
      close: '2367.8900000000000000',
      volume: '624741.7835800000000000',
      quoteVolume: 1533734359.4129868,
      btcVolume: 39025.025528815226,
      usdVolume: 1532223313.0262673,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '2368.0200000000000000',
      high: '2460.5000000000000000',
      low: '2306.0000000000000000',
      close: '2373.2800000000000000',
      volume: '560667.5856600000000000',
      quoteVolume: 1338389545.0014875,
      btcVolume: 34687.277518367926,
      usdVolume: 1336853440.9920366,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '2373.2800000000000000',
      high: '2378.0000000000000000',
      low: '2139.0000000000000000',
      close: '2234.0900000000000000',
      volume: '778905.2019700000000000',
      quoteVolume: 1758827532.8833337,
      btcVolume: 47651.515366817985,
      usdVolume: 1752866245.1499925,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '2234.0900000000000000',
      high: '2278.8200000000000000',
      low: '2161.6700000000000000',
      close: '2164.6700000000000000',
      volume: '496795.2001700000000000',
      quoteVolume: 1104751776.509073,
      btcVolume: 30908.617217601786,
      usdVolume: 1104192848.8738875,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '2165.3400000000000000',
      high: '2280.0100000000000000',
      low: '2040.6800000000000000',
      close: '2243.4700000000000000',
      volume: '743883.6474100000000000',
      quoteVolume: 1599009750.52694,
      btcVolume: 45441.06693970805,
      usdVolume: 1581146882.9629188,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '2243.4900000000000000',
      high: '2260.1600000000000000',
      low: '1865.5400000000000000',
      close: '1885.4900000000000000',
      volume: '1612068.6092700000000000',
      quoteVolume: 3216482751.6444073,
      btcVolume: 96836.53868378879,
      usdVolume: 3184376853.0560994,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '1885.2700000000000000',
      high: '1998.0000000000000000',
      low: '1700.4800000000000000',
      close: '1879.2800000000000000',
      volume: '1695015.0570000000000000',
      quoteVolume: 3158320609.65545,
      btcVolume: 98947.73538033027,
      usdVolume: 3106137818.9013505,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '1879.2900000000000000',
      high: '2045.0000000000000000',
      low: '1821.9500000000000000',
      close: '1968.1400000000000000',
      volume: '918428.2598300000000000',
      quoteVolume: 1817150090.2132,
      btcVolume: 53355.95568641275,
      usdVolume: 1800911052.56896,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '1967.9500000000000000',
      high: '2036.6100000000000000',
      low: '1884.7900000000000000',
      close: '1989.5200000000000000',
      volume: '696819.0814600000000000',
      quoteVolume: 1369338921.1143198,
      btcVolume: 40275.19926124708,
      usdVolume: 1360915669.485624,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '1989.5300000000000000',
      high: '2019.9900000000000000',
      low: '1791.2200000000000000',
      close: '1808.8200000000000000',
      volume: '858808.5476700000000000',
      quoteVolume: 1612556879.10726,
      btcVolume: 48562.74287689089,
      usdVolume: 1610678376.9593577,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '1808.9700000000000000',
      high: '1852.8000000000000000',
      low: '1717.3200000000000000',
      close: '1829.6400000000000000',
      volume: '832315.1490400000000000',
      quoteVolume: 1478550576.5646644,
      btcVolume: 47044.29221027522,
      usdVolume: 1472306570.7656367,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '1829.8400000000000000',
      high: '1984.5000000000000000',
      low: '1804.2600000000000000',
      close: '1982.2800000000000000',
      volume: '696055.7930700000000000',
      quoteVolume: 1299521992.0718284,
      btcVolume: 39054.314536943915,
      usdVolume: 1296909651.9815106,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '1982.2700000000000000',
      high: '2145.0000000000000000',
      low: '1960.5200000000000000',
      close: '2084.4100000000000000',
      volume: '1041685.6177500000000000',
      quoteVolume: 2133750196.9705138,
      btcVolume: 61642.91631656625,
      usdVolume: 2128309767.164647,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '2084.4200000000000000',
      high: '2245.0000000000000000',
      low: '2073.5700000000000000',
      close: '2165.3300000000000000',
      volume: '810280.8201900000000000',
      quoteVolume: 1758516830.5676162,
      btcVolume: 49297.042069009694,
      usdVolume: 1752182286.5171914,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '2165.4600000000000000',
      high: '2287.7800000000000000',
      low: '2087.9900000000000000',
      close: '2275.6800000000000000',
      volume: '898104.5594900000000000',
      quoteVolume: 1947010071.716728,
      btcVolume: 55804.56318164029,
      usdVolume: 1945352686.7102058,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '2275.6800000000000000',
      high: '2275.9300000000000000',
      low: '2073.1200000000000000',
      close: '2106.4100000000000000',
      volume: '787865.2513700000000000',
      quoteVolume: 1688043308.1272736,
      btcVolume: 50052.84631659016,
      usdVolume: 1684503864.007349,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '2106.4100000000000000',
      high: '2160.0000000000000000',
      low: '2018.5000000000000000',
      close: '2154.1300000000000000',
      volume: '667688.1982700000000000',
      quoteVolume: 1387639105.207638,
      btcVolume: 41678.18078815616,
      usdVolume: 1387357481.4871128,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '2153.9500000000000000',
      high: '2240.0000000000000000',
      low: '2112.0100000000000000',
      close: '2226.9900000000000000',
      volume: '567852.4913700000000000',
      quoteVolume: 1245755929.802624,
      btcVolume: 36259.684210643376,
      usdVolume: 1245045174.0268378,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '2226.9900000000000000',
      high: '2389.9300000000000000',
      low: '2189.5800000000000000',
      close: '2322.6900000000000000',
      volume: '586011.9181400000000000',
      quoteVolume: 1354921782.9253561,
      btcVolume: 38334.608601607164,
      usdVolume: 1354169661.0324094,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '2322.6900000000000000',
      high: '2324.6400000000000000',
      low: '2157.3200000000000000',
      close: '2196.5300000000000000',
      volume: '781397.7756700000000000',
      quoteVolume: 1752928567.61669,
      btcVolume: 50930.09033861102,
      usdVolume: 1733327510.3003647,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '2196.8500000000000000',
      high: '2350.0000000000000000',
      low: '2193.0000000000000000',
      close: '2322.5000000000000000',
      volume: '773628.7066000000000000',
      quoteVolume: 1776303270.1513493,
      btcVolume: 50945.131886658244,
      usdVolume: 1742938566.1977441,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '2322.5100000000000000',
      high: '2409.0000000000000000',
      low: '2291.9000000000000000',
      close: '2316.5700000000000000',
      volume: '663034.0864800000000000',
      quoteVolume: 1562325076.9449115,
      btcVolume: 45243.083431928804,
      usdVolume: 1562325076.9449115,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '2316.2500000000000000',
      high: '2325.6000000000000000',
      low: '2084.7900000000000000',
      close: '2116.3300000000000000',
      volume: '880265.0412400000000000',
      quoteVolume: 1926184139.3098714,
      btcVolume: 58517.61155780528,
      usdVolume: 1923539240.4032106,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '2116.3200000000000000',
      high: '2189.4000000000000000',
      low: '2046.5100000000000000',
      close: '2146.9900000000000000',
      volume: '738611.3630400000000000',
      quoteVolume: 1568646237.310992,
      btcVolume: 47365.37692337437,
      usdVolume: 1568180213.2470188,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '2146.9900000000000000',
      high: '2194.8200000000000000',
      low: '2074.1600000000000000',
      close: '2110.6900000000000000',
      volume: '499071.8590400000000000',
      quoteVolume: 1058023794.5114112,
      btcVolume: 31367.83342121098,
      usdVolume: 1058004268.9712181,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '2110.6800000000000000',
      high: '2174.0000000000000000',
      low: '2081.0200000000000000',
      close: '2140.4500000000000000',
      volume: '341861.9107800000000000',
      quoteVolume: 726983500.1953408,
      btcVolume: 21483.282017250633,
      usdVolume: 726871259.6481026,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '2140.5400000000000000',
      high: '2167.8300000000000000',
      low: '2006.6800000000000000',
      close: '2031.5800000000000000',
      volume: '540630.1074200000000000',
      quoteVolume: 1126108847.3713632,
      btcVolume: 33473.528532740485,
      usdVolume: 1122137454.5873184,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '2031.5700000000000000',
      high: '2047.3500000000000000',
      low: '1918.0000000000000000',
      close: '1940.1500000000000000',
      volume: '646200.5714500000000000',
      quoteVolume: 1285151955.408697,
      btcVolume: 39172.03527550333,
      usdVolume: 1284955208.4702895,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '1940.4400000000000000',
      high: '2019.1800000000000000',
      low: '1865.0000000000000000',
      close: '1994.5800000000000000',
      volume: '690308.3220300000000000',
      quoteVolume: 1342549043.7056658,
      btcVolume: 41334.87560952568,
      usdVolume: 1341142947.6783092,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '1994.4100000000000000',
      high: '2042.8800000000000000',
      low: '1881.0000000000000000',
      close: '1919.5100000000000000',
      volume: '645206.5048600000000000',
      quoteVolume: 1251707253.3075902,
      btcVolume: 38931.359341004165,
      usdVolume: 1249677161.8594604,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '1919.5400000000000000',
      high: '1965.0000000000000000',
      low: '1850.0000000000000000',
      close: '1876.2600000000000000',
      volume: '564174.3896200000000000',
      quoteVolume: 1073278193.9976848,
      btcVolume: 33853.35022909676,
      usdVolume: 1073277728.2380823,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '1876.4700000000000000',
      high: '1919.1900000000000000',
      low: '1844.4500000000000000',
      close: '1899.5900000000000000',
      volume: '425518.6836600000000000',
      quoteVolume: 801669257.5310873,
      btcVolume: 25349.466334220528,
      usdVolume: 799730281.5427622,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '1899.5000000000000000',
      high: '1993.7900000000000000',
      low: '1877.1000000000000000',
      close: '1891.6400000000000000',
      volume: '534837.6365400000000000',
      quoteVolume: 1038104997.5220226,
      btcVolume: 32671.78700030974,
      usdVolume: 1038010885.0892934,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '1891.6500000000000000',
      high: '1919.0000000000000000',
      low: '1805.0000000000000000',
      close: '1818.7300000000000000',
      volume: '547419.9377100000000000',
      quoteVolume: 1011627398.9238861,
      btcVolume: 32502.636019487036,
      usdVolume: 1011432953.4100147,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '1818.7300000000000000',
      high: '1841.3400000000000000',
      low: '1706.0000000000000000',
      close: '1786.0300000000000000',
      volume: '786035.8219700000000000',
      quoteVolume: 1386111795.633173,
      btcVolume: 46342.08150771999,
      usdVolume: 1385239379.852115,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '1786.0300000000000000',
      high: '2035.2300000000000000',
      low: '1747.5500000000000000',
      close: '1995.5000000000000000',
      volume: '1115949.8203300000000000',
      quoteVolume: 2138345813.7854047,
      btcVolume: 68045.4703475941,
      usdVolume: 2137544618.252568,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '1995.5000000000000000',
      high: '2046.0000000000000000',
      low: '1947.6200000000000000',
      close: '2024.5600000000000000',
      volume: '768854.3659000000000000',
      quoteVolume: 1536669128.520453,
      btcVolume: 47687.592340090785,
      usdVolume: 1532204051.4068804,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '2024.2700000000000000',
      high: '2094.2800000000000000',
      low: '1993.2200000000000000',
      close: '2034.1400000000000000',
      volume: '561649.8608800000000000',
      quoteVolume: 1150771289.6299481,
      btcVolume: 35513.3079063386,
      usdVolume: 1150771289.6299481,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
  cdai: {
    id: 'cdai',
    name: 'CDAI',
    symbol: 'CDAI',
    image: 'assets/images/coin-logo/CDAI.png',
    activity24h: 545323456,
    availableAmount: 508.232323,
    usdxConversion: 7,
    allTimeValue: 3812390,
    allTimeAsset: 90678678421,
    candleData: [
      {
      open: '52.2500000000000000',
      high: '52.6600000000000000',
      low: '51.7000000000000000',
      close: '52.0700000000000000',
      volume: '35288.3970200000000000',
      quoteVolume: 1838724.3862475,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-28T00:00:00.000Z'
      },
      {
      open: '52.1000000000000000',
      high: '52.2600000000000000',
      low: '48.1700000000000000',
      close: '49.2100000000000000',
      volume: '93056.5563300000000000',
      quoteVolume: 4660386.8668885,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-29T00:00:00.000Z'
      },
      {
      open: '49.1600000000000000',
      high: '49.9600000000000000',
      low: '49.0200000000000000',
      close: '49.3400000000000000',
      volume: '41869.7843300000000000',
      quoteVolume: 2069910.6907136,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-30T00:00:00.000Z'
      },
      {
      open: '49.3500000000000000',
      high: '50.7100000000000000',
      low: '47.7100000000000000',
      close: '49.9300000000000000',
      volume: '59069.9364000000000000',
      quoteVolume: 2926931.182231,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-31T00:00:00.000Z'
      },
      {
      open: '49.9400000000000000',
      high: '50.8500000000000000',
      low: '49.7800000000000000',
      close: '50.6100000000000000',
      volume: '40743.5490400000000000',
      quoteVolume: 2044481.6283927,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-01T00:00:00.000Z'
      },
      {
      open: '50.6300000000000000',
      high: '52.2000000000000000',
      low: '50.5600000000000000',
      close: '51.7800000000000000',
      volume: '55439.5422700000000000',
      quoteVolume: 2853112.5898938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-02T00:00:00.000Z'
      },
      {
      open: '51.7800000000000000',
      high: '51.8100000000000000',
      low: '50.6000000000000000',
      close: '51.0400000000000000',
      volume: '44526.4412600000000000',
      quoteVolume: 2280110.5089847,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-03T00:00:00.000Z'
      },
      {
      open: '51.0900000000000000',
      high: '56.3300000000000000',
      low: '50.7700000000000000',
      close: '54.6000000000000000',
      volume: '131563.2191000000000000',
      quoteVolume: 7106100.759797,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-04T00:00:00.000Z'
      },
      {
      open: '54.6000000000000000',
      high: '55.1500000000000000',
      low: '53.0100000000000000',
      close: '53.9100000000000000',
      volume: '71425.6818700000000000',
      quoteVolume: 3841003.0724431,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-05T00:00:00.000Z'
      },
      {
      open: '53.9300000000000000',
      high: '56.8800000000000000',
      low: '53.8700000000000000',
      close: '56.4800000000000000',
      volume: '82733.1937500000000000',
      quoteVolume: 4543573.2471019,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-06T00:00:00.000Z'
      },
      {
      open: '56.4400000000000000',
      high: '56.9200000000000000',
      low: '53.5200000000000000',
      close: '54.4700000000000000',
      volume: '96697.1057100000000000',
      quoteVolume: 5343582.6991728,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-07T00:00:00.000Z'
      },
      {
      open: '54.4900000000000000',
      high: '55.1200000000000000',
      low: '52.4200000000000000',
      close: '52.7200000000000000',
      volume: '71785.2511700000000000',
      quoteVolume: 3845880.2935037,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-08T00:00:00.000Z'
      },
      {
      open: '52.7000000000000000',
      high: '53.1500000000000000',
      low: '51.6200000000000000',
      close: '52.2500000000000000',
      volume: '54807.0000800000000000',
      quoteVolume: 2873957.5317537,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-09T00:00:00.000Z'
      },
      {
      open: '52.2800000000000000',
      high: '53.0800000000000000',
      low: '51.9700000000000000',
      close: '52.4800000000000000',
      volume: '37947.9906200000000000',
      quoteVolume: 1994759.2672652,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-10T00:00:00.000Z'
      },
      {
      open: '52.4700000000000000',
      high: '52.5700000000000000',
      low: '49.7900000000000000',
      close: '51.4600000000000000',
      volume: '66550.4946300000000000',
      quoteVolume: 3407022.15279,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-11T00:00:00.000Z'
      },
      {
      open: '51.5000000000000000',
      high: '52.0400000000000000',
      low: '50.5900000000000000',
      close: '51.2600000000000000',
      volume: '60038.9645800000000000',
      quoteVolume: 3075503.9229793,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-12T00:00:00.000Z'
      },
      {
      open: '51.2600000000000000',
      high: '51.6600000000000000',
      low: '49.6000000000000000',
      close: '50.4300000000000000',
      volume: '63966.8834300000000000',
      quoteVolume: 3255168.879664,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-13T00:00:00.000Z'
      },
      {
      open: '50.4100000000000000',
      high: '51.0800000000000000',
      low: '42.0000000000000000',
      close: '45.0500000000000000',
      volume: '307943.6722800000000000',
      quoteVolume: 13948978.8933711,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-14T00:00:00.000Z'
      },
      {
      open: '45.0500000000000000',
      high: '45.8000000000000000',
      low: '40.4000000000000000',
      close: '44.7800000000000000',
      volume: '239147.1436300000000000',
      quoteVolume: 10442471.6269976,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-15T00:00:00.000Z'
      },
      {
      open: '44.7800000000000000',
      high: '44.9500000000000000',
      low: '42.3800000000000000',
      close: '42.8900000000000000',
      volume: '126959.9343000000000000',
      quoteVolume: 5526205.6703331,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-16T00:00:00.000Z'
      },
      {
      open: '42.8900000000000000',
      high: '42.9400000000000000',
      low: '41.9200000000000000',
      close: '42.4300000000000000',
      volume: '64923.2697500000000000',
      quoteVolume: 2748488.2839843,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-17T00:00:00.000Z'
      },
      {
      open: '42.4600000000000000',
      high: '43.8400000000000000',
      low: '42.1100000000000000',
      close: '42.6100000000000000',
      volume: '69727.5181800000000000',
      quoteVolume: 2991350.2245596,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-18T00:00:00.000Z'
      },
      {
      open: '42.6300000000000000',
      high: '42.6500000000000000',
      low: '35.9400000000000000',
      close: '37.0700000000000000',
      volume: '266627.8181000000000000',
      quoteVolume: 10193547.6518018,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-19T00:00:00.000Z'
      },
      {
      open: '37.1100000000000000',
      high: '38.8000000000000000',
      low: '31.4700000000000000',
      close: '33.8000000000000000',
      volume: '481972.2055200000000000',
      quoteVolume: 16734732.4018969,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-20T00:00:00.000Z'
      },
      {
      open: '33.7100000000000000',
      high: '35.3800000000000000',
      low: '32.1700000000000000',
      close: '35.1500000000000000',
      volume: '307689.2861400000000000',
      quoteVolume: 10447986.7251629,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-21T00:00:00.000Z'
      },
      {
      open: '35.1700000000000000',
      high: '35.6800000000000000',
      low: '31.8400000000000000',
      close: '32.1100000000000000',
      volume: '140470.9296900000000000',
      quoteVolume: 4791952.8493514,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-22T00:00:00.000Z'
      },
      {
      open: '32.0900000000000000',
      high: '33.8100000000000000',
      low: '31.1000000000000000',
      close: '32.7400000000000000',
      volume: '178263.3759900000000000',
      quoteVolume: 5763152.7094353,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-23T00:00:00.000Z'
      },
      {
      open: '32.7400000000000000',
      high: '34.3000000000000000',
      low: '29.0700000000000000',
      close: '29.7300000000000000',
      volume: '175080.6502100000000000',
      quoteVolume: 5486899.3643201,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-24T00:00:00.000Z'
      },
      {
      open: '29.7200000000000000',
      high: '32.9300000000000000',
      low: '27.7000000000000000',
      close: '31.4900000000000000',
      volume: '330712.4127800000000000',
      quoteVolume: 9875455.0267542,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-25T00:00:00.000Z'
      },
      {
      open: '31.5100000000000000',
      high: '32.8800000000000000',
      low: '28.6600000000000000',
      close: '30.1800000000000000',
      volume: '346086.7723900000000000',
      quoteVolume: 10650119.32109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-26T00:00:00.000Z'
      },
      {
      open: '30.1800000000000000',
      high: '32.1600000000000000',
      low: '29.0500000000000000',
      close: '31.6100000000000000',
      volume: '194024.5309000000000000',
      quoteVolume: 5911546.9844789,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-27T00:00:00.000Z'
      },
      {
      open: '31.6200000000000000',
      high: '36.5100000000000000',
      low: '31.3900000000000000',
      close: '34.7400000000000000',
      volume: '261988.5621100000000000',
      quoteVolume: 8934592.4563842,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-28T00:00:00.000Z'
      },
      {
      open: '34.7400000000000000',
      high: '36.0000000000000000',
      low: '33.0800000000000000',
      close: '33.8800000000000000',
      volume: '206424.0394100000000000',
      quoteVolume: 7091338.8885229,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-29T00:00:00.000Z'
      },
      {
      open: '33.9000000000000000',
      high: '34.5300000000000000',
      low: '31.0700000000000000',
      close: '32.2100000000000000',
      volume: '182324.5556100000000000',
      quoteVolume: 5934596.3999476,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-30T00:00:00.000Z'
      },
      {
      open: '32.1900000000000000',
      high: '35.1000000000000000',
      low: '31.3800000000000000',
      close: '34.1700000000000000',
      volume: '130601.1752700000000000',
      quoteVolume: 4373049.6516537,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-01T00:00:00.000Z'
      },
      {
      open: '34.1100000000000000',
      high: '35.2400000000000000',
      low: '33.2100000000000000',
      close: '33.8200000000000000',
      volume: '126562.5130800000000000',
      quoteVolume: 4314174.8406725,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-02T00:00:00.000Z'
      },
      {
      open: '33.8100000000000000',
      high: '33.9700000000000000',
      low: '30.0800000000000000',
      close: '30.9000000000000000',
      volume: '160953.4761900000000000',
      quoteVolume: 5107201.1187242,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-03T00:00:00.000Z'
      },
      {
      open: '30.9200000000000000',
      high: '32.2500000000000000',
      low: '29.8700000000000000',
      close: '31.1300000000000000',
      volume: '125624.1006500000000000',
      quoteVolume: 3938334.4457955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-04T00:00:00.000Z'
      },
      {
      open: '31.1400000000000000',
      high: '31.4000000000000000',
      low: '29.1500000000000000',
      close: '29.2700000000000000',
      volume: '122536.9591900000000000',
      quoteVolume: 3686632.3658405,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-05T00:00:00.000Z'
      },
      {
      open: '29.2800000000000000',
      high: '30.4900000000000000',
      low: '26.2500000000000000',
      close: '26.7800000000000000',
      volume: '186020.5287000000000000',
      quoteVolume: 5318278.7153886,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-06T00:00:00.000Z'
      },
      {
      open: '26.7400000000000000',
      high: '26.9400000000000000',
      low: '22.3200000000000000',
      close: '24.9100000000000000',
      volume: '344334.7713200000000000',
      quoteVolume: 8550497.2274868,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-07T00:00:00.000Z'
      },
      {
      open: '24.8800000000000000',
      high: '26.6300000000000000',
      low: '22.8900000000000000',
      close: '24.2200000000000000',
      volume: '272026.2401200000000000',
      quoteVolume: 6669827.4592307,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-08T00:00:00.000Z'
      },
      {
      open: '24.2300000000000000',
      high: '26.7500000000000000',
      low: '24.2000000000000000',
      close: '25.3700000000000000',
      volume: '185392.2777400000000000',
      quoteVolume: 4728009.3049824,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-09T00:00:00.000Z'
      },
      {
      open: '25.3700000000000000',
      high: '25.8200000000000000',
      low: '23.6700000000000000',
      close: '24.1400000000000000',
      volume: '159523.3339000000000000',
      quoteVolume: 3913245.9562269,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-10T00:00:00.000Z'
      },
      {
      open: '24.1500000000000000',
      high: '24.6300000000000000',
      low: '22.9400000000000000',
      close: '23.4100000000000000',
      volume: '116912.3106800000000000',
      quoteVolume: 2774102.4887662,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-11T00:00:00.000Z'
      },
      {
      open: '23.4000000000000000',
      high: '24.9800000000000000',
      low: '23.2000000000000000',
      close: '24.1300000000000000',
      volume: '116697.0162100000000000',
      quoteVolume: 2839522.3254574,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-12T00:00:00.000Z'
      },
      {
      open: '24.1300000000000000',
      high: '24.2900000000000000',
      low: '22.9100000000000000',
      close: '23.0800000000000000',
      volume: '101343.2455300000000000',
      quoteVolume: 2391795.1552609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-13T00:00:00.000Z'
      },
      {
      open: '23.0600000000000000',
      high: '23.7600000000000000',
      low: '22.5000000000000000',
      close: '23.3400000000000000',
      volume: '115451.1935700000000000',
      quoteVolume: 2669993.897385,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-14T00:00:00.000Z'
      },
      {
      open: '23.3300000000000000',
      high: '24.0600000000000000',
      low: '23.0900000000000000',
      close: '23.6000000000000000',
      volume: '114346.7365200000000000',
      quoteVolume: 2696112.2014415,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-15T00:00:00.000Z'
      },
      {
      open: '23.5900000000000000',
      high: '26.9600000000000000',
      low: '23.4800000000000000',
      close: '25.5200000000000000',
      volume: '284638.3664300000000000',
      quoteVolume: 7367653.6178438,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-16T00:00:00.000Z'
      },
      {
      open: '25.5100000000000000',
      high: '30.3000000000000000',
      low: '25.4300000000000000',
      close: '28.9500000000000000',
      volume: '415677.5500900000000000',
      quoteVolume: 11734862.3593704,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-17T00:00:00.000Z'
      },
      {
      open: '28.9700000000000000',
      high: '30.1500000000000000',
      low: '27.3900000000000000',
      close: '29.8900000000000000',
      volume: '241540.2199400000000000',
      quoteVolume: 6940548.103116,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-18T00:00:00.000Z'
      },
      {
      open: '29.8900000000000000',
      high: '31.5500000000000000',
      low: '28.5100000000000000',
      close: '28.8300000000000000',
      volume: '464104.8813400000000000',
      quoteVolume: 13967868.6904562,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-19T00:00:00.000Z'
      },
      {
      open: '28.8300000000000000',
      high: '32.7600000000000000',
      low: '28.5200000000000000',
      close: '32.1800000000000000',
      volume: '347428.5018700000000000',
      quoteVolume: 10702281.6625123,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-20T00:00:00.000Z'
      },
      {
      open: '32.1800000000000000',
      high: '32.8700000000000000',
      low: '29.5500000000000000',
      close: '30.2900000000000000',
      volume: '222927.6634500000000000',
      quoteVolume: 6941800.3395846,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-21T00:00:00.000Z'
      },
      {
      open: '30.2900000000000000',
      high: '31.4900000000000000',
      low: '29.7000000000000000',
      close: '31.3300000000000000',
      volume: '106033.3357700000000000',
      quoteVolume: 3234572.7694072,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-22T00:00:00.000Z'
      },
      {
      open: '31.2900000000000000',
      high: '33.8400000000000000',
      low: '31.0900000000000000',
      close: '32.9700000000000000',
      volume: '255561.7289100000000000',
      quoteVolume: 8317118.2252661,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-23T00:00:00.000Z'
      },
      {
      open: '32.9800000000000000',
      high: '36.1200000000000000',
      low: '32.1700000000000000',
      close: '32.6800000000000000',
      volume: '305504.7449800000000000',
      quoteVolume: 10561724.7698791,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-24T00:00:00.000Z'
      },
      {
      open: '32.6900000000000000',
      high: '32.9300000000000000',
      low: '29.1900000000000000',
      close: '30.5000000000000000',
      volume: '248993.5555600000000000',
      quoteVolume: 7586691.2768408,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-25T00:00:00.000Z'
      },
      {
      open: '30.5000000000000000',
      high: '31.6800000000000000',
      low: '28.7900000000000000',
      close: '30.3000000000000000',
      volume: '174048.0413600000000000',
      quoteVolume: 5242464.4829548,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-26T00:00:00.000Z'
      },
      {
      open: '30.2700000000000000',
      high: '30.8900000000000000',
      low: '27.1700000000000000',
      close: '27.5500000000000000',
      volume: '157817.2261100000000000',
      quoteVolume: 4528463.300989,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-27T00:00:00.000Z'
      },
      {
      open: '27.5500000000000000',
      high: '33.0800000000000000',
      low: '27.3900000000000000',
      close: '31.8000000000000000',
      volume: '242123.8975000000000000',
      quoteVolume: 7428412.8216719,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-28T00:00:00.000Z'
      },
      {
      open: '31.7800000000000000',
      high: '32.8100000000000000',
      low: '29.9100000000000000',
      close: '30.2800000000000000',
      volume: '222196.8569800000000000',
      quoteVolume: 6994333.2082403,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-29T00:00:00.000Z'
      },
      {
      open: '30.2800000000000000',
      high: '32.0000000000000000',
      low: '29.6800000000000000',
      close: '31.3800000000000000',
      volume: '151572.9304500000000000',
      quoteVolume: 4696122.0322328,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-30T00:00:00.000Z'
      },
      {
      open: '31.3800000000000000',
      high: '31.4300000000000000',
      low: '29.4200000000000000',
      close: '29.9000000000000000',
      volume: '97446.1124100000000000',
      quoteVolume: 2959940.3443041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-31T00:00:00.000Z'
      },
      {
      open: '29.9200000000000000',
      high: '31.8000000000000000',
      low: '29.5600000000000000',
      close: '31.4800000000000000',
      volume: '103182.7376500000000000',
      quoteVolume: 3162741.4467664,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-01T00:00:00.000Z'
      },
      {
      open: '31.4700000000000000',
      high: '33.2900000000000000',
      low: '31.0700000000000000',
      close: '32.6700000000000000',
      volume: '118306.2331700000000000',
      quoteVolume: 3805773.1198366,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-02T00:00:00.000Z'
      },
      {
      open: '32.6800000000000000',
      high: '32.8000000000000000',
      low: '30.6200000000000000',
      close: '31.3900000000000000',
      volume: '94129.8511400000000000',
      quoteVolume: 2982980.2248225,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-03T00:00:00.000Z'
      },
      {
      open: '31.4100000000000000',
      high: '32.5300000000000000',
      low: '30.7800000000000000',
      close: '31.7600000000000000',
      volume: '108636.8219300000000000',
      quoteVolume: 3437486.3851984,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-04T00:00:00.000Z'
      },
      {
      open: '31.7600000000000000',
      high: '35.9500000000000000',
      low: '31.7600000000000000',
      close: '34.3300000000000000',
      volume: '335581.5584800000000000',
      quoteVolume: 11471296.9699548,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-05T00:00:00.000Z'
      },
      {
      open: '34.3300000000000000',
      high: '39.7100000000000000',
      low: '33.8900000000000000',
      close: '38.3000000000000000',
      volume: '761396.1067600000000000',
      quoteVolume: 28298586.9009635,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-06T00:00:00.000Z'
      },
      {
      open: '38.2900000000000000',
      high: '39.0400000000000000',
      low: '36.5300000000000000',
      close: '37.3500000000000000',
      volume: '444975.9243100000000000',
      quoteVolume: 16797767.6556064,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-07T00:00:00.000Z'
      },
      {
      open: '37.3500000000000000',
      high: '40.8000000000000000',
      low: '36.9100000000000000',
      close: '38.7000000000000000',
      volume: '522510.1714200000000000',
      quoteVolume: 20322945.3312497,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-08T00:00:00.000Z'
      },
      {
      open: '38.7000000000000000',
      high: '39.7200000000000000',
      low: '38.0000000000000000',
      close: '38.1700000000000000',
      volume: '312030.1302100000000000',
      quoteVolume: 12104357.4442312,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-09T00:00:00.000Z'
      },
      {
      open: '38.1700000000000000',
      high: '38.7700000000000000',
      low: '31.0000000000000000',
      close: '32.8100000000000000',
      volume: '584622.4539000000000000',
      quoteVolume: 19980421.46609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-10T00:00:00.000Z'
      },
      {
      open: '32.8100000000000000',
      high: '33.2200000000000000',
      low: '29.6800000000000000',
      close: '31.4900000000000000',
      volume: '351182.3816500000000000',
      quoteVolume: 11268415.5174647,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-11T00:00:00.000Z'
      },
      {
      open: '31.4800000000000000',
      high: '32.1300000000000000',
      low: '31.0700000000000000',
      close: '31.6500000000000000',
      volume: '178476.2871800000000000',
      quoteVolume: 5640569.2458151,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-12T00:00:00.000Z'
      },
      {
      open: '31.6500000000000000',
      high: '32.6300000000000000',
      low: '29.1200000000000000',
      close: '29.5900000000000000',
      volume: '229264.1192700000000000',
      quoteVolume: 7075842.0712984,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-13T00:00:00.000Z'
      },
      {
      open: '29.6000000000000000',
      high: '32.5000000000000000',
      low: '29.3800000000000000',
      close: '31.7100000000000000',
      volume: '255282.3580100000000000',
      quoteVolume: 7925720.2735823,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-14T00:00:00.000Z'
      },
      {
      open: '31.7200000000000000',
      high: '32.2700000000000000',
      low: '29.9500000000000000',
      close: '30.7500000000000000',
      volume: '216841.8608500000000000',
      quoteVolume: 6784015.4244266,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-15T00:00:00.000Z'
      },
      {
      open: '30.7400000000000000',
      high: '31.9300000000000000',
      low: '30.4700000000000000',
      close: '31.2900000000000000',
      volume: '153939.5962200000000000',
      quoteVolume: 4789334.1418824,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '31.2900000000000000',
      high: '31.5500000000000000',
      low: '29.8000000000000000',
      close: '31.4000000000000000',
      volume: '203218.0028600000000000',
      quoteVolume: 6238519.8934698,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '31.3800000000000000',
      high: '31.5300000000000000',
      low: '30.3100000000000000',
      close: '30.7700000000000000',
      volume: '150711.3444400000000000',
      quoteVolume: 4659547.3864997,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '30.7800000000000000',
      high: '32.9200000000000000',
      low: '30.7700000000000000',
      close: '32.0000000000000000',
      volume: '164989.6366800000000000',
      quoteVolume: 5273505.545043,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '31.9400000000000000',
      high: '33.2000000000000000',
      low: '28.4500000000000000',
      close: '30.6400000000000000',
      volume: '249450.0040800000000000',
      quoteVolume: 7815412.7300217,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '30.6300000000000000',
      high: '31.2200000000000000',
      low: '30.3400000000000000',
      close: '30.8200000000000000',
      volume: '110305.7586600000000000',
      quoteVolume: 3392716.0542585,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '30.8200000000000000',
      high: '32.0000000000000000',
      low: '29.4800000000000000',
      close: '31.4100000000000000',
      volume: '199887.1625700000000000',
      quoteVolume: 6183272.8098907,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '31.4000000000000000',
      high: '32.1800000000000000',
      low: '31.2400000000000000',
      close: '31.6900000000000000',
      volume: '123577.2723200000000000',
      quoteVolume: 3920165.8017895,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '31.6900000000000000',
      high: '33.1000000000000000',
      low: '30.8900000000000000',
      close: '32.5100000000000000',
      volume: '138812.1621600000000000',
      quoteVolume: 4453091.2945143,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '32.5200000000000000',
      high: '33.0000000000000000',
      low: '31.8700000000000000',
      close: '32.6900000000000000',
      volume: '169242.5338200000000000',
      quoteVolume: 5485967.9294638,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '32.6800000000000000',
      high: '33.9500000000000000',
      low: '32.5700000000000000',
      close: '32.8000000000000000',
      volume: '166677.4280800000000000',
      quoteVolume: 5537195.8890435,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '32.7900000000000000',
      high: '33.2000000000000000',
      low: '31.0500000000000000',
      close: '32.2100000000000000',
      volume: '173697.8199800000000000',
      quoteVolume: 5628213.3201342,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '32.2200000000000000',
      high: '32.5300000000000000',
      low: '30.0000000000000000',
      close: '30.9400000000000000',
      volume: '267887.6294200000000000',
      quoteVolume: 8275485.9723247,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '30.9400000000000000',
      high: '31.2600000000000000',
      low: '29.9100000000000000',
      close: '30.8100000000000000',
      volume: '135879.8588000000000000',
      quoteVolume: 4168438.6557358,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '30.8000000000000000',
      high: '31.9700000000000000',
      low: '30.4700000000000000',
      close: '31.6800000000000000',
      volume: '129114.9970500000000000',
      quoteVolume: 4046024.3723204,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '31.6800000000000000',
      high: '32.3300000000000000',
      low: '31.0800000000000000',
      close: '31.4100000000000000',
      volume: '154708.1742700000000000',
      quoteVolume: 4881672.9566493,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '31.4300000000000000',
      high: '33.6400000000000000',
      low: '30.7000000000000000',
      close: '32.6500000000000000',
      volume: '335544.9644500000000000',
      quoteVolume: 10864529.5873272,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '32.6100000000000000',
      high: '35.0100000000000000',
      low: '32.4400000000000000',
      close: '34.5900000000000000',
      volume: '268052.5235400000000000',
      quoteVolume: 8939273.973636,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '34.5900000000000000',
      high: '34.8900000000000000',
      low: '32.8600000000000000',
      close: '33.4400000000000000',
      volume: '348406.7322400000000000',
      quoteVolume: 11802330.6146259,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '33.4300000000000000',
      high: '34.5000000000000000',
      low: '33.3600000000000000',
      close: '34.0900000000000000',
      volume: '206649.6690200000000000',
      quoteVolume: 7014206.2681502,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '34.0800000000000000',
      high: '34.5400000000000000',
      low: '33.6300000000000000',
      close: '34.3400000000000000',
      volume: '214319.3423500000000000',
      quoteVolume: 7321284.8808597,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '34.3500000000000000',
      high: '34.5000000000000000',
      low: '32.2500000000000000',
      close: '32.7900000000000000',
      volume: '312456.5420100000000000',
      quoteVolume: 10273847.9600226,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '32.7800000000000000',
      high: '33.6100000000000000',
      low: '32.7300000000000000',
      close: '33.2400000000000000',
      volume: '144528.0808400000000000',
      quoteVolume: 4798854.8184626,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '33.2300000000000000',
      high: '44.8500000000000000',
      low: '32.7500000000000000',
      close: '42.9900000000000000',
      volume: '1237384.0701200000000000',
      quoteVolume: 48819159.693489,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '42.9900000000000000',
      high: '46.9500000000000000',
      low: '42.0500000000000000',
      close: '44.6800000000000000',
      volume: '993529.3585100000000000',
      quoteVolume: 43845377.3617417,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '44.6800000000000000',
      high: '47.5900000000000000',
      low: '42.0000000000000000',
      close: '46.7900000000000000',
      volume: '667594.4622900000000000',
      quoteVolume: 30084786.2181325,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '46.8000000000000000',
      high: '46.8700000000000000',
      low: '41.8100000000000000',
      close: '42.9000000000000000',
      volume: '570398.4899100000000000',
      quoteVolume: 25000654.7153878,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '42.8800000000000000',
      high: '44.5700000000000000',
      low: '41.8400000000000000',
      close: '43.8900000000000000',
      volume: '326731.5580100000000000',
      quoteVolume: 14181167.7324087,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '43.8900000000000000',
      high: '44.6600000000000000',
      low: '40.6400000000000000',
      close: '41.6100000000000000',
      volume: '333914.8432600000000000',
      quoteVolume: 14169773.6990322,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '41.6100000000000000',
      high: '42.4500000000000000',
      low: '40.3100000000000000',
      close: '41.2600000000000000',
      volume: '285144.9373900000000000',
      quoteVolume: 11825040.1861124,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '41.2600000000000000',
      high: '44.6000000000000000',
      low: '41.0600000000000000',
      close: '42.5800000000000000',
      volume: '389950.9521300000000000',
      quoteVolume: 16519445.9920431,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '42.6000000000000000',
      high: '44.3700000000000000',
      low: '42.2800000000000000',
      close: '43.3400000000000000',
      volume: '326691.7079300000000000',
      quoteVolume: 14225774.1208697,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '43.3400000000000000',
      high: '45.1300000000000000',
      low: '42.6000000000000000',
      close: '43.9100000000000000',
      volume: '331698.7960300000000000',
      quoteVolume: 14459368.8381966,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '43.8900000000000000',
      high: '49.0300000000000000',
      low: '43.6200000000000000',
      close: '47.8400000000000000',
      volume: '744029.8712100000000000',
      quoteVolume: 34349598.9758269,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '47.8100000000000000',
      high: '49.1000000000000000',
      low: '46.5600000000000000',
      close: '47.1400000000000000',
      volume: '404786.7578400000000000',
      quoteVolume: 19350985.317177,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '47.1600000000000000',
      high: '52.2100000000000000',
      low: '46.2500000000000000',
      close: '51.5000000000000000',
      volume: '566874.1207300000000000',
      quoteVolume: 28402239.4220076,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '51.4700000000000000',
      high: '51.6500000000000000',
      low: '47.3500000000000000',
      close: '48.9400000000000000',
      volume: '383781.9003200000000000',
      quoteVolume: 18972966.8701831,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '48.9300000000000000',
      high: '49.9700000000000000',
      low: '48.0800000000000000',
      close: '49.1900000000000000',
      volume: '265979.4348900000000000',
      quoteVolume: 13079846.878828,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '49.2200000000000000',
      high: '51.8800000000000000',
      low: '48.0000000000000000',
      close: '51.4000000000000000',
      volume: '411115.3383000000000000',
      quoteVolume: 20597303.0431109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '51.3900000000000000',
      high: '53.5300000000000000',
      low: '42.3700000000000000',
      close: '43.7500000000000000',
      volume: '685277.0160800000000000',
      quoteVolume: 32440229.6963062,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '43.7400000000000000',
      high: '46.8500000000000000',
      low: '43.0000000000000000',
      close: '45.4000000000000000',
      volume: '377273.1727500000000000',
      quoteVolume: 16992487.3052862,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '45.4000000000000000',
      high: '45.8900000000000000',
      low: '44.0600000000000000',
      close: '44.7600000000000000',
      volume: '220101.5549400000000000',
      quoteVolume: 9886505.2314402,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '44.7700000000000000',
      high: '45.5300000000000000',
      low: '42.5100000000000000',
      close: '45.1600000000000000',
      volume: '223940.9450500000000000',
      quoteVolume: 9964616.9874317,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '45.1500000000000000',
      high: '46.7000000000000000',
      low: '44.4400000000000000',
      close: '45.6100000000000000',
      volume: '258562.9677900000000000',
      quoteVolume: 11834468.51228,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '45.6200000000000000',
      high: '48.9500000000000000',
      low: '45.5300000000000000',
      close: '47.0500000000000000',
      volume: '336011.9229400000000000',
      quoteVolume: 15914635.4786478,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '47.0200000000000000',
      high: '49.4000000000000000',
      low: '46.3800000000000000',
      close: '48.4900000000000000',
      volume: '325733.9473500000000000',
      quoteVolume: 15618945.7813467,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '48.5100000000000000',
      high: '49.1000000000000000',
      low: '47.1800000000000000',
      close: '47.8600000000000000',
      volume: '218511.9939600000000000',
      quoteVolume: 10525909.3067072,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '47.8700000000000000',
      high: '48.2200000000000000',
      low: '44.5700000000000000',
      close: '45.7800000000000000',
      volume: '306985.2797700000000000',
      quoteVolume: 14131761.4970669,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '45.7800000000000000',
      high: '54.2500000000000000',
      low: '45.3800000000000000',
      close: '52.5200000000000000',
      volume: '798938.1036800000000000',
      quoteVolume: 40660412.2179829,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '52.5200000000000000',
      high: '56.8100000000000000',
      low: '51.1100000000000000',
      close: '55.3700000000000000',
      volume: '670227.4940300000000000',
      quoteVolume: 36366973.2904414,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '55.3700000000000000',
      high: '59.1200000000000000',
      low: '53.7700000000000000',
      close: '56.7300000000000000',
      volume: '816612.7704900000000000',
      quoteVolume: 46227699.9424252,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '56.7300000000000000',
      high: '57.6000000000000000',
      low: '54.3000000000000000',
      close: '55.6100000000000000',
      volume: '440480.6590400000000000',
      quoteVolume: 24793142.8918252,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '55.5800000000000000',
      high: '59.8300000000000000',
      low: '55.3800000000000000',
      close: '57.7800000000000000',
      volume: '413625.1043600000000000',
      quoteVolume: 23841397.7942103,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '57.7800000000000000',
      high: '58.0400000000000000',
      low: '55.8400000000000000',
      close: '56.5900000000000000',
      volume: '198932.2372700000000000',
      quoteVolume: 11276533.6754336,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '56.6300000000000000',
      high: '57.3700000000000000',
      low: '54.0000000000000000',
      close: '54.8200000000000000',
      volume: '319640.8436300000000000',
      quoteVolume: 17619148.0806475,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '54.8200000000000000',
      high: '56.9600000000000000',
      low: '53.7200000000000000',
      close: '56.5800000000000000',
      volume: '186798.9733400000000000',
      quoteVolume: 10473627.53209,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '56.6000000000000000',
      high: '56.8400000000000000',
      low: '54.3000000000000000',
      close: '55.2900000000000000',
      volume: '234206.3592300000000000',
      quoteVolume: 13044765.6089822,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '55.2900000000000000',
      high: '58.2000000000000000',
      low: '51.6600000000000000',
      close: '55.9400000000000000',
      volume: '333686.3963100000000000',
      quoteVolume: 18559101.3738751,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '55.9400000000000000',
      high: '59.1500000000000000',
      low: '55.9400000000000000',
      close: '58.5200000000000000',
      volume: '295307.7767700000000000',
      quoteVolume: 17013433.6384185,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '58.5200000000000000',
      high: '61.9700000000000000',
      low: '58.4100000000000000',
      close: '61.2100000000000000',
      volume: '375314.7926600000000000',
      quoteVolume: 22846386.0282299,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '61.2200000000000000',
      high: '61.3200000000000000',
      low: '59.5700000000000000',
      close: '60.4700000000000000',
      volume: '174833.3191300000000000',
      quoteVolume: 10590199.9608341,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '60.4700000000000000',
      high: '62.6300000000000000',
      low: '58.2000000000000000',
      close: '59.1700000000000000',
      volume: '301146.2139800000000000',
      quoteVolume: 18071146.4905442,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '59.2000000000000000',
      high: '59.9600000000000000',
      low: '58.3300000000000000',
      close: '59.5200000000000000',
      volume: '221995.5983300000000000',
      quoteVolume: 13162394.4379992,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '59.5000000000000000',
      high: '60.7400000000000000',
      low: '56.6400000000000000',
      close: '60.2700000000000000',
      volume: '285413.7934000000000000',
      quoteVolume: 16953291.8433374,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '60.2900000000000000',
      high: '60.6400000000000000',
      low: '56.9800000000000000',
      close: '58.5400000000000000',
      volume: '164946.6053500000000000',
      quoteVolume: 9725257.740012,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '58.5400000000000000',
      high: '59.6000000000000000',
      low: '58.0000000000000000',
      close: '59.2600000000000000',
      volume: '184427.3358700000000000',
      quoteVolume: 10869615.6738316,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '59.2300000000000000',
      high: '61.7600000000000000',
      low: '58.9300000000000000',
      close: '60.8900000000000000',
      volume: '372961.5478100000000000',
      quoteVolume: 22578010.5670285,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '60.8800000000000000',
      high: '60.9000000000000000',
      low: '58.8400000000000000',
      close: '59.8200000000000000',
      volume: '360926.1570600000000000',
      quoteVolume: 21566309.6375968,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '59.8100000000000000',
      high: '60.7000000000000000',
      low: '58.2000000000000000',
      close: '59.0400000000000000',
      volume: '216703.6696800000000000',
      quoteVolume: 12846830.3232689,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '59.0600000000000000',
      high: '59.3500000000000000',
      low: '57.2300000000000000',
      close: '58.7900000000000000',
      volume: '175752.0092500000000000',
      quoteVolume: 10247903.100617,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '58.8100000000000000',
      high: '61.8700000000000000',
      low: '58.5800000000000000',
      close: '61.4400000000000000',
      volume: '260971.7306100000000000',
      quoteVolume: 15803472.9452996,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '61.4300000000000000',
      high: '61.4300000000000000',
      low: '60.1500000000000000',
      close: '60.5000000000000000',
      volume: '160785.5461800000000000',
      quoteVolume: 9744151.8473039,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '60.4800000000000000',
      high: '61.8000000000000000',
      low: '60.1500000000000000',
      close: '61.4500000000000000',
      volume: '200422.3453500000000000',
      quoteVolume: 12200871.3372611,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '61.4100000000000000',
      high: '61.5600000000000000',
      low: '59.5900000000000000',
      close: '60.4500000000000000',
      volume: '161304.2612800000000000',
      quoteVolume: 9780439.5002168,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '60.4500000000000000',
      high: '60.7200000000000000',
      low: '59.8300000000000000',
      close: '60.4800000000000000',
      volume: '120015.8600500000000000',
      quoteVolume: 7234304.2504601,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '60.4700000000000000',
      high: '60.9000000000000000',
      low: '59.6200000000000000',
      close: '60.4500000000000000',
      volume: '129238.6612100000000000',
      quoteVolume: 7810478.4448446,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '60.4500000000000000',
      high: '78.4000000000000000',
      low: '59.9500000000000000',
      close: '76.7800000000000000',
      volume: '805505.1657500000000000',
      quoteVolume: 55327330.1915808,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '76.7600000000000000',
      high: '98.5000000000000000',
      low: '76.2400000000000000',
      close: '85.1900000000000000',
      volume: '1514710.2931300000000000',
      quoteVolume: 130062156.107703,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '85.0200000000000000',
      high: '89.8500000000000000',
      low: '79.8500000000000000',
      close: '84.7000000000000000',
      volume: '775702.6721700000000000',
      quoteVolume: 66127061.9191573,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '84.7100000000000000',
      high: '89.8500000000000000',
      low: '83.6500000000000000',
      close: '88.2400000000000000',
      volume: '366668.9956300000000000',
      quoteVolume: 31984491.3758827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '88.2400000000000000',
      high: '97.5400000000000000',
      low: '85.0000000000000000',
      close: '92.2100000000000000',
      volume: '706789.2345900000000000',
      quoteVolume: 65322880.0905888,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '92.2300000000000000',
      high: '95.4400000000000000',
      low: '88.7400000000000000',
      close: '91.5500000000000000',
      volume: '404878.1342900000000000',
      quoteVolume: 37409101.1554274,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '91.5500000000000000',
      high: '94.0000000000000000',
      low: '85.2000000000000000',
      close: '88.5700000000000000',
      volume: '439909.3384300000000000',
      quoteVolume: 39092277.7488787,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '88.5700000000000000',
      high: '88.7100000000000000',
      low: '82.6100000000000000',
      close: '86.1700000000000000',
      volume: '317748.7851900000000000',
      quoteVolume: 27340412.18294,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '86.1900000000000000',
      high: '91.1000000000000000',
      low: '85.9100000000000000',
      close: '88.1600000000000000',
      volume: '315727.2367600000000000',
      quoteVolume: 27802373.2737036,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '88.1600000000000000',
      high: '88.4500000000000000',
      low: '74.6400000000000000',
      close: '78.8800000000000000',
      volume: '663804.9849800000000000',
      quoteVolume: 52734752.5700692,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '78.9000000000000000',
      high: '80.4300000000000000',
      low: '75.0000000000000000',
      close: '78.4700000000000000',
      volume: '351092.1192400000000000',
      quoteVolume: 27388660.7258404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '78.4300000000000000',
      high: '81.0200000000000000',
      low: '75.5100000000000000',
      close: '77.6200000000000000',
      volume: '276710.7109000000000000',
      quoteVolume: 21674683.2126471,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '77.5800000000000000',
      high: '83.6000000000000000',
      low: '76.0900000000000000',
      close: '83.2800000000000000',
      volume: '242418.4130300000000000',
      quoteVolume: 19179352.734823,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '83.3100000000000000',
      high: '84.4100000000000000',
      low: '75.0000000000000000',
      close: '78.0700000000000000',
      volume: '437925.6544900000000000',
      quoteVolume: 35039724.5357955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '78.0600000000000000',
      high: '81.4900000000000000',
      low: '76.8800000000000000',
      close: '80.2700000000000000',
      volume: '295134.1772800000000000',
      quoteVolume: 23370026.8609958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '80.2700000000000000',
      high: '81.5000000000000000',
      low: '78.0000000000000000',
      close: '78.7700000000000000',
      volume: '232336.8106200000000000',
      quoteVolume: 18452843.5923519,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '78.8200000000000000',
      high: '83.6600000000000000',
      low: '78.7400000000000000',
      close: '81.6800000000000000',
      volume: '328509.7703700000000000',
      quoteVolume: 26614059.4859404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '81.6700000000000000',
      high: '83.1700000000000000',
      low: '79.4800000000000000',
      close: '82.0500000000000000',
      volume: '265365.4938300000000000',
      quoteVolume: 21606124.7509276,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '82.0500000000000000',
      high: '82.6900000000000000',
      low: '79.3300000000000000',
      close: '81.1500000000000000',
      volume: '209481.5475900000000000',
      quoteVolume: 17009020.0094766,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '81.1600000000000000',
      high: '81.8500000000000000',
      low: '72.8600000000000000',
      close: '76.6500000000000000',
      volume: '405449.3565200000000000',
      quoteVolume: 30882393.8202287,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '76.6100000000000000',
      high: '77.6300000000000000',
      low: '74.9000000000000000',
      close: '76.5100000000000000',
      volume: '285737.8388600000000000',
      quoteVolume: 21814764.7461896,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '76.4700000000000000',
      high: '79.2900000000000000',
      low: '73.3300000000000000',
      close: '73.7000000000000000',
      volume: '408341.2265500000000000',
      quoteVolume: 31534100.588167,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '73.6700000000000000',
      high: '74.6900000000000000',
      low: '69.9000000000000000',
      close: '72.8500000000000000',
      volume: '466422.9092200000000000',
      quoteVolume: 33586009.2516322,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '72.8500000000000000',
      high: '74.4300000000000000',
      low: '70.6000000000000000',
      close: '71.7800000000000000',
      volume: '307408.7038600000000000',
      quoteVolume: 22353656.7217245,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '71.7900000000000000',
      high: '76.5000000000000000',
      low: '70.3100000000000000',
      close: '73.8200000000000000',
      volume: '345014.8767600000000000',
      quoteVolume: 25320328.5480487,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '73.8300000000000000',
      high: '74.0100000000000000',
      low: '71.8300000000000000',
      close: '72.2300000000000000',
      volume: '102336.9821700000000000',
      quoteVolume: 7467848.3762795,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '72.2400000000000000',
      high: '72.7200000000000000',
      low: '68.9500000000000000',
      close: '69.8900000000000000',
      volume: '218635.5895900000000000',
      quoteVolume: 15458571.1783797,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '69.8900000000000000',
      high: '70.3700000000000000',
      low: '66.5000000000000000',
      close: '67.8700000000000000',
      volume: '203603.1860100000000000',
      quoteVolume: 13896456.9662964,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '67.8600000000000000',
      high: '74.7400000000000000',
      low: '67.8600000000000000',
      close: '74.3200000000000000',
      volume: '354469.1353800000000000',
      quoteVolume: 25357609.0003327,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '74.3100000000000000',
      high: '75.3600000000000000',
      low: '71.7800000000000000',
      close: '73.3600000000000000',
      volume: '273831.0476500000000000',
      quoteVolume: 20098972.4966065,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '73.3500000000000000',
      high: '74.4200000000000000',
      low: '72.7300000000000000',
      close: '73.6800000000000000',
      volume: '289626.4536600000000000',
      quoteVolume: 21306943.7993429,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '73.6500000000000000',
      high: '80.5800000000000000',
      low: '73.3100000000000000',
      close: '79.2200000000000000',
      volume: '538448.4052300000000000',
      quoteVolume: 41683556.4117655,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '79.2600000000000000',
      high: '82.7600000000000000',
      low: '74.5400000000000000',
      close: '77.7700000000000000',
      volume: '457004.0148400000000000',
      quoteVolume: 35750061.7116449,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '77.7300000000000000',
      high: '78.1600000000000000',
      low: '75.4000000000000000',
      close: '75.7000000000000000',
      volume: '236821.3124500000000000',
      quoteVolume: 18139200.7639219,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '75.7000000000000000',
      high: '76.9100000000000000',
      low: '71.6400000000000000',
      close: '74.9400000000000000',
      volume: '389445.4803700000000000',
      quoteVolume: 28863797.8869894,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '74.9500000000000000',
      high: '78.7500000000000000',
      low: '73.4000000000000000',
      close: '74.6100000000000000',
      volume: '357677.9279500000000000',
      quoteVolume: 27275279.8649669,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '74.5900000000000000',
      high: '75.5000000000000000',
      low: '72.4000000000000000',
      close: '74.4400000000000000',
      volume: '206081.7854800000000000',
      quoteVolume: 15267753.6578615,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '74.4400000000000000',
      high: '75.9000000000000000',
      low: '72.7700000000000000',
      close: '74.1900000000000000',
      volume: '240477.4366700000000000',
      quoteVolume: 17829294.3569958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '74.1800000000000000',
      high: '79.6100000000000000',
      low: '72.6600000000000000',
      close: '77.1100000000000000',
      volume: '422915.9252000000000000',
      quoteVolume: 32199715.1352361,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '77.1200000000000000',
      high: '95.0000000000000000',
      low: '76.8500000000000000',
      close: '86.5900000000000000',
      volume: '909602.1269400000000000',
      quoteVolume: 78091222.1082642,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '86.5900000000000000',
      high: '94.0100000000000000',
      low: '83.0000000000000000',
      close: '84.7300000000000000',
      volume: '797262.8597300000000000',
      quoteVolume: 69998434.01864,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '84.7100000000000000',
      high: '92.4900000000000000',
      low: '84.1000000000000000',
      close: '87.1600000000000000',
      volume: '627311.3557300000000000',
      quoteVolume: 55391993.134908,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '87.3400000000000000',
      high: '93.6100000000000000',
      low: '86.5000000000000000',
      close: '91.1700000000000000',
      volume: '644139.0646700000000000',
      quoteVolume: 58186818.859561,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '91.1500000000000000',
      high: '104.2500000000000000',
      low: '89.6900000000000000',
      close: '102.0200000000000000',
      volume: '673429.9261200000000000',
      quoteVolume: 66429508.1788531,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '102.0300000000000000',
      high: '107.1400000000000000',
      low: '90.7300000000000000',
      close: '95.4900000000000000',
      volume: '1179885.1078400000000000',
      quoteVolume: 116020063.777305,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '95.4900000000000000',
      high: '97.4800000000000000',
      low: '84.2700000000000000',
      close: '89.7600000000000000',
      volume: '914179.2254800000000000',
      quoteVolume: 80856683.9611846,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '89.7600000000000000',
      high: '90.5100000000000000',
      low: '85.1100000000000000',
      close: '86.5100000000000000',
      volume: '380542.1429700000000000',
      quoteVolume: 33352370.4500154,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '86.5500000000000000',
      high: '96.8100000000000000',
      low: '86.0700000000000000',
      close: '94.5800000000000000',
      volume: '584649.5175900000000000',
      quoteVolume: 54045051.6228448,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '94.5500000000000000',
      high: '94.5800000000000000',
      low: '87.1100000000000000',
      close: '91.1000000000000000',
      volume: '447735.7161500000000000',
      quoteVolume: 40656121.4410844,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '91.1000000000000000',
      high: '93.0200000000000000',
      low: '88.8300000000000000',
      close: '90.7900000000000000',
      volume: '335538.2330900000000000',
      quoteVolume: 30483197.1255505,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '90.7700000000000000',
      high: '92.0400000000000000',
      low: '85.8300000000000000',
      close: '87.7400000000000000',
      volume: '308520.2910900000000000',
      quoteVolume: 27641752.0456785,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '87.7400000000000000',
      high: '89.5300000000000000',
      low: '84.6200000000000000',
      close: '88.6100000000000000',
      volume: '208332.2952500000000000',
      quoteVolume: 18146600.071508,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '88.6700000000000000',
      high: '103.7700000000000000',
      low: '88.0100000000000000',
      close: '100.5000000000000000',
      volume: '832985.1612500000000000',
      quoteVolume: 81794059.9838869,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '100.5000000000000000',
      high: '105.0700000000000000',
      low: '98.8700000000000000',
      close: '102.6600000000000000',
      volume: '337657.9218500000000000',
      quoteVolume: 34485049.017298095,
      btcVolume: 4312.521556865614,
      usdVolume: 34485049.017298095,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '102.6600000000000000',
      high: '114.0000000000000000',
      low: '97.5000000000000000',
      close: '111.0000000000000000',
      volume: '447812.7599800000000000',
      quoteVolume: 46913902.85878797,
      btcVolume: 5689.1831608019,
      usdVolume: 46913902.85878797,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '111.0300000000000000',
      high: '121.8900000000000000',
      low: '109.9400000000000000',
      close: '116.2700000000000000',
      volume: '595659.2299900000000000',
      quoteVolume: 68097250.03244157,
      btcVolume: 7799.762188822423,
      usdVolume: 68097250.03244157,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '116.3000000000000000',
      high: '118.0000000000000000',
      low: '111.1100000000000000',
      close: '114.8100000000000000',
      volume: '230852.9642500000000000',
      quoteVolume: 26424422.174400397,
      btcVolume: 3034.2155133217652,
      usdVolume: 26424422.174400397,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '114.8700000000000000',
      high: '118.3300000000000000',
      low: '108.0000000000000000',
      close: '114.5300000000000000',
      volume: '220738.2597100000000000',
      quoteVolume: 25195330.963828564,
      btcVolume: 2920.4957713299823,
      usdVolume: 25195330.963828564,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '115.0500000000000000',
      high: '120.3500000000000000',
      low: '102.4000000000000000',
      close: '108.2100000000000000',
      volume: '507093.9330100000000000',
      quoteVolume: 57087056.14430182,
      btcVolume: 6672.278677065912,
      usdVolume: 57087056.14430182,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '107.4100000000000000',
      high: '114.9300000000000000',
      low: '104.5600000000000000',
      close: '114.3500000000000000',
      volume: '312792.4647700000000000',
      quoteVolume: 34046399.04506248,
      btcVolume: 4077.7128007930464,
      usdVolume: 34046399.04506248,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '114.8000000000000000',
      high: '116.1000000000000000',
      low: '110.5100000000000000',
      close: '112.3500000000000000',
      volume: '223549.1279200000000000',
      quoteVolume: 25341433.203971107,
      btcVolume: 2969.5187987480676,
      usdVolume: 25341433.203971107,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '112.3400000000000000',
      high: '116.4800000000000000',
      low: '111.2500000000000000',
      close: '114.3900000000000000',
      volume: '233743.1953000000000000',
      quoteVolume: 26688762.017144043,
      btcVolume: 3077.4531043299494,
      usdVolume: 26688762.017144043,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '114.4400000000000000',
      high: '116.2000000000000000',
      low: '105.0100000000000000',
      close: '105.9900000000000000',
      volume: '426880.8260100000000000',
      quoteVolume: 47600822.28424786,
      btcVolume: 5611.544120917245,
      usdVolume: 47600822.28424786,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '105.9900000000000000',
      high: '106.8000000000000000',
      low: '98.1300000000000000',
      close: '102.2000000000000000',
      volume: '673205.5704500000000000',
      quoteVolume: 69225543.10550998,
      btcVolume: 8824.314357109533,
      usdVolume: 69225543.10550998,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '102.2000000000000000',
      high: '105.2100000000000000',
      low: '100.1100000000000000',
      close: '103.6600000000000000',
      volume: '379894.4448300000000000',
      quoteVolume: 39022877.25634307,
      btcVolume: 5026.87552023653,
      usdVolume: 39022877.25634307,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '103.6100000000000000',
      high: '112.0700000000000000',
      low: '101.0000000000000000',
      close: '111.1900000000000000',
      volume: '488706.9407100000000000',
      quoteVolume: 51465078.74484297,
      btcVolume: 6669.936853023607,
      usdVolume: 51465078.74484297,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '111.2500000000000000',
      high: '121.0000000000000000',
      low: '109.5300000000000000',
      close: '116.7100000000000000',
      volume: '619673.8448900000000000',
      quoteVolume: 71546455.96240085,
      btcVolume: 9005.230709084188,
      usdVolume: 71546455.96240085,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '116.7400000000000000',
      high: '119.7000000000000000',
      low: '113.5000000000000000',
      close: '118.0100000000000000',
      volume: '454619.1137200000000000',
      quoteVolume: 52877073.47281,
      btcVolume: 6681.652583502133,
      usdVolume: 52877073.47281,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '118.0100000000000000',
      high: '118.9400000000000000',
      low: '111.0200000000000000',
      close: '114.6600000000000000',
      volume: '425242.5817000000000000',
      quoteVolume: 48979562.40265552,
      btcVolume: 6336.33411727269,
      usdVolume: 48979562.40265552,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '114.6600000000000000',
      high: '130.1200000000000000',
      low: '112.5400000000000000',
      close: '129.1600000000000000',
      volume: '793131.5001000000000000',
      quoteVolume: 97858566.95458615,
      btcVolume: 12435.906067485123,
      usdVolume: 97858566.95458615,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '129.1500000000000000',
      high: '138.5600000000000000',
      low: '124.8500000000000000',
      close: '135.6900000000000000',
      volume: '832736.8101700000000000',
      quoteVolume: 108840543.08616316,
      btcVolume: 13831.644364595131,
      usdVolume: 108840543.08616316,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '135.7900000000000000',
      high: '143.1900000000000000',
      low: '129.8600000000000000',
      close: '135.3200000000000000',
      volume: '1013528.9184000000000000',
      quoteVolume: 139315210.82220733,
      btcVolume: 17393.1824521332,
      usdVolume: 139315210.82220733,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '135.3200000000000000',
      high: '136.5200000000000000',
      low: '127.9200000000000000',
      close: '130.5500000000000000',
      volume: '477007.3052300000000000',
      quoteVolume: 63425735.66279704,
      btcVolume: 7773.751516352979,
      usdVolume: 63425735.66279704,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '130.5500000000000000',
      high: '133.0300000000000000',
      low: '125.2200000000000000',
      close: '132.0000000000000000',
      volume: '460272.4765100000000000',
      quoteVolume: 59616339.318167776,
      btcVolume: 7149.36358649792,
      usdVolume: 59616339.318167776,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '132.0300000000000000',
      high: '139.5000000000000000',
      low: '132.0100000000000000',
      close: '137.5400000000000000',
      volume: '389609.6416600000000000',
      quoteVolume: 52876679.67863012,
      btcVolume: 6089.583260704667,
      usdVolume: 52876679.67863012,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '137.5100000000000000',
      high: '138.2000000000000000',
      low: '133.1500000000000000',
      close: '136.4600000000000000',
      volume: '392160.2389100000000000',
      quoteVolume: 53217106.12389209,
      btcVolume: 5876.901296371626,
      usdVolume: 53217106.12389209,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '136.4500000000000000',
      high: '136.8200000000000000',
      low: '131.5300000000000000',
      close: '133.8000000000000000',
      volume: '302482.8519500000000000',
      quoteVolume: 40631956.395158365,
      btcVolume: 4412.679894293252,
      usdVolume: 40631956.395158365,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '133.8000000000000000',
      high: '136.8400000000000000',
      low: '128.0000000000000000',
      close: '135.2200000000000000',
      volume: '400793.1621100000000000',
      quoteVolume: 53271407.49490323,
      btcVolume: 5834.779980955947,
      usdVolume: 53232164.38223043,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '135.2500000000000000',
      high: '139.5000000000000000',
      low: '133.5000000000000000',
      close: '136.4300000000000000',
      volume: '373848.4380200000000000',
      quoteVolume: 51215339.67678405,
      btcVolume: 5598.390589121898,
      usdVolume: 51215339.67678405,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '136.4100000000000000',
      high: '136.7500000000000000',
      low: '133.0000000000000000',
      close: '135.4600000000000000',
      volume: '226900.6498000000000000',
      quoteVolume: 30613327.430043694,
      btcVolume: 3276.5296546410664,
      usdVolume: 30613327.430043694,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '135.4500000000000000',
      high: '141.2900000000000000',
      low: '131.0000000000000000',
      close: '138.2400000000000000',
      volume: '392322.7834100000000000',
      quoteVolume: 53942344.98886258,
      btcVolume: 5520.032818796717,
      usdVolume: 53942344.98886258,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '138.2400000000000000',
      high: '145.9000000000000000',
      low: '136.0100000000000000',
      close: '142.2600000000000000',
      volume: '663584.3836600000000000',
      quoteVolume: 93771724.38599907,
      btcVolume: 8742.486600283723,
      usdVolume: 93771724.38599907,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '142.2000000000000000',
      high: '143.3400000000000000',
      low: '136.1500000000000000',
      close: '137.5000000000000000',
      volume: '272572.6124300000000000',
      quoteVolume: 38252959.48642319,
      btcVolume: 3516.3861683369537,
      usdVolume: 38252959.48642319,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '137.5000000000000000',
      high: '137.5200000000000000',
      low: '132.6500000000000000',
      close: '135.7700000000000000',
      volume: '269139.9196400000000000',
      quoteVolume: 36393766.969366886,
      btcVolume: 3342.376735778028,
      usdVolume: 36393766.969366886,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '135.7800000000000000',
      high: '138.6300000000000000',
      low: '129.7500000000000000',
      close: '136.2900000000000000',
      volume: '342770.9536500000000000',
      quoteVolume: 46148912.96185091,
      btcVolume: 4050.110257770818,
      usdVolume: 46148912.96185091,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '136.3300000000000000',
      high: '138.5000000000000000',
      low: '127.5000000000000000',
      close: '132.2500000000000000',
      volume: '636720.2570400000000000',
      quoteVolume: 86027810.12377585,
      btcVolume: 6747.88318704931,
      usdVolume: 86027810.12377585,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '132.2400000000000000',
      high: '133.5000000000000000',
      low: '111.8200000000000000',
      close: '116.0100000000000000',
      volume: '925760.4631400000000000',
      quoteVolume: 110853611.31913063,
      btcVolume: 9337.051184083171,
      usdVolume: 110853611.31913063,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '116.0200000000000000',
      high: '122.1900000000000000',
      low: '114.4900000000000000',
      close: '120.0100000000000000',
      volume: '455533.6026900000000000',
      quoteVolume: 53918279.744928494,
      btcVolume: 4582.2478046452115,
      usdVolume: 53918279.744928494,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '119.9900000000000000',
      high: '137.0000000000000000',
      low: '116.0000000000000000',
      close: '133.8600000000000000',
      volume: '650765.6611200000000000',
      quoteVolume: 82923808.74529916,
      btcVolume: 6965.579657418369,
      usdVolume: 82923808.74529916,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '133.8800000000000000',
      high: '137.4400000000000000',
      low: '120.5400000000000000',
      close: '123.2000000000000000',
      volume: '808624.4477500000000000',
      quoteVolume: 105465556.33002208,
      btcVolume: 9174.964797036842,
      usdVolume: 105465556.33002208,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '123.2100000000000000',
      high: '127.0300000000000000',
      low: '113.8200000000000000',
      close: '123.1700000000000000',
      volume: '612082.5504700000000000',
      quoteVolume: 74031245.77021483,
      btcVolume: 6937.17995542507,
      usdVolume: 74031245.77021483,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '123.1600000000000000',
      high: '124.0000000000000000',
      low: '112.6300000000000000',
      close: '118.7700000000000000',
      volume: '468821.0334100000000000',
      quoteVolume: 54961125.00467627,
      btcVolume: 5357.843844817624,
      usdVolume: 54961125.00467627,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '118.7400000000000000',
      high: '123.1500000000000000',
      low: '116.7800000000000000',
      close: '121.3600000000000000',
      volume: '365004.8130000000000000',
      quoteVolume: 43802919.31616289,
      btcVolume: 3864.830877205342,
      usdVolume: 43802919.31616289,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '121.3400000000000000',
      high: '127.0300000000000000',
      low: '118.5100000000000000',
      close: '119.6100000000000000',
      volume: '374342.5657700000000000',
      quoteVolume: 45835115.28303999,
      btcVolume: 3922.4531882102947,
      usdVolume: 45835115.28303999,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '119.6400000000000000',
      high: '120.9900000000000000',
      low: '116.2200000000000000',
      close: '118.3500000000000000',
      volume: '223506.3921400000000000',
      quoteVolume: 26561837.46533212,
      btcVolume: 2393.4466400120245,
      usdVolume: 26561837.46533212,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '118.2900000000000000',
      high: '121.8000000000000000',
      low: '117.0600000000000000',
      close: '118.3100000000000000',
      volume: '153807.7581500000000000',
      quoteVolume: 18322698.17243,
      btcVolume: 1610.505545271505,
      usdVolume: 18322698.17243,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '118.2800000000000000',
      high: '120.3500000000000000',
      low: '117.0000000000000000',
      close: '119.4800000000000000',
      volume: '142378.6142900000000000',
      quoteVolume: 16885095.364536773,
      btcVolume: 1493.6997705844158,
      usdVolume: 16885095.364536773,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '119.4800000000000000',
      high: '125.0700000000000000',
      low: '117.7700000000000000',
      close: '122.9200000000000000',
      volume: '217712.8518000000000000',
      quoteVolume: 26152584.18533981,
      btcVolume: 2213.2029853237336,
      usdVolume: 26152584.18533981,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '122.9200000000000000',
      high: '124.0800000000000000',
      low: '117.6400000000000000',
      close: '119.2400000000000000',
      volume: '229937.7747500000000000',
      quoteVolume: 27739458.330567557,
      btcVolume: 2229.1310227514605,
      usdVolume: 27739458.330567557,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '119.2400000000000000',
      high: '120.1300000000000000',
      low: '102.5000000000000000',
      close: '108.3100000000000000',
      volume: '505822.3816400000000000',
      quoteVolume: 55612005.274497144,
      btcVolume: 4459.300542159586,
      usdVolume: 55612005.274497144,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '108.2900000000000000',
      high: '108.3300000000000000',
      low: '95.2100000000000000',
      close: '102.9800000000000000',
      volume: '559700.8997700000000000',
      quoteVolume: 56859127.19466299,
      btcVolume: 4940.505976488649,
      usdVolume: 56859127.19466299,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '103.0000000000000000',
      high: '107.0200000000000000',
      low: '100.6500000000000000',
      close: '104.8300000000000000',
      volume: '204844.2164100000000000',
      quoteVolume: 21371324.036817204,
      btcVolume: 1850.9778044022698,
      usdVolume: 21371324.036817204,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '104.8400000000000000',
      high: '105.0300000000000000',
      low: '97.6400000000000000',
      close: '101.0300000000000000',
      volume: '229334.5614600000000000',
      quoteVolume: 23264553.92123755,
      btcVolume: 2051.3447663181432,
      usdVolume: 23264553.92123755,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '101.0600000000000000',
      high: '101.9000000000000000',
      low: '87.5800000000000000',
      close: '89.7300000000000000',
      volume: '423313.0351200000000000',
      quoteVolume: 39325264.58610615,
      btcVolume: 3681.02471090942,
      usdVolume: 39325264.58610615,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '89.6800000000000000',
      high: '92.9900000000000000',
      low: '84.6000000000000000',
      close: '90.4300000000000000',
      volume: '382007.6629800000000000',
      quoteVolume: 34014962.47825661,
      btcVolume: 3290.421882815431,
      usdVolume: 34014962.47825661,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '90.4300000000000000',
      high: '91.9100000000000000',
      low: '76.6000000000000000',
      close: '79.5600000000000000',
      volume: '476050.7487700000000000',
      quoteVolume: 39592816.252067514,
      btcVolume: 3948.301710139775,
      usdVolume: 39592816.252067514,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '79.5700000000000000',
      high: '94.6500000000000000',
      low: '71.0000000000000000',
      close: '89.9500000000000000',
      volume: '611087.7265300000000000',
      quoteVolume: 53122108.969450906,
      btcVolume: 5523.905454198043,
      usdVolume: 53122108.969450906,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '89.9800000000000000',
      high: '104.5000000000000000',
      low: '86.8700000000000000',
      close: '101.1600000000000000',
      volume: '608935.5958700000000000',
      quoteVolume: 58255818.192272015,
      btcVolume: 5773.175422048092,
      usdVolume: 58215321.1838005,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '101.1500000000000000',
      high: '101.2600000000000000',
      low: '94.7300000000000000',
      close: '98.2300000000000000',
      volume: '364731.5720400000000000',
      quoteVolume: 35601483.447144754,
      btcVolume: 3411.5750330938563,
      usdVolume: 35601483.447144754,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '98.2500000000000000',
      high: '105.8800000000000000',
      low: '97.4200000000000000',
      close: '100.2500000000000000',
      volume: '355101.6718200000000000',
      quoteVolume: 35894490.36556177,
      btcVolume: 3363.2675353843997,
      usdVolume: 35894490.36556177,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '100.2600000000000000',
      high: '101.4100000000000000',
      low: '96.4000000000000000',
      close: '99.7000000000000000',
      volume: '247040.7271900000000000',
      quoteVolume: 24369602.513854142,
      btcVolume: 2307.538644188406,
      usdVolume: 24369602.513854142,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '99.7400000000000000',
      high: '100.9100000000000000',
      low: '91.1400000000000000',
      close: '95.3100000000000000',
      volume: '316815.0448700000000000',
      quoteVolume: 30213787.40266795,
      btcVolume: 2901.236765354251,
      usdVolume: 30213787.40266795,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '95.3100000000000000',
      high: '95.3500000000000000',
      low: '88.6200000000000000',
      close: '90.1500000000000000',
      volume: '264516.2157400000000000',
      quoteVolume: 24185689.349569995,
      btcVolume: 2407.8084257111295,
      usdVolume: 24185689.349569995,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '90.1000000000000000',
      high: '97.4500000000000000',
      low: '86.8000000000000000',
      close: '94.5000000000000000',
      volume: '354766.4814900000000000',
      quoteVolume: 32545800.829587497,
      btcVolume: 3344.4513821679425,
      usdVolume: 32545800.829587497,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '94.4300000000000000',
      high: '96.6700000000000000',
      low: '91.0600000000000000',
      close: '93.2200000000000000',
      volume: '228174.7700300000000000',
      quoteVolume: 21502628.636138897,
      btcVolume: 2150.016682373243,
      usdVolume: 21502628.636138897,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '93.2300000000000000',
      high: '94.5200000000000000',
      low: '89.8300000000000000',
      close: '94.0600000000000000',
      volume: '169001.4772100000000000',
      quoteVolume: 15639015.230352713,
      btcVolume: 1602.9055728539722,
      usdVolume: 15639015.230352713,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '94.0100000000000000',
      high: '96.0900000000000000',
      low: '86.6700000000000000',
      close: '88.7900000000000000',
      volume: '246284.6023800000000000',
      quoteVolume: 22269324.203205597,
      btcVolume: 2305.886333888476,
      usdVolume: 22269324.203205597,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '88.8300000000000000',
      high: '90.9000000000000000',
      low: '83.1300000000000000',
      close: '89.9300000000000000',
      volume: '196819.9021300000000000',
      quoteVolume: 17358318.803394448,
      btcVolume: 1837.2083760848138,
      usdVolume: 17358318.803394448,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '89.9700000000000000',
      high: '92.2600000000000000',
      low: '87.4800000000000000',
      close: '90.3300000000000000',
      volume: '232648.9633600000000000',
      quoteVolume: 20980770.098834805,
      btcVolume: 2194.949487131651,
      usdVolume: 20980770.098834805,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '90.3300000000000000',
      high: '92.7100000000000000',
      low: '88.5000000000000000',
      close: '90.1500000000000000',
      volume: '132728.7110700000000000',
      quoteVolume: 11999589.695907073,
      btcVolume: 1256.76640132148,
      usdVolume: 11999589.695907073,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '90.1200000000000000',
      high: '99.9500000000000000',
      low: '89.8100000000000000',
      close: '98.7600000000000000',
      volume: '280442.0190700000000000',
      quoteVolume: 26802062.262311406,
      btcVolume: 2708.4298976866316,
      usdVolume: 26802062.262311406,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '98.7700000000000000',
      high: '100.5900000000000000',
      low: '95.2300000000000000',
      close: '98.6400000000000000',
      volume: '256621.8002700000000000',
      quoteVolume: 25131509.021462236,
      btcVolume: 2491.621683425381,
      usdVolume: 25131509.021462236,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '98.6200000000000000',
      high: '98.7700000000000000',
      low: '92.5200000000000000',
      close: '94.7600000000000000',
      volume: '284185.5684900000000000',
      quoteVolume: 27263314.77004936,
      btcVolume: 2606.5500325395974,
      usdVolume: 27263314.77004936,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '94.8000000000000000',
      high: '96.7000000000000000',
      low: '93.5100000000000000',
      close: '94.1700000000000000',
      volume: '187442.2663600000000000',
      quoteVolume: 17836682.37128238,
      btcVolume: 1658.7354555809563,
      usdVolume: 17836682.37128238,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '94.1500000000000000',
      high: '94.8000000000000000',
      low: '90.1100000000000000',
      close: '92.6400000000000000',
      volume: '209831.4188500000000000',
      quoteVolume: 19390235.069303315,
      btcVolume: 1799.4440000625016,
      usdVolume: 19390235.069303315,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '92.6800000000000000',
      high: '106.7700000000000000',
      low: '92.6800000000000000',
      close: '96.6100000000000000',
      volume: '707982.8941800000000000',
      quoteVolume: 70027160.96589696,
      btcVolume: 5999.5126965229465,
      usdVolume: 70027160.96589696,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '96.5600000000000000',
      high: '99.3000000000000000',
      low: '91.0000000000000000',
      close: '92.9900000000000000',
      volume: '363691.9904700000000000',
      quoteVolume: 34611183.819958195,
      btcVolume: 2931.27156672291,
      usdVolume: 34611183.819958195,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '93.0500000000000000',
      high: '94.4500000000000000',
      low: '87.3600000000000000',
      close: '90.5500000000000000',
      volume: '295995.6655100000000000',
      quoteVolume: 26997231.240942493,
      btcVolume: 2299.5786668850474,
      usdVolume: 26997231.240942493,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '90.5900000000000000',
      high: '92.7500000000000000',
      low: '87.7200000000000000',
      close: '90.1400000000000000',
      volume: '226973.5770400000000000',
      quoteVolume: 20399520.9403848,
      btcVolume: 1729.0445142042847,
      usdVolume: 20399520.9403848,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '90.1400000000000000',
      high: '90.4500000000000000',
      low: '82.6400000000000000',
      close: '84.3300000000000000',
      volume: '244679.6808500000000000',
      quoteVolume: 21052903.37981216,
      btcVolume: 1782.1537264855651,
      usdVolume: 21052903.37981216,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '84.2500000000000000',
      high: '87.5000000000000000',
      low: '82.2700000000000000',
      close: '85.4600000000000000',
      volume: '240013.8068600000000000',
      quoteVolume: 20434411.59965533,
      btcVolume: 1765.6610402914791,
      usdVolume: 20434411.59965533,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '85.4600000000000000',
      high: '91.5700000000000000',
      low: '85.1500000000000000',
      close: '89.9400000000000000',
      volume: '196224.6058500000000000',
      quoteVolume: 17374539.532690413,
      btcVolume: 1524.9630326426839,
      usdVolume: 17374539.532690413,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '89.9200000000000000',
      high: '90.0400000000000000',
      low: '85.4500000000000000',
      close: '85.7900000000000000',
      volume: '148132.4291500000000000',
      quoteVolume: 12892549.668831954,
      btcVolume: 1130.0638225098155,
      usdVolume: 12892549.668831954,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '85.7900000000000000',
      high: '86.7900000000000000',
      low: '83.2600000000000000',
      close: '84.8800000000000000',
      volume: '166783.8095700000000000',
      quoteVolume: 14166418.159683961,
      btcVolume: 1274.1904971222834,
      usdVolume: 14166418.159683961,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '84.8300000000000000',
      high: '86.3200000000000000',
      low: '75.0000000000000000',
      close: '75.9000000000000000',
      volume: '301247.4537300000000000',
      quoteVolume: 24226106.115131296,
      btcVolume: 2325.4823677043105,
      usdVolume: 24226106.115131296,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '75.9100000000000000',
      high: '77.7000000000000000',
      low: '74.3600000000000000',
      close: '76.2500000000000000',
      volume: '155744.5086700000000000',
      quoteVolume: 11881666.830015346,
      btcVolume: 1170.7221866608465,
      usdVolume: 11881666.830015346,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '76.2400000000000000',
      high: '76.4200000000000000',
      low: '72.3400000000000000',
      close: '74.6700000000000000',
      volume: '186709.0272800000000000',
      quoteVolume: 13900659.419270849,
      btcVolume: 1370.0754829605883,
      usdVolume: 13900659.419270849,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '74.6300000000000000',
      high: '75.3500000000000000',
      low: '71.5000000000000000',
      close: '72.6500000000000000',
      volume: '131037.3773100000000000',
      quoteVolume: 9606480.326331407,
      btcVolume: 935.0616632613101,
      usdVolume: 9606480.326331407,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '72.6400000000000000',
      high: '78.0000000000000000',
      low: '71.4000000000000000',
      close: '76.2500000000000000',
      volume: '171758.4529300000000000',
      quoteVolume: 12942681.697281959,
      btcVolume: 1254.3177248522557,
      usdVolume: 12942681.697281959,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '76.3100000000000000',
      high: '78.9500000000000000',
      low: '75.3600000000000000',
      close: '77.1000000000000000',
      volume: '176320.8779000000000000',
      quoteVolume: 13602703.9572976,
      btcVolume: 1278.3583112223776,
      usdVolume: 13602703.9572976,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '77.1000000000000000',
      high: '77.3000000000000000',
      low: '73.3100000000000000',
      close: '75.1400000000000000',
      volume: '130272.1040200000000000',
      quoteVolume: 9809533.243689496,
      btcVolume: 914.3887166901854,
      usdVolume: 9809533.243689496,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '75.1300000000000000',
      high: '75.3300000000000000',
      low: '70.1400000000000000',
      close: '73.2700000000000000',
      volume: '188369.4364000000000000',
      quoteVolume: 13677422.750244448,
      btcVolume: 1345.3220954490534,
      usdVolume: 13677422.750244448,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '73.1800000000000000',
      high: '74.6400000000000000',
      low: '71.0000000000000000',
      close: '73.3100000000000000',
      volume: '159574.3573900000000000',
      quoteVolume: 11682660.11134095,
      btcVolume: 1163.5406280504114,
      usdVolume: 11682660.11134095,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '73.3800000000000000',
      high: '75.9200000000000000',
      low: '72.1000000000000000',
      close: '75.3800000000000000',
      volume: '126538.7728700000000000',
      quoteVolume: 9392367.036347631,
      btcVolume: 914.8262129553217,
      usdVolume: 9392367.036347631,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '75.3700000000000000',
      high: '75.6500000000000000',
      low: '71.3900000000000000',
      close: '73.3200000000000000',
      volume: '119835.6546400000000000',
      quoteVolume: 8766693.140755767,
      btcVolume: 866.621719030702,
      usdVolume: 8766693.140755767,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '73.3200000000000000',
      high: '74.9300000000000000',
      low: '70.5600000000000000',
      close: '72.1000000000000000',
      volume: '141416.0203900000000000',
      quoteVolume: 10265920.916051323,
      btcVolume: 1018.3454425401993,
      usdVolume: 10265920.916051323,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '72.1200000000000000',
      high: '75.4600000000000000',
      low: '72.0900000000000000',
      close: '73.5400000000000000',
      volume: '183788.5068600000000000',
      quoteVolume: 13607022.423831994,
      btcVolume: 1313.6613691337795,
      usdVolume: 13607022.423831994,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '73.5900000000000000',
      high: '73.7500000000000000',
      low: '72.1300000000000000',
      close: '72.9000000000000000',
      volume: '92746.0175100000000000',
      quoteVolume: 6757231.102277973,
      btcVolume: 662.8619923714382,
      usdVolume: 6757231.102277973,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '72.8800000000000000',
      high: '73.1400000000000000',
      low: '65.0000000000000000',
      close: '66.7300000000000000',
      volume: '279997.3057300000000000',
      quoteVolume: 19218269.137130044,
      btcVolume: 1926.7857817998536,
      usdVolume: 19218269.137130044,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '66.7300000000000000',
      high: '66.7700000000000000',
      low: '62.0000000000000000',
      close: '63.5900000000000000',
      volume: '206125.3285400000000000',
      quoteVolume: 13327397.63727353,
      btcVolume: 1401.9829055159644,
      usdVolume: 13327397.63727353,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '63.5500000000000000',
      high: '65.6000000000000000',
      low: '62.1600000000000000',
      close: '64.1700000000000000',
      volume: '188502.6473100000000000',
      quoteVolume: 12025107.367332652,
      btcVolume: 1260.478351604105,
      usdVolume: 12025107.367332652,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '64.1800000000000000',
      high: '65.3900000000000000',
      low: '62.2500000000000000',
      close: '64.2400000000000000',
      volume: '148167.1587700000000000',
      quoteVolume: 9443772.20454113,
      btcVolume: 985.5488740690859,
      usdVolume: 9443772.20454113,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '64.2300000000000000',
      high: '67.2100000000000000',
      low: '63.7400000000000000',
      close: '65.8900000000000000',
      volume: '160360.7877400000000000',
      quoteVolume: 10473417.107566353,
      btcVolume: 1088.2357260615822,
      usdVolume: 10473417.107566353,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '65.8500000000000000',
      high: '68.0300000000000000',
      low: '64.8000000000000000',
      close: '66.9200000000000000',
      volume: '210394.3789600000000000',
      quoteVolume: 13944978.885087235,
      btcVolume: 1390.3786949813993,
      usdVolume: 13944978.885087235,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '66.9200000000000000',
      high: '71.0000000000000000',
      low: '66.4700000000000000',
      close: '69.0600000000000000',
      volume: '285573.8842600000000000',
      quoteVolume: 19591536.12131445,
      btcVolume: 1861.1902617597352,
      usdVolume: 19591536.12131445,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '69.0000000000000000',
      high: '69.2400000000000000',
      low: '66.4000000000000000',
      close: '67.0100000000000000',
      volume: '219414.7318900000000000',
      quoteVolume: 14835718.502787702,
      btcVolume: 1407.2822863278584,
      usdVolume: 14835718.502787702,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '67.0200000000000000',
      high: '67.3900000000000000',
      low: '64.1400000000000000',
      close: '65.2400000000000000',
      volume: '165280.2625800000000000',
      quoteVolume: 10833905.336375384,
      btcVolume: 1026.921360854257,
      usdVolume: 10833905.336375384,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '65.2300000000000000',
      high: '68.0000000000000000',
      low: '63.5400000000000000',
      close: '64.8700000000000000',
      volume: '269355.4199300000000000',
      quoteVolume: 17769105.790459905,
      btcVolume: 1683.5510553061292,
      usdVolume: 17769105.790459905,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '64.8800000000000000',
      high: '70.5700000000000000',
      low: '64.5300000000000000',
      close: '68.7800000000000000',
      volume: '305074.1416000000000000',
      quoteVolume: 20786479.847835682,
      btcVolume: 1993.4143229328859,
      usdVolume: 20786479.847835682,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '68.7700000000000000',
      high: '71.4600000000000000',
      low: '68.0000000000000000',
      close: '70.3600000000000000',
      volume: '313588.1220800000000000',
      quoteVolume: 21958484.40247299,
      btcVolume: 2102.4332066868055,
      usdVolume: 21958484.40247299,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '70.3600000000000000',
      high: '71.3800000000000000',
      low: '67.7300000000000000',
      close: '69.8900000000000000',
      volume: '227135.8567900000000000',
      quoteVolume: 15787764.06820893,
      btcVolume: 1535.4445818677495,
      usdVolume: 15787764.06820893,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '69.8600000000000000',
      high: '73.1000000000000000',
      low: '69.7700000000000000',
      close: '70.8500000000000000',
      volume: '315418.1644100000000000',
      quoteVolume: 22463397.73757165,
      btcVolume: 2195.2854664792503,
      usdVolume: 22463397.73757165,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '70.8500000000000000',
      high: '71.8100000000000000',
      low: '68.2500000000000000',
      close: '69.8000000000000000',
      volume: '208661.7044600000000000',
      quoteVolume: 14616150.160225926,
      btcVolume: 1453.8574985722253,
      usdVolume: 14616150.160225926,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '69.8000000000000000',
      high: '70.0000000000000000',
      low: '68.3000000000000000',
      close: '69.1500000000000000',
      volume: '135127.9931800000000000',
      quoteVolume: 9339546.336077025,
      btcVolume: 912.766277649649,
      usdVolume: 9339546.336077025,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '69.1800000000000000',
      high: '69.5800000000000000',
      low: '67.3600000000000000',
      close: '68.9300000000000000',
      volume: '156416.2924400000000000',
      quoteVolume: 10722817.409428386,
      btcVolume: 1041.7990264891057,
      usdVolume: 10722817.409428386,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '68.9300000000000000',
      high: '71.5000000000000000',
      low: '68.2000000000000000',
      close: '70.5000000000000000',
      volume: '178914.2774100000000000',
      quoteVolume: 12510402.533513518,
      btcVolume: 1210.141755485563,
      usdVolume: 12510402.533513518,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '70.4900000000000000',
      high: '71.2800000000000000',
      low: '69.5000000000000000',
      close: '70.0400000000000000',
      volume: '123157.5877600000000000',
      quoteVolume: 8653254.98748152,
      btcVolume: 839.9682939747071,
      usdVolume: 8653254.98748152,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '70.0400000000000000',
      high: '73.6700000000000000',
      low: '69.3600000000000000',
      close: '72.6300000000000000',
      volume: '266145.5193300000000000',
      quoteVolume: 18990638.744624417,
      btcVolume: 1850.305593630325,
      usdVolume: 18990638.744624417,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '72.6700000000000000',
      high: '77.5900000000000000',
      low: '72.1500000000000000',
      close: '74.0400000000000000',
      volume: '331768.0453000000000000',
      quoteVolume: 24570461.734894328,
      btcVolume: 2405.01019871254,
      usdVolume: 24570461.734894328,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '74.0600000000000000',
      high: '80.3000000000000000',
      low: '73.9800000000000000',
      close: '77.8100000000000000',
      volume: '463500.2471200000000000',
      quoteVolume: 35715978.31337182,
      btcVolume: 3505.5073405779085,
      usdVolume: 35715978.31337182,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '77.7700000000000000',
      high: '80.9000000000000000',
      low: '72.6200000000000000',
      close: '76.5500000000000000',
      volume: '501398.1546800000000000',
      quoteVolume: 37733017.621873595,
      btcVolume: 3774.092404497032,
      usdVolume: 37733017.621873595,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '76.5600000000000000',
      high: '77.0800000000000000',
      low: '72.7800000000000000',
      close: '75.0500000000000000',
      volume: '285843.0479300000000000',
      quoteVolume: 21452170.229147196,
      btcVolume: 2108.9030586427175,
      usdVolume: 21452170.229147196,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '75.0600000000000000',
      high: '75.5800000000000000',
      low: '72.0600000000000000',
      close: '73.2200000000000000',
      volume: '211641.7919700000000000',
      quoteVolume: 15603395.96512544,
      btcVolume: 1554.647856825871,
      usdVolume: 15603395.96512544,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '73.2000000000000000',
      high: '73.2200000000000000',
      low: '71.0300000000000000',
      close: '72.2300000000000000',
      volume: '219379.8472300000000000',
      quoteVolume: 15822626.780132813,
      btcVolume: 1585.411547795001,
      usdVolume: 15822626.780132813,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '72.2600000000000000',
      high: '74.2400000000000000',
      low: '61.8800000000000000',
      close: '66.5400000000000000',
      volume: '526339.5301500000000000',
      quoteVolume: 36437108.07404466,
      btcVolume: 3714.5060783639537,
      usdVolume: 36437108.07404466,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '66.5400000000000000',
      high: '67.4200000000000000',
      low: '54.3400000000000000',
      close: '54.5700000000000000',
      volume: '896194.8912400000000000',
      quoteVolume: 54840616.36898153,
      btcVolume: 6077.979287792019,
      usdVolume: 54840616.36898153,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '54.5700000000000000',
      high: '58.3400000000000000',
      low: '52.8400000000000000',
      close: '57.4300000000000000',
      volume: '780933.6156200000000000',
      quoteVolume: 43514734.915106036,
      btcVolume: 5172.0466578427,
      usdVolume: 43514734.915106036,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '57.4800000000000000',
      high: '57.5600000000000000',
      low: '50.3000000000000000',
      close: '55.1900000000000000',
      volume: '620655.1100700000000000',
      quoteVolume: 33894831.566069685,
      btcVolume: 4162.554218117035,
      usdVolume: 33894831.566069685,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '55.1700000000000000',
      high: '56.6100000000000000',
      low: '52.6100000000000000',
      close: '55.8600000000000000',
      volume: '390319.7644900000000000',
      quoteVolume: 21346773.06550875,
      btcVolume: 2660.2624788155385,
      usdVolume: 21346773.06550875,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '55.8600000000000000',
      high: '56.1600000000000000',
      low: '53.6700000000000000',
      close: '55.3700000000000000',
      volume: '256916.9284600000000000',
      quoteVolume: 14172326.366005592,
      btcVolume: 1741.149072225589,
      usdVolume: 14172326.366005592,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '55.3700000000000000',
      high: '55.5800000000000000',
      low: '52.1300000000000000',
      close: '53.8200000000000000',
      volume: '227396.1860800000000000',
      quoteVolume: 12189476.348992856,
      btcVolume: 1518.3799061716697,
      usdVolume: 12189476.348992856,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '53.8400000000000000',
      high: '56.2500000000000000',
      low: '52.4600000000000000',
      close: '55.9900000000000000',
      volume: '369529.6878700000000000',
      quoteVolume: 20182788.05999346,
      btcVolume: 2510.805443251447,
      usdVolume: 20182788.05999346,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '56.0100000000000000',
      high: '57.6000000000000000',
      low: '54.8200000000000000',
      close: '55.6300000000000000',
      volume: '333516.6371400000000000',
      quoteVolume: 18701415.035006244,
      btcVolume: 2241.634536922254,
      usdVolume: 18701415.035006244,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '55.5900000000000000',
      high: '56.6600000000000000',
      low: '55.1300000000000000',
      close: '56.2300000000000000',
      volume: '215239.2591000000000000',
      quoteVolume: 12024564.531152936,
      btcVolume: 1459.108573099224,
      usdVolume: 12024564.531152936,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '56.2800000000000000',
      high: '57.0700000000000000',
      low: '53.6200000000000000',
      close: '56.4400000000000000',
      volume: '253120.5364200000000000',
      quoteVolume: 14050677.75934256,
      btcVolume: 1717.4520781431509,
      usdVolume: 14050677.75934256,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '56.4500000000000000',
      high: '57.5000000000000000',
      low: '55.2200000000000000',
      close: '56.3000000000000000',
      volume: '247079.7704500000000000',
      quoteVolume: 13984268.782087287,
      btcVolume: 1718.1473442856827,
      usdVolume: 13984268.782087287,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '56.3000000000000000',
      high: '57.4000000000000000',
      low: '55.5700000000000000',
      close: '56.6900000000000000',
      volume: '177602.1005800000000000',
      quoteVolume: 10003772.607079951,
      btcVolume: 1237.007987145328,
      usdVolume: 10003772.607079951,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '56.6900000000000000',
      high: '56.8000000000000000',
      low: '52.9500000000000000',
      close: '54.4100000000000000',
      volume: '255560.2861700000000000',
      quoteVolume: 14075211.354071734,
      btcVolume: 1771.6354364948543,
      usdVolume: 14075211.354071734,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '54.4200000000000000',
      high: '58.6500000000000000',
      low: '53.2500000000000000',
      close: '57.4600000000000000',
      volume: '314651.2758500000000000',
      quoteVolume: 17736478.819784664,
      btcVolume: 2198.803572764758,
      usdVolume: 17736478.819784664,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '57.4600000000000000',
      high: '58.4500000000000000',
      low: '56.4700000000000000',
      close: '56.9500000000000000',
      volume: '184191.0427500000000000',
      quoteVolume: 10558792.262372462,
      btcVolume: 1286.8688325201551,
      usdVolume: 10558792.262372462,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '56.9500000000000000',
      high: '60.0600000000000000',
      low: '56.6000000000000000',
      close: '59.1200000000000000',
      volume: '337686.6303300000000000',
      quoteVolume: 19730452.316288013,
      btcVolume: 2355.7027861823267,
      usdVolume: 19730452.316288013,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '59.1000000000000000',
      high: '59.3300000000000000',
      low: '57.0000000000000000',
      close: '57.4700000000000000',
      volume: '215031.0358800000000000',
      quoteVolume: 12487431.424961781,
      btcVolume: 1463.474709248515,
      usdVolume: 12487431.424961781,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '57.4800000000000000',
      high: '58.4200000000000000',
      low: '55.3300000000000000',
      close: '55.8500000000000000',
      volume: '265707.6043700000000000',
      quoteVolume: 15003032.363513099,
      btcVolume: 1779.3269982097474,
      usdVolume: 15003032.363513099,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '55.8500000000000000',
      high: '56.5000000000000000',
      low: '55.4100000000000000',
      close: '55.7000000000000000',
      volume: '101055.5079000000000000',
      quoteVolume: 5657808.268919437,
      btcVolume: 679.6634476450376,
      usdVolume: 5657808.268919437,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '55.7100000000000000',
      high: '57.6900000000000000',
      low: '55.4700000000000000',
      close: '56.4600000000000000',
      volume: '157772.3878500000000000',
      quoteVolume: 8939980.18918927,
      btcVolume: 1071.554187571745,
      usdVolume: 8939980.18918927,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '56.4400000000000000',
      high: '57.1000000000000000',
      low: '55.9400000000000000',
      close: '56.8300000000000000',
      volume: '133356.2528900000000000',
      quoteVolume: 7554339.147984878,
      btcVolume: 910.4939564933641,
      usdVolume: 7554339.147984878,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '56.8300000000000000',
      high: '57.3900000000000000',
      low: '53.4000000000000000',
      close: '54.5800000000000000',
      volume: '329395.4878400000000000',
      quoteVolume: 18207494.81235211,
      btcVolume: 2200.181196563216,
      usdVolume: 18207494.81235211,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '54.5700000000000000',
      high: '55.3900000000000000',
      low: '51.0000000000000000',
      close: '52.4500000000000000',
      volume: '297730.1499600000000000',
      quoteVolume: 15783948.682937246,
      btcVolume: 1965.4916381767648,
      usdVolume: 15783948.682937246,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '52.4500000000000000',
      high: '55.2200000000000000',
      low: '51.5700000000000000',
      close: '54.9400000000000000',
      volume: '238776.4036000000000000',
      quoteVolume: 12771309.090288963,
      btcVolume: 1589.7400371088647,
      usdVolume: 12771309.090288963,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '54.9500000000000000',
      high: '55.0100000000000000',
      low: '52.1900000000000000',
      close: '53.2100000000000000',
      volume: '212503.0760200000000000',
      quoteVolume: 11342598.709450595,
      btcVolume: 1428.7754993429342,
      usdVolume: 11342598.709450595,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '53.2100000000000000',
      high: '54.1600000000000000',
      low: '52.5400000000000000',
      close: '53.7200000000000000',
      volume: '152608.9214200000000000',
      quoteVolume: 8152589.987414735,
      btcVolume: 1023.959008729595,
      usdVolume: 8152589.987414735,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '53.7200000000000000',
      high: '55.4600000000000000',
      low: '52.5800000000000000',
      close: '54.8500000000000000',
      volume: '208172.7019100000000000',
      quoteVolume: 11213347.343628593,
      btcVolume: 1399.9846240450204,
      usdVolume: 11213347.343628593,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '54.8500000000000000',
      high: '55.7400000000000000',
      low: '53.6700000000000000',
      close: '54.5700000000000000',
      volume: '208443.7668600000000000',
      quoteVolume: 11370329.079330875,
      btcVolume: 1383.3455861262153,
      usdVolume: 11370329.079330875,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '54.5700000000000000',
      high: '55.0900000000000000',
      low: '52.9700000000000000',
      close: '53.3900000000000000',
      volume: '159505.0758900000000000',
      quoteVolume: 8659267.226605335,
      btcVolume: 1058.7525600589156,
      usdVolume: 8659267.226605335,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '53.3800000000000000',
      high: '53.4400000000000000',
      low: '47.5300000000000000',
      close: '49.5000000000000000',
      volume: '470048.8542900000000000',
      quoteVolume: 23488078.022841353,
      btcVolume: 3076.301841104244,
      usdVolume: 23488078.022841353,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '49.4800000000000000',
      high: '50.3200000000000000',
      low: '48.3000000000000000',
      close: '49.5400000000000000',
      volume: '308330.5219600000000000',
      quoteVolume: 15231954.366521599,
      btcVolume: 2047.6661467292997,
      usdVolume: 15231954.366521599,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '49.5500000000000000',
      high: '58.7000000000000000',
      low: '49.0100000000000000',
      close: '56.9300000000000000',
      volume: '736498.2495500000000000',
      quoteVolume: 40028251.61986273,
      btcVolume: 4942.253267416375,
      usdVolume: 40028251.61986273,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '56.9300000000000000',
      high: '63.3700000000000000',
      low: '54.1400000000000000',
      close: '56.7100000000000000',
      volume: '1011134.5544100000000000',
      quoteVolume: 58374697.06996285,
      btcVolume: 6236.904792516147,
      usdVolume: 58374697.06996285,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '56.6900000000000000',
      high: '62.8500000000000000',
      low: '55.5900000000000000',
      close: '59.7800000000000000',
      volume: '659707.7341100000000000',
      quoteVolume: 39098216.43731671,
      btcVolume: 4118.114476739268,
      usdVolume: 39098216.43731671,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '59.7900000000000000',
      high: '62.4700000000000000',
      low: '57.4000000000000000',
      close: '57.7000000000000000',
      volume: '539486.7688600000000000',
      quoteVolume: 31869821.10145664,
      btcVolume: 3367.080583556967,
      usdVolume: 31869821.10145664,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '57.7000000000000000',
      high: '60.7500000000000000',
      low: '57.6500000000000000',
      close: '59.7500000000000000',
      volume: '399523.8960500000000000',
      quoteVolume: 23770607.904536624,
      btcVolume: 2536.351568802833,
      usdVolume: 23770607.904536624,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '59.7600000000000000',
      high: '61.0500000000000000',
      low: '56.8800000000000000',
      close: '57.8900000000000000',
      volume: '341531.2851900000000000',
      quoteVolume: 20067287.16194507,
      btcVolume: 2180.7999647255724,
      usdVolume: 20067287.16194507,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '57.9000000000000000',
      high: '59.5600000000000000',
      low: '57.0200000000000000',
      close: '58.2900000000000000',
      volume: '268866.1439300000000000',
      quoteVolume: 15690777.836845942,
      btcVolume: 1711.8755410860422,
      usdVolume: 15690777.836845942,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '58.2900000000000000',
      high: '58.8000000000000000',
      low: '56.6800000000000000',
      close: '58.2300000000000000',
      volume: '221222.3294000000000000',
      quoteVolume: 12800948.223556288,
      btcVolume: 1400.6610473174119,
      usdVolume: 12800948.223556288,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '58.2400000000000000',
      high: '59.1900000000000000',
      low: '57.7300000000000000',
      close: '58.2500000000000000',
      volume: '150629.3718500000000000',
      quoteVolume: 8789951.618953658,
      btcVolume: 947.7305790128487,
      usdVolume: 8789951.618953658,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '58.2400000000000000',
      high: '58.7500000000000000',
      low: '57.1800000000000000',
      close: '58.2900000000000000',
      volume: '141294.2567900000000000',
      quoteVolume: 8186539.4970356515,
      btcVolume: 888.9066093561216,
      usdVolume: 8186539.4970356515,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '58.2900000000000000',
      high: '62.4000000000000000',
      low: '57.8700000000000000',
      close: '61.2900000000000000',
      volume: '407402.9069300000000000',
      quoteVolume: 24699682.594704557,
      btcVolume: 2665.317399877282,
      usdVolume: 24699682.594704557,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '61.2600000000000000',
      high: '64.1500000000000000',
      low: '60.8900000000000000',
      close: '63.1200000000000000',
      volume: '527467.4519600000000000',
      quoteVolume: 33010265.398333196,
      btcVolume: 3542.273311220224,
      usdVolume: 33010265.398333196,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '63.1300000000000000',
      high: '64.3300000000000000',
      low: '62.5600000000000000',
      close: '64.1100000000000000',
      volume: '334742.1432800000000000',
      quoteVolume: 21184873.260657944,
      btcVolume: 2268.6466351593385,
      usdVolume: 21184873.260657944,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '64.1100000000000000',
      high: '64.3300000000000000',
      low: '60.2700000000000000',
      close: '61.3900000000000000',
      volume: '327504.1707300000000000',
      quoteVolume: 20341773.350589715,
      btcVolume: 2200.2788075310846,
      usdVolume: 20341773.350589715,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '61.4000000000000000',
      high: '62.4900000000000000',
      low: '58.9300000000000000',
      close: '60.3800000000000000',
      volume: '345551.4396900000000000',
      quoteVolume: 20960125.60154757,
      btcVolume: 2332.7802751493164,
      usdVolume: 20960125.60154757,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '60.3600000000000000',
      high: '62.3700000000000000',
      low: '60.1700000000000000',
      close: '62.0400000000000000',
      volume: '196430.9663500000000000',
      quoteVolume: 12033723.069156943,
      btcVolume: 1365.127796378876,
      usdVolume: 12033723.069156943,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '62.0400000000000000',
      high: '66.1900000000000000',
      low: '60.8000000000000000',
      close: '63.6500000000000000',
      volume: '428980.9520600000000000',
      quoteVolume: 27218648.713102452,
      btcVolume: 3057.9731993427763,
      usdVolume: 27218648.713102452,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '63.6500000000000000',
      high: '64.3300000000000000',
      low: '60.4700000000000000',
      close: '61.8400000000000000',
      volume: '408664.6897800000000000',
      quoteVolume: 25451946.05196973,
      btcVolume: 2882.6678622354775,
      usdVolume: 25451946.05196973,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '61.8400000000000000',
      high: '62.2500000000000000',
      low: '59.7500000000000000',
      close: '61.4200000000000000',
      volume: '299554.9198200000000000',
      quoteVolume: 18351569.172878858,
      btcVolume: 2100.756847368965,
      usdVolume: 18351569.172878858,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '61.4500000000000000',
      high: '62.0500000000000000',
      low: '60.2700000000000000',
      close: '60.9600000000000000',
      volume: '175581.9554600000000000',
      quoteVolume: 10721665.62536904,
      btcVolume: 1223.0289182322622,
      usdVolume: 10721665.62536904,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '60.9600000000000000',
      high: '61.1000000000000000',
      low: '58.2500000000000000',
      close: '59.0000000000000000',
      volume: '289023.5733800000000000',
      quoteVolume: 17173463.110559292,
      btcVolume: 1981.3152223352774,
      usdVolume: 17173463.110559292,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '59.0000000000000000',
      high: '60.7500000000000000',
      low: '56.2000000000000000',
      close: '57.6000000000000000',
      volume: '331442.8743400000000000',
      quoteVolume: 19338540.966621064,
      btcVolume: 2257.9348903748273,
      usdVolume: 19338540.966621064,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '57.6100000000000000',
      high: '58.9800000000000000',
      low: '57.1400000000000000',
      close: '58.3000000000000000',
      volume: '135649.0746000000000000',
      quoteVolume: 7869798.748988588,
      btcVolume: 927.7112832694024,
      usdVolume: 7869798.748988588,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '58.2900000000000000',
      high: '59.9700000000000000',
      low: '57.5500000000000000',
      close: '59.2200000000000000',
      volume: '189990.6350700000000000',
      quoteVolume: 11229720.96215726,
      btcVolume: 1318.1179952916186,
      usdVolume: 11229720.96215726,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '59.2100000000000000',
      high: '59.3000000000000000',
      low: '54.0000000000000000',
      close: '55.8100000000000000',
      volume: '295818.0605500000000000',
      quoteVolume: 16800996.48111148,
      btcVolume: 2019.3958745827504,
      usdVolume: 16800996.48111148,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '55.8000000000000000',
      high: '56.2300000000000000',
      low: '54.2000000000000000',
      close: '55.1300000000000000',
      volume: '245040.3911600000000000',
      quoteVolume: 13537640.598777153,
      btcVolume: 1669.4864925290328,
      usdVolume: 13537640.598777153,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '55.1300000000000000',
      high: '56.1600000000000000',
      low: '54.6100000000000000',
      close: '54.9800000000000000',
      volume: '224543.3721000000000000',
      quoteVolume: 12409649.698862612,
      btcVolume: 1529.485427593074,
      usdVolume: 12409649.698862612,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '54.9900000000000000',
      high: '55.2900000000000000',
      low: '48.5100000000000000',
      close: '50.6100000000000000',
      volume: '439158.0191300000000000',
      quoteVolume: 22774364.172612227,
      btcVolume: 2908.36978884641,
      usdVolume: 22774364.172612227,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '50.6100000000000000',
      high: '51.5500000000000000',
      low: '43.5800000000000000',
      close: '47.3200000000000000',
      volume: '922303.8905500000000000',
      quoteVolume: 43869831.870156966,
      btcVolume: 6058.957447311882,
      usdVolume: 43869831.870156966,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '47.3200000000000000',
      high: '48.5300000000000000',
      low: '46.1300000000000000',
      close: '48.1000000000000000',
      volume: '301729.2088500000000000',
      quoteVolume: 14284224.628105655,
      btcVolume: 1978.2053346800967,
      usdVolume: 14284224.628105655,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '48.1100000000000000',
      high: '48.2400000000000000',
      low: '43.4500000000000000',
      close: '43.8600000000000000',
      volume: '419515.9268500000000000',
      quoteVolume: 19189897.614486806,
      btcVolume: 2708.0365194507826,
      usdVolume: 19189897.614486806,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '43.8700000000000000',
      high: '47.4200000000000000',
      low: '42.0400000000000000',
      close: '45.4700000000000000',
      volume: '568354.8930000000000000',
      quoteVolume: 25558493.400256857,
      btcVolume: 3690.2398898192073,
      usdVolume: 25558493.400256857,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '45.4800000000000000',
      high: '47.5700000000000000',
      low: '45.2200000000000000',
      close: '46.9100000000000000',
      volume: '310692.2824100000000000',
      quoteVolume: 14415831.257585848,
      btcVolume: 2017.545020006212,
      usdVolume: 14415831.257585848,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '46.9100000000000000',
      high: '48.6900000000000000',
      low: '44.5100000000000000',
      close: '47.7000000000000000',
      volume: '455208.2126200000000000',
      quoteVolume: 21261996.524687037,
      btcVolume: 2940.3101183783738,
      usdVolume: 21261996.524687037,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '47.6900000000000000',
      high: '48.0000000000000000',
      low: '46.0100000000000000',
      close: '46.7900000000000000',
      volume: '267853.2410700000000000',
      quoteVolume: 12673681.758905394,
      btcVolume: 1689.725092052278,
      usdVolume: 12673681.758905394,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '46.7800000000000000',
      high: '49.3300000000000000',
      low: '46.7300000000000000',
      close: '48.5400000000000000',
      volume: '300683.0915400000000000',
      quoteVolume: 14490088.801178327,
      btcVolume: 1907.3941093084677,
      usdVolume: 14490088.801178327,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '48.5500000000000000',
      high: '48.7600000000000000',
      low: '46.5300000000000000',
      close: '47.2100000000000000',
      volume: '270873.2850800000000000',
      quoteVolume: 12935690.65358488,
      btcVolume: 1697.6778943997815,
      usdVolume: 12935690.65358488,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '47.2100000000000000',
      high: '48.4500000000000000',
      low: '45.5000000000000000',
      close: '47.4500000000000000',
      volume: '313487.6048300000000000',
      quoteVolume: 14677077.515154386,
      btcVolume: 1995.4070593380043,
      usdVolume: 14677077.515154386,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '47.4500000000000000',
      high: '47.5700000000000000',
      low: '44.7100000000000000',
      close: '45.4500000000000000',
      volume: '302395.0200900000000000',
      quoteVolume: 13878732.659008326,
      btcVolume: 1902.976734313874,
      usdVolume: 13878732.659008326,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '45.4400000000000000',
      high: '46.2200000000000000',
      low: '44.2600000000000000',
      close: '44.7500000000000000',
      volume: '323943.4612000000000000',
      quoteVolume: 14690325.25946848,
      btcVolume: 2010.2978077888893,
      usdVolume: 14690325.25946848,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '44.7600000000000000',
      high: '46.7000000000000000',
      low: '43.6900000000000000',
      close: '44.6700000000000000',
      volume: '539077.4009800000000000',
      quoteVolume: 24320896.92843568,
      btcVolume: 3326.541904574674,
      usdVolume: 24320896.92843568,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '44.6400000000000000',
      high: '45.2100000000000000',
      low: '43.7000000000000000',
      close: '44.8100000000000000',
      volume: '444681.4243600000000000',
      quoteVolume: 19790933.976291418,
      btcVolume: 2706.2151928563303,
      usdVolume: 19790933.976291418,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '44.7800000000000000',
      high: '45.5000000000000000',
      low: '44.0500000000000000',
      close: '45.2800000000000000',
      volume: '271289.1369300000000000',
      quoteVolume: 12150894.310600491,
      btcVolume: 1640.903702691806,
      usdVolume: 12150894.310600491,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '45.2600000000000000',
      high: '46.5000000000000000',
      low: '44.9400000000000000',
      close: '45.1500000000000000',
      volume: '195130.2029600000000000',
      quoteVolume: 8895004.035972556,
      btcVolume: 1181.7389955788406,
      usdVolume: 8895004.035972556,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '45.1400000000000000',
      high: '46.2000000000000000',
      low: '44.8100000000000000',
      close: '45.5600000000000000',
      volume: '131662.0929900000000000',
      quoteVolume: 5984698.629427009,
      btcVolume: 800.0403835402077,
      usdVolume: 5984698.629427009,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '45.5500000000000000',
      high: '45.8700000000000000',
      low: '44.0400000000000000',
      close: '44.4500000000000000',
      volume: '217927.8825200000000000',
      quoteVolume: 9810024.721531982,
      btcVolume: 1315.4281615543798,
      usdVolume: 9810024.721531982,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '44.4500000000000000',
      high: '44.6700000000000000',
      low: '43.6000000000000000',
      close: '44.0900000000000000',
      volume: '150376.5875200000000000',
      quoteVolume: 6644775.029064859,
      btcVolume: 910.4725316157349,
      usdVolume: 6644775.029064859,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '44.0700000000000000',
      high: '44.4600000000000000',
      low: '43.2700000000000000',
      close: '43.7000000000000000',
      volume: '134280.0069800000000000',
      quoteVolume: 5891144.238883986,
      btcVolume: 816.6022311473681,
      usdVolume: 5891144.238883986,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '43.7000000000000000',
      high: '44.1100000000000000',
      low: '42.8400000000000000',
      close: '43.6500000000000000',
      volume: '169652.1601800000000000',
      quoteVolume: 7380545.620045383,
      btcVolume: 1028.175549454187,
      usdVolume: 7380545.620045383,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '43.6500000000000000',
      high: '44.5500000000000000',
      low: '43.4300000000000000',
      close: '44.4400000000000000',
      volume: '127640.0717900000000000',
      quoteVolume: 5608098.184686444,
      btcVolume: 775.0595310858355,
      usdVolume: 5608098.184686444,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '44.4400000000000000',
      high: '44.6700000000000000',
      low: '43.1900000000000000',
      close: '43.2500000000000000',
      volume: '121534.2323000000000000',
      quoteVolume: 5321086.2473528925,
      btcVolume: 744.3880130799254,
      usdVolume: 5321086.2473528925,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '43.2800000000000000',
      high: '44.0400000000000000',
      low: '42.9000000000000000',
      close: '43.4400000000000000',
      volume: '105006.6976000000000000',
      quoteVolume: 4563268.2074588975,
      btcVolume: 643.0807131329308,
      usdVolume: 4563268.2074588975,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '43.4600000000000000',
      high: '43.5600000000000000',
      low: '39.0200000000000000',
      close: '39.9900000000000000',
      volume: '373430.2658200000000000',
      quoteVolume: 15295271.543124389,
      btcVolume: 2193.410071747004,
      usdVolume: 15295271.543124389,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '39.9800000000000000',
      high: '40.1300000000000000',
      low: '36.1000000000000000',
      close: '36.9200000000000000',
      volume: '645072.3422400000000000',
      quoteVolume: 24673081.77184135,
      btcVolume: 3668.629711227305,
      usdVolume: 24673081.77184135,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '36.9400000000000000',
      high: '41.7100000000000000',
      low: '35.7800000000000000',
      close: '40.7900000000000000',
      volume: '667785.3073500000000000',
      quoteVolume: 25701967.270766664,
      btcVolume: 3786.72926837403,
      usdVolume: 25701967.270766664,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '40.7900000000000000',
      high: '41.1000000000000000',
      low: '39.0600000000000000',
      close: '39.6500000000000000',
      volume: '240386.3147900000000000',
      quoteVolume: 9577045.423953377,
      btcVolume: 1337.9051700822683,
      usdVolume: 9577045.423953377,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '39.6600000000000000',
      high: '40.4100000000000000',
      low: '39.2500000000000000',
      close: '40.1700000000000000',
      volume: '151508.4764800000000000',
      quoteVolume: 6050598.731822773,
      btcVolume: 845.4937670166742,
      usdVolume: 6050598.731822773,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '40.1500000000000000',
      high: '40.2200000000000000',
      low: '39.4100000000000000',
      close: '39.6700000000000000',
      volume: '91777.0964000000000000',
      quoteVolume: 3651414.4911812823,
      btcVolume: 511.12426012410907,
      usdVolume: 3651414.4911812823,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '39.6700000000000000',
      high: '42.1600000000000000',
      low: '39.6200000000000000',
      close: '41.8200000000000000',
      volume: '178219.7773000000000000',
      quoteVolume: 7343575.467333499,
      btcVolume: 1010.9593601908497,
      usdVolume: 7343575.467333499,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '41.8400000000000000',
      high: '42.6700000000000000',
      low: '40.2100000000000000',
      close: '40.5600000000000000',
      volume: '288871.4672100000000000',
      quoteVolume: 12041679.124624727,
      btcVolume: 1606.172428630869,
      usdVolume: 12041679.124624727,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '40.5600000000000000',
      high: '41.1300000000000000',
      low: '39.6800000000000000',
      close: '40.0600000000000000',
      volume: '211464.7632900000000000',
      quoteVolume: 8551583.328070415,
      btcVolume: 1173.559105839882,
      usdVolume: 8551583.328070415,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '40.3300000000000000',
      high: '40.4800000000000000',
      low: '39.3800000000000000',
      close: '40.0800000000000000',
      volume: '136222.9401800000000000',
      quoteVolume: 5447546.31914613,
      btcVolume: 755.370742894901,
      usdVolume: 5447546.31914613,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '40.1100000000000000',
      high: '41.7300000000000000',
      low: '39.5800000000000000',
      close: '41.4400000000000000',
      volume: '194299.1207600000000000',
      quoteVolume: 7858546.46046011,
      btcVolume: 1081.0147742755657,
      usdVolume: 7858546.46046011,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '39.9900000000000000',
      high: '41.5000000000000000',
      low: '39.6700000000000000',
      close: '40.9500000000000000',
      volume: '219194.0915600000000000',
      quoteVolume: 8901146.687390905,
      btcVolume: 1233.9824964047334,
      usdVolume: 8901146.687390905,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '40.9600000000000000',
      high: '43.7600000000000000',
      low: '40.9100000000000000',
      close: '42.8300000000000000',
      volume: '284295.3329600000000000',
      quoteVolume: 12068166.856607929,
      btcVolume: 1649.5543814055918,
      usdVolume: 12068166.856607929,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '42.8400000000000000',
      high: '43.7600000000000000',
      low: '42.1400000000000000',
      close: '43.0800000000000000',
      volume: '337077.7113200000000000',
      quoteVolume: 14472707.652529165,
      btcVolume: 1964.1795286823033,
      usdVolume: 14472707.652529165,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '43.0700000000000000',
      high: '44.1800000000000000',
      low: '41.8200000000000000',
      close: '42.3800000000000000',
      volume: '298581.8729900000000000',
      quoteVolume: 12800174.560334394,
      btcVolume: 1748.879815023186,
      usdVolume: 12800174.560334394,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '42.3600000000000000',
      high: '43.0500000000000000',
      low: '41.0000000000000000',
      close: '41.3000000000000000',
      volume: '201446.8929200000000000',
      quoteVolume: 8445983.18938396,
      btcVolume: 1169.7012363488755,
      usdVolume: 8445983.18938396,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '41.2900000000000000',
      high: '42.0800000000000000',
      low: '41.1600000000000000',
      close: '41.6200000000000000',
      volume: '110044.8744500000000000',
      quoteVolume: 4588192.884942515,
      btcVolume: 635.4364428671792,
      usdVolume: 4588192.884942515,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '41.5800000000000000',
      high: '41.7400000000000000',
      low: '39.1700000000000000',
      close: '39.4300000000000000',
      volume: '185573.9423700000000000',
      quoteVolume: 7483717.243429469,
      btcVolume: 1061.4403351410635,
      usdVolume: 7483717.243429469,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '39.4400000000000000',
      high: '42.3900000000000000',
      low: '38.7700000000000000',
      close: '42.2300000000000000',
      volume: '426686.1784300000000000',
      quoteVolume: 17553430.816173177,
      btcVolume: 2423.2412705017687,
      usdVolume: 17553430.816173177,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '42.2300000000000000',
      high: '42.9000000000000000',
      low: '41.7700000000000000',
      close: '42.7800000000000000',
      volume: '248765.3690300000000000',
      quoteVolume: 10534042.087356858,
      btcVolume: 1436.827480722452,
      usdVolume: 10534042.087356858,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '42.7900000000000000',
      high: '44.6100000000000000',
      low: '42.6000000000000000',
      close: '43.3000000000000000',
      volume: '291946.5645000000000000',
      quoteVolume: 12683510.13119241,
      btcVolume: 1705.9415015020584,
      usdVolume: 12683510.13119241,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '43.2900000000000000',
      high: '46.0800000000000000',
      low: '43.1600000000000000',
      close: '45.8000000000000000',
      volume: '384930.9909000000000000',
      quoteVolume: 17201996.630419996,
      btcVolume: 2279.19673036352,
      usdVolume: 17201996.630419996,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '45.8000000000000000',
      high: '47.0300000000000000',
      low: '44.4800000000000000',
      close: '46.3000000000000000',
      volume: '493847.1053200000000000',
      quoteVolume: 22589565.78223805,
      btcVolume: 2841.5038279351834,
      usdVolume: 22589565.78223805,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '46.3000000000000000',
      high: '48.5400000000000000',
      low: '44.0000000000000000',
      close: '45.3500000000000000',
      volume: '584339.7458700000000000',
      quoteVolume: 27180426.110374276,
      btcVolume: 3303.5440248504137,
      usdVolume: 27180426.110374276,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '45.3100000000000000',
      high: '45.7900000000000000',
      low: '43.9000000000000000',
      close: '44.7400000000000000',
      volume: '291473.3014800000000000',
      quoteVolume: 13068587.786202814,
      btcVolume: 1655.8036544951558,
      usdVolume: 13068587.786202814,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '44.7500000000000000',
      high: '49.7200000000000000',
      low: '43.7100000000000000',
      close: '49.0600000000000000',
      volume: '729802.1629100000000000',
      quoteVolume: 34640157.160860166,
      btcVolume: 4336.05850079116,
      usdVolume: 34640157.160860166,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '49.0600000000000000',
      high: '51.9500000000000000',
      low: '47.7700000000000000',
      close: '49.3000000000000000',
      volume: '681984.4665200000000000',
      quoteVolume: 33981679.44025984,
      btcVolume: 4182.297745203546,
      usdVolume: 33981679.44025984,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '49.3200000000000000',
      high: '51.7500000000000000',
      low: '48.7800000000000000',
      close: '51.4300000000000000',
      volume: '532886.7588500000000000',
      quoteVolume: 26955144.342092644,
      btcVolume: 3323.759557503418,
      usdVolume: 26955144.342092644,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '51.4400000000000000',
      high: '51.6000000000000000',
      low: '48.9100000000000000',
      close: '49.6000000000000000',
      volume: '381202.3532900000000000',
      quoteVolume: 19042757.26225366,
      btcVolume: 2347.839281568202,
      usdVolume: 19042757.26225366,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '49.5900000000000000',
      high: '60.8000000000000000',
      low: '49.5800000000000000',
      close: '58.3900000000000000',
      volume: '1232536.3536400000000000',
      quoteVolume: 68826521.75910157,
      btcVolume: 7986.234559202855,
      usdVolume: 68826521.75910157,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '58.3700000000000000',
      high: '60.3300000000000000',
      low: '55.2800000000000000',
      close: '58.1600000000000000',
      volume: '719639.6444600000000000',
      quoteVolume: 41814710.281005435,
      btcVolume: 4771.257558434868,
      usdVolume: 41814710.281005435,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '58.1700000000000000',
      high: '58.5000000000000000',
      low: '54.5000000000000000',
      close: '57.5600000000000000',
      volume: '477991.2016200000000000',
      quoteVolume: 27095155.674876053,
      btcVolume: 3117.7992797943502,
      usdVolume: 27095155.674876053,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '57.5600000000000000',
      high: '63.2600000000000000',
      low: '56.7200000000000000',
      close: '61.0200000000000000',
      volume: '767262.9013300000000000',
      quoteVolume: 46240460.37712067,
      btcVolume: 5206.750862665781,
      usdVolume: 46240460.37712067,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '61.0500000000000000',
      high: '62.5000000000000000',
      low: '57.9000000000000000',
      close: '59.3200000000000000',
      volume: '498224.6925600000000000',
      quoteVolume: 29957779.817371603,
      btcVolume: 3366.710070150065,
      usdVolume: 29957779.817371603,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '59.2900000000000000',
      high: '62.5500000000000000',
      low: '55.3400000000000000',
      close: '57.6000000000000000',
      volume: '530895.3948100000000000',
      quoteVolume: 31172505.611098293,
      btcVolume: 3524.9826085852515,
      usdVolume: 31172505.611098293,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '57.5900000000000000',
      high: '58.2900000000000000',
      low: '55.8400000000000000',
      close: '57.1900000000000000',
      volume: '262316.6725800000000000',
      quoteVolume: 14992782.875085551,
      btcVolume: 1733.2682720575585,
      usdVolume: 14992782.875085551,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '57.1900000000000000',
      high: '58.0900000000000000',
      low: '55.9100000000000000',
      close: '57.5700000000000000',
      volume: '216543.7237600000000000',
      quoteVolume: 12411702.385828936,
      btcVolume: 1433.8494862629868,
      usdVolume: 12411702.385828936,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '57.6000000000000000',
      high: '59.3300000000000000',
      low: '57.1800000000000000',
      close: '58.3300000000000000',
      volume: '218658.9547700000000000',
      quoteVolume: 12717189.464885991,
      btcVolume: 1461.8622331104564,
      usdVolume: 12717189.464885991,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '58.3300000000000000',
      high: '58.4000000000000000',
      low: '52.4200000000000000',
      close: '54.5200000000000000',
      volume: '464982.4302600000000000',
      quoteVolume: 25617022.981270354,
      btcVolume: 3040.6698235798467,
      usdVolume: 25617022.981270354,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '54.5200000000000000',
      high: '55.4100000000000000',
      low: '50.6400000000000000',
      close: '54.3800000000000000',
      volume: '550746.2142300000000000',
      quoteVolume: 29358272.10197633,
      btcVolume: 3502.858976790822,
      usdVolume: 29358272.10197633,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '54.4100000000000000',
      high: '54.4100000000000000',
      low: '51.9000000000000000',
      close: '53.2500000000000000',
      volume: '282004.9267600000000000',
      quoteVolume: 15034806.238122009,
      btcVolume: 1801.659164508155,
      usdVolume: 15034806.238122009,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '53.2600000000000000',
      high: '56.6800000000000000',
      low: '52.8000000000000000',
      close: '56.2400000000000000',
      volume: '348355.2649400000000000',
      quoteVolume: 19066690.28468506,
      btcVolume: 2251.529364018872,
      usdVolume: 19066690.28468506,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '56.2900000000000000',
      high: '59.6500000000000000',
      low: '55.8700000000000000',
      close: '58.5500000000000000',
      volume: '504725.6770700000000000',
      quoteVolume: 29245084.44984709,
      btcVolume: 3342.2689126799664,
      usdVolume: 29245084.44984709,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '58.5500000000000000',
      high: '61.6100000000000000',
      low: '57.2800000000000000',
      close: '60.5200000000000000',
      volume: '720540.7454800000000000',
      quoteVolume: 42958185.64174903,
      btcVolume: 4739.804305857356,
      usdVolume: 42958185.64174903,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '60.5400000000000000',
      high: '62.5600000000000000',
      low: '59.4000000000000000',
      close: '59.8000000000000000',
      volume: '566997.9273700000000000',
      quoteVolume: 34512745.46518843,
      btcVolume: 3694.810881665897,
      usdVolume: 34512745.46518843,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '59.8100000000000000',
      high: '70.2200000000000000',
      low: '58.2400000000000000',
      close: '67.9800000000000000',
      volume: '1286322.9967600000000000',
      quoteVolume: 83696612.6659622,
      btcVolume: 8901.350446887207,
      usdVolume: 83696612.6659622,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '68.0000000000000000',
      high: '70.1000000000000000',
      low: '65.3700000000000000',
      close: '68.0000000000000000',
      volume: '938256.7834600000000000',
      quoteVolume: 63513691.45788283,
      btcVolume: 6794.098635568326,
      usdVolume: 63513691.45788283,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '68.0200000000000000',
      high: '73.0000000000000000',
      low: '67.6200000000000000',
      close: '70.7200000000000000',
      volume: '952836.8964000000000000',
      quoteVolume: 67544318.68868984,
      btcVolume: 7193.168790333061,
      usdVolume: 67544318.68868984,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '70.7200000000000000',
      high: '73.5000000000000000',
      low: '67.6600000000000000',
      close: '69.9300000000000000',
      volume: '818385.8921200000000000',
      quoteVolume: 57937576.88566312,
      btcVolume: 6178.672563598776,
      usdVolume: 57937576.88566312,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '69.9300000000000000',
      high: '73.0000000000000000',
      low: '68.4500000000000000',
      close: '69.6500000000000000',
      volume: '689182.2485500000000000',
      quoteVolume: 48493966.03873978,
      btcVolume: 5178.755191366324,
      usdVolume: 48493966.03873978,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '69.6500000000000000',
      high: '70.5000000000000000',
      low: '66.6200000000000000',
      close: '68.0700000000000000',
      volume: '486977.7672700000000000',
      quoteVolume: 33194308.20363878,
      btcVolume: 3598.386589011568,
      usdVolume: 33194308.20363878,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '68.0400000000000000',
      high: '73.4700000000000000',
      low: '67.6200000000000000',
      close: '72.5900000000000000',
      volume: '646729.4887700000000000',
      quoteVolume: 46118158.32426789,
      btcVolume: 4877.923830710961,
      usdVolume: 46118158.32426789,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '72.5900000000000000',
      high: '75.0800000000000000',
      low: '70.9100000000000000',
      close: '73.6000000000000000',
      volume: '698855.4245600000000000',
      quoteVolume: 51231501.11111841,
      btcVolume: 5277.399278078971,
      usdVolume: 51231501.11111841,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '73.5900000000000000',
      high: '75.4800000000000000',
      low: '72.8500000000000000',
      close: '74.3000000000000000',
      volume: '541747.2637200000000000',
      quoteVolume: 40077414.45603498,
      btcVolume: 4090.357266366724,
      usdVolume: 40077414.45603498,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '74.3100000000000000',
      high: '77.9400000000000000',
      low: '71.5300000000000000',
      close: '76.5600000000000000',
      volume: '647821.6039800000000000',
      quoteVolume: 48757951.45692755,
      btcVolume: 4970.738929853706,
      usdVolume: 48757951.45692755,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '76.5600000000000000',
      high: '78.4300000000000000',
      low: '75.0000000000000000',
      close: '77.0000000000000000',
      volume: '472262.1156700000000000',
      quoteVolume: 36317074.30775854,
      btcVolume: 3602.7858292027363,
      usdVolume: 36317074.30775854,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '76.9900000000000000',
      high: '77.4800000000000000',
      low: '72.2100000000000000',
      close: '74.0200000000000000',
      volume: '709830.6287100000000000',
      quoteVolume: 52756980.53293135,
      btcVolume: 5317.311038857939,
      usdVolume: 52756980.53293135,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '73.9800000000000000',
      high: '77.4000000000000000',
      low: '72.6400000000000000',
      close: '76.4600000000000000',
      volume: '557017.2686200000000000',
      quoteVolume: 41776583.98157975,
      btcVolume: 4186.95219451079,
      usdVolume: 41776583.98157975,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '76.4500000000000000',
      high: '82.8100000000000000',
      low: '76.4400000000000000',
      close: '81.1700000000000000',
      volume: '771544.8829200000000000',
      quoteVolume: 61428938.033711724,
      btcVolume: 5950.1181314721225,
      usdVolume: 61428938.033711724,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '81.2000000000000000',
      high: '84.3200000000000000',
      low: '77.1500000000000000',
      close: '80.5500000000000000',
      volume: '1044135.4611700000000000',
      quoteVolume: 84499591.43981327,
      btcVolume: 8207.278543411736,
      usdVolume: 84499591.43981327,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '80.5500000000000000',
      high: '83.7900000000000000',
      low: '78.6100000000000000',
      close: '83.1400000000000000',
      volume: '523491.6950600000000000',
      quoteVolume: 42705491.43694748,
      btcVolume: 4162.817891228647,
      usdVolume: 42705491.43694748,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '83.1400000000000000',
      high: '83.5000000000000000',
      low: '75.0400000000000000',
      close: '76.7000000000000000',
      volume: '704649.8592300000000000',
      quoteVolume: 55784535.30447557,
      btcVolume: 5528.033565597649,
      usdVolume: 55784535.30447557,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '76.7400000000000000',
      high: '80.7700000000000000',
      low: '67.8500000000000000',
      close: '74.6700000000000000',
      volume: '998173.2565800000000000',
      quoteVolume: 74384870.94958961,
      btcVolume: 7540.054081915949,
      usdVolume: 74384870.94958961,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '74.6600000000000000',
      high: '75.5000000000000000',
      low: '68.5100000000000000',
      close: '73.4100000000000000',
      volume: '738054.4697500000000000',
      quoteVolume: 52679051.66613214,
      btcVolume: 5429.018811635842,
      usdVolume: 52679051.66613214,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '73.4400000000000000',
      high: '79.0900000000000000',
      low: '70.0000000000000000',
      close: '77.2500000000000000',
      volume: '713680.2316200000000000',
      quoteVolume: 53060847.638592295,
      btcVolume: 5393.830722757955,
      usdVolume: 53060847.638592295,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '77.2500000000000000',
      high: '79.4500000000000000',
      low: '67.0000000000000000',
      close: '70.6100000000000000',
      volume: '542175.5195700000000000',
      quoteVolume: 40353067.756060414,
      btcVolume: 4058.4679125064927,
      usdVolume: 40353067.756060414,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '70.6100000000000000',
      high: '72.4300000000000000',
      low: '66.1000000000000000',
      close: '69.4000000000000000',
      volume: '730443.1978900000000000',
      quoteVolume: 50737958.514938615,
      btcVolume: 5298.48056977998,
      usdVolume: 50737958.514938615,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '69.3800000000000000',
      high: '75.2500000000000000',
      low: '68.5600000000000000',
      close: '73.2500000000000000',
      volume: '624557.8448400000000000',
      quoteVolume: 45340746.56160606,
      btcVolume: 4683.2319382880305,
      usdVolume: 45340746.56160606,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '73.2500000000000000',
      high: '76.7900000000000000',
      low: '72.3200000000000000',
      close: '74.7900000000000000',
      volume: '651449.7864800000000000',
      quoteVolume: 48795358.48709348,
      btcVolume: 5060.245308587067,
      usdVolume: 48795358.48709348,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '74.8000000000000000',
      high: '80.0000000000000000',
      low: '74.6600000000000000',
      close: '79.5200000000000000',
      volume: '665878.9020700000000000',
      quoteVolume: 52072540.692516245,
      btcVolume: 5281.779201690238,
      usdVolume: 52072540.692516245,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '79.5200000000000000',
      high: '79.7600000000000000',
      low: '71.7100000000000000',
      close: '75.5900000000000000',
      volume: '936157.2591900000000000',
      quoteVolume: 70787716.5914514,
      btcVolume: 7272.425954963807,
      usdVolume: 70787716.5914514,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '75.5700000000000000',
      high: '76.0800000000000000',
      low: '69.5400000000000000',
      close: '70.9800000000000000',
      volume: '778051.0902300000000000',
      quoteVolume: 56572719.08831394,
      btcVolume: 5971.138131273642,
      usdVolume: 56572719.08831394,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '70.9800000000000000',
      high: '72.1500000000000000',
      low: '57.4100000000000000',
      close: '60.5300000000000000',
      volume: '1518769.7348500000000000',
      quoteVolume: 97983345.80007514,
      btcVolume: 10860.043177575628,
      usdVolume: 97983345.80007514,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '60.5300000000000000',
      high: '65.1000000000000000',
      low: '57.1500000000000000',
      close: '61.7700000000000000',
      volume: '1110152.5418600000000000',
      quoteVolume: 68205004.9433444,
      btcVolume: 7760.49735099012,
      usdVolume: 68205004.9433444,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '61.7600000000000000',
      high: '63.3900000000000000',
      low: '56.5600000000000000',
      close: '59.8900000000000000',
      volume: '1070641.2770800000000000',
      quoteVolume: 64057582.61899067,
      btcVolume: 7378.595868383946,
      usdVolume: 64057582.61899067,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '59.8800000000000000',
      high: '61.6500000000000000',
      low: '57.8400000000000000',
      close: '58.0500000000000000',
      volume: '506232.3970400000000000',
      quoteVolume: 30182934.208716422,
      btcVolume: 3477.3583610745964,
      usdVolume: 30182934.208716422,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '58.0500000000000000',
      high: '60.2800000000000000',
      low: '56.2100000000000000',
      close: '57.7000000000000000',
      volume: '633924.9116200000000000',
      quoteVolume: 37024496.384328924,
      btcVolume: 4325.436819033869,
      usdVolume: 37024496.384328924,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '57.7200000000000000',
      high: '62.3200000000000000',
      low: '57.2500000000000000',
      close: '61.4200000000000000',
      volume: '660927.2617600000000000',
      quoteVolume: 39608823.902695134,
      btcVolume: 4524.26294574055,
      usdVolume: 39608823.902695134,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '61.4200000000000000',
      high: '62.4000000000000000',
      low: '59.4000000000000000',
      close: '61.0100000000000000',
      volume: '640940.7745700000000000',
      quoteVolume: 39002038.56290318,
      btcVolume: 4441.375840912394,
      usdVolume: 39002038.56290318,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '61.0200000000000000',
      high: '61.7800000000000000',
      low: '58.7100000000000000',
      close: '60.3800000000000000',
      volume: '424299.8621100000000000',
      quoteVolume: 25604221.91697132,
      btcVolume: 2924.6084427488145,
      usdVolume: 25604221.91697132,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '60.3800000000000000',
      high: '63.2900000000000000',
      low: '60.3300000000000000',
      close: '62.0600000000000000',
      volume: '556284.7403100000000000',
      quoteVolume: 34448965.31724282,
      btcVolume: 3820.9976165896405,
      usdVolume: 34448965.31724282,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '62.0600000000000000',
      high: '63.8400000000000000',
      low: '61.1800000000000000',
      close: '63.2200000000000000',
      volume: '468431.8505300000000000',
      quoteVolume: 29256812.809018675,
      btcVolume: 3219.3959694013993,
      usdVolume: 29256812.809018675,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '63.2100000000000000',
      high: '63.8900000000000000',
      low: '59.8600000000000000',
      close: '60.4600000000000000',
      volume: '444270.5350800000000000',
      quoteVolume: 27655735.411491226,
      btcVolume: 3061.59890192781,
      usdVolume: 27655735.411491226,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '60.4600000000000000',
      high: '60.4700000000000000',
      low: '50.4100000000000000',
      close: '51.0000000000000000',
      volume: '945026.2712800000000000',
      quoteVolume: 51958845.61631424,
      btcVolume: 6146.981784105797,
      usdVolume: 51958845.61631424,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '51.0200000000000000',
      high: '52.2700000000000000',
      low: '46.5700000000000000',
      close: '50.3900000000000000',
      volume: '1426145.5016400000000000',
      quoteVolume: 70420337.2832661,
      btcVolume: 8969.579053763204,
      usdVolume: 70420337.2832661,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '50.3700000000000000',
      high: '51.3900000000000000',
      low: '48.8600000000000000',
      close: '50.2600000000000000',
      volume: '727732.9091100000000000',
      quoteVolume: 36535744.60946244,
      btcVolume: 4600.383086081476,
      usdVolume: 36535744.60946244,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '50.2400000000000000',
      high: '50.7400000000000000',
      low: '45.4700000000000000',
      close: '48.5300000000000000',
      volume: '798310.6481000000000000',
      quoteVolume: 38456344.38179883,
      btcVolume: 4924.684407095869,
      usdVolume: 38456344.38179883,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '48.5200000000000000',
      high: '48.7600000000000000',
      low: '28.6500000000000000',
      close: '29.8300000000000000',
      volume: '2086322.4159000000000000',
      quoteVolume: 75460005.32619856,
      btcVolume: 12025.230888564047,
      usdVolume: 75460005.32619856,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '29.8300000000000000',
      high: '39.9200000000000000',
      low: '24.0000000000000000',
      close: '37.5200000000000000',
      volume: '3233170.0085600000000000',
      quoteVolume: 106737958.21170305,
      btcVolume: 20811.33498176254,
      usdVolume: 106737958.21170305,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '37.5100000000000000',
      high: '37.6500000000000000',
      low: '33.8000000000000000',
      close: '34.4000000000000000',
      volume: '1177751.7307200000000000',
      quoteVolume: 42192079.856290706,
      btcVolume: 7824.353304327343,
      usdVolume: 42192079.856290706,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '34.4000000000000000',
      high: '38.7300000000000000',
      low: '34.0400000000000000',
      close: '36.3800000000000000',
      volume: '1040322.3028200000000000',
      quoteVolume: 37333342.64250416,
      btcVolume: 6968.352015588107,
      usdVolume: 37333342.64250416,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '36.3700000000000000',
      high: '36.4100000000000000',
      low: '29.2900000000000000',
      close: '33.2000000000000000',
      volume: '1624714.3758400000000000',
      quoteVolume: 52644643.37862483,
      btcVolume: 10729.651950772735,
      usdVolume: 52644643.37862483,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '33.2300000000000000',
      high: '35.8500000000000000',
      low: '32.4200000000000000',
      close: '34.0700000000000000',
      volume: '945437.9313600000000000',
      quoteVolume: 32633674.457892723,
      btcVolume: 6180.955240181797,
      usdVolume: 32633674.457892723,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '34.0600000000000000',
      high: '34.8300000000000000',
      low: '32.2800000000000000',
      close: '34.7100000000000000',
      volume: '1044418.0699700000000000',
      quoteVolume: 35185450.617933325,
      btcVolume: 6701.831333709307,
      usdVolume: 35185450.617933325,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '34.7200000000000000',
      high: '40.8000000000000000',
      low: '34.0000000000000000',
      close: '38.9000000000000000',
      volume: '1343522.4596300000000000',
      quoteVolume: 50290985.84804543,
      btcVolume: 8550.756313543709,
      usdVolume: 50290985.84804543,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '38.9000000000000000',
      high: '43.6900000000000000',
      low: '34.0500000000000000',
      close: '38.0800000000000000',
      volume: '1571300.6085700000000000',
      quoteVolume: 62380455.892511785,
      btcVolume: 9798.316569328548,
      usdVolume: 62380455.892511785,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '38.0700000000000000',
      high: '39.8400000000000000',
      low: '36.2100000000000000',
      close: '38.3200000000000000',
      volume: '667425.6322200000000000',
      quoteVolume: 25413717.28592991,
      btcVolume: 4123.580369137249,
      usdVolume: 25413717.28592991,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '38.3400000000000000',
      high: '39.6900000000000000',
      low: '34.8200000000000000',
      close: '35.4000000000000000',
      volume: '714342.7273000000000000',
      quoteVolume: 26556648.090772223,
      btcVolume: 4362.9065967115375,
      usdVolume: 26556648.090772223,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '35.4200000000000000',
      high: '39.9900000000000000',
      low: '34.5700000000000000',
      close: '38.9400000000000000',
      volume: '859942.5261100000000000',
      quoteVolume: 32311045.429742124,
      btcVolume: 5270.1140483688005,
      usdVolume: 32311045.429742124,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '38.9300000000000000',
      high: '40.9500000000000000',
      low: '38.2100000000000000',
      close: '40.5700000000000000',
      volume: '593656.7733500000000000',
      quoteVolume: 23511452.09394711,
      btcVolume: 3536.1717797574593,
      usdVolume: 23511452.09394711,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '40.5600000000000000',
      high: '41.0400000000000000',
      low: '38.0900000000000000',
      close: '39.1700000000000000',
      volume: '577448.8885600000000000',
      quoteVolume: 22783918.764491983,
      btcVolume: 3426.4774081346554,
      usdVolume: 22783918.764491983,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '39.1700000000000000',
      high: '40.4800000000000000',
      low: '38.4000000000000000',
      close: '40.4100000000000000',
      volume: '415288.7595000000000000',
      quoteVolume: 16295287.489501858,
      btcVolume: 2447.192594622033,
      usdVolume: 16295287.489501858,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '40.4100000000000000',
      high: '41.2100000000000000',
      low: '37.7300000000000000',
      close: '38.0400000000000000',
      volume: '481643.2817300000000000',
      quoteVolume: 19173843.62566706,
      btcVolume: 2881.7355055505905,
      usdVolume: 19173843.62566706,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '38.0400000000000000',
      high: '39.4300000000000000',
      low: '36.6100000000000000',
      close: '38.8700000000000000',
      volume: '624312.1869900000000000',
      quoteVolume: 23604375.60363385,
      btcVolume: 3798.947942790218,
      usdVolume: 23604375.60363385,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '38.8500000000000000',
      high: '39.1100000000000000',
      low: '36.9700000000000000',
      close: '37.1100000000000000',
      volume: '402076.3126400000000000',
      quoteVolume: 15295566.907294536,
      btcVolume: 2513.0753250709718,
      usdVolume: 15295566.907294536,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '37.1100000000000000',
      high: '39.7000000000000000',
      low: '37.1000000000000000',
      close: '38.9100000000000000',
      volume: '428079.8682600000000000',
      quoteVolume: 16531614.31945865,
      btcVolume: 2634.7438065394886,
      usdVolume: 16531614.31945865,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '38.9300000000000000',
      high: '39.3200000000000000',
      low: '38.3300000000000000',
      close: '39.0800000000000000',
      volume: '378250.3606500000000000',
      quoteVolume: 14716269.660095483,
      btcVolume: 2283.619623452454,
      usdVolume: 14716269.660095483,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '39.0700000000000000',
      high: '39.5000000000000000',
      low: '37.1400000000000000',
      close: '39.2300000000000000',
      volume: '488799.1143600000000000',
      quoteVolume: 18738315.769651353,
      btcVolume: 2961.325340905673,
      usdVolume: 18738315.769651353,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '39.2300000000000000',
      high: '42.9200000000000000',
      low: '39.0000000000000000',
      close: '39.8600000000000000',
      volume: '701573.7692100000000000',
      quoteVolume: 28237488.266804706,
      btcVolume: 4154.26325616401,
      usdVolume: 28237488.266804706,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '39.8800000000000000',
      high: '41.2600000000000000',
      low: '39.6400000000000000',
      close: '40.4600000000000000',
      volume: '463368.1163000000000000',
      quoteVolume: 18748635.166638713,
      btcVolume: 2747.9145418248345,
      usdVolume: 18748635.166638713,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '40.4900000000000000',
      high: '41.1600000000000000',
      low: '39.8600000000000000',
      close: '40.7100000000000000',
      volume: '362553.4451000000000000',
      quoteVolume: 14663339.769387648,
      btcVolume: 2163.870381431818,
      usdVolume: 14663339.769387648,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '40.7400000000000000',
      high: '41.0000000000000000',
      low: '39.8600000000000000',
      close: '40.3100000000000000',
      volume: '231112.9573000000000000',
      quoteVolume: 9362413.054505432,
      btcVolume: 1379.8091072782984,
      usdVolume: 9362413.054505432,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '40.3100000000000000',
      high: '45.0900000000000000',
      low: '40.2800000000000000',
      close: '45.0400000000000000',
      volume: '721562.2858000000000000',
      quoteVolume: 30935223.803984463,
      btcVolume: 4356.628030072247,
      usdVolume: 30935223.803984463,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '45.0300000000000000',
      high: '47.6400000000000000',
      low: '43.6400000000000000',
      close: '44.7700000000000000',
      volume: '807492.3694800000000000',
      quoteVolume: 36986803.916505545,
      btcVolume: 5058.8892341354185,
      usdVolume: 36986803.916505545,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '44.7900000000000000',
      high: '47.2000000000000000',
      low: '44.2700000000000000',
      close: '46.5500000000000000',
      volume: '500059.4649500000000000',
      quoteVolume: 23044062.553474456,
      btcVolume: 3156.204573659692,
      usdVolume: 23044062.553474456,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '46.5200000000000000',
      high: '46.8600000000000000',
      low: '44.6900000000000000',
      close: '46.2100000000000000',
      volume: '401085.4795800000000000',
      quoteVolume: 18464427.839610986,
      btcVolume: 2533.6102006732344,
      usdVolume: 18464427.839610986,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '46.1900000000000000',
      high: '46.3900000000000000',
      low: '40.8600000000000000',
      close: '42.2500000000000000',
      volume: '785172.3313100000000000',
      quoteVolume: 33771322.35967942,
      btcVolume: 4856.52724305218,
      usdVolume: 33771322.35967942,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '42.2800000000000000',
      high: '43.1600000000000000',
      low: '41.4300000000000000',
      close: '42.5400000000000000',
      volume: '382360.3213200000000000',
      quoteVolume: 16212942.580929037,
      btcVolume: 2364.227913329805,
      usdVolume: 16212942.580929037,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '42.5400000000000000',
      high: '43.5800000000000000',
      low: '41.8300000000000000',
      close: '42.0000000000000000',
      volume: '499257.0325700000000000',
      quoteVolume: 21342178.133174907,
      btcVolume: 3067.773815331192,
      usdVolume: 21342178.133174907,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '41.9900000000000000',
      high: '41.9900000000000000',
      low: '39.5200000000000000',
      close: '41.2200000000000000',
      volume: '744114.5130600000000000',
      quoteVolume: 30090872.086141095,
      btcVolume: 4470.071976428221,
      usdVolume: 30090872.086141095,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '41.2000000000000000',
      high: '41.8500000000000000',
      low: '40.4700000000000000',
      close: '41.2200000000000000',
      volume: '420837.6883200000000000',
      quoteVolume: 17361900.52885351,
      btcVolume: 2527.268685546851,
      usdVolume: 17361900.52885351,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '41.1900000000000000',
      high: '41.7500000000000000',
      low: '39.0800000000000000',
      close: '39.2200000000000000',
      volume: '460328.1631000000000000',
      quoteVolume: 18685153.46120497,
      btcVolume: 2758.6937261226535,
      usdVolume: 18685153.46120497,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '39.2400000000000000',
      high: '43.7800000000000000',
      low: '37.9100000000000000',
      close: '42.5300000000000000',
      volume: '913086.9777100000000000',
      quoteVolume: 37743900.83684656,
      btcVolume: 5472.020626825356,
      usdVolume: 37743900.83684656,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '42.5200000000000000',
      high: '43.0100000000000000',
      low: '41.6100000000000000',
      close: '42.1900000000000000',
      volume: '407623.0458700000000000',
      quoteVolume: 17197450.73896654,
      btcVolume: 2433.5847695278885,
      usdVolume: 17197450.73896654,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '42.2000000000000000',
      high: '44.4300000000000000',
      low: '41.9800000000000000',
      close: '44.1400000000000000',
      volume: '497104.9427100000000000',
      quoteVolume: 21438290.761193395,
      btcVolume: 2994.1891368990136,
      usdVolume: 21438290.761193395,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '44.1600000000000000',
      high: '44.3000000000000000',
      low: '41.5400000000000000',
      close: '42.2300000000000000',
      volume: '456205.8125200000000000',
      quoteVolume: 19598464.2516532,
      btcVolume: 2739.1972282400675,
      usdVolume: 19598464.2516532,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '42.2300000000000000',
      high: '43.2400000000000000',
      low: '39.5500000000000000',
      close: '40.2100000000000000',
      volume: '543840.0568300000000000',
      quoteVolume: 22495305.856098518,
      btcVolume: 3219.760771868381,
      usdVolume: 22495305.856098518,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '40.2100000000000000',
      high: '41.3400000000000000',
      low: '40.0100000000000000',
      close: '40.5900000000000000',
      volume: '345238.8723200000000000',
      quoteVolume: 14009507.177860197,
      btcVolume: 2044.2849891545281,
      usdVolume: 14009507.177860197,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '40.5900000000000000',
      high: '42.3200000000000000',
      low: '40.2700000000000000',
      close: '41.8000000000000000',
      volume: '462963.2967500000000000',
      quoteVolume: 19150251.779417757,
      btcVolume: 2733.259301365301,
      usdVolume: 19150251.779417757,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '41.8100000000000000',
      high: '43.9100000000000000',
      low: '40.9400000000000000',
      close: '42.9800000000000000',
      volume: '638808.1586600000000000',
      quoteVolume: 27205984.39496531,
      btcVolume: 3698.639315507722,
      usdVolume: 27205984.39496531,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '42.9800000000000000',
      high: '44.9900000000000000',
      low: '42.8300000000000000',
      close: '44.5300000000000000',
      volume: '623497.4206400000000000',
      quoteVolume: 27494508.18459142,
      btcVolume: 3656.2769200491334,
      usdVolume: 27494508.18459142,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '44.5200000000000000',
      high: '45.5800000000000000',
      low: '43.8400000000000000',
      close: '44.3800000000000000',
      volume: '555367.1872400000000000',
      quoteVolume: 24833710.353949737,
      btcVolume: 3290.9485922233407,
      usdVolume: 24833710.353949737,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '44.3800000000000000',
      high: '45.3900000000000000',
      low: '43.7500000000000000',
      close: '44.5200000000000000',
      volume: '485605.1559300000000000',
      quoteVolume: 21633052.172737543,
      btcVolume: 2845.7674049944853,
      usdVolume: 21633052.172737543,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '44.5400000000000000',
      high: '45.2300000000000000',
      low: '43.1600000000000000',
      close: '44.4600000000000000',
      volume: '443570.6602900000000000',
      quoteVolume: 19611892.418601938,
      btcVolume: 2546.7952458477666,
      usdVolume: 19611892.418601938,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '44.4700000000000000',
      high: '46.1300000000000000',
      low: '43.4200000000000000',
      close: '45.6900000000000000',
      volume: '590012.5230400000000000',
      quoteVolume: 26316522.275929503,
      btcVolume: 3404.4738459937435,
      usdVolume: 26316522.275929503,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '45.6900000000000000',
      high: '49.9100000000000000',
      low: '45.6400000000000000',
      close: '48.8100000000000000',
      volume: '1198086.9005900000000000',
      quoteVolume: 56983117.16653303,
      btcVolume: 6840.247516585622,
      usdVolume: 56983117.16653303,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '48.8100000000000000',
      high: '50.8000000000000000',
      low: '45.5900000000000000',
      close: '46.3300000000000000',
      volume: '1404189.5332000000000000',
      quoteVolume: 67399084.98858932,
      btcVolume: 7558.333091499474,
      usdVolume: 67399084.98858932,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '46.3300000000000000',
      high: '48.2700000000000000',
      low: '46.3000000000000000',
      close: '47.0700000000000000',
      volume: '499757.9662500000000000',
      quoteVolume: 23637798.368433,
      btcVolume: 2688.212536886058,
      usdVolume: 23637798.368433,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '47.0800000000000000',
      high: '49.6000000000000000',
      low: '46.7300000000000000',
      close: '49.2000000000000000',
      volume: '408979.1499600000000000',
      quoteVolume: 19681383.60385065,
      btcVolume: 2212.021453381788,
      usdVolume: 19681383.60385065,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '49.2000000000000000',
      high: '50.0200000000000000',
      low: '46.6100000000000000',
      close: '48.0500000000000000',
      volume: '566317.3808100000000000',
      quoteVolume: 27433863.01719666,
      btcVolume: 3063.5466670768265,
      usdVolume: 27433863.01719666,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '48.0700000000000000',
      high: '48.2000000000000000',
      low: '45.1100000000000000',
      close: '46.9600000000000000',
      volume: '693367.0109200000000000',
      quoteVolume: 32216489.944114767,
      btcVolume: 3687.084305406875,
      usdVolume: 32216489.944114767,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '46.9700000000000000',
      high: '47.7600000000000000',
      low: '45.7100000000000000',
      close: '46.5400000000000000',
      volume: '415057.4376200000000000',
      quoteVolume: 19397806.104704224,
      btcVolume: 2173.7880200232094,
      usdVolume: 19397806.104704224,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '46.5600000000000000',
      high: '47.5500000000000000',
      low: '44.5500000000000000',
      close: '44.9400000000000000',
      volume: '466241.7278500000000000',
      quoteVolume: 21723146.905489907,
      btcVolume: 2368.579458205406,
      usdVolume: 21723146.905489907,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '44.9200000000000000',
      high: '48.2200000000000000',
      low: '44.3700000000000000',
      close: '47.3700000000000000',
      volume: '796061.5993300000000000',
      quoteVolume: 37063053.8901233,
      btcVolume: 3872.7360538266807,
      usdVolume: 37063053.8901233,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '47.3700000000000000',
      high: '48.4300000000000000',
      low: '46.3400000000000000',
      close: '47.6900000000000000',
      volume: '631882.8103600000000000',
      quoteVolume: 30005956.286906376,
      btcVolume: 3025.61774026537,
      usdVolume: 30005956.286906376,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '47.7000000000000000',
      high: '49.1000000000000000',
      low: '46.7500000000000000',
      close: '46.9500000000000000',
      volume: '656199.9985000000000000',
      quoteVolume: 31531059.60198355,
      btcVolume: 3242.069854321196,
      usdVolume: 31531059.60198355,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '46.9700000000000000',
      high: '47.0400000000000000',
      low: '39.4000000000000000',
      close: '42.0600000000000000',
      volume: '1404081.1754800000000000',
      quoteVolume: 58997967.78654685,
      btcVolume: 6799.973073584511,
      usdVolume: 58997967.78654685,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '42.0500000000000000',
      high: '42.9000000000000000',
      low: '39.3700000000000000',
      close: '41.5100000000000000',
      volume: '955643.7080500000000000',
      quoteVolume: 39459230.38420516,
      btcVolume: 4550.364445794178,
      usdVolume: 39459230.38420516,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '41.4900000000000000',
      high: '42.8800000000000000',
      low: '41.2600000000000000',
      close: '42.1800000000000000',
      volume: '598291.6399500000000000',
      quoteVolume: 25210189.417792954,
      btcVolume: 2873.5800124011444,
      usdVolume: 25210189.417792954,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '42.1800000000000000',
      high: '43.7400000000000000',
      low: '41.9400000000000000',
      close: '43.1800000000000000',
      volume: '401563.4178500000000000',
      quoteVolume: 17187427.88953854,
      btcVolume: 1892.7048671079617,
      usdVolume: 17187427.88953854,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '43.1800000000000000',
      high: '44.3800000000000000',
      low: '42.2100000000000000',
      close: '43.9800000000000000',
      volume: '507436.3497400000000000',
      quoteVolume: 22075904.008925483,
      btcVolume: 2303.152931180216,
      usdVolume: 22075904.008925483,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '43.9900000000000000',
      high: '44.1400000000000000',
      low: '41.8200000000000000',
      close: '42.7400000000000000',
      volume: '468825.9372000000000000',
      quoteVolume: 20205683.375076648,
      btcVolume: 2131.6645117325697,
      usdVolume: 20205683.375076648,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '42.7300000000000000',
      high: '43.8900000000000000',
      low: '42.5000000000000000',
      close: '43.2500000000000000',
      volume: '435940.4069700000000000',
      quoteVolume: 18877872.465006832,
      btcVolume: 2006.2006217660262,
      usdVolume: 18877872.465006832,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '43.2500000000000000',
      high: '44.4900000000000000',
      low: '43.1000000000000000',
      close: '43.7100000000000000',
      volume: '344451.6233800000000000',
      quoteVolume: 15152133.939513858,
      btcVolume: 1573.049518404476,
      usdVolume: 15152133.939513858,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '43.7000000000000000',
      high: '46.5900000000000000',
      low: '43.6400000000000000',
      close: '45.3200000000000000',
      volume: '694268.3291300000000000',
      quoteVolume: 31428313.748912312,
      btcVolume: 3215.1275437118916,
      usdVolume: 31428313.748912312,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '45.3300000000000000',
      high: '45.8000000000000000',
      low: '44.0700000000000000',
      close: '45.7300000000000000',
      volume: '375917.6522500000000000',
      quoteVolume: 16910574.08702791,
      btcVolume: 1747.0177395296405,
      usdVolume: 16910574.08702791,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '45.7300000000000000',
      high: '46.1400000000000000',
      low: '43.1700000000000000',
      close: '44.2500000000000000',
      volume: '546255.0060000000000000',
      quoteVolume: 24521814.476579513,
      btcVolume: 2542.608320507337,
      usdVolume: 24521814.476579513,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '44.2400000000000000',
      high: '44.6200000000000000',
      low: '41.6600000000000000',
      close: '42.7300000000000000',
      volume: '504821.8913700000000000',
      quoteVolume: 21790870.5584972,
      btcVolume: 2362.9361362732657,
      usdVolume: 21790870.5584972,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '42.7200000000000000',
      high: '44.6800000000000000',
      low: '42.3500000000000000',
      close: '44.2300000000000000',
      volume: '398405.5385600000000000',
      quoteVolume: 17376646.5729942,
      btcVolume: 1903.3033669829076,
      usdVolume: 17376646.5729942,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '44.2200000000000000',
      high: '44.7400000000000000',
      low: '43.6700000000000000',
      close: '43.8500000000000000',
      volume: '237463.4446900000000000',
      quoteVolume: 10482358.645321624,
      btcVolume: 1139.0076516333727,
      usdVolume: 10482358.645321624,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '43.8400000000000000',
      high: '44.5500000000000000',
      low: '42.0400000000000000',
      close: '42.0800000000000000',
      volume: '360033.5515400000000000',
      quoteVolume: 15679863.525856707,
      btcVolume: 1726.0693649842788,
      usdVolume: 15679863.525856707,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '42.0800000000000000',
      high: '43.3200000000000000',
      low: '41.7200000000000000',
      close: '42.9700000000000000',
      volume: '250914.5274100000000000',
      quoteVolume: 10724204.717699511,
      btcVolume: 1217.8718797560175,
      usdVolume: 10724204.717699511,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '42.9900000000000000',
      high: '43.0700000000000000',
      low: '41.7600000000000000',
      close: '42.2700000000000000',
      volume: '218690.0446300000000000',
      quoteVolume: 9289007.752625285,
      btcVolume: 1049.1031437450883,
      usdVolume: 9289007.752625285,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '42.2600000000000000',
      high: '44.0500000000000000',
      low: '42.2200000000000000',
      close: '43.8500000000000000',
      volume: '282807.4623400000000000',
      quoteVolume: 12241409.404598255,
      btcVolume: 1350.5834483933272,
      usdVolume: 12241409.404598255,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '43.8600000000000000',
      high: '44.8800000000000000',
      low: '43.1500000000000000',
      close: '44.7700000000000000',
      volume: '291358.4733600000000000',
      quoteVolume: 12814294.25871001,
      btcVolume: 1372.8438156303184,
      usdVolume: 12814294.25871001,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '44.7700000000000000',
      high: '45.2900000000000000',
      low: '44.1700000000000000',
      close: '44.4700000000000000',
      volume: '298461.3751300000000000',
      quoteVolume: 13345930.015912458,
      btcVolume: 1409.140378403909,
      usdVolume: 13345930.015912458,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '44.4900000000000000',
      high: '48.0800000000000000',
      low: '44.1800000000000000',
      close: '47.7200000000000000',
      volume: '590070.5535000000000000',
      quoteVolume: 27463513.679888483,
      btcVolume: 2881.444155580501,
      usdVolume: 27463513.679888483,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '47.7200000000000000',
      high: '47.8400000000000000',
      low: '45.0700000000000000',
      close: '45.6100000000000000',
      volume: '494076.1867300000000000',
      quoteVolume: 23046362.233182173,
      btcVolume: 2414.446392609635,
      usdVolume: 23046362.233182173,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '45.6000000000000000',
      high: '48.8100000000000000',
      low: '45.3600000000000000',
      close: '48.2500000000000000',
      volume: '599583.6772200000000000',
      quoteVolume: 28169093.711731534,
      btcVolume: 2895.4119659902226,
      usdVolume: 28169093.711731534,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '48.2600000000000000',
      high: '49.9600000000000000',
      low: '44.0000000000000000',
      close: '45.9900000000000000',
      volume: '920374.0312200000000000',
      quoteVolume: 43606491.94942139,
      btcVolume: 4436.165811723226,
      usdVolume: 43606491.94942139,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '47.5000000000000000',
      low: '45.2500000000000000',
      close: '47.1600000000000000',
      volume: '428190.8216400000000000',
      quoteVolume: 19934098.49867122,
      btcVolume: 2086.467816317527,
      usdVolume: 19934098.49867122,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '47.1500000000000000',
      high: '48.1000000000000000',
      low: '46.1500000000000000',
      close: '47.4300000000000000',
      volume: '471692.4156600000000000',
      quoteVolume: 22364295.281365484,
      btcVolume: 2310.0219355528084,
      usdVolume: 22364295.281365484,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '47.4300000000000000',
      high: '47.9800000000000000',
      low: '46.4200000000000000',
      close: '46.8300000000000000',
      volume: '400835.1846600000000000',
      quoteVolume: 18969882.083338924,
      btcVolume: 1945.0400952427456,
      usdVolume: 18969882.083338924,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '46.8000000000000000',
      high: '47.2400000000000000',
      low: '46.2000000000000000',
      close: '46.7900000000000000',
      volume: '195688.0406400000000000',
      quoteVolume: 9141881.677323475,
      btcVolume: 948.8992424450283,
      usdVolume: 9141881.677323475,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '46.7900000000000000',
      high: '46.9800000000000000',
      low: '45.0600000000000000',
      close: '46.6300000000000000',
      volume: '306262.5414800000000000',
      quoteVolume: 14151417.896596055,
      btcVolume: 1473.8708394166745,
      usdVolume: 14151417.896596055,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '46.6000000000000000',
      high: '46.7900000000000000',
      low: '45.9500000000000000',
      close: '46.4300000000000000',
      volume: '220499.0699300000000000',
      quoteVolume: 10227376.443103297,
      btcVolume: 1051.937083531138,
      usdVolume: 10227376.443103297,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '46.4500000000000000',
      high: '46.8400000000000000',
      low: '45.3100000000000000',
      close: '45.8700000000000000',
      volume: '219582.2168000000000000',
      quoteVolume: 10105379.596295863,
      btcVolume: 1040.202431965599,
      usdVolume: 10105379.596295863,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '45.8900000000000000',
      high: '47.1900000000000000',
      low: '45.6500000000000000',
      close: '46.6300000000000000',
      volume: '319562.9294800000000000',
      quoteVolume: 14778011.431747977,
      btcVolume: 1507.8102529037167,
      usdVolume: 14778011.431747977,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '46.6300000000000000',
      high: '46.9600000000000000',
      low: '42.0000000000000000',
      close: '43.2200000000000000',
      volume: '538312.5504400000000000',
      quoteVolume: 24117129.0557672,
      btcVolume: 2526.1064285493794,
      usdVolume: 24117129.0557672,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '43.2200000000000000',
      high: '45.3600000000000000',
      low: '42.8100000000000000',
      close: '44.7200000000000000',
      volume: '236637.2390700000000000',
      quoteVolume: 10478472.454529103,
      btcVolume: 1112.938106242603,
      usdVolume: 10478472.454529103,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '44.7200000000000000',
      high: '45.2200000000000000',
      low: '44.2700000000000000',
      close: '45.0800000000000000',
      volume: '151582.8051800000000000',
      quoteVolume: 6775955.931185166,
      btcVolume: 718.694564235063,
      usdVolume: 6775955.931185166,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '45.0900000000000000',
      high: '45.1100000000000000',
      low: '43.5300000000000000',
      close: '44.0500000000000000',
      volume: '170082.7402900000000000',
      quoteVolume: 7542598.776623745,
      btcVolume: 802.8177727597499,
      usdVolume: 7542598.776623745,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '44.0400000000000000',
      high: '44.0900000000000000',
      low: '41.7500000000000000',
      close: '43.7300000000000000',
      volume: '355287.5401600000000000',
      quoteVolume: 15285580.752425862,
      btcVolume: 1659.6268802012912,
      usdVolume: 15285580.752425862,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '43.7300000000000000',
      high: '44.2200000000000000',
      low: '43.3300000000000000',
      close: '43.8800000000000000',
      volume: '215557.5907300000000000',
      quoteVolume: 9436824.369403064,
      btcVolume: 993.2645058025514,
      usdVolume: 9436824.369403064,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '43.9000000000000000',
      high: '45.0400000000000000',
      low: '43.0000000000000000',
      close: '44.0700000000000000',
      volume: '324893.8614200000000000',
      quoteVolume: 14346683.24048972,
      btcVolume: 1519.1996398986237,
      usdVolume: 14346683.24048972,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '44.0700000000000000',
      high: '44.2300000000000000',
      low: '42.8800000000000000',
      close: '43.4300000000000000',
      volume: '193534.9893800000000000',
      quoteVolume: 8454598.742349433,
      btcVolume: 898.8378626695845,
      usdVolume: 8454598.742349433,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '43.4300000000000000',
      high: '43.7300000000000000',
      low: '42.5700000000000000',
      close: '42.8900000000000000',
      volume: '304810.9753600000000000',
      quoteVolume: 13177549.12879669,
      btcVolume: 1412.1592884165075,
      usdVolume: 13177549.12879669,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '42.8900000000000000',
      high: '43.8500000000000000',
      low: '42.2100000000000000',
      close: '43.5700000000000000',
      volume: '202428.9463000000000000',
      quoteVolume: 8709765.120769074,
      btcVolume: 936.1793726946369,
      usdVolume: 8709765.120769074,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '43.5700000000000000',
      high: '43.8300000000000000',
      low: '42.8800000000000000',
      close: '43.0000000000000000',
      volume: '159632.6785000000000000',
      quoteVolume: 6913195.771169413,
      btcVolume: 739.0315965348125,
      usdVolume: 6913195.771169413,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '43.0000000000000000',
      high: '44.6700000000000000',
      low: '42.9400000000000000',
      close: '44.3000000000000000',
      volume: '281062.1117900000000000',
      quoteVolume: 12346345.856977155,
      btcVolume: 1299.505700098188,
      usdVolume: 12346345.856977155,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '44.3000000000000000',
      high: '44.4900000000000000',
      low: '43.8900000000000000',
      close: '44.1800000000000000',
      volume: '219363.9022300000000000',
      quoteVolume: 9684310.145484513,
      btcVolume: 1004.7333264570984,
      usdVolume: 9684310.145484513,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '44.1800000000000000',
      high: '44.6900000000000000',
      low: '42.1000000000000000',
      close: '42.6500000000000000',
      volume: '357937.9722300000000000',
      quoteVolume: 15461096.439797288,
      btcVolume: 1637.8654486722658,
      usdVolume: 15461096.439797288,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '42.6500000000000000',
      high: '43.0000000000000000',
      low: '41.6800000000000000',
      close: '42.3500000000000000',
      volume: '241972.0063200000000000',
      quoteVolume: 10274173.075952465,
      btcVolume: 1114.9908980390967,
      usdVolume: 10274173.075952465,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '42.3400000000000000',
      high: '43.6000000000000000',
      low: '41.3700000000000000',
      close: '43.0900000000000000',
      volume: '348379.3200800000000000',
      quoteVolume: 14852775.950750457,
      btcVolume: 1618.6751008852393,
      usdVolume: 14852775.950750457,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '43.1000000000000000',
      high: '43.1200000000000000',
      low: '39.1100000000000000',
      close: '41.0600000000000000',
      volume: '301504.6343000000000000',
      quoteVolume: 12500262.768287318,
      btcVolume: 1381.4322759722381,
      usdVolume: 12500262.768287318,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '41.0400000000000000',
      high: '42.0500000000000000',
      low: '40.4800000000000000',
      close: '41.3700000000000000',
      volume: '169011.9868500000000000',
      quoteVolume: 6984592.714551371,
      btcVolume: 769.65230482301,
      usdVolume: 6984592.714551371,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '41.3700000000000000',
      high: '42.2900000000000000',
      low: '40.5900000000000000',
      close: '41.8700000000000000',
      volume: '323346.1720000000000000',
      quoteVolume: 13400088.961560369,
      btcVolume: 1466.6645153508061,
      usdVolume: 13400088.961560369,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '41.8700000000000000',
      high: '42.0700000000000000',
      low: '40.7300000000000000',
      close: '41.1700000000000000',
      volume: '170712.3676100000000000',
      quoteVolume: 7047775.850108025,
      btcVolume: 770.4331270254231,
      usdVolume: 7047775.850108025,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '41.1600000000000000',
      high: '42.0100000000000000',
      low: '40.9600000000000000',
      close: '41.7700000000000000',
      volume: '189395.4022900000000000',
      quoteVolume: 7873933.718510014,
      btcVolume: 856.8015905668915,
      usdVolume: 7873933.718510014,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '41.7600000000000000',
      high: '41.9000000000000000',
      low: '40.2800000000000000',
      close: '41.0500000000000000',
      volume: '274520.0541700000000000',
      quoteVolume: 11294890.195721142,
      btcVolume: 1236.9147850457389,
      usdVolume: 11294890.195721142,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '41.0300000000000000',
      high: '41.7000000000000000',
      low: '40.8900000000000000',
      close: '41.1400000000000000',
      volume: '188681.9363500000000000',
      quoteVolume: 7794144.723730499,
      btcVolume: 857.0715613494609,
      usdVolume: 7794144.723730499,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '41.1300000000000000',
      high: '42.2300000000000000',
      low: '41.0600000000000000',
      close: '42.1100000000000000',
      volume: '224248.6520000000000000',
      quoteVolume: 9360266.498081032,
      btcVolume: 1028.5227163525683,
      usdVolume: 9360266.498081032,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '42.1100000000000000',
      high: '42.2000000000000000',
      low: '40.7500000000000000',
      close: '41.5800000000000000',
      volume: '226360.8260500000000000',
      quoteVolume: 9392173.28080973,
      btcVolume: 1037.076979817458,
      usdVolume: 9392173.28080973,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '41.5800000000000000',
      high: '44.3100000000000000',
      low: '41.5000000000000000',
      close: '44.0600000000000000',
      volume: '368489.7151600000000000',
      quoteVolume: 15857759.209187848,
      btcVolume: 1716.882729781821,
      usdVolume: 15857759.209187848,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '44.0700000000000000',
      high: '44.4900000000000000',
      low: '43.0600000000000000',
      close: '43.3900000000000000',
      volume: '307753.4437600000000000',
      quoteVolume: 13417182.820945997,
      btcVolume: 1447.5272221888722,
      usdVolume: 13417182.820945997,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '43.3900000000000000',
      high: '45.6200000000000000',
      low: '43.1000000000000000',
      close: '45.3500000000000000',
      volume: '527238.1869100000000000',
      quoteVolume: 23640875.868721846,
      btcVolume: 2531.3426117904874,
      usdVolume: 23640875.868721846,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '45.3500000000000000',
      high: '45.8700000000000000',
      low: '43.5700000000000000',
      close: '44.3300000000000000',
      volume: '413262.0522600000000000',
      quoteVolume: 18508468.305630103,
      btcVolume: 1984.9220768982761,
      usdVolume: 18508468.305630103,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '44.3400000000000000',
      high: '44.6400000000000000',
      low: '43.4400000000000000',
      close: '44.3000000000000000',
      volume: '256685.4454300000000000',
      quoteVolume: 11325710.672338482,
      btcVolume: 1231.7303880489342,
      usdVolume: 11325710.672338482,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '44.3200000000000000',
      high: '44.7700000000000000',
      low: '43.8500000000000000',
      close: '44.6900000000000000',
      volume: '175099.4520400000000000',
      quoteVolume: 7760961.70704716,
      btcVolume: 840.2449316980167,
      usdVolume: 7760961.70704716,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '44.6800000000000000',
      high: '45.3000000000000000',
      low: '43.7000000000000000',
      close: '44.7000000000000000',
      volume: '245471.1214700000000000',
      quoteVolume: 10954049.958728487,
      btcVolume: 1184.221349967855,
      usdVolume: 10954049.958728487,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '44.7200000000000000',
      high: '45.5900000000000000',
      low: '42.3100000000000000',
      close: '43.9200000000000000',
      volume: '445103.8423000000000000',
      quoteVolume: 19783707.239249174,
      btcVolume: 2134.0023954961785,
      usdVolume: 19783707.239249174,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '43.9100000000000000',
      high: '44.0900000000000000',
      low: '42.8600000000000000',
      close: '43.8800000000000000',
      volume: '246958.9888000000000000',
      quoteVolume: 10753439.352485811,
      btcVolume: 1167.1898197526466,
      usdVolume: 10753439.352485811,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '43.8800000000000000',
      high: '44.0600000000000000',
      low: '42.8100000000000000',
      close: '43.3300000000000000',
      volume: '216931.7592000000000000',
      quoteVolume: 9414665.121901916,
      btcVolume: 1020.4055870355884,
      usdVolume: 9414665.121901916,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '43.3400000000000000',
      high: '43.4900000000000000',
      low: '40.8500000000000000',
      close: '42.0900000000000000',
      volume: '389747.3708100000000000',
      quoteVolume: 16388849.494132191,
      btcVolume: 1793.8588392303009,
      usdVolume: 16388849.494132191,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '42.0900000000000000',
      high: '42.4900000000000000',
      low: '41.7200000000000000',
      close: '41.9000000000000000',
      volume: '201168.4514700000000000',
      quoteVolume: 8451330.732305363,
      btcVolume: 925.0899826232296,
      usdVolume: 8451330.732305363,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '41.8800000000000000',
      high: '42.9000000000000000',
      low: '41.7300000000000000',
      close: '42.4500000000000000',
      volume: '171429.4827800000000000',
      quoteVolume: 7261317.07974402,
      btcVolume: 792.6440309584311,
      usdVolume: 7261317.07974402,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '42.4500000000000000',
      high: '42.8900000000000000',
      low: '41.9400000000000000',
      close: '42.6400000000000000',
      volume: '131250.1648000000000000',
      quoteVolume: 5562354.392689597,
      btcVolume: 607.4853703600584,
      usdVolume: 5562354.392689597,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '42.6500000000000000',
      high: '42.7900000000000000',
      low: '41.3700000000000000',
      close: '41.9400000000000000',
      volume: '180313.8811200000000000',
      quoteVolume: 7608229.1871632915,
      btcVolume: 829.548965830741,
      usdVolume: 7608229.1871632915,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '41.9400000000000000',
      high: '44.1100000000000000',
      low: '41.8700000000000000',
      close: '43.7700000000000000',
      volume: '314409.7580700000000000',
      quoteVolume: 13652466.385358201,
      btcVolume: 1464.4779697490103,
      usdVolume: 13652466.385358201,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '43.7700000000000000',
      high: '45.1200000000000000',
      low: '43.2000000000000000',
      close: '44.9600000000000000',
      volume: '335284.7529800000000000',
      quoteVolume: 14745570.92134318,
      btcVolume: 1567.9594825294864,
      usdVolume: 14745570.92134318,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '44.9600000000000000',
      high: '45.6300000000000000',
      low: '44.5200000000000000',
      close: '44.9400000000000000',
      volume: '432494.1135100000000000',
      quoteVolume: 19488260.358961288,
      btcVolume: 2044.7602331347512,
      usdVolume: 19488260.358961288,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '44.9300000000000000',
      high: '44.9600000000000000',
      low: '43.9300000000000000',
      close: '44.1100000000000000',
      volume: '246802.5687100000000000',
      quoteVolume: 10968239.44478951,
      btcVolume: 1148.885394620396,
      usdVolume: 10968239.44478951,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '44.1200000000000000',
      high: '49.5200000000000000',
      low: '44.0300000000000000',
      close: '49.1600000000000000',
      volume: '908436.8540300000000000',
      quoteVolume: 43121417.14661318,
      btcVolume: 4487.739810331088,
      usdVolume: 43121417.14661318,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '49.1600000000000000',
      high: '50.7900000000000000',
      low: '47.4900000000000000',
      close: '48.1000000000000000',
      volume: '895757.1956100000000000',
      quoteVolume: 43736445.02248403,
      btcVolume: 4445.709420722064,
      usdVolume: 43736445.02248403,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '48.0700000000000000',
      high: '55.8300000000000000',
      low: '46.8500000000000000',
      close: '53.2800000000000000',
      volume: '1501022.7139000000000000',
      quoteVolume: 76319950.40804677,
      btcVolume: 7196.4237752346735,
      usdVolume: 76319950.40804677,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '53.2900000000000000',
      high: '57.9200000000000000',
      low: '51.6500000000000000',
      close: '55.7500000000000000',
      volume: '1524796.9333900000000000',
      quoteVolume: 83657329.52223343,
      btcVolume: 7615.786009539288,
      usdVolume: 83657329.52223343,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '55.7600000000000000',
      high: '56.9700000000000000',
      low: '54.6500000000000000',
      close: '55.1600000000000000',
      volume: '827762.0175900000000000',
      quoteVolume: 46195373.44725716,
      btcVolume: 4170.565054592356,
      usdVolume: 46195373.44725716,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '55.1700000000000000',
      high: '58.6100000000000000',
      low: '53.9100000000000000',
      close: '56.7500000000000000',
      volume: '1023616.3229500000000000',
      quoteVolume: 57619092.418167934,
      btcVolume: 5228.698800494788,
      usdVolume: 57619092.418167934,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '56.7300000000000000',
      high: '59.2600000000000000',
      low: '55.7800000000000000',
      close: '58.1300000000000000',
      volume: '656214.7910400000000000',
      quoteVolume: 37799292.05627845,
      btcVolume: 3370.0313097064472,
      usdVolume: 37799292.05627845,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '58.1300000000000000',
      high: '62.2300000000000000',
      low: '57.5500000000000000',
      close: '61.6500000000000000',
      volume: '1209772.3068500000000000',
      quoteVolume: 72750836.99199294,
      btcVolume: 6265.125426344265,
      usdVolume: 72750836.99199294,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '61.6500000000000000',
      high: '65.1700000000000000',
      low: '51.7900000000000000',
      close: '56.7700000000000000',
      volume: '1811190.5919400000000000',
      quoteVolume: 106128081.26600313,
      btcVolume: 9335.06038904262,
      usdVolume: 106128081.26600313,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '56.7700000000000000',
      high: '60.0300000000000000',
      low: '55.7100000000000000',
      close: '58.4800000000000000',
      volume: '825040.8628300000000000',
      quoteVolume: 48042233.88053324,
      btcVolume: 4268.170005799202,
      usdVolume: 48042233.88053324,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '58.5100000000000000',
      high: '59.6200000000000000',
      low: '56.2600000000000000',
      close: '57.6100000000000000',
      volume: '621412.6135200000000000',
      quoteVolume: 35953617.97974933,
      btcVolume: 3203.314765910491,
      usdVolume: 35953617.97974933,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '57.6200000000000000',
      high: '59.7700000000000000',
      low: '56.7300000000000000',
      close: '58.8800000000000000',
      volume: '716365.4747300000000000',
      quoteVolume: 41978222.97824183,
      btcVolume: 3649.591519738918,
      usdVolume: 41978222.97824183,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '58.8800000000000000',
      high: '60.6800000000000000',
      low: '57.6900000000000000',
      close: '59.0300000000000000',
      volume: '822577.3453400000000000',
      quoteVolume: 48695999.688867584,
      btcVolume: 4138.214894335373,
      usdVolume: 48695999.688867584,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '59.0300000000000000',
      high: '61.0800000000000000',
      low: '54.5100000000000000',
      close: '57.1700000000000000',
      volume: '897778.4995300000000000',
      quoteVolume: 52381526.540909186,
      btcVolume: 4494.798690246006,
      usdVolume: 52381526.540909186,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '57.1900000000000000',
      high: '58.5500000000000000',
      low: '56.4600000000000000',
      close: '58.4600000000000000',
      volume: '428068.0562900000000000',
      quoteVolume: 24639956.16664136,
      btcVolume: 2111.277753614711,
      usdVolume: 24639956.16664136,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '58.4600000000000000',
      high: '58.6700000000000000',
      low: '56.0000000000000000',
      close: '56.9600000000000000',
      volume: '368126.2206100000000000',
      quoteVolume: 21112826.33196615,
      btcVolume: 1810.1238543621755,
      usdVolume: 21112826.33196615,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '56.9600000000000000',
      high: '59.4000000000000000',
      low: '56.3800000000000000',
      close: '58.2700000000000000',
      volume: '651148.7946500000000000',
      quoteVolume: 37767773.986994274,
      btcVolume: 3179.571790810527,
      usdVolume: 37767773.986994274,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '58.2700000000000000',
      high: '59.6700000000000000',
      low: '52.3700000000000000',
      close: '54.1000000000000000',
      volume: '887598.7192800000000000',
      quoteVolume: 49937919.23003544,
      btcVolume: 4305.809808019921,
      usdVolume: 49937919.23003544,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '54.1100000000000000',
      high: '55.0500000000000000',
      low: '51.5500000000000000',
      close: '54.5200000000000000',
      volume: '645092.4992200000000000',
      quoteVolume: 34861226.1993341,
      btcVolume: 3047.8872072032136,
      usdVolume: 34861226.1993341,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '54.5400000000000000',
      high: '57.3000000000000000',
      low: '51.8600000000000000',
      close: '57.1400000000000000',
      volume: '837108.8358500000000000',
      quoteVolume: 45688848.09087257,
      btcVolume: 3954.229249874195,
      usdVolume: 45688848.09087257,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '57.1400000000000000',
      high: '57.8500000000000000',
      low: '55.8200000000000000',
      close: '56.7800000000000000',
      volume: '724000.2630800000000000',
      quoteVolume: 41120118.16273497,
      btcVolume: 3499.1980018550985,
      usdVolume: 41120118.16273497,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '56.7800000000000000',
      high: '60.8700000000000000',
      low: '56.4500000000000000',
      close: '59.8500000000000000',
      volume: '1137795.1226200000000000',
      quoteVolume: 66736568.52031225,
      btcVolume: 5623.638209743656,
      usdVolume: 66736568.52031225,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '59.8500000000000000',
      high: '64.6000000000000000',
      low: '57.8000000000000000',
      close: '63.8700000000000000',
      volume: '1576897.5925500000000000',
      quoteVolume: 97000374.93973804,
      btcVolume: 8195.032534326576,
      usdVolume: 97000374.93973804,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '63.8900000000000000',
      high: '68.8800000000000000',
      low: '61.3400000000000000',
      close: '67.2200000000000000',
      volume: '1790813.8027600000000000',
      quoteVolume: 116245941.78525142,
      btcVolume: 9624.636426175008,
      usdVolume: 116245941.78525142,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '67.2200000000000000',
      high: '68.3700000000000000',
      low: '63.3700000000000000',
      close: '65.4000000000000000',
      volume: '1562910.4138300000000000',
      quoteVolume: 103317811.94231711,
      btcVolume: 8525.308883804713,
      usdVolume: 103317811.94231711,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '65.4200000000000000',
      high: '67.1500000000000000',
      low: '58.7300000000000000',
      close: '62.1200000000000000',
      volume: '1699324.3872900000000000',
      quoteVolume: 105697900.02547406,
      btcVolume: 8977.615215296333,
      usdVolume: 105697900.02547406,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '62.0800000000000000',
      high: '63.6800000000000000',
      low: '61.0000000000000000',
      close: '62.8000000000000000',
      volume: '863943.4751600000000000',
      quoteVolume: 54002617.98938122,
      btcVolume: 4582.333629079296,
      usdVolume: 54002617.98938122,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '62.8000000000000000',
      high: '63.9000000000000000',
      low: '58.7700000000000000',
      close: '59.2000000000000000',
      volume: '1052514.0798200000000000',
      quoteVolume: 64605524.54157742,
      btcVolume: 5505.111606281471,
      usdVolume: 64605524.54157742,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '59.1800000000000000',
      high: '60.5400000000000000',
      low: '57.7600000000000000',
      close: '60.2100000000000000',
      volume: '735565.0401700000000000',
      quoteVolume: 43554757.475364566,
      btcVolume: 3776.8163310064206,
      usdVolume: 43554757.475364566,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '60.2100000000000000',
      high: '61.6300000000000000',
      low: '58.0100000000000000',
      close: '60.5500000000000000',
      volume: '629684.8634600000000000',
      quoteVolume: 37576440.64409044,
      btcVolume: 3233.0834064375513,
      usdVolume: 37576440.64409044,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '60.5600000000000000',
      high: '63.0100000000000000',
      low: '59.9700000000000000',
      close: '62.0200000000000000',
      volume: '687059.2379900000000000',
      quoteVolume: 42496265.57367974,
      btcVolume: 3621.93782544044,
      usdVolume: 42496265.57367974,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '62.0300000000000000',
      high: '62.2900000000000000',
      low: '56.6000000000000000',
      close: '58.3400000000000000',
      volume: '1124054.9217200000000000',
      quoteVolume: 66441420.47979737,
      btcVolume: 5809.195150196892,
      usdVolume: 66441420.47979737,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '58.3300000000000000',
      high: '59.1000000000000000',
      low: '57.0200000000000000',
      close: '58.1000000000000000',
      volume: '663363.6882900000000000',
      quoteVolume: 38514355.9755069,
      btcVolume: 3381.1134622002087,
      usdVolume: 38514355.9755069,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '58.1100000000000000',
      high: '59.2400000000000000',
      low: '53.9100000000000000',
      close: '56.1000000000000000',
      volume: '973405.5399700000000000',
      quoteVolume: 55038359.140136294,
      btcVolume: 4861.832698425037,
      usdVolume: 55038359.140136294,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '56.1200000000000000',
      high: '58.2800000000000000',
      low: '55.3300000000000000',
      close: '57.3800000000000000',
      volume: '637283.3690700000000000',
      quoteVolume: 36330743.38773648,
      btcVolume: 3176.5984724538266,
      usdVolume: 36330743.38773648,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '57.3600000000000000',
      high: '58.1800000000000000',
      low: '56.9300000000000000',
      close: '56.9600000000000000',
      volume: '401337.7699700000000000',
      quoteVolume: 23096156.99058844,
      btcVolume: 2008.145085599693,
      usdVolume: 23096156.99058844,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '56.9400000000000000',
      high: '62.9500000000000000',
      low: '56.9400000000000000',
      close: '62.8900000000000000',
      volume: '748060.0688700000000000',
      quoteVolume: 45090938.55684995,
      btcVolume: 3882.7301382338846,
      usdVolume: 45090938.55684995,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '62.8900000000000000',
      high: '63.7000000000000000',
      low: '60.5100000000000000',
      close: '60.9200000000000000',
      volume: '680146.7862100000000000',
      quoteVolume: 42096579.01839404,
      btcVolume: 3604.5811924799323,
      usdVolume: 42096579.01839404,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '60.9400000000000000',
      high: '64.3100000000000000',
      low: '59.6900000000000000',
      close: '62.6900000000000000',
      volume: '858839.7179200000000000',
      quoteVolume: 53770235.135081835,
      btcVolume: 4530.7995896947705,
      usdVolume: 53764694.36727703,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '62.6800000000000000',
      high: '63.1900000000000000',
      low: '56.3200000000000000',
      close: '58.0600000000000000',
      volume: '931097.5278800000000000',
      quoteVolume: 55440952.94712479,
      btcVolume: 4813.422832983661,
      usdVolume: 55440952.94712479,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '58.0700000000000000',
      high: '58.6200000000000000',
      low: '45.3300000000000000',
      close: '47.2800000000000000',
      volume: '1314623.3469700000000000',
      quoteVolume: 70288090.06395356,
      btcVolume: 6485.293399090235,
      usdVolume: 70288090.06395356,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '47.2800000000000000',
      high: '52.3800000000000000',
      low: '46.2400000000000000',
      close: '50.3200000000000000',
      volume: '1475370.2928000000000000',
      quoteVolume: 72616532.67811747,
      btcVolume: 7023.38061709872,
      usdVolume: 72616532.67811747,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '50.3300000000000000',
      high: '51.3200000000000000',
      low: '45.2100000000000000',
      close: '47.8300000000000000',
      volume: '1350136.6749200000000000',
      quoteVolume: 64913830.12016042,
      btcVolume: 6374.162126272181,
      usdVolume: 64913830.12016042,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '47.8200000000000000',
      high: '49.2500000000000000',
      low: '46.2800000000000000',
      close: '47.9200000000000000',
      volume: '652628.8199300000000000',
      quoteVolume: 31304000.690226022,
      btcVolume: 3068.8890835351963,
      usdVolume: 31304000.690226022,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '47.9300000000000000',
      high: '49.1800000000000000',
      low: '45.2200000000000000',
      close: '48.8400000000000000',
      volume: '811120.8419300000000000',
      quoteVolume: 38431332.983129896,
      btcVolume: 3795.0340089993906,
      usdVolume: 38431332.983129896,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '48.8500000000000000',
      high: '49.3500000000000000',
      low: '45.9800000000000000',
      close: '47.4700000000000000',
      volume: '760182.5056400000000000',
      quoteVolume: 36217916.465697624,
      btcVolume: 3579.288705742158,
      usdVolume: 36217916.465697624,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '47.4700000000000000',
      high: '48.8100000000000000',
      low: '46.5200000000000000',
      close: '47.8200000000000000',
      volume: '378772.5075900000000000',
      quoteVolume: 18086146.485945318,
      btcVolume: 1776.629629881756,
      usdVolume: 18086146.485945318,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '47.8200000000000000',
      high: '49.4500000000000000',
      low: '47.7700000000000000',
      close: '48.6700000000000000',
      volume: '472784.4530600000000000',
      quoteVolume: 23042794.85121679,
      btcVolume: 2228.667738928336,
      usdVolume: 23042794.85121679,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '48.6500000000000000',
      high: '49.5900000000000000',
      low: '47.3700000000000000',
      close: '48.9600000000000000',
      volume: '367092.2208900000000000',
      quoteVolume: 17795692.856570706,
      btcVolume: 1728.2423967171549,
      usdVolume: 17795692.856570706,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '48.9500000000000000',
      high: '51.3100000000000000',
      low: '48.3800000000000000',
      close: '50.8000000000000000',
      volume: '451940.7079000000000000',
      quoteVolume: 22526331.35838648,
      btcVolume: 2165.612033112267,
      usdVolume: 22526331.35838648,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '50.8200000000000000',
      high: '51.0400000000000000',
      low: '47.2600000000000000',
      close: '48.1500000000000000',
      volume: '458828.4182100000000000',
      quoteVolume: 22491968.402588304,
      btcVolume: 2169.5843476754585,
      usdVolume: 22491968.402588304,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '48.1700000000000000',
      high: '49.6500000000000000',
      low: '47.1100000000000000',
      close: '49.0800000000000000',
      volume: '493683.7537500000000000',
      quoteVolume: 23889800.051954094,
      btcVolume: 2275.1908831815435,
      usdVolume: 23889800.051954094,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '49.0800000000000000',
      high: '50.3700000000000000',
      low: '48.1000000000000000',
      close: '48.3100000000000000',
      volume: '615374.4693200000000000',
      quoteVolume: 30324133.006873846,
      btcVolume: 2816.2117686878037,
      usdVolume: 30324133.006873846,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '48.2900000000000000',
      high: '48.7500000000000000',
      low: '46.9100000000000000',
      close: '47.7600000000000000',
      volume: '409293.5778300000000000',
      quoteVolume: 19641762.865069613,
      btcVolume: 1806.5374075469983,
      usdVolume: 19641762.865069613,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '47.7600000000000000',
      high: '49.6700000000000000',
      low: '47.6400000000000000',
      close: '48.8500000000000000',
      volume: '412392.2905700000000000',
      quoteVolume: 20027421.966672275,
      btcVolume: 1836.6245811757067,
      usdVolume: 20027421.966672275,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '48.8500000000000000',
      high: '49.0700000000000000',
      low: '47.4400000000000000',
      close: '48.5400000000000000',
      volume: '322351.6253900000000000',
      quoteVolume: 15606108.2084333,
      btcVolume: 1428.8963360394227,
      usdVolume: 15606108.2084333,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '48.5300000000000000',
      high: '49.0300000000000000',
      low: '47.9400000000000000',
      close: '48.5200000000000000',
      volume: '225043.6954300000000000',
      quoteVolume: 10911627.63864736,
      btcVolume: 989.4437357340863,
      usdVolume: 10911627.63864736,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '48.5100000000000000',
      high: '48.5100000000000000',
      low: '46.1500000000000000',
      close: '47.0900000000000000',
      volume: '307000.2131400000000000',
      quoteVolume: 14530136.545319801,
      btcVolume: 1333.6149737245325,
      usdVolume: 14530136.545319801,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '47.1000000000000000',
      high: '47.6400000000000000',
      low: '41.5900000000000000',
      close: '43.1600000000000000',
      volume: '724381.6674200000000000',
      quoteVolume: 32251827.730186,
      btcVolume: 3041.5168729433744,
      usdVolume: 32251827.730186,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '43.1700000000000000',
      high: '44.7900000000000000',
      low: '42.7500000000000000',
      close: '44.4700000000000000',
      volume: '333108.9347300000000000',
      quoteVolume: 14589016.845933013,
      btcVolume: 1393.625461476955,
      usdVolume: 14589016.845933013,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '44.4700000000000000',
      high: '45.1400000000000000',
      low: '42.2500000000000000',
      close: '42.9600000000000000',
      volume: '416831.7164900000000000',
      quoteVolume: 18334844.49414594,
      btcVolume: 1763.5199781287474,
      usdVolume: 18334844.49414594,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '42.9900000000000000',
      high: '45.2900000000000000',
      low: '42.5600000000000000',
      close: '44.9200000000000000',
      volume: '384786.2813000000000000',
      quoteVolume: 16990572.907504234,
      btcVolume: 1626.0734649737904,
      usdVolume: 16990572.907504234,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '44.9200000000000000',
      high: '46.5800000000000000',
      low: '43.9700000000000000',
      close: '46.0200000000000000',
      volume: '417026.3309900000000000',
      quoteVolume: 18981897.443485588,
      btcVolume: 1782.2563567218074,
      usdVolume: 18981897.443485588,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '46.5600000000000000',
      low: '45.1200000000000000',
      close: '46.0300000000000000',
      volume: '239753.2724600000000000',
      quoteVolume: 11008649.377640275,
      btcVolume: 1027.554331921975,
      usdVolume: 11008649.377640275,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '46.6200000000000000',
      low: '44.5600000000000000',
      close: '46.0500000000000000',
      volume: '299418.6488900000000000',
      quoteVolume: 13705245.681490649,
      btcVolume: 1279.5801104718228,
      usdVolume: 13705245.681490649,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '46.0500000000000000',
      high: '47.1400000000000000',
      low: '44.8000000000000000',
      close: '45.2300000000000000',
      volume: '500207.2502000000000000',
      quoteVolume: 23194876.99856291,
      btcVolume: 2133.7172755091074,
      usdVolume: 23194876.99856291,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '45.2300000000000000',
      high: '45.8500000000000000',
      low: '44.9500000000000000',
      close: '45.6900000000000000',
      volume: '314647.6859400000000000',
      quoteVolume: 14294098.56854367,
      btcVolume: 1332.3540477383756,
      usdVolume: 14294098.56854367,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '45.6800000000000000',
      high: '46.7000000000000000',
      low: '45.4400000000000000',
      close: '46.2400000000000000',
      volume: '293684.9697200000000000',
      quoteVolume: 13501441.41659697,
      btcVolume: 1256.481594643728,
      usdVolume: 13501441.41659697,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '46.2600000000000000',
      high: '48.4600000000000000',
      low: '45.2300000000000000',
      close: '46.2500000000000000',
      volume: '678479.7253200000000000',
      quoteVolume: 31764172.418082513,
      btcVolume: 2962.4967676505066,
      usdVolume: 31764172.418082513,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '46.2000000000000000',
      high: '46.9700000000000000',
      low: '43.1900000000000000',
      close: '44.8800000000000000',
      volume: '514023.5243600000000000',
      quoteVolume: 23159362.416366585,
      btcVolume: 2205.834513704922,
      usdVolume: 23159362.416366585,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '44.8800000000000000',
      high: '45.9700000000000000',
      low: '44.7200000000000000',
      close: '45.5100000000000000',
      volume: '225825.7437200000000000',
      quoteVolume: 10251368.171526043,
      btcVolume: 972.2082293671139,
      usdVolume: 10251368.171526043,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '45.5000000000000000',
      high: '47.0700000000000000',
      low: '45.2800000000000000',
      close: '46.6900000000000000',
      volume: '257423.6618800000000000',
      quoteVolume: 11876813.22842714,
      btcVolume: 1119.7951342568836,
      usdVolume: 11876813.22842714,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '46.6800000000000000',
      high: '47.0800000000000000',
      low: '45.5300000000000000',
      close: '46.3400000000000000',
      volume: '287599.9852800000000000',
      quoteVolume: 13304869.255124534,
      btcVolume: 1243.5815826726844,
      usdVolume: 13304869.255124534,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '46.3500000000000000',
      high: '48.2500000000000000',
      low: '45.5200000000000000',
      close: '45.9900000000000000',
      volume: '650695.8909300000000000',
      quoteVolume: 30393271.881367873,
      btcVolume: 2846.7976903218064,
      usdVolume: 30393271.881367873,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '46.9600000000000000',
      low: '45.0200000000000000',
      close: '46.5500000000000000',
      volume: '384592.7193700000000000',
      quoteVolume: 17704976.198836096,
      btcVolume: 1667.6503606006302,
      usdVolume: 17704976.198836096,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '46.5600000000000000',
      high: '47.5000000000000000',
      low: '45.4500000000000000',
      close: '47.2100000000000000',
      volume: '401592.8257000000000000',
      quoteVolume: 18706424.61098511,
      btcVolume: 1743.2242091557664,
      usdVolume: 18706424.61098511,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '47.2100000000000000',
      high: '48.2000000000000000',
      low: '46.5900000000000000',
      close: '47.7800000000000000',
      volume: '402514.8916800000000000',
      quoteVolume: 19072193.160766963,
      btcVolume: 1736.864381218933,
      usdVolume: 19072193.160766963,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '47.7800000000000000',
      high: '50.4600000000000000',
      low: '47.7600000000000000',
      close: '48.8800000000000000',
      volume: '426163.7387700000000000',
      quoteVolume: 21004622.662101585,
      btcVolume: 1853.0414493767137,
      usdVolume: 21004622.662101585,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '48.8900000000000000',
      high: '51.2700000000000000',
      low: '48.6600000000000000',
      close: '50.4800000000000000',
      volume: '389959.9119800000000000',
      quoteVolume: 19591405.274990585,
      btcVolume: 1723.361127572673,
      usdVolume: 19591405.274990585,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '50.4800000000000000',
      high: '51.5000000000000000',
      low: '49.2400000000000000',
      close: '50.5800000000000000',
      volume: '547647.7248700000000000',
      quoteVolume: 27687729.169129193,
      btcVolume: 2420.2362706464387,
      usdVolume: 27687729.169129193,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '50.5800000000000000',
      high: '50.7100000000000000',
      low: '48.8000000000000000',
      close: '49.8900000000000000',
      volume: '415337.0570800000000000',
      quoteVolume: 20736639.884147868,
      btcVolume: 1812.348882829239,
      usdVolume: 20736639.884147868,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '49.9000000000000000',
      high: '51.0400000000000000',
      low: '49.2700000000000000',
      close: '49.7900000000000000',
      volume: '331002.8019200000000000',
      quoteVolume: 16584880.285257336,
      btcVolume: 1454.7096946875001,
      usdVolume: 16584880.285257336,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '49.7900000000000000',
      high: '50.2400000000000000',
      low: '48.8000000000000000',
      close: '49.5300000000000000',
      volume: '348137.2278700000000000',
      quoteVolume: 17261154.582352445,
      btcVolume: 1514.5854746239897,
      usdVolume: 17261154.582352445,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '49.5200000000000000',
      high: '49.8400000000000000',
      low: '45.6000000000000000',
      close: '47.6000000000000000',
      volume: '603775.0778600000000000',
      quoteVolume: 28593842.01842037,
      btcVolume: 2521.7754293189723,
      usdVolume: 28593842.01842037,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '47.6100000000000000',
      high: '47.6400000000000000',
      low: '46.2800000000000000',
      close: '46.9300000000000000',
      volume: '222889.3661200000000000',
      quoteVolume: 10493526.272867309,
      btcVolume: 925.8634715761125,
      usdVolume: 10493526.272867309,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '46.9200000000000000',
      high: '47.9800000000000000',
      low: '46.7800000000000000',
      close: '47.5400000000000000',
      volume: '164733.2730500000000000',
      quoteVolume: 7805904.884538982,
      btcVolume: 683.1095831981876,
      usdVolume: 7805904.884538982,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '47.5400000000000000',
      high: '48.4400000000000000',
      low: '46.8000000000000000',
      close: '47.9900000000000000',
      volume: '316808.8316300000000000',
      quoteVolume: 15130105.410249878,
      btcVolume: 1303.2892230318398,
      usdVolume: 15130105.410249878,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '47.9900000000000000',
      high: '48.4600000000000000',
      low: '46.3600000000000000',
      close: '46.8800000000000000',
      volume: '399229.1667700000000000',
      quoteVolume: 18895656.11728302,
      btcVolume: 1594.7065459227063,
      usdVolume: 18895656.11728302,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '46.8800000000000000',
      high: '55.0000000000000000',
      low: '46.8500000000000000',
      close: '52.9800000000000000',
      volume: '1425655.0175000000000000',
      quoteVolume: 73309576.45552866,
      btcVolume: 5835.867276617416,
      usdVolume: 73309576.45552866,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '52.9800000000000000',
      high: '56.3100000000000000',
      low: '52.9700000000000000',
      close: '54.3500000000000000',
      volume: '1405462.7678200000000000',
      quoteVolume: 77304160.5002028,
      btcVolume: 5998.580339830083,
      usdVolume: 77304160.5002028,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '54.3500000000000000',
      high: '57.0000000000000000',
      low: '53.0200000000000000',
      close: '55.4200000000000000',
      volume: '955931.4407600000000000',
      quoteVolume: 52751563.52434198,
      btcVolume: 4083.2913725414433,
      usdVolume: 52751563.52434198,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '55.4100000000000000',
      high: '59.6400000000000000',
      low: '54.8300000000000000',
      close: '59.2300000000000000',
      volume: '945743.9995400000000000',
      quoteVolume: 54668377.69328972,
      btcVolume: 4200.363593327066,
      usdVolume: 54668377.69328972,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '59.2200000000000000',
      high: '60.0000000000000000',
      low: '56.5300000000000000',
      close: '58.8400000000000000',
      volume: '906306.0174900000000000',
      quoteVolume: 52834489.40751223,
      btcVolume: 4053.9574657856656,
      usdVolume: 52834489.40751223,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '58.8300000000000000',
      high: '59.3100000000000000',
      low: '54.9400000000000000',
      close: '56.8200000000000000',
      volume: '937112.5894400000000000',
      quoteVolume: 53652363.95019294,
      btcVolume: 4114.583402151287,
      usdVolume: 53652363.95019294,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '56.8000000000000000',
      high: '59.0600000000000000',
      low: '56.4600000000000000',
      close: '57.9100000000000000',
      volume: '841336.6319200000000000',
      quoteVolume: 48609139.49673568,
      btcVolume: 3630.1006218151615,
      usdVolume: 48609139.49673568,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '57.9000000000000000',
      high: '60.5500000000000000',
      low: '54.7100000000000000',
      close: '55.7700000000000000',
      volume: '1108813.1369600000000000',
      quoteVolume: 63758393.118976705,
      btcVolume: 4749.631359213374,
      usdVolume: 63758393.118976705,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '55.7600000000000000',
      high: '56.5700000000000000',
      low: '53.3700000000000000',
      close: '54.8700000000000000',
      volume: '798253.7344200000000000',
      quoteVolume: 44138399.06422936,
      btcVolume: 3326.956263048808,
      usdVolume: 44138399.06422936,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '54.8800000000000000',
      high: '55.7800000000000000',
      low: '52.2000000000000000',
      close: '54.0100000000000000',
      volume: '706074.8976900000000000',
      quoteVolume: 38012823.48380605,
      btcVolume: 2842.8179458148893,
      usdVolume: 38012823.48380605,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '54.0200000000000000',
      high: '56.5000000000000000',
      low: '53.6000000000000000',
      close: '55.7200000000000000',
      volume: '568503.1681100000000000',
      quoteVolume: 31438490.218596324,
      btcVolume: 2284.8493085352666,
      usdVolume: 31438490.218596324,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '55.6900000000000000',
      high: '56.1900000000000000',
      low: '54.0700000000000000',
      close: '55.6000000000000000',
      volume: '369373.8115100000000000',
      quoteVolume: 20377654.865856357,
      btcVolume: 1481.5753298312095,
      usdVolume: 20377654.865856357,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '55.6000000000000000',
      high: '56.8400000000000000',
      low: '53.0100000000000000',
      close: '53.8000000000000000',
      volume: '700498.4928000000000000',
      quoteVolume: 38304588.60460887,
      btcVolume: 2824.797492464806,
      usdVolume: 38304588.60460887,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '53.8200000000000000',
      high: '54.3700000000000000',
      low: '51.1300000000000000',
      close: '53.9600000000000000',
      volume: '671178.3424500000000000',
      quoteVolume: 35476946.64793883,
      btcVolume: 2616.935741127481,
      usdVolume: 35476946.64793883,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '53.9600000000000000',
      high: '55.2400000000000000',
      low: '51.5900000000000000',
      close: '54.6700000000000000',
      volume: '801009.7913300000000000',
      quoteVolume: 42873857.89305916,
      btcVolume: 3085.610113492847,
      usdVolume: 42873857.89305916,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '54.7000000000000000',
      high: '59.7300000000000000',
      low: '54.6200000000000000',
      close: '58.9500000000000000',
      volume: '1366335.6293900000000000',
      quoteVolume: 78053912.16519552,
      btcVolume: 5264.593238274786,
      usdVolume: 78053912.16519552,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '58.9400000000000000',
      high: '63.6800000000000000',
      low: '58.6100000000000000',
      close: '63.2000000000000000',
      volume: '1688091.0752800000000000',
      quoteVolume: 103709490.48633637,
      btcVolume: 6656.930874335542,
      usdVolume: 103709490.48633637,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '63.2200000000000000',
      high: '64.7700000000000000',
      low: '57.0000000000000000',
      close: '58.9000000000000000',
      volume: '1654668.9549600000000000',
      quoteVolume: 101602887.2770486,
      btcVolume: 6659.106944962225,
      usdVolume: 101602887.2770486,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '58.9000000000000000',
      high: '62.0300000000000000',
      low: '58.5200000000000000',
      close: '61.0200000000000000',
      volume: '794156.2220100000000000',
      quoteVolume: 47870580.79491664,
      btcVolume: 3150.0612302343625,
      usdVolume: 47870580.79491664,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '61.0000000000000000',
      high: '61.8600000000000000',
      low: '57.3700000000000000',
      close: '59.2300000000000000',
      volume: '997389.4548800000000000',
      quoteVolume: 59476114.155003875,
      btcVolume: 3879.134836223116,
      usdVolume: 59476114.155003875,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '59.2200000000000000',
      high: '59.6000000000000000',
      low: '57.0400000000000000',
      close: '57.9300000000000000',
      volume: '639971.9687300000000000',
      quoteVolume: 37332965.35725458,
      btcVolume: 2441.6074414528925,
      usdVolume: 37332965.35725458,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '57.9400000000000000',
      high: '60.5300000000000000',
      low: '57.8000000000000000',
      close: '59.2700000000000000',
      volume: '824536.8814700000000000',
      quoteVolume: 49135913.09168669,
      btcVolume: 3157.3304871935893,
      usdVolume: 49135913.09168669,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '59.2800000000000000',
      high: '60.8200000000000000',
      low: '57.6000000000000000',
      close: '60.7200000000000000',
      volume: '830578.2622800000000000',
      quoteVolume: 48949867.85248493,
      btcVolume: 3069.4575024684545,
      usdVolume: 48949867.85248493,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '60.7200000000000000',
      high: '66.6600000000000000',
      low: '60.1400000000000000',
      close: '65.9600000000000000',
      volume: '1890699.0773000000000000',
      quoteVolume: 120417135.62659656,
      btcVolume: 7402.366563715269,
      usdVolume: 120416609.86659656,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '65.9600000000000000',
      high: '66.3200000000000000',
      low: '61.6300000000000000',
      close: '63.8500000000000000',
      volume: '1004682.3311900000000000',
      quoteVolume: 63840748.70659265,
      btcVolume: 3982.6190328362654,
      usdVolume: 63840748.70659265,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '63.8300000000000000',
      high: '64.4300000000000000',
      low: '61.0800000000000000',
      close: '62.2800000000000000',
      volume: '685198.3981500000000000',
      quoteVolume: 42934465.16887135,
      btcVolume: 2687.015722325898,
      usdVolume: 42934465.16887135,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '62.2900000000000000',
      high: '74.6200000000000000',
      low: '62.0800000000000000',
      close: '73.8400000000000000',
      volume: '2523297.8789300000000000',
      quoteVolume: 174212415.64149517,
      btcVolume: 10641.431291066707,
      usdVolume: 174212415.64149517,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '73.8300000000000000',
      high: '77.0000000000000000',
      low: '71.3600000000000000',
      close: '76.3200000000000000',
      volume: '2672837.8382000000000000',
      quoteVolume: 197658068.17998445,
      btcVolume: 11577.927976041276,
      usdVolume: 197658068.17998445,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '76.3200000000000000',
      high: '77.1300000000000000',
      low: '67.7700000000000000',
      close: '73.4200000000000000',
      volume: '2633618.3237700000000000',
      quoteVolume: 192305528.25402403,
      btcVolume: 10784.179482659336,
      usdVolume: 192305528.25402403,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '73.4100000000000000',
      high: '83.4000000000000000',
      low: '71.4700000000000000',
      close: '81.5300000000000000',
      volume: '2733332.0071700000000000',
      quoteVolume: 212853407.31297737,
      btcVolume: 11944.761485430221,
      usdVolume: 212853407.31297737,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '81.5400000000000000',
      high: '85.6300000000000000',
      low: '79.9900000000000000',
      close: '82.5300000000000000',
      volume: '2343217.5381100000000000',
      quoteVolume: 192700587.20860234,
      btcVolume: 10522.118130650888,
      usdVolume: 192700587.20860234,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '82.5500000000000000',
      high: '88.5000000000000000',
      low: '80.1000000000000000',
      close: '87.1400000000000000',
      volume: '2521719.4082800000000000',
      quoteVolume: 212594343.03374535,
      btcVolume: 11359.43501812571,
      usdVolume: 212594343.03374535,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '87.0900000000000000',
      high: '87.9200000000000000',
      low: '77.3000000000000000',
      close: '82.8700000000000000',
      volume: '2048304.5808200000000000',
      quoteVolume: 171408076.02709004,
      btcVolume: 9333.179458237288,
      usdVolume: 171408076.02709004,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '82.8700000000000000',
      high: '90.9300000000000000',
      low: '80.8000000000000000',
      close: '89.0300000000000000',
      volume: '2486393.8341900000000000',
      quoteVolume: 215727635.64653075,
      btcVolume: 11699.840364269925,
      usdVolume: 215727635.64653075,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '89.0400000000000000',
      high: '94.0200000000000000',
      low: '84.6000000000000000',
      close: '89.2500000000000000',
      volume: '2621715.6430300000000000',
      quoteVolume: 235080123.5942224,
      btcVolume: 12570.326121700413,
      usdVolume: 235080123.5942224,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '89.2700000000000000',
      high: '90.7600000000000000',
      low: '78.0500000000000000',
      close: '81.7500000000000000',
      volume: '1623767.0887800000000000',
      quoteVolume: 140421215.4986958,
      btcVolume: 7390.470493802939,
      usdVolume: 140421215.4986958,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '81.7500000000000000',
      high: '83.2900000000000000',
      low: '64.4500000000000000',
      close: '71.0000000000000000',
      volume: '3435959.2951800000000000',
      quoteVolume: 251435782.40591905,
      btcVolume: 14535.673824882117,
      usdVolume: 251435782.40591905,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '70.9900000000000000',
      high: '73.5000000000000000',
      low: '65.3000000000000000',
      close: '69.1500000000000000',
      volume: '1707521.5040200000000000',
      quoteVolume: 118203288.9302594,
      btcVolume: 6968.137207364826,
      usdVolume: 118203288.9302594,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '69.1800000000000000',
      high: '74.9700000000000000',
      low: '68.2600000000000000',
      close: '72.7000000000000000',
      volume: '1482675.1561800000000000',
      quoteVolume: 106143884.81866534,
      btcVolume: 6107.917822619824,
      usdVolume: 106143884.81866534,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '72.6800000000000000',
      high: '81.0700000000000000',
      low: '71.5100000000000000',
      close: '78.9100000000000000',
      volume: '1514393.2172200000000000',
      quoteVolume: 115521557.95323898,
      btcVolume: 6412.697834411257,
      usdVolume: 115521557.95323898,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '78.8900000000000000',
      high: '88.4600000000000000',
      low: '76.6300000000000000',
      close: '87.6500000000000000',
      volume: '2118061.6227200000000000',
      quoteVolume: 176367822.45216078,
      btcVolume: 9216.718286347665,
      usdVolume: 176367822.45216078,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '87.6600000000000000',
      high: '93.0100000000000000',
      low: '79.5600000000000000',
      close: '85.0800000000000000',
      volume: '3495457.6565900000000000',
      quoteVolume: 307020765.6007774,
      btcVolume: 16015.001816947644,
      usdVolume: 307020765.6007774,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '85.0700000000000000',
      high: '90.6300000000000000',
      low: '82.6100000000000000',
      close: '88.9000000000000000',
      volume: '2135078.4793700000000000',
      quoteVolume: 185587960.28621328,
      btcVolume: 9814.274674754284,
      usdVolume: 185587960.28621328,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '88.8900000000000000',
      high: '91.5000000000000000',
      low: '85.4600000000000000',
      close: '89.0000000000000000',
      volume: '1690248.4588500000000000',
      quoteVolume: 149648537.47962323,
      btcVolume: 7763.714935990381,
      usdVolume: 149648537.47962323,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '89.0100000000000000',
      high: '89.8400000000000000',
      low: '77.8000000000000000',
      close: '79.5200000000000000',
      volume: '1701697.8531700000000000',
      quoteVolume: 144315548.66815314,
      btcVolume: 7578.867302398465,
      usdVolume: 144315548.66815314,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '79.5200000000000000',
      high: '84.2800000000000000',
      low: '78.6100000000000000',
      close: '83.1400000000000000',
      volume: '1275970.0537600000000000',
      quoteVolume: 104423917.99398686,
      btcVolume: 5517.332671389263,
      usdVolume: 104423917.99398686,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '83.1100000000000000',
      high: '85.1900000000000000',
      low: '80.3100000000000000',
      close: '83.5500000000000000',
      volume: '921046.7599600000000000',
      quoteVolume: 76405873.32399245,
      btcVolume: 3991.063481423035,
      usdVolume: 76405873.32399245,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '83.5400000000000000',
      high: '86.8700000000000000',
      low: '81.7300000000000000',
      close: '83.3700000000000000',
      volume: '1145446.4740700000000000',
      quoteVolume: 96166838.18654118,
      btcVolume: 5011.269364217304,
      usdVolume: 96166838.18654118,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '83.3700000000000000',
      high: '84.8300000000000000',
      low: '75.4100000000000000',
      close: '76.7600000000000000',
      volume: '1230953.8697500000000000',
      quoteVolume: 98587741.41994153,
      btcVolume: 5229.632704580527,
      usdVolume: 98587741.41994153,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '76.7500000000000000',
      high: '78.1400000000000000',
      low: '72.1300000000000000',
      close: '77.4100000000000000',
      volume: '1378297.5836100000000000',
      quoteVolume: 104644648.13944061,
      btcVolume: 5748.061365408652,
      usdVolume: 104644648.13944061,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '77.4100000000000000',
      high: '77.6600000000000000',
      low: '72.5100000000000000',
      close: '74.7000000000000000',
      volume: '897534.5890900000000000',
      quoteVolume: 67578374.0447259,
      btcVolume: 3697.8440886872677,
      usdVolume: 67578374.0447259,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '74.7700000000000000',
      high: '75.0900000000000000',
      low: '70.1200000000000000',
      close: '72.2000000000000000',
      volume: '1385260.5103000000000000',
      quoteVolume: 99443406.27715112,
      btcVolume: 5548.574129400644,
      usdVolume: 99438023.14967503,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '72.2000000000000000',
      high: '77.5800000000000000',
      low: '71.9700000000000000',
      close: '76.6700000000000000',
      volume: '778073.6457200000000000',
      quoteVolume: 58653684.98446523,
      btcVolume: 3179.4830099512424,
      usdVolume: 58653684.98446523,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '76.6400000000000000',
      high: '84.3800000000000000',
      low: '75.5200000000000000',
      close: '82.2800000000000000',
      volume: '1278882.9832800000000000',
      quoteVolume: 102688853.34340486,
      btcVolume: 5349.804122942844,
      usdVolume: 102688853.34340486,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '82.2700000000000000',
      high: '82.9800000000000000',
      low: '79.1100000000000000',
      close: '82.3500000000000000',
      volume: '1101678.0078500000000000',
      quoteVolume: 89408089.55373186,
      btcVolume: 4664.897724867536,
      usdVolume: 89408089.55373186,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '82.3800000000000000',
      high: '84.9300000000000000',
      low: '80.3900000000000000',
      close: '81.3200000000000000',
      volume: '1261474.3270300000000000',
      quoteVolume: 103669039.39603397,
      btcVolume: 5363.974475859031,
      usdVolume: 103669039.39603397,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '81.3600000000000000',
      high: '93.4400000000000000',
      low: '78.3100000000000000',
      close: '92.9400000000000000',
      volume: '2150469.3674300000000000',
      quoteVolume: 185140134.72739515,
      btcVolume: 9105.005162094782,
      usdVolume: 185140134.72739515,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '92.9400000000000000',
      high: '109.8000000000000000',
      low: '90.0000000000000000',
      close: '101.0100000000000000',
      volume: '4161454.8925300000000000',
      quoteVolume: 414585850.6995076,
      btcVolume: 18207.769039019673,
      usdVolume: 414585850.6995076,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '100.9900000000000000',
      high: '112.8800000000000000',
      low: '99.2400000000000000',
      close: '109.3400000000000000',
      volume: '3140130.3420400000000000',
      quoteVolume: 337503795.3740959,
      btcVolume: 14761.494258487553,
      usdVolume: 337503795.3740959,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '109.3400000000000000',
      high: '124.2400000000000000',
      low: '105.3100000000000000',
      close: '119.8500000000000000',
      volume: '2753360.1997100000000000',
      quoteVolume: 316850965.36465776,
      btcVolume: 13481.095408817218,
      usdVolume: 316850965.36465776,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '119.8500000000000000',
      high: '123.2100000000000000',
      low: '109.7600000000000000',
      close: '114.5800000000000000',
      volume: '2671305.3283900000000000',
      quoteVolume: 313367367.83264947,
      btcVolume: 13298.314869032618,
      usdVolume: 313367367.83264947,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '114.5900000000000000',
      high: '118.6500000000000000',
      low: '100.3100000000000000',
      close: '104.4500000000000000',
      volume: '2580555.1076800000000000',
      quoteVolume: 281116978.1689041,
      btcVolume: 12139.994599410998,
      usdVolume: 281116978.1689041,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '104.4800000000000000',
      high: '114.6300000000000000',
      low: '98.3500000000000000',
      close: '114.3200000000000000',
      volume: '3210319.3252800000000000',
      quoteVolume: 342738420.0010703,
      btcVolume: 14838.635043893324,
      usdVolume: 342738420.0010703,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '114.3100000000000000',
      high: '117.2900000000000000',
      low: '95.2300000000000000',
      close: '102.4500000000000000',
      volume: '3923591.0743400000000000',
      quoteVolume: 424020893.5197978,
      btcVolume: 18035.881882760194,
      usdVolume: 424020893.5197978,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '102.4600000000000000',
      high: '112.3200000000000000',
      low: '98.5500000000000000',
      close: '111.1300000000000000',
      volume: '2696980.5169700000000000',
      quoteVolume: 282469396.37838954,
      btcVolume: 12178.014726858833,
      usdVolume: 282469396.37838954,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '111.1300000000000000',
      high: '128.6300000000000000',
      low: '108.1800000000000000',
      close: '127.7800000000000000',
      volume: '3108695.5070400000000000',
      quoteVolume: 369487915.87514305,
      btcVolume: 15293.283381962749,
      usdVolume: 369487915.87514305,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '127.7400000000000000',
      high: '135.1900000000000000',
      low: '124.9400000000000000',
      close: '129.6100000000000000',
      volume: '3461075.6473000000000000',
      quoteVolume: 449830180.98402226,
      btcVolume: 17798.908427176073,
      usdVolume: 449830180.98402226,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '129.6100000000000000',
      high: '139.4700000000000000',
      low: '121.8800000000000000',
      close: '127.8800000000000000',
      volume: '3743173.1300100000000000',
      quoteVolume: 494840941.2477139,
      btcVolume: 18223.64596921554,
      usdVolume: 494840941.2477139,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '127.8300000000000000',
      high: '137.4100000000000000',
      low: '126.3700000000000000',
      close: '129.8300000000000000',
      volume: '2349842.9550200000000000',
      quoteVolume: 309678082.06321186,
      btcVolume: 11483.214550343224,
      usdVolume: 309678082.06321186,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '129.8400000000000000',
      high: '130.9000000000000000',
      low: '120.0000000000000000',
      close: '128.8000000000000000',
      volume: '1732248.3840900000000000',
      quoteVolume: 218608845.40846404,
      btcVolume: 8200.6564058765,
      usdVolume: 218608845.40846404,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '128.8000000000000000',
      high: '133.1100000000000000',
      low: '122.6300000000000000',
      close: '130.0500000000000000',
      volume: '1727676.6410000000000000',
      quoteVolume: 220908109.4346938,
      btcVolume: 7872.871817315565,
      usdVolume: 220908109.4346938,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '130.0600000000000000',
      high: '130.3500000000000000',
      low: '121.9800000000000000',
      close: '124.2100000000000000',
      volume: '1248723.0342400000000000',
      quoteVolume: 156991311.03482178,
      btcVolume: 5449.115523218407,
      usdVolume: 156991311.03482178,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '124.2200000000000000',
      high: '133.9700000000000000',
      low: '122.8900000000000000',
      close: '126.2400000000000000',
      volume: '1536326.1071500000000000',
      quoteVolume: 197120659.81115305,
      btcVolume: 6747.775990258352,
      usdVolume: 197120659.81115305,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '126.2000000000000000',
      high: '142.0000000000000000',
      low: '123.1500000000000000',
      close: '136.9600000000000000',
      volume: '2442266.7650000000000000',
      quoteVolume: 323461384.15540344,
      btcVolume: 10287.35677451215,
      usdVolume: 323461384.15540344,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '136.9400000000000000',
      high: '164.7000000000000000',
      low: '135.3000000000000000',
      close: '161.2400000000000000',
      volume: '3813834.0766900000000000',
      quoteVolume: 568621276.8387065,
      btcVolume: 16926.853812273916,
      usdVolume: 568621276.8387065,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '161.2300000000000000',
      high: '173.6600000000000000',
      low: '139.3100000000000000',
      close: '155.3400000000000000',
      volume: '3530422.2039200000000000',
      quoteVolume: 551040498.8754859,
      btcVolume: 17344.92344775779,
      usdVolume: 551040498.8754859,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '155.3300000000000000',
      high: '162.8000000000000000',
      low: '146.5200000000000000',
      close: '158.4300000000000000',
      volume: '1998175.7975400000000000',
      quoteVolume: 308975694.1353862,
      btcVolume: 9579.752665689979,
      usdVolume: 308975694.1353862,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '158.4600000000000000',
      high: '169.7200000000000000',
      low: '154.9500000000000000',
      close: '168.9200000000000000',
      volume: '1857437.6845700000000000',
      quoteVolume: 302142832.62634295,
      btcVolume: 8680.956043080312,
      usdVolume: 302142832.62634295,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '168.9600000000000000',
      high: '181.2200000000000000',
      low: '161.1800000000000000',
      close: '169.3500000000000000',
      volume: '2479926.5663800000000000',
      quoteVolume: 422479799.42550886,
      btcVolume: 11058.616576435745,
      usdVolume: 422479799.42550886,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '169.3500000000000000',
      high: '181.9200000000000000',
      low: '150.0000000000000000',
      close: '172.1900000000000000',
      volume: '2833341.6404500000000000',
      quoteVolume: 478225816.520269,
      btcVolume: 11964.035801892664,
      usdVolume: 478225816.520269,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '172.2800000000000000',
      high: '178.8000000000000000',
      low: '163.3000000000000000',
      close: '177.2600000000000000',
      volume: '1749781.8497300000000000',
      quoteVolume: 299170389.286915,
      btcVolume: 7425.313037764587,
      usdVolume: 299170389.286915,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '177.2700000000000000',
      high: '185.8300000000000000',
      low: '161.8600000000000000',
      close: '170.0700000000000000',
      volume: '2269089.8322300000000000',
      quoteVolume: 397596277.8818141,
      btcVolume: 10055.323763923585,
      usdVolume: 397596277.8818141,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '170.0600000000000000',
      high: '170.7400000000000000',
      low: '112.7000000000000000',
      close: '138.8700000000000000',
      volume: '4877205.3953600000000000',
      quoteVolume: 662514925.4818044,
      btcVolume: 19588.40860151175,
      usdVolume: 662514925.4818044,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '138.8800000000000000',
      high: '146.8600000000000000',
      low: '128.0000000000000000',
      close: '133.4200000000000000',
      volume: '2477243.9833200000000000',
      quoteVolume: 340391038.4888703,
      btcVolume: 9797.131310819967,
      usdVolume: 340391038.4888703,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '133.4400000000000000',
      high: '148.4600000000000000',
      low: '125.9000000000000000',
      close: '147.3800000000000000',
      volume: '2088185.1472100000000000',
      quoteVolume: 286151973.30492896,
      btcVolume: 8269.541344650439,
      usdVolume: 286151973.30492896,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '147.3900000000000000',
      high: '156.8900000000000000',
      low: '143.5500000000000000',
      close: '152.5000000000000000',
      volume: '2033762.6002700000000000',
      quoteVolume: 304472230.40606844,
      btcVolume: 7883.6115631974335,
      usdVolume: 304472230.40606844,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '152.4600000000000000',
      high: '155.7400000000000000',
      low: '130.0000000000000000',
      close: '143.6500000000000000',
      volume: '1929869.1502000000000000',
      quoteVolume: 279867480.1701219,
      btcVolume: 7532.0663673781455,
      usdVolume: 279867480.1701219,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '143.6600000000000000',
      high: '152.5900000000000000',
      low: '139.2800000000000000',
      close: '143.5200000000000000',
      volume: '1619686.2842200000000000',
      quoteVolume: 236445434.4062732,
      btcVolume: 6406.112755540417,
      usdVolume: 236445434.4062732,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '143.5200000000000000',
      high: '147.0000000000000000',
      low: '136.2200000000000000',
      close: '142.8000000000000000',
      volume: '1379082.3792000000000000',
      quoteVolume: 195777669.99104866,
      btcVolume: 5518.389442894679,
      usdVolume: 195777669.99104866,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '142.7800000000000000',
      high: '154.9900000000000000',
      low: '138.4400000000000000',
      close: '151.9600000000000000',
      volume: '1644918.1028000000000000',
      quoteVolume: 242251163.15367344,
      btcVolume: 6662.692550152778,
      usdVolume: 242251163.15367344,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '151.9700000000000000',
      high: '166.0200000000000000',
      low: '150.1800000000000000',
      close: '151.8600000000000000',
      volume: '2250266.1240300000000000',
      quoteVolume: 358045416.38105613,
      btcVolume: 9715.528233417612,
      usdVolume: 358045416.38105613,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '151.8500000000000000',
      high: '156.1500000000000000',
      low: '138.4000000000000000',
      close: '149.6900000000000000',
      volume: '2037065.0855500000000000',
      quoteVolume: 300099295.2165031,
      btcVolume: 8570.773651988773,
      usdVolume: 300099295.2165031,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '149.6700000000000000',
      high: '150.2500000000000000',
      low: '125.6400000000000000',
      close: '129.7200000000000000',
      volume: '2176119.4788900000000000',
      quoteVolume: 296428620.1166068,
      btcVolume: 9078.008984863776,
      usdVolume: 296428620.1166068,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '129.7000000000000000',
      high: '141.8500000000000000',
      low: '122.7000000000000000',
      close: '137.6600000000000000',
      volume: '1951005.0663900000000000',
      quoteVolume: 263524938.22357655,
      btcVolume: 8423.197856584778,
      usdVolume: 263524938.22357655,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '137.6500000000000000',
      high: '143.5200000000000000',
      low: '135.0500000000000000',
      close: '137.6700000000000000',
      volume: '819478.3417900000000000',
      quoteVolume: 113831601.15240765,
      btcVolume: 3514.611539877437,
      usdVolume: 113831601.15240765,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '137.6600000000000000',
      high: '142.6500000000000000',
      low: '133.7300000000000000',
      close: '141.0100000000000000',
      volume: '917459.8505800000000000',
      quoteVolume: 127591959.12300138,
      btcVolume: 3964.1660200345823,
      usdVolume: 127591959.12300138,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '141.0100000000000000',
      high: '147.8700000000000000',
      low: '136.8300000000000000',
      close: '138.5600000000000000',
      volume: '1297445.3763800000000000',
      quoteVolume: 186110798.1019838,
      btcVolume: 5571.664082584082,
      usdVolume: 186110798.1019838,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '132.7000000000000000',
      high: '136.3000000000000000',
      low: '132.5100000000000000',
      close: '134.6700000000000000',
      volume: '239830.9402400000000000',
      quoteVolume: 32177113.892162576,
      btcVolume: 999.7459445541468,
      usdVolume: 32177113.892162576,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '134.6900000000000000',
      high: '134.9600000000000000',
      low: '118.1300000000000000',
      close: '122.4400000000000000',
      volume: '1112624.2676100000000000',
      quoteVolume: 139464919.21385488,
      btcVolume: 4524.577313776934,
      usdVolume: 139464919.21385488,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '122.4500000000000000',
      high: '135.8400000000000000',
      low: '120.7400000000000000',
      close: '133.2700000000000000',
      volume: '937794.6301600000000000',
      quoteVolume: 121424463.23872843,
      btcVolume: 3823.7182988262484,
      usdVolume: 121424463.23872843,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '133.2500000000000000',
      high: '145.5000000000000000',
      low: '131.4400000000000000',
      close: '134.6500000000000000',
      volume: '1953065.9401100000000000',
      quoteVolume: 269778147.58305985,
      btcVolume: 7637.60669728038,
      usdVolume: 269778147.58305985,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '134.6700000000000000',
      high: '136.2300000000000000',
      low: '128.0200000000000000',
      close: '133.3600000000000000',
      volume: '1171505.9664700000000000',
      quoteVolume: 154495780.49306908,
      btcVolume: 4551.220051793771,
      usdVolume: 154495780.49306908,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '133.3500000000000000',
      high: '135.0600000000000000',
      low: '125.6700000000000000',
      close: '129.3600000000000000',
      volume: '806399.7757100000000000',
      quoteVolume: 104722485.35395245,
      btcVolume: 3138.156642547338,
      usdVolume: 104722485.35395245,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '129.3700000000000000',
      high: '136.6300000000000000',
      low: '125.9500000000000000',
      close: '132.0000000000000000',
      volume: '1135286.5070700000000000',
      quoteVolume: 149340674.82508767,
      btcVolume: 4416.238424240979,
      usdVolume: 149340674.82508767,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '131.9900000000000000',
      high: '145.8200000000000000',
      low: '131.8700000000000000',
      close: '141.9800000000000000',
      volume: '1883149.5372600000000000',
      quoteVolume: 265391340.62986633,
      btcVolume: 7621.335552581734,
      usdVolume: 265391340.62986633,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '141.9600000000000000',
      high: '156.7000000000000000',
      low: '141.9200000000000000',
      close: '155.9300000000000000',
      volume: '1820821.5903600000000000',
      quoteVolume: 276355678.3739926,
      btcVolume: 7583.191926757077,
      usdVolume: 276355678.3739926,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '155.9400000000000000',
      high: '158.6800000000000000',
      low: '141.0300000000000000',
      close: '145.0700000000000000',
      volume: '1762820.3654400000000000',
      quoteVolume: 263148795.56052074,
      btcVolume: 7024.899854343085,
      usdVolume: 263148795.56052074,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '145.0500000000000000',
      high: '156.7800000000000000',
      low: '143.4500000000000000',
      close: '155.0500000000000000',
      volume: '1557577.8368500000000000',
      quoteVolume: 237025412.1601315,
      btcVolume: 6297.118687933591,
      usdVolume: 237025412.1601315,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '155.0500000000000000',
      high: '164.2700000000000000',
      low: '149.8200000000000000',
      close: '155.5700000000000000',
      volume: '1878793.7270300000000000',
      quoteVolume: 298604965.4190308,
      btcVolume: 7537.248027732104,
      usdVolume: 298604965.4190308,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '155.5700000000000000',
      high: '157.2900000000000000',
      low: '145.2300000000000000',
      close: '150.7600000000000000',
      volume: '1259975.9289400000000000',
      quoteVolume: 190631760.74434358,
      btcVolume: 4938.417851396465,
      usdVolume: 190631760.74434358,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '150.7600000000000000',
      high: '169.3800000000000000',
      low: '147.7600000000000000',
      close: '167.2800000000000000',
      volume: '1869837.9437200000000000',
      quoteVolume: 297709749.822012,
      btcVolume: 7033.137189014122,
      usdVolume: 297709749.822012,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '167.2800000000000000',
      high: '184.4900000000000000',
      low: '164.2200000000000000',
      close: '181.4900000000000000',
      volume: '1981149.1236600000000000',
      quoteVolume: 339741920.4317491,
      btcVolume: 7286.474296530587,
      usdVolume: 339741920.4317491,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '181.5200000000000000',
      high: '195.1700000000000000',
      low: '169.5700000000000000',
      close: '181.5600000000000000',
      volume: '2781973.9625400000000000',
      quoteVolume: 511568659.8745071,
      btcVolume: 11171.189003450432,
      usdVolume: 511396513.1418937,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '181.5900000000000000',
      high: '193.5900000000000000',
      low: '175.7200000000000000',
      close: '183.6200000000000000',
      volume: '1662458.2148900000000000',
      quoteVolume: 308980082.707041,
      btcVolume: 6638.340260622761,
      usdVolume: 308980082.707041,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '183.6200000000000000',
      high: '199.8400000000000000',
      low: '176.7500000000000000',
      close: '196.6900000000000000',
      volume: '1944278.4281900000000000',
      quoteVolume: 363675328.6517229,
      btcVolume: 7651.174521048404,
      usdVolume: 363646656.3346562,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '196.7100000000000000',
      high: '229.0700000000000000',
      low: '191.4500000000000000',
      close: '225.7600000000000000',
      volume: '2843476.1869100000000000',
      quoteVolume: 594213638.5294985,
      btcVolume: 12603.247974606615,
      usdVolume: 594155739.24837,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '225.7700000000000000',
      high: '230.7700000000000000',
      low: '208.1100000000000000',
      close: '213.4900000000000000',
      volume: '1654862.3886300000000000',
      quoteVolume: 363531568.69063455,
      btcVolume: 7495.86825872764,
      usdVolume: 363531568.69063455,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '213.4700000000000000',
      high: '219.9900000000000000',
      low: '185.3100000000000000',
      close: '207.8300000000000000',
      volume: '2145096.2026600000000000',
      quoteVolume: 441287107.2575872,
      btcVolume: 9249.958178220597,
      usdVolume: 440751484.0592311,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '208.0300000000000000',
      high: '222.4800000000000000',
      low: '200.1000000000000000',
      close: '210.2600000000000000',
      volume: '1655771.0432500000000000',
      quoteVolume: 350218158.5830828,
      btcVolume: 7007.571698477013,
      usdVolume: 342424004.4173157,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '210.2300000000000000',
      high: '237.7500000000000000',
      low: '203.3700000000000000',
      close: '237.4900000000000000',
      volume: '1781373.1518300000000000',
      quoteVolume: 397616883.22465694,
      btcVolume: 7787.136345561708,
      usdVolume: 397616883.22465694,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '237.3900000000000000',
      high: '239.1800000000000000',
      low: '221.0000000000000000',
      close: '226.5900000000000000',
      volume: '1580820.7100000000000000',
      quoteVolume: 362511271.6078358,
      btcVolume: 6993.752084518185,
      usdVolume: 362511271.6078358,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '226.5800000000000000',
      high: '241.9200000000000000',
      low: '220.0600000000000000',
      close: '236.5000000000000000',
      volume: '1398560.5533800000000000',
      quoteVolume: 324183188.11299986,
      btcVolume: 6114.868427314256,
      usdVolume: 324183188.11299986,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '236.4500000000000000',
      high: '247.0400000000000000',
      low: '214.0200000000000000',
      close: '226.6500000000000000',
      volume: '1699874.2734800000000000',
      quoteVolume: 401212940.2932522,
      btcVolume: 7143.652988554469,
      usdVolume: 401212460.39169174,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '226.7700000000000000',
      high: '233.7800000000000000',
      low: '220.0600000000000000',
      close: '227.1300000000000000',
      volume: '1268407.0304600000000000',
      quoteVolume: 288545534.88444746,
      btcVolume: 5070.698499732074,
      usdVolume: 288545534.88444746,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '227.1700000000000000',
      high: '227.3900000000000000',
      low: '180.0000000000000000',
      close: '208.3200000000000000',
      volume: '2222077.3503300000000000',
      quoteVolume: 460086677.0755931,
      btcVolume: 8037.5535313587925,
      usdVolume: 432162455.0772618,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '208.1000000000000000',
      high: '208.2300000000000000',
      low: '158.8300000000000000',
      close: '177.3400000000000000',
      volume: '3390922.1286900000000000',
      quoteVolume: 606664750.1952728,
      btcVolume: 12391.424200432175,
      usdVolume: 602656639.5134262,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '177.3500000000000000',
      high: '189.4600000000000000',
      low: '169.0800000000000000',
      close: '181.4300000000000000',
      volume: '1385993.1585900000000000',
      quoteVolume: 251534269.16248846,
      btcVolume: 5045.167835396706,
      usdVolume: 251505675.69378206,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '181.4300000000000000',
      high: '205.2100000000000000',
      low: '175.0000000000000000',
      close: '179.3600000000000000',
      volume: '1961972.0422000000000000',
      quoteVolume: 374933085.95019436,
      btcVolume: 7503.165956785665,
      usdVolume: 374933085.95019436,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '179.3600000000000000',
      high: '182.8600000000000000',
      low: '162.7400000000000000',
      close: '170.2500000000000000',
      volume: '2179517.4955500000000000',
      quoteVolume: 376772370.9497997,
      btcVolume: 8092.3230294664,
      usdVolume: 376485294.8561675,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '170.2000000000000000',
      high: '180.4800000000000000',
      low: '167.1300000000000000',
      close: '171.7600000000000000',
      volume: '1113606.4401000000000000',
      quoteVolume: 194721552.36965466,
      btcVolume: 4134.973488951998,
      usdVolume: 194721552.36965466,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '171.7500000000000000',
      high: '173.0000000000000000',
      low: '152.8800000000000000',
      close: '165.2200000000000000',
      volume: '1630049.9177200000000000',
      quoteVolume: 262199243.42310986,
      btcVolume: 5874.032431282306,
      usdVolume: 262199243.42310986,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '165.2700000000000000',
      high: '176.4600000000000000',
      low: '163.3000000000000000',
      close: '175.8900000000000000',
      volume: '1157182.4178100000000000',
      quoteVolume: 196920601.48332697,
      btcVolume: 4133.868226475361,
      usdVolume: 196920601.48332697,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '175.9000000000000000',
      high: '185.3200000000000000',
      low: '170.6900000000000000',
      close: '179.0800000000000000',
      volume: '1441741.0023500000000000',
      quoteVolume: 255346162.55105585,
      btcVolume: 5250.61792323071,
      usdVolume: 255346162.55105585,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '179.0600000000000000',
      high: '196.4900000000000000',
      low: '176.7900000000000000',
      close: '187.6400000000000000',
      volume: '1788998.4592800000000000',
      quoteVolume: 340485879.58694166,
      btcVolume: 6762.4933075880845,
      usdVolume: 340485879.58694166,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '187.7000000000000000',
      high: '194.4000000000000000',
      low: '176.8300000000000000',
      close: '180.6700000000000000',
      volume: '1484950.5713600000000000',
      quoteVolume: 275283690.3275003,
      btcVolume: 5567.250076468444,
      usdVolume: 275230426.05999345,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '180.6600000000000000',
      high: '183.5700000000000000',
      low: '168.5000000000000000',
      close: '180.2300000000000000',
      volume: '1182501.6988200000000000',
      quoteVolume: 206736683.42664367,
      btcVolume: 4343.41978808486,
      usdVolume: 206736683.42664367,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '180.2100000000000000',
      high: '184.6700000000000000',
      low: '174.6800000000000000',
      close: '182.8500000000000000',
      volume: '890375.3310000000000000',
      quoteVolume: 160843481.4558911,
      btcVolume: 3295.7412318699794,
      usdVolume: 159221856.4260747,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '182.8600000000000000',
      high: '192.2400000000000000',
      low: '182.2500000000000000',
      close: '190.7500000000000000',
      volume: '820418.2390100000000000',
      quoteVolume: 153066685.711372,
      btcVolume: 3041.8416759099878,
      usdVolume: 153066685.711372,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '190.7500000000000000',
      high: '195.2800000000000000',
      low: '179.5800000000000000',
      close: '191.8500000000000000',
      volume: '1145514.1533900000000000',
      quoteVolume: 213310567.88827464,
      btcVolume: 4215.520210397939,
      usdVolume: 213310567.88827464,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '191.8600000000000000',
      high: '205.0000000000000000',
      low: '189.2500000000000000',
      close: '203.8100000000000000',
      volume: '1149941.2715400000000000',
      quoteVolume: 228024787.08482048,
      btcVolume: 4230.227265341441,
      usdVolume: 228024787.08482048,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '203.8000000000000000',
      high: '208.0000000000000000',
      low: '191.4700000000000000',
      close: '200.2900000000000000',
      volume: '1292995.9055100000000000',
      quoteVolume: 259453140.60054418,
      btcVolume: 4683.568407179226,
      usdVolume: 259436705.04717085,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '200.2900000000000000',
      high: '203.5400000000000000',
      low: '191.0300000000000000',
      close: '201.3600000000000000',
      volume: '980924.4222700000000000',
      quoteVolume: 194360542.3998627,
      btcVolume: 3462.342245940359,
      usdVolume: 194360542.3998627,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '201.3700000000000000',
      high: '224.2300000000000000',
      low: '198.4100000000000000',
      close: '221.4200000000000000',
      volume: '1977431.3681000000000000',
      quoteVolume: 416264469.25011075,
      btcVolume: 5921.218381160836,
      usdVolume: 336886590.78517324,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '218.6500000000000000',
      high: '230.2300000000000000',
      low: '212.7000000000000000',
      close: '226.0600000000000000',
      volume: '1310331.5687300000000000',
      quoteVolume: 292374674.933538,
      btcVolume: 4956.360993659334,
      usdVolume: 292374674.933538,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '226.0800000000000000',
      high: '228.1200000000000000',
      low: '213.8400000000000000',
      close: '213.9400000000000000',
      volume: '798435.6040500000000000',
      quoteVolume: 175877600.8876904,
      btcVolume: 2901.7605953284483,
      usdVolume: 175390710.21983862,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '214.0000000000000000',
      high: '222.5800000000000000',
      low: '197.5600000000000000',
      close: '200.5900000000000000',
      volume: '1413381.5745600000000000',
      quoteVolume: 291674131.5058429,
      btcVolume: 5106.393558040645,
      usdVolume: 291674131.5058429,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '200.5900000000000000',
      high: '205.5300000000000000',
      low: '191.1500000000000000',
      close: '201.6000000000000000',
      volume: '992587.9162400000000000',
      quoteVolume: 197656651.7759283,
      btcVolume: 3582.8634646412274,
      usdVolume: 197591598.5747801,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '201.6100000000000000',
      high: '206.9400000000000000',
      low: '194.1300000000000000',
      close: '205.9800000000000000',
      volume: '881712.4524000000000000',
      quoteVolume: 176884881.60728255,
      btcVolume: 3142.8106849439105,
      usdVolume: 176884881.60728255,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '205.9600000000000000',
      high: '208.2500000000000000',
      low: '199.1300000000000000',
      close: '199.7000000000000000',
      volume: '774116.3689700000000000',
      quoteVolume: 158124202.7894712,
      btcVolume: 2696.520577593762,
      usdVolume: 158123481.68116263,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '199.7200000000000000',
      high: '205.0000000000000000',
      low: '195.8100000000000000',
      close: '199.9600000000000000',
      volume: '515574.3893500000000000',
      quoteVolume: 103843591.3837549,
      btcVolume: 1779.0182398838715,
      usdVolume: 103678690.17642716,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '199.9600000000000000',
      high: '206.1000000000000000',
      low: '198.8200000000000000',
      close: '200.1700000000000000',
      volume: '408707.5852000000000000',
      quoteVolume: 82836648.74984606,
      btcVolume: 1409.1616201750144,
      usdVolume: 82836585.73164566,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '200.1600000000000000',
      high: '202.0000000000000000',
      low: '192.0000000000000000',
      close: '195.3100000000000000',
      volume: '579091.9878400000000000',
      quoteVolume: 113737671.3688079,
      btcVolume: 1986.3030989694942,
      usdVolume: 113644945.73073904,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '195.3300000000000000',
      high: '199.0000000000000000',
      low: '184.4200000000000000',
      close: '185.6700000000000000',
      volume: '725077.5781400000000000',
      quoteVolume: 139899897.6498326,
      btcVolume: 2476.105185480285,
      usdVolume: 139844519.7670538,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '185.6900000000000000',
      high: '189.4400000000000000',
      low: '181.1200000000000000',
      close: '185.8300000000000000',
      volume: '632291.1116900000000000',
      quoteVolume: 117638252.58571476,
      btcVolume: 1495.9951187040629,
      usdVolume: 81470228.03850105,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '185.8200000000000000',
      high: '197.1700000000000000',
      low: '172.0000000000000000',
      close: '176.0500000000000000',
      volume: '1081496.2327600000000000',
      quoteVolume: 204651010.18833292,
      btcVolume: 3732.791518881732,
      usdVolume: 204651010.18833292,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '176.0500000000000000',
      high: '179.0300000000000000',
      low: '167.8600000000000000',
      close: '171.5300000000000000',
      volume: '840201.4116500000000000',
      quoteVolume: 145853345.58006474,
      btcVolume: 2520.2457069925285,
      usdVolume: 130917909.539687,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '171.5300000000000000',
      high: '183.8200000000000000',
      low: '171.4400000000000000',
      close: '183.4500000000000000',
      volume: '602677.7101500000000000',
      quoteVolume: 107824053.70199299,
      btcVolume: 1947.79094311182,
      usdVolume: 103830246.82252973,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '183.4400000000000000',
      high: '186.1200000000000000',
      low: '177.7500000000000000',
      close: '184.0600000000000000',
      volume: '475886.8387300000000000',
      quoteVolume: 86757743.80726413,
      btcVolume: 1572.6367137829343,
      usdVolume: 86752376.32223454,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '184.0600000000000000',
      high: '187.2900000000000000',
      low: '180.0000000000000000',
      close: '184.2400000000000000',
      volume: '380545.1621100000000000',
      quoteVolume: 70082688.39183253,
      btcVolume: 1254.4888606538248,
      usdVolume: 70065167.58697064,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '184.2200000000000000',
      high: '197.6000000000000000',
      low: '182.4500000000000000',
      close: '193.7300000000000000',
      volume: '891104.9208000000000000',
      quoteVolume: 172040612.47646165,
      btcVolume: 3033.1693284032813,
      usdVolume: 171975567.3003832,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '193.7300000000000000',
      high: '200.0900000000000000',
      low: '190.3400000000000000',
      close: '195.7400000000000000',
      volume: '932290.6498000000000000',
      quoteVolume: 181705740.05520037,
      btcVolume: 3111.562505675036,
      usdVolume: 181705701.26920035,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '195.7100000000000000',
      high: '198.9900000000000000',
      low: '187.0000000000000000',
      close: '196.7000000000000000',
      volume: '698229.6378600000000000',
      quoteVolume: 135030259.1244356,
      btcVolume: 2299.6428350422234,
      usdVolume: 134758219.97780785,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '196.6900000000000000',
      high: '205.0000000000000000',
      low: '194.0000000000000000',
      close: '202.8600000000000000',
      volume: '860883.6398300000000000',
      quoteVolume: 171796660.07412636,
      btcVolume: 2915.077930636623,
      usdVolume: 171627655.0033485,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '202.8700000000000000',
      high: '213.7700000000000000',
      low: '200.7800000000000000',
      close: '211.4600000000000000',
      volume: '911504.7463400000000000',
      quoteVolume: 188097396.48105705,
      btcVolume: 3171.97268148674,
      usdVolume: 188075782.95719445,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '211.4400000000000000',
      high: '217.9700000000000000',
      low: '194.8500000000000000',
      close: '195.2300000000000000',
      volume: '955157.6424300000000000',
      quoteVolume: 199272205.1538647,
      btcVolume: 1923.0081233352637,
      usdVolume: 113285748.669347,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '195.1700000000000000',
      high: '204.9200000000000000',
      low: '192.2200000000000000',
      close: '202.2100000000000000',
      volume: '537808.5370400000000000',
      quoteVolume: 107874767.17797312,
      btcVolume: 1616.1763098667245,
      usdVolume: 93333101.29026881,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '202.2400000000000000',
      high: '225.4700000000000000',
      low: '198.0700000000000000',
      close: '221.4800000000000000',
      volume: '1320185.3316300000000000',
      quoteVolume: 279356016.0500422,
      btcVolume: 3604.645404045187,
      usdVolume: 210101068.50432605,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '221.4800000000000000',
      high: '244.5900000000000000',
      low: '212.9700000000000000',
      close: '237.0400000000000000',
      volume: '2258610.1931100000000000',
      quoteVolume: 520462770.85170555,
      btcVolume: 8308.54901413599,
      usdVolume: 484407399.03375864,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '236.9600000000000000',
      high: '242.9900000000000000',
      low: '210.7700000000000000',
      close: '219.0500000000000000',
      volume: '2252949.3544600000000000',
      quoteVolume: 507979738.3688623,
      btcVolume: 8713.75947843975,
      usdVolume: 496605124.34581065,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '219.2200000000000000',
      high: '228.2100000000000000',
      low: '216.8900000000000000',
      close: '226.1100000000000000',
      volume: '1040059.1698900000000000',
      quoteVolume: 232196028.07719645,
      btcVolume: 2623.6393819989507,
      usdVolume: 149526942.68774295,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '226.1700000000000000',
      high: '228.9900000000000000',
      low: '218.7200000000000000',
      close: '221.1200000000000000',
      volume: '629961.8332600000000000',
      quoteVolume: 141610934.81979573,
      btcVolume: 2106.0786755977633,
      usdVolume: 122650861.38468109,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '221.1300000000000000',
      high: '257.0000000000000000',
      low: '219.1600000000000000',
      close: '256.3400000000000000',
      volume: '1859281.8830400000000000',
      quoteVolume: 441762890.57776946,
      btcVolume: 7247.337651456451,
      usdVolume: 434426894.35602313,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '256.5500000000000000',
      high: '263.6200000000000000',
      low: '0.0113660000000000',
      close: '252.3100000000000000',
      volume: '1890921.5803900000000000',
      quoteVolume: 480319113.2081568,
      btcVolume: 7907.7185822236115,
      usdVolume: 472938780.5553337,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '252.3500000000000000',
      high: '259.9400000000000000',
      low: '240.0100000000000000',
      close: '244.4200000000000000',
      volume: '1328144.9269900000000000',
      quoteVolume: 331420362.6224557,
      btcVolume: 4408.987173128506,
      usdVolume: 264961310.1003388,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '244.4400000000000000',
      high: '273.5400000000000000',
      low: '244.3600000000000000',
      close: '267.5900000000000000',
      volume: '1815822.6911300000000000',
      quoteVolume: 476947654.2477242,
      btcVolume: 7578.822561176737,
      usdVolume: 472033209.7211003,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '267.6100000000000000',
      high: '283.0000000000000000',
      low: '255.0000000000000000',
      close: '278.8600000000000000',
      volume: '1934889.3058500000000000',
      quoteVolume: 524702625.81534594,
      btcVolume: 7474.81355593654,
      usdVolume: 474608362.09590185,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '278.8000000000000000',
      high: '290.9000000000000000',
      low: '266.0000000000000000',
      close: '285.6300000000000000',
      volume: '1409512.9217200000000000',
      quoteVolume: 392049527.74386656,
      btcVolume: 5819.337916249173,
      usdVolume: 366017514.8535886,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '285.6400000000000000',
      high: '317.0900000000000000',
      low: '265.5000000000000000',
      close: '308.4300000000000000',
      volume: '3150447.9992800000000000',
      quoteVolume: 924239206.0551368,
      btcVolume: 13754.14904721424,
      usdVolume: 847181090.2771821,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '308.3900000000000000',
      high: '335.6900000000000000',
      low: '296.7500000000000000',
      close: '300.8600000000000000',
      volume: '2574519.6355000000000000',
      quoteVolume: 817941072.7363646,
      btcVolume: 8650.440913958724,
      usdVolume: 529440111.53025746,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '300.8700000000000000',
      high: '304.8300000000000000',
      low: '232.0000000000000000',
      close: '273.3600000000000000',
      volume: '3046826.0682100000000000',
      quoteVolume: 814978326.9479513,
      btcVolume: 14251.274999648283,
      usdVolume: 799689184.4717911,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '273.3500000000000000',
      high: '284.3900000000000000',
      low: '250.2100000000000000',
      close: '261.3800000000000000',
      volume: '1973502.3718900000000000',
      quoteVolume: 526831697.8987827,
      btcVolume: 7545.76975452113,
      usdVolume: 424171196.8055375,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '261.4700000000000000',
      high: '272.8000000000000000',
      low: '234.3100000000000000',
      close: '260.6800000000000000',
      volume: '2100827.2657200000000000',
      quoteVolume: 536954207.2394092,
      btcVolume: 9272.855998053765,
      usdVolume: 514533908.33471185,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '260.7300000000000000',
      high: '277.0000000000000000',
      low: '250.7500000000000000',
      close: '257.6900000000000000',
      volume: '1690547.6833500000000000',
      quoteVolume: 446244126.3759943,
      btcVolume: 6848.168090990059,
      usdVolume: 380430906.4184981,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '257.6300000000000000',
      high: '289.6100000000000000',
      low: '239.0000000000000000',
      close: '252.5000000000000000',
      volume: '2441035.4976300000000000',
      quoteVolume: 653807393.5680175,
      btcVolume: 3138.9783548631967,
      usdVolume: 166658633.73239762,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '252.6000000000000000',
      high: '257.1500000000000000',
      low: '214.2900000000000000',
      close: '241.3100000000000000',
      volume: '2633187.8420800000000000',
      quoteVolume: 613120720.0451101,
      btcVolume: 6726.945644391381,
      usdVolume: 334739258.42326885,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '241.3100000000000000',
      high: '241.4100000000000000',
      low: '220.3400000000000000',
      close: '224.7500000000000000',
      volume: '1127242.4756500000000000',
      quoteVolume: 258970433.8586322,
      btcVolume: 4959.794572907675,
      usdVolume: 247114101.5572867,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '224.7600000000000000',
      high: '235.8000000000000000',
      low: '210.7300000000000000',
      close: '223.8800000000000000',
      volume: '872669.4430500000000000',
      quoteVolume: 197094883.39333206,
      btcVolume: 2116.3775832422107,
      usdVolume: 104947659.02477793,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '223.9000000000000000',
      high: '248.2000000000000000',
      low: '221.2400000000000000',
      close: '247.4200000000000000',
      volume: '1090110.9487400000000000',
      quoteVolume: 262056371.5676198,
      btcVolume: 2935.0307772177202,
      usdVolume: 155435733.32541108,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '247.4000000000000000',
      high: '263.1500000000000000',
      low: '243.6300000000000000',
      close: '259.7700000000000000',
      volume: '1113898.0502300000000000',
      quoteVolume: 283129397.5147066,
      btcVolume: 5071.1060750962415,
      usdVolume: 276778818.2280468,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '259.7700000000000000',
      high: '266.9400000000000000',
      low: '247.5800000000000000',
      close: '258.5100000000000000',
      volume: '1014038.4373400000000000',
      quoteVolume: 259424641.2548764,
      btcVolume: 4737.856416827141,
      usdVolume: 259424641.2548764,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '258.5000000000000000',
      high: '262.8900000000000000',
      low: '248.1600000000000000',
      close: '255.3100000000000000',
      volume: '856981.6494100000000000',
      quoteVolume: 218587991.57567295,
      btcVolume: 4061.048623525768,
      usdVolume: 218587991.57567295,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '255.3100000000000000',
      high: '273.5800000000000000',
      low: '252.6100000000000000',
      close: '271.1300000000000000',
      volume: '1019109.2282500000000000',
      quoteVolume: 269497860.7586776,
      btcVolume: 3226.855828115803,
      usdVolume: 177988659.73005295,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '271.1400000000000000',
      high: '278.5800000000000000',
      low: '265.9000000000000000',
      close: '276.7800000000000000',
      volume: '759685.2319600000000000',
      quoteVolume: 206313142.37866887,
      btcVolume: 1707.670992326543,
      usdVolume: 98677446.61978804,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '276.7700000000000000',
      high: '277.4700000000000000',
      low: '264.7000000000000000',
      close: '268.8800000000000000',
      volume: '656401.3022700000000000',
      quoteVolume: 177319409.03695217,
      btcVolume: 1903.0205383441184,
      usdVolume: 108037070.23640811,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '268.8300000000000000',
      high: '299.7200000000000000',
      low: '268.4400000000000000',
      close: '294.8700000000000000',
      volume: '1427253.5714300000000000',
      quoteVolume: 406345333.7440447,
      btcVolume: 6780.1405280927665,
      usdVolume: 392154426.84161633,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '294.7300000000000000',
      high: '327.6800000000000000',
      low: '270.0000000000000000',
      close: '305.2300000000000000',
      volume: '3928402.9769700000000000',
      quoteVolume: 1192658666.056111,
      btcVolume: 17129.631266402546,
      usdVolume: 949745423.8557243,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '305.2400000000000000',
      high: '360.0000000000000000',
      low: '300.5200000000000000',
      close: '356.0400000000000000',
      volume: '3390309.8850700000000000',
      quoteVolume: 1149588604.2960927,
      btcVolume: 19086.40330757933,
      usdVolume: 1061797763.2331136,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '356.0300000000000000',
      high: '364.9300000000000000',
      low: '318.0000000000000000',
      close: '354.6700000000000000',
      volume: '2657432.8323600000000000',
      quoteVolume: 908738665.4650412,
      btcVolume: 15602.374312501974,
      usdVolume: 887300260.0806935,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '354.5200000000000000',
      high: '374.5000000000000000',
      low: '333.3300000000000000',
      close: '344.8900000000000000',
      volume: '2822658.7155800000000000',
      quoteVolume: 1005641007.73384,
      btcVolume: 17158.120265906025,
      usdVolume: 969197721.0813937,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '344.8900000000000000',
      high: '356.6800000000000000',
      low: '334.0000000000000000',
      close: '347.1000000000000000',
      volume: '1326815.3766000000000000',
      quoteVolume: 458272514.1193381,
      btcVolume: 7737.5166810146,
      usdVolume: 452069650.3441574,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '347.1700000000000000',
      high: '389.0000000000000000',
      low: '333.5100000000000000',
      close: '387.8000000000000000',
      volume: '2292100.0585200000000000',
      quoteVolume: 824990298.5702738,
      btcVolume: 14024.145540287725,
      usdVolume: 811254222.256117,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '387.7700000000000000',
      high: '413.4900000000000000',
      low: '333.6800000000000000',
      close: '358.8900000000000000',
      volume: '3692639.9933200000000000',
      quoteVolume: 1422149685.4017851,
      btcVolume: 16986.416600752273,
      usdVolume: 971531402.2277263,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '358.9300000000000000',
      high: '379.7200000000000000',
      low: '345.0000000000000000',
      close: '377.6000000000000000',
      volume: '1933301.8959500000000000',
      quoteVolume: 696994582.879416,
      btcVolume: 9658.930490487104,
      usdVolume: 538638876.0129843,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '377.8000000000000000',
      high: '394.3700000000000000',
      low: '301.0000000000000000',
      close: '301.1600000000000000',
      volume: '2535240.4789300000000000',
      quoteVolume: 908891543.0191455,
      btcVolume: 16147.06658592599,
      usdVolume: 886854180.1405613,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '310.4000000000000000',
      high: '332.7300000000000000',
      low: '291.0000000000000000',
      close: '317.9500000000000000',
      volume: '2633768.7978000000000000',
      quoteVolume: 831763903.622356,
      btcVolume: 16126.135514947295,
      usdVolume: 800855571.6677618,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '317.9500000000000000',
      high: '338.8500000000000000',
      low: '307.9600000000000000',
      close: '326.2300000000000000',
      volume: '1480983.4927200000000000',
      quoteVolume: 478735235.15381885,
      btcVolume: 9290.562772,
      usdVolume: 466090574.20245117,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '326.2400000000000000',
      high: '332.0000000000000000',
      low: '296.1700000000000000',
      close: '300.3000000000000000',
      volume: '1486174.6400000000000000',
      quoteVolume: 464488652.98064655,
      btcVolume: 9354.642033526949,
      usdVolume: 455189162.857259,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '300.2200000000000000',
      high: '323.9500000000000000',
      low: '279.6600000000000000',
      close: '295.8900000000000000',
      volume: '1689775.5445200000000000',
      quoteVolume: 509125100.09983116,
      btcVolume: 10506.571843399115,
      usdVolume: 493364301.71537817,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '295.8900000000000000',
      high: '296.3400000000000000',
      low: '261.2700000000000000',
      close: '281.5300000000000000',
      volume: '2058950.0621700000000000',
      quoteVolume: 576011740.6532687,
      btcVolume: 12647.507440176665,
      usdVolume: 556665988.3596952,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '281.3800000000000000',
      high: '317.9700000000000000',
      low: '278.3200000000000000',
      close: '293.9800000000000000',
      volume: '2558745.8496900000000000',
      quoteVolume: 770908333.9244474,
      btcVolume: 17076.297568639577,
      usdVolume: 752161422.2082568,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '293.9800000000000000',
      high: '300.3100000000000000',
      low: '147.0000000000000000',
      close: '185.9300000000000000',
      volume: '4429987.0992500000000000',
      quoteVolume: 1024051125.9764041,
      btcVolume: 21425.247480595644,
      usdVolume: 837275028.9459155,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '186.3100000000000000',
      high: '225.0000000000000000',
      low: '166.4500000000000000',
      close: '210.7400000000000000',
      volume: '4267102.3515400000000000',
      quoteVolume: 857136804.6603408,
      btcVolume: 17609.38450938026,
      usdVolume: 690358545.9245719,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '210.6600000000000000',
      high: '219.1200000000000000',
      low: '156.5600000000000000',
      close: '179.9700000000000000',
      volume: '2499829.1189300000000000',
      quoteVolume: 477038651.68571556,
      btcVolume: 9570.554815255731,
      usdVolume: 369647877.63828075,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '179.9700000000000000',
      high: '184.4400000000000000',
      low: '161.6100000000000000',
      close: '169.3200000000000000',
      volume: '1975608.6877700000000000',
      quoteVolume: 344670437.0064659,
      btcVolume: 6063.268649807608,
      usdVolume: 224771211.51457024,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '169.3000000000000000',
      high: '176.2600000000000000',
      low: '118.0300000000000000',
      close: '142.3900000000000000',
      volume: '3854006.1240800000000000',
      quoteVolume: 548343321.08721,
      btcVolume: 15597.115173980857,
      usdVolume: 534078487.19652426,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '142.5200000000000000',
      high: '185.3700000000000000',
      low: '139.5400000000000000',
      close: '185.3200000000000000',
      volume: '2471798.9177700000000000',
      quoteVolume: 401352119.8284848,
      btcVolume: 10534.620490455787,
      usdVolume: 390721604.9641617,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '185.3200000000000000',
      high: '194.6100000000000000',
      low: '167.1500000000000000',
      close: '183.7600000000000000',
      volume: '1974384.4346300000000000',
      quoteVolume: 352461818.3773789,
      btcVolume: 9048.857573695626,
      usdVolume: 343883497.5524442,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '183.8900000000000000',
      high: '204.1300000000000000',
      low: '179.4200000000000000',
      close: '198.9100000000000000',
      volume: '1522242.9800100000000000',
      quoteVolume: 295744722.487022,
      btcVolume: 7397.801239257871,
      usdVolume: 290508656.1029971,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '198.9200000000000000',
      high: '209.0000000000000000',
      low: '180.3900000000000000',
      close: '194.3200000000000000',
      volume: '1457775.3844800000000000',
      quoteVolume: 283796099.3329705,
      btcVolume: 7184.054871626712,
      usdVolume: 278865293.7616737,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '194.3100000000000000',
      high: '198.8800000000000000',
      low: '165.5000000000000000',
      close: '177.3300000000000000',
      volume: '2118247.1385100000000000',
      quoteVolume: 378817824.8082162,
      btcVolume: 10181.549955274471,
      usdVolume: 371492579.2034223,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '177.3000000000000000',
      high: '188.6900000000000000',
      low: '157.6500000000000000',
      close: '164.0100000000000000',
      volume: '1553245.0097100000000000',
      quoteVolume: 267294263.0746701,
      btcVolume: 7489.570968288885,
      usdVolume: 263585916.44740584,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '164.0400000000000000',
      high: '181.1000000000000000',
      low: '155.1100000000000000',
      close: '170.5100000000000000',
      volume: '1118503.2655100000000000',
      quoteVolume: 190582769.66552165,
      btcVolume: 5376.479791676544,
      usdVolume: 189717014.9300997,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '170.4800000000000000',
      high: '188.5000000000000000',
      low: '163.5000000000000000',
      close: '187.9700000000000000',
      volume: '1239310.5303800000000000',
      quoteVolume: 219656209.67978868,
      btcVolume: 6050.641649360358,
      usdVolume: 218836203.75448558,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '188.0100000000000000',
      high: '193.1600000000000000',
      low: '175.8200000000000000',
      close: '182.9900000000000000',
      volume: '1017682.1460000000000000',
      quoteVolume: 185830461.32079786,
      btcVolume: 4992.905208779654,
      usdVolume: 183136314.50446558,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '183.0500000000000000',
      high: '191.3400000000000000',
      low: '178.6300000000000000',
      close: '187.1400000000000000',
      volume: '919350.2437500000000000',
      quoteVolume: 170450027.89501953,
      btcVolume: 4545.1303175238545,
      usdVolume: 169822883.12995762,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '187.1800000000000000',
      high: '197.7800000000000000',
      low: '181.9500000000000000',
      close: '193.5000000000000000',
      volume: '1068163.8122400000000000',
      quoteVolume: 203871823.75115305,
      btcVolume: 5274.935447853314,
      usdVolume: 203567782.85967907,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '193.5200000000000000',
      high: '193.8100000000000000',
      low: '168.9500000000000000',
      close: '178.4400000000000000',
      volume: '1083395.0124300000000000',
      quoteVolume: 192472231.32231694,
      btcVolume: 5072.536802753547,
      usdVolume: 187806213.84734955,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '178.4600000000000000',
      high: '185.4900000000000000',
      low: '167.3200000000000000',
      close: '172.9400000000000000',
      volume: '845338.3479100000000000',
      quoteVolume: 148913591.34313303,
      btcVolume: 4047.721342287558,
      usdVolume: 147372091.39759547,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '172.9500000000000000',
      high: '179.9600000000000000',
      low: '172.0200000000000000',
      close: '176.4100000000000000',
      volume: '676410.3521800000000000',
      quoteVolume: 119363989.89739811,
      btcVolume: 3313.0261015985343,
      usdVolume: 119122275.72225323,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '176.4200000000000000',
      high: '182.2200000000000000',
      low: '160.2500000000000000',
      close: '161.9500000000000000',
      volume: '1035822.8250900000000000',
      quoteVolume: 180434186.55291733,
      btcVolume: 5056.415096494469,
      usdVolume: 179870212.29867515,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '161.9200000000000000',
      high: '164.4800000000000000',
      low: '144.8900000000000000',
      close: '160.6300000000000000',
      volume: '1417289.8764700000000000',
      quoteVolume: 222062589.14860615,
      btcVolume: 6696.45943399783,
      usdVolume: 219130900.61702827,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '160.6400000000000000',
      high: '173.3000000000000000',
      low: '152.3100000000000000',
      close: '172.5600000000000000',
      volume: '1139739.3605400000000000',
      quoteVolume: 186552484.183113,
      btcVolume: 5247.567629145548,
      usdVolume: 184026648.27611592,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '172.5400000000000000',
      high: '174.6100000000000000',
      low: '162.1200000000000000',
      close: '168.1600000000000000',
      volume: '877869.9250200000000000',
      quoteVolume: 147862248.68551347,
      btcVolume: 3977.149088401281,
      usdVolume: 147278462.99204102,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '168.1800000000000000',
      high: '173.7800000000000000',
      low: '160.5500000000000000',
      close: '162.8300000000000000',
      volume: '928611.5444600000000000',
      quoteVolume: 156280950.68599966,
      btcVolume: 4223.884717970092,
      usdVolume: 156041583.2841295,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '162.8400000000000000',
      high: '164.3000000000000000',
      low: '154.1400000000000000',
      close: '161.8000000000000000',
      volume: '653092.9881500000000000',
      quoteVolume: 103889826.80616859,
      btcVolume: 2894.8574142745842,
      usdVolume: 103359782.14313756,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '161.8100000000000000',
      high: '171.5900000000000000',
      low: '156.4200000000000000',
      close: '170.8500000000000000',
      volume: '573493.9078300000000000',
      quoteVolume: 93618007.16348189,
      btcVolume: 2549.4662560893084,
      usdVolume: 93402814.7181441,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '170.8500000000000000',
      high: '179.9900000000000000',
      low: '166.3400000000000000',
      close: '179.8900000000000000',
      volume: '802051.9564500000000000',
      quoteVolume: 138132150.6621468,
      btcVolume: 3462.3042173691624,
      usdVolume: 137992367.33837593,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '179.8800000000000000',
      high: '181.2000000000000000',
      low: '172.1500000000000000',
      close: '175.1500000000000000',
      volume: '654352.0530400000000000',
      quoteVolume: 115569055.5994115,
      btcVolume: 2868.206424184333,
      usdVolume: 115393253.99403785,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '175.1600000000000000',
      high: '177.3100000000000000',
      low: '164.2300000000000000',
      close: '166.5700000000000000',
      volume: '761479.4526300000000000',
      quoteVolume: 129526004.72512966,
      btcVolume: 3269.23856819484,
      usdVolume: 128729009.70918988,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '166.5600000000000000',
      high: '173.4000000000000000',
      low: '162.8100000000000000',
      close: '167.0800000000000000',
      volume: '559434.6236700000000000',
      quoteVolume: 94143650.72569731,
      btcVolume: 2440.0515935312997,
      usdVolume: 93977575.46471305,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '167.1300000000000000',
      high: '167.4300000000000000',
      low: '151.2000000000000000',
      close: '156.3400000000000000',
      volume: '800699.3038400000000000',
      quoteVolume: 127526013.18198103,
      btcVolume: 3451.3788942966594,
      usdVolume: 126795373.6731447,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '156.3300000000000000',
      high: '160.8800000000000000',
      low: '152.3100000000000000',
      close: '152.4900000000000000',
      volume: '522793.6393800000000000',
      quoteVolume: 82002075.66598175,
      btcVolume: 2293.7654961253556,
      usdVolume: 81905459.6444485,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '152.5100000000000000',
      high: '156.1800000000000000',
      low: '143.7500000000000000',
      close: '154.8800000000000000',
      volume: '611126.9176600000000000',
      quoteVolume: 91873187.05818173,
      btcVolume: 2616.173092821781,
      usdVolume: 91098177.96242698,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '154.8700000000000000',
      high: '155.5000000000000000',
      low: '123.9100000000000000',
      close: '124.4600000000000000',
      volume: '1491214.5233800000000000',
      quoteVolume: 203016501.85429946,
      btcVolume: 6075.534275582749,
      usdVolume: 199064002.10991165,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '124.3800000000000000',
      high: '133.0300000000000000',
      low: '104.9200000000000000',
      close: '119.6400000000000000',
      volume: '2255194.7519200000000000',
      quoteVolume: 266658628.66409847,
      btcVolume: 8351.340529707115,
      usdVolume: 262285946.07146153,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '119.6900000000000000',
      high: '133.7500000000000000',
      low: '115.4900000000000000',
      close: '129.1000000000000000',
      volume: '1018662.2307000000000000',
      quoteVolume: 129893644.46478507,
      btcVolume: 3806.186447654474,
      usdVolume: 128367468.01437268,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '129.1300000000000000',
      high: '137.3600000000000000',
      low: '123.7100000000000000',
      close: '134.5800000000000000',
      volume: '650968.5008600000000000',
      quoteVolume: 86002587.93949006,
      btcVolume: 2540.35834770411,
      usdVolume: 85601845.83265461,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '134.5900000000000000',
      high: '138.4500000000000000',
      low: '124.3100000000000000',
      close: '125.0000000000000000',
      volume: '764515.3389200000000000',
      quoteVolume: 99682735.3520955,
      btcVolume: 2989.7157760446407,
      usdVolume: 99437255.44342713,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '125.0300000000000000',
      high: '129.4400000000000000',
      low: '118.6700000000000000',
      close: '126.8900000000000000',
      volume: '868428.5919600000000000',
      quoteVolume: 107074782.7018947,
      btcVolume: 3405.7791541849497,
      usdVolume: 106608690.08379035,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '126.8100000000000000',
      high: '132.6700000000000000',
      low: '124.6600000000000000',
      close: '132.6000000000000000',
      volume: '573388.2468800000000000',
      quoteVolume: 73420914.55001809,
      btcVolume: 2206.7295809874763,
      usdVolume: 73052077.51809245,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '132.5700000000000000',
      high: '139.5500000000000000',
      low: '129.8900000000000000',
      close: '137.6900000000000000',
      volume: '657962.2346700000000000',
      quoteVolume: 88324620.00472222,
      btcVolume: 2556.119863531481,
      usdVolume: 88194403.87278609,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '137.6800000000000000',
      high: '149.1800000000000000',
      low: '137.2400000000000000',
      close: '144.2000000000000000',
      volume: '840281.7752200000000000',
      quoteVolume: 120878119.45537868,
      btcVolume: 3398.725464122438,
      usdVolume: 120738719.24876784,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '144.2300000000000000',
      high: '146.9400000000000000',
      low: '136.0100000000000000',
      close: '144.2600000000000000',
      volume: '601927.1802600000000000',
      quoteVolume: 84998636.27030478,
      btcVolume: 2433.7350642691163,
      usdVolume: 84897826.1464357,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '144.3100000000000000',
      high: '144.3100000000000000',
      low: '133.9900000000000000',
      close: '137.0200000000000000',
      volume: '539535.2997000000000000',
      quoteVolume: 74099823.13621905,
      btcVolume: 2199.154275297908,
      usdVolume: 73990525.94229446,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '137.0100000000000000',
      high: '138.9700000000000000',
      low: '130.6000000000000000',
      close: '136.8500000000000000',
      volume: '464289.9855400000000000',
      quoteVolume: 62070122.90920927,
      btcVolume: 1866.2283049451146,
      usdVolume: 62049635.439033374,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '136.8700000000000000',
      high: '141.3700000000000000',
      low: '134.5700000000000000',
      close: '140.1700000000000000',
      volume: '342781.9026900000000000',
      quoteVolume: 47569780.320621595,
      btcVolume: 1377.1630726778033,
      usdVolume: 47351874.70020899,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '140.1900000000000000',
      high: '148.1000000000000000',
      low: '136.8800000000000000',
      close: '144.9500000000000000',
      volume: '435307.8254500000000000',
      quoteVolume: 62602668.35071889,
      btcVolume: 1770.4592503135034,
      usdVolume: 62526340.18334017,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '144.9600000000000000',
      high: '145.0300000000000000',
      low: '133.8400000000000000',
      close: '137.7100000000000000',
      volume: '561316.3421300000000000',
      quoteVolume: 78108514.57286538,
      btcVolume: 2273.043772946485,
      usdVolume: 77425208.18744867,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '137.7600000000000000',
      high: '142.7300000000000000',
      low: '135.6800000000000000',
      close: '138.8700000000000000',
      volume: '513385.4598500000000000',
      quoteVolume: 71206203.53100015,
      btcVolume: 2058.938771197183,
      usdVolume: 70372583.83128636,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '138.8600000000000000',
      high: '142.9300000000000000',
      low: '136.8700000000000000',
      close: '137.4100000000000000',
      volume: '515462.6606800000000000',
      quoteVolume: 72372106.19902879,
      btcVolume: 2095.11390021604,
      usdVolume: 72372106.19902879,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '137.3800000000000000',
      high: '137.7400000000000000',
      low: '127.7700000000000000',
      close: '132.1500000000000000',
      volume: '645442.5711200000000000',
      quoteVolume: 85207418.88870086,
      btcVolume: 2580.982544909361,
      usdVolume: 84861412.34308095,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '132.1100000000000000',
      high: '135.8500000000000000',
      low: '126.0000000000000000',
      close: '134.5800000000000000',
      volume: '527547.8216300000000000',
      quoteVolume: 69418988.6298179,
      btcVolume: 2095.969894767974,
      usdVolume: 69393787.63974923,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '134.5400000000000000',
      high: '136.8200000000000000',
      low: '130.3200000000000000',
      close: '133.9900000000000000',
      volume: '365844.8683200000000000',
      quoteVolume: 48672574.565501355,
      btcVolume: 1443.797183822238,
      usdVolume: 48670934.54957684,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '133.9900000000000000',
      high: '136.4800000000000000',
      low: '132.0800000000000000',
      close: '134.2800000000000000',
      volume: '324569.4102200000000000',
      quoteVolume: 43649181.09015877,
      btcVolume: 1290.2481974206867,
      usdVolume: 43648244.99860397,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '134.2800000000000000',
      high: '139.7500000000000000',
      low: '131.8200000000000000',
      close: '133.3800000000000000',
      volume: '670917.6235500000000000',
      quoteVolume: 91393565.62359004,
      btcVolume: 2712.82869674453,
      usdVolume: 91352372.01710989,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '133.3200000000000000',
      high: '134.6100000000000000',
      low: '129.3500000000000000',
      close: '131.4400000000000000',
      volume: '446421.4796000000000000',
      quoteVolume: 58969790.60242994,
      btcVolume: 1796.7553077819084,
      usdVolume: 58951683.42339532,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '131.4500000000000000',
      high: '132.4200000000000000',
      low: '123.5900000000000000',
      close: '131.0700000000000000',
      volume: '538549.4072300000000000',
      quoteVolume: 69104634.35945345,
      btcVolume: 2129.938412921626,
      usdVolume: 69047331.59467553,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '131.0600000000000000',
      high: '133.1300000000000000',
      low: '121.9300000000000000',
      close: '125.8600000000000000',
      volume: '494276.3957800000000000',
      quoteVolume: 62599065.68099787,
      btcVolume: 1949.375687069558,
      usdVolume: 62495906.468084864,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '125.8800000000000000',
      high: '127.8700000000000000',
      low: '120.3800000000000000',
      close: '120.6500000000000000',
      volume: '396793.6475500000000000',
      quoteVolume: 49187467.51789337,
      btcVolume: 1551.73880256109,
      usdVolume: 49187456.52809857,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '120.6600000000000000',
      high: '122.9200000000000000',
      low: '117.6800000000000000',
      close: '119.9500000000000000',
      volume: '412797.9346200000000000',
      quoteVolume: 49618380.247838296,
      btcVolume: 1572.2543394713864,
      usdVolume: 49564013.24779822,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '120.0000000000000000',
      high: '124.0500000000000000',
      low: '116.2300000000000000',
      close: '119.1700000000000000',
      volume: '430985.2145700000000000',
      quoteVolume: 51789227.20684465,
      btcVolume: 1633.9578324717402,
      usdVolume: 51787309.337946646,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '119.1600000000000000',
      high: '120.7500000000000000',
      low: '111.8200000000000000',
      close: '113.1300000000000000',
      volume: '493082.9626600000000000',
      quoteVolume: 56960133.80445162,
      btcVolume: 1829.8567510212017,
      usdVolume: 56942282.4436929,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '113.1400000000000000',
      high: '115.0900000000000000',
      low: '103.8300000000000000',
      close: '107.2900000000000000',
      volume: '765414.9566800000000000',
      quoteVolume: 82409699.71007985,
      btcVolume: 2758.298975959001,
      usdVolume: 82377481.65606456,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '107.3100000000000000',
      high: '119.2400000000000000',
      low: '105.0500000000000000',
      close: '117.7400000000000000',
      volume: '682039.4728300000000000',
      quoteVolume: 77598308.8381928,
      btcVolume: 2473.82772359749,
      usdVolume: 77368480.01030874,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '117.7700000000000000',
      high: '122.0000000000000000',
      low: '115.6100000000000000',
      close: '120.6700000000000000',
      volume: '464759.4714600000000000',
      quoteVolume: 55142102.74740585,
      btcVolume: 1710.182490463156,
      usdVolume: 54964505.0582159,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '120.6900000000000000',
      high: '122.1300000000000000',
      low: '117.0200000000000000',
      close: '119.4200000000000000',
      volume: '318529.7456500000000000',
      quoteVolume: 38201094.05437847,
      btcVolume: 1177.7807010659724,
      usdVolume: 38201094.05437847,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
  ceth: {
    id: 'ceth',
    name: 'CETH',
    symbol: 'CETH',
    image: 'assets/images/coin-logo/CETH.png',
    activity24h: 421323456,
    availableAmount: 208.232323,
    usdxConversion: 2,
    allTimeValue: 55943812390,
    allTimeAsset: 542321,
    candleData: [
      {
      open: '0.5355000000000000',
      high: '0.5355000000000000',
      low: '0.4668000000000000',
      close: '0.4895000000000000',
      volume: '1343660.3100000000000000',
      quoteVolume: 665013.644449,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '0.4895000000000000',
      high: '0.4953000000000000',
      low: '0.4639000000000000',
      close: '0.4756000000000000',
      volume: '1411725.3500000000000000',
      quoteVolume: 673447.208597,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '0.4762000000000000',
      high: '0.5112000000000000',
      low: '0.4601000000000000',
      close: '0.4894000000000000',
      volume: '984417.5900000000000000',
      quoteVolume: 474999.286242,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '0.4908000000000000',
      high: '0.5000000000000000',
      low: '0.4673000000000000',
      close: '0.4831000000000000',
      volume: '735443.2000000000000000',
      quoteVolume: 354090.041046,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '0.4803000000000000',
      high: '0.4920000000000000',
      low: '0.4590000000000000',
      close: '0.4834000000000000',
      volume: '677499.5600000000000000',
      quoteVolume: 323333.553838,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '0.4839000000000000',
      high: '0.5198000000000000',
      low: '0.4650000000000000',
      close: '0.4972000000000000',
      volume: '897787.8400000000000000',
      quoteVolume: 446086.78762,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '0.4960000000000000',
      high: '0.5656000000000000',
      low: '0.4956000000000000',
      close: '0.5344000000000000',
      volume: '1784953.5000000000000000',
      quoteVolume: 955513.881021,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '0.5342000000000000',
      high: '0.5475000000000000',
      low: '0.4901000000000000',
      close: '0.5046000000000000',
      volume: '1827069.3900000000000000',
      quoteVolume: 940352.297759,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '0.5046000000000000',
      high: '0.5065000000000000',
      low: '0.4518000000000000',
      close: '0.5017000000000000',
      volume: '1529219.5300000000000000',
      quoteVolume: 745639.12774,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '0.5031000000000000',
      high: '0.5157000000000000',
      low: '0.4712000000000000',
      close: '0.4739000000000000',
      volume: '1112869.0800000000000000',
      quoteVolume: 544844.844073,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '0.4736000000000000',
      high: '0.4884000000000000',
      low: '0.4624000000000000',
      close: '0.4661000000000000',
      volume: '865653.8900000000000000',
      quoteVolume: 410185.821637,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '0.4661000000000000',
      high: '0.4666000000000000',
      low: '0.4229000000000000',
      close: '0.4303000000000000',
      volume: '2278919.6500000000000000',
      quoteVolume: 1004304.902092,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '0.4310000000000000',
      high: '0.4313000000000000',
      low: '0.3504000000000000',
      close: '0.4116000000000000',
      volume: '4006211.8500000000000000',
      quoteVolume: 1540777.018846,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '0.4104000000000000',
      high: '0.4638000000000000',
      low: '0.4061000000000000',
      close: '0.4505000000000000',
      volume: '5190825.3700000000000000',
      quoteVolume: 2252399.736119,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '0.4505000000000000',
      high: '0.4514000000000000',
      low: '0.4130000000000000',
      close: '0.4249000000000000',
      volume: '2092369.5900000000000000',
      quoteVolume: 897815.126911,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '0.4250000000000000',
      high: '0.4410000000000000',
      low: '0.3811000000000000',
      close: '0.3832000000000000',
      volume: '2029033.6100000000000000',
      quoteVolume: 826552.030246,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '0.3850000000000000',
      high: '0.4300000000000000',
      low: '0.3800000000000000',
      close: '0.4147000000000000',
      volume: '1899232.5600000000000000',
      quoteVolume: 768342.467271,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '0.4163000000000000',
      high: '0.4330000000000000',
      low: '0.3985000000000000',
      close: '0.4136000000000000',
      volume: '821033.4800000000000000',
      quoteVolume: 340840.331218,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '0.4170000000000000',
      high: '0.4170000000000000',
      low: '0.3860000000000000',
      close: '0.3963000000000000',
      volume: '1065170.5600000000000000',
      quoteVolume: 424303.971749,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '0.3959000000000000',
      high: '0.4125000000000000',
      low: '0.3867000000000000',
      close: '0.3943000000000000',
      volume: '1449384.9600000000000000',
      quoteVolume: 579009.583139,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '0.3926000000000000',
      high: '0.4327000000000000',
      low: '0.3902000000000000',
      close: '0.4224000000000000',
      volume: '1299107.0300000000000000',
      quoteVolume: 532341.103934,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '0.4224000000000000',
      high: '0.4236000000000000',
      low: '0.3915000000000000',
      close: '0.4000000000000000',
      volume: '1368546.2100000000000000',
      quoteVolume: 552819.621243,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '0.4000000000000000',
      high: '0.4065000000000000',
      low: '0.3913000000000000',
      close: '0.4021000000000000',
      volume: '498842.3600000000000000',
      quoteVolume: 199193.78253,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '0.4021000000000000',
      high: '0.4500000000000000',
      low: '0.3985000000000000',
      close: '0.4383000000000000',
      volume: '2015969.5800000000000000',
      quoteVolume: 859693.298603,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '0.4371000000000000',
      high: '0.4844000000000000',
      low: '0.4310000000000000',
      close: '0.4612000000000000',
      volume: '1384815.9500000000000000',
      quoteVolume: 637171.045365,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '0.4648000000000000',
      high: '0.4800000000000000',
      low: '0.4325000000000000',
      close: '0.4499000000000000',
      volume: '2000850.5500000000000000',
      quoteVolume: 913391.61962,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '0.4495000000000000',
      high: '0.4515000000000000',
      low: '0.4200000000000000',
      close: '0.4289000000000000',
      volume: '1580197.3900000000000000',
      quoteVolume: 685555.872631,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '0.4277000000000000',
      high: '0.4374000000000000',
      low: '0.4176000000000000',
      close: '0.4277000000000000',
      volume: '941303.5800000000000000',
      quoteVolume: 400668.760404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '0.4277000000000000',
      high: '0.4454000000000000',
      low: '0.4183000000000000',
      close: '0.4411000000000000',
      volume: '935377.3800000000000000',
      quoteVolume: 400576.99087,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '0.4422000000000000',
      high: '0.4442000000000000',
      low: '0.4203000000000000',
      close: '0.4259000000000000',
      volume: '942053.4400000000000000',
      quoteVolume: 407685.941353,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '0.4235000000000000',
      high: '0.4500000000000000',
      low: '0.4222000000000000',
      close: '0.4342000000000000',
      volume: '612251.8000000000000000',
      quoteVolume: 264659.849465,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '0.4366000000000000',
      high: '0.4464000000000000',
      low: '0.4251000000000000',
      close: '0.4398000000000000',
      volume: '465491.5800000000000000',
      quoteVolume: 203941.272106,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '0.4386000000000000',
      high: '0.4797000000000000',
      low: '0.4317000000000000',
      close: '0.4604000000000000',
      volume: '1616509.7200000000000000',
      quoteVolume: 740396.471002,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '0.4615000000000000',
      high: '0.4823000000000000',
      low: '0.4534000000000000',
      close: '0.4710000000000000',
      volume: '1430254.7800000000000000',
      quoteVolume: 671573.658591,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '0.4710000000000000',
      high: '0.4789000000000000',
      low: '0.4520000000000000',
      close: '0.4598000000000000',
      volume: '1548002.4400000000000000',
      quoteVolume: 724054.883013,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '0.4548000000000000',
      high: '0.4748000000000000',
      low: '0.4432000000000000',
      close: '0.4618000000000000',
      volume: '1358786.2800000000000000',
      quoteVolume: 625130.767047,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '0.4617000000000000',
      high: '0.4750000000000000',
      low: '0.4380000000000000',
      close: '0.4438000000000000',
      volume: '1443214.9800000000000000',
      quoteVolume: 655875.790716,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '0.4438000000000000',
      high: '0.4650000000000000',
      low: '0.4400000000000000',
      close: '0.4479000000000000',
      volume: '700523.7700000000000000',
      quoteVolume: 313850.863647,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '0.4480000000000000',
      high: '0.4640000000000000',
      low: '0.4437000000000000',
      close: '0.4585000000000000',
      volume: '925529.6400000000000000',
      quoteVolume: 419693.727298,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '0.4592000000000000',
      high: '0.4644000000000000',
      low: '0.3975000000000000',
      close: '0.4050000000000000',
      volume: '2154193.2500000000000000',
      quoteVolume: 917345.275562,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '0.4047000000000000',
      high: '0.4749000000000000',
      low: '0.4007000000000000',
      close: '0.4723000000000000',
      volume: '1785356.2100000000000000',
      quoteVolume: 783690.750459,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '0.4722000000000000',
      high: '0.4740000000000000',
      low: '0.4300000000000000',
      close: '0.4341000000000000',
      volume: '1775526.6700000000000000',
      quoteVolume: 796546.586808,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '0.4335000000000000',
      high: '0.4412000000000000',
      low: '0.4145000000000000',
      close: '0.4274000000000000',
      volume: '854212.4500000000000000',
      quoteVolume: 365787.418355,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '0.4274000000000000',
      high: '0.4500000000000000',
      low: '0.4166000000000000',
      close: '0.4244000000000000',
      volume: '1059968.1300000000000000',
      quoteVolume: 455311.559954,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '0.4236000000000000',
      high: '0.4386000000000000',
      low: '0.4203000000000000',
      close: '0.4285000000000000',
      volume: '560690.8100000000000000',
      quoteVolume: 239706.048076,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '0.4286000000000000',
      high: '0.4372000000000000',
      low: '0.4173000000000000',
      close: '0.4221000000000000',
      volume: '331570.0000000000000000',
      quoteVolume: 140599.974044,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '0.4232000000000000',
      high: '0.4292000000000000',
      low: '0.4177000000000000',
      close: '0.4205000000000000',
      volume: '225429.9500000000000000',
      quoteVolume: 95660.216465,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '0.4203000000000000',
      high: '0.4255000000000000',
      low: '0.3929000000000000',
      close: '0.4036000000000000',
      volume: '946382.1900000000000000',
      quoteVolume: 387502.948292,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '0.4029000000000000',
      high: '0.4343000000000000',
      low: '0.4023000000000000',
      close: '0.4291000000000000',
      volume: '1686179.9000000000000000',
      quoteVolume: 703630.897735,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '0.4291000000000000',
      high: '0.4385000000000000',
      low: '0.4186000000000000',
      close: '0.4296000000000000',
      volume: '716328.1500000000000000',
      quoteVolume: 305884.809425,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '0.4296000000000000',
      high: '0.4766000000000000',
      low: '0.4256000000000000',
      close: '0.4521000000000000',
      volume: '1498764.0300000000000000',
      quoteVolume: 677393.543142,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '0.4521000000000000',
      high: '0.5107000000000000',
      low: '0.4519000000000000',
      close: '0.4611000000000000',
      volume: '3248280.8300000000000000',
      quoteVolume: 1570069.711841,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '0.4611000000000000',
      high: '0.4824000000000000',
      low: '0.4524000000000000',
      close: '0.4746000000000000',
      volume: '1381686.4300000000000000',
      quoteVolume: 643096.002798,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '0.4742000000000000',
      high: '0.5460000000000000',
      low: '0.4681000000000000',
      close: '0.4981000000000000',
      volume: '2887991.8600000000000000',
      quoteVolume: 1456511.395647,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '0.4982000000000000',
      high: '0.5181000000000000',
      low: '0.4382000000000000',
      close: '0.4629000000000000',
      volume: '2633054.2100000000000000',
      quoteVolume: 1262800.852306,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '0.4641000000000000',
      high: '0.5079000000000000',
      low: '0.4503000000000000',
      close: '0.4876000000000000',
      volume: '2105182.6700000000000000',
      quoteVolume: 1016416.428628,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '0.4872000000000000',
      high: '0.4880000000000000',
      low: '0.4671000000000000',
      close: '0.4805000000000000',
      volume: '1263356.6800000000000000',
      quoteVolume: 602730.177399,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '0.4814000000000000',
      high: '0.4994000000000000',
      low: '0.4722000000000000',
      close: '0.4871000000000000',
      volume: '1720792.4000000000000000',
      quoteVolume: 835254.863559,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '0.4858000000000000',
      high: '0.4960000000000000',
      low: '0.4831000000000000',
      close: '0.4858000000000000',
      volume: '577893.0600000000000000',
      quoteVolume: 281897.916414,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '0.4866000000000000',
      high: '0.4960000000000000',
      low: '0.4681000000000000',
      close: '0.4819000000000000',
      volume: '1768514.5100000000000000',
      quoteVolume: 850597.475821,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '0.4805000000000000',
      high: '0.4887000000000000',
      low: '0.4702000000000000',
      close: '0.4785000000000000',
      volume: '1352126.7300000000000000',
      quoteVolume: 645227.394195,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '0.4789000000000000',
      high: '0.4833000000000000',
      low: '0.4670000000000000',
      close: '0.4757000000000000',
      volume: '891826.0900000000000000',
      quoteVolume: 422057.338849,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '0.4760000000000000',
      high: '0.4800000000000000',
      low: '0.4648000000000000',
      close: '0.4770000000000000',
      volume: '530734.5900000000000000',
      quoteVolume: 250536.102196,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '0.4796000000000000',
      high: '0.4899000000000000',
      low: '0.4737000000000000',
      close: '0.4807000000000000',
      volume: '696146.2800000000000000',
      quoteVolume: 335078.549549,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '0.4818000000000000',
      high: '0.4842000000000000',
      low: '0.4342000000000000',
      close: '0.4548000000000000',
      volume: '1043991.8000000000000000',
      quoteVolume: 484257.113374,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '0.4551000000000000',
      high: '0.4655000000000000',
      low: '0.4470000000000000',
      close: '0.4646000000000000',
      volume: '863577.3300000000000000',
      quoteVolume: 392384.916353,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '0.4627000000000000',
      high: '0.4644000000000000',
      low: '0.4545000000000000',
      close: '0.4608000000000000',
      volume: '406749.6600000000000000',
      quoteVolume: 186648.714066,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '0.4606000000000000',
      high: '0.4621000000000000',
      low: '0.4485000000000000',
      close: '0.4531000000000000',
      volume: '634173.7500000000000000',
      quoteVolume: 287618.895525,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '0.4539000000000000',
      high: '0.4949000000000000',
      low: '0.4410000000000000',
      close: '0.4734000000000000',
      volume: '2441517.9900000000000000',
      quoteVolume: 1142857.563225,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '0.4729000000000000',
      high: '0.4850000000000000',
      low: '0.4480000000000000',
      close: '0.4605000000000000',
      volume: '1129722.2000000000000000',
      quoteVolume: 526180.143477,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '0.4603000000000000',
      high: '0.4997000000000000',
      low: '0.4556000000000000',
      close: '0.4946000000000000',
      volume: '1352736.3400000000000000',
      quoteVolume: 651471.143333,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '0.4967000000000000',
      high: '0.5196000000000000',
      low: '0.4846000000000000',
      close: '0.4992000000000000',
      volume: '1443876.8300000000000000',
      quoteVolume: 719069.643363,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '0.4984000000000000',
      high: '0.5042000000000000',
      low: '0.4887000000000000',
      close: '0.5008000000000000',
      volume: '877803.9200000000000000',
      quoteVolume: 434977.122532,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '0.5008000000000000',
      high: '0.5034000000000000',
      low: '0.4858000000000000',
      close: '0.4940000000000000',
      volume: '675007.1300000000000000',
      quoteVolume: 332877.149186,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '0.4938000000000000',
      high: '0.5410000000000000',
      low: '0.4868000000000000',
      close: '0.5084000000000000',
      volume: '2047013.3300000000000000',
      quoteVolume: 1057434.916151,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '0.5084000000000000',
      high: '0.5630000000000000',
      low: '0.5007000000000000',
      close: '0.5540000000000000',
      volume: '2288623.9600000000000000',
      quoteVolume: 1226137.818617,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '0.5533000000000000',
      high: '0.6000000000000000',
      low: '0.5326000000000000',
      close: '0.5811000000000000',
      volume: '4081948.8400000000000000',
      quoteVolume: 2328532.773552,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '0.5811000000000000',
      high: '0.6073000000000000',
      low: '0.5170000000000000',
      close: '0.5620000000000000',
      volume: '4435492.2100000000000000',
      quoteVolume: 2584274.57288,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '0.5588000000000000',
      high: '0.5839000000000000',
      low: '0.5195000000000000',
      close: '0.5396000000000000',
      volume: '2088035.7200000000000000',
      quoteVolume: 1155026.496367,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '0.5409000000000000',
      high: '0.5799000000000000',
      low: '0.5390000000000000',
      close: '0.5729000000000000',
      volume: '1039348.6800000000000000',
      quoteVolume: 579093.364827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '0.5735000000000000',
      high: '0.5900000000000000',
      low: '0.5557000000000000',
      close: '0.5691000000000000',
      volume: '1176149.9800000000000000',
      quoteVolume: 672544.531992,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '0.5677000000000000',
      high: '0.5918000000000000',
      low: '0.5655000000000000',
      close: '0.5773000000000000',
      volume: '956649.3600000000000000',
      quoteVolume: 552493.434727,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '0.5776000000000000',
      high: '0.5912000000000000',
      low: '0.5370000000000000',
      close: '0.5634000000000000',
      volume: '1559203.7500000000000000',
      quoteVolume: 875383.308889,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '0.5617000000000000',
      high: '0.5637000000000000',
      low: '0.5170000000000000',
      close: '0.5300000000000000',
      volume: '1284451.2500000000000000',
      quoteVolume: 682600.691592,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '0.5300000000000000',
      high: '0.5452000000000000',
      low: '0.5100000000000000',
      close: '0.5197000000000000',
      volume: '1259717.4200000000000000',
      quoteVolume: 662636.077743,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '0.5198000000000000',
      high: '0.5210000000000000',
      low: '0.4530000000000000',
      close: '0.4821000000000000',
      volume: '1891750.2700000000000000',
      quoteVolume: 920572.945172,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '0.4821000000000000',
      high: '0.5134000000000000',
      low: '0.4531000000000000',
      close: '0.4920000000000000',
      volume: '1247794.8300000000000000',
      quoteVolume: 609014.276187,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '0.4932000000000000',
      high: '0.5399000000000000',
      low: '0.4913000000000000',
      close: '0.5358000000000000',
      volume: '984745.9300000000000000',
      quoteVolume: 514382.609618,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '0.5366000000000000',
      high: '0.5449000000000000',
      low: '0.5073000000000000',
      close: '0.5225000000000000',
      volume: '825009.0900000000000000',
      quoteVolume: 431556.816813,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '0.5259000000000000',
      high: '0.5268000000000000',
      low: '0.4800000000000000',
      close: '0.4883000000000000',
      volume: '1617780.6900000000000000',
      quoteVolume: 812827.262155,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '0.4884000000000000',
      high: '0.5176000000000000',
      low: '0.4883000000000000',
      close: '0.4985000000000000',
      volume: '752244.2900000000000000',
      quoteVolume: 377882.383651,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '0.4998000000000000',
      high: '0.5046000000000000',
      low: '0.4880000000000000',
      close: '0.4998000000000000',
      volume: '625939.6200000000000000',
      quoteVolume: 310958.450695,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '0.4992000000000000',
      high: '0.5226000000000000',
      low: '0.4974000000000000',
      close: '0.5193000000000000',
      volume: '747995.9900000000000000',
      quoteVolume: 382966.982357,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '0.5187000000000000',
      high: '0.5390000000000000',
      low: '0.5083000000000000',
      close: '0.5095000000000000',
      volume: '857084.8000000000000000',
      quoteVolume: 447910.430574,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '0.5105000000000000',
      high: '0.5287000000000000',
      low: '0.5050000000000000',
      close: '0.5118000000000000',
      volume: '547954.6800000000000000',
      quoteVolume: 281962.973871,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '0.5117000000000000',
      high: '0.5139000000000000',
      low: '0.4752000000000000',
      close: '0.4963000000000000',
      volume: '755636.9500000000000000',
      quoteVolume: 371111.531726,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '0.4963000000000000',
      high: '0.5170000000000000',
      low: '0.4837000000000000',
      close: '0.4957000000000000',
      volume: '669329.7000000000000000',
      quoteVolume: 335305.551779,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '0.4920000000000000',
      high: '0.5040000000000000',
      low: '0.4756000000000000',
      close: '0.4756000000000000',
      volume: '798688.9900000000000000',
      quoteVolume: 391603.526803,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '0.4756000000000000',
      high: '0.4800000000000000',
      low: '0.4400000000000000',
      close: '0.4750000000000000',
      volume: '1341167.6500000000000000',
      quoteVolume: 614034.70833,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '0.4745000000000000',
      high: '0.4997000000000000',
      low: '0.4350000000000000',
      close: '0.4503000000000000',
      volume: '1289948.5700000000000000',
      quoteVolume: 607159.886379,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '0.4499000000000000',
      high: '0.4699000000000000',
      low: '0.4264000000000000',
      close: '0.4416000000000000',
      volume: '881943.9300000000000000',
      quoteVolume: 389402.998679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '0.4437000000000000',
      high: '0.4698000000000000',
      low: '0.4357000000000000',
      close: '0.4430000000000000',
      volume: '330467.6700000000000000',
      quoteVolume: 149494.592163,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '0.4448000000000000',
      high: '0.4600000000000000',
      low: '0.4403000000000000',
      close: '0.4510000000000000',
      volume: '429353.9200000000000000',
      quoteVolume: 194108.9041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '0.4510000000000000',
      high: '0.4546000000000000',
      low: '0.4240000000000000',
      close: '0.4338000000000000',
      volume: '394944.3300000000000000',
      quoteVolume: 173429.731609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '0.4334000000000000',
      high: '0.4771000000000000',
      low: '0.4320000000000000',
      close: '0.4727000000000000',
      volume: '610148.8000000000000000',
      quoteVolume: 281321.216962,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '0.4750000000000000',
      high: '0.4876000000000000',
      low: '0.4638000000000000',
      close: '0.4730000000000000',
      volume: '397476.9300000000000000',
      quoteVolume: 188524.095397,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '0.4745000000000000',
      high: '0.4757000000000000',
      low: '0.4600000000000000',
      close: '0.4630000000000000',
      volume: '228270.4000000000000000',
      quoteVolume: 106411.499404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '0.4643000000000000',
      high: '0.5223000000000000',
      low: '0.4607000000000000',
      close: '0.5110000000000000',
      volume: '1017233.7800000000000000',
      quoteVolume: 502255.676203,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '0.5118000000000000',
      high: '0.5151000000000000',
      low: '0.4783000000000000',
      close: '0.4964000000000000',
      volume: '640984.0600000000000000',
      quoteVolume: 318811.507836,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '0.4964000000000000',
      high: '0.5238000000000000',
      low: '0.4902000000000000',
      close: '0.5058000000000000',
      volume: '641774.3200000000000000',
      quoteVolume: 328895.388404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '0.5058000000000000',
      high: '0.5882000000000000',
      low: '0.4901000000000000',
      close: '0.5745000000000000',
      volume: '1593771.0200000000000000',
      quoteVolume: 868680.989601,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '0.5798000000000000',
      high: '0.6186000000000000',
      low: '0.5409000000000000',
      close: '0.5435000000000000',
      volume: '2267674.3000000000000000',
      quoteVolume: 1314846.891036,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '0.5433000000000000',
      high: '0.6157000000000000',
      low: '0.5212000000000000',
      close: '0.5940000000000000',
      volume: '1255624.9100000000000000',
      quoteVolume: 730122.02836,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '0.5940000000000000',
      high: '0.7040000000000000',
      low: '0.5873000000000000',
      close: '0.6403000000000000',
      volume: '3548028.1300000000000000',
      quoteVolume: 2284998.871122,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '0.6402000000000000',
      high: '0.7233000000000000',
      low: '0.6374000000000000',
      close: '0.6703000000000000',
      volume: '2987660.6900000000000000',
      quoteVolume: 2051444.51188,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '0.6731000000000000',
      high: '0.7484000000000000',
      low: '0.6517000000000000',
      close: '0.6752000000000000',
      volume: '3014329.8900000000000000',
      quoteVolume: 2076341.525905,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '0.6790000000000000',
      high: '0.6999000000000000',
      low: '0.6285000000000000',
      close: '0.6586000000000000',
      volume: '1760800.0800000000000000',
      quoteVolume: 1165285.921864,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '0.6607000000000000',
      high: '0.6872000000000000',
      low: '0.6336000000000000',
      close: '0.6474000000000000',
      volume: '1281864.7000000000000000',
      quoteVolume: 853179.428728,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '0.6474000000000000',
      high: '0.9565000000000000',
      low: '0.6332000000000000',
      close: '0.8280000000000000',
      volume: '7911014.0700000000000000',
      quoteVolume: 6371210.43235,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '0.8251000000000000',
      high: '0.8890000000000000',
      low: '0.7772000000000000',
      close: '0.8427000000000000',
      volume: '3798325.6400000000000000',
      quoteVolume: 3148626.361888,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '0.8427000000000000',
      high: '0.9389000000000000',
      low: '0.7551000000000000',
      close: '0.9280000000000000',
      volume: '4555532.1900000000000000',
      quoteVolume: 3883945.234255,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '0.9264000000000000',
      high: '0.9869000000000000',
      low: '0.8100000000000000',
      close: '0.8911000000000000',
      volume: '5725645.6900000000000000',
      quoteVolume: 5097003.16005,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '0.8950000000000000',
      high: '0.9592000000000000',
      low: '0.8457000000000000',
      close: '0.9359000000000000',
      volume: '2389369.0400000000000000',
      quoteVolume: 2179041.174403,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '0.9360000000000000',
      high: '1.0500000000000000',
      low: '0.9279000000000000',
      close: '0.9934000000000000',
      volume: '4785605.6200000000000000',
      quoteVolume: 4702849.494509,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '0.9927000000000000',
      high: '1.1430000000000000',
      low: '0.9460000000000000',
      close: '1.1170000000000000',
      volume: '2936467.3000000000000000',
      quoteVolume: 2982285.87569,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '1.1146000000000000',
      high: '1.2500000000000000',
      low: '1.0600000000000000',
      close: '1.1798000000000000',
      volume: '5046994.2000000000000000',
      quoteVolume: 5745623.487639,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '1.1855000000000000',
      high: '1.4740000000000000',
      low: '1.1301000000000000',
      close: '1.2205000000000000',
      volume: '14821357.0900000000000000',
      quoteVolume: 19619790.33193,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '1.2205000000000000',
      high: '1.3655000000000000',
      low: '1.1418000000000000',
      close: '1.3392000000000000',
      volume: '6874618.3900000000000000',
      quoteVolume: 8592874.224192,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '1.3393000000000000',
      high: '1.4347000000000000',
      low: '1.2216000000000000',
      close: '1.3314000000000000',
      volume: '5097940.0500000000000000',
      quoteVolume: 6795296.790503,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '1.3257000000000000',
      high: '1.3376000000000000',
      low: '1.1005000000000000',
      close: '1.1139000000000000',
      volume: '3990172.6100000000000000',
      quoteVolume: 4655614.840081993,
      btcVolume: 581.9913178767249,
      usdVolume: 4655614.840081993,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '1.1181000000000000',
      high: '1.1824000000000000',
      low: '1.0340000000000000',
      close: '1.0756000000000000',
      volume: '2787144.3800000000000000',
      quoteVolume: 3053065.8027050006,
      btcVolume: 377.16663921841183,
      usdVolume: 3053065.8027050006,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '1.0806000000000000',
      high: '1.2750000000000000',
      low: '1.0806000000000000',
      close: '1.2119000000000000',
      volume: '3879947.9900000000000000',
      quoteVolume: 4585345.262517948,
      btcVolume: 526.2433979570677,
      usdVolume: 4585345.262517948,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '1.2199000000000000',
      high: '1.3500000000000000',
      low: '1.1444000000000000',
      close: '1.2921000000000000',
      volume: '2591508.8200000000000000',
      quoteVolume: 3228837.802578005,
      btcVolume: 371.1273709190563,
      usdVolume: 3228837.802578005,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '1.2782000000000000',
      high: '1.2844000000000000',
      low: '1.1497000000000000',
      close: '1.1665000000000000',
      volume: '2450800.8100000000000000',
      quoteVolume: 2938928.7943600034,
      btcVolume: 340.2041830509092,
      usdVolume: 2938928.7943600034,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '1.1780000000000000',
      high: '1.2250000000000000',
      low: '0.9044000000000000',
      close: '1.0057000000000000',
      volume: '6305578.1300000000000000',
      quoteVolume: 6787576.3371680295,
      btcVolume: 791.9128840898691,
      usdVolume: 6787576.3371680295,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '1.0063000000000000',
      high: '1.0444000000000000',
      low: '0.9330000000000000',
      close: '1.0195000000000000',
      volume: '4936161.7800000000000000',
      quoteVolume: 4857535.299134998,
      btcVolume: 581.7438750463537,
      usdVolume: 4857535.299134998,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '1.0252000000000000',
      high: '1.0597000000000000',
      low: '0.9511000000000000',
      close: '0.9560000000000000',
      volume: '2558921.2100000000000000',
      quoteVolume: 2554370.774409004,
      btcVolume: 299.04123365519683,
      usdVolume: 2554370.774409004,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '0.9536000000000000',
      high: '1.0554000000000000',
      low: '0.9323000000000000',
      close: '1.0155000000000000',
      volume: '3448639.2900000000000000',
      quoteVolume: 3433793.2090730225,
      btcVolume: 396.5090133601953,
      usdVolume: 3433793.2090730225,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '1.0155000000000000',
      high: '1.0200000000000000',
      low: '0.8853000000000000',
      close: '0.9000000000000000',
      volume: '3218003.4000000000000000',
      quoteVolume: 3096993.8518149965,
      btcVolume: 365.97881939525035,
      usdVolume: 3096993.8518149965,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '0.8985000000000000',
      high: '0.9299000000000000',
      low: '0.8368000000000000',
      close: '0.8850000000000000',
      volume: '4563258.6100000000000000',
      quoteVolume: 4005371.5748359957,
      btcVolume: 509.9416978186965,
      usdVolume: 4005371.5748359957,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '0.8850000000000000',
      high: '1.0991000000000000',
      low: '0.8838000000000000',
      close: '1.0959000000000000',
      volume: '10134365.7300000000000000',
      quoteVolume: 10259929.50928907,
      btcVolume: 1323.3373278111412,
      usdVolume: 10259929.50928907,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '1.0960000000000000',
      high: '1.2700000000000000',
      low: '1.0556000000000000',
      close: '1.1098000000000000',
      volume: '11104062.4700000000000000',
      quoteVolume: 12707991.386383945,
      btcVolume: 1649.824982729021,
      usdVolume: 12707991.386383945,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '1.1099000000000000',
      high: '1.2589000000000000',
      low: '1.0710000000000000',
      close: '1.1753000000000000',
      volume: '6318630.4200000000000000',
      quoteVolume: 7452520.230448068,
      btcVolume: 944.3447342802139,
      usdVolume: 7452520.230448068,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '1.1747000000000000',
      high: '1.1991000000000000',
      low: '1.1165000000000000',
      close: '1.1326000000000000',
      volume: '2532914.5100000000000000',
      quoteVolume: 2922870.3576520067,
      btcVolume: 369.3243148377816,
      usdVolume: 2922870.3576520067,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '1.1376000000000000',
      high: '1.1486000000000000',
      low: '1.0085000000000000',
      close: '1.0595000000000000',
      volume: '3542850.8800000000000000',
      quoteVolume: 3804204.519311013,
      btcVolume: 491.5768447574139,
      usdVolume: 3804204.519311013,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '1.0595000000000000',
      high: '1.2104000000000000',
      low: '1.0301000000000000',
      close: '1.2060000000000000',
      volume: '3560912.7500000000000000',
      quoteVolume: 4027483.4204219743,
      btcVolume: 514.0312403954563,
      usdVolume: 4027483.4204219743,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '1.2043000000000000',
      high: '1.2070000000000000',
      low: '1.0876000000000000',
      close: '1.1243000000000000',
      volume: '3064771.3900000000000000',
      quoteVolume: 3504537.899613992,
      btcVolume: 445.75194109413064,
      usdVolume: 3504537.899613992,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '1.1241000000000000',
      high: '1.1643000000000000',
      low: '1.0875000000000000',
      close: '1.1448000000000000',
      volume: '2552167.9900000000000000',
      quoteVolume: 2871559.2307969993,
      btcVolume: 359.4040334628175,
      usdVolume: 2871559.2307969993,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '1.1448000000000000',
      high: '1.9700000000000000',
      low: '1.1126000000000000',
      close: '1.8680000000000000',
      volume: '34026255.5700000000000000',
      quoteVolume: 58291200.9484317,
      btcVolume: 7099.103367062914,
      usdVolume: 58291200.9484317,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '1.8680000000000000',
      high: '1.8936000000000000',
      low: '1.4900000000000000',
      close: '1.6395000000000000',
      volume: '33206570.6700000000000000',
      quoteVolume: 54533735.08588836,
      btcVolume: 6570.521290055457,
      usdVolume: 54533735.08588836,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '1.6364000000000000',
      high: '1.8065000000000000',
      low: '1.6022000000000000',
      close: '1.7179000000000000',
      volume: '10700181.1400000000000000',
      quoteVolume: 18387957.79867493,
      btcVolume: 2121.574515120722,
      usdVolume: 18387957.79867493,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '1.7180000000000000',
      high: '1.7924000000000000',
      low: '1.6000000000000000',
      close: '1.6621000000000000',
      volume: '8261300.1500000000000000',
      quoteVolume: 13964950.109855045,
      btcVolume: 1544.6562665703477,
      usdVolume: 13964950.109855045,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '1.6651000000000000',
      high: '2.0776000000000000',
      low: '1.6525000000000000',
      close: '1.9632000000000000',
      volume: '17062882.5500000000000000',
      quoteVolume: 32436293.282424346,
      btcVolume: 3512.1607543888294,
      usdVolume: 32436293.282424346,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '1.9631000000000000',
      high: '2.0185000000000000',
      low: '1.7600000000000000',
      close: '1.8194000000000000',
      volume: '13903321.3000000000000000',
      quoteVolume: 25882989.567272145,
      btcVolume: 2819.6184477064003,
      usdVolume: 25816336.76297314,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '1.8158000000000000',
      high: '1.9016000000000000',
      low: '1.7869000000000000',
      close: '1.8353000000000000',
      volume: '4857233.6000000000000000',
      quoteVolume: 8953905.75995399,
      btcVolume: 980.2564405252405,
      usdVolume: 8953905.75995399,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '1.8352000000000000',
      high: '1.8865000000000000',
      low: '1.6862000000000000',
      close: '1.7656000000000000',
      volume: '5058765.9500000000000000',
      quoteVolume: 8995915.657141935,
      btcVolume: 964.4591378098079,
      usdVolume: 8995915.657141935,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '1.7671000000000000',
      high: '1.7900000000000000',
      low: '1.6100000000000000',
      close: '1.6883000000000000',
      volume: '6929829.1600000000000000',
      quoteVolume: 11630088.984655974,
      btcVolume: 1192.1055771385234,
      usdVolume: 11630088.984655974,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '1.6895000000000000',
      high: '1.9470000000000000',
      low: '1.6827000000000000',
      close: '1.8428000000000000',
      volume: '8390022.4600000000000000',
      quoteVolume: 15123936.817318201,
      btcVolume: 1410.6256719829264,
      usdVolume: 15123936.817318201,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '1.8429000000000000',
      high: '1.9100000000000000',
      low: '1.7702000000000000',
      close: '1.8169000000000000',
      volume: '4593412.3100000000000000',
      quoteVolume: 8418104.00219802,
      btcVolume: 775.4769301470001,
      usdVolume: 8418104.00219802,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '1.8169000000000000',
      high: '1.8884000000000000',
      low: '1.7900000000000000',
      close: '1.8689000000000000',
      volume: '2384487.8200000000000000',
      quoteVolume: 4400730.711527992,
      btcVolume: 403.4557862186641,
      usdVolume: 4400730.711527992,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '1.8689000000000000',
      high: '2.4273000000000000',
      low: '1.8613000000000000',
      close: '2.1928000000000000',
      volume: '21998410.4800000000000000',
      quoteVolume: 47761526.31202764,
      btcVolume: 4201.741421999988,
      usdVolume: 47761526.31202764,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '2.1931000000000000',
      high: '2.5000000000000000',
      low: '2.0231000000000000',
      close: '2.2800000000000000',
      volume: '15800691.2200000000000000',
      quoteVolume: 35017061.17698764,
      btcVolume: 2716.258242985611,
      usdVolume: 35017061.17698764,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '2.2821000000000000',
      high: '2.5550000000000000',
      low: '2.1123000000000000',
      close: '2.2879000000000000',
      volume: '13867785.5900000000000000',
      quoteVolume: 32591424.308536824,
      btcVolume: 2692.462710930972,
      usdVolume: 32591424.308536824,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '2.2912000000000000',
      high: '3.0981000000000000',
      low: '2.2150000000000000',
      close: '3.0392000000000000',
      volume: '16190974.7700000000000000',
      quoteVolume: 43067890.31031614,
      btcVolume: 3571.5324626501774,
      usdVolume: 43067890.31031614,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '3.0391000000000000',
      high: '4.5826000000000000',
      low: '2.9499000000000000',
      close: '3.7125000000000000',
      volume: '55501686.3100000000000000',
      quoteVolume: 212256136.05785748,
      btcVolume: 17792.420778535194,
      usdVolume: 212256136.05785748,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '3.7051000000000000',
      high: '4.2200000000000000',
      low: '3.2300000000000000',
      close: '3.3869000000000000',
      volume: '25190926.7600000000000000',
      quoteVolume: 97105333.71238178,
      btcVolume: 8374.845437346688,
      usdVolume: 97105333.71238178,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '3.3884000000000000',
      high: '3.8620000000000000',
      low: '2.9266000000000000',
      close: '3.6369000000000000',
      volume: '25321151.3600000000000000',
      quoteVolume: 85723441.75518183,
      btcVolume: 8111.5203882492,
      usdVolume: 85723441.75518183,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '3.6476000000000000',
      high: '3.9914000000000000',
      low: '3.2010000000000000',
      close: '3.7453000000000000',
      volume: '26688227.6900000000000000',
      quoteVolume: 97119111.08506477,
      btcVolume: 9423.268210581768,
      usdVolume: 97119111.08506477,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '3.7441000000000000',
      high: '3.8857000000000000',
      low: '3.4769000000000000',
      close: '3.4900000000000000',
      volume: '11091453.7900000000000000',
      quoteVolume: 40850492.97302097,
      btcVolume: 3600.00749644261,
      usdVolume: 40850492.97302097,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '3.4854000000000000',
      high: '3.6100000000000000',
      low: '3.2040000000000000',
      close: '3.4530000000000000',
      volume: '9871917.6900000000000000',
      quoteVolume: 33779334.70123714,
      btcVolume: 2886.5694636866006,
      usdVolume: 33779334.70123714,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '3.4555000000000000',
      high: '3.7500000000000000',
      low: '3.4410000000000000',
      close: '3.6400000000000000',
      volume: '10497021.6500000000000000',
      quoteVolume: 37787417.88724579,
      btcVolume: 3395.0022936467367,
      usdVolume: 37787417.88724579,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '3.6400000000000000',
      high: '3.6611000000000000',
      low: '3.4384000000000000',
      close: '3.4659000000000000',
      volume: '4978418.4100000000000000',
      quoteVolume: 17630030.877459843,
      btcVolume: 1551.1898933985874,
      usdVolume: 17630030.877459843,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '3.4650000000000000',
      high: '3.5038000000000000',
      low: '3.2500000000000000',
      close: '3.2750000000000000',
      volume: '5553651.6400000000000000',
      quoteVolume: 18694548.744352836,
      btcVolume: 1655.1485012561434,
      usdVolume: 18694548.744352836,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '3.2750000000000000',
      high: '3.5731000000000000',
      low: '3.2500000000000000',
      close: '3.3362000000000000',
      volume: '5268983.9800000000000000',
      quoteVolume: 17808772.169726066,
      btcVolume: 1520.284319482537,
      usdVolume: 17808772.169726066,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '3.3334000000000000',
      high: '3.3741000000000000',
      low: '2.9401000000000000',
      close: '3.0670000000000000',
      volume: '8673443.7700000000000000',
      quoteVolume: 27298953.85568921,
      btcVolume: 2188.425469158119,
      usdVolume: 27298953.85568921,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '3.0670000000000000',
      high: '3.2568000000000000',
      low: '2.8578000000000000',
      close: '3.1166000000000000',
      volume: '9527421.0200000000000000',
      quoteVolume: 29323317.465814188,
      btcVolume: 2364.92290456046,
      usdVolume: 29323317.465814188,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '3.1166000000000000',
      high: '3.1555000000000000',
      low: '2.7100000000000000',
      close: '2.7853000000000000',
      volume: '7262068.2500000000000000',
      quoteVolume: 21179286.916986044,
      btcVolume: 1836.1996771189365,
      usdVolume: 21179286.916986044,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '2.7870000000000000',
      high: '3.3282000000000000',
      low: '2.6000000000000000',
      close: '3.1660000000000000',
      volume: '11731863.1100000000000000',
      quoteVolume: 35185953.587122776,
      btcVolume: 3037.647143684832,
      usdVolume: 35185953.587122776,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '3.1660000000000000',
      high: '3.2953000000000000',
      low: '3.0400000000000000',
      close: '3.1652000000000000',
      volume: '9065258.2000000000000000',
      quoteVolume: 28811293.98759913,
      btcVolume: 2530.9180949987526,
      usdVolume: 28811293.98759913,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '3.1689000000000000',
      high: '3.2413000000000000',
      low: '2.7611000000000000',
      close: '2.7856000000000000',
      volume: '8164892.0900000000000000',
      quoteVolume: 24328975.206776988,
      btcVolume: 2259.0976435334483,
      usdVolume: 24328975.206776988,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '2.7792000000000000',
      high: '2.8498000000000000',
      low: '2.5801000000000000',
      close: '2.6913000000000000',
      volume: '7318353.9000000000000000',
      quoteVolume: 19951566.39118311,
      btcVolume: 1928.7548326502576,
      usdVolume: 19951566.39118311,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '2.6911000000000000',
      high: '2.7749000000000000',
      low: '2.1521000000000000',
      close: '2.1948000000000000',
      volume: '7934695.5900000000000000',
      quoteVolume: 19458898.407283843,
      btcVolume: 1935.2094152580992,
      usdVolume: 19458898.407283843,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '2.1947000000000000',
      high: '2.6000000000000000',
      low: '2.0531000000000000',
      close: '2.3980000000000000',
      volume: '13183927.9300000000000000',
      quoteVolume: 30803035.246100836,
      btcVolume: 3208.952057742877,
      usdVolume: 30803035.246100836,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '2.3951000000000000',
      high: '2.8094000000000000',
      low: '2.3542000000000000',
      close: '2.6700000000000000',
      volume: '13345016.8600000000000000',
      quoteVolume: 34825584.843023226,
      btcVolume: 3500.7237591055887,
      usdVolume: 34764284.05553622,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '2.6700000000000000',
      high: '2.7490000000000000',
      low: '2.4910000000000000',
      close: '2.6046000000000000',
      volume: '5510181.5700000000000000',
      quoteVolume: 14293523.817000896,
      btcVolume: 1368.4110094593677,
      usdVolume: 14293523.817000896,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '2.6020000000000000',
      high: '2.7314000000000000',
      low: '2.5751000000000000',
      close: '2.6370000000000000',
      volume: '4596378.0700000000000000',
      quoteVolume: 12202999.48393705,
      btcVolume: 1147.4765675933133,
      usdVolume: 12202999.48393705,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '2.6369000000000000',
      high: '2.6650000000000000',
      low: '2.4600000000000000',
      close: '2.5319000000000000',
      volume: '3349432.2400000000000000',
      quoteVolume: 8531199.090973042,
      btcVolume: 809.0792883766617,
      usdVolume: 8531199.090973042,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '2.5319000000000000',
      high: '2.5857000000000000',
      low: '2.3732000000000000',
      close: '2.4078000000000000',
      volume: '3415186.7600000000000000',
      quoteVolume: 8425751.27914594,
      btcVolume: 810.0485394775847,
      usdVolume: 8425751.27914594,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '2.4073000000000000',
      high: '2.5200000000000000',
      low: '2.2489000000000000',
      close: '2.3535000000000000',
      volume: '5670086.3900000000000000',
      quoteVolume: 13434498.579576982,
      btcVolume: 1334.7270621160683,
      usdVolume: 13434498.579576982,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '2.3559000000000000',
      high: '2.5131000000000000',
      low: '2.2700000000000000',
      close: '2.3999000000000000',
      volume: '5308279.8100000000000000',
      quoteVolume: 12686743.212995907,
      btcVolume: 1303.671357536717,
      usdVolume: 12686743.212995907,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '2.3971000000000000',
      high: '2.5149000000000000',
      low: '2.3595000000000000',
      close: '2.3615000000000000',
      volume: '4016580.8300000000000000',
      quoteVolume: 9840046.642877948,
      btcVolume: 982.9918828701473,
      usdVolume: 9840046.642877948,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '2.3615000000000000',
      high: '2.4300000000000000',
      low: '2.3201000000000000',
      close: '2.3989000000000000',
      volume: '1971791.7400000000000000',
      quoteVolume: 4688945.92508803,
      btcVolume: 480.4188696425642,
      usdVolume: 4688945.92508803,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '2.3954000000000000',
      high: '2.4600000000000000',
      low: '2.1525000000000000',
      close: '2.2453000000000000',
      volume: '4245834.2700000000000000',
      quoteVolume: 9865099.724368896,
      btcVolume: 1019.8334976316504,
      usdVolume: 9865099.724368896,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '2.2372000000000000',
      high: '2.2733000000000000',
      low: '2.1000000000000000',
      close: '2.2156000000000000',
      volume: '1902749.3100000000000000',
      quoteVolume: 4200464.91369699,
      btcVolume: 443.62670497298694,
      usdVolume: 4200464.91369699,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '2.2178000000000000',
      high: '2.2482000000000000',
      low: '2.1100000000000000',
      close: '2.1251000000000000',
      volume: '2215943.5300000000000000',
      quoteVolume: 4805773.902062991,
      btcVolume: 503.4159560790306,
      usdVolume: 4805773.902062991,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '2.1250000000000000',
      high: '2.1854000000000000',
      low: '1.9694000000000000',
      close: '2.0682000000000000',
      volume: '4210835.6200000000000000',
      quoteVolume: 8720296.91253309,
      btcVolume: 916.5223854094169,
      usdVolume: 8720296.91253309,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '2.0672000000000000',
      high: '2.2500000000000000',
      low: '2.0589000000000000',
      close: '2.2102000000000000',
      volume: '4558064.6100000000000000',
      quoteVolume: 9920046.062618986,
      btcVolume: 1007.3882816663967,
      usdVolume: 9920046.062618986,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '2.2169000000000000',
      high: '2.2310000000000000',
      low: '2.1054000000000000',
      close: '2.1610000000000000',
      volume: '2890541.3500000000000000',
      quoteVolume: 6235734.283716941,
      btcVolume: 618.792071520022,
      usdVolume: 6235734.283716941,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '2.1612000000000000',
      high: '2.4900000000000000',
      low: '2.1275000000000000',
      close: '2.4271000000000000',
      volume: '6319480.9600000000000000',
      quoteVolume: 14629814.693532972,
      btcVolume: 1400.4593413301136,
      usdVolume: 14629814.693532972,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '2.4269000000000000',
      high: '2.7342000000000000',
      low: '2.3569000000000000',
      close: '2.4211000000000000',
      volume: '11323687.1300000000000000',
      quoteVolume: 29135005.95525144,
      btcVolume: 2701.80633921746,
      usdVolume: 29135005.95525144,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '2.4186000000000000',
      high: '2.6044000000000000',
      low: '2.3811000000000000',
      close: '2.5099000000000000',
      volume: '6257429.6800000000000000',
      quoteVolume: 15626611.666627988,
      btcVolume: 1446.5228705557931,
      usdVolume: 15626611.666627988,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '2.5097000000000000',
      high: '2.6495000000000000',
      low: '2.4562000000000000',
      close: '2.4562000000000000',
      volume: '5389254.3200000000000000',
      quoteVolume: 13710085.081441978,
      btcVolume: 1176.551959779662,
      usdVolume: 13710085.081441978,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '2.4599000000000000',
      high: '2.5181000000000000',
      low: '2.3100000000000000',
      close: '2.4785000000000000',
      volume: '6024056.7500000000000000',
      quoteVolume: 14593117.820943044,
      btcVolume: 1242.3745698550665,
      usdVolume: 14593117.820943044,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '2.4822000000000000',
      high: '2.5000000000000000',
      low: '2.3502000000000000',
      close: '2.3826000000000000',
      volume: '3376864.0700000000000000',
      quoteVolume: 8167003.771067984,
      btcVolume: 696.2613814905944,
      usdVolume: 8167003.771067984,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '2.3883000000000000',
      high: '2.3998000000000000',
      low: '2.2713000000000000',
      close: '2.2755000000000000',
      volume: '2863707.1900000000000000',
      quoteVolume: 6666989.981878993,
      btcVolume: 564.8964889382114,
      usdVolume: 6666989.981878993,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '2.2784000000000000',
      high: '2.2919000000000000',
      low: '2.1510000000000000',
      close: '2.1669000000000000',
      volume: '2368470.3900000000000000',
      quoteVolume: 5268428.711106007,
      btcVolume: 444.782865591571,
      usdVolume: 5268428.711106007,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '2.1690000000000000',
      high: '2.4091000000000000',
      low: '2.1306000000000000',
      close: '2.3336000000000000',
      volume: '5233238.7500000000000000',
      quoteVolume: 12050390.512594998,
      btcVolume: 1035.7246734427822,
      usdVolume: 12050390.512594998,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '2.3336000000000000',
      high: '2.4900000000000000',
      low: '2.2901000000000000',
      close: '2.4157000000000000',
      volume: '3701976.9800000000000000',
      quoteVolume: 8866596.82357804,
      btcVolume: 777.1699540463694,
      usdVolume: 8866596.82357804,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '2.4157000000000000',
      high: '2.4790000000000000',
      low: '2.3350000000000000',
      close: '2.3900000000000000',
      volume: '2579648.7400000000000000',
      quoteVolume: 6209796.363283018,
      btcVolume: 544.0263252504219,
      usdVolume: 6209796.363283018,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '2.3846000000000000',
      high: '2.4679000000000000',
      low: '2.3060000000000000',
      close: '2.4229000000000000',
      volume: '3150417.3700000000000000',
      quoteVolume: 7506682.66399902,
      btcVolume: 678.7973027516069,
      usdVolume: 7506682.66399902,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '2.4190000000000000',
      high: '2.6031000000000000',
      low: '2.1630000000000000',
      close: '2.2842000000000000',
      volume: '6699207.3700000000000000',
      quoteVolume: 16231062.046769118,
      btcVolume: 1549.2558632873051,
      usdVolume: 16231062.046769118,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '2.2840000000000000',
      high: '2.4506000000000000',
      low: '2.2565000000000000',
      close: '2.4232000000000000',
      volume: '2806801.4000000000000000',
      quoteVolume: 6611413.672988072,
      btcVolume: 652.6110331933832,
      usdVolume: 6611413.672988072,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '2.4225000000000000',
      high: '2.4590000000000000',
      low: '2.2270000000000000',
      close: '2.3000000000000000',
      volume: '3572174.4000000000000000',
      quoteVolume: 8302828.475309981,
      btcVolume: 815.1156611012854,
      usdVolume: 8302828.475309981,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '2.3046000000000000',
      high: '2.4190000000000000',
      low: '2.2820000000000000',
      close: '2.4036000000000000',
      volume: '2035414.3900000000000000',
      quoteVolume: 4797115.851594993,
      btcVolume: 466.47840144058904,
      usdVolume: 4797115.851594993,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '2.4050000000000000',
      high: '2.5657000000000000',
      low: '2.3729000000000000',
      close: '2.5051000000000000',
      volume: '3610375.3000000000000000',
      quoteVolume: 8909798.939858058,
      btcVolume: 863.7326634348484,
      usdVolume: 8909798.939858058,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '2.5055000000000000',
      high: '2.6000000000000000',
      low: '2.4508000000000000',
      close: '2.4958000000000000',
      volume: '4280607.5800000000000000',
      quoteVolume: 10853323.38349604,
      btcVolume: 1017.6371082460578,
      usdVolume: 10853323.38349604,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '2.4943000000000000',
      high: '2.5401000000000000',
      low: '2.3354000000000000',
      close: '2.3624000000000000',
      volume: '3474086.6000000000000000',
      quoteVolume: 8391667.38601199,
      btcVolume: 780.6798427463843,
      usdVolume: 8391667.38601199,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '2.3622000000000000',
      high: '2.3710000000000000',
      low: '2.1500000000000000',
      close: '2.2054000000000000',
      volume: '4915776.4000000000000000',
      quoteVolume: 11029113.309874035,
      btcVolume: 1081.9973137236552,
      usdVolume: 11029113.309874035,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '2.2058000000000000',
      high: '2.2896000000000000',
      low: '2.1350000000000000',
      close: '2.2227000000000000',
      volume: '2980708.5400000000000000',
      quoteVolume: 6618775.966529956,
      btcVolume: 660.0412390163551,
      usdVolume: 6618775.966529956,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '2.2231000000000000',
      high: '2.2950000000000000',
      low: '2.1723000000000000',
      close: '2.2343000000000000',
      volume: '3274284.1600000000000000',
      quoteVolume: 7324880.720449999,
      btcVolume: 715.1730454658111,
      usdVolume: 7324880.720449999,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '2.2343000000000000',
      high: '2.2545000000000000',
      low: '2.1537000000000000',
      close: '2.1681000000000000',
      volume: '1629473.9800000000000000',
      quoteVolume: 3574006.0448640008,
      btcVolume: 352.9833991449766,
      usdVolume: 3574006.0448640008,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '2.1704000000000000',
      high: '2.1804000000000000',
      low: '2.0255000000000000',
      close: '2.0780000000000000',
      volume: '2603393.1400000000000000',
      quoteVolume: 5492656.678655035,
      btcVolume: 545.2530944238928,
      usdVolume: 5492656.678655035,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '2.0783000000000000',
      high: '2.1546000000000000',
      low: '2.0450000000000000',
      close: '2.0739000000000000',
      volume: '2094166.6100000000000000',
      quoteVolume: 4389987.473204011,
      btcVolume: 423.4809452125515,
      usdVolume: 4389987.473204011,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '2.0737000000000000',
      high: '2.1788000000000000',
      low: '2.0050000000000000',
      close: '2.1128000000000000',
      volume: '2811534.7300000000000000',
      quoteVolume: 5891385.969969013,
      btcVolume: 579.2737813324595,
      usdVolume: 5891385.969969013,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '2.1098000000000000',
      high: '2.1134000000000000',
      low: '1.8750000000000000',
      close: '1.8957000000000000',
      volume: '2925102.1700000000000000',
      quoteVolume: 5826671.213469991,
      btcVolume: 585.9165069079237,
      usdVolume: 5826671.213469991,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '1.8956000000000000',
      high: '1.8956000000000000',
      low: '1.7253000000000000',
      close: '1.7744000000000000',
      volume: '4172574.3900000000000000',
      quoteVolume: 7534972.244916026,
      btcVolume: 791.9607358540669,
      usdVolume: 7534972.244916026,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '1.7763000000000000',
      high: '1.8913000000000000',
      low: '1.7365000000000000',
      close: '1.7960000000000000',
      volume: '2660522.1000000000000000',
      quoteVolume: 4851727.818894994,
      btcVolume: 508.2563024568634,
      usdVolume: 4851727.818894994,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '1.7958000000000000',
      high: '1.8535000000000000',
      low: '1.7400000000000000',
      close: '1.7791000000000000',
      volume: '1818973.5000000000000000',
      quoteVolume: 3255608.6478299913,
      btcVolume: 339.9346500517163,
      usdVolume: 3255608.6478299913,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '1.7788000000000000',
      high: '1.8000000000000000',
      low: '1.7170000000000000',
      close: '1.7686000000000000',
      volume: '1329827.2100000000000000',
      quoteVolume: 2338722.617214002,
      btcVolume: 242.99787843430724,
      usdVolume: 2338722.617214002,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '1.7696000000000000',
      high: '1.8900000000000000',
      low: '1.7272000000000000',
      close: '1.8287000000000000',
      volume: '3042460.9700000000000000',
      quoteVolume: 5523573.6413650215,
      btcVolume: 553.7959499063911,
      usdVolume: 5523573.6413650215,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '1.8307000000000000',
      high: '1.9424000000000000',
      low: '1.8147000000000000',
      close: '1.8490000000000000',
      volume: '3198661.0900000000000000',
      quoteVolume: 5978206.5396679975,
      btcVolume: 570.2827065368367,
      usdVolume: 5978206.5396679975,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '1.8486000000000000',
      high: '1.8700000000000000',
      low: '1.7167000000000000',
      close: '1.7793000000000000',
      volume: '3545459.8000000000000000',
      quoteVolume: 6323373.737698996,
      btcVolume: 599.008568072105,
      usdVolume: 6323373.737698996,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '1.7784000000000000',
      high: '1.8624000000000000',
      low: '1.7680000000000000',
      close: '1.7921000000000000',
      volume: '2705662.3600000000000000',
      quoteVolume: 4910186.863704015,
      btcVolume: 465.71608792980777,
      usdVolume: 4910186.863704015,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '1.7919000000000000',
      high: '1.8600000000000000',
      low: '1.6767000000000000',
      close: '1.7226000000000000',
      volume: '3028097.8200000000000000',
      quoteVolume: 5355618.6108840015,
      btcVolume: 506.31943933656817,
      usdVolume: 5355618.6108840015,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '1.7222000000000000',
      high: '1.8060000000000000',
      low: '1.7085000000000000',
      close: '1.7672000000000000',
      volume: '2401823.2500000000000000',
      quoteVolume: 4215075.491124999,
      btcVolume: 404.7663920454655,
      usdVolume: 4215075.491124999,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '1.7672000000000000',
      high: '1.9054000000000000',
      low: '1.7610000000000000',
      close: '1.8275000000000000',
      volume: '3500287.6000000000000000',
      quoteVolume: 6422308.280667982,
      btcVolume: 617.3872473153666,
      usdVolume: 6422308.280667982,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '1.8290000000000000',
      high: '1.8731000000000000',
      low: '1.7610000000000000',
      close: '1.7872000000000000',
      volume: '3473067.9800000000000000',
      quoteVolume: 6305180.84346195,
      btcVolume: 613.2646136601918,
      usdVolume: 6305180.84346195,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '1.7883000000000000',
      high: '1.8326000000000000',
      low: '1.7730000000000000',
      close: '1.7998000000000000',
      volume: '2711918.0700000000000000',
      quoteVolume: 4888778.085218002,
      btcVolume: 478.77419074309563,
      usdVolume: 4888778.085218002,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '1.7988000000000000',
      high: '1.8258000000000000',
      low: '1.7136000000000000',
      close: '1.7301000000000000',
      volume: '2214016.1100000000000000',
      quoteVolume: 3890643.726558997,
      btcVolume: 386.83840067774776,
      usdVolume: 3890643.726558997,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '1.7301000000000000',
      high: '1.7458000000000000',
      low: '1.6918000000000000',
      close: '1.7073000000000000',
      volume: '2228942.3400000000000000',
      quoteVolume: 3823755.5435819686,
      btcVolume: 374.2899559647845,
      usdVolume: 3823755.5435819686,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '1.7073000000000000',
      high: '1.7099000000000000',
      low: '1.5100000000000000',
      close: '1.5759000000000000',
      volume: '6637630.0700000000000000',
      quoteVolume: 10595656.219862076,
      btcVolume: 1032.4407207665965,
      usdVolume: 10595656.219862076,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '1.5761000000000000',
      high: '1.6375000000000000',
      low: '1.5239000000000000',
      close: '1.5634000000000000',
      volume: '5678360.6100000000000000',
      quoteVolume: 9008346.159965044,
      btcVolume: 872.4429504635938,
      usdVolume: 9008346.159965044,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '1.5634000000000000',
      high: '1.6458000000000000',
      low: '1.5600000000000000',
      close: '1.6104000000000000',
      volume: '4049102.7700000000000000',
      quoteVolume: 6482781.439092984,
      btcVolume: 629.5046579926835,
      usdVolume: 6482781.439092984,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '1.6110000000000000',
      high: '1.6355000000000000',
      low: '1.5450000000000000',
      close: '1.5806000000000000',
      volume: '3545712.0300000000000000',
      quoteVolume: 5618393.775373046,
      btcVolume: 548.8843112989407,
      usdVolume: 5618393.775373046,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '1.5806000000000000',
      high: '1.6765000000000000',
      low: '1.5532000000000000',
      close: '1.6240000000000000',
      volume: '5390335.8700000000000000',
      quoteVolume: 8708882.286866039,
      btcVolume: 852.1328908855065,
      usdVolume: 8708882.286866039,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '1.6240000000000000',
      high: '1.8462000000000000',
      low: '1.6240000000000000',
      close: '1.8010000000000000',
      volume: '8267989.9400000000000000',
      quoteVolume: 14346677.761906926,
      btcVolume: 1407.7978038474118,
      usdVolume: 14346677.761906926,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '1.7994000000000000',
      high: '1.9075000000000000',
      low: '1.7079000000000000',
      close: '1.8744000000000000',
      volume: '8485812.8300000000000000',
      quoteVolume: 15522274.024547847,
      btcVolume: 1554.190947436255,
      usdVolume: 15522274.024547847,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '1.8743000000000000',
      high: '1.8772000000000000',
      low: '1.7510000000000000',
      close: '1.8245000000000000',
      volume: '3468435.2500000000000000',
      quoteVolume: 6287930.976908962,
      btcVolume: 618.0830858277567,
      usdVolume: 6287930.976908962,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '1.8215000000000000',
      high: '1.8888000000000000',
      low: '1.7881000000000000',
      close: '1.8855000000000000',
      volume: '2771763.9300000000000000',
      quoteVolume: 5102178.238018988,
      btcVolume: 508.0689834466635,
      usdVolume: 5102178.238018988,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '1.8848000000000000',
      high: '1.8868000000000000',
      low: '1.7711000000000000',
      close: '1.8157000000000000',
      volume: '2311848.6500000000000000',
      quoteVolume: 4207056.9643990025,
      btcVolume: 422.0307052601196,
      usdVolume: 4207056.9643990025,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '1.8149000000000000',
      high: '1.8800000000000000',
      low: '1.7799000000000000',
      close: '1.8031000000000000',
      volume: '2667120.7500000000000000',
      quoteVolume: 4892761.515483015,
      btcVolume: 497.2694919497504,
      usdVolume: 4892761.515483015,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '1.8048000000000000',
      high: '2.0657000000000000',
      low: '1.4612000000000000',
      close: '1.7160000000000000',
      volume: '15096447.2100000000000000',
      quoteVolume: 28169393.552325387,
      btcVolume: 3042.0172749110857,
      usdVolume: 28169393.552325387,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '1.7135000000000000',
      high: '1.9286000000000000',
      low: '1.6249000000000000',
      close: '1.7443000000000000',
      volume: '8910281.8800000000000000',
      quoteVolume: 15541684.445626967,
      btcVolume: 1835.8125331240349,
      usdVolume: 15541684.445626967,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '1.7476000000000000',
      high: '1.7584000000000000',
      low: '1.5276000000000000',
      close: '1.6512000000000000',
      volume: '6786874.8600000000000000',
      quoteVolume: 11209636.83260096,
      btcVolume: 1367.9713551044558,
      usdVolume: 11209636.83260096,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '1.6512000000000000',
      high: '1.6786000000000000',
      low: '1.5573000000000000',
      close: '1.6590000000000000',
      volume: '4333552.0600000000000000',
      quoteVolume: 6991710.784840995,
      btcVolume: 870.5039437524816,
      usdVolume: 6991710.784840995,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '1.6593000000000000',
      high: '1.7017000000000000',
      low: '1.6284000000000000',
      close: '1.6742000000000000',
      volume: '2326952.1300000000000000',
      quoteVolume: 3880224.7487799893,
      btcVolume: 476.88231743920215,
      usdVolume: 3880224.7487799893,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '1.6748000000000000',
      high: '1.7492000000000000',
      low: '1.6441000000000000',
      close: '1.7002000000000000',
      volume: '3304534.4900000000000000',
      quoteVolume: 5625241.514617002,
      btcVolume: 697.5333272708833,
      usdVolume: 5625241.514617002,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '1.7004000000000000',
      high: '1.7690000000000000',
      low: '1.6404000000000000',
      close: '1.7677000000000000',
      volume: '3564898.9100000000000000',
      quoteVolume: 6077384.966384979,
      btcVolume: 753.0627398007749,
      usdVolume: 6077384.966384979,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '1.7677000000000000',
      high: '1.8820000000000000',
      low: '1.7522000000000000',
      close: '1.8286000000000000',
      volume: '4077978.5200000000000000',
      quoteVolume: 7412545.756683013,
      btcVolume: 888.0318959948171,
      usdVolume: 7412545.756683013,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '1.8285000000000000',
      high: '2.0233000000000000',
      low: '1.8121000000000000',
      close: '2.0110000000000000',
      volume: '5789143.1000000000000000',
      quoteVolume: 11109499.143447043,
      btcVolume: 1347.6344778608,
      usdVolume: 11109499.143447043,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '2.0134000000000000',
      high: '2.0338000000000000',
      low: '1.8800000000000000',
      close: '1.9442000000000000',
      volume: '6996841.1000000000000000',
      quoteVolume: 13705258.538260838,
      btcVolume: 1670.6787632836763,
      usdVolume: 13705258.538260838,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '1.9443000000000000',
      high: '1.9949000000000000',
      low: '1.8880000000000000',
      close: '1.9663000000000000',
      volume: '3419972.1800000000000000',
      quoteVolume: 6612123.5559109915,
      btcVolume: 813.0573588319999,
      usdVolume: 6612123.5559109915,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '1.9663000000000000',
      high: '1.9775000000000000',
      low: '1.9113000000000000',
      close: '1.9624000000000000',
      volume: '2231607.1100000000000000',
      quoteVolume: 4348757.9730950035,
      btcVolume: 537.2042324566693,
      usdVolume: 4348757.9730950035,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '1.9624000000000000',
      high: '2.2659000000000000',
      low: '1.9531000000000000',
      close: '2.1669000000000000',
      volume: '9724532.2300000000000000',
      quoteVolume: 20653440.90051679,
      btcVolume: 2600.3765280923726,
      usdVolume: 20653440.90051679,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '2.1669000000000000',
      high: '2.4899000000000000',
      low: '2.0971000000000000',
      close: '2.3963000000000000',
      volume: '12793025.3500000000000000',
      quoteVolume: 29795334.186469693,
      btcVolume: 3681.5038062533695,
      usdVolume: 29795334.186469693,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '2.3963000000000000',
      high: '2.6500000000000000',
      low: '2.2680000000000000',
      close: '2.5766000000000000',
      volume: '16095226.0400000000000000',
      quoteVolume: 39937936.664554514,
      btcVolume: 4873.697508425804,
      usdVolume: 39937936.664554514,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '2.5765000000000000',
      high: '2.9500000000000000',
      low: '2.5010000000000000',
      close: '2.8011000000000000',
      volume: '21716501.4900000000000000',
      quoteVolume: 59715252.16293435,
      btcVolume: 7171.176638549378,
      usdVolume: 59715252.16293435,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '2.8011000000000000',
      high: '2.8058000000000000',
      low: '2.4650000000000000',
      close: '2.6302000000000000',
      volume: '15341662.5100000000000000',
      quoteVolume: 40233982.30970988,
      btcVolume: 4713.439912937979,
      usdVolume: 40233982.30970988,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '2.6280000000000000',
      high: '2.7936000000000000',
      low: '2.5619000000000000',
      close: '2.7481000000000000',
      volume: '11370701.0600000000000000',
      quoteVolume: 30661560.51973289,
      btcVolume: 3657.570932964459,
      usdVolume: 30661560.51973289,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '2.7443000000000000',
      high: '2.7572000000000000',
      low: '2.5519000000000000',
      close: '2.5975000000000000',
      volume: '6172891.7200000000000000',
      quoteVolume: 16310219.63231884,
      btcVolume: 1958.0872004698058,
      usdVolume: 16310219.63231884,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '2.5975000000000000',
      high: '2.6443000000000000',
      low: '2.4351000000000000',
      close: '2.4860000000000000',
      volume: '5530185.4400000000000000',
      quoteVolume: 14018335.45872208,
      btcVolume: 1683.0569562732167,
      usdVolume: 14018335.45872208,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '2.4850000000000000',
      high: '2.6741000000000000',
      low: '2.4527000000000000',
      close: '2.5324000000000000',
      volume: '5826032.3200000000000000',
      quoteVolume: 14927603.979734935,
      btcVolume: 1798.6897727563057,
      usdVolume: 14927603.979734935,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '2.5315000000000000',
      high: '2.5970000000000000',
      low: '2.3320000000000000',
      close: '2.3972000000000000',
      volume: '6184169.7700000000000000',
      quoteVolume: 15237510.027046982,
      btcVolume: 1851.108592609114,
      usdVolume: 15237510.027046982,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '2.3972000000000000',
      high: '2.4143000000000000',
      low: '2.1820000000000000',
      close: '2.3717000000000000',
      volume: '11839469.0800000000000000',
      quoteVolume: 27151346.82973899,
      btcVolume: 3372.7497076069235,
      usdVolume: 27151346.82973899,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '2.3736000000000000',
      high: '2.4900000000000000',
      low: '2.2740000000000000',
      close: '2.4124000000000000',
      volume: '10413491.6200000000000000',
      quoteVolume: 24976988.911125984,
      btcVolume: 3108.003201846181,
      usdVolume: 24976988.911125984,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '2.4190000000000000',
      high: '2.4999000000000000',
      low: '2.3164000000000000',
      close: '2.3546000000000000',
      volume: '7255303.2100000000000000',
      quoteVolume: 17379294.0806661,
      btcVolume: 2186.768617430999,
      usdVolume: 17379294.0806661,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '2.3521000000000000',
      high: '2.3910000000000000',
      low: '2.2914000000000000',
      close: '2.3521000000000000',
      volume: '4513432.3200000000000000',
      quoteVolume: 10575592.141082024,
      btcVolume: 1328.617937628987,
      usdVolume: 10575592.141082024,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '2.3513000000000000',
      high: '2.4510000000000000',
      low: '2.2780000000000000',
      close: '2.4444000000000000',
      volume: '4491279.5700000000000000',
      quoteVolume: 10590230.618070923,
      btcVolume: 1316.2113024406156,
      usdVolume: 10590230.618070923,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '2.4443000000000000',
      high: '2.6900000000000000',
      low: '2.4347000000000000',
      close: '2.6223000000000000',
      volume: '11336763.6800000000000000',
      quoteVolume: 29227623.981732704,
      btcVolume: 3558.2909764104465,
      usdVolume: 29227623.981732704,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '2.6216000000000000',
      high: '2.8178000000000000',
      low: '2.5619000000000000',
      close: '2.6074000000000000',
      volume: '11193488.7900000000000000',
      quoteVolume: 30334954.89091577,
      btcVolume: 3713.298114804292,
      usdVolume: 30334954.89091577,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '2.6073000000000000',
      high: '2.6840000000000000',
      low: '2.3717000000000000',
      close: '2.6055000000000000',
      volume: '13350403.7600000000000000',
      quoteVolume: 34058909.05652626,
      btcVolume: 4438.740203459202,
      usdVolume: 34058909.05652626,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '2.6071000000000000',
      high: '2.7748000000000000',
      low: '2.6020000000000000',
      close: '2.7200000000000000',
      volume: '9744122.3600000000000000',
      quoteVolume: 26188969.916833855,
      btcVolume: 3521.547093016349,
      usdVolume: 26188969.916833855,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '2.7201000000000000',
      high: '3.0000000000000000',
      low: '2.6165000000000000',
      close: '2.8360000000000000',
      volume: '12714070.6800000000000000',
      quoteVolume: 35489759.83948473,
      btcVolume: 4402.325745022558,
      usdVolume: 35489759.83948473,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '2.8370000000000000',
      high: '2.9977000000000000',
      low: '2.6640000000000000',
      close: '2.7348000000000000',
      volume: '12993334.8100000000000000',
      quoteVolume: 36586743.2039599,
      btcVolume: 3897.7642510202168,
      usdVolume: 36586743.2039599,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '2.7323000000000000',
      high: '2.7620000000000000',
      low: '2.6511000000000000',
      close: '2.6646000000000000',
      volume: '5966423.4400000000000000',
      quoteVolume: 16086672.751381027,
      btcVolume: 1713.5391674385778,
      usdVolume: 16086672.751381027,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '2.6707000000000000',
      high: '2.8859000000000000',
      low: '2.6600000000000000',
      close: '2.6611000000000000',
      volume: '7822708.6800000000000000',
      quoteVolume: 21672797.56783017,
      btcVolume: 2284.2347637633156,
      usdVolume: 21672797.56783017,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '2.6601000000000000',
      high: '2.7690000000000000',
      low: '2.6321000000000000',
      close: '2.6620000000000000',
      volume: '3504202.7100000000000000',
      quoteVolume: 9449017.04016303,
      btcVolume: 1008.1912647024507,
      usdVolume: 9449017.04016303,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '2.6610000000000000',
      high: '2.6768000000000000',
      low: '2.5107000000000000',
      close: '2.6093000000000000',
      volume: '3975481.9600000000000000',
      quoteVolume: 10291062.332419943,
      btcVolume: 1120.2450669570424,
      usdVolume: 10291062.332419943,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '2.6044000000000000',
      high: '2.7573000000000000',
      low: '2.5365000000000000',
      close: '2.7219000000000000',
      volume: '4135810.6300000000000000',
      quoteVolume: 10988316.188437952,
      btcVolume: 1197.8135631055834,
      usdVolume: 10988316.188437952,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '2.7233000000000000',
      high: '2.7952000000000000',
      low: '2.6428000000000000',
      close: '2.7091000000000000',
      volume: '3786813.4200000000000000',
      quoteVolume: 10249112.15861604,
      btcVolume: 1122.302010791027,
      usdVolume: 10249112.15861604,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '2.7092000000000000',
      high: '2.7399000000000000',
      low: '2.6828000000000000',
      close: '2.6973000000000000',
      volume: '1945361.3400000000000000',
      quoteVolume: 5267072.043627042,
      btcVolume: 568.6068601325862,
      usdVolume: 5267072.043627042,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '2.7015000000000000',
      high: '2.7104000000000000',
      low: '2.6202000000000000',
      close: '2.6605000000000000',
      volume: '1676684.8800000000000000',
      quoteVolume: 4468927.620704968,
      btcVolume: 485.58249494067894,
      usdVolume: 4468927.620704968,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '2.6594000000000000',
      high: '2.7300000000000000',
      low: '2.6267000000000000',
      close: '2.6746000000000000',
      volume: '2308453.4600000000000000',
      quoteVolume: 6191639.232816044,
      btcVolume: 667.6063736733863,
      usdVolume: 6191639.232816044,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '2.6746000000000000',
      high: '2.6947000000000000',
      low: '2.6322000000000000',
      close: '2.6746000000000000',
      volume: '1985221.7300000000000000',
      quoteVolume: 5293153.906610983,
      btcVolume: 566.6809866952036,
      usdVolume: 5293153.906610983,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '2.6743000000000000',
      high: '2.8200000000000000',
      low: '2.6479000000000000',
      close: '2.7448000000000000',
      volume: '3174248.6300000000000000',
      quoteVolume: 8633537.382419948,
      btcVolume: 926.8771591924037,
      usdVolume: 8633537.382419948,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '2.7446000000000000',
      high: '2.7549000000000000',
      low: '2.6022000000000000',
      close: '2.6757000000000000',
      volume: '3498719.3300000000000000',
      quoteVolume: 9378528.926173998,
      btcVolume: 1015.9056048703025,
      usdVolume: 9378528.926173998,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '2.6758000000000000',
      high: '2.7333000000000000',
      low: '2.6210000000000000',
      close: '2.7100000000000000',
      volume: '2921454.2700000000000000',
      quoteVolume: 7841818.426597041,
      btcVolume: 875.5145773375328,
      usdVolume: 7841818.426597041,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '2.7091000000000000',
      high: '2.8497000000000000',
      low: '2.7031000000000000',
      close: '2.8009000000000000',
      volume: '4656993.4400000000000000',
      quoteVolume: 12964192.966649953,
      btcVolume: 1471.6148137529362,
      usdVolume: 12964192.966649953,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '2.8008000000000000',
      high: '2.8370000000000000',
      low: '2.7362000000000000',
      close: '2.7770000000000000',
      volume: '2591145.5900000000000000',
      quoteVolume: 7226235.751716039,
      btcVolume: 811.2847092382342,
      usdVolume: 7226235.751716039,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '2.7773000000000000',
      high: '2.7777000000000000',
      low: '2.6655000000000000',
      close: '2.7440000000000000',
      volume: '2140491.0400000000000000',
      quoteVolume: 5824727.782756004,
      btcVolume: 663.0973900459279,
      usdVolume: 5824727.782756004,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '2.7438000000000000',
      high: '2.8733000000000000',
      low: '2.7311000000000000',
      close: '2.8152000000000000',
      volume: '3207309.4900000000000000',
      quoteVolume: 8980218.046382047,
      btcVolume: 1028.891814520804,
      usdVolume: 8980218.046382047,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '2.8177000000000000',
      high: '3.0894000000000000',
      low: '2.7851000000000000',
      close: '3.0200000000000000',
      volume: '6541016.7600000000000000',
      quoteVolume: 19407023.30134102,
      btcVolume: 2214.351922566352,
      usdVolume: 19407023.30134102,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '3.0189000000000000',
      high: '3.1600000000000000',
      low: '2.9666000000000000',
      close: '3.0391000000000000',
      volume: '6593839.9000000000000000',
      quoteVolume: 20356219.364202138,
      btcVolume: 2349.5884763957965,
      usdVolume: 20356219.364202138,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '3.0390000000000000',
      high: '3.0700000000000000',
      low: '2.7970000000000000',
      close: '3.0389000000000000',
      volume: '5616867.4900000000000000',
      quoteVolume: 16587870.154289966,
      btcVolume: 1939.8783909842598,
      usdVolume: 16587870.154289966,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '3.0372000000000000',
      high: '3.0435000000000000',
      low: '2.9115000000000000',
      close: '2.9894000000000000',
      volume: '1973143.8200000000000000',
      quoteVolume: 5875410.748616997,
      btcVolume: 693.1568795868579,
      usdVolume: 5875410.748616997,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '2.9893000000000000',
      high: '2.9894000000000000',
      low: '2.8644000000000000',
      close: '2.9100000000000000',
      volume: '1638152.2100000000000000',
      quoteVolume: 4776273.310892999,
      btcVolume: 560.9160031958835,
      usdVolume: 4776273.310892999,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '2.9100000000000000',
      high: '2.9610000000000000',
      low: '2.6977000000000000',
      close: '2.7885000000000000',
      volume: '3479282.4300000000000000',
      quoteVolume: 9852607.662228048,
      btcVolume: 1181.9947586571366,
      usdVolume: 9852607.662228048,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '2.7886000000000000',
      high: '2.8069000000000000',
      low: '2.5800000000000000',
      close: '2.7255000000000000',
      volume: '4538639.6800000000000000',
      quoteVolume: 12210133.545297062,
      btcVolume: 1503.1001831593057,
      usdVolume: 12210133.545297062,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '2.7279000000000000',
      high: '2.7957000000000000',
      low: '2.6300000000000000',
      close: '2.6982000000000000',
      volume: '3588766.3500000000000000',
      quoteVolume: 9788974.060772017,
      btcVolume: 1207.4464054339237,
      usdVolume: 9788974.060772017,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '2.6983000000000000',
      high: '2.7153000000000000',
      low: '2.4710000000000000',
      close: '2.5456000000000000',
      volume: '4528968.6200000000000000',
      quoteVolume: 11725649.253482027,
      btcVolume: 1506.7638418172246,
      usdVolume: 11725649.253482027,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '2.5456000000000000',
      high: '2.5952000000000000',
      low: '2.1000000000000000',
      close: '2.3466000000000000',
      volume: '10778947.2500000000000000',
      quoteVolume: 25122066.728304863,
      btcVolume: 3458.21602950047,
      usdVolume: 25122066.728304863,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '2.3434000000000000',
      high: '2.4621000000000000',
      low: '2.2604000000000000',
      close: '2.4266000000000000',
      volume: '4841617.1200000000000000',
      quoteVolume: 11394019.118919024,
      btcVolume: 1578.269819528385,
      usdVolume: 11394019.118919024,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '2.4272000000000000',
      high: '2.4533000000000000',
      low: '2.2000000000000000',
      close: '2.2216000000000000',
      volume: '4491218.3300000000000000',
      quoteVolume: 10364009.312878031,
      btcVolume: 1456.1232933078102,
      usdVolume: 10364009.312878031,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '2.2218000000000000',
      high: '2.3976000000000000',
      low: '2.0200000000000000',
      close: '2.2523000000000000',
      volume: '8151200.1200000000000000',
      quoteVolume: 18025202.51352003,
      btcVolume: 2602.121395130407,
      usdVolume: 18025202.51352003,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '2.2554000000000000',
      high: '2.3578000000000000',
      low: '2.1459000000000000',
      close: '2.2424000000000000',
      volume: '7541215.9200000000000000',
      quoteVolume: 16895075.120843165,
      btcVolume: 2370.226442420348,
      usdVolume: 16895075.120843165,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '2.2437000000000000',
      high: '2.2882000000000000',
      low: '2.1002000000000000',
      close: '2.2242000000000000',
      volume: '7867101.6900000000000000',
      quoteVolume: 17458921.123055957,
      btcVolume: 2407.2933436753665,
      usdVolume: 17458921.123055957,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '2.2216000000000000',
      high: '2.3248000000000000',
      low: '2.1711000000000000',
      close: '2.2702000000000000',
      volume: '4770270.0100000000000000',
      quoteVolume: 10676600.041403953,
      btcVolume: 1420.5539937358105,
      usdVolume: 10676600.041403953,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '2.2716000000000000',
      high: '2.3763000000000000',
      low: '2.2611000000000000',
      close: '2.3403000000000000',
      volume: '4132886.9400000000000000',
      quoteVolume: 9586717.671007054,
      btcVolume: 1262.8765759121409,
      usdVolume: 9586717.671007054,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '2.3403000000000000',
      high: '2.3437000000000000',
      low: '2.1943000000000000',
      close: '2.2373000000000000',
      volume: '2920487.5800000000000000',
      quoteVolume: 6606597.773422014,
      btcVolume: 864.7793427026156,
      usdVolume: 6606597.773422014,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '2.2360000000000000',
      high: '2.2385000000000000',
      low: '2.1274000000000000',
      close: '2.1638000000000000',
      volume: '3517483.4300000000000000',
      quoteVolume: 7624553.0653519705,
      btcVolume: 1036.6018203822432,
      usdVolume: 7624553.0653519705,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '2.1634000000000000',
      high: '2.1660000000000000',
      low: '2.0524000000000000',
      close: '2.0911000000000000',
      volume: '3648117.9700000000000000',
      quoteVolume: 7673977.60459799,
      btcVolume: 1052.6747095580927,
      usdVolume: 7673977.60459799,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '2.0913000000000000',
      high: '2.1891000000000000',
      low: '2.0580000000000000',
      close: '2.1222000000000000',
      volume: '3779094.5300000000000000',
      quoteVolume: 8035102.426474956,
      btcVolume: 1099.7458034935873,
      usdVolume: 8035102.426474956,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '2.1222000000000000',
      high: '2.1551000000000000',
      low: '2.0000000000000000',
      close: '2.0290000000000000',
      volume: '6418298.4400000000000000',
      quoteVolume: 13241702.282410966,
      btcVolume: 1821.4389081573547,
      usdVolume: 13241702.282410966,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '2.0282000000000000',
      high: '2.0740000000000000',
      low: '1.9583000000000000',
      close: '2.0068000000000000',
      volume: '5364919.9900000000000000',
      quoteVolume: 10777462.949475031,
      btcVolume: 1473.092445555551,
      usdVolume: 10777462.949475031,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '2.0059000000000000',
      high: '2.1042000000000000',
      low: '1.9685000000000000',
      close: '2.0943000000000000',
      volume: '3855061.0400000000000000',
      quoteVolume: 7874379.4179719705,
      btcVolume: 1063.7224341290423,
      usdVolume: 7874379.4179719705,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '2.0918000000000000',
      high: '2.1114000000000000',
      low: '2.0346000000000000',
      close: '2.0440000000000000',
      volume: '2405401.8200000000000000',
      quoteVolume: 4979102.437877952,
      btcVolume: 661.935259884129,
      usdVolume: 4979102.437877952,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '2.0402000000000000',
      high: '2.0850000000000000',
      low: '2.0012000000000000',
      close: '2.0528000000000000',
      volume: '2017582.9900000000000000',
      quoteVolume: 4126872.5763140195,
      btcVolume: 551.4016518898085,
      usdVolume: 4126872.5763140195,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '2.0520000000000000',
      high: '2.0985000000000000',
      low: '2.0151000000000000',
      close: '2.0984000000000000',
      volume: '2749805.9600000000000000',
      quoteVolume: 5635545.581415003,
      btcVolume: 756.3154499408689,
      usdVolume: 5635545.581415003,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '2.0983000000000000',
      high: '2.2862000000000000',
      low: '2.0611000000000000',
      close: '2.2657000000000000',
      volume: '10810913.6300000000000000',
      quoteVolume: 23748657.368107967,
      btcVolume: 3247.6560107514047,
      usdVolume: 23748657.368107967,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '2.2655000000000000',
      high: '2.3042000000000000',
      low: '2.1470000000000000',
      close: '2.1786000000000000',
      volume: '4814219.6800000000000000',
      quoteVolume: 10700085.694164913,
      btcVolume: 1482.272457471991,
      usdVolume: 10700085.694164913,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '2.1784000000000000',
      high: '2.1951000000000000',
      low: '2.0197000000000000',
      close: '2.1056000000000000',
      volume: '4784990.2800000000000000',
      quoteVolume: 10011804.878543014,
      btcVolume: 1397.2028682925654,
      usdVolume: 10011804.878543014,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '2.1037000000000000',
      high: '2.1210000000000000',
      low: '2.0575000000000000',
      close: '2.0709000000000000',
      volume: '1938856.4000000000000000',
      quoteVolume: 4050597.004999004,
      btcVolume: 560.2010274614569,
      usdVolume: 4050597.004999004,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '2.0713000000000000',
      high: '2.0933000000000000',
      low: '1.9700000000000000',
      close: '1.9946000000000000',
      volume: '1750170.3500000000000000',
      quoteVolume: 3553231.517961994,
      btcVolume: 497.52783996859756,
      usdVolume: 3553231.517961994,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '1.9947000000000000',
      high: '2.1168000000000000',
      low: '1.9606000000000000',
      close: '2.0708000000000000',
      volume: '2572245.4900000000000000',
      quoteVolume: 5290463.029340993,
      btcVolume: 744.7645762589991,
      usdVolume: 5290463.029340993,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '2.0722000000000000',
      high: '2.0873000000000000',
      low: '1.9670000000000000',
      close: '1.9751000000000000',
      volume: '2426967.3300000000000000',
      quoteVolume: 4880535.555653993,
      btcVolume: 697.783270194055,
      usdVolume: 4880535.555653993,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '1.9705000000000000',
      high: '2.0025000000000000',
      low: '1.7000000000000000',
      close: '1.7598000000000000',
      volume: '7314955.8200000000000000',
      quoteVolume: 13273928.880977929,
      btcVolume: 1967.0240939966445,
      usdVolume: 13273928.880977929,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '1.7594000000000000',
      high: '1.8995000000000000',
      low: '1.5996000000000000',
      close: '1.8703000000000000',
      volume: '8586632.6500000000000000',
      quoteVolume: 15238050.812313028,
      btcVolume: 2248.4745148935344,
      usdVolume: 15238050.812313028,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '1.8703000000000000',
      high: '1.8901000000000000',
      low: '1.7490000000000000',
      close: '1.8050000000000000',
      volume: '5044150.8900000000000000',
      quoteVolume: 9131480.463140937,
      btcVolume: 1276.1833159780792,
      usdVolume: 9131480.463140937,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '1.8050000000000000',
      high: '1.9238000000000000',
      low: '1.7849000000000000',
      close: '1.9182000000000000',
      volume: '3285479.3800000000000000',
      quoteVolume: 6091824.338370992,
      btcVolume: 851.739498901493,
      usdVolume: 6091824.338370992,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '1.9182000000000000',
      high: '1.9204000000000000',
      low: '1.8544000000000000',
      close: '1.8575000000000000',
      volume: '2246207.9200000000000000',
      quoteVolume: 4254205.469336003,
      btcVolume: 595.372491489374,
      usdVolume: 4254205.469336003,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '1.8563000000000000',
      high: '1.9400000000000000',
      low: '1.8530000000000000',
      close: '1.9181000000000000',
      volume: '2765867.8000000000000000',
      quoteVolume: 5280663.966873023,
      btcVolume: 724.5881359505457,
      usdVolume: 5280663.966873023,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '1.9167000000000000',
      high: '1.9532000000000000',
      low: '1.8460000000000000',
      close: '1.8629000000000000',
      volume: '2832932.7600000000000000',
      quoteVolume: 5427329.285269981,
      btcVolume: 724.3302026053892,
      usdVolume: 5427329.285269981,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '1.8638000000000000',
      high: '1.8854000000000000',
      low: '1.8154000000000000',
      close: '1.8318000000000000',
      volume: '2276138.1100000000000000',
      quoteVolume: 4206713.595653003,
      btcVolume: 575.7089237245987,
      usdVolume: 4206713.595653003,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '1.8434000000000000',
      high: '1.8462000000000000',
      low: '1.7600000000000000',
      close: '1.7826000000000000',
      volume: '1576134.1400000000000000',
      quoteVolume: 2842886.6440100074,
      btcVolume: 394.49536602127625,
      usdVolume: 2842886.6440100074,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '1.7836000000000000',
      high: '1.9604000000000000',
      low: '1.7702000000000000',
      close: '1.9460000000000000',
      volume: '2645538.2200000000000000',
      quoteVolume: 4914305.251431999,
      btcVolume: 675.865579877377,
      usdVolume: 4914305.251431999,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '1.8538000000000000',
      high: '1.9299000000000000',
      low: '1.8435000000000000',
      close: '1.8751000000000000',
      volume: '2413976.6800000000000000',
      quoteVolume: 4547744.520783,
      btcVolume: 630.9067581741584,
      usdVolume: 4547744.520783,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '1.8777000000000000',
      high: '1.8994000000000000',
      low: '1.8555000000000000',
      close: '1.8746000000000000',
      volume: '1269364.7000000000000000',
      quoteVolume: 2384012.458777003,
      btcVolume: 326.02528374878295,
      usdVolume: 2384012.458777003,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '1.8716000000000000',
      high: '1.9200000000000000',
      low: '1.8444000000000000',
      close: '1.8976000000000000',
      volume: '1411381.0500000000000000',
      quoteVolume: 2639536.610925001,
      btcVolume: 357.41364249988766,
      usdVolume: 2639536.610925001,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '1.8980000000000000',
      high: '1.8980000000000000',
      low: '1.8072000000000000',
      close: '1.8243000000000000',
      volume: '1099037.9900000000000000',
      quoteVolume: 2036388.7939540006,
      btcVolume: 278.66897959056115,
      usdVolume: 2036388.7939540006,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '1.8215000000000000',
      high: '1.8292000000000000',
      low: '1.7540000000000000',
      close: '1.7673000000000000',
      volume: '1637635.2800000000000000',
      quoteVolume: 2932476.277038998,
      btcVolume: 406.1371342093213,
      usdVolume: 2932476.277038998,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '1.7678000000000000',
      high: '1.8548000000000000',
      low: '1.7606000000000000',
      close: '1.8111000000000000',
      volume: '1158740.3200000000000000',
      quoteVolume: 2106240.113113006,
      btcVolume: 291.7435681061432,
      usdVolume: 2106240.113113006,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '1.8109000000000000',
      high: '1.8164000000000000',
      low: '1.7065000000000000',
      close: '1.7366000000000000',
      volume: '1346757.5400000000000000',
      quoteVolume: 2370258.0493380134,
      btcVolume: 336.50922976459,
      usdVolume: 2370258.0493380134,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '1.7366000000000000',
      high: '1.8292000000000000',
      low: '1.7013000000000000',
      close: '1.8040000000000000',
      volume: '1782059.3300000000000000',
      quoteVolume: 3173528.806004989,
      btcVolume: 440.5557916213236,
      usdVolume: 3173528.806004989,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '1.8030000000000000',
      high: '1.8446000000000000',
      low: '1.7840000000000000',
      close: '1.8242000000000000',
      volume: '1241322.8100000000000000',
      quoteVolume: 2252857.6365430057,
      btcVolume: 307.3651298252609,
      usdVolume: 2252857.6365430057,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '1.8243000000000000',
      high: '1.8413000000000000',
      low: '1.7850000000000000',
      close: '1.7957000000000000',
      volume: '1524603.4000000000000000',
      quoteVolume: 2771904.605593008,
      btcVolume: 372.70606476580207,
      usdVolume: 2771904.605593008,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '1.7954000000000000',
      high: '1.9386000000000000',
      low: '1.7925000000000000',
      close: '1.9374000000000000',
      volume: '3301230.5100000000000000',
      quoteVolume: 6219983.016896969,
      btcVolume: 822.3795323204727,
      usdVolume: 6219983.016896969,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '1.9373000000000000',
      high: '2.1398000000000000',
      low: '1.8993000000000000',
      close: '2.1033000000000000',
      volume: '6873503.3900000000000000',
      quoteVolume: 14001167.440691043,
      btcVolume: 1765.0859844694687,
      usdVolume: 14001167.440691043,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '2.1006000000000000',
      high: '2.3495000000000000',
      low: '2.1006000000000000',
      close: '2.1910000000000000',
      volume: '10645081.3000000000000000',
      quoteVolume: 23671432.343011014,
      btcVolume: 2869.5091074200345,
      usdVolume: 23671432.343011014,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '2.1842000000000000',
      high: '2.2344000000000000',
      low: '2.0977000000000000',
      close: '2.1886000000000000',
      volume: '4880978.6700000000000000',
      quoteVolume: 10564559.54597592,
      btcVolume: 1336.7758650978221,
      usdVolume: 10564559.54597592,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '2.1867000000000000',
      high: '2.2489000000000000',
      low: '2.1455000000000000',
      close: '2.2257000000000000',
      volume: '4235446.6200000000000000',
      quoteVolume: 9348313.457428945,
      btcVolume: 1187.4445809136419,
      usdVolume: 9348313.457428945,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '2.2255000000000000',
      high: '2.3450000000000000',
      low: '2.2180000000000000',
      close: '2.2734000000000000',
      volume: '4346405.0600000000000000',
      quoteVolume: 9936058.411543818,
      btcVolume: 1222.4125100446236,
      usdVolume: 9936058.411543818,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '2.2730000000000000',
      high: '2.3212000000000000',
      low: '2.2120000000000000',
      close: '2.2383000000000000',
      volume: '2499405.7900000000000000',
      quoteVolume: 5647655.962262982,
      btcVolume: 695.833466287728,
      usdVolume: 5647655.962262982,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '2.2382000000000000',
      high: '2.2382000000000000',
      low: '2.1561000000000000',
      close: '2.1901000000000000',
      volume: '1952305.6900000000000000',
      quoteVolume: 4261648.208497995,
      btcVolume: 525.0222154958136,
      usdVolume: 4261648.208497995,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '2.1904000000000000',
      high: '2.4701000000000000',
      low: '2.1792000000000000',
      close: '2.3751000000000000',
      volume: '7058803.5000000000000000',
      quoteVolume: 16487477.721062977,
      btcVolume: 1910.6120911212884,
      usdVolume: 16487477.721062977,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '2.3751000000000000',
      high: '2.5604000000000000',
      low: '2.3332000000000000',
      close: '2.3953000000000000',
      volume: '6388121.6800000000000000',
      quoteVolume: 15661576.690633962,
      btcVolume: 1788.326833421823,
      usdVolume: 15661576.690633962,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '2.3991000000000000',
      high: '2.5100000000000000',
      low: '2.2932000000000000',
      close: '2.4925000000000000',
      volume: '4937240.5300000000000000',
      quoteVolume: 11942842.296321075,
      btcVolume: 1374.9026425662325,
      usdVolume: 11942842.296321075,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '2.4924000000000000',
      high: '2.8600000000000000',
      low: '2.4589000000000000',
      close: '2.7006000000000000',
      volume: '12107831.2900000000000000',
      quoteVolume: 32609325.00067248,
      btcVolume: 3674.588483290462,
      usdVolume: 32609325.00067248,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '2.7006000000000000',
      high: '2.7946000000000000',
      low: '2.5800000000000000',
      close: '2.7709000000000000',
      volume: '4454822.8800000000000000',
      quoteVolume: 12057457.05895922,
      btcVolume: 1354.3595299323777,
      usdVolume: 12057457.05895922,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '2.7749000000000000',
      high: '2.8475000000000000',
      low: '2.5385000000000000',
      close: '2.6212000000000000',
      volume: '6634722.1700000000000000',
      quoteVolume: 17678886.450535074,
      btcVolume: 2012.0756253195111,
      usdVolume: 17678886.450535074,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '2.6207000000000000',
      high: '2.7443000000000000',
      low: '2.5425000000000000',
      close: '2.6880000000000000',
      volume: '4142689.5300000000000000',
      quoteVolume: 10966736.422782997,
      btcVolume: 1266.964503736621,
      usdVolume: 10966736.422782997,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '2.6881000000000000',
      high: '2.7440000000000000',
      low: '2.5700000000000000',
      close: '2.6716000000000000',
      volume: '3244654.0900000000000000',
      quoteVolume: 8677468.39766201,
      btcVolume: 1002.1855751352247,
      usdVolume: 8677468.39766201,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '2.6724000000000000',
      high: '2.6978000000000000',
      low: '2.6187000000000000',
      close: '2.6426000000000000',
      volume: '1637205.0200000000000000',
      quoteVolume: 4351550.913769996,
      btcVolume: 500.3133383092309,
      usdVolume: 4351550.913769996,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '2.6425000000000000',
      high: '2.6470000000000000',
      low: '2.4256000000000000',
      close: '2.4698000000000000',
      volume: '4806803.2100000000000000',
      quoteVolume: 11989939.300899168,
      btcVolume: 1413.7383885194763,
      usdVolume: 11989939.300899168,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '2.4695000000000000',
      high: '2.5330000000000000',
      low: '2.3638000000000000',
      close: '2.5024000000000000',
      volume: '4161651.9700000000000000',
      quoteVolume: 10232694.793375008,
      btcVolume: 1220.4855118581247,
      usdVolume: 10232694.793375008,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '2.5024000000000000',
      high: '2.5100000000000000',
      low: '2.4210000000000000',
      close: '2.4468000000000000',
      volume: '1881630.1800000000000000',
      quoteVolume: 4630337.163306008,
      btcVolume: 555.0830281021886,
      usdVolume: 4630337.163306008,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '2.4425000000000000',
      high: '2.5764000000000000',
      low: '2.4266000000000000',
      close: '2.5764000000000000',
      volume: '1936836.4700000000000000',
      quoteVolume: 4876802.3327080235,
      btcVolume: 574.1477799412621,
      usdVolume: 4876802.3327080235,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '2.5764000000000000',
      high: '2.6764000000000000',
      low: '2.5656000000000000',
      close: '2.6055000000000000',
      volume: '3426087.6500000000000000',
      quoteVolume: 8983048.130742965,
      btcVolume: 1025.861592856912,
      usdVolume: 8983048.130742965,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '2.6051000000000000',
      high: '2.6700000000000000',
      low: '2.5868000000000000',
      close: '2.6558000000000000',
      volume: '3113588.1700000000000000',
      quoteVolume: 8190994.708914968,
      btcVolume: 901.4969362742667,
      usdVolume: 8190994.708914968,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '2.6544000000000000',
      high: '2.8348000000000000',
      low: '2.6316000000000000',
      close: '2.7773000000000000',
      volume: '5191102.2600000000000000',
      quoteVolume: 14210741.277650112,
      btcVolume: 1519.9493554481853,
      usdVolume: 14210741.277650112,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '2.7785000000000000',
      high: '2.9482000000000000',
      low: '2.7167000000000000',
      close: '2.9156000000000000',
      volume: '5780750.0200000000000000',
      quoteVolume: 16529966.221006121,
      btcVolume: 1760.8623301714779,
      usdVolume: 16529966.221006121,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '2.9120000000000000',
      high: '2.9153000000000000',
      low: '2.7628000000000000',
      close: '2.8259000000000000',
      volume: '3910431.2100000000000000',
      quoteVolume: 11114615.65423999,
      btcVolume: 1189.571698179413,
      usdVolume: 11114615.65423999,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '2.8258000000000000',
      high: '2.8800000000000000',
      low: '2.7438000000000000',
      close: '2.8378000000000000',
      volume: '2737541.7700000000000000',
      quoteVolume: 7681755.743857064,
      btcVolume: 819.739572372717,
      usdVolume: 7681755.743857064,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '2.8372000000000000',
      high: '2.8869000000000000',
      low: '2.7314000000000000',
      close: '2.8267000000000000',
      volume: '3442889.5900000000000000',
      quoteVolume: 9768511.051874014,
      btcVolume: 1040.2867368472987,
      usdVolume: 9768511.051874014,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '2.8225000000000000',
      high: '2.8790000000000000',
      low: '2.7451000000000000',
      close: '2.7710000000000000',
      volume: '3106758.8600000000000000',
      quoteVolume: 8691569.65454806,
      btcVolume: 929.8882901515204,
      usdVolume: 8691569.65454806,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '2.7713000000000000',
      high: '2.8038000000000000',
      low: '2.6751000000000000',
      close: '2.7395000000000000',
      volume: '4491247.5800000000000000',
      quoteVolume: 12261241.924308985,
      btcVolume: 1328.2200602977618,
      usdVolume: 12261241.924308985,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '2.7397000000000000',
      high: '2.8845000000000000',
      low: '2.7261000000000000',
      close: '2.8390000000000000',
      volume: '4267689.2200000000000000',
      quoteVolume: 12053160.781550005,
      btcVolume: 1276.385904166067,
      usdVolume: 12053160.781550005,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '2.8360000000000000',
      high: '2.9436000000000000',
      low: '2.7900000000000000',
      close: '2.8900000000000000',
      volume: '4131243.5200000000000000',
      quoteVolume: 11910422.487068068,
      btcVolume: 1227.3627511910402,
      usdVolume: 11910422.487068068,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '2.8900000000000000',
      high: '3.3682000000000000',
      low: '2.8882000000000000',
      close: '3.3072000000000000',
      volume: '14813365.3600000000000000',
      quoteVolume: 47449282.522496335,
      btcVolume: 4846.042169836303,
      usdVolume: 47449282.522496335,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '3.3071000000000000',
      high: '3.4865000000000000',
      low: '3.1310000000000000',
      close: '3.4171000000000000',
      volume: '10059760.5500000000000000',
      quoteVolume: 33442719.414118227,
      btcVolume: 3409.4308561389403,
      usdVolume: 33442719.414118227,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '3.4179000000000000',
      high: '3.4984000000000000',
      low: '3.2330000000000000',
      close: '3.4945000000000000',
      volume: '6056067.2700000000000000',
      quoteVolume: 20336735.107043743,
      btcVolume: 2016.569351190022,
      usdVolume: 20336735.107043743,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '3.4927000000000000',
      high: '3.4972000000000000',
      low: '3.2806000000000000',
      close: '3.3862000000000000',
      volume: '6758374.3500000000000000',
      quoteVolume: 22873780.175180133,
      btcVolume: 2303.5567499558065,
      usdVolume: 22873780.175180133,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '3.3874000000000000',
      high: '3.9964000000000000',
      low: '3.3500000000000000',
      close: '3.9931000000000000',
      volume: '11780106.0700000000000000',
      quoteVolume: 43237892.69208104,
      btcVolume: 4336.995754427936,
      usdVolume: 43237892.69208104,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '3.9937000000000000',
      high: '4.2000000000000000',
      low: '3.8472000000000000',
      close: '3.9953000000000000',
      volume: '13673147.3100000000000000',
      quoteVolume: 55003441.64790517,
      btcVolume: 5332.8082573628735,
      usdVolume: 55003441.64790517,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '3.9930000000000000',
      high: '4.1147000000000000',
      low: '3.7123000000000000',
      close: '3.8765000000000000',
      volume: '8191206.1300000000000000',
      quoteVolume: 32257042.692259546,
      btcVolume: 3140.1527250738345,
      usdVolume: 32257042.692259546,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '3.8753000000000000',
      high: '4.4870000000000000',
      low: '3.7873000000000000',
      close: '4.4260000000000000',
      volume: '10369344.9500000000000000',
      quoteVolume: 43468717.18347415,
      btcVolume: 4231.988614585208,
      usdVolume: 43468717.18347415,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '4.4262000000000000',
      high: '4.6210000000000000',
      low: '4.0650000000000000',
      close: '4.3241000000000000',
      volume: '15573115.1200000000000000',
      quoteVolume: 68355374.03477696,
      btcVolume: 6799.603705176355,
      usdVolume: 68355374.03477696,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '4.3215000000000000',
      high: '4.8671000000000000',
      low: '4.0384000000000000',
      close: '4.4566000000000000',
      volume: '22171359.6900000000000000',
      quoteVolume: 99672983.28316268,
      btcVolume: 10084.607252387823,
      usdVolume: 99672983.28316268,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '4.4563000000000000',
      high: '4.5522000000000000',
      low: '3.8449000000000000',
      close: '4.3108000000000000',
      volume: '19343142.2500000000000000',
      quoteVolume: 80859272.12602058,
      btcVolume: 8343.69697549989,
      usdVolume: 80859272.12602058,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '4.3059000000000000',
      high: '4.6360000000000000',
      low: '4.1416000000000000',
      close: '4.5523000000000000',
      volume: '11931206.5700000000000000',
      quoteVolume: 52870430.99099803,
      btcVolume: 5385.401313218787,
      usdVolume: 52870430.99099803,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '4.5539000000000000',
      high: '4.8355000000000000',
      low: '4.1930000000000000',
      close: '4.3873000000000000',
      volume: '10055681.7000000000000000',
      quoteVolume: 46162309.631008886,
      btcVolume: 4610.077023153315,
      usdVolume: 46162309.631008886,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '4.3840000000000000',
      high: '4.4865000000000000',
      low: '4.0673000000000000',
      close: '4.2644000000000000',
      volume: '11463128.1100000000000000',
      quoteVolume: 48921964.38781903,
      btcVolume: 5107.005216621635,
      usdVolume: 48921964.38781903,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '4.2666000000000000',
      high: '4.5127000000000000',
      low: '4.1840000000000000',
      close: '4.2778000000000000',
      volume: '7706651.0700000000000000',
      quoteVolume: 33506855.339449476,
      btcVolume: 3462.076686554174,
      usdVolume: 33506855.339449476,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '4.2789000000000000',
      high: '4.3500000000000000',
      low: '4.0332000000000000',
      close: '4.1066000000000000',
      volume: '6716454.0200000000000000',
      quoteVolume: 27976312.64533768,
      btcVolume: 2902.199389115639,
      usdVolume: 27976312.64533768,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '4.1006000000000000',
      high: '4.3768000000000000',
      low: '4.0693000000000000',
      close: '4.2365000000000000',
      volume: '5642974.2100000000000000',
      quoteVolume: 23992021.76366039,
      btcVolume: 2434.114086850125,
      usdVolume: 23992021.76366039,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '4.2366000000000000',
      high: '4.2871000000000000',
      low: '3.7916000000000000',
      close: '3.9148000000000000',
      volume: '8591286.0700000000000000',
      quoteVolume: 34393400.24605992,
      btcVolume: 3548.267172458539,
      usdVolume: 34393400.24605992,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '3.9179000000000000',
      high: '3.9600000000000000',
      low: '3.4864000000000000',
      close: '3.4905000000000000',
      volume: '12394531.5200000000000000',
      quoteVolume: 45560015.71143371,
      btcVolume: 4810.153110343996,
      usdVolume: 45560015.71143371,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '3.4874000000000000',
      high: '3.8587000000000000',
      low: '3.3113000000000000',
      close: '3.5648000000000000',
      volume: '22177330.0200000000000000',
      quoteVolume: 79010843.38455322,
      btcVolume: 8784.642820650577,
      usdVolume: 79010843.38455322,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '3.5648000000000000',
      high: '4.1320000000000000',
      low: '3.4197000000000000',
      close: '3.9579000000000000',
      volume: '19080853.8700000000000000',
      quoteVolume: 73041636.71234092,
      btcVolume: 8290.177225043033,
      usdVolume: 73041636.71234092,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '3.9568000000000000',
      high: '4.2395000000000000',
      low: '3.7500000000000000',
      close: '4.1609000000000000',
      volume: '18251889.1100000000000000',
      quoteVolume: 72768936.34054644,
      btcVolume: 8381.737217549813,
      usdVolume: 72768936.34054644,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '4.1614000000000000',
      high: '4.3535000000000000',
      low: '4.0572000000000000',
      close: '4.0579000000000000',
      volume: '12433586.3300000000000000',
      quoteVolume: 52447455.00483184,
      btcVolume: 6050.453008344912,
      usdVolume: 52447455.00483184,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '4.0600000000000000',
      high: '4.2385000000000000',
      low: '3.7607000000000000',
      close: '3.8590000000000000',
      volume: '14748523.0700000000000000',
      quoteVolume: 58869388.27488089,
      btcVolume: 6880.090250435741,
      usdVolume: 58869388.27488089,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '3.8622000000000000',
      high: '4.3435000000000000',
      low: '3.8173000000000000',
      close: '4.2423000000000000',
      volume: '12656156.0500000000000000',
      quoteVolume: 52493228.92320553,
      btcVolume: 5994.207586648094,
      usdVolume: 52493228.92320553,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '4.2421000000000000',
      high: '4.6800000000000000',
      low: '4.1249000000000000',
      close: '4.5846000000000000',
      volume: '17189323.0000000000000000',
      quoteVolume: 75145836.87198897,
      btcVolume: 8560.826818400581,
      usdVolume: 75145836.87198897,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '4.5873000000000000',
      high: '4.9762000000000000',
      low: '4.5333000000000000',
      close: '4.6406000000000000',
      volume: '18623299.5100000000000000',
      quoteVolume: 88271977.43112102,
      btcVolume: 10087.310040122788,
      usdVolume: 88271977.43112102,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '4.6388000000000000',
      high: '4.8990000000000000',
      low: '4.6239000000000000',
      close: '4.7151000000000000',
      volume: '11845630.6300000000000000',
      quoteVolume: 56401786.5002544,
      btcVolume: 6263.948735289506,
      usdVolume: 56401786.5002544,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '4.7144000000000000',
      high: '4.7800000000000000',
      low: '4.5429000000000000',
      close: '4.6814000000000000',
      volume: '7645180.7400000000000000',
      quoteVolume: 35490114.51378227,
      btcVolume: 3908.2548527927784,
      usdVolume: 35490114.51378227,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '4.6813000000000000',
      high: '4.6863000000000000',
      low: '4.3143000000000000',
      close: '4.3412000000000000',
      volume: '8209506.8500000000000000',
      quoteVolume: 37077348.43982905,
      btcVolume: 4113.020563862099,
      usdVolume: 37077348.43982905,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '4.3468000000000000',
      high: '4.4646000000000000',
      low: '3.8500000000000000',
      close: '4.0537000000000000',
      volume: '18324667.2600000000000000',
      quoteVolume: 76704000.26084687,
      btcVolume: 9085.343101448982,
      usdVolume: 76704000.26084687,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '4.0537000000000000',
      high: '4.3690000000000000',
      low: '3.6012000000000000',
      close: '4.1231000000000000',
      volume: '29042929.6300000000000000',
      quoteVolume: 116996235.58194844,
      btcVolume: 14910.78468436395,
      usdVolume: 116996235.58194844,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '4.1237000000000000',
      high: '4.2119000000000000',
      low: '3.9132000000000000',
      close: '4.0839000000000000',
      volume: '18071323.5600000000000000',
      quoteVolume: 73423955.05481032,
      btcVolume: 9232.548450091543,
      usdVolume: 73423955.05481032,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '4.0842000000000000',
      high: '4.1100000000000000',
      low: '3.5514000000000000',
      close: '3.8169000000000000',
      volume: '19407327.4000000000000000',
      quoteVolume: 74033713.87227552,
      btcVolume: 9483.288672404144,
      usdVolume: 74033713.87227552,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '3.8170000000000000',
      high: '3.8519000000000000',
      low: '0.0001000000000000',
      close: '1.9931000000000000',
      volume: '67738097.2700000000000000',
      quoteVolume: 177673762.49713,
      btcVolume: 27866.237147761774,
      usdVolume: 177673762.49713,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '1.9990000000000000',
      high: '2.7520000000000000',
      low: '1.3600000000000000',
      close: '2.4333000000000000',
      volume: '69599335.5100000000000000',
      quoteVolume: 150523398.38686204,
      btcVolume: 29105.657242605583,
      usdVolume: 150523398.38686204,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '2.4328000000000000',
      high: '2.5141000000000000',
      low: '2.0607000000000000',
      close: '2.1379000000000000',
      volume: '21569351.2700000000000000',
      quoteVolume: 49487604.75409475,
      btcVolume: 9228.82980590662,
      usdVolume: 49487604.75409475,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '2.1401000000000000',
      high: '2.4000000000000000',
      low: '2.0150000000000000',
      close: '2.0884000000000000',
      volume: '24056556.5000000000000000',
      quoteVolume: 51979784.30551739,
      btcVolume: 9676.317809668104,
      usdVolume: 51979784.30551739,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '2.0868000000000000',
      high: '2.0898000000000000',
      low: '1.5571000000000000',
      close: '1.7832000000000000',
      volume: '47175196.2700000000000000',
      quoteVolume: 83730824.5977933,
      btcVolume: 16985.606488035726,
      usdVolume: 83730824.5977933,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '1.7832000000000000',
      high: '2.0690000000000000',
      low: '1.7300000000000000',
      close: '1.8641000000000000',
      volume: '32839078.6800000000000000',
      quoteVolume: 63607268.71138073,
      btcVolume: 12035.406899066662,
      usdVolume: 63607268.71138073,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '1.8660000000000000',
      high: '1.9543000000000000',
      low: '1.7620000000000000',
      close: '1.9483000000000000',
      volume: '22298409.7900000000000000',
      quoteVolume: 41588758.50010696,
      btcVolume: 7903.516934502976,
      usdVolume: 41588758.50010696,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '1.9497000000000000',
      high: '2.3700000000000000',
      low: '1.8841000000000000',
      close: '2.2398000000000000',
      volume: '33319846.3700000000000000',
      quoteVolume: 71956024.81635506,
      btcVolume: 12300.451552067118,
      usdVolume: 71956024.81635506,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '2.2409000000000000',
      high: '2.6249000000000000',
      low: '2.0143000000000000',
      close: '2.2600000000000000',
      volume: '39390658.8700000000000000',
      quoteVolume: 92950604.2035976,
      btcVolume: 14562.188219917904,
      usdVolume: 92950604.2035976,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '2.2631000000000000',
      high: '2.3750000000000000',
      low: '2.1050000000000000',
      close: '2.2787000000000000',
      volume: '19471583.8400000000000000',
      quoteVolume: 43519402.05460915,
      btcVolume: 7045.261058540346,
      usdVolume: 43519402.05460915,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '2.2754000000000000',
      high: '2.3300000000000000',
      low: '1.9334000000000000',
      close: '1.9898000000000000',
      volume: '21634031.6900000000000000',
      quoteVolume: 45651954.71691129,
      btcVolume: 7551.212589732256,
      usdVolume: 45651954.71691129,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '1.9889000000000000',
      high: '2.3000000000000000',
      low: '1.9352000000000000',
      close: '2.2468000000000000',
      volume: '23672246.4900000000000000',
      quoteVolume: 50076329.17784201,
      btcVolume: 8112.687771095752,
      usdVolume: 50076329.17784201,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '2.2470000000000000',
      high: '2.3709000000000000',
      low: '2.1950000000000000',
      close: '2.3104000000000000',
      volume: '18400021.3200000000000000',
      quoteVolume: 42125779.98805816,
      btcVolume: 6347.98486825512,
      usdVolume: 42125779.98805816,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '2.3109000000000000',
      high: '2.3500000000000000',
      low: '2.1804000000000000',
      close: '2.2570000000000000',
      volume: '11321064.3000000000000000',
      quoteVolume: 25603393.54787736,
      btcVolume: 3847.290256854321,
      usdVolume: 25603393.54787736,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '2.2569000000000000',
      high: '2.3132000000000000',
      low: '2.1884000000000000',
      close: '2.3132000000000000',
      volume: '6803251.3800000000000000',
      quoteVolume: 15346687.775324736,
      btcVolume: 2303.7934993235885,
      usdVolume: 15346687.775324736,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '2.3125000000000000',
      high: '2.3599000000000000',
      low: '2.1055000000000000',
      close: '2.1373000000000000',
      volume: '8151561.2000000000000000',
      quoteVolume: 18458235.87850203,
      btcVolume: 2776.0706097775064,
      usdVolume: 18458235.87850203,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '2.1372000000000000',
      high: '2.1544000000000000',
      low: '2.0372000000000000',
      close: '2.1364000000000000',
      volume: '11036739.0800000000000000',
      quoteVolume: 23162230.482438084,
      btcVolume: 3726.428487730779,
      usdVolume: 23162230.482438084,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '2.1356000000000000',
      high: '2.1520000000000000',
      low: '2.0070000000000000',
      close: '2.0083000000000000',
      volume: '6879303.8400000000000000',
      quoteVolume: 14309647.633855054,
      btcVolume: 2356.8649141943242,
      usdVolume: 14309647.633855054,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '2.0083000000000000',
      high: '2.2000000000000000',
      low: '2.0006000000000000',
      close: '2.1350000000000000',
      volume: '7970226.4900000000000000',
      quoteVolume: 16914779.735236812,
      btcVolume: 2698.6373186550286,
      usdVolume: 16914779.735236812,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '2.1348000000000000',
      high: '2.3020000000000000',
      low: '2.1102000000000000',
      close: '2.2658000000000000',
      volume: '8585088.2100000000000000',
      quoteVolume: 18841402.98135908,
      btcVolume: 2922.5256630907675,
      usdVolume: 18841402.98135908,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '2.2657000000000000',
      high: '2.2999000000000000',
      low: '2.1400000000000000',
      close: '2.2753000000000000',
      volume: '13239732.4400000000000000',
      quoteVolume: 29469066.54972005,
      btcVolume: 4669.983786159829,
      usdVolume: 29469066.54972005,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '2.2764000000000000',
      high: '2.4850000000000000',
      low: '2.2566000000000000',
      close: '2.2941000000000000',
      volume: '13266669.3900000000000000',
      quoteVolume: 30798141.032473035,
      btcVolume: 4540.6089277905485,
      usdVolume: 30798141.032473035,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '2.2940000000000000',
      high: '2.3837000000000000',
      low: '2.2652000000000000',
      close: '2.3013000000000000',
      volume: '8452189.4500000000000000',
      quoteVolume: 19628894.276646227,
      btcVolume: 2880.3710594063255,
      usdVolume: 19628894.276646227,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '2.2995000000000000',
      high: '2.3423000000000000',
      low: '2.2545000000000000',
      close: '2.3155000000000000',
      volume: '6117486.0100000000000000',
      quoteVolume: 14001543.18994617,
      btcVolume: 2067.6146389867217,
      usdVolume: 14001543.18994617,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '2.3155000000000000',
      high: '2.3219000000000000',
      low: '2.2437000000000000',
      close: '2.2635000000000000',
      volume: '3228659.2300000000000000',
      quoteVolume: 7361336.519118009,
      btcVolume: 1085.637636025333,
      usdVolume: 7361336.519118009,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '2.2638000000000000',
      high: '2.5677000000000000',
      low: '2.2621000000000000',
      close: '2.5555000000000000',
      volume: '12222925.8100000000000000',
      quoteVolume: 29594871.538146637,
      btcVolume: 4150.693258507703,
      usdVolume: 29594871.538146637,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '2.5556000000000000',
      high: '2.9080000000000000',
      low: '2.5550000000000000',
      close: '2.7303000000000000',
      volume: '30354976.5100000000000000',
      quoteVolume: 83149479.68281008,
      btcVolume: 11365.108305697015,
      usdVolume: 83149479.68281008,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '2.7295000000000000',
      high: '3.2748000000000000',
      low: '2.7124000000000000',
      close: '3.1693000000000000',
      volume: '27092993.2400000000000000',
      quoteVolume: 80619866.26421164,
      btcVolume: 11021.04111756064,
      usdVolume: 80619866.26421164,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '3.1695000000000000',
      high: '3.4741000000000000',
      low: '3.1224000000000000',
      close: '3.3099000000000000',
      volume: '36999763.5100000000000000',
      quoteVolume: 122751896.42489386,
      btcVolume: 16839.37173441571,
      usdVolume: 122751896.42489386,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '3.3099000000000000',
      high: '3.3199000000000000',
      low: '2.9450000000000000',
      close: '3.2200000000000000',
      volume: '43621490.6000000000000000',
      quoteVolume: 136026639.49936587,
      btcVolume: 19554.197753884662,
      usdVolume: 136026639.49936587,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '3.2207000000000000',
      high: '3.4364000000000000',
      low: '3.0629000000000000',
      close: '3.2900000000000000',
      volume: '30471997.5000000000000000',
      quoteVolume: 99435616.49901348,
      btcVolume: 14491.434066489905,
      usdVolume: 99435616.49901348,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '3.2912000000000000',
      high: '3.6412000000000000',
      low: '3.1670000000000000',
      close: '3.4189000000000000',
      volume: '32589266.7900000000000000',
      quoteVolume: 111420529.95980085,
      btcVolume: 15946.446334706492,
      usdVolume: 111420529.95980085,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '3.4117000000000000',
      high: '3.4602000000000000',
      low: '3.2000000000000000',
      close: '3.3702000000000000',
      volume: '29488744.4800000000000000',
      quoteVolume: 99093977.11353326,
      btcVolume: 14706.279591907658,
      usdVolume: 99093977.11353326,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '3.3719000000000000',
      high: '3.4437000000000000',
      low: '3.2100000000000000',
      close: '3.2520000000000000',
      volume: '17078827.0100000000000000',
      quoteVolume: 57101788.37653568,
      btcVolume: 8313.477053450417,
      usdVolume: 57101788.37653568,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '3.2517000000000000',
      high: '3.3061000000000000',
      low: '3.1085000000000000',
      close: '3.1212000000000000',
      volume: '15635873.1100000000000000',
      quoteVolume: 50161624.541744925,
      btcVolume: 7410.73145926265,
      usdVolume: 50161624.541744925,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '3.1212000000000000',
      high: '3.5555000000000000',
      low: '2.9761000000000000',
      close: '3.4851000000000000',
      volume: '24817961.6500000000000000',
      quoteVolume: 81880780.97622721,
      btcVolume: 11818.013830683783,
      usdVolume: 81880780.97622721,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '3.4831000000000000',
      high: '3.5250000000000000',
      low: '3.3778000000000000',
      close: '3.4523000000000000',
      volume: '12669277.2500000000000000',
      quoteVolume: 43776483.22340796,
      btcVolume: 6193.887862135089,
      usdVolume: 43776483.22340796,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '3.4525000000000000',
      high: '3.8300000000000000',
      low: '3.4470000000000000',
      close: '3.7805000000000000',
      volume: '16363948.9700000000000000',
      quoteVolume: 59614221.39959253,
      btcVolume: 8310.66795040822,
      usdVolume: 59614221.39959253,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '3.7814000000000000',
      high: '3.8102000000000000',
      low: '3.5131000000000000',
      close: '3.5902000000000000',
      volume: '16350496.0200000000000000',
      quoteVolume: 59872603.95570334,
      btcVolume: 8367.38354342493,
      usdVolume: 59872603.95570334,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '3.5885000000000000',
      high: '3.7920000000000000',
      low: '3.3720000000000000',
      close: '3.4322000000000000',
      volume: '20550987.6400000000000000',
      quoteVolume: 73812197.67135006,
      btcVolume: 10555.803904890008,
      usdVolume: 73812197.67135006,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '3.4317000000000000',
      high: '3.5719000000000000',
      low: '3.3903000000000000',
      close: '3.4577000000000000',
      volume: '13684724.9900000000000000',
      quoteVolume: 47685948.13373961,
      btcVolume: 6958.109221813893,
      usdVolume: 47685948.13373961,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '3.4593000000000000',
      high: '3.6904000000000000',
      low: '3.4327000000000000',
      close: '3.6786000000000000',
      volume: '11762633.8300000000000000',
      quoteVolume: 42349673.69567189,
      btcVolume: 6054.788459746665,
      usdVolume: 42349673.69567189,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '3.6787000000000000',
      high: '3.9101000000000000',
      low: '3.5968000000000000',
      close: '3.7533000000000000',
      volume: '14126638.7900000000000000',
      quoteVolume: 52991673.114868954,
      btcVolume: 7220.341851272833,
      usdVolume: 52991673.114868954,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '3.7551000000000000',
      high: '3.8874000000000000',
      low: '3.7364000000000000',
      close: '3.7735000000000000',
      volume: '7933872.6600000000000000',
      quoteVolume: 30294109.77665413,
      btcVolume: 4032.0422494074355,
      usdVolume: 30294109.77665413,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '3.7735000000000000',
      high: '3.9062000000000000',
      low: '3.7420000000000000',
      close: '3.7865000000000000',
      volume: '6095069.3800000000000000',
      quoteVolume: 23261065.041254092,
      btcVolume: 3081.0533832217207,
      usdVolume: 23261065.041254092,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '3.7865000000000000',
      high: '3.8160000000000000',
      low: '3.6432000000000000',
      close: '3.7210000000000000',
      volume: '8246658.1300000000000000',
      quoteVolume: 30808734.632196084,
      btcVolume: 4057.4729534536946,
      usdVolume: 30808734.632196084,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '3.7212000000000000',
      high: '3.7385000000000000',
      low: '3.4621000000000000',
      close: '3.6383000000000000',
      volume: '11371929.8600000000000000',
      quoteVolume: 40891564.50235291,
      btcVolume: 5313.060933220702,
      usdVolume: 40891564.50235291,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '3.6373000000000000',
      high: '3.7354000000000000',
      low: '3.5554000000000000',
      close: '3.6413000000000000',
      volume: '7379317.2300000000000000',
      quoteVolume: 26928714.47205683,
      btcVolume: 3488.09599283931,
      usdVolume: 26928714.47205683,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '3.6422000000000000',
      high: '3.9918000000000000',
      low: '3.6300000000000000',
      close: '3.8879000000000000',
      volume: '15125283.5400000000000000',
      quoteVolume: 57370181.28630435,
      btcVolume: 6832.774464426143,
      usdVolume: 57370181.28630435,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '3.8881000000000000',
      high: '3.9741000000000000',
      low: '3.6200000000000000',
      close: '3.7025000000000000',
      volume: '13545136.0200000000000000',
      quoteVolume: 51376800.29429678,
      btcVolume: 5766.507587671276,
      usdVolume: 51376800.29429678,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '3.7014000000000000',
      high: '3.9162000000000000',
      low: '3.7003000000000000',
      close: '3.7571000000000000',
      volume: '8084328.3600000000000000',
      quoteVolume: 30928293.222303115,
      btcVolume: 3519.057180538519,
      usdVolume: 30928293.222303115,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '3.7571000000000000',
      high: '3.8600000000000000',
      low: '3.7300000000000000',
      close: '3.8182000000000000',
      volume: '4467208.0100000000000000',
      quoteVolume: 16996396.923116993,
      btcVolume: 1915.6064803541883,
      usdVolume: 16996396.923116993,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '3.8186000000000000',
      high: '3.8600000000000000',
      low: '3.6379000000000000',
      close: '3.7092000000000000',
      volume: '5554731.5200000000000000',
      quoteVolume: 20831570.33686512,
      btcVolume: 2325.320369499448,
      usdVolume: 20831570.33686512,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '3.7098000000000000',
      high: '3.8272000000000000',
      low: '3.5033000000000000',
      close: '3.7499000000000000',
      volume: '10973722.2700000000000000',
      quoteVolume: 40395738.452246346,
      btcVolume: 4611.440780167115,
      usdVolume: 40395738.452246346,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '3.7496000000000000',
      high: '3.8140000000000000',
      low: '3.6540000000000000',
      close: '3.6833000000000000',
      volume: '4730504.5800000000000000',
      quoteVolume: 17621530.537070103,
      btcVolume: 1975.2075137716056,
      usdVolume: 17621530.537070103,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '3.6820000000000000',
      high: '3.7654000000000000',
      low: '3.5700000000000000',
      close: '3.5872000000000000',
      volume: '5299802.5900000000000000',
      quoteVolume: 19570134.054998033,
      btcVolume: 2138.6881860809644,
      usdVolume: 19570134.054998033,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '3.5874000000000000',
      high: '3.8500000000000000',
      low: '3.5633000000000000',
      close: '3.7560000000000000',
      volume: '8481271.5900000000000000',
      quoteVolume: 31361055.942226164,
      btcVolume: 3257.382561578943,
      usdVolume: 31361055.942226164,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '3.7580000000000000',
      high: '3.8590000000000000',
      low: '3.6874000000000000',
      close: '3.8010000000000000',
      volume: '7383724.4800000000000000',
      quoteVolume: 27986549.846176166,
      btcVolume: 2821.090111412898,
      usdVolume: 27986549.846176166,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '3.8001000000000000',
      high: '4.2129000000000000',
      low: '3.7601000000000000',
      close: '4.0150000000000000',
      volume: '15240721.6300000000000000',
      quoteVolume: 61673011.98541812,
      btcVolume: 6356.310945841886,
      usdVolume: 61673011.98541812,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '4.0149000000000000',
      high: '4.0197000000000000',
      low: '3.4602000000000000',
      close: '3.7582000000000000',
      volume: '19818388.7300000000000000',
      quoteVolume: 73819362.15594393,
      btcVolume: 8521.796803966217,
      usdVolume: 73819362.15594393,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '3.7574000000000000',
      high: '3.8320000000000000',
      low: '3.2865000000000000',
      close: '3.5837000000000000',
      volume: '13273873.6200000000000000',
      quoteVolume: 47852980.089774795,
      btcVolume: 5547.514411645623,
      usdVolume: 47852980.089774795,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '3.5838000000000000',
      high: '3.7932000000000000',
      low: '3.5400000000000000',
      close: '3.6943000000000000',
      volume: '7797486.4300000000000000',
      quoteVolume: 28881156.065596662,
      btcVolume: 3297.963204756944,
      usdVolume: 28881156.065596662,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '3.6938000000000000',
      high: '3.8150000000000000',
      low: '3.6366000000000000',
      close: '3.7655000000000000',
      volume: '5949203.5300000000000000',
      quoteVolume: 22154224.568957023,
      btcVolume: 2444.9070553685347,
      usdVolume: 22154224.568957023,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '3.7654000000000000',
      high: '3.8583000000000000',
      low: '3.6947000000000000',
      close: '3.8096000000000000',
      volume: '7333655.8100000000000000',
      quoteVolume: 27791822.366877846,
      btcVolume: 2891.52904194716,
      usdVolume: 27791822.366877846,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '3.8093000000000000',
      high: '3.8337000000000000',
      low: '3.6144000000000000',
      close: '3.6615000000000000',
      volume: '5571782.8800000000000000',
      quoteVolume: 20831712.993012883,
      btcVolume: 2197.771365437242,
      usdVolume: 20831712.993012883,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '3.6626000000000000',
      high: '3.7900000000000000',
      low: '3.6168000000000000',
      close: '3.7614000000000000',
      volume: '4045380.2000000000000000',
      quoteVolume: 15074632.829739979,
      btcVolume: 1604.6960921741713,
      usdVolume: 15074632.829739979,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '3.7621000000000000',
      high: '3.8524000000000000',
      low: '3.7526000000000000',
      close: '3.7815000000000000',
      volume: '3630692.0900000000000000',
      quoteVolume: 13812656.339239048,
      btcVolume: 1432.957547933766,
      usdVolume: 13812656.339239048,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '3.7815000000000000',
      high: '3.9500000000000000',
      low: '3.7786000000000000',
      close: '3.8895000000000000',
      volume: '5739644.8600000000000000',
      quoteVolume: 22305115.00685797,
      btcVolume: 2287.439967086519,
      usdVolume: 22305115.00685797,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '3.8895000000000000',
      high: '3.9488000000000000',
      low: '3.7734000000000000',
      close: '3.9470000000000000',
      volume: '3765966.9300000000000000',
      quoteVolume: 14542942.326336175,
      btcVolume: 1503.244995161628,
      usdVolume: 14542942.326336175,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '3.9466000000000000',
      high: '4.1388000000000000',
      low: '3.8955000000000000',
      close: '4.0866000000000000',
      volume: '9805842.1600000000000000',
      quoteVolume: 39677537.53178006,
      btcVolume: 4109.400460965454,
      usdVolume: 39677537.53178006,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '4.0880000000000000',
      high: '4.1740000000000000',
      low: '3.7292000000000000',
      close: '3.8298000000000000',
      volume: '10446021.8600000000000000',
      quoteVolume: 41318047.346202776,
      btcVolume: 4472.548344593976,
      usdVolume: 41318047.346202776,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '3.8307000000000000',
      high: '4.1447000000000000',
      low: '3.7932000000000000',
      close: '4.0611000000000000',
      volume: '7194944.8900000000000000',
      quoteVolume: 28640710.818571955,
      btcVolume: 3133.076928130972,
      usdVolume: 28640710.818571955,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '4.0620000000000000',
      high: '4.1072000000000000',
      low: '3.9100000000000000',
      close: '3.9250000000000000',
      volume: '5865674.1500000000000000',
      quoteVolume: 23402699.08869481,
      btcVolume: 2542.731357186535,
      usdVolume: 23402699.08869481,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '3.9242000000000000',
      high: '4.0262000000000000',
      low: '3.7844000000000000',
      close: '3.7917000000000000',
      volume: '6229925.9300000000000000',
      quoteVolume: 24355874.593129028,
      btcVolume: 2685.7880060204666,
      usdVolume: 24355874.593129028,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '3.7878000000000000',
      high: '3.9073000000000000',
      low: '3.7700000000000000',
      close: '3.8614000000000000',
      volume: '4443996.5100000000000000',
      quoteVolume: 17123412.802925922,
      btcVolume: 1942.855709479334,
      usdVolume: 17123412.802925922,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '3.8634000000000000',
      high: '3.8816000000000000',
      low: '3.7689000000000000',
      close: '3.8440000000000000',
      volume: '3774200.8300000000000000',
      quoteVolume: 14457491.492909947,
      btcVolume: 1633.177692310847,
      usdVolume: 14457491.492909947,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '3.8436000000000000',
      high: '3.9143000000000000',
      low: '3.8065000000000000',
      close: '3.8526000000000000',
      volume: '3793553.8500000000000000',
      quoteVolume: 14669023.347105095,
      btcVolume: 1621.3588858213739,
      usdVolume: 14669023.347105095,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '3.8542000000000000',
      high: '4.0601000000000000',
      low: '3.8270000000000000',
      close: '4.0461000000000000',
      volume: '4242354.5900000000000000',
      quoteVolume: 16636880.490730118,
      btcVolume: 1771.4352825657559,
      usdVolume: 16636880.490730118,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '4.0462000000000000',
      high: '4.1300000000000000',
      low: '3.9488000000000000',
      close: '3.9800000000000000',
      volume: '5242842.6700000000000000',
      quoteVolume: 21161407.756916173,
      btcVolume: 2231.9952818631095,
      usdVolume: 21161407.756916173,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '3.9820000000000000',
      high: '4.1800000000000000',
      low: '3.9430000000000000',
      close: '4.1763000000000000',
      volume: '4734058.3400000000000000',
      quoteVolume: 19356855.90212316,
      btcVolume: 2030.7654975998275,
      usdVolume: 19356855.90212316,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '4.1780000000000000',
      high: '4.2838000000000000',
      low: '4.0410000000000000',
      close: '4.1310000000000000',
      volume: '6223457.9900000000000000',
      quoteVolume: 25986422.58422777,
      btcVolume: 2723.684714084976,
      usdVolume: 25986422.58422777,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '4.1278000000000000',
      high: '4.4800000000000000',
      low: '4.1175000000000000',
      close: '4.3785000000000000',
      volume: '8703425.4700000000000000',
      quoteVolume: 38003418.52632179,
      btcVolume: 3946.2706304533644,
      usdVolume: 38003418.52632179,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '4.3795000000000000',
      high: '4.6185000000000000',
      low: '4.1075000000000000',
      close: '4.3976000000000000',
      volume: '8147770.0700000000000000',
      quoteVolume: 35881970.22012894,
      btcVolume: 3668.4636094846724,
      usdVolume: 35881970.22012894,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '4.3987000000000000',
      high: '4.5300000000000000',
      low: '4.2898000000000000',
      close: '4.4892000000000000',
      volume: '4168765.8100000000000000',
      quoteVolume: 18467942.894892167,
      btcVolume: 1933.344668741359,
      usdVolume: 18467942.894892167,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '4.4881000000000000',
      high: '4.5081000000000000',
      low: '4.2864000000000000',
      close: '4.4243000000000000',
      volume: '3231902.9900000000000000',
      quoteVolume: 14276426.128533928,
      btcVolume: 1476.4285848581262,
      usdVolume: 14276426.128533928,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '4.4263000000000000',
      high: '4.4860000000000000',
      low: '4.3405000000000000',
      close: '4.3580000000000000',
      volume: '2314891.8700000000000000',
      quoteVolume: 10216880.887104046,
      btcVolume: 1049.6536854309381,
      usdVolume: 10216880.887104046,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '4.3569000000000000',
      high: '4.3979000000000000',
      low: '4.3034000000000000',
      close: '4.3512000000000000',
      volume: '1153869.9600000000000000',
      quoteVolume: 5020633.317667975,
      btcVolume: 521.0659599515068,
      usdVolume: 5020633.317667975,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '4.3513000000000000',
      high: '4.4100000000000000',
      low: '4.2068000000000000',
      close: '4.3465000000000000',
      volume: '2137522.0500000000000000',
      quoteVolume: 9211491.186056014,
      btcVolume: 960.8098153273933,
      usdVolume: 9211491.186056014,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '4.3487000000000000',
      high: '4.4759000000000000',
      low: '4.3250000000000000',
      close: '4.4730000000000000',
      volume: '2037163.2100000000000000',
      quoteVolume: 8929343.506046021,
      btcVolume: 918.8751048356538,
      usdVolume: 8929343.506046021,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '4.4753000000000000',
      high: '4.5062000000000000',
      low: '4.3605000000000000',
      close: '4.4350000000000000',
      volume: '2250839.6700000000000000',
      quoteVolume: 9985584.222381042,
      btcVolume: 1027.6679495159528,
      usdVolume: 9985584.222381042,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '4.4374000000000000',
      high: '4.4404000000000000',
      low: '4.3426000000000000',
      close: '4.4225000000000000',
      volume: '2229011.4100000000000000',
      quoteVolume: 9773177.039550047,
      btcVolume: 997.9263921227159,
      usdVolume: 9773177.039550047,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '4.4207000000000000',
      high: '4.4503000000000000',
      low: '3.9100000000000000',
      close: '3.9565000000000000',
      volume: '6568688.8700000000000000',
      quoteVolume: 27115697.81298312,
      btcVolume: 2861.0421903797023,
      usdVolume: 27115697.81298312,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '3.9530000000000000',
      high: '4.1874000000000000',
      low: '3.8573000000000000',
      close: '4.0819000000000000',
      volume: '4751891.2300000000000000',
      quoteVolume: 19339118.373514097,
      btcVolume: 2057.689856681987,
      usdVolume: 19339118.373514097,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '4.0807000000000000',
      high: '4.1346000000000000',
      low: '4.0054000000000000',
      close: '4.1100000000000000',
      volume: '1604660.7400000000000000',
      quoteVolume: 6524511.677829004,
      btcVolume: 692.1491678856813,
      usdVolume: 6524511.677829004,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '4.1100000000000000',
      high: '4.1134000000000000',
      low: '3.9143000000000000',
      close: '3.9371000000000000',
      volume: '1710213.3600000000000000',
      quoteVolume: 6841578.031834006,
      btcVolume: 729.0393181477964,
      usdVolume: 6841578.031834006,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '3.9395000000000000',
      high: '3.9889000000000000',
      low: '3.6551000000000000',
      close: '3.9274000000000000',
      volume: '6850306.1100000000000000',
      quoteVolume: 26068942.977982927,
      btcVolume: 2826.528626557364,
      usdVolume: 26068942.977982927,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '3.9254000000000000',
      high: '4.0918000000000000',
      low: '3.8935000000000000',
      close: '4.0502000000000000',
      volume: '3614037.3300000000000000',
      quoteVolume: 14515402.500366056,
      btcVolume: 1528.8235581573408,
      usdVolume: 14515402.500366056,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '4.0502000000000000',
      high: '4.2000000000000000',
      low: '3.9570000000000000',
      close: '4.1659000000000000',
      volume: '4301280.9800000000000000',
      quoteVolume: 17558113.834275063,
      btcVolume: 1862.55704310758,
      usdVolume: 17558113.834275063,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '4.1663000000000000',
      high: '4.2491000000000000',
      low: '4.0957000000000000',
      close: '4.1415000000000000',
      volume: '3348384.0800000000000000',
      quoteVolume: 13969700.00507494,
      btcVolume: 1483.9403280382335,
      usdVolume: 13969700.00507494,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '4.1417000000000000',
      high: '4.1839000000000000',
      low: '4.0521000000000000',
      close: '4.0980000000000000',
      volume: '2424386.7000000000000000',
      quoteVolume: 9970938.73032103,
      btcVolume: 1068.115770950031,
      usdVolume: 9970938.73032103,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '4.0978000000000000',
      high: '4.2072000000000000',
      low: '4.0654000000000000',
      close: '4.1765000000000000',
      volume: '2414698.6200000000000000',
      quoteVolume: 10014907.932097934,
      btcVolume: 1076.6564266730459,
      usdVolume: 10014907.932097934,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '4.1762000000000000',
      high: '4.2058000000000000',
      low: '4.1395000000000000',
      close: '4.1812000000000000',
      volume: '1336859.5000000000000000',
      quoteVolume: 5582676.14346401,
      btcVolume: 596.8254803691216,
      usdVolume: 5582676.14346401,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '4.1819000000000000',
      high: '4.4859000000000000',
      low: '4.1760000000000000',
      close: '4.4841000000000000',
      volume: '4636790.2800000000000000',
      quoteVolume: 20238493.440281935,
      btcVolume: 2131.3300159503665,
      usdVolume: 20238493.440281935,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '4.4840000000000000',
      high: '4.9422000000000000',
      low: '4.4254000000000000',
      close: '4.8051000000000000',
      volume: '10884153.7200000000000000',
      quoteVolume: 51489688.58105523,
      btcVolume: 5341.591224643085,
      usdVolume: 51489688.58105523,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '4.8061000000000000',
      high: '4.9411000000000000',
      low: '4.4986000000000000',
      close: '4.7075000000000000',
      volume: '10253258.2100000000000000',
      quoteVolume: 48438749.91706304,
      btcVolume: 5137.643671618544,
      usdVolume: 48438749.91706304,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '4.7075000000000000',
      high: '4.8407000000000000',
      low: '4.5033000000000000',
      close: '4.8134000000000000',
      volume: '7023055.3700000000000000',
      quoteVolume: 33164952.91921413,
      btcVolume: 3591.85419221288,
      usdVolume: 33164952.91921413,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '4.8129000000000000',
      high: '4.8587000000000000',
      low: '4.6424000000000000',
      close: '4.6813000000000000',
      volume: '5531129.1300000000000000',
      quoteVolume: 26116409.53864893,
      btcVolume: 2843.866240257118,
      usdVolume: 26116409.53864893,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '4.6830000000000000',
      high: '4.6957000000000000',
      low: '4.3000000000000000',
      close: '4.4058000000000000',
      volume: '6165533.0500000000000000',
      quoteVolume: 27809003.537887093,
      btcVolume: 3060.9526617884444,
      usdVolume: 27809003.537887093,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '4.4051000000000000',
      high: '4.6200000000000000',
      low: '4.3647000000000000',
      close: '4.5668000000000000',
      volume: '4751931.7400000000000000',
      quoteVolume: 21438525.93253806,
      btcVolume: 2359.635570929396,
      usdVolume: 21438525.93253806,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '4.5658000000000000',
      high: '4.6500000000000000',
      low: '4.4232000000000000',
      close: '4.6187000000000000',
      volume: '4789663.3200000000000000',
      quoteVolume: 21719855.120023914,
      btcVolume: 2382.4340020200825,
      usdVolume: 21719855.120023914,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '4.6192000000000000',
      high: '4.6622000000000000',
      low: '4.5337000000000000',
      close: '4.5626000000000000',
      volume: '3001636.3100000000000000',
      quoteVolume: 13817102.521447966,
      btcVolume: 1509.948465482142,
      usdVolume: 13817102.521447966,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '4.5625000000000000',
      high: '4.7300000000000000',
      low: '4.5217000000000000',
      close: '4.7067000000000000',
      volume: '4059427.1700000000000000',
      quoteVolume: 18803296.868452046,
      btcVolume: 2044.3470835901742,
      usdVolume: 18803296.868452046,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '4.7068000000000000',
      high: '4.9181000000000000',
      low: '4.6025000000000000',
      close: '4.7996000000000000',
      volume: '7638387.3500000000000000',
      quoteVolume: 36415952.50593107,
      btcVolume: 3981.9894097602037,
      usdVolume: 36415952.50593107,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '4.7998000000000000',
      high: '4.8964000000000000',
      low: '4.7031000000000000',
      close: '4.7309000000000000',
      volume: '4456413.0000000000000000',
      quoteVolume: 21519728.981386155,
      btcVolume: 2366.7125707992577,
      usdVolume: 21519728.981386155,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '4.7300000000000000',
      high: '4.8539000000000000',
      low: '4.7186000000000000',
      close: '4.8200000000000000',
      volume: '2535114.5100000000000000',
      quoteVolume: 12133983.302445995,
      btcVolume: 1333.81578228134,
      usdVolume: 12133983.302445995,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '4.8198000000000000',
      high: '4.8325000000000000',
      low: '4.6118000000000000',
      close: '4.7439000000000000',
      volume: '3420895.3700000000000000',
      quoteVolume: 16145214.992928915,
      btcVolume: 1782.6229066425183,
      usdVolume: 16145214.992928915,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '4.7426000000000000',
      high: '5.5000000000000000',
      low: '4.7362000000000000',
      close: '5.3617000000000000',
      volume: '12032948.9700000000000000',
      quoteVolume: 61715137.636092246,
      btcVolume: 6663.492972858207,
      usdVolume: 61715137.636092246,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '5.3613000000000000',
      high: '5.8890000000000000',
      low: '5.3300000000000000',
      close: '5.7248000000000000',
      volume: '13783142.2200000000000000',
      quoteVolume: 77491639.85352772,
      btcVolume: 8361.43667342537,
      usdVolume: 77491639.85352772,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '5.7245000000000000',
      high: '6.5607000000000000',
      low: '5.5920000000000000',
      close: '6.4987000000000000',
      volume: '15825025.1900000000000000',
      quoteVolume: 97337955.14051247,
      btcVolume: 10432.903935303775,
      usdVolume: 97337955.14051247,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '6.5001000000000000',
      high: '6.5006000000000000',
      low: '5.7029000000000000',
      close: '6.0820000000000000',
      volume: '12942475.7400000000000000',
      quoteVolume: 78848343.82277149,
      btcVolume: 8463.213815528254,
      usdVolume: 78848343.82277149,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '6.0820000000000000',
      high: '6.2009000000000000',
      low: '5.7096000000000000',
      close: '6.1488000000000000',
      volume: '9932051.9600000000000000',
      quoteVolume: 59118518.95393981,
      btcVolume: 6423.877893463667,
      usdVolume: 59118518.95393981,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '6.1500000000000000',
      high: '6.2661000000000000',
      low: '5.9610000000000000',
      close: '6.0994000000000000',
      volume: '5180049.0300000000000000',
      quoteVolume: 31685675.96767919,
      btcVolume: 3429.120643940731,
      usdVolume: 31685675.96767919,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '6.1043000000000000',
      high: '7.3898000000000000',
      low: '6.0975000000000000',
      close: '7.3345000000000000',
      volume: '18949318.6200000000000000',
      quoteVolume: 128119147.50220707,
      btcVolume: 13858.753628207221,
      usdVolume: 128119147.50220707,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '7.3447000000000000',
      high: '8.4800000000000000',
      low: '6.8525000000000000',
      close: '7.1630000000000000',
      volume: '30737550.9600000000000000',
      quoteVolume: 235671215.53400916,
      btcVolume: 25398.30633539493,
      usdVolume: 235671215.53400916,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '7.1616000000000000',
      high: '8.3446000000000000',
      low: '6.9650000000000000',
      close: '8.1321000000000000',
      volume: '25153380.4100000000000000',
      quoteVolume: 192793205.38648593,
      btcVolume: 20888.78682809465,
      usdVolume: 192793205.38648593,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '8.1315000000000000',
      high: '8.9080000000000000',
      low: '7.8309000000000000',
      close: '8.6494000000000000',
      volume: '20518883.2200000000000000',
      quoteVolume: 172454027.970733,
      btcVolume: 18690.633753627804,
      usdVolume: 172454027.970733,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '8.6486000000000000',
      high: '8.8822000000000000',
      low: '7.9427000000000000',
      close: '8.3540000000000000',
      volume: '20705851.7900000000000000',
      quoteVolume: 173628619.95218885,
      btcVolume: 19005.124013284785,
      usdVolume: 173628619.95218885,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '8.3545000000000000',
      high: '8.6444000000000000',
      low: '8.2000000000000000',
      close: '8.2605000000000000',
      volume: '8580583.8800000000000000',
      quoteVolume: 71891358.28923588,
      btcVolume: 7867.034349896577,
      usdVolume: 71891358.28923588,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '8.2589000000000000',
      high: '8.2648000000000000',
      low: '7.6570000000000000',
      close: '7.9734000000000000',
      volume: '11416632.8500000000000000',
      quoteVolume: 90986110.87730221,
      btcVolume: 9936.17044525862,
      usdVolume: 90986110.87730221,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '7.9731000000000000',
      high: '8.2100000000000000',
      low: '7.8006000000000000',
      close: '8.0784000000000000',
      volume: '5325842.7900000000000000',
      quoteVolume: 42588167.82897199,
      btcVolume: 4648.695039778068,
      usdVolume: 42588167.82897199,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '8.0848000000000000',
      high: '8.1388000000000000',
      low: '7.1210000000000000',
      close: '7.2301000000000000',
      volume: '11024362.8200000000000000',
      quoteVolume: 84110745.40996003,
      btcVolume: 9169.302612989131,
      usdVolume: 84110745.40996003,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '7.2301000000000000',
      high: '7.6788000000000000',
      low: '6.9232000000000000',
      close: '7.3101000000000000',
      volume: '10932081.1000000000000000',
      quoteVolume: 80003692.44480799,
      btcVolume: 8576.725504872806,
      usdVolume: 80003692.44480799,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '7.3086000000000000',
      high: '7.6365000000000000',
      low: '7.0832000000000000',
      close: '7.4689000000000000',
      volume: '9596016.7400000000000000',
      quoteVolume: 70863239.4579672,
      btcVolume: 7572.310124975652,
      usdVolume: 70863239.4579672,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '7.4690000000000000',
      high: '8.0990000000000000',
      low: '7.4029000000000000',
      close: '7.8983000000000000',
      volume: '10915322.8800000000000000',
      quoteVolume: 85588641.40242985,
      btcVolume: 8986.708733063164,
      usdVolume: 85588641.40242985,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '7.8982000000000000',
      high: '7.9304000000000000',
      low: '7.3840000000000000',
      close: '7.4284000000000000',
      volume: '7313843.7500000000000000',
      quoteVolume: 55866119.15418281,
      btcVolume: 5854.581041359676,
      usdVolume: 55866119.15418281,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '7.4284000000000000',
      high: '7.8009000000000000',
      low: '7.3744000000000000',
      close: '7.6720000000000000',
      volume: '7502502.4600000000000000',
      quoteVolume: 57265634.06685721,
      btcVolume: 5964.891413603993,
      usdVolume: 57265634.06685721,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '7.6737000000000000',
      high: '7.9450000000000000',
      low: '7.1885000000000000',
      close: '7.5022000000000000',
      volume: '10637660.3200000000000000',
      quoteVolume: 80431491.35325707,
      btcVolume: 8167.326551727266,
      usdVolume: 80431491.35325707,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '7.5009000000000000',
      high: '7.5045000000000000',
      low: '6.5101000000000000',
      close: '7.1000000000000000',
      volume: '16680558.0400000000000000',
      quoteVolume: 117110382.73523542,
      btcVolume: 11213.359323082714,
      usdVolume: 117110382.73523542,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '7.0990000000000000',
      high: '7.4243000000000000',
      low: '6.9106000000000000',
      close: '7.2333000000000000',
      volume: '8500726.8300000000000000',
      quoteVolume: 60812597.213252656,
      btcVolume: 5549.91287596061,
      usdVolume: 60812597.213252656,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '7.2332000000000000',
      high: '7.4871000000000000',
      low: '6.9867000000000000',
      close: '7.0876000000000000',
      volume: '7058913.7900000000000000',
      quoteVolume: 50881041.657357685,
      btcVolume: 4599.08400376134,
      usdVolume: 50881041.657357685,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '7.0876000000000000',
      high: '7.5757000000000000',
      low: '7.0253000000000000',
      close: '7.4093000000000000',
      volume: '7667819.8300000000000000',
      quoteVolume: 56146316.3170551,
      btcVolume: 5092.87952074015,
      usdVolume: 56146316.3170551,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '7.4093000000000000',
      high: '7.8649000000000000',
      low: '7.4085000000000000',
      close: '7.8033000000000000',
      volume: '7419454.2600000000000000',
      quoteVolume: 57010133.853312016,
      btcVolume: 5107.431794695363,
      usdVolume: 57010133.853312016,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '7.8025000000000000',
      high: '8.4720000000000000',
      low: '7.6373000000000000',
      close: '8.3248000000000000',
      volume: '9345076.4000000000000000',
      quoteVolume: 74293026.60700549,
      btcVolume: 6369.51346066485,
      usdVolume: 74293026.60700549,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '8.3216000000000000',
      high: '8.7972000000000000',
      low: '6.8700000000000000',
      close: '8.2814000000000000',
      volume: '22647614.3400000000000000',
      quoteVolume: 187308467.6520541,
      btcVolume: 16618.54057221337,
      usdVolume: 187308467.6520541,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '8.2851000000000000',
      high: '9.2707000000000000',
      low: '8.1408000000000000',
      close: '9.2502000000000000',
      volume: '12805982.5400000000000000',
      quoteVolume: 110080414.61075139,
      btcVolume: 9792.377399999354,
      usdVolume: 110080414.61075139,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '9.2502000000000000',
      high: '9.9645000000000000',
      low: '9.0807000000000000',
      close: '9.8687000000000000',
      volume: '18460200.9900000000000000',
      quoteVolume: 175203350.75301245,
      btcVolume: 15588.926012531812,
      usdVolume: 175203350.75301245,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '9.8686000000000000',
      high: '9.8686000000000000',
      low: '9.3207000000000000',
      close: '9.5253000000000000',
      volume: '9682088.7700000000000000',
      quoteVolume: 92516426.18121561,
      btcVolume: 8087.5667816474515,
      usdVolume: 92516426.18121561,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '9.5265000000000000',
      high: '10.2917000000000000',
      low: '9.4515000000000000',
      close: '10.2343000000000000',
      volume: '9041552.6000000000000000',
      quoteVolume: 88144051.28129894,
      btcVolume: 7505.910950203218,
      usdVolume: 88144051.28129894,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '10.2284000000000000',
      high: '10.2615000000000000',
      low: '9.0500000000000000',
      close: '10.1286000000000000',
      volume: '11733225.3400000000000000',
      quoteVolume: 115568472.9989462,
      btcVolume: 9912.375472640897,
      usdVolume: 115568472.9989462,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '10.1286000000000000',
      high: '13.8799000000000000',
      low: '9.9287000000000000',
      close: '12.7093000000000000',
      volume: '28542792.8400000000000000',
      quoteVolume: 342944887.19930977,
      btcVolume: 29343.8183945227,
      usdVolume: 342944887.19930977,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '12.7095000000000000',
      high: '14.4586000000000000',
      low: '12.0000000000000000',
      close: '13.8104000000000000',
      volume: '28201686.3100000000000000',
      quoteVolume: 376769344.70101273,
      btcVolume: 32361.99931890226,
      usdVolume: 376769344.70101273,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '13.8105000000000000',
      high: '14.0551000000000000',
      low: '12.5200000000000000',
      close: '13.4722000000000000',
      volume: '17910767.8200000000000000',
      quoteVolume: 240411392.94454074,
      btcVolume: 20238.132402655556,
      usdVolume: 240411392.94454074,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '13.4722000000000000',
      high: '13.5403000000000000',
      low: '12.0905000000000000',
      close: '12.9971000000000000',
      volume: '17514622.4200000000000000',
      quoteVolume: 225363799.55548632,
      btcVolume: 19493.751301956687,
      usdVolume: 225363799.55548632,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '12.9999000000000000',
      high: '16.8000000000000000',
      low: '12.5936000000000000',
      close: '16.4635000000000000',
      volume: '23113249.4000000000000000',
      quoteVolume: 342110085.2548909,
      btcVolume: 29740.856429109834,
      usdVolume: 342110085.2548909,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '16.4634000000000000',
      high: '18.3488000000000000',
      low: '15.9175000000000000',
      close: '17.1883000000000000',
      volume: '30934675.5500000000000000',
      quoteVolume: 527151580.26604337,
      btcVolume: 45706.95238937064,
      usdVolume: 527151580.26604337,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '17.1728000000000000',
      high: '17.5998000000000000',
      low: '16.3000000000000000',
      close: '16.9574000000000000',
      volume: '11109883.8400000000000000',
      quoteVolume: 188010113.5259645,
      btcVolume: 16024.502767051485,
      usdVolume: 188010113.5259645,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '16.9447000000000000',
      high: '19.8303000000000000',
      low: '16.5554000000000000',
      close: '19.2285000000000000',
      volume: '17941112.0100000000000000',
      quoteVolume: 330894146.757813,
      btcVolume: 27921.28371563732,
      usdVolume: 330894146.757813,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '19.2108000000000000',
      high: '20.1111000000000000',
      low: '18.5144000000000000',
      close: '18.8061000000000000',
      volume: '10757840.9900000000000000',
      quoteVolume: 206509366.21838295,
      btcVolume: 17444.9116283756,
      usdVolume: 206509366.21838295,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '18.8096000000000000',
      high: '19.7111000000000000',
      low: '15.5000000000000000',
      close: '16.8196000000000000',
      volume: '13542369.7900000000000000',
      quoteVolume: 248379200.46697778,
      btcVolume: 20504.962867936094,
      usdVolume: 248379200.46697778,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '16.8064000000000000',
      high: '17.6738000000000000',
      low: '14.6900000000000000',
      close: '16.1927000000000000',
      volume: '27088057.7600000000000000',
      quoteVolume: 445332598.9067832,
      btcVolume: 36628.32089159984,
      usdVolume: 445332598.9067832,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '16.1846000000000000',
      high: '17.2554000000000000',
      low: '14.8926000000000000',
      close: '16.1863000000000000',
      volume: '18358817.9100000000000000',
      quoteVolume: 297260255.6285172,
      btcVolume: 25207.258992200754,
      usdVolume: 297260255.6285172,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '16.1884000000000000',
      high: '16.7862000000000000',
      low: '15.7232000000000000',
      close: '16.0483000000000000',
      volume: '11011575.0900000000000000',
      quoteVolume: 179321055.27822992,
      btcVolume: 15196.345359291849,
      usdVolume: 179321055.27822992,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '16.0476000000000000',
      high: '16.1421000000000000',
      low: '12.8900000000000000',
      close: '13.8325000000000000',
      volume: '26139289.1300000000000000',
      quoteVolume: 376332110.8896587,
      btcVolume: 32120.329513780653,
      usdVolume: 376332110.8896587,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '13.8342000000000000',
      high: '16.3300000000000000',
      low: '13.6600000000000000',
      close: '15.8078000000000000',
      volume: '16709823.0900000000000000',
      quoteVolume: 252784283.01039815,
      btcVolume: 21872.182565912786,
      usdVolume: 252784283.01039815,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '15.8085000000000000',
      high: '16.0042000000000000',
      low: '14.6536000000000000',
      close: '15.1428000000000000',
      volume: '10765015.8300000000000000',
      quoteVolume: 163456993.92262277,
      btcVolume: 14082.112398712841,
      usdVolume: 163456993.92262277,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '15.1572000000000000',
      high: '15.8797000000000000',
      low: '14.7000000000000000',
      close: '15.1419000000000000',
      volume: '8454928.0700000000000000',
      quoteVolume: 129187194.20472585,
      btcVolume: 11012.764821572282,
      usdVolume: 129187194.20472585,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '15.1363000000000000',
      high: '15.3743000000000000',
      low: '13.4578000000000000',
      close: '14.1164000000000000',
      volume: '11988432.8700000000000000',
      quoteVolume: 173378967.9258536,
      btcVolume: 15132.48523268232,
      usdVolume: 173378967.9258536,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '14.1171000000000000',
      high: '15.6664000000000000',
      low: '14.0537000000000000',
      close: '15.2000000000000000',
      volume: '11415333.4100000000000000',
      quoteVolume: 169326699.33486208,
      btcVolume: 14838.375991579402,
      usdVolume: 169326699.33486208,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '15.2088000000000000',
      high: '15.5215000000000000',
      low: '13.9679000000000000',
      close: '14.5234000000000000',
      volume: '11685135.1900000000000000',
      quoteVolume: 173265514.72794762,
      btcVolume: 15276.438417623027,
      usdVolume: 173265514.72794762,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '14.5234000000000000',
      high: '15.4989000000000000',
      low: '14.2360000000000000',
      close: '15.1207000000000000',
      volume: '7108908.2300000000000000',
      quoteVolume: 106217471.48299323,
      btcVolume: 9283.514516201361,
      usdVolume: 106215608.60329323,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '15.1136000000000000',
      high: '17.0305000000000000',
      low: '14.9565000000000000',
      close: '16.4273000000000000',
      volume: '11734386.8700000000000000',
      quoteVolume: 189641391.5824856,
      btcVolume: 16492.39931867633,
      usdVolume: 189641391.5824856,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '16.4537000000000000',
      high: '17.7777000000000000',
      low: '16.4037000000000000',
      close: '16.4788000000000000',
      volume: '9731994.3200000000000000',
      quoteVolume: 166124406.2667331,
      btcVolume: 14311.349188271188,
      usdVolume: 166124406.2667331,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '16.4782000000000000',
      high: '16.9495000000000000',
      low: '15.4451000000000000',
      close: '15.5772000000000000',
      volume: '9615963.2700000000000000',
      quoteVolume: 157331308.63249928,
      btcVolume: 13475.644648985366,
      usdVolume: 157331308.63249928,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '15.5746000000000000',
      high: '16.5053000000000000',
      low: '15.0629000000000000',
      close: '16.0545000000000000',
      volume: '7793462.3200000000000000',
      quoteVolume: 123969155.38132185,
      btcVolume: 10492.616965270177,
      usdVolume: 123958598.20392784,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '16.2072000000000000',
      high: '16.3587000000000000',
      low: '14.0000000000000000',
      close: '14.7982000000000000',
      volume: '10301348.0000000000000000',
      quoteVolume: 154640593.96041393,
      btcVolume: 13465.979576934247,
      usdVolume: 154640593.96041393,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '14.7922000000000000',
      high: '14.9989000000000000',
      low: '10.8261000000000000',
      close: '11.8895000000000000',
      volume: '16949856.4800000000000000',
      quoteVolume: 227014586.31002295,
      btcVolume: 20804.680530088768,
      usdVolume: 227014586.31002295,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '11.8802000000000000',
      high: '12.9040000000000000',
      low: '11.0000000000000000',
      close: '12.3708000000000000',
      volume: '18612095.2100000000000000',
      quoteVolume: 225971603.75573367,
      btcVolume: 21814.154412869015,
      usdVolume: 225971603.75573367,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '12.3804000000000000',
      high: '12.7655000000000000',
      low: '9.1000000000000000',
      close: '10.6017000000000000',
      volume: '25511608.4800000000000000',
      quoteVolume: 278344840.137839,
      btcVolume: 27443.663519385616,
      usdVolume: 278344840.137839,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '10.6087000000000000',
      high: '13.2400000000000000',
      low: '9.7155000000000000',
      close: '12.8044000000000000',
      volume: '21811584.7900000000000000',
      quoteVolume: 254940172.73275283,
      btcVolume: 24977.6005690715,
      usdVolume: 254940172.73275283,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '12.8060000000000000',
      high: '13.1747000000000000',
      low: '11.1700000000000000',
      close: '12.4700000000000000',
      volume: '20515580.9600000000000000',
      quoteVolume: 250103840.5173619,
      btcVolume: 24710.405343979466,
      usdVolume: 250103840.5173619,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '12.4744000000000000',
      high: '12.7362000000000000',
      low: '11.0284000000000000',
      close: '11.7460000000000000',
      volume: '14832129.8100000000000000',
      quoteVolume: 176444797.96903428,
      btcVolume: 17428.664020195858,
      usdVolume: 176444797.96903428,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '11.7359000000000000',
      high: '12.6566000000000000',
      low: '11.4356000000000000',
      close: '12.3444000000000000',
      volume: '10649791.4300000000000000',
      quoteVolume: 129646130.9405381,
      btcVolume: 12725.251017309938,
      usdVolume: 129646130.9405381,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '12.3452000000000000',
      high: '13.2800000000000000',
      low: '12.1800000000000000',
      close: '12.4957000000000000',
      volume: '14928167.2000000000000000',
      quoteVolume: 190985359.03175014,
      btcVolume: 18462.71704639154,
      usdVolume: 190985359.03175014,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '12.4948000000000000',
      high: '12.6960000000000000',
      low: '12.0190000000000000',
      close: '12.4406000000000000',
      volume: '7498865.1600000000000000',
      quoteVolume: 92935019.6125966,
      btcVolume: 9026.605245547558,
      usdVolume: 92935019.6125966,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '12.4406000000000000',
      high: '12.7470000000000000',
      low: '12.0934000000000000',
      close: '12.7187000000000000',
      volume: '6269794.6100000000000000',
      quoteVolume: 77882381.38087636,
      btcVolume: 7509.491501095776,
      usdVolume: 77882381.38087636,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '12.7194000000000000',
      high: '13.2300000000000000',
      low: '11.7658000000000000',
      close: '12.0670000000000000',
      volume: '11107099.4800000000000000',
      quoteVolume: 138433490.7795642,
      btcVolume: 13317.710404478297,
      usdVolume: 138433490.7795642,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '12.0757000000000000',
      high: '12.3863000000000000',
      low: '11.6349000000000000',
      close: '12.1374000000000000',
      volume: '8830940.1600000000000000',
      quoteVolume: 106057143.09535801,
      btcVolume: 10093.04992993571,
      usdVolume: 106057143.09535801,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '12.1300000000000000',
      high: '12.2400000000000000',
      low: '10.7500000000000000',
      close: '10.9314000000000000',
      volume: '11413633.6300000000000000',
      quoteVolume: 132790893.20361203,
      btcVolume: 12320.461229483713,
      usdVolume: 132790893.20361203,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '10.9301000000000000',
      high: '11.3002000000000000',
      low: '10.4771000000000000',
      close: '10.7367000000000000',
      volume: '10362445.0800000000000000',
      quoteVolume: 112764173.2894766,
      btcVolume: 10353.892720724712,
      usdVolume: 112764173.2894766,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '10.7367000000000000',
      high: '11.6759000000000000',
      low: '10.4720000000000000',
      close: '11.0139000000000000',
      volume: '10163046.4600000000000000',
      quoteVolume: 111006019.31385103,
      btcVolume: 10179.558912072785,
      usdVolume: 111006019.31385103,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '11.0139000000000000',
      high: '11.1315000000000000',
      low: '9.7863000000000000',
      close: '10.0940000000000000',
      volume: '8890206.0700000000000000',
      quoteVolume: 93387663.390679,
      btcVolume: 8561.061779425265,
      usdVolume: 93387663.390679,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '10.0938000000000000',
      high: '10.4969000000000000',
      low: '9.8756000000000000',
      close: '10.1209000000000000',
      volume: '5596280.8000000000000000',
      quoteVolume: 56998511.47489919,
      btcVolume: 5177.969389632715,
      usdVolume: 56998511.47489919,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '10.1192000000000000',
      high: '10.6199000000000000',
      low: '9.5000000000000000',
      close: '9.7357000000000000',
      volume: '7410739.4400000000000000',
      quoteVolume: 74829506.00962031,
      btcVolume: 6862.181652542345,
      usdVolume: 74829506.00962031,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '9.7394000000000000',
      high: '9.9235000000000000',
      low: '8.5012000000000000',
      close: '8.7114000000000000',
      volume: '15761415.8500000000000000',
      quoteVolume: 141463825.6035975,
      btcVolume: 13312.026406212963,
      usdVolume: 141463825.6035975,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '8.7162000000000000',
      high: '9.0800000000000000',
      low: '8.5400000000000000',
      close: '8.7401000000000000',
      volume: '8074342.9400000000000000',
      quoteVolume: 71284015.95208645,
      btcVolume: 6811.9002965444815,
      usdVolume: 71284015.95208645,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '8.7440000000000000',
      high: '8.8016000000000000',
      low: '7.2869000000000000',
      close: '7.6364000000000000',
      volume: '18221454.9700000000000000',
      quoteVolume: 146298886.40303153,
      btcVolume: 14105.940252148712,
      usdVolume: 146298886.40303153,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '7.6404000000000000',
      high: '10.5300000000000000',
      low: '7.4761000000000000',
      close: '9.8700000000000000',
      volume: '26877668.8100000000000000',
      quoteVolume: 243924037.01423475,
      btcVolume: 23200.606802237573,
      usdVolume: 243924037.01423475,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '9.8701000000000000',
      high: '11.1990000000000000',
      low: '9.3771000000000000',
      close: '10.7279000000000000',
      volume: '20220142.0700000000000000',
      quoteVolume: 206454816.90600815,
      btcVolume: 19347.190816472226,
      usdVolume: 206454816.90600815,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '10.7218000000000000',
      high: '11.1000000000000000',
      low: '9.9757000000000000',
      close: '10.3169000000000000',
      volume: '12019763.1700000000000000',
      quoteVolume: 125899152.91074796,
      btcVolume: 11759.471660591937,
      usdVolume: 125899152.91074796,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '10.3079000000000000',
      high: '10.9910000000000000',
      low: '9.9122000000000000',
      close: '10.7939000000000000',
      volume: '10005871.9800000000000000',
      quoteVolume: 104553700.74577622,
      btcVolume: 9756.633696493238,
      usdVolume: 104553700.74577622,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '10.8000000000000000',
      high: '11.0700000000000000',
      low: '10.1500000000000000',
      close: '10.2830000000000000',
      volume: '8720119.6800000000000000',
      quoteVolume: 92733132.97270194,
      btcVolume: 8531.552193163896,
      usdVolume: 92733132.97270194,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '10.2809000000000000',
      high: '10.3882000000000000',
      low: '9.4502000000000000',
      close: '10.1350000000000000',
      volume: '13371311.3100000000000000',
      quoteVolume: 133299972.25986037,
      btcVolume: 12416.99662418838,
      usdVolume: 133299972.25986037,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '10.1356000000000000',
      high: '10.2314000000000000',
      low: '9.5768000000000000',
      close: '9.8694000000000000',
      volume: '6624754.2600000000000000',
      quoteVolume: 65302473.930610836,
      btcVolume: 6075.231257322131,
      usdVolume: 65302473.930610836,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '9.8727000000000000',
      high: '10.4119000000000000',
      low: '9.2457000000000000',
      close: '9.6394000000000000',
      volume: '9437903.4200000000000000',
      quoteVolume: 93289422.56827107,
      btcVolume: 8711.5733196697,
      usdVolume: 93289422.56827107,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '9.6394000000000000',
      high: '9.8449000000000000',
      low: '8.7975000000000000',
      close: '9.2167000000000000',
      volume: '11050283.7900000000000000',
      quoteVolume: 101613627.94135539,
      btcVolume: 9676.160636390727,
      usdVolume: 101613627.94135539,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '9.2132000000000000',
      high: '9.5555000000000000',
      low: '9.1040000000000000',
      close: '9.2690000000000000',
      volume: '4803593.0900000000000000',
      quoteVolume: 44581852.41136064,
      btcVolume: 4225.164872066804,
      usdVolume: 44581852.41136064,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '9.2686000000000000',
      high: '9.4660000000000000',
      low: '9.1141000000000000',
      close: '9.3922000000000000',
      volume: '4665626.6900000000000000',
      quoteVolume: 43259192.53149099,
      btcVolume: 4079.3745493298234,
      usdVolume: 43259192.53149099,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '9.3906000000000000',
      high: '9.7200000000000000',
      low: '9.2629000000000000',
      close: '9.6176000000000000',
      volume: '6205749.7700000000000000',
      quoteVolume: 58892347.278092586,
      btcVolume: 5503.330432382905,
      usdVolume: 58892347.278092586,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '9.6134000000000000',
      high: '9.6580000000000000',
      low: '8.5630000000000000',
      close: '8.7689000000000000',
      volume: '7422770.6500000000000000',
      quoteVolume: 67706985.53549488,
      btcVolume: 6342.859632868126,
      usdVolume: 67706985.53549488,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '8.7690000000000000',
      high: '9.0100000000000000',
      low: '8.3817000000000000',
      close: '8.9799000000000000',
      volume: '7536611.8700000000000000',
      quoteVolume: 65993355.663363345,
      btcVolume: 6218.463186844811,
      usdVolume: 65993355.663363345,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '8.9801000000000000',
      high: '9.6829000000000000',
      low: '8.4016000000000000',
      close: '9.5594000000000000',
      volume: '10110727.7500000000000000',
      quoteVolume: 91351206.53641662,
      btcVolume: 8488.116029639972,
      usdVolume: 91351206.53641662,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '9.5519000000000000',
      high: '10.5254000000000000',
      low: '9.3701000000000000',
      close: '10.3959000000000000',
      volume: '11276147.4500000000000000',
      quoteVolume: 112848361.62231602,
      btcVolume: 10272.349870485246,
      usdVolume: 112848361.62231602,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '10.3923000000000000',
      high: '10.9200000000000000',
      low: '10.3700000000000000',
      close: '10.5198000000000000',
      volume: '9504046.9700000000000000',
      quoteVolume: 100981242.65024652,
      btcVolume: 8918.134330290197,
      usdVolume: 100981242.65024652,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '10.5168000000000000',
      high: '11.0258000000000000',
      low: '10.1500000000000000',
      close: '10.8440000000000000',
      volume: '8011810.1500000000000000',
      quoteVolume: 85068557.21714121,
      btcVolume: 7485.698997274424,
      usdVolume: 85068557.21714121,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '10.8441000000000000',
      high: '11.8028000000000000',
      low: '10.6807000000000000',
      close: '11.4242000000000000',
      volume: '9262541.8700000000000000',
      quoteVolume: 104346545.63303679,
      btcVolume: 9109.599515130829,
      usdVolume: 104346545.63303679,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '11.4599000000000000',
      high: '11.5520000000000000',
      low: '10.6592000000000000',
      close: '11.0016000000000000',
      volume: '7617422.8600000000000000',
      quoteVolume: 85252142.00798237,
      btcVolume: 7456.401573669332,
      usdVolume: 85252142.00798237,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '11.0016000000000000',
      high: '11.3536000000000000',
      low: '10.6201000000000000',
      close: '10.8896000000000000',
      volume: '6061485.9500000000000000',
      quoteVolume: 66687126.90002584,
      btcVolume: 5850.816052446876,
      usdVolume: 66687126.90002584,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '10.8896000000000000',
      high: '10.9317000000000000',
      low: '10.4550000000000000',
      close: '10.7483000000000000',
      volume: '6908169.7600000000000000',
      quoteVolume: 73900491.87788318,
      btcVolume: 6486.307989052753,
      usdVolume: 73900491.87788318,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '10.7472000000000000',
      high: '10.8935000000000000',
      low: '10.2694000000000000',
      close: '10.5877000000000000',
      volume: '6662666.8600000000000000',
      quoteVolume: 70509800.96815944,
      btcVolume: 6215.660703350463,
      usdVolume: 70509800.96815944,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '10.5867000000000000',
      high: '10.7149000000000000',
      low: '10.4082000000000000',
      close: '10.6267000000000000',
      volume: '3320266.0600000000000000',
      quoteVolume: 35074078.772476934,
      btcVolume: 3094.4010233917893,
      usdVolume: 35074078.772476934,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '10.6254000000000000',
      high: '11.0000000000000000',
      low: '10.6000000000000000',
      close: '10.9409000000000000',
      volume: '3679623.9400000000000000',
      quoteVolume: 39786628.52001377,
      btcVolume: 3482.0203876379023,
      usdVolume: 39786628.52001377,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '10.9435000000000000',
      high: '11.2226000000000000',
      low: '10.6510000000000000',
      close: '10.9147000000000000',
      volume: '4474849.3100000000000000',
      quoteVolume: 48718810.825249076,
      btcVolume: 4198.544817946333,
      usdVolume: 48718810.825249076,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '10.9116000000000000',
      high: '10.9349000000000000',
      low: '9.7665000000000000',
      close: '9.8589000000000000',
      volume: '7095267.1100000000000000',
      quoteVolume: 73344149.05086827,
      btcVolume: 6182.229702645229,
      usdVolume: 73344149.05086827,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '9.8589000000000000',
      high: '11.1372000000000000',
      low: '9.7864000000000000',
      close: '10.7863000000000000',
      volume: '8276637.4200000000000000',
      quoteVolume: 87959927.25448503,
      btcVolume: 7031.471299457609,
      usdVolume: 87959927.25448503,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '10.7805000000000000',
      high: '12.4173000000000000',
      low: '10.7142000000000000',
      close: '11.9539000000000000',
      volume: '10301801.8300000000000000',
      quoteVolume: 120278047.81922631,
      btcVolume: 9287.505769123953,
      usdVolume: 120278047.81922631,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '11.9551000000000000',
      high: '12.2640000000000000',
      low: '11.5784000000000000',
      close: '11.7869000000000000',
      volume: '6599326.7100000000000000',
      quoteVolume: 78921676.86675517,
      btcVolume: 6109.358731500419,
      usdVolume: 78921676.86675517,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '12.1592000000000000',
      high: '12.9500000000000000',
      low: '11.9839000000000000',
      close: '12.7001000000000000',
      volume: '7531584.3600000000000000',
      quoteVolume: 94612367.21991429,
      btcVolume: 7266.641304869805,
      usdVolume: 94612367.21991429,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '12.7002000000000000',
      high: '12.8574000000000000',
      low: '11.9080000000000000',
      close: '12.1481000000000000',
      volume: '6996448.0600000000000000',
      quoteVolume: 86499461.98964313,
      btcVolume: 6638.643976139153,
      usdVolume: 86499461.98964313,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '12.1480000000000000',
      high: '12.4472000000000000',
      low: '11.2594000000000000',
      close: '11.7096000000000000',
      volume: '8831828.5200000000000000',
      quoteVolume: 104763458.12184793,
      btcVolume: 8038.602388420682,
      usdVolume: 104763458.12184793,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '11.7086000000000000',
      high: '12.4774000000000000',
      low: '11.4900000000000000',
      close: '11.9224000000000000',
      volume: '7598237.9300000000000000',
      quoteVolume: 91297276.70568031,
      btcVolume: 6804.543512604595,
      usdVolume: 91297276.70568031,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '11.9210000000000000',
      high: '12.1509000000000000',
      low: '11.1500000000000000',
      close: '11.4403000000000000',
      volume: '6787355.3400000000000000',
      quoteVolume: 78579989.27577649,
      btcVolume: 5868.232165162447,
      usdVolume: 78579989.27577649,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '11.4448000000000000',
      high: '11.8100000000000000',
      low: '11.0816000000000000',
      close: '11.2772000000000000',
      volume: '8541237.8300000000000000',
      quoteVolume: 97758547.62588826,
      btcVolume: 7336.005858513019,
      usdVolume: 97758547.62588826,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '11.2743000000000000',
      high: '11.4811000000000000',
      low: '10.7200000000000000',
      close: '11.0900000000000000',
      volume: '7958701.4600000000000000',
      quoteVolume: 87936295.50341521,
      btcVolume: 6567.679161107949,
      usdVolume: 87936295.50341521,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '11.0947000000000000',
      high: '11.3981000000000000',
      low: '10.9053000000000000',
      close: '11.2277000000000000',
      volume: '4822720.4700000000000000',
      quoteVolume: 53963475.40082074,
      btcVolume: 3926.032199920614,
      usdVolume: 53963475.40082074,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '11.2259000000000000',
      high: '11.5885000000000000',
      low: '11.0181000000000000',
      close: '11.5722000000000000',
      volume: '3872679.9900000000000000',
      quoteVolume: 43600203.22140587,
      btcVolume: 3168.731592931039,
      usdVolume: 43600203.22140587,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '11.5681000000000000',
      high: '11.6850000000000000',
      low: '10.7049000000000000',
      close: '10.7814000000000000',
      volume: '6919050.6800000000000000',
      quoteVolume: 77130468.23187579,
      btcVolume: 5694.891968008859,
      usdVolume: 77130468.23187579,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '10.7814000000000000',
      high: '10.7814000000000000',
      low: '9.9454000000000000',
      close: '10.3911000000000000',
      volume: '9925376.6400000000000000',
      quoteVolume: 102949946.71757828,
      btcVolume: 7589.417998761801,
      usdVolume: 102949946.71757828,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '10.3982000000000000',
      high: '10.6750000000000000',
      low: '9.7500000000000000',
      close: '10.4713000000000000',
      volume: '8586603.9100000000000000',
      quoteVolume: 87954953.98638126,
      btcVolume: 6320.607986664108,
      usdVolume: 87954953.98638126,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '10.4730000000000000',
      high: '11.2749000000000000',
      low: '10.0764000000000000',
      close: '10.9794000000000000',
      volume: '10616943.9300000000000000',
      quoteVolume: 113756787.2898385,
      btcVolume: 7651.513480243185,
      usdVolume: 113756787.2898385,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '10.9822000000000000',
      high: '12.2813000000000000',
      low: '10.9286000000000000',
      close: '12.1687000000000000',
      volume: '10613986.0500000000000000',
      quoteVolume: 123261012.0719206,
      btcVolume: 7916.228371545654,
      usdVolume: 123261012.0719206,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '12.1686000000000000',
      high: '13.5956000000000000',
      low: '11.3123000000000000',
      close: '11.7638000000000000',
      volume: '24836053.4300000000000000',
      quoteVolume: 312825764.5672563,
      btcVolume: 20466.466375561587,
      usdVolume: 312825764.5672563,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '11.7668000000000000',
      high: '13.1000000000000000',
      low: '11.4770000000000000',
      close: '12.7100000000000000',
      volume: '12895124.5400000000000000',
      quoteVolume: 159621589.8883934,
      btcVolume: 10501.569151809028,
      usdVolume: 159621589.8883934,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '12.7083000000000000',
      high: '13.0026000000000000',
      low: '12.0400000000000000',
      close: '12.5107000000000000',
      volume: '12634927.8300000000000000',
      quoteVolume: 157868780.3591942,
      btcVolume: 10278.693917702634,
      usdVolume: 157868780.3591942,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '12.5182000000000000',
      high: '13.2780000000000000',
      low: '12.3156000000000000',
      close: '13.0017000000000000',
      volume: '12313102.0800000000000000',
      quoteVolume: 158472628.9918838,
      btcVolume: 10350.422830750087,
      usdVolume: 158472628.9918838,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '13.0017000000000000',
      high: '13.4293000000000000',
      low: '12.7500000000000000',
      close: '12.7530000000000000',
      volume: '9402996.4400000000000000',
      quoteVolume: 123227278.88061701,
      btcVolume: 7903.41235731508,
      usdVolume: 123227278.88061701,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '12.7530000000000000',
      high: '13.0956000000000000',
      low: '12.2700000000000000',
      close: '12.4038000000000000',
      volume: '8887319.0400000000000000',
      quoteVolume: 112105873.89800362,
      btcVolume: 7056.930684473489,
      usdVolume: 112105873.89800362,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '12.4038000000000000',
      high: '13.0000000000000000',
      low: '12.3079000000000000',
      close: '12.8687000000000000',
      volume: '7183843.8700000000000000',
      quoteVolume: 91486357.29356198,
      btcVolume: 5621.798860970854,
      usdVolume: 91486357.29356198,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '12.8679000000000000',
      high: '12.9335000000000000',
      low: '12.3000000000000000',
      close: '12.5574000000000000',
      volume: '4779543.0800000000000000',
      quoteVolume: 60108858.82407817,
      btcVolume: 3755.1233582281616,
      usdVolume: 60108858.82407817,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '12.5570000000000000',
      high: '12.6484000000000000',
      low: '11.8156000000000000',
      close: '12.1192000000000000',
      volume: '5705889.4900000000000000',
      quoteVolume: 70141538.75487132,
      btcVolume: 4393.466812059062,
      usdVolume: 70141538.75487132,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '12.1199000000000000',
      high: '12.8132000000000000',
      low: '11.9971000000000000',
      close: '12.6223000000000000',
      volume: '5966554.6100000000000000',
      quoteVolume: 74312723.17503132,
      btcVolume: 4538.090941158825,
      usdVolume: 74312723.17503132,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '12.6204000000000000',
      high: '13.4228000000000000',
      low: '12.5414000000000000',
      close: '13.3944000000000000',
      volume: '9943527.4100000000000000',
      quoteVolume: 128513966.31700706,
      btcVolume: 7475.957078646857,
      usdVolume: 128513966.31700706,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '13.3943000000000000',
      high: '14.2669000000000000',
      low: '12.5850000000000000',
      close: '13.5598000000000000',
      volume: '17994859.8100000000000000',
      quoteVolume: 243378004.2585356,
      btcVolume: 13652.72139475029,
      usdVolume: 243378004.2585356,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '13.5602000000000000',
      high: '14.0000000000000000',
      low: '13.0111000000000000',
      close: '13.6187000000000000',
      volume: '9295294.8300000000000000',
      quoteVolume: 125766975.70460743,
      btcVolume: 7051.256067524977,
      usdVolume: 125766975.70460743,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '13.6117000000000000',
      high: '14.4770000000000000',
      low: '13.4618000000000000',
      close: '14.0933000000000000',
      volume: '10955855.4700000000000000',
      quoteVolume: 153945485.5929507,
      btcVolume: 8393.83606216672,
      usdVolume: 153945485.5929507,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '14.0913000000000000',
      high: '15.5000000000000000',
      low: '13.6876000000000000',
      close: '15.4511000000000000',
      volume: '13999538.8800000000000000',
      quoteVolume: 204973635.11923143,
      btcVolume: 10955.623567420895,
      usdVolume: 204973635.11923143,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '15.4408000000000000',
      high: '15.7179000000000000',
      low: '13.8402000000000000',
      close: '14.5098000000000000',
      volume: '14244002.3200000000000000',
      quoteVolume: 209484344.0724672,
      btcVolume: 11387.241340723021,
      usdVolume: 209484344.0724672,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '14.5098000000000000',
      high: '15.4250000000000000',
      low: '14.2520000000000000',
      close: '15.2746000000000000',
      volume: '12165056.4100000000000000',
      quoteVolume: 181293925.92475033,
      btcVolume: 9837.750688600954,
      usdVolume: 181293925.92475033,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '15.2648000000000000',
      high: '16.3900000000000000',
      low: '14.9430000000000000',
      close: '15.6703000000000000',
      volume: '15636471.1700000000000000',
      quoteVolume: 244854909.96831247,
      btcVolume: 13082.91825525146,
      usdVolume: 244854909.96831247,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '15.6637000000000000',
      high: '15.8600000000000000',
      low: '13.7900000000000000',
      close: '14.2016000000000000',
      volume: '9463367.4500000000000000',
      quoteVolume: 142275307.68800414,
      btcVolume: 7489.979662764517,
      usdVolume: 142275307.68800414,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '14.2180000000000000',
      high: '14.4743000000000000',
      low: '11.2939000000000000',
      close: '12.6030000000000000',
      volume: '24581331.8400000000000000',
      quoteVolume: 307097131.8146662,
      btcVolume: 17664.03164046134,
      usdVolume: 307097131.8146662,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '12.6036000000000000',
      high: '12.9530000000000000',
      low: '11.9454000000000000',
      close: '12.4989000000000000',
      volume: '9126132.6500000000000000',
      quoteVolume: 113170817.60460344,
      btcVolume: 6660.581797963793,
      usdVolume: 113170817.60460344,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '12.5038000000000000',
      high: '13.4300000000000000',
      low: '12.3489000000000000',
      close: '13.0853000000000000',
      volume: '7281492.8400000000000000',
      quoteVolume: 94217840.3930073,
      btcVolume: 5414.366931727638,
      usdVolume: 94217840.3930073,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '13.0848000000000000',
      high: '13.4765000000000000',
      low: '12.8408000000000000',
      close: '13.3800000000000000',
      volume: '6537927.5000000000000000',
      quoteVolume: 85931374.67872472,
      btcVolume: 4773.352624805251,
      usdVolume: 85931374.67872472,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '13.3855000000000000',
      high: '14.4370000000000000',
      low: '13.3855000000000000',
      close: '14.2677000000000000',
      volume: '10253978.8400000000000000',
      quoteVolume: 143593067.98141998,
      btcVolume: 7589.521928283262,
      usdVolume: 143593067.98141998,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '14.2626000000000000',
      high: '14.8328000000000000',
      low: '13.0456000000000000',
      close: '13.3461000000000000',
      volume: '10704338.7400000000000000',
      quoteVolume: 149610565.32318997,
      btcVolume: 7801.160851270977,
      usdVolume: 149610565.32318997,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '13.3544000000000000',
      high: '14.1482000000000000',
      low: '13.0500000000000000',
      close: '13.9799000000000000',
      volume: '6137932.7300000000000000',
      quoteVolume: 83779802.26252918,
      btcVolume: 4424.74642432009,
      usdVolume: 83779802.26252918,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '13.9785000000000000',
      high: '14.2200000000000000',
      low: '13.6446000000000000',
      close: '13.9749000000000000',
      volume: '4275317.8800000000000000',
      quoteVolume: 59535321.2775434,
      btcVolume: 3090.5416607922175,
      usdVolume: 59535321.2775434,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '13.9749000000000000',
      high: '14.0332000000000000',
      low: '12.5101000000000000',
      close: '12.6800000000000000',
      volume: '5794937.4500000000000000',
      quoteVolume: 77275788.62363255,
      btcVolume: 4061.1546698955426,
      usdVolume: 77275788.62363255,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '12.6800000000000000',
      high: '13.3478000000000000',
      low: '12.5265000000000000',
      close: '13.1748000000000000',
      volume: '3411635.3200000000000000',
      quoteVolume: 44668221.07546615,
      btcVolume: 2355.8773509120115,
      usdVolume: 44668221.07546615,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '13.1743000000000000',
      high: '13.5282000000000000',
      low: '12.7671000000000000',
      close: '13.5057000000000000',
      volume: '3866618.8500000000000000',
      quoteVolume: 51005078.82048807,
      btcVolume: 2664.431067672835,
      usdVolume: 51005078.82048807,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '13.5090000000000000',
      high: '13.5536000000000000',
      low: '12.8671000000000000',
      close: '13.0704000000000000',
      volume: '3223051.6500000000000000',
      quoteVolume: 42439345.8764611,
      btcVolume: 2211.4995680383877,
      usdVolume: 42439345.8764611,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '13.0737000000000000',
      high: '13.1929000000000000',
      low: '12.0000000000000000',
      close: '12.1356000000000000',
      volume: '5175641.2100000000000000',
      quoteVolume: 65172913.683855735,
      btcVolume: 3458.1321327537844,
      usdVolume: 65172913.683855735,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '12.1280000000000000',
      high: '12.8290000000000000',
      low: '11.5726000000000000',
      close: '12.6212000000000000',
      volume: '6754613.8300000000000000',
      quoteVolume: 81954160.37834212,
      btcVolume: 4495.429433765595,
      usdVolume: 81954160.37834212,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '12.6200000000000000',
      high: '12.6730000000000000',
      low: '11.8549000000000000',
      close: '11.9172000000000000',
      volume: '3519174.2400000000000000',
      quoteVolume: 42896656.04262703,
      btcVolume: 2346.0300410065424,
      usdVolume: 42896656.04262703,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '11.9172000000000000',
      high: '11.9899000000000000',
      low: '11.4300000000000000',
      close: '11.6335000000000000',
      volume: '4337254.6200000000000000',
      quoteVolume: 50652575.468863204,
      btcVolume: 2820.6350744098336,
      usdVolume: 50647631.718757205,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '11.6393000000000000',
      high: '12.3529000000000000',
      low: '11.6201000000000000',
      close: '12.2259000000000000',
      volume: '3453043.1200000000000000',
      quoteVolume: 41720520.772683,
      btcVolume: 2259.4574947618594,
      usdVolume: 41720520.772683,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '12.2280000000000000',
      high: '13.2697000000000000',
      low: '12.0210000000000000',
      close: '12.9392000000000000',
      volume: '6036848.9700000000000000',
      quoteVolume: 76518629.71278152,
      btcVolume: 3990.4218542597705,
      usdVolume: 76518629.71278152,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '12.9389000000000000',
      high: '12.9800000000000000',
      low: '12.3238000000000000',
      close: '12.8502000000000000',
      volume: '4526653.6900000000000000',
      quoteVolume: 57446821.99876489,
      btcVolume: 2998.157797062246,
      usdVolume: 57446821.99876489,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '12.8513000000000000',
      high: '13.0220000000000000',
      low: '12.4659000000000000',
      close: '12.6818000000000000',
      volume: '2635221.9300000000000000',
      quoteVolume: 33514452.026845157,
      btcVolume: 1732.722900418818,
      usdVolume: 33514452.026845157,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '12.6786000000000000',
      high: '13.5000000000000000',
      low: '12.3119000000000000',
      close: '13.4852000000000000',
      volume: '5644198.7100000000000000',
      quoteVolume: 73189908.58627348,
      btcVolume: 3595.924463613392,
      usdVolume: 73189908.58627348,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '13.4840000000000000',
      high: '14.5038000000000000',
      low: '13.0575000000000000',
      close: '13.4440000000000000',
      volume: '11577270.1700000000000000',
      quoteVolume: 159653805.28869012,
      btcVolume: 7026.0614219511735,
      usdVolume: 159653805.28869012,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '13.4440000000000000',
      high: '14.0235000000000000',
      low: '13.1725000000000000',
      close: '13.5001000000000000',
      volume: '5513708.3800000000000000',
      quoteVolume: 74792637.47296071,
      btcVolume: 3271.2867842547794,
      usdVolume: 74792637.47296071,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '13.5000000000000000',
      high: '13.9697000000000000',
      low: '13.2981000000000000',
      close: '13.4747000000000000',
      volume: '4596156.0500000000000000',
      quoteVolume: 62945458.56621082,
      btcVolume: 2692.9200733659177,
      usdVolume: 62945458.56621082,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '13.4748000000000000',
      high: '13.8000000000000000',
      low: '12.6752000000000000',
      close: '13.0604000000000000',
      volume: '5227497.0200000000000000',
      quoteVolume: 69925342.0503345,
      btcVolume: 2966.1250407952425,
      usdVolume: 69925342.0503345,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '13.0603000000000000',
      high: '13.2700000000000000',
      low: '12.1354000000000000',
      close: '12.3049000000000000',
      volume: '5659600.6800000000000000',
      quoteVolume: 71716093.56996408,
      btcVolume: 3090.609511831206,
      usdVolume: 71716093.56996408,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '12.3028000000000000',
      high: '12.8400000000000000',
      low: '11.8117000000000000',
      close: '12.7842000000000000',
      volume: '6172266.6600000000000000',
      quoteVolume: 76364461.06396206,
      btcVolume: 3308.2084013040967,
      usdVolume: 76364461.06396206,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '12.7811000000000000',
      high: '12.8634000000000000',
      low: '8.0500000000000000',
      close: '10.9736000000000000',
      volume: '15154276.0300000000000000',
      quoteVolume: 168815393.817345,
      btcVolume: 7227.637473629581,
      usdVolume: 168815393.817345,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '10.9730000000000000',
      high: '11.6877000000000000',
      low: '9.8564000000000000',
      close: '11.5724000000000000',
      volume: '14771438.4000000000000000',
      quoteVolume: 157801028.12701663,
      btcVolume: 6807.5861925391055,
      usdVolume: 157801028.12701663,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '11.5705000000000000',
      high: '11.9234000000000000',
      low: '11.0601000000000000',
      close: '11.5543000000000000',
      volume: '7348236.1100000000000000',
      quoteVolume: 84426543.59749623,
      btcVolume: 3504.7630743045383,
      usdVolume: 84426543.59749623,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '11.5640000000000000',
      high: '11.8151000000000000',
      low: '10.6238000000000000',
      close: '10.9809000000000000',
      volume: '9093279.7400000000000000',
      quoteVolume: 101048617.75868994,
      btcVolume: 3982.7085174247964,
      usdVolume: 101048617.75868994,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '10.9840000000000000',
      high: '13.2448000000000000',
      low: '10.4763000000000000',
      close: '12.1541000000000000',
      volume: '18943413.3800000000000000',
      quoteVolume: 226941348.13784474,
      btcVolume: 8381.434369307135,
      usdVolume: 226941348.13784474,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '12.1542000000000000',
      high: '12.9781000000000000',
      low: '11.9710000000000000',
      close: '12.6081000000000000',
      volume: '11270755.2200000000000000',
      quoteVolume: 140279053.6851551,
      btcVolume: 5203.613393033124,
      usdVolume: 140279053.6851551,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '12.6080000000000000',
      high: '13.0351000000000000',
      low: '10.8161000000000000',
      close: '11.7786000000000000',
      volume: '12890847.2600000000000000',
      quoteVolume: 151849176.99178037,
      btcVolume: 5695.149438822997,
      usdVolume: 151849176.99178037,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '11.7786000000000000',
      high: '11.9900000000000000',
      low: '11.0671000000000000',
      close: '11.2435000000000000',
      volume: '7687754.9000000000000000',
      quoteVolume: 88083075.6322653,
      btcVolume: 3132.5608072986693,
      usdVolume: 88083075.6322653,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '11.2437000000000000',
      high: '11.7530000000000000',
      low: '10.9039000000000000',
      close: '11.2364000000000000',
      volume: '6639477.6200000000000000',
      quoteVolume: 75178212.43988732,
      btcVolume: 2609.4447854849886,
      usdVolume: 75178212.43988732,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '11.2364000000000000',
      high: '12.4600000000000000',
      low: '11.0638000000000000',
      close: '11.8477000000000000',
      volume: '8245184.6000000000000000',
      quoteVolume: 97963056.43351899,
      btcVolume: 3350.468743703054,
      usdVolume: 97963056.43351899,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '11.8453000000000000',
      high: '12.4188000000000000',
      low: '11.4303000000000000',
      close: '12.2215000000000000',
      volume: '9617243.6900000000000000',
      quoteVolume: 114480499.1565445,
      btcVolume: 3665.1539501073544,
      usdVolume: 114480499.1565445,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '12.2206000000000000',
      high: '13.9999000000000000',
      low: '11.8355000000000000',
      close: '13.6814000000000000',
      volume: '15927066.3100000000000000',
      quoteVolume: 205085520.50347137,
      btcVolume: 6124.344091873578,
      usdVolume: 205085520.50347137,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '13.6815000000000000',
      high: '15.6440000000000000',
      low: '12.3713000000000000',
      close: '13.5935000000000000',
      volume: '23196077.6800000000000000',
      quoteVolume: 321727867.96833014,
      btcVolume: 10107.698949256002,
      usdVolume: 321727867.96833014,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '13.5962000000000000',
      high: '14.8900000000000000',
      low: '13.0928000000000000',
      close: '14.5162000000000000',
      volume: '14809606.1500000000000000',
      quoteVolume: 208905104.45490494,
      btcVolume: 6485.977306193403,
      usdVolume: 208905104.45490494,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '14.5141000000000000',
      high: '17.2997000000000000',
      low: '14.1427000000000000',
      close: '17.2322000000000000',
      volume: '21280047.1800000000000000',
      quoteVolume: 338322543.10816723,
      btcVolume: 9721.843587977792,
      usdVolume: 338322543.10816723,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '17.2342000000000000',
      high: '17.4100000000000000',
      low: '15.3542000000000000',
      close: '15.9628000000000000',
      volume: '16135019.3000000000000000',
      quoteVolume: 266063447.9945453,
      btcVolume: 6971.427792671456,
      usdVolume: 266063447.9945453,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '15.9659000000000000',
      high: '16.1917000000000000',
      low: '14.1200000000000000',
      close: '15.2381000000000000',
      volume: '14730622.4700000000000000',
      quoteVolume: 225837560.70656046,
      btcVolume: 5699.007296482832,
      usdVolume: 225837560.70656046,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '15.2395000000000000',
      high: '17.8888000000000000',
      low: '15.0029000000000000',
      close: '17.5501000000000000',
      volume: '12807954.5600000000000000',
      quoteVolume: 209112616.22126392,
      btcVolume: 5179.822048220309,
      usdVolume: 209112616.22126392,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '17.5501000000000000',
      high: '18.4386000000000000',
      low: '15.4418000000000000',
      close: '16.2060000000000000',
      volume: '16459987.7000000000000000',
      quoteVolume: 280219098.9046208,
      btcVolume: 7124.706550510726,
      usdVolume: 280219098.9046208,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '16.1998000000000000',
      high: '16.2596000000000000',
      low: '12.5000000000000000',
      close: '14.6393000000000000',
      volume: '21046546.5800000000000000',
      quoteVolume: 299155444.76900715,
      btcVolume: 8793.50832897109,
      usdVolume: 299155444.76900715,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '14.6312000000000000',
      high: '15.4760000000000000',
      low: '13.6701000000000000',
      close: '13.9655000000000000',
      volume: '8959437.3100000000000000',
      quoteVolume: 130225909.90950419,
      btcVolume: 3745.877009935573,
      usdVolume: 130225909.90950419,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '13.9609000000000000',
      high: '16.2356000000000000',
      low: '13.3701000000000000',
      close: '15.9356000000000000',
      volume: '10911746.9200000000000000',
      quoteVolume: 163912112.80508533,
      btcVolume: 4699.829494560408,
      usdVolume: 163912112.80508533,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '15.9396000000000000',
      high: '17.9900000000000000',
      low: '15.3300000000000000',
      close: '17.9804000000000000',
      volume: '13478182.9400000000000000',
      quoteVolume: 222149143.90415877,
      btcVolume: 5733.229551150123,
      usdVolume: 222149143.90415877,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '17.9888000000000000',
      high: '21.4221000000000000',
      low: '17.5355000000000000',
      close: '20.7375000000000000',
      volume: '31054166.3600000000000000',
      quoteVolume: 598915855.4361597,
      btcVolume: 16128.175112869507,
      usdVolume: 598915855.4361597,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '20.7405000000000000',
      high: '22.9600000000000000',
      low: '19.6280000000000000',
      close: '20.1054000000000000',
      volume: '22583400.8600000000000000',
      quoteVolume: 488477166.4998309,
      btcVolume: 13263.862594524382,
      usdVolume: 488477166.4998309,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '20.1003000000000000',
      high: '23.7670000000000000',
      low: '19.3729000000000000',
      close: '23.2819000000000000',
      volume: '21794612.1800000000000000',
      quoteVolume: 467638469.6446607,
      btcVolume: 13093.00975534152,
      usdVolume: 467638469.6446607,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '23.2676000000000000',
      high: '23.6862000000000000',
      low: '21.0600000000000000',
      close: '22.0336000000000000',
      volume: '13776439.8500000000000000',
      quoteVolume: 305061086.4015469,
      btcVolume: 8477.02470472144,
      usdVolume: 305061086.4015469,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '22.0325000000000000',
      high: '22.8248000000000000',
      low: '20.3774000000000000',
      close: '20.5557000000000000',
      volume: '10237213.4000000000000000',
      quoteVolume: 221370711.89643016,
      btcVolume: 6011.617268406959,
      usdVolume: 221370711.89643016,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '20.5437000000000000',
      high: '21.9788000000000000',
      low: '19.0645000000000000',
      close: '21.8482000000000000',
      volume: '12445387.5700000000000000',
      quoteVolume: 253587783.00309002,
      btcVolume: 7255.599211354413,
      usdVolume: 253587783.00309002,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '21.8421000000000000',
      high: '22.0307000000000000',
      low: '18.0611000000000000',
      close: '18.4119000000000000',
      volume: '14800253.5200000000000000',
      quoteVolume: 295655182.9197108,
      btcVolume: 9063.9657818205,
      usdVolume: 295655182.9197108,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '18.4109000000000000',
      high: '22.4725000000000000',
      low: '17.1269000000000000',
      close: '21.5519000000000000',
      volume: '16210103.8000000000000000',
      quoteVolume: 326743324.4039971,
      btcVolume: 10379.828662817843,
      usdVolume: 326743324.4039971,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '21.5467000000000000',
      high: '25.5000000000000000',
      low: '21.1400000000000000',
      close: '24.8046000000000000',
      volume: '14892659.5600000000000000',
      quoteVolume: 357496388.1897744,
      btcVolume: 11065.14109872835,
      usdVolume: 357496388.1897744,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '24.8168000000000000',
      high: '24.9530000000000000',
      low: '23.1612000000000000',
      close: '24.7935000000000000',
      volume: '11903411.3600000000000000',
      quoteVolume: 288283267.4123565,
      btcVolume: 8969.474384440253,
      usdVolume: 288283267.4123565,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '24.7912000000000000',
      high: '25.7824000000000000',
      low: '22.6000000000000000',
      close: '23.3631000000000000',
      volume: '11247196.2700000000000000',
      quoteVolume: 275657899.55470353,
      btcVolume: 8265.386510770037,
      usdVolume: 275657899.55470353,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '22.4042000000000000',
      high: '23.3000000000000000',
      low: '22.3137000000000000',
      close: '23.0981000000000000',
      volume: '2366823.8100000000000000',
      quoteVolume: 54138852.50846183,
      btcVolume: 1682.3671327730394,
      usdVolume: 54138852.50846183,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '23.0914000000000000',
      high: '23.2295000000000000',
      low: '20.2767000000000000',
      close: '20.9586000000000000',
      volume: '8535198.1600000000000000',
      quoteVolume: 182731435.30837923,
      btcVolume: 5920.435835584469,
      usdVolume: 182731435.30837923,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '20.9586000000000000',
      high: '24.4716000000000000',
      low: '20.5734000000000000',
      close: '23.0145000000000000',
      volume: '8468868.5600000000000000',
      quoteVolume: 197396206.1119182,
      btcVolume: 6236.369329316392,
      usdVolume: 197396206.1119182,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '22.9869000000000000',
      high: '24.2400000000000000',
      low: '22.0450000000000000',
      close: '22.7480000000000000',
      volume: '9016409.4900000000000000',
      quoteVolume: 208328902.77130583,
      btcVolume: 5907.088801742898,
      usdVolume: 208328902.77130583,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '22.7480000000000000',
      high: '23.9800000000000000',
      low: '22.1594000000000000',
      close: '23.6399000000000000',
      volume: '6773423.4600000000000000',
      quoteVolume: 156017856.8708368,
      btcVolume: 4581.931097812988,
      usdVolume: 156017856.8708368,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '23.6491000000000000',
      high: '24.6780000000000000',
      low: '22.0603000000000000',
      close: '22.5613000000000000',
      volume: '5361442.2200000000000000',
      quoteVolume: 124076481.29119983,
      btcVolume: 3697.017425316733,
      usdVolume: 124076481.29119983,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '22.5643000000000000',
      high: '23.2000000000000000',
      low: '21.6526000000000000',
      close: '22.8757000000000000',
      volume: '5564509.6300000000000000',
      quoteVolume: 124203143.57187262,
      btcVolume: 3687.457488368662,
      usdVolume: 124203143.57187262,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '22.8752000000000000',
      high: '24.1693000000000000',
      low: '22.7354000000000000',
      close: '23.6582000000000000',
      volume: '6470015.7600000000000000',
      quoteVolume: 150444221.2962143,
      btcVolume: 4337.8794213047395,
      usdVolume: 150444221.2962143,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '23.6578000000000000',
      high: '25.5674000000000000',
      low: '23.5318000000000000',
      close: '25.0870000000000000',
      volume: '8459477.4100000000000000',
      quoteVolume: 208200036.68349785,
      btcVolume: 5709.561538472078,
      usdVolume: 208200036.68349785,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '25.0814000000000000',
      high: '26.1500000000000000',
      low: '22.6174000000000000',
      close: '24.4920000000000000',
      volume: '8629885.3200000000000000',
      quoteVolume: 211063847.95773497,
      btcVolume: 5630.340958663389,
      usdVolume: 211063847.95773497,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '24.4841000000000000',
      high: '27.0000000000000000',
      low: '24.4081000000000000',
      close: '26.3034000000000000',
      volume: '9640790.8100000000000000',
      quoteVolume: 251859619.30684873,
      btcVolume: 6697.035136045025,
      usdVolume: 251859619.30684873,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '26.3023000000000000',
      high: '26.8000000000000000',
      low: '24.2081000000000000',
      close: '25.0231000000000000',
      volume: '7043800.3900000000000000',
      quoteVolume: 178656384.6660932,
      btcVolume: 4511.137870600865,
      usdVolume: 178656384.6660932,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '25.0336000000000000',
      high: '25.2836000000000000',
      low: '22.8100000000000000',
      close: '24.7254000000000000',
      volume: '6499629.3000000000000000',
      quoteVolume: 157729890.3566084,
      btcVolume: 4097.192310639251,
      usdVolume: 157729890.3566084,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '24.7335000000000000',
      high: '25.9800000000000000',
      low: '23.9587000000000000',
      close: '25.4115000000000000',
      volume: '6837186.9500000000000000',
      quoteVolume: 171815141.03402072,
      btcVolume: 4122.278957205255,
      usdVolume: 171815141.03402072,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '25.4070000000000000',
      high: '28.0000000000000000',
      low: '25.0000000000000000',
      close: '27.6034000000000000',
      volume: '9472870.3200000000000000',
      quoteVolume: 246830741.98450413,
      btcVolume: 5292.997466768552,
      usdVolume: 246830741.98450413,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '27.6150000000000000',
      high: '28.6412000000000000',
      low: '25.1227000000000000',
      close: '26.8121000000000000',
      volume: '11717346.8200000000000000',
      quoteVolume: 318420727.69046074,
      btcVolume: 6960.680395575012,
      usdVolume: 318411629.02087176,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '26.8000000000000000',
      high: '28.5705000000000000',
      low: '26.2172000000000000',
      close: '27.8475000000000000',
      volume: '6650588.7300000000000000',
      quoteVolume: 183913909.31249094,
      btcVolume: 3961.896278975156,
      usdVolume: 183913909.31249094,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '27.8645000000000000',
      high: '31.4485000000000000',
      low: '26.6746000000000000',
      close: '30.6265000000000000',
      volume: '9749785.0200000000000000',
      quoteVolume: 284077020.56706005,
      btcVolume: 5975.622685196747,
      usdVolume: 284045968.225476,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '30.6404000000000000',
      high: '34.7573000000000000',
      low: '29.0700000000000000',
      close: '34.1941000000000000',
      volume: '11011345.3200000000000000',
      quoteVolume: 346030518.51178366,
      btcVolume: 7340.703684708217,
      usdVolume: 345996921.8331248,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '34.1969000000000000',
      high: '35.6945000000000000',
      low: '32.3088000000000000',
      close: '33.1565000000000000',
      volume: '8337078.5800000000000000',
      quoteVolume: 283546794.60109466,
      btcVolume: 5868.713681268827,
      usdVolume: 283546794.60109466,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '33.1555000000000000',
      high: '34.9252000000000000',
      low: '27.5000000000000000',
      close: '32.5899000000000000',
      volume: '10469894.2400000000000000',
      quoteVolume: 335112228.9163464,
      btcVolume: 7001.374521254515,
      usdVolume: 333251261.0761585,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '32.5853000000000000',
      high: '33.4269000000000000',
      low: '30.7840000000000000',
      close: '31.9632000000000000',
      volume: '6985113.3100000000000000',
      quoteVolume: 224245398.9060409,
      btcVolume: 4510.950361788866,
      usdVolume: 220012561.30996123,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '31.9630000000000000',
      high: '32.6705000000000000',
      low: '30.0000000000000000',
      close: '32.2324000000000000',
      volume: '5259067.4900000000000000',
      quoteVolume: 166231266.71195933,
      btcVolume: 3258.0237581539295,
      usdVolume: 166231266.71195933,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '32.2256000000000000',
      high: '33.5961000000000000',
      low: '31.7785000000000000',
      close: '32.4801000000000000',
      volume: '5451118.6700000000000000',
      quoteVolume: 177745245.9786863,
      btcVolume: 3426.75446906022,
      usdVolume: 177745245.9786863,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '32.4727000000000000',
      high: '35.5072000000000000',
      low: '31.3799000000000000',
      close: '34.6846000000000000',
      volume: '8360898.6500000000000000',
      quoteVolume: 281294280.39428127,
      btcVolume: 5294.475712342659,
      usdVolume: 281294280.39428127,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '34.6846000000000000',
      high: '36.9307000000000000',
      low: '32.0000000000000000',
      close: '33.9387000000000000',
      volume: '10054962.6900000000000000',
      quoteVolume: 352536639.04229677,
      btcVolume: 6272.494218045403,
      usdVolume: 352532151.53390276,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '33.9293000000000000',
      high: '35.2918000000000000',
      low: '33.2712000000000000',
      close: '34.0915000000000000',
      volume: '5179283.2400000000000000',
      quoteVolume: 177022601.01385555,
      btcVolume: 3109.2651388863383,
      usdVolume: 177022601.01385555,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '34.0916000000000000',
      high: '34.2185000000000000',
      low: '25.2312000000000000',
      close: '31.4542000000000000',
      volume: '10543122.8600000000000000',
      quoteVolume: 319683313.051021,
      btcVolume: 5634.770333904902,
      usdVolume: 301180710.3156824,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '31.4482000000000000',
      high: '31.5822000000000000',
      low: '21.6261000000000000',
      close: '25.9249000000000000',
      volume: '21560485.0600000000000000',
      quoteVolume: 554568596.2290249,
      btcVolume: 11275.518366580607,
      usdVolume: 545229229.464444,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '25.9573000000000000',
      high: '28.8292000000000000',
      low: '24.5050000000000000',
      close: '28.0851000000000000',
      volume: '6709381.7900000000000000',
      quoteVolume: 183384362.28603324,
      btcVolume: 3687.2224811202454,
      usdVolume: 183355744.81425026,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '28.0737000000000000',
      high: '28.6802000000000000',
      low: '24.2509000000000000',
      close: '24.7144000000000000',
      volume: '7893277.6100000000000000',
      quoteVolume: 214328348.89058003,
      btcVolume: 4308.799154273922,
      usdVolume: 214328348.89058003,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '24.7144000000000000',
      high: '26.9906000000000000',
      low: '23.6574000000000000',
      close: '25.1862000000000000',
      volume: '9016978.7100000000000000',
      quoteVolume: 226299006.61160633,
      btcVolume: 4860.302348071939,
      usdVolume: 226070729.98285314,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '25.1650000000000000',
      high: '27.4472000000000000',
      low: '24.8545000000000000',
      close: '26.1896000000000000',
      volume: '7622190.2300000000000000',
      quoteVolume: 197306897.2042366,
      btcVolume: 4194.093858773107,
      usdVolume: 197306897.2042366,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '26.1941000000000000',
      high: '26.7624000000000000',
      low: '22.8578000000000000',
      close: '24.7016000000000000',
      volume: '7513099.0000000000000000',
      quoteVolume: 183181771.37759796,
      btcVolume: 4101.6732584746605,
      usdVolume: 183181771.37759796,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '24.6954000000000000',
      high: '27.7326000000000000',
      low: '24.5758000000000000',
      close: '27.7116000000000000',
      volume: '11662106.9800000000000000',
      quoteVolume: 309292384.27149624,
      btcVolume: 6431.989232060969,
      usdVolume: 309292384.27149624,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '27.7187000000000000',
      high: '30.5251000000000000',
      low: '27.1757000000000000',
      close: '28.4769000000000000',
      volume: '9706793.4600000000000000',
      quoteVolume: 280186454.4698888,
      btcVolume: 5764.268832849585,
      usdVolume: 280186454.4698888,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '28.4640000000000000',
      high: '31.4300000000000000',
      low: '28.1272000000000000',
      close: '29.8500000000000000',
      volume: '6412989.4500000000000000',
      quoteVolume: 193702490.28628224,
      btcVolume: 3844.793474650542,
      usdVolume: 193702490.28628224,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '29.8334000000000000',
      high: '30.5731000000000000',
      low: '27.1891000000000000',
      close: '27.6943000000000000',
      volume: '6789296.5900000000000000',
      quoteVolume: 196271455.16863889,
      btcVolume: 3979.194700761396,
      usdVolume: 196249322.53349993,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '27.6856000000000000',
      high: '28.2338000000000000',
      low: '25.6483000000000000',
      close: '27.8343000000000000',
      volume: '5832853.2100000000000000',
      quoteVolume: 156056362.04028454,
      btcVolume: 3286.3488640127302,
      usdVolume: 156056362.04028454,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '27.8195000000000000',
      high: '28.3985000000000000',
      low: '26.2889000000000000',
      close: '28.1479000000000000',
      volume: '3381150.1600000000000000',
      quoteVolume: 92804331.2379402,
      btcVolume: 1896.9852933650902,
      usdVolume: 91553822.80733496,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '28.1608000000000000',
      high: '29.1258000000000000',
      low: '27.3859000000000000',
      close: '28.5932000000000000',
      volume: '4599258.0200000000000000',
      quoteVolume: 130068525.6266061,
      btcVolume: 2589.6634533421866,
      usdVolume: 130068525.6266061,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '28.5918000000000000',
      high: '31.8469000000000000',
      low: '28.2652000000000000',
      close: '31.8469000000000000',
      volume: '6681348.4500000000000000',
      quoteVolume: 199010644.3594535,
      btcVolume: 3922.101818010387,
      usdVolume: 199010644.3594535,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '31.8227000000000000',
      high: '32.0000000000000000',
      low: '30.3639000000000000',
      close: '31.4914000000000000',
      volume: '4049655.7000000000000000',
      quoteVolume: 126227587.21440111,
      btcVolume: 2342.082973005306,
      usdVolume: 126227587.21440111,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '31.4914000000000000',
      high: '31.7934000000000000',
      low: '29.2545000000000000',
      close: '29.9036000000000000',
      volume: '4027599.8200000000000000',
      quoteVolume: 122397560.04919197,
      btcVolume: 2207.964000897798,
      usdVolume: 122396451.37618196,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '29.8743000000000000',
      high: '30.5285000000000000',
      low: '28.5516000000000000',
      close: '30.1166000000000000',
      volume: '5024365.7100000000000000',
      quoteVolume: 148147003.90674606,
      btcVolume: 2628.6223761839137,
      usdVolume: 148147003.90674606,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '30.1227000000000000',
      high: '30.4110000000000000',
      low: '27.3717000000000000',
      close: '28.1489000000000000',
      volume: '4477144.4100000000000000',
      quoteVolume: 128936166.34822313,
      btcVolume: 1583.1266319722276,
      usdVolume: 89974102.5483421,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '27.7838000000000000',
      high: '30.5700000000000000',
      low: '26.9326000000000000',
      close: '29.7384000000000000',
      volume: '5584451.5200000000000000',
      quoteVolume: 162487581.38361448,
      btcVolume: 2765.323829329903,
      usdVolume: 162487581.38361448,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '29.7506000000000000',
      high: '30.2000000000000000',
      low: '28.5108000000000000',
      close: '28.5469000000000000',
      volume: '3121480.3400000000000000',
      quoteVolume: 91263041.59569518,
      btcVolume: 1507.0857507872481,
      usdVolume: 91064805.56460914,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '28.5310000000000000',
      high: '29.4217000000000000',
      low: '26.8000000000000000',
      close: '27.5091000000000000',
      volume: '5236280.7600000000000000',
      quoteVolume: 145357316.8911458,
      btcVolume: 2536.539090783008,
      usdVolume: 145357316.8911458,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '27.5006000000000000',
      high: '28.1883000000000000',
      low: '26.3975000000000000',
      close: '27.9099000000000000',
      volume: '3202429.1200000000000000',
      quoteVolume: 87907886.1985268,
      btcVolume: 1592.4117076930133,
      usdVolume: 87865565.54260874,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '27.9100000000000000',
      high: '31.2000000000000000',
      low: '27.3027000000000000',
      close: '31.0579000000000000',
      volume: '8119714.6200000000000000',
      quoteVolume: 240849861.95782134,
      btcVolume: 4319.501158108978,
      usdVolume: 240849861.95782134,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '31.0579000000000000',
      high: '31.3588000000000000',
      low: '29.0000000000000000',
      close: '29.3949000000000000',
      volume: '4982847.5300000000000000',
      quoteVolume: 149502922.10217696,
      btcVolume: 2552.5916810317053,
      usdVolume: 149502922.10217696,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '29.3929000000000000',
      high: '30.5510000000000000',
      low: '28.6802000000000000',
      close: '29.7770000000000000',
      volume: '3239965.5200000000000000',
      quoteVolume: 96988651.02827796,
      btcVolume: 1661.0995932340902,
      usdVolume: 96861045.95362605,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '29.7866000000000000',
      high: '31.3809000000000000',
      low: '29.5800000000000000',
      close: '29.6465000000000000',
      volume: '2540903.5400000000000000',
      quoteVolume: 77772991.47447608,
      btcVolume: 1323.149915780175,
      usdVolume: 77770316.54868808,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '29.6564000000000000',
      high: '30.0800000000000000',
      low: '28.5817000000000000',
      close: '29.2097000000000000',
      volume: '2532521.5100000000000000',
      quoteVolume: 74287913.29808004,
      btcVolume: 1297.2908602936614,
      usdVolume: 74260278.37190706,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '29.2117000000000000',
      high: '29.4444000000000000',
      low: '27.1362000000000000',
      close: '27.2431000000000000',
      volume: '3564715.9100000000000000',
      quoteVolume: 101808090.24197517,
      btcVolume: 1809.4616360427897,
      usdVolume: 101741677.36866613,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '27.2551000000000000',
      high: '27.9351000000000000',
      low: '26.6283000000000000',
      close: '26.7813000000000000',
      volume: '3659416.4600000000000000',
      quoteVolume: 99812413.47572565,
      btcVolume: 1215.8619127352338,
      usdVolume: 66191594.700597785,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '26.7831000000000000',
      high: '27.8814000000000000',
      low: '24.0404000000000000',
      close: '24.8684000000000000',
      volume: '4061588.6900000000000000',
      quoteVolume: 106692601.87539235,
      btcVolume: 1959.2264580328283,
      usdVolume: 106692601.87539235,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '24.8679000000000000',
      high: '26.6401000000000000',
      low: '24.3665000000000000',
      close: '25.5350000000000000',
      volume: '4189607.3400000000000000',
      quoteVolume: 106697254.9266262,
      btcVolume: 1689.745893902917,
      usdVolume: 87698677.2009626,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '25.5300000000000000',
      high: '27.4000000000000000',
      low: '25.3993000000000000',
      close: '27.3927000000000000',
      volume: '3356222.6100000000000000',
      quoteVolume: 88633668.99963559,
      btcVolume: 1599.8484710312273,
      usdVolume: 85435262.31795225,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '27.3988000000000000',
      high: '27.4982000000000000',
      low: '26.0030000000000000',
      close: '26.8766000000000000',
      volume: '2032627.2400000000000000',
      quoteVolume: 54412158.06017271,
      btcVolume: 986.9278333127505,
      usdVolume: 54406973.49173972,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '26.8765000000000000',
      high: '27.6624000000000000',
      low: '26.0889000000000000',
      close: '26.6588000000000000',
      volume: '2205293.2300000000000000',
      quoteVolume: 59418251.07729596,
      btcVolume: 1064.179617444524,
      usdVolume: 59406433.69876095,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '26.6576000000000000',
      high: '28.6203000000000000',
      low: '26.3886000000000000',
      close: '28.0357000000000000',
      volume: '3528308.7300000000000000',
      quoteVolume: 97966057.5120884,
      btcVolume: 1709.0159853740718,
      usdVolume: 97916578.44213544,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '28.0356000000000000',
      high: '28.5735000000000000',
      low: '27.4460000000000000',
      close: '27.8301000000000000',
      volume: '2521474.6200000000000000',
      quoteVolume: 70923243.6872522,
      btcVolume: 1213.622206853287,
      usdVolume: 70920571.39197218,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '27.8300000000000000',
      high: '29.4401000000000000',
      low: '26.1800000000000000',
      close: '29.4167000000000000',
      volume: '4353005.8100000000000000',
      quoteVolume: 120857861.76495478,
      btcVolume: 2051.9849029876646,
      usdVolume: 120365548.36152953,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '29.4168000000000000',
      high: '31.1250000000000000',
      low: '29.3143000000000000',
      close: '30.0684000000000000',
      volume: '5308598.1000000000000000',
      quoteVolume: 159884612.16588667,
      btcVolume: 2714.508183126401,
      usdVolume: 159764276.83964464,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '30.0808000000000000',
      high: '32.5555000000000000',
      low: '29.5463000000000000',
      close: '32.4864000000000000',
      volume: '4245194.7400000000000000',
      quoteVolume: 131458201.24949433,
      btcVolume: 2219.951784391892,
      usdVolume: 131448573.62730134,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '32.4867000000000000',
      high: '33.1707000000000000',
      low: '29.1256000000000000',
      close: '29.2930000000000000',
      volume: '5326728.5500000000000000',
      quoteVolume: 167961809.66658044,
      btcVolume: 1795.4395891216325,
      usdVolume: 105857363.34651382,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '29.2930000000000000',
      high: '31.4643000000000000',
      low: '28.6801000000000000',
      close: '30.7471000000000000',
      volume: '2738370.5800000000000000',
      quoteVolume: 83118846.51577824,
      btcVolume: 1249.3258134334203,
      usdVolume: 72051166.9085144,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '30.7473000000000000',
      high: '32.6399000000000000',
      low: '29.6179000000000000',
      close: '32.3791000000000000',
      volume: '4976271.3600000000000000',
      quoteVolume: 155152448.59764713,
      btcVolume: 1991.659027501243,
      usdVolume: 115877633.66876267,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '32.3791000000000000',
      high: '34.9000000000000000',
      low: '31.3074000000000000',
      close: '34.7250000000000000',
      volume: '7244775.1200000000000000',
      quoteVolume: 240433366.91596004,
      btcVolume: 3797.508343023463,
      usdVolume: 221680600.84789953,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '34.7295000000000000',
      high: '35.1200000000000000',
      low: '30.2286000000000000',
      close: '31.1090000000000000',
      volume: '7241883.5600000000000000',
      quoteVolume: 235343305.11428273,
      btcVolume: 3884.6148457101895,
      usdVolume: 221471220.7998364,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '31.1008000000000000',
      high: '32.8999000000000000',
      low: '30.9466000000000000',
      close: '32.7884000000000000',
      volume: '3371094.7200000000000000',
      quoteVolume: 108183402.49988818,
      btcVolume: 1171.9067624824738,
      usdVolume: 66948808.547933206,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '32.7870000000000000',
      high: '33.2876000000000000',
      low: '31.2348000000000000',
      close: '31.5893000000000000',
      volume: '2728512.6100000000000000',
      quoteVolume: 87716711.54413132,
      btcVolume: 1274.446364485902,
      usdVolume: 74249858.40623835,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '31.5961000000000000',
      high: '33.0000000000000000',
      low: '31.0000000000000000',
      close: '31.8944000000000000',
      volume: '3545699.4300000000000000',
      quoteVolume: 113928069.33940087,
      btcVolume: 1828.0827861744679,
      usdVolume: 109414242.01156527,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '31.8980000000000000',
      high: '34.4397000000000000',
      low: '31.7283000000000000',
      close: '33.8811000000000000',
      volume: '4949345.2000000000000000',
      quoteVolume: 164105171.51139703,
      btcVolume: 2677.3634365869484,
      usdVolume: 160031551.35507315,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '33.8811000000000000',
      high: '34.1468000000000000',
      low: '31.8601000000000000',
      close: '33.1080000000000000',
      volume: '3732041.9700000000000000',
      quoteVolume: 123252667.28419851,
      btcVolume: 1636.5603716794567,
      usdVolume: 98349366.92374945,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '33.1023000000000000',
      high: '35.8645000000000000',
      low: '32.3842000000000000',
      close: '35.5217000000000000',
      volume: '5624044.8000000000000000',
      quoteVolume: 192994936.86974585,
      btcVolume: 2998.5339331543933,
      usdVolume: 188012549.6792243,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '35.5416000000000000',
      high: '41.9901000000000000',
      low: '35.0000000000000000',
      close: '41.3641000000000000',
      volume: '13418401.1400000000000000',
      quoteVolume: 506888645.13665795,
      btcVolume: 6514.2283925472175,
      usdVolume: 412737241.69925255,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '41.3371000000000000',
      high: '44.3300000000000000',
      low: '39.6638000000000000',
      close: '42.5601000000000000',
      volume: '12581718.5700000000000000',
      quoteVolume: 528214652.25350446,
      btcVolume: 7445.637896817438,
      usdVolume: 467700360.8493485,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '42.5654000000000000',
      high: '43.2902000000000000',
      low: '38.5273000000000000',
      close: '41.9861000000000000',
      volume: '9648189.8700000000000000',
      quoteVolume: 396462710.2078966,
      btcVolume: 5702.375812210699,
      usdVolume: 350891882.7305064,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '41.9797000000000000',
      high: '44.1116000000000000',
      low: '39.8304000000000000',
      close: '39.9137000000000000',
      volume: '6757853.5600000000000000',
      quoteVolume: 284759254.2188944,
      btcVolume: 2680.560705167462,
      usdVolume: 164429323.88150203,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '39.9135000000000000',
      high: '40.3436000000000000',
      low: '29.0000000000000000',
      close: '39.0799000000000000',
      volume: '14640165.5300000000000000',
      quoteVolume: 530375955.0032846,
      btcVolume: 9097.744625141062,
      usdVolume: 507371033.8404291,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '39.0858000000000000',
      high: '41.9900000000000000',
      low: '34.8144000000000000',
      close: '35.7359000000000000',
      volume: '12978321.7600000000000000',
      quoteVolume: 497670097.2335755,
      btcVolume: 6174.098440246101,
      usdVolume: 347089895.7808836,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '35.7504000000000000',
      high: '39.3901000000000000',
      low: '33.1933000000000000',
      close: '38.9954000000000000',
      volume: '11956908.1000000000000000',
      quoteVolume: 437511825.9164905,
      btcVolume: 7196.936350920713,
      usdVolume: 398982378.77708787,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '38.9983000000000000',
      high: '39.4872000000000000',
      low: '35.8933000000000000',
      close: '36.2274000000000000',
      volume: '6732313.3700000000000000',
      quoteVolume: 253633014.1501259,
      btcVolume: 3719.7137046466846,
      usdVolume: 206621089.2032104,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '36.2364000000000000',
      high: '40.8200000000000000',
      low: '33.7859000000000000',
      close: '35.1306000000000000',
      volume: '11519039.9400000000000000',
      quoteVolume: 432182126.09576267,
      btcVolume: 2025.7054502568894,
      usdVolume: 107167666.04858308,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '35.1256000000000000',
      high: '35.9643000000000000',
      low: '30.2771000000000000',
      close: '35.2121000000000000',
      volume: '14134304.6300000000000000',
      quoteVolume: 465142368.4095787,
      btcVolume: 4763.5180098543515,
      usdVolume: 237164782.14820454,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '35.2121000000000000',
      high: '35.3033000000000000',
      low: '31.0000000000000000',
      close: '31.2443000000000000',
      volume: '6295773.8600000000000000',
      quoteVolume: 206376774.11149526,
      btcVolume: 3817.3264985910882,
      usdVolume: 190343281.2332728,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '31.2370000000000000',
      high: '33.4320000000000000',
      low: '29.8010000000000000',
      close: '31.6920000000000000',
      volume: '4274733.3210000000000000',
      quoteVolume: 135910873.76321998,
      btcVolume: 1405.9735040050004,
      usdVolume: 69698982.35040447,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '31.6900000000000000',
      high: '35.2750000000000000',
      low: '31.3630000000000000',
      close: '35.1300000000000000',
      volume: '5764275.4540000000000000',
      quoteVolume: 196917985.91491917,
      btcVolume: 1940.686409307913,
      usdVolume: 102715801.07328665,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '35.1120000000000000',
      high: '37.6650000000000000',
      low: '34.4830000000000000',
      close: '36.7880000000000000',
      volume: '5453945.1760000000000000',
      quoteVolume: 197434451.9676538,
      btcVolume: 3508.307389527573,
      usdVolume: 191567073.98651674,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '36.7860000000000000',
      high: '37.8080000000000000',
      low: '34.9010000000000000',
      close: '36.2330000000000000',
      volume: '5218990.7380000000000000',
      quoteVolume: 188499275.87359715,
      btcVolume: 3443.115782242929,
      usdVolume: 188499275.87359715,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '36.2530000000000000',
      high: '37.4500000000000000',
      low: '35.0000000000000000',
      close: '36.4670000000000000',
      volume: '4960983.1160000000000000',
      quoteVolume: 178985701.04202038,
      btcVolume: 3326.1200982145674,
      usdVolume: 178985701.04202038,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '36.4650000000000000',
      high: '38.7900000000000000',
      low: '35.8270000000000000',
      close: '38.1000000000000000',
      volume: '5036107.6020000000000000',
      quoteVolume: 189862115.18069306,
      btcVolume: 2169.133779042782,
      usdVolume: 119700732.92635407,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '38.1010000000000000',
      high: '41.2700000000000000',
      low: '37.4280000000000000',
      close: '41.2570000000000000',
      volume: '5223059.2340000000000000',
      quoteVolume: 205464629.83196887,
      btcVolume: 1552.158704895812,
      usdVolume: 89600807.00053962,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '41.2490000000000000',
      high: '41.4600000000000000',
      low: '38.6900000000000000',
      close: '39.4610000000000000',
      volume: '5198773.9980000000000000',
      quoteVolume: 206970460.92982715,
      btcVolume: 2174.314081160398,
      usdVolume: 123461594.93853049,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '39.4610000000000000',
      high: '42.7280000000000000',
      low: '39.1660000000000000',
      close: '42.0520000000000000',
      volume: '6010571.0640000000000000',
      quoteVolume: 246579333.6021091,
      btcVolume: 4045.734938153716,
      usdVolume: 233712903.15058511,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '42.0600000000000000',
      high: '48.5400000000000000',
      low: '38.6340000000000000',
      close: '45.5270000000000000',
      volume: '19237312.3900000000000000',
      quoteVolume: 845809208.6302068,
      btcVolume: 10465.55258596805,
      usdVolume: 575221269.0704849,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '45.5080000000000000',
      high: '51.2000000000000000',
      low: '44.8650000000000000',
      close: '49.5750000000000000',
      volume: '13527073.0750000000000000',
      quoteVolume: 657942604.5017196,
      btcVolume: 10857.379037079556,
      usdVolume: 601168078.6758198,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '49.5760000000000000',
      high: '49.9000000000000000',
      low: '45.3430000000000000',
      close: '47.1540000000000000',
      volume: '9482621.1830000000000000',
      quoteVolume: 450955107.1350383,
      btcVolume: 7582.913753017384,
      usdVolume: 430590815.2935991,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '47.1480000000000000',
      high: '51.9690000000000000',
      low: '45.6660000000000000',
      close: '49.1290000000000000',
      volume: '10577050.1670000000000000',
      quoteVolume: 515234804.58814085,
      btcVolume: 8494.174715359122,
      usdVolume: 484259627.57465845,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '49.1340000000000000',
      high: '50.2650000000000000',
      low: '46.5000000000000000',
      close: '48.5970000000000000',
      volume: '5883822.5840000000000000',
      quoteVolume: 283989339.17529905,
      btcVolume: 4686.917318376704,
      usdVolume: 273537936.3731609,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '48.6070000000000000',
      high: '52.4260000000000000',
      low: '45.8300000000000000',
      close: '52.2690000000000000',
      volume: '8588355.4620000000000000',
      quoteVolume: 426164154.7903979,
      btcVolume: 7030.02275298761,
      usdVolume: 405961264.1488087,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '52.2770000000000000',
      high: '53.0000000000000000',
      low: '41.1600000000000000',
      close: '46.3340000000000000',
      volume: '9923820.9180000000000000',
      quoteVolume: 486677346.87598133,
      btcVolume: 6276.730764781867,
      usdVolume: 357244261.1626152,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '46.3260000000000000',
      high: '49.0000000000000000',
      low: '44.8000000000000000',
      close: '48.8260000000000000',
      volume: '6709350.2410000000000000',
      quoteVolume: 312517087.9033863,
      btcVolume: 4159.683255202934,
      usdVolume: 232124988.1499912,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '48.8050000000000000',
      high: '49.8360000000000000',
      low: '40.9140000000000000',
      close: '41.0070000000000000',
      volume: '8272510.7590000000000000',
      quoteVolume: 383764473.964816,
      btcVolume: 6696.055930825579,
      usdVolume: 367665436.85888445,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '41.5410000000000000',
      high: '44.9580000000000000',
      low: '40.0660000000000000',
      close: '43.2870000000000000',
      volume: '9769701.1020000000000000',
      quoteVolume: 415729412.96724325,
      btcVolume: 7858.229520494778,
      usdVolume: 389116685.6106139,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '43.2730000000000000',
      high: '47.8750000000000000',
      low: '42.9350000000000000',
      close: '46.5670000000000000',
      volume: '6297019.0800000000000000',
      quoteVolume: 286301712.8153783,
      btcVolume: 5462.904943375931,
      usdVolume: 274286488.952303,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '46.5580000000000000',
      high: '47.8000000000000000',
      low: '40.9750000000000000',
      close: '41.4920000000000000',
      volume: '9851823.9350000000000000',
      quoteVolume: 432218709.2421017,
      btcVolume: 8544.101354516299,
      usdVolume: 415295386.7222744,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '41.5120000000000000',
      high: '44.9010000000000000',
      low: '37.9440000000000000',
      close: '40.6700000000000000',
      volume: '9745811.4610000000000000',
      quoteVolume: 402376827.20252913,
      btcVolume: 7873.992844695695,
      usdVolume: 370599721.498782,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '40.6620000000000000',
      high: '40.6740000000000000',
      low: '35.0680000000000000',
      close: '37.1770000000000000',
      volume: '10362229.7890000000000000',
      quoteVolume: 390099667.8940053,
      btcVolume: 8288.179682427983,
      usdVolume: 364544533.9681069,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '37.1760000000000000',
      high: '44.6900000000000000',
      low: '36.6550000000000000',
      close: '42.5660000000000000',
      volume: '11232354.3090000000000000',
      quoteVolume: 458226371.3004289,
      btcVolume: 9965.215932435603,
      usdVolume: 436152928.96179956,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '42.5550000000000000',
      high: '43.5000000000000000',
      low: '21.0000000000000000',
      close: '26.2270000000000000',
      volume: '20210187.3060000000000000',
      quoteVolume: 664059850.4303083,
      btcVolume: 13824.814824954283,
      usdVolume: 538586189.5562819,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '26.2270000000000000',
      high: '33.0500000000000000',
      low: '22.3500000000000000',
      close: '30.6060000000000000',
      volume: '20323256.5380000000000000',
      quoteVolume: 577592046.0905249,
      btcVolume: 10784.808827983394,
      usdVolume: 422558516.6124092,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '30.6330000000000000',
      high: '31.9450000000000000',
      low: '22.0190000000000000',
      close: '25.5460000000000000',
      volume: '13068081.0750000000000000',
      quoteVolume: 353993914.03694314,
      btcVolume: 6932.648993527497,
      usdVolume: 266425708.4751106,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '25.5480000000000000',
      high: '26.2000000000000000',
      low: '22.2250000000000000',
      close: '23.2960000000000000',
      volume: '13032278.1370000000000000',
      quoteVolume: 314829379.610612,
      btcVolume: 5695.951410606267,
      usdVolume: 211218954.28534862,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '23.2930000000000000',
      high: '24.3680000000000000',
      low: '15.0000000000000000',
      close: '19.8440000000000000',
      volume: '28816332.7880000000000000',
      quoteVolume: 543985798.5938362,
      btcVolume: 15126.42507829283,
      usdVolume: 517948800.9596924,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '19.8490000000000000',
      high: '26.4310000000000000',
      low: '19.6770000000000000',
      close: '26.4120000000000000',
      volume: '18217984.6480000000000000',
      quoteVolume: 425942553.28623766,
      btcVolume: 10844.812716329057,
      usdVolume: 401451530.6456157,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '26.4000000000000000',
      high: '27.6440000000000000',
      low: '23.6730000000000000',
      close: '27.5030000000000000',
      volume: '13594283.8180000000000000',
      quoteVolume: 349644756.5528623,
      btcVolume: 8716.278447151888,
      usdVolume: 331359167.24137896,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '27.5050000000000000',
      high: '34.2850000000000000',
      low: '26.8470000000000000',
      close: '34.0180000000000000',
      volume: '14862009.4600000000000000',
      quoteVolume: 456256699.2802189,
      btcVolume: 11201.464045901757,
      usdVolume: 439295034.0456097,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '34.0300000000000000',
      high: '35.3340000000000000',
      low: '30.0330000000000000',
      close: '31.7320000000000000',
      volume: '14928881.5110000000000000',
      quoteVolume: 489547108.0042761,
      btcVolume: 12329.95986455298,
      usdVolume: 477724155.1958763,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '31.7230000000000000',
      high: '32.1850000000000000',
      low: '26.8210000000000000',
      close: '27.8780000000000000',
      volume: '18297258.3920000000000000',
      quoteVolume: 533840739.506926,
      btcVolume: 13937.929959889794,
      usdVolume: 508564072.9063156,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '27.8720000000000000',
      high: '29.7340000000000000',
      low: '24.1200000000000000',
      close: '25.3650000000000000',
      volume: '11813482.7710000000000000',
      quoteVolume: 314260807.1404808,
      btcVolume: 8707.412591102062,
      usdVolume: 305391847.5639692,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '25.3770000000000000',
      high: '29.1680000000000000',
      low: '23.7000000000000000',
      close: '26.8180000000000000',
      volume: '11542374.4850000000000000',
      quoteVolume: 311943247.68354595,
      btcVolume: 8749.034315009025,
      usdVolume: 309860777.6783972,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '26.8130000000000000',
      high: '32.1590000000000000',
      low: '25.5550000000000000',
      close: '32.0980000000000000',
      volume: '11473684.1530000000000000',
      quoteVolume: 330074023.8033171,
      btcVolume: 9049.198752953676,
      usdVolume: 328513729.3170985,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '32.0980000000000000',
      high: '32.6500000000000000',
      low: '29.0350000000000000',
      close: '30.7190000000000000',
      volume: '7595288.2510000000000000',
      quoteVolume: 231579652.6416101,
      btcVolume: 6228.039389492023,
      usdVolume: 228605999.2276237,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '30.7290000000000000',
      high: '32.2180000000000000',
      low: '29.7000000000000000',
      close: '30.6700000000000000',
      volume: '5992710.5570000000000000',
      quoteVolume: 184925689.9538908,
      btcVolume: 4910.509911672765,
      usdVolume: 183636440.3796502,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '30.6690000000000000',
      high: '32.8560000000000000',
      low: '30.0000000000000000',
      close: '32.1980000000000000',
      volume: '6926586.1530000000000000',
      quoteVolume: 220195095.12678242,
      btcVolume: 5686.208264256343,
      usdVolume: 219529228.41781366,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '32.1990000000000000',
      high: '32.2670000000000000',
      low: '27.1720000000000000',
      close: '28.8260000000000000',
      volume: '10756129.4560000000000000',
      quoteVolume: 310574364.8097001,
      btcVolume: 8079.12819316762,
      usdVolume: 299137467.401279,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '28.8290000000000000',
      high: '30.1860000000000000',
      low: '26.0440000000000000',
      close: '27.1290000000000000',
      volume: '8021172.9850000000000000',
      quoteVolume: 224556115.0710708,
      btcVolume: 6072.610138454598,
      usdVolume: 220742793.7734021,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '27.1270000000000000',
      high: '28.1900000000000000',
      low: '26.8180000000000000',
      close: '27.5320000000000000',
      volume: '4071729.1110000000000000',
      quoteVolume: 111593094.49793832,
      btcVolume: 3094.66184741806,
      usdVolume: 111191327.61157948,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '27.5300000000000000',
      high: '28.6930000000000000',
      low: '24.2340000000000000',
      close: '24.5840000000000000',
      volume: '7593474.6300000000000000',
      quoteVolume: 203546009.9958056,
      btcVolume: 5664.404814150535,
      usdVolume: 201030726.639649,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '24.5740000000000000',
      high: '25.0980000000000000',
      low: '21.3340000000000000',
      close: '24.0760000000000000',
      volume: '12533550.4740000000000000',
      quoteVolume: 295383608.8363167,
      btcVolume: 8748.60414936731,
      usdVolume: 286178492.67321956,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '24.0790000000000000',
      high: '25.6260000000000000',
      low: '22.4410000000000000',
      close: '25.5850000000000000',
      volume: '10755352.9180000000000000',
      quoteVolume: 259836017.6864675,
      btcVolume: 7275.701651707724,
      usdVolume: 253653794.22880164,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '25.5890000000000000',
      high: '25.7800000000000000',
      low: '22.7210000000000000',
      close: '23.1140000000000000',
      volume: '8385905.0280000000000000',
      quoteVolume: 201718092.68922827,
      btcVolume: 5398.213856768574,
      usdVolume: 199614065.45911995,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '23.1170000000000000',
      high: '23.3740000000000000',
      low: '21.0000000000000000',
      close: '21.4780000000000000',
      volume: '7674813.3900000000000000',
      quoteVolume: 172255303.31648567,
      btcVolume: 4653.500277683774,
      usdVolume: 171879481.40592545,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '21.4750000000000000',
      high: '22.2870000000000000',
      low: '20.0000000000000000',
      close: '21.6700000000000000',
      volume: '8849284.6130000000000000',
      quoteVolume: 186854318.51986963,
      btcVolume: 5161.93685251144,
      usdVolume: 184384310.62285846,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '21.6710000000000000',
      high: '23.8860000000000000',
      low: '20.3960000000000000',
      close: '23.3120000000000000',
      volume: '8516193.5180000000000000',
      quoteVolume: 184993026.3649717,
      btcVolume: 5018.546755318045,
      usdVolume: 183990625.90337497,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '23.3140000000000000',
      high: '25.2800000000000000',
      low: '22.8370000000000000',
      close: '24.9730000000000000',
      volume: '8598594.0080000000000000',
      quoteVolume: 205633987.74170867,
      btcVolume: 5124.726546014837,
      usdVolume: 204447032.09525958,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '24.9670000000000000',
      high: '26.4580000000000000',
      low: '24.3760000000000000',
      close: '24.4820000000000000',
      volume: '7585322.2020000000000000',
      quoteVolume: 193173661.50221747,
      btcVolume: 4783.978518048711,
      usdVolume: 192339881.37543938,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '24.4800000000000000',
      high: '24.6400000000000000',
      low: '22.9180000000000000',
      close: '23.0440000000000000',
      volume: '7495769.8280000000000000',
      quoteVolume: 178312029.61373052,
      btcVolume: 4478.7932855389,
      usdVolume: 176264709.6132166,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '23.0420000000000000',
      high: '24.4450000000000000',
      low: '22.8100000000000000',
      close: '23.3960000000000000',
      volume: '5230276.4320000000000000',
      quoteVolume: 123641681.8274587,
      btcVolume: 3158.8471853964566,
      usdVolume: 121967618.36204572,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '23.4010000000000000',
      high: '23.4250000000000000',
      low: '20.5260000000000000',
      close: '21.2330000000000000',
      volume: '6979309.1900000000000000',
      quoteVolume: 152221963.2704614,
      btcVolume: 4077.4579812512793,
      usdVolume: 150001420.01759854,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '21.2360000000000000',
      high: '21.9500000000000000',
      low: '20.0000000000000000',
      close: '20.2460000000000000',
      volume: '5015956.9490000000000000',
      quoteVolume: 105884657.21403985,
      btcVolume: 2957.13775712415,
      usdVolume: 105574108.67201178,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '20.2630000000000000',
      high: '22.0710000000000000',
      low: '19.0770000000000000',
      close: '21.6460000000000000',
      volume: '6709724.1760000000000000',
      quoteVolume: 136982147.47085837,
      btcVolume: 3882.7672656123696,
      usdVolume: 135510283.7439176,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '21.6410000000000000',
      high: '21.8810000000000000',
      low: '16.9810000000000000',
      close: '17.3650000000000000',
      volume: '16151190.0200000000000000',
      quoteVolume: 304924588.43656045,
      btcVolume: 9030.919682300091,
      usdVolume: 296698047.37943095,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '17.3570000000000000',
      high: '18.7970000000000000',
      low: '15.0000000000000000',
      close: '16.8500000000000000',
      volume: '19773357.5780000000000000',
      quoteVolume: 331030753.695022,
      btcVolume: 10016.698622465823,
      usdVolume: 314934189.5838592,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '16.8400000000000000',
      high: '19.1990000000000000',
      low: '16.1490000000000000',
      close: '18.3950000000000000',
      volume: '10930842.9690000000000000',
      quoteVolume: 198104336.2413875,
      btcVolume: 5677.69271694415,
      usdVolume: 191351833.36313608,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '18.3930000000000000',
      high: '19.5250000000000000',
      low: '17.3520000000000000',
      close: '19.0600000000000000',
      volume: '6708926.7880000000000000',
      quoteVolume: 124897019.4366688,
      btcVolume: 3676.6447137809605,
      usdVolume: 124160107.18648963,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '19.0600000000000000',
      high: '19.5000000000000000',
      low: '16.8130000000000000',
      close: '16.8700000000000000',
      volume: '10048473.2290000000000000',
      quoteVolume: 180145249.5753493,
      btcVolume: 5424.613837041398,
      usdVolume: 179815215.34201533,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '16.8720000000000000',
      high: '17.4950000000000000',
      low: '15.9200000000000000',
      close: '16.9520000000000000',
      volume: '8343823.1670000000000000',
      quoteVolume: 138685945.58320868,
      btcVolume: 4317.21304493639,
      usdVolume: 135592125.3028405,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '16.9510000000000000',
      high: '18.4120000000000000',
      low: '16.5910000000000000',
      close: '18.4060000000000000',
      volume: '6649981.0660000000000000',
      quoteVolume: 114152516.37204297,
      btcVolume: 3403.722727537482,
      usdVolume: 112739292.89915286,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '18.4070000000000000',
      high: '19.4340000000000000',
      low: '17.9020000000000000',
      close: '18.8300000000000000',
      volume: '7282776.6190000000000000',
      quoteVolume: 135071888.30002892,
      btcVolume: 3906.1907284798945,
      usdVolume: 134704190.30824476,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '18.8300000000000000',
      high: '20.5460000000000000',
      low: '18.7520000000000000',
      close: '19.5350000000000000',
      volume: '6574919.5160000000000000',
      quoteVolume: 129862268.00902891,
      btcVolume: 3645.3920298803437,
      usdVolume: 129659684.4622509,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '19.5310000000000000',
      high: '19.7730000000000000',
      low: '18.1490000000000000',
      close: '19.5160000000000000',
      volume: '6554785.3410000000000000',
      quoteVolume: 124177607.62473075,
      btcVolume: 3561.7088830145212,
      usdVolume: 124094990.07156971,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '19.5170000000000000',
      high: '19.5330000000000000',
      low: '17.6030000000000000',
      close: '18.1860000000000000',
      volume: '6074285.8190000000000000',
      quoteVolume: 110566739.74832156,
      btcVolume: 3272.3128181398106,
      usdVolume: 110064065.08651572,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '18.1840000000000000',
      high: '18.5230000000000000',
      low: '17.2560000000000000',
      close: '18.2740000000000000',
      volume: '5394371.4130000000000000',
      quoteVolume: 95752765.26831836,
      btcVolume: 2873.9523665643746,
      usdVolume: 95533297.25328133,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '18.2770000000000000',
      high: '18.8940000000000000',
      low: '17.8640000000000000',
      close: '18.5430000000000000',
      volume: '3247443.1790000000000000',
      quoteVolume: 59971889.21668191,
      btcVolume: 1743.3323281938071,
      usdVolume: 59881835.060770914,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '18.5430000000000000',
      high: '19.9250000000000000',
      low: '18.0020000000000000',
      close: '19.2420000000000000',
      volume: '4486291.0750000000000000',
      quoteVolume: 85193944.29341578,
      btcVolume: 2409.86380003794,
      usdVolume: 85114681.7393157,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '19.2380000000000000',
      high: '19.2420000000000000',
      low: '17.7670000000000000',
      close: '18.3620000000000000',
      volume: '5244315.0980000000000000',
      quoteVolume: 96893912.50225921,
      btcVolume: 2815.4797708645337,
      usdVolume: 95800634.92579712,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '18.3640000000000000',
      high: '20.2710000000000000',
      low: '18.3170000000000000',
      close: '20.0730000000000000',
      volume: '7942424.0840000000000000',
      quoteVolume: 155645652.46806923,
      btcVolume: 4515.283695510405,
      usdVolume: 154167558.60065004,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '20.0730000000000000',
      high: '21.2740000000000000',
      low: '19.6200000000000000',
      close: '19.7880000000000000',
      volume: '7220562.4210000000000000',
      quoteVolume: 148395155.42902967,
      btcVolume: 4299.919566919362,
      usdVolume: 148395155.42902967,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '19.7860000000000000',
      high: '19.8730000000000000',
      low: '18.1380000000000000',
      close: '18.3530000000000000',
      volume: '7787413.9360000000000000',
      quoteVolume: 146836983.45400667,
      btcVolume: 4442.217674403576,
      usdVolume: 145991527.75807318,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '18.3650000000000000',
      high: '18.8770000000000000',
      low: '17.6170000000000000',
      close: '18.6560000000000000',
      volume: '5272793.7130000000000000',
      quoteVolume: 96043496.92060412,
      btcVolume: 2903.464250314309,
      usdVolume: 96030029.07034913,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '18.6560000000000000',
      high: '18.9880000000000000',
      low: '17.7340000000000000',
      close: '18.2040000000000000',
      volume: '4078447.0630000000000000',
      quoteVolume: 74240182.6654956,
      btcVolume: 2201.704167208646,
      usdVolume: 74224861.40051863,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '18.2090000000000000',
      high: '18.5810000000000000',
      low: '17.8610000000000000',
      close: '18.3830000000000000',
      volume: '2730333.2010000000000000',
      quoteVolume: 49800226.79839872,
      btcVolume: 1472.494351442755,
      usdVolume: 49793240.52506473,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '18.3820000000000000',
      high: '18.6090000000000000',
      low: '17.0000000000000000',
      close: '17.5180000000000000',
      volume: '3783915.1710000000000000',
      quoteVolume: 67410348.47561233,
      btcVolume: 2009.4146255562289,
      usdVolume: 67298959.85275029,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '17.5140000000000000',
      high: '17.7660000000000000',
      low: '16.6630000000000000',
      close: '16.8700000000000000',
      volume: '3488186.4250000000000000',
      quoteVolume: 60230003.646684654,
      btcVolume: 1836.6843254841956,
      usdVolume: 60221186.657093674,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '16.8730000000000000',
      high: '17.4190000000000000',
      low: '16.0140000000000000',
      close: '16.9360000000000000',
      volume: '5441562.0200000000000000',
      quoteVolume: 90914973.0594299,
      btcVolume: 2788.0780014295497,
      usdVolume: 90500474.54788788,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '16.9360000000000000',
      high: '17.2380000000000000',
      low: '15.5170000000000000',
      close: '15.7640000000000000',
      volume: '6198386.0130000000000000',
      quoteVolume: 99985406.05859742,
      btcVolume: 3118.566762095075,
      usdVolume: 99653864.88515042,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '15.7640000000000000',
      high: '16.3300000000000000',
      low: '15.1250000000000000',
      close: '15.2340000000000000',
      volume: '4106915.0750000000000000',
      quoteVolume: 64218657.385411024,
      btcVolume: 2026.355929873666,
      usdVolume: 64218546.10895903,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '15.2340000000000000',
      high: '15.6780000000000000',
      low: '15.0200000000000000',
      close: '15.3520000000000000',
      volume: '4957872.3330000000000000',
      quoteVolume: 76011680.64757319,
      btcVolume: 2401.8918472972196,
      usdVolume: 75811199.49437419,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '15.3520000000000000',
      high: '16.0780000000000000',
      low: '15.1550000000000000',
      close: '15.4700000000000000',
      volume: '3946657.1550000000000000',
      quoteVolume: 61781931.20700029,
      btcVolume: 1945.5860240070042,
      usdVolume: 61763430.72707828,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '15.4750000000000000',
      high: '15.6410000000000000',
      low: '14.4040000000000000',
      close: '14.5280000000000000',
      volume: '5828975.8470000000000000',
      quoteVolume: 86676083.7316871,
      btcVolume: 2787.3535740700127,
      usdVolume: 86630306.81259608,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '14.5290000000000000',
      high: '14.7830000000000000',
      low: '13.3840000000000000',
      close: '13.7420000000000000',
      volume: '7649757.9780000000000000',
      quoteVolume: 105449843.69424452,
      btcVolume: 3529.459900354445,
      usdVolume: 105367660.82367453,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '13.7440000000000000',
      high: '15.7970000000000000',
      low: '13.4600000000000000',
      close: '15.2970000000000000',
      volume: '8493449.3690000000000000',
      quoteVolume: 125644680.88342938,
      btcVolume: 3997.9525201581914,
      usdVolume: 124994012.93451238,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '15.3000000000000000',
      high: '16.1760000000000000',
      low: '14.9610000000000000',
      close: '16.0710000000000000',
      volume: '5857032.9790000000000000',
      quoteVolume: 90738306.1379759,
      btcVolume: 2820.8161649730764,
      usdVolume: 90645833.05113186,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '16.0730000000000000',
      high: '16.4860000000000000',
      low: '15.2300000000000000',
      close: '15.5970000000000000',
      volume: '4154495.5190000000000000',
      quoteVolume: 66029359.02171643,
      btcVolume: 2038.14247445674,
      usdVolume: 66029359.02171643,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
    comp: {
    id: 'comp',
    name: 'COMP',
    symbol: 'COMP',
    image: 'assets/images/coin-logo/COMP.png',
    activity24h: 1241256,
    availableAmount: 1328.232323,
    usdxConversion: 1,
    allTimeValue: 51512390,
    allTimeAsset: 765421,
    candleData: [
      {
      open: '9.7285000000000000',
      high: '9.7638000000000000',
      low: '9.6022000000000000',
      close: '9.7207000000000000',
      volume: '838913.9200000000000000',
      quoteVolume: 8136588.44204,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-28T00:00:00.000Z'
      },
      {
      open: '9.7207000000000000',
      high: '9.7317000000000000',
      low: '9.3748000000000000',
      close: '9.4450000000000000',
      volume: '788866.5700000000000000',
      quoteVolume: 7525106.643176,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-29T00:00:00.000Z'
      },
      {
      open: '9.4516000000000000',
      high: '9.5386000000000000',
      low: '9.4003000000000000',
      close: '9.4521000000000000',
      volume: '719748.6600000000000000',
      quoteVolume: 6803212.351281,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-30T00:00:00.000Z'
      },
      {
      open: '9.4513000000000000',
      high: '9.5546000000000000',
      low: '9.2811000000000000',
      close: '9.4807000000000000',
      volume: '707061.5900000000000000',
      quoteVolume: 6671089.908958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-31T00:00:00.000Z'
      },
      {
      open: '9.4837000000000000',
      high: '9.6600000000000000',
      low: '9.4303000000000000',
      close: '9.6192000000000000',
      volume: '791111.4800000000000000',
      quoteVolume: 7528202.69593,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-01T00:00:00.000Z'
      },
      {
      open: '9.6106000000000000',
      high: '9.7192000000000000',
      low: '9.5467000000000000',
      close: '9.6421000000000000',
      volume: '850927.5000000000000000',
      quoteVolume: 8194490.466364,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-02T00:00:00.000Z'
      },
      {
      open: '9.6607000000000000',
      high: '9.7440000000000000',
      low: '9.4888000000000000',
      close: '9.5889000000000000',
      volume: '812589.6100000000000000',
      quoteVolume: 7781309.311534,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-03T00:00:00.000Z'
      },
      {
      open: '9.5880000000000000',
      high: '9.8974000000000000',
      low: '9.5000000000000000',
      close: '9.8190000000000000',
      volume: '934579.3000000000000000',
      quoteVolume: 9056755.741708,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-04T00:00:00.000Z'
      },
      {
      open: '9.8084000000000000',
      high: '9.8294000000000000',
      low: '9.6603000000000000',
      close: '9.7333000000000000',
      volume: '836650.9100000000000000',
      quoteVolume: 8131267.116155,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-05T00:00:00.000Z'
      },
      {
      open: '9.7509000000000000',
      high: '9.8891000000000000',
      low: '9.6703000000000000',
      close: '9.8810000000000000',
      volume: '887223.8200000000000000',
      quoteVolume: 8677224.600936,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-06T00:00:00.000Z'
      },
      {
      open: '9.8718000000000000',
      high: '9.9776000000000000',
      low: '9.8100000000000000',
      close: '9.8970000000000000',
      volume: '865071.5600000000000000',
      quoteVolume: 8556329.625219,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-07T00:00:00.000Z'
      },
      {
      open: '9.8970000000000000',
      high: '9.9268000000000000',
      low: '9.6503000000000000',
      close: '9.6860000000000000',
      volume: '798983.4400000000000000',
      quoteVolume: 7826431.10844,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-08T00:00:00.000Z'
      },
      {
      open: '9.6868000000000000',
      high: '9.7497000000000000',
      low: '9.5169000000000000',
      close: '9.6141000000000000',
      volume: '735975.4500000000000000',
      quoteVolume: 7091790.464076,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-09T00:00:00.000Z'
      },
      {
      open: '9.6132000000000000',
      high: '9.6896000000000000',
      low: '9.5672000000000000',
      close: '9.5898000000000000',
      volume: '782308.0400000000000000',
      quoteVolume: 7533998.386684,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-10T00:00:00.000Z'
      },
      {
      open: '9.5898000000000000',
      high: '9.6210000000000000',
      low: '9.3500000000000000',
      close: '9.5611000000000000',
      volume: '755404.2100000000000000',
      quoteVolume: 7180788.710182,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-11T00:00:00.000Z'
      },
      {
      open: '9.5586000000000000',
      high: '9.5891000000000000',
      low: '9.3310000000000000',
      close: '9.4862000000000000',
      volume: '723363.6300000000000000',
      quoteVolume: 6843645.615956,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-12T00:00:00.000Z'
      },
      {
      open: '9.4862000000000000',
      high: '9.5891000000000000',
      low: '9.3602000000000000',
      close: '9.3936000000000000',
      volume: '658735.4600000000000000',
      quoteVolume: 6227393.415766,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-13T00:00:00.000Z'
      },
      {
      open: '9.3870000000000000',
      high: '9.4453000000000000',
      low: '8.3359000000000000',
      close: '8.6811000000000000',
      volume: '672611.0600000000000000',
      quoteVolume: 5964449.985309,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-14T00:00:00.000Z'
      },
      {
      open: '8.6896000000000000',
      high: '8.7342000000000000',
      low: '7.8000000000000000',
      close: '8.3793000000000000',
      volume: '822045.1000000000000000',
      quoteVolume: 6802948.925313,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-15T00:00:00.000Z'
      },
      {
      open: '8.3793000000000000',
      high: '8.4049000000000000',
      low: '7.8000000000000000',
      close: '8.0100000000000000',
      volume: '901842.2500000000000000',
      quoteVolume: 7292373.45122,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-16T00:00:00.000Z'
      },
      {
      open: '8.0097000000000000',
      high: '8.0192000000000000',
      low: '7.6846000000000000',
      close: '7.8494000000000000',
      volume: '752867.4700000000000000',
      quoteVolume: 5865731.485472,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-17T00:00:00.000Z'
      },
      {
      open: '7.8493000000000000',
      high: '8.1660000000000000',
      low: '7.8301000000000000',
      close: '8.0832000000000000',
      volume: '604839.7500000000000000',
      quoteVolume: 4838054.114957,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-18T00:00:00.000Z'
      },
      {
      open: '8.0833000000000000',
      high: '8.0918000000000000',
      low: '6.6453000000000000',
      close: '6.6844000000000000',
      volume: '1052222.0800000000000000',
      quoteVolume: 7512579.853245,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-19T00:00:00.000Z'
      },
      {
      open: '6.7040000000000000',
      high: '6.9050000000000000',
      low: '5.6120000000000000',
      close: '5.9200000000000000',
      volume: '1624846.6400000000000000',
      quoteVolume: 9912905.068409,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-20T00:00:00.000Z'
      },
      {
      open: '5.9419000000000000',
      high: '6.3230000000000000',
      low: '5.6502000000000000',
      close: '6.2894000000000000',
      volume: '842731.4100000000000000',
      quoteVolume: 5084237.945527,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-21T00:00:00.000Z'
      },
      {
      open: '6.2896000000000000',
      high: '6.3727000000000000',
      low: '5.7172000000000000',
      close: '5.7700000000000000',
      volume: '630372.0700000000000000',
      quoteVolume: 3840547.082434,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-22T00:00:00.000Z'
      },
      {
      open: '5.7755000000000000',
      high: '5.8396000000000000',
      low: '5.4500000000000000',
      close: '5.6611000000000000',
      volume: '598556.9000000000000000',
      quoteVolume: 3357369.283447,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-23T00:00:00.000Z'
      },
      {
      open: '5.6525000000000000',
      high: '5.8055000000000000',
      low: '4.7632000000000000',
      close: '5.1930000000000000',
      volume: '917700.2100000000000000',
      quoteVolume: 4844925.914768,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-24T00:00:00.000Z'
      },
      {
      open: '5.1990000000000000',
      high: '5.3800000000000000',
      low: '4.5320000000000000',
      close: '5.2873000000000000',
      volume: '1003892.6700000000000000',
      quoteVolume: 4998073.759825,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-25T00:00:00.000Z'
      },
      {
      open: '5.2800000000000000',
      high: '5.4000000000000000',
      low: '4.7568000000000000',
      close: '5.0631000000000000',
      volume: '852519.3700000000000000',
      quoteVolume: 4333954.593448,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-26T00:00:00.000Z'
      },
      {
      open: '5.0630000000000000',
      high: '5.0630000000000000',
      low: '4.7045000000000000',
      close: '4.9840000000000000',
      volume: '701759.1100000000000000',
      quoteVolume: 3434036.230959,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-27T00:00:00.000Z'
      },
      {
      open: '4.9840000000000000',
      high: '5.4603000000000000',
      low: '4.9840000000000000',
      close: '5.3499000000000000',
      volume: '1144816.8000000000000000',
      quoteVolume: 6061490.260849,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-28T00:00:00.000Z'
      },
      {
      open: '5.3498000000000000',
      high: '5.6344000000000000',
      low: '5.1243000000000000',
      close: '5.3805000000000000',
      volume: '1018322.9900000000000000',
      quoteVolume: 5461031.121114,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-29T00:00:00.000Z'
      },
      {
      open: '5.3902000000000000',
      high: '5.4480000000000000',
      low: '4.9534000000000000',
      close: '5.1150000000000000',
      volume: '709284.0200000000000000',
      quoteVolume: 3662427.823626,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-30T00:00:00.000Z'
      },
      {
      open: '5.1122000000000000',
      high: '5.4006000000000000',
      low: '5.0072000000000000',
      close: '5.2899000000000000',
      volume: '925388.4200000000000000',
      quoteVolume: 4874052.81019,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-01T00:00:00.000Z'
      },
      {
      open: '5.2787000000000000',
      high: '5.4357000000000000',
      low: '5.2050000000000000',
      close: '5.2888000000000000',
      volume: '853331.6100000000000000',
      quoteVolume: 4534872.243938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-02T00:00:00.000Z'
      },
      {
      open: '5.2831000000000000',
      high: '5.2956000000000000',
      low: '5.0100000000000000',
      close: '5.1266000000000000',
      volume: '673983.3800000000000000',
      quoteVolume: 3451158.980001,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-03T00:00:00.000Z'
      },
      {
      open: '5.1294000000000000',
      high: '6.3649000000000000',
      low: '5.0856000000000000',
      close: '5.9020000000000000',
      volume: '2238490.1600000000000000',
      quoteVolume: 12882498.1221741,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-04T00:00:00.000Z'
      },
      {
      open: '5.9020000000000000',
      high: '6.6200000000000000',
      low: '5.7225000000000000',
      close: '6.0727000000000000',
      volume: '4208247.7800000000000000',
      quoteVolume: 25915024.891642,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-05T00:00:00.000Z'
      },
      {
      open: '6.0689000000000000',
      high: '6.1090000000000000',
      low: '4.8500000000000000',
      close: '4.9023000000000000',
      volume: '3033034.7700000000000000',
      quoteVolume: 16605405.771891,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-06T00:00:00.000Z'
      },
      {
      open: '4.8977000000000000',
      high: '4.9997000000000000',
      low: '4.1200000000000000',
      close: '4.5160000000000000',
      volume: '2250208.5300000000000000',
      quoteVolume: 10282558.541603,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-07T00:00:00.000Z'
      },
      {
      open: '4.5118000000000000',
      high: '4.8009000000000000',
      low: '4.3001000000000000',
      close: '4.6361000000000000',
      volume: '1260790.7000000000000000',
      quoteVolume: 5751093.241387,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-08T00:00:00.000Z'
      },
      {
      open: '4.6280000000000000',
      high: '5.0003000000000000',
      low: '4.5167000000000000',
      close: '4.7693000000000000',
      volume: '1505537.6400000000000000',
      quoteVolume: 7177505.612086,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-09T00:00:00.000Z'
      },
      {
      open: '4.7693000000000000',
      high: '4.9398000000000000',
      low: '4.4698000000000000',
      close: '4.6276000000000000',
      volume: '928495.8500000000000000',
      quoteVolume: 4361795.739574,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-10T00:00:00.000Z'
      },
      {
      open: '4.6297000000000000',
      high: '4.9295000000000000',
      low: '4.5000000000000000',
      close: '4.8973000000000000',
      volume: '1232098.4300000000000000',
      quoteVolume: 5766708.758249,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-11T00:00:00.000Z'
      },
      {
      open: '4.8936000000000000',
      high: '5.1500000000000000',
      low: '4.7390000000000000',
      close: '4.9815000000000000',
      volume: '1196552.2900000000000000',
      quoteVolume: 5925304.975092,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-12T00:00:00.000Z'
      },
      {
      open: '4.9820000000000000',
      high: '4.9933000000000000',
      low: '4.5563000000000000',
      close: '4.6384000000000000',
      volume: '1003196.1800000000000000',
      quoteVolume: 4804678.850471,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-13T00:00:00.000Z'
      },
      {
      open: '4.6399000000000000',
      high: '4.7500000000000000',
      low: '4.4300000000000000',
      close: '4.5211000000000000',
      volume: '946936.1900000000000000',
      quoteVolume: 4370044.405834,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-14T00:00:00.000Z'
      },
      {
      open: '4.5148000000000000',
      high: '4.5990000000000000',
      low: '4.4436000000000000',
      close: '4.5531000000000000',
      volume: '969833.4400000000000000',
      quoteVolume: 4387901.297965,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-15T00:00:00.000Z'
      },
      {
      open: '4.5479000000000000',
      high: '4.7064000000000000',
      low: '4.5089000000000000',
      close: '4.6160000000000000',
      volume: '1069563.7700000000000000',
      quoteVolume: 4963613.541897,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-16T00:00:00.000Z'
      },
      {
      open: '4.6193000000000000',
      high: '5.1271000000000000',
      low: '4.6010000000000000',
      close: '5.0650000000000000',
      volume: '1507154.3700000000000000',
      quoteVolume: 7344673.590916,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-17T00:00:00.000Z'
      },
      {
      open: '5.0650000000000000',
      high: '5.5362000000000000',
      low: '4.9000000000000000',
      close: '5.5308000000000000',
      volume: '1906857.2400000000000000',
      quoteVolume: 9864772.820849,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-18T00:00:00.000Z'
      },
      {
      open: '5.5349000000000000',
      high: '5.8331000000000000',
      low: '5.2847000000000000',
      close: '5.3633000000000000',
      volume: '1805892.7600000000000000',
      quoteVolume: 9964231.850427,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-19T00:00:00.000Z'
      },
      {
      open: '5.3627000000000000',
      high: '5.7900000000000000',
      low: '5.2369000000000000',
      close: '5.6766000000000000',
      volume: '2160302.6100000000000000',
      quoteVolume: 12060645.667534,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-20T00:00:00.000Z'
      },
      {
      open: '5.6761000000000000',
      high: '5.7897000000000000',
      low: '5.3166000000000000',
      close: '5.4766000000000000',
      volume: '1592262.7400000000000000',
      quoteVolume: 8888728.773336,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-21T00:00:00.000Z'
      },
      {
      open: '5.4766000000000000',
      high: '5.8802000000000000',
      low: '5.3950000000000000',
      close: '5.8398000000000000',
      volume: '1775214.5900000000000000',
      quoteVolume: 10028050.954402,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-22T00:00:00.000Z'
      },
      {
      open: '5.8399000000000000',
      high: '6.1318000000000000',
      low: '5.7589000000000000',
      close: '5.9590000000000000',
      volume: '1878151.1300000000000000',
      quoteVolume: 11203811.278916,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-23T00:00:00.000Z'
      },
      {
      open: '5.9588000000000000',
      high: '6.4735000000000000',
      low: '5.9300000000000000',
      close: '6.0979000000000000',
      volume: '2058385.4600000000000000',
      quoteVolume: 12800612.446236,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-24T00:00:00.000Z'
      },
      {
      open: '6.0975000000000000',
      high: '6.0994000000000000',
      low: '5.3127000000000000',
      close: '5.4500000000000000',
      volume: '1443517.2200000000000000',
      quoteVolume: 7946748.599596,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-25T00:00:00.000Z'
      },
      {
      open: '5.4432000000000000',
      high: '5.6840000000000000',
      low: '5.3700000000000000',
      close: '5.6029000000000000',
      volume: '1351072.4800000000000000',
      quoteVolume: 7510239.890038,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-26T00:00:00.000Z'
      },
      {
      open: '5.6062000000000000',
      high: '5.6559000000000000',
      low: '5.1000000000000000',
      close: '5.1791000000000000',
      volume: '1148939.3700000000000000',
      quoteVolume: 6200387.822491,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-27T00:00:00.000Z'
      },
      {
      open: '5.1818000000000000',
      high: '5.8995000000000000',
      low: '5.1003000000000000',
      close: '5.8811000000000000',
      volume: '1505031.2400000000000000',
      quoteVolume: 8318661.357344,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-28T00:00:00.000Z'
      },
      {
      open: '5.8803000000000000',
      high: '6.0000000000000000',
      low: '5.6100000000000000',
      close: '5.6600000000000000',
      volume: '1232874.4500000000000000',
      quoteVolume: 7206376.833483,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-29T00:00:00.000Z'
      },
      {
      open: '5.6700000000000000',
      high: '5.9186000000000000',
      low: '5.5580000000000000',
      close: '5.8939000000000000',
      volume: '1394241.0900000000000000',
      quoteVolume: 8065674.631955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-30T00:00:00.000Z'
      },
      {
      open: '5.9000000000000000',
      high: '6.2433000000000000',
      low: '5.8304000000000000',
      close: '6.1111000000000000',
      volume: '2388899.2300000000000000',
      quoteVolume: 14438039.454585,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-31T00:00:00.000Z'
      },
      {
      open: '6.1139000000000000',
      high: '6.1228000000000000',
      low: '5.7735000000000000',
      close: '5.9910000000000000',
      volume: '1412914.1800000000000000',
      quoteVolume: 8379611.862422,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-01T00:00:00.000Z'
      },
      {
      open: '5.9910000000000000',
      high: '6.0939000000000000',
      low: '5.8371000000000000',
      close: '6.0510000000000000',
      volume: '2130127.0000000000000000',
      quoteVolume: 12667352.240985,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-02T00:00:00.000Z'
      },
      {
      open: '6.0445000000000000',
      high: '6.0620000000000000',
      low: '5.7739000000000000',
      close: '5.7979000000000000',
      volume: '1524726.3700000000000000',
      quoteVolume: 8984033.715786,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-03T00:00:00.000Z'
      },
      {
      open: '5.7947000000000000',
      high: '5.9900000000000000',
      low: '5.7402000000000000',
      close: '5.9692000000000000',
      volume: '1657441.7400000000000000',
      quoteVolume: 9772290.645118,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-04T00:00:00.000Z'
      },
      {
      open: '5.9688000000000000',
      high: '6.1043000000000000',
      low: '5.9188000000000000',
      close: '5.9625000000000000',
      volume: '1636284.9900000000000000',
      quoteVolume: 9826475.877965,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-05T00:00:00.000Z'
      },
      {
      open: '5.9626000000000000',
      high: '6.3888000000000000',
      low: '5.9250000000000000',
      close: '6.2688000000000000',
      volume: '2511301.2700000000000000',
      quoteVolume: 15355552.564777,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-06T00:00:00.000Z'
      },
      {
      open: '6.2681000000000000',
      high: '6.3094000000000000',
      low: '6.0061000000000000',
      close: '6.2009000000000000',
      volume: '1683858.6800000000000000',
      quoteVolume: 10428760.417152,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-07T00:00:00.000Z'
      },
      {
      open: '6.1979000000000000',
      high: '6.7984000000000000',
      low: '6.1381000000000000',
      close: '6.5128000000000000',
      volume: '3946905.6100000000000000',
      quoteVolume: 25730037.887084,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-08T00:00:00.000Z'
      },
      {
      open: '6.5197000000000000',
      high: '6.8540000000000000',
      low: '6.3386000000000000',
      close: '6.5511000000000000',
      volume: '4167653.6900000000000000',
      quoteVolume: 27575766.440919,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-09T00:00:00.000Z'
      },
      {
      open: '6.5511000000000000',
      high: '6.5764000000000000',
      low: '5.6500000000000000',
      close: '5.8449000000000000',
      volume: '3348790.0300000000000000',
      quoteVolume: 20367712.107099,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-10T00:00:00.000Z'
      },
      {
      open: '5.8440000000000000',
      high: '6.1198000000000000',
      low: '5.6853000000000000',
      close: '5.9595000000000000',
      volume: '2559930.8100000000000000',
      quoteVolume: 15157433.652937,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-11T00:00:00.000Z'
      },
      {
      open: '5.9552000000000000',
      high: '5.9657000000000000',
      low: '5.8000000000000000',
      close: '5.8594000000000000',
      volume: '1743319.3100000000000000',
      quoteVolume: 10235285.058373,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-12T00:00:00.000Z'
      },
      {
      open: '5.8629000000000000',
      high: '5.8884000000000000',
      low: '5.3733000000000000',
      close: '5.4688000000000000',
      volume: '1420692.0500000000000000',
      quoteVolume: 8108312.422364,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-13T00:00:00.000Z'
      },
      {
      open: '5.4688000000000000',
      high: '6.0620000000000000',
      low: '5.4586000000000000',
      close: '6.0583000000000000',
      volume: '2330172.4800000000000000',
      quoteVolume: 13521000.249423,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-14T00:00:00.000Z'
      },
      {
      open: '6.0565000000000000',
      high: '6.0649000000000000',
      low: '5.6601000000000000',
      close: '5.8111000000000000',
      volume: '1366715.2500000000000000',
      quoteVolume: 8031293.154063,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-15T00:00:00.000Z'
      },
      {
      open: '5.8007000000000000',
      high: '6.1817000000000000',
      low: '5.7741000000000000',
      close: '6.0911000000000000',
      volume: '2164639.9000000000000000',
      quoteVolume: 12960434.729842,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '6.0941000000000000',
      high: '6.3300000000000000',
      low: '5.9604000000000000',
      close: '6.3266000000000000',
      volume: '2312287.7400000000000000',
      quoteVolume: 14193314.652338,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '6.3152000000000000',
      high: '6.5879000000000000',
      low: '6.2132000000000000',
      close: '6.4867000000000000',
      volume: '2495447.2200000000000000',
      quoteVolume: 15985247.059954,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '6.4887000000000000',
      high: '6.6400000000000000',
      low: '6.2435000000000000',
      close: '6.5199000000000000',
      volume: '2269437.9800000000000000',
      quoteVolume: 14687340.306653,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '6.5221000000000000',
      high: '6.6295000000000000',
      low: '6.2401000000000000',
      close: '6.4008000000000000',
      volume: '1778376.9300000000000000',
      quoteVolume: 11481466.262429,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '6.4069000000000000',
      high: '6.5150000000000000',
      low: '6.2788000000000000',
      close: '6.4724000000000000',
      volume: '1851048.4900000000000000',
      quoteVolume: 11908754.858074,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '6.4681000000000000',
      high: '6.5088000000000000',
      low: '6.2500000000000000',
      close: '6.4736000000000000',
      volume: '1854629.7500000000000000',
      quoteVolume: 11938849.639233,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '6.4780000000000000',
      high: '6.5300000000000000',
      low: '6.3499000000000000',
      close: '6.4969000000000000',
      volume: '1928704.7200000000000000',
      quoteVolume: 12474449.059306,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '6.4963000000000000',
      high: '6.4963000000000000',
      low: '6.3915000000000000',
      close: '6.4588000000000000',
      volume: '1514319.9100000000000000',
      quoteVolume: 9750589.965848,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '6.4555000000000000',
      high: '6.7502000000000000',
      low: '6.4408000000000000',
      close: '6.6899000000000000',
      volume: '2583153.3800000000000000',
      quoteVolume: 17021668.779197,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '6.6890000000000000',
      high: '6.9786000000000000',
      low: '6.6196000000000000',
      close: '6.9463000000000000',
      volume: '2734629.1100000000000000',
      quoteVolume: 18598393.817596,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '6.9453000000000000',
      high: '7.2157000000000000',
      low: '6.7919000000000000',
      close: '7.0396000000000000',
      volume: '2776887.6800000000000000',
      quoteVolume: 19399583.767419,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '7.0399000000000000',
      high: '7.1398000000000000',
      low: '5.9400000000000000',
      close: '6.1648000000000000',
      volume: '4950985.6900000000000000',
      quoteVolume: 32288204.501957,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '6.1642000000000000',
      high: '6.2599000000000000',
      low: '5.9680000000000000',
      close: '6.1661000000000000',
      volume: '2967157.3600000000000000',
      quoteVolume: 18169111.04114,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '6.1335000000000000',
      high: '6.1999000000000000',
      low: '6.0010000000000000',
      close: '6.1388000000000000',
      volume: '2238811.5900000000000000',
      quoteVolume: 13687184.380913,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '6.1384000000000000',
      high: '6.2663000000000000',
      low: '5.9468000000000000',
      close: '6.2200000000000000',
      volume: '2264988.8600000000000000',
      quoteVolume: 13878555.849304,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '6.2250000000000000',
      high: '6.6500000000000000',
      low: '6.1172000000000000',
      close: '6.5229000000000000',
      volume: '2942865.6800000000000000',
      quoteVolume: 19039053.024294,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '6.5185000000000000',
      high: '6.9000000000000000',
      low: '6.4823000000000000',
      close: '6.8655000000000000',
      volume: '3251363.1600000000000000',
      quoteVolume: 21783128.783937,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '6.8688000000000000',
      high: '6.9000000000000000',
      low: '6.6148000000000000',
      close: '6.7887000000000000',
      volume: '3095048.1600000000000000',
      quoteVolume: 21095885.790271,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '6.7888000000000000',
      high: '7.1850000000000000',
      low: '6.6800000000000000',
      close: '7.1069000000000000',
      volume: '3306867.3100000000000000',
      quoteVolume: 23064420.680175,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '7.1028000000000000',
      high: '7.9715000000000000',
      low: '6.9501000000000000',
      close: '7.8665000000000000',
      volume: '4154631.4300000000000000',
      quoteVolume: 30555459.709009,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '7.8569000000000000',
      high: '8.4800000000000000',
      low: '7.3200000000000000',
      close: '8.1303000000000000',
      volume: '5817391.7600000000000000',
      quoteVolume: 46487023.094392,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '8.1347000000000000',
      high: '8.4666000000000000',
      low: '7.6733000000000000',
      close: '7.8100000000000000',
      volume: '4354792.8000000000000000',
      quoteVolume: 35149002.628069,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '7.8070000000000000',
      high: '8.8387000000000000',
      low: '7.6100000000000000',
      close: '8.5972000000000000',
      volume: '4960284.8400000000000000',
      quoteVolume: 40860967.438254,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '8.5972000000000000',
      high: '8.9210000000000000',
      low: '8.4052000000000000',
      close: '8.9051000000000000',
      volume: '5440051.1100000000000000',
      quoteVolume: 47410868.663698,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '8.9001000000000000',
      high: '9.2999000000000000',
      low: '8.8264000000000000',
      close: '9.1697000000000000',
      volume: '5750442.0600000000000000',
      quoteVolume: 52514889.989487,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '9.1695000000000000',
      high: '9.7780000000000000',
      low: '9.0100000000000000',
      close: '9.5288000000000000',
      volume: '6041136.0700000000000000',
      quoteVolume: 57201203.615301,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '9.5250000000000000',
      high: '9.9000000000000000',
      low: '8.9210000000000000',
      close: '9.2765000000000000',
      volume: '4945231.4600000000000000',
      quoteVolume: 46161674.265266,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '9.2763000000000000',
      high: '9.2763000000000000',
      low: '8.7100000000000000',
      close: '8.9103000000000000',
      volume: '4005598.1000000000000000',
      quoteVolume: 36165479.576568,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '8.9165000000000000',
      high: '9.0500000000000000',
      low: '8.4051000000000000',
      close: '8.7655000000000000',
      volume: '2803390.0100000000000000',
      quoteVolume: 24547147.485664,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '8.7636000000000000',
      high: '9.4997000000000000',
      low: '8.7293000000000000',
      close: '9.2911000000000000',
      volume: '3326003.0400000000000000',
      quoteVolume: 30588883.272504,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '9.2854000000000000',
      high: '9.3399000000000000',
      low: '9.0480000000000000',
      close: '9.1087000000000000',
      volume: '2499086.4000000000000000',
      quoteVolume: 22869550.901954,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '9.0972000000000000',
      high: '9.2200000000000000',
      low: '8.8903000000000000',
      close: '9.2200000000000000',
      volume: '2460188.8100000000000000',
      quoteVolume: 22259140.520036,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '9.2167000000000000',
      high: '9.6999000000000000',
      low: '9.1777000000000000',
      close: '9.6127000000000000',
      volume: '2809984.6500000000000000',
      quoteVolume: 26459587.703255,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '9.6127000000000000',
      high: '11.1307000000000000',
      low: '9.4810000000000000',
      close: '10.4728000000000000',
      volume: '4894965.3800000000000000',
      quoteVolume: 49969717.014679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '10.4687000000000000',
      high: '11.2951000000000000',
      low: '10.3928000000000000',
      close: '10.9211000000000000',
      volume: '4346711.0700000000000000',
      quoteVolume: 47273284.241928,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '10.9199000000000000',
      high: '10.9360000000000000',
      low: '10.1300000000000000',
      close: '10.4661000000000000',
      volume: '3077022.9900000000000000',
      quoteVolume: 31977282.892985,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '10.4695000000000000',
      high: '10.9611000000000000',
      low: '10.3799000000000000',
      close: '10.7576000000000000',
      volume: '3038507.7600000000000000',
      quoteVolume: 32510212.911946,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '10.7572000000000000',
      high: '10.8500000000000000',
      low: '10.4000000000000000',
      close: '10.7265000000000000',
      volume: '2877713.1500000000000000',
      quoteVolume: 30567202.596206,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '10.7267000000000000',
      high: '11.0900000000000000',
      low: '9.3901000000000000',
      close: '9.9476000000000000',
      volume: '4800152.2500000000000000',
      quoteVolume: 49351275.843466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '9.9442000000000000',
      high: '10.2361000000000000',
      low: '9.2685000000000000',
      close: '9.6967000000000000',
      volume: '5348579.5700000000000000',
      quoteVolume: 52442588.585831,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '9.6967000000000000',
      high: '9.7557000000000000',
      low: '9.3551000000000000',
      close: '9.4168000000000000',
      volume: '3118340.9500000000000000',
      quoteVolume: 29619413.056984,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '9.4238000000000000',
      high: '9.9998000000000000',
      low: '9.3500000000000000',
      close: '9.8467000000000000',
      volume: '3524897.3500000000000000',
      quoteVolume: 34336397.563894,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '9.8502000000000000',
      high: '10.4914000000000000',
      low: '9.7899000000000000',
      close: '10.2779000000000000',
      volume: '3693794.5400000000000000',
      quoteVolume: 37396909.411865,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '10.2777000000000000',
      high: '11.5510000000000000',
      low: '10.2067000000000000',
      close: '11.3111000000000000',
      volume: '4025695.9700000000000000',
      quoteVolume: 44152799.747361,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '11.3353000000000000',
      high: '11.8000000000000000',
      low: '10.8000000000000000',
      close: '11.7566000000000000',
      volume: '3893847.1600000000000000',
      quoteVolume: 43818057.90284,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '11.7564000000000000',
      high: '11.8701000000000000',
      low: '11.1902000000000000',
      close: '11.4511000000000000',
      volume: '2853200.1500000000000000',
      quoteVolume: 32727760.247279,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '11.4511000000000000',
      high: '11.5080000000000000',
      low: '10.8500000000000000',
      close: '11.4006000000000000',
      volume: '2739049.0800000000000000',
      quoteVolume: 30560700.450351,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '11.4050000000000000',
      high: '13.7500000000000000',
      low: '11.3950000000000000',
      close: '13.6229000000000000',
      volume: '8340655.3200000000000000',
      quoteVolume: 107482964.630679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '13.6230000000000000',
      high: '14.6630000000000000',
      low: '12.9000000000000000',
      close: '14.5294000000000000',
      volume: '6158611.9700000000000000',
      quoteVolume: 84008255.221381,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '14.5290000000000000',
      high: '15.5600000000000000',
      low: '14.3270000000000000',
      close: '15.1000000000000000',
      volume: '6757497.3300000000000000',
      quoteVolume: 101414485.34027,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '15.0999000000000000',
      high: '15.2194000000000000',
      low: '13.5578000000000000',
      close: '14.2931000000000000',
      volume: '6343194.0300000000000000',
      quoteVolume: 90848655.629742,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '14.2848000000000000',
      high: '14.9200000000000000',
      low: '13.9609000000000000',
      close: '14.4651000000000000',
      volume: '3996324.5500000000000000',
      quoteVolume: 58062140.975948,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '14.4651000000000000',
      high: '14.5075000000000000',
      low: '13.8600000000000000',
      close: '14.3711000000000000',
      volume: '3531708.7000000000000000',
      quoteVolume: 50093088.214938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '14.3741000000000000',
      high: '14.9000000000000000',
      low: '14.0110000000000000',
      close: '14.4143000000000000',
      volume: '3757653.7700000000000000',
      quoteVolume: 54303593.05517,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '14.4133000000000000',
      high: '15.4288000000000000',
      low: '14.1809000000000000',
      close: '15.3333000000000000',
      volume: '4049068.3900000000000000',
      quoteVolume: 60747159.402645,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '15.3459000000000000',
      high: '15.3800000000000000',
      low: '14.9333000000000000',
      close: '15.0244000000000000',
      volume: '3282883.1800000000000000',
      quoteVolume: 49667294.365356,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '15.0311000000000000',
      high: '15.0970000000000000',
      low: '14.5630000000000000',
      close: '14.8901000000000000',
      volume: '3079956.9300000000000000',
      quoteVolume: 45702098.682484,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '14.8993000000000000',
      high: '15.1990000000000000',
      low: '14.8400000000000000',
      close: '14.9432000000000000',
      volume: '2986987.6900000000000000',
      quoteVolume: 44970630.556925,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '14.9429000000000000',
      high: '16.0000000000000000',
      low: '14.8800000000000000',
      close: '15.9589000000000000',
      volume: '3293876.1300000000000000',
      quoteVolume: 51027787.313053,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '15.9589000000000000',
      high: '15.9589000000000000',
      low: '15.4400000000000000',
      close: '15.5693000000000000',
      volume: '3106901.4800000000000000',
      quoteVolume: 48737968.350018,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '15.5836000000000000',
      high: '15.7888000000000000',
      low: '15.2040000000000000',
      close: '15.5388000000000000',
      volume: '2472584.8900000000000000',
      quoteVolume: 38403832.139068,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '15.5361000000000000',
      high: '15.8000000000000000',
      low: '15.0000000000000000',
      close: '15.4953000000000000',
      volume: '4271050.4000000000000000',
      quoteVolume: 65955552.676115,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '15.5098000000000000',
      high: '15.5098000000000000',
      low: '15.0220000000000000',
      close: '15.1288000000000000',
      volume: '2369648.3800000000000000',
      quoteVolume: 35958975.368582,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '15.1288000000000000',
      high: '15.2987000000000000',
      low: '13.8607000000000000',
      close: '14.3511000000000000',
      volume: '3015066.5300000000000000',
      quoteVolume: 44410580.384387,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '14.3567000000000000',
      high: '15.2260000000000000',
      low: '14.2310000000000000',
      close: '15.1502000000000000',
      volume: '2695850.8700000000000000',
      quoteVolume: 40069206.590268,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '15.1524000000000000',
      high: '15.4400000000000000',
      low: '14.9469000000000000',
      close: '15.1397000000000000',
      volume: '2632489.7000000000000000',
      quoteVolume: 39846679.02577,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '15.1397000000000000',
      high: '17.5000000000000000',
      low: '14.6600000000000000',
      close: '17.1096000000000000',
      volume: '5285367.1000000000000000',
      quoteVolume: 86478446.288151,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '17.1112000000000000',
      high: '17.3380000000000000',
      low: '16.1000000000000000',
      close: '16.7631000000000000',
      volume: '2775613.8600000000000000',
      quoteVolume: 46472992.15214,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '16.7761000000000000',
      high: '16.8000000000000000',
      low: '15.5301000000000000',
      close: '16.1998000000000000',
      volume: '2725145.6800000000000000',
      quoteVolume: 44086712.960827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '16.1928000000000000',
      high: '16.8297000000000000',
      low: '15.8697000000000000',
      close: '16.6000000000000000',
      volume: '3240914.2500000000000000',
      quoteVolume: 53588345.695308,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '16.6000000000000000',
      high: '16.7188000000000000',
      low: '16.4010000000000000',
      close: '16.6665000000000000',
      volume: '2988300.9700000000000000',
      quoteVolume: 49575583.531128,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '16.6538000000000000',
      high: '16.8067000000000000',
      low: '16.3770000000000000',
      close: '16.4753000000000000',
      volume: '2737173.2000000000000000',
      quoteVolume: 45408014.592112,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '16.4949000000000000',
      high: '16.9850000000000000',
      low: '15.9612000000000000',
      close: '16.9666000000000000',
      volume: '2790050.9300000000000000',
      quoteVolume: 46116769.387966,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '16.9666000000000000',
      high: '17.7386000000000000',
      low: '16.7314000000000000',
      close: '17.5376000000000000',
      volume: '3182964.8500000000000000',
      quoteVolume: 55170337.210131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '17.5201000000000000',
      high: '18.2576000000000000',
      low: '17.3319000000000000',
      close: '17.8888000000000000',
      volume: '2966204.7100000000000000',
      quoteVolume: 52669428.49396,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '17.8888000000000000',
      high: '19.8800000000000000',
      low: '17.5303000000000000',
      close: '19.7155000000000000',
      volume: '3356886.8900000000000000',
      quoteVolume: 62702520.871521,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '19.7155000000000000',
      high: '20.0000000000000000',
      low: '18.0000000000000000',
      close: '18.5489000000000000',
      volume: '3532965.9700000000000000',
      quoteVolume: 67883042.73247,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '18.5388000000000000',
      high: '19.4100000000000000',
      low: '18.1600000000000000',
      close: '18.9920000000000000',
      volume: '2939082.4200000000000000',
      quoteVolume: 55680460.143077,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '18.9710000000000000',
      high: '19.4593000000000000',
      low: '18.8445000000000000',
      close: '19.3657000000000000',
      volume: '2703355.9100000000000000',
      quoteVolume: 51825140.403764,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '19.3658000000000000',
      high: '19.3866000000000000',
      low: '18.8200000000000000',
      close: '19.0745000000000000',
      volume: '2519027.6300000000000000',
      quoteVolume: 48185036.221402,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '19.0423000000000000',
      high: '19.2137000000000000',
      low: '18.8000000000000000',
      close: '18.9523000000000000',
      volume: '2329702.3400000000000000',
      quoteVolume: 44296486.486205,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '18.9507000000000000',
      high: '19.0420000000000000',
      low: '17.7881000000000000',
      close: '17.9777000000000000',
      volume: '2142481.9200000000000000',
      quoteVolume: 39222061.198727,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '17.9728000000000000',
      high: '18.5400000000000000',
      low: '17.0100000000000000',
      close: '18.2984000000000000',
      volume: '2776440.4800000000000000',
      quoteVolume: 49902259.792581,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '18.2984000000000000',
      high: '18.6095000000000000',
      low: '17.8927000000000000',
      close: '18.2400000000000000',
      volume: '2553039.1200000000000000',
      quoteVolume: 46664596.972989,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '18.2400000000000000',
      high: '18.2400000000000000',
      low: '16.2501000000000000',
      close: '17.5555000000000000',
      volume: '1815271.0500000000000000',
      quoteVolume: 31411384.587709,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '17.5555000000000000',
      high: '18.5520000000000000',
      low: '16.6120000000000000',
      close: '18.3333000000000000',
      volume: '2325593.3600000000000000',
      quoteVolume: 41384885.795214,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '18.2867000000000000',
      high: '18.6398000000000000',
      low: '17.9143000000000000',
      close: '18.6111000000000000',
      volume: '2205194.6100000000000000',
      quoteVolume: 40484642.365226,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '18.6111000000000000',
      high: '19.9000000000000000',
      low: '18.4259000000000000',
      close: '19.3866000000000000',
      volume: '2438051.2100000000000000',
      quoteVolume: 46779479.130832,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '19.3734000000000000',
      high: '19.7800000000000000',
      low: '18.6601000000000000',
      close: '19.2666000000000000',
      volume: '2218308.0300000000000000',
      quoteVolume: 42952596.269131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '19.2668000000000000',
      high: '19.7482000000000000',
      low: '18.7064000000000000',
      close: '19.6408000000000000',
      volume: '2237334.6000000000000000',
      quoteVolume: 43185852.935218,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '19.6399000000000000',
      high: '19.7908000000000000',
      low: '19.2600000000000000',
      close: '19.4888000000000000',
      volume: '2005582.6000000000000000',
      quoteVolume: 39131247.103883,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '19.4757000000000000',
      high: '21.9980000000000000',
      low: '19.4333000000000000',
      close: '21.8888000000000000',
      volume: '3686092.5700000000000000',
      quoteVolume: 76859035.465239,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '21.8888000000000000',
      high: '24.7459000000000000',
      low: '21.1200000000000000',
      close: '24.3188000000000000',
      volume: '4247944.3800000000000000',
      quoteVolume: 97541476.101794,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '24.3188000000000000',
      high: '25.4973000000000000',
      low: '23.7600000000000000',
      close: '24.8888000000000000',
      volume: '3545157.4900000000000000',
      quoteVolume: 87320188.459109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '24.8693000000000000',
      high: '24.8693000000000000',
      low: '22.4500000000000000',
      close: '23.9999000000000000',
      volume: '3307426.8400000000000000',
      quoteVolume: 77726576.261717,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '23.9999000000000000',
      high: '24.6368000000000000',
      low: '23.0200000000000000',
      close: '23.8000000000000000',
      volume: '3005735.9300000000000000',
      quoteVolume: 72180137.003328,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '23.7812000000000000',
      high: '24.6131000000000000',
      low: '22.4700000000000000',
      close: '22.5460000000000000',
      volume: '2877995.4900000000000000',
      quoteVolume: 67622172.827015,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '22.5207000000000000',
      high: '23.1209000000000000',
      low: '20.8800000000000000',
      close: '22.9703000000000000',
      volume: '2961681.7400000000000000',
      quoteVolume: 65293459.483933,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '22.9877000000000000',
      high: '23.4698000000000000',
      low: '21.5500000000000000',
      close: '22.4863000000000000',
      volume: '3114460.9000000000000000',
      quoteVolume: 70932653.981115,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '22.4869000000000000',
      high: '23.7860000000000000',
      low: '21.6503000000000000',
      close: '23.0555000000000000',
      volume: '3273950.4700000000000000',
      quoteVolume: 75393015.340524,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '23.0555000000000000',
      high: '23.1209000000000000',
      low: '22.3876000000000000',
      close: '22.4324000000000000',
      volume: '2989261.0700000000000000',
      quoteVolume: 67575656.858512,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '22.4326000000000000',
      high: '23.2092000000000000',
      low: '22.4200000000000000',
      close: '23.0226000000000000',
      volume: '3015774.2900000000000000',
      quoteVolume: 69067258.092041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '23.0166000000000000',
      high: '23.0828000000000000',
      low: '21.2797000000000000',
      close: '21.6788000000000000',
      volume: '2388965.0700000000000000',
      quoteVolume: 52801462.600293,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '21.6847000000000000',
      high: '22.3000000000000000',
      low: '21.3292000000000000',
      close: '22.0391000000000000',
      volume: '2197565.5300000000000000',
      quoteVolume: 47987092.830938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '22.0300000000000000',
      high: '22.0990000000000000',
      low: '21.4700000000000000',
      close: '21.8788000000000000',
      volume: '2064791.5400000000000000',
      quoteVolume: 45032827.826184,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '21.8810000000000000',
      high: '24.1270000000000000',
      low: '21.7497000000000000',
      close: '23.6166000000000000',
      volume: '2446015.2000000000000000',
      quoteVolume: 56078160.970131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '23.6168000000000000',
      high: '23.9720000000000000',
      low: '23.0101000000000000',
      close: '23.6999000000000000',
      volume: '2230465.5800000000000000',
      quoteVolume: 52406113.705314,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '23.6999000000000000',
      high: '23.7968000000000000',
      low: '22.3000000000000000',
      close: '22.8888000000000000',
      volume: '1898934.6200000000000000',
      quoteVolume: 43768914.432769,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '22.8887000000000000',
      high: '23.1772000000000000',
      low: '22.6408000000000000',
      close: '22.9777000000000000',
      volume: '1787108.3900000000000000',
      quoteVolume: 40919944.41562,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '22.9777000000000000',
      high: '22.9973000000000000',
      low: '21.4500000000000000',
      close: '22.1895000000000000',
      volume: '1764747.5400000000000000',
      quoteVolume: 39058125.114883,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '22.2018000000000000',
      high: '22.4500000000000000',
      low: '20.2050000000000000',
      close: '20.6602000000000000',
      volume: '1813897.1200000000000000',
      quoteVolume: 39553390.823722,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '20.6602000000000000',
      high: '21.4135000000000000',
      low: '19.5100000000000000',
      close: '20.6673000000000000',
      volume: '2742356.4200000000000000',
      quoteVolume: 56630740.961407,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '20.6671000000000000',
      high: '20.9670000000000000',
      low: '18.5402000000000000',
      close: '18.8388000000000000',
      volume: '2334477.6000000000000000',
      quoteVolume: 46078618.722513,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '18.8377000000000000',
      high: '19.9888000000000000',
      low: '18.3000000000000000',
      close: '19.6166000000000000',
      volume: '2429624.3200000000000000',
      quoteVolume: 46735449.139546,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '19.6294000000000000',
      high: '22.0000000000000000',
      low: '18.8800000000000000',
      close: '20.8059000000000000',
      volume: '3746402.5300000000000000',
      quoteVolume: 76700019.096599,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '20.7871000000000000',
      high: '21.5000000000000000',
      low: '20.1000000000000000',
      close: '20.8934000000000000',
      volume: '3228794.3800000000000000',
      quoteVolume: 67290647.825955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '20.9026000000000000',
      high: '24.0000000000000000',
      low: '20.7349000000000000',
      close: '23.5210000000000000',
      volume: '4566012.5800000000000000',
      quoteVolume: 103765929.624047,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '23.5210000000000000',
      high: '24.5000000000000000',
      low: '22.5748000000000000',
      close: '23.6553000000000000',
      volume: '3821993.1600000000000000',
      quoteVolume: 90350011.235486,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '23.6553000000000000',
      high: '27.6556000000000000',
      low: '23.4600000000000000',
      close: '26.8116000000000000',
      volume: '3433770.3400000000000000',
      quoteVolume: 89988096.508272,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '26.8348000000000000',
      high: '27.7900000000000000',
      low: '25.0000000000000000',
      close: '26.2888000000000000',
      volume: '4200071.2400000000000000',
      quoteVolume: 111245092.085173,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '26.2888000000000000',
      high: '26.6783000000000000',
      low: '23.1424000000000000',
      close: '25.7795000000000000',
      volume: '3429086.5600000000000000',
      quoteVolume: 85241258.720158,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '25.7986000000000000',
      high: '29.0989000000000000',
      low: '25.1124000000000000',
      close: '28.8171000000000000',
      volume: '4319102.3500000000000000',
      quoteVolume: 117061502.700204,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '28.8165000000000000',
      high: '29.8368000000000000',
      low: '27.5430000000000000',
      close: '29.1122000000000000',
      volume: '4437585.5400000000000000',
      quoteVolume: 128407708.555722,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '29.1122000000000000',
      high: '29.3399000000000000',
      low: '27.5306000000000000',
      close: '29.2666000000000000',
      volume: '3980328.4500000000000000',
      quoteVolume: 113614310.06848,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '29.2666000000000000',
      high: '32.8000000000000000',
      low: '28.0500000000000000',
      close: '32.3907000000000000',
      volume: '4704491.9800000000000000',
      quoteVolume: 142363584.104499,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '32.3911000000000000',
      high: '32.4374000000000000',
      low: '30.6100000000000000',
      close: '30.8067000000000000',
      volume: '3807962.5800000000000000',
      quoteVolume: 120261451.277312,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '30.8333000000000000',
      high: '32.2500000000000000',
      low: '29.6699000000000000',
      close: '31.3934000000000000',
      volume: '3793076.8500000000000000',
      quoteVolume: 118471724.038178,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '31.3986000000000000',
      high: '34.7067000000000000',
      low: '31.1200000000000000',
      close: '33.9435000000000000',
      volume: '4534224.5600000000000000',
      quoteVolume: 151952803.938095,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '34.1004000000000000',
      high: '35.4868000000000000',
      low: '33.6231000000000000',
      close: '35.1464000000000000',
      volume: '2287998.0600000000000000',
      quoteVolume: 79609705.50209479,
      btcVolume: 9949.002147255906,
      usdVolume: 79609705.50209479,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '35.1128000000000000',
      high: '35.1128000000000000',
      low: '32.3200000000000000',
      close: '33.0989000000000000',
      volume: '2391390.5700000000000000',
      quoteVolume: 80142171.05356416,
      btcVolume: 9883.743936491968,
      usdVolume: 80142171.05356416,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '33.0220000000000000',
      high: '35.1001000000000000',
      low: '32.5000000000000000',
      close: '33.4619000000000000',
      volume: '2295348.1100000000000000',
      quoteVolume: 77908157.85849185,
      btcVolume: 8929.21104959771,
      usdVolume: 77908157.85849185,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '33.4618000000000000',
      high: '34.5000000000000000',
      low: '32.5000000000000000',
      close: '33.1336000000000000',
      volume: '1895937.3300000000000000',
      quoteVolume: 63128977.16986113,
      btcVolume: 7252.390997849462,
      usdVolume: 63128977.16986113,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '33.0923000000000000',
      high: '33.8790000000000000',
      low: '30.8500000000000000',
      close: '33.6083000000000000',
      volume: '2212524.3300000000000000',
      quoteVolume: 72870434.08302279,
      btcVolume: 8454.326316547385,
      usdVolume: 72870434.08302279,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '33.6085000000000000',
      high: '34.9600000000000000',
      low: '30.2500000000000000',
      close: '31.6330000000000000',
      volume: '2432021.9400000000000000',
      quoteVolume: 80763627.05465643,
      btcVolume: 9414.799798958127,
      usdVolume: 80763627.05465643,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '31.6473000000000000',
      high: '32.9724000000000000',
      low: '30.6100000000000000',
      close: '32.9321000000000000',
      volume: '1923224.7500000000000000',
      quoteVolume: 61104329.90333608,
      btcVolume: 7303.226615523445,
      usdVolume: 61104329.90333608,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '32.9165000000000000',
      high: '34.3530000000000000',
      low: '32.5024000000000000',
      close: '33.3348000000000000',
      volume: '2519040.2400000000000000',
      quoteVolume: 83650473.63800699,
      btcVolume: 9797.183411874852,
      usdVolume: 83650473.63800699,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '33.3521000000000000',
      high: '33.7647000000000000',
      low: '32.6800000000000000',
      close: '33.1527000000000000',
      volume: '3125349.5700000000000000',
      quoteVolume: 103747789.15420249,
      btcVolume: 11980.301630342452,
      usdVolume: 103747789.15420249,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '33.1343000000000000',
      high: '33.1753000000000000',
      low: '30.5100000000000000',
      close: '30.8093000000000000',
      volume: '2793947.5900000000000000',
      quoteVolume: 89255709.65780656,
      btcVolume: 10474.381377841331,
      usdVolume: 89255709.65780656,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '30.8093000000000000',
      high: '31.4673000000000000',
      low: '28.7168000000000000',
      close: '29.2451000000000000',
      volume: '2872894.7300000000000000',
      quoteVolume: 86509446.32841438,
      btcVolume: 10980.6600454644,
      usdVolume: 86509446.32841438,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '29.2451000000000000',
      high: '31.1843000000000000',
      low: '28.9440000000000000',
      close: '30.8755000000000000',
      volume: '3345930.6000000000000000',
      quoteVolume: 100255371.95814995,
      btcVolume: 12913.379731084178,
      usdVolume: 100255371.95814995,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '30.8887000000000000',
      high: '31.9906000000000000',
      low: '29.6300000000000000',
      close: '31.0888000000000000',
      volume: '2987983.0800000000000000',
      quoteVolume: 93095763.1398957,
      btcVolume: 12017.344452736703,
      usdVolume: 93095763.1398957,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '31.0888000000000000',
      high: '31.9911000000000000',
      low: '30.9001000000000000',
      close: '31.3098000000000000',
      volume: '2758670.5000000000000000',
      quoteVolume: 86875753.11986831,
      btcVolume: 10951.248485073238,
      usdVolume: 86875753.11986831,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '31.3478000000000000',
      high: '32.2926000000000000',
      low: '31.1071000000000000',
      close: '31.7045000000000000',
      volume: '3228596.5900000000000000',
      quoteVolume: 102524672.99267428,
      btcVolume: 12945.174416648542,
      usdVolume: 102524672.99267428,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '31.7293000000000000',
      high: '31.8500000000000000',
      low: '29.7611000000000000',
      close: '30.0950000000000000',
      volume: '2533570.1800000000000000',
      quoteVolume: 78429078.60609235,
      btcVolume: 10087.18462271012,
      usdVolume: 78429078.60609235,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '30.1174000000000000',
      high: '32.0438000000000000',
      low: '29.7286000000000000',
      close: '32.0271000000000000',
      volume: '3550510.6300000000000000',
      quoteVolume: 110950707.9152291,
      btcVolume: 14185.907492697892,
      usdVolume: 110950707.9152291,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '32.0261000000000000',
      high: '32.0821000000000000',
      low: '30.9312000000000000',
      close: '31.8889000000000000',
      volume: '2693421.5300000000000000',
      quoteVolume: 84894700.01127566,
      btcVolume: 10799.407953421249,
      usdVolume: 84894700.01127566,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '31.8889000000000000',
      high: '34.9800000000000000',
      low: '31.8008000000000000',
      close: '34.8310000000000000',
      volume: '3941279.3400000000000000',
      quoteVolume: 132789273.60234739,
      btcVolume: 16598.78224827631,
      usdVolume: 132789273.60234739,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '34.8284000000000000',
      high: '36.3689000000000000',
      low: '34.1200000000000000',
      close: '35.2510000000000000',
      volume: '3537185.5100000000000000',
      quoteVolume: 124552938.20500359,
      btcVolume: 15300.412184978906,
      usdVolume: 124552938.20500359,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '35.2577000000000000',
      high: '35.3000000000000000',
      low: '29.8146000000000000',
      close: '32.3803000000000000',
      volume: '4820144.3200000000000000',
      quoteVolume: 155335662.97399047,
      btcVolume: 18662.671237915034,
      usdVolume: 155335662.97399047,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '32.3887000000000000',
      high: '33.9420000000000000',
      low: '31.6865000000000000',
      close: '32.7500000000000000',
      volume: '3389863.2300000000000000',
      quoteVolume: 111183078.4554273,
      btcVolume: 12804.521200155277,
      usdVolume: 111183078.4554273,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '32.7303000000000000',
      high: '33.2943000000000000',
      low: '31.7316000000000000',
      close: '32.1888000000000000',
      volume: '2826534.9700000000000000',
      quoteVolume: 92109821.4809208,
      btcVolume: 10183.184045346163,
      usdVolume: 92109821.4809208,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '32.1956000000000000',
      high: '34.4500000000000000',
      low: '32.1862000000000000',
      close: '33.8286000000000000',
      volume: '3325355.0300000000000000',
      quoteVolume: 111126704.95099491,
      btcVolume: 12078.924988720071,
      usdVolume: 111126704.95099491,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '33.8542000000000000',
      high: '35.0749000000000000',
      low: '33.7386000000000000',
      close: '34.4333000000000000',
      volume: '3404156.4000000000000000',
      quoteVolume: 117184660.8514287,
      btcVolume: 12820.477601301915,
      usdVolume: 117142616.23937275,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '34.4261000000000000',
      high: '35.6000000000000000',
      low: '34.3939000000000000',
      close: '35.1888000000000000',
      volume: '3662780.3500000000000000',
      quoteVolume: 128944461.18892004,
      btcVolume: 14098.563163206594,
      usdVolume: 128944461.18892004,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '35.1764000000000000',
      high: '36.9146000000000000',
      low: '34.3979000000000000',
      close: '36.8200000000000000',
      volume: '4059479.0200000000000000',
      quoteVolume: 145554685.05118558,
      btcVolume: 15614.242998911897,
      usdVolume: 145554685.05118558,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '36.8377000000000000',
      high: '38.8500000000000000',
      low: '36.3364000000000000',
      close: '38.6666000000000000',
      volume: '3532992.4300000000000000',
      quoteVolume: 132425793.40442118,
      btcVolume: 13534.113990105783,
      usdVolume: 132425793.40442118,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '38.6666000000000000',
      high: '39.5941000000000000',
      low: '37.1100000000000000',
      close: '38.0000000000000000',
      volume: '3287617.1200000000000000',
      quoteVolume: 126851635.12920612,
      btcVolume: 11861.268781544859,
      usdVolume: 126851635.12920612,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '38.0001000000000000',
      high: '38.5320000000000000',
      low: '37.2600000000000000',
      close: '37.2836000000000000',
      volume: '2788591.8500000000000000',
      quoteVolume: 105493792.36592467,
      btcVolume: 9731.003920100708,
      usdVolume: 105493792.36592467,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '37.2665000000000000',
      high: '37.8210000000000000',
      low: '36.0001000000000000',
      close: '37.4300000000000000',
      volume: '2631717.1900000000000000',
      quoteVolume: 97880605.03243802,
      btcVolume: 8975.410919955108,
      usdVolume: 97880605.03243802,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '37.4300000000000000',
      high: '37.5510000000000000',
      low: '35.7100000000000000',
      close: '36.0947000000000000',
      volume: '2000864.9200000000000000',
      quoteVolume: 73473294.34677196,
      btcVolume: 6467.382207788116,
      usdVolume: 73473294.34677196,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '36.0941000000000000',
      high: '38.0000000000000000',
      low: '34.5000000000000000',
      close: '36.0999000000000000',
      volume: '2758448.8600000000000000',
      quoteVolume: 100936180.80838415,
      btcVolume: 7924.993856109126,
      usdVolume: 100936180.80838415,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '36.0999000000000000',
      high: '36.3500000000000000',
      low: '33.1584000000000000',
      close: '34.3999000000000000',
      volume: '2591182.3600000000000000',
      quoteVolume: 89690344.30455235,
      btcVolume: 7533.165332525957,
      usdVolume: 89690344.30455235,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '34.3420000000000000',
      high: '35.2809000000000000',
      low: '33.8200000000000000',
      close: '34.6289000000000000',
      volume: '2104962.2500000000000000',
      quoteVolume: 72480203.20366873,
      btcVolume: 6101.8047057837575,
      usdVolume: 72480203.20366873,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '34.5951000000000000',
      high: '35.8000000000000000',
      low: '33.2447000000000000',
      close: '35.5000000000000000',
      volume: '2585753.6300000000000000',
      quoteVolume: 89769494.98459294,
      btcVolume: 7508.666820715312,
      usdVolume: 89769494.98459294,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '35.5111000000000000',
      high: '35.5111000000000000',
      low: '32.0200000000000000',
      close: '32.1314000000000000',
      volume: '2022991.5500000000000000',
      quoteVolume: 68980161.28362553,
      btcVolume: 5906.05441303598,
      usdVolume: 68980161.28362553,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '32.1140000000000000',
      high: '34.0500000000000000',
      low: '31.3682000000000000',
      close: '33.2777000000000000',
      volume: '1834460.9000000000000000',
      quoteVolume: 59929948.94285722,
      btcVolume: 5568.470684819062,
      usdVolume: 59929948.94285722,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '33.2777000000000000',
      high: '34.0000000000000000',
      low: '30.5600000000000000',
      close: '31.9570000000000000',
      volume: '2016746.5900000000000000',
      quoteVolume: 64596527.83502798,
      btcVolume: 6368.043385041789,
      usdVolume: 64596527.83502798,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '31.9772000000000000',
      high: '33.0500000000000000',
      low: '31.6230000000000000',
      close: '32.5414000000000000',
      volume: '1930269.8700000000000000',
      quoteVolume: 62412176.663419046,
      btcVolume: 5508.986915116347,
      usdVolume: 62412176.663419046,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '32.5237000000000000',
      high: '34.3839000000000000',
      low: '32.1503000000000000',
      close: '33.0888000000000000',
      volume: '2193452.4800000000000000',
      quoteVolume: 73032732.02201295,
      btcVolume: 6260.154521227522,
      usdVolume: 73032732.02201295,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '33.0399000000000000',
      high: '33.6497000000000000',
      low: '32.4000000000000000',
      close: '32.5566000000000000',
      volume: '1501816.7600000000000000',
      quoteVolume: 49493032.56383061,
      btcVolume: 4446.897333852955,
      usdVolume: 49493032.56383061,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '32.5507000000000000',
      high: '33.1815000000000000',
      low: '32.5000000000000000',
      close: '32.5888000000000000',
      volume: '1122287.4400000000000000',
      quoteVolume: 36787236.23351705,
      btcVolume: 3237.2866952944937,
      usdVolume: 36787236.23351705,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '32.5471000000000000',
      high: '33.3997000000000000',
      low: '32.4000000000000000',
      close: '32.9344000000000000',
      volume: '1219900.0500000000000000',
      quoteVolume: 40138364.745880224,
      btcVolume: 3553.1949115873035,
      usdVolume: 40138364.745880224,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '32.9326000000000000',
      high: '34.2918000000000000',
      low: '32.7500000000000000',
      close: '33.3888000000000000',
      volume: '1483935.3600000000000000',
      quoteVolume: 49789526.97420341,
      btcVolume: 4261.852971883836,
      usdVolume: 49789526.97420341,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '33.3888000000000000',
      high: '33.9000000000000000',
      low: '32.2195000000000000',
      close: '32.5075000000000000',
      volume: '1212484.7200000000000000',
      quoteVolume: 40118253.17297037,
      btcVolume: 3221.2079585364836,
      usdVolume: 40118253.17297037,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '32.4825000000000000',
      high: '32.8900000000000000',
      low: '30.5100000000000000',
      close: '31.2220000000000000',
      volume: '1575508.6000000000000000',
      quoteVolume: 50081252.967029475,
      btcVolume: 3955.920822486626,
      usdVolume: 50081252.967029475,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '31.2220000000000000',
      high: '31.8900000000000000',
      low: '28.2666000000000000',
      close: '29.4181000000000000',
      volume: '1727744.5200000000000000',
      quoteVolume: 51340643.34346419,
      btcVolume: 4462.463255903058,
      usdVolume: 51340643.34346419,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '29.4213000000000000',
      high: '32.6219000000000000',
      low: '29.0000000000000000',
      close: '31.8894000000000000',
      volume: '2941848.2500000000000000',
      quoteVolume: 92635627.07000853,
      btcVolume: 8103.016132792709,
      usdVolume: 92635627.07000853,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '31.8874000000000000',
      high: '32.4894000000000000',
      low: '30.2075000000000000',
      close: '31.4888000000000000',
      volume: '1746699.7900000000000000',
      quoteVolume: 54982624.17098898,
      btcVolume: 4813.480581114553,
      usdVolume: 54982624.17098898,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '31.4701000000000000',
      high: '31.5259000000000000',
      low: '28.3111000000000000',
      close: '28.5589000000000000',
      volume: '2103525.3200000000000000',
      quoteVolume: 62793063.28152121,
      btcVolume: 5821.32081882098,
      usdVolume: 62793063.28152121,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '28.5584000000000000',
      high: '28.7498000000000000',
      low: '26.3190000000000000',
      close: '28.6489000000000000',
      volume: '2954452.0200000000000000',
      quoteVolume: 81881912.98124091,
      btcVolume: 7918.900039057427,
      usdVolume: 81881912.98124091,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '28.6348000000000000',
      high: '29.5800000000000000',
      low: '24.8626000000000000',
      close: '25.0601000000000000',
      volume: '3691855.4300000000000000',
      quoteVolume: 100236711.8868326,
      btcVolume: 9803.524590355422,
      usdVolume: 100236711.8868326,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '25.0552000000000000',
      high: '28.2898000000000000',
      low: '24.1709000000000000',
      close: '27.6258000000000000',
      volume: '5619617.8800000000000000',
      quoteVolume: 147693999.79602024,
      btcVolume: 15463.868456792456,
      usdVolume: 147693999.79602024,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '27.6264000000000000',
      high: '29.5000000000000000',
      low: '26.8000000000000000',
      close: '28.9381000000000000',
      volume: '6265532.8900000000000000',
      quoteVolume: 177710944.23860273,
      btcVolume: 17945.243760879956,
      usdVolume: 177608163.10294086,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '28.9521000000000000',
      high: '29.9600000000000000',
      low: '28.3158000000000000',
      close: '29.0096000000000000',
      volume: '3925662.8400000000000000',
      quoteVolume: 113855677.67222823,
      btcVolume: 10894.61710090789,
      usdVolume: 113855677.67222823,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '29.0015000000000000',
      high: '31.4600000000000000',
      low: '28.9000000000000000',
      close: '31.0932000000000000',
      volume: '2868452.7700000000000000',
      quoteVolume: 87962899.98284975,
      btcVolume: 8266.346344725387,
      usdVolume: 87962899.98284975,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '31.1222000000000000',
      high: '31.5281000000000000',
      low: '29.2801000000000000',
      close: '30.4388000000000000',
      volume: '2165383.6300000000000000',
      quoteVolume: 65800415.77082615,
      btcVolume: 6225.919909520394,
      usdVolume: 65800415.77082615,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '30.4389000000000000',
      high: '32.6000000000000000',
      low: '29.6203000000000000',
      close: '30.8850000000000000',
      volume: '3465285.3200000000000000',
      quoteVolume: 107521586.41408998,
      btcVolume: 10273.673397545184,
      usdVolume: 107521586.41408998,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '30.9226000000000000',
      high: '30.9400000000000000',
      low: '28.9471000000000000',
      close: '29.4894000000000000',
      volume: '2638959.1800000000000000',
      quoteVolume: 78461044.85644288,
      btcVolume: 7795.804246913197,
      usdVolume: 78461044.85644288,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '29.4649000000000000',
      high: '29.5057000000000000',
      low: '27.7426000000000000',
      close: '28.8303000000000000',
      volume: '2335697.9600000000000000',
      quoteVolume: 66771505.28094476,
      btcVolume: 6868.82631676914,
      usdVolume: 66771505.28094476,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '28.8304000000000000',
      high: '29.6596000000000000',
      low: '28.4444000000000000',
      close: '28.9395000000000000',
      volume: '2189105.6200000000000000',
      quoteVolume: 63998748.52433625,
      btcVolume: 6402.849558443817,
      usdVolume: 63998748.52433625,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '28.9227000000000000',
      high: '29.0500000000000000',
      low: '28.1641000000000000',
      close: '28.8983000000000000',
      volume: '1926812.0300000000000000',
      quoteVolume: 55269615.02700419,
      btcVolume: 5653.511709576811,
      usdVolume: 55269615.02700419,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '28.8983000000000000',
      high: '29.4502000000000000',
      low: '27.2061000000000000',
      close: '27.7498000000000000',
      volume: '1482247.8300000000000000',
      quoteVolume: 41938098.57057801,
      btcVolume: 4304.517936922523,
      usdVolume: 41938098.57057801,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '27.7384000000000000',
      high: '27.8459000000000000',
      low: '26.4200000000000000',
      close: '27.7627000000000000',
      volume: '1320975.1600000000000000',
      quoteVolume: 36374310.99186316,
      btcVolume: 3832.5016194283835,
      usdVolume: 36374310.99186316,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '27.7581000000000000',
      high: '28.0419000000000000',
      low: '26.8202000000000000',
      close: '26.9525000000000000',
      volume: '1140682.0500000000000000',
      quoteVolume: 31110138.426472325,
      btcVolume: 3256.314799181455,
      usdVolume: 31110138.426472325,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '26.9540000000000000',
      high: '27.1198000000000000',
      low: '26.2020000000000000',
      close: '26.9867000000000000',
      volume: '820023.9000000000000000',
      quoteVolume: 21902889.9898799,
      btcVolume: 2296.8262791212037,
      usdVolume: 21902889.9898799,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '26.9879000000000000',
      high: '27.8300000000000000',
      low: '26.7800000000000000',
      close: '27.7348000000000000',
      volume: '1934428.9000000000000000',
      quoteVolume: 53152588.64401577,
      btcVolume: 5403.686098362969,
      usdVolume: 53152588.64401577,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '27.7500000000000000',
      high: '28.7664000000000000',
      low: '27.4500000000000000',
      close: '28.7088000000000000',
      volume: '2252485.2300000000000000',
      quoteVolume: 63463951.041090965,
      btcVolume: 6318.884088129215,
      usdVolume: 63463951.041090965,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '28.7100000000000000',
      high: '28.7186000000000000',
      low: '27.5643000000000000',
      close: '28.1400000000000000',
      volume: '1501539.5400000000000000',
      quoteVolume: 42179913.12631353,
      btcVolume: 4032.0149580734223,
      usdVolume: 42179913.12631353,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '28.1400000000000000',
      high: '28.3409000000000000',
      low: '27.5000000000000000',
      close: '27.7285000000000000',
      volume: '1209342.2000000000000000',
      quoteVolume: 33690305.912137166,
      btcVolume: 3130.3892108291648,
      usdVolume: 33690305.912137166,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '27.7333000000000000',
      high: '27.7719000000000000',
      low: '27.0852000000000000',
      close: '27.3834000000000000',
      volume: '967494.6400000000000000',
      quoteVolume: 26514204.222139858,
      btcVolume: 2463.628698032826,
      usdVolume: 26514204.222139858,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '27.3834000000000000',
      high: '28.3000000000000000',
      low: '27.3011000000000000',
      close: '28.0107000000000000',
      volume: '1170209.2600000000000000',
      quoteVolume: 32618569.941277068,
      btcVolume: 2806.4498779368155,
      usdVolume: 32618569.941277068,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '28.0117000000000000',
      high: '28.3125000000000000',
      low: '27.3333000000000000',
      close: '27.6523000000000000',
      volume: '1404874.7100000000000000',
      quoteVolume: 39075613.06976784,
      btcVolume: 3302.2858155088666,
      usdVolume: 39075613.06976784,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '27.6539000000000000',
      high: '29.9800000000000000',
      low: '27.3330000000000000',
      close: '29.6694000000000000',
      volume: '2398210.6200000000000000',
      quoteVolume: 68536315.25246768,
      btcVolume: 5832.360785093793,
      usdVolume: 68536315.25246768,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '29.6690000000000000',
      high: '31.8791000000000000',
      low: '29.1914000000000000',
      close: '31.1427000000000000',
      volume: '3071252.6500000000000000',
      quoteVolume: 94636423.46255855,
      btcVolume: 8004.065605940615,
      usdVolume: 94636423.46255855,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '31.1074000000000000',
      high: '31.5000000000000000',
      low: '29.6666000000000000',
      close: '29.8188000000000000',
      volume: '1467817.4400000000000000',
      quoteVolume: 45035359.27979547,
      btcVolume: 3801.756204313858,
      usdVolume: 45035359.27979547,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '29.7969000000000000',
      high: '30.0200000000000000',
      low: '29.0333000000000000',
      close: '29.6662000000000000',
      volume: '1302579.2400000000000000',
      quoteVolume: 38469949.58556509,
      btcVolume: 3320.6255793968776,
      usdVolume: 38469949.58556509,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '29.6660000000000000',
      high: '30.4458000000000000',
      low: '29.3000000000000000',
      close: '30.2109000000000000',
      volume: '1563528.9000000000000000',
      quoteVolume: 46830334.26929312,
      btcVolume: 4107.984996045789,
      usdVolume: 46830334.26929312,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '30.3192000000000000',
      high: '30.5936000000000000',
      low: '29.8230000000000000',
      close: '30.1856000000000000',
      volume: '1527254.3500000000000000',
      quoteVolume: 46134967.60864254,
      btcVolume: 4043.0634741807817,
      usdVolume: 46134967.60864254,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '30.1856000000000000',
      high: '30.4496000000000000',
      low: '29.2000000000000000',
      close: '29.4537000000000000',
      volume: '1164233.9000000000000000',
      quoteVolume: 34747155.42068626,
      btcVolume: 3108.3618369887367,
      usdVolume: 34747155.42068626,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '29.4512000000000000',
      high: '29.4693000000000000',
      low: '26.7500000000000000',
      close: '27.0106000000000000',
      volume: '1176934.2300000000000000',
      quoteVolume: 33298167.10513729,
      btcVolume: 3178.5215896041464,
      usdVolume: 33298167.10513729,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '27.0269000000000000',
      high: '28.2174000000000000',
      low: '26.7000000000000000',
      close: '28.1058000000000000',
      volume: '1242352.4600000000000000',
      quoteVolume: 34322334.57446039,
      btcVolume: 3395.545034113294,
      usdVolume: 34322334.57446039,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '28.1052000000000000',
      high: '28.1313000000000000',
      low: '26.7880000000000000',
      close: '27.5111000000000000',
      volume: '965369.6300000000000000',
      quoteVolume: 26500903.438648794,
      btcVolume: 2602.9765482710295,
      usdVolume: 26500903.438648794,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '27.4908000000000000',
      high: '27.9605000000000000',
      low: '26.9863000000000000',
      close: '27.5038000000000000',
      volume: '963986.7000000000000000',
      quoteVolume: 26558675.591700017,
      btcVolume: 2579.2725537309097,
      usdVolume: 26558675.591700017,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '27.5037000000000000',
      high: '28.1797000000000000',
      low: '27.0511000000000000',
      close: '27.8977000000000000',
      volume: '1210841.2500000000000000',
      quoteVolume: 33534708.06565196,
      btcVolume: 3250.687025349346,
      usdVolume: 33534708.06565196,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '27.9033000000000000',
      high: '29.1779000000000000',
      low: '27.6884000000000000',
      close: '28.7931000000000000',
      volume: '1802460.6600000000000000',
      quoteVolume: 51651237.54506001,
      btcVolume: 4855.6186625084,
      usdVolume: 51651237.54506001,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '28.7974000000000000',
      high: '28.8079000000000000',
      low: '27.5200000000000000',
      close: '28.0111000000000000',
      volume: '1230912.5200000000000000',
      quoteVolume: 34656879.16071867,
      btcVolume: 3223.4213999415306,
      usdVolume: 34656879.16071867,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '28.0110000000000000',
      high: '28.0510000000000000',
      low: '26.3000000000000000',
      close: '26.9250000000000000',
      volume: '1007955.5700000000000000',
      quoteVolume: 27333103.0550634,
      btcVolume: 2672.2406391647623,
      usdVolume: 27333103.0550634,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '26.9328000000000000',
      high: '27.3301000000000000',
      low: '26.2110000000000000',
      close: '26.9409000000000000',
      volume: '1087088.9100000000000000',
      quoteVolume: 29207188.464991644,
      btcVolume: 2909.9205678527996,
      usdVolume: 29207188.464991644,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '26.9652000000000000',
      high: '27.2864000000000000',
      low: '26.7400000000000000',
      close: '26.9400000000000000',
      volume: '880521.1800000000000000',
      quoteVolume: 23808910.937024746,
      btcVolume: 2322.2513891613335,
      usdVolume: 23808910.937024746,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '26.9399000000000000',
      high: '26.9586000000000000',
      low: '26.0100000000000000',
      close: '26.2814000000000000',
      volume: '917921.5400000000000000',
      quoteVolume: 24273248.70356496,
      btcVolume: 2390.3516691531204,
      usdVolume: 24273248.70356496,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '26.2982000000000000',
      high: '26.4000000000000000',
      low: '25.5700000000000000',
      close: '26.0888000000000000',
      volume: '933021.8300000000000000',
      quoteVolume: 24320148.965540506,
      btcVolume: 2410.161394681131,
      usdVolume: 24320148.965540506,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '26.0886000000000000',
      high: '27.1908000000000000',
      low: '25.6502000000000000',
      close: '26.1489000000000000',
      volume: '1811039.1700000000000000',
      quoteVolume: 47866074.05944471,
      btcVolume: 4619.226473501923,
      usdVolume: 47866074.05944471,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '26.1251000000000000',
      high: '26.1507000000000000',
      low: '24.6224000000000000',
      close: '25.6051000000000000',
      volume: '1196840.9100000000000000',
      quoteVolume: 30351679.124037657,
      btcVolume: 2975.526344844052,
      usdVolume: 30351679.124037657,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '25.6056000000000000',
      high: '26.1290000000000000',
      low: '23.2030000000000000',
      close: '23.3565000000000000',
      volume: '1398347.3100000000000000',
      quoteVolume: 34916854.38443927,
      btcVolume: 3480.137824867305,
      usdVolume: 34916854.38443927,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '23.3559000000000000',
      high: '23.3559000000000000',
      low: '21.2202000000000000',
      close: '21.7777000000000000',
      volume: '1617357.3700000000000000',
      quoteVolume: 35791217.56290334,
      btcVolume: 3750.9222576047623,
      usdVolume: 35791217.56290334,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '21.7894000000000000',
      high: '22.5678000000000000',
      low: '21.5000000000000000',
      close: '22.1448000000000000',
      volume: '1599602.4900000000000000',
      quoteVolume: 35445343.4393091,
      btcVolume: 3714.583705432022,
      usdVolume: 35445343.4393091,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '22.1449000000000000',
      high: '22.3700000000000000',
      low: '20.8857000000000000',
      close: '21.0604000000000000',
      volume: '1372689.9900000000000000',
      quoteVolume: 29884844.230699934,
      btcVolume: 3119.26041144571,
      usdVolume: 29884844.230699934,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '21.0602000000000000',
      high: '21.6828000000000000',
      low: '20.9800000000000000',
      close: '21.4248000000000000',
      volume: '1434196.5900000000000000',
      quoteVolume: 30594531.66041575,
      btcVolume: 3180.3090835419152,
      usdVolume: 30594531.66041575,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '21.4195000000000000',
      high: '22.8303000000000000',
      low: '21.0100000000000000',
      close: '22.7449000000000000',
      volume: '1743830.8100000000000000',
      quoteVolume: 38331837.52907952,
      btcVolume: 3871.494591953881,
      usdVolume: 38331837.52907952,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '22.7449000000000000',
      high: '22.8900000000000000',
      low: '22.1615000000000000',
      close: '22.3511000000000000',
      volume: '1355171.8400000000000000',
      quoteVolume: 30562302.513001986,
      btcVolume: 2912.5933500976334,
      usdVolume: 30562302.513001986,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '22.3521000000000000',
      high: '22.5998000000000000',
      low: '21.4699000000000000',
      close: '21.6764000000000000',
      volume: '1086116.6600000000000000',
      quoteVolume: 23806043.422344267,
      btcVolume: 2257.8192440245984,
      usdVolume: 23806043.422344267,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '21.6821000000000000',
      high: '23.5213000000000000',
      low: '21.3000000000000000',
      close: '22.6385000000000000',
      volume: '2235402.4400000000000000',
      quoteVolume: 50322754.76862728,
      btcVolume: 4775.140325449655,
      usdVolume: 50322754.76862728,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '22.6389000000000000',
      high: '23.4450000000000000',
      low: '21.6997000000000000',
      close: '21.9905000000000000',
      volume: '1990229.3000000000000000',
      quoteVolume: 44871162.02233011,
      btcVolume: 4221.877198386819,
      usdVolume: 44871162.02233011,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '21.9906000000000000',
      high: '22.6500000000000000',
      low: '21.9100000000000000',
      close: '22.4120000000000000',
      volume: '1681983.7800000000000000',
      quoteVolume: 37521579.67938833,
      btcVolume: 3608.3791504554933,
      usdVolume: 37521579.67938833,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '22.4120000000000000',
      high: '22.7321000000000000',
      low: '22.0000000000000000',
      close: '22.4111000000000000',
      volume: '1304840.1500000000000000',
      quoteVolume: 29335266.506811257,
      btcVolume: 2806.5841675990073,
      usdVolume: 29335266.506811257,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '22.3747000000000000',
      high: '22.4901000000000000',
      low: '21.7400000000000000',
      close: '22.1046000000000000',
      volume: '1006341.0100000000000000',
      quoteVolume: 22302503.142360844,
      btcVolume: 2165.136904136606,
      usdVolume: 22302503.142360844,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '22.0978000000000000',
      high: '22.3262000000000000',
      low: '21.5000000000000000',
      close: '21.7679000000000000',
      volume: '831886.4700000000000000',
      quoteVolume: 18339145.368692745,
      btcVolume: 1792.2664010096803,
      usdVolume: 18339145.368692745,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '21.7704000000000000',
      high: '22.0724000000000000',
      low: '20.5413000000000000',
      close: '20.7334000000000000',
      volume: '1069114.6200000000000000',
      quoteVolume: 22669645.665027037,
      btcVolume: 2253.2292197146694,
      usdVolume: 22669645.665027037,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '20.7315000000000000',
      high: '20.9572000000000000',
      low: '19.6500000000000000',
      close: '20.8197000000000000',
      volume: '1699362.5600000000000000',
      quoteVolume: 34777083.85403479,
      btcVolume: 3420.318759152667,
      usdVolume: 34777083.85403479,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '20.8197000000000000',
      high: '21.2475000000000000',
      low: '20.4088000000000000',
      close: '20.7880000000000000',
      volume: '1429270.9500000000000000',
      quoteVolume: 29787724.15361781,
      btcVolume: 2889.221782175787,
      usdVolume: 29787724.15361781,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '20.7889000000000000',
      high: '21.1500000000000000',
      low: '20.6714000000000000',
      close: '20.9555000000000000',
      volume: '1305740.9000000000000000',
      quoteVolume: 27255071.779076073,
      btcVolume: 2640.2704864370567,
      usdVolume: 27255071.779076073,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '20.9438000000000000',
      high: '21.0419000000000000',
      low: '20.2619000000000000',
      close: '20.2901000000000000',
      volume: '1149167.0800000000000000',
      quoteVolume: 23790454.124340992,
      btcVolume: 2309.5205840680474,
      usdVolume: 23790454.124340992,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '20.2901000000000000',
      high: '20.8000000000000000',
      low: '19.8775000000000000',
      close: '20.2422000000000000',
      volume: '1032883.1400000000000000',
      quoteVolume: 21027752.240456037,
      btcVolume: 2049.967916517665,
      usdVolume: 21027752.240456037,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '20.2460000000000000',
      high: '21.4390000000000000',
      low: '20.0300000000000000',
      close: '21.0473000000000000',
      volume: '1845592.2700000000000000',
      quoteVolume: 38220802.17507817,
      btcVolume: 3744.021260306312,
      usdVolume: 38220802.17507817,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '21.0302000000000000',
      high: '23.1700000000000000',
      low: '20.9442000000000000',
      close: '22.1986000000000000',
      volume: '3302750.2400000000000000',
      quoteVolume: 73063135.11076553,
      btcVolume: 7173.648061693396,
      usdVolume: 73063135.11076553,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '22.1988000000000000',
      high: '22.4383000000000000',
      low: '20.0022000000000000',
      close: '21.5376000000000000',
      volume: '1560199.9000000000000000',
      quoteVolume: 33005910.961343434,
      btcVolume: 3309.6496506288595,
      usdVolume: 33005910.961343434,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '21.5281000000000000',
      high: '21.5764000000000000',
      low: '20.9005000000000000',
      close: '21.2695000000000000',
      volume: '1389940.6700000000000000',
      quoteVolume: 29611689.325407807,
      btcVolume: 2910.330413478063,
      usdVolume: 29611689.325407807,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '21.2741000000000000',
      high: '21.4494000000000000',
      low: '20.8096000000000000',
      close: '20.9666000000000000',
      volume: '1127512.7000000000000000',
      quoteVolume: 23814050.32814106,
      btcVolume: 2369.7781077006107,
      usdVolume: 23814050.32814106,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '20.9732000000000000',
      high: '20.9912000000000000',
      low: '20.0000000000000000',
      close: '20.3602000000000000',
      volume: '972362.6300000000000000',
      quoteVolume: 19879295.326771013,
      btcVolume: 1991.1559276366263,
      usdVolume: 19879295.326771013,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '20.3640000000000000',
      high: '20.3640000000000000',
      low: '19.1614000000000000',
      close: '19.3228000000000000',
      volume: '1094197.9900000000000000',
      quoteVolume: 21719659.651494753,
      btcVolume: 2196.348015116035,
      usdVolume: 21719659.651494753,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '19.3127000000000000',
      high: '19.5000000000000000',
      low: '14.6400000000000000',
      close: '15.8249000000000000',
      volume: '2280940.4000000000000000',
      quoteVolume: 40128590.36126655,
      btcVolume: 4420.050188018196,
      usdVolume: 40128590.36126655,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '15.8240000000000000',
      high: '16.4882000000000000',
      low: '14.8945000000000000',
      close: '16.0321000000000000',
      volume: '1826023.8900000000000000',
      quoteVolume: 28771070.64009279,
      btcVolume: 3404.4134048413493,
      usdVolume: 28771070.64009279,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '16.0138000000000000',
      high: '16.4000000000000000',
      low: '14.2555000000000000',
      close: '15.2212000000000000',
      volume: '1777727.2900000000000000',
      quoteVolume: 27673009.60844759,
      btcVolume: 3368.7647208454014,
      usdVolume: 27673009.60844759,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '15.2113000000000000',
      high: '15.7770000000000000',
      low: '14.7000000000000000',
      close: '15.6666000000000000',
      volume: '1435874.0300000000000000',
      quoteVolume: 21891944.29132878,
      btcVolume: 2721.037106291271,
      usdVolume: 21891944.29132878,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '15.6666000000000000',
      high: '15.8729000000000000',
      low: '15.2911000000000000',
      close: '15.7346000000000000',
      volume: '1097731.6800000000000000',
      quoteVolume: 17158364.84970291,
      btcVolume: 2104.8652182188,
      usdVolume: 17158364.84970291,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '15.7335000000000000',
      high: '15.7953000000000000',
      low: '14.8018000000000000',
      close: '15.1999000000000000',
      volume: '963970.0200000000000000',
      quoteVolume: 14796006.704381201,
      btcVolume: 1833.4019387024855,
      usdVolume: 14796006.704381201,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '15.1870000000000000',
      high: '15.8506000000000000',
      low: '14.6200000000000000',
      close: '15.8349000000000000',
      volume: '1129249.1300000000000000',
      quoteVolume: 17240225.00851707,
      btcVolume: 2144.011528382059,
      usdVolume: 17240225.00851707,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '15.8493000000000000',
      high: '16.2800000000000000',
      low: '15.6295000000000000',
      close: '15.8563000000000000',
      volume: '1180076.1200000000000000',
      quoteVolume: 18822946.423811994,
      btcVolume: 2253.1708840608026,
      usdVolume: 18822946.423811994,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '15.8689000000000000',
      high: '15.8892000000000000',
      low: '15.5000000000000000',
      close: '15.8742000000000000',
      volume: '950281.1200000000000000',
      quoteVolume: 14907087.290238062,
      btcVolume: 1806.9759980656027,
      usdVolume: 14907087.290238062,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '15.8900000000000000',
      high: '15.9290000000000000',
      low: '15.2500000000000000',
      close: '15.6377000000000000',
      volume: '904723.7000000000000000',
      quoteVolume: 14166132.276806083,
      btcVolume: 1718.482479568365,
      usdVolume: 14166132.276806083,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '15.6489000000000000',
      high: '15.8200000000000000',
      low: '15.2100000000000000',
      close: '15.6133000000000000',
      volume: '857614.2600000000000000',
      quoteVolume: 13323519.193896951,
      btcVolume: 1637.9909754976863,
      usdVolume: 13323519.193896951,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '15.6028000000000000',
      high: '15.7794000000000000',
      low: '15.3644000000000000',
      close: '15.6869000000000000',
      volume: '815215.7400000000000000',
      quoteVolume: 12687937.458294004,
      btcVolume: 1565.997866828115,
      usdVolume: 12687937.458294004,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '15.6866000000000000',
      high: '15.7299000000000000',
      low: '14.9100000000000000',
      close: '15.0688000000000000',
      volume: '814844.4500000000000000',
      quoteVolume: 12466458.898545044,
      btcVolume: 1563.9266798482508,
      usdVolume: 12466458.898545044,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '15.0655000000000000',
      high: '16.1280000000000000',
      low: '14.8555000000000000',
      close: '16.0030000000000000',
      volume: '1137667.6700000000000000',
      quoteVolume: 17614353.686007068,
      btcVolume: 2197.8583870903244,
      usdVolume: 17614353.686007068,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '16.0198000000000000',
      high: '16.4007000000000000',
      low: '15.7959000000000000',
      close: '16.0213000000000000',
      volume: '992780.4300000000000000',
      quoteVolume: 15889938.956474038,
      btcVolume: 1934.7114076545115,
      usdVolume: 15889938.956474038,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '16.0206000000000000',
      high: '17.9999000000000000',
      low: '15.8829000000000000',
      close: '17.7100000000000000',
      volume: '2809202.1000000000000000',
      quoteVolume: 47844345.02730628,
      btcVolume: 5781.710685310098,
      usdVolume: 47844345.02730628,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '17.7172000000000000',
      high: '17.9180000000000000',
      low: '17.0310000000000000',
      close: '17.5300000000000000',
      volume: '1785749.3400000000000000',
      quoteVolume: 31222666.861801084,
      btcVolume: 3655.173159325509,
      usdVolume: 31222666.861801084,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '17.5288000000000000',
      high: '17.7000000000000000',
      low: '16.5100000000000000',
      close: '16.5656000000000000',
      volume: '1476429.8400000000000000',
      quoteVolume: 25136224.21603257,
      btcVolume: 2982.0542530942034,
      usdVolume: 25136224.21603257,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '16.5665000000000000',
      high: '17.4868000000000000',
      low: '16.5059000000000000',
      close: '17.1700000000000000',
      volume: '1405829.2300000000000000',
      quoteVolume: 23921395.579711266,
      btcVolume: 2872.116767496237,
      usdVolume: 23921395.579711266,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '17.1666000000000000',
      high: '18.7699000000000000',
      low: '17.1110000000000000',
      close: '18.2560000000000000',
      volume: '2233894.4300000000000000',
      quoteVolume: 40107522.71327606,
      btcVolume: 4802.447899917144,
      usdVolume: 40107522.71327606,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '18.2560000000000000',
      high: '18.6275000000000000',
      low: '17.8511000000000000',
      close: '18.4376000000000000',
      volume: '1643019.3600000000000000',
      quoteVolume: 29971983.59315997,
      btcVolume: 3613.0751251258484,
      usdVolume: 29971983.59315997,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '18.4389000000000000',
      high: '19.1700000000000000',
      low: '18.1000000000000000',
      close: '18.5697000000000000',
      volume: '2913060.4200000000000000',
      quoteVolume: 54382456.61385796,
      btcVolume: 6569.711201030303,
      usdVolume: 54382456.61385796,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '18.5697000000000000',
      high: '18.6470000000000000',
      low: '17.3627000000000000',
      close: '17.7401000000000000',
      volume: '1815021.0600000000000000',
      quoteVolume: 32739450.98535026,
      btcVolume: 4055.212668955555,
      usdVolume: 32739450.98535026,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '17.7504000000000000',
      high: '18.8346000000000000',
      low: '17.5620000000000000',
      close: '18.5188000000000000',
      volume: '1889272.6600000000000000',
      quoteVolume: 34608013.92635818,
      btcVolume: 4311.318414716609,
      usdVolume: 34608013.92635818,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '18.5205000000000000',
      high: '18.7501000000000000',
      low: '17.6733000000000000',
      close: '18.2188000000000000',
      volume: '1427724.2100000000000000',
      quoteVolume: 26053677.954359047,
      btcVolume: 3266.035239146559,
      usdVolume: 26053677.954359047,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '18.2188000000000000',
      high: '18.4444000000000000',
      low: '17.8575000000000000',
      close: '18.2229000000000000',
      volume: '1457872.5200000000000000',
      quoteVolume: 26457012.36290216,
      btcVolume: 3326.33246552125,
      usdVolume: 26457012.36290216,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '18.2189000000000000',
      high: '18.5800000000000000',
      low: '17.7152000000000000',
      close: '18.4997000000000000',
      volume: '1696662.0200000000000000',
      quoteVolume: 30829354.6997274,
      btcVolume: 3832.375444981121,
      usdVolume: 30829354.6997274,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '18.4897000000000000',
      high: '18.5171000000000000',
      low: '17.9100000000000000',
      close: '18.2560000000000000',
      volume: '1497274.8700000000000000',
      quoteVolume: 27204985.86214098,
      btcVolume: 3313.6879489931994,
      usdVolume: 27204985.86214098,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '18.2551000000000000',
      high: '18.8000000000000000',
      low: '18.0692000000000000',
      close: '18.2286000000000000',
      volume: '1819185.3800000000000000',
      quoteVolume: 33532586.548880003,
      btcVolume: 4093.9879290171616,
      usdVolume: 33532586.548880003,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '18.2581000000000000',
      high: '18.2700000000000000',
      low: '16.2111000000000000',
      close: '16.6796000000000000',
      volume: '1735472.3000000000000000',
      quoteVolume: 30019967.217108868,
      btcVolume: 3879.4704562633056,
      usdVolume: 30019967.217108868,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '16.6798000000000000',
      high: '17.0987000000000000',
      low: '16.3100000000000000',
      close: '16.9194000000000000',
      volume: '1244966.9900000000000000',
      quoteVolume: 20790631.76627608,
      btcVolume: 2794.787971493176,
      usdVolume: 20790631.76627608,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '16.9194000000000000',
      high: '19.1110000000000000',
      low: '16.7516000000000000',
      close: '18.6188000000000000',
      volume: '2770630.2600000000000000',
      quoteVolume: 49737182.561813205,
      btcVolume: 6263.872839719397,
      usdVolume: 49737182.561813205,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '18.6189000000000000',
      high: '19.7653000000000000',
      low: '18.0308000000000000',
      close: '18.9501000000000000',
      volume: '3038020.4400000000000000',
      quoteVolume: 57490280.69272188,
      btcVolume: 6119.3718702883,
      usdVolume: 57490280.69272188,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '18.9311000000000000',
      high: '19.6300000000000000',
      low: '18.4744000000000000',
      close: '19.2888000000000000',
      volume: '2292790.2600000000000000',
      quoteVolume: 43689050.22110826,
      btcVolume: 4656.735220807045,
      usdVolume: 43689050.22110826,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '19.2938000000000000',
      high: '21.1500000000000000',
      low: '19.2600000000000000',
      close: '19.9390000000000000',
      volume: '2712590.2200000000000000',
      quoteVolume: 54705192.09512571,
      btcVolume: 5746.423873513421,
      usdVolume: 54705192.09512571,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '19.9379000000000000',
      high: '21.2378000000000000',
      low: '19.8100000000000000',
      close: '20.7452000000000000',
      volume: '2287705.3800000000000000',
      quoteVolume: 47070804.28249447,
      btcVolume: 5028.784777580716,
      usdVolume: 47070804.28249447,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '20.7300000000000000',
      high: '20.7975000000000000',
      low: '19.1076000000000000',
      close: '20.0520000000000000',
      volume: '1822916.4300000000000000',
      quoteVolume: 36219179.39048555,
      btcVolume: 3946.264713227161,
      usdVolume: 36219179.39048555,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '20.0500000000000000',
      high: '20.3700000000000000',
      low: '19.3529000000000000',
      close: '19.9099000000000000',
      volume: '1677653.1800000000000000',
      quoteVolume: 33444789.194522977,
      btcVolume: 3654.9820249353547,
      usdVolume: 33444789.194522977,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '19.9087000000000000',
      high: '20.0399000000000000',
      low: '19.6424000000000000',
      close: '19.9811000000000000',
      volume: '1304915.2800000000000000',
      quoteVolume: 25929970.432292167,
      btcVolume: 2837.8645933661815,
      usdVolume: 25929970.432292167,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '19.9988000000000000',
      high: '20.3000000000000000',
      low: '19.9600000000000000',
      close: '20.1745000000000000',
      volume: '1604349.3200000000000000',
      quoteVolume: 32341714.859536927,
      btcVolume: 3491.3146563665596,
      usdVolume: 32341714.859536927,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '20.1847000000000000',
      high: '20.3900000000000000',
      low: '19.8053000000000000',
      close: '20.1874000000000000',
      volume: '1331174.6000000000000000',
      quoteVolume: 26791642.74283898,
      btcVolume: 2906.420761934206,
      usdVolume: 26791642.74283898,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '20.1725000000000000',
      high: '20.8800000000000000',
      low: '20.0285000000000000',
      close: '20.6139000000000000',
      volume: '1638295.2000000000000000',
      quoteVolume: 33568729.35200188,
      btcVolume: 3633.9058084230064,
      usdVolume: 33568729.35200188,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '20.6139000000000000',
      high: '20.8900000000000000',
      low: '20.3200000000000000',
      close: '20.6588000000000000',
      volume: '1435673.9200000000000000',
      quoteVolume: 29643945.41316726,
      btcVolume: 3174.3640255474643,
      usdVolume: 29643945.41316726,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '20.6621000000000000',
      high: '20.9399000000000000',
      low: '20.4408000000000000',
      close: '20.7000000000000000',
      volume: '1304183.9900000000000000',
      quoteVolume: 26943504.690265935,
      btcVolume: 2886.089792710133,
      usdVolume: 26943504.690265935,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '20.6993000000000000',
      high: '20.8490000000000000',
      low: '19.9100000000000000',
      close: '20.4208000000000000',
      volume: '1034081.1100000000000000',
      quoteVolume: 21112048.776744843,
      btcVolume: 2279.6955633595517,
      usdVolume: 21112048.776744843,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '20.4342000000000000',
      high: '20.5164000000000000',
      low: '19.2609000000000000',
      close: '19.6460000000000000',
      volume: '1081524.1000000000000000',
      quoteVolume: 21620174.59220783,
      btcVolume: 2392.7004682323113,
      usdVolume: 21620174.59220783,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '19.6460000000000000',
      high: '19.9632000000000000',
      low: '19.4626000000000000',
      close: '19.6999000000000000',
      volume: '886139.1000000000000000',
      quoteVolume: 17507369.511074036,
      btcVolume: 1985.1423284212408,
      usdVolume: 17507369.511074036,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '19.7042000000000000',
      high: '20.6000000000000000',
      low: '19.6694000000000000',
      close: '20.4333000000000000',
      volume: '1278095.9300000000000000',
      quoteVolume: 25754570.998597786,
      btcVolume: 2901.93943637929,
      usdVolume: 25754570.998597786,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '20.4323000000000000',
      high: '20.6277000000000000',
      low: '19.7497000000000000',
      close: '20.0888000000000000',
      volume: '1002364.6600000000000000',
      quoteVolume: 20292034.237837035,
      btcVolume: 2289.625297028486,
      usdVolume: 20292034.237837035,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '20.0977000000000000',
      high: '20.9798000000000000',
      low: '19.9443000000000000',
      close: '20.9798000000000000',
      volume: '1235499.6000000000000000',
      quoteVolume: 25207219.032343943,
      btcVolume: 2881.138559153625,
      usdVolume: 25207219.032343943,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '20.9797000000000000',
      high: '21.8000000000000000',
      low: '20.6800000000000000',
      close: '21.3846000000000000',
      volume: '1369703.6200000000000000',
      quoteVolume: 29120752.930271562,
      btcVolume: 3322.2398970219933,
      usdVolume: 29120752.930271562,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '21.3883000000000000',
      high: '21.6492000000000000',
      low: '20.7111000000000000',
      close: '21.3166000000000000',
      volume: '1177353.8200000000000000',
      quoteVolume: 24991032.071327083,
      btcVolume: 2875.553521588978,
      usdVolume: 24991032.071327083,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '21.3165000000000000',
      high: '21.3353000000000000',
      low: '19.9614000000000000',
      close: '20.2735000000000000',
      volume: '1358774.2500000000000000',
      quoteVolume: 27979389.54651814,
      btcVolume: 3269.53297184289,
      usdVolume: 27979389.54651814,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '20.2871000000000000',
      high: '20.5999000000000000',
      low: '19.9000000000000000',
      close: '20.1631000000000000',
      volume: '1001272.5400000000000000',
      quoteVolume: 20291968.77348383,
      btcVolume: 2394.1821660160967,
      usdVolume: 20291968.77348383,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '20.1641000000000000',
      high: '20.4359000000000000',
      low: '19.7500000000000000',
      close: '20.1888000000000000',
      volume: '1153032.2600000000000000',
      quoteVolume: 23183744.303397186,
      btcVolume: 2722.9127437014527,
      usdVolume: 23183744.303397186,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '20.1894000000000000',
      high: '20.1940000000000000',
      low: '18.6000000000000000',
      close: '19.0817000000000000',
      volume: '1368453.1000000000000000',
      quoteVolume: 26728303.31546702,
      btcVolume: 3188.792085551649,
      usdVolume: 26728303.31546702,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '19.0817000000000000',
      high: '19.2800000000000000',
      low: '18.0800000000000000',
      close: '18.4997000000000000',
      volume: '1218553.0600000000000000',
      quoteVolume: 22711534.818970643,
      btcVolume: 2795.312906394973,
      usdVolume: 22711534.818970643,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '18.4999000000000000',
      high: '18.7494000000000000',
      low: '17.7738000000000000',
      close: '18.0826000000000000',
      volume: '1209633.0100000000000000',
      quoteVolume: 22221973.60810997,
      btcVolume: 2739.6584331838912,
      usdVolume: 22221973.60810997,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '18.0827000000000000',
      high: '18.2399000000000000',
      low: '16.3400000000000000',
      close: '16.7985000000000000',
      volume: '1715376.7100000000000000',
      quoteVolume: 29495384.97432084,
      btcVolume: 3764.2748558580925,
      usdVolume: 29495384.97432084,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '16.7986000000000000',
      high: '17.2798000000000000',
      low: '14.2846000000000000',
      close: '15.4875000000000000',
      volume: '3355659.6400000000000000',
      quoteVolume: 52944056.290057965,
      btcVolume: 7292.508377878102,
      usdVolume: 52944056.290057965,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '15.4767000000000000',
      high: '16.3483000000000000',
      low: '14.9570000000000000',
      close: '16.2320000000000000',
      volume: '1941896.4600000000000000',
      quoteVolume: 30375854.714530647,
      btcVolume: 4197.788544615611,
      usdVolume: 30375854.714530647,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '16.2321000000000000',
      high: '16.5060000000000000',
      low: '14.9000000000000000',
      close: '14.9964000000000000',
      volume: '2155053.8800000000000000',
      quoteVolume: 34080052.57061797,
      btcVolume: 4773.880226329648,
      usdVolume: 34080052.57061797,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '15.0152000000000000',
      high: '15.7600000000000000',
      low: '13.8800000000000000',
      close: '15.1457000000000000',
      volume: '3063415.2800000000000000',
      quoteVolume: 45713597.39123177,
      btcVolume: 6615.167109750736,
      usdVolume: 45713597.39123177,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '15.1466000000000000',
      high: '15.8900000000000000',
      low: '14.9382000000000000',
      close: '15.3564000000000000',
      volume: '1658330.7900000000000000',
      quoteVolume: 25501834.969267953,
      btcVolume: 3567.6079268195067,
      usdVolume: 25501834.969267953,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '15.3565000000000000',
      high: '16.2255000000000000',
      low: '14.5811000000000000',
      close: '16.0888000000000000',
      volume: '2405778.6200000000000000',
      quoteVolume: 37268429.1905796,
      btcVolume: 5141.099123684343,
      usdVolume: 37268429.1905796,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '16.0888000000000000',
      high: '16.1899000000000000',
      low: '15.4404000000000000',
      close: '15.5333000000000000',
      volume: '1619845.5800000000000000',
      quoteVolume: 25668886.932569165,
      btcVolume: 3417.447648852904,
      usdVolume: 25668886.932569165,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '15.5255000000000000',
      high: '16.2998000000000000',
      low: '15.4692000000000000',
      close: '16.2281000000000000',
      volume: '2034007.5200000000000000',
      quoteVolume: 32409441.241127864,
      btcVolume: 4248.276128127099,
      usdVolume: 32409441.241127864,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '16.2281000000000000',
      high: '16.2765000000000000',
      low: '15.4501000000000000',
      close: '15.7118000000000000',
      volume: '1435716.2100000000000000',
      quoteVolume: 22780843.578936126,
      btcVolume: 2979.5426085168438,
      usdVolume: 22780843.578936126,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '15.7030000000000000',
      high: '15.7139000000000000',
      low: '14.8000000000000000',
      close: '15.4297000000000000',
      volume: '1232843.2000000000000000',
      quoteVolume: 18823975.185732864,
      btcVolume: 2559.4840004958196,
      usdVolume: 18823975.185732864,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '15.4287000000000000',
      high: '15.6200000000000000',
      low: '15.0190000000000000',
      close: '15.1288000000000000',
      volume: '1205451.5000000000000000',
      quoteVolume: 18412700.208588973,
      btcVolume: 2519.5182703419514,
      usdVolume: 18412700.208588973,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '15.1394000000000000',
      high: '15.4255000000000000',
      low: '15.0475000000000000',
      close: '15.2542000000000000',
      volume: '1260422.8200000000000000',
      quoteVolume: 19241970.49272903,
      btcVolume: 2629.721760674202,
      usdVolume: 19241970.49272903,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '15.2545000000000000',
      high: '15.7511000000000000',
      low: '14.8275000000000000',
      close: '15.1000000000000000',
      volume: '1448353.5900000000000000',
      quoteVolume: 22125483.77934104,
      btcVolume: 3029.126283546053,
      usdVolume: 22125483.77934104,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '15.1254000000000000',
      high: '15.7200000000000000',
      low: '14.8060000000000000',
      close: '15.6594000000000000',
      volume: '1702989.3100000000000000',
      quoteVolume: 26185398.260484762,
      btcVolume: 3580.017154853113,
      usdVolume: 26185398.260484762,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '15.6554000000000000',
      high: '15.7498000000000000',
      low: '15.3000000000000000',
      close: '15.6666000000000000',
      volume: '1425210.7900000000000000',
      quoteVolume: 22138436.21785305,
      btcVolume: 2987.805804791008,
      usdVolume: 22138436.21785305,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '15.6570000000000000',
      high: '15.8199000000000000',
      low: '15.5190000000000000',
      close: '15.6067000000000000',
      volume: '1249702.2900000000000000',
      quoteVolume: 19570141.810453042,
      btcVolume: 2601.8723228509753,
      usdVolume: 19570141.810453042,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '15.6066000000000000',
      high: '15.7200000000000000',
      low: '15.3496000000000000',
      close: '15.6121000000000000',
      volume: '1097588.2900000000000000',
      quoteVolume: 17074933.996354897,
      btcVolume: 2281.4706372298097,
      usdVolume: 17074933.996354897,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '15.5947000000000000',
      high: '15.7990000000000000',
      low: '15.1838000000000000',
      close: '15.3284000000000000',
      volume: '952736.1300000000000000',
      quoteVolume: 14820153.790239785,
      btcVolume: 1984.031553870555,
      usdVolume: 14820153.790239785,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '15.3284000000000000',
      high: '15.3454000000000000',
      low: '14.6000000000000000',
      close: '14.8096000000000000',
      volume: '1001509.5100000000000000',
      quoteVolume: 15034745.741738938,
      btcVolume: 2055.8667526296367,
      usdVolume: 15034745.741738938,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '14.8085000000000000',
      high: '14.9984000000000000',
      low: '14.6000000000000000',
      close: '14.7800000000000000',
      volume: '803776.6500000000000000',
      quoteVolume: 11916428.093796976,
      btcVolume: 1650.5866045607136,
      usdVolume: 11916428.093796976,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '14.7800000000000000',
      high: '14.8495000000000000',
      low: '14.3500000000000000',
      close: '14.7238000000000000',
      volume: '740738.6700000000000000',
      quoteVolume: 10828113.391639946,
      btcVolume: 1508.9196509223818,
      usdVolume: 10828113.391639946,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '14.7165000000000000',
      high: '14.9775000000000000',
      low: '14.6600000000000000',
      close: '14.8555000000000000',
      volume: '883103.0900000000000000',
      quoteVolume: 13061294.686844045,
      btcVolume: 1805.6559930105461,
      usdVolume: 13061294.686844045,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '14.8555000000000000',
      high: '15.0000000000000000',
      low: '14.1629000000000000',
      close: '14.3888000000000000',
      volume: '841038.8200000000000000',
      quoteVolume: 12317989.087613093,
      btcVolume: 1717.5495142247462,
      usdVolume: 12317989.087613093,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '14.3887000000000000',
      high: '14.4930000000000000',
      low: '14.1361000000000000',
      close: '14.3949000000000000',
      volume: '786465.1500000000000000',
      quoteVolume: 11267335.87640899,
      btcVolume: 1588.1571392617413,
      usdVolume: 11267335.87640899,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '14.3946000000000000',
      high: '14.4356000000000000',
      low: '13.2580000000000000',
      close: '13.4544000000000000',
      volume: '1091047.0100000000000000',
      quoteVolume: 15235222.031920152,
      btcVolume: 2170.523777622476,
      usdVolume: 15235222.031920152,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '13.4721000000000000',
      high: '13.4750000000000000',
      low: '12.1111000000000000',
      close: '12.3810000000000000',
      volume: '1681588.6700000000000000',
      quoteVolume: 21579880.298549183,
      btcVolume: 3181.966603781795,
      usdVolume: 21579880.298549183,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '12.3837000000000000',
      high: '13.7348000000000000',
      low: '12.2100000000000000',
      close: '13.6158000000000000',
      volume: '2321743.1800000000000000',
      quoteVolume: 29939618.62193826,
      btcVolume: 4400.7711891586205,
      usdVolume: 29939618.62193826,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '13.6247000000000000',
      high: '13.7631000000000000',
      low: '12.9624000000000000',
      close: '13.3441000000000000',
      volume: '1378005.7000000000000000',
      quoteVolume: 18297729.627122004,
      btcVolume: 2555.9462004711754,
      usdVolume: 18297729.627122004,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '13.3519000000000000',
      high: '13.5291000000000000',
      low: '13.0800000000000000',
      close: '13.5000000000000000',
      volume: '1397599.9900000000000000',
      quoteVolume: 18629001.250787843,
      btcVolume: 2608.2124018163277,
      usdVolume: 18629001.250787843,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '13.4932000000000000',
      high: '13.5100000000000000',
      low: '13.2600000000000000',
      close: '13.2933000000000000',
      volume: '1260784.8500000000000000',
      quoteVolume: 16846058.069898106,
      btcVolume: 2357.3199262431112,
      usdVolume: 16846058.069898106,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '13.2874000000000000',
      high: '13.8309000000000000',
      low: '13.2689000000000000',
      close: '13.7798000000000000',
      volume: '1436306.2900000000000000',
      quoteVolume: 19378426.182353877,
      btcVolume: 2671.594975672879,
      usdVolume: 19378426.182353877,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '13.7768000000000000',
      high: '13.9447000000000000',
      low: '13.2000000000000000',
      close: '13.2998000000000000',
      volume: '1226252.0100000000000000',
      quoteVolume: 16842774.154331747,
      btcVolume: 2236.6478855031783,
      usdVolume: 16842774.154331747,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '13.2987000000000000',
      high: '13.5916000000000000',
      low: '13.2299000000000000',
      close: '13.3261000000000000',
      volume: '1306914.4800000000000000',
      quoteVolume: 17482280.272196006,
      btcVolume: 2396.4134118510615,
      usdVolume: 17482280.272196006,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '13.3340000000000000',
      high: '13.3522000000000000',
      low: '12.8500000000000000',
      close: '13.0666000000000000',
      volume: '923319.5400000000000000',
      quoteVolume: 12154298.537538983,
      btcVolume: 1682.9049427550863,
      usdVolume: 12154298.537538983,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '13.0665000000000000',
      high: '13.5466000000000000',
      low: '13.0230000000000000',
      close: '13.4952000000000000',
      volume: '1056537.7800000000000000',
      quoteVolume: 14002514.230783973,
      btcVolume: 1930.7821358732667,
      usdVolume: 14002514.230783973,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '13.1349000000000000',
      high: '13.2981000000000000',
      low: '12.8927000000000000',
      close: '13.2888000000000000',
      volume: '1109904.6900000000000000',
      quoteVolume: 14633137.803384082,
      btcVolume: 2029.5574032953941,
      usdVolume: 14633137.803384082,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '13.2888000000000000',
      high: '13.7200000000000000',
      low: '13.2569000000000000',
      close: '13.6779000000000000',
      volume: '1358080.6500000000000000',
      quoteVolume: 18404654.469618995,
      btcVolume: 2515.9847764068354,
      usdVolume: 18404654.469618995,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '13.6788000000000000',
      high: '14.2186000000000000',
      low: '13.5750000000000000',
      close: '14.1040000000000000',
      volume: '1608471.4600000000000000',
      quoteVolume: 22338596.36302602,
      btcVolume: 3032.599269002952,
      usdVolume: 22338596.36302602,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '14.0984000000000000',
      high: '14.3796000000000000',
      low: '13.7800000000000000',
      close: '13.8597000000000000',
      volume: '1255186.2700000000000000',
      quoteVolume: 17736371.93092798,
      btcVolume: 2418.5750608115254,
      usdVolume: 17736371.93092798,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '13.8597000000000000',
      high: '14.0564000000000000',
      low: '13.6400000000000000',
      close: '13.7161000000000000',
      volume: '970000.0200000000000000',
      quoteVolume: 13410409.01565197,
      btcVolume: 1853.0878040366188,
      usdVolume: 13410409.01565197,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '13.7159000000000000',
      high: '13.8692000000000000',
      low: '13.6500000000000000',
      close: '13.7184000000000000',
      volume: '940273.7700000000000000',
      quoteVolume: 12945796.344635054,
      btcVolume: 1793.7739920087274,
      usdVolume: 12945796.344635054,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '13.7072000000000000',
      high: '13.7302000000000000',
      low: '12.9800000000000000',
      close: '13.0105000000000000',
      volume: '960515.6000000000000000',
      quoteVolume: 12855590.844871968,
      btcVolume: 1810.6692341853307,
      usdVolume: 12855590.844871968,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '13.0104000000000000',
      high: '13.7500000000000000',
      low: '12.9801000000000000',
      close: '13.6474000000000000',
      volume: '1458213.2600000000000000',
      quoteVolume: 19697994.93713138,
      btcVolume: 2725.85101080842,
      usdVolume: 19697994.93713138,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '13.6481000000000000',
      high: '13.8710000000000000',
      low: '13.5102000000000000',
      close: '13.8092000000000000',
      volume: '1231268.3700000000000000',
      quoteVolume: 16885513.379274156,
      btcVolume: 2302.0692057035767,
      usdVolume: 16885513.379274156,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '13.7978000000000000',
      high: '14.3133000000000000',
      low: '13.7910000000000000',
      close: '14.0532000000000000',
      volume: '1458003.0200000000000000',
      quoteVolume: 20531817.041770168,
      btcVolume: 2760.741171838204,
      usdVolume: 20531817.041770168,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '14.0488000000000000',
      high: '14.9873000000000000',
      low: '14.0168000000000000',
      close: '14.9471000000000000',
      volume: '1633712.0400000000000000',
      quoteVolume: 23743495.229042225,
      btcVolume: 3144.9834827722107,
      usdVolume: 23743495.229042225,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '14.9427000000000000',
      high: '15.1870000000000000',
      low: '14.4560000000000000',
      close: '15.0226000000000000',
      volume: '1458769.7000000000000000',
      quoteVolume: 21730397.146320045,
      btcVolume: 2741.5005004584696,
      usdVolume: 21730397.146320045,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '15.0212000000000000',
      high: '15.3200000000000000',
      low: '14.2000000000000000',
      close: '14.5254000000000000',
      volume: '1444209.0300000000000000',
      quoteVolume: 21394481.601980902,
      btcVolume: 2593.189647193552,
      usdVolume: 21394481.601980902,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '14.5308000000000000',
      high: '14.5823000000000000',
      low: '14.2010000000000000',
      close: '14.3978000000000000',
      volume: '1052935.6900000000000000',
      quoteVolume: 15196486.221940989,
      btcVolume: 1918.9735468286913,
      usdVolume: 15196486.221940989,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '14.3806000000000000',
      high: '15.0973000000000000',
      low: '14.1100000000000000',
      close: '15.0700000000000000',
      volume: '1572079.7800000000000000',
      quoteVolume: 23078267.45961109,
      btcVolume: 2897.5210314858323,
      usdVolume: 23078267.45961109,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '15.0643000000000000',
      high: '15.3200000000000000',
      low: '14.7111000000000000',
      close: '14.9177000000000000',
      volume: '1397398.3000000000000000',
      quoteVolume: 20959042.697335042,
      btcVolume: 2577.9974693735912,
      usdVolume: 20959042.697335042,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '14.9137000000000000',
      high: '15.4707000000000000',
      low: '14.8001000000000000',
      close: '15.3366000000000000',
      volume: '2042556.1700000000000000',
      quoteVolume: 31106160.735202186,
      btcVolume: 3830.745845979114,
      usdVolume: 31106160.735202186,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '15.3370000000000000',
      high: '15.3572000000000000',
      low: '14.9295000000000000',
      close: '15.1736000000000000',
      volume: '1520954.5900000000000000',
      quoteVolume: 22965408.692243036,
      btcVolume: 2830.7449498311053,
      usdVolume: 22965408.692243036,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '15.1735000000000000',
      high: '16.9100000000000000',
      low: '15.1659000000000000',
      close: '16.6371000000000000',
      volume: '2711583.2200000000000000',
      quoteVolume: 43167140.88381576,
      btcVolume: 5029.673767491132,
      usdVolume: 43167140.88381576,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '16.6301000000000000',
      high: '18.1900000000000000',
      low: '16.2700000000000000',
      close: '17.6655000000000000',
      volume: '3040382.6900000000000000',
      quoteVolume: 52587124.739194795,
      btcVolume: 5996.84247707859,
      usdVolume: 52587124.739194795,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '17.6655000000000000',
      high: '17.6923000000000000',
      low: '16.3100000000000000',
      close: '17.0333000000000000',
      volume: '1842017.2900000000000000',
      quoteVolume: 31029984.91111983,
      btcVolume: 3570.093648897622,
      usdVolume: 31029984.91111983,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '17.0400000000000000',
      high: '18.4849000000000000',
      low: '17.0400000000000000',
      close: '18.0724000000000000',
      volume: '2734425.7700000000000000',
      quoteVolume: 48859738.857078,
      btcVolume: 5520.430319295519,
      usdVolume: 48859738.857078,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '18.0763000000000000',
      high: '18.3602000000000000',
      low: '17.4500000000000000',
      close: '17.8088000000000000',
      volume: '1903073.6300000000000000',
      quoteVolume: 34104864.132670745,
      btcVolume: 3831.157825228501,
      usdVolume: 34104864.132670745,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '17.7954000000000000',
      high: '18.4950000000000000',
      low: '16.6666000000000000',
      close: '17.3880000000000000',
      volume: '2127422.8500000000000000',
      quoteVolume: 37440202.05314928,
      btcVolume: 4223.113876587941,
      usdVolume: 37440202.05314928,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '17.3706000000000000',
      high: '17.5516000000000000',
      low: '16.8043000000000000',
      close: '17.3235000000000000',
      volume: '1576499.0800000000000000',
      quoteVolume: 27136151.86145567,
      btcVolume: 3135.1916814614,
      usdVolume: 27136151.86145567,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '17.3265000000000000',
      high: '18.2000000000000000',
      low: '17.2228000000000000',
      close: '18.1166000000000000',
      volume: '1923914.5000000000000000',
      quoteVolume: 33973360.84587211,
      btcVolume: 3922.624684198108,
      usdVolume: 33973360.84587211,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '18.1107000000000000',
      high: '18.4000000000000000',
      low: '17.5500000000000000',
      close: '17.8344000000000000',
      volume: '1607749.7800000000000000',
      quoteVolume: 28997476.09421225,
      btcVolume: 3333.354464634478,
      usdVolume: 28997476.09421225,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '17.8344000000000000',
      high: '17.8344000000000000',
      low: '16.7441000000000000',
      close: '17.0524000000000000',
      volume: '1363632.7900000000000000',
      quoteVolume: 23479710.346752085,
      btcVolume: 2763.2312364747722,
      usdVolume: 23479710.346752085,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '17.0813000000000000',
      high: '17.4429000000000000',
      low: '16.4288000000000000',
      close: '17.1148000000000000',
      volume: '1411501.9800000000000000',
      quoteVolume: 23870035.10373168,
      btcVolume: 2846.8695971312045,
      usdVolume: 23870035.10373168,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '17.1133000000000000',
      high: '17.1215000000000000',
      low: '16.5685000000000000',
      close: '16.9555000000000000',
      volume: '1211416.3200000000000000',
      quoteVolume: 20396383.54308985,
      btcVolume: 2446.212569813146,
      usdVolume: 20396383.54308985,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '16.9419000000000000',
      high: '17.4990000000000000',
      low: '16.8315000000000000',
      close: '17.4990000000000000',
      volume: '1619344.7500000000000000',
      quoteVolume: 27866656.71784081,
      btcVolume: 3293.769502538616,
      usdVolume: 27866656.71784081,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '17.4919000000000000',
      high: '17.9500000000000000',
      low: '17.2198000000000000',
      close: '17.6700000000000000',
      volume: '1562998.8400000000000000',
      quoteVolume: 27448591.22210781,
      btcVolume: 3140.819387866112,
      usdVolume: 27448591.22210781,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '17.6700000000000000',
      high: '18.1600000000000000',
      low: '17.4466000000000000',
      close: '18.0662000000000000',
      volume: '1916526.0700000000000000',
      quoteVolume: 34097546.051095806,
      btcVolume: 3754.0928695235057,
      usdVolume: 34097546.051095806,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '18.0614000000000000',
      high: '18.4437000000000000',
      low: '17.8090000000000000',
      close: '17.9134000000000000',
      volume: '1447265.2000000000000000',
      quoteVolume: 26231426.254242063,
      btcVolume: 2807.684884866969,
      usdVolume: 26231426.254242063,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '17.9296000000000000',
      high: '18.7027000000000000',
      low: '17.7000000000000000',
      close: '18.5555000000000000',
      volume: '1787521.2200000000000000',
      quoteVolume: 32487717.892014977,
      btcVolume: 3454.6241320377576,
      usdVolume: 32487717.892014977,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '18.5557000000000000',
      high: '18.8800000000000000',
      low: '17.8000000000000000',
      close: '18.2644000000000000',
      volume: '1647399.0800000000000000',
      quoteVolume: 30233383.52456968,
      btcVolume: 3224.3985404614446,
      usdVolume: 30233383.52456968,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '18.2644000000000000',
      high: '18.5956000000000000',
      low: '18.0300000000000000',
      close: '18.2752000000000000',
      volume: '1511588.8600000000000000',
      quoteVolume: 27611547.000338193,
      btcVolume: 2942.137716907503,
      usdVolume: 27611547.000338193,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '18.2753000000000000',
      high: '18.7714000000000000',
      low: '17.8277000000000000',
      close: '18.4333000000000000',
      volume: '1558716.5200000000000000',
      quoteVolume: 28652209.626673575,
      btcVolume: 3053.4044339388865,
      usdVolume: 28652209.626673575,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '18.4333000000000000',
      high: '18.8763000000000000',
      low: '18.1959000000000000',
      close: '18.4600000000000000',
      volume: '1314693.1200000000000000',
      quoteVolume: 24235415.42380266,
      btcVolume: 2588.7478758068564,
      usdVolume: 24235415.42380266,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '18.4610000000000000',
      high: '18.6158000000000000',
      low: '17.8700000000000000',
      close: '18.1778000000000000',
      volume: '1215556.3700000000000000',
      quoteVolume: 22148996.309246045,
      btcVolume: 2399.6608902337066,
      usdVolume: 22148996.309246045,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '18.1778000000000000',
      high: '19.4583000000000000',
      low: '18.1635000000000000',
      close: '19.2620000000000000',
      volume: '2068915.0400000000000000',
      quoteVolume: 39153896.76314988,
      btcVolume: 4174.003818806791,
      usdVolume: 39153896.76314988,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '19.2686000000000000',
      high: '20.8400000000000000',
      low: '19.0300000000000000',
      close: '20.6675000000000000',
      volume: '2770774.3100000000000000',
      quoteVolume: 55407991.63401992,
      btcVolume: 5699.561189798247,
      usdVolume: 55407991.63401992,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '20.6665000000000000',
      high: '22.5221000000000000',
      low: '20.5199000000000000',
      close: '22.0555000000000000',
      volume: '3182382.8900000000000000',
      quoteVolume: 68795504.22441779,
      btcVolume: 7022.0759772449155,
      usdVolume: 68795504.22441779,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '22.0360000000000000',
      high: '22.1900000000000000',
      low: '20.8943000000000000',
      close: '21.7888000000000000',
      volume: '2208642.4000000000000000',
      quoteVolume: 47849193.68056081,
      btcVolume: 4879.389335821138,
      usdVolume: 47849193.68056081,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '21.7871000000000000',
      high: '24.3814000000000000',
      low: '21.7746000000000000',
      close: '24.3800000000000000',
      volume: '2846806.8900000000000000',
      quoteVolume: 66056350.15274652,
      btcVolume: 6555.827857878055,
      usdVolume: 66056350.15274652,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '24.3517000000000000',
      high: '25.7935000000000000',
      low: '23.3000000000000000',
      close: '25.1465000000000000',
      volume: '4794544.7200000000000000',
      quoteVolume: 118356199.03064102,
      btcVolume: 11937.05365463192,
      usdVolume: 118356199.03064102,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '25.1450000000000000',
      high: '25.7400000000000000',
      low: '24.1202000000000000',
      close: '25.6963000000000000',
      volume: '3512538.3800000000000000',
      quoteVolume: 87532758.12909538,
      btcVolume: 8788.633029031116,
      usdVolume: 87532758.12909538,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '25.6728000000000000',
      high: '26.9196000000000000',
      low: '25.3402000000000000',
      close: '26.4243000000000000',
      volume: '2954235.4800000000000000',
      quoteVolume: 76168100.46527852,
      btcVolume: 7380.550113864897,
      usdVolume: 76168100.46527852,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '26.4136000000000000',
      high: '27.1905000000000000',
      low: '24.5995000000000000',
      close: '25.4579000000000000',
      volume: '3591601.3200000000000000',
      quoteVolume: 92634936.37990527,
      btcVolume: 9002.249103389786,
      usdVolume: 92634936.37990527,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '25.4621000000000000',
      high: '26.5130000000000000',
      low: '25.0304000000000000',
      close: '26.3547000000000000',
      volume: '3252594.3200000000000000',
      quoteVolume: 84109977.24348529,
      btcVolume: 8191.832934805558,
      usdVolume: 84109977.24348529,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '26.3662000000000000',
      high: '26.4931000000000000',
      low: '23.5288000000000000',
      close: '24.2576000000000000',
      volume: '3355108.1400000000000000',
      quoteVolume: 85265826.4624216,
      btcVolume: 8426.730139384954,
      usdVolume: 85265826.4624216,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '24.2388000000000000',
      high: '25.2485000000000000',
      low: '21.8000000000000000',
      close: '23.3193000000000000',
      volume: '3752723.6900000000000000',
      quoteVolume: 89187179.91969427,
      btcVolume: 9019.615804441175,
      usdVolume: 89187179.91969427,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '23.3193000000000000',
      high: '23.6000000000000000',
      low: '21.5510000000000000',
      close: '23.4555000000000000',
      volume: '3208991.9600000000000000',
      quoteVolume: 72372207.9082924,
      btcVolume: 7461.240037311607,
      usdVolume: 72372207.9082924,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '23.4558000000000000',
      high: '24.4588000000000000',
      low: '22.8013000000000000',
      close: '24.0821000000000000',
      volume: '3010749.1700000000000000',
      quoteVolume: 71169442.58374034,
      btcVolume: 7232.1388082425665,
      usdVolume: 71169442.58374034,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '24.0611000000000000',
      high: '24.3264000000000000',
      low: '21.8000000000000000',
      close: '22.3709000000000000',
      volume: '2140879.9700000000000000',
      quoteVolume: 50475552.67366864,
      btcVolume: 5035.254917997106,
      usdVolume: 50475552.67366864,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '22.3702000000000000',
      high: '22.6741000000000000',
      low: '21.0000000000000000',
      close: '22.1626000000000000',
      volume: '2366725.1400000000000000',
      quoteVolume: 51918299.17105383,
      btcVolume: 5418.649900876407,
      usdVolume: 51918299.17105383,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '22.1617000000000000',
      high: '22.8639000000000000',
      low: '21.7677000000000000',
      close: '22.1888000000000000',
      volume: '2987444.2400000000000000',
      quoteVolume: 66404440.860843465,
      btcVolume: 6861.125072216571,
      usdVolume: 66404440.860843465,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '22.1997000000000000',
      high: '22.3500000000000000',
      low: '21.5000000000000000',
      close: '21.9689000000000000',
      volume: '2139282.2200000000000000',
      quoteVolume: 46985151.28201848,
      btcVolume: 4870.228412924825,
      usdVolume: 46985151.28201848,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '21.9458000000000000',
      high: '22.9800000000000000',
      low: '21.9275000000000000',
      close: '22.8452000000000000',
      volume: '3052036.9600000000000000',
      quoteVolume: 69055523.55500332,
      btcVolume: 7015.549581370111,
      usdVolume: 69055523.55500332,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '22.8267000000000000',
      high: '23.2550000000000000',
      low: '21.4844000000000000',
      close: '22.0965000000000000',
      volume: '2294898.3500000000000000',
      quoteVolume: 51480520.2332999,
      btcVolume: 5282.9140573604745,
      usdVolume: 51480520.2332999,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '22.0965000000000000',
      high: '22.0967000000000000',
      low: '20.0711000000000000',
      close: '20.3193000000000000',
      volume: '2335580.1100000000000000',
      quoteVolume: 49059568.007921465,
      btcVolume: 5169.869833706369,
      usdVolume: 49059568.007921465,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '20.3316000000000000',
      high: '20.5191000000000000',
      low: '17.9000000000000000',
      close: '18.7441000000000000',
      volume: '3753737.4600000000000000',
      quoteVolume: 71546982.18924734,
      btcVolume: 7905.138057480794,
      usdVolume: 71546982.18924734,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '18.7338000000000000',
      high: '19.6499000000000000',
      low: '17.7051000000000000',
      close: '19.2000000000000000',
      volume: '3016651.2500000000000000',
      quoteVolume: 56860249.75728752,
      btcVolume: 6470.8292928242745,
      usdVolume: 56860249.75728752,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '19.1889000000000000',
      high: '19.7551000000000000',
      low: '17.9053000000000000',
      close: '18.9838000000000000',
      volume: '3099999.9300000000000000',
      quoteVolume: 58487714.17780671,
      btcVolume: 6714.188632991106,
      usdVolume: 58487714.17780671,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '18.9838000000000000',
      high: '20.4000000000000000',
      low: '18.8000000000000000',
      close: '19.2300000000000000',
      volume: '3567334.5900000000000000',
      quoteVolume: 69257747.27603036,
      btcVolume: 7987.256453347228,
      usdVolume: 69257747.27603036,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '19.2481000000000000',
      high: '19.8994000000000000',
      low: '18.4880000000000000',
      close: '18.9020000000000000',
      volume: '2382184.7800000000000000',
      quoteVolume: 45970226.909694515,
      btcVolume: 5363.65102030691,
      usdVolume: 45970226.909694515,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '18.9183000000000000',
      high: '20.0722000000000000',
      low: '18.7583000000000000',
      close: '19.9289000000000000',
      volume: '3267729.0000000000000000',
      quoteVolume: 64060497.85221986,
      btcVolume: 7310.647723090957,
      usdVolume: 64060497.85221986,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '19.9297000000000000',
      high: '20.3865000000000000',
      low: '19.3156000000000000',
      close: '19.6882000000000000',
      volume: '2545825.0000000000000000',
      quoteVolume: 50338561.07374935,
      btcVolume: 5718.875108291753,
      usdVolume: 50338561.07374935,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '19.6707000000000000',
      high: '20.2847000000000000',
      low: '19.5131000000000000',
      close: '19.9500000000000000',
      volume: '2408511.9400000000000000',
      quoteVolume: 47942820.95931419,
      btcVolume: 5467.07577696825,
      usdVolume: 47942820.95931419,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '19.9500000000000000',
      high: '21.4585000000000000',
      low: '19.9401000000000000',
      close: '20.7462000000000000',
      volume: '3088988.6500000000000000',
      quoteVolume: 64384864.18373439,
      btcVolume: 7154.825193230421,
      usdVolume: 64384864.18373439,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '20.7463000000000000',
      high: '21.7628000000000000',
      low: '20.6303000000000000',
      close: '21.3389000000000000',
      volume: '3228771.5100000000000000',
      quoteVolume: 68446184.90637933,
      btcVolume: 7534.967221418779,
      usdVolume: 68446184.90637933,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '21.3389000000000000',
      high: '21.4900000000000000',
      low: '19.7183000000000000',
      close: '20.2500000000000000',
      volume: '2378944.8500000000000000',
      quoteVolume: 49387277.98027311,
      btcVolume: 5463.183437439565,
      usdVolume: 49387277.98027311,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '20.2500000000000000',
      high: '20.2612000000000000',
      low: '16.7315000000000000',
      close: '16.8940000000000000',
      volume: '3465550.2500000000000000',
      quoteVolume: 64439955.25070982,
      btcVolume: 7591.149351999756,
      usdVolume: 64439955.25070982,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '16.8939000000000000',
      high: '17.4775000000000000',
      low: '15.6168000000000000',
      close: '16.5888000000000000',
      volume: '4046754.1400000000000000',
      quoteVolume: 67231659.84751555,
      btcVolume: 8549.570747626509,
      usdVolume: 67231659.84751555,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '16.5934000000000000',
      high: '17.4475000000000000',
      low: '16.3107000000000000',
      close: '16.8671000000000000',
      volume: '3449744.5700000000000000',
      quoteVolume: 58330699.05467022,
      btcVolume: 7320.410900960158,
      usdVolume: 58330699.05467022,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '16.8530000000000000',
      high: '17.1388000000000000',
      low: '15.4100000000000000',
      close: '16.5705000000000000',
      volume: '2581538.7700000000000000',
      quoteVolume: 42412598.42866471,
      btcVolume: 5417.646463351474,
      usdVolume: 42412598.42866471,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '16.5698000000000000',
      high: '16.6000000000000000',
      low: '8.8900000000000000',
      close: '9.2566000000000000',
      volume: '7775320.7400000000000000',
      quoteVolume: 96587818.94082893,
      btcVolume: 15229.295961519643,
      usdVolume: 96587818.94082893,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '9.2565000000000000',
      high: '11.5572000000000000',
      low: '6.3800000000000000',
      close: '10.9204000000000000',
      volume: '10695887.9800000000000000',
      quoteVolume: 101790362.66277254,
      btcVolume: 19963.23178862742,
      usdVolume: 101790362.66277254,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '10.9224000000000000',
      high: '11.2886000000000000',
      low: '9.8652000000000000',
      close: '10.1487000000000000',
      volume: '5343507.5700000000000000',
      quoteVolume: 56907072.065318905,
      btcVolume: 10586.292565466663,
      usdVolume: 56907072.065318905,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '10.1475000000000000',
      high: '11.2644000000000000',
      low: '9.9768000000000000',
      close: '10.3898000000000000',
      volume: '6424065.3600000000000000',
      quoteVolume: 67251583.01660968,
      btcVolume: 12552.17335408042,
      usdVolume: 67251583.01660968,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '10.3808000000000000',
      high: '10.3952000000000000',
      low: '8.4422000000000000',
      close: '9.4285000000000000',
      volume: '5814301.1700000000000000',
      quoteVolume: 54897967.26809575,
      btcVolume: 11073.417029929627,
      usdVolume: 54897967.26809575,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '9.4290000000000000',
      high: '10.4900000000000000',
      low: '9.3265000000000000',
      close: '10.2576000000000000',
      volume: '5102840.4500000000000000',
      quoteVolume: 51631527.65429831,
      btcVolume: 9759.11417103753,
      usdVolume: 51631527.65429831,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '10.2498000000000000',
      high: '10.4301000000000000',
      low: '9.6539000000000000',
      close: '10.3866000000000000',
      volume: '4450666.1500000000000000',
      quoteVolume: 45276532.730975404,
      btcVolume: 8574.510856799883,
      usdVolume: 45276532.730975404,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '10.3917000000000000',
      high: '12.6891000000000000',
      low: '10.2260000000000000',
      close: '12.2597000000000000',
      volume: '5848299.4600000000000000',
      quoteVolume: 66264533.82355488,
      btcVolume: 11416.165972116107,
      usdVolume: 66264533.82355488,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '12.2597000000000000',
      high: '13.6500000000000000',
      low: '10.4014000000000000',
      close: '11.9147000000000000',
      volume: '6965457.9200000000000000',
      quoteVolume: 86260327.52522887,
      btcVolume: 13530.08677890309,
      usdVolume: 86260327.52522887,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '11.8967000000000000',
      high: '12.4199000000000000',
      low: '11.2500000000000000',
      close: '12.1000000000000000',
      volume: '5810720.8800000000000000',
      quoteVolume: 69365406.12530024,
      btcVolume: 11212.692133104454,
      usdVolume: 69365406.12530024,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '12.1050000000000000',
      high: '12.4754000000000000',
      low: '11.0145000000000000',
      close: '11.1670000000000000',
      volume: '4562736.8600000000000000',
      quoteVolume: 54219558.920412235,
      btcVolume: 8829.651908483296,
      usdVolume: 54219558.920412235,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '11.1494000000000000',
      high: '12.4900000000000000',
      low: '10.8427000000000000',
      close: '12.1887000000000000',
      volume: '5299899.4500000000000000',
      quoteVolume: 61800844.96620327,
      btcVolume: 10040.54539065147,
      usdVolume: 61800844.96620327,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '12.1887000000000000',
      high: '12.5834000000000000',
      low: '11.9100000000000000',
      close: '12.4260000000000000',
      volume: '4627182.2900000000000000',
      quoteVolume: 56790493.548444174,
      btcVolume: 8555.766772115445,
      usdVolume: 56790493.548444174,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '12.4261000000000000',
      high: '12.7108000000000000',
      low: '12.0561000000000000',
      close: '12.3251000000000000',
      volume: '3607793.8900000000000000',
      quoteVolume: 44491441.70805428,
      btcVolume: 6675.4778502505305,
      usdVolume: 44491441.70805428,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '12.3253000000000000',
      high: '13.2565000000000000',
      low: '12.1900000000000000',
      close: '13.1990000000000000',
      volume: '3433997.0900000000000000',
      quoteVolume: 42838269.466780595,
      btcVolume: 6422.748334392865,
      usdVolume: 42838269.466780595,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '13.1989000000000000',
      high: '13.3655000000000000',
      low: '12.1600000000000000',
      close: '12.3354000000000000',
      volume: '2778050.9100000000000000',
      quoteVolume: 36084938.85881204,
      btcVolume: 5400.701483352602,
      usdVolume: 36084938.85881204,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '12.3375000000000000',
      high: '12.3588000000000000',
      low: '11.6414000000000000',
      close: '12.1888000000000000',
      volume: '2478685.1100000000000000',
      quoteVolume: 30031382.50817986,
      btcVolume: 4830.592276464181,
      usdVolume: 30031382.50817986,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '12.1888000000000000',
      high: '12.2425000000000000',
      low: '11.2750000000000000',
      close: '11.3008000000000000',
      volume: '2305768.5200000000000000',
      quoteVolume: 27252838.148580186,
      btcVolume: 4463.8054330588775,
      usdVolume: 27252838.148580186,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '11.3007000000000000',
      high: '12.4277000000000000',
      low: '11.2552000000000000',
      close: '12.1365000000000000',
      volume: '4183148.7200000000000000',
      quoteVolume: 50273122.37520111,
      btcVolume: 7996.7455340660035,
      usdVolume: 50273122.37520111,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '12.1368000000000000',
      high: '12.7322000000000000',
      low: '12.1180000000000000',
      close: '12.5451000000000000',
      volume: '5119093.1500000000000000',
      quoteVolume: 64105779.65224831,
      btcVolume: 9947.257238601136,
      usdVolume: 64105779.65224831,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '12.5497000000000000',
      high: '12.7386000000000000',
      low: '11.9600000000000000',
      close: '12.7200000000000000',
      volume: '4355655.4600000000000000',
      quoteVolume: 53721353.74091241,
      btcVolume: 8489.017350493368,
      usdVolume: 53721353.74091241,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '12.7190000000000000',
      high: '13.7362000000000000',
      low: '12.5349000000000000',
      close: '13.0166000000000000',
      volume: '5332604.5300000000000000',
      quoteVolume: 69276074.10863224,
      btcVolume: 10253.55586604115,
      usdVolume: 69276074.10863224,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '13.0185000000000000',
      high: '13.5700000000000000',
      low: '12.9106000000000000',
      close: '13.3615000000000000',
      volume: '5030322.0300000000000000',
      quoteVolume: 66661802.96690086,
      btcVolume: 9801.523808937845,
      usdVolume: 66661802.96690086,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '13.3682000000000000',
      high: '14.0561000000000000',
      low: '13.1724000000000000',
      close: '13.7606000000000000',
      volume: '4840528.5300000000000000',
      quoteVolume: 66003739.59200456,
      btcVolume: 9745.152195828143,
      usdVolume: 66003739.59200456,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '13.7608000000000000',
      high: '13.9638000000000000',
      low: '13.4002000000000000',
      close: '13.6888000000000000',
      volume: '4275639.2500000000000000',
      quoteVolume: 58555104.63058687,
      btcVolume: 8621.568347482043,
      usdVolume: 58555104.63058687,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '13.6888000000000000',
      high: '15.2149000000000000',
      low: '13.6371000000000000',
      close: '15.1989000000000000',
      volume: '5583296.7600000000000000',
      quoteVolume: 80214973.59908952,
      btcVolume: 11301.364287830236,
      usdVolume: 80214973.59908952,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '15.1930000000000000',
      high: '15.4900000000000000',
      low: '14.2503000000000000',
      close: '14.7266000000000000',
      volume: '4715229.2800000000000000',
      quoteVolume: 70591252.82052863,
      btcVolume: 9647.541530712178,
      usdVolume: 70591252.82052863,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '14.7267000000000000',
      high: '15.3486000000000000',
      low: '14.5700000000000000',
      close: '15.0888000000000000',
      volume: '4614905.6300000000000000',
      quoteVolume: 69355343.21978821,
      btcVolume: 9493.717090075794,
      usdVolume: 69355343.21978821,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '15.0899000000000000',
      high: '15.2011000000000000',
      low: '14.6279000000000000',
      close: '15.0096000000000000',
      volume: '4638418.3900000000000000',
      quoteVolume: 69662383.47849114,
      btcVolume: 9554.251740198943,
      usdVolume: 69662383.47849114,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '15.0096000000000000',
      high: '15.0797000000000000',
      low: '13.4700000000000000',
      close: '13.7200000000000000',
      volume: '3999879.1400000000000000',
      quoteVolume: 56437561.49783074,
      btcVolume: 8088.757467476043,
      usdVolume: 56437561.49783074,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '13.7171000000000000',
      high: '14.0808000000000000',
      low: '13.5234000000000000',
      close: '13.8070000000000000',
      volume: '3692817.9200000000000000',
      quoteVolume: 51151554.74794237,
      btcVolume: 7454.864937758831,
      usdVolume: 51151554.74794237,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '13.8089000000000000',
      high: '14.8399000000000000',
      low: '13.6439000000000000',
      close: '14.2808000000000000',
      volume: '4070517.8200000000000000',
      quoteVolume: 57950623.216416314,
      btcVolume: 8337.283577200431,
      usdVolume: 57950623.216416314,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '14.2808000000000000',
      high: '15.0799000000000000',
      low: '13.6289000000000000',
      close: '15.0277000000000000',
      volume: '4313190.9300000000000000',
      quoteVolume: 62029135.8484717,
      btcVolume: 9209.291923149041,
      usdVolume: 62029135.8484717,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '15.0257000000000000',
      high: '15.9555000000000000',
      low: '14.7835000000000000',
      close: '15.6148000000000000',
      volume: '4523071.2000000000000000',
      quoteVolume: 70092382.14295062,
      btcVolume: 10207.861589084292,
      usdVolume: 70092382.14295062,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '15.6148000000000000',
      high: '15.8888000000000000',
      low: '14.4913000000000000',
      close: '14.5755000000000000',
      volume: '3795966.9000000000000000',
      quoteVolume: 58221065.7500413,
      btcVolume: 8555.74483779464,
      usdVolume: 58221065.7500413,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '14.5755000000000000',
      high: '15.7669000000000000',
      low: '14.1160000000000000',
      close: '15.7394000000000000',
      volume: '4068049.4800000000000000',
      quoteVolume: 61814683.27456321,
      btcVolume: 8977.839996691559,
      usdVolume: 61814683.27456321,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '15.7394000000000000',
      high: '15.9200000000000000',
      low: '15.2501000000000000',
      close: '15.6400000000000000',
      volume: '3150559.1700000000000000',
      quoteVolume: 49188415.57936379,
      btcVolume: 6962.024816102106,
      usdVolume: 49188415.57936379,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '15.6447000000000000',
      high: '16.8183000000000000',
      low: '15.6242000000000000',
      close: '16.6137000000000000',
      volume: '3968019.0700000000000000',
      quoteVolume: 64502814.66373515,
      btcVolume: 8996.773890457556,
      usdVolume: 64502814.66373515,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '16.6198000000000000',
      high: '16.6933000000000000',
      low: '15.8509000000000000',
      close: '16.0683000000000000',
      volume: '2962072.6000000000000000',
      quoteVolume: 48174317.52943487,
      btcVolume: 6722.896522589055,
      usdVolume: 48174317.52943487,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '16.0677000000000000',
      high: '16.4499000000000000',
      low: '14.8219000000000000',
      close: '14.9892000000000000',
      volume: '3219905.9000000000000000',
      quoteVolume: 50757347.771237776,
      btcVolume: 7228.98020818563,
      usdVolume: 50757347.771237776,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '14.9840000000000000',
      high: '15.4416000000000000',
      low: '14.6945000000000000',
      close: '15.1283000000000000',
      volume: '3134797.6600000000000000',
      quoteVolume: 47551099.75450032,
      btcVolume: 6932.414273166686,
      usdVolume: 47551099.75450032,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '15.1381000000000000',
      high: '15.8030000000000000',
      low: '14.9112000000000000',
      close: '15.7104000000000000',
      volume: '3293822.4400000000000000',
      quoteVolume: 50953432.53308494,
      btcVolume: 7270.805302216778,
      usdVolume: 50953432.53308494,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '15.7103000000000000',
      high: '16.4666000000000000',
      low: '15.4102000000000000',
      close: '16.0340000000000000',
      volume: '3451154.7600000000000000',
      quoteVolume: 54969135.67182449,
      btcVolume: 7506.714246229673,
      usdVolume: 54969135.67182449,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '16.0502000000000000',
      high: '16.2568000000000000',
      low: '15.6800000000000000',
      close: '15.9319000000000000',
      volume: '3008320.2600000000000000',
      quoteVolume: 48259042.136493415,
      btcVolume: 6423.189422170581,
      usdVolume: 48259042.136493415,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '15.9319000000000000',
      high: '16.4305000000000000',
      low: '15.7761000000000000',
      close: '16.2283000000000000',
      volume: '2946839.7900000000000000',
      quoteVolume: 47639855.83746818,
      btcVolume: 6314.8054320104275,
      usdVolume: 47639855.83746818,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '16.2235000000000000',
      high: '16.4435000000000000',
      low: '16.0400000000000000',
      close: '16.3588000000000000',
      volume: '3314818.9100000000000000',
      quoteVolume: 53903371.49447087,
      btcVolume: 7094.218778997715,
      usdVolume: 53903371.49447087,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '16.3645000000000000',
      high: '16.5600000000000000',
      low: '16.0630000000000000',
      close: '16.4229000000000000',
      volume: '2937287.3600000000000000',
      quoteVolume: 47859272.721880525,
      btcVolume: 6212.335727211785,
      usdVolume: 47859272.721880525,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '16.4199000000000000',
      high: '16.4900000000000000',
      low: '16.1125000000000000',
      close: '16.3400000000000000',
      volume: '2838511.4300000000000000',
      quoteVolume: 46309248.7211524,
      btcVolume: 5999.145090290307,
      usdVolume: 46309248.7211524,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '16.3354000000000000',
      high: '17.5171000000000000',
      low: '16.3000000000000000',
      close: '17.3667000000000000',
      volume: '3817656.7000000000000000',
      quoteVolume: 64434759.7688937,
      btcVolume: 7799.88414199083,
      usdVolume: 64434759.7688937,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '17.3754000000000000',
      high: '18.1377000000000000',
      low: '16.5000000000000000',
      close: '16.9542000000000000',
      volume: '4127322.5100000000000000',
      quoteVolume: 71629569.6960837,
      btcVolume: 8039.289696160252,
      usdVolume: 71629569.6960837,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '16.9510000000000000',
      high: '17.8551000000000000',
      low: '16.9381000000000000',
      close: '17.4660000000000000',
      volume: '3805293.6700000000000000',
      quoteVolume: 66271797.65506503,
      btcVolume: 7557.207038844103,
      usdVolume: 66271797.65506503,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '17.4665000000000000',
      high: '17.5711000000000000',
      low: '17.2599000000000000',
      close: '17.5333000000000000',
      volume: '3009939.6700000000000000',
      quoteVolume: 52504866.732339546,
      btcVolume: 5915.815664352295,
      usdVolume: 52504866.732339546,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '17.5337000000000000',
      high: '17.8100000000000000',
      low: '16.8711000000000000',
      close: '17.1071000000000000',
      volume: '2616299.5300000000000000',
      quoteVolume: 45271776.505038545,
      btcVolume: 5050.819962883537,
      usdVolume: 45271776.505038545,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '17.1027000000000000',
      high: '17.3267000000000000',
      low: '16.2556000000000000',
      close: '17.0074000000000000',
      volume: '2755883.6300000000000000',
      quoteVolume: 46419270.57261121,
      btcVolume: 5284.661382519424,
      usdVolume: 46419270.57261121,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '17.0120000000000000',
      high: '17.2993000000000000',
      low: '16.6060000000000000',
      close: '16.8886000000000000',
      volume: '2613361.6500000000000000',
      quoteVolume: 44261093.04238469,
      btcVolume: 4957.354183210489,
      usdVolume: 44261093.04238469,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '16.8814000000000000',
      high: '17.1450000000000000',
      low: '16.3018000000000000',
      close: '16.3743000000000000',
      volume: '2716280.3700000000000000',
      quoteVolume: 45806861.8974411,
      btcVolume: 5004.187193369502,
      usdVolume: 45806861.8974411,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '16.3672000000000000',
      high: '17.2400000000000000',
      low: '16.1218000000000000',
      close: '17.0003000000000000',
      volume: '3030451.5100000000000000',
      quoteVolume: 50623360.84297903,
      btcVolume: 5321.812894633818,
      usdVolume: 50623360.84297903,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '17.0003000000000000',
      high: '17.3977000000000000',
      low: '16.8230000000000000',
      close: '17.1371000000000000',
      volume: '2621559.8700000000000000',
      quoteVolume: 44879553.905084215,
      btcVolume: 4525.608471864123,
      usdVolume: 44879553.905084215,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '17.1371000000000000',
      high: '17.4926000000000000',
      low: '16.7330000000000000',
      close: '16.7751000000000000',
      volume: '2243643.8300000000000000',
      quoteVolume: 38582692.96829227,
      btcVolume: 3962.9396596703737,
      usdVolume: 38582692.96829227,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '16.7753000000000000',
      high: '16.7900000000000000',
      low: '13.9129000000000000',
      close: '15.3506000000000000',
      volume: '2900497.8000000000000000',
      quoteVolume: 43979315.8858527,
      btcVolume: 5066.467433139654,
      usdVolume: 43979315.8858527,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '15.3506000000000000',
      high: '15.5468000000000000',
      low: '14.0100000000000000',
      close: '15.0811000000000000',
      volume: '2726253.2700000000000000',
      quoteVolume: 40893423.03739202,
      btcVolume: 4712.194211346884,
      usdVolume: 40893423.03739202,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '15.0902000000000000',
      high: '16.2400000000000000',
      low: '14.9586000000000000',
      close: '15.7892000000000000',
      volume: '3026813.0900000000000000',
      quoteVolume: 47332783.09776167,
      btcVolume: 5395.76311152628,
      usdVolume: 47332783.09776167,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '15.7941000000000000',
      high: '16.3500000000000000',
      low: '15.6785000000000000',
      close: '16.2164000000000000',
      volume: '2812457.1200000000000000',
      quoteVolume: 45141960.7019688,
      btcVolume: 4989.884585136048,
      usdVolume: 45141960.7019688,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '16.2187000000000000',
      high: '16.6982000000000000',
      low: '16.0123000000000000',
      close: '16.3777000000000000',
      volume: '2801822.6000000000000000',
      quoteVolume: 45666418.70995536,
      btcVolume: 4771.624602245077,
      usdVolume: 45666418.70995536,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '16.3722000000000000',
      high: '16.4719000000000000',
      low: '15.4606000000000000',
      close: '15.7222000000000000',
      volume: '2314656.9000000000000000',
      quoteVolume: 37214374.23302308,
      btcVolume: 3912.922381413597,
      usdVolume: 37214374.23302308,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '15.7121000000000000',
      high: '16.2367000000000000',
      low: '15.5800000000000000',
      close: '16.0580000000000000',
      volume: '2730367.3500000000000000',
      quoteVolume: 43746498.66421267,
      btcVolume: 4654.9166519593045,
      usdVolume: 43746498.66421267,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '16.0580000000000000',
      high: '16.5100000000000000',
      low: '16.0014000000000000',
      close: '16.2959000000000000',
      volume: '2893701.8400000000000000',
      quoteVolume: 47142111.5064022,
      btcVolume: 4887.223961466,
      usdVolume: 47142111.5064022,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '16.2999000000000000',
      high: '16.8620000000000000',
      low: '16.2511000000000000',
      close: '16.6299000000000000',
      volume: '3116090.7500000000000000',
      quoteVolume: 51603793.31526883,
      btcVolume: 5303.9246355544265,
      usdVolume: 51603793.31526883,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '16.6298000000000000',
      high: '17.2488000000000000',
      low: '16.3000000000000000',
      close: '17.2098000000000000',
      volume: '3245275.7500000000000000',
      quoteVolume: 54590767.63110695,
      btcVolume: 5634.9082075498,
      usdVolume: 54590767.63110695,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '17.2096000000000000',
      high: '17.3396000000000000',
      low: '16.4851000000000000',
      close: '16.8998000000000000',
      volume: '2395316.0900000000000000',
      quoteVolume: 40821394.31884481,
      btcVolume: 4224.383755576833,
      usdVolume: 40821394.31884481,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '16.8994000000000000',
      high: '17.0675000000000000',
      low: '15.7218000000000000',
      close: '16.0888000000000000',
      volume: '2163570.9800000000000000',
      quoteVolume: 35589912.05392658,
      btcVolume: 3843.773844858664,
      usdVolume: 35589912.05392658,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '16.0888000000000000',
      high: '16.9240000000000000',
      low: '15.9145000000000000',
      close: '16.5864000000000000',
      volume: '2520655.4700000000000000',
      quoteVolume: 41584466.617784135,
      btcVolume: 4547.953831523734,
      usdVolume: 41584466.617784135,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '16.5884000000000000',
      high: '16.8500000000000000',
      low: '16.3202000000000000',
      close: '16.4800000000000000',
      volume: '2302712.1700000000000000',
      quoteVolume: 38173594.65329609,
      btcVolume: 4145.125970293084,
      usdVolume: 38173594.65329609,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '16.4789000000000000',
      high: '16.7900000000000000',
      low: '15.9074000000000000',
      close: '15.9624000000000000',
      volume: '2280604.7400000000000000',
      quoteVolume: 37593388.9279645,
      btcVolume: 4127.7763329936115,
      usdVolume: 37593388.9279645,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '15.9457000000000000',
      high: '16.4161000000000000',
      low: '15.8057000000000000',
      close: '16.3807000000000000',
      volume: '2348512.9100000000000000',
      quoteVolume: 38152773.256409764,
      btcVolume: 4321.228700886494,
      usdVolume: 38152773.256409764,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '16.3807000000000000',
      high: '16.4979000000000000',
      low: '15.9537000000000000',
      close: '16.2159000000000000',
      volume: '2007343.8200000000000000',
      quoteVolume: 32624803.88715872,
      btcVolume: 3682.6289500059283,
      usdVolume: 32624803.88715872,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '16.2097000000000000',
      high: '16.5100000000000000',
      low: '16.1554000000000000',
      close: '16.4700000000000000',
      volume: '2613812.0500000000000000',
      quoteVolume: 42792287.77669632,
      btcVolume: 4725.005187970007,
      usdVolume: 42792287.77669632,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '16.4700000000000000',
      high: '17.1405000000000000',
      low: '16.3890000000000000',
      close: '17.0772000000000000',
      volume: '2866468.3900000000000000',
      quoteVolume: 47917801.298296325,
      btcVolume: 5119.878787996562,
      usdVolume: 47917801.298296325,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '17.0769000000000000',
      high: '17.1980000000000000',
      low: '16.6333000000000000',
      close: '17.0031000000000000',
      volume: '2598906.5200000000000000',
      quoteVolume: 44169314.055208385,
      btcVolume: 4668.689724077699,
      usdVolume: 44169314.055208385,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '17.0030000000000000',
      high: '17.7857000000000000',
      low: '16.9291000000000000',
      close: '17.7277000000000000',
      volume: '2969818.2300000000000000',
      quoteVolume: 51559065.97362402,
      btcVolume: 5420.853255843491,
      usdVolume: 51559065.97362402,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '17.7277000000000000',
      high: '18.1954000000000000',
      low: '16.8419000000000000',
      close: '17.0916000000000000',
      volume: '2612942.1800000000000000',
      quoteVolume: 45947854.11468179,
      btcVolume: 4807.9438395434145,
      usdVolume: 45947854.11468179,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '17.0926000000000000',
      high: '18.1224000000000000',
      low: '16.9836000000000000',
      close: '17.9477000000000000',
      volume: '2989853.0600000000000000',
      quoteVolume: 52189313.03744129,
      btcVolume: 5419.06119775764,
      usdVolume: 52189313.03744129,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '17.9469000000000000',
      high: '18.2000000000000000',
      low: '16.6800000000000000',
      close: '17.2669000000000000',
      volume: '2536257.1500000000000000',
      quoteVolume: 44844446.996629834,
      btcVolume: 4522.870886309356,
      usdVolume: 44844446.996629834,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '17.2761000000000000',
      high: '17.6275000000000000',
      low: '16.9645000000000000',
      close: '17.5779000000000000',
      volume: '2650311.1400000000000000',
      quoteVolume: 45972871.326653786,
      btcVolume: 4811.90967908672,
      usdVolume: 45972871.326653786,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '17.5780000000000000',
      high: '17.6527000000000000',
      low: '17.0424000000000000',
      close: '17.5170000000000000',
      volume: '2320525.8900000000000000',
      quoteVolume: 40612423.08444422,
      btcVolume: 4186.441359549021,
      usdVolume: 40612423.08444422,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '17.5180000000000000',
      high: '17.8999000000000000',
      low: '17.4396000000000000',
      close: '17.6914000000000000',
      volume: '2454801.7700000000000000',
      quoteVolume: 43342829.55218497,
      btcVolume: 4448.532900411398,
      usdVolume: 43342829.55218497,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '17.6915000000000000',
      high: '17.7951000000000000',
      low: '17.4900000000000000',
      close: '17.5511000000000000',
      volume: '1963897.2200000000000000',
      quoteVolume: 34612966.357234664,
      btcVolume: 3593.211679800369,
      usdVolume: 34612966.357234664,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '17.5510000000000000',
      high: '17.6371000000000000',
      low: '16.8526000000000000',
      close: '17.4135000000000000',
      volume: '1816342.7400000000000000',
      quoteVolume: 31593373.320084013,
      btcVolume: 3279.991408861491,
      usdVolume: 31593373.320084013,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '17.4134000000000000',
      high: '17.5454000000000000',
      low: '17.2594000000000000',
      close: '17.4489000000000000',
      volume: '1657535.1100000000000000',
      quoteVolume: 28825851.386570796,
      btcVolume: 2964.926669627316,
      usdVolume: 28825851.386570796,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '17.4489000000000000',
      high: '17.5098000000000000',
      low: '17.0374000000000000',
      close: '17.3733000000000000',
      volume: '1630641.2900000000000000',
      quoteVolume: 28236106.363147046,
      btcVolume: 2904.68453638977,
      usdVolume: 28236106.363147046,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '17.3734000000000000',
      high: '17.5833000000000000',
      low: '17.1300000000000000',
      close: '17.4060000000000000',
      volume: '1807563.8600000000000000',
      quoteVolume: 31389856.381225992,
      btcVolume: 3201.181062165144,
      usdVolume: 31389856.381225992,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '17.4084000000000000',
      high: '17.5191000000000000',
      low: '15.9300000000000000',
      close: '16.2298000000000000',
      volume: '1799102.4900000000000000',
      quoteVolume: 30529455.476210415,
      btcVolume: 3172.6583143612193,
      usdVolume: 30529455.476210415,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '16.2376000000000000',
      high: '16.7999000000000000',
      low: '16.0869000000000000',
      close: '16.6377000000000000',
      volume: '1930125.9500000000000000',
      quoteVolume: 31938857.307844184,
      btcVolume: 3394.540608530982,
      usdVolume: 31938857.307844184,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '16.6420000000000000',
      high: '16.8179000000000000',
      low: '16.4900000000000000',
      close: '16.8101000000000000',
      volume: '2023311.8300000000000000',
      quoteVolume: 33704354.30536094,
      btcVolume: 3573.99175725781,
      usdVolume: 33704354.30536094,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '16.8111000000000000',
      high: '16.8398000000000000',
      low: '16.4023000000000000',
      close: '16.5729000000000000',
      volume: '1697344.5500000000000000',
      quoteVolume: 28336046.766627934,
      btcVolume: 3010.4223953476185,
      usdVolume: 28336046.766627934,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '16.5728000000000000',
      high: '16.5982000000000000',
      low: '15.4000000000000000',
      close: '16.3770000000000000',
      volume: '1521685.2100000000000000',
      quoteVolume: 24473919.621151537,
      btcVolume: 2658.7457305257617,
      usdVolume: 24473919.621151537,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '16.3758000000000000',
      high: '16.6481000000000000',
      low: '16.1562000000000000',
      close: '16.4329000000000000',
      volume: '1614905.6400000000000000',
      quoteVolume: 26479345.22874293,
      btcVolume: 2788.7732205533584,
      usdVolume: 26479345.22874293,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '16.4331000000000000',
      high: '16.6320000000000000',
      low: '15.9709000000000000',
      close: '16.3983000000000000',
      volume: '1502697.3100000000000000',
      quoteVolume: 24591062.00260309,
      btcVolume: 2604.547568064439,
      usdVolume: 24591062.00260309,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '16.3983000000000000',
      high: '16.5423000000000000',
      low: '15.8783000000000000',
      close: '16.1608000000000000',
      volume: '1382820.8700000000000000',
      quoteVolume: 22540156.403535735,
      btcVolume: 2394.7606782552566,
      usdVolume: 22540156.403535735,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '16.1665000000000000',
      high: '16.3106000000000000',
      low: '15.8966000000000000',
      close: '15.9512000000000000',
      volume: '1324135.6000000000000000',
      quoteVolume: 21271385.818114948,
      btcVolume: 2279.89302677804,
      usdVolume: 21271385.818114948,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '15.9611000000000000',
      high: '16.0898000000000000',
      low: '15.7000000000000000',
      close: '16.0590000000000000',
      volume: '1427210.2200000000000000',
      quoteVolume: 22776460.30155413,
      btcVolume: 2445.1087498630836,
      usdVolume: 22776460.30155413,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '16.0590000000000000',
      high: '16.3358000000000000',
      low: '15.9210000000000000',
      close: '15.9775000000000000',
      volume: '1317075.8400000000000000',
      quoteVolume: 21166507.63965404,
      btcVolume: 2262.4663175872406,
      usdVolume: 21166507.63965404,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '15.9792000000000000',
      high: '16.5500000000000000',
      low: '15.9499000000000000',
      close: '16.5051000000000000',
      volume: '1703957.1800000000000000',
      quoteVolume: 27730249.14524187,
      btcVolume: 2922.9736930384024,
      usdVolume: 27730249.14524187,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '16.5051000000000000',
      high: '16.5472000000000000',
      low: '16.2602000000000000',
      close: '16.3898000000000000',
      volume: '1450721.0800000000000000',
      quoteVolume: 23797667.00323723,
      btcVolume: 2468.0003601359663,
      usdVolume: 23797667.00323723,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '16.3898000000000000',
      high: '16.6481000000000000',
      low: '15.9500000000000000',
      close: '15.9968000000000000',
      volume: '1529800.3000000000000000',
      quoteVolume: 24889909.655295953,
      btcVolume: 2628.3948271845443,
      usdVolume: 24889909.655295953,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '16.0084000000000000',
      high: '16.0816000000000000',
      low: '15.5000000000000000',
      close: '15.9060000000000000',
      volume: '1201354.7500000000000000',
      quoteVolume: 19105061.019308876,
      btcVolume: 2069.4495041309574,
      usdVolume: 19105061.019308876,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '15.9056000000000000',
      high: '15.9996000000000000',
      low: '15.5715000000000000',
      close: '15.7694000000000000',
      volume: '1041399.0000000000000000',
      quoteVolume: 16428293.389013952,
      btcVolume: 1787.4554301521573,
      usdVolume: 16428293.389013952,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '15.7696000000000000',
      high: '15.8714000000000000',
      low: '14.8000000000000000',
      close: '15.1242000000000000',
      volume: '1309335.6600000000000000',
      quoteVolume: 20331918.057953976,
      btcVolume: 2234.822337873103,
      usdVolume: 20331918.057953976,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '15.1230000000000000',
      high: '15.4944000000000000',
      low: '14.9249000000000000',
      close: '15.3782000000000000',
      volume: '1402858.9600000000000000',
      quoteVolume: 21418398.753466826,
      btcVolume: 2360.2436605355056,
      usdVolume: 21418398.753466826,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '15.3714000000000000',
      high: '15.5901000000000000',
      low: '15.2040000000000000',
      close: '15.4656000000000000',
      volume: '1511856.3800000000000000',
      quoteVolume: 23294081.307785984,
      btcVolume: 2551.092853713331,
      usdVolume: 23294081.307785984,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '15.4674000000000000',
      high: '15.5476000000000000',
      low: '15.2519000000000000',
      close: '15.3838000000000000',
      volume: '1315772.4200000000000000',
      quoteVolume: 20305967.15507204,
      btcVolume: 2218.39382184501,
      usdVolume: 20305967.15507204,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '15.3959000000000000',
      high: '15.8888000000000000',
      low: '15.3363000000000000',
      close: '15.8144000000000000',
      volume: '1609244.5800000000000000',
      quoteVolume: 25167285.05407897,
      btcVolume: 2737.6419244650206,
      usdVolume: 25167285.05407897,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '15.8123000000000000',
      high: '15.9796000000000000',
      low: '15.0743000000000000',
      close: '15.3634000000000000',
      volume: '1399345.3900000000000000',
      quoteVolume: 21887439.935343955,
      btcVolume: 2389.049039204999,
      usdVolume: 21887439.935343955,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '15.3640000000000000',
      high: '15.4880000000000000',
      low: '15.2484000000000000',
      close: '15.2863000000000000',
      volume: '1204044.4900000000000000',
      quoteVolume: 18526262.42016398,
      btcVolume: 2036.9658883771122,
      usdVolume: 18526262.42016398,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '15.2856000000000000',
      high: '15.6399000000000000',
      low: '15.2239000000000000',
      close: '15.5548000000000000',
      volume: '1226300.0400000000000000',
      quoteVolume: 18922658.450647,
      btcVolume: 2079.9805745186986,
      usdVolume: 18922658.450647,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '15.5579000000000000',
      high: '15.6135000000000000',
      low: '15.1000000000000000',
      close: '15.4885000000000000',
      volume: '1064265.3200000000000000',
      quoteVolume: 16414898.226830075,
      btcVolume: 1810.9834840684625,
      usdVolume: 16414898.226830075,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '15.4865000000000000',
      high: '16.3496000000000000',
      low: '15.4401000000000000',
      close: '16.3110000000000000',
      volume: '1606449.3100000000000000',
      quoteVolume: 25364592.417103197,
      btcVolume: 2750.178905626979,
      usdVolume: 25364592.417103197,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '16.3120000000000000',
      high: '16.8757000000000000',
      low: '16.0301000000000000',
      close: '16.8048000000000000',
      volume: '1880630.3400000000000000',
      quoteVolume: 31044612.46472845,
      btcVolume: 3348.0111025538104,
      usdVolume: 31044612.46472845,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '16.7995000000000000',
      high: '17.4550000000000000',
      low: '16.5683000000000000',
      close: '17.2819000000000000',
      volume: '2246484.1800000000000000',
      quoteVolume: 38372913.62190868,
      btcVolume: 4118.227717083268,
      usdVolume: 38372913.62190868,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '17.2819000000000000',
      high: '17.5000000000000000',
      low: '16.4088000000000000',
      close: '16.8274000000000000',
      volume: '1869273.6700000000000000',
      quoteVolume: 31602020.87875102,
      btcVolume: 3389.70673054376,
      usdVolume: 31602020.87875102,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '16.8278000000000000',
      high: '17.3310000000000000',
      low: '16.4438000000000000',
      close: '17.3123000000000000',
      volume: '2099193.5700000000000000',
      quoteVolume: 35493337.16801673,
      btcVolume: 3853.180488607405,
      usdVolume: 35493337.16801673,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '17.3133000000000000',
      high: '17.6888000000000000',
      low: '17.0180000000000000',
      close: '17.6205000000000000',
      volume: '2276165.3000000000000000',
      quoteVolume: 39710164.3496227,
      btcVolume: 4298.963618020441,
      usdVolume: 39710164.3496227,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '17.6205000000000000',
      high: '18.5900000000000000',
      low: '17.5928000000000000',
      close: '18.2994000000000000',
      volume: '2995977.4100000000000000',
      quoteVolume: 54487455.92163716,
      btcVolume: 5888.09404421164,
      usdVolume: 54487455.92163716,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '18.3036000000000000',
      high: '18.9999000000000000',
      low: '17.7282000000000000',
      close: '18.4046000000000000',
      volume: '3228966.6100000000000000',
      quoteVolume: 59615751.20299803,
      btcVolume: 6420.203822258735,
      usdVolume: 59615751.20299803,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '18.4007000000000000',
      high: '18.7700000000000000',
      low: '17.8266000000000000',
      close: '18.0790000000000000',
      volume: '2385481.9700000000000000',
      quoteVolume: 43474784.56725936,
      btcVolume: 4714.97312281895,
      usdVolume: 43474784.56725936,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '18.0790000000000000',
      high: '18.1538000000000000',
      low: '17.4194000000000000',
      close: '17.6351000000000000',
      volume: '1959582.7300000000000000',
      quoteVolume: 34842425.382078946,
      btcVolume: 3776.4969692526847,
      usdVolume: 34842425.382078946,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '17.6351000000000000',
      high: '17.7618000000000000',
      low: '16.5473000000000000',
      close: '17.1647000000000000',
      volume: '2053439.4400000000000000',
      quoteVolume: 35131243.13980965,
      btcVolume: 3840.4754390289468,
      usdVolume: 35131243.13980965,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '17.1647000000000000',
      high: '17.4825000000000000',
      low: '16.9354000000000000',
      close: '17.0676000000000000',
      volume: '1852340.6900000000000000',
      quoteVolume: 32019335.19000079,
      btcVolume: 3504.743471468927,
      usdVolume: 32019335.19000079,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '17.0675000000000000',
      high: '17.3247000000000000',
      low: '16.8122000000000000',
      close: '17.1507000000000000',
      volume: '1687884.7600000000000000',
      quoteVolume: 28863376.478104953,
      btcVolume: 3151.7268006262602,
      usdVolume: 28863376.478104953,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '17.1542000000000000',
      high: '18.0980000000000000',
      low: '16.8900000000000000',
      close: '18.0154000000000000',
      volume: '2040900.4400000000000000',
      quoteVolume: 35931733.34284701,
      btcVolume: 3925.100741605019,
      usdVolume: 35931733.34284701,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '18.0166000000000000',
      high: '18.3400000000000000',
      low: '17.2350000000000000',
      close: '17.4645000000000000',
      volume: '2487505.4900000000000000',
      quoteVolume: 44544637.59956494,
      btcVolume: 4853.4342390229085,
      usdVolume: 44544637.59956494,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '17.4645000000000000',
      high: '17.9799000000000000',
      low: '17.4000000000000000',
      close: '17.7285000000000000',
      volume: '2110733.7600000000000000',
      quoteVolume: 37350560.43213578,
      btcVolume: 4021.207302975722,
      usdVolume: 37350560.43213578,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '17.7268000000000000',
      high: '18.1151000000000000',
      low: '17.4500000000000000',
      close: '18.0900000000000000',
      volume: '2172957.4900000000000000',
      quoteVolume: 38516127.55802692,
      btcVolume: 4103.451167804567,
      usdVolume: 38516127.55802692,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '18.0950000000000000',
      high: '18.7700000000000000',
      low: '18.0430000000000000',
      close: '18.7500000000000000',
      volume: '2393871.4500000000000000',
      quoteVolume: 43866390.168993205,
      btcVolume: 4602.916363249338,
      usdVolume: 43866390.168993205,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '18.7508000000000000',
      high: '19.5500000000000000',
      low: '18.2350000000000000',
      close: '19.1853000000000000',
      volume: '2790281.4600000000000000',
      quoteVolume: 52627925.923414364,
      btcVolume: 5510.371737165114,
      usdVolume: 52627925.923414364,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '19.1878000000000000',
      high: '20.0434000000000000',
      low: '19.1451000000000000',
      close: '19.8242000000000000',
      volume: '2956369.7600000000000000',
      quoteVolume: 58227674.3389005,
      btcVolume: 6053.932571636272,
      usdVolume: 58227674.3389005,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '19.8196000000000000',
      high: '20.4900000000000000',
      low: '19.2639000000000000',
      close: '19.5557000000000000',
      volume: '2857739.1600000000000000',
      quoteVolume: 56378423.676656574,
      btcVolume: 5746.873011607245,
      usdVolume: 56378423.676656574,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '19.5524000000000000',
      high: '20.1999000000000000',
      low: '18.4000000000000000',
      close: '19.7474000000000000',
      volume: '3841364.9100000000000000',
      quoteVolume: 74130208.23661059,
      btcVolume: 7086.863775270473,
      usdVolume: 74130208.23661059,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '19.7489000000000000',
      high: '20.6834000000000000',
      low: '19.4281000000000000',
      close: '20.1732000000000000',
      volume: '3516712.3500000000000000',
      quoteVolume: 70792618.98395808,
      btcVolume: 6451.110876885711,
      usdVolume: 70792618.98395808,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '20.1692000000000000',
      high: '20.4091000000000000',
      low: '19.7404000000000000',
      close: '19.8990000000000000',
      volume: '2219760.2000000000000000',
      quoteVolume: 44493255.13050928,
      btcVolume: 4013.2992143742485,
      usdVolume: 44493255.13050928,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '19.8926000000000000',
      high: '20.0995000000000000',
      low: '19.5111000000000000',
      close: '19.9700000000000000',
      volume: '2052242.5900000000000000',
      quoteVolume: 40761604.204639345,
      btcVolume: 3688.6879848192216,
      usdVolume: 40761604.204639345,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '19.9700000000000000',
      high: '20.8550000000000000',
      low: '19.9102000000000000',
      close: '20.6615000000000000',
      volume: '2251101.2100000000000000',
      quoteVolume: 46058826.375720695,
      btcVolume: 4123.40021955276,
      usdVolume: 46058826.375720695,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '20.6637000000000000',
      high: '21.7400000000000000',
      low: '20.5100000000000000',
      close: '21.6168000000000000',
      volume: '2657942.8500000000000000',
      quoteVolume: 55887870.71842739,
      btcVolume: 4818.782175909525,
      usdVolume: 55887870.71842739,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '21.6204000000000000',
      high: '22.1832000000000000',
      low: '19.1000000000000000',
      close: '20.9898000000000000',
      volume: '3352897.4600000000000000',
      quoteVolume: 69934920.73105527,
      btcVolume: 6141.195512343305,
      usdVolume: 69934920.73105527,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '20.9899000000000000',
      high: '22.5000000000000000',
      low: '20.7000000000000000',
      close: '22.0504000000000000',
      volume: '2668345.0200000000000000',
      quoteVolume: 58090829.071236245,
      btcVolume: 5171.676116333851,
      usdVolume: 58090829.071236245,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '22.0622000000000000',
      high: '22.4433000000000000',
      low: '21.4100000000000000',
      close: '22.2818000000000000',
      volume: '2388746.3300000000000000',
      quoteVolume: 52666694.152770996,
      btcVolume: 4685.128631957818,
      usdVolume: 52666694.152770996,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '22.2967000000000000',
      high: '23.5400000000000000',
      low: '22.0566000000000000',
      close: '23.2728000000000000',
      volume: '3698317.5100000000000000',
      quoteVolume: 85353655.3331338,
      btcVolume: 7486.721568389888,
      usdVolume: 85353655.3331338,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '23.2685000000000000',
      high: '23.3150000000000000',
      low: '22.5000000000000000',
      close: '22.7487000000000000',
      volume: '2442941.2600000000000000',
      quoteVolume: 56050066.31906953,
      btcVolume: 4770.055541701376,
      usdVolume: 56050066.31906953,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '22.7488000000000000',
      high: '22.9999000000000000',
      low: '21.4515000000000000',
      close: '22.3323000000000000',
      volume: '2770757.2300000000000000',
      quoteVolume: 62149286.781891696,
      btcVolume: 5325.432326997081,
      usdVolume: 62149286.781891696,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '22.3323000000000000',
      high: '22.9241000000000000',
      low: '21.8571000000000000',
      close: '22.9234000000000000',
      volume: '2408649.6800000000000000',
      quoteVolume: 53989515.896717064,
      btcVolume: 4622.635302886836,
      usdVolume: 53989515.896717064,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '22.9230000000000000',
      high: '22.9233000000000000',
      low: '22.0462000000000000',
      close: '22.4220000000000000',
      volume: '2107633.5500000000000000',
      quoteVolume: 47448309.30630981,
      btcVolume: 4067.839134883987,
      usdVolume: 47448309.30630981,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '22.4224000000000000',
      high: '22.7700000000000000',
      low: '22.0462000000000000',
      close: '22.6369000000000000',
      volume: '2108928.4200000000000000',
      quoteVolume: 47454718.99539966,
      btcVolume: 3996.4516752424197,
      usdVolume: 47454718.99539966,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '22.6369000000000000',
      high: '22.7306000000000000',
      low: '20.6600000000000000',
      close: '21.2902000000000000',
      volume: '3211543.4400000000000000',
      quoteVolume: 69760318.54425076,
      btcVolume: 6019.612592108588,
      usdVolume: 69760318.54425076,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '21.2993000000000000',
      high: '21.9820000000000000',
      low: '20.5710000000000000',
      close: '21.4919000000000000',
      volume: '2455644.0200000000000000',
      quoteVolume: 52586016.37716104,
      btcVolume: 4598.830579180555,
      usdVolume: 52586016.37716104,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '21.4919000000000000',
      high: '21.8560000000000000',
      low: '20.6400000000000000',
      close: '21.7667000000000000',
      volume: '3079188.7800000000000000',
      quoteVolume: 65454119.38361212,
      btcVolume: 5675.420522437151,
      usdVolume: 65454119.38361212,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '21.7751000000000000',
      high: '23.3019000000000000',
      low: '21.2600000000000000',
      close: '23.1047000000000000',
      volume: '3817320.2200000000000000',
      quoteVolume: 85895020.77906209,
      btcVolume: 7324.882591857548,
      usdVolume: 85895020.77906209,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '23.0966000000000000',
      high: '23.7884000000000000',
      low: '22.7000000000000000',
      close: '23.0881000000000000',
      volume: '2752150.0600000000000000',
      quoteVolume: 64002725.1804066,
      btcVolume: 5394.658484324495,
      usdVolume: 64002725.1804066,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '23.1045000000000000',
      high: '23.7790000000000000',
      low: '22.7000000000000000',
      close: '23.5290000000000000',
      volume: '2705813.5400000000000000',
      quoteVolume: 62902668.60949415,
      btcVolume: 5311.255677616959,
      usdVolume: 62902668.60949415,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '23.5291000000000000',
      high: '23.9100000000000000',
      low: '22.9584000000000000',
      close: '23.4961000000000000',
      volume: '2423482.1500000000000000',
      quoteVolume: 56563755.666076384,
      btcVolume: 4691.623480656751,
      usdVolume: 56563755.666076384,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '23.4868000000000000',
      high: '23.7800000000000000',
      low: '22.3330000000000000',
      close: '22.9705000000000000',
      volume: '2531759.2800000000000000',
      quoteVolume: 58722035.45027393,
      btcVolume: 4835.174004514033,
      usdVolume: 58722035.45027393,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '22.9802000000000000',
      high: '23.2500000000000000',
      low: '21.8219000000000000',
      close: '22.3761000000000000',
      volume: '2128074.0300000000000000',
      quoteVolume: 47918443.635081224,
      btcVolume: 4064.0451093136103,
      usdVolume: 47918443.635081224,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '22.3817000000000000',
      high: '23.3000000000000000',
      low: '22.0265000000000000',
      close: '23.0888000000000000',
      volume: '2236274.1500000000000000',
      quoteVolume: 50958756.88971377,
      btcVolume: 4320.849344252172,
      usdVolume: 50958756.88971377,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '23.0909000000000000',
      high: '23.3200000000000000',
      low: '21.5000000000000000',
      close: '22.0988000000000000',
      volume: '2489462.0200000000000000',
      quoteVolume: 56223221.93286248,
      btcVolume: 4795.974426477643,
      usdVolume: 56223221.93286248,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '22.0994000000000000',
      high: '22.2464000000000000',
      low: '21.2814000000000000',
      close: '22.2044000000000000',
      volume: '1891753.4200000000000000',
      quoteVolume: 41537070.73814768,
      btcVolume: 3592.3703459321446,
      usdVolume: 41537070.73814768,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '22.2010000000000000',
      high: '22.2348000000000000',
      low: '21.2841000000000000',
      close: '21.8555000000000000',
      volume: '1618939.3500000000000000',
      quoteVolume: 35415367.6439598,
      btcVolume: 3050.172713172957,
      usdVolume: 35415367.6439598,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '21.8575000000000000',
      high: '22.9746000000000000',
      low: '21.6655000000000000',
      close: '22.5953000000000000',
      volume: '1877683.4400000000000000',
      quoteVolume: 42093704.95900781,
      btcVolume: 3588.708992922525,
      usdVolume: 42093704.95900781,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '22.6016000000000000',
      high: '22.6415000000000000',
      low: '20.9805000000000000',
      close: '21.3882000000000000',
      volume: '2214962.9300000000000000',
      quoteVolume: 48368662.81298032,
      btcVolume: 4213.210966957328,
      usdVolume: 48368662.81298032,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '21.3881000000000000',
      high: '22.4743000000000000',
      low: '21.2651000000000000',
      close: '22.1999000000000000',
      volume: '2499792.2900000000000000',
      quoteVolume: 55285830.68548643,
      btcVolume: 4852.707891075785,
      usdVolume: 55285830.68548643,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '22.2005000000000000',
      high: '23.1507000000000000',
      low: '21.8064000000000000',
      close: '23.1127000000000000',
      volume: '2925157.1700000000000000',
      quoteVolume: 65680254.026992634,
      btcVolume: 5788.970095035588,
      usdVolume: 65680254.026992634,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '23.1127000000000000',
      high: '23.5846000000000000',
      low: '22.6158000000000000',
      close: '23.0811000000000000',
      volume: '2526396.8800000000000000',
      quoteVolume: 58274233.91624748,
      btcVolume: 5107.116104849058,
      usdVolume: 58274233.91624748,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '23.0783000000000000',
      high: '23.5000000000000000',
      low: '22.7416000000000000',
      close: '22.9286000000000000',
      volume: '1825207.1700000000000000',
      quoteVolume: 42300003.84051298,
      btcVolume: 3677.51541413538,
      usdVolume: 42300003.84051298,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '22.9441000000000000',
      high: '23.8300000000000000',
      low: '22.9000000000000000',
      close: '23.6208000000000000',
      volume: '1777138.8500000000000000',
      quoteVolume: 41436875.3779341,
      btcVolume: 3568.130657260122,
      usdVolume: 41436875.3779341,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '23.6250000000000000',
      high: '23.9000000000000000',
      low: '22.9670000000000000',
      close: '23.1477000000000000',
      volume: '1767387.7800000000000000',
      quoteVolume: 41283857.21209864,
      btcVolume: 3534.6531834593225,
      usdVolume: 41283857.21209864,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '23.1481000000000000',
      high: '25.4100000000000000',
      low: '22.8400000000000000',
      close: '24.7028000000000000',
      volume: '3998518.4600000000000000',
      quoteVolume: 95349979.86598961,
      btcVolume: 8016.152928036265,
      usdVolume: 95348715.97884162,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '24.7101000000000000',
      high: '25.8262000000000000',
      low: '23.1215000000000000',
      close: '24.7652000000000000',
      volume: '5843722.5300000000000000',
      quoteVolume: 144376866.58587617,
      btcVolume: 12476.71910511825,
      usdVolume: 144376866.58587617,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '24.7653000000000000',
      high: '25.5400000000000000',
      low: '19.8765000000000000',
      close: '20.3592000000000000',
      volume: '7208188.4900000000000000',
      quoteVolume: 168388157.16915026,
      btcVolume: 15323.190970084937,
      usdVolume: 168388157.16915026,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '20.4027000000000000',
      high: '21.8300000000000000',
      low: '19.2000000000000000',
      close: '20.9712000000000000',
      volume: '5304836.8000000000000000',
      quoteVolume: 110005537.1003693,
      btcVolume: 10648.840229664847,
      usdVolume: 110005537.1003693,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '20.9995000000000000',
      high: '21.5539000000000000',
      low: '18.0110000000000000',
      close: '19.4698000000000000',
      volume: '4587657.7700000000000000',
      quoteVolume: 89925282.01269266,
      btcVolume: 8795.872253723111,
      usdVolume: 89925282.01269266,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '19.4737000000000000',
      high: '23.4418000000000000',
      low: '18.5283000000000000',
      close: '23.1420000000000000',
      volume: '8069211.7600000000000000',
      quoteVolume: 174283155.6269918,
      btcVolume: 17068.88087509094,
      usdVolume: 174283155.6269918,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '23.1612000000000000',
      high: '23.5545000000000000',
      low: '20.0000000000000000',
      close: '22.4426000000000000',
      volume: '5402317.0400000000000000',
      quoteVolume: 117739085.20631854,
      btcVolume: 11629.8606720547,
      usdVolume: 117739085.20631854,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '22.4428000000000000',
      high: '23.4200000000000000',
      low: '21.2739000000000000',
      close: '23.2837000000000000',
      volume: '5158563.9800000000000000',
      quoteVolume: 115741375.12774241,
      btcVolume: 11408.651574079575,
      usdVolume: 115741375.12774241,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '23.2745000000000000',
      high: '24.5201000000000000',
      low: '22.4139000000000000',
      close: '24.4716000000000000',
      volume: '4016452.5100000000000000',
      quoteVolume: 94841678.35523182,
      btcVolume: 9328.731014737568,
      usdVolume: 94841678.35523182,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '24.4847000000000000',
      high: '25.6700000000000000',
      low: '24.0568000000000000',
      close: '24.5489000000000000',
      volume: '4197768.0800000000000000',
      quoteVolume: 103939762.77621293,
      btcVolume: 10055.05164439836,
      usdVolume: 103939762.77621293,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '24.5579000000000000',
      high: '25.6969000000000000',
      low: '23.0677000000000000',
      close: '25.3990000000000000',
      volume: '3622605.0700000000000000',
      quoteVolume: 88276634.60726066,
      btcVolume: 8560.638049834533,
      usdVolume: 88276634.60726066,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '25.4000000000000000',
      high: '28.5305000000000000',
      low: '25.3575000000000000',
      close: '28.5050000000000000',
      volume: '6538510.1800000000000000',
      quoteVolume: 175802729.4278746,
      btcVolume: 16975.401468484088,
      usdVolume: 175802729.4278746,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '28.5044000000000000',
      high: '32.4401000000000000',
      low: '27.6100000000000000',
      close: '31.1656000000000000',
      volume: '10646215.6600000000000000',
      quoteVolume: 324162248.7407463,
      btcVolume: 31172.01029736164,
      usdVolume: 324162248.7407463,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '31.1656000000000000',
      high: '33.3888000000000000',
      low: '29.0000000000000000',
      close: '31.2523000000000000',
      volume: '9039644.3200000000000000',
      quoteVolume: 282868031.2655408,
      btcVolume: 26883.24594624267,
      usdVolume: 282868031.2655408,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '31.2546000000000000',
      high: '31.2780000000000000',
      low: '26.7123000000000000',
      close: '27.1181000000000000',
      volume: '7931354.6460000000000000',
      quoteVolume: 228427870.13850227,
      btcVolume: 21238.609622009128,
      usdVolume: 228427870.13850227,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '27.1148000000000000',
      high: '28.4724000000000000',
      low: '25.8200000000000000',
      close: '27.8695000000000000',
      volume: '5777419.7520000000000000',
      quoteVolume: 157849897.40358767,
      btcVolume: 14525.119593023768,
      usdVolume: 157849897.40358767,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '27.8539000000000000',
      high: '28.8300000000000000',
      low: '26.0500000000000000',
      close: '27.0950000000000000',
      volume: '4620518.8740000000000000',
      quoteVolume: 125923630.01926374,
      btcVolume: 11551.914313428693,
      usdVolume: 125923630.01926374,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '27.1106000000000000',
      high: '28.7500000000000000',
      low: '26.5708000000000000',
      close: '27.6290000000000000',
      volume: '4243135.9990000000000000',
      quoteVolume: 117188169.08344378,
      btcVolume: 10721.855220981095,
      usdVolume: 117188169.08344378,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '27.6414000000000000',
      high: '27.9770000000000000',
      low: '26.6591000000000000',
      close: '27.2035000000000000',
      volume: '2431517.1500000000000000',
      quoteVolume: 66209405.92737906,
      btcVolume: 6017.836049060832,
      usdVolume: 66209405.92737906,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '27.2035000000000000',
      high: '27.3200000000000000',
      low: '25.1247000000000000',
      close: '26.3160000000000000',
      volume: '2843429.9720000000000000',
      quoteVolume: 74991076.55056357,
      btcVolume: 6881.997502694567,
      usdVolume: 74991076.55056357,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '26.3167000000000000',
      high: '26.6800000000000000',
      low: '22.1000000000000000',
      close: '23.1232000000000000',
      volume: '5251422.2430000000000000',
      quoteVolume: 127147745.41469589,
      btcVolume: 11950.186281642047,
      usdVolume: 127147745.41469589,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '23.1273000000000000',
      high: '24.5650000000000000',
      low: '22.6666000000000000',
      close: '24.0468000000000000',
      volume: '3281775.4620000000000000',
      quoteVolume: 77971128.39187472,
      btcVolume: 7453.267500200181,
      usdVolume: 77971128.39187472,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '24.0468000000000000',
      high: '24.3437000000000000',
      low: '22.4514000000000000',
      close: '22.8331000000000000',
      volume: '3134141.7740000000000000',
      quoteVolume: 73949538.77212654,
      btcVolume: 7097.075147605696,
      usdVolume: 73949538.77212654,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '22.8448000000000000',
      high: '24.6999000000000000',
      low: '22.5661000000000000',
      close: '24.5745000000000000',
      volume: '3280582.2240000000000000',
      quoteVolume: 78057236.24715658,
      btcVolume: 7468.991503938146,
      usdVolume: 78057236.24715658,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '24.5749000000000000',
      high: '25.0799000000000000',
      low: '23.7111000000000000',
      close: '24.6924000000000000',
      volume: '2826869.5890000000000000',
      quoteVolume: 69263025.79303573,
      btcVolume: 6491.558933280871,
      usdVolume: 69263025.79303573,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '24.6925000000000000',
      high: '26.4628000000000000',
      low: '24.4750000000000000',
      close: '26.1998000000000000',
      volume: '3109951.9450000000000000',
      quoteVolume: 79026166.8893397,
      btcVolume: 7375.746915791385,
      usdVolume: 79026166.8893397,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '26.1885000000000000',
      high: '26.9000000000000000',
      low: '25.5012000000000000',
      close: '26.2510000000000000',
      volume: '2549943.8930000000000000',
      quoteVolume: 66948796.67374076,
      btcVolume: 6245.902387721704,
      usdVolume: 66948796.67374076,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '26.2435000000000000',
      high: '27.7200000000000000',
      low: '25.8313000000000000',
      close: '26.9855000000000000',
      volume: '3215302.9540000000000000',
      quoteVolume: 85939137.96800753,
      btcVolume: 7904.878434360903,
      usdVolume: 85939137.96800753,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '26.9969000000000000',
      high: '29.0000000000000000',
      low: '26.8600000000000000',
      close: '28.8946000000000000',
      volume: '3642652.3360000000000000',
      quoteVolume: 102012505.98227179,
      btcVolume: 9504.594750278005,
      usdVolume: 102012505.98227179,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '28.8814000000000000',
      high: '29.5646000000000000',
      low: '27.8813000000000000',
      close: '29.2656000000000000',
      volume: '3102332.1810000000000000',
      quoteVolume: 89000015.31908017,
      btcVolume: 8286.090073062971,
      usdVolume: 89000015.31908017,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '29.2696000000000000',
      high: '29.3927000000000000',
      low: '26.2111000000000000',
      close: '27.4720000000000000',
      volume: '4000390.3250000000000000',
      quoteVolume: 111543168.04777229,
      btcVolume: 10423.810924564852,
      usdVolume: 111543168.04777229,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '27.3367000000000000',
      high: '28.3633000000000000',
      low: '25.6652000000000000',
      close: '27.3369000000000000',
      volume: '4117221.0050000000000000',
      quoteVolume: 111198198.15562725,
      btcVolume: 10572.72520169783,
      usdVolume: 111198198.15562725,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '27.3338000000000000',
      high: '28.3997000000000000',
      low: '27.0701000000000000',
      close: '28.1867000000000000',
      volume: '2946071.9920000000000000',
      quoteVolume: 81824336.491502,
      btcVolume: 7759.17949681676,
      usdVolume: 81824336.491502,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '28.1872000000000000',
      high: '29.1500000000000000',
      low: '27.9658000000000000',
      close: '28.9668000000000000',
      volume: '3102597.6600000000000000',
      quoteVolume: 89029435.36271603,
      btcVolume: 8394.366201707255,
      usdVolume: 89029435.36271603,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '28.9832000000000000',
      high: '29.2665000000000000',
      low: '28.0687000000000000',
      close: '28.6158000000000000',
      volume: '2268257.1710000000000000',
      quoteVolume: 65226739.00450134,
      btcVolume: 6100.3480015059995,
      usdVolume: 65226739.00450134,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '28.6330000000000000',
      high: '28.9900000000000000',
      low: '27.2500000000000000',
      close: '27.6812000000000000',
      volume: '2810020.1650000000000000',
      quoteVolume: 79203442.53717537,
      btcVolume: 7402.756056350025,
      usdVolume: 79203442.53717537,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '27.6907000000000000',
      high: '27.8965000000000000',
      low: '26.8401000000000000',
      close: '27.6898000000000000',
      volume: '2059912.1820000000000000',
      quoteVolume: 56521007.48227771,
      btcVolume: 5326.109268024313,
      usdVolume: 56521007.48227771,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '27.6931000000000000',
      high: '27.8153000000000000',
      low: '26.4999000000000000',
      close: '27.7424000000000000',
      volume: '2423081.9940000000000000',
      quoteVolume: 66102858.40443837,
      btcVolume: 6161.524987847587,
      usdVolume: 66102858.40443837,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '27.7342000000000000',
      high: '28.5368000000000000',
      low: '27.4548000000000000',
      close: '28.4076000000000000',
      volume: '2590468.0830000000000000',
      quoteVolume: 72830100.20340902,
      btcVolume: 6638.910063675278,
      usdVolume: 72830100.20340902,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '28.3980000000000000',
      high: '29.2814000000000000',
      low: '28.0666000000000000',
      close: '28.4414000000000000',
      volume: '2405371.4570000000000000',
      quoteVolume: 68983739.73955177,
      btcVolume: 6094.207111159801,
      usdVolume: 68983739.73955177,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '28.4100000000000000',
      high: '28.7200000000000000',
      low: '27.8760000000000000',
      close: '28.5271000000000000',
      volume: '2287361.9230000000000000',
      quoteVolume: 64862236.42497369,
      btcVolume: 5711.44629460792,
      usdVolume: 64862236.42497369,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '28.5200000000000000',
      high: '31.1299000000000000',
      low: '28.4338000000000000',
      close: '30.6503000000000000',
      volume: '4002611.1740000000000000',
      quoteVolume: 120352867.44459017,
      btcVolume: 10544.280084904543,
      usdVolume: 120352867.44459017,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '30.6503000000000000',
      high: '31.7999000000000000',
      low: '30.1870000000000000',
      close: '30.7256000000000000',
      volume: '3052275.4880000000000000',
      quoteVolume: 94547125.33016007,
      btcVolume: 8257.112105221257,
      usdVolume: 94547125.33016007,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '30.7256000000000000',
      high: '31.3300000000000000',
      low: '30.3733000000000000',
      close: '30.8380000000000000',
      volume: '2235892.8320000000000000',
      quoteVolume: 68852635.2776885,
      btcVolume: 6038.336550994363,
      usdVolume: 68852635.2776885,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '30.8380000000000000',
      high: '31.6663000000000000',
      low: '30.3990000000000000',
      close: '31.3256000000000000',
      volume: '2664333.4610000000000000',
      quoteVolume: 82806329.4986107,
      btcVolume: 7257.335240346204,
      usdVolume: 82806329.4986107,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '31.3256000000000000',
      high: '31.9798000000000000',
      low: '29.5705000000000000',
      close: '30.0516000000000000',
      volume: '2971307.3670000000000000',
      quoteVolume: 90779001.72612782,
      btcVolume: 7990.956897298697,
      usdVolume: 90779001.72612782,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '30.0510000000000000',
      high: '30.5640000000000000',
      low: '29.6521000000000000',
      close: '30.2414000000000000',
      volume: '1845150.5060000000000000',
      quoteVolume: 55749675.69959583,
      btcVolume: 4918.479991791265,
      usdVolume: 55749675.69959583,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '30.2423000000000000',
      high: '31.0354000000000000',
      low: '30.1650000000000000',
      close: '30.6593000000000000',
      volume: '2267377.3170000000000000',
      quoteVolume: 69506327.30622022,
      btcVolume: 6086.495770877648,
      usdVolume: 69506327.30622022,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '30.6568000000000000',
      high: '30.6766000000000000',
      low: '29.7002000000000000',
      close: '29.9107000000000000',
      volume: '1752644.9990000000000000',
      quoteVolume: 52880539.760139465,
      btcVolume: 4575.7130204564255,
      usdVolume: 52880539.760139465,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '29.9043000000000000',
      high: '30.0892000000000000',
      low: '28.5000000000000000',
      close: '28.8227000000000000',
      volume: '2583242.4400000000000000',
      quoteVolume: 75672316.51136452,
      btcVolume: 6399.37021187418,
      usdVolume: 75672316.51136452,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '28.8131000000000000',
      high: '30.4074000000000000',
      low: '28.6406000000000000',
      close: '29.7329000000000000',
      volume: '2663615.8890000000000000',
      quoteVolume: 78865122.07201448,
      btcVolume: 6329.800794199335,
      usdVolume: 78865122.07201448,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '29.7355000000000000',
      high: '31.3000000000000000',
      low: '29.6655000000000000',
      close: '30.6396000000000000',
      volume: '2830772.7050000000000000',
      quoteVolume: 86718736.3816204,
      btcVolume: 6732.909811327086,
      usdVolume: 86718736.3816204,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '30.6334000000000000',
      high: '31.2000000000000000',
      low: '30.0652000000000000',
      close: '30.3066000000000000',
      volume: '1751841.2190000000000000',
      quoteVolume: 53822247.52955811,
      btcVolume: 4166.146373136381,
      usdVolume: 53822247.52955811,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '30.6461000000000000',
      high: '30.8781000000000000',
      low: '30.4243000000000000',
      close: '30.6639000000000000',
      volume: '1625502.5240000000000000',
      quoteVolume: 49794483.44654,
      btcVolume: 3828.381409664332,
      usdVolume: 49794483.44654,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '30.6522000000000000',
      high: '30.9726000000000000',
      low: '29.8805000000000000',
      close: '30.1280000000000000',
      volume: '1486018.1520000000000000',
      quoteVolume: 45184192.15742622,
      btcVolume: 3461.2183046942464,
      usdVolume: 45184192.15742622,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '30.1342000000000000',
      high: '31.4300000000000000',
      low: '29.9600000000000000',
      close: '31.0467000000000000',
      volume: '2647734.6210000000000000',
      quoteVolume: 81431799.40994392,
      btcVolume: 6237.364724196646,
      usdVolume: 81431799.40994392,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '31.0436000000000000',
      high: '32.3788000000000000',
      low: '30.9000000000000000',
      close: '31.4826000000000000',
      volume: '2881430.2220000000000000',
      quoteVolume: 91088211.45629856,
      btcVolume: 6829.721068843635,
      usdVolume: 91088211.45629856,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '31.4730000000000000',
      high: '31.9375000000000000',
      low: '29.8000000000000000',
      close: '30.3338000000000000',
      volume: '2052118.9290000000000000',
      quoteVolume: 63425566.100130275,
      btcVolume: 4717.743672827569,
      usdVolume: 63425566.100130275,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '30.3295000000000000',
      high: '30.4272000000000000',
      low: '29.4700000000000000',
      close: '29.8702000000000000',
      volume: '1977911.0400000000000000',
      quoteVolume: 59517443.808163345,
      btcVolume: 4471.350986754041,
      usdVolume: 59517443.808163345,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '29.8696000000000000',
      high: '30.2596000000000000',
      low: '27.5111000000000000',
      close: '28.4909000000000000',
      volume: '3211305.3580000000000000',
      quoteVolume: 91773152.80737089,
      btcVolume: 6865.029260469122,
      usdVolume: 91773152.80737089,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '28.4969000000000000',
      high: '28.8880000000000000',
      low: '28.1132000000000000',
      close: '28.5058000000000000',
      volume: '1975871.5750000000000000',
      quoteVolume: 56382966.93345828,
      btcVolume: 4104.696666980671,
      usdVolume: 56382966.93345828,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '28.5058000000000000',
      high: '28.6790000000000000',
      low: '28.0000000000000000',
      close: '28.5318000000000000',
      volume: '1916131.6810000000000000',
      quoteVolume: 54186354.18994433,
      btcVolume: 3937.006778622533,
      usdVolume: 54186354.18994433,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '28.5485000000000000',
      high: '29.3079000000000000',
      low: '27.7782000000000000',
      close: '27.7983000000000000',
      volume: '2123599.0520000000000000',
      quoteVolume: 60737427.18014957,
      btcVolume: 4463.645604676236,
      usdVolume: 60737427.18014957,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '27.7984000000000000',
      high: '27.7984000000000000',
      low: '25.7499000000000000',
      close: '26.8638000000000000',
      volume: '2591560.8550000000000000',
      quoteVolume: 69103680.17142187,
      btcVolume: 5107.912094364955,
      usdVolume: 69103680.17142187,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '26.8722000000000000',
      high: '26.9700000000000000',
      low: '25.9000000000000000',
      close: '26.9600000000000000',
      volume: '2039985.3400000000000000',
      quoteVolume: 54040159.97019821,
      btcVolume: 3890.820934023125,
      usdVolume: 54040159.97019821,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '26.9607000000000000',
      high: '27.8947000000000000',
      low: '26.6601000000000000',
      close: '27.6875000000000000',
      volume: '2396900.8220000000000000',
      quoteVolume: 65625192.2818929,
      btcVolume: 4465.977407660962,
      usdVolume: 65625192.2818929,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '27.6860000000000000',
      high: '29.4692000000000000',
      low: '27.6668000000000000',
      close: '29.0435000000000000',
      volume: '2705358.2900000000000000',
      quoteVolume: 77745649.67222922,
      btcVolume: 4990.296761515876,
      usdVolume: 77745649.67222922,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '29.0391000000000000',
      high: '29.8990000000000000',
      low: '26.8500000000000000',
      close: '27.7651000000000000',
      volume: '3088858.1960000000000000',
      quoteVolume: 88661320.14317802,
      btcVolume: 5796.6328340812925,
      usdVolume: 88661320.14317802,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '27.7628000000000000',
      high: '28.6943000000000000',
      low: '27.4490000000000000',
      close: '28.4101000000000000',
      volume: '2296984.2220000000000000',
      quoteVolume: 64827072.49203944,
      btcVolume: 4258.489159084323,
      usdVolume: 64827072.49203944,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '28.4101000000000000',
      high: '28.9691000000000000',
      low: '27.3461000000000000',
      close: '28.0941000000000000',
      volume: '2147857.4210000000000000',
      quoteVolume: 60633554.16222215,
      btcVolume: 3946.070465446345,
      usdVolume: 60633554.16222215,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '28.0836000000000000',
      high: '28.4880000000000000',
      low: '27.6114000000000000',
      close: '28.2188000000000000',
      volume: '2172589.6330000000000000',
      quoteVolume: 61108455.63378035,
      btcVolume: 3990.1571339070315,
      usdVolume: 61108455.63378035,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '28.2183000000000000',
      high: '28.5999000000000000',
      low: '27.7966000000000000',
      close: '28.0160000000000000',
      volume: '1974074.3100000000000000',
      quoteVolume: 55629780.26008157,
      btcVolume: 3569.799698588421,
      usdVolume: 55629780.26008157,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '28.0057000000000000',
      high: '28.1599000000000000',
      low: '27.3028000000000000',
      close: '27.6874000000000000',
      volume: '1661489.2860000000000000',
      quoteVolume: 46197453.38885523,
      btcVolume: 2911.5701341389563,
      usdVolume: 46197453.38885523,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '27.7019000000000000',
      high: '28.5198000000000000',
      low: '27.5284000000000000',
      close: '28.1974000000000000',
      volume: '1870570.7210000000000000',
      quoteVolume: 52380431.54643035,
      btcVolume: 3219.0015810697378,
      usdVolume: 52380431.54643035,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '28.1974000000000000',
      high: '28.1974000000000000',
      low: '27.3684000000000000',
      close: '27.9311000000000000',
      volume: '1491728.0070000000000000',
      quoteVolume: 41393342.505827054,
      btcVolume: 2581.1714629579965,
      usdVolume: 41393342.505827054,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '27.9359000000000000',
      high: '28.2332000000000000',
      low: '27.0700000000000000',
      close: '27.6786000000000000',
      volume: '1441384.5610000000000000',
      quoteVolume: 40098284.74564967,
      btcVolume: 2509.6270634778307,
      usdVolume: 40098284.74564967,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '27.6822000000000000',
      high: '28.2202000000000000',
      low: '27.4461000000000000',
      close: '27.9451000000000000',
      volume: '1684972.1740000000000000',
      quoteVolume: 46951053.47256122,
      btcVolume: 2873.554556801199,
      usdVolume: 46951053.47256122,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '27.9588000000000000',
      high: '28.8000000000000000',
      low: '27.9262000000000000',
      close: '28.5552000000000000',
      volume: '2165992.6190000000000000',
      quoteVolume: 61647606.24655668,
      btcVolume: 3620.0821814901874,
      usdVolume: 61647606.24655668,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '28.5552000000000000',
      high: '28.8380000000000000',
      low: '27.5000000000000000',
      close: '28.0931000000000000',
      volume: '2208116.0360000000000000',
      quoteVolume: 62179324.88033911,
      btcVolume: 3481.8660558290812,
      usdVolume: 62179324.88033911,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '28.1049000000000000',
      high: '28.3666000000000000',
      low: '27.6183000000000000',
      close: '28.1387000000000000',
      volume: '1978784.7070000000000000',
      quoteVolume: 55532045.828950375,
      btcVolume: 3111.2806511064537,
      usdVolume: 55532045.828950375,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '28.1266000000000000',
      high: '29.3516000000000000',
      low: '28.0598000000000000',
      close: '28.9715000000000000',
      volume: '2462083.4030000000000000',
      quoteVolume: 70582295.03196461,
      btcVolume: 3855.968113643725,
      usdVolume: 70582295.03196461,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '28.9722000000000000',
      high: '30.8000000000000000',
      low: '28.2955000000000000',
      close: '30.5161000000000000',
      volume: '3160335.4720000000000000',
      quoteVolume: 93653968.66348147,
      btcVolume: 5004.286317743498,
      usdVolume: 93653968.66348147,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '30.5168000000000000',
      high: '31.1200000000000000',
      low: '29.0000000000000000',
      close: '30.0226000000000000',
      volume: '2842370.7830000000000000',
      quoteVolume: 85423529.80392088,
      btcVolume: 4642.671182761763,
      usdVolume: 85423529.80392088,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '30.0291000000000000',
      high: '31.1000000000000000',
      low: '29.7860000000000000',
      close: '30.9950000000000000',
      volume: '2854700.3560000000000000',
      quoteVolume: 86945280.82502285,
      btcVolume: 4722.192647593682,
      usdVolume: 86945280.82502285,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '30.9994000000000000',
      high: '35.1400000000000000',
      low: '30.7934000000000000',
      close: '33.9407000000000000',
      volume: '7296367.3690000000000000',
      quoteVolume: 240609850.8251657,
      btcVolume: 12879.90776771163,
      usdVolume: 240609850.8251657,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '33.9451000000000000',
      high: '35.4338000000000000',
      low: '30.4390000000000000',
      close: '31.2953000000000000',
      volume: '4579889.4800000000000000',
      quoteVolume: 152154802.22398162,
      btcVolume: 7996.9340517331775,
      usdVolume: 152154802.22398162,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '31.2950000000000000',
      high: '31.7708000000000000',
      low: '26.3500000000000000',
      close: '28.2356000000000000',
      volume: '6241853.0130000000000000',
      quoteVolume: 179684915.43192223,
      btcVolume: 10317.064916775644,
      usdVolume: 179684915.43192223,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '28.2354000000000000',
      high: '28.7175000000000000',
      low: '27.2042000000000000',
      close: '28.4606000000000000',
      volume: '2317359.7950000000000000',
      quoteVolume: 65177587.384269916,
      btcVolume: 3831.3766146767975,
      usdVolume: 65177587.384269916,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '28.4606000000000000',
      high: '29.6175000000000000',
      low: '27.9000000000000000',
      close: '29.1766000000000000',
      volume: '2636604.6020000000000000',
      quoteVolume: 76332488.51250012,
      btcVolume: 4378.493625971485,
      usdVolume: 76332488.51250012,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '29.1552000000000000',
      high: '30.4500000000000000',
      low: '28.8544000000000000',
      close: '30.1045000000000000',
      volume: '2856408.5640000000000000',
      quoteVolume: 84710614.21822041,
      btcVolume: 4707.608681471879,
      usdVolume: 84710614.21822041,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '30.1003000000000000',
      high: '31.5999000000000000',
      low: '29.7230000000000000',
      close: '31.4935000000000000',
      volume: '2977355.3390000000000000',
      quoteVolume: 91514232.68682995,
      btcVolume: 4817.517391122217,
      usdVolume: 91514232.68682995,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '31.4935000000000000',
      high: '31.8875000000000000',
      low: '29.1700000000000000',
      close: '30.1996000000000000',
      volume: '3066237.6600000000000000',
      quoteVolume: 94399139.74031925,
      btcVolume: 4914.238089053225,
      usdVolume: 94399139.74031925,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '30.2003000000000000',
      high: '30.9993000000000000',
      low: '29.5306000000000000',
      close: '30.2875000000000000',
      volume: '2360296.3080000000000000',
      quoteVolume: 71525356.8507045,
      btcVolume: 3776.942602662899,
      usdVolume: 71525356.8507045,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '30.2724000000000000',
      high: '30.7930000000000000',
      low: '29.8568000000000000',
      close: '30.7065000000000000',
      volume: '1924462.4660000000000000',
      quoteVolume: 58447530.68081103,
      btcVolume: 3036.9154023239544,
      usdVolume: 58447530.68081103,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '30.7099000000000000',
      high: '31.1371000000000000',
      low: '28.7051000000000000',
      close: '29.0231000000000000',
      volume: '2064562.0540000000000000',
      quoteVolume: 61840304.01373671,
      btcVolume: 3237.434025284598,
      usdVolume: 61840304.01373671,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '29.0315000000000000',
      high: '30.0588000000000000',
      low: '28.6588000000000000',
      close: '29.7280000000000000',
      volume: '1836006.3170000000000000',
      quoteVolume: 53868352.508677736,
      btcVolume: 2845.6790819435187,
      usdVolume: 53868352.508677736,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '29.7296000000000000',
      high: '30.1819000000000000',
      low: '29.0124000000000000',
      close: '29.5026000000000000',
      volume: '1330813.8990000000000000',
      quoteVolume: 39419545.068958886,
      btcVolume: 2055.588312615177,
      usdVolume: 39419545.068958886,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '29.4912000000000000',
      high: '29.6685000000000000',
      low: '29.0600000000000000',
      close: '29.3700000000000000',
      volume: '1219543.1660000000000000',
      quoteVolume: 35795857.65738368,
      btcVolume: 1864.6156139549516,
      usdVolume: 35795857.65738368,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '29.3705000000000000',
      high: '29.4998000000000000',
      low: '27.4500000000000000',
      close: '27.6705000000000000',
      volume: '1844102.0560000000000000',
      quoteVolume: 52562094.302194595,
      btcVolume: 2782.158122499107,
      usdVolume: 52562094.302194595,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '27.6705000000000000',
      high: '28.3853000000000000',
      low: '26.9244000000000000',
      close: '28.1452000000000000',
      volume: '1635344.1810000000000000',
      quoteVolume: 45454697.5386892,
      btcVolume: 2491.802163982006,
      usdVolume: 45454697.5386892,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '28.1440000000000000',
      high: '28.2997000000000000',
      low: '27.3900000000000000',
      close: '27.6926000000000000',
      volume: '1338821.6330000000000000',
      quoteVolume: 37239316.75626387,
      btcVolume: 2036.0163366628099,
      usdVolume: 37239316.75626387,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '27.6950000000000000',
      high: '27.7700000000000000',
      low: '26.7273000000000000',
      close: '27.3419000000000000',
      volume: '1185571.0640000000000000',
      quoteVolume: 32228789.764525007,
      btcVolume: 1796.9005359462299,
      usdVolume: 32222415.98784421,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '27.3490000000000000',
      high: '28.2300000000000000',
      low: '27.2542000000000000',
      close: '28.1603000000000000',
      volume: '1209119.9800000000000000',
      quoteVolume: 33698868.29087121,
      btcVolume: 1826.7844744499994,
      usdVolume: 33698868.29087121,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '28.1603000000000000',
      high: '29.2628000000000000',
      low: '28.0000000000000000',
      close: '29.1571000000000000',
      volume: '1639495.8160000000000000',
      quoteVolume: 47146012.390196115,
      btcVolume: 2467.453865154321,
      usdVolume: 47146012.390196115,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '29.1599000000000000',
      high: '30.1884000000000000',
      low: '28.6669000000000000',
      close: '30.0338000000000000',
      volume: '2025868.5440000000000000',
      quoteVolume: 59594740.44459001,
      btcVolume: 3107.6269302330743,
      usdVolume: 59594740.44459001,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '30.0338000000000000',
      high: '30.3790000000000000',
      low: '29.3069000000000000',
      close: '29.5053000000000000',
      volume: '1536633.4260000000000000',
      quoteVolume: 45782401.5646542,
      btcVolume: 2367.206132560912,
      usdVolume: 45782401.5646542,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '29.5059000000000000',
      high: '30.6140000000000000',
      low: '29.0265000000000000',
      close: '30.5577000000000000',
      volume: '2034306.1120000000000000',
      quoteVolume: 60707183.55854131,
      btcVolume: 2993.371881466091,
      usdVolume: 60707183.55854131,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '30.5524000000000000',
      high: '31.5489000000000000',
      low: '29.6891000000000000',
      close: '30.2255000000000000',
      volume: '3274786.1310000000000000',
      quoteVolume: 100846680.86741851,
      btcVolume: 4478.236654440235,
      usdVolume: 100846680.86741851,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '30.2254000000000000',
      high: '31.2172000000000000',
      low: '29.6100000000000000',
      close: '30.9882000000000000',
      volume: '2045132.3370000000000000',
      quoteVolume: 62437403.31907742,
      btcVolume: 2727.6635487497656,
      usdVolume: 62437403.31907742,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '30.9858000000000000',
      high: '34.2307000000000000',
      low: '30.8812000000000000',
      close: '33.3167000000000000',
      volume: '4117460.2930000000000000',
      quoteVolume: 134985674.6489003,
      btcVolume: 5801.552675700171,
      usdVolume: 134985674.6489003,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '33.3018000000000000',
      high: '35.6900000000000000',
      low: '32.5899000000000000',
      close: '34.3148000000000000',
      volume: '4477498.8180000000000000',
      quoteVolume: 153515421.81364313,
      btcVolume: 6509.728499602096,
      usdVolume: 153515421.81364313,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '34.3072000000000000',
      high: '35.6000000000000000',
      low: '31.4871000000000000',
      close: '32.0500000000000000',
      volume: '4525705.7910000000000000',
      quoteVolume: 151775639.78318813,
      btcVolume: 6527.479468708382,
      usdVolume: 151775639.78318813,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '32.0500000000000000',
      high: '33.8000000000000000',
      low: '30.4719000000000000',
      close: '33.5151000000000000',
      volume: '3563635.6730000000000000',
      quoteVolume: 115167307.80793788,
      btcVolume: 5006.055644717137,
      usdVolume: 115167307.80793788,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '33.5112000000000000',
      high: '34.5050000000000000',
      low: '30.0000000000000000',
      close: '31.1843000000000000',
      volume: '3940926.4420000000000000',
      quoteVolume: 128877133.69173539,
      btcVolume: 5483.664774174263,
      usdVolume: 128877133.69173539,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '31.1698000000000000',
      high: '32.7589000000000000',
      low: '30.0000000000000000',
      close: '32.4895000000000000',
      volume: '2999570.8530000000000000',
      quoteVolume: 94739220.62729084,
      btcVolume: 4084.295268120996,
      usdVolume: 94739220.62729084,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '32.4947000000000000',
      high: '33.4954000000000000',
      low: '31.7313000000000000',
      close: '33.1996000000000000',
      volume: '2595633.3640000000000000',
      quoteVolume: 84823958.91861957,
      btcVolume: 3526.3258018350043,
      usdVolume: 84823958.91861957,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '33.1996000000000000',
      high: '34.0495000000000000',
      low: '32.5100000000000000',
      close: '33.6361000000000000',
      volume: '2418941.5440000000000000',
      quoteVolume: 80768632.43812405,
      btcVolume: 3199.458679420725,
      usdVolume: 80768632.43812405,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '33.6510000000000000',
      high: '34.9900000000000000',
      low: '31.8601000000000000',
      close: '33.6740000000000000',
      volume: '3747872.7180000000000000',
      quoteVolume: 126569474.2263926,
      btcVolume: 4663.94819780864,
      usdVolume: 126569474.2263926,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '33.6741000000000000',
      high: '36.1500000000000000',
      low: '33.3105000000000000',
      close: '35.9510000000000000',
      volume: '4121783.1030000000000000',
      quoteVolume: 145163638.0339237,
      btcVolume: 5381.500496375316,
      usdVolume: 145163638.0339237,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '35.9507000000000000',
      high: '39.9999000000000000',
      low: '35.3015000000000000',
      close: '39.0667000000000000',
      volume: '7022548.4870000000000000',
      quoteVolume: 266651443.80075303,
      btcVolume: 10020.386760643316,
      usdVolume: 266651443.80075303,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '39.0746000000000000',
      high: '39.2700000000000000',
      low: '36.8131000000000000',
      close: '38.1558000000000000',
      volume: '2424623.4670000000000000',
      quoteVolume: 91497596.04163969,
      btcVolume: 3260.3633178562527,
      usdVolume: 91497596.04163969,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '38.1558000000000000',
      high: '38.2133000000000000',
      low: '36.5157000000000000',
      close: '37.3588000000000000',
      volume: '1887715.8350000000000000',
      quoteVolume: 70486125.96259664,
      btcVolume: 2446.931071296564,
      usdVolume: 70486125.96259664,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '37.3596000000000000',
      high: '38.9590000000000000',
      low: '36.9555000000000000',
      close: '37.7762000000000000',
      volume: '2433482.2970000000000000',
      quoteVolume: 92264806.14913891,
      btcVolume: 3158.8112751452077,
      usdVolume: 92264806.14913891,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '37.7637000000000000',
      high: '38.8798000000000000',
      low: '36.8000000000000000',
      close: '38.2331000000000000',
      volume: '3018062.2060000000000000',
      quoteVolume: 114060050.8496273,
      btcVolume: 3673.549570252766,
      usdVolume: 114060050.8496273,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '38.2331000000000000',
      high: '41.7017000000000000',
      low: '37.7630000000000000',
      close: '41.2575000000000000',
      volume: '4903626.6970000000000000',
      quoteVolume: 195224916.82053122,
      btcVolume: 5801.770817073169,
      usdVolume: 195224916.82053122,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '41.2560000000000000',
      high: '43.2039000000000000',
      low: '38.0200000000000000',
      close: '41.1333000000000000',
      volume: '5306248.1660000000000000',
      quoteVolume: 214835112.0872529,
      btcVolume: 6762.2209205324225,
      usdVolume: 214835112.0872529,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '41.1300000000000000',
      high: '41.8375000000000000',
      low: '38.8014000000000000',
      close: '41.8219000000000000',
      volume: '3513013.9910000000000000',
      quoteVolume: 141826591.3171311,
      btcVolume: 4386.074240907546,
      usdVolume: 141826591.3171311,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '41.8271000000000000',
      high: '42.2670000000000000',
      low: '40.4531000000000000',
      close: '42.2417000000000000',
      volume: '3784356.4310000000000000',
      quoteVolume: 156400986.59603542,
      btcVolume: 4502.298574139992,
      usdVolume: 156400986.59603542,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '42.2440000000000000',
      high: '44.9000000000000000',
      low: '41.3660000000000000',
      close: '43.5728000000000000',
      volume: '5010228.3040000000000000',
      quoteVolume: 216002626.83736652,
      btcVolume: 5709.586809164074,
      usdVolume: 216002626.83736652,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '43.5728000000000000',
      high: '43.7220000000000000',
      low: '40.2313000000000000',
      close: '42.3560000000000000',
      volume: '3545616.4530000000000000',
      quoteVolume: 149813003.67615464,
      btcVolume: 3783.9907502632377,
      usdVolume: 149813003.67615464,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '42.3450000000000000',
      high: '44.0552000000000000',
      low: '41.5000000000000000',
      close: '43.8479000000000000',
      volume: '2720363.6360000000000000',
      quoteVolume: 116290473.53861824,
      btcVolume: 2885.4307702783954,
      usdVolume: 116290473.53861824,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '43.8479000000000000',
      high: '45.1620000000000000',
      low: '40.0000000000000000',
      close: '42.4031000000000000',
      volume: '4276146.4430000000000000',
      quoteVolume: 185111910.32453433,
      btcVolume: 4685.988559488082,
      usdVolume: 185111910.32453433,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '42.4033000000000000',
      high: '42.5094000000000000',
      low: '35.0374000000000000',
      close: '38.1674000000000000',
      volume: '6282218.7140000000000000',
      quoteVolume: 241222935.06371692,
      btcVolume: 7090.358330981841,
      usdVolume: 241222935.06371692,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '38.1623000000000000',
      high: '40.1989000000000000',
      low: '37.0000000000000000',
      close: '38.2541000000000000',
      volume: '2967129.8990000000000000',
      quoteVolume: 114541059.98171715,
      btcVolume: 3279.5902550381034,
      usdVolume: 114541059.98171715,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '38.2703000000000000',
      high: '40.4499000000000000',
      low: '37.0200000000000000',
      close: '40.1114000000000000',
      volume: '3456796.7670000000000000',
      quoteVolume: 133704300.47653846,
      btcVolume: 3841.1434871509555,
      usdVolume: 133704300.47653846,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '40.1114000000000000',
      high: '42.0000000000000000',
      low: '39.5739000000000000',
      close: '41.8723000000000000',
      volume: '3306689.9330000000000000',
      quoteVolume: 135085613.30355036,
      btcVolume: 3524.8729501905395,
      usdVolume: 135085613.30355036,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '41.8721000000000000',
      high: '42.5622000000000000',
      low: '38.6616000000000000',
      close: '40.8668000000000000',
      volume: '3239226.4660000000000000',
      quoteVolume: 132807501.32071899,
      btcVolume: 3551.40548928815,
      usdVolume: 132807501.32071899,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '40.8951000000000000',
      high: '44.1200000000000000',
      low: '40.6250000000000000',
      close: '43.1420000000000000',
      volume: '3033653.3580000000000000',
      quoteVolume: 129106934.07833531,
      btcVolume: 3495.5013833004346,
      usdVolume: 129106934.07833531,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '43.1413000000000000',
      high: '46.7228000000000000',
      low: '40.9192000000000000',
      close: '45.8675000000000000',
      volume: '3878914.2990000000000000',
      quoteVolume: 169754085.7227777,
      btcVolume: 4749.843457538563,
      usdVolume: 169754085.7227777,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '45.8645000000000000',
      high: '46.8888000000000000',
      low: '44.0278000000000000',
      close: '45.3412000000000000',
      volume: '3069027.8490000000000000',
      quoteVolume: 139380249.91174093,
      btcVolume: 3868.6820587698926,
      usdVolume: 139380249.91174093,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '45.3592000000000000',
      high: '47.2187000000000000',
      low: '41.7000000000000000',
      close: '42.5241000000000000',
      volume: '5142469.4010000000000000',
      quoteVolume: 229041236.3572534,
      btcVolume: 6215.5026986066505,
      usdVolume: 229041236.3572534,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '42.5241000000000000',
      high: '43.0992000000000000',
      low: '40.2000000000000000',
      close: '42.6069000000000000',
      volume: '3707024.5780000000000000',
      quoteVolume: 154379904.5654336,
      btcVolume: 4404.916420772621,
      usdVolume: 154379904.5654336,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '42.6056000000000000',
      high: '42.7176000000000000',
      low: '38.2000000000000000',
      close: '38.6343000000000000',
      volume: '3460575.2420000000000000',
      quoteVolume: 140161330.8816147,
      btcVolume: 4278.077907472615,
      usdVolume: 140161330.8816147,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '38.6596000000000000',
      high: '41.5280000000000000',
      low: '36.8518000000000000',
      close: '40.8936000000000000',
      volume: '3045177.7070000000000000',
      quoteVolume: 120441457.89852692,
      btcVolume: 3837.1422348553915,
      usdVolume: 120441457.89852692,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '40.8938000000000000',
      high: '41.8800000000000000',
      low: '39.4555000000000000',
      close: '40.9109000000000000',
      volume: '2278214.3590000000000000',
      quoteVolume: 92739441.75976337,
      btcVolume: 2865.448972787932,
      usdVolume: 92739441.75976337,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '40.9113000000000000',
      high: '42.2000000000000000',
      low: '40.3144000000000000',
      close: '41.8263000000000000',
      volume: '2340747.6230000000000000',
      quoteVolume: 97017133.59785114,
      btcVolume: 3005.7392112630923,
      usdVolume: 97017133.59785114,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '41.8270000000000000',
      high: '43.4241000000000000',
      low: '40.5364000000000000',
      close: '41.1687000000000000',
      volume: '2518182.3520000000000000',
      quoteVolume: 106660129.78203705,
      btcVolume: 3193.7435879782656,
      usdVolume: 106660129.78203705,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '40.6902000000000000',
      high: '41.9990000000000000',
      low: '40.6495000000000000',
      close: '41.8396000000000000',
      volume: '569370.8780000000000000',
      quoteVolume: 23434513.457918085,
      btcVolume: 727.6668268505232,
      usdVolume: 23434513.457918085,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '41.8397000000000000',
      high: '42.0984000000000000',
      low: '39.6102000000000000',
      close: '40.8826000000000000',
      volume: '2436860.8560000000000000',
      quoteVolume: 99920228.95624547,
      btcVolume: 3215.2310783315247,
      usdVolume: 99920228.95624547,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '40.8957000000000000',
      high: '43.0850000000000000',
      low: '40.3170000000000000',
      close: '42.5254000000000000',
      volume: '2696452.7010000000000000',
      quoteVolume: 113671095.32156354,
      btcVolume: 3581.5448326085157,
      usdVolume: 113671095.32156354,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '42.5277000000000000',
      high: '44.0000000000000000',
      low: '41.8017000000000000',
      close: '42.8618000000000000',
      volume: '3128425.9590000000000000',
      quoteVolume: 134263033.31381023,
      btcVolume: 3835.6490017488704,
      usdVolume: 134263033.31381023,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '42.8653000000000000',
      high: '45.1071000000000000',
      low: '42.5911000000000000',
      close: '44.7849000000000000',
      volume: '2684982.5910000000000000',
      quoteVolume: 118195712.16575812,
      btcVolume: 3475.2585019044272,
      usdVolume: 118195712.16575812,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '44.8022000000000000',
      high: '45.8269000000000000',
      low: '43.3000000000000000',
      close: '44.3292000000000000',
      volume: '2275614.1730000000000000',
      quoteVolume: 101023763.14275552,
      btcVolume: 3017.434459535125,
      usdVolume: 101023763.14275552,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '44.3291000000000000',
      high: '52.3736000000000000',
      low: '43.4331000000000000',
      close: '51.4781000000000000',
      volume: '6050100.3010000000000000',
      quoteVolume: 292440579.7585255,
      btcVolume: 8664.193157549942,
      usdVolume: 292440579.7585255,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '51.4762000000000000',
      high: '53.0000000000000000',
      low: '49.1572000000000000',
      close: '51.0071000000000000',
      volume: '4671921.8650000000000000',
      quoteVolume: 237462302.3806651,
      btcVolume: 6853.326195790986,
      usdVolume: 237462302.3806651,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '51.0290000000000000',
      high: '52.7000000000000000',
      low: '49.7823000000000000',
      close: '52.1200000000000000',
      volume: '2949735.0360000000000000',
      quoteVolume: 150776117.80316824,
      btcVolume: 4132.7211784957935,
      usdVolume: 150776117.80316824,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '52.1200000000000000',
      high: '57.0547000000000000',
      low: '51.2500000000000000',
      close: '55.9599000000000000',
      volume: '4004728.2130000000000000',
      quoteVolume: 215001574.4533509,
      btcVolume: 5736.324770655654,
      usdVolume: 215001574.4533509,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '55.9593000000000000',
      high: '68.7300000000000000',
      low: '55.9202000000000000',
      close: '67.7005000000000000',
      volume: '5033214.7830000000000000',
      quoteVolume: 306083346.7708751,
      btcVolume: 8127.166722306979,
      usdVolume: 306083346.7708751,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '67.6217000000000000',
      high: '75.5000000000000000',
      low: '61.7000000000000000',
      close: '72.8356000000000000',
      volume: '8481923.5090000000000000',
      quoteVolume: 595939416.8906882,
      btcVolume: 15020.336224524533,
      usdVolume: 595939416.8906882,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '72.8693000000000000',
      high: '74.8199000000000000',
      low: '64.1803000000000000',
      close: '68.3542000000000000',
      volume: '5969908.4420000000000000',
      quoteVolume: 413359634.3580266,
      btcVolume: 10704.071358881154,
      usdVolume: 413359634.3580266,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '68.3550000000000000',
      high: '79.8823000000000000',
      low: '67.6152000000000000',
      close: '79.8374000000000000',
      volume: '7849448.2670000000000000',
      quoteVolume: 573011026.0739279,
      btcVolume: 14013.22524313177,
      usdVolume: 573011026.0739279,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '79.8734000000000000',
      high: '116.2400000000000000',
      low: '78.0381000000000000',
      close: '107.3469000000000000',
      volume: '12487305.8680000000000000',
      quoteVolume: 1222317019.460528,
      btcVolume: 26192.431070371436,
      usdVolume: 1222317019.460528,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '107.3496000000000000',
      high: '148.4000000000000000',
      low: '107.2811000000000000',
      close: '128.9711000000000000',
      volume: '16613534.2740000000000000',
      quoteVolume: 2139218096.5095875,
      btcVolume: 46624.18677740852,
      usdVolume: 2138674058.9751093,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '128.9463000000000000',
      high: '132.8000000000000000',
      low: '117.7289000000000000',
      close: '124.0850000000000000',
      volume: '5912684.9520000000000000',
      quoteVolume: 736389166.0586556,
      btcVolume: 15887.557250176676,
      usdVolume: 736389166.0586556,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '124.1129000000000000',
      high: '141.3200000000000000',
      low: '120.8900000000000000',
      close: '136.6139000000000000',
      volume: '5624105.4490000000000000',
      quoteVolume: 739818687.5567763,
      btcVolume: 15573.408733004304,
      usdVolume: 739734387.6189897,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '136.7321000000000000',
      high: '138.5067000000000000',
      low: '125.0000000000000000',
      close: '133.3496000000000000',
      volume: '3785723.4020000000000000',
      quoteVolume: 497454132.3754859,
      btcVolume: 10550.521716952037,
      usdVolume: 497442916.39831746,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '133.3498000000000000',
      high: '139.0000000000000000',
      low: '128.4615000000000000',
      close: '135.8623000000000000',
      volume: '4149634.1350000000000000',
      quoteVolume: 553817439.5471048,
      btcVolume: 11381.352118436944,
      usdVolume: 553817439.5471048,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '135.8623000000000000',
      high: '139.3690000000000000',
      low: '118.0000000000000000',
      close: '130.3030000000000000',
      volume: '4726200.1690000000000000',
      quoteVolume: 610939861.0625807,
      btcVolume: 12798.537422520802,
      usdVolume: 608891488.1794219,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '129.9493000000000000',
      high: '133.4925000000000000',
      low: '126.0000000000000000',
      close: '130.1830000000000000',
      volume: '3253115.9180000000000000',
      quoteVolume: 422971528.8945765,
      btcVolume: 8473.804158435485,
      usdVolume: 413215114.4048722,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '130.1830000000000000',
      high: '175.9997000000000000',
      low: '126.7546000000000000',
      close: '165.3233000000000000',
      volume: '10095433.2290000000000000',
      quoteVolume: 1529265397.2071304,
      btcVolume: 29733.66977060965,
      usdVolume: 1529265397.2071304,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '165.3176000000000000',
      high: '199.8800000000000000',
      low: '162.7969000000000000',
      close: '195.6000000000000000',
      volume: '11226893.3730000000000000',
      quoteVolume: 2093727364.8791077,
      btcVolume: 40384.559838438676,
      usdVolume: 2093727364.8791077,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '195.5549000000000000',
      high: '348.6969000000000000',
      low: '194.9279000000000000',
      close: '333.1110000000000000',
      volume: '20177086.7240000000000000',
      quoteVolume: 5524988273.532031,
      btcVolume: 104550.88500921716,
      usdVolume: 5524988273.532031,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '333.2212000000000000',
      high: '335.0000000000000000',
      low: '223.0000000000000000',
      close: '253.0000000000000000',
      volume: '13493080.4310000000000000',
      quoteVolume: 3802302297.1180034,
      btcVolume: 67696.97645332622,
      usdVolume: 3802255523.1218314,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '253.0100000000000000',
      high: '309.4995000000000000',
      low: '249.7044000000000000',
      close: '295.2597000000000000',
      volume: '10646422.3070000000000000',
      quoteVolume: 3016515097.588496,
      btcVolume: 53004.9096656135,
      usdVolume: 3016515097.588496,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '294.9167000000000000',
      high: '296.0000000000000000',
      low: '220.5000000000000000',
      close: '267.5147000000000000',
      volume: '11090199.9140000000000000',
      quoteVolume: 2901115898.821675,
      btcVolume: 51481.76655921517,
      usdVolume: 2776214823.2922387,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '267.1721000000000000',
      high: '268.8000000000000000',
      low: '188.8195000000000000',
      close: '231.3646000000000000',
      volume: '14894899.8590000000000000',
      quoteVolume: 3323972236.903524,
      btcVolume: 67971.97939281193,
      usdVolume: 3306278943.257356,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '231.3646000000000000',
      high: '281.0000000000000000',
      low: '213.0257000000000000',
      close: '254.5056000000000000',
      volume: '9115851.7520000000000000',
      quoteVolume: 2299513536.8925033,
      btcVolume: 45958.25770110001,
      usdVolume: 2299401919.088233,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '254.3826000000000000',
      high: '265.4014000000000000',
      low: '231.5000000000000000',
      close: '234.9146000000000000',
      volume: '6871992.7940000000000000',
      quoteVolume: 1718504824.021201,
      btcVolume: 34352.22898526557,
      usdVolume: 1718504824.021201,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '234.7605000000000000',
      high: '239.4000000000000000',
      low: '209.1050000000000000',
      close: '221.7170000000000000',
      volume: '7410706.3850000000000000',
      quoteVolume: 1673740236.886314,
      btcVolume: 35915.41505900414,
      usdVolume: 1672629199.376214,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '221.9592000000000000',
      high: '237.7700000000000000',
      low: '219.0110000000000000',
      close: '225.3853000000000000',
      volume: '4192697.1520000000000000',
      quoteVolume: 956073854.7960905,
      btcVolume: 20310.25188922906,
      usdVolume: 956073854.7960905,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '225.4567000000000000',
      high: '229.4000000000000000',
      low: '195.0000000000000000',
      close: '210.2138000000000000',
      volume: '5582387.4930000000000000',
      quoteVolume: 1169488220.755494,
      btcVolume: 26195.665345367866,
      usdVolume: 1169488220.755494,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '210.1177000000000000',
      high: '260.0000000000000000',
      low: '209.1204000000000000',
      close: '254.9637000000000000',
      volume: '7696473.1020000000000000',
      quoteVolume: 1861423426.5445766,
      btcVolume: 38996.01528997843,
      usdVolume: 1861423426.5445766,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '254.9486000000000000',
      high: '264.9000000000000000',
      low: '227.0000000000000000',
      close: '239.6841000000000000',
      volume: '5386013.7940000000000000',
      quoteVolume: 1322863990.4305365,
      btcVolume: 27229.610061705047,
      usdVolume: 1322863990.4305365,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '239.7204000000000000',
      high: '254.8481000000000000',
      low: '236.0020000000000000',
      close: '240.5701000000000000',
      volume: '4213922.8000000000000000',
      quoteVolume: 1038796903.290087,
      btcVolume: 20561.198898104394,
      usdVolume: 1038796903.290087,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '240.4801000000000000',
      high: '249.9885000000000000',
      low: '225.5308000000000000',
      close: '229.6369000000000000',
      volume: '3999983.8360000000000000',
      quoteVolume: 951344257.2707027,
      btcVolume: 19254.739130732472,
      usdVolume: 950963632.5327258,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '229.6369000000000000',
      high: '232.2000000000000000',
      low: '215.6439000000000000',
      close: '225.4714000000000000',
      volume: '3654842.8050000000000000',
      quoteVolume: 816218663.6047677,
      btcVolume: 17130.43983808357,
      usdVolume: 816218663.6047677,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '225.5391000000000000',
      high: '232.4996000000000000',
      low: '219.1514000000000000',
      close: '226.3814000000000000',
      volume: '2540451.2780000000000000',
      quoteVolume: 572783201.0439407,
      btcVolume: 11728.787027795814,
      usdVolume: 566811264.0986007,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '226.3812000000000000',
      high: '243.9500000000000000',
      low: '224.9542000000000000',
      close: '240.1100000000000000',
      volume: '3519962.9290000000000000',
      quoteVolume: 825683976.9668484,
      btcVolume: 16392.968709891094,
      usdVolume: 825683976.9668484,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '240.1039000000000000',
      high: '243.0986000000000000',
      low: '228.7029000000000000',
      close: '240.5509000000000000',
      volume: '2974749.0600000000000000',
      quoteVolume: 700594381.5475962,
      btcVolume: 13790.190438705486,
      usdVolume: 700594381.5475962,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '240.7852000000000000',
      high: '297.2962000000000000',
      low: '235.6001000000000000',
      close: '293.2052000000000000',
      volume: '7349183.8380000000000000',
      quoteVolume: 1967801660.5323133,
      btcVolume: 36388.01138608123,
      usdVolume: 1967801660.5323133,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '293.1305000000000000',
      high: '295.5000000000000000',
      low: '263.8184000000000000',
      close: '278.4499000000000000',
      volume: '5653640.2100000000000000',
      quoteVolume: 1590670836.0511584,
      btcVolume: 28815.964844856844,
      usdVolume: 1590533270.0117154,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '278.3522000000000000',
      high: '315.2855000000000000',
      low: '272.1200000000000000',
      close: '290.0301000000000000',
      volume: '6597122.9060000000000000',
      quoteVolume: 1920751069.112857,
      btcVolume: 34050.75363516682,
      usdVolume: 1920751069.112857,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '289.7860000000000000',
      high: '290.5000000000000000',
      low: '247.5547000000000000',
      close: '264.2515000000000000',
      volume: '5733513.3280000000000000',
      quoteVolume: 1549981975.0642865,
      btcVolume: 18321.480693181016,
      usdVolume: 1039794507.6320997,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '260.1943000000000000',
      high: '284.2857000000000000',
      low: '255.4206000000000000',
      close: '276.2822000000000000',
      volume: '3870240.0390000000000000',
      quoteVolume: 1055956835.0145825,
      btcVolume: 17918.280534933638,
      usdVolume: 1055956835.0145825,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '276.2892000000000000',
      high: '276.8847000000000000',
      low: '263.0003000000000000',
      close: '263.2380000000000000',
      volume: '2426314.6890000000000000',
      quoteVolume: 655148936.6244737,
      btcVolume: 10820.477528826821,
      usdVolume: 654442187.9202651,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '263.3500000000000000',
      high: '267.7453000000000000',
      low: '249.5327000000000000',
      close: '254.5144000000000000',
      volume: '3362729.4940000000000000',
      quoteVolume: 865777537.7956617,
      btcVolume: 15099.63548641119,
      usdVolume: 865777537.7956617,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '254.3894000000000000',
      high: '259.7847000000000000',
      low: '240.1990000000000000',
      close: '258.5352000000000000',
      volume: '2873578.7750000000000000',
      quoteVolume: 724203640.2983155,
      btcVolume: 13111.127043243541,
      usdVolume: 723730256.9268228,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '258.5611000000000000',
      high: '272.1787000000000000',
      low: '248.2001000000000000',
      close: '269.7896000000000000',
      volume: '2840597.4750000000000000',
      quoteVolume: 730345547.8462642,
      btcVolume: 12972.881070043122,
      usdVolume: 730345547.8462642,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '269.8578000000000000',
      high: '277.8000000000000000',
      low: '257.0100000000000000',
      close: '261.4816000000000000',
      volume: '3151815.5090000000000000',
      quoteVolume: 849421790.8761196,
      btcVolume: 14510.926635933718,
      usdVolume: 849416699.5951898,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '261.5157000000000000',
      high: '269.0000000000000000',
      low: '255.0000000000000000',
      close: '262.9350000000000000',
      volume: '2162339.2940000000000000',
      quoteVolume: 571656223.3764387,
      btcVolume: 9795.650695015467,
      usdVolume: 570353586.1848109,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '263.1083000000000000',
      high: '274.6805000000000000',
      low: '261.4000000000000000',
      close: '264.3023000000000000',
      volume: '2167591.3950000000000000',
      quoteVolume: 581172465.4111629,
      btcVolume: 9881.756043643418,
      usdVolume: 581150401.0430868,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '264.2213000000000000',
      high: '268.0305000000000000',
      low: '257.0001000000000000',
      close: '264.6513000000000000',
      volume: '1969870.0700000000000000',
      quoteVolume: 519208676.9906088,
      btcVolume: 9066.830743937264,
      usdVolume: 518831620.6721869,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '264.7152000000000000',
      high: '277.0000000000000000',
      low: '252.9164000000000000',
      close: '254.7976000000000000',
      volume: '3264577.5660000000000000',
      quoteVolume: 867106025.9929574,
      btcVolume: 15341.116908069982,
      usdVolume: 866971561.9590058,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '254.9689000000000000',
      high: '262.9700000000000000',
      low: '251.8380000000000000',
      close: '255.4646000000000000',
      volume: '2572459.8170000000000000',
      quoteVolume: 663206251.7188904,
      btcVolume: 8225.60529805851,
      usdVolume: 447969721.01421326,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '255.5191000000000000',
      high: '268.7062000000000000',
      low: '240.3333000000000000',
      close: '248.5236000000000000',
      volume: '3077671.5470000000000000',
      quoteVolume: 793614390.5674702,
      btcVolume: 14422.352218746142,
      usdVolume: 793614390.5674702,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '248.5345000000000000',
      high: '250.0912000000000000',
      low: '224.9686000000000000',
      close: '233.9466000000000000',
      volume: '3206750.4610000000000000',
      quoteVolume: 761130676.4045202,
      btcVolume: 13047.060000011228,
      usdVolume: 676406281.7791395,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '234.0024000000000000',
      high: '255.2113000000000000',
      low: '233.8114000000000000',
      close: '255.2105000000000000',
      volume: '2245802.5640000000000000',
      quoteVolume: 556008625.4021944,
      btcVolume: 10101.506782457785,
      usdVolume: 538894875.840913,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '255.1201000000000000',
      high: '276.0000000000000000',
      low: '249.5001000000000000',
      close: '269.2234000000000000',
      volume: '3089181.8480000000000000',
      quoteVolume: 804732179.3560896,
      btcVolume: 14561.461272689408,
      usdVolume: 804579787.2442447,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '269.2864000000000000',
      high: '281.3939000000000000',
      low: '261.0000000000000000',
      close: '268.4263000000000000',
      volume: '3272613.7550000000000000',
      quoteVolume: 888837689.7422438,
      btcVolume: 15905.983283637139,
      usdVolume: 888769684.6338859,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '268.6234000000000000',
      high: '277.9813000000000000',
      low: '264.4585000000000000',
      close: '275.2991000000000000',
      volume: '2718538.8020000000000000',
      quoteVolume: 741093981.6902637,
      btcVolume: 13018.42468453984,
      usdVolume: 741031453.2366232,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '275.2986000000000000',
      high: '311.5325000000000000',
      low: '271.2857000000000000',
      close: '311.2962000000000000',
      volume: '5532768.0410000000000000',
      quoteVolume: 1633393475.0102596,
      btcVolume: 27920.656657466523,
      usdVolume: 1633357160.421257,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '311.2962000000000000',
      high: '316.6997000000000000',
      low: '284.0000000000000000',
      close: '302.3724000000000000',
      volume: '4719335.5060000000000000',
      quoteVolume: 1432519744.2660277,
      btcVolume: 24381.244738004352,
      usdVolume: 1428700884.4968865,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '302.4591000000000000',
      high: '339.0000000000000000',
      low: '300.7000000000000000',
      close: '334.7991000000000000',
      volume: '4332111.9370000000000000',
      quoteVolume: 1384341137.684506,
      btcVolume: 23482.89919447801,
      usdVolume: 1382271214.156847,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '334.6706000000000000',
      high: '356.9882000000000000',
      low: '324.6162000000000000',
      close: '338.2932000000000000',
      volume: '4071170.6560000000000000',
      quoteVolume: 1403027066.2804952,
      btcVolume: 23692.93398872393,
      usdVolume: 1402782243.379352,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '338.2932000000000000',
      high: '349.1347000000000000',
      low: '315.9100000000000000',
      close: '321.9497000000000000',
      volume: '3098374.7850000000000000',
      quoteVolume: 1044133678.7217119,
      btcVolume: 10038.83808275111,
      usdVolume: 591404806.9357758,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '321.9597000000000000',
      high: '352.3275000000000000',
      low: '316.0000000000000000',
      close: '348.8617000000000000',
      volume: '2648776.3950000000000000',
      quoteVolume: 902098257.0088232,
      btcVolume: 13702.133872871518,
      usdVolume: 791916397.8945813,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '348.8705000000000000',
      high: '380.0000000000000000',
      low: '335.8442000000000000',
      close: '368.0263000000000000',
      volume: '3431868.4070000000000000',
      quoteVolume: 1236127810.4175525,
      btcVolume: 17901.65290795174,
      usdVolume: 1037725357.78694,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '368.1506000000000000',
      high: '411.7550000000000000',
      low: '363.8333000000000000',
      close: '403.3648000000000000',
      volume: '4239882.7000000000000000',
      quoteVolume: 1648279064.5724006,
      btcVolume: 26531.19803700832,
      usdVolume: 1548692540.941828,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '403.2607000000000000',
      high: '414.3803000000000000',
      low: '356.0000000000000000',
      close: '375.5538000000000000',
      volume: '6153246.9340000000000000',
      quoteVolume: 2372758767.1935678,
      btcVolume: 40716.89375916033,
      usdVolume: 2320206435.050474,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '375.5538000000000000',
      high: '422.6323000000000000',
      low: '371.7800000000000000',
      close: '418.7048000000000000',
      volume: '4010180.6390000000000000',
      quoteVolume: 1625423876.1601589,
      btcVolume: 18000.290159044805,
      usdVolume: 1028345774.5132892,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '418.7047000000000000',
      high: '453.9937000000000000',
      low: '403.1900000000000000',
      close: '453.9937000000000000',
      volume: '3392014.2090000000000000',
      quoteVolume: 1464479256.1346753,
      btcVolume: 22466.754948146387,
      usdVolume: 1309044489.8936975,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '453.9898000000000000',
      high: '487.4062000000000000',
      low: '450.0000000000000000',
      close: '471.2975000000000000',
      volume: '4659107.2120000000000000',
      quoteVolume: 2200226600.979961,
      btcVolume: 36213.41070222386,
      usdVolume: 2165238340.1851015,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '471.2974000000000000',
      high: '529.2110000000000000',
      low: '458.0165000000000000',
      close: '525.9275000000000000',
      volume: '3644396.1100000000000000',
      quoteVolume: 1776698304.8045623,
      btcVolume: 29506.82991366972,
      usdVolume: 1764632035.1456206,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '525.9275000000000000',
      high: '638.5673000000000000',
      low: '515.1000000000000000',
      close: '596.7104000000000000',
      volume: '9111902.9720000000000000',
      quoteVolume: 5245816431.400061,
      btcVolume: 63257.127669028734,
      usdVolume: 3806149366.686823,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '596.7878000000000000',
      high: '602.7871000000000000',
      low: '530.2288000000000000',
      close: '551.6442000000000000',
      volume: '5211856.8490000000000000',
      quoteVolume: 2928039699.6231527,
      btcVolume: 46855.84817376075,
      usdVolume: 2904056644.2781954,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '551.3956000000000000',
      high: '590.5369000000000000',
      low: '521.1313000000000000',
      close: '550.0182000000000000',
      volume: '4141516.3380000000000000',
      quoteVolume: 2316332649.32106,
      btcVolume: 32494.32817460017,
      usdVolume: 2063377293.09785,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '550.0182000000000000',
      high: '556.9000000000000000',
      low: '533.7166000000000000',
      close: '541.8824000000000000',
      volume: '2306847.3860000000000000',
      quoteVolume: 1261788213.6561053,
      btcVolume: 18450.056652820283,
      usdVolume: 1159651151.0654097,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '541.8351000000000000',
      high: '547.6074000000000000',
      low: '489.7356000000000000',
      close: '508.2743000000000000',
      volume: '4192215.1750000000000000',
      quoteVolume: 2179385039.089873,
      btcVolume: 33322.010808070954,
      usdVolume: 2049560218.6198566,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '508.2614000000000000',
      high: '547.9500000000000000',
      low: '506.0000000000000000',
      close: '514.6861000000000000',
      volume: '2949039.8180000000000000',
      quoteVolume: 1548814241.88043,
      btcVolume: 16718.141931258793,
      usdVolume: 1024252858.6623896,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '514.8178000000000000',
      high: '520.4237000000000000',
      low: '428.0000000000000000',
      close: '481.4367000000000000',
      volume: '4049186.4060000000000000',
      quoteVolume: 1904645753.8034728,
      btcVolume: 33628.949170106855,
      usdVolume: 1876226556.6125178,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '481.5112000000000000',
      high: '540.0000000000000000',
      low: '467.8846000000000000',
      close: '504.0322000000000000',
      volume: '5031304.0290000000000000',
      quoteVolume: 2572847032.948682,
      btcVolume: 37508.47886493883,
      usdVolume: 2114776432.2070198,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '504.0322000000000000',
      high: '589.5000000000000000',
      low: '470.2070000000000000',
      close: '586.3635000000000000',
      volume: '5715927.6350000000000000',
      quoteVolume: 3058354760.9721446,
      btcVolume: 53121.18738860772,
      usdVolume: 2963040031.251067,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '586.5201000000000000',
      high: '613.8975000000000000',
      low: '535.9186000000000000',
      close: '543.6327000000000000',
      volume: '5415834.4300000000000000',
      quoteVolume: 3148647997.53806,
      btcVolume: 49070.86389573589,
      usdVolume: 2723704942.1352196,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '543.6827000000000000',
      high: '582.7051000000000000',
      low: '480.0000000000000000',
      close: '505.2411000000000000',
      volume: '5711241.6150000000000000',
      quoteVolume: 3087134504.9510384,
      btcVolume: 16336.707809710622,
      usdVolume: 865615640.8242787,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '505.2282000000000000',
      high: '530.0000000000000000',
      low: '453.1000000000000000',
      close: '526.5357000000000000',
      volume: '6415439.4970000000000000',
      quoteVolume: 3176588289.098144,
      btcVolume: 33856.082810714746,
      usdVolume: 1686441255.1318297,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '526.4319000000000000',
      high: '528.8088000000000000',
      low: '482.3052000000000000',
      close: '496.9920000000000000',
      volume: '2969978.4340000000000000',
      quoteVolume: 1502798926.917138,
      btcVolume: 28745.678183221087,
      usdVolume: 1432476977.467932,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '496.9920000000000000',
      high: '517.7400000000000000',
      low: '478.0100000000000000',
      close: '505.0000000000000000',
      volume: '2352878.9486000000000000',
      quoteVolume: 1177965823.3087528,
      btcVolume: 12577.589728470612,
      usdVolume: 623023504.0575972,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '505.0000000000000000',
      high: '550.0000000000000000',
      low: '501.5000000000000000',
      close: '535.3300000000000000',
      volume: '2655262.1521000000000000',
      quoteVolume: 1411293600.6102023,
      btcVolume: 15480.36818125127,
      usdVolume: 815757282.7136099,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '535.3300000000000000',
      high: '579.5000000000000000',
      low: '525.0000000000000000',
      close: '568.7800000000000000',
      volume: '2807308.6198000000000000',
      quoteVolume: 1565140057.7051094,
      btcVolume: 28300.124067514644,
      usdVolume: 1546267212.3260415,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '568.7800000000000000',
      high: '578.8800000000000000',
      low: '538.0000000000000000',
      close: '562.7800000000000000',
      volume: '2384511.0120000000000000',
      quoteVolume: 1333085756.448968,
      btcVolume: 24357.758243790056,
      usdVolume: 1333085756.448968,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '562.7800000000000000',
      high: '615.2900000000000000',
      low: '554.0000000000000000',
      close: '599.7000000000000000',
      volume: '4242963.4049000000000000',
      quoteVolume: 2481862298.554591,
      btcVolume: 46006.51699559538,
      usdVolume: 2481862298.554591,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '599.6600000000000000',
      high: '628.0000000000000000',
      low: '585.7200000000000000',
      close: '622.6500000000000000',
      volume: '3379163.2196000000000000',
      quoteVolume: 2063664584.472258,
      btcVolume: 25172.717745086713,
      usdVolume: 1386220097.2369685,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '622.6400000000000000',
      high: '645.5700000000000000',
      low: '609.2100000000000000',
      close: '620.0000000000000000',
      volume: '2427568.3909000000000000',
      quoteVolume: 1516905856.244385,
      btcVolume: 12446.095949705987,
      usdVolume: 719848206.3838052,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '620.0000000000000000',
      high: '629.0000000000000000',
      low: '595.3000000000000000',
      close: '621.7800000000000000',
      volume: '2066188.5104000000000000',
      quoteVolume: 1273393608.5696151,
      btcVolume: 14374.383604305296,
      usdVolume: 815986629.5360534,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '621.7700000000000000',
      high: '680.0000000000000000',
      low: '621.2200000000000000',
      close: '676.1500000000000000',
      volume: '2873379.6170000000000000',
      quoteVolume: 1872406454.7114303,
      btcVolume: 31523.29798707986,
      usdVolume: 1827102859.2419474,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '676.1500000000000000',
      high: '677.7100000000000000',
      low: '604.1400000000000000',
      close: '609.3000000000000000',
      volume: '3760446.5392000000000000',
      quoteVolume: 2397311746.8970165,
      btcVolume: 34681.93696052413,
      usdVolume: 1922027889.6555834,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '609.4100000000000000',
      high: '656.6700000000000000',
      low: '605.0000000000000000',
      close: '651.1000000000000000',
      volume: '2108343.7681000000000000',
      quoteVolume: 1350489029.914887,
      btcVolume: 22376.029864647935,
      usdVolume: 1245585308.8986058,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '651.1000000000000000',
      high: '653.7700000000000000',
      low: '620.0000000000000000',
      close: '633.9900000000000000',
      volume: '2027391.4345000000000000',
      quoteVolume: 1294526643.8860967,
      btcVolume: 22104.33571757016,
      usdVolume: 1257147022.1981807,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '634.0300000000000000',
      high: '644.0200000000000000',
      low: '611.4300000000000000',
      close: '624.3000000000000000',
      volume: '1979433.7807000000000000',
      quoteVolume: 1238888054.2225192,
      btcVolume: 21423.691802310528,
      usdVolume: 1214310669.4553754,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '624.3000000000000000',
      high: '657.9100000000000000',
      low: '618.3600000000000000',
      close: '646.1000000000000000',
      volume: '2185770.9717000000000000',
      quoteVolume: 1395759313.3025906,
      btcVolume: 23506.98439331287,
      usdVolume: 1375162536.6888602,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '646.0700000000000000',
      high: '679.0000000000000000',
      low: '630.5400000000000000',
      close: '661.7400000000000000',
      volume: '2343400.2966000000000000',
      quoteVolume: 1543793211.4605246,
      btcVolume: 25883.63462490665,
      usdVolume: 1497580524.9378126,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '661.7400000000000000',
      high: '691.7700000000000000',
      low: '588.0000000000000000',
      close: '631.1000000000000000',
      volume: '3000221.4265000000000000',
      quoteVolume: 1974492506.488098,
      btcVolume: 23776.338284261044,
      usdVolume: 1357118070.6740103,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '631.1600000000000000',
      high: '673.0000000000000000',
      low: '621.1100000000000000',
      close: '671.9100000000000000',
      volume: '2559194.2717000000000000',
      quoteVolume: 1669252689.2629402,
      btcVolume: 23284.48219498061,
      usdVolume: 1299085092.4906397,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '671.9000000000000000',
      high: '684.9100000000000000',
      low: '581.4000000000000000',
      close: '590.0000000000000000',
      volume: '2807361.4119000000000000',
      quoteVolume: 1818252882.3973007,
      btcVolume: 32752.382692644092,
      usdVolume: 1796910641.5199373,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '594.2000000000000000',
      high: '633.5000000000000000',
      low: '526.6000000000000000',
      close: '571.1000000000000000',
      volume: '5067197.8665000000000000',
      quoteVolume: 2965262611.7093215,
      btcVolume: 57817.7131586063,
      usdVolume: 2860884730.229648,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '571.1000000000000000',
      high: '615.0000000000000000',
      low: '566.0000000000000000',
      close: '602.6100000000000000',
      volume: '2185834.5291000000000000',
      quoteVolume: 1300235538.911767,
      btcVolume: 25346.497714855974,
      usdVolume: 1270717338.95908,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '602.6100000000000000',
      high: '611.3100000000000000',
      low: '560.0000000000000000',
      close: '563.4100000000000000',
      volume: '2259793.5825000000000000',
      quoteVolume: 1322248759.3872828,
      btcVolume: 26733.85650391243,
      usdVolume: 1302373374.1391342,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '563.4200000000000000',
      high: '606.7800000000000000',
      low: '534.8800000000000000',
      close: '564.3200000000000000',
      volume: '2935802.4611000000000000',
      quoteVolume: 1676575346.1177924,
      btcVolume: 34768.195828266646,
      usdVolume: 1641174937.546295,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '564.6600000000000000',
      high: '564.6600000000000000',
      low: '483.0000000000000000',
      close: '512.0700000000000000',
      volume: '4002372.8778000000000000',
      quoteVolume: 2073223636.3413508,
      btcVolume: 45783.614024130424,
      usdVolume: 2011961037.140912,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '512.0600000000000000',
      high: '534.0000000000000000',
      low: '490.0000000000000000',
      close: '507.8800000000000000',
      volume: '2720458.6730000000000000',
      quoteVolume: 1404074857.8504837,
      btcVolume: 31414.796893148603,
      usdVolume: 1382388016.6856706,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '507.9000000000000000',
      high: '516.5000000000000000',
      low: '280.0000000000000000',
      close: '334.8000000000000000',
      volume: '8239380.7045000000000000',
      quoteVolume: 3344112007.75396,
      btcVolume: 72337.91025415438,
      usdVolume: 2827850240.61419,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '334.8000000000000000',
      high: '423.8300000000000000',
      low: '284.2700000000000000',
      close: '391.1700000000000000',
      volume: '8115885.0027000000000000',
      quoteVolume: 2937254802.220806,
      btcVolume: 60130.470531550855,
      usdVolume: 2366728450.1871305,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '391.3700000000000000',
      high: '415.7100000000000000',
      low: '274.0000000000000000',
      close: '326.0100000000000000',
      volume: '7320106.3957000000000000',
      quoteVolume: 2518202198.2935557,
      btcVolume: 49722.66139396216,
      usdVolume: 1903529428.7297347,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '326.2300000000000000',
      high: '335.8200000000000000',
      low: '284.5500000000000000',
      close: '299.7600000000000000',
      volume: '5296110.3904000000000000',
      quoteVolume: 1644509563.62935,
      btcVolume: 28543.884802395307,
      usdVolume: 1058397270.464872,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '299.7500000000000000',
      high: '313.4500000000000000',
      low: '211.7000000000000000',
      close: '260.7200000000000000',
      volume: '8801148.1832000000000000',
      quoteVolume: 2229226334.873108,
      btcVolume: 63281.297938914686,
      usdVolume: 2178774483.491753,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '260.7200000000000000',
      high: '350.0000000000000000',
      low: '257.4000000000000000',
      close: '345.6400000000000000',
      volume: '7781959.4786000000000000',
      quoteVolume: 2368109550.148683,
      btcVolume: 62642.28704122964,
      usdVolume: 2327105512.6887536,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '345.6400000000000000',
      high: '378.1200000000000000',
      low: '306.6100000000000000',
      close: '343.1800000000000000',
      volume: '7638986.0132000000000000',
      quoteVolume: 2568468246.8690605,
      btcVolume: 66152.31977044823,
      usdVolume: 2514295964.407211,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '343.1800000000000000',
      high: '388.2800000000000000',
      low: '336.2100000000000000',
      close: '379.1600000000000000',
      volume: '5258751.3486000000000000',
      quoteVolume: 1933572793.550551,
      btcVolume: 48379.41805278539,
      usdVolume: 1900748427.6240995,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '379.1600000000000000',
      high: '387.5600000000000000',
      low: '340.0000000000000000',
      close: '369.6700000000000000',
      volume: '4275801.5014000000000000',
      quoteVolume: 1564221587.8316987,
      btcVolume: 40027.51492212167,
      usdVolume: 1551370014.1647067,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '369.8300000000000000',
      high: '373.6800000000000000',
      low: '312.3000000000000000',
      close: '328.8200000000000000',
      volume: '5253443.3619000000000000',
      quoteVolume: 1767981944.1573837,
      btcVolume: 47664.24020697601,
      usdVolume: 1739093359.1059759,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '328.8600000000000000',
      high: '347.0000000000000000',
      low: '295.2200000000000000',
      close: '305.0400000000000000',
      volume: '3763011.1068000000000000',
      quoteVolume: 1207079531.3800404,
      btcVolume: 33934.25604475658,
      usdVolume: 1191835011.423594,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '305.1800000000000000',
      high: '338.3200000000000000',
      low: '291.0600000000000000',
      close: '325.5700000000000000',
      volume: '3737180.6229000000000000',
      quoteVolume: 1203296649.3056147,
      btcVolume: 33941.50714115873,
      usdVolume: 1201181411.5601633,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '325.5500000000000000',
      high: '356.0000000000000000',
      low: '307.5500000000000000',
      close: '353.3300000000000000',
      volume: '4100337.3521000000000000',
      quoteVolume: 1373992157.7018433,
      btcVolume: 37865.87542025713,
      usdVolume: 1369885540.0723953,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '353.3700000000000000',
      high: '364.4000000000000000',
      low: '339.4800000000000000',
      close: '362.3900000000000000',
      volume: '3509428.1657000000000000',
      quoteVolume: 1234613133.2643874,
      btcVolume: 33299.87059281471,
      usdVolume: 1220996431.8403883,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '362.3600000000000000',
      high: '419.4300000000000000',
      low: '350.7100000000000000',
      close: '401.5400000000000000',
      volume: '4195383.3566000000000000',
      quoteVolume: 1645840910.622663,
      btcVolume: 43851.300647727745,
      usdVolume: 1640011801.5316956,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '401.5200000000000000',
      high: '433.0000000000000000',
      low: '391.3200000000000000',
      close: '428.4800000000000000',
      volume: '4180143.8076000000000000',
      quoteVolume: 1737768750.2461805,
      btcVolume: 45007.68133463864,
      usdVolume: 1735956965.104566,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '428.4600000000000000',
      high: '429.4400000000000000',
      low: '365.0000000000000000',
      close: '390.7000000000000000',
      volume: '4787078.4675000000000000',
      quoteVolume: 1860279166.5224113,
      btcVolume: 49180.94965001132,
      usdVolume: 1819826565.68764,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '390.6800000000000000',
      high: '426.0000000000000000',
      low: '373.8000000000000000',
      close: '390.0800000000000000',
      volume: '3977579.3470000000000000',
      quoteVolume: 1595813798.7458856,
      btcVolume: 43299.21514760122,
      usdVolume: 1582242242.5882614,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '390.0500000000000000',
      high: '400.2900000000000000',
      low: '381.5000000000000000',
      close: '393.7300000000000000',
      volume: '1861227.0254000000000000',
      quoteVolume: 728760657.4104598,
      btcVolume: 20250.859347582682,
      usdVolume: 727603105.8069081,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '393.8200000000000000',
      high: '407.4200000000000000',
      low: '353.5300000000000000',
      close: '357.9600000000000000',
      volume: '3028938.8445000000000000',
      quoteVolume: 1170669919.3293319,
      btcVolume: 32773.58817400823,
      usdVolume: 1165121515.317816,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '357.9400000000000000',
      high: '366.3300000000000000',
      low: '319.1800000000000000',
      close: '352.5000000000000000',
      volume: '4986849.8512000000000000',
      quoteVolume: 1719924713.8838046,
      btcVolume: 52178.07647016997,
      usdVolume: 1704623920.306418,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '352.5000000000000000',
      high: '378.7700000000000000',
      low: '333.5600000000000000',
      close: '375.5400000000000000',
      volume: '3494285.6690000000000000',
      quoteVolume: 1249840132.937433,
      btcVolume: 35442.0885217172,
      usdVolume: 1236317469.7043624,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '375.5600000000000000',
      high: '377.5500000000000000',
      low: '344.1300000000000000',
      close: '351.9400000000000000',
      volume: '2416033.9503000000000000',
      quoteVolume: 872167769.3608466,
      btcVolume: 23457.678201352148,
      usdVolume: 868637983.1314929,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '351.9600000000000000',
      high: '366.2800000000000000',
      low: '340.8400000000000000',
      close: '345.7500000000000000',
      volume: '2215352.7230000000000000',
      quoteVolume: 785929588.8141638,
      btcVolume: 21185.154784415125,
      usdVolume: 783490988.6749337,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '345.7600000000000000',
      high: '348.8800000000000000',
      low: '325.3000000000000000',
      close: '343.7000000000000000',
      volume: '2074837.0012000000000000',
      quoteVolume: 699907058.519631,
      btcVolume: 19499.705610749803,
      usdVolume: 696409381.2621572,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '343.6800000000000000',
      high: '369.0000000000000000',
      low: '330.4800000000000000',
      close: '365.8000000000000000',
      volume: '1958071.3656000000000000',
      quoteVolume: 679668875.2465632,
      btcVolume: 18401.26447166104,
      usdVolume: 677962892.4327875,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '365.8100000000000000',
      high: '377.3300000000000000',
      low: '355.0700000000000000',
      close: '370.1900000000000000',
      volume: '1978613.2384000000000000',
      quoteVolume: 723637001.3751506,
      btcVolume: 18165.93816349188,
      usdVolume: 723148705.02761,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '370.2000000000000000',
      high: '379.5800000000000000',
      low: '363.2300000000000000',
      close: '366.4800000000000000',
      volume: '1545190.4369000000000000',
      quoteVolume: 573156968.6381595,
      btcVolume: 14239.62588138577,
      usdVolume: 572741192.8239167,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '366.4800000000000000',
      high: '366.7700000000000000',
      low: '343.3500000000000000',
      close: '347.2200000000000000',
      volume: '1531522.2062000000000000',
      quoteVolume: 543418325.492728,
      btcVolume: 13807.544901929507,
      usdVolume: 542608162.7021075,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '347.2200000000000000',
      high: '362.4700000000000000',
      low: '345.8500000000000000',
      close: '352.8500000000000000',
      volume: '1239146.5273000000000000',
      quoteVolume: 439111935.5714126,
      btcVolume: 11360.551963526297,
      usdVolume: 438379161.15634346,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '352.8400000000000000',
      high: '357.1200000000000000',
      low: '327.2200000000000000',
      close: '337.1600000000000000',
      volume: '1583897.2791000000000000',
      quoteVolume: 543765122.8516467,
      btcVolume: 14644.68316373736,
      usdVolume: 540156402.3887985,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '337.2100000000000000',
      high: '345.0000000000000000',
      low: '331.1100000000000000',
      close: '334.0100000000000000',
      volume: '1066914.4291000000000000',
      quoteVolume: 361009064.1546483,
      btcVolume: 10093.106647631466,
      usdVolume: 360603082.2604604,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '334.0300000000000000',
      high: '340.5700000000000000',
      low: '310.1300000000000000',
      close: '339.0800000000000000',
      volume: '1618135.6528000000000000',
      quoteVolume: 529042411.3983932,
      btcVolume: 15094.299251402948,
      usdVolume: 523909240.19277626,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '339.0900000000000000',
      high: '342.0000000000000000',
      low: '268.5700000000000000',
      close: '270.7600000000000000',
      volume: '3728933.7663000000000000',
      quoteVolume: 1133986634.6228907,
      btcVolume: 34042.98997440145,
      usdVolume: 1114042737.0850716,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '270.7600000000000000',
      high: '291.5000000000000000',
      low: '225.4200000000000000',
      close: '262.1000000000000000',
      volume: '5569480.0596000000000000',
      quoteVolume: 1444878723.2121854,
      btcVolume: 45224.72868315482,
      usdVolume: 1421659323.222384,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '262.1000000000000000',
      high: '309.7500000000000000',
      low: '251.4100000000000000',
      close: '296.2500000000000000',
      volume: '3317195.3211000000000000',
      quoteVolume: 955844232.5110707,
      btcVolume: 28063.34606238212,
      usdVolume: 947394250.7374245,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '296.1700000000000000',
      high: '316.4900000000000000',
      low: '281.5000000000000000',
      close: '308.6200000000000000',
      volume: '2610131.7883000000000000',
      quoteVolume: 786154895.0311371,
      btcVolume: 23317.078611131026,
      usdVolume: 783721733.0198007,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '308.6000000000000000',
      high: '314.8400000000000000',
      low: '275.2400000000000000',
      close: '280.8100000000000000',
      volume: '2711888.8709000000000000',
      quoteVolume: 791189610.0092999,
      btcVolume: 23725.1496532656,
      usdVolume: 789609971.7930796,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '280.8900000000000000',
      high: '290.3300000000000000',
      low: '264.2600000000000000',
      close: '279.6500000000000000',
      volume: '2255714.8070000000000000',
      quoteVolume: 620289844.1352688,
      btcVolume: 19714.79679267372,
      usdVolume: 617533521.9969708,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '279.5800000000000000',
      high: '291.7000000000000000',
      low: '269.7200000000000000',
      close: '290.8700000000000000',
      volume: '1539903.0268000000000000',
      quoteVolume: 431044973.3830572,
      btcVolume: 12975.955639558339,
      usdVolume: 430025822.8561487,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '290.8900000000000000',
      high: '299.9600000000000000',
      low: '283.5500000000000000',
      close: '290.0000000000000000',
      volume: '1791427.4545000000000000',
      quoteVolume: 521315922.3130429,
      btcVolume: 15087.577540242723,
      usdVolume: 520602774.96711284,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '290.0000000000000000',
      high: '315.6000000000000000',
      low: '289.1900000000000000',
      close: '300.7900000000000000',
      volume: '1996075.2527000000000000',
      quoteVolume: 606800912.6351702,
      btcVolume: 17004.53387746386,
      usdVolume: 606106183.2717582,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '300.7900000000000000',
      high: '304.8800000000000000',
      low: '281.5300000000000000',
      close: '303.7100000000000000',
      volume: '2003771.1501000000000000',
      quoteVolume: 589286208.902001,
      btcVolume: 16881.53168393942,
      usdVolume: 588691995.1413999,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '303.7500000000000000',
      high: '304.0000000000000000',
      low: '281.0000000000000000',
      close: '287.4300000000000000',
      volume: '1388151.0265000000000000',
      quoteVolume: 401830865.47626346,
      btcVolume: 11897.911415033517,
      usdVolume: 400902690.8213621,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '287.4900000000000000',
      high: '290.7400000000000000',
      low: '276.4000000000000000',
      close: '287.1700000000000000',
      volume: '999531.0988000000000000',
      quoteVolume: 281885530.70657486,
      btcVolume: 8474.226394092162,
      usdVolume: 281776937.5769988,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '287.1700000000000000',
      high: '303.2100000000000000',
      low: '282.7700000000000000',
      close: '298.1300000000000000',
      volume: '970447.5647000000000000',
      quoteVolume: 285959914.3739259,
      btcVolume: 8295.090216560875,
      usdVolume: 285468108.13785046,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '298.1800000000000000',
      high: '314.9100000000000000',
      low: '292.5800000000000000',
      close: '307.6200000000000000',
      volume: '1289644.5294000000000000',
      quoteVolume: 395101122.4499773,
      btcVolume: 11166.440864142483,
      usdVolume: 394799633.44753224,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '307.6300000000000000',
      high: '307.6800000000000000',
      low: '291.0000000000000000',
      close: '302.0200000000000000',
      volume: '1401707.3090000000000000',
      quoteVolume: 420794880.4929045,
      btcVolume: 12250.65881746282,
      usdVolume: 417383535.8960341,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '302.0400000000000000',
      high: '322.5000000000000000',
      low: '301.8000000000000000',
      close: '319.9200000000000000',
      volume: '2017579.2475000000000000',
      quoteVolume: 634877944.4661232,
      btcVolume: 18213.7054914752,
      usdVolume: 624628084.0134494,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '319.9100000000000000',
      high: '339.2000000000000000',
      low: '318.2300000000000000',
      close: '327.5100000000000000',
      volume: '1752283.5071000000000000',
      quoteVolume: 581264198.4175483,
      btcVolume: 16826.903381226817,
      usdVolume: 581264198.4175483,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '327.5100000000000000',
      high: '328.2100000000000000',
      low: '304.4000000000000000',
      close: '310.1400000000000000',
      volume: '1644090.8501000000000000',
      quoteVolume: 519961296.56923467,
      btcVolume: 15753.624652227563,
      usdVolume: 518366397.8303491,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '310.0800000000000000',
      high: '319.6900000000000000',
      low: '301.4300000000000000',
      close: '317.0900000000000000',
      volume: '1106411.7860000000000000',
      quoteVolume: 343735624.2635021,
      btcVolume: 10375.187952189024,
      usdVolume: 343590533.1811929,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '317.0900000000000000',
      high: '321.4400000000000000',
      low: '310.5700000000000000',
      close: '316.1200000000000000',
      volume: '818970.9225000000000000',
      quoteVolume: 258075549.81921732,
      btcVolume: 7649.980650066824,
      usdVolume: 258069158.45030236,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '316.1200000000000000',
      high: '326.8200000000000000',
      low: '312.2100000000000000',
      close: '322.1600000000000000',
      volume: '798248.5445000000000000',
      quoteVolume: 255757579.33083242,
      btcVolume: 7559.742869184711,
      usdVolume: 255696265.09578243,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '322.1700000000000000',
      high: '334.3700000000000000',
      low: '308.5700000000000000',
      close: '316.1200000000000000',
      volume: '1322836.6575000000000000',
      quoteVolume: 426623454.93747866,
      btcVolume: 12659.101822822975,
      usdVolume: 426389699.78516084,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '316.1100000000000000',
      high: '318.3300000000000000',
      low: '305.2000000000000000',
      close: '308.9400000000000000',
      volume: '920029.5386000000000000',
      quoteVolume: 286332861.64762217,
      btcVolume: 8716.39219531748,
      usdVolume: 286196536.8978347,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '308.9800000000000000',
      high: '313.4600000000000000',
      low: '290.2400000000000000',
      close: '309.4000000000000000',
      volume: '1429632.5382000000000000',
      quoteVolume: 432703278.02084684,
      btcVolume: 13340.15495367681,
      usdVolume: 432565896.3701419,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '309.3300000000000000',
      high: '324.3300000000000000',
      low: '301.3200000000000000',
      close: '316.1100000000000000',
      volume: '2182717.6094000000000000',
      quoteVolume: 682797057.890845,
      btcVolume: 21238.91718069471,
      usdVolume: 681918652.5679137,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '316.1200000000000000',
      high: '323.5000000000000000',
      low: '301.5000000000000000',
      close: '302.8800000000000000',
      volume: '1404493.8001000000000000',
      quoteVolume: 436177727.3631599,
      btcVolume: 13765.70767375639,
      usdVolume: 436177363.4888899,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '302.9400000000000000',
      high: '306.7100000000000000',
      low: '295.0000000000000000',
      close: '300.5200000000000000',
      volume: '1000972.6192000000000000',
      quoteVolume: 300875643.0396566,
      btcVolume: 9534.88714728326,
      usdVolume: 300540577.2338914,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '300.5600000000000000',
      high: '309.1800000000000000',
      low: '298.7000000000000000',
      close: '302.1000000000000000',
      volume: '815497.3527000000000000',
      quoteVolume: 248310442.2807995,
      btcVolume: 7822.5474578018375,
      usdVolume: 248294306.8784705,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '302.0400000000000000',
      high: '303.9400000000000000',
      low: '278.8500000000000000',
      close: '280.5400000000000000',
      volume: '1318041.7074000000000000',
      quoteVolume: 380236625.4640259,
      btcVolume: 12254.211166206616,
      usdVolume: 380166078.83319795,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '280.5400000000000000',
      high: '283.6000000000000000',
      low: '254.5200000000000000',
      close: '264.4400000000000000',
      volume: '1796247.8290000000000000',
      quoteVolume: 477237090.34517443,
      btcVolume: 15976.537565143271,
      usdVolume: 477091911.90441567,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '264.4300000000000000',
      high: '298.9200000000000000',
      low: '261.0200000000000000',
      close: '293.2600000000000000',
      volume: '1740736.0226000000000000',
      quoteVolume: 493675666.91880316,
      btcVolume: 15781.106267115589,
      usdVolume: 493348159.1449845,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '293.2700000000000000',
      high: '298.4400000000000000',
      low: '287.3000000000000000',
      close: '293.3600000000000000',
      volume: '1173507.9432000000000000',
      quoteVolume: 344239131.7439699,
      btcVolume: 10689.223886290574,
      usdVolume: 343305158.22704864,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '293.3900000000000000',
      high: '301.5000000000000000',
      low: '281.0100000000000000',
      close: '285.8000000000000000',
      volume: '1349255.6384000000000000',
      quoteVolume: 393613769.09714,
      btcVolume: 12142.021329993779,
      usdVolume: 393613769.09714,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
  cro: {
    id: 'cro',
    name: 'CRO',
    symbol: 'CRO',
    image: 'assets/images/coin-logo/CRO.png',
    activity24h: 9974589345,
    availableAmount: 508.232323,
    usdxConversion: .7,
    allTimeValue: 943812390,
    allTimeAsset: 3421,
    candleData: [
      {
      open: '204.4500000000000000',
      high: '205.9400000000000000',
      low: '203.6200000000000000',
      close: '204.9900000000000000',
      volume: '44394.8335700000000000',
      quoteVolume: 9084565.9157548,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-28T00:00:00.000Z'
      },
      {
      open: '204.9900000000000000',
      high: '205.4900000000000000',
      low: '195.2800000000000000',
      close: '196.9600000000000000',
      volume: '118281.8227200000000000',
      quoteVolume: 23671711.3937594,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-29T00:00:00.000Z'
      },
      {
      open: '196.9500000000000000',
      high: '198.6000000000000000',
      low: '195.9100000000000000',
      close: '197.2700000000000000',
      volume: '83633.8146300000000000',
      quoteVolume: 16501372.8633176,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-30T00:00:00.000Z'
      },
      {
      open: '197.2400000000000000',
      high: '201.4500000000000000',
      low: '193.0100000000000000',
      close: '198.7300000000000000',
      volume: '114739.7042400000000000',
      quoteVolume: 22632315.2602598,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-31T00:00:00.000Z'
      },
      {
      open: '198.7000000000000000',
      high: '200.9500000000000000',
      low: '197.8800000000000000',
      close: '199.9600000000000000',
      volume: '86097.9862900000000000',
      quoteVolume: 17116621.5351845,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-01T00:00:00.000Z'
      },
      {
      open: '199.9300000000000000',
      high: '203.7400000000000000',
      low: '199.3400000000000000',
      close: '202.3500000000000000',
      volume: '97123.2281300000000000',
      quoteVolume: 19559214.4192249,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-02T00:00:00.000Z'
      },
      {
      open: '202.3100000000000000',
      high: '202.6900000000000000',
      low: '198.5900000000000000',
      close: '200.3100000000000000',
      volume: '72084.9293200000000000',
      quoteVolume: 14456528.3775608,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-03T00:00:00.000Z'
      },
      {
      open: '200.2700000000000000',
      high: '218.7600000000000000',
      low: '199.0000000000000000',
      close: '212.1100000000000000',
      volume: '182534.6216000000000000',
      quoteVolume: 37959621.568273,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-04T00:00:00.000Z'
      },
      {
      open: '212.1300000000000000',
      high: '213.3900000000000000',
      low: '207.6200000000000000',
      close: '210.6600000000000000',
      volume: '141432.1406000000000000',
      quoteVolume: 29714567.4106342,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-05T00:00:00.000Z'
      },
      {
      open: '210.6400000000000000',
      high: '221.2500000000000000',
      low: '209.1100000000000000',
      close: '220.7400000000000000',
      volume: '197797.4952700000000000',
      quoteVolume: 42534535.8639938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-06T00:00:00.000Z'
      },
      {
      open: '220.7700000000000000',
      high: '224.8000000000000000',
      low: '217.0100000000000000',
      close: '219.0700000000000000',
      volume: '204931.0342000000000000',
      quoteVolume: 45252424.0852285,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-07T00:00:00.000Z'
      },
      {
      open: '219.0600000000000000',
      high: '220.7800000000000000',
      low: '212.4900000000000000',
      close: '212.7900000000000000',
      volume: '189828.4945000000000000',
      quoteVolume: 40931506.148422,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-08T00:00:00.000Z'
      },
      {
      open: '212.7900000000000000',
      high: '215.5100000000000000',
      low: '209.1000000000000000',
      close: '210.7700000000000000',
      volume: '152215.9468600000000000',
      quoteVolume: 32309082.1975887,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-09T00:00:00.000Z'
      },
      {
      open: '210.7800000000000000',
      high: '215.5500000000000000',
      low: '209.0200000000000000',
      close: '213.2500000000000000',
      volume: '131384.4789300000000000',
      quoteVolume: 28019225.0100827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-10T00:00:00.000Z'
      },
      {
      open: '213.2700000000000000',
      high: '213.9900000000000000',
      low: '209.0000000000000000',
      close: '212.5400000000000000',
      volume: '136268.8115600000000000',
      quoteVolume: 28875079.8849474,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-11T00:00:00.000Z'
      },
      {
      open: '212.6000000000000000',
      high: '214.2200000000000000',
      low: '210.9000000000000000',
      close: '212.8900000000000000',
      volume: '99963.9720300000000000',
      quoteVolume: 21271725.1845819,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-12T00:00:00.000Z'
      },
      {
      open: '212.9100000000000000',
      high: '213.8000000000000000',
      low: '209.2000000000000000',
      close: '209.8400000000000000',
      volume: '114466.3670800000000000',
      quoteVolume: 24176049.6062305,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-13T00:00:00.000Z'
      },
      {
      open: '209.8400000000000000',
      high: '210.3000000000000000',
      low: '176.1600000000000000',
      close: '187.4300000000000000',
      volume: '434676.1683200000000000',
      quoteVolume: 82531353.9766622,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-14T00:00:00.000Z'
      },
      {
      open: '187.4300000000000000',
      high: '190.0000000000000000',
      low: '170.9900000000000000',
      close: '184.3500000000000000',
      volume: '452416.1078400000000000',
      quoteVolume: 81674273.8857771,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-15T00:00:00.000Z'
      },
      {
      open: '184.3500000000000000',
      high: '185.0000000000000000',
      low: '173.0000000000000000',
      close: '176.8900000000000000',
      volume: '241710.3476300000000000',
      quoteVolume: 43450649.948378,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-16T00:00:00.000Z'
      },
      {
      open: '176.9300000000000000',
      high: '177.9100000000000000',
      low: '173.9400000000000000',
      close: '176.2000000000000000',
      volume: '144804.7098600000000000',
      quoteVolume: 25452515.6364988,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-17T00:00:00.000Z'
      },
      {
      open: '176.2000000000000000',
      high: '182.9400000000000000',
      low: '176.1000000000000000',
      close: '178.9700000000000000',
      volume: '141736.3226300000000000',
      quoteVolume: 25261184.0928033,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-18T00:00:00.000Z'
      },
      {
      open: '179.0400000000000000',
      high: '179.3300000000000000',
      low: '146.4700000000000000',
      close: '150.8100000000000000',
      volume: '596789.8391600000000000',
      quoteVolume: 94639227.0313713,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-19T00:00:00.000Z'
      },
      {
      open: '150.7600000000000000',
      high: '154.7000000000000000',
      low: '126.9500000000000000',
      close: '133.8200000000000000',
      volume: '996552.2872100000000000',
      quoteVolume: 139191208.455093,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-20T00:00:00.000Z'
      },
      {
      open: '133.6800000000000000',
      high: '142.0100000000000000',
      low: '127.1500000000000000',
      close: '138.2300000000000000',
      volume: '718957.7855200000000000',
      quoteVolume: 97819681.4905397,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-21T00:00:00.000Z'
      },
      {
      open: '138.3600000000000000',
      high: '140.4000000000000000',
      low: '125.6400000000000000',
      close: '126.6300000000000000',
      volume: '334674.0718500000000000',
      quoteVolume: 44837388.4512714,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-22T00:00:00.000Z'
      },
      {
      open: '126.5800000000000000',
      high: '128.9000000000000000',
      low: '118.5600000000000000',
      close: '125.0200000000000000',
      volume: '504352.2438600000000000',
      quoteVolume: 62657024.0486937,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-23T00:00:00.000Z'
      },
      {
      open: '125.0000000000000000',
      high: '130.8100000000000000',
      low: '112.3200000000000000',
      close: '114.8900000000000000',
      volume: '471321.5676400000000000',
      quoteVolume: 57264800.0665808,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-24T00:00:00.000Z'
      },
      {
      open: '114.8300000000000000',
      high: '122.3500000000000000',
      low: '102.9500000000000000',
      close: '118.6700000000000000',
      volume: '836233.3125200000000000',
      quoteVolume: 93723314.1234466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-25T00:00:00.000Z'
      },
      {
      open: '118.6500000000000000',
      high: '121.5000000000000000',
      low: '106.4100000000000000',
      close: '110.6800000000000000',
      volume: '679477.3623100000000000',
      quoteVolume: 77209761.8275687,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-26T00:00:00.000Z'
      },
      {
      open: '110.6600000000000000',
      high: '114.3100000000000000',
      low: '102.3600000000000000',
      close: '111.4300000000000000',
      volume: '524024.4061600000000000',
      quoteVolume: 56638056.0091172,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-27T00:00:00.000Z'
      },
      {
      open: '111.3800000000000000',
      high: '126.8000000000000000',
      low: '111.3700000000000000',
      close: '122.9000000000000000',
      volume: '578552.7412400000000000',
      quoteVolume: 68485222.4434163,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-28T00:00:00.000Z'
      },
      {
      open: '122.8800000000000000',
      high: '124.0000000000000000',
      low: '114.7800000000000000',
      close: '117.9800000000000000',
      volume: '478777.3961400000000000',
      quoteVolume: 57140533.5732648,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-29T00:00:00.000Z'
      },
      {
      open: '117.9900000000000000',
      high: '120.2600000000000000',
      low: '111.0900000000000000',
      close: '113.7300000000000000',
      volume: '418593.6352900000000000',
      quoteVolume: 48362085.7818422,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-30T00:00:00.000Z'
      },
      {
      open: '113.7100000000000000',
      high: '120.8000000000000000',
      low: '111.3500000000000000',
      close: '118.1000000000000000',
      volume: '330159.0674200000000000',
      quoteVolume: 38614493.6264599,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-01T00:00:00.000Z'
      },
      {
      open: '118.0900000000000000',
      high: '120.7300000000000000',
      low: '116.0000000000000000',
      close: '116.5700000000000000',
      volume: '273657.6215000000000000',
      quoteVolume: 32184013.7601348,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-02T00:00:00.000Z'
      },
      {
      open: '116.6300000000000000',
      high: '116.9600000000000000',
      low: '106.7000000000000000',
      close: '108.7600000000000000',
      volume: '324446.0332400000000000',
      quoteVolume: 36185196.1948889,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-03T00:00:00.000Z'
      },
      {
      open: '108.7600000000000000',
      high: '113.2000000000000000',
      low: '106.0000000000000000',
      close: '109.9400000000000000',
      volume: '275321.0063000000000000',
      quoteVolume: 30365546.7521367,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-04T00:00:00.000Z'
      },
      {
      open: '109.9200000000000000',
      high: '110.5800000000000000',
      low: '102.3800000000000000',
      close: '102.7100000000000000',
      volume: '354071.5324100000000000',
      quoteVolume: 37519336.0718317,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-05T00:00:00.000Z'
      },
      {
      open: '102.7300000000000000',
      high: '104.6800000000000000',
      low: '90.2000000000000000',
      close: '91.0000000000000000',
      volume: '586138.5904300000000000',
      quoteVolume: 58114721.7510634,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-06T00:00:00.000Z'
      },
      {
      open: '91.1800000000000000',
      high: '96.9000000000000000',
      low: '82.0700000000000000',
      close: '93.0500000000000000',
      volume: '1030073.5846700000000000',
      quoteVolume: 90499447.337842,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-07T00:00:00.000Z'
      },
      {
      open: '93.0600000000000000',
      high: '98.0000000000000000',
      low: '84.5500000000000000',
      close: '90.3800000000000000',
      volume: '698217.1252900000000000',
      quoteVolume: 63224518.5473893,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-08T00:00:00.000Z'
      },
      {
      open: '90.3900000000000000',
      high: '98.7000000000000000',
      low: '88.6300000000000000',
      close: '93.2600000000000000',
      volume: '520519.3602300000000000',
      quoteVolume: 48533111.8520108,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-09T00:00:00.000Z'
      },
      {
      open: '93.2600000000000000',
      high: '95.2100000000000000',
      low: '88.1100000000000000',
      close: '89.9100000000000000',
      volume: '408573.0292900000000000',
      quoteVolume: 37180525.4573109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-10T00:00:00.000Z'
      },
      {
      open: '89.8800000000000000',
      high: '91.6500000000000000',
      low: '85.8300000000000000',
      close: '87.7600000000000000',
      volume: '339991.8424600000000000',
      quoteVolume: 30055007.219609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-11T00:00:00.000Z'
      },
      {
      open: '87.7700000000000000',
      high: '91.3100000000000000',
      low: '86.6300000000000000',
      close: '89.4100000000000000',
      volume: '285436.0402900000000000',
      quoteVolume: 25500398.9286536,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-12T00:00:00.000Z'
      },
      {
      open: '89.3900000000000000',
      high: '90.7100000000000000',
      low: '84.7100000000000000',
      close: '86.3400000000000000',
      volume: '331218.3008300000000000',
      quoteVolume: 29089359.8313012,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-13T00:00:00.000Z'
      },
      {
      open: '86.3500000000000000',
      high: '87.2500000000000000',
      low: '82.2000000000000000',
      close: '83.8200000000000000',
      volume: '318003.7342700000000000',
      quoteVolume: 27041687.8001072,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-14T00:00:00.000Z'
      },
      {
      open: '83.8200000000000000',
      high: '85.0000000000000000',
      low: '81.7900000000000000',
      close: '83.7600000000000000',
      volume: '217360.6577500000000000',
      quoteVolume: 18169124.0002302,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-15T00:00:00.000Z'
      },
      {
      open: '83.7500000000000000',
      high: '87.6400000000000000',
      low: '83.7300000000000000',
      close: '84.6700000000000000',
      volume: '241366.7630500000000000',
      quoteVolume: 20783723.6930707,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-16T00:00:00.000Z'
      },
      {
      open: '84.6800000000000000',
      high: '97.0000000000000000',
      low: '84.4000000000000000',
      close: '94.0200000000000000',
      volume: '472252.2338800000000000',
      quoteVolume: 42965553.4158694,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-17T00:00:00.000Z'
      },
      {
      open: '94.0300000000000000',
      high: '101.3000000000000000',
      low: '91.3400000000000000',
      close: '100.3900000000000000',
      volume: '529946.7917500000000000',
      quoteVolume: 50202945.7917017,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-18T00:00:00.000Z'
      },
      {
      open: '100.3900000000000000',
      high: '107.5500000000000000',
      low: '97.7500000000000000',
      close: '98.7400000000000000',
      volume: '827748.5909600000000000',
      quoteVolume: 84862730.2333679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-19T00:00:00.000Z'
      },
      {
      open: '98.7300000000000000',
      high: '116.0000000000000000',
      low: '97.8800000000000000',
      close: '114.0500000000000000',
      volume: '859612.5720500000000000',
      quoteVolume: 93128082.5480073,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-20T00:00:00.000Z'
      },
      {
      open: '113.7100000000000000',
      high: '118.0000000000000000',
      low: '104.7600000000000000',
      close: '107.6900000000000000',
      volume: '666048.7178600000000000',
      quoteVolume: 74510414.5879752,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-21T00:00:00.000Z'
      },
      {
      open: '107.6700000000000000',
      high: '115.5000000000000000',
      low: '105.3800000000000000',
      close: '115.2000000000000000',
      volume: '405672.8311700000000000',
      quoteVolume: 44814571.6096925,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-22T00:00:00.000Z'
      },
      {
      open: '115.1800000000000000',
      high: '131.9400000000000000',
      low: '114.9100000000000000',
      close: '129.0300000000000000',
      volume: '936094.1049000000000000',
      quoteVolume: 118250392.555097,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-23T00:00:00.000Z'
      },
      {
      open: '129.0300000000000000',
      high: '157.6700000000000000',
      low: '128.8000000000000000',
      close: '138.0300000000000000',
      volume: '1319609.8165000000000000',
      quoteVolume: 190137361.065877,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-24T00:00:00.000Z'
      },
      {
      open: '138.0100000000000000',
      high: '139.2600000000000000',
      low: '119.6800000000000000',
      close: '127.6000000000000000',
      volume: '1076164.1280900000000000',
      quoteVolume: 136600862.042676,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-25T00:00:00.000Z'
      },
      {
      open: '127.6100000000000000',
      high: '135.3200000000000000',
      low: '122.5100000000000000',
      close: '129.3500000000000000',
      volume: '794834.0560100000000000',
      quoteVolume: 102196717.395056,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-26T00:00:00.000Z'
      },
      {
      open: '129.3500000000000000',
      high: '131.0900000000000000',
      low: '111.2200000000000000',
      close: '113.8000000000000000',
      volume: '752177.9011100000000000',
      quoteVolume: 90456912.8883521,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-27T00:00:00.000Z'
      },
      {
      open: '113.7500000000000000',
      high: '136.6600000000000000',
      low: '111.8700000000000000',
      close: '135.3000000000000000',
      volume: '894454.1905100000000000',
      quoteVolume: 111865576.497758,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-28T00:00:00.000Z'
      },
      {
      open: '135.3000000000000000',
      high: '145.4200000000000000',
      low: '128.5000000000000000',
      close: '132.8900000000000000',
      volume: '1006654.4984400000000000',
      quoteVolume: 136479145.942347,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-29T00:00:00.000Z'
      },
      {
      open: '133.0000000000000000',
      high: '139.0000000000000000',
      low: '127.0300000000000000',
      close: '137.7700000000000000',
      volume: '704259.4161400000000000',
      quoteVolume: 94804050.4933616,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-30T00:00:00.000Z'
      },
      {
      open: '137.8100000000000000',
      high: '138.6600000000000000',
      low: '128.7000000000000000',
      close: '131.4500000000000000',
      volume: '484604.8396200000000000',
      quoteVolume: 64763118.1166383,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-31T00:00:00.000Z'
      },
      {
      open: '131.4500000000000000',
      high: '140.6700000000000000',
      low: '130.0000000000000000',
      close: '139.1000000000000000',
      volume: '401776.8879700000000000',
      quoteVolume: 54280385.598127,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-01T00:00:00.000Z'
      },
      {
      open: '139.1000000000000000',
      high: '155.3700000000000000',
      low: '137.7600000000000000',
      close: '152.0100000000000000',
      volume: '941486.3152400000000000',
      quoteVolume: 138801471.118652,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-02T00:00:00.000Z'
      },
      {
      open: '152.0100000000000000',
      high: '153.6400000000000000',
      low: '143.1100000000000000',
      close: '146.3000000000000000',
      volume: '708091.3171400000000000',
      quoteVolume: 104953535.148513,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-03T00:00:00.000Z'
      },
      {
      open: '146.3000000000000000',
      high: '154.8000000000000000',
      low: '143.5400000000000000',
      close: '151.9700000000000000',
      volume: '805248.9382100000000000',
      quoteVolume: 120827141.544228,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-04T00:00:00.000Z'
      },
      {
      open: '152.0800000000000000',
      high: '159.2600000000000000',
      low: '151.2000000000000000',
      close: '152.8300000000000000',
      volume: '773169.1373600000000000',
      quoteVolume: 120238339.782219,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-05T00:00:00.000Z'
      },
      {
      open: '152.8300000000000000',
      high: '157.4000000000000000',
      low: '147.5100000000000000',
      close: '153.8600000000000000',
      volume: '627908.4736600000000000',
      quoteVolume: 95681339.4600648,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-06T00:00:00.000Z'
      },
      {
      open: '153.8900000000000000',
      high: '155.4800000000000000',
      low: '147.8600000000000000',
      close: '149.3400000000000000',
      volume: '424765.3025800000000000',
      quoteVolume: 64310962.9190328,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-07T00:00:00.000Z'
      },
      {
      open: '149.2900000000000000',
      high: '151.6500000000000000',
      low: '145.2000000000000000',
      close: '147.6000000000000000',
      volume: '441661.0677800000000000',
      quoteVolume: 65535529.7557393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-08T00:00:00.000Z'
      },
      {
      open: '147.6200000000000000',
      high: '151.5000000000000000',
      low: '146.7700000000000000',
      close: '148.1000000000000000',
      volume: '299443.5630000000000000',
      quoteVolume: 44673066.391582,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-09T00:00:00.000Z'
      },
      {
      open: '148.1100000000000000',
      high: '149.4500000000000000',
      low: '121.0000000000000000',
      close: '124.9500000000000000',
      volume: '871751.7503800000000000',
      quoteVolume: 114437025.282728,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-10T00:00:00.000Z'
      },
      {
      open: '124.9600000000000000',
      high: '127.3600000000000000',
      low: '120.0800000000000000',
      close: '124.6500000000000000',
      volume: '545293.9836900000000000',
      quoteVolume: 67782816.3375987,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-11T00:00:00.000Z'
      },
      {
      open: '124.6700000000000000',
      high: '126.1900000000000000',
      low: '122.0000000000000000',
      close: '123.3500000000000000',
      volume: '300262.1340400000000000',
      quoteVolume: 37295866.3538343,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-12T00:00:00.000Z'
      },
      {
      open: '123.3500000000000000',
      high: '124.2500000000000000',
      low: '111.8700000000000000',
      close: '114.1600000000000000',
      volume: '422561.9677700000000000',
      quoteVolume: 49521201.2764662,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-13T00:00:00.000Z'
      },
      {
      open: '114.1700000000000000',
      high: '130.0500000000000000',
      low: '113.9400000000000000',
      close: '126.9500000000000000',
      volume: '546420.4085400000000000',
      quoteVolume: 66801543.3050366,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-14T00:00:00.000Z'
      },
      {
      open: '126.9500000000000000',
      high: '129.4000000000000000',
      low: '116.0000000000000000',
      close: '118.9500000000000000',
      volume: '649020.5297000000000000',
      quoteVolume: 79985529.5587708,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-15T00:00:00.000Z'
      },
      {
      open: '118.9300000000000000',
      high: '126.8800000000000000',
      low: '118.5200000000000000',
      close: '121.4800000000000000',
      volume: '513486.6547200000000000',
      quoteVolume: 62314225.9960432,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '121.4900000000000000',
      high: '122.9900000000000000',
      low: '116.8000000000000000',
      close: '121.5400000000000000',
      volume: '447535.2271600000000000',
      quoteVolume: 53854069.2105117,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '121.5600000000000000',
      high: '121.9800000000000000',
      low: '117.6600000000000000',
      close: '119.1700000000000000',
      volume: '235646.6840000000000000',
      quoteVolume: 28305971.6688854,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '119.1600000000000000',
      high: '125.2900000000000000',
      low: '119.1400000000000000',
      close: '122.6000000000000000',
      volume: '323303.7929600000000000',
      quoteVolume: 39715159.1649606,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '122.6100000000000000',
      high: '123.3200000000000000',
      low: '115.6800000000000000',
      close: '117.4700000000000000',
      volume: '451792.8535200000000000',
      quoteVolume: 53833668.9066349,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '117.5600000000000000',
      high: '118.5000000000000000',
      low: '113.6100000000000000',
      close: '115.6300000000000000',
      volume: '303632.9346300000000000',
      quoteVolume: 35258073.4282649,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '115.6200000000000000',
      high: '119.4900000000000000',
      low: '111.3900000000000000',
      close: '117.8100000000000000',
      volume: '359542.6387300000000000',
      quoteVolume: 41777737.4704705,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '117.8000000000000000',
      high: '118.9500000000000000',
      low: '114.8000000000000000',
      close: '116.4400000000000000',
      volume: '230247.6157800000000000',
      quoteVolume: 26975386.3924584,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '116.4000000000000000',
      high: '117.1500000000000000',
      low: '113.6100000000000000',
      close: '116.3900000000000000',
      volume: '216242.2982900000000000',
      quoteVolume: 25044265.7542041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '116.4000000000000000',
      high: '117.5000000000000000',
      low: '114.3000000000000000',
      close: '115.1100000000000000',
      volume: '198617.0810800000000000',
      quoteVolume: 22977175.9541193,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '115.1300000000000000',
      high: '118.0000000000000000',
      low: '115.0000000000000000',
      close: '115.3400000000000000',
      volume: '146216.8281900000000000',
      quoteVolume: 16978386.1972377,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '115.3200000000000000',
      high: '116.1500000000000000',
      low: '111.0400000000000000',
      close: '112.0500000000000000',
      volume: '247369.6055500000000000',
      quoteVolume: 28158012.7304768,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '112.0400000000000000',
      high: '112.8700000000000000',
      low: '100.9100000000000000',
      close: '105.5000000000000000',
      volume: '566205.7828800000000000',
      quoteVolume: 60050129.3863579,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '105.4900000000000000',
      high: '106.6100000000000000',
      low: '102.2200000000000000',
      close: '104.4500000000000000',
      volume: '319768.9134800000000000',
      quoteVolume: 33427469.3450618,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '104.4100000000000000',
      high: '109.8000000000000000',
      low: '103.4500000000000000',
      close: '108.2900000000000000',
      volume: '330968.1926500000000000',
      quoteVolume: 35368520.135571,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '108.2900000000000000',
      high: '110.6100000000000000',
      low: '105.4600000000000000',
      close: '106.4400000000000000',
      volume: '290679.6193500000000000',
      quoteVolume: 31234353.515393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '106.4700000000000000',
      high: '108.3500000000000000',
      low: '103.7700000000000000',
      close: '106.7300000000000000',
      volume: '243149.8843500000000000',
      quoteVolume: 25791535.1460238,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '106.7100000000000000',
      high: '111.1500000000000000',
      low: '106.0700000000000000',
      close: '110.4200000000000000',
      volume: '186908.7573000000000000',
      quoteVolume: 20153872.302919,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '110.4100000000000000',
      high: '111.1100000000000000',
      low: '105.8200000000000000',
      close: '107.3600000000000000',
      volume: '227211.5406400000000000',
      quoteVolume: 24605892.2887283,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '107.3300000000000000',
      high: '109.3000000000000000',
      low: '106.5700000000000000',
      close: '107.6800000000000000',
      volume: '160850.1029300000000000',
      quoteVolume: 17312736.6001502,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '107.6500000000000000',
      high: '108.1800000000000000',
      low: '106.4700000000000000',
      close: '107.2700000000000000',
      volume: '159730.1886000000000000',
      quoteVolume: 17137008.2478395,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '107.2800000000000000',
      high: '107.8300000000000000',
      low: '101.2100000000000000',
      close: '104.5000000000000000',
      volume: '337204.3025400000000000',
      quoteVolume: 34984364.2970212,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '104.4900000000000000',
      high: '106.1800000000000000',
      low: '103.8200000000000000',
      close: '104.4800000000000000',
      volume: '147942.0768300000000000',
      quoteVolume: 15530160.6471066,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '104.4800000000000000',
      high: '123.0000000000000000',
      low: '103.4100000000000000',
      close: '119.2500000000000000',
      volume: '589950.6492500000000000',
      quoteVolume: 67027886.9398639,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '119.3200000000000000',
      high: '121.9900000000000000',
      low: '117.6400000000000000',
      close: '119.1900000000000000',
      volume: '328067.9574000000000000',
      quoteVolume: 39061582.3914826,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '119.1900000000000000',
      high: '126.4900000000000000',
      low: '115.8100000000000000',
      close: '125.1200000000000000',
      volume: '385819.0961900000000000',
      quoteVolume: 45929459.1290131,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '125.1200000000000000',
      high: '125.2800000000000000',
      low: '119.2400000000000000',
      close: '120.8900000000000000',
      volume: '389405.3040400000000000',
      quoteVolume: 47197595.845191,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '120.8800000000000000',
      high: '123.8900000000000000',
      low: '118.1800000000000000',
      close: '122.4100000000000000',
      volume: '343765.6698700000000000',
      quoteVolume: 41603632.811699,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '122.4000000000000000',
      high: '126.4700000000000000',
      low: '120.7000000000000000',
      close: '121.8600000000000000',
      volume: '317827.3195600000000000',
      quoteVolume: 39073077.8391656,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '121.8600000000000000',
      high: '124.5000000000000000',
      low: '120.1000000000000000',
      close: '120.4200000000000000',
      volume: '297205.7873800000000000',
      quoteVolume: 36278326.0774455,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '120.4700000000000000',
      high: '124.2500000000000000',
      low: '120.1900000000000000',
      close: '121.6700000000000000',
      volume: '255296.0814000000000000',
      quoteVolume: 31111280.2093057,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '121.6600000000000000',
      high: '124.9900000000000000',
      low: '121.5400000000000000',
      close: '122.6700000000000000',
      volume: '215320.7013400000000000',
      quoteVolume: 26462034.6125396,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '122.6700000000000000',
      high: '135.6100000000000000',
      low: '122.4300000000000000',
      close: '133.7500000000000000',
      volume: '614788.6007800000000000',
      quoteVolume: 78958054.9589805,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '133.7700000000000000',
      high: '148.3000000000000000',
      low: '132.4200000000000000',
      close: '145.6900000000000000',
      volume: '1079830.1502000000000000',
      quoteVolume: 152756654.645478,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '145.6900000000000000',
      high: '150.0000000000000000',
      low: '142.3600000000000000',
      close: '143.4400000000000000',
      volume: '747968.9044500000000000',
      quoteVolume: 109645050.466097,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '143.4500000000000000',
      high: '149.5200000000000000',
      low: '139.6000000000000000',
      close: '148.4400000000000000',
      volume: '614303.6100700000000000',
      quoteVolume: 89136482.3716519,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '148.4200000000000000',
      high: '149.6100000000000000',
      low: '142.7000000000000000',
      close: '145.6900000000000000',
      volume: '447364.4087300000000000',
      quoteVolume: 65199779.5124385,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '145.6800000000000000',
      high: '148.8500000000000000',
      low: '143.8500000000000000',
      close: '147.3600000000000000',
      volume: '366251.1854200000000000',
      quoteVolume: 53771600.8025665,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '147.4000000000000000',
      high: '159.6000000000000000',
      low: '145.7800000000000000',
      close: '157.7900000000000000',
      volume: '588183.7289600000000000',
      quoteVolume: 89997516.9234365,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '157.7500000000000000',
      high: '166.0000000000000000',
      low: '131.7000000000000000',
      close: '132.1000000000000000',
      volume: '1260506.8067200000000000',
      quoteVolume: 186688063.414179,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '132.1000000000000000',
      high: '141.5500000000000000',
      low: '131.8900000000000000',
      close: '137.8700000000000000',
      volume: '801147.0954300000000000',
      quoteVolume: 110348892.970024,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '137.8800000000000000',
      high: '138.4100000000000000',
      low: '133.7200000000000000',
      close: '136.2200000000000000',
      volume: '432944.1703300000000000',
      quoteVolume: 58979934.7160016,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '136.2200000000000000',
      high: '140.2900000000000000',
      low: '126.8400000000000000',
      close: '135.0200000000000000',
      volume: '567202.6103500000000000',
      quoteVolume: 76519497.9080148,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '135.0300000000000000',
      high: '139.0000000000000000',
      low: '132.1000000000000000',
      close: '135.3400000000000000',
      volume: '579327.3174900000000000',
      quoteVolume: 78861797.5593921,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '135.3200000000000000',
      high: '137.9500000000000000',
      low: '134.9000000000000000',
      close: '135.3200000000000000',
      volume: '302632.0283100000000000',
      quoteVolume: 41198722.5798789,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '135.3200000000000000',
      high: '136.1200000000000000',
      low: '130.0300000000000000',
      close: '132.5600000000000000',
      volume: '297005.2809300000000000',
      quoteVolume: 39467507.6857296,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '132.5400000000000000',
      high: '134.6000000000000000',
      low: '128.0100000000000000',
      close: '130.6000000000000000',
      volume: '222453.2859000000000000',
      quoteVolume: 29447917.2382588,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '130.6000000000000000',
      high: '131.1100000000000000',
      low: '122.6000000000000000',
      close: '125.6500000000000000',
      volume: '403947.7944500000000000',
      quoteVolume: 51060349.7665833,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '125.6800000000000000',
      high: '137.8400000000000000',
      low: '125.1900000000000000',
      close: '136.7200000000000000',
      volume: '512631.2317800000000000',
      quoteVolume: 67740644.3311417,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '136.7100000000000000',
      high: '140.2900000000000000',
      low: '133.3300000000000000',
      close: '137.4000000000000000',
      volume: '428826.8741500000000000',
      quoteVolume: 58701328.649431,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '137.3700000000000000',
      high: '140.2000000000000000',
      low: '135.2000000000000000',
      close: '136.8100000000000000',
      volume: '307336.4931000000000000',
      quoteVolume: 42271691.2428617,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '136.8200000000000000',
      high: '139.3400000000000000',
      low: '130.6200000000000000',
      close: '133.5200000000000000',
      volume: '330283.5823300000000000',
      quoteVolume: 44879637.3870354,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '133.5100000000000000',
      high: '138.6500000000000000',
      low: '132.9300000000000000',
      close: '137.2700000000000000',
      volume: '279861.1204900000000000',
      quoteVolume: 38220614.005662,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '137.2700000000000000',
      high: '137.5000000000000000',
      low: '134.0000000000000000',
      close: '135.3700000000000000',
      volume: '203965.1681500000000000',
      quoteVolume: 27650702.3793393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '135.3700000000000000',
      high: '136.1500000000000000',
      low: '131.0000000000000000',
      close: '132.6700000000000000',
      volume: '264587.0068300000000000',
      quoteVolume: 35285847.7482702,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '132.6700000000000000',
      high: '135.3000000000000000',
      low: '130.7700000000000000',
      close: '133.5700000000000000',
      volume: '194905.9684300000000000',
      quoteVolume: 25981446.1033466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '133.6200000000000000',
      high: '133.6500000000000000',
      low: '130.5800000000000000',
      close: '131.6500000000000000',
      volume: '209456.0984800000000000',
      quoteVolume: 27670734.4309227,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '131.6900000000000000',
      high: '135.1100000000000000',
      low: '128.5200000000000000',
      close: '132.2000000000000000',
      volume: '258413.8186800000000000',
      quoteVolume: 34099414.8055834,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '132.2400000000000000',
      high: '137.6200000000000000',
      low: '132.1800000000000000',
      close: '136.5600000000000000',
      volume: '295832.2656200000000000',
      quoteVolume: 39928522.3372778,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '136.6000000000000000',
      high: '144.4700000000000000',
      low: '136.5100000000000000',
      close: '140.9200000000000000',
      volume: '474664.1019700000000000',
      quoteVolume: 66878173.4518186,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '140.9400000000000000',
      high: '141.0700000000000000',
      low: '137.2000000000000000',
      close: '138.6700000000000000',
      volume: '252697.0814800000000000',
      quoteVolume: 35051209.9303395,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '138.6700000000000000',
      high: '142.0100000000000000',
      low: '136.1000000000000000',
      close: '137.5900000000000000',
      volume: '281718.9619500000000000',
      quoteVolume: 39017864.9224565,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '137.6200000000000000',
      high: '139.3800000000000000',
      low: '136.9700000000000000',
      close: '138.5500000000000000',
      volume: '218577.4520800000000000',
      quoteVolume: 30175322.0784989,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '138.5600000000000000',
      high: '139.6600000000000000',
      low: '136.0100000000000000',
      close: '139.0100000000000000',
      volume: '222883.5175400000000000',
      quoteVolume: 30757997.8115518,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '139.0400000000000000',
      high: '139.5500000000000000',
      low: '131.2100000000000000',
      close: '134.8500000000000000',
      volume: '312531.3936800000000000',
      quoteVolume: 42635937.4891712,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '134.8500000000000000',
      high: '137.2000000000000000',
      low: '134.2900000000000000',
      close: '135.9800000000000000',
      volume: '186119.7474500000000000',
      quoteVolume: 25309122.7264498,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '135.9200000000000000',
      high: '138.2100000000000000',
      low: '135.6000000000000000',
      close: '137.2800000000000000',
      volume: '174710.4305900000000000',
      quoteVolume: 23922572.3588934,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '137.2800000000000000',
      high: '137.3700000000000000',
      low: '134.7500000000000000',
      close: '136.0900000000000000',
      volume: '198490.4512400000000000',
      quoteVolume: 27042322.6952009,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '136.1200000000000000',
      high: '136.8300000000000000',
      low: '131.6000000000000000',
      close: '133.9800000000000000',
      volume: '230880.2850000000000000',
      quoteVolume: 31082445.5932449,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '133.9700000000000000',
      high: '134.7400000000000000',
      low: '132.2700000000000000',
      close: '134.1800000000000000',
      volume: '183139.1142600000000000',
      quoteVolume: 24490323.0607397,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '134.1400000000000000',
      high: '140.1400000000000000',
      low: '133.8400000000000000',
      close: '139.4500000000000000',
      volume: '317167.4532500000000000',
      quoteVolume: 43635238.4289265,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '139.4600000000000000',
      high: '139.5700000000000000',
      low: '137.3000000000000000',
      close: '137.8600000000000000',
      volume: '163185.6991000000000000',
      quoteVolume: 22543474.6122913,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '137.8500000000000000',
      high: '143.6000000000000000',
      low: '137.6200000000000000',
      close: '143.2500000000000000',
      volume: '235853.0454000000000000',
      quoteVolume: 33098245.9556135,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '143.2500000000000000',
      high: '145.8900000000000000',
      low: '140.0600000000000000',
      close: '142.4600000000000000',
      volume: '254798.7006700000000000',
      quoteVolume: 36382647.2268952,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '142.4400000000000000',
      high: '142.5000000000000000',
      low: '140.4200000000000000',
      close: '141.3800000000000000',
      volume: '142469.2117100000000000',
      quoteVolume: 20131931.6990724,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '141.4100000000000000',
      high: '143.0800000000000000',
      low: '139.5700000000000000',
      close: '141.2400000000000000',
      volume: '234310.1916100000000000',
      quoteVolume: 33234998.6438733,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '141.2400000000000000',
      high: '165.6000000000000000',
      low: '141.0000000000000000',
      close: '163.3100000000000000',
      volume: '906868.8586000000000000',
      quoteVolume: 138094679.302043,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '163.2600000000000000',
      high: '177.8100000000000000',
      low: '149.2300000000000000',
      close: '159.9600000000000000',
      volume: '1345344.5384500000000000',
      quoteVolume: 225523896.201466,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '159.9600000000000000',
      high: '164.0000000000000000',
      low: '152.8000000000000000',
      close: '157.3600000000000000',
      volume: '686080.9524800000000000',
      quoteVolume: 109082183.587735,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '157.3300000000000000',
      high: '167.8300000000000000',
      low: '155.9400000000000000',
      close: '164.7700000000000000',
      volume: '516070.3455300000000000',
      quoteVolume: 84123101.326904,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '164.8400000000000000',
      high: '172.7700000000000000',
      low: '159.2000000000000000',
      close: '165.5000000000000000',
      volume: '528168.3329700000000000',
      quoteVolume: 87657058.1149817,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '165.5600000000000000',
      high: '175.4300000000000000',
      low: '164.0600000000000000',
      close: '174.2100000000000000',
      volume: '465507.5249700000000000',
      quoteVolume: 78876773.2967177,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '174.2900000000000000',
      high: '185.8700000000000000',
      low: '170.9000000000000000',
      close: '178.7200000000000000',
      volume: '873410.0728500000000000',
      quoteVolume: 156417981.302118,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '178.7100000000000000',
      high: '179.1700000000000000',
      low: '171.0500000000000000',
      close: '174.6000000000000000',
      volume: '431986.3610800000000000',
      quoteVolume: 75745619.5720787,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '174.5800000000000000',
      high: '183.7700000000000000',
      low: '168.0000000000000000',
      close: '177.1200000000000000',
      volume: '523943.2049800000000000',
      quoteVolume: 93283836.587114,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '177.1200000000000000',
      high: '177.4800000000000000',
      low: '158.2700000000000000',
      close: '164.0700000000000000',
      volume: '661305.3032100000000000',
      quoteVolume: 110201659.947876,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '164.0600000000000000',
      high: '166.5000000000000000',
      low: '158.1100000000000000',
      close: '163.1900000000000000',
      volume: '316337.8782300000000000',
      quoteVolume: 51556919.1461367,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '163.2000000000000000',
      high: '165.3700000000000000',
      low: '160.7400000000000000',
      close: '162.9900000000000000',
      volume: '179237.7457400000000000',
      quoteVolume: 29247030.1526373,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '162.9900000000000000',
      high: '168.1100000000000000',
      low: '161.2000000000000000',
      close: '167.2300000000000000',
      volume: '186977.6621100000000000',
      quoteVolume: 30640985.0609499,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '167.2100000000000000',
      high: '168.8100000000000000',
      low: '155.7000000000000000',
      close: '159.9500000000000000',
      volume: '351548.9079000000000000',
      quoteVolume: 57215098.1117043,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '159.9100000000000000',
      high: '166.7300000000000000',
      low: '159.4100000000000000',
      close: '165.3700000000000000',
      volume: '240370.5044100000000000',
      quoteVolume: 39245823.494595,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '165.4100000000000000',
      high: '167.2800000000000000',
      low: '163.7800000000000000',
      close: '165.7500000000000000',
      volume: '227841.5498100000000000',
      quoteVolume: 37678036.5964673,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '165.7500000000000000',
      high: '175.2000000000000000',
      low: '165.0400000000000000',
      close: '173.3300000000000000',
      volume: '396118.9440700000000000',
      quoteVolume: 68031293.2325779,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '173.3400000000000000',
      high: '173.5500000000000000',
      low: '168.5900000000000000',
      close: '172.6400000000000000',
      volume: '320541.7216600000000000',
      quoteVolume: 54991300.1097207,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '172.6600000000000000',
      high: '176.9700000000000000',
      low: '169.8700000000000000',
      close: '172.8800000000000000',
      volume: '253596.4840700000000000',
      quoteVolume: 43939979.0160537,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '172.8900000000000000',
      high: '173.4800000000000000',
      low: '164.8200000000000000',
      close: '168.5900000000000000',
      volume: '283450.4394300000000000',
      quoteVolume: 47781349.4020958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '168.5900000000000000',
      high: '172.3000000000000000',
      low: '167.0000000000000000',
      close: '170.5200000000000000',
      volume: '269868.3987600000000000',
      quoteVolume: 45832751.057015,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '170.5200000000000000',
      high: '175.8300000000000000',
      low: '167.7800000000000000',
      close: '169.1600000000000000',
      volume: '363523.3453100000000000',
      quoteVolume: 62784241.170476,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '169.1500000000000000',
      high: '169.8700000000000000',
      low: '159.2600000000000000',
      close: '164.7800000000000000',
      volume: '426497.0415300000000000',
      quoteVolume: 70076178.2845027,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '164.7800000000000000',
      high: '165.4900000000000000',
      low: '150.3500000000000000',
      close: '154.7200000000000000',
      volume: '422657.0323900000000000',
      quoteVolume: 67326464.6484704,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '154.7000000000000000',
      high: '162.5600000000000000',
      low: '151.4000000000000000',
      close: '157.9500000000000000',
      volume: '500355.8628300000000000',
      quoteVolume: 78334214.8744062,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '157.9300000000000000',
      high: '166.2400000000000000',
      low: '156.3500000000000000',
      close: '159.3100000000000000',
      volume: '215918.6384200000000000',
      quoteVolume: 34294277.9954465,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '159.3200000000000000',
      high: '160.2400000000000000',
      low: '156.9200000000000000',
      close: '157.8500000000000000',
      volume: '152976.3018100000000000',
      quoteVolume: 24304182.1410825,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '157.8600000000000000',
      high: '159.0500000000000000',
      low: '152.0000000000000000',
      close: '155.1100000000000000',
      volume: '243591.9489800000000000',
      quoteVolume: 37892463.2904875,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '155.1400000000000000',
      high: '162.8900000000000000',
      low: '155.1100000000000000',
      close: '161.8300000000000000',
      volume: '305056.2465300000000000',
      quoteVolume: 48352308.3471109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '161.8500000000000000',
      high: '163.8800000000000000',
      low: '158.3600000000000000',
      close: '160.1900000000000000',
      volume: '192517.6276300000000000',
      quoteVolume: 30965351.6968463,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '160.2000000000000000',
      high: '163.4800000000000000',
      low: '158.5000000000000000',
      close: '161.8100000000000000',
      volume: '289519.0202500000000000',
      quoteVolume: 46716417.0127823,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '161.8200000000000000',
      high: '170.8600000000000000',
      low: '160.9900000000000000',
      close: '168.0600000000000000',
      volume: '416357.5212300000000000',
      quoteVolume: 69185162.5971677,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '168.1000000000000000',
      high: '171.3000000000000000',
      low: '159.7600000000000000',
      close: '163.7000000000000000',
      volume: '312928.5834900000000000',
      quoteVolume: 51596174.4008955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '163.7100000000000000',
      high: '165.3600000000000000',
      low: '159.1800000000000000',
      close: '163.0500000000000000',
      volume: '176029.1044500000000000',
      quoteVolume: 28659516.7862457,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '163.0500000000000000',
      high: '178.0500000000000000',
      low: '157.5500000000000000',
      close: '173.0000000000000000',
      volume: '506528.1427800000000000',
      quoteVolume: 86112232.475863,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '172.9800000000000000',
      high: '182.7600000000000000',
      low: '167.8900000000000000',
      close: '169.1800000000000000',
      volume: '732496.8786100000000000',
      quoteVolume: 129206700.127684,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '169.0700000000000000',
      high: '173.1700000000000000',
      low: '164.8000000000000000',
      close: '170.8500000000000000',
      volume: '296410.7610300000000000',
      quoteVolume: 50307994.5847109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '170.8800000000000000',
      high: '173.8800000000000000',
      low: '166.5700000000000000',
      close: '170.9000000000000000',
      volume: '284663.9945600000000000',
      quoteVolume: 48482365.5414184,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '170.9600000000000000',
      high: '176.7000000000000000',
      low: '168.4600000000000000',
      close: '172.9100000000000000',
      volume: '425543.7402100000000000',
      quoteVolume: 73717438.6539584,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '172.9200000000000000',
      high: '205.2300000000000000',
      low: '172.9200000000000000',
      close: '190.2000000000000000',
      volume: '885292.3838900000000000',
      quoteVolume: 167180496.76233,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '190.2000000000000000',
      high: '204.3200000000000000',
      low: '181.0000000000000000',
      close: '188.1100000000000000',
      volume: '823372.2814800000000000',
      quoteVolume: 157611011.725251,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '188.0700000000000000',
      high: '206.8700000000000000',
      low: '185.3200000000000000',
      close: '195.8400000000000000',
      volume: '669265.4652400000000000',
      quoteVolume: 131102303.13857,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '195.8300000000000000',
      high: '219.8100000000000000',
      low: '194.1900000000000000',
      close: '216.4000000000000000',
      volume: '864211.0060300000000000',
      quoteVolume: 179717430.310727,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '216.4000000000000000',
      high: '250.2700000000000000',
      low: '216.2100000000000000',
      close: '248.1000000000000000',
      volume: '574697.2992700000000000',
      quoteVolume: 135054369.61757,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '248.0600000000000000',
      high: '279.7600000000000000',
      low: '241.7600000000000000',
      close: '264.2500000000000000',
      volume: '1733360.8936000000000000',
      quoteVolume: 452410350.051738,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '264.3500000000000000',
      high: '268.7400000000000000',
      low: '223.4300000000000000',
      close: '244.3000000000000000',
      volume: '1529892.5689800000000000',
      quoteVolume: 366429299.202803,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '244.3100000000000000',
      high: '248.0000000000000000',
      low: '230.6200000000000000',
      close: '233.6000000000000000',
      volume: '673218.0998200000000000',
      quoteVolume: 160450040.806919,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '233.5700000000000000',
      high: '264.0000000000000000',
      low: '231.7700000000000000',
      close: '259.5100000000000000',
      volume: '823859.0634200000000000',
      quoteVolume: 207383027.826484,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '259.3700000000000000',
      high: '259.4900000000000000',
      low: '238.0000000000000000',
      close: '250.3700000000000000',
      volume: '666889.8224100000000000',
      quoteVolume: 165783106.07703,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '250.4800000000000000',
      high: '262.0000000000000000',
      low: '246.0600000000000000',
      close: '253.4300000000000000',
      volume: '580249.6975300000000000',
      quoteVolume: 146749988.273737,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '253.3500000000000000',
      high: '260.1800000000000000',
      low: '239.6300000000000000',
      close: '243.6400000000000000',
      volume: '592848.9560500000000000',
      quoteVolume: 149006735.642177,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '243.6800000000000000',
      high: '247.3400000000000000',
      low: '231.1000000000000000',
      close: '244.5600000000000000',
      volume: '600193.8936800000000000',
      quoteVolume: 144051488.877393,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '244.6300000000000000',
      high: '254.3500000000000000',
      low: '241.5400000000000000',
      close: '248.8700000000000000',
      volume: '447066.9259600000000000',
      quoteVolume: 111294049.366715,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '248.7500000000000000',
      high: '257.3300000000000000',
      low: '247.2600000000000000',
      close: '250.2900000000000000',
      volume: '210086.6689900000000000',
      quoteVolume: 52883558.98214837,
      btcVolume: 6601.861190292515,
      usdVolume: 52883558.98214837,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '250.5800000000000000',
      high: '269.3300000000000000',
      low: '245.1100000000000000',
      close: '264.4300000000000000',
      volume: '328906.1700300000000000',
      quoteVolume: 84389359.21664856,
      btcVolume: 10168.644937039242,
      usdVolume: 84389359.21664856,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '264.4400000000000000',
      high: '279.5900000000000000',
      low: '262.0000000000000000',
      close: '270.5700000000000000',
      volume: '430881.9927300000000000',
      quoteVolume: 115553890.30784754,
      btcVolume: 13224.875481256204,
      usdVolume: 115553890.30784754,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '270.6500000000000000',
      high: '275.9500000000000000',
      low: '264.7100000000000000',
      close: '271.5400000000000000',
      volume: '244142.8490000000000000',
      quoteVolume: 66001001.032268964,
      btcVolume: 7580.161845035036,
      usdVolume: 66001001.032268964,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '270.7600000000000000',
      high: '274.0400000000000000',
      low: '260.0500000000000000',
      close: '268.9400000000000000',
      volume: '250176.3188600000000000',
      quoteVolume: 67085217.72845518,
      btcVolume: 7783.531911611134,
      usdVolume: 67085217.72845518,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '268.8900000000000000',
      high: '288.6200000000000000',
      low: '240.1400000000000000',
      close: '254.3900000000000000',
      volume: '874566.2467900000000000',
      quoteVolume: 237214565.53410167,
      btcVolume: 27663.736785432655,
      usdVolume: 237214565.53410167,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '254.5600000000000000',
      high: '268.7200000000000000',
      low: '245.2100000000000000',
      close: '267.5000000000000000',
      volume: '435596.2624900000000000',
      quoteVolume: 111862948.81367387,
      btcVolume: 13416.233814032905,
      usdVolume: 111862948.81367387,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '268.5500000000000000',
      high: '275.5000000000000000',
      low: '260.6800000000000000',
      close: '264.3300000000000000',
      volume: '340251.6517200000000000',
      quoteVolume: 90953282.63449085,
      btcVolume: 10654.39476621369,
      usdVolume: 90953282.63449085,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '264.3300000000000000',
      high: '273.2000000000000000',
      low: '263.2000000000000000',
      close: '268.8800000000000000',
      volume: '301536.1506200000000000',
      quoteVolume: 80960737.4054651,
      btcVolume: 9351.28557475043,
      usdVolume: 80960737.4054651,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '268.8700000000000000',
      high: '270.0000000000000000',
      low: '248.0000000000000000',
      close: '249.9100000000000000',
      volume: '390829.8328400000000000',
      quoteVolume: 101905937.03778602,
      btcVolume: 12020.341131421099,
      usdVolume: 101905937.03778602,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '250.0200000000000000',
      high: '251.6700000000000000',
      low: '234.3900000000000000',
      close: '241.3900000000000000',
      volume: '633054.5403700000000000',
      quoteVolume: 154522639.97359976,
      btcVolume: 19697.30421417344,
      usdVolume: 154522639.97359976,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '241.4600000000000000',
      high: '250.4100000000000000',
      low: '237.3700000000000000',
      close: '245.6700000000000000',
      volume: '335951.0818300000000000',
      quoteVolume: 81838722.29639693,
      btcVolume: 10541.8570319359,
      usdVolume: 81838722.29639693,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '245.6300000000000000',
      high: '250.6600000000000000',
      low: '235.0100000000000000',
      close: '248.7300000000000000',
      volume: '297333.9071700000000000',
      quoteVolume: 72359467.8907992,
      btcVolume: 9375.200502885184,
      usdVolume: 72359467.8907992,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '248.7500000000000000',
      high: '253.2800000000000000',
      low: '243.4600000000000000',
      close: '248.7700000000000000',
      volume: '353382.9471000000000000',
      quoteVolume: 87645350.54723677,
      btcVolume: 11105.098897639275,
      usdVolume: 87645350.54723677,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '248.7800000000000000',
      high: '250.5300000000000000',
      low: '240.3700000000000000',
      close: '243.2500000000000000',
      volume: '188448.2774800000000000',
      quoteVolume: 46293916.7909611,
      btcVolume: 5858.450696551191,
      usdVolume: 46293916.7909611,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '243.3300000000000000',
      high: '244.5300000000000000',
      low: '226.5600000000000000',
      close: '230.9800000000000000',
      volume: '316894.1012500000000000',
      quoteVolume: 74585371.36728549,
      btcVolume: 9656.729097772812,
      usdVolume: 74585371.36728549,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '230.9800000000000000',
      high: '247.4500000000000000',
      low: '227.0300000000000000',
      close: '246.5800000000000000',
      volume: '314636.9740500000000000',
      quoteVolume: 75084193.16334817,
      btcVolume: 9642.047156408467,
      usdVolume: 75084193.16334817,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '246.6400000000000000',
      high: '247.8000000000000000',
      low: '236.4900000000000000',
      close: '244.2800000000000000',
      volume: '236917.8619100000000000',
      quoteVolume: 57405428.93464183,
      btcVolume: 7322.677723536042,
      usdVolume: 57405428.93464183,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '244.2900000000000000',
      high: '261.8700000000000000',
      low: '242.2000000000000000',
      close: '261.0400000000000000',
      volume: '334914.1948300000000000',
      quoteVolume: 84260792.21337387,
      btcVolume: 10493.689905645679,
      usdVolume: 84260792.21337387,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '261.0400000000000000',
      high: '262.9800000000000000',
      low: '253.6900000000000000',
      close: '254.7000000000000000',
      volume: '291757.4165600000000000',
      quoteVolume: 75447458.94669941,
      btcVolume: 9258.053283515925,
      usdVolume: 75447458.94669941,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '254.6700000000000000',
      high: '264.2600000000000000',
      low: '251.7500000000000000',
      close: '262.4700000000000000',
      volume: '327384.9350300000000000',
      quoteVolume: 84046270.42804858,
      btcVolume: 10052.089943759258,
      usdVolume: 84046270.42804858,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '262.4800000000000000',
      high: '272.2700000000000000',
      low: '260.6100000000000000',
      close: '267.8100000000000000',
      volume: '286562.0009300000000000',
      quoteVolume: 76044367.43084204,
      btcVolume: 8744.231544379601,
      usdVolume: 76044367.43084204,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '267.8200000000000000',
      high: '277.0600000000000000',
      low: '263.3100000000000000',
      close: '267.8200000000000000',
      volume: '457043.8053700000000000',
      quoteVolume: 123543186.81485234,
      btcVolume: 13633.549352858961,
      usdVolume: 123543186.81485234,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '267.7100000000000000',
      high: '276.0000000000000000',
      low: '266.8100000000000000',
      close: '273.0900000000000000',
      volume: '339245.3390700000000000',
      quoteVolume: 91798515.34806772,
      btcVolume: 9973.204806382242,
      usdVolume: 91798515.34806772,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '273.1200000000000000',
      high: '273.5800000000000000',
      low: '261.4500000000000000',
      close: '264.6600000000000000',
      volume: '319694.8046200000000000',
      quoteVolume: 85270720.28219834,
      btcVolume: 9341.837857544495,
      usdVolume: 85191137.66587001,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '264.6800000000000000',
      high: '270.0000000000000000',
      low: '263.5900000000000000',
      close: '268.1400000000000000',
      volume: '208374.2487300000000000',
      quoteVolume: 55665534.352045216,
      btcVolume: 6091.847271949922,
      usdVolume: 55665534.352045216,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '268.1400000000000000',
      high: '273.6300000000000000',
      low: '265.7100000000000000',
      close: '271.2200000000000000',
      volume: '197206.8955100000000000',
      quoteVolume: 53051977.61480555,
      btcVolume: 5666.18245046985,
      usdVolume: 53051977.61480555,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '271.2700000000000000',
      high: '295.9400000000000000',
      low: '271.2200000000000000',
      close: '294.3000000000000000',
      volume: '519753.0589000000000000',
      quoteVolume: 148622967.47444713,
      btcVolume: 15205.227567841224,
      usdVolume: 148622967.47444713,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '294.3200000000000000',
      high: '314.3900000000000000',
      low: '289.6900000000000000',
      close: '310.6100000000000000',
      volume: '716598.8706100000000000',
      quoteVolume: 219105237.54071936,
      btcVolume: 20528.78896259923,
      usdVolume: 219105237.54071936,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '310.7000000000000000',
      high: '321.7000000000000000',
      low: '305.5800000000000000',
      close: '308.5200000000000000',
      volume: '418569.8935300000000000',
      quoteVolume: 131176119.43065393,
      btcVolume: 12082.996427040667,
      usdVolume: 131176119.43065393,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '308.4200000000000000',
      high: '314.4100000000000000',
      low: '298.0000000000000000',
      close: '311.6800000000000000',
      volume: '352738.3189300000000000',
      quoteVolume: 108423026.96658173,
      btcVolume: 9981.964869792726,
      usdVolume: 108423026.96658173,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '311.6400000000000000',
      high: '318.9900000000000000',
      low: '307.0000000000000000',
      close: '318.2600000000000000',
      volume: '345551.7547400000000000',
      quoteVolume: 108140713.48472323,
      btcVolume: 9505.539339201336,
      usdVolume: 108140713.48472323,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '318.2900000000000000',
      high: '366.8000000000000000',
      low: '315.5700000000000000',
      close: '339.3900000000000000',
      volume: '977599.1145100000000000',
      quoteVolume: 331095769.33132416,
      btcVolume: 25950.15573069012,
      usdVolume: 331095769.33132416,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '339.5300000000000000',
      high: '347.0000000000000000',
      low: '280.0100000000000000',
      close: '298.3500000000000000',
      volume: '1008017.1146500000000000',
      quoteVolume: 312540982.32200617,
      btcVolume: 26554.17730475792,
      usdVolume: 312540982.32200617,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '298.3500000000000000',
      high: '313.5800000000000000',
      low: '295.2200000000000000',
      close: '310.9400000000000000',
      volume: '529156.4880900000000000',
      quoteVolume: 161833162.7906752,
      btcVolume: 13747.592392081055,
      usdVolume: 161833162.7906752,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '311.0400000000000000',
      high: '324.7400000000000000',
      low: '295.1800000000000000',
      close: '317.6200000000000000',
      volume: '424971.8825400000000000',
      quoteVolume: 130248119.6772739,
      btcVolume: 10938.351707879097,
      usdVolume: 130248119.6772739,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '317.6200000000000000',
      high: '323.5000000000000000',
      low: '290.1200000000000000',
      close: '292.6500000000000000',
      volume: '489053.0179000000000000',
      quoteVolume: 149535574.11292773,
      btcVolume: 12975.985612883589,
      usdVolume: 149535574.11292773,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '292.7900000000000000',
      high: '303.4900000000000000',
      low: '280.1300000000000000',
      close: '295.0700000000000000',
      volume: '489783.9887600000000000',
      quoteVolume: 142876245.52713487,
      btcVolume: 13339.488752079711,
      usdVolume: 142876245.52713487,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '295.0300000000000000',
      high: '297.5700000000000000',
      low: '272.6500000000000000',
      close: '291.4800000000000000',
      volume: '493473.2749400000000000',
      quoteVolume: 139935800.5385573,
      btcVolume: 13679.520911516493,
      usdVolume: 139935800.5385573,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '291.4700000000000000',
      high: '302.8900000000000000',
      low: '285.0000000000000000',
      close: '301.1400000000000000',
      volume: '369294.5282900000000000',
      quoteVolume: 108898870.22858743,
      btcVolume: 9594.832986006992,
      usdVolume: 108898870.22858743,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '301.1100000000000000',
      high: '302.7400000000000000',
      low: '280.5300000000000000',
      close: '282.8200000000000000',
      volume: '269955.9546000000000000',
      quoteVolume: 79265333.87642588,
      btcVolume: 6797.331954964179,
      usdVolume: 79265333.87642588,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '282.8300000000000000',
      high: '294.8900000000000000',
      low: '280.7800000000000000',
      close: '287.4700000000000000',
      volume: '261133.4233100000000000',
      quoteVolume: 75163436.31124392,
      btcVolume: 6755.597179253407,
      usdVolume: 75163436.31124392,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '287.3200000000000000',
      high: '296.7100000000000000',
      low: '285.2800000000000000',
      close: '288.2300000000000000',
      volume: '196064.5701800000000000',
      quoteVolume: 57137760.438672334,
      btcVolume: 5022.881732165552,
      usdVolume: 57137760.438672334,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '288.2900000000000000',
      high: '309.6900000000000000',
      low: '284.2500000000000000',
      close: '304.7200000000000000',
      volume: '246110.6519400000000000',
      quoteVolume: 73170593.82215981,
      btcVolume: 6461.019883114503,
      usdVolume: 73170593.82215981,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '304.7200000000000000',
      high: '313.7400000000000000',
      low: '300.7800000000000000',
      close: '312.0300000000000000',
      volume: '286873.6683400000000000',
      quoteVolume: 88048229.33933607,
      btcVolume: 7475.430726388852,
      usdVolume: 88048229.33933607,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '311.9700000000000000',
      high: '318.1900000000000000',
      low: '302.6900000000000000',
      close: '307.4700000000000000',
      volume: '305980.4263800000000000',
      quoteVolume: 95131881.31853455,
      btcVolume: 7644.49484918057,
      usdVolume: 95131881.31853455,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '307.4600000000000000',
      high: '314.1800000000000000',
      low: '281.0400000000000000',
      close: '289.0700000000000000',
      volume: '453318.0444900000000000',
      quoteVolume: 134696704.5792062,
      btcVolume: 10731.201391195193,
      usdVolume: 134696704.5792062,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '289.2300000000000000',
      high: '289.2300000000000000',
      low: '262.1200000000000000',
      close: '268.6300000000000000',
      volume: '499749.9402200000000000',
      quoteVolume: 135963256.5679943,
      btcVolume: 11808.225077232584,
      usdVolume: 135963256.5679943,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '268.6700000000000000',
      high: '278.9900000000000000',
      low: '266.2100000000000000',
      close: '274.6200000000000000',
      volume: '266703.2448100000000000',
      quoteVolume: 72734970.85533215,
      btcVolume: 6307.693377250677,
      usdVolume: 72734970.85533215,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '274.5300000000000000',
      high: '275.0400000000000000',
      low: '262.2000000000000000',
      close: '268.7900000000000000',
      volume: '205956.2684900000000000',
      quoteVolume: 55322770.19371056,
      btcVolume: 4873.915839593925,
      usdVolume: 55322770.19371056,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '268.8000000000000000',
      high: '269.7400000000000000',
      low: '222.8100000000000000',
      close: '225.8700000000000000',
      volume: '530763.9314500000000000',
      quoteVolume: 128508198.8008858,
      btcVolume: 12029.780617969636,
      usdVolume: 128508198.8008858,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '225.8700000000000000',
      high: '235.5800000000000000',
      low: '203.0800000000000000',
      close: '228.2000000000000000',
      volume: '713930.7343200000000000',
      quoteVolume: 159360393.30564103,
      btcVolume: 15433.265597050142,
      usdVolume: 159360393.30564103,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '228.2000000000000000',
      high: '234.5000000000000000',
      low: '192.2500000000000000',
      close: '198.8800000000000000',
      volume: '703463.4360500000000000',
      quoteVolume: 149069538.53840336,
      btcVolume: 14722.108901822574,
      usdVolume: 149069538.53840336,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '198.8900000000000000',
      high: '219.8600000000000000',
      low: '191.8400000000000000',
      close: '210.8100000000000000',
      volume: '694454.2491200000000000',
      quoteVolume: 142940279.97858965,
      btcVolume: 14966.311310757123,
      usdVolume: 142940279.97858965,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '210.8100000000000000',
      high: '229.6500000000000000',
      low: '205.7100000000000000',
      close: '225.7600000000000000',
      volume: '607720.5507500000000000',
      quoteVolume: 132341223.14210978,
      btcVolume: 13229.997952454805,
      usdVolume: 132225049.702838,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '225.7300000000000000',
      high: '226.2300000000000000',
      low: '213.4000000000000000',
      close: '220.7300000000000000',
      volume: '367728.2543200000000000',
      quoteVolume: 80763332.4244571,
      btcVolume: 7739.2579698659,
      usdVolume: 80763332.4244571,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '220.8400000000000000',
      high: '235.0900000000000000',
      low: '219.7800000000000000',
      close: '228.2000000000000000',
      volume: '324481.0616300000000000',
      quoteVolume: 73975253.97204855,
      btcVolume: 6947.4776892394175,
      usdVolume: 73975253.97204855,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '228.2500000000000000',
      high: '229.6600000000000000',
      low: '216.9900000000000000',
      close: '225.4200000000000000',
      volume: '269941.8271000000000000',
      quoteVolume: 60398168.970782824,
      btcVolume: 5727.828146485641,
      usdVolume: 60398168.970782824,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '225.3800000000000000',
      high: '228.3400000000000000',
      low: '212.2500000000000000',
      close: '217.5100000000000000',
      volume: '270400.5234700000000000',
      quoteVolume: 59405061.677015305,
      btcVolume: 5704.734783903516,
      usdVolume: 59405061.677015305,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '217.5900000000000000',
      high: '219.5500000000000000',
      low: '208.3600000000000000',
      close: '212.6500000000000000',
      volume: '317846.4293800000000000',
      quoteVolume: 67923781.93982494,
      btcVolume: 6750.32948411748,
      usdVolume: 67923781.93982494,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '212.4500000000000000',
      high: '218.2800000000000000',
      low: '202.0000000000000000',
      close: '216.2400000000000000',
      volume: '330735.0082700000000000',
      quoteVolume: 69192671.19910969,
      btcVolume: 7118.715377791444,
      usdVolume: 69192671.19910969,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '216.3500000000000000',
      high: '225.1200000000000000',
      low: '215.2300000000000000',
      close: '219.1400000000000000',
      volume: '280365.4146300000000000',
      quoteVolume: 62016363.6238403,
      btcVolume: 6193.944875951812,
      usdVolume: 62016363.6238403,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '219.1400000000000000',
      high: '220.0000000000000000',
      low: '212.7100000000000000',
      close: '218.8100000000000000',
      volume: '197602.4657900000000000',
      quoteVolume: 42800909.35558814,
      btcVolume: 4382.145350153194,
      usdVolume: 42800909.35558814,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '218.8100000000000000',
      high: '223.3000000000000000',
      low: '203.0000000000000000',
      close: '207.2300000000000000',
      volume: '296667.0630100000000000',
      quoteVolume: 62964379.504462086,
      btcVolume: 6496.223467135922,
      usdVolume: 62964379.504462086,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '207.3300000000000000',
      high: '213.5200000000000000',
      low: '198.2400000000000000',
      close: '211.6200000000000000',
      volume: '214577.6842300000000000',
      quoteVolume: 44564653.222721346,
      btcVolume: 4706.346521404506,
      usdVolume: 44564653.222721346,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '211.5800000000000000',
      high: '215.8300000000000000',
      low: '206.5900000000000000',
      close: '210.8900000000000000',
      volume: '225967.7936000000000000',
      quoteVolume: 47645918.66213094,
      btcVolume: 4986.044256069211,
      usdVolume: 47645918.66213094,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '210.8400000000000000',
      high: '214.3600000000000000',
      low: '204.4000000000000000',
      close: '209.5800000000000000',
      volume: '175533.8397300000000000',
      quoteVolume: 36699059.76343268,
      btcVolume: 3850.9828761000917,
      usdVolume: 36698995.585413486,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '209.5700000000000000',
      high: '218.7900000000000000',
      low: '209.2000000000000000',
      close: '218.4200000000000000',
      volume: '207213.5565800000000000',
      quoteVolume: 44416634.90026903,
      btcVolume: 4513.526614405959,
      usdVolume: 44416634.90026903,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '218.4200000000000000',
      high: '219.3900000000000000',
      low: '210.5400000000000000',
      close: '216.8400000000000000',
      volume: '186806.1884400000000000',
      quoteVolume: 40054998.682404175,
      btcVolume: 3972.6309014935255,
      usdVolume: 40054998.682404175,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '216.8000000000000000',
      high: '222.1800000000000000',
      low: '214.3100000000000000',
      close: '217.6100000000000000',
      volume: '204158.9276400000000000',
      quoteVolume: 44530976.07793969,
      btcVolume: 4253.794355309355,
      usdVolume: 44530976.07793969,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '217.6900000000000000',
      high: '224.5100000000000000',
      low: '216.6200000000000000',
      close: '222.1400000000000000',
      volume: '181591.9529600000000000',
      quoteVolume: 40285734.53192182,
      btcVolume: 3743.4532071145845,
      usdVolume: 40285734.53192182,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '222.1400000000000000',
      high: '223.3400000000000000',
      low: '216.9000000000000000',
      close: '221.7900000000000000',
      volume: '134883.5495500000000000',
      quoteVolume: 29674383.960012592,
      btcVolume: 2749.4027954145995,
      usdVolume: 29674383.960012592,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '221.7900000000000000',
      high: '236.2500000000000000',
      low: '221.7900000000000000',
      close: '233.5400000000000000',
      volume: '307911.0830700000000000',
      quoteVolume: 70951607.64964774,
      btcVolume: 6104.385556472976,
      usdVolume: 70951607.64964774,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '233.5300000000000000',
      high: '239.1500000000000000',
      low: '223.0300000000000000',
      close: '226.2800000000000000',
      volume: '332632.0690500000000000',
      quoteVolume: 76612125.24778406,
      btcVolume: 6481.8262212509835,
      usdVolume: 76612125.24778406,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '226.3100000000000000',
      high: '231.2500000000000000',
      low: '220.9500000000000000',
      close: '226.1000000000000000',
      volume: '276075.3803400000000000',
      quoteVolume: 62453877.71585768,
      btcVolume: 5316.932199587181,
      usdVolume: 62453877.71585768,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '226.1100000000000000',
      high: '228.5000000000000000',
      low: '215.5100000000000000',
      close: '221.3800000000000000',
      volume: '235285.6142500000000000',
      quoteVolume: 52379480.13759483,
      btcVolume: 4440.469334714355,
      usdVolume: 52379480.13759483,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '221.3800000000000000',
      high: '221.7900000000000000',
      low: '207.3000000000000000',
      close: '210.5900000000000000',
      volume: '230958.0568000000000000',
      quoteVolume: 49180479.38156705,
      btcVolume: 4160.003984113228,
      usdVolume: 49180479.38156705,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '210.5600000000000000',
      high: '215.0000000000000000',
      low: '202.6000000000000000',
      close: '206.4800000000000000',
      volume: '248474.4130400000000000',
      quoteVolume: 51678057.27487786,
      btcVolume: 4460.255650817106,
      usdVolume: 51678057.27487786,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '206.4800000000000000',
      high: '216.9400000000000000',
      low: '206.1400000000000000',
      close: '216.5700000000000000',
      volume: '187625.9175000000000000',
      quoteVolume: 39691954.109307386,
      btcVolume: 3486.7880886797293,
      usdVolume: 39691954.109307386,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '216.5700000000000000',
      high: '216.8400000000000000',
      low: '209.7500000000000000',
      close: '211.5600000000000000',
      volume: '123598.5398700000000000',
      quoteVolume: 26272463.184408363,
      btcVolume: 2303.3073218777877,
      usdVolume: 26272463.184408363,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '211.4100000000000000',
      high: '214.3000000000000000',
      low: '204.0000000000000000',
      close: '209.3300000000000000',
      volume: '166922.4822300000000000',
      quoteVolume: 34861802.56011053,
      btcVolume: 3125.4337664048094,
      usdVolume: 34861802.56011053,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '209.3000000000000000',
      high: '209.9000000000000000',
      low: '183.5600000000000000',
      close: '187.0900000000000000',
      volume: '310158.0672200000000000',
      quoteVolume: 60710593.62070666,
      btcVolume: 5862.423952661254,
      usdVolume: 60710593.62070666,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '187.1000000000000000',
      high: '189.9500000000000000',
      low: '181.2300000000000000',
      close: '188.0300000000000000',
      volume: '237166.2993100000000000',
      quoteVolume: 44039710.624289505,
      btcVolume: 4342.288061699282,
      usdVolume: 44039710.624289505,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '187.9800000000000000',
      high: '188.3900000000000000',
      low: '178.0400000000000000',
      close: '184.8800000000000000',
      volume: '282176.9227900000000000',
      quoteVolume: 51945165.33384251,
      btcVolume: 5121.74136567943,
      usdVolume: 51945165.33384251,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '184.8300000000000000',
      high: '187.0000000000000000',
      low: '181.8300000000000000',
      close: '185.5900000000000000',
      volume: '138799.6150800000000000',
      quoteVolume: 25599796.904651288,
      btcVolume: 2487.8626142405346,
      usdVolume: 25599796.904651288,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '185.6700000000000000',
      high: '197.9100000000000000',
      low: '183.3500000000000000',
      close: '194.3300000000000000',
      volume: '175216.9309700000000000',
      quoteVolume: 33627585.449778885,
      btcVolume: 3252.016245410699,
      usdVolume: 33627585.449778885,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '194.3200000000000000',
      high: '203.5900000000000000',
      low: '192.7000000000000000',
      close: '202.2800000000000000',
      volume: '239158.0927100000000000',
      quoteVolume: 47599954.978613,
      btcVolume: 4479.389668521723,
      usdVolume: 47599954.978613,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '202.2400000000000000',
      high: '202.7500000000000000',
      low: '194.4500000000000000',
      close: '196.6000000000000000',
      volume: '189101.1123700000000000',
      quoteVolume: 37373990.242117286,
      btcVolume: 3483.295716474263,
      usdVolume: 37373990.242117286,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '196.5500000000000000',
      high: '197.2000000000000000',
      low: '179.5300000000000000',
      close: '187.4500000000000000',
      volume: '284348.0028800000000000',
      quoteVolume: 53317203.7731113,
      btcVolume: 5220.638258204045,
      usdVolume: 53317203.7731113,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '187.4500000000000000',
      high: '195.1400000000000000',
      low: '182.8000000000000000',
      close: '190.4000000000000000',
      volume: '245448.2435900000000000',
      quoteVolume: 46402513.17893997,
      btcVolume: 4613.9219820769085,
      usdVolume: 46402513.17893997,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '190.3500000000000000',
      high: '196.1900000000000000',
      low: '188.1600000000000000',
      close: '194.0200000000000000',
      volume: '171898.6596700000000000',
      quoteVolume: 33061516.57383751,
      btcVolume: 3230.660408468503,
      usdVolume: 33061516.57383751,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '194.0200000000000000',
      high: '194.0900000000000000',
      low: '185.6300000000000000',
      close: '190.6000000000000000',
      volume: '167806.3429400000000000',
      quoteVolume: 31786227.862663552,
      btcVolume: 3148.57127433382,
      usdVolume: 31786227.862663552,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '190.6000000000000000',
      high: '192.4000000000000000',
      low: '182.8000000000000000',
      close: '186.5400000000000000',
      volume: '168041.6810300000000000',
      quoteVolume: 31596795.659395352,
      btcVolume: 3133.1655657182055,
      usdVolume: 31596795.659395352,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '186.5400000000000000',
      high: '193.7000000000000000',
      low: '186.0000000000000000',
      close: '188.5900000000000000',
      volume: '248347.1284900000000000',
      quoteVolume: 47147744.7486281,
      btcVolume: 4546.553792072835,
      usdVolume: 47147744.7486281,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '188.6400000000000000',
      high: '189.4900000000000000',
      low: '184.7500000000000000',
      close: '187.2400000000000000',
      volume: '157901.7820000000000000',
      quoteVolume: 29513850.7927091,
      btcVolume: 2896.2658559704814,
      usdVolume: 29513850.7927091,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '187.3000000000000000',
      high: '188.2200000000000000',
      low: '166.4800000000000000',
      close: '173.0300000000000000',
      volume: '305449.4437100000000000',
      quoteVolume: 54488502.82976617,
      btcVolume: 5471.412145390252,
      usdVolume: 54488502.82976617,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '173.0300000000000000',
      high: '173.5000000000000000',
      low: '163.6100000000000000',
      close: '169.0100000000000000',
      volume: '295241.2160000000000000',
      quoteVolume: 49838097.166188926,
      btcVolume: 5238.717079717483,
      usdVolume: 49838097.166188926,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '169.0300000000000000',
      high: '170.7700000000000000',
      low: '165.5500000000000000',
      close: '168.5000000000000000',
      volume: '238511.3647300000000000',
      quoteVolume: 40199011.83567741,
      btcVolume: 4214.966705259767,
      usdVolume: 40199011.83567741,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '168.4800000000000000',
      high: '174.9800000000000000',
      low: '165.6300000000000000',
      close: '171.5700000000000000',
      volume: '194999.1958300000000000',
      quoteVolume: 32983642.84325837,
      btcVolume: 3439.4432364087634,
      usdVolume: 32983642.84325837,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '171.5200000000000000',
      high: '173.4200000000000000',
      low: '167.6100000000000000',
      close: '170.7400000000000000',
      volume: '191088.8305400000000000',
      quoteVolume: 32618691.233790867,
      btcVolume: 3391.76802121759,
      usdVolume: 32618691.233790867,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '170.7300000000000000',
      high: '181.0000000000000000',
      low: '170.0200000000000000',
      close: '178.0500000000000000',
      volume: '294320.5420600000000000',
      quoteVolume: 51392254.91867205,
      btcVolume: 5145.478077032096,
      usdVolume: 51392254.91867205,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '178.0000000000000000',
      high: '183.0000000000000000',
      low: '174.2800000000000000',
      close: '178.7500000000000000',
      volume: '325198.5117100000000000',
      quoteVolume: 58155903.15998513,
      btcVolume: 5528.606852780161,
      usdVolume: 58155903.15998513,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '178.7900000000000000',
      high: '180.1400000000000000',
      low: '173.0000000000000000',
      close: '174.7200000000000000',
      volume: '286198.2752900000000000',
      quoteVolume: 50624411.02937586,
      btcVolume: 4799.552094657055,
      usdVolume: 50624411.02937586,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '174.7000000000000000',
      high: '176.1900000000000000',
      low: '168.1000000000000000',
      close: '173.7500000000000000',
      volume: '231857.0212900000000000',
      quoteVolume: 40092220.48523467,
      btcVolume: 3798.553502855217,
      usdVolume: 40092220.48523467,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '173.7400000000000000',
      high: '177.8700000000000000',
      low: '165.0000000000000000',
      close: '169.0800000000000000',
      volume: '310365.3140900000000000',
      quoteVolume: 53620745.30355337,
      btcVolume: 5073.360788024353,
      usdVolume: 53620745.30355337,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '169.1100000000000000',
      high: '180.8000000000000000',
      low: '168.3000000000000000',
      close: '177.6200000000000000',
      volume: '251633.8069800000000000',
      quoteVolume: 43892369.55699689,
      btcVolume: 4209.284863585666,
      usdVolume: 43892369.55699689,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '177.5800000000000000',
      high: '184.1800000000000000',
      low: '176.3000000000000000',
      close: '181.1900000000000000',
      volume: '288720.1333900000000000',
      quoteVolume: 52178342.21652669,
      btcVolume: 4999.145556813438,
      usdVolume: 52178342.21652669,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '181.1800000000000000',
      high: '185.3800000000000000',
      low: '176.0100000000000000',
      close: '180.5400000000000000',
      volume: '270831.2111100000000000',
      quoteVolume: 48805236.52920722,
      btcVolume: 4741.73754696686,
      usdVolume: 48805236.52920722,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '180.5200000000000000',
      high: '184.3600000000000000',
      low: '177.0000000000000000',
      close: '179.8800000000000000',
      volume: '238217.8448800000000000',
      quoteVolume: 43037704.07242178,
      btcVolume: 4213.994957892011,
      usdVolume: 43037704.07242178,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '179.8700000000000000',
      high: '182.8000000000000000',
      low: '173.0000000000000000',
      close: '178.2800000000000000',
      volume: '278560.0964800000000000',
      quoteVolume: 49658310.75524305,
      btcVolume: 4937.86830579354,
      usdVolume: 49658310.75524305,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '178.3000000000000000',
      high: '182.3800000000000000',
      low: '176.6200000000000000',
      close: '180.7200000000000000',
      volume: '203335.8256500000000000',
      quoteVolume: 36383120.69543437,
      btcVolume: 3557.784215657219,
      usdVolume: 36383120.69543437,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '180.7100000000000000',
      high: '181.3800000000000000',
      low: '177.5400000000000000',
      close: '180.9500000000000000',
      volume: '264421.1936600000000000',
      quoteVolume: 47382185.41032426,
      btcVolume: 4605.8993130550225,
      usdVolume: 47382185.41032426,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '180.9600000000000000',
      high: '188.7900000000000000',
      low: '179.7500000000000000',
      close: '188.1300000000000000',
      volume: '279315.8876300000000000',
      quoteVolume: 51414430.17027494,
      btcVolume: 4974.16648427331,
      usdVolume: 51414430.17027494,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '188.1400000000000000',
      high: '190.4500000000000000',
      low: '185.7600000000000000',
      close: '189.0300000000000000',
      volume: '288928.6082700000000000',
      quoteVolume: 54373471.38522485,
      btcVolume: 5276.705425791319,
      usdVolume: 54373471.38522485,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '189.0500000000000000',
      high: '199.4400000000000000',
      low: '188.3000000000000000',
      close: '197.2300000000000000',
      volume: '549687.6874800000000000',
      quoteVolume: 106195854.54559186,
      btcVolume: 10379.571268151718,
      usdVolume: 106195854.54559186,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '197.2200000000000000',
      high: '215.1300000000000000',
      low: '195.7400000000000000',
      close: '207.8400000000000000',
      volume: '715858.9165100000000000',
      quoteVolume: 145944198.49593797,
      btcVolume: 14286.041939823335,
      usdVolume: 145944198.49593797,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '207.8500000000000000',
      high: '217.2700000000000000',
      low: '207.6600000000000000',
      close: '210.2100000000000000',
      volume: '539028.1435900000000000',
      quoteVolume: 114703029.02276355,
      btcVolume: 11256.168265878949,
      usdVolume: 114703029.02276355,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '210.2700000000000000',
      high: '223.9400000000000000',
      low: '202.3000000000000000',
      close: '220.2400000000000000',
      volume: '831524.9647500000000000',
      quoteVolume: 176952550.75094664,
      btcVolume: 17699.54227982766,
      usdVolume: 176952550.75094664,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '220.2600000000000000',
      high: '221.5400000000000000',
      low: '212.0500000000000000',
      close: '218.0300000000000000',
      volume: '437246.7696400000000000',
      quoteVolume: 94949393.91495149,
      btcVolume: 9332.199000692983,
      usdVolume: 94949393.91495149,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '218.0100000000000000',
      high: '221.5000000000000000',
      low: '213.2000000000000000',
      close: '215.0500000000000000',
      volume: '417891.5242000000000000',
      quoteVolume: 90638548.23529331,
      btcVolume: 9023.05159525662,
      usdVolume: 90638548.23529331,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '215.0400000000000000',
      high: '215.6100000000000000',
      low: '206.1000000000000000',
      close: '211.2000000000000000',
      volume: '445348.5123300000000000',
      quoteVolume: 93653967.98538981,
      btcVolume: 9389.012165487718,
      usdVolume: 93653967.98538981,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '211.2000000000000000',
      high: '211.6800000000000000',
      low: '198.6500000000000000',
      close: '201.2900000000000000',
      volume: '392320.7899200000000000',
      quoteVolume: 80899036.09704779,
      btcVolume: 8221.249859609254,
      usdVolume: 80899036.09704779,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '201.2500000000000000',
      high: '202.9800000000000000',
      low: '150.9700000000000000',
      close: '165.6900000000000000',
      volume: '1370149.8274300000000000',
      quoteVolume: 245718654.8582988,
      btcVolume: 27141.120316206176,
      usdVolume: 245718654.8582988,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '165.7400000000000000',
      high: '174.8500000000000000',
      low: '161.8800000000000000',
      close: '169.9700000000000000',
      volume: '878956.3378100000000000',
      quoteVolume: 147611978.20665354,
      btcVolume: 17503.743620916684,
      usdVolume: 147611978.20665354,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '169.9600000000000000',
      high: '171.0100000000000000',
      low: '152.1100000000000000',
      close: '165.9200000000000000',
      volume: '770883.6988700000000000',
      quoteVolume: 126485225.43295789,
      btcVolume: 15552.32826268824,
      usdVolume: 126485225.43295789,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '165.9200000000000000',
      high: '176.7200000000000000',
      low: '161.0300000000000000',
      close: '173.7900000000000000',
      volume: '634893.1348000000000000',
      quoteVolume: 106454562.85455583,
      btcVolume: 13228.604400340973,
      usdVolume: 106454522.86135663,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '173.8300000000000000',
      high: '175.4900000000000000',
      low: '168.0000000000000000',
      close: '173.4900000000000000',
      volume: '517943.1928800000000000',
      quoteVolume: 89460465.58471853,
      btcVolume: 11001.489635218039,
      usdVolume: 89460465.58471853,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '173.4900000000000000',
      high: '174.5000000000000000',
      low: '164.1200000000000000',
      close: '169.2400000000000000',
      volume: '410856.2773000000000000',
      quoteVolume: 69327426.95232227,
      btcVolume: 8623.060832254734,
      usdVolume: 69327426.95232227,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '169.2600000000000000',
      high: '181.2400000000000000',
      low: '165.0100000000000000',
      close: '180.8500000000000000',
      volume: '580295.3997000000000000',
      quoteVolume: 100443187.77168407,
      btcVolume: 12452.06118869834,
      usdVolume: 100443187.77168407,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '180.8900000000000000',
      high: '185.5300000000000000',
      low: '173.1900000000000000',
      close: '175.6600000000000000',
      volume: '609952.9387900000000000',
      quoteVolume: 109361456.4661927,
      btcVolume: 13111.359686678557,
      usdVolume: 109361456.4661927,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '175.6500000000000000',
      high: '181.2900000000000000',
      low: '173.6500000000000000',
      close: '180.2400000000000000',
      volume: '348268.1162000000000000',
      quoteVolume: 61530973.80423232,
      btcVolume: 7459.867727760892,
      usdVolume: 61530973.80423232,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '180.2400000000000000',
      high: '180.7200000000000000',
      low: '169.5500000000000000',
      close: '174.6900000000000000',
      volume: '354756.7847800000000000',
      quoteVolume: 62001237.48178279,
      btcVolume: 7563.155490555289,
      usdVolume: 62001237.48178279,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '174.7100000000000000',
      high: '178.9800000000000000',
      low: '170.7400000000000000',
      close: '175.5500000000000000',
      volume: '333817.1892000000000000',
      quoteVolume: 58456089.07824363,
      btcVolume: 7179.203250367646,
      usdVolume: 58456089.07824363,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '175.5500000000000000',
      high: '176.7100000000000000',
      low: '172.0200000000000000',
      close: '176.2500000000000000',
      volume: '278096.7557300000000000',
      quoteVolume: 48579546.419203795,
      btcVolume: 6005.859799986798,
      usdVolume: 48579546.419203795,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '176.2300000000000000',
      high: '177.0400000000000000',
      low: '167.6800000000000000',
      close: '170.1200000000000000',
      volume: '313627.4917500000000000',
      quoteVolume: 54020278.79604051,
      btcVolume: 6804.035467902695,
      usdVolume: 54020278.79604051,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '170.1000000000000000',
      high: '182.3200000000000000',
      low: '168.6800000000000000',
      close: '179.8800000000000000',
      volume: '496335.0680100000000000',
      quoteVolume: 87584450.44061345,
      btcVolume: 10846.12187727844,
      usdVolume: 87584450.44061345,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '179.8900000000000000',
      high: '184.8700000000000000',
      low: '177.0000000000000000',
      close: '180.6000000000000000',
      volume: '400833.1661700000000000',
      quoteVolume: 72432871.7087306,
      btcVolume: 8826.82276500747,
      usdVolume: 72432871.7087306,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '180.6100000000000000',
      high: '195.5300000000000000',
      low: '178.9600000000000000',
      close: '192.6200000000000000',
      volume: '557835.1119300000000000',
      quoteVolume: 104652037.76379311,
      btcVolume: 12510.317498902197,
      usdVolume: 104652037.76379311,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '192.6100000000000000',
      high: '194.2000000000000000',
      low: '186.8800000000000000',
      close: '191.1400000000000000',
      volume: '436576.1546100000000000',
      quoteVolume: 83365576.54034896,
      btcVolume: 9773.782550866496,
      usdVolume: 83365576.54034896,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '191.1400000000000000',
      high: '196.6500000000000000',
      low: '179.4100000000000000',
      close: '180.6500000000000000',
      volume: '621046.7695900000000000',
      quoteVolume: 115752733.32752092,
      btcVolume: 13750.640098528595,
      usdVolume: 115752733.32752092,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '180.6500000000000000',
      high: '184.6400000000000000',
      low: '177.5900000000000000',
      close: '179.6800000000000000',
      volume: '290372.9091400000000000',
      quoteVolume: 52887563.36652836,
      btcVolume: 6354.686685667993,
      usdVolume: 52887563.36652836,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '179.6500000000000000',
      high: '184.9500000000000000',
      low: '178.5200000000000000',
      close: '180.9900000000000000',
      volume: '247588.8460700000000000',
      quoteVolume: 45067270.51547066,
      btcVolume: 5399.218077546016,
      usdVolume: 45067270.51547066,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '181.0000000000000000',
      high: '187.5400000000000000',
      low: '180.4300000000000000',
      close: '186.7200000000000000',
      volume: '279731.4719000000000000',
      quoteVolume: 51494611.47577687,
      btcVolume: 6199.09079783479,
      usdVolume: 51494611.47577687,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '186.7100000000000000',
      high: '188.3700000000000000',
      low: '175.9600000000000000',
      close: '180.4900000000000000',
      volume: '400889.5286500000000000',
      quoteVolume: 73178688.57269453,
      btcVolume: 8861.147026864002,
      usdVolume: 73178688.57269453,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '180.4900000000000000',
      high: '181.4400000000000000',
      low: '171.8100000000000000',
      close: '174.4700000000000000',
      volume: '347333.7878200000000000',
      quoteVolume: 61105309.00180442,
      btcVolume: 7601.657333953499,
      usdVolume: 61105309.00180442,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '174.5200000000000000',
      high: '178.9600000000000000',
      low: '172.6100000000000000',
      close: '177.1600000000000000',
      volume: '298795.8198000000000000',
      quoteVolume: 52578052.94403298,
      btcVolume: 6542.154565971178,
      usdVolume: 52578052.94403298,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '177.1700000000000000',
      high: '177.4400000000000000',
      low: '168.6600000000000000',
      close: '172.7400000000000000',
      volume: '318571.4343500000000000',
      quoteVolume: 55106462.09562457,
      btcVolume: 6943.9535656951975,
      usdVolume: 55106462.09562457,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '172.7800000000000000',
      high: '174.9800000000000000',
      low: '169.4400000000000000',
      close: '171.8900000000000000',
      volume: '296902.3488500000000000',
      quoteVolume: 51266629.241353355,
      btcVolume: 6441.994366966404,
      usdVolume: 51266629.241353355,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '171.8600000000000000',
      high: '176.8800000000000000',
      low: '169.2100000000000000',
      close: '175.2200000000000000',
      volume: '299146.0715200000000000',
      quoteVolume: 51655109.15151142,
      btcVolume: 6436.934282521773,
      usdVolume: 51655109.15151142,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '175.1800000000000000',
      high: '177.9000000000000000',
      low: '171.5900000000000000',
      close: '173.9800000000000000',
      volume: '270573.1392900000000000',
      quoteVolume: 47198240.85169531,
      btcVolume: 5741.980152076821,
      usdVolume: 47198240.85169531,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '174.0000000000000000',
      high: '175.0400000000000000',
      low: '170.3000000000000000',
      close: '171.2400000000000000',
      volume: '255425.1915800000000000',
      quoteVolume: 44175399.86161367,
      btcVolume: 5395.723198048019,
      usdVolume: 44175399.86161367,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '171.2000000000000000',
      high: '171.4900000000000000',
      low: '153.4500000000000000',
      close: '162.3500000000000000',
      volume: '707323.9820400000000000',
      quoteVolume: 114779124.2515252,
      btcVolume: 14987.397322367471,
      usdVolume: 114779124.2515252,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '162.3500000000000000',
      high: '163.7200000000000000',
      low: '158.7200000000000000',
      close: '160.3800000000000000',
      volume: '386873.3559300000000000',
      quoteVolume: 62349254.66595083,
      btcVolume: 8383.711086675577,
      usdVolume: 62349254.66595083,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '160.3900000000000000',
      high: '187.7500000000000000',
      low: '160.2500000000000000',
      close: '181.5000000000000000',
      volume: '868260.5296600000000000',
      quoteVolume: 151040465.53858292,
      btcVolume: 18615.31944889506,
      usdVolume: 151040465.53858292,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '181.5300000000000000',
      high: '197.7400000000000000',
      low: '173.8000000000000000',
      close: '179.4900000000000000',
      volume: '1189930.8960700000000000',
      quoteVolume: 218719059.8162375,
      btcVolume: 23386.331259630017,
      usdVolume: 218719059.8162375,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '179.4200000000000000',
      high: '188.7000000000000000',
      low: '176.2200000000000000',
      close: '183.7500000000000000',
      volume: '723948.0442200000000000',
      quoteVolume: 132240902.2748802,
      btcVolume: 13994.80514249749,
      usdVolume: 132240902.2748802,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '183.8400000000000000',
      high: '189.4800000000000000',
      low: '180.3500000000000000',
      close: '181.7200000000000000',
      volume: '581705.0428700000000000',
      quoteVolume: 106847690.6990435,
      btcVolume: 11272.596969686028,
      usdVolume: 106847690.6990435,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '181.6700000000000000',
      high: '192.7400000000000000',
      low: '181.2600000000000000',
      close: '190.4600000000000000',
      volume: '529266.3083100000000000',
      quoteVolume: 98895301.78917634,
      btcVolume: 10554.02278596135,
      usdVolume: 98895301.78917634,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '190.4500000000000000',
      high: '191.7100000000000000',
      low: '179.2800000000000000',
      close: '183.1300000000000000',
      volume: '537770.4305600000000000',
      quoteVolume: 99508269.89031763,
      btcVolume: 10838.56631729719,
      usdVolume: 99508269.89031763,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '183.1400000000000000',
      high: '185.2700000000000000',
      low: '177.6600000000000000',
      close: '182.1800000000000000',
      volume: '410609.1639300000000000',
      quoteVolume: 74631751.23578401,
      btcVolume: 8159.802334886984,
      usdVolume: 74631751.23578401,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '182.1900000000000000',
      high: '184.5000000000000000',
      low: '177.0200000000000000',
      close: '182.8600000000000000',
      volume: '331518.2372300000000000',
      quoteVolume: 60092446.93983594,
      btcVolume: 6574.424243967809,
      usdVolume: 60092446.93983594,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '182.8500000000000000',
      high: '186.0000000000000000',
      low: '181.5300000000000000',
      close: '182.9100000000000000',
      volume: '179852.0178300000000000',
      quoteVolume: 32934405.78327263,
      btcVolume: 3551.462296933882,
      usdVolume: 32934405.78327263,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '182.9000000000000000',
      high: '184.7000000000000000',
      low: '178.9500000000000000',
      close: '181.5400000000000000',
      volume: '232349.8479600000000000',
      quoteVolume: 42211230.11712696,
      btcVolume: 4590.906257325065,
      usdVolume: 42211230.11712696,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '181.5300000000000000',
      high: '188.6400000000000000',
      low: '180.3600000000000000',
      close: '185.7200000000000000',
      volume: '318731.1727400000000000',
      quoteVolume: 58897915.884484924,
      btcVolume: 6352.353989298192,
      usdVolume: 58897915.884484924,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '185.7100000000000000',
      high: '192.5100000000000000',
      low: '182.2200000000000000',
      close: '188.6800000000000000',
      volume: '389421.1059000000000000',
      quoteVolume: 72988899.54730941,
      btcVolume: 7828.37934264765,
      usdVolume: 72988899.54730941,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '188.6500000000000000',
      high: '195.0900000000000000',
      low: '187.7200000000000000',
      close: '191.1600000000000000',
      volume: '343219.9224000000000000',
      quoteVolume: 65471595.20700819,
      btcVolume: 7012.585366600161,
      usdVolume: 65471595.20700819,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '191.1600000000000000',
      high: '192.2700000000000000',
      low: '184.5900000000000000',
      close: '186.6800000000000000',
      volume: '309882.0820600000000000',
      quoteVolume: 58150631.2768292,
      btcVolume: 6291.1791580124855,
      usdVolume: 58150631.2768292,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '186.6700000000000000',
      high: '188.2600000000000000',
      low: '181.4100000000000000',
      close: '183.7400000000000000',
      volume: '364493.9337200000000000',
      quoteVolume: 67207601.06199925,
      btcVolume: 7488.491062509954,
      usdVolume: 67207601.06199925,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '183.7100000000000000',
      high: '185.7900000000000000',
      low: '182.6300000000000000',
      close: '184.8900000000000000',
      volume: '192073.3804400000000000',
      quoteVolume: 35441967.76570041,
      btcVolume: 4019.998146716231,
      usdVolume: 35441967.76570041,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '184.8600000000000000',
      high: '191.5800000000000000',
      low: '183.3000000000000000',
      close: '188.9500000000000000',
      volume: '269941.5343600000000000',
      quoteVolume: 50699742.773780935,
      btcVolume: 5698.653252114131,
      usdVolume: 50699742.773780935,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '188.9600000000000000',
      high: '190.1900000000000000',
      low: '184.0600000000000000',
      close: '184.9800000000000000',
      volume: '254362.4843600000000000',
      quoteVolume: 47520753.42440565,
      btcVolume: 5398.5502773478665,
      usdVolume: 47520753.42440565,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '184.9800000000000000',
      high: '187.6500000000000000',
      low: '182.4100000000000000',
      close: '187.0900000000000000',
      volume: '256637.8790500000000000',
      quoteVolume: 47608963.337230064,
      btcVolume: 5449.8930699344155,
      usdVolume: 47608963.337230064,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '187.0900000000000000',
      high: '189.6600000000000000',
      low: '185.3000000000000000',
      close: '188.1100000000000000',
      volume: '197273.8400100000000000',
      quoteVolume: 36936429.935943484,
      btcVolume: 4212.216828428857,
      usdVolume: 36936429.935943484,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '188.0700000000000000',
      high: '188.7200000000000000',
      low: '183.3400000000000000',
      close: '184.9300000000000000',
      volume: '245478.1899700000000000',
      quoteVolume: 45518688.567261755,
      btcVolume: 5250.084494910773,
      usdVolume: 45518688.567261755,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '184.9200000000000000',
      high: '186.7000000000000000',
      low: '177.6700000000000000',
      close: '180.0000000000000000',
      volume: '404828.6103000000000000',
      quoteVolume: 73739540.13606241,
      btcVolume: 8600.798508976508,
      usdVolume: 73739540.13606241,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '179.9900000000000000',
      high: '183.4600000000000000',
      low: '179.3000000000000000',
      close: '182.3700000000000000',
      volume: '172801.5257600000000000',
      quoteVolume: 31378510.015389085,
      btcVolume: 3700.092412038125,
      usdVolume: 31378510.015389085,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '182.3700000000000000',
      high: '186.0900000000000000',
      low: '180.0600000000000000',
      close: '183.8200000000000000',
      volume: '198323.1213100000000000',
      quoteVolume: 36407476.06173793,
      btcVolume: 4273.182664311965,
      usdVolume: 36407476.06173793,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '183.8200000000000000',
      high: '184.0600000000000000',
      low: '175.0100000000000000',
      close: '178.2000000000000000',
      volume: '292916.4108000000000000',
      quoteVolume: 52873504.41217531,
      btcVolume: 6334.069797912126,
      usdVolume: 52873504.41217531,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '178.2000000000000000',
      high: '178.5200000000000000',
      low: '172.6500000000000000',
      close: '175.9400000000000000',
      volume: '275886.6411000000000000',
      quoteVolume: 48414106.956933685,
      btcVolume: 5968.5359977672715,
      usdVolume: 48414106.956933685,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '175.9300000000000000',
      high: '177.4100000000000000',
      low: '173.5000000000000000',
      close: '174.7200000000000000',
      volume: '216315.9330900000000000',
      quoteVolume: 38001672.19309863,
      btcVolume: 4686.270009046089,
      usdVolume: 38001672.19309863,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '174.7500000000000000',
      high: '175.8500000000000000',
      low: '157.2600000000000000',
      close: '161.0100000000000000',
      volume: '470224.9285800000000000',
      quoteVolume: 78033991.15986177,
      btcVolume: 10011.92260591679,
      usdVolume: 78033991.15986177,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '161.0200000000000000',
      high: '162.7900000000000000',
      low: '138.0000000000000000',
      close: '149.5600000000000000',
      volume: '975937.0667600000000000',
      quoteVolume: 147306766.45684162,
      btcVolume: 20315.079048812546,
      usdVolume: 147306766.45684162,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '149.5500000000000000',
      high: '154.3300000000000000',
      low: '146.1100000000000000',
      close: '151.8600000000000000',
      volume: '369720.2906000000000000',
      quoteVolume: 55595034.725483,
      btcVolume: 7691.525331812389,
      usdVolume: 55595034.725483,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '151.8400000000000000',
      high: '152.8600000000000000',
      low: '138.6200000000000000',
      close: '139.9900000000000000',
      volume: '352318.5460300000000000',
      quoteVolume: 51503621.078441106,
      btcVolume: 7270.627960676268,
      usdVolume: 51503621.078441106,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '139.9600000000000000',
      high: '151.5000000000000000',
      low: '131.4500000000000000',
      close: '145.6900000000000000',
      volume: '749381.0582700000000000',
      quoteVolume: 106143909.57189968,
      btcVolume: 15339.430749701922,
      usdVolume: 106143909.57189968,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '145.8100000000000000',
      high: '149.9700000000000000',
      low: '143.3700000000000000',
      close: '147.4700000000000000',
      volume: '354023.0429800000000000',
      quoteVolume: 51873405.03929025,
      btcVolume: 7258.985728654345,
      usdVolume: 51873405.03929025,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '147.4700000000000000',
      high: '155.5400000000000000',
      low: '140.8400000000000000',
      close: '152.6200000000000000',
      volume: '563679.1596700000000000',
      quoteVolume: 83338641.04602854,
      btcVolume: 11568.893523095534,
      usdVolume: 83338641.04602854,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '152.6100000000000000',
      high: '154.6300000000000000',
      low: '149.0900000000000000',
      close: '150.7200000000000000',
      volume: '317714.1197600000000000',
      quoteVolume: 48324153.77624194,
      btcVolume: 6441.974596553162,
      usdVolume: 48324153.77624194,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '150.7200000000000000',
      high: '157.6000000000000000',
      low: '150.2300000000000000',
      close: '154.5000000000000000',
      volume: '328513.9167000000000000',
      quoteVolume: 50618447.52032263,
      btcVolume: 6641.291265743082,
      usdVolume: 50618447.52032263,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '154.5500000000000000',
      high: '155.2500000000000000',
      low: '149.7000000000000000',
      close: '151.4000000000000000',
      volume: '226862.4671000000000000',
      quoteVolume: 34617789.5827378,
      btcVolume: 4534.817039770797,
      usdVolume: 34617789.5827378,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '151.4300000000000000',
      high: '152.4900000000000000',
      low: '145.7900000000000000',
      close: '150.7300000000000000',
      volume: '344185.5408400000000000',
      quoteVolume: 51149912.23894076,
      btcVolume: 6959.7035482148,
      usdVolume: 51149912.23894076,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '150.7200000000000000',
      high: '151.4200000000000000',
      low: '146.7100000000000000',
      close: '148.6500000000000000',
      volume: '233774.3063000000000000',
      quoteVolume: 34759015.80900913,
      btcVolume: 4765.617361975778,
      usdVolume: 34759015.80900913,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '148.6600000000000000',
      high: '149.9300000000000000',
      low: '145.7700000000000000',
      close: '147.1700000000000000',
      volume: '196329.2250300000000000',
      quoteVolume: 29061161.101775736,
      btcVolume: 3976.947714942431,
      usdVolume: 29061161.101775736,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '147.1900000000000000',
      high: '151.0000000000000000',
      low: '143.1500000000000000',
      close: '145.3800000000000000',
      volume: '418523.8529900000000000',
      quoteVolume: 61465918.00020749,
      btcVolume: 8421.53839803797,
      usdVolume: 61465918.00020749,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '145.4500000000000000',
      high: '149.0200000000000000',
      low: '143.6400000000000000',
      close: '148.1000000000000000',
      volume: '298773.7016400000000000',
      quoteVolume: 43733249.10134732,
      btcVolume: 5975.972289154188,
      usdVolume: 43733249.10134732,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '148.1100000000000000',
      high: '149.7700000000000000',
      low: '145.7400000000000000',
      close: '148.4500000000000000',
      volume: '219804.5782100000000000',
      quoteVolume: 32420380.085808042,
      btcVolume: 4379.421398292797,
      usdVolume: 32420380.085808042,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '148.4600000000000000',
      high: '149.4900000000000000',
      low: '146.8500000000000000',
      close: '147.1400000000000000',
      volume: '140471.6858800000000000',
      quoteVolume: 20799551.345695384,
      btcVolume: 2765.513206109344,
      usdVolume: 20799551.345695384,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '147.1600000000000000',
      high: '151.6200000000000000',
      low: '146.1100000000000000',
      close: '150.4300000000000000',
      volume: '205292.3260400000000000',
      quoteVolume: 30604893.80851998,
      btcVolume: 4083.993478963329,
      usdVolume: 30604893.80851998,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '150.4300000000000000',
      high: '151.1800000000000000',
      low: '146.5600000000000000',
      close: '147.4100000000000000',
      volume: '241824.4918400000000000',
      quoteVolume: 36054914.410974964,
      btcVolume: 4830.038571314736,
      usdVolume: 36054914.410974964,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '147.3900000000000000',
      high: '148.5700000000000000',
      low: '143.8100000000000000',
      close: '145.5600000000000000',
      volume: '202345.9339500000000000',
      quoteVolume: 29619185.42152988,
      btcVolume: 4056.038127495942,
      usdVolume: 29619185.42152988,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '145.5300000000000000',
      high: '146.3400000000000000',
      low: '142.1200000000000000',
      close: '143.3900000000000000',
      volume: '157843.1048400000000000',
      quoteVolume: 22772651.168129046,
      btcVolume: 3157.805594325894,
      usdVolume: 22772651.168129046,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '143.4100000000000000',
      high: '145.8500000000000000',
      low: '139.2400000000000000',
      close: '144.8700000000000000',
      volume: '261614.3454600000000000',
      quoteVolume: 37425141.739041,
      btcVolume: 5217.446754533626,
      usdVolume: 37425141.739041,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '144.8700000000000000',
      high: '146.0000000000000000',
      low: '142.8000000000000000',
      close: '144.8000000000000000',
      volume: '160597.0306700000000000',
      quoteVolume: 23189253.309127137,
      btcVolume: 3205.056989013503,
      usdVolume: 23189253.309127137,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '144.8000000000000000',
      high: '145.0700000000000000',
      low: '141.1800000000000000',
      close: '141.7900000000000000',
      volume: '126232.5920100000000000',
      quoteVolume: 18027581.359546103,
      btcVolume: 2521.1961065175824,
      usdVolume: 18027581.359546103,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '141.7900000000000000',
      high: '144.1200000000000000',
      low: '139.9200000000000000',
      close: '142.4600000000000000',
      volume: '151189.6587700000000000',
      quoteVolume: 21495196.81888501,
      btcVolume: 3029.229605400445,
      usdVolume: 21495196.81888501,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '142.4600000000000000',
      high: '142.7200000000000000',
      low: '127.9300000000000000',
      close: '132.7300000000000000',
      volume: '450883.4902900000000000',
      quoteVolume: 60935923.41354128,
      btcVolume: 8739.56219158376,
      usdVolume: 60935923.41354128,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '132.7200000000000000',
      high: '132.9800000000000000',
      low: '119.1100000000000000',
      close: '121.8800000000000000',
      volume: '554842.5818300000000000',
      quoteVolume: 70638378.5959894,
      btcVolume: 10478.106986160494,
      usdVolume: 70638378.5959894,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '121.8800000000000000',
      high: '134.8700000000000000',
      low: '116.2600000000000000',
      close: '132.7800000000000000',
      volume: '884910.2066800000000000',
      quoteVolume: 110560097.03269246,
      btcVolume: 16307.41864801072,
      usdVolume: 110560097.03269246,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '132.8000000000000000',
      high: '134.0000000000000000',
      low: '125.6900000000000000',
      close: '128.1000000000000000',
      volume: '420674.8172000000000000',
      quoteVolume: 53766864.18518815,
      btcVolume: 7512.382660233176,
      usdVolume: 53766864.18518815,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '128.1000000000000000',
      high: '129.3900000000000000',
      low: '125.8400000000000000',
      close: '128.1900000000000000',
      volume: '213897.4673000000000000',
      quoteVolume: 27266605.48486431,
      btcVolume: 3812.8979207455554,
      usdVolume: 27266605.48486431,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '128.1900000000000000',
      high: '128.4000000000000000',
      low: '126.5000000000000000',
      close: '126.9900000000000000',
      volume: '135196.1164100000000000',
      quoteVolume: 17199278.79468431,
      btcVolume: 2407.517101337839,
      usdVolume: 17199278.79468431,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '127.0000000000000000',
      high: '133.0700000000000000',
      low: '126.8200000000000000',
      close: '132.0900000000000000',
      volume: '252349.1950800000000000',
      quoteVolume: 32837658.598232217,
      btcVolume: 4517.366310910823,
      usdVolume: 32837658.598232217,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '132.1200000000000000',
      high: '135.1000000000000000',
      low: '126.0000000000000000',
      close: '127.8000000000000000',
      volume: '421600.7565500000000000',
      quoteVolume: 55501305.92012195,
      btcVolume: 7393.0923029749465,
      usdVolume: 55501305.92012195,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '127.8000000000000000',
      high: '129.6900000000000000',
      low: '126.6100000000000000',
      close: '127.7100000000000000',
      volume: '200423.4802800000000000',
      quoteVolume: 25640395.796429265,
      btcVolume: 3511.8402799722517,
      usdVolume: 25640395.796429265,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '127.7000000000000000',
      high: '127.8400000000000000',
      low: '123.0700000000000000',
      close: '125.0900000000000000',
      volume: '224856.9553900000000000',
      quoteVolume: 28126021.072964624,
      btcVolume: 3897.9462094468076,
      usdVolume: 28126021.072964624,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '125.0900000000000000',
      high: '132.2600000000000000',
      low: '124.3200000000000000',
      close: '131.1800000000000000',
      volume: '271991.3960900000000000',
      quoteVolume: 34556187.22649918,
      btcVolume: 4757.447100368064,
      usdVolume: 34556187.22649918,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '125.5800000000000000',
      high: '127.1000000000000000',
      low: '121.9100000000000000',
      close: '126.2900000000000000',
      volume: '239584.0186300000000000',
      quoteVolume: 29967207.36584079,
      btcVolume: 4162.919074511555,
      usdVolume: 29967207.36584079,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '126.2800000000000000',
      high: '129.6800000000000000',
      low: '125.8400000000000000',
      close: '128.1100000000000000',
      volume: '196893.5227700000000000',
      quoteVolume: 25177121.147064537,
      btcVolume: 3441.3052732034603,
      usdVolume: 25177121.147064537,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '128.1100000000000000',
      high: '138.0700000000000000',
      low: '127.5200000000000000',
      close: '134.3600000000000000',
      volume: '316304.0786500000000000',
      quoteVolume: 41887251.75431606,
      btcVolume: 5666.2955116504645,
      usdVolume: 41887251.75431606,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '134.3600000000000000',
      high: '136.2400000000000000',
      low: '130.3000000000000000',
      close: '131.5900000000000000',
      volume: '320328.9778500000000000',
      quoteVolume: 42634148.31351888,
      btcVolume: 5822.900412418773,
      usdVolume: 42634148.31351888,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '131.6100000000000000',
      high: '133.6800000000000000',
      low: '128.1700000000000000',
      close: '129.1600000000000000',
      volume: '264902.2166100000000000',
      quoteVolume: 34674502.08294672,
      btcVolume: 4798.822256382279,
      usdVolume: 34674502.08294672,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '129.1600000000000000',
      high: '133.0500000000000000',
      low: '128.6800000000000000',
      close: '130.7700000000000000',
      volume: '144766.0171600000000000',
      quoteVolume: 18951727.853203688,
      btcVolume: 2626.058687621114,
      usdVolume: 18951727.853203688,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '130.7200000000000000',
      high: '130.7800000000000000',
      low: '126.3800000000000000',
      close: '127.1900000000000000',
      volume: '206486.6936400000000000',
      quoteVolume: 26562586.64793831,
      btcVolume: 3748.1292562505037,
      usdVolume: 26562586.64793831,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '127.1900000000000000',
      high: '135.1400000000000000',
      low: '125.8800000000000000',
      close: '134.3500000000000000',
      volume: '412941.3612600000000000',
      quoteVolume: 54124562.00994656,
      btcVolume: 7477.56431390865,
      usdVolume: 54124562.00994656,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '134.3700000000000000',
      high: '135.8500000000000000',
      low: '132.5000000000000000',
      close: '134.2000000000000000',
      volume: '184276.1710200000000000',
      quoteVolume: 24641135.568952836,
      btcVolume: 3361.8386052854335,
      usdVolume: 24641135.568952836,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '134.2000000000000000',
      high: '138.1900000000000000',
      low: '134.1900000000000000',
      close: '135.3700000000000000',
      volume: '254120.4534300000000000',
      quoteVolume: 34593687.60327518,
      btcVolume: 4653.251278572446,
      usdVolume: 34593687.60327518,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '135.3700000000000000',
      high: '144.4100000000000000',
      low: '134.8600000000000000',
      close: '144.1500000000000000',
      volume: '408020.2737500000000000',
      quoteVolume: 57400544.487255365,
      btcVolume: 7600.492701267486,
      usdVolume: 57400544.487255365,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '144.1400000000000000',
      high: '145.3100000000000000',
      low: '138.7600000000000000',
      close: '142.8000000000000000',
      volume: '447762.1728100000000000',
      quoteVolume: 63861666.4047595,
      btcVolume: 8049.865532289283,
      usdVolume: 63861666.4047595,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '142.8000000000000000',
      high: '147.7700000000000000',
      low: '137.0300000000000000',
      close: '140.7200000000000000',
      volume: '561419.2059200000000000',
      quoteVolume: 79913004.38781014,
      btcVolume: 9694.962625535327,
      usdVolume: 79913004.38781014,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '140.7600000000000000',
      high: '141.5000000000000000',
      low: '135.3000000000000000',
      close: '137.7400000000000000',
      volume: '363987.6637700000000000',
      quoteVolume: 50319731.13866686,
      btcVolume: 6374.095494904485,
      usdVolume: 50319731.13866686,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '137.7300000000000000',
      high: '145.1700000000000000',
      low: '135.3200000000000000',
      close: '144.8400000000000000',
      volume: '409400.9174100000000000',
      quoteVolume: 57377160.97009285,
      btcVolume: 7232.247618277078,
      usdVolume: 57377160.97009285,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '144.8300000000000000',
      high: '148.0500000000000000',
      low: '142.0900000000000000',
      close: '142.3800000000000000',
      volume: '368350.5793900000000000',
      quoteVolume: 53149360.56174742,
      btcVolume: 6540.115802381793,
      usdVolume: 53149360.56174742,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '142.4000000000000000',
      high: '146.6000000000000000',
      low: '141.7600000000000000',
      close: '146.5400000000000000',
      volume: '229541.8613700000000000',
      quoteVolume: 33112850.822234996,
      btcVolume: 4081.84868926777,
      usdVolume: 33112850.822234996,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '146.5600000000000000',
      high: '147.0000000000000000',
      low: '142.2700000000000000',
      close: '143.5800000000000000',
      volume: '207971.5884600000000000',
      quoteVolume: 29884467.67111847,
      btcVolume: 3683.879733305947,
      usdVolume: 29884467.67111847,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '143.5800000000000000',
      high: '171.7000000000000000',
      low: '143.5100000000000000',
      close: '165.6400000000000000',
      volume: '1108476.3118600000000000',
      quoteVolume: 173492006.24820682,
      btcVolume: 20115.096219225106,
      usdVolume: 173492006.24820682,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '165.6000000000000000',
      high: '171.9800000000000000',
      low: '159.2000000000000000',
      close: '166.4000000000000000',
      volume: '721560.7995600000000000',
      quoteVolume: 119219193.04167666,
      btcVolume: 13597.93692389315,
      usdVolume: 119219193.04167666,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '166.4000000000000000',
      high: '167.4000000000000000',
      low: '157.8000000000000000',
      close: '164.2100000000000000',
      volume: '456170.8671900000000000',
      quoteVolume: 74038650.82913166,
      btcVolume: 8518.447924957838,
      usdVolume: 74038650.82913166,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '164.2400000000000000',
      high: '174.8100000000000000',
      low: '162.1400000000000000',
      close: '169.8500000000000000',
      volume: '767180.6785300000000000',
      quoteVolume: 129909265.25615731,
      btcVolume: 14632.362690520036,
      usdVolume: 129909265.25615731,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '169.9200000000000000',
      high: '179.5000000000000000',
      low: '164.9200000000000000',
      close: '174.1400000000000000',
      volume: '688748.6367600000000000',
      quoteVolume: 119382479.69063364,
      btcVolume: 13404.09413255703,
      usdVolume: 119382479.69063364,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '174.1000000000000000',
      high: '178.0500000000000000',
      low: '161.6600000000000000',
      close: '166.7900000000000000',
      volume: '624670.6336700000000000',
      quoteVolume: 105689492.0033365,
      btcVolume: 12002.351244357744,
      usdVolume: 105689492.0033365,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '166.7900000000000000',
      high: '169.3300000000000000',
      low: '161.2400000000000000',
      close: '166.8700000000000000',
      volume: '358092.8841000000000000',
      quoteVolume: 59292534.71746608,
      btcVolume: 6852.199304969695,
      usdVolume: 59292534.71746608,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '166.8600000000000000',
      high: '170.3200000000000000',
      low: '164.8000000000000000',
      close: '169.4900000000000000',
      volume: '308007.6353000000000000',
      quoteVolume: 51615232.56698999,
      btcVolume: 5961.4179039502005,
      usdVolume: 51615232.56698999,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '169.4800000000000000',
      high: '171.4700000000000000',
      low: '166.0300000000000000',
      close: '168.0700000000000000',
      volume: '272240.9028600000000000',
      quoteVolume: 45903134.16291278,
      btcVolume: 5277.281438195617,
      usdVolume: 45903134.16291278,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '168.0700000000000000',
      high: '168.2000000000000000',
      low: '159.2100000000000000',
      close: '162.8100000000000000',
      volume: '373414.3498500000000000',
      quoteVolume: 60971659.77458279,
      btcVolume: 7198.282444848286,
      usdVolume: 60971659.77458279,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '162.8500000000000000',
      high: '164.4500000000000000',
      low: '155.5500000000000000',
      close: '162.5400000000000000',
      volume: '430013.1990200000000000',
      quoteVolume: 68980724.06049524,
      btcVolume: 8218.743302588302,
      usdVolume: 68980724.06049524,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '162.5100000000000000',
      high: '162.7900000000000000',
      low: '157.6100000000000000',
      close: '160.3500000000000000',
      volume: '219921.6519700000000000',
      quoteVolume: 35196608.30633701,
      btcVolume: 4216.84914612778,
      usdVolume: 35196608.30633701,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '160.3600000000000000',
      high: '168.0800000000000000',
      low: '159.4100000000000000',
      close: '167.8600000000000000',
      volume: '251582.5575800000000000',
      quoteVolume: 41239031.33790531,
      btcVolume: 4864.643562453211,
      usdVolume: 41239031.33790531,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '167.9100000000000000',
      high: '172.5600000000000000',
      low: '165.2200000000000000',
      close: '170.0800000000000000',
      volume: '365894.8191700000000000',
      quoteVolume: 61846624.61453907,
      btcVolume: 7067.9493571795865,
      usdVolume: 61846624.61453907,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '170.0400000000000000',
      high: '176.2000000000000000',
      low: '170.0300000000000000',
      close: '175.6400000000000000',
      volume: '473433.8960900000000000',
      quoteVolume: 81649497.49294704,
      btcVolume: 9000.09542777262,
      usdVolume: 81649497.49294704,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '175.5800000000000000',
      high: '178.4500000000000000',
      low: '173.3300000000000000',
      close: '173.7200000000000000',
      volume: '317366.2058000000000000',
      quoteVolume: 55985259.09291722,
      btcVolume: 5993.288480446213,
      usdVolume: 55985259.09291722,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '173.6800000000000000',
      high: '187.0000000000000000',
      low: '170.9300000000000000',
      close: '184.6900000000000000',
      volume: '477721.6609000000000000',
      quoteVolume: 85164074.08231418,
      btcVolume: 9048.137874415446,
      usdVolume: 85164074.08231418,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '184.7100000000000000',
      high: '185.8200000000000000',
      low: '175.2200000000000000',
      close: '179.9900000000000000',
      volume: '385596.5336500000000000',
      quoteVolume: 69633052.28263958,
      btcVolume: 7449.645096830591,
      usdVolume: 69633052.28263958,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '179.9400000000000000',
      high: '184.2800000000000000',
      low: '179.2300000000000000',
      close: '183.6000000000000000',
      volume: '259370.1290200000000000',
      quoteVolume: 47263178.06678158,
      btcVolume: 5035.1164574204295,
      usdVolume: 47263178.06678158,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '183.6300000000000000',
      high: '193.4300000000000000',
      low: '179.1000000000000000',
      close: '188.4400000000000000',
      volume: '552621.1361900000000000',
      quoteVolume: 104105613.55878504,
      btcVolume: 11088.57195048023,
      usdVolume: 104105613.55878504,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '188.4800000000000000',
      high: '195.1900000000000000',
      low: '186.6200000000000000',
      close: '189.6900000000000000',
      volume: '417175.9578100000000000',
      quoteVolume: 79336312.5246926,
      btcVolume: 8471.442558236018,
      usdVolume: 79336312.5246926,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '189.7400000000000000',
      high: '191.6000000000000000',
      low: '184.6900000000000000',
      close: '188.9100000000000000',
      volume: '366267.8454800000000000',
      quoteVolume: 68697714.9065747,
      btcVolume: 7449.885768860561,
      usdVolume: 68697714.9065747,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '188.9100000000000000',
      high: '207.6100000000000000',
      low: '188.1900000000000000',
      close: '203.7800000000000000',
      volume: '550942.1141700000000000',
      quoteVolume: 108659358.53591804,
      btcVolume: 11498.225576271325,
      usdVolume: 108659358.53591804,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '203.7800000000000000',
      high: '216.3300000000000000',
      low: '201.0200000000000000',
      close: '213.1900000000000000',
      volume: '602040.0810800000000000',
      quoteVolume: 126577112.92357521,
      btcVolume: 13029.657093734239,
      usdVolume: 126577112.92357521,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '213.1600000000000000',
      high: '225.0000000000000000',
      low: '213.1400000000000000',
      close: '223.3300000000000000',
      volume: '628858.0077800000000000',
      quoteVolume: 138414055.77483606,
      btcVolume: 14123.858774287371,
      usdVolume: 138414055.77483606,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '223.3600000000000000',
      high: '227.7500000000000000',
      low: '213.2200000000000000',
      close: '223.0500000000000000',
      volume: '548551.8746500000000000',
      quoteVolume: 122069507.65510991,
      btcVolume: 12459.442096719877,
      usdVolume: 122069507.65510991,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '223.0100000000000000',
      high: '230.6500000000000000',
      low: '222.8600000000000000',
      close: '228.4900000000000000',
      volume: '350221.2019800000000000',
      quoteVolume: 79648275.7579095,
      btcVolume: 7906.719601131233,
      usdVolume: 79648275.7579095,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '228.5300000000000000',
      high: '229.4000000000000000',
      low: '216.3700000000000000',
      close: '222.8900000000000000',
      volume: '510415.4994900000000000',
      quoteVolume: 113385288.95029798,
      btcVolume: 11438.000262952832,
      usdVolume: 113385288.95029798,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '222.8900000000000000',
      high: '239.1500000000000000',
      low: '218.1700000000000000',
      close: '236.6900000000000000',
      volume: '595576.9027600000000000',
      quoteVolume: 135863928.62430608,
      btcVolume: 13545.116057370578,
      usdVolume: 135863928.62430608,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '236.6900000000000000',
      high: '275.3400000000000000',
      low: '236.6900000000000000',
      close: '265.7400000000000000',
      volume: '1038073.7478200000000000',
      quoteVolume: 266734296.55029422,
      btcVolume: 25827.16221092207,
      usdVolume: 266734296.55029422,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '265.7400000000000000',
      high: '277.6900000000000000',
      low: '256.0800000000000000',
      close: '268.3200000000000000',
      volume: '1083763.8066500000000000',
      quoteVolume: 289993412.75714827,
      btcVolume: 28204.52124006715,
      usdVolume: 289993412.75714827,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '268.3400000000000000',
      high: '287.1500000000000000',
      low: '260.2800000000000000',
      close: '285.1500000000000000',
      volume: '734944.3226600000000000',
      quoteVolume: 201120424.58321652,
      btcVolume: 19585.77364231225,
      usdVolume: 201120424.58321652,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '285.1100000000000000',
      high: '288.4100000000000000',
      low: '261.8600000000000000',
      close: '264.8800000000000000',
      volume: '860767.3711600000000000',
      quoteVolume: 236274810.63895243,
      btcVolume: 23452.950884857593,
      usdVolume: 236274810.63895243,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '264.9100000000000000',
      high: '274.0000000000000000',
      low: '237.4100000000000000',
      close: '258.8500000000000000',
      volume: '1110118.4639500000000000',
      quoteVolume: 286269166.28143066,
      btcVolume: 29048.30557248655,
      usdVolume: 286269166.28143066,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '258.8900000000000000',
      high: '268.7700000000000000',
      low: '242.0000000000000000',
      close: '267.8500000000000000',
      volume: '1110371.3909400000000000',
      quoteVolume: 280371301.7549912,
      btcVolume: 28929.875955319196,
      usdVolume: 280371301.7549912,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '267.9000000000000000',
      high: '285.8800000000000000',
      low: '258.0000000000000000',
      close: '282.6100000000000000',
      volume: '1115523.4399200000000000',
      quoteVolume: 304162263.3061434,
      btcVolume: 30935.19354943302,
      usdVolume: 304162263.3061434,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '282.6400000000000000',
      high: '285.0000000000000000',
      low: '251.5600000000000000',
      close: '258.4500000000000000',
      volume: '702700.0948200000000000',
      quoteVolume: 190968563.2271233,
      btcVolume: 19191.632547085086,
      usdVolume: 190968563.2271233,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '258.4400000000000000',
      high: '264.3300000000000000',
      low: '245.3400000000000000',
      close: '256.9600000000000000',
      volume: '972629.9034800000000000',
      quoteVolume: 249295948.92492777,
      btcVolume: 26026.85890519547,
      usdVolume: 249295948.92492777,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '256.9700000000000000',
      high: '268.2400000000000000',
      low: '253.6100000000000000',
      close: '265.2700000000000000',
      volume: '524737.1325800000000000',
      quoteVolume: 137561519.8080567,
      btcVolume: 14215.99915940384,
      usdVolume: 137561519.8080567,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '265.3200000000000000',
      high: '266.8100000000000000',
      low: '256.0000000000000000',
      close: '261.5700000000000000',
      volume: '313062.5213300000000000',
      quoteVolume: 81781388.31935476,
      btcVolume: 8483.868403005552,
      usdVolume: 81781388.31935476,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '261.5500000000000000',
      high: '275.6800000000000000',
      low: '261.0200000000000000',
      close: '274.4800000000000000',
      volume: '444740.8288300000000000',
      quoteVolume: 120024058.97196795,
      btcVolume: 12171.335680224975,
      usdVolume: 120024058.97196795,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '274.5000000000000000',
      high: '277.2000000000000000',
      low: '257.0900000000000000',
      close: '265.5200000000000000',
      volume: '696591.7298300000000000',
      quoteVolume: 185949182.70858055,
      btcVolume: 19137.450555293988,
      usdVolume: 185949182.70858055,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '265.4700000000000000',
      high: '266.2200000000000000',
      low: '244.4400000000000000',
      close: '246.6700000000000000',
      volume: '774791.0102700000000000',
      quoteVolume: 197442911.5447298,
      btcVolume: 20862.28626803828,
      usdVolume: 197442911.5447298,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '246.6700000000000000',
      high: '250.3200000000000000',
      low: '215.6600000000000000',
      close: '223.9300000000000000',
      volume: '1390938.8858200000000000',
      quoteVolume: 323053459.4097742,
      btcVolume: 35807.633154278985,
      usdVolume: 323053459.4097742,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '223.9800000000000000',
      high: '238.3000000000000000',
      low: '210.0000000000000000',
      close: '227.7900000000000000',
      volume: '1273621.5787700000000000',
      quoteVolume: 287283849.70437545,
      btcVolume: 32695.90481354741,
      usdVolume: 287283849.70437545,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '227.7300000000000000',
      high: '234.6700000000000000',
      low: '214.0100000000000000',
      close: '226.7600000000000000',
      volume: '1054994.9239700000000000',
      quoteVolume: 236064804.33459535,
      btcVolume: 27236.336241981913,
      usdVolume: 236064804.33459535,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '226.7600000000000000',
      high: '233.0000000000000000',
      low: '217.0000000000000000',
      close: '217.2100000000000000',
      volume: '546866.6851000000000000',
      quoteVolume: 123635801.01782297,
      btcVolume: 14249.24078788142,
      usdVolume: 123635801.01782297,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '217.2900000000000000',
      high: '227.8900000000000000',
      low: '212.3600000000000000',
      close: '217.8100000000000000',
      volume: '715016.0194100000000000',
      quoteVolume: 157064953.84227648,
      btcVolume: 18346.386497742988,
      usdVolume: 157064953.84227648,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '217.8100000000000000',
      high: '234.4000000000000000',
      low: '216.0700000000000000',
      close: '231.9700000000000000',
      volume: '810051.4833000000000000',
      quoteVolume: 182948651.67645854,
      btcVolume: 20891.507673847358,
      usdVolume: 182948651.67645854,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '232.1000000000000000',
      high: '232.4600000000000000',
      low: '219.5700000000000000',
      close: '223.9100000000000000',
      volume: '741498.5482500000000000',
      quoteVolume: 168090325.5387952,
      btcVolume: 19140.228688685915,
      usdVolume: 168090325.5387952,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '223.8400000000000000',
      high: '228.8500000000000000',
      low: '220.2300000000000000',
      close: '224.2600000000000000',
      volume: '443780.3377200000000000',
      quoteVolume: 99650220.54162118,
      btcVolume: 11371.931756177451,
      usdVolume: 99650220.54162118,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '224.2600000000000000',
      high: '234.0900000000000000',
      low: '224.2300000000000000',
      close: '228.3800000000000000',
      volume: '601434.2612800000000000',
      quoteVolume: 138071496.98683217,
      btcVolume: 15332.125743401275,
      usdVolume: 138071496.98683217,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '228.3800000000000000',
      high: '245.1600000000000000',
      low: '227.3300000000000000',
      close: '244.8800000000000000',
      volume: '628147.3257000000000000',
      quoteVolume: 148563741.83299482,
      btcVolume: 16336.054719609947,
      usdVolume: 148563741.83299482,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '244.9300000000000000',
      high: '251.9300000000000000',
      low: '236.0000000000000000',
      close: '237.2300000000000000',
      volume: '633748.8966200000000000',
      quoteVolume: 154037325.61130953,
      btcVolume: 17062.989655657668,
      usdVolume: 154037325.61130953,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '237.2300000000000000',
      high: '237.2300000000000000',
      low: '195.5000000000000000',
      close: '199.4300000000000000',
      volume: '1278973.5374100000000000',
      quoteVolume: 277970187.86825454,
      btcVolume: 32927.24759153153,
      usdVolume: 277970187.86825454,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '199.4300000000000000',
      high: '208.6200000000000000',
      low: '190.0000000000000000',
      close: '202.8100000000000000',
      volume: '1661331.8355300000000000',
      quoteVolume: 332570408.53516084,
      btcVolume: 42278.225193090606,
      usdVolume: 332570408.53516084,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '202.7900000000000000',
      high: '206.2000000000000000',
      low: '195.5400000000000000',
      close: '200.7000000000000000',
      volume: '1020260.1070000000000000',
      quoteVolume: 205536190.19393793,
      btcVolume: 25870.47287880342,
      usdVolume: 205536190.19393793,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '200.7400000000000000',
      high: '203.1800000000000000',
      low: '181.7300000000000000',
      close: '194.6100000000000000',
      volume: '1079824.9016700000000000',
      quoteVolume: 208232375.1861964,
      btcVolume: 26676.44911775151,
      usdVolume: 208232375.1861964,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '194.6100000000000000',
      high: '195.5500000000000000',
      low: '101.2000000000000000',
      close: '107.8200000000000000',
      volume: '3487974.4724600000000000',
      quoteVolume: 502735483.19587886,
      btcVolume: 79582.69602720514,
      usdVolume: 502735483.19587886,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '107.6700000000000000',
      high: '139.6800000000000000',
      low: '86.0000000000000000',
      close: '134.0600000000000000',
      volume: '4352096.1180700000000000',
      quoteVolume: 524165020.32676804,
      btcVolume: 102228.2183345659,
      usdVolume: 524165020.32676804,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '134.0600000000000000',
      high: '134.6800000000000000',
      low: '120.0000000000000000',
      close: '122.5400000000000000',
      volume: '1237626.0682200000000000',
      quoteVolume: 159186518.38922155,
      btcVolume: 29588.5952380688,
      usdVolume: 159186518.38922155,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '122.5400000000000000',
      high: '133.5000000000000000',
      low: '120.1600000000000000',
      close: '123.7800000000000000',
      volume: '1312950.9013700000000000',
      quoteVolume: 163436235.26908636,
      btcVolume: 30443.399792973287,
      usdVolume: 163436235.26908636,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '123.8200000000000000',
      high: '124.3300000000000000',
      low: '101.1000000000000000',
      close: '111.0200000000000000',
      volume: '2442064.1444800000000000',
      quoteVolume: 270353891.80499524,
      btcVolume: 55152.042843550655,
      usdVolume: 270353891.80499524,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '111.0200000000000000',
      high: '120.8000000000000000',
      low: '109.3800000000000000',
      close: '115.6700000000000000',
      volume: '1295108.4848000000000000',
      quoteVolume: 150800664.07195225,
      btcVolume: 28587.190421365343,
      usdVolume: 150800664.07195225,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '115.6700000000000000',
      high: '118.5000000000000000',
      low: '109.8600000000000000',
      close: '118.1100000000000000',
      volume: '1201850.3127500000000000',
      quoteVolume: 137550912.08551753,
      btcVolume: 26224.964726877704,
      usdVolume: 137550912.08551753,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '118.1100000000000000',
      high: '143.1300000000000000',
      low: '115.4600000000000000',
      close: '136.3000000000000000',
      volume: '1801071.1880800000000000',
      quoteVolume: 231626928.1512321,
      btcVolume: 39606.47361637073,
      usdVolume: 231626928.1512321,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '136.3000000000000000',
      high: '152.5500000000000000',
      low: '116.7400000000000000',
      close: '133.4800000000000000',
      volume: '2504057.6379900000000000',
      quoteVolume: 345856006.74780256,
      btcVolume: 54225.66525238545,
      usdVolume: 345856006.74780256,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '133.4900000000000000',
      high: '137.4900000000000000',
      low: '125.6900000000000000',
      close: '132.6600000000000000',
      volume: '1231842.1112100000000000',
      quoteVolume: 162468451.77231285,
      btcVolume: 26347.20683720244,
      usdVolume: 162468451.77231285,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '132.6300000000000000',
      high: '137.1300000000000000',
      low: '121.1000000000000000',
      close: '122.3200000000000000',
      volume: '1055699.8653200000000000',
      quoteVolume: 135927941.07952216,
      btcVolume: 22332.10691388855,
      usdVolume: 135927941.07952216,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '122.3100000000000000',
      high: '137.5000000000000000',
      low: '119.5000000000000000',
      close: '135.9200000000000000',
      volume: '1436796.1244900000000000',
      quoteVolume: 184851609.12426114,
      btcVolume: 30054.31381726867,
      usdVolume: 184851609.12426114,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '135.8900000000000000',
      high: '143.3600000000000000',
      low: '132.2000000000000000',
      close: '138.4200000000000000',
      volume: '1244377.1633400000000000',
      quoteVolume: 171156363.69229996,
      btcVolume: 25793.645485705532,
      usdVolume: 171156363.69229996,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '138.4200000000000000',
      high: '142.3300000000000000',
      low: '132.5400000000000000',
      close: '136.1000000000000000',
      volume: '1034248.7436900000000000',
      quoteVolume: 141795323.0268692,
      btcVolume: 21290.647624377056,
      usdVolume: 141795323.0268692,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '136.1100000000000000',
      high: '139.7400000000000000',
      low: '133.2600000000000000',
      close: '138.4000000000000000',
      volume: '750975.5504600000000000',
      quoteVolume: 101907167.70116763,
      btcVolume: 15297.871335099853,
      usdVolume: 101907167.70116763,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '138.4000000000000000',
      high: '141.2700000000000000',
      low: '129.6800000000000000',
      close: '131.3300000000000000',
      volume: '706948.5455900000000000',
      quoteVolume: 96581886.87894869,
      btcVolume: 14496.8359010212,
      usdVolume: 96581886.87894869,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '131.3300000000000000',
      high: '132.9700000000000000',
      low: '124.8800000000000000',
      close: '130.9800000000000000',
      volume: '896032.1059400000000000',
      quoteVolume: 115166651.97007346,
      btcVolume: 18545.39550175307,
      usdVolume: 115166651.97007346,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '130.9400000000000000',
      high: '131.8400000000000000',
      low: '123.8100000000000000',
      close: '124.5000000000000000',
      volume: '543997.0000000000000000',
      quoteVolume: 69434202.20547193,
      btcVolume: 11449.693329595028,
      usdVolume: 69434202.20547193,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '124.4700000000000000',
      high: '135.4200000000000000',
      low: '124.0600000000000000',
      close: '132.0600000000000000',
      volume: '760403.4785400000000000',
      quoteVolume: 99677889.71604659,
      btcVolume: 15885.98378555245,
      usdVolume: 99677889.71604659,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '132.0900000000000000',
      high: '135.0000000000000000',
      low: '130.3800000000000000',
      close: '132.7200000000000000',
      volume: '547529.2537800000000000',
      quoteVolume: 72599600.32278414,
      btcVolume: 11270.645004277229,
      usdVolume: 72599600.32278414,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '132.7200000000000000',
      high: '136.9900000000000000',
      low: '128.6200000000000000',
      close: '135.6900000000000000',
      volume: '680643.4807700000000000',
      quoteVolume: 90027178.09661904,
      btcVolume: 14172.192723006221,
      usdVolume: 90027178.09661904,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '135.6500000000000000',
      high: '149.7600000000000000',
      low: '135.0100000000000000',
      close: '141.2800000000000000',
      volume: '1138977.6611400000000000',
      quoteVolume: 159989506.60494053,
      btcVolume: 23529.02931639409,
      usdVolume: 159989506.60494053,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '141.2700000000000000',
      high: '146.7200000000000000',
      low: '137.2600000000000000',
      close: '141.3100000000000000',
      volume: '797546.1144600000000000',
      quoteVolume: 113731706.71500106,
      btcVolume: 16666.063640760167,
      usdVolume: 113731706.71500106,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '141.3000000000000000',
      high: '146.2800000000000000',
      low: '138.8800000000000000',
      close: '144.1000000000000000',
      volume: '573508.1408600000000000',
      quoteVolume: 81640622.82843257,
      btcVolume: 12045.428630419647,
      usdVolume: 81640622.82843257,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '144.1000000000000000',
      high: '145.5800000000000000',
      low: '140.6800000000000000',
      close: '142.6800000000000000',
      volume: '414987.4524600000000000',
      quoteVolume: 59383946.127442546,
      btcVolume: 8751.783787499042,
      usdVolume: 59383946.127442546,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '142.6900000000000000',
      high: '172.0000000000000000',
      low: '142.6000000000000000',
      close: '171.3200000000000000',
      volume: '1636338.7311000000000000',
      quoteVolume: 257097597.7838751,
      btcVolume: 36146.37040615942,
      usdVolume: 257097597.7838751,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '171.3200000000000000',
      high: '176.2200000000000000',
      low: '162.2200000000000000',
      close: '164.6300000000000000',
      volume: '1717496.7435600000000000',
      quoteVolume: 292630399.0504515,
      btcVolume: 40022.062364601465,
      usdVolume: 292630399.0504515,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '164.6200000000000000',
      high: '174.4800000000000000',
      low: '163.5900000000000000',
      close: '173.1100000000000000',
      volume: '1023575.9207600000000000',
      quoteVolume: 173916484.78615347,
      btcVolume: 23819.17844363877,
      usdVolume: 173916484.78615347,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '173.1500000000000000',
      high: '173.3800000000000000',
      low: '165.1900000000000000',
      close: '169.5200000000000000',
      volume: '719837.2442300000000000',
      quoteVolume: 122531131.95595776,
      btcVolume: 16818.265725807498,
      usdVolume: 122531131.95595776,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '169.5600000000000000',
      high: '170.1000000000000000',
      low: '152.1100000000000000',
      close: '157.8100000000000000',
      volume: '1314841.3620000000000000',
      quoteVolume: 209535613.32368094,
      btcVolume: 30105.99545258993,
      usdVolume: 209535613.32368094,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '157.8100000000000000',
      high: '161.3700000000000000',
      low: '153.9100000000000000',
      close: '158.3300000000000000',
      volume: '621599.6128900000000000',
      quoteVolume: 98275533.06263469,
      btcVolume: 14322.52534224828,
      usdVolume: 98275533.06263469,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '158.3300000000000000',
      high: '164.7800000000000000',
      low: '155.0000000000000000',
      close: '158.5900000000000000',
      volume: '809097.1317400000000000',
      quoteVolume: 129919434.5212399,
      btcVolume: 18622.065967622548,
      usdVolume: 129919434.5212399,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '158.5600000000000000',
      high: '158.9700000000000000',
      low: '150.0000000000000000',
      close: '156.2900000000000000',
      volume: '1042091.0362200000000000',
      quoteVolume: 160423270.30371547,
      btcVolume: 23810.704478929125,
      usdVolume: 160423270.30371547,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '156.2800000000000000',
      high: '161.8500000000000000',
      low: '155.2300000000000000',
      close: '158.4500000000000000',
      volume: '698030.6508900000000000',
      quoteVolume: 110710086.10400799,
      btcVolume: 16092.175363956325,
      usdVolume: 110710086.10400799,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '158.4600000000000000',
      high: '161.2900000000000000',
      low: '152.0000000000000000',
      close: '152.7300000000000000',
      volume: '729119.6650500000000000',
      quoteVolume: 114640840.11551383,
      btcVolume: 16907.325021412682,
      usdVolume: 114640840.11551383,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '152.7400000000000000',
      high: '174.7900000000000000',
      low: '148.3300000000000000',
      close: '172.2900000000000000',
      volume: '1647207.5999100000000000',
      quoteVolume: 273639160.06938905,
      btcVolume: 39469.80302466689,
      usdVolume: 273639160.06938905,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '172.3100000000000000',
      high: '174.9600000000000000',
      low: '168.3100000000000000',
      close: '170.6900000000000000',
      volume: '723537.7559100000000000',
      quoteVolume: 123553456.31967713,
      btcVolume: 17477.09337208831,
      usdVolume: 123553456.31967713,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '170.6100000000000000',
      high: '189.5400000000000000',
      low: '170.4800000000000000',
      close: '187.4000000000000000',
      volume: '1127296.3697900000000000',
      quoteVolume: 203132211.95640972,
      btcVolume: 28322.990109666654,
      usdVolume: 203132211.95640972,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '187.4000000000000000',
      high: '188.3500000000000000',
      low: '175.7500000000000000',
      close: '180.0300000000000000',
      volume: '995759.5775000000000000',
      quoteVolume: 181415813.50269327,
      btcVolume: 25353.375195221084,
      usdVolume: 181415813.50269327,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '180.0200000000000000',
      high: '186.4600000000000000',
      low: '166.7000000000000000',
      close: '170.2000000000000000',
      volume: '1547821.5273700000000000',
      quoteVolume: 273854539.8037984,
      btcVolume: 39135.19214462897,
      usdVolume: 273854539.8037984,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '170.2100000000000000',
      high: '174.7000000000000000',
      low: '168.0000000000000000',
      close: '170.7400000000000000',
      volume: '906251.0337800000000000',
      quoteVolume: 155269608.04007408,
      btcVolume: 22667.419297755732,
      usdVolume: 155269608.04007408,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '170.7300000000000000',
      high: '184.0000000000000000',
      low: '169.7800000000000000',
      close: '182.7000000000000000',
      volume: '988227.0746000000000000',
      quoteVolume: 175998292.09734583,
      btcVolume: 25096.17505566981,
      usdVolume: 175998292.09734583,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '182.7000000000000000',
      high: '193.6300000000000000',
      low: '178.1600000000000000',
      close: '185.5100000000000000',
      volume: '1457546.9509400000000000',
      quoteVolume: 270803059.6644047,
      btcVolume: 36995.15878859557,
      usdVolume: 270803059.6644047,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '185.5700000000000000',
      high: '189.8000000000000000',
      low: '184.7200000000000000',
      close: '187.5900000000000000',
      volume: '780904.2125700000000000',
      quoteVolume: 146419748.08164915,
      btcVolume: 19487.960270951102,
      usdVolume: 146419748.08164915,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '187.6000000000000000',
      high: '198.3600000000000000',
      low: '185.7600000000000000',
      close: '194.2100000000000000',
      volume: '866478.0492800000000000',
      quoteVolume: 167703202.42505187,
      btcVolume: 22213.786528375527,
      usdVolume: 167703202.42505187,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '194.2100000000000000',
      high: '200.0000000000000000',
      low: '192.3000000000000000',
      close: '197.3800000000000000',
      volume: '758525.8153300000000000',
      quoteVolume: 148433709.777186,
      btcVolume: 19517.662353427906,
      usdVolume: 148433709.777186,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '197.3900000000000000',
      high: '199.0500000000000000',
      low: '189.2900000000000000',
      close: '196.3400000000000000',
      volume: '896627.3727100000000000',
      quoteVolume: 174775637.92727718,
      btcVolume: 22703.242326359323,
      usdVolume: 174775637.92727718,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '196.3300000000000000',
      high: '198.5000000000000000',
      low: '192.3600000000000000',
      close: '196.2400000000000000',
      volume: '636829.8939700000000000',
      quoteVolume: 124476041.88938437,
      btcVolume: 16122.852361506426,
      usdVolume: 124476041.88938437,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '196.2400000000000000',
      high: '218.7700000000000000',
      low: '196.2200000000000000',
      close: '215.3600000000000000',
      volume: '1620422.7243100000000000',
      quoteVolume: 338324593.63727975,
      btcVolume: 40923.435434255334,
      usdVolume: 338324593.63727975,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '215.4000000000000000',
      high: '227.3000000000000000',
      low: '202.0000000000000000',
      close: '206.0800000000000000',
      volume: '1749436.3115300000000000',
      quoteVolume: 374836060.0037704,
      btcVolume: 42031.33369272022,
      usdVolume: 374836060.0037704,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '206.0700000000000000',
      high: '217.1400000000000000',
      low: '205.9100000000000000',
      close: '212.0200000000000000',
      volume: '794913.9582800000000000',
      quoteVolume: 168339123.3579177,
      btcVolume: 19142.43801029207,
      usdVolume: 168339123.3579177,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '212.0100000000000000',
      high: '215.3700000000000000',
      low: '210.3600000000000000',
      close: '213.9000000000000000',
      volume: '443494.6724800000000000',
      quoteVolume: 94389501.51781647,
      btcVolume: 10636.541506688323,
      usdVolume: 94389501.51781647,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '213.8900000000000000',
      high: '219.3000000000000000',
      low: '203.9200000000000000',
      close: '209.8600000000000000',
      volume: '748383.9131100000000000',
      quoteVolume: 158874887.0783394,
      btcVolume: 17742.09175499337,
      usdVolume: 158874887.0783394,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '209.8600000000000000',
      high: '210.6500000000000000',
      low: '194.8800000000000000',
      close: '206.4800000000000000',
      volume: '1114249.3265800000000000',
      quoteVolume: 225451167.65536752,
      btcVolume: 25813.68579734503,
      usdVolume: 225451167.65536752,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '206.4700000000000000',
      high: '211.6800000000000000',
      low: '201.0000000000000000',
      close: '205.2900000000000000',
      volume: '640644.6626800000000000',
      quoteVolume: 131906204.90781972,
      btcVolume: 14781.073403266748,
      usdVolume: 131906204.90781972,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '205.3000000000000000',
      high: '210.7700000000000000',
      low: '197.7100000000000000',
      close: '198.9700000000000000',
      volume: '862700.6784900000000000',
      quoteVolume: 178157452.60084617,
      btcVolume: 19446.63985121468,
      usdVolume: 178157452.60084617,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '198.9200000000000000',
      high: '215.1000000000000000',
      low: '196.4300000000000000',
      close: '212.0000000000000000',
      volume: '1305778.7376400000000000',
      quoteVolume: 271197927.6650283,
      btcVolume: 28416.436328368578,
      usdVolume: 271197927.6650283,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '212.0100000000000000',
      high: '216.6100000000000000',
      low: '206.9000000000000000',
      close: '211.3100000000000000',
      volume: '917908.1944000000000000',
      quoteVolume: 194579529.42776698,
      btcVolume: 19629.460850922827,
      usdVolume: 194579529.42776698,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '211.3200000000000000',
      high: '214.6000000000000000',
      low: '208.4000000000000000',
      close: '210.0700000000000000',
      volume: '587871.9807300000000000',
      quoteVolume: 124626471.42703621,
      btcVolume: 12822.779287709614,
      usdVolume: 124626471.42703621,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '210.0900000000000000',
      high: '210.1800000000000000',
      low: '179.5900000000000000',
      close: '187.5400000000000000',
      volume: '1473226.0547500000000000',
      quoteVolume: 278394290.7597449,
      btcVolume: 32142.471378080594,
      usdVolume: 278394290.7597449,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '187.6200000000000000',
      high: '193.5000000000000000',
      low: '176.0000000000000000',
      close: '185.7300000000000000',
      volume: '1093299.8192700000000000',
      quoteVolume: 203391530.206183,
      btcVolume: 23422.170849671944,
      usdVolume: 203391530.206183,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '185.8000000000000000',
      high: '192.2400000000000000',
      low: '185.4800000000000000',
      close: '189.7600000000000000',
      volume: '669755.7956700000000000',
      quoteVolume: 126810812.16817188,
      btcVolume: 14463.158714611134,
      usdVolume: 126810812.16817188,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '189.7700000000000000',
      high: '201.0000000000000000',
      low: '188.3500000000000000',
      close: '199.5800000000000000',
      volume: '805809.8298700000000000',
      quoteVolume: 156573908.52186683,
      btcVolume: 17267.02579964432,
      usdVolume: 156573908.52186683,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '199.6100000000000000',
      high: '206.0200000000000000',
      low: '195.6700000000000000',
      close: '203.2900000000000000',
      volume: '1114700.2493700000000000',
      quoteVolume: 224233786.12671238,
      btcVolume: 23321.873078992805,
      usdVolume: 224233786.12671238,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '203.2900000000000000',
      high: '204.0800000000000000',
      low: '191.6600000000000000',
      close: '194.8600000000000000',
      volume: '904749.6357000000000000',
      quoteVolume: 179634085.6227652,
      btcVolume: 18891.585288550705,
      usdVolume: 179634085.6227652,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '194.8700000000000000',
      high: '203.5000000000000000',
      low: '193.2100000000000000',
      close: '200.4500000000000000',
      volume: '669900.0375400000000000',
      quoteVolume: 134060788.49418302,
      btcVolume: 14238.268494091166,
      usdVolume: 134060788.49418302,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '200.4400000000000000',
      high: '209.8500000000000000',
      low: '199.6800000000000000',
      close: '207.0000000000000000',
      volume: '708656.1209600000000000',
      quoteVolume: 145330053.95795897,
      btcVolume: 15038.595482450506,
      usdVolume: 145330053.95795897,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '207.0200000000000000',
      high: '217.0700000000000000',
      low: '207.0000000000000000',
      close: '214.9100000000000000',
      volume: '1064118.4969700000000000',
      quoteVolume: 226784577.1006466,
      btcVolume: 23254.32875270356,
      usdVolume: 226784577.1006466,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '214.9200000000000000',
      high: '215.9500000000000000',
      low: '209.4500000000000000',
      close: '214.5800000000000000',
      volume: '643702.9884900000000000',
      quoteVolume: 136846948.55680534,
      btcVolume: 14141.16094939455,
      usdVolume: 136846948.55680534,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '214.6000000000000000',
      high: '215.7500000000000000',
      low: '204.2700000000000000',
      close: '209.9800000000000000',
      volume: '679532.6103700000000000',
      quoteVolume: 143704806.17524174,
      btcVolume: 14897.556828334138,
      usdVolume: 143704806.17524174,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '209.9900000000000000',
      high: '211.6400000000000000',
      low: '191.7100000000000000',
      close: '198.7400000000000000',
      volume: '1003923.4342000000000000',
      quoteVolume: 203445710.96284306,
      btcVolume: 22082.32268408724,
      usdVolume: 203445710.96284306,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '198.7200000000000000',
      high: '209.1900000000000000',
      low: '196.2400000000000000',
      close: '207.3600000000000000',
      volume: '787672.3608100000000000',
      quoteVolume: 159972608.5341703,
      btcVolume: 17518.762923166818,
      usdVolume: 159972608.5341703,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '207.3600000000000000',
      high: '211.2000000000000000',
      low: '204.5500000000000000',
      close: '206.6000000000000000',
      volume: '478543.5112000000000000',
      quoteVolume: 99457681.15441823,
      btcVolume: 10806.153666981252,
      usdVolume: 99457681.15441823,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '206.6000000000000000',
      high: '210.5500000000000000',
      low: '199.5000000000000000',
      close: '199.9000000000000000',
      volume: '673364.5911000000000000',
      quoteVolume: 138882881.96251076,
      btcVolume: 15299.402726933584,
      usdVolume: 138882881.96251076,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '199.9000000000000000',
      high: '205.6700000000000000',
      low: '198.0100000000000000',
      close: '204.1500000000000000',
      volume: '469931.6249200000000000',
      quoteVolume: 95411610.79763587,
      btcVolume: 10833.988909859245,
      usdVolume: 95411610.79763587,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '204.2200000000000000',
      high: '205.0000000000000000',
      low: '196.9000000000000000',
      close: '200.8900000000000000',
      volume: '477176.7353000000000000',
      quoteVolume: 96116808.85809049,
      btcVolume: 10865.809286287838,
      usdVolume: 96116808.85809049,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '200.9200000000000000',
      high: '208.4400000000000000',
      low: '200.7600000000000000',
      close: '208.2900000000000000',
      volume: '494816.7272700000000000',
      quoteVolume: 101445351.02566375,
      btcVolume: 11223.077448084685,
      usdVolume: 101445351.02566375,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '208.2900000000000000',
      high: '220.5000000000000000',
      low: '204.6700000000000000',
      close: '220.2100000000000000',
      volume: '643945.9776900000000000',
      quoteVolume: 136361627.34641564,
      btcVolume: 14529.09885208273,
      usdVolume: 136361627.34641564,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '220.2400000000000000',
      high: '224.7900000000000000',
      low: '217.5700000000000000',
      close: '220.6700000000000000',
      volume: '602955.9267100000000000',
      quoteVolume: 133197594.260007,
      btcVolume: 14054.00894376058,
      usdVolume: 133197594.260007,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '220.6300000000000000',
      high: '247.0000000000000000',
      low: '218.8000000000000000',
      close: '243.6600000000000000',
      volume: '1019550.7514600000000000',
      quoteVolume: 238678839.809296,
      btcVolume: 25044.601085406517,
      usdVolume: 238678839.809296,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '243.7100000000000000',
      high: '245.3900000000000000',
      low: '229.5800000000000000',
      close: '231.5700000000000000',
      volume: '821608.6888400000000000',
      quoteVolume: 194867426.2941861,
      btcVolume: 20410.587835015256,
      usdVolume: 194867426.2941861,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '231.5600000000000000',
      high: '250.6800000000000000',
      low: '230.6600000000000000',
      close: '248.2300000000000000',
      volume: '823816.6993600000000000',
      quoteVolume: 198791911.84704918,
      btcVolume: 20496.02670912183,
      usdVolume: 198791911.84704918,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '248.2300000000000000',
      high: '253.8000000000000000',
      low: '225.0000000000000000',
      close: '237.7100000000000000',
      volume: '955520.9186300000000000',
      quoteVolume: 230680327.30929545,
      btcVolume: 23537.399570541293,
      usdVolume: 230680327.30929545,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '237.7100000000000000',
      high: '245.0000000000000000',
      low: '232.7100000000000000',
      close: '244.5200000000000000',
      volume: '502928.1337100000000000',
      quoteVolume: 120179301.17898554,
      btcVolume: 12578.74569388963,
      usdVolume: 120179301.17898554,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '244.5400000000000000',
      high: '246.6000000000000000',
      low: '236.0000000000000000',
      close: '243.2100000000000000',
      volume: '669369.3392000000000000',
      quoteVolume: 162460397.62767565,
      btcVolume: 16776.170878664434,
      usdVolume: 162460397.62767565,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '243.2200000000000000',
      high: '247.8700000000000000',
      low: '239.1400000000000000',
      close: '240.0300000000000000',
      volume: '502468.8571600000000000',
      quoteVolume: 122065135.39008076,
      btcVolume: 12523.278719135755,
      usdVolume: 122065135.39008076,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '240.0400000000000000',
      high: '244.7000000000000000',
      low: '238.1600000000000000',
      close: '241.9400000000000000',
      volume: '366776.8953700000000000',
      quoteVolume: 88523230.03653955,
      btcVolume: 9183.854988193434,
      usdVolume: 88523230.03653955,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '241.9400000000000000',
      high: '245.3700000000000000',
      low: '234.6000000000000000',
      close: '244.5600000000000000',
      volume: '571102.5551500000000000',
      quoteVolume: 137165732.64761737,
      btcVolume: 14264.71365491363,
      usdVolume: 137165732.64761737,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '244.5700000000000000',
      high: '247.7000000000000000',
      low: '240.5900000000000000',
      close: '246.4000000000000000',
      volume: '383116.5471900000000000',
      quoteVolume: 93355224.96830915,
      btcVolume: 9598.855468043803,
      usdVolume: 93355224.96830915,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '246.3700000000000000',
      high: '249.8200000000000000',
      low: '238.0000000000000000',
      close: '243.8000000000000000',
      volume: '419967.8054400000000000',
      quoteVolume: 102287184.99320976,
      btcVolume: 10528.431478674393,
      usdVolume: 102287184.99320976,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '243.7900000000000000',
      high: '250.2800000000000000',
      low: '242.0000000000000000',
      close: '247.7800000000000000',
      volume: '431285.6171500000000000',
      quoteVolume: 105797248.24742587,
      btcVolume: 10771.848889298295,
      usdVolume: 105797248.24742587,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '247.7800000000000000',
      high: '250.0900000000000000',
      low: '226.2000000000000000',
      close: '230.5100000000000000',
      volume: '815431.7796500000000000',
      quoteVolume: 194825983.3024181,
      btcVolume: 20371.297467425575,
      usdVolume: 194825983.3024181,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '230.4600000000000000',
      high: '239.3800000000000000',
      low: '228.1900000000000000',
      close: '237.5500000000000000',
      volume: '444488.9407200000000000',
      quoteVolume: 104599402.02950011,
      btcVolume: 11109.617756002966,
      usdVolume: 104599402.02950011,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '237.5500000000000000',
      high: '238.7300000000000000',
      low: '234.6600000000000000',
      close: '238.1100000000000000',
      volume: '253797.7809000000000000',
      quoteVolume: 60087772.65246506,
      btcVolume: 6372.817903545626,
      usdVolume: 60087772.65246506,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '238.1300000000000000',
      high: '238.5900000000000000',
      low: '229.8300000000000000',
      close: '231.7700000000000000',
      volume: '304943.2118800000000000',
      quoteVolume: 71575514.08027163,
      btcVolume: 7622.81941591543,
      usdVolume: 71575514.08027163,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '231.7400000000000000',
      high: '233.5500000000000000',
      low: '218.2700000000000000',
      close: '230.8600000000000000',
      volume: '875026.9205700000000000',
      quoteVolume: 197907504.77877796,
      btcVolume: 21476.01503478616,
      usdVolume: 197907504.77877796,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '230.8600000000000000',
      high: '236.0000000000000000',
      low: '228.7000000000000000',
      close: '235.3000000000000000',
      volume: '499194.6675800000000000',
      quoteVolume: 116456364.2312674,
      btcVolume: 12259.734211678307,
      usdVolume: 116456364.2312674,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '235.3100000000000000',
      high: '237.3000000000000000',
      low: '227.6500000000000000',
      close: '233.8200000000000000',
      volume: '478187.2917000000000000',
      quoteVolume: 111415059.05626224,
      btcVolume: 11798.305623152255,
      usdVolume: 111415059.05626224,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '233.8200000000000000',
      high: '234.6500000000000000',
      low: '227.9300000000000000',
      close: '231.2700000000000000',
      volume: '334347.7991700000000000',
      quoteVolume: 77542070.33327071,
      btcVolume: 8244.094026212786,
      usdVolume: 77542070.33327071,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '231.2600000000000000',
      high: '231.9500000000000000',
      low: '226.7400000000000000',
      close: '229.0000000000000000',
      volume: '397284.5980800000000000',
      quoteVolume: 91117706.15513879,
      btcVolume: 9760.152539929302,
      usdVolume: 91117706.15513879,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '229.0000000000000000',
      high: '230.8500000000000000',
      low: '225.6200000000000000',
      close: '228.8900000000000000',
      volume: '303704.9882600000000000',
      quoteVolume: 69343699.90954724,
      btcVolume: 7455.57113620382,
      usdVolume: 69343699.90954724,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '228.8800000000000000',
      high: '231.4900000000000000',
      low: '227.1100000000000000',
      close: '228.0400000000000000',
      volume: '256305.2373400000000000',
      quoteVolume: 58868166.506103784,
      btcVolume: 6293.000295933788,
      usdVolume: 58868166.506103784,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '228.0300000000000000',
      high: '246.8900000000000000',
      low: '227.5100000000000000',
      close: '243.1300000000000000',
      volume: '748759.3895600000000000',
      quoteVolume: 178643659.67329305,
      btcVolume: 18825.751197784553,
      usdVolume: 178643659.67329305,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '243.1100000000000000',
      high: '244.8700000000000000',
      low: '240.7500000000000000',
      close: '243.1400000000000000',
      volume: '442226.8735200000000000',
      quoteVolume: 107440173.5377359,
      btcVolume: 11144.411233446323,
      usdVolume: 107440173.5377359,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '243.1500000000000000',
      high: '249.2400000000000000',
      low: '231.0700000000000000',
      close: '234.5800000000000000',
      volume: '921634.0534000000000000',
      quoteVolume: 220763040.43374506,
      btcVolume: 23383.216137878488,
      usdVolume: 220763040.43374506,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '234.5700000000000000',
      high: '235.3900000000000000',
      low: '227.5600000000000000',
      close: '232.5000000000000000',
      volume: '613748.5934800000000000',
      quoteVolume: 142697463.3801034,
      btcVolume: 15489.816263370116,
      usdVolume: 142697463.3801034,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '232.4900000000000000',
      high: '233.6100000000000000',
      low: '227.7500000000000000',
      close: '229.6400000000000000',
      volume: '628340.0700700000000000',
      quoteVolume: 144805505.1915821,
      btcVolume: 15783.082372432726,
      usdVolume: 144805505.1915821,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '229.6600000000000000',
      high: '231.4200000000000000',
      low: '216.0000000000000000',
      close: '220.9400000000000000',
      volume: '645950.6143800000000000',
      quoteVolume: 145264632.26381838,
      btcVolume: 16009.140095513289,
      usdVolume: 145264632.26381838,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '220.9400000000000000',
      high: '227.9600000000000000',
      low: '218.3200000000000000',
      close: '224.9300000000000000',
      volume: '444766.5688100000000000',
      quoteVolume: 99423540.13931867,
      btcVolume: 10949.038056790398,
      usdVolume: 99423540.13931867,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '224.8900000000000000',
      high: '229.9600000000000000',
      low: '221.2600000000000000',
      close: '227.9300000000000000',
      volume: '597915.5224200000000000',
      quoteVolume: 134510531.19766667,
      btcVolume: 14743.622729629511,
      usdVolume: 134510531.19766667,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '227.9800000000000000',
      high: '228.7000000000000000',
      low: '222.9100000000000000',
      close: '225.6000000000000000',
      volume: '415036.0297700000000000',
      quoteVolume: 93802207.27357335,
      btcVolume: 10255.185172332418,
      usdVolume: 93802207.27357335,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '225.5900000000000000',
      high: '232.7100000000000000',
      low: '224.1600000000000000',
      close: '230.9900000000000000',
      volume: '515968.3174700000000000',
      quoteVolume: 118009144.95166142,
      btcVolume: 12823.681622799983,
      usdVolume: 118009144.95166142,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '230.9900000000000000',
      high: '232.0100000000000000',
      low: '222.8700000000000000',
      close: '226.4600000000000000',
      volume: '592485.9111500000000000',
      quoteVolume: 134976975.12319767,
      btcVolume: 14752.25945808237,
      usdVolume: 134976975.12319767,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '226.4600000000000000',
      high: '228.1300000000000000',
      low: '223.9700000000000000',
      close: '224.8700000000000000',
      volume: '312108.6060700000000000',
      quoteVolume: 70729994.77950838,
      btcVolume: 7780.293748705394,
      usdVolume: 70729994.77950838,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '224.8600000000000000',
      high: '230.7100000000000000',
      low: '224.4300000000000000',
      close: '229.1700000000000000',
      volume: '298119.6003300000000000',
      quoteVolume: 67726803.92678858,
      btcVolume: 7439.9741424165295,
      usdVolume: 67726803.92678858,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '229.2400000000000000',
      high: '229.8500000000000000',
      low: '223.0500000000000000',
      close: '227.5600000000000000',
      volume: '341994.9329000000000000',
      quoteVolume: 77425409.00913155,
      btcVolume: 8548.264489930476,
      usdVolume: 77425409.00913155,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '227.5400000000000000',
      high: '242.0000000000000000',
      low: '226.9300000000000000',
      close: '241.6000000000000000',
      volume: '864817.5070000000000000',
      quoteVolume: 203334727.850139,
      btcVolume: 22028.523967262292,
      usdVolume: 203334727.850139,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '241.6000000000000000',
      high: '243.7700000000000000',
      low: '234.4000000000000000',
      close: '239.3900000000000000',
      volume: '600378.4220900000000000',
      quoteVolume: 143111734.11362022,
      btcVolume: 15434.282792689764,
      usdVolume: 143111734.11362022,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '239.3900000000000000',
      high: '248.8800000000000000',
      low: '237.7800000000000000',
      close: '246.9500000000000000',
      volume: '942434.0782300000000000',
      quoteVolume: 229569778.54287842,
      btcVolume: 24561.674657966098,
      usdVolume: 229569778.54287842,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '246.9400000000000000',
      high: '247.9000000000000000',
      low: '237.8800000000000000',
      close: '241.9800000000000000',
      volume: '647641.5829100000000000',
      quoteVolume: 157541370.0048171,
      btcVolume: 16903.849735123644,
      usdVolume: 157541370.0048171,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '241.9700000000000000',
      high: '242.1600000000000000',
      low: '235.7200000000000000',
      close: '241.2600000000000000',
      volume: '455025.1238500000000000',
      quoteVolume: 108806462.4650961,
      btcVolume: 11835.149454014792,
      usdVolume: 108806462.4650961,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '241.2700000000000000',
      high: '241.6700000000000000',
      low: '237.5400000000000000',
      close: '239.1900000000000000',
      volume: '290546.6408300000000000',
      quoteVolume: 69603089.08903724,
      btcVolume: 7535.268541585202,
      usdVolume: 69603089.08903724,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '239.1900000000000000',
      high: '244.0000000000000000',
      low: '236.5200000000000000',
      close: '242.7900000000000000',
      volume: '469315.1172000000000000',
      quoteVolume: 112487552.43401246,
      btcVolume: 12159.372376119501,
      usdVolume: 112487552.43401246,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '242.7600000000000000',
      high: '245.3300000000000000',
      low: '237.3300000000000000',
      close: '239.5000000000000000',
      volume: '604093.0424500000000000',
      quoteVolume: 146193291.91067657,
      btcVolume: 15758.109658099816,
      usdVolume: 146193291.91067657,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '239.5000000000000000',
      high: '242.0900000000000000',
      low: '237.0000000000000000',
      close: '240.4400000000000000',
      volume: '426320.8918800000000000',
      quoteVolume: 102105027.13192993,
      btcVolume: 11077.23641948493,
      usdVolume: 102105027.13192993,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '240.4500000000000000',
      high: '241.4500000000000000',
      low: '236.8300000000000000',
      close: '238.6100000000000000',
      volume: '368350.6651800000000000',
      quoteVolume: 88059682.48343667,
      btcVolume: 9547.404137094758,
      usdVolume: 88059682.48343667,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '238.5900000000000000',
      high: '239.3900000000000000',
      low: '230.0200000000000000',
      close: '233.5700000000000000',
      volume: '587161.7389400000000000',
      quoteVolume: 137208323.20157194,
      btcVolume: 15019.41735536055,
      usdVolume: 137208323.20157194,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '233.5800000000000000',
      high: '234.8400000000000000',
      low: '231.6500000000000000',
      close: '232.6700000000000000',
      volume: '325579.4645700000000000',
      quoteVolume: 75926217.2310008,
      btcVolume: 8310.273922367218,
      usdVolume: 75926217.2310008,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '232.6600000000000000',
      high: '236.8000000000000000',
      low: '232.3200000000000000',
      close: '235.6700000000000000',
      volume: '341356.6182000000000000',
      quoteVolume: 80046747.8322333,
      btcVolume: 8738.120886553355,
      usdVolume: 80046747.8322333,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '235.6700000000000000',
      high: '239.9200000000000000',
      low: '233.2800000000000000',
      close: '239.1100000000000000',
      volume: '265225.5624400000000000',
      quoteVolume: 62547101.402666144,
      btcVolume: 6826.036997576792,
      usdVolume: 62547101.402666144,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '239.1200000000000000',
      high: '239.7200000000000000',
      low: '234.0500000000000000',
      close: '236.0100000000000000',
      volume: '396657.9193800000000000',
      quoteVolume: 94314378.93819806,
      btcVolume: 10278.861999376948,
      usdVolume: 94314378.93819806,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '236.0000000000000000',
      high: '246.6600000000000000',
      low: '235.5700000000000000',
      close: '245.5900000000000000',
      volume: '637462.0807400000000000',
      quoteVolume: 154502240.94120297,
      btcVolume: 16568.240820505314,
      usdVolume: 154502240.94120297,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '245.5300000000000000',
      high: '269.6100000000000000',
      low: '241.5100000000000000',
      close: '263.7400000000000000',
      volume: '718412.8694500000000000',
      quoteVolume: 180691409.64491135,
      btcVolume: 19223.102848907532,
      usdVolume: 180691409.64491135,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '263.7500000000000000',
      high: '279.9800000000000000',
      low: '259.7000000000000000',
      close: '275.3000000000000000',
      volume: '1068774.5787900000000000',
      quoteVolume: 287217202.72011954,
      btcVolume: 30130.661822095655,
      usdVolume: 287217202.72011954,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '275.3100000000000000',
      high: '287.3400000000000000',
      low: '266.0000000000000000',
      close: '279.1500000000000000',
      volume: '926033.0669200000000000',
      quoteVolume: 256347245.54814723,
      btcVolume: 26844.91878187144,
      usdVolume: 256347245.54814723,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '279.1500000000000000',
      high: '308.9400000000000000',
      low: '0.0000102300000000',
      close: '305.2600000000000000',
      volume: '1060891.1375400000000000',
      quoteVolume: 308799629.3812334,
      btcVolume: 32052.82286372341,
      usdVolume: 308799821.8866399,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '305.3100000000000000',
      high: '318.7500000000000000',
      low: '299.0000000000000000',
      close: '311.0000000000000000',
      volume: '1270368.7530000000000000',
      quoteVolume: 392346895.32266885,
      btcVolume: 39870.04525589428,
      usdVolume: 392346895.32266885,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '311.0200000000000000',
      high: '333.3100000000000000',
      low: '310.7800000000000000',
      close: '321.9300000000000000',
      volume: '1636122.3472700000000000',
      quoteVolume: 527292799.2879079,
      btcVolume: 50537.47976051564,
      usdVolume: 527292799.2879079,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '321.9500000000000000',
      high: '326.8000000000000000',
      low: '305.8600000000000000',
      close: '316.7700000000000000',
      volume: '1093231.5557800000000000',
      quoteVolume: 346187853.2759322,
      btcVolume: 31616.78758360037,
      usdVolume: 346187853.2759322,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '316.7700000000000000',
      high: '325.0000000000000000',
      low: '312.3800000000000000',
      close: '317.7100000000000000',
      volume: '792802.3214800000000000',
      quoteVolume: 254201662.13340423,
      btcVolume: 22934.294000855425,
      usdVolume: 254201662.13340423,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '317.7600000000000000',
      high: '342.0000000000000000',
      low: '314.0500000000000000',
      close: '334.8700000000000000',
      volume: '1008827.2143400000000000',
      quoteVolume: 328286340.23770994,
      btcVolume: 29714.76886977982,
      usdVolume: 328286340.23770994,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '334.8200000000000000',
      high: '349.4500000000000000',
      low: '328.4000000000000000',
      close: '346.3300000000000000',
      volume: '948088.4498200000000000',
      quoteVolume: 323282981.0159161,
      btcVolume: 28866.335039593676,
      usdVolume: 323282981.0159161,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '346.3200000000000000',
      high: '392.6700000000000000',
      low: '343.0700000000000000',
      close: '387.0000000000000000',
      volume: '1440843.3420300000000000',
      quoteVolume: 527543444.8628902,
      btcVolume: 45339.60061159785,
      usdVolume: 527543444.8628902,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '387.0100000000000000',
      high: '415.8500000000000000',
      low: '325.0000000000000000',
      close: '372.4200000000000000',
      volume: '2324909.0961600000000000',
      quoteVolume: 878397036.2975318,
      btcVolume: 77495.83814159164,
      usdVolume: 878397036.2975318,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '372.4500000000000000',
      high: '398.0000000000000000',
      low: '366.5000000000000000',
      close: '385.9400000000000000',
      volume: '1191565.0938900000000000',
      quoteVolume: 462170587.4784976,
      btcVolume: 41057.234921476615,
      usdVolume: 462170587.4784976,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '385.9100000000000000',
      high: '402.9800000000000000',
      low: '380.7700000000000000',
      close: '389.6100000000000000',
      volume: '1175763.9381400000000000',
      quoteVolume: 459835053.5956598,
      btcVolume: 40921.10256553506,
      usdVolume: 459835053.5956598,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '389.5900000000000000',
      high: '407.0000000000000000',
      low: '384.1100000000000000',
      close: '400.7200000000000000',
      volume: '1124119.0672000000000000',
      quoteVolume: 444335097.1493966,
      btcVolume: 38666.919386438094,
      usdVolume: 444335097.1493966,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '400.7200000000000000',
      high: '403.6600000000000000',
      low: '390.5100000000000000',
      close: '394.7700000000000000',
      volume: '821379.0839700000000000',
      quoteVolume: 325718417.71600866,
      btcVolume: 27726.769672481845,
      usdVolume: 325718417.71600866,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '394.7700000000000000',
      high: '398.2600000000000000',
      low: '362.8500000000000000',
      close: '379.3400000000000000',
      volume: '1041207.3594600000000000',
      quoteVolume: 398625567.06390804,
      btcVolume: 34262.12382888949,
      usdVolume: 398625567.06390804,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '379.3400000000000000',
      high: '397.0500000000000000',
      low: '375.7900000000000000',
      close: '397.0300000000000000',
      volume: '623013.8133600000000000',
      quoteVolume: 240992993.07174557,
      btcVolume: 20611.4249741265,
      usdVolume: 240992993.07174557,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '397.0500000000000000',
      high: '400.0000000000000000',
      low: '383.3600000000000000',
      close: '390.1400000000000000',
      volume: '594729.4803400000000000',
      quoteVolume: 232989976.98507214,
      btcVolume: 19961.97802262133,
      usdVolume: 232989976.98507214,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '390.1400000000000000',
      high: '399.1800000000000000',
      low: '385.1300000000000000',
      close: '395.4400000000000000',
      volume: '826969.9063300000000000',
      quoteVolume: 326372434.89705575,
      btcVolume: 27443.329738405846,
      usdVolume: 326372434.89705575,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '395.4300000000000000',
      high: '398.2100000000000000',
      low: '367.0000000000000000',
      close: '379.0000000000000000',
      volume: '992412.6023000000000000',
      quoteVolume: 381981614.62167376,
      btcVolume: 33030.351758669196,
      usdVolume: 381981614.62167376,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '378.9900000000000000',
      high: '389.2300000000000000',
      low: '365.8500000000000000',
      close: '387.1800000000000000',
      volume: '751848.7983000000000000',
      quoteVolume: 285872467.64409924,
      btcVolume: 24976.58134909489,
      usdVolume: 285872467.64409924,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '387.1800000000000000',
      high: '431.4900000000000000',
      low: '376.5900000000000000',
      close: '424.5400000000000000',
      volume: '1511330.7732000000000000',
      quoteVolume: 604810975.1318486,
      btcVolume: 52246.13470100553,
      usdVolume: 604810975.1318486,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '424.5400000000000000',
      high: '444.7100000000000000',
      low: '417.0500000000000000',
      close: '438.1100000000000000',
      volume: '1108919.1175900000000000',
      quoteVolume: 476850417.99412924,
      btcVolume: 40596.08524064946,
      usdVolume: 476850417.99412924,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '438.1200000000000000',
      high: '442.9400000000000000',
      low: '427.0200000000000000',
      close: '432.3200000000000000',
      volume: '681696.2610000000000000',
      quoteVolume: 296651951.35869735,
      btcVolume: 25006.734408894456,
      usdVolume: 296651951.35869735,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '432.3000000000000000',
      high: '436.9900000000000000',
      low: '412.4700000000000000',
      close: '433.6200000000000000',
      volume: '716666.2525800000000000',
      quoteVolume: 305445885.8166684,
      btcVolume: 25821.942518111297,
      usdVolume: 305445885.8166684,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '433.6100000000000000',
      high: '446.0000000000000000',
      low: '420.3700000000000000',
      close: '430.8900000000000000',
      volume: '868870.6499600000000000',
      quoteVolume: 375038999.50752,
      btcVolume: 31000.024384319247,
      usdVolume: 375038999.50752,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '430.8900000000000000',
      high: '433.8100000000000000',
      low: '415.0000000000000000',
      close: '421.9500000000000000',
      volume: '743254.5701800000000000',
      quoteVolume: 316635286.99291563,
      btcVolume: 26116.737005652198,
      usdVolume: 316635286.99291563,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '421.9200000000000000',
      high: '426.7500000000000000',
      low: '393.2500000000000000',
      close: '407.4600000000000000',
      volume: '978339.6922100000000000',
      quoteVolume: 400208683.34672534,
      btcVolume: 34018.89009102192,
      usdVolume: 400208683.34672534,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '407.4600000000000000',
      high: '418.9800000000000000',
      low: '402.0700000000000000',
      close: '415.9400000000000000',
      volume: '660964.6963900000000000',
      quoteVolume: 272240068.376272,
      btcVolume: 23069.824029488518,
      usdVolume: 272240068.376272,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '415.9400000000000000',
      high: '418.6800000000000000',
      low: '385.1000000000000000',
      close: '387.9700000000000000',
      volume: '860092.2392900000000000',
      quoteVolume: 347090880.07555234,
      btcVolume: 29626.18363494332,
      usdVolume: 347090880.07555234,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '387.9300000000000000',
      high: '397.0400000000000000',
      low: '379.9200000000000000',
      close: '395.2400000000000000',
      volume: '643112.7567000000000000',
      quoteVolume: 250021920.7245382,
      btcVolume: 21657.375885407164,
      usdVolume: 250021920.7245382,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '395.2300000000000000',
      high: '396.9500000000000000',
      low: '383.1500000000000000',
      close: '390.7100000000000000',
      volume: '445307.5880200000000000',
      quoteVolume: 173795952.99000394,
      btcVolume: 14968.181091353075,
      usdVolume: 173795952.99000394,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '390.7200000000000000',
      high: '410.4200000000000000',
      low: '387.4400000000000000',
      close: '407.6800000000000000',
      volume: '717395.9721000000000000',
      quoteVolume: 287988643.53621656,
      btcVolume: 24526.709155711666,
      usdVolume: 287988643.53621656,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '407.6800000000000000',
      high: '409.0600000000000000',
      low: '370.0000000000000000',
      close: '382.9300000000000000',
      volume: '951557.2886700000000000',
      quoteVolume: 368309918.2113772,
      btcVolume: 32162.02308140377,
      usdVolume: 368309918.2113772,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '382.9100000000000000',
      high: '392.9100000000000000',
      low: '377.3700000000000000',
      close: '385.8100000000000000',
      volume: '738590.5295000000000000',
      quoteVolume: 284465412.4634799,
      btcVolume: 24953.561629989617,
      usdVolume: 284465412.4634799,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '385.8100000000000000',
      high: '397.1000000000000000',
      low: '371.4800000000000000',
      close: '383.2700000000000000',
      volume: '876931.1946000000000000',
      quoteVolume: 336328075.9025886,
      btcVolume: 29644.310136701326,
      usdVolume: 336328075.9025886,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '383.3100000000000000',
      high: '398.0000000000000000',
      low: '379.7500000000000000',
      close: '395.1100000000000000',
      volume: '721730.6497200000000000',
      quoteVolume: 282252408.9354685,
      btcVolume: 24674.28459629944,
      usdVolume: 282252408.9354685,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '395.1000000000000000',
      high: '405.6900000000000000',
      low: '391.8800000000000000',
      close: '398.6500000000000000',
      volume: '654320.0035500000000000',
      quoteVolume: 261582840.97993016,
      btcVolume: 22739.15544880467,
      usdVolume: 261582840.97993016,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '398.6500000000000000',
      high: '429.8900000000000000',
      low: '398.3000000000000000',
      close: '428.9400000000000000',
      volume: '851160.6742400000000000',
      quoteVolume: 352311003.28142095,
      btcVolume: 30325.394571282835,
      usdVolume: 352311003.28142095,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '428.9400000000000000',
      high: '439.0000000000000000',
      low: '418.0400000000000000',
      close: '433.7900000000000000',
      volume: '1040748.6888100000000000',
      quoteVolume: 447866996.185111,
      btcVolume: 38337.44332463666,
      usdVolume: 447866996.185111,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '433.8000000000000000',
      high: '488.8400000000000000',
      low: '429.0300000000000000',
      close: '475.4000000000000000',
      volume: '1656066.8494900000000000',
      quoteVolume: 769006775.5385326,
      btcVolume: 64803.214479942595,
      usdVolume: 768959484.5333103,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '475.4000000000000000',
      high: '481.3900000000000000',
      low: '420.0000000000000000',
      close: '439.5000000000000000',
      volume: '1876373.4429600000000000',
      quoteVolume: 842455901.235392,
      btcVolume: 73163.14575846263,
      usdVolume: 842455901.235392,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '439.5000000000000000',
      high: '450.7000000000000000',
      low: '372.0000000000000000',
      close: '381.4000000000000000',
      volume: '2402627.0353300000000000',
      quoteVolume: 993081852.551086,
      btcVolume: 90931.01288632085,
      usdVolume: 993081852.551086,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '381.3500000000000000',
      high: '400.2100000000000000',
      low: '357.0000000000000000',
      close: '385.2600000000000000',
      volume: '1806487.9639800000000000',
      quoteVolume: 696658830.8205959,
      btcVolume: 67364.86665822042,
      usdVolume: 696658830.8205959,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '385.3000000000000000',
      high: '395.0000000000000000',
      low: '308.4200000000000000',
      close: '335.0100000000000000',
      volume: '2776774.5031000000000000',
      quoteVolume: 967581076.4469733,
      btcVolume: 94990.08545339752,
      usdVolume: 967581076.4469733,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '335.0400000000000000',
      high: '360.0000000000000000',
      low: '316.1000000000000000',
      close: '352.8000000000000000',
      volume: '2017811.1472100000000000',
      quoteVolume: 691925087.3968136,
      btcVolume: 67832.96092299436,
      usdVolume: 691925087.3968136,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '352.8500000000000000',
      high: '358.5100000000000000',
      low: '323.0000000000000000',
      close: '353.5900000000000000',
      volume: '1721408.5783500000000000',
      quoteVolume: 590110812.7562342,
      btcVolume: 58285.20441211165,
      usdVolume: 590110812.7562342,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '353.5900000000000000',
      high: '356.5800000000000000',
      low: '325.3800000000000000',
      close: '337.3900000000000000',
      volume: '1400246.8296200000000000',
      quoteVolume: 476444912.54462713,
      btcVolume: 47124.00188261758,
      usdVolume: 476444912.54462713,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '337.4100000000000000',
      high: '359.0000000000000000',
      low: '330.5100000000000000',
      close: '350.8500000000000000',
      volume: '879760.5625500000000000',
      quoteVolume: 304910633.80089056,
      btcVolume: 29909.92428361022,
      usdVolume: 304910633.80089056,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '350.8500000000000000',
      high: '377.2500000000000000',
      low: '349.9100000000000000',
      close: '367.9300000000000000',
      volume: '1134065.2960700000000000',
      quoteVolume: 416517016.27456033,
      btcVolume: 40287.65628581293,
      usdVolume: 416517016.27456033,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '367.9300000000000000',
      high: '375.2700000000000000',
      low: '355.4900000000000000',
      close: '373.6300000000000000',
      volume: '844017.5996600000000000',
      quoteVolume: 308822715.13365656,
      btcVolume: 29997.26435090535,
      usdVolume: 308822715.13365656,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '373.6200000000000000',
      high: '388.5700000000000000',
      low: '364.7300000000000000',
      close: '387.7800000000000000',
      volume: '766542.3163500000000000',
      quoteVolume: 287133871.2472935,
      btcVolume: 27668.664342038548,
      usdVolume: 287133871.2472935,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '387.7800000000000000',
      high: '390.4300000000000000',
      low: '350.0000000000000000',
      close: '366.4200000000000000',
      volume: '989195.6904700000000000',
      quoteVolume: 367969518.5134864,
      btcVolume: 35461.65474002583,
      usdVolume: 367969518.5134864,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '366.4300000000000000',
      high: '384.6400000000000000',
      low: '355.7300000000000000',
      close: '377.1200000000000000',
      volume: '1042059.6226500000000000',
      quoteVolume: 387238794.68096745,
      btcVolume: 36789.98529463114,
      usdVolume: 387238794.68096745,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '377.1200000000000000',
      high: '382.4700000000000000',
      low: '362.3400000000000000',
      close: '364.0600000000000000',
      volume: '883856.3300500000000000',
      quoteVolume: 328317009.5683414,
      btcVolume: 30470.16739104851,
      usdVolume: 328317009.5683414,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '364.0500000000000000',
      high: '373.4500000000000000',
      low: '355.0000000000000000',
      close: '365.1100000000000000',
      volume: '841709.9893900000000000',
      quoteVolume: 306622238.0715463,
      btcVolume: 28177.270136204377,
      usdVolume: 306622238.0715463,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '365.0400000000000000',
      high: '394.1400000000000000',
      low: '363.3000000000000000',
      close: '389.3600000000000000',
      volume: '1256300.4110000000000000',
      quoteVolume: 479026710.896617,
      btcVolume: 43950.597949227995,
      usdVolume: 479026710.896617,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '389.3500000000000000',
      high: '392.5800000000000000',
      low: '375.3300000000000000',
      close: '384.3900000000000000',
      volume: '804194.4721900000000000',
      quoteVolume: 308748579.81603116,
      btcVolume: 28281.960169687176,
      usdVolume: 308748579.81603116,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '384.4000000000000000',
      high: '388.7200000000000000',
      low: '377.0000000000000000',
      close: '385.2500000000000000',
      volume: '570908.3355300000000000',
      quoteVolume: 218785730.14017949,
      btcVolume: 19860.826040587017,
      usdVolume: 218785730.14017949,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '385.2500000000000000',
      high: '385.4500000000000000',
      low: '365.0000000000000000',
      close: '371.0400000000000000',
      volume: '692270.5369000000000000',
      quoteVolume: 259365469.57772288,
      btcVolume: 23796.352412135322,
      usdVolume: 259365469.57772288,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '371.0400000000000000',
      high: '376.8000000000000000',
      low: '331.0000000000000000',
      close: '340.3600000000000000',
      volume: '1283946.0309800000000000',
      quoteVolume: 452134893.6340549,
      btcVolume: 42542.986905510355,
      usdVolume: 452134893.6340549,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '340.3100000000000000',
      high: '346.9100000000000000',
      low: '335.3400000000000000',
      close: '344.2100000000000000',
      volume: '842519.6630700000000000',
      quoteVolume: 288094132.61557794,
      btcVolume: 27542.53930759665,
      usdVolume: 288094132.61557794,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '344.1800000000000000',
      high: '344.6500000000000000',
      low: '313.2900000000000000',
      close: '320.7200000000000000',
      volume: '940904.4975600000000000',
      quoteVolume: 313808761.09044343,
      btcVolume: 30190.07605426562,
      usdVolume: 313808761.09044343,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '320.7200000000000000',
      high: '353.1000000000000000',
      low: '316.6700000000000000',
      close: '348.9700000000000000',
      volume: '1070458.7500100000000000',
      quoteVolume: 360295904.75400215,
      btcVolume: 34447.673829712236,
      usdVolume: 360295904.75400215,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '348.9700000000000000',
      high: '358.0000000000000000',
      low: '337.5800000000000000',
      close: '351.9200000000000000',
      volume: '860643.7792400000000000',
      quoteVolume: 298266918.80064315,
      btcVolume: 27960.07827767787,
      usdVolume: 298266918.80064315,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '351.9300000000000000',
      high: '356.4000000000000000',
      low: '345.8800000000000000',
      close: '353.9200000000000000',
      volume: '530698.1258100000000000',
      quoteVolume: 186748081.4048322,
      btcVolume: 17435.278426956505,
      usdVolume: 186748081.4048322,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '353.9500000000000000',
      high: '362.1400000000000000',
      low: '347.2400000000000000',
      close: '357.4900000000000000',
      volume: '569255.6546900000000000',
      quoteVolume: 202300944.46097547,
      btcVolume: 18873.55464049207,
      usdVolume: 202300944.46097547,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '357.5800000000000000',
      high: '368.2000000000000000',
      low: '351.7000000000000000',
      close: '353.9600000000000000',
      volume: '757758.4304000000000000',
      quoteVolume: 273063157.7899871,
      btcVolume: 25118.54484016467,
      usdVolume: 273063157.7899871,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '353.9100000000000000',
      high: '360.6300000000000000',
      low: '350.4500000000000000',
      close: '359.7900000000000000',
      volume: '585138.1015300000000000',
      quoteVolume: 208027010.0546198,
      btcVolume: 19389.23399823974,
      usdVolume: 208027010.0546198,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '359.7800000000000000',
      high: '361.5000000000000000',
      low: '351.5600000000000000',
      close: '359.8300000000000000',
      volume: '498791.2080200000000000',
      quoteVolume: 177730785.31600055,
      btcVolume: 16544.49139067945,
      usdVolume: 177730785.31600055,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '359.8300000000000000',
      high: '370.4500000000000000',
      low: '345.5100000000000000',
      close: '353.0300000000000000',
      volume: '749944.4218500000000000',
      quoteVolume: 268507606.4936562,
      btcVolume: 25081.67090101762,
      usdVolume: 268507606.4936562,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '351.5000000000000000',
      high: '354.5500000000000000',
      low: '334.2400000000000000',
      close: '345.6000000000000000',
      volume: '728971.7125400000000000',
      quoteVolume: 250210659.13679448,
      btcVolume: 23823.341024494177,
      usdVolume: 250210659.13679448,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '345.6000000000000000',
      high: '351.0000000000000000',
      low: '343.7000000000000000',
      close: '345.9500000000000000',
      volume: '303595.7190100000000000',
      quoteVolume: 105401893.35734256,
      btcVolume: 9994.847525254354,
      usdVolume: 105401893.35734256,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '345.9400000000000000',
      high: '354.4500000000000000',
      low: '343.8100000000000000',
      close: '352.3700000000000000',
      volume: '343932.9171200000000000',
      quoteVolume: 119961274.70065778,
      btcVolume: 11308.936300639205,
      usdVolume: 119961274.70065778,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '352.3400000000000000',
      high: '356.1000000000000000',
      low: '348.5400000000000000',
      close: '353.6200000000000000',
      volume: '376740.3069200000000000',
      quoteVolume: 132656713.04409541,
      btcVolume: 12401.031763501092,
      usdVolume: 132656713.04409541,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '353.6300000000000000',
      high: '355.1000000000000000',
      low: '336.8000000000000000',
      close: '340.7500000000000000',
      volume: '640027.0228500000000000',
      quoteVolume: 221752725.91989398,
      btcVolume: 20777.07296176719,
      usdVolume: 221752725.91989398,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '340.8000000000000000',
      high: '342.8500000000000000',
      low: '332.1800000000000000',
      close: '341.5800000000000000',
      volume: '541928.8637600000000000',
      quoteVolume: 183873686.39043358,
      btcVolume: 17327.5506872979,
      usdVolume: 183873686.39043358,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '341.5800000000000000',
      high: '353.3200000000000000',
      low: '334.2100000000000000',
      close: '350.9500000000000000',
      volume: '622582.1733200000000000',
      quoteVolume: 214085732.44744968,
      btcVolume: 19925.932109701625,
      usdVolume: 214085732.44744968,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '350.9400000000000000',
      high: '368.3300000000000000',
      low: '347.3200000000000000',
      close: '365.0200000000000000',
      volume: '650478.1933800000000000',
      quoteVolume: 232878561.88521227,
      btcVolume: 21172.01801686735,
      usdVolume: 232878561.88521227,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '365.0100000000000000',
      high: '378.4000000000000000',
      low: '364.7100000000000000',
      close: '370.5200000000000000',
      volume: '591005.3212900000000000',
      quoteVolume: 220607973.01227474,
      btcVolume: 19473.834142001007,
      usdVolume: 220607973.01227474,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '370.5100000000000000',
      high: '377.6000000000000000',
      low: '368.8700000000000000',
      close: '374.1600000000000000',
      volume: '371050.1549000000000000',
      quoteVolume: 138482829.08381432,
      btcVolume: 12189.491907556405,
      usdVolume: 138482829.08381432,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '374.1700000000000000',
      high: '394.8000000000000000',
      low: '365.5800000000000000',
      close: '386.5100000000000000',
      volume: '777805.9870200000000000',
      quoteVolume: 295379758.31860316,
      btcVolume: 25799.813917940977,
      usdVolume: 295379758.31860316,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '386.5100000000000000',
      high: '387.7500000000000000',
      low: '374.5100000000000000',
      close: '381.0400000000000000',
      volume: '614228.9526600000000000',
      quoteVolume: 234463380.94917893,
      btcVolume: 20497.322200014103,
      usdVolume: 234463380.94917893,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '381.0500000000000000',
      high: '387.4100000000000000',
      low: '373.1300000000000000',
      close: '378.6900000000000000',
      volume: '531769.6736300000000000',
      quoteVolume: 201932522.19194937,
      btcVolume: 17718.0848554347,
      usdVolume: 201932522.19194937,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '378.6900000000000000',
      high: '381.7900000000000000',
      low: '369.8500000000000000',
      close: '377.6400000000000000',
      volume: '632253.2404100000000000',
      quoteVolume: 237722026.66919282,
      btcVolume: 20862.537140127723,
      usdVolume: 237722026.66919282,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '377.6300000000000000',
      high: '379.8300000000000000',
      low: '361.1800000000000000',
      close: '365.5000000000000000',
      volume: '654029.3640400000000000',
      quoteVolume: 241127894.70244646,
      btcVolume: 21270.53628135259,
      usdVolume: 241127894.70244646,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '365.5000000000000000',
      high: '370.4600000000000000',
      low: '363.3600000000000000',
      close: '368.3200000000000000',
      volume: '285578.2453700000000000',
      quoteVolume: 104838880.45610258,
      btcVolume: 9248.343343944713,
      usdVolume: 104838880.45610258,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '368.3100000000000000',
      high: '378.6400000000000000',
      low: '367.1000000000000000',
      close: '378.1100000000000000',
      volume: '323468.4709500000000000',
      quoteVolume: 120818450.62118112,
      btcVolume: 10571.651931836788,
      usdVolume: 120818450.62118112,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '378.1200000000000000',
      high: '384.3200000000000000',
      low: '372.5000000000000000',
      close: '379.3000000000000000',
      volume: '577252.9026500000000000',
      quoteVolume: 218372639.95702484,
      btcVolume: 18818.921615016232,
      usdVolume: 218372639.95702484,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '379.3000000000000000',
      high: '380.7300000000000000',
      low: '365.2700000000000000',
      close: '368.5200000000000000',
      volume: '617309.8167700000000000',
      quoteVolume: 229928443.79690987,
      btcVolume: 19409.513615432777,
      usdVolume: 229928443.79690987,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '368.5300000000000000',
      high: '401.4900000000000000',
      low: '367.7400000000000000',
      close: '390.3800000000000000',
      volume: '1221663.6378400000000000',
      quoteVolume: 472488832.71627617,
      btcVolume: 37631.22329892552,
      usdVolume: 472488832.71627617,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '390.3100000000000000',
      high: '420.7300000000000000',
      low: '390.2100000000000000',
      close: '413.9800000000000000',
      volume: '1095886.2298700000000000',
      quoteVolume: 447206682.58595973,
      btcVolume: 34562.69697720812,
      usdVolume: 447206682.58595973,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '413.9400000000000000',
      high: '419.3300000000000000',
      low: '401.3000000000000000',
      close: '405.9200000000000000',
      volume: '642369.4293200000000000',
      quoteVolume: 265063811.5230488,
      btcVolume: 20523.233149956377,
      usdVolume: 265063811.5230488,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '409.1900000000000000',
      high: '416.6600000000000000',
      low: '407.0000000000000000',
      close: '412.1400000000000000',
      volume: '419236.8420100000000000',
      quoteVolume: 172799812.65625998,
      btcVolume: 13272.573133752301,
      usdVolume: 172799812.65625998,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '412.1400000000000000',
      high: '417.4000000000000000',
      low: '402.5000000000000000',
      close: '405.8600000000000000',
      volume: '415431.1155000000000000',
      quoteVolume: 169990300.58460298,
      btcVolume: 13026.150189403857,
      usdVolume: 169990300.58460298,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '405.8500000000000000',
      high: '411.1100000000000000',
      low: '382.3000000000000000',
      close: '392.5200000000000000',
      volume: '894757.9162500000000000',
      quoteVolume: 356060346.50108105,
      btcVolume: 27339.184264126954,
      usdVolume: 356060346.50108105,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '392.5200000000000000',
      high: '410.2500000000000000',
      low: '389.9000000000000000',
      close: '403.4500000000000000',
      volume: '753617.4010100000000000',
      quoteVolume: 302269548.1476697,
      btcVolume: 22546.706790982997,
      usdVolume: 302269548.1476697,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '403.4100000000000000',
      high: '409.2000000000000000',
      low: '380.5200000000000000',
      close: '388.2300000000000000',
      volume: '816953.5301800000000000',
      quoteVolume: 320590416.22950745,
      btcVolume: 23922.336631484806,
      usdVolume: 320590416.22950745,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '388.2200000000000000',
      high: '393.7200000000000000',
      low: '380.5200000000000000',
      close: '387.1300000000000000',
      volume: '573096.0141600000000000',
      quoteVolume: 222669504.8753169,
      btcVolume: 16722.98084116485,
      usdVolume: 222669504.8753169,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '387.1200000000000000',
      high: '391.9700000000000000',
      low: '373.2900000000000000',
      close: '382.4900000000000000',
      volume: '771750.7474600000000000',
      quoteVolume: 294342545.9808737,
      btcVolume: 22024.58912338817,
      usdVolume: 294342545.9808737,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '382.5900000000000000',
      high: '394.8800000000000000',
      low: '379.9000000000000000',
      close: '386.4600000000000000',
      volume: '466870.1136200000000000',
      quoteVolume: 180911022.7121731,
      btcVolume: 13143.343560424059,
      usdVolume: 180911022.7121731,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '386.4400000000000000',
      high: '398.2900000000000000',
      low: '384.6100000000000000',
      close: '396.6700000000000000',
      volume: '438952.9556500000000000',
      quoteVolume: 171238317.7003242,
      btcVolume: 12438.544674604154,
      usdVolume: 171238317.7003242,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '396.6800000000000000',
      high: '404.5000000000000000',
      low: '377.9200000000000000',
      close: '383.0200000000000000',
      volume: '779968.5795700000000000',
      quoteVolume: 304223545.89203674,
      btcVolume: 22406.862744217426,
      usdVolume: 304223545.89203674,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '383.0100000000000000',
      high: '390.0000000000000000',
      low: '370.2300000000000000',
      close: '387.8800000000000000',
      volume: '752954.3711400000000000',
      quoteVolume: 286291773.9684873,
      btcVolume: 21076.429697023013,
      usdVolume: 286291773.9684873,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '387.8800000000000000',
      high: '408.3900000000000000',
      low: '376.4700000000000000',
      close: '402.4900000000000000',
      volume: '1157064.3507600000000000',
      quoteVolume: 454481208.4020135,
      btcVolume: 32586.363296352156,
      usdVolume: 454481208.4020135,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '402.5000000000000000',
      high: '420.4000000000000000',
      low: '396.1400000000000000',
      close: '416.6900000000000000',
      volume: '1475139.7734200000000000',
      quoteVolume: 601591250.4012383,
      btcVolume: 40712.080001064045,
      usdVolume: 601591250.4012383,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '416.7300000000000000',
      high: '458.2700000000000000',
      low: '414.7600000000000000',
      close: '455.9100000000000000',
      volume: '1679301.1392700000000000',
      quoteVolume: 734615875.017384,
      btcVolume: 47133.026973207314,
      usdVolume: 734615875.017384,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '455.9100000000000000',
      high: '468.2800000000000000',
      low: '424.1400000000000000',
      close: '435.2100000000000000',
      volume: '1653416.5961000000000000',
      quoteVolume: 743871175.7821237,
      btcVolume: 48717.33214579415,
      usdVolume: 743871175.7821237,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '435.2000000000000000',
      high: '460.1000000000000000',
      low: '431.9900000000000000',
      close: '454.3000000000000000',
      volume: '840722.7949000000000000',
      quoteVolume: 375595944.18961173,
      btcVolume: 24675.810368249833,
      usdVolume: 375595944.18961173,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '454.2900000000000000',
      high: '459.1000000000000000',
      low: '433.0900000000000000',
      close: '444.3200000000000000',
      volume: '1098601.4997800000000000',
      quoteVolume: 491653842.88129467,
      btcVolume: 32077.913407959655,
      usdVolume: 491653842.88129467,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '444.3200000000000000',
      high: '455.0000000000000000',
      low: '438.7000000000000000',
      close: '450.3400000000000000',
      volume: '866686.4285100000000000',
      quoteVolume: 388106243.05318296,
      btcVolume: 25360.781987038194,
      usdVolume: 388106243.05318296,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '450.3400000000000000',
      high: '476.2500000000000000',
      low: '449.2800000000000000',
      close: '463.0900000000000000',
      volume: '1205782.8426200000000000',
      quoteVolume: 559228791.8903673,
      btcVolume: 35902.59522880581,
      usdVolume: 559228791.8903673,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '463.0900000000000000',
      high: '470.0000000000000000',
      low: '451.2000000000000000',
      close: '462.3900000000000000',
      volume: '990776.6291200000000000',
      quoteVolume: 455971309.2168636,
      btcVolume: 28686.52115959985,
      usdVolume: 455971309.2168636,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '462.4800000000000000',
      high: '478.0100000000000000',
      low: '457.1200000000000000',
      close: '476.4300000000000000',
      volume: '951211.0953600000000000',
      quoteVolume: 444338362.5908921,
      btcVolume: 27326.06235354085,
      usdVolume: 444338362.5908921,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '476.4200000000000000',
      high: '477.4700000000000000',
      low: '452.0000000000000000',
      close: '460.8900000000000000',
      volume: '726969.1507100000000000',
      quoteVolume: 336364969.86737686,
      btcVolume: 21033.888011294177,
      usdVolume: 336364969.86737686,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '460.9000000000000000',
      high: '462.8900000000000000',
      low: '440.1900000000000000',
      close: '448.0800000000000000',
      volume: '665184.8098900000000000',
      quoteVolume: 301391078.6921614,
      btcVolume: 18876.13024282066,
      usdVolume: 301391078.6921614,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '448.0700000000000000',
      high: '466.0000000000000000',
      low: '445.4500000000000000',
      close: '460.5500000000000000',
      volume: '811687.1632900000000000',
      quoteVolume: 370720232.1125867,
      btcVolume: 22611.477082211626,
      usdVolume: 370720232.1125867,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '460.5100000000000000',
      high: '484.8800000000000000',
      low: '459.8000000000000000',
      close: '482.1500000000000000',
      volume: '1120652.5363800000000000',
      quoteVolume: 529585259.9541648,
      btcVolume: 30825.11456878513,
      usdVolume: 529585259.9541648,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '482.1100000000000000',
      high: '495.3100000000000000',
      low: '456.6000000000000000',
      close: '478.4100000000000000',
      volume: '1474767.0885100000000000',
      quoteVolume: 705288664.0718446,
      btcVolume: 39474.871305535446,
      usdVolume: 705288664.0718446,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '478.4300000000000000',
      high: '480.7500000000000000',
      low: '464.8600000000000000',
      close: '471.2500000000000000',
      volume: '813313.3043500000000000',
      quoteVolume: 385259724.0413454,
      btcVolume: 21647.693501398127,
      usdVolume: 385259724.0413454,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '471.2600000000000000',
      high: '514.4000000000000000',
      low: '470.6500000000000000',
      close: '510.1200000000000000',
      volume: '1504164.8389300000000000',
      quoteVolume: 750785717.8739455,
      btcVolume: 40955.1380609167,
      usdVolume: 750785717.8739455,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '510.1200000000000000',
      high: '553.3200000000000000',
      low: '502.8200000000000000',
      close: '552.3500000000000000',
      volume: '1548851.2906700000000000',
      quoteVolume: 815402228.5227968,
      btcVolume: 43572.12042857939,
      usdVolume: 815402228.5227968,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '552.4200000000000000',
      high: '580.8800000000000000',
      low: '511.2800000000000000',
      close: '560.0300000000000000',
      volume: '1850317.9358100000000000',
      quoteVolume: 1014064539.0881541,
      btcVolume: 55112.07754619641,
      usdVolume: 1014064539.0881541,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '560.0300000000000000',
      high: '610.8500000000000000',
      low: '550.1200000000000000',
      close: '608.5800000000000000',
      volume: '2059928.2932900000000000',
      quoteVolume: 1208598659.5999002,
      btcVolume: 65547.02278720128,
      usdVolume: 1208598659.5999002,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '608.5800000000000000',
      high: '623.0000000000000000',
      low: '585.0000000000000000',
      close: '604.9700000000000000',
      volume: '1606450.4919300000000000',
      quoteVolume: 976376501.3114522,
      btcVolume: 52075.479021025865,
      usdVolume: 976376501.3114522,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '604.9800000000000000',
      high: '606.0000000000000000',
      low: '553.8300000000000000',
      close: '569.5000000000000000',
      volume: '1215573.9318600000000000',
      quoteVolume: 714664659.3653877,
      btcVolume: 37634.657613884825,
      usdVolume: 714664659.3653877,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '569.3800000000000000',
      high: '577.1400000000000000',
      low: '481.0000000000000000',
      close: '519.8300000000000000',
      volume: '2683460.7417100000000000',
      quoteVolume: 1386030255.1680546,
      btcVolume: 79991.49178543665,
      usdVolume: 1386030255.1680546,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '519.8300000000000000',
      high: '530.6200000000000000',
      low: '493.7200000000000000',
      close: '518.6800000000000000',
      volume: '1088063.4322200000000000',
      quoteVolume: 558984876.9379048,
      btcVolume: 32921.56765547952,
      usdVolume: 558984876.9379048,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '518.5900000000000000',
      high: '547.9400000000000000',
      low: '506.5900000000000000',
      close: '537.5200000000000000',
      volume: '900508.6143100000000000',
      quoteVolume: 475169074.6903964,
      btcVolume: 27355.45626379676,
      usdVolume: 475169074.6903964,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '537.5000000000000000',
      high: '576.9000000000000000',
      low: '530.3200000000000000',
      close: '576.3000000000000000',
      volume: '855184.5340300000000000',
      quoteVolume: 471578566.1167156,
      btcVolume: 26185.011636034185,
      usdVolume: 471578566.1167156,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '576.3200000000000000',
      high: '616.8500000000000000',
      low: '570.0700000000000000',
      close: '616.6600000000000000',
      volume: '1501989.4540800000000000',
      quoteVolume: 895373784.8300341,
      btcVolume: 47102.56560570271,
      usdVolume: 895373784.8300341,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '616.6500000000000000',
      high: '635.7000000000000000',
      low: '560.0000000000000000',
      close: '585.2600000000000000',
      volume: '2127926.8705500000000000',
      quoteVolume: 1278451640.1510434,
      btcVolume: 66678.68357305365,
      usdVolume: 1278451640.1510434,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '585.2600000000000000',
      high: '604.9300000000000000',
      low: '575.0000000000000000',
      close: '596.8900000000000000',
      volume: '1069538.6561900000000000',
      quoteVolume: 632858653.2050295,
      btcVolume: 33458.77431583617,
      usdVolume: 632858653.2050295,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '596.8800000000000000',
      high: '623.4400000000000000',
      low: '585.8200000000000000',
      close: '615.8500000000000000',
      volume: '935687.9172300000000000',
      quoteVolume: 568024618.9410435,
      btcVolume: 29433.265740340947,
      usdVolume: 568024618.9410435,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '615.8400000000000000',
      high: '620.0000000000000000',
      low: '565.8200000000000000',
      close: '567.2400000000000000',
      volume: '1052452.7866600000000000',
      quoteVolume: 624543865.9008446,
      btcVolume: 32794.329183490656,
      usdVolume: 624543865.9008446,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '567.2400000000000000',
      high: '597.1100000000000000',
      low: '560.8800000000000000',
      close: '596.8000000000000000',
      volume: '701073.3926200000000000',
      quoteVolume: 410088998.40737647,
      btcVolume: 21637.275162148107,
      usdVolume: 410088998.40737647,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '596.8200000000000000',
      high: '607.5000000000000000',
      low: '583.0000000000000000',
      close: '601.5400000000000000',
      volume: '602496.9633900000000000',
      quoteVolume: 359236400.29498786,
      btcVolume: 18762.06787882863,
      usdVolume: 359236400.29498786,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '601.5900000000000000',
      high: '603.1500000000000000',
      low: '584.2200000000000000',
      close: '591.2800000000000000',
      volume: '498329.3758900000000000',
      quoteVolume: 295598087.0279998,
      btcVolume: 15413.761647877274,
      usdVolume: 295598087.0279998,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '591.2700000000000000',
      high: '595.0000000000000000',
      low: '549.2200000000000000',
      close: '554.9400000000000000',
      volume: '804443.0071700000000000',
      quoteVolume: 461781203.16172385,
      btcVolume: 24513.068401678625,
      usdVolume: 461781203.16172385,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '554.9200000000000000',
      high: '578.0800000000000000',
      low: '530.4300000000000000',
      close: '573.0900000000000000',
      volume: '997191.6484000000000000',
      quoteVolume: 555141876.6276906,
      btcVolume: 30469.950793735137,
      usdVolume: 555141876.6276906,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '573.2000000000000000',
      high: '575.7000000000000000',
      low: '548.1100000000000000',
      close: '558.9900000000000000',
      volume: '607169.0047400000000000',
      quoteVolume: 340648675.9975368,
      btcVolume: 18641.974867746296,
      usdVolume: 340648675.9975368,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '558.9900000000000000',
      high: '560.8000000000000000',
      low: '535.5000000000000000',
      close: '544.4300000000000000',
      volume: '747763.1198900000000000',
      quoteVolume: 408047285.092406,
      btcVolume: 22758.283163314973,
      usdVolume: 407979347.94980955,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '544.4600000000000000',
      high: '574.0000000000000000',
      low: '543.1000000000000000',
      close: '568.0400000000000000',
      volume: '539225.5604100000000000',
      quoteVolume: 301525321.23763144,
      btcVolume: 16307.87218266341,
      usdVolume: 301525321.23763144,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '568.0900000000000000',
      high: '595.0000000000000000',
      low: '563.2200000000000000',
      close: '590.7000000000000000',
      volume: '657794.4615800000000000',
      quoteVolume: 383072560.34673756,
      btcVolume: 19983.985401772963,
      usdVolume: 383072560.34673756,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '590.8100000000000000',
      high: '591.4800000000000000',
      low: '575.6000000000000000',
      close: '586.2900000000000000',
      volume: '492918.9449700000000000',
      quoteVolume: 288056061.22263926,
      btcVolume: 15035.10093618048,
      usdVolume: 288056061.22263926,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '586.2900000000000000',
      high: '597.2000000000000000',
      low: '578.5800000000000000',
      close: '588.6400000000000000',
      volume: '551569.0579400000000000',
      quoteVolume: 324168681.67163837,
      btcVolume: 16753.55977616931,
      usdVolume: 324168681.67163837,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '588.7800000000000000',
      high: '640.0000000000000000',
      low: '580.7100000000000000',
      close: '637.1800000000000000',
      volume: '1144619.8239400000000000',
      quoteVolume: 700180315.3934683,
      btcVolume: 34363.64123742975,
      usdVolume: 700180315.3934683,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '637.1800000000000000',
      high: '676.2500000000000000',
      low: '625.7600000000000000',
      close: '642.7000000000000000',
      volume: '2006130.0197500000000000',
      quoteVolume: 1307445033.1218147,
      btcVolume: 57721.48977556455,
      usdVolume: 1307445033.1218147,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '642.7100000000000000',
      high: '664.6100000000000000',
      low: '629.4000000000000000',
      close: '653.8500000000000000',
      volume: '1000012.9801800000000000',
      quoteVolume: 646217791.229689,
      btcVolume: 28267.30155209629,
      usdVolume: 646217791.229689,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '653.7600000000000000',
      high: '669.9500000000000000',
      low: '645.4200000000000000',
      close: '658.0300000000000000',
      volume: '858803.8845900000000000',
      quoteVolume: 564029933.2588553,
      btcVolume: 24101.66087667955,
      usdVolume: 564029933.2588553,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '658.0500000000000000',
      high: '661.3900000000000000',
      low: '621.0100000000000000',
      close: '638.1800000000000000',
      volume: '823958.2284300000000000',
      quoteVolume: 533109285.6519513,
      btcVolume: 22604.403561175943,
      usdVolume: 533109285.6519513,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '638.1000000000000000',
      high: '648.0000000000000000',
      low: '595.9000000000000000',
      close: '607.5800000000000000',
      volume: '834470.3823100000000000',
      quoteVolume: 518855748.82504433,
      btcVolume: 22402.25118531381,
      usdVolume: 518855748.82504433,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '607.6800000000000000',
      high: '637.7300000000000000',
      low: '588.0000000000000000',
      close: '636.4500000000000000',
      volume: '918851.2565400000000000',
      quoteVolume: 563872004.6334975,
      btcVolume: 24418.03638657798,
      usdVolume: 563872004.6334975,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '636.4100000000000000',
      high: '638.4400000000000000',
      low: '551.2800000000000000',
      close: '585.5600000000000000',
      volume: '1208807.3651800000000000',
      quoteVolume: 733443630.3656094,
      btcVolume: 31260.2759405341,
      usdVolume: 733443630.3656094,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '585.4800000000000000',
      high: '615.0200000000000000',
      low: '565.4300000000000000',
      close: '612.3100000000000000',
      volume: '886335.8633300000000000',
      quoteVolume: 519843556.98166037,
      btcVolume: 22398.590221377046,
      usdVolume: 519843556.98166037,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '612.3100000000000000',
      high: '634.2400000000000000',
      low: '604.2800000000000000',
      close: '626.8200000000000000',
      volume: '797946.9485900000000000',
      quoteVolume: 494050300.1567907,
      btcVolume: 20522.31724411632,
      usdVolume: 494050300.1567907,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '626.7800000000000000',
      high: '652.9100000000000000',
      low: '615.2600000000000000',
      close: '637.4400000000000000',
      volume: '958585.5064700000000000',
      quoteVolume: 607664782.0109019,
      btcVolume: 23891.58404704324,
      usdVolume: 607664782.0109019,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '637.4400000000000000',
      high: '717.1300000000000000',
      low: '625.0000000000000000',
      close: '685.1100000000000000',
      volume: '1859968.1502900000000000',
      quoteVolume: 1247547956.8239481,
      btcVolume: 46008.51410433634,
      usdVolume: 1247547956.8239481,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '685.1000000000000000',
      high: '748.0900000000000000',
      low: '681.0400000000000000',
      close: '730.4100000000000000',
      volume: '1627123.9175900000000000',
      quoteVolume: 1180415994.5896938,
      btcVolume: 43771.67271543014,
      usdVolume: 1180415994.5896938,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '730.4000000000000000',
      high: '740.7800000000000000',
      low: '689.2000000000000000',
      close: '732.0000000000000000',
      volume: '1106876.3585500000000000',
      quoteVolume: 794643540.4901432,
      btcVolume: 29803.913314660324,
      usdVolume: 794643540.4901432,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '732.0000000000000000',
      high: '758.7400000000000000',
      low: '716.6200000000000000',
      close: '752.1700000000000000',
      volume: '1008573.9629400000000000',
      quoteVolume: 743546576.743025,
      btcVolume: 26409.000752216456,
      usdVolume: 743546576.743025,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '752.1700000000000000',
      high: '755.7400000000000000',
      low: '722.0000000000000000',
      close: '736.4200000000000000',
      volume: '863375.5798100000000000',
      quoteVolume: 638503822.3940647,
      btcVolume: 22178.91912646232,
      usdVolume: 638503822.3940647,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '736.4200000000000000',
      high: '749.0000000000000000',
      low: '714.2900000000000000',
      close: '728.9100000000000000',
      volume: '674648.6773000000000000',
      quoteVolume: 495736382.4405749,
      btcVolume: 16984.616127141537,
      usdVolume: 495736382.4405749,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '728.9100000000000000',
      high: '787.6900000000000000',
      low: '714.9100000000000000',
      close: '774.5600000000000000',
      volume: '1352618.5766800000000000',
      quoteVolume: 1024472182.6120937,
      btcVolume: 32889.04989161244,
      usdVolume: 1024472182.6120937,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '774.4400000000000000',
      high: '1011.0700000000000000',
      low: '768.7100000000000000',
      close: '978.2800000000000000',
      volume: '2813603.8861500000000000',
      quoteVolume: 2522411859.731612,
      btcVolume: 75351.3954511743,
      usdVolume: 2522411859.731612,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '978.3300000000000000',
      high: '1162.9700000000000000',
      low: '890.0000000000000000',
      close: '1041.4300000000000000',
      volume: '4245009.5910400000000000',
      quoteVolume: 4303632891.8297615,
      btcVolume: 135522.0321379406,
      usdVolume: 4303632891.8297615,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '1041.4500000000000000',
      high: '1134.6000000000000000',
      low: '974.4500000000000000',
      close: '1099.5600000000000000',
      volume: '2706995.6752500000000000',
      quoteVolume: 2857050280.008042,
      btcVolume: 88801.0479132276,
      usdVolume: 2857050280.008042,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '1100.1600000000000000',
      high: '1213.0000000000000000',
      low: '1057.2600000000000000',
      close: '1208.4200000000000000',
      volume: '2390134.2561700000000000',
      quoteVolume: 2725259321.0546975,
      btcVolume: 78230.68111796751,
      usdVolume: 2725259321.0546975,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '1208.7500000000000000',
      high: '1289.0000000000000000',
      low: '1131.0000000000000000',
      close: '1224.8700000000000000',
      volume: '1972917.8883400000000000',
      quoteVolume: 2406213991.6994786,
      btcVolume: 63090.38541215102,
      usdVolume: 2406213991.6994786,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '1224.8600000000000000',
      high: '1273.7500000000000000',
      low: '1065.0000000000000000',
      close: '1216.9300000000000000',
      volume: '2130161.3642600000000000',
      quoteVolume: 2542192047.8181143,
      btcVolume: 64132.46310834457,
      usdVolume: 2542192047.8181143,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '1216.7200000000000000',
      high: '1304.3700000000000000',
      low: '1171.3600000000000000',
      close: '1276.0000000000000000',
      volume: '1448871.1151400000000000',
      quoteVolume: 1780137376.416452,
      btcVolume: 44126.55053631966,
      usdVolume: 1780137376.416452,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '1276.0000000000000000',
      high: '1348.3300000000000000',
      low: '1170.0000000000000000',
      close: '1254.2500000000000000',
      volume: '1951179.3500300000000000',
      quoteVolume: 2517791852.4078064,
      btcVolume: 64117.4902690094,
      usdVolume: 2517791852.4078064,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '1254.2400000000000000',
      high: '1260.0000000000000000',
      low: '915.0000000000000000',
      close: '1087.0100000000000000',
      volume: '4054496.9340000000000000',
      quoteVolume: 4277939199.1682744,
      btcVolume: 126094.41321785371,
      usdVolume: 4277939199.1682744,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '1087.0000000000000000',
      high: '1150.0200000000000000',
      low: '1006.3300000000000000',
      close: '1050.1600000000000000',
      volume: '2173756.2488300000000000',
      quoteVolume: 2351659986.680197,
      btcVolume: 67607.46412545761,
      usdVolume: 2351659986.680197,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '1050.3600000000000000',
      high: '1137.6500000000000000',
      low: '987.4800000000000000',
      close: '1129.8900000000000000',
      volume: '1546028.4733400000000000',
      quoteVolume: 1645548180.1396663,
      btcVolume: 47601.494850955794,
      usdVolume: 1645548180.1396663,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '1129.7900000000000000',
      high: '1247.5500000000000000',
      low: '1086.7000000000000000',
      close: '1231.6200000000000000',
      volume: '1555737.3855600000000000',
      quoteVolume: 1825860914.1737947,
      btcVolume: 47277.3001514479,
      usdVolume: 1825860914.1737947,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '1231.8900000000000000',
      high: '1256.9800000000000000',
      low: '1069.4200000000000000',
      close: '1168.5300000000000000',
      volume: '1854933.6970800000000000',
      quoteVolume: 2199247860.325725,
      btcVolume: 59240.27896315071,
      usdVolume: 2199247860.325725,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '1168.4800000000000000',
      high: '1292.6600000000000000',
      low: '1151.7700000000000000',
      close: '1227.8300000000000000',
      volume: '1515434.5202100000000000',
      quoteVolume: 1854150543.260001,
      btcVolume: 50229.12509310558,
      usdVolume: 1854150543.260001,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '1227.8300000000000000',
      high: '1269.0200000000000000',
      low: '1165.0000000000000000',
      close: '1232.4500000000000000',
      volume: '1317285.0057900000000000',
      quoteVolume: 1606424364.4970233,
      btcVolume: 45243.78505901359,
      usdVolume: 1606424364.4970233,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '1232.5000000000000000',
      high: '1260.7500000000000000',
      low: '1182.5800000000000000',
      close: '1258.4500000000000000',
      volume: '998004.1263100000000000',
      quoteVolume: 1223572330.470765,
      btcVolume: 33926.362340998414,
      usdVolume: 1223572330.470765,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '1258.5200000000000000',
      high: '1438.3000000000000000',
      low: '1251.4900000000000000',
      close: '1365.3600000000000000',
      volume: '2277189.7942800000000000',
      quoteVolume: 3118645648.601239,
      btcVolume: 84576.84836448962,
      usdVolume: 3118645648.601239,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '1365.3600000000000000',
      high: '1407.9300000000000000',
      low: '1235.4200000000000000',
      close: '1376.9900000000000000',
      volume: '2217969.4846900000000000',
      quoteVolume: 2930015788.0453196,
      btcVolume: 83805.54654902022,
      usdVolume: 2930015788.0453196,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '1376.7200000000000000',
      high: '1390.0000000000000000',
      low: '1086.0000000000000000',
      close: '1110.9600000000000000',
      volume: '2310208.5729800000000000',
      quoteVolume: 2857288905.5771556,
      btcVolume: 87560.1065502035,
      usdVolume: 2857288905.5771556,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '1111.9800000000000000',
      high: '1273.7900000000000000',
      low: '1042.3100000000000000',
      close: '1232.6200000000000000',
      volume: '2005642.5528900000000000',
      quoteVolume: 2341609368.9179225,
      btcVolume: 74708.67997123068,
      usdVolume: 2341609368.9179225,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '1232.6100000000000000',
      high: '1272.4400000000000000',
      low: '1195.9000000000000000',
      close: '1233.6000000000000000',
      volume: '915290.0468900000000000',
      quoteVolume: 1134112575.401647,
      btcVolume: 35109.53478031294,
      usdVolume: 1134112575.401647,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '1233.4600000000000000',
      high: '1398.8300000000000000',
      low: '1220.0000000000000000',
      close: '1392.5100000000000000',
      volume: '1510879.5381700000000000',
      quoteVolume: 1996765627.5405061,
      btcVolume: 61983.71959418937,
      usdVolume: 1996765627.5405061,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '1392.1900000000000000',
      high: '1475.0000000000000000',
      low: '1311.0200000000000000',
      close: '1347.8800000000000000',
      volume: '1715392.6717800000000000',
      quoteVolume: 2419347040.465905,
      btcVolume: 72644.26574669621,
      usdVolume: 2419347040.465905,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '1289.8900000000000000',
      high: '1374.2200000000000000',
      low: '1288.0000000000000000',
      close: '1365.9700000000000000',
      volume: '474907.7533300000000000',
      quoteVolume: 634395015.3148867,
      btcVolume: 19710.204623958827,
      usdVolume: 634395015.3148867,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '1366.5400000000000000',
      high: '1373.9000000000000000',
      low: '1207.0000000000000000',
      close: '1239.0300000000000000',
      volume: '1913340.1664600000000000',
      quoteVolume: 2432623351.210675,
      btcVolume: 78753.05627142359,
      usdVolume: 2432623351.210675,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '1238.9300000000000000',
      high: '1360.0000000000000000',
      low: '1217.2300000000000000',
      close: '1329.6900000000000000',
      volume: '1285582.1498600000000000',
      quoteVolume: 1685996087.100964,
      btcVolume: 53145.032890713526,
      usdVolume: 1685996087.100964,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '1329.4400000000000000',
      high: '1436.9000000000000000',
      low: '1285.3500000000000000',
      close: '1379.5000000000000000',
      volume: '2111692.2313000000000000',
      quoteVolume: 2883767792.77202,
      btcVolume: 81514.055974949,
      usdVolume: 2883767792.77202,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '1379.5600000000000000',
      high: '1406.0000000000000000',
      low: '1326.4100000000000000',
      close: '1378.0500000000000000',
      volume: '1017334.1650900000000000',
      quoteVolume: 1383293203.399458,
      btcVolume: 40669.810913327645,
      usdVolume: 1383293203.399458,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '1378.0400000000000000',
      high: '1380.7400000000000000',
      low: '1281.1200000000000000',
      close: '1312.5500000000000000',
      volume: '913951.9568300000000000',
      quoteVolume: 1217085727.1929095,
      btcVolume: 36537.581286708024,
      usdVolume: 1217085727.1929095,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '1312.4500000000000000',
      high: '1377.9900000000000000',
      low: '1269.9900000000000000',
      close: '1374.1700000000000000',
      volume: '1080698.2129100000000000',
      quoteVolume: 1429023779.126755,
      btcVolume: 42479.33622274833,
      usdVolume: 1429023779.126755,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '1374.0900000000000000',
      high: '1547.9000000000000000',
      low: '1360.2800000000000000',
      close: '1511.8700000000000000',
      volume: '1943744.1966000000000000',
      quoteVolume: 2820899589.0203815,
      btcVolume: 81013.11891040324,
      usdVolume: 2820899589.0203815,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '1511.9300000000000000',
      high: '1669.3500000000000000',
      low: '1508.3900000000000000',
      close: '1664.4900000000000000',
      volume: '1535869.8880100000000000',
      quoteVolume: 2418887041.5875654,
      btcVolume: 66156.27367384409,
      usdVolume: 2418887041.5875654,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '1664.6700000000000000',
      high: '1695.2000000000000000',
      low: '1554.4400000000000000',
      close: '1595.6700000000000000',
      volume: '1646502.0866300000000000',
      quoteVolume: 2693099202.271006,
      btcVolume: 72046.17314645574,
      usdVolume: 2693099202.271006,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '1595.5300000000000000',
      high: '1760.4900000000000000',
      low: '1591.0000000000000000',
      close: '1719.1900000000000000',
      volume: '1193485.8324200000000000',
      quoteVolume: 2010622894.1443644,
      btcVolume: 53367.070155870046,
      usdVolume: 2010622894.1443644,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '1718.9500000000000000',
      high: '1743.6700000000000000',
      low: '1642.9400000000000000',
      close: '1676.3500000000000000',
      volume: '1149726.0674300000000000',
      quoteVolume: 1939917120.7056394,
      btcVolume: 48912.4046267318,
      usdVolume: 1939917120.7056394,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '1676.3500000000000000',
      high: '1693.0000000000000000',
      low: '1490.4600000000000000',
      close: '1611.6200000000000000',
      volume: '1281734.8681400000000000',
      quoteVolume: 2044371589.0232632,
      btcVolume: 53081.3239508303,
      usdVolume: 2044371589.0232632,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '1611.4600000000000000',
      high: '1775.5700000000000000',
      low: '1563.8900000000000000',
      close: '1750.1500000000000000',
      volume: '1339777.0953600000000000',
      quoteVolume: 2255426926.0200105,
      btcVolume: 53848.27559053579,
      usdVolume: 2255426926.0200105,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '1749.6800000000000000',
      high: '1821.8800000000000000',
      low: '1708.4900000000000000',
      close: '1769.1300000000000000',
      volume: '1185071.8959300000000000',
      quoteVolume: 2080818891.6989098,
      btcVolume: 44595.047468861056,
      usdVolume: 2080818891.6989098,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '1769.1400000000000000',
      high: '1837.8600000000000000',
      low: '1680.0000000000000000',
      close: '1740.7800000000000000',
      volume: '1219932.2547400000000000',
      quoteVolume: 2149076144.4077,
      btcVolume: 47044.18686396017,
      usdVolume: 2148911012.185441,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '1740.7600000000000000',
      high: '1819.0000000000000000',
      low: '0.0000163270000000',
      close: '1785.6200000000000000',
      volume: '734621.0468700000000000',
      quoteVolume: 1300810400.6745741,
      btcVolume: 27978.80513237656,
      usdVolume: 1300810421.239668,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '1785.8300000000000000',
      high: '1864.8800000000000000',
      low: '1739.8800000000000000',
      close: '1840.2600000000000000',
      volume: '909354.4636700000000000',
      quoteVolume: 1634996984.4396915,
      btcVolume: 34411.479778793124,
      usdVolume: 1634776311.9280682,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '1840.2400000000000000',
      high: '1871.7200000000000000',
      low: '1765.0000000000000000',
      close: '1814.9600000000000000',
      volume: '767588.7814400000000000',
      quoteVolume: 1395593560.9624188,
      btcVolume: 29584.45599424286,
      usdVolume: 1395437080.521789,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '1815.2500000000000000',
      high: '1850.0000000000000000',
      low: '1784.1100000000000000',
      close: '1800.4200000000000000',
      volume: '653541.2701500000000000',
      quoteVolume: 1190593797.9381444,
      btcVolume: 24535.71966972843,
      usdVolume: 1190593797.9381444,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '1800.0300000000000000',
      high: '1835.7200000000000000',
      low: '1655.6700000000000000',
      close: '1779.2500000000000000',
      volume: '1049314.2533700000000000',
      quoteVolume: 1852907004.6686351,
      btcVolume: 38765.91600274143,
      usdVolume: 1846625373.4324832,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '1779.3300000000000000',
      high: '1827.2800000000000000',
      low: '1724.0000000000000000',
      close: '1781.9900000000000000',
      volume: '816519.6687500000000000',
      quoteVolume: 1454153395.7932453,
      btcVolume: 29364.208274199304,
      usdVolume: 1434518186.7829216,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '1781.9900000000000000',
      high: '1855.0000000000000000',
      low: '1733.0100000000000000',
      close: '1849.8800000000000000',
      volume: '784619.1244400000000000',
      quoteVolume: 1417932396.1485229,
      btcVolume: 27781.649794336336,
      usdVolume: 1417932396.1485229,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '1849.7000000000000000',
      high: '1950.5000000000000000',
      low: '1849.6900000000000000',
      close: '1939.6100000000000000',
      volume: '937227.1019100000000000',
      quoteVolume: 1787383532.1023593,
      btcVolume: 34457.66137137417,
      usdVolume: 1787383532.1023593,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '1938.9100000000000000',
      high: '1972.8200000000000000',
      low: '1891.0000000000000000',
      close: '1955.0000000000000000',
      volume: '793166.6203700000000000',
      quoteVolume: 1533528111.7198534,
      btcVolume: 28954.450074244185,
      usdVolume: 1533528111.7198534,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '1955.4600000000000000',
      high: '2042.3400000000000000',
      low: '1817.4000000000000000',
      close: '1913.0000000000000000',
      volume: '1231380.2653200000000000',
      quoteVolume: 2438742761.044716,
      btcVolume: 43461.22491246883,
      usdVolume: 2438737210.8991766,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '1912.9000000000000000',
      high: '1976.3100000000000000',
      low: '1883.0100000000000000',
      close: '1933.5300000000000000',
      volume: '756568.1974300000000000',
      quoteVolume: 1467970937.285071,
      btcVolume: 25788.46682354838,
      usdVolume: 1467970937.285071,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '1933.5400000000000000',
      high: '1936.2900000000000000',
      low: '1555.0000000000000000',
      close: '1777.5800000000000000',
      volume: '1544855.9559000000000000',
      quoteVolume: 2744964206.143231,
      btcVolume: 48368.6255478767,
      usdVolume: 2601427551.846227,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '1777.4200000000000000',
      high: '1781.9900000000000000',
      low: '1371.6000000000000000',
      close: '1577.8900000000000000',
      volume: '2349675.3107900000000000',
      quoteVolume: 3640290942.4959636,
      btcVolume: 74416.81196595635,
      usdVolume: 3622396228.338403,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '1577.7900000000000000',
      high: '1712.7000000000000000',
      low: '1500.2000000000000000',
      close: '1623.3900000000000000',
      volume: '998796.1130000000000000',
      quoteVolume: 1631162134.0796974,
      btcVolume: 32698.532812710335,
      usdVolume: 1631047215.6908228,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '1623.1600000000000000',
      high: '1671.5900000000000000',
      low: '1459.1600000000000000',
      close: '1481.7500000000000000',
      volume: '1032670.7940900000000000',
      quoteVolume: 1650582276.8718207,
      btcVolume: 33117.338134786136,
      usdVolume: 1650582276.8718207,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '1482.0800000000000000',
      high: '1562.2500000000000000',
      low: '1400.0000000000000000',
      close: '1444.5900000000000000',
      volume: '1355973.2937800000000000',
      quoteVolume: 2004016547.1112273,
      btcVolume: 43035.34655306483,
      usdVolume: 2003245444.1310456,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '1444.5600000000000000',
      high: '1529.0000000000000000',
      low: '1425.0000000000000000',
      close: '1458.1300000000000000',
      volume: '927423.0475000000000000',
      quoteVolume: 1375946816.3325465,
      btcVolume: 29233.007119435944,
      usdVolume: 1375946816.3325465,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '1458.2700000000000000',
      high: '1468.7500000000000000',
      low: '1293.1800000000000000',
      close: '1419.1800000000000000',
      volume: '1394039.1589300000000000',
      quoteVolume: 1906652845.303296,
      btcVolume: 42805.29027654558,
      usdVolume: 1906652845.303296,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '1418.6700000000000000',
      high: '1572.0000000000000000',
      low: '1409.9100000000000000',
      close: '1569.8600000000000000',
      volume: '1071988.8959200000000000',
      quoteVolume: 1609708358.4012525,
      btcVolume: 33760.292992242015,
      usdVolume: 1609708358.4012525,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '1570.0000000000000000',
      high: '1602.9000000000000000',
      low: '1455.5200000000000000',
      close: '1486.7800000000000000',
      volume: '1061142.3874300000000000',
      quoteVolume: 1627312282.6235447,
      btcVolume: 33587.44590463653,
      usdVolume: 1627312282.6235447,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '1486.7900000000000000',
      high: '1656.9400000000000000',
      low: '1475.4700000000000000',
      close: '1567.6900000000000000',
      volume: '1054045.4787500000000000',
      quoteVolume: 1674981197.4400375,
      btcVolume: 33038.92359702907,
      usdVolume: 1674981197.4400375,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '1567.6800000000000000',
      high: '1624.8700000000000000',
      low: '1506.3600000000000000',
      close: '1539.2300000000000000',
      volume: '946365.6708800000000000',
      quoteVolume: 1476463517.9864302,
      btcVolume: 29907.939970665844,
      usdVolume: 1476144246.8024573,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '1539.2200000000000000',
      high: '1548.9600000000000000',
      low: '1441.6600000000000000',
      close: '1528.3100000000000000',
      volume: '910786.2897600000000000',
      quoteVolume: 1355798089.2726953,
      btcVolume: 28468.307956482684,
      usdVolume: 1355798089.2726953,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '1528.0800000000000000',
      high: '1671.5800000000000000',
      low: '1513.0300000000000000',
      close: '1650.3500000000000000',
      volume: '990313.5575500000000000',
      quoteVolume: 1575968246.2858746,
      btcVolume: 32484.06849446943,
      usdVolume: 1569880633.498245,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '1650.6800000000000000',
      high: '1734.1600000000000000',
      low: '1630.3500000000000000',
      close: '1726.1600000000000000',
      volume: '848470.7813600000000000',
      quoteVolume: 1414988218.19356,
      btcVolume: 28184.765635712247,
      usdVolume: 1414988218.19356,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '1726.4600000000000000',
      high: '1843.5900000000000000',
      low: '1664.9600000000000000',
      close: '1833.1800000000000000',
      volume: '1134793.9935500000000000',
      quoteVolume: 1968559430.9578898,
      btcVolume: 38787.24460770227,
      usdVolume: 1968559430.9578898,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '1833.2200000000000000',
      high: '1870.7700000000000000',
      low: '1798.2000000000000000',
      close: '1870.5100000000000000',
      volume: '813029.3448600000000000',
      quoteVolume: 1489269737.9822018,
      btcVolume: 27646.61354923279,
      usdVolume: 1489269737.9822018,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '1870.5000000000000000',
      high: '1877.6900000000000000',
      low: '1757.0000000000000000',
      close: '1795.1200000000000000',
      volume: '901824.9905900000000000',
      quoteVolume: 1641659203.449609,
      btcVolume: 29670.607882495333,
      usdVolume: 1641510306.9788375,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '1795.2800000000000000',
      high: '1846.7000000000000000',
      low: '1725.0000000000000000',
      close: '1825.7800000000000000',
      volume: '909011.3136100000000000',
      quoteVolume: 1627027140.3418167,
      btcVolume: 28930.654359520144,
      usdVolume: 1627027140.3418167,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '1825.7800000000000000',
      high: '1841.8800000000000000',
      low: '1719.7200000000000000',
      close: '1771.4800000000000000',
      volume: '840060.3990300000000000',
      quoteVolume: 1490608510.5170076,
      btcVolume: 19454.286038851555,
      usdVolume: 1105911678.621963,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '1759.7200000000000000',
      high: '1944.3000000000000000',
      low: '1727.7500000000000000',
      close: '1922.3500000000000000',
      volume: '921478.4547800000000000',
      quoteVolume: 1710098953.5524776,
      btcVolume: 28839.313150162354,
      usdVolume: 1710098953.5524776,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '1922.3500000000000000',
      high: '1933.1000000000000000',
      low: '1836.0000000000000000',
      close: '1848.1000000000000000',
      volume: '637140.4717400000000000',
      quoteVolume: 1198818913.9901521,
      btcVolume: 19816.886952228757,
      usdVolume: 1197320013.2192478,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '1848.1000000000000000',
      high: '1891.7600000000000000',
      low: '1730.0000000000000000',
      close: '1793.4800000000000000',
      volume: '1025326.7965800000000000',
      quoteVolume: 1842608872.7630134,
      btcVolume: 32173.17842836311,
      usdVolume: 1842608872.7630134,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '1793.9900000000000000',
      high: '1819.5000000000000000',
      low: '1711.6800000000000000',
      close: '1805.1200000000000000',
      volume: '745047.8840800000000000',
      quoteVolume: 1324386049.5584662,
      btcVolume: 23990.615019311717,
      usdVolume: 1323585091.7132356,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '1805.0100000000000000',
      high: '1838.8800000000000000',
      low: '1741.1800000000000000',
      close: '1823.5800000000000000',
      volume: '654844.3962200000000000',
      quoteVolume: 1171393579.333251,
      btcVolume: 20836.313439670008,
      usdVolume: 1171393579.333251,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '1823.5800000000000000',
      high: '1850.0000000000000000',
      low: '1759.0400000000000000',
      close: '1776.0800000000000000',
      volume: '603721.6105600000000000',
      quoteVolume: 1092346826.5537026,
      btcVolume: 18665.018047687696,
      usdVolume: 1092344736.4815433,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '1775.9200000000000000',
      high: '1840.6100000000000000',
      low: '1733.4700000000000000',
      close: '1809.1000000000000000',
      volume: '485394.8323500000000000',
      quoteVolume: 875146682.91855,
      btcVolume: 14962.1323122463,
      usdVolume: 871822690.956532,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '1809.0100000000000000',
      high: '1868.8300000000000000',
      low: '1800.8600000000000000',
      close: '1806.1000000000000000',
      volume: '423872.9248500000000000',
      quoteVolume: 777603559.92763,
      btcVolume: 13225.478131358275,
      usdVolume: 777585802.0887206,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '1806.5700000000000000',
      high: '1817.1600000000000000',
      low: '1747.0000000000000000',
      close: '1782.9800000000000000',
      volume: '465300.7587400000000000',
      quoteVolume: 829784481.8129778,
      btcVolume: 14483.824726332654,
      usdVolume: 828756040.3627473,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '1783.2300000000000000',
      high: '1807.5100000000000000',
      low: '1655.1500000000000000',
      close: '1680.9700000000000000',
      volume: '582614.8017100000000000',
      quoteVolume: 1019579063.1709024,
      btcVolume: 18102.374296305938,
      usdVolume: 1019512873.3647406,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '1681.1700000000000000',
      high: '1721.1700000000000000',
      low: '1650.0000000000000000',
      close: '1668.0800000000000000',
      volume: '574333.9664000000000000',
      quoteVolume: 970709518.4563361,
      btcVolume: 12089.460579743516,
      usdVolume: 658329851.7034984,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '1668.2000000000000000',
      high: '1740.8600000000000000',
      low: '1536.9200000000000000',
      close: '1581.8400000000000000',
      volume: '712606.6818800000000000',
      quoteVolume: 1184354983.115622,
      btcVolume: 21604.55412650087,
      usdVolume: 1184354983.115622,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '1581.8800000000000000',
      high: '1622.0000000000000000',
      low: '1549.4000000000000000',
      close: '1585.7400000000000000',
      volume: '637200.0501600000000000',
      quoteVolume: 1012804696.3799217,
      btcVolume: 17272.44309048303,
      usdVolume: 897465027.6504521,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '1585.8300000000000000',
      high: '1699.9300000000000000',
      low: '1585.7900000000000000',
      close: '1698.8400000000000000',
      volume: '442973.6271000000000000',
      quoteVolume: 727250279.6639122,
      btcVolume: 13128.883505516924,
      usdVolume: 700660186.6217037,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '1698.8400000000000000',
      high: '1732.6700000000000000',
      low: '1666.4100000000000000',
      close: '1712.6300000000000000',
      volume: '443845.4995700000000000',
      quoteVolume: 753555521.6446587,
      btcVolume: 13655.180403119155,
      usdVolume: 753442291.0866222,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '1712.5200000000000000',
      high: '1725.6600000000000000',
      low: '1661.4000000000000000',
      close: '1687.0100000000000000',
      volume: '348895.5131400000000000',
      quoteVolume: 592836644.5993483,
      btcVolume: 10623.566952815665,
      usdVolume: 592744065.8884122,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '1687.1600000000000000',
      high: '1841.0000000000000000',
      low: '1677.8500000000000000',
      close: '1816.7400000000000000',
      volume: '645153.9473800000000000',
      quoteVolume: 1143708814.718205,
      btcVolume: 19952.416250138947,
      usdVolume: 1143593696.951361,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '1816.7600000000000000',
      high: '1859.1300000000000000',
      low: '1786.3000000000000000',
      close: '1840.4600000000000000',
      volume: '568842.0052600000000000',
      quoteVolume: 1039262033.3867666,
      btcVolume: 17794.857969149907,
      usdVolume: 1039257506.3019704,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '1840.4100000000000000',
      high: '1947.2900000000000000',
      low: '1768.1500000000000000',
      close: '1919.3700000000000000',
      volume: '746995.8591000000000000',
      quoteVolume: 1387448329.8618498,
      btcVolume: 23597.31023417654,
      usdVolume: 1385699357.8138206,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '1919.3700000000000000',
      high: '1984.7700000000000000',
      low: '1885.0200000000000000',
      close: '1968.0500000000000000',
      volume: '735243.4803000000000000',
      quoteVolume: 1426255182.7403693,
      btcVolume: 24205.415566070777,
      usdVolume: 1424192936.6523898,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '1968.0500000000000000',
      high: '2146.0000000000000000',
      low: '1948.4800000000000000',
      close: '2133.6900000000000000',
      volume: '836004.3995600000000000',
      quoteVolume: 1699509331.9186137,
      btcVolume: 28682.7732154343,
      usdVolume: 1699416511.4975188,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '2133.6900000000000000',
      high: '2138.1200000000000000',
      low: '1996.3000000000000000',
      close: '2008.5200000000000000',
      volume: '770009.0373200000000000',
      quoteVolume: 1601162397.6543598,
      btcVolume: 14889.771267495946,
      usdVolume: 877202202.2152315,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '2008.5900000000000000',
      high: '2091.8300000000000000',
      low: '1975.2400000000000000',
      close: '2075.6900000000000000',
      volume: '504446.3045400000000000',
      quoteVolume: 1030198911.5271093,
      btcVolume: 14552.052881946049,
      usdVolume: 839965464.8217052,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '2075.7000000000000000',
      high: '2130.6400000000000000',
      low: '2002.1500000000000000',
      close: '2107.3800000000000000',
      volume: '672715.8405800000000000',
      quoteVolume: 1393443640.6387079,
      btcVolume: 19837.173273893703,
      usdVolume: 1151438176.7157285,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '2107.4300000000000000',
      high: '2151.0000000000000000',
      low: '2044.6500000000000000',
      close: '2112.2100000000000000',
      volume: '646617.8868200000000000',
      quoteVolume: 1364425311.2743363,
      btcVolume: 21462.268704397782,
      usdVolume: 1253287568.3909323,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '2112.2200000000000000',
      high: '2129.0100000000000000',
      low: '1930.0100000000000000',
      close: '1963.4700000000000000',
      volume: '890590.5480600000000000',
      quoteVolume: 1793821185.7585125,
      btcVolume: 31085.880944942655,
      usdVolume: 1765504353.4110937,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '1963.4600000000000000',
      high: '2084.6100000000000000',
      low: '1947.3500000000000000',
      close: '2080.4600000000000000',
      volume: '492449.6518300000000000',
      quoteVolume: 998031729.5983349,
      btcVolume: 11760.316400533313,
      usdVolume: 671261695.401949,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '2080.3900000000000000',
      high: '2100.0000000000000000',
      low: '2047.5700000000000000',
      close: '2066.8700000000000000',
      volume: '410697.4165100000000000',
      quoteVolume: 852604897.8719946,
      btcVolume: 12983.009883018114,
      usdVolume: 756441273.5357431,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '2067.1900000000000000',
      high: '2200.0000000000000000',
      low: '2058.0000000000000000',
      close: '2133.4900000000000000',
      volume: '655458.2089400000000000',
      quoteVolume: 1405364881.858304,
      btcVolume: 23111.04520438008,
      usdVolume: 1384757109.84192,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '2133.4900000000000000',
      high: '2165.0200000000000000',
      low: '2113.4000000000000000',
      close: '2151.3600000000000000',
      volume: '392265.5077300000000000',
      quoteVolume: 839653140.9406079,
      btcVolume: 13957.221461420964,
      usdVolume: 834685291.3442482,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '2151.4200000000000000',
      high: '2203.4600000000000000',
      low: '2103.0500000000000000',
      close: '2137.6900000000000000',
      volume: '569303.1026900000000000',
      quoteVolume: 1224143619.588617,
      btcVolume: 16124.45725574653,
      usdVolume: 970101865.6152618,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '2137.8600000000000000',
      high: '2318.0000000000000000',
      low: '2135.5300000000000000',
      close: '2299.1900000000000000',
      volume: '753630.4481400000000000',
      quoteVolume: 1682124954.2545862,
      btcVolume: 26651.164261948637,
      usdVolume: 1663537493.65112,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '2299.1900000000000000',
      high: '2447.2900000000000000',
      low: '2281.3300000000000000',
      close: '2432.6000000000000000',
      volume: '962367.1999900000000000',
      quoteVolume: 2270110171.976935,
      btcVolume: 31771.533630725116,
      usdVolume: 2017446256.9322753,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '2432.5900000000000000',
      high: '2543.4700000000000000',
      low: '2400.0000000000000000',
      close: '2514.0400000000000000',
      volume: '681946.7520600000000000',
      quoteVolume: 1680052982.425424,
      btcVolume: 24649.296881737133,
      usdVolume: 1551057612.4665124,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '2514.0400000000000000',
      high: '2548.2900000000000000',
      low: '2300.0000000000000000',
      close: '2422.6700000000000000',
      volume: '999163.1245600000000000',
      quoteVolume: 2418565017.4451094,
      btcVolume: 37191.04906644725,
      usdVolume: 2288259291.5148406,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '2422.3800000000000000',
      high: '2495.0000000000000000',
      low: '2310.0000000000000000',
      close: '2317.6000000000000000',
      volume: '624232.2556800000000000',
      quoteVolume: 1504895144.9122174,
      btcVolume: 16884.468899235042,
      usdVolume: 1031965089.1388259,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '2317.6500000000000000',
      high: '2340.0000000000000000',
      low: '1950.0000000000000000',
      close: '2235.6400000000000000',
      volume: '1322276.0831600000000000',
      quoteVolume: 2866812093.0214953,
      btcVolume: 50861.605911477796,
      usdVolume: 2839032150.7239733,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '2235.6500000000000000',
      high: '2300.0000000000000000',
      low: '2080.0000000000000000',
      close: '2161.1200000000000000',
      volume: '820592.2655500000000000',
      quoteVolume: 1800575928.1074977,
      btcVolume: 26365.040134011742,
      usdVolume: 1481688446.6256678,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '2161.1200000000000000',
      high: '2346.6200000000000000',
      low: '2055.0000000000000000',
      close: '2330.0300000000000000',
      volume: '990682.9157700000000000',
      quoteVolume: 2177949495.5727634,
      btcVolume: 37918.97019892185,
      usdVolume: 2107334113.285447,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '2330.2600000000000000',
      high: '2468.0000000000000000',
      low: '2235.1600000000000000',
      close: '2357.0600000000000000',
      volume: '1113465.3921000000000000',
      quoteVolume: 2619975710.2322025,
      btcVolume: 40857.67918389536,
      usdVolume: 2269210586.199889,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '2357.0600000000000000',
      high: '2644.4700000000000000',
      low: '2303.4400000000000000',
      close: '2397.9400000000000000',
      volume: '1882176.3625200000000000',
      quoteVolume: 4670246227.172266,
      btcVolume: 25414.11693159991,
      usdVolume: 1354009041.8840244,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '2397.9400000000000000',
      high: '2442.8900000000000000',
      low: '2107.0000000000000000',
      close: '2367.3900000000000000',
      volume: '2392663.5084700000000000',
      quoteVolume: 5410016346.711445,
      btcVolume: 57263.218105779786,
      usdVolume: 2852064612.713971,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '2367.3900000000000000',
      high: '2368.7400000000000000',
      low: '2154.8000000000000000',
      close: '2213.3500000000000000',
      volume: '990513.3389000000000000',
      quoteVolume: 2230697502.0572195,
      btcVolume: 42345.682650564064,
      usdVolume: 2111057044.5906014,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '2214.0800000000000000',
      high: '2356.6400000000000000',
      low: '2168.0000000000000000',
      close: '2320.4900000000000000',
      volume: '817845.6182200000000000',
      quoteVolume: 1860725124.3291864,
      btcVolume: 20853.64183965011,
      usdVolume: 1033254138.8731536,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '2320.4400000000000000',
      high: '2541.8700000000000000',
      low: '2303.6400000000000000',
      close: '2532.6000000000000000',
      volume: '940704.4956200000000000',
      quoteVolume: 2314456522.3843803,
      btcVolume: 26103.489465891733,
      usdVolume: 1376208037.313604,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '2532.0600000000000000',
      high: '2680.0000000000000000',
      low: '2480.2900000000000000',
      close: '2666.1300000000000000',
      volume: '771728.4587600000000000',
      quoteVolume: 1995139009.247419,
      btcVolume: 36127.94470152542,
      usdVolume: 1973577408.7839944,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '2666.1300000000000000',
      high: '2760.0000000000000000',
      low: '2559.4400000000000000',
      close: '2748.2400000000000000',
      volume: '999383.1852000000000000',
      quoteVolume: 2673547154.2009354,
      btcVolume: 48837.864468014675,
      usdVolume: 2673547154.2009354,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '2748.2300000000000000',
      high: '2798.0000000000000000',
      low: '2668.0000000000000000',
      close: '2757.0400000000000000',
      volume: '859315.6791200000000000',
      quoteVolume: 2353486577.8108225,
      btcVolume: 43742.4160025124,
      usdVolume: 2353486577.8108225,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '2757.0400000000000000',
      high: '2798.3000000000000000',
      low: '2723.0900000000000000',
      close: '2772.4200000000000000',
      volume: '683253.7843800000000000',
      quoteVolume: 1883721206.1899638,
      btcVolume: 22122.659703772588,
      usdVolume: 1221913689.666882,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '2772.4200000000000000',
      high: '2954.2800000000000000',
      low: '2743.4100000000000000',
      close: '2944.7700000000000000',
      volume: '690260.0774100000000000',
      quoteVolume: 1979833188.065232,
      btcVolume: 16327.850363768606,
      usdVolume: 944162910.1777735,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '2944.8300000000000000',
      high: '2985.0000000000000000',
      low: '2852.2600000000000000',
      close: '2949.5600000000000000',
      volume: '571953.7712900000000000',
      quoteVolume: 1672227924.3766143,
      btcVolume: 19320.10153700959,
      usdVolume: 1096231381.3174038,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '2949.3300000000000000',
      high: '3454.0000000000000000',
      low: '2949.3200000000000000',
      close: '3431.0400000000000000',
      volume: '1329083.1120400000000000',
      quoteVolume: 4243771371.0916457,
      btcVolume: 71145.21913947008,
      usdVolume: 4116214448.7382607,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '3431.0400000000000000',
      high: '3527.0000000000000000',
      low: '3160.0000000000000000',
      close: '3239.0100000000000000',
      volume: '1797928.4935400000000000',
      quoteVolume: 6010359311.950286,
      btcVolume: 85671.24707619315,
      usdVolume: 4746803613.875628,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '3239.0100000000000000',
      high: '3549.3400000000000000',
      low: '3200.2300000000000000',
      close: '3524.2600000000000000',
      volume: '1094521.4393500000000000',
      quoteVolume: 3683966384.3414955,
      btcVolume: 60862.394706312116,
      usdVolume: 3399403885.2271895,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '3524.3300000000000000',
      high: '3605.4000000000000000',
      low: '3376.4700000000000000',
      close: '3489.7300000000000000',
      volume: '907396.3895700000000000',
      quoteVolume: 3167529015.808255,
      btcVolume: 54859.42355950186,
      usdVolume: 3118998835.779845,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '3489.7400000000000000',
      high: '3587.0000000000000000',
      low: '3353.2300000000000000',
      close: '3479.5300000000000000',
      volume: '760544.3177800000000000',
      quoteVolume: 2641739520.816758,
      btcVolume: 45671.46922983503,
      usdVolume: 2590518418.0204196,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '3479.7900000000000000',
      high: '3958.3300000000000000',
      low: '3449.2700000000000000',
      close: '3910.2600000000000000',
      volume: '1130467.5485200000000000',
      quoteVolume: 4170897763.922241,
      btcVolume: 70835.00620283352,
      usdVolume: 4147360439.8575544,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '3910.2600000000000000',
      high: '3983.6500000000000000',
      low: '3726.0800000000000000',
      close: '3923.9500000000000000',
      volume: '1000399.7612200000000000',
      quoteVolume: 3885034129.3254085,
      btcVolume: 66054.86727804251,
      usdVolume: 3823738678.629524,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '3923.9500000000000000',
      high: '4208.0000000000000000',
      low: '3693.4700000000000000',
      close: '3945.8800000000000000',
      volume: '1330445.0332100000000000',
      quoteVolume: 5397868145.894449,
      btcVolume: 66445.5379158468,
      usdVolume: 3801853644.3229055,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '3946.6300000000000000',
      high: '4180.2200000000000000',
      low: '3771.6400000000000000',
      close: '4173.4500000000000000',
      volume: '1071659.7833600000000000',
      quoteVolume: 4255907552.0657372,
      btcVolume: 59163.452670007915,
      usdVolume: 3302134688.3033047,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '4173.4600000000000000',
      high: '4372.7200000000000000',
      low: '3701.5800000000000000',
      close: '3887.7100000000000000',
      volume: '1589824.9292600000000000',
      quoteVolume: 6626411118.25824,
      btcVolume: 118376.00765287268,
      usdVolume: 6523763033.013671,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '3810.1500000000000000',
      high: '4034.7700000000000000',
      low: '3541.5100000000000000',
      close: '3717.9100000000000000',
      volume: '1849973.9261200000000000',
      quoteVolume: 7017701529.470531,
      btcVolume: 137733.97130057015,
      usdVolume: 6840474825.010459,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '3717.9200000000000000',
      high: '4173.2800000000000000',
      low: '3691.3500000000000000',
      close: '4075.0100000000000000',
      volume: '1152899.9027000000000000',
      quoteVolume: 4567552216.6355095,
      btcVolume: 88199.46408681228,
      usdVolume: 4430311894.504898,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '4075.0200000000000000',
      high: '4134.0500000000000000',
      low: '3632.0000000000000000',
      close: '3646.6800000000000000',
      volume: '1039174.3467800000000000',
      quoteVolume: 4030584420.9662867,
      btcVolume: 81888.4612645759,
      usdVolume: 3986942174.04559,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '3646.2400000000000000',
      high: '3879.0000000000000000',
      low: '3343.0600000000000000',
      close: '3582.1000000000000000',
      volume: '1322422.7023000000000000',
      quoteVolume: 4783072987.0008545,
      btcVolume: 99450.38052256861,
      usdVolume: 4678658584.545799,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '3581.8100000000000000',
      high: '3587.0600000000000000',
      low: '3102.0000000000000000',
      close: '3282.5100000000000000',
      volume: '1896852.2045000000000000',
      quoteVolume: 6376399013.426107,
      btcVolume: 141476.57089151486,
      usdVolume: 6236699464.627672,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '3282.2500000000000000',
      high: '3565.7100000000000000',
      low: '3240.0000000000000000',
      close: '3375.0700000000000000',
      volume: '1287105.1300500000000000',
      quoteVolume: 4392855117.159339,
      btcVolume: 98470.05761413471,
      usdVolume: 4341814936.084908,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '3375.0800000000000000',
      high: '3441.7300000000000000',
      low: '1900.0000000000000000',
      close: '2438.9200000000000000',
      volume: '2822906.7017300000000000',
      quoteVolume: 7943579809.10751,
      btcVolume: 169657.88253061575,
      usdVolume: 6636093341.830318,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '2438.9200000000000000',
      high: '2990.0500000000000000',
      low: '2155.0000000000000000',
      close: '2768.6200000000000000',
      volume: '2937686.1189100000000000',
      quoteVolume: 7738734836.44043,
      btcVolume: 162815.48666917285,
      usdVolume: 6411033639.910326,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '2767.8700000000000000',
      high: '2938.5400000000000000',
      low: '2101.0000000000000000',
      close: '2430.9800000000000000',
      volume: '2224854.1226900000000000',
      quoteVolume: 5663309143.626692,
      btcVolume: 119218.8275934102,
      usdVolume: 4583070212.396227,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '2430.9800000000000000',
      high: '2485.0000000000000000',
      low: '2145.0000000000000000',
      close: '2294.8700000000000000',
      volume: '1682579.1813100000000000',
      quoteVolume: 3930453187.1191373,
      btcVolume: 71381.46907218514,
      usdVolume: 2649594194.693011,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '2295.4500000000000000',
      high: '2381.5700000000000000',
      low: '1728.7400000000000000',
      close: '2096.3900000000000000',
      volume: '2914548.7312500000000000',
      quoteVolume: 5907260139.545083,
      btcVolume: 169607.22742003587,
      usdVolume: 5818960197.408998,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '2096.6300000000000000',
      high: '2675.0000000000000000',
      low: '2079.9400000000000000',
      close: '2648.4300000000000000',
      volume: '2184824.1622800000000000',
      quoteVolume: 5176143519.684482,
      btcVolume: 136880.6376374799,
      usdVolume: 5087206518.533662,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '2647.9200000000000000',
      high: '2750.0000000000000000',
      low: '2378.5400000000000000',
      close: '2705.1100000000000000',
      volume: '2115349.5044500000000000',
      quoteVolume: 5442737549.401353,
      btcVolume: 141033.426253863,
      usdVolume: 5351032818.276227,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '2705.1000000000000000',
      high: '2910.0000000000000000',
      low: '2643.0000000000000000',
      close: '2884.9400000000000000',
      volume: '1562515.8153800000000000',
      quoteVolume: 4356014953.228169,
      btcVolume: 109277.35501313985,
      usdVolume: 4286403436.2894278,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '2884.8200000000000000',
      high: '2889.7400000000000000',
      low: '2633.6800000000000000',
      close: '2742.2300000000000000',
      volume: '1099029.9120600000000000',
      quoteVolume: 3033719144.3402476,
      btcVolume: 77944.20663975782,
      usdVolume: 3015457564.549178,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '2742.2300000000000000',
      high: '2762.4500000000000000',
      low: '2327.3800000000000000',
      close: '2412.1000000000000000',
      volume: '1554584.6136200000000000',
      quoteVolume: 3934662309.0444574,
      btcVolume: 106412.09487045105,
      usdVolume: 3873392067.229942,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '2412.3400000000000000',
      high: '2574.2700000000000000',
      low: '2204.1000000000000000',
      close: '2278.7400000000000000',
      volume: '1379427.1885100000000000',
      quoteVolume: 3288804154.8317804,
      btcVolume: 92759.48942970896,
      usdVolume: 3255889564.014722,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '2278.7400000000000000',
      high: '2476.4200000000000000',
      low: '2180.5300000000000000',
      close: '2385.9800000000000000',
      volume: '1151113.1988300000000000',
      quoteVolume: 2726606138.6317267,
      btcVolume: 76968.91849705382,
      usdVolume: 2721338216.8446603,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '2385.8200000000000000',
      high: '2720.0000000000000000',
      low: '2271.4400000000000000',
      close: '2706.1500000000000000',
      volume: '1307559.4516500000000000',
      quoteVolume: 3276105181.236761,
      btcVolume: 90321.1015277825,
      usdVolume: 3271922711.64009,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '2706.1500000000000000',
      high: '2740.0000000000000000',
      low: '2523.7400000000000000',
      close: '2634.5700000000000000',
      volume: '1128194.7242100000000000',
      quoteVolume: 2948103721.112332,
      btcVolume: 79478.59488431703,
      usdVolume: 2909896222.812539,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '2634.3100000000000000',
      high: '2802.9900000000000000',
      low: '2550.0000000000000000',
      close: '2706.2200000000000000',
      volume: '942515.8424900000000000',
      quoteVolume: 2543367680.0125494,
      btcVolume: 67979.08133458497,
      usdVolume: 2536877829.1898575,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '2706.2100000000000000',
      high: '2891.9500000000000000',
      low: '2662.4000000000000000',
      close: '2857.2500000000000000',
      volume: '1020865.2499900000000000',
      quoteVolume: 2865136924.0086355,
      btcVolume: 74011.12317239847,
      usdVolume: 2859489401.1131005,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '2857.2500000000000000',
      high: '2860.0000000000000000',
      low: '2552.0000000000000000',
      close: '2689.5100000000000000',
      volume: '1128891.3342400000000000',
      quoteVolume: 3013427232.3647776,
      btcVolume: 79691.24087877397,
      usdVolume: 2948492978.646687,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '2689.5400000000000000',
      high: '2816.8700000000000000',
      low: '2552.2300000000000000',
      close: '2627.7300000000000000',
      volume: '961824.1241300000000000',
      quoteVolume: 2575726370.4308653,
      btcVolume: 69952.15513260124,
      usdVolume: 2547190306.6139827,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '2627.6300000000000000',
      high: '2745.0000000000000000',
      low: '2614.0000000000000000',
      close: '2711.9200000000000000',
      volume: '673616.2318600000000000',
      quoteVolume: 1813640710.7122996,
      btcVolume: 50332.5808706832,
      usdVolume: 1810527213.9748535,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '2711.9200000000000000',
      high: '2847.5500000000000000',
      low: '2570.0000000000000000',
      close: '2592.0500000000000000',
      volume: '1152128.0785600000000000',
      quoteVolume: 3149245176.9112477,
      btcVolume: 87844.73708419563,
      usdVolume: 3133289457.721581,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '2592.0500000000000000',
      high: '2620.9900000000000000',
      low: '2304.9100000000000000',
      close: '2507.2300000000000000',
      volume: '1693747.4275900000000000',
      quoteVolume: 4201646662.8817635,
      btcVolume: 127564.26680324697,
      usdVolume: 4173747237.8152375,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '2507.4300000000000000',
      high: '2626.6300000000000000',
      low: '2405.2800000000000000',
      close: '2610.1800000000000000',
      volume: '1276718.2401100000000000',
      quoteVolume: 3223323188.07037,
      btcVolume: 91671.38084778743,
      usdVolume: 3194267768.5913177,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '2610.2000000000000000',
      high: '2624.4600000000000000',
      low: '2425.1100000000000000',
      close: '2471.0900000000000000',
      volume: '740997.1712300000000000',
      quoteVolume: 1873951840.3917582,
      btcVolume: 50420.55129947163,
      usdVolume: 1868275591.2025664,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '2471.0100000000000000',
      high: '2497.9900000000000000',
      low: '2321.3700000000000000',
      close: '2354.0100000000000000',
      volume: '587364.2735800000000000',
      quoteVolume: 1424975261.80769,
      btcVolume: 38486.31716446658,
      usdVolume: 1422146092.2599802,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '2354.0900000000000000',
      high: '2454.5300000000000000',
      low: '2256.2200000000000000',
      close: '2370.7400000000000000',
      volume: '767439.4984000000000000',
      quoteVolume: 1807036943.9986227,
      btcVolume: 50420.12200546101,
      usdVolume: 1799225782.2437441,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '2370.6400000000000000',
      high: '2548.4100000000000000',
      low: '2308.4100000000000000',
      close: '2509.3300000000000000',
      volume: '715948.7598600000000000',
      quoteVolume: 1724097564.3342984,
      btcVolume: 46788.12807221204,
      usdVolume: 1720049201.122317,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '2509.3300000000000000',
      high: '2609.1700000000000000',
      low: '2461.0000000000000000',
      close: '2580.7700000000000000',
      volume: '684183.5562200000000000',
      quoteVolume: 1730260219.1337855,
      btcVolume: 43429.67097729521,
      usdVolume: 1729368351.3638728,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '2580.5800000000000000',
      high: '2640.0000000000000000',
      low: '2507.7000000000000000',
      close: '2543.5400000000000000',
      volume: '599826.6493300000000000',
      quoteVolume: 1548303436.0628068,
      btcVolume: 38413.865031627225,
      usdVolume: 1546074562.469748,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '2543.5500000000000000',
      high: '2556.1900000000000000',
      low: '2351.1800000000000000',
      close: '2367.8900000000000000',
      volume: '624741.7835800000000000',
      quoteVolume: 1533734359.4129868,
      btcVolume: 39025.025528815226,
      usdVolume: 1532223313.0262673,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '2368.0200000000000000',
      high: '2460.5000000000000000',
      low: '2306.0000000000000000',
      close: '2373.2800000000000000',
      volume: '560667.5856600000000000',
      quoteVolume: 1338389545.0014875,
      btcVolume: 34687.277518367926,
      usdVolume: 1336853440.9920366,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '2373.2800000000000000',
      high: '2378.0000000000000000',
      low: '2139.0000000000000000',
      close: '2234.0900000000000000',
      volume: '778905.2019700000000000',
      quoteVolume: 1758827532.8833337,
      btcVolume: 47651.515366817985,
      usdVolume: 1752866245.1499925,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '2234.0900000000000000',
      high: '2278.8200000000000000',
      low: '2161.6700000000000000',
      close: '2164.6700000000000000',
      volume: '496795.2001700000000000',
      quoteVolume: 1104751776.509073,
      btcVolume: 30908.617217601786,
      usdVolume: 1104192848.8738875,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '2165.3400000000000000',
      high: '2280.0100000000000000',
      low: '2040.6800000000000000',
      close: '2243.4700000000000000',
      volume: '743883.6474100000000000',
      quoteVolume: 1599009750.52694,
      btcVolume: 45441.06693970805,
      usdVolume: 1581146882.9629188,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '2243.4900000000000000',
      high: '2260.1600000000000000',
      low: '1865.5400000000000000',
      close: '1885.4900000000000000',
      volume: '1612068.6092700000000000',
      quoteVolume: 3216482751.6444073,
      btcVolume: 96836.53868378879,
      usdVolume: 3184376853.0560994,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '1885.2700000000000000',
      high: '1998.0000000000000000',
      low: '1700.4800000000000000',
      close: '1879.2800000000000000',
      volume: '1695015.0570000000000000',
      quoteVolume: 3158320609.65545,
      btcVolume: 98947.73538033027,
      usdVolume: 3106137818.9013505,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '1879.2900000000000000',
      high: '2045.0000000000000000',
      low: '1821.9500000000000000',
      close: '1968.1400000000000000',
      volume: '918428.2598300000000000',
      quoteVolume: 1817150090.2132,
      btcVolume: 53355.95568641275,
      usdVolume: 1800911052.56896,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '1967.9500000000000000',
      high: '2036.6100000000000000',
      low: '1884.7900000000000000',
      close: '1989.5200000000000000',
      volume: '696819.0814600000000000',
      quoteVolume: 1369338921.1143198,
      btcVolume: 40275.19926124708,
      usdVolume: 1360915669.485624,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '1989.5300000000000000',
      high: '2019.9900000000000000',
      low: '1791.2200000000000000',
      close: '1808.8200000000000000',
      volume: '858808.5476700000000000',
      quoteVolume: 1612556879.10726,
      btcVolume: 48562.74287689089,
      usdVolume: 1610678376.9593577,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '1808.9700000000000000',
      high: '1852.8000000000000000',
      low: '1717.3200000000000000',
      close: '1829.6400000000000000',
      volume: '832315.1490400000000000',
      quoteVolume: 1478550576.5646644,
      btcVolume: 47044.29221027522,
      usdVolume: 1472306570.7656367,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '1829.8400000000000000',
      high: '1984.5000000000000000',
      low: '1804.2600000000000000',
      close: '1982.2800000000000000',
      volume: '696055.7930700000000000',
      quoteVolume: 1299521992.0718284,
      btcVolume: 39054.314536943915,
      usdVolume: 1296909651.9815106,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '1982.2700000000000000',
      high: '2145.0000000000000000',
      low: '1960.5200000000000000',
      close: '2084.4100000000000000',
      volume: '1041685.6177500000000000',
      quoteVolume: 2133750196.9705138,
      btcVolume: 61642.91631656625,
      usdVolume: 2128309767.164647,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '2084.4200000000000000',
      high: '2245.0000000000000000',
      low: '2073.5700000000000000',
      close: '2165.3300000000000000',
      volume: '810280.8201900000000000',
      quoteVolume: 1758516830.5676162,
      btcVolume: 49297.042069009694,
      usdVolume: 1752182286.5171914,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '2165.4600000000000000',
      high: '2287.7800000000000000',
      low: '2087.9900000000000000',
      close: '2275.6800000000000000',
      volume: '898104.5594900000000000',
      quoteVolume: 1947010071.716728,
      btcVolume: 55804.56318164029,
      usdVolume: 1945352686.7102058,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '2275.6800000000000000',
      high: '2275.9300000000000000',
      low: '2073.1200000000000000',
      close: '2106.4100000000000000',
      volume: '787865.2513700000000000',
      quoteVolume: 1688043308.1272736,
      btcVolume: 50052.84631659016,
      usdVolume: 1684503864.007349,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '2106.4100000000000000',
      high: '2160.0000000000000000',
      low: '2018.5000000000000000',
      close: '2154.1300000000000000',
      volume: '667688.1982700000000000',
      quoteVolume: 1387639105.207638,
      btcVolume: 41678.18078815616,
      usdVolume: 1387357481.4871128,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '2153.9500000000000000',
      high: '2240.0000000000000000',
      low: '2112.0100000000000000',
      close: '2226.9900000000000000',
      volume: '567852.4913700000000000',
      quoteVolume: 1245755929.802624,
      btcVolume: 36259.684210643376,
      usdVolume: 1245045174.0268378,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '2226.9900000000000000',
      high: '2389.9300000000000000',
      low: '2189.5800000000000000',
      close: '2322.6900000000000000',
      volume: '586011.9181400000000000',
      quoteVolume: 1354921782.9253561,
      btcVolume: 38334.608601607164,
      usdVolume: 1354169661.0324094,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '2322.6900000000000000',
      high: '2324.6400000000000000',
      low: '2157.3200000000000000',
      close: '2196.5300000000000000',
      volume: '781397.7756700000000000',
      quoteVolume: 1752928567.61669,
      btcVolume: 50930.09033861102,
      usdVolume: 1733327510.3003647,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '2196.8500000000000000',
      high: '2350.0000000000000000',
      low: '2193.0000000000000000',
      close: '2322.5000000000000000',
      volume: '773628.7066000000000000',
      quoteVolume: 1776303270.1513493,
      btcVolume: 50945.131886658244,
      usdVolume: 1742938566.1977441,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '2322.5100000000000000',
      high: '2409.0000000000000000',
      low: '2291.9000000000000000',
      close: '2316.5700000000000000',
      volume: '663034.0864800000000000',
      quoteVolume: 1562325076.9449115,
      btcVolume: 45243.083431928804,
      usdVolume: 1562325076.9449115,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '2316.2500000000000000',
      high: '2325.6000000000000000',
      low: '2084.7900000000000000',
      close: '2116.3300000000000000',
      volume: '880265.0412400000000000',
      quoteVolume: 1926184139.3098714,
      btcVolume: 58517.61155780528,
      usdVolume: 1923539240.4032106,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '2116.3200000000000000',
      high: '2189.4000000000000000',
      low: '2046.5100000000000000',
      close: '2146.9900000000000000',
      volume: '738611.3630400000000000',
      quoteVolume: 1568646237.310992,
      btcVolume: 47365.37692337437,
      usdVolume: 1568180213.2470188,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '2146.9900000000000000',
      high: '2194.8200000000000000',
      low: '2074.1600000000000000',
      close: '2110.6900000000000000',
      volume: '499071.8590400000000000',
      quoteVolume: 1058023794.5114112,
      btcVolume: 31367.83342121098,
      usdVolume: 1058004268.9712181,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '2110.6800000000000000',
      high: '2174.0000000000000000',
      low: '2081.0200000000000000',
      close: '2140.4500000000000000',
      volume: '341861.9107800000000000',
      quoteVolume: 726983500.1953408,
      btcVolume: 21483.282017250633,
      usdVolume: 726871259.6481026,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '2140.5400000000000000',
      high: '2167.8300000000000000',
      low: '2006.6800000000000000',
      close: '2031.5800000000000000',
      volume: '540630.1074200000000000',
      quoteVolume: 1126108847.3713632,
      btcVolume: 33473.528532740485,
      usdVolume: 1122137454.5873184,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '2031.5700000000000000',
      high: '2047.3500000000000000',
      low: '1918.0000000000000000',
      close: '1940.1500000000000000',
      volume: '646200.5714500000000000',
      quoteVolume: 1285151955.408697,
      btcVolume: 39172.03527550333,
      usdVolume: 1284955208.4702895,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '1940.4400000000000000',
      high: '2019.1800000000000000',
      low: '1865.0000000000000000',
      close: '1994.5800000000000000',
      volume: '690308.3220300000000000',
      quoteVolume: 1342549043.7056658,
      btcVolume: 41334.87560952568,
      usdVolume: 1341142947.6783092,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '1994.4100000000000000',
      high: '2042.8800000000000000',
      low: '1881.0000000000000000',
      close: '1919.5100000000000000',
      volume: '645206.5048600000000000',
      quoteVolume: 1251707253.3075902,
      btcVolume: 38931.359341004165,
      usdVolume: 1249677161.8594604,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '1919.5400000000000000',
      high: '1965.0000000000000000',
      low: '1850.0000000000000000',
      close: '1876.2600000000000000',
      volume: '564174.3896200000000000',
      quoteVolume: 1073278193.9976848,
      btcVolume: 33853.35022909676,
      usdVolume: 1073277728.2380823,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '1876.4700000000000000',
      high: '1919.1900000000000000',
      low: '1844.4500000000000000',
      close: '1899.5900000000000000',
      volume: '425518.6836600000000000',
      quoteVolume: 801669257.5310873,
      btcVolume: 25349.466334220528,
      usdVolume: 799730281.5427622,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '1899.5000000000000000',
      high: '1993.7900000000000000',
      low: '1877.1000000000000000',
      close: '1891.6400000000000000',
      volume: '534837.6365400000000000',
      quoteVolume: 1038104997.5220226,
      btcVolume: 32671.78700030974,
      usdVolume: 1038010885.0892934,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '1891.6500000000000000',
      high: '1919.0000000000000000',
      low: '1805.0000000000000000',
      close: '1818.7300000000000000',
      volume: '547419.9377100000000000',
      quoteVolume: 1011627398.9238861,
      btcVolume: 32502.636019487036,
      usdVolume: 1011432953.4100147,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '1818.7300000000000000',
      high: '1841.3400000000000000',
      low: '1706.0000000000000000',
      close: '1786.0300000000000000',
      volume: '786035.8219700000000000',
      quoteVolume: 1386111795.633173,
      btcVolume: 46342.08150771999,
      usdVolume: 1385239379.852115,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '1786.0300000000000000',
      high: '2035.2300000000000000',
      low: '1747.5500000000000000',
      close: '1995.5000000000000000',
      volume: '1115949.8203300000000000',
      quoteVolume: 2138345813.7854047,
      btcVolume: 68045.4703475941,
      usdVolume: 2137544618.252568,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '1995.5000000000000000',
      high: '2046.0000000000000000',
      low: '1947.6200000000000000',
      close: '2024.5600000000000000',
      volume: '768854.3659000000000000',
      quoteVolume: 1536669128.520453,
      btcVolume: 47687.592340090785,
      usdVolume: 1532204051.4068804,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '2024.2700000000000000',
      high: '2094.2800000000000000',
      low: '1993.2200000000000000',
      close: '2034.1400000000000000',
      volume: '561649.8608800000000000',
      quoteVolume: 1150771289.6299481,
      btcVolume: 35513.3079063386,
      usdVolume: 1150771289.6299481,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
  dash: {
    id: 'dash',
    name: 'DASH',
    symbol: 'DASH',
    image: 'assets/images/coin-logo/DASH.png',
    activity24h: 545323456,
    availableAmount: 508.232323,
    usdxConversion: 7,
    allTimeValue: 3812390,
    allTimeAsset: 90678678421,
    candleData: [
      {
      open: '52.2500000000000000',
      high: '52.6600000000000000',
      low: '51.7000000000000000',
      close: '52.0700000000000000',
      volume: '35288.3970200000000000',
      quoteVolume: 1838724.3862475,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-28T00:00:00.000Z'
      },
      {
      open: '52.1000000000000000',
      high: '52.2600000000000000',
      low: '48.1700000000000000',
      close: '49.2100000000000000',
      volume: '93056.5563300000000000',
      quoteVolume: 4660386.8668885,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-29T00:00:00.000Z'
      },
      {
      open: '49.1600000000000000',
      high: '49.9600000000000000',
      low: '49.0200000000000000',
      close: '49.3400000000000000',
      volume: '41869.7843300000000000',
      quoteVolume: 2069910.6907136,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-30T00:00:00.000Z'
      },
      {
      open: '49.3500000000000000',
      high: '50.7100000000000000',
      low: '47.7100000000000000',
      close: '49.9300000000000000',
      volume: '59069.9364000000000000',
      quoteVolume: 2926931.182231,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-10-31T00:00:00.000Z'
      },
      {
      open: '49.9400000000000000',
      high: '50.8500000000000000',
      low: '49.7800000000000000',
      close: '50.6100000000000000',
      volume: '40743.5490400000000000',
      quoteVolume: 2044481.6283927,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-01T00:00:00.000Z'
      },
      {
      open: '50.6300000000000000',
      high: '52.2000000000000000',
      low: '50.5600000000000000',
      close: '51.7800000000000000',
      volume: '55439.5422700000000000',
      quoteVolume: 2853112.5898938,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-02T00:00:00.000Z'
      },
      {
      open: '51.7800000000000000',
      high: '51.8100000000000000',
      low: '50.6000000000000000',
      close: '51.0400000000000000',
      volume: '44526.4412600000000000',
      quoteVolume: 2280110.5089847,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-03T00:00:00.000Z'
      },
      {
      open: '51.0900000000000000',
      high: '56.3300000000000000',
      low: '50.7700000000000000',
      close: '54.6000000000000000',
      volume: '131563.2191000000000000',
      quoteVolume: 7106100.759797,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-04T00:00:00.000Z'
      },
      {
      open: '54.6000000000000000',
      high: '55.1500000000000000',
      low: '53.0100000000000000',
      close: '53.9100000000000000',
      volume: '71425.6818700000000000',
      quoteVolume: 3841003.0724431,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-05T00:00:00.000Z'
      },
      {
      open: '53.9300000000000000',
      high: '56.8800000000000000',
      low: '53.8700000000000000',
      close: '56.4800000000000000',
      volume: '82733.1937500000000000',
      quoteVolume: 4543573.2471019,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-06T00:00:00.000Z'
      },
      {
      open: '56.4400000000000000',
      high: '56.9200000000000000',
      low: '53.5200000000000000',
      close: '54.4700000000000000',
      volume: '96697.1057100000000000',
      quoteVolume: 5343582.6991728,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-07T00:00:00.000Z'
      },
      {
      open: '54.4900000000000000',
      high: '55.1200000000000000',
      low: '52.4200000000000000',
      close: '52.7200000000000000',
      volume: '71785.2511700000000000',
      quoteVolume: 3845880.2935037,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-08T00:00:00.000Z'
      },
      {
      open: '52.7000000000000000',
      high: '53.1500000000000000',
      low: '51.6200000000000000',
      close: '52.2500000000000000',
      volume: '54807.0000800000000000',
      quoteVolume: 2873957.5317537,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-09T00:00:00.000Z'
      },
      {
      open: '52.2800000000000000',
      high: '53.0800000000000000',
      low: '51.9700000000000000',
      close: '52.4800000000000000',
      volume: '37947.9906200000000000',
      quoteVolume: 1994759.2672652,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-10T00:00:00.000Z'
      },
      {
      open: '52.4700000000000000',
      high: '52.5700000000000000',
      low: '49.7900000000000000',
      close: '51.4600000000000000',
      volume: '66550.4946300000000000',
      quoteVolume: 3407022.15279,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-11T00:00:00.000Z'
      },
      {
      open: '51.5000000000000000',
      high: '52.0400000000000000',
      low: '50.5900000000000000',
      close: '51.2600000000000000',
      volume: '60038.9645800000000000',
      quoteVolume: 3075503.9229793,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-12T00:00:00.000Z'
      },
      {
      open: '51.2600000000000000',
      high: '51.6600000000000000',
      low: '49.6000000000000000',
      close: '50.4300000000000000',
      volume: '63966.8834300000000000',
      quoteVolume: 3255168.879664,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-13T00:00:00.000Z'
      },
      {
      open: '50.4100000000000000',
      high: '51.0800000000000000',
      low: '42.0000000000000000',
      close: '45.0500000000000000',
      volume: '307943.6722800000000000',
      quoteVolume: 13948978.8933711,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-14T00:00:00.000Z'
      },
      {
      open: '45.0500000000000000',
      high: '45.8000000000000000',
      low: '40.4000000000000000',
      close: '44.7800000000000000',
      volume: '239147.1436300000000000',
      quoteVolume: 10442471.6269976,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-15T00:00:00.000Z'
      },
      {
      open: '44.7800000000000000',
      high: '44.9500000000000000',
      low: '42.3800000000000000',
      close: '42.8900000000000000',
      volume: '126959.9343000000000000',
      quoteVolume: 5526205.6703331,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-16T00:00:00.000Z'
      },
      {
      open: '42.8900000000000000',
      high: '42.9400000000000000',
      low: '41.9200000000000000',
      close: '42.4300000000000000',
      volume: '64923.2697500000000000',
      quoteVolume: 2748488.2839843,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-17T00:00:00.000Z'
      },
      {
      open: '42.4600000000000000',
      high: '43.8400000000000000',
      low: '42.1100000000000000',
      close: '42.6100000000000000',
      volume: '69727.5181800000000000',
      quoteVolume: 2991350.2245596,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-18T00:00:00.000Z'
      },
      {
      open: '42.6300000000000000',
      high: '42.6500000000000000',
      low: '35.9400000000000000',
      close: '37.0700000000000000',
      volume: '266627.8181000000000000',
      quoteVolume: 10193547.6518018,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-19T00:00:00.000Z'
      },
      {
      open: '37.1100000000000000',
      high: '38.8000000000000000',
      low: '31.4700000000000000',
      close: '33.8000000000000000',
      volume: '481972.2055200000000000',
      quoteVolume: 16734732.4018969,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-20T00:00:00.000Z'
      },
      {
      open: '33.7100000000000000',
      high: '35.3800000000000000',
      low: '32.1700000000000000',
      close: '35.1500000000000000',
      volume: '307689.2861400000000000',
      quoteVolume: 10447986.7251629,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-21T00:00:00.000Z'
      },
      {
      open: '35.1700000000000000',
      high: '35.6800000000000000',
      low: '31.8400000000000000',
      close: '32.1100000000000000',
      volume: '140470.9296900000000000',
      quoteVolume: 4791952.8493514,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-22T00:00:00.000Z'
      },
      {
      open: '32.0900000000000000',
      high: '33.8100000000000000',
      low: '31.1000000000000000',
      close: '32.7400000000000000',
      volume: '178263.3759900000000000',
      quoteVolume: 5763152.7094353,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-23T00:00:00.000Z'
      },
      {
      open: '32.7400000000000000',
      high: '34.3000000000000000',
      low: '29.0700000000000000',
      close: '29.7300000000000000',
      volume: '175080.6502100000000000',
      quoteVolume: 5486899.3643201,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-24T00:00:00.000Z'
      },
      {
      open: '29.7200000000000000',
      high: '32.9300000000000000',
      low: '27.7000000000000000',
      close: '31.4900000000000000',
      volume: '330712.4127800000000000',
      quoteVolume: 9875455.0267542,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-25T00:00:00.000Z'
      },
      {
      open: '31.5100000000000000',
      high: '32.8800000000000000',
      low: '28.6600000000000000',
      close: '30.1800000000000000',
      volume: '346086.7723900000000000',
      quoteVolume: 10650119.32109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-26T00:00:00.000Z'
      },
      {
      open: '30.1800000000000000',
      high: '32.1600000000000000',
      low: '29.0500000000000000',
      close: '31.6100000000000000',
      volume: '194024.5309000000000000',
      quoteVolume: 5911546.9844789,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-27T00:00:00.000Z'
      },
      {
      open: '31.6200000000000000',
      high: '36.5100000000000000',
      low: '31.3900000000000000',
      close: '34.7400000000000000',
      volume: '261988.5621100000000000',
      quoteVolume: 8934592.4563842,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-28T00:00:00.000Z'
      },
      {
      open: '34.7400000000000000',
      high: '36.0000000000000000',
      low: '33.0800000000000000',
      close: '33.8800000000000000',
      volume: '206424.0394100000000000',
      quoteVolume: 7091338.8885229,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-29T00:00:00.000Z'
      },
      {
      open: '33.9000000000000000',
      high: '34.5300000000000000',
      low: '31.0700000000000000',
      close: '32.2100000000000000',
      volume: '182324.5556100000000000',
      quoteVolume: 5934596.3999476,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-11-30T00:00:00.000Z'
      },
      {
      open: '32.1900000000000000',
      high: '35.1000000000000000',
      low: '31.3800000000000000',
      close: '34.1700000000000000',
      volume: '130601.1752700000000000',
      quoteVolume: 4373049.6516537,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-01T00:00:00.000Z'
      },
      {
      open: '34.1100000000000000',
      high: '35.2400000000000000',
      low: '33.2100000000000000',
      close: '33.8200000000000000',
      volume: '126562.5130800000000000',
      quoteVolume: 4314174.8406725,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-02T00:00:00.000Z'
      },
      {
      open: '33.8100000000000000',
      high: '33.9700000000000000',
      low: '30.0800000000000000',
      close: '30.9000000000000000',
      volume: '160953.4761900000000000',
      quoteVolume: 5107201.1187242,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-03T00:00:00.000Z'
      },
      {
      open: '30.9200000000000000',
      high: '32.2500000000000000',
      low: '29.8700000000000000',
      close: '31.1300000000000000',
      volume: '125624.1006500000000000',
      quoteVolume: 3938334.4457955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-04T00:00:00.000Z'
      },
      {
      open: '31.1400000000000000',
      high: '31.4000000000000000',
      low: '29.1500000000000000',
      close: '29.2700000000000000',
      volume: '122536.9591900000000000',
      quoteVolume: 3686632.3658405,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-05T00:00:00.000Z'
      },
      {
      open: '29.2800000000000000',
      high: '30.4900000000000000',
      low: '26.2500000000000000',
      close: '26.7800000000000000',
      volume: '186020.5287000000000000',
      quoteVolume: 5318278.7153886,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-06T00:00:00.000Z'
      },
      {
      open: '26.7400000000000000',
      high: '26.9400000000000000',
      low: '22.3200000000000000',
      close: '24.9100000000000000',
      volume: '344334.7713200000000000',
      quoteVolume: 8550497.2274868,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-07T00:00:00.000Z'
      },
      {
      open: '24.8800000000000000',
      high: '26.6300000000000000',
      low: '22.8900000000000000',
      close: '24.2200000000000000',
      volume: '272026.2401200000000000',
      quoteVolume: 6669827.4592307,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-08T00:00:00.000Z'
      },
      {
      open: '24.2300000000000000',
      high: '26.7500000000000000',
      low: '24.2000000000000000',
      close: '25.3700000000000000',
      volume: '185392.2777400000000000',
      quoteVolume: 4728009.3049824,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-09T00:00:00.000Z'
      },
      {
      open: '25.3700000000000000',
      high: '25.8200000000000000',
      low: '23.6700000000000000',
      close: '24.1400000000000000',
      volume: '159523.3339000000000000',
      quoteVolume: 3913245.9562269,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-10T00:00:00.000Z'
      },
      {
      open: '24.1500000000000000',
      high: '24.6300000000000000',
      low: '22.9400000000000000',
      close: '23.4100000000000000',
      volume: '116912.3106800000000000',
      quoteVolume: 2774102.4887662,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-11T00:00:00.000Z'
      },
      {
      open: '23.4000000000000000',
      high: '24.9800000000000000',
      low: '23.2000000000000000',
      close: '24.1300000000000000',
      volume: '116697.0162100000000000',
      quoteVolume: 2839522.3254574,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-12T00:00:00.000Z'
      },
      {
      open: '24.1300000000000000',
      high: '24.2900000000000000',
      low: '22.9100000000000000',
      close: '23.0800000000000000',
      volume: '101343.2455300000000000',
      quoteVolume: 2391795.1552609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-13T00:00:00.000Z'
      },
      {
      open: '23.0600000000000000',
      high: '23.7600000000000000',
      low: '22.5000000000000000',
      close: '23.3400000000000000',
      volume: '115451.1935700000000000',
      quoteVolume: 2669993.897385,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-14T00:00:00.000Z'
      },
      {
      open: '23.3300000000000000',
      high: '24.0600000000000000',
      low: '23.0900000000000000',
      close: '23.6000000000000000',
      volume: '114346.7365200000000000',
      quoteVolume: 2696112.2014415,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-15T00:00:00.000Z'
      },
      {
      open: '23.5900000000000000',
      high: '26.9600000000000000',
      low: '23.4800000000000000',
      close: '25.5200000000000000',
      volume: '284638.3664300000000000',
      quoteVolume: 7367653.6178438,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-16T00:00:00.000Z'
      },
      {
      open: '25.5100000000000000',
      high: '30.3000000000000000',
      low: '25.4300000000000000',
      close: '28.9500000000000000',
      volume: '415677.5500900000000000',
      quoteVolume: 11734862.3593704,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-17T00:00:00.000Z'
      },
      {
      open: '28.9700000000000000',
      high: '30.1500000000000000',
      low: '27.3900000000000000',
      close: '29.8900000000000000',
      volume: '241540.2199400000000000',
      quoteVolume: 6940548.103116,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-18T00:00:00.000Z'
      },
      {
      open: '29.8900000000000000',
      high: '31.5500000000000000',
      low: '28.5100000000000000',
      close: '28.8300000000000000',
      volume: '464104.8813400000000000',
      quoteVolume: 13967868.6904562,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-19T00:00:00.000Z'
      },
      {
      open: '28.8300000000000000',
      high: '32.7600000000000000',
      low: '28.5200000000000000',
      close: '32.1800000000000000',
      volume: '347428.5018700000000000',
      quoteVolume: 10702281.6625123,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-20T00:00:00.000Z'
      },
      {
      open: '32.1800000000000000',
      high: '32.8700000000000000',
      low: '29.5500000000000000',
      close: '30.2900000000000000',
      volume: '222927.6634500000000000',
      quoteVolume: 6941800.3395846,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-21T00:00:00.000Z'
      },
      {
      open: '30.2900000000000000',
      high: '31.4900000000000000',
      low: '29.7000000000000000',
      close: '31.3300000000000000',
      volume: '106033.3357700000000000',
      quoteVolume: 3234572.7694072,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-22T00:00:00.000Z'
      },
      {
      open: '31.2900000000000000',
      high: '33.8400000000000000',
      low: '31.0900000000000000',
      close: '32.9700000000000000',
      volume: '255561.7289100000000000',
      quoteVolume: 8317118.2252661,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-23T00:00:00.000Z'
      },
      {
      open: '32.9800000000000000',
      high: '36.1200000000000000',
      low: '32.1700000000000000',
      close: '32.6800000000000000',
      volume: '305504.7449800000000000',
      quoteVolume: 10561724.7698791,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-24T00:00:00.000Z'
      },
      {
      open: '32.6900000000000000',
      high: '32.9300000000000000',
      low: '29.1900000000000000',
      close: '30.5000000000000000',
      volume: '248993.5555600000000000',
      quoteVolume: 7586691.2768408,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-25T00:00:00.000Z'
      },
      {
      open: '30.5000000000000000',
      high: '31.6800000000000000',
      low: '28.7900000000000000',
      close: '30.3000000000000000',
      volume: '174048.0413600000000000',
      quoteVolume: 5242464.4829548,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-26T00:00:00.000Z'
      },
      {
      open: '30.2700000000000000',
      high: '30.8900000000000000',
      low: '27.1700000000000000',
      close: '27.5500000000000000',
      volume: '157817.2261100000000000',
      quoteVolume: 4528463.300989,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-27T00:00:00.000Z'
      },
      {
      open: '27.5500000000000000',
      high: '33.0800000000000000',
      low: '27.3900000000000000',
      close: '31.8000000000000000',
      volume: '242123.8975000000000000',
      quoteVolume: 7428412.8216719,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-28T00:00:00.000Z'
      },
      {
      open: '31.7800000000000000',
      high: '32.8100000000000000',
      low: '29.9100000000000000',
      close: '30.2800000000000000',
      volume: '222196.8569800000000000',
      quoteVolume: 6994333.2082403,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-29T00:00:00.000Z'
      },
      {
      open: '30.2800000000000000',
      high: '32.0000000000000000',
      low: '29.6800000000000000',
      close: '31.3800000000000000',
      volume: '151572.9304500000000000',
      quoteVolume: 4696122.0322328,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-30T00:00:00.000Z'
      },
      {
      open: '31.3800000000000000',
      high: '31.4300000000000000',
      low: '29.4200000000000000',
      close: '29.9000000000000000',
      volume: '97446.1124100000000000',
      quoteVolume: 2959940.3443041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2018-12-31T00:00:00.000Z'
      },
      {
      open: '29.9200000000000000',
      high: '31.8000000000000000',
      low: '29.5600000000000000',
      close: '31.4800000000000000',
      volume: '103182.7376500000000000',
      quoteVolume: 3162741.4467664,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-01T00:00:00.000Z'
      },
      {
      open: '31.4700000000000000',
      high: '33.2900000000000000',
      low: '31.0700000000000000',
      close: '32.6700000000000000',
      volume: '118306.2331700000000000',
      quoteVolume: 3805773.1198366,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-02T00:00:00.000Z'
      },
      {
      open: '32.6800000000000000',
      high: '32.8000000000000000',
      low: '30.6200000000000000',
      close: '31.3900000000000000',
      volume: '94129.8511400000000000',
      quoteVolume: 2982980.2248225,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-03T00:00:00.000Z'
      },
      {
      open: '31.4100000000000000',
      high: '32.5300000000000000',
      low: '30.7800000000000000',
      close: '31.7600000000000000',
      volume: '108636.8219300000000000',
      quoteVolume: 3437486.3851984,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-04T00:00:00.000Z'
      },
      {
      open: '31.7600000000000000',
      high: '35.9500000000000000',
      low: '31.7600000000000000',
      close: '34.3300000000000000',
      volume: '335581.5584800000000000',
      quoteVolume: 11471296.9699548,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-05T00:00:00.000Z'
      },
      {
      open: '34.3300000000000000',
      high: '39.7100000000000000',
      low: '33.8900000000000000',
      close: '38.3000000000000000',
      volume: '761396.1067600000000000',
      quoteVolume: 28298586.9009635,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-06T00:00:00.000Z'
      },
      {
      open: '38.2900000000000000',
      high: '39.0400000000000000',
      low: '36.5300000000000000',
      close: '37.3500000000000000',
      volume: '444975.9243100000000000',
      quoteVolume: 16797767.6556064,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-07T00:00:00.000Z'
      },
      {
      open: '37.3500000000000000',
      high: '40.8000000000000000',
      low: '36.9100000000000000',
      close: '38.7000000000000000',
      volume: '522510.1714200000000000',
      quoteVolume: 20322945.3312497,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-08T00:00:00.000Z'
      },
      {
      open: '38.7000000000000000',
      high: '39.7200000000000000',
      low: '38.0000000000000000',
      close: '38.1700000000000000',
      volume: '312030.1302100000000000',
      quoteVolume: 12104357.4442312,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-09T00:00:00.000Z'
      },
      {
      open: '38.1700000000000000',
      high: '38.7700000000000000',
      low: '31.0000000000000000',
      close: '32.8100000000000000',
      volume: '584622.4539000000000000',
      quoteVolume: 19980421.46609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-10T00:00:00.000Z'
      },
      {
      open: '32.8100000000000000',
      high: '33.2200000000000000',
      low: '29.6800000000000000',
      close: '31.4900000000000000',
      volume: '351182.3816500000000000',
      quoteVolume: 11268415.5174647,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-11T00:00:00.000Z'
      },
      {
      open: '31.4800000000000000',
      high: '32.1300000000000000',
      low: '31.0700000000000000',
      close: '31.6500000000000000',
      volume: '178476.2871800000000000',
      quoteVolume: 5640569.2458151,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-12T00:00:00.000Z'
      },
      {
      open: '31.6500000000000000',
      high: '32.6300000000000000',
      low: '29.1200000000000000',
      close: '29.5900000000000000',
      volume: '229264.1192700000000000',
      quoteVolume: 7075842.0712984,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-13T00:00:00.000Z'
      },
      {
      open: '29.6000000000000000',
      high: '32.5000000000000000',
      low: '29.3800000000000000',
      close: '31.7100000000000000',
      volume: '255282.3580100000000000',
      quoteVolume: 7925720.2735823,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-14T00:00:00.000Z'
      },
      {
      open: '31.7200000000000000',
      high: '32.2700000000000000',
      low: '29.9500000000000000',
      close: '30.7500000000000000',
      volume: '216841.8608500000000000',
      quoteVolume: 6784015.4244266,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-15T00:00:00.000Z'
      },
      {
      open: '30.7400000000000000',
      high: '31.9300000000000000',
      low: '30.4700000000000000',
      close: '31.2900000000000000',
      volume: '153939.5962200000000000',
      quoteVolume: 4789334.1418824,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '31.2900000000000000',
      high: '31.5500000000000000',
      low: '29.8000000000000000',
      close: '31.4000000000000000',
      volume: '203218.0028600000000000',
      quoteVolume: 6238519.8934698,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '31.3800000000000000',
      high: '31.5300000000000000',
      low: '30.3100000000000000',
      close: '30.7700000000000000',
      volume: '150711.3444400000000000',
      quoteVolume: 4659547.3864997,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '30.7800000000000000',
      high: '32.9200000000000000',
      low: '30.7700000000000000',
      close: '32.0000000000000000',
      volume: '164989.6366800000000000',
      quoteVolume: 5273505.545043,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '31.9400000000000000',
      high: '33.2000000000000000',
      low: '28.4500000000000000',
      close: '30.6400000000000000',
      volume: '249450.0040800000000000',
      quoteVolume: 7815412.7300217,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '30.6300000000000000',
      high: '31.2200000000000000',
      low: '30.3400000000000000',
      close: '30.8200000000000000',
      volume: '110305.7586600000000000',
      quoteVolume: 3392716.0542585,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '30.8200000000000000',
      high: '32.0000000000000000',
      low: '29.4800000000000000',
      close: '31.4100000000000000',
      volume: '199887.1625700000000000',
      quoteVolume: 6183272.8098907,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '31.4000000000000000',
      high: '32.1800000000000000',
      low: '31.2400000000000000',
      close: '31.6900000000000000',
      volume: '123577.2723200000000000',
      quoteVolume: 3920165.8017895,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '31.6900000000000000',
      high: '33.1000000000000000',
      low: '30.8900000000000000',
      close: '32.5100000000000000',
      volume: '138812.1621600000000000',
      quoteVolume: 4453091.2945143,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '32.5200000000000000',
      high: '33.0000000000000000',
      low: '31.8700000000000000',
      close: '32.6900000000000000',
      volume: '169242.5338200000000000',
      quoteVolume: 5485967.9294638,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '32.6800000000000000',
      high: '33.9500000000000000',
      low: '32.5700000000000000',
      close: '32.8000000000000000',
      volume: '166677.4280800000000000',
      quoteVolume: 5537195.8890435,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '32.7900000000000000',
      high: '33.2000000000000000',
      low: '31.0500000000000000',
      close: '32.2100000000000000',
      volume: '173697.8199800000000000',
      quoteVolume: 5628213.3201342,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '32.2200000000000000',
      high: '32.5300000000000000',
      low: '30.0000000000000000',
      close: '30.9400000000000000',
      volume: '267887.6294200000000000',
      quoteVolume: 8275485.9723247,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '30.9400000000000000',
      high: '31.2600000000000000',
      low: '29.9100000000000000',
      close: '30.8100000000000000',
      volume: '135879.8588000000000000',
      quoteVolume: 4168438.6557358,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '30.8000000000000000',
      high: '31.9700000000000000',
      low: '30.4700000000000000',
      close: '31.6800000000000000',
      volume: '129114.9970500000000000',
      quoteVolume: 4046024.3723204,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '31.6800000000000000',
      high: '32.3300000000000000',
      low: '31.0800000000000000',
      close: '31.4100000000000000',
      volume: '154708.1742700000000000',
      quoteVolume: 4881672.9566493,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '31.4300000000000000',
      high: '33.6400000000000000',
      low: '30.7000000000000000',
      close: '32.6500000000000000',
      volume: '335544.9644500000000000',
      quoteVolume: 10864529.5873272,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '32.6100000000000000',
      high: '35.0100000000000000',
      low: '32.4400000000000000',
      close: '34.5900000000000000',
      volume: '268052.5235400000000000',
      quoteVolume: 8939273.973636,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '34.5900000000000000',
      high: '34.8900000000000000',
      low: '32.8600000000000000',
      close: '33.4400000000000000',
      volume: '348406.7322400000000000',
      quoteVolume: 11802330.6146259,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '33.4300000000000000',
      high: '34.5000000000000000',
      low: '33.3600000000000000',
      close: '34.0900000000000000',
      volume: '206649.6690200000000000',
      quoteVolume: 7014206.2681502,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '34.0800000000000000',
      high: '34.5400000000000000',
      low: '33.6300000000000000',
      close: '34.3400000000000000',
      volume: '214319.3423500000000000',
      quoteVolume: 7321284.8808597,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '34.3500000000000000',
      high: '34.5000000000000000',
      low: '32.2500000000000000',
      close: '32.7900000000000000',
      volume: '312456.5420100000000000',
      quoteVolume: 10273847.9600226,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '32.7800000000000000',
      high: '33.6100000000000000',
      low: '32.7300000000000000',
      close: '33.2400000000000000',
      volume: '144528.0808400000000000',
      quoteVolume: 4798854.8184626,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '33.2300000000000000',
      high: '44.8500000000000000',
      low: '32.7500000000000000',
      close: '42.9900000000000000',
      volume: '1237384.0701200000000000',
      quoteVolume: 48819159.693489,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '42.9900000000000000',
      high: '46.9500000000000000',
      low: '42.0500000000000000',
      close: '44.6800000000000000',
      volume: '993529.3585100000000000',
      quoteVolume: 43845377.3617417,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '44.6800000000000000',
      high: '47.5900000000000000',
      low: '42.0000000000000000',
      close: '46.7900000000000000',
      volume: '667594.4622900000000000',
      quoteVolume: 30084786.2181325,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '46.8000000000000000',
      high: '46.8700000000000000',
      low: '41.8100000000000000',
      close: '42.9000000000000000',
      volume: '570398.4899100000000000',
      quoteVolume: 25000654.7153878,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '42.8800000000000000',
      high: '44.5700000000000000',
      low: '41.8400000000000000',
      close: '43.8900000000000000',
      volume: '326731.5580100000000000',
      quoteVolume: 14181167.7324087,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '43.8900000000000000',
      high: '44.6600000000000000',
      low: '40.6400000000000000',
      close: '41.6100000000000000',
      volume: '333914.8432600000000000',
      quoteVolume: 14169773.6990322,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '41.6100000000000000',
      high: '42.4500000000000000',
      low: '40.3100000000000000',
      close: '41.2600000000000000',
      volume: '285144.9373900000000000',
      quoteVolume: 11825040.1861124,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '41.2600000000000000',
      high: '44.6000000000000000',
      low: '41.0600000000000000',
      close: '42.5800000000000000',
      volume: '389950.9521300000000000',
      quoteVolume: 16519445.9920431,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '42.6000000000000000',
      high: '44.3700000000000000',
      low: '42.2800000000000000',
      close: '43.3400000000000000',
      volume: '326691.7079300000000000',
      quoteVolume: 14225774.1208697,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '43.3400000000000000',
      high: '45.1300000000000000',
      low: '42.6000000000000000',
      close: '43.9100000000000000',
      volume: '331698.7960300000000000',
      quoteVolume: 14459368.8381966,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '43.8900000000000000',
      high: '49.0300000000000000',
      low: '43.6200000000000000',
      close: '47.8400000000000000',
      volume: '744029.8712100000000000',
      quoteVolume: 34349598.9758269,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '47.8100000000000000',
      high: '49.1000000000000000',
      low: '46.5600000000000000',
      close: '47.1400000000000000',
      volume: '404786.7578400000000000',
      quoteVolume: 19350985.317177,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '47.1600000000000000',
      high: '52.2100000000000000',
      low: '46.2500000000000000',
      close: '51.5000000000000000',
      volume: '566874.1207300000000000',
      quoteVolume: 28402239.4220076,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '51.4700000000000000',
      high: '51.6500000000000000',
      low: '47.3500000000000000',
      close: '48.9400000000000000',
      volume: '383781.9003200000000000',
      quoteVolume: 18972966.8701831,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '48.9300000000000000',
      high: '49.9700000000000000',
      low: '48.0800000000000000',
      close: '49.1900000000000000',
      volume: '265979.4348900000000000',
      quoteVolume: 13079846.878828,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '49.2200000000000000',
      high: '51.8800000000000000',
      low: '48.0000000000000000',
      close: '51.4000000000000000',
      volume: '411115.3383000000000000',
      quoteVolume: 20597303.0431109,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '51.3900000000000000',
      high: '53.5300000000000000',
      low: '42.3700000000000000',
      close: '43.7500000000000000',
      volume: '685277.0160800000000000',
      quoteVolume: 32440229.6963062,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '43.7400000000000000',
      high: '46.8500000000000000',
      low: '43.0000000000000000',
      close: '45.4000000000000000',
      volume: '377273.1727500000000000',
      quoteVolume: 16992487.3052862,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '45.4000000000000000',
      high: '45.8900000000000000',
      low: '44.0600000000000000',
      close: '44.7600000000000000',
      volume: '220101.5549400000000000',
      quoteVolume: 9886505.2314402,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '44.7700000000000000',
      high: '45.5300000000000000',
      low: '42.5100000000000000',
      close: '45.1600000000000000',
      volume: '223940.9450500000000000',
      quoteVolume: 9964616.9874317,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '45.1500000000000000',
      high: '46.7000000000000000',
      low: '44.4400000000000000',
      close: '45.6100000000000000',
      volume: '258562.9677900000000000',
      quoteVolume: 11834468.51228,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '45.6200000000000000',
      high: '48.9500000000000000',
      low: '45.5300000000000000',
      close: '47.0500000000000000',
      volume: '336011.9229400000000000',
      quoteVolume: 15914635.4786478,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '47.0200000000000000',
      high: '49.4000000000000000',
      low: '46.3800000000000000',
      close: '48.4900000000000000',
      volume: '325733.9473500000000000',
      quoteVolume: 15618945.7813467,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '48.5100000000000000',
      high: '49.1000000000000000',
      low: '47.1800000000000000',
      close: '47.8600000000000000',
      volume: '218511.9939600000000000',
      quoteVolume: 10525909.3067072,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '47.8700000000000000',
      high: '48.2200000000000000',
      low: '44.5700000000000000',
      close: '45.7800000000000000',
      volume: '306985.2797700000000000',
      quoteVolume: 14131761.4970669,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '45.7800000000000000',
      high: '54.2500000000000000',
      low: '45.3800000000000000',
      close: '52.5200000000000000',
      volume: '798938.1036800000000000',
      quoteVolume: 40660412.2179829,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '52.5200000000000000',
      high: '56.8100000000000000',
      low: '51.1100000000000000',
      close: '55.3700000000000000',
      volume: '670227.4940300000000000',
      quoteVolume: 36366973.2904414,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '55.3700000000000000',
      high: '59.1200000000000000',
      low: '53.7700000000000000',
      close: '56.7300000000000000',
      volume: '816612.7704900000000000',
      quoteVolume: 46227699.9424252,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '56.7300000000000000',
      high: '57.6000000000000000',
      low: '54.3000000000000000',
      close: '55.6100000000000000',
      volume: '440480.6590400000000000',
      quoteVolume: 24793142.8918252,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '55.5800000000000000',
      high: '59.8300000000000000',
      low: '55.3800000000000000',
      close: '57.7800000000000000',
      volume: '413625.1043600000000000',
      quoteVolume: 23841397.7942103,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '57.7800000000000000',
      high: '58.0400000000000000',
      low: '55.8400000000000000',
      close: '56.5900000000000000',
      volume: '198932.2372700000000000',
      quoteVolume: 11276533.6754336,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '56.6300000000000000',
      high: '57.3700000000000000',
      low: '54.0000000000000000',
      close: '54.8200000000000000',
      volume: '319640.8436300000000000',
      quoteVolume: 17619148.0806475,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '54.8200000000000000',
      high: '56.9600000000000000',
      low: '53.7200000000000000',
      close: '56.5800000000000000',
      volume: '186798.9733400000000000',
      quoteVolume: 10473627.53209,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '56.6000000000000000',
      high: '56.8400000000000000',
      low: '54.3000000000000000',
      close: '55.2900000000000000',
      volume: '234206.3592300000000000',
      quoteVolume: 13044765.6089822,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '55.2900000000000000',
      high: '58.2000000000000000',
      low: '51.6600000000000000',
      close: '55.9400000000000000',
      volume: '333686.3963100000000000',
      quoteVolume: 18559101.3738751,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '55.9400000000000000',
      high: '59.1500000000000000',
      low: '55.9400000000000000',
      close: '58.5200000000000000',
      volume: '295307.7767700000000000',
      quoteVolume: 17013433.6384185,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '58.5200000000000000',
      high: '61.9700000000000000',
      low: '58.4100000000000000',
      close: '61.2100000000000000',
      volume: '375314.7926600000000000',
      quoteVolume: 22846386.0282299,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '61.2200000000000000',
      high: '61.3200000000000000',
      low: '59.5700000000000000',
      close: '60.4700000000000000',
      volume: '174833.3191300000000000',
      quoteVolume: 10590199.9608341,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '60.4700000000000000',
      high: '62.6300000000000000',
      low: '58.2000000000000000',
      close: '59.1700000000000000',
      volume: '301146.2139800000000000',
      quoteVolume: 18071146.4905442,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '59.2000000000000000',
      high: '59.9600000000000000',
      low: '58.3300000000000000',
      close: '59.5200000000000000',
      volume: '221995.5983300000000000',
      quoteVolume: 13162394.4379992,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '59.5000000000000000',
      high: '60.7400000000000000',
      low: '56.6400000000000000',
      close: '60.2700000000000000',
      volume: '285413.7934000000000000',
      quoteVolume: 16953291.8433374,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '60.2900000000000000',
      high: '60.6400000000000000',
      low: '56.9800000000000000',
      close: '58.5400000000000000',
      volume: '164946.6053500000000000',
      quoteVolume: 9725257.740012,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '58.5400000000000000',
      high: '59.6000000000000000',
      low: '58.0000000000000000',
      close: '59.2600000000000000',
      volume: '184427.3358700000000000',
      quoteVolume: 10869615.6738316,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '59.2300000000000000',
      high: '61.7600000000000000',
      low: '58.9300000000000000',
      close: '60.8900000000000000',
      volume: '372961.5478100000000000',
      quoteVolume: 22578010.5670285,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '60.8800000000000000',
      high: '60.9000000000000000',
      low: '58.8400000000000000',
      close: '59.8200000000000000',
      volume: '360926.1570600000000000',
      quoteVolume: 21566309.6375968,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '59.8100000000000000',
      high: '60.7000000000000000',
      low: '58.2000000000000000',
      close: '59.0400000000000000',
      volume: '216703.6696800000000000',
      quoteVolume: 12846830.3232689,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '59.0600000000000000',
      high: '59.3500000000000000',
      low: '57.2300000000000000',
      close: '58.7900000000000000',
      volume: '175752.0092500000000000',
      quoteVolume: 10247903.100617,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '58.8100000000000000',
      high: '61.8700000000000000',
      low: '58.5800000000000000',
      close: '61.4400000000000000',
      volume: '260971.7306100000000000',
      quoteVolume: 15803472.9452996,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '61.4300000000000000',
      high: '61.4300000000000000',
      low: '60.1500000000000000',
      close: '60.5000000000000000',
      volume: '160785.5461800000000000',
      quoteVolume: 9744151.8473039,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '60.4800000000000000',
      high: '61.8000000000000000',
      low: '60.1500000000000000',
      close: '61.4500000000000000',
      volume: '200422.3453500000000000',
      quoteVolume: 12200871.3372611,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '61.4100000000000000',
      high: '61.5600000000000000',
      low: '59.5900000000000000',
      close: '60.4500000000000000',
      volume: '161304.2612800000000000',
      quoteVolume: 9780439.5002168,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '60.4500000000000000',
      high: '60.7200000000000000',
      low: '59.8300000000000000',
      close: '60.4800000000000000',
      volume: '120015.8600500000000000',
      quoteVolume: 7234304.2504601,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '60.4700000000000000',
      high: '60.9000000000000000',
      low: '59.6200000000000000',
      close: '60.4500000000000000',
      volume: '129238.6612100000000000',
      quoteVolume: 7810478.4448446,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '60.4500000000000000',
      high: '78.4000000000000000',
      low: '59.9500000000000000',
      close: '76.7800000000000000',
      volume: '805505.1657500000000000',
      quoteVolume: 55327330.1915808,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '76.7600000000000000',
      high: '98.5000000000000000',
      low: '76.2400000000000000',
      close: '85.1900000000000000',
      volume: '1514710.2931300000000000',
      quoteVolume: 130062156.107703,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '85.0200000000000000',
      high: '89.8500000000000000',
      low: '79.8500000000000000',
      close: '84.7000000000000000',
      volume: '775702.6721700000000000',
      quoteVolume: 66127061.9191573,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '84.7100000000000000',
      high: '89.8500000000000000',
      low: '83.6500000000000000',
      close: '88.2400000000000000',
      volume: '366668.9956300000000000',
      quoteVolume: 31984491.3758827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '88.2400000000000000',
      high: '97.5400000000000000',
      low: '85.0000000000000000',
      close: '92.2100000000000000',
      volume: '706789.2345900000000000',
      quoteVolume: 65322880.0905888,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '92.2300000000000000',
      high: '95.4400000000000000',
      low: '88.7400000000000000',
      close: '91.5500000000000000',
      volume: '404878.1342900000000000',
      quoteVolume: 37409101.1554274,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '91.5500000000000000',
      high: '94.0000000000000000',
      low: '85.2000000000000000',
      close: '88.5700000000000000',
      volume: '439909.3384300000000000',
      quoteVolume: 39092277.7488787,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '88.5700000000000000',
      high: '88.7100000000000000',
      low: '82.6100000000000000',
      close: '86.1700000000000000',
      volume: '317748.7851900000000000',
      quoteVolume: 27340412.18294,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '86.1900000000000000',
      high: '91.1000000000000000',
      low: '85.9100000000000000',
      close: '88.1600000000000000',
      volume: '315727.2367600000000000',
      quoteVolume: 27802373.2737036,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '88.1600000000000000',
      high: '88.4500000000000000',
      low: '74.6400000000000000',
      close: '78.8800000000000000',
      volume: '663804.9849800000000000',
      quoteVolume: 52734752.5700692,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '78.9000000000000000',
      high: '80.4300000000000000',
      low: '75.0000000000000000',
      close: '78.4700000000000000',
      volume: '351092.1192400000000000',
      quoteVolume: 27388660.7258404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '78.4300000000000000',
      high: '81.0200000000000000',
      low: '75.5100000000000000',
      close: '77.6200000000000000',
      volume: '276710.7109000000000000',
      quoteVolume: 21674683.2126471,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '77.5800000000000000',
      high: '83.6000000000000000',
      low: '76.0900000000000000',
      close: '83.2800000000000000',
      volume: '242418.4130300000000000',
      quoteVolume: 19179352.734823,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '83.3100000000000000',
      high: '84.4100000000000000',
      low: '75.0000000000000000',
      close: '78.0700000000000000',
      volume: '437925.6544900000000000',
      quoteVolume: 35039724.5357955,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '78.0600000000000000',
      high: '81.4900000000000000',
      low: '76.8800000000000000',
      close: '80.2700000000000000',
      volume: '295134.1772800000000000',
      quoteVolume: 23370026.8609958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '80.2700000000000000',
      high: '81.5000000000000000',
      low: '78.0000000000000000',
      close: '78.7700000000000000',
      volume: '232336.8106200000000000',
      quoteVolume: 18452843.5923519,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '78.8200000000000000',
      high: '83.6600000000000000',
      low: '78.7400000000000000',
      close: '81.6800000000000000',
      volume: '328509.7703700000000000',
      quoteVolume: 26614059.4859404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '81.6700000000000000',
      high: '83.1700000000000000',
      low: '79.4800000000000000',
      close: '82.0500000000000000',
      volume: '265365.4938300000000000',
      quoteVolume: 21606124.7509276,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '82.0500000000000000',
      high: '82.6900000000000000',
      low: '79.3300000000000000',
      close: '81.1500000000000000',
      volume: '209481.5475900000000000',
      quoteVolume: 17009020.0094766,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '81.1600000000000000',
      high: '81.8500000000000000',
      low: '72.8600000000000000',
      close: '76.6500000000000000',
      volume: '405449.3565200000000000',
      quoteVolume: 30882393.8202287,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '76.6100000000000000',
      high: '77.6300000000000000',
      low: '74.9000000000000000',
      close: '76.5100000000000000',
      volume: '285737.8388600000000000',
      quoteVolume: 21814764.7461896,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '76.4700000000000000',
      high: '79.2900000000000000',
      low: '73.3300000000000000',
      close: '73.7000000000000000',
      volume: '408341.2265500000000000',
      quoteVolume: 31534100.588167,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '73.6700000000000000',
      high: '74.6900000000000000',
      low: '69.9000000000000000',
      close: '72.8500000000000000',
      volume: '466422.9092200000000000',
      quoteVolume: 33586009.2516322,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '72.8500000000000000',
      high: '74.4300000000000000',
      low: '70.6000000000000000',
      close: '71.7800000000000000',
      volume: '307408.7038600000000000',
      quoteVolume: 22353656.7217245,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '71.7900000000000000',
      high: '76.5000000000000000',
      low: '70.3100000000000000',
      close: '73.8200000000000000',
      volume: '345014.8767600000000000',
      quoteVolume: 25320328.5480487,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '73.8300000000000000',
      high: '74.0100000000000000',
      low: '71.8300000000000000',
      close: '72.2300000000000000',
      volume: '102336.9821700000000000',
      quoteVolume: 7467848.3762795,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '72.2400000000000000',
      high: '72.7200000000000000',
      low: '68.9500000000000000',
      close: '69.8900000000000000',
      volume: '218635.5895900000000000',
      quoteVolume: 15458571.1783797,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '69.8900000000000000',
      high: '70.3700000000000000',
      low: '66.5000000000000000',
      close: '67.8700000000000000',
      volume: '203603.1860100000000000',
      quoteVolume: 13896456.9662964,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '67.8600000000000000',
      high: '74.7400000000000000',
      low: '67.8600000000000000',
      close: '74.3200000000000000',
      volume: '354469.1353800000000000',
      quoteVolume: 25357609.0003327,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '74.3100000000000000',
      high: '75.3600000000000000',
      low: '71.7800000000000000',
      close: '73.3600000000000000',
      volume: '273831.0476500000000000',
      quoteVolume: 20098972.4966065,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '73.3500000000000000',
      high: '74.4200000000000000',
      low: '72.7300000000000000',
      close: '73.6800000000000000',
      volume: '289626.4536600000000000',
      quoteVolume: 21306943.7993429,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '73.6500000000000000',
      high: '80.5800000000000000',
      low: '73.3100000000000000',
      close: '79.2200000000000000',
      volume: '538448.4052300000000000',
      quoteVolume: 41683556.4117655,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '79.2600000000000000',
      high: '82.7600000000000000',
      low: '74.5400000000000000',
      close: '77.7700000000000000',
      volume: '457004.0148400000000000',
      quoteVolume: 35750061.7116449,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '77.7300000000000000',
      high: '78.1600000000000000',
      low: '75.4000000000000000',
      close: '75.7000000000000000',
      volume: '236821.3124500000000000',
      quoteVolume: 18139200.7639219,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '75.7000000000000000',
      high: '76.9100000000000000',
      low: '71.6400000000000000',
      close: '74.9400000000000000',
      volume: '389445.4803700000000000',
      quoteVolume: 28863797.8869894,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '74.9500000000000000',
      high: '78.7500000000000000',
      low: '73.4000000000000000',
      close: '74.6100000000000000',
      volume: '357677.9279500000000000',
      quoteVolume: 27275279.8649669,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '74.5900000000000000',
      high: '75.5000000000000000',
      low: '72.4000000000000000',
      close: '74.4400000000000000',
      volume: '206081.7854800000000000',
      quoteVolume: 15267753.6578615,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '74.4400000000000000',
      high: '75.9000000000000000',
      low: '72.7700000000000000',
      close: '74.1900000000000000',
      volume: '240477.4366700000000000',
      quoteVolume: 17829294.3569958,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '74.1800000000000000',
      high: '79.6100000000000000',
      low: '72.6600000000000000',
      close: '77.1100000000000000',
      volume: '422915.9252000000000000',
      quoteVolume: 32199715.1352361,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '77.1200000000000000',
      high: '95.0000000000000000',
      low: '76.8500000000000000',
      close: '86.5900000000000000',
      volume: '909602.1269400000000000',
      quoteVolume: 78091222.1082642,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '86.5900000000000000',
      high: '94.0100000000000000',
      low: '83.0000000000000000',
      close: '84.7300000000000000',
      volume: '797262.8597300000000000',
      quoteVolume: 69998434.01864,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '84.7100000000000000',
      high: '92.4900000000000000',
      low: '84.1000000000000000',
      close: '87.1600000000000000',
      volume: '627311.3557300000000000',
      quoteVolume: 55391993.134908,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '87.3400000000000000',
      high: '93.6100000000000000',
      low: '86.5000000000000000',
      close: '91.1700000000000000',
      volume: '644139.0646700000000000',
      quoteVolume: 58186818.859561,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '91.1500000000000000',
      high: '104.2500000000000000',
      low: '89.6900000000000000',
      close: '102.0200000000000000',
      volume: '673429.9261200000000000',
      quoteVolume: 66429508.1788531,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '102.0300000000000000',
      high: '107.1400000000000000',
      low: '90.7300000000000000',
      close: '95.4900000000000000',
      volume: '1179885.1078400000000000',
      quoteVolume: 116020063.777305,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '95.4900000000000000',
      high: '97.4800000000000000',
      low: '84.2700000000000000',
      close: '89.7600000000000000',
      volume: '914179.2254800000000000',
      quoteVolume: 80856683.9611846,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '89.7600000000000000',
      high: '90.5100000000000000',
      low: '85.1100000000000000',
      close: '86.5100000000000000',
      volume: '380542.1429700000000000',
      quoteVolume: 33352370.4500154,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '86.5500000000000000',
      high: '96.8100000000000000',
      low: '86.0700000000000000',
      close: '94.5800000000000000',
      volume: '584649.5175900000000000',
      quoteVolume: 54045051.6228448,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '94.5500000000000000',
      high: '94.5800000000000000',
      low: '87.1100000000000000',
      close: '91.1000000000000000',
      volume: '447735.7161500000000000',
      quoteVolume: 40656121.4410844,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '91.1000000000000000',
      high: '93.0200000000000000',
      low: '88.8300000000000000',
      close: '90.7900000000000000',
      volume: '335538.2330900000000000',
      quoteVolume: 30483197.1255505,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '90.7700000000000000',
      high: '92.0400000000000000',
      low: '85.8300000000000000',
      close: '87.7400000000000000',
      volume: '308520.2910900000000000',
      quoteVolume: 27641752.0456785,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '87.7400000000000000',
      high: '89.5300000000000000',
      low: '84.6200000000000000',
      close: '88.6100000000000000',
      volume: '208332.2952500000000000',
      quoteVolume: 18146600.071508,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '88.6700000000000000',
      high: '103.7700000000000000',
      low: '88.0100000000000000',
      close: '100.5000000000000000',
      volume: '832985.1612500000000000',
      quoteVolume: 81794059.9838869,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '100.5000000000000000',
      high: '105.0700000000000000',
      low: '98.8700000000000000',
      close: '102.6600000000000000',
      volume: '337657.9218500000000000',
      quoteVolume: 34485049.017298095,
      btcVolume: 4312.521556865614,
      usdVolume: 34485049.017298095,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '102.6600000000000000',
      high: '114.0000000000000000',
      low: '97.5000000000000000',
      close: '111.0000000000000000',
      volume: '447812.7599800000000000',
      quoteVolume: 46913902.85878797,
      btcVolume: 5689.1831608019,
      usdVolume: 46913902.85878797,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '111.0300000000000000',
      high: '121.8900000000000000',
      low: '109.9400000000000000',
      close: '116.2700000000000000',
      volume: '595659.2299900000000000',
      quoteVolume: 68097250.03244157,
      btcVolume: 7799.762188822423,
      usdVolume: 68097250.03244157,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '116.3000000000000000',
      high: '118.0000000000000000',
      low: '111.1100000000000000',
      close: '114.8100000000000000',
      volume: '230852.9642500000000000',
      quoteVolume: 26424422.174400397,
      btcVolume: 3034.2155133217652,
      usdVolume: 26424422.174400397,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '114.8700000000000000',
      high: '118.3300000000000000',
      low: '108.0000000000000000',
      close: '114.5300000000000000',
      volume: '220738.2597100000000000',
      quoteVolume: 25195330.963828564,
      btcVolume: 2920.4957713299823,
      usdVolume: 25195330.963828564,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '115.0500000000000000',
      high: '120.3500000000000000',
      low: '102.4000000000000000',
      close: '108.2100000000000000',
      volume: '507093.9330100000000000',
      quoteVolume: 57087056.14430182,
      btcVolume: 6672.278677065912,
      usdVolume: 57087056.14430182,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '107.4100000000000000',
      high: '114.9300000000000000',
      low: '104.5600000000000000',
      close: '114.3500000000000000',
      volume: '312792.4647700000000000',
      quoteVolume: 34046399.04506248,
      btcVolume: 4077.7128007930464,
      usdVolume: 34046399.04506248,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '114.8000000000000000',
      high: '116.1000000000000000',
      low: '110.5100000000000000',
      close: '112.3500000000000000',
      volume: '223549.1279200000000000',
      quoteVolume: 25341433.203971107,
      btcVolume: 2969.5187987480676,
      usdVolume: 25341433.203971107,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '112.3400000000000000',
      high: '116.4800000000000000',
      low: '111.2500000000000000',
      close: '114.3900000000000000',
      volume: '233743.1953000000000000',
      quoteVolume: 26688762.017144043,
      btcVolume: 3077.4531043299494,
      usdVolume: 26688762.017144043,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '114.4400000000000000',
      high: '116.2000000000000000',
      low: '105.0100000000000000',
      close: '105.9900000000000000',
      volume: '426880.8260100000000000',
      quoteVolume: 47600822.28424786,
      btcVolume: 5611.544120917245,
      usdVolume: 47600822.28424786,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '105.9900000000000000',
      high: '106.8000000000000000',
      low: '98.1300000000000000',
      close: '102.2000000000000000',
      volume: '673205.5704500000000000',
      quoteVolume: 69225543.10550998,
      btcVolume: 8824.314357109533,
      usdVolume: 69225543.10550998,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '102.2000000000000000',
      high: '105.2100000000000000',
      low: '100.1100000000000000',
      close: '103.6600000000000000',
      volume: '379894.4448300000000000',
      quoteVolume: 39022877.25634307,
      btcVolume: 5026.87552023653,
      usdVolume: 39022877.25634307,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '103.6100000000000000',
      high: '112.0700000000000000',
      low: '101.0000000000000000',
      close: '111.1900000000000000',
      volume: '488706.9407100000000000',
      quoteVolume: 51465078.74484297,
      btcVolume: 6669.936853023607,
      usdVolume: 51465078.74484297,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '111.2500000000000000',
      high: '121.0000000000000000',
      low: '109.5300000000000000',
      close: '116.7100000000000000',
      volume: '619673.8448900000000000',
      quoteVolume: 71546455.96240085,
      btcVolume: 9005.230709084188,
      usdVolume: 71546455.96240085,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '116.7400000000000000',
      high: '119.7000000000000000',
      low: '113.5000000000000000',
      close: '118.0100000000000000',
      volume: '454619.1137200000000000',
      quoteVolume: 52877073.47281,
      btcVolume: 6681.652583502133,
      usdVolume: 52877073.47281,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '118.0100000000000000',
      high: '118.9400000000000000',
      low: '111.0200000000000000',
      close: '114.6600000000000000',
      volume: '425242.5817000000000000',
      quoteVolume: 48979562.40265552,
      btcVolume: 6336.33411727269,
      usdVolume: 48979562.40265552,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '114.6600000000000000',
      high: '130.1200000000000000',
      low: '112.5400000000000000',
      close: '129.1600000000000000',
      volume: '793131.5001000000000000',
      quoteVolume: 97858566.95458615,
      btcVolume: 12435.906067485123,
      usdVolume: 97858566.95458615,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '129.1500000000000000',
      high: '138.5600000000000000',
      low: '124.8500000000000000',
      close: '135.6900000000000000',
      volume: '832736.8101700000000000',
      quoteVolume: 108840543.08616316,
      btcVolume: 13831.644364595131,
      usdVolume: 108840543.08616316,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '135.7900000000000000',
      high: '143.1900000000000000',
      low: '129.8600000000000000',
      close: '135.3200000000000000',
      volume: '1013528.9184000000000000',
      quoteVolume: 139315210.82220733,
      btcVolume: 17393.1824521332,
      usdVolume: 139315210.82220733,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '135.3200000000000000',
      high: '136.5200000000000000',
      low: '127.9200000000000000',
      close: '130.5500000000000000',
      volume: '477007.3052300000000000',
      quoteVolume: 63425735.66279704,
      btcVolume: 7773.751516352979,
      usdVolume: 63425735.66279704,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '130.5500000000000000',
      high: '133.0300000000000000',
      low: '125.2200000000000000',
      close: '132.0000000000000000',
      volume: '460272.4765100000000000',
      quoteVolume: 59616339.318167776,
      btcVolume: 7149.36358649792,
      usdVolume: 59616339.318167776,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '132.0300000000000000',
      high: '139.5000000000000000',
      low: '132.0100000000000000',
      close: '137.5400000000000000',
      volume: '389609.6416600000000000',
      quoteVolume: 52876679.67863012,
      btcVolume: 6089.583260704667,
      usdVolume: 52876679.67863012,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '137.5100000000000000',
      high: '138.2000000000000000',
      low: '133.1500000000000000',
      close: '136.4600000000000000',
      volume: '392160.2389100000000000',
      quoteVolume: 53217106.12389209,
      btcVolume: 5876.901296371626,
      usdVolume: 53217106.12389209,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '136.4500000000000000',
      high: '136.8200000000000000',
      low: '131.5300000000000000',
      close: '133.8000000000000000',
      volume: '302482.8519500000000000',
      quoteVolume: 40631956.395158365,
      btcVolume: 4412.679894293252,
      usdVolume: 40631956.395158365,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '133.8000000000000000',
      high: '136.8400000000000000',
      low: '128.0000000000000000',
      close: '135.2200000000000000',
      volume: '400793.1621100000000000',
      quoteVolume: 53271407.49490323,
      btcVolume: 5834.779980955947,
      usdVolume: 53232164.38223043,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '135.2500000000000000',
      high: '139.5000000000000000',
      low: '133.5000000000000000',
      close: '136.4300000000000000',
      volume: '373848.4380200000000000',
      quoteVolume: 51215339.67678405,
      btcVolume: 5598.390589121898,
      usdVolume: 51215339.67678405,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '136.4100000000000000',
      high: '136.7500000000000000',
      low: '133.0000000000000000',
      close: '135.4600000000000000',
      volume: '226900.6498000000000000',
      quoteVolume: 30613327.430043694,
      btcVolume: 3276.5296546410664,
      usdVolume: 30613327.430043694,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '135.4500000000000000',
      high: '141.2900000000000000',
      low: '131.0000000000000000',
      close: '138.2400000000000000',
      volume: '392322.7834100000000000',
      quoteVolume: 53942344.98886258,
      btcVolume: 5520.032818796717,
      usdVolume: 53942344.98886258,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '138.2400000000000000',
      high: '145.9000000000000000',
      low: '136.0100000000000000',
      close: '142.2600000000000000',
      volume: '663584.3836600000000000',
      quoteVolume: 93771724.38599907,
      btcVolume: 8742.486600283723,
      usdVolume: 93771724.38599907,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '142.2000000000000000',
      high: '143.3400000000000000',
      low: '136.1500000000000000',
      close: '137.5000000000000000',
      volume: '272572.6124300000000000',
      quoteVolume: 38252959.48642319,
      btcVolume: 3516.3861683369537,
      usdVolume: 38252959.48642319,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '137.5000000000000000',
      high: '137.5200000000000000',
      low: '132.6500000000000000',
      close: '135.7700000000000000',
      volume: '269139.9196400000000000',
      quoteVolume: 36393766.969366886,
      btcVolume: 3342.376735778028,
      usdVolume: 36393766.969366886,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '135.7800000000000000',
      high: '138.6300000000000000',
      low: '129.7500000000000000',
      close: '136.2900000000000000',
      volume: '342770.9536500000000000',
      quoteVolume: 46148912.96185091,
      btcVolume: 4050.110257770818,
      usdVolume: 46148912.96185091,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '136.3300000000000000',
      high: '138.5000000000000000',
      low: '127.5000000000000000',
      close: '132.2500000000000000',
      volume: '636720.2570400000000000',
      quoteVolume: 86027810.12377585,
      btcVolume: 6747.88318704931,
      usdVolume: 86027810.12377585,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '132.2400000000000000',
      high: '133.5000000000000000',
      low: '111.8200000000000000',
      close: '116.0100000000000000',
      volume: '925760.4631400000000000',
      quoteVolume: 110853611.31913063,
      btcVolume: 9337.051184083171,
      usdVolume: 110853611.31913063,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '116.0200000000000000',
      high: '122.1900000000000000',
      low: '114.4900000000000000',
      close: '120.0100000000000000',
      volume: '455533.6026900000000000',
      quoteVolume: 53918279.744928494,
      btcVolume: 4582.2478046452115,
      usdVolume: 53918279.744928494,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '119.9900000000000000',
      high: '137.0000000000000000',
      low: '116.0000000000000000',
      close: '133.8600000000000000',
      volume: '650765.6611200000000000',
      quoteVolume: 82923808.74529916,
      btcVolume: 6965.579657418369,
      usdVolume: 82923808.74529916,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '133.8800000000000000',
      high: '137.4400000000000000',
      low: '120.5400000000000000',
      close: '123.2000000000000000',
      volume: '808624.4477500000000000',
      quoteVolume: 105465556.33002208,
      btcVolume: 9174.964797036842,
      usdVolume: 105465556.33002208,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '123.2100000000000000',
      high: '127.0300000000000000',
      low: '113.8200000000000000',
      close: '123.1700000000000000',
      volume: '612082.5504700000000000',
      quoteVolume: 74031245.77021483,
      btcVolume: 6937.17995542507,
      usdVolume: 74031245.77021483,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '123.1600000000000000',
      high: '124.0000000000000000',
      low: '112.6300000000000000',
      close: '118.7700000000000000',
      volume: '468821.0334100000000000',
      quoteVolume: 54961125.00467627,
      btcVolume: 5357.843844817624,
      usdVolume: 54961125.00467627,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '118.7400000000000000',
      high: '123.1500000000000000',
      low: '116.7800000000000000',
      close: '121.3600000000000000',
      volume: '365004.8130000000000000',
      quoteVolume: 43802919.31616289,
      btcVolume: 3864.830877205342,
      usdVolume: 43802919.31616289,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '121.3400000000000000',
      high: '127.0300000000000000',
      low: '118.5100000000000000',
      close: '119.6100000000000000',
      volume: '374342.5657700000000000',
      quoteVolume: 45835115.28303999,
      btcVolume: 3922.4531882102947,
      usdVolume: 45835115.28303999,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '119.6400000000000000',
      high: '120.9900000000000000',
      low: '116.2200000000000000',
      close: '118.3500000000000000',
      volume: '223506.3921400000000000',
      quoteVolume: 26561837.46533212,
      btcVolume: 2393.4466400120245,
      usdVolume: 26561837.46533212,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '118.2900000000000000',
      high: '121.8000000000000000',
      low: '117.0600000000000000',
      close: '118.3100000000000000',
      volume: '153807.7581500000000000',
      quoteVolume: 18322698.17243,
      btcVolume: 1610.505545271505,
      usdVolume: 18322698.17243,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '118.2800000000000000',
      high: '120.3500000000000000',
      low: '117.0000000000000000',
      close: '119.4800000000000000',
      volume: '142378.6142900000000000',
      quoteVolume: 16885095.364536773,
      btcVolume: 1493.6997705844158,
      usdVolume: 16885095.364536773,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '119.4800000000000000',
      high: '125.0700000000000000',
      low: '117.7700000000000000',
      close: '122.9200000000000000',
      volume: '217712.8518000000000000',
      quoteVolume: 26152584.18533981,
      btcVolume: 2213.2029853237336,
      usdVolume: 26152584.18533981,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '122.9200000000000000',
      high: '124.0800000000000000',
      low: '117.6400000000000000',
      close: '119.2400000000000000',
      volume: '229937.7747500000000000',
      quoteVolume: 27739458.330567557,
      btcVolume: 2229.1310227514605,
      usdVolume: 27739458.330567557,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '119.2400000000000000',
      high: '120.1300000000000000',
      low: '102.5000000000000000',
      close: '108.3100000000000000',
      volume: '505822.3816400000000000',
      quoteVolume: 55612005.274497144,
      btcVolume: 4459.300542159586,
      usdVolume: 55612005.274497144,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '108.2900000000000000',
      high: '108.3300000000000000',
      low: '95.2100000000000000',
      close: '102.9800000000000000',
      volume: '559700.8997700000000000',
      quoteVolume: 56859127.19466299,
      btcVolume: 4940.505976488649,
      usdVolume: 56859127.19466299,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '103.0000000000000000',
      high: '107.0200000000000000',
      low: '100.6500000000000000',
      close: '104.8300000000000000',
      volume: '204844.2164100000000000',
      quoteVolume: 21371324.036817204,
      btcVolume: 1850.9778044022698,
      usdVolume: 21371324.036817204,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '104.8400000000000000',
      high: '105.0300000000000000',
      low: '97.6400000000000000',
      close: '101.0300000000000000',
      volume: '229334.5614600000000000',
      quoteVolume: 23264553.92123755,
      btcVolume: 2051.3447663181432,
      usdVolume: 23264553.92123755,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '101.0600000000000000',
      high: '101.9000000000000000',
      low: '87.5800000000000000',
      close: '89.7300000000000000',
      volume: '423313.0351200000000000',
      quoteVolume: 39325264.58610615,
      btcVolume: 3681.02471090942,
      usdVolume: 39325264.58610615,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '89.6800000000000000',
      high: '92.9900000000000000',
      low: '84.6000000000000000',
      close: '90.4300000000000000',
      volume: '382007.6629800000000000',
      quoteVolume: 34014962.47825661,
      btcVolume: 3290.421882815431,
      usdVolume: 34014962.47825661,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '90.4300000000000000',
      high: '91.9100000000000000',
      low: '76.6000000000000000',
      close: '79.5600000000000000',
      volume: '476050.7487700000000000',
      quoteVolume: 39592816.252067514,
      btcVolume: 3948.301710139775,
      usdVolume: 39592816.252067514,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '79.5700000000000000',
      high: '94.6500000000000000',
      low: '71.0000000000000000',
      close: '89.9500000000000000',
      volume: '611087.7265300000000000',
      quoteVolume: 53122108.969450906,
      btcVolume: 5523.905454198043,
      usdVolume: 53122108.969450906,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '89.9800000000000000',
      high: '104.5000000000000000',
      low: '86.8700000000000000',
      close: '101.1600000000000000',
      volume: '608935.5958700000000000',
      quoteVolume: 58255818.192272015,
      btcVolume: 5773.175422048092,
      usdVolume: 58215321.1838005,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '101.1500000000000000',
      high: '101.2600000000000000',
      low: '94.7300000000000000',
      close: '98.2300000000000000',
      volume: '364731.5720400000000000',
      quoteVolume: 35601483.447144754,
      btcVolume: 3411.5750330938563,
      usdVolume: 35601483.447144754,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '98.2500000000000000',
      high: '105.8800000000000000',
      low: '97.4200000000000000',
      close: '100.2500000000000000',
      volume: '355101.6718200000000000',
      quoteVolume: 35894490.36556177,
      btcVolume: 3363.2675353843997,
      usdVolume: 35894490.36556177,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '100.2600000000000000',
      high: '101.4100000000000000',
      low: '96.4000000000000000',
      close: '99.7000000000000000',
      volume: '247040.7271900000000000',
      quoteVolume: 24369602.513854142,
      btcVolume: 2307.538644188406,
      usdVolume: 24369602.513854142,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '99.7400000000000000',
      high: '100.9100000000000000',
      low: '91.1400000000000000',
      close: '95.3100000000000000',
      volume: '316815.0448700000000000',
      quoteVolume: 30213787.40266795,
      btcVolume: 2901.236765354251,
      usdVolume: 30213787.40266795,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '95.3100000000000000',
      high: '95.3500000000000000',
      low: '88.6200000000000000',
      close: '90.1500000000000000',
      volume: '264516.2157400000000000',
      quoteVolume: 24185689.349569995,
      btcVolume: 2407.8084257111295,
      usdVolume: 24185689.349569995,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '90.1000000000000000',
      high: '97.4500000000000000',
      low: '86.8000000000000000',
      close: '94.5000000000000000',
      volume: '354766.4814900000000000',
      quoteVolume: 32545800.829587497,
      btcVolume: 3344.4513821679425,
      usdVolume: 32545800.829587497,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '94.4300000000000000',
      high: '96.6700000000000000',
      low: '91.0600000000000000',
      close: '93.2200000000000000',
      volume: '228174.7700300000000000',
      quoteVolume: 21502628.636138897,
      btcVolume: 2150.016682373243,
      usdVolume: 21502628.636138897,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '93.2300000000000000',
      high: '94.5200000000000000',
      low: '89.8300000000000000',
      close: '94.0600000000000000',
      volume: '169001.4772100000000000',
      quoteVolume: 15639015.230352713,
      btcVolume: 1602.9055728539722,
      usdVolume: 15639015.230352713,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '94.0100000000000000',
      high: '96.0900000000000000',
      low: '86.6700000000000000',
      close: '88.7900000000000000',
      volume: '246284.6023800000000000',
      quoteVolume: 22269324.203205597,
      btcVolume: 2305.886333888476,
      usdVolume: 22269324.203205597,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '88.8300000000000000',
      high: '90.9000000000000000',
      low: '83.1300000000000000',
      close: '89.9300000000000000',
      volume: '196819.9021300000000000',
      quoteVolume: 17358318.803394448,
      btcVolume: 1837.2083760848138,
      usdVolume: 17358318.803394448,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '89.9700000000000000',
      high: '92.2600000000000000',
      low: '87.4800000000000000',
      close: '90.3300000000000000',
      volume: '232648.9633600000000000',
      quoteVolume: 20980770.098834805,
      btcVolume: 2194.949487131651,
      usdVolume: 20980770.098834805,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '90.3300000000000000',
      high: '92.7100000000000000',
      low: '88.5000000000000000',
      close: '90.1500000000000000',
      volume: '132728.7110700000000000',
      quoteVolume: 11999589.695907073,
      btcVolume: 1256.76640132148,
      usdVolume: 11999589.695907073,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '90.1200000000000000',
      high: '99.9500000000000000',
      low: '89.8100000000000000',
      close: '98.7600000000000000',
      volume: '280442.0190700000000000',
      quoteVolume: 26802062.262311406,
      btcVolume: 2708.4298976866316,
      usdVolume: 26802062.262311406,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '98.7700000000000000',
      high: '100.5900000000000000',
      low: '95.2300000000000000',
      close: '98.6400000000000000',
      volume: '256621.8002700000000000',
      quoteVolume: 25131509.021462236,
      btcVolume: 2491.621683425381,
      usdVolume: 25131509.021462236,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '98.6200000000000000',
      high: '98.7700000000000000',
      low: '92.5200000000000000',
      close: '94.7600000000000000',
      volume: '284185.5684900000000000',
      quoteVolume: 27263314.77004936,
      btcVolume: 2606.5500325395974,
      usdVolume: 27263314.77004936,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '94.8000000000000000',
      high: '96.7000000000000000',
      low: '93.5100000000000000',
      close: '94.1700000000000000',
      volume: '187442.2663600000000000',
      quoteVolume: 17836682.37128238,
      btcVolume: 1658.7354555809563,
      usdVolume: 17836682.37128238,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '94.1500000000000000',
      high: '94.8000000000000000',
      low: '90.1100000000000000',
      close: '92.6400000000000000',
      volume: '209831.4188500000000000',
      quoteVolume: 19390235.069303315,
      btcVolume: 1799.4440000625016,
      usdVolume: 19390235.069303315,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '92.6800000000000000',
      high: '106.7700000000000000',
      low: '92.6800000000000000',
      close: '96.6100000000000000',
      volume: '707982.8941800000000000',
      quoteVolume: 70027160.96589696,
      btcVolume: 5999.5126965229465,
      usdVolume: 70027160.96589696,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '96.5600000000000000',
      high: '99.3000000000000000',
      low: '91.0000000000000000',
      close: '92.9900000000000000',
      volume: '363691.9904700000000000',
      quoteVolume: 34611183.819958195,
      btcVolume: 2931.27156672291,
      usdVolume: 34611183.819958195,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '93.0500000000000000',
      high: '94.4500000000000000',
      low: '87.3600000000000000',
      close: '90.5500000000000000',
      volume: '295995.6655100000000000',
      quoteVolume: 26997231.240942493,
      btcVolume: 2299.5786668850474,
      usdVolume: 26997231.240942493,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '90.5900000000000000',
      high: '92.7500000000000000',
      low: '87.7200000000000000',
      close: '90.1400000000000000',
      volume: '226973.5770400000000000',
      quoteVolume: 20399520.9403848,
      btcVolume: 1729.0445142042847,
      usdVolume: 20399520.9403848,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '90.1400000000000000',
      high: '90.4500000000000000',
      low: '82.6400000000000000',
      close: '84.3300000000000000',
      volume: '244679.6808500000000000',
      quoteVolume: 21052903.37981216,
      btcVolume: 1782.1537264855651,
      usdVolume: 21052903.37981216,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '84.2500000000000000',
      high: '87.5000000000000000',
      low: '82.2700000000000000',
      close: '85.4600000000000000',
      volume: '240013.8068600000000000',
      quoteVolume: 20434411.59965533,
      btcVolume: 1765.6610402914791,
      usdVolume: 20434411.59965533,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '85.4600000000000000',
      high: '91.5700000000000000',
      low: '85.1500000000000000',
      close: '89.9400000000000000',
      volume: '196224.6058500000000000',
      quoteVolume: 17374539.532690413,
      btcVolume: 1524.9630326426839,
      usdVolume: 17374539.532690413,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '89.9200000000000000',
      high: '90.0400000000000000',
      low: '85.4500000000000000',
      close: '85.7900000000000000',
      volume: '148132.4291500000000000',
      quoteVolume: 12892549.668831954,
      btcVolume: 1130.0638225098155,
      usdVolume: 12892549.668831954,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '85.7900000000000000',
      high: '86.7900000000000000',
      low: '83.2600000000000000',
      close: '84.8800000000000000',
      volume: '166783.8095700000000000',
      quoteVolume: 14166418.159683961,
      btcVolume: 1274.1904971222834,
      usdVolume: 14166418.159683961,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '84.8300000000000000',
      high: '86.3200000000000000',
      low: '75.0000000000000000',
      close: '75.9000000000000000',
      volume: '301247.4537300000000000',
      quoteVolume: 24226106.115131296,
      btcVolume: 2325.4823677043105,
      usdVolume: 24226106.115131296,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '75.9100000000000000',
      high: '77.7000000000000000',
      low: '74.3600000000000000',
      close: '76.2500000000000000',
      volume: '155744.5086700000000000',
      quoteVolume: 11881666.830015346,
      btcVolume: 1170.7221866608465,
      usdVolume: 11881666.830015346,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '76.2400000000000000',
      high: '76.4200000000000000',
      low: '72.3400000000000000',
      close: '74.6700000000000000',
      volume: '186709.0272800000000000',
      quoteVolume: 13900659.419270849,
      btcVolume: 1370.0754829605883,
      usdVolume: 13900659.419270849,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '74.6300000000000000',
      high: '75.3500000000000000',
      low: '71.5000000000000000',
      close: '72.6500000000000000',
      volume: '131037.3773100000000000',
      quoteVolume: 9606480.326331407,
      btcVolume: 935.0616632613101,
      usdVolume: 9606480.326331407,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '72.6400000000000000',
      high: '78.0000000000000000',
      low: '71.4000000000000000',
      close: '76.2500000000000000',
      volume: '171758.4529300000000000',
      quoteVolume: 12942681.697281959,
      btcVolume: 1254.3177248522557,
      usdVolume: 12942681.697281959,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '76.3100000000000000',
      high: '78.9500000000000000',
      low: '75.3600000000000000',
      close: '77.1000000000000000',
      volume: '176320.8779000000000000',
      quoteVolume: 13602703.9572976,
      btcVolume: 1278.3583112223776,
      usdVolume: 13602703.9572976,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '77.1000000000000000',
      high: '77.3000000000000000',
      low: '73.3100000000000000',
      close: '75.1400000000000000',
      volume: '130272.1040200000000000',
      quoteVolume: 9809533.243689496,
      btcVolume: 914.3887166901854,
      usdVolume: 9809533.243689496,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '75.1300000000000000',
      high: '75.3300000000000000',
      low: '70.1400000000000000',
      close: '73.2700000000000000',
      volume: '188369.4364000000000000',
      quoteVolume: 13677422.750244448,
      btcVolume: 1345.3220954490534,
      usdVolume: 13677422.750244448,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '73.1800000000000000',
      high: '74.6400000000000000',
      low: '71.0000000000000000',
      close: '73.3100000000000000',
      volume: '159574.3573900000000000',
      quoteVolume: 11682660.11134095,
      btcVolume: 1163.5406280504114,
      usdVolume: 11682660.11134095,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '73.3800000000000000',
      high: '75.9200000000000000',
      low: '72.1000000000000000',
      close: '75.3800000000000000',
      volume: '126538.7728700000000000',
      quoteVolume: 9392367.036347631,
      btcVolume: 914.8262129553217,
      usdVolume: 9392367.036347631,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '75.3700000000000000',
      high: '75.6500000000000000',
      low: '71.3900000000000000',
      close: '73.3200000000000000',
      volume: '119835.6546400000000000',
      quoteVolume: 8766693.140755767,
      btcVolume: 866.621719030702,
      usdVolume: 8766693.140755767,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '73.3200000000000000',
      high: '74.9300000000000000',
      low: '70.5600000000000000',
      close: '72.1000000000000000',
      volume: '141416.0203900000000000',
      quoteVolume: 10265920.916051323,
      btcVolume: 1018.3454425401993,
      usdVolume: 10265920.916051323,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '72.1200000000000000',
      high: '75.4600000000000000',
      low: '72.0900000000000000',
      close: '73.5400000000000000',
      volume: '183788.5068600000000000',
      quoteVolume: 13607022.423831994,
      btcVolume: 1313.6613691337795,
      usdVolume: 13607022.423831994,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '73.5900000000000000',
      high: '73.7500000000000000',
      low: '72.1300000000000000',
      close: '72.9000000000000000',
      volume: '92746.0175100000000000',
      quoteVolume: 6757231.102277973,
      btcVolume: 662.8619923714382,
      usdVolume: 6757231.102277973,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '72.8800000000000000',
      high: '73.1400000000000000',
      low: '65.0000000000000000',
      close: '66.7300000000000000',
      volume: '279997.3057300000000000',
      quoteVolume: 19218269.137130044,
      btcVolume: 1926.7857817998536,
      usdVolume: 19218269.137130044,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '66.7300000000000000',
      high: '66.7700000000000000',
      low: '62.0000000000000000',
      close: '63.5900000000000000',
      volume: '206125.3285400000000000',
      quoteVolume: 13327397.63727353,
      btcVolume: 1401.9829055159644,
      usdVolume: 13327397.63727353,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '63.5500000000000000',
      high: '65.6000000000000000',
      low: '62.1600000000000000',
      close: '64.1700000000000000',
      volume: '188502.6473100000000000',
      quoteVolume: 12025107.367332652,
      btcVolume: 1260.478351604105,
      usdVolume: 12025107.367332652,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '64.1800000000000000',
      high: '65.3900000000000000',
      low: '62.2500000000000000',
      close: '64.2400000000000000',
      volume: '148167.1587700000000000',
      quoteVolume: 9443772.20454113,
      btcVolume: 985.5488740690859,
      usdVolume: 9443772.20454113,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '64.2300000000000000',
      high: '67.2100000000000000',
      low: '63.7400000000000000',
      close: '65.8900000000000000',
      volume: '160360.7877400000000000',
      quoteVolume: 10473417.107566353,
      btcVolume: 1088.2357260615822,
      usdVolume: 10473417.107566353,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '65.8500000000000000',
      high: '68.0300000000000000',
      low: '64.8000000000000000',
      close: '66.9200000000000000',
      volume: '210394.3789600000000000',
      quoteVolume: 13944978.885087235,
      btcVolume: 1390.3786949813993,
      usdVolume: 13944978.885087235,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '66.9200000000000000',
      high: '71.0000000000000000',
      low: '66.4700000000000000',
      close: '69.0600000000000000',
      volume: '285573.8842600000000000',
      quoteVolume: 19591536.12131445,
      btcVolume: 1861.1902617597352,
      usdVolume: 19591536.12131445,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '69.0000000000000000',
      high: '69.2400000000000000',
      low: '66.4000000000000000',
      close: '67.0100000000000000',
      volume: '219414.7318900000000000',
      quoteVolume: 14835718.502787702,
      btcVolume: 1407.2822863278584,
      usdVolume: 14835718.502787702,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '67.0200000000000000',
      high: '67.3900000000000000',
      low: '64.1400000000000000',
      close: '65.2400000000000000',
      volume: '165280.2625800000000000',
      quoteVolume: 10833905.336375384,
      btcVolume: 1026.921360854257,
      usdVolume: 10833905.336375384,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '65.2300000000000000',
      high: '68.0000000000000000',
      low: '63.5400000000000000',
      close: '64.8700000000000000',
      volume: '269355.4199300000000000',
      quoteVolume: 17769105.790459905,
      btcVolume: 1683.5510553061292,
      usdVolume: 17769105.790459905,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '64.8800000000000000',
      high: '70.5700000000000000',
      low: '64.5300000000000000',
      close: '68.7800000000000000',
      volume: '305074.1416000000000000',
      quoteVolume: 20786479.847835682,
      btcVolume: 1993.4143229328859,
      usdVolume: 20786479.847835682,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '68.7700000000000000',
      high: '71.4600000000000000',
      low: '68.0000000000000000',
      close: '70.3600000000000000',
      volume: '313588.1220800000000000',
      quoteVolume: 21958484.40247299,
      btcVolume: 2102.4332066868055,
      usdVolume: 21958484.40247299,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '70.3600000000000000',
      high: '71.3800000000000000',
      low: '67.7300000000000000',
      close: '69.8900000000000000',
      volume: '227135.8567900000000000',
      quoteVolume: 15787764.06820893,
      btcVolume: 1535.4445818677495,
      usdVolume: 15787764.06820893,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '69.8600000000000000',
      high: '73.1000000000000000',
      low: '69.7700000000000000',
      close: '70.8500000000000000',
      volume: '315418.1644100000000000',
      quoteVolume: 22463397.73757165,
      btcVolume: 2195.2854664792503,
      usdVolume: 22463397.73757165,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '70.8500000000000000',
      high: '71.8100000000000000',
      low: '68.2500000000000000',
      close: '69.8000000000000000',
      volume: '208661.7044600000000000',
      quoteVolume: 14616150.160225926,
      btcVolume: 1453.8574985722253,
      usdVolume: 14616150.160225926,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '69.8000000000000000',
      high: '70.0000000000000000',
      low: '68.3000000000000000',
      close: '69.1500000000000000',
      volume: '135127.9931800000000000',
      quoteVolume: 9339546.336077025,
      btcVolume: 912.766277649649,
      usdVolume: 9339546.336077025,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '69.1800000000000000',
      high: '69.5800000000000000',
      low: '67.3600000000000000',
      close: '68.9300000000000000',
      volume: '156416.2924400000000000',
      quoteVolume: 10722817.409428386,
      btcVolume: 1041.7990264891057,
      usdVolume: 10722817.409428386,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '68.9300000000000000',
      high: '71.5000000000000000',
      low: '68.2000000000000000',
      close: '70.5000000000000000',
      volume: '178914.2774100000000000',
      quoteVolume: 12510402.533513518,
      btcVolume: 1210.141755485563,
      usdVolume: 12510402.533513518,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '70.4900000000000000',
      high: '71.2800000000000000',
      low: '69.5000000000000000',
      close: '70.0400000000000000',
      volume: '123157.5877600000000000',
      quoteVolume: 8653254.98748152,
      btcVolume: 839.9682939747071,
      usdVolume: 8653254.98748152,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '70.0400000000000000',
      high: '73.6700000000000000',
      low: '69.3600000000000000',
      close: '72.6300000000000000',
      volume: '266145.5193300000000000',
      quoteVolume: 18990638.744624417,
      btcVolume: 1850.305593630325,
      usdVolume: 18990638.744624417,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '72.6700000000000000',
      high: '77.5900000000000000',
      low: '72.1500000000000000',
      close: '74.0400000000000000',
      volume: '331768.0453000000000000',
      quoteVolume: 24570461.734894328,
      btcVolume: 2405.01019871254,
      usdVolume: 24570461.734894328,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '74.0600000000000000',
      high: '80.3000000000000000',
      low: '73.9800000000000000',
      close: '77.8100000000000000',
      volume: '463500.2471200000000000',
      quoteVolume: 35715978.31337182,
      btcVolume: 3505.5073405779085,
      usdVolume: 35715978.31337182,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '77.7700000000000000',
      high: '80.9000000000000000',
      low: '72.6200000000000000',
      close: '76.5500000000000000',
      volume: '501398.1546800000000000',
      quoteVolume: 37733017.621873595,
      btcVolume: 3774.092404497032,
      usdVolume: 37733017.621873595,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '76.5600000000000000',
      high: '77.0800000000000000',
      low: '72.7800000000000000',
      close: '75.0500000000000000',
      volume: '285843.0479300000000000',
      quoteVolume: 21452170.229147196,
      btcVolume: 2108.9030586427175,
      usdVolume: 21452170.229147196,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '75.0600000000000000',
      high: '75.5800000000000000',
      low: '72.0600000000000000',
      close: '73.2200000000000000',
      volume: '211641.7919700000000000',
      quoteVolume: 15603395.96512544,
      btcVolume: 1554.647856825871,
      usdVolume: 15603395.96512544,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '73.2000000000000000',
      high: '73.2200000000000000',
      low: '71.0300000000000000',
      close: '72.2300000000000000',
      volume: '219379.8472300000000000',
      quoteVolume: 15822626.780132813,
      btcVolume: 1585.411547795001,
      usdVolume: 15822626.780132813,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '72.2600000000000000',
      high: '74.2400000000000000',
      low: '61.8800000000000000',
      close: '66.5400000000000000',
      volume: '526339.5301500000000000',
      quoteVolume: 36437108.07404466,
      btcVolume: 3714.5060783639537,
      usdVolume: 36437108.07404466,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '66.5400000000000000',
      high: '67.4200000000000000',
      low: '54.3400000000000000',
      close: '54.5700000000000000',
      volume: '896194.8912400000000000',
      quoteVolume: 54840616.36898153,
      btcVolume: 6077.979287792019,
      usdVolume: 54840616.36898153,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '54.5700000000000000',
      high: '58.3400000000000000',
      low: '52.8400000000000000',
      close: '57.4300000000000000',
      volume: '780933.6156200000000000',
      quoteVolume: 43514734.915106036,
      btcVolume: 5172.0466578427,
      usdVolume: 43514734.915106036,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '57.4800000000000000',
      high: '57.5600000000000000',
      low: '50.3000000000000000',
      close: '55.1900000000000000',
      volume: '620655.1100700000000000',
      quoteVolume: 33894831.566069685,
      btcVolume: 4162.554218117035,
      usdVolume: 33894831.566069685,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '55.1700000000000000',
      high: '56.6100000000000000',
      low: '52.6100000000000000',
      close: '55.8600000000000000',
      volume: '390319.7644900000000000',
      quoteVolume: 21346773.06550875,
      btcVolume: 2660.2624788155385,
      usdVolume: 21346773.06550875,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '55.8600000000000000',
      high: '56.1600000000000000',
      low: '53.6700000000000000',
      close: '55.3700000000000000',
      volume: '256916.9284600000000000',
      quoteVolume: 14172326.366005592,
      btcVolume: 1741.149072225589,
      usdVolume: 14172326.366005592,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '55.3700000000000000',
      high: '55.5800000000000000',
      low: '52.1300000000000000',
      close: '53.8200000000000000',
      volume: '227396.1860800000000000',
      quoteVolume: 12189476.348992856,
      btcVolume: 1518.3799061716697,
      usdVolume: 12189476.348992856,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '53.8400000000000000',
      high: '56.2500000000000000',
      low: '52.4600000000000000',
      close: '55.9900000000000000',
      volume: '369529.6878700000000000',
      quoteVolume: 20182788.05999346,
      btcVolume: 2510.805443251447,
      usdVolume: 20182788.05999346,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '56.0100000000000000',
      high: '57.6000000000000000',
      low: '54.8200000000000000',
      close: '55.6300000000000000',
      volume: '333516.6371400000000000',
      quoteVolume: 18701415.035006244,
      btcVolume: 2241.634536922254,
      usdVolume: 18701415.035006244,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '55.5900000000000000',
      high: '56.6600000000000000',
      low: '55.1300000000000000',
      close: '56.2300000000000000',
      volume: '215239.2591000000000000',
      quoteVolume: 12024564.531152936,
      btcVolume: 1459.108573099224,
      usdVolume: 12024564.531152936,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '56.2800000000000000',
      high: '57.0700000000000000',
      low: '53.6200000000000000',
      close: '56.4400000000000000',
      volume: '253120.5364200000000000',
      quoteVolume: 14050677.75934256,
      btcVolume: 1717.4520781431509,
      usdVolume: 14050677.75934256,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '56.4500000000000000',
      high: '57.5000000000000000',
      low: '55.2200000000000000',
      close: '56.3000000000000000',
      volume: '247079.7704500000000000',
      quoteVolume: 13984268.782087287,
      btcVolume: 1718.1473442856827,
      usdVolume: 13984268.782087287,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '56.3000000000000000',
      high: '57.4000000000000000',
      low: '55.5700000000000000',
      close: '56.6900000000000000',
      volume: '177602.1005800000000000',
      quoteVolume: 10003772.607079951,
      btcVolume: 1237.007987145328,
      usdVolume: 10003772.607079951,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '56.6900000000000000',
      high: '56.8000000000000000',
      low: '52.9500000000000000',
      close: '54.4100000000000000',
      volume: '255560.2861700000000000',
      quoteVolume: 14075211.354071734,
      btcVolume: 1771.6354364948543,
      usdVolume: 14075211.354071734,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '54.4200000000000000',
      high: '58.6500000000000000',
      low: '53.2500000000000000',
      close: '57.4600000000000000',
      volume: '314651.2758500000000000',
      quoteVolume: 17736478.819784664,
      btcVolume: 2198.803572764758,
      usdVolume: 17736478.819784664,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '57.4600000000000000',
      high: '58.4500000000000000',
      low: '56.4700000000000000',
      close: '56.9500000000000000',
      volume: '184191.0427500000000000',
      quoteVolume: 10558792.262372462,
      btcVolume: 1286.8688325201551,
      usdVolume: 10558792.262372462,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '56.9500000000000000',
      high: '60.0600000000000000',
      low: '56.6000000000000000',
      close: '59.1200000000000000',
      volume: '337686.6303300000000000',
      quoteVolume: 19730452.316288013,
      btcVolume: 2355.7027861823267,
      usdVolume: 19730452.316288013,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '59.1000000000000000',
      high: '59.3300000000000000',
      low: '57.0000000000000000',
      close: '57.4700000000000000',
      volume: '215031.0358800000000000',
      quoteVolume: 12487431.424961781,
      btcVolume: 1463.474709248515,
      usdVolume: 12487431.424961781,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '57.4800000000000000',
      high: '58.4200000000000000',
      low: '55.3300000000000000',
      close: '55.8500000000000000',
      volume: '265707.6043700000000000',
      quoteVolume: 15003032.363513099,
      btcVolume: 1779.3269982097474,
      usdVolume: 15003032.363513099,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '55.8500000000000000',
      high: '56.5000000000000000',
      low: '55.4100000000000000',
      close: '55.7000000000000000',
      volume: '101055.5079000000000000',
      quoteVolume: 5657808.268919437,
      btcVolume: 679.6634476450376,
      usdVolume: 5657808.268919437,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '55.7100000000000000',
      high: '57.6900000000000000',
      low: '55.4700000000000000',
      close: '56.4600000000000000',
      volume: '157772.3878500000000000',
      quoteVolume: 8939980.18918927,
      btcVolume: 1071.554187571745,
      usdVolume: 8939980.18918927,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '56.4400000000000000',
      high: '57.1000000000000000',
      low: '55.9400000000000000',
      close: '56.8300000000000000',
      volume: '133356.2528900000000000',
      quoteVolume: 7554339.147984878,
      btcVolume: 910.4939564933641,
      usdVolume: 7554339.147984878,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '56.8300000000000000',
      high: '57.3900000000000000',
      low: '53.4000000000000000',
      close: '54.5800000000000000',
      volume: '329395.4878400000000000',
      quoteVolume: 18207494.81235211,
      btcVolume: 2200.181196563216,
      usdVolume: 18207494.81235211,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '54.5700000000000000',
      high: '55.3900000000000000',
      low: '51.0000000000000000',
      close: '52.4500000000000000',
      volume: '297730.1499600000000000',
      quoteVolume: 15783948.682937246,
      btcVolume: 1965.4916381767648,
      usdVolume: 15783948.682937246,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '52.4500000000000000',
      high: '55.2200000000000000',
      low: '51.5700000000000000',
      close: '54.9400000000000000',
      volume: '238776.4036000000000000',
      quoteVolume: 12771309.090288963,
      btcVolume: 1589.7400371088647,
      usdVolume: 12771309.090288963,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '54.9500000000000000',
      high: '55.0100000000000000',
      low: '52.1900000000000000',
      close: '53.2100000000000000',
      volume: '212503.0760200000000000',
      quoteVolume: 11342598.709450595,
      btcVolume: 1428.7754993429342,
      usdVolume: 11342598.709450595,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '53.2100000000000000',
      high: '54.1600000000000000',
      low: '52.5400000000000000',
      close: '53.7200000000000000',
      volume: '152608.9214200000000000',
      quoteVolume: 8152589.987414735,
      btcVolume: 1023.959008729595,
      usdVolume: 8152589.987414735,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '53.7200000000000000',
      high: '55.4600000000000000',
      low: '52.5800000000000000',
      close: '54.8500000000000000',
      volume: '208172.7019100000000000',
      quoteVolume: 11213347.343628593,
      btcVolume: 1399.9846240450204,
      usdVolume: 11213347.343628593,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '54.8500000000000000',
      high: '55.7400000000000000',
      low: '53.6700000000000000',
      close: '54.5700000000000000',
      volume: '208443.7668600000000000',
      quoteVolume: 11370329.079330875,
      btcVolume: 1383.3455861262153,
      usdVolume: 11370329.079330875,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '54.5700000000000000',
      high: '55.0900000000000000',
      low: '52.9700000000000000',
      close: '53.3900000000000000',
      volume: '159505.0758900000000000',
      quoteVolume: 8659267.226605335,
      btcVolume: 1058.7525600589156,
      usdVolume: 8659267.226605335,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '53.3800000000000000',
      high: '53.4400000000000000',
      low: '47.5300000000000000',
      close: '49.5000000000000000',
      volume: '470048.8542900000000000',
      quoteVolume: 23488078.022841353,
      btcVolume: 3076.301841104244,
      usdVolume: 23488078.022841353,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '49.4800000000000000',
      high: '50.3200000000000000',
      low: '48.3000000000000000',
      close: '49.5400000000000000',
      volume: '308330.5219600000000000',
      quoteVolume: 15231954.366521599,
      btcVolume: 2047.6661467292997,
      usdVolume: 15231954.366521599,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '49.5500000000000000',
      high: '58.7000000000000000',
      low: '49.0100000000000000',
      close: '56.9300000000000000',
      volume: '736498.2495500000000000',
      quoteVolume: 40028251.61986273,
      btcVolume: 4942.253267416375,
      usdVolume: 40028251.61986273,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '56.9300000000000000',
      high: '63.3700000000000000',
      low: '54.1400000000000000',
      close: '56.7100000000000000',
      volume: '1011134.5544100000000000',
      quoteVolume: 58374697.06996285,
      btcVolume: 6236.904792516147,
      usdVolume: 58374697.06996285,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '56.6900000000000000',
      high: '62.8500000000000000',
      low: '55.5900000000000000',
      close: '59.7800000000000000',
      volume: '659707.7341100000000000',
      quoteVolume: 39098216.43731671,
      btcVolume: 4118.114476739268,
      usdVolume: 39098216.43731671,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '59.7900000000000000',
      high: '62.4700000000000000',
      low: '57.4000000000000000',
      close: '57.7000000000000000',
      volume: '539486.7688600000000000',
      quoteVolume: 31869821.10145664,
      btcVolume: 3367.080583556967,
      usdVolume: 31869821.10145664,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '57.7000000000000000',
      high: '60.7500000000000000',
      low: '57.6500000000000000',
      close: '59.7500000000000000',
      volume: '399523.8960500000000000',
      quoteVolume: 23770607.904536624,
      btcVolume: 2536.351568802833,
      usdVolume: 23770607.904536624,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '59.7600000000000000',
      high: '61.0500000000000000',
      low: '56.8800000000000000',
      close: '57.8900000000000000',
      volume: '341531.2851900000000000',
      quoteVolume: 20067287.16194507,
      btcVolume: 2180.7999647255724,
      usdVolume: 20067287.16194507,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '57.9000000000000000',
      high: '59.5600000000000000',
      low: '57.0200000000000000',
      close: '58.2900000000000000',
      volume: '268866.1439300000000000',
      quoteVolume: 15690777.836845942,
      btcVolume: 1711.8755410860422,
      usdVolume: 15690777.836845942,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '58.2900000000000000',
      high: '58.8000000000000000',
      low: '56.6800000000000000',
      close: '58.2300000000000000',
      volume: '221222.3294000000000000',
      quoteVolume: 12800948.223556288,
      btcVolume: 1400.6610473174119,
      usdVolume: 12800948.223556288,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '58.2400000000000000',
      high: '59.1900000000000000',
      low: '57.7300000000000000',
      close: '58.2500000000000000',
      volume: '150629.3718500000000000',
      quoteVolume: 8789951.618953658,
      btcVolume: 947.7305790128487,
      usdVolume: 8789951.618953658,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '58.2400000000000000',
      high: '58.7500000000000000',
      low: '57.1800000000000000',
      close: '58.2900000000000000',
      volume: '141294.2567900000000000',
      quoteVolume: 8186539.4970356515,
      btcVolume: 888.9066093561216,
      usdVolume: 8186539.4970356515,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '58.2900000000000000',
      high: '62.4000000000000000',
      low: '57.8700000000000000',
      close: '61.2900000000000000',
      volume: '407402.9069300000000000',
      quoteVolume: 24699682.594704557,
      btcVolume: 2665.317399877282,
      usdVolume: 24699682.594704557,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '61.2600000000000000',
      high: '64.1500000000000000',
      low: '60.8900000000000000',
      close: '63.1200000000000000',
      volume: '527467.4519600000000000',
      quoteVolume: 33010265.398333196,
      btcVolume: 3542.273311220224,
      usdVolume: 33010265.398333196,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '63.1300000000000000',
      high: '64.3300000000000000',
      low: '62.5600000000000000',
      close: '64.1100000000000000',
      volume: '334742.1432800000000000',
      quoteVolume: 21184873.260657944,
      btcVolume: 2268.6466351593385,
      usdVolume: 21184873.260657944,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '64.1100000000000000',
      high: '64.3300000000000000',
      low: '60.2700000000000000',
      close: '61.3900000000000000',
      volume: '327504.1707300000000000',
      quoteVolume: 20341773.350589715,
      btcVolume: 2200.2788075310846,
      usdVolume: 20341773.350589715,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '61.4000000000000000',
      high: '62.4900000000000000',
      low: '58.9300000000000000',
      close: '60.3800000000000000',
      volume: '345551.4396900000000000',
      quoteVolume: 20960125.60154757,
      btcVolume: 2332.7802751493164,
      usdVolume: 20960125.60154757,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '60.3600000000000000',
      high: '62.3700000000000000',
      low: '60.1700000000000000',
      close: '62.0400000000000000',
      volume: '196430.9663500000000000',
      quoteVolume: 12033723.069156943,
      btcVolume: 1365.127796378876,
      usdVolume: 12033723.069156943,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '62.0400000000000000',
      high: '66.1900000000000000',
      low: '60.8000000000000000',
      close: '63.6500000000000000',
      volume: '428980.9520600000000000',
      quoteVolume: 27218648.713102452,
      btcVolume: 3057.9731993427763,
      usdVolume: 27218648.713102452,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '63.6500000000000000',
      high: '64.3300000000000000',
      low: '60.4700000000000000',
      close: '61.8400000000000000',
      volume: '408664.6897800000000000',
      quoteVolume: 25451946.05196973,
      btcVolume: 2882.6678622354775,
      usdVolume: 25451946.05196973,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '61.8400000000000000',
      high: '62.2500000000000000',
      low: '59.7500000000000000',
      close: '61.4200000000000000',
      volume: '299554.9198200000000000',
      quoteVolume: 18351569.172878858,
      btcVolume: 2100.756847368965,
      usdVolume: 18351569.172878858,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '61.4500000000000000',
      high: '62.0500000000000000',
      low: '60.2700000000000000',
      close: '60.9600000000000000',
      volume: '175581.9554600000000000',
      quoteVolume: 10721665.62536904,
      btcVolume: 1223.0289182322622,
      usdVolume: 10721665.62536904,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '60.9600000000000000',
      high: '61.1000000000000000',
      low: '58.2500000000000000',
      close: '59.0000000000000000',
      volume: '289023.5733800000000000',
      quoteVolume: 17173463.110559292,
      btcVolume: 1981.3152223352774,
      usdVolume: 17173463.110559292,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '59.0000000000000000',
      high: '60.7500000000000000',
      low: '56.2000000000000000',
      close: '57.6000000000000000',
      volume: '331442.8743400000000000',
      quoteVolume: 19338540.966621064,
      btcVolume: 2257.9348903748273,
      usdVolume: 19338540.966621064,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '57.6100000000000000',
      high: '58.9800000000000000',
      low: '57.1400000000000000',
      close: '58.3000000000000000',
      volume: '135649.0746000000000000',
      quoteVolume: 7869798.748988588,
      btcVolume: 927.7112832694024,
      usdVolume: 7869798.748988588,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '58.2900000000000000',
      high: '59.9700000000000000',
      low: '57.5500000000000000',
      close: '59.2200000000000000',
      volume: '189990.6350700000000000',
      quoteVolume: 11229720.96215726,
      btcVolume: 1318.1179952916186,
      usdVolume: 11229720.96215726,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '59.2100000000000000',
      high: '59.3000000000000000',
      low: '54.0000000000000000',
      close: '55.8100000000000000',
      volume: '295818.0605500000000000',
      quoteVolume: 16800996.48111148,
      btcVolume: 2019.3958745827504,
      usdVolume: 16800996.48111148,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '55.8000000000000000',
      high: '56.2300000000000000',
      low: '54.2000000000000000',
      close: '55.1300000000000000',
      volume: '245040.3911600000000000',
      quoteVolume: 13537640.598777153,
      btcVolume: 1669.4864925290328,
      usdVolume: 13537640.598777153,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '55.1300000000000000',
      high: '56.1600000000000000',
      low: '54.6100000000000000',
      close: '54.9800000000000000',
      volume: '224543.3721000000000000',
      quoteVolume: 12409649.698862612,
      btcVolume: 1529.485427593074,
      usdVolume: 12409649.698862612,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '54.9900000000000000',
      high: '55.2900000000000000',
      low: '48.5100000000000000',
      close: '50.6100000000000000',
      volume: '439158.0191300000000000',
      quoteVolume: 22774364.172612227,
      btcVolume: 2908.36978884641,
      usdVolume: 22774364.172612227,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '50.6100000000000000',
      high: '51.5500000000000000',
      low: '43.5800000000000000',
      close: '47.3200000000000000',
      volume: '922303.8905500000000000',
      quoteVolume: 43869831.870156966,
      btcVolume: 6058.957447311882,
      usdVolume: 43869831.870156966,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '47.3200000000000000',
      high: '48.5300000000000000',
      low: '46.1300000000000000',
      close: '48.1000000000000000',
      volume: '301729.2088500000000000',
      quoteVolume: 14284224.628105655,
      btcVolume: 1978.2053346800967,
      usdVolume: 14284224.628105655,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '48.1100000000000000',
      high: '48.2400000000000000',
      low: '43.4500000000000000',
      close: '43.8600000000000000',
      volume: '419515.9268500000000000',
      quoteVolume: 19189897.614486806,
      btcVolume: 2708.0365194507826,
      usdVolume: 19189897.614486806,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '43.8700000000000000',
      high: '47.4200000000000000',
      low: '42.0400000000000000',
      close: '45.4700000000000000',
      volume: '568354.8930000000000000',
      quoteVolume: 25558493.400256857,
      btcVolume: 3690.2398898192073,
      usdVolume: 25558493.400256857,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '45.4800000000000000',
      high: '47.5700000000000000',
      low: '45.2200000000000000',
      close: '46.9100000000000000',
      volume: '310692.2824100000000000',
      quoteVolume: 14415831.257585848,
      btcVolume: 2017.545020006212,
      usdVolume: 14415831.257585848,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '46.9100000000000000',
      high: '48.6900000000000000',
      low: '44.5100000000000000',
      close: '47.7000000000000000',
      volume: '455208.2126200000000000',
      quoteVolume: 21261996.524687037,
      btcVolume: 2940.3101183783738,
      usdVolume: 21261996.524687037,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '47.6900000000000000',
      high: '48.0000000000000000',
      low: '46.0100000000000000',
      close: '46.7900000000000000',
      volume: '267853.2410700000000000',
      quoteVolume: 12673681.758905394,
      btcVolume: 1689.725092052278,
      usdVolume: 12673681.758905394,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '46.7800000000000000',
      high: '49.3300000000000000',
      low: '46.7300000000000000',
      close: '48.5400000000000000',
      volume: '300683.0915400000000000',
      quoteVolume: 14490088.801178327,
      btcVolume: 1907.3941093084677,
      usdVolume: 14490088.801178327,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '48.5500000000000000',
      high: '48.7600000000000000',
      low: '46.5300000000000000',
      close: '47.2100000000000000',
      volume: '270873.2850800000000000',
      quoteVolume: 12935690.65358488,
      btcVolume: 1697.6778943997815,
      usdVolume: 12935690.65358488,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '47.2100000000000000',
      high: '48.4500000000000000',
      low: '45.5000000000000000',
      close: '47.4500000000000000',
      volume: '313487.6048300000000000',
      quoteVolume: 14677077.515154386,
      btcVolume: 1995.4070593380043,
      usdVolume: 14677077.515154386,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '47.4500000000000000',
      high: '47.5700000000000000',
      low: '44.7100000000000000',
      close: '45.4500000000000000',
      volume: '302395.0200900000000000',
      quoteVolume: 13878732.659008326,
      btcVolume: 1902.976734313874,
      usdVolume: 13878732.659008326,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '45.4400000000000000',
      high: '46.2200000000000000',
      low: '44.2600000000000000',
      close: '44.7500000000000000',
      volume: '323943.4612000000000000',
      quoteVolume: 14690325.25946848,
      btcVolume: 2010.2978077888893,
      usdVolume: 14690325.25946848,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '44.7600000000000000',
      high: '46.7000000000000000',
      low: '43.6900000000000000',
      close: '44.6700000000000000',
      volume: '539077.4009800000000000',
      quoteVolume: 24320896.92843568,
      btcVolume: 3326.541904574674,
      usdVolume: 24320896.92843568,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '44.6400000000000000',
      high: '45.2100000000000000',
      low: '43.7000000000000000',
      close: '44.8100000000000000',
      volume: '444681.4243600000000000',
      quoteVolume: 19790933.976291418,
      btcVolume: 2706.2151928563303,
      usdVolume: 19790933.976291418,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '44.7800000000000000',
      high: '45.5000000000000000',
      low: '44.0500000000000000',
      close: '45.2800000000000000',
      volume: '271289.1369300000000000',
      quoteVolume: 12150894.310600491,
      btcVolume: 1640.903702691806,
      usdVolume: 12150894.310600491,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '45.2600000000000000',
      high: '46.5000000000000000',
      low: '44.9400000000000000',
      close: '45.1500000000000000',
      volume: '195130.2029600000000000',
      quoteVolume: 8895004.035972556,
      btcVolume: 1181.7389955788406,
      usdVolume: 8895004.035972556,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '45.1400000000000000',
      high: '46.2000000000000000',
      low: '44.8100000000000000',
      close: '45.5600000000000000',
      volume: '131662.0929900000000000',
      quoteVolume: 5984698.629427009,
      btcVolume: 800.0403835402077,
      usdVolume: 5984698.629427009,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '45.5500000000000000',
      high: '45.8700000000000000',
      low: '44.0400000000000000',
      close: '44.4500000000000000',
      volume: '217927.8825200000000000',
      quoteVolume: 9810024.721531982,
      btcVolume: 1315.4281615543798,
      usdVolume: 9810024.721531982,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '44.4500000000000000',
      high: '44.6700000000000000',
      low: '43.6000000000000000',
      close: '44.0900000000000000',
      volume: '150376.5875200000000000',
      quoteVolume: 6644775.029064859,
      btcVolume: 910.4725316157349,
      usdVolume: 6644775.029064859,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '44.0700000000000000',
      high: '44.4600000000000000',
      low: '43.2700000000000000',
      close: '43.7000000000000000',
      volume: '134280.0069800000000000',
      quoteVolume: 5891144.238883986,
      btcVolume: 816.6022311473681,
      usdVolume: 5891144.238883986,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '43.7000000000000000',
      high: '44.1100000000000000',
      low: '42.8400000000000000',
      close: '43.6500000000000000',
      volume: '169652.1601800000000000',
      quoteVolume: 7380545.620045383,
      btcVolume: 1028.175549454187,
      usdVolume: 7380545.620045383,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '43.6500000000000000',
      high: '44.5500000000000000',
      low: '43.4300000000000000',
      close: '44.4400000000000000',
      volume: '127640.0717900000000000',
      quoteVolume: 5608098.184686444,
      btcVolume: 775.0595310858355,
      usdVolume: 5608098.184686444,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '44.4400000000000000',
      high: '44.6700000000000000',
      low: '43.1900000000000000',
      close: '43.2500000000000000',
      volume: '121534.2323000000000000',
      quoteVolume: 5321086.2473528925,
      btcVolume: 744.3880130799254,
      usdVolume: 5321086.2473528925,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '43.2800000000000000',
      high: '44.0400000000000000',
      low: '42.9000000000000000',
      close: '43.4400000000000000',
      volume: '105006.6976000000000000',
      quoteVolume: 4563268.2074588975,
      btcVolume: 643.0807131329308,
      usdVolume: 4563268.2074588975,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '43.4600000000000000',
      high: '43.5600000000000000',
      low: '39.0200000000000000',
      close: '39.9900000000000000',
      volume: '373430.2658200000000000',
      quoteVolume: 15295271.543124389,
      btcVolume: 2193.410071747004,
      usdVolume: 15295271.543124389,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '39.9800000000000000',
      high: '40.1300000000000000',
      low: '36.1000000000000000',
      close: '36.9200000000000000',
      volume: '645072.3422400000000000',
      quoteVolume: 24673081.77184135,
      btcVolume: 3668.629711227305,
      usdVolume: 24673081.77184135,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '36.9400000000000000',
      high: '41.7100000000000000',
      low: '35.7800000000000000',
      close: '40.7900000000000000',
      volume: '667785.3073500000000000',
      quoteVolume: 25701967.270766664,
      btcVolume: 3786.72926837403,
      usdVolume: 25701967.270766664,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '40.7900000000000000',
      high: '41.1000000000000000',
      low: '39.0600000000000000',
      close: '39.6500000000000000',
      volume: '240386.3147900000000000',
      quoteVolume: 9577045.423953377,
      btcVolume: 1337.9051700822683,
      usdVolume: 9577045.423953377,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '39.6600000000000000',
      high: '40.4100000000000000',
      low: '39.2500000000000000',
      close: '40.1700000000000000',
      volume: '151508.4764800000000000',
      quoteVolume: 6050598.731822773,
      btcVolume: 845.4937670166742,
      usdVolume: 6050598.731822773,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '40.1500000000000000',
      high: '40.2200000000000000',
      low: '39.4100000000000000',
      close: '39.6700000000000000',
      volume: '91777.0964000000000000',
      quoteVolume: 3651414.4911812823,
      btcVolume: 511.12426012410907,
      usdVolume: 3651414.4911812823,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '39.6700000000000000',
      high: '42.1600000000000000',
      low: '39.6200000000000000',
      close: '41.8200000000000000',
      volume: '178219.7773000000000000',
      quoteVolume: 7343575.467333499,
      btcVolume: 1010.9593601908497,
      usdVolume: 7343575.467333499,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '41.8400000000000000',
      high: '42.6700000000000000',
      low: '40.2100000000000000',
      close: '40.5600000000000000',
      volume: '288871.4672100000000000',
      quoteVolume: 12041679.124624727,
      btcVolume: 1606.172428630869,
      usdVolume: 12041679.124624727,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '40.5600000000000000',
      high: '41.1300000000000000',
      low: '39.6800000000000000',
      close: '40.0600000000000000',
      volume: '211464.7632900000000000',
      quoteVolume: 8551583.328070415,
      btcVolume: 1173.559105839882,
      usdVolume: 8551583.328070415,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '40.3300000000000000',
      high: '40.4800000000000000',
      low: '39.3800000000000000',
      close: '40.0800000000000000',
      volume: '136222.9401800000000000',
      quoteVolume: 5447546.31914613,
      btcVolume: 755.370742894901,
      usdVolume: 5447546.31914613,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '40.1100000000000000',
      high: '41.7300000000000000',
      low: '39.5800000000000000',
      close: '41.4400000000000000',
      volume: '194299.1207600000000000',
      quoteVolume: 7858546.46046011,
      btcVolume: 1081.0147742755657,
      usdVolume: 7858546.46046011,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '39.9900000000000000',
      high: '41.5000000000000000',
      low: '39.6700000000000000',
      close: '40.9500000000000000',
      volume: '219194.0915600000000000',
      quoteVolume: 8901146.687390905,
      btcVolume: 1233.9824964047334,
      usdVolume: 8901146.687390905,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '40.9600000000000000',
      high: '43.7600000000000000',
      low: '40.9100000000000000',
      close: '42.8300000000000000',
      volume: '284295.3329600000000000',
      quoteVolume: 12068166.856607929,
      btcVolume: 1649.5543814055918,
      usdVolume: 12068166.856607929,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '42.8400000000000000',
      high: '43.7600000000000000',
      low: '42.1400000000000000',
      close: '43.0800000000000000',
      volume: '337077.7113200000000000',
      quoteVolume: 14472707.652529165,
      btcVolume: 1964.1795286823033,
      usdVolume: 14472707.652529165,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '43.0700000000000000',
      high: '44.1800000000000000',
      low: '41.8200000000000000',
      close: '42.3800000000000000',
      volume: '298581.8729900000000000',
      quoteVolume: 12800174.560334394,
      btcVolume: 1748.879815023186,
      usdVolume: 12800174.560334394,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '42.3600000000000000',
      high: '43.0500000000000000',
      low: '41.0000000000000000',
      close: '41.3000000000000000',
      volume: '201446.8929200000000000',
      quoteVolume: 8445983.18938396,
      btcVolume: 1169.7012363488755,
      usdVolume: 8445983.18938396,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '41.2900000000000000',
      high: '42.0800000000000000',
      low: '41.1600000000000000',
      close: '41.6200000000000000',
      volume: '110044.8744500000000000',
      quoteVolume: 4588192.884942515,
      btcVolume: 635.4364428671792,
      usdVolume: 4588192.884942515,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '41.5800000000000000',
      high: '41.7400000000000000',
      low: '39.1700000000000000',
      close: '39.4300000000000000',
      volume: '185573.9423700000000000',
      quoteVolume: 7483717.243429469,
      btcVolume: 1061.4403351410635,
      usdVolume: 7483717.243429469,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '39.4400000000000000',
      high: '42.3900000000000000',
      low: '38.7700000000000000',
      close: '42.2300000000000000',
      volume: '426686.1784300000000000',
      quoteVolume: 17553430.816173177,
      btcVolume: 2423.2412705017687,
      usdVolume: 17553430.816173177,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '42.2300000000000000',
      high: '42.9000000000000000',
      low: '41.7700000000000000',
      close: '42.7800000000000000',
      volume: '248765.3690300000000000',
      quoteVolume: 10534042.087356858,
      btcVolume: 1436.827480722452,
      usdVolume: 10534042.087356858,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '42.7900000000000000',
      high: '44.6100000000000000',
      low: '42.6000000000000000',
      close: '43.3000000000000000',
      volume: '291946.5645000000000000',
      quoteVolume: 12683510.13119241,
      btcVolume: 1705.9415015020584,
      usdVolume: 12683510.13119241,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '43.2900000000000000',
      high: '46.0800000000000000',
      low: '43.1600000000000000',
      close: '45.8000000000000000',
      volume: '384930.9909000000000000',
      quoteVolume: 17201996.630419996,
      btcVolume: 2279.19673036352,
      usdVolume: 17201996.630419996,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '45.8000000000000000',
      high: '47.0300000000000000',
      low: '44.4800000000000000',
      close: '46.3000000000000000',
      volume: '493847.1053200000000000',
      quoteVolume: 22589565.78223805,
      btcVolume: 2841.5038279351834,
      usdVolume: 22589565.78223805,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '46.3000000000000000',
      high: '48.5400000000000000',
      low: '44.0000000000000000',
      close: '45.3500000000000000',
      volume: '584339.7458700000000000',
      quoteVolume: 27180426.110374276,
      btcVolume: 3303.5440248504137,
      usdVolume: 27180426.110374276,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '45.3100000000000000',
      high: '45.7900000000000000',
      low: '43.9000000000000000',
      close: '44.7400000000000000',
      volume: '291473.3014800000000000',
      quoteVolume: 13068587.786202814,
      btcVolume: 1655.8036544951558,
      usdVolume: 13068587.786202814,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '44.7500000000000000',
      high: '49.7200000000000000',
      low: '43.7100000000000000',
      close: '49.0600000000000000',
      volume: '729802.1629100000000000',
      quoteVolume: 34640157.160860166,
      btcVolume: 4336.05850079116,
      usdVolume: 34640157.160860166,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '49.0600000000000000',
      high: '51.9500000000000000',
      low: '47.7700000000000000',
      close: '49.3000000000000000',
      volume: '681984.4665200000000000',
      quoteVolume: 33981679.44025984,
      btcVolume: 4182.297745203546,
      usdVolume: 33981679.44025984,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '49.3200000000000000',
      high: '51.7500000000000000',
      low: '48.7800000000000000',
      close: '51.4300000000000000',
      volume: '532886.7588500000000000',
      quoteVolume: 26955144.342092644,
      btcVolume: 3323.759557503418,
      usdVolume: 26955144.342092644,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '51.4400000000000000',
      high: '51.6000000000000000',
      low: '48.9100000000000000',
      close: '49.6000000000000000',
      volume: '381202.3532900000000000',
      quoteVolume: 19042757.26225366,
      btcVolume: 2347.839281568202,
      usdVolume: 19042757.26225366,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '49.5900000000000000',
      high: '60.8000000000000000',
      low: '49.5800000000000000',
      close: '58.3900000000000000',
      volume: '1232536.3536400000000000',
      quoteVolume: 68826521.75910157,
      btcVolume: 7986.234559202855,
      usdVolume: 68826521.75910157,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '58.3700000000000000',
      high: '60.3300000000000000',
      low: '55.2800000000000000',
      close: '58.1600000000000000',
      volume: '719639.6444600000000000',
      quoteVolume: 41814710.281005435,
      btcVolume: 4771.257558434868,
      usdVolume: 41814710.281005435,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '58.1700000000000000',
      high: '58.5000000000000000',
      low: '54.5000000000000000',
      close: '57.5600000000000000',
      volume: '477991.2016200000000000',
      quoteVolume: 27095155.674876053,
      btcVolume: 3117.7992797943502,
      usdVolume: 27095155.674876053,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '57.5600000000000000',
      high: '63.2600000000000000',
      low: '56.7200000000000000',
      close: '61.0200000000000000',
      volume: '767262.9013300000000000',
      quoteVolume: 46240460.37712067,
      btcVolume: 5206.750862665781,
      usdVolume: 46240460.37712067,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '61.0500000000000000',
      high: '62.5000000000000000',
      low: '57.9000000000000000',
      close: '59.3200000000000000',
      volume: '498224.6925600000000000',
      quoteVolume: 29957779.817371603,
      btcVolume: 3366.710070150065,
      usdVolume: 29957779.817371603,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '59.2900000000000000',
      high: '62.5500000000000000',
      low: '55.3400000000000000',
      close: '57.6000000000000000',
      volume: '530895.3948100000000000',
      quoteVolume: 31172505.611098293,
      btcVolume: 3524.9826085852515,
      usdVolume: 31172505.611098293,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '57.5900000000000000',
      high: '58.2900000000000000',
      low: '55.8400000000000000',
      close: '57.1900000000000000',
      volume: '262316.6725800000000000',
      quoteVolume: 14992782.875085551,
      btcVolume: 1733.2682720575585,
      usdVolume: 14992782.875085551,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '57.1900000000000000',
      high: '58.0900000000000000',
      low: '55.9100000000000000',
      close: '57.5700000000000000',
      volume: '216543.7237600000000000',
      quoteVolume: 12411702.385828936,
      btcVolume: 1433.8494862629868,
      usdVolume: 12411702.385828936,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '57.6000000000000000',
      high: '59.3300000000000000',
      low: '57.1800000000000000',
      close: '58.3300000000000000',
      volume: '218658.9547700000000000',
      quoteVolume: 12717189.464885991,
      btcVolume: 1461.8622331104564,
      usdVolume: 12717189.464885991,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '58.3300000000000000',
      high: '58.4000000000000000',
      low: '52.4200000000000000',
      close: '54.5200000000000000',
      volume: '464982.4302600000000000',
      quoteVolume: 25617022.981270354,
      btcVolume: 3040.6698235798467,
      usdVolume: 25617022.981270354,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '54.5200000000000000',
      high: '55.4100000000000000',
      low: '50.6400000000000000',
      close: '54.3800000000000000',
      volume: '550746.2142300000000000',
      quoteVolume: 29358272.10197633,
      btcVolume: 3502.858976790822,
      usdVolume: 29358272.10197633,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '54.4100000000000000',
      high: '54.4100000000000000',
      low: '51.9000000000000000',
      close: '53.2500000000000000',
      volume: '282004.9267600000000000',
      quoteVolume: 15034806.238122009,
      btcVolume: 1801.659164508155,
      usdVolume: 15034806.238122009,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '53.2600000000000000',
      high: '56.6800000000000000',
      low: '52.8000000000000000',
      close: '56.2400000000000000',
      volume: '348355.2649400000000000',
      quoteVolume: 19066690.28468506,
      btcVolume: 2251.529364018872,
      usdVolume: 19066690.28468506,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '56.2900000000000000',
      high: '59.6500000000000000',
      low: '55.8700000000000000',
      close: '58.5500000000000000',
      volume: '504725.6770700000000000',
      quoteVolume: 29245084.44984709,
      btcVolume: 3342.2689126799664,
      usdVolume: 29245084.44984709,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '58.5500000000000000',
      high: '61.6100000000000000',
      low: '57.2800000000000000',
      close: '60.5200000000000000',
      volume: '720540.7454800000000000',
      quoteVolume: 42958185.64174903,
      btcVolume: 4739.804305857356,
      usdVolume: 42958185.64174903,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '60.5400000000000000',
      high: '62.5600000000000000',
      low: '59.4000000000000000',
      close: '59.8000000000000000',
      volume: '566997.9273700000000000',
      quoteVolume: 34512745.46518843,
      btcVolume: 3694.810881665897,
      usdVolume: 34512745.46518843,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '59.8100000000000000',
      high: '70.2200000000000000',
      low: '58.2400000000000000',
      close: '67.9800000000000000',
      volume: '1286322.9967600000000000',
      quoteVolume: 83696612.6659622,
      btcVolume: 8901.350446887207,
      usdVolume: 83696612.6659622,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '68.0000000000000000',
      high: '70.1000000000000000',
      low: '65.3700000000000000',
      close: '68.0000000000000000',
      volume: '938256.7834600000000000',
      quoteVolume: 63513691.45788283,
      btcVolume: 6794.098635568326,
      usdVolume: 63513691.45788283,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '68.0200000000000000',
      high: '73.0000000000000000',
      low: '67.6200000000000000',
      close: '70.7200000000000000',
      volume: '952836.8964000000000000',
      quoteVolume: 67544318.68868984,
      btcVolume: 7193.168790333061,
      usdVolume: 67544318.68868984,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '70.7200000000000000',
      high: '73.5000000000000000',
      low: '67.6600000000000000',
      close: '69.9300000000000000',
      volume: '818385.8921200000000000',
      quoteVolume: 57937576.88566312,
      btcVolume: 6178.672563598776,
      usdVolume: 57937576.88566312,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '69.9300000000000000',
      high: '73.0000000000000000',
      low: '68.4500000000000000',
      close: '69.6500000000000000',
      volume: '689182.2485500000000000',
      quoteVolume: 48493966.03873978,
      btcVolume: 5178.755191366324,
      usdVolume: 48493966.03873978,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '69.6500000000000000',
      high: '70.5000000000000000',
      low: '66.6200000000000000',
      close: '68.0700000000000000',
      volume: '486977.7672700000000000',
      quoteVolume: 33194308.20363878,
      btcVolume: 3598.386589011568,
      usdVolume: 33194308.20363878,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '68.0400000000000000',
      high: '73.4700000000000000',
      low: '67.6200000000000000',
      close: '72.5900000000000000',
      volume: '646729.4887700000000000',
      quoteVolume: 46118158.32426789,
      btcVolume: 4877.923830710961,
      usdVolume: 46118158.32426789,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '72.5900000000000000',
      high: '75.0800000000000000',
      low: '70.9100000000000000',
      close: '73.6000000000000000',
      volume: '698855.4245600000000000',
      quoteVolume: 51231501.11111841,
      btcVolume: 5277.399278078971,
      usdVolume: 51231501.11111841,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '73.5900000000000000',
      high: '75.4800000000000000',
      low: '72.8500000000000000',
      close: '74.3000000000000000',
      volume: '541747.2637200000000000',
      quoteVolume: 40077414.45603498,
      btcVolume: 4090.357266366724,
      usdVolume: 40077414.45603498,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '74.3100000000000000',
      high: '77.9400000000000000',
      low: '71.5300000000000000',
      close: '76.5600000000000000',
      volume: '647821.6039800000000000',
      quoteVolume: 48757951.45692755,
      btcVolume: 4970.738929853706,
      usdVolume: 48757951.45692755,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '76.5600000000000000',
      high: '78.4300000000000000',
      low: '75.0000000000000000',
      close: '77.0000000000000000',
      volume: '472262.1156700000000000',
      quoteVolume: 36317074.30775854,
      btcVolume: 3602.7858292027363,
      usdVolume: 36317074.30775854,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '76.9900000000000000',
      high: '77.4800000000000000',
      low: '72.2100000000000000',
      close: '74.0200000000000000',
      volume: '709830.6287100000000000',
      quoteVolume: 52756980.53293135,
      btcVolume: 5317.311038857939,
      usdVolume: 52756980.53293135,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '73.9800000000000000',
      high: '77.4000000000000000',
      low: '72.6400000000000000',
      close: '76.4600000000000000',
      volume: '557017.2686200000000000',
      quoteVolume: 41776583.98157975,
      btcVolume: 4186.95219451079,
      usdVolume: 41776583.98157975,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '76.4500000000000000',
      high: '82.8100000000000000',
      low: '76.4400000000000000',
      close: '81.1700000000000000',
      volume: '771544.8829200000000000',
      quoteVolume: 61428938.033711724,
      btcVolume: 5950.1181314721225,
      usdVolume: 61428938.033711724,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '81.2000000000000000',
      high: '84.3200000000000000',
      low: '77.1500000000000000',
      close: '80.5500000000000000',
      volume: '1044135.4611700000000000',
      quoteVolume: 84499591.43981327,
      btcVolume: 8207.278543411736,
      usdVolume: 84499591.43981327,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '80.5500000000000000',
      high: '83.7900000000000000',
      low: '78.6100000000000000',
      close: '83.1400000000000000',
      volume: '523491.6950600000000000',
      quoteVolume: 42705491.43694748,
      btcVolume: 4162.817891228647,
      usdVolume: 42705491.43694748,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '83.1400000000000000',
      high: '83.5000000000000000',
      low: '75.0400000000000000',
      close: '76.7000000000000000',
      volume: '704649.8592300000000000',
      quoteVolume: 55784535.30447557,
      btcVolume: 5528.033565597649,
      usdVolume: 55784535.30447557,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '76.7400000000000000',
      high: '80.7700000000000000',
      low: '67.8500000000000000',
      close: '74.6700000000000000',
      volume: '998173.2565800000000000',
      quoteVolume: 74384870.94958961,
      btcVolume: 7540.054081915949,
      usdVolume: 74384870.94958961,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '74.6600000000000000',
      high: '75.5000000000000000',
      low: '68.5100000000000000',
      close: '73.4100000000000000',
      volume: '738054.4697500000000000',
      quoteVolume: 52679051.66613214,
      btcVolume: 5429.018811635842,
      usdVolume: 52679051.66613214,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '73.4400000000000000',
      high: '79.0900000000000000',
      low: '70.0000000000000000',
      close: '77.2500000000000000',
      volume: '713680.2316200000000000',
      quoteVolume: 53060847.638592295,
      btcVolume: 5393.830722757955,
      usdVolume: 53060847.638592295,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '77.2500000000000000',
      high: '79.4500000000000000',
      low: '67.0000000000000000',
      close: '70.6100000000000000',
      volume: '542175.5195700000000000',
      quoteVolume: 40353067.756060414,
      btcVolume: 4058.4679125064927,
      usdVolume: 40353067.756060414,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '70.6100000000000000',
      high: '72.4300000000000000',
      low: '66.1000000000000000',
      close: '69.4000000000000000',
      volume: '730443.1978900000000000',
      quoteVolume: 50737958.514938615,
      btcVolume: 5298.48056977998,
      usdVolume: 50737958.514938615,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '69.3800000000000000',
      high: '75.2500000000000000',
      low: '68.5600000000000000',
      close: '73.2500000000000000',
      volume: '624557.8448400000000000',
      quoteVolume: 45340746.56160606,
      btcVolume: 4683.2319382880305,
      usdVolume: 45340746.56160606,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '73.2500000000000000',
      high: '76.7900000000000000',
      low: '72.3200000000000000',
      close: '74.7900000000000000',
      volume: '651449.7864800000000000',
      quoteVolume: 48795358.48709348,
      btcVolume: 5060.245308587067,
      usdVolume: 48795358.48709348,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '74.8000000000000000',
      high: '80.0000000000000000',
      low: '74.6600000000000000',
      close: '79.5200000000000000',
      volume: '665878.9020700000000000',
      quoteVolume: 52072540.692516245,
      btcVolume: 5281.779201690238,
      usdVolume: 52072540.692516245,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '79.5200000000000000',
      high: '79.7600000000000000',
      low: '71.7100000000000000',
      close: '75.5900000000000000',
      volume: '936157.2591900000000000',
      quoteVolume: 70787716.5914514,
      btcVolume: 7272.425954963807,
      usdVolume: 70787716.5914514,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '75.5700000000000000',
      high: '76.0800000000000000',
      low: '69.5400000000000000',
      close: '70.9800000000000000',
      volume: '778051.0902300000000000',
      quoteVolume: 56572719.08831394,
      btcVolume: 5971.138131273642,
      usdVolume: 56572719.08831394,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '70.9800000000000000',
      high: '72.1500000000000000',
      low: '57.4100000000000000',
      close: '60.5300000000000000',
      volume: '1518769.7348500000000000',
      quoteVolume: 97983345.80007514,
      btcVolume: 10860.043177575628,
      usdVolume: 97983345.80007514,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '60.5300000000000000',
      high: '65.1000000000000000',
      low: '57.1500000000000000',
      close: '61.7700000000000000',
      volume: '1110152.5418600000000000',
      quoteVolume: 68205004.9433444,
      btcVolume: 7760.49735099012,
      usdVolume: 68205004.9433444,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '61.7600000000000000',
      high: '63.3900000000000000',
      low: '56.5600000000000000',
      close: '59.8900000000000000',
      volume: '1070641.2770800000000000',
      quoteVolume: 64057582.61899067,
      btcVolume: 7378.595868383946,
      usdVolume: 64057582.61899067,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '59.8800000000000000',
      high: '61.6500000000000000',
      low: '57.8400000000000000',
      close: '58.0500000000000000',
      volume: '506232.3970400000000000',
      quoteVolume: 30182934.208716422,
      btcVolume: 3477.3583610745964,
      usdVolume: 30182934.208716422,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '58.0500000000000000',
      high: '60.2800000000000000',
      low: '56.2100000000000000',
      close: '57.7000000000000000',
      volume: '633924.9116200000000000',
      quoteVolume: 37024496.384328924,
      btcVolume: 4325.436819033869,
      usdVolume: 37024496.384328924,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '57.7200000000000000',
      high: '62.3200000000000000',
      low: '57.2500000000000000',
      close: '61.4200000000000000',
      volume: '660927.2617600000000000',
      quoteVolume: 39608823.902695134,
      btcVolume: 4524.26294574055,
      usdVolume: 39608823.902695134,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '61.4200000000000000',
      high: '62.4000000000000000',
      low: '59.4000000000000000',
      close: '61.0100000000000000',
      volume: '640940.7745700000000000',
      quoteVolume: 39002038.56290318,
      btcVolume: 4441.375840912394,
      usdVolume: 39002038.56290318,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '61.0200000000000000',
      high: '61.7800000000000000',
      low: '58.7100000000000000',
      close: '60.3800000000000000',
      volume: '424299.8621100000000000',
      quoteVolume: 25604221.91697132,
      btcVolume: 2924.6084427488145,
      usdVolume: 25604221.91697132,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '60.3800000000000000',
      high: '63.2900000000000000',
      low: '60.3300000000000000',
      close: '62.0600000000000000',
      volume: '556284.7403100000000000',
      quoteVolume: 34448965.31724282,
      btcVolume: 3820.9976165896405,
      usdVolume: 34448965.31724282,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '62.0600000000000000',
      high: '63.8400000000000000',
      low: '61.1800000000000000',
      close: '63.2200000000000000',
      volume: '468431.8505300000000000',
      quoteVolume: 29256812.809018675,
      btcVolume: 3219.3959694013993,
      usdVolume: 29256812.809018675,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '63.2100000000000000',
      high: '63.8900000000000000',
      low: '59.8600000000000000',
      close: '60.4600000000000000',
      volume: '444270.5350800000000000',
      quoteVolume: 27655735.411491226,
      btcVolume: 3061.59890192781,
      usdVolume: 27655735.411491226,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '60.4600000000000000',
      high: '60.4700000000000000',
      low: '50.4100000000000000',
      close: '51.0000000000000000',
      volume: '945026.2712800000000000',
      quoteVolume: 51958845.61631424,
      btcVolume: 6146.981784105797,
      usdVolume: 51958845.61631424,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '51.0200000000000000',
      high: '52.2700000000000000',
      low: '46.5700000000000000',
      close: '50.3900000000000000',
      volume: '1426145.5016400000000000',
      quoteVolume: 70420337.2832661,
      btcVolume: 8969.579053763204,
      usdVolume: 70420337.2832661,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '50.3700000000000000',
      high: '51.3900000000000000',
      low: '48.8600000000000000',
      close: '50.2600000000000000',
      volume: '727732.9091100000000000',
      quoteVolume: 36535744.60946244,
      btcVolume: 4600.383086081476,
      usdVolume: 36535744.60946244,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '50.2400000000000000',
      high: '50.7400000000000000',
      low: '45.4700000000000000',
      close: '48.5300000000000000',
      volume: '798310.6481000000000000',
      quoteVolume: 38456344.38179883,
      btcVolume: 4924.684407095869,
      usdVolume: 38456344.38179883,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '48.5200000000000000',
      high: '48.7600000000000000',
      low: '28.6500000000000000',
      close: '29.8300000000000000',
      volume: '2086322.4159000000000000',
      quoteVolume: 75460005.32619856,
      btcVolume: 12025.230888564047,
      usdVolume: 75460005.32619856,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '29.8300000000000000',
      high: '39.9200000000000000',
      low: '24.0000000000000000',
      close: '37.5200000000000000',
      volume: '3233170.0085600000000000',
      quoteVolume: 106737958.21170305,
      btcVolume: 20811.33498176254,
      usdVolume: 106737958.21170305,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '37.5100000000000000',
      high: '37.6500000000000000',
      low: '33.8000000000000000',
      close: '34.4000000000000000',
      volume: '1177751.7307200000000000',
      quoteVolume: 42192079.856290706,
      btcVolume: 7824.353304327343,
      usdVolume: 42192079.856290706,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '34.4000000000000000',
      high: '38.7300000000000000',
      low: '34.0400000000000000',
      close: '36.3800000000000000',
      volume: '1040322.3028200000000000',
      quoteVolume: 37333342.64250416,
      btcVolume: 6968.352015588107,
      usdVolume: 37333342.64250416,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '36.3700000000000000',
      high: '36.4100000000000000',
      low: '29.2900000000000000',
      close: '33.2000000000000000',
      volume: '1624714.3758400000000000',
      quoteVolume: 52644643.37862483,
      btcVolume: 10729.651950772735,
      usdVolume: 52644643.37862483,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '33.2300000000000000',
      high: '35.8500000000000000',
      low: '32.4200000000000000',
      close: '34.0700000000000000',
      volume: '945437.9313600000000000',
      quoteVolume: 32633674.457892723,
      btcVolume: 6180.955240181797,
      usdVolume: 32633674.457892723,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '34.0600000000000000',
      high: '34.8300000000000000',
      low: '32.2800000000000000',
      close: '34.7100000000000000',
      volume: '1044418.0699700000000000',
      quoteVolume: 35185450.617933325,
      btcVolume: 6701.831333709307,
      usdVolume: 35185450.617933325,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '34.7200000000000000',
      high: '40.8000000000000000',
      low: '34.0000000000000000',
      close: '38.9000000000000000',
      volume: '1343522.4596300000000000',
      quoteVolume: 50290985.84804543,
      btcVolume: 8550.756313543709,
      usdVolume: 50290985.84804543,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '38.9000000000000000',
      high: '43.6900000000000000',
      low: '34.0500000000000000',
      close: '38.0800000000000000',
      volume: '1571300.6085700000000000',
      quoteVolume: 62380455.892511785,
      btcVolume: 9798.316569328548,
      usdVolume: 62380455.892511785,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '38.0700000000000000',
      high: '39.8400000000000000',
      low: '36.2100000000000000',
      close: '38.3200000000000000',
      volume: '667425.6322200000000000',
      quoteVolume: 25413717.28592991,
      btcVolume: 4123.580369137249,
      usdVolume: 25413717.28592991,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '38.3400000000000000',
      high: '39.6900000000000000',
      low: '34.8200000000000000',
      close: '35.4000000000000000',
      volume: '714342.7273000000000000',
      quoteVolume: 26556648.090772223,
      btcVolume: 4362.9065967115375,
      usdVolume: 26556648.090772223,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '35.4200000000000000',
      high: '39.9900000000000000',
      low: '34.5700000000000000',
      close: '38.9400000000000000',
      volume: '859942.5261100000000000',
      quoteVolume: 32311045.429742124,
      btcVolume: 5270.1140483688005,
      usdVolume: 32311045.429742124,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '38.9300000000000000',
      high: '40.9500000000000000',
      low: '38.2100000000000000',
      close: '40.5700000000000000',
      volume: '593656.7733500000000000',
      quoteVolume: 23511452.09394711,
      btcVolume: 3536.1717797574593,
      usdVolume: 23511452.09394711,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '40.5600000000000000',
      high: '41.0400000000000000',
      low: '38.0900000000000000',
      close: '39.1700000000000000',
      volume: '577448.8885600000000000',
      quoteVolume: 22783918.764491983,
      btcVolume: 3426.4774081346554,
      usdVolume: 22783918.764491983,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '39.1700000000000000',
      high: '40.4800000000000000',
      low: '38.4000000000000000',
      close: '40.4100000000000000',
      volume: '415288.7595000000000000',
      quoteVolume: 16295287.489501858,
      btcVolume: 2447.192594622033,
      usdVolume: 16295287.489501858,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '40.4100000000000000',
      high: '41.2100000000000000',
      low: '37.7300000000000000',
      close: '38.0400000000000000',
      volume: '481643.2817300000000000',
      quoteVolume: 19173843.62566706,
      btcVolume: 2881.7355055505905,
      usdVolume: 19173843.62566706,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '38.0400000000000000',
      high: '39.4300000000000000',
      low: '36.6100000000000000',
      close: '38.8700000000000000',
      volume: '624312.1869900000000000',
      quoteVolume: 23604375.60363385,
      btcVolume: 3798.947942790218,
      usdVolume: 23604375.60363385,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '38.8500000000000000',
      high: '39.1100000000000000',
      low: '36.9700000000000000',
      close: '37.1100000000000000',
      volume: '402076.3126400000000000',
      quoteVolume: 15295566.907294536,
      btcVolume: 2513.0753250709718,
      usdVolume: 15295566.907294536,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '37.1100000000000000',
      high: '39.7000000000000000',
      low: '37.1000000000000000',
      close: '38.9100000000000000',
      volume: '428079.8682600000000000',
      quoteVolume: 16531614.31945865,
      btcVolume: 2634.7438065394886,
      usdVolume: 16531614.31945865,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '38.9300000000000000',
      high: '39.3200000000000000',
      low: '38.3300000000000000',
      close: '39.0800000000000000',
      volume: '378250.3606500000000000',
      quoteVolume: 14716269.660095483,
      btcVolume: 2283.619623452454,
      usdVolume: 14716269.660095483,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '39.0700000000000000',
      high: '39.5000000000000000',
      low: '37.1400000000000000',
      close: '39.2300000000000000',
      volume: '488799.1143600000000000',
      quoteVolume: 18738315.769651353,
      btcVolume: 2961.325340905673,
      usdVolume: 18738315.769651353,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '39.2300000000000000',
      high: '42.9200000000000000',
      low: '39.0000000000000000',
      close: '39.8600000000000000',
      volume: '701573.7692100000000000',
      quoteVolume: 28237488.266804706,
      btcVolume: 4154.26325616401,
      usdVolume: 28237488.266804706,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '39.8800000000000000',
      high: '41.2600000000000000',
      low: '39.6400000000000000',
      close: '40.4600000000000000',
      volume: '463368.1163000000000000',
      quoteVolume: 18748635.166638713,
      btcVolume: 2747.9145418248345,
      usdVolume: 18748635.166638713,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '40.4900000000000000',
      high: '41.1600000000000000',
      low: '39.8600000000000000',
      close: '40.7100000000000000',
      volume: '362553.4451000000000000',
      quoteVolume: 14663339.769387648,
      btcVolume: 2163.870381431818,
      usdVolume: 14663339.769387648,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '40.7400000000000000',
      high: '41.0000000000000000',
      low: '39.8600000000000000',
      close: '40.3100000000000000',
      volume: '231112.9573000000000000',
      quoteVolume: 9362413.054505432,
      btcVolume: 1379.8091072782984,
      usdVolume: 9362413.054505432,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '40.3100000000000000',
      high: '45.0900000000000000',
      low: '40.2800000000000000',
      close: '45.0400000000000000',
      volume: '721562.2858000000000000',
      quoteVolume: 30935223.803984463,
      btcVolume: 4356.628030072247,
      usdVolume: 30935223.803984463,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '45.0300000000000000',
      high: '47.6400000000000000',
      low: '43.6400000000000000',
      close: '44.7700000000000000',
      volume: '807492.3694800000000000',
      quoteVolume: 36986803.916505545,
      btcVolume: 5058.8892341354185,
      usdVolume: 36986803.916505545,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '44.7900000000000000',
      high: '47.2000000000000000',
      low: '44.2700000000000000',
      close: '46.5500000000000000',
      volume: '500059.4649500000000000',
      quoteVolume: 23044062.553474456,
      btcVolume: 3156.204573659692,
      usdVolume: 23044062.553474456,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '46.5200000000000000',
      high: '46.8600000000000000',
      low: '44.6900000000000000',
      close: '46.2100000000000000',
      volume: '401085.4795800000000000',
      quoteVolume: 18464427.839610986,
      btcVolume: 2533.6102006732344,
      usdVolume: 18464427.839610986,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '46.1900000000000000',
      high: '46.3900000000000000',
      low: '40.8600000000000000',
      close: '42.2500000000000000',
      volume: '785172.3313100000000000',
      quoteVolume: 33771322.35967942,
      btcVolume: 4856.52724305218,
      usdVolume: 33771322.35967942,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '42.2800000000000000',
      high: '43.1600000000000000',
      low: '41.4300000000000000',
      close: '42.5400000000000000',
      volume: '382360.3213200000000000',
      quoteVolume: 16212942.580929037,
      btcVolume: 2364.227913329805,
      usdVolume: 16212942.580929037,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '42.5400000000000000',
      high: '43.5800000000000000',
      low: '41.8300000000000000',
      close: '42.0000000000000000',
      volume: '499257.0325700000000000',
      quoteVolume: 21342178.133174907,
      btcVolume: 3067.773815331192,
      usdVolume: 21342178.133174907,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '41.9900000000000000',
      high: '41.9900000000000000',
      low: '39.5200000000000000',
      close: '41.2200000000000000',
      volume: '744114.5130600000000000',
      quoteVolume: 30090872.086141095,
      btcVolume: 4470.071976428221,
      usdVolume: 30090872.086141095,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '41.2000000000000000',
      high: '41.8500000000000000',
      low: '40.4700000000000000',
      close: '41.2200000000000000',
      volume: '420837.6883200000000000',
      quoteVolume: 17361900.52885351,
      btcVolume: 2527.268685546851,
      usdVolume: 17361900.52885351,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '41.1900000000000000',
      high: '41.7500000000000000',
      low: '39.0800000000000000',
      close: '39.2200000000000000',
      volume: '460328.1631000000000000',
      quoteVolume: 18685153.46120497,
      btcVolume: 2758.6937261226535,
      usdVolume: 18685153.46120497,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '39.2400000000000000',
      high: '43.7800000000000000',
      low: '37.9100000000000000',
      close: '42.5300000000000000',
      volume: '913086.9777100000000000',
      quoteVolume: 37743900.83684656,
      btcVolume: 5472.020626825356,
      usdVolume: 37743900.83684656,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '42.5200000000000000',
      high: '43.0100000000000000',
      low: '41.6100000000000000',
      close: '42.1900000000000000',
      volume: '407623.0458700000000000',
      quoteVolume: 17197450.73896654,
      btcVolume: 2433.5847695278885,
      usdVolume: 17197450.73896654,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '42.2000000000000000',
      high: '44.4300000000000000',
      low: '41.9800000000000000',
      close: '44.1400000000000000',
      volume: '497104.9427100000000000',
      quoteVolume: 21438290.761193395,
      btcVolume: 2994.1891368990136,
      usdVolume: 21438290.761193395,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '44.1600000000000000',
      high: '44.3000000000000000',
      low: '41.5400000000000000',
      close: '42.2300000000000000',
      volume: '456205.8125200000000000',
      quoteVolume: 19598464.2516532,
      btcVolume: 2739.1972282400675,
      usdVolume: 19598464.2516532,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '42.2300000000000000',
      high: '43.2400000000000000',
      low: '39.5500000000000000',
      close: '40.2100000000000000',
      volume: '543840.0568300000000000',
      quoteVolume: 22495305.856098518,
      btcVolume: 3219.760771868381,
      usdVolume: 22495305.856098518,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '40.2100000000000000',
      high: '41.3400000000000000',
      low: '40.0100000000000000',
      close: '40.5900000000000000',
      volume: '345238.8723200000000000',
      quoteVolume: 14009507.177860197,
      btcVolume: 2044.2849891545281,
      usdVolume: 14009507.177860197,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '40.5900000000000000',
      high: '42.3200000000000000',
      low: '40.2700000000000000',
      close: '41.8000000000000000',
      volume: '462963.2967500000000000',
      quoteVolume: 19150251.779417757,
      btcVolume: 2733.259301365301,
      usdVolume: 19150251.779417757,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '41.8100000000000000',
      high: '43.9100000000000000',
      low: '40.9400000000000000',
      close: '42.9800000000000000',
      volume: '638808.1586600000000000',
      quoteVolume: 27205984.39496531,
      btcVolume: 3698.639315507722,
      usdVolume: 27205984.39496531,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '42.9800000000000000',
      high: '44.9900000000000000',
      low: '42.8300000000000000',
      close: '44.5300000000000000',
      volume: '623497.4206400000000000',
      quoteVolume: 27494508.18459142,
      btcVolume: 3656.2769200491334,
      usdVolume: 27494508.18459142,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '44.5200000000000000',
      high: '45.5800000000000000',
      low: '43.8400000000000000',
      close: '44.3800000000000000',
      volume: '555367.1872400000000000',
      quoteVolume: 24833710.353949737,
      btcVolume: 3290.9485922233407,
      usdVolume: 24833710.353949737,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '44.3800000000000000',
      high: '45.3900000000000000',
      low: '43.7500000000000000',
      close: '44.5200000000000000',
      volume: '485605.1559300000000000',
      quoteVolume: 21633052.172737543,
      btcVolume: 2845.7674049944853,
      usdVolume: 21633052.172737543,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '44.5400000000000000',
      high: '45.2300000000000000',
      low: '43.1600000000000000',
      close: '44.4600000000000000',
      volume: '443570.6602900000000000',
      quoteVolume: 19611892.418601938,
      btcVolume: 2546.7952458477666,
      usdVolume: 19611892.418601938,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '44.4700000000000000',
      high: '46.1300000000000000',
      low: '43.4200000000000000',
      close: '45.6900000000000000',
      volume: '590012.5230400000000000',
      quoteVolume: 26316522.275929503,
      btcVolume: 3404.4738459937435,
      usdVolume: 26316522.275929503,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '45.6900000000000000',
      high: '49.9100000000000000',
      low: '45.6400000000000000',
      close: '48.8100000000000000',
      volume: '1198086.9005900000000000',
      quoteVolume: 56983117.16653303,
      btcVolume: 6840.247516585622,
      usdVolume: 56983117.16653303,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '48.8100000000000000',
      high: '50.8000000000000000',
      low: '45.5900000000000000',
      close: '46.3300000000000000',
      volume: '1404189.5332000000000000',
      quoteVolume: 67399084.98858932,
      btcVolume: 7558.333091499474,
      usdVolume: 67399084.98858932,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '46.3300000000000000',
      high: '48.2700000000000000',
      low: '46.3000000000000000',
      close: '47.0700000000000000',
      volume: '499757.9662500000000000',
      quoteVolume: 23637798.368433,
      btcVolume: 2688.212536886058,
      usdVolume: 23637798.368433,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '47.0800000000000000',
      high: '49.6000000000000000',
      low: '46.7300000000000000',
      close: '49.2000000000000000',
      volume: '408979.1499600000000000',
      quoteVolume: 19681383.60385065,
      btcVolume: 2212.021453381788,
      usdVolume: 19681383.60385065,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '49.2000000000000000',
      high: '50.0200000000000000',
      low: '46.6100000000000000',
      close: '48.0500000000000000',
      volume: '566317.3808100000000000',
      quoteVolume: 27433863.01719666,
      btcVolume: 3063.5466670768265,
      usdVolume: 27433863.01719666,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '48.0700000000000000',
      high: '48.2000000000000000',
      low: '45.1100000000000000',
      close: '46.9600000000000000',
      volume: '693367.0109200000000000',
      quoteVolume: 32216489.944114767,
      btcVolume: 3687.084305406875,
      usdVolume: 32216489.944114767,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '46.9700000000000000',
      high: '47.7600000000000000',
      low: '45.7100000000000000',
      close: '46.5400000000000000',
      volume: '415057.4376200000000000',
      quoteVolume: 19397806.104704224,
      btcVolume: 2173.7880200232094,
      usdVolume: 19397806.104704224,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '46.5600000000000000',
      high: '47.5500000000000000',
      low: '44.5500000000000000',
      close: '44.9400000000000000',
      volume: '466241.7278500000000000',
      quoteVolume: 21723146.905489907,
      btcVolume: 2368.579458205406,
      usdVolume: 21723146.905489907,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '44.9200000000000000',
      high: '48.2200000000000000',
      low: '44.3700000000000000',
      close: '47.3700000000000000',
      volume: '796061.5993300000000000',
      quoteVolume: 37063053.8901233,
      btcVolume: 3872.7360538266807,
      usdVolume: 37063053.8901233,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '47.3700000000000000',
      high: '48.4300000000000000',
      low: '46.3400000000000000',
      close: '47.6900000000000000',
      volume: '631882.8103600000000000',
      quoteVolume: 30005956.286906376,
      btcVolume: 3025.61774026537,
      usdVolume: 30005956.286906376,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '47.7000000000000000',
      high: '49.1000000000000000',
      low: '46.7500000000000000',
      close: '46.9500000000000000',
      volume: '656199.9985000000000000',
      quoteVolume: 31531059.60198355,
      btcVolume: 3242.069854321196,
      usdVolume: 31531059.60198355,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '46.9700000000000000',
      high: '47.0400000000000000',
      low: '39.4000000000000000',
      close: '42.0600000000000000',
      volume: '1404081.1754800000000000',
      quoteVolume: 58997967.78654685,
      btcVolume: 6799.973073584511,
      usdVolume: 58997967.78654685,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '42.0500000000000000',
      high: '42.9000000000000000',
      low: '39.3700000000000000',
      close: '41.5100000000000000',
      volume: '955643.7080500000000000',
      quoteVolume: 39459230.38420516,
      btcVolume: 4550.364445794178,
      usdVolume: 39459230.38420516,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '41.4900000000000000',
      high: '42.8800000000000000',
      low: '41.2600000000000000',
      close: '42.1800000000000000',
      volume: '598291.6399500000000000',
      quoteVolume: 25210189.417792954,
      btcVolume: 2873.5800124011444,
      usdVolume: 25210189.417792954,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '42.1800000000000000',
      high: '43.7400000000000000',
      low: '41.9400000000000000',
      close: '43.1800000000000000',
      volume: '401563.4178500000000000',
      quoteVolume: 17187427.88953854,
      btcVolume: 1892.7048671079617,
      usdVolume: 17187427.88953854,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '43.1800000000000000',
      high: '44.3800000000000000',
      low: '42.2100000000000000',
      close: '43.9800000000000000',
      volume: '507436.3497400000000000',
      quoteVolume: 22075904.008925483,
      btcVolume: 2303.152931180216,
      usdVolume: 22075904.008925483,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '43.9900000000000000',
      high: '44.1400000000000000',
      low: '41.8200000000000000',
      close: '42.7400000000000000',
      volume: '468825.9372000000000000',
      quoteVolume: 20205683.375076648,
      btcVolume: 2131.6645117325697,
      usdVolume: 20205683.375076648,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '42.7300000000000000',
      high: '43.8900000000000000',
      low: '42.5000000000000000',
      close: '43.2500000000000000',
      volume: '435940.4069700000000000',
      quoteVolume: 18877872.465006832,
      btcVolume: 2006.2006217660262,
      usdVolume: 18877872.465006832,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '43.2500000000000000',
      high: '44.4900000000000000',
      low: '43.1000000000000000',
      close: '43.7100000000000000',
      volume: '344451.6233800000000000',
      quoteVolume: 15152133.939513858,
      btcVolume: 1573.049518404476,
      usdVolume: 15152133.939513858,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '43.7000000000000000',
      high: '46.5900000000000000',
      low: '43.6400000000000000',
      close: '45.3200000000000000',
      volume: '694268.3291300000000000',
      quoteVolume: 31428313.748912312,
      btcVolume: 3215.1275437118916,
      usdVolume: 31428313.748912312,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '45.3300000000000000',
      high: '45.8000000000000000',
      low: '44.0700000000000000',
      close: '45.7300000000000000',
      volume: '375917.6522500000000000',
      quoteVolume: 16910574.08702791,
      btcVolume: 1747.0177395296405,
      usdVolume: 16910574.08702791,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '45.7300000000000000',
      high: '46.1400000000000000',
      low: '43.1700000000000000',
      close: '44.2500000000000000',
      volume: '546255.0060000000000000',
      quoteVolume: 24521814.476579513,
      btcVolume: 2542.608320507337,
      usdVolume: 24521814.476579513,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '44.2400000000000000',
      high: '44.6200000000000000',
      low: '41.6600000000000000',
      close: '42.7300000000000000',
      volume: '504821.8913700000000000',
      quoteVolume: 21790870.5584972,
      btcVolume: 2362.9361362732657,
      usdVolume: 21790870.5584972,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '42.7200000000000000',
      high: '44.6800000000000000',
      low: '42.3500000000000000',
      close: '44.2300000000000000',
      volume: '398405.5385600000000000',
      quoteVolume: 17376646.5729942,
      btcVolume: 1903.3033669829076,
      usdVolume: 17376646.5729942,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '44.2200000000000000',
      high: '44.7400000000000000',
      low: '43.6700000000000000',
      close: '43.8500000000000000',
      volume: '237463.4446900000000000',
      quoteVolume: 10482358.645321624,
      btcVolume: 1139.0076516333727,
      usdVolume: 10482358.645321624,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '43.8400000000000000',
      high: '44.5500000000000000',
      low: '42.0400000000000000',
      close: '42.0800000000000000',
      volume: '360033.5515400000000000',
      quoteVolume: 15679863.525856707,
      btcVolume: 1726.0693649842788,
      usdVolume: 15679863.525856707,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '42.0800000000000000',
      high: '43.3200000000000000',
      low: '41.7200000000000000',
      close: '42.9700000000000000',
      volume: '250914.5274100000000000',
      quoteVolume: 10724204.717699511,
      btcVolume: 1217.8718797560175,
      usdVolume: 10724204.717699511,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '42.9900000000000000',
      high: '43.0700000000000000',
      low: '41.7600000000000000',
      close: '42.2700000000000000',
      volume: '218690.0446300000000000',
      quoteVolume: 9289007.752625285,
      btcVolume: 1049.1031437450883,
      usdVolume: 9289007.752625285,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '42.2600000000000000',
      high: '44.0500000000000000',
      low: '42.2200000000000000',
      close: '43.8500000000000000',
      volume: '282807.4623400000000000',
      quoteVolume: 12241409.404598255,
      btcVolume: 1350.5834483933272,
      usdVolume: 12241409.404598255,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '43.8600000000000000',
      high: '44.8800000000000000',
      low: '43.1500000000000000',
      close: '44.7700000000000000',
      volume: '291358.4733600000000000',
      quoteVolume: 12814294.25871001,
      btcVolume: 1372.8438156303184,
      usdVolume: 12814294.25871001,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '44.7700000000000000',
      high: '45.2900000000000000',
      low: '44.1700000000000000',
      close: '44.4700000000000000',
      volume: '298461.3751300000000000',
      quoteVolume: 13345930.015912458,
      btcVolume: 1409.140378403909,
      usdVolume: 13345930.015912458,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '44.4900000000000000',
      high: '48.0800000000000000',
      low: '44.1800000000000000',
      close: '47.7200000000000000',
      volume: '590070.5535000000000000',
      quoteVolume: 27463513.679888483,
      btcVolume: 2881.444155580501,
      usdVolume: 27463513.679888483,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '47.7200000000000000',
      high: '47.8400000000000000',
      low: '45.0700000000000000',
      close: '45.6100000000000000',
      volume: '494076.1867300000000000',
      quoteVolume: 23046362.233182173,
      btcVolume: 2414.446392609635,
      usdVolume: 23046362.233182173,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '45.6000000000000000',
      high: '48.8100000000000000',
      low: '45.3600000000000000',
      close: '48.2500000000000000',
      volume: '599583.6772200000000000',
      quoteVolume: 28169093.711731534,
      btcVolume: 2895.4119659902226,
      usdVolume: 28169093.711731534,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '48.2600000000000000',
      high: '49.9600000000000000',
      low: '44.0000000000000000',
      close: '45.9900000000000000',
      volume: '920374.0312200000000000',
      quoteVolume: 43606491.94942139,
      btcVolume: 4436.165811723226,
      usdVolume: 43606491.94942139,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '47.5000000000000000',
      low: '45.2500000000000000',
      close: '47.1600000000000000',
      volume: '428190.8216400000000000',
      quoteVolume: 19934098.49867122,
      btcVolume: 2086.467816317527,
      usdVolume: 19934098.49867122,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '47.1500000000000000',
      high: '48.1000000000000000',
      low: '46.1500000000000000',
      close: '47.4300000000000000',
      volume: '471692.4156600000000000',
      quoteVolume: 22364295.281365484,
      btcVolume: 2310.0219355528084,
      usdVolume: 22364295.281365484,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '47.4300000000000000',
      high: '47.9800000000000000',
      low: '46.4200000000000000',
      close: '46.8300000000000000',
      volume: '400835.1846600000000000',
      quoteVolume: 18969882.083338924,
      btcVolume: 1945.0400952427456,
      usdVolume: 18969882.083338924,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '46.8000000000000000',
      high: '47.2400000000000000',
      low: '46.2000000000000000',
      close: '46.7900000000000000',
      volume: '195688.0406400000000000',
      quoteVolume: 9141881.677323475,
      btcVolume: 948.8992424450283,
      usdVolume: 9141881.677323475,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '46.7900000000000000',
      high: '46.9800000000000000',
      low: '45.0600000000000000',
      close: '46.6300000000000000',
      volume: '306262.5414800000000000',
      quoteVolume: 14151417.896596055,
      btcVolume: 1473.8708394166745,
      usdVolume: 14151417.896596055,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '46.6000000000000000',
      high: '46.7900000000000000',
      low: '45.9500000000000000',
      close: '46.4300000000000000',
      volume: '220499.0699300000000000',
      quoteVolume: 10227376.443103297,
      btcVolume: 1051.937083531138,
      usdVolume: 10227376.443103297,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '46.4500000000000000',
      high: '46.8400000000000000',
      low: '45.3100000000000000',
      close: '45.8700000000000000',
      volume: '219582.2168000000000000',
      quoteVolume: 10105379.596295863,
      btcVolume: 1040.202431965599,
      usdVolume: 10105379.596295863,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '45.8900000000000000',
      high: '47.1900000000000000',
      low: '45.6500000000000000',
      close: '46.6300000000000000',
      volume: '319562.9294800000000000',
      quoteVolume: 14778011.431747977,
      btcVolume: 1507.8102529037167,
      usdVolume: 14778011.431747977,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '46.6300000000000000',
      high: '46.9600000000000000',
      low: '42.0000000000000000',
      close: '43.2200000000000000',
      volume: '538312.5504400000000000',
      quoteVolume: 24117129.0557672,
      btcVolume: 2526.1064285493794,
      usdVolume: 24117129.0557672,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '43.2200000000000000',
      high: '45.3600000000000000',
      low: '42.8100000000000000',
      close: '44.7200000000000000',
      volume: '236637.2390700000000000',
      quoteVolume: 10478472.454529103,
      btcVolume: 1112.938106242603,
      usdVolume: 10478472.454529103,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '44.7200000000000000',
      high: '45.2200000000000000',
      low: '44.2700000000000000',
      close: '45.0800000000000000',
      volume: '151582.8051800000000000',
      quoteVolume: 6775955.931185166,
      btcVolume: 718.694564235063,
      usdVolume: 6775955.931185166,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '45.0900000000000000',
      high: '45.1100000000000000',
      low: '43.5300000000000000',
      close: '44.0500000000000000',
      volume: '170082.7402900000000000',
      quoteVolume: 7542598.776623745,
      btcVolume: 802.8177727597499,
      usdVolume: 7542598.776623745,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '44.0400000000000000',
      high: '44.0900000000000000',
      low: '41.7500000000000000',
      close: '43.7300000000000000',
      volume: '355287.5401600000000000',
      quoteVolume: 15285580.752425862,
      btcVolume: 1659.6268802012912,
      usdVolume: 15285580.752425862,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '43.7300000000000000',
      high: '44.2200000000000000',
      low: '43.3300000000000000',
      close: '43.8800000000000000',
      volume: '215557.5907300000000000',
      quoteVolume: 9436824.369403064,
      btcVolume: 993.2645058025514,
      usdVolume: 9436824.369403064,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '43.9000000000000000',
      high: '45.0400000000000000',
      low: '43.0000000000000000',
      close: '44.0700000000000000',
      volume: '324893.8614200000000000',
      quoteVolume: 14346683.24048972,
      btcVolume: 1519.1996398986237,
      usdVolume: 14346683.24048972,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '44.0700000000000000',
      high: '44.2300000000000000',
      low: '42.8800000000000000',
      close: '43.4300000000000000',
      volume: '193534.9893800000000000',
      quoteVolume: 8454598.742349433,
      btcVolume: 898.8378626695845,
      usdVolume: 8454598.742349433,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '43.4300000000000000',
      high: '43.7300000000000000',
      low: '42.5700000000000000',
      close: '42.8900000000000000',
      volume: '304810.9753600000000000',
      quoteVolume: 13177549.12879669,
      btcVolume: 1412.1592884165075,
      usdVolume: 13177549.12879669,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '42.8900000000000000',
      high: '43.8500000000000000',
      low: '42.2100000000000000',
      close: '43.5700000000000000',
      volume: '202428.9463000000000000',
      quoteVolume: 8709765.120769074,
      btcVolume: 936.1793726946369,
      usdVolume: 8709765.120769074,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '43.5700000000000000',
      high: '43.8300000000000000',
      low: '42.8800000000000000',
      close: '43.0000000000000000',
      volume: '159632.6785000000000000',
      quoteVolume: 6913195.771169413,
      btcVolume: 739.0315965348125,
      usdVolume: 6913195.771169413,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '43.0000000000000000',
      high: '44.6700000000000000',
      low: '42.9400000000000000',
      close: '44.3000000000000000',
      volume: '281062.1117900000000000',
      quoteVolume: 12346345.856977155,
      btcVolume: 1299.505700098188,
      usdVolume: 12346345.856977155,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '44.3000000000000000',
      high: '44.4900000000000000',
      low: '43.8900000000000000',
      close: '44.1800000000000000',
      volume: '219363.9022300000000000',
      quoteVolume: 9684310.145484513,
      btcVolume: 1004.7333264570984,
      usdVolume: 9684310.145484513,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '44.1800000000000000',
      high: '44.6900000000000000',
      low: '42.1000000000000000',
      close: '42.6500000000000000',
      volume: '357937.9722300000000000',
      quoteVolume: 15461096.439797288,
      btcVolume: 1637.8654486722658,
      usdVolume: 15461096.439797288,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '42.6500000000000000',
      high: '43.0000000000000000',
      low: '41.6800000000000000',
      close: '42.3500000000000000',
      volume: '241972.0063200000000000',
      quoteVolume: 10274173.075952465,
      btcVolume: 1114.9908980390967,
      usdVolume: 10274173.075952465,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '42.3400000000000000',
      high: '43.6000000000000000',
      low: '41.3700000000000000',
      close: '43.0900000000000000',
      volume: '348379.3200800000000000',
      quoteVolume: 14852775.950750457,
      btcVolume: 1618.6751008852393,
      usdVolume: 14852775.950750457,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '43.1000000000000000',
      high: '43.1200000000000000',
      low: '39.1100000000000000',
      close: '41.0600000000000000',
      volume: '301504.6343000000000000',
      quoteVolume: 12500262.768287318,
      btcVolume: 1381.4322759722381,
      usdVolume: 12500262.768287318,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '41.0400000000000000',
      high: '42.0500000000000000',
      low: '40.4800000000000000',
      close: '41.3700000000000000',
      volume: '169011.9868500000000000',
      quoteVolume: 6984592.714551371,
      btcVolume: 769.65230482301,
      usdVolume: 6984592.714551371,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '41.3700000000000000',
      high: '42.2900000000000000',
      low: '40.5900000000000000',
      close: '41.8700000000000000',
      volume: '323346.1720000000000000',
      quoteVolume: 13400088.961560369,
      btcVolume: 1466.6645153508061,
      usdVolume: 13400088.961560369,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '41.8700000000000000',
      high: '42.0700000000000000',
      low: '40.7300000000000000',
      close: '41.1700000000000000',
      volume: '170712.3676100000000000',
      quoteVolume: 7047775.850108025,
      btcVolume: 770.4331270254231,
      usdVolume: 7047775.850108025,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '41.1600000000000000',
      high: '42.0100000000000000',
      low: '40.9600000000000000',
      close: '41.7700000000000000',
      volume: '189395.4022900000000000',
      quoteVolume: 7873933.718510014,
      btcVolume: 856.8015905668915,
      usdVolume: 7873933.718510014,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '41.7600000000000000',
      high: '41.9000000000000000',
      low: '40.2800000000000000',
      close: '41.0500000000000000',
      volume: '274520.0541700000000000',
      quoteVolume: 11294890.195721142,
      btcVolume: 1236.9147850457389,
      usdVolume: 11294890.195721142,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '41.0300000000000000',
      high: '41.7000000000000000',
      low: '40.8900000000000000',
      close: '41.1400000000000000',
      volume: '188681.9363500000000000',
      quoteVolume: 7794144.723730499,
      btcVolume: 857.0715613494609,
      usdVolume: 7794144.723730499,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '41.1300000000000000',
      high: '42.2300000000000000',
      low: '41.0600000000000000',
      close: '42.1100000000000000',
      volume: '224248.6520000000000000',
      quoteVolume: 9360266.498081032,
      btcVolume: 1028.5227163525683,
      usdVolume: 9360266.498081032,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '42.1100000000000000',
      high: '42.2000000000000000',
      low: '40.7500000000000000',
      close: '41.5800000000000000',
      volume: '226360.8260500000000000',
      quoteVolume: 9392173.28080973,
      btcVolume: 1037.076979817458,
      usdVolume: 9392173.28080973,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '41.5800000000000000',
      high: '44.3100000000000000',
      low: '41.5000000000000000',
      close: '44.0600000000000000',
      volume: '368489.7151600000000000',
      quoteVolume: 15857759.209187848,
      btcVolume: 1716.882729781821,
      usdVolume: 15857759.209187848,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '44.0700000000000000',
      high: '44.4900000000000000',
      low: '43.0600000000000000',
      close: '43.3900000000000000',
      volume: '307753.4437600000000000',
      quoteVolume: 13417182.820945997,
      btcVolume: 1447.5272221888722,
      usdVolume: 13417182.820945997,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '43.3900000000000000',
      high: '45.6200000000000000',
      low: '43.1000000000000000',
      close: '45.3500000000000000',
      volume: '527238.1869100000000000',
      quoteVolume: 23640875.868721846,
      btcVolume: 2531.3426117904874,
      usdVolume: 23640875.868721846,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '45.3500000000000000',
      high: '45.8700000000000000',
      low: '43.5700000000000000',
      close: '44.3300000000000000',
      volume: '413262.0522600000000000',
      quoteVolume: 18508468.305630103,
      btcVolume: 1984.9220768982761,
      usdVolume: 18508468.305630103,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '44.3400000000000000',
      high: '44.6400000000000000',
      low: '43.4400000000000000',
      close: '44.3000000000000000',
      volume: '256685.4454300000000000',
      quoteVolume: 11325710.672338482,
      btcVolume: 1231.7303880489342,
      usdVolume: 11325710.672338482,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '44.3200000000000000',
      high: '44.7700000000000000',
      low: '43.8500000000000000',
      close: '44.6900000000000000',
      volume: '175099.4520400000000000',
      quoteVolume: 7760961.70704716,
      btcVolume: 840.2449316980167,
      usdVolume: 7760961.70704716,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '44.6800000000000000',
      high: '45.3000000000000000',
      low: '43.7000000000000000',
      close: '44.7000000000000000',
      volume: '245471.1214700000000000',
      quoteVolume: 10954049.958728487,
      btcVolume: 1184.221349967855,
      usdVolume: 10954049.958728487,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '44.7200000000000000',
      high: '45.5900000000000000',
      low: '42.3100000000000000',
      close: '43.9200000000000000',
      volume: '445103.8423000000000000',
      quoteVolume: 19783707.239249174,
      btcVolume: 2134.0023954961785,
      usdVolume: 19783707.239249174,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '43.9100000000000000',
      high: '44.0900000000000000',
      low: '42.8600000000000000',
      close: '43.8800000000000000',
      volume: '246958.9888000000000000',
      quoteVolume: 10753439.352485811,
      btcVolume: 1167.1898197526466,
      usdVolume: 10753439.352485811,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '43.8800000000000000',
      high: '44.0600000000000000',
      low: '42.8100000000000000',
      close: '43.3300000000000000',
      volume: '216931.7592000000000000',
      quoteVolume: 9414665.121901916,
      btcVolume: 1020.4055870355884,
      usdVolume: 9414665.121901916,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '43.3400000000000000',
      high: '43.4900000000000000',
      low: '40.8500000000000000',
      close: '42.0900000000000000',
      volume: '389747.3708100000000000',
      quoteVolume: 16388849.494132191,
      btcVolume: 1793.8588392303009,
      usdVolume: 16388849.494132191,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '42.0900000000000000',
      high: '42.4900000000000000',
      low: '41.7200000000000000',
      close: '41.9000000000000000',
      volume: '201168.4514700000000000',
      quoteVolume: 8451330.732305363,
      btcVolume: 925.0899826232296,
      usdVolume: 8451330.732305363,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '41.8800000000000000',
      high: '42.9000000000000000',
      low: '41.7300000000000000',
      close: '42.4500000000000000',
      volume: '171429.4827800000000000',
      quoteVolume: 7261317.07974402,
      btcVolume: 792.6440309584311,
      usdVolume: 7261317.07974402,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '42.4500000000000000',
      high: '42.8900000000000000',
      low: '41.9400000000000000',
      close: '42.6400000000000000',
      volume: '131250.1648000000000000',
      quoteVolume: 5562354.392689597,
      btcVolume: 607.4853703600584,
      usdVolume: 5562354.392689597,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '42.6500000000000000',
      high: '42.7900000000000000',
      low: '41.3700000000000000',
      close: '41.9400000000000000',
      volume: '180313.8811200000000000',
      quoteVolume: 7608229.1871632915,
      btcVolume: 829.548965830741,
      usdVolume: 7608229.1871632915,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '41.9400000000000000',
      high: '44.1100000000000000',
      low: '41.8700000000000000',
      close: '43.7700000000000000',
      volume: '314409.7580700000000000',
      quoteVolume: 13652466.385358201,
      btcVolume: 1464.4779697490103,
      usdVolume: 13652466.385358201,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '43.7700000000000000',
      high: '45.1200000000000000',
      low: '43.2000000000000000',
      close: '44.9600000000000000',
      volume: '335284.7529800000000000',
      quoteVolume: 14745570.92134318,
      btcVolume: 1567.9594825294864,
      usdVolume: 14745570.92134318,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '44.9600000000000000',
      high: '45.6300000000000000',
      low: '44.5200000000000000',
      close: '44.9400000000000000',
      volume: '432494.1135100000000000',
      quoteVolume: 19488260.358961288,
      btcVolume: 2044.7602331347512,
      usdVolume: 19488260.358961288,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '44.9300000000000000',
      high: '44.9600000000000000',
      low: '43.9300000000000000',
      close: '44.1100000000000000',
      volume: '246802.5687100000000000',
      quoteVolume: 10968239.44478951,
      btcVolume: 1148.885394620396,
      usdVolume: 10968239.44478951,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '44.1200000000000000',
      high: '49.5200000000000000',
      low: '44.0300000000000000',
      close: '49.1600000000000000',
      volume: '908436.8540300000000000',
      quoteVolume: 43121417.14661318,
      btcVolume: 4487.739810331088,
      usdVolume: 43121417.14661318,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '49.1600000000000000',
      high: '50.7900000000000000',
      low: '47.4900000000000000',
      close: '48.1000000000000000',
      volume: '895757.1956100000000000',
      quoteVolume: 43736445.02248403,
      btcVolume: 4445.709420722064,
      usdVolume: 43736445.02248403,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '48.0700000000000000',
      high: '55.8300000000000000',
      low: '46.8500000000000000',
      close: '53.2800000000000000',
      volume: '1501022.7139000000000000',
      quoteVolume: 76319950.40804677,
      btcVolume: 7196.4237752346735,
      usdVolume: 76319950.40804677,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '53.2900000000000000',
      high: '57.9200000000000000',
      low: '51.6500000000000000',
      close: '55.7500000000000000',
      volume: '1524796.9333900000000000',
      quoteVolume: 83657329.52223343,
      btcVolume: 7615.786009539288,
      usdVolume: 83657329.52223343,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '55.7600000000000000',
      high: '56.9700000000000000',
      low: '54.6500000000000000',
      close: '55.1600000000000000',
      volume: '827762.0175900000000000',
      quoteVolume: 46195373.44725716,
      btcVolume: 4170.565054592356,
      usdVolume: 46195373.44725716,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '55.1700000000000000',
      high: '58.6100000000000000',
      low: '53.9100000000000000',
      close: '56.7500000000000000',
      volume: '1023616.3229500000000000',
      quoteVolume: 57619092.418167934,
      btcVolume: 5228.698800494788,
      usdVolume: 57619092.418167934,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '56.7300000000000000',
      high: '59.2600000000000000',
      low: '55.7800000000000000',
      close: '58.1300000000000000',
      volume: '656214.7910400000000000',
      quoteVolume: 37799292.05627845,
      btcVolume: 3370.0313097064472,
      usdVolume: 37799292.05627845,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '58.1300000000000000',
      high: '62.2300000000000000',
      low: '57.5500000000000000',
      close: '61.6500000000000000',
      volume: '1209772.3068500000000000',
      quoteVolume: 72750836.99199294,
      btcVolume: 6265.125426344265,
      usdVolume: 72750836.99199294,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '61.6500000000000000',
      high: '65.1700000000000000',
      low: '51.7900000000000000',
      close: '56.7700000000000000',
      volume: '1811190.5919400000000000',
      quoteVolume: 106128081.26600313,
      btcVolume: 9335.06038904262,
      usdVolume: 106128081.26600313,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '56.7700000000000000',
      high: '60.0300000000000000',
      low: '55.7100000000000000',
      close: '58.4800000000000000',
      volume: '825040.8628300000000000',
      quoteVolume: 48042233.88053324,
      btcVolume: 4268.170005799202,
      usdVolume: 48042233.88053324,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '58.5100000000000000',
      high: '59.6200000000000000',
      low: '56.2600000000000000',
      close: '57.6100000000000000',
      volume: '621412.6135200000000000',
      quoteVolume: 35953617.97974933,
      btcVolume: 3203.314765910491,
      usdVolume: 35953617.97974933,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '57.6200000000000000',
      high: '59.7700000000000000',
      low: '56.7300000000000000',
      close: '58.8800000000000000',
      volume: '716365.4747300000000000',
      quoteVolume: 41978222.97824183,
      btcVolume: 3649.591519738918,
      usdVolume: 41978222.97824183,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '58.8800000000000000',
      high: '60.6800000000000000',
      low: '57.6900000000000000',
      close: '59.0300000000000000',
      volume: '822577.3453400000000000',
      quoteVolume: 48695999.688867584,
      btcVolume: 4138.214894335373,
      usdVolume: 48695999.688867584,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '59.0300000000000000',
      high: '61.0800000000000000',
      low: '54.5100000000000000',
      close: '57.1700000000000000',
      volume: '897778.4995300000000000',
      quoteVolume: 52381526.540909186,
      btcVolume: 4494.798690246006,
      usdVolume: 52381526.540909186,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '57.1900000000000000',
      high: '58.5500000000000000',
      low: '56.4600000000000000',
      close: '58.4600000000000000',
      volume: '428068.0562900000000000',
      quoteVolume: 24639956.16664136,
      btcVolume: 2111.277753614711,
      usdVolume: 24639956.16664136,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '58.4600000000000000',
      high: '58.6700000000000000',
      low: '56.0000000000000000',
      close: '56.9600000000000000',
      volume: '368126.2206100000000000',
      quoteVolume: 21112826.33196615,
      btcVolume: 1810.1238543621755,
      usdVolume: 21112826.33196615,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '56.9600000000000000',
      high: '59.4000000000000000',
      low: '56.3800000000000000',
      close: '58.2700000000000000',
      volume: '651148.7946500000000000',
      quoteVolume: 37767773.986994274,
      btcVolume: 3179.571790810527,
      usdVolume: 37767773.986994274,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '58.2700000000000000',
      high: '59.6700000000000000',
      low: '52.3700000000000000',
      close: '54.1000000000000000',
      volume: '887598.7192800000000000',
      quoteVolume: 49937919.23003544,
      btcVolume: 4305.809808019921,
      usdVolume: 49937919.23003544,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '54.1100000000000000',
      high: '55.0500000000000000',
      low: '51.5500000000000000',
      close: '54.5200000000000000',
      volume: '645092.4992200000000000',
      quoteVolume: 34861226.1993341,
      btcVolume: 3047.8872072032136,
      usdVolume: 34861226.1993341,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '54.5400000000000000',
      high: '57.3000000000000000',
      low: '51.8600000000000000',
      close: '57.1400000000000000',
      volume: '837108.8358500000000000',
      quoteVolume: 45688848.09087257,
      btcVolume: 3954.229249874195,
      usdVolume: 45688848.09087257,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '57.1400000000000000',
      high: '57.8500000000000000',
      low: '55.8200000000000000',
      close: '56.7800000000000000',
      volume: '724000.2630800000000000',
      quoteVolume: 41120118.16273497,
      btcVolume: 3499.1980018550985,
      usdVolume: 41120118.16273497,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '56.7800000000000000',
      high: '60.8700000000000000',
      low: '56.4500000000000000',
      close: '59.8500000000000000',
      volume: '1137795.1226200000000000',
      quoteVolume: 66736568.52031225,
      btcVolume: 5623.638209743656,
      usdVolume: 66736568.52031225,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '59.8500000000000000',
      high: '64.6000000000000000',
      low: '57.8000000000000000',
      close: '63.8700000000000000',
      volume: '1576897.5925500000000000',
      quoteVolume: 97000374.93973804,
      btcVolume: 8195.032534326576,
      usdVolume: 97000374.93973804,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '63.8900000000000000',
      high: '68.8800000000000000',
      low: '61.3400000000000000',
      close: '67.2200000000000000',
      volume: '1790813.8027600000000000',
      quoteVolume: 116245941.78525142,
      btcVolume: 9624.636426175008,
      usdVolume: 116245941.78525142,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '67.2200000000000000',
      high: '68.3700000000000000',
      low: '63.3700000000000000',
      close: '65.4000000000000000',
      volume: '1562910.4138300000000000',
      quoteVolume: 103317811.94231711,
      btcVolume: 8525.308883804713,
      usdVolume: 103317811.94231711,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '65.4200000000000000',
      high: '67.1500000000000000',
      low: '58.7300000000000000',
      close: '62.1200000000000000',
      volume: '1699324.3872900000000000',
      quoteVolume: 105697900.02547406,
      btcVolume: 8977.615215296333,
      usdVolume: 105697900.02547406,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '62.0800000000000000',
      high: '63.6800000000000000',
      low: '61.0000000000000000',
      close: '62.8000000000000000',
      volume: '863943.4751600000000000',
      quoteVolume: 54002617.98938122,
      btcVolume: 4582.333629079296,
      usdVolume: 54002617.98938122,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '62.8000000000000000',
      high: '63.9000000000000000',
      low: '58.7700000000000000',
      close: '59.2000000000000000',
      volume: '1052514.0798200000000000',
      quoteVolume: 64605524.54157742,
      btcVolume: 5505.111606281471,
      usdVolume: 64605524.54157742,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '59.1800000000000000',
      high: '60.5400000000000000',
      low: '57.7600000000000000',
      close: '60.2100000000000000',
      volume: '735565.0401700000000000',
      quoteVolume: 43554757.475364566,
      btcVolume: 3776.8163310064206,
      usdVolume: 43554757.475364566,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '60.2100000000000000',
      high: '61.6300000000000000',
      low: '58.0100000000000000',
      close: '60.5500000000000000',
      volume: '629684.8634600000000000',
      quoteVolume: 37576440.64409044,
      btcVolume: 3233.0834064375513,
      usdVolume: 37576440.64409044,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '60.5600000000000000',
      high: '63.0100000000000000',
      low: '59.9700000000000000',
      close: '62.0200000000000000',
      volume: '687059.2379900000000000',
      quoteVolume: 42496265.57367974,
      btcVolume: 3621.93782544044,
      usdVolume: 42496265.57367974,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '62.0300000000000000',
      high: '62.2900000000000000',
      low: '56.6000000000000000',
      close: '58.3400000000000000',
      volume: '1124054.9217200000000000',
      quoteVolume: 66441420.47979737,
      btcVolume: 5809.195150196892,
      usdVolume: 66441420.47979737,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '58.3300000000000000',
      high: '59.1000000000000000',
      low: '57.0200000000000000',
      close: '58.1000000000000000',
      volume: '663363.6882900000000000',
      quoteVolume: 38514355.9755069,
      btcVolume: 3381.1134622002087,
      usdVolume: 38514355.9755069,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '58.1100000000000000',
      high: '59.2400000000000000',
      low: '53.9100000000000000',
      close: '56.1000000000000000',
      volume: '973405.5399700000000000',
      quoteVolume: 55038359.140136294,
      btcVolume: 4861.832698425037,
      usdVolume: 55038359.140136294,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '56.1200000000000000',
      high: '58.2800000000000000',
      low: '55.3300000000000000',
      close: '57.3800000000000000',
      volume: '637283.3690700000000000',
      quoteVolume: 36330743.38773648,
      btcVolume: 3176.5984724538266,
      usdVolume: 36330743.38773648,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '57.3600000000000000',
      high: '58.1800000000000000',
      low: '56.9300000000000000',
      close: '56.9600000000000000',
      volume: '401337.7699700000000000',
      quoteVolume: 23096156.99058844,
      btcVolume: 2008.145085599693,
      usdVolume: 23096156.99058844,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '56.9400000000000000',
      high: '62.9500000000000000',
      low: '56.9400000000000000',
      close: '62.8900000000000000',
      volume: '748060.0688700000000000',
      quoteVolume: 45090938.55684995,
      btcVolume: 3882.7301382338846,
      usdVolume: 45090938.55684995,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '62.8900000000000000',
      high: '63.7000000000000000',
      low: '60.5100000000000000',
      close: '60.9200000000000000',
      volume: '680146.7862100000000000',
      quoteVolume: 42096579.01839404,
      btcVolume: 3604.5811924799323,
      usdVolume: 42096579.01839404,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '60.9400000000000000',
      high: '64.3100000000000000',
      low: '59.6900000000000000',
      close: '62.6900000000000000',
      volume: '858839.7179200000000000',
      quoteVolume: 53770235.135081835,
      btcVolume: 4530.7995896947705,
      usdVolume: 53764694.36727703,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '62.6800000000000000',
      high: '63.1900000000000000',
      low: '56.3200000000000000',
      close: '58.0600000000000000',
      volume: '931097.5278800000000000',
      quoteVolume: 55440952.94712479,
      btcVolume: 4813.422832983661,
      usdVolume: 55440952.94712479,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '58.0700000000000000',
      high: '58.6200000000000000',
      low: '45.3300000000000000',
      close: '47.2800000000000000',
      volume: '1314623.3469700000000000',
      quoteVolume: 70288090.06395356,
      btcVolume: 6485.293399090235,
      usdVolume: 70288090.06395356,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '47.2800000000000000',
      high: '52.3800000000000000',
      low: '46.2400000000000000',
      close: '50.3200000000000000',
      volume: '1475370.2928000000000000',
      quoteVolume: 72616532.67811747,
      btcVolume: 7023.38061709872,
      usdVolume: 72616532.67811747,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '50.3300000000000000',
      high: '51.3200000000000000',
      low: '45.2100000000000000',
      close: '47.8300000000000000',
      volume: '1350136.6749200000000000',
      quoteVolume: 64913830.12016042,
      btcVolume: 6374.162126272181,
      usdVolume: 64913830.12016042,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '47.8200000000000000',
      high: '49.2500000000000000',
      low: '46.2800000000000000',
      close: '47.9200000000000000',
      volume: '652628.8199300000000000',
      quoteVolume: 31304000.690226022,
      btcVolume: 3068.8890835351963,
      usdVolume: 31304000.690226022,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '47.9300000000000000',
      high: '49.1800000000000000',
      low: '45.2200000000000000',
      close: '48.8400000000000000',
      volume: '811120.8419300000000000',
      quoteVolume: 38431332.983129896,
      btcVolume: 3795.0340089993906,
      usdVolume: 38431332.983129896,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '48.8500000000000000',
      high: '49.3500000000000000',
      low: '45.9800000000000000',
      close: '47.4700000000000000',
      volume: '760182.5056400000000000',
      quoteVolume: 36217916.465697624,
      btcVolume: 3579.288705742158,
      usdVolume: 36217916.465697624,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '47.4700000000000000',
      high: '48.8100000000000000',
      low: '46.5200000000000000',
      close: '47.8200000000000000',
      volume: '378772.5075900000000000',
      quoteVolume: 18086146.485945318,
      btcVolume: 1776.629629881756,
      usdVolume: 18086146.485945318,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '47.8200000000000000',
      high: '49.4500000000000000',
      low: '47.7700000000000000',
      close: '48.6700000000000000',
      volume: '472784.4530600000000000',
      quoteVolume: 23042794.85121679,
      btcVolume: 2228.667738928336,
      usdVolume: 23042794.85121679,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '48.6500000000000000',
      high: '49.5900000000000000',
      low: '47.3700000000000000',
      close: '48.9600000000000000',
      volume: '367092.2208900000000000',
      quoteVolume: 17795692.856570706,
      btcVolume: 1728.2423967171549,
      usdVolume: 17795692.856570706,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '48.9500000000000000',
      high: '51.3100000000000000',
      low: '48.3800000000000000',
      close: '50.8000000000000000',
      volume: '451940.7079000000000000',
      quoteVolume: 22526331.35838648,
      btcVolume: 2165.612033112267,
      usdVolume: 22526331.35838648,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '50.8200000000000000',
      high: '51.0400000000000000',
      low: '47.2600000000000000',
      close: '48.1500000000000000',
      volume: '458828.4182100000000000',
      quoteVolume: 22491968.402588304,
      btcVolume: 2169.5843476754585,
      usdVolume: 22491968.402588304,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '48.1700000000000000',
      high: '49.6500000000000000',
      low: '47.1100000000000000',
      close: '49.0800000000000000',
      volume: '493683.7537500000000000',
      quoteVolume: 23889800.051954094,
      btcVolume: 2275.1908831815435,
      usdVolume: 23889800.051954094,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '49.0800000000000000',
      high: '50.3700000000000000',
      low: '48.1000000000000000',
      close: '48.3100000000000000',
      volume: '615374.4693200000000000',
      quoteVolume: 30324133.006873846,
      btcVolume: 2816.2117686878037,
      usdVolume: 30324133.006873846,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '48.2900000000000000',
      high: '48.7500000000000000',
      low: '46.9100000000000000',
      close: '47.7600000000000000',
      volume: '409293.5778300000000000',
      quoteVolume: 19641762.865069613,
      btcVolume: 1806.5374075469983,
      usdVolume: 19641762.865069613,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '47.7600000000000000',
      high: '49.6700000000000000',
      low: '47.6400000000000000',
      close: '48.8500000000000000',
      volume: '412392.2905700000000000',
      quoteVolume: 20027421.966672275,
      btcVolume: 1836.6245811757067,
      usdVolume: 20027421.966672275,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '48.8500000000000000',
      high: '49.0700000000000000',
      low: '47.4400000000000000',
      close: '48.5400000000000000',
      volume: '322351.6253900000000000',
      quoteVolume: 15606108.2084333,
      btcVolume: 1428.8963360394227,
      usdVolume: 15606108.2084333,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '48.5300000000000000',
      high: '49.0300000000000000',
      low: '47.9400000000000000',
      close: '48.5200000000000000',
      volume: '225043.6954300000000000',
      quoteVolume: 10911627.63864736,
      btcVolume: 989.4437357340863,
      usdVolume: 10911627.63864736,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '48.5100000000000000',
      high: '48.5100000000000000',
      low: '46.1500000000000000',
      close: '47.0900000000000000',
      volume: '307000.2131400000000000',
      quoteVolume: 14530136.545319801,
      btcVolume: 1333.6149737245325,
      usdVolume: 14530136.545319801,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '47.1000000000000000',
      high: '47.6400000000000000',
      low: '41.5900000000000000',
      close: '43.1600000000000000',
      volume: '724381.6674200000000000',
      quoteVolume: 32251827.730186,
      btcVolume: 3041.5168729433744,
      usdVolume: 32251827.730186,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '43.1700000000000000',
      high: '44.7900000000000000',
      low: '42.7500000000000000',
      close: '44.4700000000000000',
      volume: '333108.9347300000000000',
      quoteVolume: 14589016.845933013,
      btcVolume: 1393.625461476955,
      usdVolume: 14589016.845933013,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '44.4700000000000000',
      high: '45.1400000000000000',
      low: '42.2500000000000000',
      close: '42.9600000000000000',
      volume: '416831.7164900000000000',
      quoteVolume: 18334844.49414594,
      btcVolume: 1763.5199781287474,
      usdVolume: 18334844.49414594,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '42.9900000000000000',
      high: '45.2900000000000000',
      low: '42.5600000000000000',
      close: '44.9200000000000000',
      volume: '384786.2813000000000000',
      quoteVolume: 16990572.907504234,
      btcVolume: 1626.0734649737904,
      usdVolume: 16990572.907504234,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '44.9200000000000000',
      high: '46.5800000000000000',
      low: '43.9700000000000000',
      close: '46.0200000000000000',
      volume: '417026.3309900000000000',
      quoteVolume: 18981897.443485588,
      btcVolume: 1782.2563567218074,
      usdVolume: 18981897.443485588,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '46.5600000000000000',
      low: '45.1200000000000000',
      close: '46.0300000000000000',
      volume: '239753.2724600000000000',
      quoteVolume: 11008649.377640275,
      btcVolume: 1027.554331921975,
      usdVolume: 11008649.377640275,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '46.6200000000000000',
      low: '44.5600000000000000',
      close: '46.0500000000000000',
      volume: '299418.6488900000000000',
      quoteVolume: 13705245.681490649,
      btcVolume: 1279.5801104718228,
      usdVolume: 13705245.681490649,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '46.0500000000000000',
      high: '47.1400000000000000',
      low: '44.8000000000000000',
      close: '45.2300000000000000',
      volume: '500207.2502000000000000',
      quoteVolume: 23194876.99856291,
      btcVolume: 2133.7172755091074,
      usdVolume: 23194876.99856291,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '45.2300000000000000',
      high: '45.8500000000000000',
      low: '44.9500000000000000',
      close: '45.6900000000000000',
      volume: '314647.6859400000000000',
      quoteVolume: 14294098.56854367,
      btcVolume: 1332.3540477383756,
      usdVolume: 14294098.56854367,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '45.6800000000000000',
      high: '46.7000000000000000',
      low: '45.4400000000000000',
      close: '46.2400000000000000',
      volume: '293684.9697200000000000',
      quoteVolume: 13501441.41659697,
      btcVolume: 1256.481594643728,
      usdVolume: 13501441.41659697,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '46.2600000000000000',
      high: '48.4600000000000000',
      low: '45.2300000000000000',
      close: '46.2500000000000000',
      volume: '678479.7253200000000000',
      quoteVolume: 31764172.418082513,
      btcVolume: 2962.4967676505066,
      usdVolume: 31764172.418082513,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '46.2000000000000000',
      high: '46.9700000000000000',
      low: '43.1900000000000000',
      close: '44.8800000000000000',
      volume: '514023.5243600000000000',
      quoteVolume: 23159362.416366585,
      btcVolume: 2205.834513704922,
      usdVolume: 23159362.416366585,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '44.8800000000000000',
      high: '45.9700000000000000',
      low: '44.7200000000000000',
      close: '45.5100000000000000',
      volume: '225825.7437200000000000',
      quoteVolume: 10251368.171526043,
      btcVolume: 972.2082293671139,
      usdVolume: 10251368.171526043,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '45.5000000000000000',
      high: '47.0700000000000000',
      low: '45.2800000000000000',
      close: '46.6900000000000000',
      volume: '257423.6618800000000000',
      quoteVolume: 11876813.22842714,
      btcVolume: 1119.7951342568836,
      usdVolume: 11876813.22842714,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '46.6800000000000000',
      high: '47.0800000000000000',
      low: '45.5300000000000000',
      close: '46.3400000000000000',
      volume: '287599.9852800000000000',
      quoteVolume: 13304869.255124534,
      btcVolume: 1243.5815826726844,
      usdVolume: 13304869.255124534,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '46.3500000000000000',
      high: '48.2500000000000000',
      low: '45.5200000000000000',
      close: '45.9900000000000000',
      volume: '650695.8909300000000000',
      quoteVolume: 30393271.881367873,
      btcVolume: 2846.7976903218064,
      usdVolume: 30393271.881367873,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '46.0100000000000000',
      high: '46.9600000000000000',
      low: '45.0200000000000000',
      close: '46.5500000000000000',
      volume: '384592.7193700000000000',
      quoteVolume: 17704976.198836096,
      btcVolume: 1667.6503606006302,
      usdVolume: 17704976.198836096,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '46.5600000000000000',
      high: '47.5000000000000000',
      low: '45.4500000000000000',
      close: '47.2100000000000000',
      volume: '401592.8257000000000000',
      quoteVolume: 18706424.61098511,
      btcVolume: 1743.2242091557664,
      usdVolume: 18706424.61098511,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '47.2100000000000000',
      high: '48.2000000000000000',
      low: '46.5900000000000000',
      close: '47.7800000000000000',
      volume: '402514.8916800000000000',
      quoteVolume: 19072193.160766963,
      btcVolume: 1736.864381218933,
      usdVolume: 19072193.160766963,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '47.7800000000000000',
      high: '50.4600000000000000',
      low: '47.7600000000000000',
      close: '48.8800000000000000',
      volume: '426163.7387700000000000',
      quoteVolume: 21004622.662101585,
      btcVolume: 1853.0414493767137,
      usdVolume: 21004622.662101585,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '48.8900000000000000',
      high: '51.2700000000000000',
      low: '48.6600000000000000',
      close: '50.4800000000000000',
      volume: '389959.9119800000000000',
      quoteVolume: 19591405.274990585,
      btcVolume: 1723.361127572673,
      usdVolume: 19591405.274990585,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '50.4800000000000000',
      high: '51.5000000000000000',
      low: '49.2400000000000000',
      close: '50.5800000000000000',
      volume: '547647.7248700000000000',
      quoteVolume: 27687729.169129193,
      btcVolume: 2420.2362706464387,
      usdVolume: 27687729.169129193,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '50.5800000000000000',
      high: '50.7100000000000000',
      low: '48.8000000000000000',
      close: '49.8900000000000000',
      volume: '415337.0570800000000000',
      quoteVolume: 20736639.884147868,
      btcVolume: 1812.348882829239,
      usdVolume: 20736639.884147868,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '49.9000000000000000',
      high: '51.0400000000000000',
      low: '49.2700000000000000',
      close: '49.7900000000000000',
      volume: '331002.8019200000000000',
      quoteVolume: 16584880.285257336,
      btcVolume: 1454.7096946875001,
      usdVolume: 16584880.285257336,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '49.7900000000000000',
      high: '50.2400000000000000',
      low: '48.8000000000000000',
      close: '49.5300000000000000',
      volume: '348137.2278700000000000',
      quoteVolume: 17261154.582352445,
      btcVolume: 1514.5854746239897,
      usdVolume: 17261154.582352445,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '49.5200000000000000',
      high: '49.8400000000000000',
      low: '45.6000000000000000',
      close: '47.6000000000000000',
      volume: '603775.0778600000000000',
      quoteVolume: 28593842.01842037,
      btcVolume: 2521.7754293189723,
      usdVolume: 28593842.01842037,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '47.6100000000000000',
      high: '47.6400000000000000',
      low: '46.2800000000000000',
      close: '46.9300000000000000',
      volume: '222889.3661200000000000',
      quoteVolume: 10493526.272867309,
      btcVolume: 925.8634715761125,
      usdVolume: 10493526.272867309,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '46.9200000000000000',
      high: '47.9800000000000000',
      low: '46.7800000000000000',
      close: '47.5400000000000000',
      volume: '164733.2730500000000000',
      quoteVolume: 7805904.884538982,
      btcVolume: 683.1095831981876,
      usdVolume: 7805904.884538982,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '47.5400000000000000',
      high: '48.4400000000000000',
      low: '46.8000000000000000',
      close: '47.9900000000000000',
      volume: '316808.8316300000000000',
      quoteVolume: 15130105.410249878,
      btcVolume: 1303.2892230318398,
      usdVolume: 15130105.410249878,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '47.9900000000000000',
      high: '48.4600000000000000',
      low: '46.3600000000000000',
      close: '46.8800000000000000',
      volume: '399229.1667700000000000',
      quoteVolume: 18895656.11728302,
      btcVolume: 1594.7065459227063,
      usdVolume: 18895656.11728302,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '46.8800000000000000',
      high: '55.0000000000000000',
      low: '46.8500000000000000',
      close: '52.9800000000000000',
      volume: '1425655.0175000000000000',
      quoteVolume: 73309576.45552866,
      btcVolume: 5835.867276617416,
      usdVolume: 73309576.45552866,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '52.9800000000000000',
      high: '56.3100000000000000',
      low: '52.9700000000000000',
      close: '54.3500000000000000',
      volume: '1405462.7678200000000000',
      quoteVolume: 77304160.5002028,
      btcVolume: 5998.580339830083,
      usdVolume: 77304160.5002028,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '54.3500000000000000',
      high: '57.0000000000000000',
      low: '53.0200000000000000',
      close: '55.4200000000000000',
      volume: '955931.4407600000000000',
      quoteVolume: 52751563.52434198,
      btcVolume: 4083.2913725414433,
      usdVolume: 52751563.52434198,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '55.4100000000000000',
      high: '59.6400000000000000',
      low: '54.8300000000000000',
      close: '59.2300000000000000',
      volume: '945743.9995400000000000',
      quoteVolume: 54668377.69328972,
      btcVolume: 4200.363593327066,
      usdVolume: 54668377.69328972,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '59.2200000000000000',
      high: '60.0000000000000000',
      low: '56.5300000000000000',
      close: '58.8400000000000000',
      volume: '906306.0174900000000000',
      quoteVolume: 52834489.40751223,
      btcVolume: 4053.9574657856656,
      usdVolume: 52834489.40751223,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '58.8300000000000000',
      high: '59.3100000000000000',
      low: '54.9400000000000000',
      close: '56.8200000000000000',
      volume: '937112.5894400000000000',
      quoteVolume: 53652363.95019294,
      btcVolume: 4114.583402151287,
      usdVolume: 53652363.95019294,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '56.8000000000000000',
      high: '59.0600000000000000',
      low: '56.4600000000000000',
      close: '57.9100000000000000',
      volume: '841336.6319200000000000',
      quoteVolume: 48609139.49673568,
      btcVolume: 3630.1006218151615,
      usdVolume: 48609139.49673568,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '57.9000000000000000',
      high: '60.5500000000000000',
      low: '54.7100000000000000',
      close: '55.7700000000000000',
      volume: '1108813.1369600000000000',
      quoteVolume: 63758393.118976705,
      btcVolume: 4749.631359213374,
      usdVolume: 63758393.118976705,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '55.7600000000000000',
      high: '56.5700000000000000',
      low: '53.3700000000000000',
      close: '54.8700000000000000',
      volume: '798253.7344200000000000',
      quoteVolume: 44138399.06422936,
      btcVolume: 3326.956263048808,
      usdVolume: 44138399.06422936,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '54.8800000000000000',
      high: '55.7800000000000000',
      low: '52.2000000000000000',
      close: '54.0100000000000000',
      volume: '706074.8976900000000000',
      quoteVolume: 38012823.48380605,
      btcVolume: 2842.8179458148893,
      usdVolume: 38012823.48380605,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '54.0200000000000000',
      high: '56.5000000000000000',
      low: '53.6000000000000000',
      close: '55.7200000000000000',
      volume: '568503.1681100000000000',
      quoteVolume: 31438490.218596324,
      btcVolume: 2284.8493085352666,
      usdVolume: 31438490.218596324,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '55.6900000000000000',
      high: '56.1900000000000000',
      low: '54.0700000000000000',
      close: '55.6000000000000000',
      volume: '369373.8115100000000000',
      quoteVolume: 20377654.865856357,
      btcVolume: 1481.5753298312095,
      usdVolume: 20377654.865856357,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '55.6000000000000000',
      high: '56.8400000000000000',
      low: '53.0100000000000000',
      close: '53.8000000000000000',
      volume: '700498.4928000000000000',
      quoteVolume: 38304588.60460887,
      btcVolume: 2824.797492464806,
      usdVolume: 38304588.60460887,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '53.8200000000000000',
      high: '54.3700000000000000',
      low: '51.1300000000000000',
      close: '53.9600000000000000',
      volume: '671178.3424500000000000',
      quoteVolume: 35476946.64793883,
      btcVolume: 2616.935741127481,
      usdVolume: 35476946.64793883,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '53.9600000000000000',
      high: '55.2400000000000000',
      low: '51.5900000000000000',
      close: '54.6700000000000000',
      volume: '801009.7913300000000000',
      quoteVolume: 42873857.89305916,
      btcVolume: 3085.610113492847,
      usdVolume: 42873857.89305916,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '54.7000000000000000',
      high: '59.7300000000000000',
      low: '54.6200000000000000',
      close: '58.9500000000000000',
      volume: '1366335.6293900000000000',
      quoteVolume: 78053912.16519552,
      btcVolume: 5264.593238274786,
      usdVolume: 78053912.16519552,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '58.9400000000000000',
      high: '63.6800000000000000',
      low: '58.6100000000000000',
      close: '63.2000000000000000',
      volume: '1688091.0752800000000000',
      quoteVolume: 103709490.48633637,
      btcVolume: 6656.930874335542,
      usdVolume: 103709490.48633637,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '63.2200000000000000',
      high: '64.7700000000000000',
      low: '57.0000000000000000',
      close: '58.9000000000000000',
      volume: '1654668.9549600000000000',
      quoteVolume: 101602887.2770486,
      btcVolume: 6659.106944962225,
      usdVolume: 101602887.2770486,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '58.9000000000000000',
      high: '62.0300000000000000',
      low: '58.5200000000000000',
      close: '61.0200000000000000',
      volume: '794156.2220100000000000',
      quoteVolume: 47870580.79491664,
      btcVolume: 3150.0612302343625,
      usdVolume: 47870580.79491664,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '61.0000000000000000',
      high: '61.8600000000000000',
      low: '57.3700000000000000',
      close: '59.2300000000000000',
      volume: '997389.4548800000000000',
      quoteVolume: 59476114.155003875,
      btcVolume: 3879.134836223116,
      usdVolume: 59476114.155003875,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '59.2200000000000000',
      high: '59.6000000000000000',
      low: '57.0400000000000000',
      close: '57.9300000000000000',
      volume: '639971.9687300000000000',
      quoteVolume: 37332965.35725458,
      btcVolume: 2441.6074414528925,
      usdVolume: 37332965.35725458,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '57.9400000000000000',
      high: '60.5300000000000000',
      low: '57.8000000000000000',
      close: '59.2700000000000000',
      volume: '824536.8814700000000000',
      quoteVolume: 49135913.09168669,
      btcVolume: 3157.3304871935893,
      usdVolume: 49135913.09168669,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '59.2800000000000000',
      high: '60.8200000000000000',
      low: '57.6000000000000000',
      close: '60.7200000000000000',
      volume: '830578.2622800000000000',
      quoteVolume: 48949867.85248493,
      btcVolume: 3069.4575024684545,
      usdVolume: 48949867.85248493,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '60.7200000000000000',
      high: '66.6600000000000000',
      low: '60.1400000000000000',
      close: '65.9600000000000000',
      volume: '1890699.0773000000000000',
      quoteVolume: 120417135.62659656,
      btcVolume: 7402.366563715269,
      usdVolume: 120416609.86659656,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '65.9600000000000000',
      high: '66.3200000000000000',
      low: '61.6300000000000000',
      close: '63.8500000000000000',
      volume: '1004682.3311900000000000',
      quoteVolume: 63840748.70659265,
      btcVolume: 3982.6190328362654,
      usdVolume: 63840748.70659265,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '63.8300000000000000',
      high: '64.4300000000000000',
      low: '61.0800000000000000',
      close: '62.2800000000000000',
      volume: '685198.3981500000000000',
      quoteVolume: 42934465.16887135,
      btcVolume: 2687.015722325898,
      usdVolume: 42934465.16887135,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '62.2900000000000000',
      high: '74.6200000000000000',
      low: '62.0800000000000000',
      close: '73.8400000000000000',
      volume: '2523297.8789300000000000',
      quoteVolume: 174212415.64149517,
      btcVolume: 10641.431291066707,
      usdVolume: 174212415.64149517,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '73.8300000000000000',
      high: '77.0000000000000000',
      low: '71.3600000000000000',
      close: '76.3200000000000000',
      volume: '2672837.8382000000000000',
      quoteVolume: 197658068.17998445,
      btcVolume: 11577.927976041276,
      usdVolume: 197658068.17998445,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '76.3200000000000000',
      high: '77.1300000000000000',
      low: '67.7700000000000000',
      close: '73.4200000000000000',
      volume: '2633618.3237700000000000',
      quoteVolume: 192305528.25402403,
      btcVolume: 10784.179482659336,
      usdVolume: 192305528.25402403,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '73.4100000000000000',
      high: '83.4000000000000000',
      low: '71.4700000000000000',
      close: '81.5300000000000000',
      volume: '2733332.0071700000000000',
      quoteVolume: 212853407.31297737,
      btcVolume: 11944.761485430221,
      usdVolume: 212853407.31297737,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '81.5400000000000000',
      high: '85.6300000000000000',
      low: '79.9900000000000000',
      close: '82.5300000000000000',
      volume: '2343217.5381100000000000',
      quoteVolume: 192700587.20860234,
      btcVolume: 10522.118130650888,
      usdVolume: 192700587.20860234,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '82.5500000000000000',
      high: '88.5000000000000000',
      low: '80.1000000000000000',
      close: '87.1400000000000000',
      volume: '2521719.4082800000000000',
      quoteVolume: 212594343.03374535,
      btcVolume: 11359.43501812571,
      usdVolume: 212594343.03374535,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '87.0900000000000000',
      high: '87.9200000000000000',
      low: '77.3000000000000000',
      close: '82.8700000000000000',
      volume: '2048304.5808200000000000',
      quoteVolume: 171408076.02709004,
      btcVolume: 9333.179458237288,
      usdVolume: 171408076.02709004,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '82.8700000000000000',
      high: '90.9300000000000000',
      low: '80.8000000000000000',
      close: '89.0300000000000000',
      volume: '2486393.8341900000000000',
      quoteVolume: 215727635.64653075,
      btcVolume: 11699.840364269925,
      usdVolume: 215727635.64653075,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '89.0400000000000000',
      high: '94.0200000000000000',
      low: '84.6000000000000000',
      close: '89.2500000000000000',
      volume: '2621715.6430300000000000',
      quoteVolume: 235080123.5942224,
      btcVolume: 12570.326121700413,
      usdVolume: 235080123.5942224,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '89.2700000000000000',
      high: '90.7600000000000000',
      low: '78.0500000000000000',
      close: '81.7500000000000000',
      volume: '1623767.0887800000000000',
      quoteVolume: 140421215.4986958,
      btcVolume: 7390.470493802939,
      usdVolume: 140421215.4986958,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '81.7500000000000000',
      high: '83.2900000000000000',
      low: '64.4500000000000000',
      close: '71.0000000000000000',
      volume: '3435959.2951800000000000',
      quoteVolume: 251435782.40591905,
      btcVolume: 14535.673824882117,
      usdVolume: 251435782.40591905,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '70.9900000000000000',
      high: '73.5000000000000000',
      low: '65.3000000000000000',
      close: '69.1500000000000000',
      volume: '1707521.5040200000000000',
      quoteVolume: 118203288.9302594,
      btcVolume: 6968.137207364826,
      usdVolume: 118203288.9302594,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '69.1800000000000000',
      high: '74.9700000000000000',
      low: '68.2600000000000000',
      close: '72.7000000000000000',
      volume: '1482675.1561800000000000',
      quoteVolume: 106143884.81866534,
      btcVolume: 6107.917822619824,
      usdVolume: 106143884.81866534,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '72.6800000000000000',
      high: '81.0700000000000000',
      low: '71.5100000000000000',
      close: '78.9100000000000000',
      volume: '1514393.2172200000000000',
      quoteVolume: 115521557.95323898,
      btcVolume: 6412.697834411257,
      usdVolume: 115521557.95323898,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '78.8900000000000000',
      high: '88.4600000000000000',
      low: '76.6300000000000000',
      close: '87.6500000000000000',
      volume: '2118061.6227200000000000',
      quoteVolume: 176367822.45216078,
      btcVolume: 9216.718286347665,
      usdVolume: 176367822.45216078,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '87.6600000000000000',
      high: '93.0100000000000000',
      low: '79.5600000000000000',
      close: '85.0800000000000000',
      volume: '3495457.6565900000000000',
      quoteVolume: 307020765.6007774,
      btcVolume: 16015.001816947644,
      usdVolume: 307020765.6007774,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '85.0700000000000000',
      high: '90.6300000000000000',
      low: '82.6100000000000000',
      close: '88.9000000000000000',
      volume: '2135078.4793700000000000',
      quoteVolume: 185587960.28621328,
      btcVolume: 9814.274674754284,
      usdVolume: 185587960.28621328,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '88.8900000000000000',
      high: '91.5000000000000000',
      low: '85.4600000000000000',
      close: '89.0000000000000000',
      volume: '1690248.4588500000000000',
      quoteVolume: 149648537.47962323,
      btcVolume: 7763.714935990381,
      usdVolume: 149648537.47962323,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '89.0100000000000000',
      high: '89.8400000000000000',
      low: '77.8000000000000000',
      close: '79.5200000000000000',
      volume: '1701697.8531700000000000',
      quoteVolume: 144315548.66815314,
      btcVolume: 7578.867302398465,
      usdVolume: 144315548.66815314,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '79.5200000000000000',
      high: '84.2800000000000000',
      low: '78.6100000000000000',
      close: '83.1400000000000000',
      volume: '1275970.0537600000000000',
      quoteVolume: 104423917.99398686,
      btcVolume: 5517.332671389263,
      usdVolume: 104423917.99398686,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '83.1100000000000000',
      high: '85.1900000000000000',
      low: '80.3100000000000000',
      close: '83.5500000000000000',
      volume: '921046.7599600000000000',
      quoteVolume: 76405873.32399245,
      btcVolume: 3991.063481423035,
      usdVolume: 76405873.32399245,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '83.5400000000000000',
      high: '86.8700000000000000',
      low: '81.7300000000000000',
      close: '83.3700000000000000',
      volume: '1145446.4740700000000000',
      quoteVolume: 96166838.18654118,
      btcVolume: 5011.269364217304,
      usdVolume: 96166838.18654118,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '83.3700000000000000',
      high: '84.8300000000000000',
      low: '75.4100000000000000',
      close: '76.7600000000000000',
      volume: '1230953.8697500000000000',
      quoteVolume: 98587741.41994153,
      btcVolume: 5229.632704580527,
      usdVolume: 98587741.41994153,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '76.7500000000000000',
      high: '78.1400000000000000',
      low: '72.1300000000000000',
      close: '77.4100000000000000',
      volume: '1378297.5836100000000000',
      quoteVolume: 104644648.13944061,
      btcVolume: 5748.061365408652,
      usdVolume: 104644648.13944061,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '77.4100000000000000',
      high: '77.6600000000000000',
      low: '72.5100000000000000',
      close: '74.7000000000000000',
      volume: '897534.5890900000000000',
      quoteVolume: 67578374.0447259,
      btcVolume: 3697.8440886872677,
      usdVolume: 67578374.0447259,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '74.7700000000000000',
      high: '75.0900000000000000',
      low: '70.1200000000000000',
      close: '72.2000000000000000',
      volume: '1385260.5103000000000000',
      quoteVolume: 99443406.27715112,
      btcVolume: 5548.574129400644,
      usdVolume: 99438023.14967503,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '72.2000000000000000',
      high: '77.5800000000000000',
      low: '71.9700000000000000',
      close: '76.6700000000000000',
      volume: '778073.6457200000000000',
      quoteVolume: 58653684.98446523,
      btcVolume: 3179.4830099512424,
      usdVolume: 58653684.98446523,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '76.6400000000000000',
      high: '84.3800000000000000',
      low: '75.5200000000000000',
      close: '82.2800000000000000',
      volume: '1278882.9832800000000000',
      quoteVolume: 102688853.34340486,
      btcVolume: 5349.804122942844,
      usdVolume: 102688853.34340486,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '82.2700000000000000',
      high: '82.9800000000000000',
      low: '79.1100000000000000',
      close: '82.3500000000000000',
      volume: '1101678.0078500000000000',
      quoteVolume: 89408089.55373186,
      btcVolume: 4664.897724867536,
      usdVolume: 89408089.55373186,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '82.3800000000000000',
      high: '84.9300000000000000',
      low: '80.3900000000000000',
      close: '81.3200000000000000',
      volume: '1261474.3270300000000000',
      quoteVolume: 103669039.39603397,
      btcVolume: 5363.974475859031,
      usdVolume: 103669039.39603397,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '81.3600000000000000',
      high: '93.4400000000000000',
      low: '78.3100000000000000',
      close: '92.9400000000000000',
      volume: '2150469.3674300000000000',
      quoteVolume: 185140134.72739515,
      btcVolume: 9105.005162094782,
      usdVolume: 185140134.72739515,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '92.9400000000000000',
      high: '109.8000000000000000',
      low: '90.0000000000000000',
      close: '101.0100000000000000',
      volume: '4161454.8925300000000000',
      quoteVolume: 414585850.6995076,
      btcVolume: 18207.769039019673,
      usdVolume: 414585850.6995076,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '100.9900000000000000',
      high: '112.8800000000000000',
      low: '99.2400000000000000',
      close: '109.3400000000000000',
      volume: '3140130.3420400000000000',
      quoteVolume: 337503795.3740959,
      btcVolume: 14761.494258487553,
      usdVolume: 337503795.3740959,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '109.3400000000000000',
      high: '124.2400000000000000',
      low: '105.3100000000000000',
      close: '119.8500000000000000',
      volume: '2753360.1997100000000000',
      quoteVolume: 316850965.36465776,
      btcVolume: 13481.095408817218,
      usdVolume: 316850965.36465776,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '119.8500000000000000',
      high: '123.2100000000000000',
      low: '109.7600000000000000',
      close: '114.5800000000000000',
      volume: '2671305.3283900000000000',
      quoteVolume: 313367367.83264947,
      btcVolume: 13298.314869032618,
      usdVolume: 313367367.83264947,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '114.5900000000000000',
      high: '118.6500000000000000',
      low: '100.3100000000000000',
      close: '104.4500000000000000',
      volume: '2580555.1076800000000000',
      quoteVolume: 281116978.1689041,
      btcVolume: 12139.994599410998,
      usdVolume: 281116978.1689041,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '104.4800000000000000',
      high: '114.6300000000000000',
      low: '98.3500000000000000',
      close: '114.3200000000000000',
      volume: '3210319.3252800000000000',
      quoteVolume: 342738420.0010703,
      btcVolume: 14838.635043893324,
      usdVolume: 342738420.0010703,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '114.3100000000000000',
      high: '117.2900000000000000',
      low: '95.2300000000000000',
      close: '102.4500000000000000',
      volume: '3923591.0743400000000000',
      quoteVolume: 424020893.5197978,
      btcVolume: 18035.881882760194,
      usdVolume: 424020893.5197978,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '102.4600000000000000',
      high: '112.3200000000000000',
      low: '98.5500000000000000',
      close: '111.1300000000000000',
      volume: '2696980.5169700000000000',
      quoteVolume: 282469396.37838954,
      btcVolume: 12178.014726858833,
      usdVolume: 282469396.37838954,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '111.1300000000000000',
      high: '128.6300000000000000',
      low: '108.1800000000000000',
      close: '127.7800000000000000',
      volume: '3108695.5070400000000000',
      quoteVolume: 369487915.87514305,
      btcVolume: 15293.283381962749,
      usdVolume: 369487915.87514305,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '127.7400000000000000',
      high: '135.1900000000000000',
      low: '124.9400000000000000',
      close: '129.6100000000000000',
      volume: '3461075.6473000000000000',
      quoteVolume: 449830180.98402226,
      btcVolume: 17798.908427176073,
      usdVolume: 449830180.98402226,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '129.6100000000000000',
      high: '139.4700000000000000',
      low: '121.8800000000000000',
      close: '127.8800000000000000',
      volume: '3743173.1300100000000000',
      quoteVolume: 494840941.2477139,
      btcVolume: 18223.64596921554,
      usdVolume: 494840941.2477139,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '127.8300000000000000',
      high: '137.4100000000000000',
      low: '126.3700000000000000',
      close: '129.8300000000000000',
      volume: '2349842.9550200000000000',
      quoteVolume: 309678082.06321186,
      btcVolume: 11483.214550343224,
      usdVolume: 309678082.06321186,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '129.8400000000000000',
      high: '130.9000000000000000',
      low: '120.0000000000000000',
      close: '128.8000000000000000',
      volume: '1732248.3840900000000000',
      quoteVolume: 218608845.40846404,
      btcVolume: 8200.6564058765,
      usdVolume: 218608845.40846404,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '128.8000000000000000',
      high: '133.1100000000000000',
      low: '122.6300000000000000',
      close: '130.0500000000000000',
      volume: '1727676.6410000000000000',
      quoteVolume: 220908109.4346938,
      btcVolume: 7872.871817315565,
      usdVolume: 220908109.4346938,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '130.0600000000000000',
      high: '130.3500000000000000',
      low: '121.9800000000000000',
      close: '124.2100000000000000',
      volume: '1248723.0342400000000000',
      quoteVolume: 156991311.03482178,
      btcVolume: 5449.115523218407,
      usdVolume: 156991311.03482178,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '124.2200000000000000',
      high: '133.9700000000000000',
      low: '122.8900000000000000',
      close: '126.2400000000000000',
      volume: '1536326.1071500000000000',
      quoteVolume: 197120659.81115305,
      btcVolume: 6747.775990258352,
      usdVolume: 197120659.81115305,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '126.2000000000000000',
      high: '142.0000000000000000',
      low: '123.1500000000000000',
      close: '136.9600000000000000',
      volume: '2442266.7650000000000000',
      quoteVolume: 323461384.15540344,
      btcVolume: 10287.35677451215,
      usdVolume: 323461384.15540344,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '136.9400000000000000',
      high: '164.7000000000000000',
      low: '135.3000000000000000',
      close: '161.2400000000000000',
      volume: '3813834.0766900000000000',
      quoteVolume: 568621276.8387065,
      btcVolume: 16926.853812273916,
      usdVolume: 568621276.8387065,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '161.2300000000000000',
      high: '173.6600000000000000',
      low: '139.3100000000000000',
      close: '155.3400000000000000',
      volume: '3530422.2039200000000000',
      quoteVolume: 551040498.8754859,
      btcVolume: 17344.92344775779,
      usdVolume: 551040498.8754859,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '155.3300000000000000',
      high: '162.8000000000000000',
      low: '146.5200000000000000',
      close: '158.4300000000000000',
      volume: '1998175.7975400000000000',
      quoteVolume: 308975694.1353862,
      btcVolume: 9579.752665689979,
      usdVolume: 308975694.1353862,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '158.4600000000000000',
      high: '169.7200000000000000',
      low: '154.9500000000000000',
      close: '168.9200000000000000',
      volume: '1857437.6845700000000000',
      quoteVolume: 302142832.62634295,
      btcVolume: 8680.956043080312,
      usdVolume: 302142832.62634295,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '168.9600000000000000',
      high: '181.2200000000000000',
      low: '161.1800000000000000',
      close: '169.3500000000000000',
      volume: '2479926.5663800000000000',
      quoteVolume: 422479799.42550886,
      btcVolume: 11058.616576435745,
      usdVolume: 422479799.42550886,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '169.3500000000000000',
      high: '181.9200000000000000',
      low: '150.0000000000000000',
      close: '172.1900000000000000',
      volume: '2833341.6404500000000000',
      quoteVolume: 478225816.520269,
      btcVolume: 11964.035801892664,
      usdVolume: 478225816.520269,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '172.2800000000000000',
      high: '178.8000000000000000',
      low: '163.3000000000000000',
      close: '177.2600000000000000',
      volume: '1749781.8497300000000000',
      quoteVolume: 299170389.286915,
      btcVolume: 7425.313037764587,
      usdVolume: 299170389.286915,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '177.2700000000000000',
      high: '185.8300000000000000',
      low: '161.8600000000000000',
      close: '170.0700000000000000',
      volume: '2269089.8322300000000000',
      quoteVolume: 397596277.8818141,
      btcVolume: 10055.323763923585,
      usdVolume: 397596277.8818141,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '170.0600000000000000',
      high: '170.7400000000000000',
      low: '112.7000000000000000',
      close: '138.8700000000000000',
      volume: '4877205.3953600000000000',
      quoteVolume: 662514925.4818044,
      btcVolume: 19588.40860151175,
      usdVolume: 662514925.4818044,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '138.8800000000000000',
      high: '146.8600000000000000',
      low: '128.0000000000000000',
      close: '133.4200000000000000',
      volume: '2477243.9833200000000000',
      quoteVolume: 340391038.4888703,
      btcVolume: 9797.131310819967,
      usdVolume: 340391038.4888703,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '133.4400000000000000',
      high: '148.4600000000000000',
      low: '125.9000000000000000',
      close: '147.3800000000000000',
      volume: '2088185.1472100000000000',
      quoteVolume: 286151973.30492896,
      btcVolume: 8269.541344650439,
      usdVolume: 286151973.30492896,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '147.3900000000000000',
      high: '156.8900000000000000',
      low: '143.5500000000000000',
      close: '152.5000000000000000',
      volume: '2033762.6002700000000000',
      quoteVolume: 304472230.40606844,
      btcVolume: 7883.6115631974335,
      usdVolume: 304472230.40606844,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '152.4600000000000000',
      high: '155.7400000000000000',
      low: '130.0000000000000000',
      close: '143.6500000000000000',
      volume: '1929869.1502000000000000',
      quoteVolume: 279867480.1701219,
      btcVolume: 7532.0663673781455,
      usdVolume: 279867480.1701219,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '143.6600000000000000',
      high: '152.5900000000000000',
      low: '139.2800000000000000',
      close: '143.5200000000000000',
      volume: '1619686.2842200000000000',
      quoteVolume: 236445434.4062732,
      btcVolume: 6406.112755540417,
      usdVolume: 236445434.4062732,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '143.5200000000000000',
      high: '147.0000000000000000',
      low: '136.2200000000000000',
      close: '142.8000000000000000',
      volume: '1379082.3792000000000000',
      quoteVolume: 195777669.99104866,
      btcVolume: 5518.389442894679,
      usdVolume: 195777669.99104866,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '142.7800000000000000',
      high: '154.9900000000000000',
      low: '138.4400000000000000',
      close: '151.9600000000000000',
      volume: '1644918.1028000000000000',
      quoteVolume: 242251163.15367344,
      btcVolume: 6662.692550152778,
      usdVolume: 242251163.15367344,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '151.9700000000000000',
      high: '166.0200000000000000',
      low: '150.1800000000000000',
      close: '151.8600000000000000',
      volume: '2250266.1240300000000000',
      quoteVolume: 358045416.38105613,
      btcVolume: 9715.528233417612,
      usdVolume: 358045416.38105613,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '151.8500000000000000',
      high: '156.1500000000000000',
      low: '138.4000000000000000',
      close: '149.6900000000000000',
      volume: '2037065.0855500000000000',
      quoteVolume: 300099295.2165031,
      btcVolume: 8570.773651988773,
      usdVolume: 300099295.2165031,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '149.6700000000000000',
      high: '150.2500000000000000',
      low: '125.6400000000000000',
      close: '129.7200000000000000',
      volume: '2176119.4788900000000000',
      quoteVolume: 296428620.1166068,
      btcVolume: 9078.008984863776,
      usdVolume: 296428620.1166068,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '129.7000000000000000',
      high: '141.8500000000000000',
      low: '122.7000000000000000',
      close: '137.6600000000000000',
      volume: '1951005.0663900000000000',
      quoteVolume: 263524938.22357655,
      btcVolume: 8423.197856584778,
      usdVolume: 263524938.22357655,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '137.6500000000000000',
      high: '143.5200000000000000',
      low: '135.0500000000000000',
      close: '137.6700000000000000',
      volume: '819478.3417900000000000',
      quoteVolume: 113831601.15240765,
      btcVolume: 3514.611539877437,
      usdVolume: 113831601.15240765,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '137.6600000000000000',
      high: '142.6500000000000000',
      low: '133.7300000000000000',
      close: '141.0100000000000000',
      volume: '917459.8505800000000000',
      quoteVolume: 127591959.12300138,
      btcVolume: 3964.1660200345823,
      usdVolume: 127591959.12300138,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '141.0100000000000000',
      high: '147.8700000000000000',
      low: '136.8300000000000000',
      close: '138.5600000000000000',
      volume: '1297445.3763800000000000',
      quoteVolume: 186110798.1019838,
      btcVolume: 5571.664082584082,
      usdVolume: 186110798.1019838,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '132.7000000000000000',
      high: '136.3000000000000000',
      low: '132.5100000000000000',
      close: '134.6700000000000000',
      volume: '239830.9402400000000000',
      quoteVolume: 32177113.892162576,
      btcVolume: 999.7459445541468,
      usdVolume: 32177113.892162576,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '134.6900000000000000',
      high: '134.9600000000000000',
      low: '118.1300000000000000',
      close: '122.4400000000000000',
      volume: '1112624.2676100000000000',
      quoteVolume: 139464919.21385488,
      btcVolume: 4524.577313776934,
      usdVolume: 139464919.21385488,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '122.4500000000000000',
      high: '135.8400000000000000',
      low: '120.7400000000000000',
      close: '133.2700000000000000',
      volume: '937794.6301600000000000',
      quoteVolume: 121424463.23872843,
      btcVolume: 3823.7182988262484,
      usdVolume: 121424463.23872843,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '133.2500000000000000',
      high: '145.5000000000000000',
      low: '131.4400000000000000',
      close: '134.6500000000000000',
      volume: '1953065.9401100000000000',
      quoteVolume: 269778147.58305985,
      btcVolume: 7637.60669728038,
      usdVolume: 269778147.58305985,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '134.6700000000000000',
      high: '136.2300000000000000',
      low: '128.0200000000000000',
      close: '133.3600000000000000',
      volume: '1171505.9664700000000000',
      quoteVolume: 154495780.49306908,
      btcVolume: 4551.220051793771,
      usdVolume: 154495780.49306908,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '133.3500000000000000',
      high: '135.0600000000000000',
      low: '125.6700000000000000',
      close: '129.3600000000000000',
      volume: '806399.7757100000000000',
      quoteVolume: 104722485.35395245,
      btcVolume: 3138.156642547338,
      usdVolume: 104722485.35395245,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '129.3700000000000000',
      high: '136.6300000000000000',
      low: '125.9500000000000000',
      close: '132.0000000000000000',
      volume: '1135286.5070700000000000',
      quoteVolume: 149340674.82508767,
      btcVolume: 4416.238424240979,
      usdVolume: 149340674.82508767,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '131.9900000000000000',
      high: '145.8200000000000000',
      low: '131.8700000000000000',
      close: '141.9800000000000000',
      volume: '1883149.5372600000000000',
      quoteVolume: 265391340.62986633,
      btcVolume: 7621.335552581734,
      usdVolume: 265391340.62986633,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '141.9600000000000000',
      high: '156.7000000000000000',
      low: '141.9200000000000000',
      close: '155.9300000000000000',
      volume: '1820821.5903600000000000',
      quoteVolume: 276355678.3739926,
      btcVolume: 7583.191926757077,
      usdVolume: 276355678.3739926,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '155.9400000000000000',
      high: '158.6800000000000000',
      low: '141.0300000000000000',
      close: '145.0700000000000000',
      volume: '1762820.3654400000000000',
      quoteVolume: 263148795.56052074,
      btcVolume: 7024.899854343085,
      usdVolume: 263148795.56052074,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '145.0500000000000000',
      high: '156.7800000000000000',
      low: '143.4500000000000000',
      close: '155.0500000000000000',
      volume: '1557577.8368500000000000',
      quoteVolume: 237025412.1601315,
      btcVolume: 6297.118687933591,
      usdVolume: 237025412.1601315,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '155.0500000000000000',
      high: '164.2700000000000000',
      low: '149.8200000000000000',
      close: '155.5700000000000000',
      volume: '1878793.7270300000000000',
      quoteVolume: 298604965.4190308,
      btcVolume: 7537.248027732104,
      usdVolume: 298604965.4190308,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '155.5700000000000000',
      high: '157.2900000000000000',
      low: '145.2300000000000000',
      close: '150.7600000000000000',
      volume: '1259975.9289400000000000',
      quoteVolume: 190631760.74434358,
      btcVolume: 4938.417851396465,
      usdVolume: 190631760.74434358,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '150.7600000000000000',
      high: '169.3800000000000000',
      low: '147.7600000000000000',
      close: '167.2800000000000000',
      volume: '1869837.9437200000000000',
      quoteVolume: 297709749.822012,
      btcVolume: 7033.137189014122,
      usdVolume: 297709749.822012,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '167.2800000000000000',
      high: '184.4900000000000000',
      low: '164.2200000000000000',
      close: '181.4900000000000000',
      volume: '1981149.1236600000000000',
      quoteVolume: 339741920.4317491,
      btcVolume: 7286.474296530587,
      usdVolume: 339741920.4317491,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '181.5200000000000000',
      high: '195.1700000000000000',
      low: '169.5700000000000000',
      close: '181.5600000000000000',
      volume: '2781973.9625400000000000',
      quoteVolume: 511568659.8745071,
      btcVolume: 11171.189003450432,
      usdVolume: 511396513.1418937,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '181.5900000000000000',
      high: '193.5900000000000000',
      low: '175.7200000000000000',
      close: '183.6200000000000000',
      volume: '1662458.2148900000000000',
      quoteVolume: 308980082.707041,
      btcVolume: 6638.340260622761,
      usdVolume: 308980082.707041,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '183.6200000000000000',
      high: '199.8400000000000000',
      low: '176.7500000000000000',
      close: '196.6900000000000000',
      volume: '1944278.4281900000000000',
      quoteVolume: 363675328.6517229,
      btcVolume: 7651.174521048404,
      usdVolume: 363646656.3346562,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '196.7100000000000000',
      high: '229.0700000000000000',
      low: '191.4500000000000000',
      close: '225.7600000000000000',
      volume: '2843476.1869100000000000',
      quoteVolume: 594213638.5294985,
      btcVolume: 12603.247974606615,
      usdVolume: 594155739.24837,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '225.7700000000000000',
      high: '230.7700000000000000',
      low: '208.1100000000000000',
      close: '213.4900000000000000',
      volume: '1654862.3886300000000000',
      quoteVolume: 363531568.69063455,
      btcVolume: 7495.86825872764,
      usdVolume: 363531568.69063455,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '213.4700000000000000',
      high: '219.9900000000000000',
      low: '185.3100000000000000',
      close: '207.8300000000000000',
      volume: '2145096.2026600000000000',
      quoteVolume: 441287107.2575872,
      btcVolume: 9249.958178220597,
      usdVolume: 440751484.0592311,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '208.0300000000000000',
      high: '222.4800000000000000',
      low: '200.1000000000000000',
      close: '210.2600000000000000',
      volume: '1655771.0432500000000000',
      quoteVolume: 350218158.5830828,
      btcVolume: 7007.571698477013,
      usdVolume: 342424004.4173157,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '210.2300000000000000',
      high: '237.7500000000000000',
      low: '203.3700000000000000',
      close: '237.4900000000000000',
      volume: '1781373.1518300000000000',
      quoteVolume: 397616883.22465694,
      btcVolume: 7787.136345561708,
      usdVolume: 397616883.22465694,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '237.3900000000000000',
      high: '239.1800000000000000',
      low: '221.0000000000000000',
      close: '226.5900000000000000',
      volume: '1580820.7100000000000000',
      quoteVolume: 362511271.6078358,
      btcVolume: 6993.752084518185,
      usdVolume: 362511271.6078358,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '226.5800000000000000',
      high: '241.9200000000000000',
      low: '220.0600000000000000',
      close: '236.5000000000000000',
      volume: '1398560.5533800000000000',
      quoteVolume: 324183188.11299986,
      btcVolume: 6114.868427314256,
      usdVolume: 324183188.11299986,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '236.4500000000000000',
      high: '247.0400000000000000',
      low: '214.0200000000000000',
      close: '226.6500000000000000',
      volume: '1699874.2734800000000000',
      quoteVolume: 401212940.2932522,
      btcVolume: 7143.652988554469,
      usdVolume: 401212460.39169174,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '226.7700000000000000',
      high: '233.7800000000000000',
      low: '220.0600000000000000',
      close: '227.1300000000000000',
      volume: '1268407.0304600000000000',
      quoteVolume: 288545534.88444746,
      btcVolume: 5070.698499732074,
      usdVolume: 288545534.88444746,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '227.1700000000000000',
      high: '227.3900000000000000',
      low: '180.0000000000000000',
      close: '208.3200000000000000',
      volume: '2222077.3503300000000000',
      quoteVolume: 460086677.0755931,
      btcVolume: 8037.5535313587925,
      usdVolume: 432162455.0772618,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '208.1000000000000000',
      high: '208.2300000000000000',
      low: '158.8300000000000000',
      close: '177.3400000000000000',
      volume: '3390922.1286900000000000',
      quoteVolume: 606664750.1952728,
      btcVolume: 12391.424200432175,
      usdVolume: 602656639.5134262,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '177.3500000000000000',
      high: '189.4600000000000000',
      low: '169.0800000000000000',
      close: '181.4300000000000000',
      volume: '1385993.1585900000000000',
      quoteVolume: 251534269.16248846,
      btcVolume: 5045.167835396706,
      usdVolume: 251505675.69378206,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '181.4300000000000000',
      high: '205.2100000000000000',
      low: '175.0000000000000000',
      close: '179.3600000000000000',
      volume: '1961972.0422000000000000',
      quoteVolume: 374933085.95019436,
      btcVolume: 7503.165956785665,
      usdVolume: 374933085.95019436,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '179.3600000000000000',
      high: '182.8600000000000000',
      low: '162.7400000000000000',
      close: '170.2500000000000000',
      volume: '2179517.4955500000000000',
      quoteVolume: 376772370.9497997,
      btcVolume: 8092.3230294664,
      usdVolume: 376485294.8561675,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '170.2000000000000000',
      high: '180.4800000000000000',
      low: '167.1300000000000000',
      close: '171.7600000000000000',
      volume: '1113606.4401000000000000',
      quoteVolume: 194721552.36965466,
      btcVolume: 4134.973488951998,
      usdVolume: 194721552.36965466,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '171.7500000000000000',
      high: '173.0000000000000000',
      low: '152.8800000000000000',
      close: '165.2200000000000000',
      volume: '1630049.9177200000000000',
      quoteVolume: 262199243.42310986,
      btcVolume: 5874.032431282306,
      usdVolume: 262199243.42310986,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '165.2700000000000000',
      high: '176.4600000000000000',
      low: '163.3000000000000000',
      close: '175.8900000000000000',
      volume: '1157182.4178100000000000',
      quoteVolume: 196920601.48332697,
      btcVolume: 4133.868226475361,
      usdVolume: 196920601.48332697,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '175.9000000000000000',
      high: '185.3200000000000000',
      low: '170.6900000000000000',
      close: '179.0800000000000000',
      volume: '1441741.0023500000000000',
      quoteVolume: 255346162.55105585,
      btcVolume: 5250.61792323071,
      usdVolume: 255346162.55105585,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '179.0600000000000000',
      high: '196.4900000000000000',
      low: '176.7900000000000000',
      close: '187.6400000000000000',
      volume: '1788998.4592800000000000',
      quoteVolume: 340485879.58694166,
      btcVolume: 6762.4933075880845,
      usdVolume: 340485879.58694166,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '187.7000000000000000',
      high: '194.4000000000000000',
      low: '176.8300000000000000',
      close: '180.6700000000000000',
      volume: '1484950.5713600000000000',
      quoteVolume: 275283690.3275003,
      btcVolume: 5567.250076468444,
      usdVolume: 275230426.05999345,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '180.6600000000000000',
      high: '183.5700000000000000',
      low: '168.5000000000000000',
      close: '180.2300000000000000',
      volume: '1182501.6988200000000000',
      quoteVolume: 206736683.42664367,
      btcVolume: 4343.41978808486,
      usdVolume: 206736683.42664367,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '180.2100000000000000',
      high: '184.6700000000000000',
      low: '174.6800000000000000',
      close: '182.8500000000000000',
      volume: '890375.3310000000000000',
      quoteVolume: 160843481.4558911,
      btcVolume: 3295.7412318699794,
      usdVolume: 159221856.4260747,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '182.8600000000000000',
      high: '192.2400000000000000',
      low: '182.2500000000000000',
      close: '190.7500000000000000',
      volume: '820418.2390100000000000',
      quoteVolume: 153066685.711372,
      btcVolume: 3041.8416759099878,
      usdVolume: 153066685.711372,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '190.7500000000000000',
      high: '195.2800000000000000',
      low: '179.5800000000000000',
      close: '191.8500000000000000',
      volume: '1145514.1533900000000000',
      quoteVolume: 213310567.88827464,
      btcVolume: 4215.520210397939,
      usdVolume: 213310567.88827464,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '191.8600000000000000',
      high: '205.0000000000000000',
      low: '189.2500000000000000',
      close: '203.8100000000000000',
      volume: '1149941.2715400000000000',
      quoteVolume: 228024787.08482048,
      btcVolume: 4230.227265341441,
      usdVolume: 228024787.08482048,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '203.8000000000000000',
      high: '208.0000000000000000',
      low: '191.4700000000000000',
      close: '200.2900000000000000',
      volume: '1292995.9055100000000000',
      quoteVolume: 259453140.60054418,
      btcVolume: 4683.568407179226,
      usdVolume: 259436705.04717085,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '200.2900000000000000',
      high: '203.5400000000000000',
      low: '191.0300000000000000',
      close: '201.3600000000000000',
      volume: '980924.4222700000000000',
      quoteVolume: 194360542.3998627,
      btcVolume: 3462.342245940359,
      usdVolume: 194360542.3998627,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '201.3700000000000000',
      high: '224.2300000000000000',
      low: '198.4100000000000000',
      close: '221.4200000000000000',
      volume: '1977431.3681000000000000',
      quoteVolume: 416264469.25011075,
      btcVolume: 5921.218381160836,
      usdVolume: 336886590.78517324,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '218.6500000000000000',
      high: '230.2300000000000000',
      low: '212.7000000000000000',
      close: '226.0600000000000000',
      volume: '1310331.5687300000000000',
      quoteVolume: 292374674.933538,
      btcVolume: 4956.360993659334,
      usdVolume: 292374674.933538,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '226.0800000000000000',
      high: '228.1200000000000000',
      low: '213.8400000000000000',
      close: '213.9400000000000000',
      volume: '798435.6040500000000000',
      quoteVolume: 175877600.8876904,
      btcVolume: 2901.7605953284483,
      usdVolume: 175390710.21983862,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '214.0000000000000000',
      high: '222.5800000000000000',
      low: '197.5600000000000000',
      close: '200.5900000000000000',
      volume: '1413381.5745600000000000',
      quoteVolume: 291674131.5058429,
      btcVolume: 5106.393558040645,
      usdVolume: 291674131.5058429,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '200.5900000000000000',
      high: '205.5300000000000000',
      low: '191.1500000000000000',
      close: '201.6000000000000000',
      volume: '992587.9162400000000000',
      quoteVolume: 197656651.7759283,
      btcVolume: 3582.8634646412274,
      usdVolume: 197591598.5747801,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '201.6100000000000000',
      high: '206.9400000000000000',
      low: '194.1300000000000000',
      close: '205.9800000000000000',
      volume: '881712.4524000000000000',
      quoteVolume: 176884881.60728255,
      btcVolume: 3142.8106849439105,
      usdVolume: 176884881.60728255,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '205.9600000000000000',
      high: '208.2500000000000000',
      low: '199.1300000000000000',
      close: '199.7000000000000000',
      volume: '774116.3689700000000000',
      quoteVolume: 158124202.7894712,
      btcVolume: 2696.520577593762,
      usdVolume: 158123481.68116263,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '199.7200000000000000',
      high: '205.0000000000000000',
      low: '195.8100000000000000',
      close: '199.9600000000000000',
      volume: '515574.3893500000000000',
      quoteVolume: 103843591.3837549,
      btcVolume: 1779.0182398838715,
      usdVolume: 103678690.17642716,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '199.9600000000000000',
      high: '206.1000000000000000',
      low: '198.8200000000000000',
      close: '200.1700000000000000',
      volume: '408707.5852000000000000',
      quoteVolume: 82836648.74984606,
      btcVolume: 1409.1616201750144,
      usdVolume: 82836585.73164566,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '200.1600000000000000',
      high: '202.0000000000000000',
      low: '192.0000000000000000',
      close: '195.3100000000000000',
      volume: '579091.9878400000000000',
      quoteVolume: 113737671.3688079,
      btcVolume: 1986.3030989694942,
      usdVolume: 113644945.73073904,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '195.3300000000000000',
      high: '199.0000000000000000',
      low: '184.4200000000000000',
      close: '185.6700000000000000',
      volume: '725077.5781400000000000',
      quoteVolume: 139899897.6498326,
      btcVolume: 2476.105185480285,
      usdVolume: 139844519.7670538,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '185.6900000000000000',
      high: '189.4400000000000000',
      low: '181.1200000000000000',
      close: '185.8300000000000000',
      volume: '632291.1116900000000000',
      quoteVolume: 117638252.58571476,
      btcVolume: 1495.9951187040629,
      usdVolume: 81470228.03850105,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '185.8200000000000000',
      high: '197.1700000000000000',
      low: '172.0000000000000000',
      close: '176.0500000000000000',
      volume: '1081496.2327600000000000',
      quoteVolume: 204651010.18833292,
      btcVolume: 3732.791518881732,
      usdVolume: 204651010.18833292,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '176.0500000000000000',
      high: '179.0300000000000000',
      low: '167.8600000000000000',
      close: '171.5300000000000000',
      volume: '840201.4116500000000000',
      quoteVolume: 145853345.58006474,
      btcVolume: 2520.2457069925285,
      usdVolume: 130917909.539687,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '171.5300000000000000',
      high: '183.8200000000000000',
      low: '171.4400000000000000',
      close: '183.4500000000000000',
      volume: '602677.7101500000000000',
      quoteVolume: 107824053.70199299,
      btcVolume: 1947.79094311182,
      usdVolume: 103830246.82252973,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '183.4400000000000000',
      high: '186.1200000000000000',
      low: '177.7500000000000000',
      close: '184.0600000000000000',
      volume: '475886.8387300000000000',
      quoteVolume: 86757743.80726413,
      btcVolume: 1572.6367137829343,
      usdVolume: 86752376.32223454,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '184.0600000000000000',
      high: '187.2900000000000000',
      low: '180.0000000000000000',
      close: '184.2400000000000000',
      volume: '380545.1621100000000000',
      quoteVolume: 70082688.39183253,
      btcVolume: 1254.4888606538248,
      usdVolume: 70065167.58697064,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '184.2200000000000000',
      high: '197.6000000000000000',
      low: '182.4500000000000000',
      close: '193.7300000000000000',
      volume: '891104.9208000000000000',
      quoteVolume: 172040612.47646165,
      btcVolume: 3033.1693284032813,
      usdVolume: 171975567.3003832,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '193.7300000000000000',
      high: '200.0900000000000000',
      low: '190.3400000000000000',
      close: '195.7400000000000000',
      volume: '932290.6498000000000000',
      quoteVolume: 181705740.05520037,
      btcVolume: 3111.562505675036,
      usdVolume: 181705701.26920035,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '195.7100000000000000',
      high: '198.9900000000000000',
      low: '187.0000000000000000',
      close: '196.7000000000000000',
      volume: '698229.6378600000000000',
      quoteVolume: 135030259.1244356,
      btcVolume: 2299.6428350422234,
      usdVolume: 134758219.97780785,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '196.6900000000000000',
      high: '205.0000000000000000',
      low: '194.0000000000000000',
      close: '202.8600000000000000',
      volume: '860883.6398300000000000',
      quoteVolume: 171796660.07412636,
      btcVolume: 2915.077930636623,
      usdVolume: 171627655.0033485,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '202.8700000000000000',
      high: '213.7700000000000000',
      low: '200.7800000000000000',
      close: '211.4600000000000000',
      volume: '911504.7463400000000000',
      quoteVolume: 188097396.48105705,
      btcVolume: 3171.97268148674,
      usdVolume: 188075782.95719445,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '211.4400000000000000',
      high: '217.9700000000000000',
      low: '194.8500000000000000',
      close: '195.2300000000000000',
      volume: '955157.6424300000000000',
      quoteVolume: 199272205.1538647,
      btcVolume: 1923.0081233352637,
      usdVolume: 113285748.669347,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '195.1700000000000000',
      high: '204.9200000000000000',
      low: '192.2200000000000000',
      close: '202.2100000000000000',
      volume: '537808.5370400000000000',
      quoteVolume: 107874767.17797312,
      btcVolume: 1616.1763098667245,
      usdVolume: 93333101.29026881,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '202.2400000000000000',
      high: '225.4700000000000000',
      low: '198.0700000000000000',
      close: '221.4800000000000000',
      volume: '1320185.3316300000000000',
      quoteVolume: 279356016.0500422,
      btcVolume: 3604.645404045187,
      usdVolume: 210101068.50432605,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '221.4800000000000000',
      high: '244.5900000000000000',
      low: '212.9700000000000000',
      close: '237.0400000000000000',
      volume: '2258610.1931100000000000',
      quoteVolume: 520462770.85170555,
      btcVolume: 8308.54901413599,
      usdVolume: 484407399.03375864,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '236.9600000000000000',
      high: '242.9900000000000000',
      low: '210.7700000000000000',
      close: '219.0500000000000000',
      volume: '2252949.3544600000000000',
      quoteVolume: 507979738.3688623,
      btcVolume: 8713.75947843975,
      usdVolume: 496605124.34581065,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '219.2200000000000000',
      high: '228.2100000000000000',
      low: '216.8900000000000000',
      close: '226.1100000000000000',
      volume: '1040059.1698900000000000',
      quoteVolume: 232196028.07719645,
      btcVolume: 2623.6393819989507,
      usdVolume: 149526942.68774295,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '226.1700000000000000',
      high: '228.9900000000000000',
      low: '218.7200000000000000',
      close: '221.1200000000000000',
      volume: '629961.8332600000000000',
      quoteVolume: 141610934.81979573,
      btcVolume: 2106.0786755977633,
      usdVolume: 122650861.38468109,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '221.1300000000000000',
      high: '257.0000000000000000',
      low: '219.1600000000000000',
      close: '256.3400000000000000',
      volume: '1859281.8830400000000000',
      quoteVolume: 441762890.57776946,
      btcVolume: 7247.337651456451,
      usdVolume: 434426894.35602313,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '256.5500000000000000',
      high: '263.6200000000000000',
      low: '0.0113660000000000',
      close: '252.3100000000000000',
      volume: '1890921.5803900000000000',
      quoteVolume: 480319113.2081568,
      btcVolume: 7907.7185822236115,
      usdVolume: 472938780.5553337,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '252.3500000000000000',
      high: '259.9400000000000000',
      low: '240.0100000000000000',
      close: '244.4200000000000000',
      volume: '1328144.9269900000000000',
      quoteVolume: 331420362.6224557,
      btcVolume: 4408.987173128506,
      usdVolume: 264961310.1003388,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '244.4400000000000000',
      high: '273.5400000000000000',
      low: '244.3600000000000000',
      close: '267.5900000000000000',
      volume: '1815822.6911300000000000',
      quoteVolume: 476947654.2477242,
      btcVolume: 7578.822561176737,
      usdVolume: 472033209.7211003,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '267.6100000000000000',
      high: '283.0000000000000000',
      low: '255.0000000000000000',
      close: '278.8600000000000000',
      volume: '1934889.3058500000000000',
      quoteVolume: 524702625.81534594,
      btcVolume: 7474.81355593654,
      usdVolume: 474608362.09590185,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '278.8000000000000000',
      high: '290.9000000000000000',
      low: '266.0000000000000000',
      close: '285.6300000000000000',
      volume: '1409512.9217200000000000',
      quoteVolume: 392049527.74386656,
      btcVolume: 5819.337916249173,
      usdVolume: 366017514.8535886,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '285.6400000000000000',
      high: '317.0900000000000000',
      low: '265.5000000000000000',
      close: '308.4300000000000000',
      volume: '3150447.9992800000000000',
      quoteVolume: 924239206.0551368,
      btcVolume: 13754.14904721424,
      usdVolume: 847181090.2771821,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '308.3900000000000000',
      high: '335.6900000000000000',
      low: '296.7500000000000000',
      close: '300.8600000000000000',
      volume: '2574519.6355000000000000',
      quoteVolume: 817941072.7363646,
      btcVolume: 8650.440913958724,
      usdVolume: 529440111.53025746,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '300.8700000000000000',
      high: '304.8300000000000000',
      low: '232.0000000000000000',
      close: '273.3600000000000000',
      volume: '3046826.0682100000000000',
      quoteVolume: 814978326.9479513,
      btcVolume: 14251.274999648283,
      usdVolume: 799689184.4717911,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '273.3500000000000000',
      high: '284.3900000000000000',
      low: '250.2100000000000000',
      close: '261.3800000000000000',
      volume: '1973502.3718900000000000',
      quoteVolume: 526831697.8987827,
      btcVolume: 7545.76975452113,
      usdVolume: 424171196.8055375,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '261.4700000000000000',
      high: '272.8000000000000000',
      low: '234.3100000000000000',
      close: '260.6800000000000000',
      volume: '2100827.2657200000000000',
      quoteVolume: 536954207.2394092,
      btcVolume: 9272.855998053765,
      usdVolume: 514533908.33471185,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '260.7300000000000000',
      high: '277.0000000000000000',
      low: '250.7500000000000000',
      close: '257.6900000000000000',
      volume: '1690547.6833500000000000',
      quoteVolume: 446244126.3759943,
      btcVolume: 6848.168090990059,
      usdVolume: 380430906.4184981,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '257.6300000000000000',
      high: '289.6100000000000000',
      low: '239.0000000000000000',
      close: '252.5000000000000000',
      volume: '2441035.4976300000000000',
      quoteVolume: 653807393.5680175,
      btcVolume: 3138.9783548631967,
      usdVolume: 166658633.73239762,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '252.6000000000000000',
      high: '257.1500000000000000',
      low: '214.2900000000000000',
      close: '241.3100000000000000',
      volume: '2633187.8420800000000000',
      quoteVolume: 613120720.0451101,
      btcVolume: 6726.945644391381,
      usdVolume: 334739258.42326885,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '241.3100000000000000',
      high: '241.4100000000000000',
      low: '220.3400000000000000',
      close: '224.7500000000000000',
      volume: '1127242.4756500000000000',
      quoteVolume: 258970433.8586322,
      btcVolume: 4959.794572907675,
      usdVolume: 247114101.5572867,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '224.7600000000000000',
      high: '235.8000000000000000',
      low: '210.7300000000000000',
      close: '223.8800000000000000',
      volume: '872669.4430500000000000',
      quoteVolume: 197094883.39333206,
      btcVolume: 2116.3775832422107,
      usdVolume: 104947659.02477793,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '223.9000000000000000',
      high: '248.2000000000000000',
      low: '221.2400000000000000',
      close: '247.4200000000000000',
      volume: '1090110.9487400000000000',
      quoteVolume: 262056371.5676198,
      btcVolume: 2935.0307772177202,
      usdVolume: 155435733.32541108,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '247.4000000000000000',
      high: '263.1500000000000000',
      low: '243.6300000000000000',
      close: '259.7700000000000000',
      volume: '1113898.0502300000000000',
      quoteVolume: 283129397.5147066,
      btcVolume: 5071.1060750962415,
      usdVolume: 276778818.2280468,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '259.7700000000000000',
      high: '266.9400000000000000',
      low: '247.5800000000000000',
      close: '258.5100000000000000',
      volume: '1014038.4373400000000000',
      quoteVolume: 259424641.2548764,
      btcVolume: 4737.856416827141,
      usdVolume: 259424641.2548764,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '258.5000000000000000',
      high: '262.8900000000000000',
      low: '248.1600000000000000',
      close: '255.3100000000000000',
      volume: '856981.6494100000000000',
      quoteVolume: 218587991.57567295,
      btcVolume: 4061.048623525768,
      usdVolume: 218587991.57567295,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '255.3100000000000000',
      high: '273.5800000000000000',
      low: '252.6100000000000000',
      close: '271.1300000000000000',
      volume: '1019109.2282500000000000',
      quoteVolume: 269497860.7586776,
      btcVolume: 3226.855828115803,
      usdVolume: 177988659.73005295,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '271.1400000000000000',
      high: '278.5800000000000000',
      low: '265.9000000000000000',
      close: '276.7800000000000000',
      volume: '759685.2319600000000000',
      quoteVolume: 206313142.37866887,
      btcVolume: 1707.670992326543,
      usdVolume: 98677446.61978804,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '276.7700000000000000',
      high: '277.4700000000000000',
      low: '264.7000000000000000',
      close: '268.8800000000000000',
      volume: '656401.3022700000000000',
      quoteVolume: 177319409.03695217,
      btcVolume: 1903.0205383441184,
      usdVolume: 108037070.23640811,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '268.8300000000000000',
      high: '299.7200000000000000',
      low: '268.4400000000000000',
      close: '294.8700000000000000',
      volume: '1427253.5714300000000000',
      quoteVolume: 406345333.7440447,
      btcVolume: 6780.1405280927665,
      usdVolume: 392154426.84161633,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '294.7300000000000000',
      high: '327.6800000000000000',
      low: '270.0000000000000000',
      close: '305.2300000000000000',
      volume: '3928402.9769700000000000',
      quoteVolume: 1192658666.056111,
      btcVolume: 17129.631266402546,
      usdVolume: 949745423.8557243,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '305.2400000000000000',
      high: '360.0000000000000000',
      low: '300.5200000000000000',
      close: '356.0400000000000000',
      volume: '3390309.8850700000000000',
      quoteVolume: 1149588604.2960927,
      btcVolume: 19086.40330757933,
      usdVolume: 1061797763.2331136,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '356.0300000000000000',
      high: '364.9300000000000000',
      low: '318.0000000000000000',
      close: '354.6700000000000000',
      volume: '2657432.8323600000000000',
      quoteVolume: 908738665.4650412,
      btcVolume: 15602.374312501974,
      usdVolume: 887300260.0806935,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '354.5200000000000000',
      high: '374.5000000000000000',
      low: '333.3300000000000000',
      close: '344.8900000000000000',
      volume: '2822658.7155800000000000',
      quoteVolume: 1005641007.73384,
      btcVolume: 17158.120265906025,
      usdVolume: 969197721.0813937,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '344.8900000000000000',
      high: '356.6800000000000000',
      low: '334.0000000000000000',
      close: '347.1000000000000000',
      volume: '1326815.3766000000000000',
      quoteVolume: 458272514.1193381,
      btcVolume: 7737.5166810146,
      usdVolume: 452069650.3441574,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '347.1700000000000000',
      high: '389.0000000000000000',
      low: '333.5100000000000000',
      close: '387.8000000000000000',
      volume: '2292100.0585200000000000',
      quoteVolume: 824990298.5702738,
      btcVolume: 14024.145540287725,
      usdVolume: 811254222.256117,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '387.7700000000000000',
      high: '413.4900000000000000',
      low: '333.6800000000000000',
      close: '358.8900000000000000',
      volume: '3692639.9933200000000000',
      quoteVolume: 1422149685.4017851,
      btcVolume: 16986.416600752273,
      usdVolume: 971531402.2277263,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '358.9300000000000000',
      high: '379.7200000000000000',
      low: '345.0000000000000000',
      close: '377.6000000000000000',
      volume: '1933301.8959500000000000',
      quoteVolume: 696994582.879416,
      btcVolume: 9658.930490487104,
      usdVolume: 538638876.0129843,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '377.8000000000000000',
      high: '394.3700000000000000',
      low: '301.0000000000000000',
      close: '301.1600000000000000',
      volume: '2535240.4789300000000000',
      quoteVolume: 908891543.0191455,
      btcVolume: 16147.06658592599,
      usdVolume: 886854180.1405613,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '310.4000000000000000',
      high: '332.7300000000000000',
      low: '291.0000000000000000',
      close: '317.9500000000000000',
      volume: '2633768.7978000000000000',
      quoteVolume: 831763903.622356,
      btcVolume: 16126.135514947295,
      usdVolume: 800855571.6677618,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '317.9500000000000000',
      high: '338.8500000000000000',
      low: '307.9600000000000000',
      close: '326.2300000000000000',
      volume: '1480983.4927200000000000',
      quoteVolume: 478735235.15381885,
      btcVolume: 9290.562772,
      usdVolume: 466090574.20245117,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '326.2400000000000000',
      high: '332.0000000000000000',
      low: '296.1700000000000000',
      close: '300.3000000000000000',
      volume: '1486174.6400000000000000',
      quoteVolume: 464488652.98064655,
      btcVolume: 9354.642033526949,
      usdVolume: 455189162.857259,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '300.2200000000000000',
      high: '323.9500000000000000',
      low: '279.6600000000000000',
      close: '295.8900000000000000',
      volume: '1689775.5445200000000000',
      quoteVolume: 509125100.09983116,
      btcVolume: 10506.571843399115,
      usdVolume: 493364301.71537817,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '295.8900000000000000',
      high: '296.3400000000000000',
      low: '261.2700000000000000',
      close: '281.5300000000000000',
      volume: '2058950.0621700000000000',
      quoteVolume: 576011740.6532687,
      btcVolume: 12647.507440176665,
      usdVolume: 556665988.3596952,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '281.3800000000000000',
      high: '317.9700000000000000',
      low: '278.3200000000000000',
      close: '293.9800000000000000',
      volume: '2558745.8496900000000000',
      quoteVolume: 770908333.9244474,
      btcVolume: 17076.297568639577,
      usdVolume: 752161422.2082568,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '293.9800000000000000',
      high: '300.3100000000000000',
      low: '147.0000000000000000',
      close: '185.9300000000000000',
      volume: '4429987.0992500000000000',
      quoteVolume: 1024051125.9764041,
      btcVolume: 21425.247480595644,
      usdVolume: 837275028.9459155,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '186.3100000000000000',
      high: '225.0000000000000000',
      low: '166.4500000000000000',
      close: '210.7400000000000000',
      volume: '4267102.3515400000000000',
      quoteVolume: 857136804.6603408,
      btcVolume: 17609.38450938026,
      usdVolume: 690358545.9245719,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '210.6600000000000000',
      high: '219.1200000000000000',
      low: '156.5600000000000000',
      close: '179.9700000000000000',
      volume: '2499829.1189300000000000',
      quoteVolume: 477038651.68571556,
      btcVolume: 9570.554815255731,
      usdVolume: 369647877.63828075,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '179.9700000000000000',
      high: '184.4400000000000000',
      low: '161.6100000000000000',
      close: '169.3200000000000000',
      volume: '1975608.6877700000000000',
      quoteVolume: 344670437.0064659,
      btcVolume: 6063.268649807608,
      usdVolume: 224771211.51457024,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '169.3000000000000000',
      high: '176.2600000000000000',
      low: '118.0300000000000000',
      close: '142.3900000000000000',
      volume: '3854006.1240800000000000',
      quoteVolume: 548343321.08721,
      btcVolume: 15597.115173980857,
      usdVolume: 534078487.19652426,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '142.5200000000000000',
      high: '185.3700000000000000',
      low: '139.5400000000000000',
      close: '185.3200000000000000',
      volume: '2471798.9177700000000000',
      quoteVolume: 401352119.8284848,
      btcVolume: 10534.620490455787,
      usdVolume: 390721604.9641617,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '185.3200000000000000',
      high: '194.6100000000000000',
      low: '167.1500000000000000',
      close: '183.7600000000000000',
      volume: '1974384.4346300000000000',
      quoteVolume: 352461818.3773789,
      btcVolume: 9048.857573695626,
      usdVolume: 343883497.5524442,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '183.8900000000000000',
      high: '204.1300000000000000',
      low: '179.4200000000000000',
      close: '198.9100000000000000',
      volume: '1522242.9800100000000000',
      quoteVolume: 295744722.487022,
      btcVolume: 7397.801239257871,
      usdVolume: 290508656.1029971,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '198.9200000000000000',
      high: '209.0000000000000000',
      low: '180.3900000000000000',
      close: '194.3200000000000000',
      volume: '1457775.3844800000000000',
      quoteVolume: 283796099.3329705,
      btcVolume: 7184.054871626712,
      usdVolume: 278865293.7616737,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '194.3100000000000000',
      high: '198.8800000000000000',
      low: '165.5000000000000000',
      close: '177.3300000000000000',
      volume: '2118247.1385100000000000',
      quoteVolume: 378817824.8082162,
      btcVolume: 10181.549955274471,
      usdVolume: 371492579.2034223,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '177.3000000000000000',
      high: '188.6900000000000000',
      low: '157.6500000000000000',
      close: '164.0100000000000000',
      volume: '1553245.0097100000000000',
      quoteVolume: 267294263.0746701,
      btcVolume: 7489.570968288885,
      usdVolume: 263585916.44740584,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '164.0400000000000000',
      high: '181.1000000000000000',
      low: '155.1100000000000000',
      close: '170.5100000000000000',
      volume: '1118503.2655100000000000',
      quoteVolume: 190582769.66552165,
      btcVolume: 5376.479791676544,
      usdVolume: 189717014.9300997,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '170.4800000000000000',
      high: '188.5000000000000000',
      low: '163.5000000000000000',
      close: '187.9700000000000000',
      volume: '1239310.5303800000000000',
      quoteVolume: 219656209.67978868,
      btcVolume: 6050.641649360358,
      usdVolume: 218836203.75448558,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '188.0100000000000000',
      high: '193.1600000000000000',
      low: '175.8200000000000000',
      close: '182.9900000000000000',
      volume: '1017682.1460000000000000',
      quoteVolume: 185830461.32079786,
      btcVolume: 4992.905208779654,
      usdVolume: 183136314.50446558,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '183.0500000000000000',
      high: '191.3400000000000000',
      low: '178.6300000000000000',
      close: '187.1400000000000000',
      volume: '919350.2437500000000000',
      quoteVolume: 170450027.89501953,
      btcVolume: 4545.1303175238545,
      usdVolume: 169822883.12995762,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '187.1800000000000000',
      high: '197.7800000000000000',
      low: '181.9500000000000000',
      close: '193.5000000000000000',
      volume: '1068163.8122400000000000',
      quoteVolume: 203871823.75115305,
      btcVolume: 5274.935447853314,
      usdVolume: 203567782.85967907,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '193.5200000000000000',
      high: '193.8100000000000000',
      low: '168.9500000000000000',
      close: '178.4400000000000000',
      volume: '1083395.0124300000000000',
      quoteVolume: 192472231.32231694,
      btcVolume: 5072.536802753547,
      usdVolume: 187806213.84734955,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '178.4600000000000000',
      high: '185.4900000000000000',
      low: '167.3200000000000000',
      close: '172.9400000000000000',
      volume: '845338.3479100000000000',
      quoteVolume: 148913591.34313303,
      btcVolume: 4047.721342287558,
      usdVolume: 147372091.39759547,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '172.9500000000000000',
      high: '179.9600000000000000',
      low: '172.0200000000000000',
      close: '176.4100000000000000',
      volume: '676410.3521800000000000',
      quoteVolume: 119363989.89739811,
      btcVolume: 3313.0261015985343,
      usdVolume: 119122275.72225323,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '176.4200000000000000',
      high: '182.2200000000000000',
      low: '160.2500000000000000',
      close: '161.9500000000000000',
      volume: '1035822.8250900000000000',
      quoteVolume: 180434186.55291733,
      btcVolume: 5056.415096494469,
      usdVolume: 179870212.29867515,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '161.9200000000000000',
      high: '164.4800000000000000',
      low: '144.8900000000000000',
      close: '160.6300000000000000',
      volume: '1417289.8764700000000000',
      quoteVolume: 222062589.14860615,
      btcVolume: 6696.45943399783,
      usdVolume: 219130900.61702827,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '160.6400000000000000',
      high: '173.3000000000000000',
      low: '152.3100000000000000',
      close: '172.5600000000000000',
      volume: '1139739.3605400000000000',
      quoteVolume: 186552484.183113,
      btcVolume: 5247.567629145548,
      usdVolume: 184026648.27611592,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '172.5400000000000000',
      high: '174.6100000000000000',
      low: '162.1200000000000000',
      close: '168.1600000000000000',
      volume: '877869.9250200000000000',
      quoteVolume: 147862248.68551347,
      btcVolume: 3977.149088401281,
      usdVolume: 147278462.99204102,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '168.1800000000000000',
      high: '173.7800000000000000',
      low: '160.5500000000000000',
      close: '162.8300000000000000',
      volume: '928611.5444600000000000',
      quoteVolume: 156280950.68599966,
      btcVolume: 4223.884717970092,
      usdVolume: 156041583.2841295,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '162.8400000000000000',
      high: '164.3000000000000000',
      low: '154.1400000000000000',
      close: '161.8000000000000000',
      volume: '653092.9881500000000000',
      quoteVolume: 103889826.80616859,
      btcVolume: 2894.8574142745842,
      usdVolume: 103359782.14313756,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '161.8100000000000000',
      high: '171.5900000000000000',
      low: '156.4200000000000000',
      close: '170.8500000000000000',
      volume: '573493.9078300000000000',
      quoteVolume: 93618007.16348189,
      btcVolume: 2549.4662560893084,
      usdVolume: 93402814.7181441,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '170.8500000000000000',
      high: '179.9900000000000000',
      low: '166.3400000000000000',
      close: '179.8900000000000000',
      volume: '802051.9564500000000000',
      quoteVolume: 138132150.6621468,
      btcVolume: 3462.3042173691624,
      usdVolume: 137992367.33837593,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '179.8800000000000000',
      high: '181.2000000000000000',
      low: '172.1500000000000000',
      close: '175.1500000000000000',
      volume: '654352.0530400000000000',
      quoteVolume: 115569055.5994115,
      btcVolume: 2868.206424184333,
      usdVolume: 115393253.99403785,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '175.1600000000000000',
      high: '177.3100000000000000',
      low: '164.2300000000000000',
      close: '166.5700000000000000',
      volume: '761479.4526300000000000',
      quoteVolume: 129526004.72512966,
      btcVolume: 3269.23856819484,
      usdVolume: 128729009.70918988,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '166.5600000000000000',
      high: '173.4000000000000000',
      low: '162.8100000000000000',
      close: '167.0800000000000000',
      volume: '559434.6236700000000000',
      quoteVolume: 94143650.72569731,
      btcVolume: 2440.0515935312997,
      usdVolume: 93977575.46471305,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '167.1300000000000000',
      high: '167.4300000000000000',
      low: '151.2000000000000000',
      close: '156.3400000000000000',
      volume: '800699.3038400000000000',
      quoteVolume: 127526013.18198103,
      btcVolume: 3451.3788942966594,
      usdVolume: 126795373.6731447,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '156.3300000000000000',
      high: '160.8800000000000000',
      low: '152.3100000000000000',
      close: '152.4900000000000000',
      volume: '522793.6393800000000000',
      quoteVolume: 82002075.66598175,
      btcVolume: 2293.7654961253556,
      usdVolume: 81905459.6444485,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '152.5100000000000000',
      high: '156.1800000000000000',
      low: '143.7500000000000000',
      close: '154.8800000000000000',
      volume: '611126.9176600000000000',
      quoteVolume: 91873187.05818173,
      btcVolume: 2616.173092821781,
      usdVolume: 91098177.96242698,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '154.8700000000000000',
      high: '155.5000000000000000',
      low: '123.9100000000000000',
      close: '124.4600000000000000',
      volume: '1491214.5233800000000000',
      quoteVolume: 203016501.85429946,
      btcVolume: 6075.534275582749,
      usdVolume: 199064002.10991165,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '124.3800000000000000',
      high: '133.0300000000000000',
      low: '104.9200000000000000',
      close: '119.6400000000000000',
      volume: '2255194.7519200000000000',
      quoteVolume: 266658628.66409847,
      btcVolume: 8351.340529707115,
      usdVolume: 262285946.07146153,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '119.6900000000000000',
      high: '133.7500000000000000',
      low: '115.4900000000000000',
      close: '129.1000000000000000',
      volume: '1018662.2307000000000000',
      quoteVolume: 129893644.46478507,
      btcVolume: 3806.186447654474,
      usdVolume: 128367468.01437268,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '129.1300000000000000',
      high: '137.3600000000000000',
      low: '123.7100000000000000',
      close: '134.5800000000000000',
      volume: '650968.5008600000000000',
      quoteVolume: 86002587.93949006,
      btcVolume: 2540.35834770411,
      usdVolume: 85601845.83265461,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '134.5900000000000000',
      high: '138.4500000000000000',
      low: '124.3100000000000000',
      close: '125.0000000000000000',
      volume: '764515.3389200000000000',
      quoteVolume: 99682735.3520955,
      btcVolume: 2989.7157760446407,
      usdVolume: 99437255.44342713,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '125.0300000000000000',
      high: '129.4400000000000000',
      low: '118.6700000000000000',
      close: '126.8900000000000000',
      volume: '868428.5919600000000000',
      quoteVolume: 107074782.7018947,
      btcVolume: 3405.7791541849497,
      usdVolume: 106608690.08379035,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '126.8100000000000000',
      high: '132.6700000000000000',
      low: '124.6600000000000000',
      close: '132.6000000000000000',
      volume: '573388.2468800000000000',
      quoteVolume: 73420914.55001809,
      btcVolume: 2206.7295809874763,
      usdVolume: 73052077.51809245,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '132.5700000000000000',
      high: '139.5500000000000000',
      low: '129.8900000000000000',
      close: '137.6900000000000000',
      volume: '657962.2346700000000000',
      quoteVolume: 88324620.00472222,
      btcVolume: 2556.119863531481,
      usdVolume: 88194403.87278609,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '137.6800000000000000',
      high: '149.1800000000000000',
      low: '137.2400000000000000',
      close: '144.2000000000000000',
      volume: '840281.7752200000000000',
      quoteVolume: 120878119.45537868,
      btcVolume: 3398.725464122438,
      usdVolume: 120738719.24876784,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '144.2300000000000000',
      high: '146.9400000000000000',
      low: '136.0100000000000000',
      close: '144.2600000000000000',
      volume: '601927.1802600000000000',
      quoteVolume: 84998636.27030478,
      btcVolume: 2433.7350642691163,
      usdVolume: 84897826.1464357,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '144.3100000000000000',
      high: '144.3100000000000000',
      low: '133.9900000000000000',
      close: '137.0200000000000000',
      volume: '539535.2997000000000000',
      quoteVolume: 74099823.13621905,
      btcVolume: 2199.154275297908,
      usdVolume: 73990525.94229446,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '137.0100000000000000',
      high: '138.9700000000000000',
      low: '130.6000000000000000',
      close: '136.8500000000000000',
      volume: '464289.9855400000000000',
      quoteVolume: 62070122.90920927,
      btcVolume: 1866.2283049451146,
      usdVolume: 62049635.439033374,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '136.8700000000000000',
      high: '141.3700000000000000',
      low: '134.5700000000000000',
      close: '140.1700000000000000',
      volume: '342781.9026900000000000',
      quoteVolume: 47569780.320621595,
      btcVolume: 1377.1630726778033,
      usdVolume: 47351874.70020899,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '140.1900000000000000',
      high: '148.1000000000000000',
      low: '136.8800000000000000',
      close: '144.9500000000000000',
      volume: '435307.8254500000000000',
      quoteVolume: 62602668.35071889,
      btcVolume: 1770.4592503135034,
      usdVolume: 62526340.18334017,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '144.9600000000000000',
      high: '145.0300000000000000',
      low: '133.8400000000000000',
      close: '137.7100000000000000',
      volume: '561316.3421300000000000',
      quoteVolume: 78108514.57286538,
      btcVolume: 2273.043772946485,
      usdVolume: 77425208.18744867,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '137.7600000000000000',
      high: '142.7300000000000000',
      low: '135.6800000000000000',
      close: '138.8700000000000000',
      volume: '513385.4598500000000000',
      quoteVolume: 71206203.53100015,
      btcVolume: 2058.938771197183,
      usdVolume: 70372583.83128636,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '138.8600000000000000',
      high: '142.9300000000000000',
      low: '136.8700000000000000',
      close: '137.4100000000000000',
      volume: '515462.6606800000000000',
      quoteVolume: 72372106.19902879,
      btcVolume: 2095.11390021604,
      usdVolume: 72372106.19902879,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '137.3800000000000000',
      high: '137.7400000000000000',
      low: '127.7700000000000000',
      close: '132.1500000000000000',
      volume: '645442.5711200000000000',
      quoteVolume: 85207418.88870086,
      btcVolume: 2580.982544909361,
      usdVolume: 84861412.34308095,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '132.1100000000000000',
      high: '135.8500000000000000',
      low: '126.0000000000000000',
      close: '134.5800000000000000',
      volume: '527547.8216300000000000',
      quoteVolume: 69418988.6298179,
      btcVolume: 2095.969894767974,
      usdVolume: 69393787.63974923,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '134.5400000000000000',
      high: '136.8200000000000000',
      low: '130.3200000000000000',
      close: '133.9900000000000000',
      volume: '365844.8683200000000000',
      quoteVolume: 48672574.565501355,
      btcVolume: 1443.797183822238,
      usdVolume: 48670934.54957684,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '133.9900000000000000',
      high: '136.4800000000000000',
      low: '132.0800000000000000',
      close: '134.2800000000000000',
      volume: '324569.4102200000000000',
      quoteVolume: 43649181.09015877,
      btcVolume: 1290.2481974206867,
      usdVolume: 43648244.99860397,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '134.2800000000000000',
      high: '139.7500000000000000',
      low: '131.8200000000000000',
      close: '133.3800000000000000',
      volume: '670917.6235500000000000',
      quoteVolume: 91393565.62359004,
      btcVolume: 2712.82869674453,
      usdVolume: 91352372.01710989,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '133.3200000000000000',
      high: '134.6100000000000000',
      low: '129.3500000000000000',
      close: '131.4400000000000000',
      volume: '446421.4796000000000000',
      quoteVolume: 58969790.60242994,
      btcVolume: 1796.7553077819084,
      usdVolume: 58951683.42339532,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '131.4500000000000000',
      high: '132.4200000000000000',
      low: '123.5900000000000000',
      close: '131.0700000000000000',
      volume: '538549.4072300000000000',
      quoteVolume: 69104634.35945345,
      btcVolume: 2129.938412921626,
      usdVolume: 69047331.59467553,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '131.0600000000000000',
      high: '133.1300000000000000',
      low: '121.9300000000000000',
      close: '125.8600000000000000',
      volume: '494276.3957800000000000',
      quoteVolume: 62599065.68099787,
      btcVolume: 1949.375687069558,
      usdVolume: 62495906.468084864,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '125.8800000000000000',
      high: '127.8700000000000000',
      low: '120.3800000000000000',
      close: '120.6500000000000000',
      volume: '396793.6475500000000000',
      quoteVolume: 49187467.51789337,
      btcVolume: 1551.73880256109,
      usdVolume: 49187456.52809857,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '120.6600000000000000',
      high: '122.9200000000000000',
      low: '117.6800000000000000',
      close: '119.9500000000000000',
      volume: '412797.9346200000000000',
      quoteVolume: 49618380.247838296,
      btcVolume: 1572.2543394713864,
      usdVolume: 49564013.24779822,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '120.0000000000000000',
      high: '124.0500000000000000',
      low: '116.2300000000000000',
      close: '119.1700000000000000',
      volume: '430985.2145700000000000',
      quoteVolume: 51789227.20684465,
      btcVolume: 1633.9578324717402,
      usdVolume: 51787309.337946646,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '119.1600000000000000',
      high: '120.7500000000000000',
      low: '111.8200000000000000',
      close: '113.1300000000000000',
      volume: '493082.9626600000000000',
      quoteVolume: 56960133.80445162,
      btcVolume: 1829.8567510212017,
      usdVolume: 56942282.4436929,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '113.1400000000000000',
      high: '115.0900000000000000',
      low: '103.8300000000000000',
      close: '107.2900000000000000',
      volume: '765414.9566800000000000',
      quoteVolume: 82409699.71007985,
      btcVolume: 2758.298975959001,
      usdVolume: 82377481.65606456,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '107.3100000000000000',
      high: '119.2400000000000000',
      low: '105.0500000000000000',
      close: '117.7400000000000000',
      volume: '682039.4728300000000000',
      quoteVolume: 77598308.8381928,
      btcVolume: 2473.82772359749,
      usdVolume: 77368480.01030874,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '117.7700000000000000',
      high: '122.0000000000000000',
      low: '115.6100000000000000',
      close: '120.6700000000000000',
      volume: '464759.4714600000000000',
      quoteVolume: 55142102.74740585,
      btcVolume: 1710.182490463156,
      usdVolume: 54964505.0582159,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '120.6900000000000000',
      high: '122.1300000000000000',
      low: '117.0200000000000000',
      close: '119.4200000000000000',
      volume: '318529.7456500000000000',
      quoteVolume: 38201094.05437847,
      btcVolume: 1177.7807010659724,
      usdVolume: 38201094.05437847,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
  cel: {
    id: 'cel',
    name: 'CEL',
    symbol: 'CEL',
    image: 'assets/images/coin-logo/CEL.png',
    activity24h: 421323456,
    availableAmount: 208.232323,
    usdxConversion: 2,
    allTimeValue: 55943812390,
    allTimeAsset: 542321,
    candleData: [
      {
      open: '0.5355000000000000',
      high: '0.5355000000000000',
      low: '0.4668000000000000',
      close: '0.4895000000000000',
      volume: '1343660.3100000000000000',
      quoteVolume: 665013.644449,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-16T00:00:00.000Z'
      },
      {
      open: '0.4895000000000000',
      high: '0.4953000000000000',
      low: '0.4639000000000000',
      close: '0.4756000000000000',
      volume: '1411725.3500000000000000',
      quoteVolume: 673447.208597,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-17T00:00:00.000Z'
      },
      {
      open: '0.4762000000000000',
      high: '0.5112000000000000',
      low: '0.4601000000000000',
      close: '0.4894000000000000',
      volume: '984417.5900000000000000',
      quoteVolume: 474999.286242,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-18T00:00:00.000Z'
      },
      {
      open: '0.4908000000000000',
      high: '0.5000000000000000',
      low: '0.4673000000000000',
      close: '0.4831000000000000',
      volume: '735443.2000000000000000',
      quoteVolume: 354090.041046,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-19T00:00:00.000Z'
      },
      {
      open: '0.4803000000000000',
      high: '0.4920000000000000',
      low: '0.4590000000000000',
      close: '0.4834000000000000',
      volume: '677499.5600000000000000',
      quoteVolume: 323333.553838,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-20T00:00:00.000Z'
      },
      {
      open: '0.4839000000000000',
      high: '0.5198000000000000',
      low: '0.4650000000000000',
      close: '0.4972000000000000',
      volume: '897787.8400000000000000',
      quoteVolume: 446086.78762,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-21T00:00:00.000Z'
      },
      {
      open: '0.4960000000000000',
      high: '0.5656000000000000',
      low: '0.4956000000000000',
      close: '0.5344000000000000',
      volume: '1784953.5000000000000000',
      quoteVolume: 955513.881021,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-22T00:00:00.000Z'
      },
      {
      open: '0.5342000000000000',
      high: '0.5475000000000000',
      low: '0.4901000000000000',
      close: '0.5046000000000000',
      volume: '1827069.3900000000000000',
      quoteVolume: 940352.297759,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-23T00:00:00.000Z'
      },
      {
      open: '0.5046000000000000',
      high: '0.5065000000000000',
      low: '0.4518000000000000',
      close: '0.5017000000000000',
      volume: '1529219.5300000000000000',
      quoteVolume: 745639.12774,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-24T00:00:00.000Z'
      },
      {
      open: '0.5031000000000000',
      high: '0.5157000000000000',
      low: '0.4712000000000000',
      close: '0.4739000000000000',
      volume: '1112869.0800000000000000',
      quoteVolume: 544844.844073,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-25T00:00:00.000Z'
      },
      {
      open: '0.4736000000000000',
      high: '0.4884000000000000',
      low: '0.4624000000000000',
      close: '0.4661000000000000',
      volume: '865653.8900000000000000',
      quoteVolume: 410185.821637,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-26T00:00:00.000Z'
      },
      {
      open: '0.4661000000000000',
      high: '0.4666000000000000',
      low: '0.4229000000000000',
      close: '0.4303000000000000',
      volume: '2278919.6500000000000000',
      quoteVolume: 1004304.902092,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-27T00:00:00.000Z'
      },
      {
      open: '0.4310000000000000',
      high: '0.4313000000000000',
      low: '0.3504000000000000',
      close: '0.4116000000000000',
      volume: '4006211.8500000000000000',
      quoteVolume: 1540777.018846,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-28T00:00:00.000Z'
      },
      {
      open: '0.4104000000000000',
      high: '0.4638000000000000',
      low: '0.4061000000000000',
      close: '0.4505000000000000',
      volume: '5190825.3700000000000000',
      quoteVolume: 2252399.736119,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-29T00:00:00.000Z'
      },
      {
      open: '0.4505000000000000',
      high: '0.4514000000000000',
      low: '0.4130000000000000',
      close: '0.4249000000000000',
      volume: '2092369.5900000000000000',
      quoteVolume: 897815.126911,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-30T00:00:00.000Z'
      },
      {
      open: '0.4250000000000000',
      high: '0.4410000000000000',
      low: '0.3811000000000000',
      close: '0.3832000000000000',
      volume: '2029033.6100000000000000',
      quoteVolume: 826552.030246,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-01-31T00:00:00.000Z'
      },
      {
      open: '0.3850000000000000',
      high: '0.4300000000000000',
      low: '0.3800000000000000',
      close: '0.4147000000000000',
      volume: '1899232.5600000000000000',
      quoteVolume: 768342.467271,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-01T00:00:00.000Z'
      },
      {
      open: '0.4163000000000000',
      high: '0.4330000000000000',
      low: '0.3985000000000000',
      close: '0.4136000000000000',
      volume: '821033.4800000000000000',
      quoteVolume: 340840.331218,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-02T00:00:00.000Z'
      },
      {
      open: '0.4170000000000000',
      high: '0.4170000000000000',
      low: '0.3860000000000000',
      close: '0.3963000000000000',
      volume: '1065170.5600000000000000',
      quoteVolume: 424303.971749,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-03T00:00:00.000Z'
      },
      {
      open: '0.3959000000000000',
      high: '0.4125000000000000',
      low: '0.3867000000000000',
      close: '0.3943000000000000',
      volume: '1449384.9600000000000000',
      quoteVolume: 579009.583139,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-04T00:00:00.000Z'
      },
      {
      open: '0.3926000000000000',
      high: '0.4327000000000000',
      low: '0.3902000000000000',
      close: '0.4224000000000000',
      volume: '1299107.0300000000000000',
      quoteVolume: 532341.103934,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-05T00:00:00.000Z'
      },
      {
      open: '0.4224000000000000',
      high: '0.4236000000000000',
      low: '0.3915000000000000',
      close: '0.4000000000000000',
      volume: '1368546.2100000000000000',
      quoteVolume: 552819.621243,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-06T00:00:00.000Z'
      },
      {
      open: '0.4000000000000000',
      high: '0.4065000000000000',
      low: '0.3913000000000000',
      close: '0.4021000000000000',
      volume: '498842.3600000000000000',
      quoteVolume: 199193.78253,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-07T00:00:00.000Z'
      },
      {
      open: '0.4021000000000000',
      high: '0.4500000000000000',
      low: '0.3985000000000000',
      close: '0.4383000000000000',
      volume: '2015969.5800000000000000',
      quoteVolume: 859693.298603,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-08T00:00:00.000Z'
      },
      {
      open: '0.4371000000000000',
      high: '0.4844000000000000',
      low: '0.4310000000000000',
      close: '0.4612000000000000',
      volume: '1384815.9500000000000000',
      quoteVolume: 637171.045365,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-09T00:00:00.000Z'
      },
      {
      open: '0.4648000000000000',
      high: '0.4800000000000000',
      low: '0.4325000000000000',
      close: '0.4499000000000000',
      volume: '2000850.5500000000000000',
      quoteVolume: 913391.61962,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-10T00:00:00.000Z'
      },
      {
      open: '0.4495000000000000',
      high: '0.4515000000000000',
      low: '0.4200000000000000',
      close: '0.4289000000000000',
      volume: '1580197.3900000000000000',
      quoteVolume: 685555.872631,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-11T00:00:00.000Z'
      },
      {
      open: '0.4277000000000000',
      high: '0.4374000000000000',
      low: '0.4176000000000000',
      close: '0.4277000000000000',
      volume: '941303.5800000000000000',
      quoteVolume: 400668.760404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-12T00:00:00.000Z'
      },
      {
      open: '0.4277000000000000',
      high: '0.4454000000000000',
      low: '0.4183000000000000',
      close: '0.4411000000000000',
      volume: '935377.3800000000000000',
      quoteVolume: 400576.99087,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-13T00:00:00.000Z'
      },
      {
      open: '0.4422000000000000',
      high: '0.4442000000000000',
      low: '0.4203000000000000',
      close: '0.4259000000000000',
      volume: '942053.4400000000000000',
      quoteVolume: 407685.941353,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-14T00:00:00.000Z'
      },
      {
      open: '0.4235000000000000',
      high: '0.4500000000000000',
      low: '0.4222000000000000',
      close: '0.4342000000000000',
      volume: '612251.8000000000000000',
      quoteVolume: 264659.849465,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-15T00:00:00.000Z'
      },
      {
      open: '0.4366000000000000',
      high: '0.4464000000000000',
      low: '0.4251000000000000',
      close: '0.4398000000000000',
      volume: '465491.5800000000000000',
      quoteVolume: 203941.272106,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-16T00:00:00.000Z'
      },
      {
      open: '0.4386000000000000',
      high: '0.4797000000000000',
      low: '0.4317000000000000',
      close: '0.4604000000000000',
      volume: '1616509.7200000000000000',
      quoteVolume: 740396.471002,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-17T00:00:00.000Z'
      },
      {
      open: '0.4615000000000000',
      high: '0.4823000000000000',
      low: '0.4534000000000000',
      close: '0.4710000000000000',
      volume: '1430254.7800000000000000',
      quoteVolume: 671573.658591,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-18T00:00:00.000Z'
      },
      {
      open: '0.4710000000000000',
      high: '0.4789000000000000',
      low: '0.4520000000000000',
      close: '0.4598000000000000',
      volume: '1548002.4400000000000000',
      quoteVolume: 724054.883013,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-19T00:00:00.000Z'
      },
      {
      open: '0.4548000000000000',
      high: '0.4748000000000000',
      low: '0.4432000000000000',
      close: '0.4618000000000000',
      volume: '1358786.2800000000000000',
      quoteVolume: 625130.767047,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-20T00:00:00.000Z'
      },
      {
      open: '0.4617000000000000',
      high: '0.4750000000000000',
      low: '0.4380000000000000',
      close: '0.4438000000000000',
      volume: '1443214.9800000000000000',
      quoteVolume: 655875.790716,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-21T00:00:00.000Z'
      },
      {
      open: '0.4438000000000000',
      high: '0.4650000000000000',
      low: '0.4400000000000000',
      close: '0.4479000000000000',
      volume: '700523.7700000000000000',
      quoteVolume: 313850.863647,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-22T00:00:00.000Z'
      },
      {
      open: '0.4480000000000000',
      high: '0.4640000000000000',
      low: '0.4437000000000000',
      close: '0.4585000000000000',
      volume: '925529.6400000000000000',
      quoteVolume: 419693.727298,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-23T00:00:00.000Z'
      },
      {
      open: '0.4592000000000000',
      high: '0.4644000000000000',
      low: '0.3975000000000000',
      close: '0.4050000000000000',
      volume: '2154193.2500000000000000',
      quoteVolume: 917345.275562,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-24T00:00:00.000Z'
      },
      {
      open: '0.4047000000000000',
      high: '0.4749000000000000',
      low: '0.4007000000000000',
      close: '0.4723000000000000',
      volume: '1785356.2100000000000000',
      quoteVolume: 783690.750459,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-25T00:00:00.000Z'
      },
      {
      open: '0.4722000000000000',
      high: '0.4740000000000000',
      low: '0.4300000000000000',
      close: '0.4341000000000000',
      volume: '1775526.6700000000000000',
      quoteVolume: 796546.586808,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-26T00:00:00.000Z'
      },
      {
      open: '0.4335000000000000',
      high: '0.4412000000000000',
      low: '0.4145000000000000',
      close: '0.4274000000000000',
      volume: '854212.4500000000000000',
      quoteVolume: 365787.418355,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-27T00:00:00.000Z'
      },
      {
      open: '0.4274000000000000',
      high: '0.4500000000000000',
      low: '0.4166000000000000',
      close: '0.4244000000000000',
      volume: '1059968.1300000000000000',
      quoteVolume: 455311.559954,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-02-28T00:00:00.000Z'
      },
      {
      open: '0.4236000000000000',
      high: '0.4386000000000000',
      low: '0.4203000000000000',
      close: '0.4285000000000000',
      volume: '560690.8100000000000000',
      quoteVolume: 239706.048076,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-01T00:00:00.000Z'
      },
      {
      open: '0.4286000000000000',
      high: '0.4372000000000000',
      low: '0.4173000000000000',
      close: '0.4221000000000000',
      volume: '331570.0000000000000000',
      quoteVolume: 140599.974044,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-02T00:00:00.000Z'
      },
      {
      open: '0.4232000000000000',
      high: '0.4292000000000000',
      low: '0.4177000000000000',
      close: '0.4205000000000000',
      volume: '225429.9500000000000000',
      quoteVolume: 95660.216465,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-03T00:00:00.000Z'
      },
      {
      open: '0.4203000000000000',
      high: '0.4255000000000000',
      low: '0.3929000000000000',
      close: '0.4036000000000000',
      volume: '946382.1900000000000000',
      quoteVolume: 387502.948292,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-04T00:00:00.000Z'
      },
      {
      open: '0.4029000000000000',
      high: '0.4343000000000000',
      low: '0.4023000000000000',
      close: '0.4291000000000000',
      volume: '1686179.9000000000000000',
      quoteVolume: 703630.897735,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-05T00:00:00.000Z'
      },
      {
      open: '0.4291000000000000',
      high: '0.4385000000000000',
      low: '0.4186000000000000',
      close: '0.4296000000000000',
      volume: '716328.1500000000000000',
      quoteVolume: 305884.809425,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-06T00:00:00.000Z'
      },
      {
      open: '0.4296000000000000',
      high: '0.4766000000000000',
      low: '0.4256000000000000',
      close: '0.4521000000000000',
      volume: '1498764.0300000000000000',
      quoteVolume: 677393.543142,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-07T00:00:00.000Z'
      },
      {
      open: '0.4521000000000000',
      high: '0.5107000000000000',
      low: '0.4519000000000000',
      close: '0.4611000000000000',
      volume: '3248280.8300000000000000',
      quoteVolume: 1570069.711841,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-08T00:00:00.000Z'
      },
      {
      open: '0.4611000000000000',
      high: '0.4824000000000000',
      low: '0.4524000000000000',
      close: '0.4746000000000000',
      volume: '1381686.4300000000000000',
      quoteVolume: 643096.002798,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-09T00:00:00.000Z'
      },
      {
      open: '0.4742000000000000',
      high: '0.5460000000000000',
      low: '0.4681000000000000',
      close: '0.4981000000000000',
      volume: '2887991.8600000000000000',
      quoteVolume: 1456511.395647,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-10T00:00:00.000Z'
      },
      {
      open: '0.4982000000000000',
      high: '0.5181000000000000',
      low: '0.4382000000000000',
      close: '0.4629000000000000',
      volume: '2633054.2100000000000000',
      quoteVolume: 1262800.852306,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-11T00:00:00.000Z'
      },
      {
      open: '0.4641000000000000',
      high: '0.5079000000000000',
      low: '0.4503000000000000',
      close: '0.4876000000000000',
      volume: '2105182.6700000000000000',
      quoteVolume: 1016416.428628,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-12T00:00:00.000Z'
      },
      {
      open: '0.4872000000000000',
      high: '0.4880000000000000',
      low: '0.4671000000000000',
      close: '0.4805000000000000',
      volume: '1263356.6800000000000000',
      quoteVolume: 602730.177399,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-13T00:00:00.000Z'
      },
      {
      open: '0.4814000000000000',
      high: '0.4994000000000000',
      low: '0.4722000000000000',
      close: '0.4871000000000000',
      volume: '1720792.4000000000000000',
      quoteVolume: 835254.863559,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-14T00:00:00.000Z'
      },
      {
      open: '0.4858000000000000',
      high: '0.4960000000000000',
      low: '0.4831000000000000',
      close: '0.4858000000000000',
      volume: '577893.0600000000000000',
      quoteVolume: 281897.916414,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-15T00:00:00.000Z'
      },
      {
      open: '0.4866000000000000',
      high: '0.4960000000000000',
      low: '0.4681000000000000',
      close: '0.4819000000000000',
      volume: '1768514.5100000000000000',
      quoteVolume: 850597.475821,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-16T00:00:00.000Z'
      },
      {
      open: '0.4805000000000000',
      high: '0.4887000000000000',
      low: '0.4702000000000000',
      close: '0.4785000000000000',
      volume: '1352126.7300000000000000',
      quoteVolume: 645227.394195,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-17T00:00:00.000Z'
      },
      {
      open: '0.4789000000000000',
      high: '0.4833000000000000',
      low: '0.4670000000000000',
      close: '0.4757000000000000',
      volume: '891826.0900000000000000',
      quoteVolume: 422057.338849,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-18T00:00:00.000Z'
      },
      {
      open: '0.4760000000000000',
      high: '0.4800000000000000',
      low: '0.4648000000000000',
      close: '0.4770000000000000',
      volume: '530734.5900000000000000',
      quoteVolume: 250536.102196,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-19T00:00:00.000Z'
      },
      {
      open: '0.4796000000000000',
      high: '0.4899000000000000',
      low: '0.4737000000000000',
      close: '0.4807000000000000',
      volume: '696146.2800000000000000',
      quoteVolume: 335078.549549,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-20T00:00:00.000Z'
      },
      {
      open: '0.4818000000000000',
      high: '0.4842000000000000',
      low: '0.4342000000000000',
      close: '0.4548000000000000',
      volume: '1043991.8000000000000000',
      quoteVolume: 484257.113374,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-21T00:00:00.000Z'
      },
      {
      open: '0.4551000000000000',
      high: '0.4655000000000000',
      low: '0.4470000000000000',
      close: '0.4646000000000000',
      volume: '863577.3300000000000000',
      quoteVolume: 392384.916353,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-22T00:00:00.000Z'
      },
      {
      open: '0.4627000000000000',
      high: '0.4644000000000000',
      low: '0.4545000000000000',
      close: '0.4608000000000000',
      volume: '406749.6600000000000000',
      quoteVolume: 186648.714066,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-23T00:00:00.000Z'
      },
      {
      open: '0.4606000000000000',
      high: '0.4621000000000000',
      low: '0.4485000000000000',
      close: '0.4531000000000000',
      volume: '634173.7500000000000000',
      quoteVolume: 287618.895525,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-24T00:00:00.000Z'
      },
      {
      open: '0.4539000000000000',
      high: '0.4949000000000000',
      low: '0.4410000000000000',
      close: '0.4734000000000000',
      volume: '2441517.9900000000000000',
      quoteVolume: 1142857.563225,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-25T00:00:00.000Z'
      },
      {
      open: '0.4729000000000000',
      high: '0.4850000000000000',
      low: '0.4480000000000000',
      close: '0.4605000000000000',
      volume: '1129722.2000000000000000',
      quoteVolume: 526180.143477,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-26T00:00:00.000Z'
      },
      {
      open: '0.4603000000000000',
      high: '0.4997000000000000',
      low: '0.4556000000000000',
      close: '0.4946000000000000',
      volume: '1352736.3400000000000000',
      quoteVolume: 651471.143333,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-27T00:00:00.000Z'
      },
      {
      open: '0.4967000000000000',
      high: '0.5196000000000000',
      low: '0.4846000000000000',
      close: '0.4992000000000000',
      volume: '1443876.8300000000000000',
      quoteVolume: 719069.643363,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-28T00:00:00.000Z'
      },
      {
      open: '0.4984000000000000',
      high: '0.5042000000000000',
      low: '0.4887000000000000',
      close: '0.5008000000000000',
      volume: '877803.9200000000000000',
      quoteVolume: 434977.122532,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-29T00:00:00.000Z'
      },
      {
      open: '0.5008000000000000',
      high: '0.5034000000000000',
      low: '0.4858000000000000',
      close: '0.4940000000000000',
      volume: '675007.1300000000000000',
      quoteVolume: 332877.149186,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-30T00:00:00.000Z'
      },
      {
      open: '0.4938000000000000',
      high: '0.5410000000000000',
      low: '0.4868000000000000',
      close: '0.5084000000000000',
      volume: '2047013.3300000000000000',
      quoteVolume: 1057434.916151,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-03-31T00:00:00.000Z'
      },
      {
      open: '0.5084000000000000',
      high: '0.5630000000000000',
      low: '0.5007000000000000',
      close: '0.5540000000000000',
      volume: '2288623.9600000000000000',
      quoteVolume: 1226137.818617,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-01T00:00:00.000Z'
      },
      {
      open: '0.5533000000000000',
      high: '0.6000000000000000',
      low: '0.5326000000000000',
      close: '0.5811000000000000',
      volume: '4081948.8400000000000000',
      quoteVolume: 2328532.773552,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-02T00:00:00.000Z'
      },
      {
      open: '0.5811000000000000',
      high: '0.6073000000000000',
      low: '0.5170000000000000',
      close: '0.5620000000000000',
      volume: '4435492.2100000000000000',
      quoteVolume: 2584274.57288,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-03T00:00:00.000Z'
      },
      {
      open: '0.5588000000000000',
      high: '0.5839000000000000',
      low: '0.5195000000000000',
      close: '0.5396000000000000',
      volume: '2088035.7200000000000000',
      quoteVolume: 1155026.496367,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-04T00:00:00.000Z'
      },
      {
      open: '0.5409000000000000',
      high: '0.5799000000000000',
      low: '0.5390000000000000',
      close: '0.5729000000000000',
      volume: '1039348.6800000000000000',
      quoteVolume: 579093.364827,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-05T00:00:00.000Z'
      },
      {
      open: '0.5735000000000000',
      high: '0.5900000000000000',
      low: '0.5557000000000000',
      close: '0.5691000000000000',
      volume: '1176149.9800000000000000',
      quoteVolume: 672544.531992,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-06T00:00:00.000Z'
      },
      {
      open: '0.5677000000000000',
      high: '0.5918000000000000',
      low: '0.5655000000000000',
      close: '0.5773000000000000',
      volume: '956649.3600000000000000',
      quoteVolume: 552493.434727,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-07T00:00:00.000Z'
      },
      {
      open: '0.5776000000000000',
      high: '0.5912000000000000',
      low: '0.5370000000000000',
      close: '0.5634000000000000',
      volume: '1559203.7500000000000000',
      quoteVolume: 875383.308889,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-08T00:00:00.000Z'
      },
      {
      open: '0.5617000000000000',
      high: '0.5637000000000000',
      low: '0.5170000000000000',
      close: '0.5300000000000000',
      volume: '1284451.2500000000000000',
      quoteVolume: 682600.691592,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-09T00:00:00.000Z'
      },
      {
      open: '0.5300000000000000',
      high: '0.5452000000000000',
      low: '0.5100000000000000',
      close: '0.5197000000000000',
      volume: '1259717.4200000000000000',
      quoteVolume: 662636.077743,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-10T00:00:00.000Z'
      },
      {
      open: '0.5198000000000000',
      high: '0.5210000000000000',
      low: '0.4530000000000000',
      close: '0.4821000000000000',
      volume: '1891750.2700000000000000',
      quoteVolume: 920572.945172,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-11T00:00:00.000Z'
      },
      {
      open: '0.4821000000000000',
      high: '0.5134000000000000',
      low: '0.4531000000000000',
      close: '0.4920000000000000',
      volume: '1247794.8300000000000000',
      quoteVolume: 609014.276187,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-12T00:00:00.000Z'
      },
      {
      open: '0.4932000000000000',
      high: '0.5399000000000000',
      low: '0.4913000000000000',
      close: '0.5358000000000000',
      volume: '984745.9300000000000000',
      quoteVolume: 514382.609618,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-13T00:00:00.000Z'
      },
      {
      open: '0.5366000000000000',
      high: '0.5449000000000000',
      low: '0.5073000000000000',
      close: '0.5225000000000000',
      volume: '825009.0900000000000000',
      quoteVolume: 431556.816813,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-14T00:00:00.000Z'
      },
      {
      open: '0.5259000000000000',
      high: '0.5268000000000000',
      low: '0.4800000000000000',
      close: '0.4883000000000000',
      volume: '1617780.6900000000000000',
      quoteVolume: 812827.262155,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-15T00:00:00.000Z'
      },
      {
      open: '0.4884000000000000',
      high: '0.5176000000000000',
      low: '0.4883000000000000',
      close: '0.4985000000000000',
      volume: '752244.2900000000000000',
      quoteVolume: 377882.383651,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-16T00:00:00.000Z'
      },
      {
      open: '0.4998000000000000',
      high: '0.5046000000000000',
      low: '0.4880000000000000',
      close: '0.4998000000000000',
      volume: '625939.6200000000000000',
      quoteVolume: 310958.450695,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-17T00:00:00.000Z'
      },
      {
      open: '0.4992000000000000',
      high: '0.5226000000000000',
      low: '0.4974000000000000',
      close: '0.5193000000000000',
      volume: '747995.9900000000000000',
      quoteVolume: 382966.982357,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-18T00:00:00.000Z'
      },
      {
      open: '0.5187000000000000',
      high: '0.5390000000000000',
      low: '0.5083000000000000',
      close: '0.5095000000000000',
      volume: '857084.8000000000000000',
      quoteVolume: 447910.430574,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-19T00:00:00.000Z'
      },
      {
      open: '0.5105000000000000',
      high: '0.5287000000000000',
      low: '0.5050000000000000',
      close: '0.5118000000000000',
      volume: '547954.6800000000000000',
      quoteVolume: 281962.973871,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-20T00:00:00.000Z'
      },
      {
      open: '0.5117000000000000',
      high: '0.5139000000000000',
      low: '0.4752000000000000',
      close: '0.4963000000000000',
      volume: '755636.9500000000000000',
      quoteVolume: 371111.531726,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-21T00:00:00.000Z'
      },
      {
      open: '0.4963000000000000',
      high: '0.5170000000000000',
      low: '0.4837000000000000',
      close: '0.4957000000000000',
      volume: '669329.7000000000000000',
      quoteVolume: 335305.551779,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-22T00:00:00.000Z'
      },
      {
      open: '0.4920000000000000',
      high: '0.5040000000000000',
      low: '0.4756000000000000',
      close: '0.4756000000000000',
      volume: '798688.9900000000000000',
      quoteVolume: 391603.526803,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-23T00:00:00.000Z'
      },
      {
      open: '0.4756000000000000',
      high: '0.4800000000000000',
      low: '0.4400000000000000',
      close: '0.4750000000000000',
      volume: '1341167.6500000000000000',
      quoteVolume: 614034.70833,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-24T00:00:00.000Z'
      },
      {
      open: '0.4745000000000000',
      high: '0.4997000000000000',
      low: '0.4350000000000000',
      close: '0.4503000000000000',
      volume: '1289948.5700000000000000',
      quoteVolume: 607159.886379,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-25T00:00:00.000Z'
      },
      {
      open: '0.4499000000000000',
      high: '0.4699000000000000',
      low: '0.4264000000000000',
      close: '0.4416000000000000',
      volume: '881943.9300000000000000',
      quoteVolume: 389402.998679,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-26T00:00:00.000Z'
      },
      {
      open: '0.4437000000000000',
      high: '0.4698000000000000',
      low: '0.4357000000000000',
      close: '0.4430000000000000',
      volume: '330467.6700000000000000',
      quoteVolume: 149494.592163,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-27T00:00:00.000Z'
      },
      {
      open: '0.4448000000000000',
      high: '0.4600000000000000',
      low: '0.4403000000000000',
      close: '0.4510000000000000',
      volume: '429353.9200000000000000',
      quoteVolume: 194108.9041,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-28T00:00:00.000Z'
      },
      {
      open: '0.4510000000000000',
      high: '0.4546000000000000',
      low: '0.4240000000000000',
      close: '0.4338000000000000',
      volume: '394944.3300000000000000',
      quoteVolume: 173429.731609,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-29T00:00:00.000Z'
      },
      {
      open: '0.4334000000000000',
      high: '0.4771000000000000',
      low: '0.4320000000000000',
      close: '0.4727000000000000',
      volume: '610148.8000000000000000',
      quoteVolume: 281321.216962,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-04-30T00:00:00.000Z'
      },
      {
      open: '0.4750000000000000',
      high: '0.4876000000000000',
      low: '0.4638000000000000',
      close: '0.4730000000000000',
      volume: '397476.9300000000000000',
      quoteVolume: 188524.095397,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-01T00:00:00.000Z'
      },
      {
      open: '0.4745000000000000',
      high: '0.4757000000000000',
      low: '0.4600000000000000',
      close: '0.4630000000000000',
      volume: '228270.4000000000000000',
      quoteVolume: 106411.499404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-02T00:00:00.000Z'
      },
      {
      open: '0.4643000000000000',
      high: '0.5223000000000000',
      low: '0.4607000000000000',
      close: '0.5110000000000000',
      volume: '1017233.7800000000000000',
      quoteVolume: 502255.676203,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-03T00:00:00.000Z'
      },
      {
      open: '0.5118000000000000',
      high: '0.5151000000000000',
      low: '0.4783000000000000',
      close: '0.4964000000000000',
      volume: '640984.0600000000000000',
      quoteVolume: 318811.507836,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-04T00:00:00.000Z'
      },
      {
      open: '0.4964000000000000',
      high: '0.5238000000000000',
      low: '0.4902000000000000',
      close: '0.5058000000000000',
      volume: '641774.3200000000000000',
      quoteVolume: 328895.388404,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-05T00:00:00.000Z'
      },
      {
      open: '0.5058000000000000',
      high: '0.5882000000000000',
      low: '0.4901000000000000',
      close: '0.5745000000000000',
      volume: '1593771.0200000000000000',
      quoteVolume: 868680.989601,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-06T00:00:00.000Z'
      },
      {
      open: '0.5798000000000000',
      high: '0.6186000000000000',
      low: '0.5409000000000000',
      close: '0.5435000000000000',
      volume: '2267674.3000000000000000',
      quoteVolume: 1314846.891036,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-07T00:00:00.000Z'
      },
      {
      open: '0.5433000000000000',
      high: '0.6157000000000000',
      low: '0.5212000000000000',
      close: '0.5940000000000000',
      volume: '1255624.9100000000000000',
      quoteVolume: 730122.02836,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-08T00:00:00.000Z'
      },
      {
      open: '0.5940000000000000',
      high: '0.7040000000000000',
      low: '0.5873000000000000',
      close: '0.6403000000000000',
      volume: '3548028.1300000000000000',
      quoteVolume: 2284998.871122,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-09T00:00:00.000Z'
      },
      {
      open: '0.6402000000000000',
      high: '0.7233000000000000',
      low: '0.6374000000000000',
      close: '0.6703000000000000',
      volume: '2987660.6900000000000000',
      quoteVolume: 2051444.51188,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-10T00:00:00.000Z'
      },
      {
      open: '0.6731000000000000',
      high: '0.7484000000000000',
      low: '0.6517000000000000',
      close: '0.6752000000000000',
      volume: '3014329.8900000000000000',
      quoteVolume: 2076341.525905,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-11T00:00:00.000Z'
      },
      {
      open: '0.6790000000000000',
      high: '0.6999000000000000',
      low: '0.6285000000000000',
      close: '0.6586000000000000',
      volume: '1760800.0800000000000000',
      quoteVolume: 1165285.921864,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-12T00:00:00.000Z'
      },
      {
      open: '0.6607000000000000',
      high: '0.6872000000000000',
      low: '0.6336000000000000',
      close: '0.6474000000000000',
      volume: '1281864.7000000000000000',
      quoteVolume: 853179.428728,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-13T00:00:00.000Z'
      },
      {
      open: '0.6474000000000000',
      high: '0.9565000000000000',
      low: '0.6332000000000000',
      close: '0.8280000000000000',
      volume: '7911014.0700000000000000',
      quoteVolume: 6371210.43235,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-14T00:00:00.000Z'
      },
      {
      open: '0.8251000000000000',
      high: '0.8890000000000000',
      low: '0.7772000000000000',
      close: '0.8427000000000000',
      volume: '3798325.6400000000000000',
      quoteVolume: 3148626.361888,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-15T00:00:00.000Z'
      },
      {
      open: '0.8427000000000000',
      high: '0.9389000000000000',
      low: '0.7551000000000000',
      close: '0.9280000000000000',
      volume: '4555532.1900000000000000',
      quoteVolume: 3883945.234255,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-16T00:00:00.000Z'
      },
      {
      open: '0.9264000000000000',
      high: '0.9869000000000000',
      low: '0.8100000000000000',
      close: '0.8911000000000000',
      volume: '5725645.6900000000000000',
      quoteVolume: 5097003.16005,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-17T00:00:00.000Z'
      },
      {
      open: '0.8950000000000000',
      high: '0.9592000000000000',
      low: '0.8457000000000000',
      close: '0.9359000000000000',
      volume: '2389369.0400000000000000',
      quoteVolume: 2179041.174403,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-18T00:00:00.000Z'
      },
      {
      open: '0.9360000000000000',
      high: '1.0500000000000000',
      low: '0.9279000000000000',
      close: '0.9934000000000000',
      volume: '4785605.6200000000000000',
      quoteVolume: 4702849.494509,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-19T00:00:00.000Z'
      },
      {
      open: '0.9927000000000000',
      high: '1.1430000000000000',
      low: '0.9460000000000000',
      close: '1.1170000000000000',
      volume: '2936467.3000000000000000',
      quoteVolume: 2982285.87569,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-20T00:00:00.000Z'
      },
      {
      open: '1.1146000000000000',
      high: '1.2500000000000000',
      low: '1.0600000000000000',
      close: '1.1798000000000000',
      volume: '5046994.2000000000000000',
      quoteVolume: 5745623.487639,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-21T00:00:00.000Z'
      },
      {
      open: '1.1855000000000000',
      high: '1.4740000000000000',
      low: '1.1301000000000000',
      close: '1.2205000000000000',
      volume: '14821357.0900000000000000',
      quoteVolume: 19619790.33193,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-22T00:00:00.000Z'
      },
      {
      open: '1.2205000000000000',
      high: '1.3655000000000000',
      low: '1.1418000000000000',
      close: '1.3392000000000000',
      volume: '6874618.3900000000000000',
      quoteVolume: 8592874.224192,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-23T00:00:00.000Z'
      },
      {
      open: '1.3393000000000000',
      high: '1.4347000000000000',
      low: '1.2216000000000000',
      close: '1.3314000000000000',
      volume: '5097940.0500000000000000',
      quoteVolume: 6795296.790503,
      btcVolume: 0,
      usdVolume: 0,
      time: '2019-05-24T00:00:00.000Z'
      },
      {
      open: '1.3257000000000000',
      high: '1.3376000000000000',
      low: '1.1005000000000000',
      close: '1.1139000000000000',
      volume: '3990172.6100000000000000',
      quoteVolume: 4655614.840081993,
      btcVolume: 581.9913178767249,
      usdVolume: 4655614.840081993,
      time: '2019-05-25T00:00:00.000Z'
      },
      {
      open: '1.1181000000000000',
      high: '1.1824000000000000',
      low: '1.0340000000000000',
      close: '1.0756000000000000',
      volume: '2787144.3800000000000000',
      quoteVolume: 3053065.8027050006,
      btcVolume: 377.16663921841183,
      usdVolume: 3053065.8027050006,
      time: '2019-05-26T00:00:00.000Z'
      },
      {
      open: '1.0806000000000000',
      high: '1.2750000000000000',
      low: '1.0806000000000000',
      close: '1.2119000000000000',
      volume: '3879947.9900000000000000',
      quoteVolume: 4585345.262517948,
      btcVolume: 526.2433979570677,
      usdVolume: 4585345.262517948,
      time: '2019-05-27T00:00:00.000Z'
      },
      {
      open: '1.2199000000000000',
      high: '1.3500000000000000',
      low: '1.1444000000000000',
      close: '1.2921000000000000',
      volume: '2591508.8200000000000000',
      quoteVolume: 3228837.802578005,
      btcVolume: 371.1273709190563,
      usdVolume: 3228837.802578005,
      time: '2019-05-28T00:00:00.000Z'
      },
      {
      open: '1.2782000000000000',
      high: '1.2844000000000000',
      low: '1.1497000000000000',
      close: '1.1665000000000000',
      volume: '2450800.8100000000000000',
      quoteVolume: 2938928.7943600034,
      btcVolume: 340.2041830509092,
      usdVolume: 2938928.7943600034,
      time: '2019-05-29T00:00:00.000Z'
      },
      {
      open: '1.1780000000000000',
      high: '1.2250000000000000',
      low: '0.9044000000000000',
      close: '1.0057000000000000',
      volume: '6305578.1300000000000000',
      quoteVolume: 6787576.3371680295,
      btcVolume: 791.9128840898691,
      usdVolume: 6787576.3371680295,
      time: '2019-05-30T00:00:00.000Z'
      },
      {
      open: '1.0063000000000000',
      high: '1.0444000000000000',
      low: '0.9330000000000000',
      close: '1.0195000000000000',
      volume: '4936161.7800000000000000',
      quoteVolume: 4857535.299134998,
      btcVolume: 581.7438750463537,
      usdVolume: 4857535.299134998,
      time: '2019-05-31T00:00:00.000Z'
      },
      {
      open: '1.0252000000000000',
      high: '1.0597000000000000',
      low: '0.9511000000000000',
      close: '0.9560000000000000',
      volume: '2558921.2100000000000000',
      quoteVolume: 2554370.774409004,
      btcVolume: 299.04123365519683,
      usdVolume: 2554370.774409004,
      time: '2019-06-01T00:00:00.000Z'
      },
      {
      open: '0.9536000000000000',
      high: '1.0554000000000000',
      low: '0.9323000000000000',
      close: '1.0155000000000000',
      volume: '3448639.2900000000000000',
      quoteVolume: 3433793.2090730225,
      btcVolume: 396.5090133601953,
      usdVolume: 3433793.2090730225,
      time: '2019-06-02T00:00:00.000Z'
      },
      {
      open: '1.0155000000000000',
      high: '1.0200000000000000',
      low: '0.8853000000000000',
      close: '0.9000000000000000',
      volume: '3218003.4000000000000000',
      quoteVolume: 3096993.8518149965,
      btcVolume: 365.97881939525035,
      usdVolume: 3096993.8518149965,
      time: '2019-06-03T00:00:00.000Z'
      },
      {
      open: '0.8985000000000000',
      high: '0.9299000000000000',
      low: '0.8368000000000000',
      close: '0.8850000000000000',
      volume: '4563258.6100000000000000',
      quoteVolume: 4005371.5748359957,
      btcVolume: 509.9416978186965,
      usdVolume: 4005371.5748359957,
      time: '2019-06-04T00:00:00.000Z'
      },
      {
      open: '0.8850000000000000',
      high: '1.0991000000000000',
      low: '0.8838000000000000',
      close: '1.0959000000000000',
      volume: '10134365.7300000000000000',
      quoteVolume: 10259929.50928907,
      btcVolume: 1323.3373278111412,
      usdVolume: 10259929.50928907,
      time: '2019-06-05T00:00:00.000Z'
      },
      {
      open: '1.0960000000000000',
      high: '1.2700000000000000',
      low: '1.0556000000000000',
      close: '1.1098000000000000',
      volume: '11104062.4700000000000000',
      quoteVolume: 12707991.386383945,
      btcVolume: 1649.824982729021,
      usdVolume: 12707991.386383945,
      time: '2019-06-06T00:00:00.000Z'
      },
      {
      open: '1.1099000000000000',
      high: '1.2589000000000000',
      low: '1.0710000000000000',
      close: '1.1753000000000000',
      volume: '6318630.4200000000000000',
      quoteVolume: 7452520.230448068,
      btcVolume: 944.3447342802139,
      usdVolume: 7452520.230448068,
      time: '2019-06-07T00:00:00.000Z'
      },
      {
      open: '1.1747000000000000',
      high: '1.1991000000000000',
      low: '1.1165000000000000',
      close: '1.1326000000000000',
      volume: '2532914.5100000000000000',
      quoteVolume: 2922870.3576520067,
      btcVolume: 369.3243148377816,
      usdVolume: 2922870.3576520067,
      time: '2019-06-08T00:00:00.000Z'
      },
      {
      open: '1.1376000000000000',
      high: '1.1486000000000000',
      low: '1.0085000000000000',
      close: '1.0595000000000000',
      volume: '3542850.8800000000000000',
      quoteVolume: 3804204.519311013,
      btcVolume: 491.5768447574139,
      usdVolume: 3804204.519311013,
      time: '2019-06-09T00:00:00.000Z'
      },
      {
      open: '1.0595000000000000',
      high: '1.2104000000000000',
      low: '1.0301000000000000',
      close: '1.2060000000000000',
      volume: '3560912.7500000000000000',
      quoteVolume: 4027483.4204219743,
      btcVolume: 514.0312403954563,
      usdVolume: 4027483.4204219743,
      time: '2019-06-10T00:00:00.000Z'
      },
      {
      open: '1.2043000000000000',
      high: '1.2070000000000000',
      low: '1.0876000000000000',
      close: '1.1243000000000000',
      volume: '3064771.3900000000000000',
      quoteVolume: 3504537.899613992,
      btcVolume: 445.75194109413064,
      usdVolume: 3504537.899613992,
      time: '2019-06-11T00:00:00.000Z'
      },
      {
      open: '1.1241000000000000',
      high: '1.1643000000000000',
      low: '1.0875000000000000',
      close: '1.1448000000000000',
      volume: '2552167.9900000000000000',
      quoteVolume: 2871559.2307969993,
      btcVolume: 359.4040334628175,
      usdVolume: 2871559.2307969993,
      time: '2019-06-12T00:00:00.000Z'
      },
      {
      open: '1.1448000000000000',
      high: '1.9700000000000000',
      low: '1.1126000000000000',
      close: '1.8680000000000000',
      volume: '34026255.5700000000000000',
      quoteVolume: 58291200.9484317,
      btcVolume: 7099.103367062914,
      usdVolume: 58291200.9484317,
      time: '2019-06-13T00:00:00.000Z'
      },
      {
      open: '1.8680000000000000',
      high: '1.8936000000000000',
      low: '1.4900000000000000',
      close: '1.6395000000000000',
      volume: '33206570.6700000000000000',
      quoteVolume: 54533735.08588836,
      btcVolume: 6570.521290055457,
      usdVolume: 54533735.08588836,
      time: '2019-06-14T00:00:00.000Z'
      },
      {
      open: '1.6364000000000000',
      high: '1.8065000000000000',
      low: '1.6022000000000000',
      close: '1.7179000000000000',
      volume: '10700181.1400000000000000',
      quoteVolume: 18387957.79867493,
      btcVolume: 2121.574515120722,
      usdVolume: 18387957.79867493,
      time: '2019-06-15T00:00:00.000Z'
      },
      {
      open: '1.7180000000000000',
      high: '1.7924000000000000',
      low: '1.6000000000000000',
      close: '1.6621000000000000',
      volume: '8261300.1500000000000000',
      quoteVolume: 13964950.109855045,
      btcVolume: 1544.6562665703477,
      usdVolume: 13964950.109855045,
      time: '2019-06-16T00:00:00.000Z'
      },
      {
      open: '1.6651000000000000',
      high: '2.0776000000000000',
      low: '1.6525000000000000',
      close: '1.9632000000000000',
      volume: '17062882.5500000000000000',
      quoteVolume: 32436293.282424346,
      btcVolume: 3512.1607543888294,
      usdVolume: 32436293.282424346,
      time: '2019-06-17T00:00:00.000Z'
      },
      {
      open: '1.9631000000000000',
      high: '2.0185000000000000',
      low: '1.7600000000000000',
      close: '1.8194000000000000',
      volume: '13903321.3000000000000000',
      quoteVolume: 25882989.567272145,
      btcVolume: 2819.6184477064003,
      usdVolume: 25816336.76297314,
      time: '2019-06-18T00:00:00.000Z'
      },
      {
      open: '1.8158000000000000',
      high: '1.9016000000000000',
      low: '1.7869000000000000',
      close: '1.8353000000000000',
      volume: '4857233.6000000000000000',
      quoteVolume: 8953905.75995399,
      btcVolume: 980.2564405252405,
      usdVolume: 8953905.75995399,
      time: '2019-06-19T00:00:00.000Z'
      },
      {
      open: '1.8352000000000000',
      high: '1.8865000000000000',
      low: '1.6862000000000000',
      close: '1.7656000000000000',
      volume: '5058765.9500000000000000',
      quoteVolume: 8995915.657141935,
      btcVolume: 964.4591378098079,
      usdVolume: 8995915.657141935,
      time: '2019-06-20T00:00:00.000Z'
      },
      {
      open: '1.7671000000000000',
      high: '1.7900000000000000',
      low: '1.6100000000000000',
      close: '1.6883000000000000',
      volume: '6929829.1600000000000000',
      quoteVolume: 11630088.984655974,
      btcVolume: 1192.1055771385234,
      usdVolume: 11630088.984655974,
      time: '2019-06-21T00:00:00.000Z'
      },
      {
      open: '1.6895000000000000',
      high: '1.9470000000000000',
      low: '1.6827000000000000',
      close: '1.8428000000000000',
      volume: '8390022.4600000000000000',
      quoteVolume: 15123936.817318201,
      btcVolume: 1410.6256719829264,
      usdVolume: 15123936.817318201,
      time: '2019-06-22T00:00:00.000Z'
      },
      {
      open: '1.8429000000000000',
      high: '1.9100000000000000',
      low: '1.7702000000000000',
      close: '1.8169000000000000',
      volume: '4593412.3100000000000000',
      quoteVolume: 8418104.00219802,
      btcVolume: 775.4769301470001,
      usdVolume: 8418104.00219802,
      time: '2019-06-23T00:00:00.000Z'
      },
      {
      open: '1.8169000000000000',
      high: '1.8884000000000000',
      low: '1.7900000000000000',
      close: '1.8689000000000000',
      volume: '2384487.8200000000000000',
      quoteVolume: 4400730.711527992,
      btcVolume: 403.4557862186641,
      usdVolume: 4400730.711527992,
      time: '2019-06-24T00:00:00.000Z'
      },
      {
      open: '1.8689000000000000',
      high: '2.4273000000000000',
      low: '1.8613000000000000',
      close: '2.1928000000000000',
      volume: '21998410.4800000000000000',
      quoteVolume: 47761526.31202764,
      btcVolume: 4201.741421999988,
      usdVolume: 47761526.31202764,
      time: '2019-06-25T00:00:00.000Z'
      },
      {
      open: '2.1931000000000000',
      high: '2.5000000000000000',
      low: '2.0231000000000000',
      close: '2.2800000000000000',
      volume: '15800691.2200000000000000',
      quoteVolume: 35017061.17698764,
      btcVolume: 2716.258242985611,
      usdVolume: 35017061.17698764,
      time: '2019-06-26T00:00:00.000Z'
      },
      {
      open: '2.2821000000000000',
      high: '2.5550000000000000',
      low: '2.1123000000000000',
      close: '2.2879000000000000',
      volume: '13867785.5900000000000000',
      quoteVolume: 32591424.308536824,
      btcVolume: 2692.462710930972,
      usdVolume: 32591424.308536824,
      time: '2019-06-27T00:00:00.000Z'
      },
      {
      open: '2.2912000000000000',
      high: '3.0981000000000000',
      low: '2.2150000000000000',
      close: '3.0392000000000000',
      volume: '16190974.7700000000000000',
      quoteVolume: 43067890.31031614,
      btcVolume: 3571.5324626501774,
      usdVolume: 43067890.31031614,
      time: '2019-06-28T00:00:00.000Z'
      },
      {
      open: '3.0391000000000000',
      high: '4.5826000000000000',
      low: '2.9499000000000000',
      close: '3.7125000000000000',
      volume: '55501686.3100000000000000',
      quoteVolume: 212256136.05785748,
      btcVolume: 17792.420778535194,
      usdVolume: 212256136.05785748,
      time: '2019-06-29T00:00:00.000Z'
      },
      {
      open: '3.7051000000000000',
      high: '4.2200000000000000',
      low: '3.2300000000000000',
      close: '3.3869000000000000',
      volume: '25190926.7600000000000000',
      quoteVolume: 97105333.71238178,
      btcVolume: 8374.845437346688,
      usdVolume: 97105333.71238178,
      time: '2019-06-30T00:00:00.000Z'
      },
      {
      open: '3.3884000000000000',
      high: '3.8620000000000000',
      low: '2.9266000000000000',
      close: '3.6369000000000000',
      volume: '25321151.3600000000000000',
      quoteVolume: 85723441.75518183,
      btcVolume: 8111.5203882492,
      usdVolume: 85723441.75518183,
      time: '2019-07-01T00:00:00.000Z'
      },
      {
      open: '3.6476000000000000',
      high: '3.9914000000000000',
      low: '3.2010000000000000',
      close: '3.7453000000000000',
      volume: '26688227.6900000000000000',
      quoteVolume: 97119111.08506477,
      btcVolume: 9423.268210581768,
      usdVolume: 97119111.08506477,
      time: '2019-07-02T00:00:00.000Z'
      },
      {
      open: '3.7441000000000000',
      high: '3.8857000000000000',
      low: '3.4769000000000000',
      close: '3.4900000000000000',
      volume: '11091453.7900000000000000',
      quoteVolume: 40850492.97302097,
      btcVolume: 3600.00749644261,
      usdVolume: 40850492.97302097,
      time: '2019-07-03T00:00:00.000Z'
      },
      {
      open: '3.4854000000000000',
      high: '3.6100000000000000',
      low: '3.2040000000000000',
      close: '3.4530000000000000',
      volume: '9871917.6900000000000000',
      quoteVolume: 33779334.70123714,
      btcVolume: 2886.5694636866006,
      usdVolume: 33779334.70123714,
      time: '2019-07-04T00:00:00.000Z'
      },
      {
      open: '3.4555000000000000',
      high: '3.7500000000000000',
      low: '3.4410000000000000',
      close: '3.6400000000000000',
      volume: '10497021.6500000000000000',
      quoteVolume: 37787417.88724579,
      btcVolume: 3395.0022936467367,
      usdVolume: 37787417.88724579,
      time: '2019-07-05T00:00:00.000Z'
      },
      {
      open: '3.6400000000000000',
      high: '3.6611000000000000',
      low: '3.4384000000000000',
      close: '3.4659000000000000',
      volume: '4978418.4100000000000000',
      quoteVolume: 17630030.877459843,
      btcVolume: 1551.1898933985874,
      usdVolume: 17630030.877459843,
      time: '2019-07-06T00:00:00.000Z'
      },
      {
      open: '3.4650000000000000',
      high: '3.5038000000000000',
      low: '3.2500000000000000',
      close: '3.2750000000000000',
      volume: '5553651.6400000000000000',
      quoteVolume: 18694548.744352836,
      btcVolume: 1655.1485012561434,
      usdVolume: 18694548.744352836,
      time: '2019-07-07T00:00:00.000Z'
      },
      {
      open: '3.2750000000000000',
      high: '3.5731000000000000',
      low: '3.2500000000000000',
      close: '3.3362000000000000',
      volume: '5268983.9800000000000000',
      quoteVolume: 17808772.169726066,
      btcVolume: 1520.284319482537,
      usdVolume: 17808772.169726066,
      time: '2019-07-08T00:00:00.000Z'
      },
      {
      open: '3.3334000000000000',
      high: '3.3741000000000000',
      low: '2.9401000000000000',
      close: '3.0670000000000000',
      volume: '8673443.7700000000000000',
      quoteVolume: 27298953.85568921,
      btcVolume: 2188.425469158119,
      usdVolume: 27298953.85568921,
      time: '2019-07-09T00:00:00.000Z'
      },
      {
      open: '3.0670000000000000',
      high: '3.2568000000000000',
      low: '2.8578000000000000',
      close: '3.1166000000000000',
      volume: '9527421.0200000000000000',
      quoteVolume: 29323317.465814188,
      btcVolume: 2364.92290456046,
      usdVolume: 29323317.465814188,
      time: '2019-07-10T00:00:00.000Z'
      },
      {
      open: '3.1166000000000000',
      high: '3.1555000000000000',
      low: '2.7100000000000000',
      close: '2.7853000000000000',
      volume: '7262068.2500000000000000',
      quoteVolume: 21179286.916986044,
      btcVolume: 1836.1996771189365,
      usdVolume: 21179286.916986044,
      time: '2019-07-11T00:00:00.000Z'
      },
      {
      open: '2.7870000000000000',
      high: '3.3282000000000000',
      low: '2.6000000000000000',
      close: '3.1660000000000000',
      volume: '11731863.1100000000000000',
      quoteVolume: 35185953.587122776,
      btcVolume: 3037.647143684832,
      usdVolume: 35185953.587122776,
      time: '2019-07-12T00:00:00.000Z'
      },
      {
      open: '3.1660000000000000',
      high: '3.2953000000000000',
      low: '3.0400000000000000',
      close: '3.1652000000000000',
      volume: '9065258.2000000000000000',
      quoteVolume: 28811293.98759913,
      btcVolume: 2530.9180949987526,
      usdVolume: 28811293.98759913,
      time: '2019-07-13T00:00:00.000Z'
      },
      {
      open: '3.1689000000000000',
      high: '3.2413000000000000',
      low: '2.7611000000000000',
      close: '2.7856000000000000',
      volume: '8164892.0900000000000000',
      quoteVolume: 24328975.206776988,
      btcVolume: 2259.0976435334483,
      usdVolume: 24328975.206776988,
      time: '2019-07-14T00:00:00.000Z'
      },
      {
      open: '2.7792000000000000',
      high: '2.8498000000000000',
      low: '2.5801000000000000',
      close: '2.6913000000000000',
      volume: '7318353.9000000000000000',
      quoteVolume: 19951566.39118311,
      btcVolume: 1928.7548326502576,
      usdVolume: 19951566.39118311,
      time: '2019-07-15T00:00:00.000Z'
      },
      {
      open: '2.6911000000000000',
      high: '2.7749000000000000',
      low: '2.1521000000000000',
      close: '2.1948000000000000',
      volume: '7934695.5900000000000000',
      quoteVolume: 19458898.407283843,
      btcVolume: 1935.2094152580992,
      usdVolume: 19458898.407283843,
      time: '2019-07-16T00:00:00.000Z'
      },
      {
      open: '2.1947000000000000',
      high: '2.6000000000000000',
      low: '2.0531000000000000',
      close: '2.3980000000000000',
      volume: '13183927.9300000000000000',
      quoteVolume: 30803035.246100836,
      btcVolume: 3208.952057742877,
      usdVolume: 30803035.246100836,
      time: '2019-07-17T00:00:00.000Z'
      },
      {
      open: '2.3951000000000000',
      high: '2.8094000000000000',
      low: '2.3542000000000000',
      close: '2.6700000000000000',
      volume: '13345016.8600000000000000',
      quoteVolume: 34825584.843023226,
      btcVolume: 3500.7237591055887,
      usdVolume: 34764284.05553622,
      time: '2019-07-18T00:00:00.000Z'
      },
      {
      open: '2.6700000000000000',
      high: '2.7490000000000000',
      low: '2.4910000000000000',
      close: '2.6046000000000000',
      volume: '5510181.5700000000000000',
      quoteVolume: 14293523.817000896,
      btcVolume: 1368.4110094593677,
      usdVolume: 14293523.817000896,
      time: '2019-07-19T00:00:00.000Z'
      },
      {
      open: '2.6020000000000000',
      high: '2.7314000000000000',
      low: '2.5751000000000000',
      close: '2.6370000000000000',
      volume: '4596378.0700000000000000',
      quoteVolume: 12202999.48393705,
      btcVolume: 1147.4765675933133,
      usdVolume: 12202999.48393705,
      time: '2019-07-20T00:00:00.000Z'
      },
      {
      open: '2.6369000000000000',
      high: '2.6650000000000000',
      low: '2.4600000000000000',
      close: '2.5319000000000000',
      volume: '3349432.2400000000000000',
      quoteVolume: 8531199.090973042,
      btcVolume: 809.0792883766617,
      usdVolume: 8531199.090973042,
      time: '2019-07-21T00:00:00.000Z'
      },
      {
      open: '2.5319000000000000',
      high: '2.5857000000000000',
      low: '2.3732000000000000',
      close: '2.4078000000000000',
      volume: '3415186.7600000000000000',
      quoteVolume: 8425751.27914594,
      btcVolume: 810.0485394775847,
      usdVolume: 8425751.27914594,
      time: '2019-07-22T00:00:00.000Z'
      },
      {
      open: '2.4073000000000000',
      high: '2.5200000000000000',
      low: '2.2489000000000000',
      close: '2.3535000000000000',
      volume: '5670086.3900000000000000',
      quoteVolume: 13434498.579576982,
      btcVolume: 1334.7270621160683,
      usdVolume: 13434498.579576982,
      time: '2019-07-23T00:00:00.000Z'
      },
      {
      open: '2.3559000000000000',
      high: '2.5131000000000000',
      low: '2.2700000000000000',
      close: '2.3999000000000000',
      volume: '5308279.8100000000000000',
      quoteVolume: 12686743.212995907,
      btcVolume: 1303.671357536717,
      usdVolume: 12686743.212995907,
      time: '2019-07-24T00:00:00.000Z'
      },
      {
      open: '2.3971000000000000',
      high: '2.5149000000000000',
      low: '2.3595000000000000',
      close: '2.3615000000000000',
      volume: '4016580.8300000000000000',
      quoteVolume: 9840046.642877948,
      btcVolume: 982.9918828701473,
      usdVolume: 9840046.642877948,
      time: '2019-07-25T00:00:00.000Z'
      },
      {
      open: '2.3615000000000000',
      high: '2.4300000000000000',
      low: '2.3201000000000000',
      close: '2.3989000000000000',
      volume: '1971791.7400000000000000',
      quoteVolume: 4688945.92508803,
      btcVolume: 480.4188696425642,
      usdVolume: 4688945.92508803,
      time: '2019-07-26T00:00:00.000Z'
      },
      {
      open: '2.3954000000000000',
      high: '2.4600000000000000',
      low: '2.1525000000000000',
      close: '2.2453000000000000',
      volume: '4245834.2700000000000000',
      quoteVolume: 9865099.724368896,
      btcVolume: 1019.8334976316504,
      usdVolume: 9865099.724368896,
      time: '2019-07-27T00:00:00.000Z'
      },
      {
      open: '2.2372000000000000',
      high: '2.2733000000000000',
      low: '2.1000000000000000',
      close: '2.2156000000000000',
      volume: '1902749.3100000000000000',
      quoteVolume: 4200464.91369699,
      btcVolume: 443.62670497298694,
      usdVolume: 4200464.91369699,
      time: '2019-07-28T00:00:00.000Z'
      },
      {
      open: '2.2178000000000000',
      high: '2.2482000000000000',
      low: '2.1100000000000000',
      close: '2.1251000000000000',
      volume: '2215943.5300000000000000',
      quoteVolume: 4805773.902062991,
      btcVolume: 503.4159560790306,
      usdVolume: 4805773.902062991,
      time: '2019-07-29T00:00:00.000Z'
      },
      {
      open: '2.1250000000000000',
      high: '2.1854000000000000',
      low: '1.9694000000000000',
      close: '2.0682000000000000',
      volume: '4210835.6200000000000000',
      quoteVolume: 8720296.91253309,
      btcVolume: 916.5223854094169,
      usdVolume: 8720296.91253309,
      time: '2019-07-30T00:00:00.000Z'
      },
      {
      open: '2.0672000000000000',
      high: '2.2500000000000000',
      low: '2.0589000000000000',
      close: '2.2102000000000000',
      volume: '4558064.6100000000000000',
      quoteVolume: 9920046.062618986,
      btcVolume: 1007.3882816663967,
      usdVolume: 9920046.062618986,
      time: '2019-07-31T00:00:00.000Z'
      },
      {
      open: '2.2169000000000000',
      high: '2.2310000000000000',
      low: '2.1054000000000000',
      close: '2.1610000000000000',
      volume: '2890541.3500000000000000',
      quoteVolume: 6235734.283716941,
      btcVolume: 618.792071520022,
      usdVolume: 6235734.283716941,
      time: '2019-08-01T00:00:00.000Z'
      },
      {
      open: '2.1612000000000000',
      high: '2.4900000000000000',
      low: '2.1275000000000000',
      close: '2.4271000000000000',
      volume: '6319480.9600000000000000',
      quoteVolume: 14629814.693532972,
      btcVolume: 1400.4593413301136,
      usdVolume: 14629814.693532972,
      time: '2019-08-02T00:00:00.000Z'
      },
      {
      open: '2.4269000000000000',
      high: '2.7342000000000000',
      low: '2.3569000000000000',
      close: '2.4211000000000000',
      volume: '11323687.1300000000000000',
      quoteVolume: 29135005.95525144,
      btcVolume: 2701.80633921746,
      usdVolume: 29135005.95525144,
      time: '2019-08-03T00:00:00.000Z'
      },
      {
      open: '2.4186000000000000',
      high: '2.6044000000000000',
      low: '2.3811000000000000',
      close: '2.5099000000000000',
      volume: '6257429.6800000000000000',
      quoteVolume: 15626611.666627988,
      btcVolume: 1446.5228705557931,
      usdVolume: 15626611.666627988,
      time: '2019-08-04T00:00:00.000Z'
      },
      {
      open: '2.5097000000000000',
      high: '2.6495000000000000',
      low: '2.4562000000000000',
      close: '2.4562000000000000',
      volume: '5389254.3200000000000000',
      quoteVolume: 13710085.081441978,
      btcVolume: 1176.551959779662,
      usdVolume: 13710085.081441978,
      time: '2019-08-05T00:00:00.000Z'
      },
      {
      open: '2.4599000000000000',
      high: '2.5181000000000000',
      low: '2.3100000000000000',
      close: '2.4785000000000000',
      volume: '6024056.7500000000000000',
      quoteVolume: 14593117.820943044,
      btcVolume: 1242.3745698550665,
      usdVolume: 14593117.820943044,
      time: '2019-08-06T00:00:00.000Z'
      },
      {
      open: '2.4822000000000000',
      high: '2.5000000000000000',
      low: '2.3502000000000000',
      close: '2.3826000000000000',
      volume: '3376864.0700000000000000',
      quoteVolume: 8167003.771067984,
      btcVolume: 696.2613814905944,
      usdVolume: 8167003.771067984,
      time: '2019-08-07T00:00:00.000Z'
      },
      {
      open: '2.3883000000000000',
      high: '2.3998000000000000',
      low: '2.2713000000000000',
      close: '2.2755000000000000',
      volume: '2863707.1900000000000000',
      quoteVolume: 6666989.981878993,
      btcVolume: 564.8964889382114,
      usdVolume: 6666989.981878993,
      time: '2019-08-08T00:00:00.000Z'
      },
      {
      open: '2.2784000000000000',
      high: '2.2919000000000000',
      low: '2.1510000000000000',
      close: '2.1669000000000000',
      volume: '2368470.3900000000000000',
      quoteVolume: 5268428.711106007,
      btcVolume: 444.782865591571,
      usdVolume: 5268428.711106007,
      time: '2019-08-09T00:00:00.000Z'
      },
      {
      open: '2.1690000000000000',
      high: '2.4091000000000000',
      low: '2.1306000000000000',
      close: '2.3336000000000000',
      volume: '5233238.7500000000000000',
      quoteVolume: 12050390.512594998,
      btcVolume: 1035.7246734427822,
      usdVolume: 12050390.512594998,
      time: '2019-08-10T00:00:00.000Z'
      },
      {
      open: '2.3336000000000000',
      high: '2.4900000000000000',
      low: '2.2901000000000000',
      close: '2.4157000000000000',
      volume: '3701976.9800000000000000',
      quoteVolume: 8866596.82357804,
      btcVolume: 777.1699540463694,
      usdVolume: 8866596.82357804,
      time: '2019-08-11T00:00:00.000Z'
      },
      {
      open: '2.4157000000000000',
      high: '2.4790000000000000',
      low: '2.3350000000000000',
      close: '2.3900000000000000',
      volume: '2579648.7400000000000000',
      quoteVolume: 6209796.363283018,
      btcVolume: 544.0263252504219,
      usdVolume: 6209796.363283018,
      time: '2019-08-12T00:00:00.000Z'
      },
      {
      open: '2.3846000000000000',
      high: '2.4679000000000000',
      low: '2.3060000000000000',
      close: '2.4229000000000000',
      volume: '3150417.3700000000000000',
      quoteVolume: 7506682.66399902,
      btcVolume: 678.7973027516069,
      usdVolume: 7506682.66399902,
      time: '2019-08-13T00:00:00.000Z'
      },
      {
      open: '2.4190000000000000',
      high: '2.6031000000000000',
      low: '2.1630000000000000',
      close: '2.2842000000000000',
      volume: '6699207.3700000000000000',
      quoteVolume: 16231062.046769118,
      btcVolume: 1549.2558632873051,
      usdVolume: 16231062.046769118,
      time: '2019-08-14T00:00:00.000Z'
      },
      {
      open: '2.2840000000000000',
      high: '2.4506000000000000',
      low: '2.2565000000000000',
      close: '2.4232000000000000',
      volume: '2806801.4000000000000000',
      quoteVolume: 6611413.672988072,
      btcVolume: 652.6110331933832,
      usdVolume: 6611413.672988072,
      time: '2019-08-15T00:00:00.000Z'
      },
      {
      open: '2.4225000000000000',
      high: '2.4590000000000000',
      low: '2.2270000000000000',
      close: '2.3000000000000000',
      volume: '3572174.4000000000000000',
      quoteVolume: 8302828.475309981,
      btcVolume: 815.1156611012854,
      usdVolume: 8302828.475309981,
      time: '2019-08-16T00:00:00.000Z'
      },
      {
      open: '2.3046000000000000',
      high: '2.4190000000000000',
      low: '2.2820000000000000',
      close: '2.4036000000000000',
      volume: '2035414.3900000000000000',
      quoteVolume: 4797115.851594993,
      btcVolume: 466.47840144058904,
      usdVolume: 4797115.851594993,
      time: '2019-08-17T00:00:00.000Z'
      },
      {
      open: '2.4050000000000000',
      high: '2.5657000000000000',
      low: '2.3729000000000000',
      close: '2.5051000000000000',
      volume: '3610375.3000000000000000',
      quoteVolume: 8909798.939858058,
      btcVolume: 863.7326634348484,
      usdVolume: 8909798.939858058,
      time: '2019-08-18T00:00:00.000Z'
      },
      {
      open: '2.5055000000000000',
      high: '2.6000000000000000',
      low: '2.4508000000000000',
      close: '2.4958000000000000',
      volume: '4280607.5800000000000000',
      quoteVolume: 10853323.38349604,
      btcVolume: 1017.6371082460578,
      usdVolume: 10853323.38349604,
      time: '2019-08-19T00:00:00.000Z'
      },
      {
      open: '2.4943000000000000',
      high: '2.5401000000000000',
      low: '2.3354000000000000',
      close: '2.3624000000000000',
      volume: '3474086.6000000000000000',
      quoteVolume: 8391667.38601199,
      btcVolume: 780.6798427463843,
      usdVolume: 8391667.38601199,
      time: '2019-08-20T00:00:00.000Z'
      },
      {
      open: '2.3622000000000000',
      high: '2.3710000000000000',
      low: '2.1500000000000000',
      close: '2.2054000000000000',
      volume: '4915776.4000000000000000',
      quoteVolume: 11029113.309874035,
      btcVolume: 1081.9973137236552,
      usdVolume: 11029113.309874035,
      time: '2019-08-21T00:00:00.000Z'
      },
      {
      open: '2.2058000000000000',
      high: '2.2896000000000000',
      low: '2.1350000000000000',
      close: '2.2227000000000000',
      volume: '2980708.5400000000000000',
      quoteVolume: 6618775.966529956,
      btcVolume: 660.0412390163551,
      usdVolume: 6618775.966529956,
      time: '2019-08-22T00:00:00.000Z'
      },
      {
      open: '2.2231000000000000',
      high: '2.2950000000000000',
      low: '2.1723000000000000',
      close: '2.2343000000000000',
      volume: '3274284.1600000000000000',
      quoteVolume: 7324880.720449999,
      btcVolume: 715.1730454658111,
      usdVolume: 7324880.720449999,
      time: '2019-08-23T00:00:00.000Z'
      },
      {
      open: '2.2343000000000000',
      high: '2.2545000000000000',
      low: '2.1537000000000000',
      close: '2.1681000000000000',
      volume: '1629473.9800000000000000',
      quoteVolume: 3574006.0448640008,
      btcVolume: 352.9833991449766,
      usdVolume: 3574006.0448640008,
      time: '2019-08-24T00:00:00.000Z'
      },
      {
      open: '2.1704000000000000',
      high: '2.1804000000000000',
      low: '2.0255000000000000',
      close: '2.0780000000000000',
      volume: '2603393.1400000000000000',
      quoteVolume: 5492656.678655035,
      btcVolume: 545.2530944238928,
      usdVolume: 5492656.678655035,
      time: '2019-08-25T00:00:00.000Z'
      },
      {
      open: '2.0783000000000000',
      high: '2.1546000000000000',
      low: '2.0450000000000000',
      close: '2.0739000000000000',
      volume: '2094166.6100000000000000',
      quoteVolume: 4389987.473204011,
      btcVolume: 423.4809452125515,
      usdVolume: 4389987.473204011,
      time: '2019-08-26T00:00:00.000Z'
      },
      {
      open: '2.0737000000000000',
      high: '2.1788000000000000',
      low: '2.0050000000000000',
      close: '2.1128000000000000',
      volume: '2811534.7300000000000000',
      quoteVolume: 5891385.969969013,
      btcVolume: 579.2737813324595,
      usdVolume: 5891385.969969013,
      time: '2019-08-27T00:00:00.000Z'
      },
      {
      open: '2.1098000000000000',
      high: '2.1134000000000000',
      low: '1.8750000000000000',
      close: '1.8957000000000000',
      volume: '2925102.1700000000000000',
      quoteVolume: 5826671.213469991,
      btcVolume: 585.9165069079237,
      usdVolume: 5826671.213469991,
      time: '2019-08-28T00:00:00.000Z'
      },
      {
      open: '1.8956000000000000',
      high: '1.8956000000000000',
      low: '1.7253000000000000',
      close: '1.7744000000000000',
      volume: '4172574.3900000000000000',
      quoteVolume: 7534972.244916026,
      btcVolume: 791.9607358540669,
      usdVolume: 7534972.244916026,
      time: '2019-08-29T00:00:00.000Z'
      },
      {
      open: '1.7763000000000000',
      high: '1.8913000000000000',
      low: '1.7365000000000000',
      close: '1.7960000000000000',
      volume: '2660522.1000000000000000',
      quoteVolume: 4851727.818894994,
      btcVolume: 508.2563024568634,
      usdVolume: 4851727.818894994,
      time: '2019-08-30T00:00:00.000Z'
      },
      {
      open: '1.7958000000000000',
      high: '1.8535000000000000',
      low: '1.7400000000000000',
      close: '1.7791000000000000',
      volume: '1818973.5000000000000000',
      quoteVolume: 3255608.6478299913,
      btcVolume: 339.9346500517163,
      usdVolume: 3255608.6478299913,
      time: '2019-08-31T00:00:00.000Z'
      },
      {
      open: '1.7788000000000000',
      high: '1.8000000000000000',
      low: '1.7170000000000000',
      close: '1.7686000000000000',
      volume: '1329827.2100000000000000',
      quoteVolume: 2338722.617214002,
      btcVolume: 242.99787843430724,
      usdVolume: 2338722.617214002,
      time: '2019-09-01T00:00:00.000Z'
      },
      {
      open: '1.7696000000000000',
      high: '1.8900000000000000',
      low: '1.7272000000000000',
      close: '1.8287000000000000',
      volume: '3042460.9700000000000000',
      quoteVolume: 5523573.6413650215,
      btcVolume: 553.7959499063911,
      usdVolume: 5523573.6413650215,
      time: '2019-09-02T00:00:00.000Z'
      },
      {
      open: '1.8307000000000000',
      high: '1.9424000000000000',
      low: '1.8147000000000000',
      close: '1.8490000000000000',
      volume: '3198661.0900000000000000',
      quoteVolume: 5978206.5396679975,
      btcVolume: 570.2827065368367,
      usdVolume: 5978206.5396679975,
      time: '2019-09-03T00:00:00.000Z'
      },
      {
      open: '1.8486000000000000',
      high: '1.8700000000000000',
      low: '1.7167000000000000',
      close: '1.7793000000000000',
      volume: '3545459.8000000000000000',
      quoteVolume: 6323373.737698996,
      btcVolume: 599.008568072105,
      usdVolume: 6323373.737698996,
      time: '2019-09-04T00:00:00.000Z'
      },
      {
      open: '1.7784000000000000',
      high: '1.8624000000000000',
      low: '1.7680000000000000',
      close: '1.7921000000000000',
      volume: '2705662.3600000000000000',
      quoteVolume: 4910186.863704015,
      btcVolume: 465.71608792980777,
      usdVolume: 4910186.863704015,
      time: '2019-09-05T00:00:00.000Z'
      },
      {
      open: '1.7919000000000000',
      high: '1.8600000000000000',
      low: '1.6767000000000000',
      close: '1.7226000000000000',
      volume: '3028097.8200000000000000',
      quoteVolume: 5355618.6108840015,
      btcVolume: 506.31943933656817,
      usdVolume: 5355618.6108840015,
      time: '2019-09-06T00:00:00.000Z'
      },
      {
      open: '1.7222000000000000',
      high: '1.8060000000000000',
      low: '1.7085000000000000',
      close: '1.7672000000000000',
      volume: '2401823.2500000000000000',
      quoteVolume: 4215075.491124999,
      btcVolume: 404.7663920454655,
      usdVolume: 4215075.491124999,
      time: '2019-09-07T00:00:00.000Z'
      },
      {
      open: '1.7672000000000000',
      high: '1.9054000000000000',
      low: '1.7610000000000000',
      close: '1.8275000000000000',
      volume: '3500287.6000000000000000',
      quoteVolume: 6422308.280667982,
      btcVolume: 617.3872473153666,
      usdVolume: 6422308.280667982,
      time: '2019-09-08T00:00:00.000Z'
      },
      {
      open: '1.8290000000000000',
      high: '1.8731000000000000',
      low: '1.7610000000000000',
      close: '1.7872000000000000',
      volume: '3473067.9800000000000000',
      quoteVolume: 6305180.84346195,
      btcVolume: 613.2646136601918,
      usdVolume: 6305180.84346195,
      time: '2019-09-09T00:00:00.000Z'
      },
      {
      open: '1.7883000000000000',
      high: '1.8326000000000000',
      low: '1.7730000000000000',
      close: '1.7998000000000000',
      volume: '2711918.0700000000000000',
      quoteVolume: 4888778.085218002,
      btcVolume: 478.77419074309563,
      usdVolume: 4888778.085218002,
      time: '2019-09-10T00:00:00.000Z'
      },
      {
      open: '1.7988000000000000',
      high: '1.8258000000000000',
      low: '1.7136000000000000',
      close: '1.7301000000000000',
      volume: '2214016.1100000000000000',
      quoteVolume: 3890643.726558997,
      btcVolume: 386.83840067774776,
      usdVolume: 3890643.726558997,
      time: '2019-09-11T00:00:00.000Z'
      },
      {
      open: '1.7301000000000000',
      high: '1.7458000000000000',
      low: '1.6918000000000000',
      close: '1.7073000000000000',
      volume: '2228942.3400000000000000',
      quoteVolume: 3823755.5435819686,
      btcVolume: 374.2899559647845,
      usdVolume: 3823755.5435819686,
      time: '2019-09-12T00:00:00.000Z'
      },
      {
      open: '1.7073000000000000',
      high: '1.7099000000000000',
      low: '1.5100000000000000',
      close: '1.5759000000000000',
      volume: '6637630.0700000000000000',
      quoteVolume: 10595656.219862076,
      btcVolume: 1032.4407207665965,
      usdVolume: 10595656.219862076,
      time: '2019-09-13T00:00:00.000Z'
      },
      {
      open: '1.5761000000000000',
      high: '1.6375000000000000',
      low: '1.5239000000000000',
      close: '1.5634000000000000',
      volume: '5678360.6100000000000000',
      quoteVolume: 9008346.159965044,
      btcVolume: 872.4429504635938,
      usdVolume: 9008346.159965044,
      time: '2019-09-14T00:00:00.000Z'
      },
      {
      open: '1.5634000000000000',
      high: '1.6458000000000000',
      low: '1.5600000000000000',
      close: '1.6104000000000000',
      volume: '4049102.7700000000000000',
      quoteVolume: 6482781.439092984,
      btcVolume: 629.5046579926835,
      usdVolume: 6482781.439092984,
      time: '2019-09-15T00:00:00.000Z'
      },
      {
      open: '1.6110000000000000',
      high: '1.6355000000000000',
      low: '1.5450000000000000',
      close: '1.5806000000000000',
      volume: '3545712.0300000000000000',
      quoteVolume: 5618393.775373046,
      btcVolume: 548.8843112989407,
      usdVolume: 5618393.775373046,
      time: '2019-09-16T00:00:00.000Z'
      },
      {
      open: '1.5806000000000000',
      high: '1.6765000000000000',
      low: '1.5532000000000000',
      close: '1.6240000000000000',
      volume: '5390335.8700000000000000',
      quoteVolume: 8708882.286866039,
      btcVolume: 852.1328908855065,
      usdVolume: 8708882.286866039,
      time: '2019-09-17T00:00:00.000Z'
      },
      {
      open: '1.6240000000000000',
      high: '1.8462000000000000',
      low: '1.6240000000000000',
      close: '1.8010000000000000',
      volume: '8267989.9400000000000000',
      quoteVolume: 14346677.761906926,
      btcVolume: 1407.7978038474118,
      usdVolume: 14346677.761906926,
      time: '2019-09-18T00:00:00.000Z'
      },
      {
      open: '1.7994000000000000',
      high: '1.9075000000000000',
      low: '1.7079000000000000',
      close: '1.8744000000000000',
      volume: '8485812.8300000000000000',
      quoteVolume: 15522274.024547847,
      btcVolume: 1554.190947436255,
      usdVolume: 15522274.024547847,
      time: '2019-09-19T00:00:00.000Z'
      },
      {
      open: '1.8743000000000000',
      high: '1.8772000000000000',
      low: '1.7510000000000000',
      close: '1.8245000000000000',
      volume: '3468435.2500000000000000',
      quoteVolume: 6287930.976908962,
      btcVolume: 618.0830858277567,
      usdVolume: 6287930.976908962,
      time: '2019-09-20T00:00:00.000Z'
      },
      {
      open: '1.8215000000000000',
      high: '1.8888000000000000',
      low: '1.7881000000000000',
      close: '1.8855000000000000',
      volume: '2771763.9300000000000000',
      quoteVolume: 5102178.238018988,
      btcVolume: 508.0689834466635,
      usdVolume: 5102178.238018988,
      time: '2019-09-21T00:00:00.000Z'
      },
      {
      open: '1.8848000000000000',
      high: '1.8868000000000000',
      low: '1.7711000000000000',
      close: '1.8157000000000000',
      volume: '2311848.6500000000000000',
      quoteVolume: 4207056.9643990025,
      btcVolume: 422.0307052601196,
      usdVolume: 4207056.9643990025,
      time: '2019-09-22T00:00:00.000Z'
      },
      {
      open: '1.8149000000000000',
      high: '1.8800000000000000',
      low: '1.7799000000000000',
      close: '1.8031000000000000',
      volume: '2667120.7500000000000000',
      quoteVolume: 4892761.515483015,
      btcVolume: 497.2694919497504,
      usdVolume: 4892761.515483015,
      time: '2019-09-23T00:00:00.000Z'
      },
      {
      open: '1.8048000000000000',
      high: '2.0657000000000000',
      low: '1.4612000000000000',
      close: '1.7160000000000000',
      volume: '15096447.2100000000000000',
      quoteVolume: 28169393.552325387,
      btcVolume: 3042.0172749110857,
      usdVolume: 28169393.552325387,
      time: '2019-09-24T00:00:00.000Z'
      },
      {
      open: '1.7135000000000000',
      high: '1.9286000000000000',
      low: '1.6249000000000000',
      close: '1.7443000000000000',
      volume: '8910281.8800000000000000',
      quoteVolume: 15541684.445626967,
      btcVolume: 1835.8125331240349,
      usdVolume: 15541684.445626967,
      time: '2019-09-25T00:00:00.000Z'
      },
      {
      open: '1.7476000000000000',
      high: '1.7584000000000000',
      low: '1.5276000000000000',
      close: '1.6512000000000000',
      volume: '6786874.8600000000000000',
      quoteVolume: 11209636.83260096,
      btcVolume: 1367.9713551044558,
      usdVolume: 11209636.83260096,
      time: '2019-09-26T00:00:00.000Z'
      },
      {
      open: '1.6512000000000000',
      high: '1.6786000000000000',
      low: '1.5573000000000000',
      close: '1.6590000000000000',
      volume: '4333552.0600000000000000',
      quoteVolume: 6991710.784840995,
      btcVolume: 870.5039437524816,
      usdVolume: 6991710.784840995,
      time: '2019-09-27T00:00:00.000Z'
      },
      {
      open: '1.6593000000000000',
      high: '1.7017000000000000',
      low: '1.6284000000000000',
      close: '1.6742000000000000',
      volume: '2326952.1300000000000000',
      quoteVolume: 3880224.7487799893,
      btcVolume: 476.88231743920215,
      usdVolume: 3880224.7487799893,
      time: '2019-09-28T00:00:00.000Z'
      },
      {
      open: '1.6748000000000000',
      high: '1.7492000000000000',
      low: '1.6441000000000000',
      close: '1.7002000000000000',
      volume: '3304534.4900000000000000',
      quoteVolume: 5625241.514617002,
      btcVolume: 697.5333272708833,
      usdVolume: 5625241.514617002,
      time: '2019-09-29T00:00:00.000Z'
      },
      {
      open: '1.7004000000000000',
      high: '1.7690000000000000',
      low: '1.6404000000000000',
      close: '1.7677000000000000',
      volume: '3564898.9100000000000000',
      quoteVolume: 6077384.966384979,
      btcVolume: 753.0627398007749,
      usdVolume: 6077384.966384979,
      time: '2019-09-30T00:00:00.000Z'
      },
      {
      open: '1.7677000000000000',
      high: '1.8820000000000000',
      low: '1.7522000000000000',
      close: '1.8286000000000000',
      volume: '4077978.5200000000000000',
      quoteVolume: 7412545.756683013,
      btcVolume: 888.0318959948171,
      usdVolume: 7412545.756683013,
      time: '2019-10-01T00:00:00.000Z'
      },
      {
      open: '1.8285000000000000',
      high: '2.0233000000000000',
      low: '1.8121000000000000',
      close: '2.0110000000000000',
      volume: '5789143.1000000000000000',
      quoteVolume: 11109499.143447043,
      btcVolume: 1347.6344778608,
      usdVolume: 11109499.143447043,
      time: '2019-10-02T00:00:00.000Z'
      },
      {
      open: '2.0134000000000000',
      high: '2.0338000000000000',
      low: '1.8800000000000000',
      close: '1.9442000000000000',
      volume: '6996841.1000000000000000',
      quoteVolume: 13705258.538260838,
      btcVolume: 1670.6787632836763,
      usdVolume: 13705258.538260838,
      time: '2019-10-03T00:00:00.000Z'
      },
      {
      open: '1.9443000000000000',
      high: '1.9949000000000000',
      low: '1.8880000000000000',
      close: '1.9663000000000000',
      volume: '3419972.1800000000000000',
      quoteVolume: 6612123.5559109915,
      btcVolume: 813.0573588319999,
      usdVolume: 6612123.5559109915,
      time: '2019-10-04T00:00:00.000Z'
      },
      {
      open: '1.9663000000000000',
      high: '1.9775000000000000',
      low: '1.9113000000000000',
      close: '1.9624000000000000',
      volume: '2231607.1100000000000000',
      quoteVolume: 4348757.9730950035,
      btcVolume: 537.2042324566693,
      usdVolume: 4348757.9730950035,
      time: '2019-10-05T00:00:00.000Z'
      },
      {
      open: '1.9624000000000000',
      high: '2.2659000000000000',
      low: '1.9531000000000000',
      close: '2.1669000000000000',
      volume: '9724532.2300000000000000',
      quoteVolume: 20653440.90051679,
      btcVolume: 2600.3765280923726,
      usdVolume: 20653440.90051679,
      time: '2019-10-06T00:00:00.000Z'
      },
      {
      open: '2.1669000000000000',
      high: '2.4899000000000000',
      low: '2.0971000000000000',
      close: '2.3963000000000000',
      volume: '12793025.3500000000000000',
      quoteVolume: 29795334.186469693,
      btcVolume: 3681.5038062533695,
      usdVolume: 29795334.186469693,
      time: '2019-10-07T00:00:00.000Z'
      },
      {
      open: '2.3963000000000000',
      high: '2.6500000000000000',
      low: '2.2680000000000000',
      close: '2.5766000000000000',
      volume: '16095226.0400000000000000',
      quoteVolume: 39937936.664554514,
      btcVolume: 4873.697508425804,
      usdVolume: 39937936.664554514,
      time: '2019-10-08T00:00:00.000Z'
      },
      {
      open: '2.5765000000000000',
      high: '2.9500000000000000',
      low: '2.5010000000000000',
      close: '2.8011000000000000',
      volume: '21716501.4900000000000000',
      quoteVolume: 59715252.16293435,
      btcVolume: 7171.176638549378,
      usdVolume: 59715252.16293435,
      time: '2019-10-09T00:00:00.000Z'
      },
      {
      open: '2.8011000000000000',
      high: '2.8058000000000000',
      low: '2.4650000000000000',
      close: '2.6302000000000000',
      volume: '15341662.5100000000000000',
      quoteVolume: 40233982.30970988,
      btcVolume: 4713.439912937979,
      usdVolume: 40233982.30970988,
      time: '2019-10-10T00:00:00.000Z'
      },
      {
      open: '2.6280000000000000',
      high: '2.7936000000000000',
      low: '2.5619000000000000',
      close: '2.7481000000000000',
      volume: '11370701.0600000000000000',
      quoteVolume: 30661560.51973289,
      btcVolume: 3657.570932964459,
      usdVolume: 30661560.51973289,
      time: '2019-10-11T00:00:00.000Z'
      },
      {
      open: '2.7443000000000000',
      high: '2.7572000000000000',
      low: '2.5519000000000000',
      close: '2.5975000000000000',
      volume: '6172891.7200000000000000',
      quoteVolume: 16310219.63231884,
      btcVolume: 1958.0872004698058,
      usdVolume: 16310219.63231884,
      time: '2019-10-12T00:00:00.000Z'
      },
      {
      open: '2.5975000000000000',
      high: '2.6443000000000000',
      low: '2.4351000000000000',
      close: '2.4860000000000000',
      volume: '5530185.4400000000000000',
      quoteVolume: 14018335.45872208,
      btcVolume: 1683.0569562732167,
      usdVolume: 14018335.45872208,
      time: '2019-10-13T00:00:00.000Z'
      },
      {
      open: '2.4850000000000000',
      high: '2.6741000000000000',
      low: '2.4527000000000000',
      close: '2.5324000000000000',
      volume: '5826032.3200000000000000',
      quoteVolume: 14927603.979734935,
      btcVolume: 1798.6897727563057,
      usdVolume: 14927603.979734935,
      time: '2019-10-14T00:00:00.000Z'
      },
      {
      open: '2.5315000000000000',
      high: '2.5970000000000000',
      low: '2.3320000000000000',
      close: '2.3972000000000000',
      volume: '6184169.7700000000000000',
      quoteVolume: 15237510.027046982,
      btcVolume: 1851.108592609114,
      usdVolume: 15237510.027046982,
      time: '2019-10-15T00:00:00.000Z'
      },
      {
      open: '2.3972000000000000',
      high: '2.4143000000000000',
      low: '2.1820000000000000',
      close: '2.3717000000000000',
      volume: '11839469.0800000000000000',
      quoteVolume: 27151346.82973899,
      btcVolume: 3372.7497076069235,
      usdVolume: 27151346.82973899,
      time: '2019-10-16T00:00:00.000Z'
      },
      {
      open: '2.3736000000000000',
      high: '2.4900000000000000',
      low: '2.2740000000000000',
      close: '2.4124000000000000',
      volume: '10413491.6200000000000000',
      quoteVolume: 24976988.911125984,
      btcVolume: 3108.003201846181,
      usdVolume: 24976988.911125984,
      time: '2019-10-17T00:00:00.000Z'
      },
      {
      open: '2.4190000000000000',
      high: '2.4999000000000000',
      low: '2.3164000000000000',
      close: '2.3546000000000000',
      volume: '7255303.2100000000000000',
      quoteVolume: 17379294.0806661,
      btcVolume: 2186.768617430999,
      usdVolume: 17379294.0806661,
      time: '2019-10-18T00:00:00.000Z'
      },
      {
      open: '2.3521000000000000',
      high: '2.3910000000000000',
      low: '2.2914000000000000',
      close: '2.3521000000000000',
      volume: '4513432.3200000000000000',
      quoteVolume: 10575592.141082024,
      btcVolume: 1328.617937628987,
      usdVolume: 10575592.141082024,
      time: '2019-10-19T00:00:00.000Z'
      },
      {
      open: '2.3513000000000000',
      high: '2.4510000000000000',
      low: '2.2780000000000000',
      close: '2.4444000000000000',
      volume: '4491279.5700000000000000',
      quoteVolume: 10590230.618070923,
      btcVolume: 1316.2113024406156,
      usdVolume: 10590230.618070923,
      time: '2019-10-20T00:00:00.000Z'
      },
      {
      open: '2.4443000000000000',
      high: '2.6900000000000000',
      low: '2.4347000000000000',
      close: '2.6223000000000000',
      volume: '11336763.6800000000000000',
      quoteVolume: 29227623.981732704,
      btcVolume: 3558.2909764104465,
      usdVolume: 29227623.981732704,
      time: '2019-10-21T00:00:00.000Z'
      },
      {
      open: '2.6216000000000000',
      high: '2.8178000000000000',
      low: '2.5619000000000000',
      close: '2.6074000000000000',
      volume: '11193488.7900000000000000',
      quoteVolume: 30334954.89091577,
      btcVolume: 3713.298114804292,
      usdVolume: 30334954.89091577,
      time: '2019-10-22T00:00:00.000Z'
      },
      {
      open: '2.6073000000000000',
      high: '2.6840000000000000',
      low: '2.3717000000000000',
      close: '2.6055000000000000',
      volume: '13350403.7600000000000000',
      quoteVolume: 34058909.05652626,
      btcVolume: 4438.740203459202,
      usdVolume: 34058909.05652626,
      time: '2019-10-23T00:00:00.000Z'
      },
      {
      open: '2.6071000000000000',
      high: '2.7748000000000000',
      low: '2.6020000000000000',
      close: '2.7200000000000000',
      volume: '9744122.3600000000000000',
      quoteVolume: 26188969.916833855,
      btcVolume: 3521.547093016349,
      usdVolume: 26188969.916833855,
      time: '2019-10-24T00:00:00.000Z'
      },
      {
      open: '2.7201000000000000',
      high: '3.0000000000000000',
      low: '2.6165000000000000',
      close: '2.8360000000000000',
      volume: '12714070.6800000000000000',
      quoteVolume: 35489759.83948473,
      btcVolume: 4402.325745022558,
      usdVolume: 35489759.83948473,
      time: '2019-10-25T00:00:00.000Z'
      },
      {
      open: '2.8370000000000000',
      high: '2.9977000000000000',
      low: '2.6640000000000000',
      close: '2.7348000000000000',
      volume: '12993334.8100000000000000',
      quoteVolume: 36586743.2039599,
      btcVolume: 3897.7642510202168,
      usdVolume: 36586743.2039599,
      time: '2019-10-26T00:00:00.000Z'
      },
      {
      open: '2.7323000000000000',
      high: '2.7620000000000000',
      low: '2.6511000000000000',
      close: '2.6646000000000000',
      volume: '5966423.4400000000000000',
      quoteVolume: 16086672.751381027,
      btcVolume: 1713.5391674385778,
      usdVolume: 16086672.751381027,
      time: '2019-10-27T00:00:00.000Z'
      },
      {
      open: '2.6707000000000000',
      high: '2.8859000000000000',
      low: '2.6600000000000000',
      close: '2.6611000000000000',
      volume: '7822708.6800000000000000',
      quoteVolume: 21672797.56783017,
      btcVolume: 2284.2347637633156,
      usdVolume: 21672797.56783017,
      time: '2019-10-28T00:00:00.000Z'
      },
      {
      open: '2.6601000000000000',
      high: '2.7690000000000000',
      low: '2.6321000000000000',
      close: '2.6620000000000000',
      volume: '3504202.7100000000000000',
      quoteVolume: 9449017.04016303,
      btcVolume: 1008.1912647024507,
      usdVolume: 9449017.04016303,
      time: '2019-10-29T00:00:00.000Z'
      },
      {
      open: '2.6610000000000000',
      high: '2.6768000000000000',
      low: '2.5107000000000000',
      close: '2.6093000000000000',
      volume: '3975481.9600000000000000',
      quoteVolume: 10291062.332419943,
      btcVolume: 1120.2450669570424,
      usdVolume: 10291062.332419943,
      time: '2019-10-30T00:00:00.000Z'
      },
      {
      open: '2.6044000000000000',
      high: '2.7573000000000000',
      low: '2.5365000000000000',
      close: '2.7219000000000000',
      volume: '4135810.6300000000000000',
      quoteVolume: 10988316.188437952,
      btcVolume: 1197.8135631055834,
      usdVolume: 10988316.188437952,
      time: '2019-10-31T00:00:00.000Z'
      },
      {
      open: '2.7233000000000000',
      high: '2.7952000000000000',
      low: '2.6428000000000000',
      close: '2.7091000000000000',
      volume: '3786813.4200000000000000',
      quoteVolume: 10249112.15861604,
      btcVolume: 1122.302010791027,
      usdVolume: 10249112.15861604,
      time: '2019-11-01T00:00:00.000Z'
      },
      {
      open: '2.7092000000000000',
      high: '2.7399000000000000',
      low: '2.6828000000000000',
      close: '2.6973000000000000',
      volume: '1945361.3400000000000000',
      quoteVolume: 5267072.043627042,
      btcVolume: 568.6068601325862,
      usdVolume: 5267072.043627042,
      time: '2019-11-02T00:00:00.000Z'
      },
      {
      open: '2.7015000000000000',
      high: '2.7104000000000000',
      low: '2.6202000000000000',
      close: '2.6605000000000000',
      volume: '1676684.8800000000000000',
      quoteVolume: 4468927.620704968,
      btcVolume: 485.58249494067894,
      usdVolume: 4468927.620704968,
      time: '2019-11-03T00:00:00.000Z'
      },
      {
      open: '2.6594000000000000',
      high: '2.7300000000000000',
      low: '2.6267000000000000',
      close: '2.6746000000000000',
      volume: '2308453.4600000000000000',
      quoteVolume: 6191639.232816044,
      btcVolume: 667.6063736733863,
      usdVolume: 6191639.232816044,
      time: '2019-11-04T00:00:00.000Z'
      },
      {
      open: '2.6746000000000000',
      high: '2.6947000000000000',
      low: '2.6322000000000000',
      close: '2.6746000000000000',
      volume: '1985221.7300000000000000',
      quoteVolume: 5293153.906610983,
      btcVolume: 566.6809866952036,
      usdVolume: 5293153.906610983,
      time: '2019-11-05T00:00:00.000Z'
      },
      {
      open: '2.6743000000000000',
      high: '2.8200000000000000',
      low: '2.6479000000000000',
      close: '2.7448000000000000',
      volume: '3174248.6300000000000000',
      quoteVolume: 8633537.382419948,
      btcVolume: 926.8771591924037,
      usdVolume: 8633537.382419948,
      time: '2019-11-06T00:00:00.000Z'
      },
      {
      open: '2.7446000000000000',
      high: '2.7549000000000000',
      low: '2.6022000000000000',
      close: '2.6757000000000000',
      volume: '3498719.3300000000000000',
      quoteVolume: 9378528.926173998,
      btcVolume: 1015.9056048703025,
      usdVolume: 9378528.926173998,
      time: '2019-11-07T00:00:00.000Z'
      },
      {
      open: '2.6758000000000000',
      high: '2.7333000000000000',
      low: '2.6210000000000000',
      close: '2.7100000000000000',
      volume: '2921454.2700000000000000',
      quoteVolume: 7841818.426597041,
      btcVolume: 875.5145773375328,
      usdVolume: 7841818.426597041,
      time: '2019-11-08T00:00:00.000Z'
      },
      {
      open: '2.7091000000000000',
      high: '2.8497000000000000',
      low: '2.7031000000000000',
      close: '2.8009000000000000',
      volume: '4656993.4400000000000000',
      quoteVolume: 12964192.966649953,
      btcVolume: 1471.6148137529362,
      usdVolume: 12964192.966649953,
      time: '2019-11-09T00:00:00.000Z'
      },
      {
      open: '2.8008000000000000',
      high: '2.8370000000000000',
      low: '2.7362000000000000',
      close: '2.7770000000000000',
      volume: '2591145.5900000000000000',
      quoteVolume: 7226235.751716039,
      btcVolume: 811.2847092382342,
      usdVolume: 7226235.751716039,
      time: '2019-11-10T00:00:00.000Z'
      },
      {
      open: '2.7773000000000000',
      high: '2.7777000000000000',
      low: '2.6655000000000000',
      close: '2.7440000000000000',
      volume: '2140491.0400000000000000',
      quoteVolume: 5824727.782756004,
      btcVolume: 663.0973900459279,
      usdVolume: 5824727.782756004,
      time: '2019-11-11T00:00:00.000Z'
      },
      {
      open: '2.7438000000000000',
      high: '2.8733000000000000',
      low: '2.7311000000000000',
      close: '2.8152000000000000',
      volume: '3207309.4900000000000000',
      quoteVolume: 8980218.046382047,
      btcVolume: 1028.891814520804,
      usdVolume: 8980218.046382047,
      time: '2019-11-12T00:00:00.000Z'
      },
      {
      open: '2.8177000000000000',
      high: '3.0894000000000000',
      low: '2.7851000000000000',
      close: '3.0200000000000000',
      volume: '6541016.7600000000000000',
      quoteVolume: 19407023.30134102,
      btcVolume: 2214.351922566352,
      usdVolume: 19407023.30134102,
      time: '2019-11-13T00:00:00.000Z'
      },
      {
      open: '3.0189000000000000',
      high: '3.1600000000000000',
      low: '2.9666000000000000',
      close: '3.0391000000000000',
      volume: '6593839.9000000000000000',
      quoteVolume: 20356219.364202138,
      btcVolume: 2349.5884763957965,
      usdVolume: 20356219.364202138,
      time: '2019-11-14T00:00:00.000Z'
      },
      {
      open: '3.0390000000000000',
      high: '3.0700000000000000',
      low: '2.7970000000000000',
      close: '3.0389000000000000',
      volume: '5616867.4900000000000000',
      quoteVolume: 16587870.154289966,
      btcVolume: 1939.8783909842598,
      usdVolume: 16587870.154289966,
      time: '2019-11-15T00:00:00.000Z'
      },
      {
      open: '3.0372000000000000',
      high: '3.0435000000000000',
      low: '2.9115000000000000',
      close: '2.9894000000000000',
      volume: '1973143.8200000000000000',
      quoteVolume: 5875410.748616997,
      btcVolume: 693.1568795868579,
      usdVolume: 5875410.748616997,
      time: '2019-11-16T00:00:00.000Z'
      },
      {
      open: '2.9893000000000000',
      high: '2.9894000000000000',
      low: '2.8644000000000000',
      close: '2.9100000000000000',
      volume: '1638152.2100000000000000',
      quoteVolume: 4776273.310892999,
      btcVolume: 560.9160031958835,
      usdVolume: 4776273.310892999,
      time: '2019-11-17T00:00:00.000Z'
      },
      {
      open: '2.9100000000000000',
      high: '2.9610000000000000',
      low: '2.6977000000000000',
      close: '2.7885000000000000',
      volume: '3479282.4300000000000000',
      quoteVolume: 9852607.662228048,
      btcVolume: 1181.9947586571366,
      usdVolume: 9852607.662228048,
      time: '2019-11-18T00:00:00.000Z'
      },
      {
      open: '2.7886000000000000',
      high: '2.8069000000000000',
      low: '2.5800000000000000',
      close: '2.7255000000000000',
      volume: '4538639.6800000000000000',
      quoteVolume: 12210133.545297062,
      btcVolume: 1503.1001831593057,
      usdVolume: 12210133.545297062,
      time: '2019-11-19T00:00:00.000Z'
      },
      {
      open: '2.7279000000000000',
      high: '2.7957000000000000',
      low: '2.6300000000000000',
      close: '2.6982000000000000',
      volume: '3588766.3500000000000000',
      quoteVolume: 9788974.060772017,
      btcVolume: 1207.4464054339237,
      usdVolume: 9788974.060772017,
      time: '2019-11-20T00:00:00.000Z'
      },
      {
      open: '2.6983000000000000',
      high: '2.7153000000000000',
      low: '2.4710000000000000',
      close: '2.5456000000000000',
      volume: '4528968.6200000000000000',
      quoteVolume: 11725649.253482027,
      btcVolume: 1506.7638418172246,
      usdVolume: 11725649.253482027,
      time: '2019-11-21T00:00:00.000Z'
      },
      {
      open: '2.5456000000000000',
      high: '2.5952000000000000',
      low: '2.1000000000000000',
      close: '2.3466000000000000',
      volume: '10778947.2500000000000000',
      quoteVolume: 25122066.728304863,
      btcVolume: 3458.21602950047,
      usdVolume: 25122066.728304863,
      time: '2019-11-22T00:00:00.000Z'
      },
      {
      open: '2.3434000000000000',
      high: '2.4621000000000000',
      low: '2.2604000000000000',
      close: '2.4266000000000000',
      volume: '4841617.1200000000000000',
      quoteVolume: 11394019.118919024,
      btcVolume: 1578.269819528385,
      usdVolume: 11394019.118919024,
      time: '2019-11-23T00:00:00.000Z'
      },
      {
      open: '2.4272000000000000',
      high: '2.4533000000000000',
      low: '2.2000000000000000',
      close: '2.2216000000000000',
      volume: '4491218.3300000000000000',
      quoteVolume: 10364009.312878031,
      btcVolume: 1456.1232933078102,
      usdVolume: 10364009.312878031,
      time: '2019-11-24T00:00:00.000Z'
      },
      {
      open: '2.2218000000000000',
      high: '2.3976000000000000',
      low: '2.0200000000000000',
      close: '2.2523000000000000',
      volume: '8151200.1200000000000000',
      quoteVolume: 18025202.51352003,
      btcVolume: 2602.121395130407,
      usdVolume: 18025202.51352003,
      time: '2019-11-25T00:00:00.000Z'
      },
      {
      open: '2.2554000000000000',
      high: '2.3578000000000000',
      low: '2.1459000000000000',
      close: '2.2424000000000000',
      volume: '7541215.9200000000000000',
      quoteVolume: 16895075.120843165,
      btcVolume: 2370.226442420348,
      usdVolume: 16895075.120843165,
      time: '2019-11-26T00:00:00.000Z'
      },
      {
      open: '2.2437000000000000',
      high: '2.2882000000000000',
      low: '2.1002000000000000',
      close: '2.2242000000000000',
      volume: '7867101.6900000000000000',
      quoteVolume: 17458921.123055957,
      btcVolume: 2407.2933436753665,
      usdVolume: 17458921.123055957,
      time: '2019-11-27T00:00:00.000Z'
      },
      {
      open: '2.2216000000000000',
      high: '2.3248000000000000',
      low: '2.1711000000000000',
      close: '2.2702000000000000',
      volume: '4770270.0100000000000000',
      quoteVolume: 10676600.041403953,
      btcVolume: 1420.5539937358105,
      usdVolume: 10676600.041403953,
      time: '2019-11-28T00:00:00.000Z'
      },
      {
      open: '2.2716000000000000',
      high: '2.3763000000000000',
      low: '2.2611000000000000',
      close: '2.3403000000000000',
      volume: '4132886.9400000000000000',
      quoteVolume: 9586717.671007054,
      btcVolume: 1262.8765759121409,
      usdVolume: 9586717.671007054,
      time: '2019-11-29T00:00:00.000Z'
      },
      {
      open: '2.3403000000000000',
      high: '2.3437000000000000',
      low: '2.1943000000000000',
      close: '2.2373000000000000',
      volume: '2920487.5800000000000000',
      quoteVolume: 6606597.773422014,
      btcVolume: 864.7793427026156,
      usdVolume: 6606597.773422014,
      time: '2019-11-30T00:00:00.000Z'
      },
      {
      open: '2.2360000000000000',
      high: '2.2385000000000000',
      low: '2.1274000000000000',
      close: '2.1638000000000000',
      volume: '3517483.4300000000000000',
      quoteVolume: 7624553.0653519705,
      btcVolume: 1036.6018203822432,
      usdVolume: 7624553.0653519705,
      time: '2019-12-01T00:00:00.000Z'
      },
      {
      open: '2.1634000000000000',
      high: '2.1660000000000000',
      low: '2.0524000000000000',
      close: '2.0911000000000000',
      volume: '3648117.9700000000000000',
      quoteVolume: 7673977.60459799,
      btcVolume: 1052.6747095580927,
      usdVolume: 7673977.60459799,
      time: '2019-12-02T00:00:00.000Z'
      },
      {
      open: '2.0913000000000000',
      high: '2.1891000000000000',
      low: '2.0580000000000000',
      close: '2.1222000000000000',
      volume: '3779094.5300000000000000',
      quoteVolume: 8035102.426474956,
      btcVolume: 1099.7458034935873,
      usdVolume: 8035102.426474956,
      time: '2019-12-03T00:00:00.000Z'
      },
      {
      open: '2.1222000000000000',
      high: '2.1551000000000000',
      low: '2.0000000000000000',
      close: '2.0290000000000000',
      volume: '6418298.4400000000000000',
      quoteVolume: 13241702.282410966,
      btcVolume: 1821.4389081573547,
      usdVolume: 13241702.282410966,
      time: '2019-12-04T00:00:00.000Z'
      },
      {
      open: '2.0282000000000000',
      high: '2.0740000000000000',
      low: '1.9583000000000000',
      close: '2.0068000000000000',
      volume: '5364919.9900000000000000',
      quoteVolume: 10777462.949475031,
      btcVolume: 1473.092445555551,
      usdVolume: 10777462.949475031,
      time: '2019-12-05T00:00:00.000Z'
      },
      {
      open: '2.0059000000000000',
      high: '2.1042000000000000',
      low: '1.9685000000000000',
      close: '2.0943000000000000',
      volume: '3855061.0400000000000000',
      quoteVolume: 7874379.4179719705,
      btcVolume: 1063.7224341290423,
      usdVolume: 7874379.4179719705,
      time: '2019-12-06T00:00:00.000Z'
      },
      {
      open: '2.0918000000000000',
      high: '2.1114000000000000',
      low: '2.0346000000000000',
      close: '2.0440000000000000',
      volume: '2405401.8200000000000000',
      quoteVolume: 4979102.437877952,
      btcVolume: 661.935259884129,
      usdVolume: 4979102.437877952,
      time: '2019-12-07T00:00:00.000Z'
      },
      {
      open: '2.0402000000000000',
      high: '2.0850000000000000',
      low: '2.0012000000000000',
      close: '2.0528000000000000',
      volume: '2017582.9900000000000000',
      quoteVolume: 4126872.5763140195,
      btcVolume: 551.4016518898085,
      usdVolume: 4126872.5763140195,
      time: '2019-12-08T00:00:00.000Z'
      },
      {
      open: '2.0520000000000000',
      high: '2.0985000000000000',
      low: '2.0151000000000000',
      close: '2.0984000000000000',
      volume: '2749805.9600000000000000',
      quoteVolume: 5635545.581415003,
      btcVolume: 756.3154499408689,
      usdVolume: 5635545.581415003,
      time: '2019-12-09T00:00:00.000Z'
      },
      {
      open: '2.0983000000000000',
      high: '2.2862000000000000',
      low: '2.0611000000000000',
      close: '2.2657000000000000',
      volume: '10810913.6300000000000000',
      quoteVolume: 23748657.368107967,
      btcVolume: 3247.6560107514047,
      usdVolume: 23748657.368107967,
      time: '2019-12-10T00:00:00.000Z'
      },
      {
      open: '2.2655000000000000',
      high: '2.3042000000000000',
      low: '2.1470000000000000',
      close: '2.1786000000000000',
      volume: '4814219.6800000000000000',
      quoteVolume: 10700085.694164913,
      btcVolume: 1482.272457471991,
      usdVolume: 10700085.694164913,
      time: '2019-12-11T00:00:00.000Z'
      },
      {
      open: '2.1784000000000000',
      high: '2.1951000000000000',
      low: '2.0197000000000000',
      close: '2.1056000000000000',
      volume: '4784990.2800000000000000',
      quoteVolume: 10011804.878543014,
      btcVolume: 1397.2028682925654,
      usdVolume: 10011804.878543014,
      time: '2019-12-12T00:00:00.000Z'
      },
      {
      open: '2.1037000000000000',
      high: '2.1210000000000000',
      low: '2.0575000000000000',
      close: '2.0709000000000000',
      volume: '1938856.4000000000000000',
      quoteVolume: 4050597.004999004,
      btcVolume: 560.2010274614569,
      usdVolume: 4050597.004999004,
      time: '2019-12-13T00:00:00.000Z'
      },
      {
      open: '2.0713000000000000',
      high: '2.0933000000000000',
      low: '1.9700000000000000',
      close: '1.9946000000000000',
      volume: '1750170.3500000000000000',
      quoteVolume: 3553231.517961994,
      btcVolume: 497.52783996859756,
      usdVolume: 3553231.517961994,
      time: '2019-12-14T00:00:00.000Z'
      },
      {
      open: '1.9947000000000000',
      high: '2.1168000000000000',
      low: '1.9606000000000000',
      close: '2.0708000000000000',
      volume: '2572245.4900000000000000',
      quoteVolume: 5290463.029340993,
      btcVolume: 744.7645762589991,
      usdVolume: 5290463.029340993,
      time: '2019-12-15T00:00:00.000Z'
      },
      {
      open: '2.0722000000000000',
      high: '2.0873000000000000',
      low: '1.9670000000000000',
      close: '1.9751000000000000',
      volume: '2426967.3300000000000000',
      quoteVolume: 4880535.555653993,
      btcVolume: 697.783270194055,
      usdVolume: 4880535.555653993,
      time: '2019-12-16T00:00:00.000Z'
      },
      {
      open: '1.9705000000000000',
      high: '2.0025000000000000',
      low: '1.7000000000000000',
      close: '1.7598000000000000',
      volume: '7314955.8200000000000000',
      quoteVolume: 13273928.880977929,
      btcVolume: 1967.0240939966445,
      usdVolume: 13273928.880977929,
      time: '2019-12-17T00:00:00.000Z'
      },
      {
      open: '1.7594000000000000',
      high: '1.8995000000000000',
      low: '1.5996000000000000',
      close: '1.8703000000000000',
      volume: '8586632.6500000000000000',
      quoteVolume: 15238050.812313028,
      btcVolume: 2248.4745148935344,
      usdVolume: 15238050.812313028,
      time: '2019-12-18T00:00:00.000Z'
      },
      {
      open: '1.8703000000000000',
      high: '1.8901000000000000',
      low: '1.7490000000000000',
      close: '1.8050000000000000',
      volume: '5044150.8900000000000000',
      quoteVolume: 9131480.463140937,
      btcVolume: 1276.1833159780792,
      usdVolume: 9131480.463140937,
      time: '2019-12-19T00:00:00.000Z'
      },
      {
      open: '1.8050000000000000',
      high: '1.9238000000000000',
      low: '1.7849000000000000',
      close: '1.9182000000000000',
      volume: '3285479.3800000000000000',
      quoteVolume: 6091824.338370992,
      btcVolume: 851.739498901493,
      usdVolume: 6091824.338370992,
      time: '2019-12-20T00:00:00.000Z'
      },
      {
      open: '1.9182000000000000',
      high: '1.9204000000000000',
      low: '1.8544000000000000',
      close: '1.8575000000000000',
      volume: '2246207.9200000000000000',
      quoteVolume: 4254205.469336003,
      btcVolume: 595.372491489374,
      usdVolume: 4254205.469336003,
      time: '2019-12-21T00:00:00.000Z'
      },
      {
      open: '1.8563000000000000',
      high: '1.9400000000000000',
      low: '1.8530000000000000',
      close: '1.9181000000000000',
      volume: '2765867.8000000000000000',
      quoteVolume: 5280663.966873023,
      btcVolume: 724.5881359505457,
      usdVolume: 5280663.966873023,
      time: '2019-12-22T00:00:00.000Z'
      },
      {
      open: '1.9167000000000000',
      high: '1.9532000000000000',
      low: '1.8460000000000000',
      close: '1.8629000000000000',
      volume: '2832932.7600000000000000',
      quoteVolume: 5427329.285269981,
      btcVolume: 724.3302026053892,
      usdVolume: 5427329.285269981,
      time: '2019-12-23T00:00:00.000Z'
      },
      {
      open: '1.8638000000000000',
      high: '1.8854000000000000',
      low: '1.8154000000000000',
      close: '1.8318000000000000',
      volume: '2276138.1100000000000000',
      quoteVolume: 4206713.595653003,
      btcVolume: 575.7089237245987,
      usdVolume: 4206713.595653003,
      time: '2019-12-24T00:00:00.000Z'
      },
      {
      open: '1.8434000000000000',
      high: '1.8462000000000000',
      low: '1.7600000000000000',
      close: '1.7826000000000000',
      volume: '1576134.1400000000000000',
      quoteVolume: 2842886.6440100074,
      btcVolume: 394.49536602127625,
      usdVolume: 2842886.6440100074,
      time: '2019-12-25T00:00:00.000Z'
      },
      {
      open: '1.7836000000000000',
      high: '1.9604000000000000',
      low: '1.7702000000000000',
      close: '1.9460000000000000',
      volume: '2645538.2200000000000000',
      quoteVolume: 4914305.251431999,
      btcVolume: 675.865579877377,
      usdVolume: 4914305.251431999,
      time: '2019-12-26T00:00:00.000Z'
      },
      {
      open: '1.8538000000000000',
      high: '1.9299000000000000',
      low: '1.8435000000000000',
      close: '1.8751000000000000',
      volume: '2413976.6800000000000000',
      quoteVolume: 4547744.520783,
      btcVolume: 630.9067581741584,
      usdVolume: 4547744.520783,
      time: '2019-12-27T00:00:00.000Z'
      },
      {
      open: '1.8777000000000000',
      high: '1.8994000000000000',
      low: '1.8555000000000000',
      close: '1.8746000000000000',
      volume: '1269364.7000000000000000',
      quoteVolume: 2384012.458777003,
      btcVolume: 326.02528374878295,
      usdVolume: 2384012.458777003,
      time: '2019-12-28T00:00:00.000Z'
      },
      {
      open: '1.8716000000000000',
      high: '1.9200000000000000',
      low: '1.8444000000000000',
      close: '1.8976000000000000',
      volume: '1411381.0500000000000000',
      quoteVolume: 2639536.610925001,
      btcVolume: 357.41364249988766,
      usdVolume: 2639536.610925001,
      time: '2019-12-29T00:00:00.000Z'
      },
      {
      open: '1.8980000000000000',
      high: '1.8980000000000000',
      low: '1.8072000000000000',
      close: '1.8243000000000000',
      volume: '1099037.9900000000000000',
      quoteVolume: 2036388.7939540006,
      btcVolume: 278.66897959056115,
      usdVolume: 2036388.7939540006,
      time: '2019-12-30T00:00:00.000Z'
      },
      {
      open: '1.8215000000000000',
      high: '1.8292000000000000',
      low: '1.7540000000000000',
      close: '1.7673000000000000',
      volume: '1637635.2800000000000000',
      quoteVolume: 2932476.277038998,
      btcVolume: 406.1371342093213,
      usdVolume: 2932476.277038998,
      time: '2019-12-31T00:00:00.000Z'
      },
      {
      open: '1.7678000000000000',
      high: '1.8548000000000000',
      low: '1.7606000000000000',
      close: '1.8111000000000000',
      volume: '1158740.3200000000000000',
      quoteVolume: 2106240.113113006,
      btcVolume: 291.7435681061432,
      usdVolume: 2106240.113113006,
      time: '2020-01-01T00:00:00.000Z'
      },
      {
      open: '1.8109000000000000',
      high: '1.8164000000000000',
      low: '1.7065000000000000',
      close: '1.7366000000000000',
      volume: '1346757.5400000000000000',
      quoteVolume: 2370258.0493380134,
      btcVolume: 336.50922976459,
      usdVolume: 2370258.0493380134,
      time: '2020-01-02T00:00:00.000Z'
      },
      {
      open: '1.7366000000000000',
      high: '1.8292000000000000',
      low: '1.7013000000000000',
      close: '1.8040000000000000',
      volume: '1782059.3300000000000000',
      quoteVolume: 3173528.806004989,
      btcVolume: 440.5557916213236,
      usdVolume: 3173528.806004989,
      time: '2020-01-03T00:00:00.000Z'
      },
      {
      open: '1.8030000000000000',
      high: '1.8446000000000000',
      low: '1.7840000000000000',
      close: '1.8242000000000000',
      volume: '1241322.8100000000000000',
      quoteVolume: 2252857.6365430057,
      btcVolume: 307.3651298252609,
      usdVolume: 2252857.6365430057,
      time: '2020-01-04T00:00:00.000Z'
      },
      {
      open: '1.8243000000000000',
      high: '1.8413000000000000',
      low: '1.7850000000000000',
      close: '1.7957000000000000',
      volume: '1524603.4000000000000000',
      quoteVolume: 2771904.605593008,
      btcVolume: 372.70606476580207,
      usdVolume: 2771904.605593008,
      time: '2020-01-05T00:00:00.000Z'
      },
      {
      open: '1.7954000000000000',
      high: '1.9386000000000000',
      low: '1.7925000000000000',
      close: '1.9374000000000000',
      volume: '3301230.5100000000000000',
      quoteVolume: 6219983.016896969,
      btcVolume: 822.3795323204727,
      usdVolume: 6219983.016896969,
      time: '2020-01-06T00:00:00.000Z'
      },
      {
      open: '1.9373000000000000',
      high: '2.1398000000000000',
      low: '1.8993000000000000',
      close: '2.1033000000000000',
      volume: '6873503.3900000000000000',
      quoteVolume: 14001167.440691043,
      btcVolume: 1765.0859844694687,
      usdVolume: 14001167.440691043,
      time: '2020-01-07T00:00:00.000Z'
      },
      {
      open: '2.1006000000000000',
      high: '2.3495000000000000',
      low: '2.1006000000000000',
      close: '2.1910000000000000',
      volume: '10645081.3000000000000000',
      quoteVolume: 23671432.343011014,
      btcVolume: 2869.5091074200345,
      usdVolume: 23671432.343011014,
      time: '2020-01-08T00:00:00.000Z'
      },
      {
      open: '2.1842000000000000',
      high: '2.2344000000000000',
      low: '2.0977000000000000',
      close: '2.1886000000000000',
      volume: '4880978.6700000000000000',
      quoteVolume: 10564559.54597592,
      btcVolume: 1336.7758650978221,
      usdVolume: 10564559.54597592,
      time: '2020-01-09T00:00:00.000Z'
      },
      {
      open: '2.1867000000000000',
      high: '2.2489000000000000',
      low: '2.1455000000000000',
      close: '2.2257000000000000',
      volume: '4235446.6200000000000000',
      quoteVolume: 9348313.457428945,
      btcVolume: 1187.4445809136419,
      usdVolume: 9348313.457428945,
      time: '2020-01-10T00:00:00.000Z'
      },
      {
      open: '2.2255000000000000',
      high: '2.3450000000000000',
      low: '2.2180000000000000',
      close: '2.2734000000000000',
      volume: '4346405.0600000000000000',
      quoteVolume: 9936058.411543818,
      btcVolume: 1222.4125100446236,
      usdVolume: 9936058.411543818,
      time: '2020-01-11T00:00:00.000Z'
      },
      {
      open: '2.2730000000000000',
      high: '2.3212000000000000',
      low: '2.2120000000000000',
      close: '2.2383000000000000',
      volume: '2499405.7900000000000000',
      quoteVolume: 5647655.962262982,
      btcVolume: 695.833466287728,
      usdVolume: 5647655.962262982,
      time: '2020-01-12T00:00:00.000Z'
      },
      {
      open: '2.2382000000000000',
      high: '2.2382000000000000',
      low: '2.1561000000000000',
      close: '2.1901000000000000',
      volume: '1952305.6900000000000000',
      quoteVolume: 4261648.208497995,
      btcVolume: 525.0222154958136,
      usdVolume: 4261648.208497995,
      time: '2020-01-13T00:00:00.000Z'
      },
      {
      open: '2.1904000000000000',
      high: '2.4701000000000000',
      low: '2.1792000000000000',
      close: '2.3751000000000000',
      volume: '7058803.5000000000000000',
      quoteVolume: 16487477.721062977,
      btcVolume: 1910.6120911212884,
      usdVolume: 16487477.721062977,
      time: '2020-01-14T00:00:00.000Z'
      },
      {
      open: '2.3751000000000000',
      high: '2.5604000000000000',
      low: '2.3332000000000000',
      close: '2.3953000000000000',
      volume: '6388121.6800000000000000',
      quoteVolume: 15661576.690633962,
      btcVolume: 1788.326833421823,
      usdVolume: 15661576.690633962,
      time: '2020-01-15T00:00:00.000Z'
      },
      {
      open: '2.3991000000000000',
      high: '2.5100000000000000',
      low: '2.2932000000000000',
      close: '2.4925000000000000',
      volume: '4937240.5300000000000000',
      quoteVolume: 11942842.296321075,
      btcVolume: 1374.9026425662325,
      usdVolume: 11942842.296321075,
      time: '2020-01-16T00:00:00.000Z'
      },
      {
      open: '2.4924000000000000',
      high: '2.8600000000000000',
      low: '2.4589000000000000',
      close: '2.7006000000000000',
      volume: '12107831.2900000000000000',
      quoteVolume: 32609325.00067248,
      btcVolume: 3674.588483290462,
      usdVolume: 32609325.00067248,
      time: '2020-01-17T00:00:00.000Z'
      },
      {
      open: '2.7006000000000000',
      high: '2.7946000000000000',
      low: '2.5800000000000000',
      close: '2.7709000000000000',
      volume: '4454822.8800000000000000',
      quoteVolume: 12057457.05895922,
      btcVolume: 1354.3595299323777,
      usdVolume: 12057457.05895922,
      time: '2020-01-18T00:00:00.000Z'
      },
      {
      open: '2.7749000000000000',
      high: '2.8475000000000000',
      low: '2.5385000000000000',
      close: '2.6212000000000000',
      volume: '6634722.1700000000000000',
      quoteVolume: 17678886.450535074,
      btcVolume: 2012.0756253195111,
      usdVolume: 17678886.450535074,
      time: '2020-01-19T00:00:00.000Z'
      },
      {
      open: '2.6207000000000000',
      high: '2.7443000000000000',
      low: '2.5425000000000000',
      close: '2.6880000000000000',
      volume: '4142689.5300000000000000',
      quoteVolume: 10966736.422782997,
      btcVolume: 1266.964503736621,
      usdVolume: 10966736.422782997,
      time: '2020-01-20T00:00:00.000Z'
      },
      {
      open: '2.6881000000000000',
      high: '2.7440000000000000',
      low: '2.5700000000000000',
      close: '2.6716000000000000',
      volume: '3244654.0900000000000000',
      quoteVolume: 8677468.39766201,
      btcVolume: 1002.1855751352247,
      usdVolume: 8677468.39766201,
      time: '2020-01-21T00:00:00.000Z'
      },
      {
      open: '2.6724000000000000',
      high: '2.6978000000000000',
      low: '2.6187000000000000',
      close: '2.6426000000000000',
      volume: '1637205.0200000000000000',
      quoteVolume: 4351550.913769996,
      btcVolume: 500.3133383092309,
      usdVolume: 4351550.913769996,
      time: '2020-01-22T00:00:00.000Z'
      },
      {
      open: '2.6425000000000000',
      high: '2.6470000000000000',
      low: '2.4256000000000000',
      close: '2.4698000000000000',
      volume: '4806803.2100000000000000',
      quoteVolume: 11989939.300899168,
      btcVolume: 1413.7383885194763,
      usdVolume: 11989939.300899168,
      time: '2020-01-23T00:00:00.000Z'
      },
      {
      open: '2.4695000000000000',
      high: '2.5330000000000000',
      low: '2.3638000000000000',
      close: '2.5024000000000000',
      volume: '4161651.9700000000000000',
      quoteVolume: 10232694.793375008,
      btcVolume: 1220.4855118581247,
      usdVolume: 10232694.793375008,
      time: '2020-01-24T00:00:00.000Z'
      },
      {
      open: '2.5024000000000000',
      high: '2.5100000000000000',
      low: '2.4210000000000000',
      close: '2.4468000000000000',
      volume: '1881630.1800000000000000',
      quoteVolume: 4630337.163306008,
      btcVolume: 555.0830281021886,
      usdVolume: 4630337.163306008,
      time: '2020-01-25T00:00:00.000Z'
      },
      {
      open: '2.4425000000000000',
      high: '2.5764000000000000',
      low: '2.4266000000000000',
      close: '2.5764000000000000',
      volume: '1936836.4700000000000000',
      quoteVolume: 4876802.3327080235,
      btcVolume: 574.1477799412621,
      usdVolume: 4876802.3327080235,
      time: '2020-01-26T00:00:00.000Z'
      },
      {
      open: '2.5764000000000000',
      high: '2.6764000000000000',
      low: '2.5656000000000000',
      close: '2.6055000000000000',
      volume: '3426087.6500000000000000',
      quoteVolume: 8983048.130742965,
      btcVolume: 1025.861592856912,
      usdVolume: 8983048.130742965,
      time: '2020-01-27T00:00:00.000Z'
      },
      {
      open: '2.6051000000000000',
      high: '2.6700000000000000',
      low: '2.5868000000000000',
      close: '2.6558000000000000',
      volume: '3113588.1700000000000000',
      quoteVolume: 8190994.708914968,
      btcVolume: 901.4969362742667,
      usdVolume: 8190994.708914968,
      time: '2020-01-28T00:00:00.000Z'
      },
      {
      open: '2.6544000000000000',
      high: '2.8348000000000000',
      low: '2.6316000000000000',
      close: '2.7773000000000000',
      volume: '5191102.2600000000000000',
      quoteVolume: 14210741.277650112,
      btcVolume: 1519.9493554481853,
      usdVolume: 14210741.277650112,
      time: '2020-01-29T00:00:00.000Z'
      },
      {
      open: '2.7785000000000000',
      high: '2.9482000000000000',
      low: '2.7167000000000000',
      close: '2.9156000000000000',
      volume: '5780750.0200000000000000',
      quoteVolume: 16529966.221006121,
      btcVolume: 1760.8623301714779,
      usdVolume: 16529966.221006121,
      time: '2020-01-30T00:00:00.000Z'
      },
      {
      open: '2.9120000000000000',
      high: '2.9153000000000000',
      low: '2.7628000000000000',
      close: '2.8259000000000000',
      volume: '3910431.2100000000000000',
      quoteVolume: 11114615.65423999,
      btcVolume: 1189.571698179413,
      usdVolume: 11114615.65423999,
      time: '2020-01-31T00:00:00.000Z'
      },
      {
      open: '2.8258000000000000',
      high: '2.8800000000000000',
      low: '2.7438000000000000',
      close: '2.8378000000000000',
      volume: '2737541.7700000000000000',
      quoteVolume: 7681755.743857064,
      btcVolume: 819.739572372717,
      usdVolume: 7681755.743857064,
      time: '2020-02-01T00:00:00.000Z'
      },
      {
      open: '2.8372000000000000',
      high: '2.8869000000000000',
      low: '2.7314000000000000',
      close: '2.8267000000000000',
      volume: '3442889.5900000000000000',
      quoteVolume: 9768511.051874014,
      btcVolume: 1040.2867368472987,
      usdVolume: 9768511.051874014,
      time: '2020-02-02T00:00:00.000Z'
      },
      {
      open: '2.8225000000000000',
      high: '2.8790000000000000',
      low: '2.7451000000000000',
      close: '2.7710000000000000',
      volume: '3106758.8600000000000000',
      quoteVolume: 8691569.65454806,
      btcVolume: 929.8882901515204,
      usdVolume: 8691569.65454806,
      time: '2020-02-03T00:00:00.000Z'
      },
      {
      open: '2.7713000000000000',
      high: '2.8038000000000000',
      low: '2.6751000000000000',
      close: '2.7395000000000000',
      volume: '4491247.5800000000000000',
      quoteVolume: 12261241.924308985,
      btcVolume: 1328.2200602977618,
      usdVolume: 12261241.924308985,
      time: '2020-02-04T00:00:00.000Z'
      },
      {
      open: '2.7397000000000000',
      high: '2.8845000000000000',
      low: '2.7261000000000000',
      close: '2.8390000000000000',
      volume: '4267689.2200000000000000',
      quoteVolume: 12053160.781550005,
      btcVolume: 1276.385904166067,
      usdVolume: 12053160.781550005,
      time: '2020-02-05T00:00:00.000Z'
      },
      {
      open: '2.8360000000000000',
      high: '2.9436000000000000',
      low: '2.7900000000000000',
      close: '2.8900000000000000',
      volume: '4131243.5200000000000000',
      quoteVolume: 11910422.487068068,
      btcVolume: 1227.3627511910402,
      usdVolume: 11910422.487068068,
      time: '2020-02-06T00:00:00.000Z'
      },
      {
      open: '2.8900000000000000',
      high: '3.3682000000000000',
      low: '2.8882000000000000',
      close: '3.3072000000000000',
      volume: '14813365.3600000000000000',
      quoteVolume: 47449282.522496335,
      btcVolume: 4846.042169836303,
      usdVolume: 47449282.522496335,
      time: '2020-02-07T00:00:00.000Z'
      },
      {
      open: '3.3071000000000000',
      high: '3.4865000000000000',
      low: '3.1310000000000000',
      close: '3.4171000000000000',
      volume: '10059760.5500000000000000',
      quoteVolume: 33442719.414118227,
      btcVolume: 3409.4308561389403,
      usdVolume: 33442719.414118227,
      time: '2020-02-08T00:00:00.000Z'
      },
      {
      open: '3.4179000000000000',
      high: '3.4984000000000000',
      low: '3.2330000000000000',
      close: '3.4945000000000000',
      volume: '6056067.2700000000000000',
      quoteVolume: 20336735.107043743,
      btcVolume: 2016.569351190022,
      usdVolume: 20336735.107043743,
      time: '2020-02-09T00:00:00.000Z'
      },
      {
      open: '3.4927000000000000',
      high: '3.4972000000000000',
      low: '3.2806000000000000',
      close: '3.3862000000000000',
      volume: '6758374.3500000000000000',
      quoteVolume: 22873780.175180133,
      btcVolume: 2303.5567499558065,
      usdVolume: 22873780.175180133,
      time: '2020-02-10T00:00:00.000Z'
      },
      {
      open: '3.3874000000000000',
      high: '3.9964000000000000',
      low: '3.3500000000000000',
      close: '3.9931000000000000',
      volume: '11780106.0700000000000000',
      quoteVolume: 43237892.69208104,
      btcVolume: 4336.995754427936,
      usdVolume: 43237892.69208104,
      time: '2020-02-11T00:00:00.000Z'
      },
      {
      open: '3.9937000000000000',
      high: '4.2000000000000000',
      low: '3.8472000000000000',
      close: '3.9953000000000000',
      volume: '13673147.3100000000000000',
      quoteVolume: 55003441.64790517,
      btcVolume: 5332.8082573628735,
      usdVolume: 55003441.64790517,
      time: '2020-02-12T00:00:00.000Z'
      },
      {
      open: '3.9930000000000000',
      high: '4.1147000000000000',
      low: '3.7123000000000000',
      close: '3.8765000000000000',
      volume: '8191206.1300000000000000',
      quoteVolume: 32257042.692259546,
      btcVolume: 3140.1527250738345,
      usdVolume: 32257042.692259546,
      time: '2020-02-13T00:00:00.000Z'
      },
      {
      open: '3.8753000000000000',
      high: '4.4870000000000000',
      low: '3.7873000000000000',
      close: '4.4260000000000000',
      volume: '10369344.9500000000000000',
      quoteVolume: 43468717.18347415,
      btcVolume: 4231.988614585208,
      usdVolume: 43468717.18347415,
      time: '2020-02-14T00:00:00.000Z'
      },
      {
      open: '4.4262000000000000',
      high: '4.6210000000000000',
      low: '4.0650000000000000',
      close: '4.3241000000000000',
      volume: '15573115.1200000000000000',
      quoteVolume: 68355374.03477696,
      btcVolume: 6799.603705176355,
      usdVolume: 68355374.03477696,
      time: '2020-02-15T00:00:00.000Z'
      },
      {
      open: '4.3215000000000000',
      high: '4.8671000000000000',
      low: '4.0384000000000000',
      close: '4.4566000000000000',
      volume: '22171359.6900000000000000',
      quoteVolume: 99672983.28316268,
      btcVolume: 10084.607252387823,
      usdVolume: 99672983.28316268,
      time: '2020-02-16T00:00:00.000Z'
      },
      {
      open: '4.4563000000000000',
      high: '4.5522000000000000',
      low: '3.8449000000000000',
      close: '4.3108000000000000',
      volume: '19343142.2500000000000000',
      quoteVolume: 80859272.12602058,
      btcVolume: 8343.69697549989,
      usdVolume: 80859272.12602058,
      time: '2020-02-17T00:00:00.000Z'
      },
      {
      open: '4.3059000000000000',
      high: '4.6360000000000000',
      low: '4.1416000000000000',
      close: '4.5523000000000000',
      volume: '11931206.5700000000000000',
      quoteVolume: 52870430.99099803,
      btcVolume: 5385.401313218787,
      usdVolume: 52870430.99099803,
      time: '2020-02-18T00:00:00.000Z'
      },
      {
      open: '4.5539000000000000',
      high: '4.8355000000000000',
      low: '4.1930000000000000',
      close: '4.3873000000000000',
      volume: '10055681.7000000000000000',
      quoteVolume: 46162309.631008886,
      btcVolume: 4610.077023153315,
      usdVolume: 46162309.631008886,
      time: '2020-02-19T00:00:00.000Z'
      },
      {
      open: '4.3840000000000000',
      high: '4.4865000000000000',
      low: '4.0673000000000000',
      close: '4.2644000000000000',
      volume: '11463128.1100000000000000',
      quoteVolume: 48921964.38781903,
      btcVolume: 5107.005216621635,
      usdVolume: 48921964.38781903,
      time: '2020-02-20T00:00:00.000Z'
      },
      {
      open: '4.2666000000000000',
      high: '4.5127000000000000',
      low: '4.1840000000000000',
      close: '4.2778000000000000',
      volume: '7706651.0700000000000000',
      quoteVolume: 33506855.339449476,
      btcVolume: 3462.076686554174,
      usdVolume: 33506855.339449476,
      time: '2020-02-21T00:00:00.000Z'
      },
      {
      open: '4.2789000000000000',
      high: '4.3500000000000000',
      low: '4.0332000000000000',
      close: '4.1066000000000000',
      volume: '6716454.0200000000000000',
      quoteVolume: 27976312.64533768,
      btcVolume: 2902.199389115639,
      usdVolume: 27976312.64533768,
      time: '2020-02-22T00:00:00.000Z'
      },
      {
      open: '4.1006000000000000',
      high: '4.3768000000000000',
      low: '4.0693000000000000',
      close: '4.2365000000000000',
      volume: '5642974.2100000000000000',
      quoteVolume: 23992021.76366039,
      btcVolume: 2434.114086850125,
      usdVolume: 23992021.76366039,
      time: '2020-02-23T00:00:00.000Z'
      },
      {
      open: '4.2366000000000000',
      high: '4.2871000000000000',
      low: '3.7916000000000000',
      close: '3.9148000000000000',
      volume: '8591286.0700000000000000',
      quoteVolume: 34393400.24605992,
      btcVolume: 3548.267172458539,
      usdVolume: 34393400.24605992,
      time: '2020-02-24T00:00:00.000Z'
      },
      {
      open: '3.9179000000000000',
      high: '3.9600000000000000',
      low: '3.4864000000000000',
      close: '3.4905000000000000',
      volume: '12394531.5200000000000000',
      quoteVolume: 45560015.71143371,
      btcVolume: 4810.153110343996,
      usdVolume: 45560015.71143371,
      time: '2020-02-25T00:00:00.000Z'
      },
      {
      open: '3.4874000000000000',
      high: '3.8587000000000000',
      low: '3.3113000000000000',
      close: '3.5648000000000000',
      volume: '22177330.0200000000000000',
      quoteVolume: 79010843.38455322,
      btcVolume: 8784.642820650577,
      usdVolume: 79010843.38455322,
      time: '2020-02-26T00:00:00.000Z'
      },
      {
      open: '3.5648000000000000',
      high: '4.1320000000000000',
      low: '3.4197000000000000',
      close: '3.9579000000000000',
      volume: '19080853.8700000000000000',
      quoteVolume: 73041636.71234092,
      btcVolume: 8290.177225043033,
      usdVolume: 73041636.71234092,
      time: '2020-02-27T00:00:00.000Z'
      },
      {
      open: '3.9568000000000000',
      high: '4.2395000000000000',
      low: '3.7500000000000000',
      close: '4.1609000000000000',
      volume: '18251889.1100000000000000',
      quoteVolume: 72768936.34054644,
      btcVolume: 8381.737217549813,
      usdVolume: 72768936.34054644,
      time: '2020-02-28T00:00:00.000Z'
      },
      {
      open: '4.1614000000000000',
      high: '4.3535000000000000',
      low: '4.0572000000000000',
      close: '4.0579000000000000',
      volume: '12433586.3300000000000000',
      quoteVolume: 52447455.00483184,
      btcVolume: 6050.453008344912,
      usdVolume: 52447455.00483184,
      time: '2020-02-29T00:00:00.000Z'
      },
      {
      open: '4.0600000000000000',
      high: '4.2385000000000000',
      low: '3.7607000000000000',
      close: '3.8590000000000000',
      volume: '14748523.0700000000000000',
      quoteVolume: 58869388.27488089,
      btcVolume: 6880.090250435741,
      usdVolume: 58869388.27488089,
      time: '2020-03-01T00:00:00.000Z'
      },
      {
      open: '3.8622000000000000',
      high: '4.3435000000000000',
      low: '3.8173000000000000',
      close: '4.2423000000000000',
      volume: '12656156.0500000000000000',
      quoteVolume: 52493228.92320553,
      btcVolume: 5994.207586648094,
      usdVolume: 52493228.92320553,
      time: '2020-03-02T00:00:00.000Z'
      },
      {
      open: '4.2421000000000000',
      high: '4.6800000000000000',
      low: '4.1249000000000000',
      close: '4.5846000000000000',
      volume: '17189323.0000000000000000',
      quoteVolume: 75145836.87198897,
      btcVolume: 8560.826818400581,
      usdVolume: 75145836.87198897,
      time: '2020-03-03T00:00:00.000Z'
      },
      {
      open: '4.5873000000000000',
      high: '4.9762000000000000',
      low: '4.5333000000000000',
      close: '4.6406000000000000',
      volume: '18623299.5100000000000000',
      quoteVolume: 88271977.43112102,
      btcVolume: 10087.310040122788,
      usdVolume: 88271977.43112102,
      time: '2020-03-04T00:00:00.000Z'
      },
      {
      open: '4.6388000000000000',
      high: '4.8990000000000000',
      low: '4.6239000000000000',
      close: '4.7151000000000000',
      volume: '11845630.6300000000000000',
      quoteVolume: 56401786.5002544,
      btcVolume: 6263.948735289506,
      usdVolume: 56401786.5002544,
      time: '2020-03-05T00:00:00.000Z'
      },
      {
      open: '4.7144000000000000',
      high: '4.7800000000000000',
      low: '4.5429000000000000',
      close: '4.6814000000000000',
      volume: '7645180.7400000000000000',
      quoteVolume: 35490114.51378227,
      btcVolume: 3908.2548527927784,
      usdVolume: 35490114.51378227,
      time: '2020-03-06T00:00:00.000Z'
      },
      {
      open: '4.6813000000000000',
      high: '4.6863000000000000',
      low: '4.3143000000000000',
      close: '4.3412000000000000',
      volume: '8209506.8500000000000000',
      quoteVolume: 37077348.43982905,
      btcVolume: 4113.020563862099,
      usdVolume: 37077348.43982905,
      time: '2020-03-07T00:00:00.000Z'
      },
      {
      open: '4.3468000000000000',
      high: '4.4646000000000000',
      low: '3.8500000000000000',
      close: '4.0537000000000000',
      volume: '18324667.2600000000000000',
      quoteVolume: 76704000.26084687,
      btcVolume: 9085.343101448982,
      usdVolume: 76704000.26084687,
      time: '2020-03-08T00:00:00.000Z'
      },
      {
      open: '4.0537000000000000',
      high: '4.3690000000000000',
      low: '3.6012000000000000',
      close: '4.1231000000000000',
      volume: '29042929.6300000000000000',
      quoteVolume: 116996235.58194844,
      btcVolume: 14910.78468436395,
      usdVolume: 116996235.58194844,
      time: '2020-03-09T00:00:00.000Z'
      },
      {
      open: '4.1237000000000000',
      high: '4.2119000000000000',
      low: '3.9132000000000000',
      close: '4.0839000000000000',
      volume: '18071323.5600000000000000',
      quoteVolume: 73423955.05481032,
      btcVolume: 9232.548450091543,
      usdVolume: 73423955.05481032,
      time: '2020-03-10T00:00:00.000Z'
      },
      {
      open: '4.0842000000000000',
      high: '4.1100000000000000',
      low: '3.5514000000000000',
      close: '3.8169000000000000',
      volume: '19407327.4000000000000000',
      quoteVolume: 74033713.87227552,
      btcVolume: 9483.288672404144,
      usdVolume: 74033713.87227552,
      time: '2020-03-11T00:00:00.000Z'
      },
      {
      open: '3.8170000000000000',
      high: '3.8519000000000000',
      low: '0.0001000000000000',
      close: '1.9931000000000000',
      volume: '67738097.2700000000000000',
      quoteVolume: 177673762.49713,
      btcVolume: 27866.237147761774,
      usdVolume: 177673762.49713,
      time: '2020-03-12T00:00:00.000Z'
      },
      {
      open: '1.9990000000000000',
      high: '2.7520000000000000',
      low: '1.3600000000000000',
      close: '2.4333000000000000',
      volume: '69599335.5100000000000000',
      quoteVolume: 150523398.38686204,
      btcVolume: 29105.657242605583,
      usdVolume: 150523398.38686204,
      time: '2020-03-13T00:00:00.000Z'
      },
      {
      open: '2.4328000000000000',
      high: '2.5141000000000000',
      low: '2.0607000000000000',
      close: '2.1379000000000000',
      volume: '21569351.2700000000000000',
      quoteVolume: 49487604.75409475,
      btcVolume: 9228.82980590662,
      usdVolume: 49487604.75409475,
      time: '2020-03-14T00:00:00.000Z'
      },
      {
      open: '2.1401000000000000',
      high: '2.4000000000000000',
      low: '2.0150000000000000',
      close: '2.0884000000000000',
      volume: '24056556.5000000000000000',
      quoteVolume: 51979784.30551739,
      btcVolume: 9676.317809668104,
      usdVolume: 51979784.30551739,
      time: '2020-03-15T00:00:00.000Z'
      },
      {
      open: '2.0868000000000000',
      high: '2.0898000000000000',
      low: '1.5571000000000000',
      close: '1.7832000000000000',
      volume: '47175196.2700000000000000',
      quoteVolume: 83730824.5977933,
      btcVolume: 16985.606488035726,
      usdVolume: 83730824.5977933,
      time: '2020-03-16T00:00:00.000Z'
      },
      {
      open: '1.7832000000000000',
      high: '2.0690000000000000',
      low: '1.7300000000000000',
      close: '1.8641000000000000',
      volume: '32839078.6800000000000000',
      quoteVolume: 63607268.71138073,
      btcVolume: 12035.406899066662,
      usdVolume: 63607268.71138073,
      time: '2020-03-17T00:00:00.000Z'
      },
      {
      open: '1.8660000000000000',
      high: '1.9543000000000000',
      low: '1.7620000000000000',
      close: '1.9483000000000000',
      volume: '22298409.7900000000000000',
      quoteVolume: 41588758.50010696,
      btcVolume: 7903.516934502976,
      usdVolume: 41588758.50010696,
      time: '2020-03-18T00:00:00.000Z'
      },
      {
      open: '1.9497000000000000',
      high: '2.3700000000000000',
      low: '1.8841000000000000',
      close: '2.2398000000000000',
      volume: '33319846.3700000000000000',
      quoteVolume: 71956024.81635506,
      btcVolume: 12300.451552067118,
      usdVolume: 71956024.81635506,
      time: '2020-03-19T00:00:00.000Z'
      },
      {
      open: '2.2409000000000000',
      high: '2.6249000000000000',
      low: '2.0143000000000000',
      close: '2.2600000000000000',
      volume: '39390658.8700000000000000',
      quoteVolume: 92950604.2035976,
      btcVolume: 14562.188219917904,
      usdVolume: 92950604.2035976,
      time: '2020-03-20T00:00:00.000Z'
      },
      {
      open: '2.2631000000000000',
      high: '2.3750000000000000',
      low: '2.1050000000000000',
      close: '2.2787000000000000',
      volume: '19471583.8400000000000000',
      quoteVolume: 43519402.05460915,
      btcVolume: 7045.261058540346,
      usdVolume: 43519402.05460915,
      time: '2020-03-21T00:00:00.000Z'
      },
      {
      open: '2.2754000000000000',
      high: '2.3300000000000000',
      low: '1.9334000000000000',
      close: '1.9898000000000000',
      volume: '21634031.6900000000000000',
      quoteVolume: 45651954.71691129,
      btcVolume: 7551.212589732256,
      usdVolume: 45651954.71691129,
      time: '2020-03-22T00:00:00.000Z'
      },
      {
      open: '1.9889000000000000',
      high: '2.3000000000000000',
      low: '1.9352000000000000',
      close: '2.2468000000000000',
      volume: '23672246.4900000000000000',
      quoteVolume: 50076329.17784201,
      btcVolume: 8112.687771095752,
      usdVolume: 50076329.17784201,
      time: '2020-03-23T00:00:00.000Z'
      },
      {
      open: '2.2470000000000000',
      high: '2.3709000000000000',
      low: '2.1950000000000000',
      close: '2.3104000000000000',
      volume: '18400021.3200000000000000',
      quoteVolume: 42125779.98805816,
      btcVolume: 6347.98486825512,
      usdVolume: 42125779.98805816,
      time: '2020-03-24T00:00:00.000Z'
      },
      {
      open: '2.3109000000000000',
      high: '2.3500000000000000',
      low: '2.1804000000000000',
      close: '2.2570000000000000',
      volume: '11321064.3000000000000000',
      quoteVolume: 25603393.54787736,
      btcVolume: 3847.290256854321,
      usdVolume: 25603393.54787736,
      time: '2020-03-25T00:00:00.000Z'
      },
      {
      open: '2.2569000000000000',
      high: '2.3132000000000000',
      low: '2.1884000000000000',
      close: '2.3132000000000000',
      volume: '6803251.3800000000000000',
      quoteVolume: 15346687.775324736,
      btcVolume: 2303.7934993235885,
      usdVolume: 15346687.775324736,
      time: '2020-03-26T00:00:00.000Z'
      },
      {
      open: '2.3125000000000000',
      high: '2.3599000000000000',
      low: '2.1055000000000000',
      close: '2.1373000000000000',
      volume: '8151561.2000000000000000',
      quoteVolume: 18458235.87850203,
      btcVolume: 2776.0706097775064,
      usdVolume: 18458235.87850203,
      time: '2020-03-27T00:00:00.000Z'
      },
      {
      open: '2.1372000000000000',
      high: '2.1544000000000000',
      low: '2.0372000000000000',
      close: '2.1364000000000000',
      volume: '11036739.0800000000000000',
      quoteVolume: 23162230.482438084,
      btcVolume: 3726.428487730779,
      usdVolume: 23162230.482438084,
      time: '2020-03-28T00:00:00.000Z'
      },
      {
      open: '2.1356000000000000',
      high: '2.1520000000000000',
      low: '2.0070000000000000',
      close: '2.0083000000000000',
      volume: '6879303.8400000000000000',
      quoteVolume: 14309647.633855054,
      btcVolume: 2356.8649141943242,
      usdVolume: 14309647.633855054,
      time: '2020-03-29T00:00:00.000Z'
      },
      {
      open: '2.0083000000000000',
      high: '2.2000000000000000',
      low: '2.0006000000000000',
      close: '2.1350000000000000',
      volume: '7970226.4900000000000000',
      quoteVolume: 16914779.735236812,
      btcVolume: 2698.6373186550286,
      usdVolume: 16914779.735236812,
      time: '2020-03-30T00:00:00.000Z'
      },
      {
      open: '2.1348000000000000',
      high: '2.3020000000000000',
      low: '2.1102000000000000',
      close: '2.2658000000000000',
      volume: '8585088.2100000000000000',
      quoteVolume: 18841402.98135908,
      btcVolume: 2922.5256630907675,
      usdVolume: 18841402.98135908,
      time: '2020-03-31T00:00:00.000Z'
      },
      {
      open: '2.2657000000000000',
      high: '2.2999000000000000',
      low: '2.1400000000000000',
      close: '2.2753000000000000',
      volume: '13239732.4400000000000000',
      quoteVolume: 29469066.54972005,
      btcVolume: 4669.983786159829,
      usdVolume: 29469066.54972005,
      time: '2020-04-01T00:00:00.000Z'
      },
      {
      open: '2.2764000000000000',
      high: '2.4850000000000000',
      low: '2.2566000000000000',
      close: '2.2941000000000000',
      volume: '13266669.3900000000000000',
      quoteVolume: 30798141.032473035,
      btcVolume: 4540.6089277905485,
      usdVolume: 30798141.032473035,
      time: '2020-04-02T00:00:00.000Z'
      },
      {
      open: '2.2940000000000000',
      high: '2.3837000000000000',
      low: '2.2652000000000000',
      close: '2.3013000000000000',
      volume: '8452189.4500000000000000',
      quoteVolume: 19628894.276646227,
      btcVolume: 2880.3710594063255,
      usdVolume: 19628894.276646227,
      time: '2020-04-03T00:00:00.000Z'
      },
      {
      open: '2.2995000000000000',
      high: '2.3423000000000000',
      low: '2.2545000000000000',
      close: '2.3155000000000000',
      volume: '6117486.0100000000000000',
      quoteVolume: 14001543.18994617,
      btcVolume: 2067.6146389867217,
      usdVolume: 14001543.18994617,
      time: '2020-04-04T00:00:00.000Z'
      },
      {
      open: '2.3155000000000000',
      high: '2.3219000000000000',
      low: '2.2437000000000000',
      close: '2.2635000000000000',
      volume: '3228659.2300000000000000',
      quoteVolume: 7361336.519118009,
      btcVolume: 1085.637636025333,
      usdVolume: 7361336.519118009,
      time: '2020-04-05T00:00:00.000Z'
      },
      {
      open: '2.2638000000000000',
      high: '2.5677000000000000',
      low: '2.2621000000000000',
      close: '2.5555000000000000',
      volume: '12222925.8100000000000000',
      quoteVolume: 29594871.538146637,
      btcVolume: 4150.693258507703,
      usdVolume: 29594871.538146637,
      time: '2020-04-06T00:00:00.000Z'
      },
      {
      open: '2.5556000000000000',
      high: '2.9080000000000000',
      low: '2.5550000000000000',
      close: '2.7303000000000000',
      volume: '30354976.5100000000000000',
      quoteVolume: 83149479.68281008,
      btcVolume: 11365.108305697015,
      usdVolume: 83149479.68281008,
      time: '2020-04-07T00:00:00.000Z'
      },
      {
      open: '2.7295000000000000',
      high: '3.2748000000000000',
      low: '2.7124000000000000',
      close: '3.1693000000000000',
      volume: '27092993.2400000000000000',
      quoteVolume: 80619866.26421164,
      btcVolume: 11021.04111756064,
      usdVolume: 80619866.26421164,
      time: '2020-04-08T00:00:00.000Z'
      },
      {
      open: '3.1695000000000000',
      high: '3.4741000000000000',
      low: '3.1224000000000000',
      close: '3.3099000000000000',
      volume: '36999763.5100000000000000',
      quoteVolume: 122751896.42489386,
      btcVolume: 16839.37173441571,
      usdVolume: 122751896.42489386,
      time: '2020-04-09T00:00:00.000Z'
      },
      {
      open: '3.3099000000000000',
      high: '3.3199000000000000',
      low: '2.9450000000000000',
      close: '3.2200000000000000',
      volume: '43621490.6000000000000000',
      quoteVolume: 136026639.49936587,
      btcVolume: 19554.197753884662,
      usdVolume: 136026639.49936587,
      time: '2020-04-10T00:00:00.000Z'
      },
      {
      open: '3.2207000000000000',
      high: '3.4364000000000000',
      low: '3.0629000000000000',
      close: '3.2900000000000000',
      volume: '30471997.5000000000000000',
      quoteVolume: 99435616.49901348,
      btcVolume: 14491.434066489905,
      usdVolume: 99435616.49901348,
      time: '2020-04-11T00:00:00.000Z'
      },
      {
      open: '3.2912000000000000',
      high: '3.6412000000000000',
      low: '3.1670000000000000',
      close: '3.4189000000000000',
      volume: '32589266.7900000000000000',
      quoteVolume: 111420529.95980085,
      btcVolume: 15946.446334706492,
      usdVolume: 111420529.95980085,
      time: '2020-04-12T00:00:00.000Z'
      },
      {
      open: '3.4117000000000000',
      high: '3.4602000000000000',
      low: '3.2000000000000000',
      close: '3.3702000000000000',
      volume: '29488744.4800000000000000',
      quoteVolume: 99093977.11353326,
      btcVolume: 14706.279591907658,
      usdVolume: 99093977.11353326,
      time: '2020-04-13T00:00:00.000Z'
      },
      {
      open: '3.3719000000000000',
      high: '3.4437000000000000',
      low: '3.2100000000000000',
      close: '3.2520000000000000',
      volume: '17078827.0100000000000000',
      quoteVolume: 57101788.37653568,
      btcVolume: 8313.477053450417,
      usdVolume: 57101788.37653568,
      time: '2020-04-14T00:00:00.000Z'
      },
      {
      open: '3.2517000000000000',
      high: '3.3061000000000000',
      low: '3.1085000000000000',
      close: '3.1212000000000000',
      volume: '15635873.1100000000000000',
      quoteVolume: 50161624.541744925,
      btcVolume: 7410.73145926265,
      usdVolume: 50161624.541744925,
      time: '2020-04-15T00:00:00.000Z'
      },
      {
      open: '3.1212000000000000',
      high: '3.5555000000000000',
      low: '2.9761000000000000',
      close: '3.4851000000000000',
      volume: '24817961.6500000000000000',
      quoteVolume: 81880780.97622721,
      btcVolume: 11818.013830683783,
      usdVolume: 81880780.97622721,
      time: '2020-04-16T00:00:00.000Z'
      },
      {
      open: '3.4831000000000000',
      high: '3.5250000000000000',
      low: '3.3778000000000000',
      close: '3.4523000000000000',
      volume: '12669277.2500000000000000',
      quoteVolume: 43776483.22340796,
      btcVolume: 6193.887862135089,
      usdVolume: 43776483.22340796,
      time: '2020-04-17T00:00:00.000Z'
      },
      {
      open: '3.4525000000000000',
      high: '3.8300000000000000',
      low: '3.4470000000000000',
      close: '3.7805000000000000',
      volume: '16363948.9700000000000000',
      quoteVolume: 59614221.39959253,
      btcVolume: 8310.66795040822,
      usdVolume: 59614221.39959253,
      time: '2020-04-18T00:00:00.000Z'
      },
      {
      open: '3.7814000000000000',
      high: '3.8102000000000000',
      low: '3.5131000000000000',
      close: '3.5902000000000000',
      volume: '16350496.0200000000000000',
      quoteVolume: 59872603.95570334,
      btcVolume: 8367.38354342493,
      usdVolume: 59872603.95570334,
      time: '2020-04-19T00:00:00.000Z'
      },
      {
      open: '3.5885000000000000',
      high: '3.7920000000000000',
      low: '3.3720000000000000',
      close: '3.4322000000000000',
      volume: '20550987.6400000000000000',
      quoteVolume: 73812197.67135006,
      btcVolume: 10555.803904890008,
      usdVolume: 73812197.67135006,
      time: '2020-04-20T00:00:00.000Z'
      },
      {
      open: '3.4317000000000000',
      high: '3.5719000000000000',
      low: '3.3903000000000000',
      close: '3.4577000000000000',
      volume: '13684724.9900000000000000',
      quoteVolume: 47685948.13373961,
      btcVolume: 6958.109221813893,
      usdVolume: 47685948.13373961,
      time: '2020-04-21T00:00:00.000Z'
      },
      {
      open: '3.4593000000000000',
      high: '3.6904000000000000',
      low: '3.4327000000000000',
      close: '3.6786000000000000',
      volume: '11762633.8300000000000000',
      quoteVolume: 42349673.69567189,
      btcVolume: 6054.788459746665,
      usdVolume: 42349673.69567189,
      time: '2020-04-22T00:00:00.000Z'
      },
      {
      open: '3.6787000000000000',
      high: '3.9101000000000000',
      low: '3.5968000000000000',
      close: '3.7533000000000000',
      volume: '14126638.7900000000000000',
      quoteVolume: 52991673.114868954,
      btcVolume: 7220.341851272833,
      usdVolume: 52991673.114868954,
      time: '2020-04-23T00:00:00.000Z'
      },
      {
      open: '3.7551000000000000',
      high: '3.8874000000000000',
      low: '3.7364000000000000',
      close: '3.7735000000000000',
      volume: '7933872.6600000000000000',
      quoteVolume: 30294109.77665413,
      btcVolume: 4032.0422494074355,
      usdVolume: 30294109.77665413,
      time: '2020-04-24T00:00:00.000Z'
      },
      {
      open: '3.7735000000000000',
      high: '3.9062000000000000',
      low: '3.7420000000000000',
      close: '3.7865000000000000',
      volume: '6095069.3800000000000000',
      quoteVolume: 23261065.041254092,
      btcVolume: 3081.0533832217207,
      usdVolume: 23261065.041254092,
      time: '2020-04-25T00:00:00.000Z'
      },
      {
      open: '3.7865000000000000',
      high: '3.8160000000000000',
      low: '3.6432000000000000',
      close: '3.7210000000000000',
      volume: '8246658.1300000000000000',
      quoteVolume: 30808734.632196084,
      btcVolume: 4057.4729534536946,
      usdVolume: 30808734.632196084,
      time: '2020-04-26T00:00:00.000Z'
      },
      {
      open: '3.7212000000000000',
      high: '3.7385000000000000',
      low: '3.4621000000000000',
      close: '3.6383000000000000',
      volume: '11371929.8600000000000000',
      quoteVolume: 40891564.50235291,
      btcVolume: 5313.060933220702,
      usdVolume: 40891564.50235291,
      time: '2020-04-27T00:00:00.000Z'
      },
      {
      open: '3.6373000000000000',
      high: '3.7354000000000000',
      low: '3.5554000000000000',
      close: '3.6413000000000000',
      volume: '7379317.2300000000000000',
      quoteVolume: 26928714.47205683,
      btcVolume: 3488.09599283931,
      usdVolume: 26928714.47205683,
      time: '2020-04-28T00:00:00.000Z'
      },
      {
      open: '3.6422000000000000',
      high: '3.9918000000000000',
      low: '3.6300000000000000',
      close: '3.8879000000000000',
      volume: '15125283.5400000000000000',
      quoteVolume: 57370181.28630435,
      btcVolume: 6832.774464426143,
      usdVolume: 57370181.28630435,
      time: '2020-04-29T00:00:00.000Z'
      },
      {
      open: '3.8881000000000000',
      high: '3.9741000000000000',
      low: '3.6200000000000000',
      close: '3.7025000000000000',
      volume: '13545136.0200000000000000',
      quoteVolume: 51376800.29429678,
      btcVolume: 5766.507587671276,
      usdVolume: 51376800.29429678,
      time: '2020-04-30T00:00:00.000Z'
      },
      {
      open: '3.7014000000000000',
      high: '3.9162000000000000',
      low: '3.7003000000000000',
      close: '3.7571000000000000',
      volume: '8084328.3600000000000000',
      quoteVolume: 30928293.222303115,
      btcVolume: 3519.057180538519,
      usdVolume: 30928293.222303115,
      time: '2020-05-01T00:00:00.000Z'
      },
      {
      open: '3.7571000000000000',
      high: '3.8600000000000000',
      low: '3.7300000000000000',
      close: '3.8182000000000000',
      volume: '4467208.0100000000000000',
      quoteVolume: 16996396.923116993,
      btcVolume: 1915.6064803541883,
      usdVolume: 16996396.923116993,
      time: '2020-05-02T00:00:00.000Z'
      },
      {
      open: '3.8186000000000000',
      high: '3.8600000000000000',
      low: '3.6379000000000000',
      close: '3.7092000000000000',
      volume: '5554731.5200000000000000',
      quoteVolume: 20831570.33686512,
      btcVolume: 2325.320369499448,
      usdVolume: 20831570.33686512,
      time: '2020-05-03T00:00:00.000Z'
      },
      {
      open: '3.7098000000000000',
      high: '3.8272000000000000',
      low: '3.5033000000000000',
      close: '3.7499000000000000',
      volume: '10973722.2700000000000000',
      quoteVolume: 40395738.452246346,
      btcVolume: 4611.440780167115,
      usdVolume: 40395738.452246346,
      time: '2020-05-04T00:00:00.000Z'
      },
      {
      open: '3.7496000000000000',
      high: '3.8140000000000000',
      low: '3.6540000000000000',
      close: '3.6833000000000000',
      volume: '4730504.5800000000000000',
      quoteVolume: 17621530.537070103,
      btcVolume: 1975.2075137716056,
      usdVolume: 17621530.537070103,
      time: '2020-05-05T00:00:00.000Z'
      },
      {
      open: '3.6820000000000000',
      high: '3.7654000000000000',
      low: '3.5700000000000000',
      close: '3.5872000000000000',
      volume: '5299802.5900000000000000',
      quoteVolume: 19570134.054998033,
      btcVolume: 2138.6881860809644,
      usdVolume: 19570134.054998033,
      time: '2020-05-06T00:00:00.000Z'
      },
      {
      open: '3.5874000000000000',
      high: '3.8500000000000000',
      low: '3.5633000000000000',
      close: '3.7560000000000000',
      volume: '8481271.5900000000000000',
      quoteVolume: 31361055.942226164,
      btcVolume: 3257.382561578943,
      usdVolume: 31361055.942226164,
      time: '2020-05-07T00:00:00.000Z'
      },
      {
      open: '3.7580000000000000',
      high: '3.8590000000000000',
      low: '3.6874000000000000',
      close: '3.8010000000000000',
      volume: '7383724.4800000000000000',
      quoteVolume: 27986549.846176166,
      btcVolume: 2821.090111412898,
      usdVolume: 27986549.846176166,
      time: '2020-05-08T00:00:00.000Z'
      },
      {
      open: '3.8001000000000000',
      high: '4.2129000000000000',
      low: '3.7601000000000000',
      close: '4.0150000000000000',
      volume: '15240721.6300000000000000',
      quoteVolume: 61673011.98541812,
      btcVolume: 6356.310945841886,
      usdVolume: 61673011.98541812,
      time: '2020-05-09T00:00:00.000Z'
      },
      {
      open: '4.0149000000000000',
      high: '4.0197000000000000',
      low: '3.4602000000000000',
      close: '3.7582000000000000',
      volume: '19818388.7300000000000000',
      quoteVolume: 73819362.15594393,
      btcVolume: 8521.796803966217,
      usdVolume: 73819362.15594393,
      time: '2020-05-10T00:00:00.000Z'
      },
      {
      open: '3.7574000000000000',
      high: '3.8320000000000000',
      low: '3.2865000000000000',
      close: '3.5837000000000000',
      volume: '13273873.6200000000000000',
      quoteVolume: 47852980.089774795,
      btcVolume: 5547.514411645623,
      usdVolume: 47852980.089774795,
      time: '2020-05-11T00:00:00.000Z'
      },
      {
      open: '3.5838000000000000',
      high: '3.7932000000000000',
      low: '3.5400000000000000',
      close: '3.6943000000000000',
      volume: '7797486.4300000000000000',
      quoteVolume: 28881156.065596662,
      btcVolume: 3297.963204756944,
      usdVolume: 28881156.065596662,
      time: '2020-05-12T00:00:00.000Z'
      },
      {
      open: '3.6938000000000000',
      high: '3.8150000000000000',
      low: '3.6366000000000000',
      close: '3.7655000000000000',
      volume: '5949203.5300000000000000',
      quoteVolume: 22154224.568957023,
      btcVolume: 2444.9070553685347,
      usdVolume: 22154224.568957023,
      time: '2020-05-13T00:00:00.000Z'
      },
      {
      open: '3.7654000000000000',
      high: '3.8583000000000000',
      low: '3.6947000000000000',
      close: '3.8096000000000000',
      volume: '7333655.8100000000000000',
      quoteVolume: 27791822.366877846,
      btcVolume: 2891.52904194716,
      usdVolume: 27791822.366877846,
      time: '2020-05-14T00:00:00.000Z'
      },
      {
      open: '3.8093000000000000',
      high: '3.8337000000000000',
      low: '3.6144000000000000',
      close: '3.6615000000000000',
      volume: '5571782.8800000000000000',
      quoteVolume: 20831712.993012883,
      btcVolume: 2197.771365437242,
      usdVolume: 20831712.993012883,
      time: '2020-05-15T00:00:00.000Z'
      },
      {
      open: '3.6626000000000000',
      high: '3.7900000000000000',
      low: '3.6168000000000000',
      close: '3.7614000000000000',
      volume: '4045380.2000000000000000',
      quoteVolume: 15074632.829739979,
      btcVolume: 1604.6960921741713,
      usdVolume: 15074632.829739979,
      time: '2020-05-16T00:00:00.000Z'
      },
      {
      open: '3.7621000000000000',
      high: '3.8524000000000000',
      low: '3.7526000000000000',
      close: '3.7815000000000000',
      volume: '3630692.0900000000000000',
      quoteVolume: 13812656.339239048,
      btcVolume: 1432.957547933766,
      usdVolume: 13812656.339239048,
      time: '2020-05-17T00:00:00.000Z'
      },
      {
      open: '3.7815000000000000',
      high: '3.9500000000000000',
      low: '3.7786000000000000',
      close: '3.8895000000000000',
      volume: '5739644.8600000000000000',
      quoteVolume: 22305115.00685797,
      btcVolume: 2287.439967086519,
      usdVolume: 22305115.00685797,
      time: '2020-05-18T00:00:00.000Z'
      },
      {
      open: '3.8895000000000000',
      high: '3.9488000000000000',
      low: '3.7734000000000000',
      close: '3.9470000000000000',
      volume: '3765966.9300000000000000',
      quoteVolume: 14542942.326336175,
      btcVolume: 1503.244995161628,
      usdVolume: 14542942.326336175,
      time: '2020-05-19T00:00:00.000Z'
      },
      {
      open: '3.9466000000000000',
      high: '4.1388000000000000',
      low: '3.8955000000000000',
      close: '4.0866000000000000',
      volume: '9805842.1600000000000000',
      quoteVolume: 39677537.53178006,
      btcVolume: 4109.400460965454,
      usdVolume: 39677537.53178006,
      time: '2020-05-20T00:00:00.000Z'
      },
      {
      open: '4.0880000000000000',
      high: '4.1740000000000000',
      low: '3.7292000000000000',
      close: '3.8298000000000000',
      volume: '10446021.8600000000000000',
      quoteVolume: 41318047.346202776,
      btcVolume: 4472.548344593976,
      usdVolume: 41318047.346202776,
      time: '2020-05-21T00:00:00.000Z'
      },
      {
      open: '3.8307000000000000',
      high: '4.1447000000000000',
      low: '3.7932000000000000',
      close: '4.0611000000000000',
      volume: '7194944.8900000000000000',
      quoteVolume: 28640710.818571955,
      btcVolume: 3133.076928130972,
      usdVolume: 28640710.818571955,
      time: '2020-05-22T00:00:00.000Z'
      },
      {
      open: '4.0620000000000000',
      high: '4.1072000000000000',
      low: '3.9100000000000000',
      close: '3.9250000000000000',
      volume: '5865674.1500000000000000',
      quoteVolume: 23402699.08869481,
      btcVolume: 2542.731357186535,
      usdVolume: 23402699.08869481,
      time: '2020-05-23T00:00:00.000Z'
      },
      {
      open: '3.9242000000000000',
      high: '4.0262000000000000',
      low: '3.7844000000000000',
      close: '3.7917000000000000',
      volume: '6229925.9300000000000000',
      quoteVolume: 24355874.593129028,
      btcVolume: 2685.7880060204666,
      usdVolume: 24355874.593129028,
      time: '2020-05-24T00:00:00.000Z'
      },
      {
      open: '3.7878000000000000',
      high: '3.9073000000000000',
      low: '3.7700000000000000',
      close: '3.8614000000000000',
      volume: '4443996.5100000000000000',
      quoteVolume: 17123412.802925922,
      btcVolume: 1942.855709479334,
      usdVolume: 17123412.802925922,
      time: '2020-05-25T00:00:00.000Z'
      },
      {
      open: '3.8634000000000000',
      high: '3.8816000000000000',
      low: '3.7689000000000000',
      close: '3.8440000000000000',
      volume: '3774200.8300000000000000',
      quoteVolume: 14457491.492909947,
      btcVolume: 1633.177692310847,
      usdVolume: 14457491.492909947,
      time: '2020-05-26T00:00:00.000Z'
      },
      {
      open: '3.8436000000000000',
      high: '3.9143000000000000',
      low: '3.8065000000000000',
      close: '3.8526000000000000',
      volume: '3793553.8500000000000000',
      quoteVolume: 14669023.347105095,
      btcVolume: 1621.3588858213739,
      usdVolume: 14669023.347105095,
      time: '2020-05-27T00:00:00.000Z'
      },
      {
      open: '3.8542000000000000',
      high: '4.0601000000000000',
      low: '3.8270000000000000',
      close: '4.0461000000000000',
      volume: '4242354.5900000000000000',
      quoteVolume: 16636880.490730118,
      btcVolume: 1771.4352825657559,
      usdVolume: 16636880.490730118,
      time: '2020-05-28T00:00:00.000Z'
      },
      {
      open: '4.0462000000000000',
      high: '4.1300000000000000',
      low: '3.9488000000000000',
      close: '3.9800000000000000',
      volume: '5242842.6700000000000000',
      quoteVolume: 21161407.756916173,
      btcVolume: 2231.9952818631095,
      usdVolume: 21161407.756916173,
      time: '2020-05-29T00:00:00.000Z'
      },
      {
      open: '3.9820000000000000',
      high: '4.1800000000000000',
      low: '3.9430000000000000',
      close: '4.1763000000000000',
      volume: '4734058.3400000000000000',
      quoteVolume: 19356855.90212316,
      btcVolume: 2030.7654975998275,
      usdVolume: 19356855.90212316,
      time: '2020-05-30T00:00:00.000Z'
      },
      {
      open: '4.1780000000000000',
      high: '4.2838000000000000',
      low: '4.0410000000000000',
      close: '4.1310000000000000',
      volume: '6223457.9900000000000000',
      quoteVolume: 25986422.58422777,
      btcVolume: 2723.684714084976,
      usdVolume: 25986422.58422777,
      time: '2020-05-31T00:00:00.000Z'
      },
      {
      open: '4.1278000000000000',
      high: '4.4800000000000000',
      low: '4.1175000000000000',
      close: '4.3785000000000000',
      volume: '8703425.4700000000000000',
      quoteVolume: 38003418.52632179,
      btcVolume: 3946.2706304533644,
      usdVolume: 38003418.52632179,
      time: '2020-06-01T00:00:00.000Z'
      },
      {
      open: '4.3795000000000000',
      high: '4.6185000000000000',
      low: '4.1075000000000000',
      close: '4.3976000000000000',
      volume: '8147770.0700000000000000',
      quoteVolume: 35881970.22012894,
      btcVolume: 3668.4636094846724,
      usdVolume: 35881970.22012894,
      time: '2020-06-02T00:00:00.000Z'
      },
      {
      open: '4.3987000000000000',
      high: '4.5300000000000000',
      low: '4.2898000000000000',
      close: '4.4892000000000000',
      volume: '4168765.8100000000000000',
      quoteVolume: 18467942.894892167,
      btcVolume: 1933.344668741359,
      usdVolume: 18467942.894892167,
      time: '2020-06-03T00:00:00.000Z'
      },
      {
      open: '4.4881000000000000',
      high: '4.5081000000000000',
      low: '4.2864000000000000',
      close: '4.4243000000000000',
      volume: '3231902.9900000000000000',
      quoteVolume: 14276426.128533928,
      btcVolume: 1476.4285848581262,
      usdVolume: 14276426.128533928,
      time: '2020-06-04T00:00:00.000Z'
      },
      {
      open: '4.4263000000000000',
      high: '4.4860000000000000',
      low: '4.3405000000000000',
      close: '4.3580000000000000',
      volume: '2314891.8700000000000000',
      quoteVolume: 10216880.887104046,
      btcVolume: 1049.6536854309381,
      usdVolume: 10216880.887104046,
      time: '2020-06-05T00:00:00.000Z'
      },
      {
      open: '4.3569000000000000',
      high: '4.3979000000000000',
      low: '4.3034000000000000',
      close: '4.3512000000000000',
      volume: '1153869.9600000000000000',
      quoteVolume: 5020633.317667975,
      btcVolume: 521.0659599515068,
      usdVolume: 5020633.317667975,
      time: '2020-06-06T00:00:00.000Z'
      },
      {
      open: '4.3513000000000000',
      high: '4.4100000000000000',
      low: '4.2068000000000000',
      close: '4.3465000000000000',
      volume: '2137522.0500000000000000',
      quoteVolume: 9211491.186056014,
      btcVolume: 960.8098153273933,
      usdVolume: 9211491.186056014,
      time: '2020-06-07T00:00:00.000Z'
      },
      {
      open: '4.3487000000000000',
      high: '4.4759000000000000',
      low: '4.3250000000000000',
      close: '4.4730000000000000',
      volume: '2037163.2100000000000000',
      quoteVolume: 8929343.506046021,
      btcVolume: 918.8751048356538,
      usdVolume: 8929343.506046021,
      time: '2020-06-08T00:00:00.000Z'
      },
      {
      open: '4.4753000000000000',
      high: '4.5062000000000000',
      low: '4.3605000000000000',
      close: '4.4350000000000000',
      volume: '2250839.6700000000000000',
      quoteVolume: 9985584.222381042,
      btcVolume: 1027.6679495159528,
      usdVolume: 9985584.222381042,
      time: '2020-06-09T00:00:00.000Z'
      },
      {
      open: '4.4374000000000000',
      high: '4.4404000000000000',
      low: '4.3426000000000000',
      close: '4.4225000000000000',
      volume: '2229011.4100000000000000',
      quoteVolume: 9773177.039550047,
      btcVolume: 997.9263921227159,
      usdVolume: 9773177.039550047,
      time: '2020-06-10T00:00:00.000Z'
      },
      {
      open: '4.4207000000000000',
      high: '4.4503000000000000',
      low: '3.9100000000000000',
      close: '3.9565000000000000',
      volume: '6568688.8700000000000000',
      quoteVolume: 27115697.81298312,
      btcVolume: 2861.0421903797023,
      usdVolume: 27115697.81298312,
      time: '2020-06-11T00:00:00.000Z'
      },
      {
      open: '3.9530000000000000',
      high: '4.1874000000000000',
      low: '3.8573000000000000',
      close: '4.0819000000000000',
      volume: '4751891.2300000000000000',
      quoteVolume: 19339118.373514097,
      btcVolume: 2057.689856681987,
      usdVolume: 19339118.373514097,
      time: '2020-06-12T00:00:00.000Z'
      },
      {
      open: '4.0807000000000000',
      high: '4.1346000000000000',
      low: '4.0054000000000000',
      close: '4.1100000000000000',
      volume: '1604660.7400000000000000',
      quoteVolume: 6524511.677829004,
      btcVolume: 692.1491678856813,
      usdVolume: 6524511.677829004,
      time: '2020-06-13T00:00:00.000Z'
      },
      {
      open: '4.1100000000000000',
      high: '4.1134000000000000',
      low: '3.9143000000000000',
      close: '3.9371000000000000',
      volume: '1710213.3600000000000000',
      quoteVolume: 6841578.031834006,
      btcVolume: 729.0393181477964,
      usdVolume: 6841578.031834006,
      time: '2020-06-14T00:00:00.000Z'
      },
      {
      open: '3.9395000000000000',
      high: '3.9889000000000000',
      low: '3.6551000000000000',
      close: '3.9274000000000000',
      volume: '6850306.1100000000000000',
      quoteVolume: 26068942.977982927,
      btcVolume: 2826.528626557364,
      usdVolume: 26068942.977982927,
      time: '2020-06-15T00:00:00.000Z'
      },
      {
      open: '3.9254000000000000',
      high: '4.0918000000000000',
      low: '3.8935000000000000',
      close: '4.0502000000000000',
      volume: '3614037.3300000000000000',
      quoteVolume: 14515402.500366056,
      btcVolume: 1528.8235581573408,
      usdVolume: 14515402.500366056,
      time: '2020-06-16T00:00:00.000Z'
      },
      {
      open: '4.0502000000000000',
      high: '4.2000000000000000',
      low: '3.9570000000000000',
      close: '4.1659000000000000',
      volume: '4301280.9800000000000000',
      quoteVolume: 17558113.834275063,
      btcVolume: 1862.55704310758,
      usdVolume: 17558113.834275063,
      time: '2020-06-17T00:00:00.000Z'
      },
      {
      open: '4.1663000000000000',
      high: '4.2491000000000000',
      low: '4.0957000000000000',
      close: '4.1415000000000000',
      volume: '3348384.0800000000000000',
      quoteVolume: 13969700.00507494,
      btcVolume: 1483.9403280382335,
      usdVolume: 13969700.00507494,
      time: '2020-06-18T00:00:00.000Z'
      },
      {
      open: '4.1417000000000000',
      high: '4.1839000000000000',
      low: '4.0521000000000000',
      close: '4.0980000000000000',
      volume: '2424386.7000000000000000',
      quoteVolume: 9970938.73032103,
      btcVolume: 1068.115770950031,
      usdVolume: 9970938.73032103,
      time: '2020-06-19T00:00:00.000Z'
      },
      {
      open: '4.0978000000000000',
      high: '4.2072000000000000',
      low: '4.0654000000000000',
      close: '4.1765000000000000',
      volume: '2414698.6200000000000000',
      quoteVolume: 10014907.932097934,
      btcVolume: 1076.6564266730459,
      usdVolume: 10014907.932097934,
      time: '2020-06-20T00:00:00.000Z'
      },
      {
      open: '4.1762000000000000',
      high: '4.2058000000000000',
      low: '4.1395000000000000',
      close: '4.1812000000000000',
      volume: '1336859.5000000000000000',
      quoteVolume: 5582676.14346401,
      btcVolume: 596.8254803691216,
      usdVolume: 5582676.14346401,
      time: '2020-06-21T00:00:00.000Z'
      },
      {
      open: '4.1819000000000000',
      high: '4.4859000000000000',
      low: '4.1760000000000000',
      close: '4.4841000000000000',
      volume: '4636790.2800000000000000',
      quoteVolume: 20238493.440281935,
      btcVolume: 2131.3300159503665,
      usdVolume: 20238493.440281935,
      time: '2020-06-22T00:00:00.000Z'
      },
      {
      open: '4.4840000000000000',
      high: '4.9422000000000000',
      low: '4.4254000000000000',
      close: '4.8051000000000000',
      volume: '10884153.7200000000000000',
      quoteVolume: 51489688.58105523,
      btcVolume: 5341.591224643085,
      usdVolume: 51489688.58105523,
      time: '2020-06-23T00:00:00.000Z'
      },
      {
      open: '4.8061000000000000',
      high: '4.9411000000000000',
      low: '4.4986000000000000',
      close: '4.7075000000000000',
      volume: '10253258.2100000000000000',
      quoteVolume: 48438749.91706304,
      btcVolume: 5137.643671618544,
      usdVolume: 48438749.91706304,
      time: '2020-06-24T00:00:00.000Z'
      },
      {
      open: '4.7075000000000000',
      high: '4.8407000000000000',
      low: '4.5033000000000000',
      close: '4.8134000000000000',
      volume: '7023055.3700000000000000',
      quoteVolume: 33164952.91921413,
      btcVolume: 3591.85419221288,
      usdVolume: 33164952.91921413,
      time: '2020-06-25T00:00:00.000Z'
      },
      {
      open: '4.8129000000000000',
      high: '4.8587000000000000',
      low: '4.6424000000000000',
      close: '4.6813000000000000',
      volume: '5531129.1300000000000000',
      quoteVolume: 26116409.53864893,
      btcVolume: 2843.866240257118,
      usdVolume: 26116409.53864893,
      time: '2020-06-26T00:00:00.000Z'
      },
      {
      open: '4.6830000000000000',
      high: '4.6957000000000000',
      low: '4.3000000000000000',
      close: '4.4058000000000000',
      volume: '6165533.0500000000000000',
      quoteVolume: 27809003.537887093,
      btcVolume: 3060.9526617884444,
      usdVolume: 27809003.537887093,
      time: '2020-06-27T00:00:00.000Z'
      },
      {
      open: '4.4051000000000000',
      high: '4.6200000000000000',
      low: '4.3647000000000000',
      close: '4.5668000000000000',
      volume: '4751931.7400000000000000',
      quoteVolume: 21438525.93253806,
      btcVolume: 2359.635570929396,
      usdVolume: 21438525.93253806,
      time: '2020-06-28T00:00:00.000Z'
      },
      {
      open: '4.5658000000000000',
      high: '4.6500000000000000',
      low: '4.4232000000000000',
      close: '4.6187000000000000',
      volume: '4789663.3200000000000000',
      quoteVolume: 21719855.120023914,
      btcVolume: 2382.4340020200825,
      usdVolume: 21719855.120023914,
      time: '2020-06-29T00:00:00.000Z'
      },
      {
      open: '4.6192000000000000',
      high: '4.6622000000000000',
      low: '4.5337000000000000',
      close: '4.5626000000000000',
      volume: '3001636.3100000000000000',
      quoteVolume: 13817102.521447966,
      btcVolume: 1509.948465482142,
      usdVolume: 13817102.521447966,
      time: '2020-06-30T00:00:00.000Z'
      },
      {
      open: '4.5625000000000000',
      high: '4.7300000000000000',
      low: '4.5217000000000000',
      close: '4.7067000000000000',
      volume: '4059427.1700000000000000',
      quoteVolume: 18803296.868452046,
      btcVolume: 2044.3470835901742,
      usdVolume: 18803296.868452046,
      time: '2020-07-01T00:00:00.000Z'
      },
      {
      open: '4.7068000000000000',
      high: '4.9181000000000000',
      low: '4.6025000000000000',
      close: '4.7996000000000000',
      volume: '7638387.3500000000000000',
      quoteVolume: 36415952.50593107,
      btcVolume: 3981.9894097602037,
      usdVolume: 36415952.50593107,
      time: '2020-07-02T00:00:00.000Z'
      },
      {
      open: '4.7998000000000000',
      high: '4.8964000000000000',
      low: '4.7031000000000000',
      close: '4.7309000000000000',
      volume: '4456413.0000000000000000',
      quoteVolume: 21519728.981386155,
      btcVolume: 2366.7125707992577,
      usdVolume: 21519728.981386155,
      time: '2020-07-03T00:00:00.000Z'
      },
      {
      open: '4.7300000000000000',
      high: '4.8539000000000000',
      low: '4.7186000000000000',
      close: '4.8200000000000000',
      volume: '2535114.5100000000000000',
      quoteVolume: 12133983.302445995,
      btcVolume: 1333.81578228134,
      usdVolume: 12133983.302445995,
      time: '2020-07-04T00:00:00.000Z'
      },
      {
      open: '4.8198000000000000',
      high: '4.8325000000000000',
      low: '4.6118000000000000',
      close: '4.7439000000000000',
      volume: '3420895.3700000000000000',
      quoteVolume: 16145214.992928915,
      btcVolume: 1782.6229066425183,
      usdVolume: 16145214.992928915,
      time: '2020-07-05T00:00:00.000Z'
      },
      {
      open: '4.7426000000000000',
      high: '5.5000000000000000',
      low: '4.7362000000000000',
      close: '5.3617000000000000',
      volume: '12032948.9700000000000000',
      quoteVolume: 61715137.636092246,
      btcVolume: 6663.492972858207,
      usdVolume: 61715137.636092246,
      time: '2020-07-06T00:00:00.000Z'
      },
      {
      open: '5.3613000000000000',
      high: '5.8890000000000000',
      low: '5.3300000000000000',
      close: '5.7248000000000000',
      volume: '13783142.2200000000000000',
      quoteVolume: 77491639.85352772,
      btcVolume: 8361.43667342537,
      usdVolume: 77491639.85352772,
      time: '2020-07-07T00:00:00.000Z'
      },
      {
      open: '5.7245000000000000',
      high: '6.5607000000000000',
      low: '5.5920000000000000',
      close: '6.4987000000000000',
      volume: '15825025.1900000000000000',
      quoteVolume: 97337955.14051247,
      btcVolume: 10432.903935303775,
      usdVolume: 97337955.14051247,
      time: '2020-07-08T00:00:00.000Z'
      },
      {
      open: '6.5001000000000000',
      high: '6.5006000000000000',
      low: '5.7029000000000000',
      close: '6.0820000000000000',
      volume: '12942475.7400000000000000',
      quoteVolume: 78848343.82277149,
      btcVolume: 8463.213815528254,
      usdVolume: 78848343.82277149,
      time: '2020-07-09T00:00:00.000Z'
      },
      {
      open: '6.0820000000000000',
      high: '6.2009000000000000',
      low: '5.7096000000000000',
      close: '6.1488000000000000',
      volume: '9932051.9600000000000000',
      quoteVolume: 59118518.95393981,
      btcVolume: 6423.877893463667,
      usdVolume: 59118518.95393981,
      time: '2020-07-10T00:00:00.000Z'
      },
      {
      open: '6.1500000000000000',
      high: '6.2661000000000000',
      low: '5.9610000000000000',
      close: '6.0994000000000000',
      volume: '5180049.0300000000000000',
      quoteVolume: 31685675.96767919,
      btcVolume: 3429.120643940731,
      usdVolume: 31685675.96767919,
      time: '2020-07-11T00:00:00.000Z'
      },
      {
      open: '6.1043000000000000',
      high: '7.3898000000000000',
      low: '6.0975000000000000',
      close: '7.3345000000000000',
      volume: '18949318.6200000000000000',
      quoteVolume: 128119147.50220707,
      btcVolume: 13858.753628207221,
      usdVolume: 128119147.50220707,
      time: '2020-07-12T00:00:00.000Z'
      },
      {
      open: '7.3447000000000000',
      high: '8.4800000000000000',
      low: '6.8525000000000000',
      close: '7.1630000000000000',
      volume: '30737550.9600000000000000',
      quoteVolume: 235671215.53400916,
      btcVolume: 25398.30633539493,
      usdVolume: 235671215.53400916,
      time: '2020-07-13T00:00:00.000Z'
      },
      {
      open: '7.1616000000000000',
      high: '8.3446000000000000',
      low: '6.9650000000000000',
      close: '8.1321000000000000',
      volume: '25153380.4100000000000000',
      quoteVolume: 192793205.38648593,
      btcVolume: 20888.78682809465,
      usdVolume: 192793205.38648593,
      time: '2020-07-14T00:00:00.000Z'
      },
      {
      open: '8.1315000000000000',
      high: '8.9080000000000000',
      low: '7.8309000000000000',
      close: '8.6494000000000000',
      volume: '20518883.2200000000000000',
      quoteVolume: 172454027.970733,
      btcVolume: 18690.633753627804,
      usdVolume: 172454027.970733,
      time: '2020-07-15T00:00:00.000Z'
      },
      {
      open: '8.6486000000000000',
      high: '8.8822000000000000',
      low: '7.9427000000000000',
      close: '8.3540000000000000',
      volume: '20705851.7900000000000000',
      quoteVolume: 173628619.95218885,
      btcVolume: 19005.124013284785,
      usdVolume: 173628619.95218885,
      time: '2020-07-16T00:00:00.000Z'
      },
      {
      open: '8.3545000000000000',
      high: '8.6444000000000000',
      low: '8.2000000000000000',
      close: '8.2605000000000000',
      volume: '8580583.8800000000000000',
      quoteVolume: 71891358.28923588,
      btcVolume: 7867.034349896577,
      usdVolume: 71891358.28923588,
      time: '2020-07-17T00:00:00.000Z'
      },
      {
      open: '8.2589000000000000',
      high: '8.2648000000000000',
      low: '7.6570000000000000',
      close: '7.9734000000000000',
      volume: '11416632.8500000000000000',
      quoteVolume: 90986110.87730221,
      btcVolume: 9936.17044525862,
      usdVolume: 90986110.87730221,
      time: '2020-07-18T00:00:00.000Z'
      },
      {
      open: '7.9731000000000000',
      high: '8.2100000000000000',
      low: '7.8006000000000000',
      close: '8.0784000000000000',
      volume: '5325842.7900000000000000',
      quoteVolume: 42588167.82897199,
      btcVolume: 4648.695039778068,
      usdVolume: 42588167.82897199,
      time: '2020-07-19T00:00:00.000Z'
      },
      {
      open: '8.0848000000000000',
      high: '8.1388000000000000',
      low: '7.1210000000000000',
      close: '7.2301000000000000',
      volume: '11024362.8200000000000000',
      quoteVolume: 84110745.40996003,
      btcVolume: 9169.302612989131,
      usdVolume: 84110745.40996003,
      time: '2020-07-20T00:00:00.000Z'
      },
      {
      open: '7.2301000000000000',
      high: '7.6788000000000000',
      low: '6.9232000000000000',
      close: '7.3101000000000000',
      volume: '10932081.1000000000000000',
      quoteVolume: 80003692.44480799,
      btcVolume: 8576.725504872806,
      usdVolume: 80003692.44480799,
      time: '2020-07-21T00:00:00.000Z'
      },
      {
      open: '7.3086000000000000',
      high: '7.6365000000000000',
      low: '7.0832000000000000',
      close: '7.4689000000000000',
      volume: '9596016.7400000000000000',
      quoteVolume: 70863239.4579672,
      btcVolume: 7572.310124975652,
      usdVolume: 70863239.4579672,
      time: '2020-07-22T00:00:00.000Z'
      },
      {
      open: '7.4690000000000000',
      high: '8.0990000000000000',
      low: '7.4029000000000000',
      close: '7.8983000000000000',
      volume: '10915322.8800000000000000',
      quoteVolume: 85588641.40242985,
      btcVolume: 8986.708733063164,
      usdVolume: 85588641.40242985,
      time: '2020-07-23T00:00:00.000Z'
      },
      {
      open: '7.8982000000000000',
      high: '7.9304000000000000',
      low: '7.3840000000000000',
      close: '7.4284000000000000',
      volume: '7313843.7500000000000000',
      quoteVolume: 55866119.15418281,
      btcVolume: 5854.581041359676,
      usdVolume: 55866119.15418281,
      time: '2020-07-24T00:00:00.000Z'
      },
      {
      open: '7.4284000000000000',
      high: '7.8009000000000000',
      low: '7.3744000000000000',
      close: '7.6720000000000000',
      volume: '7502502.4600000000000000',
      quoteVolume: 57265634.06685721,
      btcVolume: 5964.891413603993,
      usdVolume: 57265634.06685721,
      time: '2020-07-25T00:00:00.000Z'
      },
      {
      open: '7.6737000000000000',
      high: '7.9450000000000000',
      low: '7.1885000000000000',
      close: '7.5022000000000000',
      volume: '10637660.3200000000000000',
      quoteVolume: 80431491.35325707,
      btcVolume: 8167.326551727266,
      usdVolume: 80431491.35325707,
      time: '2020-07-26T00:00:00.000Z'
      },
      {
      open: '7.5009000000000000',
      high: '7.5045000000000000',
      low: '6.5101000000000000',
      close: '7.1000000000000000',
      volume: '16680558.0400000000000000',
      quoteVolume: 117110382.73523542,
      btcVolume: 11213.359323082714,
      usdVolume: 117110382.73523542,
      time: '2020-07-27T00:00:00.000Z'
      },
      {
      open: '7.0990000000000000',
      high: '7.4243000000000000',
      low: '6.9106000000000000',
      close: '7.2333000000000000',
      volume: '8500726.8300000000000000',
      quoteVolume: 60812597.213252656,
      btcVolume: 5549.91287596061,
      usdVolume: 60812597.213252656,
      time: '2020-07-28T00:00:00.000Z'
      },
      {
      open: '7.2332000000000000',
      high: '7.4871000000000000',
      low: '6.9867000000000000',
      close: '7.0876000000000000',
      volume: '7058913.7900000000000000',
      quoteVolume: 50881041.657357685,
      btcVolume: 4599.08400376134,
      usdVolume: 50881041.657357685,
      time: '2020-07-29T00:00:00.000Z'
      },
      {
      open: '7.0876000000000000',
      high: '7.5757000000000000',
      low: '7.0253000000000000',
      close: '7.4093000000000000',
      volume: '7667819.8300000000000000',
      quoteVolume: 56146316.3170551,
      btcVolume: 5092.87952074015,
      usdVolume: 56146316.3170551,
      time: '2020-07-30T00:00:00.000Z'
      },
      {
      open: '7.4093000000000000',
      high: '7.8649000000000000',
      low: '7.4085000000000000',
      close: '7.8033000000000000',
      volume: '7419454.2600000000000000',
      quoteVolume: 57010133.853312016,
      btcVolume: 5107.431794695363,
      usdVolume: 57010133.853312016,
      time: '2020-07-31T00:00:00.000Z'
      },
      {
      open: '7.8025000000000000',
      high: '8.4720000000000000',
      low: '7.6373000000000000',
      close: '8.3248000000000000',
      volume: '9345076.4000000000000000',
      quoteVolume: 74293026.60700549,
      btcVolume: 6369.51346066485,
      usdVolume: 74293026.60700549,
      time: '2020-08-01T00:00:00.000Z'
      },
      {
      open: '8.3216000000000000',
      high: '8.7972000000000000',
      low: '6.8700000000000000',
      close: '8.2814000000000000',
      volume: '22647614.3400000000000000',
      quoteVolume: 187308467.6520541,
      btcVolume: 16618.54057221337,
      usdVolume: 187308467.6520541,
      time: '2020-08-02T00:00:00.000Z'
      },
      {
      open: '8.2851000000000000',
      high: '9.2707000000000000',
      low: '8.1408000000000000',
      close: '9.2502000000000000',
      volume: '12805982.5400000000000000',
      quoteVolume: 110080414.61075139,
      btcVolume: 9792.377399999354,
      usdVolume: 110080414.61075139,
      time: '2020-08-03T00:00:00.000Z'
      },
      {
      open: '9.2502000000000000',
      high: '9.9645000000000000',
      low: '9.0807000000000000',
      close: '9.8687000000000000',
      volume: '18460200.9900000000000000',
      quoteVolume: 175203350.75301245,
      btcVolume: 15588.926012531812,
      usdVolume: 175203350.75301245,
      time: '2020-08-04T00:00:00.000Z'
      },
      {
      open: '9.8686000000000000',
      high: '9.8686000000000000',
      low: '9.3207000000000000',
      close: '9.5253000000000000',
      volume: '9682088.7700000000000000',
      quoteVolume: 92516426.18121561,
      btcVolume: 8087.5667816474515,
      usdVolume: 92516426.18121561,
      time: '2020-08-05T00:00:00.000Z'
      },
      {
      open: '9.5265000000000000',
      high: '10.2917000000000000',
      low: '9.4515000000000000',
      close: '10.2343000000000000',
      volume: '9041552.6000000000000000',
      quoteVolume: 88144051.28129894,
      btcVolume: 7505.910950203218,
      usdVolume: 88144051.28129894,
      time: '2020-08-06T00:00:00.000Z'
      },
      {
      open: '10.2284000000000000',
      high: '10.2615000000000000',
      low: '9.0500000000000000',
      close: '10.1286000000000000',
      volume: '11733225.3400000000000000',
      quoteVolume: 115568472.9989462,
      btcVolume: 9912.375472640897,
      usdVolume: 115568472.9989462,
      time: '2020-08-07T00:00:00.000Z'
      },
      {
      open: '10.1286000000000000',
      high: '13.8799000000000000',
      low: '9.9287000000000000',
      close: '12.7093000000000000',
      volume: '28542792.8400000000000000',
      quoteVolume: 342944887.19930977,
      btcVolume: 29343.8183945227,
      usdVolume: 342944887.19930977,
      time: '2020-08-08T00:00:00.000Z'
      },
      {
      open: '12.7095000000000000',
      high: '14.4586000000000000',
      low: '12.0000000000000000',
      close: '13.8104000000000000',
      volume: '28201686.3100000000000000',
      quoteVolume: 376769344.70101273,
      btcVolume: 32361.99931890226,
      usdVolume: 376769344.70101273,
      time: '2020-08-09T00:00:00.000Z'
      },
      {
      open: '13.8105000000000000',
      high: '14.0551000000000000',
      low: '12.5200000000000000',
      close: '13.4722000000000000',
      volume: '17910767.8200000000000000',
      quoteVolume: 240411392.94454074,
      btcVolume: 20238.132402655556,
      usdVolume: 240411392.94454074,
      time: '2020-08-10T00:00:00.000Z'
      },
      {
      open: '13.4722000000000000',
      high: '13.5403000000000000',
      low: '12.0905000000000000',
      close: '12.9971000000000000',
      volume: '17514622.4200000000000000',
      quoteVolume: 225363799.55548632,
      btcVolume: 19493.751301956687,
      usdVolume: 225363799.55548632,
      time: '2020-08-11T00:00:00.000Z'
      },
      {
      open: '12.9999000000000000',
      high: '16.8000000000000000',
      low: '12.5936000000000000',
      close: '16.4635000000000000',
      volume: '23113249.4000000000000000',
      quoteVolume: 342110085.2548909,
      btcVolume: 29740.856429109834,
      usdVolume: 342110085.2548909,
      time: '2020-08-12T00:00:00.000Z'
      },
      {
      open: '16.4634000000000000',
      high: '18.3488000000000000',
      low: '15.9175000000000000',
      close: '17.1883000000000000',
      volume: '30934675.5500000000000000',
      quoteVolume: 527151580.26604337,
      btcVolume: 45706.95238937064,
      usdVolume: 527151580.26604337,
      time: '2020-08-13T00:00:00.000Z'
      },
      {
      open: '17.1728000000000000',
      high: '17.5998000000000000',
      low: '16.3000000000000000',
      close: '16.9574000000000000',
      volume: '11109883.8400000000000000',
      quoteVolume: 188010113.5259645,
      btcVolume: 16024.502767051485,
      usdVolume: 188010113.5259645,
      time: '2020-08-14T00:00:00.000Z'
      },
      {
      open: '16.9447000000000000',
      high: '19.8303000000000000',
      low: '16.5554000000000000',
      close: '19.2285000000000000',
      volume: '17941112.0100000000000000',
      quoteVolume: 330894146.757813,
      btcVolume: 27921.28371563732,
      usdVolume: 330894146.757813,
      time: '2020-08-15T00:00:00.000Z'
      },
      {
      open: '19.2108000000000000',
      high: '20.1111000000000000',
      low: '18.5144000000000000',
      close: '18.8061000000000000',
      volume: '10757840.9900000000000000',
      quoteVolume: 206509366.21838295,
      btcVolume: 17444.9116283756,
      usdVolume: 206509366.21838295,
      time: '2020-08-16T00:00:00.000Z'
      },
      {
      open: '18.8096000000000000',
      high: '19.7111000000000000',
      low: '15.5000000000000000',
      close: '16.8196000000000000',
      volume: '13542369.7900000000000000',
      quoteVolume: 248379200.46697778,
      btcVolume: 20504.962867936094,
      usdVolume: 248379200.46697778,
      time: '2020-08-17T00:00:00.000Z'
      },
      {
      open: '16.8064000000000000',
      high: '17.6738000000000000',
      low: '14.6900000000000000',
      close: '16.1927000000000000',
      volume: '27088057.7600000000000000',
      quoteVolume: 445332598.9067832,
      btcVolume: 36628.32089159984,
      usdVolume: 445332598.9067832,
      time: '2020-08-18T00:00:00.000Z'
      },
      {
      open: '16.1846000000000000',
      high: '17.2554000000000000',
      low: '14.8926000000000000',
      close: '16.1863000000000000',
      volume: '18358817.9100000000000000',
      quoteVolume: 297260255.6285172,
      btcVolume: 25207.258992200754,
      usdVolume: 297260255.6285172,
      time: '2020-08-19T00:00:00.000Z'
      },
      {
      open: '16.1884000000000000',
      high: '16.7862000000000000',
      low: '15.7232000000000000',
      close: '16.0483000000000000',
      volume: '11011575.0900000000000000',
      quoteVolume: 179321055.27822992,
      btcVolume: 15196.345359291849,
      usdVolume: 179321055.27822992,
      time: '2020-08-20T00:00:00.000Z'
      },
      {
      open: '16.0476000000000000',
      high: '16.1421000000000000',
      low: '12.8900000000000000',
      close: '13.8325000000000000',
      volume: '26139289.1300000000000000',
      quoteVolume: 376332110.8896587,
      btcVolume: 32120.329513780653,
      usdVolume: 376332110.8896587,
      time: '2020-08-21T00:00:00.000Z'
      },
      {
      open: '13.8342000000000000',
      high: '16.3300000000000000',
      low: '13.6600000000000000',
      close: '15.8078000000000000',
      volume: '16709823.0900000000000000',
      quoteVolume: 252784283.01039815,
      btcVolume: 21872.182565912786,
      usdVolume: 252784283.01039815,
      time: '2020-08-22T00:00:00.000Z'
      },
      {
      open: '15.8085000000000000',
      high: '16.0042000000000000',
      low: '14.6536000000000000',
      close: '15.1428000000000000',
      volume: '10765015.8300000000000000',
      quoteVolume: 163456993.92262277,
      btcVolume: 14082.112398712841,
      usdVolume: 163456993.92262277,
      time: '2020-08-23T00:00:00.000Z'
      },
      {
      open: '15.1572000000000000',
      high: '15.8797000000000000',
      low: '14.7000000000000000',
      close: '15.1419000000000000',
      volume: '8454928.0700000000000000',
      quoteVolume: 129187194.20472585,
      btcVolume: 11012.764821572282,
      usdVolume: 129187194.20472585,
      time: '2020-08-24T00:00:00.000Z'
      },
      {
      open: '15.1363000000000000',
      high: '15.3743000000000000',
      low: '13.4578000000000000',
      close: '14.1164000000000000',
      volume: '11988432.8700000000000000',
      quoteVolume: 173378967.9258536,
      btcVolume: 15132.48523268232,
      usdVolume: 173378967.9258536,
      time: '2020-08-25T00:00:00.000Z'
      },
      {
      open: '14.1171000000000000',
      high: '15.6664000000000000',
      low: '14.0537000000000000',
      close: '15.2000000000000000',
      volume: '11415333.4100000000000000',
      quoteVolume: 169326699.33486208,
      btcVolume: 14838.375991579402,
      usdVolume: 169326699.33486208,
      time: '2020-08-26T00:00:00.000Z'
      },
      {
      open: '15.2088000000000000',
      high: '15.5215000000000000',
      low: '13.9679000000000000',
      close: '14.5234000000000000',
      volume: '11685135.1900000000000000',
      quoteVolume: 173265514.72794762,
      btcVolume: 15276.438417623027,
      usdVolume: 173265514.72794762,
      time: '2020-08-27T00:00:00.000Z'
      },
      {
      open: '14.5234000000000000',
      high: '15.4989000000000000',
      low: '14.2360000000000000',
      close: '15.1207000000000000',
      volume: '7108908.2300000000000000',
      quoteVolume: 106217471.48299323,
      btcVolume: 9283.514516201361,
      usdVolume: 106215608.60329323,
      time: '2020-08-28T00:00:00.000Z'
      },
      {
      open: '15.1136000000000000',
      high: '17.0305000000000000',
      low: '14.9565000000000000',
      close: '16.4273000000000000',
      volume: '11734386.8700000000000000',
      quoteVolume: 189641391.5824856,
      btcVolume: 16492.39931867633,
      usdVolume: 189641391.5824856,
      time: '2020-08-29T00:00:00.000Z'
      },
      {
      open: '16.4537000000000000',
      high: '17.7777000000000000',
      low: '16.4037000000000000',
      close: '16.4788000000000000',
      volume: '9731994.3200000000000000',
      quoteVolume: 166124406.2667331,
      btcVolume: 14311.349188271188,
      usdVolume: 166124406.2667331,
      time: '2020-08-30T00:00:00.000Z'
      },
      {
      open: '16.4782000000000000',
      high: '16.9495000000000000',
      low: '15.4451000000000000',
      close: '15.5772000000000000',
      volume: '9615963.2700000000000000',
      quoteVolume: 157331308.63249928,
      btcVolume: 13475.644648985366,
      usdVolume: 157331308.63249928,
      time: '2020-08-31T00:00:00.000Z'
      },
      {
      open: '15.5746000000000000',
      high: '16.5053000000000000',
      low: '15.0629000000000000',
      close: '16.0545000000000000',
      volume: '7793462.3200000000000000',
      quoteVolume: 123969155.38132185,
      btcVolume: 10492.616965270177,
      usdVolume: 123958598.20392784,
      time: '2020-09-01T00:00:00.000Z'
      },
      {
      open: '16.2072000000000000',
      high: '16.3587000000000000',
      low: '14.0000000000000000',
      close: '14.7982000000000000',
      volume: '10301348.0000000000000000',
      quoteVolume: 154640593.96041393,
      btcVolume: 13465.979576934247,
      usdVolume: 154640593.96041393,
      time: '2020-09-02T00:00:00.000Z'
      },
      {
      open: '14.7922000000000000',
      high: '14.9989000000000000',
      low: '10.8261000000000000',
      close: '11.8895000000000000',
      volume: '16949856.4800000000000000',
      quoteVolume: 227014586.31002295,
      btcVolume: 20804.680530088768,
      usdVolume: 227014586.31002295,
      time: '2020-09-03T00:00:00.000Z'
      },
      {
      open: '11.8802000000000000',
      high: '12.9040000000000000',
      low: '11.0000000000000000',
      close: '12.3708000000000000',
      volume: '18612095.2100000000000000',
      quoteVolume: 225971603.75573367,
      btcVolume: 21814.154412869015,
      usdVolume: 225971603.75573367,
      time: '2020-09-04T00:00:00.000Z'
      },
      {
      open: '12.3804000000000000',
      high: '12.7655000000000000',
      low: '9.1000000000000000',
      close: '10.6017000000000000',
      volume: '25511608.4800000000000000',
      quoteVolume: 278344840.137839,
      btcVolume: 27443.663519385616,
      usdVolume: 278344840.137839,
      time: '2020-09-05T00:00:00.000Z'
      },
      {
      open: '10.6087000000000000',
      high: '13.2400000000000000',
      low: '9.7155000000000000',
      close: '12.8044000000000000',
      volume: '21811584.7900000000000000',
      quoteVolume: 254940172.73275283,
      btcVolume: 24977.6005690715,
      usdVolume: 254940172.73275283,
      time: '2020-09-06T00:00:00.000Z'
      },
      {
      open: '12.8060000000000000',
      high: '13.1747000000000000',
      low: '11.1700000000000000',
      close: '12.4700000000000000',
      volume: '20515580.9600000000000000',
      quoteVolume: 250103840.5173619,
      btcVolume: 24710.405343979466,
      usdVolume: 250103840.5173619,
      time: '2020-09-07T00:00:00.000Z'
      },
      {
      open: '12.4744000000000000',
      high: '12.7362000000000000',
      low: '11.0284000000000000',
      close: '11.7460000000000000',
      volume: '14832129.8100000000000000',
      quoteVolume: 176444797.96903428,
      btcVolume: 17428.664020195858,
      usdVolume: 176444797.96903428,
      time: '2020-09-08T00:00:00.000Z'
      },
      {
      open: '11.7359000000000000',
      high: '12.6566000000000000',
      low: '11.4356000000000000',
      close: '12.3444000000000000',
      volume: '10649791.4300000000000000',
      quoteVolume: 129646130.9405381,
      btcVolume: 12725.251017309938,
      usdVolume: 129646130.9405381,
      time: '2020-09-09T00:00:00.000Z'
      },
      {
      open: '12.3452000000000000',
      high: '13.2800000000000000',
      low: '12.1800000000000000',
      close: '12.4957000000000000',
      volume: '14928167.2000000000000000',
      quoteVolume: 190985359.03175014,
      btcVolume: 18462.71704639154,
      usdVolume: 190985359.03175014,
      time: '2020-09-10T00:00:00.000Z'
      },
      {
      open: '12.4948000000000000',
      high: '12.6960000000000000',
      low: '12.0190000000000000',
      close: '12.4406000000000000',
      volume: '7498865.1600000000000000',
      quoteVolume: 92935019.6125966,
      btcVolume: 9026.605245547558,
      usdVolume: 92935019.6125966,
      time: '2020-09-11T00:00:00.000Z'
      },
      {
      open: '12.4406000000000000',
      high: '12.7470000000000000',
      low: '12.0934000000000000',
      close: '12.7187000000000000',
      volume: '6269794.6100000000000000',
      quoteVolume: 77882381.38087636,
      btcVolume: 7509.491501095776,
      usdVolume: 77882381.38087636,
      time: '2020-09-12T00:00:00.000Z'
      },
      {
      open: '12.7194000000000000',
      high: '13.2300000000000000',
      low: '11.7658000000000000',
      close: '12.0670000000000000',
      volume: '11107099.4800000000000000',
      quoteVolume: 138433490.7795642,
      btcVolume: 13317.710404478297,
      usdVolume: 138433490.7795642,
      time: '2020-09-13T00:00:00.000Z'
      },
      {
      open: '12.0757000000000000',
      high: '12.3863000000000000',
      low: '11.6349000000000000',
      close: '12.1374000000000000',
      volume: '8830940.1600000000000000',
      quoteVolume: 106057143.09535801,
      btcVolume: 10093.04992993571,
      usdVolume: 106057143.09535801,
      time: '2020-09-14T00:00:00.000Z'
      },
      {
      open: '12.1300000000000000',
      high: '12.2400000000000000',
      low: '10.7500000000000000',
      close: '10.9314000000000000',
      volume: '11413633.6300000000000000',
      quoteVolume: 132790893.20361203,
      btcVolume: 12320.461229483713,
      usdVolume: 132790893.20361203,
      time: '2020-09-15T00:00:00.000Z'
      },
      {
      open: '10.9301000000000000',
      high: '11.3002000000000000',
      low: '10.4771000000000000',
      close: '10.7367000000000000',
      volume: '10362445.0800000000000000',
      quoteVolume: 112764173.2894766,
      btcVolume: 10353.892720724712,
      usdVolume: 112764173.2894766,
      time: '2020-09-16T00:00:00.000Z'
      },
      {
      open: '10.7367000000000000',
      high: '11.6759000000000000',
      low: '10.4720000000000000',
      close: '11.0139000000000000',
      volume: '10163046.4600000000000000',
      quoteVolume: 111006019.31385103,
      btcVolume: 10179.558912072785,
      usdVolume: 111006019.31385103,
      time: '2020-09-17T00:00:00.000Z'
      },
      {
      open: '11.0139000000000000',
      high: '11.1315000000000000',
      low: '9.7863000000000000',
      close: '10.0940000000000000',
      volume: '8890206.0700000000000000',
      quoteVolume: 93387663.390679,
      btcVolume: 8561.061779425265,
      usdVolume: 93387663.390679,
      time: '2020-09-18T00:00:00.000Z'
      },
      {
      open: '10.0938000000000000',
      high: '10.4969000000000000',
      low: '9.8756000000000000',
      close: '10.1209000000000000',
      volume: '5596280.8000000000000000',
      quoteVolume: 56998511.47489919,
      btcVolume: 5177.969389632715,
      usdVolume: 56998511.47489919,
      time: '2020-09-19T00:00:00.000Z'
      },
      {
      open: '10.1192000000000000',
      high: '10.6199000000000000',
      low: '9.5000000000000000',
      close: '9.7357000000000000',
      volume: '7410739.4400000000000000',
      quoteVolume: 74829506.00962031,
      btcVolume: 6862.181652542345,
      usdVolume: 74829506.00962031,
      time: '2020-09-20T00:00:00.000Z'
      },
      {
      open: '9.7394000000000000',
      high: '9.9235000000000000',
      low: '8.5012000000000000',
      close: '8.7114000000000000',
      volume: '15761415.8500000000000000',
      quoteVolume: 141463825.6035975,
      btcVolume: 13312.026406212963,
      usdVolume: 141463825.6035975,
      time: '2020-09-21T00:00:00.000Z'
      },
      {
      open: '8.7162000000000000',
      high: '9.0800000000000000',
      low: '8.5400000000000000',
      close: '8.7401000000000000',
      volume: '8074342.9400000000000000',
      quoteVolume: 71284015.95208645,
      btcVolume: 6811.9002965444815,
      usdVolume: 71284015.95208645,
      time: '2020-09-22T00:00:00.000Z'
      },
      {
      open: '8.7440000000000000',
      high: '8.8016000000000000',
      low: '7.2869000000000000',
      close: '7.6364000000000000',
      volume: '18221454.9700000000000000',
      quoteVolume: 146298886.40303153,
      btcVolume: 14105.940252148712,
      usdVolume: 146298886.40303153,
      time: '2020-09-23T00:00:00.000Z'
      },
      {
      open: '7.6404000000000000',
      high: '10.5300000000000000',
      low: '7.4761000000000000',
      close: '9.8700000000000000',
      volume: '26877668.8100000000000000',
      quoteVolume: 243924037.01423475,
      btcVolume: 23200.606802237573,
      usdVolume: 243924037.01423475,
      time: '2020-09-24T00:00:00.000Z'
      },
      {
      open: '9.8701000000000000',
      high: '11.1990000000000000',
      low: '9.3771000000000000',
      close: '10.7279000000000000',
      volume: '20220142.0700000000000000',
      quoteVolume: 206454816.90600815,
      btcVolume: 19347.190816472226,
      usdVolume: 206454816.90600815,
      time: '2020-09-25T00:00:00.000Z'
      },
      {
      open: '10.7218000000000000',
      high: '11.1000000000000000',
      low: '9.9757000000000000',
      close: '10.3169000000000000',
      volume: '12019763.1700000000000000',
      quoteVolume: 125899152.91074796,
      btcVolume: 11759.471660591937,
      usdVolume: 125899152.91074796,
      time: '2020-09-26T00:00:00.000Z'
      },
      {
      open: '10.3079000000000000',
      high: '10.9910000000000000',
      low: '9.9122000000000000',
      close: '10.7939000000000000',
      volume: '10005871.9800000000000000',
      quoteVolume: 104553700.74577622,
      btcVolume: 9756.633696493238,
      usdVolume: 104553700.74577622,
      time: '2020-09-27T00:00:00.000Z'
      },
      {
      open: '10.8000000000000000',
      high: '11.0700000000000000',
      low: '10.1500000000000000',
      close: '10.2830000000000000',
      volume: '8720119.6800000000000000',
      quoteVolume: 92733132.97270194,
      btcVolume: 8531.552193163896,
      usdVolume: 92733132.97270194,
      time: '2020-09-28T00:00:00.000Z'
      },
      {
      open: '10.2809000000000000',
      high: '10.3882000000000000',
      low: '9.4502000000000000',
      close: '10.1350000000000000',
      volume: '13371311.3100000000000000',
      quoteVolume: 133299972.25986037,
      btcVolume: 12416.99662418838,
      usdVolume: 133299972.25986037,
      time: '2020-09-29T00:00:00.000Z'
      },
      {
      open: '10.1356000000000000',
      high: '10.2314000000000000',
      low: '9.5768000000000000',
      close: '9.8694000000000000',
      volume: '6624754.2600000000000000',
      quoteVolume: 65302473.930610836,
      btcVolume: 6075.231257322131,
      usdVolume: 65302473.930610836,
      time: '2020-09-30T00:00:00.000Z'
      },
      {
      open: '9.8727000000000000',
      high: '10.4119000000000000',
      low: '9.2457000000000000',
      close: '9.6394000000000000',
      volume: '9437903.4200000000000000',
      quoteVolume: 93289422.56827107,
      btcVolume: 8711.5733196697,
      usdVolume: 93289422.56827107,
      time: '2020-10-01T00:00:00.000Z'
      },
      {
      open: '9.6394000000000000',
      high: '9.8449000000000000',
      low: '8.7975000000000000',
      close: '9.2167000000000000',
      volume: '11050283.7900000000000000',
      quoteVolume: 101613627.94135539,
      btcVolume: 9676.160636390727,
      usdVolume: 101613627.94135539,
      time: '2020-10-02T00:00:00.000Z'
      },
      {
      open: '9.2132000000000000',
      high: '9.5555000000000000',
      low: '9.1040000000000000',
      close: '9.2690000000000000',
      volume: '4803593.0900000000000000',
      quoteVolume: 44581852.41136064,
      btcVolume: 4225.164872066804,
      usdVolume: 44581852.41136064,
      time: '2020-10-03T00:00:00.000Z'
      },
      {
      open: '9.2686000000000000',
      high: '9.4660000000000000',
      low: '9.1141000000000000',
      close: '9.3922000000000000',
      volume: '4665626.6900000000000000',
      quoteVolume: 43259192.53149099,
      btcVolume: 4079.3745493298234,
      usdVolume: 43259192.53149099,
      time: '2020-10-04T00:00:00.000Z'
      },
      {
      open: '9.3906000000000000',
      high: '9.7200000000000000',
      low: '9.2629000000000000',
      close: '9.6176000000000000',
      volume: '6205749.7700000000000000',
      quoteVolume: 58892347.278092586,
      btcVolume: 5503.330432382905,
      usdVolume: 58892347.278092586,
      time: '2020-10-05T00:00:00.000Z'
      },
      {
      open: '9.6134000000000000',
      high: '9.6580000000000000',
      low: '8.5630000000000000',
      close: '8.7689000000000000',
      volume: '7422770.6500000000000000',
      quoteVolume: 67706985.53549488,
      btcVolume: 6342.859632868126,
      usdVolume: 67706985.53549488,
      time: '2020-10-06T00:00:00.000Z'
      },
      {
      open: '8.7690000000000000',
      high: '9.0100000000000000',
      low: '8.3817000000000000',
      close: '8.9799000000000000',
      volume: '7536611.8700000000000000',
      quoteVolume: 65993355.663363345,
      btcVolume: 6218.463186844811,
      usdVolume: 65993355.663363345,
      time: '2020-10-07T00:00:00.000Z'
      },
      {
      open: '8.9801000000000000',
      high: '9.6829000000000000',
      low: '8.4016000000000000',
      close: '9.5594000000000000',
      volume: '10110727.7500000000000000',
      quoteVolume: 91351206.53641662,
      btcVolume: 8488.116029639972,
      usdVolume: 91351206.53641662,
      time: '2020-10-08T00:00:00.000Z'
      },
      {
      open: '9.5519000000000000',
      high: '10.5254000000000000',
      low: '9.3701000000000000',
      close: '10.3959000000000000',
      volume: '11276147.4500000000000000',
      quoteVolume: 112848361.62231602,
      btcVolume: 10272.349870485246,
      usdVolume: 112848361.62231602,
      time: '2020-10-09T00:00:00.000Z'
      },
      {
      open: '10.3923000000000000',
      high: '10.9200000000000000',
      low: '10.3700000000000000',
      close: '10.5198000000000000',
      volume: '9504046.9700000000000000',
      quoteVolume: 100981242.65024652,
      btcVolume: 8918.134330290197,
      usdVolume: 100981242.65024652,
      time: '2020-10-10T00:00:00.000Z'
      },
      {
      open: '10.5168000000000000',
      high: '11.0258000000000000',
      low: '10.1500000000000000',
      close: '10.8440000000000000',
      volume: '8011810.1500000000000000',
      quoteVolume: 85068557.21714121,
      btcVolume: 7485.698997274424,
      usdVolume: 85068557.21714121,
      time: '2020-10-11T00:00:00.000Z'
      },
      {
      open: '10.8441000000000000',
      high: '11.8028000000000000',
      low: '10.6807000000000000',
      close: '11.4242000000000000',
      volume: '9262541.8700000000000000',
      quoteVolume: 104346545.63303679,
      btcVolume: 9109.599515130829,
      usdVolume: 104346545.63303679,
      time: '2020-10-12T00:00:00.000Z'
      },
      {
      open: '11.4599000000000000',
      high: '11.5520000000000000',
      low: '10.6592000000000000',
      close: '11.0016000000000000',
      volume: '7617422.8600000000000000',
      quoteVolume: 85252142.00798237,
      btcVolume: 7456.401573669332,
      usdVolume: 85252142.00798237,
      time: '2020-10-13T00:00:00.000Z'
      },
      {
      open: '11.0016000000000000',
      high: '11.3536000000000000',
      low: '10.6201000000000000',
      close: '10.8896000000000000',
      volume: '6061485.9500000000000000',
      quoteVolume: 66687126.90002584,
      btcVolume: 5850.816052446876,
      usdVolume: 66687126.90002584,
      time: '2020-10-14T00:00:00.000Z'
      },
      {
      open: '10.8896000000000000',
      high: '10.9317000000000000',
      low: '10.4550000000000000',
      close: '10.7483000000000000',
      volume: '6908169.7600000000000000',
      quoteVolume: 73900491.87788318,
      btcVolume: 6486.307989052753,
      usdVolume: 73900491.87788318,
      time: '2020-10-15T00:00:00.000Z'
      },
      {
      open: '10.7472000000000000',
      high: '10.8935000000000000',
      low: '10.2694000000000000',
      close: '10.5877000000000000',
      volume: '6662666.8600000000000000',
      quoteVolume: 70509800.96815944,
      btcVolume: 6215.660703350463,
      usdVolume: 70509800.96815944,
      time: '2020-10-16T00:00:00.000Z'
      },
      {
      open: '10.5867000000000000',
      high: '10.7149000000000000',
      low: '10.4082000000000000',
      close: '10.6267000000000000',
      volume: '3320266.0600000000000000',
      quoteVolume: 35074078.772476934,
      btcVolume: 3094.4010233917893,
      usdVolume: 35074078.772476934,
      time: '2020-10-17T00:00:00.000Z'
      },
      {
      open: '10.6254000000000000',
      high: '11.0000000000000000',
      low: '10.6000000000000000',
      close: '10.9409000000000000',
      volume: '3679623.9400000000000000',
      quoteVolume: 39786628.52001377,
      btcVolume: 3482.0203876379023,
      usdVolume: 39786628.52001377,
      time: '2020-10-18T00:00:00.000Z'
      },
      {
      open: '10.9435000000000000',
      high: '11.2226000000000000',
      low: '10.6510000000000000',
      close: '10.9147000000000000',
      volume: '4474849.3100000000000000',
      quoteVolume: 48718810.825249076,
      btcVolume: 4198.544817946333,
      usdVolume: 48718810.825249076,
      time: '2020-10-19T00:00:00.000Z'
      },
      {
      open: '10.9116000000000000',
      high: '10.9349000000000000',
      low: '9.7665000000000000',
      close: '9.8589000000000000',
      volume: '7095267.1100000000000000',
      quoteVolume: 73344149.05086827,
      btcVolume: 6182.229702645229,
      usdVolume: 73344149.05086827,
      time: '2020-10-20T00:00:00.000Z'
      },
      {
      open: '9.8589000000000000',
      high: '11.1372000000000000',
      low: '9.7864000000000000',
      close: '10.7863000000000000',
      volume: '8276637.4200000000000000',
      quoteVolume: 87959927.25448503,
      btcVolume: 7031.471299457609,
      usdVolume: 87959927.25448503,
      time: '2020-10-21T00:00:00.000Z'
      },
      {
      open: '10.7805000000000000',
      high: '12.4173000000000000',
      low: '10.7142000000000000',
      close: '11.9539000000000000',
      volume: '10301801.8300000000000000',
      quoteVolume: 120278047.81922631,
      btcVolume: 9287.505769123953,
      usdVolume: 120278047.81922631,
      time: '2020-10-22T00:00:00.000Z'
      },
      {
      open: '11.9551000000000000',
      high: '12.2640000000000000',
      low: '11.5784000000000000',
      close: '11.7869000000000000',
      volume: '6599326.7100000000000000',
      quoteVolume: 78921676.86675517,
      btcVolume: 6109.358731500419,
      usdVolume: 78921676.86675517,
      time: '2020-10-23T00:00:00.000Z'
      },
      {
      open: '12.1592000000000000',
      high: '12.9500000000000000',
      low: '11.9839000000000000',
      close: '12.7001000000000000',
      volume: '7531584.3600000000000000',
      quoteVolume: 94612367.21991429,
      btcVolume: 7266.641304869805,
      usdVolume: 94612367.21991429,
      time: '2020-10-24T00:00:00.000Z'
      },
      {
      open: '12.7002000000000000',
      high: '12.8574000000000000',
      low: '11.9080000000000000',
      close: '12.1481000000000000',
      volume: '6996448.0600000000000000',
      quoteVolume: 86499461.98964313,
      btcVolume: 6638.643976139153,
      usdVolume: 86499461.98964313,
      time: '2020-10-25T00:00:00.000Z'
      },
      {
      open: '12.1480000000000000',
      high: '12.4472000000000000',
      low: '11.2594000000000000',
      close: '11.7096000000000000',
      volume: '8831828.5200000000000000',
      quoteVolume: 104763458.12184793,
      btcVolume: 8038.602388420682,
      usdVolume: 104763458.12184793,
      time: '2020-10-26T00:00:00.000Z'
      },
      {
      open: '11.7086000000000000',
      high: '12.4774000000000000',
      low: '11.4900000000000000',
      close: '11.9224000000000000',
      volume: '7598237.9300000000000000',
      quoteVolume: 91297276.70568031,
      btcVolume: 6804.543512604595,
      usdVolume: 91297276.70568031,
      time: '2020-10-27T00:00:00.000Z'
      },
      {
      open: '11.9210000000000000',
      high: '12.1509000000000000',
      low: '11.1500000000000000',
      close: '11.4403000000000000',
      volume: '6787355.3400000000000000',
      quoteVolume: 78579989.27577649,
      btcVolume: 5868.232165162447,
      usdVolume: 78579989.27577649,
      time: '2020-10-28T00:00:00.000Z'
      },
      {
      open: '11.4448000000000000',
      high: '11.8100000000000000',
      low: '11.0816000000000000',
      close: '11.2772000000000000',
      volume: '8541237.8300000000000000',
      quoteVolume: 97758547.62588826,
      btcVolume: 7336.005858513019,
      usdVolume: 97758547.62588826,
      time: '2020-10-29T00:00:00.000Z'
      },
      {
      open: '11.2743000000000000',
      high: '11.4811000000000000',
      low: '10.7200000000000000',
      close: '11.0900000000000000',
      volume: '7958701.4600000000000000',
      quoteVolume: 87936295.50341521,
      btcVolume: 6567.679161107949,
      usdVolume: 87936295.50341521,
      time: '2020-10-30T00:00:00.000Z'
      },
      {
      open: '11.0947000000000000',
      high: '11.3981000000000000',
      low: '10.9053000000000000',
      close: '11.2277000000000000',
      volume: '4822720.4700000000000000',
      quoteVolume: 53963475.40082074,
      btcVolume: 3926.032199920614,
      usdVolume: 53963475.40082074,
      time: '2020-10-31T00:00:00.000Z'
      },
      {
      open: '11.2259000000000000',
      high: '11.5885000000000000',
      low: '11.0181000000000000',
      close: '11.5722000000000000',
      volume: '3872679.9900000000000000',
      quoteVolume: 43600203.22140587,
      btcVolume: 3168.731592931039,
      usdVolume: 43600203.22140587,
      time: '2020-11-01T00:00:00.000Z'
      },
      {
      open: '11.5681000000000000',
      high: '11.6850000000000000',
      low: '10.7049000000000000',
      close: '10.7814000000000000',
      volume: '6919050.6800000000000000',
      quoteVolume: 77130468.23187579,
      btcVolume: 5694.891968008859,
      usdVolume: 77130468.23187579,
      time: '2020-11-02T00:00:00.000Z'
      },
      {
      open: '10.7814000000000000',
      high: '10.7814000000000000',
      low: '9.9454000000000000',
      close: '10.3911000000000000',
      volume: '9925376.6400000000000000',
      quoteVolume: 102949946.71757828,
      btcVolume: 7589.417998761801,
      usdVolume: 102949946.71757828,
      time: '2020-11-03T00:00:00.000Z'
      },
      {
      open: '10.3982000000000000',
      high: '10.6750000000000000',
      low: '9.7500000000000000',
      close: '10.4713000000000000',
      volume: '8586603.9100000000000000',
      quoteVolume: 87954953.98638126,
      btcVolume: 6320.607986664108,
      usdVolume: 87954953.98638126,
      time: '2020-11-04T00:00:00.000Z'
      },
      {
      open: '10.4730000000000000',
      high: '11.2749000000000000',
      low: '10.0764000000000000',
      close: '10.9794000000000000',
      volume: '10616943.9300000000000000',
      quoteVolume: 113756787.2898385,
      btcVolume: 7651.513480243185,
      usdVolume: 113756787.2898385,
      time: '2020-11-05T00:00:00.000Z'
      },
      {
      open: '10.9822000000000000',
      high: '12.2813000000000000',
      low: '10.9286000000000000',
      close: '12.1687000000000000',
      volume: '10613986.0500000000000000',
      quoteVolume: 123261012.0719206,
      btcVolume: 7916.228371545654,
      usdVolume: 123261012.0719206,
      time: '2020-11-06T00:00:00.000Z'
      },
      {
      open: '12.1686000000000000',
      high: '13.5956000000000000',
      low: '11.3123000000000000',
      close: '11.7638000000000000',
      volume: '24836053.4300000000000000',
      quoteVolume: 312825764.5672563,
      btcVolume: 20466.466375561587,
      usdVolume: 312825764.5672563,
      time: '2020-11-07T00:00:00.000Z'
      },
      {
      open: '11.7668000000000000',
      high: '13.1000000000000000',
      low: '11.4770000000000000',
      close: '12.7100000000000000',
      volume: '12895124.5400000000000000',
      quoteVolume: 159621589.8883934,
      btcVolume: 10501.569151809028,
      usdVolume: 159621589.8883934,
      time: '2020-11-08T00:00:00.000Z'
      },
      {
      open: '12.7083000000000000',
      high: '13.0026000000000000',
      low: '12.0400000000000000',
      close: '12.5107000000000000',
      volume: '12634927.8300000000000000',
      quoteVolume: 157868780.3591942,
      btcVolume: 10278.693917702634,
      usdVolume: 157868780.3591942,
      time: '2020-11-09T00:00:00.000Z'
      },
      {
      open: '12.5182000000000000',
      high: '13.2780000000000000',
      low: '12.3156000000000000',
      close: '13.0017000000000000',
      volume: '12313102.0800000000000000',
      quoteVolume: 158472628.9918838,
      btcVolume: 10350.422830750087,
      usdVolume: 158472628.9918838,
      time: '2020-11-10T00:00:00.000Z'
      },
      {
      open: '13.0017000000000000',
      high: '13.4293000000000000',
      low: '12.7500000000000000',
      close: '12.7530000000000000',
      volume: '9402996.4400000000000000',
      quoteVolume: 123227278.88061701,
      btcVolume: 7903.41235731508,
      usdVolume: 123227278.88061701,
      time: '2020-11-11T00:00:00.000Z'
      },
      {
      open: '12.7530000000000000',
      high: '13.0956000000000000',
      low: '12.2700000000000000',
      close: '12.4038000000000000',
      volume: '8887319.0400000000000000',
      quoteVolume: 112105873.89800362,
      btcVolume: 7056.930684473489,
      usdVolume: 112105873.89800362,
      time: '2020-11-12T00:00:00.000Z'
      },
      {
      open: '12.4038000000000000',
      high: '13.0000000000000000',
      low: '12.3079000000000000',
      close: '12.8687000000000000',
      volume: '7183843.8700000000000000',
      quoteVolume: 91486357.29356198,
      btcVolume: 5621.798860970854,
      usdVolume: 91486357.29356198,
      time: '2020-11-13T00:00:00.000Z'
      },
      {
      open: '12.8679000000000000',
      high: '12.9335000000000000',
      low: '12.3000000000000000',
      close: '12.5574000000000000',
      volume: '4779543.0800000000000000',
      quoteVolume: 60108858.82407817,
      btcVolume: 3755.1233582281616,
      usdVolume: 60108858.82407817,
      time: '2020-11-14T00:00:00.000Z'
      },
      {
      open: '12.5570000000000000',
      high: '12.6484000000000000',
      low: '11.8156000000000000',
      close: '12.1192000000000000',
      volume: '5705889.4900000000000000',
      quoteVolume: 70141538.75487132,
      btcVolume: 4393.466812059062,
      usdVolume: 70141538.75487132,
      time: '2020-11-15T00:00:00.000Z'
      },
      {
      open: '12.1199000000000000',
      high: '12.8132000000000000',
      low: '11.9971000000000000',
      close: '12.6223000000000000',
      volume: '5966554.6100000000000000',
      quoteVolume: 74312723.17503132,
      btcVolume: 4538.090941158825,
      usdVolume: 74312723.17503132,
      time: '2020-11-16T00:00:00.000Z'
      },
      {
      open: '12.6204000000000000',
      high: '13.4228000000000000',
      low: '12.5414000000000000',
      close: '13.3944000000000000',
      volume: '9943527.4100000000000000',
      quoteVolume: 128513966.31700706,
      btcVolume: 7475.957078646857,
      usdVolume: 128513966.31700706,
      time: '2020-11-17T00:00:00.000Z'
      },
      {
      open: '13.3943000000000000',
      high: '14.2669000000000000',
      low: '12.5850000000000000',
      close: '13.5598000000000000',
      volume: '17994859.8100000000000000',
      quoteVolume: 243378004.2585356,
      btcVolume: 13652.72139475029,
      usdVolume: 243378004.2585356,
      time: '2020-11-18T00:00:00.000Z'
      },
      {
      open: '13.5602000000000000',
      high: '14.0000000000000000',
      low: '13.0111000000000000',
      close: '13.6187000000000000',
      volume: '9295294.8300000000000000',
      quoteVolume: 125766975.70460743,
      btcVolume: 7051.256067524977,
      usdVolume: 125766975.70460743,
      time: '2020-11-19T00:00:00.000Z'
      },
      {
      open: '13.6117000000000000',
      high: '14.4770000000000000',
      low: '13.4618000000000000',
      close: '14.0933000000000000',
      volume: '10955855.4700000000000000',
      quoteVolume: 153945485.5929507,
      btcVolume: 8393.83606216672,
      usdVolume: 153945485.5929507,
      time: '2020-11-20T00:00:00.000Z'
      },
      {
      open: '14.0913000000000000',
      high: '15.5000000000000000',
      low: '13.6876000000000000',
      close: '15.4511000000000000',
      volume: '13999538.8800000000000000',
      quoteVolume: 204973635.11923143,
      btcVolume: 10955.623567420895,
      usdVolume: 204973635.11923143,
      time: '2020-11-21T00:00:00.000Z'
      },
      {
      open: '15.4408000000000000',
      high: '15.7179000000000000',
      low: '13.8402000000000000',
      close: '14.5098000000000000',
      volume: '14244002.3200000000000000',
      quoteVolume: 209484344.0724672,
      btcVolume: 11387.241340723021,
      usdVolume: 209484344.0724672,
      time: '2020-11-22T00:00:00.000Z'
      },
      {
      open: '14.5098000000000000',
      high: '15.4250000000000000',
      low: '14.2520000000000000',
      close: '15.2746000000000000',
      volume: '12165056.4100000000000000',
      quoteVolume: 181293925.92475033,
      btcVolume: 9837.750688600954,
      usdVolume: 181293925.92475033,
      time: '2020-11-23T00:00:00.000Z'
      },
      {
      open: '15.2648000000000000',
      high: '16.3900000000000000',
      low: '14.9430000000000000',
      close: '15.6703000000000000',
      volume: '15636471.1700000000000000',
      quoteVolume: 244854909.96831247,
      btcVolume: 13082.91825525146,
      usdVolume: 244854909.96831247,
      time: '2020-11-24T00:00:00.000Z'
      },
      {
      open: '15.6637000000000000',
      high: '15.8600000000000000',
      low: '13.7900000000000000',
      close: '14.2016000000000000',
      volume: '9463367.4500000000000000',
      quoteVolume: 142275307.68800414,
      btcVolume: 7489.979662764517,
      usdVolume: 142275307.68800414,
      time: '2020-11-25T00:00:00.000Z'
      },
      {
      open: '14.2180000000000000',
      high: '14.4743000000000000',
      low: '11.2939000000000000',
      close: '12.6030000000000000',
      volume: '24581331.8400000000000000',
      quoteVolume: 307097131.8146662,
      btcVolume: 17664.03164046134,
      usdVolume: 307097131.8146662,
      time: '2020-11-26T00:00:00.000Z'
      },
      {
      open: '12.6036000000000000',
      high: '12.9530000000000000',
      low: '11.9454000000000000',
      close: '12.4989000000000000',
      volume: '9126132.6500000000000000',
      quoteVolume: 113170817.60460344,
      btcVolume: 6660.581797963793,
      usdVolume: 113170817.60460344,
      time: '2020-11-27T00:00:00.000Z'
      },
      {
      open: '12.5038000000000000',
      high: '13.4300000000000000',
      low: '12.3489000000000000',
      close: '13.0853000000000000',
      volume: '7281492.8400000000000000',
      quoteVolume: 94217840.3930073,
      btcVolume: 5414.366931727638,
      usdVolume: 94217840.3930073,
      time: '2020-11-28T00:00:00.000Z'
      },
      {
      open: '13.0848000000000000',
      high: '13.4765000000000000',
      low: '12.8408000000000000',
      close: '13.3800000000000000',
      volume: '6537927.5000000000000000',
      quoteVolume: 85931374.67872472,
      btcVolume: 4773.352624805251,
      usdVolume: 85931374.67872472,
      time: '2020-11-29T00:00:00.000Z'
      },
      {
      open: '13.3855000000000000',
      high: '14.4370000000000000',
      low: '13.3855000000000000',
      close: '14.2677000000000000',
      volume: '10253978.8400000000000000',
      quoteVolume: 143593067.98141998,
      btcVolume: 7589.521928283262,
      usdVolume: 143593067.98141998,
      time: '2020-11-30T00:00:00.000Z'
      },
      {
      open: '14.2626000000000000',
      high: '14.8328000000000000',
      low: '13.0456000000000000',
      close: '13.3461000000000000',
      volume: '10704338.7400000000000000',
      quoteVolume: 149610565.32318997,
      btcVolume: 7801.160851270977,
      usdVolume: 149610565.32318997,
      time: '2020-12-01T00:00:00.000Z'
      },
      {
      open: '13.3544000000000000',
      high: '14.1482000000000000',
      low: '13.0500000000000000',
      close: '13.9799000000000000',
      volume: '6137932.7300000000000000',
      quoteVolume: 83779802.26252918,
      btcVolume: 4424.74642432009,
      usdVolume: 83779802.26252918,
      time: '2020-12-02T00:00:00.000Z'
      },
      {
      open: '13.9785000000000000',
      high: '14.2200000000000000',
      low: '13.6446000000000000',
      close: '13.9749000000000000',
      volume: '4275317.8800000000000000',
      quoteVolume: 59535321.2775434,
      btcVolume: 3090.5416607922175,
      usdVolume: 59535321.2775434,
      time: '2020-12-03T00:00:00.000Z'
      },
      {
      open: '13.9749000000000000',
      high: '14.0332000000000000',
      low: '12.5101000000000000',
      close: '12.6800000000000000',
      volume: '5794937.4500000000000000',
      quoteVolume: 77275788.62363255,
      btcVolume: 4061.1546698955426,
      usdVolume: 77275788.62363255,
      time: '2020-12-04T00:00:00.000Z'
      },
      {
      open: '12.6800000000000000',
      high: '13.3478000000000000',
      low: '12.5265000000000000',
      close: '13.1748000000000000',
      volume: '3411635.3200000000000000',
      quoteVolume: 44668221.07546615,
      btcVolume: 2355.8773509120115,
      usdVolume: 44668221.07546615,
      time: '2020-12-05T00:00:00.000Z'
      },
      {
      open: '13.1743000000000000',
      high: '13.5282000000000000',
      low: '12.7671000000000000',
      close: '13.5057000000000000',
      volume: '3866618.8500000000000000',
      quoteVolume: 51005078.82048807,
      btcVolume: 2664.431067672835,
      usdVolume: 51005078.82048807,
      time: '2020-12-06T00:00:00.000Z'
      },
      {
      open: '13.5090000000000000',
      high: '13.5536000000000000',
      low: '12.8671000000000000',
      close: '13.0704000000000000',
      volume: '3223051.6500000000000000',
      quoteVolume: 42439345.8764611,
      btcVolume: 2211.4995680383877,
      usdVolume: 42439345.8764611,
      time: '2020-12-07T00:00:00.000Z'
      },
      {
      open: '13.0737000000000000',
      high: '13.1929000000000000',
      low: '12.0000000000000000',
      close: '12.1356000000000000',
      volume: '5175641.2100000000000000',
      quoteVolume: 65172913.683855735,
      btcVolume: 3458.1321327537844,
      usdVolume: 65172913.683855735,
      time: '2020-12-08T00:00:00.000Z'
      },
      {
      open: '12.1280000000000000',
      high: '12.8290000000000000',
      low: '11.5726000000000000',
      close: '12.6212000000000000',
      volume: '6754613.8300000000000000',
      quoteVolume: 81954160.37834212,
      btcVolume: 4495.429433765595,
      usdVolume: 81954160.37834212,
      time: '2020-12-09T00:00:00.000Z'
      },
      {
      open: '12.6200000000000000',
      high: '12.6730000000000000',
      low: '11.8549000000000000',
      close: '11.9172000000000000',
      volume: '3519174.2400000000000000',
      quoteVolume: 42896656.04262703,
      btcVolume: 2346.0300410065424,
      usdVolume: 42896656.04262703,
      time: '2020-12-10T00:00:00.000Z'
      },
      {
      open: '11.9172000000000000',
      high: '11.9899000000000000',
      low: '11.4300000000000000',
      close: '11.6335000000000000',
      volume: '4337254.6200000000000000',
      quoteVolume: 50652575.468863204,
      btcVolume: 2820.6350744098336,
      usdVolume: 50647631.718757205,
      time: '2020-12-11T00:00:00.000Z'
      },
      {
      open: '11.6393000000000000',
      high: '12.3529000000000000',
      low: '11.6201000000000000',
      close: '12.2259000000000000',
      volume: '3453043.1200000000000000',
      quoteVolume: 41720520.772683,
      btcVolume: 2259.4574947618594,
      usdVolume: 41720520.772683,
      time: '2020-12-12T00:00:00.000Z'
      },
      {
      open: '12.2280000000000000',
      high: '13.2697000000000000',
      low: '12.0210000000000000',
      close: '12.9392000000000000',
      volume: '6036848.9700000000000000',
      quoteVolume: 76518629.71278152,
      btcVolume: 3990.4218542597705,
      usdVolume: 76518629.71278152,
      time: '2020-12-13T00:00:00.000Z'
      },
      {
      open: '12.9389000000000000',
      high: '12.9800000000000000',
      low: '12.3238000000000000',
      close: '12.8502000000000000',
      volume: '4526653.6900000000000000',
      quoteVolume: 57446821.99876489,
      btcVolume: 2998.157797062246,
      usdVolume: 57446821.99876489,
      time: '2020-12-14T00:00:00.000Z'
      },
      {
      open: '12.8513000000000000',
      high: '13.0220000000000000',
      low: '12.4659000000000000',
      close: '12.6818000000000000',
      volume: '2635221.9300000000000000',
      quoteVolume: 33514452.026845157,
      btcVolume: 1732.722900418818,
      usdVolume: 33514452.026845157,
      time: '2020-12-15T00:00:00.000Z'
      },
      {
      open: '12.6786000000000000',
      high: '13.5000000000000000',
      low: '12.3119000000000000',
      close: '13.4852000000000000',
      volume: '5644198.7100000000000000',
      quoteVolume: 73189908.58627348,
      btcVolume: 3595.924463613392,
      usdVolume: 73189908.58627348,
      time: '2020-12-16T00:00:00.000Z'
      },
      {
      open: '13.4840000000000000',
      high: '14.5038000000000000',
      low: '13.0575000000000000',
      close: '13.4440000000000000',
      volume: '11577270.1700000000000000',
      quoteVolume: 159653805.28869012,
      btcVolume: 7026.0614219511735,
      usdVolume: 159653805.28869012,
      time: '2020-12-17T00:00:00.000Z'
      },
      {
      open: '13.4440000000000000',
      high: '14.0235000000000000',
      low: '13.1725000000000000',
      close: '13.5001000000000000',
      volume: '5513708.3800000000000000',
      quoteVolume: 74792637.47296071,
      btcVolume: 3271.2867842547794,
      usdVolume: 74792637.47296071,
      time: '2020-12-18T00:00:00.000Z'
      },
      {
      open: '13.5000000000000000',
      high: '13.9697000000000000',
      low: '13.2981000000000000',
      close: '13.4747000000000000',
      volume: '4596156.0500000000000000',
      quoteVolume: 62945458.56621082,
      btcVolume: 2692.9200733659177,
      usdVolume: 62945458.56621082,
      time: '2020-12-19T00:00:00.000Z'
      },
      {
      open: '13.4748000000000000',
      high: '13.8000000000000000',
      low: '12.6752000000000000',
      close: '13.0604000000000000',
      volume: '5227497.0200000000000000',
      quoteVolume: 69925342.0503345,
      btcVolume: 2966.1250407952425,
      usdVolume: 69925342.0503345,
      time: '2020-12-20T00:00:00.000Z'
      },
      {
      open: '13.0603000000000000',
      high: '13.2700000000000000',
      low: '12.1354000000000000',
      close: '12.3049000000000000',
      volume: '5659600.6800000000000000',
      quoteVolume: 71716093.56996408,
      btcVolume: 3090.609511831206,
      usdVolume: 71716093.56996408,
      time: '2020-12-21T00:00:00.000Z'
      },
      {
      open: '12.3028000000000000',
      high: '12.8400000000000000',
      low: '11.8117000000000000',
      close: '12.7842000000000000',
      volume: '6172266.6600000000000000',
      quoteVolume: 76364461.06396206,
      btcVolume: 3308.2084013040967,
      usdVolume: 76364461.06396206,
      time: '2020-12-22T00:00:00.000Z'
      },
      {
      open: '12.7811000000000000',
      high: '12.8634000000000000',
      low: '8.0500000000000000',
      close: '10.9736000000000000',
      volume: '15154276.0300000000000000',
      quoteVolume: 168815393.817345,
      btcVolume: 7227.637473629581,
      usdVolume: 168815393.817345,
      time: '2020-12-23T00:00:00.000Z'
      },
      {
      open: '10.9730000000000000',
      high: '11.6877000000000000',
      low: '9.8564000000000000',
      close: '11.5724000000000000',
      volume: '14771438.4000000000000000',
      quoteVolume: 157801028.12701663,
      btcVolume: 6807.5861925391055,
      usdVolume: 157801028.12701663,
      time: '2020-12-24T00:00:00.000Z'
      },
      {
      open: '11.5705000000000000',
      high: '11.9234000000000000',
      low: '11.0601000000000000',
      close: '11.5543000000000000',
      volume: '7348236.1100000000000000',
      quoteVolume: 84426543.59749623,
      btcVolume: 3504.7630743045383,
      usdVolume: 84426543.59749623,
      time: '2020-12-25T00:00:00.000Z'
      },
      {
      open: '11.5640000000000000',
      high: '11.8151000000000000',
      low: '10.6238000000000000',
      close: '10.9809000000000000',
      volume: '9093279.7400000000000000',
      quoteVolume: 101048617.75868994,
      btcVolume: 3982.7085174247964,
      usdVolume: 101048617.75868994,
      time: '2020-12-26T00:00:00.000Z'
      },
      {
      open: '10.9840000000000000',
      high: '13.2448000000000000',
      low: '10.4763000000000000',
      close: '12.1541000000000000',
      volume: '18943413.3800000000000000',
      quoteVolume: 226941348.13784474,
      btcVolume: 8381.434369307135,
      usdVolume: 226941348.13784474,
      time: '2020-12-27T00:00:00.000Z'
      },
      {
      open: '12.1542000000000000',
      high: '12.9781000000000000',
      low: '11.9710000000000000',
      close: '12.6081000000000000',
      volume: '11270755.2200000000000000',
      quoteVolume: 140279053.6851551,
      btcVolume: 5203.613393033124,
      usdVolume: 140279053.6851551,
      time: '2020-12-28T00:00:00.000Z'
      },
      {
      open: '12.6080000000000000',
      high: '13.0351000000000000',
      low: '10.8161000000000000',
      close: '11.7786000000000000',
      volume: '12890847.2600000000000000',
      quoteVolume: 151849176.99178037,
      btcVolume: 5695.149438822997,
      usdVolume: 151849176.99178037,
      time: '2020-12-29T00:00:00.000Z'
      },
      {
      open: '11.7786000000000000',
      high: '11.9900000000000000',
      low: '11.0671000000000000',
      close: '11.2435000000000000',
      volume: '7687754.9000000000000000',
      quoteVolume: 88083075.6322653,
      btcVolume: 3132.5608072986693,
      usdVolume: 88083075.6322653,
      time: '2020-12-30T00:00:00.000Z'
      },
      {
      open: '11.2437000000000000',
      high: '11.7530000000000000',
      low: '10.9039000000000000',
      close: '11.2364000000000000',
      volume: '6639477.6200000000000000',
      quoteVolume: 75178212.43988732,
      btcVolume: 2609.4447854849886,
      usdVolume: 75178212.43988732,
      time: '2020-12-31T00:00:00.000Z'
      },
      {
      open: '11.2364000000000000',
      high: '12.4600000000000000',
      low: '11.0638000000000000',
      close: '11.8477000000000000',
      volume: '8245184.6000000000000000',
      quoteVolume: 97963056.43351899,
      btcVolume: 3350.468743703054,
      usdVolume: 97963056.43351899,
      time: '2021-01-01T00:00:00.000Z'
      },
      {
      open: '11.8453000000000000',
      high: '12.4188000000000000',
      low: '11.4303000000000000',
      close: '12.2215000000000000',
      volume: '9617243.6900000000000000',
      quoteVolume: 114480499.1565445,
      btcVolume: 3665.1539501073544,
      usdVolume: 114480499.1565445,
      time: '2021-01-02T00:00:00.000Z'
      },
      {
      open: '12.2206000000000000',
      high: '13.9999000000000000',
      low: '11.8355000000000000',
      close: '13.6814000000000000',
      volume: '15927066.3100000000000000',
      quoteVolume: 205085520.50347137,
      btcVolume: 6124.344091873578,
      usdVolume: 205085520.50347137,
      time: '2021-01-03T00:00:00.000Z'
      },
      {
      open: '13.6815000000000000',
      high: '15.6440000000000000',
      low: '12.3713000000000000',
      close: '13.5935000000000000',
      volume: '23196077.6800000000000000',
      quoteVolume: 321727867.96833014,
      btcVolume: 10107.698949256002,
      usdVolume: 321727867.96833014,
      time: '2021-01-04T00:00:00.000Z'
      },
      {
      open: '13.5962000000000000',
      high: '14.8900000000000000',
      low: '13.0928000000000000',
      close: '14.5162000000000000',
      volume: '14809606.1500000000000000',
      quoteVolume: 208905104.45490494,
      btcVolume: 6485.977306193403,
      usdVolume: 208905104.45490494,
      time: '2021-01-05T00:00:00.000Z'
      },
      {
      open: '14.5141000000000000',
      high: '17.2997000000000000',
      low: '14.1427000000000000',
      close: '17.2322000000000000',
      volume: '21280047.1800000000000000',
      quoteVolume: 338322543.10816723,
      btcVolume: 9721.843587977792,
      usdVolume: 338322543.10816723,
      time: '2021-01-06T00:00:00.000Z'
      },
      {
      open: '17.2342000000000000',
      high: '17.4100000000000000',
      low: '15.3542000000000000',
      close: '15.9628000000000000',
      volume: '16135019.3000000000000000',
      quoteVolume: 266063447.9945453,
      btcVolume: 6971.427792671456,
      usdVolume: 266063447.9945453,
      time: '2021-01-07T00:00:00.000Z'
      },
      {
      open: '15.9659000000000000',
      high: '16.1917000000000000',
      low: '14.1200000000000000',
      close: '15.2381000000000000',
      volume: '14730622.4700000000000000',
      quoteVolume: 225837560.70656046,
      btcVolume: 5699.007296482832,
      usdVolume: 225837560.70656046,
      time: '2021-01-08T00:00:00.000Z'
      },
      {
      open: '15.2395000000000000',
      high: '17.8888000000000000',
      low: '15.0029000000000000',
      close: '17.5501000000000000',
      volume: '12807954.5600000000000000',
      quoteVolume: 209112616.22126392,
      btcVolume: 5179.822048220309,
      usdVolume: 209112616.22126392,
      time: '2021-01-09T00:00:00.000Z'
      },
      {
      open: '17.5501000000000000',
      high: '18.4386000000000000',
      low: '15.4418000000000000',
      close: '16.2060000000000000',
      volume: '16459987.7000000000000000',
      quoteVolume: 280219098.9046208,
      btcVolume: 7124.706550510726,
      usdVolume: 280219098.9046208,
      time: '2021-01-10T00:00:00.000Z'
      },
      {
      open: '16.1998000000000000',
      high: '16.2596000000000000',
      low: '12.5000000000000000',
      close: '14.6393000000000000',
      volume: '21046546.5800000000000000',
      quoteVolume: 299155444.76900715,
      btcVolume: 8793.50832897109,
      usdVolume: 299155444.76900715,
      time: '2021-01-11T00:00:00.000Z'
      },
      {
      open: '14.6312000000000000',
      high: '15.4760000000000000',
      low: '13.6701000000000000',
      close: '13.9655000000000000',
      volume: '8959437.3100000000000000',
      quoteVolume: 130225909.90950419,
      btcVolume: 3745.877009935573,
      usdVolume: 130225909.90950419,
      time: '2021-01-12T00:00:00.000Z'
      },
      {
      open: '13.9609000000000000',
      high: '16.2356000000000000',
      low: '13.3701000000000000',
      close: '15.9356000000000000',
      volume: '10911746.9200000000000000',
      quoteVolume: 163912112.80508533,
      btcVolume: 4699.829494560408,
      usdVolume: 163912112.80508533,
      time: '2021-01-13T00:00:00.000Z'
      },
      {
      open: '15.9396000000000000',
      high: '17.9900000000000000',
      low: '15.3300000000000000',
      close: '17.9804000000000000',
      volume: '13478182.9400000000000000',
      quoteVolume: 222149143.90415877,
      btcVolume: 5733.229551150123,
      usdVolume: 222149143.90415877,
      time: '2021-01-14T00:00:00.000Z'
      },
      {
      open: '17.9888000000000000',
      high: '21.4221000000000000',
      low: '17.5355000000000000',
      close: '20.7375000000000000',
      volume: '31054166.3600000000000000',
      quoteVolume: 598915855.4361597,
      btcVolume: 16128.175112869507,
      usdVolume: 598915855.4361597,
      time: '2021-01-15T00:00:00.000Z'
      },
      {
      open: '20.7405000000000000',
      high: '22.9600000000000000',
      low: '19.6280000000000000',
      close: '20.1054000000000000',
      volume: '22583400.8600000000000000',
      quoteVolume: 488477166.4998309,
      btcVolume: 13263.862594524382,
      usdVolume: 488477166.4998309,
      time: '2021-01-16T00:00:00.000Z'
      },
      {
      open: '20.1003000000000000',
      high: '23.7670000000000000',
      low: '19.3729000000000000',
      close: '23.2819000000000000',
      volume: '21794612.1800000000000000',
      quoteVolume: 467638469.6446607,
      btcVolume: 13093.00975534152,
      usdVolume: 467638469.6446607,
      time: '2021-01-17T00:00:00.000Z'
      },
      {
      open: '23.2676000000000000',
      high: '23.6862000000000000',
      low: '21.0600000000000000',
      close: '22.0336000000000000',
      volume: '13776439.8500000000000000',
      quoteVolume: 305061086.4015469,
      btcVolume: 8477.02470472144,
      usdVolume: 305061086.4015469,
      time: '2021-01-18T00:00:00.000Z'
      },
      {
      open: '22.0325000000000000',
      high: '22.8248000000000000',
      low: '20.3774000000000000',
      close: '20.5557000000000000',
      volume: '10237213.4000000000000000',
      quoteVolume: 221370711.89643016,
      btcVolume: 6011.617268406959,
      usdVolume: 221370711.89643016,
      time: '2021-01-19T00:00:00.000Z'
      },
      {
      open: '20.5437000000000000',
      high: '21.9788000000000000',
      low: '19.0645000000000000',
      close: '21.8482000000000000',
      volume: '12445387.5700000000000000',
      quoteVolume: 253587783.00309002,
      btcVolume: 7255.599211354413,
      usdVolume: 253587783.00309002,
      time: '2021-01-20T00:00:00.000Z'
      },
      {
      open: '21.8421000000000000',
      high: '22.0307000000000000',
      low: '18.0611000000000000',
      close: '18.4119000000000000',
      volume: '14800253.5200000000000000',
      quoteVolume: 295655182.9197108,
      btcVolume: 9063.9657818205,
      usdVolume: 295655182.9197108,
      time: '2021-01-21T00:00:00.000Z'
      },
      {
      open: '18.4109000000000000',
      high: '22.4725000000000000',
      low: '17.1269000000000000',
      close: '21.5519000000000000',
      volume: '16210103.8000000000000000',
      quoteVolume: 326743324.4039971,
      btcVolume: 10379.828662817843,
      usdVolume: 326743324.4039971,
      time: '2021-01-22T00:00:00.000Z'
      },
      {
      open: '21.5467000000000000',
      high: '25.5000000000000000',
      low: '21.1400000000000000',
      close: '24.8046000000000000',
      volume: '14892659.5600000000000000',
      quoteVolume: 357496388.1897744,
      btcVolume: 11065.14109872835,
      usdVolume: 357496388.1897744,
      time: '2021-01-23T00:00:00.000Z'
      },
      {
      open: '24.8168000000000000',
      high: '24.9530000000000000',
      low: '23.1612000000000000',
      close: '24.7935000000000000',
      volume: '11903411.3600000000000000',
      quoteVolume: 288283267.4123565,
      btcVolume: 8969.474384440253,
      usdVolume: 288283267.4123565,
      time: '2021-01-24T00:00:00.000Z'
      },
      {
      open: '24.7912000000000000',
      high: '25.7824000000000000',
      low: '22.6000000000000000',
      close: '23.3631000000000000',
      volume: '11247196.2700000000000000',
      quoteVolume: 275657899.55470353,
      btcVolume: 8265.386510770037,
      usdVolume: 275657899.55470353,
      time: '2021-01-25T00:00:00.000Z'
      },
      {
      open: '22.4042000000000000',
      high: '23.3000000000000000',
      low: '22.3137000000000000',
      close: '23.0981000000000000',
      volume: '2366823.8100000000000000',
      quoteVolume: 54138852.50846183,
      btcVolume: 1682.3671327730394,
      usdVolume: 54138852.50846183,
      time: '2021-01-26T00:00:00.000Z'
      },
      {
      open: '23.0914000000000000',
      high: '23.2295000000000000',
      low: '20.2767000000000000',
      close: '20.9586000000000000',
      volume: '8535198.1600000000000000',
      quoteVolume: 182731435.30837923,
      btcVolume: 5920.435835584469,
      usdVolume: 182731435.30837923,
      time: '2021-01-27T00:00:00.000Z'
      },
      {
      open: '20.9586000000000000',
      high: '24.4716000000000000',
      low: '20.5734000000000000',
      close: '23.0145000000000000',
      volume: '8468868.5600000000000000',
      quoteVolume: 197396206.1119182,
      btcVolume: 6236.369329316392,
      usdVolume: 197396206.1119182,
      time: '2021-01-28T00:00:00.000Z'
      },
      {
      open: '22.9869000000000000',
      high: '24.2400000000000000',
      low: '22.0450000000000000',
      close: '22.7480000000000000',
      volume: '9016409.4900000000000000',
      quoteVolume: 208328902.77130583,
      btcVolume: 5907.088801742898,
      usdVolume: 208328902.77130583,
      time: '2021-01-29T00:00:00.000Z'
      },
      {
      open: '22.7480000000000000',
      high: '23.9800000000000000',
      low: '22.1594000000000000',
      close: '23.6399000000000000',
      volume: '6773423.4600000000000000',
      quoteVolume: 156017856.8708368,
      btcVolume: 4581.931097812988,
      usdVolume: 156017856.8708368,
      time: '2021-01-30T00:00:00.000Z'
      },
      {
      open: '23.6491000000000000',
      high: '24.6780000000000000',
      low: '22.0603000000000000',
      close: '22.5613000000000000',
      volume: '5361442.2200000000000000',
      quoteVolume: 124076481.29119983,
      btcVolume: 3697.017425316733,
      usdVolume: 124076481.29119983,
      time: '2021-01-31T00:00:00.000Z'
      },
      {
      open: '22.5643000000000000',
      high: '23.2000000000000000',
      low: '21.6526000000000000',
      close: '22.8757000000000000',
      volume: '5564509.6300000000000000',
      quoteVolume: 124203143.57187262,
      btcVolume: 3687.457488368662,
      usdVolume: 124203143.57187262,
      time: '2021-02-01T00:00:00.000Z'
      },
      {
      open: '22.8752000000000000',
      high: '24.1693000000000000',
      low: '22.7354000000000000',
      close: '23.6582000000000000',
      volume: '6470015.7600000000000000',
      quoteVolume: 150444221.2962143,
      btcVolume: 4337.8794213047395,
      usdVolume: 150444221.2962143,
      time: '2021-02-02T00:00:00.000Z'
      },
      {
      open: '23.6578000000000000',
      high: '25.5674000000000000',
      low: '23.5318000000000000',
      close: '25.0870000000000000',
      volume: '8459477.4100000000000000',
      quoteVolume: 208200036.68349785,
      btcVolume: 5709.561538472078,
      usdVolume: 208200036.68349785,
      time: '2021-02-03T00:00:00.000Z'
      },
      {
      open: '25.0814000000000000',
      high: '26.1500000000000000',
      low: '22.6174000000000000',
      close: '24.4920000000000000',
      volume: '8629885.3200000000000000',
      quoteVolume: 211063847.95773497,
      btcVolume: 5630.340958663389,
      usdVolume: 211063847.95773497,
      time: '2021-02-04T00:00:00.000Z'
      },
      {
      open: '24.4841000000000000',
      high: '27.0000000000000000',
      low: '24.4081000000000000',
      close: '26.3034000000000000',
      volume: '9640790.8100000000000000',
      quoteVolume: 251859619.30684873,
      btcVolume: 6697.035136045025,
      usdVolume: 251859619.30684873,
      time: '2021-02-05T00:00:00.000Z'
      },
      {
      open: '26.3023000000000000',
      high: '26.8000000000000000',
      low: '24.2081000000000000',
      close: '25.0231000000000000',
      volume: '7043800.3900000000000000',
      quoteVolume: 178656384.6660932,
      btcVolume: 4511.137870600865,
      usdVolume: 178656384.6660932,
      time: '2021-02-06T00:00:00.000Z'
      },
      {
      open: '25.0336000000000000',
      high: '25.2836000000000000',
      low: '22.8100000000000000',
      close: '24.7254000000000000',
      volume: '6499629.3000000000000000',
      quoteVolume: 157729890.3566084,
      btcVolume: 4097.192310639251,
      usdVolume: 157729890.3566084,
      time: '2021-02-07T00:00:00.000Z'
      },
      {
      open: '24.7335000000000000',
      high: '25.9800000000000000',
      low: '23.9587000000000000',
      close: '25.4115000000000000',
      volume: '6837186.9500000000000000',
      quoteVolume: 171815141.03402072,
      btcVolume: 4122.278957205255,
      usdVolume: 171815141.03402072,
      time: '2021-02-08T00:00:00.000Z'
      },
      {
      open: '25.4070000000000000',
      high: '28.0000000000000000',
      low: '25.0000000000000000',
      close: '27.6034000000000000',
      volume: '9472870.3200000000000000',
      quoteVolume: 246830741.98450413,
      btcVolume: 5292.997466768552,
      usdVolume: 246830741.98450413,
      time: '2021-02-09T00:00:00.000Z'
      },
      {
      open: '27.6150000000000000',
      high: '28.6412000000000000',
      low: '25.1227000000000000',
      close: '26.8121000000000000',
      volume: '11717346.8200000000000000',
      quoteVolume: 318420727.69046074,
      btcVolume: 6960.680395575012,
      usdVolume: 318411629.02087176,
      time: '2021-02-10T00:00:00.000Z'
      },
      {
      open: '26.8000000000000000',
      high: '28.5705000000000000',
      low: '26.2172000000000000',
      close: '27.8475000000000000',
      volume: '6650588.7300000000000000',
      quoteVolume: 183913909.31249094,
      btcVolume: 3961.896278975156,
      usdVolume: 183913909.31249094,
      time: '2021-02-11T00:00:00.000Z'
      },
      {
      open: '27.8645000000000000',
      high: '31.4485000000000000',
      low: '26.6746000000000000',
      close: '30.6265000000000000',
      volume: '9749785.0200000000000000',
      quoteVolume: 284077020.56706005,
      btcVolume: 5975.622685196747,
      usdVolume: 284045968.225476,
      time: '2021-02-12T00:00:00.000Z'
      },
      {
      open: '30.6404000000000000',
      high: '34.7573000000000000',
      low: '29.0700000000000000',
      close: '34.1941000000000000',
      volume: '11011345.3200000000000000',
      quoteVolume: 346030518.51178366,
      btcVolume: 7340.703684708217,
      usdVolume: 345996921.8331248,
      time: '2021-02-13T00:00:00.000Z'
      },
      {
      open: '34.1969000000000000',
      high: '35.6945000000000000',
      low: '32.3088000000000000',
      close: '33.1565000000000000',
      volume: '8337078.5800000000000000',
      quoteVolume: 283546794.60109466,
      btcVolume: 5868.713681268827,
      usdVolume: 283546794.60109466,
      time: '2021-02-14T00:00:00.000Z'
      },
      {
      open: '33.1555000000000000',
      high: '34.9252000000000000',
      low: '27.5000000000000000',
      close: '32.5899000000000000',
      volume: '10469894.2400000000000000',
      quoteVolume: 335112228.9163464,
      btcVolume: 7001.374521254515,
      usdVolume: 333251261.0761585,
      time: '2021-02-15T00:00:00.000Z'
      },
      {
      open: '32.5853000000000000',
      high: '33.4269000000000000',
      low: '30.7840000000000000',
      close: '31.9632000000000000',
      volume: '6985113.3100000000000000',
      quoteVolume: 224245398.9060409,
      btcVolume: 4510.950361788866,
      usdVolume: 220012561.30996123,
      time: '2021-02-16T00:00:00.000Z'
      },
      {
      open: '31.9630000000000000',
      high: '32.6705000000000000',
      low: '30.0000000000000000',
      close: '32.2324000000000000',
      volume: '5259067.4900000000000000',
      quoteVolume: 166231266.71195933,
      btcVolume: 3258.0237581539295,
      usdVolume: 166231266.71195933,
      time: '2021-02-17T00:00:00.000Z'
      },
      {
      open: '32.2256000000000000',
      high: '33.5961000000000000',
      low: '31.7785000000000000',
      close: '32.4801000000000000',
      volume: '5451118.6700000000000000',
      quoteVolume: 177745245.9786863,
      btcVolume: 3426.75446906022,
      usdVolume: 177745245.9786863,
      time: '2021-02-18T00:00:00.000Z'
      },
      {
      open: '32.4727000000000000',
      high: '35.5072000000000000',
      low: '31.3799000000000000',
      close: '34.6846000000000000',
      volume: '8360898.6500000000000000',
      quoteVolume: 281294280.39428127,
      btcVolume: 5294.475712342659,
      usdVolume: 281294280.39428127,
      time: '2021-02-19T00:00:00.000Z'
      },
      {
      open: '34.6846000000000000',
      high: '36.9307000000000000',
      low: '32.0000000000000000',
      close: '33.9387000000000000',
      volume: '10054962.6900000000000000',
      quoteVolume: 352536639.04229677,
      btcVolume: 6272.494218045403,
      usdVolume: 352532151.53390276,
      time: '2021-02-20T00:00:00.000Z'
      },
      {
      open: '33.9293000000000000',
      high: '35.2918000000000000',
      low: '33.2712000000000000',
      close: '34.0915000000000000',
      volume: '5179283.2400000000000000',
      quoteVolume: 177022601.01385555,
      btcVolume: 3109.2651388863383,
      usdVolume: 177022601.01385555,
      time: '2021-02-21T00:00:00.000Z'
      },
      {
      open: '34.0916000000000000',
      high: '34.2185000000000000',
      low: '25.2312000000000000',
      close: '31.4542000000000000',
      volume: '10543122.8600000000000000',
      quoteVolume: 319683313.051021,
      btcVolume: 5634.770333904902,
      usdVolume: 301180710.3156824,
      time: '2021-02-22T00:00:00.000Z'
      },
      {
      open: '31.4482000000000000',
      high: '31.5822000000000000',
      low: '21.6261000000000000',
      close: '25.9249000000000000',
      volume: '21560485.0600000000000000',
      quoteVolume: 554568596.2290249,
      btcVolume: 11275.518366580607,
      usdVolume: 545229229.464444,
      time: '2021-02-23T00:00:00.000Z'
      },
      {
      open: '25.9573000000000000',
      high: '28.8292000000000000',
      low: '24.5050000000000000',
      close: '28.0851000000000000',
      volume: '6709381.7900000000000000',
      quoteVolume: 183384362.28603324,
      btcVolume: 3687.2224811202454,
      usdVolume: 183355744.81425026,
      time: '2021-02-24T00:00:00.000Z'
      },
      {
      open: '28.0737000000000000',
      high: '28.6802000000000000',
      low: '24.2509000000000000',
      close: '24.7144000000000000',
      volume: '7893277.6100000000000000',
      quoteVolume: 214328348.89058003,
      btcVolume: 4308.799154273922,
      usdVolume: 214328348.89058003,
      time: '2021-02-25T00:00:00.000Z'
      },
      {
      open: '24.7144000000000000',
      high: '26.9906000000000000',
      low: '23.6574000000000000',
      close: '25.1862000000000000',
      volume: '9016978.7100000000000000',
      quoteVolume: 226299006.61160633,
      btcVolume: 4860.302348071939,
      usdVolume: 226070729.98285314,
      time: '2021-02-26T00:00:00.000Z'
      },
      {
      open: '25.1650000000000000',
      high: '27.4472000000000000',
      low: '24.8545000000000000',
      close: '26.1896000000000000',
      volume: '7622190.2300000000000000',
      quoteVolume: 197306897.2042366,
      btcVolume: 4194.093858773107,
      usdVolume: 197306897.2042366,
      time: '2021-02-27T00:00:00.000Z'
      },
      {
      open: '26.1941000000000000',
      high: '26.7624000000000000',
      low: '22.8578000000000000',
      close: '24.7016000000000000',
      volume: '7513099.0000000000000000',
      quoteVolume: 183181771.37759796,
      btcVolume: 4101.6732584746605,
      usdVolume: 183181771.37759796,
      time: '2021-02-28T00:00:00.000Z'
      },
      {
      open: '24.6954000000000000',
      high: '27.7326000000000000',
      low: '24.5758000000000000',
      close: '27.7116000000000000',
      volume: '11662106.9800000000000000',
      quoteVolume: 309292384.27149624,
      btcVolume: 6431.989232060969,
      usdVolume: 309292384.27149624,
      time: '2021-03-01T00:00:00.000Z'
      },
      {
      open: '27.7187000000000000',
      high: '30.5251000000000000',
      low: '27.1757000000000000',
      close: '28.4769000000000000',
      volume: '9706793.4600000000000000',
      quoteVolume: 280186454.4698888,
      btcVolume: 5764.268832849585,
      usdVolume: 280186454.4698888,
      time: '2021-03-02T00:00:00.000Z'
      },
      {
      open: '28.4640000000000000',
      high: '31.4300000000000000',
      low: '28.1272000000000000',
      close: '29.8500000000000000',
      volume: '6412989.4500000000000000',
      quoteVolume: 193702490.28628224,
      btcVolume: 3844.793474650542,
      usdVolume: 193702490.28628224,
      time: '2021-03-03T00:00:00.000Z'
      },
      {
      open: '29.8334000000000000',
      high: '30.5731000000000000',
      low: '27.1891000000000000',
      close: '27.6943000000000000',
      volume: '6789296.5900000000000000',
      quoteVolume: 196271455.16863889,
      btcVolume: 3979.194700761396,
      usdVolume: 196249322.53349993,
      time: '2021-03-04T00:00:00.000Z'
      },
      {
      open: '27.6856000000000000',
      high: '28.2338000000000000',
      low: '25.6483000000000000',
      close: '27.8343000000000000',
      volume: '5832853.2100000000000000',
      quoteVolume: 156056362.04028454,
      btcVolume: 3286.3488640127302,
      usdVolume: 156056362.04028454,
      time: '2021-03-05T00:00:00.000Z'
      },
      {
      open: '27.8195000000000000',
      high: '28.3985000000000000',
      low: '26.2889000000000000',
      close: '28.1479000000000000',
      volume: '3381150.1600000000000000',
      quoteVolume: 92804331.2379402,
      btcVolume: 1896.9852933650902,
      usdVolume: 91553822.80733496,
      time: '2021-03-06T00:00:00.000Z'
      },
      {
      open: '28.1608000000000000',
      high: '29.1258000000000000',
      low: '27.3859000000000000',
      close: '28.5932000000000000',
      volume: '4599258.0200000000000000',
      quoteVolume: 130068525.6266061,
      btcVolume: 2589.6634533421866,
      usdVolume: 130068525.6266061,
      time: '2021-03-07T00:00:00.000Z'
      },
      {
      open: '28.5918000000000000',
      high: '31.8469000000000000',
      low: '28.2652000000000000',
      close: '31.8469000000000000',
      volume: '6681348.4500000000000000',
      quoteVolume: 199010644.3594535,
      btcVolume: 3922.101818010387,
      usdVolume: 199010644.3594535,
      time: '2021-03-08T00:00:00.000Z'
      },
      {
      open: '31.8227000000000000',
      high: '32.0000000000000000',
      low: '30.3639000000000000',
      close: '31.4914000000000000',
      volume: '4049655.7000000000000000',
      quoteVolume: 126227587.21440111,
      btcVolume: 2342.082973005306,
      usdVolume: 126227587.21440111,
      time: '2021-03-09T00:00:00.000Z'
      },
      {
      open: '31.4914000000000000',
      high: '31.7934000000000000',
      low: '29.2545000000000000',
      close: '29.9036000000000000',
      volume: '4027599.8200000000000000',
      quoteVolume: 122397560.04919197,
      btcVolume: 2207.964000897798,
      usdVolume: 122396451.37618196,
      time: '2021-03-10T00:00:00.000Z'
      },
      {
      open: '29.8743000000000000',
      high: '30.5285000000000000',
      low: '28.5516000000000000',
      close: '30.1166000000000000',
      volume: '5024365.7100000000000000',
      quoteVolume: 148147003.90674606,
      btcVolume: 2628.6223761839137,
      usdVolume: 148147003.90674606,
      time: '2021-03-11T00:00:00.000Z'
      },
      {
      open: '30.1227000000000000',
      high: '30.4110000000000000',
      low: '27.3717000000000000',
      close: '28.1489000000000000',
      volume: '4477144.4100000000000000',
      quoteVolume: 128936166.34822313,
      btcVolume: 1583.1266319722276,
      usdVolume: 89974102.5483421,
      time: '2021-03-12T00:00:00.000Z'
      },
      {
      open: '27.7838000000000000',
      high: '30.5700000000000000',
      low: '26.9326000000000000',
      close: '29.7384000000000000',
      volume: '5584451.5200000000000000',
      quoteVolume: 162487581.38361448,
      btcVolume: 2765.323829329903,
      usdVolume: 162487581.38361448,
      time: '2021-03-13T00:00:00.000Z'
      },
      {
      open: '29.7506000000000000',
      high: '30.2000000000000000',
      low: '28.5108000000000000',
      close: '28.5469000000000000',
      volume: '3121480.3400000000000000',
      quoteVolume: 91263041.59569518,
      btcVolume: 1507.0857507872481,
      usdVolume: 91064805.56460914,
      time: '2021-03-14T00:00:00.000Z'
      },
      {
      open: '28.5310000000000000',
      high: '29.4217000000000000',
      low: '26.8000000000000000',
      close: '27.5091000000000000',
      volume: '5236280.7600000000000000',
      quoteVolume: 145357316.8911458,
      btcVolume: 2536.539090783008,
      usdVolume: 145357316.8911458,
      time: '2021-03-15T00:00:00.000Z'
      },
      {
      open: '27.5006000000000000',
      high: '28.1883000000000000',
      low: '26.3975000000000000',
      close: '27.9099000000000000',
      volume: '3202429.1200000000000000',
      quoteVolume: 87907886.1985268,
      btcVolume: 1592.4117076930133,
      usdVolume: 87865565.54260874,
      time: '2021-03-16T00:00:00.000Z'
      },
      {
      open: '27.9100000000000000',
      high: '31.2000000000000000',
      low: '27.3027000000000000',
      close: '31.0579000000000000',
      volume: '8119714.6200000000000000',
      quoteVolume: 240849861.95782134,
      btcVolume: 4319.501158108978,
      usdVolume: 240849861.95782134,
      time: '2021-03-17T00:00:00.000Z'
      },
      {
      open: '31.0579000000000000',
      high: '31.3588000000000000',
      low: '29.0000000000000000',
      close: '29.3949000000000000',
      volume: '4982847.5300000000000000',
      quoteVolume: 149502922.10217696,
      btcVolume: 2552.5916810317053,
      usdVolume: 149502922.10217696,
      time: '2021-03-18T00:00:00.000Z'
      },
      {
      open: '29.3929000000000000',
      high: '30.5510000000000000',
      low: '28.6802000000000000',
      close: '29.7770000000000000',
      volume: '3239965.5200000000000000',
      quoteVolume: 96988651.02827796,
      btcVolume: 1661.0995932340902,
      usdVolume: 96861045.95362605,
      time: '2021-03-19T00:00:00.000Z'
      },
      {
      open: '29.7866000000000000',
      high: '31.3809000000000000',
      low: '29.5800000000000000',
      close: '29.6465000000000000',
      volume: '2540903.5400000000000000',
      quoteVolume: 77772991.47447608,
      btcVolume: 1323.149915780175,
      usdVolume: 77770316.54868808,
      time: '2021-03-20T00:00:00.000Z'
      },
      {
      open: '29.6564000000000000',
      high: '30.0800000000000000',
      low: '28.5817000000000000',
      close: '29.2097000000000000',
      volume: '2532521.5100000000000000',
      quoteVolume: 74287913.29808004,
      btcVolume: 1297.2908602936614,
      usdVolume: 74260278.37190706,
      time: '2021-03-21T00:00:00.000Z'
      },
      {
      open: '29.2117000000000000',
      high: '29.4444000000000000',
      low: '27.1362000000000000',
      close: '27.2431000000000000',
      volume: '3564715.9100000000000000',
      quoteVolume: 101808090.24197517,
      btcVolume: 1809.4616360427897,
      usdVolume: 101741677.36866613,
      time: '2021-03-22T00:00:00.000Z'
      },
      {
      open: '27.2551000000000000',
      high: '27.9351000000000000',
      low: '26.6283000000000000',
      close: '26.7813000000000000',
      volume: '3659416.4600000000000000',
      quoteVolume: 99812413.47572565,
      btcVolume: 1215.8619127352338,
      usdVolume: 66191594.700597785,
      time: '2021-03-23T00:00:00.000Z'
      },
      {
      open: '26.7831000000000000',
      high: '27.8814000000000000',
      low: '24.0404000000000000',
      close: '24.8684000000000000',
      volume: '4061588.6900000000000000',
      quoteVolume: 106692601.87539235,
      btcVolume: 1959.2264580328283,
      usdVolume: 106692601.87539235,
      time: '2021-03-24T00:00:00.000Z'
      },
      {
      open: '24.8679000000000000',
      high: '26.6401000000000000',
      low: '24.3665000000000000',
      close: '25.5350000000000000',
      volume: '4189607.3400000000000000',
      quoteVolume: 106697254.9266262,
      btcVolume: 1689.745893902917,
      usdVolume: 87698677.2009626,
      time: '2021-03-25T00:00:00.000Z'
      },
      {
      open: '25.5300000000000000',
      high: '27.4000000000000000',
      low: '25.3993000000000000',
      close: '27.3927000000000000',
      volume: '3356222.6100000000000000',
      quoteVolume: 88633668.99963559,
      btcVolume: 1599.8484710312273,
      usdVolume: 85435262.31795225,
      time: '2021-03-26T00:00:00.000Z'
      },
      {
      open: '27.3988000000000000',
      high: '27.4982000000000000',
      low: '26.0030000000000000',
      close: '26.8766000000000000',
      volume: '2032627.2400000000000000',
      quoteVolume: 54412158.06017271,
      btcVolume: 986.9278333127505,
      usdVolume: 54406973.49173972,
      time: '2021-03-27T00:00:00.000Z'
      },
      {
      open: '26.8765000000000000',
      high: '27.6624000000000000',
      low: '26.0889000000000000',
      close: '26.6588000000000000',
      volume: '2205293.2300000000000000',
      quoteVolume: 59418251.07729596,
      btcVolume: 1064.179617444524,
      usdVolume: 59406433.69876095,
      time: '2021-03-28T00:00:00.000Z'
      },
      {
      open: '26.6576000000000000',
      high: '28.6203000000000000',
      low: '26.3886000000000000',
      close: '28.0357000000000000',
      volume: '3528308.7300000000000000',
      quoteVolume: 97966057.5120884,
      btcVolume: 1709.0159853740718,
      usdVolume: 97916578.44213544,
      time: '2021-03-29T00:00:00.000Z'
      },
      {
      open: '28.0356000000000000',
      high: '28.5735000000000000',
      low: '27.4460000000000000',
      close: '27.8301000000000000',
      volume: '2521474.6200000000000000',
      quoteVolume: 70923243.6872522,
      btcVolume: 1213.622206853287,
      usdVolume: 70920571.39197218,
      time: '2021-03-30T00:00:00.000Z'
      },
      {
      open: '27.8300000000000000',
      high: '29.4401000000000000',
      low: '26.1800000000000000',
      close: '29.4167000000000000',
      volume: '4353005.8100000000000000',
      quoteVolume: 120857861.76495478,
      btcVolume: 2051.9849029876646,
      usdVolume: 120365548.36152953,
      time: '2021-03-31T00:00:00.000Z'
      },
      {
      open: '29.4168000000000000',
      high: '31.1250000000000000',
      low: '29.3143000000000000',
      close: '30.0684000000000000',
      volume: '5308598.1000000000000000',
      quoteVolume: 159884612.16588667,
      btcVolume: 2714.508183126401,
      usdVolume: 159764276.83964464,
      time: '2021-04-01T00:00:00.000Z'
      },
      {
      open: '30.0808000000000000',
      high: '32.5555000000000000',
      low: '29.5463000000000000',
      close: '32.4864000000000000',
      volume: '4245194.7400000000000000',
      quoteVolume: 131458201.24949433,
      btcVolume: 2219.951784391892,
      usdVolume: 131448573.62730134,
      time: '2021-04-02T00:00:00.000Z'
      },
      {
      open: '32.4867000000000000',
      high: '33.1707000000000000',
      low: '29.1256000000000000',
      close: '29.2930000000000000',
      volume: '5326728.5500000000000000',
      quoteVolume: 167961809.66658044,
      btcVolume: 1795.4395891216325,
      usdVolume: 105857363.34651382,
      time: '2021-04-03T00:00:00.000Z'
      },
      {
      open: '29.2930000000000000',
      high: '31.4643000000000000',
      low: '28.6801000000000000',
      close: '30.7471000000000000',
      volume: '2738370.5800000000000000',
      quoteVolume: 83118846.51577824,
      btcVolume: 1249.3258134334203,
      usdVolume: 72051166.9085144,
      time: '2021-04-04T00:00:00.000Z'
      },
      {
      open: '30.7473000000000000',
      high: '32.6399000000000000',
      low: '29.6179000000000000',
      close: '32.3791000000000000',
      volume: '4976271.3600000000000000',
      quoteVolume: 155152448.59764713,
      btcVolume: 1991.659027501243,
      usdVolume: 115877633.66876267,
      time: '2021-04-05T00:00:00.000Z'
      },
      {
      open: '32.3791000000000000',
      high: '34.9000000000000000',
      low: '31.3074000000000000',
      close: '34.7250000000000000',
      volume: '7244775.1200000000000000',
      quoteVolume: 240433366.91596004,
      btcVolume: 3797.508343023463,
      usdVolume: 221680600.84789953,
      time: '2021-04-06T00:00:00.000Z'
      },
      {
      open: '34.7295000000000000',
      high: '35.1200000000000000',
      low: '30.2286000000000000',
      close: '31.1090000000000000',
      volume: '7241883.5600000000000000',
      quoteVolume: 235343305.11428273,
      btcVolume: 3884.6148457101895,
      usdVolume: 221471220.7998364,
      time: '2021-04-07T00:00:00.000Z'
      },
      {
      open: '31.1008000000000000',
      high: '32.8999000000000000',
      low: '30.9466000000000000',
      close: '32.7884000000000000',
      volume: '3371094.7200000000000000',
      quoteVolume: 108183402.49988818,
      btcVolume: 1171.9067624824738,
      usdVolume: 66948808.547933206,
      time: '2021-04-08T00:00:00.000Z'
      },
      {
      open: '32.7870000000000000',
      high: '33.2876000000000000',
      low: '31.2348000000000000',
      close: '31.5893000000000000',
      volume: '2728512.6100000000000000',
      quoteVolume: 87716711.54413132,
      btcVolume: 1274.446364485902,
      usdVolume: 74249858.40623835,
      time: '2021-04-09T00:00:00.000Z'
      },
      {
      open: '31.5961000000000000',
      high: '33.0000000000000000',
      low: '31.0000000000000000',
      close: '31.8944000000000000',
      volume: '3545699.4300000000000000',
      quoteVolume: 113928069.33940087,
      btcVolume: 1828.0827861744679,
      usdVolume: 109414242.01156527,
      time: '2021-04-10T00:00:00.000Z'
      },
      {
      open: '31.8980000000000000',
      high: '34.4397000000000000',
      low: '31.7283000000000000',
      close: '33.8811000000000000',
      volume: '4949345.2000000000000000',
      quoteVolume: 164105171.51139703,
      btcVolume: 2677.3634365869484,
      usdVolume: 160031551.35507315,
      time: '2021-04-11T00:00:00.000Z'
      },
      {
      open: '33.8811000000000000',
      high: '34.1468000000000000',
      low: '31.8601000000000000',
      close: '33.1080000000000000',
      volume: '3732041.9700000000000000',
      quoteVolume: 123252667.28419851,
      btcVolume: 1636.5603716794567,
      usdVolume: 98349366.92374945,
      time: '2021-04-12T00:00:00.000Z'
      },
      {
      open: '33.1023000000000000',
      high: '35.8645000000000000',
      low: '32.3842000000000000',
      close: '35.5217000000000000',
      volume: '5624044.8000000000000000',
      quoteVolume: 192994936.86974585,
      btcVolume: 2998.5339331543933,
      usdVolume: 188012549.6792243,
      time: '2021-04-13T00:00:00.000Z'
      },
      {
      open: '35.5416000000000000',
      high: '41.9901000000000000',
      low: '35.0000000000000000',
      close: '41.3641000000000000',
      volume: '13418401.1400000000000000',
      quoteVolume: 506888645.13665795,
      btcVolume: 6514.2283925472175,
      usdVolume: 412737241.69925255,
      time: '2021-04-14T00:00:00.000Z'
      },
      {
      open: '41.3371000000000000',
      high: '44.3300000000000000',
      low: '39.6638000000000000',
      close: '42.5601000000000000',
      volume: '12581718.5700000000000000',
      quoteVolume: 528214652.25350446,
      btcVolume: 7445.637896817438,
      usdVolume: 467700360.8493485,
      time: '2021-04-15T00:00:00.000Z'
      },
      {
      open: '42.5654000000000000',
      high: '43.2902000000000000',
      low: '38.5273000000000000',
      close: '41.9861000000000000',
      volume: '9648189.8700000000000000',
      quoteVolume: 396462710.2078966,
      btcVolume: 5702.375812210699,
      usdVolume: 350891882.7305064,
      time: '2021-04-16T00:00:00.000Z'
      },
      {
      open: '41.9797000000000000',
      high: '44.1116000000000000',
      low: '39.8304000000000000',
      close: '39.9137000000000000',
      volume: '6757853.5600000000000000',
      quoteVolume: 284759254.2188944,
      btcVolume: 2680.560705167462,
      usdVolume: 164429323.88150203,
      time: '2021-04-17T00:00:00.000Z'
      },
      {
      open: '39.9135000000000000',
      high: '40.3436000000000000',
      low: '29.0000000000000000',
      close: '39.0799000000000000',
      volume: '14640165.5300000000000000',
      quoteVolume: 530375955.0032846,
      btcVolume: 9097.744625141062,
      usdVolume: 507371033.8404291,
      time: '2021-04-18T00:00:00.000Z'
      },
      {
      open: '39.0858000000000000',
      high: '41.9900000000000000',
      low: '34.8144000000000000',
      close: '35.7359000000000000',
      volume: '12978321.7600000000000000',
      quoteVolume: 497670097.2335755,
      btcVolume: 6174.098440246101,
      usdVolume: 347089895.7808836,
      time: '2021-04-19T00:00:00.000Z'
      },
      {
      open: '35.7504000000000000',
      high: '39.3901000000000000',
      low: '33.1933000000000000',
      close: '38.9954000000000000',
      volume: '11956908.1000000000000000',
      quoteVolume: 437511825.9164905,
      btcVolume: 7196.936350920713,
      usdVolume: 398982378.77708787,
      time: '2021-04-20T00:00:00.000Z'
      },
      {
      open: '38.9983000000000000',
      high: '39.4872000000000000',
      low: '35.8933000000000000',
      close: '36.2274000000000000',
      volume: '6732313.3700000000000000',
      quoteVolume: 253633014.1501259,
      btcVolume: 3719.7137046466846,
      usdVolume: 206621089.2032104,
      time: '2021-04-21T00:00:00.000Z'
      },
      {
      open: '36.2364000000000000',
      high: '40.8200000000000000',
      low: '33.7859000000000000',
      close: '35.1306000000000000',
      volume: '11519039.9400000000000000',
      quoteVolume: 432182126.09576267,
      btcVolume: 2025.7054502568894,
      usdVolume: 107167666.04858308,
      time: '2021-04-22T00:00:00.000Z'
      },
      {
      open: '35.1256000000000000',
      high: '35.9643000000000000',
      low: '30.2771000000000000',
      close: '35.2121000000000000',
      volume: '14134304.6300000000000000',
      quoteVolume: 465142368.4095787,
      btcVolume: 4763.5180098543515,
      usdVolume: 237164782.14820454,
      time: '2021-04-23T00:00:00.000Z'
      },
      {
      open: '35.2121000000000000',
      high: '35.3033000000000000',
      low: '31.0000000000000000',
      close: '31.2443000000000000',
      volume: '6295773.8600000000000000',
      quoteVolume: 206376774.11149526,
      btcVolume: 3817.3264985910882,
      usdVolume: 190343281.2332728,
      time: '2021-04-24T00:00:00.000Z'
      },
      {
      open: '31.2370000000000000',
      high: '33.4320000000000000',
      low: '29.8010000000000000',
      close: '31.6920000000000000',
      volume: '4274733.3210000000000000',
      quoteVolume: 135910873.76321998,
      btcVolume: 1405.9735040050004,
      usdVolume: 69698982.35040447,
      time: '2021-04-25T00:00:00.000Z'
      },
      {
      open: '31.6900000000000000',
      high: '35.2750000000000000',
      low: '31.3630000000000000',
      close: '35.1300000000000000',
      volume: '5764275.4540000000000000',
      quoteVolume: 196917985.91491917,
      btcVolume: 1940.686409307913,
      usdVolume: 102715801.07328665,
      time: '2021-04-26T00:00:00.000Z'
      },
      {
      open: '35.1120000000000000',
      high: '37.6650000000000000',
      low: '34.4830000000000000',
      close: '36.7880000000000000',
      volume: '5453945.1760000000000000',
      quoteVolume: 197434451.9676538,
      btcVolume: 3508.307389527573,
      usdVolume: 191567073.98651674,
      time: '2021-04-27T00:00:00.000Z'
      },
      {
      open: '36.7860000000000000',
      high: '37.8080000000000000',
      low: '34.9010000000000000',
      close: '36.2330000000000000',
      volume: '5218990.7380000000000000',
      quoteVolume: 188499275.87359715,
      btcVolume: 3443.115782242929,
      usdVolume: 188499275.87359715,
      time: '2021-04-28T00:00:00.000Z'
      },
      {
      open: '36.2530000000000000',
      high: '37.4500000000000000',
      low: '35.0000000000000000',
      close: '36.4670000000000000',
      volume: '4960983.1160000000000000',
      quoteVolume: 178985701.04202038,
      btcVolume: 3326.1200982145674,
      usdVolume: 178985701.04202038,
      time: '2021-04-29T00:00:00.000Z'
      },
      {
      open: '36.4650000000000000',
      high: '38.7900000000000000',
      low: '35.8270000000000000',
      close: '38.1000000000000000',
      volume: '5036107.6020000000000000',
      quoteVolume: 189862115.18069306,
      btcVolume: 2169.133779042782,
      usdVolume: 119700732.92635407,
      time: '2021-04-30T00:00:00.000Z'
      },
      {
      open: '38.1010000000000000',
      high: '41.2700000000000000',
      low: '37.4280000000000000',
      close: '41.2570000000000000',
      volume: '5223059.2340000000000000',
      quoteVolume: 205464629.83196887,
      btcVolume: 1552.158704895812,
      usdVolume: 89600807.00053962,
      time: '2021-05-01T00:00:00.000Z'
      },
      {
      open: '41.2490000000000000',
      high: '41.4600000000000000',
      low: '38.6900000000000000',
      close: '39.4610000000000000',
      volume: '5198773.9980000000000000',
      quoteVolume: 206970460.92982715,
      btcVolume: 2174.314081160398,
      usdVolume: 123461594.93853049,
      time: '2021-05-02T00:00:00.000Z'
      },
      {
      open: '39.4610000000000000',
      high: '42.7280000000000000',
      low: '39.1660000000000000',
      close: '42.0520000000000000',
      volume: '6010571.0640000000000000',
      quoteVolume: 246579333.6021091,
      btcVolume: 4045.734938153716,
      usdVolume: 233712903.15058511,
      time: '2021-05-03T00:00:00.000Z'
      },
      {
      open: '42.0600000000000000',
      high: '48.5400000000000000',
      low: '38.6340000000000000',
      close: '45.5270000000000000',
      volume: '19237312.3900000000000000',
      quoteVolume: 845809208.6302068,
      btcVolume: 10465.55258596805,
      usdVolume: 575221269.0704849,
      time: '2021-05-04T00:00:00.000Z'
      },
      {
      open: '45.5080000000000000',
      high: '51.2000000000000000',
      low: '44.8650000000000000',
      close: '49.5750000000000000',
      volume: '13527073.0750000000000000',
      quoteVolume: 657942604.5017196,
      btcVolume: 10857.379037079556,
      usdVolume: 601168078.6758198,
      time: '2021-05-05T00:00:00.000Z'
      },
      {
      open: '49.5760000000000000',
      high: '49.9000000000000000',
      low: '45.3430000000000000',
      close: '47.1540000000000000',
      volume: '9482621.1830000000000000',
      quoteVolume: 450955107.1350383,
      btcVolume: 7582.913753017384,
      usdVolume: 430590815.2935991,
      time: '2021-05-06T00:00:00.000Z'
      },
      {
      open: '47.1480000000000000',
      high: '51.9690000000000000',
      low: '45.6660000000000000',
      close: '49.1290000000000000',
      volume: '10577050.1670000000000000',
      quoteVolume: 515234804.58814085,
      btcVolume: 8494.174715359122,
      usdVolume: 484259627.57465845,
      time: '2021-05-07T00:00:00.000Z'
      },
      {
      open: '49.1340000000000000',
      high: '50.2650000000000000',
      low: '46.5000000000000000',
      close: '48.5970000000000000',
      volume: '5883822.5840000000000000',
      quoteVolume: 283989339.17529905,
      btcVolume: 4686.917318376704,
      usdVolume: 273537936.3731609,
      time: '2021-05-08T00:00:00.000Z'
      },
      {
      open: '48.6070000000000000',
      high: '52.4260000000000000',
      low: '45.8300000000000000',
      close: '52.2690000000000000',
      volume: '8588355.4620000000000000',
      quoteVolume: 426164154.7903979,
      btcVolume: 7030.02275298761,
      usdVolume: 405961264.1488087,
      time: '2021-05-09T00:00:00.000Z'
      },
      {
      open: '52.2770000000000000',
      high: '53.0000000000000000',
      low: '41.1600000000000000',
      close: '46.3340000000000000',
      volume: '9923820.9180000000000000',
      quoteVolume: 486677346.87598133,
      btcVolume: 6276.730764781867,
      usdVolume: 357244261.1626152,
      time: '2021-05-10T00:00:00.000Z'
      },
      {
      open: '46.3260000000000000',
      high: '49.0000000000000000',
      low: '44.8000000000000000',
      close: '48.8260000000000000',
      volume: '6709350.2410000000000000',
      quoteVolume: 312517087.9033863,
      btcVolume: 4159.683255202934,
      usdVolume: 232124988.1499912,
      time: '2021-05-11T00:00:00.000Z'
      },
      {
      open: '48.8050000000000000',
      high: '49.8360000000000000',
      low: '40.9140000000000000',
      close: '41.0070000000000000',
      volume: '8272510.7590000000000000',
      quoteVolume: 383764473.964816,
      btcVolume: 6696.055930825579,
      usdVolume: 367665436.85888445,
      time: '2021-05-12T00:00:00.000Z'
      },
      {
      open: '41.5410000000000000',
      high: '44.9580000000000000',
      low: '40.0660000000000000',
      close: '43.2870000000000000',
      volume: '9769701.1020000000000000',
      quoteVolume: 415729412.96724325,
      btcVolume: 7858.229520494778,
      usdVolume: 389116685.6106139,
      time: '2021-05-13T00:00:00.000Z'
      },
      {
      open: '43.2730000000000000',
      high: '47.8750000000000000',
      low: '42.9350000000000000',
      close: '46.5670000000000000',
      volume: '6297019.0800000000000000',
      quoteVolume: 286301712.8153783,
      btcVolume: 5462.904943375931,
      usdVolume: 274286488.952303,
      time: '2021-05-14T00:00:00.000Z'
      },
      {
      open: '46.5580000000000000',
      high: '47.8000000000000000',
      low: '40.9750000000000000',
      close: '41.4920000000000000',
      volume: '9851823.9350000000000000',
      quoteVolume: 432218709.2421017,
      btcVolume: 8544.101354516299,
      usdVolume: 415295386.7222744,
      time: '2021-05-15T00:00:00.000Z'
      },
      {
      open: '41.5120000000000000',
      high: '44.9010000000000000',
      low: '37.9440000000000000',
      close: '40.6700000000000000',
      volume: '9745811.4610000000000000',
      quoteVolume: 402376827.20252913,
      btcVolume: 7873.992844695695,
      usdVolume: 370599721.498782,
      time: '2021-05-16T00:00:00.000Z'
      },
      {
      open: '40.6620000000000000',
      high: '40.6740000000000000',
      low: '35.0680000000000000',
      close: '37.1770000000000000',
      volume: '10362229.7890000000000000',
      quoteVolume: 390099667.8940053,
      btcVolume: 8288.179682427983,
      usdVolume: 364544533.9681069,
      time: '2021-05-17T00:00:00.000Z'
      },
      {
      open: '37.1760000000000000',
      high: '44.6900000000000000',
      low: '36.6550000000000000',
      close: '42.5660000000000000',
      volume: '11232354.3090000000000000',
      quoteVolume: 458226371.3004289,
      btcVolume: 9965.215932435603,
      usdVolume: 436152928.96179956,
      time: '2021-05-18T00:00:00.000Z'
      },
      {
      open: '42.5550000000000000',
      high: '43.5000000000000000',
      low: '21.0000000000000000',
      close: '26.2270000000000000',
      volume: '20210187.3060000000000000',
      quoteVolume: 664059850.4303083,
      btcVolume: 13824.814824954283,
      usdVolume: 538586189.5562819,
      time: '2021-05-19T00:00:00.000Z'
      },
      {
      open: '26.2270000000000000',
      high: '33.0500000000000000',
      low: '22.3500000000000000',
      close: '30.6060000000000000',
      volume: '20323256.5380000000000000',
      quoteVolume: 577592046.0905249,
      btcVolume: 10784.808827983394,
      usdVolume: 422558516.6124092,
      time: '2021-05-20T00:00:00.000Z'
      },
      {
      open: '30.6330000000000000',
      high: '31.9450000000000000',
      low: '22.0190000000000000',
      close: '25.5460000000000000',
      volume: '13068081.0750000000000000',
      quoteVolume: 353993914.03694314,
      btcVolume: 6932.648993527497,
      usdVolume: 266425708.4751106,
      time: '2021-05-21T00:00:00.000Z'
      },
      {
      open: '25.5480000000000000',
      high: '26.2000000000000000',
      low: '22.2250000000000000',
      close: '23.2960000000000000',
      volume: '13032278.1370000000000000',
      quoteVolume: 314829379.610612,
      btcVolume: 5695.951410606267,
      usdVolume: 211218954.28534862,
      time: '2021-05-22T00:00:00.000Z'
      },
      {
      open: '23.2930000000000000',
      high: '24.3680000000000000',
      low: '15.0000000000000000',
      close: '19.8440000000000000',
      volume: '28816332.7880000000000000',
      quoteVolume: 543985798.5938362,
      btcVolume: 15126.42507829283,
      usdVolume: 517948800.9596924,
      time: '2021-05-23T00:00:00.000Z'
      },
      {
      open: '19.8490000000000000',
      high: '26.4310000000000000',
      low: '19.6770000000000000',
      close: '26.4120000000000000',
      volume: '18217984.6480000000000000',
      quoteVolume: 425942553.28623766,
      btcVolume: 10844.812716329057,
      usdVolume: 401451530.6456157,
      time: '2021-05-24T00:00:00.000Z'
      },
      {
      open: '26.4000000000000000',
      high: '27.6440000000000000',
      low: '23.6730000000000000',
      close: '27.5030000000000000',
      volume: '13594283.8180000000000000',
      quoteVolume: 349644756.5528623,
      btcVolume: 8716.278447151888,
      usdVolume: 331359167.24137896,
      time: '2021-05-25T00:00:00.000Z'
      },
      {
      open: '27.5050000000000000',
      high: '34.2850000000000000',
      low: '26.8470000000000000',
      close: '34.0180000000000000',
      volume: '14862009.4600000000000000',
      quoteVolume: 456256699.2802189,
      btcVolume: 11201.464045901757,
      usdVolume: 439295034.0456097,
      time: '2021-05-26T00:00:00.000Z'
      },
      {
      open: '34.0300000000000000',
      high: '35.3340000000000000',
      low: '30.0330000000000000',
      close: '31.7320000000000000',
      volume: '14928881.5110000000000000',
      quoteVolume: 489547108.0042761,
      btcVolume: 12329.95986455298,
      usdVolume: 477724155.1958763,
      time: '2021-05-27T00:00:00.000Z'
      },
      {
      open: '31.7230000000000000',
      high: '32.1850000000000000',
      low: '26.8210000000000000',
      close: '27.8780000000000000',
      volume: '18297258.3920000000000000',
      quoteVolume: 533840739.506926,
      btcVolume: 13937.929959889794,
      usdVolume: 508564072.9063156,
      time: '2021-05-28T00:00:00.000Z'
      },
      {
      open: '27.8720000000000000',
      high: '29.7340000000000000',
      low: '24.1200000000000000',
      close: '25.3650000000000000',
      volume: '11813482.7710000000000000',
      quoteVolume: 314260807.1404808,
      btcVolume: 8707.412591102062,
      usdVolume: 305391847.5639692,
      time: '2021-05-29T00:00:00.000Z'
      },
      {
      open: '25.3770000000000000',
      high: '29.1680000000000000',
      low: '23.7000000000000000',
      close: '26.8180000000000000',
      volume: '11542374.4850000000000000',
      quoteVolume: 311943247.68354595,
      btcVolume: 8749.034315009025,
      usdVolume: 309860777.6783972,
      time: '2021-05-30T00:00:00.000Z'
      },
      {
      open: '26.8130000000000000',
      high: '32.1590000000000000',
      low: '25.5550000000000000',
      close: '32.0980000000000000',
      volume: '11473684.1530000000000000',
      quoteVolume: 330074023.8033171,
      btcVolume: 9049.198752953676,
      usdVolume: 328513729.3170985,
      time: '2021-05-31T00:00:00.000Z'
      },
      {
      open: '32.0980000000000000',
      high: '32.6500000000000000',
      low: '29.0350000000000000',
      close: '30.7190000000000000',
      volume: '7595288.2510000000000000',
      quoteVolume: 231579652.6416101,
      btcVolume: 6228.039389492023,
      usdVolume: 228605999.2276237,
      time: '2021-06-01T00:00:00.000Z'
      },
      {
      open: '30.7290000000000000',
      high: '32.2180000000000000',
      low: '29.7000000000000000',
      close: '30.6700000000000000',
      volume: '5992710.5570000000000000',
      quoteVolume: 184925689.9538908,
      btcVolume: 4910.509911672765,
      usdVolume: 183636440.3796502,
      time: '2021-06-02T00:00:00.000Z'
      },
      {
      open: '30.6690000000000000',
      high: '32.8560000000000000',
      low: '30.0000000000000000',
      close: '32.1980000000000000',
      volume: '6926586.1530000000000000',
      quoteVolume: 220195095.12678242,
      btcVolume: 5686.208264256343,
      usdVolume: 219529228.41781366,
      time: '2021-06-03T00:00:00.000Z'
      },
      {
      open: '32.1990000000000000',
      high: '32.2670000000000000',
      low: '27.1720000000000000',
      close: '28.8260000000000000',
      volume: '10756129.4560000000000000',
      quoteVolume: 310574364.8097001,
      btcVolume: 8079.12819316762,
      usdVolume: 299137467.401279,
      time: '2021-06-04T00:00:00.000Z'
      },
      {
      open: '28.8290000000000000',
      high: '30.1860000000000000',
      low: '26.0440000000000000',
      close: '27.1290000000000000',
      volume: '8021172.9850000000000000',
      quoteVolume: 224556115.0710708,
      btcVolume: 6072.610138454598,
      usdVolume: 220742793.7734021,
      time: '2021-06-05T00:00:00.000Z'
      },
      {
      open: '27.1270000000000000',
      high: '28.1900000000000000',
      low: '26.8180000000000000',
      close: '27.5320000000000000',
      volume: '4071729.1110000000000000',
      quoteVolume: 111593094.49793832,
      btcVolume: 3094.66184741806,
      usdVolume: 111191327.61157948,
      time: '2021-06-06T00:00:00.000Z'
      },
      {
      open: '27.5300000000000000',
      high: '28.6930000000000000',
      low: '24.2340000000000000',
      close: '24.5840000000000000',
      volume: '7593474.6300000000000000',
      quoteVolume: 203546009.9958056,
      btcVolume: 5664.404814150535,
      usdVolume: 201030726.639649,
      time: '2021-06-07T00:00:00.000Z'
      },
      {
      open: '24.5740000000000000',
      high: '25.0980000000000000',
      low: '21.3340000000000000',
      close: '24.0760000000000000',
      volume: '12533550.4740000000000000',
      quoteVolume: 295383608.8363167,
      btcVolume: 8748.60414936731,
      usdVolume: 286178492.67321956,
      time: '2021-06-08T00:00:00.000Z'
      },
      {
      open: '24.0790000000000000',
      high: '25.6260000000000000',
      low: '22.4410000000000000',
      close: '25.5850000000000000',
      volume: '10755352.9180000000000000',
      quoteVolume: 259836017.6864675,
      btcVolume: 7275.701651707724,
      usdVolume: 253653794.22880164,
      time: '2021-06-09T00:00:00.000Z'
      },
      {
      open: '25.5890000000000000',
      high: '25.7800000000000000',
      low: '22.7210000000000000',
      close: '23.1140000000000000',
      volume: '8385905.0280000000000000',
      quoteVolume: 201718092.68922827,
      btcVolume: 5398.213856768574,
      usdVolume: 199614065.45911995,
      time: '2021-06-10T00:00:00.000Z'
      },
      {
      open: '23.1170000000000000',
      high: '23.3740000000000000',
      low: '21.0000000000000000',
      close: '21.4780000000000000',
      volume: '7674813.3900000000000000',
      quoteVolume: 172255303.31648567,
      btcVolume: 4653.500277683774,
      usdVolume: 171879481.40592545,
      time: '2021-06-11T00:00:00.000Z'
      },
      {
      open: '21.4750000000000000',
      high: '22.2870000000000000',
      low: '20.0000000000000000',
      close: '21.6700000000000000',
      volume: '8849284.6130000000000000',
      quoteVolume: 186854318.51986963,
      btcVolume: 5161.93685251144,
      usdVolume: 184384310.62285846,
      time: '2021-06-12T00:00:00.000Z'
      },
      {
      open: '21.6710000000000000',
      high: '23.8860000000000000',
      low: '20.3960000000000000',
      close: '23.3120000000000000',
      volume: '8516193.5180000000000000',
      quoteVolume: 184993026.3649717,
      btcVolume: 5018.546755318045,
      usdVolume: 183990625.90337497,
      time: '2021-06-13T00:00:00.000Z'
      },
      {
      open: '23.3140000000000000',
      high: '25.2800000000000000',
      low: '22.8370000000000000',
      close: '24.9730000000000000',
      volume: '8598594.0080000000000000',
      quoteVolume: 205633987.74170867,
      btcVolume: 5124.726546014837,
      usdVolume: 204447032.09525958,
      time: '2021-06-14T00:00:00.000Z'
      },
      {
      open: '24.9670000000000000',
      high: '26.4580000000000000',
      low: '24.3760000000000000',
      close: '24.4820000000000000',
      volume: '7585322.2020000000000000',
      quoteVolume: 193173661.50221747,
      btcVolume: 4783.978518048711,
      usdVolume: 192339881.37543938,
      time: '2021-06-15T00:00:00.000Z'
      },
      {
      open: '24.4800000000000000',
      high: '24.6400000000000000',
      low: '22.9180000000000000',
      close: '23.0440000000000000',
      volume: '7495769.8280000000000000',
      quoteVolume: 178312029.61373052,
      btcVolume: 4478.7932855389,
      usdVolume: 176264709.6132166,
      time: '2021-06-16T00:00:00.000Z'
      },
      {
      open: '23.0420000000000000',
      high: '24.4450000000000000',
      low: '22.8100000000000000',
      close: '23.3960000000000000',
      volume: '5230276.4320000000000000',
      quoteVolume: 123641681.8274587,
      btcVolume: 3158.8471853964566,
      usdVolume: 121967618.36204572,
      time: '2021-06-17T00:00:00.000Z'
      },
      {
      open: '23.4010000000000000',
      high: '23.4250000000000000',
      low: '20.5260000000000000',
      close: '21.2330000000000000',
      volume: '6979309.1900000000000000',
      quoteVolume: 152221963.2704614,
      btcVolume: 4077.4579812512793,
      usdVolume: 150001420.01759854,
      time: '2021-06-18T00:00:00.000Z'
      },
      {
      open: '21.2360000000000000',
      high: '21.9500000000000000',
      low: '20.0000000000000000',
      close: '20.2460000000000000',
      volume: '5015956.9490000000000000',
      quoteVolume: 105884657.21403985,
      btcVolume: 2957.13775712415,
      usdVolume: 105574108.67201178,
      time: '2021-06-19T00:00:00.000Z'
      },
      {
      open: '20.2630000000000000',
      high: '22.0710000000000000',
      low: '19.0770000000000000',
      close: '21.6460000000000000',
      volume: '6709724.1760000000000000',
      quoteVolume: 136982147.47085837,
      btcVolume: 3882.7672656123696,
      usdVolume: 135510283.7439176,
      time: '2021-06-20T00:00:00.000Z'
      },
      {
      open: '21.6410000000000000',
      high: '21.8810000000000000',
      low: '16.9810000000000000',
      close: '17.3650000000000000',
      volume: '16151190.0200000000000000',
      quoteVolume: 304924588.43656045,
      btcVolume: 9030.919682300091,
      usdVolume: 296698047.37943095,
      time: '2021-06-21T00:00:00.000Z'
      },
      {
      open: '17.3570000000000000',
      high: '18.7970000000000000',
      low: '15.0000000000000000',
      close: '16.8500000000000000',
      volume: '19773357.5780000000000000',
      quoteVolume: 331030753.695022,
      btcVolume: 10016.698622465823,
      usdVolume: 314934189.5838592,
      time: '2021-06-22T00:00:00.000Z'
      },
      {
      open: '16.8400000000000000',
      high: '19.1990000000000000',
      low: '16.1490000000000000',
      close: '18.3950000000000000',
      volume: '10930842.9690000000000000',
      quoteVolume: 198104336.2413875,
      btcVolume: 5677.69271694415,
      usdVolume: 191351833.36313608,
      time: '2021-06-23T00:00:00.000Z'
      },
      {
      open: '18.3930000000000000',
      high: '19.5250000000000000',
      low: '17.3520000000000000',
      close: '19.0600000000000000',
      volume: '6708926.7880000000000000',
      quoteVolume: 124897019.4366688,
      btcVolume: 3676.6447137809605,
      usdVolume: 124160107.18648963,
      time: '2021-06-24T00:00:00.000Z'
      },
      {
      open: '19.0600000000000000',
      high: '19.5000000000000000',
      low: '16.8130000000000000',
      close: '16.8700000000000000',
      volume: '10048473.2290000000000000',
      quoteVolume: 180145249.5753493,
      btcVolume: 5424.613837041398,
      usdVolume: 179815215.34201533,
      time: '2021-06-25T00:00:00.000Z'
      },
      {
      open: '16.8720000000000000',
      high: '17.4950000000000000',
      low: '15.9200000000000000',
      close: '16.9520000000000000',
      volume: '8343823.1670000000000000',
      quoteVolume: 138685945.58320868,
      btcVolume: 4317.21304493639,
      usdVolume: 135592125.3028405,
      time: '2021-06-26T00:00:00.000Z'
      },
      {
      open: '16.9510000000000000',
      high: '18.4120000000000000',
      low: '16.5910000000000000',
      close: '18.4060000000000000',
      volume: '6649981.0660000000000000',
      quoteVolume: 114152516.37204297,
      btcVolume: 3403.722727537482,
      usdVolume: 112739292.89915286,
      time: '2021-06-27T00:00:00.000Z'
      },
      {
      open: '18.4070000000000000',
      high: '19.4340000000000000',
      low: '17.9020000000000000',
      close: '18.8300000000000000',
      volume: '7282776.6190000000000000',
      quoteVolume: 135071888.30002892,
      btcVolume: 3906.1907284798945,
      usdVolume: 134704190.30824476,
      time: '2021-06-28T00:00:00.000Z'
      },
      {
      open: '18.8300000000000000',
      high: '20.5460000000000000',
      low: '18.7520000000000000',
      close: '19.5350000000000000',
      volume: '6574919.5160000000000000',
      quoteVolume: 129862268.00902891,
      btcVolume: 3645.3920298803437,
      usdVolume: 129659684.4622509,
      time: '2021-06-29T00:00:00.000Z'
      },
      {
      open: '19.5310000000000000',
      high: '19.7730000000000000',
      low: '18.1490000000000000',
      close: '19.5160000000000000',
      volume: '6554785.3410000000000000',
      quoteVolume: 124177607.62473075,
      btcVolume: 3561.7088830145212,
      usdVolume: 124094990.07156971,
      time: '2021-06-30T00:00:00.000Z'
      },
      {
      open: '19.5170000000000000',
      high: '19.5330000000000000',
      low: '17.6030000000000000',
      close: '18.1860000000000000',
      volume: '6074285.8190000000000000',
      quoteVolume: 110566739.74832156,
      btcVolume: 3272.3128181398106,
      usdVolume: 110064065.08651572,
      time: '2021-07-01T00:00:00.000Z'
      },
      {
      open: '18.1840000000000000',
      high: '18.5230000000000000',
      low: '17.2560000000000000',
      close: '18.2740000000000000',
      volume: '5394371.4130000000000000',
      quoteVolume: 95752765.26831836,
      btcVolume: 2873.9523665643746,
      usdVolume: 95533297.25328133,
      time: '2021-07-02T00:00:00.000Z'
      },
      {
      open: '18.2770000000000000',
      high: '18.8940000000000000',
      low: '17.8640000000000000',
      close: '18.5430000000000000',
      volume: '3247443.1790000000000000',
      quoteVolume: 59971889.21668191,
      btcVolume: 1743.3323281938071,
      usdVolume: 59881835.060770914,
      time: '2021-07-03T00:00:00.000Z'
      },
      {
      open: '18.5430000000000000',
      high: '19.9250000000000000',
      low: '18.0020000000000000',
      close: '19.2420000000000000',
      volume: '4486291.0750000000000000',
      quoteVolume: 85193944.29341578,
      btcVolume: 2409.86380003794,
      usdVolume: 85114681.7393157,
      time: '2021-07-04T00:00:00.000Z'
      },
      {
      open: '19.2380000000000000',
      high: '19.2420000000000000',
      low: '17.7670000000000000',
      close: '18.3620000000000000',
      volume: '5244315.0980000000000000',
      quoteVolume: 96893912.50225921,
      btcVolume: 2815.4797708645337,
      usdVolume: 95800634.92579712,
      time: '2021-07-05T00:00:00.000Z'
      },
      {
      open: '18.3640000000000000',
      high: '20.2710000000000000',
      low: '18.3170000000000000',
      close: '20.0730000000000000',
      volume: '7942424.0840000000000000',
      quoteVolume: 155645652.46806923,
      btcVolume: 4515.283695510405,
      usdVolume: 154167558.60065004,
      time: '2021-07-06T00:00:00.000Z'
      },
      {
      open: '20.0730000000000000',
      high: '21.2740000000000000',
      low: '19.6200000000000000',
      close: '19.7880000000000000',
      volume: '7220562.4210000000000000',
      quoteVolume: 148395155.42902967,
      btcVolume: 4299.919566919362,
      usdVolume: 148395155.42902967,
      time: '2021-07-07T00:00:00.000Z'
      },
      {
      open: '19.7860000000000000',
      high: '19.8730000000000000',
      low: '18.1380000000000000',
      close: '18.3530000000000000',
      volume: '7787413.9360000000000000',
      quoteVolume: 146836983.45400667,
      btcVolume: 4442.217674403576,
      usdVolume: 145991527.75807318,
      time: '2021-07-08T00:00:00.000Z'
      },
      {
      open: '18.3650000000000000',
      high: '18.8770000000000000',
      low: '17.6170000000000000',
      close: '18.6560000000000000',
      volume: '5272793.7130000000000000',
      quoteVolume: 96043496.92060412,
      btcVolume: 2903.464250314309,
      usdVolume: 96030029.07034913,
      time: '2021-07-09T00:00:00.000Z'
      },
      {
      open: '18.6560000000000000',
      high: '18.9880000000000000',
      low: '17.7340000000000000',
      close: '18.2040000000000000',
      volume: '4078447.0630000000000000',
      quoteVolume: 74240182.6654956,
      btcVolume: 2201.704167208646,
      usdVolume: 74224861.40051863,
      time: '2021-07-10T00:00:00.000Z'
      },
      {
      open: '18.2090000000000000',
      high: '18.5810000000000000',
      low: '17.8610000000000000',
      close: '18.3830000000000000',
      volume: '2730333.2010000000000000',
      quoteVolume: 49800226.79839872,
      btcVolume: 1472.494351442755,
      usdVolume: 49793240.52506473,
      time: '2021-07-11T00:00:00.000Z'
      },
      {
      open: '18.3820000000000000',
      high: '18.6090000000000000',
      low: '17.0000000000000000',
      close: '17.5180000000000000',
      volume: '3783915.1710000000000000',
      quoteVolume: 67410348.47561233,
      btcVolume: 2009.4146255562289,
      usdVolume: 67298959.85275029,
      time: '2021-07-12T00:00:00.000Z'
      },
      {
      open: '17.5140000000000000',
      high: '17.7660000000000000',
      low: '16.6630000000000000',
      close: '16.8700000000000000',
      volume: '3488186.4250000000000000',
      quoteVolume: 60230003.646684654,
      btcVolume: 1836.6843254841956,
      usdVolume: 60221186.657093674,
      time: '2021-07-13T00:00:00.000Z'
      },
      {
      open: '16.8730000000000000',
      high: '17.4190000000000000',
      low: '16.0140000000000000',
      close: '16.9360000000000000',
      volume: '5441562.0200000000000000',
      quoteVolume: 90914973.0594299,
      btcVolume: 2788.0780014295497,
      usdVolume: 90500474.54788788,
      time: '2021-07-14T00:00:00.000Z'
      },
      {
      open: '16.9360000000000000',
      high: '17.2380000000000000',
      low: '15.5170000000000000',
      close: '15.7640000000000000',
      volume: '6198386.0130000000000000',
      quoteVolume: 99985406.05859742,
      btcVolume: 3118.566762095075,
      usdVolume: 99653864.88515042,
      time: '2021-07-15T00:00:00.000Z'
      },
      {
      open: '15.7640000000000000',
      high: '16.3300000000000000',
      low: '15.1250000000000000',
      close: '15.2340000000000000',
      volume: '4106915.0750000000000000',
      quoteVolume: 64218657.385411024,
      btcVolume: 2026.355929873666,
      usdVolume: 64218546.10895903,
      time: '2021-07-16T00:00:00.000Z'
      },
      {
      open: '15.2340000000000000',
      high: '15.6780000000000000',
      low: '15.0200000000000000',
      close: '15.3520000000000000',
      volume: '4957872.3330000000000000',
      quoteVolume: 76011680.64757319,
      btcVolume: 2401.8918472972196,
      usdVolume: 75811199.49437419,
      time: '2021-07-17T00:00:00.000Z'
      },
      {
      open: '15.3520000000000000',
      high: '16.0780000000000000',
      low: '15.1550000000000000',
      close: '15.4700000000000000',
      volume: '3946657.1550000000000000',
      quoteVolume: 61781931.20700029,
      btcVolume: 1945.5860240070042,
      usdVolume: 61763430.72707828,
      time: '2021-07-18T00:00:00.000Z'
      },
      {
      open: '15.4750000000000000',
      high: '15.6410000000000000',
      low: '14.4040000000000000',
      close: '14.5280000000000000',
      volume: '5828975.8470000000000000',
      quoteVolume: 86676083.7316871,
      btcVolume: 2787.3535740700127,
      usdVolume: 86630306.81259608,
      time: '2021-07-19T00:00:00.000Z'
      },
      {
      open: '14.5290000000000000',
      high: '14.7830000000000000',
      low: '13.3840000000000000',
      close: '13.7420000000000000',
      volume: '7649757.9780000000000000',
      quoteVolume: 105449843.69424452,
      btcVolume: 3529.459900354445,
      usdVolume: 105367660.82367453,
      time: '2021-07-20T00:00:00.000Z'
      },
      {
      open: '13.7440000000000000',
      high: '15.7970000000000000',
      low: '13.4600000000000000',
      close: '15.2970000000000000',
      volume: '8493449.3690000000000000',
      quoteVolume: 125644680.88342938,
      btcVolume: 3997.9525201581914,
      usdVolume: 124994012.93451238,
      time: '2021-07-21T00:00:00.000Z'
      },
      {
      open: '15.3000000000000000',
      high: '16.1760000000000000',
      low: '14.9610000000000000',
      close: '16.0710000000000000',
      volume: '5857032.9790000000000000',
      quoteVolume: 90738306.1379759,
      btcVolume: 2820.8161649730764,
      usdVolume: 90645833.05113186,
      time: '2021-07-22T00:00:00.000Z'
      },
      {
      open: '16.0730000000000000',
      high: '16.4860000000000000',
      low: '15.2300000000000000',
      close: '15.5970000000000000',
      volume: '4154495.5190000000000000',
      quoteVolume: 66029359.02171643,
      btcVolume: 2038.14247445674,
      usdVolume: 66029359.02171643,
      time: '2021-07-23T00:00:00.000Z'
      }
    ]
  },
};
