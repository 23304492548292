/* eslint-disable max-len */
import { Component, OnInit, NgZone, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';

declare let window;

@Component({
  selector: 'app-wave-particle-animation',
  templateUrl: './wave-particle-animation.component.html',
  styleUrls: ['./wave-particle-animation.component.scss'],
})
export class WaveParticleAnimationComponent implements OnInit {
  data = ['line', 'point', 'rhombus', 'pentahedron', 'circle', 'x'];
  particles = [];
  isPaused = false;
  interval;

  constructor(
  ) { }

  ngOnInit() {
    window.onblur = () => {
        this.isPaused = true;
    };
    window.onfocus = () => {
        this.isPaused = false;
    };
    this.interval = setInterval(() => {
      if (!this.isPaused){
          this.particles.push({
              svg: this.data[this.randomInt(0,this.data.length-1)],
              coordinates: {
                x: (Math.random() * $('#wrapper').width()),
                y: $('#wrapper').height()
              },
              friction: 1 + Math.random() * 3,
              id: Math.random() * 10000,
              animate: true
            });
      }
    }, 400);
  }

  randomInt(min,max){
    return Math.floor(Math.random()*(max-min+1)+min);
  }

  removeMe(id) {
    this.particles = this.particles.filter((p) => p.id !== id);
  }

  update(){
    // this.particles = this.particles.filter((p) => {
    //   p.move();
    // });
  }
}
