import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppStoreService } from './app-store.service';

@Injectable({
  providedIn: 'root'
})
export class FakeLiveDataService {

  botReward: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  interval: any;
  started = false;

  constructor(
    private appStore: AppStoreService
  ) { }

  init() {
    if(!this.started) {
      this.started = true;
      this.interval = setInterval(() => {
        const random = Math.random()/10;
        this.botReward.next(random);
        this.appStore.bots[0].unclaimed += random;
      },1000);
    }
  }
}
