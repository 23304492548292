import { Component, OnInit } from '@angular/core';
import { coinLibrary } from '../../models/common/coinLibrary';

@Component({
  selector: 'app-bond-history',
  templateUrl: './bond-history.component.html',
  styleUrls: ['./bond-history.component.scss']
})
export class BondHistoryComponent implements OnInit {

  bonds = [
    {
      name: 'Empower Bond',
      value: '500',
      currency: 'USDT',
      rewards: '25.24',
      status: 'In Progress'
    },
    {
      name: 'Empower Bond',
      value: '500',
      currency: 'USDT',
      rewards: '51.12',
      status: 'Complete'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
