import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'app-wrong-address-modal',
  templateUrl: './wrong-address-modal.component.html',
  styleUrls: ['./wrong-address-modal.component.scss'],
})
export class WrongAddressModalComponent implements OnInit {
  falseAddress: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<WrongAddressModalComponent>,
    public walletService: WalletService
  ) {}

  ngOnInit() {
    this.falseAddress = this.data.address;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
