import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class AssetDataService {

  assetData;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private websocketService: WebsocketService
  ) {
    // this.websocketService.getMessage()
    //   .subscribe((response: any) => {
    //     if (response.recordType === 'bonus' && this.authService.authObj.sessIDstr.indexOf(response.sessKeys?.subkey) === 0) {
    //       this.getMoneyHistory();
    //     }
    //   });
  }

  getMoneyHistory() {
    let params = new HttpParams();
    if (this.authService.authObj.isLoggedIn) {
      params = params.set('sessIDstr', this.authService.authObj.sessIDstr);
    }
    // return this.http.get(environment.rewardsHistoryEndpoint, {
    //   params
    // }).subscribe((data) => {
    //   this.assetData = data;
    // });
  }

  assetDataInterval() {
    // this.getMoneyHistory();
    // setInterval(() => {
    //   this.getMoneyHistory();
    // }, 30000);
  }
}
