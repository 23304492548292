import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalProperties } from 'src/app/constants/set-modal-properties';
import { BuySubscriptionCreditsComponent } from '../buy-subscription-credits/buy-subscription-credits.component';
import { ConvertCreidtsComponent } from '../convert-creidts/convert-creidts.component';
import { TransferCreditModalComponent } from '../transfer-credit-modal/transfer-credit-modal.component';

@Component({
  selector: 'app-generic-history-modal',
  templateUrl: './generic-history-modal.component.html',
  styleUrls: ['./generic-history-modal.component.scss']
})
export class GenericHistoryModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<GenericHistoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    ModalProperties.setBaseParams();
  }

  close() {
    this.dialogRef.close();
  }

  openTransferCredit() {
    this.close();
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    } //end ELSE

    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];

    this.dialog.open(TransferCreditModalComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  openWithdrawCredits() {
    this.close();
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    } //end ELSE

    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    ModalProperties.properties.data = {
      data : {
        withdraw: true
      }
    };

    this.dialog.open(ConvertCreidtsComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

    openBuySubscriptionCredits() {
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    } //end ELSE

    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];

    this.dialog.open(BuySubscriptionCreditsComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  openConvertCredits() {
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    } //end ELSE

    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    ModalProperties.properties.data = {
      data : {
        withdraw: false
      }
    };

    this.dialog.open(ConvertCreidtsComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

}
