/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CoinAsset } from '../models/common/coin-asset';
import { coinLibrary } from '../models/common/coinLibrary';
import { circulationContract, usdtAddress } from '../constants/addresses';

@Injectable({
  providedIn: 'root',
})
export class CoinListService {
  data: CoinAsset[] = [
    {
      id: 'btc',
      name: 'BTC',
      symbol: 'BTC',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/BTC.png',
      activity24h: 241256,
      usdxConversion: 1,
      allTimeValue: 512390,
      allTimeAsset: 515123,
      info: 'usdt-coin-info',
      live: true,
      price: 33000
    },
    {
      id: 'eth',
      name: 'ETH',
      symbol: 'ETH',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/ETH.png',
      activity24h: 41256,
      usdxConversion: 1,
      allTimeValue: 1512390,
      allTimeAsset: 5151239,
      info: 'usdt-coin-info',
      live: true,
      price: 3000
    },
    {
      id: 'bnb',
      name: 'BNB',
      symbol: 'BNB',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/BNB.png',
      activity24h: 124125,
      usdxConversion: 1,
      allTimeValue: 12390,
      allTimeAsset: 51512,
      info: 'usdt-coin-info',
      live: true,
      price: 428.53
    },
    {
      id: 'aave',
      name: 'AAVE',
      symbol: 'AAVE',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/AAVE.png',
      activity24h: 41256,
      usdxConversion: 1,
      allTimeValue: 1512390,
      allTimeAsset: 5151239,
      info: 'usdt-coin-info',
      live: true,
      price: 190.84
    },
    {
      id: 'ada',
      name: 'ADA',
      symbol: 'ADA',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/ADA.png',
      activity24h: 241256,
      usdxConversion: 1,
      allTimeValue: 512390,
      allTimeAsset: 515123,
      info: 'usdt-coin-info',
      live: true,
      price: 1.23
    },
    {
      id: 'ankr',
      name: 'ANKR',
      symbol: 'ANKR',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/ANKR.png',
      activity24h: 41256,
      usdxConversion: 1,
      allTimeValue: 1512390,
      allTimeAsset: 5151239,
      info: 'usdt-coin-info',
      live: true,
      price: 0.07
    },
    {
      id: 'atom',
      name: 'ATOM',
      symbol: 'ATOM',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/ATOM.png',
      activity24h: 241256,
      usdxConversion: 1,
      allTimeValue: 512390,
      allTimeAsset: 515123,
      info: 'usdt-coin-info',
      live: true,
      price: 36.96
    },
    {
      id: 'avax',
      name: 'AVAX',
      symbol: 'AVAX',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/AVAX.png',
      activity24h: 124125,
      usdxConversion: 1,
      allTimeValue: 12390,
      allTimeAsset: 51512,
      info: 'usdt-coin-info',
      live: true,
      price: 76.66
    },
    {
      id: 'bat',
      name: 'BAT',
      symbol: 'BAT',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/BAT.png',
      activity24h: 41256,
      usdxConversion: 1,
      allTimeValue: 1512390,
      allTimeAsset: 5151239,
      info: 'usdt-coin-info',
      live: true,
      price: 0.88
    },
    {
      id: 'bch',
      name: 'BCH',
      symbol: 'BCH',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/BCH.png',
      activity24h: 241256,
      usdxConversion: 1,
      allTimeValue: 512390,
      allTimeAsset: 515123,
      info: 'usdt-coin-info',
      live: true,
      price: 344.13
    },
    {
      id: 'bsv',
      name: 'BSV',
      symbol: 'BSV',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/BSV.png',
      activity24h: 124125,
      usdxConversion: 1,
      allTimeValue: 12390,
      allTimeAsset: 51512,
      info: 'usdt-coin-info',
      live: true,
      price: 103
    },
    {
      id: 'btg',
      name: 'BTG',
      symbol: 'BTG',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/BTC.png',
      activity24h: 41256,
      usdxConversion: 1,
      allTimeValue: 1512390,
      allTimeAsset: 5151239,
      info: 'usdt-coin-info',
      live: true,
      price: 32.98
    },
    {
      id: 'btt',
      name: 'BTT',
      symbol: 'BTT',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/BTT.png',
      activity24h: 241256,
      usdxConversion: 1,
      allTimeValue: 512390,
      allTimeAsset: 515123,
      info: 'usdt-coin-info',
      live: true,
      price: .01
    },
    {
      id: 'ceth',
      name: 'CETH',
      symbol: 'CETH',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/CETH.png',
      activity24h: 241256,
      usdxConversion: 1,
      allTimeValue: 512390,
      allTimeAsset: 515123,
      info: 'usdt-coin-info',
      live: true,
      price: 5
    },
    {
      id: 'comp',
      name: 'COMP',
      symbol: 'COMP',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/COMP.png',
      activity24h: 124125,
      usdxConversion: 1,
      allTimeValue: 12390,
      allTimeAsset: 51512,
      info: 'usdt-coin-info',
      live: true,
      price: 154.25
    },
    {
      id: 'cro',
      name: 'CRO',
      symbol: 'CRO',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/CRO.png',
      activity24h: 41256,
      usdxConversion: 1,
      allTimeValue: 1512390,
      allTimeAsset: 5151239,
      info: 'usdt-coin-info',
      live: true,
      price: .4
    },
    {
      id: 'dash',
      name: 'DASH',
      symbol: 'DASH',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/DASH.png',
      activity24h: 241256,
      usdxConversion: 1,
      allTimeValue: 512390,
      allTimeAsset: 515123,
      info: 'usdt-coin-info',
      live: true,
      price: 118.43
    },
    {
      id: 'cel',
      name: 'CEL',
      symbol: 'CEL',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/CEL.png',
      activity24h: 124125,
      usdxConversion: 1,
      allTimeValue: 12390,
      allTimeAsset: 51512,
      info: 'usdt-coin-info',
      live: true,
      price: 3.01
    },
  ];

  constructor(private http: HttpClient) {}

  getCoinsList() {
    return this.data;
  }

  getCoinInfo(id: string) {
    const coin = this.data.filter(
      (x: any) => x.id?.toLowerCase() === id?.toLowerCase()
    )[0];
    return of(coin);
  }

  getCandleData(id: string) {
    return of(coinLibrary[id.toLowerCase()].candleData).pipe(delay(500));
  }
}
