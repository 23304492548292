import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FilterOption } from '../../models/common/filter-option';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AppStoreService } from '../../services/app-store.service';
import { Subscription } from 'rxjs';
import { CoinListService } from '../../services/coin-list.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  animations: [
    trigger('buttonAnimation', [
      state('first',
        style({
          transform: 'translateX(0%)'
        })
      ),
      state('second',
        style({
          transform: 'translateX(100%)'
        })
      ),
      state('third',
        style({
          transform: 'translateX(200%)'
        })
      ),
      transition('* => *', [
        animate('0.25s cubic-bezier(1,.01,.59,.58)')
      ])
    ])
  ]
})
export class FilterComponent implements OnInit, OnDestroy {

  @Input() options: FilterOption[] = [
    {text: 'tokens', active: false, route: '/dashboard', image: 'icon-coin'},
    {text: 'services', active: false, route: '/dashboard/services', image: 'icon-technical-support'},
    {text: 'get-smarter', active: false, route: '/dashboard', image: 'icon-lightbulb'}
  ];
  @Output() clickEvent = new EventEmitter<any>();
  @Output() openEvent = new EventEmitter<any>();

  state = 'first';
  clicked = false;
  subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public appStore: AppStoreService,
    public coinService: CoinListService
  ) { }

  ngOnInit(): void {
    this.initRouteCheck();
    this.subscription = this.router.events.subscribe((res: any) => {
      if(res instanceof NavigationEnd) {
        let lastVisited;
        if(this.router.url.includes('services')) {
          lastVisited = localStorage.getItem('dashboardService');
          this.clickEvent.emit(this.options[1]);
          if(lastVisited) {
            this.router.navigate(['/dashboard/services'], {queryParams: {type: lastVisited}});
          } else {
            this.router.navigate(['/dashboard/services'], {queryParams: {type: 'order-bots'}});
          }
        } else {
          lastVisited = localStorage.getItem('empowerLastVisited');
          this.clickEvent.emit(this.options[0]);
          if(lastVisited) {
            this.router.navigate(['/dashboard/' + lastVisited]);
          } else {
            this.router.navigate(['/dashboard/BTC']);
          }
        }
      }
    });
  }

  initRouteCheck() {
    if(this.router.url.includes('services')) {
      this.state = 'second';
      this.clickEvent.emit(this.options[1]);
    } else {
      this.state = 'first';
      this.clickEvent.emit(this.options[0]);
    }
  }

  onClick(option: FilterOption) {
    let button;
    this.options.forEach((x) => {
      if(option.text === x.text) {
        button = x.text;
        x.active = true;
      } else {
        x.active = false;
      }
    });

    this.navigate(option);
    this.clickEvent.emit(option);
  }

  onClickMobile(option: FilterOption) {
    if(!this.clicked) {
      this.openEvent.emit([option,'change']);
    } else {
      // this.openEvent.emit([option,'change']);
    }

    switch(option.text) {
      case 'services':
        this.state = 'second';
        break;
      case 'tokens':
        this.state = 'first';
        break;
      case 'get-smarter':
        this.state = 'third';
    }
  }

  onIconClick(option: FilterOption) {
    // if(!option.active) {
    //   this.openEvent.emit([option,'change']);
    //   this.clicked = true;
    // } else {
    //   this.clicked = !this.clicked;
    // }
    // this.options.forEach((x) => {
    //   if(option.text === x.text) {
    //     x.active = !x.active;
    //   } else {
    //     x.active = false;
    //   }
    // });
  }

  navigate(option: FilterOption) {
    let lastVisited;
    switch(option.text) {
      case 'tokens':
        this.state = 'first';
        if(window.innerWidth > 480) {
          this.router.navigate(['/dashboard']);
        }
        return 'first';

      case 'services':
        this.state = 'second';
        if(window.innerWidth > 480) {
          this.router.navigate(['/dashboard/services']);
        }
        return 'second';

      case 'get-smarter':
        this.state = 'third';
        return 'third';
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

}
