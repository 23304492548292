import { Injectable } from '@angular/core';
import { AppStoreService } from './app-store.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteCoinService {

  constructor(
    private appStore: AppStoreService
  ) { }

  addNewCoin(id: string) {
    let favorites: any = localStorage.getItem('favorites');

    if(favorites) {
      favorites = JSON.parse(favorites);
    } else {
      favorites = {};
    }
    favorites[id] = true;
    localStorage.setItem('favorites', JSON.stringify(favorites));
    this.appStore.favorites.next(JSON.parse(localStorage.getItem('favorites')));
  }

  deleteCoin(id: string) {
    let favorites = localStorage.getItem('favorites');
    if(favorites) {
      favorites = JSON.parse(favorites);
      delete favorites[id];
      localStorage.setItem('favorites', JSON.stringify(favorites));
      this.appStore.favorites.next(JSON.parse(localStorage.getItem('favorites')));
    };
  }
}
