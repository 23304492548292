import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-countdown-timer',
  template: `{{countDownText}}`
})
export class CountdownTimerComponent implements OnInit, OnDestroy {

  @Input() futureUnixTimeStamp: number;
  @Input() amountOfMilliseconds: number;

  countDownText: string;
  countdown: number;
  settimeout: any;

  constructor() { }

  ngOnInit(): void {

    if(this.amountOfMilliseconds) {
      this.countdown = Math.floor(Number(this.amountOfMilliseconds)/1000);
      this.countdownRecursive();
    } else if (!this.amountOfMilliseconds && this.futureUnixTimeStamp) {
      this.countdown = Math.floor((Number(this.futureUnixTimeStamp) - Math.floor(new Date().getTime()))/1000);
      this.countdownUnixRecursive();
    }
  }

  countdownRecursive() {
    if(this.countdown < 1) {
      clearTimeout(this.settimeout);
      this.countdown = 0;
      return;
    }

    this.settimeout = setTimeout(() => {
      this.countdown -= 1;
      this.countdownRecursive();
    }, 1000);
  }

  countdownUnixRecursive() {
    if(this.countdown < 1) {
      clearTimeout(this.settimeout);
      this.countdown = 0;
      this.countDownText = 'Matured';
    }

    this.settimeout = setTimeout(() => {
      this.countdown -= 1;
      let totalSeconds = this.countdown;
      const days = Math.floor(totalSeconds/ 86400);
      totalSeconds %= 86400;
      const hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      const minutes = Math.floor(totalSeconds / 60);
      this.countDownText = `${days}d : ${hours}h : ${minutes}m : ${totalSeconds % 60}s`;
      this.countdownUnixRecursive();
    }, 1000);
  }

  ngOnDestroy() {
    clearTimeout(this.settimeout);
  }

}
