import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastService } from '../../services/toast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-invite-history',
  templateUrl: './invite-history.component.html',
  styleUrls: ['./invite-history.component.scss']
})
export class InviteHistoryComponent implements OnInit, OnDestroy {

  mockData = [];
  // [
  //   {
  //     date: new Date(),
  //     user: 'John Doe',
  //     image: 'https://i.pravatar.cc/50'
  //   },
  //   {
  //     date: new Date(),
  //     user: 'John Wang',
  //     image: 'https://i.pravatar.cc/50'
  //   },
  //   {
  //     date: new Date(),
  //     user: 'John John',
  //     image: 'https://i.pravatar.cc/50'
  //   },
  // ];
  inviteURL = '';
  copied = false;

  // subscription: Subscription;

  constructor(
    public authService: AuthService,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    if (this.authService.authObj.sessIDstr) {
      // this.getInviteHistory();
    }
  }

  copy() {
    this.clipboard.copy(this.authService.inviteURL);
    this.copied = true;
  }

  getInviteHistory() {
    // this.subscription = this.authService.getInviteHistory().subscribe((res: any)=>{
    //   this.authService.invitationHistory = res.result;
    // });
  }

  ngOnDestroy() {
    // this.subscription?.unsubscribe();
  }
}
