import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MinUsdtService } from 'src/app/services/min-usdt.service';

@Component({
  selector: 'app-assets-drop-down',
  templateUrl: './assets-drop-down.component.html',
  styleUrls: ['./assets-drop-down.component.scss']
})
export class AssetsDropDownComponent implements OnInit, OnDestroy {
  @Input() list: any;
  @Input() selected: any;
  @Input() disabled = false;
  @Input() outputAmount: number;
  @Input() inputAmount: number;
  @Input() inbound: any;
  @Output() emitSelected = new EventEmitter<any>();
  @Output() emitAmount = new EventEmitter<number>();
  @Output() emitSelectedOutbound = new EventEmitter<any>();

  show = false;
  swapSubscription: Subscription;

  constructor(public minUsdtService: MinUsdtService) { }

  ngOnInit(): void {
    this.swapSubscription = this.minUsdtService.cast.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    ).subscribe(res => {
      this.minUsdtService.usdtAmt = Number(res);
    });
  }

  onSelect(item: any) {
    if (this.inbound) {
      this.selected = item;
      this.show = false;
      this.emitSelected.emit(item);
    } else {
      this.emitSelectedOutbound.emit(item);
      this.show = false;
    }
  }
  onInputChange(amount: number) {
    this.emitAmount.emit(amount);
  }

  ngOnDestroy(): void {
    this.swapSubscription?.unsubscribe();
  }
}
