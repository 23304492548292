import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ServiceItem } from '../../models/common/service-item';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppStoreService } from '../../services/app-store.service';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss']
})
export class ServiceCardComponent implements OnInit, OnDestroy {

  @Input() service: ServiceItem;

  params = 'order-bots';
  subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appStore: AppStoreService
  ) { }

  ngOnInit(): void {
    this.subscription = this.route.queryParams.subscribe((res: any) => {
      this.params = res.type;
    });
  }

  onClick() {
    this.router.navigate(['/dashboard/services'], {queryParams: {type: this.service.params}});
    localStorage.setItem('dashboardService', this.service.params);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
