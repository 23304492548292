export class ServiceItem {
  constructor(
    public image: string,
    public name: string,
    public shortDescription: string,
    public description: string,
    public params: string,
    private favoriteStatus: boolean,
    public live: boolean
  ) {
    this.image = this.image;
    this.name = this.name;
    this.description = this.description;
    this.favoriteStatus = this.favoriteStatus;
    this.live = this.live;
    this.shortDescription = this.shortDescription;
  }

  getFavoriteStatus() {
    return this.favoriteStatus;
  }
  toggleFavoriteStatus() {
    this.favoriteStatus = !this.favoriteStatus;
  }
}
