import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'coded'
})
export class CodedPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if(value) {
      return value[0] + '********' + value[value.length-1];
    }
    return null;
  }

}
