import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { WalletService } from '../../services/wallet.service';
import { AppStoreService } from '../../services/app-store.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bonds-interface',
  templateUrl: './bonds-interface.component.html',
  styleUrls: ['./bonds-interface.component.scss']
})
export class BondsInterfaceComponent implements OnInit, OnDestroy {

  coinAmount;
  selectedCoin;
  subscription: Subscription;

  constructor(
    public walletService: WalletService,
    public appStore: AppStoreService,
    private dialog: MatDialog,
    public authService: AuthService,
    public router: Router
  ) { }

  ngOnInit() {
    this.subscription = this.appStore.selectedAsset.subscribe((data)=>{
      this.selectedCoin = data;
    });
  }

  getCoinAmount() {
    return 99994.666;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

}
