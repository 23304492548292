import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-confirmation-modal',
  templateUrl: './generic-confirmation-modal.component.html',
  styleUrls: ['./generic-confirmation-modal.component.scss']
})
export class GenericConfirmationModalComponent implements OnInit {

  constructor(
    public matDialogRef: MatDialogRef<GenericConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
  }

}
