/* eslint-disable @typescript-eslint/naming-convention */
export class CirculationHistoryItem {
  constructor(
    public amt: number,
    public bonus_amt: number,
    public bonus_tx_id: string,
    public complete_dt: string,
    public currency: string,
    public status: string,
    public tran_type: string,
    public tx_dt: string,
    public tx_id: string,
    public issue: number,
    public type: 'PS' | 'GS',
    public price?: number
  ) { }
}
