import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { FavoriteCoinService } from '../../services/favorite-coin.service';
import { CoinAsset } from '../../models/common/coin-asset';
import { AppStoreService } from '../../services/app-store.service';
import { Subscription } from 'rxjs';
import { ServiceItem } from '../../models/common/service-item';

@Component({
  selector: 'app-favorite-star',
  templateUrl: './favorite-star.component.html',
  styleUrls: ['./favorite-star.component.scss']
})
export class FavoriteStarComponent implements OnInit, OnDestroy {

  @Input() coin: CoinAsset;
  @Input() service: ServiceItem;
  @Output() emitEvent = new EventEmitter<void>();

  subscription: Subscription;

  constructor(
    private favoriteCoins: FavoriteCoinService,
    private appStore: AppStoreService
  ) { }

  ngOnInit(): void {
    if(this.coin) {
      this.changeSubscription();
    }
  }

  toggleFavorites() {
    this.emitEvent.emit();
  }

  //favorite specific section
  changeSubscription() {
    this.subscription = this.appStore.favorites.subscribe((res: any) => {
      this.checkFavoriteStatus();
    });
  }

  checkFavoriteStatus(): boolean {
    if(this.appStore.favorites.value && (this.coin || this.service)) {
      return this.appStore.favorites.value[this.coin ? this.coin.id : this.service.params] ? true : false;
    }
  }

  userSelectFavorite() {
    if(this.checkFavoriteStatus()) {
      this.favoriteCoins.deleteCoin(this.coin ? this.coin.id.toLowerCase() : this.service.params.toLowerCase());
    } else {
      this.favoriteCoins.addNewCoin(this.coin ? this.coin.id.toLowerCase() : this.service.params.toLowerCase());
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

}
