import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { SubscriptionService } from './subscription.service';
import { WalletService } from './wallet.service';
import { Web3Service } from './web3.service';
import { CirculationService } from './circulation.service';
import { AssetDataService } from './asset-data.service';
import { CountdownTimerService } from './countdown-timer.service';
import { Subscription } from 'rxjs';
import { AppStoreService } from './app-store.service';
import { ForTranslateService } from './for-translate.service';

@Injectable({
  providedIn: 'root'
})
export class InitServiceService {

  timeout;
  subscription: Subscription;

  constructor(
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private walletService: WalletService,
    private web3Service: Web3Service,
    private circulationService: CirculationService,
    private assetDataService: AssetDataService,
    private countDownTimerService: CountdownTimerService,
    private appStore: AppStoreService,
    private translateService: ForTranslateService
  ) { }

  loginScript(res) {
    this.authService.setInviteUrl(this.appStore.queryParams.lang);
    if (this.appStore.queryParams.lang) {
      let lang;
      switch (this.appStore.queryParams.lang) {
        case 'zh-hans':
          lang = 'zh';
          break;
        case 'zh-hant':
          lang = 'zh-TW';
          break;
        case (this.appStore.queryParams.lang):
          lang = this.appStore.queryParams.lang;
          break;
        default:
          lang = 'en';
          break;
      }
      this.translateService.setCurrentLanguage = lang;
    }
    // this.subscriptionService.getSubscriptionInfo().subscribe(()=>{
    //   if (this.subscriptionService.subscriptionWallet.activeSubscriptionStatus !== 'notfound'
    //   && this.subscriptionService.subscriptionWallet.activeSubscriptionStatus !== 'active'
    //   && (this.subscriptionService.subscriptionWallet.activeSubscriptionAutorenew
    //   || this.subscriptionService.subscriptionWallet.activeSubscriptionNextPymtAuthorized)) {
    //     this.subscriptionService.checkSubscriptionStatus().subscribe(()=>{
    //       this.subscriptionService.getSubscriptionInfo().subscribe();
    //     });
    //   }
    // });
    // this.authService.getInviteHistory().subscribe((data: any) => {
    //   this.authService.invitationHistory = data.result;
    // },(err: any) => {
    //   console.log(err);
    //   if(err.error.includes('bad-sess') || err.error.includes('sess')) {
    //     this.authService.logout();
    //   }
    // });
    // this.web3Service.connectMetaMask();
    // this.walletService.getWalletInfo().subscribe((data: any) => {
    //   // If wallet is not connected they can't perform any empower or subscription
    //   for (const wallet of data.wallets) {
    //     if (wallet.blockchain === 'ETH') {
    //       this.walletService.wallet.ethAddress = wallet.wallet_addr;
    //       this.walletService.establishedAddress = wallet.wallet_addr;
    //       this.authService.authObj.walletConnected = true;
    //       this.web3Service.getUserBalance();
    //       this.getUserBalanceInterval();
    //     } else if (wallet.blockchain === 'AUTOPAY') {
    //       this.subscriptionService.subscriptionWallet.subscriptionAddress = wallet.wallet_addr;
    //       this.subscriptionService.getSubscriptionInfo().subscribe();
    //     }
    //   }
    // });
    this.subscriptionTimeout();
  }

  subscriptionTimeout() {
    if(this.timeout) {
      clearTimeout(this.timeout);
    }
    // this.timeout = setTimeout(() => {
    //   this.subscriptionService.getSubscriptionInfo().subscribe();
    //   this.subscriptionTimeout();
    // }, 60000);
  }

  getUserBalanceInterval() {
    if (this.authService.authObj.isLoggedIn && this.authService.authObj.walletConnected)
    {setInterval(() => {
      this.web3Service.getUserBalance();
    }, 15000);}
    if (this.authService.authObj.isLoggedIn && this.authService.authObj.walletConnected)
    {setInterval(() => {
      this.countDownTimerService.getTime(this.subscriptionService.subscriptionWallet.activeSubscriptionExpireDt);
    }, 1000);}
  }
}
