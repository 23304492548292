import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AppStoreService } from '../../services/app-store.service';
import { Subscription } from 'rxjs';
import { FakeLiveDataService } from '../../services/fake-live-data.service';
import { numberGoUp } from '../../animations/animations';

@Component({
  selector: 'app-circulation-order-bot-card',
  templateUrl: './circulation-order-bot-card.component.html',
  styleUrls: ['./circulation-order-bot-card.component.scss'],
  animations: [
    numberGoUp
  ]
})
export class CirculationOrderBotCardComponent implements OnInit {

  @Input() bot: any;

  constructor(
    public appStore: AppStoreService,
    private fakeData: FakeLiveDataService,
  ) { }

  ngOnInit(): void {

  }

  onClick() {
    this.appStore.selectedBot = this.bot;
    this.appStore.selectedSymbol = this.bot.symbol;
  }
}
