import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'coinInfo'
})
export class CoinInfoPipe implements PipeTransform {

  transform(value: any): any {
    value = Number(value);
    if (value > 10**11) {
      return '$' + (value / 10**12).toFixed(2) + 'T';
    } else if(value > 10**8) {
      return '$' + (value / 10**9).toFixed(2) + 'B';
    } else if (value> 10**5) {
      return '$' + (value / 10**8 ).toFixed(2) + 'M';
    } else {
      return '$' + (value/ 10**3).toFixed(2) + 'K';
    }
  }

}
