import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CoinAsset } from '../models/common/coin-asset';
import { ServiceItem } from '../models/common/service-item';


@Injectable({
  providedIn: 'root'
})
export class AppStoreService {

  //Dashboard
  selectedAsset = new Subject<string>();
  selectedAssetInfo = new Subject<CoinAsset>();
  selectedAssetValue: CoinAsset;
  selectedSymbol = 'ETH';

  notificationDropdown = false;
  settingsDropdown = false;
  minimizedMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);

  //Account Specific
  favorites: BehaviorSubject<any> = new BehaviorSubject({});
  queryParams: any = {};

  //Services
  services: ServiceItem[] = [
    new ServiceItem(
      'assets/images/Side Logo - Robots-min.png',
      'OrderBots',
      'automatic-circulation-order-bot',
      // 'empower-bots-allow-your-funds-to-be-working-all-the-time',
      'Exchange bots allow your funds to be working all the time',
      'order-bots',
      false,
      false
    ),
    new ServiceItem(
      'assets/images/Side Logo - Bonds-min.png',
      'Bonds',
      '',
      'longer-term-rewards-options',
      'bonds',
      false,
      false
    ),
    new ServiceItem(
      'assets/images/Side Logo - Bonds-min.png',
      'Bonds',
      '',
      'longer-term-rewards-options',
      'bonds',
      false,
      false
    ),
    new ServiceItem(
      'assets/images/Side Logo - Bonds-min.png',
      'Bonds',
      '',
      'longer-term-rewards-options',
      'bonds',
      false,
      false
    ),
    new ServiceItem(
      'assets/images/Side Logo - Bonds-min.png',
      'Bonds',
      '',
      'longer-term-rewards-options',
      'bonds',
      false,
      false
    ),
  ];
  //---order-bot
  bots = [
    {
      name: 'USDT',
      staked: 500,
      unclaimed: 27.23,
      status: true
    },
  ];
  selectedBot;
  //---bonds
  selectedBond;

  constructor() {
  }

  //Setter
  setSelectedAsset(id: string) {
    this.selectedAsset.next(id);
  }
  setSelectedAssetInfo(coin: CoinAsset) {
    this.selectedAssetInfo.next(coin);
    this.selectedAssetValue = coin;
    this.selectedSymbol = coin.id;
  }
}
