import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { SubscriptionService } from '../../services/subscription.service';
import { WalletService } from '../../services/wallet.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-transfer-credit-modal',
  templateUrl: './transfer-credit-modal.component.html',
  styleUrls: ['./transfer-credit-modal.component.scss'],
})
export class TransferCreditModalComponent implements OnInit, OnDestroy {
  step = 0;
  availableAmount = this.subscriptionService.subscriptionWallet.autopayBalance;
  totalSum = 0;

  accounts = [
    {
      address: null,
      amount: null,
      validUser: false,
      isValidatingUser: false,
      invalidUser: false,
      isSending: false,
      error: null,
      success: false
    },
  ];

  confimedAccounts = [];
  disable: boolean;
  success = false;
  subscription: Subscription;
  subscription1: Subscription;
  subscription2: Subscription;

  constructor(
    private matDialog: MatDialogRef<TransferCreditModalComponent>,
    private authService: AuthService,
    public subscriptionService: SubscriptionService,
    private walletService: WalletService
  ) { }

  ngOnInit() {
  }

  getTotal() {
    let amount = 0;
    this.accounts.forEach((account) => {
      if (account.amount) {
        amount += account.amount;
      }
    });
    return amount;
  }

  getConfirmedTotal() {
    let amount = 0;
    this.confimedAccounts.forEach((account) => {
      if (account.amount) {
        amount += account.amount;
      }
    });
    return amount;
  }

  deleteRow(index) {
    this.accounts.splice(index, 1);
  }

  send() {
    this.confimedAccounts = [];
    for (const value of this.accounts) {
      if (value.amount !== null && value.address !== null && value.validUser) {
        this.confimedAccounts.push(value);
        this.step = 1;
      }
    }
  }

  addRow() {
    this.accounts.push({
      address: null,
      amount: null,
      validUser: false,
      invalidUser: false,
      isValidatingUser: false,
      isSending: false,
      error: null,
      success: false
    });
  }

  close() {
    this.matDialog.close();
  }

  check() {
  }

  checkUser(user, index) {
    this.accounts[index].error = null;
    this.accounts[index].invalidUser = false;
    this.accounts[index].validUser = false;

    if (this.authService.authObj.login_name === user || this.authService.authObj.username === user) {
      this.accounts[index].invalidUser = true;
      return;
    }

    this.accounts[index].isValidatingUser = true;
    this.subscription = this.authService.checkUserExists(user).subscribe((data: any) => {
      this.accounts[index].isValidatingUser = false;
      this.accounts[index].validUser = data.userExists;
      this.accounts[index].invalidUser = !this.accounts[index].validUser;
    }, () => {
      this.accounts[index].isValidatingUser = false;
      this.accounts[index].validUser = false;
      this.accounts[index].invalidUser = !this.accounts[index].validUser;
    });
  }

  isSendingCredits() {
    return this.confimedAccounts.find(account => account.isSending);
  }

  confirmSend() {
    // what do you do to tell them its not on the same network tree or that some are succesful?

    this.confimedAccounts.forEach((account) => {
      account.error = '';
      account.isSending = true;
      this.subscription1 = this.walletService.sendSubscriptionCredits(account.address, account.amount)
        .subscribe(data => {
          this.subscription2 = this.subscriptionService.getSubscriptionInfo().subscribe();
          this.availableAmount -= account.amount;
          account.isSending = false;
          account.success = true;
          if (!this.confimedAccounts.find(account => !account.success)) {
            this.success = true;
          }
        }, (err) => {
          account.isSending = false;
          if (err.error) {
            switch (err.error) {
              case 'grc-different-networks: Users are not in the same network':
                account.error = 'send-credits-error-users-must-be-in-same-network';
                break;
              default:
                account.error = 'send-credits-error-could-not-send-credits-at-this-time';
                break;
            }
          }
        });
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }
}
