import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-maintenance-announcement',
  templateUrl: './maintenance-announcement.component.html',
  styleUrls: ['./maintenance-announcement.component.scss']
})
export class MaintenanceAnnouncementComponent implements OnInit {

  show = true;

  constructor(
    // private matDialogRef: MatDialogRef<MaintenanceAnnouncementComponent>,
    // @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
  }

  closeDialog() {
    // this.matDialogRef.close();
    this.show = false;
  }

}
