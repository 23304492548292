import { Component, Input, OnInit, Output, EventEmitter, SimpleChange, SimpleChanges } from '@angular/core';
import { WalletService } from '../../services/wallet.service';
import { TokenSwapService } from 'src/app/services/token-swap.service';

@Component({
  selector: 'app-token-swap-input',
  templateUrl: './token-swap-input.component.html',
  styleUrls: ['./token-swap-input.component.scss'],
})
export class TokenSwapInputComponent implements OnInit {
  @Input() list: any;
  @Input() text: string;
  @Input() selectedAsset: any;
  //next 2 Inputs are for outputAsset
  @Input() disabled = false;
  @Input() outputAmount: number;
  @Input() inputAmount: number;
  @Input() inbound: any;
  @Output() amountEmit = new EventEmitter<number>();
  @Output() selectedCoin = new EventEmitter();
  @Output() emitSelectedOutbound = new EventEmitter();
  @Output() availableBalance = new EventEmitter();

  amount: number;
  selectedItem;


  constructor(
    public walletService: WalletService,
    public tokenSwapService: TokenSwapService
  ) {}

  ngOnInit(): void {}

  onSelect(item: any) {
    this.selectedCoin.emit(item);
    this.selectedItem = item.symbol;
    // this.balance = item.balance;
  }
  onSelectedOutbound(item: any) {
    this.emitSelectedOutbound.emit(item);
  }

  getAvailableBalance() {
    const availableBalance = this.walletService.wallet[this.selectedAsset.symbol.toLowerCase()];
    this.availableBalance.emit(availableBalance);
    return availableBalance;


    // if (this.selectedAsset.symbol !== 'USDx') {
    //   const availableBalance = this.walletService.wallet[this.selectedAsset.symbol.toLowerCase()];
    //   this.availableBalance.emit(availableBalance);
    //   return availableBalance;
    // } else {
    //   const availableBalance = this.walletService.bep20Wallet[this.selectedAsset.symbol.toLowerCase()];
    //   this.availableBalance.emit(availableBalance);
    //   return this.walletService.bep20Wallet[this.selectedAsset.symbol.toLowerCase()];
    // }
  }

  onInputChange(amount: number) {
    this.amountEmit.emit(amount);
  }
}
