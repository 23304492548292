import { Component, OnInit, OnDestroy } from '@angular/core';
import { CirculationService } from '../../services/circulation.service';
import { Subscription, Observable } from 'rxjs';
import { CoinAsset } from 'src/app/models/common/coin-asset';
import { AppStoreService } from 'src/app/services/app-store.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-circulation',
  templateUrl: './my-circulation.component.html',
  styleUrls: ['./my-circulation.component.scss'],
})
export class MyCirculationComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  subscription1: Subscription;
  subscription2: Subscription;

  $selectedAsset: Observable<CoinAsset>;

  constructor(
    public appStore: AppStoreService,
    public authService: AuthService,
    public circulationService: CirculationService,
  ) { }

  ngOnInit() {
    this.$selectedAsset = this.appStore.selectedAssetInfo;

    this.subscription1 = this.appStore.selectedAsset.subscribe(res => {
      if(this.authService.authObj.isLoggedIn) {
        this.getMyCirculation();
      }
    });

    this.subscription2 = this.authService.loginObservable.subscribe((res: any) => {
      this.getMyCirculation();
    });
  }

  getMyCirculation() {
    if (this.authService.authObj.isLoggedIn) {
      this.circulationService
        .getUserCirculation();
      // .subscribe((res: any) => {
      //   this.myUserList = res;
      //   // .userList.map((item) => {
      //   //   return new CirculationHistoryItem(
      //   //     item.amount,
      //   //     null,
      //   //     null,
      //   //     item.date,
      //   //     'USDx',
      //   //     item.rewardStatus,
      //   //     null,
      //   //     null,
      //   //     item.id,
      //   //     item.type
      //   //   )
      //   // });
      //   console.log(111, this.myUserList);
      // });
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }
}
