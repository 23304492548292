import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountdownTimerService {
  hour = 0;
  min = 0;
  sec = 0;
  gap: number;
  futureDate;
  countDown;

  constructor() { }

  getTime(date): Observable<any> {
    this.futureDate = new Date(date).getTime();
    const now = new Date().getTime();
    this.gap = this.futureDate - now;

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;

    this.hour = Math.floor((this.gap) / hour);
    this.min = Math.floor((this.gap % hour) / minute);
    this.sec = Math.floor((this.gap % minute) / second);
    this.countDown = `${this.hour}${this.min}${this.sec}`;
    return this.gap < 1000 ? of({
      hour: 0,
      min: 0,
      sec: 0,
      gap: 0
    }) : of({
      hour: this.hour,
      min: this.min,
      sec: this.sec,
      gap: this.gap
    });
  }
}
