export const ModalProperties: any = {
    properties: {
      width: '',
      height: '',
      maxWidth: '100vw',
      maxHeight: '100vh',
      screenWidth: 0,
      data: {}
    },
    setBaseParams() {
      this.properties.screenWidth = window.innerWidth;
      if (this.properties.screenWidth > 900) {
        this.properties.width = '675px';
        this.properties.maxWidth = '90vw';
      } else {
        this.properties.width = '100%';
        this.properties.height = '100%';
      }
    },
    resetProperties() {
      this.properties = {
        width: '',
        height: '',
        maxWidth: '100vw',
        maxHeight: '100vh',
        screenWidth: 0,
        data: {}
      };
      this.setBaseParams();
    }
};
