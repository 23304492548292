/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import Web3 from 'web3';
import {abi, minABI} from '../constants/abi';
import { BehaviorSubject } from 'rxjs';
import { WalletService } from './wallet.service';
import usdxAbi from '../constants/usdx-abi';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { AuthService } from './auth.service';
import { TokenSwapService } from './token-swap.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { WrongAddressModalComponent } from '../common/wrong-address-modal/wrong-address-modal.component';
import { ModalProperties } from '../constants/set-modal-properties';
import { ToastrService } from 'ngx-toastr';
import { circulationContract, usdtAddress } from '../constants/addresses';

let web3;
const web4 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed1.defibit.io'));; //This is for BSC
const web5 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161')); //This is for ETH
declare let window;

// if (window.ethereum) {
//   web3 = new Web3(window.ethereum);
// }

// window.addEventListener('load', async () => {
//     // Modern dapp browsers...
//     if (window.ethereum) {
//         const ethereum = window.ethereum;
//         window.web3 = new Web3(ethereum);
//         try {
//             // Request account access if needed
//             await ethereum.enable();
//             // Acccounts now exposed

//         } catch (error) {
//             // User denied account access...
//         }
//     }
//     // Legacy dapp browsers...
//     else if (window.web3) {
//         window.web3 = new Web3(web3.currentProvider);
//         // Acccounts always exposed

//     }
//     // Non-dapp browsers...
//     else {
//     }
// });


@Injectable({
  providedIn: 'root',
})
export class Web3Service {
  activeAmount = null;
  // txid: string;
  hasMetaMask;

  usdxToAddress = circulationContract;
  usdxContractAddress = usdtAddress;
  usdtContractAddress = '0xdac17f958d2ee523a2206206994597c13d831ec7';

  confirmedToast: any;
  $chainChanged: BehaviorSubject<string> = new BehaviorSubject<string>('');
  $txConfirmed = new BehaviorSubject<any>(null);

  constructor(
    private walletService: WalletService,
    private http: HttpClient,
    private authService: AuthService,
    public tokenSwapService: TokenSwapService,
    private toastr: ToastrService,
    public dialog: MatDialog,
  ) {
    // if (window.ethereum) {
    //   this.hasMetaMask = true;
    //   window.ethereum.on('accountsChanged', (accounts) => {
    //     window.location.reload();
    //   });
    //   window.ethereum.on('chainChanged', (chainId) => {
    //     this.$chainChanged.next(chainId);
    //   });
    // } else {
    //   this.hasMetaMask = false;
    // }
  }

  getUserBalance() {
    this.getBnbBalance();
    this.getUsdxBalance();
    this.getEthBalance();
    this.getUsdtBalance();
  }

  async getEthBalance() {
    const address = this.walletService.wallet.ethAddress;
    let balance;
    try {
      balance = web5.utils.fromWei(await web5.eth.getBalance(address), 'ether');
      this.walletService.wallet.eth = balance;
    } catch (error) {}
  }

  async getBnbBalance() {
      const address = this.walletService.wallet.ethAddress;

    try {
      const balance: any = web4.utils.fromWei(await web4.eth.getBalance(address), 'ether');
      this.walletService.wallet.bnb = balance;
    } catch (error) {}
  }

  async getUsdxBalance() {
    const address = this.walletService.wallet.ethAddress;
    const contractAddress = this.usdxContractAddress;
    const contractABI: any = usdxAbi;;
    const tokenContract = new web4.eth.Contract(contractABI, contractAddress);
    const decimals = await tokenContract.methods.decimals().call();
    const balance = (await tokenContract.methods.balanceOf(address).call()) / Math.pow(10, decimals);
    this.walletService.bep20Wallet.usdx = balance;
  }

  async getUsdtBalance() {
    const address = this.walletService.wallet.ethAddress;
    const contractAddress = this.usdtContractAddress;
    const contractABI: any = usdxAbi;
    const tokenContract = new web5.eth.Contract(contractABI, contractAddress);
    const decimals = await tokenContract.methods.decimals().call();
    const balance = (await tokenContract.methods.balanceOf(address).call()) / Math.pow(10, decimals);
    this.walletService.wallet.usdt = balance;
  }

  checkMetaMask() {
    if (window.ethereum) {
      return true;
    } else {
      return false;
    }
  }

  async connectMetaMask() {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      return accounts[0];
    } else {
      // alert('Please install metamask at https://metamask.io/');
    }
  }

  sendEth = async (contractAddress, amount) => {
    this.requestAccount();
    let firstToast;
    // if you use to the 18th power in other Token it will throw an error
    web3.eth
      .sendTransaction({
        from: this.walletService.wallet.ethAddress,
        to: contractAddress,
        value: amount * 1000000000000000000,
      })
      .on('transactionHash', (hash) => {
        this.transactionLoadingBreakPeriod();
      }).on('transactionHash', async (hash) => {
        firstToast = this.toastr.warning(
          '<span class="text-bold">Your TX has been broadcasted to the network</span></br></br> <i class="icon icon-watch10 warning-yellow"></i> <span>Waiting for confirmation...</span>',
          '', {
          timeOut: 60000,
          closeButton: true,
          easeTime: 150,
          progressBar: true,
        });
        this.$txConfirmed.next(true);
      }).on('receipt', async (receipt) => {
        this.confirmedToast = this.toastr.success(
          `<span class="text-bold">Your TX has been confirmed on the blockchain!</span></br></br> <i class="icon icon-watch10 success"></i> <span>Swaping assets...</span>`,
          '', {
          timeOut: 150000,
          closeButton: true,
          easeTime: 150,
          progressBar: true,
        });
        this.toastr.clear(firstToast.toastId);
        this.getUsdxBalance();
      }).on('error', (error, receipt) =>{
        this.toastr.error(
          '<span class="text-bold">Your TX has failed or is not confirmed on the blockchain</span></br></br> <span class="font-size-m warning">x</span> <span>Please try again</span>',
          '',
          {
            timeOut: 10000,
            closeButton: true,
            easeTime: 150,
            progressBar: true
          });
        this.$txConfirmed.next(false);
      });
  };

  async checkBep20Balance(ethAddress, network?) {
    const address = ethAddress;
    const contractAddress = usdtAddress;
    const tokenContract = await new web3.eth.Contract(abi, contractAddress);
    let decimals;
    let balance;
    let adjustedBalance;
    let error = false;

    await tokenContract.methods
      .balanceOf(address)
      .call()
      .then((result) => {
        balance = result;
      })
      .catch(() => {
        error = true;
      });
    if (error) {
      return null;
    }

    await tokenContract.methods
      .decimals()
      .call()
      .then((result) => {
        decimals = result;
      })

      .catch(() => {
        error = true;
      });
    if (error) {
      return null;
    }

    try {
      adjustedBalance = balance / Math.pow(10, decimals);
      return adjustedBalance;
    } catch (err) {
    }
  }

  async getChainId() {
    const chainId = await web3.eth.getChainId();
    return chainId;
  }

  async networkRequest(network) {
    // this.requestAccount();
    if (network === 'ethNetwork') {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }], // chainId must be in hexadecimal numbers
      });
      this.$chainChanged.next('0x1');
    } else {
      // this is grabbing the chain ID
      web3.eth.getChainId().then(async (data) => {});
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainName: 'Smart Chain',
            chainId: '0x38',
            rpcUrls: ['https://bsc-dataseed.binance.org/'],
            nativeCurrency: {
              symbol: 'BNB', // 2-6 characters long
              decimals: 18,
            },
            blockExplorerUrls: ['https://bscscan.com'],
          },
        ],
      });
      this.$chainChanged.next('0x38');
    }
  }

  customToken() {
    const params = {
      type: 'ERC20',
      options: {
        address: this.usdxContractAddress,
        symbol: 'USDX',
        decimals: 8,
        image: '',
      },
    };

    web3.eth.getChainId().then((networkId) => {
      if (networkId === 56) {
        window.ethereum
          .request({ method: 'wallet_watchAsset', params })
          .then((data) => console.log('successfully added token', data))
          .catch((error: Error) => console.log('did not add token'));
      } else {
      }
    });
  }

  async getEthAmount(ethAddress) {
    const ethAmount = await new web3.eth.getBalance(ethAddress);
    return ethAmount / 10 ** 18;
  }

  async getCurrentAccount() {
    return await web3.eth.getAccounts();
  }

  sendErc20(contractAddress, coinAmount) {
    this.requestAccount();
    let firstToast;
    const tokenAddress = this.usdtContractAddress;
    const toAddress = contractAddress;
    const fromAddress = this.walletService.wallet.ethAddress;
    // Use BigNumber
    const decimals = web3.utils.toBN(6);
    const amount = web3.utils.toBN(coinAmount);
    // Get ERC20 Token contract instance
    const contract = new web3.eth.Contract(minABI, tokenAddress);
    // calculate ERC20 token amount
    const value = amount.mul(web3.utils.toBN(10).pow(decimals));
    // call transfer function
    contract.methods
      .transfer(toAddress, value)
      .send({ from: fromAddress })
      .on('transactionHash', (hash) => {
        this.transactionLoadingBreakPeriod();
      }).on('transactionHash', async (hash) => {
        firstToast = this.toastr.warning(
          '<span class="text-bold">Your TX has been broadcasted to the network</span></br></br> <i class="icon icon-watch10 warning-yellow"></i> <span>Waiting for confirmation...</span>',
          '', {
          timeOut: 60000,
          closeButton: true,
          easeTime: 150,
          progressBar: true,
        });
        this.$txConfirmed.next(true);
      }).on('receipt', async (receipt) => {
        this.confirmedToast = this.toastr.success(
          `<span class="text-bold">Your TX has been confirmed on the blockchain!</span></br></br> <i class="icon icon-watch10 success"></i> <span>Swaping assets...</span>`,
          '', {
          timeOut: 150000,
          closeButton: true,
          easeTime: 150,
          progressBar: true,
        });
        this.toastr.clear(firstToast.toastId);
        this.getUsdxBalance();
      }).on('error', (error, receipt) =>{
        this.toastr.error(
          '<span class="text-bold">Your TX has failed or is not confirmed on the blockchain</span></br></br> <span class="font-size-m warning">x</span> <span>Please try again</span>',
          '',
          {
            timeOut: 10000,
            closeButton: true,
            easeTime: 150,
            progressBar: true
          });
        this.$txConfirmed.next(false);
      });
  }

  sendBep20(contractAddress, coinAmount) {
    this.requestAccount();
    //TODO: Will have to change this for future coins
    const tokenAddress = this.usdxContractAddress;
    const toAddress = contractAddress;
    const fromAddress = this.walletService.wallet.ethAddress;
    let firstToast;
    // Get ERC20 Token contract instance
    const contract = new web3.eth.Contract(minABI, tokenAddress);
    // calculate ERC20 token amount
    const val = coinAmount*(10**8);
    console.log(val.toFixed(0));
    // call transfer function
    contract.methods
      .transfer(toAddress, val.toFixed(0))
      .send({ from: fromAddress })
      .on('transactionHash', (hash) => {
        this.transactionLoadingBreakPeriod();
      }).on('transactionHash', async (hash) => {
        firstToast = this.toastr.warning(
          '<span class="text-bold">Your TX has been broadcasted to the network</span></br></br> <i class="icon icon-watch10 warning-yellow"></i> <span>Waiting for confirmation...</span>',
          '', {
          timeOut: 30000,
          closeButton: true,
          easeTime: 150,
          progressBar: true,
        });
        this.$txConfirmed.next(true);
      }).on('receipt', async (receipt) => {
        this.confirmedToast = this.toastr.success(
          `<span class="text-bold">Your TX has been confirmed on the blockchain!</span></br></br> <i class="icon icon-watch10 success"></i> <span>Processing Exchange...</span>`,
          '', {
          timeOut: 120000,
          closeButton: true,
          easeTime: 150,
          progressBar: true,
        });
        this.toastr.clear(firstToast.toastId);
        this.getUsdxBalance();
      }).on('error', (error, receipt) =>{
        this.toastr.error(
          '<span class="text-bold">Your TX has failed or is not confirmed on the blockchain</span></br></br> <span class="font-size-m warning">x</span> <span>Please try again</span>',
          '',
          {
            timeOut: 10000,
            closeButton: true,
            easeTime: 150,
            progressBar: true
          });
        this.$txConfirmed.next(false);
      });
  }

  sendBep20Subscription(
    coinAmount,
    address,
    toastMessage,
    autoRenewOption?,
    nowOption?,
    subscriptionAmount?,
  ) {
    this.requestAccount();
    let firstToast;
    const tokenAddress = this.usdxContractAddress;
    const toAddress = address;
    const fromAddress = this.walletService.wallet.ethAddress;
    // Use BigNumber
    const decimals = web3.utils.toBN(8);
    const amount = web3.utils.toBN(coinAmount);
      // Get ERC20 Token contract instance
    const contract = new web3.eth.Contract(minABI, tokenAddress);
    // calculate ERC20 token amount
    const value = amount.mul(web3.utils.toBN(10).pow(decimals));
    // call transfer function
    contract.methods
      .transfer(toAddress, value)
      .send({ from: fromAddress })
      .on('transactionHash', async (hash) => {
        firstToast = this.toastr.warning(
          '<span class="text-bold">Your TX has been broadcasted to the network</span></br></br> <i class="icon icon-watch10 warning-yellow"></i> <span>Waiting for confirmation...</span>',
          '',
          {
          timeOut: 30000,
          closeButton: true,
          easeTime: 150,
          progressBar: true,
        });
        this.$txConfirmed.next(true);
      }).on('receipt', async (receipt) => {
        this.confirmedToast = this.toastr.success(
          '<span class="text-bold">Your TX has been confirmed on the blockchain!</span></br></br> <i class="icon icon-watch10 success"></i> <span>Updating Status...</span>',
          '', {
          timeOut: 120000,
          closeButton: true,
          easeTime: 150,
          progressBar: true,
        });
        this.toastr.clear(firstToast.toastId);
        // const status = this.subscriptionService.subscriptionWallet.activeSubscriptionStatus;
        // const paymentAuthorized = this.subscriptionService.subscriptionWallet.activeSubscriptionNextPymtAuthorized;
        // if (
        //   (hash && status === 'expired') ||
        //   (status === 'exhausted' && paymentAuthorized) ||
        //   (status === 'active' && nowOption === 1)
        // ) {
        //   this.subscriptionService
        //     .addSubscription(subscriptionAmount, autoRenewOption, nowOption)
        //     .subscribe((data) => {
        //       console.log('addsubscription return', data);
        //     });
        // }
      }).on('error', (error, receipt) =>{
        console.log(error);
        this.toastr.error(
          '<span class="text-bold">Your TX has failed or is not confirmed on the blockchain</span></br></br> <span class="font-size-m warning">x</span> <span>Please try again</span>',
          '',
          {
          timeOut: 10000,
          closeButton: true,
          easeTime: 150,
          progressBar: true
        });
        this.$txConfirmed.next(false);
      });
  }

  checkValidAddress(address) {
    return web4.utils.isAddress(address);
  }

  transactionLoadingBreakPeriod() {
    this.tokenSwapService.loadingTransaction = true;
    setTimeout(() => {
      this.tokenSwapService.loadingTransaction = false;
    }, 6000);
  }

  addSubscription(coinAmount, autopayOption, nowOption?) {
    const body: any = {
      sessIDstr: this.authService.authObj.sessIDstr,
      dfltSubscriptionAmt: coinAmount,
      autopay: autopayOption,
    };
    if (nowOption) {
      body.now = nowOption;
    }
    const config = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.post(
      environment.userSubscriptionEndpoint,
      JSON.stringify(body),
      config
    );
  }

  getBep20AmountBSC(contractAddress?, bscAddress?) {
    this.requestAccount();
    const module = 'module=account';
    const action = 'action=tokenbalance';
    const tag = 'tag=latest';
    const apiKey = 'apikey=EH4TXM8KHN4EEKG6JM18GMMUVJDT3E2XG8';
    const url = `https://api.bscscan.com/api?${module}&${action}&contractaddress=${contractAddress}&address=${bscAddress}&${tag}&${apiKey}`;
    return this.http.get(url, {});
  }

  getLatestId(appKey, nonce, timestamp, signature) {
    const url = 'http://api.wynma.com/get_latest_ps_id';
    const headers = new HttpHeaders()
      .set('content-type', 'application/x-www-form-urlencoded')
      .set('appkey', appKey)
      .set('nonce', nonce)
      .set('signature', signature)
      .set('timestamp', timestamp);
    return this.http.post(url, {}, { headers });
  }

  getLatestPsList(appKey, nonce, timestamp, signature) {
    const url = 'https://api.wynma.com/get_latest_ps_list';
    const headers = new HttpHeaders()
      .set('content-type', 'application/x-www-form-urlencoded')
      .set('appkey', appKey)
      .set('nonce', nonce)
      .set('signature', signature)
      .set('timestamp', timestamp);
    const body = new HttpParams().set('offset', '3').set('start', '0');
    return this.http.post(url, body, { headers });
  }

  createPayout(appKey, nonce, timestamp, signature, address, amount, id, time) {
    const url = 'http://161.117.36.132/docs/createpayout.html';
    const headers = new HttpHeaders()
      .set('content-type', 'application/x-www-form-urlencoded')
      .set('appkey', appKey)
      .set('nonce', nonce)
      .set('signature', signature)
      .set('timestamp', timestamp);
    const body = new HttpParams()
      .set('address', address)
      .set('amount', amount)
      .set('psid', id)
      .set('time', time);
    return this.http.post(url, body, { headers });
  }

  requestAccount() {
    web3.eth.requestAccounts().then((data) => {
      const currentAddress = data[0];
      if (currentAddress.toUpperCase() !== this.walletService.wallet.ethAddress.toUpperCase()) {
      ModalProperties.setBaseParams();
      if (ModalProperties.properties.screenWidth > 750) {
        ModalProperties.properties.width = '700px';
        ModalProperties.properties.maxWidth = '90vw';
      } else {
        ModalProperties.properties.width = '100%';
        ModalProperties.properties.maxWidth = '100vw';
      }
      ModalProperties.properties.data = {
        address: data
      };
      ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
      this.dialog.open(WrongAddressModalComponent, ModalProperties.properties);
      ModalProperties.resetProperties();
        console.log('maybe modal');
      }
    });
  }
}
