/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VerifyEmailComponent } from '../verify-email/verify-email.component';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CaptchaPopupComponent } from '../captcha-popup/captcha-popup.component';
import { WalletService } from '../../services/wallet.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { ActivatedRoute, Router } from '@angular/router';
import { pipe, Subject, Subscription } from 'rxjs';
import { Web3Service } from '../../services/web3.service';
import { SubscriptionService } from '../../services/subscription.service';
import { ForTranslateService } from '../../services/for-translate.service';
import { debounceTime } from 'rxjs/operators';
import { AppStoreService } from '../../services/app-store.service';
import { ModalProperties } from 'src/app/constants/set-modal-properties';
import { InitServiceService } from '../../services/init-service.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  signupForm: FormGroup;
  captcha = false;
  authType: 'login' | 'signup' = 'login';
  sending = false;
  error;
  showPassword = false;
  subscription: Subscription;
  subscription1: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;

  checkingEmail = false;
  checkingUserName = false;
  checkingReferrer = false;

  emailExists: boolean;
  usernameExists: boolean;
  referrerId: boolean | null;
  referrerSub: Subscription;
  referrerSub1: Subscription;
  // password needs to have at least one of the following: lowercase and uppercase letter, numeric digit, special character; no spaces allowed
  // pwRegex = /^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!?@#$%^&*()+=`~/\\|\-_<>.,'";:\[\]\{\}])[a-zA-Z0-9!?@#$%^&*()+=`~/\\|\-_<>.,'";:\[\]\{\}]*$/;
  pwRegex = /^[a-zA-Z0-9!?@#$%^&*()+=`~/\\|\-_<>.,'";:\[\]\{\}]*$/;

  emailRegex = /[A-Za-z0-9\._%+!$&*=^|~#%'`?{}/\-]+@([A-Za-z0-9\-]+\.){1,}([A-Za-z]{2,16})/;

  constructor(
    private authService: AuthService,
    private matDialog: MatDialogRef<LoginComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public appStoreService: AppStoreService,
    private init: InitServiceService,
    public translateService: ForTranslateService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {

  }

  ngOnInit() {
    //check type
    if(this.data.type === 'register') {
      this.authType = 'signup';
    }

    this.loginForm = this.fb.group({
      email: ['', {
        validators: [
          Validators.required,
          Validators.pattern(this.emailRegex)
        ],
        updateOn: 'blur'
      }],
      password: ['', {
        validators: [
          Validators.required,
        ],
        updateOn: 'change'
      }],
    });
    this.signupForm = this.fb.group({
      email: ['', {
        validators: [
          Validators.required,
          Validators.pattern(this.emailRegex),
        ],
        updateOn: 'blur'
      }],
      username: ['', {
        validators: [
          Validators.required,
          Validators.pattern('[A-Za-z0-9_.-]*'),
          Validators.minLength(3)
        ],
        updateOn: 'change'
      }],
      password: ['', {
        validators: [
          Validators.required,
          Validators.pattern(this.pwRegex),
          Validators.minLength(8)
        ],
        updateOn: 'change'
      }],
      confirmPassword: ['', {
        validators: [
          Validators.required,
          // Validators.pattern('[A-Za-z0-9_.-]*'),
          Validators.minLength(8)
        ],
        updateOn: 'change'
      }],
      referrerID: ['', {
        validators: [],
        updateOn: 'change'
      },
    ],
    }, { validator: this.validatePasswords });
    if (this.appStoreService.queryParams.refID) {
      this.signupForm.controls.referrerID.patchValue(this.appStoreService.queryParams.refID);
      this.signupForm.get('referrerID').disable();
      this.checkDifferentInputType('referrerId');
    }
    this.matDialog.disableClose = false;
    this.referrerSub = this.signupForm.controls.referrerID.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.checkDifferentInputType('referrerId');
      });
    this.referrerSub1 = this.signupForm.controls.referrerID.valueChanges
      .subscribe(() => {
        this.referrerId = null;
      });

    // this.confirmEmailStep();

    this.subscription = this.route.queryParams.subscribe((res: any) => {
      if (res.auth) {
        switch (res.auth) {
          case 'login':
            this.authType = 'login';
            break;

          case 'register':
            this.authType = 'signup';
            if (res.refID) { this.signupForm.controls.referrerID.setValue(res.refID); }
            break;
        }
      }
    });
  }


  accountLogin() {
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    } //end ELSE
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];

    const cpatchaDialog = this.dialog.open(CaptchaPopupComponent, ModalProperties.properties);
    ModalProperties.resetProperties();

    cpatchaDialog.afterClosed().subscribe((result) => {
      if (result) {
        if (this.authType === 'login') {
          this.sending = true;
          if (sessionStorage.getItem('authObj') === null) {
            this.authService
            .login(
              this.loginForm.controls.email.value,
              this.loginForm.controls.password.value
            )
            .subscribe(
              (res: any) => {
                this.authService.authObj = {
                  isLoggedIn: true,
                  username: this.loginForm.controls.email.value,
                  // login_name: res.login_name,
                  login_name: this.loginForm.controls.email.value,
                  sessIDstr: 'o1k24i3',
                  walletConnected: true
                };

                this.authService.loginObservable.next(true);

                sessionStorage.setItem('authObj', JSON.stringify(this.authService.authObj)); //will store it here for now, might need to use JWT in the future
                this.init.loginScript(res);
                this.matDialog.close();
              },
              (err: any) => {
                if (err.status === 400 && err.error === 'acct-not-confirmed: Account not confirmed') {
                  this.sendEmailVerificationCode(this.loginForm.controls.email.value);
                  this.confirmEmailStep(this.loginForm.controls.password.value, this.loginForm.controls.email.value);
                } else if (err.status === 400 && err.error === 'Cannot read property \'session_key\' of undefined') {
                  this.error = 'Please contact admin';
                } else if (err.status === 400 && err.error === 'acct-sess-max:  Users are limited to 2 simultaneous sessions') {
                  this.error = 'error-login-maximum-sessions';
                } else {
                  this.error = 'incorrect-email-or-password';
                }
                this.sending = false;
                console.log(err);
              }
            );
          } else {
            this.authService.authObj = JSON.parse(sessionStorage.getItem('authObj'));
            this.init.loginScript(this.authService.authObj);
          }
        } else if (this.authType === 'signup') {
          if (this.emailExists === false && this.usernameExists === false) {
            this.sending = true;
            this.authService
              .register(
                this.signupForm.controls.email.value,
                this.signupForm.controls.username.value,
                this.signupForm.controls.password.value,
                (this.referrerId ? this.signupForm.controls.referrerID.value : 'picasso')
              )
              .subscribe(
                (res: any) => {
                  this.sending = false;
                  this.sendEmailVerificationCode(
                    this.signupForm.controls.email.value
                  );
                  this.confirmEmailStep(this.signupForm.controls.password.value, this.signupForm.controls.email.value);
                },
                (err: any) => {
                  this.sending = false;
                  alert('error signing up, try again');
                }
              );
          } else {
            // console.log('doesnt work');
          }

        }
      }
    });
  }

  confirmEmailStep(storedPassword?, email?) {
    const dialogRef = this.dialog.open(VerifyEmailComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        password: storedPassword,
        email
      },
      panelClass: 'modal-background'
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.matDialog.close();
        }
      });

  }

  forgotPassword() {
    this.matDialog.close();
    this.dialog.open(ForgotPasswordComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        email: this.loginForm.controls.email.value
      },
      panelClass: 'modal-background'
    });
  }

  sendEmailVerificationCode(email) {
    this.subscription1 = this.authService.sendEmailVerificationCodeOrPasswordForget(email).subscribe((resp: any) => {
      // console.log('email', resp);
    });
  }

  checkDifferentInputType(type?) {
    if (type === 'email') {
      this.emailExists = false;

      if (!this.signupForm.controls.email.value || !this.signupForm.controls.email.valid) {
        return;
      }

      this.checkingEmail = true;
      this.subscription2 = this.authService.checkUserExists(this.signupForm.controls.email.value).subscribe((response: any) => {
        this.checkingEmail = false;
        this.emailExists = response.userExists;
      }, () => {
        this.checkingEmail = false;
      });
    } else if (type === 'username') {
      this.usernameExists = false;
      if (!this.signupForm.controls.username.value || !this.signupForm.controls.username.valid) {
        return;
      }

      this.checkingUserName = true;
      this.subscription2 = this.authService.checkUserExists(this.signupForm.controls.username.value).subscribe((response: any) => {
        this.checkingUserName = false;
        this.usernameExists = response.userExists;
      }, () => {
        this.checkingUserName = false;
      });
    } else {
      this.referrerId = null;
      if (!this.signupForm.controls.referrerID.value) {
        this.referrerId = false;
        return;
      }

      this.checkingReferrer = true;
      this.subscription2 = this.authService.checkUserExists(this.signupForm.controls.referrerID.value).subscribe((response: any) => {
        this.referrerId = response.userExists;
        this.signupForm.controls.referrerID.markAsDirty({onlySelf: true});
        this.checkingReferrer = false;
      }, () => {
        this.checkingReferrer = false;
      });
    }
  }

  isSignupInvalid() {
    return !this.signupForm.valid || this.sending || this.checkingEmail || this.checkingReferrer || this.checkingUserName
      || this.emailExists || this.usernameExists;
  }


  close() {
    this.matDialog.close();
    if(this.router.url.includes('dashboard')) {
      this.router.navigate(['/dashboard/BTC'], { queryParams: { query: null } });
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.referrerSub?.unsubscribe();
  }

  trimSpace() {
    const trimmedSpace = this.loginForm.get('email').value.replace(/\s/g, '').trim();
    this.loginForm.get('email').setValue(trimmedSpace);
    const trimmedSpace2 = this.signupForm.get('email').value.replace(/\s/g, '').trim();
    this.signupForm.get('email').setValue(trimmedSpace2);
    return;
  }

  disallowSpace(event) {
    if (event.keyCode === 32) {
      event.preventDefault();
      return false;
    }
  }

  private validatePasswords(form: FormGroup) {
    //if passwords match, return null because there is no error
    return form.controls.password.value === form.controls.confirmPassword.value ? null : { mismatch: true };
  }

}
