import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({
  name: 'circulationInterfaceText',
})
export class CirculationInterfaceTextPipe implements PipeTransform {

  constructor(
    private router: Router
  ) {

  }

  transform(value: string, ...args: string[]): string {
    if(this.router.url.includes('order-bots')) {
      return 'bots';
    } else if (this.router.url.includes('bonds')) {
      return 'bonds';
    } else if (this.router.url.includes('dashboard')) {
      return 'convenience-options';
    }
    return null;
  }

}
