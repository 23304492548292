import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { StatItem } from '../../models/common/stat-item';
import { StatsService } from '../../services/stats.service';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { FakeLiveDataService } from '../../services/fake-live-data.service';
import { Subscription } from 'rxjs';
import { numberGoUp, numberGoUpWithBackground } from '../../animations/animations';
import { Clipboard } from '@angular/cdk/clipboard';
import { InviteQrComponent } from '../invite-qr/invite-qr.component';
import { ModalProperties } from 'src/app/constants/set-modal-properties';
import { AssetDataService } from '../../services/asset-data.service';


@Component({
  selector: 'app-stats-widget',
  templateUrl: './stats-widget.component.html',
  styleUrls: ['./stats-widget.component.scss'],
  animations: [
    numberGoUpWithBackground
  ]
})
export class StatsWidgetComponent implements OnInit, OnDestroy {

  @Input() inviteWidget = true;
  @Input() rewardWidget = true;
  @Input() inviteLink = false;

  stats: StatItem[];
  subscription: Subscription;
  copied = false;
  inviteURL;

  constructor(
    private clipboard: Clipboard,
    public authService: AuthService,
    private dialog: MatDialog,
    public assetDataService: AssetDataService,
  ) { }

  ngOnInit(): void {
  }

  copy() {
    this.clipboard.copy(this.authService.inviteURL);
    this.copied = true;
  }

  openQR() {
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '700px';
      ModalProperties.properties.maxWidth = '90vw';
    } else {
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    }
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    this.dialog.open(InviteQrComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }
}
