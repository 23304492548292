/* eslint-disable max-len */
import { Component, Input, OnInit, ElementRef, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';
declare let window;

@Component({
  selector: 'app-wave-particle',
  templateUrl: './wave-particle.component.html',
  styleUrls: ['./wave-particle.component.scss'],
})
export class WaveParticleComponent implements OnInit, OnDestroy {
  @Input() svg: any;
  @Input() coordinates: any;
  @Input() friction: any;
  @Input() id: number;
  @Input() animate: boolean;
  @Output() removeMe = new EventEmitter<number>();
  @ViewChild('svg') svgElement;
  a: number;
  steps: number;
  item: any;
  position: any;
  dimensions: { width: any; height: any };
  rotation: string;
  scale: number;
  siner: number;
  speed: any;
  angle: number;
  bounding: { x: any; y: any };
  x: any;
  y: any;
  animationStyle;
  interval;
  constructor(
    public sanitizer: DomSanitizer,
    public elementRef: ElementRef
  ){
  }
  ngOnInit() {
    this.a = [0.5, 1, 2, 0.3, 3 ][Math.floor(Math.random()*4)];
    this.steps = $('#wrapper').width()/8;
    this.item = null;
    this.position = this.coordinates.y;
    this.dimensions = this.render();
    this.rotation = Math.random() > 0.5 ? '-' : '+';
    this.scale = 1 + Math.random();
    this.siner = 100 * Math.random();
    this.angle = 0;
    this.speed = 5;
    this.bounding = {
      x: $('#wrapper').width(),
      y: $('#wrapper').height()
    };
    this.animationStyle = {transform: `translateX(${this.coordinates.x}px) translateY(${this.coordinates.y}px)`};
    this.move();
    this.interval = setInterval(()=>{
      this.move();
    }, 16.7);
  }
  destroy(){
    this.removeMe.emit(this.id);
  }

  move(){
    this.x = this.angle;
    this.y = this.siner +  (this.a * Math.sin(this.angle/this.steps)) * 40;
    this.angle = eval(this.angle+this.speed);
    this.animationStyle = {transform: `translateX(${-60 + this.x}px) translateY(${this.y}px) scale(${this.scale}) rotate(${this.rotation+this.x}deg`};
    if((-60+this.x) > this.bounding.x){
      clearInterval(this.interval);
      this.destroy();
      return false;
    }else{
      // requestAnimationFrame(this.move);
      return true;
    }
  }

  render(){
    return {
      width: 50,
      height: 50
    };
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
