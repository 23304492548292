import { Component, Input, OnInit } from '@angular/core';
import gsap from 'gsap';

@Component({
  selector: 'app-animated-bell',
  templateUrl: './animated-bell.component.html',
  styleUrls: ['./animated-bell.component.scss'],
})
export class AnimatedBellComponent implements OnInit {

  @Input() margin = '0 0 0 0';
  @Input() redToggle;

  constructor() { }

  ngOnInit() {
    this.startAnimation();
  }

    startAnimation() {
      if (!this.redToggle) {
        const bellAn = gsap.timeline({
          repeat: -1,
          repeatDelay: 0,
          defaults: {
            ease: 'sine.inOut',
          },
        });

        bellAn
          .set('#bell-an #bell-group', { svgOrigin: '9.01px 2.95px' })
          .set('#bell-an #dot-pulse', { svgOrigin: '14.36px 5.42px' })
          .to('#bell-an #bell-group', 0.3, { rotation: 5 }, 0)
          .to('#bell-an #bell-group', 2.05, { rotation: 0, ease: 'elastic.out(4, .1)' }, 0.3)
          .to('#bell-an #bell-ball', 0.3, { x: 0.5 }, 0.05)
          .to('#bell-an #bell-ball', 2.05, { x: 0, ease: 'elastic.out(4, .1)' }, 0.35)
          .to('#bell-an #dot-pulse', 1.2, { scale: 2, opacity: 0, repeat: 1 }, 0);
      } else {
        return;
      }

  }
}
