import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionModalComponent } from '../subscription-modal/subscription-modal.component';
import { ModalProperties } from 'src/app/constants/set-modal-properties';
import { Router } from '@angular/router';
import { SubscriptionService } from '../../services/subscription.service';
import { WalletService } from 'src/app/services/wallet.service';
import { CountdownTimerService } from '../../services/countdown-timer.service';

@Component({
  selector: 'app-subscription-button',
  templateUrl: './subscription-button.component.html',
  styleUrls: ['./subscription-button.component.scss']
})
export class SubscriptionButtonComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    public subscriptionService: SubscriptionService,
    public walletService: WalletService,
    public countTimerService: CountdownTimerService
  ) { }

  ngOnInit(): void {
    ModalProperties.setBaseParams();
  }

  onClick() {
    if(!this.authService.authObj.walletConnected) {
      this.router.navigate(['/wallet']);
    } else {
      if (ModalProperties.properties.screenWidth > 750) {
        ModalProperties.properties.width = '750px';
        ModalProperties.properties.maxWidth = '90vw';
      } else { //Modal Max width for full width pop ups on mobile thingy
        ModalProperties.properties.width = '100%';
        ModalProperties.properties.maxWidth = '100vw';
      } //end ELSE

      ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
      this.dialog.open(SubscriptionModalComponent, ModalProperties.properties);
      ModalProperties.resetProperties();
    }
  }
}
