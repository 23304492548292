import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { WalletService } from '../../services/wallet.service';
import { AppStoreService } from '../../services/app-store.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { coinLibrary } from '../../models/common/coinLibrary';
import { LoginComponent } from '../login/login.component';
import { QrContractComponent } from '../qr-contract/qr-contract.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-bots-interface',
  templateUrl: './order-bots-interface.component.html',
  styleUrls: ['./order-bots-interface.component.scss']
})
export class OrderBotsInterfaceComponent implements OnInit, OnDestroy {

  coinAmount;
  selectedCoin;
  subscription: Subscription;

  constructor(
    public walletService: WalletService,
    public appStore: AppStoreService,
    private dialog: MatDialog,
    public authService: AuthService,
    public router: Router
  ) { }

  ngOnInit() {
    this.subscription = this.appStore.selectedAsset.subscribe((data)=>{
      this.selectedCoin = data;
    });
  }

  getCoinAmount() {
    return 1234.666;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  eventHandler(keycode: number) {
    if (!this.coinAmount) {
      if (keycode === 48) {
        return false;
      }
    }
  }
}
