import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailLength'
})
export class EmailLengthPipe implements PipeTransform {

  transform(value: string): any {
    const regex = /.*(?=@)+/g;
    const regex2 = /(?=@).*/g;
    const emailName = value.match(regex)[0];
    const email = value.match(regex2)[0];
    // eslint-disable-next-line max-len
    return (emailName.length > 15 ?  emailName.replace(emailName.substring(3, (emailName.length - 3)), '...') + email : value);
  }

}
