export const environment = {
  production: true,
  apiUrl: 'prod',
  registerEndpoint: 'prod/register',
  loginEndpoint: 'prod/login',
  addUserWalletEndpoint: 'prod/addwallet',
  checkUserEndpoint: 'prod/register',
  passwordEmailEndpoint: 'prod/changepassword',
  userSubscriptionEndpoint: 'prod/usersubscription',
  getInviteHistory: 'prod/invitations',
  sendCreditsEndpoint: 'prod/send-credits',
  rewardsHistoryEndpoint: 'prod/rewards-history',
  checkSusbcription: 'prod/check-subscription',
  pingUserSession: 'prod/pingusersession'
};
