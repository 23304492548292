/* eslint-disable max-len */
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { CirculationService } from 'src/app/services/circulation.service';
import { AppStoreService } from '../../services/app-store.service';
import { Observable, Subscription } from 'rxjs';
import { CoinAsset } from '../../models/common/coin-asset';
import { WebsocketService } from 'src/app/services/websocket.service';
import { CirculationHistoryItem } from 'src/app/models/common/circulation-history-models/circulation-history-item';
import { Web3Service } from '../../services/web3.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';
import { WalletService } from '../../services/wallet.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-circulation-history',
  templateUrl: './circulation-history.component.html',
  styleUrls: ['./circulation-history.component.scss']
})
export class CirculationHistoryComponent implements OnInit, AfterViewInit, OnDestroy {
  psList: CirculationHistoryItem[] = [
    // {
    //     "amt": 723,
    //     "bonus_amt": 1.2,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163212050",
    //     "tx_id": "0x7dfc57c5B332941C4f6e73cc21B98C01ca4E00a3",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41245
    // },
    // {
    //     "amt": 124,
    //     "bonus_amt": .13,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163213048",
    //     "tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41445
    // },
    // {
    //     "amt": 559,
    //     "bonus_amt": .23,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163214048",
    //     "tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41145
    // },
    // {
    //     "amt": 955,
    //     "bonus_amt": 2.0,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163215048",
    //     "tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41245
    // },
    // {
    //     "amt": 490,
    //     "bonus_amt": 3.7,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163216048",
    //     "tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41345
    // },
    // {
    //     "amt": 245,
    //     "bonus_amt": 2.2,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163217048",
    //     "tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41545
    // },
    // {
    //     "amt": 65,
    //     "bonus_amt": 2.8,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163218048",
    //     "tx_id": "0xf58bF438524d33Ee80d0c0c58555ffa82183b1dF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41312
    // },
    // {
    //     "amt": 157,
    //     "bonus_amt": .88,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163219049",
    //     "tx_id": "0xf58bF438524d33Ee80d0c0c58555ffa82183b1dF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41375
    // },
    // {
    //     "amt": 971,
    //     "bonus_amt": .75,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163220048",
    //     "tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41321
    // },
    // {
    //     "amt": 66,
    //     "bonus_amt": 5.4,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163221050",
    //     "tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41893
    // },
    // {
    //     "amt": 742,
    //     "bonus_amt": 3.2,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163222049",
    //     "tx_id": "0xf58bF438524d33Ee80d0c0c58555ffa82183b1dF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41956
    // },
    // {
    //     "amt": 90,
    //     "bonus_amt": 4.4,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163223049",
    //     "tx_id": "0xf58bF438524d33Ee80d0c0c58555ffa82183b1dF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41232
    // },
    // {
    //     "amt": 428,
    //     "bonus_amt": 5.1,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163224048",
    //     "tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41111
    // },
    // {
    //     "amt": 378,
    //     "bonus_amt": .23,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163225048",
    //     "tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41222
    // },
    // {
    //     "amt": 270,
    //     "bonus_amt": .97,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163226048",
    //     "tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41435
    // },
    // {
    //     "amt": 776,
    //     "bonus_amt": .75,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163227048",
    //     "tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41666
    // },
    // {
    //     "amt": 898,
    //     "bonus_amt": .12,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163228048",
    //     "tx_id": "0xf58bF438524d33Ee80d0c0c58555ffa82183b1dF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41622
    // },
    // {
    //     "amt": 354,
    //     "bonus_amt": .1,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163229048",
    //     "tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41511
    // },
    // {
    //     "amt": 17,
    //     "bonus_amt": .2,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163230048",
    //     "tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41311
    // },
    // {
    //     "amt": 391,
    //     "bonus_amt": .3,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163231049",
    //     "tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41535
    // },
    // {
    //     "amt": 651,
    //     "bonus_amt": .5,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163232048",
    //     "tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41865
    // },
    // {
    //     "amt": 198,
    //     "bonus_amt": .54,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163233048",
    //     "tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41364
    // },
    // {
    //     "amt": 954,
    //     "bonus_amt": .6,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163234048",
    //     "tx_id": "0x7dfc57c5B332941C4f6e73cc21B98C01ca4E00a3",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41412
    // },
    // {
    //     "amt": 200,
    //     "bonus_amt": .3,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163235048",
    //     "tx_id": "0xf58bF438524d33Ee80d0c0c58555ffa82183b1dF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41444
    // },
    // {
    //     "amt": 348,
    //     "bonus_amt": .4,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163236049",
    //     "tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41222
    // },
    // {
    //     "amt": 829,
    //     "bonus_amt": .12,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163237048",
    //     "tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41712
    // },
    // {
    //     "amt": 568,
    //     "bonus_amt": .28,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163238048",
    //     "tx_id": "0xf58bF438524d33Ee80d0c0c58555ffa82183b1dF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41412
    // },
    // {
    //     "amt": 846,
    //     "bonus_amt": .8,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163239049",
    //     "tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41444
    // },
    // {
    //     "amt": 88,
    //     "bonus_amt": .7,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163240048",
    //     "tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41666
    // },
    // {
    //     "amt": 96,
    //     "bonus_amt": .6,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163241048",
    //     "tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41777
    // },
    // {
    //     "amt": 486,
    //     "bonus_amt": .5,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163242048",
    //     "tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41756
    // },
    // {
    //     "amt": 219,
    //     "bonus_amt": 2.2,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163243048",
    //     "tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41423
    // },
    // {
    //     "amt": 42,
    //     "bonus_amt": 3.4,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163244048",
    //     "tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41612
    // },
    // {
    //     "amt": 665,
    //     "bonus_amt": 4.4,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163245048",
    //     "tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41997
    // },
    // {
    //     "amt": 861,
    //     "bonus_amt": 2.3,
    //     "bonus_tx_id": null,
    //     "complete_dt": null,
    //     "currency": "ETH",
    //     "status": "Wait Payout",
    //     "tran_type": "Wait Payout",
    //     "tx_dt": "1642163246048",
    //     "tx_id": "0x7dfc57c5B332941C4f6e73cc21B98C01ca4E00a3",
    //     "issue": 0,
    //     "type": "PS",
    //     price: 41312
    // }
  ];
  gsList: CirculationHistoryItem[] = [
    // {
    //     "amt": 179,
    //     "bonus_amt": 4.8,
    //     "bonus_tx_id": "0xf58bF438524d33Ee80d0c0c58555ffa82183b1dF",
    //     "complete_dt": "1642163287544",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163287544",
    //     "tx_id": "0xf58bF438524d33Ee80d0c0c58555ffa82183b1dF",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41321
    // },
    // {
    //     "amt": 886,
    //     "bonus_amt": 6.2,
    //     "bonus_tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "complete_dt": "1642163286544",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163286544",
    //     "tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41423
    // },
    // {
    //     "amt": 579,
    //     "bonus_amt": 8.0,
    //     "bonus_tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "complete_dt": "1642163285543",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163285543",
    //     "tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41635
    // },
    // {
    //     "amt": 908,
    //     "bonus_amt": 6.1,
    //     "bonus_tx_id": "0x7dfc57c5B332941C4f6e73cc21B98C01ca4E00a3",
    //     "complete_dt": "1642163284544",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163284544",
    //     "tx_id": "0x7dfc57c5B332941C4f6e73cc21B98C01ca4E00a3",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41774
    // },
    // {
    //     "amt": 73,
    //     "bonus_amt": 8.2,
    //     "bonus_tx_id": "0xf58bF438524d33Ee80d0c0c58555ffa82183b1dF",
    //     "complete_dt": "1642163283545",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163283545",
    //     "tx_id": "0xf58bF438524d33Ee80d0c0c58555ffa82183b1dF",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41237
    // },
    // {
    //     "amt": 259,
    //     "bonus_amt": 7.4,
    //     "bonus_tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "complete_dt": "1642163282544",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163282544",
    //     "tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41123
    // },
    // {
    //     "amt": 236,
    //     "bonus_amt": 3.4,
    //     "bonus_tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "complete_dt": "1642163281544",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163281544",
    //     "tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41423
    // },
    // {
    //     "amt": 470,
    //     "bonus_amt": 5.9,
    //     "bonus_tx_id": "0x7dfc57c5B332941C4f6e73cc21B98C01ca4E00a3",
    //     "complete_dt": "1642163280543",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163280543",
    //     "tx_id": "0x7dfc57c5B332941C4f6e73cc21B98C01ca4E00a3",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41132
    // },
    // {
    //     "amt": 677,
    //     "bonus_amt": .66,
    //     "bonus_tx_id": "0x7dfc57c5B332941C4f6e73cc21B98C01ca4E00a3",
    //     "complete_dt": "1642163279544",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163279544",
    //     "tx_id": "0x7dfc57c5B332941C4f6e73cc21B98C01ca4E00a3",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41645
    // },
    // {
    //     "amt": 904,
    //     "bonus_amt": .9,
    //     "bonus_tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "complete_dt": "1642163278544",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163278544",
    //     "tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 413722
    // },
    // {
    //     "amt": 201,
    //     "bonus_amt": .7,
    //     "bonus_tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "complete_dt": "1642163277543",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163277543",
    //     "tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41841
    // },
    // {
    //     "amt": 140,
    //     "bonus_amt": .65,
    //     "bonus_tx_id": "0x7dfc57c5B332941C4f6e73cc21B98C01ca4E00a3",
    //     "complete_dt": "1642163276544",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163276544",
    //     "tx_id": "0x7dfc57c5B332941C4f6e73cc21B98C01ca4E00a3",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41904
    // },
    // {
    //     "amt": 940,
    //     "bonus_amt": 1.7,
    //     "bonus_tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "complete_dt": "1642163275544",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163275544",
    //     "tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41409
    // },
    // {
    //     "amt": 610,
    //     "bonus_amt": 2.3,
    //     "bonus_tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "complete_dt": "1642163274545",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163274545",
    //     "tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41441
    // },
    // {
    //     "amt": 159,
    //     "bonus_amt": 1.5,
    //     "bonus_tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "complete_dt": "1642163273544",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163273544",
    //     "tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41112
    // },
    // {
    //     "amt": 538,
    //     "bonus_amt": 1.2,
    //     "bonus_tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "complete_dt": "1642163272544",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163272544",
    //     "tx_id": "0x407c5D6f7A462907deb329980c96a166CeEcb8AF",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41212
    // },
    // {
    //     "amt": 309,
    //     "bonus_amt": 2.1,
    //     "bonus_tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "complete_dt": "1642163271545",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163271545",
    //     "tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41412
    // },
    // {
    //     "amt": 47,
    //     "bonus_amt": 1.3,
    //     "bonus_tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "complete_dt": "1642163270545",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163270545",
    //     "tx_id": "0x302dF232ef2611F579E1a8B869894e668F0d4FCF",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41712
    // },
    // {
    //     "amt": 447,
    //     "bonus_amt": 4.5,
    //     "bonus_tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "complete_dt": "1642163269544",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163269544",
    //     "tx_id": "0xFA0b8cE0Ce6f089053e21E326336241d99188be5",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41314
    // },
    // {
    //     "amt": 518,
    //     "bonus_amt": 6.2,
    //     "bonus_tx_id": "0x7dfc57c5B332941C4f6e73cc21B98C01ca4E00a3",
    //     "complete_dt": "1642163268543",
    //     "currency": "ETH",
    //     "status": "complete",
    //     "tran_type": null,
    //     "tx_dt": "1642163268543",
    //     "tx_id": "0x7dfc57c5B332941C4f6e73cc21B98C01ca4E00a3",
    //     "issue": 0,
    //     "type": "GS",
    //     price: 41231
    // }
  ];
  lastPrice = this.appStore.selectedAssetValue.price;
  priceTernary = false;
  $selectedAsset: Observable<CoinAsset>;

  historySub: Subscription;
  subscription: Subscription;
  subscription1: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;
  interval;
  interval2;
  fakeAddresses = [
    '0xFA0b8cE0Ce6f089053e21E326336241d99188be5',
    '0x407c5D6f7A462907deb329980c96a166CeEcb8AF',
    '0x7dfc57c5B332941C4f6e73cc21B98C01ca4E00a3',
    '0x302dF232ef2611F579E1a8B869894e668F0d4FCF',
    '0xf58bF438524d33Ee80d0c0c58555ffa82183b1dF'
  ];

  constructor(
    private circulation: CirculationService,
    public appStore: AppStoreService,
    // private websocketService: WebsocketService,
    private web3service: Web3Service,
    private toastr: ToastrService,
    public auth: AuthService,
    private walletService: WalletService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscription2 = this.appStore.selectedAsset.subscribe(res => {
      this.psList = [];
      this.gsList = [];
      for (let i = 0; i < 40; i++) {
        this.addPS();
        this.addGS();
      }
    });

    for (let i = 0; i < 40; i++) {
      this.addPS();
      this.addGS();
    }

    this.subscription3 = this.auth.loginObservable.subscribe((res: any) => {
      this.getHistoryPS();
      this.getHistoryGS();
    });

    this.interval = setInterval(() => {
      this.addPS();
      // const currentPrice = ((this.psList[this.psList.length - 1].price + this.gsList[1].price) / 2);
      // this.priceTernary = currentPrice > this.lastPrice;
      // this.lastPrice = currentPrice;
      this.priceTernary = this.appStore.selectedAssetValue.price >= this.lastPrice;
      this.lastPrice = this.appStore.selectedAssetValue.price;

      setTimeout(() => {
        if (document.getElementById('pslist')) {
          document.getElementById('pslist').scrollTop = document.getElementById('pslist')?.scrollHeight + 100;
        }
      }, 0);
    }, 15000);
    this.interval2 = setInterval(() => {
      this.addGS();
      // const currentPrice = ((this.psList[this.psList.length - 1].price + this.gsList[1].price) / 2);
      // this.priceTernary = currentPrice > this.lastPrice;
      // this.lastPrice = currentPrice;
      // this.priceTernary = this.appStore.selectedAssetValue.price > this.lastPrice;
      // this.lastPrice = this.appStore.selectedAssetValue.price;
      setTimeout(() => {
        if (document.getElementById('pslist')) {
          document.getElementById('pslist').scrollTop = document.getElementById('pslist')?.scrollHeight + 100;
        }
      }, 0);
    }, 20000);

    // this.historySub = this.websocketService.getMessage('get new data')
    //   .subscribe((result: any) => {
    //     if (result && (result.recordType === 'PS_SENT' ||
    //       result.recordType === 'pymt') && result.data.userAddress === this.walletService.wallet.ethAddress) {
    //       this.addPS(result);
    //       this.toastr.clear(this.web3service.confirmedToast?.toastId);
    //       this.toastr.info(
    //         `<span class="text-bold">Your EMPOWER has been processed successfully!</span></br></br> <i class="icon icon-checkmark tertiary"></i> <span>Keep Empowering!</span>`,
    //         '',
    //         {
    //         timeOut: 10000,
    //         closeButton: true,
    //         easeTime: 150,
    //         progressBar: true
    //       });
    //     }

    //     if (result.recordType === 'circ' && result.data.userAddress === this.walletService.wallet.ethAddress) {
    //       this.getHistoryPS();
    //       this.addGS(result.data);
    //     }
    //   });

    this.$selectedAsset = this.appStore.selectedAssetInfo;
  }

  addPS() {
    // const data = results.data;
    // this.getHistoryPS();
    // if (!this.psList.find(psItem => psItem.tx_id === data.psTxID)) {
    // console.log('data', this.psList)
    // console.log('adding ps', results);
    const rng = Math.floor(Math.random() * 5);
    this.psList.push(new CirculationHistoryItem(
      Math.floor(Math.random() * 1001) + 1,
      Math.random() * 4,
      null,
      null,
      'ETH',
      'Wait Payout',
      'Wait Payout', // not sure what this is
      Date.now().toString(),
      this.fakeAddresses[rng],
      0,
      'PS',
      // this.appStore.selectedAssetValue.price + 1
      (Math.floor(Math.random() * (5) - 1) + this.appStore.selectedAssetValue.price)
    ));
    // }

    if (this.psList.length > 50) {
      this.psList.shift();
    }
  }

  addGS() {
    // if (!this.gsList.find(gs => gs.tx_id === results.psTxID)) {
    // this.getHistoryPS();
    // this.getHistoryGS();
    const rng = Math.floor(Math.random() * 5);
    this.gsList.unshift(new CirculationHistoryItem(
      Math.floor(Math.random() * 1001) + 1,
      Math.random() * 4,
      this.fakeAddresses[rng],
      Date.now().toString(),
      'ETH',
      'complete',
      null,
      Date.now().toString(),
      this.fakeAddresses[rng],
      0,
      'GS',
      // this.appStore.selectedAssetValue.price - 1
      (Math.floor(Math.random() * (5) + 1) + this.appStore.selectedAssetValue.price)
    ));

    if (this.gsList.length > 50) {
      this.gsList.pop();
    }

    // }
  }

  getHistoryPS() {
    // this.subscription = this.circulation.getCirculationPS(this.appStore.selectedAssetValue.symbol.toUpperCase(), this.appStore.selectedAssetValue.issue)
    //   .subscribe((res: CirculationHistoryItem[]) => {
    //     this.psList = res.splice(0,80);
    //     if(document.getElementById('psList')) {
    //       document.getElementById('pslist').scrollTop = document.getElementById('pslist')?.scrollHeight;
    //     }
    //   });
  }

  getHistoryGS() {
    // this.subscription1 = this.circulation.getCirculationGS(this.appStore.selectedAssetValue.symbol.toUpperCase(), this.appStore.selectedAssetValue.issue)
    //   .subscribe((res: CirculationHistoryItem[]) => {
    //       this.gsList = res.splice(0,80);
    //   });
  }

  scrollToBottom(): void {
    if (document.getElementById('pslist')) {
      document.getElementById('pslist').scrollTop = document.getElementById('pslist')?.scrollHeight;
    }
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  ngOnDestroy(): void {
    this.historySub?.unsubscribe();
    this.subscription?.unsubscribe();
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    clearInterval(this.interval);
    clearInterval(this.interval2);
  }
}
