import { Component, OnInit } from '@angular/core';
import { MatDialogRef,  } from '@angular/material/dialog';


@Component({
  selector: 'app-metamask-notification',
  templateUrl: './metamask-notification.component.html',
  styleUrls: ['./metamask-notification.component.scss'],
})
export class MetamaskNotificationComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<MetamaskNotificationComponent>,
  ) {}

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close();
  }

  openMetaMaskSite() {
    // window.open('https://empower.io/downloads#pc', '_blank');
  }

  refreshPage() {
    location.reload();
  }
}
