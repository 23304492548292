import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-animated-empower-logo',
  templateUrl: './animated-empower-logo.component.html',
  styleUrls: ['./animated-empower-logo.component.scss'],
})
export class AnimatedEmpowerLogoComponent implements OnInit {
  @Input() setHeight: boolean;
  @Input() width100: boolean;
  @Input() disabled: boolean;

  constructor() { }

  ngOnInit() {}

}
