import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../services/subscription.service';
import { Web3Service } from '../../services/web3.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ModalProperties } from 'src/app/constants/set-modal-properties';
import { QrContractComponent } from '../qr-contract/qr-contract.component';
import { SubscriptionModalComponent } from 'src/app/header/subscription-modal/subscription-modal.component';

@Component({
  selector: 'app-buy-subscription-credits',
  templateUrl: './buy-subscription-credits.component.html',
  styleUrls: ['./buy-subscription-credits.component.scss'],
})
export class BuySubscriptionCreditsComponent implements OnInit {
  subscriptionCredits;

  constructor(
    public dialog: MatDialog,
    public subscriptionService: SubscriptionService,
    public web3Service: Web3Service,
    public dialogRef: MatDialogRef<BuySubscriptionCreditsComponent>
  ) { }

  ngOnInit() {}

  checkValueCredits(event) {
    if (event.keyCode === 38 || event.keyCode === 40 || event.code === 'Minus') {
      event.preventDefault();
      return false;
    }
    if (event.keyCode !== 8 &&
      (((!event.target.value.length || event.target.value == 0) && event.key === '0')


      )) {
      event.preventDefault();
      return false;
    };
  }

  async openMetaMask() {
    //WHETHER OR NOT API #8 WILL BE CALLED WILL HAPPEN WITHIN THE SENDBEP20SUBSCRIPTION web3service.
    if (!this.web3Service.checkMetaMask()) {
      return this.openQrContract();
    }
    const chainId = await this.web3Service.getChainId();
    if (chainId !== 56) {
      await this.web3Service.networkRequest('bscNetwork');
      const chainddTwo = await this.web3Service.getChainId();
      if (chainddTwo === 56) {
        this.web3Service.sendBep20Subscription(this.subscriptionCredits, this.subscriptionService.subscriptionWallet.subscriptionAddress,
          'Your subscription TX has been confirmed. Your order is being processes');
      }
    } else {
      this.web3Service.sendBep20Subscription(this.subscriptionCredits, this.subscriptionService.subscriptionWallet.subscriptionAddress,
        'Your subscription TX has been confirmed. Your order is being processes');
    }
    this.subscriptionCredits = '';
  }

  openQrContract() {
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    } //end ELSE
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen']; //small-screen removes stupid material padding
    ModalProperties.properties.data = {
      data : {
        totalSum: this.subscriptionCredits,
        contractAddress: this.subscriptionService.subscriptionWallet.subscriptionAddress,
        subscriptionModal: true,
        accountCredit: true,
        selected: {
          name: 'USDT',
          image: 'assets/images/coin-logo/USDT.png'
        }
      }
    };
    this.dialog.open(QrContractComponent, ModalProperties.properties);
    this.subscriptionCredits = '';
    ModalProperties.resetProperties();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToSubscription() {
    this.closeDialog();
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    } //end ELSE
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    ModalProperties.properties.data = {
      data : {totalSum: this.subscriptionCredits, contractAddress: this.subscriptionService.subscriptionWallet.subscriptionAddress}
    };
    this.dialog.open(SubscriptionModalComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

}
