/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { AuthObj, RegisterObj, LoginObj, UserInfo } from '../models/auth-interfaces';
import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { createHash } from 'crypto-browserify';
import md5 from 'crypto-js/md5';
import { ForTranslateService } from './for-translate.service';
import * as encoder from 'js-sha256';
import { WalletService } from './wallet.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loginObservable = new Subject<any>();
  randomLogin = [
    {
      name: 'Cryptotrader123',
      profileImage: 'assets/images/profile/profile-pic-3-min.jpg'
    },
    {
      name: 'WagieWojak',
      profileImage: 'assets/images/profile/profile-pic-1-min.jpg'
    },
    {
      name: 'PepeBoss',
      profileImage: 'assets/images/profile/profile-pic-2-min.jpg'
    },
    {
      name: 'GokuBTC45',
      profileImage: 'assets/images/profile/profile-pic-4-min.jpg'
    },
    {
      name: 'NFTmaster',
      profileImage: 'assets/images/profile/profile-pic-5-min.jpg'
    }
  ];

  authObj: AuthObj = {
    isLoggedIn: false,
    userID: null,
    subscription: false,
    sessIDstr: null,
    walletConnected: null
  };

  inviteURL;
  invitationHistory: any = [];
  constructor(
    private http: HttpClient,
    private translateService: ForTranslateService,
  ) { }

  login(loginName: string, password: string, ipAddress?: string): Observable<any> {
    const body: LoginObj = {
      loginName,
      pwHash: '0x' + encoder.sha256(password),
      // pwHash: password,
      ipAddress: ipAddress ? ipAddress : ''
    };
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    // return this.http.post(environment.loginEndpoint, JSON.stringify(body), config);
    return of(true);
  }

  logout() {
    // const body = {
    //   sessIDstr: this.authObj.sessIDstr
    // };
    sessionStorage.removeItem('sessIDstr');
    sessionStorage.removeItem('authObj');
    // this.authObj.isLoggedIn = false;
    // const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    // this.http.put(environment.loginEndpoint, JSON.stringify(body), config).subscribe();
    location.reload();
  }

  register(emailAddr: string, loginName: string, password: string, referrerID: string): Observable<any> {
    const body: RegisterObj = {
      emailAddr,
      loginName,
      pwHash: '0x' + encoder.sha256(password),
      // firstName: '1',
      // lastName: '1',
      ipAddress: '1', //need a service for this
      defaultLang: this.translateService.currentLanguage,
      countryCode: 'cn ', //i think we can get this from the ip service
      referredBy: referrerID //need this in the form
    };
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.post(environment.registerEndpoint, JSON.stringify(body), config);
  }

  updateUserInfo(obj) {
    const body: any = obj;
    body.sessIDstr = this.authObj.sessIDstr;
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.put(environment.registerEndpoint, JSON.stringify(body), config);
  }

  checkUserExists(user) {
    const url = `${environment.checkUserEndpoint}?IdStr=${user}`;
    return this.http.get(url);
  }

  sendEmailVerificationCodeOrPasswordForget(email) {
    const url = `${environment.passwordEmailEndpoint}?userEmail=${email}`;
    return this.http.get(url);
  }

  forgotPassword(verificationCode, password) {
    const body = {
      token: verificationCode,
      newPwHash: '0x' + encoder.sha256(password)
    };
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.put(environment.passwordEmailEndpoint, JSON.stringify(body), config);
  }

  verifyVerificationCode(verificationCode, password) {
    const body = {
      token: verificationCode,
      oldPwHash: '0x' + encoder.sha256(password),
      newPwHash: '0x' + encoder.sha256(password)
    };
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.post(environment.passwordEmailEndpoint, JSON.stringify(body), config);
  }

  changePassword(verificationCode: string, oldPassword: string, newPassword: string): Observable<any> {
    const body = {
      token: verificationCode,
      oldPwHash: '0x' + encoder.sha256(oldPassword),
      newPwHash: '0x' + encoder.sha256(newPassword)
    };
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.post(environment.passwordEmailEndpoint, JSON.stringify(body), config);
  }

  getInviteHistory() {
    const params = new HttpParams().set('sessIDstr', this.authObj.sessIDstr);
    return this.http.get(environment.getInviteHistory, {
      params
    });
  }

  pingUserSession() {
    const params = new HttpParams().set('sessIDstr', this.authObj.sessIDstr);
    return this.http.get(environment.pingUserSession, {
      params
    });
  }

  setInviteUrl(language?: string) {
    let lang = this.translateService.currentLanguage;
    if (language) {
      lang = language;
    }
    if (lang === 'zh') {
      lang = 'zh-hans';
    } else if (lang === 'zh-TW') {
      lang = 'zh-hant';
    }
    this.inviteURL = 'https://test.io/?refID=' + this.authObj.login_name + ('&lang=' + lang);
  }
}
