import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    private dialog: MatDialogRef<ChangePasswordComponent>
  ) { }

  ngOnInit() {}

  close() {
    this.dialog.close();
  }
}
