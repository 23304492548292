/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '@ngx-translate/core';
// import { ValidationService } from 'src/app/services/validation.service';
declare let sliderCaptcha;

@Component({
  selector: 'app-captcha-popup',
  templateUrl: './captcha-popup.component.html',
  styleUrls: ['./captcha-popup.component.scss'],
  providers: [TranslatePipe],
})
export class CaptchaPopupComponent implements OnInit {
  count = 0;
  captcha: number;
  attempted = false;

  number1: number;
  number2: number;
  questionNumber: number;
  questions = [
    {
      question: 'what-is-the-sum',
      answer: 'sum',
    },
    {
      question: 'what-is-the-number-on-the-right',
      answer: 'number2',
    },
    {
      question: 'what-is-the-number-on-the-left',
      answer: 'number1',
    },
  ];

  isCaptcha = true; // true for captcha, false for number captcha

  constructor(
    public dialogRef: MatDialogRef<CaptchaPopupComponent>,
    public translatePipe: TranslatePipe // public validationService: ValidationService
  ) {}

  generateQuestion() {
    return this.questions[this.questionNumber].question;
  }

  switchQuestionType(type) {
    this.isCaptcha = type;

    if (this.isCaptcha) {
      this.setupCaptcha();
    } else {
      this.setupQuestion();
    }
  }

  validateNumber() {
    if (this.questionNumber) {
      return this[this.questions[this.questionNumber].answer] === this.captcha;
    } else {
      return this.captcha === this.number1 + this.number2;
    }
  }

  submitAnswer() {
    this.count++;
    this.attempted = true;
    if (this.validateNumber()) {
      this.close(true);
    } else {
      if (this.count > 2) {
        this.setupQuestion();
      }
    }
  }

  setupQuestion() {
    this.count = 0;
    this.attempted = false;
    this.number1 = Math.ceil(Math.random() * 9);
    while (this.number2 === this.number1 || !this.number2) {
      this.number2 = Math.ceil(Math.random() * 9);
    }
    this.questionNumber = Math.floor(Math.random() * this.questions.length);
    this.generateQuestion();
  }

  setupCaptcha() {
    this.count = 0;
    setTimeout(() => {
      const captcha = sliderCaptcha({
        id: 'captcha',
        onSuccess: () => {
          this.close(true);
        },
        onFail: () => {
          this.count++;
          if (this.count > 2) {
            // this.validationService.handleCustomError('validation-failed');
            this.close();
          }
        },
        setSrc() {
          // 278 X 155
          const imgArr = [
            'assets/images/captcha-images/captcha1.jpg',
            'assets/images/captcha-images/captcha4.jpg',
            'assets/images/captcha-images/captcha5.jpg',
            'assets/images/captcha-images/captcha9.jpg',
            'assets/images/captcha-images/captcha10.jpg',
            'assets/images/captcha-images/captcha11.jpg',
          ];

          return imgArr[Math.floor(Math.random() * imgArr.length)];
        },
        loadingText: this.translatePipe.transform('loading'),
        failedText: this.translatePipe.transform('try-again'),
        barText: ' ',
        maxLoadCount: 3,
      });
    });
  }

  ngOnInit() {
    this.setupCaptcha();
    this.dialogRef.disableClose = true;
  }

  close(result?) {
    this.dialogRef.close(result);
  }
}
