import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './routing/app-routing.module';
import { AppCommonModule } from './common/app-common.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NavigationComponent } from './header/navigation/navigation.component';
import { HeaderComponent } from './header/header.component';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { PreAccessComponent } from './pre-access/pre-access.component';
import { SubscriptionModalComponent } from './header/subscription-modal/subscription-modal.component';
import { RouterModule } from '@angular/router';
import { MobileBottomNavBarComponent } from './mobile-bottom-nav-bar/mobile-bottom-nav-bar.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io/';
import { AppStoreService } from './services/app-store.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrModule } from 'ngx-toastr';
import { MaintenanceAnnouncementComponent } from './maintenance-announcement/maintenance-announcement.component';
import { TranslatePipe } from './pipes/translate.pipe';
import { InitServiceService } from './services/init-service.service';

// const config: SocketIoConfig = {
//   url: 'wss://test.powerstation.ai',
//   options: { transports: ['websocket', 'polling'] }
// };


// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
  // eslint-disable-next-line max-len
  return new TranslateHttpLoader(http, '../assets/lang/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavigationComponent,
    PreAccessComponent,
    SubscriptionModalComponent,
    MobileBottomNavBarComponent,
    MaintenanceAnnouncementComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    // SocketIoModule.forRoot(config),
    IonicModule.forRoot(),
    AppRoutingModule,
    AppCommonModule,
    HttpClientModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse' }),
    FormsModule,
    MatDialogModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      maxOpened: 6,
      disableTimeOut: 'extendedTimeOut',
      autoDismiss: true,
      closeButton: true,
      enableHtml: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    RouterModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md'
    })
  ],
  providers: [TranslatePipe , AppStoreService, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule { }
