import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription, Observable, fromEvent } from 'rxjs';
import { AppStoreService } from '../../services/app-store.service';
import { CoinAsset } from '../../models/common/coin-asset';
import { MatDialog } from '@angular/material/dialog';
import { TokenSwapComponent } from '../token-swap/token-swap.component';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ModalProperties } from 'src/app/constants/set-modal-properties';
import { LoginComponent } from '../login/login.component';
import { map, debounceTime } from 'rxjs/operators';
import { AssetDataService } from '../../services/asset-data.service';
import { AssetInfoComponent } from '../asset-info/asset-info.component';

@Component({
  selector: 'app-chart-info',
  templateUrl: './chart-info.component.html',
  styleUrls: ['./chart-info.component.scss'],
})
export class ChartInfoComponent implements OnInit, OnDestroy, AfterViewInit {
  coin: CoinAsset;
  subscription: Subscription;
  symbol: string;
  isLoaded = false;
  $selectedAsset: Observable<CoinAsset>;
  $currentWindowWidth: Observable<any>;

  constructor(
    public appStore: AppStoreService,
    private dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    public assetDataService: AssetDataService
  ) { }

  ngOnInit(): void {
    this.assetChangeSubscription();
    this.$selectedAsset = this.appStore.selectedAssetInfo;
    this.screenSizeCheck();
  }

  ngAfterViewInit() {
    window.dispatchEvent(new Event('resize'));
  }

  screenSizeCheck() {
    const $screenSize = fromEvent(window, 'resize')
      .pipe(debounceTime(300), map(() => {
        this.isLoaded = true;
        return window.innerWidth > 480;
      }));
    this.$currentWindowWidth = $screenSize;
  }

  assetChangeSubscription() {
    this.subscription = this.appStore.selectedAssetInfo.subscribe(
      (res: CoinAsset) => {
        this.coin = res;
      }
    );
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  onSwap(asset: any) {
    if (!this.authService.authObj.isLoggedIn) {
      ModalProperties.setBaseParams();
      if (ModalProperties.properties.screenWidth > 600) {
        ModalProperties.properties.width = '600px';
        ModalProperties.properties.maxWidth = '90vw';
      }
      ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
      this.dialog.open(LoginComponent, ModalProperties.properties);
      ModalProperties.resetProperties();
    } else if (!this.authService.authObj.walletConnected) {
      this.router.navigate(['/wallet']);
    } else {
      const element = this.dialog.open(TokenSwapComponent, {
        width: '800px',
        panelClass: ['modal-background', 'small-screen'],
        data: asset,
      });
    }
  }

  openAssetInfo() {
    ModalProperties.setBaseParams();
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '700px';
      ModalProperties.properties.maxWidth = '90vw';
    } else {
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    }
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    this.dialog.open(AssetInfoComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }
}
