import { trigger, transition, style, animate, query, stagger } from '@angular/animations';
export const numberGoUp = trigger('numberGoUp', [
  transition(':increment', [
    style({ color: '#2dd36f'}),
    animate('900ms', style({ color: '#2dd36f'})),
  ]),
]);

export const numberGoUpWithBackground = trigger('numberGoUpWithBackground', [
  transition(':increment', [
    style({ background: '#2dd36f4b'}),
    animate('300ms', style({background: '#2dd36f4b'})),
  ]),
]);

export const fade = trigger('fade', [
  transition(':leave', [
    style({opacity: 1, transform: 'translateX(0%)'}),
    animate('100ms', style({opacity: 0, transform: 'translateX(-50%)'}))
  ])
]);

export const staggerListAnimation = trigger('staggerList', [
  transition('* => *', [
    query(':enter', [
      style({ opacity: 0, transform: '{{scale}} {{translateY}}' }),
      stagger( 50, [
        animate('{{time}}', style({ opacity: 1, transform: 'scale(1) translateY(0)'}))
      ])
    ],{ optional: true })
  ],{params: {scale: 'scale(0.6)', time: '0.5s', translateY: 'translateY(-.8rem)'}})
]);

export const staggerListAnimation2 = trigger('listAnimation', [
  transition('* => *', [
    query(':enter', [
      style({ opacity: 0 }),
      stagger( 50, [
        animate('{{time}}', style({ opacity: 1}))
      ])
    ],{ optional: true })
  ],{params: {scale: 'scale(0.6)', time: '0.3s'}})
]);

export const fade2 = trigger('fade2', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('100ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    animate('100ms', style({ opacity: 0 }))
  ])
]);
