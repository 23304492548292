// eslint-disable-next-line max-len
import { Component, OnInit, Input, ChangeDetectionStrategy, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ForTranslateService } from 'src/app/services/for-translate.service';
import { AuthService } from '../../services/auth.service';
import { AppStoreService } from '../../services/app-store.service';

@Component({
  selector: 'app-language-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit, OnChanges {

  @Input() translation: string;
  @Input() whiteText: boolean;
  @Input() languageSelectOpen: boolean;
  @ViewChild('languageSelect') languageSelect;
  @Output() panelToggled = new EventEmitter<boolean>();

  languageOptions = this.translateService.languages;

  constructor(
      public translateService: ForTranslateService,
      private authService: AuthService,
      private appStore: AppStoreService
    ) { }

  setTranslation() {
    this.translateService.setCurrentLanguage = this.translation;
    this.authService.setInviteUrl(this.translation);
  }

  ngOnInit() {
    if(!this.translation) {
      this.translation = this.translateService.currentLanguage;
    }
    // if (this.appStore.queryParams.lang) {
    //   const lang = this.appStore.queryParams.lang;
    //   if (lang === 'zh-hans') {
    //     return this.translateService.setCurrentLanguage = 'zh';
    //   }
    //   if (lang === 'zh-hant') {
    //     return this.translateService.setCurrentLanguage = 'zh-TW';
    //   }
    //   let lang;
    //   switch (this.appStore.queryParams.lang) {
    //     case 'zh-hans':
    //       lang = 'zh';
    //       break;
    //     case 'zh-hant':
    //       lang = 'zh-TW';
    //       break;
    //     case 'vi':
    //       lang = 'vi';
    //       break;
    //     case 'ru':
    //       lang = 'ru';
    //       break;
    //     case 'ja':
    //       lang = 'ja';
    //       break;
    //     default:
    //       lang = 'en';
    //       break;
    //   }
    //   this.translateService.setCurrentLanguage = lang;
    //   this.translateService.setCurrentLanguage = lang;
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.languageSelectOpen?.currentValue === true && !changes.languageSelectOpen?.previousValue) {
      this.languageSelect.open();
    }
  }

  togglePanel(e) {
    this.panelToggled.emit(e);
  }

}
