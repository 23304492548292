/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fade } from 'src/app/animations/animations';
import { AuthService } from '../../services/auth.service';
import { AppStoreService } from '../../services/app-store.service';
import { Subscription } from 'rxjs';
import { InitServiceService } from '../../services/init-service.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
  animations: [
    fade
  ]
})
export class VerifyEmailComponent implements OnInit, OnDestroy {

  step: 'verify-email' | 'user-info' = 'verify-email';

  verification;
  countdown = 60;
  interval;
  sentEmail = false;
  isLoading = false;
  resending = false;
  error;

  subscription: Subscription;
  subscription1: Subscription;
  subscription2: Subscription;

  constructor(
    private authService: AuthService,
    private dialogRef: MatDialogRef<VerifyEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private appStoreService: AppStoreService,
    private init: InitServiceService
  ) { }

  ngOnInit() {
    this.interval = setInterval(() => {
      this.countdown--;
      if (!this.countdown) {
        // this.resendVerificationCode();
        clearInterval(this.interval);
      }
    }, 1000);
  }

  verify() {
    this.sentEmail = false;
    this.isLoading = true;
    this.subscription = this.authService.verifyVerificationCode(this.verification, this.data.password).subscribe(
      (res: any) => {
        this.subscription1 = this.authService
            .login(
              this.data.email,
              this.data.password
            )
            .subscribe(
              (response: any) => {
                this.authService.authObj = {
                  isLoggedIn: true,
                  username: this.data.email,
                  login_name: response.login_name,
                  sessIDstr: response.sessIDstr,
                };

                sessionStorage.setItem('authObj', JSON.stringify(this.authService.authObj));
                this.init.loginScript(response);
                this.dialogRef.close();
              },
              (err: any) => {
                this.error = err;
              }
            );
        this.close();
      },
      (err: any) => {
        this.error = err;
        this.isLoading = false;
      });

    // this.authService.authObj  = {
    //   isLoggedIn: true,
    //   username: 'Bob Bob',
    //   userID: 1,
    //   walletConnected: false,
    //   subscription: false
    // };

    // IF NOT CORRECT THEN WE DONT MOVE ONTO THE NEXT PAGE
    // this.step = 'user-info';
  }

  resendVerificationCode() {
    this.sentEmail = false;
    this.isLoading = true;
    this.resending = true;
    if (!this.countdown) {
      this.subscription2 = this.authService.sendEmailVerificationCodeOrPasswordForget(this.data.email).subscribe(data => {
        this.isLoading = false;
        this.resending = false;
        this.countdown = 60;
        this.sentEmail = true;
        this.interval = setInterval(() => {
          this.countdown--;
          if (!this.countdown) {
            // this.resendVerificationCode();
            clearInterval(this.interval);
          }
        }, 1000);
      });
    }
  }

  goToRegistration() {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close(true);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    clearInterval(this.interval);
  }
}
