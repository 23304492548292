/* eslint-disable @typescript-eslint/prefer-for-of */
import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { createChart } from 'lightweight-charts';
import { Subscription } from 'rxjs';
import { CoinListService } from '../../services/coin-list.service';
import { AppStoreService } from '../../services/app-store.service';
import { switchMap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { time } from 'console';
import { constants } from 'fs';

@Component({
  selector: 'app-lightweight-chart',
  templateUrl: './lightweight-chart.component.html',
  styleUrls: ['./lightweight-chart.component.scss']
})
export class LightweightChartComponent implements OnInit, OnDestroy {

  @ViewChild('chartContainer') chartContainer!: ElementRef;

  @Input() isLoading = true;
  @Input() mobile = false;
  @Input() coin;

  chart: any;
  graph: any;
  data: any;
  clicked = false;
  subscription: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;
  interval;
  lastStored;

  constructor(
    private coinListService: CoinListService,
    public appStore: AppStoreService,
    private router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.mobile) {
      this.getMobileData();
    } else {
      this.assetChangeSubscription();
      this.subscription3 = this.appStore.minimizedMenu.subscribe((res: any) => {
        setTimeout(() => {
          this.onResize();
        }, 0);
      });
    }
  }

  onGoBack() {
    this.chart.timeScale().scrollToRealTime();
  }

  getButtonStatus() {
    return this.chart?.timeScale().scrollPosition() < 0 ? 'block' : 'none';
  }

  assetChangeSubscription() {
    // eslint-disable-next-line max-len
    this.subscription = this.appStore.selectedAsset.pipe(switchMap((id: string) =>
      this.coinListService.getCandleData(id))).subscribe((res: any) => {
        this.isLoading = true;
        this.data = res;
        this.lastStored = res[res.length - 1];
        this.initChart();
      });

    setTimeout(() => {
      if (!this.chart) {
        this.appStore.setSelectedAsset(this.router.url.split('/')[2].toLowerCase());
      }
    }, 1000);
  }

  getMobileData() {
    this.coinListService.getCandleData(this.coin).subscribe((res: any) => {
      this.isLoading = true;
      const data = res;
      this.data = [];
      for (let i = 0; i < 50; i++) {
        const obj: any = {};
        obj.time = data[i].time;
        obj.value = data[i].close;
        this.data.push(obj);
      }
      this.initLineChart();
    });
  }

  initLineChart() {
    this.chart = createChart(
      this.chartContainer.nativeElement,
      {
        width: this.chartContainer.nativeElement.offsetWidth,
        height: this.chartContainer.nativeElement.offsetHeight
      }
    );
    this.renderLineChart();
  }

  renderLineChart() {
    const chartOptions: any = {
      chart: {
        rightPriceScale: {
          visible: false,
        },
        leftPriceScale: {
          visible: false,
        },
        handleScroll: {
          mouseWheel: false,
          pressedMouseMove: false,
          horzTouchDrag: false,
          vertTouchdrag: false,
        },
        handleScale: {
          mouseWheel: false,
          pressedMouseMove: false,
          horzTouchDrag: false,
          vertTouchdrag: false,
        },
        timeScale: {
          visible: false
        },
        layout: {
          backgroundColor: '#12161c',
          textColor: '#485875',
        },
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            visible: false,
          },
        },
        watermark: {
          color: 'rgba(0, 0, 0, 0)',
        },
      },
      series: {
        lastValueVisible: false,
        priceLineVisible: false,
        crosshairMarkerVisible: false,
      }
    };
    if (this.coin === 'aave' || this.coin === 'ada' || this.coin === 'btt') {
      chartOptions.series.topColor = 'rgba(239, 83, 80, .4)';
      chartOptions.series.bottomColor = 'rgba(239, 83, 80, 0)';
      chartOptions.series.lineColor = 'rgb(239, 83, 80)';
    }
    this.chart.applyOptions(chartOptions.chart);
    this.graph = this.chart.addAreaSeries();
    this.graph.applyOptions(chartOptions.series);
    this.graph.setData(this.data);
    this.isLoading = false;
  }

  initChart() {
    this.removeChart();
    clearInterval(this.interval);
    this.chart = createChart(
      this.chartContainer.nativeElement,
      {
        width: this.chartContainer.nativeElement.offsetWidth,
        height: this.chartContainer.nativeElement.offsetHeight
      }
    );
    this.renderChart();
  }

  removeChart() {
    if (this.chart) {
      this.chart.remove();
    }
  }

  renderChart() {
    const chartOptions = {
      chart: {
        rightPriceScale: {
          scaleMargins: {
            top: 0.2,
            bottom: 0.2,
          },
          borderVisible: false,
        },
        handleScroll: {
          mouseWheel: true,
          pressedMouseMove: true,
        },
        handleScale: {
          axisPressedMouseMove: true,
          mouseWheel: true,
          pinch: true,
        },
        timeScale: {
          timeVisible: true,
          secondsVisible: false,
          borderVisible: false
        },
        layout: {
          backgroundColor: 'transparent',
          textColor: '#485875',
        },
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            visible: false,
          },
        },
        watermark: {
          color: 'rgba(0, 0, 0, 0)',
        },
        crosshair: {
          color: '#485875',
          vertLine: {
            color: '#485875',
            width: 1,
            style: 3,
            visible: true,
            labelVisible: true,
          },
          horzLine: {
            color: '#485875',
            visible: true,
            labelVisible: true
          },
        },
      },
      series: {
        priceLineVisible: true,
        lastValueVisible: true,
        priceFormat: {
          type: 'price',
          precision: (this.data[0]?.close < 0.0001) ? 7 : 2,
        }
      }
    };
    this.chart.applyOptions(chartOptions.chart);
    this.graph = this.chart.addCandlestickSeries();
    this.graph.applyOptions(chartOptions.series);
    this.graph.setData(this.data);
    this.isLoading = false;

    let maxValue = this.lastStored.close * 4;
    let target = this.lastStored.close * 2;
    let randomRange = this.lastStored.close / 3;
    let factor = 1;

    this.interval = setInterval(() => {

      if (factor > 0 && this.lastStored.close > target ||
        factor < 0 && this.lastStored.close < target
      ) {
        target = Math.floor(Math.random() * maxValue);
        factor = this.lastStored.close > target ? -1 : 1;
      }

      let randomFactor = Math.random() > .6 ? -.7 : 1;
      let newOpen = parseInt(this.lastStored.close, 10);
      let newClose = Math.abs(newOpen + (Math.floor(Math.random() * randomRange) + 5) * factor * randomFactor);

      let newHigh: number;
      let newLow: number;

      if (newOpen > newClose) {
        newHigh = newOpen + Math.floor(Math.random() * randomRange / 2);
        newLow = newClose - Math.floor(Math.random() * randomRange / 2);
      } else {
        newHigh = newClose + Math.floor(Math.random() * randomRange / 2);
        newLow = newOpen - Math.floor(Math.random() * randomRange / 2);
      }

      let newData = {
        time: this.lastStored.time,
        open: newOpen,
        high: newHigh,
        low: newLow,
        close: newClose
      }

      this.appStore.selectedAssetValue.price = newClose;

      newData.time.day += 1;

      this.lastStored = newData;

      this.graph.update(newData);
    }, 5000);
  }

  onResize() {
    if (this.chart) {
      this.chart.resize(this.chartContainer.nativeElement.offsetWidth, this.chartContainer.nativeElement.offsetHeight);
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription3?.unsubscribe();
    clearInterval(this.interval);
  }
}
