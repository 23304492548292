import { Injectable } from '@angular/core';
import { clear } from 'console';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  show = new BehaviorSubject<boolean>(false);
  text = new BehaviorSubject<string>('text');
  top = new BehaviorSubject<string>('0');
  left = new BehaviorSubject<string>('0');
  interval: any;

  constructor() { }

  toast(text: string, duration: number, top: string, left: string) {
    this.show.next(true);
    this.text.next(text);
    this.top.next(top);
    this.left.next(left);
    clearInterval(this.interval);

    this.interval = setInterval(() => {
      this.show.next(false);
      this.top.next('0');
      this.left.next('0');
    },duration);
  }
}
