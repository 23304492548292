import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet, ActivatedRoute } from '@angular/router';
import { AppStoreService } from './services/app-store.service';
import { AuthService } from './services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslatePipe } from './pipes/translate.pipe';
import { InitServiceService } from './services/init-service.service';
import { TranslateService } from '@ngx-translate/core';
import { ForTranslateService } from './services/for-translate.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Exchange';
  show = false;

  constructor(
    public router: Router,
    private appStore: AppStoreService,
    private route: ActivatedRoute,
    private init: InitServiceService,
    private authService: AuthService,
    public dialog: MatDialog,
    private translate: TranslatePipe,
    private translateService: TranslateService,
    private forTranslateService: ForTranslateService
  ) {
    let href: any = window.location.href.split('?')[1];
    if (href) {
       if (href.includes('&')) {
          href = href.split('&');
          href.forEach((data)=>{
            const subdata = data.split('=');
            this.appStore.queryParams[subdata[0]] = subdata[1];
           });
       } else {
          href = href.split('=');
          href.forEach(()=>{
            this.appStore.queryParams[href[0]] = href[1];
           });
       }
    }
    if (sessionStorage.getItem('authObj') !== null) {
      this.authService.authObj = JSON.parse(sessionStorage.getItem('authObj'));
      this.init.loginScript(this.authService.authObj);
    }
  }

  ngOnInit() {
    this.showMaintenanceMessage();
  }

  showMaintenanceMessage() {
    setTimeout(() => {
      if((Number(this.translate.transform('maintenance-to')) > new Date().getTime())) {
      //   ModalProperties.properties.panelClass = ['modal-background'];
      //   if ((Number(this.translate.transform('maintenance-from')) < new Date().getTime())) {
      //     ModalProperties.properties.disableClose = true;
      //     ModalProperties.properties.data.disable = true;
      //   } else {
      //     ModalProperties.properties.data.disable = false;
      //   }
      //   this.dialog.open(MaintenanceAnnouncementComponent, ModalProperties.properties);
      //   ModalProperties.resetProperties();
      this.show = true;
      }


    }, 3000);
  }

  closeModals(e: Event): void {
    // if target is not the notification menu ul element, close modals
    // prevents modal from closing if user clicks on the ul padding
    const target = e.target as HTMLElement;
    const id = target.id;
    if (id !== 'notification-menu' && id !== 'language' && target.nodeName !== 'APP-LANGUAGE-SELECT') {
      this.appStore.settingsDropdown = false;
      this.appStore.notificationDropdown = false;
    }
  }

  prepareRoute(outlet: RouterOutlet) {

  }
}
