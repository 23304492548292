import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MinUsdtService {
  usdtAmt: number;

  private usdt = new Subject<string>();
  cast = this.usdt.asObservable();

  constructor() { }

  validate(input: string) {
    this.usdt.next(input);
  }
}
