/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserInfo } from 'src/app/models/auth-interfaces';
import { AuthService } from '../../services/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {

  form: FormGroup;
  sending = false;
  alert = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private matDialog: MatDialogRef<UserInfoComponent>,
    private walletService: WalletService
  ) {
    this.form = fb.group({
      first_name: ['', {
        validators: [
        ],
      }],
      last_name: ['', {
        validators: [
        ],
      }],
      phone: ['', {
        validators: [
        ],
      }],
      sms: ['', {
        validators: [
        ],
      }],
    });
  }

  ngOnInit(): void {
  }

  updateUserInfo() {

  }

  close() {
    this.matDialog.close();
  }

  logout() {
    this.authService.logout();
  }

}
