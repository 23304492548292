export const usdtAddress = '0xdAC17F958D2ee523a2206206994597C13D831ec7'; //bep20

export const circulationContract = {
  usdxOld: {
    address: '0xeF2A4Fc36Deb5a6eCB0524fFcfEAcA8435a08184',
    issue: 0
  },
  usdxNew: {
    address: '0x35252Eb9F71a9957bbddF652642E7e93861Fa604',
    issue: 1
  }
};

export const tokenSwapContracts = {
  ethToUsdx: '0x0168641651b4df695f904934fa3a5bac06599768', //eth
  usdtToUsdx: '0x6a553C795B2488531d53D845E2D412c708728eDF', //eth
  ethToBnb: '0x2eC26D21f695A9fd0a0c03dC4e91d41df5D031A8', //eth
  usdtToBnb: '0x720ce9a6afd7e655a805999e27524eb47ea55911', //eth
  usdxToUsdt: '0x0883F51f5E6c632cE1D9DcD686C356198119525A', //bsc
  usdxToEth: '0x29e854088Ea8e0C7Fe8a90fd98e9b92cC8fAfaab', //bsc
  ethToUsdt: '0xdAC17F958D2ee523a2206206994597C13D831ec7'
};
