import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { RouteService } from '../services/route.service';
import { ScrollService } from '../services/scroll.service';
import { ModalProperties } from '../constants/set-modal-properties';
import { QrContractComponent } from '../common/qr-contract/qr-contract.component';
import { MatDialog } from '@angular/material/dialog';
import { AppStoreService } from '../services/app-store.service';
import { ForTranslateService } from '../services/for-translate.service';

@Component({
  selector: 'app-mobile-bottom-nav-bar',
  templateUrl: './mobile-bottom-nav-bar.component.html',
  styleUrls: ['./mobile-bottom-nav-bar.component.scss']
})
export class MobileBottomNavBarComponent implements OnInit, AfterViewChecked {
  @ViewChild('mobile2') private openBuySell: ElementRef;
  open = false;

  constructor(
    public router: Router,
    private scroll: ScrollService,
    public routeService: RouteService,
    private appStore: AppStoreService,
    private dialog: MatDialog,
    private translateService: ForTranslateService
  ) { }

  ngOnInit(): void {
  }

  checkLanguage() {
    const lang = this.translateService.currentLanguage;
    if ((lang === 'ru' || lang === 'ja' || lang === 'vi') && window.innerWidth < 320) {
      return '10px';
    }
  }

  scrollToCirculationHistory() {
    this.scroll.scrollToCirculationHistory.next(Math.random());
    this.open = false;
  }

  openQrContract() {
    //Add small screen to panel class in an array like this to target small screen sizes
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    } //end ELSE
    ModalProperties.properties.panelClass = ['small-screen','modal-background'];
    ModalProperties.properties.data = {
      // this will need to be updated if they want to pass different coin
      data: {totalSum: 0, contractAddress: this.appStore.selectedAssetValue.sendContractAddress}
    };
    this.dialog.open(QrContractComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  scrollBottom() {
    if(this.openBuySell && this.openBuySell.nativeElement) {
      setTimeout(()=>{
        this.openBuySell.nativeElement.scrollTop = this.openBuySell.nativeElement.scrollHeight;
      }, 200);
    }
  }

  ngAfterViewChecked() {
    this.scrollBottom();
  }
}
