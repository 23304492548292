import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PreAccessComponent } from '../pre-access/pre-access.component';
import { PreAccessGuard } from '../pre-access.guard';

const routes: Routes = [
  // {
  //   path: 'beta-access',
  //   component: PreAccessComponent
  // },
  {
    path: '',
    loadChildren: () => import('../home/home.module').then( m => m.HomePageModule),
    // canActivate: [PreAccessGuard],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
