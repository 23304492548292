import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-invite-qr',
  templateUrl: './invite-qr.component.html',
  styleUrls: ['./invite-qr.component.scss']
})
export class InviteQrComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private dialogRef: MatDialogRef<InviteQrComponent>) { }

  ngOnInit(): void {
  }

  download() {
    const canvas = document.querySelector('canvas');
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = canvas.toDataURL();
    a.download = `empower-invite-qr-${this.authService.authObj.login_name}.png`;
    a.click();
    document.body.removeChild(a);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
